import classNames from "classnames";
import React from "react";
function EditorToolBar({ className }: { className?: string }) {
  return (
    <div id="toolbar" className={classNames("sticky top-0 z-10 !border-0 bg-gray-100", className)}>
      <span className="ql-formats space-x-3">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
      </span>
      <span className="ql-formats space-x-3">
        <select className="ql-align">
          <option value=""></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
        <button className="ql-direction" type="button" value={"rtl"} />
        <button className="ql-indent" value="-1" type="button"></button>
        <button className="ql-indent" value="+1" type="button"></button>
        <button className="ql-link" />
      </span>

      <span className="ql-formats space-x-3">{/* <button className="ql-clean" /> */}</span>
    </div>
  );
}
export default EditorToolBar;
