import { useCallback } from "react";
import {
  IProjectSubtask,
  IProjectSubtaskCreateRequest,
  IProjectSubtaskUpdateRequest,
} from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { useAppDispatch } from "../../app/store";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface ProjectSubtaskState {
  data: IProjectSubtask;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  update: (req: IProjectSubtaskUpdateRequest) => Promise<IProjectSubtask | undefined>;
  updateProjectId: (project_id: string | undefined) => Promise<IProjectSubtask | undefined>;
  updateTaskId: (task_id: string | undefined) => Promise<IProjectSubtask | undefined>;
  deleteThis: () => Promise<boolean>;
  revalidate: () => Promise<IProjectSubtask | undefined>;
}
const defaultProjectSubtask: IProjectSubtask = {
  _id: "",
  org_id: "",
  project_id: "",
  task_id: "",
  updated_at: "",
  created_at: "",
  name: "",
  status: "",
  users: [],
};
const getProjectSubtask = async (id: string) => {
  if (!id) return new Promise<IProjectSubtask>((resolve, reject) => reject());
  return axios
    .get(process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/${id}`, config)
    .then((res) => res.data as IProjectSubtask);
};
const createProjectSubtask = async (
  req: IProjectSubtaskCreateRequest & { new_comment?: string },
) => {
  // console.log(req);
  return axios
    .post(process.env.REACT_APP_API_URL + `/api/v2/projects/subtask`, req, config)
    .then((res) => res.data as IProjectSubtask);
};
const updateProjectSubtask = async (id: string, req: IProjectSubtaskUpdateRequest) => {
  if (!id) return new Promise<IProjectSubtask>((resolve, reject) => reject());
  return axios
    .put(process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/${id}`, req, config)
    .then((res) => res.data as IProjectSubtask);
};
const deleteProjectSubtask = async (id: string) => {
  if (!id) return new Promise<boolean>((resolve, reject) => reject());
  return axios
    .delete(process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/${id}`, config)
    .then((res) => res.data as boolean);
};
function useSubtask(subtask_id?: string): ProjectSubtaskState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/${subtask_id}`;
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectSubtask>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as IProjectSubtask);
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectSubtask>(
    subtask_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const update = useCallback(
    async (req: IProjectSubtaskUpdateRequest) => {
      if (!data) return;
      const options = {
        optimisticData: { ...data, ...req },
        rollbackOnError: true,
      };
      // console.log({req});
      return mutate<IProjectSubtask>(
        url,
        axios.put(url, req, config).then((response) => response.data),
        options,
      );
    },
    [url, data],
  );
  const updateProjectId = useCallback(
    async (project_id: string | undefined) => {
      if (!data) return;
      const options = {
        optimisticData: { ...data, project_id },
        rollbackOnError: true,
      };

      return mutate<IProjectSubtask>(
        url,
        axios
          .put(url + `?update_id=project_id`, { project_id }, config)
          .then((response) => response.data),
        options,
      );
    },
    [url, data],
  );
  const updateTaskId = useCallback(
    async (task_id: string | undefined) => {
      if (!data) return;
      const options = {
        optimisticData: { ...data, task_id },
        rollbackOnError: true,
      };

      return mutate<IProjectSubtask>(
        url,
        axios
          .put(url + `?update_id=task_id`, { task_id }, config)
          .then((response) => response.data),
        options,
      );
    },
    [url, data],
  );

  const deleteThis = useCallback(async () => {
    if (subtask_id)
      return deleteProjectSubtask(subtask_id).then((res) => {
        if (res && data)
          mutate(process.env.REACT_APP_API_URL + `/api/v2/projects/subtasks/${data.task_id}`);
        return res;
      });
    else return new Promise<boolean>((resolve, reject) => reject());
  }, [subtask_id, data]);
  const revalidate = useCallback(() => mutate<IProjectSubtask>(url), [url]);
  return {
    data: data || defaultProjectSubtask,
    isLoading,
    error,
    isValidating,
    update,
    updateProjectId,
    updateTaskId,
    deleteThis,
    revalidate,
  };
}
export { getProjectSubtask, createProjectSubtask, updateProjectSubtask, defaultProjectSubtask };
export default useSubtask;
