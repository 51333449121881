import classNames from "classnames";
import { ReactNode, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../app/store";
import NPOSummary from "../../../../components/NPOSummary";
import ReviewAndEditCard from "../../../../components/ReviewAndEditCard";
import { SuggestEditButton } from "../../../../components/SuggestEditButton";
import ExpandableCard from "../../../../components/tailwind/ExpandableCard";
import useNPOSummary from "../../../../hooks/useNPOSummary";
import useOrgDataForNPODashboard from "../../../../hooks/useOrgDataForNPODashboard";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import EditBasicInfo from "../../organizational-profile/component/EditBasicInfo";
import { UserView } from "../NpoLayout";

function NpoAbout() {
  const { id } = useParams();
  const { isPrivateView, hasWritePermission } = useOutletContext<UserView>();
  return (
    <NpoAboutDetail hasWritePermission={hasWritePermission} isPrivateView={isPrivateView} id={id} />
  );
}
export default NpoAbout;
export function NpoAboutDetail({
  isPrivateView,
  hasWritePermission,
  id,
  compact = false,
  basicAboutInfo,
}: {
  isPrivateView?: boolean;
  hasWritePermission?: boolean;
  id?: string;
  compact?: boolean;
  basicAboutInfo?: () => ReactNode;
}) {
  // const { org_id, npo_id, data: orgData, projects } = useOrgDataForNPODashboard(id, isPrivateView);
  return (
    <div className={classNames("flex flex-col")}>
      <AboutOrganization
        id={id}
        isPrivateView={isPrivateView}
        hasWritePermission={hasWritePermission}
        compact={compact}
        basicAboutInfo={basicAboutInfo?.()}
      />
    </div>
  );
}
export function NpoPhilanthropicFocus({
  id,
  isPrivateView,
  loading,
  hasWritePermission,
  compact = false,
  withoutLogin,
  isProfileClaim,
}: {
  id?: string;
  isPrivateView: boolean | undefined;
  hasWritePermission: boolean | undefined;
  loading?: boolean;
  compact?: boolean;
  withoutLogin?: boolean;
  isProfileClaim?: boolean;
}) {
  const { data: orgData, org_id, npo_id } = useOrgDataForNPODashboard(id, isPrivateView);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { data: summary, isLoading: isLoadingSummary, error } = useNPOSummary(npo_id);
  const { data: orgProfile, revalidate, update: updateProfile } = useOrgProfile(org_id);

  const renderDetail = () => {
    return <NPOSummary id={id} isPrivateView={isPrivateView} compact={compact} title="" />;
  };
  return (
    <>
      {showEdit && orgData && (
        <EditBasicInfo
          id="aboutorg_edited"
          onboardingData={orgData}
          showEdit={showEdit}
          showContent={["ABOUT_ORGANIZATION"]}
          onClose={() => {
            setShowEdit(false);
            updateProfile({ reviewed_philanthropic: true });
          }}
        />
      )}
      <ExpandableCard
        id="about-view"
        title="Philanthropic focus"
        allowExpand={false}
        allowEdit={isPrivateView && hasWritePermission}
        showAllDetail={false}
        customClass={compact ? "border-0 rounded-none" : ""}
        onEdit={() => {
          setShowEdit((prev) => !prev);
        }}
        {...(withoutLogin && isProfileClaim && { editContainer: <SuggestEditButton /> })}
      >
        {isPrivateView && hasWritePermission && !orgProfile.reviewed_philanthropic && summary && (
          <ReviewAndEditCard
            content={
              <>
                Giboo has pre-loaded your organization&apos;s philanthropic focus. <br />
                Please confirm to ensure your public profile accurately represents your
                organization.
              </>
            }
            wrapperClass=""
            handleOnClick={() => {
              updateProfile({ reviewed_philanthropic: true });
            }}
          />
        )}
        <div
          className={classNames(
            isPrivateView &&
              hasWritePermission &&
              !orgProfile.reviewed_philanthropic &&
              summary &&
              "opacity-40",
          )}
        >
          {renderDetail()}
        </div>
      </ExpandableCard>
    </>
  );
}

export function AboutOrganization({
  id,
  isPrivateView,
  loading,
  hasWritePermission,
  compact = false,
  basicAboutInfo,
  withoutLogin,
  isProfileClaim,
}: {
  id?: string;
  isPrivateView: boolean | undefined;
  hasWritePermission: boolean | undefined;
  loading?: boolean;
  compact?: boolean;
  basicAboutInfo: ReactNode;
  withoutLogin?: boolean;
  isProfileClaim?: boolean;
}) {
  const { data: orgData, org_id, npo_id } = useOrgDataForNPODashboard(id, isPrivateView);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const { data: orgProfile, revalidate, update: updateProfile } = useOrgProfile(org_id);
  return (
    <>
      {showEdit && orgData && (
        <EditBasicInfo
          id="aboutorg_edited"
          onboardingData={orgData}
          showEdit={showEdit}
          showContent={["ADDRESS_WEBSITE", "ORGANIZATION_GROWTH_AND_SIZE"]}
          onClose={() => {
            setShowEdit(false);
            updateProfile({ reviewed_basic_information: true });
          }}
        />
      )}
      <ExpandableCard
        id="about-view"
        title="About organization"
        allowExpand={false}
        allowEdit={isPrivateView && hasWritePermission}
        showAllDetail={false}
        customClass={compact ? "border-0 rounded-none" : ""}
        onEdit={() => {
          setShowEdit((prev) => !prev);
        }}
        {...(withoutLogin && isProfileClaim && { editContainer: <SuggestEditButton /> })}
      >
        {isPrivateView && hasWritePermission && !orgProfile.reviewed_basic_information && (
          <ReviewAndEditCard
            content={
              <>
                Giboo has pre-loaded your organization&apos;s information.
                <br /> Please confirm to ensure your public profile accurately represents your
                organization.
              </>
            }
            wrapperClass=""
            handleOnClick={() => {
              updateProfile({ reviewed_basic_information: true });
            }}
          />
        )}
        <div
          className={classNames(
            isPrivateView &&
              hasWritePermission &&
              !orgProfile.reviewed_basic_information &&
              "opacity-40",
          )}
        >
          {basicAboutInfo && basicAboutInfo}
        </div>
      </ExpandableCard>
    </>
  );
}
