import { useEffect, useState } from "react";
import {
  selectBasicInformation,
  selectNPOInfo,
  selectNPOInfoRequested,
  selectPhilanthropyRequested,
  selectStage,
  updateOnboardingAsync,
} from "../../../app/onboardingSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import creatingProjectIcon from "../../../assets/images/creatingProject.svg";
import useNPOSearchResult from "../../../hooks/search/useNPOSearchResult";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import useLocalStorage from "../../../hooks/useLocalstorage";
import useOnboardingData from "../../../hooks/useOnboarding";
import useSavedMoreSearchOption from "../../../hooks/useSavedMoreSearchOption";
import useUser from "../../../hooks/useUser";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import { TypeStage } from "../../../types/onboarding";
export const NEW_USER = "NEW_USER";
function Finish() {
  const mxEvent = useGibooMixpanel();
  const dispatch = useAppDispatch();
  const { npo_id } = useAppSelector((state) => selectBasicInformation(state));
  const { searchType, setSearchType } = useSavedMoreSearchOption();
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const { location: head_location } = useAppSelector((state) => selectNPOInfo(state));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progressVal, setProgressVal] = useState(0);
  const [isDone, setIsDone] = useState(false);
  const [postProcessingStarted, setPostProcessingStarted] = useState<boolean>(false);
  const { update: setNewUser } = useLocalStorage(NEW_USER, false);
  const { data: npo, isLoading: isNPOLoading } = useNPOSearchResult(npo_id);
  const { service_loc_requested } = useAppSelector((state) => selectPhilanthropyRequested(state));
  const stage_requested = useAppSelector((state) => selectStage(state));
  const { mission_requested } = useAppSelector((state) => selectNPOInfoRequested(state));
  useEffect(() => {
    if (
      !user ||
      isLoading ||
      isDone ||
      onboardingData.role == undefined ||
      onboardingData.role == null
    )
      return;
    handleComplete();
  }, [user, isDone, isLoading, onboardingData.role]);
  useEffect(() => {
    if (onboardingData.completed && onboardingData.accepted) return;
    const interval = setInterval(() => {
      // const increment = 15;
      const increment = Math.floor(Math.random() * 15) + (isDone ? 20 : 10);
      setProgressVal((prevProgress) => {
        const newProgress = prevProgress + increment;
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 800);
    return () => {
      clearInterval(interval);
    };
  }, [isDone, progressVal, isLoading]);
  useEffect(() => {
    if (onboardingData.completed && onboardingData.accepted) return;
    if (npo_id && (isNPOLoading || !npo)) return;
    if (progressVal >= 100 && isDone) {
      mxEvent(MIXPANEL_EVENTS_V2.onboarding[""].successful);
      dispatch(
        updateOnboardingAsync(
          {
            ...(npo && (stage_requested === "all" || !stage_requested)
              ? {
                  stage_requested: npo.stage as TypeStage,
                }
              : {}),
            ...(npo && !mission_requested
              ? {
                  mission_requested: npo?.mission,
                }
              : {}),
            ...(npo &&
            (!service_loc_requested || service_loc_requested.length === 0) &&
            npo.location
              ? {
                  service_loc_requested: [
                    npo.location,
                    ...(npo.location.level > 1 ? [{ ...npo.location, level: 1 }] : []),
                  ],
                }
              : {}),
            completed: true,
            accepted: true,
          },
          0,
          { updateImmediately: true },
        ),
      );
    }
  }, [isDone, isNPOLoading, npo, progressVal]);

  const handleComplete = () => {
    if (postProcessingStarted) return;
    setPostProcessingStarted(true);
    // setIsLoading(true);
    // Uncomment below to enable feature introduction
    setNewUser(true);
    setSearchType("virtual_grant");
    // setIsLoading(false);
    setIsDone(true);
    // if (onboardingData.role >= 5) {
    //   // only admin can update taxonomy
    //   setIsDone(false);
    //   // taggingTaxonomy(onboardingData.mission || "").then((res) => {
    //   // const state: ILocation | undefined = head_location
    //   //   ? head_location.level > 1
    //   //     ? { ...head_location, city: "", county_name: "", level: 1 }
    //   //     : head_location
    //   //   : undefined;
    //   // Promise.resolve(
    //   //   dispatch(
    //   //     updateOnboardingAsync(
    //   //       {
    //   //         // focus_area_requested: res.focus_area,
    //   //         // beneficiary_requested: res.beneficiary,
    //   //         // program_requested: res.program,
    //   //         service_loc_requested: head_location
    //   //           ? state
    //   //             ? [state, head_location]
    //   //             : [head_location]
    //   //           : [],
    //   //       },
    //   //       0,
    //   //       { updateImmediately: true },
    //   //     ),
    //   //   ),
    //   // ).finally(() => {
    //   //   setIsLoading(false);
    //   //   setIsDone(true);
    //   // });
    //   // });
    // } else {
    //   setIsLoading(false);
    //   setIsDone(true);
    // }
  };

  return (
    <>
      <div className=" flex flex-col items-center">
        <div className="onboarding flex h-[calc(100vh-240px)] flex-col items-center justify-center">
          <div className="bg-white-100 flex h-full w-[648px] flex-col items-center justify-center">
            <h5 className="mt-[50px] font-poppins text-xl font-semibold text-purple-500">
              We are setting up your organization profile!
            </h5>
            <span className="my-[80px]">
              <img
                src={creatingProjectIcon}
                className="h-[100px] w-[100px]"
                alt="creating project for you"
              />
            </span>
          </div>
        </div>
      </div>
      {/* <OnboardingBack /> */}
    </>
  );
}
export default Finish;
