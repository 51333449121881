import useFunderFiledYear from "../../hooks/funder/useFunderFiledYear";
import useFunderWealth from "../../hooks/funder/useFunderWealth";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";
import useForecasts from "../../hooks/useForecasts";
import useGibooMixpanel from "../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../mixpanel/mixpanel";
import ChartLoader from "../ChartLoader";
import DonorWealthInfoBar from "../dashboard/DonorWealthInfoBar";
import VisibilityTracker from "../VisibilityTracker";
export default function FunderWealthInformation({
  funder_id,
  compact,
  ...props
}: {
  funder_id: string;
  wrapperClass?: string;
  compact?: boolean;
}) {
  const LAST_N_YEARS = 6;
  const mxEvent = useGibooMixpanel();
  const { data: funder, isLoading: isFunderLoading } = useFunderSearchResult(funder_id);
  const { data: filedYears } = useFunderFiledYear(funder_id, LAST_N_YEARS);
  const { data: wealth, isLoading: isWealthLoading } = useFunderWealth(funder_id, LAST_N_YEARS);
  const { data: forecast, isLoading: isForecastLoading } = useForecasts(wealth, [
    "revenue",
    "expenses",
    "assets",
    "distributions",
    "contribution",
  ]);
  const isLoading = isFunderLoading || isWealthLoading;
  if (isLoading) {
    return <ChartLoader />;
  }
  return (
    <>
      {wealth && (
        <VisibilityTracker
          elementIdOrRef={"donor-wealth-chart-view"}
          onVisible={() => {
            // console.log("chart-view: Wealth information");
            mxEvent(MIXPANEL_EVENTS_V2.detail_view.visibility.observed, {
              value: "Wealth information",
            });
          }}
        >
          <DonorWealthInfoBar
            returnCode={funder?.return_code || ""}
            filedYears={filedYears}
            wealthInformation={forecast}
            compact={compact}
          />
        </VisibilityTracker>
      )}
    </>
  );
}
