import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { IProjectSubtask } from "../../types/project";
import useOnboardingData from "../useOnboarding";
import useOrgID from "../useOrgID";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface ProjectSubtasksState {
  data: IProjectSubtask[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IProjectSubtask[] | undefined>;
}
const emptyList: IProjectSubtask[] = [];
function useMySubtasks(project_id = "", task_id = "", do_not_fetch = false): ProjectSubtasksState {
  const org_id = useOrgID();
  const url =
    process.env.REACT_APP_API_URL +
    `/api/v2/projects/subtasks/my?org_id=${org_id}&project_id=${project_id}&task_id=${task_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectSubtask[]>((resolve, reject) => reject());
    return (await axios.get(_url, config).then((res) => res.data as IProjectSubtask[])).sort(
      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    );
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectSubtask[]>(
    do_not_fetch ? null : url,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<IProjectSubtask[]>(url), [url]);
  return {
    data: data || emptyList,
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useMySubtasks;
