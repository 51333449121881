import { Portal } from "@headlessui/react";
import axios from "axios";
import classNames from "classnames";
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Divider, Placeholder } from "rsuite";
import useSWR from "swr";
import config from "../../../api";
import {
  openSigninSignup,
  sidekickDetailViewScroll,
  sidekickSearchByName,
  sidekickSearchQuery,
  sidekickStartFundraisingLoading,
  sidekickTargetId,
  sidekickTargetType,
} from "../../../app/recoilStore";
import BackButton from "../../../components/BackButton";
import ChartLoader from "../../../components/ChartLoader";
import ClickMeTooltip from "../../../components/ClickMeTooltip";
import FullScreenLoading from "../../../components/FullScreenLoading";
import FundedAmount from "../../../components/FundedAmount";
import FunderGeographicFocus from "../../../components/funder/FunderGeographicFocus";
import FunderStageAnalysisSimple from "../../../components/funder/FunderStageAnalysisSimple";
import PastRecipients from "../../../components/funder/PastRecipients";
import FunderSummary from "../../../components/FunderSummary";
import GrantDescriptionDetail, { CountedAreas } from "../../../components/GrantDescriptionDetail";
import PhilanthropicInformationFoldable from "../../../components/PhilanthropicInformationFoldable";
import PlainButton from "../../../components/PlainButton";
import RecGrants from "../../../components/RecGrants";
import RecGrantsFromSameFunder from "../../../components/RecGrantsFromSameFunder";
import RoundButton from "../../../components/RoundButton";
import SavePopup from "../../../components/SavePopup";
import ShareButton from "../../../components/ShareButton";
import SignInModal from "../../../components/SignInModal";
import Spacer from "../../../components/Spacer";
import StartFundRaisingModal, {
  useProjectCreation,
} from "../../../components/StartFundraisingModal";
import TagFunderSize from "../../../components/TagFunderSize";
import TagFunderType from "../../../components/TagFunderType";
import TagGrantEligible from "../../../components/TagGrantEligible";
import TagGrantPresent from "../../../components/TagGrantPresent";
import TagInvitationOnly from "../../../components/TagInvitationOnly";
import Button from "../../../components/tailwind/Button";
import Slider from "../../../components/tailwind/Slider";
import TryLetterCard, { TryLoiButton } from "../../../components/TryLetterCard";
import WhyMatched from "../../../components/WhyMatched";
import useGrantsFromSameFunder from "../../../hooks/grant/useGrantsFromSameFunder";
import useOpencallGrantsDetailFromSameFunder from "../../../hooks/grant/useOpencallGrantsDetailFromSameFunder";
import useVirtualGrantsFromSameFunder from "../../../hooks/grant/useVirtualGrantsFromSameFunder";
import useHasPermissionToUpdateGrant from "../../../hooks/internal_tool/useHasPermissionToUpdateGrant";
import useSave from "../../../hooks/save/useSave";
import useFunderSearchResult from "../../../hooks/search/useFunderSearchResult";
import useGrantSearchResult from "../../../hooks/search/useGrantSearchResult";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import useOnboardingData from "../../../hooks/useOnboarding";
import usePageTitle from "../../../hooks/usePagetitle";
import useRateLimit from "../../../hooks/useRateLimit";
import useScrollTracking from "../../../hooks/useScrollTracking";
import useUser from "../../../hooks/useUser";
import { Buttons, MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import { IGrant } from "../../../types/grant";
import {
  Requirement,
  applicationRequirementLabels,
  financialPositionDocumentLabels,
  financialPositionRequirementLabels,
  granteeTypeLabels,
  proposalRequirementLabels,
} from "../../../types/grantDetail";
import { SearchQuery } from "../../../types/search";
import { ASSET_SIZE_OPTION } from "../../../types/searchFilter";
import { scrollBarClass } from "../../../utils/classes";
import { prettyListStrings } from "../../../utils/formatHelper";
import { renderRow } from "../../../utils/funder";
import {
  ReprGrantServiceLocations,
  getAgencyName,
  getGrantTitle,
  isGrantEligible,
  matchGrantFocusArea,
  reprGrantAmount,
  reprGrantDeadline,
  reprGrantFundingType,
} from "../../../utils/grant";
import { getNPOSearchURLFromGrant, setSelectedQueryFunc } from "../../../utils/search";
import GrantContactWrapper from "./GrantContactWrapper";

type NavItem =
  // | "area_of_interest"
  | "about"
  | "tags"
  | "grant_focus_area"
  | "grant_beneficiary"
  | "grant_programs"
  | "grant_geo_preferences"
  | "eligibility"
  | "in_eligibility"
  | "app_requirement"
  | "proposal_requirement"
  | "reporting_requirement"
  | "general_information"
  | "past_recipients"
  | "contact";

interface GrantViewProps {
  withoutLogin?: boolean;
  edit?: boolean;
  compact?: boolean;
  showStickyHeader?: boolean;
  query?: SearchQuery;
  grantId?: string;
  nameMatch?: boolean;
  gids?: string[];
  noOfGrantClick?: number;
  showGuidance?: boolean;
  fromProjectMgt?: boolean;
}

const GrantView = ({
  withoutLogin = false,
  edit = false,
  compact = false,
  query,
  grantId,
  nameMatch,
  gids,
  noOfGrantClick,
  showGuidance = false,
  fromProjectMgt,
}: GrantViewProps) => {
  const mxEvent = useGibooMixpanel("detail_view");
  const { data: hasEditPermission } = useHasPermissionToUpdateGrant(edit, grantId);
  const { ok, isLoading: isRateLimitLoading, reset: resetRateLimit } = useRateLimit(withoutLogin);
  const setSignUpSignin = useSetRecoilState(openSigninSignup);
  const { id: paramId } = useParams();
  const id = grantId || paramId;
  const [previousId, setPreviousId] = useState<string | undefined>(id);
  useLayoutEffect(() => {
    const parentContainerId = compact ? "search-result-main" : undefined;
    if (id !== previousId) {
      (parentContainerId ? document.getElementById(parentContainerId) : window)?.scrollTo(0, 0);
    }
    setPreviousId(id);
  }, [id]);
  const { state: context, pathname, search } = useLocation();
  const [onboardingData] = useOnboardingData();
  const [user] = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const setTargetId = useSetRecoilState(sidekickTargetId);
  const setTargetType = useSetRecoilState(sidekickTargetType);
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const [selectedQuery, setSelectedQuery] = useState<SearchQuery>(
    setSelectedQueryFunc(onboardingData, query, context, user),
  );
  const hasNoTaxonomy =
    selectedQuery.focus_area.length === 0 &&
    selectedQuery.beneficiary.length === 0 &&
    selectedQuery.program.length === 0 &&
    selectedQuery.hq_loc.length === 0 &&
    selectedQuery.service_loc.length === 0;
  useEffect(() => {
    setSelectedQuery(setSelectedQueryFunc(onboardingData, query, context, user));
  }, [query, context, user, onboardingData]);
  const {
    data: matched,
    isLoading: isGrantLoading,
    dataAll: grant,
    dataUnMatched: unMatched,
  } = useGrantSearchResult(id, process.env.REACT_APP_ROUTER === "FUNDER", selectedQuery);

  !query && usePageTitle(grant ? grant.name : "Grant");
  useEffect(() => {
    if (!id || !grant) {
      setTargetId("");
    } else {
      setTargetId(id);
      setTargetType(grant.type === "grant-page" ? 0 : 2);
    }
    return () => {
      setTargetId("");
      setTargetType(-1);
    };
  }, [grant, id]);
  const { data: grantDetail, isLoading: isGrantDetailLoading } = useSWR<IGrant>(
    id ? process.env.REACT_APP_API_URL + `/api/v2/grants/${id}` : "",
    fetch,
  );
  const { newProject, projects, isCreating } = useProjectCreation();
  const setSidekickStartFundraisingLoading = useSetRecoilState(sidekickStartFundraisingLoading);
  useEffect(() => {
    setSidekickStartFundraisingLoading(isCreating);
  }, [isCreating]);
  const { data: currentSavedList } = useSave(id, 1);
  const { data: funder } = useFunderSearchResult(grant?.donor_id);
  const [showFundraising, setShowFundraising] = useState<boolean>(false);
  const [showSave, setShowSave] = useState<boolean>(false);

  const setSidekickSearchQuery = useSetRecoilState(sidekickSearchQuery);
  const setSidekickSearchByName = useSetRecoilState(sidekickSearchByName);
  useEffect(() => {
    setSidekickSearchQuery(selectedQuery);
  }, [selectedQuery]);
  useEffect(() => {
    setSidekickSearchByName(nameMatch || context?.nameMatch ? true : false);
  }, [nameMatch, context]);
  const eligible = isGrantEligible(onboardingData.tax_exemption, selectedQuery?.service_loc, grant);
  // useScrollToHashElement();

  const useUloc =
    grant?.type && ["state", "county", "city", "federal"].includes(grant.type) ? true : false;
  // console.log(selectedQuery);
  const { data: recommendation } = useGrantsFromSameFunder({
    use: !compact,
    query: selectedQuery,
    keyId: useUloc ? grant?.uloc : grant?.donor_id,
    grantType: grant?.type,
  });
  // console.log("grant", grant, grantDetail, matched);
  const setCountedAreasFunc = () => {
    return {
      FocusAreas: grantDetail?.FocusAreas?.map((a) => ({
        ...a,
        ...matchGrantFocusArea(a, selectedQuery),
      })).sort((a, b) => b.matchedCount - a.matchedCount),
      Beneficiaries: grantDetail?.Beneficiaries?.map((a) => ({
        ...a,
        ...matchGrantFocusArea(a, selectedQuery),
      })).sort((a, b) => b.matchedCount - a.matchedCount),
      Programs: grantDetail?.Programs?.map((a) => ({
        ...a,
        ...matchGrantFocusArea(a, selectedQuery),
      })).sort((a, b) => b.matchedCount - a.matchedCount),
      GeographicalPreferences: grantDetail?.GeographicalPreferences?.map((a) => ({
        ...a,
        ...matchGrantFocusArea(a, selectedQuery, grant?.type === "grant-page" ? false : true),
      })).sort((a, b) => b.matchedCount - a.matchedCount),
    };
  };
  const [countedAreas, setCountedAreas] = useState<CountedAreas>(setCountedAreasFunc());
  useEffect(() => {
    setCountedAreas(setCountedAreasFunc());
  }, [grantDetail, selectedQuery]);

  // NAV
  //  STICKY NAV BAR START
  const [showStickyNav, setShowStickyNav] = useState<boolean>(false);
  const stickyHeaderRef = useRef<HTMLDivElement>(null);
  const [activeLink, setActiveLink] = useState<NavItem>();
  const elementIdsForVirtualGrant = [
    "about",
    "tags",
    "grant_focus_area",
    "grant_beneficiary",
    "grant_programs",
    "grant_geo_preferences",
    "eligibility",
    "in_eligibility",
    "app_requirement",
    "proposal_requirement",
    "reporting_requirement",
    "general_information",
    "past_recipients",
    "contact",
  ];
  const elementIds = [
    // "area_of_interest",
    "about",
    "tags",
    "grant_focus_area",
    "grant_beneficiary",
    "grant_programs",
    "grant_geo_preferences",
    "eligibility",
    "in_eligibility",
    "app_requirement",
    "proposal_requirement",
    "reporting_requirement",
    "general_information",
    "past_recipients",
    "contact",
  ];
  const parentContainerId = compact ? "search-result-main" : undefined;
  const NAV_SCROLL_OFFSET =
    (stickyHeaderRef?.current?.offsetHeight || 65) + 54 + (compact ? 0 : +16);
  const isVirtualGrant = grant?.type === "grant-page";
  const elementIdList = isVirtualGrant ? elementIdsForVirtualGrant : elementIds;
  const { topElement, scrollY, scrollToTop, scrollTo, setTopElement, showNav, reset } =
    useScrollTracking({
      parentElementId: parentContainerId,
      childElementIds: elementIdList,
      offset: NAV_SCROLL_OFFSET,
      headerElementId: "grant-header",
      dependency: id,
    });
  // const { scrollTo } = useScrollTo(parentContainerId, NAV_SCROLL_OFFSET);
  useEffect(() => {
    setShowStickyNav(scrollY === 0 ? false : showNav);
    setActiveLink(topElement ? (topElement as NavItem) : (elementIdList?.[0] as NavItem));
  }, [topElement, scrollY, showNav]);
  const setSidekickDetailViewScroll = useSetRecoilState(sidekickDetailViewScroll);
  useEffect(() => {
    if (scrollY > 0) setSidekickDetailViewScroll(true);
  }, [scrollY]);
  useEffect(() => {
    setSidekickDetailViewScroll(false);
  }, [id]);

  const { data: opencallData, isLoading: isOpencallLoading } =
    useOpencallGrantsDetailFromSameFunder(isVirtualGrant ? grant.donor_id : undefined);
  useLayoutEffect(() => {
    if (id || grantId) {
      scrollToTop();
      reset();
    }
  }, [id, grantId]);
  const { data: virtualGrants, isLoading: isVirtualGrantsLoading } = useVirtualGrantsFromSameFunder(
    !isVirtualGrant ? grant?.donor_id : undefined,
  );
  const globalLoading =
    isGrantLoading || isGrantDetailLoading || isOpencallLoading || isVirtualGrantsLoading;
  const grantForEligibility = isVirtualGrant
    ? opencallData
        ?.filter((a) => a.fiscal_position && a.fiscal_position.length > 0)
        ?.sort((a, b) => (b.fiscal_position?.length || 0) - (a.fiscal_position?.length || 0))?.[0]
    : grantDetail;
  const grantForIneligibility = isVirtualGrant
    ? opencallData
        ?.filter((a) => a.ineligibility && a.ineligibility.length > 0 && a.ineligibility[0].trim())
        .sort(
          (a, b) => (b.ineligibility?.[0]?.length || 0) - (a.ineligibility?.[0]?.length || 0),
        )?.[0]
    : grantDetail;
  const grantForApplicationRequirement = isVirtualGrant
    ? opencallData
        ?.filter(
          (a) =>
            a.requirement_application &&
            a.requirement_application.filter((r) => r.checked).length > 0,
        )
        .sort(
          (a, b) =>
            (b.requirement_application?.filter((r) => r.checked)?.length || 0) -
            (a.requirement_application?.filter((r) => r.checked)?.length || 0),
        )?.[0]
    : grantDetail;

  const grantForProposalRequirement = isVirtualGrant
    ? opencallData
        ?.filter(
          (a) =>
            a.requirement_proposal && a.requirement_proposal.filter((r) => r.checked).length > 0,
        )
        .sort(
          (a, b) =>
            (b.requirement_proposal?.filter((r) => r.checked)?.length || 0) -
            (a.requirement_proposal?.filter((r) => r.checked)?.length || 0),
        )?.[0]
    : grantDetail;
  const grantForReportingRequirement = isVirtualGrant
    ? opencallData
        ?.filter(
          (a) =>
            a.requirement_reporting &&
            a.requirement_reporting.length > 0 &&
            a.requirement_reporting[0].trim(),
        )
        .sort(
          (a, b) =>
            (b.requirement_reporting?.[0]?.length || 0) -
            (a.requirement_reporting?.[0]?.length || 0),
        )?.[0]
    : grantDetail;
  const opencallGrantAvailable = [
    isVirtualGrant && grantForEligibility ? true : false,
    isVirtualGrant && grantForIneligibility ? true : false,
    isVirtualGrant && grantForApplicationRequirement ? true : false,
    isVirtualGrant && grantForProposalRequirement ? true : false,
    isVirtualGrant && grantForReportingRequirement ? true : false,
  ];
  // STICKY NAV BAR END
  const renderHeader = () => {
    return (
      <div
        className={classNames(
          "loading-light-shimmer flex flex-col gap-3",
          isGrantLoading && "loading-light-shimmer-on",
        )}
      >
        <div className="flex items-center justify-between">
          {grant && (
            <div className="inline-flex items-center gap-2">
              <TagFunderType type={grant?.type} funder_type={grant.donor?.funder_type} />
              <div className="inline-flex items-center gap-1">
                <TagGrantPresent grant={grant} />
              </div>
              {selectedQuery &&
                selectedQuery.funder_assets.length > 0 &&
                selectedQuery.funder_assets.length < ASSET_SIZE_OPTION.length &&
                grant.donor && <TagFunderSize assets={grant.donor.assets} />}
            </div>
          )}
          <div className="inline-flex items-center gap-0">
            {hasEditPermission && grant?._id && grantDetail && (
              <>
                <div className="flex items-center gap-2 rounded bg-purple-50 px-3 py-1">
                  <span className="text-sm text-gray-700">{`score: `}</span>
                  <span className="font-semibold">{grantDetail.valid_score?.score || 0}</span>
                </div>
                <PlainButton
                  className="!m-0 min-w-[80px] !p-0 !text-sm font-semibold !text-blue-800 underline"
                  label="Duplicate"
                  handleOnClick={() => {
                    navigate("/admin/grants/new", {
                      state: {
                        copy: {
                          ...grantDetail,
                          focus_area: undefined,
                          beneficiary: undefined,
                          program: undefined,
                          FocusAreas: undefined,
                          Beneficiaries: undefined,
                          Programs: undefined,
                          GeographicalPreferences: undefined,
                          parsed: undefined,
                          title: undefined,
                          description: undefined,
                          grant_amount: undefined,
                          grant_type: undefined,
                          file_size: undefined,
                          file_key: undefined,
                          reviewed: undefined,
                          completed: false,
                          status: "not_checked",
                        },
                        fromPath: `${pathname}${search}`,
                      },
                    });
                  }}
                  leftIconClass="gi-sm gi-editor"
                  id={`btn-duplicate`}
                />
                <PlainButton
                  className="!m-0 min-w-[80px] !p-0 !text-sm font-semibold !text-red-800 underline"
                  label="Update this grant"
                  handleOnClick={() => {
                    navigate(`/admin/grants/edit/${grant._id}`, {
                      state: { fromPath: `${pathname}${search}` },
                    });
                  }}
                  leftIconClass="gi-sm gi-editor"
                  id={`btn-edit`}
                />
              </>
            )}
            {compact && (
              <PlainButton
                className="!m-0 min-w-[120px] !p-0 !text-sm !text-purple-500"
                label="Full view"
                handleOnClick={() => {
                  navigate(`/grants/${grant?._id}`, {
                    state: {
                      query: selectedQuery,
                      nameMatch: nameMatch || context?.nameMatch ? true : false,
                      gids: gids || context?.gids,
                      from_for_mixpanel: location.pathname.includes("/search")
                        ? "search"
                        : location.pathname.includes("/saved")
                        ? "saved_list"
                        : undefined,
                      showBack: true,
                    },
                  });
                }}
                leftIconClass="gi-sm gi-open"
                id={`btn-view-full`}
              />
            )}
          </div>
        </div>
        <div className="loading-light-shimmer-child whitespace-pre-line">
          <span className="inline-flex w-full text-xl font-semibold text-purple-500">
            <span className="min-w-[200px]">
              {getGrantTitle(grant)}
              {grantDetail?.website && (
                <a target="_blank" rel="noreferrer" href={grantDetail.website}>
                  <span className="inline-flex items-center whitespace-nowrap rounded-full p-1 text-sm font-normal hover:font-semibold">
                    <i className="gi-sm gi-open mx-2 text-xs leading-[30px] text-purple-500" />
                    <span className="cursor-pointer text-sm text-purple-500">Website</span>
                  </span>
                </a>
              )}
            </span>
          </span>
        </div>
        {process.env.REACT_APP_SHOW_GRANT_SUPPORTING_URL === "TRUE" ? (
          <div className="loading-light-shimmer-child flex flex-col whitespace-pre-line">
            <h6 className="text-gray-700">{`Supporting informations (${
              grantDetail?.supporting_urls_used?.length || 0
            })`}</h6>
            {grantDetail?.supporting_urls_used?.map((l, i) => (
              <a key={i} target="_blank" rel="noreferrer" href={l}>
                <h6 className="cursor-pointer">{l}</h6>
              </a>
            ))}
          </div>
        ) : null}
        {process.env.REACT_APP_ENV === "DEV" && grant ? (
          <div>
            <Button
              id={"btn-eligible-npos"}
              handleOnClick={() => {
                navigate(getNPOSearchURLFromGrant(grant, onboardingData));
              }}
              label="See eligible nonprofits"
              size="sm"
              outline
              underline
              color="red"
            />
          </div>
        ) : null}
        <div>
          <div className="flex items-center gap-2">
            <TagGrantEligible eligible={eligible} />
            {isVirtualGrant && funder && funder.does_not_accept_unsolicited !== true && (
              <TagInvitationOnly invitationOnly={false} />
            )}
          </div>
          <div className="mt-1 flex flex-col gap-6">
            {grant && getAgencyName(grant) ? (
              <div className="flex items-start gap-5 ">
                <span className="whitespace-pre-line text-base">
                  <span className="">{grant.donor_id ? "Funder: " : "Agency: "}</span>
                  <span
                    className={classNames(
                      "mr-1 font-semibold text-gray-900",
                      funder ? "cursor-pointer underline hover:text-purple-500" : "",
                    )}
                    onClick={() => {
                      if (grant.donor_id)
                        navigate(`/donors/${funder?._id}`, { state: { query: selectedQuery } });
                    }}
                  >
                    {getAgencyName(grant)}
                  </span>
                </span>
                {(virtualGrants && virtualGrants.length > 0) ||
                (opencallData && opencallData.length > 0) ? (
                  <div
                    className={classNames(
                      "flex h-[22px] w-fit cursor-pointer items-center gap-2 whitespace-nowrap rounded-full px-2 py-[2px] text-purple-500 underline hover:opacity-90 hover:shadow",
                      isVirtualGrant ? "bg-blue-50" : "bg-purple-50",
                    )}
                    id={
                      isVirtualGrant
                        ? "GA_TAG_click_clicktoviewothergrant"
                        : "GA_TAG_click_clicktoOpenCallgrant"
                    }
                    onClick={() => {
                      if (virtualGrants && virtualGrants.length > 0) {
                        navigate(`/grants/${virtualGrants[0]}`, {
                          state: { query: selectedQuery },
                        });
                      } else if (opencallData && opencallData.length > 0) {
                        navigate(`/grants/${opencallData[0]._id}`, {
                          state: { query: selectedQuery },
                        });
                      }
                    }}
                  >
                    {`Click to view ${isVirtualGrant ? "an open call" : "a virtual"} grant`}
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.36305 9.07445L12.8311 12.5415M0.852051 3.62445L2.52505 4.07345M1.56205 7.64945L2.78705 6.42545M3.78805 0.689453L4.23605 2.36145M7.81305 1.39945L6.58805 2.62345M7.26005 12.5345L4.79505 5.15245C4.76549 5.06427 4.76111 4.9696 4.7824 4.87907C4.80369 4.78853 4.8498 4.70573 4.91557 4.63997C4.98133 4.57421 5.06413 4.52809 5.15466 4.5068C5.2452 4.48551 5.33987 4.48989 5.42805 4.51945L12.8101 6.98245C12.9102 7.01603 12.9971 7.08044 13.0583 7.16645C13.1196 7.25245 13.152 7.35564 13.151 7.46122C13.15 7.5668 13.1156 7.66936 13.0527 7.75419C12.9899 7.83902 12.9018 7.90177 12.8011 7.93345L9.55605 8.95346C9.47917 8.97737 9.4092 9.0195 9.3521 9.07625C9.29499 9.13301 9.25244 9.20272 9.22805 9.27945L8.21005 12.5255C8.17861 12.6265 8.1159 12.715 8.03095 12.7782C7.946 12.8414 7.84319 12.876 7.73733 12.877C7.63147 12.878 7.52803 12.8453 7.4419 12.7838C7.35577 12.7222 7.29139 12.6349 7.25805 12.5345H7.26005Z"
                        stroke="#5C38A7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className={classNames("flex", fromProjectMgt && "hidden")}>
              <Button
                className={classNames("!h-9 !w-[224px] !px-4")}
                id="GA_TAG_click_startfundraising"
                loading={isCreating}
                handleOnClick={() => {
                  // if (projects.isFetched && projects.data.length === 0) {
                  //   id &&
                  //     newProject({
                  //       id: id,
                  //       grant: grant,
                  //       initialName: true,
                  //       query: query,
                  //       type: "GRANT",
                  //     });
                  //   return;
                  // }
                  withoutLogin
                    ? setSignUpSignin({ show: true })
                    : setShowFundraising((prev) => !prev);
                  mxEvent(MIXPANEL_EVENTS_V2.detail_view.button.clicked, {
                    button: Buttons.FUNDRAISING,
                    location: location.pathname,
                    targetType: isVirtualGrant ? "virtual_grant" : "grant",
                    targetName: grant?.name,
                    targetId: grant?._id,
                  });
                }}
                label="Start fundraising"
                appendIcon={
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.41406 5.5L13.4141 10.5L8.41406 15.5"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              />
              <Divider vertical className="mx-4 h-[37px]" />
              <RoundButton
                id={"GA_TAG_click_save"}
                handleOnClick={() => {
                  withoutLogin ? setSignUpSignin({ show: true }) : setShowSave(true);
                }}
                ariaValueText={id}
                ariaDescribedBy="in_detail_view"
                bordered={false}
                type="secondary"
                size="md"
                icon={
                  <i
                    className={
                      currentSavedList.length > 0
                        ? "gi-md gi-heart-full !ml-[-0.5px] text-purple-500"
                        : "gi-md gi-heart !ml-[-0.5px] text-purple-500"
                    }
                  />
                }
                tooltip={currentSavedList.length > 0 ? "Unsave" : "Save"}
              />
              <Divider vertical className="mx-4 h-[37px]" />
              <ShareButton
                isRoundButton
                bordered={false}
                initialUrl={`${window.location.origin}/grants/${id}`}
                onClick={() => {
                  mxEvent(MIXPANEL_EVENTS_V2.detail_view.button.clicked, {
                    button: Buttons.SHARE,
                    location: location.pathname,
                    targetType: isVirtualGrant ? "virtual_grant" : "grant",
                    targetName: grant?.name,
                    targetId: grant?._id,
                  });
                }}
              />
            </div>
          </div>
          {!nameMatch && !context?.nameMatch && selectedQuery && grant?._id ? (
            <>
              <Divider className={classNames("my-[30px]", fromProjectMgt)} />
              <ClickMeTooltip
                id="click-me-grant-why-match"
                position="right"
                open={withoutLogin}
                delay={3000}
              >
                <WhyMatched
                  {...(withoutLogin && { additionalComponent: <TryLetterCard /> })}
                  query={selectedQuery}
                  grant_id={grant?._id}
                  countedAreas={countedAreas}
                  funder_id={isVirtualGrant ? grant?.donor_id : undefined}
                  funder_uid={isVirtualGrant ? grant?.donor_uid : undefined}
                  type={isVirtualGrant ? "virtual" : "opencall"}
                  nameMatch={nameMatch || context?.nameMatch ? true : false}
                  gids={gids || context?.gids}
                  onClick={() => {
                    mxEvent(MIXPANEL_EVENTS_V2.detail_view.button.clicked, {
                      button: Buttons.WHY_MATCHED,
                      location: location.pathname,
                      targetType: isVirtualGrant ? "virtual_grant" : "grant",
                      targetName: grant?.name,
                      targetId: grant?._id,
                    });
                  }}
                />
              </ClickMeTooltip>
            </>
          ) : null}
        </div>
      </div>
    );
  };
  const renderSummary = () => {
    return (
      <div className="flex flex-col gap-3">
        {funder?.address && <>{renderRow("Address", funder.address)}</>}
        {renderRow(
          "Service areas",
          <ReprGrantServiceLocations
            grant={grant}
            detail={true}
            max={5}
            selected={false}
            scrollFunc={() => scrollTo("geograhpical-focus")}
            query={selectedQuery}
          />,
        )}
        {/* {renderRow("Location of eligible nonprofits", reprGrantEligibleNPOLocations(grant))} */}
        {renderRow("Grant type", reprGrantFundingType(grant))}
        {renderRow(
          "Grant amount",
          grant
            ? reprGrantAmount({
                ...grant,
                ...(grant?.donor && grant.type === "grant-page"
                  ? {
                      grant_amount_min: grant?.donor?.grant_amount_min,
                      grant_amount_max: grant?.donor?.grant_amount_max,
                      grant_amount_median: grant?.donor?.grant_amount_median,
                    }
                  : {}),
              })
            : "",
        )}
        {renderRow("Deadline", grant ? reprGrantDeadline(grant) || "" : "")}
      </div>
    );
  };

  const renderTaxonomy = () => {
    return (
      <>
        {isVirtualGrant && (
          <div className="inline-flex h-[27px] w-fit items-center gap-2 rounded bg-purple-50 px-2 py-[6px]">
            <span className="grid place-items-center">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.36305 9.57445L12.8311 13.0415M0.852051 4.12445L2.52505 4.57345M1.56205 8.14945L2.78705 6.92545M3.78805 1.18945L4.23605 2.86145M7.81305 1.89945L6.58805 3.12345M7.26005 13.0345L4.79505 5.65245C4.76549 5.56427 4.76111 5.4696 4.7824 5.37907C4.80369 5.28853 4.8498 5.20573 4.91557 5.13997C4.98133 5.07421 5.06413 5.02809 5.15466 5.0068C5.2452 4.98551 5.33987 4.98989 5.42805 5.01945L12.8101 7.48245C12.9102 7.51603 12.9971 7.58044 13.0583 7.66645C13.1196 7.75245 13.152 7.85564 13.151 7.96122C13.15 8.0668 13.1156 8.16936 13.0527 8.25419C12.9899 8.33902 12.9018 8.40177 12.8011 8.43345L9.55605 9.45346C9.47917 9.47737 9.4092 9.5195 9.3521 9.57625C9.29499 9.63301 9.25244 9.70272 9.22805 9.77945L8.21005 13.0255C8.17861 13.1265 8.1159 13.215 8.03095 13.2782C7.946 13.3414 7.84319 13.376 7.73733 13.377C7.63147 13.378 7.52803 13.3453 7.4419 13.2838C7.35577 13.2222 7.29139 13.1349 7.25805 13.0345H7.26005Z"
                  stroke="#5C38A7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span className="font-poppins text-[10px] text-purple-500">
              Click the tags below to view past grant information
            </span>
          </div>
        )}
        {!hasNoTaxonomy && (
          <PhilanthropicInformationFoldable
            className="!p-0"
            id={`matched-${grant?._id}`}
            title={`Matched tags`}
            globalOpen={true}
            arrowPosition="start"
            focusArea={matched?.focus_area}
            beneficiary={matched?.beneficiary}
            program={matched?.program}
            location={matched?.service_loc}
            priorityLocation={query?.must_service_loc}
            secondaryPriorityLocation={query?.service_loc}
            allowPastGrantSearch={grant?.donor_id && isVirtualGrant ? true : false}
            funder_id={grant?.donor_id}
            funder_uid={grant?.donor_uid}
            searchQuery={selectedQuery}
            showFundedOverview={isVirtualGrant}
          />
        )}
        {process.env.REACT_APP_DISABLE_VIEW_ALL_OTHER_KEYWORDS === "TRUE" ? null : (
          <PhilanthropicInformationFoldable
            className="!p-0"
            id={`unmatched-${grant?._id}`}
            title={`${!hasNoTaxonomy ? "Other tags" : "Tags"}`}
            initialShow={!hasNoTaxonomy ? undefined : 20}
            tagMatched={!hasNoTaxonomy ? false : true}
            arrowPosition="start"
            focusArea={unMatched?.focus_area}
            beneficiary={unMatched?.beneficiary}
            program={unMatched?.program}
            location={unMatched?.service_loc}
            priorityLocation={query?.must_service_loc}
            secondaryPriorityLocation={query?.service_loc}
            allowPastGrantSearch={grant?.donor_id && isVirtualGrant ? true : false}
            funder_id={grant?.donor_id}
            searchQuery={selectedQuery}
            enableGroupLocation
          />
        )}
        {grant?.donor_uid && grant?.donor_id && selectedQuery && isVirtualGrant && (
          <>
            <FundedAmount
              query={selectedQuery}
              funder_id={grant.donor_id}
              funder_uid={grant.donor_uid}
            />
          </>
        )}
      </>
    );
  };
  const renderAbout = () => {
    return (
      <div
        id="about"
        className={classNames(
          "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
          !compact ? "rounded border" : "border-t",
        )}
      >
        <h4>About</h4>
        {renderSummary()}
      </div>
    );
  };
  const renderTags = () => {
    return (
      <div
        id="tags"
        className={classNames(
          "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
          !compact && "rounded border",
        )}
      >
        <h4>Tags</h4>
        {renderTaxonomy()}
      </div>
    );
  };
  const renderAny = (v: any, depth = 0, renderNotSpecified = true) => {
    if (Array.isArray(v) && v.length > 0) {
      return (
        <div className="flex flex-col gap-3">
          <ul
            className={classNames(
              "flex list-inside flex-col gap-1 text-sm",
              depth === 0 ? "list-disc" : "list-square",
            )}
          >
            {v?.map((t, i) => (
              <li key={i} className="ml-8 -indent-5">
                {t}
              </li>
            ))}
          </ul>
        </div>
      );
    } else if (typeof v === "string") {
      return (
        <div className="flex flex-col gap-3">
          <ul
            className={classNames(
              "flex list-inside flex-col gap-1 text-sm",
              depth === 0 ? "list-disc" : "list-square",
            )}
          >
            <li className="ml-8 -indent-5">{v}</li>
          </ul>
        </div>
      );
    } else if (typeof v === "object" && v !== null && !Array.isArray(v)) {
      if (Object.keys(v).length === 0) return renderNotSpecified ? <h5>Not specified</h5> : <></>;
      return (
        <div className="flex flex-col gap-3">
          <ul
            className={classNames(
              "flex list-inside flex-col gap-1 text-sm",
              depth === 0 ? "list-disc" : "list-square",
            )}
          >
            {Object.keys(v).map((key, i) => {
              return (
                <li key={i} className="">
                  {`${key}:`}
                  <div className="ml-8 -indent-5">
                    {renderAny(v[key], depth + 1, renderNotSpecified)}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else if (v && !Array.isArray(v)) {
      try {
        return (
          <div className="flex flex-col gap-3">
            <ul
              className={classNames(
                "flex list-inside flex-col gap-1 text-sm",
                depth === 0 ? "list-disc" : "list-square",
              )}
            >
              <li>{v}</li>
            </ul>
          </div>
        );
      } catch {
        return renderNotSpecified ? <h5>Not specified</h5> : <></>;
      }
    } else {
      return renderNotSpecified ? <h5>Not specified</h5> : <></>;
    }
  };
  const renderLinkOpencall = (g: { title?: string; _id: string }) => {
    return (
      <PlainButton
        id={`btn-opencall-${g._id}`}
        handleOnClick={() => navigate(`/grants/${g._id}`)}
        label={`View ${g.title}`}
        color="purple"
        className="self-start !text-sm"
      />
    );
  };
  const renderEligibility = () => {
    const labels = {
      exemption: "Organizations are currently recognized by tax-exempt nonprofit organization.",
      sponsored: "Organizations receive support from a tax-exempt nonprofit organization",
      without: "Organizations do not have tax-exempt status.",
    };
    const transformed =
      grantForEligibility?.fiscal_position
        ?.filter((t) => t !== "others")
        .map((t) =>
          Object.keys(labels).includes(t) ? [labels[t as keyof typeof labels]] : t.split("\n"),
        )
        .reduce((prev, cur) => [...prev, ...cur], [])
        ?.filter(Boolean) || [];

    const transformed_grantee_type =
      grantForEligibility?.grantee_type
        ?.filter((t) => t !== "others")
        .map((t) =>
          Object.keys(granteeTypeLabels).includes(t)
            ? [granteeTypeLabels[t as keyof typeof granteeTypeLabels]]
            : t.split("\n"),
        )
        .reduce((prev, cur) => [...prev, ...cur], [])
        ?.filter(Boolean) || [];
    const transformed_grantee_type_npo =
      grantForEligibility?.grantee_type_npo
        ?.filter((t) => t !== "others")
        .map((t) =>
          Object.keys(granteeTypeLabels).includes(t)
            ? [granteeTypeLabels[t as keyof typeof granteeTypeLabels]]
            : t.split("\n"),
        )
        .reduce((prev, cur) => [...prev, ...cur], [])
        ?.filter(Boolean) || [];
    const transformed_grantee_type_ind =
      grantForEligibility?.grantee_type_ind
        ?.filter((t) => t !== "others")
        .map((t) =>
          Object.keys(granteeTypeLabels).includes(t)
            ? [granteeTypeLabels[t as keyof typeof granteeTypeLabels]]
            : t.split("\n"),
        )
        .reduce((prev, cur) => [...prev, ...cur], [])
        ?.filter(Boolean) || [];
    const npo_str = granteeTypeLabels["npo" as keyof typeof granteeTypeLabels];
    const ind_str = granteeTypeLabels["individual" as keyof typeof granteeTypeLabels];

    const financial_position_document =
      grantForEligibility?.financial_position_document
        ?.filter((t) => t !== "others")
        .map((t) =>
          Object.keys(financialPositionDocumentLabels).includes(t)
            ? [financialPositionDocumentLabels[t as keyof typeof financialPositionDocumentLabels]]
            : t.split("\n"),
        )
        .reduce((prev, cur) => [...prev, ...cur], [])
        ?.filter(Boolean) || [];
    const financial_position_requirement =
      grantForEligibility?.financial_position_requirement
        ?.filter((t) => t !== "others")
        .map((t) =>
          Object.keys(financialPositionRequirementLabels).includes(t)
            ? [
                financialPositionRequirementLabels[
                  t as keyof typeof financialPositionRequirementLabels
                ].reduce((prev: string, cur: string) => `${prev}: ${cur}`, ""),
              ]
            : t.split("\n"),
        )
        .reduce((prev, cur) => [...prev, ...cur], [])
        ?.filter(Boolean) || [];

    const positive_financial_position = grantForEligibility?.financial_position?.find(
      (t) => t === "positive",
    )
      ? true
      : false;
    return (
      <div className="flex flex-col gap-5">
        <h4>Eligibility</h4>
        <div
          className={classNames(
            "flex flex-col gap-1",
            isVirtualGrant &&
              grantForEligibility &&
              "rounded border border-gray-300 bg-gray-50 p-4",
          )}
        >
          {transformed.length > 0 ||
          transformed_grantee_type.length > 0 ||
          transformed_grantee_type_npo.length > 0 ||
          financial_position_document.length > 0 ||
          financial_position_requirement.length > 0 ||
          positive_financial_position ? (
            <ul className="flex list-inside list-disc flex-col gap-1 text-sm ">
              {transformed.map((t) => (
                <li key={t} className="ml-8 -indent-5">
                  {t}
                </li>
              ))}
              {transformed_grantee_type.length > 0 || transformed_grantee_type_npo.length > 0 ? (
                <li key="grantee_type" className="ml-8 -indent-5">
                  Eligible grantee type
                  <ul className="mt-1 flex list-inside list-square flex-col gap-1 text-sm">
                    {transformed_grantee_type_npo.length > 0 ||
                    transformed_grantee_type.includes(npo_str) ? (
                      <li key={"npo"} className="ml-6 -indent-5">
                        {npo_str}
                        {transformed_grantee_type_npo.length > 0 && (
                          <ul className="mt-1 flex list-inside list-disc flex-col gap-0 text-sm">
                            {transformed_grantee_type_npo.map((t) => (
                              <li key={t} className="ml-5 -indent-5">
                                {t}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ) : null}
                    {transformed_grantee_type_ind.length > 0 ||
                    transformed_grantee_type.includes(ind_str) ? (
                      <li key={"ind"} className="ml-6 -indent-5">
                        {ind_str}
                        {transformed_grantee_type_ind.length > 0 && (
                          <ul className="mt-1 flex list-inside list-disc flex-col gap-0 text-sm">
                            {transformed_grantee_type_ind.map((t) => (
                              <li key={t} className="ml-5 -indent-5">
                                {t}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ) : null}
                    {transformed_grantee_type
                      .filter((t) => t !== npo_str && t !== ind_str)
                      .map((t) => (
                        <li key={t} className="ml-5 -indent-5">
                          {t}
                        </li>
                      ))}
                  </ul>
                </li>
              ) : null}
              {financial_position_document.length > 0 ||
              financial_position_requirement.length > 0 ||
              positive_financial_position ? (
                <li key={"positive_financial_position"} className="ml-8 -indent-5">
                  {"Positive financial standing is required."}
                  {financial_position_document.length > 0 ||
                  financial_position_requirement.length > 0 ? (
                    <>
                      {" "}
                      {"The organization should provide:"}
                      <ul className="mt-1 flex list-inside list-square flex-col gap-0 text-sm">
                        {financial_position_requirement.map((t) => (
                          <li key={t} className="ml-5 -indent-5">
                            {t}
                          </li>
                        ))}
                        {financial_position_document.map((t) => (
                          <li key={t} className="ml-5 -indent-5">
                            {t}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : null}
                </li>
              ) : null}
            </ul>
          ) : (
            <h5>Not specified</h5>
          )}
          {/* {renderAny(
            grantDetail?.eligibility_detail,
            0,
            !grant || grant?.fiscal_position?.length === 0,
          )}
          {renderAny(grantDetail?.Eligibility, 0, !grant || grant?.fiscal_position?.length === 0)} */}
          {isVirtualGrant && grantForEligibility && <>{renderLinkOpencall(grantForEligibility)}</>}
        </div>
        {/* <div className="flex flex-col gap-3">
          <ul className="list-inside list-disc text-sm ">
            {granteeType?.map((t) => (
              <li key={t} className="">
                {granteeTypeLabels[t]}
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    );
  };
  const renderIneligibility = () => {
    return (
      <div className="flex flex-col gap-5">
        <h4>Ineligibility</h4>
        <div
          className={classNames(
            isVirtualGrant &&
              grantForIneligibility &&
              "rounded border border-gray-300 bg-gray-50 p-4",
          )}
        >
          {renderAny(
            grantForIneligibility?.ineligibility &&
              grantForIneligibility?.ineligibility.length > 0 &&
              grantForIneligibility?.ineligibility[0].trim()
              ? grantForIneligibility?.ineligibility.reduce(
                  (prev, cur) => [...prev, ...cur.split("\n")],
                  [] as string[],
                )
              : undefined,
          )}
          {isVirtualGrant && grantForIneligibility && (
            <>{renderLinkOpencall(grantForIneligibility)}</>
          )}
        </div>

        {/* <div className="flex flex-col gap-3">
          <ul className="list-inside list-disc text-sm ">
            {grantProgramType?.map((t) => (
              <li key={t} className="">
                {grantProgramTypeLabels[t]}
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    );
  };
  const renderRequrement = (
    id: string,
    label: { [key: string]: string },
    req?: Requirement[],
    g?: { title?: string; _id: string },
  ) => {
    return (
      <div
        className={classNames(
          "flex flex-col gap-3",
          isVirtualGrant && " rounded border border-gray-300 bg-gray-50 p-4",
        )}
      >
        {req && req.length > 0 ? (
          <ul className={classNames("flex list-inside flex-col gap-3 text-sm", "list-disc")}>
            {req.map((r) => (
              <li key={`${id}_${r.key}`} className={classNames("ml-8 -indent-5")}>
                <b>{label[r.key]}</b>
                {r.others.trim() && (
                  <div className="ml-6 mt-1 -indent-5">
                    <ul
                      className={classNames(
                        "flex list-inside flex-col gap-1 text-sm",
                        "list-square",
                      )}
                      style={{ listStyleType: "circle" }}
                    >
                      {r.others.split("\n").map((d, i) => (
                        <li key={`${id}_${r.key}_${i}`} className="ml-0 -indent-5">
                          {d}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <h5>Not specified</h5>
        )}
        {isVirtualGrant && g && <>{renderLinkOpencall(g)}</>}
      </div>
    );
  };
  const renderReportingRequirement = () => {
    return (
      <div className="flex flex-col gap-5">
        <h4>Reporting requirement</h4>
        <div
          className={classNames(isVirtualGrant && " rounded border border-gray-300 bg-gray-50 p-4")}
        >
          {renderAny(
            grantForReportingRequirement?.requirement_reporting &&
              grantForReportingRequirement?.requirement_reporting.length > 0 &&
              grantForReportingRequirement?.requirement_reporting[0].trim()
              ? grantForReportingRequirement?.requirement_reporting.reduce(
                  (prev, cur) => [...prev, ...cur.split("\n")],
                  [] as string[],
                )
              : undefined,
          )}
          {isVirtualGrant && grantForReportingRequirement && (
            <>{renderLinkOpencall(grantForReportingRequirement)}</>
          )}
        </div>
      </div>
    );
  };
  const renderNav = (isSticky = false) => {
    const renderItem = (label: string, value: NavItem, selectedDefault = false) => {
      return (
        <div
          id={`nav-${value}`}
          className={classNames(
            "h-[41px] cursor-pointer gap-3 whitespace-nowrap font-poppins text-sm",
            activeLink === value || selectedDefault
              ? "border-b-[2px]  border-purple-500  font-semibold text-purple-500"
              : " !font-normal !text-gray-700",
          )}
          onClick={() => {
            scrollTo(value);
          }}
        >
          {label}
        </div>
      );
    };
    const hasFocusArea = countedAreas?.FocusAreas?.length || 0;
    const hasBeneficiary = countedAreas?.Beneficiaries?.length || 0;
    const hasProgram = countedAreas?.Programs?.length || 0;
    const hasLocation = countedAreas?.GeographicalPreferences?.length || 0;
    const virtaulGrantNavItems: ReactNode[] = [
      renderItem("About", "about", !activeLink && !hasBeneficiary && !hasProgram),
      renderItem("Tags", "tags"),
      hasFocusArea &&
        renderItem(
          "Focus areas",
          "grant_focus_area",
          !activeLink && !hasBeneficiary && !hasProgram,
        ),
      hasBeneficiary > 0 &&
        renderItem(
          "Beneficiaries",
          "grant_beneficiary",
          !activeLink && !hasFocusArea && !hasProgram,
        ),
      hasProgram > 0 &&
        renderItem("Programs", "grant_programs", !activeLink && !hasBeneficiary && !hasFocusArea),
      hasLocation > 0 &&
        renderItem(
          "Service areas",
          "grant_geo_preferences",
          !activeLink && !hasFocusArea && !hasBeneficiary && !hasProgram,
        ),
      grantForEligibility && renderItem("Eligibility", "eligibility"),
      grantForIneligibility && renderItem("Ineligibility", "in_eligibility"),
      grantForApplicationRequirement && renderItem("Application requirement", "app_requirement"),
      grantForProposalRequirement && renderItem("Proposal requirement", "proposal_requirement"),
      grantForReportingRequirement && renderItem("Reporting requirement", "reporting_requirement"),
      grant?.donor_id &&
        renderItem(
          "About funder",
          "general_information",
          !activeLink && !hasFocusArea && !hasBeneficiary && !hasProgram,
        ),
      grant?.donor_id && renderItem("Past grant analysis", "past_recipients", false),
      renderItem("Contact information", "contact"),
    ].filter(Boolean);
    const grantNavItems: ReactNode[] = [
      renderItem("About", "about"),
      renderItem("Tags", "tags"),
      hasFocusArea &&
        renderItem(
          "Focus areas",
          "grant_focus_area",
          !activeLink && !hasBeneficiary && !hasProgram,
        ),
      hasBeneficiary > 0 &&
        renderItem(
          "Beneficiaries",
          "grant_beneficiary",
          !activeLink && !hasFocusArea && !hasProgram,
        ),
      hasProgram > 0 &&
        renderItem("Programs", "grant_programs", !activeLink && !hasBeneficiary && !hasFocusArea),
      hasLocation > 0 &&
        renderItem(
          "Service areas",
          "grant_geo_preferences",
          !activeLink && !hasFocusArea && !hasBeneficiary && !hasProgram,
        ),
      renderItem("Eligibility", "eligibility"),
      renderItem("Ineligibility", "in_eligibility"),
      renderItem("Application requirement", "app_requirement"),
      renderItem("Proposal requirement", "proposal_requirement"),
      renderItem("Reporting requirement", "reporting_requirement"),
      grant?.donor_id &&
        renderItem(
          "About funder",
          "general_information",
          !activeLink && !hasFocusArea && !hasBeneficiary && !hasProgram,
        ),
      grant?.donor_id && renderItem("Past recipients", "past_recipients", false),
      renderItem("Contact information", "contact"),
    ].filter(Boolean);
    return (
      <div
        className={classNames(
          "inline-flex h-full w-full items-center gap-4 overflow-x-scroll px-4 scrollbar-none",
        )}
      >
        <Slider
          slides={
            isGrantDetailLoading
              ? Array(8)
                  .fill("#")
                  .map((_, idx) => (
                    <Placeholder.Graph
                      key={idx}
                      active
                      height={30}
                      width={130}
                      className="rounded-full"
                    />
                  ))
              : isVirtualGrant
              ? virtaulGrantNavItems
              : grantNavItems
          }
          showFeatureIntro={false}
          wrapperClass={classNames("w-full", isGrantDetailLoading ? "my-2" : "mt-4")}
          leftArrowClass="!-left-2 !top-[10px]"
          rightArrowClass="!-right-2 !top-[10px]"
          hideArrow={!compact}
          iconColor="purple"
          targetId={`nav-${topElement}`}
        />
        {!compact && isSticky && withoutLogin && <TryLoiButton fromNav />}
        {/* {renderItem("Areas of interest", "area_of_interest", !activeLink && !activeElement)} */}
        {/* {renderItem("Eligibility", "eligibility")} */}
      </div>
    );
  };
  const renderGrayInfo = (message: string | ReactNode) => {
    if (!isVirtualGrant) return;
    return (
      <div className="inline-flex w-full  items-start gap-4 rounded border border-gray-300 bg-gray-50 p-4">
        <div>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.5453 10.4546C25.3976 10.3069 25.2118 10.2032 25.0085 10.1551C24.8053 10.107 24.5926 10.1165 24.3944 10.1824L14.2694 13.5574C14.1036 13.6127 13.9529 13.7058 13.8293 13.8294C13.7057 13.953 13.6126 14.1037 13.5573 14.2695L10.1823 24.3945C10.1162 24.5927 10.1066 24.8055 10.1546 25.0088C10.2026 25.2122 10.3063 25.3982 10.454 25.5459C10.6018 25.6937 10.7878 25.7974 10.9911 25.8454C11.1945 25.8933 11.4072 25.8838 11.6054 25.8176L21.7304 22.4426C21.8962 22.3874 22.0469 22.2942 22.1705 22.1706C22.2941 22.047 22.3873 21.8963 22.4425 21.7305L25.8175 11.6055C25.8835 11.4073 25.8929 11.1947 25.8448 10.9914C25.7968 10.7882 25.693 10.6023 25.5453 10.4546ZM13.0285 22.9725L15.5148 15.516L20.4862 20.4874L13.0285 22.9725Z"
              fill="#429BCA"
            />
            <path
              d="M18 33.75C14.885 33.75 11.8398 32.8263 9.24978 31.0957C6.6597 29.365 4.64098 26.9052 3.4489 24.0273C2.25682 21.1493 1.94492 17.9825 2.55264 14.9273C3.16036 11.8721 4.6604 9.06575 6.86307 6.86307C9.06575 4.6604 11.8721 3.16036 14.9273 2.55264C17.9825 1.94492 21.1493 2.25682 24.0273 3.4489C26.9052 4.64098 29.365 6.6597 31.0957 9.24978C32.8263 11.8398 33.75 14.885 33.75 18C33.7452 22.1757 32.0843 26.179 29.1317 29.1317C26.179 32.0843 22.1757 33.7452 18 33.75ZM18 4.50001C15.33 4.50001 12.7199 5.29177 10.4998 6.77517C8.27975 8.25857 6.54942 10.367 5.52763 12.8338C4.50585 15.3006 4.23851 18.015 4.75941 20.6337C5.28031 23.2525 6.56606 25.6579 8.45407 27.5459C10.3421 29.434 12.7475 30.7197 15.3663 31.2406C17.985 31.7615 20.6994 31.4942 23.1662 30.4724C25.633 29.4506 27.7414 27.7203 29.2248 25.5002C30.7082 23.2801 31.5 20.6701 31.5 18C31.4958 14.4209 30.0722 10.9895 27.5413 8.45867C25.0105 5.92783 21.5792 4.50418 18 4.50001Z"
              fill="#429BCA"
            />
          </svg>
        </div>
        <h6 className="text-xs  text-gray-800">{message}</h6>
      </div>
    );
  };
  const renderVirtualGrantInfo = () => {
    if (!isVirtualGrant || opencallGrantAvailable.filter(Boolean).length === 0) return;
    return (
      <>
        {compact && <Divider className={classNames("my-[20px]")} />}
        <div
          className={classNames(
            "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
            !compact && "rounded border",
          )}
          id={
            [
              "eligibility",
              "in_eligibility",
              "app_requirement",
              "proposal_requirement",
              "reporting_requirement",
            ].filter((s, i) => opencallGrantAvailable[i])[0]
          }
        >
          {renderGrayInfo(
            <span>
              {`Discover `}
              <span className="font-semibold">
                {prettyListStrings(
                  [
                    "eligibility",
                    "ineligibility",
                    "application requirement",
                    "grant proposal requirement",
                    "reporting requirement",
                  ].filter((_, i) => opencallGrantAvailable[i]),
                  false,
                  true,
                )}
              </span>
              {` details by referencing the latest open call grants. Please note that this information is solely for reference purposes and does not pertain to the specific criteria for this grant opportunity. The information below serves as a guide for understanding the broader context of grant requirements.`}
            </span>,
          )}
        </div>
      </>
    );
  };
  if (withoutLogin) {
    if (isRateLimitLoading)
      return (
        <div className="flex h-full w-full items-center justify-center">
          <FullScreenLoading />
        </div>
      );
  }
  return (
    <>
      {withoutLogin && ok && <SignInModal showDefault={ok} reset={resetRateLimit} />}
      {showFundraising && id && (
        <StartFundRaisingModal
          from_for_mixpanel={
            compact
              ? location.pathname.includes("/search")
                ? "search"
                : location.pathname.includes("/saved")
                ? "saved_list"
                : "detail_view"
              : context?.from_for_mixpanel
              ? context?.from_for_mixpanel
              : "detail_view"
          }
          query={selectedQuery}
          open={showFundraising}
          setOpen={setShowFundraising}
          type="GRANT"
          id={id}
          grant={grant}
        />
      )}
      {showSave && id && (
        <SavePopup
          open={showSave}
          onClose={() => setShowSave(false)}
          target_id={id}
          target_type={1}
          searchQuery={selectedQuery}
          compact={compact}
          isVirtualGrant={grant?.type === "grant-page"}
        />
      )}
      {showStickyNav && !compact && (
        <Portal>
          <div
            className={classNames(
              "fixed top-[58px]  z-[10] h-[61px] w-full items-end bg-white shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)]",
            )}
          >
            <div className="container mx-auto h-full max-w-[1275px] items-end">
              {renderNav(true)}
            </div>
          </div>
        </Portal>
      )}
      {showStickyNav && compact && stickyHeaderRef?.current && (
        <div
          style={{ top: `${stickyHeaderRef?.current?.getBoundingClientRect().height || 65}px` }}
          className={classNames(
            "sticky z-[9] min-h-[30px] w-full border-b border-gray-300 bg-white px-0 pb-0 transition-all ease-linear",
            showStickyNav ? "block" : "hidden",
          )}
        >
          {renderNav()}
        </div>
      )}
      {compact && (
        <div
          ref={stickyHeaderRef}
          className={classNames(
            "sticky top-0 z-[9] min-h-[30px] w-full border-b border-gray-300 bg-white px-5 py-3 transition-all ease-linear",
            showStickyNav ? "block" : "hidden",
          )}
        >
          <div className="inline-flex w-full items-center justify-between">
            <p className="info-name max-w-[600px] whitespace-pre-line py-2 font-poppins text-base font-semibold text-purple-500">
              {getGrantTitle(grant)}
            </p>
            <div className={classNames("flex gap-2", fromProjectMgt && "hidden")}>
              <RoundButton
                id={"GA_TAG_click_startfundraising"}
                handleOnClick={() => {
                  withoutLogin
                    ? setSignUpSignin({ show: true })
                    : setShowFundraising((prev) => !prev);
                  mxEvent(MIXPANEL_EVENTS_V2.detail_view.button.clicked, {
                    button: Buttons.FUNDRAISING,
                    location: location.pathname,
                    targetType: isVirtualGrant ? "virtual_grant" : "grant",
                    targetName: grant?.name,
                    targetId: grant?._id,
                  });
                }}
                size="md"
                icon={
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.41406 5.5L13.4141 10.5L8.41406 15.5"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                tooltip="Start fundraising"
              />
              <RoundButton
                id={"GA_TAG_click_save"}
                handleOnClick={() => {
                  withoutLogin ? setSignUpSignin({ show: true }) : setShowSave(true);
                }}
                ariaValueText={id}
                ariaDescribedBy="in_detail_view"
                type="secondary"
                size="md"
                icon={
                  <i
                    className={
                      currentSavedList.length > 0
                        ? "gi-md gi-heart-full !ml-[-0.5px] text-purple-500"
                        : "gi-md gi-heart !ml-[-0.5px] text-purple-500"
                    }
                  />
                }
                tooltip={currentSavedList.length > 0 ? "Unsave" : "Save"}
              />
              <ShareButton
                isRoundButton
                initialUrl={`${window.location.origin}/grants/${id}`}
                onClick={() => {
                  mxEvent(MIXPANEL_EVENTS_V2.detail_view.button.clicked, {
                    button: Buttons.SHARE,
                    location: location.pathname,
                    targetType: isVirtualGrant ? "virtual_grant" : "grant",
                    targetName: grant?.name,
                    targetId: grant?._id,
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className={classNames("container flex w-full flex-col", compact ? "" : "mt-[25px]")}>
        {/* back button */}
        {!compact && (
          <div className="w-full">
            <BackButton />
          </div>
        )}
        <div className="flex w-full gap-x-8 pb-2">
          <main className="w-full max-w-[800px] overflow-x-hidden overflow-y-hidden">
            {user && id && !grant ? (
              <div
                className="flex w-full flex-col items-center justify-center"
                style={{ height: "300px" }}
              >
                <div className="flex h-6 w-6 animate-spin items-center justify-center rounded-full bg-gradient-to-tr from-purple-500 to-transparent">
                  <div className="h-4 w-4 rounded-full bg-gray-50"></div>
                </div>
              </div>
            ) : (
              <div
                className={classNames(
                  "mx-auto flex w-full max-w-[800px] flex-col gap-5 ",
                  compact ? "first:gap-0" : "my-4",
                )}
              >
                <div
                  id="grant-header"
                  className={classNames(
                    "flex w-full flex-col   border-gray-300 bg-white px-0 pt-5",
                    compact ? "rounded " : "rounded border",
                  )}
                >
                  <div className={classNames("flex w-full flex-col px-5")}>{renderHeader()}</div>
                  <Divider className={classNames("mb-0 mt-[30px]")} />
                  {renderNav()}
                </div>
                {renderAbout()}
                {compact && <Divider className={classNames("my-[20px]")} />}
                {renderTags()}
                {/* {isGrantDetailLoading && compact && <Divider className={classNames("my-[20px]")} />} */}
                {!isGrantDetailLoading && grantDetail && countedAreas ? (
                  <div className={classNames("flex flex-col", !compact && "gap-5")}>
                    <GrantDescriptionDetail
                      grant={grantDetail}
                      funder_uid={grant?.donor_uid}
                      edit={edit}
                      query={selectedQuery}
                      countedAreas={countedAreas}
                      compact={compact}
                    />
                  </div>
                ) : (
                  <ChartLoader
                    wrapperClass={classNames(
                      " border-gray-300",
                      !compact ? "border bg-white rounded" : "!border-t",
                    )}
                  />
                )}

                {renderVirtualGrantInfo()}
                {grantForEligibility && (
                  <>
                    {compact && <Divider className={classNames("my-[20px]")} />}
                    <div
                      id="eligibility"
                      className={classNames(
                        "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
                        !compact && "rounded border",
                      )}
                    >
                      {renderEligibility()}
                    </div>
                  </>
                )}
                {grantForIneligibility && (
                  <>
                    {compact && <Divider className={classNames("my-[20px]")} />}
                    <div
                      id="in_eligibility"
                      className={classNames(
                        "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
                        !compact && "rounded border",
                      )}
                    >
                      {renderIneligibility()}
                    </div>
                  </>
                )}
                {grantForApplicationRequirement && (
                  <>
                    {compact && <Divider className={classNames("my-[20px]")} />}
                    <div
                      id="app_requirement"
                      className={classNames(
                        "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
                        !compact && "rounded border",
                      )}
                    >
                      <div className="flex flex-col gap-5">
                        <h4>Application requirement</h4>
                        {renderRequrement(
                          "application",
                          applicationRequirementLabels,
                          grantForApplicationRequirement?.requirement_application,
                          grantForApplicationRequirement,
                        )}
                      </div>
                    </div>
                  </>
                )}
                {grantForProposalRequirement && (
                  <>
                    {compact && <Divider className={classNames("my-[20px]")} />}
                    <div
                      id="proposal_requirement"
                      className={classNames(
                        "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
                        !compact && "rounded border",
                      )}
                    >
                      <div className="flex flex-col gap-5">
                        <h4>Grant proposal requirement</h4>
                        {renderRequrement(
                          "proposal",
                          proposalRequirementLabels,
                          grantForProposalRequirement?.requirement_proposal,
                          grantForProposalRequirement,
                        )}
                      </div>
                    </div>
                  </>
                )}
                {grantForReportingRequirement && (
                  <>
                    {compact && <Divider className={classNames("my-[20px]")} />}
                    <div
                      id="reporting_requirement"
                      className={classNames(
                        "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
                        !compact && "rounded border",
                      )}
                    >
                      {renderReportingRequirement()}
                    </div>
                  </>
                )}

                {grant?.donor_id ? (
                  <>
                    {compact && <Divider className={classNames("my-[20px]")} />}
                    <FunderSummary
                      id={"general_information"}
                      wrapperClass={classNames(
                        "w-full p-5 border-gray-300 bg-white",
                        !compact && "rounded border",
                      )}
                      funder_id={grant?.donor_id}
                      showButton
                    />
                  </>
                ) : null}
                {grant?.donor_id ? (
                  <>
                    {compact && <Divider className={classNames("my-[20px]")} />}
                    <div
                      className={classNames(
                        "flex w-full flex-col border-gray-300 bg-white p-5",
                        !compact && "rounded border",
                      )}
                      id="past_recipients"
                    >
                      <h4 className="font-poppins text-xl font-semibold text-gray-900">
                        Past grant analysis
                      </h4>
                      <Spacer height="20px" />
                      <div>
                        <div className="mb-5 border-b border-gray-300">
                          <h4 className="text-base font-semibold underline underline-offset-[6px]">
                            Past grant recipients of this funder
                          </h4>
                        </div>
                        <PastRecipients
                          id={`funder-past-recipient-${grant?.donor_id}`}
                          funder_id={grant?.donor_id}
                          funder_uid={grant?.donor_uid}
                          query={selectedQuery}
                        />
                      </div>
                      <Spacer height="40px" />
                      <div>
                        <div className="mb-5 border-b border-gray-300">
                          <h4 className="text-base font-semibold underline underline-offset-[6px]">
                            Funding stage analysis of past grant recipients
                          </h4>
                        </div>
                        <FunderStageAnalysisSimple funder_id={grant?.donor_id} />
                      </div>
                      <Spacer height="40px" />
                      <div>
                        <div className="mb-5 border-b border-gray-300">
                          <h4 className="text-base font-semibold underline underline-offset-[6px]">
                            Location of previous grant recipients
                          </h4>
                        </div>
                        {process.env.REACT_APP_DISABLE_MAP === "TRUE" ? null : (
                          <FunderGeographicFocus
                            funder_id={grant?.donor_id}
                            query={selectedQuery}
                            title="Past recipient location"
                            compact={compact}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
                {grant?.donor_id && (
                  <>
                    {compact && <Divider className={classNames("my-[20px]")} />}
                    <div id="contact">
                      <GrantContactWrapper
                        funder_id={grant?.donor_id}
                        grant={grantDetail}
                        compact={compact}
                        withoutLogin={withoutLogin}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </main>
          {!compact && (
            <aside className={classNames("my-4 flex h-fit w-full max-w-[384px] flex-col gap-10")}>
              {grant?._id &&
              recommendation &&
              recommendation.filter((g) => g._id !== grant?._id).length > 0 ? (
                <div className="flex flex-col rounded border border-gray-300 bg-white">
                  <div className="flex w-full items-center gap-2 border-b border-gray-300 px-5">
                    <p className="py-4">Other opportunities from this funder</p>
                    {recommendation.filter((g) => g._id !== grant?._id).length > 0 && (
                      <span className="min-w-4 grid h-fit place-items-center rounded bg-purple-500 px-1 text-[10px] !text-white">
                        {recommendation.filter((g) => g._id !== grant?._id).length}
                      </span>
                    )}
                  </div>
                  <div className={classNames("flex max-h-[1200px] flex-col gap-2", scrollBarClass)}>
                    <RecGrantsFromSameFunder
                      keyId={useUloc ? grant?.uloc : grant?.donor_id}
                      useUloc={useUloc}
                      grantType={grant?.type}
                      exclude={[grant._id]}
                    />
                  </div>
                </div>
              ) : null}
              <div className="flex flex-col rounded border border-gray-300 bg-white">
                <div className="flex w-full items-center gap-2 border-b border-gray-300 px-5">
                  <p className="py-4">Similar to this grant</p>
                  <span className="min-w-4 grid h-fit place-items-center rounded bg-purple-500 px-1 text-[10px] !text-white">
                    20
                  </span>
                </div>
                <div className={classNames("flex max-h-[1200px] flex-col gap-2", scrollBarClass)}>
                  <RecGrants hasInitialData={false} recommendationQuery={selectedQuery} />
                </div>
              </div>
            </aside>
          )}
        </div>
      </div>
    </>
  );
};

export default GrantView;
