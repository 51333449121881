import classNames from "classnames";
import { memo } from "react";
import { sidekickScrollTargetMoreSearchOption } from "../app/recoilStore";
import { useSetRecoilState } from "recoil";

function TagPreviousFunder() {
  const enableClick = document.getElementById("GA_TAG_click_moresearchoption") ? true : false;
  const setSidekickScrollTargetMoreSearchOption = useSetRecoilState(
    sidekickScrollTargetMoreSearchOption,
  );
  return (
    <div
      className={classNames(
        "flex h-6 items-center gap-1 rounded-[20px] border border-purple-500 bg-purple-50 px-2 text-purple-500",
        enableClick ? "cursor-pointer hover:bg-purple-100" : "",
      )}
      {...(enableClick
        ? {
            onClick: (e) => {
              e.stopPropagation();
              setSidekickScrollTargetMoreSearchOption("previousFunders");
              const element = document.getElementById("GA_TAG_click_moresearchoption");
              element?.click();
            },
          }
        : {})}
    >
      <i className="gi-md gi-refresh-dot" />
      <h6>Previous funder</h6>
    </div>
  );
}
const MemoizedTagPreviousFunder = memo(TagPreviousFunder);
export default MemoizedTagPreviousFunder;
