import { useCallback } from "react";
import { IProject } from "../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../api";
interface ProjectTasksState {
  data: IProject[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IProject[] | undefined>;
}
function useOrgProjects(org_id?: string): ProjectTasksState {
  // this useOrgProjects fetchs only private=false and completed=true
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects?org_id=${org_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProject[]>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => res.data as IProject[])
      .then((res) =>
        res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
      );
  };
  const { data, isLoading, error, isValidating } = useSWR<IProject[]>(org_id ? url : null, fetch, {
    keepPreviousData: false,
  });
  const revalidate = useCallback(() => mutate<IProject[]>(url), [url]);
  return {
    data: org_id ? data || [] : [],
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useOrgProjects;
