import classNames from "classnames";
import * as React from "react";
import Spinner from "../Spinner";
import { Dispatch, SetStateAction } from "react";
import { Toggle } from "rsuite";

interface ToggleInputProps {
  id: string;
  // className?: string;
  label?: string;
  itemClassName?: string;
  labelClass?: string;
  size?: "lg" | "md" | "sm" | "xs";
  color?: "purple" | "red" | "yellow" | "green" | "blue" | "gray";
  style?: React.CSSProperties;
  disabled?: boolean;
  onChange: (val: boolean) => void;
  isChecked: boolean;
}

function ToggleInput({
  color,
  id,
  size = "sm",
  isChecked,
  itemClassName,
  label,
  labelClass,
  ...props
}: ToggleInputProps) {
  return (
    <div className={classNames("flex cursor-pointer items-center gap-2", itemClassName)} key={id}>
      {label && (
        <label
          htmlFor={id}
          className={classNames(
            "cursor-pointer whitespace-nowrap text-xs",
            props.disabled ? "text-gray-400" : "labelClass",
            labelClass,
          )}
          onClick={(e) => {
            props.onChange(!isChecked);
          }}
        >
          <h5 className="w-full truncate text-ellipsis">{label}</h5>
        </label>
      )}
      <Toggle
        id={id}
        size="sm"
        checked={isChecked}
        disabled={props.disabled}
        onChange={(val) => props.onChange(val)}
        className="cursor-pointer !accent-purple-500 !outline-none !ring-transparent"
      />
    </div>
  );
}

ToggleInput.defaultProps = {
  color: "purple",
};

export default ToggleInput;
