import React from "react";
import IMG_RECOM from "../assets/financial/recommendation.svg";

export default function FinancialDiversityRecommendation({
  title,
  recommendation,
}: {
  title: string;
  recommendation: string;
}) {
  const renderRecommendations = ({
    title,
    recommendation,
  }: {
    title: string;
    recommendation: string;
  }) => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">{title}</p>
        <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
          <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
            <span>
              <img src={IMG_RECOM} className="h-fit w-fit" />
            </span>
            <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
          </div>
          <div className="font-poppins text-sm">{recommendation || ""}</div>
        </div>
      </div>
    );
  };
  return <>{renderRecommendations({ title: title, recommendation: recommendation })}</>;
}
