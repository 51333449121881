import classNames from "classnames";
import React from "react";
import "./css/icon.scss";

interface LabelProps {
  className?: string;
  id?: string;
  name: string;
  outline?: boolean;
  outlineColor?: boolean;
  focused?: boolean;
  recommended?: boolean;
  active?: boolean;
  color?: "purple" | "red" | "yellow" | "green" | "blue" | "gray";
  size?: "lg" | "md" | "sm";
  number?: number;
  onAdd?: () => void;
  onDelete?: () => void;
  leftIconClass?: string;
  rightIconClass?: string;
  opacity?: number;
  onClick?: () => void;
  disabled?: boolean;
}

function Label({
  className,
  id,
  name,
  outline = false,
  outlineColor = false,
  active = false,
  focused = false,
  recommended = false,
  color = "gray",
  size = "md",
  onDelete,
  onAdd,
  leftIconClass,
  rightIconClass,
  number,
  onClick = undefined,
  disabled,
}: LabelProps) {
  const focusedColorVariants = {
    purple: "bg-purple-200 border-purple-500",
    red: "bg-red-200 border-red-500",
    yellow: "bg-yellow-200 border-yellow-500",
    green: "bg-green-200 border-green-500",
    blue: "bg-blue-200 border-blue-500",
    gray: "bg-gray-300 border-gray-500",
  };
  const colorVariants = {
    purple: "bg-purple-50 border-purple-500",
    red: "bg-red-50 border-red-500",
    yellow: "bg-yellow-50 border-yellow-500",
    green: "bg-green-50 border-green-500",
    blue: "bg-blue-50 border-blue-500",
    gray: "bg-gray-200 border-gray-500",
  };
  const hoverColorVariants = {
    purple: "hover:border-purple-900",
    red: "hover:border-red-900",
    yellow: "hover:border-yellow-900",
    green: "hover:border-green-900",
    blue: "hover:border-blue-900",
    gray: "hover:border-gray-900",
  };
  const outlineColorVariants = {
    purple: "bg-transparent border-purple-500",
    red: "bg-transparent border-red-500",
    yellow: "bg-transparent border-yellow-500",
    green: "bg-transparent border-green-500",
    blue: "bg-transparent border-blue-500",
    gray: "bg-transparent border-gray-500",
  };
  const sizeVariants = {
    sm: "!min-h-[24px]",
    md: "!min-h-[32px]",
    lg: "!min-h-[48px]",
  };
  return (
    <div
      id={id}
      className={classNames(
        "flex max-w-full items-center gap-1 rounded px-2 py-0.5 leading-6 text-gray-800",
        {
          "border border-solid": outline,
          "opacity-40": disabled,
          "animate-bounce": recommended,
          "cursor-pointer": onClick,
        },
        active
          ? focused
            ? focusedColorVariants[color]
            : colorVariants[color]
          : outlineColor
          ? outlineColorVariants[color]
          : "border-gray-300 bg-transparent",
        // active
        //   ? `bg-${color}-${color === "gray" ? "100" : "50"} border-${color}-500`
        //   : "border-gray-300 bg-transparent",
        onClick ? hoverColorVariants[color] : "",
        sizeVariants[size],
        className,
      )}
      onClick={() => {
        onClick?.();
      }}
    >
      {leftIconClass && <img className={classNames("icon", leftIconClass)} />}
      <span
        className={classNames("w-full grow overflow-hidden text-ellipsis whitespace-normal", {
          "font-semibold": focused,
        })}
      >
        {name}
      </span>
      {onDelete ? (
        <div className="grow-0">
          <button
            id={`btn-delete-label-${id}`}
            className="border-0 bg-transparent text-center text-inherit no-underline"
            onClick={onDelete}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
      ) : onAdd ? (
        <div className="grow-0">
          <button
            id={`btn-add-label-${id}`}
            className="border-0 bg-transparent text-center text-inherit no-underline"
            onClick={onAdd}
          >
            <i className="fa-regular fa-plus"></i>
          </button>
        </div>
      ) : null}
      {number && number > 0 ? <span className="grow-0 font-bold">{`${number}+`}</span> : null}
      {rightIconClass && <i className={classNames("icon", rightIconClass)} />}
    </div>
  );
}

export default Label;
