import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import useNPOIRSProfile, { MonthOfLiquid } from "../../../../hooks/useNPOIRSProfile";
import NoGraph from "../../../donor-detail-view/components/NoGraph";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import { Divider } from "rsuite";
import IncreaseTag from "../../financials/component/IncreaseTag";
import Spacer from "../../../../components/Spacer";
import FinancialChartLoader from "../../financials/component/FinancialChartLoader";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import DecreaseTag from "../../financials/component/DecreaseTag";
import PercentageTag from "../../../../components/PercentageTag";
import PredictionIcon from "../../../../assets/financial/predictionIcon.svg";
import AssessmentResult from "../../../../components/AssessmentResult";
import PopupModal from "../../../../components/tailwind/PopupModal";
import { MonthLiquidInfo } from "../../financials/pages/MonthOfLiquid";
import { NpoTitle } from "./NpoDiversityEthnicity";
import FinancialPredictionInfo from "./FinancialPredictionInfo";
import ContentHideWrapper from "../../../../components/ContentHideWrapper";
const COLOR_MAP = ["#F27059", "#FBD1A2", "#7DCFB6", "#00B2CA", "#FBD1A2"];
interface Iprops {
  npo_id?: string;
  org_id?: string;
  allowEdit?: boolean;
  isPrivateView?: boolean;
  compact?: boolean;
  recomendation?: boolean;
  showStats?: boolean;
  showInfo?: boolean;
  hideTitle?: boolean;
  children?: ReactNode;
  showChartLoader?: boolean;
  withoutLogin?: boolean;
  isProfileClaim?: boolean;
}

function NpoFinancialMonthLiquid({
  npo_id,
  org_id,
  allowEdit,
  isPrivateView,
  compact,
  recomendation,
  showInfo = true,
  showStats = true,
  hideTitle = false,
  showChartLoader = true,
  children,
  withoutLogin,
  isProfileClaim,
}: Iprops) {
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { monthOfLiquid, revenueStreamInfo, isLoading, beforeLatest, latest } =
    useNPOIRSProfile(npo_id);
  const { data: luna, isLoading: isLunaLoading } = useFinancialRecommendedActions(
    !isPrivateView && loading ? undefined : !isPrivateView && !org_id ? npo_id : org_id,
    "luna",
    !isPrivateView && !org_id,
  );
  const renderMonthOfLiq = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Month of liquid</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={revenueStreamInfo?.diff_months_of_cash}
            beforeLatest={beforeLatest?.months_of_cash}
            beforeLatestYear={beforeLatest?.tax_year}
            latest={latest?.months_of_cash}
            latestYear={latest?.tax_year}
            isDollar={false}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {luna?.Months_of_Liquid?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              {luna?.Months_of_Liquid?.recommendations || ""}
            </div>
          </div>
        )}
      </div>
    );
  };
  const chartLoading = recomendation ? loading : isLoading;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-5"
        showHeader={false}
        showActionButtons={false}
      >
        <MonthLiquidInfo
          handleClose={() => {
            setShowInfoModal(false);
          }}
        />
      </PopupModal>
      <div
        className={classNames(
          "flex min-h-[100px] flex-col ",
          recomendation && "rounded border border-gray-300 bg-white p-5",
        )}
      >
        {!hideTitle && (
          <NpoTitle
            title="Working capital: Month of liquid"
            recomendation={recomendation}
            setShowInfoModal={setShowInfoModal}
          />
        )}
        {showChartLoader && chartLoading ? (
          <FinancialChartLoader title="Analyzing organization’s Month of liquid" />
        ) : (
          <>
            {/* chart */}
            <div className="mx-auto my-5">
              <MonthOfLiquidChart
                monthOfLiquid={monthOfLiquid}
                npo_id={npo_id}
                wrapperClass={""}
                compact
              />
            </div>
            <ContentHideWrapper
              id="month-of-liquid"
              open={withoutLogin && isProfileClaim}
              messageTitle="Ensure your nonprofit's success with Giboo's financial assessment tool. "
              messageDescription="Get key insights and strategies to secure sustainable funding—try it now for free!"
            >
              {/* info */}
              {showInfo &&
                (isLunaLoading || loading ? (
                  <MemoizedLoadingRecommendedAction loading={loading || isLunaLoading} short />
                ) : (
                  <AssessmentResult content={luna?.Summary || ""} />
                ))}
              {recomendation ? <Divider className="my-[40px]" /> : <Spacer height="20px" />}
              {/* stats */}
              {showStats &&
                (isLunaLoading || loading ? (
                  <MemoizedLoadingRecommendedAction loading={loading || isLunaLoading} />
                ) : (
                  <div className="bg-gray-50 px-6 py-5">
                    <h6 className="mb-5 font-poppins text-base font-semibold text-black">Stats</h6>
                    <div className="flex flex-col gap-10">{renderMonthOfLiq()}</div>
                  </div>
                ))}
            </ContentHideWrapper>
          </>
        )}
      </div>
      {!chartLoading && children && children}
    </>
  );
}
export default NpoFinancialMonthLiquid;

export function MonthOfLiquidChart({
  npo_id,
  wrapperClass,
  compact,
  hideInfo = false,
  monthOfLiquid,
}: {
  monthOfLiquid: MonthOfLiquid[];
  npo_id?: string;
  wrapperClass?: string;
  compact?: boolean;
  hideInfo?: boolean;
}) {
  const valid = monthOfLiquid?.filter((i) => !i.is_interpolated && !i.is_predicted) || [];
  const averageMonthOfLiquid =
    valid?.length > 0
      ? valid?.reduce((sum, entry) => sum + entry.months_of_cash, 0) / valid.length
      : 0;
  const getColorCode = (value: number) => {
    if (value < 3.0) {
      return "#FF8C84";
    } else if (value >= 3.0 && value <= 5.9) {
      return "#FFE34D";
    } else if (value >= 6.0 && value <= 8.9) {
      return "#9BE9BA";
    } else {
      return "#4DBFFF";
    }
  };
  const estimatedData = monthOfLiquid?.filter((i) => i.is_interpolated);
  const predictedData = monthOfLiquid?.filter((i) => i.is_predicted);

  const renderHeader = () => {
    return (
      <div className=" inline-flex flex-wrap items-center gap-5 md:flex-nowrap">
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#FF8C84",
            }}
          />
          <p className="font-poppins text-xs text-black">{`<2.9`}</p>
        </div>
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#FFE34D",
            }}
          />
          <p className="font-poppins text-xs text-black">3.0-5.9</p>
        </div>
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#9BE9BA",
            }}
          />
          <p className="font-poppins text-xs text-black">6.0-8.9</p>
        </div>
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#4DBFFF",
            }}
          />
          <p className="font-poppins text-xs text-black">{">9.0"}</p>
        </div>
      </div>
    );
  };
  const renderChart = () => {
    const baseWidth = 400;
    const baseSizePercentage = 0.28;
    const maxMonthsOfCash = Math.max(...monthOfLiquid.map((t) => t.months_of_cash));
    return (
      <div className="grid w-full max-w-[628px] place-items-center items-start">
        <div className="flex justify-center">{renderHeader()}</div>
        <div className="inline-flex h-[150px] w-[495px] items-center justify-center">
          {monthOfLiquid.map((t, index) => {
            const value = parseFloat(t.months_of_cash.toFixed(1));
            const isPredicted = t.is_predicted;
            const isInterpolated = t.is_interpolated;
            const sizePercentage = (value / maxMonthsOfCash) * baseSizePercentage;
            const size = baseWidth * sizePercentage;
            return (
              <div key={index} className={classNames("flex flex-col items-center gap-4")}>
                <div
                  style={{
                    height: `${size}px`,
                    width: `${size}px`,
                    minHeight: `${80}px`,
                    minWidth: `${80}px`,
                    backgroundColor: isPredicted ? "transparent" : getColorCode(value),
                    border: isInterpolated
                      ? `1px solid #5C38A7`
                      : isPredicted
                      ? `1px dashed ${getColorCode(value)}`
                      : "none",
                  }}
                  className="grid place-items-center rounded-full p-1"
                >
                  <div className="text-center">
                    <div className="inline-flex items-center gap-1">
                      <p className="font-poppins text-sm text-gray-800">{t.tax_year}</p>
                      {t.is_predicted && (
                        <span className="">
                          <i className="gi gi-predicted text-purple-500" />
                        </span>
                      )}
                      {t.is_interpolated && (
                        <span className="">
                          <i className="gi gi-estimated text-purple-500" />
                        </span>
                      )}
                    </div>

                    <p className="font-poppins text-base font-semibold text-gray-800">{value}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mx-auto flex flex-col justify-center gap-4">
          <p className="text-xs font-semibold text-black">Month of liquid</p>
          <div className="flex flex-col gap-1">
            <p className="font-poppins text-xs text-gray-700">Average of</p>
            <h4 className="font-poppins text-2xl text-black">
              {averageMonthOfLiquid.toFixed(1)} months
            </h4>
          </div>
        </div>
      </div>
    );
  };
  if (monthOfLiquid.length === 0) return <NoGraph />;
  return (
    <div
      className={classNames(
        "flex min-h-[280px] w-full flex-wrap items-center justify-center gap-4",
        wrapperClass,
      )}
    >
      {renderChart()}
      <div>
        <div className="flex items-center  justify-center gap-4">
          {estimatedData && estimatedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-purple-100 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-estimated text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Estimated years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {estimatedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
          {predictedData && predictedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-dashed border-purple-500 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-predicted text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Predicted years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {predictedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <FinancialPredictionInfo />
        </div>
      </div>

      {/* {hideInfo === false && renderInfo()} */}
    </div>
  );
}
