import classNames from "classnames";
import { ReactNode, memo, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useFunderTags from "../../hooks/funder/useFunderTags";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";
import useOnboardingData from "../../hooks/useOnboarding";
import NoInformation from "../../pages/donor-detail-view/components/NoInformation";
import no_info from "../../pages/img/geographic-no-info.svg";
import { Philanthropy } from "../../types/philanthropy";
import { SearchQuery } from "../../types/search";
import { matchTaxonomyBasedProfile } from "../../types/taxonomy";
import { prettierTaxonomy } from "../../utils/philanthropy";
import ChartLoader from "../ChartLoader";
import PhilanFocusHBar from "../dashboard/PhilanFocusHBar";
import MemoizedFunderWordCloud from "./FunderWordCloud";
function funderTaxonomy({
  funder_id,
  query,
  compact,
  additionalComponent,
  ...props
}: {
  funder_id: string;
  query: SearchQuery;
  wrapperClass?: string;
  compact?: boolean;
  additionalComponent?: ReactNode;
}) {
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const { data: funder, isLoading: isFunderLoading } = useFunderSearchResult(funder_id);
  const { data: profile, isLoading: isFunderProfileLoading } = useFunderTags(funder_id);
  // const setMatchedFunc = () =>
  //   profile && query
  //     ? prettierTaxonomy(matchTaxonomyBasedProfile(profile, query, true))
  //     : profile
  //     ? prettierTaxonomy(profile)
  //     : undefined;
  // const [matched, setMatched] = useState<Philanthropy | undefined>(profile);
  const matched = useMemo<Philanthropy | undefined>(
    () =>
      profile && query
        ? prettierTaxonomy(matchTaxonomyBasedProfile(profile, query, true))
        : profile
        ? prettierTaxonomy(profile)
        : undefined,
    [profile, query],
  );
  const isLoading = isFunderLoading || isFunderProfileLoading;
  // useEffect(() => {
  //   setMatched(setMatchedFunc());
  // }, [profile, query]);

  if (isLoading) {
    return <ChartLoader />;
  }
  const hasNoTaxonomy =
    query.focus_area.length === 0 &&
    query.beneficiary.length === 0 &&
    query.program.length === 0 &&
    query.hq_loc.length === 0 &&
    query.service_loc.length === 0;
  return (
    <>
      {matched ? (
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <div className="border-b border-gray-300 ">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">
                Words relevant to funder
              </h5>
            </div>
            {process.env.REACT_APP_DISABLE_WORD_CLOUD === "TRUE" ? null : (
              <MemoizedFunderWordCloud funder_id={funder_id} query={query} />
            )}
          </div>
          <div className="flex flex-col gap-5" id="chart_view" aria-valuetext="focus-area">
            <div className="border-b border-gray-300 ">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">
                Focus area
              </h5>
            </div>
            <PhilanFocusHBar
              funder={funder}
              query={query}
              philanthropy={matched}
              selectedTab={0}
              compact={compact}
              tagMatched={hasNoTaxonomy ? true : undefined}
            />
            {funder?.grant_year_min && funder.grant_year_max && (
              <h6 className={classNames("mt-5 self-end text-xs text-gray-700")}>
                {`Year of data: ${funder?.grant_year_min} - ${funder?.grant_year_max} `}
              </h6>
            )}
          </div>
          <div className="flex flex-col gap-5" id="chart_view" aria-valuetext="beneficiary">
            <div className="border-b border-gray-300 ">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">
                Beneficiary
              </h5>
            </div>
            <PhilanFocusHBar
              funder={funder}
              query={query}
              philanthropy={matched}
              selectedTab={1}
              compact={compact}
              tagMatched={hasNoTaxonomy ? true : undefined}
            />
            {funder?.grant_year_min && funder.grant_year_max && (
              <h6 className={classNames("mt-5 self-end text-xs text-gray-700")}>
                {`Year of data: ${funder?.grant_year_min} - ${funder?.grant_year_max} `}
              </h6>
            )}
          </div>
          <div className="flex flex-col gap-5" id="chart_view" aria-valuetext="program">
            <div className="border-b border-gray-300 ">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">Program</h5>
            </div>
            <PhilanFocusHBar
              funder={funder}
              query={query}
              philanthropy={matched}
              selectedTab={2}
              compact={compact}
              tagMatched={hasNoTaxonomy ? true : undefined}
            />
            {funder?.grant_year_min && funder.grant_year_max && (
              <h6 className={classNames("mt-5 self-end text-xs text-gray-700")}>
                {`Year of data: ${funder?.grant_year_min} - ${funder?.grant_year_max} `}
              </h6>
            )}
          </div>
          {additionalComponent}
        </div>
      ) : (
        <NoInformation
          img={no_info}
          content={
            <div className="-mt-2 flex flex-col items-center">
              {/* <h5 className="text-sm text-gray-600">
                You can{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={() => navigate(`/organization/${onboardingData._id}/`)}
                >
                  click to add more tags
                </span>{" "}
                to get better matches!
              </h5> */}
            </div>
          }
        />
      )}
    </>
  );
}
const MemoizedFunderTaxonomy = memo(funderTaxonomy);
export default MemoizedFunderTaxonomy;
