import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import Button from "../components/tailwind/Button";
import IMAGE_ROBOT from "../assets/images/robot.svg";
import logo from "../pages/img/logo.svg";
import usePageTitle from "../hooks/usePagetitle";
const SUPPORT_EMAIL = "support@giboo.com";
function NoPage() {
  usePageTitle("Not found");
  const navigate = useNavigate();
  const [user] = useUser();
  // useEffect(() => {
  //   if (user?._id) navigate("/");
  // }, [user]);
  const renderHeader = () => {
    return (
      <div className="fixed top-0 z-40  w-screen bg-white shadow-sm ">
        <nav className="container mx-auto flex !h-[58px] w-full  max-w-[1280px] items-center  justify-between">
          <div className="flex items-center gap-[22px]">
            <a href="/">
              <div className="flex items-center">
                <img src={logo} alt="Giboo" width="51" height="31" />
                <span className="logo-label">Giboo</span>
              </div>
            </a>
          </div>
        </nav>
      </div>
    );
  };
  return (
    <>
      {/* {renderHeader()} */}
      <div className="grid place-items-center" style={{ height: "calc(100vh - 100px)" }}>
        <div className="grid h-fit w-full place-items-center gap-10">
          <img src={IMAGE_ROBOT} />
          <div className="grid place-items-center">
            <h4 className="font-poppins text-[24px] text-black">Page not found</h4>
            <p className="mt-4 text-center font-poppins text-sm">
              Sorry but we could not find the page requested.
              <br />
              If you cannot find what you are looking for, please{" "}
              <span
                className="cursor-pointer underline"
                onClick={() => {
                  window.open(`mailto:${SUPPORT_EMAIL}`, "_blank");
                }}
              >
                contact us.
              </span>
            </p>
          </div>
          <Button
            className="!h-[37px] w-fit !text-sm"
            size="md"
            handleOnClick={() => {
              navigate("/");
            }}
            id="btn-back"
            label={"Back to home"}
          />
        </div>
      </div>
    </>
  );
}

export default NoPage;
