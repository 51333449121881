import axios from "axios";
import config from "../api";
import { ILocation } from "../types/location";

const findCloseLocations = (center: ILocation, distance = 20000, limit = 10, level = -1) => {
  return new Promise<ILocation[]>((resolve, reject) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/v2/geocode/spatial?lat=${center.lat}&lng=${center.lng}&distance=${distance}&limit=${limit}&lavel=${level}`,
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export { findCloseLocations };
