import { CSSProperties, MutableRefObject, memo, useEffect, useRef, useState } from "react";
import classNames from "classnames";

interface IMessage {
  show: boolean;
  text: string;
  type: string;
  prevTimer: NodeJS.Timeout | undefined;
}
interface DropAreaProps {
  id: string;
  className?: string;
  formats: string[];
  sizeLimit?: number;
  onDrop: (arg: File) => void;
  style?: CSSProperties;
  defaultValue?: string;
  disabled?: boolean;
  topLabel?: string;
  wrapperClass?: string;
  resetDefaultFile?: () => void;
  onRemove?: () => void;
  containerClass?: string;
  placeholder?: JSX.Element;
  placeholderText?: string;
  showFileSelection?: boolean;
  resetAlways?: boolean;
}
const FileDropArea = ({
  id,
  className,
  formats,
  sizeLimit,
  onDrop,
  style,
  defaultValue = "",
  disabled,
  topLabel,
  wrapperClass,
  resetDefaultFile,
  containerClass,
  onRemove,
  placeholder,
  showFileSelection = true,
  resetAlways = false,
  placeholderText,
}: DropAreaProps) => {
  const ref = useRef() as MutableRefObject<HTMLInputElement>;

  const [fileName, setFileName] = useState<string | undefined>();
  const [filePlaceHolder, setFilePlaceHolder] = useState("Select a file to upload");

  useEffect(() => {
    if (placeholderText) {
      setFilePlaceHolder(placeholderText);
    } else if (formats.includes(".png" || ".jpg")) {
      setFilePlaceHolder("Select a image to upload");
    } else if (formats.includes(".pdf")) {
      setFilePlaceHolder("Select a pdf to upload");
    } else {
      setFilePlaceHolder("We support JPEGs, PNGs, PDFs and DOCs under 10MB");
    }
  }, [placeholderText, formats]);
  const [dropDepth, setDropDepth] = useState<number>(0);
  const [message, setMessage] = useState<IMessage>({
    show: false,
    text: "",
    type: "",
    prevTimer: undefined,
  });
  const showMessage = (text: string, type: string, timeout: number) => {
    const resetTimeout = setTimeout(
      () =>
        setMessage({
          show: false,
          text: "",
          type: "",
          prevTimer: undefined,
        }),
      timeout,
    );
    setMessage((prev) => {
      if (prev.prevTimer) clearTimeout(prev.prevTimer);
      return {
        show: true,
        text,
        type,
        prevTimer: resetTimeout,
      };
    });
  };
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDropDepth((prev) => prev + 1);
  };
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDropDepth((prev) => prev - 1);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleFile = (files: FileList) => {
    if (disabled) return;
    const len = files.length;
    if (len === 0) return;
    if (len !== 1) {
      showMessage(`Only one file can be uploaded at a time`, "error", 2000);
      return;
    }
    const file = files.item(0);
    if (
      formats &&
      file &&
      !formats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase()))
    ) {
      showMessage(
        `Only following file formats are acceptable: ${formats.join(", ")}`,
        "error",
        2000,
      );
      return;
    }
    if (file) {
      if (sizeLimit && file.size / 1024 / 1024 > sizeLimit) {
        showMessage(`Only a file less than ${sizeLimit}MB can be uploaded`, "error", 2000);
        return;
      }
      onDrop(file);
      setFileName(file.name);
    }
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleFile(e.dataTransfer.files);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    handleFile(e.target.files);
    if (resetAlways) resetFile();
  };
  const resetFile = () => {
    ref.current.value = "";
    setFileName("");
    onRemove && onRemove();
  };

  return (
    <div className={classNames(wrapperClass)}>
      {topLabel ? (
        <div className=" pb-3 font-poppins text-base font-normal ">{topLabel}</div>
      ) : null}
      <div
        className={classNames(
          className,
          "drop-area pointer group flex min-h-[110px] cursor-pointer items-center rounded border border-dashed border-gray-400 py-2 hover:border-gray-500",
          {
            "drop-area-dragging": dropDepth > 0,
            "bg-gray-100": disabled,
          },
          containerClass,
        )}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        style={style}
        onClick={() => {
          if (!disabled && ref && ref.current) ref.current.click();
        }}
      >
        <input
          className="hidden"
          type="file"
          id={id}
          ref={ref}
          accept={formats.join(",")}
          onChange={onChange}
          disabled={disabled}
        />
        <div className="w-full ">
          {message.show ? (
            <div
              className={classNames("w-full text-center", `drop-area-placeholder-${message.type}`)}
            >
              <p>{message.text}</p>
            </div>
          ) : (
            <>
              {placeholder ? (
                placeholder
              ) : (
                <div className="grid w-full place-items-center text-center">
                  <p className="m-2 font-poppins text-base text-gray-900 group-hover:font-semibold group-hover:text-purple-600">
                    {dropDepth > 0 ? "drop here" : filePlaceHolder}
                  </p>
                  <h5 className="font-poppins text-sm text-gray-500">
                    {dropDepth > 0 ? "" : "or drag and drop here"}
                  </h5>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {!disabled && showFileSelection && (
        <>
          {fileName ? (
            <div className="mt-3 inline-flex h-4 w-full items-center space-x-2 py-2 text-left">
              <p className="font-poppins text-base text-purple-600">{fileName} (new)</p>
              <div className="cursor-pointer " onClick={() => resetFile()}>
                <i className="fa fa-close text-gray-900" />
              </div>
            </div>
          ) : (
            defaultValue && (
              <div className="mt-3 inline-flex h-4 w-full items-center space-x-2 py-2 text-left">
                <p className="font-poppins text-base text-purple-600">{defaultValue} (old)</p>
                <div
                  className="cursor-pointer "
                  onClick={() => resetDefaultFile && resetDefaultFile()}
                >
                  <i className="fa fa-close text-gray-900" />
                </div>
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

const MemoizedDropArea = memo(FileDropArea);
export default MemoizedDropArea;
