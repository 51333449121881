import classNames from "classnames";
import React, { useState } from "react";
import icon_invisible from "../../assets/images/eye-slash.svg";
import icon_visible from "../../assets/images/eye.svg";

export interface TextInputProps {
  id: string;
  className?: string;
  type?: "text" | "password" | "number";
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  color?: "purple" | "red" | "yellow" | "blue" | "green";
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  readonly?: boolean;
  placeholder?: string;
  value?: string | number;
  invalid?: boolean;
  disabled?: boolean;
  min?: number | undefined;
  max?: number | undefined;
  topPlaceholder?: string;
  defaultValue?: string;
  status?: "success" | "fail";
  wrapperClass?: string;
  autoFocus?: boolean;
  isClearable?: boolean;
  onClear?: () => void;
  errorMessage?: string;
  focusClass?: string;
  iconClass?: string;
  topLabelClass?: string;
}

const TextInput = React.forwardRef(function textinput(
  props: TextInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className={classNames("relative w-full ", props.wrapperClass)}>
      {props.topPlaceholder ? (
        <div
          className={classNames(
            "py-2 font-poppins text-base font-semibold text-gray-900",
            props.topLabelClass,
          )}
        >
          {props.topPlaceholder}
        </div>
      ) : null}
      <div
        className={classNames(
          "flex items-center rounded-[4px]  border border-gray-400",
          isFocused && props.focusClass,
          props.wrapperClass,
        )}
      >
        {props.iconLeft && (
          <div
            className={classNames("absolute left-0 flex h-full items-center px-3", props.iconClass)}
          >
            {props.iconLeft}
          </div>
        )}
        <input
          ref={ref}
          id={props.id}
          value={props.value}
          placeholder={props.placeholder}
          autoComplete="off"
          type={props.type === "password" ? (showPassword ? "text" : "password") : props.type}
          className={classNames(
            " flex h-14 w-full appearance-none rounded-[4px]  border-0 px-3 py-2 text-sm  leading-tight  text-gray-700 placeholder-gray-500 placeholder:font-poppins placeholder:text-sm  placeholder:font-normal focus:ring-0 focus:ring-gray-900 ",
            {
              "!border border-purple-500 bg-purple-50 text-gray-700 focus:border-purple-500":
                props.invalid,
              "pr-10": props.type === "password" || props.iconRight || props.isClearable,
              "pl-10": props.iconLeft,
            },
            props.className,
          )}
          onFocus={(e) => {
            setIsFocused(true);
            props.handleOnFocus && props.handleOnFocus(e);
          }}
          onBlur={(e) => {
            setIsFocused(false);
            props.handleOnBlur && props.handleOnBlur(e);
          }}
          onChange={props.handleOnChange}
          onKeyDown={props.handleOnKeyDown}
          disabled={props.disabled}
          readOnly={props.readonly}
          min={props.min}
          max={props.max}
          autoFocus={props.autoFocus}
          defaultValue={props.defaultValue}
        />
        {props.isClearable && props.value && (
          <div className="absolute right-0 flex h-full cursor-pointer items-center px-3">
            <i
              className={classNames("fa-solid fa-close text-gray-500", props.color)}
              onClick={(e) => {
                e.preventDefault();
                props.onClear && props.onClear();
              }}
            />
          </div>
        )}
        {props.type === "password" && (
          <div
            className="absolute right-0 flex h-full cursor-pointer items-center px-3"
            onClick={handleTogglePassword}
          >
            {showPassword ? (
              <img src={icon_visible} alt="password visible" />
            ) : (
              <img src={icon_invisible} alt="password invisible" />
            )}
          </div>
        )}
        {props.iconRight && (
          <div
            className={classNames(
              "absolute right-0 flex h-full cursor-pointer items-center px-3",
              props.iconClass,
            )}
          >
            {props.iconRight}
          </div>
        )}
      </div>
      {props.errorMessage && props.invalid ? (
        <div className="font-sm pt-2 font-poppins text-base text-purple-500">
          {props.errorMessage}
        </div>
      ) : null}
    </div>
  );
});

TextInput.defaultProps = {
  type: "text",
};
export default TextInput;
