import {
  AnyAction,
  Dispatch,
  PayloadAction,
  ThunkAction,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import * as CryptoJS from "crypto-js";
import config from "../api";
import { RootState } from "./store";
interface PrefetchItem {
  isFetched: boolean;
  md5: string;
}
interface TaxonomyUppercase extends PrefetchItem {
  data: { list: string[] };
}
interface Geocode extends PrefetchItem {
  data: {
    id_2_label: { [key: number]: string };
    label_2_id: { [key: string]: number };
    graph: { [key: number]: number[] };
  };
}
interface PrefetchState {
  taxonomy_uppercase: TaxonomyUppercase;
  geocode: Geocode;
}
function stringToMd5(jsonString: string): string {
  if (!jsonString) return "";
  const md5Hash = CryptoJS.MD5(jsonString).toString(CryptoJS.enc.Hex);
  return md5Hash;
}
const getKey = (key: string) => `prefetch_static_data_${key}`;
const getMD5Key = (key: string) => `prefetch_static_data_${key}_md5`;
function readStoredData(key: string): PrefetchItem & { data: object } {
  if (typeof window !== "undefined") {
    try {
      const dataKey = getKey(key);
      const md5key = getMD5Key(key);
      const storedData = sessionStorage.getItem(dataKey) || "";
      const storedMD5 = sessionStorage.getItem(md5key) || "";
      const storedDataObject = storedData ? JSON.parse(storedData) : undefined;
      const md5 = stringToMd5(storedData);
      if (storedMD5 && storedDataObject && md5 === storedMD5)
        return { isFetched: true, md5, data: storedDataObject };
    } catch (e: unknown) {
      console.error(e);
      return { isFetched: false, md5: "", data: {} };
    }
  }
  return { isFetched: false, md5: "", data: {} };
}
function storeData(key: string, md5: string, data: string) {
  if (typeof window !== "undefined") {
    try {
      const dataKey = getKey(key);
      const md5key = getMD5Key(key);
      sessionStorage.setItem(dataKey, data);
      sessionStorage.setItem(md5key, md5);
    } catch (e: unknown) {
      console.error(e);
    }
  }
}

const storedTaxonomyUppercase = readStoredData(`prefetch_taxonomy_uppercase`);
const storedGeocode = readStoredData(`prefetch_geocode`);
const initialState: PrefetchState = {
  taxonomy_uppercase: storedTaxonomyUppercase,
  geocode: storedGeocode,
} as PrefetchState;
const staticDataSlice = createSlice({
  name: "staticData",
  initialState,
  reducers: {
    fetchTaxonomyUppercase: (state, action: PayloadAction<TaxonomyUppercase>) => {
      state.taxonomy_uppercase = action.payload;
    },
    fetchGeocode: (state, action: PayloadAction<Geocode>) => {
      state.geocode = action.payload;
    },
  },
});

const fetchStaticData = (
  key: "taxonomy_uppercase" | "geocode",
  force = false,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const currentStored = readStoredData(key);
    axios
      .get(process.env.REACT_APP_API_URL + `/api/v2/static_data/${key}/hash`, config)
      .then((response) => response.data)
      .then((hash) => {
        if (!force && currentStored?.isFetched && currentStored?.md5 === hash) return undefined;
        return hash;
      })
      .then(async (hash) => {
        return (
          hash
            ? axios
                .get(process.env.REACT_APP_API_URL + `/api/v2/static_data/${hash}/data`, config)
                .then((response) => {
                  storeData(key, hash, response.data);
                  return { isFetched: true, md5: hash, data: JSON.parse(response.data) };
                })
            : new Promise<{
                isFetched: boolean;
                md5: string;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data: any;
              }>((resolve) => resolve({ ...currentStored, isFetched: true }))
        ).then((obj) => {
          if (key === "taxonomy_uppercase") {
            dispatch(staticDataSlice.actions.fetchTaxonomyUppercase(obj));
          } else if (key === "geocode") {
            try {
              const id_2_label = obj.data.id_2_label;
              const label_2_id = Object.keys(id_2_label).reduce((prev, key) => {
                prev[id_2_label[key]] = +key;
                return prev;
              }, {} as { [key: string]: number });
              dispatch(
                staticDataSlice.actions.fetchGeocode({
                  ...obj,
                  data: { ...obj.data, label_2_id },
                }),
              );
            } catch (e: unknown) {
              console.error(e);
            }
          }
        });
      });
  };
};

const selectFetched = createDraftSafeSelector(
  (state: RootState) => state,
  (state: RootState): boolean =>
    state?.staticData?.taxonomy_uppercase?.isFetched && state?.staticData?.geocode?.isFetched
      ? true
      : false,
);
export { fetchStaticData, selectFetched };
export default staticDataSlice;
