import classNames from "classnames";
import { memo } from "react";

function LoadingRecommendedAction({
  loading,
  max = 3,
  short = false,
  hidePercentage = false,
  wrapperClass,
}: {
  loading: boolean;
  max?: number;
  short?: boolean;
  hidePercentage?: boolean;
  wrapperClass?: string;
}) {
  return (
    <>
      {short ? (
        <div className="loading-light-shimmer loading-light-shimmer-on flex flex-col gap-3 rounded border border-gray-200 px-4 py-3">
          <div className="loading-light-shimmer-child flex flex-col items-start gap-3">
            <div className="!min-h-[20px] !min-w-[440px]"></div>
            <div className="!min-h-[20px] !min-w-[400px]"></div>
          </div>
        </div>
      ) : (
        <div className={classNames("flex flex-col gap-6", wrapperClass)}>
          {Array.from({ length: max }, (x, i) => i).map((_, i) => (
            <div
              key={i}
              className="loading-light-shimmer-on flex flex-col gap-4 overflow-hidden px-4 py-3"
            >
              <div className="loading-light-shimmer-child self-start">
                <div className="!min-h-[20px] !min-w-[180px]"></div>
              </div>
              {!hidePercentage && (
                <div className="flex items-center gap-6">
                  <div className="loading-light-shimmer-child">
                    <div className="flex !min-h-[76px] !min-w-[90px] items-center gap-4"></div>
                  </div>
                  <div className="loading-light-shimmer-child flex flex-col items-start gap-1">
                    <div className="!min-h-[20px] !min-w-[310px]"></div>
                    <div className="!min-h-[20px] !min-w-[240px]"></div>
                    <div className="!min-h-[20px] !min-w-[260px]"></div>
                  </div>
                </div>
              )}
              <div className="flex flex-col gap-3 rounded border border-gray-200 px-4 py-3">
                <div className="loading-light-shimmer-child flex flex-col items-start gap-3">
                  <div className="!min-h-[20px] !min-w-[460px]"></div>
                  <div className="!min-h-[20px] !min-w-[320px]"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
const MemoizedLoadingRecommendedAction = memo(LoadingRecommendedAction);
export default MemoizedLoadingRecommendedAction;
