import React, { useState, useEffect, ReactNode, useCallback, memo, useRef } from "react";
import { GeographicFocus } from "../../types/philanthropy";
import {
  prettyListStrings,
  toUsdLong,
  toUsdShort,
  stateIdToStateName,
  stateNameToStateId,
  fipsCodeToStateName,
} from "../../utils/formatHelper";
import { compareAmount } from "../../utils/philanthropy";
import { reprLocation } from "../../types/location";
import { useRecoilState } from "recoil";
import { matchingSummaryState } from "../../app/recoilStore";
import classNames from "classnames";
import { scrollBarClass } from "../../utils/classes";
import GibooGradientDiv from "../GibooGradientDiv";
import { ComposableMap, Geographies, Geography, ZoomableGroup, Marker } from "react-simple-maps";
import "react-tooltip/dist/react-tooltip.css";
import * as d3 from "d3";
import RoundButton from "../RoundButton";
import { geoCentroid } from "d3-geo";
import { ClickToScrollWrapper } from "../ClickToScrollWrapper";

import UNMATCHED_PIN from "../../assets/images/unmatch_pin.svg";

interface props {
  service_loc: GeographicFocus[];
}
interface LegendItem {
  label: string;
  color: string;
  range: string;
}

interface PinItem {
  label: string;
  color: string;
}

interface MapTooltipProps {
  content: TooltipContent | null;
  x: number;
  y: number;
}
interface MergedData {
  [key: string]: {
    amount: number;
    state_id: string;
    city_count: number;
    grant_count: number;
    matched: boolean;
    lat: number;
    lng: number;
  };
}

interface cityGrant {
  [key: string]: {
    county_name: string;
    city_name: string;

    grant_count: number;
    amount: number;
    state_id: string;
  };
}

interface grantedCity {
  [key: string]: {
    // county_name: string;
    // city_name: string;

    grant_count: number;
    amount: number;
    state_id: string;
  };
}

interface TooltipContent {
  name: string;
  grantAmount: number;
  grantedCities: number;
  grantCounts: number;
}

const getColorFromScale = (value: number): string => {
  const scaledValue = Math.log10(value) / 9;
  const colorInterpolator = d3.interpolate("#DEDEDE", "#565656");
  return colorInterpolator(scaledValue);
};

const getMatchedColor = (value: number): string => {
  const scaledValue = Math.log10(value) / 9;
  const colorInterpolator = d3.interpolate("#BFAEE4", "#5C38A7");
  return colorInterpolator(scaledValue);
};

const ZOOM = 1;
const COUNTY_ZOOM = 3;
const US_CENTER: [number, number] = [-98.35, 39.5];
const COUNTY_MAP_URL = "https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json";
const STATE_MAP_URL = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
// const COUNTY_MAP_URL = "https://cdn.jsdelivr.net/npm/us-atlas@3/counties-albers-10m.json";

function ServiceLocationGeoTest(props: props) {
  const [matchingDescription, setMatchingDescription] = useRecoilState<{
    [key: string]: ReactNode;
  }>(matchingSummaryState);

  const [zoom, setZoom] = useState(ZOOM);
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: ZOOM });
  const [focusedState, setFocusedState] = useState<string>("");
  const [tooltipSize, setTooltipSize] = useState({ width: 0, height: 0 });
  const [isHovering, setIsHovering] = useState(false);
  const [marker, setMarker] = useState({
    lat: 0,
    lng: 0,
    visible: false,
    map: false,
    item: undefined,
  });
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [searchState, setSearchState] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [filteredLocations, setFilteredLocations] = useState(props.service_loc);
  const [scaleFactor, setScaleFactor] = useState(3);
  // const [filteredStates, setFilteredStates] = useState([]);

  const [tooltip, setTooltip] = useState<{
    visible: boolean;
    content: TooltipContent | null;
    x: number;
    y: number;
  }>({
    visible: false,
    content: null,
    x: 0,
    y: 0,
  });

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [firstZoomClick, setFirstZoomClick] = useState(true);
  // Function to handle zoom in
  const handleZoomIn = () => {
    if (firstZoomClick) {
      setPosition({ coordinates: US_CENTER, zoom: 1.2 });
      setZoom(1.2);
      setFirstZoomClick(false);
      // setPosition(position);
    } else if (zoom < 20) {
      setZoom(zoom * 1.2);
    }
  };

  const hasMatchedCities = () => {
    return service_location.some((item) => stateNameToStateId(focusedState) === item.state_id);
  };

  // 4. Search Functionality (simplified example)
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value.toLowerCase();
    if (searchText == "") {
      setSearchState(false);
    } else {
      setSearchState(true);
    }

    const filtered = props.service_loc.filter((d) => {
      const stateIdMatch =
        d.state_id && stateIdToStateName(d.state_id).toLowerCase().startsWith(searchText);
      const stateNameMatch =
        d.state_name && stateNameToStateId(d.state_name).toLowerCase().startsWith(searchText);
      const cityNameMatch = d.city && d.city?.toLowerCase().startsWith(searchText);
      const countyNameMatch = d.county_name && d.county_name?.toLowerCase().startsWith(searchText);
      return stateIdMatch || stateNameMatch || cityNameMatch || countyNameMatch;
    });

    const filteredStates = all_states.filter((d) => {
      const stateIdMatch =
        d.state_id && stateIdToStateName(d.state_id).toLowerCase().startsWith(searchText);

      return stateIdMatch;
    });
    setFilteredStates(filteredStates);
    setFilteredLocations(filtered);
    if (searchText == "") {
      setSearchState(false);
    } else {
      setSearchState(true);
    }
    // Filter your cities list based on the searchText
    // Update the state that holds the filtered list
  };

  // Function to handle zoom out
  const handleZoomOut = () => {
    if (firstZoomClick) {
      setPosition({ coordinates: US_CENTER, zoom: 0.8 });
      setZoom(0.8);
      setFirstZoomClick(false);
      // setPosition(position);
    } else if (zoom >= 1) {
      setZoom(zoom / 1.2);
    }
    // setZoom(zoom / 1.2); // Decrease zoom by 20%
    if (zoom / 1.2 <= 3) {
      setMarker({ lat: 0, lng: 0, visible: false, map: false, item: undefined });
    }
  };

  useEffect(() => {
    if (tooltipRef.current) {
      setTooltipSize({
        width: tooltipRef.current.offsetWidth,
        height: tooltipRef.current.offsetHeight,
      });
    }
  }, [tooltip]);

  const handleMarkerEnter = (item: GeographicFocus, event: React.MouseEvent<SVGGElement>) => {
    const { clientX, clientY } = event;
    const containerBounds = mapContainerRef.current?.getBoundingClientRect();
    const name = `City: ${item.city}, ${stateIdToStateName(item.state_id || "")}, ${
      item.county_name
    }`;

    if (containerBounds) {
      const tooltipX = clientX - containerBounds.left;
      const tooltipY = clientY - containerBounds.top;
      const offsetX = 10; // To avoid the tooltip sticking to the cursor
      const offsetY = 10; // To avoid the tooltip sticking to the cursor

      // Adjust for the right edge
      const rightEdge = containerBounds.left + containerBounds.width;
      const adjustedX =
        clientX + offsetX + tooltipSize.width > rightEdge
          ? tooltipX - tooltipSize.width - offsetX
          : tooltipX + offsetX;

      // Adjust for the bottom edge
      const bottomEdge = containerBounds.top + containerBounds.height;
      const adjustedY =
        clientY + offsetY + tooltipSize.height > bottomEdge
          ? tooltipY - tooltipSize.height - offsetY
          : tooltipY + offsetY;
      setTooltip({
        visible: true,
        content: {
          name: name,
          grantAmount: (item && item.amount) || 0,
          grantedCities: 0,
          grantCounts: item.count || 0,
        },
        x: adjustedX,
        y: adjustedY,
      });
    }
  };

  const handleListClickTooltip = (item: GeographicFocus) => {
    const containerBounds = mapContainerRef.current?.getBoundingClientRect();
    let name;
    if (item.city) {
      name = `City: ${item.city}, ${stateIdToStateName(item.state_id || "")}, ${item.county_name}`;
    } else {
      name = stateIdToStateName(item.state_id || "");
    }

    if (containerBounds) {
      setTooltip({
        visible: true,
        content: {
          name: name,
          grantAmount: (item && item.amount) || 0,
          grantedCities: 0,
          grantCounts: item.count || 0,
        },
        x: containerBounds.width / 2 + 2,
        y: containerBounds.height / 2 + 2,
      });
    }
  };

  const handleMarkerLeave = () => {
    setTooltip({
      ...tooltip,
      visible: false,
    });
  };

  const handleGeographyEnter = (
    event: React.MouseEvent<SVGPathElement>,
    name: string[],
    amount: number,
    cityCounts: number,
    grantCount: number,
  ) => {
    if (zoom <= COUNTY_ZOOM) {
      setFocusedState(name[0]);
    }
    const { clientX, clientY } = event;
    const containerBounds = mapContainerRef.current?.getBoundingClientRect();

    if (containerBounds) {
      const tooltipX = clientX - containerBounds.left;
      const tooltipY = clientY - containerBounds.top;
      const offsetX = 10; // To avoid the tooltip sticking to the cursor
      const offsetY = 10; // To avoid the tooltip sticking to the cursor

      // Adjust for the right edge
      const rightEdge = containerBounds.left + containerBounds.width;
      const adjustedX =
        clientX + offsetX + tooltipSize.width > rightEdge
          ? tooltipX - tooltipSize.width - offsetX
          : tooltipX + offsetX;

      // Adjust for the bottom edge
      const bottomEdge = containerBounds.top + containerBounds.height;
      const adjustedY =
        clientY + offsetY + tooltipSize.height > bottomEdge
          ? tooltipY - tooltipSize.height - offsetY
          : tooltipY + offsetY;

      setTooltip({
        visible: true,
        content: {
          name: name.length > 1 ? `County: ${name[1]}` : name[0],
          grantAmount: amount,
          grantedCities: cityCounts,
          grantCounts: grantCount,
        },
        x: adjustedX,
        y: adjustedY,
      });
    }
  };

  const handleGeographyLeave = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  const handleListClick = (item: any) => {
    if (item && item.lng && item.lat) {
      setSelectedCity(item.city);
      if (zoom > COUNTY_ZOOM) {
        // handleMarkerEnter(item, )
        handleListClickTooltip(item);
        setFocusedState(stateIdToStateName(item.state_id));
        setPosition({ coordinates: [item.lng, item.lat], zoom: 6.2 });
        setZoom(6.2);
        setMarker({ lat: item.lat, lng: item.lng, visible: true, map: false, item: item });
      } else {
        handleListClickTooltip(item);
        setPosition({ coordinates: [item.lng, item.lat], zoom: 3.2 });
        setZoom(3.2);
        setFocusedState(stateIdToStateName(item.state_id));
        setMarker({ lat: item.lat, lng: item.lng, visible: true, map: false, item: undefined });
      }
      if (mapContainerRef.current) {
        mapContainerRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const getGrantCountByCounty = (cityGrants: cityGrant, countyName: string) => {
    const grant = Object.values(cityGrants).find((grant) => {
      return grant.county_name === countyName || grant.city_name === countyName;
    });
    return grant ? grant.grant_count : 0;
  };

  const handleGeographyClick = (geography: any) => {
    // Cut this short if the bypass is enabled...

    if (zoom <= COUNTY_ZOOM + 0.1) {
      const centroid = geoCentroid(geography);
      setMarker({ lat: centroid[1], lng: centroid[0], visible: true, map: true, item: undefined });
      setPosition({ coordinates: centroid, zoom: 4.5 });
      setSearchState(false);
      setIsSearchBarVisible(false);
      setZoom(4.5);
      setFocusedState(geography.properties.name);
      setScaleFactor(4.5);
    } else {
      const centroid = geoCentroid(geography);
      setMarker({ lat: centroid[1], lng: centroid[0], visible: true, map: true, item: undefined });
      setPosition({ coordinates: centroid, zoom: 9 });
      setSearchState(false);
      setIsSearchBarVisible(false);
      setZoom(9);
      setScaleFactor(9);
      // setFocusedState(geography.properties.name);
    }
  };

  const handleMarkerClick = (centroid: number[]) => {
    setMarker({ lat: centroid[1], lng: centroid[0], visible: true, map: true, item: undefined });
    setPosition({ coordinates: centroid, zoom: 13 });
    setSearchState(false);
    setIsSearchBarVisible(false);
    setZoom(13);
    setScaleFactor(13);
  };

  function handleMoveEnd(position: any) {
    setPosition(position);
    setZoom(position.zoom);
    if (position.zoom <= COUNTY_ZOOM) {
      setMarker({ lat: 0, lng: 0, visible: false, map: false, item: undefined });
    } else {
      // const centroid = geoCentroid(geography);
      setMarker({
        lat: position.lat,
        lng: position.lng,
        visible: true,
        map: true,
        item: undefined,
      });
      // setPosition({ coordinates: [position.lat, position.lng], zoom: 9 });
      // setSearchState(false);
      // setIsSearchBarVisible(false);
      // setZoom(9);
      // setScaleFactor(9);
      // setFocusedState(geography.properties.name);
    }
  }

  const handleBack = () => {
    setPosition({ coordinates: US_CENTER, zoom: 1 });
    setZoom(1);
    setIsHovering(false);
    setMarker({ lat: 0, lng: 0, visible: false, map: false, item: undefined });
  };

  const total_amount = props.service_loc.reduce((prev, cur) => prev + (cur.amount || 0), 0);
  // const maxAmount = props.service_loc.reduce((max, item) => {
  //   return item.amount || 0 > max ? item.amount || 0 : max || 0;
  // }, 0);
  const service_amount = props.service_loc
    ?.filter((loc) => loc.matched)
    .reduce((prev, cur) => prev + (cur.amount || 0), 0);
  const service_location = props.service_loc?.filter((loc) => loc.matched).sort(compareAmount);
  const unmatched = props.service_loc?.filter((loc) => !loc.matched).sort(compareAmount);

  const purpleColorVariantsFill = {
    900: "fill-purple-900",
    800: "fill-purple-800",
    700: "fill-purple-700",
    600: "fill-purple-600",
    500: "fill-purple-500",
    300: "fill-purple-300",
    50: "fill-purple-50",
  };
  const orangeColorVariantsFill = {
    900: "fill-orange-900",
    800: "fill-orange-800",
    700: "fill-orange-700",
    600: "fill-orange-600",
    500: "fill-orange-500",
    300: "fill-orange-300",
    50: "fill-orange-50",
  };

  const purpleColorVariants = {
    900: "bg-purple-900",
    800: "bg-purple-800",
    700: "bg-purple-700",
    600: "bg-purple-600",
    500: "bg-purple-500",
    300: "bg-purple-300",
    50: "bg-purple-50",
  };
  const orangeColorVariants = {
    900: "bg-orange-900",
    800: "bg-orange-800",
    700: "bg-orange-700",
    600: "bg-orange-600",
    500: "bg-orange-500",
    300: "bg-orange-300",
    50: "bg-orange-50",
  };

  const MapTooltip: React.FC<MapTooltipProps> = ({ content, x, y }) => {
    if (!content) return null;

    return (
      <div
        ref={tooltipRef}
        className="absolute bg-white p-2 font-poppins"
        style={{
          top: y,
          left: x,
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
          border: "1px solid #ccc",
          borderRadius: "8px",
          fontSize: "12px",
          lineHeight: "18px",
          zIndex: 1000,
          pointerEvents: "none",
        }}
      >
        {content.name.startsWith("City") ? (
          <>
            <div>
              <strong>
                {content.name.split(",")[0]},{content.name.split(",")[1]}
              </strong>
            </div>

            <div>County: {content.name.split(",")[2]}</div>
            <div>Grant amount: {toUsdLong(content.grantAmount ? content.grantAmount : 0)}</div>
            {zoom > COUNTY_ZOOM ? (
              <></>
            ) : (
              <div>
                {"Granted cities: " + (content.grantedCities ? content.grantedCities : "0")}
              </div>
            )}
            <div>{"Grant counts:  " + (content.grantCounts || "0")}</div>
          </>
        ) : (
          <>
            <div>
              <strong>{content.name}</strong>
            </div>

            <div className=""></div>
            <div>Grant amount: {toUsdLong(content.grantAmount ? content.grantAmount : 0)}</div>
            {zoom > COUNTY_ZOOM ? (
              <></>
            ) : (
              <div>
                {"Granted cities: " + (content.grantedCities ? content.grantedCities : "0")}
              </div>
            )}

            <div>{"Grant counts:  " + (content.grantCounts || "0")}</div>
            {/* <div> */}
            {/* {zoom > COUNTY_ZOOM
                ? "Granted organizations:  " + content.grantCounts // getGrantCountByCounty(cityGrants, content.name)
                : "Granted cities: " + (content.grantedCities ? content.grantedCities : "0")} */}
            {/* </div> */}
          </>
        )}
      </div>
    );
  };
  const LegendBar = () => {
    const legendWidth = 206;
    const legendHeight = 16;

    // Define the gradient style
    const gradientStyle = {
      background: `linear-gradient(to right, 
        #DEDEDE, 
        #565656)`,
      width: `${legendWidth}px`,
      height: `${legendHeight}px`,
      borderRadius: "5px",
      border: "1px solid #ccc",
    };

    return (
      <div>
        <div style={gradientStyle} />
        <div
          style={{ display: "flex", justifyContent: "space-between", width: `${legendWidth}px` }}
        >
          <span className="text-gray-700" style={{ fontSize: "12px" }}>
            $1
          </span>
          <span className="text-gray-700" style={{ fontSize: "12px" }}>
            $1K
          </span>
          <span className="text-gray-700" style={{ fontSize: "12px" }}>
            $1M
          </span>
          <span className="text-gray-700" style={{ fontSize: "12px" }}>
            $1B
          </span>
        </div>
      </div>
    );
  };
  const cityGrants = props.service_loc.reduce<cityGrant>((acc, item) => {
    if (item.city) {
      if (!acc[item.city]) {
        acc[item.city] = {
          amount: 0,
          county_name: item.county_name ? item.county_name : "",
          city_name: item.city,
          state_id: item.state_id ? item.state_id : "",
          grant_count: 0,
        };
      }
      acc[item.city].amount += item.amount ? item.amount : 0;
      // acc[item.city].county_name = item.county_name;
      // acc[item.city].city_name = item.city_name;
      // acc[item.city].state_id = item.state_id;
      acc[item.city].grant_count += 1;
    }
    return acc;
  }, {});
  // const grantedCities = props.service_loc.reduce<cityGrant>((acc, item) => {
  //   if (item.city) {
  //     if (!acc[item.city]) {
  //       acc[item.city] = {
  //         amount: 0,
  //         county_name: item.county_name ? item.county_name : "",
  //         city_name: item.city,
  //         state_id: item.state_id ? item.state_id : "",
  //         grant_count: 0,
  //       };
  //     }
  //     acc[item.city].amount += item.amount ? item.amount : 0;
  //     // acc[item.city].county_name = item.county_name;
  //     // acc[item.city].city_name = item.city_name;
  //     // acc[item.city].state_id = item.state_id;
  //     acc[item.city].grant_count += 1;
  //   }
  //   return acc;
  // }, {});
  const mergedCounties = props.service_loc.reduce<MergedData>((acc, item) => {
    if (item.state_id) {
      if (!acc[item.state_id]) {
        acc[item.state_id] = {
          amount: 0,
          state_id: item.state_id,
          city_count: 0,
          matched: item.matched || false,
          lat: item.lat,
          lng: item.lng,
          grant_count: 0,
        };
      }
      acc[item.state_id].amount += item.amount ? item.amount : 0;
      acc[item.state_id].grant_count += item.count ? item.count : 0;
      acc[item.state_id].city_count += 1;
      acc[item.state_id].matched ||= item.matched || false;
      acc[item.state_id].lat = item.lat;
      acc[item.state_id].lng = item.lng;
    }
    return acc;
  }, {});

  const service_states = Object.keys(mergedCounties)
    .filter((key) => mergedCounties[key].matched)
    .map((key) => mergedCounties[key])
    .sort((a, b) => b.amount - a.amount);
  const unmatched_states = Object.keys(mergedCounties)
    .filter((key) => !mergedCounties[key].matched)
    .map((key) => mergedCounties[key])
    .sort((a, b) => b.amount - a.amount);

  const all_states = Object.keys(mergedCounties)
    .map((key) => mergedCounties[key])
    .sort((a, b) => b.amount - a.amount);

  const [filteredStates, setFilteredStates] = useState(all_states);

  const calculateMarkerScale = (zoomLevel: number) => {
    // This is an example scale calculation. You may need to adjust the formula to suit your needs.
    const scale = 1 / zoomLevel;
    return scale;
  };
  const map = (
    <>
      {zoom > COUNTY_ZOOM && (
        <>
          <div
            className="absolute left-4 top-4 flex "
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <RoundButton
              id={`btn-back-item`}
              icon="gi-md gi-left-arrow"
              type="primary"
              size="sm"
              handleOnClick={handleBack}
            />
            {isHovering && (
              <div
                onClick={handleBack}
                className="back-button-tooltip bottom-full  ml-1 rounded border border-solid border-[#ccc] bg-gray-700 p-[5px] text-center text-white shadow-[0px_0px_5px_rgba(0,0,0,0.2)] hover:cursor-pointer"
              >
                Back to the state map
              </div>
            )}
          </div>
        </>
      )}
      <div className="map-zoom-controls absolute right-4 top-4 flex flex-col items-center rounded-[3px] border border-solid border-[#ccc] bg-white shadow-[0_1px_2px_rgba(0,0,0,0.1)]">
        <button
          className="flex h-[30px]  w-[30px] cursor-pointer items-center justify-center border-b  border-solid border-[none] border-b-[#ccc] text-lg leading-none"
          onClick={handleZoomIn}
        >
          +
        </button>
        <button
          className="flex h-[30px] w-[30px] cursor-pointer items-center justify-center border-b  border-solid border-[none] border-b-[#ccc] text-lg leading-none"
          onClick={handleZoomOut}
        >
          -
        </button>
      </div>
      <ComposableMap projection="geoAlbersUsa">
        <ZoomableGroup
          minZoom={0.8}
          maxZoom={20}
          zoom={zoom}
          onMoveEnd={handleMoveEnd}
          center={[position.coordinates[0], position.coordinates[1]]}
          onMove={({ zoom }) => setScaleFactor(zoom)}
        >
          <Geographies geography={COUNTY_MAP_URL}>
            {({ geographies }) =>
              geographies.map((geo) => {
                // county level
                const loc = props.service_loc.find((s) => {
                  const isMatch =
                    s.county_name === geo.properties.name &&
                    s.state_id ==
                      stateNameToStateId(fipsCodeToStateName(String(geo.id).substring(0, 2)));

                  return isMatch;
                });
                const strokeColor = zoom > COUNTY_ZOOM ? "#9B9B9B" : "none";
                const colorVal = loc?.amount || 0;
                const per = (((colorVal || 0) * mapLabelScalingFactor) / total_amount) * 100;
                const c = per > 80 ? 900 : per > 60 ? 700 : per > 40 ? 500 : per > 20 ? 300 : 50;
                const fillColor =
                  colorVal && loc && loc.matched
                    ? purpleColorVariantsFill[c]
                    : orangeColorVariantsFill[c];

                return (
                  <Geography
                    key={geo.id}
                    geography={geo}
                    stroke={strokeColor}
                    strokeWidth={zoom > COUNTY_ZOOM ? "0.2" : "0"}
                    onMouseEnter={(event) =>
                      handleGeographyEnter(
                        event,
                        [fipsCodeToStateName(String(geo.id).substring(0, 2)), geo.properties.name],
                        colorVal,
                        0,
                        loc?.count || 0,
                      )
                    }
                    onMouseLeave={handleGeographyLeave}
                    className={classNames(
                      "outline-none",
                      "hover:border-[4px]",
                      "hover:stroke-white",
                      zoom > COUNTY_ZOOM ? "hover:stroke-[1px]" : "hover:stroke-[4px]",
                      "hover:drop-shadow-lg",
                      zoom > COUNTY_ZOOM ? fillColor : "fill-none",
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleGeographyClick(geo);
                    }}
                  />
                );
              })
            }
          </Geographies>
          <Geographies geography={STATE_MAP_URL}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const loc = props.service_loc.find(
                  (s) => s.state_id === stateNameToStateId(geo.properties.name),
                );
                const colorVal = mergedCounties[stateNameToStateId(geo.properties.name)]?.amount;
                const cityCounts =
                  mergedCounties[stateNameToStateId(geo.properties.name)]?.city_count;
                const grantCount =
                  mergedCounties[stateNameToStateId(geo.properties.name)]?.grant_count;
                const per = (((colorVal || 0) * mapLabelScalingFactor) / total_amount) * 100;
                const c = per > 80 ? 900 : per > 60 ? 700 : per > 40 ? 500 : per > 20 ? 300 : 50;
                const fillColor =
                  colorVal && loc && loc.matched
                    ? purpleColorVariantsFill[c]
                    : orangeColorVariantsFill[c];

                return (
                  <Geography
                    key={geo.id}
                    geography={geo}
                    stroke={zoom > COUNTY_ZOOM ? "orange" : "#9B9B9B"}
                    strokeWidth={zoom > COUNTY_ZOOM ? "0.5" : "1"}
                    // fill={zoom > COUNTY_ZOOM ? "none" : fillColor}
                    onMouseEnter={(event) =>
                      handleGeographyEnter(
                        event,
                        [geo.properties.name],
                        colorVal,
                        cityCounts,
                        grantCount,
                      )
                    }
                    onMouseLeave={handleGeographyLeave} // handleGeographyLeave
                    className={classNames(
                      "z-10",
                      "outline-none",
                      "hover:border-[4px]",
                      "hover:stroke-white",
                      zoom > COUNTY_ZOOM ? "hover:stroke-[1px]" : "hover:stroke-[6px]",
                      "hover:drop-shadow-lg",
                      "hover:z-50",
                      // "hover:-translate-y-1",
                      zoom > COUNTY_ZOOM ? "fill-none" : fillColor,
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleGeographyClick(geo);
                    }}
                  />
                );
              })
            }
          </Geographies>
          {marker.visible && (
            <>
              {props.service_loc.map((item) => {
                if (stateNameToStateId(focusedState) != item.state_id) {
                  return <></>;
                }

                return (
                  <Marker
                    key={reprLocation(item)}
                    coordinates={[item.lng, item.lat]}
                    onMouseEnter={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      handleMarkerEnter(item, event);
                    }}
                    onMouseLeave={handleMarkerLeave}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleMarkerClick([item.lng, item.lat]);
                    }}
                  >
                    <rect
                      width={10 / scaleFactor}
                      height={10 / scaleFactor}
                      fill={
                        item.city == selectedCity ? "#B67008" : item.matched ? "#5C38A7" : "#7eab67"
                      }
                      className="z-10 hover:z-50 hover:fill-orange-500"
                    />
                  </Marker>
                );
              })}
              {!marker.map && (
                <>
                  <Marker
                    key={"clicked-item-marker"}
                    coordinates={[marker.lng, marker.lat]}
                    onMouseEnter={(event) => {
                      if (marker.item) {
                        event.stopPropagation();
                        event.preventDefault();
                        handleMarkerEnter(marker.item, event);
                      }
                    }}
                    onMouseLeave={handleMarkerLeave}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleMarkerClick([marker.lng, marker.lat]);
                    }}
                  >
                    <rect
                      width={10 / scaleFactor}
                      height={10 / scaleFactor}
                      fill={"#B67008"}
                      className="z-10 hover:z-50 hover:fill-orange-500"
                    />
                  </Marker>
                </>
              )}
            </>
          )}
        </ZoomableGroup>
      </ComposableMap>
      {tooltip.visible && <MapTooltip content={tooltip.content} x={tooltip.x} y={tooltip.y} />}
    </>
  );

  useEffect(() => {
    setMatchingDescription((prev) => ({
      ...prev,
      service_loc:
        service_location.length > 0 ? (
          <h5 className="font-poppins text-gray-700">
            This funder has funded <b className="text-purple-500">{toUsdShort(service_amount)}</b>{" "}
            toward causes that align with your organization&apos;s philanthropic geographic focus,
            including{" "}
            <b className="text-purple-500">
              {prettyListStrings(service_location.slice(0, 3).map((i) => reprLocation(i)))}
            </b>
            .
          </h5>
        ) : null,
    }));
  }, [props.service_loc]);

  const renderHeader = () => {
    return (
      <GibooGradientDiv borderSize={2} wrapperClassName="mb-4 mt-0">
        <div className="rounded bg-gray-100 px-4 py-[10px]">{matchingDescription.service_loc}</div>
      </GibooGradientDiv>
    );
  };
  const renderLeftContent = useCallback(() => {
    const mapContainerStyle: React.CSSProperties = {
      position: "relative",

      overflowY: "hidden",
    };
    return (
      <>
        <div className="flex h-full w-full shrink grow flex-col" id="geographic-focus-chart-view">
          <div ref={mapContainerRef} style={mapContainerStyle}>
            {map && map}
          </div>
        </div>
      </>
    );
  }, [map]);

  const formatCurrency = (amount: number): string => {
    if (amount >= 1e9) {
      return `${(amount / 1e9).toFixed(1)}B`;
    } else if (amount >= 1e6) {
      return `${(amount / 1e6).toFixed(1)}M`;
    } else if (amount >= 1e3) {
      return `${(amount / 1e3).toFixed(1)}K`;
    } else {
      return `${amount.toFixed(1)}`;
    }
  };

  const generateRangeLabels = (maxAmount: number): string[] => {
    const numberOfRanges = 5;
    const rangeSize = Math.ceil(maxAmount / numberOfRanges);
    const grantAmounts: string[] = [];

    for (let i = 0; i < numberOfRanges; i++) {
      const lowerBound = rangeSize * i;
      const upperBound = i === numberOfRanges - 1 ? maxAmount : lowerBound + rangeSize;
      const label =
        i === numberOfRanges - 1
          ? `>= ${formatCurrency(lowerBound)}`
          : `${formatCurrency(lowerBound)} - ${formatCurrency(upperBound)}`;

      grantAmounts.push(label);
    }

    return grantAmounts;
  };
  const mapLabelScalingFactor = 5;

  const labelValueRanges: string[] = generateRangeLabels(total_amount / mapLabelScalingFactor);
  const grantAmounts: LegendItem[] = [
    { label: labelValueRanges[0], color: "bg-orange-50", range: "0-200" },
    { label: labelValueRanges[1], color: "bg-orange-300", range: "200-400" },
    { label: labelValueRanges[2], color: "bg-orange-500", range: "400-600" },
    { label: labelValueRanges[3], color: "bg-orange-700", range: "600-800" },
    { label: labelValueRanges[4], color: "bg-orange-900", range: "800+" },
  ];

  const grantMatches: LegendItem[] = [
    { label: labelValueRanges[0], color: "bg-purple-50", range: "0-200" },
    { label: labelValueRanges[1], color: "bg-purple-300", range: "200-400" },
    { label: labelValueRanges[2], color: "bg-purple-500", range: "400-600" },
    { label: labelValueRanges[3], color: "bg-purple-700", range: "600-800" },
    { label: labelValueRanges[4], color: "bg-purple-900", range: "800+" },
  ];

  const cityPins: PinItem[] = [
    { label: "Unmatched city", color: "bg-[#7eab67]" },
    { label: "Matched city", color: "bg-[#5C38A7]" },
    { label: "Selected city", color: "bg-[#B67008]" },
  ];

  // Define the legend component
  const AmountLabels = ({ items, name }: { items: LegendItem[]; name: string }) => (
    <div className="flex flex-col">
      <h5 className="mb-1 font-poppins text-xs font-normal text-gray-700">{name}</h5>
      {items.map((item) => (
        <div key={item.range} className="mb-1 flex items-center gap-2">
          <span className={classNames("inline-block h-3 w-3", item.color)}></span>
          <span className="font-poppins text-xs font-normal text-gray-700">{item.label}</span>
        </div>
      ))}
    </div>
  );

  // Define the pins component
  const PinLabels = ({ items, name }: { items: PinItem[]; name: string }) => (
    <div className="flex flex-col">
      <h5 className="mb-1 font-poppins text-xs font-normal text-gray-700">{name}</h5>
      {items.map((item) => (
        <div key={item.label} className="mb-1 flex items-center gap-2">
          <span className={classNames("inline-block h-3 w-3", item.color)}>
            {/* <svg
              fill="none"
              strokeWidth="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(-34, -20)  scale(0.8)"
              className={item.color}
            >
              <defs>
                <filter id="dropshadow" height="130%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="1.8" />
                  <feOffset dx="0" dy="0.6" />
                  <feComponentTransfer>
                    <feFuncA type="linear" slope="0.3" />
                  </feComponentTransfer>
                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
              </defs>

              <path
                filter="url(#dropshadow)"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="white"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="white"
                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg> */}
          </span>
          <span className="font-poppins text-xs font-normal text-gray-700">{item.label}</span>
        </div>
      ))}
    </div>
  );

  const renderDetailsTool = () => {
    return (
      <div className="flex h-full w-full flex-col items-center justify-start">
        <h5 className="mb-4 w-full text-center font-poppins text-xs font-normal text-gray-700">
          Click the state map to see the granted counties and cities
        </h5>
        <div className="flex flex-row gap-4 bg-white p-4 shadow-[0_0_15px_1px_rgba(0,0,0,0.1)]">
          {/* <div className=""></div> */}
          <AmountLabels items={grantAmounts} name="Amount granted" />
          <AmountLabels items={grantMatches} name="Grant matched" />
          <PinLabels items={cityPins} name="City pins" />
        </div>
      </div>
    );
  };

  const renderRightContent = () => {
    return (
      <div className="h-full w-full">
        <div className="flex flex-row items-center justify-between">
          <p key={-1} className="text-base font-semibold">
            Geographic overview
          </p>
          {!isSearchBarVisible && (
            <RoundButton
              id={`btn-geographic-search`}
              size={"md"}
              type={"tertiary"}
              icon={"gi-md gi-search text-gray-700"}
              handleOnClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsSearchBarVisible(!isSearchBarVisible);
              }}
            />
          )}
        </div>
        {isSearchBarVisible && (
          <div className="search-bar-container mt-4 flex justify-around rounded border-[1px] border-gray-300">
            <RoundButton
              id={`btn-geographic-search-icon`}
              size={"md"}
              type={"tertiary"}
              icon={"gi-md gi-search text-gray-700"}
              handleOnClick={() => {}}
            />
            <input
              type="text"
              placeholder="Search city or state"
              onChange={handleSearch}
              className="search-input m-0 grow border-none p-0 text-left text-sm focus:outline-none focus:ring-0"
            />
          </div>
        )}
        {searchState ? (
          <>
            <>
              <h6 className="mt-4 text-gray-700">Matched cities and states</h6>
              <div className="mt-3 flex flex-col gap-3">
                {filteredStates.map((item) => {
                  return (
                    <div key={stateIdToStateName(item.state_id)} className="flex flex-col gap-1">
                      <div className="min-h-[8px] w-full rounded-full bg-gray-200">
                        <div
                          className="min-h-[8px] rounded-full bg-gray-500"
                          style={{
                            width: `${
                              total_amount === 0 ? 100 : ((item.amount || 0) / total_amount) * 100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <div className="flex justify-between whitespace-nowrap">
                        <h6 className="self-end text-gray-700">{toUsdLong(item.amount || 0)}</h6>
                        <h6
                          className="self-end text-purple-500 underline hover:cursor-pointer"
                          onClick={() => handleListClick(item)}
                        >
                          {stateIdToStateName(item.state_id)}
                        </h6>
                      </div>
                    </div>
                  );
                })}
                <></>
                {filteredLocations.map((item) => {
                  return (
                    <div key={reprLocation(item)} className="flex flex-col gap-1">
                      <div className="min-h-[8px] w-full rounded-full bg-gray-200">
                        <div
                          className="min-h-[8px] rounded-full bg-gray-500"
                          style={{
                            width: `${
                              total_amount === 0 ? 100 : ((item.amount || 0) / total_amount) * 100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <div className="flex justify-between whitespace-nowrap">
                        <h6 className="self-end text-gray-700">{toUsdLong(item.amount || 0)}</h6>
                        <h6
                          className="self-end text-purple-500 underline hover:cursor-pointer"
                          onClick={() => handleListClick(item)}
                        >
                          {reprLocation(item)}
                        </h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          </>
        ) : (
          <>
            {zoom > COUNTY_ZOOM ? (
              <>
                {service_location.length > 0 && hasMatchedCities() && (
                  <>
                    <h6 className="mt-4 text-gray-700">Matched cities in {focusedState}</h6>
                    <div className="mt-3 flex flex-col gap-3">
                      {service_location.map((item) => {
                        if (stateNameToStateId(focusedState) != item.state_id) {
                          return <></>;
                        }
                        const per =
                          total_amount === 0 ? 100 : ((item.amount || 0) / total_amount) * 100;
                        const c =
                          per > 50 ? 900 : per > 40 ? 800 : per > 30 ? 700 : per > 20 ? 600 : 500;
                        return (
                          <div key={reprLocation(item)} className="flex flex-col gap-1">
                            <div className="min-h-[8px] w-full rounded-full bg-gray-200">
                              <div
                                className={classNames(
                                  "min-h-[8px] rounded-full",
                                  purpleColorVariants[c],
                                )}
                                style={{
                                  width: `${per}%`,
                                }}
                              ></div>
                            </div>
                            <div className="flex justify-between whitespace-nowrap">
                              <h6 className="self-end text-gray-700">
                                {toUsdLong(item.amount || 0)}
                              </h6>
                              <h6
                                className="self-end text-purple-500 underline hover:cursor-pointer"
                                onClick={() => handleListClick(item)}
                              >
                                {reprLocation(item)}
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="mb-3 mt-4 w-full border-b border-gray-300"></div>
                  </>
                )}
                <>
                  <h6 className="mt-4 text-gray-700">
                    {hasMatchedCities() ? `Other granted cities in ` : "Granted cities in "}{" "}
                    {focusedState}
                  </h6>
                  <div className="mt-3 flex flex-col gap-3">
                    {unmatched.map((item) => {
                      if (stateNameToStateId(focusedState) != item.state_id) {
                        return <></>;
                      }
                      return (
                        <div key={reprLocation(item)} className="flex flex-col gap-1">
                          <div className="min-h-[8px] w-full rounded-full bg-gray-200">
                            <div
                              className="min-h-[8px] rounded-full bg-gray-500"
                              style={{
                                width: `${
                                  total_amount === 0
                                    ? 100
                                    : ((item.amount || 0) / total_amount) * 100
                                }%`,
                              }}
                            ></div>
                          </div>
                          <div className="flex justify-between whitespace-nowrap">
                            <h6 className="self-end text-gray-700">
                              {toUsdLong(item.amount || 0)}
                            </h6>
                            <h6
                              className="self-end text-purple-500 underline hover:cursor-pointer"
                              onClick={() => handleListClick(item)}
                            >
                              {reprLocation(item)}
                            </h6>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              </>
            ) : (
              // STATE LEVEL RIGHT SIDE BAR
              <>
                {service_states.length > 0 && (
                  <>
                    <h6 className="mt-4 text-gray-700">{"Matched state(s)"}</h6>
                    <div className="mt-3 flex flex-col gap-3">
                      {service_states.map((item) => {
                        const per =
                          total_amount === 0 ? 100 : ((item.amount || 0) / total_amount) * 100;
                        const c =
                          per > 50 ? 900 : per > 40 ? 800 : per > 30 ? 700 : per > 20 ? 600 : 500;
                        return (
                          <div
                            key={stateIdToStateName(item.state_id)}
                            className="flex flex-col gap-1"
                          >
                            <div className="min-h-[8px] w-full rounded-full bg-gray-200">
                              <div
                                className={classNames(
                                  "min-h-[8px] rounded-full",
                                  purpleColorVariants[c],
                                )}
                                style={{
                                  width: `${per}%`,
                                }}
                              ></div>
                            </div>
                            <div className="flex justify-between whitespace-nowrap">
                              <h6 className="self-end text-gray-700">
                                {toUsdLong(item.amount || 0)}
                              </h6>
                              <h6
                                className="self-end text-purple-500 underline hover:cursor-pointer"
                                onClick={() => handleListClick(item)}
                              >
                                {stateIdToStateName(item.state_id)}
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="mb-3 mt-4 w-full border-b border-gray-300"></div>
                  </>
                )}
                <div className="mt-3 flex flex-col gap-3">
                  {unmatched_states.map((item) => (
                    <div key={stateIdToStateName(item.state_id)} className="flex flex-col gap-1">
                      <div className="min-h-[8px] w-full rounded-full bg-gray-200">
                        <div
                          className="min-h-[8px] rounded-full bg-gray-500"
                          style={{
                            width: `${
                              total_amount === 0 ? 100 : ((item.amount || 0) / total_amount) * 100
                            }%`,
                          }}
                        ></div>
                      </div>
                      <div className="flex justify-between whitespace-nowrap">
                        <h6 className="self-end text-gray-700">{toUsdLong(item.amount || 0)}</h6>
                        <h6
                          className="self-end text-purple-500 underline hover:cursor-pointer"
                          onClick={() => handleListClick(item)}
                        >
                          {stateIdToStateName(item.state_id)}
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <div className="container" id="geograhpical-focus">
        {service_location.length > 0 && renderHeader()}
        <div className="flex h-full w-full overflow-hidden">
          <div className="flex w-full flex-col gap-3 ">
            <ClickToScrollWrapper>
              <div
                className=" w-full min-w-[300px] grow"
                onClick={() => {}}
                onMouseLeave={() => {}}
              >
                {renderLeftContent()}
                {/* {renderLegendBar()} */}
              </div>
            </ClickToScrollWrapper>
            <ClickToScrollWrapper>
              <div className="flex w-full flex-col gap-3 lg:flex-row">
                <div className="max-h-[420px] w-full min-w-[300px] grow">{renderDetailsTool()}</div>
                <div
                  className={classNames(
                    "f-hit flex max-h-[420px] min-w-[260px] grow-0 flex-col pr-3",
                    scrollBarClass,
                  )}
                >
                  {renderRightContent()}
                </div>
              </div>
            </ClickToScrollWrapper>
          </div>
        </div>
      </div>
    </>
  );
}

const MemoizedServiceLocationGeoTest = memo(ServiceLocationGeoTest);
export default MemoizedServiceLocationGeoTest;
