import { ReactElement, useCallback, useEffect, useState } from "react";
import FilterBase from "./FilterBase";
import useStars, { SearchOptionsType, getStarKey } from "../../../hooks/useStars";
import {
  ISearchParamNullable,
  SearchType,
  getObjectFromURLSearchParams,
  getURLSearchParamsFromSearchParam,
} from "../../../types/search";
import { useNavigate, useSearchParams } from "react-router-dom";
import TagSelector from "../../TagSelector";
import GibooLocationInput from "../../GibooLocationInput";
import { ILocation, reprLocation } from "../../../types/location";
import TagInfo from "../../TagInfo";
import useExcludeds, { ExcludeSearchOptionsType, getExcludedKey } from "../../../hooks/useExclude";
import Checkbox from "../../checkbox/Checkbox";
import { useRecoilValue } from "recoil";
import { searchSession } from "../../../app/recoilStore";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";

interface ServiceLocationFilterProps {
  repr: (v: number | undefined) => string | ReactElement;
  selected?: (v: ILocation[] | undefined) => boolean;
  downIcon?: boolean;
  panelWidth?: string;
  panelMaxHeight?: string;
  title?: string;
  hideHQ?: boolean;
  seeOpenToAnyLocation?: boolean;
}
function MergedLocationFilter({ title, ...props }: ServiceLocationFilterProps) {
  const searchSessionForMixpanel = useRecoilValue(searchSession);
  const mxEvent = useGibooMixpanel(undefined, true);
  const navigate = useNavigate();
  const session = useRecoilValue(searchSession);
  const [searchParams, setSearchParams] = useSearchParams();
  const [diffQueue, setDiffQueue] = useState<ISearchParamNullable[]>([]);
  const [serviceLocation, setServiceLocation] = useState<ILocation[]>([]);
  const [serviceLocationSubs, setServiceLocationSubs] = useState<ILocation[]>([]);
  const [hqLocation, setHqLocation] = useState<ILocation[]>([]);
  const [hqLocationSubs, setHqLocationSubs] = useState<ILocation[]>([]);
  const [anyLocation, setAnyLocation] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<SearchType>("funder");
  const { stars, setStars, isStar, isAllStar } = useStars();
  const { excludes, setExcludes, isExcluded } = useExcludeds();
  useEffect(() => {
    const obj = getObjectFromURLSearchParams(searchParams);
    setSearchType(obj.type[0]);
    setServiceLocation(obj.service_loc);
    setServiceLocationSubs([...(obj.service_loc_subs || [])]);
    setHqLocation(obj.hq_loc);
    setHqLocationSubs([...(obj.hq_loc_subs || [])]);
    setStars([...(obj.stars || [])]);
    setExcludes([...(obj.excludes || [])]);
    setAnyLocation(obj.any_location);
  }, [searchParams, setSearchParams]);
  const updateFilter = useCallback(
    (diff: ISearchParamNullable) => {
      setDiffQueue((prev) => [...prev, diff]);
    },
    [setDiffQueue],
  );
  useEffect(() => {
    if (diffQueue.length === 0) return;
    const diff = diffQueue[0];
    const done_tagging = searchParams.get("done_tagging") === "true" ? true : false;
    const _obj = getObjectFromURLSearchParams(searchParams);
    if (!done_tagging) return;
    const obj = {
      ..._obj,
      ...diff,
    };

    if (
      getURLSearchParamsFromSearchParam(obj).toString() !==
      getURLSearchParamsFromSearchParam(_obj).toString()
    ) {
      navigate(
        `/search?${getURLSearchParamsFromSearchParam(
          {
            ...obj,
            updated_fields: [
              ...(_obj.updated_fields?.filter((v) => !Object.keys(diff).includes(v)) || []),
              ...Object.keys(diff),
            ],
          },
          true,
        ).toString()}`,
      );
    }
    setDiffQueue((prev) => prev.slice(1));
  }, [diffQueue, searchParams, setDiffQueue]);
  const flipStar = useCallback(
    (key: SearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      updateFilter({
        stars: stars.includes(starKey) ? stars.filter((i) => i !== starKey) : [...stars, starKey],
      });
    },
    [stars, updateFilter, getStarKey],
  );
  const setStar = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      const excludeKey = getExcludedKey(key, item);
      mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
        session: searchSessionForMixpanel,
        searchType: searchParams.get("type"),
        filterName: key === "service_loc" ? "service_location" : "hq_location",
        filterValue: item,
        filterAction: "set must have",
      });
      updateFilter({
        stars: [...stars.filter((s) => s !== starKey), starKey],
        excludes: excludes.filter((s) => s !== excludeKey),
      });
    },
    [stars, excludes, updateFilter, getStarKey, getExcludedKey, mxEvent],
  );
  const clearStar = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      const excludeKey = getExcludedKey(key, item);
      mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
        session: searchSessionForMixpanel,
        searchType: searchParams.get("type"),
        filterName: key === "service_loc" ? "service_location" : "hq_location",
        filterValue: item,
        filterAction: "set good to have",
      });
      updateFilter({
        stars: stars.filter((s) => s !== starKey),
        excludes: excludes.filter((s) => s !== excludeKey),
      });
    },
    [stars, excludes, updateFilter, getStarKey, getExcludedKey, mxEvent],
  );
  const clearAllStar = useCallback(
    (key: SearchOptionsType) => {
      const starKey = getStarKey(key);
      updateFilter({
        stars: stars.filter((prevItem) => !prevItem.startsWith(starKey)),
      });
    },
    [stars, updateFilter, getStarKey],
  );
  const addAllStar = useCallback(
    (key: SearchOptionsType, item: string[]) => {
      const starKeys = item.map((i) => getStarKey(key, i));
      updateFilter({
        stars: [...stars.filter((prevItem) => !starKeys.includes(prevItem)), ...starKeys],
      });
    },
    [stars, updateFilter, getStarKey],
  );
  const flipAllStar = useCallback(
    (key: SearchOptionsType, item: string[]) => {
      if (isAllStar(key, item)) clearAllStar(key);
      else addAllStar(key, item);
    },
    [stars, updateFilter, getStarKey, isAllStar, clearAllStar, addAllStar],
  );
  const setExcluded = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      const excludeKey = getExcludedKey(key, item);
      mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
        session: searchSessionForMixpanel,
        searchType: searchParams.get("type"),
        filterName: key === "service_loc" ? "service_location" : "hq_location",
        filterValue: item,
        filterAction: "set exclude",
      });
      updateFilter({
        stars: stars.filter((s) => s !== starKey),
        excludes: [...excludes.filter((s) => s !== excludeKey), excludeKey],
      });
    },
    [stars, excludes, updateFilter, getStarKey, getExcludedKey, mxEvent],
  );

  const renderServiceLocation = () => {
    return (
      <div className="mt-[-8px] flex flex-col px-3">
        <TagSelector<ILocation>
          onCheck={(t, check) => {
            mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
              session: searchSessionForMixpanel,
              searchType: searchParams.get("type"),
              filterName: "service_location",
              filterValue: reprLocation(t),
              filterAction: check ? "check" : "uncheck",
            });
          }}
          className="z-[4] "
          globalOpen={true}
          isPrioritySelector
          id="service-location"
          name="Service areas"
          renderInfo={() => (
            <TagInfo type="OTHERS" value={"SERVICE_AREA"} placements="rightStart" />
          )}
          value={serviceLocation}
          setValue={(ls) => {
            updateFilter({ service_loc: ls, ...(ls.length > 0 ? { any_location: false } : {}) });
          }}
          subs={serviceLocationSubs}
          setSubs={(ls) => updateFilter({ service_loc_subs: ls })}
          setValueAndSubs={(ls, lss) => updateFilter({ service_loc: ls, service_loc_subs: lss })}
          color="orange"
          size="sm"
          matched
          noIndent
          allowSelectAll
          getKey={(item: ILocation) => reprLocation(item)}
          getLabel={(item: ILocation) => reprLocation(item)}
          setStar={(item: ILocation) => setStar("service_loc", reprLocation(item))}
          flipStar={(item: ILocation) => flipStar("service_loc", reprLocation(item))}
          isStar={(item: ILocation) => isStar("service_loc", reprLocation(item))}
          isAllStar={(items: ILocation[]) =>
            isAllStar(
              "service_loc",
              items.map((l) => reprLocation(l)),
            )
          }
          flipAllStar={(items: ILocation[]) =>
            flipAllStar(
              "service_loc",
              items.map((l) => reprLocation(l)),
            )
          }
          clearStar={(item: ILocation) => clearStar("service_loc", reprLocation(item))}
          clearAllStar={() => clearAllStar("service_loc")}
          isExcluded={(item: ILocation) => isExcluded("service_loc", reprLocation(item))}
          setExcluded={(item: ILocation) => setExcluded("service_loc", reprLocation(item))}
          clearExcluded={(item: ILocation) => clearStar("service_loc", reprLocation(item))}
          hideCount
          hideCheckbox
          hideArrow
          removable
          seeAll
          twoLineCut={28}
        >
          <GibooLocationInput
            from_for_mixpanel="search"
            asMixPanelProperty={(l) => ({
              type: "service_location",
              name: reprLocation(l),
              search_session: session,
            })}
            setValue={setServiceLocationSubs}
            value={serviceLocation}
            excludes={serviceLocationSubs}
            inputClassName="!h-[45px] !w-full"
            id="service-location"
            hideItems
            icon
            onAdd={(loc) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "service_location",
                filterValue: reprLocation(loc),
                filterAction: "check",
              });
              updateFilter({
                any_location: false,
                service_loc: [
                  ...serviceLocation.filter((l) => reprLocation(l) !== reprLocation(loc)),
                  loc,
                ],
                service_loc_subs: [
                  ...serviceLocationSubs.filter((l) => reprLocation(l) !== reprLocation(loc)),
                  loc,
                ],
              });
            }}
            placeholder="Search service area"
            hideSearch
            addMoreLabelClass="!text-sm"
            addMoreLabel="Add your own service area"
          />
          {props.seeOpenToAnyLocation && (
            <Checkbox
              id="service-loc-open-to-all"
              className="mb-[-8px] mt-2"
              checked={anyLocation}
              onChange={() => {
                mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                  session: searchSessionForMixpanel,
                  searchType: searchParams.get("type"),
                  filterName: "any_location",
                  filterAction: anyLocation ? "uncheck" : "check",
                });
                updateFilter({
                  any_location: !anyLocation,
                  ...(!anyLocation ? { service_loc: [] } : {}),
                });
              }}
              label="Open to any geographical location"
            />
          )}
        </TagSelector>
      </div>
    );
  };
  const renderHqLocation = () => {
    return (
      <div className="mt-[-8px] flex flex-col px-3">
        <TagSelector<ILocation>
          onCheck={(t, check) => {
            mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
              session: searchSessionForMixpanel,
              searchType: searchParams.get("type"),
              filterName: "hq_location",
              filterValue: reprLocation(t),
              filterAction: check ? "check" : "uncheck",
            });
          }}
          className="z-[3]"
          globalOpen={true}
          id="hq-location"
          name={title || ""}
          renderInfo={() => <TagInfo type="OTHERS" value={"HQ"} placements="rightStart" />}
          value={hqLocation}
          setValue={(ls) => {
            updateFilter({ hq_loc: ls });
          }}
          subs={hqLocationSubs}
          setSubs={(ls) => updateFilter({ hq_loc_subs: ls })}
          setValueAndSubs={(ls, lss) => updateFilter({ hq_loc: ls, hq_loc_subs: lss })}
          color="orange"
          size="sm"
          matched
          noIndent
          allowSelectAll
          getKey={(item: ILocation) => reprLocation(item)}
          getLabel={(item: ILocation) => reprLocation(item)}
          setStar={(item: ILocation) => setStar("hq_loc", reprLocation(item))}
          flipStar={(item: ILocation) => flipStar("hq_loc", reprLocation(item))}
          isStar={(item: ILocation) => isStar("hq_loc", reprLocation(item))}
          isAllStar={(items: ILocation[]) =>
            isAllStar(
              "hq_loc",
              items.map((l) => reprLocation(l)),
            )
          }
          flipAllStar={(items: ILocation[]) =>
            flipAllStar(
              "hq_loc",
              items.map((l) => reprLocation(l)),
            )
          }
          clearStar={(item: ILocation) => clearStar("hq_loc", reprLocation(item))}
          clearAllStar={() => clearAllStar("hq_loc")}
          isExcluded={(item: ILocation) => isExcluded("hq_loc", reprLocation(item))}
          setExcluded={(item: ILocation) => setExcluded("hq_loc", reprLocation(item))}
          clearExcluded={(item: ILocation) => clearStar("hq_loc", reprLocation(item))}
          hideCount
          hideCheckbox
          hideArrow
          removable
          seeAll
        >
          <GibooLocationInput
            from_for_mixpanel="search"
            asMixPanelProperty={(l) => ({
              type: "hq_location",
              name: reprLocation(l),
              search_session: session,
            })}
            setValue={setHqLocationSubs}
            value={hqLocation}
            excludes={hqLocationSubs}
            inputClassName="!h-[45px] !w-full"
            id="hq-location"
            hideItems
            icon
            onAdd={(loc) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "hq_location",
                filterValue: reprLocation(loc),
                filterAction: "check",
              });
              updateFilter({
                hq_loc: [...hqLocation.filter((l) => reprLocation(l) !== reprLocation(loc)), loc],
                hq_loc_subs: [
                  ...hqLocationSubs.filter((l) => reprLocation(l) !== reprLocation(loc)),
                  loc,
                ],
              });
            }}
            placeholder="Search headquarter location"
            hideSearch
            addMoreLabelClass="!text-sm"
            addMoreLabel="Add your own HQ locations"
          />
        </TagSelector>
      </div>
    );
  };
  return (
    <FilterBase<ILocation[]>
      id={"service-location"}
      value={serviceLocation}
      size={
        serviceLocation.length + hqLocation.length + (anyLocation && searchType === "grant" ? 1 : 0)
      }
      setValue={(v) => {
        if (v) setServiceLocation(v);
      }}
      {...props}
      repr={(v: ILocation[] | undefined) => ""}
      reprWithSize={props.repr}
    >
      {({ open, close }) => (
        <div className="flex flex-col gap-4">
          <h5 className="mb-[-8px] pl-3 pt-2 font-semibold">Locations</h5>
          {renderServiceLocation()}
          {props.hideHQ ? null : (
            <>
              <div className="mb-[-8px] h-[1px] w-full bg-gray-300"></div>
              {renderHqLocation()}
            </>
          )}
        </div>
      )}
    </FilterBase>
  );
}
export default MergedLocationFilter;
