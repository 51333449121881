import classNames from "classnames";
import { GrantSearchResult, PastGrantSearchResult } from "../types/search";
import { isActiveGrant, isGrantPrivate } from "../utils/grant";
import { Popover, Whisper } from "rsuite";
import { INFO_GRANT_TYPE_OPTION } from "./TagInfo";

export default function TagGrantPresent({
  grant,
  className,
}: {
  grant: { search_type: string; type?: string };
  className?: string;
}) {
  const isPrivate =
    grant.search_type === "past_grant" ? false : isGrantPrivate(grant as GrantSearchResult);
  const infoValue =
    grant.search_type === "past_grant"
      ? "pastgrant"
      : (grant as any)?.type && (grant as GrantSearchResult)?.type === "grant-page"
      ? "virtual"
      : "opencall";
  const renderItem = () => {
    return (
      <div>
        {grant.search_type === "past_grant" ? (
          <div
            className={classNames(
              "flex h-6 items-center gap-1 whitespace-nowrap rounded-[20px] border border-gray-700 bg-gray-100 px-2 text-gray-900",
              className,
            )}
            aria-valuetext={"Past grant"}
          >
            <h6 aria-valuetext={"Past grant"}>Past grant</h6>
          </div>
        ) : (grant as any)?.type && (grant as GrantSearchResult)?.type === "grant-page" ? (
          <div className={classNames("giboo-gradient-bg h-6 rounded-[14px] p-[1px]", className)}>
            <div
              className={classNames(
                "flex h-full items-center whitespace-nowrap rounded-[13px] bg-purple-50 px-2",
              )}
              aria-valuetext={"Virtual grant"}
            >
              <h6 aria-valuetext={"Virtual grant"}>Virtual grant</h6>
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              "flex h-6 items-center gap-1 whitespace-nowrap rounded-[20px] border border-blue-500 bg-blue-50 px-2 text-gray-900",
              className,
            )}
            aria-valuetext={"Open call grant"}
          >
            <h6 aria-valuetext={"Open call grant"}>Open call grant</h6>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <Whisper
        trigger={"hover"}
        placement={"rightStart"}
        className="shadow-none"
        speaker={
          <Popover
            id="hover_tooltip"
            prefix="tooltip-hover-search-plus"
            className={classNames(
              "!z-[9999] !mt-[-2px] w-[323px] rounded border border-gray-300 bg-gray-50 p-4",
            )}
            arrow={false}
          >
            {INFO_GRANT_TYPE_OPTION[infoValue || ""]}
          </Popover>
        }
      >
        {renderItem()}
      </Whisper>
    </>
  );
}
