import classNames from "classnames";
import useFunderOrGrantName from "../../../../hooks/project/useFunderOrGrantName";
import useTask from "../../../../hooks/project/useTask";

function TaskName({
  project_id,
  task_id,
  wrapperClass,
  className,
}: {
  project_id: string | undefined;
  task_id: string | undefined;
  wrapperClass?: string;
  className?: string;
}) {
  const { data, isLoading } = useTask(task_id);
  const { data: name, isLoading: isNameLoading } = useFunderOrGrantName(
    data.target_id,
    data.target_type,
  );
  return (
    <div
      className={classNames(
        "loading-light-shimmer",
        isLoading || isNameLoading ? "loading-light-shimmer-on" : "",
        wrapperClass,
      )}
    >
      <div
        title={name}
        className={classNames(
          "loading-light-shimmer-child text-sm min-w-[50px]",
        )}
      >
        <div className={className}>{name}</div>
      </div>
    </div>
  );
}

export default TaskName;
