import { useCallback } from "react";
import { IProject, IProjectCreateRequest, IProjectUpdateRequest } from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { User, UserRoleEnum } from "../../app/userSlice";
import { useAppDispatch } from "../../app/store";
import projectSlice from "../../app/projectSlice";
import useOnboardingData from "../useOnboarding";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
import useOrgID from "../useOrgID";
interface ProjectState {
  data: IProject;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  update: (req: IProjectUpdateRequest) => Promise<IProject | undefined>;
  deleteThis: () => Promise<boolean>;
  hasEditPermission: boolean;
  setMemberRole: (user_id: string, role: number) => Promise<IProject | undefined>;
  revalidate: () => Promise<IProject | undefined>;
}
const defaultProject: IProject = {
  _id: "",
  updated_at: "",
  activity_desc: "",
  beneficiary: [],
  beneficiary_desc: "",
  completed: false,
  created_at: "",
  development_stage: [],
  focus_area: [],
  fund_to_raise: 0,
  name: "",
  org_id: "",
  outcome_desc: "",
  problem_desc: "",
  program: [],
  project_type: "",
  rolling: false,
  service_loc: [],
  summary: "",
  tagging_started: false,
  tasks: [],
  members: [],
  my_role: 0,
  project_timeline: 0,
  impact_desc: "",
  output_desc: "",
  budget_desc: "",
  service_area_desc: "",
  collaboration_desc: "",
  measurement_desc: "",
};
const getProject = async (id: string) => {
  if (!id) return new Promise<IProject>((resolve, reject) => reject());
  return axios
    .get(process.env.REACT_APP_API_URL + `/api/v2/projects/${id}`, config)
    .then((res) => res.data as IProject);
};
const createProject = async (req: IProjectCreateRequest) => {
  return axios
    .post(process.env.REACT_APP_API_URL + `/api/v2/projects`, req, config)
    .then((res) => res.data as IProject);
};
const updateProject = async (id: string, req: IProjectUpdateRequest) => {
  if (!id) return new Promise<IProject>((resolve, reject) => reject());
  return axios
    .put(process.env.REACT_APP_API_URL + `/api/v2/projects/${id}`, req, config)
    .then((res) => res.data as IProject);
};
const deleteProject = async (id: string) => {
  if (!id) return new Promise<boolean>((resolve, reject) => reject());
  return axios
    .delete(process.env.REACT_APP_API_URL + `/api/v2/projects/${id}`, config)
    .then((res) => res.data as boolean);
};
const getProjectMembers = async (id: string) => {
  if (!id) return new Promise<User[]>((resolve, reject) => reject());
  return axios
    .get(process.env.REACT_APP_API_URL + `/api/v2/projects/${id}/members`, config)
    .then((res) => res.data as User[]);
};
function useProject(id?: string): ProjectState {
  const org_id = useOrgID();
  const [onboardingData] = useOnboardingData();
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/${id}`;
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProject>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as IProject);
  };
  const { data, isLoading, error, isValidating } = useSWR<IProject>(
    id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const update = useCallback(
    async (req: IProjectUpdateRequest) => {
      if (!data) return;
      const options = {
        optimisticData: { ...data, ...req },
        rollbackOnError: true,
      };
      return mutate<IProject>(
        url,
        axios.put(url, req, config).then((response) => response.data),
        options,
      ).then((res) => {
        if (res) dispatch(projectSlice.actions.updateProject(res));
        return res;
      });
    },
    [url, data],
  );

  // this is also used for invitation, if user is not in the project, this can be an invitation
  const setMemberRole = useCallback(
    (user_id: string, role: number) => {
      if (!data || role < 0 || role > 5 || !Number.isInteger(role))
        return new Promise<IProject>((resolve, reject) => reject());
      const options = {
        optimisticData: {
          ...data,
          members: [
            ...data.members.filter((m) => m.user_id !== user_id),
            ...(role > 0 ? [{ user_id, role }] : []),
          ],
        },
        rollbackOnError: true,
      };
      return mutate<IProject>(
        url,
        axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/projects/${id}/setrole`,
            { to: user_id, role },
            config,
          )
          .then((response) => response.data),
        options,
      );
    },
    [url, data],
  );

  const deleteThis = useCallback(async () => {
    if (id)
      return deleteProject(id).then((res) => {
        if (res) dispatch(projectSlice.actions.deleteProject(id));
        return res;
      });
    else return new Promise<boolean>((resolve, reject) => reject());
  }, [id]);

  const revalidate = useCallback(() => mutate<IProject>(url), [url]);
  return {
    data: data || defaultProject,
    isLoading,
    error,
    isValidating,
    update,
    deleteThis,
    hasEditPermission:
      data?.org_id !== org_id
        ? false
        : data
        ? data.my_role >= UserRoleEnum.EDITOR
          ? true
          : data.permission === 2 && onboardingData.role >= 2
          ? true
          : false
        : false,
    setMemberRole,
    revalidate,
  };
}
export { getProject, createProject, updateProject, deleteProject, getProjectMembers };
export default useProject;
