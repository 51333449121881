import classNames from "classnames";
import React, { useState, useEffect, useRef, ReactNode } from "react";

interface SliderProps {
  slides?: ReactNode[];
  wrapperClass?: string;
  showFeatureIntro?: boolean;
  leftArrowClass?: string;
  rightArrowClass?: string;
  hideArrow?: boolean;
  iconColor?: "purple" | "white";
  targetId?: string | null;
  itemClass?: string;
  renderItems?: () => ReactNode;
  className?: string;
}

const Slider: React.FC<SliderProps> = ({
  slides,
  showFeatureIntro,
  hideArrow = false,
  rightArrowClass,
  leftArrowClass,
  iconColor = "white",
  itemClass,
  targetId,
  renderItems,
  className,
  ...props
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  useEffect(() => {
    const sliderElement = sliderRef.current;
    if (sliderElement) {
      setShowPrevButton(sliderElement.scrollLeft > 0);
      setShowNextButton(
        sliderElement.scrollLeft + sliderElement.clientWidth < sliderElement.scrollWidth,
      );
    }
  }, [sliderRef.current, slides]);

  const handleScroll = () => {
    const sliderElement = sliderRef.current;
    if (sliderElement) {
      setShowPrevButton(sliderElement.scrollLeft > 0);
      setShowNextButton(
        sliderElement.scrollLeft + sliderElement.clientWidth < sliderElement.scrollWidth &&
          sliderElement.scrollLeft + sliderElement.clientWidth !== sliderElement.scrollWidth,
      );
    }
  };

  const goToPrevSlide = () => {
    const sliderElement = sliderRef.current;
    if (sliderElement) {
      sliderElement.scrollBy({
        left: -sliderElement.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const goToNextSlide = () => {
    const sliderElement = sliderRef.current;
    if (sliderElement) {
      sliderElement.scrollBy({
        left: sliderElement.clientWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={classNames("relative flex h-fit w-full items-start", props.wrapperClass)}>
      <div
        ref={sliderRef}
        id="giboo-slider-component"
        className={classNames(
          "flex gap-5 overflow-x-scroll scrollbar-none",
          showFeatureIntro ? "w-fit" : "",
          className,
        )}
        onScroll={handleScroll}
      >
        {renderItems
          ? renderItems()
          : slides?.map((slide, index) => (
              <div
                key={index}
                className={classNames("flex w-fit transition-opacity duration-300 ", itemClass)}
              >
                {slide}
              </div>
            ))}
      </div>
      <div
        className={classNames(
          "absolute left-0 top-[44%] -translate-y-1/2 transform cursor-pointer",
          leftArrowClass,
        )}
      >
        {showPrevButton && !hideArrow && (
          <div
            className={classNames(
              "flex h-[100%] w-fit items-center pr-[2px] transition duration-300 ease-in-out",
            )}
            onClick={goToPrevSlide}
          >
            <div
              className={classNames(
                "z-10 grid h-9 w-9 place-items-center rounded-full  border-gray-100  text-gray-800 opacity-100 shadow hover:shadow-md",
                iconColor === "purple" ? "bg-purple-500" : "border bg-white",
              )}
            >
              <i
                className={classNames(
                  "gi-angle-left gi-sm mr-[2px] mt-[2px]",
                  iconColor === "purple" ? "text-white " : "text-gray-700 ",
                )}
              />
            </div>
          </div>
        )}
      </div>
      <div
        className={classNames(
          "absolute right-0 top-[44%] -translate-y-1/2 transform cursor-pointer",
          rightArrowClass,
        )}
      >
        {showNextButton && !hideArrow && (
          <div
            className={classNames(
              "ml-[2px] flex h-[100%] w-fit items-center transition duration-300 ease-in-out",
            )}
            onClick={goToNextSlide}
          >
            <div
              className={classNames(
                "z-10 grid  h-9 w-9 place-items-center rounded-full  border-gray-100  text-gray-800 opacity-100 shadow hover:shadow-md",
                iconColor === "purple" ? "bg-purple-500" : "border bg-white",
              )}
            >
              <i
                className={classNames(
                  "gi-angle-right gi-sm pl-[2px]",
                  iconColor === "purple" ? "text-white " : "text-gray-700 ",
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Slider;
