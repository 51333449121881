import axios from "axios";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import config from "../../api";
import { getOrgMembers } from "../../services/org.services";
import { IOrgManualMemberUpdateRequest, IOrgMember } from "../../types/org";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
import useOnboardingData from "../useOnboarding";
import { ORG_MEMBER_DEFAULT_LIMIT } from "./useOrgManualMembers";
interface ProjectInteractionState {
  data: IOrgMember[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  hasEditPermission: boolean;
  revalidate: () => Promise<IOrgMember[] | undefined>;
  setMemberRole: (user_id: string, role: number) => Promise<any> | undefined;
  getOptimisticDataOnUpdate: (
    id: string | undefined,
    req: IOrgManualMemberUpdateRequest,
  ) => IOrgMember[] | undefined;
}
const emptyList: IOrgMember[] = [];
function useOrgMembers(
  org_id?: string,
  limit = ORG_MEMBER_DEFAULT_LIMIT,
  only_accepted = true,
): ProjectInteractionState {
  const [onboardingData] = useOnboardingData();
  const url =
    process.env.REACT_APP_API_URL +
    `/api/v2/orgs/${org_id}/members?limit=${limit}&only_accepted=${only_accepted}`;
  const { mutate, cache } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url || !org_id) return new Promise<IOrgMember[]>((resolve, reject) => reject());
    return getOrgMembers(org_id, limit, only_accepted).then((res) =>
      res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
    );
  };
  const { data, isLoading, error, isValidating } = useSWR<IOrgMember[]>(
    org_id && org_id.length === 24 ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const setMemberRole = useCallback(
    async (user_id: string, role: number) => {
      if (!data || role < 0 || role > 5 || !Number.isInteger(role) || !org_id)
        return new Promise((resolve, reject) => reject());
      return axios
        .post(
          process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/setrole`,
          { to: user_id, role, origin: window.location.origin },
          config,
        )
        .then((response) => {
          mutate(url);
          return response.data;
        });
    },
    [org_id, data, url],
  );
  const revalidate = useCallback(() => mutate<IOrgMember[]>(url), [url]);
  const getOptimisticDataOnUpdate = useCallback(
    (id: string | undefined, req: IOrgManualMemberUpdateRequest): IOrgMember[] | undefined => {
      const c = cache.get(url);
      const data = c?.data as IOrgMember[] | undefined;
      if (!data) return undefined;
      const foundIndex = data.findIndex((m) => m._id === id);
      if (foundIndex < 0) return data;
      return [
        ...data.slice(0, foundIndex),
        {
          ...data[foundIndex],
          ...req,
        },
        ...data.slice(foundIndex + 1),
      ];
    },
    [cache, url],
  );
  return {
    data: data || emptyList,
    isLoading,
    error,
    hasEditPermission: onboardingData.role >= 4 ? true : false,
    setMemberRole,
    isValidating,
    revalidate,
    getOptimisticDataOnUpdate,
  };
}
export default useOrgMembers;
