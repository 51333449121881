import axios from "axios";
import config from "../api";
import { Onboarding } from "../app/onboarding";
import { User } from "../app/userSlice";
import { IOrgManualMember, IOrgMember } from "../types/org";
import { ORG_MEMBER_DEFAULT_LIMIT } from "../hooks/project/useOrgManualMembers";

const createOrg = () => {
  return new Promise<Onboarding>((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL + `/api/v2/orgs`, {}, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const deleteOrg = (org_id: string) => {
  return new Promise<boolean>((resolve, reject) => {
    axios
      .delete(process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}`, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
const getOrgMembers = (org_id: string, limit = ORG_MEMBER_DEFAULT_LIMIT, only_accepted = true) => {
  return new Promise<IOrgMember[]>((resolve, reject) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/v2/orgs/${org_id}/members?limit=${limit}&only_accepted=${only_accepted}`,
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getOrgManualMembers = (org_id: string, limit = ORG_MEMBER_DEFAULT_LIMIT) => {
  return new Promise<IOrgManualMember[]>((resolve, reject) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/orgmembers?limit=${limit}`,
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const sendOrgInvitation = (org_id: string, to: string, role?: number, project_id?: string) => {
  return new Promise<string>((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/invite`,
        { to, project_id, origin: window.location.origin, role: role || 1 },
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export { createOrg, getOrgMembers, getOrgManualMembers, sendOrgInvitation, deleteOrg };
