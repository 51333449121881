import cr from "./img/copyright.svg";
import logo from "./img/footer-logo.svg";

function Footer() {
  return (
    <footer className="giboo-footer p-2 md:p-4" style={{ background: "#21143C", color: "white" }}>
      <div className="px-5 md:px-20">
        <div className="flex flex-wrap justify-between pb-7 md:flex-nowrap">
          <div className="flex flex-col">
            <div>
              <img src={logo} className="h-[70px] md:h-fit" />
            </div>
            <p className="pt-4 text-sm md:text-base">Enter the new era of philanthropy</p>
          </div>
          <div className="my-auto mt-4 md:mt-0">
            <p className="pb-4 text-sm md:text-base">Legal</p>
            <h5 className="pb-2 text-sm md:text-base">
              <a href="/terms" target="_blank" style={{ color: "white", fontWeight: 400 }}>
                Terms of service
              </a>
            </h5>
            <h5 className="text-sm md:text-base">
              <a href="/privacy" target="_blank" style={{ color: "white", fontWeight: 400 }}>
                Privacy policy
              </a>
            </h5>
          </div>
        </div>
        <div className="flex flex-row pt-7" style={{ borderTop: "solid 1px #5C38A7" }}>
          <img src={cr} />
          <h6 className="px-1 pt-px">2023 Giboo. All rights reserved.</h6>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
