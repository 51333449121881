import {
  AnyAction,
  Dispatch,
  PayloadAction,
  ThunkAction,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { debounce } from "lodash";
import config from "../api";
import { ILocation } from "../types/location";
import { Onboarding, OnboardingData, OnboardingRequest } from "./onboarding";
import { RootState } from "./store";

import { TreeItem } from "../components/TreeSelector";
import { setUserDictionaryValue } from "../hooks/useUserDictionary";
import { createOrg } from "../services/org.services";
import { NPO, NPOProfile } from "../types/npo";
import {
  TypeRole,
  TypeStage,
  TypeTaxExemption,
  planningIncorpKeys,
  roleKeys,
  stageKeys,
} from "../types/onboarding";
import {
  ISearchParam,
  NPOSearchResult,
  SearchQuery,
  getSearchQueryFromISearchParam,
  getValidTaxExemption,
} from "../types/search";
import { isNumeric } from "../utils/dataTypeHelper";

const excludesWhenRequest = {
  _id: undefined,
  npo: undefined,
  npo_profile: undefined,
  owner: undefined,
};
interface TaxonomyState {
  isTaxonomyFetched: boolean;
  focus_area: TreeItem[];
  beneficiary: TreeItem[];
  program: TreeItem[];
}
interface OnboardingState {
  isFetched: boolean;
  isOrgsFetched: boolean;
  data: Onboarding;
  orgs: Onboarding[];
  selected: string;
  taxonomy?: TaxonomyState;
}

const initialOnboarding = {
  _id: "",
  completed: false,
  accepted: false,
  step: 0,
  roles: [],
  role: 0,
  created_at: new Date().toString(),
  updated_at: new Date().toString(),
  joined: new Date().toString(),
  type: 1,
  user_id: "",
};
const token = JSON.parse(sessionStorage.getItem("token") || "{}");
const selectedOrg = sessionStorage.getItem(`selected_org_${token?._id}`) || "";
const initialState: OnboardingState = {
  isFetched: false,
  isOrgsFetched: false,
  data: initialOnboarding,
  orgs: [],
  selected: selectedOrg,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<OnboardingRequest>) => {
      state.data = { ...state.data, ...action.payload };
      state.orgs = [
        ...state.orgs.filter((o) => o._id !== state.data._id),
        { ...state.data, ...action.payload },
      ];
    },
    fetch: (state, action: PayloadAction<OnboardingState>) => {
      state.isFetched = action.payload.isFetched;
      state.isOrgsFetched = action.payload.isOrgsFetched;
      state.data = action.payload.data;
      state.orgs = action.payload.orgs;
      state.selected = action.payload.selected;
      sessionStorage.setItem(
        `selected_org_${action.payload.data.user_id}`,
        action.payload.selected,
      );
      setUserDictionaryValue("selected_org", action.payload.selected);
    },
    next: (state) => {
      state.data.step = state.data.step ? state.data.step + 1 : 1;
    },
    back: (state) => {
      state.data.step = state.data.step ? state.data.step - 1 : 0;
    },
    clear: (state) => {
      state.orgs = [];
      state.data = initialOnboarding;
      state.isFetched = false;
      state.isOrgsFetched = false;
    },
    loading: (state) => {
      state.isFetched = false;
    },
  },
});

const _fetchAndSelectOrgAsync = async (
  dispatch: Dispatch,
  getState: () => RootState,
  id: string,
  fetchedData?: Onboarding[],
) => {
  const state = getState();
  if (!id)
    return dispatch(
      onboardingSlice.actions.fetch({
        ...state.onboarding,
        selected: "",
        data: { _id: "", user_id: state.user?.user?._id },
      }),
    );
  // axios
  //   .get(
  //     process.env.REACT_APP_API_URL +
  //       `/api/v2/search/mso?user_id=${state.user.user._id}&org_id=${id}`,
  //     config,
  //   )
  //   .then((response) => response.data)
  //   .then((data: ISearchHistory) => {
  //     if (data) dispatch(moreSearchOptionSlice.actions.fetch(data));
  //   });
  let found = fetchedData?.find((o) => o._id === id);
  if (!found) {
    found = await axios
      .get(process.env.REACT_APP_API_URL + `/api/v2/orgs/${id}`, config)
      .then((response) => response.data)
      .then((data: Onboarding) => data);
  }
  return found
    ? Promise.resolve(
        dispatch(
          onboardingSlice.actions.fetch({
            ...state.onboarding,
            isFetched: true,
            selected: id,
            data: {
              ...found,
              user_id: state.user.user?._id as string,
            },
          }),
        ),
      ).then(() => true)
    : false;
};
const fetchAndSelectOrgAsync = (id: string): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) =>
    _fetchAndSelectOrgAsync(dispatch, getState, id);
};
const createNewOrgAsync = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    return createOrg().then((res) => _fetchOnboardingAsync(dispatch, getState, res._id || ""));
  };
};

const _fetchOnboardingAsync = (dispatch: Dispatch, getState: () => RootState, id?: string) => {
  // read all my orgs, and then select org
  // priority
  // 1. if len(orgs)==1 -> select the one
  // 2. given id parameter
  // 3. stored id in sessionstorage
  // 4. the first org in orgs
  // not found -> create new org
  const state = getState();
  axios
    .get(process.env.REACT_APP_API_URL + `/api/v2/orgs/my/${state.user?.user?._id}`, config)
    .then((response) => response.data)
    .then((data: Onboarding[]) => {
      dispatch(
        onboardingSlice.actions.fetch({
          ...state.onboarding,
          isOrgsFetched: true,
          orgs: data,
        }),
      );
      if (data.length === 1)
        return _fetchAndSelectOrgAsync(dispatch, getState, data[0]._id || "", data);
      else {
        const selectedOrg = sessionStorage.getItem(`selected_org_${state.user?.user?._id}`) || "";
        const priority =
          data.find((o) => o._id === id) || data.find((o) => o._id === selectedOrg) || data?.[0];
        if (priority) {
          return _fetchAndSelectOrgAsync(dispatch, getState, priority._id, data);
        } else {
          createOrg().then((res) => _fetchAndSelectOrgAsync(dispatch, getState, res._id || ""));
        }
      }
    })
    .catch((e) => {
      // console.log(e);
      dispatch(
        onboardingSlice.actions.fetch({
          ...initialState,
          isOrgsFetched: false,
          taxonomy: { isTaxonomyFetched: false, focus_area: [], beneficiary: [], program: [] },
        }),
      );
    });
};
const fetchOnboardingAsync = (id?: string): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) =>
    _fetchOnboardingAsync(dispatch, getState, id);
};
const updateNPO = (dispatch: Dispatch, npo_id: string) => {
  dispatch(
    onboardingSlice.actions.update({
      npo: undefined,
      npo_profile: undefined,
    }),
  );
  axios
    .get(
      process.env.REACT_APP_API_URL + `/api/v2/search/npos_searchresult?id=${npo_id}&by=_id`,
      config,
    )
    .then((response) => response.data)
    .then((data: NPOSearchResult[]) => {
      if (data && data.length > 0) return data[0];
      else throw "no result";
    })
    .then((data: NPOSearchResult) => {
      // console.log("update npo", data);
      const addrs = data?.address?.split(",");
      let zip = "";
      let address1 = "";
      if (addrs) {
        if (isNumeric(addrs[addrs.length - 1].trim())) {
          zip = addrs[addrs.length - 1].trim();
          address1 = addrs.slice(0, addrs.length - 3).join(",");
        } else address1 = addrs.slice(0, addrs.length - 2).join(",");
      }
      dispatch(
        onboardingSlice.actions.update({
          npo: data,
          city: data?.location?.city || data?.location?.county_name || "",
          address1: address1 || data.address || "",
          zipcode: zip || "",
          state_id: data?.location?.state_id || "",
          website: data?.website || "",
          npo_profile: data,
          npo_uid: data?.uid,
        }),
      );
    })
    .catch((e) => {
      // console.log(e);
      onboardingSlice.actions.update({
        npo: undefined,
        npo_profile: undefined,
        npo_uid: undefined,
      });
    });
};
const updateOnboarding = (
  id: string | undefined,
  user_id: string | undefined,
  data: Onboarding,
) => {
  if (id === undefined || user_id === undefined) return;
  const unset = Object.keys(data)
    .filter((key) => data[key as keyof Onboarding] === undefined && key !== "_id")
    .reduce((prev, k) => ({ ...prev, [k]: "" }), {});
  const isOwner = data.role && data.role >= 5;
  axios
    .put(
      process.env.REACT_APP_API_URL + `/api/v2/orgs/${id}`,
      isOwner
        ? {
            ...data,
            ...excludesWhenRequest,
          }
        : {
            step: data.step,
            roles: data.roles,
            accepted: data.accepted,
          },
      config,
    )
    .then((response) => {
      // console.log("updateOnboarding", data, response);
    })
    .catch((e) => {
      // console.log(e);
    });
};

// debounced function should update all data at the moment requesting to the server
// because it kills the previous call
const debouncedUpdateOnboardingAll = debounce(
  (getState: () => RootState) => {
    const state = getState();
    const unset = Object.keys(state.onboarding.data)
      .filter(
        (key) => state.onboarding.data[key as keyof Onboarding] === undefined && key !== "_id",
      )
      .reduce((prev, k) => ({ ...prev, [k]: "" }), {});
    const isOwner = state.onboarding.data.role && state.onboarding.data.role >= 5;
    axios
      .put(
        process.env.REACT_APP_API_URL + `/api/v2/orgs/${state.onboarding.data._id}`,
        isOwner
          ? {
              ...state.onboarding.data,
              ...excludesWhenRequest,
            }
          : {
              step: state.onboarding.data.step,
              roles: state.onboarding.data.roles,
              accepted: state.onboarding.data.accepted,
            },
        config,
      )
      .then((response) => {
        // console.log("debouncedUpdateOnboardingAll", response);
      })
      .catch((e) => {
        // console.log(e);
      });
  },
  1500,
  { maxWait: 3000 },
);
const updateOnboardingAsync = (
  data: OnboardingRequest,
  step = 0,
  option: { updateImmediately?: boolean; updateOnlyRequestedData?: boolean } = {},
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const nextStep = state.onboarding.data.step ? state.onboarding.data.step + step : step;
    dispatch(
      onboardingSlice.actions.update({ ...data, ...(step !== 0 ? { step: nextStep } : {}) }),
    ); // update locally first and debounce request
    if (data.npo_id) updateNPO(dispatch, data.npo_id);
    if (option.updateImmediately) {
      updateOnboarding(
        state.onboarding.data._id,
        state.user.user?._id,
        option.updateOnlyRequestedData
          ? {
              ...data,
              step: nextStep,
            }
          : {
              ...state.onboarding.data,
              ...data,
              step: nextStep,
              ...excludesWhenRequest,
            },
      );
    } else {
      debouncedUpdateOnboardingAll(getState);
    }
  };
};

const selectSelf = (state: RootState) => state;
const isOnboardingFetched = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.onboarding.isFetched,
);
const isOnboardingCompleted = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.onboarding.data.completed || false,
);
const getOnboardingStatus = createDraftSafeSelector(
  selectSelf,
  (
    state: RootState,
  ): {
    _id?: string;
    completed?: boolean;
    type?: number;
    step?: number;
  } & {
    isFetched: boolean;
    isOrgsFetched: boolean;
    selected: string;
    role: number;
    accepted: boolean;
  } => ({
    selected: state.onboarding.selected,
    isFetched: state.onboarding.isFetched,
    isOrgsFetched: state.onboarding.isOrgsFetched,
    _id: state.onboarding.data._id,
    completed: state.onboarding.data.completed,
    type: state.onboarding.data.type,
    step: state.onboarding.data.type ? state.onboarding.data.step : undefined,
    role: state.onboarding.data.role || 0,
    accepted: state.onboarding.data.accepted || false,
  }),
);
const selectBasicInformation = createDraftSafeSelector(
  selectSelf,
  (
    state: RootState,
  ): {
    npo_id?: string;
    npo_name?: string;
    npo_ein?: string;
    npo_location?: string;
  } => {
    switch (state.onboarding.data.type) {
      case 1:
        return {
          npo_id: state.onboarding.data.npo_id,
          npo_name: state.onboarding.data.npo_name_requested || state.onboarding.data.npo?.name,
          npo_ein: state.onboarding.data.npo?.ein,
          npo_location: state.onboarding.data.npo?.address,
        };
      case 2:
        return {
          npo_name: state.onboarding.data.npo_name_requested,
          npo_ein: state.onboarding.data.npo_ein_requested,
          npo_location: state.onboarding.data.npo_location_requested,
        };
      case 3:
        return {
          npo_name: state.onboarding.data.npo_name_requested,
          npo_ein: state.onboarding.data.npo_ein_requested,
          npo_location: state.onboarding.data.npo_location_requested,
        };
      case 5:
        return {
          npo_name: state.onboarding.data.npo_name_requested,
          npo_ein: state.onboarding.data.npo_ein_requested,
          npo_location: state.onboarding.data.npo_location_requested,
        };
      default:
        return {};
    }
  },
);
const selectRoles = createDraftSafeSelector(
  selectSelf,
  (state: RootState): TypeRole[] =>
    state.onboarding.data.roles
      ?.filter(
        (item: any) => item.startsWith("*") || roleKeys.find((validName) => validName === item),
      )
      .map((item: any) => item as TypeRole) || [],
);
const selectStage = createDraftSafeSelector(selectSelf, (state: RootState): TypeStage | undefined =>
  stageKeys.find((validName) => validName === state.onboarding.data.stage_requested)
    ? state.onboarding.data.stage_requested
    : undefined,
);
const selectNPO = createDraftSafeSelector(
  selectSelf,
  (state: RootState): NPO | undefined => state.onboarding.data.npo,
);
const selectNPOProfile = createDraftSafeSelector(
  selectSelf,
  (state: RootState): NPOProfile | undefined => state.onboarding.data.npo_profile,
);
const selectNPOInfo = createDraftSafeSelector(
  selectNPO,
  selectNPOProfile,
  (
    npo: NPO | undefined,
    npo_profile: NPOProfile | undefined,
  ): {
    tax_exemption?: TypeTaxExemption | undefined;
    operating_budget?: number;
    n_employee?: number;
    n_volunteer?: number;
    mission?: string;
    tax_year?: number;
    return_code?: string;
    location?: ILocation;
  } => ({
    tax_exemption: getValidTaxExemption(npo_profile?.tax_exemption),
    n_employee: npo_profile?.n_employee,
    n_volunteer: npo_profile?.n_volunteer,
    mission: npo?.mission,
    tax_year: npo?.tax_year,
    return_code: npo?.return_code,
    operating_budget: npo?.total_expenses,
    location: npo?.location,
  }),
);
const selectNPOInfoRequested = createDraftSafeSelector(
  selectSelf,
  (
    state: RootState,
  ): {
    tax_exemption_requested?: TypeTaxExemption | undefined;
    operating_budget_requested?: number;
    n_employee_requested?: number;
    n_volunteer_requested?: number;
    mission_requested?: string;
  } => ({
    tax_exemption_requested: getValidTaxExemption(state.onboarding.data.tax_exemption_requested),
    operating_budget_requested: state.onboarding.data.operating_budget_requested,
    n_employee_requested: state.onboarding.data.n_employee_requested,
    n_volunteer_requested: state.onboarding.data.n_volunteer_requested,
    mission_requested: state.onboarding.data.mission_requested,
  }),
);
const selectPhilanthropyRecommended = createDraftSafeSelector(
  selectSelf,
  (
    state: RootState,
  ): {
    focus_area?: string[];
    beneficiary?: string[];
    program?: string[];
    service_loc?: ILocation[];
  } => ({
    focus_area: state.onboarding.data.npo_profile?.focus_area,
    beneficiary: state.onboarding.data.npo_profile?.beneficiary,
    program: state.onboarding.data.npo_profile?.activity,
    service_loc: state.onboarding.data.npo_profile?.service_loc,
  }),
);
const selectAdditionalInfo = createDraftSafeSelector(
  selectSelf,
  (
    state: RootState,
  ): {
    focus_area_text?: string;
    beneficiary_text?: string;
    strategy_text?: string;
    summary?: string;
  } => ({
    focus_area_text: state.onboarding.data.focus_area_text,
    beneficiary_text: state.onboarding.data.beneficiary_text,
    strategy_text: state.onboarding.data.strategy_text,
    summary: state.onboarding.data.summary,
  }),
);
const selectPhilanthropyRequested = createDraftSafeSelector(
  selectSelf,
  (
    state: RootState,
  ): {
    focus_area_requested?: string[];
    beneficiary_requested?: string[];
    program_requested?: string[];
    service_loc_requested?: ILocation[];
  } => ({
    focus_area_requested: state.onboarding.data.focus_area_requested,
    beneficiary_requested: state.onboarding.data.beneficiary_requested,
    program_requested: state.onboarding.data.program_requested,
    service_loc_requested: state.onboarding.data.service_loc_requested,
  }),
);
const selectDescription = createDraftSafeSelector(
  selectSelf,
  (
    state: RootState,
  ): {
    focus_area_text?: string;
    beneficiary_text?: string;
    strategy_text?: string;
  } => ({
    focus_area_text: state.onboarding.data.focus_area_text,
    beneficiary_text: state.onboarding.data.beneficiary_text,
    strategy_text: state.onboarding.data.strategy_text,
  }),
);

export const __getOnboardingData = (onboarding: Onboarding, user_id: string): OnboardingData => {
  const common = {
    _id: onboarding._id || "",
    user_id: user_id,
    owner: onboarding.owner,
    completed: onboarding.completed || false,
    type: onboarding.type || 0,
    step: onboarding.step || 0,
    npo_id: onboarding.npo_id,
    focus_area: onboarding.focus_area_requested || [],
    beneficiary: onboarding.beneficiary_requested || [],
    program: onboarding.program_requested || [],
    service_loc: onboarding.service_loc_requested || [],
    role: onboarding.role,
    joined: onboarding.joined,
    accepted: onboarding.accepted,
    roles:
      onboarding.roles
        ?.filter(
          (item: any) => item.startsWith("*") || roleKeys.find((validName) => validName === item),
        )
        .map((item: any) => item as TypeRole) || [],
    stage:
      (stageKeys.find((validName) => validName === onboarding.stage_requested) as TypeStage) ||
      undefined,
    mission: onboarding.mission_requested || undefined,
    ein: onboarding.npo_ein_requested || "",
    address1: onboarding.address1 || "",
    address2: onboarding.address2 || "",
    city: onboarding.city || "",
    state_id: onboarding.state_id || "",
    country: onboarding.country || "",
    zipcode: onboarding.zipcode || "",
    website: onboarding.website || "",
    activity: onboarding.activity || "",

    photo_object_key: onboarding.photo_object_key,
    background_object_key: onboarding.background_object_key,
    tax_exemption_object_key: onboarding.tax_exemption_object_key,
    sponsorship_object_key: onboarding.sponsorship_object_key,

    incorporated_year: onboarding.incorporated_year,
    about: onboarding.about,
    summary: onboarding.summary,
    phone: onboarding.phone,
    email: onboarding.email,
    verified: onboarding.verified,

    focus_area_text: onboarding.focus_area_text,
    beneficiary_text: onboarding.beneficiary_text,
    strategy_text: onboarding.strategy_text,
    pending: onboarding.pending,
    npo_uid: onboarding.npo_uid,
    // };
    // const type1and2 = {
    tax_exemption: getValidTaxExemption(onboarding.tax_exemption_requested),
    operating_budget: onboarding.operating_budget_requested,
    n_employee: onboarding.n_employee_requested,
    n_volunteer: onboarding.n_volunteer_requested,

    org_type: onboarding.org_type,
    n_grants_received: onboarding.n_grants_received,
    grant_amount_min: onboarding.grant_amount_min,
    grant_amount_max: onboarding.grant_amount_max,
    org_status: onboarding.org_status || "nonprofit",

    created_at: onboarding.created_at || new Date().toString(),
    updated_at: onboarding.updated_at || new Date().toString(),
  };
  switch (onboarding.type) {
    case 1:
      return {
        ...common,
        // ...type1and2,
        npo_name: onboarding.npo_name_requested || onboarding.npo?.name || "",
        npo_ein: onboarding.npo?.ein || "",
        npo_address: onboarding.npo?.address || "",
        website: onboarding?.website || "",
        npo_city: onboarding.npo?.city || "",
      };
    case 2:
      return {
        ...common,
        // ...type1and2,
        npo_name: onboarding.npo_name_requested || "",
        npo_ein: onboarding.npo_ein_requested || "",
        npo_address: onboarding.npo_location_requested || "",
        website: onboarding?.website || "",
        city: onboarding?.city || "",
      };
    case 3:
      return {
        ...common,
        npo_name: onboarding.npo_name_requested || "",
        npo_ein: onboarding.npo_ein_requested || "",
        npo_address: onboarding.npo_location_requested || "",
        website: onboarding?.website || "",
        city: onboarding?.city || "",
        incorp_planned: planningIncorpKeys.find(
          (validName) => validName === onboarding.incorp_planned,
        )
          ? onboarding.incorp_planned
          : undefined,
      };
    case 5:
      return {
        ...common,
        npo_name: onboarding.npo_name_requested || "",
        npo_ein: onboarding.npo_ein_requested || "",
        npo_address: onboarding.npo_location_requested || "",
        website: onboarding?.website || "",
        city: onboarding?.city || "",
        country: onboarding?.country || "",
        incorp_planned: planningIncorpKeys.find(
          (validName) => validName === onboarding.incorp_planned,
        )
          ? onboarding.incorp_planned
          : undefined,
      };
    default:
      return {
        ...common,
      };
  }
};
const _getOnboardingData = (state: RootState): OnboardingData => {
  return __getOnboardingData(state.onboarding.data, state.user?.user?._id);
};
const selectOnboardingDataID = createDraftSafeSelector(
  (state: RootState) => state,
  (state: RootState): string => state.onboarding.data._id,
);

const selectOnboardingData = createDraftSafeSelector(
  (state: RootState) => state,
  (state: RootState): OnboardingData => _getOnboardingData(state),
);

const selectOrgs = createDraftSafeSelector(
  (state: RootState) => state,
  (state: RootState): OnboardingData[] =>
    state.onboarding.orgs.map((o: Onboarding) => __getOnboardingData(o, state.user?.user?._id)),
);
const updateOnboardingLocal = (
  data: OnboardingRequest,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch) => {
    dispatch(onboardingSlice.actions.update({ ...data, step: 0 })); // update locally
  };
};
const getISearchParamFromOnboardingData = (onboardingData: OnboardingData): ISearchParam => {
  const options: ISearchParam = {
    text_query: onboardingData.text_query || "",
    focus_area: onboardingData.focus_area,
    beneficiary: onboardingData.beneficiary,
    program: onboardingData.program,
    service_loc: onboardingData.service_loc,
    hq_loc: [],
    mission: onboardingData.mission || "",
    stars: [],
    excludes: [],
    focus_area_subs: onboardingData.focus_area,
    beneficiary_subs: onboardingData.beneficiary,
    program_subs: onboardingData.program,
    service_loc_subs: onboardingData.service_loc,
    hq_loc_subs: onboardingData.service_loc,
    tagged_focus_area: [],
    tagged_beneficiary: [],
    tagged_program: [],
    type: ["virtual_grant"],
    solicitation: [], // SOLICITATION_OPTION.map((v) => v.value),
    funder_assets: [], // ASSET_SIZE_OPTION.map((v) => v.value),
    contact: [],
    funder_type: [], // DEFAULT_FUNDER_TYPE,
    funding_type: [],
    funding_size: [], // FUNDING_SIZE_OPTION.map((v) => v.value),
    grant_deadline: undefined,
    grant_deadline_filter: [],
    grant_type: [], // GRANT_TYPE_OPTION.map((v) => v.value),
    grantee_type: [],
    sortby: "total_matched_grant_amount",
    grantee_stage: [], // STAGE_OPTION.map((v) => v.value),
    done_tagging: false,
    done_pre_tagging: false,
    from_more_search_option: false,
    selected: 0,
    current_grantee_stage: onboardingData.stage || "buildup",
    any_location: false,
    npo_uid: onboardingData.npo_uid,
    previous_funders: [],
    incorporated_status: [],
    npo_assets: [],
    npo_grant_amount_min: undefined,
    npo_grant_amount_max: undefined,
    npo_month_of_liquid: [],
    tax_exempt_status: [],
    year_of_formation_min: undefined,
    year_of_formation_max: undefined,
    npo_financial_exact_value_filters: [],
    npo_financial_per_revenue_filters: [],
    npo_financial_per_expense_filters: [],
  } as ISearchParam;
  return options;
};
const getSearchQueryFromOnboardingData = (onboardingData: OnboardingData): SearchQuery => {
  const p = getISearchParamFromOnboardingData(onboardingData);
  const q: SearchQuery = getSearchQueryFromISearchParam(onboardingData.user_id, onboardingData, p);
  return q;
};
export {
  createNewOrgAsync,
  fetchAndSelectOrgAsync,
  fetchOnboardingAsync,
  getISearchParamFromOnboardingData,
  getOnboardingStatus,
  getSearchQueryFromOnboardingData,
  isOnboardingCompleted,
  isOnboardingFetched,
  selectAdditionalInfo,
  selectBasicInformation,
  selectDescription,
  selectNPOInfo,
  selectNPOInfoRequested,
  selectOnboardingData,
  selectOnboardingDataID,
  selectOrgs,
  selectPhilanthropyRecommended,
  selectPhilanthropyRequested,
  selectRoles,
  selectStage,
  updateOnboardingAsync,
  updateOnboardingLocal,
};
export default onboardingSlice;
