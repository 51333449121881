import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getValidNumber, reprTimeDistance } from "../utils/formatHelper";
import useSavedItems from "../hooks/save/useSavedItems";
import Button from "../components/tailwind/Button";
import { SaveList } from "../components/SaveList";
import useMySavedList from "../hooks/save/useMySavedList";
import Spinner from "../components/Spinner";
import classNames from "classnames";
import RadioBoxPopup from "../components/radio/RadioBoxPopup";
import LoadingPhilanthropy from "../components/LoadingPhilanthropy";
import NO_RESULT from "../assets/images/no-result.svg";
import {
  FunderSearchResult,
  GrantSearchResult,
  NPOSearchResult,
  PastGrantSearchResult,
  UnifiedSearchResult,
  defaultSearchQuery,
  getSearchResultKey,
} from "../types/search";
import PastGrantCard from "../components/search/PastGrantCard";
import NPOCard from "../components/search/NPOCard";
import GrantCard from "../components/search/GrantCard";
import FunderCard from "../components/search/FunderCard";
import DonorDetailView from "./donor-detail-view/DonorDetailView";
import { Pagination } from "rsuite";
import PastGrantView from "./npo/grant/PastGrantView";
import useScrollPosition from "../hooks/useScrollPosition";
import GrantView from "./npo/grant/GrantView";
import NpoView from "./npo/dashboard/pages/NpoView";
import useSavedDetailedItems from "../hooks/save/useSavedDetailedItems";
import PlainButton from "../components/PlainButton";
import LeftInnerIconInput from "../components/LeftInnerIconInput";
import { useSWRConfig } from "swr";
import useOrgID from "../hooks/useOrgID";
import useSave from "../hooks/save/useSave";
import ClickToEditInput from "../components/input/ClickToEditInput";
import { getSearchQueryFromOnboardingData } from "../app/onboardingSlice";
import useOnboardingData from "../hooks/useOnboarding";
import usePageTitle from "../hooks/usePagetitle";

const UNIT = 20;
export default function SavedItemListView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const org_id = useOrgID();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  useEffect(() => {
    const _page = getValidNumber(searchParams.get("page"), 1);
    setPage(_page);
  }, [searchParams, setPage]);
  const { saved, data, isLoading } = useSavedDetailedItems(id, UNIT, (page - 1) * UNIT);
  const { data: list, isLoading: isLoadingList, updateSavedList } = useMySavedList();
  const savedList = list.find((l) => l._id === id);
  usePageTitle(savedList?.name ? `${savedList?.name} | Saved` : "Saved");
  const [selected, setSelected] = useState<number>(0);
  const [onboardingData] = useOnboardingData();
  const onboardingQuery = getSearchQueryFromOnboardingData(onboardingData);

  const refElement = useRef<HTMLDivElement | null>(null);
  const { y: scrollY } = useScrollPosition(refElement, [isLoading, selected]);
  const [showStickyHeader, setShowStickyHeader] = useState<boolean>(false);
  const [showStickyHeaderFunder, setShowStickyHeaderFunder] = useState<boolean>(false);
  const [showStickyHeaderNPO, setShowStickyHeaderNPO] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [editName, setEditName] = useState<boolean>(false);
  const [unSaved, setUnsaved] = useState<string[]>([]);
  const { cache } = useSWRConfig();
  const renderSearchResultItem = useCallback(
    (item: UnifiedSearchResult, i: number, byName = false) => {
      const key = getSearchResultKey(item);
      const cached = cache.get(
        process.env.REACT_APP_API_URL +
          `/api/v2/users/savedlist?org_id=${org_id}&target_id=${item._id}&target_type=${
            item.search_type === "funder"
              ? 0
              : item.search_type === "grant"
              ? 1
              : item.search_type === "npo"
              ? 3
              : -1
          }`,
      )?.data;
      const hidden = cached && !cached.includes(id) ? true : false;
      return (
        <div
          key={key}
          className={classNames(
            "pl-1",
            selected === i ? "bg-purple-500" : "bg-white pl-0 hover:bg-purple-500",
            hidden ? "opacity-30" : "",
          )}
          onClick={() => {
            setSelected(i);
          }}
        >
          <div
            className={classNames(
              "group flex cursor-pointer flex-col px-4 py-3",
              selected === i ? "bg-purple-50" : "bg-white",
            )}
          >
            {item.search_type === "funder" ? (
              <FunderCard
                key={key}
                funder={item as FunderSearchResult}
                query={item.search_query}
                selected={selected === i}
                isHidden={hidden}
                save_list_id={id}
              />
            ) : item.search_type === "grant" ? (
              <GrantCard
                key={key}
                grant={item as GrantSearchResult}
                query={item.search_query}
                selected={selected === i}
                isHidden={hidden}
                save_list_id={id}
              />
            ) : item.search_type === "npo" ? (
              <NPOCard
                key={key}
                npo={item as NPOSearchResult}
                query={item.search_query}
                selected={selected === i}
                isHidden={hidden}
                save_list_id={id}
              />
            ) : item.search_type === "past_grant" ? (
              <PastGrantCard
                key={key}
                grant={item as PastGrantSearchResult}
                query={item.search_query}
                selected={selected === i}
                isHidden={hidden}
              />
            ) : null}
          </div>
        </div>
      );
    },
    [id, setUnsaved, selected, saved, cache],
  );
  const renderError = (msg = "We apologize for the inconvenience. Please try again.") => {
    return (
      <div className="mb-3 flex h-[65vh] flex-col items-center gap-[60px] border border-gray-300 bg-white py-[60px]">
        <h4 className="text-2xl text-gray-900">{msg}</h4>
        <img src={NO_RESULT} alt="no result" className="h-[160px] w-[180px]" />
      </div>
    );
  };
  const renderNoResult = (msg = "No items") => {
    return (
      <div className="mb-3 flex h-[65vh] flex-col items-center gap-[60px] border border-gray-300 bg-white py-[60px]">
        <h4 className="text-2xl text-gray-900">{msg}</h4>
        <img src={NO_RESULT} alt="no result" className="h-[160px] w-[180px]" />
      </div>
    );
  };
  const renderLeftHeader = () => {
    return (
      <div className="min-h-[53px] w-full">
        <div className="flex h-[53px] w-full items-center justify-between border-b border-gray-300 bg-white px-4 py-3">
          <div className="flex items-center gap-2">
            {isLoadingList ? (
              <Spinner size="sm" />
            ) : (
              <>
                <p className="whitespace-nowrap font-poppins text-sm font-semibold">{`${(
                  savedList?.size || 0
                ).toLocaleString()} results`}</p>
              </>
            )}
          </div>
          <div className="inline-flex items-center gap-2">
            {/* <div className="w-fit whitespace-nowrap">Sort by :</div>
            <div className="z-[6] max-h-[36px] min-w-[117px]">
              <RadioBoxPopup
                id="input-funder-sort"
                className="!h-[29px]"
                data={searchType === "grant" ? GRANT_SORT_OPTION : SORT_OPTION}
                value={sorting}
                setValue={(v) => {
                  updateFilter({ sortby: v });
                }}
                popupClassName={searchType === "grant" ? "!w-[230px] right-0" : ""}
                downIcon
                compact
                closeOnClick
              />
            </div> */}
          </div>
        </div>
      </div>
    );
  };
  const renderLeftItems = () => {
    return (
      <>
        {isLoading ? (
          <LoadingPhilanthropy loading={isLoading} max={5} relevant />
        ) : (
          <div className="flex flex-col divide-y divide-gray-300">
            {data.map((d, i) => (
              <div key={getSearchResultKey(d)}>{renderSearchResultItem(d, i)}</div>
            ))}
          </div>
        )}
      </>
    );
  };
  const renderSelectedContent = useCallback(() => {
    const selectedItem = selected >= 0 && selected < data.length ? data[selected] : undefined;
    const search_type = selectedItem?.search_type || "";
    return (
      <>
        {selectedItem && (
          <>
            {search_type === "funder" && (
              <DonorDetailView
                compact
                donorId={selectedItem?._id || ""}
                query={selectedItem.search_query || onboardingQuery}
                showStickyHeader={showStickyHeaderFunder}
              />
            )}
            {search_type === "grant" && (
              <GrantView
                compact
                grantId={selectedItem?._id || ""}
                query={selectedItem.search_query || onboardingQuery}
                showStickyHeader={showStickyHeader}
              />
            )}
            {search_type === "npo" && (
              <NpoView
                npoId={selectedItem?._id || ""}
                query={selectedItem.search_query || onboardingQuery}
                showStickyHeader={showStickyHeaderNPO}
                compact
              />
            )}
            {search_type === "past_grant" && (
              <PastGrantView
                compact
                grantId={selectedItem?._id || ""}
                query={selectedItem.search_query || onboardingQuery}
                showStickyHeader={showStickyHeader}
              />
            )}
          </>
        )}
      </>
    );
  }, [
    data,
    showStickyHeaderFunder,
    showStickyHeaderNPO,
    showStickyHeader,
    onboardingQuery,
    selected,
  ]);
  const renderRight = () => {
    return (
      <>
        {isLoading ? (
          <div className="flex h-[860px] w-full items-center justify-center rounded">
            <Spinner size="md" />
          </div>
        ) : data.length > 0 ? (
          <div className="w-full rounded">{renderSelectedContent()}</div>
        ) : (
          <></>
        )}
      </>
    );
  };
  return (
    <div className="flex flex-col gap-4 pt-10">
      <div className="flex flex-col gap-5">
        <div className="flex h-[30px] items-center justify-between">
          {id && (
            <ClickToEditInput
              isLoading={id ? false : true}
              initalValue={savedList?.name || ""}
              placeholder="Enter name"
              height="!h-[30px]"
              wrapperClass="max-w-[350px]"
              textInputClass="!max-w-[350px]"
              onChange={(val) => {
                updateSavedList(id, { name: val });
              }}
            />
          )}
          {/* <div className="flex gap-2">
            <Button
              id="btn-invite"
              handleOnClick={() => alert("Coming soon")}
              size="sm"
              label="Invite"
              prependIcon={<i className="gi-md gi-funder" />}
            />
            <div className="h-full w-[1px] bg-gray-300"></div>
            <Button
              id="btn-delete"
              handleOnClick={() => alert("Coming soon")}
              size="sm"
              color="gray"
              outline
              label="Delete"
              prependIcon={<i className="fa fa-trash-o" />}
            />
          </div> */}
        </div>
        <div className="flex justify-between">
          <h5 className="text-black"></h5>
          <div className="flex items-center gap-2">
            <i className="gi-sm gi-calendar" />
            <h6>Updated: {savedList && reprTimeDistance(savedList.updated_at)}</h6>
          </div>
        </div>
      </div>
      <div className="h-[1px] w-full bg-gray-300"></div>

      {/* main view */}
      <div
        className={classNames(
          "flex w-full max-w-[1280px]",
          //showDashboard ? "mt-[160px]" : "mt-[65px]"
        )}
      >
        {!isLoading && data.length === 0 ? (
          <div className="w-full">{renderNoResult()}</div>
        ) : (
          <>
            <aside className={classNames("w-full max-w-[536px] transition-all duration-200")}>
              <div className={classNames("flex flex-col border border-gray-300 bg-white")}>
                {renderLeftHeader()}
                <div className="overflow-y-scroll" style={{ height: `calc(100vh - 270px)` }}>
                  {renderLeftItems()}
                  <div className="mb-4 flex flex-col items-center border-t border-gray-300 py-4">
                    {!isLoadingList ? (
                      <Pagination
                        prev={true}
                        last={false}
                        next={true}
                        first={false}
                        size="md"
                        total={savedList?.size || 0}
                        limit={UNIT}
                        maxButtons={8}
                        ellipsis
                        activePage={page}
                        onChangePage={(page) =>
                          setSearchParams(
                            new URLSearchParams([
                              ["page", `${page}`],
                              ["selected", "0"],
                            ]),
                          )
                        }
                      />
                    ) : (
                      <Spinner size="sm" />
                    )}
                  </div>
                </div>
              </div>
            </aside>

            <div
              id="search-result-main"
              className="min-h-full w-full grow overflow-y-auto border-b border-r border-t border-gray-300 bg-white"
              style={{ height: `calc(100vh - 215px)` }}
              ref={refElement}
            >
              {renderRight()}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
