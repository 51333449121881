import {
  AnyAction,
  Dispatch,
  PayloadAction,
  ThunkAction,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import config from "../api";
import { deleteProject, updateProject } from "../hooks/project/useProject";
import { IProject, IProjectUpdateRequest } from "../types/project";
import { RootState } from "./store";
interface orgProjectState {
  isFetched: boolean;
  data: IProject[];
}
// employee | board | volunteer
const initialState: orgProjectState = {
  isFetched: false,
  data: [],
};
// const postWorkAfterProjectCreation = (
//   data: IProject,
//   dispatch: Dispatch,
//   getState: () => RootState,
// ) => {
//   const state = getState();
//   if (!state.onboarding.data.role || state.onboarding.data.role < 3) return;
//   const id = data._id!;
//   const callback = (summaryRes: string, completed: boolean) => {
//     if (completed) {
//       _updateProjectAsync(dispatch, getState, { summary: summaryRes }, id);
//     }
//   };
//   if (!data.summary)
//     getProjectSummary(callback, data, getState().onboarding.data.mission_requested);
//   if (
//     data.tagging_started ||
//     (data.beneficiary?.length || 0) +
//       (data.focus_area?.length || 0) +
//       (data.program?.length || 0) ===
//       0
//   ) {
//     _updateProjectAsync(dispatch, getState, { tagging_started: true }, id);
//     taggingTaxonomy(
//       [data.problem_desc || "", data.outcome_desc || "", data.activity_desc || ""].join("\n"),
//     ).then((res) => {
//       _updateProjectAsync(dispatch, getState, { ...res, tagging_started: false }, id);
//     });
//   }
// };

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<orgProjectState>) => {
      state.data = action.payload.data;
    },
    fetch: (state, action: PayloadAction<orgProjectState>) => {
      state.isFetched = action.payload.isFetched;
      state.data = action.payload.data.sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
    },
    updateProject: (state, action: PayloadAction<IProject>) => {
      const originalIndex = state.data.findIndex((item: any) => item._id === action.payload._id);
      const updatedData = [...state.data];
      if (originalIndex !== -1) {
        updatedData.splice(originalIndex, 1, {
          ...action.payload,
          tasks: [],
          members: [],
          my_role: 0,
        });
      }
      state.data = updatedData;
    },
    deleteProject: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((item: any) => item._id !== action.payload);
    },
    clear: (state) => {
      state.data = [];
      state.isFetched = false;
    },
  },
});

const fetchProjectsAsync = (org_id: string): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const user_id = state.user?.user?._id;
    if (user_id)
      axios
        .get(process.env.REACT_APP_API_URL + `/api/v2/projects/my?org_id=${org_id}`, config)
        .then((response) => {
          return response.data;
        })
        .then((projects: IProject[]) => {
          dispatch(
            projectSlice.actions.fetch({
              isFetched: true,
              data: projects,
            }),
          );
          // const postFunc = (getState: () => RootState) => {
          //   const state = getState();
          //   if (state.onboarding.isFetched && state.taxonomySlice.isTaxonomyFetched)
          //     projects.map((p) => postWorkAfterProjectCreation(p, dispatch, getState));
          //   else setTimeout(() => postFunc(getState), 5000);
          // };
          // setTimeout(() => postFunc(getState), 2000);
        })
        .catch((e) => {
          // console.log(e);
          dispatch(projectSlice.actions.fetch({ isFetched: false, data: initialState.data }));
        });
  };
};
const appendProjectAsync = (data: IProject): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    dispatch(
      projectSlice.actions.update({
        data: [data, ...state.orgProjects.data],
        isFetched: true,
      }),
    );
    // postWorkAfterProjectCreation(data, dispatch, getState);
  };
};
const _updateProjectAsync = (
  dispatch: Dispatch,
  getState: () => RootState,
  data: IProjectUpdateRequest,
  project_id: string,
) => {
  updateProject(project_id, data)
    .then((res: IProject) => {
      const state = getState();
      const originalIndex = state.orgProjects.data.findIndex(
        (item: any) => item._id === project_id,
      );
      const updatedData = [...state.orgProjects.data];
      if (originalIndex !== -1) {
        updatedData.splice(originalIndex, 1, res);
      }
      dispatch(
        projectSlice.actions.update({
          data: updatedData,
          isFetched: true,
        }),
      );
    })
    .catch((e) => {
      throw e;
    });
};
const updateProjectAsync = (
  data: IProjectUpdateRequest,
  project_id: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    return _updateProjectAsync(dispatch, getState, data, project_id);
  };
};
const deleteProjectAsync = (
  project_id: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    return deleteProject(project_id)
      ?.then(() => {
        const state = getState();
        dispatch(
          projectSlice.actions.update({
            data: [...state.orgProjects.data.filter((i: any) => i._id !== project_id)],
            isFetched: true,
          }),
        );
      })
      .catch((e) => {
        throw e;
      });
  };
};

const selectSelf = (state: RootState) => state;

const isProjectsFetched = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.orgProjects.isFetched,
);

const _getProjectsData = (state: RootState): orgProjectState => {
  return state.orgProjects;
};
const selectProjectData = createDraftSafeSelector(
  (state: RootState) => state,
  (state: RootState): orgProjectState => _getProjectsData(state),
);

const selectId = (state: RootState, id?: string) => id;
const selectProject = createDraftSafeSelector(
  [selectProjectData, selectId],
  (projectState: orgProjectState, id?: string): { projectData?: IProject; isFetched: boolean } => {
    const filtered = projectState.data.filter((item) => item._id === id);
    return id && projectState.isFetched && filtered.length > 0
      ? { projectData: { ...filtered[0] }, isFetched: true }
      : { isFetched: false };
  },
);
export {
  appendProjectAsync,
  deleteProjectAsync,
  // postWorkAfterProjectCreation,
  fetchProjectsAsync,
  isProjectsFetched,
  selectProject,
  selectProjectData,
  updateProjectAsync,
};
export default projectSlice;
