import { Dialog, Listbox, Transition } from "@headlessui/react";
import PlainButton from "./PlainButton";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useMemo,
  Fragment,
  useRef,
  ReactNode,
} from "react";
import useUser from "../hooks/useUser";
import classNames from "classnames";
import useOnboardingData from "../hooks/useOnboarding";
import useOrgs from "../hooks/useOrgs";
import { DatePicker, Divider, Popover, Whisper } from "rsuite";
import UserMentionsInput from "./UserMentionsInput";
import useOrgMembers from "../hooks/project/useOrgMembers";
import Button from "./tailwind/Button";
import TextArea from "./tailwind/TextArea";
import CloseButton from "./CloseButton";
import useMySubtasks from "../hooks/project/useMySubtasks";
import { createProjectSubtaskNote } from "../hooks/project/useSubtaskNote";
import icon_attach from "../assets/project-mgt/attach.svg";
// import { useParams } from "react-router-dom";
// import "../css/dropdown.scss";
// import "./css/dropdown.scss";
import useSubtask, {
  createProjectSubtask,
  updateProjectSubtask,
} from "../hooks/project/useSubtask";
import UserList from "./UserList";
import UserProfile from "./UserProfile";
import TextInput from "./tailwind/TextInput";
import useTasks from "../hooks/project/useTasks";
import { IProject, IProjectTask } from "../types/project";
// import DropdownSelector from "./dropdown/DropdownSelector";
import TaskName from "../pages/npo/projects/components/TaskName";
import useProjects from "../hooks/project/useProjects";
import { ToggleWrapper } from "./ToggleWrapper";
import { toDateString } from "../utils/formatHelper";
import GibooToast from "./GibooToast";
import { useNavigate, useParams } from "react-router-dom";
import ClickToEditInput from "./input/ClickToEditInput";
import arrowIcon from "../assets/images/arrow-down.svg";
import ProjectInformationModal from "./ProjectInformationModal";
import GotoSmartSearchContext from "./useGotoSmartSearch";
import SingleSelector from "./selector/SingleSelector";
import { IOption } from "./tailwind/AsyncCreatableSelector";
import OrgMemberSuggestion from "./OrgMemberSuggestion";
import ProjectInvite from "./ProjectInvite";
import LeftInnerIconInput from "./LeftInnerIconInput";
import OrgInvitationPopup from "./OrgInvitationPopup";
import {
  zIndexSubtaskCreateModal,
  zIndexBackdrop,
  zIndexDialog,
  zIndexRsuitepopper,
} from "../types/zIndex";
import useSubtasks from "../hooks/project/useSubtasks";
import Spinner from "./Spinner";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useOrgID from "../hooks/useOrgID";
const MOCK_DATA = `👋 Welcome to My Tasks!
Explore all tasks assigned to you in this personal planning space. Leverage My Tasks to:
Monitor and control your to-dos
Arrange tasks by due date, projects, or priority
Share tasks with your teammates

👉 Ready to dive in?
Add a to-do with "Add Task."
Assign a due date.
Now, go ahead and complete the task!

😎 Pro-tip: Tasks crafted in My Tasks remain private, visible exclusively to you.`;
interface TaskCreationModalProps {
  title?: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onTaskCreation: (data?: any) => void;
  onClose?: () => void;
  projectId?: string;
  taskId?: string;
  defaultStatus?: string;
  isEditMode?: boolean;
}
function TaskCreationModal({
  title = "Create a task",
  open,
  setOpen,
  onTaskCreation: onComplete,
  onClose,
  defaultStatus = "Not started",
  isEditMode = false,
  ...props
}: TaskCreationModalProps) {
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const { selected } = useOrgs();
  const [memo, setMemo] = useState("");
  const { revalidate } = useMySubtasks();
  const { data: members, isLoading: orgmembersLoading } = useOrgMembers(selected);
  const [isLoading, setIsLoading] = useState(false);
  const [subTaskId, setSubtaskId] = useState<string | undefined>(props.taskId);
  const [projectId, setProjectId] = useState<string | undefined>(props.projectId);
  const [content, setContent] = useState<string>(MOCK_DATA || "");
  const [status, setStatus] = useState<string>(defaultStatus || "Not started");
  const presentDay = new Date().toISOString();
  const [dueDate, setDueDate] = useState<string | undefined>(presentDay);
  const [taskName, SetTaskName] = useState<string>("Untitled task (1)");
  const navigate = useNavigate();
  const [users, setUsers] = useState<string[]>([]);
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const [showInviteByMention, setShowInviteByComment] = useState<boolean>(false);
  const {
    data,
    isLoading: subtaskLoading,
    update,
    revalidate: revalidateSubTask,
    deleteThis,
  } = useSubtask(props.taskId);
  useEffect(() => {
    if (subtaskLoading) return;
    if (isEditMode) {
      SetTaskName(data.name);
      setDueDate(data.due_date);
      setStatus(data.status);
      setProjectId(data.project_id);
      setSubtaskId(data.task_id);
      setContent(data.content || "");
      setUsers(data.users);
    }
  }, [isEditMode, subtaskLoading]);
  useEffect(() => {
    props.projectId && setProjectId(props.projectId);
    props.taskId && setSubtaskId(props.taskId);
  }, [props.taskId, props.projectId]);
  const handleTaskCreate = () => {
    setIsLoading(true);
    if (isEditMode) {
      update({
        name: taskName,
        users: users,
        due_date: dueDate || new Date().toISOString(),
        status: status,
        content: content,
      })
        .then(() => {
          revalidate();
          onComplete();
        })
        .catch((error) => {
          GibooToast({
            type: "failure",
            message: `We apologize for the inconvenience. Please try again.`,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      createProjectSubtask({
        name: taskName,
        org_id: onboardingData._id,
        task_id: subTaskId || undefined,
        project_id: projectId ? projectId : undefined,
        status: status,
        due_date: dueDate || new Date().toISOString(),
        content: content,
        new_comment: memo ? memo : undefined,
      })
        .then(() => {
          !onComplete && navigate(`/my-tasks`);
          revalidate();
        })
        .catch((error) => {
          GibooToast({
            type: "failure",
            message: `We apologize for the inconvenience. Please try again.`,
          });
        })
        .finally(() => {
          setIsLoading(false);
          onComplete();
        });
    }
  };

  const renderContent = () => {
    const renderRow = (label: ReactNode, value: ReactNode) => {
      return (
        <div className="grid grid-cols-[77px_auto] place-items-stretch items-center gap-9">
          {label}
          {value}
        </div>
      );
    };
    return (
      <div className="mt-7 flex !w-[610px] flex-col">
        <div className="flex flex-col gap-4">
          {renderRow(
            <p className="font-poppins text-xs text-gray-700">Owner</p>,
            <TaskOwners
              users={users}
              showOrgInvite={() => setShowInvite(true)}
              addUser={(users) => {
                setUsers(users);
              }}
              isEditMode={isEditMode}
            />,
          )}
          {renderRow(
            <p className="font-poppins text-xs text-gray-700">Status</p>,
            <div className="hover:cursor-pointer">
              <StatusPicker
                status={status}
                onChange={(status) => {
                  setStatus(status);
                  console.log({ status });
                }}
              />
            </div>,
          )}
          {renderRow(
            <p className="font-poppins text-xs text-gray-700">Due date</p>,
            <div className="flex !h-[25px] max-w-fit items-center hover:cursor-pointer">
              <DateEditor
                dueDate={dueDate}
                onChange={(date) => {
                  setDueDate(date ? date : undefined);
                }}
              />
            </div>,
          )}
          {renderRow(
            <p className="font-poppins text-xs text-gray-700">Projects</p>,
            <div className="flex !h-[25px] w-full content-center">
              <ProjectSelector onSelect={(data) => setProjectId(data)} projectId={projectId} />
            </div>,
          )}
          {projectId &&
            renderRow(
              <p className="font-poppins text-xs text-gray-700">Task under</p>,
              <div className="flex !h-[25px] w-full content-center">
                <TaskUnderSelector
                  projectId={projectId}
                  onSelect={(data) => setSubtaskId(data)}
                  subTaskId={subTaskId}
                />
              </div>,
            )}
        </div>
        {/* Description */}
        <div className="mt-4 flex flex-col gap-4">
          <p className="font-poppins text-xs text-gray-700">Description</p>
          <TextArea
            id="description-statement"
            value={content}
            className="!min-h-[256px] !border-gray-300 !p-5 !text-xs !text-gray-900"
            topLabelClass="!text-gray-700 !text-base !font-normal !font-poppins"
            noOfRows={10}
            handleOnChange={(e) => {
              setContent(e.target.value);
            }}
            handleOnFocus={(e) => {
              if (e.target.value === MOCK_DATA) {
                // console.log(e.target.value);
                setContent("");
              }
            }}
          />
        </div>
        {!isEditMode && <Divider className="my-4" />}
        {/* Comment */}
        <div className="flex h-fit w-full flex-col gap-3">
          {!isEditMode && (
            <div className=" min-h-[200px]">
              <div className="flex w-full">
                <h5 className="whitespace-nowrap font-poppins text-xs font-normal">Comments</h5>
              </div>
              <div>
                <UserMentionsInput
                  id="note"
                  value={memo}
                  placeholder="Add a comment"
                  noOfRows={3}
                  handleOnChange={(event, newValue, newPlainTextValue, mentions) => {
                    if (mentions?.[0]?.id === "CUSTOM") {
                      return;
                    }
                    setMemo(event.target.value);
                  }}
                  onAdd={(id) => {
                    if (id === "CUSTOM") {
                      setShowInviteByComment(true);
                    }
                  }}
                  customComponent={
                    <div className="w-full text-center font-poppins text-xs text-gray-900">
                      Invite member
                    </div>
                  }
                  // disabled={!hasEditPermission}
                  autoFocus={true}
                  options={[
                    ...(members
                      .filter((i) => i._id !== user?._id)
                      .map((i) => ({ id: i._id, display: `${i.firstname} ${i.lastname}` })) as []),
                    { id: "CUSTOM", display: `Invite Member` },
                  ]}
                  className="!mb-0 !min-h-[124px] !pb-0"
                />
                <div className="flex w-full items-center justify-between">
                  <span className="inline-flex gap-5">
                    <h5 className="font-poppins text-sm text-gray-700">@ mention</h5>
                  </span>
                  <Button
                    id="btn-loi-close"
                    className="!h-[37px]"
                    color="purple"
                    label={isEditMode ? "Update" : "Create"}
                    loading={isLoading}
                    handleOnClick={() => {
                      handleTaskCreate();
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {isEditMode && (
            <div className="mt-7 flex w-full items-center justify-end">
              <Button
                id="btn-loi-close"
                className="!h-[37px]"
                color="purple"
                label={isEditMode ? "Update" : "Create"}
                loading={isLoading}
                handleOnClick={() => {
                  handleTaskCreate();
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      {onboardingData._id && showInvite && (
        <OrgInvitationPopup
          from_for_mixpanel="owner_in_project_task_creation"
          show={showInvite}
          onClose={() => setShowInvite(false)}
          noBackdrop
        />
      )}
      {onboardingData._id && showInviteByMention && (
        <OrgInvitationPopup
          from_for_mixpanel="mention_in_project_task_comment"
          show={showInviteByMention}
          onClose={() => setShowInviteByComment(false)}
          noBackdrop
        />
      )}
      <Dialog open={open} onClose={() => null} className={classNames("")}>
        <div
          className={classNames("fixed inset-0 bg-black/80", zIndexBackdrop)}
          aria-hidden="true"
        />
        <div className={classNames("fixed inset-0 w-screen overflow-y-auto p-4 ", zIndexDialog)}>
          <Dialog.Panel
            className={classNames(
              "mx-auto my-[50px] min-h-[500px] !w-[765px] rounded bg-white",
              zIndexSubtaskCreateModal,
            )}
          >
            <div className="flex h-full w-full flex-col py-[35px] pl-[35px] pr-[40px]">
              <div className="flex justify-between pl-[30px]">
                {/* <h4>{title}</h4> */}
                <ClickToEditInput
                  initalValue={taskName}
                  placeholder="Enter name"
                  height="!h-[30px]"
                  wrapperClass="max-w-[620px]"
                  textInputClass="!max-w-[620px]"
                  onChange={(val) => {
                    SetTaskName(val);
                  }}
                  withoutEditIcon
                />
                <PlainButton
                  id={`btn-update-image-close`}
                  className="self-end"
                  handleOnClick={() => {
                    onClose?.();
                    setOpen(false);
                  }}
                  leftIconClass={<CloseButton />}
                />
              </div>
              <div className="flex justify-center">{renderContent()}</div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
export default TaskCreationModal;

//Status Picker Starts
export enum SUBTASK_STATUS {
  NOT_STARTED = "Not started",
  IN_PROGRESS = "In progress",
  DONE = "Done",
}
export const SUBTASK_STATUS_COLOR = {
  NOT_STARTED: "#DEDEDE",
  IN_PROGRESS: "#FFEC8B",
  DONE: "#7FC8F8",
};
interface IstatusPicker {
  wrapperClassName?: string;
  status?: string;
  onChange: (val: string) => void;
}
function StatusPicker({ wrapperClassName, status, onChange }: IstatusPicker) {
  const enumKeys = Object.keys(SUBTASK_STATUS) as Array<keyof typeof SUBTASK_STATUS>;
  const [value, setValue] = useState(status || "Not started");
  useEffect(() => {
    status && setValue(status);
  }, [status]);
  const enumKeyValuePairs = enumKeys.map((enumKey) => ({
    key: enumKey,
    value: SUBTASK_STATUS[enumKey],
  }));
  const whisperRef = useRef<any>(null);
  return (
    <>
      <Whisper
        ref={whisperRef}
        placement="bottomStart"
        controlId="control-id-hover-enterable"
        className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)] "
        trigger={"click"}
        speaker={
          <Popover className={classNames(" !mt-1 !px-0", zIndexRsuitepopper)} arrow={false}>
            <ul className="h-auto min-w-[162px] flex-col gap-4">
              {enumKeyValuePairs.map((enumPair) => {
                return (
                  <li
                    key={enumPair.key}
                    className="w-full cursor-pointer px-3 py-1 hover:bg-gray-100 hover:shadow-sm"
                    onClick={() => {
                      whisperRef?.current?.close();
                      setValue(enumPair.value);
                      onChange(enumPair.value);
                    }}
                  >
                    <span
                      className="grid h-[25px] w-fit  place-items-center rounded px-[10px] font-poppins text-sm text-gray-900 last:mb-0"
                      style={{ background: SUBTASK_STATUS_COLOR[enumPair.key] }}
                    >
                      {enumPair.value}
                    </span>
                  </li>
                );
              })}
            </ul>
          </Popover>
        }
      >
        <div
          className={classNames(
            "grid h-[25px] w-fit place-items-center rounded px-[10px] font-poppins text-sm text-gray-900 hover:opacity-80",
            wrapperClassName,
          )}
          style={{
            background:
              SUBTASK_STATUS_COLOR[enumKeyValuePairs.find((i) => i.value === value)?.key || "DONE"],
          }}
        >
          {value}
        </div>
      </Whisper>
    </>
  );
}
interface IDateEditor {
  wrapperClassName?: string;
  dueDate: string | undefined;
  onChange: (val: string | undefined) => void;
}
function DateEditor({ wrapperClassName, dueDate, onChange }: IDateEditor) {
  const [value, setValue] = useState<Date | undefined>(dueDate ? new Date(dueDate) : new Date());
  const [open, setOpen] = useState(true);
  return (
    <div className="">
      {open ? (
        <p
          className="font-poppins text-xs text-black"
          onClick={() => {
            setOpen((prev) => !prev);
          }}
        >
          Today
        </p>
      ) : (
        <DatePicker
          className={classNames(" !z-[888] !h-[30px] !w-full ")}
          value={value}
          size="xs"
          oneTap
          editable={true}
          cleanable={true}
          placeholder="MM/DD/YYYY"
          onChange={(value) => {
            if (value) {
              setValue(value);
              onChange(value.toISOString());
            } else {
              setValue(undefined);
              onChange(undefined);
            }
          }}
        />
      )}
    </div>
  );
}
function ProjectSelector({
  onSelect,
  projectId,
}: {
  projectId?: string;
  onSelect: (item: string | undefined) => void;
}) {
  const [projects] = useProjects();
  const [selected, setSelected] = useState<IProject>();
  const initialValue = projects.data.find((p) => p._id === projectId);
  const [showProjectCreationModal, setShowProjectCreationModal] = useState<boolean>(false);
  const org_id = useOrgID();
  return (
    <>
      {showProjectCreationModal && (
        <ProjectInformationModal
          from_for_mixpanel="task_creation"
          open={showProjectCreationModal}
          setOpen={setShowProjectCreationModal}
          id=""
          withOutFunderGrant
          isNewProject
        />
      )}
      <div className="max-w-[300px]">
        <SingleSelector<IProject>
          id={`input-project-selector`}
          value={selected || initialValue}
          inputContainerClass={
            "!h-[25px] !min-h-[25px]  min-w-[200px]  max-w-[300px] !border-none !text-gray-900 !text-xs !px-0"
          }
          onChange={(v) => {
            onSelect(v ? v._id : undefined);
            setSelected(v ? v : undefined);
          }}
          placeholderClass="!text-gray-700 !text-xs"
          compact
          data={projects.data || []}
          isClearable={false}
          dropdownIcon={false}
          placeholder="Not assigned"
          isSearchable={false}
          renderItem={(i) => <>{i?.name}</>}
          renderButton={(i) => <>{i?.name}</>}
          renderNoOption={() => (
            <div
              onClick={() => {
                // mxEvent(MIXPANEL_EVENTS.PROJECT_CREATION.PROJECT_CREATE_CLICKED, {
                //   organizationId: org_id,
                //   location: location.pathname,
                // });
                setShowProjectCreationModal(true);
              }}
              className="cursor-pointer whitespace-nowrap py-2 !text-xs hover:bg-gray-100"
            >
              + Create a project
            </div>
          )}
        />
      </div>
    </>
  );
}
function TaskUnderSelector({
  onSelect,
  projectId,
  subTaskId,
}: {
  projectId: string | undefined;
  subTaskId?: string;
  onSelect: (item: string | undefined) => void;
}) {
  const { data: tasks, isLoading } = useTasks(projectId);
  const [selected, setSelected] = useState<IProjectTask>();
  useEffect(() => {
    if (isLoading || !projectId) return;
    setSelected(tasks.find((i) => i._id === subTaskId));
  }, [tasks, subTaskId, isLoading, projectId]);

  return (
    <div className="min-w-[122px] max-w-[300px]">
      <SingleSelector<IProjectTask>
        id={`input-task-selector`}
        value={selected}
        inputContainerClass={
          "!h-[25px] !min-h-[25px]  min-w-[200px]  max-w-[300px] !border-none !text-gray-900 !text-xs !px-0"
        }
        onChange={(v) => {
          setSelected(v ? v : undefined);
          onSelect(v ? v._id : undefined);
        }}
        placeholderClass="!text-gray-700 !text-xs"
        compact
        data={tasks}
        isClearable={false}
        dropdownIcon={false}
        placeholder="Not assigned"
        isSearchable={false}
        renderItem={(i) => (
          <TaskName task_id={i._id || ""} className="text-xs" project_id={projectId} />
        )}
        renderButton={(i) => (
          <TaskName task_id={i._id || ""} className="text-xs" project_id={projectId} />
        )}
        renderNoOption={() => (
          <GotoSmartSearchContext type="virtual_grant" project_id={projectId}>
            {({ go }) => (
              <div
                onClick={() => {
                  go();
                }}
              >
                {isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <p className="cursor-pointer whitespace-nowrap py-2 text-xs hover:bg-gray-100">
                    + grant or funder to project
                  </p>
                )}
              </div>
            )}
          </GotoSmartSearchContext>
        )}
      />
    </div>
  );
}

function TaskOwners({
  users,
  addUser,
  showOrgInvite,
  isEditMode,
}: {
  users: string[];
  addUser: (user: string[]) => void;
  showOrgInvite: () => void;
  isEditMode: boolean;
}) {
  const whisperRef = useRef<any>(null);
  const [user] = useUser();
  return (
    <Whisper
      ref={whisperRef}
      placement="bottomStart"
      controlId="control-id-hover-enterable"
      className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)]"
      trigger={"click"}
      speaker={
        <Popover className={classNames("!px-0", zIndexRsuitepopper)} arrow={false}>
          <InviteUser
            users={users}
            addUser={addUser}
            showOrgInvite={() => {
              whisperRef?.current?.close();
              showOrgInvite();
            }}
          />
        </Popover>
      }
    >
      <div>
        {users && users.length > 0 ? (
          <UserList users={users} maxUsers={1} size={16} />
        ) : (
          "Not assigned"
        )}
      </div>
    </Whisper>
  );
}
function InviteUser({
  users,
  addUser,
  showOrgInvite,
}: {
  users: string[];
  addUser: (user: string[]) => void;
  showOrgInvite: () => void;
}) {
  const [accessList, setAccessList] = useState<{ user_id: string; role: number }[]>([]);
  const [query, setQuery] = useState<string>("");
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  useEffect(() => {
    setAccessList(users.map((item) => ({ user_id: item, role: 1 })));
  }, [users]);
  return (
    <>
      <div className="flex h-full min-h-[144px] w-[379px] flex-col justify-between px-4">
        <div>
          <LeftInnerIconInput
            className="placholder:!text-gray-500 !h-[29px] w-full"
            type="text"
            placeholder="Search name"
            iconClass="gi-sm gi-search text-gray-500 !top-[3px]"
            id="input-email"
            value={query}
            handleOnFocus={() => setShowSuggestion(true)}
            handleOnChange={(e) => {
              setQuery(e.target.value);
              setShowSuggestion(true);
            }}
            offAutoComplete
          />
          <OrgMemberSuggestion
            show={showSuggestion}
            setShow={setShowSuggestion}
            query={query}
            exclude={[...accessList.map((u) => u.user_id)]}
            onSelect={(user) => {
              setQuery("");
              setShowSuggestion(false);
              const userExist = accessList.find((i) => i.user_id === user._id);
              if (!userExist && user._id) addUser([...users, user._id]);
            }}
          />
          {/* <h6 className=" mt-3 text-xs text-gray-500">Added:</h6> */}
          <div className="my-[14px] inline-flex flex-wrap gap-3">
            {accessList.length > 0
              ? accessList.map((item) => {
                  return (
                    <div
                      key={item.user_id}
                      className="inline-flex h-[32px] w-fit  items-center gap-2 rounded border border-gray-300 px-2"
                    >
                      <UserProfile showName userId={item.user_id} />
                      <span
                        className="mt-1 cursor-pointer"
                        onClick={() => {
                          addUser(users.filter((i) => i !== item.user_id));
                        }}
                      >
                        <i className="gi-x  text-sm text-gray-500" />
                      </span>
                    </div>
                  );
                })
              : "None"}
          </div>
        </div>
        <div className="flex justify-end self-end">
          <div
            id="invite-people"
            className={classNames(
              "cursor-pointer bg-white hover:shadow",
              "flex h-[30px] w-fit items-center gap-2 rounded border border-gray-300 px-3 py-1 font-poppins",
            )}
            onClick={() => {
              showOrgInvite();
            }}
          >
            <i className="gi-funder gi-md text-gray-700" />
            <span className="text-black">Invite</span>
          </div>
        </div>
      </div>
    </>
  );
}
