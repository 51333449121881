const valid_f = [
  "quality of life",
  "biotechnology risk",
  "environment",
  "social science",
  "recreation",
  "poor leadership",
  "poverty",
  "nature exploitation",
  "political empowerment",
  "chronic disease",
  "decolonization",
  "human rights",
  "pandemic",
  "cultural values",
  "community development",
  "vaccination",
  "gender equality",
  "theater",
  "religion",
  "crime prevention",
  "democracy",
  "rehabilitation",
  "literacy",
  "homelessness",
  "nature preservation",
  "mental health",
  "social safety net",
  "gun control",
  "crime",
  "education",
  "health",
  "unemployment",
  "equitable society",
  "business development",
  "art & culture",
  "animal cruelty",
  "gun violence",
  "immigration issues",
  "climate change",
  "women empowerment",
  "science",
  "criminal justice",
  "disability",
  "food security",
  "housing crisis",
  "social justice",
  "mass destruction",
  "public safety",
  "professional development",
  "politics",
  "maternal health",
  "social welfare",
  "architecture",
  "war and conflict",
  "sport",
  "well-being",
  "national security and defense",
  "artificial intelligence",
  "extreme poverty",
  "inflation",
  "economic empowerment",
  "visual arts",
  "underpopulation",
  "discrimination",
  "poverty reduction",
  "obesity",
  "overpopulation",
  "school safety",
  "economic inequality",
  "equal opportunity",
  "affordable housing",
  "healthcare",
  "equality and social justice",
  "molecular nanotechnology",
  "student loan debt",
  "music",
  "armament and weapons",
  "nuclear",
  "nutrition",
  "government and politics",
  "modern art",
  "pollution",
  "social media",
  "waste management",
  "youth development",
  "disease",
  "film and video",
  "freedom",
  "fine arts",
  "waste production",
  "poetry",
  "childcare philosophy",
  "ballet",
  "future technology",
  "government and public challenges",
  "early education",
  "workplace issues",
  "academics",
  "sustainability",
  "personal growth",
  "painting",
  "substance abuse",
  "inequality and social justice",
  "government transparency",
  "financial education",
  "food insecurity",
  "literature",
  "government corruption",
  "disarmament",
  "cyber security",
  "international peace and security",
  "equal education",
  "art awarness",
  "public health",
  "social empowerment",
  "public safety issue",
  "ageing",
  "politic",
  "animal protection",
];
const valid_b = [
  "widow and widower",
  "wildlife animal",
  "scientists",
  "LGBT community",
  "healthcare providers",
  "alumni",
  "Asian Indian",
  "Buddhists",
  "Latin American",
  "Silent Generation",
  "local community",
  "African",
  "intergovernmental organization",
  "abandoned animal",
  "Pakistani American",
  "Salvadoran",
  "Honduran American",
  "Christians",
  "urban community",
  "retired people",
  "substance abusers",
  "Arab American",
  "Muslim",
  "intersex",
  "Thai American",
  "soup kitchen",
  "Filipino",
  "cancer patients",
  "farmers",
  "Haitian American",
  "Spanish American",
  "HIV/AIDS patients",
  "childcare center",
  "artists",
  "Cambodian American",
  "Iranian American",
  "self-employed people",
  "people with disabilities",
  "Ukrainian American",
  "Guatemalan American",
  "Spanish",
  "Jewish",
  "researchers",
  "planet",
  "Baby Boomers",
  "air",
  "Mexican American",
  "Thai",
  "Irish",
  "Indigenous peoples",
  "Native Hawaiian",
  "activists",
  "Caribbean",
  "Argentinian American",
  "Jamaican American",
  "audience",
  "Japanese",
  "Mexican",
  "Sri Lankan American",
  "Scottish",
  "real estate business",
  "students",
  "patients",
  "museum",
  "activist",
  "Indian American",
  "land",
  "religious institution",
  "Earth",
  "desert",
  "global community",
  "journalists",
  "Cuban",
  "Chamorros",
  "Nigerian American",
  "Chinese",
  "men",
  "disadvantaged people",
  "medical institution",
  "Vietnamese American",
  "Kurdish American",
  "victims and oppressed people",
  "Puerto Rican American",
  "infants and toddlers",
  "Generation Alpha",
  "Samoan Americans",
  "Ethiopian American",
  "bird",
  "women",
  "youth",
  "Puerto Rican",
  "grassroots organization",
  "Armenian American",
  "government and agency",
  "veterans",
  "Portuguese American",
  "zoo",
  "Alaskan Natives",
  "Italian American",
  "Swedish",
  "Scotch-Irish",
  "Indigenous peoples of Oceania",
  "medically underserved",
  "Moroccan",
  "Welsh",
  "Somali",
  "Japanese American",
  "Arab",
  "pregnant people",
  "young adults",
  "immigrants",
  "Millennials",
  "Greek American",
  "Asian American",
  "library",
  "Asian",
  "Dominican",
  "Indonesian",
  "Polish",
  "police",
  "children",
  "Indonesian American",
  "Ethiopian",
  "school staff",
  "farm animal",
  "rural community",
  "French American",
  "Cuban American",
  "African Caribbean",
  "teachers",
  "Russian American",
  "French",
  "European American",
  "British",
  "Turkish American",
  "South African",
  "Latino",
  "technology company",
  "Guatemalan",
  "German",
  "animal shelter",
  "international organization",
  "Generation X",
  "service animal",
  "incarcerated people",
  "media business",
  "Irish American",
  "Hindus",
  "water",
  "parent",
  "Carribean American",
  "mountain",
  "caregiver",
  "German American",
  "Ecuadorian American",
  "adults",
  "English American",
  "Polish American",
  "pet",
  "Ghanaian American",
  "African American",
  "ecosystem",
  "refugees",
  "Portuguese",
  "military personnel",
  "athletes",
  "food bank",
  "abused animal",
  "Generation Z",
  "Vietnamese",
  "park",
  "wildlife",
  "entrepreneurs",
  "Salvadoran American",
  "at-risk family",
  "Kenyan",
  "education center",
  "Russian",
  "Norwegian",
  "Dutch",
  "Korean",
  "First Nations of Canada",
  "Brazilian American",
  "Chinese American",
  "Cape Verdean",
  "unemployed people",
  "Egyptian",
  "people with mental illness",
  "Assyrian American",
  "Scottish American",
  "pharmaceutical business",
  "forest",
  "climate",
  "Filipino American",
  "American Indians",
  "Ghanaian",
  "Romanian American",
  "Nigerian",
  "Peruvian American",
  "Italian",
  "Colombian American",
  "school",
  "seniors",
  "Korean American",
].map((i) => i.toLowerCase());
const valid_p = [
  "policy reform",
  "museum collection",
  "Individual developmental services",
  "networking support",
  "competition",
  "food donation",
  "outreach program",
  "psychosocial services",
  "rehabilitation program",
  "volunteer works",
  "General support services",
  "landscaping",
  "technological equipment donation",
  "protests",
  "language education program",
  "foster care",
  "real estate assistance",
  "diversity program",
  "support program",
  "award and recognition",
  "academic course",
  "public event",
  "therapeutic supports",
  "HIV/AIDS prevention",
  "nursing care",
  "blood services",
  "art event",
  "networking event",
  "tour",
  "consulting",
  "investment",
  "Financial service",
  "outpatient services",
  "curation",
  "shelter",
  "medical research",
  "emergency response",
  "toy donation",
  "camp programs",
  "grassroots organizing",
  "behavioral health services",
  "financial assistance",
  "leadership development program",
  "internship",
  "partnership",
  "self-help skills development program",
  "neighborhood preservation",
  "Events",
  "sustaiable recycling programs",
  "Housing service",
  "school supplies donation",
  "monitoring and evaluation",
  "medical treatment",
  "Medical services",
  "donation event",
  "demonstrations",
  "school program",
  "transportation",
  "career development program",
  "college preparatory program",
  "public transportation accessbility",
  "nutritional assistance",
  "technical assistance",
  "Art service",
  "mobility aids donation",
  "discussion",
  "Advocacy",
  "vocational training program",
  "financial education program",
  "Career",
  "education program",
  "affordable housing",
  "art program",
  "production",
  "Advisory service",
  "sale",
  "environmental education programs",
  "missionary work",
  "Environmental programs",
  "Educational services",
  "legal advisory service",
  "litigation",
  "In-kind donation",
  "social media",
  "campaign",
  "adoption service",
  "medical education",
  "capacity building",
  "basic sanitation services",
  "transitional housing",
  "basic water services",
  "animal program",
  "training program",
  "permanent housing",
  "Family service",
  "family wellness service",
  "public transit",
  "mental health services",
  "primary medical services",
  "job placement",
  "educational method",
  "Network building",
  "child welfare service",
];
const sortTaxonomy = (
  taxonomies?: string[],
  type?: "focus_area" | "beneficiary" | "program",
): string[] | undefined => {
  if (!taxonomies) return undefined;
  const target = type === "focus_area" ? valid_f : type === "beneficiary" ? valid_b : valid_p;
  return [
    ...taxonomies.filter((t) => target.includes(t.toLowerCase())),
    ...taxonomies.filter((t) => !target.includes(t.toLowerCase())),
  ];
};

export { valid_f, valid_b, valid_p, sortTaxonomy };
