import React, { Ref, useCallback, useEffect, useRef, useState } from "react";
import TextInput from "../../components/tailwind/TextInput";
import Button from "../../components/tailwind/Button";
import classNames from "classnames";

import LeftInnerIconNumberInput from "../../components/LeftInnerIconNumberInput";
import { isInteger } from "../../utils/dataTypeHelper";
import { ILocation, reprLocation } from "../../types/location";
import useOnboardingData from "../../hooks/useOnboarding";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { appendProjectAsync, selectProject, updateProjectAsync } from "../../app/projectSlice";
import useUser from "../../hooks/useUser";
import { IProject, IProjectCreateRequest } from "../../types/project";
import useScrollToHashElement from "../../hooks/useScrollToHashElement";
import GibooDatePicker from "../../components/tailwind/GibooDatePicker";
import Tag from "../../components/Tag";
import MultipleSelectGroup from "../../components/input/MultipleSelectGroup";
import RephrasableInput from "./components/RephrasableInput";
import PrimaryButton from "../../components/PrimaryButton";
import AITextField from "../../components/tailwind/AITextField";
import EditPhilanthropicFocus from "../../components/EditPhilanthropicFocus";
import Checkbox from "../../components/checkbox/Checkbox";
import { createProject } from "../../hooks/project/useProject";
import useOrgs from "../../hooks/useOrgs";
import PopupModal from "../../components/tailwind/PopupModal";
import EditTaxonomy from "../../components/EditTaxonomy";
import { Relevant } from "../../app/searchResultCacheSlice";
import { getProjectSummary } from "../../services/summary.services";
import Spinner from "../../components/Spinner";
import PlainButton from "../../components/PlainButton";
import RadioGroup from "../../components/radio/RadioGroup";
import LongTextInput from "../../components/LongTextInput";
import { Portal } from "@headlessui/react";
import useTotalTaxonomyTagging from "../../hooks/useTotalTaxonomyTagging";
import { useSWRConfig } from "swr";
import GibooLocationInput from "../../components/GibooLocationInput";

interface IProps {
  isUpdate?: boolean;
  customClass?: string | undefined;
  inProjectLayout?: boolean;
}

export const PROJECT_PURPOSE_OPTIONS = [
  {
    value: "supporting_organizations_general_operating_needs",
    label: "Supporting general operating needs for the organization",
  },
  { value: "building_organizations_capacity", label: "Expanding the capacity of the organization" },
  { value: "starting_a_new_program", label: "Starting a new project or program" },
  { value: "building_an_endowment_fund", label: "Building an endowment fund" },
];

const convertToUTCDate = (date: Date | undefined): Date | undefined => {
  if (!date) return undefined;
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
  if (ref?.current) {
    const topOffset = ref.current.offsetTop - 5;
    window.scrollTo({ top: topOffset, behavior: "smooth" });
  }
};
export interface IProjectDataWithId extends IProjectCreateRequest {
  _id: string;
}
interface ProjectCreationState {
  startTagging?: boolean;
  from?: string;
}
function ProjectCreationNew({ customClass, ...props }: IProps) {
  const { id } = useParams();
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState<number>(1);
  const { state, pathname } = useLocation() as { state: ProjectCreationState; pathname: string };
  const navigate = useNavigate();
  const [user] = useUser();
  const dispatch = useAppDispatch();
  const [appliedPrevProjectData, setAppliedPrevProjectData] = useState<boolean>(false);
  const { mutate } = useSWRConfig();
  const [onboardingData] = useOnboardingData();
  const { selected } = useOrgs();
  const { projectData: prevProjectData } = useAppSelector((state) => selectProject(state, id));
  const [projectData, setProjectData] = useState<IProjectDataWithId>({
    _id: "",
    org_id: selected,
    name: onboardingData.npo_name ? `${onboardingData.npo_name} Project` : "",
    fund_to_raise: 0,
    beneficiary: [],
    focus_area: [],
    service_loc: [],
    program: [],
  });

  const [tempServiceLocation, setTempServiceLocation] = useState<ILocation[]>(
    projectData.service_loc || [],
  );
  const [taxonomyQuery, setTaxonomyQuery] = useState<string>("");

  //suggested
  const [focusArea, setFocusArea] = useState<string[]>([]);
  const [beneficiary, setBeneficiary] = useState<string[]>([]);
  const [program, setProgram] = useState<string[]>([]);
  const [focusAreaAll, setFocusAreaAll] = useState<string[]>([]);
  const [beneficiaryAll, setBeneficiaryAll] = useState<string[]>([]);
  const [programAll, setProgramAll] = useState<string[]>([]);
  const [serviceLocSuggested, setServiceLocSuggested] = useState<ILocation[]>([]);

  const [isCreating, setIsCreating] = useState<boolean>(false);

  // refs for timeline, budget, project focus.
  const accessRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const budgetRef = useRef<HTMLDivElement>(null);
  const locationRef = useRef<HTMLDivElement>(null);
  const problemRef = useRef<HTMLDivElement>(null);
  const outcomeRef = useRef<HTMLDivElement>(null);
  const activityRef = useRef<HTMLDivElement>(null);
  const beneficiaryRef = useRef<HTMLDivElement>(null);
  const primaryPurposeRef = useRef<HTMLDivElement>(null);
  const [isInvalidData, setIsInvalidData] = useState(false);
  const [stage, setStage] = useState<string[]>([]);
  const [primaryPurpose, setPurpose] = useState<string | undefined>("");
  const [showEditTags, setShowEditTags] = useState<boolean>(false);
  const [showEditTagsOnCreate, setShowEditTagsOnCreate] = useState<boolean>(false);
  const [editTag, setEditTag] = useState<boolean>(false);
  const [access, setAccess] = useState<"edit" | "read" | "private" | undefined>("edit");
  useEffect(() => {
    const _step = searchParams.get("step");
    if (_step && isInteger(_step)) setStep(Number(_step));
    else setStep(1);
  }, [searchParams]);
  useEffect(() => {
    if (!prevProjectData || appliedPrevProjectData) return;
    setAppliedPrevProjectData(true);
    setProjectData(prevProjectData);
    setTempServiceLocation(prevProjectData.service_loc || []);
    setStage(prevProjectData.development_stage || []);
    setPurpose(
      prevProjectData.project_type === "starting_a_new_project"
        ? "starting_a_new_program"
        : prevProjectData.project_type || "",
    );
    // console.log(projectData, prevProjectData);
    // setProjectStart(prevProjectData.project_start || convertToUTCDate(new Date())?.toISOString());
    // setProjectEnd(prevProjectData.project_end);
    setBeneficiary(prevProjectData.beneficiary || []);
    setFocusArea(prevProjectData.focus_area || []);
    setProgram(prevProjectData.program || []);
    setAccess(
      prevProjectData.permission === 2
        ? "edit"
        : prevProjectData.permission === 1
        ? "read"
        : "private",
    );
  }, [prevProjectData]);

  useEffect(() => {
    if (step === 1 && state?.startTagging) {
      navigate(".", { replace: true, state: { from: state?.from } });
      navigate(`/project/edit/${id}?step=2`, { state: { from: state?.from } });
      // navigate(`/project/edit/${id}?step=2`, { replace: true });
    }
  }, [state]);

  useEffect(() => {
    if (onboardingData || projectData) {
      setServiceLocSuggested(onboardingData.service_loc || []);
    }
  }, [onboardingData, projectData]);

  useEffect(() => {
    setIsUpdate(props.isUpdate && projectData.completed ? true : false);
  }, [props, projectData]);
  const [summary, setSummary] = useState<Relevant>();
  const callback = useCallback(
    (res: string, completed: boolean, failed?: boolean) => {
      if (completed) {
        setProjectData((prev) => ({ ...prev, summary: res }));
      }
      setSummary({ desc: res, completed, error: failed ? "failed" : undefined });
    },
    [setSummary],
  );
  const {
    isLoading,
    focusArea: fr,
    beneficiary: br,
    program: pr,
  } = useTotalTaxonomyTagging(summary?.completed ? summary?.desc || "" : "");

  useEffect(() => {
    setFocusAreaAll(() => [...focusArea.filter((f) => !fr.includes(f)), ...fr]);
  }, [fr, focusArea, setFocusAreaAll]);
  useEffect(() => {
    setBeneficiaryAll(() => [...beneficiary.filter((f) => !br.includes(f)), ...br]);
  }, [br, beneficiary, setBeneficiaryAll]);
  useEffect(() => {
    setProgramAll(() => [...program.filter((f) => !pr.includes(f)), ...pr]);
  }, [pr, program, setProgramAll]);

  useEffect(() => {
    if (tempServiceLocation) {
      setProjectData((prev) => ({
        ...prev,
        service_loc: tempServiceLocation,
      }));
    }
  }, [tempServiceLocation]);

  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);
  // const prevScrollY = useRef<number>(window.scrollY);
  // const scrollThreshold = 10;
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY;
  //     if (scrollPosition > prevScrollY.current + scrollThreshold) {
  //       setShrinkHeader(true);
  //     } else if (scrollPosition < prevScrollY.current - scrollThreshold) {
  //       setShrinkHeader(false);
  //     }
  //     prevScrollY.current = scrollY;
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  const handleCreateProject = () => {
    if (!user?._id) return;
    const isValid = validate();
    if (!isValid) {
      setIsInvalidData(!isValid);
      return;
    }
    const npo_id = onboardingData.npo_id;
    const data: IProjectCreateRequest = {
      ...projectData,
      name: projectData.name || "",
      development_stage: stage,
      project_type: primaryPurpose,
      org_id: onboardingData._id,
      completed: false,
      // tagging_started: true,
    };
    setIsCreating(true);
    createProject(data)
      .then((res: IProject) => {
        // console.log("project created successfully", res);
        // mxEvent(MIXPANEL_EVENTS.PROJECT_CREATION.PROJECT_CREATED, {
        //   name: res.name,
        //   organizationId: onboardingData._id,
        //   location: location.pathname,
        //   projectId: res._id,
        // });
        Promise.resolve(dispatch(appendProjectAsync(res))).then(() =>
          navigate(`/project/edit/${res._id}?step=1`, {
            replace: true,
            state: { startTagging: true, from: state?.from },
          }),
        );
      })
      .catch((err) => {
        // console.log("failed to create project");
      })
      .finally(() => {
        setIsCreating(false);
      });
  };
  const validate = () => {
    const { project_end, project_start, fund_to_raise, name } = projectData;
    if (step === 1) {
      if (access === undefined) {
        scrollToRef(accessRef);
        return false;
      }
      if (!name) {
        scrollToRef(nameRef);
        return false;
      }
      if (!primaryPurpose) {
        scrollToRef(primaryPurposeRef);
        return false;
      }
      if (!projectData.problem_desc || projectData.problem_desc.length < 10) {
        scrollToRef(problemRef);
        return false;
      }
      // if (!projectData.outcome_desc || projectData.outcome_desc.length < 10) {
      //   scrollToRef(outcomeRef);
      //   return false;
      // }
      // if (!projectData.activity_desc || projectData.activity_desc.length < 10) {
      //   scrollToRef(activityRef);
      //   return false;
      // }
      // if (!projectData.beneficiary_desc || projectData.beneficiary_desc.length < 10) {
      //   scrollToRef(beneficiaryRef);
      //   return false;
      // }
    } else if (step === 2) {
      // if (!project_start || !project_end) {
      //   scrollToRef(timelineRef);
      //   return false;
      // }
      // if (!fund_to_raise) {
      //   scrollToRef(budgetRef);
      //   return false;
      // }
    }

    return true;
  };
  const handleUpdateProject = (goToNextStep?: boolean) => {
    const isValid = validate();
    if (!isValid) {
      setIsInvalidData(!isValid);
      return;
    }
    if (isValid && projectData?._id) {
      const data = {
        ...projectData,
        completed:
          projectData.completed || focusArea.length + beneficiary.length + program.length > 0,
        fund_to_raise: projectData.fund_to_raise,
        rolling: projectData.rolling,
        // project_start: projectStart,
        // project_end: projectEnd,
        summary: projectData.summary || summary?.desc || "",
        project_start: projectData.project_start,
        project_end: projectData.project_end,
        development_stage: stage,
        asset_min: projectData.asset_min,
        asset_max: projectData.asset_max,
        focus_area: focusArea,
        beneficiary: beneficiary,
        program: program,
        permission: access === "edit" ? 2 : access === "read" ? 1 : 0,
      };
      // console.log("data", data);
      setIsCreating(true);
      Promise.resolve(dispatch(updateProjectAsync(data, projectData._id)))
        .then((res) => {
          // console.log("project updated successfully", res);
          mutate(process.env.REACT_APP_API_URL + `/api/v2/projects/${projectData._id}`);
        })
        .catch((err) => {
          // console.log("failed to update project");
        })
        .finally(() => {
          if (goToNextStep) {
            setIsCreating(false);
            setStep(2);
            return;
          }
          if (state?.from && state.from !== "search") {
            navigate(state.from, { state: { projectData: projectData } });
          } else if (!isUpdate || state?.from === "search") {
            // if (!isUpdate) {
            // navigate(
            //   `/search?${getURLSearchParams({
            //     ...getParamsFromProjectData(
            //       onboardingData,
            //       {
            //         ...projectData,
            //         development_stage: stage,
            //         asset_min: projectData.asset_min,
            //         asset_max: projectData.asset_max,
            //         focus_area: focusArea,
            //         beneficiary: beneficiary,
            //         program: program,
            //       },
            //       "funder",
            //     ),
            //   })}`,
            // );
          }
          // else if (state?.from) navigate(state.from, { state: { projectData: projectData } });
          else navigate(`/project/${projectData._id}`);
        });
    }
  };
  useScrollToHashElement("smooth", isUpdate);
  const renderHeader = () => {
    return (
      <div className={classNames("flex w-full items-center justify-between gap-0 pt-5")}>
        <div className="min-h-[47px] w-full">
          <div className="inline-flex min-h-[37px] items-center gap-2 bg-inherit">
            {!props.inProjectLayout && (
              <PlainButton
                className="ml-[-108px]"
                id="btn-back"
                label="back"
                leftIconClass="fa-solid fa-arrow-left"
                handleOnClick={() => {
                  navigate(-1);
                }}
              />
            )}{" "}
            <h4
              className={classNames(
                "overflow-hidden truncate text-ellipsis text-base font-medium text-purple-500 md:max-w-md md:text-2xl",
              )}
            >
              {projectData.name || "No title"}
            </h4>
          </div>
        </div>
        <div className="mb-3 flex w-fit flex-row gap-5">
          {isUpdate ? (
            <Button
              label={isUpdate ? "Update project" : "Create project"}
              className="h-[37px] self-start"
              id="create-project"
              color="purple"
              loading={isCreating}
              handleOnClick={() => {
                handleUpdateProject();
              }}
            />
          ) : step === 2 ? (
            <Button
              label={isUpdate ? "Update project" : "Create project"}
              id="create-project"
              color="purple"
              loading={isCreating}
              handleOnClick={() => {
                getProjectSummary(callback, projectData, onboardingData.mission);
                setShowEditTagsOnCreate(true);
              }}
            />
          ) : (
            <PrimaryButton
              className="!h-9 w-[220px]"
              id="btn-yes"
              label="Save and continue"
              color="purple"
              rightIconClass="gi-left-arrow rotate-180"
              loading={isCreating}
              handleOnClick={() => {
                if (pathname.startsWith("/project/edit")) handleUpdateProject(true);
                else handleCreateProject();
              }}
            />
          )}
        </div>
      </div>
    );
  };
  const renderProjectStart = () => {
    return (
      <GibooDatePicker
        id="date-picker-start"
        type="start"
        // value={projectStart}
        value={projectData.project_start}
        maxDate={projectData.project_end}
        hasStartEnd
        // handleDateSelection={(date) => {
        //   setProjectStart(date ? convertToUTCDate(new Date(date))?.toISOString() : undefined);
        // }}
        handleDateSelection={(date) => {
          setProjectData((prev) => ({
            ...prev,
            project_start: date ? convertToUTCDate(new Date(date))?.toISOString() : undefined,
          }));
        }}
      />
    );
  };
  const renderProjectEnd = () => {
    return (
      <GibooDatePicker
        id="date-picker-end"
        type="end"
        hasStartEnd
        // disabled={projectData.project_start ? false : true}
        value={projectData.project_end}
        minDate={projectData.project_start}
        // value={projectEnd}
        // handleDateSelection={(date) => {
        //   // console.log(date, date ? convertToUTCDate(new Date(date))?.toISOString() : undefined);
        //   setProjectEnd(date ? convertToUTCDate(new Date(date))?.toISOString() : undefined);
        // }}
        handleDateSelection={(date) => {
          setProjectData((prev) => ({
            ...prev,
            project_end: date ? convertToUTCDate(new Date(date))?.toISOString() : undefined,
          }));
        }}
      />
    );
  };
  const renderErrorMessage = (show: boolean) => {
    if (show) {
      return (
        <>
          <span className="mt-2 inline-flex font-poppins text-sm font-semibold text-purple-500">
            Please provide the required information.
          </span>
        </>
      );
    }
  };

  const renderAssetSize = (index = 0) => {
    // const startAmmount = projectData.fund_raised?.[index]?.amount;
    // const endAmmount = projectData.fund_raised?.[index]?.amount;
    return (
      <div
        key={`fundraised-item-${index}`}
        className="mt-4 flex w-full flex-col md:flex-row md:items-center md:gap-3"
      >
        <LeftInnerIconNumberInput
          id="input-number-1"
          className="!h-[48px] !w-[344px]"
          value={projectData.asset_min || 0}
          setValue={(value) => {
            setProjectData((prev) => ({ ...prev, asset_min: Number(value) }));
          }}
          iconClass="gi gi-dollar  text-sm text-gray-500"
          placeholder="Enter amount"
        />
        <div className="h-[1px] min-w-[20px] max-w-[20px] bg-gray-500" />
        <LeftInnerIconNumberInput
          id="input-number-1"
          className="!h-[48px] !w-[344px]"
          value={projectData.asset_max || undefined}
          setValue={(value) => {
            setProjectData((prev) => ({ ...prev, asset_max: Number(value) }));
          }}
          iconClass="gi gi-dollar  text-sm text-gray-500"
          placeholder="Enter amount"
        />
      </div>
    );
  };
  const renderProjectAccess = () => {
    return (
      <div className="my-[60px] flex">
        <div className="w-full md:w-[822px]" ref={accessRef}>
          <h4 className="mb-[30px] mt-[60px] font-poppins font-semibold text-purple-500">
            1. Project access <span className="text-sm font-normal text-gray-700">(required)</span>
          </h4>
          <RadioGroup<"edit" | "read" | "private">
            id="input-access"
            data={[
              { value: "edit", label: `Everyone at ${onboardingData.npo_name} can edit` },
              { value: "read", label: `Everyone at ${onboardingData.npo_name} can view` },
              { value: "private", label: `Invite-only: choose who has access` },
            ]}
            flexColumn
            mandatory
            value={access}
            setValue={setAccess}
          />
          {isInvalidData && renderErrorMessage(access === undefined ? true : false)}
        </div>
      </div>
    );
  };

  const renderProjectOverview = () => {
    return (
      <div ref={(el) => (contentRefs.current[6] = el)} className="my-[60px] flex">
        <div className="w-full md:w-[822px]">
          <h4 className="mb-[30px] mt-[20px] font-poppins font-semibold text-purple-500">
            2. Project overview
          </h4>
          <div className="w-full pr-5 font-poppins">
            <h4 ref={nameRef} className="pb-2 text-gray-900">
              What is the name of this project?*{" "}
              <span className="text-sm font-normal text-gray-700">(required)</span>
            </h4>
            <div className="mt-4 min-h-[47px] md:max-w-md">
              <TextInput
                id="ein"
                wrapperClass={classNames("md:w-full")}
                className="h-[48px]"
                value={projectData.name || ""}
                handleOnKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (projectData.name === "") return;
                  }
                }}
                handleOnBlur={(e) => {
                  if (projectData.name === "") return;
                }}
                placeholder="Enter Project Name"
                invalid={isInvalidData && !projectData.name ? true : false}
                handleOnChange={(e) => {
                  setProjectData((prev) => ({ ...prev, name: e.target.value }));
                }}
              />
              {isInvalidData && renderErrorMessage(!projectData.name ? true : false)}
            </div>
          </div>
          <div className="mt-[60px] w-full pr-5 font-poppins">
            <h4 ref={primaryPurposeRef} className="pb-2 text-gray-900">
              How will the funds for this project be used?*{" "}
              <span className="text-sm font-normal text-gray-700">(required)</span>
            </h4>
            <RadioGroup<string>
              id="primary-purpose"
              flexColumn
              data={PROJECT_PURPOSE_OPTIONS}
              mandatory
              value={primaryPurpose}
              setValue={setPurpose}
            />
          </div>
          {isInvalidData && renderErrorMessage(!primaryPurpose ? true : false)}

          <div className="w-full pr-5 font-poppins">
            {isUpdate && (
              <>
                <h4 className="mt-[60px] pb-2 text-gray-900">
                  Summary
                  {/* <span className="text-sm font-normal text-gray-700">(optional)</span> */}
                </h4>
                <div className="my-4">
                  <AITextField
                    id="summary"
                    descType="summary"
                    placeholder="Enter text here."
                    suggestionQuery={{
                      mission: onboardingData.mission,
                      problem_desc: projectData?.problem_desc || "",
                      outcome_desc: projectData?.outcome_desc || "",
                      activity_desc: projectData?.activity_desc || "",
                      beneficiary_desc: projectData?.beneficiary_desc || "",
                      service_loc: projectData?.service_loc?.map((l) => reprLocation(l)) || [],
                    }}
                    value={projectData.summary || ""}
                    onChange={(value) => {
                      setProjectData((prev) => ({ ...prev, summary: value.trim() }));
                    }}
                  />
                </div>

                {/* philanthropic focus */}
                <div className="my-2 flex flex-wrap gap-2" id="philanthropy">
                  {isUpdate &&
                  focusArea.length + (beneficiary.length || 0) + (program.length || 0) === 0 ? (
                    <p>None</p>
                  ) : (
                    <>
                      {focusArea.map((i) => (
                        <Tag
                          key={`focus-area-${i}`}
                          id={`focus-area-${i}`}
                          name={i}
                          size="sm"
                          color="yellow"
                          matched
                        />
                      ))}
                      {beneficiary.map((i) => (
                        <Tag
                          key={`beneficiary-${i}`}
                          id={`beneficiary-${i}`}
                          name={i}
                          size="sm"
                          color="blue"
                          matched
                        />
                      ))}
                      {program.map((i) => (
                        <Tag
                          key={`program-${i}`}
                          id={`program-${i}`}
                          name={i}
                          size="sm"
                          color="green"
                          matched
                        />
                      ))}
                    </>
                  )}
                </div>
                {isUpdate && (
                  <div className="flex">
                    <Tag
                      id={"edit-tags"}
                      name="edit tags"
                      onClick={() => {
                        setShowEditTags(true);
                      }}
                      color="purple"
                      matched
                      size="sm"
                      rightIconClass="gi gi-pencil"
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div ref={problemRef} className="mt-[60px] w-full pr-5 font-poppins">
            <h4 className="pb-2 text-gray-900">
              What problems or challenges will this project address?*{" "}
              <span className="text-sm font-normal text-gray-700">(required)</span>
            </h4>
            <h5 className="text-gray-700">
              Write one or two sentences that best describe the problems this project aim to solve.
              <br />
              This is very critical information used for matching potential funders or grants for
              the project.
            </h5>
            <RephrasableInput
              id="problem_desc"
              value={projectData.problem_desc}
              promptPrefix="Below is the problems and challenges does the project aim to address.\nPlease rephrase below the original sentence.\n"
              minLength={10}
              renderError={
                isInvalidData
                  ? renderErrorMessage(
                      !projectData.problem_desc || projectData.problem_desc.length < 10
                        ? true
                        : false,
                    )
                  : undefined
              }
              additonalInformation={
                onboardingData.mission
                  ? { "Organization's mission": onboardingData.mission }
                  : undefined
              }
              setValue={(val) => setProjectData((prev) => ({ ...prev, problem_desc: val }))}
            />
          </div>

          <div ref={outcomeRef} className="mt-[60px] w-full pr-5 font-poppins">
            <h4 className="pb-2 text-gray-900">
              What expected outcomes or results will be achieved by this project?{" "}
              <span className="text-sm font-normal text-gray-700">(optional)</span>
            </h4>
            <RephrasableInput
              id="outcome_desc"
              value={projectData.outcome_desc}
              promptPrefix="Below is the outcomes do I expect upon completion of this project.\nPlease rephrase below the original sentence.\n"
              minLength={10}
              // renderError={
              //   isInvalidData
              //     ? renderErrorMessage(
              //         !projectData.outcome_desc || projectData.outcome_desc.length < 10
              //           ? true
              //           : false,
              //       )
              //     : undefined
              // }
              additonalInformation={
                onboardingData.mission
                  ? { "Organization's mission": onboardingData.mission }
                  : undefined
              }
              setValue={(val) => setProjectData((prev) => ({ ...prev, outcome_desc: val }))}
            />
          </div>

          <div ref={activityRef} className="mt-[60px] w-full pr-5 font-poppins">
            <h4 className="pb-2 text-gray-900">
              What activities will be included in this project?{" "}
              <span className="text-sm font-normal text-gray-700">(optional)</span>
            </h4>
            <RephrasableInput
              id="activity_desc"
              value={projectData.activity_desc}
              promptPrefix="Below is the activities will this project engage in to achieve these desired outcomes.\nPlease rephrase below the original sentence.\n"
              minLength={10}
              // renderError={
              //   isInvalidData
              //     ? renderErrorMessage(
              //         !projectData.activity_desc || projectData.activity_desc.length < 10
              //           ? true
              //           : false,
              //       )
              //     : undefined
              // }
              additonalInformation={
                onboardingData.mission
                  ? { "Organization's mission": onboardingData.mission }
                  : undefined
              }
              setValue={(val) => setProjectData((prev) => ({ ...prev, activity_desc: val }))}
            />
          </div>

          <div ref={beneficiaryRef} className="mt-[60px] w-full pr-5 font-poppins">
            <h4 className="pb-2 text-gray-900">
              Who will benefit from this project?{" "}
              <span className="text-sm font-normal text-gray-700">(optional)</span>
            </h4>
            <h5 className="text-sm font-normal text-gray-700">
              Beneficiaries could include a specific group of people, the general public, or the
              environment.
            </h5>
            <RephrasableInput
              id="beneficiary_desc"
              value={projectData.beneficiary_desc}
              promptPrefix="Below is the beneficiary will benefit form the project.\nPlease rephrase below the original sentence.\n"
              minLength={10}
              // renderError={
              //   isInvalidData
              //     ? renderErrorMessage(
              //         !projectData.beneficiary_desc || projectData.beneficiary_desc.length < 10
              //           ? true
              //           : false,
              //       )
              //     : undefined
              // }
              additonalInformation={
                onboardingData.mission
                  ? { "Organization's mission": onboardingData.mission }
                  : undefined
              }
              setValue={(val) => setProjectData((prev) => ({ ...prev, beneficiary_desc: val }))}
            />
          </div>

          {/* location */}
          <div
            ref={locationRef}
            className="mt-[40px] w-full max-w-fit font-poppins"
            id="service-loc"
          >
            <h4 className="pb-2 text-gray-900">
              Where do the project beneficiaries mainly reside?{" "}
              <span className="text-sm font-normal text-gray-700">(optional)</span>
            </h4>
            <h5 className="text-sm font-normal text-gray-700">
              To improve matching with potential funders, add only specific locations.
              <br /> For example, if the project serves beneficiaries in Manhattan, there is no need
              to add New York or United States.
            </h5>

            <GibooLocationInput
              id="location"
              value={projectData.service_loc || []}
              setValue={(value) => {
                setTempServiceLocation(value);
              }}
              maxWidth="900px"
              wrapperClass="!items-start my-2"
              inputBoxMaxWidth="384px"
              itemsMaxHeight="480px"
              itemsPosition="top"
              itemsAlign="start"
              inputClassName="!h-[48px] mt-4 mb-1"
              gap={2}
              hideNone
              placeholder={"Choose location"}
              icon
              spinnerBackgroundColor="bg-gray-50"
              matched
              isFullTagClick
              tagSize="sm"
            />
          </div>
          <h5 className="text-sm font-normal text-gray-700">
            Begin typing a location, then click the labels to add them.
            <br /> You can also click labels in the Suggested geographic focus box below.
          </h5>
          {serviceLocSuggested &&
            serviceLocSuggested.length > 0 &&
            serviceLocSuggested.some(
              (i) =>
                !projectData?.service_loc?.map((i) => reprLocation(i)).includes(reprLocation(i)),
            ) && (
              <div className="mb-3 mt-3 rounded border border-gray-300 p-3">
                <p className="pb-3 text-sm font-semibold text-gray-900">
                  Suggested geographic focus
                </p>
                <div className=" flex flex-wrap gap-x-1 gap-y-2">
                  {serviceLocSuggested
                    .filter(
                      (i) =>
                        !projectData?.service_loc
                          ?.map((i) => reprLocation(i))
                          .includes(reprLocation(i)),
                    )
                    ?.map((item, i) => (
                      <Tag
                        key={`location-${reprLocation(item)}-${i}`}
                        id={`location-${reprLocation(item)}-${i}`}
                        name={reprLocation(item)}
                        color="orange"
                        matched
                        isFullTagClick
                        size="sm"
                        onAdd={() => {
                          if (!tempServiceLocation.includes(item)) {
                            setTempServiceLocation((prev) => [...prev, item]);
                            setServiceLocSuggested((prev) =>
                              prev.filter((i) => reprLocation(i) !== reprLocation(item)),
                            );
                          }
                        }}
                      />
                    ))}
                </div>
              </div>
            )}
          {/* {isInvalidData &&
            renderErrorMessage(projectData.service_loc?.length === 0 ? true : false)} */}
        </div>
      </div>
    );
  };

  const renderProjectDetail = () => {
    return (
      <div
        ref={(el) => (contentRefs.current[0] = el)}
        className="mb-[60px] mt-[80px] flex h-fit flex-col "
      >
        <div className="w-full">
          <h4 className="mb-[30px] mt-[20px] font-poppins font-semibold text-purple-500">
            3. Project details
          </h4>
          <div
            ref={timelineRef}
            className="mb-[30px] flex w-full flex-row justify-start font-poppins"
          >
            <div className="" id="timeline">
              <h4 className="pb-2 text-gray-900">
                What is the expected timeline for this project, excluding the fundraising phase?{" "}
                <span className="text-sm font-normal text-gray-700">(optional)</span>
              </h4>
              <h5 className="text-sm font-normal text-gray-700">
                Enter the projected start and end dates for this project, or choose Rolling if these
                dates are flexible or unknown.
              </h5>
              <div className="flex flex-col gap-2">
                <div className=" flex flex-col  space-y-5 md:mt-4 md:flex-row md:items-center md:space-y-0">
                  {renderProjectStart()}
                  <div className="mx-5 hidden h-[1px] w-6 bg-gray-400 md:block" />
                  {renderProjectEnd()}
                </div>
                <h5>or</h5>
                <Checkbox
                  id="input-timeline-rolling"
                  checked={projectData.rolling ? true : false}
                  label="Rolling"
                  onChange={() => {
                    setProjectData((prev) => ({ ...prev, rolling: prev.rolling ? false : true }));
                  }}
                />
              </div>
            </div>
          </div>
          <div ref={budgetRef} className="mb-[40px] w-full font-poppins" id="target-amount">
            <h4 className="pb-2 text-gray-900">
              What is the target amount you aim to raise?{" "}
              <span className="text-sm font-normal text-gray-700">(optional)</span>
            </h4>
            <h5 className="text-sm font-normal text-gray-700">
              The target amount helps match your project with donors who have typically funded
              projects in that range.
            </h5>
            <div className="my-2 flex flex-col gap-3">
              <div className="w-[400px]">
                <LeftInnerIconNumberInput
                  id="input-number-1"
                  className="!h-[48px]"
                  value={projectData.fund_to_raise || 0}
                  setValue={(value) => {
                    setProjectData((prev) => ({ ...prev, fund_to_raise: Number(value) }));
                  }}
                  iconClass="fa fa-dollar text-sm text-gray-500"
                  placeholder="Enter target amount"
                />
                {isInvalidData && renderErrorMessage(!projectData.fund_to_raise ? true : false)}
              </div>
              {/* {onboardingData.operating_budget ? (
                <SecondaryButton
                  className="w-[400px]"
                  id="btn-load-budget"
                  label="Load my organization's operating budget"
                  handleOnClick={() => {
                    setProjectData((prev) => ({
                      ...prev,
                      fund_to_raise: Number(onboardingData.operating_budget),
                    }));
                  }}
                />
              ) : null} */}
            </div>
          </div>
        </div>

        <div>
          <h4 className="max-w-[600px] pb-2 text-gray-900">
            Which nonprofit stage do you prefer your ideal funder to have a history of funding?{" "}
            <span className="text-sm font-normal text-gray-700">(optional)</span>
          </h4>
          <MultipleSelectGroup
            id="stage"
            selectAll
            labels={{
              dreamer: ["Individual"],
              seed: ["Seed"],
              startup: ["Start up"],
              buildup: ["Buildup"],
              superstar: ["Superstar"],
              hyperstar: ["Hyperstar"],
            }}
            wrapperClass={"!gap-0"}
            setValue={setStage}
            value={stage}
          />
        </div>

        <div className="mt-[60px] w-full max-w-[700px] font-poppins">
          <h4 className="max-w-[600px] pb-2 text-gray-900">
            What is your desired funder&apos;s asset size?{" "}
            <span className="text-sm font-normal text-gray-700">(optional)</span>
          </h4>
          {renderAssetSize(0)}
        </div>
      </div>
    );
  };

  const handleSave = (updated: {
    focus_area: string[];
    beneficiary: string[];
    program: string[];
    service_loc: ILocation[] | undefined;
  }) => {
    setShowEditTags(false);
    setFocusArea(updated.focus_area);
    setBeneficiary(updated.beneficiary);
    setProgram(updated.program);
  };
  return (
    <>
      {showEditTags && (
        <EditPhilanthropicFocus
          focus_area={projectData.focus_area || []}
          beneficiary={projectData.beneficiary || []}
          program={projectData.program || []}
          service_loc={undefined}
          showEdit={showEditTags}
          allowEdit={true}
          onClose={() => setShowEditTags(false)}
          handleSave={handleSave}
          withoutMission
        />
      )}
      {showEditTagsOnCreate && (
        <PopupModal
          isOpen={showEditTagsOnCreate}
          handleClose={() => {
            setShowEditTagsOnCreate(false);
            setEditTag(false);
          }}
          title=""
          noTitleDivider
          wrapperClass="max-w-[864px] md:!px-0 "
          contentClass="!px-[24px] max-h-[600px] overflow-y-scroll h-fit"
          showActionButtons={false}
        >
          <div className="flex h-[600px] flex-col gap-7 pb-7 pt-0">
            {!editTag ? (
              <>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-2">
                    <p>Project summary</p>
                    {(summary?.completed || !summary?.desc) && (
                      <PlainButton
                        id="btn-retry"
                        leftIconClass="gi gi-refresh"
                        handleOnClick={() => {
                          setSummary({ desc: "", completed: false });
                          getProjectSummary(
                            callback,
                            projectData,
                            onboardingData.mission,
                            undefined,
                            Math.floor(Math.random() * 0.3) + 0.7,
                          );
                        }}
                      />
                    )}
                  </div>
                  {summary?.completed ? (
                    <LongTextInput
                      id="input-summary"
                      value={projectData.summary || ""}
                      handleOnChange={(e) =>
                        setProjectData((prev) => ({ ...prev, summary: e.target.value }))
                      }
                      height="160px"
                    />
                  ) : (
                    <span className="rounded border border-gray-300 p-4 text-gray-700">
                      {summary?.desc}
                      {!summary || !summary.completed ? <Spinner size="sm" /> : null}
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-3">
                    <p className="font-medium">
                      Choose the tags that best represent your organization
                    </p>
                    <div className="flex gap-2">
                      <Tag
                        id="tag-select-all"
                        name="Select all"
                        useCheckbox
                        checked={
                          focusAreaAll.filter((i) => focusArea.includes(i)).length ===
                            focusAreaAll.length &&
                          beneficiaryAll.filter((i) => beneficiary.includes(i)).length ===
                            beneficiaryAll.length &&
                          programAll.filter((i) => program.includes(i)).length === programAll.length
                        }
                        onClick={() => {
                          if (
                            focusAreaAll.filter((i) => focusArea.includes(i)).length ===
                              focusAreaAll.length &&
                            beneficiaryAll.filter((i) => beneficiary.includes(i)).length ===
                              beneficiaryAll.length &&
                            programAll.filter((i) => program.includes(i)).length ===
                              programAll.length
                          ) {
                            setFocusArea([]);
                            setBeneficiary([]);
                            setProgram([]);
                          } else {
                            setFocusArea(focusAreaAll);
                            setBeneficiary(beneficiaryAll);
                            setProgram(programAll);
                          }
                        }}
                        size="sm"
                      />
                      <Tag
                        id="tag-add-more-tags"
                        name="Add more tags"
                        onAdd={() => {
                          setEditTag(true);
                        }}
                        isFullTagClick
                        size="sm"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex gap-3">
                      <h6>{`Focus area (${focusArea.length}/${focusAreaAll.length})`}</h6>
                      {isLoading && <Spinner size="sm" />}
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {focusAreaAll.map((i) => (
                        <Tag
                          key={`tag-focus-area-${i}`}
                          id={`tag-focus-area-${i}`}
                          matched
                          useCheckbox
                          checked={focusArea.includes(i)}
                          onClick={() => {
                            setFocusArea((prev) =>
                              prev.includes(i) ? prev.filter((j) => j !== i) : [...prev, i],
                            );
                          }}
                          color="yellow"
                          size="sm"
                          name={i}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex gap-3">
                      <h6>{`Beneficiary (${beneficiary.length}/${beneficiaryAll.length})`}</h6>
                      {isLoading && <Spinner size="sm" />}
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {beneficiaryAll.map((i) => (
                        <Tag
                          key={`tag-beneficiary-${i}`}
                          id={`tag-beneficiary-${i}`}
                          matched
                          useCheckbox
                          checked={beneficiary.includes(i)}
                          onClick={() => {
                            setBeneficiary((prev) =>
                              prev.includes(i) ? prev.filter((j) => j !== i) : [...prev, i],
                            );
                          }}
                          color="blue"
                          size="sm"
                          name={i}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex gap-3">
                      <h6>{`Program (${program.length}/${programAll.length})`}</h6>
                      {isLoading && <Spinner size="sm" />}
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {programAll.map((i) => (
                        <Tag
                          key={`tag-program-${i}`}
                          id={`tag-program-${i}`}
                          matched
                          useCheckbox
                          checked={program.includes(i)}
                          onClick={() => {
                            setProgram((prev) =>
                              prev.includes(i) ? prev.filter((j) => j !== i) : [...prev, i],
                            );
                          }}
                          color="green"
                          size="sm"
                          name={i}
                        />
                      ))}
                    </div>
                    <PrimaryButton
                      id="btn-save-edit-tag"
                      label={`Looks good!${
                        (state?.from && state.from !== "search") || isUpdate
                          ? ""
                          : " Go to smart search"
                      }`}
                      className="my-4 w-fit self-center"
                      disabled={focusArea.length + beneficiary.length + program.length === 0}
                      handleOnClick={() => {
                        handleUpdateProject();
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <PlainButton
                  id="btn-back"
                  label="Back"
                  className="ml-5 self-start text-sm !text-gray-600"
                  leftIconClass="fa-solid fa-arrow-left !text-gray-600"
                  handleOnClick={() => setEditTag(false)}
                />
                <h5 className="text-gray-700">
                  You can remove or add additional tags related to your organization.
                </h5>
                <EditTaxonomy
                  focusArea={focusArea}
                  beneficiary={beneficiary}
                  program={program}
                  setFocusArea={setFocusArea}
                  setBeneficiary={setBeneficiary}
                  setProgram={setProgram}
                />
                <PrimaryButton
                  id="btn-save-edit-tag"
                  label="Save"
                  className="my-4 w-fit self-center"
                  handleOnClick={() => {
                    setEditTag(false);
                  }}
                />
              </>
            )}
          </div>
        </PopupModal>
      )}
      <div className="container mx-auto flex h-full max-w-[900px] flex-col bg-gray-50">
        <Portal>
          <div className="fixed top-[58px] z-[10] flex w-full justify-center bg-white shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)]">
            <div
              className={classNames(
                "flex w-full items-center justify-around",
                props.inProjectLayout ? "max-w-[900px] pl-[80px]" : "container max-w-[900px]",
              )}
            >
              {renderHeader()}
            </div>
          </div>
        </Portal>
        <div
          className={classNames(
            "relative h-auto overflow-y-auto ",
            props.inProjectLayout ? "" : "max-w-[900px]",
          )}
        >
          {step === 1 && (
            <>
              {renderProjectAccess()}
              {renderProjectOverview()}
            </>
          )}
          {(step === 2 || isUpdate) && renderProjectDetail()}
        </div>
      </div>
    </>
  );
}
ProjectCreationNew.defaultProps = {};
export default ProjectCreationNew;
