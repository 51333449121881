import { useCallback, useEffect, useMemo, useState } from "react";
import useTotalOrganizationMembersData from "./useTotalOrganizationMembersData";
import { ITotalOrganizationMember } from "../types/org";
import { ILocation, reprLocation } from "../types/location";
import useTaxonomyTree from "./useTaxonomyTree";
import { IOption } from "../components/tailwind/AsyncCreatableSelector";
import useSWR from "swr";
import { getOtherTaxonomies } from "../services/taxonomy.services";
import useDropdownOption from "./useDropdownOption";
import { GENERATIONS } from "../pages/npo/diversity/component/DiversitySelector";
import { TreeItem } from "../components/TreeSelector";
interface ICount {
  [key: string]: number;
}
export interface IDiversityInfo {
  [key: string]: { percentage: number; count: number };
}

interface IDiversity {
  diversity: IDiversityInfo;
  disability: IDiversityInfo;
  ethnicity: IDiversityInfo;
  gender: IDiversityInfo;
  generation: IDiversityInfo;
  profession: IDiversityInfo;
  religion: IDiversityInfo;
  sexualOrientation: IDiversityInfo;
  isLoading: boolean;
}
function useDiversity(
  org_id?: string,
  filter: "all" | "board" | "volunteer" | "employee" = "all",
): IDiversity {
  const { data, isLoading } = useTotalOrganizationMembersData(org_id);
  const { sexualOrientationOption, religionOption, professionOption } = useDropdownOption();
  const { data: beneficiary } = useTaxonomyTree("beneficiary");
  // const filteredData = useMemo(() => {
  //   if (filter === "all") {
  //     return data;
  //   } else {
  //     return data.filter((i) => i.employ_type === filter);
  //   }
  // }, [data, filter]);
  const [diversity, setDiversity] = useState<IDiversityInfo>({});
  const [disability, setDisability] = useState<IDiversityInfo>({});
  const [ethnicity, setEthnicity] = useState<IDiversityInfo>({});
  const [gender, setGender] = useState<IDiversityInfo>({});
  const [generation, setGeneration] = useState<IDiversityInfo>({});
  const [profession, setProfession] = useState<IDiversityInfo>({});
  const [religion, setReligion] = useState<IDiversityInfo>({});
  const [sexualOrientation, setSexualOrientation] = useState<IDiversityInfo>({});
  useEffect(() => {
    const filteredData =
      filter === "all"
        ? data.filter((i) => ["board", "volunteer", "employee"].includes(i.employ_type))
        : data.filter((i) => i.employ_type === filter);
    setDiversity(getDiversityInfo(filteredData));
    setDisability(getDisabilityPercentages(filteredData));
    setEthnicity(getEthnicityInfo(filteredData, beneficiary));
    setGender(getGenderInfo(filteredData));
    setGeneration(getGenerationInfo(filteredData));
    setProfession(
      getProfessionInfo(
        filteredData.filter((i) => i.employ_type === "board"),
        professionOption.map((i) => i.value),
      ),
    );
    setReligion(
      getReligionInfo(
        filteredData,
        religionOption.map((i) => i.value),
      ),
    );
    setSexualOrientation(
      getSexualOrientationInfo(
        filteredData,
        sexualOrientationOption.map((i) => i.value),
      ),
    );
  }, [data, filter]);
  return {
    diversity,
    disability,
    ethnicity,
    gender,
    generation,
    profession,
    religion,
    sexualOrientation,
    isLoading,
  };
}
const UNKNOWN = "Unknown";
const ignoreKeys = ["do_not_wish_to_answer"];
export default useDiversity;
const getCountsFromMultipleOption = (
  keys: string[],
  data: string[][],
  allowAnyKey = false,
  allowOther = false,
  allowDoNotWishToAnswer = false,
  showUnknown = UNKNOWN,
): IDiversityInfo => {
  const filteredKeys = allowDoNotWishToAnswer ? keys : keys.filter((k) => !ignoreKeys.includes(k));
  const empty = filteredKeys.reduce(
    (prev, cur) => ({ ...prev, [cur]: { percentage: 0, count: 0 } }),
    allowOther ? { other: { percentage: 0, count: 0 } } : ({} as IDiversityInfo),
  );
  if (data.length === 0) return empty;
  const counts: ICount = data.reduce(
    (prev: ICount, cur: string[]) =>
      showUnknown && cur.length === 0
        ? { ...prev, [showUnknown]: prev[showUnknown] ? prev[showUnknown] + 1 : 1 }
        : cur
            .filter((v) => allowOther || allowDoNotWishToAnswer || !ignoreKeys.includes(v))
            .reduce(
              (p, cur: string) =>
                filteredKeys.includes(cur) || allowAnyKey
                  ? { ...p, [cur]: p[cur] ? p[cur] + 1 : 1 }
                  : allowOther
                  ? { ...p, other: p["other"] ? p["other"] + 1 : 1 }
                  : p,
              prev,
            ),
    filteredKeys.reduce((prev, cur) => ({ ...prev, [cur]: 0 }), {}) as ICount,
  );
  const totalCount = Object.keys(counts).reduce((prev, key) => prev + counts[key], 0);
  if (totalCount === 0) return empty;
  return Object.keys(counts).reduce(
    (prev, key) => ({
      ...prev,
      [key]: { percentage: Math.ceil((100 * counts[key]) / totalCount), count: counts[key] },
    }),
    {} as IDiversityInfo,
  );
};
const getCountsFromSingleOption = (
  keys: string[],
  data: string[],
  allowAnyKey = false,
  allowOther = false,
  allowDoNotWishToAnswer = false,
  showUnknown = UNKNOWN,
): IDiversityInfo => {
  const filteredKeys = allowDoNotWishToAnswer ? keys : keys.filter((k) => !ignoreKeys.includes(k));
  const empty = filteredKeys.reduce(
    (prev, cur) => ({ ...prev, [cur]: { percentage: 0, count: 0 } }),
    {},
  );
  if (data.length === 0) return empty;
  const counts: ICount = data
    .filter((v) => allowOther || allowDoNotWishToAnswer || !ignoreKeys.includes(v))
    .reduce(
      (prev: ICount, cur: string) =>
        !cur && showUnknown
          ? {
              ...prev,
              [showUnknown]: prev[showUnknown] ? prev[showUnknown] + 1 : 1,
            }
          : cur
          ? filteredKeys.includes(cur) || allowAnyKey
            ? { ...prev, [cur]: prev[cur] ? prev[cur] + 1 : 1 }
            : allowOther
            ? { ...prev, other: prev["other"] ? prev["other"] + 1 : 1 }
            : prev
          : prev,
      filteredKeys.reduce((prev, cur) => ({ ...prev, [cur]: 0 }), {}) as ICount,
    );
  const totalCount = Object.keys(counts).reduce((prev, key) => prev + counts[key], 0);
  if (totalCount === 0) return empty;
  return Object.keys(counts).reduce(
    (prev, key) => ({
      ...prev,
      [key]: { percentage: Math.ceil((100 * counts[key]) / totalCount), count: counts[key] },
    }),
    {} as IDiversityInfo,
  );
};
function getDiversityInfo(data: ITotalOrganizationMember[]) {
  const DiversityCounts: ICount = {};
  for (const person of data) {
    const locations = Array.isArray(person.geographic_focus)
      ? person.geographic_focus
      : [person.geographic_focus];
    for (const loc of locations) {
      const temp = reprLocation(loc as ILocation) || "";
      if (temp in DiversityCounts) {
        DiversityCounts[temp]++;
      } else {
        DiversityCounts[temp] = 1;
      }
    }
  }
  const totalRecords = data.length;
  const diversityPercentage: IDiversityInfo = getCountsFromMultipleOption(
    [],
    data.map((m) => (m.geographic_focus ? m.geographic_focus.map((l) => reprLocation(l)) : [])),
    true,
  );
  return diversityPercentage;
}

function getDisabilityPercentages(data: ITotalOrganizationMember[]) {
  const labels = ["with disabilities", "without disabilities", "do_not_wish_to_answer"];
  const percentages: IDiversityInfo = getCountsFromSingleOption(
    labels,
    data.map((m) => m.disability || ""),
    false,
    false,
    true,
  );
  return percentages;
}

function getEthnicityInfo(data: ITotalOrganizationMember[], beneficiary: TreeItem[]) {
  const ethnicGroup = beneficiary
    .filter((item) => item.parent === "ethnic & cultural groups")
    .reduce(
      (prev, p) => [
        ...prev,
        p.label,
        ...beneficiary.filter((t) => t.parent === p.label).map((t) => t.label),
      ],
      [] as string[],
    );
  const ethnicityPercentages: IDiversityInfo = getCountsFromMultipleOption(
    ethnicGroup,
    data.map((m) => m.ethnic || []),
    true,
    true,
  );
  return ethnicityPercentages;
}

function getGenderInfo(data: ITotalOrganizationMember[]) {
  const GenderLabels = ["man", "woman", "other"];
  const GenderPercentages: IDiversityInfo = getCountsFromSingleOption(
    GenderLabels,
    data.map((m) => m.gender || ""),
    false,
    false,
    true,
  );
  return GenderPercentages;
}

function getGenerationInfo(data: ITotalOrganizationMember[]) {
  const generationLabels = GENERATIONS.map((i) => i.value);
  const generationPercentages: IDiversityInfo = getCountsFromMultipleOption(
    generationLabels,
    data.map((m) => m.generation || []),
  );
  return generationPercentages;
}

function getProfessionInfo(data: ITotalOrganizationMember[], labels: string[] = []) {
  const professionPercentages: IDiversityInfo = getCountsFromMultipleOption(
    labels,
    data.map((m) => m.profession || []),
    true,
  );
  return professionPercentages;
}

function getReligionInfo(data: ITotalOrganizationMember[], labels: string[] = []) {
  const generationPercentages: IDiversityInfo = getCountsFromMultipleOption(
    labels,
    data.map((m) => m.religion || []),
    true,
  );

  return generationPercentages;
}

function getSexualOrientationInfo(data: ITotalOrganizationMember[], labels: string[] = []) {
  const sexualOrientationPercentages: IDiversityInfo = getCountsFromMultipleOption(
    labels,
    data.map((m) => m.sexual_orientation || []),
  );
  return sexualOrientationPercentages;
}
