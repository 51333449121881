import classNames from "classnames";
import { TooltipWrapper } from "../TooltipWrapper";
import { FunderSearchResult, SearchQuery, getSearchResultKey } from "../../types/search";
import RoundButton from "../RoundButton";
import {
  getFunderHqLocation,
  renderRow,
  reprFunderPreferredStages,
  reprFunderTotalGrantCount,
} from "../../utils/funder";
import TagFunderSize from "../TagFunderSize";
import PhilanthropicInformationFlexWrap from "../PhilanthropicInformationFlexWrap";
import TagOrganization from "../TagOrganization";
import useIsPreviousFunder from "../../hooks/search/useIsPreviousFunder";
import TagPreviousFunder from "../TagPreviousFunder";
import TagInvitationOnly from "../TagInvitationOnly";
import Fuse from "fuse.js";
import FuzzyMatchName from "../FuzzyMatchName";
import StartFundRaisingModal from "../StartFundraisingModal";
import { ReactElement, memo, useCallback, useEffect, useState } from "react";
import TagFunderType from "../TagFunderType";
import { ReprServiceLocations } from "../../utils/grant";
import { toUsdLong } from "../../utils/formatHelper";
import useSave from "../../hooks/save/useSave";
import { ASSET_SIZE_OPTION, FUNDING_SIZE_OPTION } from "../../types/searchFilter";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";
import useLazyLoad from "../../hooks/useLazyLoad";
import MemoizedLoadingPhilanthropy from "../LoadingPhilanthropy";
import LoadingTaxonomy from "../LoadingTaxonomy";
import { filterUnmatched, matchTaxonomyBySearchQuery } from "../../types/taxonomy";
import { Divider } from "rsuite";
import SavePopup from "../SavePopup";
interface FunderCardProps {
  funder: FunderSearchResult;
  query?: SearchQuery;
  noMatchTaxonomy?: boolean;
  hideInfo?: boolean;
  fuzzy?: Fuse<string>;
  isHidden?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  setHide?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, hide: boolean) => void;
  selected?: boolean;
  save_list_id?: string;
  savedSearchQuery?: SearchQuery;
  lazyLoadDelay?: number;
  hideBtns?: boolean;
  useMinHeightOnTaxonomy?: boolean;
}
function FunderCard({
  funder,
  noMatchTaxonomy = false,
  hideInfo = false,
  query,
  fuzzy,
  isHidden = false,
  setHide,
  onClick,
  lazyLoadDelay = 0,
  hideBtns = false,
  ...props
}: FunderCardProps) {
  const { data: hasGranted } = useIsPreviousFunder(funder._id);
  const { ready } = useLazyLoad(lazyLoadDelay);
  const {
    data: funderTaxonomy,
    isLoading,
    dataAll,
  } = useFunderSearchResult(!hideInfo && !noMatchTaxonomy && ready ? funder._id : undefined, query);
  const [showFundraising, setShowFundraising] = useState<boolean>(false);
  const key = getSearchResultKey(funder);
  const saveMode = props.save_list_id ? true : false;
  const { data, save } = useSave(saveMode ? funder._id : undefined, 0, props.savedSearchQuery);
  const { data: currentSavedList } = useSave(funder._id, 0);
  const [showSave, setShowSave] = useState<boolean>(false);
  return (
    <>
      {showSave && funder._id && (
        <SavePopup
          open={showSave}
          onClose={() => setShowSave(false)}
          target_id={funder?._id}
          target_type={0}
          searchQuery={query}
        />
      )}
      <div
        key={key}
        className={classNames(
          "flex h-fit w-full flex-col gap-[10px]",
          onClick ? "cursor-pointer bg-white hover:bg-purple-50" : "",
        )}
        {...(onClick ? { onClick } : {})}
      >
        {/* {showFundraising && (
        <StartFundRaisingModal
          open={showFundraising}
          setOpen={setShowFundraising}
          funder={funder}
          id={funder._id}
          type="FUNDER"
        />
      )} */}
        <div className="inline-flex items-center justify-between">
          <div className="flex flex-wrap items-center gap-2">
            {/* <TagOrganization type="funder" /> */}
            <TagFunderType funder_type={funder.funder_type} />
            {query &&
              query.funder_assets.length > 0 &&
              query.funder_assets.length < ASSET_SIZE_OPTION.length && (
                <TagFunderSize assets={funder.assets} className="w-fit" />
              )}
            {hasGranted ? <TagPreviousFunder /> : null}
          </div>
          {!hideInfo && !hideBtns && (
            <>
              {saveMode ? (
                <TooltipWrapper content={isHidden ? "Save this funder" : "Unsave this funder"}>
                  <div
                    className="cursor-pointer p-1 !text-gray-900"
                    id={"GA_TAG_click_save"}
                    onClick={(e) => {
                      if (props.save_list_id) save(props.save_list_id, isHidden);
                      e.stopPropagation();
                    }}
                  >
                    <i
                      className={classNames(
                        "gi-md text-purple-500 ",
                        isHidden ? "gi-heart" : "gi-heart-full",
                      )}
                    />
                  </div>
                </TooltipWrapper>
              ) : (
                <div className="inline-flex items-center">
                  <TooltipWrapper
                    content={
                      currentSavedList.length > 0 ? "Unsave this funder" : "Save this funder"
                    }
                  >
                    <div
                      className="cursor-pointer p-1 !text-gray-900"
                      id={"GA_TAG_click_save"}
                      onClick={(e) => {
                        setShowSave(true);
                        e.stopPropagation();
                      }}
                    >
                      <i
                        className={classNames(
                          "gi-md text-purple-500 ",
                          currentSavedList.length > 0 ? "gi-heart-full" : "gi-heart",
                        )}
                      />
                    </div>
                  </TooltipWrapper>
                  <TooltipWrapper content={isHidden ? "Unhide this funder" : "Hide this funder"}>
                    <div
                      className="cursor-pointer p-1 !text-gray-900"
                      onClick={(e) => {
                        setHide?.(e, !isHidden);
                      }}
                    >
                      <i className={classNames("gi-md ", isHidden ? "gi-view-hide" : "gi-view")} />
                    </div>
                  </TooltipWrapper>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex flex-col items-start justify-between gap-2">
          <h4 className="line-clamp-2 w-full grow whitespace-pre-line font-semibold text-purple-500 group-hover:underline">
            <FuzzyMatchName
              name={funder.name}
              fuzzy={fuzzy}
              items={query?.text_query?.split(" ")}
            />
          </h4>
          {!hideInfo && funder.does_not_accept_unsolicited !== true && (
            <TagInvitationOnly invitationOnly={false} />
          )}
          {/* <div className="mt-1 flex min-w-[40px] items-center justify-end gap-4">
          <RoundButton
            tooltip={"Start fundraising"}
            type="secondary"
            id={`btn-add-funder-to-project-${funder._id}`}
            handleOnClick={() => {
              setShowFundraising((prev) => !prev);
            }}
            icon="gi-sm gi-start text-purple-500"
          />
        </div> */}
        </div>
        {!hideInfo ? (
          <>
            {!hideInfo && (
              <Divider
                className={classNames("my-0 ", props.selected ? "bg-purple-200" : "bg-gray-200")}
              />
            )}
            {funder.address && renderRow("Address", funder.address)}
            {dataAll?.service_loc &&
              dataAll?.service_loc.length > 0 &&
              renderRow(
                "Service areas",
                <ReprServiceLocations
                  service_loc={dataAll.service_loc}
                  detail={false}
                  max={2}
                  selected={props.selected}
                  query={query}
                />,
              )}
            {reprFunderPreferredStages(funder, false) &&
              renderRow(
                "Funded stage",
                reprFunderPreferredStages(funder, false, 3, props.selected),
              )}
            {funder.grant_amount_max
              ? renderRow(
                  "Funding size",
                  `${toUsdLong(funder.grant_amount_min)} - ${toUsdLong(funder.grant_amount_max)}`,
                )
              : null}
            {reprFunderTotalGrantCount(funder)
              ? renderRow(
                  "Number of grants made",
                  reprFunderTotalGrantCount(funder).toLocaleString(),
                )
              : null}
            {!noMatchTaxonomy &&
              (isLoading || (!ready && !funderTaxonomy) ? (
                <LoadingTaxonomy seed={funder.uid} />
              ) : (
                <PhilanthropicInformationFlexWrap
                  containerClass={classNames(
                    "p-4 border rounded",
                    props.selected ? "border-purple-200" : "border-gray-200",
                  )}
                  id={key}
                  focusArea={funderTaxonomy?.focus_area}
                  beneficiary={funderTaxonomy?.beneficiary}
                  program={funderTaxonomy?.program}
                  location={funderTaxonomy?.service_loc}
                  priorityFocusArea={query?.must_focus_area}
                  priorityBeneficiary={query?.must_beneficiary}
                  priorityProgram={query?.must_program}
                  priorityLocation={query?.must_service_loc}
                  secondaryPriorityLocation={query?.service_loc}
                  tagSize="sm"
                  tagMatched
                  allowPastGrantSearch
                  funder_id={funder._id}
                  searchQuery={query}
                  predictedSize={5}
                  useMinHeight={props.useMinHeightOnTaxonomy}
                />
              ))}
          </>
        ) : (
          <div className="h-2"></div>
        )}
      </div>
    </>
  );
}
export default FunderCard;
