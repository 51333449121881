import classNames from "classnames";
import { TooltipWrapper } from "../TooltipWrapper";
import { NPOSearchResult, SearchQuery, getSearchResultKey } from "../../types/search";
import { getNPOHqLocation, getNPOServiceLocation } from "../../utils/npo";
import PhilanthropicInformationFlexWrap from "../PhilanthropicInformationFlexWrap";
import TagOrganization from "../TagOrganization";
import Fuse from "fuse.js";
import FuzzyMatchName from "../FuzzyMatchName";
import StartFundRaisingModal from "../StartFundraisingModal";
import { useState } from "react";
import useLazyLoad from "../../hooks/useLazyLoad";
import useNPOSearchResult from "../../hooks/search/useNPOSearchResult";
import MemoizedLoadingTaxonomy from "../LoadingTaxonomy";
import TagFunderType from "../TagFunderType";
interface NPOCardProps {
  wrapperClass?: string;
  npo: NPOSearchResult;
  query?: SearchQuery;
  noMatchTaxonomy?: boolean;
  hideInfo?: boolean;
  fuzzy?: Fuse<string>;
  isHidden?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  setHide?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, hide: boolean) => void;
  selected?: boolean;
  lazyLoadDelay?: number;
}
function NPOCardSimple({
  npo,
  noMatchTaxonomy = false,
  hideInfo = false,
  query,
  fuzzy,
  isHidden = false,
  setHide,
  onClick,
  lazyLoadDelay = 0,
  ...props
}: NPOCardProps) {
  const key = getSearchResultKey(npo);
  const [showFundraising, setShowFundraising] = useState<boolean>(false);
  const { ready } = useLazyLoad(lazyLoadDelay);
  const { data: npoTaxonomy, isLoading } = useNPOSearchResult(
    !hideInfo && !noMatchTaxonomy && ready ? npo._id : undefined,
    query,
  );
  return (
    <div
      key={key}
      className={classNames(
        "group flex h-fit w-full flex-col gap-2",
        onClick ? "cursor-pointer bg-white hover:bg-purple-50" : "",
        props.wrapperClass,
      )}
      {...(onClick ? { onClick } : {})}
    >
      {/* {showFundraising && (
        <StartFundRaisingModal open={showFundraising} setOpen={setShowFundraising} type="NPO" />
      )} */}
      <div className="mb-1 inline-flex items-center justify-between">
        <div className="flex items-center gap-2">
          <TagFunderType funder_type={npo.npo_type} />
        </div>
      </div>
      <div className="flex items-start justify-between">
        <h4 className="line-clamp-2 w-full grow whitespace-pre-line font-semibold text-purple-500 group-hover:underline">
          <FuzzyMatchName name={npo.name} fuzzy={fuzzy} items={query?.text_query?.split(" ")} />
        </h4>
        {/* <div className="mt-1 flex min-w-[40px] items-center justify-end gap-4">
          <RoundButton
            tooltip={"Start fundraising"}
            type="secondary"
            id={`btn-add-npo-to-project-${npo._id}`}
            handleOnClick={() => {
              console.log("handle save to project");
            }}
            icon="gi-sm gi-start text-purple-500"
          />
        </div> */}
      </div>
      {!hideInfo ? (
        <>
          <h5 className="text-gray-900">
            Address: <span className="text-gray-900 font-semibold">{getNPOHqLocation(npo)}</span>
          </h5>
          {!noMatchTaxonomy &&
            (isLoading || (!ready && !npoTaxonomy) ? (
              <MemoizedLoadingTaxonomy seed={npo.uid} />
            ) : (
              <PhilanthropicInformationFlexWrap
                containerClass={classNames(
                  "mt-2 p-4 border rounded",
                  props.selected ? "border-purple-200" : "border-gray-300",
                )}
                id={key}
                focusArea={npoTaxonomy?.focus_area}
                beneficiary={npoTaxonomy?.beneficiary}
                program={npoTaxonomy?.program}
                location={npoTaxonomy?.service_loc}
                priorityFocusArea={query?.must_focus_area}
                priorityBeneficiary={query?.must_beneficiary}
                priorityProgram={query?.must_program}
                priorityLocation={query?.must_service_loc}
                secondaryPriorityLocation={query?.service_loc}
                tagSize="sm"
                tagMatched
                predictedSize={3}
              />
            ))}
        </>
      ) : (
        <div className="h-2"></div>
      )}
    </div>
  );
}
export default NPOCardSimple;
