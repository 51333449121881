import axios from "axios";
import config from "../api";
import { Onboarding, OnboardingRequest } from "../app/onboarding";
import { AppDispatch } from "../app/store";
import { fetchOnboardingAsync, updateOnboardingLocal } from "../app/onboardingSlice";

const updateOnboardingData = ({
  data,
  id,
}: {
  data: OnboardingRequest;
  id: string | undefined;
}) => {
  if (!id) return;
  const unset = Object.keys(data)
    .filter((key) => data[key as keyof OnboardingRequest] === undefined && key !== "_id")
    .reduce((prev, k) => ({ ...prev, [k]: "" }), {});
  return new Promise((resolve, reject) => {
    axios
      .put(
        process.env.REACT_APP_API_URL + `/api/v2/orgs/${id}`,
        {
          ...data,
          ...{
            _id: undefined,
            npo: undefined,
            npo_profile: undefined,
            owner: undefined,
          },
        },
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const updateOnboardingDataImmediately = ({
  data,
  id,
  dispatch,
}: {
  data: OnboardingRequest;
  id: string | undefined;
  dispatch: AppDispatch;
}) => {
  if (!id) return;
  return Promise.all([
    dispatch(updateOnboardingLocal(data)),
    updateOnboardingData({
      data,
      id,
    }),
  ])
    .then((res) => res[1])
    .catch((err) => {
      // revert
      dispatch(fetchOnboardingAsync(id));
    });
};

export { updateOnboardingData, updateOnboardingDataImmediately };
