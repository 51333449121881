import { useCallback } from "react";
import { updateProjectLetter } from "../hooks/project/useLetter";
import useTask from "../hooks/project/useTask";
import { IProjectLetterUpdateRequest } from "../types/project";
import GenerateLetterOfInquiry from "./GenerateLetterOfInquiry";
import GibooToast from "./GibooToast";

interface GeneratedLetterPreviewProps {
  project_id: string;
  task_id: string;
  letter_id: string;
  showLoiPreview: boolean;
  setShowLoiPreview: (flag: boolean) => void;
}

function GeneratedLetterPreview({
  project_id,
  task_id,
  letter_id,
  showLoiPreview,
  setShowLoiPreview,
  ...props
}: GeneratedLetterPreviewProps) {
  const { data: task, revalidate } = useTask(task_id);
  const draftLOI = useCallback(
    (loi: IProjectLetterUpdateRequest) => {
      if (task && letter_id)
        updateProjectLetter(project_id, letter_id, { ...loi })
          .then(() => {
            revalidate();
            GibooToast({
              type: "success",
              message: `Letter drafted.`,
            });
          })
          .catch((e) => {
            GibooToast({
              type: "failure",
              message: `We apologize for the inconvenience. Please try again.`,
            });
          });
    },
    [project_id, task, letter_id],
  );
  return (
    <>
      {showLoiPreview && (
        <GenerateLetterOfInquiry
          open={showLoiPreview}
          project_id={project_id}
          prev_letter_id={letter_id}
          task_id={task?._id}
          target_id={task.target_id}
          target_type={task.target_type}
          onClose={() => setShowLoiPreview(false)}
          onSave={(loi) => {
            setShowLoiPreview(false);
            draftLOI(loi);
          }}
          disableFeedback
        />
      )}
    </>
  );
}
export default GeneratedLetterPreview;
