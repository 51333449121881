import React from "react";
import { TooltipWrapper } from "./TooltipWrapper";
import Button from "./tailwind/Button";
import PlainButton from "./PlainButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getValidNumber } from "../utils/formatHelper";
import { getSkipOnboardingStep } from "../pages/npo/OnboardingView";
import classNames from "classnames";
import useOnboardingData from "../hooks/useOnboarding";

export default function OnboardingSkipButton({
  onSkip,
  disabled = false,
}: {
  onSkip?: () => void;
  disabled?: boolean;
}) {
  const [onboardingData] = useOnboardingData();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const _type = getValidNumber(searchParams.get("type"), 0);
  const handleSkip = () => {
    navigate(`/onboarding?type=${_type}&step=${getSkipOnboardingStep(_type)}`, {
      replace: true,
    });
  };
  return (
    <>
      {onboardingData.role === 5 ? (
        <TooltipWrapper
          WrapperClass="max-w-[260px] !p-4 !rounded-[4px]"
          Zindex={9999}
          placements="right"
          content={`You may skip onboarding now, but completing it will increase accuracy. You can always finish filling in the information later.`}
        >
          <div
            id="skip-onboarding"
            className={classNames(
              " grid w-[265px] cursor-pointer place-items-center rounded px-4 py-2 hover:bg-purple-50",
              disabled ? "!text-gray-400" : "group text-purple-500",
            )}
            onClick={() => {
              if (disabled) return;
              onSkip?.();
              handleSkip();
            }}
          >
            <h5 className="cursor-pointer text-sm font-normal text-inherit group-hover:font-semibold">
              Skip onboarding and finish later
            </h5>
          </div>
        </TooltipWrapper>
      ) : null}
    </>
  );
}
