import { useLayoutEffect } from "react";

export default function usePageTitle(pageName: string, count?: number, custom = false): void {
  useLayoutEffect(() => {
    if (custom) {
      document.title = `${pageName}`;
    } else if (count !== undefined) {
      document.title = `(${count}) ${pageName} | Giboo`;
    } else {
      document.title = `${pageName} | Giboo`;
    }
    //reset page title
    return () => {
      document.title = "Giboo";
    };
  }, [pageName, count, custom]);
}
