import classNames from "classnames";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { AppThunkDispatch, useAppDispatch, useAppSelector } from "../../../app/store";
import EditableInput from "../../../components/input/EditableInput";
import LongTextInput from "../../../components/LongTextInput";
import PlainButton from "../../../components/PlainButton";
import PrimaryButton from "../../../components/PrimaryButton";
import {
  getOnboardingStatus,
  selectNPOInfo,
  selectNPOInfoRequested,
  updateOnboardingAsync,
} from "../../../app/onboardingSlice";
import OnboardingBack from "./OnboardingBack";
import TextArea from "../../../components/tailwind/TextArea";
import GibooGradientDiv from "../../../components/GibooGradientDiv";
import { Information, MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import useOnboardingData from "../../../hooks/useOnboarding";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import OnboardingSkipButton from "../../../components/OnboardingSkip";
const debouncedUpdateMission = debounce(
  function update(dispatch: AppThunkDispatch, mission: string) {
    dispatch(
      updateOnboardingAsync({ mission_requested: mission }, 0, {
        updateImmediately: true,
        updateOnlyRequestedData: true,
      }),
    );
  },
  5000,
  { maxWait: 15000 },
);
function NPOMission() {
  const mxEvent = useGibooMixpanel();
  const dispatch = useAppDispatch();
  const { type } = useAppSelector((state) => getOnboardingStatus(state));
  const NPOInfo = useAppSelector((state) => selectNPOInfo(state));
  const { mission_requested } = useAppSelector((state) => selectNPOInfoRequested(state));
  const [missionState, setMissionState] = useState<string>("");
  const [onboardingData] = useOnboardingData();
  useEffect(() => {
    setMissionState(mission_requested ? mission_requested : NPOInfo.mission ? NPOInfo.mission : "");
  }, [NPOInfo]);
  const updateInfo = () => {
    debouncedUpdateMission.cancel();
    mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
      field: Information.MISSION,
      orgMission: missionState,
      orgOriginalMission: NPOInfo?.mission,
      orgMissionIsModified: missionState !== NPOInfo?.mission ? true : false,
    });
    dispatch(
      updateOnboardingAsync(
        {
          mission_requested: missionState,
        },
        +1,
      ),
    );
  };
  const restore = () => {
    setMissionState(NPOInfo.mission || "");
  };
  return (
    <>
      <div className="onboarding flex flex-col">
        <h4 className="onboarding-header">
          Now please take a moment to review your organization mission statement.
        </h4>
        {type === 3 || type === 5 ? (
          <p className="onboarding-sub-header-gray  onboarding-width-lg mt-3">
            Your organization&apos;s mission statement is a starting place for Giboo to analyze and
            match potential funders and grant opportunities.
          </p>
        ) : (
          <p className="onboarding-sub-header-gray  onboarding-width-lg mt-3">
            Your organization&apos;s mission statement is a starting place for Giboo to analyze and
            match potential funders and grant <br /> opportunities. You can add more details to help
            capture important details that might not be included in this statement.
          </p>
        )}

        <div className="onboarding-body mt-[40px] w-[750px]">
          <div className="inline-flex items-center justify-between">
            <h6 className="font-poppins text-sm font-semibold">
              Mission statement*{" "}
              <span className="font-poppins text-xs !font-normal !text-gray-700">(Required)</span>
            </h6>
            {type === 1 && (
              <PlainButton
                id="btn-restore"
                className="!h-[29px] !text-sm !text-gray-600"
                label="Reset to default"
                handleOnClick={restore}
                outline
              />
            )}
          </div>
          <div className="editableinput-container container">
            <TextArea
              id="mission-statement"
              value={missionState}
              placeholder="Enter text here. It must be longer than 10 words."
              className="!border-gray-300 !px-[18px] !text-sm font-normal text-gray-700"
              noOfRows={7}
              handleOnChange={(e) => {
                setMissionState(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={classNames("my-10 inline-flex items-center gap-2")}>
          <OnboardingBack
            outline
            data={{
              mission_requested: missionState,
            }}
          />
          <PrimaryButton
            disabled={!missionState}
            size="md"
            id="btn-next"
            handleOnClick={updateInfo}
            rightIconClass={<i className="gi gi-right-arrow" />}
            label="Next"
          />
          <OnboardingSkipButton />
        </div>
      </div>
    </>
  );
}
export default NPOMission;
