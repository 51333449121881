import classNames from "classnames";
import { memo } from "react";

function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const randomArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) =>
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => getRandomNumber(100, 150)),
);
function LoadingTaxonomy({ seed = 0, wrapperClass }: { seed?: number; wrapperClass?: string }) {
  const arr = randomArray[seed < 0 ? (seed * -1) % seed : seed % 10];
  return (
    <div className="loading-light-shimmer-on flex flex-col overflow-hidden rounded border border-gray-300 px-4 py-3">
      <div className="loading-light-shimmer-child flex max-h-[50px] flex-wrap gap-1 overflow-hidden">
        {arr.map((w, i) => (
          <div key={i} style={{ minWidth: `${w}px`, minHeight: "24px" }}></div>
        ))}
      </div>
    </div>
  );
}
const MemoizedLoadingTaxonomy = memo(LoadingTaxonomy);
export default MemoizedLoadingTaxonomy;
