import React, { ReactNode } from "react";
import IMG_1 from "../../../../assets/financial/business-model-1.svg";
import IMG_2 from "../../../../assets/financial/business-model-2.svg";
import IMG_3 from "../../../../assets/financial/business-model-3.svg";
import classNames from "classnames";
import Spacer from "../../../../components/Spacer";
import Button from "../../../../components/tailwind/Button";

export default function BusinessModel({
  onBack,
  onNext,
  npo_id,
  isBack,
  hasWritePermission,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  isBack?: boolean;
  hasWritePermission?: boolean;
}) {
  const renderTitle = (title: string, className?: string) => {
    return (
      <div>
        <h5 className={classNames("font-poppins text-xl font-semibold text-black", className)}>
          {title}
        </h5>
      </div>
    );
  };

  const renderitem = (
    index: number,
    title: string | ReactNode,
    content: ReactNode,
    image: string,
  ) => {
    return (
      <div className="flex flex-wrap items-center gap-[60px] md:flex-nowrap">
        <div className="flex w-full max-w-[493px] flex-col gap-3">
          <div className="inline-flex items-start gap-2">
            <span className="flex h-[21px] w-[21px] shrink-0 items-center justify-center rounded-full bg-purple-500 text-xs font-semibold text-white ">
              {index}
            </span>
            <div className="font-poppins text-sm font-semibold text-black">{title}</div>
          </div>
          <div className="pl-[30px] font-poppins text-sm text-gray-800">{content}</div>
        </div>
        <div className="h-fit w-fit">
          <img src={image} alt="business-model-image" />
        </div>
      </div>
    );
  };
  return (
    <div className="flex max-w-[881px] flex-col gap-6">
      {renderTitle("I. Business model")}
      <div className="flex flex-col gap-[60px]">
        {renderitem(
          1,
          "What is the business model of a nonprofit organization?",
          <>
            The business model of a nonprofit organization encapsulates the fundamental approach
            through which it generates income to sustain its operations and fulfill its mission.
            <Spacer height="8px" />
            This multifaceted framework outlines the diverse avenues through which revenue is
            acquired, encompassing donations, grants, services, and other potential sources.
          </>,
          IMG_1,
        )}
        {renderitem(
          2,
          "What is the significance of analyzing the business model of a nonprofit organization?",
          <>
            Essentially, it serves as the organizational blueprint for financial sustainability,
            delineating the primary strategies employed to secure the necessary resources for the
            nonprofit&apos;s activities.
            <Spacer height="8px" />
            The significance of a well-defined business model lies in its capacity to shape and
            guide the overall financial strategy, providing clarity on how the organization can
            thrive in the long run.
          </>,
          IMG_2,
        )}
        {renderitem(
          3,
          "What strategic actions can nonprofits take to both fortify their financial foundation and enhance adaptability and resilience?",
          <>
            To fortify the financial foundation, it is imperative to take strategic actions such as
            diversifying revenue sources to mitigate dependence on a singular channel, thereby
            reducing vulnerability to external fluctuations.
            <Spacer height="8px" />
            Additionally, a critical step involves assessing the alignment of the business model
            with the nonprofit&apos;s mission to ensure that financial pursuits are harmoniously
            integrated with the overarching goals and values. By consistently evaluating and
            adapting the business model, nonprofits can enhance their adaptability and resilience in
            the ever-evolving landscape of financial sustainability.
          </>,
          IMG_3,
        )}
        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            disabled
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              onNext?.();
            }}
            disabled={!hasWritePermission}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
