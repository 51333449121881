import { Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { useState, useEffect, useRef, ReactNode } from "react";

interface SliderProps {
  selectedIndex?: number;
  slides: ReactNode[];
  wrapperClass?: string;
  showFeatureIntro?: boolean;
  dotNavClass?: string;
  onChange?: (index: number) => void;
  renderFooter?: () => ReactNode;
}
const ArraySlider: React.FC<SliderProps> = ({
  slides,
  dotNavClass,
  showFeatureIntro,
  selectedIndex = 0,
  onChange,
  renderFooter,
  ...props
}) => {
  const [showPrevButton, setShowPrevButton] = useState(true);
  const [showNextButton, setShowNextButton] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState<"previous" | "next">();

  useEffect(() => {
    setCurrentIndex(selectedIndex);
    if (currentIndex < selectedIndex) {
      setDirection("next");
    } else if (currentIndex > selectedIndex) {
      setDirection("previous");
    }
  }, [selectedIndex]);

  const keys = Object.keys(slides);
  const values = Object.values(slides);
  const total = keys.length;

  useEffect(() => {
    setShowPrevButton(currentIndex > 0);
    setShowNextButton(currentIndex < total - 1);
  }, [currentIndex, slides]);

  const goToPrevSlide = () => {
    const index = currentIndex > 0 ? currentIndex - 1 : 0;
    setCurrentIndex(index);
    setDirection("previous");
    onChange?.(index);
  };
  const goToNextSlide = () => {
    const index = currentIndex <= total ? currentIndex + 1 : 0;
    setCurrentIndex(index);
    setDirection("next");
    onChange?.(index);
  };

  return (
    <>
      <div className={classNames("relative grid  h-full place-items-center", props.wrapperClass)}>
        <div className="flex w-full justify-center overflow-x-hidden">
          {values.map((value, index) => {
            return (
              <Transition
                key={index}
                show={currentIndex === index}
                enter="transition-transform duration-700"
                enterFrom={`transform ${
                  direction === "previous"
                    ? "-translate-x-full"
                    : direction === "next"
                    ? "translate-x-full"
                    : ""
                }`}
                enterTo="transform translate-x-0"
                leave="transition-transform duration-650"
                leaveFrom="transform translate-x-0"
                leaveTo={`transform ${
                  direction === "previous"
                    ? "translate-x-full"
                    : direction === "next"
                    ? "-translate-x-full"
                    : ""
                }`}
              >
                {value}
              </Transition>
            );
          })}
        </div>
        {showPrevButton && (
          <div
            className="absolute -left-5 top-[44%]  flex h-[90%] w-fit -translate-y-1/2 transform items-center  transition duration-300 ease-in-out"
            onClick={goToPrevSlide}
          >
            <div className="z-10 grid h-9 w-9 place-items-center rounded-full border border-gray-100 bg-white text-gray-800 opacity-100 shadow-md hover:bg-gray-100">
              <i className="fas fa-chevron-left text-sm"></i>
            </div>
          </div>
        )}
        {showNextButton && (
          <div
            className="absolute -right-5 top-[44%] flex h-[90%] w-fit -translate-y-1/2 transform items-center  transition duration-300 ease-in-out"
            onClick={goToNextSlide}
          >
            <div className="z-10 grid  h-9 w-9 place-items-center rounded-full border border-gray-100 bg-white text-gray-800 opacity-100 shadow-md hover:bg-gray-100">
              <i className="fas fa-chevron-right text-sm"></i>
            </div>
          </div>
        )}
      </div>
      <div
        className={classNames("inline-flex w-full flex-wrap justify-center gap-[9px]", dotNavClass)}
      >
        {keys.map((i, index) => (
          <span
            key={i}
            className={classNames(
              "h-2 w-2 cursor-pointer rounded-full",
              index === currentIndex ? " bg-purple-500 " : "bg-gray-200",
            )}
            onClick={() => {
              if (index > currentIndex) {
                setDirection("next");
              } else if (index < currentIndex) {
                setDirection("previous");
              }
              setCurrentIndex(index);
              onChange?.(index);
            }}
          />
        ))}
      </div>
      {renderFooter?.()}
    </>
  );
};

export default ArraySlider;
