import { IOption } from "../components/tailwind/AsyncCreatableSelector";
import { FunderMemberContact, getFunderContacts } from "../services/funders.services";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { getFunderMemberContactKey } from "../types/funder";
import useGrantSearchResult from "./search/useGrantSearchResult";
import useFunderSearchResult from "./search/useFunderSearchResult";
import useGrant from "./grant/useGrant";

interface ContactOption extends IOption {
  data: FunderMemberContact;
}
function useContacts(
  target_id?: string,
  target_type?: 0 | 1 | 2,
): { data: ContactOption[]; isLoading?: boolean } {
  const { data: funder } = useFunderSearchResult(target_type === 0 && target_id ? target_id : "");
  const { data: grant } = useGrant(target_type === 1 && target_id ? target_id : "");
  const funder_id = funder?._id || grant?.donor_id;

  const fetch = async (url: string) => {
    if (!url || !funder_id) return [];
    return getFunderContacts(funder_id).then((res) => res.contacts);
  };

  const { data, isLoading, error } = useSWR<FunderMemberContact[]>(
    funder_id ? process.env.REACT_APP_API_URL + `/api/v2/donors/${funder_id}/contact` : null,
    fetch,
  );
  const [OPTION, setOPTION] = useState<ContactOption[]>([]);

  useEffect(() => {
    let OPTION: ContactOption[] = [];
    //funder
    if (funder || grant) {
      const mailing = {
        _id: "mailing",
        firstname: "Mailing address",
        lastname: "",
        titles: ["Post"],
        email: funder ? funder.address || "" : grant ? grant.donor?.address || "" : "",
      };
      OPTION = mailing.email
        ? [
            {
              label: mailing.email,
              value: getFunderMemberContactKey(mailing),
              data: mailing,
            },
          ]
        : [];
    }
    if (data && data.length > 0) {
      OPTION = [
        ...OPTION,
        ...data
          .filter((i) => i.email)
          .map((i): ContactOption => {
            return {
              label: i.email || "",
              value: getFunderMemberContactKey(i),
              data: i,
            };
          }),
      ];
    }

    //aditional OPTION
    if (grant?.contact_email) {
      const mailing =
        grant?.contact_email && OPTION.map((o) => o.data.email).includes(grant.contact_email)
          ? undefined
          : {
              _id: "grant",
              firstname: grant.contact_name,
              lastname: "",
              titles: [grant?.contact_title || ""],
              email: grant.contact_email,
            };
      OPTION = [
        ...(mailing
          ? [
              {
                label: mailing.email || "",
                value: getFunderMemberContactKey(mailing),
                data: mailing,
              },
            ]
          : []),
        ...OPTION,
      ];
    }
    setOPTION(OPTION);
  }, [data, funder, grant]);

  return { data: OPTION, isLoading };
}
export default useContacts;
