import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";
import onboardingSlice, { fetchOnboardingAsync } from "../app/onboardingSlice";
import projectSlice from "../app/projectSlice";
import searchHistorySlice from "../app/searchHistorySlice";
import { RootState, useAppDispatch, useAppSelector } from "../app/store";
import userSlice, { selectUser, User } from "./../app/userSlice";
import useOrgID from "./useOrgID";
import useOrgMemberInner from "./useOrgMemberInner";

const useUser = (): [
  User | undefined,
  (data: User | null | undefined, org_id?: string | undefined | null) => void,
] => {
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => selectUser(state));
  const dispatch = useAppDispatch();
  const { cache } = useSWRConfig();
  const org_id = useOrgID();
  const { data: orgUser, isLoading, error, isValidating } = useOrgMemberInner(org_id, user?._id);
  const [userByOrg, setUserByOrg] = useState<User | undefined>(user);
  useEffect(() => {
    setUserByOrg(
      !orgUser
        ? user
        : user
        ? {
            ...user,
            firstname: orgUser?.firstname || user?.firstname,
            lastname: orgUser?.lastname || user?.lastname,
            idp_google_detail: orgUser?.idp_google_detail || user?.idp_google_detail,
            title: orgUser?.title || user?.title,
          }
        : undefined,
    );
  }, [user, orgUser]);

  const setUser = async (
    data: User | null | undefined,
    org_id_by_param?: string | undefined | null,
  ): Promise<void> => {
    if (data) {
      dispatch(
        userSlice.actions.logIn({
          user: data as User,
        }),
      );
      const selectedOrg = sessionStorage.getItem(`selected_org_${data?._id}`) || "";
      const nextOrg = org_id_by_param || selectedOrg || undefined;
      dispatch(fetchOnboardingAsync(nextOrg));
    } else {
      dispatch(userSlice.actions.logOff());
      dispatch(onboardingSlice.actions.clear());
      dispatch(projectSlice.actions.clear());
      dispatch(searchHistorySlice.actions.clear());
      for (const key in cache.keys()) {
        cache.delete(key);
      }
    }
  };

  return [userByOrg, setUser];
};
export default useUser;
