import classNames from "classnames";
import React, {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import PlainButton from "./PlainButton";
import Tag from "./Tag";
import StarCheckbox from "./StarCheckbox";
import Spinner from "./Spinner";
import { IOption } from "./tailwind/AsyncCreatableSelector";
import ToggleInput from "./tailwind/ToggleInput";
import RadioGroup from "./radio/RadioGroup";
import { Divider } from "rsuite";
import MustHaveSelector from "./MustHaveSelector";
const PRIORITY_OPTION: IOption[] = [
  { label: "Must have", value: "MUST_HAVE" },
  { label: "Good to have", value: "GOOD_TO_HAVE" },
];
interface TagSelectorProps<T> {
  onCheck?: (t: T, check: boolean) => void;
  globalOpen?: boolean;
  className?: string;
  name: string;
  id: string;
  color?: "purple" | "red" | "yellow" | "green" | "blue" | "gray" | "orange";
  size?: "lg" | "md" | "sm";
  outline?: "none" | "gray" | "color";
  selected?: boolean; // in a form, user can select
  focused?: boolean; // focused in treeview
  matched?: boolean; // matched taxonomy in matching summary veiw, detail view or etc
  disabled?: boolean;
  loading?: boolean;
  value: T[];
  setValue: (t: T[]) => void;
  subs: T[];
  setSubs: (t: T[]) => void;
  setValueAndSubs: (t: T[], t2: T[]) => void;
  allowSelectAll?: boolean;
  getColor?: (item: T) => "purple" | "red" | "yellow" | "green" | "blue" | "gray";
  getKey: (item: T) => string;
  getLabel: (item: T) => string;
  noTag?: boolean;
  removable?: boolean;
  isStar?: (item: T) => boolean;
  flipStar?: (item: T) => void;
  setStar?: (item: T) => void;
  flipAllStar?: (items: T[]) => void;
  isAllStar?: (items: T[]) => boolean;
  clearStar?: (item: T) => void;
  clearAllStar?: () => void;
  isExcluded?: (item: T) => boolean;
  setExcluded?: (item: T) => void;
  clearExcluded?: (item: T) => void;
  indentOnClose?: boolean;
  starClass?: string;
  hideExpand?: boolean;
  hideCount?: boolean;
  isPrioritySelector?: boolean;
  isPriorityToggle?: boolean;
  isStarToggle?: boolean;
  hideCheckbox?: boolean;
  seeAll?: boolean;
  noIndent?: boolean;
  check?: boolean;
  setCheck?: (v: boolean) => void;
  twoLineCut?: number;
  hideArrow?: boolean;
  renderInfo?: () => ReactNode;
}
type TagSelectorPropsWithChildren<T> = PropsWithChildren<TagSelectorProps<T>>;
const maxShow = 3;
type TagSelectorComponent<T> = React.FunctionComponent<TagSelectorPropsWithChildren<T>>;
const TagSelector = <T extends object | string>({
  onCheck,
  className,
  id,
  outline,
  disabled,
  loading,
  value,
  setValue,
  subs,
  setSubs,
  setValueAndSubs,
  getKey,
  getLabel,
  children,
  setStar,
  flipStar,
  isStar,
  flipAllStar,
  clearAllStar,
  isAllStar,
  clearStar,
  isExcluded,
  setExcluded,
  clearExcluded,
  starClass,
  hideExpand = false,
  hideCount = false,
  hideCheckbox = false,
  isPrioritySelector = false,
  isPriorityToggle = false,
  isStarToggle = false,
  noIndent = false,
  check,
  setCheck,
  twoLineCut = 37,
  hideArrow = false,
  renderInfo,
  ...props
}: TagSelectorPropsWithChildren<T>) => {
  const [open, setOpen] = useState<boolean>(props.globalOpen || false);
  const [prevLen, setPrevLen] = useState<number>(0);
  const [tempChecked, setTempChecked] = useState<boolean>(true);
  const [seeAll, setSeeAll] = useState<boolean>(false);
  const [priority, setPriority] = useState<string>("GOOD_TO_HAVE");
  const [existKeys, setExistKeys] = useState<string[]>([]);
  const [existSubKeys, setExistSubKeys] = useState<string[]>([]);
  const [realSubs, setRealSubs] = useState<T[]>([]);
  useEffect(() => {
    if (!seeAll && !props.seeAll) {
      setSubs([...value, ...realSubs.filter((i) => !existKeys.includes(getKey(i)))]);
    }
  }, [seeAll]);
  useEffect(() => {
    setOpen(props.globalOpen || false);
  }, [props.globalOpen]);
  const hasValue = value.length > 0;
  useEffect(() => {
    if (value.length === 0 && prevLen !== value.length) {
      setPrevLen(value.length);
      setTempChecked(false);
    }
  }, [value]);
  useEffect(() => {
    const _existKeys = value.map((i) => getKey(i));
    setExistKeys(_existKeys);
  }, [value, setExistKeys]);
  // useEffect(() => {
  //   const _existSubKeys = realSubs.map((i) => getKey(i));
  //   setExistSubKeys(_existSubKeys);
  // }, [realSubs, setExistSubKeys]);
  useEffect(() => {
    const subsKey = subs.map((i) => getKey(i));
    const r = [...subs, ...value.filter((v) => !subsKey.includes(getKey(v)))];
    const _existSubKeys = r.map((i) => getKey(i));
    setRealSubs(r);
    setExistSubKeys(_existSubKeys);
  }, [subs, value]);
  return (
    <div
      className={classNames(
        "flex flex-col pt-4",
        { "ml-6": !noIndent && !open && props.indentOnClose },
        className,
      )}
    >
      <div
        className={classNames(
          "flex w-full items-center justify-between gap-3",
          props.name ? "mb-4" : "mb-0",
        )}
      >
        {props.name && (
          <div className="inline-flex w-full items-center gap-2">
            <div
              key={`${id}0`}
              className={classNames(
                "group flex cursor-pointer items-center gap-3",
                hideCheckbox ? "hidden" : "block",
              )}
            >
              <input
                className="giboo-checkbox form-check-input cursor-pointer group-hover:shadow-md"
                type="checkbox"
                id={`input-${id}0`}
                checked={
                  setCheck
                    ? check
                    : existSubKeys.length === 0
                    ? tempChecked
                    : existKeys.filter((i) => existSubKeys.includes(i)).length === realSubs.length
                }
                onClick={() => {
                  if (hideCheckbox) return;
                  if (setCheck) setCheck(!check);
                  else {
                    if (existSubKeys.length === 0) setTempChecked((prev) => !prev);
                    if (
                      value.length === realSubs.length &&
                      existKeys.filter((i) => existSubKeys.includes(i)).length === realSubs.length
                    ) {
                      setValue(
                        value.length === realSubs.length &&
                          existKeys.filter((i) => existSubKeys.includes(i)).length ===
                            realSubs.length
                          ? []
                          : realSubs,
                      );
                      clearAllStar?.();
                    } else setValue(realSubs);
                  }
                }}
                readOnly
              />
            </div>
            <label
              htmlFor={`input-${id}0`}
              className={classNames(hideCheckbox ? "" : "cursor-pointer")}
            >
              <div className="inline-flex items-center gap-2">
                <div className="inline-flex gap-2">
                  <h5 className="font-semibold">{props.name}</h5>
                  {loading ? (
                    <Spinner color="purple" size="sm" />
                  ) : (
                    <span
                      className={classNames("text-sm font-normal", hideCount && "hidden")}
                    >{` (${value.length})`}</span>
                  )}
                </div>
                {renderInfo && renderInfo()}
              </div>
            </label>
          </div>
        )}
        <span
          className={classNames("inline-flex items-center gap-2", hideExpand ? "hidden" : "block")}
        >
          {!hideArrow && (
            <PlainButton
              className="!p-0 !text-gray-700"
              id={`${id}-open`}
              label=""
              leftIconClass={open ? "gi-md gi-angle-up" : "gi-md gi-angle-down"}
              handleOnClick={() => {
                setOpen((prev) => !prev);
              }}
            />
          )}
          {!isPrioritySelector && !isPriorityToggle && realSubs.length > 0 && isStarToggle && (
            <StarCheckbox
              id={`${id}-open`}
              checked={isAllStar?.(realSubs) || false}
              onClick={() => {
                if (!isAllStar?.(realSubs)) setValue(realSubs);
                flipAllStar?.(realSubs);
              }}
            />
          )}
        </span>
      </div>
      {open && (
        <>
          {children && (
            <div className={classNames("w-full", realSubs.length > 0 ? "mb-4" : "")}>
              {children}
            </div>
          )}
          <div
            className={classNames(
              "flex flex-col items-start",
              props.noTag ? "gap-2" : "gap-3",
              noIndent ? "" : "ml-6",
            )}
          >
            {/* {props.allowSelectAll && realSubs.length > 0 && (
              <div key={`${id}0`} className="group flex cursor-pointer items-center gap-3">
                <input
                  className="giboo-checkbox form-check-input cursor-pointer group-hover:shadow-md"
                  type="checkbox"
                  id={`input-${id}0`}
                  checked={value.length === realSubs.length}
                  onClick={() => setValue(value.length === realSubs.length ? [] : realSubs)}
                  readOnly
                />
                <label htmlFor={`input-${id}0`} className="cursor-pointer">
                  <h5 className="text-gray-800">Select all</h5>
                </label>
              </div>
            )} */}
            {(seeAll || props.seeAll ? realSubs : realSubs.slice(0, maxShow)).map((item, index) => {
              const label = getLabel(item);
              const key = getKey(item);
              const inputItemId = `input-${id}-${key}`;
              const twoLines = label.length > twoLineCut ? true : false;
              return (
                <div key={key} className="group flex w-full cursor-pointer items-center gap-2">
                  <input
                    className="giboo-checkbox form-check-input flex-shrink-0 cursor-pointer disabled:!bg-gray-200 group-hover:shadow-md"
                    type="checkbox"
                    id={inputItemId}
                    checked={existKeys.includes(key)}
                    onClick={() => {
                      onCheck?.(item, !existKeys.includes(key));
                      if (existKeys.includes(key)) clearStar?.(item);
                      setValue(
                        existKeys.includes(key)
                          ? value.filter((i) => getKey(i) !== key)
                          : [...value.filter((i) => getKey(i) !== key), item],
                      );
                    }}
                    disabled={disabled}
                    readOnly
                  />
                  <div
                    className={classNames(
                      "grid w-full gap-2",
                      !isPrioritySelector && !disabled && props.removable
                        ? "grid-cols-[auto_16px]"
                        : "",
                    )}
                  >
                    <div
                      className={classNames(
                        "flex grow",
                        twoLines ? "flex-col gap-2" : "justify-between gap-1",
                      )}
                      style={{ width: "calc(100%)" }}
                    >
                      <label
                        htmlFor={inputItemId}
                        title={label}
                        className={classNames(
                          "flex max-w-[480px] flex-shrink-0 cursor-pointer items-center justify-between overflow-ellipsis",
                          {
                            "w-full": props.noTag,
                          },
                        )}
                      >
                        {props.noTag ? (
                          <>
                            <pre
                              id={`keyword-${key}`}
                              className="truncate !whitespace-normal py-[2px] font-poppins text-sm"
                            >
                              {label}
                            </pre>
                          </>
                        ) : (
                          <Tag
                            className="group-hover:shadow-md"
                            id={key}
                            name={label}
                            color={props.getColor ? props.getColor(item) : props.color}
                            matched={props.matched}
                            focused={props.focused}
                            selected={props.selected}
                            size={props.size}
                            textTruncate
                          />
                        )}
                      </label>
                      {!existKeys.includes(key) &&
                      !isPrioritySelector ? null : isPrioritySelector && !disabled ? (
                        <div
                          className={classNames(
                            "flex items-center whitespace-nowrap",
                            twoLines ? "self-end" : "",
                          )}
                        >
                          {existKeys.includes(key) && (
                            <>
                              <Divider vertical className="h-[14px] bg-gray-300" />
                              <MustHaveSelector
                                id={key}
                                label={label}
                                value={
                                  isStar?.(item) ? "must" : isExcluded?.(item) ? "exclude" : "good"
                                }
                                onChange={(v) => {
                                  if (v === "must") setStar?.(item);
                                  else if (v === "exclude") setExcluded?.(item);
                                  else clearStar?.(item);
                                }}
                              />
                            </>
                          )}
                          <PlainButton
                            className="min-w-5 ml-2 mt-1 w-fit !items-start !p-0 !text-sm"
                            id="btn-delete"
                            handleOnClick={() => {
                              onCheck?.(item, false);
                              setValueAndSubs(
                                realSubs.filter((i) => getKey(i) !== key),
                                value.filter((i) => getKey(i) !== key),
                              );
                            }}
                            leftIconClass={<i className="gi-sm gi-x min-w-[16px]" />}
                          />
                        </div>
                      ) : isPriorityToggle ? (
                        <div className="inline-flex items-center">
                          <Divider vertical className=" h-[14px] bg-gray-300" />
                          <ToggleInput
                            id={inputItemId}
                            isChecked={isStar?.(item) ? true : false}
                            itemClassName="truncate text-ellipsis ml-2"
                            labelClass="truncate text-ellipsis"
                            label="Must have"
                            onChange={() => {
                              if (!isStar?.(item))
                                setValue([...value.filter((i) => getKey(i) !== key), item]);
                              flipStar?.(item);
                            }}
                          />
                        </div>
                      ) : isStarToggle ? (
                        <div className="min-w-5">
                          <StarCheckbox
                            id={inputItemId}
                            labelClass={index === 0 ? starClass : ""}
                            checked={isStar?.(item) || false}
                            onClick={() => {
                              if (!isStar?.(item))
                                setValue([...value.filter((i) => getKey(i) !== key), item]);
                              flipStar?.(item);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                    {!isPrioritySelector && props.removable ? (
                      <PlainButton
                        className="min-w-5 mt-1 w-fit !items-start !p-0 !text-sm"
                        id="btn-delete"
                        handleOnClick={() => {
                          onCheck?.(item, false);
                          setValueAndSubs(
                            realSubs.filter((i) => getKey(i) !== key),
                            value.filter((i) => getKey(i) !== key),
                          );
                        }}
                        leftIconClass={<i className="gi-sm gi-x min-w-[16px]" />}
                      />
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
          {!props.seeAll && (
            <div className="mt-2 flex w-full justify-start">
              {realSubs.length > maxShow && (
                <PlainButton
                  className="ms-4 !p-0 !text-sm"
                  id={`${id}-see-all`}
                  label={seeAll ? "Show less" : "Show all"}
                  handleOnClick={() => {
                    setSeeAll((prev) => !prev);
                  }}
                />
              )}
            </div>
          )}
          <div className="pb-1"></div>
        </>
      )}
    </div>
  );
};
TagSelector.defaultProps = {
  onClick: undefined,
  onAdd: undefined,
  onDelete: undefined,
  color: "gray",
  size: "md",
  outline: "color",
  selected: false,
  focused: false,
  matched: false,
  disabled: false,
};
export default TagSelector;
