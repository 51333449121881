import { toValidNumberOrSign } from "../utils/formatHelper";
import LeftInnerIconInput from "./LeftInnerIconInput";
import "./css/input.scss";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

interface LeftInnerIconNumberInputProps {
  id: string;
  className?: string;
  iconClass?: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green";
  readonly?: boolean;
  placeholder?: string;
  value: number | undefined;
  setValue: (v: number | undefined) => void;
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  min?: number | undefined;
  max?: number | undefined;
  topPlaceholder?: string;
  defaultValue?: string;
  status?: "success" | "fail";
  acceptFloat?: boolean;
  style?: React.CSSProperties;
}

const LeftInnerIconNumberInput = React.forwardRef(function leftInnerIconNumberInput(
  props: LeftInnerIconNumberInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const [stringValue, setStringValue] = useState<string>("");
  const getValidNum = useCallback(
    (v: number): number => {
      return props.min !== undefined && props.min !== null && v < props.min
        ? props.min
        : props.max !== undefined && props.max !== null && v > props.max
        ? props.max
        : v;
    },
    [props.min, props.max],
  );
  useEffect(() => {
    if (props.value !== undefined && props.value !== null)
      setStringValue(props.value.toLocaleString());
  }, [props.value]);
  return (
    <LeftInnerIconInput
      {...props}
      type="text"
      ref={ref}
      value={stringValue}
      className={props.className}
      handleOnChange={(e) => {
        const validString = toValidNumberOrSign(e.currentTarget.value, props.acceptFloat);
        const lastVal = validString.length > 0 ? validString.slice(-1) : "";
        if (validString === "" || validString === "-") {
          setStringValue(
            props.min === undefined || props.min === null || props.min < 0 ? validString : "",
          );
          props.setValue(undefined);
          return;
        }
        let validNum = 0;
        try {
          if (lastVal === "." || lastVal === "-") validNum = +validString.slice(0, -1);
          else validNum = +validString;
          validNum = getValidNum(validNum);
          setStringValue(
            validNum.toLocaleString() + (lastVal === "." || lastVal === "-" ? lastVal : ""),
          );
          props.setValue(validNum);
        } catch (e) {
          setStringValue("");
          props.setValue(undefined);
        }
        props.handleOnChange?.(e);
      }}
    />
  );
});

export default LeftInnerIconNumberInput;
