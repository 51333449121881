import { Dialog } from "@headlessui/react";
import PlainButton from "./PlainButton";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import useUser from "../hooks/useUser";
import classNames from "classnames";
import Button from "./tailwind/Button";
import TextInput from "./tailwind/TextInput";
import useOnboardingData from "../hooks/useOnboarding";
import useOrgs from "../hooks/useOrgs";
import { IProjectDataWithId } from "../pages/project-creation/ProjectCreationNew";
import Checkbox from "./checkbox/Checkbox";
import GibooDatePicker from "./tailwind/GibooDatePicker";
import GibooLocationInput from "./GibooLocationInput";
import { ILocation, reprLocation } from "../types/location";
import LeftInnerIconNumberInput from "./LeftInnerIconNumberInput";
import RadioGroup from "./radio/RadioGroup";
import { IProject, IProjectCreateRequest } from "../types/project";
import useProject, { createProject, updateProject } from "../hooks/project/useProject";
import { appendProjectAsync, updateProjectAsync } from "../app/projectSlice";
import { useAppDispatch } from "../app/store";
import { useNavigate } from "react-router-dom";
import useLocationRecommendation from "../hooks/useLocationRecommendation";
import { scrollBarClass } from "../utils/classes";
import Tag from "./Tag";
import Spinner from "./Spinner";
import { Divider } from "rsuite";
import CloseButton from "./CloseButton";
import { useSWRConfig } from "swr";
import useHowGibooWorks from "../hooks/useHowGibooWorks";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";

interface ProjectCreationModalProps {
  id?: string;
  title?: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onProjectCreation?: (data?: IProject) => void;
  disableDefaultNavigate?: boolean;
  isEdit?: boolean;
  data?: IProject;
  onClose?: () => void;
}
const PROJECT_TAB = [
  "Project name",
  "Project timeline",
  "Project location",
  "Target amount",
  "Project access (invite)",
];
function ProjectCreationModal({
  id,
  title,
  open,
  setOpen,
  onProjectCreation,
  disableDefaultNavigate = false,
  isEdit,
  data,
  onClose,
}: ProjectCreationModalProps) {
  const [user] = useUser();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const tabsLength = PROJECT_TAB.length;
  const [onboardingData] = useOnboardingData();
  const { selected } = useOrgs();
  const { mutate } = useSWRConfig();
  const projectPlaceHolder = `${onboardingData.npo_name} Project`;
  const [projectData, setProjectData] = useState<IProjectDataWithId>({
    _id: "",
    org_id: selected,
    name: "",
    fund_to_raise: 0,
    beneficiary: [],
    focus_area: [],
    service_loc: [],
    program: [],
  });
  const dispatch = useAppDispatch();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isInvalidData, setIsInvalidData] = useState(false);
  const [tempServiceLocation, setTempServiceLocation] = useState<ILocation[]>(
    projectData.service_loc || [],
  );
  const [access, setAccess] = useState<"edit" | "read" | "private" | undefined>("edit");
  const { pageVisit, updatePageVisit } = useHowGibooWorks();

  //location
  const { recommended, addCenter, isLoading } = useLocationRecommendation(
    tempServiceLocation,
    onboardingData.service_loc,
  );
  useEffect(() => {
    if (data && isEdit) {
      setProjectData(data);
      setAccess(data.permission === 2 ? "edit" : data.permission === 1 ? "read" : "private");
    }
  }, [data, isEdit]);
  useEffect(() => {
    if (tempServiceLocation) {
      setProjectData((prev) => ({
        ...prev,
        service_loc: tempServiceLocation,
      }));
    }
  }, [tempServiceLocation]);
  const renderLeft = () => {
    return (
      <div className=" flex h-full w-full flex-col gap-4 rounded-l !bg-gray-200 px-5 pt-[68px]">
        {PROJECT_TAB.map((item, i) => {
          return (
            <h5
              key={i}
              className={classNames(
                "flex cursor-pointer gap-1 px-1 font-poppins text-sm",
                selectedIndex === i
                  ? "font-semibold text-purple-500"
                  : "font-normal text-gray-700  hover:text-gray-800",
              )}
              onClick={() => {
                if (projectData.name) {
                  setSelectedIndex(i);
                } else {
                  validate();
                }
              }}
            >
              <span>{i + 1}.</span>
              <span>{item}</span>
            </h5>
          );
        })}
      </div>
    );
  };
  const renderSuggestedLocationTags = () => {
    return (
      <>
        <div className="mt-5 h-full max-h-[145px] w-full max-w-[585px] rounded border border-gray-300 bg-white p-4">
          <div className="self-start">
            <h5 className="font-semibold">{`Suggested project location ${
              recommended && !isLoading && recommended.length > 0 ? `(${recommended.length})` : ""
            }`}</h5>
            <div
              className={classNames("mt-3 flex h-fit max-h-[88px] flex-wrap gap-2", scrollBarClass)}
            >
              {recommended.length > 0
                ? recommended.map((l) => (
                    <Tag
                      id={`btn-location-${reprLocation(l)}`}
                      key={reprLocation(l)}
                      name={reprLocation(l)}
                      color="orange"
                      matched
                      onClick={() => {
                        setTempServiceLocation((prev) =>
                          prev.map((loc) => reprLocation(loc)).includes(reprLocation(l))
                            ? prev
                            : [...prev, l],
                        );
                        addCenter(l);
                      }}
                      onAdd={() => {
                        //
                      }}
                      size="sm"
                      hover
                    />
                  ))
                : !isLoading && <h6 className="font-poppins text-sm text-gray-700">None</h6>}
              {isLoading && <Spinner size="sm" wrapperClass="h-fit" />}
            </div>
          </div>
        </div>
      </>
    );
  };
  const selectedContent = () => {
    if (selectedIndex === 0) {
      return (
        <div className="w-full pr-5 font-poppins">
          <h6 className="pb-2 text-base font-semibold text-gray-900">
            First, let’s name your project.* <span className="text-xs font-normal">(required)</span>
          </h6>
          <h6 className="max-w-[480px] text-xs font-normal text-gray-700">
            If you intend to make this project publicly accessible, we recommend giving it a clear
            and intuitive title that effectively conveys its primary objective.
          </h6>
          <div className="mt-4 min-h-[47px] max-w-[480px]">
            <TextInput
              id="ein"
              wrapperClass={classNames("md:w-full")}
              className="h-[48px]"
              value={projectData.name || ""}
              handleOnKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (projectData.name === "") return;
                }
              }}
              handleOnBlur={(e) => {
                if (projectData.name === "") return;
              }}
              placeholder={projectPlaceHolder || "Enter Project Name"}
              invalid={isInvalidData && !projectData.name ? true : false}
              handleOnChange={(e) => {
                setProjectData((prev) => ({ ...prev, name: e.target.value }));
              }}
            />
            {isInvalidData && renderErrorMessage(!projectData.name ? true : false)}
          </div>
        </div>
      );
    } else if (selectedIndex === 1) {
      const TIMELINE_OPTION: { value: string; label: string }[] = [
        { label: "Not specific", value: "NOT_SPECIFIC" },
        { label: "Ongoing", value: "ROLLING" },
        { label: "Specific date", value: "SPECIFIC_DATE" },
      ];
      return (
        <div className="max-w-[454px]" id="timeline">
          <h6 className="pb-2 text-sm font-semibold text-gray-900">
            What are the anticipated start and end dates of the project?*
            <span className="text-xs font-normal text-gray-700">(optional)</span>
          </h6>
          <div className="flex flex-col gap-4">
            <RadioGroup<string>
              id="timeline-option"
              className="flex w-[150px] flex-col items-start !gap-4"
              itemClassName="!text-gray-900"
              data={TIMELINE_OPTION}
              value={
                projectData.project_timeline === 2
                  ? "SPECIFIC_DATE"
                  : projectData.project_timeline === 1
                  ? "ROLLING"
                  : "NOT_SPECIFIC"
              }
              setValue={(v) => {
                if (v) {
                  if (v !== "SPECIFIC_DATE") {
                    setProjectData((prev) => ({
                      ...prev,
                      project_timeline: v === "ROLLING" ? 1 : 0,
                      project_end: undefined,
                      project_start: undefined,
                    }));
                  } else {
                    setProjectData((prev) => ({
                      ...prev,
                      project_timeline: 2,
                    }));
                  }
                }
              }}
            />
            {projectData.project_timeline === 2 && (
              <div className=" sflex-col flex  space-y-5 md:flex-row md:items-center md:space-y-0">
                {renderProjectStart()}
                <span className="px-4 font-poppins text-sm text-gray-500">to</span>
                {renderProjectEnd()}
              </div>
            )}
          </div>
        </div>
      );
    } else if (selectedIndex === 2) {
      return (
        <div className="w-full font-poppins" id="service-loc">
          <h6 className="pb-4 text-base font-semibold text-gray-900">
            Where will this project mainly take place?{" "}
            <span className="text-xs font-normal">(optional)</span>
          </h6>
          <h6 className="max-w-[480px] text-xs font-normal text-gray-700">
            This location should be where the beneficiaries of this project mostly reside.
          </h6>
          <GibooLocationInput
            id="location"
            value={projectData.service_loc || []}
            setValue={(value) => {
              setTempServiceLocation(value);
            }}
            onAdd={(loc) => addCenter(loc)}
            maxWidth="900px"
            wrapperClass="!items-start mt-4"
            inputBoxMaxWidth="384px"
            itemsMaxHeight="480px"
            itemsPosition="top"
            itemsAlign="start"
            float
            inputClassName="!h-[48px] mb-1"
            gap={2}
            hideNone
            placeholder={"Choose location"}
            icon
            spinnerBackgroundColor="bg-gray-50"
            matched
            isFullTagClick
            tagSize="sm"
          />
          <h6 className="my-4 max-w-[520px] text-xs font-normal text-gray-700">
            Enter a location and select the provided option to add it.
            <br />
            You can also select from the “Suggested project location” box below.
          </h6>
          <div className="min-h-[100px] max-w-[550px]">{renderSuggestedLocationTags()}</div>
        </div>
      );
    } else if (selectedIndex === 3) {
      return (
        <div className="mb-[40px] w-full font-poppins" id="target-amount">
          <h6 className="pb-4 text-base font-semibold text-gray-900">
            What is the overall funding target amount you aim <br /> to raise for this project?*{" "}
            <span className="text-xs font-normal">(required)</span>
          </h6>
          <div className="my-2 flex flex-col gap-3">
            <div className="w-[400px]">
              <LeftInnerIconNumberInput
                id="input-number-1"
                className="!h-[45px]"
                value={projectData.fund_to_raise || 0}
                setValue={(value) => {
                  setProjectData((prev) => ({ ...prev, fund_to_raise: Number(value) }));
                }}
                iconClass="fa fa-dollar -mt-[1px] text-xs text-gray-500"
                placeholder="Enter target amount"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col">
          <h6 className="pb-4 text-base font-semibold text-gray-900">
            Choose a project access option* <span className="text-xs font-normal">(required)</span>
          </h6>
          <RadioGroup<"edit" | "read" | "private">
            id="input-access"
            data={[
              { value: "edit", label: `Everyone at ${onboardingData.npo_name} can edit` },
              { value: "read", label: `Everyone at ${onboardingData.npo_name} can view` },
              { value: "private", label: `Invite-only: choose who has access` },
            ]}
            flexColumn
            mandatory
            value={access}
            setValue={setAccess}
          />
          <h6 className="ml-5 mt-1 max-w-[480px] text-xs font-normal text-gray-700">
            Only people who are invited can access this project
          </h6>
        </div>
      );
    }
  };
  const renderProjectStart = () => {
    return (
      <GibooDatePicker
        id="date-picker-start"
        type="start"
        className="h-[44px]"
        value={projectData.project_start || undefined}
        maxDate={projectData.project_end}
        hasStartEnd
        handleDateSelection={(date) => {
          setProjectData((prev) => ({
            ...prev,
            project_timeline: 2,
            project_start: date ? convertToUTCDate(new Date(date))?.toISOString() : undefined,
          }));
        }}
      />
    );
  };
  const renderProjectEnd = () => {
    return (
      <GibooDatePicker
        id="date-picker-end"
        type="end"
        className="h-[44px]"
        hasStartEnd
        value={projectData.project_end || undefined}
        minDate={projectData.project_start}
        handleDateSelection={(date) => {
          setProjectData((prev) => ({
            ...prev,
            project_timeline: 2,
            project_end: date ? convertToUTCDate(new Date(date))?.toISOString() : undefined,
          }));
        }}
      />
    );
  };
  const convertToUTCDate = (date: Date | undefined): Date | undefined => {
    if (!date) return undefined;
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  };

  const renderErrorMessage = (show: boolean) => {
    if (show) {
      return (
        <>
          <span className="mt-2 inline-flex font-poppins text-xs font-semibold text-purple-500">
            Project name required.
          </span>
        </>
      );
    }
  };
  const validate = () => {
    const isInvalid = projectData.name === "";
    setIsInvalidData(isInvalid);
  };
  const handleNext = () => {
    if (projectData.name) {
      if (selectedIndex < tabsLength - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
    } else {
      validate();
    }
  };

  const handleBack = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };
  const handleSkip = () => {
    if (selectedIndex === 1) {
      setProjectData((prev) => ({ ...prev, project_timeline: 0 }));
    }
    if (selectedIndex === 3) {
      setProjectData((prev) => ({ ...prev, fund_to_raise: 0 }));
    }
    if (selectedIndex === 2) {
      setProjectData((prev) => ({ ...prev, service_loc: [] }));
      setTempServiceLocation([]);
    }
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const handleUpdateProject = () => {
    if (projectData?._id) {
      const data = {
        ...projectData,
        completed: false,
        fund_to_raise: projectData.fund_to_raise,
        rolling: projectData.rolling,
        project_start: projectData.project_start,
        project_end: projectData.project_end,
        permission: access === "edit" ? 2 : access === "read" ? 1 : 0,
      };
      setIsCreating(true);

      Promise.resolve(dispatch(updateProjectAsync(data, projectData._id)))
        .then((res) => {
          mutate(process.env.REACT_APP_API_URL + `/api/v2/projects/${projectData._id}`)
            .then((res) => {
              onProjectCreation?.(res);
              setOpen(false);
            })
            .finally(() => {
              setIsCreating(false);
            });
        })
        .catch((err) => {
          // console.log("failed to update project");
        });
    }
  };
  const handleCreateProject = () => {
    if (!user?._id) return;
    const npo_id = onboardingData.npo_id;
    const data = {
      ...projectData,
      name: projectData.name || "",
      org_id: onboardingData._id,
      completed: false,
      permission: access === "edit" ? 2 : access === "read" ? 1 : 0,
    };
    setIsCreating(true);
    createProject(data)
      .then((res: IProject) => {
        // console.log("project created successfully", res);
        Promise.resolve(dispatch(appendProjectAsync(res))).then(() => {
          res && onProjectCreation?.(res);
          // mxEvent(MIXPANEL_EVENTS.PROJECT_CREATION.PROJECT_CREATED, {
          //   name: res.name,
          //   organizationId: onboardingData._id,
          //   location: location.pathname,
          //   projectId: res._id,
          // });
          setOpen(false);
          if (id === "how-giboo-works") {
            updatePageVisit({ ...pageVisit, projectVisited: true });
          }
          if (!disableDefaultNavigate)
            navigate(`/project/${res._id}/`, {
              replace: true,
              state: {
                showInviteModal: access === "private",
              },
            });
        });
      })
      .catch((err) => {
        // console.log("failed to create project");
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const renderRight = () => {
    return (
      <div className="flex h-full w-full flex-col">
        <div className="flex justify-end p-6">
          <PlainButton
            id={`btn-update-image-close`}
            className="self-end"
            handleOnClick={() => {
              onClose?.();
              setOpen(false);
            }}
            leftIconClass={<CloseButton />}
          />
        </div>
        <div className=" flex h-full px-[70px]">{selectedContent()}</div>
        <div className="m-7 flex h-[38px] justify-end gap-3">
          {(selectedIndex === 1 || selectedIndex === 2) && (
            <span
              className="grid !h-[37px] cursor-pointer place-items-center px-2"
              onClick={handleSkip}
            >
              <h5 className="text-sm text-purple-500 hover:text-purple-600">Skip</h5>
            </span>
          )}
          {selectedIndex !== 0 && (
            <Button
              className="!h-[37px]"
              id="btn-back"
              handleOnClick={handleBack}
              label="Back"
              outline
              prependIcon={<i className="gi-left-arrow gi" />}
            />
          )}
          {selectedIndex === tabsLength - 1 ? (
            <Button
              className="!h-[37px]"
              id="btn-finish"
              handleOnClick={() => (isEdit ? handleUpdateProject() : handleCreateProject())}
              label={isEdit ? "Update" : "Create project"}
              loading={isCreating}
            />
          ) : (
            <Button
              className="!h-[37px]"
              id="btn-next"
              handleOnClick={handleNext}
              disabled={projectData.name === ""}
              label="Next"
              appendIcon={
                <div className="-mt-1 !rotate-180">
                  <i className="gi-left-arrow" />
                </div>
              }
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <Dialog open={open} onClose={() => null} className="relative z-[9999]">
      <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto min-h-[571px]  !w-[959px]">
          <div className="grid min-h-[571px] grid-cols-[231px__1fr] rounded bg-white">
            {renderLeft()}
            {renderRight()}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
export default ProjectCreationModal;
