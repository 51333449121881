import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import ExpandableCard from "../../../components/tailwind/ExpandableCard";
import PopupModal from "../../../components/tailwind/PopupModal";
import OrganizationMembersList from "./component/OrganizationMembersList";
import AddNewEmployee from "./component/AddNewEmployee";
import Button from "../../../components/tailwind/Button";
import { useLocation, useSearchParams } from "react-router-dom";
import useOrgs from "../../../hooks/useOrgs";
import useTotalOrganizationMembersData, {
  getTotalOrganizationMembersId,
} from "../../../hooks/useTotalOrganizationMembersData";
import PublicPrivateButton from "../../../components/PublicPrivateButton";
import useOrgProfile from "../../../hooks/useOrgProfile";
import useNPOMembers from "../../../hooks/useNPOMembers";
import GrantContact from "../grant/GrantContact";
import FoldableBox from "../../../components/FoldableBox";
import classNames from "classnames";
import { ITotalOrganizationMember } from "../../../types/org";
import ProfileCard from "./component/ProfileCard";
import ProfileCard2 from "./component/ProfileCard2";
import useScrollTo from "../../../hooks/useScrollTo";
import GibooToast from "../../../components/GibooToast";
import AddNewMember from "./component/AddNewMember";
import { TooltipWrapper } from "../../../components/TooltipWrapper";
import { scrollBarClass } from "../../../utils/classes";
import { CommunicationSelector } from "./CommunicationSelector";
import { zIndexAddPerson, zIndexEditPeople, zIndexEditPerson } from "../../../types/zIndex";
import { Information, MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import { SuggestEditButton } from "../../../components/SuggestEditButton";
import ReviewAndEditCard from "../../../components/ReviewAndEditCard";
import ProfileCard2Old from "./component/ProfileCard2Old";
import GrantContactOld from "../grant/GrantContactOld";

interface IEmployees {
  org_id?: string;
  npo_id?: string;
  allowEdit: boolean;
  additionalComponent?: ReactNode;
  compact?: boolean;
  withoutLogin?: boolean;
  isProfileClaim?: boolean;
  forSearch?: boolean;
  isPrivateView?: boolean;
  hasWritePermission?: boolean;
}
export default function OrganizationContact({
  org_id,
  npo_id,
  allowEdit,
  additionalComponent,
  compact,
  withoutLogin,
  isProfileClaim,
  forSearch,
  isPrivateView,
  hasWritePermission,
}: IEmployees) {
  const mxEvent = useGibooMixpanel();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const { data: peoples } = useTotalOrganizationMembersData(org_id);
  const { data: peopleNPO, isLoading: isLoadingPeopleNPO } = useNPOMembers(npo_id);
  const { data: profile, update: profileUpdate, revalidate } = useOrgProfile(org_id);
  const [addNew, setAddNew] = useState(false);
  const profileCardEmpty = () => {
    return (
      <div className="my-5 flex h-[112px] w-full max-w-sm items-center rounded-[4px] border-[1px] border-gray-300 px-4 py-2">
        <div className="flex w-full content-center items-center">
          <div className="mr-2  flex h-[55px] w-[55px] items-center justify-center rounded-full border-[5px] border-solid border-gray-200">
            <p className="p-1  font-medium">A</p>
          </div>
          <p className="px-4 font-poppins text-lg font-medium md:text-xl">Add people</p>
        </div>
        <div
          className="cursor-pointer p-2"
          onClick={() => {
            setAddNew(true);
          }}
        >
          <i className="fa fa-plus text-gray-500 " />
        </div>
      </div>
    );
  };

  return (
    <>
      {showEdit && (
        <EditContactModal
          open={showEdit}
          setOpen={setShowEdit}
          org_id={org_id}
          npo_id={npo_id}
          withoutLogin={withoutLogin}
          onClose={() => {
            if (isPrivateView && hasWritePermission && !profile.reviewed_contacts) {
              profileUpdate({ reviewed_contacts: true });
            }
          }}
        />
      )}

      <ExpandableCard
        id="People"
        allowExpand={false}
        title="Contact"
        editClass="tutor-org-3"
        noBorder
        noPadding
        customClass={compact ? "!border-x-0 !rounded-none" : ""}
        childClass="pt-5 !pb-0"
        allowEdit={allowEdit}
        onEdit={() => {
          //mixpanel
          mxEvent(MIXPANEL_EVENTS_V2.org_profile.information.edit, {
            field: Information.CONTACT,
          });
          setShowEdit(!showEdit);
        }}
        {...(withoutLogin && isProfileClaim && { editContainer: <SuggestEditButton /> })}
      >
        <>
          {isPrivateView && hasWritePermission && !profile.reviewed_contacts && (
            <ReviewAndEditCard
              content={
                <>
                  Giboo has pre-loaded your organization&apos;s contact information. <br />
                  Please confirm to ensure your public profile accurately represents your
                  organization.
                </>
              }
              wrapperClass=""
              handleOnClick={() => {
                profileUpdate({ reviewed_contacts: true });
              }}
            />
          )}
          <div
            className={classNames(
              isPrivateView && hasWritePermission && !profile.reviewed_contacts && "opacity-40",
            )}
          >
            {peoples.length === 0 && peopleNPO.length === 0 ? (
              allowEdit === true && !addNew && profileCardEmpty()
            ) : org_id ? (
              <GrantContactOld
                contacts={peoples}
                wrapperClass="!p-5 !mb-0 !-mt-4 max-h-[463px] h-[463px]"
                address={profile.contact_mailing}
                phone={profile.contact_phone}
                information={profile.contact_information}
                grant={profile.contact_grant}
                support={profile.contact_support}
                email={profile.contact_communication}
                compact={compact}
                withoutLogin={withoutLogin}
                allowEdit={allowEdit}
                forSearch={forSearch}
                onEdit={() => {
                  setShowEdit(!showEdit);
                }}
              />
            ) : (
              <GrantContactOld
                contacts={peopleNPO}
                wrapperClass="!p-5 !mb-0 !-mt-4 max-h-[463px] h-[463px]"
                compact={compact}
                withoutLogin={withoutLogin}
                allowEdit={allowEdit}
                forSearch={forSearch}
                onEdit={() => {
                  setShowEdit(!showEdit);
                }}
              />
            )}
            {additionalComponent && additionalComponent}
            {addNew && (
              <AddNewMember
                from_for_mixpanel="org_profile"
                isEdit={false}
                onClose={() => setAddNew(false)}
              />
            )}
          </div>
        </>
      </ExpandableCard>
    </>
  );
}

const SIDE_MENU_CONSTANT = [
  {
    title: "Communication",
    value: "ORG_COMMUNICATION",
  },
  {
    title: "People",
    value: "ORG_PEOPLE",
  },
];

const EditContactModal = ({
  org_id,
  npo_id,
  open,
  setOpen,
  withoutLogin,
  onClose,
}: {
  org_id?: string;
  npo_id?: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  withoutLogin?: boolean;
  onClose?: () => void;
}) => {
  const [selectedItem, setSelectedItem] = useState<string>("ORG_COMMUNICATION");
  const { data: peoples } = useTotalOrganizationMembersData(org_id);
  const { scrollTo } = useScrollTo("org-edit-scroll-container", -100);
  const { data, update: profileUpdate, isLoading, revalidate } = useOrgProfile(org_id);
  const [isSaving, setIsSaving] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const hasNoData = !data?.contact_mailing && !data?.contact_phone && !data?.contact_communication;
  const excludeOption: any = [
    data?.contact_mailing && "contact_mailing",
    data?.contact_phone && "contact_phone",
    data?.contact_communication && "contact_communication",
  ].filter(Boolean);
  const renderContactInfo = () => {
    return (
      <div id={"ORG_COMMUNICATION"} className="flex w-full flex-col gap-4">
        <div className="inline-flex border-b border-gray-300 ">
          <h5 className="text-base font-semibold underline underline-offset-[6px]">
            Organization communication
          </h5>
          <TooltipWrapper
            content="Feel free to add as many email addresses, mailing address or phone numbers as needed to ensure effective communication with your organization."
            textClass="max-w-[280px]"
            WrapperClass="!p-4"
            placements="right"
          >
            <div>
              <span className="grid place-items-center pl-1">
                <i className="gi-md gi-info mt-1 text-black" />
              </span>
            </div>
          </TooltipWrapper>
        </div>

        {hasNoData ? (
          <div className="rounded border border-gray-300 p-4">
            <CommunicationSelector
              isNew
              exclude={excludeOption}
              onComplete={(method, value) => {
                if (method === "contact_mailing") {
                  updateOrgContact({ contact_mailing: value });
                }
                if (method === "contact_phone") {
                  updateOrgContact({ contact_phone: value });
                }
                if (method === "contact_communication") {
                  updateOrgContact({ contact_communication: value });
                }
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 rounded border border-gray-300 p-4">
              {data.contact_mailing && (
                <CommunicationSelector
                  isEdit
                  defaulValue={data.contact_mailing}
                  defaultMethod="contact_mailing"
                  onComplete={(method, value) => {
                    updateOrgContact({ contact_mailing: value });
                  }}
                />
              )}
              {data.contact_phone && (
                <CommunicationSelector
                  isEdit
                  defaulValue={data.contact_phone}
                  defaultMethod="contact_phone"
                  onComplete={(method, value) => {
                    updateOrgContact({ contact_phone: value });
                  }}
                />
              )}
              {data.contact_communication && (
                <CommunicationSelector
                  isEdit
                  defaulValue={data.contact_communication}
                  defaultMethod="contact_communication"
                  onComplete={(method, value) => {
                    updateOrgContact({ contact_communication: value });
                  }}
                />
              )}
              {(!data.contact_communication || !data.contact_phone || !data.contact_mailing) && (
                <>
                  {addNew ? (
                    <CommunicationSelector
                      isNew
                      exclude={excludeOption}
                      onComplete={(method, value) => {
                        if (method === "contact_mailing") {
                          updateOrgContact({ contact_mailing: value });
                          setAddNew(false);
                        }
                        if (method === "contact_phone") {
                          updateOrgContact({ contact_phone: value });
                          setAddNew(false);
                        }
                        if (method === "contact_communication") {
                          updateOrgContact({ contact_communication: value });
                          setAddNew(false);
                        }
                      }}
                    />
                  ) : (
                    <p
                      className="cursor-pointer font-poppins text-sm text-purple-500"
                      onClick={() => {
                        setAddNew(true);
                      }}
                    >
                      + add communication method
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRightContent = () => {
    return (
      <>
        {renderContactInfo()}
        <OrganizationPeople
          id={"ORG_PEOPLE"}
          npo_id={npo_id}
          org_id={org_id}
          peoples={peoples}
          withoutLogin={withoutLogin}
        />
      </>
    );
  };
  const editContent = () => {
    return (
      <div className="grid  h-[618px] grid-cols-[232px_1fr]">
        {/* Left content */}
        <div className="flex h-full w-full flex-col gap-6 bg-gray-200 px-4 py-5">
          <div className="flex flex-col gap-3">
            {SIDE_MENU_CONSTANT.map((item, index) => {
              const isHeaderSelected = item.value === selectedItem;
              return (
                <div key={item.value} className="flex w-full max-w-[192px] flex-col gap-3 ">
                  <div
                    className={classNames(
                      "inline-flex cursor-pointer  items-center gap-[10px] font-poppins text-sm font-semibold ",
                      isHeaderSelected
                        ? " text-purple-500 underline underline-offset-2"
                        : "text-gray-700",
                    )}
                    onClick={() => {
                      setSelectedItem(item.value);
                      scrollTo(item.value);
                    }}
                  >
                    {item.title}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* Right content */}

        <div
          id="org-edit-scroll-container"
          className="flex h-full w-full flex-col gap-10 overflow-y-scroll bg-white px-[60px] py-[40px]"
        >
          {renderRightContent()}
        </div>
      </div>
    );
  };
  const updateOrgContact = (data: any) => {
    profileUpdate(data)
      .catch(() => {
        GibooToast({
          type: "failure",
          message: "We apologize for the inconvenience., Please try again",
        });
      })
      .finally(() => {
        revalidate();
      });
  };
  return (
    <>
      <PopupModal
        wrapperClass="w-full max-w-[959px] md:!px-0 "
        contentClass="!p-0"
        zIndex={zIndexEditPeople}
        isOpen={open}
        handleClose={() => {
          onClose?.();
          setOpen(false);
        }}
        title="Edit people"
        RightActionButton={() => (
          <Button
            id="save-btn"
            handleOnClick={() => {
              onClose?.();
              setOpen(false);
            }}
            loading={isSaving}
            label="Save"
          />
        )}
      >
        {editContent()}
      </PopupModal>
    </>
  );
};

function OrganizationPeople({
  org_id,
  npo_id,
  id,
  peoples,
  withoutLogin,
  forSearch,
}: {
  org_id?: string;
  npo_id?: string;
  id?: string;
  peoples: ITotalOrganizationMember[];
  withoutLogin?: boolean;
  forSearch?: boolean;
}) {
  const [addNew, setAddNew] = useState(false);
  return (
    <>
      <PopupModal
        isOpen={addNew}
        allowBackdropClose
        handleClose={() => {
          setAddNew(false);
        }}
        zIndex={zIndexAddPerson}
        title="Add member"
        wrapperClass="!min-w-[520px] max-w-[600px] md:!px-0 "
        contentClass="!px-2"
        showActionButtons={false}
      >
        <AddNewMember
          from_for_mixpanel="org_profile"
          isEdit={false}
          noBorder
          memberEditId={id}
          contentClass={classNames("max-h-[480px] min-w-[520px] overflow-y-scroll", scrollBarClass)}
          onClose={() => {
            setAddNew(false);
          }}
        />
      </PopupModal>
      <div id={id} className="flex flex-col gap-3">
        <div className="border-b border-gray-300 ">
          <h5 className="text-base font-semibold underline underline-offset-[6px]">People</h5>
        </div>
        <div className={"flex flex-col gap-4"}>
          {peoples.map((item, i) => {
            return (
              <ProfileCard2Old
                key={i}
                data={item}
                allowEdit
                withoutLogin={withoutLogin}
                forSearch={false}
              />
            );
          })}
          {/* {addNew && <AddNewMember isEdit={false} onClose={() => setAddNew(false)} />} */}
        </div>
        <div
          className="flex !h-[40px] w-[300px] cursor-pointer items-center justify-start gap-2 py-1 pl-[29px] text-sm text-gray-600"
          onClick={() => {
            setAddNew(true);
          }}
        >
          <i className="gi gi-add" />
          <span className={classNames("")}>add more organization members</span>
        </div>
      </div>
    </>
  );
}
