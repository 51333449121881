import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Divider, Steps, Tooltip, Whisper } from "rsuite";
import {
  getOnboardingStatus,
  selectAdditionalInfo,
  selectBasicInformation,
  selectNPOInfoRequested,
  selectPhilanthropyRequested,
  updateOnboardingAsync,
} from "../../../app/onboardingSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import SearchableDropdown from "../../../components/dropdown/SearchableDropdown";
import OnboardingSkipButton from "../../../components/OnboardingSkip";
import PrimaryButton from "../../../components/PrimaryButton";
import Spinner from "../../../components/Spinner";
import Tag from "../../../components/Tag";
import { TreeItem } from "../../../components/TreeSelector";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import useOnboardingData from "../../../hooks/useOnboarding";
import useOrgProfile from "../../../hooks/useOrgProfile";
import useTaxonomyTagging from "../../../hooks/useTaxonomyTagging";
import { Information, MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import { getOnboardingSummary } from "../../../services/summary.services";
import { allTaxonomyAutoComplete } from "../../../utils/autoComplete";
import { scrollBarClass } from "../../../utils/classes";
import { getWordLength } from "../../../utils/formatHelper";
import RephrasableInput from "../../project-creation/components/RephrasableInput";
import OnboardingBack from "./OnboardingBack";
const MAX_LENGTH = 200;
const MINIMUM_LENGTH = 10;
function NPOadditionalInfo() {
  const mxEvent = useGibooMixpanel("onboarding", true);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { type } = useAppSelector((state) => getOnboardingStatus(state));
  const { npo_name } = useAppSelector((state) => selectBasicInformation(state));
  const { mission_requested } = useAppSelector((state) => selectNPOInfoRequested(state));
  const additionalInfo = useAppSelector((state) => selectAdditionalInfo(state));
  const { focus_area_requested, beneficiary_requested, program_requested } = useAppSelector(
    (state) => selectPhilanthropyRequested(state),
  );
  const [onboardingData] = useOnboardingData();
  const [focusAreaText, setFocusAreaText] = useState<string>("");
  const [beneficiaryText, setBeneficiaryText] = useState<string>("");
  const [strategyText, setStrategyText] = useState<string>("");
  const [summary, setSummary] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [fromBack, setFromBack] = useState<boolean>(false);
  const [scrollTo, setScrollTo] = useState<string>();
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const { update: updateOrgProfile } = useOrgProfile(onboardingData._id);
  const callback = useCallback(
    (res: string, completed: boolean, failed?: boolean) => {
      if (completed || failed) setLoadingSummary(false);
      setSummary(res);
    },
    [setLoadingSummary, setSummary],
  );
  const startGeneratingSummary = useCallback(() => {
    setLoadingSummary(true);
    getOnboardingSummary(
      callback,
      npo_name || "nonprofit",
      focusAreaText,
      beneficiaryText,
      strategyText,
    );
  }, [
    npo_name,
    focusAreaText,
    beneficiaryText,
    strategyText,
    getOnboardingSummary,
    setLoadingSummary,
    callback,
  ]);

  const {
    isLoading: isLoading,
    focus_area: focusAreaByMission,
    beneficiary: beneficiaryByMission,
    program: programByMission,
  } = useTaxonomyTagging(summary || "", "all", 20);
  const [recommended, setRecommended] = useState<TreeItem[]>([]);
  const [focusArea, setFocusArea] = useState<string[]>(focus_area_requested || []);
  const [beneficiary, setBeneficiary] = useState<string[]>(beneficiary_requested || []);
  const [program, setProgram] = useState<string[]>(program_requested || []);
  useEffect(() => {
    const lower = {
      focusArea: focusArea.map((i) => i.toLowerCase()),
      beneficiary: beneficiary.map((i) => i.toLowerCase()),
      program: program.map((i) => i.toLowerCase()),
    };
    setRecommended(
      [
        ...focusAreaByMission.filter((i) => !lower.focusArea.includes(i.label.toLowerCase())),
        ...beneficiaryByMission.filter((i) => !lower.beneficiary.includes(i.label.toLowerCase())),
        ...programByMission.filter((i) => !lower.program.includes(i.label.toLowerCase())),
      ].sort((a, b) => (b.score || 0) - (a.score || 0)),
    );
  }, [
    focusAreaByMission,
    beneficiaryByMission,
    programByMission,
    focusArea,
    beneficiary,
    program,
    setRecommended,
  ]);
  useEffect(() => {
    const from_back = searchParams.get("from_back");
    setFromBack(from_back && from_back.toLowerCase()[0] === "t" ? true : false);
  }, [searchParams, setFromBack]);

  useEffect(() => {
    setFocusAreaText(additionalInfo.focus_area_text || "");
    setBeneficiaryText(additionalInfo.beneficiary_text || "");
    setStrategyText(additionalInfo.strategy_text || "");
    setSummary(additionalInfo.summary || "");
  }, [additionalInfo]);

  useEffect(() => {
    setFocusArea(focus_area_requested || []);
  }, [focus_area_requested, setFocusArea]);
  useEffect(() => {
    setBeneficiary(beneficiary_requested || []);
  }, [beneficiary_requested, setBeneficiary]);
  useEffect(() => {
    setProgram(program_requested || []);
  }, [program_requested, setProgram]);
  useEffect(() => {
    const hasTaxonomy =
      (focus_area_requested?.length || 0) +
      (beneficiary_requested?.length || 0) +
      (program_requested?.length || 0);
    if (hasTaxonomy) {
      setCurrentStep(2);
      setScrollTo("input-taxonomy");
    } else if (additionalInfo.summary) {
      setCurrentStep(1);
      setScrollTo("input-summary-header");
    }
  }, [
    additionalInfo,
    focus_area_requested,
    beneficiary_requested,
    program_requested,
    setCurrentStep,
  ]);

  const scrollToElement = (elementId: string, offset = 0): boolean => {
    if (elementId === "top") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      const elementTop = targetElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementTop - offset,
        behavior: "smooth",
      });
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (scrollTo) {
      if (scrollToElement(scrollTo, 140)) {
        setScrollTo(undefined);
      }
    }
  }, [scrollTo, currentStep, setScrollTo]);
  const updateText = () => {
    mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
      field: Information.FOCUS_AREA,
      value: focusAreaText,
    });
    mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
      field: Information.BENEFICIARY,
      value: beneficiaryText,
    });
    mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
      field: Information.PROGRAM,
      value: strategyText,
    });
    updateOrgProfile({ reviewed_philanthropic: true });
    dispatch(
      updateOnboardingAsync(
        {
          focus_area_text: focusAreaText,
          beneficiary_text: beneficiaryText,
          strategy_text: strategyText,
        },
        0,
      ),
    );
  };
  const updateInfo = () => {
    mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
      field: Information.SUMMARY,
      value: summary,
    });
    mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
      field: Information.TAGS,
      tags: [
        ...focusArea.map((t) => ({
          type: "focus_area" as "focus_area" | "beneficiary" | "program",
          name: t,
        })),
        ...beneficiary.map((t) => ({
          type: "beneficiary" as "focus_area" | "beneficiary" | "program",
          name: t,
        })),
        ...program.map((t) => ({
          type: "program" as "focus_area" | "beneficiary" | "program",
          name: t,
        })),
      ],
    });
    updateOrgProfile({ reviewed_tags: true, reviewed_philanthropic: true });
    dispatch(
      updateOnboardingAsync(
        {
          focus_area_text: focusAreaText,
          beneficiary_text: beneficiaryText,
          strategy_text: strategyText,
          summary: summary,
          focus_area_requested: focusArea,
          beneficiary_requested: beneficiary,
          program_requested: program,
        },
        +1,
      ),
    );
  };
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value <= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };
  const pass1 =
    getWordLength(focusAreaText) >= MINIMUM_LENGTH &&
    getWordLength(beneficiaryText) >= MINIMUM_LENGTH &&
    getWordLength(strategyText) >= MINIMUM_LENGTH;
  const pass2 = getWordLength(summary) >= MINIMUM_LENGTH;
  const pass3 = pass2 && focusArea.length + beneficiary.length + program.length > 0;
  const renderDescription = () => {
    return (
      <div className="flex max-w-[774px] flex-col gap-4">
        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            Why is tag selection important?
          </h4>
          <p className="max-w-[774px] font-poppins text-xs text-gray-800">
            Derived from your input of your organization&apos;s information, above are suggested
            tags that reflect the essence of your organization. These tags play a crucial role in
            identifying potential funders and grants. Please select the tags that most accurately
            represent your organization. If the tags you envision are not listed, you may search for
            additional ones from our dictionaries.
          </p>
        </div>
      </div>
    );
  };
  const renderStep1 = () => {
    return (
      <>
        <h4 className="onboarding-header">
          Please provide additional information about your nonprofit organization.
        </h4>
        <p className="onboarding-sub-header-gray  onboarding-width-lg mt-3">
          This can be in the form of tags or sentences. This information can help us to include
          potential funders and grant <br />
          opportunities with a track record of supporting similar areas or initiatives that align
          with the goals of your organization.
        </p>
        <div className="onboarding-body mt-[40px] w-[750px] !gap-[60px]">
          <div className="onboarding-width-md2 flex flex-col gap-4">
            <div>
              <h6 className="font-poppins text-[20px] font-semibold text-black">
                1. What is your organization&apos;s main areas of focus?
              </h6>
              <p className="mt-2 font-poppins text-sm !font-normal !text-gray-700">
                This can be challenges you want to solve or values you want to support.
              </p>
            </div>
            <RephrasableInput
              from_for_pixpanel="onboarding"
              id={`input-focus-area-detail`}
              title=""
              placeholder={
                "E.g: We aim to address the limited exposure and opportunities available to artists of Korean heritage in the contemporary art world of the United States."
              }
              buttonLabel="Ask Giboo AI"
              promptPrefix={
                "Write a comprehensive description of focus area in 100 words. This can be challenges you want to solve or values you want to support."
              }
              additonalInformation={mission_requested ? { mission: mission_requested } : undefined}
              value={focusAreaText}
              setValue={(v) => setFocusAreaText(v)}
              buttonPosition="bottomLeft"
              height="300px"
              wrapperClass="!w-[699px]"
              buttonDisable={getWordLength(focusAreaText) < MINIMUM_LENGTH}
              enableAiSuggestion={false}
              minLength={MINIMUM_LENGTH}
              onClick={() => {
                mxEvent(MIXPANEL_EVENTS_V2.onboarding.ask_giboo_ai.clicked, {
                  ask_giboo_ai_target: "focus_area",
                });
              }}
              buttonClass="!h-[29px] !text-sm"
            />
          </div>
          <div className="onboarding-width-md2 flex flex-col gap-4">
            <div>
              <h6 className="font-poppins text-[20px] font-semibold text-black">
                2. Who or what is the beneficiary group you want to support?
              </h6>
              <p className="mt-2 font-poppins text-sm !font-normal !text-gray-700">
                This can be a group of people, the environment, or even the general public.
              </p>
            </div>
            <RephrasableInput
              from_for_pixpanel="onboarding"
              id={`input-beneficiary-detail`}
              title=""
              placeholder="E.g: The primary beneficiaries are talented Korean and Korean-American artists active in the United States."
              buttonLabel="Ask Giboo AI"
              promptPrefix={
                "Write a comprehensive description of beneficiary who you support in 100 words. This can be a group of people, the environment, or even the general public."
              }
              additonalInformation={mission_requested ? { mission: mission_requested } : undefined}
              value={beneficiaryText}
              setValue={(v) => setBeneficiaryText(v)}
              buttonPosition="bottomLeft"
              height="300px"
              wrapperClass="!w-[699px]"
              enableAiSuggestion={false}
              buttonDisable={getWordLength(beneficiaryText) < MINIMUM_LENGTH}
              minLength={MINIMUM_LENGTH}
              onClick={() => {
                mxEvent(MIXPANEL_EVENTS_V2.onboarding.ask_giboo_ai.clicked, {
                  ask_giboo_ai_target: "beneficiary",
                });
              }}
              buttonClass="!h-[29px] !text-sm"
            />
          </div>
          <div className="onboarding-width-md2 flex flex-col gap-4">
            <div>
              <h6 className="font-poppins text-[20px] font-semibold text-black">
                3. What are your organization&apos;s strategies to achieve its goals?
              </h6>
              <p className="mt-2 font-poppins text-sm !font-normal !text-gray-700">
                Describe activities or programs you undertake to achieve your organization&apos;s
                goals.
              </p>
            </div>
            <RephrasableInput
              from_for_pixpanel="onboarding"
              id={`input-program-detail`}
              title=""
              placeholder={
                "E.g: Our strategies include promoting artists through exhibitions and public art showings; educating artists on the business aspects of art;"
              }
              buttonLabel="Ask Giboo AI"
              promptPrefix={
                "Describe activities or programs you undertake to achieve your organization's goals in 100 words."
              }
              additonalInformation={mission_requested ? { mission: mission_requested } : undefined}
              value={strategyText}
              setValue={(v) => setStrategyText(v)}
              buttonPosition="bottomLeft"
              height="300px"
              wrapperClass="!w-[699px]"
              enableAiSuggestion={false}
              buttonDisable={getWordLength(strategyText) < MINIMUM_LENGTH}
              minLength={MINIMUM_LENGTH}
              onClick={() => {
                mxEvent(MIXPANEL_EVENTS_V2.onboarding.ask_giboo_ai.clicked, {
                  ask_giboo_ai_target: "program",
                });
              }}
              buttonClass="!h-[29px] !text-sm"
            />
          </div>
        </div>
        <div className={classNames("my-10 inline-flex items-center gap-2 pb-24")}>
          <OnboardingBack
            outline
            data={{
              focus_area_text: focusAreaText,
              beneficiary_text: beneficiaryText,
              strategy_text: strategyText,
              summary: summary,
              focus_area_requested: focusArea,
              beneficiary_requested: beneficiary,
              program_requested: program,
            }}
          />
          <Whisper
            {...(!pass1 ? { trigger: "hover" } : { open: false })}
            controlId="control-id-container"
            trigger="hover"
            speaker={
              <Tooltip arrow={false} style={{ maxWidth: 260 }} className="!mb-1 bg-gray-600">
                Please enter the above three questions, each with a minimum of 10 words.
              </Tooltip>
            }
            placement="topStart"
          >
            <div>
              <PrimaryButton
                size="md"
                id="btn-next"
                handleOnClick={() => {
                  if (currentStep < 1) {
                    setCurrentStep(1);
                    startGeneratingSummary();
                  }
                  updateText();
                  setScrollTo("input-summary-header");
                }}
                rightIconClass={<i className="gi gi-right-arrow" />}
                label="Next"
                disabled={!pass1}
              />
            </div>
          </Whisper>
          <OnboardingSkipButton />
        </div>
      </>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <h4 className="onboarding-header" id="input-summary-header">
          Based on your input, we&apos;ve crafted a <br />
          philanthropic summary for your organization.
        </h4>
        <p className="onboarding-sub-header-gray  onboarding-width-lg mt-3 !text-xs">
          Take a moment to review, and feel free to make any edits to ensure it perfectly reflects
          your organization&apos;s vision and values.
        </p>
        <div className="onboarding-body mt-[40px] w-[750px] !gap-[60px]">
          <div className="flex max-w-[699px] flex-col gap-1">
            <RephrasableInput
              from_for_pixpanel="onboarding"
              id={`input-summary`}
              title=""
              placeholder=""
              buttonLabel="Ask Giboo AI"
              promptPrefix={`Write a comprehensive summary of philanthropic focus of '${
                npo_name || "nonprofit"
              }' in 100 words using below`}
              additonalInformation={{
                focus_area: focusAreaText,
                beneficiary: beneficiaryText,
                program: strategyText,
              }}
              disabled={loadingSummary}
              value={summary}
              setValue={(v) => setSummary(v)}
              buttonPosition="bottomLeft"
              height="355px"
              noOfRows={20}
              enableAiSuggestion={false}
              buttonDisable={getWordLength(summary) < MINIMUM_LENGTH}
              minLength={MINIMUM_LENGTH}
              onClick={() => {
                mxEvent(MIXPANEL_EVENTS_V2.onboarding.ask_giboo_ai.clicked, {
                  ask_giboo_ai_target: "summary",
                });
              }}
              buttonClass="!h-[29px] !text-sm"
              loading={loadingSummary}
            />
          </div>
        </div>
        <div className={classNames("my-10 inline-flex items-center gap-2 pb-24")}>
          <OnboardingBack
            outline
            handleOnClick={() => {
              setCurrentStep(0);
              setSummary("");
              setScrollTo("top");
            }}
            noAction
          />
          <Whisper
            {...(!pass2 ? { trigger: "hover" } : { open: false })}
            controlId="control-id-container"
            trigger="hover"
            speaker={
              <Tooltip arrow={false} style={{ maxWidth: 260 }} className="!mb-1 bg-gray-600">
                Please enter the above four questions, each with a minimum of 10 words.
              </Tooltip>
            }
            placement="topStart"
          >
            <div>
              <PrimaryButton
                size="md"
                id="btn-next2"
                handleOnClick={() => {
                  if (currentStep < 2) setCurrentStep(2);
                  setScrollTo("input-taxonomy");
                }}
                rightIconClass={<i className="gi gi-right-arrow" />}
                label="Next"
                disabled={!pass1 || !pass2}
              />
            </div>
          </Whisper>
        </div>
      </>
    );
  };
  const renderStep3 = () => {
    return (
      <div className="onboarding-width-lg">
        <h4 className="onboarding-header" id={"input-taxonomy"}>
          Choose all tags that represent your organization, or <br />
          you can also add your own tags.
        </h4>
        <p className="onboarding-sub-header-gray  onboarding-width-lg mt-3">
          According to the information you gave us, we suggest the most important tags.
          <br /> Tags show the main areas your organization focuses on, groups of people you help,
          and the activities and programs you have.
        </p>
        <div className="onboarding-width-lg onboarding-body mt-[40px] w-[750px] !gap-[40px]">
          <div className="flex max-w-[908px] flex-col gap-4 rounded border border-gray-300 p-4">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <h5 className="font-semibold text-purple-500">
                  {`Select from the following suggested tags (${recommended.length})`}
                  {isLoading && <Spinner size="sm" span wrapperClass="ml-2" />}
                </h5>
                <div className="inline-flex items-center gap-3">
                  <div className="inline-flex items-center gap-2">
                    <span className="h-3 w-3 bg-yellow-500"></span>
                    <h6 className="font-poppins text-xs text-gray-800">Focus area</h6>
                  </div>
                  <div className="inline-flex items-center gap-2">
                    <span className="h-3 w-3 bg-blue-500"></span>
                    <h6 className="font-poppins text-xs text-gray-800">Beneficiary</h6>
                  </div>
                  <div className="inline-flex items-center gap-2">
                    <span className="h-3 w-3 bg-green-500"></span>
                    <h6 className="font-poppins text-xs text-gray-800">Program</h6>
                  </div>
                </div>
              </div>
              <h6>
                Please select the tags that most accurately represent your organization. <br />
                If the tags you envision are not listed, you may search for additional tags.
              </h6>
            </div>
            <div
              id="container-suggested-tags"
              className={classNames(
                "flex max-h-[220px] max-w-[908px] flex-wrap gap-2 rounded border border-gray-300 p-4",
                scrollBarClass,
                { ["hidden"]: recommended.length === 0 },
              )}
            >
              {recommended.map((t) => {
                const key = `${t.type}_${t.label}`;
                return (
                  <Tag
                    key={key}
                    id={key}
                    name={t.label}
                    color={
                      t.type === "f"
                        ? "yellow"
                        : t.type === "b"
                        ? "blue"
                        : t.type === "p"
                        ? "green"
                        : "gray"
                    }
                    size="sm"
                    textTruncate={false}
                    isFullTagClick
                    onAdd={() => {
                      if (t.type === "f") {
                        setFocusArea((prev) => [
                          ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                          t.label,
                        ]);
                        mxEvent(MIXPANEL_EVENTS_V2.onboarding.tag.added, {
                          type: "focus_area",
                          name: t.label,
                        });
                      } else if (t.type === "b") {
                        setBeneficiary((prev) => [
                          ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                          t.label,
                        ]);
                        mxEvent(MIXPANEL_EVENTS_V2.onboarding.tag.added, {
                          type: "beneficiary",
                          name: t.label,
                        });
                      } else if (t.type === "p") {
                        setProgram((prev) => [
                          ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                          t.label,
                        ]);
                        mxEvent(MIXPANEL_EVENTS_V2.onboarding.tag.added, {
                          type: "program",
                          name: t.label,
                        });
                      }
                    }}
                  />
                );
              })}
            </div>
            {open ? (
              <SearchableDropdown<TreeItem>
                from_for_mixpanel={"onboarding"}
                mixpanel_event={MIXPANEL_EVENTS_V2.search_tag[""]}
                asMixPanelProperty={(t) => ({
                  type:
                    t.type === "f"
                      ? "focus_area"
                      : t.type === "b"
                      ? "beneficiary"
                      : t.type === "p"
                      ? "program"
                      : undefined,
                  name: t.label,
                })}
                id={"tag"}
                getColor={(item: TreeItem) =>
                  item.type === "f"
                    ? "yellow"
                    : item.type === "b"
                    ? "blue"
                    : item.type === "p"
                    ? "green"
                    : "gray"
                }
                query={query}
                setQuery={setQuery}
                autoCompleteFunction={allTaxonomyAutoComplete}
                autoCompleteThrottleMilliseconds={200}
                addItem={(t) => {
                  if (t.type === "f") {
                    setFocusArea((prev) => [
                      ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                      t.label,
                    ]);
                    mxEvent(MIXPANEL_EVENTS_V2.onboarding.tag.added, {
                      type: "focus_area",
                      name: t.label,
                      own: true,
                    });
                  } else if (t.type === "b") {
                    setBeneficiary((prev) => [
                      ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                      t.label,
                    ]);
                    mxEvent(MIXPANEL_EVENTS_V2.onboarding.tag.added, {
                      type: "beneficiary",
                      name: t.label,
                      own: true,
                    });
                  } else if (t.type === "p") {
                    setProgram((prev) => [
                      ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                      t.label,
                    ]);
                    mxEvent(MIXPANEL_EVENTS_V2.onboarding.tag.added, {
                      type: "program",
                      name: t.label,
                      own: true,
                    });
                  }
                }}
                // createItem={(str) => ({
                //   type: "",
                //   label: str,
                //   parent: "",
                //   object_id: "",
                // })}
                renderItem={(item) => item.label}
                getKey={(item) => `${item.type}_${item.label.toLowerCase()}`}
                excludeKeys={[
                  ...focusArea.map((t) => `f_${t.toLowerCase()}`),
                  ...beneficiary.map((t) => `b_${t.toLowerCase()}`),
                  ...program.map((t) => `p_${t.toLowerCase()}`),
                ]}
                placeholder={"Add tags from our classification dictionary"}
                float
                maxWidth="480px"
                maxHeight="400px"
                icon={true}
                spinner
                spinnerBackgroundColor="bg-gray-50"
              />
            ) : (
              <div
                id="btn-add-your-own"
                className="flex w-fit cursor-pointer items-center gap-3 rounded border border-gray-300 px-2 py-[2px] hover:border-gray-500 hover:bg-gray-100"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <i className="gi-search !text-xs text-gray-600" />
                <h5 className="text-gray-700">Add your own</h5>
              </div>
            )}
          </div>
          <div className="flex max-w-[908px] flex-col gap-4 rounded border border-gray-300 p-4">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <h5 className="font-semibold text-purple-500">
                  {`Selected tags (${focusArea.length + beneficiary.length + program.length})`}
                </h5>
                <div className="inline-flex items-center gap-3">
                  <div className="inline-flex items-center gap-2">
                    <span className="h-3 w-3 bg-yellow-500"></span>
                    <h6 className="font-poppins text-xs text-gray-800">Focus area</h6>
                  </div>
                  <div className="inline-flex items-center gap-2">
                    <span className="h-3 w-3 bg-blue-500"></span>
                    <h6 className="font-poppins text-xs text-gray-800">Beneficiary</h6>
                  </div>
                  <div className="inline-flex items-center gap-2">
                    <span className="h-3 w-3 bg-green-500"></span>
                    <h6 className="font-poppins text-xs text-gray-800">Program</h6>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames("flex flex-wrap gap-2 rounded border border-gray-300 p-4", {
                ["hidden"]:
                  focusArea.length === 0 && beneficiary.length === 0 && program.length === 0,
              })}
            >
              {focusArea.map((t) => {
                const key = `f_${t}`;
                return (
                  <Tag
                    key={key}
                    id={key}
                    name={t}
                    color="yellow"
                    size="sm"
                    isFullTagClick
                    textTruncate={false}
                    matched
                    onDelete={() => {
                      setFocusArea((prev) =>
                        prev.filter((p) => p.toLowerCase() !== t.toLowerCase()),
                      );
                      mxEvent(MIXPANEL_EVENTS_V2.onboarding.tag.removed, {
                        type: "focus_area",
                        name: t,
                      });
                    }}
                  />
                );
              })}
              {beneficiary.map((t) => {
                const key = `b_${t}`;
                return (
                  <Tag
                    key={key}
                    id={key}
                    name={t}
                    color="blue"
                    size="sm"
                    isFullTagClick
                    textTruncate={false}
                    matched
                    onDelete={() => {
                      setBeneficiary((prev) =>
                        prev.filter((p) => p.toLowerCase() !== t.toLowerCase()),
                      );
                      mxEvent(MIXPANEL_EVENTS_V2.onboarding.tag.removed, {
                        type: "beneficiary",
                        name: t,
                      });
                    }}
                  />
                );
              })}
              {program.map((t) => {
                const key = `p_${t}`;
                return (
                  <Tag
                    key={key}
                    id={key}
                    name={t}
                    color="green"
                    size="sm"
                    isFullTagClick
                    textTruncate={false}
                    matched
                    onDelete={() => {
                      setProgram((prev) => prev.filter((p) => p.toLowerCase() !== t.toLowerCase()));
                      mxEvent(MIXPANEL_EVENTS_V2.onboarding.tag.removed, {
                        type: "program",
                        name: t,
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className={classNames("my-10 inline-flex items-center gap-2")}>
          <OnboardingBack
            outline
            handleOnClick={() => {
              setCurrentStep(1);
              setFocusArea([]);
              setBeneficiary([]);
              setProgram([]);
              setScrollTo("input-summary-header");
            }}
            noAction
          />
          <PrimaryButton
            size="md"
            id="btn-next3"
            handleOnClick={updateInfo}
            rightIconClass={<i className="gi gi-right-arrow" />}
            label="Next"
            disabled={!pass3}
          />
          <div
            className="grid h-[37px] w-[62px] cursor-pointer place-items-center rounded p-1 hover:bg-purple-50"
            onClick={() => {
              mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
                field: Information.SUMMARY,
                value: summary,
              });
              mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
                field: Information.TAGS,
                tags: [
                  ...focusArea.map((t) => ({
                    type: "focus_area" as "focus_area" | "beneficiary" | "program",
                    name: t,
                  })),
                  ...beneficiary.map((t) => ({
                    type: "beneficiary" as "focus_area" | "beneficiary" | "program",
                    name: t,
                  })),
                  ...program.map((t) => ({
                    type: "program" as "focus_area" | "beneficiary" | "program",
                    name: t,
                  })),
                ],
              });
              updateOrgProfile({ reviewed_tags: true, reviewed_philanthropic: true });
              dispatch(
                updateOnboardingAsync(
                  {
                    focus_area_text: focusAreaText,
                    beneficiary_text: beneficiaryText,
                    strategy_text: strategyText,
                    summary: summary,
                    focus_area_requested: focusArea,
                    beneficiary_requested: beneficiary,
                    program_requested: program,
                  },
                  +1,
                ),
              );
            }}
          >
            <h5 className="text-sm font-normal text-purple-500">Skip</h5>
          </div>
        </div>
        <Divider className="!mt-0 mb-[40px]" />
        {renderDescription()}
      </div>
    );
  };
  return (
    <div className="onboarding flex flex-col">
      <Steps current={currentStep} vertical>
        <Steps.Item
          title={false}
          className="!-ml-[40px] !mt-0"
          icon={currentStep > 0 ? renderDot(1) : <></>}
          description={renderStep1()}
        />
        {currentStep > 0 && (
          <Steps.Item icon={renderDot(2)} className="!-ml-[40px]" description={renderStep2()} />
        )}
        {currentStep > 1 && (
          <Steps.Item icon={renderDot(3)} className="!-ml-[40px]" description={renderStep3()} />
        )}
      </Steps>
    </div>
  );
}
export default NPOadditionalInfo;
