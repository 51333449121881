import classNames from "classnames";
import React, { ReactNode } from "react";
import { toast } from "react-toastify";
import CLOSE_ICON from "../assets/images/close-thin.svg";

interface SpacerProps {
  type: "info" | "success" | "warning" | "failure";
  handleAction?: () => void;
  customClass?: string;
  message: string | ReactNode;
  messageBody?: ReactNode;
  actionLabel?: string;
}
function GibooToast({
  type,
  actionLabel,
  messageBody,
  handleAction,
  customClass,
  message,
}: SpacerProps) {
  let toastClass = "";
  if (type === "success") {
    toastClass = "border-l-[8px] border-green-500";
  } else if (type === "info") {
    toastClass = "border-l-[8px] border-purple-500";
  } else if (type === "warning") {
    toastClass = "border-l-[8px] border-yellow-500";
  } else {
    toastClass = "border-l-[8px] border-red-500";
  }
  return toast(
    <div className={classNames("flex w-full flex-col", customClass)}>
      {message && (
        <div className="flex w-full items-start justify-between">
          <p className="font-poppins text-base font-medium text-black">{message}</p>
          <div className="grid h-full place-items-center">
            <img src={CLOSE_ICON} className="mt-[4px] h-[14px] w-[14px]" />
          </div>
        </div>
      )}
      {messageBody && messageBody}
      {actionLabel && (
        <h5
          className="mt-[4px] cursor-pointer font-poppins text-sm text-purple-500"
          onClick={() => handleAction && handleAction()}
        >
          {actionLabel}
        </h5>
      )}
    </div>,
    {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 6000,
      bodyClassName: "p-0",
      className: toastClass,
    },
  );
}

export default GibooToast;
