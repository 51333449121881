import { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import Button from "./tailwind/Button";
import GibooDialog from "./Dialog";
import TextInput from "./tailwind/TextInput";
import ICON_CLIP from "../assets/project-mgt/link.svg";
import RoundButton from "./RoundButton";
import GibooToast from "./GibooToast";

type ShareButtonProps = {
  classname?: string;
  outline?: boolean;
  iconClass?: string;
  isRoundButton?: boolean;
  initialUrl?: string;
  bordered?: boolean;
  onClick?: () => void;
};

function ShareButton({
  classname,
  outline,
  iconClass,
  isRoundButton = false,
  initialUrl,
  bordered = true,
  onClick,
}: ShareButtonProps) {
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  useEffect(() => {
    if (initialUrl) {
      setLink(initialUrl);
    } else {
      setLink(`${window.location.href}` || "");
    }
  }, [initialUrl]);
  const handleOnClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          setIsCopied(true);
          GibooToast({
            type: "success",
            message: `Copied to clipboard.`,
          });
          setTimeout(() => {
            setIsCopied(false);
            setOpen(false);
          }, 500);
          console.log("Link copied to clipboard successfully");
        })
        .catch((err) => {
          console.error("Unable to copy link to clipboard", err);
          if (err.name === "NotAllowedError") {
            console.warn("Permission to access the clipboard was denied.");
          }
        });
    } else {
      setOpen(false);
      console.error("Clipboard API not supported");
    }
  };
  return (
    <>
      {open && (
        <GibooDialog
          onClose={() => setOpen(false)}
          open={open}
          title="Share link"
          style={{ width: "586px" }}
          contentClassname="!h-[104px] !p-7"
          content={
            <div>
              <TextInput
                iconLeft={
                  <span className="inline-flex items-center">
                    <img src={ICON_CLIP} className="h-5 w-5" />
                  </span>
                }
                id="project-link"
                className={classNames(
                  "!h-[48px] !w-[530px] !text-sm !placeholder-gray-600 placeholder:!text-sm",
                )}
                value={link}
                //   placeholder="example:https://www.linkurl.com"
                handleOnChange={(e) => {
                  setLink(e.target.value);
                }}
              />
            </div>
          }
          footer={
            <div className="inline-flex items-center gap-2">
              <Button
                id="btn-cancel"
                handleOnClick={() => setOpen(false)}
                outline
                size="md"
                color="purple"
                label="Cancel"
              />
              <Button
                id="btn-copy"
                handleOnClick={handleOnClick}
                color="purple"
                size="md"
                label={isCopied ? "Copied" : "Copy"}
              />
            </div>
          }
        />
      )}
      {isRoundButton ? (
        <RoundButton
          id={"GA_TAG_click_share"}
          handleOnClick={() => {
            setOpen((prev) => !prev);
            onClick?.();
          }}
          type="secondary"
          size="md"
          icon="fa-regular fa-share-from-square text-purple-500"
          tooltip="Share"
          bordered={bordered}
        />
      ) : (
        <Button
          id={"GA_TAG_click_share"}
          handleOnClick={() => {
            setOpen((prev) => !prev);
            onClick?.();
          }}
          className={classNames(classname)}
          outline={outline}
          size="md"
          label="Share"
          prependIcon={
            <i
              className={classNames(
                "fa-regular fa-share-from-square ",
                outline ? "text-purple-500" : "text-white",
                iconClass,
              )}
            />
          }
        />
      )}
    </>
  );
}

export default ShareButton;
