import React from "react";
import classNames from "classnames";

import IMG_GENERATING_LOI from "../../../img/loi-creation.svg";
import Button from "../../../../components/tailwind/Button";
import Spinner from "../../../../components/Spinner";
import { scrollBarClass } from "../../../../utils/classes";

interface Iprops {
  title: string;
  customClass?: string | undefined;
  message?: string;
  tryAgain?: () => void;
  loading?: boolean;
  forFunder?: boolean;
  name?: string;
  LOIContent?: string;
}
function GeneratingLoi({ message, customClass, loading, forFunder = true, ...props }: Iprops) {
  return (
    <div
      className={classNames(
        "flex h-[535px] w-full flex-col items-stretch  justify-center",
        customClass,
      )}
    >
      {message ? (
        <div className="flex h-full flex-col items-center justify-center space-y-5">
          <h4 className="font-poppins text-xl text-gray-900">{message}</h4>
          {props.tryAgain && (
            <Button
              handleOnClick={() => {
                props.tryAgain?.();
              }}
              size="lg"
              label="Try again"
              id="btn-try-again"
            />
          )}
        </div>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-center gap-5 py-10">
          <div className="flex h-[352px] w-[438px] items-center justify-center ">
            <img src={IMG_GENERATING_LOI} className="ml-[28px] h-[311px] w-[191px]" />
          </div>
          <div className="flex flex-col px-2 text-center font-poppins">
            <h4 className="text-xl font-normal">
              {props.name
                ? `Creating a ${props.title.toLowerCase()} for ${props.name}`
                : forFunder
                ? `Creating a ${props.title.toLowerCase()} for this funder`
                : `Creating a ${props.title.toLowerCase()}`}
            </h4>
            <div className="mt-3 flex w-full items-center justify-center gap-2">
              {loading && (
                <span className="flex ">
                  <Spinner size="sm" wrapperClass="!px-0" />
                </span>
              )}
              <h5 className="text-center text-sm font-normal">
                Giboo AI is generating the letter for you!
              </h5>
            </div>
          </div>
          {/* <div
            className={classNames(
              "h-[500px] max-h-[500px] w-full max-w-[700px] whitespace-pre-line text-left font-poppins text-xs",
              scrollBarClass,
            )}
          >
            <span>{props.LOIContent}</span>
            <span>
              {loading && (
                <span className="ml-2 inline-block h-3 w-3 animate-spin rounded-full border-[2px] border-solid border-purple-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]">
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </span>
              )}
            </span>
          </div> */}
        </div>
      )}
    </div>
  );
}
export default GeneratingLoi;
