import { useEffect, useRef, useState } from "react";
import useUser from "../hooks/useUser";
import { IProjectInteractionComment, IProjectSubtaskNote } from "../types/project";
import useOnClickOutside from "../hooks/useOnClickOutside";
import {
  deleteProjectInteractionComment,
  updateProjectInteractionComment,
} from "../hooks/project/useInteractionComments";
import GibooToast from "./GibooToast";
import UserProfile from "./UserProfile";
import TextArea from "./tailwind/TextArea";
import classNames from "classnames";
import Button from "./tailwind/Button";
import { TooltipWrapper } from "./TooltipWrapper";
import { convertToReadable, toDateString } from "../utils/formatHelper";
import useSubtaskNote from "../hooks/project/useSubtaskNote";
import UserMentionsInput from "./UserMentionsInput";
import useOrgMembers from "../hooks/project/useOrgMembers";
import useOrgs from "../hooks/useOrgs";
import OrgInvitationPopup from "./OrgInvitationPopup";
import { FROM_FOR_MIXPANEL } from "../mixpanel/mixpanel";
import ProjectInvite from "./ProjectInvite";

export default function CommentBox({
  projectId,
  from_for_mixpanel,
  activity,
  revalidate,
  onDelete,
  onUpdate,
  isNote,
}: {
  projectId: string | undefined;
  from_for_mixpanel: FROM_FOR_MIXPANEL;
  activity: IProjectSubtaskNote;
  revalidate: () => void;
  onDelete?: (data: any) => void;
  onUpdate?: (data: any) => void;
  isNote?: boolean;
}) {
  const [user] = useUser();
  const isOwner = activity.user_id === user?._id;
  const [isEditMode, setIsEditMode] = useState(false);
  const [comment, setComment] = useState(activity.content);
  const [isUpdating, setIsUpdating] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isDirty = comment !== activity.content;
  const { update: noteUpdate, deleteThis: noteDelete } = useSubtaskNote(activity._id || undefined);
  const { selected } = useOrgs();
  const {
    data: members,
    isLoading: orgmembersLoading,
    revalidate: revalidateMembers,
  } = useOrgMembers(selected);
  const [showInvite, setShowInvite] = useState<boolean>(false);
  useOnClickOutside(ref, () => {
    if (isEditMode) {
      setIsEditMode(false);
      isDirty && setComment(activity.content);
    }
  });
  const handleDelete = () => {
    (isNote ? noteDelete() : deleteProjectInteractionComment(activity._id))
      .then(() => {
        GibooToast({
          type: "success",
          message: isNote ? "Note deleted successfully." : `Comment deleted from status.`,
        });
        revalidate();
      })
      .catch((err) => {
        console.log(err);
        GibooToast({
          type: "failure",
          message: `We apologize for the inconvenience. Please try again.`,
        });
      })
      .finally(() => {});
  };
  const updateComment = () => {
    setIsUpdating(true);
    const reqData: any = {
      ...activity,
      content: comment ? comment : activity.content,
    };
    (isNote ? noteUpdate(reqData) : updateProjectInteractionComment(activity._id, reqData))
      .then(() => {
        setIsEditMode(false);
        revalidate();
      })
      .catch((err) => {
        GibooToast({
          type: "failure",
          message: `We apologize for the inconvenience. Please try again.`,
        });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  if (isEditMode) {
    return (
      <div
        ref={ref}
        className="flex h-fit w-full flex-col gap-3 rounded border border-gray-300 p-4"
      >
        {showInvite &&
          (projectId ? (
            <ProjectInvite
              projectId={projectId}
              from_for_mixpanel={from_for_mixpanel}
              showInvite={showInvite}
              handleClose={() => {
                setShowInvite(false);
                revalidateMembers();
              }}
            />
          ) : (
            <OrgInvitationPopup
              from_for_mixpanel={from_for_mixpanel}
              show={showInvite}
              onClose={() => {
                setShowInvite(false);
                revalidateMembers();
              }}
            />
          ))}
        <div ref={ref} className="grid w-full grid-cols-[20px_1fr] items-center gap-[10px]">
          <div className="flex h-full">
            <span className="self-start">
              <UserProfile userId={user?._id} showName={false} size={16} />
            </span>
            <div />
          </div>
          <div className="flex w-full flex-col gap-3">
            <UserMentionsInput
              id="note"
              value={comment}
              noOfRows={4}
              autoFocus
              className="!mb-0 !min-h-[114px]"
              handleOnChange={(event, newValue, newPlainTextValue, mentions) => {
                if (mentions?.[0]?.id === "CUSTOM") {
                  return;
                }
                setComment(event.target.value);
              }}
              onAdd={(id) => {
                if (id === "CUSTOM") {
                  setShowInvite(true);
                }
              }}
              customComponent={
                <div className="w-full text-center font-poppins text-xs text-gray-900">
                  Invite member
                </div>
              }
              options={[
                ...(members
                  .filter((i) => i._id !== user?._id)
                  .map((i) => ({
                    id: i._id,
                    display: `${i.firstname} ${i.lastname}`,
                  })) as []),
                { id: "CUSTOM", display: `Invite Member` },
              ]}
            />
            <div className="flex gap-2 self-end">
              <Button
                handleOnClick={() => {
                  setIsEditMode(false);
                  setComment(activity.content);
                }}
                outline
                className="h-[37px]"
                id="cancel"
                label={"Cancel"}
              />
              <Button
                className="h-[37px] px-3"
                handleOnClick={() => {
                  onUpdate
                    ? onUpdate({
                        ...activity,
                        content: comment ? comment : activity.content,
                      })
                    : updateComment();
                }}
                disabled={!comment}
                loading={isUpdating}
                id="post"
                label={"Save changes"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-fit w-full flex-col gap-3 rounded border border-gray-300 p-4">
      <div className="inline-flex w-full items-center justify-between gap-4">
        <span className="w-[200px]">
          <UserProfile userId={activity.user_id} showName={true} size={24} />
        </span>
        <div className="inline-flex items-center gap-2">
          <h5 className="font-poppins text-xs text-gray-900">
            {activity?.created_at ? toDateString(activity?.created_at, true, true) : "None"}
          </h5>
          {isOwner && (
            <TooltipWrapper
              placements="bottomEnd"
              WrapperClass="!p-0 !rounded-md  bg-purple-500 !w-fit -mt-2"
              arrow={false}
              color="white"
              enterable
              trigger="click"
              content={
                <div className="flex gap-3 rounded-md ">
                  <div className="flex h-fit w-[55px] flex-col gap-2 py-2 ">
                    <div
                      className="w-full cursor-pointer px-2 font-poppins text-xs text-gray-900 hover:bg-gray-50"
                      onClick={() => {
                        setIsEditMode(true);
                      }}
                    >
                      Edit
                    </div>
                    <div
                      className="cursor-pointer px-2 font-poppins text-xs text-[#C40000] hover:bg-gray-50"
                      onClick={() => {
                        onDelete ? onDelete(activity) : handleDelete();
                      }}
                    >
                      Delete
                    </div>
                  </div>
                </div>
              }
            >
              <span className="grid place-items-center p-[6px]">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 12C8.13261 12 8.25979 11.9473 8.35355 11.8536C8.44732 11.7598 8.5 11.6326 8.5 11.5C8.5 11.3674 8.44732 11.2402 8.35355 11.1464C8.25979 11.0527 8.13261 11 8 11C7.86739 11 7.74021 11.0527 7.64645 11.1464C7.55268 11.2402 7.5 11.3674 7.5 11.5C7.5 11.6326 7.55268 11.7598 7.64645 11.8536C7.74021 11.9473 7.86739 12 8 12ZM8 8.5C8.13261 8.5 8.25979 8.44732 8.35355 8.35355C8.44732 8.25979 8.5 8.13261 8.5 8C8.5 7.86739 8.44732 7.74021 8.35355 7.64645C8.25979 7.55268 8.13261 7.5 8 7.5C7.86739 7.5 7.74021 7.55268 7.64645 7.64645C7.55268 7.74021 7.5 7.86739 7.5 8C7.5 8.13261 7.55268 8.25979 7.64645 8.35355C7.74021 8.44732 7.86739 8.5 8 8.5ZM8 5C8.13261 5 8.25979 4.94732 8.35355 4.85355C8.44732 4.75979 8.5 4.63261 8.5 4.5C8.5 4.36739 8.44732 4.24021 8.35355 4.14645C8.25979 4.05268 8.13261 4 8 4C7.86739 4 7.74021 4.05268 7.64645 4.14645C7.55268 4.24021 7.5 4.36739 7.5 4.5C7.5 4.63261 7.55268 4.75979 7.64645 4.85355C7.74021 4.94732 7.86739 5 8 5Z"
                    stroke="black"
                    strokeWidth="1.25"
                  />
                </svg>
              </span>
            </TooltipWrapper>
          )}
        </div>
      </div>
      <div className="w-full">
        <div className="flex items-start gap-2">
          <h5 className="font-poppins text-sm !text-black">{convertToReadable(comment)}</h5>
        </div>
      </div>
    </div>
  );
}
