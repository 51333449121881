import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { Divider, Steps } from "rsuite";
import {
  getOnboardingStatus,
  selectBasicInformation,
  updateOnboardingAsync,
} from "../../../app/onboardingSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import IMG_ADMIN_VERIFY from "../../../assets/home/admin-verify-white.svg";
import IMG_ADMIN_EMAIL from "../../../assets/home/member-email.svg";
import IMG_ADMIN_ADD from "../../../assets/home/member-request.svg";
import ICON_FILE from "../../../assets/images/file-upload.svg";
import ICON_VERIFY from "../../../assets/onboarding/org-verify.svg";
import FakeProgressBar from "../../../components/FakeProgressBar";
import GibooToast from "../../../components/GibooToast";
import NPOSelectItem from "../../../components/NPOSelectItem";
import OnboardingSkipButton from "../../../components/OnboardingSkip";
import PlainButton from "../../../components/PlainButton";
import PrimaryButton from "../../../components/PrimaryButton";
import RoundButton from "../../../components/RoundButton";
import Spacer from "../../../components/Spacer";
import { TooltipWrapper } from "../../../components/TooltipWrapper";
import SearchableDropdown from "../../../components/dropdown/SearchableDropdown";
import MemoizedDropArea from "../../../components/input/FileDropArea";
import Button from "../../../components/tailwind/Button";
import useEINFromSearchParams from "../../../hooks/useEINFromSearchParams";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import useLocalStorage from "../../../hooks/useLocalstorage";
import useOrgID from "../../../hooks/useOrgID";
import useRequestOrgVerification from "../../../hooks/useRequestOrgVerification";
import useUser from "../../../hooks/useUser";
import useVerifiedOrg from "../../../hooks/useVerifiedOrg";
import { Information, MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import { uploadFileService } from "../../../services/file-upload/upload.service";
import { ILocation } from "../../../types/location";
import { npoAutoComplete } from "../../../utils/autoComplete";
import { toBytesFormat, toEinString } from "../../../utils/formatHelper";
import { getVerificationFileKey } from "../../../utils/media";
import OnboardingBack from "./OnboardingBack";

const SUPPORT_EMAIL = "support@giboo.com";
export interface INPO {
  _id?: string;
  name: string;
  ein: string;
  address: string;
  website?: string;
  location?: ILocation;
}
function FindNPO() {
  const mxEvent = useGibooMixpanel("onboarding");
  const dispatch = useAppDispatch();
  const { type } = useAppSelector((state) => getOnboardingStatus(state));
  const { npo_id, npo_name, npo_ein, npo_location } = useAppSelector((state) =>
    selectBasicInformation(state),
  );
  const { npo, isLoading: isCidContextLoading } = useEINFromSearchParams();
  const ref = useRef<HTMLInputElement>(null);
  const [selectedNPO, setSelectedNPO] = useState<INPO>(
    type === 1 && npo_id
      ? {
          _id: npo_id || "",
          name: npo_name || "",
          ein: npo_ein || "",
          address: npo_location || "",
        }
      : { _id: undefined, name: "", ein: "", address: "" },
  );
  useEffect(() => {
    if (!selectedNPO?._id && npo) {
      setSelectedNPO({ _id: npo._id, name: npo.name, ein: npo.ein, address: npo.address });
      autoSaveNpo();
    }
  }, [npo, isCidContextLoading]);
  const [query, setQuery] = useState<string>("");
  const [tried, setTried] = useState<boolean>(false);
  const havingTrouble = tried && selectedNPO.ein.length === 0;
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [allowScroll, setAllowScroll] = useState(true);
  const { value: cid, deleteThis: deleteCid } = useLocalStorage("CID");
  useEffect(() => {
    if (!allowScroll) return;
    if (currentStep === 2) return;
    if (npo_id && selectedNPO.name) setCurrentStep(2);
  }, [npo_id, selectedNPO, currentStep, allowScroll]);

  useEffect(() => {
    setTimeout(() => setTried(true), 10_000);
  }, []);
  const goWithFoundNPO = (goNext = true) => {
    mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
      field: Information.ORG_NAME,
      npoId: selectedNPO._id,
      organizationName: selectedNPO.name,
    });
    dispatch(
      updateOnboardingAsync(
        {
          npo_id: selectedNPO._id,
          type: 1,
          step: 1,
          ...(npo_id !== selectedNPO._id
            ? {
                tax_exemption_requested: undefined,
                operating_budget_requested: undefined,
                n_employee_requested: undefined,
                n_volunteer_requested: undefined,
                mission_requested: undefined,
                focus_area_requested: undefined,
                beneficiary_requested: undefined,
                program_requested: undefined,
                service_loc_requested: undefined,
              }
            : {}),

          npo_ein_requested: selectedNPO?.ein || "",
          npo_name_requested: undefined,
          address1: undefined,
          address2: undefined,
          city: undefined,
          state_id: undefined,
          zipcode: undefined,
          country: undefined,
          service_loc_requested: [],
        },
        goNext ? +1 : 0,
      ),
    );
  };
  const autoSaveNpo = useCallback(() => {
    dispatch(
      updateOnboardingAsync(
        {
          npo_id: selectedNPO._id,
          type: 1,
          step: 1,
          ...(npo_id !== selectedNPO._id
            ? {
                tax_exemption_requested: undefined,
                operating_budget_requested: undefined,
                n_employee_requested: undefined,
                n_volunteer_requested: undefined,
                mission_requested: undefined,
                focus_area_requested: undefined,
                beneficiary_requested: undefined,
                program_requested: undefined,
                service_loc_requested: undefined,
              }
            : {}),

          npo_ein_requested: selectedNPO?.ein || "",
          npo_name_requested: undefined,
          address1: undefined,
          address2: undefined,
          city: undefined,
          state_id: undefined,
          zipcode: undefined,
          country: undefined,
          service_loc_requested: [],
        },
        0,
      ),
    );
  }, []);
  const goToAddNPO = () => {
    mxEvent(MIXPANEL_EVENTS_V2.onboarding.search_org.failed);
    dispatch(
      updateOnboardingAsync({
        npo_id: "",
        npo: undefined,
        npo_profile: undefined,
        npo_uid: undefined,
        type: 2,
        step: 1,
        ...(type === 1
          ? {
              tax_exemption_requested: undefined,
              operating_budget_requested: undefined,
              n_employee_requested: undefined,
              n_volunteer_requested: undefined,
              mission_requested: undefined,
              focus_area_requested: undefined,
              beneficiary_requested: undefined,
              program_requested: undefined,
              service_loc_requested: undefined,
              npo_ein_requested: undefined,
              city: undefined,
              address1: undefined,
              zipcode: undefined,
              state_id: undefined,
              website: undefined,
            }
          : {}),
      }),
    );
  };
  const [isVerifyInitiated, setIsVerifyInitiated] = useState<boolean>(false);
  const [isReqInitiated, setIsReqInitiated] = useState<boolean>(false);
  const renderStep1 = () => {
    return (
      <>
        <h4 className="onboarding-header pt-[3px]">What is the name of your organization?</h4>
        <p className="onboarding-sub-header mt-3">
          To get started, enter the EIN or name of your nonprofit organization. <br />
          Once we have that information, we can proceed with the verification process.
        </p>
        <div className="onboarding-body onboarding-width-sm !mt-[40px] !gap-0">
          <SearchableDropdown<INPO>
            value={selectedNPO}
            controlledValue
            id="npo"
            color="gray"
            query={query}
            keepPreviousQueryOnBlur
            setQuery={setQuery}
            reprItem={(npo: INPO) => npo?.name}
            autoCompleteFunction={async (query: string, match_simliar?: boolean) => {
              return npoAutoComplete(query, match_simliar).then((res) => {
                setTried(true);
                if (query)
                  mxEvent(MIXPANEL_EVENTS_V2.onboarding.search_org.started, { textQuery: query });
                return res;
              });
            }}
            useSimilarSearch
            defaultValue={selectedNPO}
            getKey={(npo) => npo.ein}
            renderItem={(npo) => (
              <NPOSelectItem name={npo.name} address={npo.address} ein={npo.ein} />
            )}
            maxHeight="311px"
            addItem={(item) => {
              mxEvent(MIXPANEL_EVENTS_V2.onboarding.search_org.successful, {
                textQuery: query,
                npoId: item._id,
                organizationName: item.name,
              });
              setSelectedNPO(item);
            }}
            placeholder="Enter organization name or EIN"
            float
            icon
            spinner
            spinnerBackgroundColor="bg-gray-50"
            clearQuery={false}
            size="xl"
            showClear={selectedNPO._id ? true : false}
            onClear={() => {
              setQuery("");
              setSelectedNPO({ _id: undefined, name: "", ein: "", address: "" });
              setTried(true);
              setCurrentStep(0);
              cid && deleteCid();
            }}
          />
          {selectedNPO.name && (
            <div className="flex justify-between pb-5 pt-10">
              <div className="grow">
                <div className="flex justify-start">
                  <div style={{ width: "50px", textAlign: "center" }}>
                    <i className="fa fa-location-dot purple" />
                  </div>
                  <div id="info-address">{selectedNPO.address}</div>
                </div>
                <div className="flex justify-start pt-2">
                  <div style={{ width: "50px", textAlign: "center" }} className="purple">
                    EIN
                  </div>
                  <div id="info-ein">{toEinString(selectedNPO.ein)}</div>
                </div>
              </div>
              {/* <PlainButton
            id="btn-cancel"
            leftIconClass={<img src={closeTag} alt="icon" className="h-[17px] w-[17px]" />}
            handleOnClick={() => {
              setSelectedNPO({ _id: undefined, name: "", ein: "", address: "" });
              setTried(true);
            }}
          /> */}
            </div>
          )}
          {!selectedNPO.name && (havingTrouble || query.length > 0) && (
            <div className="flex flex-col items-start rounded bg-white py-4 shadow">
              {query.length > 0 && (
                <h5 className="px-5">
                  Searching through 1.8 million NPOs to find your organization. Please wait a moment
                  for the results to appear.
                </h5>
              )}
              {havingTrouble && (
                <>
                  <div className="mt-5 rounded px-5">
                    <h5 className="mb-1">
                      Are you having trouble finding a nonprofit organization to associate with?
                    </h5>
                  </div>
                  <PlainButton
                    className="my-3"
                    handleOnClick={goToAddNPO}
                    id="btn-next"
                    underline
                    color="purple"
                    label={"Enter your organization information"}
                  />
                </>
              )}
            </div>
          )}
          <div className="mt-10 inline-flex items-center gap-2">
            {currentStep === 0 && (
              <OnboardingBack
                outline
                data={{
                  npo_id: undefined,
                  npo: undefined,
                  npo_profile: undefined,
                  npo_ein_requested: undefined,
                }}
              />
            )}
            {currentStep === 0 && (
              <PrimaryButton
                className="w-fit"
                size="md"
                // handleOnClick={goWithFoundNPO}
                handleOnClick={() => {
                  goWithFoundNPO(false);
                  setCurrentStep(2);
                }}
                id="btn-next2"
                label={"Next"}
                rightIconClass={<i className="gi gi-right-arrow" />}
                disabled={!selectedNPO.name}
              />
            )}
            {currentStep === 0 && (
              <OnboardingSkipButton
                disabled={!selectedNPO.name}
                onSkip={() => {
                  goWithFoundNPO(false); //save current npo
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  };
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value <= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };

  const renderComplete = () => {
    return (
      <div className="onboarding">
        <div className="onboarding-width-lg2 mt-2 flex flex-col !gap-[40px] border-b">
          <h4 className="onboarding-header">
            Amazing! You&apos;ve successfully initiated the organization verification process.{" "}
          </h4>
          <div className="grid h-[209px] w-[564px] place-items-center rounded border border-green-500 bg-green-50 px-[100px] py-7">
            <div className="grid w-full place-items-center gap-6 text-center">
              <img src={ICON_VERIFY} className="h-[60px] w-[60px]" />
              <h6 className="text-base font-semibold text-green-900">Submitted successfully!</h6>
              <p className="font-poppins text-sm">
                This will be confirmed within{" "}
                <span className="font-poppins font-semibold text-green-900">24-48 hours.</span>
              </p>
            </div>
          </div>
          <div className=" mb-10 inline-flex items-center gap-2">
            <OnboardingBack
              outline
              noAction
              handleOnClick={() => {
                setIsVerifyInitiated(false);
              }}
            />
            <PrimaryButton
              className="w-fit"
              size="md"
              handleOnClick={() => goWithFoundNPO()}
              id="btn-next2"
              label={"Next"}
              rightIconClass={<i className="gi gi-right-arrow" />}
              disabled={!selectedNPO.name}
            />
          </div>
        </div>
      </div>
    );
  };
  const renderRequestComplete = () => {
    return (
      <div className="onboarding">
        <div className="onboarding-width-lg2 mt-2 flex flex-col !gap-[40px] border-b">
          <h4 className="onboarding-header">
            Amazing! You&apos;ve successfully requested access to Giboo.
          </h4>
          <div className="grid h-[230px] w-[564px] place-items-center rounded border border-green-500 bg-green-50 px-[100px] py-7">
            <div className="grid w-full place-items-center gap-6 text-center">
              <img src={ICON_VERIFY} className="h-[60px] w-[60px]" />
              <h6 className="text-base font-semibold text-green-900">Requested successfully!</h6>
              <p className="font-poppins text-sm">
                Once your organization admin approves you will be able to have full access to Giboo.
              </p>
            </div>
          </div>
          <div className=" mb-10 inline-flex items-center gap-2">
            <OnboardingBack
              outline
              noAction
              handleOnClick={() => {
                setIsReqInitiated(false);
              }}
            />
            <PrimaryButton
              className="w-fit"
              size="md"
              handleOnClick={() => goWithFoundNPO()}
              id="btn-next2"
              label={"Next"}
              rightIconClass={<i className="gi gi-right-arrow" />}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  };
  if (isVerifyInitiated) {
    return renderComplete();
  }
  if (isReqInitiated) {
    return renderRequestComplete();
  }

  return (
    <div className="onboarding flex flex-col">
      {currentStep > 1 ? (
        <Steps current={currentStep} vertical>
          <Steps.Item
            title={false}
            className="!-ml-[40px] !mt-0"
            icon={renderDot(1)}
            description={renderStep1()}
          />
          <Steps.Item
            icon={renderDot(2)}
            className="!-ml-[40px]"
            description={
              <VerifyNpo
                selectedNPO={selectedNPO}
                type={type}
                goWithFoundNPO={() => goWithFoundNPO()}
                onComplete={() => setIsVerifyInitiated(true)}
                onReqAccess={() => setIsReqInitiated(true)}
                reset={() => {
                  setAllowScroll(false);
                  setCurrentStep(0);
                }}
              />
            }
          />
        </Steps>
      ) : (
        renderStep1()
      )}
    </div>
  );
}
export default FindNPO;

function VerifyNpo({
  selectedNPO,
  type,
  goWithFoundNPO,
  onComplete,
  onReqAccess,
  reset,
}: {
  selectedNPO: INPO;
  type: number | undefined;
  goWithFoundNPO: () => void;
  onComplete: () => void;
  onReqAccess: () => void;
  reset: () => void;
}) {
  const mxEvent = useGibooMixpanel("onboarding", true);
  //step2
  const org_id = useOrgID();
  const { isLoading: isCheckingExisting, data: hasVerifiedData } = useVerifiedOrg(selectedNPO._id);
  const { data, request } = useRequestOrgVerification(org_id);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<
    { file?: File; name: string; size: number; done?: boolean; key: string; failed?: boolean }[]
  >([]);
  const [loadingFileUpload, setLoadingFileUpload] = useState<boolean>(false);
  const [user] = useUser();
  useEffect(() => {
    if (data.files) setSelectedFile(data.files);
  }, [data]);
  useEffect(() => {
    if (isCheckingExisting) return;
    if (!isCheckingExisting) {
      scrollToElement("verify-npo", 140);
    }
  }, [isCheckingExisting]);
  const scrollToElement = (elementId: string, offset = 0): void => {
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      const elementTop = targetElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementTop - offset,
        behavior: "smooth",
      });
    }
  };
  const renderDescription = () => {
    return (
      <div className="flex flex-col gap-4">
        <h6 className="font-poppins text-xs text-gray-800">
          After verification, you will have access to the full Giboo features.
        </h6>
        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            1. Why is organization verification important?
          </h4>
          <p className="font-poppins text-xs text-gray-800">
            Verification helps in the prevention of fraudulent activities or misrepresentation,
            protecting both your organizations and funders from potential scams. Help us build a
            trustworthy Giboo community!
          </p>
        </div>

        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            2. How long does the verification process take?
          </h4>
          <p className="font-poppins text-xs text-gray-800">
            Verification typically takes 24-48 hours, and we&apos;ll notify you as soon as it&apos;s
            complete. Your information and privacy are safe with us, as we use these documents
            exclusively for verification purposes.
          </p>
        </div>
      </div>
    );
  };
  const handleUpload = (file: File) => {
    if (!selectedNPO) return;
    setLoadingFileUpload(true);
    selectedNPO._id &&
      uploadFileService(file, getVerificationFileKey(selectedNPO._id), ["image", "doc"])
        .then((res) => {
          const key = res as string;
          const success = key ? true : false;
          mxEvent(
            success
              ? MIXPANEL_EVENTS_V2.org_verification.file.successful
              : MIXPANEL_EVENTS_V2.org_verification.file.failed,
            {
              fileName: file.name,
              fileSize: file.size,
              error: "failed to upload to s3",
            },
          );
          setSelectedFile((prev) => {
            const index = prev.findIndex((f) => f.name === file.name && f.size === file.size);
            if (index >= 0) {
              return [
                ...prev.slice(0, index),
                { ...prev[index], done: success, failed: !success, key: success ? key : "" },
                ...prev.slice(index + 1),
              ];
            } else return prev;
          });
        })
        .catch(() => {
          mxEvent(MIXPANEL_EVENTS_V2.org_verification.file.failed, {
            fileName: file.name,
            fileSize: file.size,
            error: "failed to upload to s3",
          });
          setSelectedFile((prev) => {
            const index = prev.findIndex((f) => f.name === file.name && f.size === file.size);
            if (index >= 0) {
              return [
                ...prev.slice(0, index),
                { ...prev[index], done: false, failed: true, key: "" },
                ...prev.slice(index + 1),
              ];
            } else return prev;
          });
        })
        .finally(() => setLoadingFileUpload(false));
  };
  const renderFileUpload = () => {
    return (
      <div className="min-h-[192px]  max-w-[564px] rounded bg-gray-50">
        <MemoizedDropArea
          id={"tax file"}
          formats={[".pdf", ".png", ".jpeg", ".jpg", ".doc", ".docx"]}
          containerClass="min-h-[192px] !border-solid border border-gray-200 hover:!border-[2px]  hover:!border-purple-500 hover:!bg-[#FBFAFF]"
          placeholder={
            <div className="grid w-full place-items-center text-center">
              <span>
                <img src={ICON_FILE} className="h-full w-full" />
              </span>
              <h5 className="mt-3 font-poppins text-sm text-gray-700">
                <span className="font-semibold text-purple-500">Click to upload </span>
                or drag and drop here
              </h5>
              <p className="m-2 font-poppins text-sm text-gray-700">
                We support JPEGs, PNGs, PDFs and DOCs under 10MB
              </p>
            </div>
          }
          sizeLimit={10}
          showFileSelection={false}
          onDrop={(arg: File) => {
            setSelectedFile((prev) => [
              ...prev.filter((f) => f.name !== arg.name || f.size !== arg.size),
              { file: arg, name: arg.name, size: arg.size, done: false, key: "" },
            ]);
            handleUpload(arg);
          }}
          resetAlways
        />
      </div>
    );
  };
  const renderSelectedFiles = () => {
    return (
      <div
        className={classNames(
          "onboarding-width-sm flex flex-col gap-4 ",
          selectedFile.length > 0 && "mt-4",
        )}
      >
        {selectedFile.map((item, index) => {
          return (
            <div key={index} className="grid w-full grid-cols-5 items-center gap-2">
              <div
                className={classNames(
                  "col-span-4 grid grid-cols-4 items-center gap-2 rounded px-2 py-1",
                  item.failed ? "bg-purple-50" : "",
                )}
              >
                <span className="col-span-3 truncate whitespace-nowrap">{item.name}</span>
                <div className="col-span-1 flex justify-end">
                  {item.failed ? (
                    <span className="text-sm font-semibold text-purple-500">Failed</span>
                  ) : item.done ? (
                    <span className="text-sm">{toBytesFormat(item.size)}</span>
                  ) : (
                    <FakeProgressBar estimatedSeconds={3} done={item.done} />
                  )}
                </div>
              </div>
              <div className="col-span-1 flex gap-2">
                <RoundButton
                  id={`btn-remove-item-${index}`}
                  icon="gi-md gi-x"
                  type="tertiary"
                  size="sm"
                  handleOnClick={() =>
                    setSelectedFile((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
                  }
                />
                {item.failed && item.file && (
                  <PlainButton
                    handleOnClick={() => {
                      if (item.file) handleUpload(item.file);
                    }}
                    id={`btn-retry-item-${index}`}
                    className="!p-0 !text-sm"
                    color="purple"
                    label="Retry"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  if (isCheckingExisting) {
    return (
      <div className="onboarding-width-md2 grid min-h-[150px] place-items-center">
        <div
          className="m-5  inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-purple-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    );
  }
  if (hasVerifiedData && !isCheckingExisting) {
    return (
      <div id="verify-npo" className="onboarding-width-md2 mt-2 flex flex-col gap-[40px]">
        <h4 className="onboarding-header">{selectedNPO.name} already exists in Giboo</h4>
        <div className="grid min-h-[100px] w-[286px] gap-3 rounded-[20px] bg-gradient-to-r from-pink-500 via-purple-600 to-blue-500 p-5 shadow-[0_1px_2px_0px_rgba(0,0,0,0.30)]">
          <div className="inline-flex items-center gap-1">
            <span>
              <img src={IMG_ADMIN_VERIFY} className="h-5 w-5" />
            </span>
            <h6 className="font-poppins text-xs font-normal text-white">Verified organization</h6>
          </div>
          <h5 className="font-poppins text-xl font-semibold text-white">{selectedNPO.name}</h5>
        </div>
        <div className="flex items-center gap-3">
          <Button
            id="member-request"
            className="!h-[37px] !text-sm"
            prependIcon={<img src={IMG_ADMIN_ADD} className="h-5 w-5" />}
            color="purple"
            label="Request as a member"
            handleOnClick={() => {
              // console.log("verify admin");
              onReqAccess?.();
            }}
          />
          <Button
            id="support"
            className="!h-[37px] !text-sm"
            prependIcon={<img src={IMG_ADMIN_EMAIL} className="h-5 w-5" />}
            color="purple"
            outline
            label="Contact Giboo for support"
            handleOnClick={() => {
              window.open(`mailto:${SUPPORT_EMAIL}`, "_blank");
            }}
          />
        </div>
        <Divider className="!mt-0 mb-[60px]" />
        <Spacer height="300px" />
      </div>
    );
  }
  return (
    <div id="verify-npo" className="onboarding-width-md2 mt-2">
      <h4 className="onboarding-header">Now, let’s get your organization verified.</h4>
      <h4 className="onboarding-header mt-2">
        Upload essential documents to verify your organization.
      </h4>
      <div className="mb-5 mt-10">
        <p className="font-poppins text-sm text-gray-700">
          Upload at least one supporting document.
        </p>
        <div className="font-poppins text-sm font-semibold text-gray-900">
          IRS tax-exempt letter and incorporation letter are examples of supporting documents.
        </div>
      </div>
      {renderFileUpload()}
      {renderSelectedFiles()}
      <div className="my-10 inline-flex items-center gap-2">
        <OnboardingBack
          outline
          handleOnClick={() => {
            reset();
          }}
          noAction
        />
        <Button
          id="btn-done"
          className="!h-[37px] w-fit !text-sm"
          color="purple"
          label="Next"
          appendIcon={<i className="gi gi-right-arrow" />}
          size="md"
          loading={isLoading}
          disabled={loadingFileUpload || selectedFile.filter((i) => i.done).length === 0}
          handleOnClick={() => {
            if (isLoading || !selectedNPO || !user || !type) return;
            setIsLoading(true);
            request({
              ...user,
              npo_id: selectedNPO._id || "",
              npo_name: selectedNPO.name || "",
              type: type,
              origin: window.location.origin,
              files: selectedFile.filter((f) => f.done && f.key),
            })
              .then(() => {
                mxEvent(MIXPANEL_EVENTS_V2.org_verification[""].successful, {
                  fileName: selectedFile.map((f) => f.name).join(", "),
                });
                onComplete();
                // GibooToast({
                //   type: "success",
                //   message: `Successfully requested`,
                // });
              })
              .catch(() => {
                mxEvent(MIXPANEL_EVENTS_V2.org_verification[""].failed, {
                  error: "api error",
                });
                GibooToast({
                  type: "failure",
                  message: `We apologize for the inconvenience., please try again`,
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
        <TooltipWrapper
          WrapperClass="max-w-[304px] !p-4 !rounded-[4px]"
          placements="rightStart"
          content={`Skipping this step will limit your access to Giboo's outreach services including
              sending letters to funders or making your project publicly available. You can still
              explore and enjoy our other services!`}
        >
          <div
            id="btn-skip"
            className="grid h-[37px] w-[62px] cursor-pointer place-items-center rounded p-1 hover:bg-purple-50"
            onClick={() => {
              mxEvent(MIXPANEL_EVENTS_V2.org_verification[""].failed, {
                skip: true,
              });
              goWithFoundNPO();
            }}
          >
            <h5 className="text-sm font-normal text-purple-500 hover:font-semibold">Skip</h5>
          </div>
        </TooltipWrapper>
      </div>
      <Divider className="!mt-0 mb-[60px]" />
      {renderDescription()}
    </div>
  );
}
