import React, { useCallback, useEffect, useRef, useState } from "react";
import Spacer from "../../../components/Spacer";
import Button from "../../../components/tailwind/Button";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import BusinessModel from "./pages/BusinessModel";
import BusinessRevenueModel from "./pages/BusinessRevenueModel";
import TotalContribution from "./pages/TotalContribution";
import RevenueAndExpense from "./pages/RevenueAndExpense";
import RevenueStream from "./pages/RevenueStream";
import RevenueExpenseStream from "./pages/RevenueExpenseStream";
import OperatingExpenses from "./pages/OperatingExpenses";
import BalanceSheetNeeds from "./pages/BalanceSheetNeeds";
import FinancialAssetsCategories from "./pages/FinancialAssetsCategories";
import MonthOfLiquid from "./pages/MonthOfLiquid";
import PlanningAhead from "./pages/PlanningAhead";
import useOrgDataForNPODashboard from "../../../hooks/useOrgDataForNPODashboard";
import useOrgProfile from "../../../hooks/useOrgProfile";
import useNPOIRSProfile from "../../../hooks/useNPOIRSProfile";
import Spinner from "../../../components/Spinner";
import UploadIRSModal from "../../../components/UploadIRSModal";
import IMG_IN_PROGRESS from "../../../assets/financial/in_progress.svg";
import useOrg from "../../../hooks/useOrg";
import useOnboardingData from "../../../hooks/useOnboarding";
import useUploadIRS from "../../../hooks/useUploadIRS";
import UploadIRS from "../../../components/UploadIRS";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import BackButton from "../../../components/BackButton";

const SIDE_MENU_CONSTANT = [
  {
    title: "I. Business model",
    value: 0,
    subMenu: [
      { title: "Business (revenue) model", value: 1 },
      { title: "Total contributions", value: 2 },
    ],
  },
  {
    title: "II. Revenue and expense",
    value: 3,
    subMenu: [
      { title: "Revenue stream", value: 4 },
      { title: "Revenue & expense stream", value: 5 },
      { title: "Operating expenses", value: 6 },
    ],
  },
  {
    title: "III. Balance sheet needs",
    value: 7,
    subMenu: [
      { title: "Financial assets categories", value: 8 },
      { title: "Working capital: Month of liquid", value: 9 },
    ],
  },
  {
    title: "IV. Planning ahead for fundraising needs",
    value: 10,
  },
];
export const FAKE_LOADING_TIME = 2800;
export default function FinancialDetail() {
  const mxEvent = useGibooMixpanel(undefined, true); // this is a navigated page and location.state already has 'from_for_mixpanel', so do not set from_for_mixpanel
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const location = useLocation();
  const { npo_id, org_id, hasWritePermission } = useOrgDataForNPODashboard(undefined, true);
  const { data: uploaded, isLoading: isUploadedFileLoading, revalidate } = useUploadIRS(org_id);
  const { isLoading: IRSProfileLoading, data: IRSProfile } = useNPOIRSProfile(npo_id);
  const { data, update: profileUpdate, isLoading } = useOrgProfile(org_id);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [tempIndex, setTempIndex] = useState<number>(0);
  const navigate = useNavigate();
  const previousItemIndexRef = useRef<number | null>(null);
  const clickedNextRef = useRef<boolean | null>(null);
  const [onboardingData] = useOnboardingData();
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.financial[""].started);
  }, []);
  useEffect(() => {
    if (isLoading) return;
    setSelectedItem(data.financial_saved_index || 0);
    setTempIndex(data.financial_saved_index || 0);
  }, [data, isLoading]);
  useEffect(() => {
    if (isLoading) return;
    scrollToTop();
  }, [isLoading, selectedItem]);
  const handleBack = () => {
    if (selectedItem > 0 && hasWritePermission) {
      setSelectedItem(selectedItem - 1);
      profileUpdate({ financial_saved_index: selectedItem - 1 });
      setTempIndex(selectedItem - 1);
      if (clickedNextRef.current) {
        previousItemIndexRef.current = selectedItem;
        clickedNextRef.current = false;
      }
    }
  };
  const handleNext = () => {
    if (selectedItem >= 0 && hasWritePermission) {
      previousItemIndexRef.current = selectedItem;
      setSelectedItem(selectedItem + 1);
      profileUpdate({ financial_saved_index: selectedItem + 1 });
      setTempIndex(selectedItem + 1);
    }
  };
  const scrollToTop = () => {
    const element = document.getElementById("financial-scroll-container");
    if (element) {
      element.scrollTo(0, 0);
    }
  };
  const isBack =
    previousItemIndexRef.current !== null && previousItemIndexRef.current >= selectedItem;

  const renderItem = useCallback(() => {
    return [
      <BusinessModel
        key={"businessmodel"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        isBack={isBack}
        hasWritePermission={hasWritePermission}
      />,
      <BusinessRevenueModel
        key={"revenuemodel"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <TotalContribution
        key={"totalcontribution"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <RevenueAndExpense
        key={"revenueexpense"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        isBack={isBack}
      />,
      <RevenueStream
        key={"revenuestream"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <RevenueExpenseStream
        key={"revenueexpensestream"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <OperatingExpenses
        key={"operatingexpenses"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <BalanceSheetNeeds
        key={"balancesheet"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        isBack={isBack}
      />,
      <FinancialAssetsCategories
        key={"asset"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <MonthOfLiquid
        key={"mol"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <PlanningAhead
        key={"planing"}
        onBack={handleBack}
        onNext={() => {
          profileUpdate({ financial_saved_index: selectedItem, financial_completed: true });
        }}
        npo_id={npo_id}
        org_id={org_id}
      />,
    ][selectedItem];
  }, [selectedItem, org_id, npo_id, isBack]);
  const renderRightContent = () => {
    return (
      <div
        className="h-full w-full overflow-y-scroll bg-gray-50 px-[60px] py-[30px]"
        id="financial-scroll-container"
      >
        <div className="mb-5">
          <BackButton onClick={() => (location?.state?.showBack ? navigate(-2) : navigate("/"))} />
        </div>
        {renderItem()}
      </div>
    );
  };

  const renderNoData = () => {
    const renderOrg2 = () => {
      return (
        <>
          <p className="font-semibold">
            It appears that we do not have your organization&apos;s IRS 990 data in our records.{" "}
          </p>
          <div className="my-12">
            <img src={IMG_IN_PROGRESS} alt="in-progress" />
          </div>
          <div className="flex max-w-[684px] flex-col items-center gap-4 pb-6">
            <h6 className="text-center text-gray-800">
              Sorry, we are actively working on supporting financial data for organizations without
              IRS 990 data.
            </h6>
            <h6 className="text-center text-gray-800">
              We will promptly share updates once this development is complete.
            </h6>
            <h6 className="text-center text-gray-800">Thank you for your patience.</h6>
          </div>
        </>
      );
    };
    return (
      <div className="flex w-full flex-col">
        <div className="flex h-full max-h-[636px] w-full max-w-[800px] flex-col items-center self-center rounded border border-gray-300 bg-white pt-10">
          {onboardingData.type === 2 ? (
            renderOrg2()
          ) : uploaded && uploaded.files.length > 0 ? (
            <>
              <p className="font-semibold">Thank you for submitting the file!</p>
              <div className="my-12">
                <img src={IMG_IN_PROGRESS} alt="in-progress" />
              </div>
              <div className="flex max-w-[440px] flex-col items-center gap-4 pb-6">
                <h6 className="text-center text-gray-800">
                  Following a thorough review of your documents, we will promptly reach out to
                  initiate the process of analyzing your organization&apos;s financial data. Expect
                  to hear from us shortly with valuable insights.
                </h6>
                <h6 className="text-center text-gray-800">Thank you for your patience.</h6>
                <UploadIRS />
                <Button
                  id="btn-open-upload"
                  size="sm"
                  handleOnClick={() => setOpenUpload(true)}
                  prependIcon={<i className="gi-sm gi-upload" />}
                  className="self-center !px-2"
                  label={"Upload more files"}
                />
              </div>
            </>
          ) : (
            <>
              <p className="font-semibold">
                It appears that we do not have your organization&apos;s IRS 990 data in our records.
              </p>
              <div className="my-36 flex max-w-[480px] grow flex-col gap-8 rounded border border-gray-300 bg-gray-50 p-5">
                <h6>
                  To access Giboo&apos;s financial data analysis, please provide the necessary
                  documents by uploading your organization&apos;s IRS 990 forms.
                </h6>
                <h6>
                  This will enable us to facilitate a comprehensive evaluation of your
                  organization&apos;s financial information.
                </h6>
                <Button
                  id="btn-open-upload"
                  size="sm"
                  handleOnClick={() => setOpenUpload(true)}
                  prependIcon={<i className="gi-sm gi-upload" />}
                  className="self-start !px-2"
                  label={"Upload files"}
                />
              </div>
            </>
          )}
          <div className="flex w-full items-center justify-center gap-3 border-t border-gray-300 py-4">
            <Button id="btn-home" handleOnClick={() => navigate("/")} label="Back to home" />
          </div>
        </div>
      </div>
    );
  };

  const renderLeftContent = () => {
    return (
      <div className="h-full w-full bg-white p-5">
        <div className="flex flex-col gap-2">
          <h6 className="font-poppins text-xs text-gray-700">Progress</h6>
          <div className="inline-flex items-center gap-4">
            <div className="h-1 w-full max-w-[191px] rounded-full bg-purple-50">
              <div
                className="h-1 rounded-full bg-purple-500"
                style={{ width: `${selectedItem * 10}%` }}
              />
            </div>
            <h6 className="font-poppins text-xs text-gray-700"> {`${selectedItem * 10}%`}</h6>
          </div>
        </div>
        <Spacer height="40px" />
        <div className="grid grid-cols-[1px_auto]">
          <div className="h-full w-[1px] bg-gray-300">
            <div className="w-[1px] bg-purple-500" style={{ height: `${selectedItem * 10}%` }} />
          </div>
          <div className="flex flex-col gap-3">
            {SIDE_MENU_CONSTANT.map((item, index) => {
              const isHeaderSelected = item.value === selectedItem;
              return (
                <div key={item.value} className="flex w-full  flex-col gap-3 ">
                  <div
                    className={classNames(
                      "cursor-pointer pl-4  font-poppins text-xs font-semibold ",
                      isHeaderSelected
                        ? " text-purple-500 underline underline-offset-2"
                        : "text-gray-700",
                      !data.financial_completed &&
                        (item.value > tempIndex || !hasWritePermission) &&
                        "!cursor-not-allowed",
                    )}
                    onClick={() => {
                      if (!hasWritePermission) return;
                      if (data.financial_completed) {
                        setSelectedItem(item.value);
                      } else {
                        if (item.value <= tempIndex) {
                          setSelectedItem(item.value);
                        }
                      }
                    }}
                  >
                    {item.title}
                  </div>
                  {item?.subMenu?.map((item) => {
                    const isSelected = item.value === selectedItem;
                    return (
                      <div
                        key={item.value}
                        onClick={() => {
                          if (data.financial_completed) {
                            setSelectedItem(item.value);
                          } else {
                            if (item.value <= tempIndex) {
                              setSelectedItem(item.value);
                            }
                          }
                        }}
                        className={classNames(
                          "cursor-pointer pl-[35px] font-poppins text-xs",
                          isSelected
                            ? "font-semibold text-purple-500 underline underline-offset-2"
                            : "text-gray-700",
                          !data.financial_completed &&
                            (item.value > tempIndex || !hasWritePermission) &&
                            "!cursor-not-allowed",
                        )}
                      >
                        {item.title}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>

        <Spacer height="40px" />
        <Button
          label="Save & exit"
          id="save_exit"
          disabled={!hasWritePermission}
          handleOnClick={() => {
            if (!hasWritePermission) return;
            profileUpdate({ financial_saved_index: selectedItem });
            location?.state?.showBack ? navigate(-2) : navigate("/");
          }}
        />
      </div>
    );
  };
  return (
    <div className="h-[calc(100vh-58px)] w-full">
      {isLoading || IRSProfileLoading || isUploadedFileLoading ? (
        <Spinner wrapperClass="mt-12" />
      ) : IRSProfile.length === 0 ? (
        <div className="grid h-full place-items-center">
          {org_id && (
            <UploadIRSModal
              open={openUpload}
              setOpen={setOpenUpload}
              onCreation={() => revalidate()}
            />
          )}
          {renderNoData()}
        </div>
      ) : (
        <div className="grid  h-full grid-cols-[278px_1fr]">
          {/* Left content */}
          {renderLeftContent()}
          {/* Right content */}
          {renderRightContent()}
        </div>
      )}
    </div>
  );
}
