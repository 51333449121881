import { useCallback } from "react";
import {
  IProjectInteractionComment,
  IProjectInteractionCommentCreateRequest,
  IProjectInteractionCommentUpdateRequest,
} from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
import { deleteProjectInteractionComment } from "./useInteractionComments";
interface ProjectInteractionCommentState {
  data: IProjectInteractionComment;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  update: (
    req: IProjectInteractionCommentUpdateRequest,
  ) => Promise<IProjectInteractionComment | undefined>;
  deleteThis: () => Promise<boolean>;
  revalidate: () => Promise<IProjectInteractionComment | undefined>;
}
const defaultProjectInteractionComment: IProjectInteractionComment = {
  _id: "",
  interaction_id: "",
  updated_at: "",
  created_at: "",
  content: "",
  mention: [],
};
function useInteractionComment(comment_id?: string): ProjectInteractionCommentState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/interaction/comment/${comment_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectInteractionComment>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as IProjectInteractionComment);
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectInteractionComment>(
    comment_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const update = useCallback(
    async (req: IProjectInteractionCommentUpdateRequest) => {
      if (!data) return;
      const options = {
        optimisticData: { ...data, ...req },
        rollbackOnError: true,
      };
      return mutate<IProjectInteractionComment>(
        url,
        axios.put(url, req, config).then((response) => response.data),
        options,
      );
    },
    [url, data],
  );

  const deleteThis = useCallback(async () => {
    if (comment_id)
      return deleteProjectInteractionComment(comment_id).then((res) => {
        if (res && data)
          mutate(
            process.env.REACT_APP_API_URL +
              `/api/v2/projects/interaction/comments/${data?.interaction_id}`,
          );
        return res;
      });
    else return new Promise<boolean>((resolve, reject) => reject());
  }, [comment_id, data]);
  const revalidate = useCallback(() => mutate<IProjectInteractionComment>(url), [url]);
  return {
    data: data || defaultProjectInteractionComment,
    isLoading,
    error,
    isValidating,
    update,
    deleteThis,
    revalidate,
  };
}

export default useInteractionComment;
