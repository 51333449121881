import useOnboardingData from "../../hooks/useOnboarding";
import classNames from "classnames";
import { TooltipWrapper } from "../TooltipWrapper";
import { GrantSearchResult, SearchQuery, getSearchResultKey } from "../../types/search";
import RoundButton from "../RoundButton";
import {
  getGrantTitle,
  isActiveGrant,
  getAgencyName,
  isGrantEligible,
  reprGrantAmount,
  reprGrantDeadline,
  reprGrantEligibleNPOLocations,
  reprGrantFundingType,
  isGrantPrivate,
  ReprGrantServiceLocations,
} from "../../utils/grant";
import TagFunderSize from "../TagFunderSize";
import PhilanthropicInformationFlexWrap from "../PhilanthropicInformationFlexWrap";
import { getFunderHqLocation, renderRow } from "../../utils/funder";
import TagGrantEligible from "../TagGrantEligible";
import TagInvitationOnly from "../TagInvitationOnly";
import FuzzyMatchName from "../FuzzyMatchName";
import Fuse from "fuse.js";
import TagGrantPresent from "../TagGrantPresent";
import { ReactElement, useCallback, useState } from "react";
import StartFundRaisingModal from "../StartFundraisingModal";
import useGrantSearchResult from "../../hooks/search/useGrantSearchResult";
import useLazyLoad from "../../hooks/useLazyLoad";
import LoadingTaxonomy from "../LoadingTaxonomy";
import TagFunderType from "../TagFunderType";
interface GrantCardProps {
  wrapperClass?: string;
  grant: GrantSearchResult;
  query?: SearchQuery;
  noMatchTaxonomy?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  lazyLoadDelay?: number;
}
function GrantCard({
  grant,
  noMatchTaxonomy = false,
  query,
  onClick,
  lazyLoadDelay = 0,
  ...props
}: GrantCardProps) {
  const [onboardingData] = useOnboardingData();
  const key = getSearchResultKey(grant);
  const eligible = isGrantEligible(onboardingData.tax_exemption, query?.service_loc, grant);
  const deadline = reprGrantDeadline(grant);
  const { ready } = useLazyLoad(lazyLoadDelay);
  const { data: grantTaxonomy, isLoading } = useGrantSearchResult(
    !noMatchTaxonomy && ready ? grant._id : undefined,
    false,
    query,
  );
  return (
    <div
      key={key}
      className={classNames(
        "group flex h-fit w-full flex-col gap-2",
        onClick ? "cursor-pointer bg-white hover:bg-purple-50" : "",
        props.wrapperClass,
      )}
      {...(onClick ? { onClick } : {})}
    >
      <div className="mb-1 inline-flex items-center justify-between">
        <div className="flex flex-wrap  items-center gap-2">
          <TagFunderType type={grant.type} funder_type={grant.donor?.funder_type} />
          <TagGrantPresent grant={grant} />
        </div>
      </div>
      <div className="flex items-start justify-between">
        <p className="w-full grow whitespace-pre-line font-semibold text-purple-500 group-hover:underline">
          {getGrantTitle(grant)}
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <TagGrantEligible eligible={eligible} />
        {grant?.type === "grant-page" &&
          grant.donor &&
          grant.donor.does_not_accept_unsolicited !== true && (
            <TagInvitationOnly invitationOnly={false} />
          )}
      </div>
      <div className="flex flex-col gap-2">
        {/* {grant && getAgencyName(grant) ? (
          <>{renderRow(grant.donor_id ? "Funder" : "Agency", getAgencyName(grant))}</>
        ) : null}
        {grant.donor?.address ? (
          <>{renderRow("Address", grant.donor.address)}</>
        ) : null}
        {grant ? (
          <>
            {renderRow(
              "Service areas",
              <ReprGrantServiceLocations grant={grantTaxonomy} detail={false} />,
            )}
          </>
        ) : null}
        {grant.npo_loc_text || reprGrantEligibleNPOLocations(grant, false) ? (
          <>
            {renderRow(
              "Location of eligible nonprofits",
              reprGrantEligibleNPOLocations(grant, false),
            )}
          </>
        ) : null}
        {grant.funding_type ? <>{renderRow("Grant type", reprGrantFundingType(grant))}</> : null} */}
        {renderRow("Grant amount", reprGrantAmount(grant))}
        {deadline && renderRow("Deadline", deadline)}
      </div>
      {!noMatchTaxonomy &&
        (isLoading || (!ready && !grantTaxonomy) ? (
          <LoadingTaxonomy seed={grant.uid} />
        ) : (
          <PhilanthropicInformationFlexWrap
            containerClass={classNames(
              "mt-2 p-4 border rounded",
              "border-gray-300 group-hover:border-purple-200",
            )}
            id={key}
            focusArea={grantTaxonomy?.focus_area}
            beneficiary={grantTaxonomy?.beneficiary}
            program={grantTaxonomy?.program}
            location={grantTaxonomy?.service_loc}
            priorityFocusArea={query?.must_focus_area}
            priorityBeneficiary={query?.must_beneficiary}
            priorityProgram={query?.must_program}
            priorityLocation={query?.must_service_loc}
            tagSize="sm"
            tagMatched
            allowPastGrantSearch={grant.donor_id && grant.type === "grant-page" ? true : false}
            funder_id={grant.donor_id}
            searchQuery={query}
            enableGroupLocation
            predictedSize={3}
          />
        ))}
    </div>
  );
}
export default GrantCard;
