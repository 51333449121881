import classNames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import { Divider, Steps } from "rsuite";
import Button from "../../../../components/tailwind/Button";
import Spacer from "../../../../components/Spacer";
import IMG_EXAMPLE from "../../../../assets/diversity/final-info.svg";
import PlainButton from "../../../../components/PlainButton";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import GibooToast from "../../../../components/GibooToast";
import useTotalOrganizationMembersData from "../../../../hooks/useTotalOrganizationMembersData";
import DiversitySelector from "../component/DiversitySelector";
import NpoDiversityProfession from "../../dashboard/components/NpoDiversityProfession";
import CloseButton from "../../../../components/CloseButton";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import useDiversityRecommendedAction from "../../../../hooks/npo/useDiversityRecommendedAction";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import { useNavigate } from "react-router-dom";
import NpoDiversityEthnicity from "../../dashboard/components/NpoDiversityEthnicity";
import NpoDiversityGender from "../../dashboard/components/NpoDiversityGender";
import NpoDiversityGeneration from "../../dashboard/components/NpoDiversityGeneration";
import NpoDiversitySexualOrientation from "../../dashboard/components/NpoDiversitySexualOrientation";
import NpoDiversityDisability from "../../dashboard/components/NpoDiversityDisability";
import NpoDiversityReligion from "../../dashboard/components/NpoDiversityReligion";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { EditModeItem } from "../../../../components/AssessmentSetting";
import EthnicityInfo from "./EthnicityInfo";
import GenderInfo from "./GenderInfo";
import AgeInfo from "./AgeInfo";
import SexualOrientationInfo from "./SexualOrientationInfo";
import DisablityInfo from "./DisablityInfo";
import ReligionInfo from "./ReligionInfo";
import ProfessionInfo from "./ProfessionInfo";
import { MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";
export default function FinalInfo({
  onBack,
  onNext,
  npo_id,
  org_id,
  isBack,
  isEditMode,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  org_id?: string;
  isBack?: boolean;
  isEditMode?: boolean;
}) {
  const mxEvent = useGibooMixpanel(undefined, true);
  const [currentStep, setCurrentStep] = useState<number>(isEditMode ? 1 : isBack ? 2 : 0);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { data: assessment, isLoading: isAssessmentLoading } = useDiversityRecommendedAction(
    org_id,
    "diversity_assessment",
  );
  useEffect(() => {
    setTimeout(() => {
      setCurrentStep(1);
    }, 1500);
  }, []);

  useEffect(() => {
    if (isBack || isEditMode) {
      setTimeout(() => {
        scrollToElement("diversity-step-2");
      }, 500);
    }
  }, [isBack, isEditMode]);
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value >= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };

  const scrollToElement = (id: string) => {
    const element = document?.getElementById(id);
    const container = document.getElementById(
      isEditMode ? "edit-diversity-scroll-container" : "diversity-scroll-container",
    );
    if (element && container) {
      const elementTop = element.getBoundingClientRect().top + container.scrollTop;
      const adjustedOffset = elementTop - (isEditMode ? 150 : 120);

      container.scrollTo({ top: adjustedOffset, behavior: "smooth" });
    }
  };
  const renderStep1 = () => {
    return (
      <div
        className="mt-3 flex min-h-[274px] flex-col rounded border border-gray-300 bg-white p-5 "
        id="diversity-step-1"
      >
        <div className="flex justify-center">
          <img src={IMG_EXAMPLE} className="h-fit w-fit" alt="example" />
        </div>
      </div>
    );
  };
  const renderStep2 = () => {
    return (
      <>
        <div
          className="mt-3 flex min-h-[100px] flex-col rounded border border-gray-300 bg-white p-5"
          id="diversity-step-2"
        >
          <div className="flex flex-col gap-5">
            <h5 className="font-poppins text-base font-semibold text-black">Overall assessment</h5>
            {loading || isAssessmentLoading ? (
              <MemoizedLoadingRecommendedAction loading={loading || isAssessmentLoading} short />
            ) : (
              <pre className="whitespace-pre-line font-poppins text-xs text-gray-800">
                {assessment?.summary || ""}
              </pre>
            )}
          </div>
        </div>
        <div
          className={classNames(
            "mt-4 flex h-[38px] items-center justify-start gap-2",
            currentStep === 2 && "hidden",
          )}
        >
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              setCurrentStep(2);
              setTimeout(() => {
                scrollToElement("diversity-step-3");
              }, 500);
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </>
    );
  };
  const renderStep3 = () => {
    return (
      <Allcharts
        org_id={org_id}
        npo_id={npo_id}
        onBack={() => {
          setTimeout(() => {
            setCurrentStep(1);
          }, 500);
          setTimeout(() => {
            scrollToElement("diversity-step-2");
          }, 1500);
        }}
        onNext={onNext}
      />
    );
  };
  const stepperClass = isEditMode ? "!-ml-[5px]" : "!-ml-[40px]";

  return (
    <div className="flex !max-w-[760px] flex-col">
      <Steps current={currentStep} vertical>
        <Steps.Item
          title={false}
          className={classNames("!mt-0", stepperClass)}
          icon={currentStep > 0 ? renderDot(1) : <></>}
          description={renderStep1()}
        />
        {currentStep > 0 && (
          <Steps.Item
            icon={renderDot(2)}
            className={classNames(stepperClass)}
            description={renderStep2()}
          />
        )}
        {currentStep > 1 && (
          <Steps.Item
            icon={renderDot(3)}
            className={classNames(stepperClass)}
            description={renderStep3()}
          />
        )}
      </Steps>
      <Spacer height="150px" />
    </div>
  );
}

function Allcharts({
  onBack,
  onNext,
  org_id,
  npo_id,
}: {
  onBack?: () => void;
  onNext?: () => void;
  org_id?: string;
  npo_id?: string;
}) {
  const mxEvent = useGibooMixpanel(undefined, true);
  const navigate = useNavigate();
  const { data: profile, update: profileUpdate, isLoading } = useOrgProfile(org_id);
  const [consent, setConsent] = useState<boolean>(false);
  useEffect(() => {
    if (isLoading) return;
    setConsent(profile?.diversity_consent ? true : false);
  }, [isLoading, profile]);
  return (
    <div className="mt-3 flex flex-col gap-4" id="diversity-step-3">
      <div className="flex min-h-[100px] flex-col gap-5 rounded border border-gray-300 bg-white p-5">
        {profile.hide_ethnicity !== true && (
          <EditModeItem
            title="Ethnicity"
            value={!profile.hide_ethnicity}
            noBorder
            onChange={(data) => {
              profileUpdate({ hide_ethnicity: !data });
            }}
            Edit={<EthnicityInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          >
            <NpoDiversityEthnicity
              allowEdit={false}
              org_id={org_id}
              showInfo={true}
              showStats={false}
              hideTitle
            />
          </EditModeItem>
        )}
        {profile.hide_gender !== true && (
          <EditModeItem
            title="Gender"
            value={!profile.hide_gender}
            noBorder
            onChange={(data) => {
              profileUpdate({ hide_gender: !data });
            }}
            Edit={<GenderInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          >
            <NpoDiversityGender
              allowEdit={false}
              org_id={org_id}
              showInfo={true}
              showStats={false}
              hideTitle
            />
          </EditModeItem>
        )}
        {profile.hide_age !== true && (
          <EditModeItem
            title="Age"
            value={!profile.hide_age}
            noBorder
            onChange={(data) => {
              profileUpdate({ hide_age: !data });
            }}
            Edit={<AgeInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          >
            <NpoDiversityGeneration
              allowEdit={false}
              org_id={org_id}
              showInfo={true}
              showStats={false}
              hideTitle
            />
          </EditModeItem>
        )}
        {profile.hide_sexual !== true && (
          <EditModeItem
            title="Sexual orientation perspective"
            value={!profile.hide_sexual}
            noBorder
            onChange={(data) => {
              profileUpdate({ hide_sexual: !data });
            }}
            Edit={<SexualOrientationInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          >
            <NpoDiversitySexualOrientation
              allowEdit={false}
              org_id={org_id}
              showInfo={true}
              showStats={false}
              hideTitle
            />
          </EditModeItem>
        )}
        {profile.hide_disability !== true && (
          <EditModeItem
            title="Disability"
            value={!profile.hide_disability}
            noBorder
            onChange={(data) => {
              profileUpdate({ hide_disability: !data });
            }}
            Edit={<DisablityInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          >
            <NpoDiversityDisability
              allowEdit={false}
              org_id={org_id}
              showInfo={true}
              showStats={false}
              hideTitle
            />
          </EditModeItem>
        )}
        {profile.hide_religion !== true && (
          <EditModeItem
            title="Religion"
            value={!profile.hide_religion}
            noBorder
            onChange={(data) => {
              profileUpdate({ hide_religion: !data });
            }}
            Edit={<ReligionInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          >
            <NpoDiversityReligion
              allowEdit={false}
              org_id={org_id}
              showInfo={true}
              showStats={false}
              hideTitle
            />
          </EditModeItem>
        )}
        {profile.hide_profession !== true && (
          <EditModeItem
            title="Professional background of board member"
            value={!profile.hide_profession}
            noBorder
            onChange={(data) => {
              profileUpdate({ hide_profession: !data });
            }}
            Edit={<ProfessionInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          >
            <NpoDiversityProfession
              allowEdit={false}
              org_id={org_id}
              showInfo={true}
              showStats={false}
              hideTitle
            />
          </EditModeItem>
        )}
      </div>
      <div className="space-y-4 rounded border border-orange-300 bg-white p-4">
        <h6 className="font-poppins text-xs text-gray-800">
          Your organization&apos;s dashboard will showcase the information gathered in these steps,
          providing a comprehensive overview of your philanthropic diversity. By consenting to the
          presentation of this data, you acknowledge and agree that the information pertaining to
          board members, employees, and volunteers collected during these steps will be displayed in
          your organization profile.
        </h6>
        <div className="flex w-full justify-start">
          <Checkbox
            id={`input-check-consent`}
            className=""
            label={"I consent"}
            size="sm"
            inputClass="!border-gray-700"
            labelClass="!text-xs font-poppins !text-gray-700"
            checked={consent}
            onChange={() => {
              setConsent(!consent);
              profileUpdate({ diversity_consent: !consent });
            }}
          />
        </div>
      </div>
      <div className={classNames("flex h-[38px] items-center justify-start gap-2")}>
        <Button
          className="!h-[37px]"
          id="btn-back"
          handleOnClick={() => {
            onBack?.();
          }}
          label="Back"
          outline
          prependIcon={<i className="gi-left-arrow gi" />}
        />
        <Button
          className="!h-[37px]"
          id="btn-next"
          disabled={!consent}
          handleOnClick={() => {
            mxEvent(MIXPANEL_EVENTS_V2.diversity[""].successful);
            onNext?.();
            setTimeout(() => {
              navigate("/organization", { state: { activeTab: "diversity" } });
            }, 300);
          }}
          label="Go to my organization profile"
          appendIcon={
            <div className="-mt-1 !rotate-180">
              <i className="gi-left-arrow" />
            </div>
          }
        />
      </div>
    </div>
  );
}
