import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import PopupModal from "./PopupModal";
import Button from "../tailwind/Button";

interface AlertModalI {
  variant?: "purple" | "red" | "yellow" | "blue" | "green";
  isOpen: boolean;
  title?: string | undefined;
  handleClose: () => void;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  cancelTitle?: string;
  confirmTitle?: string;
  children?: React.ReactNode;
  showConfirm?: boolean;
  showCancel?: boolean;
  content?: string | ReactNode;
  isLoading?: boolean;
}

function AlertModal({
  showCancel,
  showConfirm,
  cancelTitle,
  confirmTitle,
  handleClose,
  handleCancel,
  handleConfirm,
  content,
  variant,
  isLoading,
  ...props
}: AlertModalI) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props]);

  const renderContent = () => {
    if (typeof content === "string") {
      return (
        <div className="my-10">
          <p className="text-center font-poppins text-base font-normal">{content}</p>
        </div>
      );
    }
    return content;
  };
  return (
    <>
      {isOpen && (
        <PopupModal
          isOpen={isOpen}
          handleClose={() => {
            handleClose();
          }}
          title={props.title}
          wrapperClass="!max-w-fit !min-w-[500px] md:!px-0 "
          contentClass="!px-[24px] max-h-fit overflow-y-scroll h-fit"
          RightActionButton={() => (
            <>
              <div className="flex items-center justify-center space-x-2">
                {showCancel && (
                  <Button
                    id="cancel-btn"
                    outline
                    handleOnClick={() => {
                      handleCancel?.();
                    }}
                    label={cancelTitle}
                  />
                )}
                {showConfirm && (
                  <Button
                    id="confirm-btn"
                    className="px-4 py-2"
                    handleOnClick={() => {
                      if (isLoading) return;
                      handleConfirm?.();
                    }}
                    loading={isLoading}
                    label={confirmTitle}
                  />
                )}
              </div>
            </>
          )}
        >
          <>{renderContent()}</>
        </PopupModal>
      )}
    </>
  );
}
AlertModal.defaultProps = {
  openModal: false,
  title: "Delete user",
  cancelTitle: "Cancel",
  confirmTitle: "Delete",
  showConfirm: true,
  showCancel: true,
  variant: "purple",
};

export default AlertModal;
