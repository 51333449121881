import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { SaveItem } from "../../types/save";
import useOrgID from "../useOrgID";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface SavedItemsState {
  data: SaveItem[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<SaveItem[] | undefined>;
}
function useSavedItems(id?: string, limit = 20, skip = 0): SavedItemsState {
  const url =
    process.env.REACT_APP_API_URL + `/api/v2/users/savelistitems/${id}?limit=${limit}&skip=${skip}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<SaveItem[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as SaveItem[]);
  };
  const { data, isLoading, error, isValidating } = useSWR<SaveItem[]>(
    id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<SaveItem[]>(url), [url]);
  return {
    data: data || [],
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useSavedItems;
