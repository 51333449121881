import React, { useEffect, useState } from "react";
import Spacer from "../../../components/Spacer";
import Button from "../../../components/tailwind/Button";
import IMG_1 from "../../../assets/images/diversity1.png";
import IMG_2 from "../../../assets/images/diversity2.png";
import IMG_3 from "../../../assets/images/diversity3.png";
import IMG_4 from "../../../assets/images/diversity4.png";
import IMG_5 from "../../../assets/images/diversity5.png";
import IMG_Loader from "../../../assets/images/diversityLoader.svg";
import ArraySlider from "../../../components/tailwind/ArraySlider";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import useGibooNavigate from "../../../hooks/useGibooNavigate";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import BackButton from "../../../components/BackButton";
export default function DiversityIntro() {
  const mxEvent = useGibooMixpanel(undefined, true); // this is a navigated page and location.state already has 'from_for_mixpanel', so do not set from_for_mixpanel
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const locationState = useLocation().state;
  const navigate = useGibooNavigate(); // by pass from_for_mixpanel and session_for_mixpanel
  const items = [
    <Item1 key={"item1"} />,
    <Item2 key={"item2"} />,
    <Item3 key={"item3"} />,
    <Item4 key={"item4"} />,
    <Item5 key={"item5"} />,
  ];
  const itemLength = items.length;
  const isLast = itemLength === selectedIndex + 1;
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.diversity.intro.started);
  }, []);
  return (
    <div className="flex flex-col items-center overflow-y-scroll">
      <Spacer height="118px" />
      <div className="my-4 flex min-h-fit w-full max-w-[759px]">
        <BackButton wrapper="self-start" />
      </div>
      <div className="flex min-h-fit w-full max-w-[759px] flex-col justify-start rounded-t-[4px] border border-gray-200 bg-white">
        {loading ? (
          <LoadingState
            onComplete={() => {
              setLoading(false);
              navigate("/diversity/detail", { state: { ...locationState } });
              mxEvent(MIXPANEL_EVENTS_V2.diversity.intro.successful);
            }}
          />
        ) : (
          <>
            <div className="pt-10">
              <ArraySlider
                slides={items}
                selectedIndex={selectedIndex}
                wrapperClass="mx-8"
                dotNavClass="-mt-5"
                onChange={(value) => {
                  setSelectedIndex(value);
                }}
                renderFooter={() => (
                  <div className="mt-5 flex h-[77px] w-full items-center justify-center border-t border-gray-200">
                    {!isLast ? (
                      <Button
                        label="Next"
                        handleOnClick={() => {
                          setSelectedIndex((prev) => prev + 1);
                        }}
                        id="Next"
                      />
                    ) : (
                      <Button
                        label="Start"
                        handleOnClick={() => {
                          setLoading(true);
                        }}
                        id="Start assessment"
                      />
                    )}
                  </div>
                )}
              />
            </div>
            {/* */}
          </>
        )}
      </div>
      <Spacer height="118px" />
    </div>
  );
}

const Item1 = () => {
  return (
    <div className="flex h-[532px] w-[557px] select-none flex-col items-center gap-5">
      <div className="flex h-[48px] max-w-[426px] items-center text-center text-base font-semibold">
        Why is it important to convey the diversity <br />
        landscape of your organization?
      </div>
      <div className="flex max-h-[248px] w-full max-w-[350px] justify-center">
        <img src={IMG_1} className="h-fit w-fit" alt="diversity image" />
      </div>
      <div className="flex h-[198px] items-center">
        <h6 className="w-[504px] font-poppins text-xs font-normal text-gray-800">
          There has been an increasing trend among funders to request for diversity information from
          nonprofit organizations. Specifically focusing on board members or employees information
          and seeking diversity data relevant to their mission&apos;s demographic groups. Funders
          view diversity information as a strategic evaluation tool, acknowledging its importance in
          assessing organizational effectiveness.
          <br />
          <br />
          Recognizing the time-consuming nature of diversity data collection, Giboo simplifies the
          process, providing an efficient approach so that nonprofits can focus efficiently on their
          core missions for greater impact.
          <br />
          <br /> Click next to proceed to discover how Giboo helps:
        </h6>
      </div>
    </div>
  );
};

const Item2 = () => {
  return (
    <div className="flex h-[532px] w-[557px] select-none flex-col items-center gap-5">
      <div className="flex h-[48px] max-w-[449px] items-center text-center  text-base font-semibold">
        First, you can have a comprehensive understanding of <br />
        your organization&apos;s diversity landscape.
      </div>
      <div className="flex max-h-[248px] w-full max-w-[316px] justify-center">
        <img src={IMG_2} className="h-fit w-fit" alt="financial image" />
      </div>
      <div className="flex h-[198px] items-center">
        <h6 className="w-[418px] font-poppins text-xs font-normal text-gray-800">
          <b className="text-purple-500">
            Giboo facilitates the collection and analysis of organization&apos;s demographic data
          </b>
          , encompassing factors like ethnicity, gender, age, sexual orientation, disability,
          religion, and professional background.
          <br />
          <br />
          This process offers valuable insights into the current composition of your
          organization&apos;s board members, employees, and volunteers.
        </h6>
      </div>
    </div>
  );
};
const Item3 = () => {
  return (
    <div className="flex h-[532px] w-[557px] select-none flex-col items-center gap-5">
      <div className="flex h-[48px] items-center text-center  text-base font-semibold ">
        Second, you can strategically customize your <br /> organization&apos;s mission diversity
      </div>
      <div className="flex max-h-[248px] w-full max-w-[312px] justify-center">
        <img src={IMG_3} className="h-fit w-fit" alt="financial image" />
      </div>
      <div className="flex h-[198px] items-center">
        <h6 className="w-[418px] font-poppins text-xs font-normal text-gray-800">
          Giboo assists in customizing the diversity information to align with specific demographic
          groups crucial to their mission. This allows nonprofits to{" "}
          <b className="text-purple-500">spotlight diversity within their philanthropic focus,</b>{" "}
          ensuring resonance with the unique needs and characteristics of their focus area,
          beneficiaries, or programs.
          <br />
          <br /> By providing a tailored approach, Giboo empowers organizations to communicate their
          dedication to diversity in a manner that resonates with their specific context and
          mission, thereby strengthening trust and connections with funders.
        </h6>
      </div>
    </div>
  );
};
const Item4 = () => {
  return (
    <div className="flex h-[532px] w-[557px] select-none flex-col items-center gap-5">
      <div className="flex h-[48px] items-center text-center  text-base font-semibold">
        Third, you can strategically integrate diversity information <br /> in grant proposals or
        letters for greater impact
      </div>
      <div className="flex max-h-[248px] w-full max-w-[300px] justify-center">
        <img src={IMG_4} className="h-fit w-fit" alt="financial image" />
      </div>
      <div className="flex h-[198px] items-center">
        <h6 className="w-[418px] font-poppins text-xs font-normal text-gray-800">
          <b className="text-purple-500">
            You can integrate your organization&apos;s diversity information into essential
            documents such as grant proposals or letters of inquiry
          </b>{" "}
          for outreach and fundraising initiatives.
          <br />
          <br />
          Funders frequently seek diversity insights during the grant writing process, making it
          crucial to incorporate key details such as board member composition. By seamlessly
          including this information, you not only enhance the transparency of your organization but
          also align with the expectations of funders, strengthening your grant applications and
          increasing the likelihood of successful outcomes.
        </h6>
      </div>
    </div>
  );
};
const Item5 = () => {
  return (
    <div className="flex h-[532px] w-[557px] select-none flex-col items-center gap-5">
      <div className="flex h-[48px] items-center text-center  text-base font-semibold">
        Are you ready to embark on the journey towards a <br /> more diverse and inclusive
        organization?
      </div>
      <div className="flex max-h-[248px] w-full max-w-[425px] justify-center">
        <img src={IMG_5} className="h-fit w-fit" alt="financial image" />
      </div>
      <div className="flex h-[198px] items-center">
        <h6 className="w-[418px] font-poppins text-xs font-normal text-gray-800">
          With Giboo as your dedicated partner, we&apos;re here to guide you every step of the way.
          Let&apos;s get started!
        </h6>
      </div>
    </div>
  );
};
const LoadingState = ({ onComplete }: { onComplete?: () => void }) => {
  const items = [
    "People’s information...",
    "Ethnicity",
    "Gender",
    "Age",
    "Sexual orientation perspective",
    "Disability",
    "Religion",
    "Professional background of board member",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 450);
    const lastIndex = items.length - 1;
    return () => {
      clearInterval(intervalId);

      if (currentIndex === lastIndex) {
        onComplete?.();
      }
    };
  }, [items.length, currentIndex, onComplete]);
  return (
    <div className="flex h-full min-h-[636px] w-full flex-col items-center justify-center gap-[32px]">
      <div className="flex items-center text-center  text-xs italic text-gray-800">
        Based on your IRS 990 data, we are
      </div>
      <div className="relative flex justify-center ">
        <div
          className={classNames("absolute inset-0 flex items-center justify-center bg-transparent")}
        >
          <div className="z-20 mt-[124px] grid  h-[44px] max-w-[165px] place-items-center">
            <p className="text-xs font-semibold italic text-gray-900">Collecting organization’s</p>
            <div className="max-w-[135px] truncate overflow-ellipsis text-xs font-normal italic text-gray-800">
              People’s information...
            </div>
          </div>
        </div>
        <img src={IMG_Loader} className="h-fit w-fit" alt="financial image" />
        <div></div>
      </div>
    </div>
  );
};
