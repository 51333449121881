import { useCallback, useEffect } from "react";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../api";
import { INotification } from "../types/notification";
import useUser from "./useUser";
import useOrgs from "./useOrgs";
import { useAppDispatch } from "../app/store";
import { fetchProjectsAsync } from "../app/projectSlice";
interface NotificationState {
  data: INotification[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  read: (id: string) => Promise<INotification[] | undefined>;
  readAll: () => Promise<INotification[] | undefined>;
  revalidate: () => Promise<INotification[] | undefined>;
}

function useNotification(): NotificationState {
  const [user] = useUser();
  const { selected } = useOrgs();
  const { mutate } = useSWRConfig();
  const dispatch = useAppDispatch();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<INotification[]>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => res.data as INotification[])
      .then((res) =>
        res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
      );
  };
  const fetchUpdate = async (_url: string) => {
    if (!_url) return new Promise<string>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as string);
  };
  const { data: notificationUpdate } = useSWR<string>(
    user && selected
      ? process.env.REACT_APP_API_URL + `/api/v2/users/notification/update?org_id=${selected}`
      : null,
    fetchUpdate,
    {
      refreshInterval: 30 * 1000, // 30 sec
      dedupingInterval: 1000 * 300, //5 min
      revalidateIfStale: false,
      keepPreviousData: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const { data, isLoading, error, isValidating } = useSWR<INotification[]>(
    user && selected && notificationUpdate
      ? process.env.REACT_APP_API_URL +
          `/api/v2/users/notifications?org_id=${selected}&t=${notificationUpdate}`
      : null,
    fetch,
    {
      keepPreviousData: false,
    },
  );
  useEffect(() => {
    // revalidate based notification
    if (!data) return;
    if (
      data.filter(
        (n) => !n.read && (n.target === "invite_project" || n.target === "set_role_project"),
      ).length > 0
    ) {
      dispatch(fetchProjectsAsync(selected));
    }
    data
      .filter((n) => !n.read)
      .map((n) => {
        const pid =
          n.data.filter((d) => d.project_id).length > 0
            ? n.data.filter((d) => d.project_id)[0]
            : undefined;
        const revalidateURL = pid
          ? n.target === "mention_project_interaction"
            ? process.env.REACT_APP_API_URL + `/api/v2/projects/${pid}/interaction/${n.target_id}`
            : n.target === "mention_project_interaction_comment"
            ? process.env.REACT_APP_API_URL +
              `/api/v2/projects/${pid}/interaction/comment/${n.target_id}`
            : n.target === "mention_project_subtask_note"
            ? process.env.REACT_APP_API_URL + `/api/v2/projects/${pid}/subtask/note/${n.target_id}`
            : undefined
          : undefined;
        return revalidateURL;
      })
      .filter(Boolean)
      .map((url) => mutate(url));
  }, [data]);
  const readNotification = async (id: string) => {
    if (!id) return new Promise<boolean>((resolve, reject) => reject());
    return axios
      .post(process.env.REACT_APP_API_URL + `/api/v2/users/notification/read/${id}`, {}, config)
      .then((res) => res.data as boolean);
  };
  const readAllNotification = async () => {
    return axios
      .post(
        process.env.REACT_APP_API_URL + `/api/v2/users/notification/read/all?org_id=${selected}`,
        {},
        config,
      )
      .then((res) => res.data as boolean);
  };
  const read = useCallback(
    async (id: string) => {
      if (!id || !data || !selected) return;
      const index = data.findIndex((n) => n._id === id);
      const options = {
        optimisticData:
          index >= 0
            ? [...data.slice(0, index), { ...data[index], read: true }, ...data.slice(index + 1)]
            : data,
        rollbackOnError: true,
      };
      return mutate<INotification[]>(
        process.env.REACT_APP_API_URL +
          `/api/v2/users/notifications?org_id=${selected}&t=${notificationUpdate}`,
        axios
          .post(process.env.REACT_APP_API_URL + `/api/v2/users/notification/read/${id}`, {}, config)
          .then((response) =>
            axios
              .get(
                process.env.REACT_APP_API_URL +
                  `/api/v2/users/notifications?org_id=${selected}&t=${notificationUpdate}`,
                config,
              )
              .then((res) => res.data),
          ),
        options,
      );
    },
    [notificationUpdate, data],
  );
  const readAll = useCallback(async () => {
    if (!data || !selected) return;
    const options = {
      optimisticData: data.map((n) => ({ ...n, read: true })),
      rollbackOnError: true,
    };
    return mutate<INotification[]>(
      process.env.REACT_APP_API_URL +
        `/api/v2/users/notifications?org_id=${selected}&t=${notificationUpdate}`,
      axios
        .post(
          process.env.REACT_APP_API_URL + `/api/v2/users/notification/read/all?org_id=${selected}`,
          {},
          config,
        )
        .then((response) =>
          axios
            .get(
              process.env.REACT_APP_API_URL +
                `/api/v2/users/notifications?org_id=${selected}&t=${notificationUpdate}`,
              config,
            )
            .then((res) => res.data),
        ),
      options,
    );
  }, [notificationUpdate, data]);
  const revalidate = useCallback(
    () =>
      mutate<INotification[]>(
        process.env.REACT_APP_API_URL +
          `/api/v2/users/notifications?org_id=${selected}&t=${notificationUpdate}`,
      ),
    [notificationUpdate],
  );
  return {
    data: data || [],
    isLoading,
    error,
    isValidating,
    read,
    readAll,
    revalidate,
  };
}
export default useNotification;
