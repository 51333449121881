import { useEffect, useState } from "react";
import { OnboardingData } from "../app/onboarding";
import { __getOnboardingData } from "../app/onboardingSlice";
import {
  FORMAT_INCORPORATED_STATUS,
  FORMAT_ORGANIZATION_TYPE,
} from "../pages/npo/organizational-profile/component/EditBasicInfo";
import { Tagging } from "../services/taxonomy.algo.services";
import { IProject } from "../types/project";
import { NPOSearchResult, getValidStage, getValidTaxExemption } from "../types/search";
import useProjects from "./project/useProjects";
import useNPOSearchResult from "./search/useNPOSearchResult";
import useOnboardingData from "./useOnboarding";
import useOrg from "./useOrg";
import useOrgProjects from "./useOrgProjects";
import useOrgProjectTagging from "./useOrgProjectTagging";
import useUser from "./useUser";
import useVerifiedOrg from "./useVerifiedOrg";

interface OrgDataForNPODashboard {
  org_id: string | undefined;
  npo_id: string | undefined;
  data: OnboardingData | undefined;
  projects: IProject[];
  hasWritePermission?: boolean;
  isLoading?: boolean;
  projectsIsLoading?: boolean;
  isVerified?: boolean;
}

const getValidOrgType = (s?: string): string | undefined => {
  if (!s) return undefined;
  const found = Object.keys(FORMAT_ORGANIZATION_TYPE).find((t) => t === s);
  return found ? found : undefined;
};

const getValidOrgStatus = (s?: string): string | undefined => {
  if (!s) return undefined;
  const found = Object.keys(FORMAT_INCORPORATED_STATUS).find((t) => t === s);
  return found ? found : undefined;
};

const getOnboardingDataFromNPOandNPOProfile = (profile?: NPOSearchResult): OnboardingData => {
  return {
    _id: "",
    npo_id: profile?._id,
    address1: profile?.address?.split(",").slice(0, -3).join(",") || "",
    address2: "",
    focus_area: profile?.focus_area?.map((t) => t.label) || [],
    beneficiary: profile?.beneficiary?.map((t) => t.label) || [],
    program: profile?.program?.map((t) => t.label) || [],
    service_loc: profile?.location ? [profile.location] : [],
    completed: true,
    created_at: "",
    updated_at: "",
    joined: "",
    city: profile?.address?.split(",").slice(-3, -2).join(",") || "",
    state_id: profile?.address?.split(",").slice(-2, -1).join(",") || "",
    country: profile?.location?.iso2 || "US",
    roles: [],
    step: 0,
    type: 1,
    role: 0,
    user_id: "",
    website: profile?.website || "",
    zipcode: profile?.address?.split(",").slice(-1, 0).join(",") || "",
    mission: profile?.mission,
    ein: profile?.ein,
    npo_ein: profile?.ein,
    npo_name: profile?.name || "",
    phone: profile?.phone,
    stage: getValidStage(profile?.stage),
    npo_address: profile?.address,
    tax_exemption: getValidTaxExemption(profile?.tax_exemption ? 3 : profile?.tax_exempt_status),
    npo_type: profile?.npo_type,
    incorporated_year: profile?.year_of_formation,
    org_type: getValidOrgType(profile?.npo_type),
    org_status: profile?.incorporated_status
      ? getValidOrgStatus(profile?.incorporated_status)
      : getValidOrgStatus("nonprofit"),
    n_grants_received: profile?.grant_count_total,
    grant_amount_min: profile?.grant_amount_min,
    grant_amount_max: profile?.grant_amount_max,
    n_employee: profile?.employee_count,
    n_volunteer: profile?.volunteer_count,
    return_code: profile?.return_code,
  };
};
const supplementDataFromNPO = (
  onboardingData: OnboardingData,
  npoProfile?: NPOSearchResult,
  tagging?: Tagging,
): OnboardingData => {
  return {
    ...onboardingData,
    npo_address: [
      onboardingData.address1,
      onboardingData.address2,
      onboardingData.city,
      onboardingData.state_id,
      onboardingData.zipcode,
    ]
      .filter(Boolean)
      .join(", "),
    npo_id: npoProfile?._id,
    npo_ein: onboardingData.npo_ein ? onboardingData.npo_ein : npoProfile?.ein || "",
    npo_name: onboardingData.npo_name ? onboardingData.npo_name : npoProfile?.name || "",
    npo_type: npoProfile?.npo_type,
    phone: onboardingData.phone || npoProfile?.phone,
    tax_exemption: onboardingData.tax_exemption
      ? getValidTaxExemption(onboardingData.tax_exemption)
      : getValidTaxExemption(npoProfile?.tax_exemption ? 3 : npoProfile?.tax_exempt_status),
    org_type: getValidOrgType(onboardingData.org_type) || getValidOrgType(npoProfile?.npo_type),
    org_status: getValidOrgStatus(onboardingData.org_status),
    incorporated_year: onboardingData.incorporated_year || npoProfile?.year_of_formation,
    grant_amount_max: onboardingData.grant_amount_max || npoProfile?.grant_amount_max,
    grant_amount_min: onboardingData.grant_amount_min || npoProfile?.grant_amount_min,
    n_grants_received: onboardingData.n_grants_received || npoProfile?.grant_count_total,
    n_employee: onboardingData.n_employee || npoProfile?.employee_count || 0,
    n_volunteer: onboardingData.n_volunteer || npoProfile?.volunteer_count || 0,
    website: onboardingData.website || npoProfile?.website || "",
    return_code: npoProfile?.return_code,
    ...(tagging &&
    onboardingData.focus_area.length +
      onboardingData.beneficiary.length +
      onboardingData.program.length ===
      0
      ? tagging
      : {}),
  };
};
function useOrgDataForNPODashboard(id?: string, isPrivateView?: boolean): OrgDataForNPODashboard {
  const [user] = useUser();

  const { data: verifiedOrg, isLoading: verifiedOrgIsLoading } = useVerifiedOrg(
    id && id.length === 24 ? id : undefined,
  );
  const [onboardingData] = useOnboardingData(); // current my org
  const org_id = isPrivateView
    ? onboardingData._id
    : id && id.length === 24
    ? verifiedOrg?._id
    : id;
  const { data: orgData, isLoading: orgIsLoading } = useOrg(!isPrivateView ? org_id : undefined);
  const npo_id =
    id && id.length === 24 ? id : isPrivateView ? onboardingData.npo_id : orgData?.npo_id;
  const [projects] = useProjects(); // current my org's project
  const { data: orgProjects, isLoading: orgProjectsIsLoading } = useOrgProjects(
    !isPrivateView ? org_id : undefined,
  );
  const { data: npoProfile, isLoading: NPOProfileIsLoading } = useNPOSearchResult(npo_id);
  const hasWritePermission = isPrivateView && onboardingData.role >= 4;
  const [data, setData] = useState<OnboardingData>(
    getOnboardingDataFromNPOandNPOProfile(npoProfile),
  );
  const { data: orgTagging } = useOrgProjectTagging();
  useEffect(() => {
    setData(
      isPrivateView
        ? supplementDataFromNPO(onboardingData, npoProfile, orgTagging)
        : id === npo_id && verifiedOrg
        ? supplementDataFromNPO(__getOnboardingData(verifiedOrg, user?._id || ""), npoProfile)
        : id === org_id && orgData
        ? __getOnboardingData(orgData, user?._id || "")
        : getOnboardingDataFromNPOandNPOProfile(npoProfile),
    );
  }, [id, npoProfile, onboardingData, orgData, user, npo_id, org_id, verifiedOrg, orgTagging]);
  return {
    org_id,
    npo_id,
    isVerified: orgData?.verified ? true : false,
    isLoading: isPrivateView
      ? false
      : orgIsLoading
      ? true
      : npo_id
      ? NPOProfileIsLoading || verifiedOrgIsLoading
      : false,
    data,
    projects: isPrivateView ? projects.data : orgData ? orgProjects : [],
    projectsIsLoading: isPrivateView ? false : orgProjectsIsLoading,
    hasWritePermission,
  };
}
export default useOrgDataForNPODashboard;
export { getOnboardingDataFromNPOandNPOProfile };
