import React, { useState } from "react";
import useProject from "../../../../hooks/project/useProject";
import { ProjectFundraisedModal } from "../../../../components/ProjectDashboardInfo";
import { delay } from "lodash";
import ProgressBarCircle from "../../../../components/ProgressBarCircle";
import { toUsdLong } from "../../../../utils/formatHelper";
import { Divider } from "rsuite";
import useTask from "../../../../hooks/project/useTask";
import IMG_FUNDER from "../../../../assets/images/funder.png";
import IMG_GRANT from "../../../../assets/images/grant.png";
import classNames from "classnames";
export default function ProjectTaskTargetAmount({
  projectId,
  taskId,
  wraperClass,
}: {
  projectId?: string;
  taskId?: string;
  wraperClass?: string;
}) {
  const { data: projectData, revalidate } = useProject(projectId);
  const [showFundraised, setShowFundraised] = useState(false);
  const { data: task } = useTask(taskId);

  const renderTargetAmount = () => {
    const fundRaised = projectData.amount || 0;
    const fundToRaise = projectData?.fund_to_raise || 0;
    const fundPercentage = fundRaised > 0 && fundToRaise > 0 ? (fundRaised / fundToRaise) * 100 : 0;
    return (
      <>
        {showFundraised && (
          <ProjectFundraisedModal
            taskId={taskId}
            projectId={projectId || ""}
            handleClose={() => {
              setShowFundraised(false);
              delay(() => revalidate(), 500);
            }}
            open={showFundraised}
          />
        )}
        <div className="flex min-h-[183px] w-full flex-col">
          <div className="flex justify-between">
            <p className="font-poppins text-sm font-semibold text-purple-500">Target amount</p>
            <span
              className="giboo-btn-text-purple grid cursor-pointer place-items-center text-sm text-gray-600"
              onClick={() => {
                setShowFundraised(true);
              }}
            >
              Edit
            </span>
          </div>
          <div className=" my-4  grid place-items-center">
            <ProgressBarCircle
              percentage={fundPercentage}
              img={task.target_type === 0 ? IMG_FUNDER : IMG_GRANT}
            />
          </div>
          <div className="flex min-h-[85px] w-full items-center border-t border-gray-300 pt-[7px]">
            <div className="flex w-full flex-col gap-1 ">
              <p className="font-poppins text-xs text-gray-700">Raised:</p>
              <span className="font-poppins text-base font-semibold text-black">
                {toUsdLong(fundRaised)}
              </span>
            </div>
            <Divider vertical className="mx-[14px] h-full" />
            <div className="flex w-full flex-col gap-1 ">
              <p className="font-poppins text-xs text-gray-700">Project goal:</p>
              <span className="font-poppins text-base font-semibold text-black">
                {toUsdLong(fundToRaise)}
              </span>
            </div>
          </div>
          <p
            className="giboo-btn-text-purple mt-2 cursor-pointer font-poppins text-xs text-purple-500"
            onClick={() => setShowFundraised(true)}
          >{`+ Add raised amount`}</p>
        </div>
      </>
    );
  };
  return (
    <div
      className={classNames(
        "flex h-[306px] w-[363px] flex-col rounded border border-gray-200 bg-white p-4",
        wraperClass,
      )}
    >
      {renderTargetAmount()}
    </div>
  );
}
