import useFunderStageAnalysis from "../../hooks/funder/useFunderStageAnalysis";
import useFunderStageAnalysisOverview from "../../hooks/funder/useFunderStageAnalysisOverview";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";
import useGibooMixpanel from "../../hooks/useGibooMixpanel";
import useOnboardingData from "../../hooks/useOnboarding";
import { MIXPANEL_EVENTS_V2 } from "../../mixpanel/mixpanel";
import ChartLoader from "../ChartLoader";
import VisibilityTracker from "../VisibilityTracker";
import FundCharChart from "../dashboard/FundCharChart";
export default function FunderStageAnalysisSimple({
  funder_id,
  ...props
}: {
  funder_id: string;
  wrapperClass?: string;
}) {
  const [onboardingData] = useOnboardingData();
  const mxEvent = useGibooMixpanel();
  const { data: funder, isLoading: isFunderLoading } = useFunderSearchResult(funder_id);
  const { data: amount, isLoading: isLoading1 } = useFunderStageAnalysis(funder_id, "amount");
  const { data: count, isLoading: isLoading2 } = useFunderStageAnalysis(funder_id, "count");
  const { data: fundCharOverview, isLoading: isLoading3 } =
    useFunderStageAnalysisOverview(funder_id);
  const isLoading = isFunderLoading || isLoading1 || isLoading2 || isLoading3;
  if (isLoading) {
    return <ChartLoader />;
  }
  return (
    <>
      {amount.length > 0 && count.length > 0 && fundCharOverview ? (
        <VisibilityTracker
          elementIdOrRef={"funding-chart-view-1"}
          onVisible={() => {
            // console.log("chart-view:  Funding stage analysis of past grant recipients");
            mxEvent(MIXPANEL_EVENTS_V2.detail_view.visibility.observed, {
              value: "Funding stage analysis of past grant recipients",
            });
          }}
        >
          <FundCharChart
            selectedTab={1}
            fundCharCount={count}
            fundCharOverview={fundCharOverview}
            fundCharAmount={amount}
            returnCode={funder?.return_code || ""}
            width={0}
            selected={onboardingData.stage}
          />
        </VisibilityTracker>
      ) : (
        <div>
          <p>No information available</p>
        </div>
      )}
    </>
  );
}
