import useUser from "../hooks/useUser";
import arrowIcon from "../assets/images/arrow-down.svg";
import logoutIcon from "../assets/images/logout.svg";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import useOnboardingData from "../hooks/useOnboarding";
import { useRef, useState } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import usePhotoUrl from "../hooks/usePhotoUrl";
import useTotalOrganizationMember from "../hooks/useTotalOrganizationMember";
import { getTotalOrganizationMembersId } from "../hooks/useTotalOrganizationMembersData";
import { getInitials, getRandomColor } from "../utils/formatHelper";
import { MIXPANEL_EVENTS_V2, Navigate } from "../mixpanel/mixpanel";
import useOrgID from "../hooks/useOrgID";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
function NavigationUserProfile({
  handleLogout,
  showMyOrgProfile = true,
  isOnboarding = false,
  ...props
}: {
  handleLogout?: () => void;
  showMyOrgProfile?: boolean;
  isOnboarding?: boolean;
}) {
  const mxEvent = useGibooMixpanel();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [user] = useUser();
  const org_id = useOrgID();
  if (!user) return <></>;
  const [onboardingData] = useOnboardingData();
  const userName = `${user && user.firstname ? user.firstname : ""} ${
    user && user.lastname ? user.lastname : ""
  }`;
  const { data: orgMember } = useTotalOrganizationMember(
    onboardingData._id,
    getTotalOrganizationMembersId({ _id: user?._id || "", type: "user" }),
  );
  const { data: photoUrl } = usePhotoUrl(orgMember?.photo_object_key);
  const renderProfile = (size = 8) => {
    return orgMember && photoUrl ? (
      <img
        className={`prevent-select my-0 h-${size} w-${size} shrink-0 rounded-full bg-purple-500 text-white`}
        src={photoUrl}
      />
    ) : user && user.idp_google_detail && user.idp_google_detail.picture ? (
      <img
        className={`prevent-select my-0 h-${size} w-${size} shrink-0 rounded-full bg-purple-500 text-white`}
        src={user.idp_google_detail.picture}
      />
    ) : (
      <div
        className={`prevent-select my-0 h-${size} w-${size} grid  shrink-0 place-items-center rounded-full text-white`}
        style={{
          backgroundColor: getRandomColor(user?.firstname, user?.lastname),
        }}
      >
        <h5 className="mt-[1.5px] text-center">{`${getInitials(
          user?.firstname,
          user?.lastname,
        )}`}</h5>
      </div>
    );
  };
  if (!user) return <></>;
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (isOpen) setIsOpen(false);
  });
  const renderItem = ({
    id,
    label,
    onClick,
  }: {
    id: number | string;
    label: string;
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }) => {
    return (
      <button
        key={id}
        className="block w-full truncate text-ellipsis px-[10px] py-2 text-left text-xs font-normal text-black hover:bg-gray-200 active:text-purple-500"
        onClick={(e: any) => {
          onClick(e);
        }}
      >
        {label}
      </button>
    );
  };
  return (
    <div ref={ref} className={classNames("group relative flex flex-col items-center")}>
      {user ? (
        <div
          onClick={() => setIsOpen((prev) => !prev)}
          className="grid place-items-center gap-[4px]"
        >
          <div
            className={classNames(
              "giboo-nav-profile flex justify-center",
              isOnboarding && " !h-fit !pb-0",
            )}
          >
            {renderProfile(6)}
          </div>
          <span className={classNames("inline-flex cursor-pointer items-center gap-2")}>
            <h6
              className={classNames(
                "font-poppins text-gray-900",
                isOnboarding ? "text-xs" : " text-sm",
              )}
            >
              Me
            </h6>
            <img src={arrowIcon} className="h-4 w-4" />
          </span>
        </div>
      ) : null}
      <div
        className={classNames(
          " group absolute right-0 z-[50]   h-fit  w-[211px] origin-top-right flex-col rounded-md bg-white shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)] ring-1 ring-black ring-opacity-5  focus:outline-none ",
          isOpen ? "block" : "hidden",
          isOnboarding ? "!top-[55px]" : " !top-[53.5px] min-h-[113px]",
        )}
      >
        <div className="mx-4 grid grow grid-cols-[22%_70%] place-items-center gap-3 pt-3">
          {renderProfile(9)}
          <div className="w-full">
            <h5
              title={userName}
              className="giboo-nav-label prevent-select m-0 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold"
            >
              {userName}
            </h5>
          </div>
        </div>
        <div className="mx-[1px] mt-3 h-[1px] w-full bg-gray-300"></div>
        {!isOnboarding &&
          renderItem({
            id: 1,
            label: "My profile",
            onClick: () => {
              mxEvent(MIXPANEL_EVENTS_V2.header[""].clicked, {
                to: Navigate.USER_PROFILE,
              });
              setIsOpen(false);
              navigate("/profile");
            },
          })}
        {!isOnboarding &&
          showMyOrgProfile &&
          renderItem({
            id: 2,
            label: "My organization profile",
            onClick: () => {
              mxEvent(MIXPANEL_EVENTS_V2.header[""].clicked, {
                to: Navigate.ORG_PROFILE,
              });
              setIsOpen(false);
              navigate(`/organization`);
            },
          })}
        {!isOnboarding &&
          renderItem({
            id: 3,
            label: "Saved list",
            onClick: () => {
              mxEvent(MIXPANEL_EVENTS_V2.header[""].clicked, {
                to: Navigate.SAVED_LIST,
              });
              setIsOpen(false);
              navigate(`/saved`);
            },
          })}
        {!isOnboarding &&
          process.env.REACT_APP_ROUTER === "FUNDER" &&
          renderItem({
            id: 4,
            label: "Email setting",
            onClick: () => {
              setIsOpen(false);
              navigate(`/emails`);
            },
          })}
        {!isOnboarding && <div className="mx-[1px] h-[1px] w-full bg-gray-300"></div>}
        <div
          onClick={() => {
            mxEvent(MIXPANEL_EVENTS_V2.header[""].clicked, {
              to: Navigate.LOGOUT,
            });
            setIsOpen(false);
            handleLogout?.();
          }}
          className="inline-flex w-full cursor-pointer items-center gap-3 px-[10px] py-2 text-black placeholder:!cursor-pointer  hover:bg-gray-200"
        >
          <h5 className="font-poppins text-sm  text-inherit">Log out</h5>
          <img src={logoutIcon} className="h-4 w-4" />
        </div>
      </div>
    </div>
  );
}
export default NavigationUserProfile;
