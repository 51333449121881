import { ReactNode, useState } from "react";
import useFunderStageAnalysis from "../../hooks/funder/useFunderStageAnalysis";
import useFunderStageAnalysisOverview from "../../hooks/funder/useFunderStageAnalysisOverview";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";
import useGibooMixpanel from "../../hooks/useGibooMixpanel";
import useOnboardingData from "../../hooks/useOnboarding";
import { MIXPANEL_EVENTS_V2 } from "../../mixpanel/mixpanel";
import NoInformation from "../../pages/donor-detail-view/components/NoInformation";
import no_info from "../../pages/img/funding-stage-no-info.svg";
import ChartLoader from "../ChartLoader";
import FundCharChart from "../dashboard/FundCharChart";
import GibooGradientDiv from "../GibooGradientDiv";
import VisibilityTracker from "../VisibilityTracker";
export default function FunderStageAnalysis({
  funder_id,
  compact = false,
  additionalComponent,
  ...props
}: {
  funder_id: string;
  wrapperClass?: string;
  compact?: boolean;
  additionalComponent?: ReactNode;
}) {
  const [onboardingData] = useOnboardingData();
  const mxEvent = useGibooMixpanel();
  const { data: funder, isLoading: isFunderLoading } = useFunderSearchResult(funder_id);
  const { data: fundCharAmount } = useFunderStageAnalysis(funder_id, "amount");
  const { data: fundCharCount } = useFunderStageAnalysis(funder_id, "count");
  const { data: fundCharOverview, isLoading: isOverviewLoading } =
    useFunderStageAnalysisOverview(funder_id);
  const [description, setDescription] = useState<ReactNode | undefined>();
  const isLoading = isFunderLoading || isOverviewLoading;
  const hasData = fundCharAmount.length > 0 && fundCharCount.length > 0 && fundCharOverview;
  const renderFundingStage = () => {
    return (
      <>
        <div
          className="flex flex-col gap-4"
          id="chart_view"
          aria-valuetext="average-amount-per-year"
        >
          <div className="border-b border-gray-300 ">
            <h5 className="underline-offset-[6px] text-base font-semibold underline">
              Average amount per year
            </h5>
          </div>
          {isLoading ? (
            <ChartLoader />
          ) : hasData ? (
            <VisibilityTracker
              elementIdOrRef={"funding-chart-view-1"}
              onVisible={() => {
                // console.log("chart-view: Average amount per year");
                mxEvent(MIXPANEL_EVENTS_V2.detail_view.visibility.observed, {
                  value: "Average amount per year",
                });
              }}
            >
              <FundCharChart
                returnCode={funder?.return_code || ""}
                selectedTab={1}
                fundCharAmount={fundCharAmount}
                fundCharOverview={fundCharOverview}
                fundCharCount={fundCharCount}
                width={600}
                selected={onboardingData.stage}
                compact={compact}
                setDescription={(description) => {
                  setDescription(description);
                }}
              />
            </VisibilityTracker>
          ) : (
            <NoInformation img={no_info} />
          )}
        </div>
        <div className="flex flex-col gap-4" id="chart_view" aria-valuetext="median-size-of-grant">
          <div className="border-b border-gray-300 ">
            <h5 className="underline-offset-[6px] text-base font-semibold underline">
              Median size of grant
            </h5>
          </div>
          {isLoading ? (
            <ChartLoader />
          ) : hasData ? (
            <VisibilityTracker
              elementIdOrRef={"funding-chart-view-2"}
              onVisible={() => {
                // console.log("chart-view: Median size of grant");
                mxEvent(MIXPANEL_EVENTS_V2.detail_view.visibility.observed, {
                  value: "Median size of grant",
                });
              }}
            >
              <FundCharChart
                returnCode={funder?.return_code || ""}
                selectedTab={2}
                fundCharAmount={fundCharAmount}
                fundCharOverview={fundCharOverview}
                fundCharCount={fundCharCount}
                width={600}
                selected={onboardingData.stage}
                compact={compact}
              />
            </VisibilityTracker>
          ) : (
            <NoInformation img={no_info} />
          )}
        </div>
        <div
          className="flex flex-col gap-4"
          id="chart_view"
          aria-valuetext="average-number-of-grants-per-year"
        >
          <div className="border-b border-gray-300 ">
            <h5 className="underline-offset-[6px] text-base font-semibold underline">
              Average number of grants per year
            </h5>
          </div>
          {isLoading ? (
            <ChartLoader />
          ) : hasData ? (
            <VisibilityTracker
              elementIdOrRef={"funding-chart-view-3"}
              onVisible={() => {
                // console.log("chart-view: Average number of grants per year");
                mxEvent(MIXPANEL_EVENTS_V2.detail_view.visibility.observed, {
                  value: "Average number of grants per year",
                });
              }}
            >
              <FundCharChart
                returnCode={funder?.return_code || ""}
                selectedTab={3}
                fundCharAmount={fundCharAmount}
                fundCharOverview={fundCharOverview}
                fundCharCount={fundCharCount}
                width={600}
                selected={onboardingData.stage}
                compact={compact}
              />
            </VisibilityTracker>
          ) : (
            <NoInformation img={no_info} />
          )}
        </div>
      </>
    );
  };
  return (
    <>
      {onboardingData.stage && description && (
        <GibooGradientDiv borderSize={2} wrapperClassName="my-4">
          <div className=" py-[10px] rounded bg-gray-100 px-4">{description}</div>
        </GibooGradientDiv>
      )}
      <div className="flex flex-col gap-10">
        {renderFundingStage()}
        {additionalComponent && additionalComponent}
      </div>
    </>
  );
}
