import classNames from "classnames";
import * as React from "react";
import "./css/input.scss";

interface LeftInnerIconInputProps {
  id: string;
  className?: string;
  type?: string;
  iconClass?: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green";
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  readonly?: boolean;
  placeholder?: string;
  value?: string | number;
  invalid?: boolean;
  disabled?: boolean;
  min?: number | undefined;
  max?: number | undefined;
  topPlaceholder?: string;
  defaultValue?: string;
  status?: "success" | "fail";
  offAutoComplete?: boolean;
  style?: React.CSSProperties;
}

const LeftInnerIconInput = React.forwardRef(function leftInnerIconInput(
  props: LeftInnerIconInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const colorClass = props.color ? `-${props.color}` : "";
  const rightIconClass = props.status
    ? props.status === "success"
      ? "fa-solid fa-check"
      : "fa-solid fa-x"
    : undefined;
  return (
    <div className="inner-addon">
      {props.iconClass ? (
        <i
          className={classNames(props.iconClass, "left-glyphicon", props.color, {
            "!pt-[16px]": props.topPlaceholder,
            "!top-[12px] !pt-[0px]": !props.topPlaceholder,
          })}
        />
      ) : null}
      {props.topPlaceholder ? (
        <h6 className="giboo-input-inner-label prevent-select gray !text-xs">
          {props.topPlaceholder}
        </h6>
      ) : null}
      <input
        ref={ref}
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        type={props.type ? props.type : "text"}
        className={classNames(
          "form-control giboo-input placeholder-gray-500 placeholder:font-poppins placeholder:font-normal",
          {
            "form-invalid": props.invalid,
            "!h-16 !pt-5": props.topPlaceholder,
          },
          colorClass ? `giboo-input${colorClass}` : "",
          "[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
          props.className,
        )}
        onFocus={props.handleOnFocus}
        onBlur={props.handleOnBlur}
        onChange={props.handleOnChange}
        onKeyDown={props.handleOnKeyDown}
        disabled={props.disabled}
        readOnly={props.readonly}
        min={props.min}
        max={props.max}
        style={{
          paddingLeft: props.iconClass ? "42px" : "18px",
          paddingRight: rightIconClass ? "36px" : "18px",
          ...props.style,
        }}
        defaultValue={props.defaultValue}
        {...(props.offAutoComplete ? { autoComplete: "off" } : {})}
      />
      {rightIconClass ? (
        <i className={classNames(rightIconClass, "right-glyphicon", props.color)}></i>
      ) : null}
    </div>
  );
});

export default LeftInnerIconInput;
