import {
  ActionMeta,
  GroupBase,
  components,
  MultiValue,
  OptionProps,
  MenuPlacement,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import "../css/selector.scss";
import classNames from "classnames";
import { ReactNode, useEffect, useRef, useState } from "react";
import { scrollBarClass } from "../../utils/classes";
import { UnifiedSearchResult } from "../../types/search";

interface IOption {
  iconRight?: JSX.Element;
  iconLeft?: JSX.Element;
  readonly value: string;
  readonly label: string;
  data?: any;
}
interface GroupBaseMultipleCreatableSelectorProps<T> {
  id: string;
  data: GroupBase<T>[] | GroupBase<T>[];
  placeholderClass?: string;
  value: T[];
  placeholder?: string;
  focusPlaceholder?: string;
  onCreateOption: (inputValue: string) => void;
  onChange: (newValue: MultiValue<T>, actionMeta: ActionMeta<T>) => void;
  disabled?: boolean;
  inputContainerClass?: string;
  formatCreateLabel?: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green" | "gray";
  className?: string;
  inputClass?: string;
  allowCreateOnLoad?: boolean;
  topAction?: ReactNode;
  handleTopAction?: (val: string) => void;
  compact?: boolean;
  isClearable?: boolean;
  dropdownIcon?: boolean;
  isRequired?: boolean;
  requiredMessage?: string;
  menuPlacement?: MenuPlacement;
  defaultOpen?: boolean;
  menuClass?: string;
}
const getP = (option: string, query: string): number => {
  if (!option) return 7;
  if (query === option) return 0;
  else if (option.startsWith(`${query} `)) return 1;
  else if (option.includes(` ${query} `)) return 2;
  else if (option.startsWith(`${query}`)) return 3;
  else if (option.includes(` ${query}`)) return 4;
  else if (option.includes(`${query}`)) return 5;
  return 6;
};
export const sortSearchResult = (
  a: UnifiedSearchResult,
  b: UnifiedSearchResult,
  input: string,
): number => {
  const query = input.toLowerCase();
  const al = a.name?.toLowerCase() || "";
  const bl = b.name?.toLowerCase() || "";
  const ap = getP(al, query);
  const bp = getP(bl, query);
  if (ap !== bp) return ap - bp;
  const ar = a.rank_conf || 0;
  const br = b.rank_conf || 0;
  if (ar !== br) return br - ar;
  return al.localeCompare(bl);
};

export const sortIOption = (
  a: { label: string; group?: string },
  b: { label: string; group?: string },
  input: string,
): number => {
  const query = input.toLowerCase();
  const al = a.label.toLowerCase();
  const bl = b.label.toLowerCase();
  const agl = a.group?.toLowerCase() || "";
  const bgl = b.group?.toLowerCase() || "";
  const minA = Math.min(getP(al, query), getP(agl, query) + 1);
  const minB = Math.min(getP(bl, query), getP(bgl, query) + 1);
  if (minA !== minB) return minA - minB;
  return a.label.localeCompare(b.label);
};
const allTheSameGroupIfFiltered = true;

function GroupBaseMultipleCreatableSelector<T extends IOption>({
  id,
  data,
  value,
  placeholder,
  onCreateOption,
  onChange,
  disabled,
  inputContainerClass,
  placeholderClass,
  color = "gray",
  formatCreateLabel,
  allowCreateOnLoad = false,
  className,
  inputClass,
  topAction,
  compact = false,
  isClearable = true,
  dropdownIcon = true,
  isRequired,
  requiredMessage,
  focusPlaceholder,
  handleTopAction,
  menuPlacement = "auto",
  defaultOpen = false,
  menuClass,
}: GroupBaseMultipleCreatableSelectorProps<T>) {
  const [inputValue, setInputValue] = useState<string>("");
  const [sortedData, setSortedData] = useState<GroupBase<T>[]>(data);
  useEffect(() => {
    if (!inputValue) {
      setSortedData(data);
      return;
    }
    const optionsWithGroup = data.reduce(
      (prev, cur) => [...prev, ...cur.options.map((o) => ({ ...o, group: cur.label }))],
      [] as [] as (T & { group?: string })[],
    );
    const sorted = optionsWithGroup.sort((a, b) => sortIOption(a, b, inputValue));
    const grouped = sorted.reduce(
      (prev, cur) =>
        allTheSameGroupIfFiltered || (prev.length > 0 && cur.group === prev[prev.length - 1].label)
          ? [
              ...prev.slice(0, prev.length - 1),
              {
                label: allTheSameGroupIfFiltered ? "" : prev[prev.length - 1].label,
                options: [
                  ...(prev.length > 0 ? prev[prev.length - 1].options : []),
                  { ...cur, group: undefined },
                ],
              },
            ]
          : [...prev, { label: cur.group || "", options: [{ ...cur, group: undefined }] }],
      [] as GroupBase<T>[],
    );
    setSortedData(grouped);
  }, [data, inputValue, setSortedData]);
  const ref = useRef<any>();
  const Option = (props: OptionProps<T>) => {
    const {
      className,
      isDisabled,
      isFocused,
      isSelected,
      innerRef,
      innerProps,
      data: tempData,
    } = props;
    const data = tempData as any;
    return (
      <>
        <div
          ref={innerRef}
          className={classNames(
            "text-normal group  z-50 select-none",
            isSelected && "!bg-gray-500  text-white",
            isDisabled && "bg-gray-300",
            !isDisabled && "hover:bg-gray-100",
            className,
          )}
          {...innerProps}
        >
          <div className="flex items-center justify-between border-b border-white p-3">
            {data?.iconLeft && <div className="pr-2">{data?.iconLeft}</div>}
            <div className="w-full">{data.label}</div>
            {data?.iconRight && <div className="pl-2">{data?.iconRight}</div>}
          </div>
        </div>
      </>
    );
  };
  const CustomMenu = ({ children, ...props }: any) => {
    return (
      <>
        {topAction && inputValue.length === 0 && (
          <div
            className={classNames("text-normal rounded p-3", `hover:bg-${color}-200`)}
            onClick={() => {
              ref?.current?.blur?.();
              handleTopAction?.(inputValue);
            }}
          >
            {topAction}
          </div>
        )}
        {children}
      </>
    );
  };
  const DropdownIndicator = (props: any) => {
    // if (props?.hasValue) {
    //   return <></>;
    // }
    return (
      <components.DropdownIndicator {...props} className="px-1">
        <i className={classNames("gi gi-angle-down")} />
      </components.DropdownIndicator>
    );
  };
  const Placeholder = (props: any) => {
    return (
      <components.Placeholder {...props}>
        {isRequired
          ? requiredMessage
          : focusPlaceholder
          ? props.isFocused
            ? focusPlaceholder
            : placeholder
          : placeholder}
      </components.Placeholder>
    );
  };
  const ClearIndicator = (props: any) => {
    return (
      <components.ClearIndicator {...props} className="px-1">
        <i className={classNames("gi gi-x cursor-pointer")} />
      </components.ClearIndicator>
    );
  };
  const MultiValueRemove = (props: any) => {
    return (
      <components.MultiValueRemove {...props} className="p-1">
        <i className={classNames("gi  gi-x scale-90 cursor-pointer")} />
      </components.MultiValueRemove>
    );
  };
  return (
    <CreatableSelect
      unstyled
      classNamePrefix="giboo"
      classNames={{
        control: (state) =>
          classNames(
            "rounded border px-3 text-gray-700 max-h-[90px] min-h-[56px] overflow-y-auto font-poppins",
            compact ? "py-0" : "py-2",
            state.isFocused ? `border-gray-400` : `border-gray-400`,
            // state.isDisabled && `!bg-gray-50`,
            className,
            inputContainerClass ? inputContainerClass : "!max-h-[90px] !min-h-[56px] ",
            isRequired && "!bg-purple-50 !rounded-none",
          ),
        menu: (state) =>
          classNames(
            `p-2 bg-white rounded shadow border border-gray-300`,
            "overscroll-contain max-h-[300px]",
            menuClass,
            scrollBarClass,
          ),
        menuList: (state) => classNames("flex flex-col"),
        option: (state) =>
          classNames(
            "p-3 rounded text-gray-700 !text-sm hover:bg-gray-200",
            `hover:bg-${color}-200`,
            state.isSelected ? `bg-${color}-300 __option--selected` : "",
            state.isFocused ? `bg-${color}-300 __option--focused` : "",
          ),
        groupHeading: (state) => "text-sm text-gray-500",
        placeholder: (state) =>
          classNames(
            "text-gray-500 text-sm font-poppins font-normal",
            placeholderClass,
            isRequired && "!text-purple-500",
          ),
        container: (state) => "p-0",
        input: (state) => classNames("creatable-select-input", inputClass),
        valueContainer: (state) => "flex flex-wrap gap-2",
        multiValue: (state) =>
          `rounded bg-${color}-200 border-${color}-500 px-2 !h-[24px] items-center`,
        multiValueRemove: (state) => "ml-1",
        indicatorsContainer: () => "grid place-items-center h-full",
      }}
      id={id}
      isMulti
      options={sortedData}
      value={value}
      components={{
        Option,
        MenuList: CustomMenu,
        DropdownIndicator: dropdownIcon ? DropdownIndicator : null,
        ClearIndicator,
        Placeholder,
        MultiValueRemove,
        // CrossIcon,
      }}
      ref={ref}
      onCreateOption={onCreateOption}
      onChange={onChange}
      onInputChange={(value) => {
        setInputValue(value);
      }}
      formatCreateLabel={(value) =>
        `${formatCreateLabel ? formatCreateLabel : "Click to add "} "${value}"`
      }
      createOptionPosition="first"
      allowCreateWhileLoading={allowCreateOnLoad}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 99999 }),
      }}
      menuPortalTarget={document.body}
      isDisabled={disabled}
      isClearable={isClearable}
      menuPlacement={menuPlacement}
    />
  );
}
export default GroupBaseMultipleCreatableSelector;
