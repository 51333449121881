import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import {
  SaveItem,
  SaveItemList,
  SaveItemListCreateRequest,
  SaveItemListUpdateRequest,
  SaveItemUpsertRequest,
} from "../../types/save";
import useOrgID from "../useOrgID";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
import { Philanthropy } from "../../types/philanthropy";
import { SearchQuery } from "../../types/search";
import useMySavedList from "./useMySavedList";
interface SaveState {
  data: string[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<string[] | undefined>;
  save: (save_list_id: string, save: boolean) => Promise<string[] | undefined>;
}
const emptyList: string[] = [];
function useSave(
  target_id?: string,
  target_type?: 0 | 1 | 3,
  searchQuery?: SearchQuery,
): SaveState {
  const { updateSize } = useMySavedList();
  const org_id = useOrgID();
  const url =
    process.env.REACT_APP_API_URL +
    `/api/v2/users/savedlist?org_id=${org_id}&target_id=${target_id}&target_type=${target_type}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<string[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as string[]);
  };
  const { data, isLoading, error, isValidating } = useSWR<string[]>(
    org_id && target_id && target_type !== undefined && target_type !== null ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<string[]>(url), [url]);
  const save = useCallback(
    async (save_list_id: string, save: boolean) => {
      if (!save_list_id || !target_id || target_type === undefined || target_type === null) return;
      const options = {
        optimisticData: [
          ...(data?.filter((l) => l !== save_list_id) || []),
          ...(save ? [save_list_id] : []),
        ],
        rollbackOnError: true,
        revalidate: data ? false : true,
      };
      updateSize(save_list_id, save ? 1 : -1);
      return mutate<string[]>(
        url,
        axios
          .put(
            process.env.REACT_APP_API_URL + `/api/v2/users/save`,
            {
              save_list_id,
              saved: save,
              target_id,
              target_type,
              search_query: save ? searchQuery : undefined,
            } as SaveItemUpsertRequest,
            config,
          )
          .then((response) => response.data)
          .then((res: SaveItem) => {
            return [
              ...(data?.filter((l) => l !== save_list_id) || []),
              ...(res.saved ? [save_list_id] : []),
            ];
          }),
        options,
      );
    },
    [url, data, searchQuery, target_id, target_type, updateSize],
  );
  return {
    data: data || emptyList,
    isLoading,
    error,
    isValidating,
    revalidate,
    save,
  };
}
export default useSave;
