import { addDays, compareAsc, parseISO } from "date-fns";
import { ILocation, isLocationEligible, reprLocation } from "../types/location";
import { IGrantRecommendationResult } from "../components/RecGrants";
import { GrantDeadline, IGrant } from "../types/grant";
import {
  TypeFiscalPosition,
  TypeGrantType,
  TypeSolicitation,
  fiscalPositionLabels,
  grantTypeKeys,
  grantTypeLabels,
} from "../types/grantDetail";
import { TypeStage } from "../types/onboarding";
import { FunderSearchResult, GrantSearchResult, SearchQuery, getValidDate } from "../types/search";
import { toUTCDateString, toUsdLong, toUsdShort } from "./formatHelper";
import { IProjectCreateRequest } from "../types/project";
import { ReactElement, memo, useEffect, useState } from "react";
import classNames from "classnames";
import { NavigateFunction } from "react-router-dom";
import { GeographicFocus, Philanthropy } from "../types/philanthropy";
import {
  filterUnmatched,
  matchTaxonomyBySearchQuery,
  matchTaxonomyBySearchQueryWithPhilanthropy,
} from "../types/taxonomy";

export const grantHistory = {
  seed: { arr: [], min: 0, max: 5000 },
  all: {
    arr: [
      20.51519066475946, 15.026739553945323, 13.399678469152942, 12.77416933531818,
      11.538673953082668, 11.202736043250168, 10.133142212400601, 11.507794640198696,
      9.884170519108435, 9.124121311829187, 9.686500527183219, 8.672425341971495, 8.290018846932618,
      7.820178962415188, 9.467605550082997, 7.839203788096944, 7.569855608330948, 8.303780748177102,
      7.098032082960526, 6.672425341971495, 6.870364719583405, 8.038918989292302,
      6.6293566200796095, 6.442943495848728, 6.569855608330948, 6.409390936137702,
      6.044394119358453, 5.807354922057604, 7.149747119504682, 5.584962500721156, 5.321928094887363,
      5.832890014164741, 4.754887502163468, 4.700439718141093, 5.20945336562895, 7.7414669864011465,
      4.857980995127572, 4.700439718141093, 4.754887502163468, 4.954196310386875, 4.643856189774724,
      3.584962500721156, 5.321928094887363, 4.459431618637297, 4.247927513443585, 4.247927513443585,
      4.169925001442312, 3.584962500721156, 4.087462841250339, 4.906890595608519, 3.321928094887362,
      4.169925001442312, 5.129283016944966, 3.321928094887362, 3.584962500721156, 3.0, 4.0,
      3.700439718141092, 3.807354922057604, 3.9068905956085187, 3.0, 3.169925001442312,
      3.169925001442312, 4.700439718141093, 2.807354922057604, 3.169925001442312, 3.0,
      3.169925001442312, 2.807354922057604, 2.584962500721156,
    ],
    min: 0,
    max: 10000000,
  },
  dreamer: {
    arr: [
      10.12670447284319, 9.612868497291041, 7.20945336562895, 9.918863237274595, 6.169925001442312,
      7.900866807980749, 5.20945336562895, 8.413627929024173, 8.467605550082997, 5.0,
      7.6653359171851765, 4.321928094887363, 5.584962500721156, 4.321928094887363,
      6.658211482751795, 4.807354922057604, 3.0, 9.67595703294175, 3.584962500721156,
      4.523561956057013, 4.0, 6.266786540694901, 4.392317422778761, 2.584962500721156,
      4.954196310386875, 2.321928094887362, 6.189824558880018, 2.807354922057604, 5.459431618637297,
      3.9068905956085187, 2.807354922057604, 4.247927513443585, 1.584962500721156, 3.0, 0.0,
      9.3151495622563, 3.4594316186372973, 2.584962500721156, 4.523561956057013, 2.321928094887362,
      3.4594316186372973, 2.807354922057604, 4.459431618637297, 4.523561956057013,
      1.584962500721156, 3.584962500721156, 1.0, 3.4594316186372973, 2.321928094887362,
      3.4594316186372973, 2.807354922057604, 1.584962500721156, 7.539158811108031, 1.0,
      2.584962500721156, 0.0, 3.169925001442312, 2.584962500721156, 1.0, 3.169925001442312,
      2.321928094887362, 3.9068905956085187, 1.0, 3.0, 1.584962500721156, 2.321928094887362,
      1.584962500721156, 0, 1.584962500721156, 2.0,
    ],
    min: 0,
    max: 5000,
  },
  startup: {
    arr: [
      13.858855208611182, 12.258271576282851, 10.959277505720502, 11.845097960972975,
      9.533329732305834, 9.485829308701904, 7.888743248898259, 11.122180901259753,
      8.144658242831882, 7.400879436282184, 9.328674927327947, 6.918863237274595,
      6.6865005271832185, 5.491853096329675, 9.483815777264256, 6.0, 6.066089190457772,
      9.564149489985732, 5.807354922057604, 5.700439718141093, 5.044394119358453, 8.238404739325079,
      5.0, 5.554588851677638, 7.0, 5.0, 4.754887502163468, 4.169925001442312, 6.918863237274595,
      4.754887502163468, 3.807354922057604, 6.044394119358453, 2.321928094887362,
      3.9068905956085187, 3.0, 8.744833837499545, 3.700439718141092, 2.807354922057604,
      4.954196310386875, 3.4594316186372973, 3.584962500721156, 3.0, 5.906890595608519,
      2.584962500721156, 3.321928094887362, 4.906890595608519, 4.0, 4.0, 2.807354922057604,
      4.754887502163468, 4.0, 2.584962500721156, 6.569855608330948, 2.584962500721156,
      3.321928094887362, 2.584962500721156, 4.087462841250339, 1.584962500721156, 1.0,
      4.459431618637297, 3.0, 2.321928094887362, 2.0, 3.807354922057604, 1.0, 1.584962500721156,
      2.584962500721156, 0.0, 1.584962500721156, 1.0,
    ],
    min: 0,
    max: 10000,
  },
  buildup: {
    arr: [
      17.411411680144823, 14.77071550529637, 13.755617848610903, 13.031528909787223,
      12.052568050804153, 10.987264012072538, 9.774787059601174, 12.02894204332241,
      9.90388184573618, 9.394462694610317, 10.197216693110052, 9.087462841250339, 8.118941072723507,
      7.523561956057013, 10.836839359748716, 7.7073591320808825, 7.614709844115208,
      8.643856189774725, 7.434628227636725, 6.870364719583405, 6.357552004618084, 9.184875342908283,
      6.475733430966398, 6.658211482751795, 6.954196310386875, 6.321928094887363, 5.672425341971495,
      5.700439718141093, 8.78135971352466, 5.906890595608519, 6.0, 6.409390936137702,
      5.459431618637297, 5.523561956057013, 5.20945336562895, 8.430452551665532, 4.857980995127572,
      4.906890595608519, 5.643856189774724, 4.954196310386875, 4.523561956057013, 4.906890595608519,
      7.392317422778761, 4.584962500721156, 4.321928094887363, 5.523561956057013, 4.584962500721156,
      4.392317422778761, 3.807354922057604, 5.954196310386875, 4.247927513443585, 3.700439718141092,
      5.247927513443585, 4.247927513443585, 4.321928094887363, 2.807354922057604, 6.459431618637297,
      3.169925001442312, 3.807354922057604, 2.807354922057604, 3.4594316186372973,
      3.700439718141092, 3.321928094887362, 4.857980995127572, 3.169925001442312, 3.584962500721156,
      3.4594316186372973, 3.169925001442312, 3.584962500721156, 4.321928094887363,
    ],
    min: 0,
    max: 20000,
  },
  superstar: {
    arr: [
      18.19130505492119, 15.371232128369622, 13.676508079524549, 13.675185213955384,
      11.877284133523196, 12.129605011152504, 10.191059214531656, 12.693269142893206,
      10.881878707600446, 9.779719355143405, 11.332596057789214, 9.20945336562895,
      9.702172685365548, 8.519636252843213, 11.109830654278793, 9.055282435501189,
      8.189824558880018, 10.845490050944376, 7.851749041416058, 8.108524456778168,
      7.285402218862249, 9.949826710759112, 7.392317422778761, 7.562242424221073, 8.566054038171092,
      6.794415866350106, 7.6865005271832185, 6.442943495848728, 8.915879378835774,
      6.832890014164741, 6.321928094887363, 7.451211111832329, 6.426264754702098, 6.266786540694901,
      6.0, 9.862637357558794, 6.169925001442312, 5.672425341971495, 6.672425341971495,
      5.285402218862249, 5.614709844115208, 5.554588851677638, 7.774787059601174, 5.832890014164741,
      5.321928094887363, 6.357552004618084, 5.087462841250339, 5.129283016944966, 5.0,
      6.672425341971495, 4.700439718141093, 5.20945336562895, 7.643856189774724, 4.087462841250339,
      4.857980995127572, 3.9068905956085187, 6.247927513443585, 4.584962500721156,
      3.807354922057604, 4.857980995127572, 2.807354922057604, 5.247927513443585, 4.247927513443585,
      5.491853096329675, 3.700439718141092, 4.087462841250339, 4.584962500721156, 3.584962500721156,
      3.584962500721156, 4.523561956057013,
    ],
    min: 0,
    max: 100_000,
  },
  hyperstar: {
    arr: [
      18.566217593022625, 13.77612455554518, 12.271463027904375, 11.754887502163468,
      10.53430288245463, 10.273795599214264, 9.144658242831882, 10.664447284547972,
      9.06339508128851, 8.243173983472952, 8.83605035505807, 7.7481928495894605, 7.499845887083206,
      7.011227255423254, 8.72451385311995, 7.087462841250339, 6.714245517666122, 7.643856189774724,
      6.285402218862249, 5.807354922057604, 6.108524456778169, 7.348728154231077, 5.807354922057604,
      5.459431618637297, 5.672425341971495, 5.491853096329675, 4.584962500721156, 4.954196310386875,
      6.507794640198696, 4.857980995127572, 4.906890595608519, 5.087462841250339, 4.087462841250339,
      4.169925001442312, 4.087462841250339, 7.011227255423254, 4.321928094887363,
      3.4594316186372973, 4.169925001442312, 4.321928094887363, 3.700439718141092,
      2.321928094887362, 4.754887502163468, 3.700439718141092, 3.807354922057604, 3.807354922057604,
      3.4594316186372973, 2.584962500721156, 3.321928094887362, 4.169925001442312,
      2.321928094887362, 3.807354922057604, 3.9068905956085187, 1.0, 2.807354922057604,
      2.321928094887362, 3.169925001442312, 2.807354922057604, 2.584962500721156, 2.807354922057604,
      0.0, 2.0, 2.321928094887362, 3.4594316186372973, 2.321928094887362, 2.0, 2.321928094887362,
      2.321928094887362, 2.0, 0.0,
    ],
    min: 0,
    max: 500_000,
  },
} as { [key: string]: { arr: number[]; min: number; max: number } };

const categoryMap: { [key: string]: string } = {
  ACA: "Affordable Care Act",
  AG: "Agriculture",
  AR: "Arts",
  BC: "Business and Commerce",
  DPR: "Disaster Prevention and Relief",
  EN: "Energy",
  HL: "Health",
  IS: "Information and Statistics",
  NR: "Natural Resources",
  RA: "Recovery Act",
  T: "Transportation",
  CD: "Community Development",
  ED: "Education",
  ENV: "Environment",
  HO: "Housing",
  ISS: "Income Security and Social Services",
  O: "Other",
  RD: "Regional Development",
  CP: "Consumer Protection",
  ELT: "Employment, Labor and Training",
  FN: "Food and Nutrition",
  HU: "Humanities",
  LJL: "Law, Justice and Legal Services",
  OZ: "Opportunity Zone Benefits",
  ST: "Science and Technology and other Research and Development",
};

const getPhilanthropyFiscalPosition = (grant?: { fiscal_position?: string[] }): string => {
  if (!grant || !grant.fiscal_position || grant.fiscal_position.length === 0) return "";
  const p = ["without", "sponsored", "exemption"];
  for (let i = 0; i < p.length; i++) {
    if (grant.fiscal_position?.includes(p[i]))
      return fiscalPositionLabels[p[i] as TypeFiscalPosition][0];
  }
  return "";
};

const reprGovernmentGrantCategory = (code: string): string => {
  return categoryMap[code];
};

////////// new
const isActiveGrant = (g: { deadline?: string; rolling: boolean }): boolean => {
  if (g.rolling) return true;
  if (g.deadline) {
    const validDate = parseISO(g.deadline);
    const currentDate = new Date();
    return compareAsc(currentDate, addDays(validDate, 1)) <= 0;
  } else return true;
};
const getGrantTitle = (grant?: {
  title?: string;
  name?: string;
  donor?: { name: string };
  uloc?: string;
}): string => {
  return grant?.name
    ? grant.name
    : grant?.title
    ? grant.title
    : grant?.donor?.name
    ? `${grant.donor.name} Grant`
    : grant?.uloc
    ? `${grant.uloc} Grant`
    : "Grant";
};
const getAgencyName = (grant: {
  donor?: { name: string };
  agency_name?: string;
  grant_maker?: string;
  valid_grant_maker?: boolean;
  uloc?: string;
  type: string;
}): string => {
  const agency =
    grant.valid_grant_maker && grant.grant_maker ? grant.grant_maker : grant.agency_name;
  return grant.donor
    ? grant.donor.name
    : agency
    ? `${agency.replaceAll("&amp;", "&")}${
        grant.uloc && agency.toLowerCase().includes(grant.uloc.toLowerCase())
          ? ""
          : grant.uloc
          ? ` (${grant.type === "state" ? `State of ${grant.uloc}` : grant.uloc})`
          : ""
      }`
    : grant.type === "state"
    ? `State of ${grant.uloc}`
    : grant.uloc || "";
};

const isEligible = (
  tax_exemption?: string,
  needTaxExemption?: boolean,
  withoutTaxExemption?: boolean,
  service_specific_loc?: boolean,
  matchedLocation?: ILocation[],
  needReferral?: boolean,
): number => {
  const is501c3 = tax_exemption === "yes" || tax_exemption === "applied";
  const ok501c3 = needTaxExemption && is501c3;
  const eligible =
    ((ok501c3 || withoutTaxExemption) &&
      (!service_specific_loc || (matchedLocation && matchedLocation.length > 0))) ||
    false;
  if (!needReferral && eligible) return 2;
  if (needReferral && eligible) return 1;
  return 0;
};

const isGrantEligible = (
  tax_exemption?: string,
  query_location?: ILocation[],
  grant?: {
    fiscal_position?: string[];
    service_specific_loc?: boolean;
    service_loc?: ILocation[];
    private?: boolean;
    donor_name?: string;
  },
): number => {
  const service_loc = grant?.service_loc?.map((item) => ({
    ...item,
    matched: isLocationEligible(item, query_location || []),
  }));
  return isEligible(
    tax_exemption,
    grant?.fiscal_position?.includes("exemption") || false,
    grant?.fiscal_position?.includes("without") ||
      grant?.fiscal_position?.includes("sponsored") ||
      false,
    grant?.service_specific_loc,
    service_loc?.filter((i) => i.matched),
    grant?.private,
  );
};

const reprGrantAmount = (
  grant: {
    specific_grant_amount?: boolean;
    grant_amount?: number;
    grant_amount_max?: number;
    grant_amount_min?: number;
    grant_amount_median?: number;
  },
  long = true,
) => {
  const func = long ? toUsdLong : toUsdShort;
  return !grant.specific_grant_amount || (!grant.grant_amount_min && !grant.grant_amount_max)
    ? "Not fixed"
    : grant.grant_amount_min !== null &&
      grant.grant_amount_min !== undefined &&
      grant.grant_amount_min === grant.grant_amount_max
    ? func(grant.grant_amount_min)
    : grant.grant_amount_min !== null &&
      grant.grant_amount_min !== undefined &&
      grant.grant_amount_max !== null &&
      grant.grant_amount_max !== undefined
    ? `${func(grant.grant_amount_min)}-${func(grant.grant_amount_max)}${
        grant.grant_amount_median ? `, Median: ${func(grant.grant_amount_median)}` : ""
      }`
    : "Not fixed";
};

const parseDeadline = (
  deadline: {
    type?: string;
    start?: Date | string;
    end?: Date | string;
  },
  onlyEnd?: boolean,
): string | undefined => {
  try {
    if (onlyEnd) {
      return deadline.type === "specific"
        ? deadline.end
          ? toUTCDateString(deadline.end, false)
          : deadline.start
          ? toUTCDateString(deadline.start, false)
          : "Rolling"
        : "Rolling";
    } else
      return deadline.type === "specific"
        ? deadline.start && deadline.end && deadline.start !== deadline.end
          ? `${toUTCDateString(deadline.start, false)} - ${toUTCDateString(deadline.end, false)}`
          : deadline.start
          ? toUTCDateString(deadline.start, false)
          : deadline.end
          ? toUTCDateString(deadline.end, false)
          : "Rolling"
        : "Rolling";
  } catch {
    return undefined;
  }
};

// const reprDeadline = (deadline?: GrantDeadline, onlyEnd?: boolean): string | undefined => {
//   if (!deadline) return "Rolling";
//   return parseDeadline(deadline, onlyEnd);
// };

const reprGrantDeadline = ({
  type,
  rolling,
  deadline,
}: {
  type?: string;
  rolling?: boolean;
  deadline?: string;
}): string | undefined => {
  if ((rolling && !deadline) || type === "grant-page") return "Rolling";
  if (rolling && deadline) return `Rolling (Grant cycle: ${toUTCDateString(deadline, false)})`;
  if (deadline) return toUTCDateString(deadline, false);
  return "Not specified";
};
const oldGrantFundingTypeMap = {
  "General Operating Support/ Unrestricted Grants": ["general"],
  "Program/ Project": ["program"],
  "Endowment / Capital Building": ["endowment", "capital"],
  "Matching Gifts": ["matching"],
  Scholarship: ["scholarship"],
  Fellowship: ["fellowship"],
  "Community Support": ["community"],
  Relief: ["relief"],
  "Capacity Building ": ["capacity"],
  Religious: ["religious"],
  Research: ["research"],
  Other: ["others"],
  Others: ["others"],
};
const transformGrantFundingType = (funding_type?: string[]): TypeGrantType[] => {
  const oldFundingType =
    funding_type
      ?.filter((o) => Object.keys(oldGrantFundingTypeMap).includes(o.trim()))
      .map((o) => oldGrantFundingTypeMap[o.trim() as keyof typeof oldGrantFundingTypeMap])
      .reduce((prev, cur) => [...prev, ...cur], []) || [];
  const newFundingType =
    funding_type?.filter((t) => grantTypeKeys.includes(t) && !oldFundingType.includes(t)) || [];
  return [...oldFundingType, ...newFundingType] as TypeGrantType[];
};
const reprGrantFundingType = (
  grant?: { funding_type?: string[] },
  query?: { funding_type?: string[] },
  max = 15,
): string | ReactElement => {
  const transformed = transformGrantFundingType(grant?.funding_type);
  const sorted = [
    ...transformed.filter((t) => query?.funding_type?.includes(t)),
    ...transformed.filter((t) => !query?.funding_type?.includes(t)),
  ];
  const subs = Object.keys(grantTypeLabels).filter((t) => sorted.includes(t as TypeGrantType));
  return sorted.length > 0 ? (
    <span className="font-normal">
      {`${subs
        .slice(0, max)
        .map((g) => grantTypeLabels[g as TypeGrantType])
        .join(", ")}`}
      <span className="font-normal">{`${
        subs.length > max ? `... +${subs.length - max}` : ""
      }`}</span>
    </span>
  ) : (
    "Others"
  );
};
const prioritySortLocation = (
  a: GeographicFocus,
  b: GeographicFocus,
  priority?: string[][],
): number => {
  if (!priority) return 0;
  const pa = priority.findIndex((arr: string[]) => a.label && arr.includes(a.label));
  const pb = priority.findIndex((arr: string[]) => b.label && arr.includes(b.label));
  if (pa != pb) return pa > pb ? -1 : 1;
  return (b.amount || 0) - (a.amount || 0);
};
const buildPriorityLocation = (must_have?: ILocation[], good_to_have?: ILocation[]): string[][] => {
  const must_have_with_state = [
    ...(must_have || []),
    ...(must_have
      ?.filter((l) => l.level >= 2 && l.iso2 === "US")
      .map((l) => ({ ...l, level: 1 } as ILocation)) || []),
  ];
  const good_to_have_with_state = [
    ...(good_to_have || []),
    ...(good_to_have
      ?.filter((l) => l.level >= 2 && l.iso2 === "US")
      .map((l) => ({ ...l, level: 1 } as ILocation)) || []),
  ];
  return [
    ...good_to_have_with_state
      .filter((l) => l.level >= 0 && l.level <= 4)
      .reduce(
        (prev, cur) => {
          const idx = cur.level;
          return [...prev.slice(0, idx), [...prev[idx], reprLocation(cur)], ...prev.slice(idx + 1)];
        },
        [[], [], [], [], []] as string[][],
      ),
    ...must_have_with_state
      .filter((l) => l.level >= 0 && l.level <= 4)
      .reduce(
        (prev, cur) => {
          const idx = cur.level;
          return [...prev.slice(0, idx), [...prev[idx], reprLocation(cur)], ...prev.slice(idx + 1)];
        },
        [[], [], [], [], []] as string[][],
      ),
  ];
};
const reprServiceLocations = ({
  service_loc,
  detail = true,
  max = 3,
  selected = false,
  scrollFunc,
  query,
  merge = true,
}: {
  service_loc?: ILocation[];
  detail?: boolean;
  max?: number;
  selected?: boolean;
  scrollFunc?: () => void;
  query?: SearchQuery;
  merge?: boolean;
}) => {
  const [sorted, setSorted] = useState<ILocation[]>([]);
  useEffect(() => {
    if (!service_loc) setSorted([]);
    else {
      const priority = buildPriorityLocation(query?.must_service_loc, query?.service_loc);
      setSorted(
        (merge ? mergeToState(service_loc) : service_loc)
          .map((l) => ({ ...l, label: reprLocation(l) }))
          .sort((a, b) => prioritySortLocation(a, b, priority)),
      );
    }
  }, [service_loc, setSorted]);
  const _max = Math.min(max, sorted?.length || 0);
  return (
    <span className="">
      {sorted
        .map((l) => reprLocation(l))
        .slice(0, _max)
        .map((l, i) => (
          <span
            key={l}
            className={classNames(
              "font-normal",
              i === _max - 1
                ? ""
                : selected
                ? "mr-1 border-r border-purple-200 pr-1"
                : "mr-1 border-r border-gray-300 pr-1",
            )}
          >
            {l}
          </span>
        ))}

      <span className="!text-black">
        {`${sorted && sorted.length > max ? `... +${sorted.length - max}` : ""}`}
      </span>
      {sorted && sorted.length > max && scrollFunc ? (
        <span
          className="ml-3 cursor-pointer text-gray-700 hover:underline"
          onClick={() => {
            scrollFunc?.();
          }}
        >
          View all
        </span>
      ) : null}
    </span>
  );
};
const mergeToState = (loc?: ILocation[]): ILocation[] =>
  loc?.reduce(
    (prev, cur) => [
      ...prev,
      ...(cur.level >= 1 && !prev.map((l) => l.state_id).includes(cur.state_id)
        ? [{ ...cur, level: 1, city: "" }]
        : []),
    ],
    [] as ILocation[],
  ) || [];
const ReprServiceLocations = memo(reprServiceLocations);
const reprGrantServiceLocations = ({
  grant,
  detail = true,
  max = 3,
  selected = false,
  scrollFunc,
  query,
}: {
  grant?: {
    type?: string;
    service_loc_text?: string;
    service_loc?: ILocation[];
    service_specific_loc?: boolean;
  };
  detail?: boolean;
  max?: number;
  selected?: boolean;
  scrollFunc?: () => void;
  query?: SearchQuery;
}) => {
  const [matched, setMatched] = useState<string>("");
  useEffect(() => {
    if (grant && grant.service_specific_loc === false) {
      setMatched(
        (grant?.type === "grant-page"
          ? mergeToState(grant?.service_loc?.filter((l) => l.matched))
          : grant?.service_loc?.filter((l) => l.matched) || []
        )
          .map((l) => reprLocation(l))
          .join("; ") || "",
      );
    } else {
      setMatched("");
    }
  }, [grant]);
  return (
    <>
      {grant && grant.service_specific_loc === false ? (
        <span
          className={classNames("font-normal", detail ? "" : "w-full truncate")}
        >{`Open to any geograhpical location${matched ? ` (including ${matched})` : ""}`}</span>
      ) : grant?.type === "grant-page" ? (
        <ReprServiceLocations
          service_loc={grant?.service_loc}
          detail={detail}
          max={max}
          selected={selected}
          scrollFunc={scrollFunc}
          query={query}
        />
      ) : grant?.service_loc_text ? (
        <span className={classNames("font-normal text-gray-900", detail ? "" : "w-full truncate")}>
          {grant?.service_loc_text}
        </span>
      ) : (
        <span className="font-normal">Not specified</span>
      )}
    </>
  );
};
const ReprGrantServiceLocations = memo(reprGrantServiceLocations);
const reprGrantEligibleNPOLocations = (
  grant?: {
    type?: string;
    npo_loc_text?: string;
    npo_loc?: ILocation[];
    npo_specific_loc?: boolean;
  },
  detail = true,
): string => {
  if (grant && grant.npo_specific_loc === false) {
    const matched =
      grant?.npo_loc
        ?.filter((l) => l.matched)
        .map((l) => reprLocation(l))
        .join("; ") || "";
    return `All${matched ? ` (including ${matched})` : ""}`;
  } else if (grant?.type === "grant-page") return "";
  else if (grant?.npo_loc_text) return grant?.npo_loc_text;
  return "Not specified";
};
const matchGrantFocusArea = (
  item: {
    focus_area?: string[];
    beneficiary?: string[];
    program?: string[];
    service_loc?: ILocation[];
  },
  query: SearchQuery,
  keep_service_loc?: boolean,
) => {
  const getMatchedTaxonomyCount = (item: Philanthropy): number => {
    const matched = filterUnmatched(item);
    return (
      (matched.focus_area?.length || 0) +
      (matched.beneficiary?.length || 0) +
      (matched.program?.length || 0) +
      (matched.service_loc?.filter((i) => i.matched).length || 0)
    );
  };
  const matched = matchTaxonomyBySearchQuery(
    {
      focus_area: item.focus_area || [],
      beneficiary: item.beneficiary || [],
      program: item.program || [],
      service_loc: item.service_loc || [],
    },
    query,
    true,
    keep_service_loc,
  );
  const sortFunc = (a: { matched?: boolean }, b: { matched?: boolean }) => {
    if (a.matched && !b.matched) return -1;
    if (!a.matched && b.matched) return -1;
    return 0;
  };
  return {
    ...matched,
    focus_area: matched.focus_area?.sort(sortFunc),
    beneficiary: matched.beneficiary?.sort(sortFunc),
    program: matched.program?.sort(sortFunc),
    matchedCount: getMatchedTaxonomyCount(matched),
  };
};
const getProjectCreationRequestFromGrant = (
  grant: GrantSearchResult,
  org_id: string,
  searchQuery?: SearchQuery,
  grantDetail?: IGrant,
): IProjectCreateRequest => {
  const matched = matchTaxonomyBySearchQueryWithPhilanthropy(grant, searchQuery, true, false);
  const matchedFocusAreas = searchQuery
    ? {
        FocusAreas:
          grantDetail?.FocusAreas?.map((a) => ({
            ...a,
            ...matchGrantFocusArea(a, searchQuery),
          }))
            .filter((a) => a.matchedCount)
            .sort((a, b) => b.matchedCount - a.matchedCount) || [],
        Beneficiaries:
          grantDetail?.Beneficiaries?.map((a) => ({
            ...a,
            ...matchGrantFocusArea(a, searchQuery),
          }))
            .filter((a) => a.matchedCount)
            .sort((a, b) => b.matchedCount - a.matchedCount) || [],
        Programs:
          grantDetail?.Programs?.map((a) => ({
            ...a,
            ...matchGrantFocusArea(a, searchQuery),
          }))
            .filter((a) => a.matchedCount)
            .sort((a, b) => b.matchedCount - a.matchedCount) || [],
        GeographicalPreferences:
          grantDetail?.GeographicalPreferences?.map((a) => ({
            ...a,
            ...matchGrantFocusArea(
              a,
              searchQuery,
              grantDetail?.type === "grant-page" ? false : true,
            ),
          }))
            .filter((a) => a.matchedCount)
            .sort((a, b) => b.matchedCount - a.matchedCount) || [],
      }
    : {
        FocusAreas: [],
        Beneficiaries: [],
        Programs: [],
        GeographicalPreferences: [],
      };
  return {
    name: `${grant.name} project`,
    org_id,
    completed: false,
    ...(grant.deadline
      ? {
          project_start: new Date().toISOString(),
          project_end: grant.deadline,
        }
      : {}),
    project_timeline: grant.deadline ? 2 : grant.rolling ? 1 : 0,
    rolling: grant.rolling || !grant.deadline,
    fund_to_raise: grant?.grant_amount_min,
    problem_desc: (matchedFocusAreas.FocusAreas.length > 0
      ? matchedFocusAreas.FocusAreas
      : grantDetail?.FocusAreas
    )
      ?.map((t) => `${t.title}\n${t.description}\n`)
      .join("\n"),
    beneficiary_desc: (matchedFocusAreas.Beneficiaries.length > 0
      ? matchedFocusAreas.Beneficiaries
      : grantDetail?.Beneficiaries
    )
      ?.map((t) => `${t.title}\n${t.description}\n`)
      .join("\n"),
    activity_desc: (matchedFocusAreas.Programs.length > 0
      ? matchedFocusAreas.Programs
      : grantDetail?.Programs
    )
      ?.map((t) => `${t.title}\n${t.description}\n`)
      .join("\n"),
    service_area_desc: (matchedFocusAreas.GeographicalPreferences.length > 0
      ? matchedFocusAreas.GeographicalPreferences
      : grantDetail?.GeographicalPreferences
    )
      ?.map((t) => `${t.title}`)
      .join("\n"),
    focus_area: grant.focus_area?.filter((t) => t.matched).map((t) => t.label) || [],
    beneficiary: grant.beneficiary?.filter((t) => t.matched).map((t) => t.label) || [],
    program: grant.program?.filter((t) => t.matched).map((t) => t.label) || [],
    // service_loc: matched?.service_loc,
  };
};
const getProjectCreationRequestFromFunder = (
  funder: FunderSearchResult,
  org_id: string,
  searchQuery?: SearchQuery,
): IProjectCreateRequest => {
  const matched = matchTaxonomyBySearchQueryWithPhilanthropy(funder, searchQuery);
  return {
    name: `${funder.name} project`,
    org_id,
    completed: false,
    fund_to_raise: funder.grant_amount_median,
    project_timeline: 2,
    project_start: new Date().toISOString(),
    focus_area: funder.focus_area?.filter((t) => t.matched).map((t) => t.label) || [],
    beneficiary: funder.beneficiary?.filter((t) => t.matched).map((t) => t.label) || [],
    program: funder.program?.filter((t) => t.matched).map((t) => t.label) || [],
    service_loc: matched?.service_loc,
  };
};
const isGrantPrivate = (grant?: {
  type: string;
  donor?: { does_not_accept_unsolicited: boolean };
  private: boolean;
}): boolean => {
  return grant?.type === "grant-page" && grant?.donor
    ? grant.donor.does_not_accept_unsolicited !== true
      ? false
      : true
    : grant?.private
    ? true
    : false;
};
export {
  getProjectCreationRequestFromGrant,
  getProjectCreationRequestFromFunder,
  parseDeadline,
  // reprDeadline,
  getPhilanthropyFiscalPosition,
  reprGovernmentGrantCategory,
  getGrantTitle,
  isActiveGrant,
  getAgencyName,
  isGrantEligible,
  reprGrantAmount,
  reprGrantDeadline,
  reprGrantFundingType,
  ReprServiceLocations,
  ReprGrantServiceLocations,
  reprGrantEligibleNPOLocations,
  transformGrantFundingType,
  isGrantPrivate,
  prioritySortLocation,
  buildPriorityLocation,
  mergeToState,
  matchGrantFocusArea,
};
