import { useState, useEffect } from 'react';

function useDeviceType() {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const mobileVersion = window.matchMedia("(max-width: 600px)").matches;
      const tabletVersion = window.matchMedia("(min-width: 601px) and (max-width: 1024px)").matches;
      const laptopVersion = window.matchMedia("(min-width: 1025px) and (max-width: 1440px)").matches;
      setIsMobile(mobileVersion);
      setIsTablet(tabletVersion);
      setIsLaptop(laptopVersion);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isMobile, isTablet, isLaptop };
}

export default useDeviceType;
