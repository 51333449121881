import axios from "axios";
import { useEffect, useState } from "react";
import useSWR from "swr";
import config from "../api";
import { NPOIRSProfile } from "../types/npo";
import { swrOptionDedupling5mins } from "../types/swr";
import useForecasts from "./useForecasts";
export interface NPOIRSProfileState {
  data: NPOIRSProfile[];
  latest_year: number | undefined;
  earliest_year: number | undefined;
  revenueModel: RevenueModel | undefined;
  totalContribution: Contribution[];
  contributionModel: ContributionModel | undefined;
  revenueStream: Revenue[];
  revenueStreamInfo: RevenueInfo | undefined;
  operatingExpenses: OperatingExpenses | undefined;
  operating: Operating[];
  operatingInfo: OperatingInfo | undefined;
  monthOfLiquid: MonthOfLiquid[];
  assets: Assets[];
  assetInfo: AssetInfo | undefined;
  assetsTransposed: AssetsTransposed | undefined;
  error: any;
  isLoading: boolean;
  latest: NPOIRSProfileWithNetProfit | undefined;
  beforeLatest: NPOIRSProfileWithNetProfit | undefined;
}
export interface Contribution {
  tax_year: number;
  grants_gov: number;
  grants_tot: number;
  grants_phil: number;
  grants_ind: number;
  is_predicted?: boolean;
  is_interpolated?: boolean;
}
export interface ContributionModel {
  tax_year: number;
  grants_gov: number;
  grants_phil: number;
  grants_ind: number;
  per_grants_gov: number;
  per_grants_phil: number;
  per_grants_ind: number;
  diff_grants_gov: number;
  diff_grants_phil: number;
  diff_grants_ind: number;
}
export interface RevenueModel {
  years: number;
  tax_year: number;
  contributions: number;
  investment: number;
  operation: number;
  per_contributions: number;
  per_investment: number;
  per_operation: number;
}
export interface Revenue {
  tax_year: number;
  contributions: number;
  investment: number;
  operation: number;
  is_predicted?: boolean;
  is_interpolated?: boolean;
}
export interface RevenueInfo {
  tax_year: number;
  contributions: number;
  investment: number;
  operation: number;
  diff_contributions: number;
  diff_investment: number;
  diff_operation: number;
  diff_months_of_cash: number;
}
export interface OperatingExpenses {
  tax_year: number;
  program: number;
  operating: number;
  fundraising: number;
  per_program: number;
  per_operating: number;
  per_fundraising: number;
  diff_program: number;
  diff_operating: number;
  diff_fundraising: number;
}
export interface Operating {
  tax_year: number;
  revenue: number;
  expenses: number;
  net_profit: number;
  is_predicted?: boolean;
  is_interpolated?: boolean;
}
export interface OperatingInfo {
  tax_year: number;
  revenue: number;
  expenses: number;
  net_profit: number;
  diff_revenue: number;
  diff_expenses: number;
  diff_net_profit: number;
}
export interface MonthOfLiquid {
  tax_year: number;
  months_of_cash: number;
  is_predicted?: boolean;
  is_interpolated?: boolean;
}
export interface Assets {
  tax_year: number;
  asset_cash: number;
  asset_receivable: number;
  asset_investment: number;
  asset_property: number;
  assets_total: number;
  diff_assets_total: number;
  is_predicted?: boolean;
  is_interpolated?: boolean;
}
interface AssetInfo {
  asset_cash: number;
  asset_receivable: number;
  asset_investment: number;
  asset_property: number;
  per_asset_cash: number;
  per_asset_receivable: number;
  per_asset_investment: number;
  per_asset_property: number;
  diff_asset_cash: number;
  diff_asset_receivable: number;
  diff_asset_investment: number;
  diff_asset_property: number;
}
export interface AssetsTransposed {
  tax_year: number[];
  cash: number[];
  receivable: number[];
  investment: number[];
  property: number[];
  is_predicted: (boolean | null | undefined)[];
  is_interpolated: (boolean | null | undefined)[];
}

const expenseCols = ["program", "operating", "fundraising"];
const revenueCols = ["contributions", "investment", "operation"];
const assetCols = ["asset_cash", "asset_receivable", "asset_investment", "asset_property"];
const grantCols = ["grants_gov", "grants_phil", "grants_ind"];
const nonNegativeColumns = [
  ...expenseCols,
  ...revenueCols,
  ...assetCols,
  ...grantCols,
  "grants_tot",
];
const allColumns = [...nonNegativeColumns, "luna", "months_of_cash"];
const allColumnsWithNetProfit = [...allColumns, "revenue", "expenses", "net_profit"];
interface NPOIRSProfileWithNetProfit extends NPOIRSProfile {
  revenue: number;
  expenses: number;
  net_profit: number;
  assets_total: number;
  is_predicted: boolean;
  is_interpolated: boolean;
}
interface DiffNPOIRSProfileWithNetProfit {
  diff_program: number;
  diff_operating: number;
  diff_fundraising: number;
  diff_contributions: number;
  diff_investment: number;
  diff_operation: number;
  diff_asset_cash: number;
  diff_asset_receivable: number;
  diff_asset_investment: number;
  diff_asset_property: number;
  diff_grants_gov: number;
  diff_grants_tot: number;
  diff_grants_phil: number;
  diff_grants_ind: number;
  diff_luna: number;
  diff_months_of_cash: number;
  diff_revenue: number;
  diff_expenses: number;
  diff_net_profit: number;
  diff_assets_total: number;
}
interface ProportionNPOIRSProfileWithNetProfit {
  per_program: number;
  per_operating: number;
  per_fundraising: number;
  per_contributions: number;
  per_investment: number;
  per_operation: number;
  per_asset_cash: number;
  per_asset_receivable: number;
  per_asset_investment: number;
  per_asset_property: number;
  per_grants_gov: number;
  per_grants_tot: number;
  per_grants_phil: number;
  per_grants_ind: number;
  per_luna: number;
  per_months_of_cash: number;
  per_revenue: number;
  per_expenses: number;
  per_net_profit: number;
}
const defaultAllZeroIRS: NPOIRSProfileWithNetProfit = {
  npo_id: "",
  tax_year: 0,
  program: 0,
  operating: 0,
  fundraising: 0,
  contributions: 0,
  investment: 0,
  operation: 0,
  asset_cash: 0,
  asset_receivable: 0,
  asset_investment: 0,
  asset_property: 0,
  grants_gov: 0,
  grants_tot: 0,
  grants_phil: 0,
  grants_ind: 0,
  luna: 0,
  months_of_cash: 0,
  revenue: 0,
  expenses: 0,
  net_profit: 0,
  assets_total: 0,
  is_predicted: false,
  is_interpolated: false,
};
const defaultAllZeroIRSDiff: DiffNPOIRSProfileWithNetProfit = {
  diff_program: 0,
  diff_operating: 0,
  diff_fundraising: 0,
  diff_contributions: 0,
  diff_investment: 0,
  diff_operation: 0,
  diff_asset_cash: 0,
  diff_asset_receivable: 0,
  diff_asset_investment: 0,
  diff_asset_property: 0,
  diff_grants_gov: 0,
  diff_grants_tot: 0,
  diff_grants_phil: 0,
  diff_grants_ind: 0,
  diff_luna: 0,
  diff_months_of_cash: 0,
  diff_revenue: 0,
  diff_expenses: 0,
  diff_net_profit: 0,
  diff_assets_total: 0,
};
const defaultAllZeroIRSProportion: ProportionNPOIRSProfileWithNetProfit = {
  per_program: 0,
  per_operating: 0,
  per_fundraising: 0,
  per_contributions: 0,
  per_investment: 0,
  per_operation: 0,
  per_asset_cash: 0,
  per_asset_receivable: 0,
  per_asset_investment: 0,
  per_asset_property: 0,
  per_grants_gov: 0,
  per_grants_tot: 0,
  per_grants_phil: 0,
  per_grants_ind: 0,
  per_luna: 0,
  per_months_of_cash: 0,
  per_revenue: 0,
  per_expenses: 0,
  per_net_profit: 0,
};
const getSum = (acc: NPOIRSProfile, cols: string[]): number =>
  cols.reduce((prev, col) => +(acc[col as keyof typeof acc] || 0) + prev, 0);
const getPer = (data: NPOIRSProfile, key: string, sums: number[]) => {
  const denominator = expenseCols.includes(key)
    ? sums[0]
    : revenueCols.includes(key)
    ? sums[1]
    : assetCols.includes(key)
    ? sums[2]
    : grantCols.includes(key)
    ? sums[3]
    : 0;
  if (!denominator) return 0;
  return (100 * +(data[key as keyof typeof data] || 0)) / denominator;
};
const getRateOfChange = (
  before: NPOIRSProfileWithNetProfit | undefined,
  cur: NPOIRSProfileWithNetProfit | undefined,
): DiffNPOIRSProfileWithNetProfit => {
  if (!before || !cur) return defaultAllZeroIRSDiff;
  return allColumnsWithNetProfit.reduce((prev, col) => {
    const before_v: number = +(before[col as keyof typeof before] || 0);
    const cur_v: number = +(cur[col as keyof typeof cur] || 0);
    return {
      ...prev,
      ["diff_" + col]: before_v
        ? Math.round((100 * (cur_v - before_v)) / Math.abs(before_v))
        : cur_v > 0
        ? Number.POSITIVE_INFINITY
        : cur_v < 0
        ? Number.NEGATIVE_INFINITY
        : 0,
    };
  }, defaultAllZeroIRSDiff);
};
const convertData = (
  data: NPOIRSProfile[],
): {
  acc: NPOIRSProfile;
  proportion: ProportionNPOIRSProfileWithNetProfit;
  data: NPOIRSProfileWithNetProfit[];
  rateOfChange: DiffNPOIRSProfileWithNetProfit;
  latest_year: NPOIRSProfileWithNetProfit | undefined;
  before_latest_year: NPOIRSProfileWithNetProfit | undefined;
  valid_years_size: number;
} => {
  // estimated value is excludede when calculating avg and proportion
  const acc = data
    .filter((d) => !d.is_interpolated_or_predicted)
    .reduce(
      (prev, cur) => ({
        ...prev,
        ...allColumns.reduce(
          (p, col) => ({
            ...p,
            [col]: +(p[col as keyof typeof p] || 0) + +(cur[col as keyof typeof cur] || 0),
          }),
          prev,
        ),
      }),
      defaultAllZeroIRS,
    );
  const sums = [expenseCols, revenueCols, assetCols, grantCols].map((cols) => getSum(acc, cols));
  const proportion = allColumns.reduce(
    (prev, col) => ({
      ...prev,
      ["per_" + col]: getPer(acc, col, sums),
    }),
    defaultAllZeroIRSProportion,
  );

  const dataWithNetProfit: NPOIRSProfileWithNetProfit[] = data.map((d) => ({
    ...d,
    revenue: getSum(d, revenueCols),
    expenses: getSum(d, expenseCols),
    net_profit: getSum(d, revenueCols) - getSum(d, expenseCols),
    assets_total: getSum(d, assetCols),
    is_predicted: false,
    is_interpolated: false,
  }));
  const filteredData = dataWithNetProfit.filter((d) => !d.is_interpolated_or_predicted);
  const _beforeLatest = filteredData.length > 1 ? filteredData[filteredData.length - 2] : undefined;
  const _latest = filteredData.length > 0 ? filteredData[filteredData.length - 1] : undefined;
  const dataWithNetProfitMarked: NPOIRSProfileWithNetProfit[] = data.map((d) => ({
    ...d,
    revenue: getSum(d, revenueCols),
    expenses: getSum(d, expenseCols),
    net_profit: getSum(d, revenueCols) - getSum(d, expenseCols),
    assets_total: getSum(d, assetCols),
    is_predicted:
      !_latest || (d.is_interpolated_or_predicted && d.tax_year > _latest?.tax_year) ? true : false,
    is_interpolated:
      _latest && d.is_interpolated_or_predicted && d.tax_year < _latest.tax_year ? true : false,
  }));
  return {
    acc: allColumns.reduce(
      (prev, col) => ({
        ...prev,
        [col as keyof typeof acc]: !filteredData.length
          ? 0
          : +(prev[col as keyof typeof acc] || 0) / filteredData.length,
      }),
      acc,
    ),
    proportion: proportion,
    data: dataWithNetProfitMarked,
    rateOfChange: getRateOfChange(_beforeLatest, _latest),
    latest_year: _latest,
    before_latest_year: _beforeLatest,
    valid_years_size: filteredData.length,
  };
};
const fixUndefinedValue = (data: NPOIRSProfile[]): NPOIRSProfile[] => {
  return data.map((i) => ({
    ...i,
    ...allColumns.reduce(
      (prev, key) => ({
        ...prev,
        [key]: !prev[key as keyof typeof i] ? 0 : prev[key as keyof typeof i],
      }),
      i,
    ),
  }));
};
const fixNegativeEstimation = (data: NPOIRSProfile[]): NPOIRSProfile[] => {
  return data.map((i) => ({
    ...i,
    ...nonNegativeColumns.reduce(
      (prev, key) => ({
        ...prev,
        [key]:
          !prev[key as keyof typeof i] ||
          (prev[key as keyof typeof i] &&
            typeof prev[key as keyof typeof i] === "number" &&
            +prev[key as keyof typeof i]! < 0)
            ? 0
            : prev[key as keyof typeof i],
      }),
      i,
    ),
  }));
};
function useNPOIRSProfile(npo_id?: string): NPOIRSProfileState {
  const start = new Date().getUTCFullYear() - 7 + 1;
  const url = process.env.REACT_APP_API_URL + `/api/v2/npos/${npo_id}/profile_irs?by=id`;
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<NPOIRSProfile[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as NPOIRSProfile[]);
  };
  const {
    data: dataOriginal,
    isLoading,
    error,
  } = useSWR<NPOIRSProfile[]>(npo_id ? url : null, fetch, swrOptionDedupling5mins);
  const {
    data: dataEstimated,
    isLoading: isEstimatedLoading,
    error: errorEstimated,
  } = useForecasts(dataOriginal, [
    "program",
    "operating",
    "fundraising",
    "contributions",
    "investment",

    "operation",
    "asset_cash",
    "asset_receivable",
    "asset_investment",
    "asset_property",

    "grants_gov",
    "grants_tot",
    "grants_phil",
    "grants_ind",
    "luna",

    "months_of_cash",
  ]);

  const setDataFunc = () => {
    if (!dataOriginal || dataOriginal.length === 0) {
      return [];
    }
    if (!dataEstimated) {
      return dataOriginal;
    }
    const convertedDataOriginal = fixUndefinedValue(dataOriginal).sort(
      (a, b) => a.tax_year - b.tax_year,
    );
    const years = convertedDataOriginal.map((a) => a.tax_year);
    const convertedDataEstimated = fixNegativeEstimation(fixUndefinedValue(dataEstimated));
    const unordered = [
      ...convertedDataOriginal,
      ...convertedDataEstimated.filter((a) => !years.includes(a.tax_year)),
    ].slice(-7);
    return unordered.sort((a, b) => a.tax_year - b.tax_year);
  };
  const [data, setData] = useState<NPOIRSProfile[]>(setDataFunc());
  useEffect(() => {
    setData(setDataFunc());
  }, [dataOriginal, dataEstimated, setData]);
  const [c, setC] = useState<{
    acc: NPOIRSProfile;
    proportion: ProportionNPOIRSProfileWithNetProfit;
    data: NPOIRSProfileWithNetProfit[];
    rateOfChange: DiffNPOIRSProfileWithNetProfit;
    latest_year: NPOIRSProfileWithNetProfit | undefined;
    before_latest_year: NPOIRSProfileWithNetProfit | undefined;
    valid_years_size: number;
  }>(() => convertData(data));
  const [converted, setConverted] = useState<NPOIRSProfileWithNetProfit[]>(c.data);
  const [latestYear, setLatestYear] = useState<NPOIRSProfileWithNetProfit | undefined>(
    c.latest_year,
  );
  const [earliestYear, setEarliestYear] = useState<NPOIRSProfileWithNetProfit | undefined>(
    c.data.length > 0 ? c.data[0] : undefined,
  );
  const [beforeLatestYear, setBeforeLatestYear] = useState<NPOIRSProfileWithNetProfit | undefined>(
    c.before_latest_year,
  );
  const [revenueModel, setRevenueModel] = useState<RevenueModel | undefined>({
    years: c.valid_years_size,
    ...c.acc,
    ...c.proportion,
  });
  const [totalContribution, setTotalContribution] = useState<Contribution[]>(c.data);
  const [contributionModel, setContributionModel] = useState<ContributionModel | undefined>(
    c.latest_year
      ? {
          ...c.acc,
          ...c.proportion,
          ...c.rateOfChange,
        }
      : undefined,
  );
  const [revenueStream, setRevenueStream] = useState<Revenue[]>(c.data);
  const [revenueStreamInfo, setRevenueStreamInfo] = useState<RevenueInfo | undefined>({
    ...(c.latest_year || defaultAllZeroIRS),
    ...c.rateOfChange,
  });
  const [operatingExpenses, setOperatingExpenses] = useState<OperatingExpenses | undefined>(
    c.latest_year ? { ...c.acc, ...c.proportion, ...c.rateOfChange } : undefined,
  );
  const [operating, setOperating] = useState<Operating[]>(c.data);
  const [operatingInfo, setOperatingInfo] = useState<OperatingInfo | undefined>({
    ...(c.latest_year || defaultAllZeroIRS),
    ...c.rateOfChange,
  });
  const [monthOfLiquid, setMonthOfLiquid] = useState<MonthOfLiquid[]>(c.data);
  const [assets, setAssets] = useState<Assets[]>(
    c.data.map((d) => ({
      ...d,
      ...(d.tax_year === c.latest_year?.tax_year ? c.rateOfChange : defaultAllZeroIRSDiff),
    })),
  );
  const [assetInfo, setAssetInfo] = useState<AssetInfo | undefined>({
    ...c.acc,
    ...c.proportion,
    ...c.rateOfChange,
  });
  const [assetsTransposed, setAssetsTransposed] = useState<AssetsTransposed | undefined>(
    c.data
      .sort((a, b) => a.tax_year - b.tax_year)
      .reduce(
        (prev, cur) => ({
          tax_year: [...prev.tax_year, cur.tax_year],
          cash: [...prev.cash, cur.asset_cash],
          investment: [...prev.investment, cur.asset_investment],
          receivable: [...prev.receivable, cur.asset_receivable],
          property: [...prev.property, cur.asset_property],
          is_interpolated: [...prev.is_interpolated, cur.is_interpolated ? true : false],
          is_predicted: [...prev.is_predicted, cur.is_predicted ? true : false],
        }),
        {
          tax_year: [] as number[],
          cash: [] as number[],
          investment: [] as number[],
          receivable: [] as number[],
          property: [] as number[],
          is_interpolated: [] as boolean[],
          is_predicted: [] as boolean[],
        },
      ),
  );
  useEffect(() => {
    if (!data || data.length === 0) {
      setLatestYear(undefined);
      setEarliestYear(undefined);
      setBeforeLatestYear(undefined);
      setRevenueModel(undefined);
      setTotalContribution([]);
      setContributionModel(undefined);
      setRevenueStream([]);
      setRevenueStreamInfo(undefined);
      setOperatingExpenses(undefined);
      setOperating([]);
      setOperatingInfo(undefined);
      setMonthOfLiquid([]);
      setAssets([]);
      setAssetsTransposed(undefined);
      setConverted([]);
    } else {
      const c = convertData(data);
      setC(c);
      setConverted(c.data);
      setLatestYear(c.latest_year);
      setBeforeLatestYear(c.before_latest_year);
      setEarliestYear(c.data.length > 0 ? c.data[0] : undefined);
      setRevenueModel({
        years: c.valid_years_size,
        ...c.acc,
        ...c.proportion,
      });
      setTotalContribution(c.data);
      setContributionModel(
        c.latest_year
          ? {
              ...c.acc,
              ...c.proportion,
              ...c.rateOfChange,
            }
          : undefined,
      );
      setRevenueStream(c.data);
      setRevenueStreamInfo({ ...(c.latest_year || defaultAllZeroIRS), ...c.rateOfChange });
      setOperatingExpenses(
        c.latest_year ? { ...c.acc, ...c.proportion, ...c.rateOfChange } : undefined,
      );
      setOperating(c.data);
      setOperatingInfo({ ...(c.latest_year || defaultAllZeroIRS), ...c.rateOfChange });
      setMonthOfLiquid(c.data);
      setAssets(
        c.data.map((d) => ({
          ...d,
          ...(d.tax_year === c.latest_year?.tax_year ? c.rateOfChange : defaultAllZeroIRSDiff),
        })),
      );
      setAssetInfo({ ...c.acc, ...c.proportion, ...c.rateOfChange });
      setAssetsTransposed(
        c.data
          .sort((a, b) => a.tax_year - b.tax_year)
          .reduce(
            (prev, cur) => ({
              tax_year: [...prev.tax_year, cur.tax_year],
              cash: [...prev.cash, cur.asset_cash],
              investment: [...prev.investment, cur.asset_investment],
              receivable: [...prev.receivable, cur.asset_receivable],
              property: [...prev.property, cur.asset_property],
              is_interpolated: [...prev.is_interpolated, cur.is_interpolated ? true : false],
              is_predicted: [...prev.is_predicted, cur.is_predicted ? true : false],
            }),
            {
              tax_year: [] as number[],
              cash: [] as number[],
              investment: [] as number[],
              receivable: [] as number[],
              property: [] as number[],
              is_interpolated: [] as boolean[],
              is_predicted: [] as boolean[],
            },
          ),
      );
    }
  }, [data]);
  return {
    data: converted,
    latest_year: latestYear?.tax_year,
    earliest_year: earliestYear?.tax_year,
    latest: latestYear,
    beforeLatest: beforeLatestYear,
    revenueModel,
    totalContribution,
    contributionModel,
    revenueStream,
    revenueStreamInfo,
    operatingExpenses,
    operating,
    operatingInfo,
    monthOfLiquid,
    assets,
    assetsTransposed,
    assetInfo,
    isLoading: isLoading,
    error,
  };
}
export default useNPOIRSProfile;
