const convertNumberOrUndefinedToString = (num: number | undefined | null): string => {
  return num === undefined || num === null ? "" : num.toLocaleString();
};
const convertStringToNumberOrUndefined = (str: string): number | undefined => {
  try {
    return str === "" ? undefined : +str;
  } catch {
    return undefined;
  }
};
const notNone = (v: any): boolean => {
  return v !== undefined && v !== null;
};
const caseInsensitiveAndSpaceInsensitive = (item: string) => {
  if (!item) return item;
  return item.replace(/ /g, "").toLowerCase();
};
const onlyLowerAlphabetOrNumber = (item: string) => {
  if (!item) return item;
  return item.replace(/[^a-zA-Z0-9]+/g, "").toLowerCase();
};
const isNumeric = (str: string): boolean => {
  if (typeof str != "string") return false;
  return !isNaN(+str) && !isNaN(parseFloat(str));
};
function isInteger(str: string): boolean {
  if (typeof str != "string") return false;
  return !isNaN(Number(str));
}
export {
  convertNumberOrUndefinedToString,
  convertStringToNumberOrUndefined,
  notNone,
  caseInsensitiveAndSpaceInsensitive,
  onlyLowerAlphabetOrNumber,
  isNumeric,
  isInteger,
};
