import axios from "axios";
import config from "../api";

export interface IDonorContact {
  completed: true;
  website: string;
  guideline_website: string;
  grant_availability: true;
  loi: {
    firstname: string;
    lastname: string;
    email: string;
    website: string;
    apply_via_post: true;
    address: string;
  };
  general: {
    firstname: string;
    lastname: string;
    email: string;
    website: string;
    apply_via_post: true;
    address: string;
  };
  members: {
    employ_type: string;
    firstname: string;
    lastname: string;
    titles: string[];
    email: string;
    phone: string;
    linkedin: string;
    twitter: string;
    memo: string;
  }[];
  _id: string;
  donor_id: string;
  donor: {
    _id: string;
    name: string;
    ein: string;
    address: string;
    website: string;
    phone: string;
    tax_year: number;
    return_code: string;
    total_revenue: number;
    total_expenses: number;
    total_grants_made: number;
    total_grants_received: number;
    grants: {
      _id: string;
      donor_id: string;
      npo_id: string;
      grant_year: number;
      grant_amount: number;
      grant_description: string;
    }[];
    location: {
      level: number;
      address1: string;
      lat: number;
      lng: number;
      city: string;
      county_name: string;
      state_name: string;
      state_id: string;
      iso2: string;
    };
  };
  created_at: string;
  updated_at: string;
}

export const getDonorsPhilanthropyWords = (donor_id: string, tags?: string[]) => {
  const url =
    process.env.REACT_APP_TAXONOMY_URL +
    `/algo/v1/wordcloud${tags && tags.length > 0 ? "_matching_words" : ""}`;
  return new Promise<{ score: number; label: string; amount: number; count: number }[]>(
    (resolve, reject) => {
      axios
        .post(
          url,
          { donor_id, ...(tags ? { tags: tags.map((i) => i.toLowerCase()) } : {}) },
          config,
        )
        .then(({ data, status }) => {
          if (status === 204) resolve([]);
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    },
  );
};
