import { ILocation } from "../types/location";
import FundCharChart from "../components/dashboard/FundCharChart";
import { FunderMemberContact } from "../services/funders.services";
import { Philanthropy } from "./philanthropy";

const getFunderMemberContactKey = (c: FunderMemberContact): string => {
  return `${c.firstname || ""}_${c.lastname || ""}_${c.email || ""}`;
};
export interface ISimpleFunder {
  _id: string;
  name: string;
  ein: string;
  address: string;
}

export interface DonorGrant {
  grant_description: string;
  grant_npos: any;
  grant_amount: number[];
}

export interface FunderProfile extends Philanthropy {
  donor_id: string;
  does_not_accept_unsolicited?: boolean;
  object_id?: number;
  ntee_cd?: string;
  dreamer_count?: number;
  seed_count?: number;
  startup_count?: number;
  buildup_count?: number;
  superstar_count?: number;
  hyperstar_count?: number;
  grant_amount_min?: number;
  grant_amount_max?: number;
  assets?: number;
}

export interface IFunder {
  _id: string;
  name: string;
  ein: string;
  address: string;
  website?: string;
  phone?: string;
  location?: ILocation;
  return_code: string;
  tax_year: number;
  total_expenses: number;
  total_grants_made: number;
  total_grants_received: number;
  total_revenue: number;
  score?: number;
  ntee_cd?: string;
  funder_type: string;
}

export interface GrantHistory {
  _id: string;
  grant_description: string;
  npos: {
    npo_id: string;
    amount: number;
    name: string;
  }[];
  donor_id: string;
}

export interface Wealth {
  _id?: string;
  donor_id: string;
  tax_year: number;
  revenue: number;
  expenses: number;
  assets: number;
  distributions: number;
  contribution: number;
  is_predicted?: boolean;
  is_interpolated?: boolean;
}

export interface GrantAmount {
  count: number;
  min: number;
  max: number;
  sum: number;
  grant_year: number;
  tax_year: number;
  in_range_sum: number;
  in_range_count: number;
  median: number;
  mean: number;
  variance: number;
  is_predicted?: boolean;
  is_interpolated?: boolean;
}

const defaultGrantAmountOverview: GrantAmount = {
  sum: 0,
  min: 0,
  max: 0,
  count: 0,
  median: 0,
  in_range_sum: 0,
  in_range_count: 0,
  grant_year: 0,
  tax_year: 0,
  variance: 0,
  mean: 0,
};

export interface FundChar {
  tax_year: number;
  seed: number;
  dreamer: number;
  startup: number;
  buildup: number;
  superstar: number;
  hyperstar: number;
}
export interface FundCharOverviewAttrs {
  min: number;
  max: number;
  median: number;
  p25: number;
  p75: number;
}
const defaultFundChar: FundChar = {
  tax_year: 0,
  seed: 0,
  dreamer: 0,
  startup: 0,
  buildup: 0,
  superstar: 0,
  hyperstar: 0,
};
export interface FundCharOverview {
  seed: FundCharOverviewAttrs;
  dreamer: FundCharOverviewAttrs;
  startup: FundCharOverviewAttrs;
  buildup: FundCharOverviewAttrs;
  superstar: FundCharOverviewAttrs;
  hyperstar: FundCharOverviewAttrs;
}

export interface FunderPastGrantGroup extends Philanthropy {
  npo_id: string;
  donor_id: string;
  npo_name: string;
  grant_description?: string;
  total_grant_amount: number;
  from_year: number;
  to_year: number;
  grants: {
    grant_amount: number;
    grant_description: string;
    from_year: number;
    to_year: number;
    grant_amount_min: number;
    grant_amount_max: number;
    funding_type: string[];
  }[];
  npo_location?: ILocation;
  stage?: string;
  npo_address?: string;
  years: number[];
}
const defaultFundCharOverviewAttrs = { min: 0, max: 0, median: 0, p25: 0, p75: 0 };
const defaultFundCharOverview: FundCharOverview = {
  seed: defaultFundCharOverviewAttrs,
  dreamer: defaultFundCharOverviewAttrs,
  startup: defaultFundCharOverviewAttrs,
  buildup: defaultFundCharOverviewAttrs,
  superstar: defaultFundCharOverviewAttrs,
  hyperstar: defaultFundCharOverviewAttrs,
};
export interface FunderSummary {
  mission_and_vision?: string;
  giving_philosophy_and_values?: string;
  philanthropic_focuses?: string;
  beneficiaries?: string;
  headquarter_location?: string;
  financial_information?: string;
  [key: string]: string | undefined;
}
export {
  defaultGrantAmountOverview,
  defaultFundChar,
  defaultFundCharOverview,
  getFunderMemberContactKey,
};
