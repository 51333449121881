import classNames from "classnames";
import { Dispatch, ReactElement, ReactNode, SetStateAction } from "react";

interface RadioGroupProps<T extends string> {
  id: string;
  data: { value: T; label: string | ReactElement }[];
  value: T | undefined;
  setValue: (v: T | undefined) => void;
  disabled?: boolean;
  disabledItems?: T[];
  flexColumn?: boolean;
  mandatory?: boolean;
  className?: string;
  itemClassName?: string;
  gap?: number;
  buttonClass?: string;
  labelClassName?: string;
  renderInfo?: (key: string) => ReactNode;
}

function RadioGroup<T extends string>({ mandatory = true, ...props }: RadioGroupProps<T>) {
  return (
    <div
      id={props.id}
      className={classNames(
        "flex",
        props.flexColumn
          ? `flex-col items-start gap-${props.gap ? props.gap : 3}`
          : `gap-${props.gap ? props.gap : 5}`,
        props.className,
      )}
    >
      {props.data.map(({ value, label }) => {
        const disabled = props.disabled || props.disabledItems?.includes(value) ? true : false;
        return (
          <div
            className={classNames(
              disabled ? "cursor-not-allowed" : "cursor-pointer",
              props.itemClassName,
            )}
            key={`${props.id}-${value}`}
            onClick={(e) => {
              if (disabled) return;
              if (!mandatory && props.value === value) props.setValue(undefined);
              else props.setValue(value);
            }}
          >
            <div className={classNames("flex items-center gap-2")}>
              <input
                type="radio"
                id={`input-${props.id}-${value}`}
                checked={props.value === value}
                disabled={disabled}
                readOnly
                className={classNames(
                  disabled ? "cursor-not-allowed" : "cursor-pointer",
                  "border-gray-500 text-purple-500 !outline-none !ring-transparent",
                  props.buttonClass,
                  props.id,
                )}
                aria-valuetext={value}
              />
              <div
                className={classNames("flex w-full items-center justify-between", props.id)}
                aria-valuetext={value}
              >
                {typeof label === "string" ? (
                  <>
                    <label
                      htmlFor={`input-${props.id}-${value}`}
                      className={classNames(disabled ? "cursor-not-allowed" : "cursor-pointer")}
                      aria-valuetext={value}
                    >
                      <span
                        className={classNames(
                          "text-sm",
                          props.labelClassName,
                          disabled ? "text-gray-500" : "text-gray-900",
                        )}
                        aria-valuetext={value}
                      >
                        {label}
                      </span>
                    </label>
                    {props.renderInfo && <>{props.renderInfo(value)}</>}
                  </>
                ) : (
                  <>{label}</>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default RadioGroup;
