import React from "react";

export default function DecreaseTag() {
  return (
    <span className="inline-flex h-5 w-fit items-center gap-1 rounded bg-[#FFEED5] px-1 py-[2px]">
      <span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 3.33268L8 12.666"
            stroke="#B67008"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.667 7.99935L8.00033 12.666L3.33366 7.99935"
            stroke="#B67008"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
      <p className="font-poppins text-[10px] text-[#B67008]">Decrease</p>
    </span>
  );
}
