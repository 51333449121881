export enum GibooSidekickGAValue {
  SEARCH_TRY_SEARCHING_FOR = "try_search_for_in_myhome",
  CREATE_PROJECT = "create_project",
  FINANCIAL_ASSESSMENT = "financial_assessment",
  DIVERSITY_ASSESSMENT = "diversity_assessment",
  ORG_PROFILE = "org_profile",
  VIEW_SUBTASKS = "view_subtasks",
  DOCUMENT_AI = "document_ai",
  INVITE_ORG_MEMBERS = "invite_org_members",
  SEARCH_WITH_MISSION = "search_with_mission",
  ORG_VERIFICATION = "org_verification",

  ORG_CONTACT = "org_profile_contact",

  PROJECT_INFORMATION = "project_information",
  INVITE_PROJECT_MEMBERS = "invite_project_members",
  PROJECT_ACTIVITIES = "project_activities",
  PROJECT_FUNDRAISING_STATUS = "project_fundraising_status",
  PROJECT_SUBTASKS = "project_subtasks",
  PROJECT_PROGRESS = "project_progress",

  CREATE_SUBTASK = "create_subtask",
  VIEW_OPPORTUNITY_DETAIL = "view_detail",

  SEARCH_FUNDER = "search_funder",
  SEARCH_OPENCALL_GRANT = "search_opencall_grant",
  SEARCH_VIRTUAL_GRANT = "search_virtual_grant",

  INFO_OPENCALL_GRANT = "info_opencall_grant",
  INFO_VIRTUAL_GRANT = "info_virtual_grant",
  INFO_GENERATED_TAGS = "info_generated_tags",
  INFO_SEARCH = "info_search",

  SEE_VIRTUAL_GRANT = "see_virtual_grant",
  SEE_OPENCALL_GRANT = "see_opencall_grant",
  WHY_MATCHED = "why_matched",

  FILTER_ADD_LOCATION_IN_FUNDER = "filter_add_location_in_funder",
  FILTER_ADD_LOCATION_IN_GRANT = "filter_add_location_in_grant",
  FILTER_UPDATE_LOCATION = "filter_update_location",
  FILTER_UPDATE_TAG = "filter_tag",
  FILTER_UPDATE_GRANT_DEADLINE = "filter_grant_deadline",
  FILTER_UPDATE_FUNDER_TYPE_IN_GRANT = "filter_funder_type_in_grant",
  FILTER_UPDATE_FUNDER_TYPE_IN_FUNDER = "filter_funder_type_in_funder",
  FILTER_UPDATE_FUNDING_TYPE = "filter_funding_type",
  FILTER_UPDATE_STAGE = "filter_stage",

  SCROLL_TO_MAP = "scroll_to_map",
  SCROLL_TO_TAGS = "scroll_to_tags",
  CLICK_START_FUNDRAISING = "click_startfundraising",
  CLICK_SAVE = "click_save",

  DOCUMENT_AI_ASKING_INFO_FOCUS_AREA = "document_ai_asking_info_focus_area",
  DOCUMENT_AI_ASKING_INFO_BENEFICIARY = "document_ai_asking_info_beneficiary",
  DOCUMENT_AI_ASKING_INFO_SERVICE_LOC = "document_ai_asking_info_service_loc",
  DOCUMENT_AI_ASKING_INFO_PAST_GRANTS = "document_ai_asking_info_past_grants",
  DOCUMENT_AI_ASKING_INFO_ELIGIBILITY = "document_ai_asking_info_eligibility",
  DOCUMENT_AI_ASKING_INFO_APPLICATION_REQUIREMENT = "document_ai_asking_info_application_requirement",
  DOCUMENT_AI_ASKING_INFO_STAGE = "document_ai_asking_info_stage",
  DOCUMENT_AI_ASKING_PAST_GRANTEE_LOC = "document_ai_asking_past_grantee_loc",
  DOCUMENT_AI_ASKING_ADDITIONAL_DOCUMENTATION = "document_ai_asking_additional documentation",
  DOCUMENT_AI_ASKING_TIP = "document_ai_asking_tip",
}
export enum GibooSidekickActionType {
  NAVIGATE = 100,
  SCROLL_TO = 101,

  SEARCH_VIRTUAL_GRANT = 1,
  SEARCH_OPENCALL_GRANT = 2,
  SEARCH_VIRTUAL_GRANT_TEXT = 3,
  SEARCH_OPENCALL_GRANT_TEXT = 4,
  SEARCH_FUNDER_TEXT = 5,

  OPEN_SEARCH_TAGS = 8,
  OPEN_SEARCH_LOCATION = 9,
  OPEN_MORE_FILTER = 10,

  WHY_MATCHED = 11,
  START_FUNDRAISING = 12,
  SAVE = 13,

  SCROLL_TO_MAP = 16,
  SCROLL_TO_TAGS = 17,

  CREATE_PROJECT = 31,
  CREATE_OR_VIEW_SUBTASK = 32,
  HIGHLIGHT_DOCUMENT_AI = 33,
  INVITE_ORG_MEMBERS = 34,
  VERIFY_ORG = 35,
  ORG_CONTACT = 36,

  PROJECT_INFORMATION = 41,
  INVITE_PROJECT_MEMBERS = 42,
  PROJECT_ACTIVITIES = 43,
  PROJECT_FUNDRAISING_STATUS = 44,
  PROJECT_SUBTASKS = 45,
  PROJECT_CREATE_SUBTASK = 46,
  PROJECT_TARGET_DETAIL = 47,
  PROJECT_PROGRESS = 48,

  DOCUMENT_AI_ASKING_INFO_COMMAND = 71,
}
export enum GibooSidekickGroup {
  MYHOME_SEARCH = "myhome_search",
  MYHOME = "myhome",
  SEARCH_LOADING = "search_loading",
  SEARCH = "search",
  VIRTUAL_GRANT = "virtual_grant",
  OPENCALL_GRANT = "opencall_grant",
  FUNDER = "funder",
  NPO = "npo",
  ORG_PROFILE = "org_profile",
  PROJECT = "project",
  PROJECT_TASK = "project_task",
  DOCUMENT_AI = "document_ai",
}
export enum GibooSidekickCondition {
  NOT_NEW_USER = 1000,
  NEW_USER = 1001,
  SEARCH_COMPLETED = 1,
  SEARCH_VIRTUAL_GRANT = 2,
  SEARCH_GRANT = 3,
  SEARCH_FUNDER = 4,
  SEARCH_NPO = 5,
  SEARCH_FUNDER_SUGGESTION_EXIST = 7,
  SEARCH_GRANT_SUGGESTION_EXIST = 8,
  SEARCH_OPEN = 9,
  SEARCH_CLOSE = 10,

  VIEW_VIRTUAL_GRANT = 11,
  VIEW_GRANT = 12,
  VIEW_FUNDER = 13,
  VIEW_NPO = 14,

  EXPLORE_DETAIL_VIEW = 16,

  TARGET_EXIST = 21,
  HAS_OPENCALL_GRANT = 22,
  HAS_VIRTUAL_GRANT = 23,
  ANSWERED_OPENCALL_GRANT = 24,
  ANSWERED_VIRTUAL_GRANT = 25,

  WHY_MATCHED_AVAILABLE = 26,
  HAS_GOOD_TO_HAVE_SERVICE_LOCATION = 27,
  HAS_MUST_HAVE_TAGS = 28,
  SEARCH_NOT_ROLLING = 29,

  NOT_SAVED = 31,
  SAVE_QUESTION_OPENED = 32,

  ORG_EDIT_PERMISSION = 41,
  ORG_NOT_VERIFIED = 42,
  ORG_FINANCIAL_NOT_DONE = 43,
  ORG_DIVERSITY_NOT_DONE = 44,
  ORG_HAS_MY_TASK = 45,
  MYHOME_READY = 46,

  PROJECT_INFOMATION_NOT_FILLED = 51,
  PROJECT_FUNDER_NOT_ADDED = 52,
  PROJECT_FUNDER_ADDED = 53,
  PROJECT_GRANT_NOT_ADDED = 54,
  PROJECT_GRANT_ADDED = 55,
  PROJECT_DOCUMENT_NOT_CREATED = 56,
  PROJECT_TASK_LOADED = 57,
  PROJECT_EDIT_PERMISSION = 58,

  DOCUMENT_GENERATED = 71,
  DOCUMENT_FOR_VIRTUAL_GRANT = 72,
  DOCUMENT_FOR_OPENCALL_GRANT = 73,
  DOCUMENT_FOR_FUNDER = 74,
  DOCUMENT_FOR_NPO = 75,
  DOCUMENT_LOI = 76,
}
export interface ChatDataGroup {
  group: GibooSidekickGroup;
  show_condition?: (GibooSidekickCondition | GibooSidekickCondition[])[];
  matchIndex: number[];
  data: GibooSidekickChatData[];
}
export enum GibooSidekickIcon {
  WHY_MATCHED = 1,
}
export enum GibooSidekickChatComponentType {
  GREETING = 0,
  GREETING_WITHOUT_COLOR = 2,
  GREETING_AND_INFORMATION = 4,
  INFORMATION_BUTTON = 5,
  ACTION_BUTTON = 6,
  ANSWER = 7,
}
export interface GibooSidekickChatData {
  type: GibooSidekickChatComponentType;
  id: string;
  show_condition?: (GibooSidekickCondition | GibooSidekickCondition[])[];
  title?: string;
  message: string;
  answer?: string;
  action_type?: GibooSidekickActionType;
  action_target?: string;
  action_button?: { label: string; action_type: GibooSidekickActionType; action_target?: string };
  updated_at?: Date;
  icon?: GibooSidekickIcon;
}

export enum GibooSidekickURLMatchIndex {
  MYHOME = 0,
  SEARCH = 1,
  FUNDER = 2,
  GRANT = 3,
  NPO = 4,
  ORG_PROFILE = 5,
  PROJECT = 6,
  PROJECT_TASK = 7,
  DOCUMENT_AI = 8,
}
const chatData: ChatDataGroup[] = [
  {
    group: GibooSidekickGroup.MYHOME,
    matchIndex: [GibooSidekickURLMatchIndex.MYHOME],
    show_condition: [[GibooSidekickCondition.NOT_NEW_USER, GibooSidekickCondition.SEARCH_CLOSE]],
    data: [
      {
        id: "greeting",
        type: GibooSidekickChatComponentType.GREETING,
        title: `Hello, $firstname!`,
        message:
          "I'm here to provide support as you explore Giboo. Let's kickstart your journey by choosing an option below:",
      },
      {
        id: GibooSidekickGAValue.SEARCH_TRY_SEARCHING_FOR,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "<b>Try searching grants</b> $search_grant_suggestion",
        answer: "Click to see the results",
        show_condition: [GibooSidekickCondition.SEARCH_GRANT_SUGGESTION_EXIST],
        action_type: GibooSidekickActionType.SEARCH_VIRTUAL_GRANT_TEXT,
        action_target: "$search_grant_suggestion_query",
      },
      {
        id: GibooSidekickGAValue.CREATE_PROJECT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Do you have a fundraising goal? Let us help you achieve!",
        answer:
          "Create a fundraising project and utilize our project management to streamline and enhance the efficiency of your fundraising efforts to achieve your goal.",
        action_button: {
          label: "Create a project",
          action_type: GibooSidekickActionType.CREATE_PROJECT,
        },
        action_type: GibooSidekickActionType.SCROLL_TO,
        action_target: "projects",
      },
      {
        id: GibooSidekickGAValue.FINANCIAL_ASSESSMENT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.ORG_EDIT_PERMISSION],
        message: "Curious about your organization's financial health?",
        answer:
          "Gain valuable insights into your financial landscape, empowering you to make informed decisions and secure your organization's future.",
        action_button: {
          label: "Start financial assessment",
          action_type: GibooSidekickActionType.NAVIGATE,
          action_target: "financial",
        },
        action_type: GibooSidekickActionType.SCROLL_TO,
        action_target: "financials",
      },
      {
        id: GibooSidekickGAValue.ORG_PROFILE,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.ORG_EDIT_PERMISSION],
        message: "Want to stand out to potential funders?",
        answer:
          "Complete your organization profile to ensure you're well-represented on Giboo. This step will help you make a strong impression and build valuable connections.",
        action_button: {
          label: "Go to organization profile",
          action_type: GibooSidekickActionType.NAVIGATE,
          action_target: "organization",
        },
      },
      {
        id: GibooSidekickGAValue.VIEW_SUBTASKS,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Which fundraising task needs immediate attention?",
        answer:
          "Utilize our task management to organize your workload efficiently. Easily track and prioritize tasks, ensuring that crucial fundraising activities receive the attention they deserve.",
        action_button: {
          label: "$create_or_view_subtasks",
          action_type: GibooSidekickActionType.CREATE_OR_VIEW_SUBTASK,
        },
        action_type: GibooSidekickActionType.SCROLL_TO,
        action_target: "upcoming-tasks",
      },
      {
        id: GibooSidekickGAValue.DOCUMENT_AI,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Need to craft a document that captures your organization's essence?",
        answer:
          "Let's work together to create a compelling document that resonates with potential funders and collaborators, showcasing the impact of your work., ensuring that crucial fundraising activities receive the attention they deserve.",
        action_button: {
          label: "Write a document",
          action_type: GibooSidekickActionType.NAVIGATE,
          action_target: "/document",
        },
        action_type: GibooSidekickActionType.SCROLL_TO,
        action_target: "document-ai",
      },
      {
        id: GibooSidekickGAValue.INVITE_ORG_MEMBERS,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.ORG_EDIT_PERMISSION],
        message: "Want to boost teamwork and productivity?",
        answer:
          "Are you looking to improve collaboration among your organization member? Invite your colleagues to share potential opportunities, divide tasks, and collaborate seamlessly to maximize your organization's impact.",
        action_button: {
          label: "Invite member",
          action_type: GibooSidekickActionType.INVITE_ORG_MEMBERS,
        },
      },
      {
        id: GibooSidekickGAValue.SEARCH_FUNDER,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Discover ideal funders or grant for your organization",
        answer:
          "Seeking funders who align perfectly with your organization's mission? Customize your search criteria and explore a curated list of funders tailored to your needs.",
        action_button: {
          label: "Discover",
          action_type: GibooSidekickActionType.SEARCH_FUNDER_TEXT,
          action_target: "",
        },
      },
      {
        id: GibooSidekickGAValue.ORG_VERIFICATION,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.ORG_EDIT_PERMISSION],
        message: "Ready to explore all the features Giboo has to offer?",
        answer:
          "Verify your organization to unlock full access and maximize your fundraising potential. With full access, you can take your fundraising efforts to new heights.",
        action_button: {
          label: "Verify organization",
          action_type: GibooSidekickActionType.VERIFY_ORG,
          action_target: "",
        },
      },
    ],
  },
  {
    group: GibooSidekickGroup.MYHOME_SEARCH,
    matchIndex: [GibooSidekickURLMatchIndex.MYHOME],
    show_condition: [GibooSidekickCondition.SEARCH_OPEN],
    data: [
      {
        id: "greeting",
        type: GibooSidekickChatComponentType.GREETING,
        show_condition: [GibooSidekickCondition.NEW_USER],
        title: `Welcome to Giboo, $firstname!`,
        message:
          "To kick things off, type your philanthropy focus to search for best-fit grant opportunities.<br /><br />You can also use “Try searching for” to find potential grants tailored to your organization!",
      },
      {
        id: GibooSidekickGAValue.INFO_VIRTUAL_GRANT,
        type: GibooSidekickChatComponentType.INFORMATION_BUTTON,
        message: "What is a virtual grant?",
        answer:
          "Virtual grants, generated by Giboo, leverage historical patterns from the funder's grant-making history. The content on this page is derived from past funding patterns, offering insights, but may not precisely reflect current open call and/or Request for Proposals (RFPs) opportunities issued by the funder.",
        action_button: {
          label: "Search virtual grants",
          action_type: GibooSidekickActionType.SEARCH_VIRTUAL_GRANT,
        },
      },
      {
        id: GibooSidekickGAValue.INFO_OPENCALL_GRANT,
        type: GibooSidekickChatComponentType.INFORMATION_BUTTON,
        message: "What is an open call grant?",
        answer:
          "Open call grants, which include Request for Proposals (RFPs), offer organizations solicited opportunities to address broad or specific themes. Explore the open call page for comprehensive details on these valuable funding opportunities.",
        action_button: {
          label: "Search open call grants",
          action_type: GibooSidekickActionType.SEARCH_OPENCALL_GRANT,
        },
      },
    ],
  },
  {
    group: GibooSidekickGroup.SEARCH_LOADING,
    matchIndex: [GibooSidekickURLMatchIndex.SEARCH],
    show_condition: [
      [
        GibooSidekickCondition.SEARCH_VIRTUAL_GRANT,
        GibooSidekickCondition.SEARCH_GRANT,
        GibooSidekickCondition.SEARCH_FUNDER,
        GibooSidekickCondition.SEARCH_NPO,
      ],
    ],
    data: [
      {
        id: "greeting",
        type: GibooSidekickChatComponentType.GREETING,
        message: "<b>Finding $search_target </b> $search_query.",
      },
      {
        id: GibooSidekickGAValue.INFO_GENERATED_TAGS,
        type: GibooSidekickChatComponentType.INFORMATION_BUTTON,
        message: "What can I do with Giboo generated tags?",
        answer:
          "Giboo generated tags provide you with the ability to tailor their search results according to your organization's interests and objectives. By adjusting the settings of these tags, you can generate different results and refine the number of outcomes. The tags enables you to quickly access relevant answer aligned with your focus areas, beneficiaries, and programs.",
        action_button: {
          label: "View search tags",
          action_type: GibooSidekickActionType.OPEN_SEARCH_TAGS,
        },
      },
      {
        id: GibooSidekickGAValue.INFO_SEARCH,
        type: GibooSidekickChatComponentType.INFORMATION_BUTTON,
        message: "How does Giboo search work?",
        answer:
          "Giboo's search is unique. It utilizes a combination of Natural Language Processing (NLP) and a large language model driven by AI to find the most relevant opportunity. Additionally, Giboo translates the search input into specific search tags found in its extensive classification dictionary, facilitating structured searches. This dual approach combines flexibility and structure, empowering users to find answer tailored to their needs efficiently.",
      },
    ],
  },
  {
    group: GibooSidekickGroup.SEARCH,
    show_condition: [
      GibooSidekickCondition.SEARCH_COMPLETED,
      [
        GibooSidekickCondition.SEARCH_VIRTUAL_GRANT,
        GibooSidekickCondition.SEARCH_GRANT,
        GibooSidekickCondition.SEARCH_FUNDER,
        GibooSidekickCondition.SEARCH_NPO,
      ],
    ],
    matchIndex: [GibooSidekickURLMatchIndex.SEARCH],
    data: [
      {
        id: "greeting",
        type: GibooSidekickChatComponentType.GREETING_WITHOUT_COLOR,
        message: "<b>Great! We found $search_count results.</b>",
      },
      //   {
      //     id: GibooSidekickGAValue.FILTER_UPDATE_LOCATION,
      //     type: GibooSidekickChatComponentType.ACTION_BUTTON,
      //     show_condition: [
      //       [
      //         GibooSidekickCondition.SEARCH_VIRTUAL_GRANT,
      //         GibooSidekickCondition.SEARCH_FUNDER,
      //         GibooSidekickCondition.SEARCH_NPO,
      //       ],
      //       GibooSidekickCondition.HAS_GOOD_TO_HAVE_SERVICE_LOCATION,
      //     ],
      //     message: "Would you like to see more <b>relevant results</b>? Try this!",
      //     answer:
      //       "Consider refining your search parameters to narrow down the results. For instance, you could adjust the location from <b>”$service_loc_str”</b> from being a <b>”Good to have”</b> to a <b>”Must have”</b>.",
      //     action_type: GibooSidekickActionType.OPEN_SEARCH_LOCATION,
      //   },
      {
        id: GibooSidekickGAValue.FILTER_UPDATE_TAG,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [
          [
            GibooSidekickCondition.SEARCH_VIRTUAL_GRANT,
            GibooSidekickCondition.SEARCH_GRANT,
            GibooSidekickCondition.SEARCH_FUNDER,
            GibooSidekickCondition.SEARCH_NPO,
          ],
          GibooSidekickCondition.HAS_MUST_HAVE_TAGS,
        ],
        message: "Would you like to see more <b>relevant results</b>? Try this!",
        answer:
          "Try changing $must_have_taxonomy_str tag to <b>“Good to have”</b> to see more results.",
        action_type: GibooSidekickActionType.OPEN_SEARCH_TAGS,
      },
      {
        id: GibooSidekickGAValue.FILTER_ADD_LOCATION_IN_GRANT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.SEARCH_VIRTUAL_GRANT],
        message: "Which grants support your organization's service locations?",
        answer:
          "Let's try to locate grants that have supported your service area. To refine your search, you can <b>add more locations</b> or <b>adjust the ”Must have”</b> option.",
        action_type: GibooSidekickActionType.OPEN_SEARCH_LOCATION,
      },
      {
        id: GibooSidekickGAValue.FILTER_ADD_LOCATION_IN_FUNDER,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.SEARCH_FUNDER],
        message: "Who funded to the service areas of your organization?",
        answer:
          "Let's try to locate funders that have supported your service area. To refine your search, you can <b>add more locations</b> or <b>adjust the ”Must have”</b> option.",
        action_type: GibooSidekickActionType.OPEN_SEARCH_LOCATION,
      },
      {
        id: GibooSidekickGAValue.FILTER_UPDATE_FUNDER_TYPE_IN_GRANT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.SEARCH_VIRTUAL_GRANT],
        message: "What if you only want to view a certain funder type?",
        answer:
          "Try clicking on ”More search options”. To filter the funders, simply click on the funder type. We offer 10 different types of funders in our search.",
        action_type: GibooSidekickActionType.OPEN_MORE_FILTER,
        action_target: "funder-type",
      },
      {
        id: GibooSidekickGAValue.FILTER_UPDATE_FUNDER_TYPE_IN_FUNDER,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.SEARCH_FUNDER],
        message: "Does your organization have a preference for a specific type of funder?",
        answer:
          "Try clicking on ”More search options”. To filter the funders, simply click on the funder type. We offer 10 different types of funders in our search.",
        action_type: GibooSidekickActionType.OPEN_MORE_FILTER,
        action_target: "funder-type",
      },
      {
        id: GibooSidekickGAValue.FILTER_UPDATE_FUNDING_TYPE,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.SEARCH_GRANT],
        message:
          "Do you have a preference for a certain type of grant? Are you looking for <b>$funding_type_unchecked_str</b>? or others?",
        answer: "Click ”$funding_type_unchecked_str” to filter the grant category.",
        action_type: GibooSidekickActionType.OPEN_MORE_FILTER,
        action_target: "funding-type",
      },
      {
        id: GibooSidekickGAValue.FILTER_UPDATE_GRANT_DEADLINE,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [
          GibooSidekickCondition.SEARCH_GRANT,
          GibooSidekickCondition.SEARCH_NOT_ROLLING,
        ],
        message: "Are you looking for a <b>rolling grant</b>?",
        answer: "Click ”Rolling” to filter.",
        action_type: GibooSidekickActionType.OPEN_MORE_FILTER,
        action_target: "deadline",
      },
      {
        id: GibooSidekickGAValue.FILTER_UPDATE_STAGE,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.SEARCH_FUNDER],
        message: "Do you want to find funders who are particularly interested in your stage?",
        answer:
          "Funding stage offers insights on funders' preferences across nonprofit stages, helping your organization find optimal matches for successful fundraising. Use it to refine your search and find funders who support your stage!",
        action_type: GibooSidekickActionType.OPEN_MORE_FILTER,
        action_target: "stage",
      },
    ],
  },
  {
    group: GibooSidekickGroup.VIRTUAL_GRANT,
    show_condition: [
      GibooSidekickCondition.SEARCH_COMPLETED,
      GibooSidekickCondition.TARGET_EXIST,
      GibooSidekickCondition.VIEW_VIRTUAL_GRANT,
      GibooSidekickCondition.EXPLORE_DETAIL_VIEW,
    ],
    matchIndex: [GibooSidekickURLMatchIndex.SEARCH, GibooSidekickURLMatchIndex.GRANT],
    data: [
      {
        id: "greeting",
        type: GibooSidekickChatComponentType.GREETING_WITHOUT_COLOR,
        message:
          "We're here to support you as you review the <b>”$grant_name”</b>.<br />Select an option below to get started.",
      },
      {
        id: GibooSidekickGAValue.WHY_MATCHED,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.WHY_MATCHED_AVAILABLE],
        message: "Is your organization a good match to this grant?",
        answer: "$why_matched",
        action_type: GibooSidekickActionType.WHY_MATCHED,
        icon: GibooSidekickIcon.WHY_MATCHED,
      },
      {
        id: GibooSidekickGAValue.CLICK_START_FUNDRAISING,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message:
          "What is the <b>next step</b> if you want to raise funds using this grant opportunity?",
        answer:
          "Try clicking on <b>”Start fundraising”</b>. By doing so, you can create a project and start fundraising. Don't be afraid, this is just the beginning of the fundraising process. ",
        action_button: {
          action_type: GibooSidekickActionType.START_FUNDRAISING,
          label: "Start fundraising",
        },
      },
      {
        id: GibooSidekickGAValue.CLICK_SAVE,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message:
          "Are you interested in this opportunity but haven't decided to proceed with the fundraising project yet?",
        answer:
          "You can <b>save</b> this opportunity for later. Simply click to add to your saved list to take a look later.",
        action_button: {
          action_type: GibooSidekickActionType.SAVE,
          label: "$label_save_list",
        },
      },
      {
        id: GibooSidekickGAValue.SEE_OPENCALL_GRANT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.HAS_OPENCALL_GRANT],
        message:
          "Are you interested in an <b>open call grant opportunity</b> that this funder is currently providing?",
        answer: "There is an open call grant made by $funder_name.",
        action_button: {
          action_type: GibooSidekickActionType.NAVIGATE,
          label: "View open call grant",
          action_target: `grants/$opencall_id`,
        },
      },
      {
        id: GibooSidekickGAValue.INFO_OPENCALL_GRANT,
        type: GibooSidekickChatComponentType.INFORMATION_BUTTON,
        show_condition: [
          GibooSidekickCondition.HAS_OPENCALL_GRANT,
          GibooSidekickCondition.ANSWERED_OPENCALL_GRANT,
        ],
        message: "What is an open call grant?",
        answer:
          "Open call grants, which include Request for Proposals (RFPs), offer organizations solicited opportunities to address broad or specific themes. Explore the open call page for comprehensive details on these valuable funding opportunities.",
        action_button: {
          label: "Search open call grants",
          action_type: GibooSidekickActionType.SEARCH_OPENCALL_GRANT,
        },
      },
    ],
  },
  {
    group: GibooSidekickGroup.OPENCALL_GRANT,
    show_condition: [
      GibooSidekickCondition.SEARCH_COMPLETED,
      GibooSidekickCondition.TARGET_EXIST,
      GibooSidekickCondition.VIEW_GRANT,
      GibooSidekickCondition.EXPLORE_DETAIL_VIEW,
    ],
    matchIndex: [GibooSidekickURLMatchIndex.SEARCH, GibooSidekickURLMatchIndex.GRANT],
    data: [
      {
        id: "greeting",
        type: GibooSidekickChatComponentType.GREETING_WITHOUT_COLOR,
        message:
          "We're here to support you as you review the <b>”$grant_name”</b>.<br />Select an option below to get started.",
      },
      {
        id: GibooSidekickGAValue.WHY_MATCHED,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.WHY_MATCHED_AVAILABLE],
        message: "Is your organization a good match to this grant?",
        answer: "$why_matched",
        action_type: GibooSidekickActionType.WHY_MATCHED,
        icon: GibooSidekickIcon.WHY_MATCHED,
      },
      {
        id: GibooSidekickGAValue.CLICK_START_FUNDRAISING,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message:
          "What is the <b>next step</b> if you want to raise funds using this grant opportunity?",
        answer:
          "Try clicking on <b>”Start fundraising”</b>. By doing so, you can create a project and start fundraising. Don't be afraid, this is just the beginning of the fundraising process. ",
        action_button: {
          action_type: GibooSidekickActionType.START_FUNDRAISING,
          label: "Start fundraising",
        },
      },
      {
        id: GibooSidekickGAValue.CLICK_SAVE,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message:
          "Are you interested in this opportunity but haven't decided to proceed with the fundraising project yet?",
        answer:
          "You can <b>save</b> this opportunity for later. Simply click to add to your saved list to take a look later.",
        action_button: {
          action_type: GibooSidekickActionType.SAVE,
          label: "Add to a saved list",
        },
      },
      {
        id: GibooSidekickGAValue.SEE_VIRTUAL_GRANT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.HAS_VIRTUAL_GRANT],
        message:
          "Are you interested in a <b>virtual grant opportunity</b> that this funder is currently providing?",
        answer: "There is a virtual grant made by $funder_name.",
        action_button: {
          action_type: GibooSidekickActionType.NAVIGATE,
          label: "View virtual grant",
          action_target: `grants/$virtual_grant_id`,
        },
      },
      {
        id: GibooSidekickGAValue.INFO_VIRTUAL_GRANT,
        type: GibooSidekickChatComponentType.INFORMATION_BUTTON,
        show_condition: [
          GibooSidekickCondition.HAS_VIRTUAL_GRANT,
          GibooSidekickCondition.ANSWERED_VIRTUAL_GRANT,
        ],
        message: "What is a virtual grant?",
        answer:
          "Virtual grants, generated by Giboo, leverage historical patterns from the funder's grant-making history. The content on this page is derived from past funding patterns, offering insights, but may not precisely reflect current open call and/or Request for Proposals (RFPs) opportunities issued by the funder.",
        action_button: {
          label: "Search virtual grants",
          action_type: GibooSidekickActionType.SEARCH_VIRTUAL_GRANT,
        },
      },
    ],
  },
  {
    group: GibooSidekickGroup.FUNDER,
    show_condition: [
      GibooSidekickCondition.SEARCH_COMPLETED,
      GibooSidekickCondition.TARGET_EXIST,
      GibooSidekickCondition.VIEW_FUNDER,
      GibooSidekickCondition.EXPLORE_DETAIL_VIEW,
    ],
    matchIndex: [GibooSidekickURLMatchIndex.SEARCH, GibooSidekickURLMatchIndex.FUNDER],
    data: [
      {
        id: "greeting",
        type: GibooSidekickChatComponentType.GREETING_WITHOUT_COLOR,
        message:
          "We're here to support you as you review the <b>”$funder_name</b>.<br />Select an option below to get started.",
      },
      {
        id: GibooSidekickGAValue.WHY_MATCHED,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.WHY_MATCHED_AVAILABLE],
        message: "Is your organization a good match to this grant?",
        answer: "$why_matched",
        action_type: GibooSidekickActionType.WHY_MATCHED,
        icon: GibooSidekickIcon.WHY_MATCHED,
      },
      {
        id: GibooSidekickGAValue.SCROLL_TO_MAP,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message:
          "Does this funder have an interest in the location where my organization provides services?",
        answer:
          "Examine this map. It allows you to identify a range of colors. The items in purple match your filter, and the intensity of the color indicates the level of support.",
        action_type: GibooSidekickActionType.SCROLL_TO_MAP,
      },
      {
        id: GibooSidekickGAValue.CLICK_START_FUNDRAISING,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message:
          "What is the <b>next step</b> if you want to raise funds using this grant opportunity?",
        answer:
          "Try clicking on <b>”Start fundraising”</b>. By doing so, you can create a project and start fundraising. Don't be afraid, this is just the beginning of the fundraising process. ",
        action_button: {
          action_type: GibooSidekickActionType.START_FUNDRAISING,
          label: "Start fundraising",
        },
      },
      {
        id: GibooSidekickGAValue.CLICK_SAVE,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [
          [GibooSidekickCondition.NOT_SAVED, GibooSidekickCondition.SAVE_QUESTION_OPENED],
        ],
        message:
          "Are you interested in this opportunity but haven't decided to proceed with the fundraising project yet?",
        answer: "$answer_save_list",
        action_button: {
          action_type: GibooSidekickActionType.SAVE,
          label: "$btn_label_save_list",
        },
      },
      {
        id: GibooSidekickGAValue.SCROLL_TO_TAGS,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message:
          "Would you like to view past grants related to ”$taxonomy_str” that this funder has supported?",
        answer:
          "Giboo provides information on each tag from past grant data provided by the funder.<br/>Click on any of the tags to view the information about the grantees.",
        action_type: GibooSidekickActionType.SCROLL_TO_TAGS,
      },
    ],
  },
  {
    group: GibooSidekickGroup.ORG_PROFILE,
    matchIndex: [GibooSidekickURLMatchIndex.ORG_PROFILE],
    data: [
      {
        id: GibooSidekickGAValue.DIVERSITY_ASSESSMENT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.ORG_EDIT_PERMISSION],
        message: "Want to showcase your organization's diversity?",
        answer:
          "Discover how you can highlight the rich diversity within your organization. Showcase the varied backgrounds and perspectives of your organization members to demonstrate your commitment to inclusivity and representation.",
        action_type: GibooSidekickActionType.SCROLL_TO,
        action_target: "diversity",
        action_button: {
          action_type: GibooSidekickActionType.NAVIGATE,
          label: "Add diversity information",
          action_target: "diversity",
        },
      },
      {
        id: GibooSidekickGAValue.FINANCIAL_ASSESSMENT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.ORG_EDIT_PERMISSION],
        message: "Curious about your organization's financial health?",
        answer:
          "Gain insights into your organization's financial well-being with detailed reports and analysis tools. Explore key metrics, financial trends, and performance indicators to make informed decisions and ensure the long-term sustainability of your organization.",
        action_type: GibooSidekickActionType.SCROLL_TO,
        action_target: "financials",
        action_button: {
          action_type: GibooSidekickActionType.NAVIGATE,
          label: "Assess financial information",
          action_target: "financial",
        },
      },
      {
        id: GibooSidekickGAValue.ORG_CONTACT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.ORG_EDIT_PERMISSION],
        message: "Try adding contact information",
        answer:
          "Add contact information to connect with your organization by providing up-to-date contact information. Make sure that potential funders or collaborators can reach out for inquiries, partnerships, or support by including email addresses, phone numbers, and physical addresses where applicable.",
        action_type: GibooSidekickActionType.SCROLL_TO,
        action_target: "contact",
        action_button: {
          action_type: GibooSidekickActionType.ORG_CONTACT,
          label: "Add contact information",
        },
      },
      {
        id: GibooSidekickGAValue.ORG_VERIFICATION,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.ORG_EDIT_PERMISSION],
        message: "Want to verify your organization?",
        answer:
          "Admins can verify the authenticity and legitimacy of their organization to build trust and credibility with stakeholders. Verification provides an official stamp of approval, confirming that your organization is genuine and adheres to established standards.",
        action_button: {
          label: "Verify organization",
          action_type: GibooSidekickActionType.VERIFY_ORG,
        },
      },
      {
        id: GibooSidekickGAValue.INVITE_ORG_MEMBERS,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [GibooSidekickCondition.ORG_EDIT_PERMISSION],
        message: "Ready to expand your team's collaboration within Giboo?",
        answer: "Invite your organization members to collaborate within Giboo!",
        action_button: {
          label: "Invite member",
          action_type: GibooSidekickActionType.INVITE_ORG_MEMBERS,
        },
      },
    ],
  },
  {
    group: GibooSidekickGroup.PROJECT,
    matchIndex: [GibooSidekickURLMatchIndex.PROJECT],
    data: [
      {
        id: GibooSidekickGAValue.PROJECT_INFORMATION,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        show_condition: [
          GibooSidekickCondition.PROJECT_INFOMATION_NOT_FILLED,
          GibooSidekickCondition.PROJECT_EDIT_PERMISSION,
        ],
        message: "Ready to finalize your project information?",
        answer:
          "Ensure your project is set up for success by entering all the necessary details accurately.",
        action_button: {
          action_type: GibooSidekickActionType.PROJECT_INFORMATION,
          label: "Complete project information",
        },
      },
      {
        id: GibooSidekickGAValue.DOCUMENT_AI,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Help me write $document_ai_target.",
        answer: "Sure! $go_to_document_ai_label",
        action_type: GibooSidekickActionType.NAVIGATE,
        action_target: "/project/$project_id/document",
      },
      {
        id: GibooSidekickGAValue.SEARCH_VIRTUAL_GRANT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Do you want to <b>search grants</b> that best fit this project?",
        answer:
          "Explore the list of possibilities tailored to your project's needs. Giboo offers a curated list of opportunities that align perfectly with your project's goals, helping you find the best fit to propel your project forward.",
        action_button: {
          action_type: GibooSidekickActionType.SEARCH_VIRTUAL_GRANT_TEXT,
          label: "Search grants",
          action_target: "",
        },
      },
      {
        id: GibooSidekickGAValue.INVITE_PROJECT_MEMBERS,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Who's part of your project team?",
        answer: "Collaborate on the project by inviting your organization members!",
        action_button: {
          action_type: GibooSidekickActionType.INVITE_PROJECT_MEMBERS,
          label: "Invite",
        },
      },
      {
        id: GibooSidekickGAValue.PROJECT_ACTIVITIES,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "There has been a recent update in project activities",
        answer: "Update or stay informed about the latest activities related to your project.",
        action_button: {
          action_type: GibooSidekickActionType.PROJECT_ACTIVITIES,
          label: "View activities",
        },
      },
      {
        id: GibooSidekickGAValue.PROJECT_FUNDRAISING_STATUS,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Wondering how far along your project has come?",
        answer:
          "Gain insight into your project's progress and see how far you've come. Giboo offers a clear overview of your project's status, helping you track your progress and celebrate achievements.",
        action_button: {
          action_type: GibooSidekickActionType.PROJECT_FUNDRAISING_STATUS,
          label: "View fundraising status",
        },
      },
      {
        id: GibooSidekickGAValue.PROJECT_SUBTASKS,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Check if you are on track to meet the project deadline.",
        answer: "Keep the project on schedule by monitoring your progress towards the deadline.",
        action_button: {
          action_type: GibooSidekickActionType.PROJECT_SUBTASKS,
          label: "View tasks",
        },
      },
      {
        id: GibooSidekickGAValue.SEARCH_FUNDER,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Do you want to <b>search funders</b> that best fit this project?",
        answer:
          "Explore the list of possibilities tailored to your project's needs. Giboo offers a curated list of opportunities that align perfectly with your project's goals, helping you find the best fit to propel your project forward.",
        action_button: {
          action_type: GibooSidekickActionType.SEARCH_FUNDER_TEXT,
          label: "Search funders",
          action_target: "",
        },
      },
    ],
  },
  {
    group: GibooSidekickGroup.PROJECT_TASK,
    matchIndex: [GibooSidekickURLMatchIndex.PROJECT_TASK],
    show_condition: [GibooSidekickCondition.PROJECT_TASK_LOADED],
    data: [
      {
        id: GibooSidekickGAValue.DIVERSITY_ASSESSMENT,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Are there new tasks for this $target_type?",
        answer:
          "Create a new task to raise funds from this $target_type: By setting up a dedicated task, you can effectively strategize and execute your fundraising efforts to maximize success.",
        action_button: {
          action_type: GibooSidekickActionType.PROJECT_CREATE_SUBTASK,
          label: "Create a task",
        },
      },
      {
        id: GibooSidekickGAValue.VIEW_OPPORTUNITY_DETAIL,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message: "Interested in the details about this opportunity?",
        answer: "Let’s view the details of this opportunity.",
        action_button: {
          action_type: GibooSidekickActionType.PROJECT_TARGET_DETAIL,
          label: "View detail",
        },
      },
      {
        id: GibooSidekickGAValue.PROJECT_PROGRESS,
        type: GibooSidekickChatComponentType.ACTION_BUTTON,
        message:
          "How is the project progressing? Let's provide an update on the progress and activities.",
        answer:
          "Please keep your project progress updated to stay informed or share with members of your organization. Record the latest activities to effectively track the project's advancements.",
        action_button: {
          action_type: GibooSidekickActionType.PROJECT_PROGRESS,
          label: "Update project progress",
        },
      },
    ],
  },
  ...(process.env.REACT_APP_ENV === "DEV"
    ? [
        {
          group: GibooSidekickGroup.DOCUMENT_AI,
          matchIndex: [GibooSidekickURLMatchIndex.DOCUMENT_AI],
          show_condition: [GibooSidekickCondition.DOCUMENT_GENERATED],
          data: [
            {
              id: GibooSidekickGAValue.DOCUMENT_AI_ASKING_INFO_FOCUS_AREA,
              type: GibooSidekickChatComponentType.ACTION_BUTTON,
              message:
                "Can you provide insights into the focus areas that this funder prioritizes, and how closely they align with our project and organization's mission?",
              answer: "Click to ask again",
              action_type: GibooSidekickActionType.DOCUMENT_AI_ASKING_INFO_COMMAND,
              action_target:
                "Can you provide insights into the focus areas that this funder prioritizes, and how closely they align with our project and organization's mission?",
            },
            {
              id: GibooSidekickGAValue.DOCUMENT_AI_ASKING_INFO_BENEFICIARY,
              type: GibooSidekickChatComponentType.ACTION_BUTTON,
              message:
                "What specific types of beneficiaries does this funder typically support, and how does our project align with those demographics or groups?",
              answer: "Click to ask again",
              action_type: GibooSidekickActionType.DOCUMENT_AI_ASKING_INFO_COMMAND,
              action_target:
                "What specific types of beneficiaries does this funder typically support, and how does our project align with those demographics or groups?",
            },
            {
              id: GibooSidekickGAValue.DOCUMENT_AI_ASKING_INFO_SERVICE_LOC,
              type: GibooSidekickChatComponentType.ACTION_BUTTON,
              message:
                "What is the service area preferences of this funder, and how well our project fits within those parameters?",
              answer: "Click to ask again",
              action_type: GibooSidekickActionType.DOCUMENT_AI_ASKING_INFO_COMMAND,
              action_target:
                "What is the service area preferences of this funder, and how well our project fits within those parameters?",
            },
            {
              id: GibooSidekickGAValue.DOCUMENT_AI_ASKING_INFO_PAST_GRANTS,
              type: GibooSidekickChatComponentType.ACTION_BUTTON,
              show_condition: [
                [
                  GibooSidekickCondition.DOCUMENT_FOR_VIRTUAL_GRANT,
                  GibooSidekickCondition.DOCUMENT_FOR_FUNDER,
                ],
              ],
              message:
                "What is the past grant history with this funder, particularly in terms of similar organizations as ours?",
              action_type: GibooSidekickActionType.DOCUMENT_AI_ASKING_INFO_COMMAND,
              action_target:
                "What is the past grant history with this funder, particularly in terms of similar organizations as ours?",
            },
            {
              id: GibooSidekickGAValue.DOCUMENT_AI_ASKING_INFO_ELIGIBILITY,
              type: GibooSidekickChatComponentType.ACTION_BUTTON,
              show_condition: [
                GibooSidekickCondition.DOCUMENT_FOR_OPENCALL_GRANT,
                GibooSidekickCondition.DOCUMENT_LOI,
              ],
              message:
                "Are there any eligibility criteria or requirements that our organization needs to meet in order to apply for funding from this funder?",
              answer: "Click to ask again",
              action_type: GibooSidekickActionType.DOCUMENT_AI_ASKING_INFO_COMMAND,
              action_target:
                "Are there any eligibility criteria or requirements that our organization needs to meet in order to apply for funding from this funder?",
            },
            {
              id: GibooSidekickGAValue.DOCUMENT_AI_ASKING_INFO_APPLICATION_REQUIREMENT,
              type: GibooSidekickChatComponentType.ACTION_BUTTON,
              show_condition: [
                GibooSidekickCondition.DOCUMENT_FOR_OPENCALL_GRANT,
                GibooSidekickCondition.DOCUMENT_LOI,
              ],
              message:
                "What are the key application requirements or components that we should prioritize when crafting our $document_type?",
              answer: "Click to ask again",
              action_type: GibooSidekickActionType.DOCUMENT_AI_ASKING_INFO_COMMAND,
              action_target:
                "What are the key application requirements or components that we should prioritize when crafting our $document_type?",
            },
            {
              id: GibooSidekickGAValue.DOCUMENT_AI_ASKING_PAST_GRANTEE_LOC,
              type: GibooSidekickChatComponentType.ACTION_BUTTON,
              show_condition: [
                [
                  GibooSidekickCondition.DOCUMENT_FOR_VIRTUAL_GRANT,
                  GibooSidekickCondition.DOCUMENT_FOR_FUNDER,
                ],
              ],
              message:
                "Where have previous grant recipients of this funder been located geographically, and how might that inform our approach in the letter of intent?",
              answer: "Click to ask again",
              action_type: GibooSidekickActionType.DOCUMENT_AI_ASKING_INFO_COMMAND,
              action_target:
                "Where have previous grant recipients of this funder been located geographically, and how might that inform our approach in the letter of intent?",
            },
            // {
            //   id: GibooSidekickGAValue.DOCUMENT_AI,
            //   type: GibooSidekickChatComponentType.ACTION_BUTTON,
            //   message:
            //     "Is there any additional documentation or supplementary materials that this funder typically requests alongside a letter of intent, and how should we prepare for that?",
            //   action_type: GibooSidekickActionType.DOCUMENT_AI_ASKING_INFO_COMMAND,
            //   action_target:
            //     "Is there any additional documentation or supplementary materials that this funder typically requests alongside a letter of intent, and how should we prepare for that?",
            // },
            {
              id: GibooSidekickGAValue.DOCUMENT_AI_ASKING_TIP,
              type: GibooSidekickChatComponentType.ACTION_BUTTON,
              show_condition: [GibooSidekickCondition.DOCUMENT_LOI],
              message:
                "Can you offer any tips or best practices for making our $document_type stand out among other submissions to this funder?",
              answer: "Click to ask again",
              action_type: GibooSidekickActionType.DOCUMENT_AI_ASKING_INFO_COMMAND,
              action_target:
                "Can you offer any tips or best practices for making our $document_type stand out among other submissions to this funder?",
            },
          ],
        },
      ]
    : []),
];
export { chatData };
