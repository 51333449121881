import { useCallback, useEffect, useState } from "react";
import {
  selectNPOInfo,
  selectPhilanthropyRecommended,
  selectPhilanthropyRequested,
  updateOnboardingAsync,
} from "../../../app/onboardingSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import Label from "../../../components/Label";
import PrimaryButton from "../../../components/PrimaryButton";
import OnboardingBack from "./OnboardingBack";
import GibooLocationInput from "../../../components/GibooLocationInput";
import Tag from "../../../components/Tag";
import useLocationRecommendation from "../../../hooks/useLocationRecommendation";
import Spinner from "../../../components/Spinner";
import classNames from "classnames";
import { scrollBarClass } from "../../../utils/classes";
import useOnboardingData from "../../../hooks/useOnboarding";
import { Information, MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import { stateIdToStateName } from "../../../utils/formatHelper";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import { ILocation, reprLocation } from "../../../types/location";

function ServiceLocation() {
  const dispatch = useAppDispatch();
  const mxEvent = useGibooMixpanel("onboarding", true);
  const [onboardingData] = useOnboardingData();
  const { location: head_location } = useAppSelector((state) => selectNPOInfo(state));
  const { service_loc_requested } = useAppSelector((state) => selectPhilanthropyRequested(state));
  const [location, setLocation] = useState<ILocation[]>(service_loc_requested || []);
  const [open, setOpen] = useState<boolean>(false);
  const { recommended, addCenter, isLoading } = useLocationRecommendation(
    location,
    head_location ? [head_location] : [],
    onboardingData.country === "US" && stateIdToStateName(onboardingData.state_id)
      ? stateIdToStateName(onboardingData.state_id)
      : onboardingData.country,
    true,
  );
  //mx-panel
  //service areas tags
  const [suggestedTagClicked, setSuggestedTagClicked] = useState<string[]>([]);
  const [ownTagClicked, setOwnTagClicked] = useState<string[]>([]);

  useEffect(() => {
    if (!head_location) return;
    addCenter(head_location);
  }, [head_location]);

  const renderLocationSelection = () => {
    return (
      <div className="onboarding-width-lg onboarding-body mt-[28px] w-[750px] !gap-[28px]">
        {/* SUGGESTED LOCATION */}
        <div className="flex flex-col gap-4 rounded border border-gray-300 p-4">
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-purple-500">
              {`Select from the following suggested service areas (${recommended.length})`}
              {isLoading && <Spinner size="sm" span wrapperClass="ml-2" />}
            </h5>
            <h6>
              Please select the service areas that most accurately represent your organization.
              <br />
              If the service areas you envision are not listed, you may search for additional
              service areas.
            </h6>
          </div>
          <div
            id="container-suggested-tags"
            className={classNames(
              "flex max-h-[110px] flex-wrap gap-2 rounded border border-gray-300 p-4",
              scrollBarClass,
              { ["hidden"]: recommended.length === 0 },
            )}
          >
            {recommended.map((l) => (
              <Tag
                id={`btn-location-${reprLocation(l)}`}
                key={reprLocation(l)}
                name={reprLocation(l)}
                color="orange"
                onClick={() => {
                  setLocation((prev) =>
                    prev.map((loc) => reprLocation(loc)).includes(reprLocation(l))
                      ? prev
                      : [...prev, l],
                  );
                  mxEvent(MIXPANEL_EVENTS_V2.onboarding.location.added, {
                    type: "service_location",
                    name: reprLocation(l),
                  });
                  addCenter(l);
                }}
                onAdd={() => {
                  //
                }}
                size="sm"
                hover
              />
            ))}
          </div>
          {open ? (
            <div className={classNames("onboarding-width-lg container flex flex-col gap-3")}>
              <GibooLocationInput
                from_for_mixpanel="onboarding"
                asMixPanelProperty={(l) => ({ type: "service_location", name: reprLocation(l) })}
                wrapperClass="self-start w-full"
                searchFieldPosition="start"
                id="location"
                value={location}
                setValue={(l) => {
                  setLocation(l);
                }}
                onAdd={(loc) => {
                  mxEvent(MIXPANEL_EVENTS_V2.onboarding.location.added, {
                    type: "service_location",
                    name: reprLocation(loc),
                    own: true,
                  });
                  addCenter(loc);
                }}
                maxWidth="900px"
                inputBoxMaxWidth="480px"
                itemsMaxHeight="480px"
                itemsPosition="top"
                itemsAlign="start"
                gap={5}
                hideNone
                hideItems
                placeholder={"Search and add your service areas"}
                icon
                matched
                float
                tagSize="sm"
                isFullTagClick
                spinnerBackgroundColor="bg-gray-50"
              />
            </div>
          ) : (
            <div
              id="btn-add-your-own"
              className="flex w-fit cursor-pointer items-center gap-3 rounded border border-gray-300 px-2 py-[2px] hover:border-gray-500 hover:bg-gray-100"
              onClick={() => {
                setOpen(true);
              }}
            >
              <i className="gi-search !text-xs text-gray-600" />
              <h5 className="text-gray-700">Add your own</h5>
            </div>
          )}
        </div>
        {/* SELECTED LOCATION */}
        <div className="flex flex-col gap-4 rounded border border-gray-300 p-4">
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold text-purple-500">
              {`Selected service areas (${location.length})`}
            </h5>
          </div>
          <div
            className={classNames("flex flex-wrap gap-2 rounded border border-gray-300 p-4", {
              ["hidden"]: location.length === 0,
            })}
          >
            {location.map((l) => {
              return (
                <Tag
                  id={`btn-location-${reprLocation(l)}`}
                  key={reprLocation(l)}
                  name={reprLocation(l)}
                  color="orange"
                  matched
                  onDelete={() => {
                    setLocation([...location.filter((item) => item !== l)]);
                    mxEvent(MIXPANEL_EVENTS_V2.onboarding.location.removed, {
                      type: "service_location",
                      name: reprLocation(l),
                    });
                  }}
                  size="sm"
                  hover
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="onboarding flex max-w-[970px] flex-col">
        <div className="onboarding-header onboarding-width-lg2">
          <div className="flex flex-col gap-3">
            <h4 className="onboarding-header">
              In which geographic areas does your organization provide services?
            </h4>
            <p className="onboarding-sub-header onboarding-width-lg">
              This includes cities, states, or countries where your beneficiaries are situated.
              Please provide the 1-5 most important places.{" "}
            </p>
          </div>
        </div>

        {renderLocationSelection()}
        <div className={classNames("my-10 inline-flex items-center gap-2")}>
          <OnboardingBack
            outline
            data={{
              service_loc_requested: location,
            }}
          />
          <PrimaryButton
            className="text-sm"
            size="md"
            id="btn-next"
            label="Next"
            rightIconClass={<i className="gi gi-right-arrow" />}
            handleOnClick={() => {
              mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
                field: Information.SERVICE_AREAS,
                locations: location.map((l) => ({
                  name: reprLocation(l),
                  type: "service_location",
                })),
              });
              dispatch(
                updateOnboardingAsync(
                  {
                    service_loc_requested: location,
                  },
                  +1,
                ),
              );
            }}
            disabled={location.length === 0}
          />
        </div>
      </div>
    </>
  );
}
export default ServiceLocation;
