import axios, { AxiosError } from "axios";
import { stringify } from "querystring";

const parseError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (typeof error.response?.data === "string") {
      return error.response?.data;
    } else if (typeof error.response?.data.detail === "string") {
      return error.response?.data.detail;
    } else if (Array.isArray(error.response?.data.detail)) {
      const a: string[] = [];
      for (let i = 0; i < error.response?.data.detail.length; i++) {
        a.push(error.response?.data.detail[i].msg);
      }
      return a.join("\n");
    } else {
      // console.log('unknown error format', error)
    }
  } else if (typeof error === "string") {
    return error as string;
  }

  // console.log('unknown error format', error)
  return error as string;
};

export default parseError;
