import classNames from "classnames";
import GibooGradientDiv from "../../../../components/GibooGradientDiv";
import NpoFinancialAssetsCategories from "../components/NpoFinancialAssetsCategories";
import NpoFinancialContribution from "../components/NpoFinancialContribution";
import NpoFinancialMonthLiquid from "../components/NpoFinancialMonthLiquid";
import NpoFinancialOperatingExpenses from "../components/NpoFinancialOperatingExpenses";
import NpoFinancialRevenue from "../components/NpoFinancialRevenue";
import NpoFinancialRevenueExpenses from "../components/NpoFinancialRevenueExpenses";
import NpoFinancialRevenueStream from "../components/NpoFinancialRevenueStream";
import { RestartAssessment } from "../../Home";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/PrimaryButton";
import { SuggestEditButton } from "../../../../components/SuggestEditButton";
import ReviewAndEditCard from "../../../../components/ReviewAndEditCard";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import useNPOIRSProfile from "../../../../hooks/useNPOIRSProfile";
import IMG_SUPPORTERS from "../../../../assets/images/top-supporters.svg";
import UploadIRSModal from "../../../../components/UploadIRSModal";
import { useState } from "react";
function NpoFinancials({
  isPrivateView,
  hasWritePermission,
  npo_id,
  org_id,
  compact = false,
  inProgress = false,
  progressIndex,
  withoutLogin,
  isProfileClaim,
  showAll = false,
}: {
  isPrivateView?: boolean;
  hasWritePermission?: boolean;
  npo_id?: string;
  org_id?: string;
  compact?: boolean;
  inProgress?: boolean;
  progressIndex?: number;
  withoutLogin?: boolean;
  isProfileClaim?: boolean;
  showAll?: boolean;
}) {
  const navigate = useNavigate();
  const [showUploadIRS, setShowUploadIRS] = useState<boolean>(false);
  const {
    data: profileData,
    isLoading: isProfileLoading,
    update: updateProfile,
    revalidate,
  } = useOrgProfile(org_id);
  const { data } = useNPOIRSProfile(npo_id);
  const isPrivateViewConsideringFinancialStarted =
    !profileData.financial_completed &&
    (!profileData.financial_saved_index || profileData.financial_saved_index < 1)
      ? false
      : isPrivateView;
  return (
    <div
      className={classNames(
        "flex flex-col gap-5 border-gray-300 bg-white p-5",
        !compact && "rounded border",
      )}
    >
      {showUploadIRS && org_id && (
        <UploadIRSModal open={showUploadIRS} setOpen={setShowUploadIRS} />
      )}
      <div className="inline-flex items-center justify-between">
        <h4 className="font-poppins text-xl font-semibold text-gray-900">Financial</h4>
        {withoutLogin === true && isProfileClaim === true && <SuggestEditButton />}
        {isPrivateView && !showAll && (
          <>
            {inProgress ? (
              <PrimaryButton
                size="md"
                id={`btn-financial-info`}
                disabled={!hasWritePermission}
                label={"Resume to add financial information"}
                className="!h-[29px] !text-sm"
                leftIconClass={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M2.271 15.0968V3.90642C2.271 3.71335 2.33672 3.55154 2.46815 3.42101C2.59959 3.29048 2.76094 3.22567 2.95221 3.22657C3.14529 3.22657 3.30709 3.29138 3.43762 3.42101C3.56725 3.55154 3.63206 3.71335 3.63206 3.90642V15.0968C3.63206 15.2899 3.5668 15.4517 3.43626 15.5822C3.30573 15.7128 3.14393 15.7776 2.95085 15.7767C2.75777 15.7767 2.59642 15.7118 2.46679 15.5822C2.33717 15.4526 2.271 15.2908 2.271 15.0968ZM8.52972 14.7881L15.7878 10.448C15.9782 10.3365 16.116 10.1973 16.2012 10.0305C16.2864 9.86375 16.329 9.68744 16.329 9.50161C16.329 9.31579 16.2864 9.13948 16.2012 8.97269C16.116 8.8059 15.9782 8.66675 15.7878 8.55526L8.52972 4.21508C8.43364 4.16432 8.34072 4.1276 8.25098 4.10494C8.16034 4.08228 8.06969 4.07095 7.97904 4.07095C7.68444 4.07095 7.42655 4.17066 7.20537 4.37008C6.98419 4.56951 6.8736 4.83556 6.8736 5.16823V13.835C6.8736 14.1677 6.98419 14.4337 7.20537 14.6331C7.42655 14.8326 7.68398 14.9323 7.97768 14.9323C8.07014 14.9323 8.16079 14.9209 8.24962 14.8983C8.34027 14.8756 8.43364 14.8394 8.52972 14.7895M8.2333 13.3115V5.69172L14.6008 9.50161L8.2333 13.3115Z"
                      fill="white"
                    />
                  </svg>
                }
                handleOnClick={() => navigate("/financial/detail")}
              />
            ) : (
              <RestartAssessment
                hasWritePermission={hasWritePermission}
                type="financial"
                org_id={org_id}
              />
            )}
          </>
        )}
      </div>

      {isPrivateView && hasWritePermission && showAll && !profileData.reviewed_financial && (
        <ReviewAndEditCard
          content={
            <>
              {data.length > 0 ? (
                <>
                  Giboo has pre-loaded your organization&apos;s financial information. <br />
                  Please confirm to ensure your public profile accurately represents your
                  organization.
                </>
              ) : (
                <>
                  Giboo can assist with visualizing and analyzing your financial health. If you
                  upload your IRS Form 990, we will review it and provide an analysis of your
                  financial situation.
                </>
              )}
            </>
          }
          wrapperClass=""
          handleOnClick={() => {
            updateProfile({
              reviewed_financial: true,
              financial_completed: true,
              financial_saved_index: 10,
            });
          }}
        />
      )}
      {data.length === 0 ? (
        <div className="grid h-[239px] w-full place-items-center">
          <img src={IMG_SUPPORTERS} className="h-[124px] w-[132px]" />
          <p className="mt-2 font-semibold">No information available</p>
          {isPrivateView && hasWritePermission ? (
            <h5 className="mt-1 text-center font-poppins text-sm font-normal text-gray-900">
              <span
                className="cursor-pointer text-purple-500"
                onClick={() => {
                  setShowUploadIRS(true);
                }}
              >
                Click here
              </span>{" "}
              to upload IRS 990 for analysis
            </h5>
          ) : (
            <h5></h5>
          )}
        </div>
      ) : (
        <div
          className={classNames(
            "flex flex-col",
            compact ? "gap-10" : "gap-10",
            isPrivateView &&
              hasWritePermission &&
              showAll &&
              !profileData.reviewed_financial &&
              "pointer-events-none opacity-40",
          )}
        >
          {/* <div>
          <p className="font-poppins text-xl font-semibold text-purple-500">
            Operating performance{" "}
            <span className="font-poppins !text-xs !font-normal text-gray-700">
              (Are they living within their means?)
            </span>
          </p>
        </div> */}
          {(progressIndex && 1 < progressIndex) || showAll ? (
            <NpoFinancialRevenue
              allowEdit={false}
              isPrivateView={isPrivateViewConsideringFinancialStarted}
              npo_id={npo_id}
              org_id={org_id}
              compact={compact}
              showInfo
              showStats
            />
          ) : (
            <></>
          )}
          {(progressIndex && 2 < progressIndex) || showAll ? (
            <NpoFinancialContribution
              allowEdit={false}
              isPrivateView={isPrivateViewConsideringFinancialStarted}
              npo_id={npo_id}
              org_id={org_id}
              compact={compact}
              showInfo
              showStats
            />
          ) : (
            <></>
          )}
          {(progressIndex && 4 < progressIndex) || showAll ? (
            <NpoFinancialRevenueStream
              allowEdit={false}
              isPrivateView={isPrivateViewConsideringFinancialStarted}
              npo_id={npo_id}
              org_id={org_id}
              compact={compact}
              showInfo
              showStats
            />
          ) : (
            <></>
          )}
          {(progressIndex && 5 < progressIndex) || showAll ? (
            <NpoFinancialRevenueExpenses
              allowEdit={false}
              isPrivateView={isPrivateViewConsideringFinancialStarted}
              npo_id={npo_id}
              org_id={org_id}
              compact={compact}
              showInfo
              showStats
            />
          ) : (
            <></>
          )}
          {(progressIndex && 6 < progressIndex) || showAll ? (
            <NpoFinancialOperatingExpenses
              allowEdit={false}
              isPrivateView={isPrivateViewConsideringFinancialStarted}
              npo_id={npo_id}
              org_id={org_id}
              compact={compact}
              showInfo
              showStats
              withoutLogin={withoutLogin}
              isProfileClaim={withoutLogin === true && isProfileClaim === true}
            />
          ) : (
            <></>
          )}
          {/* <div>
          <p className="font-poppins text-xl font-semibold ">
            Balance sheet needs
            <span className="font-poppins !text-xs !font-normal text-gray-700">
              (How much risk can they take?)
            </span>
          </p>
        </div> */}
          {(progressIndex && 8 < progressIndex) || showAll ? (
            <NpoFinancialAssetsCategories
              allowEdit={false}
              isPrivateView={isPrivateViewConsideringFinancialStarted}
              npo_id={npo_id}
              org_id={org_id}
              compact={compact}
              showInfo
              showStats
              withoutLogin={withoutLogin}
              isProfileClaim={withoutLogin === true && isProfileClaim === true}
            />
          ) : (
            <></>
          )}
          {(progressIndex && 9 < progressIndex) || showAll ? (
            <NpoFinancialMonthLiquid
              allowEdit={false}
              isPrivateView={isPrivateViewConsideringFinancialStarted}
              npo_id={npo_id}
              org_id={org_id}
              compact={compact}
              showInfo
              showStats
              withoutLogin={withoutLogin}
              isProfileClaim={withoutLogin === true && isProfileClaim === true}
            />
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}
export default NpoFinancials;
