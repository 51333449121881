import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { ReactNode, useCallback } from "react";
import { swrOptionDedupling5mins } from "../../types/swr";
import { NPOSummary, FinancialRecommendedActions } from "../../types/npo";
interface FinancialRecommendedActionsState {
  data: FinancialRecommendedActions | undefined;
  error: any;
  isLoading: boolean;
}
type FinancialRecommendedActionTypes =
  | "asset"
  | "business_model"
  | "contribution"
  | "expense"
  | "luna"
  | "net_profit"
  | "revenue"
  | "assessment";

function useFinancialRecommendedActions(
  _id?: string,
  type?: FinancialRecommendedActionTypes,
  by_npo = false,
): FinancialRecommendedActionsState {
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<FinancialRecommendedActions>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data);
  };
  const { data, isLoading, error } = useSWR<FinancialRecommendedActions>(
    _id && type
      ? process.env.REACT_APP_API_URL +
          `/api/v2/${by_npo ? "npos" : "orgs"}/${_id}/recommend_action?type=${type}`
      : null,
    fetch,
    swrOptionDedupling5mins,
  );

  return {
    data: data,
    isLoading,
    error,
  };
}
export default useFinancialRecommendedActions;
