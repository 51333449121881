import axios from "axios";
import config from "../api";
import { ILocation, locationAutoComplete, reprLocation } from "../types/location";
import { FunderSearchResult, GrantSearchResult, NPOSearchResult } from "../types/search";
import { TaxonomyConcept } from "../types/taxonomy";

const focusAutoComplete = async (key: string): Promise<TaxonomyConcept[]> => {
  if (key.length < 1)
    return new Promise<TaxonomyConcept[]>((resolve, reject) => reject("Query is not long enough."));
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v2/taxonomy/search?name=${encodeURIComponent(
        key,
      )}&taxonomy=focus_area`,
      config,
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => new Promise<TaxonomyConcept[]>((resolve, reject) => reject(e)));
};
const beneficiaryAutoComplete = async (key: string): Promise<TaxonomyConcept[]> => {
  if (key.length < 1)
    return new Promise<TaxonomyConcept[]>((resolve, reject) => reject("Query is not long enough."));
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v2/taxonomy/search?name=${encodeURIComponent(
        key,
      )}&taxonomy=beneficiary`,
      config,
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => new Promise<TaxonomyConcept[]>((resolve, reject) => reject(e)));
};
const programAutoComplete = async (key: string): Promise<TaxonomyConcept[]> => {
  if (key.length < 1)
    return new Promise<TaxonomyConcept[]>((resolve, reject) => reject("Query is not long enough."));
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v2/taxonomy/search?name=${encodeURIComponent(
        key,
      )}&taxonomy=program`,
      config,
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => new Promise<TaxonomyConcept[]>((resolve, reject) => reject(e)));
};
const allTaxonomyAutoComplete = async (key: string): Promise<TaxonomyConcept[]> => {
  if (key.length < 3)
    return new Promise<TaxonomyConcept[]>((resolve, reject) => reject("Query is not long enough."));
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v2/taxonomy/search?name=${encodeURIComponent(
        key,
      )}&taxonomy=all`,
      config,
    )
    .then((res) => {
      return res.data;
    })
    .catch((e) => new Promise<TaxonomyConcept[]>((resolve, reject) => reject(e)));
};
const getLocationKey = (location: ILocation): string => {
  if (location.level === 0) return location.country || location.iso2;
  if (location.level === 1) return location.state_name || "";
  if (location.level === 2) return location.county_name || "";
  return location.city || "";
};
const getPriority = (_i: string, _key: string): number => {
  const i = _i.toLowerCase();
  const key = _key.toLowerCase();
  if (i === key) return 0;
  else if (i.startsWith(`${key} `)) return 1;
  else if (i.includes(` ${key} `)) return 2;
  else if (i.startsWith(`${key}`)) return 3;
  else if (i.includes(` ${key}`)) return 4;
  else if (i.includes(`${key}`)) return 5;
  return 6;
};
const sortTaxonomyConcept = (a: TaxonomyConcept, b: TaxonomyConcept): number => {
  if (a.priority === undefined && b.priority !== undefined) return 1;
  if (a.priority !== undefined && b.priority === undefined) return -1;
  if (a.score === undefined || b.score === undefined) return 0;
  if (a.priority === b.priority) return a.score - b.score;
  if (a.priority === undefined || b.priority === undefined) return 0;
  return a.priority - b.priority;
};
const allTaxonomyAndLocationAutoComplete = async (key: string): Promise<TaxonomyConcept[]> => {
  if (key.length < 1)
    return new Promise<TaxonomyConcept[]>((resolve, reject) => reject("Query is not long enough."));
  const promises = [allTaxonomyAutoComplete(key), locationAutoComplete(key)];
  return Promise.all(promises).then((res) => {
    return [
      ...(res[0] as TaxonomyConcept[]).map((i) => ({ ...i, priority: getPriority(i.label, key) })),
      ...res[1].map((i) => ({
        type: "l",
        location: i as ILocation,
        label: reprLocation(i as ILocation),
        score: ((i as ILocation).score === 0 ? 0 : (i as ILocation).score || 1) + 0.3,
        priority: getPriority(getLocationKey(i as ILocation), key),
        object_id: "",
      })),
    ].sort(sortTaxonomyConcept);
  });
};
interface INPO {
  _id: string;
  name: string;
  ein: string;
  address: string;
  score?: number;
}
interface IDonor {
  _id: string;
  name: string;
  ein: string;
  address: string;
  score?: number;
}
interface IGrant {
  _id: string;
  title: string;
  type: string;
}
const regEin = new RegExp(/^([0-9]{7,9})|^([0-9]{2}-[0-9]{5,7})/);
const npoAutoComplete = async (
  query: string,
  match_similar = false,
  skip = 0,
  limit = 50,
  controller?: AbortController,
): Promise<NPOSearchResult[]> => {
  if (query.trim().length < 1) {
    return new Promise<NPOSearchResult[]>((resolve, reject) => reject("Query is not long enough."));
  }
  const params = regEin.test(query)
    ? `ein=${query.replaceAll("-", "")}`
    : `name=${encodeURIComponent(query)}`;
  // console.log("call,", query);
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v2/search/npos?${params}&skip=${skip}&limit=${limit}${
        match_similar ? "&match_similar=true" : ""
      }`,
      {
        ...config,
        ...(controller ? { signal: controller.signal } : {}),
      },
    )
    .then((res) => {
      return res.data.map((r: any) => ({
        ...r,
        focus_area: [],
        beneficiary: [],
        program: [],
        service_loc: [],
        search_type: "npo",
        search_by_name: true,
      }));
    })
    .catch((e) => new Promise<NPOSearchResult[]>((resolve, reject) => reject(e)));
};
const donorAutoComplete = async (
  query: string,
  match_similar = false,
  skip = 0,
  limit = 50,
  controller?: AbortController,
): Promise<FunderSearchResult[]> => {
  if (query.trim().length < 1) {
    return new Promise<FunderSearchResult[]>((resolve, reject) => resolve([])); //reject("Query is not long enough."));
  }
  const params = regEin.test(query)
    ? `ein=${query.replaceAll("-", "")}`
    : `name=${encodeURIComponent(query)}`;
  // console.log("call,", query);
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v2/search/donors?${params}&skip=${skip}&limit=${limit}${
        match_similar ? "&match_similar=true" : ""
      }`,
      {
        ...config,
        ...(controller ? { signal: controller.signal } : {}),
      },
    )
    .then((res) => {
      return res.data.map((r: any) => ({
        ...r,
        focus_area: [],
        beneficiary: [],
        program: [],
        service_loc: [],
        search_type: "funder",
        search_by_name: true,
      }));
    })
    .catch((e) => new Promise<FunderSearchResult[]>((resolve, reject) => reject(e)));
};
const grantAutoComplete = async (
  query: string,
  match_similar = false,
  skip = 0,
  limit = 50,
  controller?: AbortController,
  grant_type?: undefined | "virtual" | "opencall",
): Promise<GrantSearchResult[]> => {
  if (query.length < 1) {
    return new Promise<GrantSearchResult[]>((resolve, reject) =>
      reject("Query is not long enough."),
    );
  }
  const params = `name=${encodeURIComponent(query)}`;
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v2/search/grants?${params}&skip=${skip}&limit=${limit}${
        match_similar ? "&match_similar=true" : ""
      }${grant_type ? `&grant_type=${grant_type}` : ""}`,
      {
        ...config,
        ...(controller ? { signal: controller.signal } : {}),
      },
    )
    .then((res) => {
      return res.data.map((r: any) => ({
        ...r,
        focus_area: [],
        beneficiary: [],
        program: [],
        service_loc: [],
        search_type: "grant",
        search_by_name: true,
      }));
    })
    .catch((e) => new Promise<GrantSearchResult[]>((resolve, reject) => reject(e)));
};

export {
  allTaxonomyAndLocationAutoComplete,
  allTaxonomyAutoComplete,
  beneficiaryAutoComplete,
  donorAutoComplete,
  focusAutoComplete,
  grantAutoComplete,
  npoAutoComplete,
  programAutoComplete,
};
