import axios from "axios";
import { createRef, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import config from "../api";
import { signupUserState } from "../app/recoilStore";
import LeftInnerIconInput from "../components/LeftInnerIconInput";
import PrimaryButton from "../components/PrimaryButton";
import useUser from "../hooks/useUser";
import parseError from "./errorParser";

import PlainButton from "../components/PlainButton";
import usePageTitle from "../hooks/usePagetitle";

// For Beta
function SignupView() {
  usePageTitle("Invitation");
  const queryParameters = new URLSearchParams(window.location.search);
  const context = queryParameters.get("context");
  const [_, setUser] = useUser();
  const [signupUser] = useRecoilState(signupUserState);
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState("");
  const [err, setErr] = useState();
  const navigate = useNavigate();
  const refbtn = createRef<HTMLButtonElement>();

  const handleGoogleLogin = async () => {
    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/v2/oauth/google/token",
        { token: signupUser.token, access_from: document?.referrer },
        config,
      )
      .then((response) => {
        setUser(response.data);
        navigate("/", { replace: true });
      })
      .catch((error) => setErr(parseError(error)))
      .finally(() => setLoading(false));
  };

  const emailVerify = async (credentials: any) => {
    try {
      const req = {
        origin: window.location.origin,
        email: credentials.email,
        firstname: credentials.firstname,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v2/auth/send_email_verify",
        req,
        config,
      );
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };

  const verifyCode = async (user: any) => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `/api/v2/auth/invitation/beta?code=${code}&email=${user.email}` +
          (context === "google" && user.idp_google ? `&idp_google=${user.idp_google}` : ""),
        config,
      );
      // console.log("ok", response);
      if (context === "google") {
        // console.log("!!!!!!!!!", user);
        setUser(user);
        navigate("/onboarding", { replace: true });
      } else {
        setUser(response.data);
        emailVerify(user);
        navigate("/onboarding", { replace: true });
        // navigate("/pending", { replace: true });
      }
    } catch (error: any) {
      // console.log("error", error);
      if (error.response?.status === 404) {
        setErr(parseError("Invalid invitation code"));
      } else {
        setErr(parseError(error));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (signupUser?.email && signupUser.email !== "" && code !== "") {
      const obj = JSON.parse(JSON.stringify(signupUser));
      obj["code"] = code;
      await verifyCode(obj);
    } else {
      setErr(parseError("Invalid email or code"));
    }
  };
  const renderRight = () => {
    return (
      <div className="px-[49px] py-[37px]">
        <div className="flex h-fit w-full justify-end">
          <img alt="signup" src="/signup-image.svg" className=" h-[250px] w-fit" />
        </div>
        <div className="mt-[26px] grid place-items-center">
          <div className="min-h-[348px] w-full">
            <h4 className="font-poppins text-xl">
              Giboo transforms your philanthropic journey with AI-powered automation and unwavering
              support.
            </h4>
            <div className="mt-6 flex flex-col gap-3 ">
              <div className=" flex min-h-[72px] w-full gap-[10px] rounded border border-gray-200 bg-white px-[16px] py-[12px]">
                <img alt="icon1" src="/icon1.svg" className="h-[24px] w-[24px] " />
                <p className="font-poppins text-base text-gray-800">
                  Discover personalized funder-nonprofit matching driven by data analytics and
                  machine learning.
                </p>
              </div>
              <div className=" flex min-h-[72px] w-full gap-[10px] rounded border border-gray-200 bg-white px-[16px] py-[12px]">
                <img alt="icon1" src="/icon2.svg" className="h-[24px] w-[24px] " />
                <p className="font-poppins text-base text-gray-800">
                  Gain profound insights into funding patterns, finances, programs and contacts.
                </p>
              </div>
              <div className=" flex min-h-[72px] w-full gap-[10px] rounded border border-gray-200 bg-white px-[16px] py-[12px]">
                <img alt="icon1" src="/icon3.svg" className="h-[24px] w-[24px] " />
                <p className="font-poppins text-base text-gray-800">
                  Effortlessly craft compelling letters of inquiry and grant applications, backed by
                  data analytics for needs analysis and impact measurements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLeft = () => {
    return (
      <div className="w-full py-1">
        <h3>Welcome to Giboo</h3>
        <p className="gray-700 pt-8">Thank you for your interest in joining the Giboo service.</p>
        <p className="gray-700 pt-8">
          We are currently offering a closed beta service exclusively for invited nonprofit users.
          Our one-to-one approach allows us to work closely with our customers and provide the best
          possible experience.
        </p>
        <p className="gray-700 pt-8">
          We are here to support all amazing nonprofit organizations that want to raise funds. We
          have successfully received your registration information and will get back to you within
          one to two days! If you have invitation code, please enter here to start.
        </p>
        <p className="pt-8" style={{ color: "#9b9b9b" }}>
          Invitation code
        </p>
        <form className="flex flex-wrap gap-y-4 pt-2">
          <div className="flex w-full flex-col gap-x-3 md:flex-row">
            <div className="w-full">
              <LeftInnerIconInput
                id="input-invitation-code"
                placeholder="Enter invitation code"
                className="!h-[48px]"
                handleOnChange={(e) => setCode(e.target.value)}
                handleOnKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (refbtn.current?.disabled) {
                      e.preventDefault();
                    } else {
                      // console.log(refbtn.current);
                      refbtn.current?.focus();
                    }
                  }
                }}
                // invalid={err !== undefined}
              />
            </div>
          </div>
        </form>
        <div className="flex justify-start pt-1">
          <h5 className="text-purple-500">{err}</h5>
        </div>
        <div className="flex justify-end pt-5">
          <PlainButton
            id="btn-back"
            label="Back"
            leftIconClass="fa-solid fa-arrow-left"
            handleOnClick={() => navigate(-1)}
          />
          <PrimaryButton
            id="btn-next"
            size="lg"
            ref={refbtn}
            // tonal={true}
            disabled={!(code !== "") || loading}
            handleOnClick={handleSubmit}
            label="Enter"
            style={{ width: "33%" }}
            // rightIconClass="fa-solid fa-arrow-right"
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="container mx-auto ">
        <div className="gap-[12px]px-5 my-[48px] flex min-h-fit w-full flex-col justify-center md:flex-row md:gap-[113px] md:px-0">
          <div className="min-h-[525px] w-full md:w-[480px]">{renderLeft()}</div>
          <div className="gray-box !m-0 hidden min-h-[700px] w-full rounded-lg !p-0 md:block md:w-[606px]">
            {renderRight()}
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupView;
