import { Outlet, useNavigate, useParams, NavLink, useMatch, useLocation } from "react-router-dom";
import icon_detail from "../../../assets/project-mgt/file-write.svg";
import icon_detail_active from "../../../assets/project-mgt/file-write-active.svg";
import icon_dashboard from "../../../assets/project-mgt/project-dashboard.svg";
import icon_setting from "../../../assets/project-mgt/project-setting.svg";
import icon_public from "../../../assets/images/website.svg";
import icon_ai from "../../../assets/project-mgt/document-ai.svg";
import icon_ai_active from "../../../assets/project-mgt/document-ai-active.svg";
import icon_management from "../../../assets/project-mgt/project-management.svg";
import icon_management_active from "../../../assets/project-mgt/project-management-active.svg";
import icon_file from "../../../assets/project-mgt/file.svg";
import icon_file_active from "../../../assets/project-mgt/file-active.svg";
import classNames from "classnames";
import ProjectHeader from "./components/ProjectHeader";

import useTasks from "../../../hooks/project/useTasks";
import useTask from "../../../hooks/project/useTask";
import useFunderOrGrantName from "../../../hooks/project/useFunderOrGrantName";
import useProject from "../../../hooks/project/useProject";
import { Portal } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import ProjectInformationModal, {
  ProjectInfoType,
  getProjectInfoTypeName,
} from "../../../components/ProjectInformationModal";
import useOnboardingData from "../../../hooks/useOnboarding";
import ProjectCreationModal from "../../../components/ProjectCreationModal";
import ProjectInvite from "../../../components/ProjectInvite";
import { delay } from "lodash";
import GotoSmartSearchContext from "../../../components/useGotoSmartSearch";
import DivNavLink from "../../../components/DivNavLink";
import OutReachPickerModal from "../../../components/OutReachPickerModal";
import { IProject } from "../../../types/project";
import usePageTitle from "../../../hooks/usePagetitle";
import Spinner from "../../../components/Spinner";
import BackButton from "../../../components/BackButton";

function ProjectsLayout() {
  const { id } = useParams();
  const noProject = id === "u";
  const projectId = noProject ? undefined : id;

  const { data: tasks, isLoading } = useTasks(projectId);
  const { hasEditPermission, isValidating, data } = useProject(projectId);
  usePageTitle(`${data.name}`);
  const previousProject = useRef<IProject>();
  const [showProjectEdit, setShowProjectEdit] = useState<boolean>(false);
  const [showOutreach, setShowOutreach] = useState<boolean>(false);
  const showGuidance = useMatch("/project/:id/")?.pathname ? true : false;
  const isManagementPath = useMatch(
    process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE"
      ? "/project/:id"
      : "/project/:id/management",
  )?.pathname
    ? true
    : false;
  const isSubtaskPath = useMatch("/project/:id/task/:id")?.pathname ? true : false;
  const isDocumentAI = useMatch("/project/:id/document*")?.pathname ? true : false;
  const isDocumentList = useMatch("/project/:id/documents")?.pathname ? true : false;
  const hideHeader = useMatch("/project/:id/edit")?.pathname ? true : false;
  const hasTable = isSubtaskPath || isManagementPath;
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [onboardingData] = useOnboardingData();
  const [collapseNavbar, setCollapseNavbar] = useState<boolean>(false);
  const [showInvite, setShowInvite] = useState(false);
  const [currentType, setCurrentType] = useState<ProjectInfoType | undefined>();

  useEffect(() => {
    if (!data._id) {
      // console.log("ignore no id", { prev: previousProject.current?._id, cur: data._id });
      previousProject.current = data;
      return;
    }
    if (data._id === previousProject.current?._id) {
      // console.log("ignore same event", { prev: previousProject.current?._id, cur: data._id });
      previousProject.current = data;
      return;
    }
    previousProject.current = data;
    const mandatoryFields = [
      { type: "NAME", value: data.name },
      {
        type: "TIMELINE",
        value: data.project_timeline !== null && data.project_timeline !== undefined,
      },
      { type: "GRANT_AMOUNT", value: data.fund_to_raise > 0 },
      { type: "FOCUS_AREA", value: data.problem_desc },
      { type: "BENEFICIARY", value: data.beneficiary_desc },
      // { type: "SERVICE_AREA", value: data.service_area_desc },
      // { type: "BUDGETS", value: data.budget_desc },
    ];
    const requiredFields = mandatoryFields.map((i) => i.value);
    const currentType = mandatoryFields.find((i) => !i.value);
    setCurrentType(currentType?.type as ProjectInfoType);
    const isCompleted = requiredFields.every((i) => i);
    if (locationState?.showOutreach) {
      setShowOutreach(true);
    } else if (!isCompleted && !isDocumentAI && !isDocumentList) {
      setShowProjectEdit(true);
    } else if (locationState?.fromFundraising) {
      setShowOutreach(true);
    }
  }, [data]);

  useEffect(() => {
    // console.log({ isDocumentAI, isDocumentList });
    if (!projectId) {
      setCollapseNavbar(true);
    } else if (isDocumentAI && !isDocumentList) {
      setCollapseNavbar(true);
    } else if (!isDocumentAI && isDocumentList) {
      setCollapseNavbar(false);
    } else {
      setCollapseNavbar(false);
    }
  }, [projectId, isDocumentAI, isDocumentList]);
  const renderMyProjectItem = () => {
    return (
      <DivNavLink
        to={`/project/${projectId}/${
          process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE" ? "" : "management"
        }`}
      >
        {({ isActive }) => (
          <>
            {process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE" ? (
              <div className="flex flex-col gap-4">
                <div
                  id={`project-selector-bar`}
                  className={classNames(
                    " inline-flex h-full cursor-pointer items-start gap-4 rounded-l px-3 py-[10px] font-poppins text-sm text-inherit ",
                    isActive
                      ? " bg-purple-500  font-semibold text-white"
                      : "font-normal !text-gray-700 hover:bg-purple-50 ",
                  )}
                >
                  <span>
                    <img
                      src={isActive ? icon_management_active : icon_management}
                      className="h-5 w-5"
                    />
                  </span>
                  {!collapseNavbar && (
                    <h5
                      className={classNames(
                        "w-[172px] text-sm",
                        isActive ? "font-semibold" : "font-normal",
                      )}
                    >
                      Project management
                    </h5>
                  )}
                </div>
                {!collapseNavbar && (
                  <>
                    <div className={classNames("relative ml-[18px]")}>
                      <div className="flex w-full max-w-[211px] flex-col" role="none">
                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <div className="flex max-h-[450px] flex-col gap-3 overflow-y-scroll">
                            <div
                              className={classNames(
                                "flex flex-col gap-3",
                                tasks.length > 0 ? "block" : "hidden",
                              )}
                            >
                              {tasks.map((item: any, i) => (
                                <CustomNavLink
                                  key={"custom-link-" + i}
                                  project_id={projectId}
                                  task_id={item._id}
                                  index={i}
                                />
                              ))}
                            </div>
                            <div
                              className={classNames(
                                "w-full max-w-[211px]  cursor-pointer gap-4 rounded-l border-y border-l border-gray-300  p-[11px] font-poppins text-xs !font-normal ",
                                "!font-normal !text-gray-700 hover:bg-purple-50 ",
                              )}
                            >
                              <GotoSmartSearchContext type="virtual_grant" project_id={projectId}>
                                {({ go, isLoading }) => (
                                  <div
                                    className="grid grid-cols-[20px_1fr] place-items-start gap-1"
                                    onClick={(e) => {
                                      go({ fromProjectMgt: true });
                                      e.preventDefault();
                                    }}
                                  >
                                    {isLoading ? (
                                      <Spinner size="sm" />
                                    ) : (
                                      <>
                                        <div className="grid place-items-center">
                                          <i className="gi-md gi-add text-gray-700" />
                                        </div>
                                        <h6 className="font-poppins text-xs text-gray-700">
                                          add grants or funders to this project
                                        </h6>
                                      </>
                                    )}
                                  </div>
                                )}
                              </GotoSmartSearchContext>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="flex flex-col gap-1">
                <div
                  id={`project-selector-bar`}
                  className={classNames(
                    " inline-flex h-full cursor-pointer items-center gap-2 rounded px-3 py-[10px] font-poppins text-sm text-inherit hover:bg-gray-50 ",
                    isActive
                      ? " bg-gray-100  !font-semibold text-purple-500"
                      : "!font-normal !text-gray-900",
                  )}
                >
                  <span>
                    <img src={icon_management} className="h-5 w-5" />
                  </span>
                  <h5 className="w-[172px] text-sm">Manage project</h5>
                </div>
                <div
                  className={classNames(
                    " relative ml-10 ",
                    tasks.length > 0 && isActive ? "block" : "hidden",
                  )}
                >
                  <div className="flex  w-full max-w-[188px] flex-col" role="none">
                    <NavLink
                      to={`/project/${projectId}/management`}
                      className={({ isActive }) =>
                        classNames(
                          " cursor-pointer text-left font-poppins text-xs text-black hover:font-semibold",
                          isActive
                            ? " !font-semibold text-purple-500"
                            : "!font-normal !text-black ",
                        )
                      }
                    >
                      <h6>View all</h6>
                    </NavLink>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <h6 className="mt-3 select-none  text-left font-poppins text-xs text-gray-700">
                        Recent funders
                      </h6>
                      <div className="my-2 h-[1px] w-full border-b border-gray-300" />
                      <div className="flex flex-col gap-3">
                        {tasks.slice(0, 4).map((item: any, i) => (
                          <CustomNavLink
                            key={"custom-link-" + i}
                            project_id={projectId}
                            task_id={item._id}
                            index={i}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </DivNavLink>
    );
  };
  const renderMyDocumentAi = () => {
    return (
      <div
        onClick={(e) => {
          if (isDocumentAI || isDocumentList) {
            e.stopPropagation();
            e.preventDefault();
            setCollapseNavbar((prev) => !prev);
          }
        }}
      >
        <DivNavLink
          to={`/project/${projectId}/document`}
          pattern={`/project/${projectId}/document*`}
        >
          {({ isActive }) => (
            <div className="flex flex-col gap-4">
              <div
                id={`project-selector-bar`}
                className={classNames(
                  " inline-flex h-full cursor-pointer items-start gap-4 rounded-l px-3 py-[10px] font-poppins text-sm text-inherit ",
                  isActive
                    ? " bg-purple-500  !font-semibold !text-white"
                    : "!font-normal !text-gray-700 hover:bg-purple-50 ",
                )}
              >
                <span>
                  <img src={isActive ? icon_ai_active : icon_ai} className="h-5 w-5" />
                </span>
                {!collapseNavbar && (
                  <h5
                    className={classNames(
                      "w-[172px] text-sm",
                      isActive ? "font-semibold" : "font-normal",
                    )}
                  >
                    Document AI
                  </h5>
                )}
              </div>
              {!collapseNavbar && (
                <>
                  <div className={classNames(" relative ml-[18px]")}>
                    <div className="flex w-full max-w-[211px] flex-col" role="none">
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <div className="flex flex-col gap-3">
                          <NavLink to={`/project/${projectId}/documents`} caseSensitive>
                            {({ isActive }) => (
                              <div className="flex w-[211px] flex-col gap-1">
                                <div
                                  id={`project-selector-bar`}
                                  className={classNames(
                                    "flex  w-full cursor-pointer gap-1 rounded-l border-y border-l border-gray-300  px-3 py-2 font-poppins text-xs !font-normal ",
                                    isActive
                                      ? " bg-purple-500  !font-semibold text-white"
                                      : "!font-normal !text-gray-700 hover:bg-purple-50 ",
                                  )}
                                >
                                  <span>
                                    <img
                                      src={isActive ? icon_file_active : icon_file}
                                      className="h-[14px] w-[14px]"
                                    />
                                  </span>
                                  {!collapseNavbar && (
                                    <h5 className={classNames("w-[172px] text-xs")}>
                                      View all documents
                                    </h5>
                                  )}
                                </div>
                              </div>
                            )}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </DivNavLink>
      </div>
    );
  };
  const renderLeftNavigationBar = () => {
    return (
      <div
        className={classNames(
          "flex h-full  max-w-[238px] flex-col justify-between gap-6",
          collapseNavbar && "w-[52px]",
        )}
      >
        {projectId && (
          <div className="flex flex-col gap-6 pl-2">
            {renderMyProjectItem()}
            <NavLink to={`/project/${projectId}/detail`} caseSensitive>
              {({ isActive }) => (
                <div className="flex flex-col gap-1">
                  <div
                    id={`project-selector-bar`}
                    className={classNames(
                      "inline-flex h-full cursor-pointer items-start gap-4 rounded-l px-3 py-[10px] font-poppins text-sm text-inherit",
                      isActive
                        ? " bg-purple-500  !font-semibold !text-white"
                        : "!font-normal !text-gray-700 hover:bg-purple-50 ",
                    )}
                  >
                    <span>
                      <img src={isActive ? icon_detail_active : icon_detail} className="h-5 w-5" />
                    </span>
                    {!collapseNavbar && (
                      <h5
                        className={classNames(
                          "w-[172px] text-sm",
                          isActive ? "font-semibold" : "font-normal",
                        )}
                      >
                        Project information
                      </h5>
                    )}
                  </div>
                </div>
              )}
            </NavLink>
            {renderMyDocumentAi()}
          </div>
        )}
        <div
          className={classNames(
            "mb-7 grid cursor-pointer place-items-center !font-normal !text-gray-900 hover:bg-purple-50 ",
          )}
          onClick={() => {
            setCollapseNavbar((prev) => !prev);
          }}
        >
          <span>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.1667 13.834H23.8333" stroke="#5F5F5F" strokeLinecap="round" />
              <path d="M12.1667 18H23.8333" stroke="#5F5F5F" strokeLinecap="round" />
              <path d="M12.1667 22.166H23.8333" stroke="#5F5F5F" strokeLinecap="round" />
            </svg>
          </span>
        </div>
        {/* {renderLink("Manage settings", `/project/${projectId}/setting`, icon_setting)} */}
      </div>
    );
  };

  const renderLeftContainer = () => {
    const renderLink = (label: string, url: string, icon: string, className?: string) => {
      return (
        <NavLink
          to={url}
          className={({ isActive }) =>
            classNames(
              " inline-flex h-[41px] cursor-pointer items-center gap-2 rounded px-3 py-[10px] font-poppins text-xs hover:bg-gray-50 ",
              isActive
                ? " bg-gray-100  !font-semibold text-purple-500"
                : "!font-normal !text-gray-900",
              className,
            )
          }
        >
          <span className="h-5 w-5">
            <img src={icon} className="h-5 w-5 object-scale-down" />
          </span>
          <h5 className="w-[172px] text-sm">{label}</h5>
        </NavLink>
      );
    };
    return (
      <div className="flex flex-col gap-6">
        {renderLink("View dashboard", `/project/${projectId}/`, icon_dashboard)}
        {renderMyProjectItem()}
        <div
          className={classNames(
            " inline-flex h-[41px] cursor-pointer items-center gap-2 rounded px-3 py-[10px] font-poppins text-xs hover:bg-gray-50 ",
            showProjectEdit
              ? " bg-gray-100  !font-semibold text-purple-500"
              : "!font-normal !text-gray-900",
            !hasEditPermission && "!cursor-not-allowed",
          )}
          onClick={() => {
            hasEditPermission && setShowProjectEdit((prev) => !prev);
          }}
        >
          <span className="h-5 w-5">
            <img src={icon_detail} className="h-5 w-5 object-scale-down" />
          </span>
          <h5 className="w-[172px] text-sm">Edit project information</h5>
        </div>
        {renderLink("Edit project public view", `/project/${projectId}/detail`, icon_public)}
        {renderLink("Manage settings", `/project/${projectId}/setting`, icon_setting)}
      </div>
    );
  };
  return (
    <>
      {showInvite && (
        <ProjectInvite
          from_for_mixpanel="project_dashboard"
          projectId={data?._id || ""}
          handleClose={() => {
            setShowInvite(false);
          }}
          showInvite={showInvite}
        />
      )}
      {showProjectEdit && (
        <ProjectInformationModal
          from_for_mixpanel="project_dashboard"
          open={showProjectEdit}
          setOpen={setShowProjectEdit}
          id={data._id}
          fromFundraising={locationState?.fromFundraising}
          target_id={locationState?.target_id}
          target_type={locationState?.target_type}
          currentType={currentType}
        />
      )}
      {showOutreach && (
        <OutReachPickerModal
          open={showOutreach}
          setOpen={setShowOutreach}
          onSelect={(data) => {
            navigate(`/project/${projectId}/document?type=${data.typeEnum}`, {
              state: {
                target: locationState?.target_id
                  ? `${locationState?.target_type}_${locationState?.target_id}`
                  : undefined,
              },
            });
            setShowOutreach(false);
          }}
        />
      )}
      {/* {!noProject && !hasEditPermission && !isValidating && !isLoading && data._id && (
        <Portal>
          <div className="absolute top-[65px] z-[10] flex h-[53px] w-full items-center justify-around bg-purple-500">
            <div className="font-poppins text-base text-white">
              You are viewing the project as a viewer.
            </div>
          </div>
        </Portal>
      )} */}
      <div
        className={classNames(
          process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE"
            ? "full-screen-container grid w-full grid-cols-[auto_1fr]"
            : "full-screen-container mx-auto flex w-full gap-x-4 gap-y-8",
          (!projectId || isDocumentAI || isDocumentList) &&
            process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE"
            ? "nav-collapse"
            : "",
          // !noProject && !hasEditPermission && !isValidating && !isLoading && data._id
          //   ? "mt-[53px]"
          //   : "",
          "",
        )}
      >
        <aside
          className={classNames(
            "sticky top-0 w-full max-w-[238px] border-l border-gray-300 bg-white pt-[30px] shadow-[0_0px_15px_1px_rgba(0,0,0,0.1)]",
            process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE" ? "" : "px-2",
          )}
          style={{ height: `calc(100vh - 60px)` }}
        >
          {process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE" ? (
            <>{renderLeftNavigationBar()}</>
          ) : (
            <>{renderLeftContainer()}</>
          )}
        </aside>
        <main
          className={classNames(
            process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE"
              ? "flex w-full flex-col items-start"
              : "w-full overflow-y-auto",
            process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE"
              ? ""
              : hasTable
              ? "ml-[48px] w-full"
              : "mx-auto ml-[48px] max-w-[1198px]",
          )}
        >
          <div
            className={classNames(
              "w-full border-b border-gray-300 bg-white shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]",
              process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE"
                ? " px-[30px] py-3"
                : " self-center px-[30px] py-3",
            )}
            style={{
              maxWidth: `calc(100vw - ${collapseNavbar ? 55 : 240}px)`,
            }}
          >
            {!projectId ? (
              <div className="inline-flex h-[30px] w-full items-center gap-2">
                <div className="w-fit">
                  <BackButton />
                </div>
                <h4 className="font-poppins text-base ">Untitled project</h4>
              </div>
            ) : (
              <>{!hideHeader && <ProjectHeader wrapperClass={hasTable ? "" : ""} />}</>
            )}
          </div>
          <div
            className={classNames(
              "flex w-full flex-col gap-4 overflow-x-scroll overflow-y-scroll",
              "grow pl-7 pt-7",
            )}
            style={{
              maxHeight: `calc(100vh - 114px)`,
              maxWidth: `calc(100vw - ${collapseNavbar ? 54 : 160}px)`,
            }}
          >
            <Outlet />
          </div>
        </main>
      </div>
    </>
  );
}
export default ProjectsLayout;
interface INavlink {
  project_id: string | undefined;
  task_id: string | undefined;
  index: number;
}
const DOT_COLORS = ["#3D6FF2", "#6DC985", "#DC52BA", "#F2EB3D", "#F27E3D", "#9B0082"];
const CustomNavLink = ({ project_id, task_id, index }: INavlink) => {
  const { data, isLoading } = useTask(task_id);
  const { data: name, isLoading: isNameLoading } = useFunderOrGrantName(
    data.target_id,
    data.target_type,
  );
  if (!project_id) return <></>;
  return (
    <NavLink
      to={`/project/${project_id}/task/${task_id}?type=${data.target_type}`}
      title={name}
      className={({ isActive }) =>
        classNames(
          "flex w-full cursor-pointer gap-2 rounded-l border-y border-l border-gray-300  px-3 py-2 font-poppins text-xs !font-normal ",
          isActive
            ? "bg-purple-500  !text-white"
            : "!font-normal !text-gray-900 hover:bg-purple-50 ",
          {
            "loading-light-shimmer": !isNameLoading || isLoading,
            "loading-light-shimmer-on -mt-[2px]": isNameLoading || isLoading,
          },
        )
      }
    >
      {(!isNameLoading || !isLoading) && (
        <div className="mt-[2px] grid h-3 w-3 place-items-center">
          <div
            className="h-2 w-2 rounded-full "
            style={{ background: DOT_COLORS[index % DOT_COLORS.length] }}
          />
        </div>
      )}
      {isNameLoading || isLoading ? (
        <div className="loading-light-shimmer-child">
          <div className="h-full w-full" />
        </div>
      ) : (
        name
      )}
    </NavLink>
  );
};
