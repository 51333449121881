import classNames from "classnames";

export default function TagGrantEligible({ eligible }: { eligible: number }) {
  const tagColorVariant: { [key: number]: string } = {
    2: "border-green-500 bg-green-50",
    1: "border-yellow-500 bg-yellow-50",
  };
  const iconColorVariant: { [key: number]: string } = { 2: "bg-green-500", 1: "bg-yellow-500" };
  return (
    <>
      {eligible > 0 ? (
        <div
          className={classNames(
            "flex w-fit items-center gap-2 rounded border px-2 py-[2px]",
            tagColorVariant[eligible],
          )}
        >
          <div
            className={classNames(
              "flex h-3 w-3 items-center justify-center rounded-full",
              iconColorVariant[eligible],
            )}
          >
            <i
              className={classNames(
                eligible === 2 ? "gi-check" : "fa-solid fa-minus",
                "text-[8px] text-white",
              )}
            />
          </div>
          <h6 className="text-gray-900">
            {eligible > 1
              ? "Your organization is eligible"
              : "Your organization is partially eligible"}
          </h6>
        </div>
      ) : null}
    </>
  );
}
