import React, { ReactNode, memo, useCallback, useEffect, useState } from "react";
import {
  prettyListStrings,
  toFixedWithMinimum,
  toUsdLong,
  toUsdShort,
} from "../../utils/formatHelper";
import PlainButton from "../PlainButton";
import { PhilanthropicFocusArea, Philanthropy } from "../../types/philanthropy";
import classNames from "classnames";
import GibooTooltipWrapper from "../GibooTooltipWrapper";
import { compareAmount } from "../../utils/philanthropy";
import Tag from "../Tag";
import { useRecoilState } from "recoil";
import { matchingSummaryState } from "../../app/recoilStore";
import { scrollBarClass } from "../../utils/classes";
import no_info from "../../pages/img/no-info2.svg";
import NoInformation from "../../pages/donor-detail-view/components/NoInformation";
import useOnboardingData from "../../hooks/useOnboarding";
import { useNavigate } from "react-router-dom";
import {
  FunderSearchResult,
  SearchQuery,
  getURLSearchParamsFromSearchQuery,
} from "../../types/search";
import { IFunder } from "../../types/funder";
import { getPastGrantSearchURL } from "../../utils/search";
import { getSearchQueryFromOnboardingData } from "../../app/onboardingSlice";
import { ClickToScrollWrapper } from "../ClickToScrollWrapper";
import Button from "../tailwind/Button";
import TagMatched from "../TagMatched";

interface props {
  philanthropy?: Philanthropy;
  selectedTab: number;
  funder?: FunderSearchResult;
  compact?: boolean;
  query: SearchQuery;
  tagMatched?: boolean;
}
interface HbarProps extends PhilanthropicFocusArea {
  max?: number;
  id?: string;
  field: "focus_area" | "beneficiary" | "program";
}
interface HbarPropsWithContext extends HbarProps {
  funder?: FunderSearchResult;
  query: SearchQuery;
  tagClass?: string;
  tagMatched?: boolean;
}
function hBar({ funder, tagClass, tagMatched = false, ...props }: HbarPropsWithContext) {
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const colorVariant = {
    focus_area: (matched = false) =>
      `bg-yellow-200 ${matched ? "border-yellow-500" : "border-yellow-500"}`,
    beneficiary: (matched = false) =>
      `bg-blue-200 ${matched ? "border-blue-500" : "border-blue-500"}`,
    program: (matched = false) =>
      `bg-green-200 ${matched ? "border-green-500" : "border-green-500"}`,
  };
  const width = (props.amount || 0) / (props.max || 1);
  return (
    <div
      key={props.label}
      className="grid items-center md:grid-cols-5 "
      id={`tooltip-bar-${props.id}`}
    >
      <div
        className="col-span-2 flex justify-end border-r border-dashed border-gray-300 pr-4"
        title={props.label}
      >
        <Tag
          className={classNames("my-1 mt-2 w-fit", tagClass)}
          id={`tag-${props.id}`}
          color={
            props.field === "focus_area"
              ? "yellow"
              : props.field === "beneficiary"
              ? "blue"
              : props.field === "program"
              ? "green"
              : "gray"
          }
          name={props.label}
          matched={props.matched || tagMatched}
          textTruncate
          size="sm"
          {...(funder?._id
            ? {
                onClick: () => {
                  navigate(
                    getPastGrantSearchURL(
                      funder?._id,
                      props.label,
                      props.field === "focus_area"
                        ? "f"
                        : props.field === "beneficiary"
                        ? "b"
                        : props.field === "program"
                        ? "p"
                        : "",
                      onboardingData,
                      props.query,
                    ),
                  );
                },
                tooltip: (
                  <div id="tooltip">
                    Click to view past grants associated with “<b>{props.label}</b>“
                  </div>
                ),
              }
            : {})}
        />
      </div>
      <div className={classNames("col-span-3 flex items-center justify-start gap-3")}>
        <div
          className={classNames(
            "h-5 rounded-e",
            props.matched || tagMatched
              ? colorVariant[props.field]?.(props.matched || tagMatched)
              : "bg-gray-200",
            {
              // "border-gray-500": props.matched,
            },
          )}
          style={{ minWidth: `calc(max(${width * 100}% - 62px, 4px))` }}
        />
        {/* <GibooTooltipWrapper
          id={`tooltip-bar-${props.id}`}
          content={
            <div className="min-w-[140px] max-w-[180px]">
              <h5 className="font-poppins text-sm font-semibold">{props.label}</h5>
              <h5 className="mt-1 font-poppins text-sm">Amount: {toUsdLong(props.amount || 0)}</h5>
              <h5 className="mt-1 font-poppins text-sm">
                Count: {`${(props.count || 0).toLocaleString()} grants`}
              </h5>
            </div>
          }
        /> */}
        <h5
          style={{ minWidth: "50px" }}
          className={classNames(
            "font-poppins text-sm font-normal",
            props.matched || tagMatched ? "text-gray-500 " : "text-gray-500",
          )}
        >
          {toUsdShort(props.amount || 0, 1)}
        </h5>
      </div>
    </div>
  );
}
const HBar = memo(hBar);
interface FundedBeneficiary {
  beneficiary: HbarProps;
  focus_area: HbarProps;
  program: HbarProps;
}

function PhilanFocusHBar({ selectedTab, compact, tagMatched = false, ...props }: props) {
  const [matchingDescription, setMatchingDescription] = useRecoilState<{
    [key: string]: ReactNode;
  }>(matchingSummaryState);
  const setDataFunc = () => {
    const prev: HbarProps[][] = [
      props.philanthropy?.focus_area
        ?.sort((a, b) => compareAmount(a, b, true))
        .map((item) => ({ ...item, field: "focus_area" })) || [],
      props.philanthropy?.beneficiary
        ?.sort((a, b) => compareAmount(a, b, true))
        .map((item) => ({ ...item, field: "beneficiary" })) || [],
      props.philanthropy?.program
        ?.sort((a, b) => compareAmount(a, b, true))
        .map((item) => ({ ...item, field: "program" })) || [],
    ];
    const d = [
      ...prev,
      [...prev[0], ...prev[1], ...prev[2]]
        .filter((i) => i.matched)
        ?.sort((a, b) => compareAmount(a, b, true)) || [],
    ];
    return d;
  };
  const setMaxsFunc = (d: HbarProps[][]) => {
    const m: number[] = d.map((arr) =>
      arr.reduce((prev, cur) => Math.max(prev, cur.amount || 0), 0),
    );
    return m;
  };
  const [data, setData] = useState<HbarProps[][]>(setDataFunc());
  const [maxs, setMaxs] = useState<number[]>(setMaxsFunc(data));
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState<HbarProps[]>(data[selectedTab]);
  const [selectedMax, setSelectedMax] = useState<number>(maxs[selectedTab]);
  const [showMore, setShowMore] = useState<number>(20);

  const hasNoTaxonomy =
    props.query.focus_area.length === 0 &&
    props.query.beneficiary.length === 0 &&
    props.query.program.length === 0 &&
    props.query.hq_loc.length === 0 &&
    props.query.service_loc.length === 0;
  useEffect(() => {
    const d = setDataFunc();
    const m: number[] = setMaxsFunc(d);
    setData(d);
    setMaxs(m);
    setSelectedData(d[selectedTab]);
    setSelectedMax(m[selectedTab]);
  }, [props.philanthropy, selectedTab, setData, setSelectedData, setSelectedMax]);

  const totalMatchedFundedAmounts = [0, 1, 2].map((i) =>
    data.length > 3
      ? data[i].filter((item) => item.matched).reduce((p, c) => p + (c.amount || 0), 0)
      : 1,
  );
  const totalFundedAmounts = [0, 1, 2].map((i) =>
    data.length > 3 ? data[i].reduce((p, c) => p + (c.amount || 0), 0) : 1,
  );
  const topMatchedLabels = [0, 1, 2].map((k) =>
    [0, 1, 2].map((i) =>
      data.length > 3 && data[k][i] && data[k][i].matched ? data[k][i]?.label : "",
    ),
  );
  const topMatchedAmounts = [0, 1, 2].map((k) =>
    [0, 1, 2].map((i) =>
      data.length > 3 && data[k][i] && data[k][i].matched ? data[k][i]?.amount || 0 : 0,
    ),
  );

  const desc = ["focus area", "beneficiary", "program"].map((key, i) =>
    topMatchedLabels[i][0] ? (
      <h5 key={key} className="font-poppins text-gray-700">
        This funder has funded{" "}
        <b className="text-purple-500">
          {toUsdShort(
            topMatchedAmounts[i].reduce((p, c) => p + c, 0),
            1,
          )}
        </b>{" "}
        toward causes that align with your organization&apos;s {key}, including{" "}
        <b className="text-purple-500">{prettyListStrings(topMatchedLabels[i].filter((i) => i))}</b>
        as highlighted in tags below.{" "}
        <b className="font-poppins text-sm text-gray-900">
          You may click the tags to see grant information.
        </b>
      </h5>
    ) : (
      topMatchedLabels[i][0]
    ),
  );
  useEffect(() => {
    setMatchingDescription((prev) => ({
      ...prev,
      focus_area: desc[0],
      beneficiary: desc[1],
      program: desc[2],
    }));
  }, [data]);
  const renderHeader = () => {
    return (
      <div className="my-4 rounded bg-gray-100 px-4 py-[10px]">
        {selectedTab === 3 ? (
          topMatchedLabels.map((arr) => arr[0]).filter((i) => i).length > 0 ? (
            <h5 className="font-poppins text-gray-700">
              This funder has funded{" "}
              <b className="text-purple-500">
                {toUsdShort(
                  totalFundedAmounts.reduce((p, c) => p + c, 0),
                  1,
                )}
              </b>{" "}
              toward causes that align with your organization&apos;s philanthropic focus including{" "}
              <b className="text-purple-500">
                {prettyListStrings(topMatchedLabels.map((arr) => arr[0]).filter((i) => i))}
              </b>
              as highlighted in tags below.{" "}
              <b className="font-poppins text-sm text-gray-900">
                You may click the tags to see grant information.
              </b>
            </h5>
          ) : null
        ) : (
          [
            matchingDescription.focus_area,
            matchingDescription.beneficiary,
            matchingDescription.program,
          ][selectedTab]
        )}
      </div>
    );
  };
  const renderRightContent = () => {
    const pcts = [0, 1, 2].map((i) => {
      const temp = !totalFundedAmounts[i]
        ? 0
        : ((totalMatchedFundedAmounts[i] || 0) / totalFundedAmounts[i]) * 100;
      return toFixedWithMinimum(temp);
    });
    const colorVariant = ["text-yellow-700", "text-blue-700", "text-green-700"];
    const nameVariant = ["Focus area", "Beneficiary", "Program"];
    return (
      <>
        <p key={-1} className="font-semibold">
          Your match analysis
        </p>
        <div className={classNames("mt-4 flex flex-wrap gap-7")}>
          {selectedTab === 3 &&
            ["focus area", "beneficiary", "program"].map((key, i) =>
              topMatchedLabels[i][0] ? (
                <div
                  key={`cat-${i}`}
                  className={classNames("font-poppings mt-4 flex flex-col gap-[4px]")}
                >
                  <h5 className="text-sm text-gray-700">{nameVariant[i]}</h5>
                  <h3 className="text-[28px]">{`${pcts[i]}%`}</h3>
                  <h6 className="text-xs text-gray-900">
                    funding toward <span className={colorVariant[i]}>{key}</span>
                  </h6>
                </div>
              ) : null,
            )}
          {selectedTab >= 0 && selectedTab < 3 && (
            <>
              {topMatchedLabels[selectedTab][0] ? (
                [0, 1, 2].map((i) => {
                  const temp =
                    ((topMatchedAmounts[selectedTab][i] || 0) / totalFundedAmounts[selectedTab]) *
                    100;
                  const percentage = toFixedWithMinimum(temp);
                  // console.log({ temp, percentage });
                  return topMatchedLabels[selectedTab][i] ? (
                    <div
                      key={`top-${i}`}
                      className={classNames("font-poppings mt-4 flex flex-col gap-[4px]")}
                    >
                      <h5 className=" text-sm text-gray-700">{`Top ${i + 1}`}</h5>
                      <h3 className="text-[28px]">{`${
                        !totalFundedAmounts[selectedTab]
                          ? 0
                          : Number.isInteger(percentage)
                          ? 0
                          : percentage
                      }%`}</h3>
                      <h6 className="text-xs text-gray-900">
                        funding toward{" "}
                        <span className={colorVariant[selectedTab]}>
                          {topMatchedLabels[selectedTab][i]}
                        </span>
                      </h6>
                    </div>
                  ) : null;
                })
              ) : (
                <>
                  <h5 className="mt-3">No match</h5>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  };
  const renderLeftContent = useCallback(() => {
    const matchedData = selectedData?.filter((i) => i.matched) || [];
    const otherData = selectedData?.filter((i) => !i.matched) || [];
    const hasMatchedData = matchedData.length > 0;
    return (
      <div className="">
        <div className="info-philanthropy flex flex-col gap-0 px-4 pt-1">
          {hasMatchedData && (
            <div className="grid items-center border-t  border-dashed border-gray-300 md:grid-cols-5">
              <div className="col-span-2 flex h-6 items-center justify-end border-r border-dashed border-gray-300 pr-4 text-xs text-gray-700">
                Matched
              </div>
            </div>
          )}
          <div className="pb-1">
            {matchedData.map((v, i) => (
              <HBar
                funder={props.funder}
                query={props.query}
                key={i}
                id={`Bar-matched-${selectedTab}-${i}`}
                tagClass={i === 0 ? "tutor-donor-detail-4" : ""}
                {...v}
                max={selectedMax}
                tagMatched={tagMatched}
              />
            ))}
          </div>
          {process.env.REACT_APP_DISABLE_TAXONOMY_GRAPH_UNMATCHED === "TRUE" ? null : (
            <>
              {!hasNoTaxonomy && selectedTab !== 3 && otherData.length > 0 && (
                <div className="brt grid items-center  border-t border-dashed border-gray-300 md:grid-cols-5">
                  <div className="col-span-2 flex h-6 items-center justify-end border-r border-dashed border-gray-300 pr-4 text-xs text-gray-700 ">
                    Others
                  </div>
                </div>
              )}
              {otherData.slice(0, showMore).map((v, i) => (
                <HBar
                  funder={props.funder}
                  query={props.query}
                  key={i}
                  id={`Bar-not-matched-${selectedTab}-${i}`}
                  {...v}
                  max={selectedMax}
                  tagMatched={tagMatched}
                />
              ))}
              {otherData.length > showMore && (
                <div key={-1} className="py-2">
                  <Button
                    size="sm"
                    outline
                    className="w-full"
                    label="Show more"
                    id="btn-show-more"
                    handleOnClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setShowMore((prev) => prev + 10);
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }, [selectedData, selectedMax, showMore]);
  const hasMatched = selectedData && selectedData.filter((i) => i.matched).length > 0;
  return (
    <>
      <div className="flex w-full flex-col ">
        {selectedData && selectedData.length === 0 ? (
          <NoInformation
            img={no_info}
            content={
              <div className="-mt-2 flex flex-col items-center">
                {/* <h5 className="text-sm text-gray-600">
                  You can{" "}
                  <span
                    className="cursor-pointer underline"
                    onClick={() => navigate("/organization")}
                  >
                    click to add more tags
                  </span>{" "}
                  to get better matches!
                </h5> */}
              </div>
            }
          />
        ) : (
          <>
            {/* {hasMatched ? (
              renderHeader()
            ) : (
              <div className="my-4 rounded bg-gray-100 px-4 py-[10px]">
                <h5 className="font-poppins text-sm text-gray-700">
                  This funder does not have any matches with you.{" "}
                  <b className="font-poppins text-sm  text-gray-900">
                    You may click the tags to see grant information.
                  </b>
                </h5>
              </div>
            )} */}
            <div className={classNames("flex flex-col gap-3")}>
              <ClickToScrollWrapper>
                <div
                  className={classNames(
                    "h-[350px] min-w-[300px] grow",
                    scrollBarClass,
                    compact ? "w-[710px]" : "w-[740px]",
                  )}
                >
                  {renderLeftContent()}
                </div>
              </ClickToScrollWrapper>
              {!hasNoTaxonomy && (
                <div className={classNames("mt-[40px] flex h-full min-w-[200px] grow-0 flex-col")}>
                  {renderRightContent()}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

const MemoizedPhilanFocusHBar = memo(PhilanFocusHBar);
export default MemoizedPhilanFocusHBar;
