import classNames from "classnames";
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
export type DiversityFilter = "all" | "board" | "volunteer" | "employee";
const labelList: Record<DiversityFilter, string> = {
  all: "Organization overview",
  board: "Board member",
  employee: "Employee",
  volunteer: "Volunteer",
};
const TAB_ORDER: DiversityFilter[] = ["all", "board", "employee", "volunteer"];
export default function DiversityFilterWrapper({
  children,
  selectedTab,
  setSelectedTab,
  wrapperClass,
  hideTop = false,
  hideBottom = false,
  hideArrow = false,
  excludeTabs = [],
}: {
  children?: ReactNode;
  selectedTab: DiversityFilter;
  setSelectedTab: Dispatch<SetStateAction<DiversityFilter>>;
  wrapperClass?: string;
  hideTop?: boolean;
  hideBottom?: boolean;
  hideArrow?: boolean;
  excludeTabs?: DiversityFilter[];
}) {
  const [tabOrder, setTabOrder] = useState<DiversityFilter[]>(["all"]);
  const [showPrevButton, setShowPrevButton] = useState(true);
  const [showNextButton, setShowNextButton] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    if (excludeTabs.length > 0) {
      setTabOrder(TAB_ORDER.filter((i) => !excludeTabs.includes(i)));
    } else {
      setTabOrder(TAB_ORDER);
    }
  }, [excludeTabs]);
  useEffect(() => {
    setShowPrevButton(currentIndex > 0);
    setShowNextButton(currentIndex < tabOrder.length - 1);
  }, [currentIndex, tabOrder]);

  const goToPrev = () => {
    const index = currentIndex > 0 ? currentIndex - 1 : 0;
    setCurrentIndex(index);
    setSelectedTab(tabOrder[index]);
    setShowPrevButton(true);
  };
  const goToNext = () => {
    const index = currentIndex <= tabOrder.length ? currentIndex + 1 : 0;
    setCurrentIndex(index);
    setShowNextButton(true);
    setSelectedTab(tabOrder[index]);
  };

  const renderTopNav = () => {
    return (
      <div className="mx-auto mb-5">
        <div className="inline-flex w-fit items-center gap-5 border-b border-gray-200">
          {tabOrder.map((item, index) => {
            return (
              <div
                key={item}
                className={classNames(
                  " cursor-pointer font-poppins text-sm  text-gray-700  underline-offset-[5px]",
                  selectedTab === item && "font-semibold text-purple-500 underline",
                )}
                onClick={() => {
                  setCurrentIndex(index);
                  setSelectedTab(tabOrder[index]);
                }}
              >
                {labelList[item as DiversityFilter]}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderBottomNav = () => {
    if (tabOrder.length < 2) return <></>;
    return (
      <div className={classNames("mt-5 inline-flex w-full flex-wrap justify-center gap-[9px]")}>
        {tabOrder.map((item) => (
          <span
            key={item}
            className={classNames(
              "h-2 w-2 cursor-pointer rounded-full",
              item === selectedTab ? " bg-purple-500 " : "bg-gray-200",
            )}
            onClick={() => setSelectedTab(item as any)}
          />
        ))}
      </div>
    );
  };
  return (
    <div className={classNames("relative flex w-full flex-col items-center", wrapperClass)}>
      {!hideTop && renderTopNav()}
      {children}
      {!hideBottom && renderBottomNav()}
      {showPrevButton && !hideArrow && (
        <div
          className={classNames(
            "absolute left-0 top-[44%]  flex h-[90%] w-fit -translate-y-1/2 transform items-center  transition duration-300 ease-in-out",
          )}
          onClick={goToPrev}
        >
          <div className="z-10 grid h-9 w-9 place-items-center rounded-full border border-gray-100 bg-white text-gray-800 opacity-100 shadow-md hover:bg-gray-100">
            <i className="fas fa-chevron-left text-sm"></i>
          </div>
        </div>
      )}
      {showNextButton && !hideArrow && (
        <div
          className={classNames(
            "absolute right-0 top-[44%] flex h-[90%] w-fit -translate-y-1/2 transform items-center  transition duration-300 ease-in-out",
          )}
          onClick={goToNext}
        >
          <div className="z-10 grid  h-9 w-9 place-items-center rounded-full border border-gray-100 bg-white text-gray-800 opacity-100 shadow-md hover:bg-gray-100">
            <i className="fas fa-chevron-right text-sm"></i>
          </div>
        </div>
      )}
    </div>
  );
}
