import { RefObject, createRef, useEffect, useRef, useState } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import classNames from "classnames";
export function ClickToScrollWrapper({
  children,
  disabled = false,
}: {
  // children?: (props: { toggle: () => void; allowScroll: boolean }) => React.ReactNode;
  children?: React.ReactNode;
  disabled?: boolean;
}) {
  const [allowScroll, setAllowScroll] = useState<boolean>(false);
  const [showTip, setShowTip] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  if (process.env.REACT_APP_DISABLE_HOVER_TO_SCROLL === "TRUE") {
    //
  } else {
    useOnClickOutside(ref, () => {
      if (allowScroll) {
        setAllowScroll(false);
      }
      setShowTip(false);
    });
    const handleMouseEnter = () => {
      // console.log("entering.....");
      if (disabled) return;
      if (!allowScroll) {
        timeoutRef.current = setTimeout(() => {
          setShowTip(true);
        }, 2000);
      }
    };
    const handleMouseLeave = () => {
      // console.log("leaving.....");
      if (disabled) return;
      setShowTip(false);
      setAllowScroll(false);
      clearTimeout(timeoutRef.current!);
    };
    useMouseEnterLeave(ref, handleMouseEnter, handleMouseLeave);
  }
  return (
    <div
      ref={ref}
      className="relative"
      id={'GA_TAG_click_clicktoscroll'}
      {...(process.env.REACT_APP_DISABLE_HOVER_TO_SCROLL === "TRUE"
        ? {}
        : {
            onClick: (evt) => {
              if (disabled) return;
              setAllowScroll(true);
            },
          })}
    >
      <div
        className={classNames(
          "absolute right-0 top-0 !z-[4] inline-flex h-[20px] items-center gap-2 rounded bg-gray-600  px-2  py-[2px]",
          { block: showTip && !allowScroll, hidden: !showTip || allowScroll },
        )}
      >
        <div className="-mt-[2px]">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2341_56462)">
              <path
                d="M8.02537 8.28089L10.9979 11.2526M0.730225 3.60946L2.16423 3.99432M1.3388 7.05946L2.3888 6.01032M3.2468 1.09375L3.6308 2.52689M6.6968 1.70232L5.6468 2.75146M6.2228 11.2466L4.10994 4.91918C4.0846 4.8436 4.08084 4.76244 4.09909 4.68485C4.11734 4.60725 4.15687 4.53627 4.21324 4.47991C4.26961 4.42354 4.34058 4.38401 4.41818 4.36576C4.49578 4.34751 4.57693 4.35127 4.65251 4.37661L10.9799 6.48775C11.0657 6.51653 11.1402 6.57174 11.1927 6.64546C11.2452 6.71918 11.273 6.80762 11.2722 6.89812C11.2713 6.98862 11.2418 7.07652 11.188 7.14924C11.1341 7.22195 11.0586 7.27574 10.9722 7.30289L8.1908 8.17718C8.1249 8.19768 8.06493 8.23379 8.01598 8.28244C7.96703 8.33108 7.93056 8.39084 7.90965 8.45661L7.03708 11.2389C7.01014 11.3255 6.95639 11.4014 6.88357 11.4555C6.81075 11.5097 6.72263 11.5393 6.63189 11.5402C6.54116 11.541 6.45249 11.5131 6.37866 11.4603C6.30484 11.4076 6.24966 11.3327 6.22108 11.2466H6.2228Z"
                stroke="white"
                strokeWidth="0.857143"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2341_56462">
                <rect width="12" height="12" fill="white" transform="translate(0 1)" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div>
          <p className="font-poppins text-xs  font-normal text-white">Click to scroll</p>
        </div>
      </div>
      {process.env.REACT_APP_DISABLE_HOVER_TO_SCROLL === "TRUE" ? (
        <>{children}</>
      ) : (
        <div {...(!disabled && { style: { pointerEvents: allowScroll ? "auto" : "none" } })}>
          {children}
        </div>
      )}
    </div>
  );
}

type Event = MouseEvent;

const useMouseEnterLeave = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  onMouseEnterHandler: (event: Event) => void,
  onMouseLeaveHandler: (event: Event) => void,
) => {
  useEffect(() => {
    const handleMouseEnter = (event: MouseEvent) => {
      onMouseEnterHandler(event);
    };
    const handleMouseLeave = (event: MouseEvent) => {
      const target = event?.relatedTarget as HTMLElement;
      if (target && !["control-id-container", "tooltip"].includes(target.id)) {
        // console.log(target);
        onMouseLeaveHandler(event);
      }
    };
    if (ref.current) {
      ref.current.addEventListener("mouseenter", handleMouseEnter);
      ref.current.addEventListener("mouseleave", handleMouseLeave);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("mouseenter", handleMouseEnter);
        ref.current.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [ref, onMouseEnterHandler, onMouseLeaveHandler]);
};
