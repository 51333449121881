import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import logo from "./img/logo.svg";
import NavigationUserProfile from "../components/NavigationUserProfile";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import axios from "axios";
import config from "../api";
import useOnboardingData from "../hooks/useOnboarding";
import arrowIcon from "../assets/images/arrow-down.svg";
import { IProject } from "../types/project";
import useUserDictionary from "../hooks/useUserDictionary";
import useProjects from "../hooks/project/useProjects";
import { scrollBarClass } from "../utils/classes";
import useNotification from "../hooks/useNotification";
import ReprLogData, { extractLogDataLink } from "../components/ReprLogData";
import { ILogData, INotification } from "../types/notification";
import icon_notification from "../assets/images/notification-empty.svg";
import OrganizationSelector from "../components/OrganizationSelector";
import GibooTotalSearch from "../components/GibooTotalSearch";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { differenceInDays, format, formatDistanceToNow, parseISO } from "date-fns";
import ProjectCreationModal from "../components/ProjectCreationModal";
import { Divider, Popover, Tooltip, Whisper } from "rsuite";
import ProjectInformationModal from "../components/ProjectInformationModal";
import { useSetRecoilState } from "recoil";
import { openSigninSignup } from "../app/recoilStore";
import Button from "../components/tailwind/Button";
import SignInModal from "../components/SignInModal";
import mixpanel from "mixpanel-browser";
import { MIX_PANEL_TOKEN } from "../App";
import { MIXPANEL_EVENTS_V2, Navigate } from "../mixpanel/mixpanel";
import useOrgID from "../hooks/useOrgID";
import useGibooNavigate from "../hooks/useGibooNavigate";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import useEINFromSearchParams from "../hooks/useEINFromSearchParams";
interface Iprops {
  customClass?: string;
  withoutLogin?: boolean;
}
function Header({ withoutLogin = false, customClass }: Iprops) {
  const mxEvent = useGibooMixpanel();
  const [user, setUser] = useUser();
  const navigate = useNavigate();
  const [projects] = useProjects();
  const location = useLocation();
  const [showNavigation, setShowNavigation] = useState<boolean>(true);
  const setSignUpSignin = useSetRecoilState(openSigninSignup);
  const org_id = useOrgID();

  //mixpanel
  const sendHeaderNavActions = (to: Navigate) => {
    mxEvent(MIXPANEL_EVENTS_V2.header[""].clicked, {
      to,
    });
  };
  const logoutHandle = async () => {
    sendHeaderNavActions(Navigate.LOGOUT);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/api/v2/auth/logout",
        config,
      );
      // console.log("ok", response);
    } catch (err) {
      // console.log("error", err);
    } finally {
      if (MIX_PANEL_TOKEN) {
        mixpanel.reset();
      }
      setUser(null);
      sessionStorage.removeItem("featureIntroduction");
      sessionStorage.removeItem("newUser");
      navigate("/", { state: { noRedirect: true } });
    }
  };
  const renderNavItem = useCallback(() => {
    if (!showNavigation) {
      return <></>;
    }
    return (
      <div className="mr-8 inline-flex h-[58px] items-end transition delay-300">
        <div className="flex h-[40px] items-center gap-7 whitespace-nowrap text-center">
          {!withoutLogin && (
            <NavLink
              to="/"
              className={({ isActive }) =>
                classNames(
                  "h-full pb-6 font-poppins text-sm  ",
                  isActive
                    ? "border-b-2 border-purple-500 font-medium text-purple-300 "
                    : "!font-normal !text-gray-700",
                )
              }
              onClick={() => {
                sendHeaderNavActions(Navigate.HOME);
              }}
            >
              Home
            </NavLink>
          )}
          {!withoutLogin && (
            <>
              <MyProjectItems
                onClick={() => {
                  sendHeaderNavActions(Navigate.PROJECTS);
                }}
              />
              <NavLink
                to="/my-tasks"
                className={({ isActive }) =>
                  classNames(
                    "h-full pb-6 font-poppins text-sm  ",
                    isActive
                      ? "border-b-2 border-purple-500 font-medium text-purple-300"
                      : "!font-normal !text-gray-700",
                  )
                }
                onClick={() => {
                  sendHeaderNavActions(Navigate.TASKS);
                }}
              >
                My tasks
              </NavLink>
              {/* <NavLink
            to="/organization"
            className={({ isActive }) =>
              classNames(
                "h-full pb-6 font-poppins text-sm  ",
                isActive
                  ? "border-b-2 border-purple-500 font-medium text-purple-300"
                  : "!font-normal !text-gray-700",
              )
            }
          >
            My organization profile
          </NavLink> */}
              <Notification
                onClick={() => {
                  sendHeaderNavActions(Navigate.NOTIFICATION);
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  }, [location, projects, showNavigation]);
  return (
    <>
      {withoutLogin && <SignInModal />}
      <div
        className={classNames(
          "fixed top-0 z-[910] flex h-[58px] w-full items-center justify-center bg-white",
          customClass,
        )}
      >
        <div className="z-[12] flex h-full w-full items-center justify-center bg-white px-20 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]">
          <div className="flex h-full w-[1280px] max-w-[1280px] items-center justify-between">
            <div className="flex h-[58px] grow items-center gap-3 pr-5">
              <NavLink
                to="/"
                className={"mr-5  w-[41px] min-w-[41px]"}
                onClick={() => {
                  sendHeaderNavActions(Navigate.LOGO);
                }}
              >
                <img src={logo} alt="Giboo" className=" w-full" />
              </NavLink>
              <OrganizationSelector wrapperClass="mt-1" />
              {location.pathname !== "/" && (
                <div className="mt-[-4px] h-full w-fit grow py-[14px]">
                  <GibooTotalSearch
                    from_for_mixpanel="header"
                    id="org-search"
                    className="w-full"
                    inputMaxWidth="670px"
                    itemMaxWidth="670px"
                    inputWidth={withoutLogin ? "670px" : "350px"}
                    itemMaxHeight="700px"
                    float
                    showBackdrop
                    onInputShrink={() => {
                      setShowNavigation(true);
                    }}
                    onInputExpand={() => {
                      setShowNavigation(false);
                    }}
                    withoutLogin={withoutLogin}
                    placeholder={
                      withoutLogin
                        ? "Enter phrases or keywords on your focus areas to find relevant grants and funders"
                        : undefined
                    }
                  />
                </div>
              )}
              {withoutLogin && (
                <div className="mr-7 inline-flex items-center gap-2">
                  <Button
                    label="Join now"
                    id="btn-signup"
                    outline
                    handleOnClick={() => {
                      mxEvent(MIXPANEL_EVENTS_V2.header.join.clicked, {
                        location: location.pathname,
                      });
                      setSignUpSignin({ show: true });
                      // setSignUpSignin({ show: true, type: "TRIAL_END" }); // For trail end
                    }}
                    className={classNames("h-[29px] font-poppins text-sm text-purple-500 ")}
                  />
                </div>
              )}
            </div>
            <div className="flex h-full w-auto items-center">
              {renderNavItem()}
              {!withoutLogin && (
                <>
                  <Divider vertical className="mx-5 h-full" />
                  <div className="inline-flex items-center gap-6">
                    <div className="grid h-full place-items-center">
                      <NavigationUserProfile handleLogout={() => logoutHandle()} />
                    </div>
                    <Feedback />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
function MyProjectItems({ onClick }: { onClick: () => void }) {
  const navigate = useNavigate();
  const [projects] = useProjects();
  const [onboardingData] = useOnboardingData();
  const recentProjects = projects.data.slice(0, 4);
  const location = useLocation();
  const [showProjectCreationModal, setShowProjectCreationModal] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<IProject>();
  const renderItem = ({
    id,
    label,
    onClick,
  }: {
    id: number;
    label: string;
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }) => {
    return (
      <button
        key={id}
        className="block truncate text-ellipsis px-[10px] py-2  text-left text-xs font-normal text-black hover:bg-gray-200 active:text-purple-500"
        onClick={(e: any) => {
          onClick(e);
        }}
      >
        {label}
      </button>
    );
  };
  const render = (isOpen: boolean, toggle: () => void) => {
    return (
      <>
        <div
          id={`project-selector-bar`}
          className={classNames("inline-flex h-full items-center gap-2 text-inherit")}
          onClick={() => {
            onClick?.();
            toggle();
          }}
        >
          My projects
          <span className="grid place-items-center">
            <i className={classNames("gi-md gi-angle-down pt-0.5")} />
          </span>
        </div>
        <div
          className={classNames(
            " group absolute !top-[42px] right-0 z-[50]  w-[239px]   max-w-[239px] origin-top-right overflow-hidden rounded-md bg-white  shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)] ring-1 ring-black ring-opacity-5  focus:outline-none ",
            isOpen ? "block" : "hidden",
          )}
        >
          {recentProjects.length > 0 && (
            <>
              <div className="flex  flex-col gap-[5px] pt-3" role="none">
                <h6 className="px-3 text-left font-poppins text-xs text-purple-500">Recent</h6>
                {recentProjects.map((item, i) =>
                  renderItem({
                    id: i,
                    label: `${item?.name || "No name"}`,
                    onClick: (e) => {
                      toggle();
                      navigate(`/project/${item._id}/`, { relative: "route" });
                      e.preventDefault();
                      e.stopPropagation();
                    },
                  }),
                )}
              </div>
              <div className="h-[1px] w-full border-b border-gray-300" />
            </>
          )}
          <div className="flex flex-col">
            {renderItem({
              id: 1,
              label: "View all projects",
              onClick: (e) => {
                toggle();
                navigate("/projects");

                e.stopPropagation();
                e.preventDefault();
              },
            })}
            {onboardingData.role >= 3 &&
              renderItem({
                id: 2,
                label: "Create project",
                onClick: (e) => {
                  setShowProjectCreationModal(true);
                  toggle();
                  e.stopPropagation();
                  e.preventDefault();
                },
              })}
          </div>
        </div>
      </>
    );
  };
  // const isActive = location.pathname.toLowerCase().startsWith("/project");
  return (
    <>
      {showProjectCreationModal && (
        <ProjectInformationModal
          from_for_mixpanel="header"
          open={showProjectCreationModal}
          setOpen={setShowProjectCreationModal}
          id=""
          withOutFunderGrant
          isNewProject
        />
      )}
      {showEdit && (
        <ProjectCreationModal
          open={showEdit}
          setOpen={setShowEdit}
          data={selectedProject}
          isEdit
          onProjectCreation={() => {
            setSelectedProject(undefined);
          }}
        />
      )}
      <ToggleButton>
        {({ isOpen, toggle }) => {
          return (
            <div className="mt-[-1px]">
              <NavLink
                to="/project"
                caseSensitive
                className={({ isActive }) =>
                  classNames(
                    "group relative h-full cursor-pointer pb-[19px] font-poppins text-sm  ",
                    isActive
                      ? "border-b-2 border-purple-500 font-medium text-purple-300 "
                      : "!font-normal !text-gray-700",
                  )
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {render(isOpen, toggle)}
              </NavLink>
            </div>
          );
        }}
      </ToggleButton>
    </>
  );
}

function Notification({ onClick }: { onClick: () => void }) {
  const { data } = useNotification();
  const notificationCount = data.filter((i) => !i.read).length || 0;
  return (
    <ToggleButton>
      {({ isOpen, toggle }) => {
        return (
          <div
            className={classNames(
              "relative h-full  border-purple-500 pb-6 font-poppins  text-sm !font-normal !text-gray-700 ",
              isOpen ? "border-b-2" : "border-b-0",
            )}
          >
            <div
              className="inline-flex cursor-pointer items-center gap-1 text-gray-700"
              onClick={() => {
                onClick?.();
                toggle();
              }}
            >
              Notification
              {notificationCount > 0 && (
                <span className="min-w-4 grid h-fit place-items-center rounded bg-purple-500 px-1 text-[10px] !text-white">
                  {notificationCount}
                </span>
              )}
            </div>
            <div
              className={classNames(
                " absolute !top-[41px] right-0 z-[50] h-full max-h-[753px] min-h-[400px] w-[418px] max-w-[418px] origin-top-right  overflow-hidden overscroll-contain rounded-md bg-white p-5 shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)] ring-1 ring-black ring-opacity-5  focus:outline-none ",
                notificationCount === 0 && "!min-h-[340px]",
                isOpen ? "block" : "hidden",
              )}
            >
              <NotificationList toggle={toggle} />
            </div>
          </div>
        );
      }}
    </ToggleButton>
  );
}
export function NotificationList({
  toggle,
  titleClass,
}: {
  toggle?: () => void;
  titleClass?: string;
}) {
  const [filter, setFilter] = useState<"all" | "unread">("all");
  const { data, revalidate, read, readAll } = useNotification();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  useEffect(() => {
    data.length > 0 && setNotifications(filter === "all" ? data : data.filter((i) => !i.read));
  }, [data, filter]);
  return (
    <div className="flex h-full flex-col gap-[13px]">
      <h4
        className={classNames("text-left font-poppins text-xl font-normal text-black", titleClass)}
      >
        Notifications
      </h4>
      {data.length > 0 ? (
        <>
          <div className="flex items-center justify-between">
            <div className="inline-flex gap-3">
              <span
                className={classNames(
                  "cursor-pointer px-3 py-1 text-black",
                  filter == "all" && "rounded-full bg-purple-50",
                )}
                onClick={() => {
                  setFilter("all");
                }}
              >
                All
              </span>
              <span
                className={classNames(
                  "cursor-pointer px-3 py-1 text-black",
                  filter == "unread" && "rounded-full bg-purple-50",
                )}
                onClick={() => {
                  setFilter("unread");
                }}
              >
                Unread
              </span>
            </div>
            <div
              className="inline-flex cursor-pointer items-center gap-3 text-gray-700 hover:text-gray-800"
              onClick={() => {
                readAll();
              }}
            >
              <i className="gi-check " /> <span>Mark all as read</span>
            </div>
          </div>
          {/* notification list */}
          <div
            className={classNames(
              "my-[13px] flex h-[470px] flex-col gap-[8px] overflow-y-scroll pr-1",
              scrollBarClass,
            )}
          >
            {notifications.map((n) => (
              <NotificationItem
                toggle={() => toggle?.()}
                key={n._id}
                item={n}
                onView={(id: string) => {
                  read(id);
                }}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="mt-5 flex h-full w-full flex-col items-center justify-center gap-5">
          <img src={icon_notification} className="h-fit w-fit" />
          <p className="text-center text-base font-semibold text-gray-900">No notifications yet</p>
        </div>
      )}
    </div>
  );
}

function NotificationItem({
  item,
  onView,
  toggle,
}: {
  item: INotification;
  onView: (id: string) => void;
  toggle?: () => void;
}) {
  const navigate = useNavigate();
  const extractLinkObject = (data: ILogData[]): string | undefined => {
    const list = data
      .map((log) => extractLogDataLink(log, undefined, undefined))
      .filter(Boolean)
      .slice(-1);
    return list && list.length > 0 ? list[0] : undefined;
  };
  const isLink = extractLinkObject(item.data);
  return (
    <>
      <div
        className={classNames(
          "flex cursor-pointer  flex-col gap-2 border-b border-gray-300 bg-white p-3 first:border-t  hover:bg-gray-100",
          item.read ? "opacity-50" : "",
        )}
        onClick={() => {
          toggle?.();
          onView(item._id);
          if (isLink) navigate(isLink);
        }}
      >
        <h6 className="text-left font-poppins text-xs text-gray-700">
          {item.created_at
            ? differenceInDays(new Date(), new Date(item.created_at + "Z")) > 1
              ? format(parseISO(item.created_at + "Z"), "MM/dd/yyyy, hh:mm a")
              : `${formatDistanceToNow(parseISO(item.created_at + "Z"))} ago`
            : ""}
        </h6>
        <div className="inline-flex items-start justify-between">
          <div className="flex items-center">
            <ReprLogData data={item.data} id={item._id} project_id="" task_id="" />
          </div>
          {!item.read && <span className="h-[6px] w-[6px] rounded-full bg-blue-800" />}
        </div>
      </div>
    </>
  );
}

function ToggleButton({
  children,
}: {
  children?: (props: { toggle: () => void; isOpen: boolean }) => any;
}) {
  const [isOpen, setIsOpened] = useState<boolean>(false);
  const handleToggle = () => {
    setIsOpened((prev) => !prev);
  };
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    if (isOpen) setIsOpened(false);
  });
  return (
    <div ref={ref} id={"?"} className="self-start">
      {children && children({ toggle: handleToggle, isOpen })}
    </div>
  );
}

function Feedback() {
  const mxEvent = useGibooMixpanel("header");
  const navigate = useGibooNavigate("header");
  const whisperRef = useRef<any>(null);
  return (
    <>
      <Whisper
        ref={whisperRef}
        controlId="control-id-container"
        trigger={"click"}
        speaker={
          <Popover
            prefix="tooltip-hover-search-plus"
            className={classNames(
              "!mt-[-1px] h-fit w-[211px] rounded border border-gray-300 bg-gray-50 p-0 shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)]",
            )}
            arrow={false}
          >
            <div
              className="block cursor-pointer truncate text-ellipsis px-4 py-3 text-left text-xs font-normal text-gray-700 hover:bg-gray-200 active:text-purple-500"
              onClick={() => {
                mxEvent(MIXPANEL_EVENTS_V2.header[""].clicked, { to: Navigate.FEEDBACK });
                whisperRef?.current?.close();
                navigate("/feedback");
              }}
            >
              Give feedback
            </div>
            <div className="h-px w-52 bg-neutral-200" />
            <div
              className="block cursor-pointer truncate text-ellipsis px-4  py-3 text-left text-xs font-normal text-gray-700 hover:bg-gray-200 active:text-purple-500"
              onClick={() => {
                mxEvent(MIXPANEL_EVENTS_V2.header[""].clicked, { to: Navigate.REPORT_BUG });
                whisperRef?.current?.close();
                navigate("/report");
              }}
            >
              Report bug
            </div>
          </Popover>
        }
        placement="bottomEnd"
      >
        <div
          className={classNames(
            "inline-flex h-[26px] cursor-pointer items-center gap-2 whitespace-nowrap rounded border border-green-500 bg-green-50 px-2 py-1 text-green-900",
          )}
        >
          Beta feedback
          <span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.66667 2.66666C5.40402 2.66666 5.14395 2.71839 4.9013 2.8189C4.65865 2.91941 4.43817 3.06673 4.25245 3.25245C4.06674 3.43816 3.91942 3.65864 3.81891 3.90129C3.7184 4.14395 3.66667 4.40402 3.66667 4.66666C3.66667 4.9293 3.7184 5.18938 3.81891 5.43203C3.91942 5.67468 4.06674 5.89516 4.25245 6.08087C4.43817 6.26659 4.65865 6.41391 4.9013 6.51442C5.14395 6.61493 5.40402 6.66666 5.66667 6.66666C6.1971 6.66666 6.70581 6.45595 7.08088 6.08087C7.45595 5.7058 7.66667 5.19709 7.66667 4.66666C7.66667 4.13623 7.45595 3.62752 7.08088 3.25245C6.70581 2.87738 6.1971 2.66666 5.66667 2.66666ZM2.33333 4.66666C2.33333 3.78261 2.68452 2.93476 3.30964 2.30964C3.93477 1.68452 4.78261 1.33333 5.66667 1.33333C6.55072 1.33333 7.39857 1.68452 8.02369 2.30964C8.64881 2.93476 9 3.78261 9 4.66666C9 5.55072 8.64881 6.39856 8.02369 7.02368C7.39857 7.6488 6.55072 7.99999 5.66667 7.99999C4.78261 7.99999 3.93477 7.6488 3.30964 7.02368C2.68452 6.39856 2.33333 5.55072 2.33333 4.66666ZM13.7153 3.76533L14.0467 4.34333C14.453 5.05088 14.6666 5.8526 14.6664 6.66851C14.6662 7.48442 14.452 8.28601 14.0453 8.99333L13.7127 9.57133L12.5573 8.90666L12.8893 8.32866C13.1799 7.82348 13.3329 7.25095 13.3331 6.66817C13.3334 6.0854 13.1808 5.51275 12.8907 5.00733L12.5587 4.42933L13.7153 3.76533ZM11.692 4.92666L12.0233 5.50533C12.2265 5.85901 12.3334 6.25977 12.3334 6.66766C12.3334 7.07555 12.2265 7.47631 12.0233 7.82999L11.69 8.40799L10.5347 7.74333L10.8667 7.16533C10.9539 7.01373 10.9998 6.84189 10.9998 6.66699C10.9998 6.4921 10.9539 6.32026 10.8667 6.16866L10.5353 5.59066L11.692 4.92666ZM0 12.6667C0 11.7826 0.351189 10.9348 0.976311 10.3096C1.60143 9.68452 2.44928 9.33333 3.33333 9.33333H8C8.88405 9.33333 9.7319 9.68452 10.357 10.3096C10.9821 10.9348 11.3333 11.7826 11.3333 12.6667V14H10V12.6667C10 12.1362 9.78929 11.6275 9.41421 11.2524C9.03914 10.8774 8.53043 10.6667 8 10.6667H3.33333C2.8029 10.6667 2.29419 10.8774 1.91912 11.2524C1.54405 11.6275 1.33333 12.1362 1.33333 12.6667V14H0V12.6667Z"
                fill="#3B552F"
              />
            </svg>
          </span>
        </div>
      </Whisper>
    </>
  );
}
