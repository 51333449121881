import React, { Component, ErrorInfo, ReactNode } from "react";
import IMAGE_ERROR from "../assets/images/something-went-wrong.svg";
import Button from "./tailwind/Button";
import { useNavigate } from "react-router-dom";
interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <SomethingWentWrong />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

const SomethingWentWrong = () => {
  const navigate = useNavigate();
  return (
    <div className="grid h-[90vh] place-items-center">
      <div className="grid h-fit w-full place-items-center gap-10">
        <img src={IMAGE_ERROR} />
        <h4 className="font-poppins text-[24px] text-black">
          Oops! We apologize for the inconvenience.
        </h4>
        <Button
          className="!h-[37px] w-fit !text-sm"
          size="md"
          handleOnClick={() => {
            window.location.href = "/";
          }}
          id="btn-back"
          label={"Go back"}
          prependIcon={<i className="gi gi-left-arrow" />}
        />
      </div>
    </div>
  );
};
