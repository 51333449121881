import { useCallback } from "react";
import { IOrgVerificationRequest, IOrgVerificationRequestStatus } from "../types/org";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../api";
import { swrOptionFetchOnlyOnMount } from "../types/swr";
import { currentDateString } from "../utils/dateUtils";
interface useRequestOrgVerificationState {
  data: IOrgVerificationRequestStatus;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  request: (req: IOrgVerificationRequest) => Promise<IOrgVerificationRequestStatus | undefined>;
  revalidate: () => Promise<IOrgVerificationRequestStatus | undefined>;
}
const VerificationRequestStatus: IOrgVerificationRequestStatus = {
  org_id: "",
  requested: false,
  created_at: "",
  updated_at: "",
  files: [],
};

function useRequestOrgVerification(
  org_id?: string,
  verifyAdmin = false,
): useRequestOrgVerificationState {
  const url =
    process.env.REACT_APP_API_URL +
    `/api/v2/orgs/verify/${verifyAdmin ? "admin/" : ""}status/${org_id}`;
  const requestUrl =
    process.env.REACT_APP_API_URL + `/api/v2/orgs/verify/${verifyAdmin ? "admin/" : ""}${org_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IOrgVerificationRequestStatus>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) =>
        res.status === 204
          ? {
              org_id: org_id || "",
              created_at: currentDateString(),
              updated_at: currentDateString(),
              files: [],
              requested: false,
            }
          : (res.data as IOrgVerificationRequestStatus),
      )
      .then((res) => ({ ...res, files: res.files.map((f) => ({ ...f, done: true })) }));
  };
  const { data, isLoading, error, isValidating } = useSWR<IOrgVerificationRequestStatus>(
    org_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const request = useCallback(
    async (req: IOrgVerificationRequest) => {
      if (!data) return new Promise<IOrgVerificationRequestStatus>((resolve, reject) => reject());
      const options = {
        optimisticData: { ...data, ...req, requested: true, updated_at: new Date().toISOString() },
        rollbackOnError: true,
      };
      return mutate<IOrgVerificationRequestStatus>(
        url,
        axios.post(requestUrl, req, config).then((response) => response.data),
        options,
      );
    },
    [url, requestUrl, data],
  );
  const revalidate = useCallback(() => mutate<IOrgVerificationRequestStatus>(url), [url]);
  return {
    data: data || VerificationRequestStatus,
    isLoading,
    error,
    isValidating,
    request,
    revalidate,
  };
}
export default useRequestOrgVerification;
