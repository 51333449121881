import { ActionMeta, MenuPlacement, MultiValue, components } from "react-select";
import CreatableSelect from "react-select/creatable";
import "../css/selector.scss";
import classNames from "classnames";
import { scrollBarClass } from "../../utils/classes";
import { useEffect, useState } from "react";
import { sortIOption } from "./GroupBaseMultipleCreatableSelector";
import { IOption } from "../tailwind/AsyncCreatableSelector";

interface MultipleCreatableSelectorProps<T> {
  id: string;
  data: T[];
  value: T[];
  placeholder?: string;
  focusPlaceholder?: string;
  placeholderClass?: string;
  onCreateOption: (inputValue: string) => void;
  onChange: (newValue: MultiValue<T>, actionMeta: ActionMeta<T>) => void;
  disabled?: boolean;
  invalid?: boolean;
  errorMessage?: string;
  className?: string;
  inputClass?: string;
  compact?: boolean;
  isClearable?: boolean;
  dropdownIcon?: boolean;
  isRequired?: boolean;
  requiredMessage?: string;
  menuPlacement?: MenuPlacement;
  defaultOpen?: boolean;
  menuClass?: string;
}
function MultipleCreatableSelector<T extends IOption>({
  id,
  data,
  value,
  placeholder,
  onCreateOption,
  onChange,
  disabled,
  errorMessage,
  invalid,
  className,
  compact = false,
  placeholderClass,
  isClearable = true,
  dropdownIcon = true,
  inputClass,
  isRequired,
  requiredMessage,
  focusPlaceholder,
  menuPlacement = "auto",
  defaultOpen = false,
  menuClass,
}: MultipleCreatableSelectorProps<T>) {
  const [inputValue, setInputValue] = useState<string>("");
  const [sortedData, setSortedData] = useState<T[]>(data);
  useEffect(() => {
    if (!inputValue) {
      setSortedData(data);
      return;
    }
    const sorted = data.sort((a, b) => sortIOption(a, b, inputValue));
    setSortedData(sorted);
  }, [data, inputValue, setSortedData]);
  const DropdownIndicator = (props: any) => {
    // if (props?.hasValue) {
    //   return <></>;
    // }
    return (
      <components.DropdownIndicator {...props} className="px-1">
        <i className={classNames("gi gi-angle-down")} />
      </components.DropdownIndicator>
    );
  };
  const Placeholder = (props: any) => {
    return (
      <components.Placeholder {...props}>
        {isRequired
          ? requiredMessage
          : focusPlaceholder
          ? props.isFocused
            ? focusPlaceholder
            : placeholder
          : placeholder}
      </components.Placeholder>
    );
  };
  const ClearIndicator = (props: any) => {
    return (
      <components.ClearIndicator {...props} className="px-1">
        <i className={classNames("gi  gi-x cursor-pointer")} />
      </components.ClearIndicator>
    );
  };
  const MultiValueRemove = (props: any) => {
    return (
      <components.MultiValueRemove {...props} className="px-1">
        <i className={classNames("gi  gi-x scale-90 cursor-pointer")} />
      </components.MultiValueRemove>
    );
  };
  return (
    <>
      <CreatableSelect
        unstyled
        classNamePrefix=""
        classNames={{
          control: (state) =>
            classNames(
              "rounded border px-3 text-gray-700 max-h-[90px] min-h-[56px] overflow-y-auto font-poppins",
              compact ? "py-0" : "py-2",
              state.isFocused ? "border-gray-400" : "border-gray-400",
              {
                "border-purple-500 bg-purple-50 text-gray-700": invalid,
              },
              className ? className : "!max-h-[90px] !min-h-[56px]",
              isRequired && "!bg-purple-50 !rounded-none",
            ),
          menu: (state) => classNames(`bg-white rounded shadow border border-gray-300`, menuClass),
          menuList: (state) =>
            classNames("flex flex-col overscroll-contain max-h-[300px]", scrollBarClass),
          option: (state) =>
            classNames(
              "p-3 rounded text-gray-700 !text-sm hover:bg-gray-200",
              state.isSelected ? "bg-gray-300 __option--selected" : "",
              state.isFocused ? "bg-gray-300 __option--focused" : "",
            ),
          groupHeading: (state) => "text-sm text-gray-500",
          placeholder: (state) =>
            classNames(
              "text-gray-500 text-sm font-poppins font-normal",
              placeholderClass,
              isRequired && "!text-purple-500",
            ),
          container: (state) => "p-0",
          input: (state) => classNames("creatable-select-input", inputClass),
          valueContainer: (state) => "flex flex-wrap gap-2",
          multiValue: (state) => "rounded bg-gray-200 border-gray-500 px-2 !h-[24px] items-center",
          multiValueRemove: (state) => "ml-1",
          indicatorsContainer: () => "grid place-items-center h-full",
        }}
        id={id}
        isMulti
        options={sortedData}
        value={value}
        placeholder={isRequired ? requiredMessage : placeholder}
        onCreateOption={onCreateOption}
        onChange={onChange}
        formatCreateLabel={(value) => `Click to add "${value}"`}
        createOptionPosition="first"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 99999 }),
        }}
        components={{
          DropdownIndicator: dropdownIcon ? DropdownIndicator : null,
          ClearIndicator,
          Placeholder,
          MultiValueRemove,
        }}
        onInputChange={(value) => {
          setInputValue(value);
        }}
        menuPortalTarget={document.body}
        isDisabled={disabled}
        isClearable={isClearable}
        menuPlacement={menuPlacement}
        defaultMenuIsOpen={defaultOpen}
      />
      {errorMessage && invalid ? (
        <div className="font-sm pt-2 font-poppins text-base text-purple-500">{errorMessage}</div>
      ) : null}
    </>
  );
}
export default MultipleCreatableSelector;