import classNames from "classnames";
import { TooltipWrapper } from "../TooltipWrapper";
import { FunderSearchResult, SearchQuery, getSearchResultKey } from "../../types/search";
import RoundButton from "../RoundButton";
import {
  getFunderHqLocation,
  renderRow,
  reprFunderPreferredStages,
  reprFunderTotalGrantCount,
} from "../../utils/funder";
import TagFunderSize from "../TagFunderSize";
import PhilanthropicInformationFlexWrap from "../PhilanthropicInformationFlexWrap";
import TagOrganization from "../TagOrganization";
import useIsPreviousFunder from "../../hooks/search/useIsPreviousFunder";
import TagPreviousFunder from "../TagPreviousFunder";
import TagInvitationOnly from "../TagInvitationOnly";
import Fuse from "fuse.js";
import FuzzyMatchName from "../FuzzyMatchName";
import StartFundRaisingModal from "../StartFundraisingModal";
import { ReactElement, useCallback, useState } from "react";
import TagFunderType from "../TagFunderType";
import { ReprServiceLocations } from "../../utils/grant";
import { toUsdLong } from "../../utils/formatHelper";
import useLazyLoad from "../../hooks/useLazyLoad";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";
import MemoizedLoadingTaxonomy from "../LoadingTaxonomy";
interface FunderCardProps {
  wrapperClass?: string;
  funder: FunderSearchResult;
  query?: SearchQuery;
  noMatchTaxonomy?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  lazyLoadDelay?: number;
}
function FunderCard({
  funder,
  noMatchTaxonomy = false,
  query,
  onClick,
  lazyLoadDelay = 0,
  ...props
}: FunderCardProps) {
  const { data: hasGranted } = useIsPreviousFunder(funder._id);
  const { ready } = useLazyLoad(lazyLoadDelay);
  const { data: funderTaxonomy, isLoading } = useFunderSearchResult(
    !noMatchTaxonomy && ready ? funder._id : undefined,
    query,
  );
  const key = getSearchResultKey(funder);
  return (
    <div
      key={key}
      className={classNames(
        "group flex h-fit w-full flex-col gap-2",
        onClick ? "cursor-pointer bg-white hover:bg-purple-50" : "",
        props.wrapperClass,
      )}
      {...(onClick ? { onClick } : {})}
    >
      <div className="mb-1 inline-flex items-center justify-between">
        <div className="flex flex-wrap items-center gap-2">
          {/* <TagOrganization type="funder" /> */}
          <TagFunderType funder_type={funder.funder_type} />
          {hasGranted ? <TagPreviousFunder /> : null}
        </div>
      </div>
      <div className="flex items-start justify-between">
        <p className="line-clamp-2 w-full grow whitespace-pre-line font-semibold text-purple-500 group-hover:underline">
          {funder.name}
        </p>
      </div>
      {funder.does_not_accept_unsolicited !== true && <TagInvitationOnly invitationOnly={false} />}
      {/* <TagFunderSize assets={funder.assets} className="mb-1 w-fit" /> */}
      {funder && renderRow("Address", getFunderHqLocation(funder))}
      {funder.service_loc &&
        funder.service_loc.length > 0 &&
        renderRow(
          "Service areas",
          <ReprServiceLocations service_loc={funder.service_loc} detail={false} />,
        )}
      {reprFunderPreferredStages(funder, false) &&
        renderRow("Funded stage", reprFunderPreferredStages(funder, false, 2))}
      {funder.grant_amount_max
        ? renderRow(
            "Funding size",
            `${toUsdLong(funder.grant_amount_min)} - ${toUsdLong(funder.grant_amount_max)}`,
          )
        : null}
      {reprFunderTotalGrantCount(funder)
        ? renderRow("Number of grants made", reprFunderTotalGrantCount(funder).toLocaleString())
        : null}
      {!noMatchTaxonomy &&
        (isLoading || (!ready && !funderTaxonomy) ? (
          <MemoizedLoadingTaxonomy seed={funder.uid} />
        ) : (
          <PhilanthropicInformationFlexWrap
            containerClass={classNames(
              "mt-2 p-4 border rounded border-gray-300 group-hover:border-purple-200",
            )}
            id={key}
            focusArea={funderTaxonomy?.focus_area}
            beneficiary={funderTaxonomy?.beneficiary}
            program={funderTaxonomy?.program}
            location={funderTaxonomy?.service_loc}
            priorityFocusArea={query?.must_focus_area}
            priorityBeneficiary={query?.must_beneficiary}
            priorityProgram={query?.must_program}
            priorityLocation={query?.must_service_loc}
            tagSize="sm"
            tagMatched
            allowPastGrantSearch
            funder_id={funder._id}
            searchQuery={query}
            enableGroupLocation
            predictedSize={3}
          />
        ))}
    </div>
  );
}
export default FunderCard;
