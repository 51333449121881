import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import useUser from "../useUser";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
import { useCallback } from "react";
interface RateLimitOverview {
  ip: string;
  data: {
    page: string;
    created_at: string;
  }[];
}
interface RateLimitOverviewState {
  data: RateLimitOverview | undefined;
  error: any;
  isLoading: boolean;
  revalidate: () => Promise<RateLimitOverview | undefined>;
}
function useRateLimitOverview(): RateLimitOverviewState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/rate/ip`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<RateLimitOverview>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data);
  };
  const { data, isLoading, error } = useSWR<RateLimitOverview>(
    url,
    fetch,
    swrOptionFetchOnlyOnMount,
  );

  const revalidate = useCallback(() => mutate<RateLimitOverview>(url), [url]);
  return {
    data: data,
    isLoading,
    error,
    revalidate,
  };
}
export default useRateLimitOverview;
