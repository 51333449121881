import classNames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import { Divider, Steps } from "rsuite";
import Button from "../../../../components/tailwind/Button";
import Spacer from "../../../../components/Spacer";
import IMG_EXAMPLE from "../../../../assets/financial/revenue-stream-placeholder.svg";
import IncreaseTag from "../component/IncreaseTag";
import SmallestProportionTag from "../component/SmallestProportionTag";
import NpoFinancialRevenueStream, {
  RevenueStreamChart,
} from "../../dashboard/components/NpoFinancialRevenueStream";
import useNPOIRSProfile from "../../../../hooks/useNPOIRSProfile";
import LargestProportionTag from "../component/LargestProportionTag";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import DecreaseTag from "../component/DecreaseTag";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import CloseButton from "../../../../components/CloseButton";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";

export default function RevenueStream({
  onBack,
  onNext,
  npo_id,
  org_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  org_id?: string;
  isBack?: boolean;
}) {
  const [currentStep, setCurrentStep] = useState<number>(isBack ? 1 : 0);
  useEffect(() => {
    if (isBack) {
      setTimeout(() => {
        scrollToElement("businessmodel-step-2");
      }, 300);
    }
  }, [isBack]);
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value >= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };
  const scrollToElement = (id: string) => {
    const element = document?.getElementById(id);
    const container = document.getElementById("financial-scroll-container");
    if (element && container) {
      const elementTop = element.getBoundingClientRect().top + container.scrollTop;
      const adjustedOffset = elementTop - 120;
      container.scrollTo({ top: adjustedOffset, behavior: "smooth" });
    }
  };
  const renderStep1 = () => {
    return (
      <RevenueStreamInfo>
        <div
          className={classNames(
            "flex h-[38px] items-center justify-start gap-2",
            currentStep === 0 ? "flex" : "hidden",
          )}
        >
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              if (currentStep < 1) {
                setCurrentStep(1);
                setTimeout(() => {
                  scrollToElement("businessmodel-step-2");
                }, 300);
              }
            }}
            label="See the assessment result"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
        <Spacer height="100px" />
      </RevenueStreamInfo>
    );
  };
  return (
    <div className="flex max-w-[760px] flex-col">
      <Steps current={currentStep} vertical>
        <Steps.Item
          title={false}
          className="!-ml-[40px] !mt-0"
          icon={currentStep > 0 ? renderDot(1) : <></>}
          description={renderStep1()}
        />
        {currentStep > 0 && (
          <Steps.Item
            icon={renderDot(2)}
            className="!-ml-[40px]"
            description={
              <Step2
                onBack={() => {
                  setCurrentStep(0);
                }}
                npo_id={npo_id}
                org_id={org_id}
                onNext={() => {
                  onNext?.();
                }}
              />
            }
          />
        )}
      </Steps>
    </div>
  );
}
const Step2 = ({
  onBack,
  onNext,
  npo_id,
  org_id,
  recomendation = true,
}: {
  onBack: () => void;
  onNext: () => void;
  npo_id?: string;
  org_id?: string;
  recomendation?: boolean;
}) => {
  const { data, update } = useOrgProfile(org_id);
  const prev = data?.skip_loading || [];
  const mxEvent = useGibooMixpanel(undefined, true);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.financial.revenue.successful);
  }, []);
  return (
    <div className="mt-3 flex min-h-[600px] flex-col gap-4" id="businessmodel-step-2">
      <h6 className="text-base font-semibold text-gray-900">
        Your organization&apos;s assessment result
      </h6>
      <NpoFinancialRevenueStream
        npo_id={npo_id}
        org_id={org_id}
        recomendation
        showChartLoader={!data.skip_loading?.includes("revenue_stream")}
      >
        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              data &&
                update({
                  skip_loading: [...prev.filter((p) => p !== "revenue_stream"), "revenue_stream"],
                });
              onNext?.();
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </NpoFinancialRevenueStream>
      <Spacer height="150px" />
    </div>
  );
};

export function RevenueStreamInfo({
  children,
  handleClose,
}: {
  children?: ReactNode;
  handleClose?: () => void;
}) {
  return (
    <div className={classNames("flex min-h-[600px] flex-col gap-4", !handleClose && "mt-3")}>
      <div className="inline-flex w-full items-center justify-between border-b border-gray-300">
        <h6 className="text-base font-semibold text-gray-900 underline underline-offset-[6px]">
          Revenue stream
        </h6>
        {handleClose && <CloseButton onClick={() => handleClose?.()} />}
      </div>
      <div className="flex min-h-[500px] flex-col rounded border border-gray-300 bg-white p-5">
        {/* section 1 */}
        <div className="flex flex-col gap-10">
          <div>
            <h5 className="font-poppins text-base font-semibold text-black">
              What is Revenue stream?
            </h5>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800">
              A revenue stream refers to the sources of income that sustain a nonprofit
              organization, encompassing contributions and grants, investment revenue, and operation
              revenue. Recognizing and understanding these revenue streams are vital for nonprofits
              as they provide a clear picture of the organization&apos;s main sources of financial
              support.
              <br />
              <br />
              It serves as a compass guiding strategic decisions, allowing nonprofits to optimize
              high-performing revenue streams and mitigate risks associated with volatile ones. By
              identifying and focusing on the most effective sources, nonprofits can enhance
              financial stability, adaptability, and ultimately ensure sustained success in
              fulfilling their mission.
            </h6>
          </div>
          <div className="flex min-h-[330px] justify-center">
            <img src={IMG_EXAMPLE} className="h-fit w-fit" alt="example" />
          </div>
        </div>
        <Spacer height="16px" />
        {/* section 2 */}
        <div className="flex flex-col">
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">
              Contribution and grants
            </p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Contribution and grants constitute financial support received by a nonprofit from
              various sources. Contributions can include donations from individuals, corporations,
              or foundations, and grants can include funds provided by government entities, private
              foundations, or other institutions to support specific projects or the overall mission
              of the nonprofit.
              <br />
              <br />
              Contribution and grants serve as essential pillars of financial support for
              nonprofits. They often fund key initiatives, programs, and operational expenses,
              contributing to the organization&apos;s ability to fulfill its mission.
            </h6>
          </div>
          <Divider className="my-5" />
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Investment revenue</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Investment revenue is the income generated by a nonprofit from its investments and
              financial assets. Nonprofits may invest their funds in stocks, bonds, real estate, or
              other financial instruments, and the returns generated from these investments
              contribute to the investment revenue stream.
              <br />
              <br />
              Investment revenue provides an additional source of income for nonprofits. While it
              involves financial risk, effective management of investments can yield returns that
              further support the organization&apos;s activities, ensuring financial sustainability.
            </h6>
          </div>
          <Divider className="my-5" />
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Operation revenue</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Operation revenue encompasses income generated through the day-to-day activities
              directly related to the nonprofit&apos;s mission. This may include program fees,
              service charges, or other income-generating activities tied to the nonprofit&apos;s
              core objectives.
              <br />
              <br />
              Operation revenue is crucial for covering the ongoing costs of running the
              organization. It ensures that the nonprofit can sustain its daily operations, deliver
              services, and make progress toward achieving its mission without solely relying on
              external contributions or investments.
            </h6>
          </div>
        </div>
      </div>
      <div className="flex min-h-[100px] flex-col rounded border border-gray-300 bg-white p-5">
        <div>
          <h5 className="font-poppins text-base font-semibold text-black">
            Why is revenue stream important?
          </h5>
          <Spacer height="10px" />
          <h6 className="font-poppins text-sm text-gray-800">
            Identifying and understanding these streams is crucial for nonprofits as they provide a
            clear and comprehensive picture of where the organization&apos;s funding originates.
            This clarity, in turn, guides strategic decision-making, allowing nonprofits to optimize
            high-performing revenue sources and mitigate risks associated with volatile ones.
            <br />
            <br />
            The significance of revenue streams lies in their role as the financial backbone of the
            organization, supporting critical initiatives, programs, and day-to-day activities. By
            diversifying and strategically managing these streams, nonprofits enhance their
            financial stability, adaptability, and overall resilience. A well-balanced and optimized
            set of revenue streams not only ensures sustained financial health but also empowers
            nonprofits to make a lasting impact on their communities and causes.
          </h6>
        </div>
      </div>
      {children && children}
    </div>
  );
}
