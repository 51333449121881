import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import { GrantAmount, Wealth, defaultGrantAmountOverview } from "../../types/funder";
import { getGrantAmountOverView } from "../../services/funders.services";
import { Order, genLastNYears } from "../../utils/formatHelper";
import useFunderFiledYear from "./useFunderFiledYear";
import { swrOptionDedupling5mins } from "../../types/swr";

interface useFunderState {
  data: GrantAmount[];
  isLoading: boolean;
  error?: object;
}
const emptyList: GrantAmount[] = [];
export default function useFunderGrantAmountOverview(id: string, LAST_N_YEARS = 6): useFunderState {
  const yearParam = genLastNYears(LAST_N_YEARS, Order.ASCEND);
  const fetch = useCallback(
    async (url: string) => {
      if (!url) return new Promise<GrantAmount[]>((resolve, reject) => reject());
      return getGrantAmountOverView(id, yearParam);
    },
    [id],
  );
  const [filledData, setFilledData] = useState<GrantAmount[]>([]);
  const { data, isLoading, error } = useSWR<GrantAmount[]>(
    id
      ? process.env.REACT_APP_API_URL +
          `/api/v2/donors/grantamountoverview?donor_id=${id}?last_n_year=${LAST_N_YEARS}`
      : null,
    fetch,
    swrOptionDedupling5mins,
  );
  const { data: filedYears } = useFunderFiledYear(id, LAST_N_YEARS);
  useEffect(() => {
    if (data) {
      setFilledData(
        filedYears.map(
          (y) =>
            data.find((item) => item.grant_year === y) || {
              ...defaultGrantAmountOverview,
              tax_year: y,
              grant_year: y,
            },
        ),
      );
    } else setFilledData(emptyList);
  }, [filedYears, data]);
  return { data: filledData || emptyList, isLoading, error };
}
