import { useCallback } from "react";
import { NavigateFunction, NavigateOptions, To, useLocation, useNavigate } from "react-router-dom";
import { FROM_FOR_MIXPANEL } from "../mixpanel/mixpanel";
import uuid from "react-uuid";

function useGibooNavigate(
  currentPage?: FROM_FOR_MIXPANEL,
  createSession = false, //create new session or bypass previous session
): NavigateFunction {
  const navigate = useNavigate();
  const { state: context, pathname } = useLocation();
  const overriddenNavigate: (to: To, options?: NavigateOptions) => void = useCallback(
    (to: To, options?: NavigateOptions) => {
      navigate(to, {
        ...(options || {}),
        state: {
          ...(options?.state || {}),
          from_for_mixpanel: currentPage
            ? currentPage
            : context?.from_for_mixpanel
            ? context.from_for_mixpanel
            : undefined,
          from_url_for_mixpanel: currentPage
            ? pathname
            : context?.from_url_for_mixpanel
            ? context.from_url_for_mixpanel
            : undefined,
          session_for_mixpanel: createSession ? uuid() : context?.session_for_mixpanel,
        },
      });
    },
    [navigate, context, pathname],
  );
  const mergedNavigate: (arg: any, arg2?: NavigateOptions) => void = useCallback(
    (arg: any, arg2?: NavigateOptions) => {
      if (typeof arg === "number") return navigate(arg);
      else return overriddenNavigate(arg, arg2);
    },
    [navigate, overriddenNavigate],
  );
  return mergedNavigate;
}
export default useGibooNavigate;
