import classNames from "classnames";
import { memo } from "react";

function LoadingDocument({
  loading,
  max = 4,
  wrapperClass,
}: {
  loading: boolean;
  max?: number;
  wrapperClass?: string;
}) {
  return (
    <>
      {Array.from({ length: max }, (x, i) => i).map((_, i) => (
        <div
          key={i}
          className="loading-light-shimmer-on flex h-[180px] w-[332px] max-w-[332px] flex-col justify-between rounded border bg-white px-5 py-4"
        >
          <div className="loading-light-shimmer-child flex flex-col items-start gap-2">
            <div className="!min-h-[24px] !min-w-[270px]"></div>
            <div className="mt-3 !min-h-[18px] !min-w-[230px]"></div>
            <div className="!min-h-[18px] !min-w-[180px]"></div>
            <div className="!min-h-[18px] !min-w-[220px]"></div>
            <div className="!min-h-[18px] !min-w-[200px]"></div>
          </div>
        </div>
      ))}
    </>
  );
}
const MemoizedLoadingDocument = memo(LoadingDocument);
export default MemoizedLoadingDocument;
