import { useEffect, useLayoutEffect, useState } from "react";
import useUser from "../../../hooks/useUser";
import axios from "axios";
import config from "../../../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { reprGrantFundingType } from "../../../utils/grant";
import FunderSummary from "../../../components/FunderSummary";
import PhilanthropicInformationFoldable from "../../../components/PhilanthropicInformationFoldable";
import useOnboardingData from "../../../hooks/useOnboarding";
import { SearchQuery, getSearchQueryFromISearchParam } from "../../../types/search";
import { matchTaxonomyBySearchQueryWithPhilanthropy } from "../../../types/taxonomy";
import PlainButton from "../../../components/PlainButton";
import { scrollBarClass } from "../../../utils/classes";
import { getISearchParamFromOnboardingData } from "../../../app/onboardingSlice";
import TagGrantPresent from "../../../components/TagGrantPresent";
import useHasPermissionToUpdateGrant from "../../../hooks/internal_tool/useHasPermissionToUpdateGrant";
import usePastGrantSearchResult from "../../../hooks/search/usePastGrantSearchResult";
import useFunderSearchResult from "../../../hooks/search/useFunderSearchResult";
import NPOSummary from "../../../components/NPOSummary";
import { titleize, toUsdLong } from "../../../utils/formatHelper";
import { renderRow } from "../../../utils/funder";
import { Divider } from "rsuite";
import TagFunderType from "../../../components/TagFunderType";
import RecPastGrantsFromSameFunder from "../../../components/RecPastGrantsFromSameFunder";
import useRateLimit from "../../../hooks/useRateLimit";
import FullScreenLoading from "../../../components/FullScreenLoading";
import SignInModal from "../../../components/SignInModal";
import { reprLocation } from "../../../types/location";
import Tag from "../../../components/Tag";
import { useSetRecoilState } from "recoil";
import { sidekickTargetId, sidekickTargetType } from "../../../app/recoilStore";
import { setSelectedQueryFunc } from "../../../utils/search";

interface GrantViewProps {
  withoutLogin?: boolean;
  edit?: boolean;
  compact?: boolean;
  showStickyHeader?: boolean;
  query?: SearchQuery;
  grantId?: string;
}
const PastGrantView = ({
  withoutLogin = false,
  edit = false,
  compact = false,
  query,
  grantId,
  showStickyHeader,
}: GrantViewProps) => {
  const { data: hasEditPermission } = useHasPermissionToUpdateGrant(edit, grantId);
  const { ok, isLoading: isRateLimitLoading, reset: resetRateLimit } = useRateLimit(withoutLogin);
  const { id: paramId } = useParams();
  const id = grantId || paramId;
  const [previousId, setPreviousId] = useState<string | undefined>(id);
  useLayoutEffect(() => {
    const parentContainerId = compact ? "search-result-main" : undefined;
    if (id !== previousId) {
      (parentContainerId ? document.getElementById(parentContainerId) : window)?.scrollTo(0, 0);
    }
    setPreviousId(id);
  }, [id]);
  const { state: context, pathname } = useLocation();
  const [onboardingData] = useOnboardingData();
  const [user] = useUser();
  const navigate = useNavigate();
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const { data: grant } = usePastGrantSearchResult(id);
  const { data: funder } = useFunderSearchResult(grant?.donor?._id);
  const [selectedQuery, setSelectedQuery] = useState<SearchQuery>(
    setSelectedQueryFunc(onboardingData, query, context, user),
  );
  const hasNoTaxonomy =
    selectedQuery.focus_area.length === 0 &&
    selectedQuery.beneficiary.length === 0 &&
    selectedQuery.program.length === 0 &&
    selectedQuery.hq_loc.length === 0 &&
    selectedQuery.service_loc.length === 0;
  useEffect(() => {
    setSelectedQuery(
      query
        ? query
        : context?.query
        ? context.query
        : getSearchQueryFromISearchParam(
            user?._id || "",
            onboardingData,
            getISearchParamFromOnboardingData(onboardingData),
          ),
    );
  }, [query, context, user, onboardingData]);
  // console.log(onboardingData.tax_exemption, selectedQuery?.service_loc, grant);
  const matched = grant
    ? matchTaxonomyBySearchQueryWithPhilanthropy(grant, selectedQuery, true, true)
    : undefined;

  const setTargetId = useSetRecoilState(sidekickTargetId);
  const setTargetType = useSetRecoilState(sidekickTargetType);
  useEffect(() => {
    setTargetId("");
    setTargetType(-1);
  }, []);
  // console.log(selectedQuery);
  // console.log("past grant", grant);
  const renderHeader = () => {
    return (
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          {grant && (
            <div className="inline-flex gap-2">
              <TagFunderType funder_type={grant.donor?.funder_type} />
              <TagGrantPresent grant={grant} />
            </div>
          )}
          <div className="inline-flex gap-0">
            {compact && (
              <PlainButton
                className="!m-0 min-w-[120px] !p-0 !text-sm !text-purple-500"
                label="Full view"
                handleOnClick={() => {
                  navigate(`/grants/past/${grant?._id}`, {
                    state: { query: selectedQuery, showBack: true },
                  });
                }}
                leftIconClass="gi-sm gi-open"
                id={`btn-view-full`}
              />
            )}
          </div>
        </div>
        <div className="whitespace-pre-line">
          <span className="text-xl font-semibold text-purple-500">
            <span className="mr-3">
              {grant?.grant_description ? titleize(grant?.grant_description) : "Untitled"}
            </span>
          </span>
        </div>
        {grant &&
          grant.donor &&
          renderRow(
            "Funder",
            <span
              className="mr-1 cursor-pointer text-sm font-semibold text-gray-900 underline underline-offset-2 hover:text-purple-500"
              onClick={() => {
                if (funder?._id) {
                  navigate(`/donors/${funder?._id}`);
                }
              }}
            >
              {grant.donor.name}
            </span>,
          )}
        {grant &&
          (grant.npo || grant.recipient_name) &&
          renderRow(
            "Recipient",
            <span
              className={classNames(
                "mr-1 text-sm font-semibold text-gray-900",
                grant.npo
                  ? "cursor-pointer underline underline-offset-2 hover:text-purple-500"
                  : "",
              )}
              onClick={() => {
                if (grant.npo?._id) {
                  navigate(`/npos/${grant.npo?._id}/`);
                }
              }}
            >
              {grant?.npo?.name || grant.recipient_name}
            </span>,
          )}
      </div>
    );
  };
  const renderSummary = () => {
    return (
      <div className="flex flex-col gap-4">
        {renderRow("Grant type", reprGrantFundingType(grant))}
        {grant?.npo?.address &&
          renderRow("Recipient location", grant.npo?.address || "No information available")}
        <div className={classNames("flex gap-4")}>
          <div
            className={classNames(
              "font-poppings flex w-full flex-col gap-2 rounded border border-gray-300  p-4",
            )}
          >
            <h5 className=" text-sm text-gray-700">Grant amount</h5>
            <h3 className="text-2xl">{grant ? toUsdLong(grant.grant_amount) : ""}</h3>
          </div>
          <div
            className={classNames(
              "font-poppings flex w-full flex-col gap-2 rounded border border-gray-300 p-4",
            )}
          >
            <h5 className="text-sm text-gray-700">Year granted</h5>
            <h3 className="text-2xl">{grant ? `${grant.grant_year}` || "" : ""}</h3>
          </div>
        </div>
        <div className="h-[1px] w-full bg-gray-300"></div>
        {!hasNoTaxonomy && (
          <PhilanthropicInformationFoldable
            className="!py-0"
            id={`matched-${grant?._id}`}
            title={`Matched tags`}
            globalOpen={true}
            arrowPosition="start"
            focusArea={matched?.focus_area?.filter((i) => i.matched)}
            beneficiary={matched?.beneficiary?.filter((i) => i.matched)}
            program={matched?.program?.filter((i) => i.matched)}
            location={matched?.service_loc?.filter((i) => i.matched)}
          />
        )}
        <PhilanthropicInformationFoldable
          className="!py-0"
          id={`unmatched-${grant?._id}`}
          title={`${!hasNoTaxonomy ? "Other tags" : "Tags"}`}
          initialShow={!hasNoTaxonomy ? undefined : 20}
          tagMatched={!hasNoTaxonomy ? false : true}
          arrowPosition="start"
          focusArea={matched?.focus_area?.filter((i) => !i.matched)}
          beneficiary={matched?.beneficiary?.filter((i) => !i.matched)}
          program={matched?.program?.filter((i) => !i.matched)}
          location={matched?.service_loc?.filter((i) => !i.matched)}
        />
      </div>
    );
  };
  const renderAny = (v: any, depth = 0, renderNotSpecified = true) => {
    if (Array.isArray(v) && v.length > 0) {
      return (
        <div className="flex flex-col gap-3">
          <ul
            className={classNames(
              "flex list-inside flex-col gap-1 text-sm",
              depth === 0 ? "list-disc" : "list-square",
            )}
          >
            {v?.map((t) => (
              <li key={t} className="ml-8 -indent-5">
                {t}
              </li>
            ))}
          </ul>
        </div>
      );
    } else if (typeof v === "string") {
      return (
        <div className="flex flex-col gap-3">
          <ul
            className={classNames(
              "flex list-inside flex-col gap-1 text-sm",
              depth === 0 ? "list-disc" : "list-square",
            )}
          >
            <li className="ml-8 -indent-5">{v}</li>
          </ul>
        </div>
      );
    } else if (typeof v === "object" && v !== null && !Array.isArray(v)) {
      if (Object.keys(v).length === 0) return renderNotSpecified ? <h5>Not specified</h5> : <></>;
      return (
        <div className="flex flex-col gap-3">
          <ul
            className={classNames(
              "flex list-inside flex-col gap-1 text-sm",
              depth === 0 ? "list-disc" : "list-square",
            )}
          >
            {Object.keys(v).map((key) => {
              return (
                <li key={key} className="">
                  {`${key}:`}
                  <div className="ml-8 -indent-5">
                    {renderAny(v[key], depth + 1, renderNotSpecified)}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else if (v && !Array.isArray(v)) {
      try {
        return (
          <div className="flex flex-col gap-3">
            <ul
              className={classNames(
                "flex list-inside flex-col gap-1 text-sm",
                depth === 0 ? "list-disc" : "list-square",
              )}
            >
              <li>{v}</li>
            </ul>
          </div>
        );
      } catch {
        return renderNotSpecified ? <h5>Not specified</h5> : <></>;
      }
    } else {
      return renderNotSpecified ? <h5>Not specified</h5> : <></>;
    }
  };

  const renderLeftContainer = () => {
    return (
      <div className="flex  w-full flex-col items-center justify-start  rounded border border-gray-300  bg-white">
        <div className="flex w-full select-none flex-col items-center   border-b border-gray-300 bg-white px-6 py-4 font-poppins ">
          <p className=" w-full font-poppins text-base font-normal ">Past grant of this funder</p>
        </div>
        <div className={classNames("flex max-h-[1200px] flex-col gap-2", scrollBarClass)}>
          {grant && funder?.uid && (
            <RecPastGrantsFromSameFunder exclude={[grant._id]} funder_uid={funder.uid} />
          )}
        </div>
      </div>
    );
  };
  if (withoutLogin) {
    if (isRateLimitLoading)
      return (
        <div className="flex h-full w-full items-center justify-center">
          <FullScreenLoading />
        </div>
      );
  }
  return (
    <>
      {withoutLogin && ok && <SignInModal showDefault={ok} reset={resetRateLimit} />}
      {compact && (
        <div
          className={classNames(
            "sticky top-0 z-[9] min-h-[30px] w-full border-b border-gray-300 bg-white px-5 py-3 shadow-[0px_4px_10px_0px_rgba(0,0,0,0.12)] transition-all ease-linear",
            showStickyHeader ? "block" : "hidden",
          )}
        >
          <div className="inline-flex w-full items-center justify-between">
            <p className="info-name max-w-[600px] whitespace-pre-line py-2 font-poppins text-base font-semibold text-purple-500">
              {grant?.grant_description ? titleize(grant?.grant_description) : "Untitled"}
            </p>
          </div>
        </div>
      )}
      <div className={classNames("flex w-full gap-5 rounded", compact ? "" : "my-10")}>
        <main className="w-full max-w-[800px] overflow-x-hidden overflow-y-hidden">
          {user && id && !grant ? (
            <div
              className="flex w-full flex-col items-center justify-center"
              style={{ height: "300px" }}
            >
              <div className="flex h-6 w-6 animate-spin items-center justify-center rounded-full bg-gradient-to-tr from-purple-500 to-transparent">
                <div className="h-4 w-4 rounded-full bg-gray-50"></div>
              </div>
            </div>
          ) : (
            <div
              className={classNames(
                "mx-auto flex w-full max-w-[800px] flex-col",
                !compact && "gap-4",
              )}
            >
              <div
                className={classNames(
                  "flex w-full flex-col gap-4  border-gray-300 bg-white p-5",
                  compact ? "rounded" : "rounded border",
                )}
              >
                {renderHeader()}
                <div className="h-[1px] w-full bg-gray-300"></div>
                {renderSummary()}
              </div>
              {grant?.npo?._id ? (
                <>
                  {compact && <Divider className={classNames("my-[20px]")} />}
                  <NPOSummary
                    wrapperClass={classNames(
                      "w-full p-5 border-gray-300 bg-white",
                      !compact && "rounded border",
                    )}
                    title={`About recipient`}
                    id={grant?.npo?._id}
                    showButton
                  />
                </>
              ) : grant?.recipient_name && grant?.service_loc && grant.service_loc?.length > 0 ? (
                <>
                  {compact && <Divider className={classNames("my-[20px]")} />}
                  <div
                    id={id}
                    className={classNames(
                      "flex flex-col gap-5",
                      "w-full border-gray-300 bg-white p-5",
                      !compact && "rounded border",
                    )}
                  >
                    <div className="flex items-start justify-between gap-5">
                      <h4 className="grow text-start text-black">{`About recipient` || ""}</h4>
                    </div>
                    <div className="flex items-center gap-2">
                      <h5>
                        {`Located `}
                        <span className="font-semibold">{reprLocation(grant.service_loc[0])}</span>
                      </h5>
                    </div>
                  </div>
                </>
              ) : null}
              {grant?.donor?._id ? (
                <>
                  {compact && <Divider className={classNames("my-[20px]")} />}
                  <FunderSummary
                    wrapperClass={classNames(
                      "w-full p-5 border-gray-300 bg-white",
                      !compact && "rounded border",
                    )}
                    title={`About funder`}
                    funder_id={grant?.donor?._id}
                    showButton
                    showFinancial={false}
                  />
                </>
              ) : null}
            </div>
          )}
        </main>
        {!compact && (
          <aside className="flex w-[384px] items-start justify-center">
            {renderLeftContainer()}
          </aside>
        )}
      </div>
    </>
  );
};

export default PastGrantView;
