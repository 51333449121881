import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import classNames from "classnames";
import useNPOGrants from "../../../../hooks/useNPOGrants";
import { toUsdLong } from "../../../../utils/formatHelper";
import TopSupporterCard from "../../../../components/TopSupporterCard";
import { scrollBarClass } from "../../../../utils/classes";
import PastAwardCard from "../../../../components/PastAwardCard";
import PublicPrivateButton from "../../../../components/PublicPrivateButton";
import { UserView } from "../NpoLayout";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import useOrgDataForNPODashboard from "../../../../hooks/useOrgDataForNPODashboard";
import IMG_SUPPORTERS from "../../../../assets/images/top-supporters.svg";
import IMG_RANGE from "../../../../assets/images/people-no-info.svg";
import IMG_PAST_AWARDS from "../../../../assets/images/no-past-award.svg";
import GotoSmartSearchContext from "../../../../components/useGotoSmartSearch";
import Button from "../../../../components/tailwind/Button";
import GibooGradientDiv from "../../../../components/GibooGradientDiv";
import PopupModal from "../../../../components/tailwind/PopupModal";
import MemoizedDropArea from "../../../../components/input/FileDropArea";
import { uploadFileService } from "../../../../services/file-upload/upload.service";
import { getIRS990Key } from "../../../../utils/media";
import GibooToast from "../../../../components/GibooToast";
import { ClickToScrollWrapper } from "../../../../components/ClickToScrollWrapper";
import Spacer from "../../../../components/Spacer";
import UploadIRSModal from "../../../../components/UploadIRSModal";
import Spinner from "../../../../components/Spinner";
import ContentHideWrapper from "../../../../components/ContentHideWrapper";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";

function NpoContributionGrants() {
  const { id } = useParams();
  const { isPrivateView, hasWritePermission } = useOutletContext<UserView>();
  return (
    <NpoContribution
      hasWritePermission={hasWritePermission}
      isPrivateView={isPrivateView}
      id={id}
    />
  );
}
export default NpoContributionGrants;

export function NpoContribution({
  isPrivateView,
  hasWritePermission,
  id,
  compact = false,
  withoutLogin,
  isProfileClaim,
}: {
  isPrivateView?: boolean;

  withoutLogin?: boolean;
  isProfileClaim?: boolean;
  hasWritePermission?: boolean;
  id?: string;
  compact?: boolean;
}) {
  const { org_id, npo_id } = useOrgDataForNPODashboard(id, isPrivateView);
  const { isLoading, topSupporters, supporters, groupByYears, statistic } = useNPOGrants(npo_id);
  const { data: profile, update: profileUpdate } = useOrgProfile(org_id);
  const [years, setYears] = useState<string[]>(Object.keys(groupByYears).sort((a, b) => +b - +a));
  const [selectedYear, setSelectedYear] = useState<string>(years[0]);
  const [isPrivateGrantRange, setIsPrivateGrantRange] = useState<boolean>(false);
  const [isPrivatePastAwards, setIsPrivatePastAwards] = useState<boolean>(false);
  const [showUploadIRS, setShowUploadIRS] = useState<boolean>(false);
  const [blurPastGrants, setBlurPastGrants] = useState(false);
  useEffect(() => {
    if (isLoading) return;
    const YEARS = Object.keys(groupByYears).sort((a, b) => +b - +a);
    if (YEARS.length > 0) {
      setYears(YEARS);
      setSelectedYear(YEARS[0]);
    } else {
      setYears([]);
      setSelectedYear("");
    }
  }, [groupByYears, isLoading]);
  useEffect(() => {
    if (!profile) return;
    setIsPrivateGrantRange(profile.hide_grant_range ? true : false);
    setIsPrivatePastAwards(profile.hide_past_awards ? true : false);
  }, [profile]);

  const renderTopSupporters = () => {
    let data: any[] = [];
    if (supporters.length > 4) {
      data = supporters.slice(0, 4);
    } else {
      data = supporters;
    }
    const middleIndex = Math.ceil(data.length / 2);
    const firstHalf = data.slice(0, middleIndex);
    const secondHalf = data.slice(middleIndex);
    const renderWithoutLoginContent = () => {
      return (
        <>
          {firstHalf.map((i, index) => (
            <TopSupporterCard
              data={i}
              key={"top_supporter_" + index}
              topSupporter={topSupporters.includes(i.donor_id)}
              showSaveToProject={false}
            />
          ))}
          {secondHalf.length > 0 && (
            <ContentHideWrapper
              open
              id="top-supporter_funders"
              messageTitle="Interested in seeing which funders supported this organization?"
              messageDescription="Click here to unlock the funder information for free."
            >
              {secondHalf.map((i, index) => (
                <TopSupporterCard
                  data={i}
                  key={"top_supporter_" + index}
                  topSupporter={topSupporters.includes(i.donor_id)}
                  showSaveToProject={false}
                />
              ))}
            </ContentHideWrapper>
          )}
        </>
      );
    };
    return (
      <div className={classNames("flex w-full flex-col gap-4")}>
        <div className="w-full border-b border-gray-300">
          <p className="text-base font-semibold text-black underline underline-offset-[6px]">
            Funders
          </p>
        </div>
        {withoutLogin === true && supporters.length > 0 ? (
          renderWithoutLoginContent()
        ) : supporters.length > 0 ? (
          <ClickToScrollWrapper disabled={supporters.length <= 2}>
            <div
              className={classNames(
                "flex max-h-[450px] flex-col gap-4 overflow-y-scroll",
                supporters.length > 2 && scrollBarClass,
              )}
            >
              {supporters.map((i, index) => (
                <TopSupporterCard
                  data={i}
                  key={"top_supporter_" + index}
                  topSupporter={topSupporters.includes(i.donor_id)}
                  showSaveToProject={false}
                />
              ))}
            </div>
          </ClickToScrollWrapper>
        ) : (
          <GotoSmartSearchContext type={"funder"} project_id={undefined}>
            {({ go, isLoading }) => (
              <div className="grid h-[239px] w-full place-items-center">
                <img src={IMG_SUPPORTERS} className="h-[124px] w-[132px]" />
                <p className="mt-2 font-semibold">No information available</p>
                {isLoading ? (
                  <Spinner size="sm" />
                ) : isPrivateView ? (
                  <h5 className="mt-1 text-center font-poppins text-sm font-normal text-gray-900">
                    Your organization currently has no funder.
                    <br />{" "}
                    <span className="cursor-pointer text-purple-500" onClick={() => go()}>
                      Click here
                    </span>{" "}
                    to find new funders.
                  </h5>
                ) : (
                  <h5>{`This organization currently has no funders.`}</h5>
                )}
              </div>
            )}
          </GotoSmartSearchContext>
        )}
      </div>
    );
  };
  const grpByYears = selectedYear && groupByYears[selectedYear as unknown as number];
  const renderPastAwards = () => {
    return (
      <>
        <Spacer height="40px" />
        <div className={classNames("flex w-full flex-col gap-4")}>
          <div className="flex w-full justify-between">
            <div className="w-full border-b border-gray-300">
              <p className="text-base font-semibold text-black underline underline-offset-[6px]">
                Past grants and contribution awarded
              </p>
            </div>
            {/* {isPrivateView && hasWritePermission && (
              <PublicPrivateButton
                id="private-past-awards"
                handleOnClick={() => {
                  profileUpdate({ hide_past_awards: !isPrivatePastAwards });
                  setIsPrivatePastAwards(!isPrivatePastAwards);
                }}
                isPrivate={isPrivatePastAwards}
              />
            )} */}
          </div>

          {years.length > 0 ? (
            <>
              <ClickToScrollWrapper disabled={grpByYears?.length <= 1}>
                <div className="inline-flex w-full gap-5 border-b border-gray-300">
                  {years.map((year, index) => {
                    return (
                      <span
                        key={index}
                        className={classNames(
                          "w-fit cursor-pointer pb-[5px] font-poppins  text-sm text-gray-700 ",
                          {
                            "font-semibold text-purple-500 underline underline-offset-[10.5px]":
                              selectedYear === year,
                          },
                        )}
                        onClick={() => {
                          setSelectedYear(year);
                          if (withoutLogin === true) {
                            index > 1 ? setBlurPastGrants(true) : setBlurPastGrants(false);
                          }
                        }}
                      >
                        {year}
                      </span>
                    );
                  })}
                </div>
                <Spacer height="16px" />
                <ContentHideWrapper
                  open={blurPastGrants}
                  id="past-grant-contributions"
                  messageTitle="Interested in exploring the organization’s past grants and contributions?"
                  messageDescription="Click here to unlock the information for free."
                >
                  <div
                    className={classNames(
                      "flex max-h-[590px] flex-col gap-4 overflow-y-scroll",
                      scrollBarClass,
                    )}
                  >
                    {selectedYear &&
                      groupByYears[selectedYear as unknown as number]?.map((i, index) => (
                        <PastAwardCard
                          year={selectedYear}
                          data={i}
                          key={"top_supporter_" + index}
                          topSupporter={topSupporters.includes(i.donor_id)}
                        />
                      ))}
                  </div>
                </ContentHideWrapper>
              </ClickToScrollWrapper>
            </>
          ) : (
            <div className="grid h-[239px] w-full place-items-center">
              <img src={IMG_PAST_AWARDS} className="h-[124px] w-[132px]" />
              <p className="mt-2 font-semibold">No information available</p>
              {isPrivateView && hasWritePermission ? (
                <h5 className="mt-1 text-center font-poppins text-sm font-normal text-gray-900">
                  <span
                    className="cursor-pointer text-purple-500"
                    onClick={() => {
                      setShowUploadIRS(true);
                    }}
                  >
                    Click here
                  </span>{" "}
                  to upload IRS 990 for analysis
                </h5>
              ) : (
                <h5></h5>
              )}
            </div>
          )}
        </div>
      </>
    );
  };
  const renderGrantRange = () => {
    const { avg, max, median, min } = statistic;
    const minValue = (min / max) * 100;
    const medianValue = (median / max) * 100;
    const avgValue = (avg / max) * 100;
    const maxValue = 100;
    return (
      <>
        <Spacer height="40px" />
        <div className={classNames("flex w-full flex-col gap-4")}>
          <div className="flex justify-between">
            <div className="w-full border-b border-gray-300">
              <p className="text-base font-semibold text-black underline underline-offset-[6px]">
                Grant amount range
              </p>
            </div>
            {/* {isPrivateView && hasWritePermission && (
              <PublicPrivateButton
                id="private-grant-range"
                handleOnClick={() => {
                  profileUpdate({ hide_grant_range: !isPrivateGrantRange });
                  setIsPrivateGrantRange(!isPrivateGrantRange);
                }}
                isPrivate={isPrivateGrantRange}
              />
            )} */}
          </div>
          {statistic.from > 0 ? (
            <>
              <div className="relative mt-4 h-6 w-full rounded-full bg-gray-100">
                {minValue !== null && minValue !== maxValue && (
                  <>
                    <div
                      className="absolute top-0 h-6 w-[9px]  transform  bg-purple-50"
                      style={{ left: `${minValue}%` }}
                    />
                  </>
                )}
                {medianValue !== null && minValue !== maxValue && (
                  <>
                    <div
                      className="absolute top-0 h-6 w-[9px]  transform  bg-purple-100"
                      style={{ left: `${medianValue}%` }}
                    />
                  </>
                )}
                {avgValue !== null && minValue !== maxValue && (
                  <>
                    <div
                      className="absolute top-0 h-6 w-[9px] transform  bg-purple-300"
                      style={{ left: `${avgValue}%` }}
                    />
                  </>
                )}
                {maxValue !== null && (
                  <>
                    <div
                      className="absolute top-0 h-6 w-[9px] -translate-x-2 transform  bg-purple-500"
                      style={{ left: `${maxValue}%` }}
                    />
                  </>
                )}
                <div className="absolute top-0 z-[8] h-6 w-full rounded-full outline outline-8 outline-offset-0 outline-white"></div>
                <div className="absolute top-0 z-[9] h-6 w-full rounded-full border border-gray-300"></div>
              </div>
              <div className="mt-3 grid grid-cols-2 gap-4 self-center rounded border border-gray-300 p-3 md:w-2/3">
                <div className="flex items-start gap-2">
                  <span className="h-5 w-[9px] transform  rounded-sm  bg-purple-50" />
                  <div className="mt-[2px] flex flex-col items-start gap-2">
                    <h5 className="text-xs font-light text-black">{toUsdLong(min)}</h5>
                    <h6 className="text-xs font-light text-gray-700">
                      Minimum grant amount received
                    </h6>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span className="h-5 w-[9px] transform  rounded-sm  bg-purple-100" />
                  <div className="mt-[2px] flex flex-col items-start gap-2">
                    <h5 className="text-xs font-light text-black">{toUsdLong(median)}</h5>
                    <h6 className="text-xs font-light text-gray-700">
                      Median grant amount received
                    </h6>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span className="h-5 w-[9px] transform  rounded-sm  bg-purple-300" />
                  <div className="mt-[2px] flex flex-col items-start gap-2">
                    <h5 className="text-xs font-light text-black">{toUsdLong(avg)}</h5>
                    <h6 className="text-xs font-light text-gray-700">
                      Average grant amount received
                    </h6>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <span className="h-5 w-[9px] transform  rounded-sm  bg-purple-500" />
                  <div className="mt-[2px] flex flex-col items-start gap-2">
                    <h5 className="text-xs font-light text-black">{toUsdLong(max)}</h5>
                    <h6 className="text-xs font-light text-gray-700">
                      Maximum grant amount received
                    </h6>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="grid h-[239px] w-full place-items-center">
              <img src={IMG_RANGE} className="h-[124px] w-[132px]" />
              <p className="mt-2 font-semibold">No information available</p>
              {isPrivateView && hasWritePermission ? (
                <h5 className="mt-1 text-center font-poppins text-sm font-normal text-gray-900">
                  <span
                    className="cursor-pointer text-purple-500"
                    onClick={() => {
                      setShowUploadIRS(true);
                    }}
                  >
                    Click here
                  </span>{" "}
                  to upload IRS 990 for analysis
                </h5>
              ) : (
                <h5></h5>
              )}
            </div>
          )}
        </div>
      </>
    );
  };
  return (
    <div
      className={classNames(
        "flex flex-col border-gray-300 bg-white p-5",
        !compact && "rounded border",
      )}
    >
      {showUploadIRS && org_id && (
        <UploadIRSModal open={showUploadIRS} setOpen={setShowUploadIRS} />
      )}
      <h4 className="font-poppins text-xl font-semibold text-gray-900">Contribution and grants</h4>
      <Spacer height="16px" />
      {isLoading ? (
        <MemoizedLoadingRecommendedAction loading={isLoading} hidePercentage short />
      ) : (
        <div className={classNames(" grid grid-cols-2 gap-5")}>
          <div className="flex flex-col rounded border border-gray-300 bg-white p-4">
            <h5>{`Total number of foundation`}</h5>
            <h5>
              funders
              {statistic.to ? (
                <>
                  {" "}
                  from <b>{statistic.from}</b>
                </>
              ) : (
                ""
              )}
              {statistic.to && statistic.from !== statistic.to ? <b>-{statistic.to}</b> : ""}:
            </h5>
            <h3 className="mt-3 text-xl text-purple-500">{`${statistic.totalNumberOfFunder.toLocaleString()} funders`}</h3>
          </div>
          <div className="flex flex-col rounded border border-gray-300 bg-white p-4">
            <h5>{`Total foundation grant amount`}</h5>
            <h5>
              raised
              {statistic.from ? (
                <>
                  {" "}
                  from <b>{statistic.from}</b>
                </>
              ) : (
                ""
              )}
              {statistic.to && statistic.from !== statistic.to ? <b>-{statistic.to}</b> : ""}:
            </h5>
            <h3 className="mt-3 text-xl text-purple-500">{`${toUsdLong(statistic.sum)}`}</h3>
          </div>
        </div>
      )}
      <Spacer height="40px" />
      {isLoading ? (
        <MemoizedLoadingRecommendedAction loading={isLoading} hidePercentage />
      ) : (
        <>{renderTopSupporters()}</>
      )}
      {isLoading ? (
        <MemoizedLoadingRecommendedAction loading={isLoading} hidePercentage short />
      ) : isPrivateView ? (
        renderGrantRange()
      ) : (
        !profile?.hide_grant_range && renderGrantRange()
      )}
      {isLoading ? (
        <MemoizedLoadingRecommendedAction loading={isLoading} hidePercentage />
      ) : isPrivateView ? (
        renderPastAwards()
      ) : (
        !profile?.hide_past_awards && renderPastAwards()
      )}
    </div>
  );
}

const FunderContributionMock = () => {
  return <></>;
};
