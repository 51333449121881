import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../api";
import { Onboarding } from "../app/onboarding";
import { useCallback, useEffect, useState } from "react";
import { swrOptionFetchOnlyOnMount } from "../types/swr";
import useOrgs from "./useOrgs";
import useEINFromSearchParams from "./useEINFromSearchParams";
interface useOrgState {
  data?: string[];
  isLoading?: boolean;
  error?: object;
  isValidating: boolean;
  revalidate: () => Promise<string[] | undefined>;
}
function useOrgSearchQuery(): useOrgState {
  const { selected } = useOrgs();
  const { ein } = useEINFromSearchParams();
  const { mutate } = useSWRConfig();
  const [maxTry, setMaxTry] = useState<number>(20);
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const url = selected
    ? process.env.REACT_APP_API_URL + `/api/v2/orgs/searchquery?org_id=${selected}`
    : ein
    ? process.env.REACT_APP_API_URL + `/api/v2/npos/searchquery?ein=${ein}`
    : null;
  const { data, isLoading, error, isValidating } = useSWR<string[]>(
    url,
    fetch,
    swrOptionFetchOnlyOnMount,
  );

  useEffect(() => {
    if (data && data.length === 0) {
      if (maxTry > 0) {
        setMaxTry((prev) => prev - 1);
        setTimeout(() => {
          revalidate();
        }, (7 - maxTry / 5) * 1000);
      }
    }
  }, [data]);
  const revalidate = useCallback(() => mutate<string[]>(url), [url]);
  // console.log(data);
  return {
    data: (selected || ein) && data ? data : undefined,
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useOrgSearchQuery;
