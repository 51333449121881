import { useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useScrollToHashElement = (behavior: "auto" | "smooth" = "smooth", dependency?: any) => {
  const location = useLocation();
  const [hashElement, setHashElement] = useState<HTMLElement | null>();

  useEffect(() => {
    const hash = location.hash;
    const removeHashCharacter = (str: string) => {
      const result = str.slice(1);
      return result;
    };
    setHashElement(document.getElementById(removeHashCharacter(hash)));
  }, [location, dependency]);

  useEffect(() => {
    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: behavior,
        block: "center",
        inline: "nearest",
      });
    }
  }, [hashElement]);

  return null;
};

export default useScrollToHashElement;
