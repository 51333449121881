import classNames from "classnames";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import useFunderSearchResult from "../hooks/search/useFunderSearchResult";
import useFunderSummary from "../hooks/useFunderSummary";
import PlainButton from "./PlainButton";
// export interface FunderSummary {
//     mission_and_vision?: string;
//     giving_philosophy_and_values?: string;
//     philanthropic_focuses?: string;
//     beneficiaries?: string;
//     headquater_location?: string;
//     financial_information?: string;
//     [key: string]: string | undefined;
//   }
export default function FunderSummary({
  funder_id,
  title = "About funder",
  showButton = false,
  showFinancial = false,
  compact = false,
  id,
  renderComponent,
  ...props
}: {
  id?: string;
  funder_id: string;
  title?: string;
  wrapperClass?: string;
  showButton?: boolean;
  showFinancial?: boolean;
  compact?: boolean;
  renderComponent?: () => ReactNode;
}) {
  const navigate = useNavigate();
  const { data: summary, isLoading } = useFunderSummary(funder_id);
  const { data: funder } = useFunderSearchResult(funder_id);
  const renderLoading = () => {
    return (
      <div
        className={classNames("flex w-full flex-wrap ", {
          "loading-light-shimmer": !isLoading,
          "loading-light-shimmer-on gap-4": isLoading,
        })}
      >
        <div className="loading-light-shimmer-child flex w-full flex-col gap-3 px-[28px] py-[16px]">
          <div className="h-[21px] w-[500px]" />
          <div className="h-[21px] w-[560px]" />
          <div className="h-[21px] w-[430px]" />
          <div className="h-[21px] w-[520px]" />
          <div className="h-[21px] w-[300px]" />
        </div>
      </div>
    );
  };
  const renderAny = (a: any) => {
    if (typeof a === "string") return a;
    else return JSON.stringify(a);
  };
  return (
    <div id={id} className={classNames("flex flex-col gap-5", props.wrapperClass)}>
      <div className="flex items-start justify-between gap-5">
        <h4 className="grow text-start text-black">{title}</h4>
        {showButton && (
          <p onClick={() => navigate(`/donors/${funder?._id}`)}>
            <PlainButton
              className="!m-0 min-w-[120px] whitespace-nowrap !p-0 !text-sm !text-purple-500"
              label="View funder detail"
              handleOnClick={() => {
                //   navigate(`/donors/${funder_id}`);
              }}
              leftIconClass="gi-sm gi-open"
              id={`btn-go-${funder_id}`}
            />
          </p>
        )}
      </div>
      {renderComponent?.()}
      {isLoading ? (
        <>{renderLoading()}</>
      ) : !summary ? (
        <p className="self-center py-12">No information available</p>
      ) : null}
      {summary?.mission_and_vision && <h5>{renderAny(summary.mission_and_vision)}</h5>}
      {summary?.giving_philosophy_and_values && (
        <>
          <div>
            <div className="mb-2 border-b border-gray-300">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">
                Philosophy of giving
              </h5>
            </div>
            <h5>{renderAny(summary.giving_philosophy_and_values)}</h5>
          </div>
        </>
      )}
      {/* {summary?.philanthropic_focuses && (
        <>
          <div>
            <div className="mb-2 border-b border-gray-300">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">
                Philanthropic focus
              </h5>
            </div>
            <h5>{renderAny(summary.philanthropic_focuses)}</h5>
          </div>
        </>
      )}
      {summary?.beneficiaries && (
        <>
          <div>
            <div className="mb-2 border-b border-gray-300">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">Beneficiary</h5>
            </div>
            <h5>{renderAny(summary.beneficiaries)}</h5>
          </div>
        </>
      )}
      {summary?.headquarter_location && (
        <>
          <div>
            <div className="mb-2 border-b border-gray-300">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">
                Address
              </h5>
            </div>
            <h5>{renderAny(summary.headquarter_location)}</h5>
          </div>
        </>
      )} */}
      {/* {summary?.financial_information && showFinancial && (
        <>
          <div>
            <div className="mb-2 border-b border-gray-300">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">
                Financial information
              </h5>
            </div>
            <h5>{renderAny(summary.financial_information)}</h5>
          </div>
        </>
      )} */}
      {/* {summary &&
        Object.keys(summary)
          .filter(
            (key) =>
              ![
                "mission_and_vision",
                "giving_philosophy_and_values",
                "philanthropic_focuses",
                "beneficiaries",
                "headquarter_location",
                "financial_information",
              ].includes(key),
          )
          .map((key) => (
            <div key={key}>
              <h5 className="font-semibold">
                {key[0].toUpperCase()}
                {snakeToCamelCase(key.slice(1)).toLowerCase()}
              </h5>
              <h5>{renderAny(summary[key])}</h5>
            </div>
          ))} */}

      {/* wealth information  */}
      {/* {showFinancial && (
        <div>
          <div className="mb-2 border-b border-gray-300">
            <h5 className="text-base font-semibold underline underline-offset-[6px]">Wealth information</h5>
          </div>
          <FunderWealthInformation funder_id={funder_id} compact={compact} />
        </div>
      )} */}
    </div>
  );
}
