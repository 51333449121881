import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useScrollTopByDefault(elementId = "") {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, elementId]);
}
