import classNames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import { Divider, Steps } from "rsuite";
import Button from "../../../../components/tailwind/Button";
import Spacer from "../../../../components/Spacer";
import IMG_EXAMPLE from "../../../../assets/financial/month-of-liquid-placeholder.svg";
import IncreaseTag from "../component/IncreaseTag";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import LargestProportionTag from "../component/LargestProportionTag";
import DecreaseTag from "../component/DecreaseTag";
import SmallestProportionTag from "../component/SmallestProportionTag";
import useNPOIRSProfile from "../../../../hooks/useNPOIRSProfile";
import NpoFinancialMonthLiquid, {
  MonthOfLiquidChart,
} from "../../dashboard/components/NpoFinancialMonthLiquid";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import CloseButton from "../../../../components/CloseButton";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";

export default function MonthOfLiquid({
  onBack,
  onNext,
  npo_id,
  org_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  org_id?: string;
  isBack?: boolean;
}) {
  const [currentStep, setCurrentStep] = useState<number>(isBack ? 1 : 0);
  useEffect(() => {
    if (isBack) {
      setTimeout(() => {
        scrollToElement("businessmodel-step-2");
      }, 300);
    }
  }, [isBack]);
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value >= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };
  const scrollToElement = (id: string) => {
    const element = document?.getElementById(id);
    const container = document.getElementById("financial-scroll-container");
    if (element && container) {
      const elementTop = element.getBoundingClientRect().top + container.scrollTop;
      const adjustedOffset = elementTop - 120;
      container.scrollTo({ top: adjustedOffset, behavior: "smooth" });
    }
  };
  const renderStep1 = () => {
    return (
      <MonthLiquidInfo>
        <div
          className={classNames(
            "flex h-[38px] items-center justify-start gap-2",
            currentStep === 0 ? "flex" : "hidden",
          )}
        >
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              if (currentStep < 1) {
                setCurrentStep(1);
                setTimeout(() => {
                  scrollToElement("businessmodel-step-2");
                }, 300);
              }
            }}
            label="See the assessment result"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
        <Spacer height="100px" />
      </MonthLiquidInfo>
    );
  };

  return (
    <div className="flex max-w-[760px] flex-col">
      <Steps current={currentStep} vertical>
        <Steps.Item
          title={false}
          className="!-ml-[40px] !mt-0"
          icon={currentStep > 0 ? renderDot(1) : <></>}
          description={renderStep1()}
        />
        {currentStep > 0 && (
          <Steps.Item
            icon={renderDot(2)}
            className="!-ml-[40px]"
            description={
              <Step2
                onBack={() => {
                  setCurrentStep(0);
                }}
                npo_id={npo_id}
                org_id={org_id}
                onNext={() => {
                  onNext?.();
                }}
              />
            }
          />
        )}
      </Steps>
    </div>
  );
}
const Step2 = ({
  onBack,
  onNext,
  npo_id,
  org_id,
  recomendation = true,
}: {
  onBack: () => void;
  onNext: () => void;
  npo_id?: string;
  org_id?: string;
  recomendation?: boolean;
}) => {
  const { data, update } = useOrgProfile(org_id);
  const prev = data?.skip_loading || [];
  const mxEvent = useGibooMixpanel(undefined, true);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.financial.month_of_liquid.successful);
  }, []);
  return (
    <div className="mt-3 flex min-h-[600px] flex-col gap-4" id="businessmodel-step-2">
      <h6 className="text-base font-semibold text-gray-900">
        Your organization&apos;s assessment result
      </h6>
      <NpoFinancialMonthLiquid
        npo_id={npo_id}
        org_id={org_id}
        recomendation
        showChartLoader={!data.skip_loading?.includes("financial_month_of_liq")}
      >
        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              data &&
                update({
                  skip_loading: [
                    ...prev.filter((p) => p !== "financial_month_of_liq"),
                    "financial_month_of_liq",
                  ],
                });
              onNext?.();
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </NpoFinancialMonthLiquid>
      <Spacer height="150px" />
    </div>
  );
};

export function MonthLiquidInfo({
  children,
  handleClose,
}: {
  children?: ReactNode;
  handleClose?: () => void;
}) {
  return (
    <div className={classNames("flex min-h-[600px] flex-col gap-4", !handleClose && "mt-3")}>
      <div className="inline-flex w-full items-center justify-between border-b border-gray-300">
        <h6 className="text-base font-semibold text-gray-900 underline underline-offset-[6px]">
          Working capital: Month of liquid
        </h6>
        {handleClose && <CloseButton onClick={() => handleClose?.()} />}
      </div>
      <div className="flex min-h-[500px] flex-col rounded border border-gray-300 bg-white p-5">
        {/* section 1 */}
        <div className="flex flex-col gap-10">
          <div>
            <h5 className="font-poppins text-base font-semibold text-black">
              What is working capital: Month of liquid?
            </h5>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800">
              Month of Liquid, particularly associated with Liquid Unrestricted Net Assets (LUNA),
              represents a critical metric in assessing a nonprofit organization&apos;s short-term
              financial resilience. Unrestricted net assets, in this context, pertain to the portion
              of an organization&apos;s net assets that is not encumbered by donor-imposed
              restrictions. These funds are essentially free from specific directives and can be
              utilized for the organization&apos;s general operating expenses and activities.
              <br />
              <br />
              Month of liquid is essentially a report that shows a nonprofit organization’s
              liquidity and cash flow position over a specific time frame. It measures your
              nonprofit’s ability to cover its short-term liabilities with its available liquid
              assets. In essence, it provides a comprehensive understanding of how well the
              organization can meet its financial obligations and operational needs without relying
              on long-term funding or borrowing. This plays a pivotal role in ensuring that the
              nonprofit can sustain its day-to-day operations, respond to unforeseen expenses, and
              maintain financial stability in the short term.
            </h6>
          </div>
          <div className="flex min-h-[330px] justify-center">
            <img src={IMG_EXAMPLE} className="h-fit w-fit" alt="example" />
          </div>
        </div>
        <Spacer height="16px" />
        {/* section 2 */}
        <div className="flex flex-col">
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Liquid</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              In the financial context, liquidity refers to the ease with which an asset can be
              converted into cash or near-cash instruments. Liquid assets are readily available for
              use and can be quickly mobilized.
              <br />
              <br />
              Liquid assets provide the organization with the agility to respond promptly to
              financial needs, emergencies, or opportunities, ensuring that it can meet its
              short-term obligations efficiently.
            </h6>
          </div>
        </div>
      </div>
      <div className="flex min-h-[100px] flex-col rounded border border-gray-300 bg-white p-5">
        <div>
          <h5 className="font-poppins text-base font-semibold text-black">
            Why is working capital: Month of liquid important?
          </h5>
          <Spacer height="10px" />
          <h6 className="font-poppins text-sm text-gray-800">
            Month of Liquid is a crucial financial metric that holds significant importance for
            nonprofit organizations. This metric assesses the organization&apos;s ability to cover
            its monthly expenses with its existing liquid assets. The importance lies in its role as
            a barometer for short-term financial resilience. By understanding how many months of
            operational expenses can be sustained solely from liquid assets, nonprofits gain
            insights into their capacity to weather unforeseen challenges, respond to immediate
            needs, and maintain financial stability.
            <br />
            <br />
            This metric is invaluable for strategic financial planning, risk mitigation, and
            informed decision-making. It provides a tangible measure of the organization&apos;s
            flexibility in managing day-to-day operations without over-reliance on external funding
            sources, ensuring adaptability and sustainability in the dynamic landscape of nonprofit
            finance.
          </h6>
        </div>
      </div>
      {children && children}
    </div>
  );
}
