export enum DocumentSelectionTypeEnum {
  RADIO = "radio",
  RADIO_OR_TEXT = "radio_or_text",
  TEXT = "text",
}
// type DocumentSelectionType =
//   | DocumentSelectionTypeEnum.RADIO
//   | DocumentSelectionTypeEnum.RADIO_OR_TEXT
//   | DocumentSelectionTypeEnum.TEXT;
const UNKNOWN = "UNKNOWN";
export enum DocumentActionTypeEnum {
  GREETING = 0,
  CHOOSE_TARGET = 1,
  CHOOSE_PREVIOUS_LETTER = 2,
  ASK_TO_WANT_TO_ADD = 3,
  TEXT = 4,
  TEXT2 = 5,
  CHOOSE_RECIPIENT = 7,
}
const actionTypeEnumStrs = [
  "GREETING",
  "CHOOSE_TARGET",
  "CHOOSE_PREVIOUS_LETTER",
  "ASK_TO_WANT_TO_ADD",
  "TEXT",
  "TEXT2",
  "UNKNOWN",
  "CHOOSE_RECIPIENT",
];
export const reprDocumentActionTypeEnum = (i: number | undefined | null) =>
  i === undefined || i === null || i < 0 || i >= actionTypeEnumStrs.length
    ? UNKNOWN
    : actionTypeEnumStrs[i];

export enum ProjectInformationEnum {
  PROJECT_INFORMATION_NAME = 10,
  PROJECT_INFORMATION_TIMELINE = 11,
  PROJECT_INFORMATION_GRANT_AMOUNT = 12,
  PROJECT_INFORMATION_FOCUS_AREA = 13,
  PROJECT_INFORMATION_BENEFICIARY = 14,
  PROJECT_INFORMATION_PROGRAM = 15,
  PROJECT_INFORMATION_SERVICE_AREA = 16,
  PROJECT_INFORMATION_OUTPUT = 17,
  PROJECT_INFORMATION_IMPACT = 18,
  PROJECT_INFORMATION_BUDGET = 19,
  PROJECT_INFORMATION_MEASUREMENT = 20,
  PROJECT_INFORMATION_COLLABORATION = 21,
}
export enum DocumentViewTypeEnum {
  LETTER = "letter",
  TARGET = "target",
  TEXT = "text",
  RECIPIENT = "recipient",
}

export enum DocumentOperatorEnum {
  TARGET = "target",
  FETCH = "fetch",
  PREVIOUS_ANSWER = "previous_answer",
  SIZE = "size",
  EQUAL = "eq",
  NEQUAL = "ne",
}
const nodeTypeEnumStrs = [
  "BOT",
  "USER",
  "CUSTOM_QUESTION",
  "ANSWER_TO_CUSTOM_QUESTION",
  "USER_CORRECTION",
  "USER_ASKING_INFO_COMMAND",
  "ERROR",
];
export const reprDocumentNodeTypeEnum = (i: number | undefined | null) =>
  i === undefined || i === null || i < 0 || i >= nodeTypeEnumStrs.length
    ? UNKNOWN
    : nodeTypeEnumStrs[i];

export enum DocumentTypeEnum {
  BASIC = 0,
  OUTREACHING_PHONE_CALL_SCRIPT = 1,
  OUTREACHING_LOI = 2,
  OUTREACHING_INTRODUCTORY_EMAIL = 3,
  OUTREACHING_INTRODUCTORY_LINKEDIN_MSG = 4,

  OUTREACHING_ANNUAL_GIVING_APPEAL = 11,
  OUTREACHING_FOLLOWUP_LETTER_TO_NO_RESPONSE = 12,
  OUTREACHING_REMINDER_LETTER = 13,
  OUTREACHING_FOLLOWUP_LETTER_TO_POSITIVE = 14,
  OUTREACHING_FOLLOWUP_LETTER_TO_NEGATIVE = 15,

  OUTREACHING_LETTER_TO_AN_OLD_FUNDER = 21,
  OUTREACHING_ACK_LETTER_FOR_IN_KIND_DONATION = 22,
  OUTREACHING_EOY_GIVING_APPEAL_TO_AN_OLD_FUNDER = 23,
  OUTREACHING_EOY_GIVING_APPEAL_TO_A_NEW_FUNDER = 24,

  CUSTOM = 41,

  GRANT_WRITING_PROPOSAL = 51,

  REPORTING_THANK_YOU_FOR_GRANT_AWARD = 71,
  REPORTING_PROGRESS = 72,
}

export enum DocumentNodeType {
  BOT = 0,
  USER = 1,
  CUSTOM_QUESTION = 2,
  ANSWER_TO_CUSTOM_QUESTION = 3,
  USER_CORRECTION = 4,
  USER_ASKING_INFO_COMMAND = 5,
  ERROR = 6,
}

interface DocumentFetchDefinition {
  table: string;
  values: string[];
  pks: string[];
  join: string[];
}

export interface DocumentActionDefinition {
  type: DocumentActionTypeEnum;
  selection_type: DocumentSelectionTypeEnum;
  fetch?: DocumentFetchDefinition;
  view: DocumentViewTypeEnum;
}

export interface DocumentCustomQuestion {
  action_type: DocumentActionTypeEnum;
  action: DocumentActionDefinition;
  value: string;
  param_key: string;
  skip_if?: object;
}

export interface DocumentDefinition {
  name: string;
  short_name?: string;
  type: DocumentTypeEnum;
  custom_question: DocumentCustomQuestion[];
  param_key: string[];
}

export interface GibooDocument {
  _id: string;
  type: DocumentTypeEnum;
  subtask_id: string;
  updated_at: string;
  created_at: string;

  history_id?: string;
  user_id?: string;
  datetime_key: number;
  name: string;
  output: string;
  node_history_path: string[];

  project_id?: string;
}

export interface DocumentNode {
  _id: string;
  user_id: string;
  document_id: string;
  parent_id?: string;
  type: DocumentNodeType;
  action_type?: DocumentActionTypeEnum;
  created_at: string;
  answer?: string;
  value: string;
}
export interface DocumentTreeNode extends DocumentNode {
  children: DocumentNode[];
  action?: DocumentActionDefinition;
}

export interface DocumentUpdateRequest {
  name?: string;
  output?: string;
  node_id?: string;
  node_history_path?: string[];
}

export interface DocumentCreateRequest {
  org_id: string;
  project_id?: string;
  target_id?: string;
  target_type: 0 | 1 | undefined;
  name: string;
  output?: string;
  type: DocumentTypeEnum;
}

export interface DocumentChatRequest {
  parent_id?: string;
  answer?: string;
  value: string;
  output?: string;
  asking_info_command?: boolean;
}

export interface ChatActionLetter {
  _id: string;
  name: string;
  output: string;
  updated_at: string;
  created_at: string;
}
export interface ChatActionTarget {
  target_id: string;
  target_type: 0 | 1;
  updated_at: string;
}
