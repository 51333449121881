import classNames from "classnames";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import useUser from "../../../../hooks/useUser";
import useSubtaskNotes from "../../../../hooks/project/useSubtaskNotes";
import useOrgs from "../../../../hooks/useOrgs";
import useOrgMembers from "../../../../hooks/project/useOrgMembers";
import UserProfile from "../../../../components/UserProfile";
import { convertToReadable, reprTimeDistance, toDateString } from "../../../../utils/formatHelper";
import { scrollBarClass } from "../../../../utils/classes";
import OrgInvitationPopup from "../../../../components/OrgInvitationPopup";
import UserMentionsInput from "../../../../components/UserMentionsInput";
import { Drawer, Popover, Whisper } from "rsuite";
import CloseButton from "../../../../components/CloseButton";
import Button from "../../../../components/tailwind/Button";
import useProject from "../../../../hooks/project/useProject";
import TaskName from "./TaskName";
import { ActivityTimeline, TimelineContent, TimelineItem } from "./ProjectTaskActivities";
import useInteractionsOfProject from "../../../../hooks/project/useInteractionsOfProject";
import useInteractions from "../../../../hooks/project/useInteractions";
import { IProjectInteraction, IProjectInteractionCreateRequest } from "../../../../types/project";
import useInteraction, { createProjectInteraction } from "../../../../hooks/project/useInteraction";
import useInteractionComments, {
  createProjectInteractionComment,
} from "../../../../hooks/project/useInteractionComments";
import ReprLogData from "../../../../components/ReprLogData";
import ClickToEditInput from "../../../../components/input/ClickToEditInput";
import PlainButton from "../../../../components/PlainButton";
import EditButton from "../../../../components/EditButton";
import TextInput from "../../../../components/tailwind/TextInput";
import { IOrgMember } from "../../../../types/org";
import StatusUpdateModal from "../../../../components/StatusUpdateModal";
import IMG_PROJECT from "../../../../assets/images/project.svg";
import TextArea from "../../../../components/tailwind/TextArea";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import CommentBox from "../../../../components/CommentBox";
import ProjectInvite from "../../../../components/ProjectInvite";
import CommentBoxForInteractionComment from "../../../../components/CommentBoxForInteractionComment";
interface ProjectAddAcitivitySliderProps {
  projectId: string;
  taskId: string | undefined;
  show: boolean;
  interaction?: IProjectInteraction;
  onClose?: () => void;
  toAdd?: IProjectInteractionCreateRequest;
  onAdd?: () => void;
}
function ProjectAddAcitivitySlider({
  projectId,
  taskId,
  show,
  onClose,
  interaction,
  toAdd,
  ...props
}: ProjectAddAcitivitySliderProps) {
  const [user] = useUser();
  const { selected } = useOrgs();
  const {
    data: members,
    isLoading: orgmembersLoading,
    revalidate: revalidateMembers,
  } = useOrgMembers(selected);
  // const [showInvite, setShowInvite] = useState<boolean>(false);

  const { data: project } = useProject(projectId);
  const [selectedInteraction, setSelectedInteraction] = useState<IProjectInteraction | undefined>(
    interaction,
  );

  //activity timeline start
  const { data: dataByProject, revalidate: revalidateInteractionsOfProject } =
    useInteractionsOfProject(!taskId ? projectId : undefined);
  const { data: dataByTask, revalidate } = useInteractions(taskId);
  const [timelineData, setTimelineData] = useState<IProjectInteraction[]>([]);
  const [latest, setLatest] = useState<IProjectInteraction | undefined>(undefined);
  useEffect(() => {
    const _data = taskId ? dataByTask : projectId ? dataByProject : [];
    const ordered = _data.sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
    setTimelineData(ordered);
    setLatest(ordered.length > 0 ? ordered[0] : undefined);
  }, [projectId, taskId, dataByTask, dataByProject]);
  //activity timeline end
  ///// for side panel start
  const [isEditMode, setIsEditMode] = useState<string | undefined>(interaction?._id); // set interaction_id to edit

  return (
    <>
      {/* {showInvite && projectId && (
        <ProjectInvite
          projectId={projectId}
          from_for_mixpanel=""
          showInvite={showInvite}
          handleClose={() => {
            setShowInvite(false);
            revalidateMembers();
          }}
        />
      )} */}
      <Drawer
        onBackdropClick={() => onClose?.()}
        placement={"right"}
        open={show}
        onClose={() => onClose?.()}
        size={"md"}
        dialogTransitionTimeout={500}
        dialogClassName="!z-[9200]"
        backdropClassName="bg-black/80 top-[60px]"
        // containerClassName="top-[60px]"
        className="top-[60px] !w-[765px]"
      >
        <Drawer.Header closeButton={false} className="!border-b-0 p-0">
          <div className="w-full">
            <div className="h-2 bg-purple-500" />
            <div className="inline-flex  items-center justify-between gap-5 px-7 py-4">
              <CloseButton style={{ height: 16, width: 16 }} onClick={() => onClose?.()} />
              <div className="flex items-center gap-3">
                <p className="font-poppins text-xs text-gray-700">{project.name}</p>
                {taskId && (
                  <>
                    <i className="gi gi-angle-right" />
                    <TaskName
                      project_id={projectId}
                      task_id={taskId}
                      className="!max-w-[130px] !truncate text-xs text-gray-700"
                    />
                  </>
                )}
                <>
                  <i className="gi gi-angle-right" />
                  <p className="font-poppins text-xs text-gray-700">View all project progress</p>
                </>
              </div>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className={"!p-0"}>
          <div className={classNames("grid h-full w-full grid-cols-[243px_522px]")}>
            <div className="max-h-full overflow-y-scroll bg-gray-50 pb-8 pl-8  pt-8">
              <ActivityTimeline
                projectId={projectId}
                taskId={taskId}
                fromDashboard
                timelineData={timelineData.filter((i) => i.type !== "Created")}
                iconClass={"!bg-gray-50"}
                renderItem={(item) => {
                  const notAllow = ["Joined", "Added", "Created"].includes(item?.type);
                  return (
                    <TimelineContent
                      data={item}
                      onClick={(data) => {
                        // if (notAllow) return;
                        setSelectedInteraction(data);
                        setIsEditMode(data?._id);
                      }}
                      wrapperClass={"cursor-pointer"}
                      title={
                        <>
                          {item.data && item.data.length > 0 ? (
                            <div
                              className={classNames(
                                isEditMode &&
                                  selectedInteraction?._id === item._id &&
                                  "border-b border-purple-500",
                              )}
                            >
                              <ReprLogData
                                project_id={item.project_id || projectId}
                                task_id={item.task_id || taskId}
                                id={item._id}
                                data={item.data}
                                disableClick
                              />
                            </div>
                          ) : (
                            <div
                              className={classNames(
                                isEditMode &&
                                  selectedInteraction?._id === item._id &&
                                  "w-fit border-b border-purple-500",
                              )}
                            >
                              {item.title}
                            </div>
                          )}
                        </>
                      }
                      date={item.created_at}
                      // date={<>{reprTimeDistance(item.created_at, 1)}</>}
                    />
                  );
                }}
                header={
                  <TimelineItem
                    onClick={() => {
                      setIsEditMode(undefined);
                    }}
                    icon={<i className="gi gi-add text-gray-700"></i>}
                    iconClass={"!bg-gray-50"}
                    borderType="dashed"
                    customIcon
                    description={
                      <TimelineContent
                        title={"Add new"}
                        wrapperClass="cursor-pointer mb-7 giboo-btn-text-purple"
                      />
                    }
                  />
                }
                footer={
                  <>
                    <TimelineItem
                      borderType="solid"
                      icon={
                        <span>
                          <img src={IMG_PROJECT} className="h-4 w-4" />
                        </span>
                      }
                      isLast
                      customIcon
                      iconClass={"!bg-gray-50"}
                      description={
                        <TimelineContent title="Project created" date={project.created_at} />
                      }
                    />
                  </>
                }
              />
            </div>
            <div className="border-l border-t border-gray-200">
              <AddActivity
                taskId={taskId}
                projectId={projectId}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                setSelectedInteraction={setSelectedInteraction}
                revalidate={() => {
                  return !taskId ? revalidateInteractionsOfProject() : revalidate?.();
                }}
                toAdd={toAdd}
                onAdd={props.onAdd}
              />
            </div>
          </div>
        </Drawer.Body>
      </Drawer>
    </>
  );
}
export default ProjectAddAcitivitySlider;

const AddActivity = ({
  isEditMode,
  taskId,
  projectId,
  revalidate,
  setIsEditMode,
  toAdd,
  onAdd,
  setSelectedInteraction,
}: {
  isEditMode?: string;
  taskId?: string;
  projectId: string;
  revalidate: () => Promise<IProjectInteraction[] | undefined>;
  setIsEditMode: Dispatch<SetStateAction<string | undefined>>;
  setSelectedInteraction: Dispatch<SetStateAction<IProjectInteraction | undefined>>;
  toAdd?: IProjectInteractionCreateRequest;
  onAdd?: () => void;
}) => {
  const { data: interaction, update } = useInteraction(isEditMode);
  const { data: comments, revalidate: revalidateComments } = useInteractionComments(isEditMode);
  const [isViewMode, setIsViewMode] = useState(false);
  const [showTaskCreation, setShowTaskCreation] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [taskName, SetTaskName] = useState<string>("");
  const [showEdit, setShowEdit] = useState(false);
  const [doNotUseToAdd, setDoNotUseToAdd] = useState(false);
  const [memo, setMemo] = useState("");
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const { selected } = useOrgs();
  const { data: members, isLoading: orgmembersLoading } = useOrgMembers(selected);
  const [comment, setComment] = useState("");
  const [user] = useUser();
  const [selectedUser, setSelectedUser] = useState<IOrgMember>();
  const [showCommentInput, setShowCommentInput] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [isAddingComment, setIsAddingComment] = useState(false);
  useOnClickOutside(ref, () => {
    if (showCommentInput) {
      setShowCommentInput(false);
    }
  });
  useEffect(() => {
    if (selectedUser) setShowTaskCreation(true);
  }, [selectedUser]);
  useEffect(() => {
    if (isEditMode) {
      setIsViewMode(true);
      setShowEdit(true);
      setDoNotUseToAdd(true);
    } else if (toAdd && !doNotUseToAdd) {
      setShowEdit(false);
      SetTaskName(toAdd.title || "");
      setMemo(toAdd.content || "");
    } else {
      setShowEdit(false);
      setIsViewMode(false);
      SetTaskName("");
      setMemo("");
    }
  }, [isEditMode]);

  const handleSubmit = () => {
    if (!taskId && !projectId) return;
    if (isEditMode) {
      setIsCreating(true);
      update({
        title: taskName,
        content: memo,
      })
        .then(() => {
          revalidate().finally(() => {
            setIsCreating(false);
            setShowEdit(false);
          });
        })
        .finally(() => {
          setIsCreating(false);
          setShowEdit(false);
          setIsViewMode(true);
        });
    } else {
      setIsCreating(true);
      createProjectInteraction({
        ...(!doNotUseToAdd && toAdd ? toAdd : {}),
        project_id: projectId || "",
        task_id: taskId,
        title: taskName,
        content: memo,
      })
        .then((res: IProjectInteraction) => {
          revalidate()
            .then(() => {
              setIsEditMode?.(res._id);
              setSelectedInteraction?.(res);
            })
            .finally(() => setIsCreating(false));
          onAdd?.();
        })
        .catch(() => setIsCreating(false));
    }
  };

  const renderAllComments = useCallback(() => {
    return (
      <>
        <div className={classNames("mt-3 flex max-h-[250px]  grow flex-col gap-4", scrollBarClass)}>
          {comments.length > 0 ? (
            comments.map((i) => {
              return (
                <CommentBoxForInteractionComment
                  projectId={projectId}
                  from_for_mixpanel="mention_in_project_progress_comment"
                  key={i._id}
                  activity={i}
                  revalidate={() => {
                    revalidateComments();
                  }}
                />
              );
            })
          ) : (
            <div className="grid h-[130px] place-items-center rounded border border-gray-300">
              <h4 className="font-poppins text-sm font-medium text-black">No comments</h4>
            </div>
          )}
        </div>
      </>
    );
  }, [comments]);
  const handleAddComment = () => {
    if (!interaction._id || !projectId) return;
    setIsAddingComment(true);
    createProjectInteractionComment({ content: comment, interaction_id: interaction._id })
      .then(() => {
        revalidateComments();
        setComment("");
      })
      .finally(() => {
        setComment("");
        setIsAddingComment(false);
      });
  };
  return (
    <>
      {showTaskCreation && (
        <StatusUpdateModal
          open={showTaskCreation}
          setOpen={setShowTaskCreation}
          selectedUser={selectedUser}
          projecId={projectId}
          taskId={taskId}
          onTaskCreation={() => {
            setShowTaskCreation(false);
          }}
        />
      )}
      {showInvite && projectId && (
        <ProjectInvite
          from_for_mixpanel={
            !showCommentInput
              ? "mention_in_project_progress"
              : "mention_in_project_progress_comment"
          }
          projectId={projectId}
          showInvite={showInvite}
          handleClose={() => setShowInvite(false)}
        />
      )}
      <div className="flex h-full w-full flex-col">
        <div className="h-full max-h-[425px] w-full bg-white  px-8 py-8">
          {showEdit || isViewMode ? (
            <div>
              <div className="flex h-fit flex-col gap-6">
                {interaction.data && interaction.data.length > 0 ? (
                  <div className="w-full text-lg font-semibold">
                    <ReprLogData
                      id={interaction._id}
                      project_id={interaction.project_id}
                      task_id={interaction.task_id}
                      data={interaction.data}
                      disableClick
                    />
                  </div>
                ) : (
                  <div className="flex w-full items-center justify-between">
                    <ClickToEditInput
                      initalValue={interaction.title}
                      placeholder="Enter the latest project progress"
                      noValueLabel="Enter the latest project progress"
                      height="!h-[30px]"
                      wrapperClass="max-w-full min-w-[250px]"
                      textInputClass="!max-w-full !text-sm placeholder:!text-sm !font-normal"
                      textClass="w-full font-poppins !text-sm"
                      onInputChange={(val) => {
                        SetTaskName(val);
                      }}
                      withOutBorder
                      withoutEditIcon
                      disabled={showEdit}
                    />
                    {interaction.user_id === user?._id && (
                      <PlainButton
                        id={`btn-update-image-close`}
                        handleOnClick={() => {
                          SetTaskName(interaction.title);
                          setMemo(interaction.content);
                          setShowEdit(false);
                          setIsViewMode(false);
                        }}
                        leftIconClass={<EditButton />}
                      />
                    )}
                  </div>
                )}
                <div className="flex items-center gap-5">
                  <p className="w-[106px] font-poppins text-sm text-gray-700">Owner</p>
                  <UserProfile userId={interaction.user_id} />
                </div>
                <div className="flex flex-col gap-4">
                  <p className=" font-poppins text-sm text-gray-700">Update</p>
                  <h5 className="font-poppins text-xs text-gray-900">
                    {interaction?.content ? convertToReadable(interaction?.content) : "None"}
                  </h5>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex h-fit flex-col">
              <div>
                <div className="flex h-fit flex-col gap-6">
                  <div className="flex w-full justify-between">
                    <ClickToEditInput
                      shouldFocus
                      initalValue={taskName}
                      placeholder="Enter the latest project progress"
                      noValueLabel="Enter the latest project progress"
                      height="!h-[30px]"
                      wrapperClass="max-w-full min-w-[250px]"
                      textInputClass="!max-w-full !text-sm placeholder:!text-sm !font-normal"
                      textClass=" w-full font-poppins !text-sm"
                      onInputChange={(val) => {
                        SetTaskName(val);
                      }}
                      withOutBorder
                      withoutEditIcon
                    />
                  </div>
                  <div className="flex items-center gap-5">
                    <p className="w-[106px] font-poppins text-sm text-gray-700">Owner</p>
                    <UserPicker
                      projectId={projectId}
                      taskId={taskId}
                      setSelected={(data) => {
                        setSelectedUser(data);
                      }}
                      value={selectedUser}
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className=" font-poppins text-sm text-gray-700"> Add an update</p>
                    <UserMentionsInput
                      id="note"
                      value={memo}
                      placeholder="How’s the project going?"
                      noOfRows={5}
                      className="!mb-0 !min-h-[124px] !pb-0"
                      handleOnChange={(event, newValue, newPlainTextValue, mentions) => {
                        if (mentions?.[0]?.id === "CUSTOM") {
                          return;
                        }
                        setMemo(event.target.value);
                      }}
                      onAdd={(id) => {
                        if (id === "CUSTOM") {
                          setShowInvite(true);
                        }
                      }}
                      customComponent={
                        <div className="w-full text-center font-poppins text-xs text-gray-900">
                          Invite member
                        </div>
                      }
                      options={[
                        ...(members
                          .filter((i) => i._id !== user?._id)
                          .map((i) => ({
                            id: i._id,
                            display: `${i.firstname} ${i.lastname}`,
                          })) as []),
                        { id: "CUSTOM", display: `Invite Member` },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="inline-flex items-center justify-between gap-5">
                <p className="font-poppins text-xs text-gray-700">@ mention</p>
                <Button
                  className="self-end !text-sm"
                  id="btn-open-all-status"
                  handleOnClick={() => {
                    handleSubmit();
                  }}
                  loading={isCreating}
                  label={!isEditMode ? "Update" : "Done"}
                />
              </div>
            </div>
          )}
        </div>
        {isEditMode && (
          <>
            <div className="min-h-fit flex-1 bg-gray-50">
              <div className="w-full grow flex-col gap-3 p-6">
                <div className=" w-full border-b border-gray-300">
                  <h5 className="text-sm font-semibold text-purple-500 underline underline-offset-[6px]">
                    Comments
                  </h5>
                </div>
                {renderAllComments()}
              </div>
            </div>
            <div
              className={classNames(
                "flex-0 border-t border-gray-300 bg-gray-50 px-4 py-10",
                !showCommentInput ? "!min-h-[150px]" : "mb-4 !py-4",
              )}
            >
              <div className="grid h-full w-full grid-cols-[20px_1fr] items-start gap-[10px]">
                <div className="flex h-full">
                  <span className="self-start">
                    <UserProfile userId={user?._id} showName={false} size={16} />
                  </span>
                  <div />
                </div>
                <div
                  ref={ref}
                  onClick={() => {
                    setShowCommentInput(true);
                  }}
                  className="flex w-full flex-col gap-4"
                >
                  {showCommentInput ? (
                    <UserMentionsInput
                      id="note"
                      value={comment}
                      noOfRows={4}
                      autoFocus
                      className="!mb-0 !min-h-[114px]"
                      handleOnChange={(event, newValue, newPlainTextValue, mentions) => {
                        if (mentions?.[0]?.id === "CUSTOM") {
                          return;
                        }
                        setComment(event.target.value);
                      }}
                      onAdd={(id) => {
                        if (id === "CUSTOM") {
                          setShowInvite(true);
                        }
                      }}
                      customComponent={
                        <div className="w-full text-center font-poppins text-xs text-gray-900">
                          Invite member
                        </div>
                      }
                      options={[
                        ...(members
                          .filter((i) => i._id !== user?._id)
                          .map((i) => ({
                            id: i._id,
                            display: `${i.firstname} ${i.lastname}`,
                          })) as []),
                        { id: "CUSTOM", display: `Invite Member` },
                      ]}
                    />
                  ) : (
                    <div className="w-full rounded border border-gray-200 px-[18px] pb-4 pt-4 text-xs text-gray-500">
                      Ask a question or post a comment...
                    </div>
                  )}
                  {showCommentInput && (
                    <div className="flex items-center justify-between pb-5">
                      <h5 className="font-poppins text-sm text-gray-700">@ mention</h5>
                      <Button
                        handleOnClick={() => {
                          handleAddComment();
                        }}
                        disabled={!comment}
                        id="post"
                        loading={isAddingComment}
                        label={"Comment"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

function UserPicker({
  projectId,
  value,
  setSelected,
  taskId,
}: {
  projectId?: string;
  value?: IOrgMember;
  setSelected: (data?: IOrgMember) => void;
  taskId?: string;
}) {
  const whisperRef = useRef<any>(null);
  const { selected } = useOrgs();
  const [user] = useUser();
  const {
    data: members,
    isLoading: orgmembersLoading,
    revalidate: revalidateMembers,
  } = useOrgMembers(selected);
  const [showInvite, setShowInvite] = useState<boolean>(false);
  return (
    <>
      {/* {showInvite && projectId && (
        <ProjectInvite
          projectId={projectId}
          from_for_mixpanel="mention_in_project_progress_comment"
          showInvite={showInvite}
          handleClose={() => {
            setShowInvite(false);
            revalidateMembers();
          }}
        />
      )} */}
      <Whisper
        ref={whisperRef}
        placement="bottomStart"
        controlId="control-id-hover-enterable"
        className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)] "
        trigger={"click"}
        // disabled={!taskId}
        onOpen={() => {
          setSelected();
        }}
        speaker={
          <Popover className="z-[9999] !mt-2 !px-3" arrow={false}>
            <ul className="flex h-auto max-w-[220px] flex-col !gap-3">
              {members
                .filter((i) => i._id !== user?._id)
                .map((user) => {
                  return (
                    <li
                      key={user._id}
                      className="w-full cursor-pointer px-3 py-1 hover:bg-gray-100 hover:shadow-sm"
                      onClick={() => {
                        whisperRef?.current?.close();
                        setSelected(user);
                      }}
                    >
                      <UserProfile userId={user._id} />
                    </li>
                  );
                })}
              <li className="inline-flex w-[196px] cursor-pointer items-center gap-[10px] rounded bg-gray-100 p-2">
                <span className="h-full self-start pt-1">
                  <i className="gi-md gi-info text-gray-700 " />
                </span>
                <p className="font-poppins text-[10px] text-gray-700">
                  By assigning an owner, you are requesting an update.
                </p>
              </li>
            </ul>
          </Popover>
        }
      >
        <div
          className={classNames(
            "grid h-[25px] w-fit place-items-center rounded px-[10px] text-sm text-gray-900 hover:opacity-80",
          )}
        >
          <UserProfile userId={value?._id || user?._id} />
        </div>
      </Whisper>
    </>
  );
}
