import React from "react";
import IMG_Loader from "../../../../assets/images/diversity-chart-loader.svg";
import classNames from "classnames";
export default function DiversityChartLoader({
  title,
  wrapperClass,
  hideTitle = false,
}: {
  title?: string;
  wrapperClass?: string;
  hideTitle?: boolean;
}) {
  return (
    <div
      className={classNames(
        "flex min-h-[545px] w-full flex-col items-center justify-center gap-10 ",
        wrapperClass,
      )}
    >
      <img src={IMG_Loader} className="h-fit w-fit" alt="financial chart loader image" />
      <div className="max-w-[514px] flex flex-col gap-2 items-center">
        {!hideTitle && title && (
          <p className="font-poppins text-sm font-semibold italic text-gray-900">Analyzing organization member&apos;s {title} information</p>
        )}
        {!hideTitle && title && <div className="font-poppins text-xs italic text-gray-800">Based on your input, we are currently analyzing the {title} information within your organization. Soon, you will have access to a detailed analysis of each board member, employee, volunteer, and an overview of the organization&apos;s demographics</div>}
      </div>
    </div>
  );
}
