import React, { useEffect, useState } from "react";
import Spacer from "../../../components/Spacer";
import Button from "../../../components/tailwind/Button";
import IMG_1 from "../../../assets/images/financial1.png";
import IMG_2 from "../../../assets/images/financial2.png";
import IMG_3 from "../../../assets/images/financial3.png";
import IMG_4 from "../../../assets/images/financial4.png";
import IMG_Loader from "../../../assets/images/financialLoader.svg";
import ArraySlider from "../../../components/tailwind/ArraySlider";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import useGibooNavigate from "../../../hooks/useGibooNavigate";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import BackButton from "../../../components/BackButton";
export default function FinancialsIntro() {
  const mxEvent = useGibooMixpanel(undefined, true); // this is a navigated page and location.state already has 'from_for_mixpanel', so do not set from_for_mixpanel
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const locationState = useLocation().state;
  const navigate = useGibooNavigate(); // by pass from_for_mixpanel and session_for_mixpanel
  const items = [
    <Item1 key={"item1"} />,
    <Item2 key={"item2"} />,
    <Item3 key={"item3"} />,
    <Item4 key={"item4"} />,
  ];
  const itemLength = items.length;
  const isLast = itemLength === selectedIndex + 1;
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.financial.intro.started);
  }, []);
  return (
    <div className="flex flex-col items-center overflow-y-scroll">
      <Spacer height="118px" />
      <div className="my-4 flex min-h-fit w-full max-w-[759px]">
        <BackButton wrapper="self-start" />
      </div>
      <div className="flex min-h-fit w-full max-w-[759px] flex-col justify-start rounded-t-[4px] border border-gray-200 bg-white">
        {loading ? (
          <LoadingState
            onComplete={() => {
              setLoading(false);
              navigate("/financial/detail", { state: { ...locationState } });
              mxEvent(MIXPANEL_EVENTS_V2.financial.intro.successful);
            }}
          />
        ) : (
          <>
            <div className="pt-10">
              <ArraySlider
                slides={items}
                selectedIndex={selectedIndex}
                wrapperClass="mx-8"
                dotNavClass="mt-5"
                onChange={(value) => {
                  setSelectedIndex(value);
                }}
                renderFooter={() => {
                  return (
                    <div className="mt-5 flex h-[77px] w-full items-center justify-center border-t border-gray-200">
                      {!isLast ? (
                        <Button
                          label="Next"
                          handleOnClick={() => {
                            setSelectedIndex((prev) => prev + 1);
                          }}
                          id="Next"
                        />
                      ) : (
                        <Button
                          label="Start assessment"
                          handleOnClick={() => {
                            setLoading(true);
                          }}
                          id="Start assessment"
                        />
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </>
        )}
      </div>
      <Spacer height="118px" />
    </div>
  );
}

const Item1 = () => {
  return (
    <div className="flex h-[508px] w-[557px] select-none flex-col items-center gap-5">
      <div className="flex h-[48px] items-center text-base font-semibold">
        Why is it important to assess the financial health of my organization?
      </div>
      <div className="flex max-h-[248px] w-full max-w-[311px] justify-center">
        <img src={IMG_1} className="h-fit w-fit" alt="financial image" />
      </div>
      <div className="flex items-center">
        <h6 className="w-[418px] font-poppins text-xs font-normal text-gray-800">
          As a dedicated manager of a nonprofit organization, you understand that success hinges on
          more than just good intentions. <br />
          <br />
          It&apos;s about sustainable impact and ensuring your mission thrives for years to come.
          That&apos;s why assessing your organization&apos;s financial health is not just important;
          it&apos;s mission-critical.
          <br />
          <br /> Click next to proceed to discover the key benefits.
        </h6>
      </div>
    </div>
  );
};

const Item2 = () => {
  return (
    <div className="flex h-[508px] w-[418px] select-none flex-col items-center gap-5">
      <div className="flex h-[48px] items-center text-center  text-base font-semibold">
        First, you can assess the financial health to plan your organization&apos;s financial
        sustainability.
      </div>
      <div className="flex max-h-[248px] w-full max-w-[372px] justify-center">
        <img src={IMG_2} className="h-fit w-fit" alt="financial image" />
      </div>
      <div className="flex items-center">
        <h6 className="w-[418px] font-poppins text-xs font-normal text-gray-800">
          Giboo empowers you with a clear understanding of the financial landscape, enabling you to
          confidently <b className="text-purple-500">comprehend your current financial status</b>{" "}
          and proactively <b className="text-purple-500">plan for future fundraising</b> to
          optimally meet your financial needs.
          <br />
          <br />
          We utilize a variety of indicators, such as revenue streams, months of liquidity, and
          business model, to ensure a comprehensive understanding of your financial situation.
        </h6>
      </div>
    </div>
  );
};
const Item3 = () => {
  return (
    <div className="flex h-[508px] w-[557px] select-none flex-col items-center gap-5">
      <div className="flex h-[48px] items-center text-center  text-base font-semibold">
        Second, you can plan ahead for fundraising needs.
      </div>
      <div className="flex max-h-[248px] w-full max-w-[418px] justify-center">
        <img src={IMG_3} className="h-fit w-fit" alt="financial image" />
      </div>
      <div className="flex items-center">
        <h6 className="w-[418px] font-poppins text-xs font-normal text-gray-800">
          With Giboo, you can{" "}
          <b className="text-purple-500">anticipate and plan for your fundraising requirements</b>{" "}
          with confidence.
          <br />
          <br /> We arm you with insights and data-driven strategies to ensure that sufficient funds
          are available to support your programs and initiatives. Say goodbye to financial
          uncertainty.
        </h6>
      </div>
    </div>
  );
};
const Item4 = () => {
  return (
    <div className="flex h-[508px] w-[557px] select-none flex-col items-center gap-5">
      <div className="flex h-[48px] items-center text-center  text-base font-semibold">
        Are you ready to start on the journey towards financial prosperity?
      </div>
      <div className="flex max-h-[248px] w-full max-w-[396px] justify-center">
        <img src={IMG_4} className="h-fit w-fit" alt="financial image" />
      </div>
      <div className="flex h-[144px] items-center">
        <h6 className="w-[418px] font-poppins text-xs font-normal text-gray-800">
          With Giboo as your dedicated partner, we&apos;re here to guide you every step of the way.
          Let&apos;s get started!
        </h6>
      </div>
    </div>
  );
};
const LoadingState = ({ onComplete }: { onComplete?: () => void }) => {
  const items = [
    "Business (revenue) model",
    "Total contributions",
    "Revenue stream",
    "Revenue & expense stream",
    "Operating expenses",
    "Financial assets categories",
    "Working capital: Month of liquid",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 400);
    const lastIndex = items.length - 1;
    return () => {
      clearInterval(intervalId);

      if (currentIndex === lastIndex) {
        onComplete?.();
      }
    };
  }, [items.length, currentIndex, onComplete]);
  return (
    <div className="flex h-[664px] w-full flex-col items-center justify-center gap-[32px]">
      <div className="flex items-center text-center  text-xs  text-gray-800">
        Based on your IRS 990 data we are assessing your financial sustainability.
      </div>
      <div className="relative flex justify-center ">
        <div
          className={classNames("absolute inset-0 flex items-center justify-center bg-transparent")}
        >
          <div className="z-20 mt-[124px] grid  h-[44px] max-w-[165px] place-items-center">
            <p className="text-xs font-semibold italic text-gray-900">Analyzing organization’s</p>
            <div className="truncate overflow-ellipsis text-xs font-normal italic text-gray-800">
              {items[currentIndex]}
            </div>
          </div>
        </div>
        <img src={IMG_Loader} className="h-fit w-fit" alt="financial image" />
        <div></div>
      </div>
    </div>
  );
};
