import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import LeftInnerIconInput from "../../../components/LeftInnerIconInput";
import PlainButton from "../../../components/PlainButton";
import classNames from "classnames";
import CreatableSelector from "../../../components/selector/CreatableSelector";
import AlertModal from "../../../components/tailwind/AlertModal";

interface IMemberContactInfo {
  _id: string;
  firstname: string;
  lastname: string;
  title?: string;
  titles?: string[];
  email?: string;
  phone?: string;
  linkedin?: string;
  twitter?: string;
  memo?: string;
  category?: string;
}
interface IOption {
  label: string;
  value: string;
}

interface InputEmployeeProps {
  i: number;
  members: IMemberContactInfo[];
  setMembers: Dispatch<SetStateAction<IMemberContactInfo[]>>;
  titleLabels: IOption[];
  loading?: boolean;
}

const categoryData = [
  { label: "person", value: "" },
  { label: "Grant", value: "grant" },
  { label: "CS/Support", value: "cs" },
  { label: "Communication", value: "communication" },
  { label: "Info(general)", value: "info" },
  { label: "Media", value: "media" },
];
const regEmail = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
const isEmailOk = (email: string) => {
  return regEmail.test(email);
};
const employeeTypes = { board: ["Board member"], employee: ["Employee"], volunteer: ["Volunteer"] };
function InputMember({ members, setMembers, i, titleLabels, loading = false }: InputEmployeeProps) {
  const [showConfirm, setShowConfirom] = useState<boolean>(false);
  // const title = members[i].titles;
  // const [titleOption, setTitleOption] = useState<IOption[]>(
  //   title ? title.map((item) => ({ label: item, value: item })) : [],
  // );
  const [titleOption, setTitleOption] = useState<IOption | undefined>(
    titleLabels.find((d) => d.value === members[i].title) ||
      (members[i].title
        ? { label: members[i].title || "", value: members[i].title || "" }
        : undefined),
  );

  const [categoryOption, setCategoryOption] = useState<IOption | undefined>(
    categoryData.find((d) => d.value === members[i].category) || { label: "person", value: "" },
  );

  useEffect(() => {
    setTitleOption(
      titleLabels.find((d) => d.value === members[i].title) ||
        (members[i].title
          ? { label: members[i].title || "", value: members[i].title || "" }
          : undefined),
    );
    setCategoryOption(
      categoryData.find((d) => d.value === members[i].category) || { label: "person", value: "" },
    );
  }, [members]);
  // useEffect(() => {
  //   setMembers((prev) => [
  //     ...prev.slice(0, i),
  //     {
  //       ...prev[i],
  //       title: titleOption?.value ? titleOption.value : undefined,
  //     },
  //     ...prev.slice(i + 1),
  //   ]);
  // }, [titleOption]);
  // useEffect(() => {
  //   setMembers((prev) => [
  //     ...prev.slice(0, i),
  //     {
  //       ...prev[i],
  //       category: categoryOption?.value ? categoryOption.value : undefined,
  //     },
  //     ...prev.slice(i + 1),
  //   ]);
  // }, [categoryOption]);
  return (
    <div className={classNames("w-full")}>
      {showConfirm && (
        <AlertModal
          isOpen={showConfirm}
          handleClose={() => setShowConfirom(false)}
          confirmTitle="Delete"
          cancelTitle="Cancel"
          handleCancel={() => setShowConfirom(false)}
          title="Delete"
          content="Do you want to delete this member?"
          handleConfirm={() => {
            setMembers((prev) => [...prev.slice(0, i), ...prev.slice(i + 1)]);
            setShowConfirom(false);
          }}
        />
      )}
      <div className="flex gap-3">
        <div className="mb-5 flex grow flex-col gap-3">
          <p className="semibold">Category</p>
          <div className="flex max-w-[400px] flex-col gap-1">
            <CreatableSelector
              id={`category-${i}`}
              data={categoryData}
              onChange={(value) => {
                if (value) setCategoryOption(value);
              }}
              value={categoryOption}
              onCreateOption={(val: string) => {
                setCategoryOption({ label: val, value: val });
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="semibold">Name</p>
            <div className="grid grid-cols-2 gap-3">
              <div className="flex flex-col gap-1">
                <LeftInnerIconInput
                  id={`input-emp-firstname-${i}`}
                  disabled={loading}
                  value={members[i].firstname}
                  placeholder="First name"
                  handleOnChange={(e) =>
                    setMembers((prev) => [
                      ...prev.slice(0, i),
                      {
                        ...prev[i],
                        firstname: e.target.value,
                      },
                      ...prev.slice(i + 1),
                    ])
                  }
                />
                {!members[i].firstname && <h5 className="purple">Firstname is required</h5>}
              </div>
              <div className="flex flex-col gap-1">
                <LeftInnerIconInput
                  id={`input-emp-lastname-${i}`}
                  disabled={loading}
                  value={members[i].lastname}
                  placeholder="Last name"
                  handleOnChange={(e) =>
                    setMembers((prev) => [
                      ...prev.slice(0, i),
                      {
                        ...prev[i],
                        lastname: e.target.value,
                      },
                      ...prev.slice(i + 1),
                    ])
                  }
                />
                {!members[i].lastname && <h5 className="purple">Lastname is required</h5>}
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <p className="semibold mb-0">Title</p>
            {/* <MultipleCreatableSelector
              id={`input-emp-title-${i}`}
              placeholder="Choose title"
              data={titleLabels}
              value={titleOption}
              onCreateOption={(val: string) =>
                setTitleOption((prev) => [...prev, { value: val, label: val }])
              }
              onChange={(v) => setTitleOption(v.map((item) => item))}
              disabled={loading}
            /> */}
            <CreatableSelector
              id={`title-${i}`}
              data={titleLabels}
              onChange={(value) => {
                if (value) setTitleOption(value);
              }}
              value={titleOption}
              onCreateOption={(val: string) => {
                setTitleOption({ label: val, value: val });
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="semibold mb-0">Email</p>
            <LeftInnerIconInput
              id={`input-emp-email-${i}`}
              disabled={loading}
              value={members[i].email}
              placeholder="Email address"
              handleOnChange={(e) =>
                setMembers((prev) => [
                  ...prev.slice(0, i),
                  {
                    ...prev[i],
                    email: e.target.value,
                  },
                  ...prev.slice(i + 1),
                ])
              }
            />
            {members[i].email && !isEmailOk(members[i].email || "") && (
              <h5 className="purple">Email address is invalid</h5>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <p className="semibold mb-0">Phone</p>
            <LeftInnerIconInput
              id={`input-emp-phone-${i}`}
              disabled={loading}
              value={members[i].phone}
              placeholder="Phone"
              handleOnChange={(e) =>
                setMembers((prev) => [
                  ...prev.slice(0, i),
                  {
                    ...prev[i],
                    phone: e.target.value,
                  },
                  ...prev.slice(i + 1),
                ])
              }
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="semibold mb-0">LinkedIn</p>
            <LeftInnerIconInput
              id={`input-emp-linkedin-${i}`}
              disabled={loading}
              value={members[i].linkedin}
              placeholder="LinkedIn"
              handleOnChange={(e) =>
                setMembers((prev) => [
                  ...prev.slice(0, i),
                  {
                    ...prev[i],
                    linkedin: e.target.value,
                  },
                  ...prev.slice(i + 1),
                ])
              }
            />
          </div>
          {/* <div className="flex flex-col gap-1">
            <p className="semibold mb-0">Memo</p>
            <LeftInnerIconInput
              id={`input-emp-memo-${i}`}
              disabled={loading}
              value={members[i].memo}
              placeholder="Memo"
              handleOnChange={(e) =>
                setMembers((prev) => [
                  ...prev.slice(0, i),
                  {
                    ...prev[i],
                    memo: e.target.value,
                  },
                  ...prev.slice(i + 1),
                ])
              }
            />
          </div> */}
        </div>
        {members.length > 0 && (
          <PlainButton
            className="self-start"
            id={`btn-emp-delete-${i}`}
            leftIconClass="fa-solid fa-xmark"
            handleOnClick={() => {
              setShowConfirom(true);
            }}
            disabled={loading}
          />
        )}
      </div>
    </div>
  );
}
export default InputMember;
