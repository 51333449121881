import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "rsuite";
import { getSearchQueryFromOnboardingData } from "../app/onboardingSlice";
import useFunderPastRecipients from "../hooks/funder/useFunderPastRecipients";
import { queryMaskForNameSearch } from "../hooks/useGibooStructureSearch";
import useOnboardingData from "../hooks/useOnboarding";
import { IGrant } from "../types/grant";
import { Philanthropy } from "../types/philanthropy";
import { SearchQuery } from "../types/search";
import PhilanthropicInformationFoldable from "./PhilanthropicInformationFoldable";
export interface CountedAreas {
  FocusAreas?: PhilanthropyDescriptionWithMatchedCount[];
  Beneficiaries?: PhilanthropyDescriptionWithMatchedCount[];
  Programs?: PhilanthropyDescriptionWithMatchedCount[];
  GeographicalPreferences?: PhilanthropyDescriptionWithMatchedCount[];
}
interface PhilanthropyDescriptionWithMatchedCount extends Philanthropy {
  title: string;
  description?: string;
  top_recipients?: { npo_id: string; name: string }[];
  disable?: boolean;
  matchedCount: number;
}
export default function GrantDescriptionDetail({
  grant,
  funder_uid,
  edit = false,
  query,
  countedAreas,
  compact,
  ...props
}: {
  compact?: boolean;
  grant: IGrant;
  funder_uid?: number;
  edit?: boolean;
  query: SearchQuery;
  wrapperClass?: string;
  countedAreas: CountedAreas;
}) {
  const navigate = useNavigate();
  // console.log({ countedAreas });
  const renderTitleAndDescription = ({
    title = "",
    description = "",
    matchedCount,
    ...i
  }: {
    title?: string;
    description?: string;
    matchedCount: number;
    top_recipients?: { npo_id: string; name: string }[];
  }) => {
    return (
      <>
        <div className={classNames("mb-2 w-fit", matchedCount > 0 ? "bg-yellow-500 p-1" : "")}>
          <h5 className="font-poppins text-base font-semibold">{title}</h5>
        </div>
        <h5 className="">
          <span className={classNames()}>{description}</span>
        </h5>
      </>
    );
  };
  return (
    <>
      {countedAreas.FocusAreas && countedAreas.FocusAreas.length > 0 && (
        <>
          {compact && <Divider className={classNames("my-[20px]")} />}
          <div
            id={"grant_focus_area"}
            className={classNames(
              "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
              !compact && "rounded border",
            )}
          >
            <h5 className="text-xl font-semibold text-gray-900">Focus areas</h5>
            <div className={classNames("ml-4 flex flex-col gap-7")}>
              {countedAreas.FocusAreas.map((i) => (
                <div key={i.title}>
                  <>{renderTitleAndDescription(i)}</>
                  <RenderTaxonomy
                    funder_id={grant.type === "grant-page" ? grant.donor_id : undefined}
                    query={query}
                    id={i.title}
                    philanthropy={i}
                  />
                  <RenderRecipients grant={grant} funder_uid={funder_uid} detail={i} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {countedAreas.Beneficiaries && countedAreas.Beneficiaries.length > 0 && (
        <>
          {compact && <Divider className={classNames("my-[20px]")} />}
          <div
            id={"grant_beneficiary"}
            className={classNames(
              "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
              !compact && "rounded border",
            )}
          >
            <h5 className="text-xl font-semibold text-gray-900">Beneficiaries</h5>
            <div className={classNames("ml-4 flex flex-col gap-7")}>
              {countedAreas.Beneficiaries.map((i) => (
                <div key={i.title}>
                  <>{renderTitleAndDescription(i)}</>
                  <RenderTaxonomy
                    funder_id={grant.type === "grant-page" ? grant.donor_id : undefined}
                    query={query}
                    id={i.title}
                    philanthropy={i}
                  />
                  <RenderRecipients grant={grant} funder_uid={funder_uid} detail={i} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {countedAreas.Programs && countedAreas.Programs.length > 0 && (
        <>
          {compact && <Divider className={classNames("my-[20px]")} />}
          <div
            id={"grant_programs"}
            className={classNames(
              "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
              !compact && "rounded border",
            )}
          >
            <h5 className="text-xl font-semibold text-gray-900">Programs</h5>

            <div className={classNames("ml-4 flex flex-col gap-7")}>
              {countedAreas.Programs?.map((i) => (
                <div key={i.title}>
                  <>{renderTitleAndDescription(i)}</>
                  <RenderTaxonomy
                    funder_id={grant.type === "grant-page" ? grant.donor_id : undefined}
                    query={query}
                    id={i.title}
                    philanthropy={i}
                  />
                  <RenderRecipients grant={grant} funder_uid={funder_uid} detail={i} />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {countedAreas.GeographicalPreferences && countedAreas.GeographicalPreferences.length > 0 && (
        <>
          {compact && <Divider className={classNames("my-[20px]")} />}
          <div
            id="grant_geo_preferences"
            className={classNames(
              "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
              !compact && "rounded border",
            )}
          >
            <h5 className="text-xl font-semibold text-gray-900">Service areas</h5>
            <div className={classNames("ml-4 flex flex-col gap-7")}>
              {countedAreas.GeographicalPreferences?.map((i) => (
                <div key={i.title}>
                  <>{renderTitleAndDescription(i)}</>
                  <RenderTaxonomy
                    funder_id={grant.type === "grant-page" ? grant.donor_id : undefined}
                    query={query}
                    id={i.title}
                    philanthropy={i}
                  />
                  <RenderRecipients grant={grant} funder_uid={funder_uid} detail={i} location />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}
function RenderRecipients({
  grant,
  detail,
  funder_uid,
  location = false,
}: {
  grant: IGrant;
  funder_uid?: number;
  detail: PhilanthropyDescriptionWithMatchedCount;
  location?: boolean;
}) {
  const [onboardingData] = useOnboardingData();
  const navigate = useNavigate();
  const pivot = 5;
  const [seeAll, setSeeAll] = useState<boolean>(false);
  const setQueryFunc = () => {
    return {
      ...getSearchQueryFromOnboardingData(onboardingData),
      ...queryMaskForNameSearch,
      focus_area: detail.focus_area?.filter((t) => t.matched).map((t) => t.label) || [],
      beneficiary: detail.beneficiary?.filter((t) => t.matched)?.map((t) => t.label) || [],
      program: detail.program?.filter((t) => t.matched)?.map((t) => t.label) || [],
      service_loc: detail.service_loc?.filter((t) => t.matched) || [],
    };
  };
  const [query, setQuery] = useState<SearchQuery>(setQueryFunc());
  useEffect(() => {
    setQuery(setQueryFunc());
  }, [onboardingData, detail]);
  const { data, isLoading } = useFunderPastRecipients(
    query,
    detail.matchedCount > 0 && grant.donor_id ? grant.donor_id : undefined,
    detail.matchedCount > 0 && funder_uid ? funder_uid : undefined,
    20,
    location ? false : true,
  );
  const top =
    !isLoading && data && data.length > 0
      ? detail.top_recipients
          ?.filter((r) => (data.find((d) => d.npo_id === r.npo_id) ? true : false))
          .slice(0, pivot) || []
      : detail.top_recipients?.slice(0, pivot) || [];
  const merged_top = top.length === 0 ? detail.top_recipients?.slice(0, 1) || [] : top;
  // const rest =
  //   detail.top_recipients?.filter((r) => (top.find((d) => d.npo_id === r.npo_id) ? false : true)) ||
  //   [];
  return (
    <>
      {grant.type === "grant-page" && detail.top_recipients && detail.top_recipients.length > 0 ? (
        <div className="ml-2 flex flex-col items-start">
          <h5 className="mt-4 font-semibold italic">Top recipients</h5>
          {isLoading ? (
            <div className="loading-light-shimmer loading-light-shimmer-on mt-2">
              <div className="loading-light-shimmer-child flex flex-col gap-1">
                {[250, 190, 270, 230].map((i) => (
                  <div key={i} style={{ width: `${i}px`, maxWidth: `${i}px` }}></div>
                ))}
              </div>
            </div>
          ) : (
            <>
              <ul className="list-inside list-disc text-sm">
                {/* {(seeAll ? [...merged_top, ...rest] : merged_top).map((r) => ( */}
                {merged_top.map((r) => (
                  <li
                    key={`${detail.title}-${r.npo_id}-${r.name}`}
                    className="ml-9 w-fit cursor-pointer -indent-5 !font-normal"
                    onClick={() => navigate(`/npos/${r.npo_id}/`)}
                  >
                    <span className="!text-purple-500 !underline hover:!text-purple-700">
                      {r.name}
                    </span>
                  </li>
                ))}
              </ul>
              {/* {rest.length > 0 && (
                <PlainButton
                  handleOnClick={() => setSeeAll((prev) => !prev)}
                  className="ml-5 !text-sm"
                  id={`btn-see-all-${detail.title}`}
                  label={seeAll ? "View less" : `+${rest.length} View more`}
                  leftIconClass={seeAll ? "gi-sm gi-angle-up" : "gi-sm gi-angle-down"}
                />
              )} */}
            </>
          )}
        </div>
      ) : null}
    </>
  );
}
function RenderTaxonomy({
  funder_id,
  query,
  id,
  philanthropy,
}: {
  funder_id: string | undefined;
  query: SearchQuery;
  id: string;
  philanthropy: Philanthropy & { matchedCount: number };
}) {
  const [onboardingData] = useOnboardingData();
  const navigate = useNavigate();
  const hasNoTaxonomy =
    query.focus_area.length === 0 &&
    query.beneficiary.length === 0 &&
    query.program.length === 0 &&
    query.hq_loc.length === 0 &&
    query.service_loc.length === 0;
  return (
    <>
      {(philanthropy.focus_area?.length || 0) +
        (philanthropy.beneficiary?.length || 0) +
        (philanthropy.program?.length || 0) +
        (philanthropy.service_loc?.length || 0) >
      0 ? (
        <PhilanthropicInformationFoldable
          className="mt-2 !p-0"
          id={`unmatched-${id}`}
          title={`${!hasNoTaxonomy ? "Other tags" : "Tags"}`}
          initialShow={!hasNoTaxonomy ? undefined : 20}
          tagMatched={!hasNoTaxonomy ? false : true}
          arrowPosition="start"
          focusArea={philanthropy?.focus_area}
          beneficiary={philanthropy?.beneficiary}
          program={philanthropy?.program}
          location={philanthropy?.service_loc}
          priorityLocation={query?.must_service_loc}
          secondaryPriorityLocation={query?.service_loc}
          allowPastGrantSearch={funder_id ? true : false}
          funder_id={funder_id}
          searchQuery={query}
          enableGroupLocation
        />
      ) : // <div className="mt-3 flex flex-col gap-1">
      //   {philanthropy.matchedCount > 0 && (
      //     <>
      //       <span className="font-semibold">{`Matched tags (${philanthropy.matchedCount})`}</span>
      //       <span className={classNames("flex flex-wrap gap-1")}>
      //         {philanthropy.service_loc
      //           ?.filter((i) => hasNoTaxonomy || i.matched)
      //           .map((t) => (
      //             <Tag
      //               id={`tag-l-${id}-${reprLocation(t)}`}
      //               key={`tag-l-${id}-${reprLocation(t)}`}
      //               color="orange"
      //               size="sm"
      //               matched
      //               name={reprLocation(t)}
      //               tooltip={
      //                 funder_id ? (
      //                   <span>
      //                     Click to view past grants associated with “<b>{reprLocation(t)}</b>“
      //                   </span>
      //                 ) : undefined
      //               }
      //               onClick={
      //                 funder_id
      //                   ? (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      //                       e.stopPropagation();
      //                       e.preventDefault();
      //                       navigate(
      //                         getPastGrantSearchURLFromLocation(
      //                           funder_id,
      //                           t,
      //                           "l",
      //                           onboardingData,
      //                           query,
      //                         ),
      //                       );
      //                     }
      //                   : undefined
      //               }
      //             />
      //           ))}
      //         {philanthropy.focus_area
      //           ?.filter((i) => hasNoTaxonomy || i.matched)
      //           .map((t) => (
      //             <Tag
      //               id={`tag-f-${id}-${t.label}`}
      //               key={`tag-f-${id}-${t.label}`}
      //               color="yellow"
      //               size="sm"
      //               matched
      //               name={t.label}
      //               tooltip={
      //                 funder_id ? (
      //                   <span>
      //                     Click to view past grants associated with “<b>{t.label}</b>“
      //                   </span>
      //                 ) : undefined
      //               }
      //               onClick={
      //                 funder_id
      //                   ? (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      //                       e.stopPropagation();
      //                       e.preventDefault();
      //                       navigate(
      //                         getPastGrantSearchURL(
      //                           funder_id,
      //                           t.label,
      //                           "f",
      //                           onboardingData,
      //                           query,
      //                         ),
      //                       );
      //                     }
      //                   : undefined
      //               }
      //             />
      //           ))}
      //         {philanthropy.beneficiary
      //           ?.filter((i) => hasNoTaxonomy || i.matched)
      //           .map((t) => (
      //             <Tag
      //               id={`tag-b-${id}-${t.label}`}
      //               key={`tag-b-${id}-${t.label}`}
      //               color="blue"
      //               size="sm"
      //               matched
      //               name={t.label}
      //               tooltip={
      //                 funder_id ? (
      //                   <span>
      //                     Click to view past grants associated with “<b>{t.label}</b>“
      //                   </span>
      //                 ) : undefined
      //               }
      //               onClick={
      //                 funder_id
      //                   ? (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      //                       e.stopPropagation();
      //                       e.preventDefault();
      //                       navigate(
      //                         getPastGrantSearchURL(
      //                           funder_id,
      //                           t.label,
      //                           "b",
      //                           onboardingData,
      //                           query,
      //                         ),
      //                       );
      //                     }
      //                   : undefined
      //               }
      //             />
      //           ))}
      //         {philanthropy.program
      //           ?.filter((i) => hasNoTaxonomy || i.matched)
      //           .map((t) => (
      //             <Tag
      //               id={`tag-p-${id}-${t.label}`}
      //               key={`tag-p-${id}-${t.label}`}
      //               color="green"
      //               size="sm"
      //               matched
      //               name={t.label}
      //               tooltip={
      //                 funder_id ? (
      //                   <span>
      //                     Click to view past grants associated with “<b>{t.label}</b>“
      //                   </span>
      //                 ) : undefined
      //               }
      //               onClick={
      //                 funder_id
      //                   ? (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      //                       e.stopPropagation();
      //                       e.preventDefault();
      //                       navigate(
      //                         getPastGrantSearchURL(
      //                           funder_id,
      //                           t.label,
      //                           "p",
      //                           onboardingData,
      //                           query,
      //                         ),
      //                       );
      //                     }
      //                   : undefined
      //               }
      //             />
      //           ))}
      //       </span>
      //     </>
      //   )}
      //   {/* <PhilanthropicInformationFoldable
      //     className="!py-0"
      //     id={`matched-${id}`}
      //     title={`Other tags`}
      //     arrowPosition="start"
      //     focusArea={focus_area?.filter((i) => !i.matched)}
      //     beneficiary={beneficiary?.filter((i) => !i.matched)}
      //     program={program?.filter((i) => !i.matched)}
      //     location={service_loc.filter((i) => !i.matched)}
      //     tagMatched={false}
      //   /> */}
      // </div>
      null}
    </>
  );
}
