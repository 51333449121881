import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
  createRef,
  MutableRefObject,
} from "react";
import IMG_PULSE from "../assets/images/pulseArrow.svg";
import classNames from "classnames";
import { TooltipWrapper } from "./TooltipWrapper";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { zIndexBackdrop } from "../types/zIndex";
import { PlacesType, Tooltip } from "react-tooltip";
import GibooTooltipWrapper from "./GibooTooltipWrapper";

interface IClickMeTooltip {
  position?: "left" | "right" | "center" | "top" | "bottom";
  noText?: boolean;
  children: ReactNode;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  autoClose?: boolean;
  autoOpen?: boolean;
  delay?: number;
  id: string;
  tooltipPlacement?: PlacesType;
  controlled?: boolean;
  label?: string;
}

export default function ClickMeTooltip({
  id,
  children,
  position = "right",
  open,
  setOpen,
  autoClose,
  autoOpen,
  delay = 500,
  noText,
  tooltipPlacement = "bottom",
  controlled,
  label = "Click me!",
}: IClickMeTooltip) {
  const [show, setShow] = useState(open || false);
  useEffect(() => {
    if (!controlled) return;
    setShow(!!open);
  }, [open, controlled]);
  const wrapperRef = createRef<HTMLDivElement>();

  const getPositionClasses = () => {
    switch (position) {
      case "left":
        return "-left-5 top-1/2 transform -translate-y-1/2";
      case "right":
        return "-right-5 top-1/2 transform -translate-y-1/2";
      case "center":
        return "left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2";
      case "top":
        return "top-0 left-1/2 transform -translate-x-1/2";
      case "bottom":
      default:
        return "bottom-0 left-1/2 transform -translate-x-1/2";
    }
  };
  useEffect(() => {
    if (autoClose) {
      setTimeout(() => {
        setShow(false);
      }, delay);
    }
    if (autoOpen) {
      setTimeout(() => {
        setShow(true);
      }, delay);
    }
  }, [autoClose, autoOpen, delay]);
  return (
    <div
      ref={wrapperRef}
      className="relative w-fit"
      onClick={() => {
        setShow(false);
      }}
    >
      {children}
      {show && (
        <div className={`absolute ${getPositionClasses()}`}>
          <div className="relative" id={id ? id : `tooltip-giboo-custom`}>
            <img src={IMG_PULSE} className="z-[1100] h-[54px] w-[54px]" alt="Tooltip pulse" />
          </div>
        </div>
      )}
      {show && !noText && (
        <GibooTooltipWrapper
          id={id ? id : `tooltip-giboo-custom`}
          place={tooltipPlacement}
          isOpen
          wrapperClassName="!bg-transparent"
          style={{ borderRadius: 11, zIndex: 5 }}
          clickable
          offset={-5}
          content={
            <div
              className={classNames(
                "grid h-10 place-items-center rounded-[11px] bg-[#E6E1F2] p-[10px] shadow-[0_0_15px_1px_rgba(0,0,0,0.1)]",
              )}
            >
              <p className="whitespace-nowrap font-poppins text-sm font-semibold text-purple-500">
                {label}
              </p>
            </div>
          }
        />
      )}
    </div>
  );
}
