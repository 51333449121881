import axios from "axios";
import config from "../api";
import {
  FundChar,
  FundCharOverview,
  FunderPastGrantGroup,
  GrantAmount,
  Wealth,
} from "../types/funder";
import { SearchQuery } from "../types/search";
import { matchTaxonomyBySearchQuery } from "../types/taxonomy";

const MAX_SEARCHED_BY_NAME = 30;

export interface FunderMemberContact {
  _id: string;
  firstname?: string;
  lastname?: string;
  employ_type?: string;
  email?: string;
  titles?: string[];
  title?: string;
  linkedin?: string;
  twitter?: string;
  category?: string;
}
interface ContactInfo {
  firstname?: string;
  lastname?: string;
  email?: string;
  website?: string;
  apply_via_post?: boolean;
  address?: string;
}
interface FunderContact {
  completed?: boolean;
  website?: string;
  guideline_website?: string;
  grant_availability?: boolean;
  loi?: ContactInfo;
  general?: ContactInfo;
  members?: FunderMemberContact[];
}
const getFunderContacts = async (
  funder_id: string,
  controller?: AbortController,
): Promise<{
  contacts: FunderMemberContact[];
  apply_via_website?: string;
  website?: string;
  loi?: FunderMemberContact;
  general?: FunderMemberContact;
}> => {
  if (funder_id === undefined)
    return new Promise<{
      contacts: FunderMemberContact[];
      apply_via_website?: string;
      website?: string;
      loi?: FunderMemberContact;
      general?: FunderMemberContact;
    }>((resolve, reject) => resolve({ contacts: [] }));
  return new Promise<{
    contacts: FunderMemberContact[];
    apply_via_website?: string;
    website?: string;
  }>((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/v2/donors/${funder_id}/contact`, {
        ...config,
        ...(controller ? { signal: controller.signal } : {}),
      })
      .then(({ data }: { data: FunderContact }) => {
        resolve({
          ...data,
          contacts: [
            ...(data.loi
              ? [{ ...data.loi, titles: ["Contact for LOI"], category: "grant", _id: "loi" }]
              : []),
            ...(data.general
              ? [
                  {
                    ...data.general,
                    titles: ["Contact for general Q/A"],
                    category: "info",
                    _id: "general",
                  },
                ]
              : []),
            ...(data.members ? data.members : []),
          ],
          apply_via_website: data.loi && data.loi.website ? data.loi.website : undefined,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getWealthInformation = (funder_id: string, years: number[] = []) => {
  const url = `/api/v2/donors/wealth?donor_id=${funder_id}&year=${years.join("&year=")}`;
  return new Promise<Wealth[]>((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + url, config)
      .then(({ data }) => {
        resolve((data as Wealth[]).sort((a, b) => a.tax_year - b.tax_year));
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getGrantAmountOverView = (
  funder_id: string,
  years: number[] = [],
  range_from = 0,
  range_to = 10000000000000,
) => {
  let url = `/api/v2/donors/grantamountoverview?donor_id=${funder_id}&`;
  url = url + `year=${years.join("&year=")}`;
  url = url + `&range_from=${range_from}&range_to=${range_to}`;

  return new Promise<GrantAmount[]>((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + url, config)
      .then(({ data }) => {
        resolve(
          (data as GrantAmount[])
            .sort((a, b) => a.grant_year - b.grant_year)
            .map((a) => ({ ...a, tax_year: a.grant_year })),
        );
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getFundingChar = (
  funder_id: string,
  info_type: "count" | "amount" | "grant_amounts" = "count",
  range_from = 0,
  range_to = 10000000000000,
) => {
  let url = `/api/v2/donors/fundcharacteristics?donor_id=${funder_id}&info_type=${info_type}`;
  url = url + `&range_from=${range_from}&range_to=${range_to}`;
  return new Promise<FundChar[]>((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + url, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getFundingCharOverview = (funder_id: string) => {
  const url = `/api/v2/donors/fundcharacteristics_overview?donor_id=${funder_id}`;
  return new Promise<FundCharOverview>((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + url, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getFunderPastRecipients = async (
  query: SearchQuery,
  funder_id: string,
  funder_uid: number,
  clearLocation: boolean,
  limit = 20,
  controller?: AbortController,
): Promise<FunderPastGrantGroup[]> => {
  return new Promise<FunderPastGrantGroup[]>((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/v2/donors/${funder_uid}/recipients?limit=${limit}`,
        {
          ...query,
          ...(clearLocation
            ? {
                hq_loc: [],
                must_hq_loc: [],
                exclude_hq_loc: [],
                service_loc: [],
                must_service_loc: [],
                exclude_service_loc: [],
              }
            : {}),
          user_id: query?.user_id || "",
          donor_id: funder_id,
          donor_uid: funder_uid,
          pagination_limit: limit,
          pagination_skip: 0,
        },
        {
          ...config,
          ...(controller ? { signal: controller.signal } : {}),
        },
      )
      .then((res) => res.data)
      .then((res) => {
        return res.items.map(
          (d: any) =>
            ({
              ...d,
              npo_location: d.service_loc.length > 0 ? d.service_loc[0] : undefined,
              ...matchTaxonomyBySearchQuery(d, query, true),
            } as FunderPastGrantGroup),
        );
      })
      .then((res) => resolve(res))
      .catch((e) => {
        // console.log(e);
        reject(e);
      });
  });
};
export {
  getFunderContacts,
  getFunderPastRecipients,
  getFundingChar,
  getFundingCharOverview,
  getGrantAmountOverView,
  getWealthInformation,
};
