import { useEffect, useState } from "react";
import { useAppSelector } from "../app/store";
import { selectTaxonomy } from "../app/taxonomySlice";
import { TreeItem } from "../components/TreeSelector";
import useThrottle from "./useThrottle";

interface TagItem {
  key: string;
  score: number;
}
interface Tagging {
  focus_area: TreeItem[];
  beneficiary: TreeItem[];
  program: TreeItem[];
  isLoading: boolean;
  error?: Error;
}
interface IState extends Tagging {
  controller: AbortController;
}
interface CacheItem {
  key: string;
  focus_area: TagItem[];
  beneficiary: TagItem[];
  program: TagItem[];
  finalized: boolean;
}
const MAX_LEN = 480;
const MAX_CACHE_SIZE = 100;
const splitSentenceWithMaxLen = (input: string): string[] => {
  return input
    .split(" ")
    .map((item) => item.trim())
    .reduce(
      (prev, item) =>
        prev.length > 0 && prev[prev.length - 1].length + 1 + item.length < MAX_LEN
          ? [...prev.slice(0, -1), prev[prev.length - 1] + " " + item]
          : [...prev, item],
      [] as string[],
    );
};
const splitSentence = (input: string): string[] => {
  return input
    .split(/\r?(\n|;|\+|\*|\\|\?|!|\/)/)
    .map((item) => item.trim())
    .filter((item) => item.length > 1)
    .reduce(
      (prev, item) =>
        item.length < MAX_LEN ? [...prev, item] : [...prev, ...splitSentenceWithMaxLen(item)],
      [] as string[],
    );
};
const useTaxonomyTagging = (
  input: string,
  taxonomy: "all" | "focus_area" | "beneficiary" | "program" = "all",
  topk = 15,
): Tagging => {
  const delayedQuery = useThrottle<string>(input, 500);
  const [inputs, setInputs] = useState<string[]>([]);
  const [cache, setCache] = useState<CacheItem[]>([]);
  const [state, setState] = useState<IState>({
    focus_area: [],
    beneficiary: [],
    program: [],
    isLoading: false,
    controller: new AbortController(),
  });
  const { focus_area, beneficiary, program, isTaxonomyFetched } = useAppSelector((state) =>
    selectTaxonomy(state),
  );
  const [nameDict, setNameDict] = useState<{ [key: string]: TreeItem }>({});
  useEffect(() => {
    if (!isTaxonomyFetched) return;
    setNameDict(
      [...focus_area, ...beneficiary, ...program].reduce(
        (prev, cur) => ({ ...prev, [cur.label.toLowerCase()]: cur }),
        {},
      ),
    );
  }, [isTaxonomyFetched, focus_area, beneficiary, program]);
  const nameDictKeys = Object.keys(nameDict);
  type TypeNameDict = keyof typeof nameDict;

  useEffect(() => {
    if (!delayedQuery) return;
    const inputList = [delayedQuery]; // splitSentence(delayedQuery);
    // console.log("query changed", inputList);
    setInputs(inputList);
    const keepKeys = cache.filter((item) => item.finalized).map((item) => item.key);
    let queue: string[] = inputList.filter((item) => !keepKeys.includes(item));
    setCache((prev) => {
      const keep = prev.filter(
        (item) => item.finalized && inputList.find((inputKey) => inputKey === item.key),
      );
      const keepOrDiscard = prev.filter((item) => item.finalized && !inputList.includes(item.key));
      const max = Math.max(MAX_CACHE_SIZE, inputList.length + 10);
      if (keepOrDiscard.length + keep.length + queue.length <= max)
        return [...keepOrDiscard, ...keep];
      else if (keep.length + queue.length <= max) {
        const size = max - keep.length - queue.length;
        return [...(size > 0 ? keepOrDiscard.slice(-size) : []), ...keep];
      } else return keep;
    });
    const controller = new AbortController();
    (async () => {
      while (queue.length > 0) {
        setState((prev) => ({ ...prev, isLoading: true }));
        const target = queue[0];
        try {
          // console.log("call ", target);
          const resp = await fetch(process.env.REACT_APP_TAXONOMY_URL + "/taxonomy/v2/tag", {
            method: "POST",
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              "X-API-Key": process.env.REACT_APP_API_KEY || "",
              "Access-Control-Allow-Origin": window.location.origin,
            },
            body: JSON.stringify({
              input_text: target,
              taxonomy: taxonomy,
              topk,
            }),
            signal: controller.signal,
          });
          if (!resp.ok || !resp.body) {
            throw resp.statusText;
          }
          const reader = resp.body.getReader();
          const done = false;
          while (!done) {
            const { value, done } = await reader.read();
            if (done) {
              break;
            }
            let decoded: {
              focus_area?: { [key: string]: number };
              beneficiary?: { [key: string]: number };
              program?: { [key: string]: number };
            } = {};
            try {
              decoded = JSON.parse(new TextDecoder().decode(value));
            } catch (err: any) {
              // console.log(err);
            }
            setCache((prev) => {
              const prevTargets = prev.filter((item) => item.key === target);
              const prevTarget =
                prevTargets && prevTargets.length > 0
                  ? prevTargets[0]
                  : { key: target, focus_area: [], beneficiary: [], program: [] };
              const newValue: CacheItem = {
                key: prevTarget.key,
                focus_area: [
                  ...prevTarget.focus_area,
                  ...(decoded.focus_area
                    ? Object.keys(decoded.focus_area).map((k) => ({
                        key: k,
                        score: decoded.focus_area![k],
                      }))
                    : []),
                ],
                beneficiary: [
                  ...prevTarget.beneficiary,
                  ...(decoded.beneficiary
                    ? Object.keys(decoded.beneficiary).map((k) => ({
                        key: k,
                        score: decoded.beneficiary![k],
                      }))
                    : []),
                ],
                program: [
                  ...prevTarget.program,
                  ...(decoded.program
                    ? Object.keys(decoded.program).map((k) => ({
                        key: k,
                        score: decoded.program![k],
                      }))
                    : []),
                ],
                finalized: false,
              };
              return [...prev.filter((item) => item.key !== target), newValue];
            });
          }
          queue = queue.slice(1);
          setCache((prev) => {
            const prevTargets = prev.filter((item) => item.key === target);
            return prevTargets && prevTargets.length > 0
              ? [
                  ...prev.filter((item) => item.key !== target),
                  { ...prevTargets[0], finalized: true },
                ]
              : prev;
          });
          setState((prev) => ({ ...prev, isLoading: false }));
        } catch (err: any) {
          if (err.name !== "AbortError") {
            // console.log(err);
            queue = queue.slice(1);
            setState((prev) => ({ ...prev, error: err, isLoading: false }));
          } else {
            queue = [];
          }
        }
      }
    })();
    return () => {
      controller.abort();
    };
  }, [isTaxonomyFetched, delayedQuery]);

  useEffect(() => {
    setState((prevState) => {
      const res = cache.filter((item) => inputs.includes(item.key));
      return {
        ...prevState,
        focus_area: res
          .reduce((prev, cur) => [...prev, ...cur.focus_area], [] as TagItem[])
          .sort((a, b) => a.key.localeCompare(b.key))
          .reduce(
            (prev, cur) => [
              ...(prev.length > 0 && prev[prev.length - 1].key === cur.key
                ? prev.slice(0, -1)
                : prev),
              cur,
            ],
            [] as TagItem[],
          )
          .map((item) => ({
            ...(nameDictKeys.includes(item.key)
              ? nameDict[item.key as TypeNameDict].type === "f"
                ? nameDict[item.key as TypeNameDict]
                : { type: "f", label: nameDict[item.key as TypeNameDict].label, object_id: "" }
              : { type: "f", label: item.key, object_id: "" }),
            score: item.score,
          }))
          .sort((a, b) => a.score - b.score),
        beneficiary: res
          .reduce((prev, cur) => [...prev, ...cur.beneficiary], [] as TagItem[])
          .sort((a, b) => a.key.localeCompare(b.key))
          .reduce(
            (prev, cur) => [
              ...(prev.length > 0 && prev[prev.length - 1].key === cur.key
                ? prev.slice(0, -1)
                : prev),
              cur,
            ],
            [] as TagItem[],
          )
          .map((item) => ({
            ...(nameDictKeys.includes(item.key)
              ? nameDict[item.key as TypeNameDict].type === "b"
                ? nameDict[item.key as TypeNameDict]
                : { type: "b", label: nameDict[item.key as TypeNameDict].label, object_id: "" }
              : { type: "b", label: item.key, object_id: "" }),
            score: item.score,
          }))
          // .filter((item) => item.parent)
          .sort((a, b) => a.score - b.score),
        program: res
          .reduce((prev, cur) => [...prev, ...cur.program], [] as TagItem[])
          .sort((a, b) => a.key.localeCompare(b.key))
          .reduce(
            (prev, cur) => [
              ...(prev.length > 0 && prev[prev.length - 1].key === cur.key
                ? prev.slice(0, -1)
                : prev),
              cur,
            ],
            [] as TagItem[],
          )
          .map((item) => ({
            ...(nameDictKeys.includes(item.key)
              ? nameDict[item.key as TypeNameDict].type === "p"
                ? nameDict[item.key as TypeNameDict]
                : { type: "p", label: nameDict[item.key as TypeNameDict].label, object_id: "" }
              : { type: "p", label: item.key, object_id: "" }),
            score: item.score,
          }))
          .sort((a, b) => a.score - b.score),
      };
    });
  }, [cache]);
  return state;
};

export default useTaxonomyTagging;
