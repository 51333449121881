import classNames from "classnames";
import { useState, useCallback } from "react";
import { SaveItemList } from "../types/save";
import useUser from "../hooks/useUser";
import { useNavigate } from "react-router-dom";
import UserProfile from "./UserProfile";
import { reprTimeDistance } from "../utils/formatHelper";
import RoundButton from "./RoundButton";
import { SearchQuery } from "../types/search";
import { Menu, Transition } from "@headlessui/react";
import useMySavedList from "../hooks/save/useMySavedList";
import LeftInnerIconInput from "./LeftInnerIconInput";
import LongTextInput from "./LongTextInput";

export function SaveList({
  saveList: d,
  onClick,
  saved = false,
  onClosePopup,
  pageView = false,
  onDelete,
  onRename,
  onUpdate,
}: // query,
{
  saveList: SaveItemList;
  onClick?: () => void;
  saved?: boolean;
  onClosePopup?: () => void;
  pageView?: boolean;
  onDelete?: () => Promise<void>;
  onRename?: (newName: string) => void;
  onUpdate?: (newName: string, newDescription: string) => void;
  // query?: SearchQuery;
}) {
  // const { deleteSavedList } = useMySavedList();
  const [open, setOpen] = useState(false);
  const [user] = useUser();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [name, setName] = useState<string>(d.name);
  const [description, setDescription] = useState<string>(d.description || "");

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleEditClick = () => {
    setIsEditModalOpen(true);
  };

  const cancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const cancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = async () => {
    try {
      await onDelete?.();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting saved list", error);
      setIsDeleteModalOpen(false);
      // Optionally handle the error (e.g., show a notification to the user)
    }
  };
  const confirmEdit = async () => {
    try {
      const updatedList = await onUpdate?.(name, description);
      console.log(updatedList);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error editing the saved list", error);
      setIsEditModalOpen(false);
    }
  };
  const validate = useCallback(() => {
    if (!name) {
      setDirty(true);
      return false;
    }
    return true;
  }, [name, setDirty]);

  return (
    <div
      key={d._id}
      className={classNames(
        "flex w-full cursor-pointer flex-col gap-2 rounded border border-gray-300 p-4",
        onClick ? "bg-white hover:bg-gray-100" : "",
      )}
      onClick={onClick}
    >
      <div className="flex h-7 justify-between">
        <h5
          className="font-semibold text-purple-500 hover:underline"
          onClick={(e) => {
            console.log(user);
            e.stopPropagation(); // avoid adding to list
            e.preventDefault();
            if (onClosePopup) onClosePopup();
            navigate(`/saved/${d._id}`); // { state: { context: query } }
          }}
        >
          {d.name}
        </h5>
        {pageView ? (
          <div>
            <div className="relative inline-block text-left">
              <Menu as="div" className="inline-block text-left">
                <div>
                  <Menu.Button
                    className="inline-flex w-full justify-center rounded-md  bg-opacity-20 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-opacity-30"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpen(!open);
                    }}
                  >
                    {/* Replace with your icon */}
                    <i className="fa-solid fa-ellipsis-vertical text-gray-700"></i>
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    onMouseLeave={() => {
                      setOpen(!open);
                    }}
                    className="absolute right-0 z-10 mt-2 w-[186px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-100 text-black" : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm",
                            )}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleEditClick();
                              // const newName = prompt(
                              //   "Enter a new name for the saved list:",
                              //   d.name,
                              // );
                              // if (newName && newName.trim() !== "" && newName !== d.name) {
                              //   onRename?.(newName);
                              // }
                            }}
                          >
                            Edit
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={classNames(
                              active ? "bg-gray-100 text-black" : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm",
                            )}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleDeleteClick();
                              // onDelete?.();
                              // handleDelete(d._id);
                            }}
                          >
                            Delete
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        ) : (
          <div>
            {saved ? (
              <div className="flex h-7 w-7 items-center justify-center rounded-full bg-purple-500">
                <i className="gi-sm gi-check !font-semibold text-white" />
              </div>
            ) : (
              <div className="flex h-7 w-7 items-center justify-center rounded-full border border-gray-600 bg-white">
                <i className="gi-sm gi-check !font-semibold text-gray-600" />
              </div>
            )}
          </div>
        )}

        {/* {user?._id && user?._id !== d.user_id && (
          <div className="flex gap-2">
            <h6>Shared by</h6>
            <UserProfile userId={d.user_id} />
          </div>
        )} */}
      </div>
      <div className="flex-grow">
        <p className="text-xs font-normal text-gray-700">{d.description ? d.description : " "}</p>
      </div>
      <div className="flex flex-col gap-1">
        <h5 className="text-black">{`${d.size} saved`}</h5>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <i className="gi gi-calendar text-gray-700" />
            <h6 className="text-gray-700">{`Updated ${reprTimeDistance(d.updated_at)}`}</h6>
          </div>
        </div>
      </div>
      {isEditModalOpen && (
        <div
          className="fixed inset-0 z-50  bg-black bg-opacity-50"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            cancelEdit();
          }}
        >
          <div className="flex h-screen items-center justify-center p-4">
            <div className="flex w-[600px] flex-col justify-between rounded-lg bg-white shadow-xl">
              <div className="edit_modal_header flex items-center justify-between p-6 text-center">
                <h3 className=" text-2xl font-medium">Edit list</h3>
                <button
                  className="rounded bg-none p-2  hover:bg-gray-300"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    cancelEdit();
                  }}
                >
                  <i className="gi-x text-2xl"></i>
                </button>
              </div>

              <div className="flex h-full w-full flex-col items-center justify-center border-y-[1px] border-solid border-gray-300">
                <div
                  className={classNames("flex h-[300px] w-full flex-col gap-7 p-6")}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <div className="flex flex-col gap-2">
                    <h5 className="font-semibold">Name</h5>
                    <LeftInnerIconInput
                      id="input-name"
                      handleOnChange={(e) => setName(e.target.value)}
                      defaultValue={d.name}
                      placeholder="Enter name"
                    />
                    {dirty && !name && (
                      <h6 className="font-semibold text-purple-500">Name required.</h6>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <h5 className="font-semibold">Description (Optional)</h5>
                    <LongTextInput
                      id="input-desc"
                      height="120px"
                      handleOnChange={(e) => setDescription(e.target.value)}
                      defaultValue={d.description}
                      placeholder="Enter description"
                    />
                  </div>
                </div>
              </div>
              <div className=" flex justify-end  p-6">
                <button
                  className="rounded bg-purple-500 px-4 py-2 text-sm text-white hover:bg-purple-700"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (validate()) {
                      confirmEdit();
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isDeleteModalOpen && (
        <div
          className="fixed inset-0 z-50  bg-black bg-opacity-50"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            cancelDelete();
          }}
        >
          <div className="flex h-screen items-center justify-center p-4">
            <div className="flex h-[290px] w-[568px] flex-col justify-between rounded-lg bg-white shadow-xl">
              <div className="delete_modal_header flex items-center justify-between p-6 text-center">
                <h3 className=" text-2xl font-medium">Delete list</h3>
                <button
                  className="rounded bg-none p-2  hover:bg-gray-300"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    cancelDelete();
                  }}
                >
                  <i className="gi-x text-2xl"></i>
                </button>
              </div>

              <div className="flex h-full w-full flex-col items-center justify-center border-y-[1px] border-solid border-gray-300">
                <p className="mt-2 text-sm text-purple-500">
                  Are you sure you want to delete this saved list?
                </p>
                <p className="text-sm text-gray-500">This action cannot be undone.</p>
              </div>
              <div className=" flex justify-end  p-6">
                <button
                  className="rounded bg-purple-500 px-4 py-2 text-sm text-white hover:bg-purple-700"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    confirmDelete();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
