import axios from "axios";
import { useEffect, useState } from "react";
import useSWR from "swr";
import config from "../../api";
import { IGrant } from "../../types/grant";
import { FunderSearchResult, GrantSearchResult, SearchQuery } from "../../types/search";
import {
  filterUnmatched,
  matchTaxonomyBySearchQueryWithPhilanthropy,
  unnestTaxonomy,
} from "../../types/taxonomy";
import useFunderSearchResult from "./useFunderSearchResult";
import useGrantSearchResults from "./useGrantSearchResults";

interface GrantSearchResultState {
  data: GrantSearchResult | undefined;
  dataAll: GrantSearchResult | undefined;
  dataUnMatched: GrantSearchResult | undefined;
  isLoading: boolean;
  error: object;
}
const swrOption = {
  dedupingInterval: 1000 * 300,
  refreshInterval: 0,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
const setTransformedFunc = (
  grantDetail?: IGrant,
  funder?: FunderSearchResult,
): GrantSearchResult | undefined => {
  return grantDetail
    ? {
        ...grantDetail,
        search_type: "grant",
        rank_conf: 0,
        name: grantDetail.title || "",
        private: grantDetail.solicitation && grantDetail.solicitation === "referral" ? true : false,
        rolling: !grantDetail.deadline_application
          ? false
          : grantDetail.deadline_application.find((d) => d.type === "rolling")
          ? true
          : false,
        service_loc_text: grantDetail.projectLocation || "",
        npo_loc_text: grantDetail.npoLocation || "",
        uid: -1,
        specific_grant_amount: grantDetail.grant_amount_type === "fixed",
        grant_amount_min:
          grantDetail.grant_amount_type === "fixed"
            ? grantDetail.grant_amount || 0
            : grantDetail.grant_amount_min || 0,
        grant_amount_max:
          grantDetail.grant_amount_type === "fixed"
            ? grantDetail.grant_amount || 0
            : grantDetail.grant_amount_max || 0,
        agency_name: "",
        fiscal_position: grantDetail.fiscal_position || [],
        ...unnestTaxonomy(grantDetail),
        // focus_area:
        //   grantDetail.focus_area?.map((t: string) => ({ label: t, matched: false })) || [],
        // beneficiary:
        //   grantDetail.beneficiary?.map((t: string) => ({ label: t, matched: false })) || [],
        // program: grantDetail.program?.map((t: string) => ({ label: t, matched: false })) || [],
        service_loc: grantDetail.service_loc || [],
        npo_loc: grantDetail.npo_loc || [],
        service_specific_loc: grantDetail.service_specific_loc === false ? false : true,
        npo_specific_loc: grantDetail.npo_specific_loc || false,
        donor: funder ? funder : undefined,
        funding_type: grantDetail.funding_type || [],
      }
    : undefined;
};
export default function useGrantSearchResult(
  id?: string,
  transformFromDocumentDB = false,
  query?: SearchQuery,
): GrantSearchResultState {
  const { data, isLoading, error } = useGrantSearchResults(id ? [id] : []);
  const fetch2 = async (_url: string) => {
    if (!_url) return new Promise<GrantSearchResult>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data);
  };
  const { data: grantDetail } = useSWR<IGrant>(
    id && transformFromDocumentDB ? process.env.REACT_APP_API_URL + `/api/v2/grants/${id}` : "",
    fetch2,
  );
  const { data: funder } = useFunderSearchResult(
    transformFromDocumentDB && grantDetail?.donor_id ? grantDetail.donor_id : undefined,
  );
  const [transformed, setTransformed] = useState<GrantSearchResult | undefined>(
    setTransformedFunc(grantDetail, funder),
  );
  useEffect(() => {
    setTransformed(setTransformedFunc(grantDetail, funder));
  }, [grantDetail, funder]);

  const [all, setAll] = useState<GrantSearchResult | undefined>();
  const [matched, setMatched] = useState<GrantSearchResult | undefined>();
  const [unMatched, setUnMatched] = useState<GrantSearchResult | undefined>();
  useEffect(() => {
    const g = transformFromDocumentDB ? transformed : data && data.length > 0 ? data[0] : undefined;
    setAll(
      g
        ? query
          ? {
              ...g,
              ...matchTaxonomyBySearchQueryWithPhilanthropy(
                g,
                query,
                g.service_specific_loc,
                g.type === "grant-page" ? false : true,
              ),
            }
          : g
        : undefined,
    );
  }, [transformFromDocumentDB, transformed, data, query]);
  useEffect(() => {
    if (isLoading || !all) return;
    setMatched({ ...all, ...filterUnmatched(all, false) });
    setUnMatched({
      ...all,
      focus_area: all?.focus_area?.filter((t) => !t.matched),
      beneficiary: all?.beneficiary?.filter((t) => !t.matched),
      program: all?.program?.filter((t) => !t.matched),
      service_loc: all?.service_loc?.filter((t) => !t.matched),
    });
  }, [all, isLoading]);
  return {
    data: matched,
    dataAll: all,
    dataUnMatched: unMatched,
    isLoading,
    error,
  };
}
