import classNames from "classnames";
import React, { ReactNode } from "react";
import { Popover, Whisper } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { zIndexTooltip } from "../types/zIndex";
export const INFO_SORT_OPTION: any = {
  total_matched_grant_amount: (
    <>
      <b>Grant amount:</b> This sorting option arranges the total amount of past grants linked to
      the matched tags. It provides a comprehensive overview of the funding amount associated with
      these tags, facilitating a quick assessment of the scale of past grant directed towards
      specific areas. It assists in prioritizing areas that have received substantial funding.
    </>
  ),
  total_matched_grantee_number: (
    <>
      <b>Number of grantees:</b> This sorting option arranges the number of grantees that include
      the specified tags. It helps prioritizing insights into the distribution of support, revealing
      how many organizations the funder has supported to the matched tags.
    </>
  ),
  total_grants_in_your_stage: (
    <>
      <b>Grant amount allocated in my stage:</b> This sorting option arranges the total grant amount
      allocated to grantees with matched tags in the same stage as your organization. It helps in
      prioritizing funding distribution specific to your organization&aops;s stage, providing
      valuable insights into the allocation of resources within your developmental phase.
    </>
  ),
  grant_in_matched_area_perc: (
    <>
      <b>% of the amount allocated:</b> This sorting option arranges the percentage of the total
      grant amount allocated to matched tags relative to the overall funding. It helps in
      prioritizing organization with higher proportions, ensuring a strategic allocation of
      resources to areas that significantly contribute to the overall mission.
    </>
  ),
  grants_in_my_stage_perc: (
    <>
      <b>% of the amount allocated in my stage:</b> This sorting option arranges the percentage of
      grants given to organizations in the same stage related to matched tags over the total grant
      amount. It assists in prioritizing funding concentration within your organization&aops;s
      stages, providing insights into how resources are distributed across different developmental
      phases.
    </>
  ),
  relevance: (
    <>
      <b>Relevance:</b> This sorting option is arranges based on your search input and unique
      profile. It assists in prioritizing results based on the relevance to your specific search and
      profile, ensuring that the displayed information aligns closely with your individual interests
      and needs.
    </>
  ),
};
export const INFO_FUNDER_TYPE_OPTION: any = {
  family: (
    <>
      <b>Family foundation:</b> A philanthropic entity established and funded by a family or
      individual with the primary purpose of contributing to charitable causes. This tag includes
      foundations classified under &apos;T20 - Private Grantmaking Foundations&apos; in the NTEE
      system, encompassing a range of grantmaking organizations driven by family or individual
      philanthropy.
    </>
  ),
  corporate: (
    <>
      <b>Corporate foundation:</b> A corporate foundation is a philanthropic entity established and
      funded by a for-profit corporation. It operates independently from the business activities of
      the parent company, with a distinct governance structure, mission, and grantmaking process,
      focusing on charitable giving and community impact.
    </>
  ),
  community: (
    <>
      <b>Community foundation:</b> These foundations serve specific geographic areas, such as
      cities, regions, or counties. They pool donations from various sources and distribute grants
      to nonprofits and projects within their designated communities.
    </>
  ),
  private: (
    <>
      <b>Private foundation:</b> Private foundations, also known as ‘Private Independent
      Foundations’, are organizations that make grants based on charitable endowments. Their primary
      focus is on grant-making, and they typically do not actively raise funds or seek public
      financial support. Private foundations are commonly endowed, often by a single individual,
      family, or corporation.
    </>
  ),
  private_operating: (
    <>
      <b>Private operating foundation:</b> A private operating foundation is a type of private
      foundation that primarily allocates a significant portion of its resources to directly provide
      charitable services or run charitable programs, rather than making extensive grants to
      external organizations.
    </>
  ),
  public: (
    <>
      <b>Public foundation:</b> A public foundation, classified under T30 in the NTEE system, is
      funded by a diverse donor base, engaging in broad charitable activities like grantmaking and
      community initiatives with wide public support.
    </>
  ),
  daf: (
    <>
      <b>Donor-Advised Fund (DAF):</b> A Donor-Advised Fund (DAF) is a vehicle of foundation that
      provides a specific avenue for donors to direct their contributions, primarily for grant
      recommendations. Our filter offers clarity to nonprofits, allowing them to discern foundations
      that utilize DAFs.
    </>
  ),
  federated_giving: (
    <>
      <b>Federated giving program:</b> A federated giving program is a philanthropic approach where
      multiple charitable organizations collaborate under a common umbrella, often known as a
      federation or united campaign.
    </>
  ),
  funding_intermediary: (
    <>
      <b>Funding intermediary:</b> Nonprofit organizations primarily engaged in fundraising and/or
      fund distribution act as intermediaries. They do not provide direct services but instead
      facilitate fundraising activities and contribute to the development of specific domains.
    </>
  ),
  hybrid: (
    <>
      <b>Hybrid nonprofit:</b> A Hybrid nonprofit is an organization that not only directly provides
      services to communities in need but also actively engages in making grants or contributions
      directly to other nonprofits or individuals.
    </>
  ),
  service_provider: (
    <>
      <b>Direct service provider:</b> A direct service provider is a nonprofit organization or
      entity that directly delivers programs, services, or assistance to individuals, communities,
      or target populations. Instead of primarily granting funds or support to other organizations,
      a direct service provider engages directly with the end beneficiaries, addressing their needs,
      and delivering specific services or interventions to achieve its mission and goals.
    </>
  ),
  field_builder: (
    <>
      <b>Field builder:</b> A field builder is an entity or initiative that actively contributes to
      the development, capacity building, and progress of a specific sector or field. They play
      crucial roles in strengthening organizations, encouraging collaboration, offering resources,
      and endorsing best practices to improve the overall effectiveness and impact of entities
      within that field.
    </>
  ),
  federal: (
    <>
      <b>Federal agency:</b> The U.S. federal agency offers funding opportunities through various
      agencies and departments. This includes grants for research, education, healthcare, social
      services, infrastructure, and more. Examples of federal agencies providing funding include the
      National Institutes of Health (NIH), the National Science Foundation (NSF), and the Department
      of Education.
    </>
  ),
  state: (
    <>
      <b>State agency:</b> Each state in the U.S. has its own government agencies and departments
      that provide grants and funding for state-specific projects, programs, and initiatives. These
      grants can cover areas like education, healthcare, economic development, and environmental
      conservation.
    </>
  ),
  local: (
    <>
      <b>Local agency:</b> Local agency, such as cities and counties, offer funding for community
      projects, public services, and local initiatives. These grants may support programs like
      public safety, housing, transportation, and cultural events.
    </>
  ),
};

export const INFO_SOLICITATION_OPTION: any = {
  open: (
    <>
      <b>Accepts unsolicited proposals:</b> When a nonprofit funder indicates that it &quot;accepts
      unsolicited proposals,&quot; it signifies their openness to receiving grant proposals without
      a prior invitation. This allows organizations or individuals to voluntarily submit their
      proposals for consideration. Giboo gathers information about a funder&apos;s acceptance of
      unsolicited proposals by analyzing details on the funder&apos;s website, grant guidelines,
      annual reports, or by directly contacting the funder for specific information.
    </>
  ),
  private: (
    <>
      <b>Invitation only:</b> Nonprofit funders using &apos;invitation only&apos; mean they
      don&apos;t accept unsolicited grant proposals. Giboo gathers details on their grant practices
      from the funder&apos;s website, guidelines, or direct communication. For private foundations,
      insights can be found in the Form 990-PF, offering specific information about their
      grantmaking activities.
    </>
  ),
};
export const INFO_OTHERS: any = {
  FUNDING_STAGE_ANALYSIS: (
    <>
      <b>Funding stage analysis:</b> Funding stage indicates the specific phase of nonprofit
      organizations that a funder has historically supported. This information is valuable for grant
      seekers, helping them assess whether a prospective funder has a track record of funding
      organizations in similar stages, aiding in more targeted and relevant grant applications.
    </>
  ),
  CONTACT: (
    <>
      <b>Contact availability:</b> Contact availability encompasses various connection points
      provided by a funder. Giboo extracts this data from the funder&apos;s website, annual report,
      grant pages, IRS 990, and IRS 990 PF forms. Though not all funders provide a complete set of
      contact details, these sources often offer relevant information. This is a valuable resource
      for nonprofit grant seekers seeking to connect with funders.
    </>
  ),
  HQ: (
    <>
      The headquarters or primary office location of a funder is distinct from the service areas
      where the funder allocates grants or contributions. Consider proximity of your headquarter
      location to enhance strategic decision-making.
    </>
  ),
  SERVICE_AREA: (
    <>
      Service areas denote geographic locations where nonprofits offer services. For funders,
      service areas represent regions where grants or contributions are made. Discover grants or
      funders supports your service areas.
    </>
  ),
  SEARCH_KEYWORDS: (
    <>
      Giboo transforms your inputs into search tags, improving both structured and flexible searches
      for personalized outcomes. Use &apos;must have&apos; criteria for essential precision, while
      &apos;good to have&apos; criteria add optional preferences, and ‘exclude’ to remove from the
      search result.
    </>
  ),
  ORG_ID: (
    <>
      An &apos;Organization ID&apos; is a unique alphanumeric code assigned to entities,
      encompassing identifiers like the US Employer Identification Number (EIN), international
      Company Registration Numbers, and Legal Entity Identifiers. Used for administrative and
      financial purposes, it uniquely distinguishes organizations, facilitating tracking and
      identification on a global scale.
    </>
  ),
  INCORPORATED_STATUS: (
    <>
      Incorporated status refers to an organization&apos;s legal structure as a formally recognized
      entity. It signifies completion of the incorporation process, providing the entity with
      distinct legal standing.
    </>
  ),
  TAX_EXEMPT_STATUS: (
    <>
      Tax-exempt status refers to an organization&apos;s exemption from certain taxes, such as
      income tax, under designations like the US 501(c)(3). This status allows eligible entities to
      operate without tax obligations on qualifying activities, offering financial advantages and
      exemptions.
    </>
  ),
  ORG_STAGE: (
    <>
      The term &quot;organizational stage&quot; includes phases such as individuals, Preseed/Seed,
      Startup, Superstar, and Hyperstar. These stages are derived from years of information,
      including tax forms. Data analysis helps in evaluating the different developmental stages of
      an organization.
    </>
  ),
  FUNDING_SIZE: (
    <>
      The range of received funding represents the grants that the organization received from
      diverse sources, such as foundations and governments. Inclusion of individual contributions
      varies, and the data may not reflect the most recent year.
    </>
  ),
};
export const INFO_GRANT_TYPE_OPTION: any = {
  opencall: (
    <>
      <b>Open call grant:</b> Including Request for Proposals (RFPs), offers organizations solicited
      opportunities to address broad or specific themes. Explore the open call page for
      comprehensive details on these valuable funding opportunities.
    </>
  ),
  virtual: (
    <>
      <b>Virtual grant:</b>{" "}
      <b className="text-purple-500">Potential grant opportunities generated by Giboo</b>, leverage
      historical patterns from the funder&apos;s grant-making history. The content on this page is
      derived from past funding patterns, offering insights, but may not precisely reflect current
      open call and/or Request for Proposals (RFPs) opportunities issued by the funder.
    </>
  ),
  pastgrant: (
    <>
      <b>Past grant:</b> Funder’s history of supporting nonprofit organizations, reflecting previous
      grants awarded, aiding in informed decision-making for future funding.
    </>
  ),
};
export const INFO_FUNDING_TYPE_OPTION: any = {
  general: (
    <>
      <b>General operating grant (Unrestricted):</b> Also called unrestricted grants, these funds
      are provided to organizations without specific usage restrictions. Nonprofits can use them for
      daily operational expenses, including salaries, rent, utilities, and administrative costs.
    </>
  ),
  program: (
    <>
      <b>Program and project-related funds:</b> Grants designated for specific programs or projects,
      often with restrictions on usage. Nonprofits must use these funds exclusively for the intended
      program or initiative as outlined in the grant application.
    </>
  ),
  capacity: (
    <>
      <b>Capacity-building grants:</b> Targeted funding to enhance organizational capacity,
      encompassing staff development, technology upgrades, strategic planning, and infrastructure
      improvements.
    </>
  ),
  community: (
    <>
      <b>Community support grants:</b> Financial assistance provided for initiatives, projects, or
      activities within a community, aiming to contribute to the well-being and development of that
      specific community.
    </>
  ),
  "endowment/capital": (
    <>
      <b>Endowment grants:</b> Large and often permanent funds invested to offer enduring financial
      support, with an annual spending limited to a portion of the generated income.
    </>
  ),
  capital: (
    <>
      <b>Capital grants:</b> Funds allocated for specific projects, emphasizing the acquisition,
      construction, renovation, or maintenance of physical assets such as buildings, equipment, or
      infrastructure. Typically restricted to a specific purpose for the development or enhancement
      of these assets.
    </>
  ),
  fellowship: (
    <>
      <b>Research/Fellowship grants:</b> Financial support for individuals or organizations engaged
      in focused study, research, or professional development. Covers living expenses and associated
      costs during the fellowship period
    </>
  ),
  research: (
    <>
      <b>Research/Fellowship grants:</b> Financial support for individuals or organizations engaged
      in focused study, research, or professional development. Covers living expenses and associated
      costs during the fellowship period
    </>
  ),
  matching: (
    <>
      <b>Matching grants:</b> Funds committed to matching recipient organization&apos;s raised
      funds, often on a dollar-for-dollar basis, incentivizing the organization to seek additional
      financial support from other donors or sources.
    </>
  ),
  religious: (
    <>
      <b>Religious grants:</b> Funds provided to religious organizations or projects aligned with
      missions and values. These grants support activities, community outreach, charitable
      endeavors, or specific projects in line with religious goals
    </>
  ),
  scholarship: (
    <>
      <b>Scholarship grants:</b> Merit-based financial assistance for students, covering tuition and
      related educational expenses without the need for repayment
    </>
  ),
  others: (
    <>
      <b>Other grants:</b> Encompassing all unspecified grant types. The use of funds may vary and
      is determined by the funders.
    </>
  ),
};
type tagType =
  | "FUNDER_TYPE_OPTION"
  | "FUNDING_TYPE_OPTION"
  | "SOLICITATION_OPTION"
  | "GRANT_TYPE_OPTION"
  | "OTHERS"
  | "SORT_OPTION";
type tagValue =
  | keyof typeof INFO_FUNDER_TYPE_OPTION
  | keyof typeof INFO_FUNDING_TYPE_OPTION
  | keyof typeof INFO_SOLICITATION_OPTION
  | keyof typeof INFO_GRANT_TYPE_OPTION
  | keyof typeof INFO_OTHERS
  | keyof typeof INFO_SORT_OPTION;

export default function TagInfo({
  type,
  value,
  custom,
  wrapperClass,
  iconClass,
  color = "gray",
  placements = "rightStart",
}: {
  type: tagType;
  custom?: ReactNode;
  wrapperClass?: string;
  iconClass?: string;
  value: tagValue;
  placements?: TypeAttributes.Placement;
  color?: "gray" | "white";
}) {
  const getContent = () => {
    const options = {
      FUNDER_TYPE_OPTION: INFO_FUNDER_TYPE_OPTION,
      FUNDING_TYPE_OPTION: INFO_FUNDING_TYPE_OPTION,
      GRANT_TYPE_OPTION: INFO_GRANT_TYPE_OPTION,
      SOLICITATION_OPTION: INFO_SOLICITATION_OPTION,
      SORT_OPTION: INFO_SORT_OPTION,
      OTHERS: INFO_OTHERS,
    };
    const result = options[type]?.[value];
    return result !== undefined ? result : "";
  };
  return (
    <Whisper
      trigger={"hover"}
      placement={placements}
      className=" shadow-none"
      speaker={
        <Popover
          id="hover_tooltip"
          prefix="tooltip-hover-search-plus"
          className={classNames(
            "!mt-[-2px] w-[323px] rounded border border-gray-300 bg-gray-50 p-4",
            wrapperClass,
            zIndexTooltip,
          )}
          arrow={false}
        >
          {custom ? custom : <h6>{getContent()}</h6>}
        </Popover>
      }
    >
      <span className={classNames("grid h-[18px] w-[18px] place-items-center  p-[2px]", iconClass)}>
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 14.25C11.7279 14.25 14.75 11.2279 14.75 7.5C14.75 3.77208 11.7279 0.75 8 0.75C4.27208 0.75 1.25 3.77208 1.25 7.5C1.25 11.2279 4.27208 14.25 8 14.25Z"
            stroke={color === "white" ? "#fff" : "#5F5F5F"}
            strokeWidth="1.125"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 4.5H8.00833V4.50833H8V4.5Z"
            stroke={color === "white" ? "#fff" : "#5F5F5F"}
            strokeWidth="1.6875"
            strokeLinejoin="round"
          />
          <path
            d="M8 7.5V10.5"
            stroke={color === "white" ? "#fff" : "#5F5F5F"}
            strokeWidth="1.125"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </Whisper>
  );
}
