import React, { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../app/store";
import tutorSlice, { selectTutor } from "../app/tutorSlice";
import classNames from "classnames";
import { setUserDictionaryValue } from "../hooks/useUserDictionary";
import { useNavigate } from "react-router-dom";
import useOnboardingData from "../hooks/useOnboarding";
import useUser from "../hooks/useUser";
import { Steps, Hints } from "intro.js-react";
import { JsxElement } from "typescript";
interface IApptourProps {
  id: string;
  tutorType: TutorType;
}
interface Step {
  element?: string | HTMLElement | Element;
  intro: string | React.ReactNode;
  position?: string;
  title?: string;
  tooltipClass?: string;
  highlightClass?: string;
}
export enum TutorType {
  MYHOME = "my-home",
  MYHOME_SEARCH = "my-home-search",
  OPTION_SEARCH = "my-home-option-search",
  MY_ORG = "my-organization",
  MY_PROFILE = "my-profile",
  SERCH_PAGE = "search_page",
  SERCH_PAGE_1 = "search_page_1",
  DONOR_DETAIL = "donor-detail",
  PROJECT_MGT = "project-management-page",
  LOI = "loi-page",
}
function AppTour({ id, tutorType }: IApptourProps) {
  const [user] = useUser();
  const currentTour = useAppSelector((state: RootState) => selectTutor(state, tutorType));
  const [currentStep, setCurrentStep] = useState(0);
  const showFooter = [
    TutorType.MYHOME_SEARCH,
    TutorType.DONOR_DETAIL,
    TutorType.LOI,
    TutorType.MY_ORG,
    TutorType.MY_PROFILE,
    TutorType.SERCH_PAGE_1,
  ].includes(tutorType)
    ? false
    : true;

  const dispatch = useAppDispatch();
  const stepsRef = useRef<any>(null);

  // STEP LIST
  const HOME_GUIDANCE: Step[] = [
    {
      element: ".my-home-step-1",
      position: "bottom",
      intro: customContent(
        <div>
          <h6 className="font-poppins text-base font-semibold">
            Welcome to Giboo, {user?.firstname || ""}!
          </h6>
          <p className="mt-2 text-sm">
            To kick things off, take a few minutes to explore Giboo&apos;s main features. You will
            soon be able to expertly use Giboo to take your fundraising to the next level!
          </p>
        </div>,
      ),
    },
    {
      element: ".my-home-step-2",
      position: "bottom",
      intro: customContent(
        <div>
          <p className="text-sm">
            Type in your philanthropy focus to search for best-fit funders and grant opportunities.
            You can also use names to search for funders or other NPOs.
          </p>
        </div>,
      ),
    },
  ];
  const HOME_GUIDANCE_SEARCH: Step[] = [
    {
      element: ".my-home-step-3",
      position: "right",
      intro: customContent(
        <div>
          <p className="text-sm">
            Try searching for potential grants tailored to your organization!
          </p>
        </div>,
      ),
    },
    // {
    //   element: ".my-home-step-4",
    // disableInteraction:true,
    // exitOnOverlayClick:false,
    //  intro: (
    //     <div>
    //       <p className="text-sm">
    //         We have pre-loaded your service location and headquarter location based on your profile.
    //         You may edit or make adjustments to tailor your search.
    //       </p>
    //     </div>
    //   ),
    // },
  ];
  const HOME_GUIDANCE_OPTION_SEARCH: Step[] = [
    {
      element: ".my-home-step-4",
      position: "bottom",
      intro: customContent(
        <div>
          <p className="text-sm">
            We have pre-loaded your service location and headquarter location based on your profile.
            You may edit or make adjustments to tailor your search.
          </p>
        </div>,
      ),
    },
  ];
  const SEARCH_PAGE: Step[] = [
    {
      element: ".search-step-3",
      position: "bottom",
      intro: customContent(
        <div>
          <p className="text-sm">
            Giboo transforms your inputs into search tags, improving both structured and flexible
            searches for personalized outcomes.
          </p>
        </div>,
      ),
    },
    {
      element: ".search-step-1",
      position: "right",
      intro: customContent(
        <div>
          <p className="text-sm">Use more search options to refine the search results.</p>
        </div>,
      ),
    },
  ];
  const SEARCH_PAGE_1: Step[] = [
    {
      element: ".search-step-2",
      position: "right",
      intro: customContent(
        <div>
          <p className="text-sm">Looks like a good opportunity? Let’s start fundraising!</p>
        </div>,
      ),
    },
  ];
  const PROJECT_MANAGEMENT: Step[] = [
    {
      element: ".project-mgt-step-1",
      intro: customContent(
        <div>
          <p className="text-sm">
            Add funders and grants to this project and track the progress of your task.
          </p>
        </div>,
      ),
    },
    {
      element: ".project-mgt-step-2",
      position: "left",
      intro: customContent(
        <div>
          <p className="text-sm">Invite your colleagues to collaborate on this project.</p>
        </div>,
      ),
    },
    {
      element: ".project-mgt-step-3",
      position: "right",
      intro: customContent(
        <div>
          <p className="text-sm">
            Interested in making this project public? Click to provide more information and showcase
            your project!
          </p>
        </div>,
      ),
    },
  ];
  const MY_ORG: Step[] = [
    {
      element: ".my-org-step-1",
      position: "left",
      intro: customContent(
        <div>
          <p className="text-sm">
            Complete these tasks to finalize your organization profile and showcase it to the
            public!
          </p>
        </div>,
      ),
    },
  ];
  const MY_PROFILE: Step[] = [
    {
      element: ".my-profile-step-1",
      position: "left",
      intro: customContent(
        <div>
          <p className="text-sm">
            Share details about you to showcase diversity information on your profile. This will
            help present a comprehensive picture of your organization.
          </p>
        </div>,
      ),
    },
  ];
  // TUTOR FUNCTIONALITY
  const steps: Step[] =
    tutorType === TutorType.MYHOME
      ? HOME_GUIDANCE
      : tutorType === TutorType.MYHOME_SEARCH
      ? HOME_GUIDANCE_SEARCH
      : tutorType === TutorType.SERCH_PAGE
      ? SEARCH_PAGE
      : tutorType === TutorType.SERCH_PAGE_1
      ? SEARCH_PAGE_1
      : tutorType === TutorType.OPTION_SEARCH
      ? HOME_GUIDANCE_OPTION_SEARCH
      : tutorType === TutorType.PROJECT_MGT
      ? PROJECT_MANAGEMENT
      : tutorType === TutorType.MY_ORG
      ? MY_ORG
      : tutorType === TutorType.MY_PROFILE
      ? MY_PROFILE
      : [];

  function customContent(content: ReactNode): ReactElement {
    return <>{content}</>;
  }
  const stopTour = (type: TutorType, paused?: boolean, pausedIndex?: number) => {
    dispatch(
      tutorSlice.actions.stopTutor({ type: type, isPaused: paused ? true : false, pausedIndex }),
    );
  };
  const handleDone = (type: TutorType) => {
    setUserDictionaryValue(type, { type: type, completed: true, start: false });
  };
  const overlayOpacity = [TutorType.MYHOME_SEARCH].includes(tutorType) ? 0 : 1;
  const disableInteraction = [TutorType.MYHOME_SEARCH].includes(tutorType) ? false : true;
  const exitOnOverlayClick = [TutorType.MYHOME_SEARCH].includes(tutorType) ? false : false;
  return (
    <Steps
      ref={stepsRef}
      enabled={currentTour?.start || false}
      steps={steps}
      initialStep={0}
      onChange={(step) => {
        setCurrentStep(step);
      }}
      onAfterChange={(index, newElement) => {
        if (index !== 0) return;
        if ([TutorType.MYHOME, TutorType.PROJECT_MGT, TutorType.MY_ORG].includes(tutorType)) {
          newElement.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" });
        }
      }}
      options={{
        nextLabel: "Next",
        prevLabel: "Back",
        showBullets: steps.length > 1,
        showButtons: steps.length > 1,
        exitOnOverlayClick: exitOnOverlayClick,
        disableInteraction: disableInteraction,
        overlayOpacity: overlayOpacity,
        scrollToElement: true,
        scrollTo: "element",
      }}
      onExit={() => {
        tutorType && stopTour(tutorType);
        handleDone(tutorType);
      }}
    />
  );
}

export default AppTour;
