import classNames from "classnames";
import { stageKeys, stageLabels, TypeStage } from "../../../types/onboarding";
import { selectStage, updateOnboardingAsync } from "../../../app/onboardingSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import OnboardingBack from "./OnboardingBack";
import icon_seed from "../../../assets/images/seed.svg";
import icon_startup from "../../../assets/images/startup.svg";
import icon_buildup from "../../../assets/images/buildup.svg";
import icon_dreamer from "../../../assets/images/dreamer.svg";
import icon_hyperstar from "../../../assets/images/hyperstar.svg";
import icon_superstar from "../../../assets/images/superstar.svg";
import PrimaryButton from "../../../components/PrimaryButton";
import { useEffect, useState } from "react";
import { Divider } from "rsuite";
import { Information, MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import useOnboardingData from "../../../hooks/useOnboarding";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import OnboardingSkipButton from "../../../components/OnboardingSkip";
const STAGES_ICON: any = {
  seed: icon_seed,
  startup: icon_startup,
  buildup: icon_buildup,
  dreamer: icon_dreamer,
  hyperstar: icon_hyperstar,
  superstar: icon_superstar,
};
function NPOStage() {
  const mxEvent = useGibooMixpanel();
  const dispatch = useAppDispatch();
  const prevStage = useAppSelector((state) => selectStage(state));
  const [stage, setStage] = useState<TypeStage | undefined>(prevStage);
  const [onboardingData] = useOnboardingData();
  useEffect(() => {
    setStage(prevStage);
  }, [prevStage]);
  const renderDescription = () => {
    return (
      <div className="onboarding-width-md2 flex flex-col gap-4">
        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            How did Giboo decide on these six stages?
          </h4>
          <p className="max-w-[676px font-poppins text-xs text-gray-800">
            We found that funders often prefer to provide grants to nonprofit organizations in
            certain stages. Giboo came up with these six stages of nonprofit organizations by
            analyzing various criteria, including financial status, years of operation, and number
            of employees to better match the funders and nonprofit organizations.
          </p>
        </div>
      </div>
    );
  };
  return (
    <div className="onboarding onboarding-width-lg flex flex-col ">
      <h4 className="onboarding-header">
        Which of these six stages does your nonprofit most closely match?
      </h4>
      <p className="onboarding-sub-header-gray mt-3">
        Please select the stage that best represents your organization&apos;s current status. <br />
        Giboo optimizes funder matching results by aligning the funder&apos;s grant-making history
        and your organization&apos;s stage.
      </p>
      <div className="onboarding-body onboarding-width-lg !mt-10 !gap-10">
        <div className="flex w-full">
          <div className="grid min-h-[340px] w-full grid-cols-1 gap-4 md:w-[932px] md:grid-cols-3 md:gap-4">
            {stageKeys
              .filter((key) => stageLabels[key as keyof typeof stageLabels].title)
              .map((key) => (
                <div className="group/card relative h-[162px] w-[300px]" key={"btn-stage-" + key}>
                  <div
                    className={classNames(
                      "onboarding-stage flex h-full max-w-[300px] items-center justify-center gap-[28px] !border-gray-300",
                      {
                        ["active !border-purple-500 bg-purple-50 shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)]"]:
                          stage === key,
                      },
                      { ["!border-gray-300"]: stage != key },
                    )}
                  >
                    <img src={STAGES_ICON[key]} className="h-fit w-fit scale-90" alt="stage" />
                    <div>{stageLabels[key as keyof typeof stageLabels].title}</div>
                  </div>
                  <div
                    className={classNames(
                      "absolute -left-[15px] bottom-[15px] z-10 hidden h-fit min-w-[300px] max-w-full cursor-pointer select-none rounded border border-gray-200 bg-white p-4 shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)] transition-all delay-75 group-hover/card:block",
                      {
                        ["active !bg-purple-50"]: stage === key,
                      },
                    )}
                    id={"btn-stage-" + key}
                    onClick={() => {
                      if (stage === key) {
                        setStage(undefined);
                      } else {
                        setStage(key as TypeStage);
                      }
                    }}
                  >
                    <div className="flex min-h-[146px] flex-col justify-between">
                      {stageLabels[key as keyof typeof stageLabels].detail}
                      <h6 className="mt-1 flex justify-end self-end text-end font-poppins text-xs text-gray-700">
                        {stage === key ? "Click to unselect" : "Click to select"}
                      </h6>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={classNames("mb-10 inline-flex items-center gap-2")}>
          <OnboardingBack
            outline
            data={{
              stage_requested: stage,
            }}
          />
          <PrimaryButton
            disabled={!stage}
            size="md"
            id="btn-next"
            handleOnClick={() => {
              mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
                field: Information.STAGE,
                orgStage: stage,
              });
              dispatch(
                updateOnboardingAsync(
                  {
                    stage_requested: stage,
                  },
                  +1,
                ),
              );
            }}
            rightIconClass={<i className="gi gi-right-arrow" />}
            label="Next"
          />
          <OnboardingSkipButton />
        </div>
      </div>
      <Divider className="!mt-0 mb-[60px]" />
      {renderDescription()}
    </div>
  );
}
export default NPOStage;
