import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import { IOrgMember } from "../types/org";
import axios from "axios";
import config from "../api";
import { swrOptionFetchOnlyOnMount } from "../types/swr";
interface ProjectInteractionState {
  data: IOrgMember[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IOrgMember[] | undefined>;
}
function useNPOMembers(npo_id?: string): ProjectInteractionState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/npos/${npo_id}/members`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url || !npo_id) return new Promise<IOrgMember[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data);
  };
  const { data, isLoading, error, isValidating } = useSWR<IOrgMember[]>(
    npo_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<IOrgMember[]>(url), [url]);
  return {
    data: data || [],
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useNPOMembers;
