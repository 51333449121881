import { Dispatch, MouseEventHandler, SetStateAction, useState } from "react";
import { IOption } from "../tailwind/AsyncCreatableSelector";
import classNames from "classnames";
import ReactDatePicker from "react-datepicker";
import calendar_icon from "../../assets/images/calendar.svg";
import { toDateString } from "../../utils/formatHelper";
import Checkbox from "./Checkbox";
import { getDateFromUTCDate } from "../../utils/dateUtils";
// import { DatePicker } from "rsuite";

const BASE_DATE_DIFF = 90;
interface CheckBoxDeadlineProps {
  onCheck?: (t: string, check: boolean) => void;
  id: string;
  data: IOption[];
  value: string[];
  setValue: (v: string[]) => void;
  flexColumn?: boolean;
  disabled?: boolean;
  placeholder?: string;
  downIcon?: boolean;
  compact?: boolean;
  dateValue: Date | undefined;
  setDateValue: (d: Date) => void;
  onFocus?: () => void;
}
export default function CheckBoxDeadline({
  data,
  value,
  setValue,
  dateValue,
  setDateValue,
  ...props
}: CheckBoxDeadlineProps) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <div className="flex flex-col gap-1">
        {data.map((item) => {
          const key = `${props.id}-${item.value.toLowerCase().replaceAll(" ", "")}`;
          return (
            <div
              key={key}
              className="flex cursor-pointer items-center gap-0"
              onClick={() => {
                if (item.value === "specific") {
                  if (open) {
                    setOpen(false);
                  } else {
                    if (value.includes(item.value)) {
                      setValue(value.filter((v) => v !== item.value));
                      props.onCheck?.(item.value, false);
                    } else {
                      setValue([...value, item.value]);
                      props.onCheck?.(item.value, true);
                      setOpen(true);
                    }
                  }
                } else {
                  if (value.includes(item.value)) {
                    setValue(value.filter((v) => v !== item.value));
                    props.onCheck?.(item.value, false);
                  } else {
                    setValue([...value, item.value]);
                    props.onCheck?.(item.value, true);
                  }
                }
              }}
            >
              <Checkbox
                id={key}
                checked={value.includes(item.value)}
                label={
                  value.includes("specific") && item.value === "specific" && dateValue
                    ? "Deadline:"
                    : item.label
                }
                onChange={() => {}}
              />
              {value.includes("specific") && item.value === "specific" && dateValue && (
                <div className="flex items-center gap-2">
                  <h5>{`${toDateString(getDateFromUTCDate(dateValue?.toISOString()))}`}</h5>
                  <span className="h-5 w-5">
                    <img src={calendar_icon} className="h-full w-full" />
                  </span>
                </div>
              )}
            </div>
            // <div
            //   className="flex cursor-pointer items-center gap-2"
            //   key={key}
            //   onClick={() => {
            //     if (item.value === "specific") {
            //       setOpen(true);
            //     } else {
            //       setOpen(false);
            //     }
            //     setValue(item.value);
            //   }}
            // >
            //   <input
            //     id={key}
            //     type="radio"
            //     name={item.value}
            //     value={item.value}
            //     checked={value === item.value}
            //     disabled={props.disabled}
            //     readOnly
            //     className="cursor-pointer border-gray-500 text-purple-500 !outline-none !ring-transparent"
            //   />
            //   <label
            //     htmlFor={key}
            //     className={classNames(
            //       "flex cursor-pointer items-center gap-2",
            //       props.disabled ? "text-gray-400" : "text-gray-900",
            //     )}
            //   >
            //     {item.value === "specific" ? (
            //       <>
            //         <h5>{dateValue ? `Deadline: ${toDateString(dateValue)}` : item.label}</h5>
            //         <span className="h-5 w-5">
            //           <img src={calendar_icon} className="h-full w-full" />
            //         </span>
            //       </>
            //     ) : (
            //       <h5>{item.label}</h5>
            //     )}
            //   </label>
            // </div>
          );
        })}
      </div>
      {open && (
        <div className="mt-2">
          <ReactDatePicker
            selected={getDateFromUTCDate(dateValue?.toISOString())}
            dateFormat={"yyyy/MM/dd"}
            onChange={(date: Date | null) => {
              if (!date) setValue(value.filter((p) => p !== "specific"));
            }}
            onSelect={(date: Date) => {
              setDateValue(date);
              if (date) {
                setValue([...value.filter((p) => p !== "specific"), "specific"]);
              }
              setOpen(false);
            }}
            customInput={<></>}
            id={`${props.id}-specific-date`}
            name="giboo-date-picker"
            showYearDropdown
            showMonthDropdown
            open={true}
            inline
          />
          {/* <DatePicker
            onClick={handleDatepickerClick}
            className={classNames("table-date-picker !z-[1200] !h-[24px] !w-full")}
            id={`${props.id}-specific-date`}
            value={dateValue ? new Date(dateValue) : undefined}
            size="sm"
            block
            editable={false}
            cleanable={false}
            placeholder="MM/DD/YYYY"
            onChange={(value: any, e) => {
              if (value) {
                setDateValue(value.toISOString());
                setOpen(false);
              } else {
                // setDateValue(undefined);
                setOpen(false);
              }
              e.stopPropagation();
            }}
            open={true}
          /> */}
        </div>
      )}
    </>
  );
}
