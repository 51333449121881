import classNames from "classnames";
import "../css/dropdown.scss";
import React, { Dispatch, SetStateAction, useMemo, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import Checkbox from "../checkbox/Checkbox";

interface MultipleDropdownSelectorProps<T> {
  id: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green" | "gray";
  data: T[];
  selected: T[];
  setSelected: Dispatch<SetStateAction<T[]>>;
  getKey: (item: T) => number | string;
  renderItem: (item: T) => React.ReactNode;
  renderButton: (item: T[]) => React.ReactNode;
  maxHeight?: string;
  openAnimation?: boolean;
  placeholder?: string;
  disabled?: boolean;
  float?: boolean;
  compact?: boolean;
  defaultValue?: T;
  checkbox?: boolean;
  downIcon?: boolean;
  textSize?: string;
  selectAll?: boolean;
}

function MultipleDropdownSelector<T>({
  id,
  color,
  data,
  selected = [],
  setSelected,
  getKey,
  renderItem,
  renderButton,
  maxHeight,
  openAnimation = false,
  placeholder,
  disabled = false,
  float = false,
  compact = false,
  defaultValue,
  checkbox = false,
  downIcon = false,
  textSize = "md",
  selectAll = false,
}: MultipleDropdownSelectorProps<T>) {
  const colorClass = color ? `-${color}` : "";
  const options = useMemo(() => {
    return (
      <Listbox.Options
        as="div"
        className={classNames("dropdown-selector-item-container w-full overflow-auto shadow-sm", {
          float: float,
          compact: compact,
        })}
        style={{ maxHeight: maxHeight }}
      >
        {data.map((item) => (
          <Listbox.Option as={Fragment} key={getKey(item)} value={item} disabled={false}>
            <div
              className={classNames(
                "dropdown-selector-item flex items-center",
                `dropdown-selector-item${colorClass}`,
                compact ? "min-h-[28px] " : "min-h-[48px]",
                {
                  compact: compact,
                },
              )}
            >
              {checkbox && (
                <Checkbox
                  id={`input-check-${item}`}
                  checked={selected.includes(item)}
                  onChange={() => {}}
                />
              )}
              <div id={`item-${id}-${getKey(item)}`}>{renderItem(item)}</div>
            </div>
          </Listbox.Option>
        ))}
      </Listbox.Options>
    );
  }, [data, selected, renderItem]);
  const transitionClasses = openAnimation
    ? {
        enter: "transition ease-out duration-100",
        enterFrom: "transform opacity-0 scale-95",
        enterTo: "transform opacity-100 scale-100",
        leave: "transition ease-in duration-75",
        leaveFrom: "transform opacity-100 scale-100",
        leaveTo: "transform opacity-0 scale-95",
      }
    : {};
  const defaultValueProp = defaultValue
    ? ({ defaultValue: defaultValue } as { defaultValue: T })
    : typeof selected === "string"
    ? ({ value: selected } as { value: string })
    : {};
  return (
    <Listbox
      id={id}
      as="div"
      by={(a: T | undefined, b: T | undefined) =>
        a !== undefined && b !== undefined && getKey(a) === getKey(b)
      }
      onChange={setSelected}
      className="dropdown-selector"
      disabled={disabled}
      {...defaultValueProp}
      multiple
    >
      <Listbox.Button as={Fragment}>
        <button
          id={`btn-${id}`}
          className={classNames(
            "dropdown-selector-button flex h-12 items-center justify-between overflow-hidden py-1",
            `dropdown-selector-button${colorClass}`,
          )}
          disabled={disabled}
        >
          {selected && renderButton(selected) ? (
            renderButton(selected)
          ) : (
            <p className="dropdown-selector-placeholder m-0">{placeholder}</p>
          )}
          {downIcon && <i className={classNames("gi gi-angle-down", color)} />}
        </button>
      </Listbox.Button>
      <div className="relative z-30 mt-3">
        {openAnimation ? (
          <Transition as={Fragment} {...transitionClasses}>
            {options}
          </Transition>
        ) : (
          <>{options}</>
        )}
      </div>
    </Listbox>
  );
}

export default MultipleDropdownSelector;
