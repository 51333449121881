import useOrgs from "./useOrgs";
import useVerifiedOrg from "./useVerifiedOrg";
import useUser from "./useUser";

const useWritePermission = (
  npo_id?: string,
): {
  hasWritePermission: boolean;
} => {
  const [user] = useUser();;
  const { orgs } = useOrgs();
  const { data: orgData } = useVerifiedOrg(npo_id);
  const hasWritePermission = orgs.find(
    (o) => o?._id === orgData?._id && o?.owner?._id === user?._id,
  )
    ? true
    : false;
  return { hasWritePermission };
};
export default useWritePermission;
