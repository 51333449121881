import { useEffect, useState } from "react";
import { TypeRole, roleLabels } from "../types/onboarding";
import { IOrgManualMember, ITotalOrganizationMember } from "../types/org";
import useOrgManualMembers from "./project/useOrgManualMembers";
import useOrgMembers from "./project/useOrgMembers";

interface ITotalOrganizationMemberSimple {
  _id: string;
  type: string;
}
const getTotalOrganizationMembersId = (m: ITotalOrganizationMemberSimple) => `${m.type}_${m._id}`;
const useTotalOrganizationMembersData = (
  org_id?: string,
): {
  data: ITotalOrganizationMember[];
  isLoading: boolean;
  revalidate: () => void;
  isValidating: boolean;
} => {
  const {
    data: userMembers,
    isLoading: isLoadingUserMembers,
    isValidating: validating1,
  } = useOrgMembers(org_id);
  const {
    data: manualMembers,
    isLoading,
    revalidate,
    isValidating: validating2,
  } = useOrgManualMembers(org_id);
  const [mergedMembers, setMergedMembers] = useState<ITotalOrganizationMember[]>([]);
  useEffect(() => {
    setMergedMembers([
      ...userMembers
        .map((m) => ({
          ...m,
          type: "user",
          _id: m._id || "",
        }))
        .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()),
      ...manualMembers
        .map((m) => ({ ...m, type: "manual" }))
        .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()),
    ]);
  }, [userMembers, manualMembers]);
  return {
    data: mergedMembers,
    isLoading: isLoading || isLoadingUserMembers,
    isValidating: validating1 || validating2,
    revalidate: revalidate,
  };
};
export { getTotalOrganizationMembersId };
export default useTotalOrganizationMembersData;
