import classNames from "classnames";
import useOnboardingData from "../hooks/useOnboarding";
import usePastGrantOverview from "../hooks/usePastGrantOverview";
import { SearchQuery } from "../types/search";
import { Divider, Popover, Whisper } from "rsuite";
import Spinner from "./Spinner";
import { toFixedWithMinimum, toUsdLong, toUsdShort } from "../utils/formatHelper";
import useFunderSearchResult from "../hooks/search/useFunderSearchResult";
import { queryMaskForPastGrantOverview } from "../hooks/useGibooStructureSearch";
import GibooGradientDiv from "./GibooGradientDiv";
import { ReactNode } from "react";

interface FundedAmountProps {
  funder_id: string;
  funder_uid: number;
  query: SearchQuery;
  color?: "gray" | "white";
}
function FundedAmount({ funder_id, funder_uid, query, color = "gray" }: FundedAmountProps) {
  const [onboardingData] = useOnboardingData();
  const { data: funder, isLoading: isFunderLoading } = useFunderSearchResult(funder_id);
  const { isPastGrantOverviewLoading, pastGrantOverview } = usePastGrantOverview(
    onboardingData ? funder_uid : undefined,
    query,
    [],
    onboardingData.stage,
    true,
    queryMaskForPastGrantOverview,
    true,
  );

  const renderRow = (title: ReactNode, value: ReactNode) => {
    return (
      <div className="inline-flex">
        <div className="w-full max-w-[265px] pr-3 font-poppins text-black">{title}</div>
        <div className="w-full border-l-[1px] border-gray-300 pl-3">{value}</div>
      </div>
    );
  };
  const hasNoTaxonomy =
    query.focus_area.length === 0 &&
    query.beneficiary.length === 0 &&
    query.program.length === 0 &&
    query.hq_loc.length === 0 &&
    query.service_loc.length === 0;
  return (
    <>
      {isPastGrantOverviewLoading || !pastGrantOverview || isFunderLoading || !funder ? (
        <Spinner size="sm" />
      ) : (
        <GibooGradientDiv borderSize={1}>
          <div className="flex flex-col gap-1 p-4">
            <h6 className="font-poppins text-sm text-black ">
              {`Over the past `}
              <span className="font-semibold text-purple-500">{`${
                pastGrantOverview?.max_year - pastGrantOverview.min_year + 1
              } years,`}</span>
              <span>{` in the areas represented by ${
                !hasNoTaxonomy ? `the matched` : ""
              } tags,`}</span>
              <br />
              <span>the following data reflects the grant activities:</span>
            </h6>
            <Divider className="my-2" />
            {renderRow(
              <h6 className="font-poppins text-sm text-black ">{`Amount of grants disbursed `}</h6>,
              <span>
                <span className="font-semibold text-purple-500">{`${toUsdLong(
                  pastGrantOverview.amount_total,
                )}`}</span>{" "}
                {!hasNoTaxonomy && (
                  <span className="!text-xs font-normal text-purple-500">{`(${
                    !funder.grant_amount_total
                      ? 0
                      : toFixedWithMinimum(
                          (100 * pastGrantOverview.amount_total) / funder.grant_amount_total,
                        )
                  }% of grant amount for matching tags over the overall grant amount)`}</span>
                )}
              </span>,
            )}
            <Divider className="my-2" />
            {renderRow(
              <h6 className="font-poppins text-sm text-black ">{`Number of grants made `}</h6>,
              <span className="font-semibold text-purple-500">{`${pastGrantOverview.len_total.toLocaleString()}`}</span>,
            )}
            <Divider className="my-2" />
            {renderRow(
              <h6 className="font-poppins text-sm text-black ">
                {`Number of organizations supported `}
              </h6>,
              <span className="font-semibold text-purple-500">{`${pastGrantOverview.org_total.toLocaleString()}`}</span>,
            )}
          </div>
        </GibooGradientDiv>
      )}
    </>
  );
}
export default FundedAmount;
