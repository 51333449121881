import React, { ReactNode } from "react";
import IMG_1 from "../../../../assets/financial/blance-sheet-needs-1.svg";
import IMG_2 from "../../../../assets/financial/blance-sheet-needs-2.svg";
import classNames from "classnames";
import Spacer from "../../../../components/Spacer";
import Button from "../../../../components/tailwind/Button";

export default function BalanceSheetNeeds({
  onBack,
  onNext,
  npo_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  isBack?: boolean;
}) {
  const renderTitle = (title: string, className?: string) => {
    return (
      <div>
        <h5 className={classNames("font-poppins text-xl font-semibold text-black", className)}>
          {title}
        </h5>
      </div>
    );
  };

  const renderitem = (
    index: number,
    title: string | ReactNode,
    content: ReactNode,
    image: string,
  ) => {
    return (
      <div className="flex flex-wrap items-center gap-[60px] md:flex-nowrap">
        <div className="flex w-full max-w-[493px] flex-col gap-3">
          <div className="inline-flex items-start gap-2">
            <span className="flex h-[21px] w-[21px] shrink-0 items-center justify-center rounded-full bg-purple-500 text-xs font-semibold text-white ">
              {index}
            </span>
            <div className="font-poppins text-sm font-semibold text-black">{title}</div>
          </div>
          <div className="pl-[30px] font-poppins text-sm text-gray-800">{content}</div>
        </div>
        <div className="h-fit w-fit">
          <img src={image} alt="business-model-image" />
        </div>
      </div>
    );
  };
  return (
    <div className="flex max-w-[881px] flex-col gap-6">
      {renderTitle("III. Balance sheet needs")}
      <div className="flex flex-col gap-[60px]">
        {renderitem(
          1,
          "What is balance sheet needs of a nonprofit organization?",
          <>
            The balance sheet serves as a comprehensive report, offering a snapshot that intricately
            captures the financial health of your nonprofit organization.
            <Spacer height="8px" />
            By meticulously measuring various financial assets and the organization&apos;s Month of
            Liquid, this document provides a nuanced and detailed understanding of your
            nonprofit&apos;s financial standing at a specific point in time. It goes beyond mere
            numerical representation, encapsulating the intricate interplay between assets such as
            cash, investments, and receivables.
          </>,
          IMG_1,
        )}
        {renderitem(
          2,
          "What role does the balance sheet needs play in the organization?",
          <>
            This comprehensive understanding is pivotal, serving as a roadmap for effective
            financial management and strategic decision-making. It not only sheds light on the
            liquidity of the organization but also highlights the balance between short-term
            financial resilience and long-term sustainability.
            <Spacer height="8px" />
            In essence, the balance sheet serves as a vital tool, offering insights that are
            instrumental in ensuring the financial robustness and adaptability of your nonprofit,
            ultimately fostering its capacity for sustained mission fulfillment and long-term
            impact.
          </>,
          IMG_2,
        )}
        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              onNext?.();
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
