import { ILocation, isLocationEligible, reprLocation } from "../types/location";
import { mergeToState } from "../utils/grant";
import { Philanthropy } from "./philanthropy";

export interface ITaxonomyOntology {
  focus_area: { [key: string]: number };
  beneficiary: { [key: string]: number };
  program: { [key: string]: number };
  service_loc: ILocation[];
}

interface TaxonomyWithOperator {
  type?: string;
  tag: string;
  opr: string;
}
export interface ITaxonomyTaggingOperator {
  focus_area: TaxonomyWithOperator[];
  beneficiary: TaxonomyWithOperator[];
  program: TaxonomyWithOperator[];
  service_loc: TaxonomyWithOperator[];
}
export interface TaxonomyConcept {
  type: string;
  label: string;
  parent?: string;
  hide_in_onboarding?: string;
  location?: ILocation;
  priority?: number;
  score?: number;
  object_id: string;
  parent_object_id?: string;
}

export interface TaxonomyTrainingDataReadRequest {
  text_key: string;
}
export interface TaxonomyTrainingDataUpsertRequest {
  text_key: string;
  title: string;
  description: string;
  focus_area?: string[];
  beneficiary?: string[];
  program?: string[];
}
export interface TaxonomyTrainingData extends TaxonomyTrainingDataUpsertRequest {
  _id: string;
  user_id: string;
  updated_at: string;
  created_at: string;
}
const focusAreaId = "64522fab5ab99dc82112926a";
const beneficiaryId = "64522fab5ab99dc82112926b";
const programId = "64522fab5ab99dc82112926c";
///////
///////
///////
const filterUnmatched = (philanthropy: Philanthropy, unFilterLocation = false): Philanthropy => {
  return {
    focus_area: philanthropy?.focus_area?.filter((i) => i.matched) || [],
    beneficiary: philanthropy?.beneficiary?.filter((i) => i.matched) || [],
    program: philanthropy?.program?.filter((i) => i.matched) || [],
    service_loc: unFilterLocation
      ? philanthropy?.service_loc
      : philanthropy?.service_loc?.filter((i) => i.matched) || [],
    user_input: philanthropy?.user_input?.filter((i) => i.matched) || [],
  };
};
const matchTaxonomyBySearchQueryWithPhilanthropy = (
  philanthropy: Philanthropy,
  query?: {
    focus_area: string[];
    beneficiary: string[];
    program: string[];
    service_loc: ILocation[];
    tagged_focus_area: string[];
    tagged_beneficiary: string[];
    tagged_program: string[];
    exclude_focus_area?: string[];
    exclude_beneficiary?: string[];
    exclude_program?: string[];
    exclude_service_loc?: ILocation[];
  },
  service_specific_loc = true,
  keep_service_loc = true,
) => {
  const focus_area = [
    ...(query?.focus_area.map((i) => i.toLowerCase()) || []),
    ...(query?.tagged_focus_area.map((i) => i.toLowerCase()) || []),
  ].filter((t) => !query?.exclude_focus_area || !query.exclude_focus_area.includes(t));
  const beneficiary = [
    ...(query?.beneficiary.map((i) => i.toLowerCase()) || []),
    ...(query?.tagged_beneficiary.map((i) => i.toLowerCase()) || []),
  ].filter((t) => !query?.exclude_beneficiary || !query.exclude_beneficiary.includes(t));
  const program = [
    ...(query?.program.map((i) => i.toLowerCase()) || []),
    ...(query?.tagged_program.map((i) => i.toLowerCase()) || []),
  ].filter((t) => !query?.exclude_program || !query.exclude_program.includes(t));
  const exclude_label = query?.exclude_service_loc?.map((l) => reprLocation(l)) || [];
  const service_loc =
    query?.service_loc?.filter((l) => !exclude_label.includes(reprLocation(l))) || [];
  const service_loc_based_query = philanthropy?.service_loc || [];

  return {
    focus_area: philanthropy.focus_area?.map((i) => ({
      ...i,
      matched: focus_area.includes(i.label.toLowerCase()),
    })),
    beneficiary: philanthropy.beneficiary?.map((i) => ({
      ...i,
      matched: beneficiary.includes(i.label.toLowerCase()),
    })),
    program: philanthropy.program?.map((i) => ({
      ...i,
      matched: program.includes(i.label.toLowerCase()),
    })),
    service_loc: service_specific_loc
      ? keep_service_loc
        ? philanthropy.service_loc?.map((i) => ({
            ...i,
            matched: !service_specific_loc || isLocationEligible(i, service_loc),
          })) || []
        : [
            ...(query?.service_loc
              ?.filter(
                (l) =>
                  !exclude_label.includes(reprLocation(l)) &&
                  isLocationEligible(l, service_loc_based_query),
              )
              ?.map((i) => ({
                // virtual grant, funder -> use query's location
                ...i,
                matched: true,
              })) || []),
            ...mergeToState(
              philanthropy.service_loc
                ?.filter((i) => service_specific_loc && !isLocationEligible(i, service_loc))
                .map((i) => ({
                  ...i,
                  matched: false,
                })) || [],
            ),
          ]
      : [],
  };
};
const matchTaxonomyBySearchQuery = (
  philanthropy: {
    focus_area: string[];
    beneficiary: string[];
    program: string[];
    service_loc: ILocation[];
  },
  query?: {
    focus_area: string[];
    beneficiary: string[];
    program: string[];
    service_loc: ILocation[];
    tagged_focus_area: string[];
    tagged_beneficiary: string[];
    tagged_program: string[];
    exclude_focus_area?: string[];
    exclude_beneficiary?: string[];
    exclude_program?: string[];
    exclude_service_loc?: ILocation[];
  },
  service_specific_loc = true,
  keep_service_loc = true,
) => {
  return matchTaxonomyBySearchQueryWithPhilanthropy(
    {
      focus_area: philanthropy.focus_area.map((i) => ({ label: i })),
      beneficiary: philanthropy.beneficiary.map((i) => ({ label: i })),
      program: philanthropy.program.map((i) => ({ label: i })),
      service_loc: philanthropy.service_loc,
    },
    query,
    service_specific_loc,
    keep_service_loc,
  );
};
const matchTaxonomyBasedProfile = (
  philanthropy: Philanthropy,
  query?: {
    focus_area: string[];
    beneficiary: string[];
    program: string[];
    service_loc: ILocation[];
    tagged_focus_area: string[];
    tagged_beneficiary: string[];
    tagged_program: string[];
    exclude_focus_area?: string[];
    exclude_beneficiary?: string[];
    exclude_program?: string[];
    exclude_service_loc?: ILocation[];
  },
  service_specific_loc = true,
) => {
  return matchTaxonomyBySearchQueryWithPhilanthropy(
    philanthropy,
    query,
    service_specific_loc,
    true, //keep all service location
  );
};

const unnestTaxonomy = (d: {
  FocusAreas?: { focus_area?: string[]; beneficiary?: string[]; program?: string[] }[];
  Beneficiaries?: { focus_area?: string[]; beneficiary?: string[]; program?: string[] }[];
  Programs?: { focus_area?: string[]; beneficiary?: string[]; program?: string[] }[];
}) => {
  return {
    focus_area: [
      d.FocusAreas?.reduce(
        (prev: string[], cur: { focus_area?: string[] }) => [
          ...prev,
          ...(cur.focus_area?.filter((t: string) => !prev.includes(t)) || []),
        ],
        [],
      ) || [],
      d.Beneficiaries?.reduce(
        (prev: string[], cur: { focus_area?: string[] }) => [
          ...prev,
          ...(cur.focus_area?.filter((t: string) => !prev.includes(t)) || []),
        ],
        [],
      ) || [],
      d.Programs?.reduce(
        (prev: string[], cur: { focus_area?: string[] }) => [
          ...prev,
          ...(cur.focus_area?.filter((t: string) => !prev.includes(t)) || []),
        ],
        [],
      ) || [],
    ]
      .reduce((prev, cur) => [...prev, ...cur.filter((t: string) => !prev.includes(t))], [])
      .map((t: string) => ({ label: t, matched: false })),
    beneficiary: [
      d.FocusAreas?.reduce(
        (prev: string[], cur: { beneficiary?: string[] }) => [
          ...prev,
          ...(cur.beneficiary?.filter((t: string) => !prev.includes(t)) || []),
        ],
        [],
      ) || [],
      d.Beneficiaries?.reduce(
        (prev: string[], cur: { beneficiary?: string[] }) => [
          ...prev,
          ...(cur.beneficiary?.filter((t: string) => !prev.includes(t)) || []),
        ],
        [],
      ) || [],
      d.Programs?.reduce(
        (prev: string[], cur: { beneficiary?: string[] }) => [
          ...prev,
          ...(cur.beneficiary?.filter((t: string) => !prev.includes(t)) || []),
        ],
        [],
      ) || [],
    ]
      .reduce((prev, cur) => [...prev, ...cur.filter((t: string) => !prev.includes(t))], [])
      .map((t: string) => ({ label: t, matched: false })),
    program: [
      d.FocusAreas?.reduce(
        (prev: string[], cur: { program?: string[] }) => [
          ...prev,
          ...(cur.program?.filter((t: string) => !prev.includes(t)) || []),
        ],
        [],
      ) || [],
      d.Beneficiaries?.reduce(
        (prev: string[], cur: { program?: string[] }) => [
          ...prev,
          ...(cur.program?.filter((t: string) => !prev.includes(t)) || []),
        ],
        [],
      ) || [],
      d.Programs?.reduce(
        (prev: string[], cur: { program?: string[] }) => [
          ...prev,
          ...(cur.program?.filter((t: string) => !prev.includes(t)) || []),
        ],
        [],
      ) || [],
    ]
      .reduce((prev, cur) => [...prev, ...cur.filter((t: string) => !prev.includes(t))], [])
      .map((t: string) => ({ label: t, matched: false })),
  };
};

export {
  focusAreaId,
  beneficiaryId,
  programId,
  filterUnmatched,
  matchTaxonomyBySearchQueryWithPhilanthropy,
  matchTaxonomyBySearchQuery,
  matchTaxonomyBasedProfile,
  unnestTaxonomy,
};
