import axios from "axios";
const getFileUrl = (key: string, filename?: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_DOWNLOAD_NPO_MEDIA_URL || "",
        { key, filename },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => reject(e));
  });
};
export { getFileUrl };
