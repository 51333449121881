import React, { ReactNode, memo, useEffect, useRef, useState } from "react";
import "../css/box.scss";
import classNames from "classnames";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
  Label,
} from "recharts";
import {
  getPercentageDiff,
  getYearsString,
  reprFloat,
  toFixedWithMinimum,
  toUsdLong,
  toUsdShort,
} from "../../utils/formatHelper";
import { FundChar, FundCharOverview, FundCharOverviewAttrs } from "../../types/funder";
import RenderIncrOrDecr from "../../pages/donor-detail-view/components/RenderIncrOrDecr";
import { useRecoilState } from "recoil";
import { matchingSummaryState } from "../../app/recoilStore";
import GibooGradientDiv from "../GibooGradientDiv";

interface FundCharProps {
  returnCode: string;
  fundCharAmount: FundChar[];
  fundCharCount: FundChar[];
  fundCharOverview: FundCharOverview;
  width: number;
  selected?: "dreamer" | "startup" | "seed" | "buildup" | "superstar" | "hyperstar" | "all";
  selectedTab: number;
  compact?: boolean;
  setDescription?: (ele: ReactNode) => void;
}
const stages = ["dreamer", "seed", "startup", "buildup", "superstar", "hyperstar"];
const getP = (stage: string): number => {
  if (stage === "dreamer") return 0;
  else if (stage === "seed") return 1;
  else if (stage === "startup") return 2;
  else if (stage === "buildup") return 3;
  else if (stage === "superstar") return 4;
  else if (stage === "hyperstar") return 5;
  else return -1;
};
interface FundChartToolTipProps {
  active?: boolean;
  payload?: any[];
  data?: FundChar[];
  label?: string;
  selectedTab: number;
  percentage?: number[];
  years?: number[];
}

const FundChartToolTip = (props: FundChartToolTipProps) => {
  if (props.payload && props.payload.length) {
    const name = props.payload[0]?.payload?.name as keyof FundChar;
    const index = getIndex(props.payload[0]?.payload?.name);
    // const percent = props.percentage?.[index];
    const totals = stages.map((key) =>
      props.data ? props.data.reduce((prev, cur) => prev + cur[key as keyof FundChar], 0) : 0,
    );
    const total = totals.reduce((prev, cur) => prev + cur, 0);
    const percent = total === 0 ? 0 : (totals[index] * 100) / total;
    return (
      <div className="giboo-box flex gap-2">
        {props.selectedTab === 3 || props.selectedTab === 1 ? (
          <>
            <div className="flex flex-col gap-0 text-end">
              <p key={`percentage-label`} className="">{`Percentage: `}</p>
              <p key={`avg-label`}>{`Average over ${
                props.years ? getYearsString(props.years) : ""
              }: `}</p>
              {props.years?.map((year, i) => {
                const d = name
                  ? props.data?.find((r) => r.tax_year === year)?.[name as keyof FundChar]
                  : undefined;
                return (
                  <p className="" key={`${year}-label`}>
                    {d !== undefined && d !== null ? <>{`${year}: `}</> : null}
                  </p>
                );
              })}
            </div>
            <div className="flex flex-col gap-0 text-end">
              <p key={`percentage-value`} className="font-semibold">{`${toFixedWithMinimum(
                percent || 0,
                2,
              )}%`}</p>
              <p key={`avg-value`} className="font-semibold">{`${
                props.selectedTab === 3
                  ? reprFloat(props.payload[0].value)
                  : toUsdLong(props.payload[0].value)
              }`}</p>
              {props.years?.map((year, i) => {
                const d = name
                  ? props.data?.find((r) => r.tax_year === year)?.[name as keyof FundChar]
                  : undefined;
                return (
                  <p key={`${year}-value`} className="font-semibold">
                    {d !== undefined && d !== null ? (
                      <>{props.selectedTab === 3 ? d.toLocaleString() : toUsdLong(d)}</>
                    ) : null}
                  </p>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <p>Amount: {`${toUsdLong(props.payload[0].value)}`}</p>
            {props.selectedTab === 1 && (
              <p>Percentage: {`${toFixedWithMinimum(percent || 0, 2)}%`}</p>
            )}
          </>
        )}
      </div>
    );
  }
  return null;
};
const formatLabel = (key: string): any => {
  const temp: any = {
    dreamer: "Individual",
    startup: "Start up",
    buildup: "Buildup",
    superstar: "Superstar",
    hyperstar: "Hyperstar",
    seed: "Pre-seed & Seed",
  };
  return temp[key];
};
const getIndex = (key: string): number => {
  const temp: any = {
    dreamer: 0,
    seed: 1,
    startup: 2,
    buildup: 3,
    superstar: 4,
    hyperstar: 5,
  };
  return temp[key];
};
function transformData(data: FundChar): number[] {
  return [data.dreamer, data.seed, data.startup, data.buildup, data.superstar, data.hyperstar];
}
function transformData2(data: FundCharOverview): FundCharOverviewAttrs[] {
  if (!data)
    return [0, 1, 2, 3, 4, 5].map((t) => ({
      median: 0,
      p25: 0,
      p75: 0,
      min: 0,
      max: 0,
    }));
  return [data.dreamer, data.seed, data.startup, data.buildup, data.superstar, data.hyperstar].map(
    (item) => ({
      ...item,
      median: item.median ? item.median : 0,
      p25: item.p25 ? item.p25 : 0,
      p75: item.p75 ? item.p75 : 0,
      min: item.min ? item.min : 0,
      max: item.max ? item.max : 0,
    }),
  );
}

function FundCharChart({
  selectedTab,
  fundCharAmount,
  fundCharCount,
  fundCharOverview,
  selected,
  returnCode,
  compact = false,
  setDescription,
  ...props
}: FundCharProps) {
  const [matchingDescription, setMatchingDescription] = useRecoilState<{
    [key: string]: ReactNode;
  }>(matchingSummaryState);
  const latestYearDataAmount: FundChar = fundCharAmount[fundCharAmount.length - 1];
  const latestYearDataCount: FundChar = fundCharCount[fundCharCount.length - 1];
  const keys = Object.keys(latestYearDataAmount) as (keyof FundChar)[];
  const totalDataAmount: FundChar = fundCharAmount.reduce((prev, cur) =>
    keys.reduce((p, key) => ({ ...p, [key]: prev[key] + cur[key] }), {} as FundChar),
  );
  const averageDataAmount: FundChar = keys.reduce(
    (p, key) => ({ ...p, [key]: totalDataAmount[key] / fundCharAmount.length }),
    {} as FundChar,
  );
  const totalDataCount: FundChar = fundCharCount.reduce((prev, cur) =>
    keys.reduce((p, key) => ({ ...p, [key]: prev[key] + cur[key] }), {} as FundChar),
  );
  const averageDataCount: FundChar = keys.reduce(
    (p, key) => ({ ...p, [key]: totalDataCount[key] / fundCharAmount.length }),
    {} as FundChar,
  );
  const previousYearDataAmount: FundChar =
    fundCharAmount.length > 1 ? fundCharAmount[fundCharAmount.length - 2] : fundCharAmount[0];
  const previousYearDataCount: FundChar =
    fundCharCount.length > 1 ? fundCharCount[fundCharCount.length - 2] : fundCharCount[0];
  const latestYearDataOverview: FundCharOverview = fundCharOverview;
  // const previousYearDataOverview: FundCharOverview =
  //   fundCharCount.length > 1 ? fundCharOverview[fundCharOverview.length - 2] : fundCharOverview[0];

  const index = selected ? getIndex(selected) : 0;
  const latestYearDataAmountTransformed = transformData(latestYearDataAmount);
  const previousYearDataAmountTransformed = transformData(previousYearDataAmount);
  const latestYearDataAmountSum = latestYearDataAmountTransformed.reduce(
    (prev, cur) => prev + cur,
    0,
  );
  const latestYearDataAmountPer =
    latestYearDataAmountSum > 0
      ? (latestYearDataAmountTransformed[index] / latestYearDataAmountSum) * 100
      : 0;
  //inidividual percentage calculation
  const percentageDetail = Array(6)
    .fill(0)
    .map((_, i) =>
      latestYearDataAmountSum > 0
        ? (latestYearDataAmountTransformed[i] / latestYearDataAmountSum) * 100
        : 0,
    );
  const previousYearDataAmountSum = previousYearDataAmountTransformed.reduce(
    (prev, cur) => prev + cur,
    0,
  );
  const previousYearDataAmountPer =
    previousYearDataAmountSum > 0
      ? (previousYearDataAmountTransformed[index] / previousYearDataAmountSum) * 100
      : 0;

  const latestYearDataCountTransformed = transformData(latestYearDataCount);
  const previousYearDataCountTransformed = transformData(previousYearDataCount);
  const latestYearDataCountSum = latestYearDataCountTransformed.reduce(
    (prev, cur) => prev + cur,
    0,
  );
  const latestYearDataCountPer =
    latestYearDataCountSum > 0
      ? (latestYearDataCountTransformed[index] / latestYearDataCountSum) * 100
      : 0;
  const previousYearDataCountSum = previousYearDataCountTransformed.reduce(
    (prev, cur) => prev + cur,
    0,
  );
  const previousYearDataCountPer =
    previousYearDataCountSum > 0
      ? (previousYearDataCountTransformed[index] / previousYearDataCountSum) * 100
      : 0;
  const latestYearDataMedianTransformed = transformData2(latestYearDataOverview);
  // const previousYearDataMedianTransformed = transformData2(previousYearDataOverview);

  useEffect(() => {
    setMatchingDescription((prev) => ({
      ...prev,
      stage: (
        <h5 key={"stage"} className="font-poppins font-normal text-gray-700">
          This funder has a track record of supporting{" "}
          <b className="text-purple-500">{selected ? formatLabel(selected) : ""}</b> organizations
          like yours, allocating approximately{" "}
          <b className="text-purple-500">{`${Math.round(latestYearDataAmountPer)}%`}</b> of their
          funding, resulting in a total of{" "}
          <b className="text-purple-500">{toUsdShort(latestYearDataAmountTransformed[index], 1)}</b>{" "}
          through{" "}
          <b className="text-purple-500">
            {latestYearDataCountTransformed[index].toLocaleString()}
          </b>{" "}
          fundings in {latestYearDataAmount.tax_year}.
        </h5>
      ),
    }));
  }, []);
  useEffect(() => {
    if (matchingDescription && selected) {
      if (fundCharAmount.length !== 0 || stages.includes(selected))
        setDescription?.(matchingDescription.stage);
    }
  }, [matchingDescription]);
  const renderChart = (data: any, xKey: string, yKey: string) => {
    return (
      <ResponsiveContainer width={"100%"} height={"100%"} debounce={50}>
        <BarChart data={data} layout="vertical" margin={{ left: 8, right: 50 }}>
          <CartesianGrid
            horizontal={false}
            strokeDasharray="3 3"
            stroke={"#DEDEDE"}
            strokeWidth={1}
          />
          <Tooltip
            content={
              <FundChartToolTip
                selectedTab={selectedTab}
                percentage={percentageDetail}
                data={
                  selectedTab === 1 ? fundCharAmount : selectedTab === 3 ? fundCharCount : undefined
                }
                years={fundCharAmount.map((d) => d.tax_year) || []}
              />
            }
            cursor={false}
            wrapperStyle={{ outline: "none" }}
          />
          <XAxis
            type="number"
            axisLine={false}
            dataKey={yKey}
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              whiteSpace: "pre-wrap",
            }}
            minTickGap={1}
            allowDecimals={false}
            tickFormatter={(v: any) => {
              if (selectedTab === 3) {
                return reprFloat(v);
              } else return toUsdShort(v);
            }}
          />
          <YAxis
            yAxisId={0}
            dataKey={xKey}
            type="category"
            axisLine={false}
            tickLine={false}
            textAnchor="end"
            width={140}
            dy={-6}
            dx={-6}
            tick={(e) => {
              const {
                payload: { value },
              } = e;
              return (
                <Text
                  {...e}
                  className={classNames(
                    "flex fill-gray-500  font-poppins text-sm ",
                    value === selected ? "fill-purple-500" : "fill-gray-700",
                  )}
                >
                  {formatLabel(value)}
                </Text>
              );
            }}
          />
          <Bar
            radius={[0, 4, 4, 0]}
            dataKey={yKey}
            minPointSize={0}
            barSize={32}
            style={{ marginTop: 5 }}
            isAnimationActive={false}
            label={{
              style: {
                fontFamily: "poppins",
                fontSize: "12px",
                whiteSpace: "pre-wrap",
              },
              position: "right",
              formatter: (v: any) => {
                if (selectedTab === 3) {
                  return reprFloat(v);
                } else {
                  return toUsdShort(v, 1);
                }
              },
            }}
          >
            {data.map((d: any, idx: any) => {
              return (
                <Cell
                  key={d[xKey]}
                  height={20}
                  className={classNames(
                    !selected || d.name === selected ? "sms fill-purple-500" : "fill-gray-200",
                  )}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };
  const renderChart2 = (data: any) => {
    return (
      <ResponsiveContainer width={"100%"} height={"100%"} debounce={50}>
        <BarChart data={data} layout="vertical" margin={{ left: 8, right: 50 }}>
          <CartesianGrid
            horizontal={false}
            strokeDasharray="3 3"
            stroke={"#DEDEDE"}
            strokeWidth={1}
          />
          <Tooltip
            content={<FundChartToolTip selectedTab={selectedTab} />}
            cursor={false}
            wrapperStyle={{ outline: "none" }}
          />
          <XAxis
            type="number"
            axisLine={false}
            dataKey={"median"}
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              whiteSpace: "pre-wrap",
            }}
            minTickGap={1}
            tickFormatter={(v: any) => {
              if (selectedTab === 3) {
                return v;
              } else return toUsdShort(v);
            }}
          />
          <YAxis
            yAxisId={0}
            dataKey={"name"}
            type="category"
            axisLine={false}
            tickLine={false}
            width={140}
            dy={-6}
            dx={-6}
            tick={(e) => {
              const {
                payload: { value },
              } = e;
              return (
                <Text
                  {...e}
                  className={classNames(
                    "flex fill-gray-500  font-poppins text-sm ",
                    value === selected ? "fill-purple-500" : "fill-gray-700",
                  )}
                >
                  {formatLabel(value)}
                </Text>
              );
            }}
          />
          <Bar
            radius={[0, 4, 4, 0]}
            isAnimationActive={false}
            dataKey={"median"}
            minPointSize={0}
            barSize={32}
            style={{ marginTop: 5 }}
            className="custom-bar"
            label={{
              style: {
                fontFamily: "poppins",
                fontSize: "12px",
                whiteSpace: "pre-wrap",
              },
              position: "right",
              formatter: (v: any) => {
                if (selectedTab === 3) {
                  return v;
                } else return toUsdShort(v, 1);
              },
            }}
          >
            {data.map((d: any, idx: any) => {
              return (
                <Cell
                  key={d["name"]}
                  height={20}
                  className={classNames(
                    !selected || d.name === selected ? "fill-purple-500" : "fill-gray-200",
                  )}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };
  const renderLeftContent = () => {
    if (fundCharAmount.length === 0) return;
    const xKey = "name";
    const yKey = "value";
    return (
      <div className="h-[250px] w-full grow" id={"funding-chart-view-" + selectedTab}>
        {selectedTab === 1
          ? renderChart(
              transformData(averageDataAmount).map((item, i) => ({
                name: stages[i],
                value: item,
              })),
              xKey,
              yKey,
            )
          : selectedTab === 3
          ? renderChart(
              transformData(averageDataCount).map((item, i) => ({
                name: stages[i],
                value: item,
              })),
              xKey,
              yKey,
            )
          : selectedTab === 2
          ? renderChart2(
              transformData2(latestYearDataOverview).map((item, i) => ({
                name: stages[i],
                ...item,
              })),
            )
          : null}
      </div>
    );
  };
  const renderRightContent = () => {
    if (!selected || fundCharAmount.length === 0 || !stages.includes(selected)) return;
    return (
      <>
        <p className="font-semibold">
          Funding analysis{" "}
          <span className="text-sm font-light text-gray-700">
            ({latestYearDataAmount.tax_year})
          </span>
        </p>

        <div
          className={classNames(
            "mt-4 flex flex-wrap",
            compact ? "gap-[60px] flex-row" : "flex-col gap-6",
          )}
        >
          {selectedTab == 1 && (
            <>
              <div className={classNames("font-poppings  gap-[4px] flex flex-col")}>
                <h5 className=" text-sm text-gray-700">Grant disbursed to your stage</h5>
                <h3>{toUsdShort(latestYearDataAmountTransformed[index], 1)}</h3>
                <RenderIncrOrDecr
                  value={getPercentageDiff(
                    previousYearDataAmountTransformed[index],
                    latestYearDataAmountTransformed[index],
                  )}
                />
              </div>
              <div className={classNames("font-poppings gap-[4px] flex flex-col")}>
                <h5 className=" text-sm text-gray-700">Grant percentage to your stage</h5>
                <h3>{`${Math.round(latestYearDataAmountPer)}%`}</h3>
                <RenderIncrOrDecr
                  value={getPercentageDiff(previousYearDataAmountPer, latestYearDataAmountPer)}
                />
              </div>
            </>
          )}
          {selectedTab == 2 && (
            <>
              <div className={classNames("font-poppings  gap-[4px] flex flex-col")}>
                <h5 className=" text-sm text-gray-700">Median grant disbursed to your stage</h5>
                <h3>{toUsdShort(latestYearDataMedianTransformed[index].median, 1)}</h3>
                {/* <RenderIncrOrDecr
                  value={getPercentageDiff(
                    previousYearDataMedianTransformed[index].median,
                    latestYearDataMedianTransformed[index].median,
                  )}
                /> */}
              </div>
            </>
          )}
          {selectedTab == 3 && (
            <>
              <div className={classNames("font-poppings  gap-[4px] flex flex-col")}>
                <h5 className=" text-sm text-gray-700">Grant count disbursed to your stage</h5>
                <h3>{latestYearDataCountTransformed[index].toLocaleString()}</h3>
                <RenderIncrOrDecr
                  value={getPercentageDiff(
                    previousYearDataCountTransformed[index],
                    latestYearDataCountTransformed[index],
                  )}
                />
              </div>
              <div
                className={classNames(
                  "font-poppings  gap-[4px] flex flex-col",
                  compact ? "mt-0" : "mt-[17px]",
                )}
              >
                <h5 className=" text-sm text-gray-700">Grant count percentage to your stage</h5>
                <h3>{`${Math.round(latestYearDataCountPer)}%`}</h3>
                <RenderIncrOrDecr
                  value={getPercentageDiff(previousYearDataCountPer, latestYearDataCountPer)}
                />
              </div>
            </>
          )}
        </div>
        <h6 className={classNames("mt-5  text-gray-700", compact ? "self-start" : "self-end")}>
          {" "}
          {`Dataset from: IRS ${returnCode}`}
        </h6>
      </>
    );
  };
  const renderHeader = () => {
    if (!selected || fundCharAmount.length === 0 || !stages.includes(selected)) return;
    return (
      <GibooGradientDiv borderSize={2} wrapperClassName="my-4">
        <div className=" py-[10px] rounded bg-gray-100 px-4">{matchingDescription.stage}</div>
      </GibooGradientDiv>
    );
  };
  return (
    <>
      <div className="flex flex-col gap-3">
        {/* {renderHeader()} */}
        <div className={classNames("flex gap-3 ", compact ? "flex-col" : "flex-col")}>
          <div
            className={classNames(
              "max-h-[250px] mt-4 w-full  grow pr-12",
              // compact && "max-w-[500px]",
              // compact ? "pr-12" : "min-w-[685px]",
            )}
          >
            {renderLeftContent()}
          </div>
          <div
            className={classNames(
              "  min-w-[232px] flex h-full grow-0 flex-col",
              compact ? "mt-[20px]" : "-mt-3",
            )}
          >
            {/* {renderRightContent()} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default FundCharChart;
