import { useNavigate } from "react-router-dom";
import ExpandableCard from "../../../components/tailwind/ExpandableCard";
import IMG_TASK from "../../../assets/home/create-task.svg";
import useOnboardingData from "../../../hooks/useOnboarding";
import useWritePermission from "../../../hooks/useWritePermission";
import PlainButton from "../../../components/PlainButton";
import PrimaryButton from "../../../components/PrimaryButton";
import classNames from "classnames";
import { useState } from "react";
import TaskCreationModal from "../../../components/TaskCreationModal";
import ProjectTaskManage from "../../../components/ProjectTaskManage";
import useMySubtasks from "../../../hooks/project/useMySubtasks";
import { TASK_STATUS } from "../../npo/projects/MyTasks";
import SubtaskTable from "../../../components/SubtaskTable";

const UpcomingTask = () => {
  const navigate = useNavigate();
  const { data, isLoading, revalidate } = useMySubtasks();
  const [onboardingData] = useOnboardingData();
  const [showTaskCreation, setShowTaskCreation] = useState<boolean>(false);
  const { hasWritePermission } = useWritePermission(onboardingData.npo_id);
  const tasks = data.slice(-10);

  const hasData = tasks.length > 0;
  return (
    <>
      {showTaskCreation && (
        <TaskCreationModal
          open={showTaskCreation}
          setOpen={setShowTaskCreation}
          onTaskCreation={() => {
            // console.log("finished task creation..");
            setShowTaskCreation(false);
          }}
        />
      )}
      <ExpandableCard
        id="upcoming-tasks"
        title="Upcoming tasks"
        titleClass="!text-base"
        allowExpand={false}
        allowEdit={false}
        showAllDetail={false}
        rightAction={
          <div className={classNames("inline-flex")}>
            {onboardingData.role >= 3 && tasks.length > 0 && (
              <PrimaryButton
                size="md"
                id={`btn-create-task`}
                label={"Create a task"}
                className="GA_TAG_click_createatask !h-[29px] !text-sm"
                leftIconClass="gi-add gi-md"
                handleOnClick={() => setShowTaskCreation(true)}
              />
            )}
            {hasData && data.length <= 10 && (
              <PlainButton
                id={`btn-view-all`}
                label={`View all (${data.length})`}
                className="!h-[29px] !text-sm"
                handleOnClick={() => navigate("/my-tasks")}
              />
            )}
          </div>
        }
      >
        {hasData ? (
          <div className="flex flex-col items-center gap-4">
            <SubtaskTable
              data={tasks}
              autoHeight={false}
              hideFooter
              hideColumns={["Owner", "Project", "Task under", "Latest note", "Delete"]}
              revalidate_my_subtask_list={revalidate}
            />
            {data.length > 10 && (
              <div className="mt-4 grid w-full place-items-center rounded-b bg-gray-100 px-10 py-3">
                <div
                  className="inline-flex w-fit cursor-pointer items-center gap-2"
                  onClick={() => {
                    navigate("/my-tasks");
                  }}
                >
                  <p className="font-poppins text-sm font-semibold text-purple-500">
                    View all ({data.length})
                  </p>
                  <div className="grid h-5 w-5 place-items-center">
                    <i className="gi gi-right-arrow text-purple-500" />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-[22px] grid min-h-[239px] w-full place-items-center gap-6">
            <PrimaryButton
              size="md"
              id={`btn-create-task`}
              label={"Create a task"}
              className="GA_TAG_click_createatask !h-[29px] !text-sm"
              leftIconClass="gi-add gi-md"
              handleOnClick={() => setShowTaskCreation(true)}
            />
            <div className="flex max-w-[400px] flex-col  items-center justify-center gap-4">
              <h6 className="font-poppins text-base font-semibold text-black">Create a new task</h6>
              <p className="w-[375px] text-center font-poppins text-xs text-gray-700">
                Use our task management tool to manage your workload and easily identify the tasks
                that need your immediate attention. Also collaborate with your colleagues to share
                the work!
              </p>
            </div>
            <img src={IMG_TASK} className="-mb-5 h-[207px] w-full" />
          </div>
        )}
      </ExpandableCard>
    </>
  );
};
export default UpcomingTask;
