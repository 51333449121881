import axios from "axios";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import config from "../api";
import useSWR from "swr";
import useOnboardingData from "../hooks/useOnboarding";
import useUser from "../hooks/useUser";
import PlainButton from "./PlainButton";
import { useNavigate } from "react-router-dom";
import "./css/recommendation.scss";
import { Philanthropy } from "../types/philanthropy";

import { ILocation, isLocationEligible, reprLocation } from "../types/location";
import { getDescription, prettierTaxonomy } from "../utils/philanthropy";
import classNames from "classnames";
import Tag from "./Tag";
import { SearchQuery, getURLSearchParamsFromSearchQuery } from "../types/search";
import GibooToast from "./GibooToast";
import { useRecoilValue } from "recoil";
import { searchRelevantMapCachedSelector } from "../app/recoilStore";
import useRelevant from "../hooks/useRelevant";
import RelevantText from "./RelevantText";
import PhilanthropicInformationFlexWrap from "./PhilanthropicInformationFlexWrap";
import Button from "./tailwind/Button";
import { IProject } from "../types/project";
import { toUTCDateString } from "../utils/formatHelper";
import { swrOptionDedupling5mins } from "../types/swr";
import { getSearchQueryFromOnboardingData } from "../app/onboardingSlice";
import useCancellableSWR from "../hooks/useCancellableSWR";
import { queryMaskForGrantSearch } from "../hooks/useGibooStructureSearch";
import { matchTaxonomyBySearchQuery } from "../types/taxonomy";
import GrantCardSimple from "./search/GrantCardSimple";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";

export interface IGrantRecommendationResult extends Philanthropy {
  _id: string;
  type: string;
  title: string;
  score: number;
  rank_conf: number;
  donor?: { name: string; location?: ILocation };
  location?: ILocation;
  grant_type?: string;
  grant_amount?: number;
  grant_amount_min?: number;
  grant_amount_max?: number;
  award_ceiling?: number;
  award_floor?: number;
  search_type: string;
  rank: number;
  grant_desc?: string;
  active?: boolean;
}

function RecGrants({
  recData,
  isLoading,
  hasInitialData,
  recommendationQuery,
  ...props
}: {
  recData?: IGrantRecommendationResult[];
  isLoading?: boolean;
  hasInitialData?: boolean;
  recommendationQuery?: SearchQuery;
  isLoadingAddGrant?: (id: string, type: "phil" | "federal") => boolean;
  onAdd?: (id: string, type: "phil" | "federal") => void;
}) {
  const mxEvent = useGibooMixpanel();
  const [user] = useUser();
  const [onboardingData, ready] = useOnboardingData();
  const navigate = useNavigate();

  const query: SearchQuery = recommendationQuery
    ? recommendationQuery
    : getSearchQueryFromOnboardingData(onboardingData);

  const fetchGrantSearch = useCallback(async (url: string, controller: AbortController) => {
    try {
      const req = JSON.parse(url.slice(9));
      return axios
        .post(
          process.env.REACT_APP_API_URL + `/api/v2/search/grants`,
          {
            ...req,
            store_search_history: false,
            hidden_uids: [],
            search_by_name: false,
            pagination_limit: 20,
            pagination_skip: 0,
          },
          { ...config, ...{ signal: controller.signal } },
        )
        .then((res) => ({
          ...res.data,
          items: res.data.items
            .map((d: any) => ({
              ...d,
              search_type: "grant",
              search_by_name: false,
              pagination_limit: 20,
              pagination_skip: 0,
              recommendation: true,
            }))
            .map((d: any) => ({
              ...d,
              ...matchTaxonomyBySearchQuery(
                d,
                req,
                d.service_specific_loc,
                d.type === "grant-page" ? false : true,
              ),
            })),
        }))
        .then((res) => res.items);
    } catch {
      return new Promise<any>((resolve, reject) => reject());
    }
  }, []);
  const {
    data: grant,
    isLoading: isGrantLoading,
    error: errorGrant,
  } = useCancellableSWR<any[]>(
    !ready || !query
      ? null
      : "gsr_grant" +
          JSON.stringify({
            ...query,
            ...queryMaskForGrantSearch,
          }),
    fetchGrantSearch,
    swrOptionDedupling5mins,
  );
  const recommendation = hasInitialData ? recData : grant;
  const recommendationLoading = isLoading || isGrantLoading;
  return (
    <div
      className={classNames({
        "loading-light-shimmer-on": recommendationLoading,
      })}
    >
      {!recommendation || (recommendation.length === 0 && recommendationLoading)
        ? Array.from({ length: 5 }, (x, i) => i).map((_, i) => (
            <div
              key={`${i}_item`}
              className="loading-light-shimmer-child flex h-[142px] w-full flex-col gap-[16px]  rounded border border-gray-300 bg-white px-[28px] py-[16px]"
            >
              <span className="flex justify-between gap-[10px]">
                <div className="h-[28px] w-full" />
                <div className="h-[14px] w-[30px]" />
              </span>
              <div className="h-[14px] w-1/2" />
              <div className="h-[48px] w-full" />
            </div>
          ))
        : recommendation?.map((grant, index) => {
            return (
              <GrantCardSimple
                wrapperClass="p-5 border-t first:border-t-0 border-gray-300"
                key={"rec-grant-from-same-funder-" + index}
                grant={grant}
                query={query}
                onClick={() => {
                  mxEvent(MIXPANEL_EVENTS_V2.detail_view.similar_grant.clicked, {
                    targetType: grant.type === "grant-page" ? "virtual_grant" : "grant",
                    targetId: grant._id,
                    targetName: grant.name,
                    location: location.pathname,
                  });
                  navigate(`/grants/${grant._id}`);
                }}
              />
            );
          })}
    </div>
  );
}

export default RecGrants;
