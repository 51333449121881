import axios from "axios";
import config from "../api";
interface AIService {
  content: string;
  instruction: string;
  selected_phrase: string;
}

const AiSuggestionService = (data: AIService) => {
  if (data === undefined) return;
  return new Promise<{ revised_phrase: string }>((resolve, reject) => {
    axios
      .put(
        process.env.REACT_APP_API_URL + "/api/v1/openai/loi",
        {
          ...data,
        },
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export { AiSuggestionService };
