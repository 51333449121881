import classNames from "classnames";
import * as React from "react";

interface EditButtonProps {
  onClick?: (e: any) => void;
  className?: string;
  fillClass?: string;
  white?: boolean;
  size?: "lg" | "md" | "sm";
}
function EditButton({ size = "sm", ...props }: EditButtonProps) {
  const sizeVariants: any = {
    sm: "!min-h-5 !min-w-5",
    md: "!min-h-6 !min-w-6",
    lg: "!min-h-7 !min-w-7",
  };
  return (
    <span
      onClick={(e) => {
        props?.onClick?.(e);
      }}
      className={classNames("h-5 w-5 cursor-pointer", sizeVariants[size], props.className)}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4617 3.67858C16.2466 3.46345 15.9912 3.29279 15.7101 3.17636C15.4291 3.05993 15.1278 3 14.8236 3C14.5194 3 14.2181 3.05993 13.937 3.17636C13.656 3.29279 13.4006 3.46345 13.1855 3.67858L4.47381 12.3903C4.21458 12.6495 4.02606 12.9712 3.92767 13.3246L3.01626 16.5797C2.99521 16.6551 2.99459 16.7348 3.01447 16.8106C3.03435 16.8864 3.07402 16.9555 3.12942 17.0109C3.18481 17.0663 3.25394 17.1059 3.32971 17.1258C3.40548 17.1457 3.48518 17.1451 3.56063 17.124L6.81506 16.2126C7.16851 16.1137 7.49052 15.9254 7.75004 15.6659L16.4617 6.95423C16.896 6.51981 17.14 5.93068 17.14 5.3164C17.14 4.70213 16.896 4.113 16.4617 3.67858ZM13.8106 4.30308C14.0793 4.0344 14.4438 3.8835 14.8238 3.88355C15.2038 3.88361 15.5683 4.03462 15.8369 4.30337C16.1056 4.57212 16.2565 4.9366 16.2565 5.31661C16.2564 5.69663 16.1054 6.06106 15.8366 6.32973L14.9293 7.23643L12.9033 5.20977L13.8106 4.30308ZM12.2788 5.83485L14.3054 7.86151L7.12495 15.0414C6.97287 15.1935 6.78417 15.3039 6.57705 15.3619L4.07849 16.0612L4.7778 13.5632C4.83592 13.3562 4.94628 13.1675 5.0983 13.0153L12.2788 5.83485Z"
          fill="#5F5F5F"
          className={props.fillClass}
        />
      </svg>
    </span>
  );
}

export default EditButton;
