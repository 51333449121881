import { useSearchParams } from "react-router-dom";
import { Onboarding, OnboardingRequest } from "../../../app/onboarding";
import { getOnboardingStatus, updateOnboardingAsync } from "../../../app/onboardingSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import PlainButton from "../../../components/PlainButton";
import { useEffect } from "react";
import PrimaryButton from "../../../components/PrimaryButton";
import Button from "../../../components/tailwind/Button";

interface OnboardingBackProps {
  data?: OnboardingRequest;
  handleOnClick?: () => void;
  outline?: boolean;
  noAction?: boolean;
}
const getValidNumber = (v: string | null, defaultNumber = 0): number => {
  const _v = v ? Number(v) : undefined;
  if (_v !== undefined && !isNaN(_v)) return _v;
  return defaultNumber;
};
const OnboardingBack = ({
  data,
  handleOnClick,
  outline = false,
  noAction = false,
}: OnboardingBackProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const _type = getValidNumber(searchParams.get("type"), 0);
  const _step = getValidNumber(searchParams.get("step"), 0);
  const handleBack = () => {
    // setSearchParams(
    //   new URLSearchParams([
    //     ["type", `${_type}`],
    //     ["step", `${_step}`],
    //     ["from_back", "true"],
    //   ]),
    // );
    dispatch(updateOnboardingAsync(data ? data : {}, -1));
  };
  if (outline) {
    return (
      <>
        {_step ? (
          <Button
            className="!h-[37px] w-fit !text-sm"
            size="md"
            handleOnClick={() => {
              handleOnClick?.();
              !noAction && handleBack();
            }}
            id="btn-back"
            label={"Back"}
            outline
            prependIcon={<i className="gi gi-left-arrow" />}
          />
        ) : null}
      </>
    );
  }
  return (
    <>
      {_step ? (
        <div className="giboo-fixed-left-bottom p-5">
          <PlainButton
            id="btn-back"
            label="back"
            leftIconClass="fa-solid fa-arrow-left gray mt-1"
            handleOnClick={() => {
              handleOnClick?.();
              handleBack();
            }}
            underline
          />
        </div>
      ) : null}
    </>
  );
};
export default OnboardingBack;
