import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import config from "../../../api";
import PlainButton from "../../../components/PlainButton";
import PrimaryButton from "../../../components/PrimaryButton";
import Spinner from "../../../components/Spinner";
import LeftInnerIconInput from "../../../components/LeftInnerIconInput";
import useUser from "../../../hooks/useUser";
import InputMember from "./InputEmployee";
import Checkbox from "../../../components/checkbox/Checkbox";
import { getOtherTaxonomies } from "../../../services/taxonomy.services";
import useSWR from "swr";
import { contactInfoService } from "./service";

interface IDonor {
  _id?: string;
  ein: string;
  name: string;
  website?: string;
  address: string;
}
interface IContactInfo {
  firstname?: string;
  lastname?: string;
  email?: string;
  website?: string;
  apply_via_post: boolean;
  address?: string;
}
interface IMemberContactInfo {
  _id: string;
  firstname: string;
  lastname: string;
  title?: string;
  titles?: string[];
  email?: string;
  phone?: string;
  linkedin?: string;
  twitter?: string;
  memo?: string;
  category?: string;
}
interface IDonorContactInformationRequest {
  completed: boolean;
  website?: string;
  website_by_manual?: string;
  guideline_website?: string;
  grant_availability: boolean;
  loi?: IContactInfo;
  general?: IContactInfo;
  members?: IMemberContactInfo[];
}
interface IDonorContactInformation extends IDonorContactInformationRequest {
  _id: string;
  address?: string;
  name: string;
  ein: string;
  created_at: string;
  updated_at: string;
}

interface IOption {
  label: string;
  value: string;
}
const swrOption = {
  dedupingInterval: 1000 * 3600,
  refreshInterval: 0,
  revalidateIfStale: false,
  keepPreviousData: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}; // 60 mins
const TITLE_TAXONOMY_ID = "6467c82b784b787ee02f3a08";
const fetchTaxonomiesAsOption = async (url: string) => {
  if (!url) return new Promise<IOption[]>((resolve) => resolve([]));
  return getOtherTaxonomies(url.split("@")[1])
    .then((data) => data as { name: string }[])
    .then((data) =>
      data
        .map((item) => ({ label: item.name, value: item.name } as IOption))
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
};
const regEmail = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
const isEmailOk = (email: string) => {
  return regEmail.test(email);
};
const ContactEditView = () => {
  const [user] = useUser();
  const navigate = useNavigate();
  const { state } = useLocation() as { state: { donor?: IDonor } };
  const { id } = useParams();
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const [data, setData] = useState<IDonorContactInformation>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [website, setWebsite] = useState<string>("");
  const [guidelineWebsite, setGuidelineWebsite] = useState<string>("");
  const [grantAvailable, setGrantAvailable] = useState<boolean>(false);

  const [loi, setLoi] = useState<IContactInfo>({
    apply_via_post: false,
    firstname: "",
    lastname: "",
    email: "",
    website: "",
    address: "",
  });
  const [general, setGeneral] = useState<IContactInfo>({
    apply_via_post: false,
    firstname: "",
    lastname: "",
    email: "",
    website: "",
    address: "",
  });
  const [members, setMembers] = useState<IMemberContactInfo[]>([]);

  const { data: titleLabels } = useSWR<IOption[]>(
    `taxonomy@${TITLE_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data: donorWebsite } = useSWR<IDonor>(
    process.env.REACT_APP_API_URL + `/api/v1/donors/${id}?by=id`,
    fetch,
    swrOption,
  );
  useEffect(() => {
    if (!id) return;
    setNotFound(false);
    setFetchLoading(true);
    contactInfoService
      .getDonorContactInfo(id)
      .then((data: IDonorContactInformation) => setData(data))
      .catch((error: any) => {
        // console.log(error);
        if (error && error.response && error.response.status === 404) setNotFound(true);
      })
      .finally(() => setFetchLoading(false));
  }, [id]);
  const donor = data;
  useEffect(() => {
    if (donor && donorWebsite)
      setWebsite(
        donor?.website
          ? donor.website.toLowerCase()
          : donorWebsite?.website
          ? donorWebsite.website.toLowerCase()
          : "",
      );
    if (donor && donor.address)
      setLoi((prev) => (prev.address ? prev : { ...prev, address: donor.address }));
  }, [donor, donorWebsite]);
  useEffect(() => {
    if (!data) return;
    if (data.website_by_manual || data.website)
      setWebsite(data.website_by_manual || data.website || "");
    if (data.guideline_website) setGuidelineWebsite(data.guideline_website);
    setGrantAvailable(data.grant_availability || false);
    if (data.loi)
      setLoi({
        apply_via_post: data.loi.apply_via_post || false,
        firstname: data.loi.firstname || "",
        lastname: data.loi.lastname || "",
        email: data.loi.email || "",
        website: data.loi.website || "",
        address: data.loi.address || "",
      });
    if (data.general)
      setGeneral({
        apply_via_post: data.general.apply_via_post || false,
        firstname: data.general.firstname || "",
        lastname: data.general.lastname || "",
        email: data.general.email || "",
        website: data.general.website || "",
        address: data.general.address || "",
      });
    if (data.members)
      setMembers(
        data.members.map((emp) => ({
          _id: emp._id,
          firstname: emp.firstname || "",
          lastname: emp.lastname || "",
          title: emp.title || "",
          email: emp.email || "",
          phone: emp.phone || "",
          linkedin: emp.linkedin || "",
          twitter: emp.twitter || "",
          memo: emp.memo || "",
          category: emp.category || undefined,
        })),
      );
  }, [data]);

  // console.log("data", data);
  const submit = async (completed: boolean): Promise<boolean> => {
    if (!id || loading) return false;
    setLoading(true);
    const success = await contactInfoService
      .upsertDonorContactInfo(id, {
        completed,
        website,
        guideline_website: guidelineWebsite,
        grant_availability: grantAvailable,
        loi: { ...loi, email: loi.email && isEmailOk(loi.email) ? loi.email : undefined },
        general: {
          ...general,
          email: general.email && isEmailOk(general.email) ? general.email : undefined,
        },
        members: members.map((emp) => ({
          ...emp,
          email: emp.email && isEmailOk(emp.email) ? emp.email : undefined,
        })),
      } as IDonorContactInformationRequest)
      .then(() => {
        setLoading(false);
        return true;
      })
      .catch(() => {
        return false;
      });
    setLoading(false);
    return success;
  };
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const success = await submit(true);
    // console.log("success", success);
    if (success) navigate("/admin/contacts");
  };
  const onSave = async () => {
    const success = await submit(false);
    if (success) navigate(0);
  };

  const disabled = false;

  return (
    <div className="flex flex-col p-6">
      {fetchLoading ? (
        <div className="mt-24">
          <Spinner />
        </div>
      ) : (
        <>
          {user?.email.endsWith("@giboo.com") && (
            <div className="new-grant-header-container bg-gray-50">
              <div className="new-grant-header flex items-center justify-between">
                <h3 className="purple">Contact information upload</h3>
                <div className="mr-3 flex gap-3" style={{ width: "400px" }}>
                  <PlainButton
                    className="w-full"
                    id="btn-save"
                    label="Save draft"
                    handleOnClick={onSave}
                    outline
                    disabled={loading}
                  />
                  <PrimaryButton
                    className="w-full"
                    color="purple"
                    id="btn-submit"
                    label="Publish"
                    handleOnClick={onSubmit}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="mx-auto flex w-full max-w-[900px] py-20">
            <div className="flex w-full flex-col gap-12 p-5">
              {donor && (
                <div className="flex w-full flex-col">
                  <p className="text-gray-700">{donor.ein}</p>
                  <h4 className="text-gray-900">{donor.name}</h4>
                </div>
              )}
              <div className="flex w-full flex-col gap-3">
                <div className="flex w-full flex-col gap-3">
                  <h3 className="text-gray-700">Basic Information</h3>
                </div>
                <div className="flex flex-col gap-3">
                  <p className="semibold">Website url</p>
                  <LeftInnerIconInput
                    id="input-website"
                    disabled={loading}
                    value={website}
                    placeholder="Enter website url"
                    handleOnChange={(e) => setWebsite(e.currentTarget.value)}
                  />
                </div>
                {/* <div className="flex flex-col gap-3">
                  <p className="semibold">Guideline website url</p>
                  <LeftInnerIconInput
                    id="input-guideline-website"
                    disabled={loading}
                    value={guidelineWebsite}
                    placeholder="Enter Guideline website url"
                    handleOnChange={(e) => setGuidelineWebsite(e.currentTarget.value)}
                  />
                </div> */}
                {/* <Checkbox
                  id="input-grant-availability"
                  checked={grantAvailable}
                  onChange={() => setGrantAvailable((prev) => !prev)}
                  label="Grant Available"
                /> */}
              </div>
              {/* <div className="flex w-full flex-col gap-3">
                <h3 className="text-gray-700">LOI & Grant</h3>
                <div className="flex flex-col gap-1">
                  <p className="semibold">Name</p>
                  <div className="grid grid-cols-2 gap-3">
                    <LeftInnerIconInput
                      id="input-loi-firstname"
                      disabled={loading}
                      value={loi.firstname}
                      placeholder="First name"
                      handleOnChange={(e) =>
                        setLoi((prev) => ({ ...prev, firstname: e.target.value }))
                      }
                    />
                    <LeftInnerIconInput
                      id="input-loi-lastname"
                      disabled={loading}
                      value={loi.lastname}
                      placeholder="Last name"
                      handleOnChange={(e) =>
                        setLoi((prev) => ({ ...prev, lastname: e.target.value }))
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="semibold mb-0">Email</p>
                  <LeftInnerIconInput
                    id="input-loi-email"
                    disabled={loading}
                    value={loi.email}
                    placeholder="Email address"
                    handleOnChange={(e) => setLoi((prev) => ({ ...prev, email: e.target.value }))}
                  />
                  {loi.email && !isEmailOk(loi.email) && (
                    <h5 className="purple">Email address is invalid</h5>
                  )}
                </div>
                <Checkbox
                  id="input-loi-post"
                  checked={loi.apply_via_post}
                  onChange={() =>
                    setLoi((prev) => ({ ...prev, apply_via_post: !prev.apply_via_post }))
                  }
                  label="Apply via paper mail"
                />
                {loi.apply_via_post ? (
                  <div className="flex flex-col gap-1">
                    <p className="semibold mb-0">Address</p>
                    <LeftInnerIconInput
                      key="input-loi-address"
                      id="input-loi-address"
                      disabled={loading}
                      value={loi.address}
                      placeholder="Address"
                      handleOnChange={(e) =>
                        setLoi((prev) => ({ ...prev, address: e.target.value }))
                      }
                    />
                  </div>
                ) : (
                  <div className="flex flex-col gap-1">
                    <p className="semibold mb-0">Apply url</p>
                    <LeftInnerIconInput
                      key="input-loi-apply"
                      id="input-loi-apply"
                      disabled={loading}
                      value={loi.website}
                      placeholder="Apply url"
                      handleOnChange={(e) =>
                        setLoi((prev) => ({ ...prev, website: e.target.value }))
                      }
                    />
                  </div>
                )}
              </div>
              <div className="flex w-full flex-col gap-3">
                <h3 className="text-gray-700">General</h3>
                <div className="flex flex-col gap-1">
                  <p className="semibold">Name</p>
                  <div className="grid grid-cols-2 gap-3">
                    <LeftInnerIconInput
                      id="input-general-firstname"
                      disabled={loading}
                      value={general.firstname}
                      placeholder="First name"
                      handleOnChange={(e) =>
                        setGeneral((prev) => ({ ...prev, firstname: e.target.value }))
                      }
                    />
                    <LeftInnerIconInput
                      id="input-general-lastname"
                      disabled={loading}
                      value={general.lastname}
                      placeholder="Last name"
                      handleOnChange={(e) =>
                        setGeneral((prev) => ({ ...prev, lastname: e.target.value }))
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="semibold mb-0">Email</p>
                  <LeftInnerIconInput
                    id="input-general-email"
                    disabled={loading}
                    value={general.email}
                    placeholder="Email address"
                    handleOnChange={(e) =>
                      setGeneral((prev) => ({ ...prev, email: e.target.value }))
                    }
                  />
                  {general.email && !isEmailOk(general.email) && (
                    <h5 className="purple">Email address is invalid</h5>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <p className="semibold mb-0">Contact url</p>
                  <LeftInnerIconInput
                    id="input-general-url"
                    disabled={loading}
                    value={general.website}
                    placeholder="Contact url"
                    handleOnChange={(e) =>
                      setGeneral((prev) => ({ ...prev, website: e.target.value }))
                    }
                  />
                </div>
              </div> */}
              <div className="flex w-full flex-col gap-3">
                <h3 className="text-gray-700">Members</h3>
                <div className="flex flex-col gap-2">
                  <div className="ml-5 flex flex-col items-start gap-12">
                    {titleLabels &&
                      members.map((item, i) => (
                        <div key={i} className="w-full p-4 odd:bg-gray-100">
                          <InputMember
                            i={i}
                            members={members}
                            setMembers={setMembers}
                            titleLabels={titleLabels}
                            key={i}
                            loading={loading}
                          />
                          <div key={`hr-${i}`} className="w-full border-t border-gray-400" />
                        </div>
                      ))}
                    <PlainButton
                      id={`btn-${id}-add`}
                      label="Add more members"
                      leftIconClass="fa-regular fa-plus"
                      handleOnClick={() => {
                        setMembers((prev) => [
                          ...prev,
                          {
                            _id: "",
                            employ_type: "",
                            firstname: "",
                            lastname: "",
                            email: "",
                            phone: "",
                            linkedin: "",
                            memo: "",
                          },
                        ]);
                      }}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactEditView;
