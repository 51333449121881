import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Spacer from "../../components/Spacer";
import { getValidNumber } from "../../utils/formatHelper";
import Button from "../../components/tailwind/Button";
import PlainButton from "../../components/PlainButton";
import useUser from "../../hooks/useUser";
import TestProgressHeader, {
  TEST_GRANT_CREATION_SECTIONS,
  TEST_GRANT_CREATION_STEPS,
  TypeStepKey,
} from "./TestProgressHeader";
import useUserDictionary from "../../hooks/useUserDictionary";
function TestView({ KEY, content }: { KEY: TypeStepKey; content: string }) {
  const [section, setSection] = useState<number>(0);
  const [step, setStep] = useState<number>(0);
  const { value, update } = useUserDictionary<string[]>("test_financial", []);
  const [fromBack, setFromBack] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const _section = getValidNumber(searchParams.get("section"), 0);
    const _step = getValidNumber(searchParams.get("step"), 0);
    const from_back = searchParams.get("from_back");

    setSection(_section >= 0 && _section < TEST_GRANT_CREATION_SECTIONS.length ? _section : 0);
    setStep(_step);
    setFromBack(from_back && from_back.toLowerCase()[0] === "t" ? true : false);
  }, [searchParams]);
  const backIsAvailable = section > 0 || step > 0;
  const nextIsAvailable =
    section + 1 < TEST_GRANT_CREATION_STEPS.length ||
    step + 1 < TEST_GRANT_CREATION_STEPS[section].length;

  const setSectionAndPage = useCallback(
    (section: number, step = 0, from_back = false) => {
      setSearchParams(
        new URLSearchParams([
          ["section", `${section}`],
          ["step", `${step}`],
          ...(from_back ? [["from_back", "true"]] : []),
        ]),
      );
    },
    [setSearchParams],
  );
  const back = useCallback(() => {
    if (section > 0 || step > 0) {
      if (step === 0)
        setSectionAndPage(section - 1, TEST_GRANT_CREATION_STEPS[section - 1].length - 1, true);
      else setSectionAndPage(section, step - 1, true);
    }
  }, [section, step, TEST_GRANT_CREATION_STEPS]);
  const next = useCallback(() => {
    if (
      section < TEST_GRANT_CREATION_SECTIONS.length ||
      step + 1 < TEST_GRANT_CREATION_STEPS[section].length
    ) {
      if (step + 1 >= TEST_GRANT_CREATION_STEPS[section].length) setSectionAndPage(section + 1, 0);
      else setSectionAndPage(section, step + 1);
    }
  }, [section, step, TEST_GRANT_CREATION_SECTIONS, TEST_GRANT_CREATION_STEPS]);
  const renderButtons = useCallback(() => {
    const prevReviewd = value || [];
    return (
      <div className="mt-8 flex gap-2">
        <Button
          spinnerColor="purple"
          id={"btn-back"}
          label={"Back"}
          outline
          prependIcon={<i className="gi-md gi-left-arrow" />}
          handleOnClick={() => {
            back();
          }}
          disabled={!backIsAvailable}
        />
        <Button
          id={"btn-next"}
          label={"Next"}
          appendIcon={<i className="gi-md gi-right-arrow" />}
          handleOnClick={() => {
            const reviewed = [...prevReviewd.filter((k) => k !== KEY), KEY];
            update(reviewed);
            next();
          }}
          disabled={!nextIsAvailable}
        />
        <PlainButton
          id={"btn-skip"}
          handleOnClick={() => {
            next();
          }}
          color="purple"
          className="!text-sm"
          label="Skip"
        />
      </div>
    );
  }, [section, step, update, value]);
  return (
    <div>
      {content}
      {content}
      {content}
      {renderButtons()}
    </div>
  );
}
const VIEWS = [
  [
    <TestView key={"grant-information-name"} KEY={"name"} content={"hi"} />,
    <TestView key={"grant-information-amount"} KEY={"amount"} content={"hi"} />,
    <TestView key={"grant-information-website"} KEY={"website"} content={"hi"} />,
    <TestView key={"grant-information-funding-type"} KEY={"funding_type"} content={"hi"} />,
    <TestView key={"grant-information-deadline"} KEY={"deadline_application"} content={"hi"} />,
  ],
  [
    // <GrantInterestDocument key={"grant-interest-document"} />,
    <TestView key={"grant-interest-focus-area"} KEY={"FocusAreas"} content={"hi"} />,
    <TestView key={"grant-interest-beneficiary"} KEY={"Beneficiaries"} content={"hi"} />,
    // <GrantInterestProgram key={"grant-interest-program"} />,
    <TestView
      key={"grant-interest-geograhpical-focus"}
      KEY={"GeographicalPreferences"}
      content={"hi"}
    />,
  ],
  [
    <TestView key={"grant-eligibility-grantee-type"} KEY={"grantee_type"} content={"hi"} />,
    <TestView key={"grant-eligibility-grantee-type-npo"} KEY={"grantee_type_npo"} content={"hi"} />,
    <TestView key={"grant-eligibility-fiscal-position"} KEY={"fiscal_position"} content={"hi"} />,
    <TestView key={"grant-eligibility-ineligibility"} KEY={"ineligibility"} content={"hi"} />,
    <TestView
      key={"grant-eligibility-financial-position"}
      KEY={"financial_position"}
      content={"hi"}
    />,
    <TestView key={"grant-eligibility-npo-location"} KEY={"npo_loc"} content={"hi"} />,
  ],
  [
    <TestView
      key={"grant-requirement-application"}
      KEY={"requirement_application"}
      content={"hi"}
    />,
    <TestView key={"grant-requirement-proposal"} KEY={"requirement_proposal"} content={"hi"} />,
  ],
  [<TestView key={"grant-requirement-reporting"} KEY={"requirement_reporting"} content={"hi"} />],
];

function TestProgressView() {
  const { id } = useParams();
  const [user] = useUser();
  const { state: context, ...browserLocation } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [section, setSection] = useState<number>(0);
  const [step, setStep] = useState<number>(0);
  const [fromBack, setFromBack] = useState<boolean>(false);
  const { value, update } = useUserDictionary<string[]>("test_financial", []);
  const key = TEST_GRANT_CREATION_STEPS[section]?.[step]?.value;
  useEffect(() => {
    const _section = getValidNumber(searchParams.get("section"), 0);
    const _step = getValidNumber(searchParams.get("step"), 0);
    const from_back = searchParams.get("from_back");

    setSection(_section >= 0 && _section < TEST_GRANT_CREATION_SECTIONS.length ? _section : 0);
    setStep(_step);
    setFromBack(from_back && from_back.toLowerCase()[0] === "t" ? true : false);
  }, [searchParams]);

  const setSectionAndPage = useCallback(
    (section: number, step = 0, from_back = false) => {
      setSearchParams(
        new URLSearchParams([
          ["section", `${section}`],
          ["step", `${step}`],
          ...(from_back ? [["from_back", "true"]] : []),
        ]),
      );
    },
    [setSearchParams],
  );
  return (
    <div className="flex w-full gap-10">
      <aside
        className="py-auto fixed top-[60px] flex min-w-[300px] justify-center border-r border-gray-300 bg-white shadow-md"
        style={{ maxHeight: "calc(100vh - 60px)", height: "calc(100vh - 60px)" }}
      >
        <div className="relative w-[260px] min-w-[260px]">
          <TestProgressHeader
            section={section}
            step={step}
            setSectionAndPage={setSectionAndPage}
            fromPath={context?.fromPath}
          />
        </div>
      </aside>
      <div className="ml-[300px] flex max-w-[1280px] grow flex-col gap-7 px-8 py-8">
        <div className="flex items-center gap-10">
          <h4 className="text-purple-500">{"Hello world" || ""}</h4>
          <Button
            id="btn-reset"
            label="reset progress"
            size="sm"
            handleOnClick={() => {
              update([]);
            }}
          />
        </div>
        {section >= 0 && section < VIEWS.length && step >= 0 && step < VIEWS[section].length ? (
          VIEWS[section][step]
        ) : (
          <TestView key={"TBD"} KEY={"name"} content={"TBD"} />
        )}
      </div>
      <Spacer height="100px" />
    </div>
  );
}
export default TestProgressView;
