import { Portal } from "@headlessui/react";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Divider } from "rsuite";
import { getOnboardingStatus } from "../../app/onboardingSlice";
import { RootState, useAppSelector } from "../../app/store";
import introductionImage from "../../assets/images/giboIntroduction.svg";
import ErrorBoundary from "../../components/ErrorBoundary";
import OrganizationSelector from "../../components/OrganizationSelector";
import Spacer from "../../components/Spacer";
import useEINFromSearchParams from "../../hooks/useEINFromSearchParams";
import useHowGibooWorks from "../../hooks/useHowGibooWorks";
import useLocalStorage from "../../hooks/useLocalstorage";
import usePageTitle from "../../hooks/usePagetitle";
import useUser from "../../hooks/useUser";
import TopNav from "../TopNav";
import "../css/onboarding.scss";
import logo from "../img/logo.svg";
import FindNPO from "./onboarding/FindNPO";
import Finish from "./onboarding/Finish";
import NPOMission from "./onboarding/NPOMission";
import NPOStage from "./onboarding/NPOStage";
import NPOadditionalInfo from "./onboarding/NPOadditionalInfo";
import NpoInviteMember from "./onboarding/NpoInviteMember";
import OnboardingType from "./onboarding/OnboardingType";
import RequestNPOToAdd from "./onboarding/RequestNPOToAdd";
import Role from "./onboarding/Role";
import ServiceLocation from "./onboarding/ServiceLocation";

interface IViewInfo {
  view: ReactNode;
  step: number;
}

const defaultViews: IViewInfo[] = [{ view: <OnboardingType />, step: 0 }];
const foundNPOViews: IViewInfo[] = [
  { view: <OnboardingType />, step: 0 },
  { view: <FindNPO />, step: 1 },
  { view: <Role />, step: 2 },
  { view: <NPOStage />, step: 3 },
  { view: <NPOMission />, step: 4 },
  { view: <NPOadditionalInfo />, step: 5 },
  // { view: <TaxonomyAll />, step: 6 },
  { view: <ServiceLocation />, step: 6 },
  // { view: <GibooFeatureStart />, step: 7 },
  { view: <NpoInviteMember />, step: 7 },
  { view: <Finish />, step: 8 },
];
const requestNPOToAddViews: IViewInfo[] = [
  { view: <OnboardingType />, step: 0 },
  { view: <RequestNPOToAdd />, step: 1 },
  { view: <Role />, step: 2 },
  { view: <NPOStage />, step: 3 },
  { view: <NPOMission />, step: 4 },
  { view: <NPOadditionalInfo />, step: 5 },
  // { view: <TaxonomyAll />, step: 6 },
  { view: <ServiceLocation />, step: 6 },
  // { view: <GibooFeatureStart />, step: 7 },
  { view: <Finish />, step: 7 },
];
const OtherNPOs: IViewInfo[] = [
  { view: <OnboardingType />, step: 0 },
  { view: <RequestNPOToAdd />, step: 1 },
  { view: <Role />, step: 2 },
  { view: <NPOStage />, step: 3 },
  { view: <NPOMission />, step: 4 },
  { view: <NPOadditionalInfo />, step: 5 },
  // { view: <TaxonomyAll />, step: 6 },
  { view: <ServiceLocation />, step: 6 },
  // { view: <GibooFeatureStart />, step: 7 },
  { view: <NpoInviteMember />, step: 7 },
  { view: <Finish />, step: 8 },
];
const planningNPOViews: IViewInfo[] = [
  { view: <OnboardingType />, step: 0 },
  { view: <RequestNPOToAdd isNew />, step: 1 },
  { view: <Role />, step: 2 },
  { view: <NPOMission />, step: 3 },
  { view: <NPOadditionalInfo />, step: 4 },
  // { view: <TaxonomyAll />, step: 5 },
  { view: <ServiceLocation />, step: 5 },
  // { view: <GibooFeatureStart />, step: 6 },
  { view: <NpoInviteMember />, step: 6 },
  { view: <Finish />, step: 7 },
];
const noneViews: IViewInfo[] = [
  { view: <OnboardingType />, step: 0 },
  { view: <Role />, step: 1 },
  { view: <Finish />, step: 2 },
];
const invitedViews: IViewInfo[] = [
  { view: <Role invited />, step: 0 },
  // { view: <GibooFeatureStart invited />, step: 1 },
  { view: <Finish />, step: 1 },
];
const views: IViewInfo[][] = [
  defaultViews,
  foundNPOViews,
  requestNPOToAddViews,
  planningNPOViews,
  noneViews,
  OtherNPOs,
  invitedViews,
];
export const getSkipOnboardingStep = (type: number) => {
  return views[type].length - 1;
};
const getValidNumber = (v: string | null, defaultNumber = 0): number => {
  const _v = v ? Number(v) : undefined;
  if (_v !== undefined && !isNaN(_v)) return _v;
  return defaultNumber;
};
function OnboardingView() {
  usePageTitle("Onboarding");
  const navigate = useNavigate();
  const [showIntro, setShowIntro] = useState(false);
  const { _id, type, step, completed, accepted, selected, role, isFetched } = useAppSelector(
    (state: RootState) => getOnboardingStatus(state),
  );
  const { cid, isLoading } = useEINFromSearchParams();
  const [user] = useUser();
  // v1.4
  const [searchParams, setSearchParams] = useSearchParams();
  const _type = getValidNumber(searchParams.get("type"), cid ? 1 : 0);
  const _step = getValidNumber(searchParams.get("step"), cid ? 1 : 0);
  const { reset } = useHowGibooWorks();
  const { value: joinPath, update: updateJoinPath, deleteThis } = useLocalStorage("JOIN_PATH");
  useEffect(() => {
    if (!user?._id || !isFetched) return;
    setSearchParams(
      new URLSearchParams([
        ["type", `${!type && cid ? 1 : type}`],
        ["step", `${step === undefined && cid ? 1 : step || 0}`],
      ]),
    );
  }, [type, step]);
  const correct_type = role >= 5 ? (_type ? _type : 0) : 6;
  const correct_step = _step && _step > 0 ? _step : 0;
  const [completedPercentage, setCompletedPercentage] = useState<number>(0);
  useEffect(() => {
    const currentStep = views[correct_type][correct_step]
      ? views[correct_type][correct_step].step
      : 0;
    const totalSteps = views[correct_type].length;
    const basePercentage = 10;
    const percentageCompletion = Math.min((currentStep / totalSteps) * 100 + basePercentage, 100);
    setCompletedPercentage(percentageCompletion);
  }, [correct_type, correct_step]);
  useEffect(() => {
    if (!selected) {
      navigate("/", { replace: true });
    } else if (completed && accepted) {
      navigate(joinPath || "/", { replace: true });
      deleteThis();
    } else {
      setShowIntro(true);
    }
  }, [completed, selected, accepted]);
  useEffect(() => {
    let timeout: any;
    if (showIntro) {
      reset();
      timeout = setTimeout(() => {
        setShowIntro(false);
      }, 1500);
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [showIntro]);
  const renderIntro = () => {
    return (
      <div className="flex h-[calc(100vh-10rem)] w-full items-center justify-center">
        <div className="flex w-[568px] flex-col items-center">
          <img
            src={introductionImage}
            className="h-[260px] w-[190px]"
            alt="creating project for you"
          />
          <h3 className="mt-[40px] font-poppins text-2xl font-semibold capitalize text-purple-900">
            Hello {user?.firstname || ""}!
          </h3>
          <h3 className="mt-5 font-poppins text-2xl font-semibold text-purple-900">
            We&apos;re excited to have you on Giboo 💜
          </h3>
        </div>
      </div>
    );
  };
  return (
    <>
      {isFetched || !isLoading ? (
        <>
          <ErrorBoundary>
            <OnboardingHeader />
            {!completed || !accepted ? (
              <div className="giboo-body" style={{ backgroundColor: "#fff" }}>
                <Portal>
                  <div className="fixed top-[58px] h-[10px] w-full bg-gray-50">
                    <div
                      style={{ width: `${completedPercentage}%` }}
                      className="h-full w-[42px] bg-gradient-to-r from-pink-500 via-purple-600 to-blue-500"
                    />
                  </div>
                </Portal>
                {showIntro || !isFetched ? (
                  renderIntro()
                ) : (
                  <div className="container mx-auto  max-w-[1280px]">
                    <div>
                      {views[correct_type][correct_step]
                        ? views[correct_type][correct_step].view
                        : null}
                    </div>
                    <Spacer height="100px" />
                  </div>
                )}
              </div>
            ) : null}
          </ErrorBoundary>
        </>
      ) : (
        <div className="grid h-screen w-screen place-items-center">
          <div
            className="m-5  inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-purple-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}
    </>
  );
}
export default OnboardingView;

const OnboardingHeader = () => {
  return (
    <div className="fixed top-0 z-40  w-screen bg-white shadow-sm ">
      <nav className="container mx-auto flex !h-[58px] w-full  max-w-[1280px] items-center  justify-between">
        <div className="flex items-center gap-[22px]">
          <a href="/">
            <div className="flex items-center">
              <img src={logo} alt="Giboo" width="40" height="24" />
            </div>
          </a>
          <div className="flex items-center gap-4">
            <OrganizationSelector />
          </div>
        </div>
        <div className="flex items-center gap-5">
          <Divider vertical className="h-[58px]" />
          <div className="mt-[4px] w-fit">
            <TopNav isOnboarding />
          </div>
        </div>
      </nav>
    </div>
  );
};
