import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import TextInput from "../../../../components/tailwind/TextInput";
import AlertModal from "../../../../components/tailwind/AlertModal";
import useOnboardingData from "../../../../hooks/useOnboarding";
import MultipleCreatableSelector from "../../../../components/selector/MultipleCreatableSelector";
import CreatableSelector from "../../../../components/selector/CreatableSelector";
import Button from "../../../../components/tailwind/Button";
import { IOrgManualMemberCreateRequest } from "../../../../types/org";
import useOrgManualMembers, {
  createOrgManualMember,
  deleteOrgManualMember,
} from "../../../../hooks/project/useOrgManualMembers";
import { isValidEmail } from "../../../../utils/formatHelper";
import useSWR from "swr";
import useTotalOrganizationMember from "../../../../hooks/useTotalOrganizationMember";
import SingleSelector from "../../../../components/selector/SingleSelector";
import {
  BOARD_PROFESSION_TAXONOMY_ID,
  BOARD_TITLE_TAXONOMY_ID,
  fetchTaxonomiesAsOption,
  ROLE_TAXONOMY_ID,
  swrOption,
  TITLE_TAXONOMY_ID,
} from "./AddNewEmployee";
import { Divider } from "rsuite";
import PlainButton from "../../../../components/PlainButton";
import GibooToast from "../../../../components/GibooToast";
import classNames from "classnames";
import PopupModal from "../../../../components/tailwind/PopupModal";
import useOrgs from "../../../../hooks/useOrgs";
import useOrgMembers from "../../../../hooks/project/useOrgMembers";
import { sendOrgInvitation } from "../../../../services/org.services";
import LeftInnerIconInput from "../../../../components/LeftInnerIconInput";
import RolePicker from "../../../../components/RolePicker";
import { scrollBarClass } from "../../../../utils/classes";
import UserProfile from "../../../../components/UserProfile";
import { roleToName } from "../../../../app/userSlice";
import useDropdownOption from "../../../../hooks/useDropdownOption";
import { FROM_FOR_MIXPANEL, Information, MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";
import { useLocation } from "react-router-dom";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";

const EMPSTATUS: IOption[] = [
  { label: "Full Time", value: "Full Time" },
  { label: "Part Time", value: "Part Time" },
];
const EMP_TYPE: IOption[] = [
  { label: "Employee", value: "employee" },
  { label: "Board member", value: "board" },
  { label: "Volunteers", value: "volunteer" },
];

interface IOption {
  label: string;
  value: string;
}
interface OrganizationAddEmployee {
  from_for_mixpanel: FROM_FOR_MIXPANEL;
  allowEdit?: boolean;
  customClass?: string | undefined;
  contentClass?: string;
  isEdit: boolean;
  employType?: "employee" | "volunteer" | "board";
  memberEditId?: string;
  onClose?: () => void;
  noBorder?: boolean;
  noPadding?: boolean;
  isPopup?: boolean;
  show?: boolean;
  setShow?: Dispatch<SetStateAction<boolean>>;
}

const titleClass = "py-2 font-poppins text-sm font-semibold text-gray-900 ";
const inputClass = "!max-h-[48px] !min-h-[48px] !h-[48px]";

function AddNewMember({
  from_for_mixpanel,
  employType = "employee",
  memberEditId,
  isEdit = false,
  noBorder = false,
  noPadding = false,
  isPopup = false,
  show = false,
  setShow,
  onClose,
  contentClass,
  ...props
}: OrganizationAddEmployee) {
  const mxEvent = useGibooMixpanel();
  const [onboardingData] = useOnboardingData();
  const { revalidate } = useOrgManualMembers(onboardingData._id);
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>("");
  const [email, setEmail] = useState<string>("");
  const [linkedin, setLinkedin] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [roleOption, setRoleOption] = useState<IOption[]>([]);
  const [titleOption, setTitleOption] = useState<IOption | undefined>();
  const [empStatus, setEmpStatus] = useState<IOption>();
  const [professionOption, setProfessionOption] = useState<IOption[]>([]);
  const [boardProfessionOption, setBoardProfessionOption] = useState<IOption[]>([]);
  const {
    sexualOrientationOption,
    religionOption,
    professionOption: BOARD_PROFESSION,
  } = useDropdownOption();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [type, setType] = useState<IOption>();
  const {
    data: memberEdit,
    update,
    isLoading: memberLoading,
  } = useTotalOrganizationMember(onboardingData._id, memberEditId);
  const { data: TITLES } = useSWR<IOption[]>(
    `taxonomy@${TITLE_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data: BOARD_TITLE } = useSWR<IOption[]>(
    `taxonomy@${BOARD_TITLE_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  // const { data: BOARD_PROFESSION } = useSWR<IOption[]>(
  //   `taxonomy@${BOARD_PROFESSION_TAXONOMY_ID}`,
  //   fetchTaxonomiesAsOption,
  //   swrOption,
  // );
  const { data: ROLE } = useSWR<IOption[]>(
    `taxonomy@${ROLE_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );

  useEffect(() => {
    if (memberLoading) return;
    if (memberEdit) {
      const {
        email,
        firstname,
        lastname,
        roles,
        title,
        profession,
        board_profession_sector,
        employ_type,
        employ_status,
        linkedin,
        twitter,
      } = memberEdit;
      employ_type
        ? setType(EMP_TYPE.find((i) => i.value === employ_type))
        : setType(EMP_TYPE.find((i) => i.value === employType));
      setFirstName(firstname);
      setLastName(lastname);
      setEmail(email || "");
      setLinkedin(linkedin || "");
      setTwitter(twitter || "");
      setRoleOption(roles?.map((item) => ({ label: item, value: item })) || []);
      setTitleOption(title ? { label: title, value: title } : undefined);
      setProfessionOption(profession?.map((item) => ({ label: item, value: item })) || []);
      setBoardProfessionOption(
        board_profession_sector?.map((item) => ({ label: item, value: item })) || [],
      );
      employ_status &&
        setEmpStatus(employ_status ? { label: employ_status, value: employ_status } : undefined);
    }
  }, [memberEdit, memberLoading]);
  const renderAddNewEmployee = () => {
    return (
      <div className={classNames("flex max-w-[480px] flex-col gap-3", contentClass)}>
        <div className="relative w-full">
          <div className={titleClass}>Member type</div>
          <SingleSelector
            id={`input-member-type`}
            value={type}
            placeholder="Choose member type"
            inputContainerClass={"h-[48px]"}
            placeholderClass="!text-gray-500 !text-sm"
            onChange={(v) => {
              setType(v ? v : undefined);
            }}
            compact
            data={EMP_TYPE}
            isClearable={false}
            dropdownIcon
          />
        </div>
        <TextInput
          id="fName"
          topPlaceholder="First name"
          topLabelClass="text-sm font-medium"
          className="!h-[48px]"
          value={firstName}
          placeholder="First name"
          handleOnChange={(e) => {
            setFirstName(e.target.value);
          }}
          disabled={isLoading}
        />
        <TextInput
          id="lname"
          value={lastName}
          className="!h-[48px]"
          topPlaceholder="Last name"
          topLabelClass="text-sm font-medium"
          placeholder="Last name"
          handleOnChange={(e) => {
            setLastName(e.target.value);
          }}
          disabled={isLoading}
        />

        <div className="relative w-full">
          <div className={titleClass}>{"Title"}</div>
          <CreatableSelector
            id="emp-title"
            placeholder="Choose title"
            className={inputClass}
            inputContainerClass="h-[48px]"
            data={(type?.value === "board" ? BOARD_TITLE : TITLES) || []}
            value={titleOption}
            onCreateOption={(val: string) => setTitleOption({ label: val, value: val })}
            onChange={(v) => setTitleOption(v ? v : undefined)}
            disabled={isLoading}
            isClearable={false}
          />
        </div>
        {type?.value !== "board" && (
          <div className="relative w-full">
            <div className={titleClass}>
              {type?.value === "volunteer" ? "Role as a volunteer" : "Role in the organization"}
            </div>
            <MultipleCreatableSelector
              id="role"
              placeholder={
                type?.value === "volunteer"
                  ? "Choose role as a volunteer"
                  : "Choose role in the organization"
              }
              className={inputClass}
              data={ROLE || []}
              value={roleOption}
              disabled={isLoading}
              onCreateOption={(val: string) =>
                setRoleOption((prev) => [...prev, { value: val, label: val }])
              }
              onChange={(v) => setRoleOption(v.map((item) => item))}
            />
          </div>
        )}
        {type?.value === "employee" && (
          <div className="relative w-full">
            <div className={titleClass}>Employment status</div>
            <SingleSelector
              id={`input-generation`}
              value={empStatus}
              placeholder="Choose employment status"
              inputContainerClass={"h-[48px]"}
              placeholderClass="!text-gray-500 !text-sm"
              onChange={(v) => {
                setEmpStatus(v ? v : undefined);
              }}
              compact
              data={EMPSTATUS}
              isClearable={false}
              dropdownIcon
            />
          </div>
        )}
        {type?.value === "board" && (
          <div className="relative w-full">
            <div className={titleClass}>Profession</div>
            <MultipleCreatableSelector
              id="board-profession-background"
              placeholder="Choose profession"
              className={inputClass}
              data={BOARD_PROFESSION || []}
              value={professionOption}
              onCreateOption={(val: string) =>
                setProfessionOption((prev) => [...prev, { value: val, label: val }])
              }
              onChange={(v) => setProfessionOption(v.map((item) => item))}
              disabled={isLoading}
              dropdownIcon
              isClearable={false}
            />
          </div>
        )}
        <TextInput
          id="email"
          topPlaceholder={`Email address`}
          topLabelClass="text-sm font-medium"
          value={email}
          className="!h-[48px]"
          placeholder="Email address"
          handleOnChange={(e) => {
            setEmail(e.target.value);
          }}
          disabled={isLoading}
        />
        <TextInput
          id="linkedin"
          topPlaceholder={`LinkedIn`}
          topLabelClass="text-sm font-medium"
          value={linkedin}
          className="!h-[48px]"
          placeholder="Enter url"
          handleOnChange={(e) => {
            setLinkedin(e.target.value);
          }}
          disabled={isLoading}
        />
        <TextInput
          id="twitter"
          topPlaceholder={`X (Twitter)`}
          topLabelClass="text-sm font-medium"
          value={twitter}
          className="!h-[48px]"
          placeholder="Enter url"
          handleOnChange={(e) => {
            setTwitter(e.target.value);
          }}
          disabled={isLoading}
        />
      </div>
    );
  };

  const reset = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setRoleOption([]);
    setTitleOption(undefined);
    setEmpStatus(undefined);
    setProfessionOption([]);
    setBoardProfessionOption([]);
  };

  const saveMember = (data: IOrgManualMemberCreateRequest) => {
    const popupData = { firstname: firstName, lastname: lastName } as IOrgManualMemberCreateRequest;
    if (isEdit) {
      update(data)
        .then((res) => {
          if (res)
            GibooToast({
              type: "success",
              message: "Updated successfully",
            });
          onClose?.();
        })
        .catch((err) => {
          // console.log(err);
          GibooToast({
            type: "failure",
            message: "We apologize for the inconvenience., Please try again",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      createOrgManualMember(onboardingData._id, isPopup ? popupData : data)
        .then((res) => {
          GibooToast({
            type: "success",
            message: "Created successfully",
          });
          revalidate();
          onClose?.();
        })
        .catch((err) => {
          GibooToast({
            type: "failure",
            message: "We apologize for the inconvenience., Please try again",
          });
        })
        .finally(() => {
          revalidate();
          setIsLoading(false);
        });
    }
  };
  const handleSave = () => {
    setIsLoading(true);
    const profession = professionOption.map((item) => item.value);
    const boardProfession = boardProfessionOption.map((item) => item.value);
    const role = roleOption.map((item) => item.value);
    const title = titleOption?.value || "";
    const employee_type = type?.value;
    const data = {
      ...memberEdit,
      employ_type: employee_type,
      firstname: firstName,
      lastname: lastName,
      email: email === "" ? null : email,
      roles: role ? [...role] : [],
      title: title,
      linkedin: linkedin,
      twitter: twitter,
      employ_status: empStatus?.value,
      profession: profession ? [...profession] : [],
      board_profession_sector: boardProfession ? [...boardProfession] : [],
    } as IOrgManualMemberCreateRequest;

    if (type?.value !== "board") {
      data["profession"] = [];
      data["board_profession_sector"] = [];
    } else {
      data["roles"] = [];
    }
    if (type?.value !== "employee") {
      data["employ_status"] = undefined;
    }
    saveMember(data);
  };

  const handleDeleteUser = () => {
    if (!memberEdit?._id) return;
    setIsDeleting(true);
    deleteOrgManualMember(onboardingData._id, memberEdit._id)
      .then(() => {
        revalidate();
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setShowAlert(false);
        setIsDeleting(false);
        reset();
      });
  };
  const renderContent = (hideFooter = false) => {
    return (
      <>
        {showAlert && (
          <AlertModal
            variant="purple"
            handleCancel={() => {
              setShowAlert(false);
            }}
            handleConfirm={() => {
              handleDeleteUser();
            }}
            isLoading={isDeleting}
            confirmTitle={"Remove"}
            title="Remove"
            content="Are you sure to remove from organization?"
            isOpen={showAlert}
            handleClose={() => setShowAlert(false)}
          />
        )}
        <div
          className={classNames(
            "w-full rounded bg-white",
            !noBorder && "border border-gray-300 ",
            !noPadding && "p-4",
          )}
        >
          {renderAddNewEmployee()}
          {!hideFooter && (
            <>
              <Divider className="my-4" />
              <div className="flex items-center justify-end gap-2">
                {isEdit ? (
                  <div
                    className="grid !h-[29px] cursor-pointer place-items-center px-2 font-poppins text-sm text-gray-600"
                    onClick={() => {
                      setShowAlert(true);
                    }}
                  >
                    Remove from organization
                  </div>
                ) : (
                  <Button
                    className="!h-[29px]"
                    id="save-btn"
                    outline
                    handleOnClick={() => onClose?.()}
                    label="cancel"
                  />
                )}
                <Button
                  className="!h-[29px]"
                  id="save-btn"
                  handleOnClick={() => handleSave()}
                  loading={isLoading}
                  label={isEdit ? "Done" : "Create"}
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  };
  const renderPopupContent = () => {
    return (
      <>
        {showAlert && (
          <AlertModal
            variant="purple"
            handleCancel={() => {
              setShowAlert(false);
            }}
            handleConfirm={() => {
              handleDeleteUser();
            }}
            isLoading={isDeleting}
            confirmTitle={"Remove"}
            title="Remove"
            content="Are you sure to remove from organization?"
            isOpen={showAlert}
            handleClose={() => setShowAlert(false)}
          />
        )}
        <div
          className={classNames(
            "mx-auto w-full rounded bg-white p-[28px]",
            !noBorder && "border border-gray-300 ",
            !noPadding && "p-4",
          )}
        >
          <div className={classNames("flex max-w-[480px] flex-col gap-3", contentClass)}>
            <TextInput
              id="fName"
              topPlaceholder="First name*"
              topLabelClass="text-sm font-medium"
              className="!h-[48px]"
              value={firstName}
              placeholder="First name"
              handleOnChange={(e) => {
                setFirstName(e.target.value);
              }}
              disabled={isLoading}
            />
            <TextInput
              id="lname"
              value={lastName}
              className="!h-[48px]"
              topPlaceholder="Last name*"
              topLabelClass="text-sm font-medium"
              placeholder="Last name"
              handleOnChange={(e) => {
                setLastName(e.target.value);
              }}
              disabled={isLoading}
            />
          </div>
          <MemberInvite
            from_for_mixpanel={from_for_mixpanel}
            handleClose={() => {
              setShow?.(false);
            }}
          />
        </div>
      </>
    );
  };
  if (isPopup) {
    return (
      <PopupModal
        isOpen={show}
        handleClose={() => {
          setShow?.(false);
        }}
        title="Add more organization memebers"
        wrapperClass="!min-w-fit !max-w-[586px] md:!px-0 "
        contentClass="!p-0 max-h-[486px] !max-w-[586px] overflow-y-scroll h-fit"
        RightActionButton={() => (
          <>
            <Button
              id="save-btn"
              handleOnClick={() => handleSave()}
              loading={isLoading}
              disabled={!firstName || !lastName}
              label={"Create"}
            />
          </>
        )}
      >
        <div className="mx-auto">{renderPopupContent()}</div>
      </PopupModal>
    );
  }
  return <>{renderContent(false)}</>;
}
AddNewMember.defaultProps = {
  allowEdit: false,
  showAddEmployee: false,
};
export default AddNewMember;

const MemberInvite = ({
  from_for_mixpanel,
  handleClose,
}: {
  from_for_mixpanel: FROM_FOR_MIXPANEL;
  handleClose: () => void;
}) => {
  const mxEvent = useGibooMixpanel(from_for_mixpanel);
  const [onboardingData] = useOnboardingData();
  const { selected } = useOrgs();
  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<number>(3);
  const location = useLocation();
  const {
    data: members,
    revalidate,
    setMemberRole,
    hasEditPermission,
  } = useOrgMembers(selected, 1000, false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.invitation[""].started);
  }, []);
  const validate = () => {
    if (!email || !isValidEmail(email)) {
      setError("Wrong email");
      setIsDirty(true);
      mxEvent(MIXPANEL_EVENTS_V2.invitation[""].failed, {
        invitationType: "organization",
        email,
        error: "wrong email",
      });
      return false;
    } else if (members.find((m) => m.accepted && m?.email === email)) {
      setError(`This user is already a member of ${onboardingData.npo_name}`);
      mxEvent(MIXPANEL_EVENTS_V2.invitation[""].failed, {
        invitationType: "organization",
        email,
        error: "tried to invite an existing member",
      });
      setIsDirty(true);
      return false;
    } else {
      setError("");
    }
    return true;
  };
  useEffect(() => {
    setError(email && !isValidEmail(email) ? "Wrong email" : "");
  }, [email]);
  const handleSend = () => {
    const isValid = validate();
    if (!selected) return;
    if (isValid) {
      setLoading(true);
      sendOrgInvitation(selected, email, role)
        .then(() => {
          mxEvent(MIXPANEL_EVENTS_V2.invitation[""].successful, {
            invitationType: "organization",
            email,
          });
          GibooToast({
            type: "success",
            message: `Email successfully sent.`,
          });
          revalidate();
          setEmail("");
          handleClose();
        })
        .catch((e) => {
          mxEvent(MIXPANEL_EVENTS_V2.invitation[""].failed, {
            invitationType: "organization",
            email,
            error: "api error",
          });
          GibooToast({
            type: "failure",
            message: e.response?.data?.detail || `Failed to send email.`,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <>
      <div className="flex w-full flex-col gap-4 pt-10">
        <div className="flex w-full flex-col items-start gap-3">
          <p className="text-sm text-gray-700">
            Let&apos;s invite your organization members to the organization and <br /> assign access
            rights. Once invited, they&apos;ll receive email notifications.
          </p>
          <div className="relative flex w-full gap-3">
            <div className="inline-flex w-full grow items-center">
              <LeftInnerIconInput
                className="w-full"
                type="email"
                placeholder="Enter email"
                id="input-email"
                value={email}
                handleOnChange={(e) => setEmail(e.target.value)}
              />
              <div className="absolute right-4 flex items-center gap-2">
                <RolePicker
                  wrapperClassName="!h-[29px]"
                  disable={["Owner", "Guest", "Delete", "Viewer"]}
                  currentRole={role}
                  allowEdit={true}
                  updateRole={(role: number) => {
                    setRole(role);
                  }}
                />
                <Button
                  id="btn-send"
                  className="!h-[29px]"
                  disabled={!email ? true : false}
                  handleOnClick={handleSend}
                  label="Invite"
                  loading={loading}
                />
              </div>
            </div>
          </div>
          {isDirty && error && <h5 className="text-purple-500">{error}</h5>}
        </div>
      </div>
    </>
  );
};
