import classNames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import { Divider, Steps } from "rsuite";
import Button from "../../../../components/tailwind/Button";
import Spacer from "../../../../components/Spacer";
import IMG_EXAMPLE from "../../../../assets/financial/operating-expense-placeholder.svg";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import useOrg from "../../../../hooks/useOrg";
import useNPOIRSProfile from "../../../../hooks/useNPOIRSProfile";
import IncreaseTag from "../component/IncreaseTag";
import LargestProportionTag from "../component/LargestProportionTag";
import DecreaseTag from "../component/DecreaseTag";
import SmallestProportionTag from "../component/SmallestProportionTag";
import NpoFinancialOperatingExpenses, {
  OperatingExpenseChart,
} from "../../dashboard/components/NpoFinancialOperatingExpenses";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import CloseButton from "../../../../components/CloseButton";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";

export default function OperatingExpenses({
  onBack,
  onNext,
  npo_id,
  org_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  org_id?: string;
  isBack?: boolean;
}) {
  const [currentStep, setCurrentStep] = useState<number>(isBack ? 1 : 0);
  useEffect(() => {
    if (isBack) {
      setTimeout(() => {
        scrollToElement("businessmodel-step-2");
      }, 300);
    }
  }, [isBack]);
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value >= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };
  const scrollToElement = (id: string) => {
    const element = document?.getElementById(id);
    const container = document.getElementById("financial-scroll-container");
    if (element && container) {
      const elementTop = element.getBoundingClientRect().top + container.scrollTop;
      const adjustedOffset = elementTop - 120;
      container.scrollTo({ top: adjustedOffset, behavior: "smooth" });
    }
  };
  const renderStep1 = () => {
    return (
      <OperatingExpensesInfo>
        <div
          className={classNames(
            "flex h-[38px] items-center justify-start gap-2",
            currentStep === 0 ? "flex" : "hidden",
          )}
        >
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              if (currentStep < 1) {
                setCurrentStep(1);
                setTimeout(() => {
                  scrollToElement("businessmodel-step-2");
                }, 300);
              }
            }}
            label="See the assessment result"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
        <Spacer height="100px" />
      </OperatingExpensesInfo>
    );
  };
  return (
    <div className="flex max-w-[760px] flex-col">
      <Steps current={currentStep} vertical>
        <Steps.Item
          title={false}
          className="!-ml-[40px] !mt-0"
          icon={currentStep > 0 ? renderDot(1) : <></>}
          description={renderStep1()}
        />
        {currentStep > 0 && (
          <Steps.Item
            icon={renderDot(2)}
            className="!-ml-[40px]"
            description={
              <Step2
                onBack={() => {
                  setCurrentStep(0);
                }}
                npo_id={npo_id}
                org_id={org_id}
                onNext={() => {
                  onNext?.();
                }}
              />
            }
          />
        )}
      </Steps>
    </div>
  );
}
const Step2 = ({
  onBack,
  onNext,
  npo_id,
  org_id,
  recomendation = true,
}: {
  onBack: () => void;
  onNext: () => void;
  npo_id?: string;
  org_id?: string;
  recomendation?: boolean;
}) => {
  const { data, update } = useOrgProfile(org_id);
  const prev = data?.skip_loading || [];
  const mxEvent = useGibooMixpanel(undefined, true);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.financial.expense.successful);
  }, []);
  return (
    <div className="mt-3 flex min-h-[600px] flex-col gap-4" id="businessmodel-step-2">
      <h6 className="text-base font-semibold text-gray-900">
        Your organization&apos;s assessment result
      </h6>
      <NpoFinancialOperatingExpenses
        npo_id={npo_id}
        org_id={org_id}
        recomendation
        showChartLoader={!data.skip_loading?.includes("financial_operating_expenses")}
      >
        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              data &&
                update({
                  skip_loading: [
                    ...prev.filter((p) => p !== "financial_operating_expenses"),
                    "financial_operating_expenses",
                  ],
                });
              onNext?.();
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </NpoFinancialOperatingExpenses>
      <Spacer height="150px" />
    </div>
  );
};

export function OperatingExpensesInfo({
  children,
  handleClose,
}: {
  children?: ReactNode;
  handleClose?: () => void;
}) {
  return (
    <div className={classNames("flex min-h-[600px] flex-col gap-4", !handleClose && "mt-3")}>
      <div className="inline-flex w-full items-center justify-between border-b border-gray-300">
        <h6 className="text-base font-semibold text-gray-900 underline underline-offset-[6px]">
          Operating expenses
        </h6>
        {handleClose && <CloseButton onClick={() => handleClose?.()} />}
      </div>
      <div className="flex min-h-[500px] flex-col rounded border border-gray-300 bg-white p-5">
        {/* section 1 */}
        <div className="flex flex-col gap-10">
          <div>
            <h5 className="font-poppins text-base font-semibold text-black">
              What is operating expenses?
            </h5>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800">
              Operating expenses encompass the essential costs incurred in the daily functioning of
              a nonprofit organization. This category comprises three main components: Program
              expenses, Operating Expenses, and Fundraising Expenses,
              <br />
              <br />
              Balancing these expenses is crucial for financial stability, ensuring that resources
              are efficiently allocated to sustain day-to-day operations while supporting the
              organization&apos;s overarching mission. Effective management of operating expenses
              involves regularly identifying non-essential items for potential cuts, optimizing
              financial resources for long-term sustainability and mission fulfillment.
            </h6>
          </div>
          <div className="flex min-h-[330px] justify-center">
            <img src={IMG_EXAMPLE} className="h-fit w-fit" alt="example" />
          </div>
        </div>
        <Spacer height="16px" />
        {/* section 2 */}
        <div className="flex flex-col">
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Program expenses</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Program expenses encompass the costs directly associated with the nonprofit&apos;s
              core mission and the delivery of services or programs to its beneficiaries. This
              includes expenses such as staff salaries directly involved in program delivery,
              materials, resources, and any costs directly tied to the implementation of the
              organization&apos;s programs and initiatives.
              <br />
              <br />
              Program expenses are critical for fulfilling the nonprofit&apos;s mission and
              achieving its goals. These costs directly contribute to the impact and effectiveness
              of the organization&apos;s programs, making them a central component of its overall
              financial strategy.
            </h6>
          </div>
          <Divider className="my-5" />
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Operating expenses</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Operating expenses cover the general costs of running the nonprofit organization. This
              category includes expenses related to administration, office rent, utilities,
              insurance, and other day-to-day operational necessities that are not directly tied to
              program delivery or fundraising. This expenses ensure the smooth functioning of the
              organization as a whole.
              <br />
              <br />
              Operating Expenses are essential for maintaining the infrastructure and administrative
              functions necessary to support the overall operations of the nonprofit. Efficient
              management of these costs contributes to the organization&apos;s stability and ability
              to carry out its programs effectively.{" "}
            </h6>
          </div>
          <Divider className="my-5" />
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">
              Fundraising expenses
            </p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Fundraising expenses pertain to the costs associated with activities aimed at securing
              financial support for the nonprofit. This includes expenses related to events,
              campaigns, marketing, and any initiatives specifically designed to attract and retain
              donors. This expense plays a crucial role in generating the financial resources needed
              to sustain the organization&apos;s mission.
              <br />
              <br />
              Fundraising expenses are vital for acquiring the necessary funds to support the
              nonprofit&apos;s programs and operational activities. Effectively managing these costs
              ensures that the organization can engage in successful fundraising efforts, expanding
              its reach and impact.{" "}
            </h6>
          </div>
        </div>
      </div>
      <div className="flex min-h-[100px] flex-col rounded border border-gray-300 bg-white p-5">
        <div>
          <h5 className="font-poppins text-base font-semibold text-black">
            Why is operating expesnes important?
          </h5>
          <Spacer height="10px" />
          <h6 className="font-poppins text-sm text-gray-800">
            Balancing operating costs is vital for the financial stability of a nonprofit. It
            ensures that resources are allocated efficiently to support both the essential
            day-to-day operations and the execution of the organization&apos;s mission. Effective
            management of operating expenses contributes to a healthy financial foundation, allowing
            the nonprofit to allocate more resources toward its programs and initiatives.
          </h6>
        </div>
      </div>
      {children && children}
    </div>
  );
}
