import classNames from "classnames";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createRef,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { PlacesType } from "react-tooltip";
import IMG_PULSE from "../assets/images/pulseArrow.svg";
import GibooTooltipWrapper from "./GibooTooltipWrapper";
import Button from "./tailwind/Button";
import { useSetRecoilState } from "recoil";
import { openSigninSignup } from "../app/recoilStore";

interface IContentHideWrapper {
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  id: string;
  wrapperClass?: string;
  children: ReactNode;
  messageTitle?: string | ReactNode;
  messageDescription?: string | ReactNode;
  messageAction?: () => void;
  content?: ReactNode;
  actionLabel?: string;
}

export default function ContentHideWrapper({
  id,
  children,
  open,
  setOpen,
  content,
  wrapperClass,
  messageAction,
  messageDescription,
  messageTitle,
  actionLabel = "Try for free",
}: IContentHideWrapper) {
  const [show, setShow] = useState(open);
  useEffect(() => {
    setShow(open);
  }, [open]);
  const wrapperRef = createRef<HTMLDivElement>();
  const hideContentClass =
    "relative !pointer-events-none select-none blur-[3.5px] !min-h-[200px] content-hide-wrapper-bg";
  const setSignUpSignin = useSetRecoilState(openSigninSignup);

  const renderMessage = () => {
    return (
      <div
        className="z-2 npo-org-message-bg absolute inset-0 left-1/2 top-1/2 h-fit w-[695px] -translate-x-1/2 -translate-y-1/2  transform rounded p-4 shadow-[0_0_15px_1px_rgba(0,0,0,0.1)]"
        onContextMenu={(e) => e.preventDefault()}
      >
        {content ? (
          content
        ) : (
          <div className="flex w-full flex-col gap-3">
            <h6 className="font-poppins text-base font-semibold text-purple-500">{messageTitle}</h6>
            <p className="font-poppins text-sm">{messageDescription}</p>
            <Button
              id="try free"
              label={actionLabel}
              className="!h-[29px] w-fit"
              labelClass="-mt-[1px]"
              handleOnClick={() => {
                messageAction?.();
                !messageAction && setSignUpSignin({ show: true });
              }}
              prependIcon={
                <span className="grid place-items-center">
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25 5.75C5.25 3.2645 6.70275 2 8.904 2C10.293 2 11.3843 2.504 12 3.5M9 9.5V11.375"
                      stroke="white"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                    />
                    <path
                      d="M9 7.19828L8.601 7.59428C8.65328 7.64689 8.71544 7.68864 8.78392 7.71713C8.8524 7.74562 8.92583 7.76029 9 7.76029C9.07417 7.76029 9.1476 7.74562 9.21608 7.71713C9.28456 7.68864 9.34672 7.64689 9.399 7.59428L9 7.19828ZM7.29075 15.5143C6.26325 14.7305 5.15175 13.973 4.269 13.0108C3.4065 12.0703 2.8125 10.982 2.8125 9.57428H1.6875C1.6875 11.345 2.45025 12.692 3.44025 13.7713C4.41075 14.8295 5.649 15.6763 6.60825 16.409L7.29075 15.5143ZM2.8125 9.57503C2.8125 8.20703 3.61125 7.05203 4.71375 6.56378C5.7855 6.08978 7.22775 6.21428 8.601 7.59503L9.399 6.80228C7.734 5.12828 5.80275 4.85228 4.2585 5.53478C2.745 6.20453 1.6875 7.76528 1.6875 9.57503H2.8125ZM6.6075 16.4098C6.95325 16.673 7.3275 16.9573 7.707 17.1718C8.08725 17.387 8.5245 17.5633 9 17.5633V16.4383C8.8005 16.4383 8.56275 16.3633 8.26125 16.1923C7.95975 16.022 7.64625 15.7865 7.29075 15.515L6.6075 16.4098ZM11.3925 16.409C12.351 15.6763 13.5892 14.8303 14.5597 13.772C15.5498 12.6928 16.3125 11.3458 16.3125 9.57503H15.1875C15.1875 10.9828 14.5935 12.071 13.731 13.0115C12.8483 13.9738 11.736 14.7313 10.7093 15.515L11.3925 16.409ZM16.3125 9.57503C16.3125 7.76528 15.255 6.20453 13.7415 5.53553C12.1973 4.85153 10.2653 5.12828 8.601 6.80228L9.399 7.59503C10.7715 6.21428 12.2145 6.08978 13.2863 6.56378C14.3888 7.05128 15.1875 8.20628 15.1875 9.57503H16.3125ZM10.7093 15.515C10.353 15.7865 10.0402 16.022 9.73875 16.1923C9.43725 16.3633 9.19875 16.4383 9 16.4383V17.5633C9.4755 17.5633 9.91275 17.387 10.293 17.1718C10.6725 16.9565 11.0468 16.6723 11.3925 16.409L10.7093 15.515Z"
                      fill="white"
                    />
                  </svg>
                </span>
              }
            />
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      id={id}
      ref={wrapperRef}
      className={classNames("relative", wrapperClass)}
      onContextMenu={(e) => e.preventDefault()}
    >
      <div className={classNames({ [hideContentClass]: show })}>{children}</div>
      {show && renderMessage()}
    </div>
  );
}
