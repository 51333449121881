import { ReactElement } from "react";
import { GeographicFocus, PhilanthropicFocusArea, Philanthropy } from "../types/philanthropy";
import { ILocation, reprLocation } from "../types/location";
import { selectTaxonomyUppercaseMapGlobalized } from "../app/store";

const compareAmount = (
  a: { amount?: number; matched?: boolean },
  b: { amount?: number; matched?: boolean },
  compareMatched = false,
): number => {
  if (compareMatched) {
    if (a.matched && !b.matched) return -1;
    else if (!a.matched && b.matched) return 1;
  }
  if (!a.amount)
    if (!b.amount) return 0;
    else return 1;
  else if (!b.amount) return -1;
  return a.amount > b.amount ? -1 : a.amount < b.amount ? 1 : 0;
};

const getMatchedPhilanthropy = (philanthropy: Philanthropy | undefined): Philanthropy => ({
  focus_area: philanthropy?.focus_area
    ?.slice()
    .sort(compareAmount)
    ?.filter((item: PhilanthropicFocusArea) => item.matched),
  beneficiary: philanthropy?.beneficiary
    ?.slice()
    .sort(compareAmount)
    ?.filter((item: PhilanthropicFocusArea) => item.matched),
  program: philanthropy?.program
    ?.slice()
    .sort(compareAmount)
    ?.filter((item: PhilanthropicFocusArea) => item.matched),
  service_loc: philanthropy?.service_loc
    ?.slice()
    .sort(compareAmount)
    ?.filter((item: GeographicFocus) => item.matched),
});

const getDescription = (philanthropy: Philanthropy | undefined): ReactElement | undefined => {
  const matchedContext = getMatchedPhilanthropy(philanthropy);
  const focus_area =
    matchedContext.focus_area && matchedContext.focus_area.length > 0
      ? matchedContext.focus_area[0].label
      : "";
  const beneficiary =
    matchedContext.beneficiary && matchedContext.beneficiary.length > 0
      ? matchedContext.beneficiary[0].label
      : "";
  const location =
    matchedContext.service_loc && matchedContext.service_loc.length > 0
      ? reprLocation(matchedContext.service_loc[0])
      : undefined;
  let res = focus_area ? (
    <b className="text-purple-500">{focus_area[0].toUpperCase() + focus_area.slice(1)}</b>
  ) : undefined;
  res = beneficiary ? (
    <>
      {res ? <span className="text-gray-700">{res} for</span> : <></>}{" "}
      <b className="text-purple-500">{beneficiary[0].toUpperCase() + beneficiary.slice(1)}</b>
    </>
  ) : (
    res
  );
  res = location ? (
    <>
      {res ? <span className="text-gray-700">{res} in</span> : <></>}{" "}
      <b className="text-purple-500">{location}</b>
    </>
  ) : (
    res
  );
  return res ? res : undefined;
};
interface PhilantrhopyExtended extends Philanthropy {
  service_locs?: { label: string; matched: boolean }[];
  service_area?: { label: string; matched: boolean }[];
}
interface PhilantrhopyStrings {
  focus_area?: string[];
  beneficiary?: string[];
  program?: string[];
  focus_area_subs?: string[];
  beneficiary_subs?: string[];
  program_subs?: string[];
}
const prettierTaxonomy = (
  philanthropy: PhilantrhopyExtended,
  query: { focus_area: string[]; beneficiary: string[]; program: string[] } = {
    focus_area: [],
    beneficiary: [],
    program: [],
  },
): Philanthropy => {
  const converter = [
    ...(query?.focus_area || []),
    ...(query?.beneficiary || []),
    ...(query?.program || []),
  ].reduce(
    (prev, cur) => ({ ...prev, ...{ [cur]: cur }, ...{ [cur.toLowerCase()]: cur } }),
    {} as { [key: string]: string },
  );
  const map = { ...converter, ...selectTaxonomyUppercaseMapGlobalized() };
  return {
    ...philanthropy,
    focus_area: philanthropy.focus_area?.map((i) => ({
      ...i,
      ...(Object.keys(map).includes(i.label.toLowerCase())
        ? { label: map[i.label.toLowerCase()] }
        : {}),
    })),
    beneficiary: philanthropy.beneficiary?.map((i) => ({
      ...i,
      ...(Object.keys(map).includes(i.label.toLowerCase())
        ? { label: map[i.label.toLowerCase()] }
        : {}),
    })),
    program: philanthropy.program?.map((i) => ({
      ...i,
      ...(Object.keys(map).includes(i.label.toLowerCase())
        ? { label: map[i.label.toLowerCase()] }
        : {}),
    })),
    service_loc: (philanthropy?.service_loc ||
      philanthropy?.service_locs ||
      philanthropy?.service_area ||
      []) as ILocation[],
  };
};
const prettierTaxonomyString = (
  philanthropy: PhilantrhopyStrings,
  query: { focus_area: string[]; beneficiary: string[]; program: string[] } = {
    focus_area: [],
    beneficiary: [],
    program: [],
  },
  convertLocation = false,
): PhilantrhopyStrings => {
  const converter = [
    ...(query?.focus_area || []),
    ...(query?.beneficiary || []),
    ...(query?.program || []),
  ].reduce(
    (prev, cur) => ({ ...prev, ...{ [cur]: cur }, ...{ [cur.toLowerCase()]: cur } }),
    {} as { [key: string]: string },
  );
  const map = { ...converter, ...selectTaxonomyUppercaseMapGlobalized() };
  return {
    ...philanthropy,
    focus_area:
      philanthropy.focus_area?.map((i) => (Object.keys(map).includes(i) ? map[i] : i)) || [],
    beneficiary:
      philanthropy.beneficiary?.map((i) => (Object.keys(map).includes(i) ? map[i] : i)) || [],
    program: philanthropy.program?.map((i) => (Object.keys(map).includes(i) ? map[i] : i)) || [],
    focus_area_subs:
      philanthropy.focus_area_subs?.map((i) => (Object.keys(map).includes(i) ? map[i] : i)) || [],
    beneficiary_subs:
      philanthropy.beneficiary_subs?.map((i) => (Object.keys(map).includes(i) ? map[i] : i)) || [],
    program_subs:
      philanthropy.program_subs?.map((i) => (Object.keys(map).includes(i) ? map[i] : i)) || [],
  };
};

export {
  compareAmount,
  getMatchedPhilanthropy,
  getDescription,
  prettierTaxonomy,
  prettierTaxonomyString,
};
