import { ReactNode, SetStateAction, useEffect, useState } from "react";
import Button from "./tailwind/Button";
import PopupModal from "./tailwind/PopupModal";
import { useNavigate } from "react-router-dom";
import TextInput from "./tailwind/TextInput";
import ICON_CLIP from "../assets/project-mgt/clipboard.svg";
import PrimaryButton from "./PrimaryButton";
import useProject from "../hooks/project/useProject";
import UserProfile from "./UserProfile";
import { Whisper, Popover } from "rsuite";
import { User, roleToID, roleToName } from "../app/userSlice";
import classNames from "classnames";
import { scrollBarClass } from "../utils/classes";
import { IOption } from "./tailwind/AsyncCreatableSelector";
import useOrgMembers from "../hooks/project/useOrgMembers";
import SearchableDropdown from "./dropdown/SearchableDropdown";
import Spinner from "./Spinner";
import useUser from "../hooks/useUser";
import useOnboardingData from "../hooks/useOnboarding";
import { isValidEmail } from "../utils/formatHelper";
import { sendOrgInvitation } from "../services/org.services";
import GibooToast from "./GibooToast";
import useOrgs from "../hooks/useOrgs";
import LeftInnerIconInput from "./LeftInnerIconInput";
import RolePicker from "./RolePicker";
import OrgMemberSuggestion from "./OrgMemberSuggestion";
import { zIndexProjectInvitation } from "../types/zIndex";
import { FROM_FOR_MIXPANEL, MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
interface IProjectInvite {
  from_for_mixpanel: FROM_FOR_MIXPANEL;
  projectId: string;
  loading?: boolean;
  showInvite?: boolean;
  handleClose?: () => void;
}

export default function ProjectInvite({
  from_for_mixpanel,
  projectId,
  showInvite = false,
  handleClose,
}: IProjectInvite) {
  const [tried, setTried] = useState<boolean>(false);
  const mxEvent = useGibooMixpanel(from_for_mixpanel, true);
  const {
    data: projectData,
    hasEditPermission,
    revalidate: revalidateProject,
    isLoading,
    setMemberRole,
  } = useProject(projectId);
  const { orgs, selected } = useOrgs();
  const [projectLink, setProjectLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  useEffect(() => {
    setProjectLink(`${window.location.origin}/project/${projectId}` || "");
  }, []);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.invitation[""].started, {
      invitationType: "project",
      projectId,
    });
  }, [projectId]);

  const [onboardingData] = useOnboardingData();
  const [user] = useUser();
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<number>(3);
  const {
    data: members,
    revalidate: revalidateOrgMembers,
    hasEditPermission: hasEditPermissionOrgMembers,
  } = useOrgMembers(selected, 1000, false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const validate = () => {
    if (!email || !isValidEmail(email)) {
      mxEvent(MIXPANEL_EVENTS_V2.invitation[""].failed, {
        invitationType: "project",
        projectId,
        email,
        error: "wrong email",
      });
      setError("Wrong email");
      setIsDirty(true);
      return false;
    } else {
      setError("");
    }
    return true;
  };
  useEffect(() => {
    setError(email && !isValidEmail(email) ? "Wrong email" : "");
  }, [email]);
  const handleSend = () => {
    const isValid = validate();
    if (!selected) return;
    if (isValid) {
      setLoading(true);
      setTried(true);
      sendOrgInvitation(selected, email, role, projectId)
        .then((res) => {
          mxEvent(MIXPANEL_EVENTS_V2.invitation[""].successful, {
            invitationType: "project",
            projectId,
            email,
          });
          GibooToast({
            type: "success",
            message: `Email successfully sent.`,
          });
          setEmail("");
          // if (res)
          //   return setMemberRole(res, role)
          //     .then(() => {
          //       GibooToast({
          //         type: "success",
          //         message: `Email successfully sent.`,
          //       });
          //       setEmail("");
          //     })
          //     .catch((e) => {
          //       GibooToast({
          //         type: "failure",
          //         message: `Failed to set member role.`,
          //       });
          //     });
        })
        .catch((e) => {
          mxEvent(MIXPANEL_EVENTS_V2.invitation[""].failed, {
            invitationType: "project",
            projectId,
            email,
            error: "api error",
          });
          GibooToast({
            type: "failure",
            message: e.response?.data?.detail || `Failed to send email.`,
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const renderContent = () => {
    return (
      <div className="flex flex-col p-7">
        <div className="flex w-full flex-col gap-5 px-4">
          <div className="w-full ">
            <p className="mb-3 font-poppins text-base text-gray-700">Project link</p>
            <TextInput
              id="project-link"
              className={classNames(navigator?.clipboard ? "!mr-[60px] !h-[48px]" : "!h-[48px]")}
              value={projectLink}
              placeholder="Project link"
              iconRight={
                navigator?.clipboard && (
                  <div
                    className="inline-flex cursor-pointer items-center gap-2 rounded border border-gray-300 bg-white px-2 py-1 font-poppins hover:shadow"
                    onClick={() => {
                      navigator?.clipboard?.writeText(projectLink);
                      setIsCopied(true);
                      setTimeout(() => {
                        setIsCopied(false);
                      }, 2000);
                    }}
                  >
                    {!isCopied && <img src={ICON_CLIP} className="h-5 w-5" />}
                    <h5 className="text-sm text-gray-700">{isCopied ? "Copied" : "Copy"}</h5>
                  </div>
                )
              }
              handleOnChange={(e) => {
                setProjectLink(e.target.value);
              }}
            />
          </div>
          {onboardingData.role >= 3 && hasEditPermission && (
            <div className="my-6 w-full">
              <p className="mb-3 font-poppins text-base text-gray-700">
                Invite a new member by email
              </p>
              <div className="relative flex w-full gap-3">
                <div className="h-[58px] w-full grow">
                  <TextInput
                    className="h-[48px] max-w-[360px]"
                    placeholder="Enter email"
                    id="input-email"
                    value={email}
                    handleOnFocus={() => setShowSuggestion(true)}
                    handleOnChange={(e) => {
                      setEmail(e.target.value);
                      setShowSuggestion(true);
                    }}
                    iconRight={
                      <div className="flex items-center gap-2">
                        <RolePicker
                          disable={["Owner", "Admin", "Delete", "Guest", "Viewer"]}
                          currentRole={role}
                          allowEdit={true}
                          updateRole={(role: number) => {
                            setRole(role);
                          }}
                        />
                        <Button
                          id="btn-send"
                          className="!h-[25px] text-xs"
                          disabled={!email ? true : false}
                          handleOnClick={handleSend}
                          label="Invite"
                          loading={loading}
                        />
                      </div>
                    }
                  />
                  <OrgMemberSuggestion
                    show={showSuggestion}
                    setShow={setShowSuggestion}
                    query={email}
                    exclude={projectData.members.map((u) => u.user_id)}
                    onSelect={(user) => {
                      setEmail(user.email);
                      setShowSuggestion(false);
                    }}
                    className="max-h-[250px]"
                  />
                </div>
              </div>
              {isDirty && error && <h5 className="text-purple-500">{error}</h5>}
            </div>
          )}
          <div className="w-full">
            <p className="mb-3 font-poppins text-base text-gray-700">Members</p>
            <div
              className={classNames(
                "flex max-h-[400px] w-full flex-col items-start gap-3",
                scrollBarClass,
              )}
            >
              {members
                .filter((m) => projectData.members.map((u) => u.user_id).includes(m._id))
                .map((m) => {
                  const projectRole = projectData.members.find((a) => a.user_id === m._id)?.role;
                  return (
                    <div
                      key={m._id}
                      className="flex w-full items-center justify-between rounded border border-gray-300 p-3"
                    >
                      <UserProfile
                        showName={m.accepted}
                        size={24}
                        userId={m._id}
                        showEmail
                        pending={!m.accepted}
                        org_id={selected}
                        role={m.role}
                        profileImageClass={"h-[30px] w-[30px] p-2"}
                      />
                      {projectRole === 5 || !hasEditPermission ? (
                        <h6 className="pr-5 text-gray-900">{roleToName[5]}</h6>
                      ) : (
                        <RolePicker
                          disable={["Owner", "Admin", "Guest", "Viewer"]}
                          currentRole={projectRole || 0}
                          allowEdit={true}
                          updateRole={(role: number) => {
                            console.log("setrole", role);
                            setMemberRole(m._id, role);
                            // if (role === 0) {
                            //   revalidateProject();
                            // }
                          }}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <PopupModal
        isOpen={showInvite}
        zIndex={zIndexProjectInvitation}
        wrapperClass="!min-w-[588px] !max-w-[588px] md:!px-0 !pb-0 "
        contentClass="my-0 py-0 min-h-[500px] max-h-[780px] overflow-y-scroll"
        handleClose={() => {
          handleClose?.();
          setEmail("");
        }}
        title={"Invite"}
        LeftActionButton={() => <div />}
        RightActionButton={() => {
          return (
            <Button
              id="btn-loi-close"
              color="purple"
              label="Done"
              handleOnClick={() => {
                handleClose?.();
              }}
            />
          );
        }}
      >
        {renderContent()}
      </PopupModal>
    </>
  );
}
