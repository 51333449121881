import classNames from "classnames";
import * as React from "react";
import "./css/button.scss";
import Spinner from "./Spinner";

interface SecondaryButtonProps {
  id: string;
  handleOnClick: (e: React.FormEvent) => void;
  className?: string;
  color?: "purple" | "red" | "yellow" | "green" | "blue" | "gray";
  tonal?: boolean;
  active?: boolean;
  leftIconClass?: string;
  rightIconClass?: string;
  label?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  size?: "md" | "lg";
  loading?: boolean;
  spinnerColor?: "purple" | "red" | "yellow" | "green" | "blue" | "gray" | "white";
}

function SecondaryButton({ spinnerColor = "purple", size = "md", ...props }: SecondaryButtonProps) {
  const className = classNames(
    "giboo-secondary-btn flex prevent-select",
    props.className,
    props.color ? `giboo-secondary-btn-${props.color}` : "",
    { tonal: props.tonal, active: props.active },
  );
  return (
    <button
      id={props.id}
      disabled={props.disabled}
      onClick={(e) => {
        e.currentTarget.blur();
        props.handleOnClick(e);
      }}
      className={classNames(className, size === "lg" ? "!h-12" : "")}
      style={props.style}
    >
      {props.leftIconClass && <i className={props.leftIconClass}></i>}
      {props.loading && <Spinner {...(spinnerColor && { color: spinnerColor })} size="sm" />}
      {props.label && <span className={"giboo-secondary-btn-label"}>{props.label}</span>}
      {props.rightIconClass && <i className={props.rightIconClass}></i>}
    </button>
  );
}

export default SecondaryButton;
