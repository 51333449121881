import axios from "axios";
import { IProjectUpdateRequest } from "../types/project";
import config from "../api";

export const parseProjectInformation = (key: string, type: string) => {
  return new Promise<IProjectUpdateRequest>((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_LAMBDA_URL}/project/parse`,
        { key, type },
        {
          headers: { "Content-Type": "application/json" },
        },
      )
      .then(async ({ data }) => {
        const checkStatus = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_LAMBDA_URL}/project/parse/status?executionArn=${data.executionArn}`,
            );
            const { status, res } = response.data;

            if (status === "RUNNING") {
              setTimeout(checkStatus, 1000);
            } else if (status === "SUCCEEDED") {
              resolve(res);
            } else {
              reject(`Unexpected status: ${status}`);
            }
          } catch (error) {
            reject(error);
          }
        };
        setTimeout(checkStatus, 7000);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
