import useSWR from "swr";
import useUser from "../useUser";
import useOnboardingData from "../useOnboarding";
import { ITaxonomyOntology, ITaxonomyTaggingOperator, TaxonomyConcept } from "../../types/taxonomy";
import {
  getTaxonomyTaggingForSearch,
  getTaxonomyTaggingOperatorForSearch,
} from "../../services/search.services";
import { ILocation } from "../../types/location";
import { useEffect, useState } from "react";
import { swrOptionDedupling5mins } from "../../types/swr";

interface TaggingResultState {
  data: ITaxonomyTaggingOperator | undefined;
  currentTextQuery: string;
  isLoading: boolean;
  isValidating: boolean;
  error: object;
}
const getTaggingKey = (text_query: string, mission: string): string =>
  `[${text_query}]\n${mission}`;
export default function useTaggingOperatorResult(
  text_query?: string,
  mission?: string,
  currentTextQuery?: string,
  taxonomy?: ITaxonomyOntology,
): TaggingResultState {
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const url =
    taxonomy && currentTextQuery === getTaggingKey(text_query || "", mission || "")
      ? process.env.REACT_APP_TAXONOMY_URL +
        `/taxonomy/v2/tag3?text_query=${text_query || ""}&mission=${mission || ""}&foucus_area=${
          taxonomy?.focus_area ? Object.keys(taxonomy.focus_area).join("&focus_area=") : ""
        }&beneficiary=${
          taxonomy?.beneficiary ? Object.keys(taxonomy.beneficiary).join("&beneficiary=") : ""
        }&program=${taxonomy?.program ? Object.keys(taxonomy.program).join("&program=") : ""}`
      : null;
  const fetch = async (_url: string) => {
    if (
      !_url ||
      text_query === undefined ||
      text_query === null ||
      !taxonomy ||
      currentTextQuery !== getTaggingKey(text_query || "", mission || "")
    )
      return new Promise<{ data: ITaxonomyTaggingOperator; query: string }>((resolve, reject) =>
        reject(),
      );
    return getTaxonomyTaggingOperatorForSearch({ text_query, mission, taxonomy }).then((res) => ({
      data: res,
      query: getTaggingKey(text_query || "", mission || ""),
    }));
  };
  const { data, isLoading, error, isValidating } = useSWR<{
    data: ITaxonomyTaggingOperator;
    query: string;
  }>(url ? url : null, fetch, swrOptionDedupling5mins);
  return {
    data: data?.data,
    currentTextQuery: data?.query || "",
    isLoading,
    isValidating,
    error,
  };
}
export { getTaggingKey };
