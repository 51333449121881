import useSWR from "swr";
import useUser from "../useUser";
import useOnboardingData from "../useOnboarding";
import { ITaxonomyOntology, TaxonomyConcept } from "../../types/taxonomy";
import { getTaxonomyTaggingForSearch } from "../../services/search.services";
import { ILocation } from "../../types/location";
import { useEffect, useState } from "react";
import { swrOptionDedupling5mins } from "../../types/swr";

interface TaggingResultState {
  data: ITaxonomyOntology | undefined;
  currentTextQuery: string;
  isLoading: boolean;
  isValidating: boolean;
  error: object;
}
const getTaggingKey = (text_query: string, mission: string): string =>
  `[${text_query}]\n${mission}`;
const sortTaggingResult = (
  byTextQuery: ITaxonomyOntology,
  // byMission?: { focus_area?: string[]; beneficiary?: string[]; program?: string[] },
): {
  focus_area: string[];
  beneficiary: string[];
  program: string[];
  service_loc: ILocation[];
} => {
  const res = byTextQuery;
  return {
    focus_area: Object.keys(res.focus_area).sort((a, b) =>
      res.focus_area[b] === res.focus_area[a]
        ? a.localeCompare(b)
        : res.focus_area[b] - res.focus_area[a],
    ),
    beneficiary: Object.keys(res.beneficiary).sort((a, b) =>
      res.beneficiary[b] === res.beneficiary[a]
        ? a.localeCompare(b)
        : res.beneficiary[b] - res.beneficiary[a],
    ),
    program: Object.keys(res.program).sort((a, b) =>
      res.program[b] === res.program[a] ? a.localeCompare(b) : res.program[b] - res.program[a],
    ),
    service_loc: res.service_loc,
  };
};
const sortTaggingResultCombined = (
  byTextQuery: ITaxonomyOntology,
  // byMission?: { focus_area?: string[]; beneficiary?: string[]; program?: string[] },
): TaxonomyConcept[] => {
  const res = byTextQuery;
  return [
    ...Object.keys(res.focus_area).map((t) => ({
      label: t,
      score: res.focus_area[t],
      type: "f",
      object_id: "",
    })),
    ...Object.keys(res.beneficiary).map((t) => ({
      label: t,
      score: res.beneficiary[t],
      type: "b",
      object_id: "",
    })),
    ...Object.keys(res.program).map((t) => ({
      label: t,
      score: res.program[t],
      type: "p",
      object_id: "",
    })),
  ].sort((a, b) => (a.score === b.score ? a.label.localeCompare(b.label) : b.score - a.score));
};
export default function useTaggingResult(
  text_query?: string,
  mission?: string,
): TaggingResultState {
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const url =
    process.env.REACT_APP_TAXONOMY_URL +
    `/taxonomy/v2/tag?text_query=${text_query || ""}&mission=${mission || ""}`;
  const fetch = async (_url: string) => {
    if (!_url || text_query === undefined || text_query === null)
      return new Promise<{ data: ITaxonomyOntology; query: string }>((resolve, reject) => reject());
    return getTaxonomyTaggingForSearch({ text_query, mission }).then((res) => ({
      data: res,
      query: getTaggingKey(text_query || "", mission || ""),
    }));
  };
  const { data, isLoading, error, isValidating } = useSWR<{
    data: ITaxonomyOntology;
    query: string;
  }>(url ? url : null, fetch, swrOptionDedupling5mins);
  return {
    data: data?.data,
    currentTextQuery: data?.query || "",
    isLoading,
    isValidating,
    error,
  };
}
export { getTaggingKey, sortTaggingResult, sortTaggingResultCombined };
