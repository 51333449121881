import React, { useEffect, useState } from "react";
import { Tooltip, PlacesType, EventsType } from "react-tooltip";
import "./css/tooltip.scss";
import infoIcon from "../assets/images/info-circle.svg";
import infoIconGrey from "../assets/images/info-circle-light.svg";
import classNames from "classnames";
interface TooltipButtonProps {
  id: string;
  title?: string;
  content?: string;
  size?: "sm" | "md" | "lg";
  maxWidth?: string;
  place?: PlacesType;
  children?: React.ReactNode;
  color?: "purple" | "gray";
  bgColor?: string;
  iconClass?: string;
  iconColor?: "purple" | "gray";
  wrapperClassName?: string;
  showArrow?: boolean;
  controlled?: boolean;
  showInfoIcon?: boolean;
  show?: boolean;
  clickable?: boolean;
}

function TooltipButton({
  id,
  title = "",
  content,
  size = "md",
  maxWidth = "320px",
  place = "right",
  children,
  color = "purple",
  iconColor = "purple",
  bgColor = "bg-white",
  iconClass,
  wrapperClassName,
  showArrow = false,
  controlled = false,
  showInfoIcon = true,
  clickable = false,
  show,
}: TooltipButtonProps) {
  const [open, setIsOpen] = useState(show || false);
  let titleHtml = "";
  if (title) {
    titleHtml = `<p class="text-gray-900 semibold mb-2 bg-purple-500">${title}</p>`;
  }
  const contentHtml = `<h5 class="text-gray-900">${content}</h5>`;
  const html = titleHtml + contentHtml;
  return (
    <>
      {showInfoIcon && (
        <span className={`flex cursor-pointer text-${color}-500 items-center`} id={id}>
          <img
            src={iconColor === "purple" ? infoIcon : infoIconGrey}
            className={classNames("h-[25px] w-[25px]", iconClass)}
          />
        </span>
      )}
      {children ? (
        <Tooltip
          {...(controlled && {
            isOpen: open,
            setIsOpen: setIsOpen,
          })}
          noArrow={!showArrow}
          clickable={clickable}
          wrapper="div"
          className={classNames(
            " !font-poppins text-sm text-white shadow-md",
            wrapperClassName,
            bgColor,
          )}
          anchorId={id}
          place={place}
          style={{
            maxWidth: maxWidth,
            zIndex: 1000,
            opacity: 1,
          }}
        >
          {children}
        </Tooltip>
      ) : (
        <Tooltip
          wrapper="div"
          className={classNames(" !font-poppins text-sm text-white shadow-md", bgColor)}
          classNameArrow={classNames("shadow-md", bgColor)}
          anchorId={id}
          html={html}
          place={place}
          style={{
            maxWidth: maxWidth,
            zIndex: 1000,
            opacity: 1,
          }}
        />
      )}
    </>
  );
}

export default TooltipButton;
