import { Fragment, useEffect, useState } from "react";
import { Drawer, ButtonToolbar, Button, IconButton, Placeholder } from "rsuite";
import closeTag from "../assets/images/closeTag.svg";
import classNames from "classnames";
type SlideoverProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  direction?: "left" | "right" | "top" | "bottom";
  size?: "full" | "lg" | "md" | "sm" | "xs";
  title?: string;
  subTitle?: string;
  classname?: string;
  headerClass?: string;
};

function Slideover({
  isOpen = false,
  direction = "right",
  size,
  title = "",
  onClose,
  children,
  subTitle = "Provide an update about the progress and mention someone, or upload files to share with your team members.",
  classname,
  headerClass,
}: SlideoverProps) {
  const renderHeader = () => {
    return (
      <div className={classNames("-mt-1 mb-4 flex flex-col gap-4", headerClass)}>
        {title && <h4 className="font-poppins text-xl font-semibold">{title}</h4>}
        {subTitle && (
          <h5 className="max-w-[441px] font-poppins text-sm text-gray-700">{subTitle}</h5>
        )}
      </div>
    );
  };
  return (
    <Drawer
      onBackdropClick={() => onClose()}
      placement={direction}
      open={isOpen}
      onClose={() => onClose()}
      size={size}
      dialogTransitionTimeout={500}
      dialogClassName="z-[1200]"
      backdropClassName="bg-black/80"
    >
      <Drawer.Body className={classname}>
        {renderHeader()}
        {children}
      </Drawer.Body>
    </Drawer>
  );
}

export default Slideover;
