import React, { ReactNode, useEffect, useState } from "react";
import Header from "../../components/header/Header";
import classNames from "classnames";
import { toDateString } from "../../utils/formatHelper";
import usePageTitle from "../../hooks/usePagetitle";

interface navItem {
  id: number;
  title: string;
  subMenu?: navItem[];
  type: CONTENT_TYPE;
}

enum CONTENT_TYPE {
  INTERPRETAION_AND_DEFINITIONS,
  ACKNOWLEDGMENT,
  USER_ACCOUNTS,
  INTELLECTUAL_PROPERTY,
  YOUR_FEEDBACK_TO_US,
  LINKS_TO_OTHER_WEBSITE,
  TERMINATION,
  LIMITATION_OF_LIABILITY,
  AS_IS_AND_AS_AVAILABLE,
  GOVERNING_LAW,
  conta,
  EU_USERS,
  UNITED_STATES_LEGAL_COMPLIANCE,
  SERVER_ABILITY_AND_WAIVER,
  TRANSLATION_INTERPRETATION,
  CHANGES_TO_TERMS_AND_CONDITIONS,
  CONTACT_US,
}
const NAV_DATA = [
  {
    title: "Interpretation and Definitions",
    id: 1,
    type: CONTENT_TYPE.INTERPRETAION_AND_DEFINITIONS,
  },
  {
    title: "Acknowledgement",
    id: 2,
    type: CONTENT_TYPE.ACKNOWLEDGMENT,
  },
  {
    title: "user Accounts",
    id: 3,
    type: CONTENT_TYPE.USER_ACCOUNTS,
  },
  {
    title: "Intellectual Property",
    id: 4,
    type: CONTENT_TYPE.INTELLECTUAL_PROPERTY,
  },
  {
    title: "Your Feedback to Us",
    id: 5,
    type: CONTENT_TYPE.YOUR_FEEDBACK_TO_US,
  },
  {
    title: "Links to Other Websites",
    id: 6,
    type: CONTENT_TYPE.LINKS_TO_OTHER_WEBSITE,
  },
  {
    title: "Termination",
    id: 7,
    type: CONTENT_TYPE.TERMINATION,
  },
  {
    title: "Limitaion of Liability",
    id: 8,
    type: CONTENT_TYPE.LIMITATION_OF_LIABILITY,
  },
  {
    title: `"AS IS" and "AS AVAILABLE" Disclaimer`,
    id: 9,
    type: CONTENT_TYPE.AS_IS_AND_AS_AVAILABLE,
  },
  {
    title: "Governing Law",
    id: 10,
    type: CONTENT_TYPE.GOVERNING_LAW,
  },
  {
    title: "Disputes Resolution",
    id: 11,
    type: CONTENT_TYPE.conta,
  },
  {
    title: "For European Union (EU) Users",
    id: 12,
    type: CONTENT_TYPE.EU_USERS,
  },
  {
    title: "United States Legal Compliance",
    id: 13,
    type: CONTENT_TYPE.UNITED_STATES_LEGAL_COMPLIANCE,
  },
  {
    title: "Sever-ability and Waiver",
    id: 14,
    type: CONTENT_TYPE.SERVER_ABILITY_AND_WAIVER,
  },
  {
    title: "Translation Interpretation",
    id: 15,
    type: CONTENT_TYPE.TRANSLATION_INTERPRETATION,
  },
  {
    title: "Changes to These Terms and Conditions",
    id: 16,
    type: CONTENT_TYPE.CHANGES_TO_TERMS_AND_CONDITIONS,
  },
  {
    title: "Contact Us",
    id: 17,
    type: CONTENT_TYPE.CONTACT_US,
  },
];
function TermsAndConditionView() {
  usePageTitle("Terms of Service");
  const [navList, setNavList] = useState<navItem[]>([]);
  const [activeTab, setActiveTab] = useState<CONTENT_TYPE>(
    CONTENT_TYPE.INTERPRETAION_AND_DEFINITIONS,
  );
  const lastUpdatedDate = toDateString(new Date("2022-09-15"));
  useEffect(() => {
    const navList: navItem[] = NAV_DATA.map((item) => {
      return {
        title: item.title,
        id: item.id,
        type: item.type,
      };
    });
    setNavList(navList);
  }, []);

  const handleTabSelection = (tab: CONTENT_TYPE) => {
    setActiveTab(tab);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const renderSideNav = () => {
    return (
      <div className="sticky top-24  w-full md:w-96 md:pr-10">
        {navList.map((item: navItem, index: number) => {
          return (
            <div key={item.id + index} className="flex flex-col border-b-[1px] border-gray-600 ">
              <div
                onClick={() => {
                  handleTabSelection(item.type);
                }}
                className={classNames(
                  "cursor-pointer break-normal py-4 font-poppins text-base  text-gray-900  hover:text-purple-500 ",
                  {
                    "font-poppins text-base font-semibold text-purple-500": activeTab === item.type,
                    "pb-3": item?.subMenu,
                  },
                )}
              >
                {item.title}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const renderContentTitle = (contentTitle: string, customClass?: string) => {
    return (
      <div
        className={classNames(
          "py-2 font-poppins text-xl font-semibold text-purple-500 ",
          customClass,
        )}
      >
        {contentTitle}
      </div>
    );
  };
  const renderContentSubTitle = (subtitle: string, customClass?: string) => {
    return (
      <div
        className={classNames("mt-2 pt-2 font-poppins font-semibold text-purple-500", customClass)}
      >
        {subtitle}
      </div>
    );
  };
  const renderDescription = (description: JSX.Element, customClass?: string) => {
    return (
      <>
        <p
          className={classNames(" my-4 font-poppins text-base  font-normal leading-6", customClass)}
        >
          {description}
        </p>
      </>
    );
  };
  const renderItems = (items: JSX.Element[], customClass?: string) => {
    return (
      <div className={classNames("pl-2 font-poppins text-base font-normal leading-6", customClass)}>
        {items.map((item, i) => {
          return (
            <li key={i} className="list-inside list-disc">
              {item}
            </li>
          );
        })}
      </div>
    );
  };

  const renderContent = (contentType: CONTENT_TYPE) => {
    switch (contentType) {
      case CONTENT_TYPE.INTERPRETAION_AND_DEFINITIONS:
        return renderInterpretationAndDefinition();
      case CONTENT_TYPE.ACKNOWLEDGMENT:
        return renderAcknowledgement();
      case CONTENT_TYPE.USER_ACCOUNTS:
        return renderUserAccounts();
      case CONTENT_TYPE.INTELLECTUAL_PROPERTY:
        return renderIntellectualProperty();
      case CONTENT_TYPE.YOUR_FEEDBACK_TO_US:
        return renderYourFeedBackToUs();
      case CONTENT_TYPE.LINKS_TO_OTHER_WEBSITE:
        return renderLinksToOthersWebsite();
      case CONTENT_TYPE.TERMINATION:
        return renderTermination();
      case CONTENT_TYPE.LIMITATION_OF_LIABILITY:
        return renderLimitationOfLiability();
      case CONTENT_TYPE.AS_IS_AND_AS_AVAILABLE:
        return renderDesclaimer();
      case CONTENT_TYPE.GOVERNING_LAW:
        return renderGoverningLaw();
      case CONTENT_TYPE.conta:
        return renderDisputesResolution();
      case CONTENT_TYPE.EU_USERS:
        return renderforEuropeanUsers();
      case CONTENT_TYPE.UNITED_STATES_LEGAL_COMPLIANCE:
        return renderUnitedStatesLegalCompliance();
      case CONTENT_TYPE.SERVER_ABILITY_AND_WAIVER:
        return renderServerAbilityAndWaiver();
      case CONTENT_TYPE.TRANSLATION_INTERPRETATION:
        return renderTranslationInterpretation();
      case CONTENT_TYPE.CHANGES_TO_TERMS_AND_CONDITIONS:
        return renderChangesToTermsAndCondition();
      case CONTENT_TYPE.CONTACT_US:
        return renderContactUs();
      default:
        throw new Error(`Non-existent content type in switch: ${contentType}`);
    }
  };
  const renderChangesToTermsAndCondition = () => {
    return (
      <div>
        {renderContentTitle("Changes to These Terms and Conditions")}
        {renderDescription(
          <>
            We reserve the right, at Our sole discretion, to modify or replace these Terms at any
            time. If a revision is material We will make reasonable efforts to provide at least 30
            days&apos; notice prior to any new terms taking effect. What constitutes a material
            change will be determined at Our sole discretion.
          </>,
        )}
        {renderDescription(
          <>
            By continuing to access or use Our Service after those revisions become effective, You
            agree to be bound by the revised terms. If You do not agree to the new terms, in whole
            or in part, please stop using the website and the Service.
          </>,
        )}
      </div>
    );
  };
  const renderTranslationInterpretation = () => {
    return (
      <div>
        {renderContentTitle("Translation Interpretation")}
        {renderDescription(
          <>
            These Terms and Conditions may have been translated if We have made them available to
            You on our Service. You agree that the original English text shall prevail in the case
            of a dispute.
          </>,
        )}
      </div>
    );
  };
  const renderServerAbilityAndWaiver = () => {
    return (
      <div>
        {renderContentTitle("Severability and Waiver")}
        {renderContentSubTitle("Severability")}
        {renderDescription(
          <>
            If any provision of these Terms is held to be unenforceable or invalid, such provision
            will be changed and interpreted to accomplish the objectives of such provision to the
            greatest extent possible under applicable law and the remaining provisions will continue
            in full force and effect.
          </>,
        )}
        {renderContentSubTitle("Waiver")}
        {renderDescription(
          <>
            Except as provided herein, the failure to exercise a right or to require performance of
            an obligation under these Terms shall not effect a party&apos;s ability to exercise such
            right or require such performance at any time thereafter nor shall the waiver of a
            breach constitute a waiver of any subsequent breach.
          </>,
        )}
      </div>
    );
  };
  const renderUnitedStatesLegalCompliance = () => {
    return (
      <div>
        {renderContentTitle("United States Legal Compliance")}
        {renderDescription(
          <>
            You represent and warrant that (i) You are not located in a country that is subject to
            the United States government embargo, or that has been designated by the United States
            government as a &quot;terrorist supporting&quot; country, and (ii) You are not listed on
            any United States government list of prohibited or restricted parties.
          </>,
        )}
      </div>
    );
  };
  const renderLink = (link: string, title: string) => {
    return (
      <a href={link} target="_blank" rel="noreferrer" className="px-1 text-base">
        {title}
      </a>
    );
  };
  const renderInterpretationAndDefinition = () => {
    const contentList: JSX.Element[] = [
      <>
        <b>Affiliate</b> means an entity that controls, is controlled by or is under common control
        with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity
        interest or other securities entitled to vote for election of directors or other managing
        authority.
      </>,
      <>
        <b>Account</b> means a unique account created for You to access our Service or parts of our
        Service.
      </>,
      <>
        <b>Country</b> refers to: New York, United States
      </>,
      <>
        <b>Company</b> (referred to as either &quot;the Company&quot;, &quot;We&quot;,
        &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Giboo, Inc., 1115 Broadway,
        NY 10010.
      </>,
      <>
        <b>Device</b> means any device that can access the Service such as a computer, a cellphone
        or a digital tablet.
      </>,
      <>
        <b>Feedback</b> means feedback, innovations or suggestions sent by You regarding the
        attributes, performance or features of our Service.
      </>,
      <>
        <b>Service</b> refers to the Website.
      </>,
      <>
        <b>Terms and Conditions</b> (also referred as &quot;Terms&quot;) mean these Terms and
        Conditions that form the entire agreement between You and the Company regarding the use of
        the Service.
      </>,
      <>
        <b>Third-party Social Media Service</b> means any services or content (including data,
        information, products or services) provided by a third-party that may be displayed, included
        or made available by the Service.
      </>,
      <>
        <b>Website</b> refers to Giboo, accessible from www.giboo.com
      </>,
      <>
        <b>You</b> means the individual accessing or using the Service, or the company, or other
        legal entity on behalf of which such individual is accessing or using the Service, as
        applicable.
      </>,
    ];
    return (
      <div>
        {renderContentTitle("Interpretation and Definitions")}
        {renderContentSubTitle("Interpretation")}
        {renderDescription(
          <>
            The words of which the initial letter is capitalized have meanings defined under the
            following conditions. The following definitions shall have the same meaning regardless
            of whether they appear in singular or in plural.
          </>,
        )}
        {renderContentSubTitle("Definitions", "pt-3")}
        {renderDescription(<>For the purposes of these Terms and Conditions:</>)}
        {renderItems(contentList,'-mt-5')}
      </div>
    );
  };
  const renderTermination = () => {
    return (
      <div>
        {renderContentTitle("Termination")}

        {renderDescription(
          <>
            We may terminate or suspend Your Account immediately, without prior notice or liability,
            for any reason whatsoever, including without limitation if You breach these Terms and
            Conditions.
          </>,
        )}
        {renderDescription(
          <>
            Upon termination, Your right to use the Service will cease immediately. If You wish to
            terminate Your Account, You may simply discontinue using the Service.
          </>,
        )}
      </div>
    );
  };
  const renderLimitationOfLiability = () => {
    return (
      <div>
        {renderContentTitle("Limitation of Liability")}
        {renderDescription(
          <>
            Notwithstanding any damages that You might incur, the entire liability of the Company
            and any of its suppliers under any provision of this Terms and Your exclusive remedy for
            all of the foregoing shall be limited to the amount actually paid by You through the
            Service or 100 USD if You haven&apos;t purchased anything through the Service.
          </>,
        )}

        {renderDescription(
          <>
            To the maximum extent permitted by applicable law, in no event shall the Company or its
            suppliers be liable for any special, incidental, indirect, or consequential damages
            whatsoever (including, but not limited to, damages for loss of profits, loss of data or
            other information, for business interruption, for personal injury, loss of privacy
            arising out of or in any way related to the use of or inability to use the Service,
            third-party software and/or third-party hardware used with the Service, or otherwise in
            connection with any provision of this Terms), even if the Company or any supplier has
            been advised of the possibility of such damages and even if the remedy fails of its
            essential purpose.
          </>,
        )}
        {renderDescription(
          <>
            Some states do not allow the exclusion of implied warranties or limitation of liability
            for incidental or consequential damages, which means that some of the above limitations
            may not apply. In these states, each party&apos;s liability will be limited to the
            greatest extent permitted by law.
          </>,
        )}
      </div>
    );
  };
  const renderDesclaimer = () => {
    return (
      <div>
        {renderContentTitle('"AS IS" and "AS AVAILABLE" Disclaimer')}
        {renderDescription(
          <>
            The Service is provided to You &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and with
            all faults and defects without warranty of any kind. To the maximum extent permitted
            under applicable law, the Company, on its own behalf and on behalf of its Affiliates and
            its and their respective licensors and service providers, expressly disclaims all
            warranties, whether express, implied, statutory or otherwise, with respect to the
            Service, including all implied warranties of merchantability, fitness for a particular
            purpose, title and non-infringement, and warranties that may arise out of course of
            dealing, course of performance, usage or trade practice. Without limitation to the
            foregoing, the Company provides no warranty or undertaking, and makes no representation
            of any kind that the Service will meet Your requirements, achieve any intended results,
            be compatible or work with any other software, applications, systems or services,
            operate without interruption, meet any performance or reliability standards or be error
            free or that any errors or defects can or will be corrected.
          </>,
        )}

        {renderDescription(
          <>
            Without limiting the foregoing, neither the Company nor any of the company&apos;s
            provider makes any representation or warranty of any kind, express or implied: (i) as to
            the operation or availability of the Service, or the information, content, and materials
            or products included thereon; (ii) that the Service will be uninterrupted or error-free;
            (iii) as to the accuracy, reliability, or currency of any information or content
            provided through the Service; or (iv) that the Service, its servers, the content, or
            e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan
            horses, worms, malware, timebombs or other harmful components.
          </>,
        )}
        {renderDescription(
          <>
            Some jurisdictions do not allow the exclusion of certain types of warranties or
            limitations on applicable statutory rights of a consumer, so some or all of the above
            exclusions and limitations may not apply to You. But in such a case the exclusions and
            limitations set forth in this section shall be applied to the greatest extent
            enforceable under applicable law.
          </>,
        )}
      </div>
    );
  };
  const renderGoverningLaw = () => {
    return (
      <div>
        {renderContentTitle("Governing Law")}
        {renderDescription(
          <>
            The laws of the Country, excluding its conflicts of law rules, shall govern this Terms
            and Your use of the Service. Your use of the Application may also be subject to other
            local, state, national, or international laws.
          </>,
        )}
      </div>
    );
  };
  const renderDisputesResolution = () => {
    return (
      <div>
        {renderContentTitle("Disputes Resolution")}
        {renderDescription(
          <>
            If You have any concern or dispute about the Service, You agree to first try to resolve
            the dispute informally by contacting the Company.
          </>,
        )}
      </div>
    );
  };
  const renderforEuropeanUsers = () => {
    return (
      <div>
        {renderContentTitle("For European Union (EU) Users")}
        {renderDescription(
          <>
            If You are a European Union consumer, you will benefit from any mandatory provisions of
            the law of the country in which you are resident in.
          </>,
        )}
      </div>
    );
  };
  const renderAcknowledgement = () => {
    return (
      <div>
        {renderContentTitle("Acknowledgement", "mb-2")}
        {renderDescription(
          <>
            These are the Terms and Conditions governing the use of this Service and the agreement
            that operates between You and the Company. These Terms and Conditions set out the rights
            and obligations of all users regarding the use of the Service.
          </>,
        )}
        {renderDescription(
          <>
            Your access to and use of the Service is conditioned on Your acceptance of and
            compliance with these Terms and Conditions. These Terms and Conditions apply to all
            visitors, users and others who access or use the Service.
          </>,
        )}
        {renderDescription(
          <>
            By accessing or using the Service You agree to be bound by these Terms and Conditions.
            If You disagree with any part of these Terms and Conditions then You may not access the
            Service.
          </>,
        )}
        {renderDescription(
          <>
            You represent that you are over the age of 18. The Company does not permit those under
            18 to use the Service.
          </>,
        )}
        {renderDescription(
          <>
            Your access to and use of the Service is also conditioned on Your acceptance of and
            compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our
            policies and procedures on the collection, use and disclosure of Your personal
            information when You use the Application or the Website and tells You about Your privacy
            rights and how the law protects You. Please read Our Privacy Policy carefully before
            using Our Service.
          </>,
        )}
      </div>
    );
  };

  const renderUserAccounts = () => {
    return (
      <div>
        {renderContentTitle("User Accounts", "mb-2")}
        {renderDescription(
          <>
            When You create an account with Us, You must provide Us information that is accurate,
            complete, and current at all times. Failure to do so constitutes a breach of the Terms,
            which may result in immediate termination of Your account on Our Service.
          </>,
        )}
        {renderDescription(
          <>
            You are responsible for safeguarding the password that You use to access the Service and
            for any activities or actions under Your password, whether Your password is with Our
            Service or a Third-Party Social Media Service.
          </>,
        )}
        {renderDescription(
          <>
            You agree not to disclose Your password to any third party. You must notify Us
            immediately upon becoming aware of any breach of security or unauthorized use of Your
            account.
          </>,
        )}
        {renderDescription(
          <>
            You may not use as a username the name of another person or entity or that is not
            lawfully available for use, a name or trademark that is subject to any rights of another
            person or entity other than You without appropriate authorization, or a name that is
            otherwise offensive, vulgar or obscene.
          </>,
        )}
      </div>
    );
  };
  const renderIntellectualProperty = () => {
    return (
      <div>
        {renderContentTitle("Intellectual Property", "mb-2")}
        {renderDescription(
          <>
            The Service and its original content (excluding Content provided by You or other users),
            features and functionality are and will remain the exclusive property of the Company and
            its licensors.
          </>,
        )}
        {renderDescription(
          <>
            The Service is protected by copyright, trademark, and other laws of both the Country and
            foreign countries.
          </>,
        )}
        {renderDescription(
          <>
            Our trademarks and trade dress may not be used in connection with any product or service
            without the prior written consent of the Company.
          </>,
        )}
      </div>
    );
  };
  const renderYourFeedBackToUs = () => {
    return (
      <div>
        {renderContentTitle("Your Feedback to Us", "mb-2")}
        {renderDescription(
          <>
            You assign all rights, title and interest in any Feedback You provide the Company. If
            for any reason such assignment is ineffective, You agree to grant the Company a
            non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use,
            reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without
            restriction.
          </>,
        )}
      </div>
    );
  };
  const renderLinksToOthersWebsite = () => {
    return (
      <div>
        {renderContentTitle("Links to Other Websites", "mb-2")}
        {renderDescription(
          <>
            Our Service may contain links to third-party web sites or services that are not owned or
            controlled by the Company.
          </>,
        )}
        {renderDescription(
          <>
            The Company has no control over, and assumes no responsibility for, the content, privacy
            policies, or practices of any third party web sites or services. You further acknowledge
            and agree that the Company shall not be responsible or liable, directly or indirectly,
            for any damage or loss caused or alleged to be caused by or in connection with the use
            of or reliance on any such content, goods or services available on or through any such
            web sites or services.
          </>,
        )}
        {renderDescription(
          <>
            We strongly advise You to read the terms and conditions and privacy policies of any
            third-party web sites or services that You visit.
          </>,
        )}
      </div>
    );
  };
  const renderContactUs = () => {
    return (
      <div>
        {renderContentTitle("Contact Us", "mb-2")}
        {renderDescription(
          <>If you have any questions about this Privacy Policy, You can contact us:</>,
        )}
        {renderDescription(
          <>By email:{renderLink("mailto:contact@giboo.com", "contact@giboo.com")} </>,
        )}
      </div>
    );
  };
  const renderTermsAndConditionDescription = () => {
    return (
      <div className="my-4 font-poppins text-base font-normal  leading-6">
        {renderDescription(
          <>Please read these terms and conditions carefully before using Our Service.</>,
        )}
      </div>
    );
  };
  return (
    <div>
      <Header />
      <div className="relative mt-24 h-full p-2">
        <div className="mx-auto mt-4 max-w-[930px]">
          <h4 className="font-poppins text-3xl">Terms of Service</h4>
          <div className="my-3">
            <p className="text-md font-sm text-muted font-poppins">
              Last Updated:{lastUpdatedDate}
            </p>
          </div>
          {renderTermsAndConditionDescription()}
          <div className="relative mt-2 flex h-screen w-full flex-col border-t-[1px] border-gray-600 pt-3 md:flex-row">
            <div className="relative w-full md:w-2/5">{renderSideNav()}</div>
            <div className="w-full whitespace-pre-line  md:mt-2 md:w-2/3 md:pl-10">
              {renderContent(activeTab)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TermsAndConditionView.propTypes = {};

export default TermsAndConditionView;
