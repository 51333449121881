import React, { FormEvent, useEffect, useState } from "react";
import axios from "axios";
import config from "../api";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../components/PrimaryButton";
import Spacer from "../components/Spacer";
import PasswordInput from "../components/PasswordInput";
import parseError from "./errorParser";
import classNames from "classnames";
import PlainButton from "../components/PlainButton";
import { userService } from "../services/user.services";
import Spinner from "../components/Spinner";

const regCase = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9`"'~!@#$%^&*\-\\_\\=\\+|;:,<.>/?{}()]{2,}$/,
);
const regSymbol = new RegExp(
  /^(?=.*[`"'~!@#$%^&*\-\\_\\=\\+|;:,<.>/?{}()])(?=.*[0-9])[a-zA-Z0-9`"'~!@#$%^&*\-\\_\\=\\+|;:,<.>/?{}()]{2,}$/,
);

function NewPasswordView() {
  const { resettoken } = useParams();
  const [passwd1, setPasswd1] = useState("");
  const [passwd2, setPasswd2] = useState("");
  const [invalidPassword, setInvalidpassword] = useState(false);
  const [submitted, setSubmit] = useState(false);
  const [pwLengthOk, setPwLengthOk] = useState(false);
  const navigate = useNavigate();
  // const [pwCaseOk, setPwCaseOk] = useState(false);
  // const [pwSymbolOk, setPwSymbolOk] = useState(false);

  const [err, setErr] = useState(undefined);
  const [isLoading, setLoading] = useState(true);
  const [isResetting, setIsResetting] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  useEffect(() => {
    if (isLoading) {
      if (resettoken)
        userService
          .pwdResetTokenVerify(resettoken)
          .then((response) => {
            // console.log(response);
          })
          .catch((error) => {
            // console.log("error", error);
            setErrorMsg(parseError(error));
          })
          .finally(() => {
            setLoading(false);
          });
    }
  }, [resettoken, setErrorMsg, isLoading]);

  const isLengthOk = (passwd: string) => {
    return passwd.length >= 8;
  };

  const isCaseOk = (passwd: string) => {
    return regCase.test(passwd);
  };

  const isSymbolOk = (passwd: string) => {
    return regSymbol.test(passwd);
  };

  const passwordVerify = (passwd: string) => {
    setPasswd1(passwd);
    setPwLengthOk(isLengthOk(passwd));
    // setPwCaseOk(isCaseOk(passwd));
    // setPwSymbolOk(isSymbolOk(passwd));
  };

  const requestReset = () => {
    setIsResetting(true);
    resettoken &&
      userService
        .resetPassword(resettoken, passwd1)
        .then((response) => {
          // console.log(response);
          if (response) setSubmit(true);
        })
        .catch((error) => {
          // console.log("err", error);
          if (error.response.status === 408) {
            setErr(parseError("The reset link is expired"));
          } else {
            setErr(parseError(error));
          }
          setInvalidpassword(true);
        })
        .finally(() => {
          setIsResetting(false);
        });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (passwd1 === passwd2) {
      requestReset();
    } else {
      // console.log("check passwd");
      setErr(parseError("Password do not match"));
      setInvalidpassword(true);
    }
  };

  const renderPwdResetSuccess = () => {
    return (
      <div className="flex h-[700px] justify-center">
        <div className="mt-[36px] min-h-[486px] w-[536px]">
          <div className="flex h-[270px] w-[495px] justify-center">
            <img alt="img1" src={"/pwd-reset-ok.svg"} className="h-full w-full" />
          </div>
          <Spacer height="48px" />
          <div className="flex justify-center">
            <h3>Password reset successful!</h3>
          </div>
          <Spacer height="1em" />
          <div className="flex justify-center">
            <p>You can now use your new password to log in to your account</p>
          </div>
          <Spacer height="48px" />
          <div className="flex justify-center px-2">
            <PrimaryButton
              id="gogo-login-btn"
              className="w-full"
              size="lg"
              label="Back to log in"
              handleOnClick={() => navigate("/signin")}
            />
          </div>
          <div></div>
          <Spacer height="266px" />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="mt-[50px] min-h-[486px] w-[536px]">
        <h3 className="text-center">Reset password</h3>
        <p className="subtitle mt-4 text-center">
          Your new password must be different from your previous password
        </p>
        <Spacer height="60px" />
        <div className="flex justify-center py-4">
          <form style={{ width: "480px" }}>
            <PasswordInput
              id="password-1-input"
              placeholder="New password*"
              handleOnChange={(e) => {
                passwordVerify(e.target.value);
                setInvalidpassword(false);
              }}
              invalid={invalidPassword}
            />
            <div className="my-4 w-full">
              <ul className="subtitle list-disc" style={{ paddingLeft: "1.5em", marginBottom: 0 }}>
                <li className={classNames("text-sm", pwLengthOk && "text-purple-500")}>
                  Length at least 8 or longer
                </li>
              </ul>
            </div>
            <PasswordInput
              id="password-2-input"
              placeholder="Confirm password*"
              handleOnChange={(e) => {
                setPasswd2(e.target.value);
                setInvalidpassword(false);
              }}
              invalid={invalidPassword}
            />
          </form>
        </div>
        <div className="flex justify-center">
          <div className="flex flex-row" style={{ width: "480px", paddingBottom: "1em" }}>
            <h5 className="text-purple-500">{err}</h5>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row-reverse" style={{ width: "480px" }}>
            <PrimaryButton
              size="lg"
              id="btn-next"
              disabled={!pwLengthOk}
              handleOnClick={handleSubmit}
              loading={isResetting}
              label="Reset password"
              style={{ width: "100%" }}
            />
          </div>
          <Spacer height="60px" />
          <PlainButton
            id="btn-back"
            underline
            label="Back to log in"
            leftIconClass="fa-solid fa-arrow-left"
            handleOnClick={() => navigate("/")}
          />
        </div>
        <Spacer height="200px" />
      </div>
    );
  };

  const renderErrorMessage = () => {
    return (
      <>
        <div className="flex h-[620px] justify-center">
          <div className="min-h-[486px] w-[596px]">
            <Spacer height="50px" />
            <h3 className="text-center">Whoops, password reset link expired</h3>
            <p className="subtitle mt-4 text-center">
              For security reasons, password rest links expire in 48hours.
              <br /> If you still need to reset your password, you can request a new reset email.
            </p>
            <Spacer height="72px" />
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col" style={{ width: "480px" }}>
                <PrimaryButton
                  size="lg"
                  id="btn-next"
                  handleOnClick={() => {
                    navigate("/forgetpassword");
                  }}
                  label="Reset your password"
                  style={{ width: "100%" }}
                />
                <Spacer height="60px" />
                <PlainButton
                  id="btn-back"
                  underline
                  label="Back to log in"
                  leftIconClass="fa-solid fa-arrow-left"
                  handleOnClick={() => navigate("/")}
                />
              </div>
            </div>
            <Spacer height="200px" />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex h-[600px] justify-center">
      {isLoading ? (
        <div>
          <Spacer height="269px" />
          <Spinner size="lg" />
          <Spacer height="369px" />
        </div>
      ) : errorMsg ? (
        renderErrorMessage()
      ) : submitted ? (
        renderPwdResetSuccess()
      ) : (
        renderContent()
      )}
    </div>
  );
}

export default NewPasswordView;
