import axios from "axios";
import useSWR, { useSWRConfig } from "swr";
import config from "../api";
import { useCallback, useEffect, useState } from "react";
import { swrOptionDedupling5mins } from "../types/swr";
import { differenceInDays } from "date-fns";
import useUser from "./useUser";

export enum ConsentCookieEnum {
  CONSENT = 1,
  DEFAULT = 0,
  REJECT = -1,
}
interface ConsentCookie {
  value: ConsentCookieEnum;
  updated_at: string;
}
interface useConsentCookieState {
  needToAsk: boolean;
  value: ConsentCookie | undefined;
  update: (value: ConsentCookieEnum) => void;
  isLoading?: boolean;
}
function useConsentCookie(): useConsentCookieState {
  const [user] = useUser();
  const url = user?._id
    ? process.env.REACT_APP_API_URL + `/api/v2/users/consent_cookie?user_id=${user?._id}`
    : null;
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const { mutate } = useSWRConfig();
  const { data, isLoading } = useSWR<ConsentCookie>(url, fetch, swrOptionDedupling5mins);
  const [needToAsk, setNeedToAsk] = useState<boolean>(false);
  const handleUpdate = useCallback(
    (newValue: ConsentCookieEnum) => {
      if (!url) return;
      const options = {
        optimisticData: { value: newValue, updated_at: new Date() },
        rollbackOnError: true,
      };
      mutate(
        url,
        axios.put(url, { value: newValue }, config).then((response) => response.data),
        options,
      );
    },
    [url],
  );
  useEffect(() => {
    if (!data) {
      setNeedToAsk(false);
    } else if (user?._id) {
      const daysDifference = differenceInDays(new Date(), new Date(data.updated_at));
      setNeedToAsk(
        data.value === ConsentCookieEnum.DEFAULT ||
          (data.value === ConsentCookieEnum.REJECT && daysDifference > 6) ||
          (data.value === ConsentCookieEnum.CONSENT && daysDifference > 30)
          ? true
          : false,
      );
    }
  }, [data, user, user?._id]);

  return {
    needToAsk,
    value: data,
    update: handleUpdate,
    isLoading: isLoading,
  };
}
export default useConsentCookie;
