import React from "react";
import PropTypes from "prop-types";
import logo from "../../pages/img/logo.svg";

function Header() {
  return (
    <div className=" fixed top-0 w-full h-20 bg-white shadow-md z-20 flex items-center px-4">
      <a href="/">
        <div className="flex items-center mx-2">
          <img src={logo} alt="Giboo" width="51" height="31" />
          <span className="font-bold text-2xl leading-7 items-center text-center text-purple-0 ml-4">
            Giboo
          </span>
        </div>
      </a>
    </div>
  );
}

Header.propTypes = {};

export default Header;
