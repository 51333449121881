const roleLabels = {
  executive: ["Executive"],
  board: ["Board member"],
  officer: ["Program/Project officer"],
  grant: ["Grant officer"],
  fundraiser: ["Fundraiser"],
  volunteer: ["Volunteer"],
  consultant: ["External consultant"],
};
const roleKeys = Object.keys(roleLabels);
export type TypeRole = keyof typeof roleLabels & string;

const stageLabels = {
  all: { title: undefined, detail: undefined },
  dreamer: {
    title: <h4 className="semibold  font-poppins text-base">Individual</h4>,
    detail: (
      <div className="h-full pl-4 font-poppins text-sm text-gray-900">
        <ul className=" list-disc">
          <li>
            <span className="font-semibold text-purple-500">0-1</span> employees
          </li>
          <li>
            <span className="font-semibold text-purple-500">Minimal or no </span> gross receipts
          </li>
          <li> Strategic plan is available</li>
          <li> Ready to seek funding</li>
        </ul>
      </div>
    ),
  },
  seed: {
    title: <h4 className="semibold  font-poppins text-base"> Pre-seed & Seed</h4>,
    detail: (
      <div className="h-full pl-4 font-poppins text-sm text-gray-900">
        <ul className=" list-disc">
          <li>
            <span className="font-semibold text-purple-500">1-3</span> employees
          </li>
          <li>
            Gross receipts <span className="font-semibold text-purple-500">below $50,000 </span>
          </li>
          <li> N-postcard on file</li>
        </ul>
      </div>
    ),
  },
  startup: {
    title: <h4 className="semibold  font-poppins text-base">Start up</h4>,
    detail: (
      <div className="h-full pl-4 font-poppins text-sm text-gray-900">
        <ul className=" list-disc">
          <li>
            <span className="font-semibold text-purple-500">1-5</span> employees
          </li>
          <li>
            Gross receipts of{" "}
            <span className="font-semibold text-purple-500">$50,000 to $200,000 </span>
          </li>
          <li>IRS 990 EZ forms filed</li>
          <li>Successfully fulfilled at least one part of mission</li>
        </ul>
      </div>
    ),
  },
  buildup: {
    title: <h4 className="semibold  font-poppins text-base">Build up</h4>,
    detail: (
      <div className="h-full pl-4 font-poppins text-sm text-gray-900">
        <ul className=" list-disc">
          <li>
            <span className="font-semibold text-purple-500">3+ years</span> of operation
          </li>
          <li>
            <span className="font-semibold text-purple-500">5-15</span> employees
          </li>
          <li>
            <span className="font-semibold text-purple-500">$200,000 to $750,000</span> gross
            receipts
          </li>
          <li>IRS 990 forms filed</li>
          <li>Auditability in progress</li>
        </ul>
      </div>
    ),
  },
  superstar: {
    title: <h4 className="semibold  font-poppins text-base">Super star</h4>,
    detail: (
      <div className="h-full pl-4 font-poppins text-sm text-gray-900">
        <ul className=" list-disc">
          <li>
            <span className="font-semibold text-purple-500">5+ years</span> of operation
          </li>
          <li>
            <span className="font-semibold text-purple-500">15+</span> employees
          </li>
          <li>
            Gross receipts of{" "}
            <span className="font-semibold text-purple-500">$750,000 to $10 million</span>
          </li>
          <li>IRS 990 forms filed</li>
          <li>audited Financial statement available</li>
        </ul>
      </div>
    ),
  },
  hyperstar: {
    title: <h4 className="semibold  font-poppins text-base">Hyper star</h4>,
    detail: (
      <div className="h-full pl-4 font-poppins text-sm text-gray-900">
        <ul className=" list-disc text-gray-900">
          <li>
            <span className="font-semibold text-purple-500">5+ years</span> of operation
          </li>
          <li>
            <span className="font-semibold text-purple-500">25+</span> employees
          </li>
          <li>
            Gross receipts of <span className="font-semibold text-purple-500">$10 million+</span>
          </li>
          <li>IRS 990 forms filed</li>
          <li>audited Financial statement available</li>
        </ul>
      </div>
    ),
  },
};
const stageKeys = Object.keys(stageLabels);
export type TypeStage = keyof typeof stageLabels;

const taxExemptionLabels = {
  yes: ["501(c)(3) - Charitable organizations"],
  no: ["No"],
  applied: ["Applied, not yet received"],
  sponsorship: ["Have fiscal sponsorships"],
  "501c4": ["501(c)(4) - Social welfare organizations"],
  "501c6": ["501(c)(6) - Business leagues, Chambers of commerce"],
  "501c7": ["501(c)(7) - Social and recreational clubs"],
  "501c19": ["501(c)(19) - Veterans' organizations"],
  others: "Others",
};
const taxExemptionKeys = Object.keys(taxExemptionLabels);
export type TypeTaxExemption = keyof typeof taxExemptionLabels;
const taxExemptionMap: { [key: number]: TypeTaxExemption } = {
  3: "yes",
  4: "501c4",
  6: "501c6",
  7: "501c7",
  19: "501c19",
};

const planningIncorpLabels = {
  in_half_year: ["In 6 months"],
  in_a_year: ["In one year"],
  no_plan: ["Do not have any plan yet"],
  skip: ["Skip, think about it later"],
};
const planningIncorpKeys = Object.keys(planningIncorpLabels);
export type TypePlanningIncorp = keyof typeof planningIncorpLabels;

export {
  taxExemptionMap,
  roleLabels,
  roleKeys,
  stageLabels,
  stageKeys,
  taxExemptionLabels,
  taxExemptionKeys,
  planningIncorpLabels,
  planningIncorpKeys,
};
