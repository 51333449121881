import { useCallback, useEffect, useState } from "react";
import { ILocation, locationAutoComplete, reprLocation } from "../types/location";
import { findCloseLocations } from "../services/geo.services";
import useSWR from "swr";

interface CloseLocationKeysMap {
  [key: string]: string[];
}
interface LocationMap {
  [key: string]: ILocation;
}
const useLocationRecommendation = (
  location: ILocation[],
  primary?: ILocation[],
  text?: string,
  exactMatchByText = false,
): {
  recommended: ILocation[];
  addCenter: (center: ILocation, limit?: number | undefined) => void;
  isLoading: boolean;
} => {
  const [locationKey, setLocationKey] = useState<string[]>([]);
  useEffect(() => {
    setLocationKey(location.map((loc) => reprLocation(loc)));
  }, [location]);
  const [closeLocationKeysMap, setCloseLocationKeysMap] = useState<CloseLocationKeysMap>({});
  const [locationMap, setLocationMap] = useState<LocationMap>(
    primary
      ? primary.reduce((prev, l) => {
          const state: ILocation | undefined =
            l.level > 1 ? { ...l, city: "", county_name: "", level: 1 } : undefined;
          return {
            ...prev,
            [reprLocation(l)]: l,
            ...(state ? { [reprLocation(state)]: state } : {}),
          };
        }, {} as LocationMap)
      : {},
  );
  const [recommended, setRecommended] = useState<ILocation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState<boolean>(false);
  const fetch = (url: string) => {
    if (!url) return new Promise<ILocation[]>((resolve) => resolve([]));
    const query = url.split("@")[1];
    return locationAutoComplete(query);
  };
  const { data: recommendedByText } = useSWR<ILocation[]>(
    text ? `location-recommendation@${text}` : null,
    fetch,
  );
  const addCenter = useCallback(
    async (center: ILocation, limit = 10) => {
      setIsLoading(true);
      await findCloseLocations(center, 20000, limit).then((res) => {
        const state: ILocation[] =
          center.level > 1 ? [{ ...center, city: "", county_name: "", level: 1 }] : [];
        const res2 = [...state, ...res];
        setCloseLocationKeysMap((prev) => {
          return {
            ...prev,
            [reprLocation(center)]: res2.map((l) => reprLocation(l)),
          };
        });
        setLocationMap((prev) => res2.reduce((p, c) => ({ ...p, [reprLocation(c)]: c }), prev));
      });
      setIsLoading(false);
    },
    [setIsLoading, setCloseLocationKeysMap, setLocationMap],
  );
  useEffect(() => {
    setIsLoadingInitial(true);
    const promises = location.map((loc) => addCenter(loc));
    Promise.all(promises).finally(() => setIsLoadingInitial(false));
  }, [location, addCenter, setIsLoadingInitial]);
  useEffect(() => {
    const all = locationKey.reduce(
      (prev, key) => [...prev, ...(closeLocationKeysMap[key] ? closeLocationKeysMap[key] : [])],
      [] as string[],
    );
    const defaultMap: { [key: string]: number } = primary
      ? primary.reduce((prev, cur) => {
          const state: ILocation | undefined =
            cur.level > 1 ? { ...cur, city: "", county_name: "", level: 1 } : undefined;
          return {
            ...prev,
            [reprLocation(cur)]: 1_000_000_000,
            ...(state ? { [reprLocation(state)]: 1_000_000_000 } : {}),
          };
        }, {})
      : {};
    const counter = all.reduce(
      (prev, cur) => ({ ...prev, [cur]: prev[cur] ? prev[cur] + 1 : 1 }),
      defaultMap,
    );
    const t = Object.keys(counter)
      .filter((key) => !locationKey.includes(key))
      .map((key) => ({ key, count: counter[key] }))
      .sort((a, b) => b.count - a.count)
      .map(({ key, count }) => locationMap[key])
      .filter(Boolean);
    const labels = t.map((l) => reprLocation(l));
    setRecommended([
      ...t,
      ...(recommendedByText
        ?.slice(0, 3)
        .filter(
          (r) =>
            (!exactMatchByText || text?.toLowerCase() === reprLocation(r).toLowerCase()) &&
            !labels.includes(reprLocation(r)) &&
            !locationKey.includes(reprLocation(r)),
        ) || []),
    ]);
  }, [locationKey, locationMap, closeLocationKeysMap, recommendedByText, text, exactMatchByText]);
  return { recommended, addCenter, isLoading: isLoading || isLoadingInitial };
};
export default useLocationRecommendation;
