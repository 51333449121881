import { useEffect, useState } from "react";
import useFunderWealth from "./useFunderWealth";

interface useFunderState {
  data: number[];
}
export default function useFunderFiledYear(id?: string, LAST_N_YEARS = 6): useFunderState {
  const { data: wealth } = useFunderWealth(id, LAST_N_YEARS);
  const [data, setData] = useState<number[]>([]);
  useEffect(() => {
    if (wealth) {
      setData(wealth.map((w) => w.tax_year).sort((a, b) => a - b));
    } else {
      setData([]);
    }
  }, [wealth]);
  return { data };
}
