import classNames from "classnames";
import * as React from "react";
import Spinner from "../Spinner";

interface ButtonProps {
  id: string;
  handleOnClick: (e: React.FormEvent) => void;
  className?: string;
  color?: "purple" | "red" | "yellow" | "green" | "blue" | "gray";
  label?: React.ReactNode | string;
  style?: React.CSSProperties;
  disabled?: boolean;
  outline?: boolean;
  underline?: boolean;
  labelClass?: string;
  loading?: boolean;
  size?: "sm" | "md" | "lg";
  appendIcon?: React.ReactNode;
  prependIcon?: React.ReactNode;
  fullWidth?: boolean;
  spinnerColor?: "purple" | "red" | "yellow" | "green" | "blue" | "gray" | "white";
  alignmentClass?: string;
  plain?: boolean;
  ariaValueText?: string;
  iconClass?: string;
}

function Button({
  color,
  size = "md",
  spinnerColor = "white",
  plain = false,
  alignmentClass,
  fullWidth,
  iconClass,
  ...props
}: ButtonProps) {
  const sizeClasses = {
    sm: "h-7 px-4 text-xs w-auto",
    md: "h-9 px-3 text-sm w-auto",
    lg: "h-12 px-6 text-lg w-auto",
  };

  const buttonClasses = classNames(
    "select-none rounded group font-poppins whitespace-pre hover:shadow",
    props.className,
    sizeClasses[size],
    {
      "font-normal": !props.outline,
      "disabled:opacity-75 bg-gray-200 text-gray-500 font-medium": props.disabled,
      [`bg-${color}-500 text-white hover:bg-purple-400`]:
        !props.outline && !props.disabled && !plain,
      [`border text-${color}-500 border-${color}-300 `]: props.outline && !props.disabled && !plain,
    },
    { "w-full": fullWidth },
    { "w-auto": !fullWidth },
  );

  return (
    <button
      id={props.id}
      disabled={props.disabled}
      onClick={(e) => {
        if (props.loading) return;
        e.currentTarget.blur();
        props.handleOnClick(e);
      }}
      onFocus={(e) => {
        e.preventDefault();
      }}
      className={classNames("!outline-none !ring-0", buttonClasses)}
      style={props.style}
      aria-valuetext={props.ariaValueText}
    >
      <div
        className={classNames("flex items-center justify-center", alignmentClass)}
        aria-valuetext={props.ariaValueText}
      >
        {props.prependIcon && (
          <span
            className={classNames("mr-2 grid h-5 w-5 place-items-center", iconClass)}
            aria-valuetext={props.ariaValueText}
          >
            {props.prependIcon}
          </span>
        )}
        {props.loading && <Spinner {...(spinnerColor && { color: spinnerColor })} size="sm" />}
        {props.label && (
          <span
            className={classNames(
              "w-auto text-start",
              {
                underline: props.underline,
              },
              props.labelClass,
            )}
            aria-valuetext={props.ariaValueText}
          >
            {props.label}
          </span>
        )}
        {props.appendIcon && (
          <span
            className={classNames("ml-2 grid h-5 w-5 place-items-center", iconClass)}
            aria-valuetext={props.ariaValueText}
          >
            {props.appendIcon}
          </span>
        )}
      </div>
    </button>
  );
}

Button.defaultProps = {
  color: "purple",
  fullWidth: false,
  outline: false,
};

export default Button;
