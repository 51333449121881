import classNames from "classnames";
import { ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getISearchParamFromOnboardingData } from "../../app/onboardingSlice";
import {
  matchingSummaryState,
  openSigninSignup,
  sidekickDetailViewScroll,
  sidekickSearchByName,
  sidekickSearchQuery,
  sidekickStartFundraisingLoading,
  sidekickTargetId,
  sidekickTargetType,
} from "../../app/recoilStore";
import Feedback from "../../components/Feedback";
import PlainButton from "../../components/PlainButton";
import WhiteContainer from "../../components/dashboard/WhiteContainer";
import FunderSolicitedGrantOpportunities from "../../components/funder/FunderSolicitedGrantOpportunities";
import Button from "../../components/tailwind/Button";
import useOnboardingData from "../../hooks/useOnboarding";
import useUser from "../../hooks/useUser";
import { Wealth } from "../../types/funder";
import { SearchQuery, getSearchQueryFromISearchParam } from "../../types/search";
import { getPercentageDiff, toUsdLong } from "../../utils/formatHelper";
import SimilarToFunder from "./SimilarToFunder";

import { Portal } from "@headlessui/react";
import { Divider } from "rsuite";
import FullScreenLoading from "../../components/FullScreenLoading";
import FundedAmount from "../../components/FundedAmount";
import FunderSummary from "../../components/FunderSummary";
import PhilanthropicInformationFoldable from "../../components/PhilanthropicInformationFoldable";
import RoundButton from "../../components/RoundButton";
import SavePopup from "../../components/SavePopup";
import ShareButton from "../../components/ShareButton";
import Spacer from "../../components/Spacer";
import StartFundRaisingModal, { useProjectCreation } from "../../components/StartFundraisingModal";
import TagFunderSize from "../../components/TagFunderSize";
import TagFunderType from "../../components/TagFunderType";
import TagInvitationOnly from "../../components/TagInvitationOnly";
import TagPreviousFunder from "../../components/TagPreviousFunder";
import { TooltipWrapper } from "../../components/TooltipWrapper";
import WhyMatched from "../../components/WhyMatched";
import FunderContact from "../../components/funder/FunderContact";
import FunderGeographicFocus from "../../components/funder/FunderGeographicFocus";
import FunderGrantAmountOverview from "../../components/funder/FunderGrantAmountOverview";
import FunderStageAnalysis from "../../components/funder/FunderStageAnalysis";
import FunderTaxonomy from "../../components/funder/FunderTaxonomy";
import FunderWealthInformation from "../../components/funder/FunderWealthInformation";
import PastRecipients from "../../components/funder/PastRecipients";
import Slider from "../../components/tailwind/Slider";
import useFunderContact from "../../hooks/funder/useFunderContact";
import useFunderWealth from "../../hooks/funder/useFunderWealth";
import useSave from "../../hooks/save/useSave";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";
import useIsPreviousFunder from "../../hooks/search/useIsPreviousFunder";
import useFunderSummary from "../../hooks/useFunderSummary";
import useRateLimit from "../../hooks/useRateLimit";
import useScrollTracking from "../../hooks/useScrollTracking";
import { ASSET_SIZE_OPTION } from "../../types/searchFilter";
import {
  renderRow,
  reprFunderPreferredStages,
  reprFunderTotalGrantCount,
} from "../../utils/funder";
import { ReprServiceLocations } from "../../utils/grant";
import RenderIncrOrDecr from "./components/RenderIncrOrDecr";

import usePageTitle from "../../hooks/usePagetitle";

import BackButton from "../../components/BackButton";
import ClickMeTooltip from "../../components/ClickMeTooltip";
import SignInModal from "../../components/SignInModal";
import TryLetterCard, { TryLoiButton } from "../../components/TryLetterCard";
import useFunderTags from "../../hooks/funder/useFunderTags";
import useGibooMixpanel from "../../hooks/useGibooMixpanel";
import { Buttons, FROM_FOR_MIXPANEL, MIXPANEL_EVENTS_V2 } from "../../mixpanel/mixpanel";
import { setSelectedQueryFunc } from "../../utils/search";

interface DonorDetailViewState {
  query?: SearchQuery;
  nameMatch?: boolean;
  gids?: string[];
  from_for_mixpanel?: FROM_FOR_MIXPANEL;
}

type NavItem =
  | "general_info"
  | "tags"
  | "funding_strategy"
  | "phil_focus"
  | "funder_financial"
  | "grant"
  | "contact";
const LAST_N_YEARS = 6;
function DonorDetailView({
  withoutLogin = false,
  query,
  compact = false,
  donorId,
  nameMatch,
  gids,
  noOfGrantClick,
  showGuidance = false,
  fromProjectMgt = false,
}: {
  withoutLogin?: boolean;
  query?: SearchQuery;
  compact?: boolean;
  donorId?: string;
  showStickyHeader?: boolean;
  nameMatch?: boolean;
  gids?: string[];
  noOfGrantClick?: number;
  showGuidance?: boolean;
  fromProjectMgt?: boolean;
}) {
  // useScrollTopByDefault();
  const mxEvent = useGibooMixpanel("detail_view");
  const { state: context } = useLocation() as { state: DonorDetailViewState };
  const { ok, isLoading: isRateLimitLoading, reset: resetRateLimit } = useRateLimit(withoutLogin);
  const setSignUpSignin = useSetRecoilState(openSigninSignup);
  const { id: paramId } = useParams();
  const id = donorId || paramId;
  const [previousId, setPreviousId] = useState<string | undefined>(id);
  useLayoutEffect(() => {
    const parentContainerId = compact ? "search-result-main" : undefined;
    if (id !== previousId) {
      (parentContainerId ? document.getElementById(parentContainerId) : window)?.scrollTo(0, 0);
    }
    setPreviousId(id);
  }, [id]);
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const [selectedQuery, setSelectedQuery] = useState<SearchQuery>(
    setSelectedQueryFunc(onboardingData, query, context, user),
  );
  const hasNoTaxonomy =
    selectedQuery.focus_area.length === 0 &&
    selectedQuery.beneficiary.length === 0 &&
    selectedQuery.program.length === 0 &&
    selectedQuery.hq_loc.length === 0 &&
    selectedQuery.service_loc.length === 0;
  useEffect(() => {
    setSelectedQuery(
      query
        ? query
        : context?.query
        ? context.query
        : getSearchQueryFromISearchParam(
            user?._id || "",
            onboardingData,
            getISearchParamFromOnboardingData(onboardingData),
          ),
    );
  }, [query, context, user, onboardingData]);
  const {
    data: matched,
    dataAll: funder,
    isLoading: isFunderLoading,
    dataUnMatched: unMatched,
  } = useFunderSearchResult(id, selectedQuery);
  const { data: funderTags, isLoading: isFunderTagsLoading } = useFunderTags(id);
  const setTargetId = useSetRecoilState(sidekickTargetId);
  const setTargetType = useSetRecoilState(sidekickTargetType);
  useEffect(() => {
    if (!id || !funder) {
      setTargetId("");
    } else {
      setTargetId(id);
      setTargetType(1);
    }
    return () => {
      setTargetId("");
      setTargetType(-1);
    };
  }, [funder, id]);
  const { data: hasGranted } = useIsPreviousFunder(funder?._id);
  !query && usePageTitle(funder ? funder?.name : "Funder");
  const { data: currentSavedList } = useSave(id, 0);
  const [contactLoading, setContactLoading] = useState<boolean>(false);
  const leftContentRef = useRef<HTMLDivElement>(null);
  const { data: contact } = useFunderContact(id);
  const [showFundraising, setShowFundraising] = useState<boolean>(false);
  const [showSave, setShowSave] = useState<boolean>(false);
  //nav
  const { data: wealthInformation, isLoading: isWealthInfoLoading } = useFunderWealth(
    id,
    LAST_N_YEARS,
  );
  const { newProject, projects, isCreating } = useProjectCreation();
  const setSidekickStartFundraisingLoading = useSetRecoilState(sidekickStartFundraisingLoading);
  useEffect(() => {
    setSidekickStartFundraisingLoading(isCreating);
  }, [isCreating]);
  const { data: summary, isLoading: isSummaryLoading } = useFunderSummary(funder?._id);
  const globalLoading = isFunderLoading || isWealthInfoLoading || isSummaryLoading;
  //  STICKY NAV BAR START
  const [showStickyNav, setShowStickyNav] = useState<boolean>(false);
  const stickyHeaderRef = useRef<HTMLDivElement>(null);
  const [activeLink, setActiveLink] = useState<NavItem>();
  const elementIds = [
    "general_info",
    "tags",
    "phil_focus",
    "funder_financial",
    "funding_strategy",
    "grant",
    "contact",
  ];
  const parentContainerId = compact ? "search-result-main" : undefined;
  const NAV_SCROLL_OFFSET =
    (stickyHeaderRef?.current?.offsetHeight || 65) + 54 + (compact ? 0 : +16);
  const { topElement, scrollY, scrollToTop, scrollTo, setTopElement, showNav } = useScrollTracking({
    parentElementId: parentContainerId,
    childElementIds: elementIds,
    offset: NAV_SCROLL_OFFSET,
    headerElementId: "grant-header",
    dependency: id,
  });
  // const { scrollTo } = useScrollTo(parentContainerId, NAV_SCROLL_OFFSET-100);
  const setSidekickDetailViewScroll = useSetRecoilState(sidekickDetailViewScroll);
  useEffect(() => {
    if (scrollY > 0) setSidekickDetailViewScroll(true);
  }, [scrollY]);
  useEffect(() => {
    setSidekickDetailViewScroll(false);
  }, [id]);
  useEffect(() => {
    const scrollThreshold = 570;
    setShowStickyNav(showNav);
    topElement && setActiveLink(topElement as NavItem);
  }, [topElement, scrollY, showNav]);
  useLayoutEffect(() => {
    if (id) {
      scrollToTop();
      setTopElement(elementIds[0]);
      setShowStickyNav(false);
    }
  }, [id]);
  // STICKY NAV BAR END
  const setMatchingSummary = useSetRecoilState(matchingSummaryState);

  useEffect(() => {
    if (!id) return;
    setMatchingSummary({});
  }, [id]);

  const setSidekickSearchQuery = useSetRecoilState(sidekickSearchQuery);
  const setSidekickSearchByName = useSetRecoilState(sidekickSearchByName);
  useEffect(() => {
    setSidekickSearchQuery(selectedQuery);
  }, [selectedQuery]);
  useEffect(() => {
    setSidekickSearchByName(nameMatch || context?.nameMatch ? true : false);
  }, [nameMatch, context]);
  // console.log("solicitateGrants", solicitateGrants);
  // console.log("matchedContext", context, projectData, matchedContext, philanthropy);

  // const grantHistoryWords: Word[] =
  //   grantHistory
  //     ?.map((item) => ({
  //       text: item.grant_description,
  //       value: item.npos.reduce((prev, cur) => prev + cur.amount, 0),
  //     }))
  //     .sort((a, b) => b.value - a.value)
  //     .slice(0, 50) || [];
  // console.log(grantHistory); //, grantHistoryWords);

  // useScrollToHashElement("smooth", [funder]);
  const renderHealthInfo = () => {
    const latestYearData: Wealth = wealthInformation[wealthInformation.length - 1];
    const previousYearData: Wealth =
      wealthInformation.length > 1
        ? wealthInformation[wealthInformation.length - 2]
        : wealthInformation[0];
    return (
      <div className={classNames("flex gap-4")}>
        <div
          className={classNames(
            "font-poppings flex w-full flex-col gap-[4px] rounded border border-gray-300  p-4",
          )}
        >
          <h5 className=" text-sm text-gray-700">Total asset in {latestYearData.tax_year}</h5>
          <h3>{toUsdLong(latestYearData?.assets || 0)}</h3>
          <RenderIncrOrDecr
            value={getPercentageDiff(previousYearData.assets, latestYearData.assets)}
          />
        </div>
        <div
          className={classNames(
            "font-poppings flex w-full flex-col gap-[4px] rounded border border-gray-300 p-4",
          )}
        >
          <h5 className=" text-sm text-gray-700">
            Total contribution in {latestYearData.tax_year}
          </h5>
          <h3>{toUsdLong(latestYearData?.contribution || 0)}</h3>
          <RenderIncrOrDecr
            value={getPercentageDiff(previousYearData.contribution, latestYearData.contribution)}
          />
        </div>
      </div>
    );
  };
  const renderHeader = () => {
    const q = selectedQuery;
    return (
      <div
        id="grant-header"
        className={classNames("border-gray-300  bg-white", compact ? "border-0" : "rounded border")}
      >
        {renderProfile(true)}
        <div className={classNames("flex flex-col", fromProjectMgt && "hidden")}>
          <div className={classNames("flex px-5")}>
            <Button
              className={classNames("!h-9 !w-[224px] !px-4")}
              id="GA_TAG_click_startfundraising"
              loading={isCreating}
              handleOnClick={() => {
                // if (projects.isFetched && projects.data.length === 0) {
                //   id &&
                //     newProject({
                //       id: id,
                //       funder: funder,
                //       initialName: true,
                //       query: selectedQuery,
                //       type: "FUNDER",
                //     });
                //   return;
                // }
                withoutLogin
                  ? setSignUpSignin({ show: true })
                  : setShowFundraising((prev) => !prev);
                mxEvent(MIXPANEL_EVENTS_V2.detail_view.button.clicked, {
                  button: Buttons.FUNDRAISING,
                  location: location.pathname,
                  targetType: "funder",
                  targetName: funder?.name,
                  targetId: funder?._id,
                });
              }}
              label="Start fundraising"
              appendIcon={
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.41406 5.5L13.4141 10.5L8.41406 15.5"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
            <Divider vertical className="mx-4 h-[37px]" />
            <RoundButton
              id={"GA_TAG_click_save"}
              handleOnClick={() => {
                withoutLogin ? setSignUpSignin({ show: true }) : setShowSave(true);
              }}
              ariaValueText={id}
              ariaDescribedBy="in_detail_view"
              bordered={false}
              type="secondary"
              size="md"
              icon={
                <i
                  className={
                    currentSavedList.length > 0
                      ? "gi-md gi-heart-full !ml-[-0.5px] text-purple-500"
                      : "gi-md gi-heart !ml-[-0.5px] text-purple-500"
                  }
                />
              }
              tooltip={currentSavedList.length > 0 ? "Unsave" : "Save"}
            />
            <Divider vertical className="mx-4 h-[37px]" />
            <ShareButton
              isRoundButton
              bordered={false}
              initialUrl={`${window.location.origin}/donors/${id}`}
              onClick={() => {
                mxEvent(MIXPANEL_EVENTS_V2.detail_view.button.clicked, {
                  button: Buttons.SHARE,
                  location: location.pathname,
                  targetType: "funder",
                  targetName: funder?.name,
                  targetId: funder?._id,
                });
              }}
            />
          </div>
          {!nameMatch && !context?.nameMatch && selectedQuery && funder?._id ? (
            <>
              <Divider className={classNames("mx-5 my-6")} />
              <div className="px-5">
                <div className={classNames(" flex w-full gap-3 ")}>
                  <ClickMeTooltip
                    id="click-me-donor-why-match"
                    position="right"
                    open={withoutLogin}
                    delay={3000}
                  >
                    <WhyMatched
                      {...(withoutLogin && { additionalComponent: <TryLetterCard /> })}
                      query={selectedQuery}
                      funder_id={funder?._id}
                      funder_uid={funder?.uid}
                      type={"funder"}
                      nameMatch={nameMatch || context?.nameMatch ? true : false}
                      gids={gids || context?.gids}
                      onClick={() => {
                        mxEvent(MIXPANEL_EVENTS_V2.detail_view.button.clicked, {
                          button: Buttons.WHY_MATCHED,
                          location: location.pathname,
                        });
                      }}
                    />
                  </ClickMeTooltip>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <Divider className={classNames("mb-0 mt-[30px]", fromProjectMgt && "!mt-0")} />
        {renderNav()}
      </div>
    );
  };
  const renderNav = (isSticky = false) => {
    const renderItem = (
      label: string,
      value: NavItem,
      selectedDefault = false,
      scrollValue = 0,
    ) => {
      return (
        <div
          id={`nav-${value}`}
          className={classNames(
            "h-[41px] cursor-pointer gap-3 whitespace-nowrap font-poppins text-sm",
            activeLink === value || selectedDefault
              ? "border-b-[2px]  border-purple-500  font-semibold text-purple-500"
              : " !font-normal !text-gray-700",
          )}
          onClick={() => {
            scrollTo(value, scrollValue);
          }}
        >
          {label}
        </div>
      );
    };
    return (
      <div className="inline-flex h-full w-full items-center gap-4 overflow-x-scroll px-4 scrollbar-none">
        <Slider
          slides={[
            renderItem("About funder", "general_info", !activeLink),
            renderItem("Tags", "tags", !activeLink),
            renderItem("Philanthropic focus", "phil_focus"),
            renderItem("Financial", "funder_financial"),
            renderItem("Funding stage", "funding_strategy"),
            renderItem("Grant", "grant", false, 2),
            renderItem("Contact", "contact", false, 2),
          ]}
          showFeatureIntro={false}
          wrapperClass="w-full mt-4"
          hideArrow={!compact}
          leftArrowClass="!-left-2 !top-[10px]"
          rightArrowClass="!-right-2 !top-[10px]"
          iconColor="purple"
          targetId={`nav-${topElement}`}
        />
        {!compact && isSticky && withoutLogin && <TryLoiButton fromNav />}
      </div>
    );
  };
  const renderLeftContainer = () => {
    const height = (leftContentRef.current?.clientHeight || 0) - 70;
    return (
      <>
        <div
          style={{ top: `-${height}px` }}
          className={classNames("sticky flex w-[384px] flex-col items-center justify-start gap-4")}
        >
          <div className={classNames("max-h-[1200px] w-full  overscroll-contain")}>
            <SimilarToFunder exclude={id ? [id] : []} query={selectedQuery} />
          </div>
        </div>
      </>
    );
  };
  const renderProfile = (noBorder = false) => {
    return (
      <div
        className={classNames(
          "loading-light-shimmer w-full border-gray-300 p-5",
          compact ? "border-0" : "rounded border bg-white",
          noBorder && "!border-none",
          isFunderLoading && "loading-light-shimmer-on",
        )}
      >
        <div className="inline-flex w-full justify-between gap-2">
          <div className="flex flex-nowrap items-center gap-2">
            {funder && <TagFunderType funder_type={funder.funder_type} />}
            {hasGranted ? <TagPreviousFunder /> : null}
            {selectedQuery &&
              selectedQuery.funder_assets.length > 0 &&
              selectedQuery.funder_assets.length < ASSET_SIZE_OPTION.length &&
              funder && <TagFunderSize assets={funder.assets} className="mb-1 w-fit" />}
          </div>
          {compact && (
            <PlainButton
              className="!m-0 min-w-[120px] !p-0 !text-sm !text-purple-500"
              label="Full view"
              handleOnClick={() => {
                navigate(`/donors/${id}`, {
                  state: {
                    query: selectedQuery,
                    nameMatch: nameMatch || context?.nameMatch ? true : false,
                    gids: gids || context?.gids,
                    from_for_mixpanel: location.pathname.includes("/search")
                      ? "search"
                      : location.pathname.includes("/saved")
                      ? "saved_list"
                      : undefined,
                    showBack: true,
                  },
                });
              }}
              leftIconClass="gi-sm gi-open"
              id={`btn-view-full`}
            />
          )}
        </div>
        <div className="loading-light-shimmer-child my-3 flex items-start justify-between">
          <div className="">
            <span
              className={classNames(
                "line-clamp-5 min-w-[200px] text-xl font-semibold text-purple-500",
              )}
            >
              {funder?.name}
            </span>
          </div>
        </div>
        {funder && funder.does_not_accept_unsolicited !== true && (
          <TagInvitationOnly invitationOnly={false} />
        )}
      </div>
    );
  };
  const renderBasicDetail = (noBorder = false) => {
    const renderItem = (icon: string, label: string, value: ReactNode | string | number) => {
      return (
        <div
          className={classNames(
            "gap-[40px]",
            compact ? "flex flex-row" : "grid grid-cols-[96px_1fr]",
          )}
        >
          <div className={classNames("flex  gap-[10px]", compact ? "min-w-[100px]" : "w-[96px]")}>
            <div className="h-5 w-5">
              <img src={icon} className="bg-contain" />
            </div>
            <div className="font-poppins text-sm text-gray-700">{label}</div>
          </div>
          <div
            className="line-clamp-3 w-full text-left"
            title={typeof value == "string" ? value : ""}
          >
            <div className="font-poppins text-base font-normal text-purple-900">{value}</div>
          </div>
        </div>
      );
    };
    const emailAddress = contact?.general?.email || contact?.loi?.email;
    const website = funder?.website;
    return (
      <WhiteContainer
        wrapperClass={classNames(
          "!my-0 min-h-fit w-full",
          compact ? "rounded-none border-t border-b border-x-0 !my-4 !p-0" : "!p-0",
          noBorder && "!border-none",
        )}
      >
        <div className="flex flex-col gap-y-3">
          {funder?.address && renderRow("Address", funder.address)}
          {renderRow(
            "Website",
            website ? (
              <span className="text-gray-900">
                <a
                  target="_blank"
                  rel="noreferrer"
                  title={website}
                  className="text-xm cursor-pointer break-all font-poppins !font-normal !text-gray-900 hover:!text-purple-500"
                  href={website.includes("://") ? website : "//" + website}
                >
                  {website}
                </a>
              </span>
            ) : undefined,
            true,
          )}
          {funder?.service_loc &&
            funder.service_loc.length > 0 &&
            renderRow(
              "Service areas",
              <ReprServiceLocations
                service_loc={funder.service_loc}
                detail={false}
                max={5}
                selected={false}
                scrollFunc={() => scrollTo("geograhpical-focus")}
                query={selectedQuery}
              />,
            )}
          {funder ? renderRow("Funded stage", reprFunderPreferredStages(funder, true), true) : null}
          {funder
            ? renderRow(
                "Funding size",
                `${toUsdLong(funder.grant_amount_min || 0)} - ${toUsdLong(
                  funder.grant_amount_max || 0,
                )}`,
              )
            : null}
          {funder
            ? renderRow(
                "Number of grants made",
                <span className="font-normal">
                  {reprFunderTotalGrantCount(funder).toLocaleString()}
                  {funder.grant_year_max && (
                    <span className="!text-xs text-gray-700">{` (Year: ${
                      funder.grant_year_min === funder.grant_year_max
                        ? funder.grant_year_max
                        : `${funder.grant_year_min}-${funder.grant_year_max}`
                    })`}</span>
                  )}
                </span>,
              )
            : null}
        </div>
      </WhiteContainer>
    );
  };
  const renderFeedback = () => {
    return <Feedback type="FUNDER_ANALYSIS" ratingType="TEXT" />;
  };
  const renderGeographicFocus = useCallback(() => {
    return (
      <div className="flex flex-col gap-5" id="chart_view" aria-valuetext="geographic-focus">
        <div className="border-b border-gray-300 ">
          <h5 className="text-base font-semibold underline underline-offset-[6px]">
            Geographic focus
          </h5>
        </div>
        {id && <FunderGeographicFocus funder_id={id} query={selectedQuery} compact={compact} />}
      </div>
    );
  }, [id, selectedQuery]);

  const renderTaxonomy = () => {
    return (
      <>
        <div className="inline-flex h-[27px] w-fit items-center gap-2 rounded bg-purple-50 px-2 py-[6px]">
          <span className="grid place-items-center">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.36305 9.57445L12.8311 13.0415M0.852051 4.12445L2.52505 4.57345M1.56205 8.14945L2.78705 6.92545M3.78805 1.18945L4.23605 2.86145M7.81305 1.89945L6.58805 3.12345M7.26005 13.0345L4.79505 5.65245C4.76549 5.56427 4.76111 5.4696 4.7824 5.37907C4.80369 5.28853 4.8498 5.20573 4.91557 5.13997C4.98133 5.07421 5.06413 5.02809 5.15466 5.0068C5.2452 4.98551 5.33987 4.98989 5.42805 5.01945L12.8101 7.48245C12.9102 7.51603 12.9971 7.58044 13.0583 7.66645C13.1196 7.75245 13.152 7.85564 13.151 7.96122C13.15 8.0668 13.1156 8.16936 13.0527 8.25419C12.9899 8.33902 12.9018 8.40177 12.8011 8.43345L9.55605 9.45346C9.47917 9.47737 9.4092 9.5195 9.3521 9.57625C9.29499 9.63301 9.25244 9.70272 9.22805 9.77945L8.21005 13.0255C8.17861 13.1265 8.1159 13.215 8.03095 13.2782C7.946 13.3414 7.84319 13.376 7.73733 13.377C7.63147 13.378 7.52803 13.3453 7.4419 13.2838C7.35577 13.2222 7.29139 13.1349 7.25805 13.0345H7.26005Z"
                stroke="#5C38A7"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span className="font-poppins text-[10px] text-purple-500">
            Click the tags below to view past grant information
          </span>
        </div>
        {!hasNoTaxonomy && (
          <PhilanthropicInformationFoldable
            className="!p-0"
            id={`matched-${funder?._id}`}
            title={`Matched tags`}
            globalOpen={true}
            arrowPosition="start"
            focusArea={matched?.focus_area}
            beneficiary={matched?.beneficiary}
            program={matched?.program}
            location={matched?.service_loc}
            priorityLocation={query?.must_service_loc}
            secondaryPriorityLocation={query?.service_loc}
            allowPastGrantSearch
            funder_id={id}
            funder_uid={funder?.uid}
            searchQuery={selectedQuery}
            showFundedOverview
          />
        )}
        {process.env.REACT_APP_DISABLE_VIEW_ALL_OTHER_KEYWORDS === "TRUE" ? null : (
          <PhilanthropicInformationFoldable
            className="!p-0"
            id={`unmatched-${funder?._id}`}
            title={`${!hasNoTaxonomy ? "Other tags" : "Tags"}`}
            initialShow={!hasNoTaxonomy ? undefined : 20}
            tagMatched={!hasNoTaxonomy ? false : true}
            arrowPosition="start"
            focusArea={unMatched?.focus_area}
            beneficiary={unMatched?.beneficiary}
            program={unMatched?.program}
            location={unMatched?.service_loc}
            allowPastGrantSearch
            funder_id={id}
            searchQuery={selectedQuery}
            enableGroupLocation
          />
        )}
        {funder?.uid && id && selectedQuery && (
          <>
            <FundedAmount query={selectedQuery} funder_id={id} funder_uid={funder?.uid} />
          </>
        )}
      </>
    );
  };
  const renderTags = () => {
    return (
      <div
        id="tags"
        className={classNames(
          "flex w-full flex-col gap-4 border-gray-300 bg-white p-5",
          !compact && "rounded border",
        )}
      >
        <h4>Tags</h4>
        {renderTaxonomy()}
      </div>
    );
  };
  if (withoutLogin) {
    if (isRateLimitLoading)
      return (
        <div className="flex h-full w-full items-center justify-center">
          <FullScreenLoading />
        </div>
      );
  }

  return (
    <>
      {withoutLogin && ok && <SignInModal showDefault={ok} reset={resetRateLimit} />}
      {showFundraising && id && (
        <StartFundRaisingModal
          from_for_mixpanel={
            compact
              ? location.pathname.includes("/search")
                ? "search"
                : location.pathname.includes("/saved")
                ? "saved_list"
                : "detail_view"
              : context?.from_for_mixpanel
              ? context?.from_for_mixpanel
              : "detail_view"
          }
          open={showFundraising}
          setOpen={setShowFundraising}
          id={id}
          type="FUNDER"
        />
      )}
      {showSave && id && (
        <SavePopup
          open={showSave}
          onClose={() => setShowSave(false)}
          target_id={id}
          target_type={0}
          searchQuery={selectedQuery}
          compact={compact}
        />
      )}
      {showStickyNav && !compact && (
        <Portal>
          <div
            className={classNames(
              "fixed top-[58px]  z-[10] h-[61px] w-full items-end bg-white shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)]",
            )}
          >
            <div className="container relative mx-auto h-full max-w-[1275px] items-end">
              {renderNav(true)}
            </div>
          </div>
        </Portal>
      )}
      {showStickyNav && compact && stickyHeaderRef?.current && (
        <div
          style={{ top: `${stickyHeaderRef?.current?.getBoundingClientRect().height || 65}px` }}
          className={classNames(
            "sticky z-[9] min-h-[30px] w-full border-b border-gray-300 bg-white px-0 pb-0 transition-all ease-linear",
            showStickyNav ? "block" : "hidden",
          )}
        >
          {renderNav()}
        </div>
      )}
      {compact && (
        <div
          ref={stickyHeaderRef}
          className={classNames(
            "sticky top-0 z-[9] min-h-[30px] w-full border-b border-gray-300 bg-white px-5 py-3  transition-all ease-linear",
            showStickyNav ? "block" : "hidden",
          )}
        >
          <div className="inline-flex w-full items-center justify-between">
            <p className="info-name max-w-[600px] whitespace-pre-line py-2 font-poppins text-base font-semibold text-purple-500">
              {funder?.name}
            </p>
            <div className={classNames("flex gap-2", fromProjectMgt && "hidden")}>
              <RoundButton
                id={"GA_TAG_click_startfundraising"}
                handleOnClick={() => {
                  withoutLogin
                    ? setSignUpSignin({ show: true })
                    : setShowFundraising((prev) => !prev);
                  mxEvent(MIXPANEL_EVENTS_V2.detail_view.button.clicked, {
                    button: Buttons.FUNDRAISING,
                    location: location.pathname,
                    targetType: "funder",
                    targetName: funder?.name,
                    targetId: funder?._id,
                  });
                }}
                size="md"
                icon={
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.41406 5.5L13.4141 10.5L8.41406 15.5"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                tooltip="Start fundraising"
              />
              <RoundButton
                id={"GA_TAG_click_save"}
                handleOnClick={() => {
                  withoutLogin ? setSignUpSignin({ show: true }) : setShowSave(true);
                }}
                ariaValueText={id}
                ariaDescribedBy="in_detail_view"
                type="secondary"
                size="md"
                icon={
                  <i
                    className={
                      currentSavedList.length > 0
                        ? "gi-md gi-heart-full !ml-[-0.5px] text-purple-500"
                        : "gi-md gi-heart !ml-[-0.5px] text-purple-500"
                    }
                  />
                }
                tooltip={currentSavedList.length > 0 ? "Unsave" : "Save"}
              />
              <ShareButton
                isRoundButton
                initialUrl={`${window.location.origin}/donors/${id}`}
                onClick={() => {
                  mxEvent(MIXPANEL_EVENTS_V2.detail_view.button.clicked, {
                    button: Buttons.SHARE,
                    location: location.pathname,
                    targetType: "funder",
                    targetName: funder?.name,
                    targetId: funder?._id,
                  });
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div className={classNames("container flex w-full flex-col", compact ? "" : "mt-[25px]")}>
        {/* back button */}
        {!compact && (
          <div className="mb-4 w-full">
            <BackButton />
          </div>
        )}
        <div className="flex w-full gap-x-8 pb-2">
          <main className={classNames("w-[800px] overflow-x-hidden overflow-y-hidden")}>
            <div className="relative flex w-full flex-col">
              <div
                className={classNames(
                  "relative flex w-full flex-col ",
                  compact ? "gap-0" : "gap-4",
                )}
              >
                {renderHeader()}
                {/* {!compact && showFeedback && renderFeedback()} */}
                {/* {id && !compact && (
                  <MatchingSummaryText
                    refMatchedGrants={refMatchedGrants}
                    funder_id={id}
                    matchedGrants={[]}
                    matchedGrantsLoading={matchedGrantsLoading}
                    wrapperClass={
                      compact
                        ? "p-5 border-t border-gray-300"
                        : "p-5 rounded border border-gray-300"
                    }
                  />
                )} */}
                {id && (
                  <FunderSummary
                    id="general_info"
                    funder_id={id}
                    showFinancial
                    compact={compact}
                    wrapperClass={
                      compact
                        ? "p-5 border-t border-gray-300"
                        : "p-5 rounded border border-gray-300 bg-white"
                    }
                    renderComponent={() => {
                      return (
                        <>
                          {renderBasicDetail(true)}
                          {!isWealthInfoLoading &&
                            wealthInformation.length > 0 &&
                            renderHealthInfo()}
                        </>
                      );
                    }}
                  />
                )}
                {/* Tags */}
                {compact && <Divider className={classNames("my-[20px]")} />}
                {renderTags()}
                {/* Philanthropy focus */}
                {compact && <Divider className={classNames("my-[20px]")} />}
                {id && (
                  <div
                    id="phil_focus"
                    className={classNames(
                      "p-5 ",
                      !compact && "rounded border border-gray-300 bg-white",
                    )}
                  >
                    <div className="flex items-center gap-1 !pb-4 font-poppins text-xl font-semibold text-black">
                      <h4 className="">Philanthropy focus</h4>
                    </div>
                    {process.env.REACT_APP_DISABLE_TAXONOMY_GRAPH === "TRUE" ? null : (
                      <FunderTaxonomy
                        funder_id={id}
                        query={selectedQuery}
                        compact={compact}
                        // additionalComponent={<>{id && renderGeographicFocus()}</>}
                      />
                    )}
                  </div>
                )}
                {/* financials */}
                {compact && <Divider className={classNames("my-[20px]")} />}
                {id && (
                  <div
                    id="funder_financial"
                    className={classNames(
                      "flex flex-col gap-5 p-5",
                      !compact && "rounded border border-gray-300 bg-white",
                    )}
                  >
                    <div className="flex items-center gap-1 font-poppins text-xl font-semibold text-black">
                      <h4 className="">Financial</h4>
                    </div>
                    {summary?.financial_information && (
                      <>
                        <div>
                          <div className="mb-2 border-b border-gray-300">
                            <h5 className="text-base font-semibold underline underline-offset-[6px]">
                              Financial information
                            </h5>
                          </div>
                          <h5>
                            {typeof summary.financial_information === "string"
                              ? summary.financial_information
                              : JSON.stringify(summary?.financial_information)}
                          </h5>
                        </div>
                      </>
                    )}
                    <div>
                      <div
                        className="mb-2 border-b border-gray-300"
                        id="chart_view"
                        aria-valuetext="wealth-information"
                      >
                        <h5 className="text-base font-semibold underline underline-offset-[6px]">
                          Wealth information
                        </h5>
                      </div>
                      <FunderWealthInformation funder_id={funder?._id || ""} compact={compact} />
                    </div>
                  </div>
                )}
                {/* funding stage */}
                {compact && <Divider className={classNames("my-[20px]")} />}
                {id && (
                  <div
                    id="funding_strategy"
                    className={classNames(
                      "p-5 ",
                      !compact && "rounded border border-gray-300 bg-white",
                    )}
                  >
                    <p className="flex gap-1 !pb-4 font-poppins text-xl font-semibold text-black">
                      Funding stage
                      <TooltipWrapper
                        placements="rightStart"
                        WrapperClass="!p-4 !rounded-md  bg-purple-500 !w-fit"
                        content={
                          <div className="flex gap-3 rounded-md ">
                            <div className="w-[345px]">
                              <p className="text-sm text-white">
                                Funding stage is your key to tailored insights on funder&apos;s
                                preferences across nonprofit stages—Pre-seed & Seed, Startup,
                                Buildup, Super Star, and Hyperstar. Derived from years of tax forms,
                                this data analysis helps your organization gauge suitability,
                                ensuring optimal compatibility for enhanced fundraising success.
                              </p>
                            </div>
                          </div>
                        }
                      >
                        <span className="cursor-pointer p-1">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.16602 7.53609H10.8327V5.86943H9.16602M9.99935 16.7028C6.32435 16.7028 3.33268 13.7111 3.33268 10.0361C3.33268 6.36109 6.32435 3.36943 9.99935 3.36943C13.6743 3.36943 16.666 6.36109 16.666 10.0361C16.666 13.7111 13.6743 16.7028 9.99935 16.7028ZM9.99935 1.70276C8.905 1.70276 7.82137 1.91831 6.81032 2.3371C5.79927 2.75589 4.88061 3.36971 4.10679 4.14354C2.54399 5.70634 1.66602 7.82595 1.66602 10.0361C1.66602 12.2462 2.54399 14.3658 4.10679 15.9286C4.88061 16.7025 5.79927 17.3163 6.81032 17.7351C7.82137 18.1539 8.905 18.3694 9.99935 18.3694C12.2095 18.3694 14.3291 17.4915 15.8919 15.9286C17.4547 14.3658 18.3327 12.2462 18.3327 10.0361C18.3327 8.94174 18.1171 7.85811 17.6983 6.84706C17.2796 5.83602 16.6657 4.91736 15.8919 4.14354C15.1181 3.36971 14.1994 2.75589 13.1884 2.3371C12.1773 1.91831 11.0937 1.70276 9.99935 1.70276ZM9.16602 14.2028H10.8327V9.20276H9.16602V14.2028Z"
                              fill="#5C38A7"
                            />
                          </svg>
                        </span>
                      </TooltipWrapper>
                    </p>
                    <FunderStageAnalysis funder_id={id} compact={compact} />
                  </div>
                )}
                {/* grant new */}
                {compact && <Divider className={classNames("my-[20px]")} />}
                {id && (
                  <div
                    id="grant"
                    className={classNames(
                      "flex flex-col gap-10 p-5",
                      !compact && "rounded border border-gray-300 bg-white",
                    )}
                  >
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center gap-1 font-poppins text-xl font-semibold text-black">
                        <h4 className="">Grant</h4>
                      </div>
                      <div>
                        <div className="mb-3 border-b border-gray-300">
                          <h5 className="text-base font-semibold underline underline-offset-[6px]">
                            Currently solicited grant opportunities
                          </h5>
                        </div>
                        <FunderSolicitedGrantOpportunities
                          funder_id={id}
                          query={selectedQuery}
                          compact={compact}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="mb-3 border-b border-gray-300">
                        <h5 className="text-base font-semibold underline underline-offset-[6px]">
                          Past grant recipients of this funder
                        </h5>
                      </div>
                      <PastRecipients
                        id={`funder-past-recipient-${funder?._id}`}
                        funder_id={funder?._id}
                        funder_uid={funder?.uid}
                        query={selectedQuery}
                        compact={compact}
                      />
                    </div>
                    <div>
                      <FunderGrantAmountOverview funder_id={id} compact={true} />
                    </div>
                  </div>
                )}
                {/* contact */}
                {compact && <Divider className={classNames("my-[20px]")} />}
                {id && (
                  <div
                    id="contact"
                    className={classNames(
                      "p-5 ",
                      !compact && "rounded border border-gray-300 bg-white",
                    )}
                  >
                    <div className="flex items-center gap-1 !pb-4 font-poppins text-xl font-semibold text-black">
                      <h4 className="">Contact information</h4>
                    </div>
                    <FunderContact funder_id={id} compact={compact} withoutLogin={withoutLogin} />
                  </div>
                )}
              </div>
              {!compact && <Spacer height="100px" />}
            </div>
          </main>
          {!compact && (
            <aside className="flex w-[384px] items-start justify-center">
              {renderLeftContainer()}
            </aside>
          )}
        </div>
      </div>
    </>
  );
}

export default DonorDetailView;
