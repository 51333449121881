import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import { TaxonomyConcept } from "../types/taxonomy";
import { getTaxonomies } from "../services/taxonomy.services";
import { TreeItem } from "../components/TreeSelector";
interface TaxonomyTreeState {
  data: TreeItem[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<TaxonomyConcept[] | undefined>;
}
const swrOption = {
  dedupingInterval: 1000 * 3600,
  refreshInterval: 0,
  revalidateIfStale: false,
  keepPreviousData: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}; // 1 hour
function useTaxonomyTree(taxonomy?: "focus_area" | "beneficiary" | "program"): TaxonomyTreeState {
  // this hook only check subtasks' users. not tasks' users
  const url = `taxonomy@${taxonomy}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<TaxonomyConcept[]>((resolve, reject) => reject());
    return getTaxonomies(_url.split("@")[1])
      .then((data) => data as TaxonomyConcept[])
      .then((data) => {
        const parentMap = data.reduce(
          (prev: { [key: string]: string | undefined | null }, cur: TaxonomyConcept) => ({
            ...prev,
            [cur.label]: cur.parent,
          }),
          {},
        );
        const treeItems = data.reduce((prev: TreeItem[], cur: TaxonomyConcept) => {
          const parentList: string[] = [];
          let p: string | undefined | null = cur.parent;
          while (p) {
            parentList.splice(0, 0, p);
            p = parentMap[p];
          }
          return [...prev, { ...cur, parents: parentList }];
        }, []);
        return treeItems;
      });
  };
  const { data, isLoading, error, isValidating } = useSWR<TreeItem[]>(
    taxonomy ? url : null,
    fetch,
    swrOption,
  );
  const revalidate = useCallback(() => mutate<TreeItem[]>(url), [url]);
  return {
    data: data || [],
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useTaxonomyTree;
