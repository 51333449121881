import classNames from "classnames";
import * as React from "react";
import ICON_CLOSE from "../assets/images/close-thin.svg";
import ICON_CLOSE_WHITE from "../assets/images/close-thin-white.svg";
interface CloseButtonProps {
  onClick?: (e: any) => void;
  className?: string;
  white?: boolean;
  size?: "lg" | "md" | "sm";
  style?: React.CSSProperties;
}
function CloseButton({ size = "sm", ...props }: CloseButtonProps) {
  const sizeVariants: any = {
    sm: "!min-h-5 !min-w-5",
    md: "!min-h-6 !min-w-6",
    lg: "!min-h-7 !min-w-7",
  };
  return (
    <span
      onClick={(e) => {
        props?.onClick?.(e);
      }}
      className={classNames("h-5 w-5 cursor-pointer", sizeVariants[size], props.className)}
      style={props.style}
    >
      <img src={props.white ? ICON_CLOSE_WHITE : ICON_CLOSE} className="h-full w-full" />
    </span>
  );
}

export default CloseButton;
