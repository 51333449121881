import axios from "axios";
import { ITaxonomyOntology, ITaxonomyTaggingOperator } from "../types/taxonomy";
import config from "../api";
import { selectTaxonomyUppercaseMapGlobalized } from "../app/store";

const getTaxonomyTaggingForSearch = (
  req: {
    text_query: string;
    mission?: string;
    taxonomy?: "all" | "focus_area" | "beneficiary" | "program";
    topk?: number;
  },
  controller?: AbortController,
) => {
  const map = selectTaxonomyUppercaseMapGlobalized();
  return new Promise<ITaxonomyOntology>((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_TAXONOMY_URL + `/taxonomy/v2/tag`,
        {
          input_text:
            !req.text_query.trim() && req.mission?.trim()
              ? req.mission?.trim()
              : req.text_query.trim(),
          mission: !req.text_query.trim() && req.mission?.trim() ? undefined : req.mission?.trim(),
          taxonomy: req.taxonomy || "all",
          topk: req.topk || 15,
        },
        {
          ...config,
          ...(controller ? { signal: controller.signal } : {}),
        },
      )
      .then(({ data, status }) => {
        if (status === 204)
          resolve({ focus_area: {}, beneficiary: {}, program: {}, service_loc: [] });
        else
          resolve({
            ...data,
            focus_area: Object.keys(data.focus_area).reduce(
              (prev, cur) => ({
                ...prev,
                [Object.keys(map).includes(cur) ? map[cur] : cur]: data.focus_area[cur],
              }),
              {} as { [key: string]: number },
            ),
            beneficiary: Object.keys(data.beneficiary).reduce(
              (prev, cur) => ({
                ...prev,
                [Object.keys(map).includes(cur) ? map[cur] : cur]: data.beneficiary[cur],
              }),
              {} as { [key: string]: number },
            ),
            program: Object.keys(data.program).reduce(
              (prev, cur) => ({
                ...prev,
                [Object.keys(map).includes(cur) ? map[cur] : cur]: data.program[cur],
              }),
              {} as { [key: string]: number },
            ),
            service_loc: [],
          });
      })
      .catch((e) => reject(e));
  });
};
const getTaxonomyTaggingOperatorForSearch = (
  req: {
    text_query: string;
    mission?: string;
    taxonomy: ITaxonomyOntology;
  },
  controller?: AbortController,
) => {
  return new Promise<ITaxonomyTaggingOperator>((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_TAXONOMY_URL + `/algo/v1/query_logical_operators`,
        {
          query:
            !req.text_query.trim() && req.mission?.trim()
              ? req.mission?.trim()
              : req.text_query.trim(),
          mission: !req.text_query.trim() && req.mission?.trim() ? undefined : req.mission?.trim(),
          focus_area: Object.keys(req.taxonomy.focus_area).map((t) => ({ tag: t })),
          beneficiary: Object.keys(req.taxonomy.beneficiary).map((t) => ({ tag: t })),
          program: Object.keys(req.taxonomy.program).map((t) => ({ tag: t })),
        },
        {
          ...config,
          ...(controller ? { signal: controller.signal } : {}),
        },
      )
      .then(({ data, status }) => {
        if (status === 204)
          resolve({ focus_area: [], beneficiary: [], program: [], service_loc: [] });
        else resolve(data);
      })
      .catch((e) => reject(e));
    // .catch((e) =>
    //   setTimeout(() => {
    //     resolve({
    //       focus_area: Object.keys(req.taxonomy.focus_area).map((t, i) => ({
    //         tag: t,
    //         opr: i % 4 == 3 ? "not" : i % 4 == 2 ? "and" : "or",
    //       })),
    //       beneficiary: Object.keys(req.taxonomy.beneficiary).map((t, i) => ({
    //         tag: t,
    //         opr: i % 4 == 3 ? "not" : i % 4 == 2 ? "and" : "or",
    //       })),
    //       program: Object.keys(req.taxonomy.program).map((t, i) => ({
    //         tag: t,
    //         opr: i % 4 == 3 ? "not" : i % 4 == 2 ? "and" : "or",
    //       })),
    //       service_loc: [],
    //     });
    //   }, 5000),
    // );
  });
};
export { getTaxonomyTaggingForSearch, getTaxonomyTaggingOperatorForSearch };
