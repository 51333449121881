import { useEffect, useState } from "react";
import useProject from "./useProject";
import useProjects from "./useProjects";

interface ProjectNameState {
  data?: string;
  isLoading: boolean;
  error?: object;
}
export default function useProjectName(id?: string): ProjectNameState {
  const [{ data: projects, isFetched }] = useProjects();
  const {
    data: project,
    isLoading,
    error,
  } = useProject(isFetched && !projects.find((p) => p._id === id) ? id : undefined);
  const [data, setData] = useState<string>("");
  useEffect(() => {
    setData(project._id ? project.name : projects.find((p) => p._id === id)?.name || "");
  }, [project, projects]);
  return {
    data: data,
    isLoading: isLoading || !isFetched ? true : false,
    error,
  };
}
