import classNames from "classnames";
import { Popover, Whisper } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { OverlayTriggerType } from "rsuite/esm/Overlay/OverlayTrigger";

export function TooltipWrapper({
  children,
  content,
  isControlled = false,
  isOpen = false,
  WrapperClass,
  arrow = true,
  placements = "top",
  controlId = "project-management-tooltip",
  color = "purple",
  textClass,
  Zindex = 1200,
  trigger = "hover",
  enterable,
}: {
  children: React.ReactElement;
  content: string | React.ReactElement;
  isControlled?: boolean;
  isOpen?: boolean;
  arrow?: boolean;
  WrapperClass?: string;
  placements?: TypeAttributes.Placement;
  trigger?: OverlayTriggerType;
  controlId?: string;
  color?: "purple" | "gray" | "white";
  textClass?: string;
  Zindex?: number;
  enterable?: boolean;
}) {
  const colorClass =
    color == "gray" ? "bg-gray-600" : color == "white" ? "bg-white" : "bg-purple-500";
  return (
    <Whisper
      {...(isControlled ? { open: isOpen } : { trigger: trigger })}
      placement={placements}
      controlId={color == "gray" ? "hover-tooltip" : controlId}
      className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)]"
      enterable={enterable}
      speaker={
        <Popover
          prefix="custom-tooltip"
          className={classNames(
            `z-[${Zindex}]`,
            typeof content === "string" ? "px-2 py-1 font-poppins  text-white" : "px-2 py-1",
            colorClass,
            WrapperClass,
          )}
          arrow={arrow}
        >
          {typeof content === "string" ? (
            <h5 className={classNames(textClass)}>{content}</h5>
          ) : (
            content
          )}
        </Popover>
      }
    >
      {children}
    </Whisper>
  );
}
