import { useCallback } from "react";
import {
  IProjectSubtaskNote,
  IProjectSubtaskNoteCreateRequest,
  IProjectSubtaskNoteUpdateRequest,
} from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { useAppDispatch } from "../../app/store";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface ProjectSubtaskNoteState {
  data: IProjectSubtaskNote;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  update: (req: IProjectSubtaskNoteUpdateRequest) => Promise<IProjectSubtaskNote | undefined>;
  deleteThis: () => Promise<boolean>;
  revalidate: () => Promise<IProjectSubtaskNote | undefined>;
}
const defaultProjectSubtaskNote: IProjectSubtaskNote = {
  _id: "",
  subtask_id: "",
  updated_at: "",
  created_at: "",
  content: "",
  mention: [],
};
const getProjectSubtaskNote = async (id: string) => {
  if (!id) return new Promise<IProjectSubtaskNote>((resolve, reject) => reject());
  return axios
    .get(process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/note/${id}`, config)
    .then((res) => res.data as IProjectSubtaskNote);
};
const createProjectSubtaskNote = async (req: IProjectSubtaskNoteCreateRequest) => {
  return axios
    .post(process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/note`, req, config)
    .then((res) => res.data as IProjectSubtaskNote);
};
const updateProjectSubtaskNote = async (id: string, req: IProjectSubtaskNoteUpdateRequest) => {
  if (!id) return new Promise<IProjectSubtaskNote>((resolve, reject) => reject());
  return axios
    .put(process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/note/${id}`, req, config)
    .then((res) => res.data as IProjectSubtaskNote);
};
const deleteProjectSubtaskNote = async (id: string) => {
  if (!id) return new Promise<boolean>((resolve, reject) => reject());
  return axios
    .delete(process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/note/${id}`, config)
    .then((res) => res.data as boolean);
};
function useSubtaskNote(note_id?: string): ProjectSubtaskNoteState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/note/${note_id}`;
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectSubtaskNote>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as IProjectSubtaskNote);
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectSubtaskNote>(
    note_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const update = useCallback(
    async (req: IProjectSubtaskNoteUpdateRequest) => {
      if (!data) return;
      const options = {
        optimisticData: { ...data, ...req },
        rollbackOnError: true,
      };
      return mutate<IProjectSubtaskNote>(
        url,
        axios.put(url, req, config).then((response) => response.data),
        options,
      );
    },
    [url, data],
  );

  const deleteThis = useCallback(async () => {
    if (note_id)
      return deleteProjectSubtaskNote(note_id).then((res) => {
        if (res && data)
          mutate(
            process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/notes/${data?.subtask_id}`,
          );
        return res;
      });
    else return new Promise<boolean>((resolve, reject) => reject());
  }, [note_id, data]);
  const revalidate = useCallback(() => mutate<IProjectSubtaskNote>(url), [url]);
  return {
    data: data || defaultProjectSubtaskNote,
    isLoading,
    error,
    isValidating,
    update,
    deleteThis,
    revalidate,
  };
}
export {
  getProjectSubtaskNote,
  createProjectSubtaskNote,
  updateProjectSubtaskNote,
  deleteProjectSubtaskNote,
};
export default useSubtaskNote;
