import classNames from "classnames";
import React, { CSSProperties, ReactNode } from "react";
import { MentionsInput, Mention, OnChangeHandlerFunc, SuggestionDataItem } from "react-mentions";
import UserProfile from "./UserProfile";
export interface UserMentionsInputProps {
  id: string;
  className?: string;
  handleOnChange: OnChangeHandlerFunc;
  handleOnKeyDown?: (
    event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>,
  ) => void;

  handleOnBlur?: (
    event: React.KeyboardEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  readonly?: boolean;
  placeholder?: string;
  value: string;
  invalid?: boolean;
  disabled?: boolean;
  topPlaceholder?: string;
  defaultValue?: string;
  wrapperClass?: string;
  autoFocus?: boolean;
  onClear?: () => void;
  errorMessage?: string;
  focusClass?: string;
  topLabelClass?: string;
  options: SuggestionDataItem[];
  noOfRows?: number;
  customComponent?: ReactNode;
  onAdd?: (id: string | number, display: string) => void | undefined;
  style?: CSSProperties;
}

const UserMentionsInput = React.forwardRef(function TextInput(
  props: UserMentionsInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const renderSuggestion = (suggestion: SuggestionDataItem) => {
    if (suggestion.id === "CUSTOM" && props?.customComponent) {
      return props.customComponent ? props.customComponent : <>{suggestion.display}</>;
    }
    return (
      <UserProfile
        userId={suggestion.id as string}
        profileImageClass=" !p-0 !text-xs"
        showEmail={false}
        showName={true}
        size={20}
      />
    );
  };
  return (
    <div className={classNames("mb-4 h-fit w-full", props.wrapperClass)}>
      <MentionsInput
        id="user-mention"
        autoFocus={props.autoFocus}
        className={classNames("mentions", props.className)}
        value={props.value}
        style={props.style}
        onChange={props.handleOnChange}
        onKeyDown={props.handleOnKeyDown}
        placeholder={props.placeholder}
        readOnly={props.readonly}
        rows={props.noOfRows}
        disabled={props.disabled}
        nonce="No data"
        a11ySuggestionsListLabel={"Suggested mentions"}
      >
        <Mention
          trigger="@"
          displayTransform={(_, display) => `@${display}`}
          data={props.options}
          className="mentions__mention"
          renderSuggestion={renderSuggestion}
          onAdd={props.onAdd}
        />
      </MentionsInput>
    </div>
  );
});

export default UserMentionsInput;
