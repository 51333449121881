import mixpanel from "mixpanel-browser";
import { MIX_PANEL_TOKEN } from "../App";

const mxPageEvent = (pathName: string, title?: string) => {
  if (!MIX_PANEL_TOKEN) return;
  mixpanel.track("Page Visited", { page: pathName, title: title });
};
export type SidekickLocation =
  | "myhome"
  | "document"
  | "search"
  | "funder_detail_view"
  | "grant_detail_view"
  | "npo_detail_view"
  | "org_profile"
  | "project_dashboard"
  | "project_task"
  | "documentai";
export type FROM_FOR_MIXPANEL =
  | "signup"
  | "signin"
  | "header"
  | "myhome"
  | "how_giboo_works"
  | "quick_links"
  | "onboarding"
  | "search"
  | "detail_view"
  | "user_profile"
  | "org_profile"
  | "sidekick"
  | "project_dashboard"
  | "project_header"
  | "project_detail"
  | "project_left_menu"
  | "project_task_assistant"
  | "owner_in_project_task_creation"
  | "owner_in_project_task_table"
  | "owner_in_project_progress_status"
  | "mention_in_project_task_comment"
  | "mention_in_project_progress"
  | "mention_in_project_progress_comment"
  | "projects"
  | "tasks"
  | "documentai"
  | "task_creation"
  | "saved_list"
  | "welcome_modal"
  | "join_modal"
  | "diversity";
enum Category { // page or popup which has consecutive actions
  HEADER = "header",
  ONBOARDING = "onboarding",
  MYHOME = "myhome",
  ORG_PROFILE = "org_profile",
  MYPROFILE = "myprofile",
  SEARCH = "search",
  FUNDRAISING = "fundraising",
  PROJECT_DASHBOARD = "project_dashboard",
  PROJECT_CREATION = "project_creation",
  DOCUMENTAI = "documentai",
  CRM = "crm",
  FINANCIAL = "financial",
  DIVERSITY = "diversity",
  SUBSCRIPTION = "subscription",
  DETAIL_VIEW = "detail_view",
  FEEDBACK = "feedback",
  REPORT = "report",
  INVITATION = "invitation",
  ORG_VERIFICATION = "org_verification",
  OWNER_VERIFICATION = "owner_verification",
  UPLOAD_IMAGE = "upload_image",
  ASK_GIBOO_AI = "ask_giboo_ai",
  SEARCH_TAG = "search_tag",
  SEARCH_LOCATION = "search_location",
  SIDEKICK = "sidekick",
  AUTH = "auth",
  SAVE = "save",
  INVITED_USER_SESSION = "invited_user_session",
}
enum Objective {
  SELF = "",
  SIGNIN = "signin",
  SIGNUP = "signup",
  INFORMATION = "information",
  PARSING = "parsing",
  FILTER = "filter",

  BUTTON = "button",
  ASK_GIBOO_AI = "ask_giboo_ai", // special case, it's a button though

  ORG_SELECTION = "org_selection",

  TAG_PASTGRANT = "tag_pastgrant",
  TOP_RECIPIENTS = "top_recipients",
  MAP = "map",
  MESSAGE = "message",
  ASSISTANT = "assistant",
  RAISED_AMOUNT = "raised_amount",
  PROGRESS = "progress",

  TASK_ASSISTANT = "task_assistant",

  QUICK_LINKS = "quick_links",
  HOW_GIBOO_WORKS = "how_giboo_works",
  SEARCH_RESULT = "search_result",
  HIDE_SEARCH_RESULT = "hide_search_result",
  RECOMMENDATION_RESULT = "recommendation_result",
  FILE = "file",
  SCALE = "scale",
  ROTATION = "rotation",
  MEMBER = "member",
  NAVIGATE = "navigate",
  SEARCH = "search",
  SEARCH_ORG = "search_org",
  SUGGESTION = "suggestion",
  TAG = "tag",
  LOCATION = "location",
  JOIN = "join",
  OTHER_GRANTS_FROM_THE_SAME_FUNDER = "other_grants_from_the_same_funder",
  SIMILAR_GRANT = "similar_grant",
  // org profile
  SIMILAR_ORG = "similar_org",

  // financial
  INTRO = "intro",
  BUSINESS_MODEL = "business_model",
  TOTAL_CONTRIBUTION = "total_contribution",
  REVENUE = "revenue",
  REVENUE_AND_EXPENSE = "revenue_and_expense",
  EXPENSE = "expense",
  ASSET = "asset",
  MONTH_OF_LIQUID = "month_of_liquid",
  ASSESSMENT_RECOMMENDATION = "assessment_recommendation",

  // diversity
  MEMBER_TYPE = "member_type",
  ETHNICITY = "ethnicity",
  GENDER = "gender",
  AGE = "age",
  SEXUAL_ORIENTATION = "sexual_orientation",
  DISABILITY = "disability",
  RELIGION = "religion",
  PROFESSIONAL = "professional",

  //sidekick
  OPEN = "open",
  CLOSE = "close",
  DO_NOT_SHOW_AGAIN = "do_not_show_again",

  // search
  TYPE = "type",
  SEARCH_TEXT_QUERY = "search_text_query",
  NAME_SEARCH_RESULT = "name_search_result",

  SAVED_ITEM = "saved_item",

  //visibility
  VISIBILITY = "visibility",
}
export enum Information { // sub objective1 (Objective.Information) which goes into property
  ORG_TYPE = "org_type",
  ORG_NAME = "org_name",
  USER_TITLE = "user_title",
  STAGE = "stage",
  MISSION = "mission",
  FOCUS_AREA = "focus_area",
  BENEFICIARY = "beneficiary",
  PROGRAM = "program",
  SERVICE_AREAS = "service_areas",
  SUMMARY = "summary",
  TAGS = "tags",
  LOGO_IMAGE = "logo_image",
  COVER_IMAGE = "cover_image",
  CONTACT = "contact",
  ABOUT_ORG = "about_org",
  ADDRESS = "address",
  PROJECT_NAME = "project_name",
  PROJECT_TIMELINE = "project_timeline",
}
export enum Navigate { // sub objective2 (Objective.NAVIGATE) which goes into property
  HOME = "home",
  PROJECT_CREATION = "project_creation",
  TASK_CREATION = "task_creation",
  PROJECTS = "projects",
  SAVED_LIST = "saved_list",
  TASKS = "tasks",
  ORG_PROFILE = "org_profile",
  USER_PROFILE = "user_profile",
  DOCUMENTAI = "documentai",
  SEARCH_FUNDERS = "search_funders",
  SEARCH_VIRTUAL_GRANTS = "search_virtual_grants",
  SEARCH_OPENCALL_GRANTS = "search_opencall_grants",
  ORG_VERIFICATION = "org_verification",
  OWNER_VERIFICATION = "owner_verification",
  INVITATION = "invitation",
  FINANCIAL = "financial",
  DIVERSITY = "diversity",
  NOTIFICATION = "notification",
  SEARCH_INPUT = "search_input",
  LOGO = "logo",
  FEEDBACK = "feedback",
  REPORT_BUG = "report_bug",
  LOGOUT = "logout",
}
export enum Buttons { // sub objective3 (Objective.Button) which goes into property
  FUNDRAISING = "fundraising",
  SAVE = "save",
  SHARE = "share",
  CONTACT = "contact",
  WHY_MATCHED = "why_matched",
  FULLVIEW = "fullview",
  EXPORT = "export",
  COPY = "copy",
  REGENERATE = "recreate",
  LIKE = "like",
  DISLIKE = "dislike",
  DONE = "done",
}
enum Action {
  CLICKED = "clicked",
  // - include meaning of selected
  // - click navigation item
  // - click one of item in list
  // - click a button

  STARTED = "started",
  // - started long process(more than one step) such as invitation, verification, project creation, diversity

  SUCCESSFUL = "successful",
  // - success long process

  FAILED = "failed",
  // - failed or **skipped** long process

  CREATED = "created",
  // - project, task, document, …

  EDIT = "edit",
  // - edit a previous field of org or project, …
  // - even if it was empty string previously, or adding an item in list

  ADDED = "added",
  REMOVED = "removed",
  // - tag
  // - suggestion, too

  //observe
  OBSERVED = "observed",
}
export const MIXPANEL_EVENTS_V2 = {
  auth: {
    signin: { successful: "auth_signin_successful", failed: "auth_signin_failed" },
    signup: { successful: "auth_signup_successful", failed: "auth_signup_failed" },
  },
  onboarding: {
    "": { successful: "onboarding_successful" },
    search_org: {
      started: "onboarding_search_org_started",
      successful: "onboarding_search_org_successful",
      failed: "onboarding_search_org_failed",
    },
    information: { edit: "onboarding_information_edit" },
    tag: { added: "onboarding_tag_added", removed: "onboarding_tag_removed" },
    location: { added: "onboarding_location_added", removed: "onboarding_location_removed" },
    ask_giboo_ai: { clicked: "onboarding_ask_giboo_ai_clicked" },
  },
  search: {
    name_search_result: { clicked: "search_name_search_result_clicked" },
    search_text_query: { edit: "search_search_text_query_edit" },
    "": {
      started: "search_started",
      successful: "search_successful",
      failed: "search_failed",
    },
    type: {
      edit: "search_type_edit",
    },
    filter: {
      edit: "search_filter_edit",
    },
    search_result: {
      clicked: "search_search_result_clicked",
    },
    hide_search_result: {
      clicked: "search_hide_search_result_clicked",
    },
  },
  myhome: {
    search: { clicked: "myhome_search_clicked" },
    quick_links: { clicked: "myhome_quick_links_clicked" },
    how_giboo_works: {
      clicked: "myhome_how_giboo_works_clicked",
      removed: "myhome_how_giboo_works_removed",
    },
  },
  org_verification: {
    "": {
      started: "org_verification_started",
      successful: "org_verification_successful",
      failed: "org_verification_failed",
    },
    file: {
      successful: "org_verification_file_successful",
      failed: "org_verification_file_failed",
    },
  },
  owner_verification: {
    "": {
      started: "owner_verification_started",
      successful: "owner_verification_successful",
      failed: "owner_verification_failed",
    },
    file: {
      successful: "owner_verification_file_successful",
      failed: "owner_verification_file_failed",
    },
  },
  invitation: {
    "": {
      started: "invitation_started",
      successful: "invitation_successful",
      failed: "invitation_failed",
    },
  },
  ask_giboo_ai: {
    "": {
      successful: "ask_giboo_ai_successful",
    },
    suggestion: {
      successful: "ask_giboo_ai_suggestion_successful",
      failed: "ask_giboo_ai_suggestion_failed",
    },
  },
  search_tag: {
    "": {
      started: "search_tag_started",
      successful: "search_tag_successful",
      failed: "search_tag_failed",
    },
  },
  search_location: {
    "": {
      started: "search_location_started",
      successful: "search_location_successful",
      failed: "search_location_failed",
    },
  },
  upload_image: {
    "": {
      started: "upload_image_started",
      successful: "upload_image_successful",
      failed: "upload_image_failed",
    },
    // file: {
    //   successful: "upload_image_file_successful",
    //   failed: "upload_image_file_failed",
    // },
  },
  header: {
    "": {
      clicked: "header_clicked",
    },
    join: {
      clicked: "header_join_clicked",
    },
    org_selection: { clicked: "header_org_selection_clicked" },
  },
  project_creation: {
    "": {
      started: "project_creation_started",
      successful: "project_creation_successful",
      failed: "project_creation_failed",
    },
    information: {
      edit: "project_creation_information_edit",
      failed: "project_creation_information_failed",
    },
    parsing: {
      started: "project_creation_parsing_started",
      successful: "project_creation_parsing_successful",
      failed: "project_creation_parsing_failed",
    },
  },
  project_dashboard: {
    task_assistant: {
      clicked: "project_dashboard_task_assistant_clicked",
    },
  },
  detail_view: {
    button: { clicked: "detail_view_button_clicked" },
    similar_org: { clicked: "detail_view_similar_org_clicked" },
    similar_grant: { clicked: "detail_view_similar_grant_clicked" },
    other_grants_from_the_same_funder: {
      clicked: "detail_view_other_grants_from_the_same_funder_clicked",
    },
    tag: { clicked: "detail_view_tag_clicked" },
    visibility: { observed: "detail_view_visibility_observed" },
  },
  org_profile: {
    button: { clicked: "org_profile_button_clicked" },
    information: { edit: "org_profile_information_edit" },
    similar_org: { clicked: "org_profile_similar_org_clicked" },
  },
  fundraising: {
    [""]: {
      started: "fundraising_started",
      successful: "fundraising_successful",
    },
  },
  documentai: {
    [""]: {
      started: "documentai_started",
      successful: "documentai_successful",
    },
    message: {
      started: "documentai_message_started",
      successful: "documentai_message_successful",
      failed: "documentai_message_failed",
    },
    button: { clicked: "documentai_button_clicked" },
  },
  sidekick: {
    button: { clicked: "sidekick_button_clicked" },
    open: { clicked: "sidekick_open_clicked" },
    close: { clicked: "sidekick_close_clicked" },
    do_not_show_again: { clicked: "sidekick_do_not_show_again_clicked" },
  },
  financial: {
    "": {
      started: "financial_started",
      successful: "financial_successful",
    },
    intro: {
      started: "financial_intro_started",
      successful: "financial_intro_successful",
    },
    business_model: {
      successful: "financial_business_model_successful",
    },
    total_contribution: {
      successful: "financial_total_contribution_successful",
    },
    revenue: {
      successful: "financial_revenue_successful",
    },
    revenue_and_expense: {
      successful: "financial_revenue_and_expense_successful",
    },
    expense: {
      successful: "financial_expense_successful",
    },
    asset: {
      successful: "financial_asset_successful",
    },
    month_of_liquid: {
      successful: "financial_month_of_liquid_successful",
    },
    assessment_recommendation: {
      clicked: "financial_assessment_recommendation_clicked",
    },
  },
  diversity: {
    "": {
      started: "diversity_started",
      successful: "diversity_successful",
    },
    intro: {
      started: "diversity_intro_started",
      successful: "diversity_intro_successful",
    },
    member_type: {
      successful: "diversity_member_type_successful",
    },
    ethnicity: {
      successful: "diversity_ethnicity_successful",
      failed: "diversity_ethnicity_failed",
    },
    gender: {
      successful: "diversity_gender_successful",
      failed: "diversity_gender_failed",
    },
    age: {
      successful: "diversity_age_successful",
      failed: "diversity_age_failed",
    },
    sexual_orientation: {
      successful: "diversity_sexual_orientation_successful",
      failed: "diversity_sexual_orientation_failed",
    },
    disability: {
      successful: "diversity_disability_successful",
      failed: "diversity_disability_failed",
    },
    religion: {
      successful: "diversity_religion_successful",
      failed: "diversity_religion_failed",
    },
    professional: {
      successful: "diversity_professional_successful",
      failed: "diversity_professional_failed",
    },
  },
  save: {
    saved_item: {
      added: "save_saved_item_added",
      removed: "save_saved_item_removed",
    },
  },
  invited_user_session: {
    [""]: {
      started: "invited_user_session_started",
    },
  },
};
const validate = () => {
  if (process.env.REACT_APP_ENV !== "DEV") return;
  const build_event_name = (e: {
    key1: string;
    key2: string;
    key3: string;
    value: string;
  }): string =>
    e.key2 === Objective.SELF ? `${e.key1}_${e.key3}` : `${e.key1}_${e.key2}_${e.key3}`;
  const parse2 = (m: { [key3: string]: string }): { key3: string; value: string }[] => {
    return Object.entries(m).map(([key, value]) => ({ key3: key, value }));
  };
  const parse1 = (m: {
    [key: string]: { [key3: string]: string };
  }): { key2: string; key3: string; value: string }[] => {
    return Object.entries(m).reduce(
      (prev, [key, value]) => [...prev, ...parse2(value).map((v) => ({ key2: key, ...v }))],
      [] as { key2: string; key3: string; value: string }[],
    );
  };
  const all_events = Object.entries(MIXPANEL_EVENTS_V2).reduce(
    (prev, [key, value]) => [...prev, ...parse1(value).map((v) => ({ key1: key, ...v }))],
    [] as { key1: string; key2: string; key3: string; value: string }[],
  );
  console.log({ all_events });
  const err = all_events.find((e) => e.value !== build_event_name(e));
  if (err) throw new Error(`wrong event definition: ${err.value}!==${build_event_name(err)}`);
  const categories = Object.values(Category);
  const objectives = Object.values(Objective);
  const actions = Object.values(Action);
  const err_cateogry = all_events.find((e) => !categories.includes(e.key1 as Category));
  if (err_cateogry)
    throw new Error(`wrong category (not in enum): ${err_cateogry.key1} in ${err_cateogry.value}`);
  const err_objective = all_events.find((e) => !objectives.includes(e.key2 as Objective));
  if (err_objective)
    throw new Error(
      `wrong objective (not in enum): ${err_objective.key2} in ${err_objective.value}`,
    );
  const err_action = all_events.find((e) => !actions.includes(e.key3 as Action));
  if (err_action)
    throw new Error(`wrong action (not in enum): ${err_action.key3} in ${err_action.value}`);
};
validate();

//Basic Events
const MIXPANEL_EVENTS = {
  SIGN_UP_COMPLETED: `signup_completed`,
  SIGN_IN_COMPLETED: `signin_completed`,
  HOME_PAGE_VIEWED: "hompage_viewed",
  ONBOARDING: {
    ORG_NAME_SELECTED: `org_name_selected`,
    ORG_VERIFICATION_UPLOADED: `org_verification_uploaded`,
    ORG_VERIFICATION_SKIPPED: `org_verification_skipped`,
    TITLE_SELECTED: `title_selected`,
    OWNER_VERIFICATION_UPLOADED: `owner_verification_uploaded`,
    STAGE_SELECTED: `stage_selected`,
    MISSION_STATEMENT_EDITED: `mission_statement_edited`,
    FOCUS_AREA_ENTERED: `focus_area_entered`,
    BENEFICIARY_ENTERED: `beneficiary_entered`,
    PROGRAM_ENTERED: `program_entered`,
    ASK_GIBOO_AI_USED: `ask_giboo_ai_used`,
    SUMMARY_EDITED: `summary_edited`,
    TAGS_SELECTED: `tags_selected`,
    SERVICE_AREAS_TAGS_SELECTED: `service_areas_selected`,
    USER_INVITE_SENT: `user_invite_sent`,
    USER_INVITE_SKIPPED: `user_invite_skipped`,
    ONBOARDING_COMPLETED: `onboarding_completed`,
  },
  // Search
  SEARCH: {
    COMPLETED: "search_completed",
    SEARCH_FILTER_APPLIED: "search_filter_applied",
    SEARCH_TYPE_CLICKED: "search_type_clicked",
    SEARCH_TAGS_CLICKED: "search_tags_clicked",
    SEARCH_LOCATION_CLICKED: "search_location_clicked",
    SEARCH_PLUS_CLICKED: "search_plus_clicked",
    SEARCH_SORT_CLICKED: "search_sort_clicked",
    SEARCH_MSO_CLICKED: "search_mso_clicked",
    // Search results
    RESULTS_CLICKED: "search_result_clicked",
    RESULTS_HIDDEN: "search_result_hidden",
    RESULTS_SAVED: "search_result_saved",
    // Search detail (v.g/o.c)
    START_FUNDRAISING_CLICKED: `start_fundraising_clicked`,
    SAVE_CLICKED: "save_clicked",
    SHARE_CLICKED: "share_clicked",
    WHY_MATCHED_CLICKED: "whymatched_clicked",
    TAG_PAST_GRANT_CLICKED: "tag_pastgrant_clicked",
    FULL_VIEW_CLICKED: "fullview_clicked",
  },
  // Project & DocumentAI
  PROJECT_CREATION: {
    PROJECT_CREATED: `project_created`,
    PROJECT_CREATE_CLICKED: "createaproject_clicked",
  },
  DOCUMENT: {
    OUTREACH_DOCUMENT_CREATED: `document_created`,
    OUTREACH_DOCUMENT_ACTIONS: "outreach_document_actions",
    //document ai -separate evt
    EXPORT_SELECTED: "export_selected",
    COPY_SELECTED: "copy_selected",
    REGENERATE_SELECTED: "regenerate_selected",
    EDIT_SELECTED: "edit_selected",
    LIKE_SELECTED: "like_selected",
    DISLIKE_SELECTED: "dislike_selected",
    DONE_SELECTED: "done_selected",
    GIBOO_AI_MESSAGED: "gibooAI_message_sent",
  },
  PROJECT: {
    PROJECT_INVITED: `project_invitation_sent`,
    ASSISTANT_PROSPECTED_SELECTED: "assistant_prospected_selected",
    ASSISTANT_PROJECT_INFORMATION_SELECTED: "assistant_projectinformation_selected",
    ASSISTANT_OUTREACH_SELECTED: "assistant_outreach_selected",
    RAISED_AMOUNT_ADDED: "raised_amount_added",
    PROJECT_PROGRESS_ADDED: "project_progress_added",
    PROJECT_TASK_ADDED: "project_task_added",
    PROJECT_STATUS_ADDED: "project_status_added",
    PROJECT_NEW_OPPORTUNITIES_ADDED: "project_newopportunities_added",
  },

  // My home
  MY_HOME: {
    // Quicklinks
    HOME_QUICK_LINKS: "quicklinks_actions",
    HOME_NAV_BAR_ACTION: "navigation_bar_actions",
    HOME_HOW_GIBOO_WORKS_ACTION: "howGibooworks_actions",
    // How Giboo works(hgw)-separate evt
    HGW_COMPLETED: "hgw_completed",
    HGW_FIND_GRANT_CLICKED: "hgw_findgrant_clicked",
    HGW_CREATE_PROJECT_CLICKED: "hgw_createproject_clicked",
    HGW_TASK_CLICKED: "hgw_task_clicked",
    HGW_FINANCIAL_CLICKED: "hgw_financial_clicked",
    HGW_DIVERSITY_CLICKED: "hgw_diversity_clicked",
    HGW_FIND_FUNDER_CLICKED: "hgw_findfunder_clicked",
    HGW_INVITE_CLICKED: "hgw_invite_clicked",
    HGW_ORG_PROFILE_CLICKED: "hgw_orgprofile_clicked",
    HGW_VERIFY_CLICKED: "hgw_verify_clicked",
    HGW_PROFILE_CLICKED: "hgw_profile_clicked",
    HGW_REMOVE: "hgw_removed",
    // Navigation bar - separate evt
    NAV_HOME_CLICKED: "nav_home_clicked",
    NAV_MY_PROJECTS_CLICKED: "nav_myprojects_clicked",
    NAV_MY_TASKS_CLICKED: "nav_mytasks_clicked",
    NAV_NOTIFICATION_CLICKED: "nav_notification_clicked",
    NAV_MY_ORG_CLICKED: "nav_myorg_clicked",
    NAV_MY_PROFILE_CLICKED: "nav_myprofile_clicked",
    NAV_SAVED_LIST_CLICKED: "nav_savedlist_clicked",
    NAV_LOGOUT_CLICKED: "nav_logout_clicked",
    NAV_LOGO_CLICKED: "nav_logo_clicked",
    NAV_SEARCH_BAR_CLICKED: "nav_searchbar_clicked",
    NAV_CHANGE_ORG_CLICKED: "nav_changeorg_clicked",
  },

  // My organization profile
  MY_ORG_PROFILE: {
    PROFILE_ACTIONS: "organization_profile_actions",
    // organization profile - separate evt
    INVITATION_SENT: `orgprofile_org_invitation_sent`,
    VERIFICATION_UPLOADED: `orgprofile_org_verification_uploaded`,
    LOGO_UPLOADED: "logo_uploaded",
    COVER_IMAGE_UPLOADED: "coverimage_uploaded",
    DIVERISITY_COMPLETED: "diverisity_completed",
    FINANCIA_COMPLETED: "financial_completed",
    SIMILAR_ORG_CLICKED: "similarorg_clicked",
    ORG_INFO_EDITED: "orginfo_edited",
    ABOUT_ORG_EDITED: "aboutorg_edited",
    TAGS_EDITED: "tags_edited",
    CONTACT_EDITED: "contact_edited",
    PROJECT_CREATED: "org_project_created",
    PROJECT_IMAGE_UPLOADED: "project_image_added",
  },
  MY_PROFILE: {
    PROFILE_ACTIONS: "my_profile_actions",
  },
  // FINANCIAL
  FINANCIAL_ASSESSMENT: {
    COMPLETED: "financial_completed",
    STARTED: "financial_started",
    RE_STARTED: "financial_restarted",
  },
  // DIVERSITY
  DIVERSITY_ASSESSMENT: {
    COMPLETED: "diversity_completed",
    STARTED: "diversity_started",
    RE_STARTED: "diversity_restarted",
  },
  // DIVERSITY
  PUBLIC_VERSION: {
    JOIN_NOW: "public_join_clicked",
    OTHER_OPPORTUNITIES: "otheropportunities_clicked",
  },
};
