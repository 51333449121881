import { Route, Routes, useSearchParams } from "react-router-dom";
import DeleteView from "../DeleteView";
import EmailVerifyView from "../EmailVerifyView";
import HiddenTest from "../HiddenTest";
import NewPasswordView from "../NewPasswordView";
import NoPage from "../NoPage";
import PendingVerifyView from "../PendingVerifyView";
import ResetPasswordView from "../ResetPasswordView";
import SigninView from "../SigninView";
import SignupIntroView from "../SignupIntroView";
import SignupView from "../SignupView";
import VersionView from "../VersionView";
import WelcomeLayout from "../WelcomeLayout";
import PrivacyPolicyView from "../npo/PrivacyPolicyView";
import TermsAndConditionView from "../npo/TermsAndConditionsView";
import InvitationCodeView from "../InvitationCodeView";
import useLogin from "../../hooks/useLogin";
import NpoLayout from "../npo/dashboard/NpoLayout";
import NpoView from "../npo/dashboard/pages/NpoView";
import Donors from "../npo/Donors";
import DonorDetailView from "../donor-detail-view/DonorDetailView";
import PastGrantView from "../npo/grant/PastGrantView";
import GrantView from "../npo/grant/GrantView";
import Layout from "../npo/Layout";
import SearchResultView from "../SearchResultView";
import RateLimitOverviewView from "../test/RateLimitOverviewView";
import AcceptInvitationView from "../AcceptInvitationView";

export function WelcomeRoutes() {
  const { isLoading } = useLogin(false);
  return (
    <Routes>
      <Route path="/" element={<WelcomeLayout />}>
        <Route index element={<SigninView />} />
        <Route path="signin" element={<SigninView />} />
        {process.env.REACT_APP_DISABLE_SIGNUP !== "TRUE" && (
          <>
            <Route path="forgetpassword" element={<ResetPasswordView />} />
            <Route path="resetpassword/:resettoken" element={<NewPasswordView />} />
            {/* <Route path="agreement" element={<SignupIntroView />} /> */}
            <Route path="signup" element={<SignupView />} />
            <Route path="pending" element={<PendingVerifyView />} />
            <Route path="invitation" element={<InvitationCodeView />} />
          </>
        )}
      </Route>
      <Route path="/" element={<Layout withoutLogin />}>
        <Route path="search" element={<SearchResultView withoutLogin />}>
          <Route path=":id" element={<SearchResultView withoutLogin />} />
        </Route>
        <Route path="npos" element={<NpoLayout withoutLogin />}>
          <Route path=":id" element={<NpoView withoutLogin />} />
        </Route>
        <Route path="donors">
          <Route path=":id" element={<DonorDetailView withoutLogin />} />
        </Route>
        <Route path="grants">
          <Route path="past">
            <Route path=":id" element={<PastGrantView withoutLogin />} />
          </Route>
          <Route path=":id" element={<GrantView withoutLogin />} />
        </Route>
        {process.env.REACT_APP_ENV === "DEV" && (
          <>
            <Route path="/test/rate_limit" element={<RateLimitOverviewView />} />
          </>
        )}
        <Route path="accept_invitation">
          <Route path=":id" element={<AcceptInvitationView />} />
        </Route>
      </Route>
      <Route path="/verify" element={<WelcomeLayout />}>
        <Route path=":signintoken" element={<EmailVerifyView />} />
      </Route>
      <Route path="version" element={<VersionView />} />
      <Route path="delete" element={<DeleteView />} />
      <Route path="testtest486" element={<HiddenTest />} />
      <Route path="privacy" element={<PrivacyPolicyView />} />
      <Route path="terms" element={<TermsAndConditionView />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}
