import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import config from "../../../api";
import {
  getOnboardingStatus,
  selectBasicInformation,
  selectOnboardingData,
  updateOnboardingAsync,
} from "../../../app/onboardingSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import SearchableDropdown from "../../../components/dropdown/SearchableDropdown";
import LeftInnerIconInput from "../../../components/LeftInnerIconInput";
import PrimaryButton from "../../../components/PrimaryButton";
import PlainDropdownSelector from "../../../components/tailwind/dropdown/PlainDropdownSelector";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import { Information, MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import { ILocation, reprLocation } from "../../../types/location";
import { getStateNames, stateIdToStateName, stateNameToStateId } from "../../../utils/formatHelper";
import OnboardingBack from "./OnboardingBack";

function RequestNPOToAdd({ isNew = false }: { isNew?: boolean }) {
  const mxEvent = useGibooMixpanel();
  const dispatch = useAppDispatch();
  const { type } = useAppSelector((state) => getOnboardingStatus(state));
  const { npo_name, npo_ein, npo_location } = useAppSelector((state) =>
    selectBasicInformation(state),
  );
  const {
    address1: addressRequested1,
    city: city_requested,
    state_id,
    zipcode: zipcode_requested,
    country: country_requested,
    service_loc,
    address2: addressRequested2,
  } = useAppSelector((state) => selectOnboardingData(state));
  const [name, setName] = useState<string>(npo_name || "");
  const [ein, setEin] = useState<string>(npo_ein || "");
  const [address1, setAddress1] = useState<string>(addressRequested1 || "");
  const [address2, setAddress2] = useState<string>(addressRequested2 || "");
  const [state, setState] = useState<string | undefined>(stateIdToStateName(state_id));
  const [zipcode, setZipcode] = useState<string | undefined>(zipcode_requested || "");
  const [city, setCity] = useState<string | undefined>(city_requested || "");
  const [country, setCountry] = useState<string | undefined>(country_requested || "");
  const [locationQuery, setLocationQuery] = useState<string>("");
  const [location, setLocation] = useState<ILocation>({
    level: -1,
    lat: 0,
    lng: 0,
    iso2: "",
  });
  useEffect(() => {
    if (npo_name) setName(npo_name);
  }, [npo_name]);
  useEffect(() => {
    if (npo_ein) setEin(npo_ein);
  }, [npo_ein]);
  useEffect(() => {
    if (addressRequested1) setAddress1(addressRequested1);
  }, [addressRequested1]);
  useEffect(() => {
    if (addressRequested2) setAddress2(addressRequested2);
  }, [addressRequested2]);
  useEffect(() => {
    if (city_requested) setCity(city_requested);
  }, [city_requested]);
  useEffect(() => {
    if (state_id) setState(stateIdToStateName(state_id));
  }, [state_id]);
  useEffect(() => {
    if (zipcode_requested) setZipcode(zipcode_requested);
  }, [zipcode_requested]);
  useEffect(() => {
    if (country_requested) setLocation((prev) => ({ ...prev, country: country_requested }));
  }, [country_requested]);

  useEffect(() => {
    // console.log({ location });
    if (location) {
      setCountry(location.country);
    }
  }, [location]);

  useEffect(() => {
    if (service_loc && service_loc.length > 0 && service_loc[0].iso2) {
      setLocation(service_loc[0]);
    }
  }, [service_loc]);

  const locationAutoComplete = useCallback(async (key: string) => {
    // if (!key)
    //   return new Promise<ILocation[]>((resolve, reject) => reject("Query is not long enough."));
    const limit = "&limit=10000";
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/v2/geocode?name=${encodeURIComponent(
          key,
        )}&level=${0}${limit}`,
        config,
      )
      .then((res) => {
        return key.length === 0
          ? res.data
              .map((l: ILocation) => ({ ...l, label: reprLocation(l) }))
              .sort((a: ILocation, b: ILocation) => a.label?.localeCompare(b.label || ""))
          : res.data;
      })
      .catch((e) => new Promise<ILocation[]>((resolve, reject) => reject(e)));
  }, []);
  return (
    <div className="onboarding flex flex-col">
      <h3 className="onboarding-header onboarding-width-lg">
        {type === 2 ? (
          <div className="flex flex-col gap-2">
            <span>Need help finding a nonprofit?</span>
            <span>No problem! Share the name and I&apos;ll assist you.</span>
          </div>
        ) : (
          <>
            {isNew ? (
              <h4 className="onboarding-header">What is the name of your new organization?</h4>
            ) : (
              <h4 className="onboarding-header">What is the name of your organization?</h4>
            )}
            <p className="onboarding-sub-header mt-3">
              To get started, please start by entering the name of your nonprofit organization.
            </p>
          </>
        )}
      </h3>
      <div className="onboarding-body onboarding-width-sm !max-w-[480px]">
        <LeftInnerIconInput
          id="input-npo-name"
          className="h-[48px] max-w-[480px] !border-gray-400"
          placeholder="Enter organization name*"
          handleOnChange={(e) => setName(e.currentTarget.value)}
          value={name}
        />
        {type === 2 && (
          <LeftInnerIconInput
            type="number"
            id="input-npo-ein"
            className="h-[48px] max-w-[480px] !border-gray-400"
            placeholder="Enter organization's EIN"
            handleOnChange={(e) => setEin(e.currentTarget.value)}
            value={ein}
          />
        )}
        <LeftInnerIconInput
          id="input-npo-address1"
          placeholder="Address line 1"
          className="h-[48px] max-w-[480px] !border-gray-400"
          handleOnChange={(e) => setAddress1(e.currentTarget.value)}
          value={address1}
          // defaultValue={address1}
        />
        <LeftInnerIconInput
          id="input-npo-address1"
          placeholder="Address line 2"
          className="h-[48px] max-w-[480px] !border-gray-400"
          handleOnChange={(e) => setAddress2(e.currentTarget.value)}
          value={address2}
          // defaultValue={address1}
        />
        {type === 5 ? (
          <div className="flex w-full justify-between gap-4">
            <LeftInnerIconInput
              id="input-npo-city"
              placeholder="City"
              className="h-[48px] !max-w-[232px]  !border-gray-400"
              handleOnChange={(e) => setCity(e.currentTarget.value)}
              value={city}
            />
            <LeftInnerIconInput
              id="input-npo-start"
              placeholder="State/Province/Region"
              className="h-[48px] !max-w-[232px]  !border-gray-400"
              handleOnChange={(e) => setState(e.currentTarget.value)}
              value={state}
            />
          </div>
        ) : (
          <div className="flex w-full justify-between gap-4">
            <LeftInnerIconInput
              id="input-npo-city"
              placeholder="City"
              className="h-[48px] !max-w-[232px]  !border-gray-400"
              handleOnChange={(e) => setCity(e.currentTarget.value)}
              value={city}
            />
            <PlainDropdownSelector
              id="state"
              color="gray"
              wrapperClass="max-h-[48px] "
              inputClass="h-[48px] !border-gray-400 !max-w-[232px] "
              data={getStateNames()}
              placeholder="State/Province/Region"
              selected={state}
              setSelected={setState}
              maxHeight={"400px"}
              downIcon
              clearIcon
            />
          </div>
        )}
        <div className="flex w-full justify-between gap-4">
          <LeftInnerIconInput
            id="input-npo-location"
            placeholder="Zip code"
            handleOnChange={(e) => setZipcode(e.currentTarget.value)}
            value={zipcode}
            iconClass=""
            className="h-[48px]  !max-w-[232px]   !border-gray-400"
          />
          <SearchableDropdown<ILocation>
            controlledValue
            id={`input-location-country`}
            className="max-w-[232px] !pl-4"
            color="gray"
            query={locationQuery}
            setQuery={setLocationQuery}
            autoCompleteFunction={locationAutoComplete}
            autoCompleteThrottleMilliseconds={200}
            addItem={(l) => setLocation(l)}
            renderItem={(l) => reprLocation(l)}
            getKey={(l) => reprLocation(l)}
            placeholder={"Country"}
            spinner
            value={location}
            defaultValue={location}
            float
            arrowIcon
            maxHeight="400px"
            spinnerBackgroundColor="bg-gray-50"
            reprItem={(l) => (l.iso2 ? reprLocation(l) : "")}
            showClear={location.iso2 ? true : false}
            onClear={() => {
              setLocation({
                level: -1,
                lat: 0,
                lng: 0,
                iso2: "",
              });
              setLocationQuery("");
            }}
          />
        </div>
      </div>
      <div className="mt-10 inline-flex items-center gap-2">
        <OnboardingBack
          outline
          data={{
            npo_name_requested: undefined,
            npo_ein_requested: undefined,
            npo_location_requested: undefined,
            address1: undefined,
            address2: undefined,
            city: undefined,
            state_id: undefined,
            zipcode: undefined,
            country: undefined,
          }}
        />
        <PrimaryButton
          className="w-fit"
          size="md"
          handleOnClick={() => {
            mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
              field: Information.ORG_TYPE,
              orgType: "us_but_not_found_in_data",
            });
            mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
              field: Information.ORG_NAME,
              organizationName: name,
              organizationEIN: ein,
              organizationCountry: country,
              organizationState: state,
              organizationCity: city,
              organizationAddress: `${address1} ${address2 || ""}`,
            });
            dispatch(
              updateOnboardingAsync(
                {
                  npo_name_requested: name,
                  npo_ein_requested: ein,
                  npo_location_requested: address1,
                  address1: address1,
                  address2: address2,
                  city: city,
                  state_id: state ? stateNameToStateId(state) : undefined,
                  zipcode: zipcode,
                  country: country,
                  service_loc_requested: location.iso2 ? [location] : [],
                },
                +1,
              ),
            );
          }}
          id="btn-next2"
          label={"Next"}
          rightIconClass={<i className="gi gi-right-arrow" />}
          disabled={name.length === 0}
        />
      </div>
    </div>
  );
}
export default RequestNPOToAdd;
