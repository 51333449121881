import React, { ReactNode, useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import classNames from "classnames";
import { toUsdLong, toUsdShort } from "../../../../utils/formatHelper";
import useNPOIRSProfile, { Contribution } from "../../../../hooks/useNPOIRSProfile";
import NoGraph from "../../../donor-detail-view/components/NoGraph";
import IncreaseTag from "../../financials/component/IncreaseTag";
import SmallestProportionTag from "../../financials/component/SmallestProportionTag";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import LargestProportionTag from "../../financials/component/LargestProportionTag";
import DecreaseTag from "../../financials/component/DecreaseTag";
import { Divider } from "rsuite";
import Spacer from "../../../../components/Spacer";
import FinancialChartLoader from "../../financials/component/FinancialChartLoader";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import PercentageTag from "../../../../components/PercentageTag";
import PredictionIcon from "../../../../assets/financial/predictionIcon.svg";
import ICON_PREDICT from "../../../../assets/financial/preditction.svg";
import AssessmentResult from "../../../../components/AssessmentResult";
import PopupModal from "../../../../components/tailwind/PopupModal";
import { TotalContributionInfo } from "../../financials/pages/TotalContribution";
import { NpoTitle } from "./NpoDiversityEthnicity";
import FinancialPredictionInfo from "./FinancialPredictionInfo";

interface Ifinancial {
  npo_id?: string;
  org_id?: string;
  allowEdit?: boolean;
  isPrivateView?: boolean;
  compact?: boolean;
  recomendation?: boolean;
  showStats?: boolean;
  showInfo?: boolean;
  hideTitle?: boolean;
  children?: ReactNode;
  showChartLoader?: boolean;
}
const InfoTooltip = (props: any) => {
  const renderTitle = (value: string) => {
    return <span className="font-poppins font-normal text-gray-700">{value}</span>;
  };
  const renderValue = (value: string) => {
    return <span className="font-poppins font-semibold text-gray-900">{value}</span>;
  };
  if (props.active && props.payload && props.payload.length) {
    return (
      <div className="giboo-box flex flex-col gap-1">
        <p className="font-poppins font-semibold text-gray-900">
          {props.payload[0].payload.tax_year}
          {props.payload[0].payload.is_predicted
            ? ` (Predicted)`
            : props.payload[0].payload.is_interpolated
            ? ` (Estimated)`
            : ""}
        </p>
        <div className="inline-flex gap-2 !text-sm">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#DD9787",
            }}
          />
          {renderTitle("Government grants:")}
          {renderValue(toUsdLong(props.payload[0].payload?.grants_gov || 0))}
        </div>
        <div className="inline-flex gap-2 !text-sm">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#BFDBF7",
            }}
          />
          {renderTitle("Individual and corporation:")}
          {renderValue(toUsdLong(props.payload[0].payload?.grants_ind || 0))}
        </div>
        <div className="inline-flex gap-2 !text-sm">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#A6C48A",
            }}
          />
          {renderTitle("Foundation contribution:")}
          {renderValue(toUsdLong(props.payload[0].payload?.grants_phil || 0))}
        </div>
      </div>
    );
  }
  return null;
};

function NpoFinancialContribution({
  npo_id,
  org_id,
  allowEdit,
  isPrivateView,
  compact,
  recomendation,
  showInfo = true,
  showStats = true,
  hideTitle = false,
  showChartLoader = true,
  children,
}: Ifinancial) {
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const { totalContribution, contributionModel, isLoading, latest, beforeLatest } =
    useNPOIRSProfile(npo_id);
  const { data: contribution, isLoading: isContributionLoading } = useFinancialRecommendedActions(
    !isPrivateView && loading ? undefined : !isPrivateView && !org_id ? npo_id : org_id,
    "contribution",
    !isPrivateView && !org_id,
  );
  const maxPortion = contributionModel
    ? [
        contributionModel.grants_gov,
        contributionModel.grants_ind,
        contributionModel.grants_phil,
      ].reduce((prev, cur) => Math.max(prev, cur), contributionModel.grants_gov)
    : -1;
  const minPortion = contributionModel
    ? [
        contributionModel.grants_gov,
        contributionModel.grants_ind,
        contributionModel.grants_phil,
      ].reduce((prev, cur) => Math.min(prev, cur), contributionModel.grants_gov)
    : -1;
  const renderGovtGrant = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Government grants</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={contributionModel?.diff_grants_gov}
            beforeLatest={beforeLatest?.grants_gov}
            latest={latest?.grants_gov}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {contributionModel ? (
              maxPortion === contributionModel.grants_gov ? (
                <LargestProportionTag />
              ) : minPortion === contributionModel.grants_gov ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {contribution?.Government?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              {contribution?.Government?.recommendations || ""}
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderindividualCorporation = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Individual and corporation</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={contributionModel?.diff_grants_ind}
            beforeLatest={beforeLatest?.grants_ind}
            latest={latest?.grants_ind}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {contributionModel ? (
              maxPortion === contributionModel.grants_ind ? (
                <LargestProportionTag />
              ) : minPortion === contributionModel.grants_ind ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {contribution?.Individual_and_Corporation?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              {contribution?.Individual_and_Corporation?.recommendations || ""}
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderFoundationCorporation = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Foundation contribution</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={contributionModel?.diff_grants_phil}
            beforeLatest={beforeLatest?.grants_phil}
            latest={latest?.grants_phil}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {contributionModel ? (
              maxPortion === contributionModel.grants_phil ? (
                <LargestProportionTag />
              ) : minPortion === contributionModel.grants_phil ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {contribution?.Foundation?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              {contribution?.Foundation?.recommendations || ""}
            </div>
          </div>
        )}
      </div>
    );
  };
  const chartLoading = recomendation ? loading : isLoading;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-5"
        showHeader={false}
        showActionButtons={false}
      >
        <TotalContributionInfo
          handleClose={() => {
            setShowInfoModal(false);
          }}
        />
      </PopupModal>
      <div
        className={classNames(
          "flex min-h-[100px] flex-col ",
          recomendation && "rounded border border-gray-300 bg-white p-5",
        )}
      >
        {!hideTitle && (
          <NpoTitle
            title="Total contributions"
            recomendation={recomendation}
            setShowInfoModal={setShowInfoModal}
          />
        )}
        {showChartLoader && chartLoading ? (
          <FinancialChartLoader title="Analyzing organization’s Total contributions" />
        ) : (
          <>
            {/* chart */}
            <div className="mx-auto my-5">
              <FinancialContribution
                totalContribution={totalContribution}
                npo_id={npo_id}
                wrapperClass={""}
                compact
              />
            </div>
            {/* info */}
            {showInfo &&
              (isContributionLoading || loading ? (
                <MemoizedLoadingRecommendedAction
                  loading={loading || isContributionLoading}
                  short
                />
              ) : (
                <AssessmentResult content={contribution?.Summary || ""} />
              ))}
            {recomendation ? <Divider className="my-[40px]" /> : <Spacer height="20px" />}
            {/* stats */}
            {showStats &&
              (isContributionLoading || loading ? (
                <MemoizedLoadingRecommendedAction loading={loading || isContributionLoading} />
              ) : (
                <div className="bg-gray-50 px-6 py-5">
                  <h6 className="mb-5 font-poppins text-base font-semibold text-black">Stats</h6>
                  <div className="flex flex-col gap-10">
                    {renderGovtGrant()}
                    {renderindividualCorporation()}
                    {renderFoundationCorporation()}
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      {!chartLoading && children && children}
    </>
  );
}
export default NpoFinancialContribution;

export function FinancialContribution({
  npo_id,
  wrapperClass,
  compact,
  totalContribution,
}: {
  totalContribution: Contribution[];
  npo_id?: string;
  wrapperClass?: string;
  compact?: boolean;
}) {
  const renderHeader = () => {
    return (
      <div className="mb-4 inline-flex flex-wrap items-center gap-3 whitespace-nowrap md:flex-nowrap">
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#DD9787",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Government grants</p>
        </div>
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#BFDBF7",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Individual and corporation</p>
        </div>
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#A6C48A",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Foundation contribution</p>
        </div>
      </div>
    );
  };
  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload, index } = props;
    return (
      <g transform={`translate(${x},${y})`} dy={30}>
        <text x={0} y={20} textAnchor="middle" fill={"#5F5F5F"}>
          {payload?.value}
        </text>
      </g>
    );
  };
  if (totalContribution.length === 0) {
    return <NoGraph />;
  }
  // case for 0 values
  const maxYValue =
    totalContribution.length > 0
      ? Math.max(
          ...totalContribution.map((d) => Math.max(d.grants_gov, d.grants_ind, d.grants_phil)),
          100,
        )
      : 100;
  const estimatedData = totalContribution?.filter((i) => i.is_interpolated);
  const predictedData = totalContribution?.filter((i) => i.is_predicted);

  return (
    <div
      className={classNames("flex h-fit w-full flex-col items-center justify-center", wrapperClass)}
    >
      <div className="grid w-full max-w-[628px] place-items-center items-start">
        {renderHeader()}
        <ResponsiveContainer minHeight="330px" minWidth="50px" height={380} width="100%">
          <BarChart data={totalContribution} margin={{ left: 25, right: 0, top: 16 }}>
            <CartesianGrid
              vertical={false}
              strokeDasharray="4 4"
              stroke={"#DEDEDE"}
              strokeWidth={1}
            />
            <XAxis
              // dx={10}
              dy={5}
              dataKey="tax_year"
              strokeWidth={1}
              stroke="#DEDEDE"
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                whiteSpace: "pre-wrap",
              }}
              tick={<CustomizedAxisTick />}
            />
            <YAxis
              dy={8}
              type="number"
              domain={[0, maxYValue]}
              tickFormatter={(v, i) => toUsdLong(v, 0)}
              stroke="#DEDEDE"
              tick={{ fill: "#5F5F5F" }}
              strokeWidth={1}
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                whiteSpace: "pre-wrap",
              }}
            />
            <Tooltip
              content={<InfoTooltip />}
              cursor={{ fill: "transparent" }}
              wrapperStyle={{ outline: "none" }}
            />
            <Bar
              dataKey="grants_gov"
              fill="#DD9787"
              minPointSize={2}
              barSize={9}
              radius={[10, 10, 0, 0]}
            >
              {totalContribution.map((d: any, idx: any) => {
                return (
                  <Cell
                    key={d["grants_gov"]}
                    stroke={d.is_interpolated ? "#AA93DB" : "#DD9787"}
                    fillOpacity={d.is_predicted || d.is_interpolated ? 0.3 : 1}
                    strokeDasharray={d.is_interpolated ? 0 : d.is_predicted ? 3 : 0}
                  />
                );
              })}
            </Bar>
            <Bar
              dataKey="grants_ind"
              fill="#BFDBF7"
              minPointSize={2}
              barSize={9}
              radius={[10, 10, 0, 0]}
            >
              {totalContribution.map((d: any, idx: any) => {
                return (
                  <Cell
                    key={d["grants_ind"]}
                    stroke={d.is_interpolated ? "#AA93DB" : "#BFDBF7"}
                    fillOpacity={d.is_predicted || d.is_interpolated ? 0.3 : 1}
                    strokeDasharray={d.is_interpolated ? 0 : d.is_predicted ? 3 : 0}
                  />
                );
              })}
            </Bar>
            <Bar
              dataKey="grants_phil"
              fill="#A6C48A"
              minPointSize={2}
              barSize={9}
              radius={[10, 10, 0, 0]}
            >
              {totalContribution.map((d: any, idx: any) => {
                return (
                  <Cell
                    key={d["grants_phil"]}
                    stroke={d.is_interpolated ? "#AA93DB" : "#A6C48A"}
                    fillOpacity={d.is_predicted || d.is_interpolated ? 0.3 : 1}
                    strokeDasharray={d.is_interpolated ? 0 : d.is_predicted ? 3 : 0}
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <div className="mt-4 flex  items-center justify-center gap-4">
          {estimatedData && estimatedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-purple-100 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-estimated text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Estimated years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {estimatedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
          {predictedData && predictedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-dashed border-purple-500 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-predicted text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Predicted years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {predictedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center">
        <FinancialPredictionInfo />
      </div>
    </div>
  );
}
