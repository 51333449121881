import axios from "axios";
import config from "../../api";
interface loiData {
  sender: string;
  to: string[];
  cc?: string[];
  bcc?: string[];
  subject: string;
  content: string;
}
interface ReturnType {
  msg: string;
  sent: boolean;
}
export const sendEmailService = (data: loiData) => {
  if (data === undefined) return new Promise<ReturnType>((resolve, reject) => reject());
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/v1/auth/send_email`,
        {
          ...data,
        },
        config,
      )
      .then(({ data }) => data as ReturnType)
      .then((res) => {
        if (res.sent) resolve(res);
        else reject();
      })
      .catch((e) => {
        reject(e);
      });
  });
};
