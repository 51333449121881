import { AnyAction, configureStore, ThunkDispatch } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import logger from "redux-logger";
import moreSearchOptionSlice from "./moreSearchOptionSlice";
import onboardingSlice from "./onboardingSlice";
import projectSlice from "./projectSlice";
import searchHistorySlice from "./searchHistorySlice";
import staticDataSlice from "./staticDataSlice";
import taxonomySlice from "./taxonomySlice";
import tutorSlice from "./tutorSlice";
import userSlice from "./userSlice";
const ENV = process.env.REACT_APP_ENV; // enabling redux logger for DEV env only;
const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    onboarding: onboardingSlice.reducer,
    moreSearchOption: moreSearchOptionSlice.reducer,
    searchHistory: searchHistorySlice.reducer,
    orgProjects: projectSlice.reducer,
    tutor: tutorSlice.reducer,
    taxonomySlice: taxonomySlice.reducer,
    staticData: staticDataSlice.reducer,
  },
  ...(ENV == "DEV" && {
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  }),
});
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;

const selectTaxonomyUppercaseMapGlobalized = () => store.getState().taxonomySlice.uppercaseMap;
const selectTopBeneficiaryGlobalized = () => store.getState().taxonomySlice.topBeneficiary;
const selectTaxonomyGlobalized = (taxonomy: "focus_area" | "beneficiary" | "program") =>
  taxonomy === "focus_area"
    ? store.getState().onboarding.taxonomy?.focus_area
    : taxonomy === "beneficiary"
    ? store.getState().onboarding.taxonomy?.beneficiary
    : taxonomy === "program"
    ? store.getState().onboarding.taxonomy?.program
    : [];
export {
  selectTaxonomyGlobalized,
  selectTaxonomyUppercaseMapGlobalized,
  selectTopBeneficiaryGlobalized,
};
