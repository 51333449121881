import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { getFileUrl } from "../services/file-upload/download.service";
import { Org } from "../types/org";
import useUser from "../hooks/useUser";
import { roleToName } from "../app/userSlice";
import { getRandomColor } from "../utils/formatHelper";
import useOrgProfile from "../hooks/useOrgProfile";
import usePhotoUrl from "../hooks/usePhotoUrl";

interface IOrgProfileCard {
  org?: Org;
  showRole?: boolean;
  showInvited?: boolean;
  showNotFinished?: boolean;
  customClass?: string | undefined;
}
const roleColor = ["#FFFFFF", "#FFFFFF", "#F1FFC9", "#FFD6D6", "#CCBDDC", "#AFD9FF"];
function OrgProfileCard({ customClass, org, ...props }: IOrgProfileCard) {
  const [user] = useUser();
  const { data: profileUrl } = usePhotoUrl(org?.photo_object_key);
  return (
    <div
      key={`org-${org?._id}`}
      className={classNames("flex w-full items-center justify-between", customClass)}
    >
      <div className="flex w-full items-center gap-2">
        <div
          className="itmes-center flex h-7 w-7 justify-center overflow-hidden rounded-full "
          style={{
            backgroundColor: getRandomColor(org?.npo_name || ""),
          }}
        >
          {profileUrl ? (
            <img src={profileUrl} alt="profile pic" className=" h-full w-full bg-cover" />
          ) : org?.npo_name ? (
            <p className="p-1 text-sm font-semibold capitalize text-white">
              {org?.npo_name?.charAt(0)}
            </p>
          ) : (
            <p className="p-1 text-sm font-semibold capitalize text-white">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.61092 9.61811C8.49457 9.61811 7.58959 10.6031 7.58959 11.8182C7.58959 13.0333 8.49457 14.0183 9.61092 14.0183C10.7273 14.0183 11.6322 13.0333 11.6322 11.8182C11.6322 10.6031 10.7273 9.61811 9.61092 9.61811ZM6.94141 11.8182C6.94141 10.2135 8.13659 8.9126 9.61092 8.9126C11.0852 8.9126 12.2804 10.2135 12.2804 11.8182C12.2804 13.4229 11.0852 14.7238 9.61092 14.7238C8.13659 14.7238 6.94141 13.4229 6.94141 11.8182Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.03728 10.313C9.21888 10.2311 9.41352 10.189 9.61008 10.189C9.78908 10.189 9.93418 10.3469 9.93418 10.5417C9.93418 10.7365 9.78908 10.8945 9.61008 10.8945C9.49864 10.8945 9.38829 10.9184 9.28533 10.9648C9.18237 11.0112 9.08882 11.0792 9.01002 11.165C8.93122 11.2508 8.86871 11.3526 8.82607 11.4647C8.78342 11.5767 8.76147 11.6968 8.76147 11.8181C8.76147 12.013 8.61637 12.1709 8.43738 12.1709C8.25838 12.1709 8.11328 12.013 8.11328 11.8181C8.11328 11.6042 8.152 11.3923 8.22722 11.1947C8.30244 10.997 8.41269 10.8174 8.55168 10.6661C8.69068 10.5149 8.85568 10.3949 9.03728 10.313Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.7238 14.1214C11.8504 13.9836 12.0556 13.9836 12.1822 14.1214L13.3549 15.3978C13.4814 15.5356 13.4814 15.7589 13.3549 15.8967C13.2283 16.0344 13.0231 16.0344 12.8965 15.8967L11.7238 14.6203C11.5973 14.4825 11.5973 14.2591 11.7238 14.1214Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 4C8.13402 4 6.52889 5.21231 5.81183 6.95128C5.62308 7.40903 5.26309 7.71322 4.88273 7.71322C3.29064 7.71322 2 9.11801 2 10.8509C2 12.2675 2.86253 13.4649 4.04716 13.8547C4.06333 13.5669 4.0948 13.3442 4.15603 13.1515C3.27867 12.8233 2.64819 11.9172 2.64819 10.8509C2.64819 9.50765 3.64863 8.41874 4.88273 8.41874C5.59412 8.41874 6.14491 7.86653 6.40319 7.24016C7.01954 5.74542 8.39855 4.70551 10 4.70551C11.6015 4.70551 12.9805 5.74542 13.5968 7.24016C13.8551 7.86653 14.4059 8.41874 15.1173 8.41874C16.3514 8.41874 17.3518 9.50765 17.3518 10.8509C17.3518 11.9171 16.7215 12.8231 15.8443 13.1514C15.9056 13.3441 15.9371 13.5668 15.9532 13.8546C17.1377 13.4646 18 12.2674 18 10.8509C18 9.11801 16.7094 7.71322 15.1173 7.71322C14.7369 7.71322 14.3769 7.40903 14.1882 6.95128C13.4711 5.21231 11.866 4 10 4Z"
                  fill="white"
                />
              </svg>
            </p>
          )}
        </div>
        <div className={classNames("flex flex-col gap-1 py-3 text-xs", "w-[calc(100%-50px)]")}>
          <div className={classNames("flex items-center gap-2 text-xs")}>
            <span className="truncate whitespace-nowrap">
              {org?.npo_name ? org.npo_name : !org?.completed ? "onboarding in progress" : ""}
            </span>

            {props.showRole && (
              <span
                className={classNames("rounded px-2 py-1 text-gray-900")}
                style={{ backgroundColor: roleColor[org?.role || 0] }}
              >
                {roleToName[org?.role || 0]}
              </span>
            )}
            {props.showInvited && org?.owner?.email !== user?.email && !org?.accepted && (
              <span className="rounded border border-gray-500 px-2 py-1 text-gray-900 whitespace-nowrap">
                Invited
              </span>
            )}
            {props.showNotFinished && !org?.completed && (
              <span className="rounded border border-gray-500 px-2 py-1 text-gray-900 whitespace-nowrap">
                In progress
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
OrgProfileCard.defaultProps = {
  showRole: true,
  showInvited: true,
  showNotFinished: true,
};

export default OrgProfileCard;
