import classNames from "classnames";
import Tag from "./Tag";
import { GeographicFocus, PhilanthropicFocusArea } from "../types/philanthropy";
import { ILocation, reprLocation } from "../types/location";
import { useCallback, useEffect, useRef, useState } from "react";
import PlainButton from "./PlainButton";
import { SearchQuery } from "../types/search";
import { useLocation, useNavigate } from "react-router-dom";
import useOnboardingData from "../hooks/useOnboarding";
import { getPastGrantSearchURL, getPastGrantSearchURLFromLocation } from "../utils/search";
import { buildPriorityLocation, prioritySortLocation } from "../utils/grant";
import { toUsdLong, toUsdShort } from "../utils/formatHelper";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
interface IPhilanthropicInformation {
  id: string;
  focusArea?: PhilanthropicFocusArea[];
  beneficiary?: PhilanthropicFocusArea[];
  program?: PhilanthropicFocusArea[];
  location?: GeographicFocus[];
  priorityFocusArea?: string[];
  priorityBeneficiary?: string[];
  priorityProgram?: string[];
  priorityLocation?: ILocation[];
  secondaryPriorityLocation?: ILocation[];
  containerClass?: string;
  leftContainerClass?: string;
  rightContainerClass?: string;
  tagMatched?: boolean;
  maxLine?: number;
  otherTagLabel?: "other" | "more" | "matched";
  tagSize?: "lg" | "md" | "sm";
  tagOutline?: "none" | "gray" | "color";
  hideShowMoreBtn?: boolean;
  allowPastGrantSearch?: boolean;
  funder_id?: string;
  searchQuery?: SearchQuery;
  predictedSize?: number;
  enableGroupLocation?: boolean;
  initialShowAllCount?: number;
  showAll: boolean;
  setShowAll: (v: boolean) => void;
  useMinHeight?: boolean;
}
interface PhilanthropicFocusAreaWithColor extends PhilanthropicFocusArea {
  color: "green" | "purple" | "red" | "yellow" | "blue" | "gray" | "orange";
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}
interface GeographicFocusWithColor extends GeographicFocus {
  color: "green" | "purple" | "red" | "yellow" | "blue" | "gray" | "orange";
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  more?: number;
}
function prioritySort(
  a: PhilanthropicFocusArea,
  b: PhilanthropicFocusArea,
  priority?: string[],
): number {
  if (!priority) return 0;
  const pa = priority.includes(a.label);
  const pb = priority.includes(b.label);
  return pa && !pb ? -1 : !pa && pb ? 1 : 0;
}
function interleaveArrays(arrays: any[][]): any[] {
  const maxLength = Math.max(...arrays.map((arr) => arr.length));
  const result: any[] = [];

  for (let i = 0; i < maxLength; i++) {
    for (let j = 0; j < arrays.length; j++) {
      if (arrays[j][i]) {
        result.push(arrays[j][i]);
      }
    }
  }

  return result;
}
const getP = (
  item: (PhilanthropicFocusAreaWithColor | GeographicFocusWithColor) & { priority?: boolean },
) => {
  if (item.priority) return 0;
  else if (item.color === "yellow") return 1;
  else if (item.color === "blue") return 2;
  else if (item.color === "green") return 3;
  else if (item.color === "orange") return 4;
  else if (item.color === "gray") return 5;
  return 6;
};
const PhilanthropicInformationFlexWrapInner = ({
  id,
  focusArea,
  beneficiary,
  program,
  location,
  tagMatched = false,
  otherTagLabel = "other",
  tagSize,
  maxLine = 2,
  tagOutline,
  hideShowMoreBtn,
  showAll,
  setShowAll,
  predictedSize = -1,
  initialShowAllCount = 50,
  priorityFocusArea,
  priorityBeneficiary,
  priorityProgram,
  priorityLocation,
  searchQuery,
  allowPastGrantSearch,
  funder_id,
  ...props
}: IPhilanthropicInformation) => {
  const mxEvent = useGibooMixpanel();
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const ref = useRef<HTMLDivElement>(null);
  const [groupLocation, setGroupLocation] = useState<number>(props.enableGroupLocation ? 1 : -1);
  const [tags, setTags] = useState<(PhilanthropicFocusAreaWithColor | GeographicFocusWithColor)[]>(
    [],
  );
  const loc = useLocation();
  const [showCount, setShowCount] = useState<number>(initialShowAllCount);
  const [highPriority, setHighPriority] = useState<{
    focus_area: PhilanthropicFocusAreaWithColor[];
    beneficiary: PhilanthropicFocusAreaWithColor[];
    program: PhilanthropicFocusAreaWithColor[];
    service_loc: GeographicFocusWithColor[];
  }>({ focus_area: [], beneficiary: [], program: [], service_loc: [] });
  const [lowPriority, setLowPriority] = useState<{
    focus_area: PhilanthropicFocusAreaWithColor[];
    beneficiary: PhilanthropicFocusAreaWithColor[];
    program: PhilanthropicFocusAreaWithColor[];
    service_loc: GeographicFocusWithColor[];
  }>({ focus_area: [], beneficiary: [], program: [], service_loc: [] });
  useEffect(() => {
    const priorityLabels = priorityLocation?.map((l) => reprLocation(l)) || [];
    const priority = buildPriorityLocation(priorityLocation, props.secondaryPriorityLocation);
    const tempLocation = location?.map((l) => ({ ...l, label: reprLocation(l) })) || [];
    const sortedLocations = tempLocation?.sort((a, b) => prioritySortLocation(a, b, priority));
    const highPriority = {
      focus_area:
        focusArea
          ?.filter((i) => priorityFocusArea && priorityFocusArea?.includes(i.label))
          .sort((a, b) => {
            if (a.matched && !b.matched) return -1;
            if (!a.matched && b.matched) return 0;
            return (b.amount || 0) - (a.amount || 0);
          })
          .map((i) => ({
            ...i,
            color: "yellow" as "blue" | "green" | "orange" | "yellow" | "purple" | "red" | "gray",
          })) || [],
      beneficiary:
        beneficiary
          ?.filter((i) => priorityBeneficiary && priorityBeneficiary?.includes(i.label))
          .sort((a, b) => {
            if (a.matched && !b.matched) return -1;
            if (!a.matched && b.matched) return 0;
            return (b.amount || 0) - (a.amount || 0);
          })
          .map((i) => ({
            ...i,
            color: "blue" as "blue" | "green" | "orange" | "yellow" | "purple" | "red" | "gray",
          })) || [],
      program:
        program
          ?.filter((i) => priorityProgram && priorityProgram?.includes(i.label))
          .sort((a, b) => {
            if (a.matched && !b.matched) return -1;
            if (!a.matched && b.matched) return 0;
            return (b.amount || 0) - (a.amount || 0);
          })
          .map((i) => ({
            ...i,
            color: "green" as "blue" | "green" | "orange" | "yellow" | "purple" | "red" | "gray",
          })) || [],
      service_loc: sortedLocations
        ?.filter((i) => i.label && priorityLabels?.includes(i.label))
        .map((i) => ({
          ...i,
          color: "orange" as "blue" | "green" | "orange" | "yellow" | "purple" | "red" | "gray",
        })),
    };
    const lowPriority = {
      focus_area:
        focusArea
          ?.filter((i) => !priorityFocusArea?.includes(i.label))
          .sort((a, b) => {
            if (a.matched && !b.matched) return -1;
            if (!a.matched && b.matched) return 0;
            return (b.amount || 0) - (a.amount || 0);
          })
          .map((i) => ({
            ...i,
            color: "yellow" as "blue" | "green" | "orange" | "yellow" | "purple" | "red" | "gray",
          })) || [],
      beneficiary:
        beneficiary
          ?.filter((i) => !priorityBeneficiary?.includes(i.label))
          .sort((a, b) => {
            if (a.matched && !b.matched) return -1;
            if (!a.matched && b.matched) return 0;
            return (b.amount || 0) - (a.amount || 0);
          })
          .map((i) => ({
            ...i,
            color: "blue" as "blue" | "green" | "orange" | "yellow" | "purple" | "red" | "gray",
          })) || [],
      program:
        program
          ?.filter((i) => !priorityProgram?.includes(i.label))
          .sort((a, b) => {
            if (a.matched && !b.matched) return -1;
            if (!a.matched && b.matched) return 0;
            return (b.amount || 0) - (a.amount || 0);
          })
          .map((i) => ({
            ...i,
            color: "green" as "blue" | "green" | "orange" | "yellow" | "purple" | "red" | "gray",
          })) || [],
      service_loc: sortedLocations
        ?.filter((i) => i.label && !priorityLabels?.includes(i.label))
        .map((i) => ({
          ...i,
          color: "orange" as "blue" | "green" | "orange" | "yellow" | "purple" | "red" | "gray",
        })),
    };
    setHighPriority(highPriority);
    setLowPriority(lowPriority);
  }, [
    // id,
    focusArea,
    beneficiary,
    program,
    location,
    // groupLocation,
    priorityFocusArea,
    priorityBeneficiary,
    priorityProgram,
    priorityLocation,
    // searchQuery,
    // funder_id,
    // allowPastGrantSearch,
  ]);
  useEffect(() => {
    const enableSearchPastGrants = allowPastGrantSearch && funder_id && searchQuery;
    const sendToMixpanel = (
      tagValue: string,
      tagType: "focus_area" | "beneficiary" | "program" | "service_location",
    ) => {
      mxEvent(MIXPANEL_EVENTS_V2.detail_view.tag.clicked, {
        value: tagValue,
        type: tagType,
        textQuery: searchQuery?.text_query,
      });
    };
    const highArray = [
      highPriority.focus_area,
      highPriority.beneficiary,
      highPriority.program,
      highPriority.service_loc,
    ];
    const lowArray = [
      lowPriority.focus_area,
      lowPriority.beneficiary,
      lowPriority.program,
      lowPriority.service_loc,
    ];
    const _tags = [...interleaveArrays(highArray), ...interleaveArrays(lowArray)].map((i) => ({
      ...i,
      priority: true,
      onClick: enableSearchPastGrants
        ? (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
            e.stopPropagation();
            e.preventDefault();
            const type =
              i.color === "yellow"
                ? "focus_area"
                : i.color == "blue"
                ? "beneficiary"
                : i.color === "green"
                ? "program"
                : "service_location";
            const typeChar =
              i.color === "yellow"
                ? "f"
                : i.color == "blue"
                ? "b"
                : i.color === "green"
                ? "p"
                : "l";
            sendToMixpanel(i.label, type);
            navigate(
              typeChar === "l"
                ? getPastGrantSearchURLFromLocation(
                    funder_id!,
                    i.label,
                    typeChar,
                    onboardingData,
                    searchQuery!,
                  )
                : getPastGrantSearchURL(
                    funder_id!,
                    i.label,
                    typeChar,
                    onboardingData,
                    searchQuery!,
                  ),
            );
          }
        : undefined,
    }));
    setTags(_tags);
  }, [
    id,
    highPriority,
    lowPriority,
    onboardingData,
    // groupLocation,
    searchQuery,
    funder_id,
    allowPastGrantSearch,
  ]);
  useEffect(() => {
    setShowCount(initialShowAllCount);
  }, [id]);
  const totalTags = tags.length;
  const [{ left, right, mid }, setValue] = useState<{ left: number; right: number; mid: number }>({
    left: maxLine * 2,
    right: totalTags,
    mid:
      process.env.REACT_APP_DISABLE_CUT_TWO_LINE === "TRUE"
        ? 6
        : predictedSize > 0
        ? predictedSize
        : Math.ceil((totalTags + 1) / 2),
  });
  const viewCount = showAll
    ? totalTags > showCount
      ? showCount
      : totalTags
    : totalTags > mid
    ? mid
    : totalTags;
  const remainingCount = totalTags - viewCount;
  const tagHeight = tagSize === "lg" ? 48 : tagSize === "md" ? 32 : 24;
  const bound = tagHeight / 2 + (maxLine - 1) * 4 + maxLine * tagHeight;
  // useEffect(() => {
  //   setValue({
  //     left: maxLine * 2,
  //     right: tags.length,
  //     mid:
  //       process.env.REACT_APP_DISABLE_CUT_TWO_LINE === "TRUE"
  //         ? 6
  //         : predictedSize > 0
  //         ? predictedSize
  //         : Math.floor((tags.length + 1) / 2),
  //   });
  // }, [tags]);
  useEffect(() => {
    if (process.env.REACT_APP_DISABLE_CUT_TWO_LINE === "TRUE") return;
    if (!ref.current || tags.length === 0) return;
    if (left >= right) {
      return;
    }
    const h = ref.current.offsetHeight;
    if (h <= bound) {
      setValue({ left: mid, mid: Math.floor((mid + right + 1) / 2), right: right });
    } else {
      setValue({ left: left, mid: Math.floor((left + mid) / 2), right: mid - 1 });
    }
  }, [ref, left, right, mid]);

  // const renderPhilanthropicFocus = useCallback(() => {
  const renderTags = ({ matched = false }: { matched: boolean }) => {
    return (
      <div
        ref={ref}
        className={classNames("flex h-auto flex-wrap gap-x-[4px] gap-y-[4px]")}
        style={
          props.useMinHeight && tags.length > 0
            ? { minHeight: `${(maxLine - 1) * 4 + maxLine * tagHeight}px` }
            : {}
        }
      >
        {tags && tags?.length > 0
          ? tags
              .slice(
                0,
                showAll
                  ? totalTags > showCount
                    ? showCount
                    : totalTags
                  : totalTags > mid
                  ? mid
                  : totalTags,
              )
              .sort((a, b) => getP(a) - getP(b))
              .map(
                (item: PhilanthropicFocusAreaWithColor | GeographicFocusWithColor, i: number) => {
                  return "level" in item ? (
                    <Tag
                      key={`${id}-${item.color}-${item.label}`}
                      id={`${id}-${item.color}-${item.label}`}
                      name={item.label || ""}
                      color={item.color}
                      matched={matched || item.matched}
                      className={`${id?.split("-")?.[0] || "tag"}-${item.color}`}
                      size={tagSize}
                      onClick={item.onClick}
                      number={item.more && groupLocation > 0 ? item.more : undefined}
                      tooltip={
                        item.more && groupLocation > 0 ? (
                          `See more location`
                        ) : item.onClick ? (
                          <span>
                            Click to view past grants associated with “<b>{item.label}</b>“
                            {item.amount !== undefined && item.amount !== null && (
                              <>
                                {" "}
                                <b>{toUsdShort(item.amount || 0)}</b> disbursed
                              </>
                            )}
                          </span>
                        ) : undefined
                      }
                    />
                  ) : (
                    <Tag
                      key={`${id}-${item.color}-${item.label}`}
                      id={`${id}-${item.color}-${item.label}`}
                      name={item.label}
                      color={item.color}
                      className={`${id?.split("-")?.[0] || "tag"}-${item.color}`}
                      matched={matched || item.matched}
                      size={tagSize}
                      onClick={item.onClick}
                      tooltip={
                        item.onClick ? (
                          <span>
                            Click to view past grants associated with “<b>{item.label}</b>“
                            {item.amount !== undefined && item.amount !== null && (
                              <>
                                {" "}
                                <b>{toUsdShort(item.amount || 0)}</b> disbursed
                              </>
                            )}
                          </span>
                        ) : undefined
                      }
                    />
                  );
                },
              )
          : null}
        {remainingCount > 0 && (
          <Tag
            key={`${id}other`}
            id={`${id}tag-other`}
            name={`+${remainingCount} ${otherTagLabel}`}
            className="border-gray-300"
            size={tagSize}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowAll(true);
              setShowCount((prev) => prev + 50);
            }}
          />
        )}
      </div>
    );
  };
  const renderPhilanthropicFocus = () => {
    return (
      <div className="flex flex-col gap-1">
        {renderTags({
          matched: tagMatched,
        })}
        {!hideShowMoreBtn && remainingCount > 0 && (
          <PlainButton
            className="!m-0 self-start !p-0 !text-sm"
            label={!showAll && remainingCount > 0 ? "Show more" : "Show less"}
            id="btn-show-more"
            handleOnClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (!showAll && remainingCount > 0) {
                //
              } else {
                if (props.enableGroupLocation) setGroupLocation(props.enableGroupLocation ? 1 : -1);
              }
              setShowAll(!showAll);
            }}
          />
        )}
      </div>
    );
  };
  // }, [
  //   reprLocation,
  //   groupLocation,
  //   mid,
  //   totalTags,
  //   otherTagLabel,
  //   tags,
  //   ref,
  //   hideShowMoreBtn,
  //   remainingCount,
  //   showAll,
  //   setShowAll,
  //   props.enableGroupLocation,
  //   setGroupLocation,
  // ]);
  return (
    <>
      <div
        className={classNames(
          "flex h-full w-full flex-col lg:flex-row",
          props.containerClass,
          tags && tags.length === 0 ? "hidden" : "block",
        )}
      >
        {renderPhilanthropicFocus()}
      </div>
    </>
  );
};

export default PhilanthropicInformationFlexWrapInner;
