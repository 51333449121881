import classNames from "classnames";
import { Dispatch, SetStateAction, forwardRef, useRef } from "react";
import DatePicker from "react-datepicker";
export default function YearPicker({
  value,
  setValue,
  placeHolder = "Choose year",
  min,
  max,
  disabled,
  contentClass,
}: {
  value: Date | null;
  min?: Date | null;
  max?: Date | null;
  setValue: (v: Date | null) => void;
  placeHolder?: string;
  contentClass?: string;
  disabled?: boolean;
}) {
  const dateRef = useRef<any>(null);
  const CustomInput = forwardRef((props: any, ref: any) => {
    return (
      <button
        className={classNames(
          "inline-flex w-[120px] items-center justify-between gap-1 border-b pb-1",
          props.value ? "border-gray-700" : " border-gray-500",
          props.disabled && "opacity-50",
          contentClass,
        )}
        onClick={props?.onClick}
        ref={ref}
      >
        <p
          className={classNames(
            "font-poppins text-sm",
            props.value ? "text-gray-900" : "text-gray-500",
          )}
        >
          {props.value || placeHolder}
        </p>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              d="M16.25 3H13.75V1.75H12.5V3H7.5V1.75H6.25V3H3.75C3.0625 3 2.5 3.5625 2.5 4.25V16.75C2.5 17.4375 3.0625 18 3.75 18H16.25C16.9375 18 17.5 17.4375 17.5 16.75V4.25C17.5 3.5625 16.9375 3 16.25 3ZM16.25 16.75H3.75V8H16.25V16.75ZM16.25 6.75H3.75V4.25H6.25V5.5H7.5V4.25H12.5V5.5H13.75V4.25H16.25V6.75Z"
              fill={props.value ? "#5f5f5f" : "#9B9B9B"}
            />
          </svg>
        </span>
      </button>
    );
  });
  CustomInput.displayName = "CustomInput";
  return (
    <DatePicker
      ref={dateRef}
      selected={value}
      onChange={(date) => setValue(date)}
      showYearPicker
      dateFormat="yyyy"
      customInput={<CustomInput />}
      disabled={disabled}
      minDate={min}
      maxDate={max}
    />
  );
}
