import classNames from "classnames";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import SearchableDropdown from "./dropdown/SearchableDropdown";
import Tag from "./Tag";
import axios from "axios";
import config from "../api";
import PlainButton from "./PlainButton";
import { FROM_FOR_MIXPANEL, MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useGibooMixpanel, { GibooMixpanelProperties } from "../hooks/useGibooMixpanel";
import {
  ILocation,
  countryLocationAutoComplete,
  locationAutoComplete,
  reprLocation,
  stateLocationAutoComplete,
} from "../types/location";

const reprItem = reprLocation;
interface GibooLocationInputProps {
  from_for_mixpanel?: FROM_FOR_MIXPANEL;
  asMixPanelProperty?: (key: ILocation) => GibooMixpanelProperties;
  id: string;
  className?: string;
  placeholder?: string;
  value: ILocation[];
  excludes?: ILocation[];
  setValue: (v: ILocation[]) => void;
  onAdd?: (loc: ILocation) => void;
  hideItems?: boolean;
  maxWidth?: string;
  maxHeight?: string;
  inputBoxMaxWidth?: string;
  itemsMaxHeight?: string;
  itemsPosition?: "top" | "bottom";
  itemsAlign?: "start" | "center" | "end";
  gap?: number;
  itemGap?: number;
  icon?: boolean;
  hideNone?: boolean;
  disabled?: boolean;
  float?: boolean;
  spinnerBackgroundColor?: string;
  wrapperClass?: string;
  hideSearch?: boolean;
  inputClassName?: string;
  addMoreLabel?: string;
  addMoreLabelClass?: string;
  selected?: boolean;
  matched?: boolean;
  isFullTagClick?: boolean;
  searchFieldPosition?: "start" | "center" | "end";
  onFocus?: () => void;
  tagSize?: "sm" | "md" | "lg";
  specificLevel?: "all" | "country" | "state";
}
function GibooLocationInput({
  from_for_mixpanel,
  asMixPanelProperty,
  id,
  className,
  placeholder,
  value,
  setValue,
  onAdd,
  hideItems = false,
  maxWidth,
  inputBoxMaxWidth,
  itemsMaxHeight,
  itemsPosition = "bottom",
  itemsAlign = "start",
  searchFieldPosition = "center",
  gap = 2,
  itemGap = 2,
  icon,
  hideNone,
  disabled,
  float,
  wrapperClass,
  spinnerBackgroundColor = "bg-white",
  inputClassName,
  hideSearch = false,
  addMoreLabel = "Add more",
  addMoreLabelClass,
  selected,
  matched,
  onFocus,
  isFullTagClick,
  maxHeight = "320px",
  tagSize,
  specificLevel = "all",
  ...props
}: GibooLocationInputProps) {
  const mxEvent = useGibooMixpanel(from_for_mixpanel, true);
  const color = "orange";
  const [query, setQuery] = useState<string>("");
  const excludes = [
    ...value.map((item) => reprLocation(item)),
    ...(props.excludes?.map((item) => reprLocation(item)) || []),
  ];
  const addItem = (item: ILocation) => {
    if (excludes.includes(reprLocation(item))) {
      // TODO : focus label
      //   href={props.fragment}
    } else {
      setValue([...value, item]);
      onAdd?.(item);
      mxEvent(MIXPANEL_EVENTS_V2.search_location[""].successful, {
        ...(asMixPanelProperty?.(item) || {}),
        textQuery: query,
      });
    }
  };
  const [hideSearchState, setHideSearchState] = useState<boolean>(true);
  const memoizedItems = useMemo(() => {
    return (
      <div
        className={classNames(
          className,
          "multiple-selector-items flex w-full flex-wrap overflow-auto",
          `!gap-${itemGap}`,
          `justify-${itemsAlign}`,
        )}
        style={{ maxWidth: maxWidth, maxHeight: itemsMaxHeight }}
      >
        {value.length > 0 ? (
          value.map((v) => (
            <Tag
              id={`${id}-${reprLocation(v)}`}
              key={reprLocation(v)}
              name={reprItem(v)}
              color={color}
              matched={matched}
              selected={selected}
              isFullTagClick={isFullTagClick}
              onDelete={() => {
                setValue([...value.filter((item) => item !== v)]);
              }}
              size={tagSize}
            />
          ))
        ) : !hideNone ? (
          <Tag
            id={`${id}-`}
            key={null}
            name={"None"}
            selected={selected}
            color={color}
            size={tagSize}
          />
        ) : null}
      </div>
    );
  }, [value, setValue]);
  return (
    <div
      className={classNames(
        `multiple-selector-container flex flex-col items-${searchFieldPosition} gap-${gap}`,
        wrapperClass,
      )}
      style={{ maxWidth: maxWidth }}
    >
      {!hideItems && itemsPosition === "top" && <>{memoizedItems}</>}
      {hideSearch && hideSearchState ? (
        <div className="self-start">
          <PlainButton
            id={`btn-hide-${id}`}
            label={addMoreLabel}
            className={addMoreLabelClass}
            leftIconClass="fa-regular fa-plus"
            handleOnClick={() => setHideSearchState(false)}
          />
        </div>
      ) : (
        <SearchableDropdown<ILocation>
          id={id}
          color={color}
          query={query}
          setQuery={setQuery}
          autoCompleteFunction={(query: string) => {
            if (query)
              mxEvent(MIXPANEL_EVENTS_V2.search_location[""].started, {
                textQuery: query,
              });
            return specificLevel === "state"
              ? stateLocationAutoComplete(query)
              : specificLevel === "country"
              ? countryLocationAutoComplete(query)
              : locationAutoComplete(query);
          }}
          autoCompleteThrottleMilliseconds={200}
          addItem={addItem}
          renderItem={reprItem}
          getKey={reprLocation}
          excludeKeys={excludes}
          placeholder={placeholder}
          maxHeight={maxHeight}
          maxWidth={inputBoxMaxWidth}
          className={inputClassName}
          icon={icon}
          disabled={disabled}
          float={float}
          onFocus={onFocus}
          spinner
          spinnerBackgroundColor={spinnerBackgroundColor}
        />
      )}
      {!hideItems && itemsPosition === "bottom" && <>{memoizedItems}</>}
    </div>
  );
}
export default GibooLocationInput;
