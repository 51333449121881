import React from "react";
import "./css/input.scss";
import classNames from "classnames";
import TooltipButton from "./TooltipButton";
import GibooTooltipWrapper from "./GibooTooltipWrapper";

interface StarCheckboxProps {
  id: string;
  checked: boolean;
  disabled?: boolean;
  onClick?: () => void;
  labelClass?: string;
}

function StarCheckbox({
  checked = false,
  disabled = false,
  labelClass,
  id,
  onClick,
  ...props
}: StarCheckboxProps) {
  return (
    <>
      <div
        className="cursor-pointer"
        id={`tooltip-bar-${id}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!disabled) {
            onClick?.();
          }
        }}
      >
        <i
          className={classNames(
            "text-gray-700",
            labelClass,
            checked ? "gi-star-filled" : "gi-star",
          )}
        />
      </div>
      {/* <GibooTooltipWrapper
        id={`tooltip-bar-${id}`}
        place="top"
        wrapperClassName="!p-2"
        content={
          <h6 className="font-poppins text-sm">
            This search criterion must be <br /> matched in the search result.
          </h6>
        }
      /> */}
    </>
  );
}

export default StarCheckbox;
