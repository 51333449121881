import { Dialog, Listbox, Transition } from "@headlessui/react";
import PlainButton from "./PlainButton";
import React, { Dispatch, SetStateAction, useEffect, useState, useRef, ReactNode } from "react";
import useUser from "../hooks/useUser";
import classNames from "classnames";
import useOnboardingData from "../hooks/useOnboarding";
import useOrgs from "../hooks/useOrgs";
import { DatePicker, Divider, Popover, Whisper } from "rsuite";
import UserMentionsInput from "./UserMentionsInput";
import useOrgMembers from "../hooks/project/useOrgMembers";
import Button from "./tailwind/Button";
import CloseButton from "./CloseButton";
import useMySubtasks from "../hooks/project/useMySubtasks";
import { createProjectSubtask } from "../hooks/project/useSubtask";
import UserProfile from "./UserProfile";
import useTasks from "../hooks/project/useTasks";
import { IProject, IProjectTask } from "../types/project";
import TaskName from "../pages/npo/projects/components/TaskName";
import GibooToast from "./GibooToast";
import { useNavigate, useParams } from "react-router-dom";
import ClickToEditInput from "./input/ClickToEditInput";
import GotoSmartSearchContext from "./useGotoSmartSearch";
import SingleSelector from "./selector/SingleSelector";
import OrgInvitationPopup from "./OrgInvitationPopup";

import { IOrgMember } from "../types/org";
import useProject from "../hooks/project/useProject";
import { zIndexBackdrop, zIndexDialog, zIndexStatusUpdate } from "../types/zIndex";
import Spinner from "./Spinner";
import ProjectInvite from "./ProjectInvite";
interface StatusUpdateModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onTaskCreation: (data?: any) => void;
  onClose?: () => void;
  selectedUser?: IOrgMember;
  projecId?: string;
  taskId?: string;
}
function StatusUpdateModal({
  open,
  setOpen,
  onTaskCreation: onComplete,
  onClose,
  selectedUser,
  projecId,
  taskId,
}: StatusUpdateModalProps) {
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const { selected } = useOrgs();
  const [memo, setMemo] = useState("");
  const { revalidate } = useMySubtasks();
  const { data: members, isLoading: orgmembersLoading } = useOrgMembers(selected);
  const [isLoading, setIsLoading] = useState(false);
  const [subTaskId, setSubtaskId] = useState<string | undefined>(taskId);
  const [projectId, setProjectId] = useState<string | undefined>(projecId);
  const presentDay = new Date().toISOString();
  const [dueDate, setDueDate] = useState<string | undefined>(presentDay);
  const [taskName, SetTaskName] = useState<string>("Please update status");
  const navigate = useNavigate();
  const [users, setUsers] = useState<IOrgMember | undefined>(
    selectedUser ? selectedUser : undefined,
  );
  useEffect(() => {}, [taskId, projecId]);
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const handleTaskCreate = () => {
    setIsLoading(true);
    createProjectSubtask({
      name: taskName,
      org_id: onboardingData._id,
      task_id: subTaskId || undefined,
      project_id: projectId ? projectId : undefined,
      due_date: dueDate || new Date().toISOString(),
      content: memo ? memo : undefined,
    })
      .then(() => {
        navigate(`/my-tasks`);
        revalidate();
      })
      .catch((error) => {
        GibooToast({
          type: "failure",
          message: `We apologize for the inconvenience. Please try again.`,
        });
      })
      .finally(() => {
        setIsLoading(false);
        onComplete();
      });
  };

  const renderContent = () => {
    const renderRow = (label: ReactNode, value: ReactNode) => {
      return (
        <div className="grid grid-cols-[77px_auto] place-items-stretch items-center gap-9">
          {label}
          {value}
        </div>
      );
    };
    return (
      <div className="flex flex-col">
        <div className="flex flex-col gap-4">
          <ClickToEditInput
            initalValue={taskName}
            placeholder="Enter status"
            height="!h-[30px]"
            wrapperClass="max-w-[350px]"
            textInputClass="!max-w-[350px]"
            textClass="!px-0 "
            onChange={(val) => {
              SetTaskName(val);
            }}
            withoutEditIcon
            withOutBorder
            noValueLabel="Click to add status"
          />
          {renderRow(
            <p className="font-poppins text-xs text-gray-700">For</p>,
            <UserPicker
              value={users}
              setSelected={(users) => {
                setUsers(users);
              }}
            />,
          )}
          {renderRow(
            <p className="font-poppins text-xs text-gray-700">Due date</p>,
            <div className="flex !h-[25px] max-w-fit items-center hover:cursor-pointer">
              <DateEditor
                dueDate={dueDate}
                onChange={(date) => {
                  setDueDate(date ? date : undefined);
                }}
              />
            </div>,
          )}
          {renderRow(
            <p className="font-poppins text-xs text-gray-700">Projects</p>,
            <div className="flex !h-[25px] w-full content-center">
              <ProjectName projectId={projecId} />
            </div>,
          )}
          {projectId &&
            renderRow(
              <p className="font-poppins text-xs text-gray-700">Task under</p>,
              <div className="flex !h-[25px] w-full content-center">
                <TaskUnderSelector
                  taskId={taskId}
                  projectId={projectId}
                  onSelect={(data) => setSubtaskId(data)}
                />
              </div>,
            )}
        </div>
        {/* Comment */}
        <div className="mt-5 flex h-fit min-h-[220px] w-full flex-col gap-4">
          <div className="flex w-full">
            <h5 className="whitespace-nowrap font-poppins text-xs font-normal">Message</h5>
          </div>
          <div>
            <UserMentionsInput
              id="note"
              value={memo}
              placeholder="Please update ..."
              noOfRows={3}
              handleOnChange={(event, newValue, newPlainTextValue, mentions) => {
                if (mentions?.[0]?.id === "CUSTOM") {
                  return;
                }
                setMemo(event.target.value);
              }}
              onAdd={(id) => {
                if (id === "CUSTOM") {
                  setShowInvite(true);
                }
              }}
              customComponent={
                <div className="w-full text-center font-poppins text-xs text-gray-900">
                  Invite member
                </div>
              }
              // disabled={!hasEditPermission}
              autoFocus={true}
              options={[
                ...(members
                  .filter((i) => i._id !== user?._id)
                  .map((i) => ({ id: i._id, display: `${i.firstname} ${i.lastname}` })) as []),
                { id: "CUSTOM", display: `Invite Member` },
              ]}
              className="!mb-0 !min-h-[160px] !pb-0"
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {onboardingData && showInvite && projectId && (
        <ProjectInvite
          from_for_mixpanel={"owner_in_project_progress_status"}
          projectId={projectId}
          showInvite={showInvite}
          handleClose={() => setShowInvite(false)}
        />
      )}
      <Dialog open={open} onClose={() => null} className="">
        <div
          className={classNames("fixed inset-0 bg-black/30", zIndexBackdrop)}
          aria-hidden="true"
        />
        <div
          className={classNames(
            "fixed inset-0 flex w-screen items-center justify-center p-4",
            zIndexDialog,
          )}
        >
          <div
            className={classNames(
              "flex min-h-full items-center justify-center p-4",
              zIndexStatusUpdate,
            )}
          >
            <Dialog.Panel className="mx-auto my-[50px] min-h-[500px] !w-[760px] rounded bg-white">
              <div className="flex h-full w-full flex-col">
                <div className="flex justify-between border-b p-5">
                  <h5 className="font-poppins text-xl font-semibold text-gray-900">
                    Request status update
                  </h5>
                  <PlainButton
                    id={`btn-update-image-close`}
                    handleOnClick={() => {
                      onClose?.();
                      setOpen(false);
                    }}
                    leftIconClass={<CloseButton />}
                  />
                </div>
                <div className="w-full px-7 pt-7">{renderContent()}</div>
                <div className="flex w-full items-center justify-between border-t border-gray-200 p-5">
                  <div />
                  <Button
                    id="btn-loi-close"
                    className="!h-[37px]"
                    color="purple"
                    label={isLoading ? "Creating" : "Create task"}
                    loading={isLoading}
                    handleOnClick={() => {
                      handleTaskCreate();
                    }}
                  />
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
export default StatusUpdateModal;

interface IDateEditor {
  wrapperClassName?: string;
  dueDate: string | undefined;
  onChange: (val: string | undefined) => void;
}
function DateEditor({ wrapperClassName, dueDate, onChange }: IDateEditor) {
  const [value, setValue] = useState<Date | undefined>(dueDate ? new Date(dueDate) : new Date());
  const [open, setOpen] = useState(true);
  return (
    <div className="">
      {open ? (
        <p
          className="font-poppins text-xs text-black"
          onClick={() => {
            setOpen((prev) => !prev);
          }}
        >
          Today
        </p>
      ) : (
        <DatePicker
          className={classNames(" !z-[888] !h-[30px] !w-full ")}
          value={value}
          size="xs"
          oneTap
          editable={true}
          cleanable={true}
          placeholder="MM/DD/YYYY"
          onChange={(value) => {
            if (value) {
              setValue(value);
              onChange(value.toISOString());
            } else {
              setValue(undefined);
              onChange(undefined);
            }
          }}
        />
      )}
    </div>
  );
}
function ProjectName({ projectId }: { projectId?: string }) {
  const { data } = useProject(projectId);
  return (
    <>
      <div className="max-w-[300px]">
        <p
          title={data.name}
          className="!h-[25px] min-w-[200px]  max-w-[300px] truncate !text-sm !text-gray-700"
        >
          {data.name}
        </p>
      </div>
    </>
  );
}
function TaskUnderSelector({
  onSelect,
  projectId,
  taskId,
}: {
  taskId?: string;
  projectId: string | undefined;
  onSelect: (item: string | undefined) => void;
}) {
  const { data: tasks, isLoading } = useTasks(projectId);
  const [selected, setSelected] = useState<IProjectTask>();
  useEffect(() => {
    if (isLoading || !projectId) return;
    setSelected(tasks.find((i) => i._id === taskId));
  }, [tasks, taskId, isLoading, projectId]);

  return (
    <div className="min-w-[122px] max-w-[500]">
      <SingleSelector<IProjectTask>
        id={`input-task-selector`}
        value={selected}
        inputContainerClass={
          "!h-[25px] !min-h-[25px]  min-w-[200px]  max-w-[500] !border-none !text-gray-700 !text-sm !px-0"
        }
        onChange={(v) => {
          setSelected(v ? v : undefined);
          onSelect(v ? v._id : undefined);
        }}
        placeholderClass="!text-gray-700 !text-xs"
        compact
        data={tasks}
        isClearable={false}
        dropdownIcon={true}
        placeholder="Not assigned"
        isSearchable={false}
        renderItem={(i) => (
          <TaskName task_id={i._id || ""} className="text-xs" project_id={projectId} />
        )}
        renderButton={(i) => (
          <TaskName task_id={i._id || ""} className="text-xs" project_id={projectId} />
        )}
        renderNoOption={() => (
          <GotoSmartSearchContext type="virtual_grant" project_id={projectId}>
            {({ go, isLoading }) => (
              <div
                onClick={() => {
                  go();
                }}
              >
                {isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <p className="cursor-pointer whitespace-nowrap py-2 text-xs hover:bg-gray-100">
                    + grant or funder to project
                  </p>
                )}
              </div>
            )}
          </GotoSmartSearchContext>
        )}
      />
    </div>
  );
}

function UserPicker({
  value,
  setSelected,
}: {
  value?: IOrgMember;
  setSelected: (data: IOrgMember) => void;
}) {
  const whisperRef = useRef<any>(null);
  const { selected } = useOrgs();
  const [user] = useUser();
  const {
    data: members,
    isLoading: orgmembersLoading,
    revalidate: revalidateMembers,
  } = useOrgMembers(selected);
  // const [showInvite, setShowInvite] = useState<boolean>(false);
  return (
    <>
      {/* {showInvite && (
        <OrgInvitationPopup
          show={showInvite}
          onClose={() => {
            setShowInvite(false);
            revalidateMembers();
          }}
        />
      )} */}
      <Whisper
        ref={whisperRef}
        placement="bottomStart"
        controlId="control-id-hover-enterable"
        className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)] "
        trigger={"click"}
        speaker={
          <Popover className="z-[9600] !mt-2 !px-3" arrow={false}>
            <ul className="flex h-auto max-w-[220px] flex-col !gap-3">
              {members.map((user) => {
                return (
                  <li
                    key={user._id}
                    className="w-full cursor-pointer px-3 py-1 hover:bg-gray-100 hover:shadow-sm"
                    onClick={() => {
                      whisperRef?.current?.close();
                      setSelected(user);
                    }}
                  >
                    <UserProfile userId={user._id} />
                  </li>
                );
              })}
              {/* <li className="inline-flex w-[196px] cursor-pointer items-center gap-[10px] rounded bg-gray-100 p-2">
                <span className="h-full self-start pt-1">
                  <i className="gi-md gi-info text-gray-700 " />
                </span>
                <p className="font-poppins text-[10px] text-gray-700">
                  By assigning an owner, you are requesting an update.
                </p>
              </li> */}
            </ul>
          </Popover>
        }
      >
        <div
          className={classNames(
            "grid h-[25px] w-fit place-items-center rounded  text-sm text-gray-900 hover:opacity-80",
          )}
        >
          <UserProfile userId={value?._id || user?._id} />
        </div>
      </Whisper>
    </>
  );
}
