import { useCallback } from "react";
import useSWR from "swr";
import { FunderMemberContact, getFunderContacts } from "../../services/funders.services";
import { swrOptionDedupling5mins } from "../../types/swr";

interface useFunderState {
  data?: {
    contacts?: FunderMemberContact[];
    apply_via_website?: string;
    website?: string;
    loi?: FunderMemberContact;
    general?: FunderMemberContact;
  };
  isLoading: boolean;
  error?: object;
}
export default function useFunderContact(funder_id?: string): useFunderState {
  const fetch = useCallback(
    async (url: string) => {
      if (!url || !funder_id)
        return new Promise<{
          contacts: FunderMemberContact[];
          apply_via_website?: string;
          website?: string;
          loi?: FunderMemberContact;
          general?: FunderMemberContact;
        }>((resolve, reject) => reject());
      return getFunderContacts(funder_id);
    },
    [funder_id],
  );
  const { data, isLoading, error } = useSWR<{
    contacts: FunderMemberContact[];
    apply_via_website?: string;
    website?: string;
    loi?: FunderMemberContact;
    general?: FunderMemberContact;
  }>(
    funder_id ? process.env.REACT_APP_API_URL + `/api/v2/donors/${funder_id}/contact` : null,
    fetch,
    swrOptionDedupling5mins,
  );
  return { data: data || {}, isLoading, error };
}
