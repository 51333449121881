import classNames from "classnames";
import { Popover, Whisper } from "rsuite";
import { INFO_SOLICITATION_OPTION } from "./TagInfo";

export default function TagInvitationOnly({
  invitationOnly,
  className,
}: {
  invitationOnly: boolean;
  className?: string;
}) {
  const tagColorVariant = invitationOnly
    ? "border-[#D76700] bg-[#FFF7EF] text-[#D76700]"
    : "border-[#00A1AB] bg-[#EEFEFF] text-[#00A1AB]";
  const renderItem = () => {
    return (
      <div
        className={classNames(
          "flex w-fit items-center gap-2 rounded border px-2 py-[2px]",
          tagColorVariant,
          className,
        )}
        aria-valuetext={invitationOnly ? "private" : "open"}
      >
        <i
          aria-valuetext={invitationOnly ? "private" : "open"}
          className={classNames(invitationOnly ? "gi-sm gi-send" : "gi-sm gi-funder")}
        />
        <h6 aria-valuetext={invitationOnly ? "private" : "open"}>
          {!invitationOnly ? "Accepts unsolicited proposal" : "Invitation only"}
        </h6>
      </div>
    );
  };
  return (
    <>
      <Whisper
        trigger={"hover"}
        placement={"rightStart"}
        className="shadow-none"
        speaker={
          <Popover
            prefix="tooltip-hover-search-plus"
            className={classNames(
              "!z-[9999] !mt-[-2px] w-[323px] rounded border border-gray-300 bg-gray-50 p-4",
            )}
            arrow={false}
            aria-valuetext={invitationOnly ? "private" : "open"}
          >
            {INFO_SOLICITATION_OPTION[invitationOnly ? "private" : "open" || ""]}
          </Popover>
        }
      >
        {renderItem()}
      </Whisper>
    </>
  );
}
