import classNames from "classnames";
import React from "react";
import "./css/card.scss";
import GibooGradientDiv from "./GibooGradientDiv";
import ICON_SMART_SEARCH from "../assets/images/brain.svg";

interface SmartSearchButtonProps {
  label?: string;
  labelClass?: string;
  wrapperClass?: string;
  handleOnclick: () => void;
}

function SmartSearchButton({
  label = "Smart search",
  labelClass,
  wrapperClass,
  ...props
}: SmartSearchButtonProps) {
  return (
    <GibooGradientDiv
      wrapperClassName="cursor-pointer hover:shadow"
      className="h-full w-full"
      onClick={() => props?.handleOnclick()}
      borderSize={1}
    >
      <div className={classNames("flex items-center justify-center gap-2 px-4 py-2", wrapperClass)}>
        <img src={ICON_SMART_SEARCH} className="h-4 w-4" />
        <h5
          className={classNames("font-poppins text-sm font-semibold text-purple-500", labelClass)}
        >
          {label}
        </h5>
      </div>
    </GibooGradientDiv>
  );
}

export default SmartSearchButton;
