import classNames from "classnames";
import * as React from "react";

type TypeMustHave = "must" | "good" | "exclude";
interface MustHaveSelectorProps {
  id: string;
  // className?: string;
  label?: string;
  itemClassName?: string;
  labelClass?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onChange: (val: TypeMustHave) => void;
  value: TypeMustHave;
}

function MustHaveSelector({
  id,
  value,
  itemClassName,
  label,
  labelClass,
  onChange,
  ...props
}: MustHaveSelectorProps) {
  return (
    <div className={classNames("flex rounded border border-gray-300", itemClassName)} key={id}>
      <div
        id={"GA_TAG_click_musthave"}
        className={classNames(
          "GA_TAG_click_musthave flex h-[20px] w-[80px] min-w-[80px] items-center justify-center rounded-l rounded-s border-r border-gray-300",
          value === "must" ? "bg-purple-500 text-white hover:bg-purple-400" : "hover:bg-gray-100",
        )}
        onClick={() => onChange("must")}
        aria-valuetext={label}
      >
        <h6 className="!text-[10px]" aria-valuetext={label}>
          Must have
        </h6>
      </div>
      <div
        id={"GA_TAG_click_goodtohave"}
        className={classNames(
          "GA_TAG_click_goodtohave flex h-[20px] w-[80px] min-w-[80px] items-center justify-center border-r border-gray-300",
          value === "good" ? "bg-purple-500 text-white hover:bg-purple-400" : "hover:bg-gray-100",
        )}
        onClick={() => onChange("good")}
        aria-valuetext={label}
      >
        <h6 className="!text-[10px]" aria-valuetext={label}>
          Good to have
        </h6>
      </div>
      <div
        id={"GA_TAG_click_exclude"}
        className={classNames(
          "GA_TAG_click_exclude flex h-[20px] w-[80px] min-w-[80px] items-center justify-center rounded-e rounded-r",
          value === "exclude"
            ? "bg-purple-500 text-white hover:bg-purple-400"
            : "hover:bg-gray-100",
        )}
        onClick={() => onChange("exclude")}
        aria-valuetext={label}
      >
        <h6 className="!text-[10px]" aria-valuetext={label}>
          Exclude
        </h6>
      </div>
    </div>
  );
}

export default MustHaveSelector;
