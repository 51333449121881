import classNames from "classnames";
import React from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
interface Iprops {
  customClass?: string | undefined;
  data: string;
  isPreview?: boolean;
  showTitle?: boolean;
}
function LetterPreview({ data, customClass, isPreview = false, showTitle = true }: Iprops) {
  return (
    <div className={classNames("h-full", customClass)}>
      {showTitle && (
        <h5 className="mb-2 font-poppins text-sm font-normal text-gray-700">Document Preview</h5>
      )}
      <div className="h-[calc(100%-60px)] overflow-hidden bg-white pl-[24px] pt-[29px]">
        <ReactQuill
          theme="snow"
          className={classNames(
            "h-full min-h-[650px] overflow-y-auto scrollbar-none",
            isPreview ? "react-quill-readonly-preview" : "react-quill-readonly",
          )}
          value={data}
          readOnly
          modules={{
            toolbar: false,
          }}
        />
      </div>
    </div>
  );
}
export default LetterPreview;
