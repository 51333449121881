import { GeographicFocus, PhilanthropicFocusArea } from "../types/philanthropy";
import { ILocation } from "../types/location";
import { useState } from "react";
import { SearchQuery } from "../types/search";
import PhilanthropicInformationFlexWrapInner from "./PhilanthropicInformationFlexWrapInner";
interface IPhilanthropicInformation {
  id: string;
  focusArea?: PhilanthropicFocusArea[];
  beneficiary?: PhilanthropicFocusArea[];
  program?: PhilanthropicFocusArea[];
  location?: GeographicFocus[];
  priorityFocusArea?: string[];
  priorityBeneficiary?: string[];
  priorityProgram?: string[];
  priorityLocation?: ILocation[];
  secondaryPriorityLocation?: ILocation[];
  containerClass?: string;
  leftContainerClass?: string;
  rightContainerClass?: string;
  tagMatched?: boolean;
  maxLine?: number;
  otherTagLabel?: "other" | "more" | "matched";
  tagSize?: "lg" | "md" | "sm";
  tagOutline?: "none" | "gray" | "color";
  hideShowMoreBtn?: boolean;
  alwaysShowAll?: boolean;
  allowPastGrantSearch?: boolean;
  funder_id?: string;
  searchQuery?: SearchQuery;
  predictedSize?: number;
  enableGroupLocation?: boolean;
  initialShowAll?: number;
  useMinHeight?: boolean;
}
const PhilanthropicInformationFlexWrap = (props: IPhilanthropicInformation) => {
  const [showAll, setShowAll] = useState<boolean>(props.alwaysShowAll || false);
  return (
    <PhilanthropicInformationFlexWrapInner
      // {...props}
      id={props.id}
      focusArea={props.focusArea}
      beneficiary={props.beneficiary}
      program={props.program}
      location={props.location}
      priorityFocusArea={props.priorityFocusArea}
      priorityBeneficiary={props.priorityBeneficiary}
      priorityProgram={props.priorityProgram}
      priorityLocation={props.priorityLocation}
      secondaryPriorityLocation={props.secondaryPriorityLocation}
      containerClass={props.containerClass}
      leftContainerClass={props.leftContainerClass}
      rightContainerClass={props.rightContainerClass}
      tagMatched={props.tagMatched}
      maxLine={props.maxLine}
      otherTagLabel={props.otherTagLabel}
      tagSize={props.tagSize}
      tagOutline={props.tagOutline}
      hideShowMoreBtn={props.hideShowMoreBtn}
      allowPastGrantSearch={props.allowPastGrantSearch}
      funder_id={props.funder_id}
      searchQuery={props.searchQuery}
      predictedSize={props.predictedSize}
      enableGroupLocation={props.enableGroupLocation}
      useMinHeight={props.useMinHeight}
      showAll={showAll}
      setShowAll={setShowAll}
    />
  );
};

export default PhilanthropicInformationFlexWrap;
