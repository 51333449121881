import { Portal } from "@headlessui/react";
import classNames from "classnames";
import {
  Dispatch,
  FormEvent,
  RefObject,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Popover, Whisper } from "rsuite";
import useUserDictionary from "../hooks/useUserDictionary";
import Checkbox from "./checkbox/Checkbox";
import { PathMatch, useLocation, useMatch, useSearchParams } from "react-router-dom";
import GibooSidekickChatComponent from "./GibooSidekickChatComponent";
import { User } from "../app/userSlice";
import { scrollBarClass } from "../utils/classes";
import useUser from "../hooks/useUser";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  sidekickDetailViewScroll,
  sidekickDocumentGenerated,
  sidekickDocumentTarget,
  sidekickDocumentTargetGrant,
  sidekickDocumentDefinition,
  sidekickMyHomeReady,
  sidekickOpen,
  sidekickSearchByName,
  sidekickSearchCount,
  sidekickSearchEstimatedLoadingTime,
  sidekickSearchItemClicked,
  sidekickSearchLoading,
  sidekickSearchOpen,
  sidekickSearchQuery,
  sidekickShowTaskCreation,
  sidekickTargetId,
  sidekickTargetReady,
  sidekickTargetType,
  sidekickWhyMatched,
} from "../app/recoilStore";
import useFakeProgress from "../hooks/useFakeProgress";
import { SearchQueryNullable, parseLocation } from "../types/search";
import { reprLocation } from "../types/location";
import { getStarKey } from "../hooks/useStars";
import useFunderSearchResult from "../hooks/search/useFunderSearchResult";
import useGrantSearchResult from "../hooks/search/useGrantSearchResult";
import useOpencallGrantsDetailFromSameFunder from "../hooks/grant/useOpencallGrantsDetailFromSameFunder";
import useVirtualGrantsFromSameFunder from "../hooks/grant/useVirtualGrantsFromSameFunder";
import Spacer from "./Spacer";
import useGibooUpdateSearchFilter from "../hooks/useGibooUpdateSearchFilter";
import { getExcludedKey } from "../hooks/useExclude";
import { FUNDING_TYPE_OPTION } from "../types/searchFilter";
import { zIndexSidekick } from "../types/zIndex";
import useOnboardingData from "../hooks/useOnboarding";
import useOrgProfile from "../hooks/useOrgProfile";
import useMySubtasks from "../hooks/project/useMySubtasks";
import useProject from "../hooks/project/useProject";
import useTasks from "../hooks/project/useTasks";
import useLocalStorage from "../hooks/useLocalstorage";
import { useAppSelector } from "../app/store";
import { isSearchHistoryFetched, selectSearchHistory } from "../app/searchHistorySlice";
import useOrgSearchQuery from "../hooks/useOrgSearchQuery";
import {
  ChatDataGroup,
  GibooSidekickChatComponentType,
  GibooSidekickChatData,
  GibooSidekickCondition,
  GibooSidekickGAValue,
  GibooSidekickGroup,
  chatData,
} from "../types/sidekick";
import OrgInvitationPopup from "./OrgInvitationPopup";
import VerifyOrganizationModal from "./VerifyOrganizationModal";
import TaskCreationModal from "./TaskCreationModal";
import ProjectInvite from "./ProjectInvite";
import useTask from "../hooks/project/useTask";
import ProjectInformationModal, { ProjectInfoType } from "./ProjectInformationModal";
import useSave from "../hooks/save/useSave";
import { DocumentTypeEnum } from "../types/document";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2, SidekickLocation } from "../mixpanel/mixpanel";
const ENABLE_SHOW_MORE = true;
const getMustHaveTags = (searchParams: URLSearchParams) => {
  const stars = searchParams.getAll("stars");
  const focus_area = searchParams.getAll("focus_area");
  const beneficiary = searchParams.getAll("beneficiary");
  const program = searchParams.getAll("program");
  const must_have = [
    ...stars
      .filter((i) => i.startsWith("focus_area_") && focus_area.includes(i.slice(11)))
      .map((i) => i.slice(11)),
    ...stars
      .filter((i) => i.startsWith("beneficiary_") && beneficiary.includes(i.slice(12)))
      .map((i) => i.slice(12)),
    ...stars
      .filter((i) => i.startsWith("program_") && program.includes(i.slice(8)))
      .map((i) => i.slice(8)),
  ]
    .sort((a, b) => a.localeCompare(b))
    .reduce(
      (prev, cur) => (prev.length > 0 && prev[prev.length - 1] === cur ? prev : [...prev, cur]),
      [] as string[],
    );
  return must_have;
};
const getTags = (searchParams: URLSearchParams) => {
  const stars = searchParams.getAll("stars");
  const excludes = searchParams.getAll("excludes");
  const focus_area = searchParams.getAll("focus_area");
  const beneficiary = searchParams.getAll("beneficiary");
  const program = searchParams.getAll("program");
  const tags = [
    ...focus_area.filter(
      (t) =>
        !stars.includes(getStarKey("focus_area", t)) &&
        !excludes.includes(getExcludedKey("focus_area", t)),
    ),
    ...beneficiary.filter(
      (t) =>
        !stars.includes(getStarKey("beneficiary", t)) &&
        !excludes.includes(getExcludedKey("beneficiary", t)),
    ),
    ...program.filter(
      (t) =>
        !stars.includes(getStarKey("program", t)) &&
        !excludes.includes(getExcludedKey("program", t)),
    ),
  ]
    .sort((a, b) => a.localeCompare(b))
    .reduce(
      (prev, cur) => (prev.length > 0 && prev[prev.length - 1] === cur ? prev : [...prev, cur]),
      [] as string[],
    );
  return tags;
};
const getServiceLocationLabels = (searchParams: URLSearchParams) => {
  const stars = searchParams.getAll("stars");
  const excludes = searchParams.getAll("excludes");
  const normal_service_loc = parseLocation(searchParams, "service_loc")
    .map((l) => reprLocation(l))
    .filter(
      (i) =>
        !stars.includes(getStarKey("service_loc", i)) &&
        !excludes.includes(getExcludedKey("service_loc", i)),
    );
  return normal_service_loc;
};
interface ISidekickContext {
  chatLoading: { [key: string]: boolean };
  setChatLoading: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  setAnswer: (
    group: GibooSidekickGroup,
    id: string,
    data: GibooSidekickChatData,
    replace?: boolean,
  ) => boolean;
  setScrollInitiate: Dispatch<SetStateAction<string>>;
  updateFilter: (diff: SearchQueryNullable) => void;
  setActiveGroup: Dispatch<SetStateAction<string>>;
  param: { [key: string]: string };
  setShowOrgVerify: Dispatch<SetStateAction<boolean>>;
  setShowInvite: Dispatch<SetStateAction<boolean>>;
  setShowTaskCreation: Dispatch<SetStateAction<boolean>>;
  setShowInviteProject: Dispatch<SetStateAction<boolean>>;
  setShowProjectEdit: Dispatch<SetStateAction<boolean>>;
  sidekickLocation: SidekickLocation | undefined;
}
const SidekickContext = createContext<ISidekickContext | undefined>(undefined);
export const useSidekickContext = () => {
  const context = useContext(SidekickContext);
  if (!context) throw new Error("Provider not found");
  useEffect(() => {
    if (
      chatData
        .map((g) => g.group)
        .sort((a, b) => a.localeCompare(b))
        .map((v, i, arr) => i > 0 && arr[i] === arr[i - 1])
        .filter(Boolean).length > 0
    )
      throw new Error("duplicated group");
    if (
      chatData.some(
        (g) =>
          g.data
            .map((c) => c.id)
            .sort((a, b) => a.localeCompare(b))
            .map((v, i, arr) => i > 0 && arr[i] === arr[i - 1])
            .filter(Boolean).length > 0,
      )
    )
      throw new Error("duplicated id");
  }, []);
  return context;
};
function GibooSidekick() {
  const mxEvent = useGibooMixpanel();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [user] = useUser();
  const browserLocation = useLocation();
  const prevBrowserLocation = useRef(browserLocation);
  const [searchParams, setSearchParams] = useSearchParams();
  const isHome = useMatch("/");
  const isSearch = useMatch("/search/*");
  const isSearchNext = useMatch("/search/:id");
  const isFunderView = useMatch("/donors/:id");
  const isGrantView = useMatch("/grants/:id");
  const isNPOView = useMatch("/npos/:id");
  const isOrgProfile = useMatch("/organization");
  const isProject = useMatch("/project/:id/*");
  const isProjectTask = useMatch("/project/:project_id/task/:id");
  const isDocument = useMatch("/project/:project_id/document*");
  const isDocumentFromMyHome = useMatch("/document*");
  const [matches, setMatches] = useState<(PathMatch | null)[]>([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const sidekickLocation: SidekickLocation | undefined = isHome
    ? "myhome"
    : isSearch || isSearchNext
    ? "search"
    : isFunderView
    ? "funder_detail_view"
    : isGrantView
    ? "grant_detail_view"
    : isNPOView
    ? "npo_detail_view"
    : isOrgProfile
    ? "org_profile"
    : !isProject && !isDocument && isProject
    ? "project_dashboard"
    : isProjectTask
    ? "project_task"
    : isDocument
    ? "documentai"
    : undefined;
  useEffect(() => {
    setMatches([
      isHome,
      isSearch || isSearchNext,
      isFunderView,
      isGrantView,
      isNPOView,
      isOrgProfile,
      isProjectTask || isDocument ? null : isProject,
      isProjectTask,
      isDocument || isDocumentFromMyHome,
    ]);
  }, [
    isHome,
    isSearch,
    isSearchNext,
    isFunderView,
    isGrantView,
    isNPOView,
    isOrgProfile,
    isProject,
    isProjectTask,
    isDocument,
    isDocumentFromMyHome,
    setMatches,
  ]);
  const {
    isLoading: isLoadingDoNotShowSidekick,
    value: doNotShowSidekick,
    update,
  } = useUserDictionary("DO_NOT_SHOW_SIDEKICK", false);
  const whisperRef = useRef(null);
  const [param, setParams] = useState<{ [key: string]: string }>({});
  const { value: isNewUser } = useLocalStorage("NEW_USER");
  const [openSidekick, setOpenSidekick] = useRecoilState(sidekickOpen);
  const [forceClose, setForceClose] = useState<boolean>(false);
  const searchOpen = useRecoilValue(sidekickSearchOpen);
  const searchLoading = useRecoilValue(sidekickSearchLoading);
  const searchCount = useRecoilValue(sidekickSearchCount);
  const targetId = useRecoilValue(sidekickTargetId);
  const targetType = useRecoilValue(sidekickTargetType);
  const searchQuery = useRecoilValue(sidekickSearchQuery);
  const searchByName = useRecoilValue(sidekickSearchByName);
  const detailViewScroll = useRecoilValue(sidekickDetailViewScroll);
  const myHomeReady = useRecoilValue(sidekickMyHomeReady);
  const documentGenerated = useRecoilValue(sidekickDocumentGenerated);
  const documentTarget = useRecoilValue(sidekickDocumentTarget);
  const documentTargetGrant = useRecoilValue(sidekickDocumentTargetGrant);
  const documentDefinition = useRecoilValue(sidekickDocumentDefinition);
  const [scrollInitiate, setScrollInitiate] = useState<string>("1");
  const whyMatched = useRecoilValue(sidekickWhyMatched);
  const [activeGroup, setActiveGroup] = useState<string>("");
  const searchItemClicked = useRecoilValue(sidekickSearchItemClicked);
  const [targetReady, setTargetReady] = useRecoilState(sidekickTargetReady);
  const { updateFilter } = useGibooUpdateSearchFilter();
  const [onboardingData] = useOnboardingData();
  const searchHistory = useAppSelector((state) => selectSearchHistory(state));
  const searchHistoryFetched = useAppSelector((state) => isSearchHistoryFetched(state));
  const { data: searchQuerySuggestion } = useOrgSearchQuery();
  const [grantSearchSuggestion, setGrantSearchSuggestion] = useState<
    { query: string; label: string }[]
  >([]);
  const [funderSearchSuggestion, setFunderSearchSuggestion] = useState<
    { query: string; label: string }[]
  >([]);
  const { data: orgProfile } = useOrgProfile(onboardingData._id);
  const { data: subtasks } = useMySubtasks();
  const { data: project, hasEditPermission } = useProject(isProject?.params?.id);
  const { data: tasks, isLoading: isTasksLoading } = useTasks(isProject?.params?.id);
  const { data: task, isLoading: isTaskLoading } = useTask(isProjectTask?.params?.id);
  const { data: subtasksProject, isLoading: isSubtasksLoading } = useMySubtasks(
    isProject?.params?.id,
  );
  const { data: currentSavedList } = useSave(
    targetType === 1 ? targetId : undefined,
    targetType === 1 ? 0 : targetType === 3 ? 3 : 1,
  );
  const { data: funder } = useFunderSearchResult(targetType === 1 ? targetId : undefined);
  const { data: grant } = useGrantSearchResult(
    targetType === 0 || targetType === 2 ? targetId : undefined,
  );
  const { data: opencallData, isLoading: isOpencallLoading } =
    useOpencallGrantsDetailFromSameFunder(
      grant?.type === "grant-page" ? grant.donor_id : undefined,
    );
  const { data: virtualGrants, isLoading: isVirtualGrantsLoading } = useVirtualGrantsFromSameFunder(
    grant?.type !== "grant-page" ? grant?.donor_id : undefined,
  );
  const [chatLoading, setChatLoading] = useState<{ [key: string]: boolean }>({});
  const [answers, setAnswers] = useState<{
    [key: string]: { [key: string]: GibooSidekickChatData };
  }>({});
  const [showMore, setShowMore] = useState<{
    [key: string]: number;
  }>({});
  const [showMoreLoading, setShowMoreLoading] = useState<{ [key: string]: boolean }>({});
  const [filteredChatData, setFilteredChatData] = useState<ChatDataGroup[]>([]);
  const prevFilteredChatDataRef = useRef<ChatDataGroup[]>();
  const [showTaskCreation, setShowTaskCreation] = useRecoilState(sidekickShowTaskCreation);
  const [showOrgVerify, setShowOrgVerify] = useState<boolean>(false);
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const [showProjectEdit, setShowProjectEdit] = useState<boolean>(false);
  const [showInviteProject, setShowInviteProject] = useState<boolean>(false);
  const [currentType, setCurrentType] = useState<ProjectInfoType | undefined>();
  useEffect(() => {
    setAnswers((prev) => ({
      ...prev,
      [GibooSidekickGroup.FUNDER]: {},
      [GibooSidekickGroup.VIRTUAL_GRANT]: {},
      [GibooSidekickGroup.OPENCALL_GRANT]: {},
      [GibooSidekickGroup.NPO]: {},
    }));
  }, [targetId, targetType]);
  useEffect(() => {
    if (
      prevBrowserLocation.current?.pathname.split("/").slice(0, 2).join("/") !==
      browserLocation.pathname.split("/").slice(0, 2).join("/")
    )
      setForceClose(false);
    prevBrowserLocation.current = browserLocation;
    setActiveGroup([""].join(""));
    setChatLoading(chatData.reduce((prev, g) => ({ ...prev, [g.group]: true }), {}));
    setTimeout(() => setChatLoading({}), 800);
  }, [browserLocation]);
  useEffect(() => {
    setTimeout(() => setTargetReady(true), 500);
  }, [searchItemClicked]);
  useEffect(() => {
    setAnswers((prev) => ({
      ...prev,
      [GibooSidekickGroup.SEARCH]: {},
    }));
  }, [searchParams]);
  useEffect(() => {
    if (searchLoading)
      setAnswers((prev) =>
        Object.keys(prev[GibooSidekickGroup.SEARCH_LOADING] || {}).length === 0
          ? prev
          : {
              ...prev,
              [GibooSidekickGroup.SEARCH_LOADING]: {},
            },
      );
  }, [searchLoading]);
  useEffect(() => {
    if (!searchQuerySuggestion || !searchHistoryFetched) {
      setGrantSearchSuggestion([]);
      setFunderSearchSuggestion([]);
      return;
    }
    const textToDate = searchHistory.reduce(
      (prev, h) => ({ ...prev, [h.text_query]: new Date(h.updated_at).getTime() }),
      {} as { [key: string]: number },
    );
    const _raw_funder = searchQuerySuggestion.filter(
      (t) => t.toLowerCase().startsWith("funder ") || t.toLowerCase().startsWith("funders "),
    );
    const _raw_grant = searchQuerySuggestion.filter(
      (t) => t.toLowerCase().startsWith("grant ") || t.toLowerCase().startsWith("grants "),
    );
    const _raw_seeking_grant = searchQuerySuggestion.filter(
      (t) =>
        t.toLowerCase().startsWith("seeking grants") ||
        t.toLowerCase().startsWith("seeking grants"),
    );
    const _raw_support = searchQuerySuggestion.filter(
      (t) => t.toLowerCase().startsWith("support ") || t.toLowerCase().startsWith("supporting "),
    );
    const _raw_funding = searchQuerySuggestion.filter((t) =>
      t.toLowerCase().startsWith("funding opportuni"),
    );
    const grantSuggestion = [
      ..._raw_grant.map((t) => ({ query: t, label: t.split(" ").slice(1).join(" ") })),
      ..._raw_seeking_grant.map((t) => ({ query: t, label: t.split(" ").slice(2).join(" ") })),
      ..._raw_support.map((t) => ({ query: t, label: t })),
      ..._raw_funding.map((t) => ({ query: t, label: t.split(" ").slice(1).join(" ") })),
    ].sort((a, b) => (textToDate[a.query] || 0) - (textToDate[b.query] || 0));
    const funderSuggestion = [
      ..._raw_funder.map((t) => ({ query: t, label: t.split(" ").slice(1).join(" ") })),
      ..._raw_support.map((t) => ({ query: t, label: t })),
      ..._raw_funding.map((t) => ({ query: t, label: t.split(" ").slice(1).join(" ") })),
    ].sort((a, b) => (textToDate[a.query] || 0) - (textToDate[b.query] || 0));
    const oneFocusAreaOrBeneficiary = [
      ...onboardingData.beneficiary,
      ...onboardingData.focus_area,
      ...onboardingData.program,
    ][0];
    setGrantSearchSuggestion([
      ...grantSuggestion,
      {
        query: `grants supporting ${oneFocusAreaOrBeneficiary}`,
        label: `supporting ${oneFocusAreaOrBeneficiary}`,
      },
    ]);
    setFunderSearchSuggestion([
      ...funderSuggestion,
      {
        query: `funders supporting ${oneFocusAreaOrBeneficiary}`,
        label: `supporting ${oneFocusAreaOrBeneficiary}`,
      },
    ]);
  }, [searchQuerySuggestion, searchHistory, onboardingData, searchHistoryFetched]);
  useEffect(() => {
    const text_query = searchParams.get("text_query");
    const mission = searchParams.get("mission");
    const type = searchParams.get("type");
    const search_query = text_query ? `“${text_query}“` : mission ? "My organization" : "";
    const search_target = `${
      type?.includes("grant")
        ? "grants"
        : type?.includes("funder")
        ? "funders"
        : type?.includes("npo")
        ? "nonprofits"
        : "all"
    }${search_query ? " relevant to" : ""}`;
    const normal_service_loc = getServiceLocationLabels(searchParams);
    const service_loc_str = normal_service_loc.length > 0 ? normal_service_loc[0] : "New York, NY";
    const must_have_tags = getMustHaveTags(searchParams);
    const must_have_taxonomy_str =
      must_have_tags.length > 1
        ? `<b>“${must_have_tags[0]}“</b> and <b>“${must_have_tags[1]}“</b>`
        : must_have_tags.length > 0
        ? `<b>“${must_have_tags[0]}“</b>`
        : "education";
    const tags = must_have_tags.length > 0 ? must_have_tags : getTags(searchParams);
    const taxonomy_str = tags.length > 0 ? tags[0] : "education";
    const grant_name = grant?.name || "";
    const funder_name = funder?.name || grant?.donor?.name || "";
    const opencall_id = opencallData && opencallData.length > 0 ? opencallData[0]._id : "";
    const virtual_grant_id = virtualGrants && virtualGrants.length > 0 ? virtualGrants[0] : "";
    const funding_type = searchParams.get("funding_type");
    const funding_type_unchecked_str =
      funding_type && funding_type.length > 0
        ? FUNDING_TYPE_OPTION.find((o) => !funding_type.includes(o.value))?.label.split(" (")[0] ||
          "General operating grant"
        : "General operating grant";
    const search_grant_suggestion =
      grantSearchSuggestion.length > 0 ? grantSearchSuggestion[0].label : "";
    const search_funder_suggestion =
      funderSearchSuggestion.length > 0 ? funderSearchSuggestion[0].label : "";
    const search_grant_suggestion_query =
      grantSearchSuggestion.length > 0 ? grantSearchSuggestion[0].query : "";
    const search_funder_suggestion_query =
      funderSearchSuggestion.length > 0 ? funderSearchSuggestion[0].query : "";
    const create_or_view_subtasks = subtasks.length > 0 ? "View all tasks" : "Create a task";
    const document_ai_target =
      tasks.filter((t) => t.target_type === 1).length > 0
        ? "a grant proposal"
        : "a letter to the funder";
    const go_to_document_ai_label =
      tasks.filter((t) => t.target_type === 1).length > 0
        ? "Message Giboo AI for commands to write a grant proposal."
        : "Choose the type of letter you wish to create.";
    const project_id = isProject?.params?.id || "";
    const target_type = task?.target_type === 1 ? "grant" : "funder";
    const target_detail_url =
      task?.target_type === 1 ? `/grants/${task.target_id}` : `/donors/${task.target_id}`;
    const answer_save_list =
      currentSavedList.length === 0
        ? "You can <b>save</b> this opportunity for later. Simply click to add to your saved list to take a look later."
        : "Explore your save list management options. Click to view your saved list.";
    const btn_label_save_list =
      currentSavedList.length === 0 ? "Add to a saved list" : "See saved list";
    const _document_type = documentDefinition?.short_name || documentDefinition?.name;
    const document_type =
      _document_type && _document_type !== "Custom document" ? _document_type : "grant proposal";
    setParams({
      firstname: user?.firstname || "",
      lastname: user?.lastname || "",
      search_query,
      search_target,
      search_count: `${searchCount.toLocaleString()}`,
      service_loc_str,
      funder_name,
      grant_name,
      opencall_id,
      virtual_grant_id,
      must_have_taxonomy_str,
      funding_type_unchecked_str,
      taxonomy_str,
      search_grant_suggestion,
      search_funder_suggestion,
      search_grant_suggestion_query,
      search_funder_suggestion_query,
      create_or_view_subtasks,
      document_ai_target,
      go_to_document_ai_label,
      project_id,
      target_type,
      target_detail_url,
      answer_save_list,
      btn_label_save_list,
      document_type,
    });
  }, [
    user,
    searchParams,
    searchCount,
    funder,
    grant,
    opencallData,
    virtualGrants,
    grantSearchSuggestion,
    funderSearchSuggestion,
    subtasks,
    tasks,
    task,
    currentSavedList,
    documentDefinition,
  ]);

  const setAnswer = useCallback(
    (group: GibooSidekickGroup, id: string, data: GibooSidekickChatData, replace = true) => {
      const needToReplace = !answers[group]?.[id] || replace;
      setActiveGroup(`${group}-${id}`);
      // setActiveGroup(group);
      setAnswers((prev) => ({
        ...prev,
        [group]: {
          ...prev[group],
          [id]: needToReplace ? { ...data, updated_at: new Date() } : prev[group]?.[id],
        },
      }));
      return needToReplace;
    },
    [answers, setAnswers, setActiveGroup],
  );
  const handleScrollEvent = useCallback((ref: RefObject<HTMLDivElement>, activeGroup: string) => {
    if (ref.current) {
      const element = activeGroup
        ? document.getElementById(`chat-group-${activeGroup}`)
        : undefined;
      if (element) {
        let distance = -10 + (!activeGroup.includes("-") ? element.offsetHeight || 0 : 0);
        let el: HTMLElement | null = element;
        const arr = [];
        while (el && ref.current && el !== ref.current) {
          // console.log({
          //   from: el,
          //   to: el.parentElement,
          //   d: el.offsetTop - (el.parentElement?.offsetTop || 0),
          // });
          arr.push(el.offsetTop - (el.parentElement?.offsetTop || 0));
          distance += el.offsetTop - (el.parentElement?.offsetTop || 0);
          el = el.parentElement;
        }
        // if (process.env.REACT_APP_ENV === "DEV")
        //   console.log(`go to element ${activeGroup} [${distance}] arr:${arr}`);
        ref.current?.scrollTo({
          top: distance, // - ref.current.offsetHeight,
          behavior: "smooth",
        });
      } else {
        // if (process.env.REACT_APP_ENV === "DEV")
        //   console.log(`go to bottom ${activeGroup} [${ref.current.scrollHeight}]`);
        const gap = ref.current.scrollHeight - ref.current.scrollTop;
        ref.current.scrollTop =
          gap > 800
            ? ref.current.scrollHeight - 800
            : gap < -800
            ? ref.current.scrollHeight + 800
            : ref.current.scrollTop - 1;
        ref.current?.scrollTo({
          top: ref.current?.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      handleScrollEvent(chatContainerRef, activeGroup);
    }, 600);
  }, [answers, chatLoading, handleScrollEvent, showMore]);
  useEffect(() => {
    handleScrollEvent(chatContainerRef, activeGroup);
  }, [
    filteredChatData,
    openSidekick,
    // answers,
    matches,
    // chatLoading,
    scrollInitiate,
    whyMatched,
    activeGroup,
    handleScrollEvent,
  ]);

  const checkShowCondition = useCallback(
    (show_condition?: (GibooSidekickCondition | GibooSidekickCondition[])[]) => {
      const checkFunction = (c: GibooSidekickCondition): boolean => {
        switch (c) {
          case GibooSidekickCondition.SEARCH_OPEN: {
            return searchOpen;
          }
          case GibooSidekickCondition.SEARCH_CLOSE: {
            return !searchOpen && myHomeReady;
          }
          case GibooSidekickCondition.MYHOME_READY: {
            return myHomeReady;
          }
          case GibooSidekickCondition.SEARCH_COMPLETED: {
            return (
              !searchLoading &&
              ((!isSearch && !isSearchNext) || searchParams.get("done_tagging") === "true")
            );
          }
          case GibooSidekickCondition.SEARCH_VIRTUAL_GRANT: {
            return !searchLoading && searchParams.get("type") === "virtual_grant";
          }
          case GibooSidekickCondition.SEARCH_GRANT: {
            return !searchLoading && searchParams.get("type") === "grant";
          }
          case GibooSidekickCondition.SEARCH_FUNDER: {
            return !searchLoading && searchParams.get("type") === "funder";
          }
          case GibooSidekickCondition.SEARCH_NPO: {
            return !searchLoading && searchParams.get("type") === "npo";
          }
          case GibooSidekickCondition.VIEW_VIRTUAL_GRANT: {
            return targetId && targetType === 0 ? true : false;
          }
          case GibooSidekickCondition.VIEW_GRANT: {
            return targetId && targetType === 2 ? true : false;
          }
          case GibooSidekickCondition.VIEW_FUNDER: {
            return targetId && targetType === 1 ? true : false;
          }
          case GibooSidekickCondition.VIEW_NPO: {
            return targetId && targetType === 3 ? true : false;
          }
          case GibooSidekickCondition.TARGET_EXIST: {
            return targetId ? true : false;
          }
          case GibooSidekickCondition.HAS_OPENCALL_GRANT: {
            return opencallData && opencallData.length > 0 ? true : false;
          }
          case GibooSidekickCondition.HAS_VIRTUAL_GRANT: {
            return virtualGrants && virtualGrants.length > 0 ? true : false;
          }
          case GibooSidekickCondition.ANSWERED_OPENCALL_GRANT: {
            return answers[GibooSidekickGroup.VIRTUAL_GRANT]["opencall"] ? true : false;
          }
          case GibooSidekickCondition.ANSWERED_VIRTUAL_GRANT: {
            return answers[GibooSidekickGroup.OPENCALL_GRANT]["virtual_grant"] ? true : false;
          }
          case GibooSidekickCondition.WHY_MATCHED_AVAILABLE: {
            return searchQuery && !searchByName ? true : false;
          }
          case GibooSidekickCondition.EXPLORE_DETAIL_VIEW: {
            const page = isSearchNext?.params?.id;
            const selected = searchParams.get("selected");
            return (
              targetReady &&
              ((!isSearch && !isSearchNext) ||
                (page && page !== "1") ||
                (selected && selected !== "0") ||
                searchItemClicked >= 0 ||
                detailViewScroll)
            );
          }
          case GibooSidekickCondition.HAS_GOOD_TO_HAVE_SERVICE_LOCATION: {
            return getServiceLocationLabels(searchParams).length > 0;
          }
          case GibooSidekickCondition.HAS_MUST_HAVE_TAGS: {
            return getMustHaveTags(searchParams).length > 0;
          }
          case GibooSidekickCondition.SEARCH_NOT_ROLLING: {
            const grant_deadline_filter = searchParams.getAll("grant_deadline_filter");
            return (
              grant_deadline_filter.length === 0 ||
              grant_deadline_filter.length === 2 ||
              !grant_deadline_filter.includes("rolling")
            );
          }
          case GibooSidekickCondition.ORG_EDIT_PERMISSION: {
            return onboardingData.role >= 4;
          }
          case GibooSidekickCondition.ORG_NOT_VERIFIED: {
            return onboardingData.verified ? false : true;
          }
          case GibooSidekickCondition.ORG_FINANCIAL_NOT_DONE: {
            return orgProfile.financial_completed ? false : true;
          }
          case GibooSidekickCondition.ORG_DIVERSITY_NOT_DONE: {
            return orgProfile.diversity_completed ? false : true;
          }
          case GibooSidekickCondition.ORG_HAS_MY_TASK: {
            return subtasks.length > 0 ? true : false;
          }
          case GibooSidekickCondition.PROJECT_INFOMATION_NOT_FILLED: {
            return isProject && currentType ? true : false;
          }
          case GibooSidekickCondition.PROJECT_EDIT_PERMISSION: {
            return hasEditPermission ? true : false;
          }
          case GibooSidekickCondition.PROJECT_FUNDER_NOT_ADDED: {
            return (!isTasksLoading || tasks.length > 0) &&
              tasks.filter((t) => t.target_type === 0).length === 0
              ? true
              : false;
          }
          case GibooSidekickCondition.PROJECT_FUNDER_ADDED: {
            return (!isTasksLoading || tasks.length > 0) &&
              tasks.filter((t) => t.target_type === 0).length > 0
              ? true
              : false;
          }
          case GibooSidekickCondition.PROJECT_GRANT_NOT_ADDED: {
            return (!isTasksLoading || tasks.length > 0) &&
              tasks.filter((t) => t.target_type === 1).length === 0
              ? true
              : false;
          }
          case GibooSidekickCondition.PROJECT_GRANT_ADDED: {
            return (!isTasksLoading || tasks.length > 0) &&
              tasks.filter((t) => t.target_type === 1).length > 0
              ? true
              : false;
          }
          case GibooSidekickCondition.PROJECT_DOCUMENT_NOT_CREATED: {
            return (!isSubtasksLoading || subtasksProject.length > 0) &&
              subtasksProject.filter((t) => t.document_id).length === 0
              ? true
              : false;
          }
          case GibooSidekickCondition.NOT_NEW_USER: {
            return isNewUser ? false : true;
          }
          case GibooSidekickCondition.NEW_USER: {
            return isNewUser ? true : false;
          }
          case GibooSidekickCondition.SEARCH_FUNDER_SUGGESTION_EXIST: {
            return funderSearchSuggestion.length > 0;
          }
          case GibooSidekickCondition.SEARCH_GRANT_SUGGESTION_EXIST: {
            return grantSearchSuggestion.length > 0;
          }
          case GibooSidekickCondition.PROJECT_TASK_LOADED: {
            return isTaskLoading || !task._id ? false : true;
          }
          case GibooSidekickCondition.NOT_SAVED: {
            return currentSavedList.length === 0 ? true : false;
          }
          case GibooSidekickCondition.SAVE_QUESTION_OPENED: {
            return answers?.[GibooSidekickGroup.FUNDER]?.[GibooSidekickGAValue.CLICK_SAVE] ||
              answers?.[GibooSidekickGroup.OPENCALL_GRANT]?.[GibooSidekickGAValue.CLICK_SAVE] ||
              answers?.[GibooSidekickGroup.VIRTUAL_GRANT]?.[GibooSidekickGAValue.CLICK_SAVE]
              ? true
              : false;
          }
          case GibooSidekickCondition.DOCUMENT_GENERATED: {
            return documentGenerated;
          }
          case GibooSidekickCondition.DOCUMENT_FOR_FUNDER: {
            return documentTarget === 0;
          }
          case GibooSidekickCondition.DOCUMENT_FOR_NPO: {
            return documentTarget === 2;
          }
          case GibooSidekickCondition.DOCUMENT_FOR_VIRTUAL_GRANT: {
            return documentTarget === 1 && documentTargetGrant === "virtual";
          }
          case GibooSidekickCondition.DOCUMENT_FOR_OPENCALL_GRANT: {
            return documentTarget === 1 && documentTargetGrant === "opencall";
          }
          case GibooSidekickCondition.DOCUMENT_LOI: {
            return (
              documentDefinition?.type === DocumentTypeEnum.CUSTOM ||
              documentDefinition?.type === DocumentTypeEnum.OUTREACHING_INTRODUCTORY_EMAIL ||
              documentDefinition?.type === DocumentTypeEnum.OUTREACHING_INTRODUCTORY_LINKEDIN_MSG ||
              documentDefinition?.type ===
                DocumentTypeEnum.OUTREACHING_EOY_GIVING_APPEAL_TO_A_NEW_FUNDER ||
              documentDefinition?.type === DocumentTypeEnum.OUTREACHING_LOI ||
              documentDefinition?.type === DocumentTypeEnum.OUTREACHING_PHONE_CALL_SCRIPT
            );
          }
          default:
            return false;
        }
      };
      if (!show_condition || show_condition.length === 0) return true;
      return show_condition.every((c) => {
        return Array.isArray(c) ? c.some((cc) => checkFunction(cc)) : checkFunction(c);
      });
    },
    [
      isSearch,
      isSearchNext,
      isProject,
      searchOpen,
      searchLoading,
      searchParams,
      targetId,
      opencallData,
      virtualGrants,
      answers,
      targetType,
      targetId,
      searchQuery,
      searchByName,
      detailViewScroll,
      targetReady,
      searchItemClicked,
      onboardingData,
      orgProfile,
      subtasks,
      tasks,
      subtasksProject,
      isTasksLoading,
      isSubtasksLoading,
      isNewUser,
      grantSearchSuggestion,
      funderSearchSuggestion,
      currentType, // needs filling in project information
      hasEditPermission,
      currentSavedList,
      myHomeReady,
      documentGenerated,
      documentTarget,
      documentTargetGrant,
      documentDefinition,
    ],
  );
  useEffect(() => {
    setFilteredChatData(
      chatData.filter(
        (group) =>
          checkShowCondition(group.show_condition) &&
          group.matchIndex.some((i) => i < matches.length && matches[i]),
      ),
    );
  }, [chatData, matches, checkShowCondition]);
  useEffect(() => {
    if ((prevFilteredChatDataRef.current?.length || 0) !== filteredChatData.length) {
      setActiveGroup("");
    }
    prevFilteredChatDataRef.current = filteredChatData;
  }, [filteredChatData]);
  // console.log({
  //   filtered: filteredChatData.map((g) => ({
  //     ...g,
  //     data: g.data.filter(
  //       (d) =>
  //         (d.type === GibooSidekickChatComponentType.ACTION_BUTTON ||
  //           d.type === GibooSidekickChatComponentType.INFORMATION_BUTTON) &&
  //         checkShowCondition(d.show_condition),
  //     ),
  //   })),
  //   answers,
  // });
  useEffect(() => {
    if (isLoadingDoNotShowSidekick) return;
    if (
      !doNotShowSidekick &&
      !forceClose &&
      filteredChatData
        .map((group) => group.data.some((d) => checkShowCondition(d.show_condition)))
        .filter(Boolean).length > 0
    )
      setOpenSidekick(true);
    else {
      const group = answers[activeGroup.split("-")[0]];
      if (
        filteredChatData
          .map((group) => group.data.some((d) => checkShowCondition(d.show_condition)))
          .filter(Boolean).length === 0 ||
        forceClose ||
        (group && Object.keys(group).length === 0)
      )
        setOpenSidekick(false);
    }
  }, [isLoadingDoNotShowSidekick, matches, filteredChatData, checkShowCondition]);
  // useEffect(() => {
  //   if (matches.every((m) => !m)) {
  //     setOpenSidekick(false);
  //   }
  // }, [browserLocation]);
  useEffect(() => {
    if (!project._id) {
      return;
    }
    const mandatoryFields = [
      { type: "NAME", value: project.name },
      {
        type: "TIMELINE",
        value: project.project_timeline !== null && project.project_timeline !== undefined,
      },
      { type: "GRANT_AMOUNT", value: project.fund_to_raise > 0 },
      { type: "FOCUS_AREA", value: project.problem_desc },
      { type: "BENEFICIARY", value: project.beneficiary_desc },
    ];
    const currentType = mandatoryFields.find((i) => !i.value);
    setCurrentType(currentType?.type as ProjectInfoType);
  }, [project]);
  const renderHeader = () => {
    return (
      <div
        className={classNames(
          "flex h-12 w-full cursor-pointer items-center justify-between rounded-t-2xl border border-gray-300 bg-gray-100 px-4 hover:bg-gray-200",
        )}
        onClick={() => {
          if (openSidekick) {
            mxEvent(MIXPANEL_EVENTS_V2.sidekick.close.clicked, {
              sidekickLocation,
              location: browserLocation.pathname,
            });
            setOpenSidekick(false);
            setForceClose(true);
          } else {
            mxEvent(MIXPANEL_EVENTS_V2.sidekick.open.clicked, {
              sidekickLocation,
              location: browserLocation.pathname,
            });
            setOpenSidekick(true);
            setForceClose(false);
          }
        }}
      >
        <div className="flex items-center gap-2 font-semibold text-gray-900">
          <h5>Giboo Sidekick</h5>
          <i className="gi-sidekick2 text-lg" />
        </div>
        <div className="flex items-center gap-2">
          <Whisper
            ref={whisperRef}
            trigger={"click"}
            placement={!openSidekick ? "topEnd" : "bottomEnd"}
            controlId="control-id-hover-enterable"
            className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)]"
            // onClose={() => setOpenMenu(false)}
            speaker={
              <Popover
                prefix="custom-tooltip"
                className={classNames("z-[9101] flex flex-col px-3 py-1")}
                arrow={false}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    id="input-sidekick-do-not-show"
                    checked={doNotShowSidekick}
                    labelClass="!text-xs"
                    onChange={() => {
                      mxEvent(MIXPANEL_EVENTS_V2.sidekick.do_not_show_again.clicked, {
                        sidekickLocation,
                        location: browserLocation.pathname,
                        doNotShowAgain: !doNotShowSidekick,
                      });
                      if (!doNotShowSidekick) {
                        setOpenSidekick(false);
                        setForceClose(true);
                        (whisperRef?.current as any)?.close();
                      }
                      update(!doNotShowSidekick);
                      // (whisperRef?.current as any)?.close();
                    }}
                    label="Do not show again"
                  />
                </div>
                {process.env.REACT_APP_ENV === "DEV" && (
                  <div
                    className="flex h-8 w-full cursor-pointer items-center gap-2 px-2 py-1 hover:bg-gray-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      setAnswers(chatData.reduce((prev, g) => ({ ...prev, [g.group]: {} }), {}));
                      setShowMore(chatData.reduce((prev, g) => ({ ...prev, [g.group]: 2 }), {}));
                      (whisperRef?.current as any)?.close();
                    }}
                  >
                    <p className="font-poppins text-xs text-gray-700">reset(testing purpose)</p>
                  </div>
                )}
              </Popover>
            }
          >
            <div
              className="flex h-6 w-6 cursor-pointer flex-col items-center justify-center rounded-full hover:bg-gray-300"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <i className="gi gi-meatballs-menu" />
            </div>
          </Whisper>
          <div
            className="flex h-6 w-6 cursor-pointer flex-col items-center justify-center rounded-full hover:bg-gray-300"
            onClick={(e) => {
              // e.stopPropagation();
            }}
          >
            <i className={classNames("gi", !openSidekick ? "gi-angle-up" : "gi-angle-down")} />
          </div>
        </div>
      </div>
    );
  };
  const renderContent = () => {
    return (
      <div
        className={classNames(
          "flex h-fit w-full flex-col border-b border-l border-r border-gray-300 bg-gray-100 transition-all duration-300",
        )}
      >
        <div
          ref={chatContainerRef}
          className={classNames(
            "flex flex-col space-y-4 divide-y-[1px] divide-gray-300",
            "h-[600px] overscroll-y-contain transition-all",
            !openSidekick ? "h-0 max-h-0" : "max-h-[calc(min(600px,100vh-140px))]",
            scrollBarClass,
            "!overflow-y-scroll",
            openSidekick ? "pb-4" : "",
          )}
        >
          {filteredChatData.map((group, i) => {
            const currentAnswers = answers[group.group] || ([] as GibooSidekickChatData[]);
            // const isLast = filteredChatData.length === i + 1;
            return (
              <div
                className="mx-4 flex w-[calc(100%-32px)] flex-col gap-[10px] pt-3"
                key={i}
                id={`chat-group-${group.group}`}
              >
                {group.data
                  .filter(
                    (d) =>
                      (checkShowCondition(d.show_condition) &&
                        d.type !== GibooSidekickChatComponentType.ACTION_BUTTON &&
                        d.type !== GibooSidekickChatComponentType.INFORMATION_BUTTON) ||
                      currentAnswers[d.id],
                  )
                  .sort((a, b) => {
                    const ua = currentAnswers[a.id]?.updated_at;
                    const ub = currentAnswers[b.id]?.updated_at;
                    if (ua === ub) return 0;
                    if (!ua) return -1;
                    if (!ub) return 1;
                    return ua.getTime() - ub.getTime();
                  })
                  .map((d, i) => {
                    const currentAnswer = currentAnswers[d.id];
                    return (
                      <GibooSidekickChatComponent
                        group={group.group}
                        key={`${group.group}-${d.id}`}
                        opened={currentAnswer ? true : false}
                        id={d.id}
                        type={d.type}
                        message={d.message}
                        title={d.title}
                        answer={d.answer}
                        action_button={d.action_button}
                        action_type={d.action_type}
                        action_target={d.action_target}
                        icon={d.icon}
                        child={
                          d.type === GibooSidekickChatComponentType.ACTION_BUTTON && currentAnswer
                            ? currentAnswer
                            : undefined
                        }
                      />
                    );
                  })}
                {!chatLoading[group.group] &&
                  group.data
                    .filter(
                      (d) =>
                        checkShowCondition(d.show_condition) &&
                        (d.type === GibooSidekickChatComponentType.ACTION_BUTTON ||
                          d.type === GibooSidekickChatComponentType.INFORMATION_BUTTON) &&
                        !currentAnswers[d.id],
                    )
                    .slice(0, ENABLE_SHOW_MORE ? showMore[group.group] || 2 : group.data.length)
                    .map((d, i) => (
                      <GibooSidekickChatComponent
                        group={group.group}
                        key={`${group.group}-${d.id}`}
                        id={d.id}
                        type={d.type}
                        message={d.message}
                        title={d.title}
                        answer={d.answer}
                        action_button={d.action_button}
                        action_type={d.action_type}
                        action_target={d.action_target}
                        icon={d.icon}
                      />
                    ))}
                {chatLoading[group.group] || showMoreLoading[group.group] ? (
                  <div className="flex justify-end">
                    <svg
                      className="giboo-anim-loading-dot-container mr-3"
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle className="giboo-anim-loading-dot" cx="2.5" cy="7" r="2.5" />
                      <circle className="giboo-anim-loading-dot" cx="10" cy="7" r="2.5" />
                      <circle className="giboo-anim-loading-dot" cx="17.5" cy="7" r="2.5" />
                    </svg>
                  </div>
                ) : ENABLE_SHOW_MORE &&
                  group.data.filter(
                    (d) =>
                      checkShowCondition(d.show_condition) &&
                      (d.type === GibooSidekickChatComponentType.ACTION_BUTTON ||
                        d.type === GibooSidekickChatComponentType.INFORMATION_BUTTON) &&
                      !currentAnswers[d.id],
                  ).length > (showMore[group.group] || 2) ? (
                  <div
                    className="flex h-[30px] cursor-pointer items-center gap-1 self-end rounded-full bg-purple-500 px-3 py-1 text-white hover:bg-purple-400"
                    onClick={() => {
                      setShowMoreLoading((prev) => ({ ...prev, [group.group]: true }));
                      setTimeout(() => {
                        setShowMore((prev) => ({
                          ...prev,
                          [group.group]: (prev[group.group] || 2) + 2,
                        }));
                        setShowMoreLoading((prev) => ({ ...prev, [group.group]: false }));
                        setActiveGroup([group.group].join(""));
                        setTimeout(() => setScrollInitiate([`${group}`].join("")), 400);
                      }, 500);
                    }}
                  >
                    <i className="gi-md gi-sidekick2" />
                    <span className="text-xs">Show more</span>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <Portal key="sidekick">
      {showTaskCreation && (
        <TaskCreationModal
          open={showTaskCreation}
          setOpen={setShowTaskCreation}
          onClose={() => {
            setShowTaskCreation(false);
          }}
          onTaskCreation={() => {
            setShowTaskCreation(false);
          }}
          projectId={isProjectTask ? isProjectTask?.params?.project_id : undefined}
          taskId={isProjectTask ? isProjectTask?.params?.id : undefined}
        />
      )}
      {onboardingData._id && showOrgVerify && (
        <VerifyOrganizationModal
          from_for_mixpanel="sidekick"
          open={showOrgVerify}
          setOpen={setShowOrgVerify}
          onClose={() => {
            setShowOrgVerify(false);
          }}
          onCreation={() => {
            //
          }}
        />
      )}
      {onboardingData._id && showInvite && (
        <OrgInvitationPopup
          from_for_mixpanel="sidekick"
          show={showInvite}
          onClose={() => {
            setShowInvite(false);
          }}
        />
      )}
      {showInviteProject && isProject?.params?.id && (
        <ProjectInvite
          from_for_mixpanel="sidekick"
          projectId={isProject?.params?.id || ""}
          handleClose={() => {
            setShowInviteProject(false);
          }}
          showInvite={showInviteProject}
        />
      )}
      {showProjectEdit && (
        <ProjectInformationModal
          from_for_mixpanel="sidekick"
          open={showProjectEdit}
          setOpen={setShowProjectEdit}
          id={isProject?.params?.id || ""}
          currentType={currentType}
        />
      )}
      <SidekickContext.Provider
        value={{
          chatLoading,
          setChatLoading,
          setAnswer,
          setScrollInitiate,
          updateFilter,
          setActiveGroup,
          param,
          setShowOrgVerify,
          setShowInvite,
          setShowTaskCreation,
          setShowInviteProject,
          setShowProjectEdit,
          sidekickLocation,
        }}
      >
        <div
          id="giboo-sidekick"
          className={classNames(
            "fixed bottom-0 right-0 flex h-fit w-full max-w-[400px] flex-col items-center",
            zIndexSidekick,
          )}
        >
          {renderHeader()}
          {renderContent()}
        </div>
      </SidekickContext.Provider>
    </Portal>
  );
}
export default GibooSidekick;
