import { ReactNode, useCallback, useEffect, useState } from "react";
import useOnboardingData from "../hooks/useOnboarding";
import { SearchQuery, SearchType, getURLSearchParamsFromSearchQuery } from "../types/search";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
interface DivNavLinkRenderProps {
  isActive: boolean;
}
interface DivNavLinkProps {
  to: string;
  pattern?: string;
  children?: (props: DivNavLinkRenderProps) => ReactNode;
}
type IsFunction<T> = T extends (...args: any[]) => any ? T : never;
const isFunction = <T extends object>(value: T): value is IsFunction<T> =>
  typeof value === "function";
const DivNavLink = ({ to, pattern, children }: DivNavLinkProps) => {
  if (children && !isFunction(children)) {
    throw new Error("children is mandatory and needs to be a function");
  }
  const navigate = useNavigate();
  const matched = useMatch(pattern || to);
  return (
    <div onClick={() => !matched && navigate(to)}>
      {children && children({ isActive: matched ? true : false })}
    </div>
  );
};
export default DivNavLink;
