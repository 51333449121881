import classNames from "classnames";
import * as React from "react";
import "./css/button.scss";
import Spinner from "./Spinner";

interface CardButtonProps {
  id: string;
  handleOnClick: (e: React.FormEvent) => void;
  className?: string;
  img?: string;
  label?: string;
  imgClas?: string;
  labelClass?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  content?: JSX.Element;
  isComingSoon?: boolean;
  renderLabel?: () => React.ReactNode;
}

function CardButton({ img, content, style, isComingSoon = false, ...props }: CardButtonProps) {
  const className = classNames(
    "grid place-items-center  gap-3 prevent-select border border-gray-300 rounded p-5 min-h-[50px] min-w-[50px]",
    props.className,
    isComingSoon && "opacity-50 !gap-0",
  );
  return (
    <button
      id={props.id}
      disabled={props.disabled}
      onClick={(e) => {
        if (isComingSoon) return;
        e.currentTarget.blur();
        props.handleOnClick(e);
      }}
      className={classNames(className)}
      style={style}
    >
      {content ? (
        content
      ) : (
        <>
          <img src={img} className={classNames("h-full w-full", props.imgClas)} />
          {isComingSoon && (
            <div className=" grid h-[29px] w-fit place-items-center rounded bg-gray-300 px-2 py-1 mb-3">
              <h5 className="text-sm text-gray-800">Coming soon</h5>
            </div>
          )}
          {props.renderLabel ? (
            props.renderLabel()
          ) : (
            <p className={classNames("font-poppins text-sm text-gray-800", props.labelClass)}>
              {props.label}
            </p>
          )}
        </>
      )}
    </button>
  );
}

export default CardButton;
