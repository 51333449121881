import useSWR from "swr";
import axios from "axios";
import config from "../api";
import Fuse from "fuse.js";
import {
  NPOGrant,
  NPOGrantGroupedByFunder,
  NPOGrantGroupedByFunderAndYear,
  NPOGrantStatistic,
} from "../types/npo";
import { useEffect, useState } from "react";
import { swrOptionDedupling5mins } from "../types/swr";
interface ProjectSubtaskNotesState {
  data: NPOGrant[];
  supporters: NPOGrantGroupedByFunder[];
  topSupporters: string[];
  groupByYears: { [key: number]: NPOGrantGroupedByFunderAndYear[] };
  statistic: NPOGrantStatistic;
  error: any;
  isLoading: boolean;
}
const defaultStatistic = {
  from: 0,
  to: 0,
  min: 0,
  max: 0,
  median: 0,
  avg: 0,
  sum: 0,
  totalNumberOfFunder: 0,
  totalNumber: 0,
};
const emptyList: NPOGrant[] = [];
const emptyStringList: string[] = [];
const emptyByFunderList: NPOGrantGroupedByFunder[] = [];
const emptyByFunderAndYear: {
  [key: number]: NPOGrantGroupedByFunderAndYear[];
} = {};
const groupByDesc = (arr: NPOGrant[]): { grant_amount: number; grant_desc: string }[] => {
  const sorted = arr.sort((a, b) => a.grant_amount - b.grant_amount); //asc
  const parentMap: { [key: number]: number } = {};
  const fuse = new Fuse<string>(
    sorted.map((i) => i.grant_desc),
    { includeScore: true, findAllMatches: true, threshold: 0.6 },
  );
  for (let i = 0; i < sorted.length; i++) {
    const res = fuse.search(sorted[i].grant_desc);
    const maxScore = res.reduce(
      (prev, cur) =>
        cur.refIndex > i && cur.score !== undefined && cur.score !== null && cur.score < prev.score
          ? { index: cur.refIndex, score: cur.score }
          : prev,
      { index: -1, score: 0.6 },
    );
    parentMap[i] = maxScore.index;
  }
  const getParent = (i: number): number => {
    if (parentMap[i] < 0) return i;
    else return getParent(parentMap[i]);
  };
  const res = sorted.reduce((prev, cur, i) => {
    const p = getParent(i);
    return {
      ...prev,
      [p]: prev[p]
        ? { ...prev[p], grant_amount: prev[p].grant_amount + cur.grant_amount }
        : { grant_desc: sorted[p].grant_desc, grant_amount: cur.grant_amount },
    };
  }, {} as { [key: number]: { grant_amount: number; grant_desc: string } });

  return Object.values(res).sort((a, b) => b.grant_amount - a.grant_amount);
};
function useNPOGrants(npo_id?: string): ProjectSubtaskNotesState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/npos/${npo_id}/grants`;
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<NPOGrant[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as NPOGrant[]);
  };
  const { data, isLoading, error } = useSWR<NPOGrant[]>(
    npo_id ? url : null,
    fetch,
    swrOptionDedupling5mins,
  );

  // all the computation will be done at frontend
  const computeFunc = () => {
    const sorted = (data || []).map((g) => g.grant_amount).sort((a, b) => a - b);
    const sum = sorted.reduce((prev, cur) => prev + cur, 0);
    const idx = Math.floor(sorted.length / 2);
    const even = sorted.length % 2 === 0;
    const groupByYearAndFunder = (data || []).reduce((prev, cur) => {
      const id1 = cur.grant_year;
      const id = cur.donor_id;
      return {
        ...prev,
        [id1]: prev[id1]
          ? { ...prev[id1], [id]: prev[id1][id] ? [...prev[id1][id], cur] : [cur] }
          : { [id]: [cur] },
      };
    }, {} as { [key: number]: { [key: string]: NPOGrant[] } });
    const groupByFunder = (data || []).reduce((prev, cur) => {
      const id = cur.donor_id;
      return { ...prev, [id]: prev[id] ? [...prev[id], cur] : [cur] };
    }, {} as { [key: string]: NPOGrant[] });
    return { sorted, sum, idx, even, groupByYearAndFunder, groupByFunder };
  };
  const setGroupByFunderCalculatedFunc = (groupByFunder: {
    [key: string]: NPOGrant[];
  }): NPOGrantGroupedByFunder[] => {
    return Object.keys(groupByFunder).map((key) => {
      const detail = groupByDesc(groupByFunder[key]);
      return {
        donor_id: key,
        donor_name: groupByFunder[key][0].donor_name,
        grant_amount: groupByFunder[key].reduce((prev, cur) => prev + cur.grant_amount, 0),
        grant_years: groupByFunder[key]
          .reduce(
            (prev, cur) => [...prev.filter((p) => p !== cur.grant_year), cur.grant_year],
            [] as number[],
          )
          .sort((a, b) => a - b),
        grant_desc: detail.length > 0 ? detail[0].grant_desc : "",
        detail: detail,
        origin: groupByFunder[key],
      };
    });
  };
  const setGroupByYearCalculatedFunc = (groupByYearAndFunder: {
    [key: number]: {
      [key: string]: NPOGrant[];
    };
  }): {
    [key: number]: NPOGrantGroupedByFunderAndYear[];
  } => {
    return Object.keys(groupByYearAndFunder).reduce(
      (prev, y) => {
        const yearData = groupByYearAndFunder[+y];
        const v = Object.keys(yearData).map((key) => {
          const detail = groupByDesc(yearData[key]);
          return {
            donor_id: key,
            donor_name: yearData[key][0].donor_name,
            grant_amount: yearData[key].reduce((prev, cur) => prev + cur.grant_amount, 0),
            grant_desc: detail.length > 0 ? detail[0].grant_desc : "",
            detail: detail,
            origin: yearData[key],
          };
        });
        const v2 = v.reduce(
          (prev, cur) => [
            ...prev,
            ...cur.detail.map((d) => ({
              ...cur,
              detail: [],
              origin: [],
              grant_desc: d.grant_desc,
              grant_amount: d.grant_amount,
            })),
          ],
          [] as NPOGrantGroupedByFunderAndYear[],
        );
        return { ...prev, [+y]: v2.sort((a, b) => b.grant_amount - a.grant_amount) };
      },
      {} as {
        [key: number]: NPOGrantGroupedByFunderAndYear[];
      },
    );
  };
  const setStatisticFunc = (
    groupByYearCalculated: {
      [key: number]: NPOGrantGroupedByFunderAndYear[];
    },
    sorted: number[],
    sum: number,
    idx: number,
    even: boolean,
    groupByFunderCalculated: NPOGrantGroupedByFunder[],
  ) => {
    const years = Object.keys(groupByYearCalculated).map((i) => +i);
    return {
      from: years.sort((a, b) => a - b)[0],
      to: years.sort((a, b) => b - a)[0],
      min: sorted[0],
      max: sorted[sorted.length - 1],
      avg: Math.floor(sum / sorted.length),
      median: !even ? sorted[idx] : Math.floor((sorted[idx] + sorted[idx - 1]) / 2),
      sum: sum,
      totalNumber: sorted.length,
      totalNumberOfFunder: groupByFunderCalculated.length,
    };
  };
  const [computed, setComputed] = useState<{
    sorted: number[];
    sum: number;
    idx: number;
    even: boolean;
    groupByYearAndFunder: {
      [key: number]: {
        [key: string]: NPOGrant[];
      };
    };
    groupByFunder: {
      [key: string]: NPOGrant[];
    };
  }>(computeFunc());
  const [groupByFunderCalculated, setGroupByFunderCalculated] = useState<NPOGrantGroupedByFunder[]>(
    setGroupByFunderCalculatedFunc(computed.groupByFunder),
  );
  const [groupByYearCalculated, setGroupByYearCalculated] = useState<{
    [key: number]: NPOGrantGroupedByFunderAndYear[];
  }>(setGroupByYearCalculatedFunc(computed.groupByYearAndFunder));
  const [supporters, setSupporters] = useState<NPOGrantGroupedByFunder[]>(
    groupByFunderCalculated.sort((a, b) => b.grant_amount - a.grant_amount),
  );
  const [topSupporters, setTopSupporters] = useState<string[]>(
    groupByFunderCalculated
      .sort((a, b) => b.grant_amount - a.grant_amount)
      .slice(0, 3)
      .map((i) => i.donor_id),
  );
  const [groupByYears, setGroupByYears] = useState<{
    [key: number]: NPOGrantGroupedByFunderAndYear[];
  }>(groupByYearCalculated);
  const [statistic, setStatistic] = useState<NPOGrantStatistic>(
    setStatisticFunc(
      groupByYearCalculated,
      computed.sorted,
      computed.sum,
      computed.idx,
      computed.even,
      groupByFunderCalculated,
    ),
  );
  useEffect(() => {
    if (!data || data.length === 0) {
      setSupporters([]);
      setTopSupporters([]);
      setGroupByYears([]);
      setStatistic(defaultStatistic);
      return;
    }
    const computed = computeFunc();
    setComputed(computed);
    const { sorted, sum, idx, even, groupByYearAndFunder, groupByFunder } = computed;
    const groupByFunderCalculated = setGroupByFunderCalculatedFunc(groupByFunder);
    const groupByYearCalculated = setGroupByYearCalculatedFunc(groupByYearAndFunder);
    setStatistic(
      setStatisticFunc(groupByYearCalculated, sorted, sum, idx, even, groupByFunderCalculated),
    );
    setSupporters(groupByFunderCalculated.sort((a, b) => b.grant_amount - a.grant_amount));
    setTopSupporters(
      groupByFunderCalculated
        .sort((a, b) => b.grant_amount - a.grant_amount)
        .slice(0, 3)
        .map((i) => i.donor_id),
    );
    setGroupByYears(groupByYearCalculated);
  }, [data]);
  return {
    data: npo_id ? data || emptyList : emptyList,
    supporters: npo_id ? supporters : emptyByFunderList,
    topSupporters: npo_id ? topSupporters : emptyStringList,
    groupByYears: npo_id ? groupByYears : emptyByFunderAndYear,
    statistic: npo_id ? statistic : defaultStatistic,
    isLoading: npo_id ? isLoading : false,
    error,
  };
}
export default useNPOGrants;
