import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import useOrgVerificationStatus from "../hooks/admin/useOrgVerificationStatus";
import { getValidNumber, toDateString } from "../utils/formatHelper";
import { Pagination } from "rsuite";
import RadioGroup from "../components/radio/RadioGroup";
import classNames from "classnames";
import AnyUserProfile from "../components/internal_tool/AnyUserProfile";
import usePhotoUrl from "../hooks/usePhotoUrl";
import PlainButton from "../components/PlainButton";
import Button from "../components/tailwind/Button";
import DropdownSelector from "../components/dropdown/DropdownSelector";
import { IOption } from "../components/tailwind/AsyncCreatableSelector";
import LongTextInput from "../components/LongTextInput";
import axios from "axios";
import config from "../api";
interface RejectOption extends IOption {
  message: string;
}
const REJECT_DATA = [
  {
    label: "No file",
    value: "no_file",
    message:
      "Thank you for submitting your request for organization verification. However, we noticed that no file was attached to your submission. In order for us to proceed with the verification process, please ensure that you provide the necessary documentation related to your organization. Feel free to reach out if you have any questions or need assistance. We look forward to assisting you further.",
  },
  {
    label: "Not sufficient files for org verification",
    value: "not_sufficient_org_data",
    message:
      "We appreciate your initiative in initiating the organization verification process. However, upon reviewing your submission, we found that the provided files are not sufficient to proceed with the verification. To ensure a smooth and accurate verification process, please provide all the necessary documentation and information related to your organization. If you have any questions or need guidance on the required documents, please don't hesitate to contact us. We're here to help.",
  },
  {
    label: "The other already occufied the org",
    value: "occufied",
    message:
      "Thank you for your interest in organization verification. Unfortunately, it seems that another entity has already initiated the verification process for the same organization. As a result, we are unable to proceed with your request at this time. If you believe this is an error or if you have any concerns, please reach out to us for further assistance. We apologize for any inconvenience this may cause and appreciate your understanding.",
  },
];
const unit = 10;
function VerificationView() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [close, setClose] = useState<{ [key: string]: boolean }>({});
  const [showMoreMembers, setShowMoreMembers] = useState<{ [key: string]: boolean }>({});
  const [showButtons, setShowButtons] = useState<{ [key: string]: number }>({});
  const [selectedReason, setSelectedReason] = useState<{ [key: string]: RejectOption }>({});
  const [message, setMessage] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});
  const [status, setStatus] = useState<{ [key: string]: object }>({});
  const [verifiedFilter, setVerifiedFilter] = useState<"all" | "verified" | "unverified">(
    "unverified",
  );
  useEffect(() => {
    const _page = searchParams.get("page");
    const _verified = searchParams.get("verified");
    const valid_page = getValidNumber(_page, 1);
    const valid_verified = ["all", "verified", "unverified"].find((v) => v === _verified) as
      | "all"
      | "verified"
      | "unverified"
      | undefined;
    setPage(valid_page);
    setVerifiedFilter(valid_verified || "all");
  }, [searchParams]);

  const { data, size, isLoading, isSizeLoading, error, hasPermission, revalidate } =
    useOrgVerificationStatus({
      limit: unit,
      skip: (page - 1) * unit,
      verified: verifiedFilter === "unverified" ? -1 : verifiedFilter === "verified" ? 1 : 0,
    });
  const setVerified = (npo_id: string, org_id: string) => {
    setLoading((prev) => ({ ...prev, [org_id]: true }));
    axios
      .put(process.env.REACT_APP_API_URL + `/api/v2/orgs/verify`, { npo_id, org_id }, config)
      .then(() => {
        revalidate().finally(() => setLoading((prev) => ({ ...prev, [org_id]: false })));
        // setStatus((prev) => ({
        // ...prev,
        // [org_id]: { verified: true, other_verified_org: undefined, rejected: false },
      })
      .catch(() => setLoading((prev) => ({ ...prev, [org_id]: false })));
  };
  const unsetVerified = (npo_id: string) => {
    // setLoading((prev) => ({ ...prev, [npo_id]: true }));
    // axios
    //   .delete(process.env.REACT_APP_API_URL + `/api/v2/orgs/verify/${npo_id}`, config)
    //   .then(() => mutate(url))
    //   .finally(() => setLoading((prev) => ({ ...prev, [npo_id]: false })));
  };

  /* FIXME */
  // const { data: abc } = useVerifiedOrg("638cba7689755d0b6cca7b35");
  // const { data: help2020 } = useVerifiedOrg("647115c3a51e3d381a4649e1");
  // const { data: project_abc } = useOrgProjects("367f512f-8d03-4954-a279-cd0d789acd68");
  // console.log("project_abc", project_abc);
  // console.log("abc org", abc);
  // console.log("help2020 org", help2020);
  // const { data: projects } = useOrgProjects(help2020?._id);
  // console.log("help2020 projects", projects);
  // const { data: project } = useProject(projects.at(0)?._id);
  // console.log("help2020 project 1", project);

  // const { data: profile2 } = useOrgProfile(abc?._id);
  // console.log("abc org profile", profile2);
  // const { data: profile, update } = useOrgProfile(help2020?._id);
  // // useEffect(() => {
  // //   if (help2020?._id && !profile?.hide_age) update({ hide_age: true });
  // // }, [profile]);
  // console.log("help2020 org profile", profile);
  /* */
  return (
    <>
      <div className="flex w-full flex-col items-start gap-5 p-12">
        <div className="flex w-full flex-col gap-3">
          <div className="flex items-center justify-between">
            <h4>Verify organization</h4>
            <RadioGroup<"all" | "verified" | "unverified">
              id={"btn-verified"}
              data={[
                { label: "All", value: "all" },
                { label: "Verified", value: "verified" },
                { label: "not verified", value: "unverified" },
              ]}
              value={verifiedFilter}
              setValue={(v) => {
                if (v)
                  setSearchParams(
                    new URLSearchParams([
                      ["verified", v],
                      ["page", `1`],
                    ]),
                  );
              }}
            />
          </div>
          {isLoading ? (
            <Spinner />
          ) : error || !hasPermission ? (
            <h4>No permission</h4>
          ) : (
            <div className="flex flex-col gap-3">
              {data
                .map((d) => ({ ...d, ...(status[d._id] ? status[d._id] : {}) }))
                .map((d) => (
                  <div
                    key={d._id}
                    className={classNames("flex flex-col rounded border border-gray-300 bg-white")}
                  >
                    <div
                      className={classNames(
                        "grid cursor-pointer grid-cols-9 px-5 py-3",
                        !close[d._id] ? "rounded-t border-b border-gray-300" : "rounded",
                        d.verified
                          ? "bg-purple-100 hover:bg-purple-300"
                          : d.other_verified_org?.org_id
                          ? "bg-orange-100 hover:bg-orange-300"
                          : "bg-gray-200 hover:bg-gray-300",
                      )}
                      onClick={() =>
                        setClose((prev) => ({ ...prev, [d._id]: prev[d._id] ? false : true }))
                      }
                    >
                      <div className="flex items-center">
                        <div
                          className={classNames(
                            "flex h-12 w-24 items-center justify-center rounded-2xl",
                            d.verified
                              ? "bg-purple-500"
                              : d.other_verified_org?.org_id
                              ? "bg-orange-700"
                              : "bg-gray-500",
                          )}
                        >
                          <h5 className="text-white">
                            {d.verified
                              ? "Verified"
                              : d.other_verified_org?.org_id
                              ? "Occupied"
                              : "On review"}
                          </h5>
                        </div>
                      </div>
                      <div className="col-span-5 flex flex-col gap-2">
                        <a
                          className="w-fit cursor-pointer !text-lg !text-gray-900 hover:underline"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          target="_blank"
                          rel="noreferrer"
                          href={`/npos/${d._id}/`}
                        >
                          {d.npo?.name}
                        </a>
                        <h6 className="text-gray-700">
                          <AnyUserProfile userId={d.owner?._id} showEmail />
                        </h6>
                      </div>
                      <div className="col-span-3 flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                          <div className="flex h-5 w-5 items-center justify-center rounded-full"></div>
                          <h6>
                            {`Created at: `}
                            <span>{toDateString(d.created_at)}</span>
                          </h6>
                        </div>

                        <div className="flex items-center gap-2">
                          <div
                            className={classNames(
                              "flex h-5 w-5 items-center justify-center rounded-full",
                              d.org_verification_requested ? "bg-blue-500" : "",
                            )}
                          >
                            {d.org_verification_requested && (
                              <i className="gi-sm gi-check text-white" />
                            )}
                          </div>
                          <h6
                            className={classNames(
                              d.org_verification_requested ? "" : "text-gray-600",
                            )}
                          >
                            {`Org verification requested at: `}
                            <span>
                              {d.org_verification_requested
                                ? toDateString(d.org_verification_requested.created_at)
                                : "None"}
                            </span>
                          </h6>
                        </div>
                        <div className="flex items-center gap-2">
                          <div
                            className={classNames(
                              "flex h-5 w-5 items-center justify-center rounded-full",
                              d.org_admin_verification_requested ? "bg-blue-500" : "",
                            )}
                          >
                            {d.org_admin_verification_requested && (
                              <i className="gi-sm gi-check text-white" />
                            )}
                          </div>
                          <h6
                            className={classNames(
                              d.org_admin_verification_requested ? "" : "text-gray-600",
                            )}
                          >
                            {`Admin verification requested at: `}
                            <span>
                              {d.org_admin_verification_requested
                                ? toDateString(d.org_admin_verification_requested.created_at)
                                : "None"}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    {!close[d._id] && (
                      <div className="flex w-full flex-col divide-y divide-gray-300 rounded-b bg-white">
                        {!d.verified && d.other_verified_org?.org_id && (
                          <div className="flex items-center gap-2 px-5 py-3">
                            This nonprofit has already occufied by the other organization{" "}
                            <a
                              className="!m-0 !p-0 !text-sm"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              target="_blank"
                              rel="noreferrer"
                              href={`/npos/${d._id}/`}
                            >
                              See the org
                            </a>
                          </div>
                        )}
                        <div
                          className={classNames(
                            "flex justify-between gap-5 px-24 py-2",
                            showButtons[d._id] < 0
                              ? "bg-red-50"
                              : showButtons[d._id] > 0
                              ? "bg-purple-50"
                              : "",
                          )}
                        >
                          {showButtons[d._id] < 0 ? (
                            <div className="flex w-full flex-col gap-2">
                              <div className="w-[400px] bg-white">
                                <DropdownSelector<RejectOption>
                                  id="input-dropdown-reason"
                                  placeholder="Select a message template"
                                  data={REJECT_DATA}
                                  compact
                                  selected={selectedReason[d._id]}
                                  setSelected={(item) => {
                                    if (item) {
                                      setSelectedReason((prev) => ({ ...prev, [d._id]: item }));
                                      setMessage((prev) => ({ ...prev, [d._id]: item.message }));
                                    }
                                  }}
                                  getKey={(item) => item.value}
                                  renderItem={(item) => item.label}
                                />
                              </div>
                              <LongTextInput
                                className="!bg-white"
                                id={`input-message-${d._id}`}
                                value={message[d._id] || ""}
                                height="300px"
                              />
                              <Button
                                id={`btn-reject-confirm${d._id}`}
                                label={"Send a rejction mail"}
                                className="self-start"
                                color="red"
                                outline
                                disabled={!d._id}
                                loading={loading[d._id]}
                                handleOnClick={() => {
                                  alert("not supporting yet");
                                }}
                              />
                            </div>
                          ) : showButtons[d._id] > 0 ? (
                            <div className="flex w-full flex-col gap-2">
                              <LongTextInput
                                className="!bg-white"
                                id={`input-message-${d._id}`}
                                value={"Thank you"}
                                height="200px"
                                readonly
                              />
                              <Button
                                id={`btn-reject-confirm${d._id}`}
                                label={"Confirm"}
                                className="self-start"
                                disabled={!d.npo_id || !d._id}
                                loading={loading[d._id]}
                                handleOnClick={() => {
                                  if (!d.npo_id || !d._id) return;
                                  setVerified(d.npo_id, d._id);
                                }}
                              />
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <div className="flex items-center gap-2">
                            <Button
                              id="btn-id-start-review"
                              handleOnClick={() =>
                                setShowButtons((prev) => ({
                                  ...prev,
                                  [d._id]: 1,
                                }))
                              }
                              label={"Verify"}
                            />
                            <Button
                              id="btn-id-start-reject"
                              handleOnClick={() =>
                                setShowButtons((prev) => ({
                                  ...prev,
                                  [d._id]: -1,
                                }))
                              }
                              outline
                              color="red"
                              label={"Reject"}
                            />
                          </div>
                        </div>
                        {d.org_verification_requested && (
                          <div className="flex w-full gap-3 px-5 py-3">
                            <div className="flex w-[100px] min-w-[100px]">
                              <h6 className="text-gray-700">Org</h6>
                            </div>
                            <div className="flex w-[calc(100%-100px)] grow flex-col gap-2">
                              {d.org_verification_requested.files?.map((f) => (
                                <div key={f.key} className="flex w-full items-center">
                                  <FileView id={f.key} name={f.name} />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {d.org_admin_verification_requested && (
                          <div className="flex w-full gap-3 px-5 py-3">
                            <div className="flex w-[100px] min-w-[100px]">
                              <h6 className="text-gray-700">Admin</h6>
                            </div>
                            <div className="flex w-[calc(100%-100px)] grow flex-col gap-2">
                              {d.org_admin_verification_requested.files?.map((f) => (
                                <div key={f.key} className="flex w-full items-center">
                                  <FileView id={f.key} name={f.name} />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        <div className="flex w-full gap-3 px-5 py-3">
                          <div className="flex w-[100px] min-w-[100px]">
                            <h6 className="text-gray-700">{`Members (${
                              d.members
                                ?.filter((mid) => mid !== d.owner?._id)
                                .sort((a, b) => a.localeCompare(b))
                                .reduce(
                                  (prev, cur) =>
                                    prev.length > 0 && prev[prev.length - 1] === cur
                                      ? prev
                                      : [...prev, cur],
                                  [] as string[],
                                ).length
                            })`}</h6>
                          </div>
                          <div className="flex w-[calc(100%-100px)] grow flex-col gap-2">
                            {d.members
                              ?.filter((mid) => mid !== d.owner?._id)
                              .sort((a, b) => a.localeCompare(b))
                              .reduce(
                                (prev, cur) =>
                                  prev.length > 0 && prev[prev.length - 1] === cur
                                    ? prev
                                    : [...prev, cur],
                                [] as string[],
                              )
                              .slice(0, showMoreMembers[d._id] ? d.members.length : 10)
                              .map((mid) => (
                                <AnyUserProfile userId={mid} showEmail key={mid} />
                              ))}
                            {!showMoreMembers[d._id] &&
                              d.members &&
                              d.members
                                ?.filter((mid) => mid !== d.owner?._id)
                                .sort((a, b) => a.localeCompare(b))
                                .reduce(
                                  (prev, cur) =>
                                    prev.length > 0 && prev[prev.length - 1] === cur
                                      ? prev
                                      : [...prev, cur],
                                  [] as string[],
                                ).length > 10 && (
                                <PlainButton
                                  id={`btn-show-more-${d._id}`}
                                  label="show more"
                                  className="!m-0 self-start !p-0 !text-xs"
                                  handleOnClick={() =>
                                    setShowMoreMembers((prev) => ({
                                      ...prev,
                                      [d._id]: prev[d._id] ? false : true,
                                    }))
                                  }
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              {/* {Object.keys(group)
                .sort((a, b) => a.localeCompare(b))
                .map((id) => (
                  <div
                    className="flex flex-col gap-2 rounded border border-gray-300 bg-white p-3"
                    key={id}
                  >
                    <div className="flex items-center gap-3">
                      {(isLoading || isValidating || loading[id]) && <Spinner />}
                      <p className="text-purple-500">
                        <span className="text-sm text-gray-700">{group[id][0].npo?.ein}</span>{" "}
                        <span className="font-semibold">{group[id][0].npo?.name}</span>{" "}
                        <span className="text-white">{group[id][0].npo?._id}</span>
                      </p>
                    </div>
                    <div className="w-full gap-3 divide-y divide-gray-300 rounded border border-gray-300">
                      {group[id].map((n) => (
                        <div
                          key={n._id}
                          className={classNames(
                            "grid grid-cols-12 gap-3 p-3 hover:bg-gray-200",
                            n.verified ? "bg-blue-50" : "bg-white",
                            // loading[id] ? "cursor-wait" : "cursor-pointer",
                          )}
                          onClick={() => {
                            // if (loading[id]) return;
                            if (n.verified) unsetVerified(id);
                            else setVerified(id, n._id);
                          }}
                        >
                          {n.verified ? (
                            <i className="gi-lg gi-circle-check font-semibold text-blue-700"></i>
                          ) : (
                            <span></span>
                          )}
                          <span className="col-span-3 font-semibold">
                            {n.owner?.firstname || ""} {n.owner?.lastname || ""}
                          </span>{" "}
                          <span className="col-span-3">{n.owner?.email || ""}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))} */}
              <div className="flex flex-col items-start gap-2"></div>
              <div className="flex flex-col items-center border-t border-gray-300 py-3">
                {!isSizeLoading ? (
                  <Pagination
                    prev={true}
                    last={false}
                    next={true}
                    first={false}
                    size="md"
                    total={size || 0}
                    limit={unit}
                    maxButtons={8}
                    ellipsis
                    activePage={page}
                    onChangePage={(page) => {
                      setSearchParams(
                        new URLSearchParams([
                          ["verified", verifiedFilter],
                          ["page", `${page}`],
                        ]),
                      );
                    }}
                  />
                ) : (
                  <Spinner size="sm" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default VerificationView;

function FileView({ id, name }: { id: string; name: string }) {
  const { data, isLoading } = usePhotoUrl(id, name);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex w-full items-center gap-3">
          <img src={data} width={"50px"} />
          {data ? (
            <a className="truncate text-ellipsis hover:underline" href={data} download>
              {name}
            </a>
          ) : (
            <span>Something went wrong</span>
          )}
        </div>
      )}
    </>
  );
}
