import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/store";
import { ReactNode, useEffect, useState } from "react";
import { PROJECT_PURPOSE_OPTIONS } from "../../project-creation/ProjectCreationNew";
import { toDateString, toUsdLong } from "../../../utils/formatHelper";
import { reprLocation } from "../../../types/location";
import Tag from "../../../components/Tag";
import icon_purpose from "../../../assets/project-mgt/purpose.svg";
import icon_problem from "../../../assets/project-mgt/problem.svg";
import icon_timeline from "../../../assets/project-mgt/timeline.svg";
import icon_amount from "../../../assets/project-mgt/amount.svg";
import icon_summary from "../../../assets/project-mgt/summary.svg";
import icon_location from "../../../assets/project-mgt/location.svg";
import icon_outcome from "../../../assets/project-mgt/outcome.svg";
import icon_activities from "../../../assets/project-mgt/activities.svg";
import icon_beneficiary from "../../../assets/project-mgt/beneficiary.svg";
import useProject from "../../../hooks/project/useProject";
import { getFileUrl } from "../../../services/file-upload/download.service";
import icon_placeholder from "../../../assets/images/placeholder.svg";
import useOrgDataForNPODashboard from "../../../hooks/useOrgDataForNPODashboard";
import { Portal } from "@headlessui/react";
import Button from "../../../components/tailwind/Button";

function PublicProjectDetail() {
  const { id: projectId } = useParams();
  const { data: projectData, hasEditPermission, isValidating } = useProject(projectId);
  const navigate = useNavigate();
  const renderTitle = (
    label: string,
    icon?: string,
    value?: string | ReactNode,
    onEdit?: () => void,
  ) => {
    return (
      <div className="min-h-6 w-full">
        <div className=" flex w-full items-start gap-3">
          {icon && <img src={icon} className="h-5 w-5 object-scale-down" />}
          <div className={typeof value !== "string" ? "inline-flex" : ""}>
            <span className="whitespace-nowrap pr-1 font-semibold text-purple-500">{label}</span>
            {value && typeof value === "string" ? value : value}
          </div>
          {onEdit && (
            <span className="cursor-pointer" onClick={onEdit}>
              <i className="gi-pencil text-purple-500" />
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderTargetAmt = () => {
    const fundRaised = projectData.amount || 0;
    const fundToRaise = projectData?.fund_to_raise || 0;
    const fundPercentage = fundRaised > 0 && fundToRaise > 0 ? (fundRaised / fundToRaise) * 100 : 0;
    return (
      <div className="mt-4 flex flex-col items-center justify-center gap-5">
        <div className="flex items-center">
          <span className="text-xl font-semibold ">{toUsdLong(fundRaised || 0)}</span>
          <div className="mx-5 h-5 w-[1px]  bg-gray-300" />
          <p className="font-poppins text-base">
            Goal <span>{toUsdLong(fundToRaise || 0)}</span>
          </p>
        </div>
        <div className="relative mx-10 h-[33px] w-full overflow-hidden rounded bg-gray-100">
          <span
            className="absolute h-[33px]  bg-purple-500"
            style={{ width: `${fundPercentage <= 100 ? fundPercentage : 100}%` }}
          />
        </div>
      </div>
    );
  };
  const renderLeft = () => {
    return (
      <div className="flex h-full w-full max-w-[800px] flex-col gap-4">
        <ProjectImage projectId={projectId} />
        <div className="flex w-full  flex-col rounded border border-gray-300 bg-white px-5 py-4">
          {renderTitle(
            "Project purpose:",
            icon_purpose,
            PROJECT_PURPOSE_OPTIONS.find((o) => o.value === projectData?.project_type)?.label || "",
          )}
          <div className="my-3 h-[1px] w-full bg-gray-300" />
          {renderTitle(
            "Project timeline:",
            icon_timeline,
            projectData?.rolling
              ? "Rolling"
              : projectData?.project_start && projectData?.project_end
              ? `${
                  projectData?.project_start ? toDateString(projectData.project_start, false) : ""
                } - ${projectData?.project_end ? toDateString(projectData.project_end, false) : ""}`
              : "",
          )}
          <div className="my-3 h-[1px] w-full bg-gray-300" />
          {renderTitle(
            "Beneficiary location:",
            icon_location,
            <span className="flex flex-wrap gap-2">
              {projectData?.service_loc?.map((loc) => (
                <Tag
                  key={`loc-${reprLocation(loc)}`}
                  id={`loc-${reprLocation(loc)}`}
                  name={reprLocation(loc)}
                  color="orange"
                  matched
                  size="sm"
                />
              ))}
            </span>,
          )}
        </div>
        {/* Project summary */}
        <div className="flex h-full w-full flex-col rounded border border-gray-300 bg-white px-5 py-4">
          <div className="inline-flex w-full justify-between">
            <p>{renderTitle("Project summary", icon_summary)}</p>
          </div>
          <h5 className="mt-[14px] font-poppins text-sm text-gray-900">
            {projectData?.summary || ""}
          </h5>
        </div>
        {/* problems and challenges */}
        <div className="flex h-full w-full flex-col rounded border border-gray-300 bg-white px-5 py-4">
          <div className="inline-flex w-full justify-between">
            <p>{renderTitle("Problems and challenges", icon_problem)}</p>
          </div>
          <h5 className="mt-[14px] font-poppins text-sm text-gray-900">
            {projectData?.problem_desc || ""}
          </h5>
        </div>
        {/* Expected outcome */}

        <div className="flex h-full w-full flex-col rounded border border-gray-300 bg-white px-5 py-4">
          <div className="inline-flex w-full justify-between">
            <p>{renderTitle("Expected outcome", icon_outcome)}</p>
          </div>
          <h5 className="mt-[14px] font-poppins text-sm text-gray-900">
            {projectData?.outcome_desc || ""}
          </h5>
        </div>
        {/* Activities */}
        <div className="flex h-full w-full flex-col rounded border border-gray-300 bg-white px-5 py-4">
          <div className="inline-flex w-full justify-between">
            <p> {renderTitle("Activities for this project", icon_activities)}</p>
          </div>
          <h5 className="mt-[14px] font-poppins text-sm text-gray-900">
            {projectData?.activity_desc || ""}
          </h5>
        </div>
        {/* Beneficiary */}
        <div className="flex h-full w-full flex-col rounded border border-gray-300 bg-white px-5 py-4">
          <div className="inline-flex w-full justify-between">
            <p> {renderTitle("Beneficiary for this project", icon_beneficiary)}</p>
          </div>
          <h5 className="mt-[14px] font-poppins text-sm text-gray-900">
            {projectData?.beneficiary_desc || ""}
          </h5>
        </div>
      </div>
    );
  };
  const renderRight = () => {
    return (
      <div className="flex w-full max-w-[384px] flex-col gap-4">
        {/* target amount */}
        {!projectData.hide_fund && (
          <div className="flex h-fit w-full  flex-col rounded border border-gray-300 bg-white px-5 py-4">
            {renderTitle("Target amount", icon_amount)}
            {renderTargetAmt()}
          </div>
        )}
        <OrgProfile projectId={projectId} />
      </div>
    );
  };
  return (
    <div className="container mx-auto flex flex-col  pb-5">
      {hasEditPermission && !isValidating && (
        <Portal>
          <div className="absolute top-[65px] z-[10] flex h-[53px] w-full items-center justify-around bg-purple-500">
            <div className="font-poppins text-base text-white">
              You are viewing the page in a public view.
            </div>
            <Button
              id="edit-mode-btn"
              className="!h-[29px] !border !border-white"
              labelClass="!text-sm"
              handleOnClick={() => navigate(`/project/${projectId}/detail`)}
              label="View as member"
            />
          </div>
        </Portal>
      )}
      <h4 className="my-5 font-poppins text-xl text-black">{projectData.name || "No Title"}</h4>
      <div className="flex w-full gap-[25px]">
        {renderLeft()}
        {renderRight()}
      </div>
    </div>
  );
}
export default PublicProjectDetail;

function ProjectImage({ projectId }: { projectId: string | undefined }) {
  const { data: projectData } = useProject(projectId);
  const [bgImage, setBgImage] = useState<string | undefined>();
  const [backgroundObjectKey, setBackgroundObjectKey] = useState<string | undefined>();
  useEffect(() => {
    if (!backgroundObjectKey) setBgImage(undefined);
    if (backgroundObjectKey) {
      getFileUrl(backgroundObjectKey)
        .then((url) => {
          setTimeout(() => {
            setBgImage(url as string);
          }, 1000);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [backgroundObjectKey]);
  useEffect(() => {
    setBackgroundObjectKey(projectData.image_key);
  }, [projectData]);
  return (
    <>
      <div className="relative grid max-h-[400px] min-h-[400px] w-full place-items-center overflow-hidden rounded border border-gray-300 bg-gray-100">
        {/* <span
          className="absolute right-3 top-3  grid h-9 w-9 cursor-pointer place-items-center rounded-full bg-white bg-opacity-50 p-2 shadow-md"
          onClick={() => {
            setShowProfileBackgroundEdit(true);
          }}
        >
          <img className="h-full w-full " src={camera_icon} />
        </span> */}
        {bgImage ? (
          <img className="h-[400px] w-full object-cover" src={bgImage} />
        ) : (
          <div className="mt-3 grid h-fit  w-full place-items-center">
            <img className="h-[203px] w-[334px] bg-cover px-4" src={icon_placeholder} />
          </div>
        )}
      </div>
    </>
  );
}
function OrgProfile({ projectId }: { projectId: string | undefined }) {
  const navigate = useNavigate();
  const { data: projectData } = useProject(projectId);
  const { data } = useOrgDataForNPODashboard(projectData.org_id);
  const [profileImage, setProfileImage] = useState<string | undefined>();
  const [profileObjectKey, setProfileObjectKey] = useState<string | undefined>();
  useEffect(() => {
    if (profileObjectKey == "") setProfileImage(undefined);
    if (profileObjectKey) {
      getFileUrl(profileObjectKey)
        .then((url) => {
          setTimeout(() => {
            setProfileImage(url as string);
          }, 1000);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [profileObjectKey]);

  useEffect(() => {
    data && setProfileObjectKey(data.photo_object_key);
  }, [data]);

  return (
    <div className="inline-flex min-h-[160px] w-full max-w-[384px]  gap-5 rounded border border-gray-300 bg-white px-5 py-4">
      <div className="flex  h-full min-w-[87px] items-center">
        {profileImage ? (
          <div className="group relative z-0 cursor-pointer overflow-hidden rounded-full">
            <img
              className="prevent-select my-0 flex h-[87px] w-[87px] items-center justify-center rounded-full  border-[4px] border-gray-100 bg-purple-500 bg-cover text-white shadow-sm"
              src={profileImage}
            />
          </div>
        ) : (
          <div className="prevent-select group relative my-0 flex h-[87px] w-[87px] cursor-pointer items-center justify-center overflow-hidden  rounded-full border-[4px] border-gray-100 bg-purple-500 text-white shadow-sm">
            <h4 className="my-1 text-center text-3xl">{`${
              data?.npo_name?.[0]?.toUpperCase() || ""
            }`}</h4>
          </div>
        )}
      </div>
      <div className="flex flex-col items-start gap-3">
        <h4 className="line-clamp-2 w-full font-poppins text-xl text-black">{data?.npo_name}</h4>
        <h5
          className="cursor-pointer font-poppins text-sm text-gray-700"
          onClick={() => {
            navigate(`/organization/${data?.npo_id}/`);
          }}
        >
          View organization profile
        </h5>
        <div className="inline-flex items-center gap-[10px] text-purple-500">
          <i className="gi-globe gi-md" />
          <h5>{data?.website || "No information available"}</h5>
        </div>
        <div className="inline-flex items-center gap-[10px] text-purple-500">
          <i className="gi-mail gi-md" />
          <h5>{data?.email || "No information available"}</h5>
        </div>
      </div>
    </div>
  );
}
