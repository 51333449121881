import { useMatch, useSearchParams } from "react-router-dom";
import useLocalStorage from "./useLocalstorage";
import useUser from "./useUser";
import { useEffect, useState } from "react";
import useICPSearchContext from "./useICPSearchContext";
import useOnboardingData from "./useOnboarding";
import { RootState, useAppSelector } from "../app/store";
import { selectOnboardingData } from "../app/onboardingSlice";
import { NPOSearchResult } from "../types/search";
import useNPOSearchResult from "./search/useNPOSearchResult";
function useEINFromSearchParams(): {
  isLoading: boolean;
  cid: string | undefined | null;
  ein: string | undefined | null;
  firstname: string | undefined | null;
  invited: string | undefined | null;
  textQuery: string | undefined | null;
  tags:
    | {
        focus_area: string[];
        beneficiary: string[];
        program: string[];
      }
    | undefined;
  npo: NPOSearchResult | undefined;
} {
  const [user] = useUser();
  const onboardingData = useAppSelector((state: RootState) => selectOnboardingData(state));
  const [searchParams] = useSearchParams();
  const isFunderPage = useMatch("/donors/:id");
  const isGrantPage = useMatch("/grants/:id");
  const isPastGrantPage = useMatch("/grants/past/:id");
  const isNPOPage = useMatch("/npos/:id");
  const isSearch = useMatch("/search/*");
  const isSearchNext = useMatch("/search/:id");
  const { value: ein, update, deleteThis } = useLocalStorage("EIN_BY_SEARCH_PARAM");
  const {
    value: firstname,
    update: updateFirstname,
    deleteThis: deleteFirstname,
  } = useLocalStorage("FIRSTNAME_BY_SEARCH_PARAM");
  const {
    value: invited,
    update: updateInvited,
    deleteThis: deleteInvited,
  } = useLocalStorage("INVITED");
  const { value: cid, update: updateCid, deleteThis: deleteCid } = useLocalStorage("CID");
  const { data: context, isLoading } = useICPSearchContext(cid || undefined);
  const {
    value: sent,
    update: updateSent,
    deleteThis: deleteSent,
  } = useLocalStorage("SENT_INVITED_USER_MIXPANEL_EVENT");
  const {
    data: npoSearchResult,
    isLoading: isLoadingSearchResult,
    error: errorSearchResult,
  } = useNPOSearchResult(ein || context?.ein ? ein || context?.ein : undefined, undefined, "ein");
  useEffect(() => {
    if (user?._id && onboardingData.completed) {
      deleteThis();
      deleteFirstname();
      deleteInvited();
      deleteCid();
      deleteSent();
    }
    //  if (
    //   isFunderPage ||
    //   isGrantPage ||
    //   isNPOPage ||
    //   isSearch ||
    //   isSearchNext ||
    //   isPastGrantPage
    // )
    else {
      const ein = searchParams.get("ein");
      const firstname = searchParams.get("firstname");
      const invited = searchParams.get("invited");
      const cid = searchParams.get("cid");
      if (ein) {
        update(ein);
      }
      if (firstname) {
        updateFirstname(firstname);
      }
      if (invited) {
        updateInvited(invited);
      }
      if (cid) {
        updateCid(cid);
      }
    }
  }, [
    user,
    searchParams,
    // isFunderPage,
    // isGrantPage,
    // isNPOPage,
    // isSearch,
    // isSearchNext,
    // isPastGrantPage,
  ]);
  return {
    cid,
    isLoading: isLoading || isLoadingSearchResult,
    ein: context?.ein || ein || undefined,
    firstname: context?.firstname || firstname || undefined,
    invited,
    tags: context,
    textQuery: context?.text_query || undefined,
    npo: npoSearchResult,
  };
}

export default useEINFromSearchParams;
