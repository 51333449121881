import useFunderSearchResult from "../search/useFunderSearchResult";
import useGrantSearchResult from "../search/useGrantSearchResult";

interface useFunderOrGrantNameState {
  data?: string;
  isLoading: boolean;
  error?: object;
}
export default function useFunderOrGrantName(
  id: string,
  type: 0 | 1 | 2,
): useFunderOrGrantNameState {
  const {
    data: funder,
    isLoading: isLoadingFunder,
    error: errorFunder,
  } = useFunderSearchResult(type === 0 ? id : undefined);
  const {
    data: grant,
    isLoading: isLoadingGrant,
    error: errorGrant,
  } = useGrantSearchResult(type === 1 ? id : undefined, process.env.REACT_APP_ROUTER === "FUNDER");
  return {
    data: funder?.name || grant?.name || "",
    isLoading: isLoadingFunder || isLoadingGrant,
    error: errorFunder || errorGrant,
  };
}
