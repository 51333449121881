import classNames from "classnames";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Divider, Popover, RangeSlider, Whisper } from "rsuite";
import { searchSession, sidekickScrollTargetMoreSearchOption } from "../app/recoilStore";
import IMG_FILTER from "../assets/images/filter.svg";
import useTaggingResult, { sortTaggingResult } from "../hooks/search/useTaggingResult";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import useOnboardingData from "../hooks/useOnboarding";
import useStars, { getStarKey } from "../hooks/useStars";
import useUser from "../hooks/useUser";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import { ILocation, reprLocation } from "../types/location";
import {
  AdvancedFilter,
  FinancialFieldName,
  ISearchParamNullable,
  getObjectFromURLSearchParams,
  getURLSearchParamsFromSearchParam,
  getValidDate,
} from "../types/search";
import {
  ASSET_SIZE_OPTION,
  CONTACT_INFO_OPTION,
  DEADLINE_OPTION,
  FUNDER_TYPE_FOR_GRANT_OPTION,
  FUNDER_TYPE_FOR_NPO_OPTION,
  FUNDER_TYPE_OPTION,
  FUNDING_SIZE_OPTION,
  FUNDING_TYPE_OPTION,
  NPO_ADVANCED_FILTER_EXACT_VALUE_OPTION,
  NPO_ADVANCED_FILTER_EXPENSE_PERCENTAGE_OPTION,
  NPO_ADVANCED_FILTER_OPERATOR_OPTION,
  NPO_ADVANCED_FILTER_REVENUE_PERCENTAGE_OPTION,
  NPO_ASSET_SIZE_OPTION,
  NPO_INCOPORATED_STATUS_OPTION,
  NPO_MONTH_OF_LIQUID_OPTION,
  NPO_TAX_EXEMPT_STATUS_OPTION,
  PREVIOUS_FUNDER_OPTION,
  SOLICITATION_OPTION,
  STAGE_OPTION,
} from "../types/searchFilter";
import { TaxonomyConcept } from "../types/taxonomy";
import { zIndexDialog } from "../types/zIndex";
import { prettierTaxonomyString } from "../utils/philanthropy";
import Checkbox from "./checkbox/Checkbox";
import CheckBoxDeadline from "./checkbox/CheckBoxDeadline";
import CheckboxGroup from "./checkbox/CheckboxGroup";
import DropdownSelector from "./dropdown/DropdownSelector";
import GibooGradientDiv from "./GibooGradientDiv";
import LeftInnerIconNumberInput from "./LeftInnerIconNumberInput";
import PlainButton from "./PlainButton";
import TagInfo from "./TagInfo";
import { IOption } from "./tailwind/AsyncCreatableSelector";
import Button from "./tailwind/Button";
import ToggleInput from "./tailwind/ToggleInput";
import YearPicker from "./YearPicker";

interface IFilterOptions {
  className?: string;
  onClick?: () => void;
}
interface ISearchQuery {
  searchType: SEARCH_TYPE;
  keywordCategories: [];
}
type SEARCH_TYPE = "GRANTS" | "FUNDER" | "NPO" | "NAME";
const DEFAULT_MUST_HAVE = false;
const BASE_DATE_DIFF = 90;
export const MoreFilterOptions = ({ className, onClick }: IFilterOptions) => {
  const searchSessionForMixpanel = useRecoilValue(searchSession);
  const mxEvent = useGibooMixpanel(undefined, true);
  const navigate = useNavigate();
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const [searchParams, setSearchParams] = useSearchParams();
  const ref = useRef<HTMLDivElement>(null);
  const [diffQueue, setDiffQueue] = useState<ISearchParamNullable[]>([]);
  const [scrollTargetMoreSearchOption, setScrollTargetMoreSearchOption] = useRecoilState(
    sidekickScrollTargetMoreSearchOption,
  );
  useEffect(() => {
    const targetElement = document.getElementById(scrollTargetMoreSearchOption);
    targetElement?.scrollIntoView({ block: "center", behavior: "auto", inline: "nearest" });
    // return () => setScrollTargetMoreSearchOption("");
  }, [scrollTargetMoreSearchOption, ref, ref?.current]);
  const [showFilterOption, setShowFilterOption] = useState<boolean>(false);
  // useOnClickOutside(ref, () => {
  //   if (showFilterOption) setShowFilterOption(false);
  // });
  const [hideTaxonomySearch, setHideTaxonomySearch] = useState<boolean>(true);
  // search input
  const [specificWord, setSpecificWord] = useState("");
  const [searchPriority, setSearchPriority] = useState<boolean>(DEFAULT_MUST_HAVE);
  // search type
  const [type, setType] = useState<string[]>([]);
  const [searchType, setSearchType] = useState<string>("");
  const [grantActive, setGrantActive] = useState<"all" | "active" | "past">("all");
  // keyword
  const [taxonomyQuery, setTaxonomyQuery] = useState<string>("");
  const [taxonomy, setTaxonomy] = useState<TaxonomyConcept[]>([]);
  const [hqLocation, setHqLocation] = useState<ILocation[]>([]);
  const [serviceLocation, setServiceLocation] = useState<ILocation[]>([]);

  const [taxonomySubs, setTaxonomySubs] = useState<TaxonomyConcept[]>([]);
  const [hqLocationSubs, setHqLocationSubs] = useState<ILocation[]>([]);
  const [serviceLocationSubs, setServiceLocationSubs] = useState<ILocation[]>([]);

  // solicitation
  const [solicitationPriority, setSolicitationPriority] = useState<boolean>(DEFAULT_MUST_HAVE);
  const [solicitation, setSolicitation] = useState<string[]>([]);
  // previous funders
  const [previousFundersPriority, setPreviousFundersPriority] =
    useState<boolean>(DEFAULT_MUST_HAVE);
  const [previousFunders, setPreviousFunders] = useState<string[]>([]);
  // grantType
  const [grantTypePriority, setGrantTypePriority] = useState<boolean>(DEFAULT_MUST_HAVE);
  const [grantType, setGrantType] = useState<string[]>([]);
  // funding size
  const [fundingSizePriority, setFundingSizePriority] = useState<boolean>(DEFAULT_MUST_HAVE);
  const [fundingSize, setFundingSize] = useState<string[]>([]);
  // funder type
  const [funderTypePriority, setFunderTypePriority] = useState<boolean>(DEFAULT_MUST_HAVE);
  const [funderType, setFunderType] = useState<string[]>([]);
  // funding type
  const [fundingTypePriority, setFundingTypePriority] = useState<boolean>(DEFAULT_MUST_HAVE);
  const [fundingType, setFundingType] = useState<string[]>([]);
  // grant deadline
  const [deadlinePriority, setDeadlinePriority] = useState<boolean>(DEFAULT_MUST_HAVE);
  const [deadline, setDeadline] = useState<string[]>([]);
  const currentDate = new Date();
  const [deadlineSpecificFilter, setDeadlineSpecificFilter] = useState<Date | undefined>(
    new Date(),
  );
  // asset size
  const [assetSizePriority, setAssetSizePriority] = useState<boolean>(DEFAULT_MUST_HAVE);
  const [assetSize, setAssetSize] = useState<string[]>([]);
  // funding size
  const [fundingStagePriority, setFundingStagePriority] = useState<boolean>(DEFAULT_MUST_HAVE);
  const [fundingStage, setFundingStage] = useState<string[]>([]);
  // contact infp
  const [contactInfoPriority, setContactInfoPriority] = useState<boolean>(DEFAULT_MUST_HAVE);
  const [contactInfo, setContactInfo] = useState<string[]>([]);
  // use star -- temp
  const { stars, setStars, isStar, isAllStar } = useStars();
  // npo incorporated type
  const [npoIncorporatedTypePriority, setNpoIncorporatedTypePriority] =
    useState<boolean>(DEFAULT_MUST_HAVE);
  const [npoIncorporated, setNpoIncorporated] = useState<string[]>([]);
  // npo tax exempt status
  const [npoTaxExemptTypePriority, setNpoTaxExemptTypePriority] =
    useState<boolean>(DEFAULT_MUST_HAVE);
  const [taxExemptStatus, setTaxExemptStatus] = useState<string[]>([]);
  // npo asset size
  const [npoAssetsizeTypePriority, setNpoAssetsizeTypePriority] =
    useState<boolean>(DEFAULT_MUST_HAVE);
  const [npoAssetsize, setNpoAssetsize] = useState<string[]>([]);
  // npo month of liquid
  const [npoMonthofliquidTypePriority, setNpoMonthofliquidTypePriority] =
    useState<boolean>(DEFAULT_MUST_HAVE);
  const [npoMonthofliquid, setNpoMonthofliquid] = useState<string[]>([]);
  // npo incorporated year
  const [npoIncorporatedYearTypePriority, setNpoIncorporatedYearTypePriority] =
    useState<boolean>(DEFAULT_MUST_HAVE);
  const [startIncorporatedYear, setStartIncorporatedYear] = useState<Date | null>(null);
  const [endIncorporatedYear, setEndIncorporatedYear] = useState<Date | null>(null);
  // npo range funds
  const [npoFundReceivedTypePriority, setNpoFundReceivedTypePriority] =
    useState<boolean>(DEFAULT_MUST_HAVE);

  // npo advance financial filter
  const [npoAdvanceFinancialFilter, setNpoAdvanceFinancialFilter] =
    useState<boolean>(DEFAULT_MUST_HAVE);
  const [exactValue, setExactValue] = useState<AdvancedFilter[]>([]);
  const [percentageOfRev, setPercentageOfRev] = useState<AdvancedFilter[]>([]);
  const [percentageOfExpense, setPercentageOfExpense] = useState<AdvancedFilter[]>([]);
  const emptyNpoFilter = [{ field_name: "", operator: ">", value: undefined }];
  const [fundMin, setFundMin] = useState<number | undefined>();
  const [fundMax, setFundMax] = useState<number | undefined>();
  const [doneTagging, setDoneTagging] = useState<boolean>(false);
  const [textQuery, setTextQuery] = useState<string>("");
  const [mission, setMission] = useState<string>();
  const { data: taggingResult } = useTaggingResult(
    doneTagging ? textQuery : undefined,
    doneTagging ? mission : undefined,
  );
  // test
  // useEffect(() => {
  //   console.log({ exactValue, percentageOfRev, percentageOfExpense });
  // }, [exactValue, percentageOfExpense, percentageOfRev]);

  useEffect(() => {
    console.log({
      taxExemptStatus,
      startIncorporatedYear: startIncorporatedYear?.getFullYear(),
      endIncorporatedYear: endIncorporatedYear?.getFullYear(),
      fundMin,
      fundMax,
      npoMonthofliquid,
      npoAssetsize,
      npoIncorporated,
      real: {
        fundMin: searchParams.get("npo_grant_amount_min"),
        fundMax: searchParams.get("npo_grant_amount_max"),
      },
    });
  }, [
    searchParams,
    startIncorporatedYear,
    endIncorporatedYear,
    fundMin,
    fundMax,
    npoMonthofliquid,
    npoAssetsize,
    taxExemptStatus,
    npoIncorporated,
  ]);

  useEffect(() => {
    const done_tagging = searchParams.get("done_tagging") === "true" ? true : false;
    const _obj = getObjectFromURLSearchParams(searchParams);
    const obj = { ..._obj, ...prettierTaxonomyString(_obj) };
    setTaxonomy([
      ...obj.focus_area.map((t) => ({ type: "f", label: t, object_id: "" })),
      ...obj.beneficiary.map((t) => ({ type: "b", label: t, object_id: "" })),
      ...obj.program.map((t) => ({ type: "p", label: t, object_id: "" })),
    ]);
    setTaxonomySubs([
      ...obj.focus_area_subs.map((t) => ({ type: "f", label: t, object_id: "" })),
      ...obj.beneficiary_subs.map((t) => ({ type: "b", label: t, object_id: "" })),
      ...obj.program_subs.map((t) => ({ type: "p", label: t, object_id: "" })),
    ]);
    setServiceLocation(obj.service_loc);
    setServiceLocationSubs([...(obj.service_loc_subs || [])]);
    setHqLocation(obj.hq_loc);
    setHqLocationSubs([...(obj.hq_loc_subs || [])]);
    setStars([...(obj.stars || [])]);
    setSolicitation(obj.solicitation);
    setPreviousFunders(obj.previous_funders.length > 1 ? ["hide"] : obj.previous_funders);
    setAssetSize(obj.funder_assets);
    setContactInfo(obj.contact);
    setFunderType(obj.funder_type);
    setFundingType(obj.funding_type);
    setFundingSize(obj.funding_size);
    const _deadline_date = getValidDate(obj.grant_deadline);
    setDeadline(obj.grant_deadline_filter);
    setDeadlineSpecificFilter(_deadline_date || new Date());
    setGrantType(obj.grant_type);
    setFundingStage(obj.grantee_stage);
    setTextQuery(obj.text_query);
    setMission(obj.mission);
    setDoneTagging(done_tagging);
    setType(obj.type);
    setNpoAssetsize(obj.npo_assets);
    setNpoIncorporated(obj.incorporated_status);
    setNpoMonthofliquid(obj.npo_month_of_liquid);
    setTaxExemptStatus(obj.tax_exempt_status);
    setStartIncorporatedYear(
      obj.year_of_formation_min !== undefined &&
        obj.year_of_formation_min >= 0 &&
        obj.year_of_formation_min < 10000
        ? new Date(obj.year_of_formation_min, 0, 1)
        : null,
    );
    setEndIncorporatedYear(
      obj.year_of_formation_max !== undefined &&
        obj.year_of_formation_max >= 0 &&
        obj.year_of_formation_max < 10000
        ? new Date(obj.year_of_formation_max, 0, 1)
        : null,
    );
    setFundMin(obj.npo_grant_amount_min);
    setFundMax(obj.npo_grant_amount_max);
    // if (obj.solicitation.length === 0) setSolicitationPriority(false);
    // if (obj.grant_type.length === 0) setGrantTypePriority(false);
    // if (obj.funding_size.length === 0) setFundingSizePriority(false);
    // if (obj.funder_assets.length === 0) setAssetSizePriority(false);
    // if (obj.grantee_stage.length === 0) setFundingStagePriority(false);
    // if (obj.contact.length === 0) setContactInfoPriority(false);
    // if (obj.funder_type.length === 0) setFunderTypePriority(false);
    setExactValue(obj.npo_financial_exact_value_filters);
    setPercentageOfRev(obj.npo_financial_per_revenue_filters);
    setPercentageOfExpense(obj.npo_financial_per_expense_filters);
  }, [searchParams, setSearchParams]);
  const updateFilter = useCallback(
    (diff: ISearchParamNullable) => {
      setDiffQueue((prev) => [...prev, diff]);
    },
    [setDiffQueue],
  );
  useEffect(() => {
    if (diffQueue.length === 0) return;
    const diff = diffQueue[0];
    const done_tagging = searchParams.get("done_tagging") === "true" ? true : false;
    const _obj = getObjectFromURLSearchParams(searchParams);
    if (!done_tagging) return;
    const obj = {
      ..._obj,
      ...diff,
    };
    if (
      getURLSearchParamsFromSearchParam(obj).toString() !==
      getURLSearchParamsFromSearchParam(_obj).toString()
    ) {
      navigate(
        `/search?${getURLSearchParamsFromSearchParam(
          {
            ...obj,
            updated_fields: [
              ...(_obj.updated_fields?.filter((v) => !Object.keys(diff).includes(v)) || []),
              ...Object.keys(diff),
            ],
          },
          true,
        ).toString()}`,
      );
    }
    setDiffQueue((prev) => prev.slice(1));
  }, [diffQueue, searchParams, setDiffQueue]);

  const renderInput = () => {
    return (
      <div className="flex flex-col">
        <div className="inline-flex items-center gap-2">
          <Button
            id={`reset-default`}
            color="gray"
            labelClass="!text-sm !text-gray-900"
            className="!h-[29px] !w-fit"
            handleOnClick={() => {
              const sortedRes = taggingResult ? sortTaggingResult(taggingResult) : undefined;
              const service_loc = onboardingData.service_loc;

              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "reset_default",
              });
              updateFilter({
                mission: onboardingData.mission || "",
                focus_area: sortedRes?.focus_area || [],
                beneficiary: sortedRes?.beneficiary || [],
                program: sortedRes?.program || [],
                focus_area_subs: sortedRes?.focus_area || [],
                beneficiary_subs: sortedRes?.beneficiary || [],
                program_subs: sortedRes?.program || [],
                service_loc: service_loc,
                service_loc_subs: service_loc,
                hq_loc: [],
                hq_loc_subs: service_loc,
                stars: [
                  ...service_loc
                    .filter((l) => l.level && l.level < 2)
                    .map((l) => getStarKey("service_loc", reprLocation(l))),
                ],
                excludes: [],
                solicitation: [], // SOLICITATION_OPTION.map((v) => v.value),
                grant_type: [], // GRANT_TYPE_OPTION.map((v) => v.value),
                grantee_type: [],
                grant_deadline_filter: [],
                grant_deadline: undefined,
                funding_size: [], // FUNDING_SIZE_OPTION.map((v) => v.value),
                funder_type: [], // DEFAULT_FUNDER_TYPE, //FUNDER_TYPE_OPTION.map((v) => v.value),
                funder_assets: [], // ASSET_SIZE_OPTION.map((v) => v.value),
                grantee_stage: [], // STAGE_OPTION.map((v) => v.value),
                contact: [],
                sortby:
                  searchType === "virtaul_grant" || searchType === "funder"
                    ? "total_matched_grant_amount"
                    : "relevance",
                any_location: undefined,
                funding_type: [],
                incorporated_status: [],
                npo_assets: [],
                npo_grant_amount_max: undefined,
                npo_grant_amount_min: undefined,
                previous_funders: [],
                npo_month_of_liquid: [],
                tax_exempt_status: [],
                year_of_formation_max: undefined,
                year_of_formation_min: undefined,
              });
            }}
            label={"Reset to default settings"}
            outline
          />
          <Button
            id={`clear-all`}
            color="gray"
            labelClass="!text-sm !text-gray-900"
            className="!h-[29px] !w-fit"
            handleOnClick={() => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "clear_all",
              });
              updateFilter({
                mission: "",
                stars: [],
                excludes: [],
                focus_area: [],
                beneficiary: [],
                program: [],
                service_loc: [],
                hq_loc: [],
                focus_area_subs: [],
                beneficiary_subs: [],
                program_subs: [],
                service_loc_subs: [],
                hq_loc_subs: [],
                solicitation: [],
                grant_type: [],
                grantee_type: [],
                grant_deadline: undefined,
                grant_deadline_filter: [],
                funding_size: [],
                funder_type: [],
                funder_assets: [],
                grantee_stage: [],
                contact: [],
                sortby:
                  searchType === "virtaul_grant" || searchType === "funder"
                    ? "total_matched_grant_amount"
                    : "relevance",
                any_location: undefined,
                funding_type: [],
                incorporated_status: [],
                npo_assets: [],
                npo_grant_amount_max: undefined,
                npo_grant_amount_min: undefined,
                previous_funders: [],
                npo_month_of_liquid: [],
                tax_exempt_status: [],
                year_of_formation_max: undefined,
                year_of_formation_min: undefined,
              });
            }}
            label={"Clear all"}
            outline
          />
        </div>
      </div>
    );
  };

  const renderSolicitation = () => {
    return (
      <>
        <ExpandOption
          id="solicitation"
          label="Solicitation types"
          priority={solicitationPriority}
          setPriority={setSolicitationPriority}
          // showPriority={solicitation.length > 0}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_solicitation"
            data={SOLICITATION_OPTION}
            value={solicitation}
            setValue={(v) => {
              updateFilter({ solicitation: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "solicitation",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };
  const renderPreviousFunders = () => {
    return (
      <>
        <ExpandOption
          id="previousFunders"
          label="Previous funders"
          priority={previousFundersPriority}
          setPriority={setPreviousFundersPriority}
          // showPriority={solicitation.length > 0}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_previous_funders"
            data={PREVIOUS_FUNDER_OPTION}
            value={previousFunders}
            setValue={(v) => {
              updateFilter({
                previous_funders:
                  v.length > 1 ? (previousFunders.includes("view") ? ["hide"] : ["view"]) : v,
                npo_uid: onboardingData.npo_uid,
              });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "previous_funder",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };

  const renderFunderType = () => {
    return (
      <>
        <ExpandOption
          id="funder-type"
          label="Funder type"
          priority={funderTypePriority}
          setPriority={setFunderTypePriority}
          // showPriority={funderType.length > 0}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_fundertype"
            data={FUNDER_TYPE_OPTION}
            value={funderType}
            setValue={(v) => {
              updateFilter({ funder_type: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "funder_type",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };
  const renderNPOType = () => {
    return (
      <>
        <ExpandOption
          id="npo-type"
          label="Nonprofit type"
          priority={funderTypePriority}
          setPriority={setFunderTypePriority}
          // showPriority={funderType.length > 0}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_npo_type"
            data={FUNDER_TYPE_FOR_NPO_OPTION}
            value={funderType}
            setValue={(v) => {
              updateFilter({ funder_type: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "npo_type",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };
  const renderFunderTypeForGrant = () => {
    return (
      <>
        <ExpandOption
          id="funder-type"
          label="Funder type"
          priority={funderTypePriority}
          setPriority={setFunderTypePriority}
          // showPriority={funderType.length > 0}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_fundertype_for_grant"
            data={FUNDER_TYPE_FOR_GRANT_OPTION}
            value={funderType}
            setValue={(v) => {
              updateFilter({ funder_type: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "funder_type",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };
  const renderFundingType = () => {
    return (
      <>
        <ExpandOption
          id="funding-type"
          label="Grant categories"
          priority={fundingTypePriority}
          setPriority={setFundingTypePriority}
          // showPriority={funderType.length > 0}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_fundingtype_for_grant"
            data={FUNDING_TYPE_OPTION}
            value={fundingType}
            setValue={(v) => {
              updateFilter({ funding_type: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "grant_category",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
            renderInfo={(value) => {
              return (
                <TagInfo
                  type={"FUNDING_TYPE_OPTION"}
                  value={value}
                  placements="autoHorizontalStart"
                />
              );
            }}
          />
        </ExpandOption>
      </>
    );
  };
  const renderFundingSize = () => {
    return (
      <>
        <ExpandOption
          id="fund-size"
          label={
            type.includes("grant") || type.includes("virtual_grant")
              ? "Grant amount"
              : "Funding size"
          }
          priority={fundingSizePriority}
          setPriority={setFundingSizePriority}
          // showPriority={fundingSize.length > 0}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_funding_size"
            data={FUNDING_SIZE_OPTION}
            value={fundingSize}
            setValue={(v) => {
              updateFilter({ funding_size: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName:
                  type.includes("grant") || type.includes("virtual_grant")
                    ? "grant_amount"
                    : "funding_size",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };
  const renderDeadline = () => {
    return (
      <div className="relative z-[2]">
        <ExpandOption
          id="deadline"
          label="Grant deadline"
          priority={deadlinePriority}
          setPriority={setDeadlinePriority}
          // showPriority={deadline !== "all"}
        >
          <CheckBoxDeadline
            id="deadline"
            data={DEADLINE_OPTION}
            value={deadline}
            setValue={(v) => {
              updateFilter({ grant_deadline_filter: v });
            }}
            dateValue={deadlineSpecificFilter}
            setDateValue={(v) => {
              if (v) {
                updateFilter({ grant_deadline: v.toISOString() });
                mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                  session: searchSessionForMixpanel,
                  searchType: searchParams.get("type"),
                  filterName: "deadline",
                  filterValue: "specific",
                  filterAction: "check",
                  value: v.toISOString(),
                });
              } else updateFilter({ grant_deadline: undefined });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "deadline",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            downIcon
          />
        </ExpandOption>
      </div>
    );
  };
  const renderAssetSize = () => {
    return (
      <>
        <ExpandOption
          id="asset-size"
          label="Funder's asset size"
          priority={assetSizePriority}
          setPriority={setAssetSizePriority}
          // showPriority={assetSize.length > 0}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_asset_size"
            data={ASSET_SIZE_OPTION}
            value={assetSize}
            setValue={(v) => {
              updateFilter({ funder_assets: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "asset_size",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };
  const renderFundingStage = () => {
    return (
      <>
        <ExpandOption
          id="stage"
          label={type.includes("npo") ? "Stage analysis" : "Funding stages analysis"}
          priority={fundingStagePriority}
          setPriority={setFundingStagePriority}
          renderInfo={() => <TagInfo type="OTHERS" value="FUNDING_STAGE_ANALYSIS" />}
          // showPriority={fundingStage.length > 0}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_stage"
            data={STAGE_OPTION}
            value={fundingStage}
            setValue={(v) => {
              updateFilter({ grantee_stage: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "stage",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };
  const renderContactInform = () => {
    return (
      <>
        <ExpandOption
          id="contact-info"
          label="Contact availability"
          priority={contactInfoPriority}
          setPriority={setContactInfoPriority}
          renderInfo={() => <TagInfo type="OTHERS" value="CONTACT" />}
          // showPriority={contactInfo.length > 0}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_contact_info"
            data={CONTACT_INFO_OPTION}
            value={contactInfo}
            setValue={(v) => {
              updateFilter({ contact: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "contact",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };

  const renderNpoIncorporatedStatus = () => {
    return (
      <>
        <ExpandOption
          id="npo-incorporated"
          label="Incorporated status"
          priority={npoIncorporatedTypePriority}
          setPriority={setNpoIncorporatedTypePriority}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_npo_incorporated_status"
            data={NPO_INCOPORATED_STATUS_OPTION}
            value={npoIncorporated}
            setValue={(v) => {
              // setNpoIncorporated(v);
              updateFilter({ incorporated_status: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "incorporated_status",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };
  const renderNpoTaxExemptStatus = () => {
    return (
      <>
        <ExpandOption
          id="npo-tax-exempt"
          label="Tax-exempt status"
          priority={npoTaxExemptTypePriority}
          setPriority={setNpoTaxExemptTypePriority}
        >
          <CheckboxGroup<string>
            id="GA_TAG_click_npo_taxexempt_status"
            data={NPO_TAX_EXEMPT_STATUS_OPTION}
            value={taxExemptStatus}
            setValue={(v) => {
              // setTaxExemptStatus(v); //temp
              updateFilter({ tax_exempt_status: v });
            }}
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "tax_exempt_status",
                filterValue: t,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            flexColumn
          />
        </ExpandOption>
      </>
    );
  };
  const renderNpoAssetSize = () => {
    const converted = npoAssetsize
      .map((v) => NPO_ASSET_SIZE_OPTION.findIndex((o) => o.value === v))
      .filter((i) => i >= 0)
      .sort((a, b) => a - b);
    const min = converted.length === 0 ? 0 : converted[0];
    const max =
      converted.length === 0 ? NPO_ASSET_SIZE_OPTION.length - 1 : converted[converted.length - 1];
    return (
      <>
        <ExpandOption
          id="npo-asset-size"
          label="Nonprofit asset size"
          priority={npoAssetsizeTypePriority}
          setPriority={setNpoAssetsizeTypePriority}
        >
          <RangeSlider
            value={[min, max]}
            className="mx-2 mt-4"
            min={0}
            step={1}
            max={NPO_ASSET_SIZE_OPTION.length - 1}
            constraint={(range) => range[0] !== range[1]}
            onChange={([min, max]) => {
              // setNpoAssetsize(
              //   NPO_ASSET_SIZE_OPTION.filter((_, i) => i >= min && i <= max).map((o) => o.value),
              // );
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "npo_asset_size",
                filterValue: `${
                  min >= 0 && min < NPO_ASSET_SIZE_OPTION.length
                    ? NPO_ASSET_SIZE_OPTION[min].value
                    : ""
                } - ${
                  max >= 0 && max < NPO_ASSET_SIZE_OPTION.length
                    ? NPO_ASSET_SIZE_OPTION[max].value
                    : ""
                }`,
                filterAction: "check",
              });
              updateFilter({
                npo_assets: NPO_ASSET_SIZE_OPTION.filter((_, i) => i >= min && i <= max).map(
                  (o) => o.value,
                ),
              });
            }}
            graduated
            tooltip={false}
            renderMark={(mark) => {
              return (
                <span
                  className={classNames(
                    "text-purple-500",
                    mark === NPO_ASSET_SIZE_OPTION.length - 1 && "pr-7",
                    mark === 0 && "pl-2",
                  )}
                >
                  {NPO_ASSET_SIZE_OPTION[mark]?.label}
                </span>
              );
            }}
          />
        </ExpandOption>
      </>
    );
  };
  const renderNpoMonthofliquid = () => {
    const converted = npoMonthofliquid
      .map((v) => NPO_MONTH_OF_LIQUID_OPTION.findIndex((o) => o.value === v))
      .filter((i) => i >= 0)
      .sort((a, b) => a - b);
    const min = converted.length === 0 ? 0 : converted[0];
    const max =
      converted.length === 0
        ? NPO_MONTH_OF_LIQUID_OPTION.length - 1
        : converted[converted.length - 1];
    return (
      <>
        <ExpandOption
          id="npo-monthofliquid"
          label="Month of liquid"
          priority={npoMonthofliquidTypePriority}
          setPriority={setNpoMonthofliquidTypePriority}
        >
          <RangeSlider
            value={[min, max]}
            className="mx-2 mt-4"
            min={0}
            step={1}
            max={NPO_MONTH_OF_LIQUID_OPTION.length - 1}
            constraint={(range) => range[0] !== range[1]}
            onChange={([min, max]) => {
              // setNpoMonthofliquid(
              //   NPO_MONTH_OF_LIQUID_OPTION.filter((_, i) => i >= min && i <= max).map(
              //     (o) => o.value,
              //   ),
              // );
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "month_of_liquid",
                filterValue: `${
                  min >= 0 && min < NPO_MONTH_OF_LIQUID_OPTION.length
                    ? NPO_MONTH_OF_LIQUID_OPTION[min].value
                    : ""
                } - ${
                  max >= 0 && max < NPO_MONTH_OF_LIQUID_OPTION.length
                    ? NPO_MONTH_OF_LIQUID_OPTION[max].value
                    : ""
                }`,
                filterAction: "check",
              });
              updateFilter({
                npo_month_of_liquid: NPO_MONTH_OF_LIQUID_OPTION.filter(
                  (_, i) => i >= min && i <= max,
                ).map((o) => o.value),
              });
            }}
            graduated
            tooltip={false}
            renderMark={(mark) => {
              return (
                <span
                  className={classNames(
                    "text-purple-500",
                    mark === NPO_MONTH_OF_LIQUID_OPTION.length - 1 && "pr-7",
                    mark === 0 && "pl-2",
                  )}
                >
                  {NPO_MONTH_OF_LIQUID_OPTION[mark]?.label}
                </span>
              );
            }}
          />
          {/* <CheckboxGroup<string>
            id="GA_TAG_click_npo_taxexempt_status"
            data={TAX_EXEMPT_STATUS_OPTION}
            value={taxExemptStatus}
            setValue={(v) => {
              // updateFilter({ funder_type: v });
            }}
            flexColumn
          /> */}
        </ExpandOption>
      </>
    );
  };
  const renderNpoFinancialFilters = () => {
    return (
      <>
        <ExpandOption
          id="npo-advancefilter"
          label="Advanced financial filters"
          priority={npoAdvanceFinancialFilter}
          setPriority={setNpoAdvanceFinancialFilter}
        >
          <div className="mb-5 mt-2 flex flex-col gap-5">
            <AdvanceFilter
              onCheck={(v: AdvancedFilter, check: boolean) => {
                mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                  session: searchSessionForMixpanel,
                  searchType: searchParams.get("type"),
                  filterName: "npo_advanced_filter",
                  filterValue: `${v.field_name} ${v.operator} ${v.value}`,
                  filterAction: check ? "check" : "uncheck",
                });
              }}
              id="exact-value"
              label="Exact value"
              filter={exactValue.length === 0 ? (emptyNpoFilter as any) : exactValue}
              updateFilter={(f) => updateFilter({ npo_financial_exact_value_filters: f })}
              filterOption={NPO_ADVANCED_FILTER_EXACT_VALUE_OPTION}
              operatorOption={NPO_ADVANCED_FILTER_OPERATOR_OPTION}
              placeholder="Enter value"
              iconClass="gi-md gi-dollar !mt-[-10px] text-gray-400"
            />
            <AdvanceFilter
              onCheck={(v: AdvancedFilter, check: boolean) => {
                mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                  session: searchSessionForMixpanel,
                  searchType: searchParams.get("type"),
                  filterName: "npo_advanced_filter",
                  filterValue: `${v.field_name} ${v.operator} ${v.value}`,
                  filterAction: check ? "check" : "uncheck",
                });
              }}
              id="per-revenue"
              label="Percentage of revenue"
              filter={percentageOfRev.length === 0 ? (emptyNpoFilter as any) : percentageOfRev}
              updateFilter={(f) => updateFilter({ npo_financial_per_revenue_filters: f })}
              filterOption={NPO_ADVANCED_FILTER_REVENUE_PERCENTAGE_OPTION}
              operatorOption={NPO_ADVANCED_FILTER_OPERATOR_OPTION}
              iconClass="fa fa-percent !mt-[-6px] !text-xs text-gray-400"
              placeholder="Enter value"
            />
            <AdvanceFilter
              onCheck={(v: AdvancedFilter, check: boolean) => {
                mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                  session: searchSessionForMixpanel,
                  searchType: searchParams.get("type"),
                  filterName: "npo_advanced_filter",
                  filterValue: `${v.field_name} ${v.operator} ${v.value}`,
                  filterAction: check ? "check" : "uncheck",
                });
              }}
              id="per-expense"
              label="Percentage of expense"
              filter={
                percentageOfExpense.length === 0 ? (emptyNpoFilter as any) : percentageOfExpense
              }
              updateFilter={(f) => updateFilter({ npo_financial_per_expense_filters: f })}
              filterOption={NPO_ADVANCED_FILTER_EXPENSE_PERCENTAGE_OPTION}
              operatorOption={NPO_ADVANCED_FILTER_OPERATOR_OPTION}
              iconClass="fa fa-percent !mt-[-6px] !text-xs text-gray-400"
              placeholder="Enter value"
            />
          </div>
        </ExpandOption>
      </>
    );
  };
  const renderNpoIncorporatedYear = () => {
    return (
      <>
        <ExpandOption
          id="npo-incorporated-year"
          label="Incorporated year"
          priority={npoIncorporatedTypePriority}
          setPriority={setNpoIncorporatedYearTypePriority}
        >
          <Checkbox
            id={`input-check-consent`}
            className=""
            size="md"
            checked={!!startIncorporatedYear || !!endIncorporatedYear}
            onChange={() => {
              setStartIncorporatedYear(null);
              setEndIncorporatedYear(null);
            }}
            renderItem={
              <div
                className="ml-4 flex flex-col  space-y-5 md:flex-row md:items-center md:space-y-0"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <YearPicker
                  value={startIncorporatedYear}
                  setValue={(v) => {
                    updateFilter({ year_of_formation_min: v?.getFullYear() || undefined });
                    mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                      session: searchSessionForMixpanel,
                      searchType: searchParams.get("type"),
                      filterName: "incorporated_year",
                      filterValue: `${v?.getFullYear()} - ${endIncorporatedYear || "over"}`,
                      filterAction: "check",
                    });
                  }}
                />
                <span className="mx-4 h-[1px] w-4 bg-gray-500 " />
                <YearPicker
                  value={endIncorporatedYear}
                  setValue={(v) => {
                    updateFilter({ year_of_formation_max: v?.getFullYear() || undefined });
                    mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                      session: searchSessionForMixpanel,
                      searchType: searchParams.get("type"),
                      filterName: "incorporated_year",
                      filterValue: `${startIncorporatedYear} - ${v?.getFullYear()}`,
                      filterAction: "check",
                    });
                  }}
                  min={startIncorporatedYear}
                  disabled={!startIncorporatedYear}
                />
              </div>
            }
          />
        </ExpandOption>
      </>
    );
  };
  const renderNpoFundRange = () => {
    const sendEvent = () => {
      mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
        session: searchSessionForMixpanel,
        searchType: searchParams.get("type"),
        filterName: "npo_fund_range",
        filterValue: `${fundMin === undefined ? 0 : fundMin || 0} - ${
          fundMax === undefined ? "over" : fundMax || 0
        }`,
        filterAction: "check",
      });
    };
    return (
      <>
        <ExpandOption
          id="npo-incorporated-year"
          label="Range of received funding"
          priority={npoFundReceivedTypePriority}
          setPriority={setNpoFundReceivedTypePriority}
        >
          <div className=" flex flex-col  space-y-5 md:flex-row md:items-center md:space-y-0">
            <LeftInnerIconNumberInput
              id="input-number-1"
              className="!h-[40px] !pl-[25px]"
              value={fundMin || 0}
              min={0}
              setValue={(value) => {
                setFundMin(Number(value));
                // updateFilter({ npo_grant_amount_min: Number(value || 0) });
              }}
              handleOnBlur={() => {
                updateFilter({
                  npo_grant_amount_min: fundMin === undefined ? undefined : fundMin || 0,
                });
                sendEvent();
              }}
              handleOnKeyDown={(e) => {
                if (e.key === "Enter") {
                  updateFilter({
                    npo_grant_amount_min: fundMin === undefined ? undefined : fundMin || 0,
                  });
                  sendEvent();
                }
              }}
              iconClass="gi gi-dollar -mt-1 text-xs text-gray-500 !px-2"
              placeholder="Enter amount"
            />
            <span className="mx-4 h-[1px] w-3 bg-gray-500 " />
            <LeftInnerIconNumberInput
              id="input-number-1"
              className="!h-[40px] !pl-[25px]"
              value={fundMax || 0}
              setValue={(value) => {
                setFundMax(Number(value));
              }}
              min={0}
              handleOnBlur={() => {
                updateFilter({
                  npo_grant_amount_max:
                    fundMax === undefined ? undefined : Math.max(fundMax || 0, fundMin || 0),
                });
                sendEvent();
              }}
              handleOnKeyDown={(e) => {
                if (e.key === "Enter") {
                  updateFilter({
                    npo_grant_amount_max:
                      fundMax === undefined ? undefined : Math.max(fundMax || 0, fundMin || 0),
                  });
                  sendEvent();
                }
              }}
              iconClass="gi gi-dollar -mt-1 text-xs text-gray-500 !px-2"
              placeholder="Enter amount"
            />
          </div>
        </ExpandOption>
      </>
    );
  };
  const renderContent = () => {
    return (
      <div ref={ref} id="more-filter-option">
        {renderInput()}
        {/* <Divider className="my-5" />
        {renderSearchPlus()} */}
        {/* {type.includes("grant") && (
          <>
            <Divider className="my-5" />
            {renderGrantActive()}
          </>
        )} */}
        {/* <Divider className="my-5" />
        {renderSpecificWord()} */}
        {/* <Divider className="my-5" />
        {renderKeywordsCategories()}
        <Divider className="my-5" />
        {renderServiceLocation()}
        {!type.includes("past_grant") && (
          <>
            <Divider className="my-5" />
            {renderHqLocation()}
          </>
        )} */}
        {type.includes("grant") && (
          <>
            <Divider className="my-5" />
            {renderFunderTypeForGrant()}
            {/* <Divider className="my-5" />
            {renderGrantType()} */}
          </>
        )}
        {(type.includes("funder") || type.includes("virtual_grant")) && (
          <>
            <Divider className="my-5" />
            {renderFunderType()}
          </>
        )}
        {(type.includes("npo") || type.includes("eligible_npo")) && (
          <>
            <Divider className="my-5" />
            {renderNPOType()}
          </>
        )}
        {type.includes("funder") && (
          <>
            <Divider className="my-5" />
            {renderSolicitation()}
          </>
        )}
        {(type.includes("funder") || type.includes("virtual_grant")) && (
          <>
            <Divider className="my-5" />
            {renderPreviousFunders()}
          </>
        )}
        {(type.includes("grant") || type.includes("virtual_grant")) && (
          <>
            <Divider className="my-5" />
            {renderFundingType()}
            <Divider className="my-5" />
            {renderDeadline()}
          </>
        )}
        {(type.includes("grant") || type.includes("virtual_grant") || type.includes("funder")) && (
          <>
            <Divider className="my-5" />
            {renderFundingSize()}
            <Divider className="my-5" />
            {renderAssetSize()}
          </>
        )}
        {(type.includes("grant") ||
          type.includes("virtual_grant") ||
          type.includes("funder") ||
          type.includes("npo") ||
          type.includes("eligible_npo")) && (
          <>
            <Divider className="my-5" />
            {renderFundingStage()}
            <Divider className="my-5" />
            {renderContactInform()}
          </>
        )}
        {(type.includes("npo") || type.includes("eligible_npo")) && (
          <>
            <Divider className="my-5" />
            {renderNpoIncorporatedStatus()}
            <Divider className="my-5" />
            {renderNpoIncorporatedYear()}

            {type.includes("npo") && (
              <>
                <Divider className="my-5" />
                {renderNpoTaxExemptStatus()}
              </>
            )}
            <Divider className="my-5" />
            {renderNpoFundRange()}
            <Divider className="my-5" />
            {renderNpoAssetSize()}
            <Divider className="my-5" />
            {renderNpoMonthofliquid()}
            <Divider className="my-5" />
            {renderNpoFinancialFilters()}
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <GibooGradientDiv
        id="btn-filter-more"
        borderSize={1}
        className="!h-[29px] min-w-[190px] py-1"
        onClick={onClick}
      >
        <Whisper
          placement="bottomStart"
          className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)] "
          trigger="click"
          speaker={
            <Popover
              ref={ref}
              arrow={false}
              className={classNames(
                "!mt-1 max-h-[calc(100vh-188px)] w-[428px] max-w-[428px] overflow-y-scroll p-5",
                className,
                zIndexDialog,
              )}
            >
              {renderContent()}
            </Popover>
          }
          onClose={() => setScrollTargetMoreSearchOption("")}
        >
          <div
            className="inline-flex w-fit cursor-pointer items-center gap-2 px-2"
            id="GA_TAG_click_moresearchoption"
            aria-valuetext="More search option"
            onClick={() => {
              setShowFilterOption((prev) => !prev);
            }}
          >
            <img className="h-5 w-5" src={IMG_FILTER} />
            <h5 className="whitespace-nowrap font-poppins text-sm text-gray-900">
              More search options
            </h5>
          </div>
        </Whisper>
      </GibooGradientDiv>
    </>
  );
};

interface ISelector {
  id: string;
  children?: JSX.Element;
  label: string;
  priority?: boolean;
  hasPriority?: boolean;
  setPriority?: Dispatch<SetStateAction<boolean>>;
  hideArrow?: boolean;
  showPriority?: boolean;
  renderInfo?: () => ReactNode;
}
function ExpandOption({
  id,
  children,
  label,
  setPriority,
  hasPriority = true,
  priority = false,
  hideArrow = false,
  showPriority = false,
  renderInfo,
}: ISelector) {
  const scrollTargetMoreSearchOption = useRecoilValue(sidekickScrollTargetMoreSearchOption);
  const [isOpen, setIsOpened] = useState<boolean>(true);
  return (
    <div className="flex flex-col gap-2" id={id}>
      <div className="inline-flex w-full justify-between">
        <div className="inline-flex items-center gap-1">
          <div
            className={classNames(
              "inline-flex items-center gap-2",
              scrollTargetMoreSearchOption === id ? "rounded bg-purple-50 px-2 py-1" : "",
            )}
          >
            <p className="font-poppins text-sm font-semibold">{label}</p>
            {renderInfo && renderInfo()}
          </div>
          {showPriority && hasPriority && (
            <>
              <Divider vertical />
              <ToggleInput
                id={id}
                isChecked={priority}
                label="Must have"
                onChange={() => {
                  setPriority?.((prev) => !prev);
                }}
              />
            </>
          )}
        </div>
        {!hideArrow && (
          <span
            className="cursor-pointer !text-gray-700"
            onClick={() => setIsOpened((prev) => !prev)}
          >
            {isOpen ? <i className="gi-angle-up gi-md" /> : <i className="gi-angle-down gi-md" />}
          </span>
        )}
      </div>
      {isOpen && children}
    </div>
  );
}
export { ExpandOption };
function AdvanceFilter({
  onCheck,
  id,
  label,
  filter,
  updateFilter,
  filterOption,
  operatorOption,
  additionalComponent,
  noIcon,
  iconClass = "gi gi-dollar !mt-[1px]",
  placeholder,
}: {
  onCheck?: (v: AdvancedFilter, check: boolean) => void;
  id: string;
  label: string;
  filter: AdvancedFilter[];
  updateFilter: (v: AdvancedFilter[]) => void;
  filterOption: IOption[];
  operatorOption: IOption[];
  additionalComponent?: ReactNode;
  noIcon?: boolean;
  iconClass?: string;
  placeholder?: string;
}) {
  const [values, setValues] = useState<{ [key: number]: undefined | number }>({});
  useEffect(() => {
    setValues(filter.reduce((prev, cur, index) => ({ ...prev, [index]: cur.value }), {}));
  }, [filter]);
  return (
    <div className="flex w-full flex-col gap-2">
      <p className="font-poppins text-xs font-semibold text-gray-900">{label}</p>
      {filter.map((item, index) => {
        return (
          <div className="inline-flex w-full items-start gap-2" key={`input-filter-${index}`}>
            <div className="flex w-full flex-col items-start gap-[10px] rounded-md bg-gray-50 p-5">
              <div className="inline-flex w-full items-center justify-start gap-[10px]">
                <DropdownSelector<IOption>
                  id={`input-${id}-filter-${index}`}
                  color="gray"
                  inputClass="!min-h-[26px] !w-full !h-full"
                  className="!min-h-[26px] !w-full "
                  iconElement={<i className={classNames("gi gi-angle-down text-gray-500")} />}
                  data={filterOption}
                  placeholder="Choose filter"
                  placeholderClass="!text-xs"
                  selected={filterOption.find((i) => i.value === item.field_name)}
                  setSelected={(v) => {
                    const newFilters = [
                      ...filter.slice(0, index),
                      { ...filter[index], field_name: v?.value as FinancialFieldName | undefined },
                      ...filter.slice(index + 1),
                    ];
                    const f = newFilters.at(index);
                    if (f) onCheck?.(f, true);
                    updateFilter(newFilters);
                  }}
                  maxHeight={"400px"}
                  getKey={(i) => i.value}
                  renderItem={(i) => i.label}
                  isClearable={false}
                  downIcon
                  compact
                />
                <p className="font-poppins text-xs text-gray-700">is</p>
              </div>
              <div className="inline-flex w-full items-center gap-[10px]">
                <DropdownSelector<IOption>
                  id={`input-${id}-op-${index}`}
                  color="gray"
                  inputClass="!min-h-[26px]  !text-xs !px-2 !h-full"
                  className="!min-h-[26px] flex-1"
                  iconElement={<i className={classNames("gi gi-angle-down text-gray-500")} />}
                  data={operatorOption}
                  placeholder="Choose filter"
                  placeholderClass="!text-xs"
                  selected={operatorOption.find((i) => i.value === item.operator)}
                  setSelected={(v) => {
                    const newFilters = [
                      ...filter.slice(0, index),
                      { ...filter[index], operator: v?.value as "<" | ">" | undefined },
                      ...filter.slice(index + 1),
                    ];
                    const f = newFilters.at(index);
                    if (f) onCheck?.(f, true);
                    updateFilter(newFilters);
                  }}
                  maxHeight={"400px"}
                  getKey={(i) => i.value}
                  renderItem={(i) => i.label}
                  isClearable={false}
                  downIcon
                  compact
                />
                <div className={classNames("flex-1")}>
                  <LeftInnerIconNumberInput
                    id={`input-${id}-number-${index}`}
                    className="!h-[26px] !border-gray-400 !pl-[25px] !text-xs !font-normal !text-gray-500 placeholder:!text-xs"
                    value={values[index]}
                    placeholder={placeholder}
                    iconClass={classNames(iconClass, "text-xs text-gray-400 !top-0 !px-2")}
                    setValue={(v: number | undefined) => {
                      setValues((prev) => ({ ...prev, [index]: v }));
                    }}
                    handleOnBlur={() => {
                      const newFilters = [
                        ...filter.slice(0, index),
                        {
                          ...filter[index],
                          value:
                            values[index] === undefined
                              ? undefined
                              : noIcon
                              ? Math.max(Math.min(values[index] || 0, 100), 0)
                              : Math.max(values[index] || 0, 0),
                        },
                        ...filter.slice(index + 1),
                      ];
                      const v = newFilters.at(index);
                      if (v) onCheck?.(v, true);
                      updateFilter(newFilters);
                    }}
                    handleOnKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const newFilters = [
                          ...filter.slice(0, index),
                          {
                            ...filter[index],
                            value:
                              values[index] === undefined
                                ? undefined
                                : noIcon
                                ? Math.max(Math.min(values[index] || 0, 100), 0)
                                : Math.max(values[index] || 0, 0),
                          },
                          ...filter.slice(index + 1),
                        ];
                        const v = newFilters.at(index);
                        if (v) onCheck?.(v, true);
                        updateFilter(newFilters);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-3">
              {index > 0 && (
                <PlainButton
                  id={`input-${id}-delete-${index}`}
                  handleOnClick={() => {
                    const v = filter.at(index);
                    if (v) onCheck?.(v, false);
                    updateFilter([...filter.slice(0, index), ...filter.slice(index + 1)]);
                  }}
                  leftIconClass="gi gi-x text-gray-700"
                  className="!m-0 self-start !p-0 !text-xs"
                />
              )}
            </div>
          </div>
        );
      })}
      <PlainButton
        id={`input-${id}-add-more`}
        handleOnClick={() => {
          updateFilter([
            ...filter,
            {
              field_name: undefined,
              operator: ">",
              value: undefined,
              type: undefined,
            } as AdvancedFilter,
          ]);
        }}
        label="Add more"
        leftIconClass={
          <span>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3423_67628)">
                <path
                  d="M9 16.875C13.3492 16.875 16.875 13.3492 16.875 9C16.875 4.65076 13.3492 1.125 9 1.125C4.65076 1.125 1.125 4.65076 1.125 9C1.125 13.3492 4.65076 16.875 9 16.875Z"
                  stroke="#9B9B9B"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.625 9H12.375"
                  stroke="#9B9B9B"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 5.625V12.375"
                  stroke="#9B9B9B"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3423_67628">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        }
        className="!m-0 self-start !p-0 !text-xs"
      />
    </div>
  );
}
