import { ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Outlet, useBeforeUnload, useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import useOnboardingData from "../../hooks/useOnboarding";
//Profile image Todo: Need to refactor
import ProfilePlaceholder from "../../assets/images/profile-bg-placeholder.svg";
import camera_icon from "../../assets/images/camera.svg";
import group_icon from "../../assets/images/group-out.svg";
import camera_icon_white from "../../assets/images/camera-white.svg";
import {
  updateOnboardingData,
  updateOnboardingDataImmediately,
} from "../../services/onboarding.services";
import { Onboarding, OnboardingRequest } from "../../app/onboarding";
import { uploadFileService } from "../../services/file-upload/upload.service";
import { getFileUrl } from "../../services/file-upload/download.service";
import { useAppDispatch, useAppSelector } from "../../app/store";
import UpdateImage from "../../components/UpdateImage";
import useDiversity from "../../hooks/useDiversity";
import useDiversityRecommendedAction from "../../hooks/npo/useDiversityRecommendedAction";

import {
  getOrgBGImageKey,
  getOrgProfileImageKey,
  getUserBGImageKey,
  getUserProfileImageKey,
} from "../../utils/media";
import Feedback from "../../components/Feedback";
import PlainButton from "../../components/PlainButton";
import { checkFeedBackStatus } from "../../services/feedback.services";
import classNames from "classnames";
import useOrgMembers from "../../hooks/project/useOrgMembers";
import OrgInvitationPopup from "../../components/OrgInvitationPopup";
import { getInitials, getRandomColor, toUsdLong } from "../../utils/formatHelper";
import useNPOGrants from "../../hooks/useNPOGrants";
import useWritePermission from "../../hooks/useWritePermission";
import UserList from "../../components/UserList";
import { NotificationList } from "../Header";
import WhiteContainer from "../../components/dashboard/WhiteContainer";
import useTotalOrganizationMember from "../../hooks/useTotalOrganizationMember";
import { getTotalOrganizationMembersId } from "../../hooks/useTotalOrganizationMembersData";
import usePhotoUrl from "../../hooks/usePhotoUrl";
import {
  IOrgManualMemberUpdateRequest,
  IOrgProfile,
  IOrgProfileUpdateRequest,
  ITotalOrganizationMember,
} from "../../types/org";
import Button from "../../components/tailwind/Button";
import IMG_ADMIN_VERIFY from "../../assets/home/admin-verify.svg";
import IMG_SETTING from "../../assets/home/settings.svg";
import GibooWorks from "../../components/GibooWorks";
import GibooTotalSearch from "../../components/GibooTotalSearch";
import Spacer from "../../components/Spacer";
import useHowGibooWorks from "../../hooks/useHowGibooWorks";
import VerifyAdminModal from "../../components/VerifyAdminModal";
import useRequestOrgVerification from "../../hooks/useRequestOrgVerification";
import Project from "../my-home/components/Project";
import UpcomingTask from "../my-home/components/UpcomingTask";
import IMG_CREATE_PROJ from "../../assets/home/project-create.svg";
import IMG_VIEW_PROJ from "../../assets/home/project-all-view.svg";
import IMG_MANAGE_TASK from "../../assets/home/project-manage-task.svg";
import IMG_ORG_PROFILE from "../../assets/home/view-org-profile.svg";
import IMG_MY_PROFILE from "../../assets/home/view-my-profile.svg";
import IMG_SAVED_LIST from "../../assets/home/saved-list.svg";
import IMG_PC from "../../assets/document/pc.svg";
import IMG_GIFTS from "../../assets/document/gifts.svg";
import IMG_RAINBOW from "../../assets/document/rainbow.svg";
import IMG_DOC_AI from "../../assets/home/document-ai.svg";
import ProjectCreationModal from "../../components/ProjectCreationModal";
import IMG_FINANCIAL from "../../assets/images/impact.svg";
import IMG_FINANCIAL_INPROGRESS from "../../assets/financial/resume-financial.svg";
import IMG_DIVERSITY from "../../assets/images/diversity.svg";
import PrimaryButton from "../../components/PrimaryButton";
import { createProject } from "../../hooks/project/useProject";
import useOrgID from "../../hooks/useOrgID";
import useProjects from "../../hooks/project/useProjects";
import { IProject } from "../../types/project";
import { appendProjectAsync } from "../../app/projectSlice";
import GibooToast from "../../components/GibooToast";
import useOrgDataForNPODashboard from "../../hooks/useOrgDataForNPODashboard";
import useOrgProfile from "../../hooks/useOrgProfile";
import Slider from "../../components/tailwind/Slider";
import NpoFinancialRevenue from "./dashboard/components/NpoFinancialRevenue";
import NpoFinancialContribution from "./dashboard/components/NpoFinancialContribution";
import NpoFinancialRevenueStream from "./dashboard/components/NpoFinancialRevenueStream";
import NpoFinancialRevenueExpenses from "./dashboard/components/NpoFinancialRevenueExpenses";
import NpoFinancialAssetsCategories from "./dashboard/components/NpoFinancialAssetsCategories";
import NpoFinancialMonthLiquid from "./dashboard/components/NpoFinancialMonthLiquid";
import NpoDiversityEthnicity from "./dashboard/components/NpoDiversityEthnicity";
import NpoDiversityGender from "./dashboard/components/NpoDiversityGender";
import NpoDiversityGeneration from "./dashboard/components/NpoDiversityGeneration";
import NpoDiversityDisability from "./dashboard/components/NpoDiversityDisability";
import NpoDiversitySexualOrientation from "./dashboard/components/NpoDiversitySexualOrientation";
import NpoDiversityReligion from "./dashboard/components/NpoDiversityReligion";
import NpoDiversityProfession from "./dashboard/components/NpoDiversityProfession";
import NpoFinancialOperatingExpenses from "./dashboard/components/NpoFinancialOperatingExpenses";
import { DocumentTypeEnum } from "../../types/document";
import { Dictionary } from "@reduxjs/toolkit";
import useLocalStorage from "../../hooks/useLocalstorage";
import { delay } from "lodash";
import ProjectInformationModal from "../../components/ProjectInformationModal";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { sidekickHighlightTarget, sidekickMyHomeReady, sidekickOpen } from "../../app/recoilStore";
import usePageTitle from "../../hooks/usePagetitle";
import { MIXPANEL_EVENTS_V2, Navigate } from "../../mixpanel/mixpanel";
import useGibooMixpanel from "../../hooks/useGibooMixpanel";
import useGibooNavigate from "../../hooks/useGibooNavigate";
import { User } from "../../app/userSlice";

type NestedObject = Record<string, any>;
type SectionStatus = {
  ethnicity: boolean;
  disability: boolean;
  gender: boolean;
  age: boolean;
  profession: boolean;
  religion: boolean;
  sexual_orientation: boolean;
};

function Home() {
  usePageTitle("Home");
  const mxEvent = useGibooMixpanel();
  const navigateFromMyHome = useGibooNavigate("myhome"); // not in quick links
  const navigate = useGibooNavigate("quick_links");
  const [user, setUser] = useUser();
  const [showFeedback, setSetshowFeedback] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [isFindGrant, setIsFindGrant] = useState(false);
  const [isFindFunder, setIsFindFunder] = useState(false);
  const [onboardingData] = useOnboardingData();
  const org_id = useOrgID();
  const { data: orgUser } = useTotalOrganizationMember(
    org_id,
    getTotalOrganizationMembersId({ _id: user?._id || "", type: "user" }),
  );
  const prevOrgUserRef = useRef<ITotalOrganizationMember | undefined>();
  const { pageVisit, updatePageVisit, isCompleted } = useHowGibooWorks();
  const [projects] = useProjects();
  const dispatch = useAppDispatch();
  const { data: profileData } = useOrgProfile(org_id);
  const [showProjectCreationModal, setShowProjectCreationModal] = useState<boolean>(false);
  const [disableScroll, setDisableScroll] = useState<boolean>(false);
  const { hasWritePermission, npo_id } = useOrgDataForNPODashboard(undefined, true);
  const { value: isNewUser, deleteThis } = useLocalStorage("NEW_USER");
  const setSidekickMyHomeReady = useSetRecoilState(sidekickMyHomeReady);
  const highlightTarget = useRecoilValue(sidekickHighlightTarget);

  //checking is newuser after from onboarding.
  useEffect(() => {
    if (isNewUser) {
      setTimeout(() => {
        setShowSearch(true);
      }, 2000);
    }
    return () => {
      deleteThis();
    };
  }, [isNewUser]);
  useEffect(() => {
    setTimeout(() => {
      setSidekickMyHomeReady(true);
    }, 2500);
    return () => {
      setSidekickMyHomeReady(false);
    };
  }, []);
  //how works
  useEffect(() => {
    return () => {
      if (isFindGrant) {
        !pageVisit.searchVisited && updatePageVisit({ ...pageVisit, searchVisited: true });
      }
      if (isFindFunder) {
        !pageVisit.funderVisited && updatePageVisit({ ...pageVisit, funderVisited: true });
      }
    };
  }, []);

  /* start scroll UI */
  const [scrollY, setScrollY] = useState<number>(0);
  // const throttledScrollY = useThrottle<number>(scrollY, 200);
  const feedbackRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState(220);
  useEffect(() => {
    if (feedbackRef.current && showFeedback) {
      const top = 233 + (feedbackRef?.current?.clientHeight || 0);
      setTop(top);
    } else {
      setTop(220);
    }
  }, [feedbackRef.current, showFeedback]);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const stick = scrollY > 300;
  /* end scrol UI */

  // useEffect(() => {
  //   if (user?._id)
  //     checkFeedBackStatus(user?._id, "FUNDER_RECOMMENDATION")
  //       .then((res) => {
  //         setSetshowFeedback(res?.feedback);
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //       });
  // }, [user]);

  const renderFeedback = () => {
    return (
      <div ref={feedbackRef}>
        <Feedback
          type="FUNDER_RECOMMENDATION"
          ratingType="TEXT"
          onSubmit={() => {
            setTop(220);
          }}
        />
      </div>
    );
  };
  const renderNotification = () => {
    return (
      <WhiteContainer wrapperClass="!p-5 !max-h-[calc(100vh-88px)] min-h-[600px] h-full">
        <NotificationList titleClass="font-semibold" />
      </WhiteContainer>
    );
  };
  const renderSearch = () => {
    return (
      <div className="mx-auto flex  w-full max-w-[728px] flex-col gap-7">
        <div className="relative mt-[90px] flex h-[58px] w-full items-center justify-center">
          <div
            className="search-wrapper absolute top-0 z-[300] overscroll-contain"
            onClick={() => {
              setDisableScroll(true);
            }}
          >
            <GibooTotalSearch
              onInputExpand={() => {
                mxEvent(MIXPANEL_EVENTS_V2.myhome.search.clicked);
              }}
              from_for_mixpanel="myhome"
              id="giboo-search-my-home"
              isHeader={false}
              inputMaxWidth="700px"
              itemMaxHeight="700px"
              itemMaxWidth="729px"
              inputWidth="700px"
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              showOption={showOption}
              setShowOption={setShowOption}
              defaultSearchType={
                isFindGrant ? "virtual_grant" : isFindFunder ? "funder" : undefined
              }
              showBackdrop
              backDropClass={classNames("!-z-[100]")}
              showActionButton
              containerClass="!overflow-y-auto overscroll-contain"
              containerStyle={{
                maxHeight: "calc(100vh - 250px)",
              }}
              onClose={() => {
                setDisableScroll(false);
                !isCompleted("find_grant") && isFindGrant
                  ? updatePageVisit({ ...pageVisit, searchVisited: true })
                  : setIsFindFunder(false);
                !isCompleted("search") && isFindFunder
                  ? updatePageVisit({ ...pageVisit, funderVisited: true })
                  : setIsFindFunder(false);
              }}
              placeholder="Enter phrases or keywords on your focus areas to find relevant grants and funders"
              //show guidance
              showGuidance={isFindGrant || isFindFunder}
            />
          </div>
        </div>
        {renderQuickAction()}
      </div>
    );
  };
  const handleCreateProject = () => {
    setShowProjectCreationModal(true);
  };
  //mixpanel
  const sendQuickLinkAction = (to: Navigate) => {
    mxEvent(MIXPANEL_EVENTS_V2.myhome.quick_links.clicked, {
      to,
    });
  };
  const renderQuickAction = () => {
    const renderItem = (title: string, icon: string, action: () => void, wrapperClass?: string) => (
      <div
        className={classNames("grid cursor-pointer place-items-center gap-2", wrapperClass)}
        onClick={() => action()}
      >
        <img src={icon} className="h-fit w-fit" />
        <p className="whitespace-nowrap font-poppins text-xs">{title}</p>
      </div>
    );
    return (
      <div className="inline-flex w-full max-w-full justify-between gap-4 overflow-x-scroll scrollbar-none">
        {renderItem("Create a project", IMG_CREATE_PROJ, () => {
          sendQuickLinkAction(Navigate.PROJECT_CREATION);
          handleCreateProject();
        })}
        {renderItem("View all projects", IMG_VIEW_PROJ, () => {
          sendQuickLinkAction(Navigate.PROJECTS);
          navigate("/projects");
        })}
        {renderItem("View saved list", IMG_SAVED_LIST, () => {
          sendQuickLinkAction(Navigate.SAVED_LIST);
          navigate("/saved");
        })}
        {renderItem("Manage tasks", IMG_MANAGE_TASK, () => {
          sendQuickLinkAction(Navigate.TASKS);
          navigate("/my-tasks");
        })}
        {renderItem("View org profile", IMG_ORG_PROFILE, () => {
          sendQuickLinkAction(Navigate.ORG_PROFILE);
          navigate(`/organization`);
        })}
        {renderItem("View my profile", IMG_MY_PROFILE, () => {
          sendQuickLinkAction(Navigate.USER_PROFILE);
          navigate("/profile");
        })}
        {renderItem(
          "Document AI",
          IMG_DOC_AI,
          () => {
            sendQuickLinkAction(Navigate.DOCUMENTAI);
            if (process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE" && projects.isFetched)
              navigate(
                projects.data.length > 0
                  ? `/project/${projects.data[0]._id}/document`
                  : "/document",
              );
          },
          process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE" && projects.isFetched
            ? ""
            : "opacity-50",
        )}
      </div>
    );
  };
  const renderGreeting = () => {
    return (
      <div className="grid grid-cols-3 gap-4">
        {[
          {
            title: "Connect professionally",
            content:
              "Craft a compelling LinkedIn introductory message that leaves a lasting impression.",
            img: IMG_PC,
            type: DocumentTypeEnum.OUTREACHING_INTRODUCTORY_LINKEDIN_MSG,
          },
          {
            title: "Express gratitude",
            content: "Strengthen relationships by responding graciously to positive feedback.",
            img: IMG_GIFTS,
            type: DocumentTypeEnum.OUTREACHING_FOLLOWUP_LETTER_TO_POSITIVE,
          },
          {
            title: "Secure support",
            content:
              "Craft a compelling end-of-year giving appeal to attract new support for our mission.",
            img: IMG_RAINBOW,
            type: DocumentTypeEnum.OUTREACHING_EOY_GIVING_APPEAL_TO_A_NEW_FUNDER,
          },
        ].map((n, i) => (
          <div
            key={i}
            className="flex cursor-pointer flex-col gap-5 rounded border border-gray-300 p-4 hover:border-purple-500"
            onClick={() => {
              navigateFromMyHome(
                projects.data.length > 0
                  ? `/project/${projects.data[0]._id}/document?type=${n.type}`
                  : `/document?type=${n.type}`,
              );
            }}
          >
            <img src={n.img} className="h-fit w-fit" />
            <h5 className="font-semibold 2xl:text-base">{n.title}</h5>
            <h6>{n.content}</h6>
          </div>
        ))}
      </div>
    );
  };
  const renderDocumentAi = () => {
    const renderItem = (title: string, icon: string, action: () => void) => (
      <div className="grid cursor-pointer place-items-center gap-2" onClick={() => action()}>
        <img src={icon} className="h-fit w-fit" />
        <p className="whitespace-nowrap font-poppins text-xs">{title}</p>
      </div>
    );
    return (
      <div
        id="document-ai"
        className={classNames(
          "flex flex-col rounded border border-gray-300 bg-white p-5",
          process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE" ? "" : "opacity-50",
          highlightTarget === "document-ai" ? "border-2 border-purple-500" : "",
        )}
      >
        <h4 className="font-poppins text-base font-semibold text-gray-900">Document AI</h4>
        <Spacer height="24px" />
        <div className="mx-auto grid w-[429px] place-items-center">
          {process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE" ? (
            <PrimaryButton
              size="md"
              id={`btn-document-ai2`}
              label={"Create a document"}
              className="!h-[29px] !text-sm"
              leftIconClass="gi-add gi-md"
              disabled={!projects.isFetched}
              handleOnClick={() =>
                navigateFromMyHome(
                  projects.data.length > 0
                    ? `/project/${projects.data[0]._id}/document`
                    : "/document",
                )
              }
            />
          ) : (
            <div className="bi grid h-[29px] place-items-center rounded bg-gray-300 px-2 py-1">
              <h5 className="text-sm text-gray-800">Coming soon</h5>
            </div>
          )}
          <Spacer height="24px" />
          <h4 className="font-poppins text-base font-semibold text-gray-900">
            Create a compelling document
          </h4>
          <Spacer height="16px" />
          <p className="text-xs  text-gray-700">
            Discover the wide range of documents you can effortlessly craft using our Document AI.
            With just a few simple touches, you can effortlessly create compelling and professional
            documents tailored to your needs.
          </p>
        </div>
        <Spacer height="28px" />
        <div className="mx-auto flex w-full max-w-[686px] flex-col  gap-4  md:flex-row">
          {renderGreeting()}
        </div>
      </div>
    );
  };
  return (
    <>
      {showProjectCreationModal && (
        <ProjectInformationModal
          from_for_mixpanel="myhome"
          open={showProjectCreationModal}
          setOpen={setShowProjectCreationModal}
          id=""
          withOutFunderGrant
          isNewProject
        />
      )}
      <div className={classNames("flex flex-col", disableScroll && "fixed inset-0 mt-[58px]")}>
        {renderSearch()}
        <GibooWorks
          findGrand={() => {
            setShowSearch((prev) => !prev);
            setDisableScroll(true);
            setIsFindGrant(true);
            setIsFindFunder(false);
          }}
          findFunder={() => {
            setShowSearch((prev) => !prev);
            setDisableScroll(true);
            setIsFindFunder(true);
            setIsFindGrant(false);
          }}
        />
        <div className="mx-auto mt-[60px] flex max-w-[1151px]">
          <main className="flex w-full max-w-[800px] flex-col gap-5 overflow-y-auto">
            <Project
              handleCreateProject={() => {
                handleCreateProject();
              }}
            />
            <UpcomingTask />
            <FinancialsAssessment
              hasWritePermission={hasWritePermission}
              savedIndex={profileData?.financial_saved_index}
              npo_id={npo_id}
              org_id={org_id}
              isInprogress={
                profileData?.financial_saved_index && profileData?.financial_saved_index > 1
                  ? true
                  : false
              }
              isCompleted={profileData?.financial_completed ? true : false}
            />
            <DiversityAssessment
              hasWritePermission={hasWritePermission}
              savedIndex={profileData?.diversity_saved_index}
              npo_id={npo_id}
              org_id={org_id}
              isInprogress={
                profileData?.diversity_saved_index &&
                profileData?.diversity_saved_index > 1 &&
                profileData?.diversity_saved_index < 8
                  ? true
                  : false
              }
              isCompleted={profileData?.diversity_completed ? true : false}
            />
            {renderDocumentAi()}
          </main>
        </div>
        <Spacer height="50px" />
      </div>
    </>
  );
}
export default Home;

function OrgProfile({ wrapperClass }: { wrapperClass?: string }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const { hasWritePermission } = useWritePermission(onboardingData.npo_id);
  const { data: members } = useOrgMembers(onboardingData._id);
  const appState = useAppSelector((state) => state);
  const [showProfilImageEdit, setShowProfileImageEdit] = useState<boolean>(false);
  const [showProfilBackgroundEdit, setShowProfileBackgroundEdit] = useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<File | undefined>();
  const [selectedBG, setSelectedBG] = useState<File | undefined>();
  const [bgImage, setBgImage] = useState<string | undefined>();
  const [profileImage, setProfileImage] = useState<string | undefined>();
  const [backgroundObjectKey, setBackgroundObjectKey] = useState<string | undefined>();
  const [profileObjectKey, setProfileObjectKey] = useState<string | undefined>();
  const { statistic } = useNPOGrants(onboardingData.npo_id);
  const [showInvite, setShowInvite] = useState<boolean>(false);

  //link copy
  const [isCopied, setIsCopied] = useState(false);
  const [projectLink, setProjectLink] = useState("");
  useEffect(() => {
    setProjectLink(`${window.location.origin}${window.location.pathname}` || "");
  }, []);

  useEffect(() => {
    if (backgroundObjectKey == "") setBgImage(undefined);
    if (profileObjectKey == "") setProfileImage(undefined);
    if (backgroundObjectKey) {
      getFileUrl(backgroundObjectKey)
        .then((url) => {
          setTimeout(() => {
            setBgImage(url as string);
          }, 1000);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
    if (profileObjectKey) {
      getFileUrl(profileObjectKey)
        .then((url) => {
          setTimeout(() => {
            setProfileImage(url as string);
          }, 1000);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [backgroundObjectKey, profileObjectKey]);

  useEffect(() => {
    onboardingData && setBackgroundObjectKey(onboardingData.background_object_key);
    onboardingData && setProfileObjectKey(onboardingData.photo_object_key);
  }, [onboardingData]);

  const updateData = (data: OnboardingRequest) => {
    updateOnboardingDataImmediately({
      data,
      id: onboardingData._id,
      dispatch,
    })?.finally(() => {
      setShowProfileImageEdit(false);
      setShowProfileBackgroundEdit(false);
    });
  };
  const renderEditProfile = () => {
    return (
      <div
        onClick={() => {
          setShowProfileImageEdit(true);
        }}
        className="absolute bottom-0 hidden h-[34px] w-[90px] place-items-center bg-gray-300 bg-opacity-50  group-hover:grid "
      >
        <img className="-mt-[2px] ml-[4px] h-4 w-4" src={camera_icon} />
      </div>
    );
  };
  return (
    <>
      {onboardingData && showInvite && (
        <OrgInvitationPopup
          from_for_mixpanel="quick_links" //deprecated
          show={showInvite}
          onClose={() => setShowInvite(false)}
        />
      )}
      <UpdateImage
        from_for_mixpanel="user_profile"
        image_type_for_mixpanel="user_profile"
        id="input-project-image"
        title={"Add a profile image"}
        type="PROFILE"
        open={showProfilImageEdit}
        setOpen={setShowProfileImageEdit}
        selectedImage={selectedProfile}
        setSelectedImage={setSelectedProfile}
        previousImageURL={profileImage}
        onUpdateImage={async () => {
          if (onboardingData) {
            if (selectedProfile)
              uploadFileService(selectedProfile, getOrgProfileImageKey(onboardingData._id), [
                "image",
              ]).then((res) => {
                const key = res as string;
                const data = {
                  photo_object_key: key,
                } as Onboarding;
                setProfileObjectKey(key);
                updateData(data);
              });
            else {
              updateData({ photo_object_key: "" });
              setProfileObjectKey("");
            }
          }
        }}
      />

      <UpdateImage
        from_for_mixpanel="user_profile"
        image_type_for_mixpanel="user_cover"
        id="input-user-cover-image"
        title={"Add a cover image"}
        type="COVER"
        open={showProfilBackgroundEdit}
        setOpen={setShowProfileBackgroundEdit}
        selectedImage={selectedBG}
        setSelectedImage={setSelectedBG}
        previousImageURL={bgImage}
        onUpdateImage={async () => {
          if (onboardingData) {
            if (selectedBG)
              uploadFileService(selectedBG, getOrgBGImageKey(onboardingData._id), ["image"]).then(
                (res) => {
                  const key = res as string;
                  const data = {
                    background_object_key: key,
                  } as Onboarding;
                  updateData(data);
                  setBackgroundObjectKey(key);
                },
              );
            else {
              updateData({ background_object_key: "" });
              setBackgroundObjectKey("");
            }
          }
        }}
      />
      <div
        className={classNames(
          "flex min-h-[398px] w-full flex-col overflow-hidden rounded border border-gray-300 bg-white",
          wrapperClass,
        )}
      >
        <div className="relative h-[398px] w-full overflow-hidden  bg-gray-200">
          {hasWritePermission && (
            <span
              className="absolute right-3 top-3  grid h-9 w-9 cursor-pointer place-items-center rounded-full !bg-white p-2 shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)] "
              onClick={() => {
                setShowProfileBackgroundEdit(true);
              }}
            >
              <img className="h-full w-full" src={camera_icon} />
            </span>
          )}
          {bgImage ? (
            <img className="h-[183px] w-full object-cover" src={bgImage} />
          ) : (
            <div className="flex h-full w-full justify-center ">
              <img
                className="h-[203px] w-full max-w-[334px] bg-cover px-4"
                src={ProfilePlaceholder}
              />
            </div>
          )}
          <div className="z-3 absolute top-[38%] h-full w-full  bg-white">
            <div className="flex -translate-y-1/2 justify-center">
              {profileImage ? (
                <div className="group relative z-0 cursor-pointer overflow-hidden rounded-full">
                  <img
                    className="prevent-select my-0 flex h-[112px] w-[112px] items-center justify-center rounded-full  border-[4px] border-gray-100 bg-purple-500 bg-cover text-white shadow-sm"
                    src={profileImage}
                  />
                  {hasWritePermission && renderEditProfile()}
                </div>
              ) : (
                <div className="prevent-select group relative my-0 flex h-24 w-24 cursor-pointer items-center justify-center overflow-hidden  rounded-full border-[4px] border-gray-100 bg-purple-500 text-white shadow-sm">
                  <h4 className="my-1 text-center text-3xl">{`${
                    onboardingData?.npo_name?.[0]?.toUpperCase() || ""
                  }`}</h4>
                  {hasWritePermission && (
                    <div
                      onClick={() => {
                        setShowProfileImageEdit(true);
                      }}
                      className="absolute -bottom-[1px] grid h-[30px] w-[90px] place-items-center bg-gray-600  opacity-70 "
                    >
                      <img className="-mt-[2px] ml-[1px] h-4 w-4" src={camera_icon_white} />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mt-[52px] flex -translate-y-1/2 flex-col items-center justify-center gap-3 bg-white  px-1 pb-5 font-poppins">
              <h4 className="w-full text-center text-2xl font-semibold text-gray-900">{`${
                onboardingData?.npo_name || "No name"
              }`}</h4>
              <div className="h-[1px] w-full px-8">
                <div className="h-[1px] w-full bg-gray-300" />
              </div>
              <div
                className="inline-flex w-[321px] cursor-pointer items-center justify-center gap-3 rounded bg-purple-500 px-4 py-2 font-poppins hover:shadow"
                onClick={() => {
                  navigate(`/organization`);
                }}
              >
                <img src={group_icon} className="text-white"></img>
                <h5 className="text-sm text-white">{"Go to my organization profile"}</h5>
              </div>
              <div className="my-[1px] flex items-center justify-center gap-2">
                <div className="inline-flex">
                  <UserList
                    users={members.filter((i) => i._id).map((i) => i._id as string)}
                    maxUsers={onboardingData.role >= 5 ? 1 : 10}
                  />
                </div>
                {onboardingData.role >= 4 && (
                  <PlainButton
                    outline
                    id="btn-invite"
                    handleOnClick={() => setShowInvite(true)}
                    className="!h-6 !border-gray-300 !text-sm"
                    leftIconClass="gi gi-funder"
                    label="Invite a new member"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function MyProfileCard() {
  const [onboardingData] = useOnboardingData();
  const [showProfilImageEdit, setShowProfileImageEdit] = useState<boolean>(false);
  const [showProfilBackgroundEdit, setShowProfileBackgroundEdit] = useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<File | undefined>();
  const [selectedBG, setSelectedBG] = useState<File | undefined>();
  const [user] = useUser();
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [showAdminVerify, setShowAdminVerify] = useState(false);
  const { data: orgUser, update } = useTotalOrganizationMember(
    onboardingData._id,
    getTotalOrganizationMembersId({ _id: user?._id || "", type: "user" }),
  );
  const { data: orgMember, revalidate } = useTotalOrganizationMember(
    onboardingData._id,
    getTotalOrganizationMembersId({ _id: user?._id || "", type: "user" }),
  );
  const { data: photoUrl, revalidate: revalidateProfile } = usePhotoUrl(
    orgMember?.photo_object_key,
  );
  const { data: backgroundUrl, revalidate: revalidateBG } = usePhotoUrl(
    orgMember?.background_object_key,
  );
  const { data: verifyData } = useRequestOrgVerification(onboardingData._id, true);

  const updateData = async (data: IOrgManualMemberUpdateRequest) => {
    setIsUpdating(true);
    return update(data)
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setShowProfileImageEdit(false);
        setShowProfileBackgroundEdit(false);
      });
  };

  const renderEditProfile = () => {
    return (
      <div
        onClick={() => {
          setShowProfileImageEdit(true);
        }}
        className="absolute bottom-0 hidden h-[34px] w-[90px] place-items-center bg-gray-300 bg-opacity-40  group-hover:grid "
      >
        <img className="-ml-[4px] h-4 w-4" src={camera_icon_white} />
      </div>
    );
  };
  return (
    <>
      {user && user._id && (
        <UpdateImage
          from_for_mixpanel="user_profile"
          image_type_for_mixpanel="user_profile"
          id="input-project-image"
          title={"Add a profile image"}
          type="PROFILE"
          open={showProfilImageEdit}
          setOpen={setShowProfileImageEdit}
          selectedImage={selectedProfile}
          setSelectedImage={setSelectedProfile}
          previousImageURL={photoUrl}
          onUpdateImage={async () => {
            if (user && user._id) {
              if (selectedProfile)
                uploadFileService(selectedProfile, getUserProfileImageKey(user._id), [
                  "image",
                ]).then((res) => {
                  const key = res as string;
                  const data = {
                    photo_object_key: key,
                  } as Onboarding;
                  updateData(data).then(() => revalidateProfile());
                });
              else {
                updateData({ photo_object_key: "" });
              }
            }
          }}
        />
      )}
      {user && user._id && (
        <UpdateImage
          from_for_mixpanel="user_profile"
          image_type_for_mixpanel="user_cover"
          id="input-user-cover-image"
          title={"Add a cover image"}
          type="COVER"
          open={showProfilBackgroundEdit}
          setOpen={setShowProfileBackgroundEdit}
          selectedImage={selectedBG}
          setSelectedImage={setSelectedBG}
          previousImageURL={backgroundUrl}
          onUpdateImage={async () => {
            if (user && user._id) {
              if (selectedBG)
                uploadFileService(selectedBG, getUserBGImageKey(user._id), ["image"]).then(
                  (res) => {
                    const key = res as string;
                    const data = {
                      background_object_key: key,
                    } as Onboarding;
                    updateData(data).then(() => revalidateBG());
                  },
                );
              else {
                updateData({ background_object_key: "" });
              }
            }
          }}
        />
      )}
      {showAdminVerify && (
        <VerifyAdminModal
          from_for_mixpanel="user_profile"
          open={showAdminVerify}
          setOpen={setShowAdminVerify}
          onCreation={() => {
            setShowAdminVerify(false);
          }}
        />
      )}
      <div className="relative min-h-[145px] w-full overflow-hidden rounded border border-gray-300 bg-white  p-5">
        <div className="flex h-full gap-7">
          {/* image */}
          <div className="w-fit">
            <div className="flex h-[81px] w-[81px] justify-center">
              {photoUrl ? (
                <div className="group relative z-0 cursor-pointer overflow-hidden rounded-full">
                  <img
                    className="prevent-select my-0 flex h-[81px] w-[81px] items-center justify-center rounded-full  border-[4px] border-gray-100 bg-purple-500 bg-cover text-white shadow-sm"
                    src={photoUrl}
                  />
                  {renderEditProfile()}
                </div>
              ) : user && user.idp_google_detail && user.idp_google_detail.picture ? (
                <div className="group relative cursor-pointer overflow-hidden rounded-full">
                  <img
                    className="prevent-select my-0 flex h-[81px] w-[81px] items-center justify-center  rounded-full border-[4px] border-gray-100 bg-purple-500 text-white shadow-sm"
                    src={user.idp_google_detail.picture}
                  />
                  {renderEditProfile()}
                </div>
              ) : (
                <div
                  className="prevent-select group relative my-0 flex h-[81px] w-[81px] cursor-pointer items-center justify-center overflow-hidden  rounded-full border-[4px] border-gray-100 text-white shadow-sm"
                  style={{
                    backgroundColor: getRandomColor(user?.firstname, user?.lastname),
                  }}
                >
                  <h4 className="my-1 text-center text-[24px]">{`${getInitials(
                    user?.firstname,
                    user?.lastname,
                  )}`}</h4>
                  <div
                    onClick={() => {
                      setShowProfileImageEdit(true);
                    }}
                    className="absolute -bottom-[1px] grid h-[26px] w-[90px] place-items-center bg-gray-600  bg-opacity-70 "
                  >
                    <img className="-mt-[2px] ml-[1px] h-[15px] w-[15px]" src={camera_icon_white} />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* detail */}
          <div className="flex w-full flex-col gap-2">
            <div className=" inline-flex items-center gap-2 font-poppins">
              <h5 className="text-xl font-medium ">{`${user?.firstname} ${user?.lastname}`}</h5>
              {/* <span className="h-5 w-5 cursor-pointer" onClick={() => navigate("/profile")}>
                <img src={IMG_SETTING} className="h-5 w-5" />
              </span> */}
            </div>
            <p className="text-sm font-medium text-gray-900">{`${user?.email}`}</p>
            <h5 className="text-sm text-gray-700">{orgUser?.title || ""}</h5>
            <div>
              {verifyData?.requested ? (
                <div className="inline-flex gap-2 rounded border border-purple-500 px-2  py-1 font-poppins text-sm text-purple-500">
                  <span>
                    <img src={IMG_ADMIN_VERIFY} className="h-5 w-5" />
                  </span>
                  Verification in progress
                </div>
              ) : !onboardingData.verified ? (
                <Button
                  id="verify-admin"
                  className="!h-[29px] !text-sm"
                  prependIcon={<img src={IMG_ADMIN_VERIFY} className="h-5 w-5" />}
                  color="purple"
                  outline
                  label="Verify admin"
                  handleOnClick={() => {
                    setShowAdminVerify(true);
                  }}
                />
              ) : (
                <h5 className="inline-flex gap-2 font-poppins text-sm  text-purple-500">
                  Admin{" "}
                  <span>
                    <img src={IMG_ADMIN_VERIFY} className="h-5 w-5" />
                  </span>
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const FinancialsAssessment = ({
  hasWritePermission,
  org_id,
  npo_id,
  savedIndex = 0,
  hideComplete = false,
  isCompleted = false,
  isInprogress,
}: {
  hasWritePermission?: boolean;
  savedIndex?: number;
  hideComplete?: boolean;
  org_id?: string;
  npo_id?: string;
  isCompleted?: boolean;
  isInprogress?: boolean;
}) => {
  const navigate = useGibooNavigate("myhome", true);
  const highlightTarget = useRecoilValue(sidekickHighlightTarget);
  const renderHeader = () => {
    return (
      <div className="inline-flex items-center justify-between">
        <h4 className="font-poppins text-base font-semibold text-gray-900">Financial</h4>
        <RestartAssessment
          hasWritePermission={hasWritePermission}
          type="financial"
          org_id={org_id}
        />
      </div>
    );
  };
  const renderHeaderInComplete = () => {
    return (
      <div className="inline-flex items-center justify-between">
        <h4 className="font-poppins text-base font-semibold text-gray-900">Financial</h4>
        <PrimaryButton
          size="md"
          id={`btn-financial-info`}
          disabled={!hasWritePermission}
          label={"Resume to add financial information"}
          className="!h-[29px] !text-sm"
          leftIconClass={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
            >
              <path
                d="M2.271 15.0968V3.90642C2.271 3.71335 2.33672 3.55154 2.46815 3.42101C2.59959 3.29048 2.76094 3.22567 2.95221 3.22657C3.14529 3.22657 3.30709 3.29138 3.43762 3.42101C3.56725 3.55154 3.63206 3.71335 3.63206 3.90642V15.0968C3.63206 15.2899 3.5668 15.4517 3.43626 15.5822C3.30573 15.7128 3.14393 15.7776 2.95085 15.7767C2.75777 15.7767 2.59642 15.7118 2.46679 15.5822C2.33717 15.4526 2.271 15.2908 2.271 15.0968ZM8.52972 14.7881L15.7878 10.448C15.9782 10.3365 16.116 10.1973 16.2012 10.0305C16.2864 9.86375 16.329 9.68744 16.329 9.50161C16.329 9.31579 16.2864 9.13948 16.2012 8.97269C16.116 8.8059 15.9782 8.66675 15.7878 8.55526L8.52972 4.21508C8.43364 4.16432 8.34072 4.1276 8.25098 4.10494C8.16034 4.08228 8.06969 4.07095 7.97904 4.07095C7.68444 4.07095 7.42655 4.17066 7.20537 4.37008C6.98419 4.56951 6.8736 4.83556 6.8736 5.16823V13.835C6.8736 14.1677 6.98419 14.4337 7.20537 14.6331C7.42655 14.8326 7.68398 14.9323 7.97768 14.9323C8.07014 14.9323 8.16079 14.9209 8.24962 14.8983C8.34027 14.8756 8.43364 14.8394 8.52972 14.7895M8.2333 13.3115V5.69172L14.6008 9.50161L8.2333 13.3115Z"
                fill="white"
              />
            </svg>
          }
          handleOnClick={() => navigate("/financial/detail")}
        />
      </div>
    );
  };
  if (isInprogress && !isCompleted) {
    return (
      <div
        id="financials"
        className={classNames(
          "flex flex-col  border-gray-300 bg-white p-5",
          "rounded border",
          highlightTarget === "financials" ? "border-2 border-purple-500" : "",
        )}
      >
        {renderHeaderInComplete()}
        <FinancialCompleted npo_id={npo_id} org_id={org_id} savedIndex={savedIndex} />
      </div>
    );
  }
  if (isCompleted && !hideComplete) {
    return (
      <div
        id="financials"
        className={classNames(
          "flex flex-col  border-gray-300 bg-white p-5",
          "rounded border",
          highlightTarget === "financials" ? "border-2 border-purple-500" : "",
        )}
      >
        {renderHeader()}
        <FinancialCompleted npo_id={npo_id} org_id={org_id} />
      </div>
    );
  }
  return (
    <div
      id="financials"
      className={classNames(
        "flex flex-col  border-gray-300 bg-white p-5",
        "rounded border",
        highlightTarget === "financials" ? "border-2 border-purple-500" : "",
      )}
    >
      <h4 className="font-poppins text-base font-semibold text-gray-900">Financial</h4>
      <Spacer height="38px" />
      <div className="grid min-h-[239px] w-full place-items-center">
        <PrimaryButton
          size="md"
          id={`btn-financial-info`}
          disabled={!hasWritePermission}
          label={"Start financial assessment"}
          className="!h-[29px] !text-sm"
          leftIconClass="gi-add gi-md"
          handleOnClick={() => {
            navigate("/financial");
          }}
        />

        <Spacer height="24px" />
        <div className="flex max-w-[517px] flex-col justify-center gap-4 self-center">
          <h4 className="text-center font-poppins text-base font-semibold text-black">
            Present organization&apos;s financial information
          </h4>
          <h6 className="text-xs font-normal text-gray-700">
            Giboo facilitates the evaluation of your organization&apos;s financial sustainability,
            offering a comprehensive view of your financial landscape for long-term operational
            viability.
          </h6>
          <h6 className="text-center text-xs font-normal text-gray-700">
            Additionally, we provide data-driven strategies for fundraising planning, ensuring
            confidence in meeting financial needs and enhancing transparency when communicating with
            potential funders. Ready for a ride?
          </h6>
        </div>
        <Spacer height="28px" />
        <img src={IMG_FINANCIAL} className="h-[124px] w-[132px]" />
      </div>
    </div>
  );
};

export const DiversityAssessment = ({
  hasWritePermission,
  savedIndex = 0,
  hideComplete = false,
  isCompleted = false,
  isInprogress,
  org_id,
  npo_id,
}: {
  hasWritePermission?: boolean;
  savedIndex?: number;
  hideComplete?: boolean;
  isCompleted?: boolean;
  isInprogress?: boolean;
  org_id?: string;
  npo_id?: string;
}) => {
  const navigate = useGibooNavigate("myhome", true);
  const highlightTarget = useRecoilValue(sidekickHighlightTarget);
  const renderHeader = () => {
    return (
      <div className="inline-flex items-center justify-between">
        <h4 className="font-poppins text-base font-semibold text-gray-900">Diversity</h4>
        <RestartAssessment
          hasWritePermission={hasWritePermission}
          type="diversity"
          org_id={org_id}
        />
      </div>
    );
  };

  const renderHeaderInComplete = () => {
    return (
      <div className="inline-flex items-center justify-between">
        <h4 className="font-poppins text-base font-semibold text-gray-900">Diversity</h4>
        <PrimaryButton
          size="md"
          id={`btn-diversity-info`}
          disabled={!hasWritePermission}
          label={"Resume to add diversity information"}
          className="!h-[29px] !text-sm"
          leftIconClass={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
            >
              <path
                d="M2.271 15.0968V3.90642C2.271 3.71335 2.33672 3.55154 2.46815 3.42101C2.59959 3.29048 2.76094 3.22567 2.95221 3.22657C3.14529 3.22657 3.30709 3.29138 3.43762 3.42101C3.56725 3.55154 3.63206 3.71335 3.63206 3.90642V15.0968C3.63206 15.2899 3.5668 15.4517 3.43626 15.5822C3.30573 15.7128 3.14393 15.7776 2.95085 15.7767C2.75777 15.7767 2.59642 15.7118 2.46679 15.5822C2.33717 15.4526 2.271 15.2908 2.271 15.0968ZM8.52972 14.7881L15.7878 10.448C15.9782 10.3365 16.116 10.1973 16.2012 10.0305C16.2864 9.86375 16.329 9.68744 16.329 9.50161C16.329 9.31579 16.2864 9.13948 16.2012 8.97269C16.116 8.8059 15.9782 8.66675 15.7878 8.55526L8.52972 4.21508C8.43364 4.16432 8.34072 4.1276 8.25098 4.10494C8.16034 4.08228 8.06969 4.07095 7.97904 4.07095C7.68444 4.07095 7.42655 4.17066 7.20537 4.37008C6.98419 4.56951 6.8736 4.83556 6.8736 5.16823V13.835C6.8736 14.1677 6.98419 14.4337 7.20537 14.6331C7.42655 14.8326 7.68398 14.9323 7.97768 14.9323C8.07014 14.9323 8.16079 14.9209 8.24962 14.8983C8.34027 14.8756 8.43364 14.8394 8.52972 14.7895M8.2333 13.3115V5.69172L14.6008 9.50161L8.2333 13.3115Z"
                fill="white"
              />
            </svg>
          }
          handleOnClick={() => navigate("/diversity/detail")}
        />
      </div>
    );
  };
  if (isCompleted && !hideComplete) {
    return (
      <div
        id="diversity"
        className={classNames(
          "flex flex-col  border-gray-300 bg-white p-5",
          "rounded border",
          highlightTarget === "diversity" ? "border-2 border-purple-500" : "",
        )}
      >
        {renderHeader()}
        <DiversityCompleted
          org_id={org_id}
          npo_id={npo_id}
          // sectionStatus={completedDiversitySections}
        />
      </div>
    );
  }
  if (isInprogress && !isCompleted) {
    return (
      <div
        id="diversity"
        className={classNames(
          "flex flex-col border-gray-300 bg-white p-5",
          "rounded border",
          highlightTarget === "diversity" ? "border-2 border-purple-500" : "",
        )}
      >
        {renderHeaderInComplete()}
        <DiversityCompleted
          org_id={org_id}
          npo_id={npo_id}
          savedIndex={savedIndex}
          // sectionStatus={completedDiversitySections}
        />
      </div>
    );
  }
  return (
    <div
      id="diversity"
      className={classNames(
        "flex flex-col rounded border border-gray-300 bg-white p-5",
        highlightTarget === "diversity" ? "border-2 border-purple-500" : "",
      )}
    >
      <h4 className="font-poppins text-base font-semibold text-gray-900">Diversity</h4>
      <Spacer height="38px" />
      <div className="grid min-h-[239px] w-full place-items-center">
        <PrimaryButton
          size="md"
          id={`btn-diverstiy-info`}
          label={"Add diversity information"}
          disabled={!hasWritePermission}
          className="!h-[29px] !text-sm"
          leftIconClass="gi-add gi-md"
          handleOnClick={() => {
            navigate("/diversity");
          }}
        />
        <Spacer height="24px" />
        <h4 className="font-poppins text-base font-semibold text-black">
          Showcase organization&apos;s diversity
        </h4>
        <Spacer height="16px" />
        <div className="flex max-w-[527px] flex-col items-center justify-center gap-4 self-center">
          <h6 className="max-w-[379px] text-center text-xs font-normal text-gray-700">
            Giboo simplifies diversity information collection for nonprofits, addressing the
            increasing demand from funders.
          </h6>
          <h6 className="text-center text-xs font-normal text-gray-700">
            By efficiently capturing and customizing key demographic factors for board members,
            employees, and volunteers, Giboo enables organizations to highlight diversity aligned
            with their mission, fostering stronger connections with funders.
          </h6>
        </div>
        <Spacer height="28px" />
        <img src={IMG_DIVERSITY} className="h-[124px] w-[132px]" />
      </div>
    </div>
  );
};
export function FinancialCompleted({
  npo_id,
  org_id,
  savedIndex,
}: {
  org_id?: string;
  npo_id?: string;
  savedIndex?: number;
}) {
  const navigate = useGibooNavigate("myhome", true);
  const { data: profileData } = useOrgProfile(org_id);
  type NavItem =
    | "business_revenue_model"
    | "total_contribution"
    | "revenue_stream"
    | "revenue_expense_stream"
    | "operating_expense"
    | "assets_categories"
    | "moth_of_liquid";
  const [activeLink, setActiveLink] = useState<NavItem>("business_revenue_model");
  const componentList: Record<NavItem, ReactNode> = {
    business_revenue_model: (
      <NpoFinancialRevenue
        allowEdit={false}
        npo_id={npo_id}
        org_id={org_id}
        showInfo
        showStats
        hideTitle
      />
    ),
    total_contribution: (
      <NpoFinancialContribution
        allowEdit={false}
        npo_id={npo_id}
        org_id={org_id}
        showInfo
        showStats
        hideTitle
      />
    ),
    revenue_stream: (
      <NpoFinancialRevenueStream
        allowEdit={false}
        npo_id={npo_id}
        org_id={org_id}
        showInfo
        showStats
        hideTitle
      />
    ),
    operating_expense: (
      <NpoFinancialOperatingExpenses
        allowEdit={false}
        npo_id={npo_id}
        org_id={org_id}
        showInfo
        showStats
        hideTitle
      />
    ),
    revenue_expense_stream: (
      <NpoFinancialRevenueExpenses
        allowEdit={false}
        npo_id={npo_id}
        org_id={org_id}
        showInfo
        showStats
        hideTitle
      />
    ),
    assets_categories: (
      <NpoFinancialAssetsCategories
        allowEdit={false}
        npo_id={npo_id}
        org_id={org_id}
        showInfo
        showStats
        hideTitle
      />
    ),
    moth_of_liquid: (
      <NpoFinancialMonthLiquid
        allowEdit={false}
        npo_id={npo_id}
        org_id={org_id}
        showInfo
        showStats
        hideTitle
      />
    ),
  };
  const renderContent = () => {
    const renderItem = (label: string, value: NavItem, index: number) => {
      if (savedIndex && !profileData.financial_completed && index < savedIndex) {
        return (
          <div
            id={`nav-${value}`}
            key={`nav-${value}`}
            className={classNames(
              "h-full cursor-pointer gap-3 whitespace-nowrap font-poppins text-sm",
              activeLink === value
                ? "border-b border-black pb-[10px] font-semibold"
                : " !font-normal !text-gray-700",
            )}
            onClick={() => {
              setActiveLink(value);
            }}
          >
            {label}
          </div>
        );
      }
      if (savedIndex === undefined) {
        return (
          <div
            id={`nav-${value}`}
            key={`nav-${value}`}
            className={classNames(
              "h-full cursor-pointer gap-3 whitespace-nowrap font-poppins text-sm",
              activeLink === value
                ? "border-b border-black pb-[10px] font-semibold"
                : " !font-normal !text-gray-700",
            )}
            onClick={() => {
              setActiveLink(value);
            }}
          >
            {label}
          </div>
        );
      }
    };
    return (
      <div className="flex flex-col">
        <div className="inline-flex h-full w-full max-w-[800px] items-end gap-4 overflow-x-scroll scrollbar-none">
          <Slider
            renderItems={() => (
              <div className="relative flex h-[24px] max-w-full gap-5">
                {[
                  renderItem("Business (Revenue) model", "business_revenue_model", 1),
                  renderItem("Total contributions", "total_contribution", 2),
                  renderItem("Revenue stream", "revenue_stream", 4),
                  renderItem("Revenue & expense stream", "revenue_expense_stream", 5),
                  renderItem("Operating expenses", "operating_expense", 6),
                  renderItem("Financial assets categories", "assets_categories", 8),
                  renderItem("Working capital: Month of liquid", "moth_of_liquid", 9),
                ]
                  .filter(Boolean)
                  .map((i) => i)}
              </div>
            )}
            showFeatureIntro={false}
            wrapperClass="w-full mt-4 border-b border-gray-300"
            leftArrowClass="!left-0 !top-[4px]"
            rightArrowClass="!right-0 !top-[4px]"
            className="!w-full"
            iconColor="purple"
            targetId={`nav-${activeLink}`}
          />
        </div>
        {componentList[activeLink]}
      </div>
    );
  };
  return (
    <div>
      {renderContent()}
      <div className="mt-4 grid w-full place-items-center rounded-b bg-gray-100 px-10 py-3">
        <div
          className="inline-flex w-fit cursor-pointer items-center gap-2"
          onClick={() => {
            navigate("/organization", { state: { activeTab: "financials" } });
          }}
        >
          <p className="font-poppins text-sm font-semibold text-purple-500">
            View organization&apos;s financial assessment result
          </p>
          <div className="grid h-5 w-5 place-items-center">
            <i className="gi gi-right-arrow text-purple-500" />
          </div>
        </div>
      </div>
    </div>
  );
}
export function DiversityCompleted({
  npo_id,
  org_id,
  savedIndex,
}: // sectionStatus,
{
  org_id?: string;
  npo_id?: string;
  savedIndex?: number;
  // sectionStatus: SectionStatus;
}) {
  const navigate = useGibooNavigate("myhome", true);
  const { data: profileData } = useOrgProfile(org_id);
  type NavItem =
    | "ethnicity"
    | "gender"
    | "age"
    | "sexual_orientation"
    | "disability"
    | "religion"
    | "profession";

  const { data: profile } = useOrgProfile(org_id);

  const [activeLink, setActiveLink] = useState<NavItem>(
    !profile.hide_ethnicity
      ? "ethnicity"
      : !profile.hide_gender
      ? "gender"
      : !profile.hide_age
      ? "age"
      : !profile.hide_sexual
      ? "sexual_orientation"
      : !profile.hide_disability
      ? "disability"
      : !profile.hide_religion
      ? "religion"
      : "profession",
  ); // Default to "ethnicity" if all are true

  // const [activeLink, setActiveLink] = useState<NavItem>("ethnicity");

  const componentList: Record<NavItem, ReactNode> = {
    ethnicity: (
      <NpoDiversityEthnicity
        allowEdit={false}
        org_id={org_id}
        npo_id={npo_id}
        showInfo={true}
        showStats={false}
        hideTitle
      />
    ),
    gender: (
      <NpoDiversityGender
        allowEdit={false}
        org_id={org_id}
        npo_id={npo_id}
        showInfo={true}
        showStats={false}
        hideTitle
      />
    ),
    age: (
      <NpoDiversityGeneration
        allowEdit={false}
        org_id={org_id}
        npo_id={npo_id}
        showInfo={true}
        showStats={false}
        hideTitle
      />
    ),
    disability: (
      <NpoDiversityDisability
        allowEdit={false}
        org_id={org_id}
        npo_id={npo_id}
        showInfo={true}
        showStats={false}
        hideTitle
      />
    ),
    sexual_orientation: (
      <NpoDiversitySexualOrientation
        allowEdit={false}
        org_id={org_id}
        npo_id={npo_id}
        showInfo={true}
        showStats={false}
        hideTitle
      />
    ),
    religion: (
      <NpoDiversityReligion
        allowEdit={false}
        org_id={org_id}
        npo_id={npo_id}
        showInfo={true}
        showStats={false}
        hideTitle
      />
    ),
    profession: (
      <NpoDiversityProfession
        allowEdit={false}
        org_id={org_id}
        npo_id={npo_id}
        showInfo={true}
        showStats={false}
        hideTitle
      />
    ),
  };
  const renderContent = () => {
    const renderItem = (label: string, value: NavItem, index: number) => {
      if (savedIndex && !profileData.diversity_completed && index <= savedIndex) {
        return (
          <div
            id={`nav-${value}`}
            key={`nav-${value}`}
            className={classNames(
              "h-full cursor-pointer gap-3 whitespace-nowrap font-poppins text-sm",
              activeLink === value
                ? "border-b border-black pb-[10px] font-semibold"
                : " !font-normal !text-gray-700",
            )}
            onClick={() => {
              setActiveLink(value);
            }}
          >
            {label}
          </div>
        );
      }
      if (savedIndex === undefined) {
        return (
          <div
            id={`nav-${value}`}
            key={`nav-${value}`}
            className={classNames(
              "h-full cursor-pointer gap-3 whitespace-nowrap font-poppins text-sm",
              activeLink === value
                ? "border-b border-black pb-[10px] font-semibold"
                : " !font-normal !text-gray-700",
            )}
            onClick={() => {
              setActiveLink(value);
            }}
          >
            {label}
          </div>
        );
      }
    };
    return (
      <div className="flex max-w-[800px] flex-col">
        <div className="inline-flex h-full w-full items-end gap-4 overflow-x-scroll scrollbar-none">
          <Slider
            renderItems={() => (
              <div className="flex w-full gap-5 border-b border-gray-300">
                {[
                  profile?.hide_ethnicity === false && renderItem("Ethnicity", "ethnicity", 2),
                  profile?.hide_gender === false && renderItem("Gender", "gender", 3),
                  profile?.hide_age === false && renderItem("Age", "age", 4),
                  profile?.hide_sexual === false &&
                    renderItem("Sexual orientation perspective", "sexual_orientation", 5),
                  profile?.hide_disability === false && renderItem("Disability", "disability", 6),
                  profile?.hide_religion === false && renderItem("Religion", "religion", 7),
                  profile?.hide_profession === false &&
                    renderItem("Professional background", "profession", 8),
                ]
                  .filter(Boolean)
                  .map((i) => i)}
              </div>
            )}
            showFeatureIntro={false}
            wrapperClass="w-full mt-4"
            leftArrowClass="!left-0 !top-[4px]"
            rightArrowClass="!right-0 !top-[4px]"
            iconColor="purple"
            className="!w-full"
            targetId={`nav-${activeLink}`}
          />
        </div>
        {componentList[activeLink]}
      </div>
    );
  };
  return (
    <div>
      {renderContent()}
      <div className="mt-4 grid w-full place-items-center rounded-b bg-gray-100 px-10 py-3">
        <div
          className="inline-flex w-fit cursor-pointer items-center gap-2"
          onClick={() => {
            navigate("/organization", { state: { activeTab: "diversity" } });
          }}
        >
          <p className="font-poppins text-sm font-semibold text-purple-500">
            View organization&apos;s diversity information
          </p>
          <div className="grid h-5 w-5 place-items-center">
            <i className="gi gi-right-arrow text-purple-500" />
          </div>
        </div>
      </div>
    </div>
  );
}

export const RestartAssessment = ({
  hasWritePermission,
  type,
  org_id,
}: {
  hasWritePermission?: boolean;
  type: "financial" | "diversity";
  org_id?: string;
}) => {
  const navigate = useGibooNavigate("myhome", true);

  const { data: profileData, revalidate, update } = useOrgProfile(org_id);
  if (!hasWritePermission) return <></>;
  return (
    <div
      className="group inline-flex h-[29px] cursor-pointer items-center gap-2"
      onClick={() => {
        if (!hasWritePermission) return;
        const loadStatus = [
          "age",
          "disability",
          "ethnicity",
          "gender",
          "board",
          "religion",
          "sexual_orientation",
        ];
        const data: IOrgProfileUpdateRequest =
          type === "financial"
            ? {
                financial_completed: false,
                financial_saved_index: 0,
                skip_loading: profileData.skip_loading?.filter((i) => loadStatus.includes(i)),
              }
            : {
                diversity_completed: false,
                diversity_saved_index: 0,
                diversity_consent: false,
                skip_loading: profileData.skip_loading?.filter((i) => !loadStatus.includes(i)),
                hide_ethnicity: undefined,
                hide_gender: undefined,
                hide_age: undefined,
                hide_sexual: undefined,
                hide_disability: undefined,
                hide_religion: undefined,
                hide_profession: undefined,
              };
        update(data)
          .then(() => {
            revalidate().finally(() => {
              type === "financial" ? navigate("/financial") : navigate("/diversity");
            });
          })
          .catch((er) => {
            GibooToast({
              type: "failure",
              message: `We apologize for the inconvenience. Please try again.`,
            });
          });
      }}
    >
      <span className="h-5 w-5">
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0954 1.77955C9.52211 1.35235 7.85581 1.41602 6.31966 1.96203C4.78351 2.50803 3.45083 3.51032 2.50007 4.83468V3.24961C2.50007 3.05069 2.42104 2.85991 2.28039 2.71926C2.13973 2.5786 1.94895 2.49958 1.75003 2.49958C1.55111 2.49958 1.36034 2.5786 1.21968 2.71926C1.07902 2.85991 1 3.05069 1 3.24961V7.4998H5.25019C5.44911 7.4998 5.63988 7.42077 5.78054 7.28012C5.9212 7.13946 6.00022 6.94868 6.00022 6.74976C6.00022 6.55084 5.9212 6.36007 5.78054 6.21941C5.63988 6.07875 5.44911 5.99973 5.25019 5.99973H3.52311C4.30648 4.77416 5.47826 3.84675 6.85099 3.36585C8.22373 2.88494 9.71809 2.87835 11.095 3.34711C12.472 3.81587 13.6519 4.73291 14.446 5.95151C15.2402 7.17011 15.6027 8.61985 15.4755 10.0688C15.3484 11.5178 14.7389 12.8822 13.7447 13.9439C12.7504 15.0056 11.4288 15.7031 9.99127 15.9249C8.55374 16.1466 7.08337 15.8799 5.81537 15.1672C4.54737 14.4546 3.55503 13.3373 2.99709 11.994C2.91787 11.8147 2.77163 11.6736 2.58967 11.6008C2.40771 11.528 2.20448 11.5293 2.02347 11.6045C1.84247 11.6796 1.69807 11.8226 1.62117 12.0029C1.54427 12.1832 1.54099 12.3864 1.61203 12.569C2.13324 13.8236 2.96535 14.9248 4.02993 15.7688C5.0945 16.6128 6.35647 17.1718 7.69687 17.3931C9.03728 17.6144 10.412 17.4908 11.6913 17.0338C12.9707 16.5768 14.1127 15.8015 15.0095 14.7811C15.9064 13.7606 16.5286 12.5286 16.8175 11.2011C17.1064 9.87364 17.0525 8.49446 16.6609 7.19357C16.2694 5.89268 15.5529 4.71294 14.5792 3.76556C13.6055 2.81817 12.4066 2.13535 11.0954 1.77955Z"
            fill="#727272"
          />
        </svg>
      </span>
      <p className="font-poppins text-sm text-gray-600">Restart assessment</p>
    </div>
  );
};
