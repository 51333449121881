import React, { ReactNode, useState } from "react";
import edit from "../../assets/images/edit.svg";
import classNames from "classnames";
import TooltipButton from "../TooltipButton";
import { useRecoilValue } from "recoil";
import { sidekickHighlightTarget } from "../../app/recoilStore";
interface ExpandableCardInterface {
  id: string;
  title: string | React.ReactNode;
  tooltip?: string;
  openDefault: boolean;
  children?: React.ReactNode;
  allowEdit: boolean;
  allowExpand?: boolean;
  customClass?: string | undefined;
  editClass?: string;
  onEdit?: () => void;
  showAllDetail?: boolean;
  showAllLabel?: string;
  onShowAllClick?: () => void;
  rightAction?: React.ReactNode;
  childClass?: string;
  noBorder?: boolean;
  noPadding?: boolean;
  titleClass?: string;
  editContainer?: ReactNode;
}
function ExpandableCard({
  id,
  openDefault,
  allowEdit,
  children,
  title,
  tooltip,
  customClass,
  allowExpand = true,
  editClass,
  onEdit,
  showAllDetail = false,
  showAllLabel = "Show all details",
  onShowAllClick,
  rightAction,
  childClass,
  noBorder = false,
  noPadding = false,
  titleClass,
  editContainer,
}: ExpandableCardInterface) {
  const [open, setOpen] = useState<boolean>(openDefault);
  const highlightTarget = useRecoilValue(sidekickHighlightTarget);
  return (
    <div
      id={id}
      className={classNames(
        "rounded border border-gray-300 bg-white",
        highlightTarget === id ? "border-2 border-purple-500" : "",
        customClass,
        noBorder && "border-none",
      )}
    >
      <div
        className={classNames(
          "flex items-center justify-between px-5 pb-4 pt-5 ",
          noPadding && "!p-0",
        )}
      >
        <div className="flex grow items-center gap-2">
          {typeof title == "string" ? (
            <h4
              className={classNames("font-poppins text-xl font-semibold text-gray-900", titleClass)}
            >
              {title}
            </h4>
          ) : (
            title
          )}
          {tooltip && <TooltipButton id={`btn-info-${id}`} content={tooltip} place="right" />}
        </div>
        <div className="inline-flex gap-3">
          <div className={classNames("flex select-none items-center ")}>
            {editContainer ? (
              editContainer
            ) : (
              <>
                {allowEdit && (
                  <div
                    id={`btn-edit-${id}`}
                    className={classNames(
                      "grid h-5 w-5 cursor-pointer place-items-center rounded-full",
                      editClass,
                    )}
                    onClick={() => onEdit && onEdit()}
                  >
                    <img src={edit} className="h-4 w-4 " />
                  </div>
                )}
                {allowExpand && (
                  <>
                    {open ? (
                      <div
                        className="ml-6 w-20 cursor-pointer  text-base leading-3  underline"
                        onClick={() => setOpen(false)}
                      >
                        Show less
                      </div>
                    ) : (
                      <div
                        className=" ml-6 w-24 cursor-pointer text-base leading-3   underline"
                        onClick={() => setOpen(true)}
                      >
                        Show more
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {rightAction && rightAction}
        </div>
      </div>
      <div className={classNames("px-5 pb-5", childClass, { hidden: !open }, noPadding && "!px-0")}>
        {open && children}
      </div>
      <div
        className={classNames("grid place-items-center border-t border-gray-300 py-[14px]", {
          hidden: !showAllDetail,
        })}
      >
        <h5
          className="inline-flex cursor-pointer items-center gap-4 text-sm font-semibold text-gray-700"
          onClick={() => onShowAllClick?.()}
        >
          {showAllLabel}
          <span className="rotate-180">
            <i className="gi-left-arrow " />
          </span>
        </h5>
      </div>
    </div>
  );
}
ExpandableCard.defaultProps = {
  openDefault: true,
  allowEdit: false,
};

export default ExpandableCard;
