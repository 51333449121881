import {
  AnyAction,
  ThunkAction,
  createSlice,
  Dispatch,
  PayloadAction,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { debounce } from "lodash";
import config from "../api";
import { RootState } from "./store";
import {
  ISearchHistory,
  SearchQuery,
  SearchQueryNullable,
  defaultSearchQuery,
} from "../types/search";
import { getValidNumber } from "../utils/formatHelper";

interface MoreSearchOptionState {
  isFetched: boolean;
  query: SearchQuery;
  search_type: number;
}

const initialState: MoreSearchOptionState = {
  isFetched: false,
  query: defaultSearchQuery,
  search_type: 0,
};
const moreSearchOptionSlice = createSlice({
  name: "moreSearchOption",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<{ query: SearchQueryNullable; search_type: number }>) => {
      state.query = { ...state.query, ...action.payload.query };
      state.search_type = action.payload.search_type;
    },
    fetch: (state, action: PayloadAction<ISearchHistory>) => {
      state.isFetched = true;
      state.search_type = action.payload.search_type;
      state.query = action.payload.obj;
    },
  },
});

const fetchMoreSearchOptionAsync = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const search_type = sessionStorage.getItem(`search_type`) || "0";
    const searchType = getValidNumber(search_type) || 0;
    dispatch(
      moreSearchOptionSlice.actions.fetch({
        search_type: searchType,
        obj: defaultSearchQuery,
        updated_at: "",
        text_query: "",
      }),
    );
    // const state = getState();
    // if (!state.user?.user?._id || !state.onboarding?.data?._id) return;
    // axios
    //   .get(
    //     process.env.REACT_APP_API_URL +
    //       `/api/v2/search/mso?user_id=${state.user.user._id}&org_id=${state.onboarding.data._id}`,
    //     config,
    //   )
    //   .then((response) => response.data)
    //   .then((data: ISearchHistory) => {
    //     if (data) dispatch(moreSearchOptionSlice.actions.fetch(data));
    //   });
  };
};

const updateMoreSearchOption = (
  user_id: string | undefined,
  org_id: string | undefined,
  query: SearchQuery,
  search_type: number,
) => {
  if (org_id === undefined || user_id === undefined) return;
  axios
    .put(
      process.env.REACT_APP_API_URL +
        `/api/v2/search/mso?user_id=${user_id}&org_id=${org_id}&search_type=${search_type}`,
      query,
      config,
    )
    .then((response) => {
      // console.log("updateOnboarding", data, response);
    })
    .catch((e) => {
      // console.log(e);
    });
};

// debounced function should update all data at the moment requesting to the server
// because it kills the previous call
const debouncedUpdateMoreSearchOption = debounce(
  (getState: () => RootState) => {
    const state = getState();
    const search_type = state.moreSearchOption.search_type;
    sessionStorage.setItem("search_type", `${search_type}`);
    // const org_id = state.onboarding?.data?._id;
    // const user_id = state.user?.user?._id;
    // if (!org_id || !user_id) return;
    // const search_type = state.moreSearchOption.search_type;
    // const query = state.moreSearchOption.query;
    // axios
    //   .put(
    //     process.env.REACT_APP_API_URL +
    //       `/api/v2/search/mso?user_id=${user_id}&org_id=${org_id}&search_type=${search_type}`,
    //     query,
    //     config,
    //   )
    //   .then((response) => {
    //     // console.log("debouncedUpdateOnboardingAll", response);
    //   })
    //   .catch((e) => {
    //     // console.log(e);
    //   });
  },
  1500,
  { maxWait: 3000 },
);
const updateMoreSearchOptionAsync = (
  query: SearchQueryNullable,
  search_type: number,
  option: { updateImmediately?: boolean } = {},
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    dispatch(moreSearchOptionSlice.actions.update({ query, search_type })); // update locally first and debounce request
    if (option.updateImmediately) {
      // updateMoreSearchOption(
      //   state.user.user?._id,
      //   state.onboarding?.data?._id,
      //   { ...state.query, ...query },
      //   search_type,
      // );
    } else {
      debouncedUpdateMoreSearchOption(getState);
    }
  };
};

const selectSelf = (state: RootState) => state;
const isMoreSearchOptionFetched = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.moreSearchOption.isFetched,
);
const selectMoreSearchOptionState = createDraftSafeSelector(
  selectSelf,
  (state: RootState): MoreSearchOptionState => state.moreSearchOption,
);
const selectMoreSearchOptionSearchType = createDraftSafeSelector(
  (state: RootState) => state,
  (state: RootState): number => state.search_type,
);
const selectMoreSearchOption = createDraftSafeSelector(
  (state: RootState) => state,
  (state: RootState): SearchQuery | undefined => state.query,
);

export {
  fetchMoreSearchOptionAsync,
  updateMoreSearchOptionAsync,
  isMoreSearchOptionFetched,
  selectMoreSearchOptionSearchType,
  selectMoreSearchOption,
  selectMoreSearchOptionState,
};
export default moreSearchOptionSlice;
