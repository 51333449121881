import { useEffect, useState } from 'react';

type IUseElement = (elementId: string) => HTMLElement | null;

const useElement: IUseElement = (elementId) => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  useEffect(() => {
    const targetElement = document.getElementById(elementId);
    setElement(targetElement);
  }, [elementId]);

  return element;
};

export default useElement;