export const zIndexTooltip = "!z-[9900]";
export const zIndexCelebration = "!z-[9850]";
export const zIndexVerification = "!z-[9800]";
export const zIndexRephrasableInputPopup = 9800;
export const zIndexUpdateImage = "!z-[9600]";
export const zIndexOutReach = "!z-[9500]";
export const zIndexUploadIRS = "!z-[9400]";
export const zIndexStatusUpdate = "!z-[9300]";
export const zIndexProjectPickerModal = "!z-[9250]";
export const zIndexStartfundraising = "!z-[9200]";
export const zIndexSubtaskCreateModal = "!z-[9150]";
export const zIndexRolePicker = 9146;
export const zIndexProjectInvitation = 9145;
export const zIndexOrgInvitation = 9140;
export const zIndexSave = 9130;
export const zIndexEditPerson = 9125;
export const zIndexAddPerson = 9124;
export const zIndexEditPeople = 9120;
export const zIndexDialog = "!z-[8889]";
export const zIndexBackdrop = "!z-[8888]";
export const zIndexBackdropNumber = 8888;
export const zIndexRsuitepopper = "!z-[99999]";
export const zIndexSidekick = "!z-[8887]";
