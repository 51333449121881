import React from "react";
import IMG_Loader from "../../../../assets/images/financial-chart-loader.svg";
import classNames from "classnames";
export default function FinancialChartLoader({
  title,
  wrapperClass,
  hideTitle = false,
}: {
  title?: string;
  wrapperClass?: string;
  hideTitle?: boolean;
}) {
  return (
    <div
      className={classNames(
        "flex min-h-[545px] w-full flex-col items-center justify-center gap-10 ",
        wrapperClass,
      )}
    >
      <img src={IMG_Loader} className="h-fit w-fit" alt="financial chart loader image" />
      {!hideTitle && title && (
        <p className="font-poppins text-sm font-semibold italic text-gray-900">{title}</p>
      )}
    </div>
  );
}
