import React from "react";
import { toEinString } from "../utils/formatHelper";

interface NPOType {
  name: string;
  ein: string;
  address: string;
}

function NPOSelectItem(props: NPOType) {
  return (
    <>
      <div className="live-search-npo-name truncate">{props.name}</div>
      <div className="live-search-npo-address truncate">{toEinString(props.ein)}</div>
    </>
  );
}

export default NPOSelectItem;
