import classNames from "classnames";
import IMAGE_TRAIL_END from "../assets/images/trial-end.svg";
import Spacer from "./Spacer";
import Button from "./tailwind/Button";
import PopupModal from "./tailwind/PopupModal";
import useEINFromSearchParams from "../hooks/useEINFromSearchParams";
import useOnboardingData from "../hooks/useOnboarding";
import { useRecoilValue } from "recoil";
import { sidekickSearchCount } from "../app/recoilStore";
import useOrgSearchQuery from "../hooks/useOrgSearchQuery";
import { useEffect, useState } from "react";
import useGibooNavigate from "../hooks/useGibooNavigate";
import { useLocation } from "react-router-dom";
import axios from "axios";
import config from "../api";
function WelcomeModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const browserLocation = useLocation();
  const navigate = useGibooNavigate("welcome_modal");
  const { firstname } = useEINFromSearchParams();
  const [onboardingData] = useOnboardingData();
  const searchCount = useRecoilValue(sidekickSearchCount);
  const { data: searchQuerySuggestion } = useOrgSearchQuery();
  const { ein } = useEINFromSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <>
      <PopupModal
        isOpen={open}
        allowBackdropClose={false}
        handleClose={() => {
          onClose();
        }}
        wrapperClass={classNames("!min-w-[586px] max-w-[586px] md:!px-0 ", "!max-w-[760px]")}
        contentClass={classNames("!p-0")}
        showHeader={false}
        showActionButtons={false}
        blurBackdrop={false}
      >
        <div className="p-0">
          <div className="w-full">
            <img src={IMAGE_TRAIL_END} className="h-fit w-fit" />
          </div>
          <div className="flex flex-col items-center justify-center px-7 py-10">
            <div className="flex w-full flex-col items-center justify-center gap-4">
              <h5 className="text-center text-xl font-semibold text-black">
                {`Welcome to Giboo${firstname ? `, ${firstname}` : ""}`}
              </h5>
              <h5 className="text-center text-sm text-gray-700">
                We&apos;ve found some great opportunities
                {onboardingData.npo_name ? ` for ${onboardingData.npo_name}` : ""}.
                <br />
                Currently, we&apos;re in our beta launch phase, and you&apos;re enjoying a free
                trial that will end soon.
              </h5>
            </div>
            <Spacer height="40px" />
            <Button
              id="btn-ok"
              label={
                searchCount && Math.floor(searchCount / 100) > 0
                  ? `Explore ${(Math.floor(searchCount / 100) * 100).toLocaleString()}+ results`
                  : Math.floor(searchCount / 10) > 0
                  ? `Explore ${(Math.floor(searchCount / 10) * 10).toLocaleString()}+ results`
                  : `Explore results`
              }
              loading={loading}
              handleOnClick={() => {
                if (!browserLocation.pathname.includes("/search")) {
                  if (searchQuerySuggestion && searchQuerySuggestion.length > 0) {
                    onClose();
                    navigate(
                      `/search?text_query=${encodeURIComponent(
                        searchQuerySuggestion[0],
                      )}&type=virtual_grant`,
                    );
                  } else if (ein) {
                    setLoading(true);
                    axios
                      .get(
                        process.env.REACT_APP_API_URL + `/api/v2/npos/searchquery?ein=${ein}`,
                        config,
                      )
                      .then((res) => {
                        if (res?.data && res?.data.length > 0)
                          navigate(
                            `/search?text_query=${encodeURIComponent(
                              res.data[0],
                            )}&type=virtual_grant`,
                          );
                      })
                      .finally(() => {
                        onClose();
                        setLoading(false);
                      });
                  } else {
                    onClose();
                  }
                } else {
                  onClose();
                }
              }}
            />
          </div>
        </div>
      </PopupModal>
    </>
  );
}
export default WelcomeModal;
