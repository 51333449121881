import {
  AnyAction,
  createDraftSafeSelector,
  createSlice,
  Dispatch,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
import { TutorType } from "../components/AppTour";
import { getUserDisctionaryValue } from "../hooks/useUserDictionary";

export interface Tutor {
  type: TutorType;
  start: boolean;
  completed: boolean;
  isPaused: boolean;
  pausedIndex: number;
}

interface TutorState {
  tutor: Tutor[];
}
const initialState = { tutor: [] } as TutorState;

const tutorSlice = createSlice({
  name: "tutor",
  initialState,
  reducers: {
    startTutor: (
      state,
      action: PayloadAction<{
        type: TutorType;
      }>,
    ) => {
      const existingTutorIndex = state.tutor.findIndex((t) => t.type === action.payload.type);
      if (existingTutorIndex !== -1) {
        state.tutor[existingTutorIndex] = {
          ...state.tutor[existingTutorIndex],
          completed: false,
          start: true,
          isPaused: false,
          pausedIndex: 0,
          type: action.payload.type,
        };
      } else {
        state.tutor.push({
          completed: false,
          start: true,
          type: action.payload.type,
          isPaused: false,
          pausedIndex: 0,
        });
      }
    },
    resumeTutor: (
      state,
      action: PayloadAction<{
        step: number;
        type: TutorType;
      }>,
    ) => {
      const existingTutorIndex = state.tutor.findIndex((t) => t.type === action.payload.type);
      if (existingTutorIndex !== -1 && state.tutor[existingTutorIndex].isPaused) {
        state.tutor[existingTutorIndex] = {
          ...state.tutor[existingTutorIndex],
          completed: false,
          start: true,
          isPaused: false,
          pausedIndex: action.payload.step,
          type: action.payload.type,
        };
      } else {
        state.tutor.push({
          completed: false,
          start: true,
          isPaused: false,
          pausedIndex: action.payload.step,
          type: action.payload.type,
        });
      }
    },
    stopTutor: (
      state,
      action: PayloadAction<{
        type: TutorType;
        isPaused?: boolean;
        pausedIndex?: number;
      }>,
    ) => {
      // console.log("stop tutor");
      const existingTutorIndex = state.tutor.findIndex((t) => t.type === action.payload.type);
      if (existingTutorIndex !== -1) {
        state.tutor[existingTutorIndex] = {
          ...state.tutor[existingTutorIndex],
          completed: true,
          start: false,
          isPaused: action.payload.isPaused || false,
          pausedIndex: action.payload.pausedIndex || 0,
          type: action.payload.type,
        };
      } else {
        state.tutor.push({
          completed: true,
          start: false,
          type: action.payload.type,
          isPaused: action.payload.isPaused || false,
          pausedIndex: action.payload.pausedIndex || 0,
        });
      }
    },
  },
});

// const fetchTutorState = (
//   tutorType: TutorType,
// ): ThunkAction<void, RootState, unknown, AnyAction> => {
//   return async (dispatch: Dispatch, getState: () => RootState) => {
//     getUserDisctionaryValue(tutorType)
//       .then((data: Tutor) => {
//         dispatch(
//           tutorSlice.actions.fetch({
//             tutorType,
//             data,
//           }),
//         );
//       })
//       .catch((e) => {
//         // console.log(e);
//         dispatch(
//           tutorSlice.actions.fetch({
//             tutorType,
//             data: {
//               type: tutorType,
//               start: false,
//               completed: false,
//               isPaused: false,
//               pausedIndex: 0,
//             },
//           }),
//         );
//       });
//   };
// };
const selectSelf = (state: RootState) => state;
const selectTutorType = (state: RootState, tutorType: TutorType) => tutorType;
export const selectTutor = createDraftSafeSelector(
  [selectSelf, selectTutorType],
  (state: RootState, tutorType: TutorType): Tutor | undefined =>
    state.tutor.tutor.find((tutor: Tutor) => tutor.type === tutorType),
);

export default tutorSlice;
