import classNames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import Spinner from "../Spinner";
import { Dialog } from "@headlessui/react";
import { zIndexBackdrop, zIndexDialog } from "../../types/zIndex";
interface ModalI {
  isOpen: boolean;
  title?: string | ReactNode | undefined;
  handleClose?: () => void;
  handleSave?: () => void;
  headerClass?: string;
  footerClass?: string;
  contentClass?: string;
  wrapperClass?: string;
  children?: React.ReactNode;
  showPrimaryButton?: boolean;
  primaryButtonLabel?: string;
  disableSave?: boolean;
  LeftActionButton?: () => ReactNode;
  RightActionButton?: () => ReactNode;
  isLoading?: boolean;
  showActionButtons?: boolean;
  fullScreen?: boolean;
  fixed?: boolean;
  noTitleDivider?: boolean;
  zIndex?: number;
  backDropZindex?: number;
  showHeader?: boolean;
  allowBackdropClose?: boolean;
  noBackdrop?: boolean;
  blurBackdrop?: boolean;
  dialogPanelClass?: string;
}
function PopupModal({
  showPrimaryButton,
  primaryButtonLabel,
  disableSave,
  LeftActionButton,
  RightActionButton,
  fullScreen,
  isLoading,
  contentClass,
  wrapperClass,
  showActionButtons = true,
  fixed = false,
  noTitleDivider = false,
  zIndex = 1200,
  backDropZindex = 1199,
  showHeader = true,
  allowBackdropClose = false,
  noBackdrop,
  blurBackdrop = false,
  dialogPanelClass,
  ...props
}: ModalI) {
  // useEffect(() => {
  //   document.body.style.overflow = "hidden";
  //   return () => {
  //     document.body.style.overflow = "unset";
  //   };
  // }, [props]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props]);

  const renderHeader = () => {
    return (
      <div
        className={classNames(
          noTitleDivider
            ? "inline-flex w-full"
            : "sticky top-0 z-50 inline-flex h-fit w-full items-center justify-between border-b-[1px] border-gray-300 bg-white px-6 py-4",
          props.headerClass,
        )}
      >
        {props.title ? (
          typeof props.title === "string" ? (
            <p
              className="w-full font-poppins text-lg font-semibold text-gray-900 md:text-xl"
              id="modal-title"
            >
              {props.title}
            </p>
          ) : (
            <>{props.title}</>
          )
        ) : (
          <div />
        )}
        {props.handleClose && (
          <div
            className="grid h-full w-fit cursor-pointer place-items-center pl-1 text-lg"
            onClick={() => props.handleClose?.()}
          >
            <span>&#10005;</span>
          </div>
        )}
      </div>
    );
  };
  const renderFooter = () => {
    return (
      <div
        className={classNames(
          "flex w-full items-center justify-between border-t-[1px] border-gray-300 px-6 py-4",
          props.footerClass,
        )}
      >
        {LeftActionButton ? LeftActionButton() : <div />}
        {RightActionButton
          ? RightActionButton()
          : showPrimaryButton && (
              <button
                disabled={disableSave}
                onClick={() => {
                  if (props.handleSave) props.handleSave();
                }}
                type="button"
                className={classNames(
                  "flex !h-9 w-40 items-center justify-center rounded-[4px] bg-purple-500 px-2  py-1 text-sm font-normal text-white shadow-sm hover:bg-purple-400 md:h-14 md:px-3 md:py-2",
                  { "disabled:bg-purple-400": disableSave },
                )}
              >
                {isLoading && <Spinner size="sm" color="white" />}
                <span>{primaryButtonLabel}</span>
              </button>
            )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div
        className={classNames(
          contentClass ? contentClass : "my-2 overflow-scroll",
          noTitleDivider ? "-mt-2" : "",
        )}
      >
        {props.children}
      </div>
    );
  };
  return (
    <>
      {isOpen && !noBackdrop && (
        <div
          className={classNames(
            "fixed inset-0 bg-black/80 ",
            backDropZindex > 8888 ? zIndexBackdrop : "",
            blurBackdrop && "!bg-black/50 backdrop-blur backdrop-filter ",
          )}
          style={{ ...(backDropZindex <= 8888 ? { zIndex: backDropZindex } : {}) }}
          aria-hidden="true"
        />
      )}
      <Dialog
        open={isOpen}
        onClose={allowBackdropClose ? () => props.handleClose?.() : () => null}
        className={classNames(
          "relative inset-0 flex items-center justify-center",
          fixed && "overflow-y-auto",
          zIndex > 8888 ? zIndexDialog : "",
        )}
        style={{ ...(zIndex <= 8888 ? { zIndex: zIndex } : {}) }}
      >
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center">
            <Dialog.Panel
              className={classNames(
                "my-8 h-fit w-fit transform overflow-hidden rounded bg-white text-left shadow-xl transition-all",
                dialogPanelClass,
              )}
              style={{ zIndex: zIndex }}
            >
              <div
                className={classNames(
                  "flex min-h-full w-full min-w-[964px] items-end justify-center sm:items-center",
                  wrapperClass,
                )}
              >
                <div className={classNames(fullScreen ? "w-screen-xl" : "h-full w-full ")}>
                  {showHeader && renderHeader()}
                  {renderContent()}
                  {showActionButtons && renderFooter()}
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
PopupModal.defaultProps = {
  openModal: false,
  showPrimaryButton: true,
  disableSave: false,
  primaryButtonLabel: "Save",
};

export default PopupModal;
