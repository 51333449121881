import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../components/PrimaryButton";
import Spacer from "../components/Spacer";
import useUser from "../hooks/useUser";
import parseError from "./errorParser";
import Spinner from "../components/Spinner";
import { userService } from "../services/user.services";

function EmailVerifyView() {
  const { signintoken } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string>();
  const navigate = useNavigate();
  const [user, setUser] = useUser();
  useEffect(() => {
    if (isLoading && !user) {
      if (signintoken)
        userService
          .emailVerify(signintoken)
          .then((response) => {
            if (response) {
              setUser(response);
              navigate("/");
            }
          })
          .catch((error) => {
            // console.log("error", error);
            setUser(null);
            setErrorMsg(parseError(error));
          })
          .finally(() => {
            setLoading(false);
          });
    }
  }, [signintoken, errorMsg, setErrorMsg, isLoading]);
  const renderErrorMessage = () => {
    return (
      <>
        <div className="flex h-[620px] justify-center">
          <div className="min-h-[486px] w-[536px]">
            <Spacer height="50px" />
            <h3 className="text-center">Email verification link expired</h3>
            <p className="subtitle mt-4 text-center">
              Looks like the verification link has expired.
            </p>
            <Spacer height="72px" />
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-row-reverse" style={{ width: "480px" }}>
                <PrimaryButton
                  size="lg"
                  id="btn-next"
                  handleOnClick={() => {
                    navigate("/signup");
                  }}
                  label="Back to sign up"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <Spacer height="200px" />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="min-h-[600px]">
      {isLoading ? (
        <div>
          <Spacer height="269px" />
          <Spinner size="lg" />
          <Spacer height="369px" />
        </div>
      ) : (
        errorMsg && renderErrorMessage()
      )}
    </div>
  );
}

export default EmailVerifyView;
