import axios from "axios";
import useSWR, { useSWRConfig, mutate } from "swr";
import config from "../api";
import { useCallback } from "react";
import { TutorType } from "../components/AppTour";
import { swrOptionDedupling5mins } from "../types/swr";

export type UserDictionaryKeys =
  | "smart_search_do_not_ask"
  | "selected_org"
  | TutorType
  | "HOW_GIBOO_DONT_SHOW_AGAIN"
  | "FINANCIAL_SAVED_INDEX"
  | "DIVERSITY_SAVED_INDEX"
  | "DIVERSITY_CONSENT"
  | "FINANCIAL_ASSESSMENT_COMPLETED"
  | "test_financial"
  | "DO_NOT_SHOW_SIDEKICK";
interface useUserDictionaryState<T> {
  value: T;
  update: (newValue: T) => void;
  isLoading?: boolean;
}
const getUserDisctionaryValue = (key: UserDictionaryKeys) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/v2/users/dictionary?key=${key}`, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
const setUserDictionaryValue = (key: UserDictionaryKeys, value: any) => {
  const options = {
    optimisticData: value, // data will update instantly
    rollbackOnError: true,
  };
  return mutate(
    process.env.REACT_APP_API_URL + `/api/v2/users/dictionary?key=${key}`,
    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/v2/users/dictionary?key=${key}`,
        { value: value },
        config,
      )
      .then((response) => response.data),
    options,
  );
};
function useUserDictionary<T>(key: string | null, defaultValue: T): useUserDictionaryState<T> {
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const { mutate } = useSWRConfig();
  const { data: value, isLoading } = useSWR<T>(
    key ? process.env.REACT_APP_API_URL + `/api/v2/users/dictionary?key=${key}` : null,
    fetch,
    swrOptionDedupling5mins,
  );
  const handleUpdate = useCallback(
    (newValue: T) => {
      if (!key) return;
      const options = {
        optimisticData: newValue, // data will update instantly
        rollbackOnError: true,
      };
      mutate(
        process.env.REACT_APP_API_URL + `/api/v2/users/dictionary?key=${key}`,
        axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/users/dictionary?key=${key}`,
            { value: newValue },
            config,
          )
          .then((response) => response.data),
        options,
      );
    },
    [key],
  );

  return {
    value: value === undefined || value === null ? defaultValue : value,
    update: handleUpdate,
    isLoading: isLoading,
  };
}
export { getUserDisctionaryValue, setUserDictionaryValue };
export default useUserDictionary;
