import { Dialog } from "@headlessui/react";
import PlainButton from "./PlainButton";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import classNames from "classnames";
import useOnboardingData from "../hooks/useOnboarding";
import useOrgs from "../hooks/useOrgs";
import Button from "./tailwind/Button";
import { Divider } from "rsuite";
import MemoizedDropArea from "./input/FileDropArea";
import ICON_FILE from "../assets/images/file-upload.svg";
import ICON_CLOSE from "../assets/images/close-thin.svg";
import CloseButton from "./CloseButton";
import useRequestOrgVerification from "../hooks/useRequestOrgVerification";
import RoundButton from "./RoundButton";
import FakeProgressBar from "./FakeProgressBar";
import { uploadFileService } from "../services/file-upload/upload.service";
import { getVerificationFileKey } from "../utils/media";
import GibooToast from "./GibooToast";
import { toBytesFormat } from "../utils/formatHelper";
import { zIndexBackdrop, zIndexDialog, zIndexVerification } from "../types/zIndex";
import { FROM_FOR_MIXPANEL, MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import { useLocation } from "react-router-dom";
import useOrgID from "../hooks/useOrgID";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
interface VerifyOrganizationModalProps {
  from_for_mixpanel: FROM_FOR_MIXPANEL;
  title?: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCreation: (data?: any) => void;
  onClose?: () => void;
}
function VerifyOrganizationModal({
  from_for_mixpanel,
  title = "Verify organization",
  open,
  setOpen,
  onCreation,
  onClose,
}: VerifyOrganizationModalProps) {
  const mxEvent = useGibooMixpanel(from_for_mixpanel, true);
  const [user] = useUser();
  const [loadingFileUpload, setLoadingFileUpload] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [onboardingData] = useOnboardingData();
  const location = useLocation();
  const org_id = useOrgID();
  const { data, request } = useRequestOrgVerification(onboardingData._id);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<
    { file?: File; name: string; size: number; done?: boolean; key: string; failed?: boolean }[]
  >([]);
  useEffect(() => {
    if (data.files) setSelectedFile(data.files);
  }, [data]);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.org_verification[""].started);
  }, []);

  const handleUpload = (file: File) => {
    setLoadingFileUpload(true);
    uploadFileService(file, getVerificationFileKey(onboardingData._id), ["image", "doc"])
      .then((res) => {
        const key = res as string;
        const success = key ? true : false;
        mxEvent(
          success
            ? MIXPANEL_EVENTS_V2.org_verification.file.successful
            : MIXPANEL_EVENTS_V2.org_verification.file.failed,
          {
            fileName: file.name,
            fileSize: file.size,
            error: "failed to upload to s3",
          },
        );
        setSelectedFile((prev) => {
          const index = prev.findIndex((f) => f.name === file.name && f.size === file.size);
          if (index >= 0) {
            return [
              ...prev.slice(0, index),
              { ...prev[index], done: success, failed: !success, key: success ? key : "" },
              ...prev.slice(index + 1),
            ];
          } else return prev;
        });
      })
      .catch(() => {
        mxEvent(MIXPANEL_EVENTS_V2.org_verification.file.failed, {
          fileName: file.name,
          fileSize: file.size,
          error: "failed to upload to s3",
        });
        setSelectedFile((prev) => {
          const index = prev.findIndex((f) => f.name === file.name && f.size === file.size);
          if (index >= 0) {
            return [
              ...prev.slice(0, index),
              { ...prev[index], done: false, failed: true, key: "" },
              ...prev.slice(index + 1),
            ];
          } else return prev;
        });
      })
      .finally(() => setLoadingFileUpload(false));
  };
  const renderContent = () => {
    return (
      <div className="flex flex-col p-[28px]">
        {onboardingData.verified ? (
          <h5 className="grid h-[40px] w-fit place-items-center rounded border border-gray-300 px-4 py-2 font-poppins text-base font-semibold text-purple-500">
            Verified
          </h5>
        ) : data.requested ? (
          <h5 className="grid h-[40px] w-fit place-items-center rounded border border-gray-300 px-4 py-2 font-poppins text-base font-semibold text-purple-500">
            Verification in progress
          </h5>
        ) : null}
        <div className="my-4">
          <p className="font-poppins text-sm text-gray-700">
            You can upload supporting documents such as:
          </p>
          <div className="font-poppins text-sm font-semibold text-gray-900">
            IRS tax-exempt letter, incorporation letter, or other relevant documents.
          </div>
        </div>
        {renderFileUpload()}
        {renderSelectedFiles()}
        <Divider className="my-7" />
        {renderDescription()}
      </div>
    );
  };
  const renderFooter = () => {
    return (
      <div className="flex w-full justify-between px-6 py-5">
        <h6 className="text-purple-500">
          {error ? "We apologize for the inconvenience. Please try again." : ""}
        </h6>
        <Button
          id="btn-done"
          color="purple"
          label="Done"
          loading={isLoading}
          disabled={loadingFileUpload || selectedFile.filter((i) => i.done).length === 0}
          handleOnClick={() => {
            if (isLoading || !onboardingData || !user) return;
            setIsLoading(true);
            request({
              ...user,
              npo_id: onboardingData.npo_id || "",
              npo_name: onboardingData.npo_name || "",
              type: onboardingData.type,
              origin: window.location.origin,
              files: selectedFile.filter((f) => f.done && f.key),
            })
              .then(() => {
                mxEvent(MIXPANEL_EVENTS_V2.org_verification[""].successful, {
                  fileName: selectedFile.map((f) => f.name).join(", "),
                });
                GibooToast({
                  type: "success",
                  message: `Successfully requested`,
                });
                onCreation();
                setOpen(false);
              })
              .catch(() => {
                mxEvent(MIXPANEL_EVENTS_V2.org_verification[""].failed, {
                  error: "api error",
                });
                GibooToast({
                  type: "failure",
                  message: `We apologize for the inconvenience., please try again`,
                });
              })
              .finally(() => setIsLoading(false));
          }}
        />
      </div>
    );
  };
  const renderFileUpload = () => {
    return (
      <div className="min-h-[192px] rounded bg-gray-50 ">
        <MemoizedDropArea
          id={"tax file"}
          formats={[".pdf", ".png", ".jpeg", ".jpg", ".doc", ".docx"]}
          containerClass="min-h-[192px] !border-solid !border hover:!border-purple-500"
          placeholder={
            <div className="grid w-full place-items-center text-center">
              <span>
                <img src={ICON_FILE} className="h-[75px] w-[75px]" />
              </span>
              <h5 className="mt-3 font-poppins text-sm text-gray-700">
                <span className="font-semibold text-purple-500">Click to upload </span>
                or drag and drop here
              </h5>
              <p className="m-2 font-poppins text-sm text-gray-700">
                We support JPEGs, PNGs, PDFs and DOCs under 10MB
              </p>
            </div>
          }
          sizeLimit={10}
          showFileSelection={false}
          onDrop={(arg: File) => {
            setSelectedFile((prev) => [
              ...prev.filter((f) => f.name !== arg.name || f.size !== arg.size),
              { file: arg, name: arg.name, size: arg.size, done: false, key: "" },
            ]);
            handleUpload(arg);
          }}
          resetAlways
        />
      </div>
    );
  };
  const renderSelectedFiles = () => {
    return (
      <div className="mt-4 flex flex-col gap-4">
        {selectedFile.map((item, index) => {
          return (
            <div key={index} className="grid w-full grid-cols-5 items-center gap-2">
              <div
                className={classNames(
                  "col-span-4 grid grid-cols-4 items-center gap-2 rounded px-2 py-1",
                  item.failed ? "bg-purple-50" : "",
                )}
              >
                <span className="col-span-3 truncate whitespace-nowrap">{item.name}</span>
                <div className="col-span-1 flex justify-end">
                  {item.failed ? (
                    <span className="text-sm font-semibold text-purple-500">Failed</span>
                  ) : item.done ? (
                    <span className="text-sm">{toBytesFormat(item.size)}</span>
                  ) : (
                    <FakeProgressBar estimatedSeconds={3} done={item.done} />
                  )}
                </div>
              </div>
              <div className="col-span-1 flex justify-end gap-2">
                <RoundButton
                  id={`btn-remove-item-${index}`}
                  icon="gi-md gi-x"
                  type="tertiary"
                  size="sm"
                  handleOnClick={() =>
                    setSelectedFile((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
                  }
                />
                {item.failed && item.file && (
                  <PlainButton
                    handleOnClick={() => {
                      if (item.file) handleUpload(item.file);
                    }}
                    id={`btn-retry-item-${index}`}
                    className="!p-0 !text-sm"
                    color="purple"
                    label="Retry"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const renderDescription = () => {
    return (
      <div className="flex flex-col gap-4">
        <h6 className="font-poppins text-xs text-gray-800">
          After verification, you will get to enjoy the full Giboo features. Verification helps in
          the prevention of fraudulent activities or misrepresentation, protecting both your
          organizations and funders from potential scams.
        </h6>

        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            1. Why is organization verification important?
          </h4>
          <p className="font-poppins text-xs text-gray-800">
            Verification helps in the prevention of fraudulent activities or misrepresentation,
            protecting both your organizations and funders from potential scams. Help us build a
            trustworthy Giboo community!
          </p>
        </div>

        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            2. How long does the verification process take?
          </h4>
          <p className="font-poppins text-xs text-gray-800">
            Verification typically takes 24-48 hours, and we&apos;ll notify you as soon as it&apos;s
            complete. Your information and privacy are safe with us, as we use these documents
            exclusively for verification purposes.
          </p>
        </div>
      </div>
    );
  };
  return (
    <Dialog open={open} onClose={() => null} className=" ">
      <div className={classNames("fixed inset-0 bg-black/80", zIndexBackdrop)} aria-hidden="true" />
      <div
        className={classNames(
          "fixed inset-0 flex  w-screen items-center justify-center overflow-y-auto p-4",
          zIndexDialog,
        )}
      >
        <Dialog.Panel
          className={classNames(
            "mx-auto min-h-fit !w-[586px] rounded bg-white",
            zIndexVerification,
          )}
        >
          <div className="flex h-full w-full flex-col ">
            <div className="flex items-center justify-between px-6 py-4">
              <h3 className="font-poppins text-2xl font-semibold">{title}</h3>
              <CloseButton
                className="cursor-pointer"
                onClick={() => {
                  onClose?.();
                  setOpen(false);
                }}
              />
            </div>
            <div className="h-[550px] overflow-y-scroll border-y border-gray-300 ">
              {renderContent()}
            </div>
            {renderFooter()}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
export default VerifyOrganizationModal;
