import React, { ReactNode, useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  ComposedChart,
  Dot,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import classNames from "classnames";
import { toFixedWithMinimum, toUsdLong, toUsdShort } from "../../../../utils/formatHelper";
import useNPOIRSProfile, { OperatingExpenses } from "../../../../hooks/useNPOIRSProfile";
import NoGraph from "../../../donor-detail-view/components/NoGraph";
import { TooltipWrapper } from "../../../../components/TooltipWrapper";
import useDiversity from "../../../../hooks/useDiversity";
import { Divider } from "rsuite";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import DecreaseTag from "../../financials/component/DecreaseTag";
import Spacer from "../../../../components/Spacer";
import { NPOIRSProfile } from "../../../../types/npo";
import FinancialChartLoader from "../../financials/component/FinancialChartLoader";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import SmallestProportionTag from "../../financials/component/SmallestProportionTag";
import LargestProportionTag from "../../financials/component/LargestProportionTag";
import IncreaseTag from "../../financials/component/IncreaseTag";
import PercentageTag from "../../../../components/PercentageTag";
import AssessmentResult from "../../../../components/AssessmentResult";
import PopupModal from "../../../../components/tailwind/PopupModal";
import { OperatingExpensesInfo } from "../../financials/pages/OperatingExpenses";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { NpoTitle } from "./NpoDiversityEthnicity";
import FinancialPredictionInfo from "./FinancialPredictionInfo";
import ContentHideWrapper from "../../../../components/ContentHideWrapper";
const COLOR_MAP = {
  program: "#FF70A6",
  operating: "#FF9770",
  fundraising: "#70D6FF",
};
interface Iprops {
  npo_id?: string;
  org_id?: string;
  allowEdit?: boolean;
  isPrivateView?: boolean;
  compact?: boolean;
  recomendation?: boolean;
  showStats?: boolean;
  showInfo?: boolean;
  hideTitle?: boolean;
  children?: ReactNode;
  showChartLoader?: boolean;
  withoutLogin?: boolean;
  isProfileClaim?: boolean;
}
const InfoTooltip = (props: any) => {
  const renderTitle = (value: string) => {
    return <span className="font-poppins font-normal text-gray-700">{value}</span>;
  };
  const renderValue = (value: string) => {
    return <span className="font-poppins font-semibold text-gray-900">{value}</span>;
  };
  if (props.active && props.payload && props.payload.length) {
    const totalExpenses =
      (props.payload[0].payload?.program || 0) +
      (props.payload[0].payload?.operating || 0) +
      (props.payload[0].payload?.fundraising || 0);
    return (
      <div className="giboo-box flex flex-col gap-1">
        <p className="font-poppins font-semibold text-gray-900">
          {props.payload[0].payload.tax_year}
          {props.payload[0].payload.is_predicted
            ? ` (Predicted)`
            : props.payload[0].payload.is_interpolated
            ? ` (Estimated)`
            : ""}
        </p>
        <div className="inline-flex gap-2 !text-sm">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#3E5E6C",
            }}
          />
          {renderTitle("Program expenses:")}
          {renderValue(toUsdLong(props.payload[0].payload?.program || 0))}
        </div>
        <div className="inline-flex gap-2 !text-sm">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#866168",
            }}
          />
          {renderTitle("Operating expenses:")}
          {renderValue(toUsdLong(props.payload[0].payload?.operating || 0))}
        </div>
        <div className="inline-flex gap-2 !text-sm">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#DD9323",
            }}
          />
          {renderTitle("Fundraising expenses:")}
          {renderValue(toUsdLong(props.payload[0].payload?.fundraising || 0))}
        </div>
        <Divider className="my-2" />
        {renderValue(`Total expenses in ${props.payload[0].payload.tax_year}`)}
        {renderValue(toUsdLong(totalExpenses))}
      </div>
    );
  }
  return null;
};

function NpoFinancialOperatingExpenses({
  npo_id,
  org_id,
  recomendation,
  allowEdit,
  isPrivateView,
  compact,
  showInfo = true,
  showStats = true,
  hideTitle = false,
  showChartLoader = true,
  children,
  withoutLogin,
  isProfileClaim,
}: Iprops) {
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { data, operatingExpenses, isLoading, beforeLatest, latest } = useNPOIRSProfile(npo_id);
  const { data: expense, isLoading: isExpenseLoading } = useFinancialRecommendedActions(
    !isPrivateView && loading
      ? undefined
      : !isPrivateView && loading
      ? undefined
      : !isPrivateView && !org_id
      ? npo_id
      : org_id,
    "expense",
    !isPrivateView && !org_id,
  );
  const maxPortion = operatingExpenses
    ? [
        operatingExpenses.program,
        operatingExpenses.fundraising,
        operatingExpenses.operating,
      ].reduce((prev, cur) => Math.max(prev, cur), operatingExpenses.program)
    : -1;
  const minPortion = operatingExpenses
    ? [
        operatingExpenses.program,
        operatingExpenses.fundraising,
        operatingExpenses.operating,
      ].reduce((prev, cur) => Math.min(prev, cur), operatingExpenses.program)
    : -1;
  const renderProgramExpenses = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Program expenses</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={operatingExpenses?.diff_program}
            beforeLatest={beforeLatest?.program}
            latest={latest?.program}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {operatingExpenses ? (
              maxPortion === operatingExpenses.program ? (
                <LargestProportionTag />
              ) : minPortion === operatingExpenses.program ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {expense?.Program?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">{expense?.Program?.recommendations || ""}</div>
          </div>
        )}
      </div>
    );
  };
  const renderOperatingExpenses = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Operating expenses</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={operatingExpenses?.diff_operating}
            beforeLatest={beforeLatest?.operating}
            latest={latest?.operating}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {operatingExpenses ? (
              maxPortion === operatingExpenses.operating ? (
                <LargestProportionTag />
              ) : minPortion === operatingExpenses.operating ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {expense?.Operating?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">{expense?.Operating?.recommendations || ""}</div>
          </div>
        )}
      </div>
    );
  };
  const renderFundraisingExpenses = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Fundraising expenses</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={operatingExpenses?.diff_fundraising}
            beforeLatest={beforeLatest?.fundraising}
            latest={latest?.fundraising}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {operatingExpenses ? (
              maxPortion === operatingExpenses.fundraising ? (
                <LargestProportionTag />
              ) : minPortion === operatingExpenses.fundraising ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {expense?.Fundraising?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              {expense?.Fundraising?.recommendations || ""}
            </div>
          </div>
        )}
      </div>
    );
  };
  const chartLoading = recomendation ? loading : isLoading;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-5"
        showHeader={false}
        showActionButtons={false}
      >
        <OperatingExpensesInfo
          handleClose={() => {
            setShowInfoModal(false);
          }}
        />
      </PopupModal>
      <div
        className={classNames(
          "flex min-h-[100px] flex-col ",
          recomendation && "rounded border border-gray-300 bg-white p-5",
        )}
      >
        {!hideTitle && (
          <NpoTitle
            title="Operating expenses"
            recomendation={recomendation}
            setShowInfoModal={setShowInfoModal}
          />
        )}
        {showChartLoader && chartLoading ? (
          <FinancialChartLoader title="Analyzing organization’s Operating expenses" />
        ) : (
          <>
            {/* chart */}
            <div className="mx-auto my-5">
              <OperatingExpenseChart data={data} npo_id={npo_id} wrapperClass={""} compact />
            </div>
            <ContentHideWrapper
              id="Operating expenses"
              open={withoutLogin && isProfileClaim}
              messageTitle="Ensure your nonprofit's success with Giboo's financial assessment tool. "
              messageDescription="Get key insights and strategies to secure sustainable funding—try it now for free!"
            >
              {/* info */}
              {showInfo &&
                (isExpenseLoading || loading ? (
                  <MemoizedLoadingRecommendedAction loading={loading || isExpenseLoading} short />
                ) : (
                  <AssessmentResult content={expense?.Summary || ""} />
                ))}
              {recomendation ? <Divider className="my-[40px]" /> : <Spacer height="20px" />}
              {/* stats */}
              {showStats &&
                (isExpenseLoading || loading ? (
                  <MemoizedLoadingRecommendedAction loading={loading || isExpenseLoading} />
                ) : (
                  <div className="bg-gray-50 px-6 py-5">
                    <h6 className="mb-5 font-poppins text-base font-semibold text-black">Stats</h6>
                    <div className="flex flex-col gap-10">
                      {renderProgramExpenses()}
                      {renderOperatingExpenses()}
                      {renderFundraisingExpenses()}
                    </div>
                  </div>
                ))}
            </ContentHideWrapper>
          </>
        )}
      </div>
      {!chartLoading && children && children}
    </>
  );
}
export default NpoFinancialOperatingExpenses;

export function OperatingExpenseChart({
  npo_id,
  wrapperClass,
  compact,
  data,
}: {
  data?: NPOIRSProfile[];
  npo_id?: string;
  wrapperClass?: string;
  compact?: boolean;
}) {
  const renderHeader = () => {
    return (
      <div className="mb-4 inline-flex flex-wrap items-center gap-5 md:flex-nowrap">
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#3E5E6C",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Program expenses</p>
        </div>
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#866168",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Operating expenses</p>
        </div>
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#DD9323",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Fundraising expenses</p>
        </div>
      </div>
    );
  };

  if (data?.length === 0) {
    return <NoGraph />;
  }
  const renderChart = () => {
    const foreCastCount = data?.reduce((count, item) => {
      return item.is_predicted ? count + 1 : count;
    }, 0);
    const estimatedData = data?.filter((i) => i.is_interpolated);
    const predictedData = data?.filter((i) => i?.is_predicted);
    return (
      <>
        <ResponsiveContainer minHeight="330px" minWidth="50px" width="100%">
          <ComposedChart
            data={data}
            width={500}
            margin={{ top: 0, bottom: 20 }}
            layout="vertical"
            barCategoryGap="20%"
            barGap={2}
            maxBarSize={10}
          >
            <CartesianGrid
              vertical={false}
              horizontal={false}
              strokeDasharray="4 4"
              stroke={"#DEDEDE"}
              strokeWidth={1}
            />
            <XAxis
              // dx={10}
              axisLine={true}
              type="number"
              tickFormatter={(v, i) => toUsdShort(v, 0)}
              strokeWidth={1}
              tick={{ fill: "#5F5F5F" }}
              stroke="#DEDEDE"
              style={{
                fontFamily: "poppins",
                fontSize: "12px",
                whiteSpace: "pre-wrap",
              }}
            />
            <YAxis
              axisLine={true}
              dataKey="tax_year"
              type="category"
              width={40}
              domain={[0, "dataMax"]}
              stroke="#DEDEDE"
              tick={{ fill: "#5F5F5F" }}
              strokeWidth={1}
              style={{
                fontFamily: "poppins",
                fontSize: "12px",
                whiteSpace: "pre-wrap",
              }}
            />
            <Tooltip content={<InfoTooltip />} cursor={false} wrapperStyle={{ outline: "none" }} />
            <Bar dataKey="program" fill="#3E5E6C" minPointSize={30} stackId={"A"} barSize={23}>
              <LabelList
                dataKey="program"
                position="insideRight"
                fill="#fff"
                fontSize={10}
                formatter={(props: any) => {
                  return toUsdShort(props, 1);
                }}
              />
            </Bar>
            <Bar dataKey="operating" minPointSize={30} fill="#866168" stackId={"A"} barSize={23}>
              <LabelList
                dataKey="operating"
                position="insideRight"
                fill="#fff"
                fontSize={10}
                formatter={(props: any) => {
                  return toUsdShort(props, 1);
                }}
              />
            </Bar>
            <Bar dataKey="fundraising" fill="#DD9323" minPointSize={30} stackId={"A"} barSize={23}>
              <LabelList
                dataKey="fundraising"
                position="insideRight"
                fill="#fff"
                fontSize={10}
                formatter={(props: any) => {
                  return toUsdShort(props, 1);
                }}
              />
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
        <div className="flex items-center  justify-center gap-4">
          {estimatedData && estimatedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-purple-100 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-estimated text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Estimated years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {estimatedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
          {predictedData && predictedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-dashed border-purple-100 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-predicted text-purple-0" />
                </span>
                <p className="font-poppins text-xs text-gray-700">Predicted</p>
              </div>
              <p className="font-poppins text-sm text-black">
                {predictedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
        </div>
      </>
    );
  };
  const renderChart2 = () => {
    const foreCastCount = data?.reduce((count, item) => {
      return item.is_predicted ? count + 1 : count;
    }, 0);
    const estimatedData = data?.filter((i) => i.is_interpolated);
    const predictedData = data?.filter((i) => i.is_predicted);
    const chartSeries: ApexAxisChartSeries = [
      {
        name: "program",
        type: "bar",
        data: data?.map((i) => i.program) || [],
        color: "#3E5E6C",
      },
      {
        name: "operating",
        type: "bar",
        data: data?.map((i) => i.operating) || [],
        color: "#866168",
      },
      {
        name: "fundraising",
        type: "bar",
        data: data?.map((i) => i.fundraising) || [],
        color: "#DD9323",
      },
    ];
    const chartOptions: ApexOptions = {
      chart: {
        selection: {
          enabled: false,
        },
        toolbar: { show: false },
        height: 350,
        type: "bar",
        stacked: true,
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: 24,
          dataLabels: {
            hideOverflowingLabels: true,
            total: {
              enabled: false,
            },
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter(val, opts) {
          const value = Number(val);
          return value > 0 ? toUsdShort(value, 1) : "";
        },
        textAnchor: "middle",
        background: { enabled: false },
        style: {
          colors: ["#fff"],
          fontSize: "10px",
          fontFamily: "poppins",
          fontWeight: "400px",
        },
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: data?.map((i) => i.tax_year) || [],
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "poppins",
            fontWeight: 400,
          },
          formatter(val, opts) {
            return toUsdShort(Number(val));
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#DEDEDE",
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "poppins",
            fontWeight: 400,
          },
          formatter(val, opts) {
            return val.toString();
          },
        },
      },
      stroke: {
        curve: "monotoneCubic",
        width: 1,
        colors: ["#3E5E6C", "#866168", "#DD9323"],
      },
      forecastDataPoints: {
        count: foreCastCount,
        strokeWidth: 1,
        dashArray: 4,
        fillOpacity: 0.5,
      },
      markers: {
        size: 6,
        shape: "circle",
        hover: {
          size: 6,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const _temp = {
            ...(data?.[dataPointIndex] || {}),
            program: series[0]?.[dataPointIndex] || 0,
            operating: series[1]?.[dataPointIndex] || 0,
            fundraising: series[2]?.[dataPointIndex] || 0,
          };
          const sum = (_temp.program || 0) + (_temp.operating || 0) + (_temp.fundraising || 0);
          const temp = {
            ..._temp,
            p_program: sum === 0 ? 0 : toFixedWithMinimum((_temp.program / sum) * 100, 1),
            p_operating: sum === 0 ? 0 : toFixedWithMinimum((_temp.operating / sum) * 100, 1),
            p_fundraising: sum === 0 ? 0 : toFixedWithMinimum((_temp.fundraising / sum) * 100, 1),
          };
          const renderTitle = (value: string) => {
            return '<span class="font-poppins font-normal text-gray-700">' + value + "</span>";
          };
          const renderValue = (value: string) => {
            return '<span class="font-poppins font-semibold text-gray-900">' + value + "</span>";
          };

          if (temp) {
            return (
              '<div class="giboo-box flex flex-col gap-1">' +
              '<p class="font-poppins font-semibold text-gray-900">' +
              (temp ? temp.tax_year : "") +
              (temp && temp.is_predicted
                ? " (Predicted)"
                : temp && temp.is_interpolated
                ? " (Estimated)"
                : "") +
              "</p>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[16px] w-[16px] bg-[#3E5E6C]">' +
              "</span>" +
              renderTitle("Program expenses:") +
              renderValue(
                `${toUsdLong(temp && temp.program ? temp.program : 0)} (${temp.p_program}%)`,
              ) +
              "</div>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[16px] w-[16px] bg-[#866168]">' +
              "</span>" +
              renderTitle("Operating expenses:") +
              renderValue(
                `${toUsdLong(temp && temp.operating ? temp.operating : 0)} (${temp.p_operating}%)`,
              ) +
              "</div>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[16px] w-[16px] bg-[#DD9323]">' +
              "</span>" +
              renderTitle("Fundraising expenses:") +
              renderValue(
                `${toUsdLong(temp && temp.fundraising ? temp.fundraising : 0)} (${
                  temp.p_fundraising
                }%)`,
              ) +
              "</div>" +
              "</div>"
            );
          }
        },
      },
    };
    return (
      <>
        <Chart options={chartOptions} series={chartSeries} type="bar" width="508" height={350} />
        <div className="flex items-center  justify-center gap-4">
          {estimatedData && estimatedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-purple-100 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-estimated text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Estimated years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {estimatedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
          {predictedData && predictedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-dashed border-purple-500 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-predicted text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Predicted years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {predictedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <FinancialPredictionInfo />
        </div>
      </>
    );
  };
  return (
    <div
      className={classNames("flex h-fit w-full flex-col items-center justify-center", wrapperClass)}
    >
      <div className="grid w-full max-w-[528px] place-items-center items-start">
        {renderHeader()}
        <>{renderChart2()}</>
      </div>
    </div>
  );
}
