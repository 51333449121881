import classNames from "classnames";
import { useSidekickContext } from "./GibooSidekick";
import { ReactNode, useCallback, useEffect, useState } from "react";
import Button from "./tailwind/Button";
import useOnboardingData from "../hooks/useOnboarding";
import { useNavigate } from "react-router";
import { getURLSearchParamsFromSearchQuery } from "../types/search";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import ICON_WHY_MATCH from "../assets/images/whyMatchedWithoutRound.svg";
import ICON_WHY_MATCH_WHITE from "../assets/images/whyMatchedWhite.svg";
import {
  sidekickDocumentMessageInput,
  sidekickHighlightTarget,
  sidekickScrollTargetMoreSearchOption,
  sidekickSearchEstimatedLoadingTime,
  sidekickSearchLoading,
  sidekickStartFundraisingLoading,
  sidekickWhyMatched,
  sidekickWhyMatchedLoading,
} from "../app/recoilStore";
import Spinner from "./Spinner";
import useFakeProgress from "../hooks/useFakeProgress";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  GibooSidekickChatComponentType,
  GibooSidekickActionType,
  GibooSidekickChatData,
  GibooSidekickGroup,
  GibooSidekickIcon,
  GibooSidekickGAValue,
} from "../types/sidekick";
import useMySubtasks from "../hooks/project/useMySubtasks";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useGibooMixpanel from "../hooks/useGibooMixpanel";

const GA_TAG_FOR_SIDEKICK = "GA_TAG_sidekick";

const fillingTemplate = (
  t: string | undefined,
  param: { [key: string]: string },
): string | undefined => {
  if (!t) return t;
  return Object.keys(param)
    .sort((a, b) => b.localeCompare(a))
    .reduce((prev, key) => prev.replaceAll(`$${key}`, param[key] || ""), t);
};

interface GibooSidekickChatComponentProps {
  group: GibooSidekickGroup;
  id: string;
  opened?: boolean;
  type: GibooSidekickChatComponentType;
  title?: string;
  message?: string;
  answer?: string;
  action_type?: GibooSidekickActionType;
  action_target?: string;
  action_button?: { label: string; action_type: GibooSidekickActionType; action_target?: string };
  child?: GibooSidekickChatData;
  icon?: GibooSidekickIcon;
}
function GibooSidekickChatComponent({
  group,
  opened = false,
  id,
  type,
  title,
  message,
  answer,
  action_type,
  action_button,
  action_target,
  child,
  icon,
}: GibooSidekickChatComponentProps) {
  const navigate = useNavigate();
  const browserLocation = useLocation();
  const {
    setChatLoading,
    setAnswer,
    setScrollInitiate,
    updateFilter,
    param,
    setShowInvite,
    setShowOrgVerify,
    setShowTaskCreation,
    setShowInviteProject,
    setShowProjectEdit,
    sidekickLocation,
  } = useSidekickContext();
  const mxEvent = useGibooMixpanel();
  const [onboardingData] = useOnboardingData();
  const [open, setOpen] = useState<boolean>(false);
  const [delayForAnimation, setDelayForAnimation] = useState<boolean>(true);
  const startFundraisingLoading = useRecoilValue(sidekickStartFundraisingLoading);
  const setSidekickScrollTargetMoreSearchOption = useSetRecoilState(
    sidekickScrollTargetMoreSearchOption,
  );
  const setSidekickHighlightTarget = useSetRecoilState(sidekickHighlightTarget);
  const setSidekickDocumentMessageInput = useSetRecoilState(sidekickDocumentMessageInput);
  const getSidekickDocumentMessageInput = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const i = await snapshot.getPromise(sidekickDocumentMessageInput);
        return i;
      },
    [],
  );

  const { data: subtasks } = useMySubtasks(
    undefined,
    undefined,
    action_button?.action_type !== GibooSidekickActionType.CREATE_OR_VIEW_SUBTASK,
  );
  const userSide = type & 1;
  useEffect(() => {
    if (opened && !open) {
      setDelayForAnimation(true);
      setTimeout(() => setDelayForAnimation(false), 500);
    } else {
      setDelayForAnimation(false);
    }
    setOpen(opened);
  }, [opened]);
  const handleOnAction = useCallback(
    (isButton = false) => {
      const at = !isButton ? action_type || action_button?.action_type : action_button?.action_type;
      const atarget =
        fillingTemplate(
          !isButton
            ? action_target || action_button?.action_target || ""
            : action_button?.action_target || "",
          param,
        ) || "";
      // console.log({ at, atarget });
      if (!at) return;
      switch (at) {
        case GibooSidekickActionType.SEARCH_OPENCALL_GRANT: {
          navigate(
            `/search?text_query=&mission=${encodeURIComponent(
              onboardingData.mission || "",
            )}&type=grant&done_tagging=false&done_pre_tagging=false`,
          );
          break;
        }
        case GibooSidekickActionType.SEARCH_VIRTUAL_GRANT: {
          navigate(
            `/search?text_query=&mission=${encodeURIComponent(
              onboardingData.mission || "",
            )}&type=virtual_grant&done_tagging=false&done_pre_tagging=false`,
          );
          break;
        }
        case GibooSidekickActionType.SEARCH_OPENCALL_GRANT_TEXT: {
          navigate(
            `/search?text_query=${encodeURIComponent(atarget)}&mission=${encodeURIComponent(
              onboardingData.mission || "",
            )}&type=grant&done_tagging=false&done_pre_tagging=false`,
          );
          break;
        }
        case GibooSidekickActionType.SEARCH_VIRTUAL_GRANT_TEXT: {
          navigate(
            `/search?text_query=${encodeURIComponent(atarget)}&mission=${encodeURIComponent(
              onboardingData.mission || "",
            )}&type=virtual_grant&done_tagging=false&done_pre_tagging=false`,
          );
          break;
        }
        case GibooSidekickActionType.SEARCH_FUNDER_TEXT: {
          navigate(
            `/search?text_query=${encodeURIComponent(atarget)}&mission=${encodeURIComponent(
              onboardingData.mission || "",
            )}&type=funder&done_tagging=false&done_pre_tagging=false`,
          );
          break;
        }
        case GibooSidekickActionType.OPEN_SEARCH_TAGS: {
          const element = document.getElementById("btn-filter-keyword");
          element?.click();
          break;
        }
        case GibooSidekickActionType.OPEN_SEARCH_LOCATION: {
          const element = document.getElementById("btn-filter-service-location");
          element?.click();
          break;
        }
        case GibooSidekickActionType.OPEN_MORE_FILTER: {
          if (atarget) setSidekickScrollTargetMoreSearchOption(atarget);
          const element = document.getElementById("GA_TAG_click_moresearchoption");
          element?.click();
          break;
        }
        case GibooSidekickActionType.WHY_MATCHED: {
          const element = document.getElementById("GA_TAG_click_whymatched");
          element?.click();
          break;
        }
        case GibooSidekickActionType.NAVIGATE: {
          if (atarget) navigate(atarget);
          break;
        }
        case GibooSidekickActionType.SCROLL_TO: {
          if (atarget) {
            const targetElement = document.getElementById(atarget);
            setSidekickHighlightTarget(atarget);
            targetElement?.scrollIntoView({ block: "center", behavior: "auto", inline: "nearest" });
          }
          break;
        }
        case GibooSidekickActionType.SAVE: {
          const elements = document.querySelectorAll(
            `[id^="GA_TAG_click_save"][aria-describedby="in_detail_view"]`,
          );
          if (elements.length > 0) (elements[0] as HTMLElement)?.click();
          break;
        }
        case GibooSidekickActionType.START_FUNDRAISING: {
          const element = document.getElementById("GA_TAG_click_startfundraising");
          element?.click();
          break;
        }
        case GibooSidekickActionType.SCROLL_TO_MAP: {
          const targetElement = document.getElementById("geograhpical-focus");
          targetElement?.scrollIntoView({ block: "center", behavior: "auto", inline: "nearest" });
          break;
        }
        case GibooSidekickActionType.SCROLL_TO_TAGS: {
          const targetElement = document.getElementById("tags");
          targetElement?.scrollIntoView({ block: "center", behavior: "auto", inline: "nearest" });
          break;
        }
        case GibooSidekickActionType.CREATE_PROJECT: {
          const element = document.getElementById("btn-create-project");
          element?.click();
          break;
        }
        case GibooSidekickActionType.CREATE_OR_VIEW_SUBTASK: {
          if (subtasks.length === 0) {
            setShowTaskCreation(true);
          } else {
            navigate(`/my-tasks`);
          }
          break;
        }
        case GibooSidekickActionType.INVITE_ORG_MEMBERS: {
          setShowInvite(true);
          break;
        }
        case GibooSidekickActionType.VERIFY_ORG: {
          setShowOrgVerify(true);
          break;
        }
        case GibooSidekickActionType.ORG_CONTACT: {
          const element = document.getElementById("btn-edit-People");
          element?.click();
          break;
        }
        case GibooSidekickActionType.INVITE_PROJECT_MEMBERS: {
          setShowInviteProject(true);
          break;
        }
        case GibooSidekickActionType.PROJECT_ACTIVITIES: {
          navigate(`${browserLocation.pathname}#activities`, { replace: true });
          break;
        }
        case GibooSidekickActionType.PROJECT_SUBTASKS: {
          navigate(`${browserLocation.pathname}#task_list`, { replace: true });
          break;
        }
        case GibooSidekickActionType.PROJECT_FUNDRAISING_STATUS: {
          navigate(`${browserLocation.pathname}#fundraising_status`, { replace: true });
          break;
        }
        case GibooSidekickActionType.PROJECT_PROGRESS: {
          navigate(`${browserLocation.pathname}#project_progress`, { replace: true });
          break;
        }
        case GibooSidekickActionType.PROJECT_CREATE_SUBTASK: {
          setShowTaskCreation(true);
          break;
        }
        case GibooSidekickActionType.PROJECT_INFORMATION: {
          setShowProjectEdit(true);
          break;
        }
        case GibooSidekickActionType.PROJECT_TARGET_DETAIL: {
          navigate(`${browserLocation.pathname}#detail`, { replace: true });
          break;
        }
        case GibooSidekickActionType.DOCUMENT_AI_ASKING_INFO_COMMAND: {
          setSidekickDocumentMessageInput(atarget || "");
          setTimeout(() => {
            getSidekickDocumentMessageInput().then((i) => {
              if (i) {
                const element = document.getElementById("btn-send-asking-info");
                element?.click();
              }
            });
          }, 200);
          break;
        }
      }
    },
    [
      type,
      action_type,
      action_button,
      onboardingData,
      browserLocation,
      subtasks,
      setShowTaskCreation,
      setShowInvite,
      setShowInviteProject,
      setShowOrgVerify,
      setShowProjectEdit,
      setSidekickHighlightTarget,
      setSidekickDocumentMessageInput,
      getSidekickDocumentMessageInput,
    ],
  );
  const color =
    type === GibooSidekickChatComponentType.GREETING
      ? "bg-[#F5F0EA]"
      : type === GibooSidekickChatComponentType.GREETING_WITHOUT_COLOR
      ? "bg-white"
      : type === GibooSidekickChatComponentType.INFORMATION_BUTTON ||
        type === GibooSidekickChatComponentType.ACTION_BUTTON
      ? open
        ? type === GibooSidekickChatComponentType.INFORMATION_BUTTON
          ? "bg-purple-500 text-white"
          : "bg-white"
        : "cursor-pointer border border-gray-300 focus:bg-purple-50 hover:bg-gray-300 bg-white"
      : type === GibooSidekickChatComponentType.ANSWER
      ? action_type
        ? "bg-purple-500 text-white cursor-pointer"
        : "bg-purple-500 text-white"
      : "bg-white";
  const shape =
    (type === GibooSidekickChatComponentType.INFORMATION_BUTTON ||
      type === GibooSidekickChatComponentType.ACTION_BUTTON) &&
    !open
      ? "rounded-[24px] shadow-[0px_1px_2px_0px_rgba(0,0,0,0.3),0px_2px_6px_2px_rgba(0,0,0,0.15)]"
      : userSide
      ? "ml-10 rounded-[10px_10px_0px_10px] shadow-[0px_0px_15px_1px_rgba(0,0,0,0.1)]"
      : "mr-10 rounded-[10px_10px_10px_0px] shadow-[0px_0px_15px_1px_rgba(0,0,0,0.1)]";
  const track =
    action_type &&
    id &&
    (type === GibooSidekickChatComponentType.INFORMATION_BUTTON ||
      type === GibooSidekickChatComponentType.ACTION_BUTTON)
      ? true
      : false;
  const ariaValueTextForTrack = { ...(track ? { "aria-valuetext": id } : {}) };
  return (
    <>
      <div id={`chat-group-${group}-${id}`}>
        <div
          {...(track ? { id: GA_TAG_FOR_SIDEKICK, "aria-valuetext": id } : {})}
          onClick={() => {
            if (id && id !== "greeting")
              mxEvent(MIXPANEL_EVENTS_V2.sidekick.button.clicked, {
                sidekickLocation,
                sidekickButton: id as GibooSidekickGAValue,
                location: browserLocation.pathname,
              });
            if (delayForAnimation) return;
            if (
              type === GibooSidekickChatComponentType.INFORMATION_BUTTON ||
              type === GibooSidekickChatComponentType.ACTION_BUTTON
            ) {
              const added = setAnswer(
                group,
                id,
                {
                  id,
                  message: answer || "",
                  type: GibooSidekickChatComponentType.ANSWER,
                  action_type,
                  icon,
                  action_button,
                  action_target,
                },
                false,
              );
              if (added) {
                setChatLoading((prev) => ({ ...prev, [group]: true }));
                setTimeout(() => {
                  setChatLoading((prev) => ({ ...prev, [group]: false }));
                }, 1500);
                if (action_type)
                  setTimeout(() => {
                    handleOnAction();
                  }, 300);
              }
              //   console.log("initiate");
              // }, 400);
              // setTimeout(() => {
              //   setScrollInitiate([`${group}-${id}`].join(""));
              //   console.log("initiate");
              // }, 600);
            } else if (type === GibooSidekickChatComponentType.ANSWER && action_type) {
              handleOnAction();
            }
          }}
          className={classNames("px-4 py-2 transition-all duration-300", color, shape)}
        >
          {title && (
            <p className="mb-3 font-semibold" {...ariaValueTextForTrack}>
              {fillingTemplate(title, param) || ""}
            </p>
          )}
          <div className="flex items-start gap-2" {...ariaValueTextForTrack}>
            {(type === GibooSidekickChatComponentType.INFORMATION_BUTTON || icon) && (
              <>
                {type === GibooSidekickChatComponentType.INFORMATION_BUTTON ? (
                  <i className="gi-md gi-info" {...ariaValueTextForTrack} />
                ) : icon === GibooSidekickIcon.WHY_MATCHED ? (
                  <img
                    src={color.includes("bg-purple-500") ? ICON_WHY_MATCH_WHITE : ICON_WHY_MATCH}
                    {...ariaValueTextForTrack}
                  />
                ) : null}
              </>
            )}
            <div className="my-[0.5px] flex flex-col" {...ariaValueTextForTrack}>
              {message === "$why_matched" ? (
                <WhyMatchedDesc />
              ) : (
                <h6
                  dangerouslySetInnerHTML={{ __html: fillingTemplate(message, param) || "" }}
                  {...ariaValueTextForTrack}
                ></h6>
              )}
              {type === GibooSidekickChatComponentType.INFORMATION_BUTTON && answer && (
                <div
                  className={classNames(
                    "overflow-hidden !transition-none",
                    open && !delayForAnimation ? "h-full pt-5" : "!h-0 pt-0",
                  )}
                  {...ariaValueTextForTrack}
                >
                  <h6
                    className={classNames(
                      "text-white !transition-all !delay-500 duration-500",
                      open ? "" : "!translate-y-[-100px] scale-y-0",
                    )}
                    dangerouslySetInnerHTML={{
                      __html: !open ? "" : fillingTemplate(answer, param) || "",
                    }}
                    {...ariaValueTextForTrack}
                  ></h6>
                </div>
              )}
              {(type === GibooSidekickChatComponentType.INFORMATION_BUTTON ||
                type === GibooSidekickChatComponentType.ANSWER) &&
                open &&
                !delayForAnimation &&
                action_button && (
                  <Button
                    className="mt-5 self-start !border-white !text-sm !text-white hover:!bg-purple-400"
                    size="sm"
                    outline
                    id={GA_TAG_FOR_SIDEKICK}
                    ariaValueText={id}
                    loading={
                      action_button?.action_type === GibooSidekickActionType.START_FUNDRAISING
                        ? startFundraisingLoading
                        : false
                    }
                    appendIcon={<i className="gi gi-right-arrow" />}
                    handleOnClick={() => handleOnAction(true)}
                    label={fillingTemplate(action_button.label, param) || ""}
                  />
                )}
            </div>
            {type === GibooSidekickChatComponentType.GREETING &&
              group === GibooSidekickGroup.SEARCH_LOADING && <SearchLoading />}
          </div>
        </div>
      </div>
      {child && !delayForAnimation && (
        <GibooSidekickChatComponent
          group={group}
          id={child.id}
          opened
          type={child.type}
          message={child.message}
          action_type={child.action_type}
          action_button={child.action_button}
          icon={child.icon}
          action_target={child.action_target}
        />
      )}
    </>
  );
}
export default GibooSidekickChatComponent;
function WhyMatchedDesc() {
  const loading = useRecoilValue(sidekickWhyMatchedLoading);
  const whyDesc = useRecoilValue(sidekickWhyMatched);
  return (
    <>
      {loading || !whyDesc ? (
        <Spinner size="sm" wrapperClass="mt-[1px]" color="white" />
      ) : (
        <h6
          className="text-white"
          dangerouslySetInnerHTML={{
            __html: whyDesc || "",
          }}
        ></h6>
      )}
    </>
  );
}

function SearchLoading() {
  const [searchParams] = useSearchParams();
  const [doneTagging, setDoneTaggig] = useState<boolean>(false);
  const searchLoading = useRecoilValue(sidekickSearchLoading);
  const searchEstimatedLoadingTime = useRecoilValue(sidekickSearchEstimatedLoadingTime);
  const { progress: searchProgress, restart: restartProgress } = useFakeProgress({
    done: !searchLoading,
    estimatedSeconds: searchEstimatedLoadingTime,
    max: 0.99,
  });
  useEffect(() => {
    restartProgress();
  }, [searchLoading]);
  useEffect(() => {
    const done_tagging = searchParams.get("done_tagging") === "true" ? true : false;
    setDoneTaggig(done_tagging);
  }, [searchParams]);
  return (
    <>
      {!doneTagging || searchLoading ? (
        <div className="flex flex-col gap-1">
          <Spinner size="sm" wrapperClass="mt-[1px]" />
          <h6 className="font-semibold">{`${Math.ceil(
            (doneTagging ? searchProgress : 0) * 100,
          )}%`}</h6>
        </div>
      ) : null}
    </>
  );
}
