import classNames from "classnames";

interface RenderIncrOrDecrProps {
  value: number;
  compact?: boolean;
}
const RenderIncrOrDecr = ({ value, compact = false }: RenderIncrOrDecrProps) => {
  return value > 0 ? (
    <h6
      className={classNames(
        "flex items-center  space-x-1  text-xs text-gray-900",
        compact && "whitespace-nowrap",
      )}
    >
      <span className="grid min-h-[20px] min-w-[20px] place-items-center rounded-full bg-green-200">
        <i className="fa fa-arrow-up text-xs text-green-900" />
      </span>
      <span className="pr-2 text-green-700">{`${Math.round(value)}% `}</span>compared to previous{" "}
      year
    </h6>
  ) : value < 0 ? (
    <h6
      className={classNames(
        "flex items-center  space-x-1  text-xs text-gray-900",
        compact && "whitespace-nowrap",
      )}
    >
      <span className="grid min-h-[20px] min-w-[20px] place-items-center rounded-full bg-yellow-500">
        <i className="fa fa-arrow-down text-xs text-gray-700" />
      </span>
      <span className="pr-2 text-yellow-900">{`${Math.abs(Math.round(value))}% `} </span>compared to{" "}
      previous year
    </h6>
  ) : (
    <h6
      className={classNames(
        "flex items-center  space-x-1  text-xs text-gray-900",
        compact && "whitespace-nowrap",
      )}
    >
      <span className="mr-2 grid min-h-[20px] min-w-[20px] place-items-center rounded-full bg-gray-200">
        <i className="fa fa-equals text-xs text-gray-400" />
      </span>
      same to previous year
    </h6>
  );
};
export default RenderIncrOrDecr;
