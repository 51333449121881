function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
const currentDateString = (withoutTimezone = true) => {
  return withoutTimezone
    ? new Date().toISOString().replace(/\.\d{3}Z$/, "")
    : new Date().toISOString();
};

const getDateFromUTCDate = (date?: string): Date => {
  if (!date) return new Date();
  try {
    const year = +date.slice(0, 4);
    const month = +date.slice(5, 7) - 1;
    const day = +date.slice(8, 10);
    return new Date(year, month, day);
  } catch {
    return new Date();
  }
};

const convertToUTCDate = (date: Date | undefined): Date | undefined => {
  if (!date) return undefined;
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};
export { dateDiffInDays, currentDateString, getDateFromUTCDate, convertToUTCDate };
