import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import EmailVerifyView from "../EmailVerifyView";
import NoPage from "../NoPage";
import Layout from "../npo/Layout";
import Home from "../npo/Home";
import Donors from "../npo/Donors";
import FindFunder from "../npo/FindFunder";
import VersionView from "../VersionView";
import HiddenTest from "../HiddenTest";
import OnboardingView from "../npo/OnboardingView";
import ResetView from "../ResetView";
import DeleteView from "../DeleteView";
import DonorDetailView from "../donor-detail-view/DonorDetailView";
import WelcomeLayout from "../WelcomeLayout";
import PrivacyPolicyView from "../npo/PrivacyPolicyView";
import TermsAndConditionView from "../npo/TermsAndConditionsView";
import ProjectCreationNew from "../project-creation/ProjectCreationNew";
import ProjectList from "../project-creation/ProjectList";
import ProjectsLayout from "../npo/projects/ProjectsLayout";
import ProjectManage from "../../components/ProjectManage";
import ProjectSetting from "../npo/projects/ProjectSetting";
import ProjectDetail from "../npo/projects/ProjectDetail";
import ProjectTaskList from "../../components/ProjectTaskList";
import MyTasks from "../npo/projects/MyTasks";
import NpoLayout from "../npo/dashboard/NpoLayout";
import NpoView from "../npo/dashboard/pages/NpoView";
import VerificationView from "../VerificationView";
import MyProfile from "../npo/MyProfile";
import useOrgs from "../../hooks/useOrgs";
import { useEffect } from "react";
import useLogin from "../../hooks/useLogin";
import PublicProjectDetail from "../npo/projects/PublicProjectDetail";
import TestNPOFinancial from "../test/TestNPOFinancial";
import SearchResultView from "../SearchResultView";
import useOnboardingData from "../../hooks/useOnboarding";
import GrantView from "../npo/grant/GrantView";
import HowGibooWorksTestView from "../test/HowGibooWorksTestView";
import PastGrantView from "../npo/grant/PastGrantView";
import ProjectDocumentAI from "../npo/projects/ProjectDocumentAI";
import ProjectDocumentList from "../npo/projects/ProjectDocumentList";
import SavedListView from "../SavedListView";
import SavedItemListView from "../SavedItemListView";
import ResetGuidanceView from "../ResetGuidanceView";
import TestProgressView from "../test/TestProgressView";
import FinancialDetail from "../npo/financials/FinancialDetail";
import FinancialsIntro from "../npo/financials/FinancialsIntro";
import DiversityIntro from "../npo/diversity/DiversityIntro";
import DiversityDetail from "../npo/diversity/DiversityDetail";
import Feedback from "../Feedback";
import ReportBug from "../ReportBug";
import MediaView from "../MediaView";
import UnsubscribeView from "../UnsubscribeView";
import ProjectTaskDetail from "../../components/ProjectTaskDetail";
import AcceptInvitationView from "../AcceptInvitationView";
import TestSubtasksView from "../test/TestSubtasksView";
import TestProjectDataView from "../test/TestProjectDataView";

export function NPORoutes() {
  const { isLoading } = useLogin();
  const [onboardingData] = useOnboardingData();
  const [searchParams] = useSearchParams();
  const { orgs, setOrg } = useOrgs();
  useEffect(() => {
    const org_id = searchParams.get("org_id");
    if (org_id && orgs.find((o) => o._id === org_id) && onboardingData._id !== org_id) {
      setOrg(
        org_id,
        orgs.find((o) => o._id === org_id) && !orgs.find((o) => o._id === org_id)?.accepted,
      );
    }
  }, [searchParams, orgs, onboardingData]);
  return (
    <Routes>
      <Route path="/onboarding" element={<OnboardingView />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        {/* <Route path="search" element={<SearchView />} /> */}
        {/* new search */}
        {/* <Route path="search" element={<AdvanceSearchView />} /> */}
        <Route path="search" element={<SearchResultView />}>
          <Route path=":id" element={<SearchResultView />} />
        </Route>
        <Route path="profile" element={<MyProfile />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="report" element={<ReportBug />} />
        <Route path="accept_invitation">
          <Route path=":id" element={<AcceptInvitationView />} />
        </Route>
        <Route path="financial">
          <Route index element={<FinancialsIntro />} />
          <Route path="detail" element={<FinancialDetail />} />
        </Route>
        <Route path="diversity">
          <Route index element={<DiversityIntro />} />
          <Route path="detail" element={<DiversityDetail />} />
        </Route>
        <Route path="npo" element={<FinancialDetail />} />
        <Route path="saved">
          <Route index element={<SavedListView />} />
          <Route path=":id" element={<SavedItemListView />} />
        </Route>
        <Route path="projects">
          <Route index element={<ProjectList />} />
        </Route>
        <Route path="my-tasks" element={<MyTasks />} />
        <Route path="document" element={<ProjectsLayout />}>
          <Route index element={<ProjectDocumentAI />} />
          <Route path=":document_id" element={<ProjectDocumentAI />} />
        </Route>
        <Route path="project">
          <Route path="new" element={<ProjectCreationNew />} />
          <Route path="edit">
            <Route path=":id" element={<ProjectCreationNew isUpdate={true} />} />
          </Route>
          <Route path=":id" element={<ProjectsLayout />}>
            <Route index element={<ProjectManage />} />
            <Route path="task" element={<ProjectTaskList />} />
            <Route path="task/:task_id" element={<ProjectTaskDetail />} />
            <Route path="detail" element={<ProjectDetail />} />
            <Route path="setting" element={<ProjectSetting />} />
            <Route path="documents" element={<ProjectDocumentList />} />
            <Route path="document">
              <Route index element={<ProjectDocumentAI />} />
              <Route path=":document_id" element={<ProjectDocumentAI />} />
            </Route>
            <Route
              path="edit"
              element={<ProjectCreationNew isUpdate={true} inProjectLayout={true} />}
            />
          </Route>
        </Route>
        <Route path="public/project">
          <Route path=":id" element={<PublicProjectDetail />} />
        </Route>
        <Route path="npos">
          <Route path=":id" element={<NpoLayout />}>
            <Route index element={<NpoView />} />
          </Route>
        </Route>
        <Route path="organization" element={<NpoLayout isPrivateView showGuidance />}>
          <Route index element={<NpoView />} />
          <Route path=":id" element={<NpoLayout />}>
            <Route index element={<NpoView />} />
          </Route>
        </Route>
        <Route path="donors">
          <Route path=":id" element={<DonorDetailView />} />
        </Route>
        <Route path="grants">
          <Route path="past">
            <Route path=":id" element={<PastGrantView />} />
          </Route>
          <Route path=":id" element={<GrantView />} />
        </Route>
        {/* <Route path="profile" element={<Profile />} /> */}
        {/* TEST VIEW */}
        <Route path="admin/verification" element={<VerificationView />} />
        <>
          {process.env.REACT_APP_ENV === "DEV" && (
            <>
              <Route path="test/progress" element={<TestProgressView />} />
              <Route path="test/how" element={<HowGibooWorksTestView />} />
              <Route path="test/financial" element={<TestNPOFinancial />} />
              <Route path="test/subtasks" element={<TestSubtasksView />} />
              <Route path="test/edit_project" element={<TestProjectDataView />} />
            </>
          )}
        </>
      </Route>
      <Route path="/verify" element={<WelcomeLayout />}>
        <Route path=":signintoken" element={<EmailVerifyView />} />
      </Route>
      <Route path="/unsubscribe" element={<WelcomeLayout />}>
        <Route path=":token" element={<UnsubscribeView />} />
      </Route>
      <Route path="version" element={<VersionView />} />
      <>
        {process.env.REACT_APP_ENV === "DEV" && (
          <>
            <Route path="media">
              <Route path=":id" element={<MediaView />} />
            </Route>
            <Route path="delete" element={<DeleteView />} />
            <Route path="reset" element={<ResetView />} />
            <Route path="reset_guidance" element={<ResetGuidanceView />} />
            <Route path="testtest486" element={<HiddenTest />} />
          </>
        )}
      </>
      <Route path="privacy" element={<PrivacyPolicyView />} />
      <Route path="terms" element={<TermsAndConditionView />} />

      <Route path="*" element={<Layout />}>
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  );
}
