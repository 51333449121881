import { Dialog } from "@headlessui/react";
import PlainButton from "./PlainButton";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import CloseButton from "./CloseButton";
import IMG_WhyMatched from "../assets/images/whyMatched.svg";
import ProjectPickerModal from "./ProjectPickerModal";
import { IProject } from "../types/project";
import { FunderSearchResult, GrantSearchResult, SearchQuery } from "../types/search";
import { createProjectTask } from "../hooks/project/useTask";
import { createProject } from "../hooks/project/useProject";
import Spinner from "./Spinner";
import { useAppDispatch } from "../app/store";
import { appendProjectAsync } from "../app/projectSlice";
import useOrgID from "../hooks/useOrgID";
import {
  getProjectCreationRequestFromFunder,
  getProjectCreationRequestFromGrant,
} from "../utils/grant";
import Spacer from "./Spacer";
import useProjects from "../hooks/project/useProjects";
import GibooToast from "./GibooToast";
import ProjectInformationModal from "./ProjectInformationModal";
import { delay } from "lodash";
import { caseInsensitiveAndSpaceInsensitive } from "../utils/dataTypeHelper";
import { FROM_FOR_MIXPANEL, MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useGibooMixpanel, { GibooMixpanelProperties } from "../hooks/useGibooMixpanel";
import useGibooNavigate from "../hooks/useGibooNavigate";
interface StartFundRaisingModalProps {
  from_for_mixpanel: FROM_FOR_MIXPANEL;
  open: boolean;
  query?: SearchQuery;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCreation?: (data?: any) => void;
  funder?: FunderSearchResult;
  grant?: GrantSearchResult;
  id: string;
  type: "FUNDER" | "GRANT";
  urlPath?: string;
}
function StartFundRaisingModal({
  from_for_mixpanel,
  query,
  open,
  setOpen,
  onCreation,
  grant,
  funder,
  id,
  type,
}: StartFundRaisingModalProps) {
  const mxEvent = useGibooMixpanel(from_for_mixpanel, true);
  const navigate = useGibooNavigate(from_for_mixpanel, true);
  const [showProjectPickerModal, setShowProjectPickerModal] = useState<boolean>(false);
  const [showProjectCreationModal, setShowProjectCreationModal] = useState<boolean>(false);
  const { newProject, projects, isCreating } = useProjectCreation();
  const [showDefault, setShowDefault] = useState<boolean>(true);
  const org_id = useOrgID();
  const mxEventProperty: GibooMixpanelProperties = {
    targetType:
      type === "FUNDER"
        ? "funder"
        : type === "GRANT"
        ? grant?.type === "page-grant"
          ? "virtual_grant"
          : "grant"
        : undefined,
    targetId: type === "FUNDER" ? funder?._id : type === "GRANT" ? grant?._id : undefined,
    targetName: type === "FUNDER" ? funder?.name : type === "GRANT" ? grant?.name : undefined,
  };

  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.fundraising[""].started, mxEventProperty);
  }, []);
  const renderDescription = useCallback(() => {
    return (
      <div className={classNames("flex w-[704px] flex-col gap-3 rounded-[10px] bg-gray-50 p-4")}>
        <div className="inline-flex items-center gap-3">
          <span>
            <img src={IMG_WhyMatched} />
          </span>
          <p className="font-poppins text-sm font-semibold text-purple-500">
            Fundraising begins with defining your project details.{" "}
          </p>
        </div>
        <h6 className="font-poppins text-xs text-gray-800">
          A fundraising project is an organized effort to raise money for a specific cause,
          initiative, or program.
        </h6>
        <h6 className="font-poppins text-xs font-semibold text-purple-500">
          In the creation of a fundraising project, Giboo will assist you with fundraising planning,
          outreach execution, and task management in just a few simple steps.
        </h6>
        <h6 className="font-poppins text-xs text-gray-800">
          Define the purpose and details of the project and set specific goals. The more specific
          information you provide, the better outputs we can generate, including documents for
          potential funders.
        </h6>
      </div>
    );
  }, []);
  const renderContent = () => {
    const renderItem = (
      label = "",
      icon: ReactNode = <></>,
      onClick: () => void,
      disabled = false,
      defaultSelected = false,
    ) => {
      return (
        <div
          id={`btn-${caseInsensitiveAndSpaceInsensitive(label)}`}
          className={classNames(
            "group grid h-[164px] w-[172px] cursor-pointer place-items-center gap-4 rounded bg-white  px-3 py-5  active:bg-gray-100",
            !showDefault && "hover:bg-gray-50",
            disabled && "!cursor-not-allowed opacity-40",
            defaultSelected && "!bg-gray-50",
          )}
          onClick={onClick}
        >
          <div
            className={classNames(
              "grid h-[90px] w-[90px] border-spacing-40 items-center rounded-sm border border-gray-300 p-[30px] group-hover:border-dashed",
              defaultSelected && "!border-dashed",
            )}
          >
            <span className="grid h-[21px] w-[21px] items-center">{icon}</span>
          </div>
          <p className="text-center font-poppins text-xs font-semibold">{label}</p>
        </div>
      );
    };
    const handleOutreachDocument = () => {
      const hasProjects = projects.data.length > 0;
      const url = hasProjects ? `/project/${projects.data[0]._id}/document` : "/document";
      navigate(url, {
        state: {
          ...(hasProjects ? { showOutreach: true } : { showOutreachModal: true }),
          target_type: type === "FUNDER" ? 0 : 1,
          target_id: id,
          showBack: true,
          fromFundarisingModal: true,
        },
      });
    };
    return (
      <div className={classNames("mx-auto flex w-[680px] flex-col items-center")}>
        <div
          className="w flex justify-center gap-[37px]"
          onMouseMove={() => {
            showDefault && setShowDefault(false);
          }}
        >
          {renderItem(
            "Create a project",
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9952 4.5C15.4413 4.5 15.8029 4.86162 15.8029 5.30769L15.8029 24.6923C15.8029 25.1384 15.4413 25.5 14.9952 25.5C14.5491 25.5 14.1875 25.1384 14.1875 24.6923L14.1875 5.30769C14.1875 4.86162 14.5491 4.5 14.9952 4.5Z"
                fill="#1F1F1F"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.5 14.9991C25.5 15.4452 25.1384 15.8068 24.6923 15.8068L5.30769 15.8068C4.86162 15.8068 4.5 15.4452 4.5 14.9991C4.5 14.553 4.86162 14.1914 5.30769 14.1914L24.6923 14.1914C25.1384 14.1914 25.5 14.553 25.5 14.9991Z"
                fill="#1F1F1F"
              />
            </svg>,
            () => {
              setShowProjectCreationModal(true);
            },
            false,
            showDefault,
          )}
          {renderItem(
            "Add to an existing project",
            <span className="grid h-[21px] w-[21px] place-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M4.375 7.8125V10H10.1612C10.4098 9.99978 10.6481 9.90087 10.8238 9.725L12.7362 7.8125L10.8238 5.9C10.6481 5.72413 10.4098 5.62522 10.1612 5.625H6.5625C5.98234 5.625 5.42594 5.85547 5.0157 6.2657C4.60547 6.67594 4.375 7.23234 4.375 7.8125ZM2.5 7.8125C2.5 6.73506 2.92801 5.70175 3.68988 4.93988C4.45175 4.17801 5.48506 3.75 6.5625 3.75H10.1612C10.9067 3.75032 11.6216 4.04661 12.1488 4.57375L14.4525 6.875H23.4387C24.516 6.87533 25.549 7.30349 26.3106 8.06532C27.0722 8.82715 27.5 9.86027 27.5 10.9375V22.1875C27.5 22.721 27.3949 23.2493 27.1908 23.7422C26.9866 24.235 26.6874 24.6829 26.3101 25.0601C25.9329 25.4374 25.485 25.7366 24.9922 25.9408C24.4993 26.1449 23.971 26.25 23.4375 26.25H6.5625C5.48506 26.25 4.45175 25.822 3.68988 25.0601C2.92801 24.2983 2.5 23.2649 2.5 22.1875V7.8125ZM4.375 11.875V22.1875C4.375 23.395 5.355 24.375 6.5625 24.375H23.4375C24.0177 24.375 24.5741 24.1445 24.9843 23.7343C25.3945 23.3241 25.625 22.7677 25.625 22.1875V10.9375C25.625 10.3573 25.3945 9.80094 24.9843 9.3907C24.5741 8.98047 24.0177 8.75 23.4375 8.75H14.45L12.15 11.0512C11.8888 11.3124 11.5788 11.5196 11.2376 11.6609C10.8963 11.8023 10.5306 11.875 10.1612 11.875H4.375Z"
                  fill="#000000"
                />
              </svg>
            </span>,
            () => {
              setShowProjectPickerModal(true);
            },
            projects.data.length === 0,
          )}
          {renderItem(
            "Create an outreaching document",
            <svg
              width="30"
              height="30"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.70922 5.93058H3.65836C3.35141 5.93058 3.05702 6.05252 2.83997 6.26958C2.62292 6.48663 2.50098 6.78102 2.50098 7.08798V16.3472C2.50098 16.6542 2.62292 16.9486 2.83997 17.1656C3.05702 17.3827 3.35141 17.5046 3.65836 17.5046H14.0749C14.3818 17.5046 14.6762 17.3827 14.8933 17.1656C15.1103 16.9486 15.2322 16.6542 15.2322 16.3472V11.7176M2.50098 8.82409H10.024M8.86686 14.6111H10.6029M10.4594 6.46759C10.0543 6.39699 10.0543 5.81366 10.4594 5.74306C11.1789 5.61807 11.8449 5.28161 12.3724 4.77657C12.8999 4.27153 13.265 3.62081 13.4212 2.90741L13.4443 2.79514C13.5335 2.39352 14.104 2.39005 14.1966 2.79167L14.2256 2.92246C14.3876 3.6327 14.756 4.27918 15.2844 4.78063C15.8128 5.28209 16.4777 5.61612 17.1954 5.74074C17.6028 5.81134 17.6028 6.39815 17.1954 6.46991C16.4777 6.59453 15.8128 6.92856 15.2844 7.43001C14.756 7.93147 14.3876 8.57795 14.2256 9.28819L14.1955 9.41782C14.104 9.81828 13.5334 9.81597 13.4455 9.41435L13.4212 9.30439C13.265 8.59099 12.8999 7.94027 12.3724 7.43524C11.8449 6.9302 11.1789 6.59373 10.4594 6.46875V6.46759Z"
                stroke="#000000"
                strokeWidth="0.972213"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>,
            () => {
              // createNewProject();
              mxEvent(MIXPANEL_EVENTS_V2.fundraising[""].successful, {
                ...mxEventProperty,
                fundraisingType: "create a document",
              });
              handleOutreachDocument();
            },
          )}
        </div>
        {/* description */}
        {isCreating && <Spinner wrapperClass="mt-5" />}
        <Spacer height="38px" />
        {renderDescription()}
      </div>
    );
  };
  return (
    <>
      {id && (
        <ProjectPickerModal
          from_for_mixpanel={from_for_mixpanel}
          open={showProjectPickerModal}
          setOpen={setShowProjectPickerModal}
          target_id={id}
          target_type={type}
          search_query={query}
          onSelect={() => {
            mxEvent(MIXPANEL_EVENTS_V2.fundraising[""].successful, {
              ...mxEventProperty,
              fundraisingType: "add to an exisiting project",
            });
          }}
        />
      )}
      {showProjectCreationModal && (
        <ProjectInformationModal
          query={query}
          from_for_mixpanel={from_for_mixpanel}
          open={showProjectCreationModal}
          setOpen={setShowProjectCreationModal}
          id={""}
          withOutFunderGrant={id ? false : true}
          isNewProject
          target_id={id}
          target_type={type === "FUNDER" ? 0 : type === "GRANT" ? 1 : undefined}
          fromFundraising
          onProjectCreation={(res) => {
            mxEvent(MIXPANEL_EVENTS_V2.fundraising[""].successful, {
              ...mxEventProperty,
              fundraisingType: "create a project",
            });
            createProjectTask({
              project_id: res._id,
              target_id: id,
              target_type: type === "FUNDER" ? 0 : 1,
              ...(query ? { search_query: query } : {}),
            });
          }}
        />
      )}
      <Dialog open={open} onClose={() => null} className={classNames(" relative", `!z-[${8888}]`)}>
        <div
          className={classNames(
            "fixed inset-0 bg-black",
            showProjectPickerModal || showProjectCreationModal ? "bg-opacity-0" : "bg-opacity-80",
          )}
          aria-hidden="true"
        />
        <div className="fixed inset-0  grid w-screen place-items-center overflow-y-auto p-4 ">
          <Dialog.Panel className="mx-auto min-h-[571px] !w-[959px] rounded bg-white">
            <div className="flex h-full w-full flex-col px-7 pb-[40px] pt-7">
              <div className="flex justify-end">
                <PlainButton
                  id={`btn-update-image-close`}
                  handleOnClick={() => {
                    setOpen(false);
                  }}
                  leftIconClass={<CloseButton />}
                />
              </div>
              <div className="flex  w-full justify-center">
                <h5 className="font-poppins text-[20px] font-semibold text-black">
                  Start fundraising
                </h5>
              </div>
              <Spacer height="22px" />
              {renderContent()}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
export default StartFundRaisingModal;
interface IProjectCreation {
  funder?: FunderSearchResult;
  grant?: GrantSearchResult;
  query?: SearchQuery;
  type?: "FUNDER" | "GRANT";
  initialName?: boolean;
  id: string;
  callback?: (project: IProject) => void;
  locationState?: any;
  urlPath?: string;
}

// deprecated
export const useProjectCreation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const org_id = useOrgID();
  const [projects] = useProjects();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const newProject = ({
    type,
    funder,
    grant,
    query,
    id,
    initialName,
    callback,
    locationState,
    urlPath,
  }: IProjectCreation) => {
    setIsCreating(true);
    const data = grant
      ? getProjectCreationRequestFromGrant(grant, org_id, query)
      : funder
      ? getProjectCreationRequestFromFunder(funder, org_id, query)
      : {
          name: ``,
          org_id,
          completed: false,
          project_start: new Date().toISOString(),
        };
    const projectName = initialName ? `Untitled project (${projects.data.length})` : "";
    createProject({ ...data, name: projectName })
      .then((res: IProject) => {
        Promise.resolve(dispatch(appendProjectAsync(res))).then(() => {
          if (res) {
            callback?.(res);
            if ((grant || funder) && type) {
              createProjectTask({
                project_id: res._id,
                target_id: id,
                target_type: type === "FUNDER" ? 0 : 1,
                ...(query ? { search_query: query } : {}),
              })
                .then((taskRes) => {
                  navigate(`/project/${res._id}/${urlPath}`, {
                    state: {
                      fromFundraising: true,
                      target_type: type === "FUNDER" ? 0 : 1,
                      target_id: id,
                      showBack: true,
                      ...locationState,
                    },
                  });
                })
                .catch((err) => {
                  GibooToast({
                    type: "failure",
                    message: `We apologize for the inconvenience. Please try again.`,
                  });
                })
                .finally(() => {
                  setIsCreating(false);
                });
            }
          } else {
            setIsCreating(false);
          }
        });
      })
      .catch((err) => {
        setIsCreating(false);
        GibooToast({
          type: "failure",
          message: `We apologize for the inconvenience. Please try again.`,
        });
      });
  };
  return { newProject, projects, isCreating };
};
