import classNames from "classnames";
import { Popover, Whisper } from "rsuite";
import { INFO_FUNDER_TYPE_OPTION } from "./TagInfo";

export default function TagFunderType({
  type: _type,
  funder_type,
  npo_type,
  className,
}: {
  type?: string;
  funder_type?: string;
  npo_type?: string;
  className?: string;
}) {
  const type = funder_type ? funder_type : npo_type ? npo_type : _type;
  const tagColorVariant =
    type === "funding_intermediary"
      ? "border-[#3F4128] text-[#3F4128]"
      : type === "federal"
      ? "border-[#8E7725] text-[#8E7725]"
      : type === "state"
      ? "border-[#254C62] text-[#254C62]"
      : type === "local"
      ? "border-[#2F3EC5] text-[#2F3EC5]"
      : type === "business"
      ? "border-[#258E3C] text-[#258E3C]"
      : type === "corporate"
      ? "border-[#EB7B73] text-[#EB7B73]"
      : type === "community"
      ? "border-[#3E4188] text-[#3E4188]"
      : type === "family"
      ? "border-[#B63CB9] text-[#B63CB9]"
      : type === "private"
      ? "border-[#0096A0] text-[#0096A0]"
      : type === "private_operating"
      ? "border-[#9A3832] text-[#9A3832]"
      : type === "public"
      ? "border-[#258E3C] text-[#258E3C]"
      : type === "daf"
      ? "border-[#3C67FF] text-[#3C67FF]"
      : type === "federated_giving"
      ? "border-[#740A86] text-[#740A86]"
      : type === "service_provider"
      ? "border-[#CC5F10] text-[#CC5F10]"
      : type === "field_builder"
      ? "border-[#C13EFF] text-[#C13EFF]"
      : type === "unknown"
      ? "border-gray-700 text-gray-700"
      : "border-[#0ABBBB] text-[#0ABBBB]";
  const iconVariant =
    type === "funding_intermediary"
      ? "gi gi-funding_intermediary"
      : type === "federal"
      ? "gi gi-federal"
      : type === "state"
      ? "gi-sm gi-state"
      : type === "local"
      ? "gi gi-local"
      : type === "business"
      ? "gi gi-corporation-business"
      : type === "corporate"
      ? "gi gi-corporate"
      : type === "community"
      ? "gi-sm gi-funder"
      : type === "family"
      ? "gi-sm gi-gift"
      : type === "private"
      ? "gi gi-private"
      : type === "private_operating"
      ? "gi gi-private_operating"
      : type === "public"
      ? "gi gi-public"
      : type === "daf"
      ? "gi gi-daf"
      : type === "federated_giving"
      ? "gi gi-federated_giving"
      : type === "service_provider"
      ? "gi gi-service_provider"
      : type === "field_builder"
      ? "gi-sm gi-field_builder"
      : type === "unknown"
      ? " "
      : "gi-sm gi-hybrid";
  const name =
    type === "funding_intermediary"
      ? "Funding intermediary"
      : type && ["federal", "state", "local"].includes(type)
      ? `${type[0].toUpperCase()}${type.slice(1)} agency`
      : type === "business"
      ? "Corporation and business"
      : type === "corporate"
      ? "Corporate foundation"
      : type === "community"
      ? "Community foundation"
      : type === "family"
      ? "Family foundation"
      : type === "private"
      ? "Private foundation"
      : type === "private_operating"
      ? "Private operating foundation"
      : type === "public"
      ? "Public foundation"
      : type === "daf"
      ? "Donor-advised fund (DAF)"
      : type === "federated_giving"
      ? "Federated giving program"
      : type === "service_provider"
      ? "Direct service provider"
      : type === "field_builder"
      ? "Field builder"
      : type === "unknown"
      ? "Funder"
      : "Hybrid nonprofit";
  const renderItem = () => {
    return (
      <div
        className={classNames(
          "flex h-6 w-fit items-center gap-1 whitespace-nowrap rounded-[20px] border bg-gray-50 px-2",
          tagColorVariant,
          className,
        )}
        aria-valuetext={funder_type}
      >
        <i className={classNames("gi", iconVariant)} />
        <h6 aria-valuetext={funder_type}>{name}</h6>
      </div>
    );
  };
  return (
    <>
      <Whisper
        trigger={"hover"}
        placement={"rightStart"}
        className="shadow-none"
        speaker={
          <Popover
            id="hover_tooltip"
            prefix="tooltip-hover-search-plus"
            className={classNames(
              "!z-[9999] !mt-[-2px] w-[323px] rounded border border-gray-300 bg-gray-50 p-4",
            )}
            arrow={false}
            aria-valuetext={funder_type}
          >
            {INFO_FUNDER_TYPE_OPTION[type || ""]}
          </Popover>
        }
      >
        {renderItem()}
      </Whisper>
    </>
  );
}
