import { useCallback } from "react";
import { IProjectInteraction, IProjectInteractionUpdateRequest } from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { useAppDispatch } from "../../app/store";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface ProjectInteractionState {
  data: IProjectInteraction[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IProjectInteraction[] | undefined>;
}
const emptyList: IProjectInteraction[] = [];
function useInteractionsOfProject(project_id?: string): ProjectInteractionState {
  const url =
    process.env.REACT_APP_API_URL + `/api/v2/projects/interactions?project_id=${project_id}`;
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectInteraction[]>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => res.data as IProjectInteraction[])
      .then((res) =>
        res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
      );
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectInteraction[]>(
    project_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<IProjectInteraction[]>(url), [url]);
  return {
    data: data || emptyList,
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useInteractionsOfProject;
