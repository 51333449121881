import axios from "axios";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import config from "../../api";
import { SearchQuery, SearchType, UnifiedSearchResult } from "../../types/search";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
import { filterUnmatched, matchTaxonomyBySearchQuery } from "../../types/taxonomy";
import { prettierTaxonomy } from "../../utils/philanthropy";
import useCancellableSWR from "../useCancellableSWR";
import {
  queryMaskForCurrentGrantSearchWithSpecificFunder,
  queryMaskForFunderSearch,
  queryMaskForGrantSearch,
  queryMaskForNPOSearch,
  queryMaskForNameSearch,
} from "../useGibooStructureSearch";
import useOrgID from "../useOrgID";
import useUser from "../useUser";

interface IHide {
  hide: boolean;
  uid: number;
}
interface HidingState {
  dataWithQuery: number[];
  data: number[];
  detail: UnifiedSearchResult[];
  detailWithQuery: UnifiedSearchResult[];
  isLoading: boolean;
  isValidating: boolean;
  error: object;
  setHide: (id: number, hide: boolean) => Promise<number[] | void>;
}

const emptyList: number[] = [];
const emptyDetailList: UnifiedSearchResult[] = [];
export default function useHiddenSearchItems({
  type,
  use_query = false,
  query,
  fetch_detail = false,
  search_by_name = false,
  enable = true,
  skip = 0,
  limit = 20,
}: {
  type: SearchType;
  use_query?: boolean;
  query?: SearchQuery;
  fetch_detail?: boolean;
  search_by_name?: boolean;
  enable?: boolean;
  skip?: number;
  limit?: number;
}): HidingState {
  const key =
    type === "specific_funder_grant"
      ? "grants_specific_funder"
      : type === "virtual_grant"
      ? "grants"
      : type === "funder"
      ? "donors"
      : type === "eligible_npo"
      ? "npos"
      : `${type.replaceAll("_", "")}s`;

  const queryMask =
    type === "funder"
      ? queryMaskForFunderSearch
      : type === "npo"
      ? queryMaskForNPOSearch
      : type === "grant" || type === "virtual_grant"
      ? queryMaskForGrantSearch
      : type === "specific_funder_grant"
      ? queryMaskForCurrentGrantSearchWithSpecificFunder
      : type === "eligible_npo"
      ? queryMaskForNPOSearch
      : queryMaskForNameSearch;
  const maskForGrant =
    type === "grant"
      ? { grant_type: ["opencall"] }
      : type === "virtual_grant"
      ? { grant_type: ["virtual"] }
      : {};
  const [user] = useUser();
  const org_id = useOrgID();
  const url =
    user?._id && org_id
      ? process.env.REACT_APP_API_URL +
        `/api/v2/search/${key}/hidden?user_id=${user?._id}&org_id=${org_id}${
          type === "virtual_grant" ? "&virtual=true" : ""
        }`
      : null;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<number[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as number[]);
  };
  const { data, isLoading, error, isValidating } = useSWR<number[]>(
    enable && url ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const url_with_query =
    user?._id && org_id && use_query && query
      ? `gss_${type[0]}_hidden_with_query` +
        JSON.stringify({
          ...query,
          // hidden_uids: data,
          ...(search_by_name ? queryMaskForNameSearch : queryMask),
          ...maskForGrant,
          search_by_name: search_by_name,
        })
      : null;
  const fetchWithQuery = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(23));
        return axios
          .post(
            process.env.REACT_APP_API_URL +
              `/api/v2/search/${key}/hidden?user_id=${req.user_id}&org_id=${req.org_id}${
                type === "virtual_grant" ? "&virtual=true" : ""
              }`,
            {
              ...req,
              hidden_uids: [],
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => res.data as number[]);
      } catch {
        return new Promise<number[]>((resolve, reject) => reject());
      }
    },
    [key],
  );

  const fetchDetailWithQuery = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(12));
        const valid = Object.keys(req).length > 0 ? true : false;
        return axios
          .post(
            process.env.REACT_APP_API_URL +
              `/api/v2/search/${key}/hidden/detail?user_id=${req.user_id}&org_id=${req.org_id}${
                type === "virtual_grant" ? "&virtual=true" : ""
              }`,
            {
              ...req,
              hidden_uids: [],
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => res.data.map((d: any) => asSearchResult(d, req, req.search_by_name)));
      } catch {
        return new Promise<UnifiedSearchResult[]>((resolve, reject) => reject());
      }
    },
    [key],
  );
  const fetchDetail = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(12));
        const valid = Object.keys(req).length > 0 ? true : false;
        return axios
          .post(
            process.env.REACT_APP_API_URL +
              `/api/v2/search/${key}/hidden/detail?user_id=${req.user_id}&org_id=${
                req.org_id
              }&without_query=true${type === "virtual_grant" ? "&virtual=true" : ""}`,
            {
              ...req,
              hidden_uids: [],
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => res.data.map((d: any) => asSearchResult(d, req, req.search_by_name)));
      } catch {
        return new Promise<UnifiedSearchResult[]>((resolve, reject) => reject());
      }
    },
    [key],
  );
  const {
    data: dataWithQuery,
    isLoading: isLoadingWithQuery,
    error: errorWithQuery,
    isValidating: isValidatingWithQuery,
  } = useCancellableSWR<number[]>(
    enable && url_with_query && use_query && query ? url_with_query : null,
    fetchWithQuery,
    swrOptionFetchOnlyOnMount,
  );
  const {
    data: dataDetail,
    isLoading: isLoadingDataDetail,
    error: errorDataDetail,
    isValidating: isValidatingDataDetail,
  } = useCancellableSWR<UnifiedSearchResult[]>(
    enable && fetch_detail && query
      ? `gss_${type[0]}_hidden` +
          JSON.stringify({
            ...query,
            // hidden_uids: data,
            pagination_skip: skip,
            pagination_limit: limit,
            ...queryMaskForNameSearch,
            ...maskForGrant,
            search_by_name: search_by_name ? true : undefined,
          })
      : null,
    fetchDetail,
    swrOptionFetchOnlyOnMount,
  );
  const {
    data: dataDetailWithQuery,
    isLoading: isLoadingDetailWithQuery,
    error: errorDetailWithQuery,
    isValidating: isValidatingDetailWithQuery,
  } = useCancellableSWR<UnifiedSearchResult[]>(
    enable && fetch_detail && query
      ? `gss_${type[0]}_hidqry` +
          JSON.stringify({
            ...query,
            // hidden_uids: data,
            pagination_skip: skip,
            pagination_limit: limit,
            ...(search_by_name ? queryMaskForNameSearch : queryMask),
            ...maskForGrant,
            search_by_name: search_by_name ? true : undefined,
          })
      : null,
    fetchDetailWithQuery,
    swrOptionFetchOnlyOnMount,
  );
  const setHide = useCallback(
    async (id: number, hide: boolean) => {
      const mutateWithOptimisticData = (target: number[] | undefined, targetURL: string | null) => {
        if (!targetURL || !target) return;
        const options = {
          optimisticData: hide
            ? [...target.filter((i) => i !== id), id]
            : target.filter((i) => i !== id),
          rollbackOnError: true,
        };
        return mutate<number[]>(
          targetURL,
          axios
            .put(
              process.env.REACT_APP_API_URL +
                `/api/v2/search/${
                  key === "grants_specific_funder" ? "grants" : key
                }/hidden/${id}?user_id=${user?._id}&org_id=${org_id}`,
              { hide: hide },
              config,
            )
            .then((response) => response.data as IHide)
            .then((res) => [...target.filter((i) => i !== id), ...(res?.hide ? [id] : [])]),
          options,
        );
      };
      mutateWithOptimisticData(dataWithQuery, url_with_query);
      return mutateWithOptimisticData(data, url);
    },
    [use_query, key, url_with_query, url, data, dataWithQuery, user, org_id],
  );
  const asSearchResult = useCallback(
    (i: any, query: SearchQuery, search_by_name = false) => ({
      ...i,
      search_type:
        type === "specific_funder_grant" ? "grant" : type === "eligible_npo" ? "npo" : type,
      search_by_name,
      ...prettierTaxonomy(
        filterUnmatched(
          matchTaxonomyBySearchQuery(
            i,
            query,
            i.service_specific_loc,
            type === "funder" || (type === "grant" && i.type === "grant-page") ? false : true,
          ),
        ),
        query,
      ),
    }),
    [type],
  );
  return {
    dataWithQuery: dataWithQuery || emptyList,
    data: data || emptyList,
    detail: dataDetail || emptyDetailList,
    detailWithQuery: dataDetailWithQuery || emptyDetailList,
    isLoading: isLoadingDetailWithQuery || isLoadingDataDetail || isLoadingWithQuery || isLoading,
    isValidating:
      isValidatingDetailWithQuery ||
      isValidatingDataDetail ||
      isValidatingWithQuery ||
      isValidating,
    error: errorDetailWithQuery || errorDataDetail || errorWithQuery || error,
    setHide,
  };
}
