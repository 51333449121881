import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import { toUsdLong, toUsdShort } from "../../../../utils/formatHelper";
import useNPOIRSProfile, { Revenue } from "../../../../hooks/useNPOIRSProfile";
import NoGraph from "../../../donor-detail-view/components/NoGraph";
import RenderIncrOrDecr from "../../../donor-detail-view/components/RenderIncrOrDecr";
import { Divider } from "rsuite";
import IncreaseTag from "../../financials/component/IncreaseTag";
import SmallestProportionTag from "../../financials/component/SmallestProportionTag";
import LargestProportionTag from "../../financials/component/LargestProportionTag";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import DecreaseTag from "../../financials/component/DecreaseTag";
import Spacer from "../../../../components/Spacer";
import FinancialChartLoader from "../../financials/component/FinancialChartLoader";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import PercentageTag from "../../../../components/PercentageTag";
import PredictionIcon from "../../../../assets/financial/predictionIcon.svg";
import ICON_PREDICT from "../../../../assets/financial/preditction.svg";
import ICON_ESTIMATE from "../../../../assets/financial/estimate.svg";
import AssessmentResult from "../../../../components/AssessmentResult";
import PopupModal from "../../../../components/tailwind/PopupModal";
import { RevenueStreamInfo } from "../../financials/pages/RevenueStream";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { NpoTitle } from "./NpoDiversityEthnicity";
import FinancialPredictionInfo from "./FinancialPredictionInfo";
interface Iprops {
  npo_id?: string;
  org_id?: string;
  allowEdit?: boolean;
  isPrivateView?: boolean;
  compact?: boolean;
  recomendation?: boolean;
  showStats?: boolean;
  showInfo?: boolean;
  hideTitle?: boolean;
  children?: ReactNode;
  showChartLoader?: boolean;
}
function NpoFinancialRevenueStream({
  npo_id,
  org_id,
  allowEdit,
  isPrivateView,
  compact,
  recomendation,
  showInfo = true,
  showStats = true,
  hideTitle = false,
  children,
  showChartLoader = true,
}: Iprops) {
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { revenueModel, revenueStreamInfo, revenueStream, isLoading, latest, beforeLatest } =
    useNPOIRSProfile(npo_id);
  const { data: revenue, isLoading: isRevenueLoading } = useFinancialRecommendedActions(
    !isPrivateView && loading ? undefined : !isPrivateView && !org_id ? npo_id : org_id,
    "revenue",
    !isPrivateView && !org_id,
  );
  const maxPortion = revenueModel
    ? [revenueModel.contributions, revenueModel.investment, revenueModel.operation].reduce(
        (prev, cur) => Math.max(prev, cur),
        revenueModel.contributions,
      )
    : -1;
  const minPortion = revenueModel
    ? [revenueModel.contributions, revenueModel.investment, revenueModel.operation].reduce(
        (prev, cur) => Math.min(prev, cur),
        revenueModel.contributions,
      )
    : -1;
  const renderContributionGrants = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Contribution and grants</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={revenueStreamInfo?.diff_contributions}
            beforeLatest={beforeLatest?.contributions}
            latest={latest?.contributions}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {revenueModel ? (
              maxPortion === revenueModel.contributions ? (
                <LargestProportionTag />
              ) : minPortion === revenueModel.contributions ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {revenue?.Contributions_and_Grants?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              {revenue?.Contributions_and_Grants?.recommendations || ""}
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderInvestmentRevenue = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Investment revenue</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={revenueStreamInfo?.diff_investment}
            beforeLatest={beforeLatest?.investment}
            latest={latest?.investment}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {revenueModel ? (
              maxPortion === revenueModel.investment ? (
                <LargestProportionTag />
              ) : minPortion === revenueModel.investment ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {revenue?.Investment?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">{revenue?.Investment?.recommendations || ""}</div>
          </div>
        )}
      </div>
    );
  };
  const renderOperationRevenue = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Operation revenue</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={revenueStreamInfo?.diff_operation}
            beforeLatest={beforeLatest?.operation}
            latest={latest?.operation}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {revenueModel ? (
              maxPortion === revenueModel.operation ? (
                <LargestProportionTag />
              ) : minPortion === revenueModel.operation ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {revenue?.Operation?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">{revenue?.Operation?.recommendations || ""}</div>
          </div>
        )}
      </div>
    );
  };
  const chartLoading = recomendation ? loading : isLoading;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-5"
        showHeader={false}
        showActionButtons={false}
      >
        <RevenueStreamInfo
          handleClose={() => {
            setShowInfoModal(false);
          }}
        />
      </PopupModal>
      <div
        className={classNames(
          "flex min-h-[100px] flex-col ",
          recomendation && "rounded border border-gray-300 bg-white p-5",
        )}
      >
        {!hideTitle && (
          <NpoTitle
            title="Revenue stream"
            recomendation={recomendation}
            setShowInfoModal={setShowInfoModal}
          />
        )}
        {showChartLoader && chartLoading ? (
          <FinancialChartLoader title="Analyzing organization’s Revenue stream" />
        ) : (
          <>
            {/* chart */}
            <div className="mx-auto my-5">
              <RevenueStreamChart
                revenueStream={revenueStream}
                npo_id={npo_id}
                wrapperClass={""}
                compact
              />
            </div>
            {/* info */}
            {showInfo &&
              (isRevenueLoading || loading ? (
                <MemoizedLoadingRecommendedAction loading={loading || isRevenueLoading} short />
              ) : (
                <AssessmentResult content={revenue?.Summary || ""} />
              ))}
            {recomendation ? <Divider className="my-[40px]" /> : <Spacer height="20px" />}
            {/* stats */}
            {showStats &&
              (isRevenueLoading || loading ? (
                <MemoizedLoadingRecommendedAction loading={loading || isRevenueLoading} />
              ) : (
                <div className="bg-gray-50 px-6 py-5">
                  <h6 className="mb-5 font-poppins text-base font-semibold text-black">Stats</h6>
                  <div className="flex flex-col gap-10">
                    {renderContributionGrants()}
                    {renderInvestmentRevenue()}
                    {renderOperationRevenue()}
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      {!chartLoading && children && children}
    </>
  );
}
export default NpoFinancialRevenueStream;

export function RevenueStreamChart({
  npo_id,
  wrapperClass,
  compact,
  revenueStream,
}: {
  revenueStream?: Revenue[];
  npo_id?: string;
  wrapperClass?: string;
  compact?: boolean;
}) {
  const renderChart2 = () => {
    const foreCastCount = revenueStream?.reduce((count, item) => {
      return item.is_predicted ? count + 1 : count;
    }, 0);
    const estimatedData = revenueStream?.filter((i) => i.is_interpolated);
    const predictedData = revenueStream?.filter((i) => i.is_predicted);
    const chartSeries: ApexAxisChartSeries = [
      {
        name: "contributions",
        type: "line",
        data: revenueStream?.map((i) => i.contributions) || [],
        color: "#27678A",
      },
      {
        name: "investments",
        type: "line",
        data: revenueStream?.map((i) => i.investment) || [],
        color: "#7EAB67",
      },
      {
        name: "operation",
        type: "line",
        data: revenueStream?.map((i) => i.operation) || [],
        color: "#ECD332",
      },
    ];
    const chartOptions: ApexOptions = {
      chart: {
        toolbar: { show: false },
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => toUsdShort(Number(val), 1),
        distributed: true,
        offsetY: -8,
        offsetX: -5,
        textAnchor: "start",
        background: { enabled: false },
        style: {
          colors: ["#9B9B9B"],
          fontSize: "10px",
          fontFamily: "poppins",
          fontWeight: "400px",
        },
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 4,
        borderColor: "#DEDEDE",
        row: {
          colors: ["transparent"],
          opacity: 1,
        },
      },
      xaxis: {
        categories: revenueStream?.map((i) => i.tax_year) || [],
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "poppins",
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#DEDEDE",
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "poppins",
            fontWeight: 400,
          },
          formatter(val, opts) {
            return toUsdLong(val);
          },
        },
      },
      stroke: {
        curve: "monotoneCubic",
        width: 2,
        colors: ["#27678A", "#7EAB67", "#ECD332"],
      },
      forecastDataPoints: {
        count: foreCastCount,
        strokeWidth: undefined,
        dashArray: 4,
      },
      markers: {
        size: 4.5,
        shape: "circle",
        hover: {
          size: 4.5,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const data = revenueStream?.[dataPointIndex];
          const renderTitle = (value: string) => {
            return '<span class="font-poppins font-normal text-gray-700">' + value + "</span>";
          };
          const renderValue = (value: string) => {
            return '<span class="font-poppins font-semibold text-gray-900">' + value + "</span>";
          };
          if (data) {
            return (
              '<div class="giboo-box flex flex-col gap-1">' +
              '<p class="font-poppins font-semibold text-gray-900">' +
              data.tax_year +
              (data.is_predicted ? " (Predicted)" : data.is_interpolated ? " (Estimated)" : "") +
              "</p>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-[#27678A]">' +
              '<span class=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-[#27678A]"></span>' +
              "</span>" +
              renderTitle("Contributions and grants:") +
              renderValue(toUsdLong(data.contributions || 0)) +
              "</div>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-[#7EAB67]">' +
              '<span class=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-[#7EAB67]"></span>' +
              "</span>" +
              renderTitle("Investment revenue:") +
              renderValue(toUsdLong(data?.investment || 0)) +
              "</div>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-[#ECD332]">' +
              '<span class=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-[#ECD332]"></span>' +
              "</span>" +
              renderTitle("Operation revenue:") +
              renderValue(toUsdLong(data?.operation || 0)) +
              "</div>" +
              "</div>"
            );
          }
        },
      },
    };

    return (
      <>
        <Chart options={chartOptions} series={chartSeries} type="line" width="600" />
        <div className="flex items-center  justify-center gap-4">
          {estimatedData && estimatedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-purple-100 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-estimated text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Estimated years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {estimatedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
          {predictedData && predictedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-dashed border-purple-500 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-predicted text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Predicted years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {predictedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <FinancialPredictionInfo />
        </div>
      </>
    );
  };
  const renderTitle = () => {
    return (
      <div className="flex items-center justify-end gap-3">
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-[#27678A]">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-[#27678A]" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Contributions and grants</h6>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-green-500">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-green-500" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Investment revenue</h6>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-yellow-500">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-yellow-500" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Operation revenue</h6>
        </div>
      </div>
    );
  };

  if (revenueStream?.length === 0) return <NoGraph />;
  return (
    <div
      className={classNames("flex h-fit w-full flex-col items-center justify-center", wrapperClass)}
    >
      <div className="grid w-full max-w-[700px] place-items-center items-start">
        {renderTitle()}
        {/* {renderChart()} */}
        {renderChart2()}
      </div>
    </div>
  );
}
