import classNames from "classnames";
import { Dispatch, SetStateAction } from "react";
import DropdownSelector from "./DropdownSelector";

interface PlainDropdownSelectorProps {
  id: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green" | "gray";
  data: string[];
  selected: string | undefined;
  setSelected: Dispatch<SetStateAction<string | undefined>>;
  maxHeight?: string;
  openAnimation?: boolean;
  placeholder?: string;
  disabled?: boolean;
  float?: boolean;
  compact?: boolean;
  downIcon?: boolean;
  leftIconClass?: string;
  rightIconClass?: string;
  defaultValue?: string;
  topPlaceholder?: string;
}
function PlainDropdownSelector({
  id,
  color,
  data,
  selected,
  setSelected,
  maxHeight,
  openAnimation = false,
  placeholder,
  disabled = false,
  float = false,
  compact = false,
  downIcon = false,
  leftIconClass,
  rightIconClass,
  defaultValue,
  topPlaceholder,
}: PlainDropdownSelectorProps) {
  return (
    <div className="relative h-[94px] w-full">
      {topPlaceholder ? (
        <div className="py-2 font-poppins text-base font-semibold text-gray-900">
          {topPlaceholder}
        </div>
      ) : null}
      <DropdownSelector
        id={id}
        color={color}
        data={data}
        selected={selected}
        setSelected={setSelected}
        maxHeight={maxHeight}
        openAnimation={openAnimation}
        placeholder={placeholder}
        disabled={disabled}
        float={float}
        compact={compact}
        defaultValue={defaultValue}
        getKey={(item) => item}
        renderItem={(item) => (
          <div>
            {leftIconClass && <i className={leftIconClass}></i>}
            <span className="mx-2">{item}</span>
            {rightIconClass && <i className={rightIconClass}></i>}
          </div>
        )}
        renderButton={(item) => (
          <div className="flex justify-between">
            <div>
              {leftIconClass && <i className={leftIconClass}></i>}
              <span className="mx-2">{item}</span>
              {rightIconClass && <i className={rightIconClass}></i>}
            </div>
            {downIcon && (
              <i className={classNames("gi gi-angle-down", color)} />
            )}
          </div>
        )}
      />
    </div>
  );
}
export default PlainDropdownSelector;
