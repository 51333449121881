import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../api";
import { ReactNode, useCallback } from "react";
import { FunderSummary } from "../types/funder";
import { swrOptionFetchOnlyOnMount } from "../types/swr";
interface FunderSummaryState {
  data: FunderSummary | undefined;
  error: any;
  isLoading: boolean;
}
function useFunderSummary(funder_id?: string): FunderSummaryState {
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<FunderSummary>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data?.summary);
  };
  const { data, isLoading, error } = useSWR<FunderSummary>(
    funder_id ? process.env.REACT_APP_API_URL + `/api/v2/donors/${funder_id}/summary` : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );

  return {
    data: data,
    isLoading,
    error,
  };
}
export default useFunderSummary;
