import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MIX_PANEL_TOKEN } from "../App";
import mixpanel from "mixpanel-browser";
import uuid from "react-uuid";
import {
  Buttons,
  FROM_FOR_MIXPANEL,
  Information,
  Navigate,
  SidekickLocation,
} from "../mixpanel/mixpanel";
import useOnboardingData from "./useOnboarding";
import useEINFromSearchParams from "./useEINFromSearchParams";
import { GibooSidekickGAValue } from "../types/sidekick";
import { SearchQuery, SearchType } from "../types/search";

export interface GibooMixpanelProperties {
  //common
  skip?: boolean;
  session?: string;
  field?: Information;
  to?: Navigate;
  button?: Buttons;
  error?:
    | "api error"
    | "failed to upload to s3"
    | "wrong email"
    | "wrong email or password"
    | "tried to invite an existing member"
    | "tried to signup with an existing email";
  // query?: string;
  value?: string;
  location?: string;

  // user
  sigininMethod?: "password" | "google" | "google_onetab";
  userTitle?: string;

  // search
  search_session?: string;
  searchQuery?: SearchQuery;

  // org
  // organizationId?: string; // will be filled automatically
  orgType?: "us" | "outside_us" | "not_launched" | "us_but_not_found_in_data";
  npoId?: string;
  organizationName?: string;
  organizationEIN?: string;
  organizationCountry?: string;
  organizationState?: string;
  organizationCity?: string;
  organizationAddress?: string;
  orgStage?: string;
  orgMission?: string;
  orgOriginalMission?: string;
  orgMissionIsModified?: boolean;
  orgFocusArea?: string;
  orgBeneficiary?: string;
  orgProgram?: string;
  tags?: {
    type: "focus_area" | "beneficiary" | "program";
    name: string;
    priority?: "good_to_have" | "must_have" | "exclude";
  }[];
  locations?: {
    type: "hq_location" | "service_location";
    name: string;
    priority?: "good_to_have" | "must_have" | "exclude";
  }[];

  // file
  fileName?: string;
  fileSize?: number;

  // ask giboo ai
  ask_giboo_ai_target?: "focus_area" | "beneficiary" | "program" | "summary";
  ask_giboo_ai_selected?: "suggestion" | "original";

  // tags or location
  type?: "focus_area" | "beneficiary" | "program" | "service_location" | "hq_location";
  name?: string;
  own?: boolean;

  // invitation
  invitationType?: "organization" | "project";
  email?: string;
  projectId?: string;

  // how giboo works
  howGibooWorksCompleted?: number;
  howGibooWorksTotal?: number;

  // invited
  invitedEIN?: string;
  invitedFirstname?: string;
  invitedOrganizationName?: string;

  // project
  projectName?: string;

  // assessment
  recommendedAction?: "find unrestricted funding" | "recommended grant opportunities";

  // image
  imageType?: string;

  // similar org
  targetType?: "npo" | "funder" | "grant" | "virtual_grant" | "past_grant";
  targetId?: string;
  targetName?: string;

  //fundraising
  fundraisingType?: "create a project" | "add to an exisiting project" | "create a document";

  //documentai
  documentaiId?: string;
  message?: string;

  //sidekick
  sidekickLocation?: SidekickLocation;
  sidekickButton?: GibooSidekickGAValue;
  doNotShowAgain?: boolean;

  //search
  queryType?: "direct" | "recent" | "other_recent_searches" | "try_searching_for";
  searchType?: SearchType | string | null;
  textQuery?: string;
  filterName?:
    | "search_plus"
    | "focus_area"
    | "beneficiary"
    | "program"
    | "service_location"
    | "hq_location"
    | "any_location"
    | "sort"
    | "reset_default"
    | "clear_all"
    | "solicitation"
    | "previous_funder"
    | "funder_type"
    | "npo_type"
    | "grant_amount"
    | "funding_size"
    | "asset_size"
    | "stage"
    | "grant_category"
    | "deadline"
    | "contact"
    | "incorporated_status"
    | "incorporated_year"
    | "tax_exempt_status"
    | "npo_asset_size"
    | "npo_fund_range"
    | "npo_advanced_filter"
    | "month_of_liquid";
  filterValue?: any;
  filterAction?: "set must have" | "set good to have" | "set exclude" | "uncheck" | "check";

  // invited user session
  cid?: string;
}
function useGibooMixpanel(specificFrom?: FROM_FOR_MIXPANEL, useSession = false) {
  const { ein, firstname } = useEINFromSearchParams();
  const [onboardingData] = useOnboardingData();
  const { state: context } = useLocation();
  const [session, setSession] = useState<string | undefined>(useSession ? uuid() : undefined);
  const mxEvent = useCallback(
    (eventName: string, properties: GibooMixpanelProperties = {}) => {
      if (!MIX_PANEL_TOKEN) return;
      mixpanel.track(eventName, {
        ...properties,
        organizationId: onboardingData._id,
        from: specificFrom || context?.from_for_mixpanel,
        from_url: context?.from_url_for_mixpanel,

        // session is not important, it's just a trying.
        // mixpanel support identifying session using identifier and timestamp
        session: useSession
          ? (properties as any)?.session || context?.session_for_mixpanel || session
          : undefined,
        ...(ein
          ? {
              invitedEIN: ein,
              invitedFirstname: firstname,
              invitedOrganizationName: onboardingData.npo_name,
            }
          : {}),
      });
    },
    [onboardingData, context, mixpanel, session, specificFrom, useSession, ein, firstname],
  );
  return mxEvent;
}
export default useGibooMixpanel;
