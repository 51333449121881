import axios from "axios";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import config from "../../api";
import { prettyListStrings, toUsdLong } from "../../utils/formatHelper";
import "../css/box.scss";
import starBullet from "../css/star_bullet.svg";
import classNames from "classnames";
import Label from "../Label";
import SecondaryButton from "../SecondaryButton";
import { useNavigate } from "react-router-dom";
import useOnboardingData from "../../hooks/useOnboarding";

import PhilanthropicInformationSummary from "../PhilanthropicInformationSummary";
import { GrantSearchResult, SearchQuery } from "../../types/search";
import { reprLocation } from "../../types/location";
import { prettierTaxonomy } from "../../utils/philanthropy";
import { filterUnmatched, matchTaxonomyBySearchQueryWithPhilanthropy } from "../../types/taxonomy";
import PhilanthropicInformationFlexWrap from "../PhilanthropicInformationFlexWrap";
import { IProject } from "../../types/project";
import GibooGradientDiv from "../GibooGradientDiv";
import GrantCard from "../search/GrantCard";

interface IProps {
  solicitateGrants: GrantSearchResult[];
  query: SearchQuery;
}

function DonorSolicitateGrants({ query, ...props }: IProps) {
  const navigate = useNavigate();
  // const [matched, setMatched] = useState<{ [key: string]: Philanthropy }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [onboardingData] = useOnboardingData();
  // useEffect(() => {
  //   if (!props.solicitateGrants || props.solicitateGrants.length === 0) setMatched({});
  //   setLoading(true);
  //   getGrantsMatchedInformation(
  //     props.solicitateGrants.map((item) => item._id),
  //     props.solicitateGrants.map((_) => "philanthropy"),
  //     props?.context?.query ? props.context.query : getQueryFromOnboardingData(onboardingData),
  //   )
  //     .then((res) =>
  //       setMatched(
  //         res.reduce(
  //           (prev, cur, i) => ({ ...prev, [props.solicitateGrants[i]._id]: cur }),
  //           {} as { [key: string]: Philanthropy },
  //         ),
  //       ),
  //     )
  //     .finally(() => setLoading(false));
  // }, [props.solicitateGrants]);
  const compareGrant = (a: GrantSearchResult, b: GrantSearchResult): number => {
    return 0;
  };
  const hasNoTaxonomy =
    query.focus_area.length === 0 &&
    query.beneficiary.length === 0 &&
    query.program.length === 0 &&
    query.hq_loc.length === 0 &&
    query.service_loc.length === 0;
  return (
    <div className="flex flex-col gap-3">
      {props.solicitateGrants
        .sort((a, b) => compareGrant(a, b))
        .map((v, i) => {
          // const {
          //   focus_area = [],
          //   beneficiary = [],
          //   program = [],
          //   service_loc = [],
          // } = matched[v._id] || { focus_area: [], beneficiary: [], program: [], service_loc: [] };
          const philanthropy = prettierTaxonomy(
            matchTaxonomyBySearchQueryWithPhilanthropy(
              v,
              query,
              v.service_specific_loc,
              v.type === "grant-page" ? false : true,
            ),
            query,
          );
          return (
            <GibooGradientDiv
              key={v._id}
              borderSize={1}
              className={classNames("cursor-pointer p-5 hover:bg-gray-100")}
              onClick={() => {
                navigate(`/grants/${v._id}`, {
                  state: {
                    context: { query },
                  },
                });
              }}
            >
              <GrantCard
                grant={{ ...v, ...philanthropy }}
                query={query}
                hideBtns
                hideFunderInfo
                tagMatched={hasNoTaxonomy}
                showUnmatched
              />
            </GibooGradientDiv>
          );
        })}
    </div>
  );
}

export default DonorSolicitateGrants;
