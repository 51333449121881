import React from "react";
import classNames from "classnames";
import ICON_CROWN from "../assets/images/crown.svg";
import { NPOGrantGroupedByFunder, NPOGrantStatistic } from "../types/npo";
import { getYearsString, toUsdLong } from "../utils/formatHelper";
import { useNavigate } from "react-router-dom";
import SaveToProjectContext from "./SaveToProjectContext";
import Button from "./tailwind/Button";
interface TopSupporterCardInterface {
  data: NPOGrantGroupedByFunder;
  wrapperClass?: string;
  statistic?: NPOGrantStatistic;
  topSupporter?: boolean;
  showPurpose?: boolean;
  showSaveToProject?: boolean;
}
function TopSupporterCard({
  data,
  wrapperClass,
  topSupporter,
  showPurpose,
  showSaveToProject = false,
}: TopSupporterCardInterface) {
  const navigate = useNavigate();
  const formatGrantYears = (grantYears: any[]) => {
    if (Array.isArray(grantYears) && grantYears.length >= 2) {
      return `${grantYears[0]}-${grantYears[grantYears.length - 1]}`;
    } else if (grantYears.length === 1) {
      return grantYears[0];
    } else {
      return null;
    }
  };
  return (
    <>
      <div
        className={classNames(
          "flex h-fit w-full flex-col rounded border border-gray-200 bg-white p-4",
          wrapperClass,
        )}
      >
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2 truncate">
            <h4
              title={data?.donor_name}
              className="grow cursor-pointer truncate font-poppins text-base font-semibold text-gray-900 hover:text-purple-500"
              onClick={() => {
                navigate(`/donors/${data.donor_id}`);
              }}
            >
              {data?.donor_name}
            </h4>
            {topSupporter && (
              <div className="inline-flex min-w-fit items-center gap-2 whitespace-nowrap rounded bg-yellow-100 px-2 py-1">
                <img src={ICON_CROWN} className="h-5 w-5" />
                <h5 className="font-poppins text-xs !font-light text-black">Top funder</h5>
              </div>
            )}
          </div>
          {showSaveToProject && (
            <SaveToProjectContext project_id={undefined} target_id={data.donor_id} target_type={0}>
              {({ showPicker, isLoading }) => (
                <Button
                  className="inline-flex max-h-9 items-center whitespace-nowrap !text-sm"
                  handleOnClick={(e) => {
                    showPicker();
                  }}
                  outline
                  spinnerColor={"purple"}
                  prependIcon={
                    !isLoading && <i className={classNames("gi-md", "gi-bookmark-unsaved")} />
                  }
                  id={`btn-add-project`}
                  label={"Save to a project"}
                />
              )}
            </SaveToProjectContext>
          )}
        </div>
        <h5 className="mt-2 font-poppins text-sm font-light text-gray-700">
          Awarded year:{" "}
          <span className="font-semibold text-purple-500">
            {getYearsString(data?.grant_years || [])}
          </span>
        </h5>
        <div className="mt-1 flex items-center">
          <h5 className="font-poppins text-sm font-light text-gray-700">
            Total contributions and grants amount:{" "}
            <span className="font-semibold text-purple-500">{toUsdLong(data?.grant_amount)}</span>
          </h5>
        </div>
        <h5 className="mt-1 font-poppins text-sm font-light text-gray-700">
          Purpose: <span className="font-semibold text-purple-500">{data?.grant_desc}</span>
        </h5>
      </div>
    </>
  );
}

export default TopSupporterCard;
