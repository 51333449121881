import { Dialog } from "@headlessui/react";
import PlainButton from "./PlainButton";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import classNames from "classnames";
import useOnboardingData from "../hooks/useOnboarding";
import useOrgs from "../hooks/useOrgs";
import Button from "./tailwind/Button";
import { Divider } from "rsuite";
import MemoizedDropArea from "./input/FileDropArea";
import ICON_FILE from "../assets/images/file-upload.svg";
import CloseButton from "./CloseButton";
import useRequestOrgVerification from "../hooks/useRequestOrgVerification";
import RoundButton from "./RoundButton";
import FakeProgressBar from "./FakeProgressBar";
import { uploadFileService } from "../services/file-upload/upload.service";
import { getVerificationFileKey } from "../utils/media";
import GibooToast from "./GibooToast";
import { toBytesFormat } from "../utils/formatHelper";
import useUploadIRS from "../hooks/useUploadIRS";
import { zIndexBackdrop, zIndexDialog, zIndexUploadIRS } from "../types/zIndex";
import FileUploadComponent, { UploadFile } from "./FIleUploadComponent";
interface VerifyOrganizationModalProps {
  title?: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCreation?: (data?: any) => void;
  onClose?: () => void;
}
function UploadIRSModal({
  title = "Upload IRS990 forms",
  open,
  setOpen,
  onCreation,
  onClose,
}: VerifyOrganizationModalProps) {
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const { data, request } = useUploadIRS(onboardingData._id);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingFileUpload, setLoadingFileUpload] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<UploadFile[]>([]);
  const renderContent = () => {
    return (
      <div className="flex flex-col p-[28px]">
        {data.requested ? (
          <h5 className="grid h-[40px] w-fit place-items-center rounded border border-gray-300 px-4 py-2 font-poppins text-base font-semibold text-purple-500">
            Analysis in progress
          </h5>
        ) : null}
        <div className="my-4">
          <p className="font-poppins text-sm text-gray-700">You can upload IRS990 form</p>
        </div>
        <div className="p-[28px]">
          <FileUploadComponent
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loadingFileUpload={loadingFileUpload}
            setLoadingFileUpload={setLoadingFileUpload}
            error={error}
            setError={setError}
            allowMultipleFile
            previousFiles={data?.files || []}
            formats={[".pdf", ".png", ".jpeg", ".jpg", ".doc", ".docx"]}
            uploadService={(file) =>
              uploadFileService(file, getVerificationFileKey(onboardingData._id), ["image", "doc"])
            }
            placeholderText="We support JPEGs, PNGs, PDFs and DOCs under 10MB"
            id={"upload-irs"}
            sizeLimit={10}
          />
        </div>
      </div>
    );
  };
  const renderFooter = () => {
    return (
      <div className="flex w-full justify-between px-6 py-5">
        <h6 className="text-purple-500">
          {error ? "We apologize for the inconvenience. Please try again." : ""}
        </h6>
        <Button
          id="btn-done"
          color="purple"
          label="Done"
          loading={isLoading}
          disabled={loadingFileUpload || selectedFile.filter((i) => i.done).length === 0}
          handleOnClick={() => {
            if (isLoading || !onboardingData || !user) return;
            setIsLoading(true);
            request({
              ...user,
              npo_id: onboardingData.npo_id || "",
              npo_name: onboardingData.npo_name || "",
              type: onboardingData.type,
              origin: window.location.origin,
              files: selectedFile.filter((f) => f.done && f.key),
            })
              .then(() => {
                GibooToast({
                  type: "success",
                  message: `Successfully requested`,
                });
                onCreation?.();
                setOpen(false);
              })
              .catch(() => {
                GibooToast({
                  type: "failure",
                  message: `We apologize for the inconvenience., please try again`,
                });
              })
              .finally(() => setIsLoading(false));
          }}
        />
      </div>
    );
  };
  const renderDescription = () => {
    return (
      <div className="flex flex-col gap-4">
        <h6 className="font-poppins text-xs text-gray-800">
          After verification, you will get to enjoy the full Giboo features. Verification helps in
          the prevention of fraudulent activities or misrepresentation, protecting both your
          organizations and funders from potential scams.
        </h6>

        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            1. Why is organization verification important?
          </h4>
          <p className="font-poppins text-xs text-gray-800">
            Verification helps in the prevention of fraudulent activities or misrepresentation,
            protecting both your organizations and funders from potential scams. Help us build a
            trustworthy Giboo community!
          </p>
        </div>

        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            2. How long does the verification process take?
          </h4>
          <p className="font-poppins text-xs text-gray-800">
            Verification typically takes 24-48 hours, and we&apos;ll notify you as soon as it&apos;s
            complete. Your information and privacy are safe with us, as we use these documents
            exclusively for verification purposes.
          </p>
        </div>
      </div>
    );
  };
  return (
    <Dialog open={open} onClose={() => null} className="">
      <div className={classNames("fixed inset-0 bg-black/80", zIndexBackdrop)} aria-hidden="true" />
      <div
        className={classNames(
          "fixed inset-0 flex  w-screen items-center justify-center overflow-y-auto p-4",
          zIndexDialog,
        )}
      >
        <Dialog.Panel
          className={classNames("mx-auto min-h-fit !w-[586px] rounded bg-white", zIndexUploadIRS)}
        >
          <div className="flex h-full w-full flex-col ">
            <div className="flex items-center justify-between px-6 py-4">
              <h3 className="font-poppins text-2xl font-semibold">{title}</h3>
              <CloseButton
                className="cursor-pointer"
                onClick={() => {
                  onClose?.();
                  setOpen(false);
                }}
              />
            </div>
            <div className="h-[550px] overflow-y-scroll border-y border-gray-300 ">
              {renderContent()}
            </div>
            {renderFooter()}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
export default UploadIRSModal;
