import { useCallback } from "react";
import { IProjectSubtaskNote } from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { useAppDispatch } from "../../app/store";
import { swrOptionDedupling5mins } from "../../types/swr";
import { DocumentDefinition, DocumentTypeEnum } from "../../types/document";
interface DocumentDefinitionsState {
  data: DocumentDefinition[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  getDefinition: (typeEnum: DocumentTypeEnum) => DocumentDefinition | undefined;
}
function useDocumentDefinitions(): DocumentDefinitionsState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/document`;
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<DocumentDefinition[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as DocumentDefinition[]);
  };
  const { data, isLoading, error, isValidating } = useSWR<DocumentDefinition[]>(
    url,
    fetch,
    swrOptionDedupling5mins,
  );
  const getDefinition = useCallback(
    (typeEnum: DocumentTypeEnum) => data?.find((d) => d.type === typeEnum),
    [data],
  );
  return {
    data: data || [],
    isLoading,
    error,
    isValidating,
    getDefinition,
  };
}
export default useDocumentDefinitions;
