import Slider from "../../../components/tailwind/Slider";
import { useNavigate } from "react-router-dom";
import useProjects from "../../../hooks/project/useProjects";
import ExpandableCard from "../../../components/tailwind/ExpandableCard";
import ProjectCard from "../../../components/ProjectCard";
import IMG_PROJECTS from "../../../assets/home/create-project.svg";
import useOnboardingData from "../../../hooks/useOnboarding";
import useWritePermission from "../../../hooks/useWritePermission";
import PlainButton from "../../../components/PlainButton";
import CreateProjectCard from "../../../components/CreateProjectCard";
import PrimaryButton from "../../../components/PrimaryButton";
import classNames from "classnames";
import Spacer from "../../../components/Spacer";
import ProjectCard2 from "../../../components/ProjectCard2";
import ProjectCreationModal from "../../../components/ProjectCreationModal";
import { useState } from "react";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";

interface Iprops {
  newUser: boolean;
}
const Project = ({ handleCreateProject }: { handleCreateProject?: () => void }) => {
  const navigate = useNavigate();
  const [projects] = useProjects();
  const [onboardingData] = useOnboardingData();
  const { hasWritePermission } = useWritePermission(onboardingData.npo_id);
  const [showProjectCreationModal, setShowProjectCreationModal] = useState<boolean>(false);
  return (
    <>
      {showProjectCreationModal && (
        <ProjectCreationModal
          open={showProjectCreationModal}
          setOpen={setShowProjectCreationModal}
        />
      )}
      <ExpandableCard
        id="projects"
        title="Projects"
        titleClass="!text-base"
        childClass={projects.data.length > 0 ? "" : "!pb-0"}
        allowExpand={false}
        allowEdit={false}
        showAllDetail={false}
        rightAction={
          <div className={classNames("inline-flex")}>
            {onboardingData.role >= 3 && projects.data.length > 0 && (
              <PrimaryButton
                size="md"
                id={`btn-create-project`}
                label={"Create a project"}
                className="GA_TAG_click_createaproject !h-[29px] !text-sm"
                leftIconClass="gi-add gi-md"
                handleOnClick={() => {
                  // mxEvent(MIXPANEL_EVENTS.PROJECT_CREATION.PROJECT_CREATE_CLICKED, {
                  //   organizationId: onboardingData._id,
                  //   location: location.pathname,
                  // });
                  handleCreateProject?.();
                }}
              />
            )}
            {projects.data.length < 4 && (
              <PlainButton
                id={`btn-create-project`}
                label={`View all (${projects.data.length})`}
                className="!h-[29px] !text-sm"
                handleOnClick={() => navigate(`/projects`)}
              />
            )}
          </div>
        }
      >
        {projects.data.length > 0 ? (
          <div className="flex w-full flex-col items-center gap-4">
            <div className="grid grid-flow-row grid-cols-2 gap-4">
              {projects.data.slice(0, 4).map((i, index) => (
                <ProjectCard2
                  key={index}
                  projectId={i._id}
                  showPublicBtn={false}
                  isPrivateView
                  hasWritePermission={hasWritePermission}
                />
              ))}
            </div>
            {projects.data.length > 4 && (
              <div className="mt-4 grid w-full place-items-center rounded-b bg-gray-100 px-10 py-3">
                <div
                  className="inline-flex w-fit cursor-pointer items-center gap-2"
                  onClick={() => {
                    navigate("/projects");
                  }}
                >
                  <p className="font-poppins text-sm font-semibold text-purple-500">
                    View all ({projects.data.length})
                  </p>
                  <div className="grid h-5 w-5 place-items-center">
                    <i className="gi gi-right-arrow text-purple-500" />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="mt-3 grid min-h-[239px] w-full place-items-center gap-6">
            <PrimaryButton
              size="md"
              id={`btn-create-project`}
              label={"Create a project"}
              className="GA_TAG_click_createaproject !h-[29px] !text-sm"
              leftIconClass="gi-add gi-md"
              handleOnClick={() => {
                // mxEvent(MIXPANEL_EVENTS.PROJECT_CREATION.PROJECT_CREATE_CLICKED, {
                //   organizationId: onboardingData._id,
                //   location: location.pathname,
                // });
                handleCreateProject?.();
              }}
            />
            <div className="flex max-w-[475px] flex-col items-center justify-center gap-4">
              <p className="font-poppins text-base font-semibold text-black">
                Create a fundraising project
              </p>
              <h5 className="w-[346px] text-center font-poppins text-xs font-normal text-gray-700">
                Do you need fundraising assistance? Create a project and utilize our project
                management tool to streamline and enhance the efficiency of your fundraising
                efforts.
              </h5>
            </div>
            <img src={IMG_PROJECTS} className="-mt-1 h-[207px] w-full" />
          </div>
        )}
      </ExpandableCard>
    </>
  );
};
export default Project;
