import useSWR, { Key } from "swr";
import { CancellablePromise } from "../middleware/swr";
export default function useCancellableSWR<T>(
  key: Key,
  fetcher: (url: string, controller: AbortController) => Promise<T>,
  swrOption: any,
) {
  const res = useSWR<T>(
    key,
    (url: string) => {
      const controller = new AbortController();
      const promise: CancellablePromise<T> = fetcher(url, controller);
      promise.cancel = (str?: string) => controller.abort(str);
      return promise;
    },
    swrOption,
  );
  return { ...res };
}
