import axios from "axios";
import useSWR, { useSWRConfig } from "swr";
import config from "../api";
import useUser from "./useUser";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { swrOptionFetchOnlyOnMount } from "../types/swr";

export type LikeType = "document_node"; //| "something";

interface useLikeState {
  liked: 0 | 1 | -1;
  handleClickLike: (v: 0 | 1 | -1) => void;
}
interface LikedResponse {
  user_id: string;
  liked: 0 | 1 | -1; // 0:undefined 1:liked 2:disliked
  updated_at: string;
  note?: string;
  document_node_id?: string;
}
function useLikeObject(type: LikeType, id?: string): useLikeState {
  const fetch = async (url: string) => {
    if (!url) return new Promise<LikedResponse>((resolve, reject) => reject());
    return axios.get(url, config).then((response) => response.data as LikedResponse);
  };
  const { mutate } = useSWRConfig();
  const key = type === "document_node" ? "like_document_node" : undefined;
  const url = id && key ? process.env.REACT_APP_API_URL + `/api/v2/users/${key}/${id}` : undefined;
  const { data: liked } = useSWR<LikedResponse>(url ? url : null, fetch, swrOptionFetchOnlyOnMount);
  const handleClickLike = useCallback(
    (v: number, note?: string) => {
      if (!liked || !url) return;
      const newData = {
        ...liked,
        liked: v,
        note: note ? note : undefined,
      };
      const options = {
        optimisticData: newData, // data will update instantly
        rollbackOnError: true,
      };
      mutate(
        url,
        axios.put(url, newData, config).then((response) => {
          if (typeof response?.data?.liked == "boolean") {
            const isLiked = response.data.liked;
          }
          return response.data;
        }),
        options,
      );
    },
    [liked, url],
  );
  return {
    liked: liked ? liked.liked : 0,
    handleClickLike,
  };
}
export default useLikeObject;
