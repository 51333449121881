import { useCallback, useEffect, useState } from "react";
import { swrOptionDedupling5mins } from "../../types/swr";
import { Philanthropy } from "../../types/philanthropy";
import axios from "axios";
import useCancellableSWR from "../useCancellableSWR";
import config from "../../api";

interface useFunderState {
  data?: Philanthropy;
  isLoading: boolean;
  error?: object;
}
export default function useFunderTags(
  id?: string,
  type: "" | "focus_area" = "",
  skip = 0,
  limit = 1000_000,
  sorting = "amount",
): useFunderState {
  const fetch = useCallback(
    async (url: string, controller?: AbortController) => {
      if (!url || !id) return new Promise<Philanthropy>((resolve, reject) => reject());
      return axios
        .get(url, {
          ...config,
          ...(controller ? { signal: controller.signal } : {}),
        })
        .then((res) => res.data as Philanthropy);
    },
    [id],
  );
  const { data, isLoading, error } = useCancellableSWR<Philanthropy>(
    id
      ? process.env.REACT_APP_API_URL +
          `/api/v2/donors/${id}/tags?skip=${skip}&limit=${limit}&sorting=${sorting}&${
            type ? `&type=${type}` : ""
          }`
      : null,
    fetch,
    swrOptionDedupling5mins,
  );
  return { data, isLoading, error };
}
