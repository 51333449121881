import { Dialog } from "@headlessui/react";
import PlainButton from "./PlainButton";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import Button from "./tailwind/Button";
import MemoizedDropArea from "./input/FileDropArea";
import useUser from "../hooks/useUser";
import ICON_FILE from "../assets/images/file-upload.svg";
import {
  CircleStencil,
  CropperRef,
  FixedCropper,
  FixedCropperRef,
  ImageRestriction,
} from "react-advanced-cropper";
import CloseButton from "./CloseButton";
import classNames from "classnames";
import { zIndexBackdrop, zIndexDialog, zIndexUpdateImage } from "../types/zIndex";
import { FROM_FOR_MIXPANEL, MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import { Slider } from "rsuite";
interface UpdateImageProps {
  from_for_mixpanel: FROM_FOR_MIXPANEL;
  image_type_for_mixpanel: "user_profile" | "user_cover" | "org_profile" | "org_cover" | "project";
  id: string;
  title: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedImage: File | undefined;
  setSelectedImage: Dispatch<SetStateAction<File | undefined>>;
  previousImageURL?: string;
  onUpdateImage: () => Promise<any>;
  type: "PROFILE" | "COVER" | "PROJECT";
  projectId?: string;
}
function UpdateImage({
  from_for_mixpanel,
  image_type_for_mixpanel,
  id,
  title,
  open,
  setOpen,
  onUpdateImage,
  selectedImage,
  setSelectedImage,
  previousImageURL,
  type = "PROFILE",
  projectId,
}: UpdateImageProps) {
  const mxEvent = useGibooMixpanel(from_for_mixpanel, true);
  const [user] = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deletePreviousImage, setDeletePreviousImage] = useState<boolean>(false);
  const cropperRef = useRef<FixedCropperRef>(null);
  const [tempSelected, setTempSelected] = useState<File | undefined>();
  const [previousRotation, setPreviousRotation] = useState(1);
  const [previousZoom, setPreviousZoom] = useState(0);
  // console.log({ selectedImage });
  //image editor
  const [imageString, setImageString] = useState<string>();
  useEffect(() => {
    if (open)
      mxEvent(MIXPANEL_EVENTS_V2.upload_image[""].started, { imageType: image_type_for_mixpanel });
  }, [open]);
  useEffect(() => {
    fileToImageUrl(tempSelected).then((res) => {
      res && setImageString(res);
    });
  }, [tempSelected]);
  const fileToImageUrl = (file: File | undefined) => {
    if (!file) return Promise.resolve<string | null>(null);
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event?.target?.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const renderEditor = () => {
    if (!imageString) return <></>;
    return (
      <div className=" flex w-full flex-col">
        <FixedCropper
          className="!h-[350px]"
          ref={cropperRef}
          backgroundWrapperClassName="w-full bg-black opacity-90"
          src={imageString}
          defaultSize={({ imageSize, visibleArea }) => {
            return {
              width: (visibleArea || imageSize).width,
              height: (visibleArea || imageSize).height,
            };
          }}
          onChange={(cropper: CropperRef) => {
            const canvas = cropper?.getCanvas();
            if (canvas) {
              canvas.toBlob((blob) => {
                if (blob) {
                  const file = new File([blob], "edited.jpg", { type: "image/jpeg" });
                  setSelectedImage(file);
                }
              }, "image/jpeg");
            }
          }}
          {...(type === "PROFILE"
            ? {
                stencilComponent: CircleStencil,
                stencilSize: {
                  width: 300,
                  height: 300,
                },
              }
            : type === "PROJECT"
            ? {
                stencilSize: {
                  width: 800,
                  height: 400,
                },
              }
            : {
                stencilSize: {
                  width: 864,
                  height: 228,
                },
              })}
          stencilProps={{
            handlers: false,
            lines: true,
            movable: false,
            resizable: false,
            grid: true,
          }}
          backgroundWrapperProps={{
            scaleImage: false,
            moveImage: true,
          }}
          imageRestriction={ImageRestriction.stencil}
        />
        <div className="inline-flex flex-wrap items-center gap-7 px-[61px] py-[36px]">
          <div className="flex flex-col gap-2">
            Zoom:{" "}
            <input
              type="range"
              min={0}
              max={3}
              value={previousZoom}
              className="range w-[355px] accent-purple-500"
              step={0.5}
              onChange={(e) => {
                const zoom = Number(e.target.value);
                setPreviousZoom(zoom);
                if (cropperRef.current) {
                  const zoomDiff = zoom - previousZoom;
                  if (zoomDiff < 0) {
                    cropperRef.current?.zoomImage(Math.min(1, 1 - 0.25));
                  } else {
                    cropperRef.current?.zoomImage(Math.max(1, 1 + 0.25));
                  }
                }
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            Rotate:{" "}
            <input
              type="range"
              min={-45}
              max={45}
              step={2}
              value={previousRotation}
              className="range  h-4 w-[355px] accent-purple-500"
              onChange={(e) => {
                const rotation = Number(e.target.value);
                setPreviousRotation(rotation);
                if (cropperRef.current) {
                  const rotationDiff = rotation - previousRotation;
                  cropperRef?.current?.rotateImage(rotationDiff);
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setPreviousZoom(0);
        setPreviousRotation(0);
        setDeletePreviousImage(false);
        setSelectedImage(undefined);
        setTempSelected(undefined);
      }}
      className=""
    >
      <div className={classNames("fixed inset-0 bg-black/80", zIndexBackdrop)} aria-hidden="true" />
      <div
        className={classNames("fixed inset-0 flex items-center justify-center p-4", zIndexDialog)}
      >
        <Dialog.Panel
          className={classNames("mx-auto h-fit  !w-[864px] rounded bg-white", zIndexUpdateImage)}
        >
          <div className="flex justify-between p-6">
            <h4>{title}</h4>
            <PlainButton
              id={`btn-update-image-close`}
              handleOnClick={() => {
                setTempSelected(undefined);
                setPreviousZoom(0);
                setPreviousRotation(0);
                setDeletePreviousImage(false);
                setOpen(false);
                setSelectedImage(undefined);
              }}
              leftIconClass={<CloseButton />}
            />
          </div>
          <div className="flex w-full flex-col items-center justify-center border-b border-t border-gray-300">
            {tempSelected ? (
              renderEditor()
            ) : (
              <MemoizedDropArea
                id={id}
                wrapperClass="w-full p-4"
                className="h-[145px] w-full "
                formats={[".png", ".jpg", ".jpeg"]}
                containerClass={classNames(
                  "min-h-[192px] !border-solid !border hover:!border-purple-500",
                )}
                placeholder={
                  <div className="grid w-full place-items-center text-center">
                    <span>
                      <img src={ICON_FILE} className="h-[75px] w-[75px]" />
                    </span>
                    <h5 className="mt-3 font-poppins text-sm text-gray-700">
                      <span className="font-semibold text-purple-500">Click to upload </span>
                      or drag and drop here
                    </h5>
                    <p className="m-2 font-poppins text-sm text-gray-700">
                      {" "}
                      We support JPEGs, JPG and PNGs under 10MB
                    </p>
                  </div>
                }
                onRemove={() => {
                  setTempSelected(undefined);
                }}
                onDrop={function (arg: File): void {
                  setDeletePreviousImage(true);
                  setTempSelected(arg);
                  setSelectedImage(arg);
                }}
              />
            )}
            {previousImageURL && !deletePreviousImage && (
              <div className="my-6 flex items-center justify-center self-start px-4">
                <img
                  className="h-[50px] w-fit max-w-[100px] bg-cover opacity-80"
                  src={previousImageURL}
                />
                <PlainButton
                  id={`btn-update-image-close`}
                  handleOnClick={() => {
                    setDeletePreviousImage(true);
                  }}
                  leftIconClass={<CloseButton />}
                />
              </div>
            )}
          </div>
          <div className="flex h-20 items-center justify-end px-4">
            <Button
              id="btn-update-image"
              handleOnClick={() => {
                // console.log({ selectedImage, tempSelected, deletePreviousImage });
                if (isLoading) return;
                setIsLoading(true);
                onUpdateImage()
                  .then(() => {
                    mxEvent(MIXPANEL_EVENTS_V2.upload_image[""].successful, {
                      fileName: selectedImage?.name,
                      fileSize: selectedImage?.size,
                      imageType: image_type_for_mixpanel,
                      projectId,
                    });
                  })
                  .catch(() => {
                    mxEvent(MIXPANEL_EVENTS_V2.upload_image[""].failed, {
                      fileName: selectedImage?.name,
                      fileSize: selectedImage?.size,
                      imageType: image_type_for_mixpanel,
                      projectId,
                      error: "api error",
                    });
                  })
                  .finally(() => {
                    setTimeout(() => {
                      setIsLoading(false);
                      setSelectedImage(undefined);
                      setTempSelected(undefined);
                      setDeletePreviousImage(false);
                      setOpen(false);
                    }, 2000);
                  });
              }}
              color="purple"
              label="Apply"
              disabled={!deletePreviousImage}
              loading={isLoading}
            />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
export default UpdateImage;
