import classNames from "classnames";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Checkbox from "../checkbox/Checkbox";

interface MultipleSelectGroupProps<T extends string> {
  id: string;
  labels: { [key in T]: string[] };
  value: T[];
  setValue: Dispatch<SetStateAction<T[]>>;
  flexColumn?: boolean;
  disabled?: boolean;
  allowOthers?: boolean;
  selectAll?: boolean;
  wrapperClass?: string;
}
function MultipleSelectGroup<T extends string>(props: MultipleSelectGroupProps<T>) {
  const [others, setOthers] = useState<string>("");
  const [allSelected, setAllSelected] = useState<boolean>(false);
  useEffect(() => {
    if (others)
      props.setValue((prev) => [
        ...prev.filter((item) => !item.startsWith("*")),
        `*${others}` as T,
      ]);
    else props.setValue((prev) => prev.filter((item) => !item.startsWith("*")));
  }, [others]);
  useEffect(() => {
    setAllSelected(
      props.value.length === Object.keys(props.labels).length + (props.allowOthers ? 1 : 0),
    );
    setOthers(props.value.filter((item) => item.startsWith("*"))[0]?.slice(1) || "");
  }, [props.value]);
  return (
    <div
      className={classNames(
        "flex gap-3",
        {
          "flex-wrap": !props.flexColumn,
          "flex-col items-start": props.flexColumn,
        },
        props.wrapperClass,
      )}
    >
      {props.selectAll && (
        <div className="me-4 flex items-center gap-1">
          <Checkbox
            id={`input-${props.id}-*all`}
            checked={allSelected}
            onChange={() => {
              if (!allSelected) {
                props.setValue([
                  ...Object.keys(props.labels).map((item) => item as T),
                  ...(props.allowOthers ? [`*${others}` as T] : []),
                ]);
              } else props.setValue([]);
            }}
            label="Select all"
            disabled={props.disabled}
          />
        </div>
      )}
      {Object.keys(props.labels).map((key) => (
        <div className="me-4 flex gap-2" key={key}>
          {key.startsWith("*") ? null : (
            <div
              className="flex cursor-pointer flex-col"
              onClick={() =>
                props.setValue((prev) => {
                  if (prev.includes(key as T)) return prev.filter((item) => item !== key);
                  else return [...prev, key as T];
                })
              }
            >
              <Checkbox
                id={`input-${props.id}-${key}`}
                checked={props.value.includes(key as T)}
                onChange={() => {}}
                label={props.labels[key as T][0]}
                disabled={props.disabled}
              />
              {props.labels[key as T].slice(1).map((label, i) => (
                <h5 className="gray-700 ms-4 ps-1" key={i}>
                  {label}
                </h5>
              ))}
            </div>
          )}
        </div>
      ))}
      {props.allowOthers && (
        <div className="flex items-center gap-1">
          <Checkbox
            id={`input-${props.id}-`}
            checked={props.value.filter((item) => item.startsWith("*")).length > 0}
            onChange={() => {
              if (props.value.filter((item) => item.startsWith("*")).length > 0) setOthers("");
            }}
            label="Others,"
            disabled={props.disabled}
          />
          <p className="gray">please specify</p>
          <input
            id={`input-${props.id}-others`}
            className="h-6 border-b border-l-0 border-r-0 border-t-0 border-dashed !border-gray-500 bg-transparent outline-none !ring-0"
            type="text"
            value={others}
            onChange={(e) => {
              setOthers(e.currentTarget.value);
            }}
          />
        </div>
      )}
    </div>
  );
}
export default MultipleSelectGroup;
