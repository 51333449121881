import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../api";
import { useAppSelector } from "../app/store";
import PrimaryButton from "../components/PrimaryButton";
import useUser from "../hooks/useUser";
import LeftInnerIconInput from "../components/LeftInnerIconInput";
import Button from "../components/tailwind/Button";

function DeleteView() {
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useUser();
  const [email, setEmail] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    if (process.env.REACT_APP_ENV !== "DEV") return;
    if (email !== user?.email) {
      setIsDirty(true);
      return;
    }
    setError(false);
    setLoading(true);
    axios
      .delete(process.env.REACT_APP_API_URL + `/api/v2/users/${user?._id}`, config)
      .then(() => {
        setUser(null);
        navigate("/");
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {process.env.REACT_APP_ENV === "DEV" ? (
        user?._id ? (
          <>
            <h4>Do you want to delete account?</h4>
            <h5>Please enter your email address</h5>
            <LeftInnerIconInput
              value={email}
              handleOnChange={(e) => setEmail(e.target.value)}
              id="input-email"
            />
            {isDirty && user?.email !== email && (
              <h6 className="text-purple-500">Email doesn&apos;t match</h6>
            )}
            {error && <h6 className="text-purple-500">Sorry, please try again</h6>}
            <Button
              loading={isLoading}
              id={"btn-home"}
              label="Delete"
              handleOnClick={handleDelete}
            />
          </>
        ) : (
          <div>
            <h5>please login first</h5>
            <Button id={"btn-home"} label="go to login" handleOnClick={() => navigate("/")} />
          </div>
        )
      ) : (
        <div className="no-page">
          <h1>Not Found</h1>
        </div>
      )}
    </>
  );
}

export default DeleteView;
