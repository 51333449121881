import { useCallback, useEffect, useState } from "react";

function useLocalStorage(key: string, initialValue?: any) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    try {
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch {
      return initialValue || null;
    }
  });

  const update = useCallback(
    (newValue: any) => {
      localStorage.setItem(key, JSON.stringify(newValue));
      setValue(newValue);
    },
    [key, setValue],
  );

  const deleteThis = useCallback(() => {
    localStorage.removeItem(key);
    setValue(null);
  }, [key, setValue]);
  return { value, update, deleteThis };
}
export default useLocalStorage;
