import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PopupModal from "./tailwind/PopupModal";
import Button from "./tailwind/Button";
import LetterEditor from "../pages/npo/grant/pages/component/LetterEditor";
import { IOption } from "./tailwind/AsyncCreatableSelector";
import MultipleCreatableSelector from "./selector/MultipleCreatableSelector";
import LeftInnerIconInput from "./LeftInnerIconInput";
import CreatableSelector from "./selector/CreatableSelector";
interface LetterEditorModalProps {
  inputClass?: string;
  useContactInput?: boolean;
  senders?: IOption[];
  quillRefProp?: React.RefObject<ReactQuill>;
  show: boolean;
  onClose?: () => void;
  onSave?: (data: {
    sender: IOption | undefined;
    sendTo: IOption[];
    title: string;
    content: string;
    htmlContent: string;
  }) => void;
  saveLoading?: boolean;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;
  buttonLabel?: string;
}
function LetterEditorModal({
  useContactInput = false,
  senders = [],
  quillRefProp,
  show,
  onClose,
  onSave,
  saveLoading,
  content,
  setContent,
  buttonLabel = "Done",
}: LetterEditorModalProps) {
  const inputClass = "!max-h-[32px] !min-h-[32px] !h-[32px] w-full";
  const leftDivSize = "min-w-[48px] max-w-[48px] w-12";
  const [downloadContent, setDownloadContent] = useState<any>();
  const [curSender, setCurSender] = useState<IOption | undefined>(
    senders.length > 0 ? senders[0] : undefined,
  );
  const [curSendTo, setCurSendTo] = useState<IOption[]>([]);
  const [curTitle, setCurTitle] = useState<string>("");
  // const [cc, setCc] = useState<IOption[]>([]);
  // const [bcc, setBcc] = useState<IOption[]>([]);

  const renderSender = () => {
    return (
      <div className="mb-3 flex w-full flex-col gap-1">
        <div className="flex w-full items-center gap-3">
          <div className={leftDivSize}>From</div>
          <div className="w-full grow">
            <CreatableSelector
              id="input-letter-from"
              compact
              data={senders}
              value={curSender}
              className={inputClass}
              inputContainerClass={inputClass}
              onCreateOption={(email) =>
                setCurSender({ label: email, value: email, data: { email: email } })
              }
              onChange={(v) => {
                setCurSender(v ? { ...v } : undefined);
              }}
            />
          </div>
        </div>
        <div className="flex w-full items-center gap-3">
          <div className={leftDivSize}>To</div>
          <div className="w-full grow">
            <MultipleCreatableSelector
              id="input-letter-to"
              compact
              data={[]}
              value={curSendTo}
              className={inputClass}
              onCreateOption={(email) =>
                setCurSendTo((prev) =>
                  prev.find((p) => p.data?.email === email)
                    ? prev
                    : [...prev, { label: email, value: email, data: { email: email } }],
                )
              }
              onChange={(v) => {
                setCurSendTo(v.map((i) => i));
              }}
            />
          </div>
        </div>
        <div className="flex w-full items-center gap-3">
          <div className={leftDivSize}>Subject</div>
          <LeftInnerIconInput
            id="input-letter-subject"
            className={inputClass}
            defaultValue={curTitle}
            handleOnChange={(e) => setCurTitle(e.target.value)}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <PopupModal
        title={"Edit"}
        isOpen={show}
        contentClass="h-full p-5"
        handleClose={() => onClose?.()}
        wrapperClass="w-fit md:!px-0 h-full !min-w-[400px]"
        showActionButtons={true}
        RightActionButton={() => (
          <Button
            id="btn-close"
            label={buttonLabel}
            loading={saveLoading}
            handleOnClick={() =>
              onSave?.({
                sender: curSender,
                sendTo: curSendTo,
                title: curTitle,
                content: quillRefProp?.current?.getEditor()?.getText() || "",
                htmlContent: content,
              })
            }
          />
        )}
        dialogPanelClass="overflow-visible"
      >
        <>{useContactInput && renderSender()}</>
        <LetterEditor
          style={{
            maxWidth: "840px",
            width: `calc(100vw - 300px)`,
            maxHeight: `calc(100vh - 500px)`,
            minHeight: "500px",
            height: `calc(100vh - 500px)`,
          }}
          letterEditorWrapper="!h-full"
          customClass="!px-0"
          textContainerClass="border border-gray-300 !h-full"
          quillRefProp={quillRefProp}
          data={content}
          enableAI={false}
          updateDownloadContent={(val) => setDownloadContent(val)}
          updateContent={(val) => setContent(val)}
          showWordLength
        />
      </PopupModal>
    </>
  );
}
export default LetterEditorModal;
