import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import UserProfile from "./UserProfile";
import UserName from "./UserName";
import useOrgs from "../hooks/useOrgs";
import { ILogData } from "../types/notification";
import useInteraction from "../hooks/project/useInteraction";
import { reprProjectInteractionType } from "../types/project";
import useTask from "../hooks/project/useTask";
import useFunderOrGrantName from "../hooks/project/useFunderOrGrantName";
import useProject from "../hooks/project/useProject";
import useSubtask from "../hooks/project/useSubtask";
import useSubtaskNote from "../hooks/project/useSubtaskNote";

interface ReprLogDataProps {
  id: string;
  project_id: string | undefined;
  task_id: string | undefined;
  data: ILogData[];
  disableClick?: boolean;
}
const role = ["", "as Guest", "as Viewer", "as Member", "as Owner", "as Admin"];
const getRoleString = (v?: string) => {
  if (!v) return "";
  try {
    return role[+v];
  } catch {
    return "";
  }
};
export const extractLogDataLink = (
  log: ILogData,
  project_id: string | undefined,
  task_id: string | undefined,
): string | undefined => {
  const pid = log.project_id || project_id;
  const tid = log.task_id || task_id;
  return pid && log.type === "task"
    ? `/project/${pid}/task/${log.value}`
    : log.type === "project"
    ? `/project/${log.value}/`
    : log.type === "interaction"
    ? `/project/${pid}?task_id=${tid}&interaction_id=${log.value}#interaction_${log.value}`
    : log.type === "interaction_comment"
    ? `/project/${pid}?task_id=${tid}&interaction_id=${log.interaction_id}&comment_id=${log.value}#comment_${log.value}`
    : log.type === "subtask_note"
    ? pid
      ? `/project/${pid}/task/${tid}?subtask_id=${log.subtask_id}&note_id=${log.value}#note_${log.value}`
      : `/my-tasks?subtask_id=${log.subtask_id}&note_id=${log.value}#note_${log.value}`
    : log.type === "org"
    ? `/organization/${log.value}/`
    : log.type === "diversity"
    ? `/profile`
    : undefined;
};
function ReprLogData({ id, project_id, task_id, data, disableClick, ...props }: ReprLogDataProps) {
  const navigate = useNavigate();
  const { orgs } = useOrgs();
  return (
    <span className="w-full text-left">
      {data.map((log, i) => {
        const pid = log.project_id || project_id;
        const tid = log.task_id || task_id;
        const isLink = extractLogDataLink(log, project_id, task_id);
        const key = `${id}-${i}-${log.type}-${log.value}`;
        return (
          <span
            key={key}
            className="mr-1"
            onClick={() => {
              if (isLink && !disableClick) navigate(isLink);
            }}
          >
            {log.type === "user" ? (
              <span className="text-purple-500">
                <UserName userId={log.value} />
              </span>
            ) : log.type === "task" ? (
              <TaskName
                className="cursor-pointer text-purple-500 hover:text-purple-700"
                project_id={project_id}
                task_id={log.value}
              />
            ) : log.type === "project" ? (
              <ProjectName
                className="cursor-pointer text-ellipsis text-purple-500 hover:text-purple-700"
                project_id={log.value}
              />
            ) : log.type === "org" ? (
              <span>
                {orgs.find((o) => o._id === log.value)
                  ? orgs.find((o) => o._id === log.value)?.npo_name || ""
                  : ""}
              </span>
            ) : log.type === "role" ? (
              <span>{log.value ? getRoleString(log.value) : ""}</span>
            ) : log.type === "interaction" ? (
              <InteractionName
                className="cursor-pointer text-sm text-purple-500 hover:text-purple-700"
                project_id={pid}
                task_id={tid}
                interaction_id={log.value}
                prefix="at"
              />
            ) : log.type === "interaction_comment" ? (
              <InteractionComment
                className="cursor-pointer text-sm text-purple-500 hover:text-purple-700"
                project_id={pid}
                task_id={tid}
                comment_id={log.value}
                interaction_id={log.interaction_id}
                prefix="at"
              />
            ) : log.type === "subtask_note" ? (
              <SubtaskNote
                className="cursor-pointer text-sm text-purple-500 hover:text-purple-700"
                project_id={pid}
                task_id={tid}
                subtask_id={log.subtask_id}
                note_id={log.value}
                prefix="at"
              />
            ) : (
              <span
                className={classNames(
                  log.type !== "text" ? "font-medium text-purple-500" : "text-gray-900",
                  isLink ? "cursor-pointer hover:text-purple-700" : "",
                )}
              >
                {log.value}
              </span>
            )}
          </span>
        );
      })}
    </span>
  );
}
export default ReprLogData;

function ProjectName({
  project_id,
  className,
}: {
  project_id: string | undefined;
  className?: string;
}) {
  const { data, isLoading } = useProject(project_id);
  return <span className={classNames("", className)}>{data.name}</span>;
}

function TaskName({
  project_id,
  task_id,
  wrapperClass,
  className,
}: {
  project_id: string | undefined;
  task_id: string | undefined;
  wrapperClass?: string;
  className?: string;
}) {
  const { data, isLoading } = useTask(task_id);
  const { data: name, isLoading: isNameLoading } = useFunderOrGrantName(
    data.target_id,
    data.target_type,
  );
  return (
    <span
      className={classNames(
        "loading-light-shimmer",
        isLoading || isNameLoading ? "loading-light-shimmer-on" : "",
        wrapperClass,
      )}
    >
      <span className={classNames("loading-light-shimmer-child min-w-[50px]", className)}>
        <span>{name}</span>
      </span>
    </span>
  );
}
function InteractionName({
  className,
  project_id,
  task_id,
  interaction_id,
  prefix,
}: {
  className?: string;
  project_id?: string;
  task_id?: string;
  interaction_id?: string;
  prefix?: string;
}) {
  const { data: interaction, isLoading: isLoadingInteraction } = useInteraction(interaction_id);
  const { data: project, isLoading: isLoadingProject } = useProject(project_id);
  const { data: task, isLoading: isLoadingTask } = useTask(task_id);
  const { data: name, isLoading: isLoadingName } = useFunderOrGrantName(
    task.target_id,
    task.target_type,
  );
  return (
    <span className="flex flex-wrap items-center gap-x-1 gap-y-0">
      {prefix && <span>{prefix}</span>}
      <span
        className={classNames(
          "loading-light-shimmer cursor-pointer",
          isLoadingInteraction ? "loading-light-shimmer-on" : "",
          className,
        )}
      >
        <span className="loading-light-shimmer-child">
          {reprProjectInteractionType(interaction.type)}
        </span>
      </span>
      <span>for</span>
      <span
        className={classNames(
          "loading-light-shimmer cursor-pointer",
          isLoadingTask || isLoadingName ? "loading-light-shimmer-on" : "",
          className,
        )}
      >
        <span className="loading-light-shimmer-child">{name}</span>
      </span>
      <span>in</span>
      <span
        className={classNames(
          "loading-light-shimmer cursor-pointer",
          isLoadingProject ? "loading-light-shimmer-on" : "",
          className,
        )}
      >
        <span className="loading-light-shimmer-child">{project.name}</span>
      </span>
    </span>
  );
}
function InteractionComment({
  className,
  project_id,
  task_id,
  interaction_id,
  comment_id,
  prefix,
}: {
  className?: string;
  project_id?: string;
  task_id?: string;
  interaction_id?: string;
  comment_id?: string;
  prefix?: string;
}) {
  const { data: interaction, isLoading: isLoadingInteraction } = useInteraction(interaction_id);
  const { data: project, isLoading: isLoadingProject } = useProject(project_id);
  const { data: task, isLoading: isLoadingTask } = useTask(task_id);
  const { data: name, isLoading: isLoadingName } = useFunderOrGrantName(
    task.target_id,
    task.target_type,
  );
  // const { data: comments, isLoading: isLoadingComment } = useInteractionComments(
  //   project_id,
  //   interaction_id,
  // );
  const comment = {};
  return (
    <span className="flex flex-wrap items-center gap-x-1 gap-y-0">
      {prefix && <span>{prefix}</span>}
      <span>comment on</span>
      <span
        className={classNames(
          "loading-light-shimmer cursor-pointer",
          isLoadingInteraction ? "loading-light-shimmer-on" : "",
          className,
        )}
      >
        <span className="loading-light-shimmer-child">
          {reprProjectInteractionType(interaction.type)}
        </span>
      </span>
      <span>for</span>
      <span
        className={classNames(
          "loading-light-shimmer cursor-pointer",
          isLoadingTask || isLoadingName ? "loading-light-shimmer-on" : "",
          className,
        )}
      >
        <span className="loading-light-shimmer-child">{name}</span>
      </span>
      <span>in</span>
      <span
        className={classNames(
          "loading-light-shimmer cursor-pointer",
          isLoadingProject ? "loading-light-shimmer-on" : "",
          className,
        )}
      >
        <span className="loading-light-shimmer-child">{project.name}</span>
      </span>
    </span>
  );
}
function SubtaskNote({
  className,
  project_id,
  task_id,
  subtask_id,
  note_id,
  prefix,
}: {
  className?: string;
  project_id?: string;
  task_id?: string;
  subtask_id?: string;
  note_id?: string;
  prefix?: string;
}) {
  const { data: subtask, isLoading: isLoadingSubtask } = useSubtask(subtask_id);
  const { data: project, isLoading: isLoadingProject } = useProject(project_id);
  const { data: task, isLoading: isLoadingTask } = useTask(task_id);
  const { data: name, isLoading: isLoadingName } = useFunderOrGrantName(
    task.target_id,
    task.target_type,
  );
  const { data: note, isLoading: isLoadingNote } = useSubtaskNote(note_id);
  return (
    <span className="flex flex-wrap items-center gap-x-1 gap-y-0">
      {prefix && <span>{prefix}</span>}
      <span>note on</span>
      <span
        className={classNames(
          "loading-light-shimmer cursor-pointer",
          isLoadingSubtask ? "loading-light-shimmer-on" : "",
          className,
        )}
      >
        <span className="loading-light-shimmer-child">{subtask.name}</span>
      </span>
      <span>for</span>
      <span
        className={classNames(
          "loading-light-shimmer cursor-pointer",
          isLoadingTask || isLoadingName ? "loading-light-shimmer-on" : "",
          className,
        )}
      >
        <span className="loading-light-shimmer-child">{name}</span>
      </span>
      <span>in</span>
      <span
        className={classNames(
          "loading-light-shimmer cursor-pointer",
          isLoadingProject ? "loading-light-shimmer-on" : "",
          className,
        )}
      >
        <span className="loading-light-shimmer-child">{project.name}</span>
      </span>
    </span>
  );
}
