import React from "react";
import { Tooltip, PlacesType } from "react-tooltip";
import "./css/tooltip.scss";
import classNames from "classnames";
interface GibooTooltipWrapperProps {
  id: string;
  maxWidth?: string;
  place?: PlacesType;
  children?: React.ReactNode;
  content?: React.ReactNode;
  wrapperClassName?: string;
  isOpen?: boolean;
  style?: React.CSSProperties;
  clickable?: boolean;
  offset?: number;
}

function GibooTooltipWrapper({
  id,
  content,
  maxWidth = "320px",
  place = "right",
  children,
  wrapperClassName,
  isOpen,
  style,
  clickable = false,
  offset,
}: GibooTooltipWrapperProps) {
  return (
    <>
      {content && (
        <Tooltip
          isOpen={isOpen}
          classNameArrow="hidden"
          wrapper="div"
          className={classNames(
            "!font-poppins text-sm !text-gray-900 shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)]",
            wrapperClassName,
          )}
          anchorId={id}
          place={place}
          style={{
            maxWidth: maxWidth,
            zIndex: 1000,
            opacity: 1,
            padding: 0,
            ...style,
          }}
          clickable={clickable}
          offset={offset}
        >
          {content}
          {children && children}
        </Tooltip>
      )}
    </>
  );
}

export default GibooTooltipWrapper;
