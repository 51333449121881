import { ChangeEventHandler } from "react";
import "../css/input.scss";

interface RangeSliderProps {
  id: string;
  min: number;
  max: number;
  value?: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

function RangeSlider({ id, min, max, value, onChange, disabled = false }: RangeSliderProps) {
  return (
    <div className="giboo-slider-single-container">
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        className="giboo-slider-single"
        id={id}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
}

export default RangeSlider;
