import classNames from "classnames";
import * as React from "react";
import "./css/button.scss";
import Spinner from "./Spinner";

interface PrimaryButtonProps {
  id: string;
  handleOnClick: (e: React.FormEvent) => void;
  className?: string;
  color?: "purple" | "red" | "yellow" | "green" | "blue";
  leftIconClass?: string | JSX.Element;
  rightIconClass?: string | JSX.Element;
  label?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  tonal?: boolean; // will be deprecated
  loading?: boolean;
  spinnerColor?: "purple" | "red" | "yellow" | "green" | "blue" | "gray" | "white";
  size?: "md" | "lg";
}

const PrimaryButton = React.forwardRef(function primaryButton(
  {
    spinnerColor = "white",
    size = "md",
    leftIconClass,
    rightIconClass,
    ...props
  }: PrimaryButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const className = classNames(
    "giboo-primary-btn flex prevent-select disabled:!border-0",
    props.className,
    props.color ? `giboo-primary-btn-${props.color}` : "",
  );
  return (
    <button
      id={props.id}
      ref={ref}
      disabled={props.disabled}
      onClick={(e) => {
        e.currentTarget.blur();
        props.handleOnClick(e);
      }}
      className={classNames(className, size === "lg" ? "!h-12" : "!h-9 !text-sm")}
      style={props.style}
    >
      {leftIconClass && typeof leftIconClass === "string" ? (
        <i className={leftIconClass}></i>
      ) : (
        leftIconClass
      )}
      {props.loading && <Spinner {...(spinnerColor && { color: spinnerColor })} size="sm" />}
      {props.label && <span className={"giboo-primary-btn-label"}>{props.label}</span>}
      {rightIconClass && typeof rightIconClass === "string" ? (
        <i className={rightIconClass}></i>
      ) : (
        rightIconClass
      )}
    </button>
  );
});

export default PrimaryButton;
