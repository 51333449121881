import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/header/Header";
import classNames from "classnames";
import { toDateString } from "../../utils/formatHelper";
import { useLocation } from "react-router-dom";
import usePageTitle from "../../hooks/usePagetitle";

interface navItem {
  id: number;
  title: string;
  subMenu?: navItem[];
  type: CONTENT_TYPE;
}

enum CONTENT_TYPE {
  INTERPRETAION_AND_DEFINITIONS,
  PROCESSING_OF_PERSONAL_DATA,
  CHILDRENS_PRIVACY,
  LINK_TO_OTHER_WEBSITE,
  CHANGES_TO_THIS_PRIVACY_POLICY,
  CONTACT_US,
  TYPES_OF_DATA_COLLECTED,
  SECURITY_OF_YOUR_PERSONAL_DATA,
  USE_OF_YOUR_PERSONAL_DATA,
  RETENTION_OF_YOUR_PERSONAL_DATA,
  TRANSFER_OF_YOUR_PERSONAL_DATA,
  DISCLOSURE_OF_YOUR_PERSONAL_DATA,
  COLLECTING_AND_USING_PERSONAL_DATA,
}
const NAV_DATA = [
  {
    title: "Interpretation and Definitions",
    id: 1,
    type: CONTENT_TYPE.INTERPRETAION_AND_DEFINITIONS,
  },
  {
    title: "Collecting and Using Your Personal Data",
    id: 2,
    type: CONTENT_TYPE.COLLECTING_AND_USING_PERSONAL_DATA,
    subContent: [
      {
        title: "Types of Data Collected",
        id: 1,
        type: CONTENT_TYPE.TYPES_OF_DATA_COLLECTED,
      },
      {
        title: "Use of Your Personal Data",
        id: 2,
        type: CONTENT_TYPE.USE_OF_YOUR_PERSONAL_DATA,
      },
      {
        title: "Retention of Your Personal Data",
        id: 3,
        type: CONTENT_TYPE.RETENTION_OF_YOUR_PERSONAL_DATA,
      },
      {
        title: "Transfer of Your Personal Data",
        id: 4,
        type: CONTENT_TYPE.TRANSFER_OF_YOUR_PERSONAL_DATA,
      },
      {
        title: "Disclosure of Your Personal Data",
        id: 5,
        type: CONTENT_TYPE.DISCLOSURE_OF_YOUR_PERSONAL_DATA,
      },
      {
        title: "Security of Your Personal Data",
        id: 6,
        type: CONTENT_TYPE.SECURITY_OF_YOUR_PERSONAL_DATA,
      },
    ],
  },
  {
    title: "Detailed Information on the Processing of Your Personal Data",
    id: 3,
    type: CONTENT_TYPE.PROCESSING_OF_PERSONAL_DATA,
  },
  {
    title: "Children's Privacy",
    id: 4,
    type: CONTENT_TYPE.CHILDRENS_PRIVACY,
  },
  {
    title: "Links to Other Websites",
    id: 5,
    type: CONTENT_TYPE.LINK_TO_OTHER_WEBSITE,
  },
  {
    title: "Changes to this Privacy Policy",
    id: 6,
    type: CONTENT_TYPE.CHANGES_TO_THIS_PRIVACY_POLICY,
  },
  {
    title: "Contact Us",
    id: 7,
    type: CONTENT_TYPE.CONTACT_US,
  },
];
function PrivacyPolicyView() {
  usePageTitle("Privacy policy");
  const { state: context } = useLocation();
  const [navList, setNavList] = useState<navItem[]>([]);
  const [activeTab, setActiveTab] = useState<CONTENT_TYPE>(
    CONTENT_TYPE.INTERPRETAION_AND_DEFINITIONS,
  );
  const [activeSubTab, setActiveSubTab] = useState<CONTENT_TYPE>(
    CONTENT_TYPE.TYPES_OF_DATA_COLLECTED,
  );
  const lastUpdatedDate = toDateString(new Date("2023-04-18"));
  useEffect(() => {
    const navList: navItem[] = NAV_DATA.map((item) => {
      return {
        title: item.title,
        id: item.id,
        type: item.type,
        subMenu: item.subContent?.map((item) => {
          return { id: item.id, title: item.title, type: item.type };
        }),
      };
    });
    setNavList(navList);
  }, []);
  useEffect(() => {
    if (context?.selected && context?.selected >= 0 && context?.selected < 13)
      setActiveTab(context?.selected);
  }, [context]);

  const handleTabSelection = (tab: CONTENT_TYPE, subTab?: CONTENT_TYPE) => {
    if (tab === CONTENT_TYPE.COLLECTING_AND_USING_PERSONAL_DATA) {
      if (subTab) {
        setActiveSubTab(subTab);
        setActiveTab(tab);
      } else {
        setActiveSubTab(CONTENT_TYPE.TYPES_OF_DATA_COLLECTED);
        setActiveTab(tab);
      }
    } else {
      setActiveTab(tab);
    }
  };
  const renderSideNav = () => {
    return (
      <div className="w-full md:w-96  md:pr-10">
        {navList.map((item: navItem, index: number) => {
          return (
            <div key={item.id + index} className="flex flex-col border-b-[1px] border-gray-600 ">
              <div
                onClick={() => {
                  handleTabSelection(item.type);
                }}
                className={classNames(
                  "cursor-pointer break-normal py-4 font-poppins text-base  text-gray-900  hover:text-purple-500 ",
                  {
                    "font-poppins text-base font-semibold text-purple-500": activeTab === item.type,
                    "pb-3": item?.subMenu,
                  },
                )}
              >
                {item.title}
              </div>
              {activeTab === CONTENT_TYPE.COLLECTING_AND_USING_PERSONAL_DATA && (
                <ul className={classNames("flex w-full flex-col px-10 md:ml-8 md:px-0")}>
                  {item?.subMenu?.map((subItem, index) => {
                    return (
                      <li
                        key={subItem.id + index}
                        onClick={() => {
                          handleTabSelection(item.type, subItem.type);
                        }}
                        className={classNames(
                          "cursor-pointer list-none  pb-3 pt-1 font-poppins  text-base text-gray-900  hover:text-purple-500",
                          {
                            "font-poppins text-base font-semibold text-purple-500":
                              activeSubTab === subItem.type,
                          },
                        )}
                      >
                        {subItem.title}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    );
  };
  const renderContentTitle = (contentTitle: string, customClass?: string) => {
    return (
      <div
        className={classNames(
          "py-2 font-poppins text-xl font-semibold text-purple-500 ",
          customClass,
        )}
      >
        {contentTitle}
      </div>
    );
  };
  const renderContentSubTitle = (subtitle: string, customClass?: string) => {
    return (
      <div
        className={classNames("mt-2 pt-2 font-poppins font-semibold text-purple-500", customClass)}
      >
        {subtitle}
      </div>
    );
  };
  const renderDescription = (description: JSX.Element, customClass?: string) => {
    return (
      <>
        <p className={classNames(" font-poppins text-base font-normal  leading-6", customClass)}>
          {description}
        </p>
      </>
    );
  };
  const renderItems = (items: JSX.Element[], customClass?: string) => {
    return (
      <div className={classNames("pl-2 font-poppins text-base font-normal leading-6", customClass)}>
        {items.map((item, i) => {
          return (
            <li key={i} className="list-inside list-disc">
              {item}
            </li>
          );
        })}
      </div>
    );
  };

  const renderContent = (contentType: CONTENT_TYPE) => {
    switch (contentType) {
      case CONTENT_TYPE.INTERPRETAION_AND_DEFINITIONS:
        return renderInterpretationAndDefinition();
      case CONTENT_TYPE.PROCESSING_OF_PERSONAL_DATA:
        return renderProgressionOfPersonalData();
      case CONTENT_TYPE.CHILDRENS_PRIVACY:
        return renderChildrensPrivacy();
      case CONTENT_TYPE.LINK_TO_OTHER_WEBSITE:
        return renderLinkToOtherWebsite();
      case CONTENT_TYPE.CHANGES_TO_THIS_PRIVACY_POLICY:
        return renderChangesToThisPrivacy();
      case CONTENT_TYPE.CONTACT_US:
        return renderContactUs();
      case CONTENT_TYPE.TYPES_OF_DATA_COLLECTED:
        return renderTypesOfDataCollected();
      case CONTENT_TYPE.COLLECTING_AND_USING_PERSONAL_DATA:
        return renderTypesOfDataCollected();
      case CONTENT_TYPE.USE_OF_YOUR_PERSONAL_DATA:
        return renderUseOfPersonalData();
      case CONTENT_TYPE.RETENTION_OF_YOUR_PERSONAL_DATA:
        return renderRetentionOfPersonalData();
      case CONTENT_TYPE.TRANSFER_OF_YOUR_PERSONAL_DATA:
        return renderTransferOfPersonalData();
      case CONTENT_TYPE.DISCLOSURE_OF_YOUR_PERSONAL_DATA:
        return renderDisclosureOfPersonalData();
      case CONTENT_TYPE.SECURITY_OF_YOUR_PERSONAL_DATA:
        return renderSecurityOfPersonalData();
      default:
        throw new Error(`Non-existent content type in switch: ${contentType}`);
    }
  };
  const renderLink = (link: string, title: string) => {
    return (
      <a href={link} target="_blank" rel="noreferrer" className="px-1 text-base">
        {title}
      </a>
    );
  };
  const renderInterpretationAndDefinition = () => {
    const contentList: JSX.Element[] = [
      <>
        Account means a unique account created for You to access our Service or parts of our
        Service.
      </>,
      <>
        Company (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or
        &quot;Our&quot; in this Agreement) refers to Giboo, inc., 1115 Broadway, New York, NY 10010.
      </>,
      <>
        Cookies are small files that are placed on Your computer, mobile device or any other device
        by a website, containing the details of Your browsing history on that website among its many
        uses.
      </>,
      <>Country refers to: New York, United States</>,
      <>
        Device means any device that can access the Service such as a computer, a cellphone or a
        digital tablet.
      </>,
      <>
        Personal Data is any information that relates to an identified or identifiable individual.
      </>,
      <>Service refers to the Website.</>,
      <>
        Service Provider means any natural or legal person who processes the data on behalf of the
        Company. It refers to third-party companies or individuals employed by the Company to
        facilitate the Service, to provide the Service on behalf of the Company, to perform services
        related to the Service or to assist the Company in analyzing how the Service is used.
      </>,
      <>
        Usage Data refers to data collected automatically, either generated by the use of the
        Service or from the Service infrastructure itself (for example, the duration of a page
        visit).
      </>,
      <>
        Website refers to Giboo, accessible from
        {renderLink("https://www.Giboo.com", " www.Giboo.com")}
      </>,
      <>
        You means the individual accessing or using the Service, or the company, or other legal
        entity on behalf of which such individual is accessing or using the Service, as applicable.
      </>,
    ];
    return (
      <div>
        {renderContentTitle("Interpretation and Definitions")}
        {renderContentSubTitle("Interpretation")}
        {renderDescription(
          <>
            The words of which the initial letter is capitalized have meanings defined under the
            following conditions. The following definitions shall have the same meaning regardless
            of whether they appear in singular or in plural.
          </>,
        )}
        {renderContentSubTitle("Definitions", "pt-3")}
        {renderDescription(<>For the purposes of this Privacy Policy:</>)}
        {renderItems(contentList)}
      </div>
    );
  };
  const renderTypesOfDataCollected = () => {
    const personalDataContentList: JSX.Element[] = [<>Email address</>, <> Usage Data</>];
    const trackingTechnologiesList: JSX.Element[] = [
      <>
        Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct
        Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if
        You do not accept Cookies, You may not be able to use some parts of our Service. Unless you
        have adjusted Your browser setting so that it will refuse Cookies, our Service may use
        Cookies.
      </>,
      <>
        Flash Cookies. Certain features of our Service may use local stored objects (or Flash
        Cookies) to collect and store information about Your preferences or Your activity on our
        Service. Flash Cookies are not managed by the same browser settings as those used for
        Browser Cookies. For more information on how You can delete Flash Cookies, please read
        &quot;Where can I change the settings for disabling, or deleting local shared objects?&quot;
        available at
        {renderLink(
          "https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling_or_deleting_local_shared_objects",
          "https://helpx.adobe.com/disable-local-shared-objects-flash",
        )}
      </>,
      <>
        Web Beacons. Certain sections of our Service and our emails may contain small electronic
        files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
        gifs) that permit the Company, for example, to count users who have visited those pages or
        opened an email and for other related website statistics (for example, recording the
        popularity of a certain section and verifying system and server integrity).
      </>,
    ];
    const trackingTechnologiesList2: JSX.Element[] = [
      <>
        Necessary / Essential CookiesType: Session CookiesAdministered by: UsPurpose: These Cookies
        are essential to provide You with services available through the Website and to enable You
        to use some of its features. They help to authenticate users and prevent fraudulent use of
        user accounts. Without these Cookies, the services that You have asked for cannot be
        provided, and We only use these Cookies to provide You with those services.
      </>,
      <>
        Cookies Policy / Notice Acceptance CookiesType: Persistent CookiesAdministered by:
        UsPurpose: These Cookies identify if users have accepted the use of cookies on the Website.
      </>,
      <>
        Functionality CookiesType: Persistent CookiesAdministered by: UsPurpose: These Cookies allow
        us to remember choices You make when You use the Website, such as remembering your login
        details or language preference. The purpose of these Cookies is to provide You with a more
        personal experience and to avoid You having to re-enter your preferences every time You use
        the Website.
      </>,
      <>
        Tracking and Performance CookiesType: Persistent CookiesAdministered by:
        Third-PartiesPurpose: These Cookies are used to track information about traffic to the
        Website and how users use the Website. The information gathered via these Cookies may
        directly or indirectly identify you as an individual visitor. This is because the
        information collected is typically linked to a pseudonymous identifier associated with the
        device you use to access the Website. We may also use these Cookies to test new pages,
        features or new functionality of the Website to see how our users react to them.
      </>,
    ];
    return (
      <div>
        {renderContentTitle("Collecting and Using Your Personal Data")}
        {renderContentSubTitle("Types of Data Collected")}
        <div className="px-3">
          {/* Personal Data */}
          {renderContentSubTitle("Personal Data")}
          {renderDescription(
            <>
              While using Our Service, We may ask You to provide Us with certain personally
              identifiable information that can be used to contact or identify You. Personally
              identifiable information may include, but is not limited to:
            </>,
          )}
          {renderItems(personalDataContentList)}
          {/* Usage Data */}
          {renderContentSubTitle("Usage Data", "pt-3")}
          {renderDescription(
            <>
              Usage Data is collected automatically when using the Service. Usage Data may include
              information such as Your Device&apos;s Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that You visit, the time and
              date of Your visit, the time spent on those pages, unique device identifiers and other
              diagnostic data.
            </>,
          )}
          <br />
          {renderDescription(
            <>
              When You access the Service by or through a mobile device, We may collect certain
              information automatically, including, but not limited to, the type of mobile device
              You use, Your mobile device unique ID, the IP address of Your mobile device, Your
              mobile operating system, the type of mobile Internet browser You use, unique device
              identifiers and other diagnostic data. We may also collect information that Your
              browser sends whenever You visit our Service or when You access the Service by or
              through a mobile device.
            </>,
          )}
          {/* Tracking Techonologies and Cookies */}
          {renderContentSubTitle("Tracking Technologies and Cookies", "pt-3")}
          {renderDescription(
            <>
              We use Cookies and similar tracking technologies to track the activity on Our Service
              and store certain information. Tracking technologies used are beacons, tags, and
              scripts to collect and track information and to improve and analyze Our Service. The
              technologies We use may include:
            </>,
          )}
          {renderItems(trackingTechnologiesList)}
          {renderDescription(
            <>
              Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You go offline, while
              Session Cookies are deleted as soon as You close Your web browser. Learn more about
              cookies on the
              {renderLink(
                "https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking",
                "Free Privacy Policy website",
              )}
              Article.
            </>,
            undefined,
          )}
          <br />
          {renderDescription(
            <>We use both Session and Persistent Cookies for the purposes set out below:</>,
          )}
          {renderItems(trackingTechnologiesList2)}
          {renderDescription(
            <>
              For more information about the cookies we use and your choices regarding cookies,
              please visit our Cookies Policy or the Cookies section of our Privacy Policy.
            </>,
          )}
        </div>
      </div>
    );
  };
  const renderUseOfPersonalData = () => {
    const personalDataList: JSX.Element[] = [
      <>To provide and maintain our Service, including to monitor the usage of our Service.</>,
      <>
        To manage Your Account: to manage Your registration as a user of the Service. The Personal
        Data You provide can give You access to different functionalities of the Service that are
        available to You as a registered user.
      </>,
      <>
        For the performance of a contract: the development, compliance and undertaking of the
        purchase contract for the products, items or services You have purchased or of any other
        contract with Us through the Service.
      </>,
      <>
        To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of
        electronic communication, such as a mobile application&apos;s push notifications regarding
        updates or informative communications related to the functionalities, products or contracted
        services, including the security updates, when necessary or reasonable for their
        implementation.
      </>,
      <>
        To provide You with news, special offers and general information about other goods, services
        and events which we offer that are similar to those that you have already purchased or
        enquired about unless You have opted not to receive such information.
      </>,
      <>To manage Your requests: To attend and manage Your requests to Us.</>,
      <>
        For business transfers: We may use Your information to evaluate or conduct a merger,
        divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some
        or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or
        similar proceeding, in which Personal Data held by Us about our Service users is among the
        assets transferred.
      </>,
      <>
        For other purposes: We may use Your information for other purposes, such as data analysis,
        identifying usage trends, determining the effectiveness of our promotional campaigns and to
        evaluate and improve our Service, products, services, marketing and your experience.
      </>,
    ];
    const personalDataList2: JSX.Element[] = [
      <>
        With Service Providers: We may share Your personal information with Service Providers to
        monitor and analyze the use of our Service, to contact You.
      </>,
      <>
        For business transfers: We may share or transfer Your personal information in connection
        with, or during negotiations of, any merger, sale of Company assets, financing, or
        acquisition of all or a portion of Our business to another company.
      </>,
      <>
        With Affiliates: We may share Your information with Our affiliates, in which case we will
        require those affiliates to honor this Privacy Policy. Affiliates include Our parent company
        and any other subsidiaries, joint venture partners or other companies that We control or
        that are under common control with Us.
      </>,
      <>
        With business partners: We may share Your information with Our business partners to offer
        You certain products, services or promotions.
      </>,
      <>
        With other users: when You share personal information or otherwise interact in the public
        areas with other users, such information may be viewed by all users and may be publicly
        distributed outside.
      </>,
      <>
        With Your consent: We may disclose Your personal information for any other purpose with Your
        consent.
      </>,
    ];
    return (
      <div>
        {renderContentTitle("Collecting and Using Your Personal Data")}
        {renderContentSubTitle("Use of Your Personal Data", "mb-4")}
        {renderDescription(<>The Company may use Personal Data for the following purposes:</>)}
        {renderItems(personalDataList)}
        {renderDescription(
          <>We may share Your personal information in the following situations:</>,
        )}
        {renderItems(personalDataList2)}
      </div>
    );
  };
  const renderRetentionOfPersonalData = () => {
    return (
      <div>
        {renderContentTitle("Collecting and Using Your Personal Data")}
        {renderContentSubTitle("Retention of Your Personal Data", "mb-4")}
        {renderDescription(
          <>
            The Company will retain Your Personal Data only for as long as is necessary for the
            purposes set out in this Privacy Policy. We will retain and use Your Personal Data to
            the extent necessary to comply with our legal obligations (for example, if we are
            required to retain your data to comply with applicable laws), resolve disputes, and
            enforce our legal agreements and policies.
          </>,
        )}
        <br />
        {renderDescription(
          <>
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is
            generally retained for a shorter period of time, except when this data is used to
            strengthen the security or to improve the functionality of Our Service, or We are
            legally obligated to retain this data for longer time periods.
          </>,
        )}
      </div>
    );
  };
  const renderTransferOfPersonalData = () => {
    return (
      <div>
        {renderContentTitle("Collecting and Using Your Personal Data")}
        {renderContentSubTitle("Transfer of Your Personal Data", "mb-4")}
        {renderDescription(
          <>
            Your information, including Personal Data, is processed at the Company&quot;s operating
            offices and in any other places where the parties involved in the processing are
            located. It means that this information may be transferred to — and maintained on —
            computers located outside of Your state, province, country or other governmental
            jurisdiction where the data protection laws may differ than those from Your
            jurisdiction.
          </>,
        )}
        <br />
        {renderDescription(
          <>
            Your consent to this Privacy Policy followed by Your submission of such information
            represents Your agreement to that transfer.
          </>,
        )}
        <br />
        {renderDescription(
          <>
            The Company will take all steps reasonably necessary to ensure that Your data is treated
            securely and in accordance with this Privacy Policy and no transfer of Your Personal
            Data will take place to an organization or a country unless there are adequate controls
            in place including the security of Your data and other personal information.
          </>,
        )}
      </div>
    );
  };
  const renderDisclosureOfPersonalData = () => {
    const disclosureDataList: JSX.Element[] = [
      <>Comply with a legal obligation</>,
      <>Protect and defend the rights or property of the Company</>,
      <>Prevent or investigate possible wrongdoing in connection with the Service</>,
      <>Protect the personal safety of Users of the Service or the public</>,
      <>Protect against legal liability</>,
    ];
    return (
      <div>
        {renderContentTitle("Collecting and Using Your Personal Data")}
        {renderContentSubTitle("Disclosure of Your Personal Data")}
        <div className="px-3">
          {renderContentSubTitle("Business Transactions", "pt-3")}
          {renderDescription(
            <>
              If the Company is involved in a merger, acquisition or asset sale, Your Personal Data
              may be transferred. We will provide notice before Your Personal Data is transferred
              and becomes subject to a different Privacy Policy.
            </>,
          )}
          {renderContentSubTitle("Law enforcement", "pt-3")}
          {renderDescription(
            <>
              Under certain circumstances, the Company may be required to disclose Your Personal
              Data if required to do so by law or in response to valid requests by public
              authorities (e.g. a court or a government agency).
            </>,
          )}
          {renderContentSubTitle("Other legal requirements", "pt-3")}
          {renderDescription(
            <>
              The Company may disclose Your Personal Data in the good faith belief that such action
              is necessary to:
            </>,
          )}
          {renderItems(disclosureDataList)}
        </div>
      </div>
    );
  };
  const renderSecurityOfPersonalData = () => {
    return (
      <div>
        {renderContentTitle("Collecting and Using Your Personal Data")}
        {renderContentSubTitle("Security of Your Personal Data", "mb-4")}
        {renderDescription(
          <>
            The security of Your Personal Data is important to Us, but remember that no method of
            transmission over the Internet, or method of electronic storage is 100% secure. While We
            strive to use commercially acceptable means to protect Your Personal Data, We cannot
            guarantee its absolute security.
          </>,
        )}
      </div>
    );
  };
  const renderProgressionOfPersonalData = () => {
    return (
      <div>
        {renderContentTitle("Detailed Information on the Processing of Your Personal Data", "mb-2")}
        {renderDescription(
          <>
            The Service Providers We use may have access to Your Personal Data. These third-party
            vendors collect, store, use, process and transfer information about Your activity on Our
            Service in accordance with their Privacy Policies.
          </>,
        )}
        {renderContentSubTitle(`Analytics`)}
        {renderDescription(
          <>
            We may use third-party Service providers to monitor and analyze the use of our Service.
          </>,
        )}
        <br />
        {renderDescription(
          <>
            Google AnalyticsGoogle Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected to track and monitor
            the use of our Service. This data is shared with other Google services. Google may use
            the collected data to contextualize and personalize the ads of its own advertising
            network.You can opt-out of having made your activity on the Service available to Google
            Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents
            the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information
            with Google Analytics about visits activity.For more information on the privacy
            practices of Google, please visit the Google Privacy & Terms web page:
            {renderLink(
              " https://policies.google.com/privacy",
              " https://policies.google.com/privacy",
            )}
          </>,
        )}
      </div>
    );
  };

  const renderChildrensPrivacy = () => {
    return (
      <div>
        {renderContentTitle("Children's Privacy", "mb-2")}

        {renderDescription(
          <>
            Our Service does not address anyone under the age of 13. We do not knowingly collect
            personally identifiable information from anyone under the age of 13. If You are a parent
            or guardian and You are aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data from anyone under
            the age of 13 without verification of parental consent, We take steps to remove that
            information from Our servers.
          </>,
        )}
        <br />
        {renderDescription(
          <>
            If We need to rely on consent as a legal basis for processing Your information and Your
            country requires consent from a parent, We may require Your parent&quot;s consent before
            We collect and use that information.
          </>,
        )}
      </div>
    );
  };
  const renderLinkToOtherWebsite = () => {
    return (
      <div>
        {renderContentTitle("Links to Other Websites", "mb-2")}
        {renderDescription(
          <>
            Our Service may contain links to other websites that are not operated by Us. If You
            click on a third party link, You will be directed to that third party&quot;s site. We
            strongly advise You to review the Privacy Policy of every site You visit.
          </>,
        )}
        <br />
        {renderDescription(
          <>
            We have no control over and assume no responsibility for the content, privacy policies
            or practices of any third party sites or services.
          </>,
        )}
      </div>
    );
  };
  const renderChangesToThisPrivacy = () => {
    return (
      <div>
        {renderContentTitle("Changes to this Privacy Policy", "mb-2")}
        {renderDescription(
          <>
            We may update Our Privacy Policy from time to time. We will notify You of any changes by
            posting the new Privacy Policy on this page.
          </>,
        )}
        <br />
        {renderDescription(
          <>
            We will let You know via email and/or a prominent notice on Our Service, prior to the
            change becoming effective and update the &quot;Last updated&quot; date at the top of
            this Privacy Policy.You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are posted on this page.
          </>,
        )}
      </div>
    );
  };
  const renderContactUs = () => {
    return (
      <div>
        {renderContentTitle("Contact Us", "mb-2")}
        {renderDescription(
          <>If you have any questions about this Privacy Policy, You can contact us:</>,
        )}
        <br />
        {renderDescription(
          <>By email:{renderLink("mailto:contact@giboo.com", "contact@giboo.com")} </>,
        )}
      </div>
    );
  };
  const renderPrivacyDescription = () => {
    return (
      <div className="my-4 font-poppins text-base font-normal  leading-6">
        {renderDescription(
          <>
            This Privacy Policy describes Our policies and procedures on the collection, use and
            disclosure of Your information when You use the Service and tells You about Your privacy
            rights and how the law protects You. We use Your Personal data to provide and improve
            the Service. By using the Service, You agree to the collection and use of information in
            accordance with this Privacy Policy.
          </>,
        )}
      </div>
    );
  };
  return (
    <div className="maxhs">
      <Header />
      <div className=" mt-24 h-full p-2">
        <div className="mx-auto max-w-[930px]">
          <h4 className="font-poppins text-3xl">Privacy Policy</h4>
          <div className="my-3">
            <p className="text-md font-sm text-muted font-poppins">
              Last Updated:{lastUpdatedDate}
            </p>
          </div>
          {renderPrivacyDescription()}
          <div className="mt-2 flex h-full w-full flex-col border-t-[1px] border-gray-600 pt-3 md:flex-row">
            <div className="w-full md:w-2/5">{renderSideNav()}</div>
            <div className="w-full whitespace-pre-line  md:mt-2 md:w-2/3 md:pl-10">
              {renderContent(
                activeTab === CONTENT_TYPE.COLLECTING_AND_USING_PERSONAL_DATA
                  ? activeSubTab
                  : activeTab,
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PrivacyPolicyView.propTypes = {};

export default PrivacyPolicyView;
