import { ILocation, reprLocation } from "../types/location";
import { NPO } from "../types/npo";
import { NPOSearchResult } from "../types/search";

const getNPOServiceLocation = (funder: { location?: ILocation; address?: string }): string => {
  return funder.location
    ? reprLocation(funder.location)
    : funder.address
    ? funder.address.split(",").slice(-3, -1).join(",")
    : "Open to any geograhpic location";
};

const getNPOHqLocation = (funder: { location?: ILocation; address?: string }): string => {
  return funder.location
    ? reprLocation(funder.location)
    : funder.address
    ? funder.address.split(",").slice(-3, -1).join(",")
    : "US";
};

export { getNPOServiceLocation, getNPOHqLocation };
