import classNames from "classnames";
import { Dispatch, SetStateAction, useState } from "react";
import Tag from "./Tag";
import SearchableDropdown from "./dropdown/SearchableDropdown";
import { TaxonomyConcept } from "../types/taxonomy";
import {
  beneficiaryAutoComplete,
  focusAutoComplete,
  programAutoComplete,
} from "../utils/autoComplete";
import { caseInsensitiveAndSpaceInsensitive } from "../utils/dataTypeHelper";

interface EditTaxonomyProps {
  wrapperClass?: string;
  focusArea: string[];
  beneficiary: string[];
  program: string[];
  setFocusArea: Dispatch<SetStateAction<string[]>>;
  setBeneficiary: Dispatch<SetStateAction<string[]>>;
  setProgram: Dispatch<SetStateAction<string[]>>;
}
function EditTaxonomy({
  wrapperClass,
  focusArea,
  beneficiary,
  program,
  setFocusArea,
  setBeneficiary,
  setProgram,
  ...props
}: EditTaxonomyProps) {
  const [showAddFocusArea, setShowAddFocusArea] = useState<boolean>(false);
  const [showAddBeneficiary, setShowAddBeneficiary] = useState<boolean>(false);
  const [showAddProgram, setShowAddProgram] = useState<boolean>(false);
  const [focusAreaQuery, setFocusAreaQuery] = useState<string>("");
  const [beneficiaryQuery, setBeneficiaryQuery] = useState<string>("");
  const [programQuery, setProgramQuery] = useState<string>("");
  const [addedFocusArea, setAddedFocusArea] = useState<string[]>(focusArea);
  const [addedBeneficiary, setAddedBeneficiary] = useState<string[]>(beneficiary);
  const [addedProgram, setAddedProgram] = useState<string[]>(program);
  const excludeFocusArea = focusArea.map((f) => caseInsensitiveAndSpaceInsensitive(f));
  const excludeBeneficiary = beneficiary.map((f) => caseInsensitiveAndSpaceInsensitive(f));
  const excludeProgram = program.map((f) => caseInsensitiveAndSpaceInsensitive(f));
  return (
    <div className={classNames("flex flex-col gap-5", wrapperClass)}>
      <div className="z-[2] flex flex-col gap-2">
        <p className="font-semibold">Focus area</p>
        <div className="flex flex-wrap gap-2">
          {[...focusArea.filter((i) => !addedFocusArea.includes(i)), ...addedFocusArea].map((i) => (
            <Tag
              key={`tag-focusarea-${i}`}
              id={`tag-focusarea-${i}`}
              matched
              useCheckbox
              checked={focusArea.includes(i)}
              onClick={() => {
                setFocusArea((prev) =>
                  prev.includes(i) ? prev.filter((j) => j !== i) : [...prev, i],
                );
              }}
              color="yellow"
              size="sm"
              name={i}
            />
          ))}
        </div>
        {showAddFocusArea ? (
          <div className="w-[500px] self-start">
            <SearchableDropdown<TaxonomyConcept>
              id="focus_area"
              color="yellow"
              query={focusAreaQuery}
              setQuery={setFocusAreaQuery}
              autoCompleteThrottleMilliseconds={300}
              autoCompleteFunction={focusAutoComplete}
              addItem={(item: TaxonomyConcept) => {
                if (!excludeFocusArea.includes(caseInsensitiveAndSpaceInsensitive(item.label))) {
                  setFocusArea((prev) => [...prev, item.label]);
                  setAddedFocusArea((prev) => [...prev, item.label]);
                }
              }}
              createItem={(item: string) => ({ type: "f", object_id: "", label: item })}
              renderItem={(item: TaxonomyConcept) => item.label}
              getKey={(item: TaxonomyConcept) =>
                `${caseInsensitiveAndSpaceInsensitive(item.label)}`
              }
              excludeKeys={excludeFocusArea}
              placeholder={`Search and add your focus area`}
              float
              maxHeight="300px"
              icon
              size="md"
              spinner
              spinnerBackgroundColor="bg-gray-50"
            />
          </div>
        ) : (
          <div className="self-start">
            <Tag
              id="tag-add-focusarea"
              name="Add focus area"
              onAdd={() => {
                setShowAddFocusArea(true);
              }}
              isFullTagClick
              size="sm"
            />
          </div>
        )}
      </div>
      <div className="z-[1] flex flex-col gap-2">
        <p className="font-semibold">Beneficiary</p>
        <div className="flex flex-wrap gap-2">
          {[...beneficiary.filter((i) => !addedBeneficiary.includes(i)), ...addedBeneficiary].map(
            (i) => (
              <Tag
                key={`tag-beneficiary-${i}`}
                id={`tag-beneficiary-${i}`}
                matched
                useCheckbox
                checked={beneficiary.includes(i)}
                onClick={() => {
                  setBeneficiary((prev) =>
                    prev.includes(i) ? prev.filter((j) => j !== i) : [...prev, i],
                  );
                }}
                color="blue"
                size="sm"
                name={i}
              />
            ),
          )}
        </div>
        {showAddBeneficiary ? (
          <div className="w-[500px] self-start">
            <SearchableDropdown<TaxonomyConcept>
              id="beneficiary"
              color="blue"
              query={beneficiaryQuery}
              setQuery={setBeneficiaryQuery}
              autoCompleteThrottleMilliseconds={300}
              autoCompleteFunction={beneficiaryAutoComplete}
              addItem={(item: TaxonomyConcept) => {
                if (!excludeBeneficiary.includes(caseInsensitiveAndSpaceInsensitive(item.label))) {
                  setBeneficiary((prev) => [...prev, item.label]);
                  setAddedBeneficiary((prev) => [...prev, item.label]);
                }
              }}
              createItem={(item: string) => ({ type: "b", object_id: "", label: item })}
              renderItem={(item: TaxonomyConcept) => item.label}
              getKey={(item: TaxonomyConcept) =>
                `${caseInsensitiveAndSpaceInsensitive(item.label)}`
              }
              excludeKeys={excludeBeneficiary}
              placeholder={`Search and add your beneficiary`}
              float
              maxHeight="300px"
              icon
              size="md"
              spinner
              spinnerBackgroundColor="bg-gray-50"
            />
          </div>
        ) : (
          <div className="self-start">
            <Tag
              id="tag-add-beneficiary"
              name="Add beneficiary"
              onAdd={() => {
                setShowAddBeneficiary(true);
              }}
              isFullTagClick
              size="sm"
            />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <p className="font-semibold">Program</p>
        <div className="flex flex-wrap gap-2">
          {[...program.filter((i) => !addedProgram.includes(i)), ...addedProgram].map((i) => (
            <Tag
              key={`tag-program-${i}`}
              id={`tag-program-${i}`}
              matched
              useCheckbox
              checked={program.includes(i)}
              onClick={() => {
                setProgram((prev) =>
                  prev.includes(i) ? prev.filter((j) => j !== i) : [...prev, i],
                );
              }}
              color="green"
              size="sm"
              name={i}
            />
          ))}
        </div>
        {showAddProgram ? (
          <div className="w-[500px] self-start">
            <SearchableDropdown<TaxonomyConcept>
              id="program"
              color="green"
              query={programQuery}
              setQuery={setProgramQuery}
              autoCompleteThrottleMilliseconds={300}
              autoCompleteFunction={programAutoComplete}
              addItem={(item: TaxonomyConcept) => {
                if (!excludeProgram.includes(caseInsensitiveAndSpaceInsensitive(item.label))) {
                  setProgram((prev) => [...prev, item.label]);
                  setAddedProgram((prev) => [...prev, item.label]);
                }
              }}
              createItem={(item: string) => ({ type: "p", object_id: "", label: item })}
              renderItem={(item: TaxonomyConcept) => item.label}
              getKey={(item: TaxonomyConcept) =>
                `${caseInsensitiveAndSpaceInsensitive(item.label)}`
              }
              excludeKeys={excludeProgram}
              placeholder={`Search and add your program`}
              float
              maxHeight="300px"
              icon
              size="md"
              spinner
              spinnerBackgroundColor="bg-gray-50"
            />
          </div>
        ) : (
          <div className="self-start">
            <Tag
              id="tag-add-program"
              name="Add program"
              onAdd={() => {
                setShowAddProgram(true);
              }}
              isFullTagClick
              size="sm"
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default EditTaxonomy;
