import { useCallback, useState } from "react";

export type SearchOptionsType =
  | "keyword"
  | "focus_area"
  | "beneficiary"
  | "program"
  | "service_loc"
  | "hq_loc"
  | "mission"
  | "funding_amount"
  | "stage"
  | "asset"
  | "grant_type"
  | "deadline";

const getStarKey = (key: SearchOptionsType, item?: string) => {
  return `${key}_${item ? item : ""}`;
};

const isMarkedAsStar = (stars: string[], key: SearchOptionsType, item?: string): boolean => {
  if (stars.length === 0) return false;
  return stars.includes(getStarKey(key, item));
};

function useStars() {
  const [stars, setStars] = useState<string[]>([]);

  const isStar = useCallback(
    (key: SearchOptionsType, item?: string): boolean => {
      return isMarkedAsStar(stars, key, item);
    },
    [stars],
  );
  const isAllStar = useCallback(
    (key: SearchOptionsType, item: string[]): boolean => {
      const starKey = getStarKey(key);
      const keys = stars.filter((i) => i.startsWith(starKey));
      return (
        keys.length >= item.length &&
        item
          .filter((i) => keys.includes(getStarKey(key, i)))
          .sort((a, b) => a.localeCompare(b))
          .reduce(
            (prev, cur) =>
              prev.length > 0 && prev[prev.length - 1] === cur ? prev : [...prev, cur],
            [] as string[],
          ).length === item.length
      );
    },
    [stars],
  );
  const clearStar = useCallback(
    (key: SearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      setStars((prev) => prev.filter((i) => i !== starKey));
    },
    [setStars],
  );
  const flipStar = useCallback(
    (key: SearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      setStars((prev) =>
        prev.includes(starKey) ? prev.filter((i) => i !== starKey) : [...prev, starKey],
      );
    },
    [setStars],
  );
  const setStar = useCallback(
    (key: SearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      setStars((prev) => [...prev.filter((i) => i !== starKey), starKey]);
    },
    [setStars],
  );
  const addAllStar = useCallback(
    (key: SearchOptionsType, item: string[]) => {
      const starKeys = item.map((i) => getStarKey(key, i));
      setStars((prev) => [...prev.filter((prevItem) => !starKeys.includes(prevItem)), ...starKeys]);
    },
    [setStars],
  );
  const clearAllStar = useCallback(
    (key: SearchOptionsType) => {
      const starKey = getStarKey(key);
      setStars((prev) => prev.filter((prevItem) => !prevItem.startsWith(starKey)));
    },
    [setStars],
  );
  const flipAllStar = useCallback(
    (key: SearchOptionsType, item: string[]) => {
      if (isAllStar(key, item)) clearAllStar(key);
      else addAllStar(key, item);
    },
    [isAllStar, addAllStar, clearAllStar],
  );
  return {
    stars,
    setStars,
    setStar,
    isStar,
    flipStar,
    isAllStar,
    flipAllStar,
    clearStar,
    clearAllStar,
  };
}
export { isMarkedAsStar, getStarKey };
export default useStars;
