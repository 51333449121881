import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { IProjectSubtask } from "../../types/project";
interface ProjectSubtasksState {
  data: IProjectSubtask[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IProjectSubtask[] | undefined>;
}
function useSubtasksByProject(project_id?: string): ProjectSubtasksState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/subtasks_by_project/${project_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectSubtask[]>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) =>
        (res.data as IProjectSubtask[]).sort(
          (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        ),
      );
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectSubtask[]>(
    project_id ? url : null,
    fetch,
    {
      keepPreviousData: false,
    },
  );
  const revalidate = useCallback(() => mutate<IProjectSubtask[]>(url), [url]);
  return {
    data: data || [],
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useSubtasksByProject;
