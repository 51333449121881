import {
  IOrgManualMember,
  IOrgManualMemberUpdateRequest,
  ITotalOrganizationMember,
} from "../types/org";
import useSWR, { useSWRConfig } from "swr";
import { useCallback } from "react";
import config from "../api";
import axios from "axios";
import useOrgManualMembers, { ORG_MEMBER_DEFAULT_LIMIT } from "./project/useOrgManualMembers";
import useOrgMember from "./useOrgMember";
import { swrOptionFetchOnlyOnMount } from "../types/swr";
import useOrgMembers from "./project/useOrgMembers";
interface TotalOrganizationMemberState {
  data: ITotalOrganizationMember | undefined;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  update: (req: IOrgManualMemberUpdateRequest) => Promise<ITotalOrganizationMember | undefined>;
  deleteThis: () => Promise<boolean>;
  revalidate: () => Promise<ITotalOrganizationMember | undefined>;
}
const useTotalOrganizationMember = (
  org_id?: string,
  member_id?: string,
): TotalOrganizationMemberState => {
  const type = member_id && member_id.includes("_") ? member_id.split("_")[0] : undefined;
  const id = member_id && member_id.includes("_") ? member_id.split("_")[1] : undefined;
  const url = process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/orgmember/${id}`;
  const urlUserMember = process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/member/${id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url || !org_id || type !== "manual" || !id)
      return new Promise<ITotalOrganizationMember>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => res.data as IOrgManualMember)
      .then((res) => ({ ...res, type: "manual" }));
  };
  const { getOptimisticDataOnUpdate: getOptimisticDataOnUpdateManualMember } = useOrgManualMembers(
    type === "manual" ? org_id : undefined,
  );
  const {
    data: manualMember,
    isLoading,
    error,
    isValidating,
  } = useSWR<ITotalOrganizationMember>(
    org_id && type === "manual" && id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const {
    data: userMember,
    isLoading: userMemberIsLoading,
    error: userMemberError,
    isValidating: userMemberIsValidating,
    update: userMemberUpdate,
  } = useOrgMember(org_id, type === "user" && id ? id : undefined);
  const update = useCallback(
    async (req: IOrgManualMemberUpdateRequest) => {
      if (type === "manual") {
        if (!manualMember) return;
        const options = {
          optimisticData: { ...manualMember, ...req },
          rollbackOnError: true,
        };
        mutate(
          process.env.REACT_APP_API_URL +
            `/api/v2/orgs/${org_id}/orgmembers?limit=${ORG_MEMBER_DEFAULT_LIMIT}`,
          getOptimisticDataOnUpdateManualMember(id, req),
          {
            revalidate: false,
          },
        );
        return mutate<ITotalOrganizationMember>(
          url,
          axios
            .put(url, req, config)
            .then((response) => response.data)
            .then((res) => ({ ...res, type: "manual" })),
          options,
        ).then((res) => {
          return res ? { ...res, type: "manual" } : undefined;
        });
      } else if (type === "user") {
        if (!userMember) return;
        return userMemberUpdate(req);
      }
    },
    [org_id, url, urlUserMember, manualMember, userMember],
  );

  const deleteThis = useCallback(async () => {
    if (url && type === "manual" && id)
      return axios.delete(url, config).then((res) => {
        if (res.data)
          mutate(
            process.env.REACT_APP_API_URL +
              `/api/v2/orgs/${org_id}/orgmembers?limit=${ORG_MEMBER_DEFAULT_LIMIT}`,
          );
        return res.data;
      });
    else return new Promise<boolean>((resolve, reject) => reject());
  }, [url, org_id, type, id]);
  const revalidate = useCallback(() => mutate<ITotalOrganizationMember>(url), [url]);
  return {
    data: type === "manual" ? manualMember : userMember,
    isLoading: isLoading || userMemberIsLoading,
    error: error || userMemberError,
    update,
    isValidating: isValidating || userMemberIsValidating,
    revalidate,
    deleteThis,
  };
};
export default useTotalOrganizationMember;
