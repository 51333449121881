import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/store";
import projectSlice, { fetchProjectsAsync } from "../app/projectSlice";
import { getOnboardingStatus } from "../app/onboardingSlice";
import axios from "axios";
import useUser from "../hooks/useUser";
import config from "../api";
import { useLocation, useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { User } from "../app/userSlice";
import { getUserDisctionaryValue } from "./useUserDictionary";
import { fetchMoreSearchOptionAsync } from "../app/moreSearchOptionSlice";

interface useOrgState {
  user?: User;
  isLoading?: boolean;
}
export default function useLogin(redirectToHome = true): useOrgState {
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const isLogin = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/api/v2/auth/session",
          config,
        );
        const org_id = searchParams.get("org_id");
        if (org_id) setUser(response.data, org_id);
        else
          getUserDisctionaryValue("selected_org")
            .then((res) => {
              if (res && typeof res === "string") setUser(response.data, res);
              else setUser(response.data);
            })
            .catch(() => setUser(response.data));
      } catch (err) {
        if (redirectToHome) navigate("/");
        setUser(null);
      } finally {
        setLoading(false);
      }
    };
    isLogin();
  }, []);
  return {
    user,
    isLoading,
  };
}

interface redirectState {
  redirectPath: string | undefined;
  // setRedirectPath: Dispatch<SetStateAction<string | undefined>>;
  hasMatchedPath: boolean;
}
export function useRedirect(): redirectState {
  const pathsToCheck = [
    "/grants/federal/:id",
    "/grants/philanthropy/:id",
    "/organization/:id",
    "/project/:id",
    "/public/project/:id",
  ];
  const { pathname } = useLocation();
  const isFederalGrant = useMatch(pathsToCheck[0])?.pathname ? true : false;
  const isPhilGrant = useMatch(pathsToCheck[1])?.pathname ? true : false;
  const isOrg = useMatch(pathsToCheck[2])?.pathname ? true : false;
  const isProject = useMatch(pathsToCheck[3])?.pathname ? true : false;
  const isPublicProject = useMatch(pathsToCheck[3])?.pathname ? true : false;
  const isMatched = isFederalGrant || isPhilGrant || isOrg || isProject || isPublicProject;
  return {
    redirectPath: pathname,
    hasMatchedPath: isMatched,
  };
}
