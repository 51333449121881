import { delay } from "lodash";
import { useEffect, useLayoutEffect, useState } from "react";

function useScrollTracking({
  childElementIds=[],
  dependency,
  headerElementId,
  offset = 100,
  parentElementId,
  callBack,
}: {
  parentElementId: string | undefined;
  childElementIds?: string[];
  offset?: number;
  headerElementId?: string | undefined;
  dependency?: any;
  callBack?: () => void;
}) {
  const [topElement, setTopElement] = useState<string | null>(null);
  const [isReachedEnd, setIsReachedEnd] = useState<boolean>(false);
  const [scrollY, setScrollY] = useState<number>(0);
  const [showNav, setShowNav] = useState<boolean>(false);
  const [tempSeletected, setTempSeletected] = useState<string | null>(null);
  const parentElement = parentElementId ? document.getElementById(parentElementId) : window;
  if (process.env.REACT_APP_DISABLE_HEADER === "TRUE") {
    //
  } else {
    //header nav auto scroll to left or right
    useEffect(() => {
      if (!topElement) return;
      const childElement = document.getElementById(`nav-${topElement}`);
      const parentElement = document.getElementById("giboo-slider-component");
      if (childElement && parentElement) {
        const childRect = childElement.getBoundingClientRect();
        const parentRect = parentElement.getBoundingClientRect();
        // Checking: child element is not fully visible horizontally
        if (childRect.right > parentRect.right || childRect.left < parentRect.left) {
          parentElement.scrollBy({
            left: childRect.left - parentRect.left - 40,
            behavior: "smooth",
          });
        }
      }
    }, [topElement]);
  }
  const handleScroll = () => {
    if (callBack) {
      callBack?.();
      return;
    }
    if (process.env.REACT_APP_DISABLE_HEADER === "TRUE") return;
    let scrollTop = 0;
    if (parentElement instanceof Window) {
      scrollTop = window.scrollY;
    } else if (parentElement instanceof HTMLElement) {
      scrollTop = parentElement.scrollTop;
    }
    const isAtBottom =
      parentElement instanceof Window
        ? window.innerHeight + window.scrollY >= document.body.offsetHeight
        : (parentElement?.scrollHeight || 0) - (parentElement as HTMLElement).scrollTop ===
          (parentElement as HTMLElement).clientHeight;
    // setScrollY(scrollTop);
    delay(() => setScrollY(scrollTop), 1500);
    let topElementId = null;
    childElementIds.forEach((childId: string) => {
      const childElement = document.getElementById(`${childId}`);
      if (childElement) {
        const childRect = childElement.getBoundingClientRect();
        const parentTop =
          parentElement instanceof HTMLElement ? parentElement.getBoundingClientRect().top : 0;
        const childTopRelativeToParent = childRect.top - parentTop;
        const isAtTop = childTopRelativeToParent <= offset;
        // console.log("scroll-track", {
        //   isAtTop,
        //   childId,
        //   childTop: childRect.top,
        //   parentTop,
        //   offset,
        //   isAtBottom,
        //   childElementIds,
        // });
        if (isAtTop) {
          topElementId = childId;
        }
      }
    });
    // showNav
    if (headerElementId && parentElement) {
      const headerElement = document.getElementById(headerElementId);
      if (headerElement) {
        const headerRect = headerElement.getBoundingClientRect();
        setShowNav(scrollTop >= headerRect.height);
      }
    }
    tempSeletected && setTopElement(tempSeletected);
    !tempSeletected && setTopElement(topElementId);
    if (isAtBottom) {
      setTopElement(childElementIds[childElementIds.length - 1]);
    }
    setIsReachedEnd(isAtBottom);
  };
  const handleScrollEnd = () => {
    tempSeletected && setTempSeletected(null);
  };
  if (process.env.REACT_APP_DISABLE_HEADER === "TRUE") {
    //
  } else {
    useEffect(() => {
      if (parentElement instanceof Window) {
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("scrollend", handleScrollEnd);
      } else if (parentElement instanceof HTMLElement) {
        parentElement.addEventListener("scroll", handleScroll);
        parentElement.addEventListener("scrollend", handleScrollEnd);
      }
      return () => {
        if (parentElement instanceof Window) {
          window.removeEventListener("scroll", handleScroll);
          window.addEventListener("scrollend", handleScrollEnd);
        } else if (parentElement instanceof HTMLElement) {
          parentElement.removeEventListener("scroll", handleScroll);
          parentElement.addEventListener("scrollend", handleScrollEnd);
        }
      };
    }, [parentElementId, childElementIds, offset, dependency]);
  }
  const scrollToTop = (offset = 0) => {
    if (parentElement instanceof Window) {
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    } else if (parentElement instanceof HTMLElement) {
      parentElement.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };
  const scrollTo = (id: string, scrollvalue = 0) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      !showNav && setTempSeletected(id);
      const parentTop =
        parentElement instanceof HTMLElement ? parentElement.getBoundingClientRect().top : 0;
      const targetTop = targetElement.getBoundingClientRect().top - parentTop;
      if (parentElement instanceof Window) {
        window.scrollTo({
          top: targetTop + window.scrollY - offset + scrollvalue,
          behavior: "smooth",
        });
      } else if (parentElement instanceof HTMLElement) {
        parentElement.scrollTo({
          top: targetTop + parentElement.scrollTop - offset + scrollvalue,
          behavior: "smooth",
        });
      }
    }
  };
  const reset = () => {
    setTopElement(null);
    setIsReachedEnd(false);
    setScrollY(0);
    setShowNav(false);
  };
  return {
    topElement,
    setTopElement,
    isReachedEnd,
    scrollY,
    scrollToTop,
    scrollTo,
    showNav,
    reset,
  };
}
export default useScrollTracking;
