import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import useOrgID from "../useOrgID";
import { GibooDocument } from "../../types/document";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface LatestDocumentState {
  data: GibooDocument | undefined;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<GibooDocument | undefined>;
}
function useLatestDocument(
  project_id?: string,
  task_id?: string,
  last_days = 1,
  type: "created" | "updated" = "created",
): LatestDocumentState {
  const url =
    process.env.REACT_APP_API_URL +
    `/api/v2/document/latest?project_id=${project_id || ""}&task_id=${
      task_id || ""
    }&days=${last_days}&type=${type}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string, controller?: AbortController) => {
    if (!_url) return new Promise<GibooDocument>((resolve, reject) => reject());
    return axios
      .get(_url, { ...config, ...(controller ? { signal: controller.signal } : {}) })
      .then((res) => (res.status === 200 ? (res.data as GibooDocument) : undefined));
  };
  const { data, isLoading, error, isValidating } = useSWR<GibooDocument | undefined>(
    project_id || task_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<GibooDocument>(url), [url]);
  return {
    data: data,
    isLoading: isLoading,
    error: error,
    isValidating,
    revalidate,
  };
}
export default useLatestDocument;
