import useSWR from "swr";
import axios from "axios";
import config from "../../api";
import { User } from "../../app/userSlice";
import { swrOptionDedupling5mins } from "../../types/swr";
interface UserState {
  data: User | undefined;
  error: any;
  isLoading: boolean;
}
function useUserDetail(userId?: string): UserState {
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const { data, isLoading, error } = useSWR<User>(
    userId ? process.env.REACT_APP_API_URL + `/api/v2/users/${userId}` : null,
    fetch,
    swrOptionDedupling5mins,
  );

  return {
    data: data,
    isLoading,
    error,
  };
}
export default useUserDetail;
