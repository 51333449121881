import React, { ReactNode, useEffect, useState } from "react";
import ExpandableCard from "./tailwind/ExpandableCard";
import classNames from "classnames";
import Spacer from "./Spacer";
import useOrgDataForNPODashboard from "../hooks/useOrgDataForNPODashboard";
import useOrgProfile from "../hooks/useOrgProfile";
import useTotalOrganizationMembersData from "../hooks/useTotalOrganizationMembersData";
import Button from "./tailwind/Button";
import GotoSmartSearchContext from "./useGotoSmartSearch";
import Spinner from "./Spinner";
import useUser from "../hooks/useUser";

interface IfinishSetup {
  isPrivateView?: boolean;
  compact?: boolean;
  wrapperClass?: string;
  id?: string;
  withoutLogin?: boolean;
  isProfileClaim?: boolean;
  triggerAction: (type: CallBackAction) => void;
}
type CallBackAction =
  | "UPLOAD_LOGO"
  | "UPLOAD_COVER"
  | "REVIEW_ABOUT"
  | "REVIEW_PHILANTHROPIC"
  | "REVIEW_TAGS"
  | "START_FINANCIAL_ASSESSMENT"
  | "START_DIVERSITY_ASSESSMENT"
  | "CONTACT_INFORMATION"
  | "VERIFY_ORG"
  // | "PROJECT_CREATE"
  | "INVITE_MEMBER";
enum TsetupActions {
  // PROJECT_COMPLETED = "projectCompleted",
  MEMBERS_COMPLETED = "membersCompleted",
  PROFILE_IMAGE_COMPLETED = "profileImageCompleted",
  PROFILE_BG_COMPLETED = "profileBgImageCompleted",
  VERIFIED_COMPLETED = "verifiedCompleted",
  VERIFIED_CONTACT = "verifiedContact",
  FINANCIAL_INFO_COMPLETED = "financialInfoCompleted",
  REVIEWED_TAGS_COMPLETED = "reviewedTagsCompleted",
  REVIEWED_PHILANTHROPIC_COMPLETED = "reviewedPhilanthropicCompleted",
  REVIEWED_ABOUT_COMPLETED = "reviewedAboutCompleted",
}
export default function FinishOrgSetup({
  id,
  isPrivateView,
  compact,
  wrapperClass,
  withoutLogin,
  triggerAction,
}: IfinishSetup) {
  const {
    org_id,
    npo_id,
    data: orgData,
    projects,
    hasWritePermission,
    projectsIsLoading,
    isLoading,
  } = useOrgDataForNPODashboard(id, isPrivateView);
  const { data: profileDetail } = useOrgProfile(org_id);
  const { data: members, isLoading: membersIsLoading } = useTotalOrganizationMembersData(org_id);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [completedAll, setCompletedAll] = useState(false);
  const [user] = useUser();
  const [conditions, setConditions] = useState<{
    [key in TsetupActions]: { completed: boolean };
  }>({
    // [TsetupActions.PROJECT_COMPLETED]: { completed: false },
    [TsetupActions.MEMBERS_COMPLETED]: { completed: false },
    [TsetupActions.PROFILE_IMAGE_COMPLETED]: { completed: false },
    [TsetupActions.PROFILE_BG_COMPLETED]: { completed: false },
    [TsetupActions.VERIFIED_COMPLETED]: { completed: false },
    [TsetupActions.VERIFIED_CONTACT]: { completed: false },
    [TsetupActions.FINANCIAL_INFO_COMPLETED]: { completed: false },
    [TsetupActions.REVIEWED_TAGS_COMPLETED]: { completed: false },
    [TsetupActions.REVIEWED_PHILANTHROPIC_COMPLETED]: { completed: false },
    [TsetupActions.REVIEWED_ABOUT_COMPLETED]: { completed: false },
  });
  useEffect(() => {
    const membersCount = members.filter((i) => i._id !== user?._id).length > 0;
    const hasProfileImage = !!orgData?.photo_object_key;
    const hasBgImage = !!orgData?.background_object_key;
    const hasVerified = !!orgData?.verified;
    const hasContactReviewed = !!profileDetail.reviewed_contacts;
    const hasFinancialReviewed = !!profileDetail.reviewed_financial;
    const hasReviewedTags = !!profileDetail.reviewed_tags;
    const hasReviewedPhilanthropic = !!profileDetail.reviewed_philanthropic;
    const hasReviewedAbout = !!profileDetail.reviewed_basic_information;

    const conditions: { [key in TsetupActions]: { completed: boolean } } = {
      // [TsetupActions.PROJECT_COMPLETED]: { completed: projectsCount },
      [TsetupActions.MEMBERS_COMPLETED]: { completed: membersCount },
      [TsetupActions.PROFILE_IMAGE_COMPLETED]: { completed: hasProfileImage },
      [TsetupActions.PROFILE_BG_COMPLETED]: { completed: hasBgImage },
      [TsetupActions.VERIFIED_COMPLETED]: { completed: hasVerified },
      [TsetupActions.FINANCIAL_INFO_COMPLETED]: { completed: hasFinancialReviewed },
      [TsetupActions.REVIEWED_TAGS_COMPLETED]: { completed: hasReviewedTags },
      [TsetupActions.REVIEWED_PHILANTHROPIC_COMPLETED]: { completed: hasReviewedPhilanthropic },
      [TsetupActions.REVIEWED_ABOUT_COMPLETED]: { completed: hasReviewedAbout },
      [TsetupActions.VERIFIED_CONTACT]: { completed: hasContactReviewed },
    };
    setConditions(conditions);
    const count = Object.values(conditions).reduce((acc, condition) => {
      return acc + (condition.completed ? 1 : 0);
    }, 0);
    const totalCount = Object.values(conditions).length;
    setTotalCount(totalCount);
    setCompletedCount(count);
    setCompletedAll(count === totalCount);
  }, [projects, members, orgData, profileDetail]);

  const renderpercentage = () => {
    const percentage = Math.min((completedCount / totalCount) * 100, 100).toFixed(0);
    return (
      <div className="inline-flex h-fit w-full  items-center gap-3">
        <div className="relative h-4 grow rounded-full bg-gray-200">
          <div
            className="giboo-gradient-bg h-full rounded-full"
            style={{ width: `${percentage}%` }}
          />
        </div>
        <h5 className="text-xs text-gray-700">{`${completedCount}/${totalCount}`}</h5>
      </div>
    );
  };

  const renderContent = () => {
    const renderItem = ({
      title,
      description,
      label,
      completed,
      onClick,
    }: {
      title: string;
      description: string | ReactNode;
      label: string;
      completed: boolean;
      onClick: () => void;
    }) => {
      return (
        <div
          className={classNames(
            "flex h-[138px] min-w-[329px] flex-col  rounded border border-gray-200 bg-[#F8FAFD] p-4 transition-all",
            completed && "order-last delay-[3000ms]",
          )}
        >
          <div className="inline-flex items-center gap-2">
            {completed && (
              <span className="grid h-5 w-5 place-items-center rounded-full bg-green-500">
                <i className="gi-xs gi-check mt-0.5 text-[10px] text-white" />
              </span>
            )}
            <h5 className="font-poppins text-sm font-semibold text-gray-900">{title}</h5>
          </div>
          <Spacer height="8px" />
          <div className="font-poppins text-xs text-black">{description}</div>
          <Spacer height="12px" />
          <div className="">
            <Button
              id="btn-setup"
              className="!h-[29px] !border-gray-500 !text-gray-600"
              label={completed ? "Completed" : label}
              outline
              color="gray"
              handleOnClick={onClick}
              {...(completed && {
                prependIcon: (
                  <>
                    <i className="gi gi-check" />
                  </>
                ),
              })}
            />
          </div>
        </div>
      );
    };
    if (completedAll) {
      return <></>;
    }
    return (
      <div className="inline-flex w-full snap-x items-center gap-5 overflow-x-scroll">
        {renderItem({
          title: "Confirm About organization",
          description: (
            <>
              Accurate organization information can increase{" "}
              <b className="font-semibold text-purple-500">profile views by up to 2.2 times.</b>
            </>
          ),
          label: "Go to About organization",
          completed: conditions?.reviewedAboutCompleted?.completed,
          onClick: () => {
            triggerAction("REVIEW_ABOUT");
          },
        })}
        {renderItem({
          title: "Confirm Philanthropic focus",
          description: (
            <>
              An accurate and detailed philanthropic focus attracts{" "}
              <b className="font-semibold text-purple-500">3.8 times more funders.</b>
            </>
          ),
          label: "Go to Philanthropic focus",
          completed: conditions?.reviewedPhilanthropicCompleted?.completed,
          onClick: () => {
            triggerAction("REVIEW_PHILANTHROPIC");
          },
        })}

        {renderItem({
          title: "Confirm Organization tags",
          description: (
            <>
              Properly tagged profiles have{" "}
              <b className="font-semibold text-purple-500">
                3.8 times higher discoverability in searches.
              </b>
            </>
          ),
          label: "Go to Organization tags",
          completed: conditions?.reviewedTagsCompleted?.completed,
          onClick: () => {
            triggerAction("REVIEW_TAGS");
          },
        })}
        {renderItem({
          title: "Confirm financial information",
          description: (
            <>
              Accurate financial information can{" "}
              <b className="font-semibold text-purple-500">
                increase funding chances by up to 72%.
              </b>
            </>
          ),
          label: "Go to financial information",
          completed: conditions?.financialInfoCompleted?.completed,
          onClick: () => {
            triggerAction("START_FINANCIAL_ASSESSMENT");
          },
        })}
        {renderItem({
          title: "Confirm contact information",
          description: (
            <>
              Accurate contact information increases outreach from funders and other npos by{" "}
              <b className="font-semibold text-purple-500">4 times..</b>
            </>
          ),
          label: "Go to Contact information",
          completed: conditions?.verifiedContact?.completed,
          onClick: () => {
            triggerAction("CONTACT_INFORMATION");
          },
        })}
        {renderItem({
          title: "Upload organization logo",
          description: (
            <>
              Upload the logo and receive up to{" "}
              <b className="font-semibold text-purple-500">2.5 times as many profile views.</b>
            </>
          ),
          label: "Upload logo",
          completed: conditions?.profileImageCompleted?.completed,
          onClick: () => {
            triggerAction("UPLOAD_LOGO");
          },
        })}
        {renderItem({
          title: "Upload organization cover image",
          description: (
            <>
              Profiles with a cover image attract up to{" "}
              <b className="font-semibold text-purple-500">
                2.5 times more visitors and increase engagement.
              </b>
            </>
          ),
          label: "Upload cover image",
          completed: conditions?.profileBgImageCompleted?.completed,
          onClick: () => {
            triggerAction("UPLOAD_COVER");
          },
        })}

        {renderItem({
          title: "Verify organization",
          description: (
            <>
              Verify your organization to{" "}
              <b className="font-semibold text-purple-500">publicly showcase your profile.</b>
            </>
          ),
          label: "Verify organization",
          completed: conditions?.verifiedCompleted?.completed,
          onClick: () => {
            triggerAction("VERIFY_ORG");
          },
        })}

        {renderItem({
          title: "Invite organization members",
          description: (
            <>
              Invite the organization members to{" "}
              <b className="font-semibold text-purple-500">
                collaborate and increase the chances of funding.
              </b>
            </>
          ),
          label: "Invite members",
          completed: conditions?.membersCompleted?.completed,
          onClick: () => {
            triggerAction("INVITE_MEMBER");
          },
        })}
      </div>
    );
  };

  return (
    <ExpandableCard
      id="org-setup"
      title="Finish setting up your organization profile"
      allowExpand={false}
      allowEdit={isPrivateView && hasWritePermission}
      showAllDetail={false}
      customClass={compact ? "border-0 rounded-none" : ""}
      editContainer={<></>}
    >
      <div className={classNames("mt-2 flex flex-col gap-6", wrapperClass)}>
        <div className="inline-flex gap-3 rounded bg-gray-50 p-4">
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 7C13.5 7.82843 12.8284 8.5 12 8.5C11.1716 8.5 10.5 7.82843 10.5 7C10.5 6.17157 11.1716 5.5 12 5.5C12.8284 5.5 13.5 6.17157 13.5 7Z"
                fill="#5C38A7"
              />
              <path
                d="M18 9.5C18 10.3284 17.3284 11 16.5 11C15.6716 11 15 10.3284 15 9.5C15 8.67157 15.6716 8 16.5 8C17.3284 8 18 8.67157 18 9.5Z"
                fill="#5C38A7"
              />
              <path
                d="M9 10C9 10.8284 8.32843 11.5 7.5 11.5C6.67157 11.5 6 10.8284 6 10C6 9.17157 6.67157 8.5 7.5 8.5C8.32843 8.5 9 9.17157 9 10Z"
                fill="#5C38A7"
              />
              <path
                d="M10 15.5C10 16.3284 9.32843 17 8.5 17C7.67157 17 7 16.3284 7 15.5C7 14.6716 7.67157 14 8.5 14C9.32843 14 10 14.6716 10 15.5Z"
                fill="#5C38A7"
              />
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M19.9998 11.9449C19.9702 7.552 16.3999 4 12 4C7.58172 4 4 7.58172 4 12C4 16.3999 7.552 19.9702 11.9449 19.9998C11.9539 19.9856 11.9644 19.9658 11.9739 19.9397C11.9896 19.8967 12 19.8444 12 19.7866V18C12 14.6863 14.6863 12 18 12H19.7866C19.8444 12 19.8967 11.9896 19.9397 11.9739C19.9658 11.9644 19.9856 11.9539 19.9998 11.9449ZM22 12C22 13.1714 20.9581 14 19.7866 14H18C15.7909 14 14 15.7909 14 18V19.7866C14 20.9581 13.1714 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                fill="#5C38A7"
              />
            </svg>
          </span>{" "}
          <h5 className="texs font-poppins">
            By completing these steps, you can showcase your organization profile to the public.
            <br /> This process only takes about <b className="text-purple-500">8 minutes.</b>
          </h5>
        </div>
        {renderpercentage()}
        {renderContent()}
      </div>
    </ExpandableCard>
  );
}
