import { useState } from "react";
import Button from "../components/tailwind/Button";
import useMySavedList from "../hooks/save/useMySavedList";
import useUser from "../hooks/useUser";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { reprTimeDistance } from "../utils/formatHelper";
import UserProfile from "../components/UserProfile";
import SavePopup from "../components/SavePopup";
import { SaveList } from "../components/SaveList";
import Spinner from "../components/Spinner";
import Spacer from "../components/Spacer";
import PrimaryButton from "../components/PrimaryButton";
import EMPTY from "../assets/images/empty-saved-list.svg";
import usePageTitle from "../hooks/usePagetitle";

export default function SavedListView() {
  usePageTitle("Saved");
  const [user] = useUser();
  const navigate = useNavigate();
  const { data, isLoading, handleDeleteSavedList, renameSavedList, updateSavedList } =
    useMySavedList();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const sharedLength = data.filter((d) => user?._id && d.user_id !== user?._id).length;
  const hasData = data.length > 0;
  const renderEmpty = () => {
    return (
      <div className="flex h-full  flex-col rounded-t border border-gray-300 bg-white">
        <Spacer height="140px" />
        <div className="flex justify-center">
          <div className="grid w-[462px] place-items-center">
            <img src={EMPTY} className="h-[167px] w-[162px]" />
            <Spacer height="36px" />
            <p className="text-center text-sm font-normal text-gray-800">
              Looks like you haven&apos;t created a saved list yet! <br /> Try creating your own
              list and effortlessly save grant opportunities, funders, or peer nonprofits that catch
              your interest!
            </p>
            <Spacer height="20px" />
            <PrimaryButton
              size="md"
              id={`btn-create-new-list`}
              label={"Create a new list"}
              className="!h-[29px] !text-sm"
              leftIconClass="gi-add gi-md"
              handleOnClick={() => setOpenPopup(true)}
            />
          </div>
        </div>
        <Spacer height="140px" />
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-4" style={{ height: "calc(100vh - 64px)" }}>
      <SavePopup
        open={openPopup}
        target_id={""}
        target_type={0}
        forceCreateMode={true}
        onClose={() => setOpenPopup(false)}
        onCreate={() => {
          setOpenPopup(false);
        }}
      />
      {isLoading ? (
        <div
          className="flex w-full items-center justify-center"
          style={{ height: "calc(100vh - 100px)" }}
        >
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          <Spacer height="40px" />
          <div className="flex flex-col gap-5">
            <div className="flex items-center justify-between">
              <h4>Saved list</h4>
              {hasData && (
                <Button
                  id="btn-new-save-list"
                  handleOnClick={() => setOpenPopup(true)}
                  size="md"
                  className="!h-[29px]"
                  label="Create a new list"
                  prependIcon={<i className="gi-md gi-add" />}
                />
              )}
            </div>
            <div className="flex justify-between">
              <h5 className="text-black">
                {`${data.length - sharedLength} saved list`}
                {sharedLength > 0 ? <span>&bull;</span> : null}
                {sharedLength > 0 ? <span>{`${sharedLength} shared list`}</span> : null}
              </h5>
            </div>
          </div>
          <div className="h-[1px] w-full bg-gray-300"></div>
          {hasData ? (
            <div className="grid grid-cols-2 gap-4 pb-10">
              {data.map((d) => (
                <SaveList
                  key={d._id}
                  onClick={() => navigate(`/saved/${d._id}`)}
                  saveList={d}
                  pageView={true}
                  onDelete={() => handleDeleteSavedList(d._id)}
                  onRename={(newName) => renameSavedList(d._id, newName)}
                  onUpdate={(newName, newDescription) =>
                    updateSavedList(d._id, { name: newName, description: newDescription })
                  }
                />
              ))}
            </div>
          ) : (
            renderEmpty()
          )}
        </>
      )}
    </div>
  );
}
