import { ReactNode, useEffect, useState } from "react";
import useSubtasksByProject from "../hooks/project/useSubtasksByProject";
import Spacer from "./Spacer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import classNames from "classnames";
import { TASK_STATUS } from "../pages/npo/projects/MyTasks";
import { IProjectSubtask } from "../types/project";
import UserProfile from "./UserProfile";
import { toDateString } from "../utils/formatHelper";
import { createProjectSubtask, updateProjectSubtask } from "../hooks/project/useSubtask";
import useSubtasks from "../hooks/project/useSubtasks";
import GibooToast from "./GibooToast";
import TaskCreationModal from "./TaskCreationModal";
import UserList from "./UserList";

function TaskKanbanBoard({ projectId, taskId }: { projectId?: string; taskId?: string }) {
  const {
    data: tasksList,
    revalidate: revalidate2,
    isLoading,
    isValidating,
  } = useSubtasksByProject(projectId);
  const {
    data: subtask,
    revalidate,
    isLoading: isLoading2,
    isValidating: isValidating2,
  } = useSubtasks(taskId);
  const [allData, setAllData] = useState<IProjectSubtask[]>(taskId ? subtask : tasksList);
  const [showTaskCreation, setShowTaskCreation] = useState<boolean>(false);
  const globalLoading = isLoading || isValidating || isValidating2 || isLoading2;
  const [fromStatus, setFromStatus] = useState<TASK_STATUS>(TASK_STATUS.DONE);
  useEffect(() => {
    if (globalLoading) return;
    setAllData(taskId ? subtask : tasksList);
  }, [globalLoading, subtask, tasksList]);
  useEffect(() => {
    return () => {
      projectId && revalidate2();
      taskId && revalidate();
    };
  }, [allData]);
  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    const targetStatus: TASK_STATUS = destination.droppableId as TASK_STATUS;
    const sourceStatus: TASK_STATUS = source.droppableId as TASK_STATUS;
    const sourceData = allData.filter((i) => i.status === sourceStatus)[source.index];
    // console.log({ sourceData: sourceData, targetStatus });
    //droped in an unknown destination
    if (!destination) return;
    //droped in the same position
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    //droped in same column but in a different position
    if (source.droppableId === destination.droppableId) {
      return;
    }
    // from one column to another
    if (sourceData && targetStatus) {
      if (!sourceData) return;
      setAllData((prevData) => {
        return prevData.map((task) => {
          if (task._id === sourceData?._id) {
            return { ...task, status: targetStatus };
          }
          return task;
        });
      });
      sourceData &&
        updateProjectSubtask(sourceData._id, { status: targetStatus })
          .catch((e) => {
            projectId && revalidate2();
            taskId && revalidate();
            GibooToast({
              type: "failure",
              message: `We apologize for the inconvenience. Please try again.`,
            });
          })
          .finally(() => {
            // projectId && revalidate2();
            // taskId && revalidate();
          });
      return;
    }
  };

  return (
    <>
      {showTaskCreation && (
        <TaskCreationModal
          open={showTaskCreation}
          setOpen={setShowTaskCreation}
          projectId={projectId}
          taskId={taskId}
          defaultStatus={fromStatus}
          onTaskCreation={() => {
            revalidate2?.();
            revalidate();
            setShowTaskCreation(false);
          }}
        />
      )}
      <div
        className=" flex w-full grow flex-col gap-5  rounded bg-white"
        style={{ minHeight: "calc(100vh - 220px)" }}
      >
        <div className="grid h-full w-full grid-cols-[308px_308px_308px] bg-gray-50 p-4">
          <DragDropContext onDragEnd={onDragEnd}>
            <Column
              id={TASK_STATUS.NOT_STARTED}
              title={"Not started"}
              tasks={allData.filter((i) => i.status === TASK_STATUS.NOT_STARTED)}
              footer={
                <span
                  className="flex !h-[36px] w-full cursor-pointer items-center truncate rounded !border-0 text-gray-700 hover:bg-purple-50  "
                  onClick={() => {
                    setFromStatus(TASK_STATUS.NOT_STARTED);
                    setShowTaskCreation(true);
                  }}
                >
                  <i className="gi gi-add mr-1 text-sm" />
                  <h5>add task</h5>
                </span>
              }
              revalidate={() => {
                revalidate2?.();
                revalidate();
              }}
            />
            <Column
              id={TASK_STATUS.IN_PROGRESS}
              title={"In progress"}
              tasks={allData.filter((i) => i.status === TASK_STATUS.IN_PROGRESS)}
              footer={
                <span
                  className="flex !h-[36px] w-full cursor-pointer items-center truncate rounded !border-0 text-gray-700 hover:bg-purple-50 "
                  onClick={() => {
                    setFromStatus(TASK_STATUS.IN_PROGRESS);
                    setShowTaskCreation(true);
                  }}
                >
                  <i className="gi gi-add mr-1 text-sm" />
                  <h5>add task</h5>
                </span>
              }
              revalidate={() => {
                revalidate2?.();
                revalidate();
              }}
            />
            <Column
              id={TASK_STATUS.DONE}
              title={"Done"}
              tasks={allData.filter((i) => i.status === TASK_STATUS.DONE)}
              footer={
                <span
                  className="flex !h-[36px] w-full cursor-pointer items-center truncate rounded !border-0 text-gray-700 hover:bg-purple-50 "
                  onClick={() => {
                    setFromStatus(TASK_STATUS.DONE);
                    setShowTaskCreation(true);
                  }}
                >
                  <i className="gi gi-add mr-1 text-sm" />
                  <h5>add task</h5>
                </span>
              }
              revalidate={() => {
                revalidate2?.();
                revalidate();
              }}
            />
          </DragDropContext>
        </div>
      </div>
    </>
  );
}
export default TaskKanbanBoard;
function Column({
  title,
  type,
  id,
  tasks,
  header,
  footer,
  revalidate,
}: {
  id: TASK_STATUS;
  title: string;
  type?: TASK_STATUS;
  tasks: IProjectSubtask[];
  header?: ReactNode;
  footer?: ReactNode;
  revalidate?: () => void;
}) {
  const [showTaskCreation, setShowTaskCreation] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState("");
  return (
    <>
      {showTaskCreation && (
        <TaskCreationModal
          open={showTaskCreation}
          setOpen={setShowTaskCreation}
          onClose={() => {
            setSelectedTask("");
          }}
          isEditMode
          taskId={selectedTask}
          onTaskCreation={() => {
            setShowTaskCreation(false);
            revalidate?.();
          }}
        />
      )}
      <div className="h-full w-[308px] p-3">
        {header ? (
          header
        ) : (
          <span
            className="grid h-[25px] w-fit  place-items-center rounded px-[10px] font-poppins text-sm text-gray-900 last:mb-0"
            style={{
              background: {
                "Not started": "#DEDEDE",
                "In progress": "#FFEC8B",
                Done: "#7FC8F8",
              }[title],
            }}
          >
            {title}
          </span>
        )}
        <Spacer height="20px" />
        <Droppable droppableId={id}>
          {(droppableProvided, droppableSnapshot) => (
            <div
              className={classNames(
                "relative flex  w-full flex-col gap-3 overflow-y-scroll rounded",
                droppableSnapshot.isDraggingOver ? "bg-purple-50/30" : "",
              )}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              style={{ maxHeight: "calc(100vh - 300px)" }}
            >
              {tasks.map((task, index) => (
                <Draggable key={task?._id} draggableId={`${task?._id}`} index={index}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      className={classNames(
                        " flex h-[111px]  w-full flex-col justify-between  gap-2 rounded-md border border-gray-200 bg-white p-4 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.08)]",
                        draggableSnapshot.isDragging ? "border-purple-100" : "",
                      )}
                      onClick={() => {
                        setSelectedTask(task._id);
                        setShowTaskCreation(true);
                      }}
                    >
                      <h6 className="truncate font-poppins text-base font-semibold text-black">
                        {task?.name}
                      </h6>
                      <p
                        title={task?.content}
                        className="truncate font-poppins text-sm font-normal text-gray-800 "
                      >
                        {task?.content || "None"}
                      </p>
                      <div className="flex items-center justify-between">
                        <UserList
                          users={task.users}
                          maxUsers={1}
                          size={16}
                          withName
                          nameClass="max-w-[80px]"
                        />
                        <div className="inline-flex items-center text-xs text-gray-800">
                          Due: {task?.due_date ? toDateString(task?.due_date, true, false) : "-"}
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              <div className="min-h-fit">{footer}</div>
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
}
