import { ReactNode } from "react";
import no_graph from "../../img/no-graph.svg";

export default function NoGraph() {
  return (
    <div className="mt-3">
      <img src={no_graph} />
    </div>
  );
}
