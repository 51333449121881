import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import useProject from "../../../hooks/project/useProject";
import { useEffect, useState } from "react";
import { formatRelative, subDays } from "date-fns";
import Checkbox from "../../../components/checkbox/Checkbox";
import { IOption } from "../../../components/tailwind/AsyncCreatableSelector";
import DropdownSelector from "../../../components/dropdown/DropdownSelector";
import Spacer from "../../../components/Spacer";
import GibooAiTaskAssistant from "../../../components/GibooAiTaskAssistant";
import PlainButton from "../../../components/PlainButton";
import { GibooDocument } from "../../../types/document";
import useDocumentSearch from "../../../hooks/project/useDocumentSearch";
import { getValidNumber } from "../../../utils/formatHelper";
import { Pagination } from "rsuite";
import Spinner from "../../../components/Spinner";
import MemoizedLoadingDocument from "../../../components/LoadingDocument";
import ProjectName from "../../../components/ProjectName";

const SORT_TYPE: IOption[] = [
  { label: "Last updated", value: "last_updated" },
  { label: "A-Z", value: "az" },
  { label: "Z-A", value: "za" },
];
const unit = 8;
function ProjectDocumentList() {
  const { id: projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [sorting, setSorting] = useState<IOption>(SORT_TYPE[0]);
  const navigate = useNavigate();
  // const { data: projectData, hasEditPermission, isLoading, setMemberRole } = useProject(projectId);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  useEffect(() => {
    const _page = getValidNumber(searchParams.get("page")) || 1;
    const _sort = searchParams.get("sorting");
    const _valid_sort = SORT_TYPE.find((o) => o.value === _sort) || SORT_TYPE[0];
    setPage(_page);
    setSorting(_valid_sort);
  }, [searchParams]);
  const { data, size, error, isLoading, isSizeLoading, revalidate } = useDocumentSearch(
    "",
    undefined,
    undefined,
    (page - 1) * unit,
    unit,
    -1,
    sorting.value,
  );
  return (
    <>
      <div className="flex flex-col gap-6">
        <GibooAiTaskAssistant id="project-management-document-list" />
        <div className="flex w-full max-w-[1368px] justify-between">
          <div />
          <div className="inline-flex w-fit items-center gap-3">
            {/* <PlainButton
              className="!w-[110px] whitespace-nowrap !p-0 !text-sm"
              id={`open`}
              label="Start new"
              color="purple"
              leftIconClass={"gi-md gi-add"}
              handleOnClick={() => {
                navigate(`/project/${projectId}/document`);
              }}
            /> */}
            <DropdownSelector<IOption>
              id={`sort-type-selector`}
              defaultValue={sorting}
              selected={sorting}
              inputClass="!h-[35px] !w-[139px] !bg-white !text-black"
              setSelected={(v) => {
                setSorting(v ? v : SORT_TYPE[0]);
              }}
              renderButton={(i) => i.label}
              data={SORT_TYPE}
              compact
              downIcon
              getKey={(i) => (i ? i.label : "")}
              renderItem={(i) => <div className="py-2 font-normal text-black">{i.label}</div>}
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          {isLoading ? (
            <MemoizedLoadingDocument loading={isLoading} max={4} />
          ) : (
            data
              .filter((item) => item.project_id)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex h-[180px] w-[332px] max-w-[332px] cursor-pointer flex-col justify-between rounded border bg-white px-5 py-4 hover:bg-gray-100"
                    onClick={() => navigate(`/project/${item.project_id}/document/${item._id}`)}
                  >
                    <div className="flex flex-col gap-3">
                      <div className="flex w-full justify-between">
                        <div>
                          <h6 className="font-poppins text-base font-semibold">{item.name}</h6>
                        </div>
                        {/* <div>
                      <Checkbox
                        id={`input-check-`}
                        checked={selectedItems.includes(item._id)}
                        size="sm"
                        onChange={() => {
                          if (selectedItems.includes(item._id)) {
                            setSelectedItems((prev) => [...prev.filter((i) => i !== item._id)]);
                          } else {
                            setSelectedItems((prev) => [item._id, ...prev]);
                          }
                        }}
                      />
                    </div> */}
                      </div>
                      <h6
                        className="giboo-chat-component line-clamp-2 truncate text-gray-700"
                        dangerouslySetInnerHTML={{ __html: item.output || "" }}
                      />
                    </div>
                    <div className="w-full">
                      {item.project_id && (
                        <div className="inline-flex w-full items-center gap-2">
                          <i className="gi-sm gi-project text-gray-700" />
                          <div className="inline-flex gap-1 ">
                            <h5 className="text-xs font-semibold text-gray-700">Project:</h5>
                            <ProjectName id={item.project_id} className="text-xs text-gray-700" />
                          </div>
                        </div>
                      )}
                      <div className="mt-[1px] inline-flex w-full items-center gap-2">
                        <span className="h-4 w-4">
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.375 1.75H9.625V0.875H8.75V1.75H5.25V0.875H4.375V1.75H2.625C2.14375 1.75 1.75 2.14375 1.75 2.625V11.375C1.75 11.8562 2.14375 12.25 2.625 12.25H11.375C11.8562 12.25 12.25 11.8562 12.25 11.375V2.625C12.25 2.14375 11.8562 1.75 11.375 1.75ZM11.375 11.375H2.625V5.25H11.375V11.375ZM11.375 4.375H2.625V2.625H4.375V3.5H5.25V2.625H8.75V3.5H9.625V2.625H11.375V4.375Z"
                              fill="#5F5F5F"
                            />
                          </svg>
                        </span>

                        <div className="inline-flex gap-1 ">
                          <h5 className="text-xs font-semibold text-gray-700">Updated:</h5>
                          <h5 className="text-xs text-gray-700">
                            {formatRelative(subDays(new Date(item.updated_at), 3), new Date())}
                          </h5>
                        </div>
                      </div>
                      <div className="inline-flex w-full items-center gap-2">
                        <span className="h-4 w-4">
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.6845 4.50297L8.62203 1.44047C8.58137 1.39985 8.53311 1.36765 8.48 1.34569C8.42689 1.32373 8.36997 1.31245 8.3125 1.3125H3.0625C2.83044 1.3125 2.60788 1.40469 2.44378 1.56878C2.27969 1.73288 2.1875 1.95544 2.1875 2.1875V11.8125C2.1875 12.0446 2.27969 12.2671 2.44378 12.4312C2.60788 12.5953 2.83044 12.6875 3.0625 12.6875H10.9375C11.1696 12.6875 11.3921 12.5953 11.5562 12.4312C11.7203 12.2671 11.8125 12.0446 11.8125 11.8125V4.8125C11.8125 4.75503 11.8013 4.69811 11.7793 4.645C11.7574 4.59189 11.7251 4.54363 11.6845 4.50297ZM8.75 2.80602L10.319 4.375H8.75V2.80602ZM10.9375 11.8125H3.0625V2.1875H7.875V4.8125C7.875 4.92853 7.92109 5.03981 8.00314 5.12186C8.08519 5.20391 8.19647 5.25 8.3125 5.25H10.9375V11.8125Z"
                              fill="#5F5F5F"
                            />
                          </svg>
                        </span>

                        <div className="flex w-full items-center gap-1">
                          <h5 className="text-xs font-semibold text-gray-700">Documented:</h5>
                          <h5 className="text-xs text-gray-700">
                            {formatRelative(subDays(new Date(item.created_at), 3), new Date())}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
          )}
        </div>
        {isLoading ? null : !isSizeLoading ? (
          <div className="self-center">
            <Pagination
              prev={true}
              last={false}
              next={true}
              first={false}
              size="md"
              total={size}
              limit={unit}
              maxButtons={10}
              ellipsis
              activePage={page}
              onChangePage={(_page) => {
                setSearchParams([
                  ["sort", sorting.value],
                  ["page", `${_page}`],
                ]);
              }}
            />
          </div>
        ) : (
          <Spinner size="sm" />
        )}
        <Spacer height="40px" />
      </div>
    </>
  );
}
export default ProjectDocumentList;
