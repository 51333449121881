import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { swrOptionDedupling5mins, swrOptionFetchOnlyOnMount } from "../../types/swr";
import { DiversityRecommendedActions } from "../../types/npo";
interface DiversityRecommendedActionsState {
  data: DiversityRecommendedActions | undefined;
  error: any;
  isLoading: boolean;
}
type DiversityRecommendedActionTypes =
  | "age"
  | "disability"
  | "ethnicity"
  | "gender"
  | "profession"
  | "religion"
  | "sexual"
  | "diversity_assessment";

function useDiversityRecommendedAction(
  org_id?: string,
  type?: DiversityRecommendedActionTypes,
): DiversityRecommendedActionsState {
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<DiversityRecommendedActions>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data);
  };
  const { data, isLoading, error } = useSWR<DiversityRecommendedActions>(
    org_id && type
      ? process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/recommend_action?type=${type}`
      : null,
    fetch,
    swrOptionDedupling5mins,
  );

  return {
    data: data,
    isLoading,
    error,
  };
}
export default useDiversityRecommendedAction;
