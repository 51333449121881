import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import NpoHeader from "./components/NpoHeader";
import Spacer from "../../../components/Spacer";
import useOrgProjects from "../../../hooks/useOrgProjects";
import useOrgMembers from "../../../hooks/project/useOrgMembers";
import Button from "../../../components/tailwind/Button";
import { Portal } from "@headlessui/react";
import useOrgDataForNPODashboard from "../../../hooks/useOrgDataForNPODashboard";
import { useEffect, useRef, useState } from "react";
import useOnboardingData from "../../../hooks/useOnboarding";
import useRequestOrgVerification from "../../../hooks/useRequestOrgVerification";
import useUser from "../../../hooks/useUser";
import { differenceInHours, format, formatDistanceToNow, parseISO } from "date-fns";
import RecNPOs from "../../../components/RecNPOs";
import { scrollBarClass } from "../../../utils/classes";
import GibooToast from "../../../components/GibooToast";
import useTotalOrganizationMembersData from "../../../hooks/useTotalOrganizationMembersData";
import useNPOMembers from "../../../hooks/useNPOMembers";
import useScrollTopByDefault from "../../../hooks/useScrollTopByDefault";
import VerifyOrganizationModal from "../../../components/VerifyOrganizationModal";
import {
  getISearchParamFromOnboardingData,
  getSearchQueryFromOnboardingData,
} from "../../../app/onboardingSlice";
import { verify } from "crypto";
import NpoView from "./pages/NpoView";
import OrgInvitationPopup from "../../../components/OrgInvitationPopup";
import useOrgProfile from "../../../hooks/useOrgProfile";
import { SearchQuery, getSearchQueryFromISearchParam } from "../../../types/search";
import BackButton from "../../../components/BackButton";
export interface UserView {
  withoutLogin?: boolean;
  isPrivateView?: boolean;
  hasWritePermission?: boolean;
  showSimilarOrg?: boolean;
  npoId?: string;
  showGuidance?: boolean;
}
function NpoLayout({
  withoutLogin = false,
  isPrivateView,
  showSimilarOrg = true,
  showGuidance = false,
  npoId,
}: UserView) {
  const lastDivRef = useRef<HTMLDivElement>(null);
  const recDivRef = useRef<HTMLDivElement>(null);
  const [user] = useUser();
  const location = useLocation();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [onboardingData] = useOnboardingData();
  const {
    org_id,
    npo_id,
    data: orgData,
    projects,
    hasWritePermission,
    projectsIsLoading,
    isLoading,
  } = useOrgDataForNPODashboard(id, isPrivateView);
  const { data: profileDetail } = useOrgProfile(org_id);
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const { data: members, isLoading: membersIsLoading } = useTotalOrganizationMembersData(org_id);
  const { data: peopleNPO, isLoading: peopleNPOIsLoading } = useNPOMembers(npo_id);
  const navigate = useNavigate();
  const [selectedQuery, setSelectedQuery] = useState<SearchQuery>(
    getSearchQueryFromISearchParam(
      user?._id || "",
      onboardingData,
      getISearchParamFromOnboardingData(onboardingData),
    ),
  );
  useEffect(() => {
    setSelectedQuery(
      location?.state?.context?.query
        ? location?.state?.context.query
        : getSearchQueryFromISearchParam(
            user?._id || "",
            onboardingData,
            getISearchParamFromOnboardingData(onboardingData),
          ),
    );
  }, [location, user, onboardingData]);
  const [showOrgVerify, setShowOrgVerify] = useState<boolean>(false);
  const showPortal =
    !isPrivateView &&
    ((npo_id && npo_id === onboardingData.npo_id && npo_id !== id) ||
      (!npo_id && onboardingData._id === org_id));
  const {
    data: requestStatus,
    request,
    isLoading: orgVerifyLoading,
  } = useRequestOrgVerification(isPrivateView ? org_id : undefined);
  const [toggle, setToggle] = useState(false);
  const [orgVerifyStep, setOrgVerifyStep] = useState(0);
  const [recHeight, setRecHeight] = useState<number>(1500);
  useEffect(() => {
    if (orgVerifyLoading || isLoading) return;
    if (requestStatus.requested === false && !onboardingData.verified) {
      setOrgVerifyStep(0);
    } else if (requestStatus.requested === true && !onboardingData.verified) {
      const timeDiffHours = differenceInHours(new Date(), new Date(requestStatus.created_at));
      if (timeDiffHours < 24) {
        setOrgVerifyStep(1);
      } else {
        setOrgVerifyStep(2);
      }
    }
    if (onboardingData.verified) {
      setOrgVerifyStep(3);
    }
  }, [orgData, requestStatus, isLoading, orgVerifyLoading]);
  useScrollTopByDefault();
  useEffect(() => {
    if (lastDivRef?.current && recDivRef?.current) {
      setRecHeight(Math.max(1000, lastDivRef.current.offsetTop - recDivRef.current.offsetTop - 60));
    } else {
      setRecHeight(1500);
    }
  }, [lastDivRef.current?.offsetTop, recDivRef.current?.offsetTop]);

  useEffect(() => {
    if (
      location.pathname.includes("diversity") &&
      !membersIsLoading &&
      members &&
      members.length === 0 &&
      !peopleNPOIsLoading &&
      peopleNPO &&
      peopleNPO.length === 0
    ) {
      navigate(location.pathname.split("/").slice(0, -1).join("/"), { replace: true });
    }
    if (
      location.pathname.includes("projects") &&
      !projectsIsLoading &&
      projects &&
      projects.length === 0
    ) {
      navigate(location.pathname.split("/").slice(0, -1).join("/"), { replace: true });
    }
  }, [location, projectsIsLoading, membersIsLoading, members, projects]);

  const handleRequest = () => {
    setShowOrgVerify(true);
    // if (!user || !orgData) return;
    // setLoading(true);
    // request({
    //   ...user,
    //   npo_id: orgData.npo_id || "",
    //   npo_name: orgData.npo_name || "",
    //   type: orgData.type,
    //   origin: window.location.origin,
    //   files: [],
    // })
    //   .then(() => {
    //     GibooToast({
    //       type: "success",
    //       message: `Successfully requested`,
    //     });
    //   })
    //   .catch(() => {
    //     GibooToast({
    //       type: "failure",
    //       message: `We apologize for the inconvenience., please try again`,
    //     });
    //   })
    //   .finally(() => setLoading(false));
  };
  const renderRightContainer = () => {
    const totalCount = 6;
    const projectsCount = projects.length || 0;
    const membersCount = members.length || 0;
    const hasProfileImage = orgData?.photo_object_key ? true : false;
    const hasBgImage = orgData?.background_object_key ? true : false;
    const hasVerified = orgData?.verified;
    const hasDiversityInfo = profileDetail.diversity_completed;
    const hasFinancialInfo = profileDetail.financial_completed;
    let completedCount = 0;
    const increaseCount = () => (completedCount = completedCount + 1);
    if (projectsCount > 0) {
      completedCount = completedCount + 1;
    }

    if (hasProfileImage) {
      increaseCount();
    }
    if (hasProfileImage) {
      increaseCount();
    }
    if (hasBgImage) {
      increaseCount();
    }
    if (hasVerified) {
      increaseCount();
    }
    if (hasDiversityInfo) {
      increaseCount();
    }
    if (hasFinancialInfo) {
      increaseCount();
    }
    const renderItem = (completed = false, label: string) => {
      return (
        <div className="inline-flex w-fit items-center gap-3">
          {completed ? (
            <span className="grid h-4 w-4 place-items-center rounded-full bg-purple-500 p-[1px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M11.6667 4.08398L5.83341 9.91732L2.91675 7.00065"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          ) : (
            <span className="h-4 w-4 rounded-full border border-gray-500 bg-white"></span>
          )}
          {label}
        </div>
      );
    };
    const percentage = Math.min((completedCount / totalCount) * 100, 100).toFixed(0);
    return (
      <div className="h-fit w-full rounded border border-gray-300 bg-white p-5">
        <div className="relative h-5 rounded-full bg-gray-200">
          <div
            className="giboo-gradient-bg h-full rounded-full"
            style={{ width: `${percentage}%` }}
          />
        </div>
        <Spacer height="12px" />
        <h5 className="text-sm text-gray-700">{`${percentage}% completed`}</h5>
        <Spacer height="28px" />
        <div className="flex flex-col gap-4">
          {renderItem(hasProfileImage, "Upload organization logo")}
          {renderItem(hasBgImage, "Upload organization cover image")}
          {renderItem(hasDiversityInfo, "Add diversity information")}
          {renderItem(hasFinancialInfo, "Start financial assessment")}
          {renderItem(projectsCount > 0, "Add projects")}
          {renderItem(hasVerified, "Verify organization")}
        </div>
      </div>
    );
  };
  const renderSimilarToNpos = () => {
    const query = {
      npo_id: npo_id,
      focus_area: orgData?.focus_area || [],
      beneficiary: orgData?.beneficiary || [],
      program: orgData?.program || [],
      service_loc: [],
    };
    const mission = orgData?.mission || orgData?.npo_name || "";
    return (
      <div
        ref={recDivRef}
        className=" min-h-[600px] w-full rounded border border-gray-300 bg-white"
      >
        <p className="border-b border-gray-300 px-6 py-4 font-poppins text-base font-light">
          Similar organization
        </p>
        <div
          className={classNames("flex w-full flex-col overflow-y-scroll", scrollBarClass)}
          style={{ maxHeight: recHeight }}
        >
          <RecNPOs
            isPrivateView={isPrivateView}
            exclude={npo_id ? [npo_id] : []}
            recommendationQuery={selectedQuery}
            withoutLogin={withoutLogin}
          />
        </div>
      </div>
    );
  };
  const renderPrivateHeader = () => {
    return (
      <div className="mt-[27px] flex w-full items-start justify-between">
        <div className="flex max-w-[798px] grow flex-col items-center justify-center gap-[30px] rounded bg-orange-800 px-4 pt-2">
          <div className="min-h-9 relative grid w-full  grid-cols-[1fr_494px_28px] items-center gap-2">
            <p className="font-poppins text-xs font-semibold text-white">
              {orgVerifyStep === 0 && "Verify organization"}
              {orgVerifyStep === 1 && "Verify organization"}
              {orgVerifyStep === 2 && "Verification in progress"}
              {orgVerifyStep === 3 && "Organization verified"}
            </p>
            <div className="flex items-center">
              <div className="inline-flex flex-col items-center justify-start gap-2">
                <div className="inline-flex items-center justify-start gap-1.5">
                  <div
                    className={classNames(
                      "h-4 w-4 rounded-full border-4 bg-gray-300",
                      orgVerifyStep >= 1 ? "border-yellow-500 bg-white" : "border-white",
                    )}
                  />
                  <div
                    className={classNames(
                      "h-[1px] w-[150px] rounded border-2 ",
                      orgVerifyStep >= 2 ? "border-yellow-500 bg-white" : "border-white",
                    )}
                  />
                  <div
                    className={classNames(
                      "h-4 w-4 rounded-full border-4  bg-gray-300",
                      orgVerifyStep >= 2 ? "border-yellow-500 bg-white" : "border-white",
                    )}
                  />
                  <div
                    className={classNames(
                      "h-[1px] w-[150px] rounded border-2 ",
                      orgVerifyStep >= 3 ? "border-yellow-500 bg-white" : "border-white",
                    )}
                  />
                  <div
                    className={classNames(
                      "h-4 w-4 rounded-full border-4  bg-gray-300",
                      orgVerifyStep >= 3 ? "border-yellow-500 bg-white" : "border-white",
                    )}
                  />
                </div>
              </div>
            </div>
            <div>
              <span
                className="grid h-full cursor-pointer place-items-center"
                onClick={() => {
                  setToggle((prev) => !prev);
                }}
              >
                <i
                  className={
                    toggle ? "gi-md gi-angle-up text-white" : "gi-md gi-angle-down text-white"
                  }
                />
              </span>
            </div>
            <div />
            {toggle && (
              <div className="inline-flex -translate-x-16 items-start justify-start gap-[43px]">
                <div
                  className={classNames(
                    " text-center font-poppins text-xs font-semibold text-white",
                    orgVerifyStep >= 1 && "text-white",
                  )}
                >
                  Information submitted
                </div>
                <div
                  className={classNames(
                    " text-center font-poppins text-xs font-semibold text-white",
                    orgVerifyStep >= 2 && "text-white",
                  )}
                >
                  Verification in progress
                </div>
                <div
                  className={classNames(
                    " text-center font-poppins text-xs font-semibold text-white",
                    orgVerifyStep >= 3 && "text-white",
                  )}
                >
                  Organization verified
                </div>
              </div>
            )}
            <div />
          </div>
          {toggle && (
            <div className="mb-5 grid w-full grid-cols-[100px_1fr] place-items-center">
              <div />
              <p className="font-poppins text-xs text-gray-800">
                {orgVerifyStep === 0 &&
                  "Please submit relevant information or documents related to your organization to get verified."}
                {orgVerifyStep === 1 &&
                  "Your organization is going through a verification process. This may take up to 48 hours."}
                {orgVerifyStep === 2 &&
                  "Your organization is going through a verification process. This may take up to 48 hours."}
                {orgVerifyStep === 3 && (
                  <>
                    Congratulations! Your organization has been verified. <br /> Your organization
                    profile will now be publicly available.
                  </>
                )}
              </p>
            </div>
          )}
        </div>

        <div className="flex justify-end gap-2">
          {!orgData?.verified && (
            <Button
              id="view as"
              handleOnClick={() => {
                setShowOrgVerify(true);
              }}
              className="!text-sm"
              outline
              label="Verify organization"
            />
          )}
          <Button
            id="invite"
            handleOnClick={() => {
              setShowInvite(true);
            }}
            className="!text-sm"
            label="Invite member"
          />
        </div>
      </div>
    );
  };
  return (
    <div className="flex w-full max-w-[1217px] flex-col">
      {showOrgVerify && (
        <VerifyOrganizationModal
          from_for_mixpanel="org_profile"
          open={showOrgVerify}
          setOpen={setShowOrgVerify}
          onCreation={() => {
            //
          }}
        />
      )}
      {onboardingData && showInvite && (
        <OrgInvitationPopup
          show={showInvite}
          onClose={() => {
            setShowInvite(false);
          }}
          from_for_mixpanel={"org_profile"}
        />
      )}
      {isPrivateView && hasWritePermission && renderPrivateHeader()}
      {requestStatus.requested && !hasWritePermission && (
        <div className="mt-5 flex h-[53px] w-full items-center bg-purple-50 px-5">
          <h5 className="text-purple-500">
            Your organization is currently undergoing a verification process.
          </h5>
        </div>
      )}
      {showPortal && (
        <Portal>
          <div
            className={classNames(
              "absolute top-[65px] z-[10] flex w-full items-center justify-around bg-purple-500",
              !orgData?._id || orgData?.verified ? "h-[53px]" : "h-[74px]",
            )}
          >
            <div className="flex flex-col font-poppins text-base text-white">
              <span>You are viewing the page in a public view.</span>
              {orgData?._id && !orgData?.verified && (
                <span>
                  {`Your organization is not verfied. If you want to expose your organization at
                  search, please contact ${
                    onboardingData.role < 4 ? "Admin to request verification." : "us"
                  }`}
                </span>
              )}
            </div>
            <div className="flex gap-3">
              {orgData?._id && !orgData?.verified && hasWritePermission && (
                <Button
                  id="edit-mode-btn"
                  className="!h-[29px] !border !border-white"
                  labelClass="!text-sm whitespace-nowrap"
                  loading={loading}
                  handleOnClick={handleRequest}
                  label={!requestStatus.requested ? "Verify" : "Verification In review"}
                />
              )}
              <Button
                id="edit-mode-btn"
                className="!h-[29px] !border !border-white"
                labelClass="!text-sm whitespace-nowrap"
                handleOnClick={() => {
                  const path = location.pathname.split("/");
                  path.splice(1, 1, "organization");
                  navigate(path.join("/"));
                }}
                label={hasWritePermission ? "Go to edit mode" : "Go to private view"}
              />
            </div>
          </div>
        </Portal>
      )}
      <div
        className={classNames(
          "flex w-full flex-col",
          showPortal
            ? orgData?._id && !orgData?.verified
              ? "mt-[85px]"
              : "mt-[65px]"
            : "mt-[28px]",
        )}
      >
        {!isPrivateView && (
          <div className="mb-4 w-full">
            <BackButton />
          </div>
        )}
        <div className={classNames(" flex w-full gap-[32px]")}>
          <main className="w-full max-w-[800px]  overflow-y-auto  overflow-x-hidden">
            <NpoView isPrivateView={isPrivateView} compact={false} withoutLogin={withoutLogin} />
            <div ref={lastDivRef}>
              <Spacer height="60px" />
            </div>
          </main>
          {showSimilarOrg && (
            <aside className="flex w-[384px] flex-col gap-y-4">
              {/* {isPrivateView && renderRightContainer()} */}
              {renderSimilarToNpos()}
            </aside>
          )}
        </div>
      </div>
    </div>
  );
}
export default NpoLayout;
