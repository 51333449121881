import { useState, useEffect, RefObject } from "react";

type ScrollValue = {
  x: number;
  y: number;
};

function useScrollPosition(ref?: RefObject<HTMLDivElement>, dependency?: any[]) {
  const [scrollPosition, setScrollPosition] = useState<ScrollValue>({ x: 0, y: 0 });

  const handleScroll = () => {
    if (ref && ref.current) {
      setScrollPosition({ x: ref.current.scrollLeft, y: ref.current.scrollTop });
    } else {
      setScrollPosition({ x: window.scrollX, y: window.scrollY });
    }
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener("scroll", handleScroll);
    } else window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (ref && ref.current) {
        ref.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref, ...(dependency || [])]);

  return scrollPosition;
}

export default useScrollPosition;
