import classNames from "classnames";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import useHowGibooWorks, {
  HowGibooWorks,
  HowGibooWorksKeyString,
  HowGibooWorksType,
  IpageVisit,
  levelToTitle,
} from "../hooks/useHowGibooWorks";
import Slider from "./tailwind/Slider";
import icon_find_grant from "../assets/home/find-grant.svg";
import icon_create_project from "../assets/home/fundraising-project.svg";
import icon_create_task from "../assets/home/task-create.svg";
import icon_diversity from "../assets/home/task-diversity.svg";
import icon_financial from "../assets/home/task-financial.svg";
import icon_search from "../assets/home/new-funder.svg";
import icon_invite from "../assets/home/colaborate-member.svg";
import icon_org_profile from "../assets/home/showcase-org.svg";
import icon_verification from "../assets/home/verify-org.svg";
import icon_my_profile from "../assets/home/complete-profile.svg";
import icon_arrow from "../assets/home/arrow-right.svg";
import icon_beginner from "../assets/home/beginner.svg";
import icon_explorer from "../assets/home/explorer.svg";
import icon_expert from "../assets/home/expert.svg";
import { Divider } from "rsuite";
import { useNavigate } from "react-router-dom";
import useOnboardingData from "../hooks/useOnboarding";
import ProjectCreationModal from "./ProjectCreationModal";
import OrgInvitationPopup from "./OrgInvitationPopup";
import TaskCreationModal from "./TaskCreationModal";
import GibooToast from "./GibooToast";
import VerifyOrganizationModal from "./VerifyOrganizationModal";
import CloseButton from "./CloseButton";
import { Dialog } from "@headlessui/react";
import PlainButton from "./PlainButton";
import IMG_BEGINNER_1 from "../assets/images/beginner-1.svg";
import IMG_BEGINNER_2 from "../assets/images/beginner-2.svg";
import IMG_EXPLORER_1 from "../assets/images/explorer-1.svg";
import IMG_EXPLORER_2 from "../assets/images/explorer-2.svg";
import IMG_EXPERT_1 from "../assets/images/expert-1.svg";
import IMG_EXPERT_2 from "../assets/images/expert-2.svg";
import useUser from "../hooks/useUser";
import useUserDictionary, {
  getUserDisctionaryValue,
  setUserDictionaryValue,
} from "../hooks/useUserDictionary";
import Checkbox from "./checkbox/Checkbox";
import {
  getUserOrgDisctionaryValue,
  setUserOrgDictionaryValue,
} from "../hooks/useUserOrgDictionary";
import ProjectInformationModal from "./ProjectInformationModal";
import { zIndexBackdrop, zIndexCelebration, zIndexDialog } from "../types/zIndex";
import { MIXPANEL_EVENTS_V2, Navigate } from "../mixpanel/mixpanel";
import useOrgID from "../hooks/useOrgID";
import useGibooNavigate from "../hooks/useGibooNavigate";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
interface IGibooWorks {
  wrapperClass?: string;
  findGrand?: () => void;
  findFunder?: () => void;
}
const ICON_LEVEL = [icon_beginner, icon_explorer, icon_expert];
const ICONS_COLLECTION: Record<HowGibooWorksType, string> = {
  find_grant: icon_find_grant,
  create_project: icon_create_project,
  create_task: icon_create_task,
  org_diversity: icon_diversity,
  org_financial: icon_financial,
  search: icon_search,
  invite: icon_invite,
  org_profile: icon_org_profile,
  verification: icon_verification,
  my_profile: icon_my_profile,
};

export default function GibooWorks({ wrapperClass, findGrand, findFunder }: IGibooWorks) {
  const [onboardingData] = useOnboardingData();
  const {
    allCompleted,
    totalCompletedCount,
    experience,
    items,
    level,
    targetExperience,
    totalCount,
    complete,
    descriptions,
    labels,
    setAsLowPriority,
    pageVisit,
    updatePageVisit,
    reset: resetAll,
  } = useHowGibooWorks();
  const mxEvent = useGibooMixpanel("how_giboo_works");
  const percentage = (100 / targetExperience - experience) * experience;
  const navigate = useGibooNavigate("how_giboo_works", true);
  const [showProjectCreationModal, setShowProjectCreationModal] = useState<boolean>(false);
  const [showTaskCreation, setShowTaskCreation] = useState<boolean>(false);
  const [showOrgVerify, setShowOrgVerify] = useState<boolean>(false);
  const [showInvite, setShowInvite] = useState<boolean>(false);
  const reset = sessionStorage.getItem("reset-how-works");
  const [completedAll, setCompletedAll] = useState<boolean>(true);
  const [showCelebrate, setShowCelebrate] = useState<boolean>(false);
  const [allowClose, setAllowClose] = useState(true);
  const [celebrateType, setCelebrateType] = useState<"BEGINNER" | "EXPLORER" | "EXPERT">();
  const [open, setOpen] = useState<boolean>(true);
  const [dontShow, setDontShow] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const org_id = useOrgID();

  useEffect(() => {
    setIsLoading2(true);
    getUserOrgDisctionaryValue(onboardingData._id, "HOW_GIBOO_TOGGLE")
      .then((res) => {
        if (res !== null) {
          setOpen(res);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading2(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading1(true);
    getUserDisctionaryValue("HOW_GIBOO_DONT_SHOW_AGAIN")
      .then((res) => {
        if (res !== null) {
          setDontShow(res);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading1(false);
      });
  }, []);
  useEffect(() => {
    //returning from page visited
    if (pageVisit.profileVisited) {
      updatePageVisit({ ...pageVisit, profileVisited: false });
      setTimeout(() => {
        handleComplete("my_profile");
      }, 1000);
    } else if (pageVisit.orgProfileVisited) {
      updatePageVisit({ ...pageVisit, orgProfileVisited: false });
      setTimeout(() => {
        handleComplete("org_profile");
      }, 1000);
    } else if (pageVisit.projectVisited) {
      updatePageVisit({ ...pageVisit, projectVisited: false });
      setTimeout(() => {
        handleComplete("create_project");
      }, 1000);
    } else if (pageVisit.searchVisited) {
      updatePageVisit({ ...pageVisit, searchVisited: false });
      setTimeout(() => {
        handleComplete("find_grant");
      }, 1000);
    } else if (pageVisit.funderVisited) {
      updatePageVisit({ ...pageVisit, funderVisited: false });
      setTimeout(() => {
        handleComplete("search");
      }, 1000);
    } else if (pageVisit.financialAssessVisited) {
      updatePageVisit({ ...pageVisit, financialAssessVisited: false });
      setTimeout(() => {
        handleComplete("org_financial");
      }, 1000);
    } else if (pageVisit.diversityAssessVisited) {
      updatePageVisit({ ...pageVisit, diversityAssessVisited: false });
      setTimeout(() => {
        handleComplete("org_diversity");
      }, 1000);
    }
  }, [pageVisit]);

  const handleShowCelebrate = () => {
    setTimeout(() => {
      setShowCelebrate(true);
    }, 4000);
  };
  useEffect(() => {
    setCompletedAll(allCompleted);
  }, [allCompleted]);

  const handleComplete = useCallback(
    (key: HowGibooWorksType) => {
      const res = complete(key);
      if (res) {
        if (res.level === 1 && res.experience === 0 && pageVisit?.beginner === false) {
          setCelebrateType("BEGINNER");
          handleShowCelebrate();
        }
        if (res.level === 2 && res.experience === 0 && pageVisit?.explorer === false) {
          setCelebrateType("EXPLORER");
          handleShowCelebrate();
        }
        if (res.level === 3 && res.experience === 0 && pageVisit?.expert === false) {
          setCelebrateType("EXPERT");
          setAllowClose(false);
          setTimeout(() => {
            setShowCelebrate(true);
          }, 1000);
        }
      }
    },
    [complete],
  );
  useEffect(() => {
    if (reset === "true") {
      updatePageVisit({
        beginner: false,
        expert: false,
        explorer: false,
        orgProfileVisited: false,
        profileVisited: false,
        funderVisited: false,
        projectVisited: false,
        searchVisited: false,
        financialAssessVisited: false,
        diversityAssessVisited: false,
      });
      sessionStorage.removeItem("reset-how-works");
      resetAll();
    }
  }, [reset]);
  //mixpanel
  const sendHGWactions = (to: Navigate) => {
    mxEvent(MIXPANEL_EVENTS_V2.myhome.how_giboo_works.clicked, {
      to,
    });
  };
  const handleSelect = (i: HowGibooWorks, completed = false) => {
    switch (i?.key) {
      case "find_grant":
        sendHGWactions(Navigate.SEARCH_VIRTUAL_GRANTS);
        findGrand?.();
        break;
      case "search":
        sendHGWactions(Navigate.SEARCH_FUNDERS);
        findFunder?.();
        break;
      case "create_project":
        sendHGWactions(Navigate.PROJECT_CREATION);
        setShowProjectCreationModal(true);
        break;
      case "create_task":
        sendHGWactions(Navigate.TASK_CREATION);
        setShowTaskCreation(true);
        break;
      case "org_financial":
        sendHGWactions(Navigate.FINANCIAL);
        !completed && updatePageVisit({ ...pageVisit, financialAssessVisited: true });
        navigate("/financial");
        break;
        break;
      case "org_diversity":
        sendHGWactions(Navigate.DIVERSITY);
        !completed && updatePageVisit({ ...pageVisit, diversityAssessVisited: true });
        navigate("/diversity");
        break;
        break;
      case "invite":
        sendHGWactions(Navigate.INVITATION);
        setShowInvite(true);
        break;
      case "my_profile":
        sendHGWactions(Navigate.USER_PROFILE);
        !completed && updatePageVisit({ ...pageVisit, profileVisited: true });
        navigate("/profile");
        break;
      case "org_profile":
        sendHGWactions(Navigate.ORG_PROFILE);
        !completed && updatePageVisit({ ...pageVisit, orgProfileVisited: true });
        navigate(`/organization`);
        break;
      case "verification":
        sendHGWactions(Navigate.ORG_VERIFICATION);
        setShowOrgVerify(true);
        break;
      default:
        break;
    }
  };
  if (completedAll && allowClose) {
    return <></>;
  }
  if (dontShow || isLoading1 || isLoading2) {
    return <></>;
  }
  const renderItems = () => {
    return items.map((item, index) => (
      <GibooWorksCard
        key={index}
        item={item}
        handleSelect={handleSelect}
        setAsLowPriority={setAsLowPriority}
        descriptions={descriptions}
        labels={labels}
        pageVisit={pageVisit}
      />
    ));
  };
  return (
    <>
      {showCelebrate && (
        <CelebrationDialog
          onClose={() => {
            if (celebrateType === "BEGINNER") {
              updatePageVisit({ ...pageVisit, beginner: true });
            }
            if (celebrateType === "EXPERT") {
              updatePageVisit({ ...pageVisit, expert: true });
            }
            if (celebrateType === "EXPLORER") {
              updatePageVisit({ ...pageVisit, explorer: true });
            }
            setShowCelebrate(false);
            setAllowClose(true);
          }}
          open={showCelebrate}
          type={celebrateType}
        />
      )}
      {showProjectCreationModal && (
        <ProjectInformationModal
          from_for_mixpanel="how_giboo_works"
          open={showProjectCreationModal}
          setOpen={setShowProjectCreationModal}
          onClose={() => {
            setTimeout(() => {
              handleComplete("create_project");
            }, 1000);
          }}
          id=""
          isNewProject
          withOutFunderGrant
          onProjectCreation={() => {
            setTimeout(() => {
              handleComplete("create_project");
            }, 1000);
          }}
        />
      )}
      {showTaskCreation && (
        <TaskCreationModal
          open={showTaskCreation}
          setOpen={setShowTaskCreation}
          onClose={() => {
            setTimeout(() => {
              handleComplete("create_task");
            }, 1000);
          }}
          onTaskCreation={() => {
            setShowTaskCreation(false);
            setTimeout(() => {
              handleComplete("create_task");
            }, 1000);
          }}
        />
      )}
      {showOrgVerify && (
        <VerifyOrganizationModal
          from_for_mixpanel="how_giboo_works"
          open={showOrgVerify}
          setOpen={setShowOrgVerify}
          onClose={() => {
            setTimeout(() => {
              handleComplete("verification");
            }, 1000);
          }}
          onCreation={() => {
            setShowOrgVerify(false);
            setTimeout(() => {
              handleComplete("verification");
            }, 1000);
          }}
        />
      )}
      {onboardingData && showInvite && (
        <OrgInvitationPopup
          from_for_mixpanel="how_giboo_works"
          show={showInvite}
          onClose={() => {
            setShowInvite(false);
            setTimeout(() => {
              handleComplete("invite");
            }, 1000);
          }}
        />
      )}
      <div
        className={classNames(
          "mx-auto mt-[60px] flex h-fit w-full max-w-[1151px] flex-col border-y border-gray-300 bg-gray-50 py-6",
          wrapperClass,
        )}
      >
        <div className="inline-flex w-full flex-wrap justify-between">
          <div className="flex flex-col gap-3">
            <h5 className="font-poppins text-xl font-semibold text-purple-500">How Giboo works</h5>
            <p className="font-poppins text-sm text-gray-700">
              Here is a glance of what you can achieve in Giboo.
            </p>
          </div>
          <div className="inline-flex items-start gap-4">
            <div className="flex max-w-[270px] flex-col gap-4">
              <div className="flex items-center gap-1 px-3">
                <div className="inline-flex items-center gap-2">
                  <img src={ICON_LEVEL[level]} />
                  <p className="font-semibold">{levelToTitle[level]}</p>
                </div>
                <Divider vertical />
                <h6 className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  {allCompleted ? (
                    "All done"
                  ) : (
                    <>{targetExperience - experience} more to level up</>
                  )}
                </h6>
              </div>
              <div className="relative h-[14px] w-full max-w-[253px] rounded-full bg-gray-200">
                <div
                  className="giboo-gradient-bg h-full rounded-full"
                  style={{ width: `${percentage}%` }}
                />
              </div>
            </div>
            <PlainButton
              className="!p-0"
              id={`how-giboo-works-collapse`}
              label=""
              leftIconClass={classNames(
                "mt-1",
                !open ? "gi-md gi-angle-up" : "gi-md gi-angle-down",
              )}
              handleOnClick={() => {
                setOpen(!open);
                setUserOrgDictionaryValue(onboardingData._id, "HOW_GIBOO_TOGGLE", !open);
              }}
            />
          </div>
        </div>
        <Slider
          iconColor="purple"
          wrapperClass={classNames("mt-[20px]", open ? "block" : "hidden")}
          slides={renderItems()}
        />
        <div className="mt-6 flex w-full justify-end">
          <Checkbox
            id={`input-check-dont-show-again`}
            className="GA_TAG_click_removetutorial"
            label={"Click to remove the tutorial"}
            size="sm"
            inputClass="!border-gray-700"
            labelClass="!text-xs font-poppins !text-gray-700"
            checked={dontShow}
            onChange={() => {
              // sendHGWactions("hgw_remove");
              mxEvent(MIXPANEL_EVENTS_V2.myhome.how_giboo_works.removed, {
                howGibooWorksCompleted: totalCompletedCount,
                howGibooWorksTotal: totalCount,
              });
              setUserDictionaryValue("HOW_GIBOO_DONT_SHOW_AGAIN", !dontShow);
              setDontShow(!dontShow);
            }}
          />
        </div>
      </div>
    </>
  );
}
function GibooWorksCard({
  item,
  handleSelect,
  setAsLowPriority,
  descriptions,
  labels,
  pageVisit,
}: {
  item: HowGibooWorks;
  handleSelect: (i: HowGibooWorks, completed: boolean) => void;
  setAsLowPriority: (key: HowGibooWorksType) => void;
  descriptions: HowGibooWorksKeyString;
  labels: HowGibooWorksKeyString;
  pageVisit: IpageVisit;
}) {
  const { completed, key, lowPriority } = item;
  const [showTemp, setShowTemp] = useState(false);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (completed) {
      setShowTemp(true);
      setTimeout(() => {
        setShowTemp(false);
      }, 2000);
    }
  }, [completed]);
  return (
    <>
      <div
        key={key}
        id="GA_TAG_click_hgw1"
        className={classNames(
          "relative flex min-h-[244px] w-[352px] cursor-pointer flex-col rounded border  bg-white p-4 ",
          lowPriority && !completed ? "opacity-30" : "",
          completed ? "!bg-gray-100" : "",
          !completed ? "hover:bg-purple-50" : "",
          !completed && clicked ? " border-purple-500" : "border-gray-300",
        )}
        onMouseLeave={() => {
          setClicked(false);
        }}
        onClick={() => {
          setClicked(true);
          handleSelect(item, completed);
        }}
      >
        {showTemp && (
          <div className="absolute left-0 top-0 z-[10] grid  h-full w-full place-items-center overflow-hidden rounded  bg-white">
            <span
              onClick={(e) => {
                e.preventDefault();
              }}
              className="grid h-[80px] w-[80px] cursor-pointer place-items-center rounded-full bg-green-300"
            >
              <i className="gi-md gi-check text-green-700" />
            </span>
          </div>
        )}
        <>
          <div className="-mt-1 inline-flex w-full items-start justify-between">
            <span className="grid place-items-center ">
              <img src={ICONS_COLLECTION[key]} className="h-[66px] w-[66px]" />
            </span>
            {completed ? (
              <span
                onClick={(e) => {
                  setAsLowPriority(key);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className="mt-2 grid h-[22px] w-fit place-items-center rounded border border-green-500 bg-green-50 px-2"
              >
                <h6 className="font-poppins text-xs text-gray-900">Completed</h6>
              </span>
            ) : (
              !lowPriority && (
                <span
                  onClick={(e) => {
                    setAsLowPriority(key);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="mt-2 h-5 w-5 cursor-pointer"
                >
                  <CloseButton />
                </span>
              )
            )}
          </div>
          <div className="mt-[23px] inline-flex items-center gap-2">
            <p className="font-semibold">{labels[key]}</p>
            <span>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.6583 17.2749C11.4414 17.058 11.4414 16.7064 11.6583 16.4895L17.374 10.7738L2.05531 10.7738C1.74862 10.7738 1.5 10.5251 1.5 10.2184C1.5 9.91175 1.74862 9.66313 2.05531 9.66313L17.374 9.66313L11.6583 3.94737C11.4414 3.7305 11.4414 3.3789 11.6583 3.16204C11.8751 2.94517 12.2267 2.94517 12.4436 3.16204L19.5 10.2184L12.4436 17.2749C12.2267 17.4917 11.8751 17.4917 11.6583 17.2749Z"
                  fill="#727272"
                />
              </svg>
            </span>
          </div>
          <h6 className="mt-4 max-w-[300px] select-none font-poppins text-xs text-gray-700">
            {descriptions[key]}
          </h6>
        </>
      </div>
    </>
  );
}

function CelebrationDialog({
  open,
  onClose,
  type,
}: {
  type?: "BEGINNER" | "EXPLORER" | "EXPERT";
  open: boolean;
  onClose: () => void;
}) {
  const [user] = useUser();
  return (
    <Dialog open={open} onClose={() => null} className={classNames("")}>
      <div className={classNames("fixed inset-0 bg-black/80", zIndexBackdrop)} aria-hidden="true" />
      <div
        className={classNames("fixed inset-0 flex items-center justify-center p-4", zIndexDialog)}
      >
        <Dialog.Panel
          className={classNames(
            "mx-auto min-h-[400px]  !w-[765px] rounded bg-white",
            zIndexCelebration,
          )}
        >
          <div className="flex justify-between p-6">
            <div />
            <PlainButton
              id={`btn-delete-close`}
              handleOnClick={() => {
                onClose();
              }}
              leftIconClass={<CloseButton />}
            />
          </div>
          <div className="grid h-full place-items-center gap-[40px]">
            <div className="grid h-[224px] w-[377px] place-items-center gap-[40px]">
              <h5 className="font-poppins text-[20px] font-semibold">
                Great job, <span className="capitalize">{user?.firstname}</span>!
              </h5>
              <div className="inline-flex items-center gap-5">
                {type === "BEGINNER" && (
                  <>
                    <div className="h-[72px] w-[72px] ">
                      <img src={IMG_BEGINNER_1} className="h-full w-full" />
                    </div>
                    <div className="h-[72px] w-[72px] ">
                      <img src={IMG_BEGINNER_2} className="h-full w-full" />
                    </div>
                  </>
                )}
                {type === "EXPLORER" && (
                  <>
                    <div className="h-[72px] w-[72px] ">
                      <img src={IMG_EXPLORER_1} className="h-full w-full" />
                    </div>
                    <div className="h-[72px] w-[72px] ">
                      <img src={IMG_EXPLORER_2} className="h-full w-full" />
                    </div>
                  </>
                )}
                {type === "EXPERT" && (
                  <>
                    <div className="h-[72px] w-[72px] ">
                      <img src={IMG_EXPERT_1} className="h-full w-full" />
                    </div>
                    <div className="h-[72px] w-[72px] ">
                      <img src={IMG_EXPERT_2} className="h-full w-full" />
                    </div>
                  </>
                )}
              </div>
              <p className="text-center font-poppins text-sm">
                {type === "BEGINNER" &&
                  `Congratulations on completing the beginner course! You're now on your way to becoming an explorer!`}
                {type === "EXPLORER" &&
                  `Congratulations on completing the explorer course! You're now on your way to becoming
              an expert!`}
                {type === "EXPERT" &&
                  `Congratulations on completing the expert course! Now let’s become a fundraising expert with Giboo`}
              </p>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
