import { useCallback } from "react";
import {
  IProjectInteractionComment,
  IProjectInteractionCommentCreateRequest,
  IProjectInteractionCommentUpdateRequest,
} from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
interface ProjectInteractionCommentsState {
  data: IProjectInteractionComment[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IProjectInteractionComment[] | undefined>;
}
const createProjectInteractionComment = async (req: IProjectInteractionCommentCreateRequest) => {
  return axios
    .post(process.env.REACT_APP_API_URL + `/api/v2/projects/interaction/comment`, req, config)
    .then((res) => res.data as IProjectInteractionComment);
};
const updateProjectInteractionComment = async (
  id: string,
  req: IProjectInteractionCommentUpdateRequest,
) => {
  if (!id) return new Promise<IProjectInteractionComment>((resolve, reject) => reject());
  return axios
    .put(process.env.REACT_APP_API_URL + `/api/v2/projects/interaction/comment/${id}`, req, config)
    .then((res) => res.data as IProjectInteractionComment);
};
const deleteProjectInteractionComment = async (id: string) => {
  if (!id) return new Promise<boolean>((resolve, reject) => reject());
  return axios
    .delete(process.env.REACT_APP_API_URL + `/api/v2/projects/interaction/comment/${id}`, config)
    .then((res) => res.data as boolean);
};
function useInteractionComments(interaction_id?: string): ProjectInteractionCommentsState {
  const url =
    process.env.REACT_APP_API_URL + `/api/v2/projects/interaction/comments/${interaction_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectInteractionComment[]>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => res.data as IProjectInteractionComment[])
      .then((res) =>
        res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
      );
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectInteractionComment[]>(
    interaction_id ? url : null,
    fetch,
    {
      keepPreviousData: false,
    },
  );
  const revalidate = useCallback(() => mutate<IProjectInteractionComment[]>(url), [url]);
  return {
    data: data || [],
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export {
  createProjectInteractionComment,
  updateProjectInteractionComment,
  deleteProjectInteractionComment,
};
export default useInteractionComments;
