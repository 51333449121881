import axios from "axios";
import config from "../../api";
import useSWR from "swr";
import { FunderSearchResult } from "../../types/search";
import { prettierTaxonomy } from "../../utils/philanthropy";

interface FunderSearchResultState {
  data: FunderSearchResult[];
  isLoading: boolean;
  error: object;
}
const swrOption = {
  dedupingInterval: 1000 * 300,
  refreshInterval: 0,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
const emptyList: FunderSearchResult[] = [];
export default function useFunderSearchResults(id?: (string | number)[]): FunderSearchResultState {
  const url =
    id && id.length > 0
      ? process.env.REACT_APP_API_URL +
        `/api/v2/search/donors_searchresult?id=${id.join("&id=")}${
          typeof id[0] === "string" ? "" : "&by=uid"
        }`
      : null;
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<FunderSearchResult[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) =>
      res.data.map((d: any) => ({
        ...d,
        search_type: "funder",
        ...prettierTaxonomy({
          focus_area: d.focus_area.map((t: string) => ({ label: t, matched: false })),
          beneficiary: d.beneficiary.map((t: string) => ({ label: t, matched: false })),
          program: d.program.map((t: string) => ({ label: t, matched: false })),
          service_loc: d.service_loc,
        }),
      })),
    );
  };
  const { data, isLoading, error } = useSWR<FunderSearchResult[]>(
    url ? url : null,
    fetch,
    swrOption,
  );
  return { data: data || emptyList, isLoading, error };
}
