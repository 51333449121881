import classNames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import { Divider, Steps } from "rsuite";
import Button from "../../../../components/tailwind/Button";
import Spacer from "../../../../components/Spacer";
import IMG_EXAMPLE from "../../../../assets/financial/total-contribution-placeholder.svg";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import NpoFinancialContribution, {
  FinancialContribution,
} from "../../dashboard/components/NpoFinancialContribution";
import useNPOIRSProfile from "../../../../hooks/useNPOIRSProfile";
import IncreaseTag from "../component/IncreaseTag";
import LargestProportionTag from "../component/LargestProportionTag";
import DecreaseTag from "../component/DecreaseTag";
import SmallestProportionTag from "../component/SmallestProportionTag";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import CloseButton from "../../../../components/CloseButton";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import { MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";

export default function TotalContribution({
  onBack,
  onNext,
  npo_id,
  org_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  org_id?: string;
  isBack?: boolean;
}) {
  const [currentStep, setCurrentStep] = useState<number>(isBack ? 1 : 0);
  useEffect(() => {
    if (isBack) {
      setTimeout(() => {
        scrollToElement("businessmodel-step-2");
      }, 300);
    }
  }, [isBack]);
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value >= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };
  const scrollToElement = (id: string) => {
    const element = document?.getElementById(id);
    const container = document.getElementById("financial-scroll-container");
    if (element && container) {
      const elementTop = element.getBoundingClientRect().top + container.scrollTop;
      const adjustedOffset = elementTop - 120;
      container.scrollTo({ top: adjustedOffset, behavior: "smooth" });
    }
  };
  const renderStep1 = () => {
    return (
      <TotalContributionInfo>
        <div
          className={classNames(
            "flex h-[38px] items-center justify-start gap-2",
            currentStep === 0 ? "flex" : "hidden",
          )}
        >
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              if (currentStep < 1) {
                setCurrentStep(1);
                setTimeout(() => {
                  scrollToElement("businessmodel-step-2");
                }, 300);
              }
            }}
            label="See the assessment result"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
        <Spacer height="100px" />
      </TotalContributionInfo>
    );
  };

  return (
    <div className="flex max-w-[760px] flex-col">
      <Steps current={currentStep} vertical>
        <Steps.Item
          title={false}
          className="!-ml-[40px] !mt-0"
          icon={currentStep > 0 ? renderDot(1) : <></>}
          description={renderStep1()}
        />
        {currentStep > 0 && (
          <Steps.Item
            icon={renderDot(2)}
            className="!-ml-[40px]"
            description={
              <Step2
                onBack={() => {
                  setCurrentStep(0);
                }}
                npo_id={npo_id}
                org_id={org_id}
                onNext={() => {
                  onNext?.();
                }}
              />
            }
          />
        )}
      </Steps>
    </div>
  );
}
const Step2 = ({
  onBack,
  onNext,
  npo_id,
  org_id,
  recomendation = true,
}: {
  onBack: () => void;
  onNext: () => void;
  npo_id?: string;
  org_id?: string;
  recomendation?: boolean;
}) => {
  const { data, update } = useOrgProfile(org_id);
  const prev = data?.skip_loading || [];
  const mxEvent = useGibooMixpanel(undefined, true);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.financial.total_contribution.successful);
  }, []);
  return (
    <div className="mt-3 flex min-h-[600px] flex-col gap-4" id="businessmodel-step-2">
      <h6 className="text-base font-semibold text-gray-900">
        Your organization&apos;s assessment result
      </h6>
      <NpoFinancialContribution
        npo_id={npo_id}
        org_id={org_id}
        recomendation
        showChartLoader={!data.skip_loading?.includes("total_contribution")}
      >
        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              data &&
                update({
                  skip_loading: [
                    ...prev.filter((p) => p !== "total_contribution"),
                    "total_contribution",
                  ],
                });
              onNext?.();
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </NpoFinancialContribution>
      <Spacer height="150px" />
    </div>
  );
};

export function TotalContributionInfo({
  children,
  handleClose,
}: {
  children?: ReactNode;
  handleClose?: () => void;
}) {
  return (
    <div className={classNames("flex min-h-[600px] flex-col gap-4", !handleClose && "mt-3")}>
      <div className="inline-flex w-full items-center justify-between border-b border-gray-300">
        <h6 className="text-base font-semibold text-gray-900 underline underline-offset-[6px]">
          Total contributions
        </h6>
        {handleClose && <CloseButton onClick={() => handleClose?.()} />}
      </div>
      <div className="flex min-h-[500px] flex-col rounded border border-gray-300 bg-white p-5">
        {/* section 1 */}
        <div className="flex flex-col gap-10">
          <div>
            <h5 className="font-poppins text-base font-semibold text-black">
              What is total contributions?
            </h5>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800">
              Total contributions represents the sum of income sources that contribute to the
              financial health of the organization. This encompasses funding from various channels,
              including government grants, individual and corporate grants, as well as contributions
              from foundations. Essentially, it is the aggregate of financial support received from
              diverse entities and channels.
            </h6>
          </div>
          <div className="flex min-h-[330px] justify-center">
            <img src={IMG_EXAMPLE} className="h-fit w-fit" alt="example" />
          </div>
        </div>
      </div>
      <div className="flex min-h-[100px] flex-col rounded border border-gray-300 bg-white p-5">
        <div>
          <h5 className="font-poppins text-base font-semibold text-black">
            Why is total contributions important?
          </h5>
          <Spacer height="10px" />
          <h6 className="font-poppins text-sm text-gray-800">
            Total contributions serves as a crucial tool for a nonprofit&apos;s holistic financial
            overview, offering in-depth insights into its financial landscape. By encompassing
            diverse income sources, from government grants to individual and corporate
            contributions, it provides a nuanced panoramic view for funders to assess the
            organization&apos;s financial health.
            <br />
            <br />
            This diversity inherent in total contributions is key to risk mitigation, reducing
            vulnerability that arises from reliance on a single funding source. Informed by this
            comprehensive insight, nonprofits can make strategic decisions, identifying and
            strengthening substantial funding streams while addressing weaknesses. This knowledge
            empowers effective resource allocation, capitalizing on strengths and enhancing overall
            financial sustainability.
            <br />
            <br />
            Additionally, total contributions ensures mission alignment, allowing nonprofits to
            assess whether funding sources contribute meaningfully to their overarching goals.
            Moreover, it fosters adaptability and innovation by promoting a proactive approach to
            financial management, enabling organizations to navigate dynamic financial landscapes
            and respond to emerging opportunities effectively.
          </h6>
        </div>
      </div>
      {children && children}
    </div>
  );
}
