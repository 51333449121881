import { Dispatch, SetStateAction, useEffect, useState } from "react";
import MemoizedDropArea from "./input/FileDropArea";
import ICON_FILE from "../assets/images/file-upload.svg";
import classNames from "classnames";
import { toBytesFormat } from "../utils/formatHelper";
import FakeProgressBar from "./FakeProgressBar";
import RoundButton from "./RoundButton";
import PlainButton from "./PlainButton";

export interface UploadFile {
  file?: File;
  name: string;
  size: number;
  done?: boolean;
  key: string;
  failed?: boolean;
}

interface FileUploadComponentProps {
  selectedFile: UploadFile[];
  setSelectedFile: Dispatch<SetStateAction<UploadFile[]>>;
  loadingFileUpload: boolean;
  setLoadingFileUpload: Dispatch<SetStateAction<boolean>>;
  error: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  previousFiles?: UploadFile[];
  uploadService: (file: File) => Promise<string | undefined>;
  allowMultipleFile: boolean;
  id: string;
  formats: string[];
  sizeLimit?: number;
  disabled?: boolean;
  placeholderText?: string;
  onSuccess?: (file: UploadFile) => void;
  dropAreaClass?: string;
}

function FileUploadComponent({
  selectedFile,
  setSelectedFile,
  previousFiles,
  uploadService,
  allowMultipleFile,
  loadingFileUpload,
  setLoadingFileUpload,
  error,
  setError,
  onSuccess,
  ...props
}: FileUploadComponentProps) {
  useEffect(() => {
    setSelectedFile(previousFiles || []);
  }, [previousFiles]);

  const handleUpload = (file: File) => {
    setError(false);
    setLoadingFileUpload(true);
    uploadService(file)
      .then((res) => {
        const key = res || "";
        const success = key ? true : false;
        setSelectedFile((prev) => {
          if (allowMultipleFile) {
            const index = prev.findIndex((f) => f.name === file.name && f.size === file.size);
            if (index >= 0) {
              return [
                ...prev.slice(0, index),
                { ...prev[index], done: success, failed: !success, key: success ? key : "" },
                ...prev.slice(index + 1),
              ];
            } else return prev;
          } else {
            return [
              {
                name: file.name,
                size: file.size,
                done: success,
                failed: !success,
                key: success ? key : "",
              },
            ];
          }
        });
        onSuccess?.({
          file: file,
          name: file.name,
          size: file.size,
          done: success,
          failed: !success,
          key: success ? key : "",
        });
      })
      .catch(() => {
        setError(true);
        setSelectedFile((prev) => {
          if (allowMultipleFile) {
            const index = prev.findIndex((f) => f.name === file.name && f.size === file.size);
            if (index >= 0) {
              return [
                ...prev.slice(0, index),
                { ...prev[index], done: false, failed: true, key: "" },
                ...prev.slice(index + 1),
              ];
            } else return prev;
          } else {
            return [
              {
                name: file.name,
                size: file.size,
                done: false,
                failed: true,
                key: "",
              },
            ];
          }
        });
      })
      .finally(() => setLoadingFileUpload(false));
  };
  const renderFileUpload = () => {
    return (
      <div className="rounded bg-gray-50 ">
        <MemoizedDropArea
          id={props.id}
          formats={props.formats}
          containerClass={classNames(
            "min-h-[192px] !border-solid !border hover:!border-purple-500",
            props.dropAreaClass,
          )}
          placeholder={
            <div className="grid w-full place-items-center text-center">
              <span>
                <img src={ICON_FILE} className="h-[75px] w-[75px]" />
              </span>
              <h5 className="mt-3 font-poppins text-sm text-gray-700">
                <span className="font-semibold text-purple-500">Click to upload </span>
                or drag and drop here
              </h5>
              <p className="m-2 font-poppins text-sm text-gray-700">{props.placeholderText}</p>
            </div>
          }
          disabled={props.disabled}
          sizeLimit={props.sizeLimit}
          showFileSelection={false}
          onDrop={(arg: File) => {
            if (allowMultipleFile) {
              setSelectedFile((prev) => [
                ...prev.filter((f) => f.name !== arg.name || f.size !== arg.size),
                { file: arg, name: arg.name, size: arg.size, done: false, key: "" },
              ]);
            } else {
              setSelectedFile([
                { file: arg, name: arg.name, size: arg.size, done: false, key: "" },
              ]);
            }
            handleUpload(arg);
          }}
          resetAlways
        />
      </div>
    );
  };
  const renderSelectedFiles = () => {
    return (
      <div className="mt-4 flex flex-col gap-4">
        {selectedFile.map((item, index) => {
          return (
            <div key={index} className="grid w-full grid-cols-5 items-center gap-2">
              <div
                className={classNames(
                  "col-span-4 grid grid-cols-4 items-center gap-2 rounded px-2 py-1",
                  item.failed ? "bg-purple-50" : "",
                )}
              >
                <span className="col-span-3 truncate whitespace-nowrap">{item.name}</span>
                <div className="col-span-1 flex justify-end">
                  {item.failed ? (
                    <span className="text-sm font-semibold text-purple-500">Failed</span>
                  ) : item.done ? (
                    <span className="text-sm">{toBytesFormat(item.size)}</span>
                  ) : (
                    <FakeProgressBar estimatedSeconds={3} done={item.done} />
                  )}
                </div>
              </div>
              <div className="col-span-1 flex gap-2">
                <RoundButton
                  id={`btn-remove-item-${index}`}
                  icon="gi-md gi-x"
                  type="tertiary"
                  size="sm"
                  handleOnClick={() =>
                    setSelectedFile((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
                  }
                />
                {item.failed && item.file && (
                  <PlainButton
                    handleOnClick={() => {
                      if (item.file) handleUpload(item.file);
                    }}
                    id={`btn-retry-item-${index}`}
                    className="!p-0 !text-sm"
                    color="purple"
                    label="Retry"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex flex-col">
        <>
          {(allowMultipleFile || (!allowMultipleFile && selectedFile.length === 0)) &&
            renderFileUpload()}
        </>
        {selectedFile.length > 0 && renderSelectedFiles()}
      </div>
    );
  };
  return <>{renderContent()}</>;
}
export default FileUploadComponent;
