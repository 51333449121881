const FILE_PREFIX = process.env.REACT_APP_S3_STORAGE_PREFIX
  ? `${process.env.REACT_APP_S3_STORAGE_PREFIX}/`
  : "DEV/";
const getProjectImageKey = (userId: string, projectId: string) => {
  return `${FILE_PREFIX}PROJECT_IMAGE_${userId}_${projectId}`;
};
const getOrgProfileImageKey = (org_id: string) => {
  return `${FILE_PREFIX}ORG_PROFILE_IMAGE_${org_id}`;
};
const getOrgBGImageKey = (org_id: string) => {
  return `${FILE_PREFIX}ORG_BG_IMAGE_${org_id}`;
};
const getUserProfileImageKey = (user_id: string) => {
  return `${FILE_PREFIX}USER_PROFILE_IMAGE_${user_id}`;
};
const getUserBGImageKey = (user_id: string) => {
  return `${FILE_PREFIX}USER_BG_IMAGE_${user_id}`;
};
const getOrgMemberImageKey = (member_id: string) => {
  return `${FILE_PREFIX}ORG_MEMBER_PROFILE_IMAGE_${member_id}`;
};
const getOrgDocKey = (org_id: string, taxExemption: string) => {
  return `${FILE_PREFIX}ORG_TAX_DOC_${org_id}_${taxExemption}`;
};
const getIRS990Key = (org_id: string) => {
  return `${FILE_PREFIX}990/ORG_FORM_${org_id}_${new Date().getTime()}`;
};
const getVerificationFileKey = (org_id: string) => {
  return `${FILE_PREFIX}verification/${org_id}_${new Date().getTime()}`;
};
const getAdminVerificationFileKey = (org_id: string) => {
  return `${FILE_PREFIX}verification/${org_id}_admin_${new Date().getTime()}`;
};
const getFeebackFileKey = (user_id: string) => {
  return `${FILE_PREFIX}feedback/${user_id}_${new Date().getTime()}`;
};
const getReportFileKey = (user_id: string) => {
  return `${FILE_PREFIX}report/${user_id}_${new Date().getTime()}`;
};
const getProposalLetterFileKey = (org_id: string) => {
  return `${FILE_PREFIX}letter/${org_id}_proposal_${new Date().getTime()}`;
};
const getProjectInformationFileKey = (org_id: string) => {
  return `${FILE_PREFIX}proejct_information/${org_id}_${new Date().getTime()}`;
};
export {
  getProjectImageKey,
  getOrgProfileImageKey,
  getOrgBGImageKey,
  getUserProfileImageKey,
  getUserBGImageKey,
  getOrgMemberImageKey,
  getOrgDocKey,
  getIRS990Key,
  getVerificationFileKey,
  getAdminVerificationFileKey,
  getProposalLetterFileKey,
  getFeebackFileKey,
  getReportFileKey,
  getProjectInformationFileKey,
};
