import { useCallback } from "react";

function useScrollTo(parentId: string | undefined, offset: number) {
  const scrollTo = (elementId: string) => {
    const targetElement = document.getElementById(elementId);
    const parentElement = parentId ? document.getElementById(parentId) : window;
    if (targetElement && parentElement) {
      const parentTop =
        parentElement instanceof HTMLElement ? parentElement.getBoundingClientRect().top : 0;
      const topOffset = targetElement.offsetTop - parentTop - offset;
      // console.log("scroll-to", {
      //   elementId,
      //   offset,
      //   parentTop,
      //   targetOffTop: targetElement.offsetTop,
      //   topOffset,
      // });
      if (parentElement instanceof Window) {
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      } else {
        parentElement.scrollTo({ top: topOffset, behavior: "smooth" });
      }
    }
  };
  return { scrollTo };
}

export default useScrollTo;
