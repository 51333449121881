import { useCallback, useEffect, useState } from "react";
import useOnboardingData from "./useOnboarding";
import useUserDictionary from "./useUserDictionary";

export interface HowGibooWorks {
  key: HowGibooWorksType;
  completed: boolean;
  lowPriority: boolean;
}
export type HowGibooWorksType =
  | "find_grant"
  | "create_project"
  | "create_task"
  | "org_financial"
  | "org_diversity"
  | "search"
  | "invite"
  | "org_profile"
  | "verification"
  | "my_profile";
export const howGibooWorksKeys: HowGibooWorksType[] = [
  "find_grant",
  "create_project",
  "create_task",
  "org_financial",
  "org_diversity",
  "search",
  "invite",
  "org_profile",
  "verification",
  "my_profile",
];
const excludeKeysForEditor: HowGibooWorksType[] = ["invite", "verification"];
const HowGibooWorksLabels = {
  find_grant: "Find grant opportunities",
  create_project: "Create a fundraising project",
  create_task: "Manage tasks efficiently",
  org_financial: "Assess org’s financial health",
  org_diversity: "Analyze organization’s diversity",
  search: "Prospect new funders",
  invite: "Collaborate with organization members",
  org_profile: "Showcase my organization",
  verification: "Verify organization",
  my_profile: "Complete your profile",
};
const HowGibooWorksForEditorLabels = {
  ...HowGibooWorksLabels,
  invite: "",
  org_profile: "Review my organization profile",
  verification: "",
};
const HowGibooWorksDescriptions = {
  find_grant:
    "Are you searching for a new funding opportunity? We can assist you in finding grants that could fund your organization. Let’s begin!",
  create_project:
    "Do you need fundraising assistance? Based on your project details, Giboo will identify potential funders to add to your project management page. From there, you can apply for grants or other types of funding.",
  create_task:
    "Use our task management tool to manage your workload and easily identify the tasks that need your immediate attention. You can also collaborate with your colleagues to share the work!",
  org_financial: `Conduct a thorough evaluation of your organization's financial sustainability. This provides a comprehensive overview of insights into your organization’s financial landscape.`,
  org_diversity: `Elevate your organization profile by adding diversity information. This fostering stronger connections with funders and demonstrating a commitment to inclusivity.`,
  search:
    "Are you looking for new funders that fit your organization? Customize your search for ideal funders and explore the list of recommended funders who fulfill your needs.",
  invite:
    "Invite other members of your team to work with you on Giboo! Share potential opportunities, divide tasks, collaborate seamlessly to maximize your organization's impact.",
  org_profile:
    "Build your organization’s profile page to highlight its mission, values, and impact. Attract potential funders by showcasing the strengths of your organization!",
  verification:
    "Verify your organization to unlock full access to Giboo's features and help us create a secure community.",
  my_profile:
    "Enhance your personal profile to make a strong impression on potential collaborators and funders. Completing your profile ensures you're well-represented on Giboo.",
};
const HowGibooWorksForEditorDescriptions = {
  ...HowGibooWorksDescriptions,
  invite: "",
  // org_profile: "TBD",
  verification: "",
};
export const levelToTitle = ["Beginner", "Explorer", "Expert"];
export interface HowGibooWorksKeyString {
  find_grant: string;
  create_project: string;
  create_task: string;
  org_financial: string;
  org_diversity: string;
  search: string;
  invite: string;
  org_profile: string;
  verification: string;
  my_profile: string;
}
export interface HowGibooWorksLevelState {
  allCompleted: boolean;
  totalCompletedCount: number;
  totalCount: number;
  level: number;
  experience: number;
  targetExperience: number;
}
interface HowGibooWorksState extends HowGibooWorksLevelState {
  items: HowGibooWorks[];
  labels: HowGibooWorksKeyString;
  descriptions: HowGibooWorksKeyString;
  complete: (key: HowGibooWorksType) => HowGibooWorksLevelState | undefined;
  setTopPriority: (key: HowGibooWorksType[]) => void;
  setAsLowPriority: (key: HowGibooWorksType) => void;
  pageVisit: IpageVisit;
  updatePageVisit: (data: IpageVisit) => void;
  reset: () => void;
  isCompleted: (key: HowGibooWorksType) => boolean;
}
const defaultItems: HowGibooWorks[] = howGibooWorksKeys.map((k) => ({
  key: k,
  completed: false,
  lowPriority: false,
}));
const levelUpCountForAdmin = [3, 2, 5, 1000];
const levelUpCountForEditor = [2, 2, 4, 1000];
export interface IpageVisit {
  beginner: boolean;
  explorer: boolean;
  expert: boolean;
  profileVisited: boolean;
  orgProfileVisited: boolean;
  funderVisited: boolean;
  searchVisited: boolean;
  projectVisited: boolean;
  financialAssessVisited: boolean;
  diversityAssessVisited: boolean;
}
export default function useHowGibooWorks(): HowGibooWorksState {
  const [onboardingData] = useOnboardingData();
  const { value, update } = useUserDictionary<{ items: HowGibooWorks[] }>("how_giboo_works", {
    items: defaultItems,
  });
  const { value: pageVisit, update: updatePageVisit } = useUserDictionary<IpageVisit>(
    "giboo_works_level",
    {
      beginner: false,
      expert: false,
      explorer: false,
      orgProfileVisited: false,
      profileVisited: false,
      funderVisited: false,
      searchVisited: false,
      projectVisited: false,
      financialAssessVisited: false,
      diversityAssessVisited: false,
    },
  );
  const [labels, setLabels] = useState<HowGibooWorksKeyString>(HowGibooWorksLabels);
  const [descriptions, setDescriptions] =
    useState<HowGibooWorksKeyString>(HowGibooWorksDescriptions);
  const [totalCount, setTotalCount] = useState<number>(howGibooWorksKeys.length);
  const [allCompleted, setAllCompleted] = useState<boolean>(false);
  const [totalCompletedCount, setTotalCompletedCount] = useState<number>(0);
  const [level, setLevel] = useState<number>(0);
  const [experience, setExperience] = useState<number>(0);
  const [targetExperience, setTargetExperience] = useState<number>(0);
  const [validKeys, setValidKeys] = useState<HowGibooWorksType[]>([]);
  useEffect(() => {
    setLabels(onboardingData.role >= 4 ? HowGibooWorksLabels : HowGibooWorksForEditorLabels);
    setDescriptions(
      onboardingData.role >= 4 ? HowGibooWorksDescriptions : HowGibooWorksForEditorDescriptions,
    );
    setTotalCount(
      onboardingData.role >= 4
        ? howGibooWorksKeys.length
        : howGibooWorksKeys.length - excludeKeysForEditor.length,
    );
    setValidKeys(
      onboardingData.role >= 4
        ? howGibooWorksKeys
        : howGibooWorksKeys.filter((t) => !excludeKeysForEditor.includes(t)),
    );
  }, [onboardingData, setLabels, setDescriptions, setTotalCount, setValidKeys]);

  useEffect(() => {
    const levelUpCount = onboardingData.role >= 4 ? levelUpCountForAdmin : levelUpCountForEditor;
    const completedCount = value.items.filter((i) => i.completed).length;
    const { _level, count } = levelUpCount.reduce(
      (prev, cur) =>
        prev.count >= cur
          ? { count: prev.count - cur, _level: prev._level + 1 }
          : { count: prev.count - cur, _level: prev._level },
      { count: completedCount, _level: 0 },
    );
    setLevel(_level);
    const _targetExperience = levelUpCount.slice(0, _level + 1).reduce((p, c) => p + c, 0);
    const prevExperience = levelUpCount.slice(0, _level).reduce((p, c) => p + c, 0);
    setTotalCompletedCount(completedCount);
    setTargetExperience(levelUpCount[_level]);
    setExperience(completedCount - prevExperience);
    setAllCompleted(completedCount === totalCount);
  }, [
    onboardingData,
    value,
    totalCount,
    setLevel,
    setTotalCompletedCount,
    setTargetExperience,
    setExperience,
    setAllCompleted,
  ]);

  const complete = useCallback(
    (key: HowGibooWorksType): HowGibooWorksLevelState | undefined => {
      const levelUpCount = onboardingData.role >= 4 ? levelUpCountForAdmin : levelUpCountForEditor;
      const target = value.items.find((v) => v.key === key);
      const index = value.items.findIndex((v) => v.key === key);
      const completedCount = value.items.filter((i) => i.completed).length;
      const { _level: nextLevel, count } = levelUpCount.reduce(
        (prev, cur) =>
          prev.count >= cur
            ? { count: prev.count - cur, _level: prev._level + 1 }
            : { count: prev.count - cur, _level: prev._level },
        { count: completedCount + 1, _level: 0 },
      );
      const _targetExperience = levelUpCount.slice(0, nextLevel + 1).reduce((p, c) => p + c, 0);
      const prevExperience = levelUpCount.slice(0, nextLevel).reduce((p, c) => p + c, 0);
      if (target) {
        update({
          items: [
            ...value.items.slice(0, index),
            { ...value.items[index], completed: true },
            ...value.items.slice(index + 1),
          ],
        });
        setTimeout(() => {
          const target = value.items.find((v) => v.key === key);
          if (target)
            update({
              items: [
                ...value.items.filter((v) => v.key !== key),
                { ...target, lowPriority: true, completed: true },
              ].sort((a, b) => (a.completed as any) - (b.completed as any)),
            });
        }, 2000);
        return {
          allCompleted: completedCount + 1 === totalCount,
          experience:
            completedCount + 1 === _targetExperience ? 0 : completedCount - prevExperience + 1,
          level: nextLevel,
          targetExperience: levelUpCount[nextLevel],
          totalCompletedCount: completedCount + 1,
          totalCount,
        };
      }
      return undefined;
    },
    [value, update, onboardingData],
  );
  const setTopPriority = useCallback(
    (key: HowGibooWorksType[]) => {
      update({
        items: [
          ...value.items.filter((v) => key.includes(v.key)),
          ...value.items.filter((v) => !key.includes(v.key)),
        ],
      });
    },
    [value, update],
  );
  const setAsLowPriority = useCallback(
    (key: HowGibooWorksType) => {
      const target = value.items.find((v) => v.key === key);
      if (target)
        update({
          items: [
            ...value.items.filter((v) => v.key !== key && !v.completed),
            { ...target, lowPriority: true },
            ...value.items.filter((v) => v.key !== key && v.completed),
          ],
        });
    },
    [value, update],
  );
  const reset = useCallback(() => {
    update({ items: defaultItems });
  }, [value, update]);

  const isCompleted = (key: HowGibooWorksType) => {
    return value.items.find((i) => i.key === key)?.completed || false;
  };
  return {
    allCompleted,
    totalCompletedCount,
    totalCount,
    level,
    experience,
    targetExperience,
    items: value.items.filter((i) => validKeys.includes(i.key)),
    // .sort((a, b) => (a.completed as any) - (b.completed as any)),
    labels,
    descriptions,
    complete,
    setTopPriority, //this is going to be needed at onboarding
    setAsLowPriority,
    reset,
    pageVisit,
    updatePageVisit,
    isCompleted,
  };
}
