import { useCallback, useEffect, useState } from "react";
import { IProject } from "../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../api";
import { swrOptionDedupling5mins, swrOptionFetchOnlyOnMount } from "../types/swr";
import useOrgID from "./useOrgID";
import useProject from "./project/useProject";
import useOnboardingData from "./useOnboarding";
interface Tagging {
  focus_area: string[];
  beneficiary: string[];
  program: string[];
}
interface OrgProjectTaggingState {
  data: Tagging;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
}
function useOrgProjectTagging(project_id?: string): OrgProjectTaggingState {
  const org_id = useOrgID();
  const [onboardingData] = useOnboardingData();
  const { data: project, isLoading: projectLoading } = useProject(project_id);
  const url_project = process.env.REACT_APP_API_URL + `/api/v2/projects/${project_id}/tagging`;
  const url_org = process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/tagging`;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Tagging>({ focus_area: [], beneficiary: [], program: [] });
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<Tagging>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as Tagging);
  };
  const {
    data: dataProject,
    isLoading,
    error,
    isValidating,
  } = useSWR<Tagging>(
    project._id &&
      (project.focus_area.length === 0 ||
        project.beneficiary.length === 0 ||
        project.program.length === 0)
      ? url_project
      : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const {
    data: dataOrg,
    isLoading: isLoadingOrg,
    error: errorOrg,
    isValidating: isValidatingOrg,
  } = useSWR<Tagging>(
    org_id &&
      (!project_id ||
        (dataProject &&
          dataProject.focus_area.length === 0 &&
          dataProject.beneficiary.length === 0 &&
          dataProject.program.length === 0))
      ? url_org
      : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  useEffect(() => {
    setLoading(true);
    const p1 = project.focus_area.length + project.beneficiary.length + project.program.length;
    const p2 =
      project.focus_area.length +
      project.beneficiary.length +
      project.program.length +
      (dataProject?.focus_area.length || 0) +
      (dataProject?.beneficiary.length || 0) +
      (dataProject?.program.length || 0);
    const p3 =
      onboardingData.focus_area.length +
      onboardingData.beneficiary.length +
      onboardingData.program.length;
    const enough_p1 = p1 > 9;
    const enough_p2 = p1 + p2 > 9;
    const enough_p3 = p1 + p2 + p3 > 9;
    const all = {
      focus_area: [
        ...project.focus_area,
        ...(enough_p1 ? [] : dataProject?.focus_area || []),
        ...(enough_p2 ? [] : onboardingData.focus_area),
        ...(enough_p3 ? [] : dataOrg?.focus_area || []),
      ],
      beneficiary: [
        ...project.beneficiary,
        ...(enough_p1 ? [] : dataProject?.beneficiary || []),
        ...(enough_p2 ? [] : onboardingData.beneficiary),
        ...(enough_p3 ? [] : dataOrg?.beneficiary || []),
      ],
      program: [
        ...project.program,
        ...(enough_p1 ? [] : dataProject?.program || []),
        ...(enough_p2 ? [] : onboardingData.program),
        ...(enough_p3 ? [] : dataOrg?.program || []),
      ],
    };
    setData(all);
    setLoading(false);
  }, [project, dataProject, dataOrg, onboardingData]);
  return {
    data,
    isLoading: loading || projectLoading || isLoading || isLoadingOrg,
    error: error || errorOrg,
    isValidating: isValidating || isValidatingOrg,
  };
}
export default useOrgProjectTagging;
