import React, { useEffect, useRef, useState, ReactNode } from "react";

interface VisibilityTrackerProps {
  children: ReactNode;
  loading?: boolean;
  onVisible: () => void;
  elementIdOrRef: string | React.RefObject<Element>;
  timeout?: number;
  options?: IntersectionObserverInit;
}

const VisibilityTracker: React.FC<VisibilityTrackerProps> = ({
  children,
  onVisible,
  elementIdOrRef,
  timeout = 5000,
  options,
  loading,
}) => {
  const [hasBeenVisible, setHasBeenVisible] = useState(false);
  const visibilityTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (loading) return;
    const element =
      typeof elementIdOrRef === "string"
        ? document.getElementById(elementIdOrRef)
        : elementIdOrRef.current;
    if (!element) {
      console.warn("Element not found");
      return;
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasBeenVisible) {
          visibilityTimeoutRef.current = window.setTimeout(() => {
            onVisible();
            setHasBeenVisible(true);
          }, timeout);
        } else {
          if (visibilityTimeoutRef.current) {
            clearTimeout(visibilityTimeoutRef.current);
          }
        }
      });
    }, options || { threshold: 1.0 });

    observer.observe(element);

    return () => {
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current);
      }
      observer.unobserve(element);
    };
  }, [elementIdOrRef, hasBeenVisible, onVisible, timeout, options, loading]);

  return <>{children}</>;
};

export default VisibilityTracker;
