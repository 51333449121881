import axios from "axios";

const SUPPORTED_MIME = {
  image: [
    "image/jpeg", // .jpg, .jpeg
    "image/png", // .png
    "image/gif", // .gif
    "image/bmp", // .bmp
    "image/tiff", // .tiff
    "image/webp", // .webp
    "image/svg+xml", // .svg
    "image/x-icon", // .ico],
  ],
  doc: [
    "application/pdf", // .pdf
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/vnd.ms-excel", // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/vnd.ms-powerpoint", // .ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/rtf", // .rtf
    "text/plain", // .txt
    "text/csv", // .csv
    "application/vnd.oasis.opendocument.text", // .odt
    "application/vnd.oasis.opendocument.spreadsheet", // .ods
    "application/vnd.oasis.opendocument.presentation", // .odp
    "application/epub+zip", // .epub
    "application/x-mobipocket-ebook", // .mobi
    "application/x-fictionbook+xml", // .fb2
  ],
};
export const uploadFileService = (
  file: File,
  key: string,
  types: ("image" | "doc")[],
): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    if (types.length === 0) return reject("type is not specified");
    const supportedMimes = [
      ...(types.includes("image") ? SUPPORTED_MIME.image : []),
      ...(types.includes("doc") ? SUPPORTED_MIME.doc : []),
    ];
    const mime = file.type;
    if (!mime || !supportedMimes.includes(mime)) reject(`${mime} is not supported`);
    axios
      .post(
        process.env.REACT_APP_UPLOAD_NPO_MEDIA_URL || "",
        { key, mime },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then(({ data }) => {
        if (data) {
          // console.log("upload presigned url:", data);
          try {
            axios.put(data, file, {
              headers: {
                "Content-Type": mime,
              },
            });
          } catch (e) {
            // console.log(e);
          }
        }
        resolve(key);
      })
      .catch((e) => reject(e));
  });
};
