import classNames from "classnames";
import Fuse from "fuse.js";
import { useState } from "react";
import { Divider } from "rsuite";
import useSave from "../../hooks/save/useSave";
import useNPOSearchResult from "../../hooks/search/useNPOSearchResult";
import useLazyLoad from "../../hooks/useLazyLoad";
import { NPOSearchResult, SearchQuery, getSearchResultKey } from "../../types/search";
import FuzzyMatchName from "../FuzzyMatchName";
import MemoizedLoadingTaxonomy from "../LoadingTaxonomy";
import PhilanthropicInformationFlexWrap from "../PhilanthropicInformationFlexWrap";
import SavePopup from "../SavePopup";
import TagFunderType from "../TagFunderType";
import { TooltipWrapper } from "../TooltipWrapper";
interface NPOCardProps {
  npo: NPOSearchResult;
  query?: SearchQuery;
  noMatchTaxonomy?: boolean;
  hideInfo?: boolean;
  fuzzy?: Fuse<string>;
  isHidden?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  setHide?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, hide: boolean) => void;
  selected?: boolean;
  save_list_id?: string;
  savedSearchQuery?: SearchQuery;
  lazyLoadDelay?: number;
  hideBtns?: boolean;
  hideHiddenBtn?: boolean;
  useMinHeightOnTaxonomy?: boolean;
}
function NPOCard({
  npo,
  noMatchTaxonomy = false,
  hideHiddenBtn = false,
  hideInfo = false,
  query,
  fuzzy,
  isHidden = false,
  setHide,
  onClick,
  lazyLoadDelay = 0,
  hideBtns = false,
  ...props
}: NPOCardProps) {
  const key = getSearchResultKey(npo);
  const [showFundraising, setShowFundraising] = useState<boolean>(false);
  const { ready } = useLazyLoad(lazyLoadDelay);
  const { data: npoTaxonomy, isLoading } = useNPOSearchResult(
    !hideInfo && !noMatchTaxonomy && ready ? npo._id : undefined,
    query,
  );
  const saveMode = props.save_list_id ? true : false;
  const { data, save } = useSave(saveMode ? npo._id : undefined, 3, props.savedSearchQuery);
  const { data: currentSavedList } = useSave(npo._id, 3);
  const [showSave, setShowSave] = useState<boolean>(false);

  return (
    <>
      {showSave && npo._id && (
        <SavePopup
          open={showSave}
          onClose={() => setShowSave(false)}
          target_id={npo?._id}
          target_type={3}
          searchQuery={query}
        />
      )}
      <div
        key={key}
        className={classNames(
          "flex h-fit w-full flex-col gap-[10px]",
          onClick ? "cursor-pointer bg-white hover:bg-purple-50" : "",
        )}
        {...(onClick ? { onClick } : {})}
      >
        {/* {showFundraising && (
        <StartFundRaisingModal open={showFundraising} setOpen={setShowFundraising} type="NPO" />
      )} */}
        <div className="inline-flex items-center justify-between">
          <div className="flex items-center gap-2">
            <TagFunderType funder_type={npo.npo_type} />
          </div>
          {!hideBtns && !hideInfo && (
            <>
              {saveMode ? (
                <TooltipWrapper content={isHidden ? "Save this npo" : "Unsave this npo"}>
                  <div
                    className="cursor-pointer p-1 !text-gray-900"
                    id={"GA_TAG_click_save"}
                    onClick={(e) => {
                      if (props.save_list_id) save(props.save_list_id, isHidden);
                      e.stopPropagation();
                    }}
                  >
                    <i
                      className={classNames(
                        "gi-md text-purple-500 ",
                        isHidden ? "gi-heart" : "gi-heart-full",
                      )}
                    />
                  </div>
                </TooltipWrapper>
              ) : (
                <div className="inline-flex items-center">
                  <TooltipWrapper
                    content={currentSavedList.length > 0 ? "Unsave this npo" : "Save this npo"}
                  >
                    <div
                      className="cursor-pointer p-1 !text-gray-900"
                      id={"GA_TAG_click_save"}
                      onClick={(e) => {
                        setShowSave(true);
                        e.stopPropagation();
                      }}
                    >
                      <i
                        className={classNames(
                          "gi-md text-purple-500 ",
                          currentSavedList.length > 0 ? "gi-heart-full" : "gi-heart",
                        )}
                      />
                    </div>
                  </TooltipWrapper>
                  {!hideHiddenBtn && (
                    <TooltipWrapper content={isHidden ? "Unhide this npo" : "Hide this npo"}>
                      <div
                        className="cursor-pointer p-1 !text-gray-900"
                        onClick={(e) => {
                          setHide?.(e, !isHidden);
                        }}
                      >
                        <i
                          className={classNames("gi-md ", isHidden ? "gi-view-hide" : "gi-view")}
                        />
                      </div>
                    </TooltipWrapper>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex items-start justify-between">
          <h4 className="line-clamp-2 w-full grow whitespace-pre-line font-semibold text-purple-500 group-hover:underline">
            <FuzzyMatchName name={npo.name} fuzzy={fuzzy} items={query?.text_query?.split(" ")} />
          </h4>
          {/* <div className="mt-1 flex min-w-[40px] items-center justify-end gap-4">
          <RoundButton
            tooltip={"Start fundraising"}
            type="secondary"
            id={`btn-add-npo-to-project-${npo._id}`}
            handleOnClick={() => {
              console.log("handle save to project");
            }}
            icon="gi-sm gi-start text-purple-500"
          />
        </div> */}
        </div>
        {!hideInfo ? (
          <>
            <Divider
              className={classNames("my-0 ", props.selected ? "bg-purple-200" : "bg-gray-200")}
            />
            {npo.address && (
              <h5 className="font-semibold text-gray-900">
                Address: <span className="font-normal text-gray-900">{npo.address}</span>
              </h5>
            )}
            {!noMatchTaxonomy &&
              (isLoading || (!ready && !npoTaxonomy) ? (
                <MemoizedLoadingTaxonomy seed={npo.uid} />
              ) : (
                <PhilanthropicInformationFlexWrap
                  containerClass={classNames(
                    "p-4 border rounded",
                    props.selected ? "border-purple-200" : "border-gray-300",
                  )}
                  id={key}
                  focusArea={npoTaxonomy?.focus_area}
                  beneficiary={npoTaxonomy?.beneficiary}
                  program={npoTaxonomy?.program}
                  location={npoTaxonomy?.service_loc}
                  priorityFocusArea={query?.must_focus_area}
                  priorityBeneficiary={query?.must_beneficiary}
                  priorityProgram={query?.must_program}
                  priorityLocation={query?.must_service_loc}
                  secondaryPriorityLocation={query?.service_loc}
                  tagSize="sm"
                  tagMatched
                  predictedSize={5}
                  useMinHeight={props.useMinHeightOnTaxonomy}
                />
              ))}
          </>
        ) : (
          <div className="h-2"></div>
        )}
      </div>
    </>
  );
}
export default NPOCard;
