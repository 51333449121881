import classNames from "classnames";
import useOrgMembers from "../hooks/project/useOrgMembers";
import useOnboardingData from "../hooks/useOnboarding";
import { scrollBarClass } from "../utils/classes";
import { User } from "../app/userSlice";
import UserProfile from "./UserProfile";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { Dispatch, SetStateAction, useRef } from "react";

interface OrgMemberSuggestionProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  query: string;
  exclude: string[];
  wrapperClass?: string;
  className?: string;
  onSelect: (member: User) => void;
}
function OrgMemberSuggestion({
  show,
  setShow,
  query,
  exclude,
  wrapperClass,
  className,
  onSelect,
}: OrgMemberSuggestionProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [onboardingData] = useOnboardingData();
  const { data: members } = useOrgMembers(onboardingData._id);
  useOnClickOutside(ref, (e) => setShow(false));
  return (
    <>
      {show && (
        <div ref={ref} className={classNames("relative h-0 w-full", wrapperClass)}>
          <div
            className={classNames(
              "absolute top-0 float-left flex max-h-[400px] w-full flex-col gap-2 border border-gray-300 bg-white shadow",
              scrollBarClass,
              className,
            )}
          >
            {members
              .filter(
                (m) =>
                  !exclude.includes(m._id) &&
                  (`${m.firstname} ${m.lastname}`.toLowerCase().includes(query.toLowerCase()) ||
                    m.email.toLowerCase().includes(query.toLowerCase())),
              )
              .map((m) => (
                <div
                  key={m._id}
                  onClick={() => {
                    console.log(m);
                    onSelect(m);
                  }}
                  className="cursor-pointer px-2 py-1 hover:bg-gray-200"
                >
                  <UserProfile org_id={onboardingData._id} userId={m._id} showEmail showName />
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
export default OrgMemberSuggestion;
