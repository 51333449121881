import { useEffect, useState } from "react";
import useFakeProgress from "../hooks/useFakeProgress";
import classNames from "classnames";

function FakeProgressBar({
  progressKey,
  done,
  estimatedSeconds = 3,
  height = "6px",
  gradation = false,
}: {
  progressKey?: string;
  done?: boolean;
  estimatedSeconds?: number;
  height?: string;
  gradation?: boolean;
}) {
  const { progress, restart } = useFakeProgress({ done, estimatedSeconds });
  useEffect(() => {
    if (progressKey) restart();
  }, [progressKey]);
  return (
    <div className="flex w-full justify-start rounded bg-gray-200" style={{ height: height }}>
      <div
        className={classNames(
          "rounded transition-all duration-200",
          gradation ? "giboo-gradient-bg" : "bg-purple-500",
        )}
        style={{ height: height, width: `${Math.ceil(progress * 100)}%` }}
      ></div>
    </div>
  );
}
export default FakeProgressBar;
