import { useNavigate } from "react-router-dom";
import useOrgProfile from "../hooks/useOrgProfile";
import GibooToast from "./GibooToast";
import { IOrgProfileUpdateRequest } from "../types/org";
import { TooltipWrapper } from "./TooltipWrapper";
import { ReactNode, useRef, useState } from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";
import PopupModal from "./tailwind/PopupModal";
import Button from "./tailwind/Button";
import Checkbox from "./checkbox/Checkbox";
import { NpoTitle } from "../pages/npo/dashboard/components/NpoDiversityEthnicity";
import EditButton from "./EditButton";
import EthnicityInfo from "../pages/npo/diversity/pages/EthnicityInfo";
import GenderInfo from "../pages/npo/diversity/pages/GenderInfo";
import AgeInfo from "../pages/npo/diversity/pages/AgeInfo";
import SexualOrientationInfo from "../pages/npo/diversity/pages/SexualOrientationInfo";
import DisablityInfo from "../pages/npo/diversity/pages/DisablityInfo";
import ReligionInfo from "../pages/npo/diversity/pages/ReligionInfo";
import ProfessionInfo from "../pages/npo/diversity/pages/ProfessionInfo";
import Spinner from "./Spinner";
import classNames from "classnames";
import PlainButton from "./PlainButton";

export const AssessmentSetting = ({
  hasWritePermission,
  type,
  org_id,
  npo_id,
}: {
  hasWritePermission?: boolean;
  type: "financial" | "diversity";
  org_id?: string;
  npo_id?: string;
}) => {
  const navigate = useNavigate();
  const [openSetting, setOpenSetting] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const { data: profileData, revalidate, update } = useOrgProfile(org_id);
  const [isResetting, setIsResetting] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpenSetting(false);
  });
  const restartAsessment = () => {
    if (!hasWritePermission) return;
    setIsResetting(true);
    const loadStatus = [
      "age",
      "disability",
      "ethnicity",
      "gender",
      "board",
      "religion",
      "sexual_orientation",
    ];
    const data: IOrgProfileUpdateRequest =
      type === "financial"
        ? {
            financial_completed: false,
            financial_saved_index: 0,
            skip_loading: profileData.skip_loading?.filter((i) => loadStatus.includes(i)),
          }
        : {
            diversity_completed: false,
            diversity_saved_index: 0,
            diversity_consent: false,
            skip_loading: profileData.skip_loading?.filter((i) => !loadStatus.includes(i)),
            hide_ethnicity: undefined,
            hide_gender: undefined,
            hide_age: undefined,
            hide_sexual: undefined,
            hide_disability: undefined,
            hide_religion: undefined,
            hide_profession: undefined,
          };
    update(data)
      .then(() => {
        revalidate().finally(() => {
          type === "financial" ? navigate("/financial") : navigate("/diversity");
          setIsResetting(false);
          setShowInfoModal(false);
        });
      })
      .catch((er) => {
        GibooToast({
          type: "failure",
          message: `We apologize for the inconvenience. Please try again.`,
        });
        setIsResetting(false);
        setShowInfoModal(false);
      });
  };
  if (!hasWritePermission) return <></>;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-6"
        title="Edit public mode"
        LeftActionButton={() => {
          return (
            <Button
              id="reset-assessment"
              label="Restart assessment"
              className="!h-[37px]"
              loading={isResetting}
              spinnerColor="purple"
              handleOnClick={() => {
                restartAsessment();
              }}
              outline
            />
          );
        }}
        RightActionButton={() => {
          return (
            <Button
              id="done-setting"
              label="Save"
              className="!h-[37px]"
              handleOnClick={() => {
                setShowInfoModal(false);
              }}
            />
          );
        }}
      >
        {type === "financial" ? (
          <FinancialPublicMode org_id={org_id} npo_id={npo_id} />
        ) : (
          <DiversityPublicMode org_id={org_id} npo_id={npo_id} />
        )}
      </PopupModal>
      <TooltipWrapper
        color="white"
        isControlled
        isOpen={openSetting}
        WrapperClass="!p-0"
        content={
          <div className="flex flex-col gap-0 p-0" ref={ref}>
            <div
              className="cursor-pointer px-4 py-2 font-poppins text-sm text-gray-900 hover:bg-gray-100"
              onClick={() => {
                setOpenSetting(false);
                setShowInfoModal(true);
              }}
            >
              Edit public mode
            </div>
            <div
              onClick={() => {
                restartAsessment();
              }}
              className="inline-flex cursor-pointer px-4 py-2 font-poppins text-sm  text-gray-900 hover:bg-gray-100"
            >
              {isResetting ? <Spinner size="sm" wrapperClass="mx-auto" /> : "Restart assessment"}
            </div>
          </div>
        }
        placements="bottomEnd"
        arrow={false}
      >
        <span
          className="cursor-pointer rounded-full p-1 hover:bg-gray-100"
          onClick={() => setOpenSetting((prev) => !prev)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.92391 4.01923L8.42827 2.73846C8.51333 2.52161 8.66298 2.33509 8.85762 2.20332C9.05226 2.07155 9.28282 2.00069 9.5191 2H10.4809C10.7172 2.00069 10.9477 2.07155 11.1424 2.20332C11.337 2.33509 11.4867 2.52161 11.5717 2.73846L12.0761 4.01923L13.7886 4.98846L15.1726 4.78077C15.4031 4.75 15.6377 4.78732 15.8465 4.88799C16.0554 4.98866 16.2291 5.14814 16.3456 5.34615L16.8148 6.15385C16.935 6.35502 16.9904 6.58732 16.9736 6.8201C16.9569 7.05288 16.8688 7.27519 16.7209 7.45769L15.8647 8.53077V10.4692L16.7444 11.5423C16.8922 11.7248 16.9803 11.9471 16.9971 12.1799C17.0138 12.4127 16.9584 12.645 16.8382 12.8462L16.369 13.6538C16.2525 13.8519 16.0788 14.0113 15.87 14.112C15.6611 14.2127 15.4266 14.25 15.1961 14.2192L13.812 14.0115L12.0996 14.9808L11.5952 16.2615C11.5101 16.4784 11.3605 16.6649 11.1658 16.7967C10.9712 16.9284 10.7406 16.9993 10.5044 17H9.5191C9.28282 16.9993 9.05226 16.9284 8.85762 16.7967C8.66298 16.6649 8.51333 16.4784 8.42827 16.2615L7.92391 14.9808L6.21142 14.0115L4.82736 14.2192C4.5969 14.25 4.36234 14.2127 4.15349 14.112C3.94464 14.0113 3.77092 13.8519 3.65442 13.6538L3.18525 12.8462C3.06503 12.645 3.00964 12.4127 3.02639 12.1799C3.04314 11.9471 3.13125 11.7248 3.27908 11.5423L4.13533 10.4692V8.53077L3.25563 7.45769C3.10779 7.27519 3.01968 7.05288 3.00293 6.8201C2.98618 6.58732 3.04157 6.35502 3.16179 6.15385L3.63097 5.34615C3.74747 5.14814 3.92118 4.98866 4.13003 4.88799C4.33888 4.78732 4.57344 4.75 4.8039 4.78077L6.18796 4.98846L7.92391 4.01923ZM7.65413 9.5C7.65413 9.95642 7.79171 10.4026 8.04948 10.7821C8.30725 11.1616 8.67362 11.4574 9.10227 11.632C9.53093 11.8067 10.0026 11.8524 10.4577 11.7634C10.9127 11.6743 11.3307 11.4545 11.6588 11.1318C11.9869 10.809 12.2103 10.3979 12.3008 9.95021C12.3913 9.50256 12.3449 9.03856 12.1673 8.61688C11.9897 8.19521 11.6891 7.8348 11.3033 7.58122C10.9175 7.32765 10.464 7.19231 10 7.19231C9.37784 7.19231 8.78116 7.43544 8.34122 7.86821C7.90128 8.30099 7.65413 8.88796 7.65413 9.5Z"
              stroke="black"
              strokeWidth="1.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </TooltipWrapper>
    </>
  );
};

function DiversityPublicMode({ org_id, npo_id }: { org_id?: string; npo_id?: string }) {
  const { data: profileData, revalidate, update } = useOrgProfile(org_id);
  return (
    <>
      <div className="flex w-full flex-col gap-3">
        <div className="rounded bg-gray-50 px-5 py-4">
          <h5 className="font-poppins text-sm">
            Choose or edit the diversity information you wish to showcase in your
            organization&apos;s profile.
          </h5>
        </div>
        {/* ETHINICITY */}
        <EditModeItem
          Edit={<EthnicityInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          title="Ethnicity"
          value={!profileData.hide_ethnicity}
          onChange={(data) => {
            update({ hide_ethnicity: !data });
          }}
        />
        {/* GENDER */}

        <EditModeItem
          Edit={<GenderInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          title="Gender"
          value={!profileData.hide_gender}
          onChange={(data) => {
            update({ hide_gender: !data });
          }}
        />

        {/* AGE */}
        <EditModeItem
          Edit={<AgeInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          title="Age"
          value={!profileData.hide_age}
          onChange={(data) => {
            update({ hide_age: !data });
          }}
        />

        {/* Sexaul orientation */}
        <EditModeItem
          Edit={<SexualOrientationInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          title="Sexual orientation perspective"
          value={!profileData.hide_sexual}
          onChange={(data) => {
            update({ hide_sexual: !data });
          }}
        />

        {/* Disability */}
        <EditModeItem
          Edit={<DisablityInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          title="Disability"
          value={!profileData.hide_disability}
          onChange={(data) => {
            update({ hide_disability: !data });
          }}
        />

        {/* Religion */}
        <EditModeItem
          Edit={<ReligionInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          title="Religion"
          value={!profileData.hide_religion}
          onChange={(data) => {
            update({ hide_religion: !data });
          }}
        />

        {/* Profession */}
        <EditModeItem
          Edit={<ProfessionInfo npo_id={npo_id} org_id={org_id} isEditMode />}
          title="Professional background of board member"
          value={!profileData.hide_profession}
          onChange={(data) => {
            update({ hide_profession: !data });
          }}
        />
      </div>
    </>
  );
}
function FinancialPublicMode({ org_id, npo_id }: { org_id?: string; npo_id?: string }) {
  const { data: profileData, revalidate, update } = useOrgProfile(org_id);
  return (
    <div className="flex w-full flex-col gap-3">
      <div className="rounded bg-gray-50 px-5 py-4">
        <h5 className="font-poppins text-sm">
          Choose or edit the financial information you wish to showcase in your organization&apos;s
          profile.
        </h5>
      </div>
      {/* Business (Revenue) model */}
      <div className="inline-flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-4">
        <Checkbox
          id={`input-check-consent`}
          className=""
          label={""}
          size="md"
          inputClass="!border-gray-700"
          labelClass="!text-xs font-poppins !text-gray-700"
          checked={!profileData.hide_revenue_model}
          onChange={() => {
            update({ hide_revenue_model: !profileData.hide_revenue_model });
          }}
        />
        <NpoTitle title="Business (Revenue) model" recomendation />
        <EditButton />
      </div>
      {/* Total contributions */}
      <div className="inline-flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-4">
        <Checkbox
          id={`input-check-consent`}
          className=""
          label={""}
          size="md"
          inputClass="!border-gray-700"
          labelClass="!text-xs font-poppins !text-gray-700"
          checked={!profileData.hide_contribution}
          onChange={() => {
            update({ hide_contribution: !profileData.hide_contribution });
          }}
        />
        <NpoTitle title="Total contributions" recomendation />
        <EditButton />
      </div>
      {/* Revenue stream */}
      <div className="inline-flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-4">
        <Checkbox
          id={`input-check-consent`}
          className=""
          label={""}
          size="md"
          inputClass="!border-gray-700"
          labelClass="!text-xs font-poppins !text-gray-700"
          checked={!profileData.hide_revenue_stream}
          onChange={() => {
            update({ hide_revenue_stream: !profileData.hide_revenue_stream });
          }}
        />
        <NpoTitle title="Revenue stream" recomendation />
        <EditButton />
      </div>
      {/* Operating expenses */}
      <div className="inline-flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-4">
        <Checkbox
          id={`input-check-consent`}
          className=""
          label={""}
          size="md"
          inputClass="!border-gray-700"
          labelClass="!text-xs font-poppins !text-gray-700"
          checked={!profileData.hide_operating}
          onChange={() => {
            update({ hide_operating: !profileData.hide_operating });
          }}
        />
        <NpoTitle title="Operating expensesOperating expenses" recomendation />
        <EditButton />
      </div>
      {/* Financial assets categories */}
      <div className="inline-flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-4">
        <Checkbox
          id={`input-check-consent`}
          className=""
          label={""}
          size="md"
          inputClass="!border-gray-700"
          labelClass="!text-xs font-poppins !text-gray-700"
          checked={!profileData.hide_asset}
          onChange={() => {
            update({ hide_asset: !profileData.hide_asset });
          }}
        />
        <NpoTitle title="Financial assets categories" recomendation />
        <EditButton />
      </div>
      {/* Working capital: Month of liquid */}
      <div className="inline-flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-4">
        <Checkbox
          id={`input-check-consent`}
          className=""
          label={""}
          size="md"
          inputClass="!border-gray-700"
          labelClass="!text-xs font-poppins !text-gray-700"
          checked={!profileData.hide_month_of_liquid}
          onChange={() => {
            update({ hide_month_of_liquid: !profileData.hide_month_of_liquid });
          }}
        />
        <NpoTitle title="Working capital: Month of liquid" recomendation />
        <EditButton />
      </div>
    </div>
  );
}
export function EditModeItem({
  value,
  onChange,
  title,
  Edit,
  onEdit,
  noBorder,
  children,
}: {
  value: boolean;
  onChange?: (data: boolean) => void;
  onEdit?: () => void;
  title: string;
  Edit?: ReactNode;
  noBorder?: boolean;
  children?: ReactNode;
}) {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [hide, setHide] = useState(false);
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[930px] !max-w-[930px] md:!px-0 "
        contentClass="!pl-6 !pt-0"
        title="Edit information"
        RightActionButton={() => {
          return (
            <Button
              id="done-setting"
              label="Save"
              className="!h-[37px]"
              handleOnClick={() => {
                setShowInfoModal(false);
              }}
            />
          );
        }}
      >
        <div
          className="diversity-edit-mode h-[650px] w-full overflow-y-auto pt-2"
          id="edit-diversity-scroll-container"
        >
          {Edit}
        </div>
      </PopupModal>
      <div
        className={classNames(
          "inline-flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-4",
          noBorder && "!border-none !p-0",
        )}
      >
        <Checkbox
          id={`input-check-consent`}
          className=""
          label={""}
          size="md"
          inputClass="!border-gray-700"
          labelClass="!text-xs font-poppins !text-gray-700"
          checked={value}
          onChange={() => {
            onChange?.(!value);
          }}
        />
        <NpoTitle title={title} recomendation />
        {Edit && (
          <EditButton
            onClick={() => {
              onEdit?.();
              setShowInfoModal((prev) => !prev);
            }}
          />
        )}
        {children && (
          <PlainButton
            className="!m-0 !p-1 !text-sm"
            id="collapse"
            label={""}
            handleOnClick={() => setHide((prev) => !prev)}
            rightIconClass={hide ? "gi-md gi-angle-up" : "gi-md gi-angle-down"}
          />
        )}
      </div>
      {!hide && children && children}
    </>
  );
}
