import Fuse from "fuse.js";
import { getStringCombinations, stopWord } from "../utils/stopword";
import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
interface FuzzyMatchNameProps {
  name: string;
  fuzzy?: Fuse<string>;
  items?: string[];
}
export default function FuzzyMatchName({ name, fuzzy, items }: FuzzyMatchNameProps) {
  const [match, setMatch] = useState<string[]>([]);
  useEffect(() => {
    if (!fuzzy) {
      setMatch([]);
      return;
    }
    const nameCombination = getStringCombinations(name);
    const threshold = 0.2;
    const bestMatch = nameCombination
      .reduce(
        (prev, cur) => [
          ...prev,
          ...(fuzzy.search(cur) as { item: string; score: number; refIndex: number }[])
            .filter((i) => i.score < threshold)
            .map((i) => ({ ...i, match: cur })),
        ],
        [] as { item: string; score: number; refIndex: number; match: string }[],
      )
      .sort((a, b) => b.match.length - a.match.length)
      .reduce(
        (prev, cur) =>
          prev.length > 0 && prev[prev.length - 1].match === cur.match ? prev : [...prev, cur],
        [] as { item: string; score: number; refIndex: number; match: string }[],
      )
      .filter((i) => i.match.length > 1 && !stopWord.includes(i.match.toLowerCase()));
    setMatch([
      ...(items?.filter((i) => i.length > 1 && !stopWord.includes(i.toLowerCase())) || []),
      ...bestMatch.map((i) => i.match).filter((m) => !items || !items.includes(m)),
    ]);
  }, [name, fuzzy]);
  return fuzzy ? (
    <Highlighter
      highlightClassName="bg-purple-100"
      searchWords={match}
      autoEscape={true}
      textToHighlight={name}
    />
  ) : (
    <span>{name}</span>
  );
}
