import { useCallback } from "react";
import { IProjectTask } from "../../types/project";
import useSWR, { SWRConfiguration, useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
interface ProjectTasksState {
  data: IProjectTask[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IProjectTask[] | undefined>;
}
const emptyList: IProjectTask[] = [];
function useTasks(project_id?: string): ProjectTasksState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/tasks?project_id=${project_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectTask[]>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => res.data as IProjectTask[])
      .then((res) =>
        res.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()),
      );
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectTask[]>(
    project_id ? url : null,
    fetch,
    { keepPreviousData: false },
  );
  const revalidate = useCallback(() => mutate<IProjectTask[]>(url), [url]);
  return {
    data: data || emptyList,
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useTasks;
