import {
  AnyAction,
  ThunkAction,
  createSlice,
  Dispatch,
  PayloadAction,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";
import { debounce } from "lodash";
import config from "../api";
import { RootState } from "./store";
import {
  AdvancedFilter,
  ISearchHistory,
  SearchQuery,
  getURLSearchParamsFromSearchQuery,
  getValidAdvancedFilter,
  isearch_param_advanced_filter_array_cols,
  isearch_param_ilocation_array_cols,
  isearch_param_number_cols,
  isearch_param_string_array_cols,
} from "../types/search";
import { ILocation, reprLocation } from "../types/location";

interface SearchHistoryState {
  isFetched: boolean;
  history: ISearchHistory[];
}
const getKey = (h: ISearchHistory) => `${h.search_type}_${h.text_query}`;
const initialState: SearchHistoryState = {
  isFetched: false,
  history: [],
};
const searchHistorySlice = createSlice({
  name: "searchHistory",
  initialState,
  reducers: {
    update: (
      state,
      action: PayloadAction<{ query: SearchQuery; search_type: number; updated?: boolean }>,
    ) => {
      const newHistory = {
        text_query: action.payload.query.text_query || "",
        search_type: action.payload.search_type,
        updated_at: new Date().toISOString(),
        obj: action.payload.query,
        updated: action.payload.updated ? true : false,
      } as ISearchHistory;
      state.history = [
        { ...newHistory, key: getKey(newHistory) },
        ...(state.history?.filter((p) => p.key !== getKey(newHistory)) || []),
      ];
    },
    fetch: (state, action: PayloadAction<ISearchHistory[]>) => {
      state.isFetched = true;
      state.history = action.payload.map((h) => ({ ...h, key: getKey(h), updated: true }));
    },
    clear: (state) => {
      state.isFetched = false;
      state.history = [];
    },
  },
});

const fetchSearchHistoryAsync = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    if (!state.user?.user?._id || !state.onboarding?.data?._id) return;
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/v2/search/history?user_id=${state.user.user._id}&org_id=${state.onboarding.data._id}`,
        config,
      )
      .then((response) => response.data)
      .then((data: ISearchHistory[]) => {
        if (data) dispatch(searchHistorySlice.actions.fetch(data));
      });
  };
};

const updateSearchHistory = (
  user_id: string | undefined,
  org_id: string | undefined,
  query: SearchQuery,
  search_type: number,
) => {
  if (org_id === undefined || user_id === undefined) return;
  axios
    .post(
      process.env.REACT_APP_API_URL +
        `/api/v2/search/history?user_id=${user_id}&org_id=${org_id}&search_type=${search_type}`,
      query,
      config,
    )
    .then((response) => {
      // console.log("updateOnboarding", data, response);
    })
    .catch((e) => {
      // console.log(e);
    });
};

// debounced function should update all data at the moment requesting to the server
// because it kills the previous call
const debouncedUpdateSearchHistory = debounce(
  (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const org_id = state.onboarding?.data?._id;
    const user_id = state.user?.user?._id;
    if (!org_id || !user_id) return;
    const subs = state.searchHistory.history.filter(
      (t: ISearchHistory) => !t.updated,
    ) as ISearchHistory[];
    const promises = subs.map((h: ISearchHistory) =>
      axios.post(
        process.env.REACT_APP_API_URL +
          `/api/v2/search/history?user_id=${user_id}&org_id=${org_id}&search_type=${h.search_type}`,
        h.obj,
        config,
      ),
    );
    Promise.allSettled(promises).then((res) => {
      res.map((r, i) => {
        if (r.status === "fulfilled") {
          dispatch(
            searchHistorySlice.actions.update({
              query: r.value.data.obj,
              search_type: r.value.data.search_type,
              updated: true,
            }),
          );
        }
      });
    });
  },
  1000,
  { maxWait: 3000 },
);
const search_query_simpleCols = [
  "any_location",
  "text_query",
  "mission",
  "sortby",
  "grant_deadline",
  ...isearch_param_number_cols.filter((v) => !["npo_uid", "donor_uid"].includes(v)),
];
const search_query_arrayCols = [
  ...isearch_param_string_array_cols.filter((v) => !["type", "stars", "excludes"].includes(v)),
  ...[
    "must_focus_area",
    "must_beneficiary",
    "must_program",
    "exclude_focus_area",
    "exclude_beneficiary",
    "exclude_program",
  ],
];
const search_query_locationArrayCols = [
  ...isearch_param_ilocation_array_cols,
  ...["must_service_loc", "must_hq_loc", "exclude_service_loc", "exclude_hq_loc"],
];
const search_query_advacedFilterArrayCols = isearch_param_advanced_filter_array_cols;
const searchQueryToString = (query: SearchQuery, search_type: number): string =>
  `${search_type}_${search_query_simpleCols.map(
    (t) => `${t}=${query[t as keyof typeof query] || ""}`,
  )}_${search_query_arrayCols.map((t) => {
    const _vals = query[t as keyof typeof query];
    const vals = _vals ? [...(_vals as string[])] : [];
    return `${t}=${vals.sort((a, b) => a.localeCompare(b)).join("|")}`;
  })}_${search_query_locationArrayCols.map((t) => {
    const _vals = query[t as keyof typeof query];
    const vals = _vals ? [...(_vals as ILocation[])] : [];
    return `${t}=${vals
      .map((t) => reprLocation(t))
      .sort((a, b) => a.localeCompare(b))
      .join("|")}`;
  })}_${search_query_advacedFilterArrayCols.map((t) => {
    const _vals = query[t as keyof typeof query];
    const vals = _vals
      ? [...(_vals as AdvancedFilter[])].filter((f) => getValidAdvancedFilter(f))
      : [];
    return `${t}=${vals
      .map((t) => `${t.type}_${t.field_name}_${t.operator}_${t.value}`)
      .sort((a, b) => a.localeCompare(b))
      .join("|")}`;
  })}`;
const updateSearchHistoryAsync = (
  query: SearchQuery,
  search_type: number,
  option: { updateImmediately?: boolean } = {},
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const onboardingData = state.onboarding?.data;
    const history = state.searchHistory?.history;
    if (!onboardingData || !history) return;
    const newHistory = searchQueryToString(query, search_type).toString();
    const found = history
      .slice(0, 1)
      .find(
        (t: ISearchHistory) =>
          t.search_type === search_type &&
          searchQueryToString(t.obj, t.search_type).toString() === newHistory,
      );
    // console.log({
    //   found,
    //   1: newHistory,
    //   2: searchQueryToString(history[0].obj, history[0].search_type).toString(),
    // });
    if (found) return;
    dispatch(
      searchHistorySlice.actions.update({
        query,
        search_type,
        updated: option.updateImmediately ? true : false,
      }),
    ); // update locally first and debounce request
    if (option.updateImmediately) {
      updateSearchHistory(state.user.user?._id, state.onboarding?.data?._id, query, search_type);
    } else {
      debouncedUpdateSearchHistory(dispatch, getState);
    }
  };
};

const selectSelf = (state: RootState) => state;
const isSearchHistoryFetched = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.searchHistory.isFetched,
);
const selectSearchHistoryState = createDraftSafeSelector(
  selectSelf,
  (state: RootState): SearchHistoryState => state.searchHistory,
);
const selectSearchHistory = createDraftSafeSelector(
  (state: RootState) => state,
  (state: RootState): ISearchHistory[] => state.searchHistory.history,
);

export {
  fetchSearchHistoryAsync,
  updateSearchHistoryAsync,
  isSearchHistoryFetched,
  selectSearchHistory,
  selectSearchHistoryState,
};
export default searchHistorySlice;
