import Table from "rc-table";
import classNames from "classnames";
import { IProject, IProjectSubtask, IProjectTask } from "../types/project";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import useSubtask, { createProjectSubtask } from "../hooks/project/useSubtask";
import useSubtaskNote from "../hooks/project/useSubtaskNote";
import UserProfile from "./UserProfile";
import useTasks from "../hooks/project/useTasks";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import DropdownSelector from "./dropdown/DropdownSelector";
import useTask from "../hooks/project/useTask";
import useFunderOrGrantName from "../hooks/project/useFunderOrGrantName";
import useProjects from "../hooks/project/useProjects";
import TaskCreationModal, { SUBTASK_STATUS, SUBTASK_STATUS_COLOR } from "./TaskCreationModal";
import { DatePicker, Placeholder, Popover, Whisper } from "rsuite";
import GibooToast from "./GibooToast";
import OrgMemberSuggestion from "./OrgMemberSuggestion";
import LeftInnerIconInput from "./LeftInnerIconInput";
import UserList from "./UserList";
import TextInput from "./tailwind/TextInput";
import ProjectSubtaskNotesPopup from "./ProjectSubtaskNotesPopup";
import { convertToReadable } from "../utils/formatHelper";
import { TooltipWrapper } from "./TooltipWrapper";
import Checkbox from "./checkbox/Checkbox";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useProject from "../hooks/project/useProject";
import IMG_TRASH from "../assets/project-mgt/trash.svg";
import useOrgDataForNPODashboard from "../hooks/useOrgDataForNPODashboard";
import ProjectInformationModal, {
  ProjectInfoType,
  getProjectInfoTypeFrom,
} from "./ProjectInformationModal";
import AlertModal from "./tailwind/AlertModal";
import useWritePermission from "../hooks/useWritePermission";
import useOnboardingData from "../hooks/useOnboarding";
import { TASK_STATUS } from "../pages/npo/projects/MyTasks";
import SingleSelector from "./selector/SingleSelector";
import { IOption } from "./tailwind/AsyncCreatableSelector";
import ProjectInvite from "./ProjectInvite";
import GotoSmartSearchContext from "./useGotoSmartSearch";
import Spinner from "./Spinner";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useOrgID from "../hooks/useOrgID";
import { scrollBarXYClass } from "../utils/classes";

interface ISubtaskTable {
  data: IProjectSubtask[];
  revalidate_my_subtask_list?: () => Promise<IProjectSubtask[] | undefined>;
  autoWidth?: boolean;
  autoHeight?: boolean;
  hideFooter?: boolean;
  hideColumns?: string[];
  projectId?: string;
  hasEditPermission?: boolean;
  defaultStatus?: TASK_STATUS;
  detailView?: boolean;
  wrapperClass?: string;
}
export default function SubtaskTable({
  revalidate_my_subtask_list,
  autoWidth = true,
  autoHeight = false,
  hideFooter = false,
  hideColumns,
  data,
  projectId,
  hasEditPermission = false,
  defaultStatus = TASK_STATUS.NOT_STARTED,
  detailView = false,
  wrapperClass,
}: ISubtaskTable) {
  const [onboardingData] = useOnboardingData();
  const [isLoading, setIsLoading] = useState(false);
  const [showTaskCreation, setShowTaskCreation] = useState<boolean>(false);
  const [showProjectEdit, setShowProjectEdit] = useState<boolean>(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const [selectedTaskType, setSelectedTaskType] = useState<string | undefined>();

  // NOT STARTED
  const toggleNotstarted =
    sessionStorage.getItem("PROJECT_MANAGEMENT_TOGGLE_NOT_STARTED") === "close" ? false : true;
  const updateNotstartedToggle = useCallback((flag: boolean) => {
    sessionStorage.setItem("PROJECT_MANAGEMENT_TOGGLE_NOT_STARTED", flag ? "open" : "close");
  }, []);
  const [openNotstarted, setOpenNotstarted] = useState<boolean>(toggleNotstarted);
  // IN PROGRESS
  const toggleInprogress =
    sessionStorage.getItem("PROJECT_MANAGEMENT_TOGGLE_IN_PROGRESS") === "close" ? false : true;
  const updateInprogressToggle = useCallback((flag: boolean) => {
    sessionStorage.setItem("PROJECT_MANAGEMENT_TOGGLE_IN_PROGRESS", flag ? "open" : "close");
  }, []);
  const [openInprogress, setOpenInprogress] = useState<boolean>(toggleInprogress);

  // DONE
  const toggleDone =
    sessionStorage.getItem("PROJECT_MANAGEMENT_TOGGLE_DONE") === "close" ? false : true;
  const updateDoneToggle = useCallback((flag: boolean) => {
    sessionStorage.setItem("PROJECT_MANAGEMENT_TOGGLE_DONE", flag ? "open" : "close");
  }, []);
  const [openDone, setOpenDone] = useState<boolean>(toggleDone);

  const handleOpenAndCloseProjectUpdate = useCallback(
    (open: boolean, project_id?: string, type?: number) => {
      if (open) {
        setSelectedProjectId(project_id);
        setShowProjectEdit(true);
        setSelectedTaskType(type ? getProjectInfoTypeFrom(type) : undefined);
      } else {
        setSelectedProjectId(undefined);
        setShowProjectEdit(false);
        setSelectedTaskType(undefined);
      }
    },
    [setSelectedProjectId, setShowProjectEdit, setSelectedTaskType],
  );
  const columns = [
    {
      title: "Task name",
      dataIndex: "_id",
      key: "_id",
      width: 435,
      render: (id: any, rowData: any) => {
        return (
          <SubtaskName
            subtask_id={rowData._id}
            revalidate={() => revalidate_my_subtask_list?.()}
            handleOpenAndCloseProjectUpdate={handleOpenAndCloseProjectUpdate}
          />
        );
      },
    },
    {
      title: "Owner",
      dataIndex: "_id",
      key: "_id",
      width: 150,
      render: (id: any, rowData: any) => {
        return <TaskOwners wrapperClass="whitespace-nowrap" subtask_id={rowData._id} />;
      },
    },
    {
      title: "Due date",
      dataIndex: "_id",
      key: "_id",
      width: 150,
      render: (id: any, rowData: any) => {
        return <SubtaskDuedate className="text-xs" subtask_id={rowData._id} />;
      },
    },
    {
      title: "Status",
      dataIndex: "_id",
      key: "_id",
      width: 150,
      render: (id: any, rowData: any) => {
        return (
          <SubtaskStatus
            wrapperClass="pl-2"
            className="text-xs"
            subtask_id={rowData._id}
            revalidate={() => revalidate_my_subtask_list?.()}
          />
        );
      },
    },
    {
      title: "Project",
      dataIndex: "_id",
      key: "_id",
      width: 323,
      render: (id: any, rowData: any) => {
        return <ProjectSelectorForSubtask subtask_id={rowData._id} maxWidth="290px" />;
      },
    },
    {
      title: "Task under",
      dataIndex: "_id",
      key: "_id",
      width: 323,
      render: (id: any, rowData: any) => {
        return rowData.type > 0 ? <></> : <TaskSelectorForSubtask subtask_id={rowData._id} />;
      },
    },
    // {
    //   title: "Latest note",
    //   dataIndex: "_id",
    //   key: "_id",
    //   width: 295,
    //   render: (id: any, rowData: any) => {
    //     return (
    //       <LatestSubtaskNoteMemo
    //         wrapperClass="pl-2"
    //         className="text-xs"
    //         subtask_id={rowData._id}
    //         revalidate={() => revalidate_my_subtask_list?.()}
    //       />
    //     );
    //   },
    // },
    // {
    //   title: "Delete",
    //   dataIndex: "",
    //   key: "_id",
    //   width: 50,
    //   render: (rowData: any) => (
    //     <SubTaskDelete subtask_id={rowData._id} revalidate={() => revalidate_my_subtask_list?.()} />
    //   ),
    // },
  ];
  const handleTaskCreation = () => {
    createProjectSubtask({
      name: "untitled",
      org_id: onboardingData._id,
      status: TASK_STATUS.NOT_STARTED,
      task_id: undefined,
      project_id: projectId ? projectId : undefined,
      content: "",
    })
      .then(() => {
        revalidate_my_subtask_list?.();
      })
      .catch((error) => {
        GibooToast({
          type: "failure",
          message: `We apologize for the inconvenience. Please try again.`,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const renderTable = (data: any[], defaultStatus: TASK_STATUS) => {
    return (
      <div className={classNames("relative", wrapperClass)}>
        <Table
          {...(hasEditPermission && {
            footer: () => (
              <AddMoreSubTask
                project_id={projectId}
                defaultStatus={defaultStatus}
                revalidate={() => {
                  revalidate_my_subtask_list?.();
                }}
              />
            ),
          })}
          columns={
            hideColumns && hideColumns?.length > 0
              ? columns.filter((i) => !hideColumns?.includes(i.title))
              : columns
          }
          data={data}
          tableLayout="fixed"
          rowKey={"_id"}
          emptyText={""}
          className={classNames("w-full", scrollBarXYClass, "!pr-0")}
          rowClassName={"!h-[36px]"}
          style={{
            maxHeight: "calc(100vh - 365px)",
            width: "100%",
            overflow: "scroll",
          }}
          scroll={{ x: "100%" }}
        />
      </div>
    );
  };
  return (
    <>
      {showTaskCreation && (
        <TaskCreationModal
          open={showTaskCreation}
          setOpen={setShowTaskCreation}
          onTaskCreation={() => {
            revalidate_my_subtask_list?.();
            setShowTaskCreation(false);
          }}
        />
      )}
      {showProjectEdit && selectedProjectId && (
        <ProjectInformationModal
          from_for_mixpanel="tasks"
          open={showProjectEdit}
          setOpen={setShowProjectEdit}
          id={selectedProjectId}
          currentType={selectedTaskType as ProjectInfoType}
          isEdit
        />
      )}
      {detailView ? (
        <div
          className={classNames(
            "relative flex h-full w-full flex-col bg-white p-5",
            isLoading && "gap-10",
          )}
          style={{ minHeight: "calc(100vh - 162px)" }}
        >
          {isLoading ? (
            Array(3)
              .fill("#")
              .map((_, idx) => (
                <Placeholder.Graph key={idx} active height={220} className="rounded" />
              ))
          ) : (
            <div className="flex flex-col gap-[50px]">
              <div className="flex flex-col gap-5">
                <div
                  className="inline-flex cursor-pointer items-center gap-2"
                  onClick={() => {
                    updateNotstartedToggle(!openNotstarted);
                    setOpenNotstarted((prev) => !prev);
                  }}
                >
                  <span className="grid place-items-center">
                    <i className={classNames(openNotstarted ? " gi-angle-up" : " gi-angle-down")} />
                  </span>
                  <h6 className="font-poppins text-base font-semibold">Not started</h6>
                </div>
                {openNotstarted &&
                  renderTable(
                    data.filter((i) => i.status === TASK_STATUS.NOT_STARTED),
                    TASK_STATUS.NOT_STARTED,
                  )}
              </div>
              <div className="flex flex-col gap-5">
                <div
                  className="inline-flex cursor-pointer items-center gap-2"
                  onClick={() => {
                    updateInprogressToggle(!openInprogress);
                    setOpenInprogress((prev) => !prev);
                  }}
                >
                  <span className="grid place-items-center">
                    <i className={classNames(openInprogress ? " gi-angle-up" : " gi-angle-down")} />
                  </span>
                  <h6 className="font-poppins text-base font-semibold">In progress</h6>
                </div>
                {openInprogress &&
                  renderTable(
                    data.filter((i) => i.status === TASK_STATUS.IN_PROGRESS),
                    TASK_STATUS.IN_PROGRESS,
                  )}
              </div>
              <div className="flex flex-col gap-5">
                <div
                  className="inline-flex cursor-pointer items-center gap-2"
                  onClick={() => {
                    updateDoneToggle(!openDone);
                    setOpenDone((prev) => !prev);
                  }}
                >
                  <span className="grid place-items-center">
                    <i className={classNames(openDone ? " gi-angle-up" : " gi-angle-down")} />
                  </span>
                  <h6 className="font-poppins text-base font-semibold">Done</h6>
                </div>
                {openDone &&
                  renderTable(
                    data.filter((i) => i.status === TASK_STATUS.DONE),
                    TASK_STATUS.DONE,
                  )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={classNames("relative", wrapperClass)}>
          <Table
            {...(hasEditPermission &&
              !hideFooter && {
                footer: () => (
                  <AddMoreSubTask
                    project_id={projectId}
                    defaultStatus={defaultStatus}
                    revalidate={() => {
                      revalidate_my_subtask_list?.();
                    }}
                  />
                ),
              })}
            columns={
              hideColumns && hideColumns?.length > 0
                ? columns.filter((i) => !hideColumns?.includes(i.title))
                : columns
            }
            data={data}
            tableLayout="fixed"
            rowKey={"_id"}
            emptyText={<></>}
            rowClassName={"!h-[36px]"}
            className={classNames("w-full", scrollBarXYClass, "!pr-0")}
            style={{
              maxHeight: "calc(100vh - 365px)",
              width: "100%",
              overflow: "scroll",
            }}
            scroll={{ x: "100%" }}
          />
        </div>
      )}
    </>
  );
}

function SubtaskName({
  subtask_id,
  wrapperClass,
  className,
  revalidate,
  handleOpenAndCloseProjectUpdate,
}: {
  subtask_id: string | undefined;
  wrapperClass?: string;
  className?: string;
  revalidate: () => void;
  handleOpenAndCloseProjectUpdate: (open: boolean, project_id?: string, type?: number) => void;
}) {
  const navigate = useNavigate();
  const {
    data,
    isLoading,
    update,
    revalidate: revalidateSubTask,
    deleteThis,
  } = useSubtask(subtask_id);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const hasEditPermission = true;
  const [showAllNotes, setShowAllNotes] = useState(false);
  const [selected, setSelected] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) return;
    setValue(data.name);
  }, [data, isLoading]);
  const handleDelete = () => {
    setIsDeleting(true);
    deleteThis()
      .then(() => {
        revalidate();
        // GibooToast({
        //   type: "success",
        //   message: `deleted successfully.`,
        // });
      })
      .catch(() => {
        GibooToast({
          type: "failure",
          message: `Something went wrong. Please try again.`,
        });
      })
      .finally(() => {
        setIsDeleting(false);
        setSelected(false);
      });
  };
  return (
    <>
      {showAllNotes && (
        <ProjectSubtaskNotesPopup
          project_id={data.project_id}
          subtask_id={subtask_id}
          onClose={() => {
            setShowAllNotes(false);
            revalidate();
            revalidateSubTask();
          }}
          show={showAllNotes}
          title={data.name}
        />
      )}
      <div
        className={classNames(
          "group/card loading-light-shimmer relative pr-3",
          isLoading ? "loading-light-shimmer-on" : "",
          wrapperClass,
        )}
      >
        <div
          className={classNames(
            "grid w-full grid-cols-[20px_1fr_auto]  items-center px-1",
            "loading-light-shimmer-child  rounded",
            className,
          )}
          onClick={() => hasEditPermission && setShow(true)}
          onMouseLeave={() => {
            setShow(false);
            if (data.name !== value) {
              update({ name: value });
            } else {
              setValue(data.name);
            }
          }}
        >
          <div
            className={classNames(
              "relative group-hover/card:visible",
              selected ? "visible" : "invisible",
            )}
          >
            <Whisper
              trigger={"click"}
              onClose={() => {
                setSelected(false);
              }}
              placement={"bottomStart"}
              controlId="control-id-hover-enterable"
              className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)]"
              speaker={
                <Popover
                  prefix="custom-tooltip"
                  className={classNames("grid place-items-center !p-0")}
                  arrow={false}
                >
                  <div
                    className="inline-flex h-[35px] w-full cursor-pointer items-center gap-2 p-[10px] hover:bg-gray-100"
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    <span className="h-5 w-5">
                      {isDeleting ? (
                        <Spinner size="sm" span />
                      ) : (
                        <img src={IMG_TRASH} alt="trash" className="h-5 w-5" />
                      )}
                    </span>
                    <p className="font-poppins text-xs text-gray-700">Remove task</p>
                  </div>
                </Popover>
              }
            >
              <div>
                <Checkbox
                  id={`row-check-box`}
                  size="xs"
                  checked={selected}
                  onChange={() => {
                    setSelected((prev) => !prev);
                  }}
                />
              </div>
            </Whisper>
          </div>
          {!show ? (
            <div className="grid w-full rounded border-0 border-purple-100 group-hover/card:border">
              <h5
                className={classNames(
                  "!h-[25px] min-w-full truncate  border-0 px-2 py-[2px] font-poppins !text-sm !text-black",
                )}
              >
                {value}
              </h5>
            </div>
          ) : (
            <TextInput
              id="input-number-1"
              className={classNames("!h-[25px] w-full  px-3  py-[2px] !text-sm")}
              value={value === "Untitled" ? "" : value}
              placeholder={value === "Untitled" ? "Untitled" : ""}
              autoFocus
              handleOnChange={(e: any) => {
                setValue(e.target.value);
              }}
              handleOnKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (data.name !== value) {
                    update({ name: value });
                  }
                  setShow(false);
                }
              }}
            />
          )}
          <div className="inline-flex items-center gap-1">
            {/* {data?.type && data?.type !== 1 && (
              <TooltipWrapper content="Update information">
                <span
                  className={classNames("grid h-[24px] place-items-center")}
                  onClick={() => {
                    handleOpenAndCloseProjectUpdate(true, data.project_id, data.type);
                  }}
                >
                  <span className=" grid place-items-center rounded-3xl bg-[#E2F2FF] px-[7px] py-[4px] text-gray-700">
                    <i className="gi-wand gi-sm text-[#2C7CC4]" />
                  </span>
                </span>
              </TooltipWrapper>
            )} */}
            {data?.project_id && data?.document_id && (
              <TooltipWrapper content="View document">
                <span
                  className={classNames("grid h-[24px] place-items-center")}
                  onClick={() => {
                    navigate(`/project/${data.project_id}/document/${data.document_id}`);
                  }}
                >
                  <span className=" grid place-items-center rounded-3xl border border-gray-300 bg-white px-[7px] py-[4px] text-gray-700">
                    <i className="gi-view-document gi-sm" />
                  </span>
                </span>
              </TooltipWrapper>
            )}
            <TooltipWrapper content="View all notes">
              <span
                className={classNames("grid h-[24px] place-items-center")}
                onClick={() => {
                  setShowAllNotes(true);
                }}
              >
                <span className=" grid place-items-center rounded-3xl border border-gray-300 bg-white px-[7px] py-[4px] text-gray-700">
                  <i className="gi-pencil-edit gi-sm" />
                </span>
              </span>
            </TooltipWrapper>
          </div>
        </div>
      </div>
    </>
  );
}

export function TaskOwners({
  subtask_id,
  wrapperClass,
  className,
}: {
  subtask_id: string | undefined;
  wrapperClass?: string;
  className?: string;
}) {
  const { data, isLoading } = useSubtask(subtask_id);
  const whisperRef = useRef<any>(null);
  const [allowRootClose, setRootClose] = useState<boolean>(true);
  return (
    <Whisper
      ref={whisperRef}
      placement="bottomStart"
      controlId="control-id-hover-enterable"
      className=" shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)]"
      trigger={"click"}
      rootClose={allowRootClose}
      speaker={
        <Popover className="!px-0" arrow={false}>
          <InviteUser subtask_id={subtask_id} setRootClose={setRootClose} />
        </Popover>
      }
    >
      <div
        className={classNames(
          "loading-light-shimmer mt-1",
          isLoading ? "loading-light-shimmer-on" : "",
          wrapperClass,
        )}
      >
        <div
          className={classNames(
            "loading-light-shimmer-child group flex min-w-[50px]  items-center justify-center  gap-0  group-hover:justify-center",
            className,
          )}
        >
          {data.users && data.users.length > 0 ? (
            <UserList users={data.users} maxUsers={1} size={16} nameClass="max-w-[80px]" />
          ) : (
            "No owner"
          )}
          {data.users && data.users.length > 0 && (
            <span className={classNames("hidden h-5 w-5 place-items-center group-hover:grid")}>
              <i className={"gi-x gi-sm text-purple-500"} />
            </span>
          )}
        </div>
      </div>
    </Whisper>
  );
}
export function InviteUser({
  subtask_id,
  setRootClose,
}: {
  subtask_id: string | undefined;
  setRootClose: Dispatch<SetStateAction<boolean>>;
}) {
  const { data: task, isLoading: taskLoading, update, revalidate } = useSubtask(subtask_id);
  const [accessList, setAccessList] = useState<{ user_id: string; role: number }[]>([]);
  const [showInvite, setShowInvite] = useState(false);
  const hasEditPermission = true;
  const [query, setQuery] = useState<string>("");
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  useEffect(() => {
    if (taskLoading) return;
    setAccessList(task.users.map((item) => ({ user_id: item, role: 1 })));
  }, [taskLoading, task]);

  return (
    <>
      {showInvite && (
        <ProjectInvite
          from_for_mixpanel="owner_in_project_task_table"
          projectId={task.project_id || ""}
          handleClose={() => {
            setShowInvite(false);
            setRootClose(true);
          }}
          showInvite={showInvite}
        />
      )}
      <div className="flex h-full min-h-[144px] w-[379px] flex-col px-4">
        <LeftInnerIconInput
          className="placholder:!text-gray-500 !h-[29px] w-full"
          type="text"
          placeholder="Search name"
          iconClass="gi-sm gi-search text-gray-500 !top-[3px]"
          id="input-email"
          value={query}
          handleOnFocus={() => setShowSuggestion(true)}
          handleOnChange={(e) => {
            setQuery(e.target.value);
            setShowSuggestion(true);
          }}
          offAutoComplete
        />
        <OrgMemberSuggestion
          show={showSuggestion}
          setShow={setShowSuggestion}
          query={query}
          exclude={accessList.map((u) => u.user_id)}
          onSelect={(user) => {
            setQuery("");
            setShowSuggestion(false);
            const userExist = accessList.find((i) => i.user_id === user._id);
            if (!userExist && user._id)
              update({ users: [...task.users, user._id] })
                .then(() => {
                  revalidate();
                  // GibooToast({
                  //   type: "success",
                  //   message: `User added successfully.`,
                  // });
                })
                .catch(() => {
                  GibooToast({
                    type: "failure",
                    message: `We apologize for the inconvenience. Please try again.`,
                  });
                })
                .finally(() => {});
          }}
        />
        {/* <h6 className=" mt-3 text-xs text-gray-500">Added:</h6> */}
        <div className="my-[14px] inline-flex flex-wrap gap-3">
          {accessList.length > 0
            ? accessList.map((item) => {
                return (
                  <div
                    key={item.user_id}
                    className="inline-flex h-[32px] w-fit  items-center gap-2 rounded border border-gray-300 px-2"
                  >
                    <UserProfile showName userId={item.user_id} />
                    <span
                      className="mt-1 cursor-pointer"
                      onClick={() => {
                        update({ users: task.users.filter((i) => i !== item.user_id) })
                          .then(() => {
                            revalidate();
                            // GibooToast({
                            //   type: "success",
                            //   message: `User removed successfully.`,
                            // });
                          })
                          .catch(() => {
                            GibooToast({
                              type: "failure",
                              message: `We apologize for the inconvenience. Please try again.`,
                            });
                          })
                          .finally(() => {});
                      }}
                    >
                      <i className="gi-x  text-sm text-gray-500" />
                    </span>
                  </div>
                );
              })
            : "None"}
        </div>
        <h6 className="text-xs text-gray-500">Suggested: None</h6>
        <div className="flex justify-end self-end">
          <div
            id="invite-people"
            className={classNames(
              hasEditPermission ? "cursor-pointer bg-white hover:shadow" : "bg-gray-100",
              "flex h-[30px] w-fit items-center gap-2 rounded border border-gray-300 px-3 py-1 font-poppins",
            )}
            onClick={() => {
              if (!hasEditPermission) return;
              setShowInvite(true);
              setRootClose(false);
            }}
          >
            <i className="gi-funder gi-md text-gray-700" />
            <span className="text-black">Invite</span>
          </div>
        </div>
      </div>
    </>
  );
}
function SubtaskStatus({
  subtask_id,
  wrapperClass,
  className,
  revalidate,
}: {
  subtask_id: string | undefined;
  wrapperClass?: string;
  className?: string;
  revalidate: () => void;
}) {
  const enumKeys = Object.keys(SUBTASK_STATUS) as Array<keyof typeof SUBTASK_STATUS>;
  const whisperRef = useRef<any>(null);

  const [value, setValue] = useState("Not started");
  const enumKeyValuePairs = enumKeys.map((enumKey) => ({
    key: enumKey,
    value: SUBTASK_STATUS[enumKey],
  }));
  const { data, isLoading, update } = useSubtask(subtask_id);
  useEffect(() => {
    if (isLoading) return;
    setValue(data.status);
  }, [data, isLoading]);
  return (
    <div
      className={classNames(
        "loading-light-shimmer pl-4",
        isLoading ? "loading-light-shimmer-on" : "",
        wrapperClass,
      )}
    >
      <div className={classNames("loading-light-shimmer-child min-w-[30px]", className)}>
        <Whisper
          ref={whisperRef}
          placement="bottomStart"
          controlId="control-id-hover-enterable"
          className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)] "
          trigger={"click"}
          speaker={
            <Popover
              onMouseLeave={() => whisperRef?.current?.close()}
              className="z-[9999] !mt-0 !px-0"
              arrow={false}
            >
              <ul className="h-auto min-w-[162px] flex-col gap-4">
                {enumKeyValuePairs.map((enumPair) => {
                  return (
                    <li
                      key={enumPair.key}
                      className="w-full cursor-pointer px-3 py-1 hover:bg-gray-100 hover:shadow-sm"
                      onClick={() => {
                        whisperRef?.current?.close();
                        update({ status: enumPair.value }).then(() => {
                          revalidate();
                        });
                      }}
                    >
                      <span
                        className="grid h-[25px] w-fit  place-items-center rounded px-[10px] font-poppins text-sm text-gray-900 last:mb-0"
                        style={{ background: SUBTASK_STATUS_COLOR[enumPair.key] }}
                      >
                        {enumPair.value}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </Popover>
          }
        >
          <div
            className={classNames(
              "grid h-[25px] w-fit place-items-center rounded px-[10px] text-sm text-gray-900 hover:opacity-80",
            )}
            style={{
              background:
                SUBTASK_STATUS_COLOR[
                  enumKeyValuePairs.find((i) => i.value === value)?.key || "DONE"
                ],
            }}
          >
            {value}
          </div>
        </Whisper>
      </div>
    </div>
  );
}

function SubtaskDuedate({
  subtask_id,
  wrapperClass,
  className,
}: {
  subtask_id: string | undefined;
  wrapperClass?: string;
  className?: string;
}) {
  const { data, isLoading, update } = useSubtask(subtask_id);
  const [value, setValue] = useState<string | undefined>(data.due_date);
  const [today, setToday] = useState(false);
  useEffect(() => {
    setValue(data.due_date);
    setToday(
      data.due_date ? new Date(data.due_date).toISOString() === new Date().toISOString() : false,
    );
  }, [data.due_date]);
  return (
    <>
      <div
        className={classNames(
          "loading-light-shimmer group w-full px-2",
          isLoading ? "loading-light-shimmer-on" : "",
        )}
      >
        <div className={classNames("loading-light-shimmer-child min-w-[50px]")}>
          <div className="border-none border-purple-400 hover:border">
            <DatePicker
              className={classNames(
                "table-cell-date-picker  !h-[30px] w-full  max-w-[130px] !text-xs ",
              )}
              value={value ? new Date(value) : undefined}
              size="sm"
              oneTap
              editable={false}
              cleanable={false}
              appearance="default"
              placeholder={today ? "Today" : "MM/DD/YYYY"}
              format={"MM/dd/yyyy"}
              onChange={(value) => {
                if (value) {
                  setValue(value.toISOString());
                  update({ due_date: value.toISOString() });
                } else {
                  setValue(undefined);
                  update({ due_date: undefined });
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
function ProjectSelectorForSubtask({
  subtask_id,
  wrapperClass,
  className,
  maxWidth = "300px",
  maxHeight = "300px",
  inputHeight = "30px",
}: {
  subtask_id: string | undefined;
  wrapperClass?: string;
  className?: string;
  maxWidth?: string;
  maxHeight?: string;
  inputHeight?: string;
}) {
  const [projects] = useProjects();
  const { data, isLoading, updateProjectId } = useSubtask(subtask_id);
  const [selected, setSelected] = useState<IOption>();
  const PROJECT_LIST = projects.data.map((i) => ({ label: i.name, value: i._id }));
  const initialValue = PROJECT_LIST.find((p) => p.value === data.project_id);
  const [showProjectCreationModal, setShowProjectCreationModal] = useState<boolean>(false);
  const org_id = useOrgID();
  return (
    <>
      {showProjectCreationModal && (
        <ProjectInformationModal
          from_for_mixpanel="tasks"
          open={showProjectCreationModal}
          setOpen={setShowProjectCreationModal}
          id=""
          withOutFunderGrant
          isNewProject
        />
      )}
      <SingleSelector<IOption>
        id={`input-project-selector`}
        value={initialValue || selected}
        inputContainerClass={
          "!h-[36px] !min-h-[36px] !max-w-[519px] !border-none !text-gray-700 !text-xs"
        }
        onChange={(v) => {
          setSelected(v ? v : undefined);
          updateProjectId(v ? v.value : undefined);
        }}
        placeholderClass="!text-gray-700 !text-xs"
        compact
        data={PROJECT_LIST}
        isClearable={false}
        dropdownIcon={true}
        isSearchable
        renderItem={(i) => <>{i.label}</>}
        renderNoOption={() => (
          <div
            onClick={() => {
              // mxEvent(MIXPANEL_EVENTS.PROJECT_CREATION.PROJECT_CREATE_CLICKED, {
              //   organizationId: org_id,
              //   location: location.pathname,
              // });
              setShowProjectCreationModal(true);
            }}
            className="cursor-pointer whitespace-nowrap py-2 hover:bg-gray-100"
          >
            + Create a project
          </div>
        )}
      />
    </>
  );
}

function TaskSelectorForSubtask({
  subtask_id,
  wrapperClass,
  className,
}: {
  subtask_id: string | undefined;
  wrapperClass?: string;
  className?: string;
}) {
  const { data, isLoading, updateTaskId } = useSubtask(subtask_id);
  const { data: tasks } = useTasks(data?.project_id);
  const [selected, setSelected] = useState<IOption>();
  const TASK_LIST = tasks.map((i) => ({ label: i._id, value: i._id }));
  const initialValue = TASK_LIST.find((p) => p.value === data.task_id);
  return (
    <SingleSelector<IOption>
      id={`input-task-selector`}
      value={initialValue || selected}
      inputContainerClass={
        "!h-[36px] !min-h-[36px] !max-w-[519px] !border-none !text-gray-700 !text-xs"
      }
      onChange={(v) => {
        setSelected(v ? v : undefined);
        updateTaskId(v ? v.value : undefined);
      }}
      placeholderClass="!text-gray-700 !text-xs"
      compact
      data={TASK_LIST}
      isClearable={false}
      dropdownIcon={true}
      isSearchable
      renderItem={(i) => <TaskName task_id={i.value || ""} className="text-xs" />}
      renderButton={(i) => <TaskName task_id={i.value || ""} className="text-xs" />}
      renderNoOption={() => (
        <GotoSmartSearchContext type="virtual_grant" project_id={data.project_id}>
          {({ go }) => (
            <div
              onClick={() => {
                go();
              }}
            >
              {isLoading ? (
                <Spinner size="sm" />
              ) : (
                <p className="cursor-pointer whitespace-nowrap py-2 text-xs hover:bg-gray-100">
                  + grant or funder to project
                </p>
              )}
            </div>
          )}
        </GotoSmartSearchContext>
      )}
    />
  );
}

function TaskName({
  task_id,
  wrapperClass,
  className,
}: {
  task_id: string | undefined;
  wrapperClass?: string;
  className?: string;
}) {
  const { data, isLoading } = useTask(task_id);
  const { data: name, isLoading: isNameLoading } = useFunderOrGrantName(
    data.target_id,
    data.target_type,
  );
  return (
    <div
      className={classNames(
        "loading-light-shimmer",
        isLoading || isNameLoading ? "loading-light-shimmer-on" : "",
        wrapperClass,
      )}
    >
      <div className={classNames("loading-light-shimmer-child min-w-[50px]", className)}>
        <div>{name}</div>
      </div>
    </div>
  );
}

function LatestSubtaskNoteMemo({
  subtask_id,
  className,
  wrapperClass,
  revalidate,
}: {
  subtask_id: string | undefined;
  className?: string;
  wrapperClass?: string;
  revalidate: () => void;
}) {
  const hasEditPermission = true;
  const { data, isLoading, revalidate: revalidateSubTask } = useSubtask(subtask_id);
  const { data: note, isLoading: isLoadingInteraction } = useSubtaskNote(data.latest_note_id);
  const [showAllNotes, setShowAllNotes] = useState(false);
  return (
    <>
      {showAllNotes && (
        <ProjectSubtaskNotesPopup
          project_id={data.project_id}
          subtask_id={subtask_id}
          onClose={() => {
            setShowAllNotes(false);
            revalidate();
            revalidateSubTask();
          }}
          show={showAllNotes}
          title={data.name}
        />
      )}
      <div
        className={classNames(
          "loading-light-shimmer",
          isLoading || isLoadingInteraction ? "loading-light-shimmer-on" : "",
          wrapperClass,
        )}
      >
        <div className={classNames("loading-light-shimmer-child min-w-[50px]", className)}>
          <div className="flex items-center justify-between">
            <div className="w-[240px] truncate  text-left">
              {data.latest_note_id ? convertToReadable(note.content) : "None"}
            </div>
            <div className="inline-flex items-center gap-1">
              <TooltipWrapper content="View all notes">
                <span
                  className={classNames("grid h-[24px] place-items-center")}
                  onClick={() => {
                    setShowAllNotes(true);
                  }}
                >
                  <span className=" grid place-items-center rounded-3xl border border-gray-300 px-[7px] py-[4px] text-gray-700">
                    <i className="gi-pencil text-sm" />
                  </span>
                </span>
              </TooltipWrapper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function SubTaskDelete({
  subtask_id,
  revalidate,
}: {
  subtask_id: string | undefined;
  revalidate: () => void;
}) {
  const { data, deleteThis } = useSubtask(subtask_id);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { hasEditPermission: projectEditPermission } = useProject(data?.project_id);
  return (
    <>
      {showAlert && (
        <AlertModal
          variant="purple"
          handleCancel={() => {
            setShowAlert(false);
          }}
          handleConfirm={() => {
            setIsLoading(true);
            deleteThis()
              .then(() => {
                revalidate();
                GibooToast({
                  type: "success",
                  message: `deleted successfully.`,
                });
              })
              .catch(() => {
                GibooToast({
                  type: "failure",
                  message: `Something went wrong. Please try again.`,
                });
              })
              .finally(() => setIsLoading(false));
          }}
          isLoading={isLoading}
          confirmTitle={"Delete"}
          title="Delete"
          content={`Are you sure you want to delete${data.name ? ` "${data.name}"` : ""}?`}
          isOpen={showAlert}
          handleClose={() => setShowAlert(false)}
        />
      )}
      {data && (data.project_id ? projectEditPermission : true) && (
        <span
          onClick={() => {
            setShowAlert(true);
          }}
          className="h-5 w-5"
        >
          <img src={IMG_TRASH} alt="trash" className="h-5 w-5" />
        </span>
      )}
    </>
  );
}
function AddMoreSubTask({
  project_id,
  revalidate,
  defaultStatus = TASK_STATUS.NOT_STARTED,
}: {
  project_id?: string;
  revalidate: () => void;
  defaultStatus?: TASK_STATUS;
}) {
  const [onboardingData] = useOnboardingData();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [isAddTaskLoading, setIsAddTaskLoading] = useState<boolean>(false);
  const createNewTask = (name?: string) => {
    if (isAddTaskLoading) return;
    setIsAddTaskLoading(true);
    createProjectSubtask({
      name: name ? name : value,
      org_id: onboardingData._id,
      status: defaultStatus,
      task_id: undefined,
      project_id: project_id ? project_id : undefined,
      content: "",
    })
      .then(() => {
        revalidate();
      })
      .catch(() => {
        GibooToast({
          type: "failure",
          message: `Something went wrong. Please try again.`,
        });
      })
      .finally(() => {
        setValue("");
        setIsAddTaskLoading(false);
      });
  };
  return (
    <div
      className={classNames(
        "flex !h-[36px] w-full cursor-pointer items-center justify-start gap-2 !py-2 pl-3 ",
        !show && "hover:bg-purple-50",
      )}
    >
      {!show ? (
        <span
          className="flex !h-[36px] w-full items-center truncate !border-0 text-gray-700"
          onClick={() => createNewTask("Untitled")}
        >
          {isAddTaskLoading && <Spinner size="sm" />}
          <i className="gi gi-add mr-1 text-sm" />
          <h5>add task</h5>
        </span>
      ) : (
        <TextInput
          id="input-add-subtask"
          className={classNames("!h-[30px] max-w-[310px] !py-1 !text-sm placeholder:!text-sm")}
          wrapperClass="!max-w-[310px]"
          autoFocus
          value={value}
          handleOnBlur={() => {
            setShow(false);
            setValue("");
          }}
          handleOnChange={(e: any) => {
            setValue(e.target.value);
          }}
          handleOnKeyDown={(e) => {
            if (e.key === "Enter") {
              if (value) {
                createNewTask();
              }
              setShow(false);
            }
          }}
        />
      )}
    </div>
  );
}
