import { SearchQuery } from "../../types/search";
import { ReactNode, memo, useCallback, useEffect, useState } from "react";
import { getDonorsPhilanthropyWords } from "../../services/donor.services";
import useSWR from "swr";
import MemoizedPhilanthropyWords from "../../pages/donor-detail-view/components/PhilanthropyWords";
import { swrOptionDedupling5mins } from "../../types/swr";
import { toUsdLong } from "../../utils/formatHelper";
interface WordCloudData {
  label: string;
  amount: number;
  count: number;
  score: number;
}
const emptyList: WordCloudData[] = [];
function funderWordCloud({
  funder_id,
  query,
  ...props
}: {
  funder_id: string;
  query?: SearchQuery;
}) {
  const fetch = async (url: string) => {
    return getDonorsPhilanthropyWords(funder_id).then((res) =>
      res.sort((a, b) => b.score - a.score).slice(0, 150),
    );
  };
  const tags: string[] = [];
  const { data, isLoading, error } = useSWR<WordCloudData[]>(
    process.env.REACT_APP_TAXONOMY_URL +
      `/algo/v1/wordcloud?funder_id=${funder_id}&${
        tags && tags.length > 0 ? "_matching_words" : ""
      }`,
    fetch,
    swrOptionDedupling5mins,
  );
  const renderTooltip = useCallback((d: WordCloudData) => {
    return (
      <>
        <h5 className="font-semibold">{d?.label || ""}</h5>
        <h5>{`Grant amount: ${toUsdLong(d?.amount || 0)}`}</h5>
        <h5>{`Grant count: ${(d?.count || 0).toLocaleString()}`}</h5>
      </>
    );
  }, []);
  return (
    <div id="chart_view" aria-valuetext="word-cloud">
      <MemoizedPhilanthropyWords<WordCloudData>
        colors={["#5c38a7", "#e18b82", "#ecd332", "#7eab67", "#8ac1de"]}
        title="Words relevant to funder"
        data={data || emptyList}
        key={"word-cloud-2"}
        loading={isLoading}
        reprTooltip={renderTooltip}
      />
    </div>
  );
}
const MemoizedFunderWordCloud = memo(funderWordCloud);
export default MemoizedFunderWordCloud;
