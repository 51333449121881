import axios from "axios";
import config from "../api";
import { User } from "../app/userSlice";

const emailVerify = (signintoken: string) => {
  return new Promise<User>((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/v2/auth/verify",
        {
          token: signintoken,
        },
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const pwdResetTokenVerify = (signintoken: string) => {
  return new Promise<User>((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/v2/auth/verify/reset_token",
        {
          token: signintoken,
        },
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
const resetPassword = (resettoken: string, passwd1: string) => {
  return new Promise<any>((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "/api/v2/auth/reset_password",
        {
          token: resettoken,
          password: passwd1,
        },
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export interface UnsubscribeStatus {
  email: string;
  unsubscribe: boolean;
  updated_at: string;
}
const getUnsubscribeStatus = (token: string) => {
  return new Promise<UnsubscribeStatus>((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/v2/users/unsubscribe/${token}`, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
const updateUnsubscribeStatus = (token: string, unsubscribe: boolean) => {
  return new Promise<UnsubscribeStatus>((resolve, reject) => {
    axios
      .put(
        process.env.REACT_APP_API_URL + `/api/v2/users/unsubscribe/${token}`,
        { unsubscribe },
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
const isEmailExist = (email: string) => {
  return new Promise<boolean>((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/v2/users/exist?email=${email}`, config)
      .then(({ data, status }) => {
        if (status === 204) resolve(false);
        else if (status == 200) resolve(true);
        else resolve(false);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const userService = {
  emailVerify,
  pwdResetTokenVerify,
  resetPassword,
  getUnsubscribeStatus,
  updateUnsubscribeStatus,
  isEmailExist,
};
