import axios from "axios";
import config from "../../api";
import useSWR from "swr";
import { swrOptionDedupling5mins } from "../../types/swr";

interface VirtualGrantsFromSameFunderState {
  data: string[] | undefined;
  isLoading: boolean;
  error: object;
}
export default function useVirtualGrantsFromSameFunder(
  funder_id?: string,
): VirtualGrantsFromSameFunderState {
  const fetchGrants = async (url: string) => {
    if (!url || !funder_id) return new Promise<string[]>((resolve) => resolve([]));
    return axios.get(url, config).then((res) => res.data);
  };
  const { data, isLoading, error } = useSWR<string[]>(
    funder_id ? process.env.REACT_APP_API_URL + `/api/v2/donors/${funder_id}/virtual_grant` : null,
    fetchGrants,
    swrOptionDedupling5mins,
  );
  return { data, isLoading, error };
}
