import useOnboardingData from "../../hooks/useOnboarding";
import classNames from "classnames";
import { TooltipWrapper } from "../TooltipWrapper";
import { GrantSearchResult, SearchQuery, getSearchResultKey } from "../../types/search";
import {
  getGrantTitle,
  isActiveGrant,
  getAgencyName,
  isGrantEligible,
  reprGrantAmount,
  reprGrantDeadline,
  ReprGrantServiceLocations,
  reprGrantEligibleNPOLocations,
  reprGrantFundingType,
  isGrantPrivate,
} from "../../utils/grant";
import TagFunderSize from "../TagFunderSize";
import PhilanthropicInformationFlexWrap from "../PhilanthropicInformationFlexWrap";
import { getFunderHqLocation, renderRow } from "../../utils/funder";
import TagGrantEligible from "../TagGrantEligible";
import TagInvitationOnly from "../TagInvitationOnly";
import FuzzyMatchName from "../FuzzyMatchName";
import Fuse from "fuse.js";
import TagGrantPresent from "../TagGrantPresent";
import { ReactElement, useCallback, useState } from "react";
import StartFundRaisingModal from "../StartFundraisingModal";
import useSave from "../../hooks/save/useSave";
import { ASSET_SIZE_OPTION } from "../../types/searchFilter";
import useLazyLoad from "../../hooks/useLazyLoad";
import useGrantSearchResult from "../../hooks/search/useGrantSearchResult";
import LoadingTaxonomy from "../LoadingTaxonomy";
import TagFunderType from "../TagFunderType";
import { Divider } from "rsuite";
import SavePopup from "../SavePopup";
interface GrantCardProps {
  grant: GrantSearchResult;
  query?: SearchQuery;
  noMatchTaxonomy?: boolean;
  hideInfo?: boolean;
  hideFunderInfo?: boolean;
  fuzzy?: Fuse<string>;
  isHidden?: boolean;
  hideBtns?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  setHide?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, hide: boolean) => void;
  selected?: boolean;
  save_list_id?: string;
  savedSearchQuery?: SearchQuery;
  lazyLoadDelay?: number;
  tagMatched?: boolean;
  showUnmatched?: boolean;
  useMinHeightOnTaxonomy?: boolean;
}
function GrantCard({
  grant,
  noMatchTaxonomy = false,
  hideInfo = false,
  query,
  fuzzy,
  isHidden = false,
  hideBtns = false,
  hideFunderInfo = false,
  setHide,
  onClick,
  lazyLoadDelay = 0,
  tagMatched = false,
  showUnmatched = false,
  ...props
}: GrantCardProps) {
  const [onboardingData] = useOnboardingData();
  const key = getSearchResultKey(grant);
  const eligible = isGrantEligible(onboardingData.tax_exemption, query?.service_loc, grant);
  const deadline = reprGrantDeadline(grant);
  const [showFundraising, setShowFundraising] = useState<boolean>(false);
  const saveMode = props.save_list_id ? true : false;
  const { data, save } = useSave(saveMode ? grant._id : undefined, 1, props.savedSearchQuery);
  const { data: currentSavedList } = useSave(grant._id, 1);
  const [showSave, setShowSave] = useState<boolean>(false);
  const { ready } = useLazyLoad(lazyLoadDelay);
  const {
    data: grantTaxonomy,
    dataAll,
    isLoading,
  } = useGrantSearchResult(
    !hideInfo && ready ? grant._id : undefined,
    false,
    tagMatched ? undefined : query,
  );
  return (
    <>
      {showSave && grant._id && (
        <SavePopup
          open={showSave}
          onClose={() => setShowSave(false)}
          target_id={grant?._id}
          target_type={1}
          searchQuery={query}
          isVirtualGrant={grant?.type === "grant-page"}
        />
      )}
      <div
        key={key}
        className={classNames(
          "flex h-fit w-full flex-col gap-2",
          onClick ? "cursor-pointer bg-white hover:bg-purple-50" : "",
        )}
        {...(onClick ? { onClick } : {})}
      >
        {/* {showFundraising && (
        <StartFundRaisingModal
          query={query}
          open={showFundraising}
          setOpen={setShowFundraising}
          grant={grant}
          id={grant._id}
          type="GRANT"
        />
      )} */}
        <div className="inline-flex items-center justify-between">
          <div className="inline-flex gap-1">
            <TagFunderType type={grant.type} funder_type={grant.donor?.funder_type} />
            <TagGrantPresent grant={grant} />
            {grant.donor &&
              query &&
              query.funder_assets.length > 0 &&
              query.funder_assets.length < ASSET_SIZE_OPTION.length && (
                <TagFunderSize assets={grant.donor.assets} className="w-fit" />
              )}
          </div>
          {!hideBtns && !hideInfo && (
            <>
              {saveMode ? (
                <TooltipWrapper content={isHidden ? "Save this grant" : "Unsave this grant"}>
                  <div
                    className="cursor-pointer p-1 !text-gray-900"
                    id={"GA_TAG_click_save"}
                    onClick={(e) => {
                      if (props.save_list_id) save(props.save_list_id, isHidden);
                      e.stopPropagation();
                    }}
                  >
                    <i
                      className={classNames(
                        "gi-md text-purple-500 ",
                        isHidden ? "gi-heart" : "gi-heart-full",
                      )}
                    />
                  </div>
                </TooltipWrapper>
              ) : (
                <div className="inline-flex items-center">
                  <TooltipWrapper
                    content={currentSavedList.length > 0 ? "Unsave this grant" : "Save this grant"}
                  >
                    <div
                      className="cursor-pointer p-1 !text-gray-900"
                      id={"GA_TAG_click_save"}
                      onClick={(e) => {
                        setShowSave(true);
                        e.stopPropagation();
                      }}
                    >
                      <i
                        className={classNames(
                          "gi-md text-purple-500 ",
                          currentSavedList.length > 0 ? "gi-heart-full" : "gi-heart",
                        )}
                      />
                    </div>
                  </TooltipWrapper>
                  <TooltipWrapper content={isHidden ? "Unhide this grant" : "Hide this grant"}>
                    <div
                      className="cursor-pointer p-1 !text-gray-900"
                      onClick={(e) => {
                        setHide?.(e, !isHidden);
                      }}
                    >
                      <i className={classNames("gi-md ", isHidden ? "gi-view-hide" : "gi-view")} />
                    </div>
                  </TooltipWrapper>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex items-start justify-between">
          <h4 className="w-full grow whitespace-pre-line font-semibold text-purple-500 group-hover:underline">
            <FuzzyMatchName
              name={getGrantTitle(grant)}
              fuzzy={fuzzy}
              items={query?.text_query?.split(" ")}
            />
          </h4>
          {/* {!hideBtns && (
          <div className="mt-1 flex min-w-[40px] items-center justify-end gap-4">
            <RoundButton
              tooltip={"Start fundraising"}
              type="secondary"
              id={`btn-add-grant-to-project-${grant._id}`}
              handleOnClick={() => {
                setShowFundraising((prev) => !prev);
              }}
              icon="gi-sm gi-start text-purple-500"
            />
          </div>
        )} */}
        </div>
        {!hideInfo ? (
          <>
            <div className="flex items-center gap-2">
              <TagGrantEligible eligible={eligible} />
              {grant?.type === "grant-page" &&
                grant.donor &&
                grant.donor.does_not_accept_unsolicited !== true && (
                  <TagInvitationOnly invitationOnly={false} />
                )}
            </div>
            <div className="flex flex-col gap-[10px]">
              {!hideFunderInfo && grant && getAgencyName(grant) ? (
                <>{renderRow(grant.donor_id ? "Funder" : "Agency", getAgencyName(grant))}</>
              ) : null}
              {!hideFunderInfo && (
                <Divider
                  className={classNames("my-0 ", props.selected ? "bg-purple-200" : "bg-gray-200")}
                />
              )}
              {!hideFunderInfo && grant.donor?.address ? (
                <>{renderRow("Address", grant.donor.address)}</>
              ) : null}
              {!hideFunderInfo ? (
                <>
                  {renderRow(
                    "Service areas",
                    <ReprGrantServiceLocations
                      grant={dataAll}
                      detail={true}
                      max={2}
                      selected={props.selected}
                      query={query}
                    />,
                    false,
                    true,
                  )}
                </>
              ) : null}
              {/* {grant.npo_loc_text || reprGrantEligibleNPOLocations(grant, false) ? (
              <>
                {renderRow(
                  "Location of eligible nonprofits",
                  reprGrantEligibleNPOLocations(grant, false),
                  false,
                  true,
                )}
              </>
            ) : null} */}
              {grant.funding_type ? (
                <>{renderRow("Grant type", reprGrantFundingType(grant, query, 1))}</>
              ) : null}
              <div className="flex items-center gap-3 divide-x divide-gray-500">
                {renderRow("Grant amount", reprGrantAmount(grant), false, true)}
              </div>
              {deadline ? <>{renderRow("Deadline", deadline, false, true)}</> : null}
            </div>
            {!noMatchTaxonomy &&
              (isLoading || (!ready && !grantTaxonomy) ? (
                <LoadingTaxonomy seed={grant.uid} />
              ) : (
                <PhilanthropicInformationFlexWrap
                  containerClass={classNames(
                    "p-4 border rounded",
                    props.selected ? "border-purple-200" : "border-gray-200",
                  )}
                  id={key}
                  focusArea={showUnmatched ? dataAll?.focus_area : grantTaxonomy?.focus_area}
                  beneficiary={showUnmatched ? dataAll?.beneficiary : grantTaxonomy?.beneficiary}
                  program={showUnmatched ? dataAll?.program : grantTaxonomy?.program}
                  location={showUnmatched ? dataAll?.service_loc : grantTaxonomy?.service_loc}
                  priorityFocusArea={query?.must_focus_area}
                  priorityBeneficiary={query?.must_beneficiary}
                  priorityProgram={query?.must_program}
                  priorityLocation={query?.must_service_loc}
                  secondaryPriorityLocation={query?.service_loc}
                  tagSize="sm"
                  tagMatched={showUnmatched ? false : tagMatched}
                  allowPastGrantSearch={
                    grant.donor_id && grant.type === "grant-page" ? true : false
                  }
                  funder_id={grant.donor_id}
                  searchQuery={query}
                  predictedSize={5}
                  useMinHeight={props.useMinHeightOnTaxonomy}
                />
              ))}
          </>
        ) : (
          <div className="flex flex-col gap-2">
            {grant && getAgencyName(grant) ? (
              <>{renderRow(grant.donor_id ? "Funder" : "Agency", getAgencyName(grant))}</>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
}
export default GrantCard;
