import React from "react";
import classNames from "classnames";

interface SpinnerProps {
  size?: "sm" | "md" | "lg";
  color?: "purple" | "red" | "yellow" | "green" | "blue" | "gray" | "white";
  wrapperClass?: string;
  borderSize?: number;
  span?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({
  size = "md",
  color,
  wrapperClass,
  borderSize = 2,
  span = false,
}) => {
  const sizeClasses = {
    sm: "h-4 w-4",
    md: "h-6 w-6",
    lg: "h-8 w-8",
  };

  const colorClasses: any = {
    gray: "border-gray-300",
    red: "border-red-500",
    green: "border-green-500",
    blue: "border-blue-500",
    indigo: "border-indigo-500",
    purple: "border-purple-500",
    pink: "border-pink-500",
    white: "border-white",
  };

  if (span) {
    return (
      <span
        className={classNames(
          "inline-block  animate-spin rounded-full border-2 border-solid  border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]",
          wrapperClass,
          sizeClasses[size],
          color && colorClasses[color],
          borderSize && `border-[${borderSize}px]`,
        )}
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </span>
    );
  }

  return (
    <span className={classNames("flex items-center justify-center px-2", wrapperClass)}>
      <span
        className={classNames(
          "inline-block  animate-spin rounded-full border-2 border-solid  border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]",
          sizeClasses[size],
          color && colorClasses[color],
          borderSize && `border-[${borderSize}px]`,
        )}
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </span>
    </span>
  );
};
Spinner.defaultProps = {
  color: "purple",
};
export default Spinner;
