import classNames from "classnames";
import {
  IProjectInteraction,
  IProjectInteractionComment,
  IProjectInteractionWithProject,
  getProjectInteractionTypeColor,
  reprProjectInteractionType,
} from "../types/project";
import { scrollBarClass } from "../utils/classes";
import Button from "./tailwind/Button";
import PopupModal from "./tailwind/PopupModal";
import { useCallback, useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import GibooToast from "./GibooToast";
import { convertToReadable, toDateString } from "../utils/formatHelper";
import IMG_LIKE from "../assets/project-mgt/like.svg";
import IMG_REPLY from "../assets/project-mgt/reply.svg";
import LetterPreview from "../pages/npo/grant/pages/component/LetterPreview";
import { Whisper, Popover, Placeholder } from "rsuite";
import AlertModal from "./tailwind/AlertModal";
import Slideover from "./Slideover";
import UserProfile from "./UserProfile";
import useInteractions from "../hooks/project/useInteractions";
import { createProjectInteraction } from "../hooks/project/useInteraction";
import useInteractionComments, {
  createProjectInteractionComment,
} from "../hooks/project/useInteractionComments";
import UserMentionsInput from "./UserMentionsInput";
import useOrgs from "../hooks/useOrgs";
import useOrgMembers from "../hooks/project/useOrgMembers";
import icon_attach from "../assets/project-mgt/attach.svg";
import useProject from "../hooks/project/useProject";
import useInteractionsOtherProject from "../hooks/project/useInteractionsOtherProject";
import { useNavigate } from "react-router-dom";
import { TooltipWrapper } from "./TooltipWrapper";
interface ProjectAllActivityPopupProps {
  project_id: string | undefined;
  task_id: string | undefined;
  show: boolean;
  onClose?: () => void;
  title?: string;
  activitySelected?: PROJ_ACTIVITY;
}

enum PROJ_ACTIVITY {
  PLANNING = "Planning",
  LOI = "LOI sent",
  GRANT_APPLICATION = "Grant application submitted",
  MEETING = "Meeting",
  CALL = "Call",
  EMAIL = "Email",
  REPORTING = "Reporting",
}
const PROJ_ACTIVITY_COLOR = {
  PLANNING: "#ececec",
  LOI: "#F7D9C4",
  GRANT_APPLICATION: "#BCD4E6",
  MEETING: "#B8E0D2",
  CALL: "#EAC4D5",
  EMAIL: "#F1FFC4",
  REPORTING: "#BAF2E9",
  NONE: "#bababa",
};
const enumKeys = Object.keys(PROJ_ACTIVITY) as Array<keyof typeof PROJ_ACTIVITY>;
const enumKeyValuePairs = enumKeys.map((enumKey) => ({
  key: enumKey,
  value: PROJ_ACTIVITY[enumKey],
}));
function ProjectAllActivityPopup({
  project_id,
  task_id,
  show,
  onClose,
  title,
  activitySelected,
  ...props
}: ProjectAllActivityPopupProps) {
  const [user] = useUser();
  const { hasEditPermission } = useProject(project_id);
  const { data, isLoading, revalidate } = useInteractions(task_id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined);
  const [memo, setMemo] = useState("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [selectedActivity, setSelectedActivity] = useState<PROJ_ACTIVITY | undefined>(
    activitySelected || PROJ_ACTIVITY.PLANNING,
  );
  const { selected } = useOrgs();
  const { data: members, isLoading: orgmembersLoading } = useOrgMembers(selected);

  const handleInteraction = () => {
    if (!task_id || !project_id) return;
    setIsUpdating(true);
    // createProjectInteraction({
    //   memo,
    //   type: selectedActivity as string,
    //   task_id: task_id,
    // })
    //   .then(() => {
    //     GibooToast({
    //       type: "success",
    //       message: "Interaction added successfully",
    //     });
    //     revalidate();
    //   })
    //   .catch(() => {
    //     GibooToast({
    //       type: "failure",
    //       message: "We apologize for the inconvenience., Please try again",
    //     });
    //   })
    //   .finally(() => {
    //     setMemo("");
    //     // setSelectedActivity(undefined);
    //     setIsUpdating(false);
    //   });
  };
  return (
    <>
      {showAlert && (
        <AlertModal
          variant="purple"
          handleCancel={() => {
            setShowAlert(false);
          }}
          handleConfirm={() => {
            if (editIndex === undefined) return;
            setEditIndex(undefined);
          }}
          confirmTitle={"Delete"}
          title="Delete"
          content="Are you sure you want to delete?"
          isOpen={showAlert}
          handleClose={() => setShowAlert(false)}
        />
      )}
      <Slideover
        isOpen={show}
        onClose={() => {
          setEditIndex(undefined);
          onClose?.();
        }}
        title={title}
        direction="right"
        size="md"
      >
        <div className="mt-[44px] flex h-fit  w-full flex-col gap-3 py-5">
          <div className="flex w-full">
            <h5 className="whitespace-nowrap font-poppins text-base">Add an update</h5>
          </div>
          <ActivitySelector
            selected={selectedActivity || PROJ_ACTIVITY.PLANNING}
            onChange={(value) => {
              setSelectedActivity(value as PROJ_ACTIVITY);
            }}
            allowEdit={hasEditPermission}
          />
          <UserMentionsInput
            id="note-memo"
            value={memo}
            className="h-[100px]"
            wrapperClass="!mb-0"
            disabled={!hasEditPermission}
            placeholder="Add an update"
            handleOnChange={(e) => {
              setMemo(e.target.value);
            }}
            options={
              members
                .filter((i) => i._id !== user?._id)
                .map((i) => ({ id: i._id, display: `${i.firstname} ${i.lastname}` })) as []
            }
          />
          <div className="flex w-full items-center justify-between">
            <span className="inline-flex gap-5">
              {/* <h5 className="inline-flex gap-2 font-poppins text-sm text-gray-700">
                <img src={icon_attach} className="h-4 w-4" />
                add files
              </h5> */}
              <h5 className="font-poppins text-sm text-gray-700">@ mention</h5>
            </span>
            <Button
              id="btn-loi-close"
              className="mr-4 !h-[37px]"
              color="purple"
              label={isUpdating ? "Updating" : "Update"}
              loading={isUpdating}
              disabled={memo === "" || !hasEditPermission}
              handleOnClick={() => {
                handleInteraction();
              }}
            />
          </div>
          <InteractionLog project_id={project_id} task_id={task_id} />
        </div>
      </Slideover>
    </>
  );
}
export default ProjectAllActivityPopup;

interface IActivitySelector {
  selected: PROJ_ACTIVITY;
  onChange: (value: string) => void;
  allowEdit?: boolean;
  wrapperClassName?: string;
}
function ReplyComponent({
  project_id,
  interaction_id,
  revalidate,
}: {
  project_id: string | undefined;
  interaction_id: string | undefined;
  revalidate: () => void;
}) {
  const [showInput, setShowInput] = useState(false);
  const [memo, setMemo] = useState("");
  const [user] = useUser();
  const [isLoadingAddComment, setIsLoadingAddComment] = useState<boolean>(false);
  const { selected } = useOrgs();
  const { data: members, isLoading: orgmembersLoading } = useOrgMembers(selected);

  const handleAddComment = () => {
    if (!interaction_id || !project_id) return;
    setIsLoadingAddComment(true);
    createProjectInteractionComment({ content: memo, interaction_id: interaction_id })
      .then(() => {
        revalidate();
        setMemo("");
      })
      .finally(() => {
        setIsLoadingAddComment(false);
        setShowInput(false);
        setMemo("");
      });
  };
  return showInput ? (
    <div className="flex flex-col">
      <div className="flex gap-[10px]">
        <UserProfile
          userId={user?._id}
          showEmail={false}
          showName={false}
          wrapperClassname={"item-start"}
        />
        <UserMentionsInput
          id="note-memo"
          value={memo}
          placeholder="Add an update"
          noOfRows={5}
          handleOnChange={(e) => {
            setMemo(e.target.value);
          }}
          options={
            members
              .filter((i) => i._id !== user?._id)
              .map((i) => ({ id: i._id, display: `${i.firstname} ${i.lastname}` })) as []
          }
        />
      </div>
      <div className="mt-2 inline-flex justify-end">
        <Button
          id="btn-loi-close"
          className="mr-4"
          color="purple"
          label="Cancel"
          outline
          handleOnClick={() => {
            setShowInput((prev) => !prev);
          }}
        />
        <Button
          id="btn-loi-close"
          className="mr-4"
          color="purple"
          label="Reply"
          loading={isLoadingAddComment}
          handleOnClick={() => {
            handleAddComment();
          }}
        />
      </div>
    </div>
  ) : (
    <div className="flex justify-end gap-3">
      {/* <span title={"Like"}>
        <img src={IMG_LIKE} className="h-5 w-5" />
      </span> */}
      <TooltipWrapper content="Reply">
        <span
          className="hover:bg-gra-100 rounded"
          onClick={() => {
            setShowInput((prev) => !prev);
          }}
          title={"Reply"}
        >
          <img src={IMG_REPLY} className="h-5 w-5" />
        </span>
      </TooltipWrapper>
    </div>
  );
}
function Interaction({
  interaction,
  project_id,
}: {
  interaction: IProjectInteractionWithProject | IProjectInteraction;
  project_id: string | undefined;
}) {
  const navigate = useNavigate();
  const {
    data: comments,
    isLoading: isLoadingComment,
    revalidate: revalidateComments,
  } = useInteractionComments(interaction._id);
  const renderComment = (comment: IProjectInteractionComment) => {
    return (
      <div className="flex flex-col rounded border border-gray-200 p-4 ">
        <div className="inline-flex w-full justify-between gap-3">
          <span className="w-[200px]">
            <UserProfile userId={comment.user_id} showName={true} size={24} />
          </span>
          <h5 className="font-poppins text-sm text-gray-900">
            {comment?.created_at ? toDateString(comment?.created_at, true, true) : "None"}
          </h5>
        </div>
        <div className="mt-1 w-full">
          <div className="flex items-start gap-2">
            <h5 className="font-poppins text-sm text-gray-900">
              {convertToReadable(comment.content)}
            </h5>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex h-fit w-full flex-col gap-3 rounded border border-gray-300 p-4">
      <div className="inline-flex w-full justify-between gap-3">
        <span className="w-[200px]">
          <UserProfile userId={interaction.user_id} showName={true} size={24} />
        </span>
        <h5 className="font-poppins text-sm text-gray-900">
          {interaction?.created_at ? toDateString(interaction?.created_at, true, true) : "None"}
        </h5>
      </div>
      <span className="inline-flex w-fit items-center gap-3">
        <div
          className={classNames(
            "grid h-[25px] w-fit grow place-items-center rounded px-[10px] text-xs text-gray-900 hover:opacity-80",
          )}
          style={{
            background: getProjectInteractionTypeColor(interaction?.type || ""),
          }}
        >
          {interaction?.type ? reprProjectInteractionType(interaction.type) : "None"}
        </div>
        {(interaction as IProjectInteractionWithProject).project_name &&
          (interaction as IProjectInteractionWithProject).project_id && (
            <div className="inline-flex gap-2">
              <span>at</span>
              <span
                className="cursor-pointer hover:text-purple-500"
                onClick={() => {
                  navigate(
                    `/project/${(interaction as IProjectInteractionWithProject).project_id}`,
                  );
                }}
              >
                {(interaction as IProjectInteractionWithProject).project_name}
              </span>
            </div>
          )}
      </span>
      <div className="w-full">
        <div className="flex items-start gap-2">
          <h5 className="font-poppins text-sm text-gray-900">
            {convertToReadable(interaction.content)}
          </h5>
        </div>
      </div>
      <div
        className={classNames(
          "flex max-h-[250px] flex-col gap-3 overflow-y-scroll overscroll-contain",
          scrollBarClass,
        )}
      >
        {comments.map((item) => {
          return renderComment(item);
        })}
      </div>
      <ReplyComponent
        interaction_id={interaction._id}
        project_id={project_id}
        revalidate={() => {
          revalidateComments();
        }}
      />
    </div>
  );
}
function ActivitySelector({ selected, onChange, ...props }: IActivitySelector) {
  const [showAddActivity, setShowAddActivity] = useState<boolean>(false);
  return (
    <Whisper
      placement="bottom"
      className=" shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)] "
      open={showAddActivity}
      speaker={
        <Popover onMouseLeave={() => setShowAddActivity(false)} className="!px-0">
          <ul className="h-auto w-fit">
            {enumKeyValuePairs.map((enumPair) => {
              return (
                <li
                  key={enumPair.key}
                  className="w-full cursor-pointer px-3 py-1 hover:bg-gray-100 hover:shadow-sm"
                  onClick={() => {
                    onChange(enumPair.value);
                    setShowAddActivity(false);
                  }}
                >
                  <span
                    style={{ background: PROJ_ACTIVITY_COLOR[enumPair.key] }}
                    className="grid h-[25px] w-fit  place-items-center rounded px-[10px] font-poppins text-xs text-gray-900 last:mb-0 "
                  >
                    {enumPair.value}
                  </span>
                </li>
              );
            })}
          </ul>
        </Popover>
      }
    >
      <div className="w-fit hover:opacity-80">
        <div
          onClick={() => {
            props.allowEdit && setShowAddActivity(true);
          }}
          id="btn-add-activity"
          className={classNames(
            "-mt-1 grid h-[25px] w-fit grow place-items-center rounded px-[10px] text-xs text-gray-900 hover:opacity-80",
            props.wrapperClassName,
          )}
          style={{
            background:
              PROJ_ACTIVITY_COLOR[
                enumKeyValuePairs.find((i) => i.value === selected)?.key || "NONE"
              ],
          }}
        >
          {selected}
        </div>
      </div>
    </Whisper>
  );
}

function InteractionLog({
  project_id,
  task_id,
}: {
  project_id: string | undefined;
  task_id: string | undefined;
}) {
  const { data, isLoading: loading } = useInteractions(task_id);
  const [tabSelected, setTabSelected] = useState<"own" | "other">("own");
  const { data: otherData, isLoading: otherLoading } = useInteractionsOtherProject(task_id);
  console.log(data, otherData);
  const selected = tabSelected == "own" ? data : otherData;
  return (
    <>
      <div className="inline-flex w-full gap-[38px] border-b border-gray-300">
        <h5
          className={classNames(
            "w-fit cursor-pointer  border-0 pb-[5px] font-poppins  text-sm text-gray-700 ",
            {
              "border-b border-purple-500 font-semibold text-purple-500": tabSelected == "own",
            },
          )}
          onClick={() => setTabSelected("own")}
        >
          Interactions for this project
        </h5>
        <h5
          className={classNames(
            "pb-[5px]font-poppins w-fit  cursor-pointer border-0  text-sm text-gray-700  ",
            {
              "border-b border-purple-500 font-semibold text-purple-500": tabSelected == "other",
            },
            { hidden: otherData.length === 0 },
          )}
          onClick={() => setTabSelected("other")}
        >
          Interactions with this funder in other projects
        </h5>
      </div>
      {loading || otherLoading ? (
        Array(5)
          .fill("#")
          .map((_, idx) => <Placeholder.Graph key={idx} active height={120} className="rounded" />)
      ) : (
        <div
          className={classNames("mt-3 flex h-[calc(100vh-520px)] flex-col gap-3", scrollBarClass)}
        >
          {selected.length > 0 ? (
            selected.map((i, index) => {
              return <Interaction interaction={i} key={index} project_id={project_id} />;
            })
          ) : (
            <div className="grid h-[130px] place-items-center rounded border border-gray-300">
              <h4 className="font-poppins text-xl font-medium text-black">No activities</h4>
            </div>
          )}
        </div>
      )}
    </>
  );
}
