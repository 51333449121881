import WhiteContainer from "../../../components/dashboard/WhiteContainer";
import WordCloud from "react-d3-cloud";
import { Word } from "react-d3-cloud/lib/WordCloud";
import { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import NoInformation from "./NoInformation";
import no_info from "../../img/no-info.svg";
import { Tooltip, Whisper } from "rsuite";
import classNames from "classnames";
import { toUsdLong } from "../../../utils/formatHelper";
interface WordDataBase {
  score: number;
  label: string;
}
interface Iprops<T> {
  wrapperClass?: string;
  data: T[];
  reprTooltip: (d: T) => ReactNode;
  colors: string[];
  title: string;
  loading: boolean;
  sizeRatio?: number;
}
const intToHex = (num: number): string => {
  const n = num > 255 ? 255 : num < 0 ? 0 : num;
  return n.toString(16);
};
function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function findG(e: Element | null): Element | null {
  if (!e) return null;
  if (e.tagName === "g") return e;
  else if (e.children && e.children.length > 0) return findG(e.children[0]);
  return null;
}
const MIN_OPACITY = 150;
const WIDTH = 600;
const HEIGHT = 320;
function PhilanthropyWords<T extends WordDataBase>({
  data,
  colors,
  title,
  loading,
  sizeRatio = 1,
  reprTooltip,
}: Iprops<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const [wordCloud, setWordCloud] = useState<Word[]>([]);
  const [dataMap, setDataMap] = useState<{
    [key: string]: T;
  }>({});
  const [index, setIndex] = useState<{
    [key: string]: number;
  }>({});
  const [maxSize, setMaxSize] = useState<number>(100);
  const [hover, setHover] = useState<string>("");
  useEffect(() => {
    const maxExpectedValue = data.reduce(
      (prev, cur) => Math.max(prev, (cur.label?.length || 0) * (100 * (cur.score || 0))),
      0,
    );
    // console.log(maxExpectedValue);
    setWordCloud(data.map((i) => ({ text: i.label, value: i.score })));
    setDataMap(data.reduce((prev, cur) => ({ ...prev, [cur.label]: cur }), {}));
    setMaxSize(Math.max(0.0008, (220 / maxExpectedValue) * sizeRatio));
    const randomNum = data.length > 0 && data[0].label.length > 0 ? data[0].label.charCodeAt(0) : 0;
    setIndex(
      data.reduce(
        (prev, cur, i) => ({ ...prev, [cur.label]: (i + randomNum) % colors.length }),
        {},
      ),
    );
  }, [data]);
  // console.log([6, 18, 26, 40].map((v) => console.log(v, 150 - Math.log2(v) * 24)));
  const fontSize = useCallback((word: Word) => 14 + maxSize * (word.value || 0) * 100, [maxSize]);
  // console.log(wordCloud?.map((v) => fontSize(v)));
  const random = useCallback(() => 0, []);
  const fill = useCallback(
    (d: Word, i: number) =>
      `${colors[index[d.text]]}${
        hover === ""
          ? intToHex(
              MIN_OPACITY + Math.min(255 - MIN_OPACITY, Math.round(d.value * (255 - MIN_OPACITY))),
            )
          : hover === d.text
          ? "FF"
          : "33"
      }`,
    [index, hover],
  );
  // const fontWeight = useCallback(
  //   (d: Word, i: number) => `${hover === d.text ? "bold" : "normal"}`,
  //   [hover],
  // );
  const onWordMouseOver = useCallback((e: any, d: Word) => {
    // const g = findG(ref.current) as SVGGElement;
    // console.log(g, g?.clientHeight, g?.scrollHeight, g?.getBBox().height);
    // console.log(d.text, fontSize(d));
    setHover(d.text);
  }, []);
  const onWordMouseOut = useCallback((e: any, d: Word) => {
    setTimeout(() => setHover((prev) => (prev === d.text ? "" : prev)), 50);
  }, []);

  const renderWordCloud = useMemo(() => {
    return (
      <WordCloud
        data={wordCloud}
        width={WIDTH}
        height={HEIGHT}
        font="poppins"
        fontWeight={"normal"}
        fontSize={fontSize}
        spiral="archimedean"
        rotate={0}
        padding={1}
        random={() => random()}
        fill={(d: Word, i: number) => fill(d, i)}
        onWordMouseOver={onWordMouseOver}
        onWordMouseOut={onWordMouseOut}
      />
    );
  }, [fontSize, random, fill, wordCloud, onWordMouseOver, onWordMouseOut]);

  useEffect(() => {
    const g = findG(ref.current) as SVGGElement;
    if (g) {
      const height = g.getBBox().height;
      const width = g.getBBox().width;
      const x = width < WIDTH ? (WIDTH - width) / 3 : 0;
      const y = height < HEIGHT ? (HEIGHT - height) / 3 : 0;
      g.setAttribute("transform", `translate(${WIDTH / 2 + x},${HEIGHT / 2 + y})`);
    }
  }, [ref.current, wordCloud]);
  //0   214    320
  return (
    <WhiteContainer wrapperClass="!p-0 !my-0 h-fit border-none bg-inherit">
      <div ref={ref}>
        {loading ? (
          <div className="loading-light-shimmer-on w-full p-4">
            <div className="loading-light-shimmer-child flex flex-col items-stretch gap-4 p-4">
              <div className="mx-16 h-8"></div>
              <div className="ml-24 mr-16 h-8"></div>
              <div className="mx-8 h-6"></div>
              <div className="ml-16 mr-24 h-8"></div>
              <div className="ml-24 mr-12 h-8"></div>
            </div>
          </div>
        ) : data.length === 0 ? (
          <NoInformation img={no_info} />
        ) : (
          <div className="mt-0 flex h-full w-full items-center justify-center">
            <Whisper
              open
              placement="bottomStart"
              followCursor
              speaker={
                <Tooltip
                  className={classNames(
                    "absolute z-[1000] flex flex-col gap-1 rounded bg-white px-4 py-3 text-gray-900 opacity-100 shadow-[0_0px_15px_1px_rgba(0,0,0,0.1)]",
                    { hidden: !hover },
                  )}
                >
                  {reprTooltip(dataMap[hover])}
                </Tooltip>
              }
            >
              <div className="min-h-[220px] w-[535px]">{renderWordCloud}</div>
            </Whisper>
          </div>
        )}
      </div>
    </WhiteContainer>
  );
}
const MemoizedPhilanthropyWords = memo(PhilanthropyWords) as typeof PhilanthropyWords;
export default MemoizedPhilanthropyWords;
