import axios from "axios";
import config from "../../api";
import useSWR from "swr";
import { swrOptionDedupling5mins } from "../../types/swr";
import { OpencallGrantDetail } from "../../types/grant";

interface OpencallGrantsDetailFromSameFunderState {
  data: OpencallGrantDetail[] | undefined;
  isLoading: boolean;
  error: object;
}
export default function useOpencallGrantsDetailFromSameFunder(
  funder_id?: string,
): OpencallGrantsDetailFromSameFunderState {
  const fetchGrants = async (url: string) => {
    if (!url || !funder_id) return new Promise<OpencallGrantDetail[]>((resolve) => resolve([]));
    return axios.get(url, config).then((res) => res.data);
  };
  const { data, isLoading, error } = useSWR<OpencallGrantDetail[]>(
    funder_id
      ? process.env.REACT_APP_API_URL + `/api/v2/donors/${funder_id}/open_call_grants_info`
      : null,
    fetchGrants,
    swrOptionDedupling5mins,
  );
  return { data, isLoading, error };
}
