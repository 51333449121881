import { Tooltip, Whisper } from "rsuite";
import DecreaseTag from "../pages/npo/financials/component/DecreaseTag";
import IncreaseTag from "../pages/npo/financials/component/IncreaseTag";
import classNames from "classnames";
import { toFixedWithMinimum, toUsdLong } from "../utils/formatHelper";

export default function PercentageTag({
  beforeLatest,
  beforeLatestYear,
  latest,
  latestYear,
  percentage,
  showIncreaseDecreaseTag = true,
  isDollar = true,
}: {
  beforeLatest?: number | undefined;
  beforeLatestYear?: number | undefined;
  latest?: number | undefined;
  latestYear?: number | undefined;
  percentage?: number;
  showIncreaseDecreaseTag?: boolean;
  isDollar?: boolean;
}) {
  return (
    <Whisper
      placement="top"
      speaker={
        <Tooltip
          className={classNames(
            "flex flex-col gap-1 rounded bg-white px-4 py-3 text-gray-900 opacity-100 shadow-[0_0px_15px_1px_rgba(0,0,0,0.1)]",
          )}
        >
          {beforeLatest !== undefined && beforeLatest !== null && (
            <h5>
              {`In ${beforeLatestYear}, `}
              <span className="font-semibold">
                {`${
                  isDollar ? toUsdLong(beforeLatest || 0) : (beforeLatest || 0).toLocaleString()
                }`}
              </span>
            </h5>
          )}
          {latest !== undefined && latest !== null && (
            <h5>
              {`In ${latestYear}, `}
              <span className="font-semibold">
                {`${isDollar ? toUsdLong(latest || 0) : (latest || 0).toLocaleString()}${
                  beforeLatest === undefined || beforeLatest === null ? " (new)" : ""
                }`}
              </span>
            </h5>
          )}
        </Tooltip>
      }
    >
      <div className="flex h-[76px]  w-[90px] shrink-0 flex-col items-center justify-evenly rounded bg-purple-50 font-poppins text-2xl font-semibold text-purple-500">
        {percentage && (percentage > 1000 || percentage < -1000) ? (
          <>
            {!showIncreaseDecreaseTag && (
              <span className={classNames(showIncreaseDecreaseTag ? "text-sm" : "text-xs")}>
                {percentage > 0 ? "Increased" : "Decreased"}
              </span>
            )}
            <span className={classNames(showIncreaseDecreaseTag ? "text-sm" : "text-xs")}>
              {showIncreaseDecreaseTag ? "Significant" : "significantly"}
            </span>
          </>
        ) : (
          `${toFixedWithMinimum(Math.abs(percentage || 0), 2, 1)}%`
        )}
        {showIncreaseDecreaseTag && percentage ? (
          percentage > 0 ? (
            <IncreaseTag />
          ) : percentage < 0 ? (
            <DecreaseTag />
          ) : null
        ) : null}
      </div>
    </Whisper>
  );
}
