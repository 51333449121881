import { Dialog } from "@headlessui/react";
import PlainButton from "./PlainButton";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import classNames from "classnames";
import useOnboardingData from "../hooks/useOnboarding";
import RoundButton from "./RoundButton";
import FakeProgressBar from "./FakeProgressBar";
import { uploadFileService } from "../services/file-upload/upload.service";
import { getVerificationFileKey } from "../utils/media";
import { toBytesFormat } from "../utils/formatHelper";
import useUploadIRS from "../hooks/useUploadIRS";

function UploadIRS() {
  const [user] = useUser();
  const [loadingFileUpload, setLoadingFileUpload] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [onboardingData] = useOnboardingData();
  const { data, request } = useUploadIRS(onboardingData._id);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<
    { file?: File; name: string; size: number; done?: boolean; key: string; failed?: boolean }[]
  >([]);
  useEffect(() => {
    if (data.files) setSelectedFile(data.files);
  }, [data]);
  const handleUpload = (file: File) => {
    setLoadingFileUpload(true);
    uploadFileService(file, getVerificationFileKey(onboardingData._id), ["image", "doc"])
      .then((res) => {
        const key = res as string;
        const success = key ? true : false;
        setSelectedFile((prev) => {
          const index = prev.findIndex((f) => f.name === file.name && f.size === file.size);
          if (index >= 0) {
            return [
              ...prev.slice(0, index),
              { ...prev[index], done: success, failed: !success, key: success ? key : "" },
              ...prev.slice(index + 1),
            ];
          } else return prev;
        });
      })
      .catch(() => {
        setSelectedFile((prev) => {
          const index = prev.findIndex((f) => f.name === file.name && f.size === file.size);
          if (index >= 0) {
            return [
              ...prev.slice(0, index),
              { ...prev[index], done: false, failed: true, key: "" },
              ...prev.slice(index + 1),
            ];
          } else return prev;
        });
      })
      .finally(() => setLoadingFileUpload(false));
  };
  const renderSelectedFiles = () => {
    return (
      <div className="lex flex-col gap-4">
        {selectedFile.map((item, index) => {
          return (
            <div key={index} className="grid w-full grid-cols-5 items-center gap-2">
              <div
                className={classNames(
                  "col-span-4 grid grid-cols-4 items-center gap-2 rounded px-2 py-1",
                  item.failed ? "bg-purple-50" : "",
                )}
              >
                <span className="col-span-3 truncate whitespace-nowrap">{item.name}</span>
                <div className="col-span-1 flex justify-end">
                  {item.failed ? (
                    <span className="text-sm font-semibold text-purple-500">Failed</span>
                  ) : item.done ? (
                    <span className="text-sm">{toBytesFormat(item.size)}</span>
                  ) : (
                    <FakeProgressBar estimatedSeconds={3} done={item.done} />
                  )}
                </div>
              </div>
              <div className="col-span-1 flex gap-2">
                <RoundButton
                  id={`btn-remove-item-${index}`}
                  icon="gi-md gi-x"
                  type="tertiary"
                  size="sm"
                  handleOnClick={() =>
                    setSelectedFile((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
                  }
                />
                {item.failed && item.file && (
                  <PlainButton
                    handleOnClick={() => {
                      if (item.file) handleUpload(item.file);
                    }}
                    id={`btn-retry-item-${index}`}
                    className="!p-0 !text-sm"
                    color="purple"
                    label="Retry"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return <>{renderSelectedFiles()}</>;
}
export default UploadIRS;
