import { useNavigate, useParams } from "react-router-dom";
import TextInput from "../../../components/tailwind/TextInput";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { useEffect, useState } from "react";
import IMG_TRASH from "../../../assets/project-mgt/trash.svg";
import PlainButton from "../../../components/PlainButton";
import GibooToast from "../../../components/GibooToast";
import GibooDialog from "../../../components/Dialog";
import Button from "../../../components/tailwind/Button";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import { Popover, Whisper } from "rsuite";
import classNames from "classnames";
import UserProfile from "../../../components/UserProfile";
import useUser from "../../../hooks/useUser";
import DropdownSelector from "../../../components/dropdown/DropdownSelector";
import useProject from "../../../hooks/project/useProject";
import useUserDetail from "../../../hooks/project/useUserDetail";
import { User, roleToID, roleToName } from "../../../app/userSlice";
import RolePicker from "../../../components/RolePicker";
import PrimaryButton from "../../../components/PrimaryButton";
interface IAccess {
  user_id: string;
  role: number;
}

function ProjectSetting() {
  const { id: projectId } = useParams();
  const {
    data: projectData,
    hasEditPermission,
    update,
    revalidate,
    isLoading,
    deleteThis,
    setMemberRole,
  } = useProject(projectId);
  const [accessList, setAccessList] = useState<IAccess[]>([]);
  const [projectName, setProjectName] = useState("");
  const [owner, setOwner] = useState<IAccess>(
    projectData.members.filter((i) => i.user_id === projectData.user_id)[0],
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoading) return;
    setAccessList(projectData.members);
  }, [isLoading, projectData]);
  useEffect(() => {
    if (projectData) {
      setProjectName(projectData?.name);
      setOwner(projectData.members.filter((i) => i.user_id === projectData.user_id)[0]);
    }
  }, [projectData]);
  const renderProjectOwner = () => {
    return (
      <div className="my-10 flex w-[480px] flex-col gap-3">
        <p>Project owner</p>
        <DropdownSelector<IAccess>
          downIcon
          inputClass="!h-[48px]"
          renderItem={(item: IAccess) => (
            <div className={classNames("flex  w-fit items-center gap-2")}>
              <UserProfile userId={item?.user_id} showName={true} size={24} />
            </div>
          )}
          id="donors"
          color="gray"
          selected={owner}
          setSelected={setOwner as any}
          getKey={(item: IAccess) => item.user_id}
          data={accessList.filter((i) => i.role === 5)}
        />
      </div>
    );
  };
  const renderAccess = () => {
    return (
      <div className={classNames("relative w-fit rounded border border-gray-100")}>
        <Table
          {...(accessList.length > 5 ? { height: 300, autoHeight: false } : { autoHeight: true })}
          headerHeight={35}
          width={accessList.length > 5 ? 767 : 765}
          loading={isLoading}
          data={accessList}
          rowHeight={45}
          className={classNames("w-full bg-gray-100")}
          rowClassName={(_, rowIndex) => (rowIndex === 0 ? "!bg-gray-400 cursor-pointer" : "")}
          hover={false}
          affixHorizontalScrollbar
        >
          {/* title */}
          <Column width={369} align="left">
            <HeaderCell className="text-center font-poppins text-sm font-semibold text-gray-900">
              Name
            </HeaderCell>
            <Cell dataKey="name">
              {(rowData) => {
                return (
                  <>
                    <span className="grid w-full place-items-start p-1">
                      <UserProfile userId={rowData?.user_id} showName={true} size={24} />
                    </span>
                  </>
                );
              }}
            </Cell>
          </Column>
          {/* owner */}
          <Column width={250} align="center">
            <HeaderCell isHeaderCell className="font-poppins text-sm font-semibold text-gray-900">
              Email
            </HeaderCell>
            <Cell dataKey="user_id">
              {(rowData) => {
                return (
                  <>
                    <UserEmail id={rowData.user_id} />
                  </>
                );
              }}
            </Cell>
          </Column>
          {/* status */}
          <Column width={150} align="left">
            <HeaderCell className="text-center font-poppins text-sm font-semibold text-gray-900">
              Role
            </HeaderCell>
            <Cell dataKey="role" align="center">
              {(rowData, rowIndex) => {
                return (
                  <>
                    {!rowData.special_row ? (
                      <>
                        {rowData.role === 5 || !hasEditPermission ? (
                          <h6 className="pr-5 pt-1 text-gray-900">{roleToName[5]}</h6>
                        ) : (
                          <RolePicker
                            disable={["Owner", "Admin", "Guest", "Viewer"]}
                            currentRole={rowData.role || 0}
                            allowEdit={true}
                            updateRole={(role: number) => {
                              setMemberRole(rowData.user_id, role);
                            }}
                          />
                        )}
                      </>
                    ) : null}
                  </>
                );
              }}
            </Cell>
          </Column>
        </Table>
      </div>
    );
  };

  return (
    <>
      <GibooDialog
        onClose={() => setShowConfirm(false)}
        open={showConfirm}
        title="Delete project"
        content={<p>Are you sure you want to delete this project?</p>}
        footer={
          <Button
            id="btn-delete-project"
            handleOnClick={() => {
              if (!projectData?._id) return;
              setIsDeleting(true);
              deleteThis()
                .then((res) => {
                  if (res) {
                    GibooToast({
                      type: "success",
                      message: `Project deleted`,
                      actionLabel: "",
                    });
                  }
                })
                .catch((err) => {
                  // console.log(err);
                  GibooToast({
                    type: "failure",
                    message: `Failed to delete project`,
                    actionLabel: "",
                  });
                })
                .finally(() => {
                  navigate("/projects", { replace: true });
                  setIsDeleting(false);
                });
            }}
            color="purple"
            label="Delete"
            loading={isDeleting}
          />
        }
      />
      <div className="container mx-auto">
        <div>
          <p>Project Name</p>
          <div className="mt-3 inline-flex items-center gap-2 ">
            <TextInput
              id="projectName"
              className="!h-[47px] !bg-gray-400 !bg-inherit !text-gray-500"
              wrapperClass="!w-[480px] !h-[47px]"
              value={projectName}
              placeholder="Project name"
              handleOnChange={(e) => {
                setProjectName(e.target.value);
              }}
              disabled={!hasEditPermission}
            />
            {hasEditPermission && projectData.name !== projectName && (
              <PrimaryButton
                size="md"
                id={"btn-next"}
                color="purple"
                label="Update"
                handleOnClick={() => {
                  update({ name: projectName })
                    .then((res) => {
                      GibooToast({
                        type: "success",
                        message: `Project name changed successfully.`,
                      });
                    })
                    .catch(() => {
                      GibooToast({
                        type: "failure",
                        message: `We apologize for the inconvenience. Please try again.`,
                      });
                    });
                }}
              />
            )}
          </div>
        </div>
        {/* <div>{renderProjectOwner()}</div> */}
        <div className="my-10 flex flex-col gap-4">
          <p>Access control</p>
          {renderAccess()}
        </div>
        <PlainButton
          className="w-fit !px-0 !text-sm"
          id="btn-delete"
          disabled={!hasEditPermission}
          handleOnClick={() => setShowConfirm(true)}
          label={"Delete this project"}
          leftIconClass={<img src={IMG_TRASH} className="h-5 w-5" alt="icon" />}
        />
      </div>
    </>
  );
}
export default ProjectSetting;

function UserEmail({ id }: { id: string }) {
  const { data, isLoading } = useUserDetail(id);
  return (
    <>
      <div
        className={classNames(
          "loading-light-shimmer",
          isLoading ? "loading-light-shimmer-on" : "",
          "grid h-[25px] w-fit place-items-center   px-[10px] text-xs text-gray-900 ",
        )}
      >
        <div className={classNames("loading-light-shimmer-child min-w-[50px]")}>
          <span className="inline-flex items-center gap-2">{data?.email || "None"}</span>
        </div>
      </div>
    </>
  );
}
