import classNames from "classnames";
import * as React from "react";
import "./css/button.scss";
import Spinner from "./Spinner";

interface PlainButtonProps {
  id: string;
  handleOnClick: (e: React.FormEvent) => void;
  className?: string;
  color?: "purple" | "red" | "yellow" | "green" | "blue" | "gray";
  leftIconClass?: React.ReactNode | string;
  rightIconClass?: React.ReactNode | string;
  label?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  outline?: boolean;
  skipTab?: boolean;
  underline?: boolean;
  loading?: boolean;
}

function PlainButton({ leftIconClass, rightIconClass, ...props }: PlainButtonProps) {
  const className = classNames(
    "giboo-plain-btn flex prevent-select items-center hover:font-semibold",
    props.className,
    props.color ? `giboo-plain-btn-${props.color}` : "",
    {
      "no-outline": !props.outline,
    },
  );
  return (
    <button
      id={props.id}
      disabled={props.disabled}
      onClick={(e) => {
        if (props.loading) return;
        e.currentTarget.blur();
        props.handleOnClick(e);
      }}
      onFocus={(e) => {
        e.preventDefault();
      }}
      className={className}
      style={props.style}
      tabIndex={props.skipTab ? -1 : undefined}
    >
      {props.loading && <Spinner size="sm" />}
      {leftIconClass && typeof leftIconClass === "string" ? (
        <i className={leftIconClass}></i>
      ) : (
        leftIconClass
      )}
      {props.label && (
        <span
          className={classNames("giboo-plain-btn-label hover:text-semibold", {
            underline: props.underline,
          })}
        >
          {props.label}
        </span>
      )}
      {rightIconClass && typeof rightIconClass === "string" ? (
        <i className={rightIconClass}></i>
      ) : (
        rightIconClass
      )}
    </button>
  );
}

export default PlainButton;
