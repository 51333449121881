import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
//images
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useProject from "../hooks/project/useProject";
import useSubtasksByProject from "../hooks/project/useSubtasksByProject";
import useSubtask from "../hooks/project/useSubtask";
import useSubtaskNotes from "../hooks/project/useSubtaskNotes";
import ProjectAllActivityPopup from "./ProjectAllActivityPopup";
import PlainButton from "./PlainButton";
import TestFundraisingStatusAll from "./TestFundraisingStatusAll";
import TestFundraisingActivities from "./TestFundraisingActivities";
import GibooAiTaskAssistant from "./GibooAiTaskAssistant";
import ProjectDashboardInfo from "./ProjectDashboardInfo";

export default function ProjectManage() {
  const navigate = useNavigate();
  const { id: project_id } = useParams();
  const [searchParams] = useSearchParams();
  const { hasEditPermission } = useProject(project_id);
  const { data: subtasks, revalidate } = useSubtasksByProject(project_id);
  const { data: selectedSubTask } = useSubtask(searchParams.get("subtask_id") || undefined);
  const { data: selectedNote } = useSubtaskNotes(searchParams.get("note_id") || undefined);
  // const { value: toggle, update: updateToggle } = useUserDictionary<boolean>(
  //   "PROJECT_YOUR_TASK_TOGGLE",
  //   true,
  // );
  const toggle = sessionStorage.getItem("PROJECT_YOUR_TASK_TOGGLE") === "close" ? false : true;
  const updateToggle = useCallback((flag: boolean) => {
    sessionStorage.setItem("PROJECT_YOUR_TASK_TOGGLE", flag ? "open" : "close");
  }, []);
  const [open, setOpen] = useState<boolean>(toggle);
  // useEffect(() => {
  //   setOpen(toggle);
  // }, [toggle]);
  const [showAllNotes, setShowAllNotes] = useState(false);
  useEffect(() => {
    const subtask_id = searchParams.get("subtask_id") || undefined;
    const note_id = searchParams.get("note_id") || undefined;
    if (subtask_id && note_id && selectedSubTask && selectedNote) {
      setShowAllNotes(true);
    }
  }, [searchParams, selectedSubTask, selectedNote]);
  const renderHeader = () => {
    return (
      <>
        {showAllNotes && (
          <ProjectAllActivityPopup
            project_id={project_id}
            task_id={selectedSubTask._id}
            onClose={() => {
              navigate(location.pathname, { replace: true });
              setShowAllNotes(false);
            }}
            show={showAllNotes}
            title={selectedSubTask.name}
          />
        )}
        <div
          className={classNames(
            "inline-flex w-full items-center justify-between border-b px-4 py-3",
            !open && "!border-0",
          )}
        >
          <div className="inline-flex items-center gap-3">
            <span>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="30" height="30" rx="3.65854" fill="#F3EAF9" />
                <path
                  d="M13.8566 17.3914L11.8044 15.3392L10.9984 16.1452L13.8566 19.0034L19.0015 13.8586L18.1954 13.0469L13.8566 17.3914Z"
                  fill="#C25DBE"
                />
                <path
                  d="M20.1448 8.71494H18.4299V8.14329C18.4299 7.84007 18.3094 7.54927 18.095 7.33486C17.8806 7.12045 17.5898 7 17.2866 7H12.7134C12.4102 7 12.1194 7.12045 11.905 7.33486C11.6906 7.54927 11.5701 7.84007 11.5701 8.14329V8.71494H9.85521C9.55199 8.71494 9.26119 8.83539 9.04678 9.0498C8.83237 9.26421 8.71191 9.55501 8.71191 9.85823V21.8628C8.71191 22.166 8.83237 22.4568 9.04678 22.6712C9.26119 22.8856 9.55199 23.0061 9.85521 23.0061H20.1448C20.4481 23.0061 20.7389 22.8856 20.9533 22.6712C21.1677 22.4568 21.2881 22.166 21.2881 21.8628V9.85823C21.2881 9.55501 21.1677 9.26421 20.9533 9.0498C20.7389 8.83539 20.4481 8.71494 20.1448 8.71494ZM12.7134 8.14329H17.2866V10.4299H12.7134V8.14329ZM20.1448 21.8628H9.85521V9.85823H11.5701V11.5732H18.4299V9.85823H20.1448V21.8628Z"
                  fill="#C25DBE"
                />
              </svg>
            </span>
            <h6 className="font-poppins text-base font-semibold text-gray-900">Your tasks</h6>
            <PlainButton
              className="!p-0"
              id={`your-tasks-collapse`}
              label=""
              leftIconClass={classNames("mt-1", open ? " gi-angle-up" : " gi-angle-down")}
              handleOnClick={() => {
                updateToggle(!open);
                setOpen((prev) => !prev);
              }}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="flex flex-col gap-4">
      <GibooAiTaskAssistant id="project-management-page" />
      <ProjectDashboardInfo id="project-management-page" />
    </div>
  );
}
