import classNames from "classnames";
import React, { ReactElement, memo, useMemo } from "react";
import "./css/icon.scss";
import closeTag from "../assets/images/closeTag.svg";
import plusTag from "../assets/images/addTag.svg";
import Checkbox from "./checkbox/Checkbox";
import GibooTooltipWrapper from "./GibooTooltipWrapper";
import { Tooltip, Whisper } from "rsuite";
interface TagProps {
  className?: string;
  id: string;
  name: string;
  color?: "purple" | "red" | "yellow" | "green" | "blue" | "gray" | "orange";
  size?: "lg" | "md" | "sm";
  number?: number;
  onAdd?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onDelete?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  leftIconClass?: string;
  rightIconClass?: string;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  outline?: "none" | "gray" | "color";
  selected?: boolean; // in a form, user can select
  focused?: boolean; // focused in treeview
  matched?: boolean; // matched taxonomy in matching summary veiw, detail view or etc
  hover?: boolean;
  disabled?: boolean;
  isTagCount?: boolean;
  maxWidth?: string;
  isFullTagClick?: boolean;
  useCheckbox?: boolean;
  checked?: boolean;
  textTruncate?: boolean;
  tooltip?: string | ReactElement;
}

const tag: React.FC<TagProps> = ({
  className,
  id,
  name,
  color = "gray",
  size = "md",
  onDelete,
  onAdd,
  leftIconClass,
  rightIconClass,
  number,
  onClick,
  outline,
  selected,
  focused,
  matched,
  hover,
  disabled,
  maxWidth,
  checked = false,
  useCheckbox,
  isFullTagClick,
  textTruncate = false,
  tooltip,
}) => {
  const focusedColorVariants = {
    purple: "bg-purple-200 border-purple-500",
    red: "bg-red-200 border-red-500",
    yellow: "bg-yellow-200 border-yellow-500",
    green: "bg-green-200 border-green-500",
    blue: "bg-blue-200 border-blue-500",
    gray: "bg-gray-300 border-gray-500",
    orange: "bg-orange-200 border-orange-500",
  };
  const colorVariants = {
    purple: "bg-purple-50 border-purple-500",
    red: "bg-red-50 border-red-500",
    yellow: "bg-yellow-50 border-yellow-500",
    green: "bg-green-100 border-green-500",
    blue: "bg-blue-100 border-blue-500",
    gray: "bg-gray-200 border-gray-500",
    orange: "bg-orange-50 border-orange-500",
  };
  const hoverColorVariants = {
    purple: "hover:border-purple-700",
    red: "hover:border-red-700",
    yellow: "hover:border-yellow-700",
    green: "hover:border-green-700",
    blue: "hover:border-blue-700",
    gray: "hover:border-gray-700",
    orange: "hover:border-orange-700",
  };
  const outlineColorVariants = {
    purple: "bg-transparent border-purple-500",
    red: "bg-transparent border-red-500",
    yellow: "bg-transparent border-yellow-500",
    green: "bg-transparent border-green-500",
    blue: "bg-transparent border-blue-500",
    gray: "bg-transparent border-gray-500",
    orange: "bg-transparent border-orange-500",
  };
  const sizeVariants = {
    sm: "!min-h-[22px] !max-h-[22px]",
    md: "!min-h-[32px] !max-h-[32px]",
    lg: "!min-h-[48px] !max-h-[48px] pt-[6px]",
  };
  const textSizeVariants = {
    sm: "text-xs leading-5",
    md: "text-md leading-8",
    lg: "text-md leading-8",
  };
  const render = useMemo(() => {
    return (
      <span
        {...(maxWidth && { title: name })}
        id={id}
        className={classNames(
          "tag inline-flex max-w-full place-items-center whitespace-nowrap rounded px-2 align-middle !font-normal !text-gray-900",
          {
            "border border-solid": outline !== "none",
            "opacity-40": disabled,
            "cursor-pointer": onClick || isFullTagClick,
          },
          focused
            ? focusedColorVariants[color]
            : selected || matched
            ? colorVariants[color]
            : outline === "color"
            ? outlineColorVariants[color]
            : outline === "gray"
            ? "border-gray-300 bg-transparent"
            : "",
          onClick || isFullTagClick || hover ? hoverColorVariants[color] : "",
          onClick || isFullTagClick || hover
            ? "hover:font-semibold hover:shadow-[0_2px_6px_2px_rgba(0,0,0,0.15)]"
            : "",
          sizeVariants[size],
          className,
          maxWidth,
        )}
        onClick={(e) => {
          if (isFullTagClick) {
            onDelete?.(e as any);
            onAdd?.(e as any);
          } else {
            onClick?.(e);
          }
        }}
      >
        {leftIconClass && <i className={classNames("icon mr-2 align-middle", leftIconClass)} />}
        <span
          className={classNames(
            "inline-block text-ellipsis whitespace-nowrap align-middle",
            textSizeVariants[size],
            {
              "font-semibold": focused,
            },
            textTruncate && "truncate",
          )}
          style={{ width: onDelete || onAdd ? "calc(100% - 20px)" : "100%" }}
        >
          {name}
          {number && number > 1 ? <span className="font-medium">{` +${number - 1}`}</span> : null}
        </span>
        {useCheckbox ? (
          <Checkbox
            id={`checkbox-tag-${id}`}
            checked={checked}
            readOnly
            size={size === "sm" ? "sm" : "md"}
            className="ml-2"
          />
        ) : onDelete ? (
          <span
            id={`btn-delete-label-${id}`}
            className="ml-1 border-0 bg-transparent text-inherit no-underline"
            onClick={(e) => !isFullTagClick && onDelete(e)}
          >
            <i className="gi gi-x align-middle" />
          </span>
        ) : onAdd ? (
          <span
            id={`btn-add-label-${id}`}
            className="ml-1 border-0 bg-transparent text-center text-inherit no-underline"
            onClick={(e) => !isFullTagClick && onAdd(e)}
          >
            <i className="gi gi-add align-middle" />
          </span>
        ) : null}
        {rightIconClass && <i className={classNames("icon", rightIconClass)} />}
      </span>
    );
  }, [
    color,
    name,
    id,
    maxWidth,
    outline,
    disabled,
    selected,
    matched,
    isFullTagClick,
    hover,
    className,
    leftIconClass,
    focused,
    textTruncate,
    onDelete,
    onAdd,
    onClick,
    rightIconClass,
    useCheckbox,
    number,
  ]);
  return (
    <>
      {process.env.REACT_APP_DISABLE_TOOLTIP_FOR_TAG !== "TRUE" && tooltip ? (
        <Whisper
          controlId="control-id-container"
          trigger="hover"
          speaker={
            <Tooltip arrow={false} style={{ maxWidth: 240 }} className="!mt-[0px] bg-gray-600">
              {tooltip}
            </Tooltip>
          }
          placement="bottomStart"
        >
          {render}
        </Whisper>
      ) : (
        <>{render}</>
      )}
    </>
  );
};
tag.defaultProps = {
  onClick: undefined,
  onAdd: undefined,
  onDelete: undefined,
  color: "gray",
  size: "md",
  outline: "color",
  selected: false,
  focused: false,
  matched: false,
  disabled: false,
};

const Tag = memo(tag);
export default Tag;
