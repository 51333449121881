import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
import { Onboarding } from "../../app/onboarding";
import { IOrgVerificationRequestStatus } from "../../types/org";
import { useCallback, useEffect, useState } from "react";

interface OrgVerificationStatus {
  data: OrgVerification[];
  size: number;
  isLoading: boolean;
  isValidating: boolean;
  isSizeLoading: boolean;
  error: object;
  hasPermission?: boolean;
  revalidate: () => Promise<OrgVerification[] | undefined>;
}
export interface OrgVerification extends Onboarding {
  org_verification_requested?: IOrgVerificationRequestStatus;
  org_admin_verification_requested?: IOrgVerificationRequestStatus;
  other_verified_org?: { org_id: string; npo_id: string; created_at: string; updated_at: string };
  members?: string[];
  rejected?: boolean;
}
const emptyList: OrgVerification[] = [];
const zero = 0;
function useOrgVerificationStatus({
  limit,
  skip,
  verified,
}: {
  limit: number;
  skip: number;
  verified: 0 | 1 | -1;
}): OrgVerificationStatus {
  const { mutate } = useSWRConfig();
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const url =
    process.env.REACT_APP_API_URL +
    `/api/v2/orgs/verify/list?limit=${limit}&skip=${skip}&verified=${verified}`;
  const size_url =
    process.env.REACT_APP_API_URL + `/api/v2/orgs/verify/list/size?verified=${verified}`;
  const permission_url = process.env.REACT_APP_API_URL + `/api/v2/orgs/verify/permission`;
  const [fetchURL, setFetchURL] = useState<string>("");

  const { data, isLoading } = useSWR<OrgVerification[]>(url, fetch, swrOptionFetchOnlyOnMount);
  const {
    data: size,
    isLoading: isSizeLoading,
    error: errorSize,
    isValidating: isSizeValidating,
  } = useSWR<number>(size_url, fetch, swrOptionFetchOnlyOnMount);
  const { data: hasPermission } = useSWR<boolean>(permission_url, fetch, swrOptionFetchOnlyOnMount);
  useEffect(() => {
    setFetchURL(
      data && data.length > 0
        ? process.env.REACT_APP_API_URL +
            `/api/v2/orgs/verify/list/search?limit=${limit}&id=${data
              ?.map((d) => d._id)
              .join("&id=")}`
        : "",
    );
  }, [data]);
  const {
    data: data2,
    isLoading: isLoading2,
    error,
    isValidating,
  } = useSWR<OrgVerification[]>(fetchURL, fetch, swrOptionFetchOnlyOnMount);
  const [arrangedData, setArrangedData] = useState<OrgVerification[]>([]);
  useEffect(() => {
    setArrangedData(data?.map((d) => data2?.find((d2) => d2._id === d._id) || d) || emptyList);
  }, [data2, data]);
  const revalidate = useCallback(() => mutate<OrgVerification[]>(fetchURL), [fetchURL]);
  return {
    data: arrangedData || emptyList,
    size: size || zero,
    isLoading,
    isSizeLoading,
    error,
    isValidating,
    hasPermission,
    revalidate,
  };
}
export default useOrgVerificationStatus;
