import { ILocation } from "../types/location";
import { FunderMemberContact } from "../services/funders.services";
import { ILogData } from "./notification";
import { SearchQuery } from "./search";

//Activity Picker Starts
enum PROJ_ACTIVITY {
  PLANNING = "Planning",
  LOI = "LOI sent",
  GRANT_APPLICATION = "Grant application submitted",
  MEETING = "Meeting",
  CALL = "Call",
  EMAIL = "Email",
  REPORTING = "Reporting",
}
const PROJ_ACTIVITY_COLOR = {
  PLANNING: "#ececec",
  LOI: "#F7D9C4",
  GRANT_APPLICATION: "#BCD4E6",
  MEETING: "#B8E0D2",
  CALL: "#EAC4D5",
  EMAIL: "#F1FFC4",
  REPORTING: "#BAF2E9",
  NONE: "#DEDEDE",
};
export const reprProjectInteractionType = (type: string): string => {
  return type;
};
export const getProjectInteractionTypeColor = (type: string): string => {
  const enumKeys = Object.keys(PROJ_ACTIVITY) as Array<keyof typeof PROJ_ACTIVITY>;
  const enumKeyValuePairs = enumKeys.map((enumKey) => ({
    key: enumKey,
    value: PROJ_ACTIVITY[enumKey],
  }));
  return PROJ_ACTIVITY_COLOR[enumKeyValuePairs.find((i) => i.value === type)?.key || "NONE"];
};
export interface IProjectUpdateRequest {
  name?: string;
  image_key?: string;
  project_type?: string;
  project_start?: string;
  project_end?: string;
  rolling?: boolean;
  fund_to_raise?: number;
  summary?: string;
  focus_area?: string[];
  beneficiary?: string[];
  program?: string[];
  service_loc?: ILocation[];
  problem_desc?: string;
  outcome_desc?: string;
  activity_desc?: string;
  beneficiary_desc?: string;
  development_stage?: string[];
  asset_min?: number;
  asset_max?: number;
  tagging_started?: boolean;
  completed?: boolean;
  private?: boolean;
  hide_fund?: boolean;
  permission?: number;

  project_timeline?: number;
  service_area_desc?: string;
  impact_desc?: string;
  output_desc?: string;
  budget_desc?: string;
  collaboration_desc?: string;
  measurement_desc?: string;
}

export interface IProjectCreateRequest extends IProjectUpdateRequest {
  name: string;
  org_id: string;
}

export interface IProject extends IProjectCreateRequest {
  _id: string;
  created_at: string;
  updated_at: string;
  name: string;
  org_id: string;
  user_id?: string;
  project_type: string;
  rolling: boolean; // to be deprecated
  fund_to_raise: number;
  summary: string;
  focus_area: string[];
  beneficiary: string[];
  program: string[];
  service_loc: ILocation[];
  problem_desc: string;
  outcome_desc: string; // to be deprecated
  activity_desc: string;
  beneficiary_desc: string;
  development_stage: string[]; // to be deprecated
  tagging_started: boolean; // to be deprecated
  completed: boolean;
  my_role: number;
  tasks: string[];
  members: { user_id: string; role: number }[];
  target_amount?: number; // to be deprecated
  amount?: number; // to be deprecated

  project_timeline: number;
  service_area_desc: string;
  impact_desc: string;
  output_desc: string;
  budget_desc: string;
  collaboration_desc: string;
  measurement_desc: string;
}
export interface IProjectLetterUpdateRequest {
  type?: string;
  contact?: FunderMemberContact[];
  send_to?: string[];
  cc?: string[];
  bcc?: string[];
  subject?: string;
  content?: string;
  sender?: string;
}
export interface IProjectLetterCreateRequest extends IProjectLetterUpdateRequest {
  type: string;
  task_id: string;
  contact: FunderMemberContact[];
  send_to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  content: string;
  sender: string;
}
export interface IProjectLetter {
  _id: string;
  type: string;
  created_at: string;
  updated_at: string;
  subtask_id: string;
  contact: FunderMemberContact[];
  send_to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  content: string;
  sender: string;
}

export interface IProjectInteractionUpdateRequest {
  content?: string;
  title?: string;
  status?: string;
  user_id?: string; //owner
  document_id?: string;
}
export interface IProjectInteractionCreateRequest extends IProjectInteractionUpdateRequest {
  project_id: string;
  task_id?: string;
  content: string;
  title: string;
}
export interface IProjectInteraction extends IProjectInteractionCreateRequest {
  _id: string;
  project_id: string;
  type: string;
  task_id?: string;
  user_id?: string;
  created_at: string;
  updated_at: string;
  data: ILogData[];
}
export interface IProjectInteractionWithProject extends IProjectInteraction {
  project_id: string;
  project_name: string;
}

export interface IProjectTaskUpdateRequest {
  status?: string;
  amount?: number;
  target_amount?: number;
  users?: string[];
  inactive?: boolean;
  search_query?: SearchQuery;
}

export interface IProjectTaskCreateRequest extends IProjectTaskUpdateRequest {
  project_id: string;
  target_id: string;
  target_type: 0 | 1 | 2;
}
export interface IProjectTask extends IProjectTaskCreateRequest {
  _id: string;
  created_at: string;
  updated_at: string;
  status: string;
  amount: number;
  target_amount: number;
  users: string[];
  inactive: boolean;
}

export interface IProjectLetterInformationUpdateRequest {
  contact?: FunderMemberContact[];
  past_grants?: string[];
  activity?: string;
  approach?: string;
  outcome?: string;
  budget?: string;
  sender?: string;
  participants?: string[];
  collaboration?: string;
  additional?: string;

  prev_letter?: string;
  send_date?: string;
  grant_date?: string;
  grant_amount?: number;
  grant_desc?: string;
  donation_date?: string;
  donation?: string;
}
export interface IProjectLetterInformation extends IProjectLetterInformationUpdateRequest {
  task_id: string;
  updated_at: string;
  created_at: string;
}

export interface IProjectInteractionCommentUpdateRequest {
  content?: string;
  mention?: string[];
}

export interface IProjectInteractionCommentCreateRequest
  extends IProjectInteractionCommentUpdateRequest {
  content: string;
  interaction_id: string;
}

export interface IProjectInteractionComment extends IProjectInteractionCommentCreateRequest {
  _id: string;
  user_id?: string;
  created_at: string;
  updated_at: string;
  mention: string[];
}

export interface IProjectSubtaskUpdateRequest {
  users?: string[];
  name?: string;
  content?: string;
  status?: string;
  due_date?: string;
}

export interface IProjectSubtaskCreateRequest extends IProjectSubtaskUpdateRequest {
  name: string;
  org_id: string;
  project_id?: string;
  task_id?: string;
  content?: string;
}

export interface IProjectSubtask extends IProjectSubtaskCreateRequest {
  _id: string;
  user_id?: string;
  created_at: string;
  updated_at: string;
  status: string;
  users: string[];
  project_id?: string;
  latest_note_id?: string;
  letter_id?: string;
  document_id?: string;
  type?: number;
  document_type?: number;
}

export interface IProjectSubtaskNoteUpdateRequest {
  content?: string;
  mention?: string[];
}

export interface IProjectSubtaskNoteCreateRequest extends IProjectSubtaskNoteUpdateRequest {
  subtask_id: string;
  content: string;
}

export interface IProjectSubtaskNote extends IProjectSubtaskNoteCreateRequest {
  _id: string;
  user_id?: string;
  created_at: string;
  updated_at: string;
  mention: string[];
}

export interface IProjectContribution {
  user_id: string;
  count: number;
}

export interface IProjectTaskCommentUpdateRequest {
  content?: string;
}

export interface IProjectTaskCommentCreateRequest extends IProjectTaskCommentUpdateRequest {
  content: string;
}

export interface IProjectTaskComment extends IProjectTaskCommentCreateRequest {
  _id: string;
  task_id: string;
  user_id?: string;
  created_at: string;
  updated_at: string;
}
