import { useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import { User } from "../app/userSlice";
import useSWR from "swr";
import classNames from "classnames";
import axios from "axios";
import config from "../api";
import { swrOptionDedupling5mins } from "../types/swr";

export default function UserName({ userId }: { userId?: string }) {
  const [user] = useUser();
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const { data, isLoading } = useSWR<User>(
    userId ? process.env.REACT_APP_API_URL + `/api/v2/users/${userId}` : null,
    fetch,
    swrOptionDedupling5mins,
  );
  const profile = userId === user?._id ? user : data;
  const userName = `${profile && profile.firstname ? profile.firstname : ""}${
    profile && profile.lastname ? ` ${profile.lastname}` : ""
  }`;

  return <>{userName}</>;
}
