import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { useAppDispatch } from "../../app/store";
import { GrantLog, GrantLogCreateRequest, GrantLogUpdateRequest } from "../../types/grant";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface GrantLogsState {
  data: GrantLog[] | undefined;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<GrantLog[] | undefined>;
}
const createGrantLog = async (req: GrantLogCreateRequest) => {
  return axios
    .post(process.env.REACT_APP_API_URL + `/api/v2/grants/log`, req, config)
    .then((res) => res.data as string);
};
const updateGrantLog = async (id: string, req: GrantLogUpdateRequest) => {
  if (!id) return new Promise<GrantLog>((resolve, reject) => reject());
  return axios
    .put(process.env.REACT_APP_API_URL + `/api/v2/grants/log/${id}`, req, config)
    .then((res) => res.data as GrantLog);
};
const deleteGrantLog = async (id: string) => {
  if (!id) return new Promise<boolean>((resolve, reject) => reject());
  return axios
    .delete(process.env.REACT_APP_API_URL + `/api/v2/grants/log/${id}`, config)
    .then((res) => res.data as boolean);
};
function useGrantLogs(grant_id?: string, key?: string): GrantLogsState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/grants/logs?grant_id=${grant_id}&key=${key}`;
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<GrantLog[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as GrantLog[]);
  };
  const { data, isLoading, error, isValidating } = useSWR<GrantLog[]>(
    url,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<GrantLog[]>(url), [url]);
  return {
    data: data,
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export { createGrantLog, updateGrantLog, deleteGrantLog };
export default useGrantLogs;
