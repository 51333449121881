import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import { toUsdLong, toUsdShort } from "../../../../utils/formatHelper";
import useNPOIRSProfile, { Operating } from "../../../../hooks/useNPOIRSProfile";
import NoGraph from "../../../donor-detail-view/components/NoGraph";
import RenderIncrOrDecr from "../../../donor-detail-view/components/RenderIncrOrDecr";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import { Divider } from "rsuite";
import IncreaseTag from "../../financials/component/IncreaseTag";
import DecreaseTag from "../../financials/component/DecreaseTag";
import Spacer from "../../../../components/Spacer";
import FinancialChartLoader from "../../financials/component/FinancialChartLoader";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import PercentageTag from "../../../../components/PercentageTag";
import AssessmentResult from "../../../../components/AssessmentResult";
import PopupModal from "../../../../components/tailwind/PopupModal";
import { RevenueExpenseStreamInfo } from "../../financials/pages/RevenueExpenseStream";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { NpoTitle } from "./NpoDiversityEthnicity";
import FinancialPredictionInfo from "./FinancialPredictionInfo";
interface Iprops {
  npo_id?: string;
  org_id?: string;
  allowEdit?: boolean;
  isPrivateView?: boolean;
  compact?: boolean;
  recomendation?: boolean;
  showStats?: boolean;
  showInfo?: boolean;
  hideTitle?: boolean;
  children?: ReactNode;
  showChartLoader?: boolean;
}
function NpoFinancialRevenueExpenses({
  npo_id,
  org_id,
  allowEdit,
  isPrivateView,
  compact,
  recomendation,
  showInfo = true,
  showStats = true,
  hideTitle = false,
  showChartLoader = true,
  children,
}: Iprops) {
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { operatingInfo, operating, isLoading, latest, beforeLatest } = useNPOIRSProfile(npo_id);
  const { data: netProfit, isLoading: isNetProfitLoading } = useFinancialRecommendedActions(
    !isPrivateView && loading ? undefined : !isPrivateView && !org_id ? npo_id : org_id,
    "net_profit",
    !isPrivateView && !org_id,
  );
  const renderTotalRevenue = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Total revenue</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={operatingInfo?.diff_revenue}
            beforeLatest={beforeLatest?.revenue}
            latest={latest?.revenue}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {netProfit?.Revenue?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">{netProfit?.Revenue?.recommendations || ""}</div>
          </div>
        )}
      </div>
    );
  };
  const renderTotalExpenses = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Total expenses</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={operatingInfo?.diff_expenses}
            beforeLatest={beforeLatest?.expenses}
            latest={latest?.expenses}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {netProfit?.Expenses?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              <div className="font-poppins text-sm">
                {netProfit?.Expenses?.recommendations || ""}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderNetProfit = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Net profit</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={operatingInfo?.diff_net_profit}
            beforeLatest={beforeLatest?.net_profit}
            latest={latest?.net_profit}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {netProfit?.Profit?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              <div className="font-poppins text-sm">{netProfit?.Profit?.recommendations || ""}</div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const chartLoading = recomendation ? loading : isLoading;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-5"
        showHeader={false}
        showActionButtons={false}
      >
        <RevenueExpenseStreamInfo
          handleClose={() => {
            setShowInfoModal(false);
          }}
        />
      </PopupModal>
      <div
        className={classNames(
          "flex min-h-[100px] flex-col ",
          recomendation && "rounded border border-gray-300 bg-white p-5",
        )}
      >
        {!hideTitle && (
          <NpoTitle
            title="Revenue & expense stream"
            recomendation={recomendation}
            setShowInfoModal={setShowInfoModal}
          />
        )}
        {showChartLoader && chartLoading ? (
          <FinancialChartLoader title="Analyzing organization’s Revenue & expense stream." />
        ) : (
          <>
            {/* chart */}
            <div className="mx-auto my-5">
              <RevenueExpensesChart
                operating={operating}
                npo_id={npo_id}
                wrapperClass={""}
                compact
              />
            </div>
            {/* info */}
            {showInfo &&
              (isNetProfitLoading || loading ? (
                <MemoizedLoadingRecommendedAction loading={loading || isNetProfitLoading} short />
              ) : (
                <AssessmentResult content={netProfit?.Summary || ""} />
              ))}
            {recomendation ? <Divider className="my-[40px]" /> : <Spacer height="20px" />}
            {/* stats */}
            {showStats &&
              (isNetProfitLoading || loading ? (
                <MemoizedLoadingRecommendedAction loading={loading || isNetProfitLoading} />
              ) : (
                <div className="bg-gray-50 px-6 py-5">
                  <h6 className="mb-5 font-poppins text-base font-semibold text-black">Stats</h6>
                  <div className="flex flex-col gap-10">
                    {renderTotalRevenue()}
                    {renderTotalExpenses()}
                    {renderNetProfit()}
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      {!chartLoading && children && children}
    </>
  );
}
export default NpoFinancialRevenueExpenses;

export function RevenueExpensesChart({
  npo_id,
  operating,
  wrapperClass,
  compact,
}: {
  operating?: Operating[];
  npo_id?: string;
  wrapperClass?: string;
  compact?: boolean;
}) {
  const renderChart2 = () => {
    const foreCastCount = operating?.reduce((count, item) => {
      return item.is_predicted ? count + 1 : count;
    }, 0);
    const estimatedData = operating?.filter((i) => i.is_interpolated);
    const predictedData = operating?.filter((i) => i.is_predicted);
    const chartSeries: ApexAxisChartSeries = [
      {
        name: "revenue",
        type: "line",
        data: operating?.map((i) => i.revenue) || [],
        color: "#429BCA",
      },
      {
        name: "expenses",
        type: "line",
        data: operating?.map((i) => i.expenses) || [],
        color: "#AB6790",
      },
      {
        name: "net_profit",
        type: "line",
        data: operating?.map((i) => i.net_profit) || [],
        color: "#938E12",
      },
    ];
    const chartOptions: ApexOptions = {
      chart: {
        toolbar: { show: false },
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => toUsdShort(Number(val), 1),
        distributed: true,
        offsetY: -8,
        offsetX: -5,
        textAnchor: "start",
        background: { enabled: false },
        style: {
          colors: ["#9B9B9B"],
          fontSize: "10px",
          fontFamily: "poppins",
          fontWeight: "400px",
        },
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 4,
        borderColor: "#DEDEDE",
        row: {
          colors: ["transparent"],
          opacity: 1,
        },
      },
      xaxis: {
        categories: operating?.map((i) => i.tax_year) || [],
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "poppins",
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#DEDEDE",
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "poppins",
            fontWeight: 400,
          },
          formatter(val, opts) {
            return toUsdLong(val);
          },
        },
      },

      stroke: {
        curve: "monotoneCubic",
        width: 2,
        colors: ["#429BCA", "#AB6790", "#938E12"],
      },
      forecastDataPoints: {
        count: foreCastCount,
        strokeWidth: undefined,
        dashArray: 4,
      },
      markers: {
        size: 4.5,
        shape: "circle",
        hover: {
          size: 4.5,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const data = operating?.[dataPointIndex];
          const renderTitle = (value: string) => {
            return '<span class="font-poppins font-normal text-gray-700">' + value + "</span>";
          };
          const renderValue = (value: string) => {
            return '<span class="font-poppins font-semibold text-gray-900">' + value + "</span>";
          };

          if (data) {
            return (
              '<div class="giboo-box flex flex-col gap-1">' +
              '<p class="font-poppins font-semibold text-gray-900">' +
              (data ? data.tax_year : "") +
              (data && data.is_predicted
                ? " (Predicted)"
                : data && data.is_interpolated
                ? " (Estimated)"
                : "") +
              "</p>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-[#429BCA]">' +
              '<span class="absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-[#429BCA]"></span>' +
              "</span>" +
              renderTitle("Total revenue:") +
              renderValue(toUsdLong(data && data.revenue ? data.revenue : 0)) +
              "</div>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-[#AB6790]">' +
              '<span class="absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-[#AB6790]"></span>' +
              "</span>" +
              renderTitle("Total expenses:") +
              renderValue(toUsdLong(data && data.expenses ? data.expenses : 0)) +
              "</div>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-[#938E12]">' +
              '<span class="absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-[#938E12]"></span>' +
              "</span>" +
              renderTitle("Net profit:") +
              renderValue(toUsdLong(data && data.net_profit ? data.net_profit : 0)) +
              "</div>" +
              "</div>"
            );
          }
        },
      },
    };
    return (
      <>
        <Chart options={chartOptions} series={chartSeries} type="line" width="600" />
        <div className="flex items-center  justify-center gap-4">
          {estimatedData && estimatedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-purple-100 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-estimated text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Estimated years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {estimatedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
          {predictedData && predictedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-dashed border-purple-500 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-predicted text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Predicted years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {predictedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <FinancialPredictionInfo />
        </div>
      </>
    );
  };
  const renderTitle = () => {
    return (
      <div className="flex items-center justify-center gap-5">
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-blue-700">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-blue-700" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Total revenue</h6>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-[#AB6790]">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-[#AB6790]" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Total expenses</h6>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-[#938E12]">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-[#938E12]" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Net profit</h6>
        </div>
      </div>
    );
  };
  const renderLeftContent = () => {
    if (operating?.length === 0) return <NoGraph />;
    return (
      <>
        {renderTitle()}
        {/* <div className="min-h-[260px] w-[540px]">{renderChart()}</div> */}
        {renderChart2()}
      </>
    );
  };
  return (
    <>
      <div className="container mx-auto flex min-h-[370px] items-center">
        <div
          className={classNames("flex min-h-[345px] w-full", compact ? "flex-col" : "flex-row ")}
        >
          {renderLeftContent()}
        </div>
      </div>
    </>
  );
}
