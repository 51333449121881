import { useState } from "react";
import Button from "../../components/tailwind/Button";
import useRateLimitOverview from "../../hooks/internal_tool/useRateLimitOverview";
import { toDateString } from "../../utils/formatHelper";
import axios from "axios";
import config from "../../api";
import useLocalStorage from "../../hooks/useLocalstorage";

function RateLimitOverviewView() {
  const { data, revalidate } = useRateLimitOverview();
  const { value: closed, deleteThis } = useLocalStorage("CLOSED_WELCOME");
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  return (
    <div className="w-full p-24">
      <div className="flex flex-col gap-5 rounded bg-white p-5">
        <div className="flex justify-between">
          <p>
            Your ip address: <span className="font-semibold">{data?.ip || ""}</span>{" "}
            <span className="ml-5 rounded bg-purple-50 px-2 py-1">
              visit count: {data?.data?.length || 0}
            </span>
          </p>
          <Button
            id="btn-reset"
            size="md"
            loading={resetLoading}
            handleOnClick={() => {
              deleteThis();
              setResetLoading(true);
              axios
                .delete(process.env.REACT_APP_API_URL + `/api/v2/rate/ip`, config)
                .then(() => revalidate())
                .finally(() => setResetLoading(false));
            }}
            label="reset"
          />
        </div>
        <div className="flex flex-col gap-2 rounded bg-gray-50 p-3">
          {data?.data
            ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            .map((d) => (
              <p key={d.created_at} className="text-gray-700">
                <span>{toDateString(d.created_at, false, true)}</span> <span>{d.page}</span>
              </p>
            ))}
        </div>
      </div>
    </div>
  );
}

export default RateLimitOverviewView;
