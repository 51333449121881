import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../api";

function VersionView() {
  const [isLoading, setLoading] = useState(true);
  const [beVersion, setBeVersion] = useState({
    git_revision: "",
    build_date: "",
  });

  useEffect(() => {
    const getVersion = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + "/api/v1/version", config);
        // console.log("ok", response);
        setBeVersion(response.data);
      } catch (error: unknown) {
        // console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    if (isLoading) {
      getVersion();
    }
  }, [setBeVersion, setLoading, isLoading]);

  return (
    <>
      <p>Frontend hash: {process.env.REACT_APP_GIT_REVISION || "n/a"}</p>
      <p>Frontend build date: {process.env.REACT_APP_BUILD_DATE || "n/a"}</p>
      <p>Backend hash: {beVersion.git_revision}</p>
      <p>Backend build date: {beVersion.build_date}</p>
    </>
  );
}

export default VersionView;
