import { useCallback } from "react";
import { IProjectInteractionWithProject } from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface ProjectInteractionState {
  data: IProjectInteractionWithProject[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IProjectInteractionWithProject[] | undefined>;
}
function useInteractionsOtherProject(task_id?: string): ProjectInteractionState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/interactions/others/${task_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectInteractionWithProject[]>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => res.data as IProjectInteractionWithProject[])
      .then((res) =>
        res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
      );
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectInteractionWithProject[]>(
    task_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<IProjectInteractionWithProject[]>(url), [url]);
  return {
    data: data || [],
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useInteractionsOtherProject;
