import React from "react";
import classNames from "classnames";
import ICON_CROWN from "../assets/images/crown.svg";
import { NPOGrantGroupedByFunderAndYear, NPOGrantStatistic } from "../types/npo";
import { toUsdLong } from "../utils/formatHelper";
import { useNavigate } from "react-router-dom";
interface PastAwardCardInterface {
  data: NPOGrantGroupedByFunderAndYear;
  wrapperClass?: string;
  year: string;
  topSupporter?: boolean;
}
function PastAwardCard({ data, wrapperClass, year, topSupporter }: PastAwardCardInterface) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={classNames(
          "flex min-h-fit w-full flex-col rounded border border-gray-200 bg-white p-4",
          wrapperClass,
        )}
      >
        <div className="inline-flex items-center justify-between gap-2">
          <div className="flex items-center gap-2 truncate">
            <h4
              title={data?.donor_name}
              className="cursor-pointer truncate font-poppins text-base font-semibold text-gray-900 hover:text-purple-500"
              onClick={() => {
                navigate(`/donors/${data.donor_id}`);
              }}
            >
              {data?.donor_name}
            </h4>
            {topSupporter && (
              <div className="inline-flex min-w-fit items-center gap-2 rounded bg-yellow-100 px-2 py-1">
                <img src={ICON_CROWN} className="h-5 w-5" />
                <h5 className="font-poppins text-xs !font-light text-black">Top funder </h5>
              </div>
            )}
          </div>
        </div>
        <div className="mt-2 flex items-center">
          {/* <h5 className="font-poppins text-sm font-light text-gray-700">
            Awarded years: <span className="font-semibold text-purple-500">{year}</span>
          </h5>
          <div className="mx-2 h-4 w-[1px] bg-gray-300" /> */}
          <h5 className="font-poppins text-sm font-light text-gray-700">
            Total contribution amount:{" "}
            <span className="font-semibold text-purple-500">{toUsdLong(data?.grant_amount)}</span>
          </h5>
        </div>
        <h5 className="mt-1 font-poppins text-sm font-light text-gray-700">
          Purpose: <span className="font-semibold text-purple-500">{data?.grant_desc}</span>
        </h5>
      </div>
    </>
  );
}

export default PastAwardCard;
