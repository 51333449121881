import { ReactNode, useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import { User } from "../app/userSlice";
import useSWR from "swr";
import classNames from "classnames";
import axios from "axios";
import config from "../api";
import { sendOrgInvitation } from "../services/org.services";
import Spinner from "./Spinner";
import GibooToast from "./GibooToast";
import useTotalOrganizationMember from "../hooks/useTotalOrganizationMember";
import useOnboardingData from "../hooks/useOnboarding";
import { getTotalOrganizationMembersId } from "../hooks/useTotalOrganizationMembersData";
import usePhotoUrl from "../hooks/usePhotoUrl";
import { getRandomColor } from "../utils/formatHelper";
import { swrOptionDedupling5mins } from "../types/swr";

export default function UserProfile({
  userId,
  showName = true,
  showEmail = false,
  pending = false,
  size = 24,
  wrapperStyle = {},
  wrapperClassname = "",
  profileImageClass,
  role = 1,
  textSize = "text-xs",
  renderName,
  ...props
}: {
  userId?: string;
  showName?: boolean;
  showEmail?: boolean;
  size?: 12 | 16 | 20 | 24 | 44;
  wrapperClassname?: string;
  wrapperStyle?: object;
  profileImageClass?: string;
  pending?: boolean;
  org_id?: string;
  role?: number;
  textSize?: string;
  renderName?: (user: User | undefined) => JSX.Element;
}) {
  const [user] = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const sizeVariants = {
    12: "h-[12px] w-[12px] min-w-[12px] min-h-[12px]",
    16: "h-[17px] w-[17px] min-w-[17px] min-h-[17px]",
    20: "h-[20px] w-[20px] min-w-[20px] min-h-[20px]",
    24: "h-[24px] w-[24px] min-w-[24px] min-h-[24px]",
    44: "h-[44px] w-[44px] min-w-[44px] min-h-[44px]",
  };
  const textSizeVariants = {
    12: "text-[10px]",
    16: "text-[10px]",
    20: "text-xs",
    24: "text-xs",
    44: "text-sm",
    28: "text-base",
  };
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const { data, isLoading } = useSWR<User>(
    userId ? process.env.REACT_APP_API_URL + `/api/v2/users/${userId}` : null,
    fetch,
    swrOptionDedupling5mins,
  );
  const profile = userId === user?._id ? user : data;
  const userName = `${profile && profile.firstname ? profile.firstname : ""} ${
    profile && profile.lastname ? profile.lastname : ""
  }`;
  const userEmail = profile?.email || "";
  const [onboardingData] = useOnboardingData();
  const { data: orgMember } = useTotalOrganizationMember(
    onboardingData._id,
    getTotalOrganizationMembersId({ _id: userId || "", type: "user" }),
  );
  const { data: photoUrl } = usePhotoUrl(orgMember?.photo_object_key);
  const renderProfile = (size: 12 | 16 | 20 | 24 | 44 = 12) => {
    const first =
      profile && !pending && profile.firstname && profile.firstname.length > 0
        ? profile.firstname[0].toUpperCase()
        : "";
    const second =
      profile && !pending && profile.lastname && profile.lastname.length > 0
        ? profile.lastname[0].toUpperCase()
        : "";
    return !pending && orgMember && photoUrl ? (
      <img
        className={classNames(
          "prevent-select rounded-full bg-purple-500 text-white",
          sizeVariants[size],
        )}
        src={photoUrl}
      />
    ) : !pending &&
      orgMember &&
      orgMember.idp_google_detail &&
      orgMember.idp_google_detail.picture ? (
      <img
        className={classNames(
          "prevent-select rounded-full bg-purple-500 text-white",
          sizeVariants[size],
        )}
        src={orgMember.idp_google_detail.picture}
      />
    ) : profile && !pending && profile.idp_google_detail && profile.idp_google_detail.picture ? (
      <img
        className={classNames(
          "prevent-select rounded-full bg-purple-500 text-white",
          sizeVariants[size],
        )}
        src={profile.idp_google_detail.picture}
      />
    ) : (
      <div
        style={{
          backgroundColor: getRandomColor(profile?.firstname || "", profile?.lastname || ""),
        }}
        className={classNames(
          "prevent-select flex items-center justify-center rounded-full p-1 text-white",
          sizeVariants[size],
          profileImageClass,
        )}
      >
        <h4 className={classNames("text-center", textSizeVariants[size])}>{`${first}${second}`}</h4>
      </div>
    );
  };
  if (renderName) {
    return renderName(profile);
  }
  return (
    <div
      className={classNames(
        "flex h-full w-fit items-center",
        wrapperClassname,
        showName || showEmail ? "gap-2" : "gap-0",
      )}
      style={wrapperStyle}
    >
      {renderProfile(size)}
      <span>
        {showName && (
          <div className="w-fit">
            <h5
              title={userName}
              className={classNames(
                "giboo-nav-label  prevent-select m-0 overflow-hidden text-ellipsis whitespace-nowrap font-normal text-black",
                textSize,
              )}
            >
              {userName}
            </h5>
          </div>
        )}
        {showEmail && (
          <div className="w-fit">
            <h6
              title={userEmail}
              className="giboo-nav-label  prevent-select m-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs font-normal text-gray-700"
            >
              {userEmail}
            </h6>
          </div>
        )}
        {pending && props.org_id && userEmail && (
          <div className="flex items-center gap-1 text-gray-600">
            <h6>Pending invitation • </h6>
            <div
              className="flex cursor-pointer items-center gap-1 text-xs text-gray-600 hover:text-gray-900 "
              onClick={() => {
                if (!props.org_id || !userEmail || loading) return;
                setLoading(true);
                sendOrgInvitation(props.org_id, userEmail, role)
                  .then(() => {
                    GibooToast({
                      type: "success",
                      message: `Email successfully sent.`,
                    });
                  })
                  .catch((e) => {
                    GibooToast({
                      type: "failure",
                      message: e.response?.data?.detail || `Failed to send email.`,
                    });
                  })
                  .finally(() => setLoading(false));
              }}
            >
              {loading && <Spinner color="gray" size="sm" />}
              <i className="gi gi-mail" />
              <div id="btn-re-send" className="">
                Resend email
              </div>
            </div>
          </div>
        )}
      </span>
    </div>
  );
}
