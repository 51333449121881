import axios from "axios";
import useSWR, { useSWRConfig } from "swr";
import config from "../api";
import { useCallback, useEffect, useState } from "react";
import { swrOptionDedupling5mins } from "../types/swr";
import { differenceInDays, differenceInHours, differenceInMilliseconds } from "date-fns";
import useUser from "./useUser";
import useLocalStorage from "./useLocalstorage";
import { reprTimeDistance } from "../utils/formatHelper";
import GibooToast from "../components/GibooToast";

interface useAskEmailVerificationState {
  needToAsk: boolean;
  send: () => Promise<void>;
  handleClose: () => void;
  isLoading?: boolean;
}
function useAskEmailVerification(): useAskEmailVerificationState {
  const [user] = useUser();
  const { value: closed, update: setClosed } = useLocalStorage("CLOSED_EMAIL_VERIFICATION");
  const [closeDate, setCloseDate] = useState<Date>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (closed) {
      try {
        setCloseDate(new Date(closed));
      } catch {
        setCloseDate(undefined);
      }
    } else {
      setCloseDate(undefined);
    }
  }, [closed]);
  const handleClose = useCallback(() => {
    setClosed(new Date().toISOString());
  }, [setClosed]);
  const send = useCallback(async () => {
    if (!user) return;
    setIsLoading(true);
    try {
      const req = {
        origin: window.location.origin,
        email: user.email,
        firstname: user.firstname,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v2/auth/send_email_verify",
        req,
        config,
      );
      if (response) {
        setIsLoading(false);
        GibooToast({ type: "success", message: "The email has been sent." });
      }
      // console.log(response);
    } catch (error) {
      setIsLoading(false);
      if (error) GibooToast({ type: "failure", message: "Failed to send an email." });
      // console.log(error);
    }
  }, [user, setIsLoading, GibooToast]);
  return {
    needToAsk:
      user && !user?.email_verified && (!closeDate || differenceInHours(new Date(), closed) > 23)
        ? true
        : false,
    send,
    handleClose,
    isLoading,
  };
}
export default useAskEmailVerification;
