import { useEffect, useRef, useState } from "react";
import { getTaxonomiesRelated } from "../services/taxonomy.services";
import { ITaxonomyOntology, beneficiaryId, focusAreaId, programId } from "../types/taxonomy";
import useFetch from "./useFetch";
import useThrottle from "./useThrottle";
import useSWR from "swr";
import axios from "axios";
import config from "../api";
import { swrOptionDedupling5mins } from "../types/swr";

const emptyList: string[] = [];
const useRelevantTaxonomyTotal = ({
  focusArea,
  beneficiary,
  program,
  high_threshold = false,
}: {
  focusArea?: string[];
  beneficiary?: string[];
  program?: string[];
  high_threshold?: boolean;
}): {
  isLoading: boolean;
  focusArea?: string[];
  beneficiary?: string[];
  program?: string[];
} => {
  const [focusAreaFound, setFocusAreaFound] = useState<string[]>([]);
  const [beneficiaryFound, setBeneficiaryFound] = useState<string[]>([]);
  const [programFound, setProgramFound] = useState<string[]>([]);
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<ITaxonomyOntology>((resolve, reject) => reject());
    const cut = _url.split("@");
    if (cut.length < 4) return new Promise<ITaxonomyOntology>((resolve, reject) => reject());
    const focus_area = cut[1].split("%");
    const beneficiary = cut[2].split("%");
    const program = cut[3].split("%");
    return axios
      .post(
        cut[0],
        {
          focus_area: focus_area || [],
          beneficiary: beneficiary || [],
          program: program,
          high_threshold,
        },
        config,
      )
      .then((res) => res.data as ITaxonomyOntology);
  };
  const [url, setUrl] = useState<string | null>();
  const { data, isLoading, error, isValidating } = useSWR<ITaxonomyOntology>(
    url ? url : null,
    fetch,
    swrOptionDedupling5mins,
  );
  useEffect(() => {
    if ((focusArea?.length || 0) + (beneficiary?.length || 0) + (program?.length || 0) === 0) {
      setUrl(null);
      return;
    }
    setUrl(
      process.env.REACT_APP_API_URL +
        `/api/v2/taxonomy/ontology?high_threshold=${high_threshold}@${focusArea?.join(
          "%",
        )}@${beneficiary?.join("%")}@${program?.join("%")}`,
    );
  }, [focusArea, beneficiary, program]);

  useEffect(() => {
    if (!data) {
      // setFocusAreaFound([]);
      // setBeneficiaryFound([]);
      // setProgramFound([]);
    } else {
      setFocusAreaFound(
        Object.keys(data.focus_area)
          .sort((a, b) => data.focus_area[b] - data.focus_area[a])
          .slice(0, high_threshold ? 5 : 10),
      );
      setBeneficiaryFound(
        Object.keys(data.beneficiary)
          .sort((a, b) => data.beneficiary[b] - data.beneficiary[a])
          .slice(0, high_threshold ? 5 : 10),
      );
      setProgramFound(
        Object.keys(data.program)
          .sort((a, b) => data.program[b] - data.program[a])
          .slice(0, high_threshold ? 5 : 10),
      );
    }
  }, [data]);

  return {
    isLoading,
    focusArea: url ? focusAreaFound : emptyList,
    beneficiary: url ? beneficiaryFound : emptyList,
    program: url ? programFound : emptyList,
  };
};
export default useRelevantTaxonomyTotal;
