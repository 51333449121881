import { useEffect, useState } from "react";
import useTaxonomyTagging from "./useTaxonomyTagging";
import useRelevantTaxnomy from "./useRelevantTaxnomy";
import useRelevantTaxonomyTotal from "./useRelevantTaxonomyTotal";
interface TotalTaxonomyTaggingState {
  focusArea: string[];
  beneficiary: string[];
  program: string[];
  isLoading: boolean;
}
function useTotalTaxonomyTagging(
  input: string,
  selected?: { focusArea: string[]; beneficiary: string[]; program: string[] },
  topk = 15,
): TotalTaxonomyTaggingState {
  const [focusAreaAll, setFocusAreaAll] = useState<string[]>([]);
  const [beneficiaryAll, setBeneficiaryAll] = useState<string[]>([]);
  const [programAll, setProgramAll] = useState<string[]>([]);
  const [focusAreaByMission, setFocusAreaByMission] = useState<string[]>([]);
  const [beneficiaryByMission, setBeneficiaryByMission] = useState<string[]>([]);
  const [programByMission, setProgramByMission] = useState<string[]>([]);
  const { isLoading: taggingByMissionIsLoading, ...taggingByMission } = useTaxonomyTagging(
    input || "",
    "all",
    topk,
  );
  useEffect(() => {
    setFocusAreaByMission(taggingByMission.focus_area.map((i) => i.label));
  }, [taggingByMission.focus_area]);
  useEffect(() => {
    setBeneficiaryByMission(taggingByMission.beneficiary.map((i) => i.label));
  }, [taggingByMission.beneficiary]);
  useEffect(() => {
    setProgramByMission(taggingByMission.program.map((i) => i.label));
  }, [taggingByMission.program]);

  const { focusArea, beneficiary, program, isLoading } = useRelevantTaxonomyTotal({
    focusArea: selected ? selected.focusArea : taggingByMissionIsLoading ? [] : focusAreaByMission,
    beneficiary: selected
      ? selected.beneficiary
      : taggingByMissionIsLoading
      ? []
      : beneficiaryByMission,
    program: selected ? selected.program : taggingByMissionIsLoading ? [] : programByMission,
    high_threshold: true,
  });

  useEffect(() => {
    setFocusAreaAll([
      ...(focusArea || []),
      ...(focusAreaByMission?.filter((i) => !focusArea || !focusArea.includes(i)) || []),
    ]);
  }, [focusAreaByMission, focusArea]);
  useEffect(() => {
    setBeneficiaryAll([
      ...(beneficiary || []),
      ...(beneficiaryByMission?.filter((i) => !beneficiary || !beneficiary.includes(i)) || []),
    ]);
  }, [beneficiaryByMission, beneficiary]);
  useEffect(() => {
    setProgramAll([
      ...(program || []),
      ...(programByMission?.filter((i) => !program || !program.includes(i)) || []),
    ]);
  }, [programByMission, program]);

  return {
    isLoading: isLoading || taggingByMissionIsLoading,
    focusArea: focusAreaAll,
    beneficiary: beneficiaryAll,
    program: programAll,
  };
}
export default useTotalTaxonomyTagging;
