import classNames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import { Divider, Steps } from "rsuite";
import Button from "../../../../components/tailwind/Button";
import Spacer from "../../../../components/Spacer";
import IMG_EXAMPLE from "../../../../assets/financial/financial-asset-placeholder.svg";
import useOrg from "../../../../hooks/useOrg";
import useNPOIRSProfile from "../../../../hooks/useNPOIRSProfile";
import NpoFinancialAssetsCategories, {
  AssetCategoriesChart,
} from "../../dashboard/components/NpoFinancialAssetsCategories";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import useOrgDataForNPODashboard from "../../../../hooks/useOrgDataForNPODashboard";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import CloseButton from "../../../../components/CloseButton";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";

export default function FinancialAssetsCategories({
  onBack,
  onNext,
  npo_id,
  org_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  org_id?: string;
  isBack?: boolean;
}) {
  const [currentStep, setCurrentStep] = useState<number>(isBack ? 1 : 0);
  useEffect(() => {
    if (isBack) {
      setTimeout(() => {
        scrollToElement("businessmodel-step-2");
      }, 300);
    }
  }, [isBack]);
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value >= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };
  const scrollToElement = (id: string) => {
    const element = document?.getElementById(id);
    const container = document.getElementById("financial-scroll-container");
    if (element && container) {
      const elementTop = element.getBoundingClientRect().top + container.scrollTop;
      const adjustedOffset = elementTop - 120;
      container.scrollTo({ top: adjustedOffset, behavior: "smooth" });
    }
  };
  const renderStep1 = () => {
    return (
      <FinancialAssetsCategoriesInfo>
        <div
          className={classNames(
            "flex h-[38px] items-center justify-start gap-2",
            currentStep === 0 ? "flex" : "hidden",
          )}
        >
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              if (currentStep < 1) {
                setCurrentStep(1);
                setTimeout(() => {
                  scrollToElement("businessmodel-step-2");
                }, 300);
              }
            }}
            label="See the assessment result"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
        <Spacer height="100px" />
      </FinancialAssetsCategoriesInfo>
    );
  };

  return (
    <div className="flex max-w-[760px] flex-col">
      <Steps current={currentStep} vertical>
        <Steps.Item
          title={false}
          className="!-ml-[40px] !mt-0"
          icon={currentStep > 0 ? renderDot(1) : <></>}
          description={renderStep1()}
        />
        {currentStep > 0 && (
          <Steps.Item
            icon={renderDot(2)}
            className="!-ml-[40px]"
            description={
              <Step2
                onBack={() => {
                  setCurrentStep(0);
                }}
                npo_id={npo_id}
                org_id={org_id}
                onNext={() => {
                  onNext?.();
                }}
              />
            }
          />
        )}
      </Steps>
    </div>
  );
}
const Step2 = ({
  onBack,
  onNext,
  npo_id,
  org_id,
  recomendation = true,
}: {
  onBack: () => void;
  onNext: () => void;
  npo_id?: string;
  org_id?: string;
  recomendation?: boolean;
}) => {
  const { data, update } = useOrgProfile(org_id);
  const prev = data?.skip_loading || [];
  const mxEvent = useGibooMixpanel(undefined, true);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.financial.asset.successful);
  }, []);
  return (
    <div className="mt-3 flex min-h-[600px] flex-col gap-4" id="businessmodel-step-2">
      <h6 className="text-base font-semibold text-gray-900">
        Your organization&apos;s assessment result
      </h6>
      <NpoFinancialAssetsCategories
        npo_id={npo_id}
        org_id={org_id}
        recomendation
        showChartLoader={!data.skip_loading?.includes("financial_assets_categories")}
      >
        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              data &&
                update({
                  skip_loading: [
                    ...prev.filter((p) => p !== "financial_assets_categories"),
                    "financial_assets_categories",
                  ],
                });
              onNext?.();
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </NpoFinancialAssetsCategories>
      <Spacer height="150px" />
    </div>
  );
};

export function FinancialAssetsCategoriesInfo({
  children,
  handleClose,
}: {
  children?: ReactNode;
  handleClose?: () => void;
}) {
  return (
    <div className={classNames("flex min-h-[600px] flex-col gap-4", !handleClose && "mt-3")}>
      <div className="inline-flex w-full items-center justify-between border-b border-gray-300">
        <h6 className="text-base font-semibold text-gray-900 underline underline-offset-[6px]">
          Financial assets categories
        </h6>
        {handleClose && <CloseButton onClick={() => handleClose?.()} />}
      </div>

      <div className="flex min-h-[500px] flex-col rounded border border-gray-300 bg-white p-5">
        {/* section 1 */}
        <div className="flex flex-col gap-10">
          <div>
            <h5 className="font-poppins text-base font-semibold text-black">
              What is financial assets categories?
            </h5>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800">
              Financial asset categories are classifications of various assets held by a nonprofit
              organization, each providing a distinct lens into its financial landscape.
              Understanding these categories is crucial for gaining insights into the
              organization&apos;s liquidity, operational capacity, and overall financial health.
            </h6>
          </div>
          <div className="flex min-h-[330px] justify-center">
            <img src={IMG_EXAMPLE} className="h-fit w-fit" alt="example" />
          </div>
        </div>
        <Spacer height="16px" />
        {/* section 2 */}
        <div className="flex flex-col">
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Cash</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Cash represents the organization&apos;s immediately available funds and equivalents.
              This includes cash in checking and savings accounts, along with short-term investments
              readily convertible to cash.
              <br />
              <br />
              Cash is a liquid asset that ensures the organization&apos;s ability to meet short-term
              obligations and navigate unforeseen financial challenges. It serves as a financial
              buffer, allowing for agility in responding to immediate needs.
            </h6>
          </div>
          <Divider className="my-5" />
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Receivables</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Receivables signify amounts owed to the organization by customers, clients, or other
              entities. These are typically short-term assets, representing funds expected to be
              collected in the near future.
              <br />
              <br />
              Receivables contribute to short-term cash flow and financial stability, providing
              insights into the organization&apos;s ability to collect outstanding amounts and
              manage its working capital effectively.
            </h6>
          </div>
          <Divider className="my-5" />
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Investments</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Investments encompass a variety of financial assets, such as stocks, bonds, mutual
              funds, and securities. These may be categorized as short-term or long-term based on
              their anticipated holding period.
              <br />
              <br />
              Investments reflect the organization&apos;s strategic approach to growing financial
              resources. They offer opportunities for capital appreciation and income generation,
              contributing to long-term financial sustainability.
            </h6>
          </div>
          <Divider className="my-5" />
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">
              Property & equipment
            </p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Property & equipment, or fixed/tangible assets, represent physical items owned by the
              organization, including buildings, machinery, vehicles, computers, and furniture.
              These are typically long-term assets depreciated over time.
              <br />
              <br />
              Property & equipment indicate the organization&apos;s infrastructure and capacity for
              sustained operations. They contribute to the overall valuation of the nonprofit and
              are vital for delivering on its mission.
            </h6>
          </div>
        </div>
      </div>
      <div className="flex min-h-[100px] flex-col rounded border border-gray-300 bg-white p-5">
        <div>
          <h5 className="font-poppins text-base font-semibold text-black">
            Why is financial asset categories important?
          </h5>
          <Spacer height="10px" />
          <h6 className="font-poppins text-sm text-gray-800">
            These categories collectively inform decisions related to short-term liquidity,
            investment strategies, and the long-term operational capacity of the organization. A
            balanced and well-managed mix of cash, receivables, investments, and tangible assets
            enhances the nonprofit&apos;s financial resilience and its ability to fulfill its
            mission over the long term.
          </h6>
        </div>
      </div>
      {children && children}
    </div>
  );
}
