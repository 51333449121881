import ServiceLocationGeoTest from "../dashboard/ServiceLocationGeoTest";
import no_info from "../../pages/img/geographic-no-info.svg";
import NoInformation from "../../pages/donor-detail-view/components/NoInformation";
import { SearchQuery } from "../../types/search";
import { matchTaxonomyBasedProfile } from "../../types/taxonomy";

import { ClickToScrollWrapper } from "../ClickToScrollWrapper";
import ChartLoader from "../ChartLoader";
import useFunderServiceLocations from "../../hooks/funder/useFunderServiceLocations";
import { useEffect, useState } from "react";
import { Philanthropy } from "../../types/philanthropy";
import VisibilityTracker from "../VisibilityTracker";
import useGibooMixpanel from "../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../mixpanel/mixpanel";
export default function FunderGeographicFocus({
  funder_id,
  query,
  title,
  compact = false,
  ...props
}: {
  funder_id: string;
  query?: SearchQuery;
  wrapperClass?: string;
  title?: string;
  compact?: boolean;
}) {
  const mxEvent = useGibooMixpanel();
  const { data, isLoading } = useFunderServiceLocations(funder_id);
  const setMatchedFunc = () => {
    return data && query
      ? matchTaxonomyBasedProfile({ service_loc: data }, query, true)
      : undefined;
  };
  const [matched, setMatched] = useState<Philanthropy | undefined>(setMatchedFunc());
  useEffect(() => {
    setMatched(setMatchedFunc());
  }, [data]);

  if (isLoading) {
    return <ChartLoader />;
  }
  return (
    <>
      {data && data.length > 0 ? (
        <VisibilityTracker
          elementIdOrRef={"geographic-focus-chart-view"}
          onVisible={() => {
            // console.log("chart-view: Geographic focus");
            mxEvent(MIXPANEL_EVENTS_V2.detail_view.visibility.observed, {
              value: "Geographic focus",
            });
          }}
        >
          <ServiceLocationGeoTest
            // title={title}
            service_loc={matched?.service_loc ? matched.service_loc : data}
          />
        </VisibilityTracker>
      ) : (
        <div>
          <NoInformation img={no_info} />
        </div>
      )}
    </>
  );
}
