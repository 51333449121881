import axios from "axios";
import config from "../../api";
import useSWR from "swr";
import { swrOptionDedupling5mins } from "../../types/swr";
import { IGrant } from "../../types/grant";

interface useGrantState {
  data: IGrant | undefined;
  isLoading: boolean;
  error: object;
}
export default function useGrant(id?: string): useGrantState {
  const fetch2 = async (_url: string) => {
    if (!_url) return new Promise<IGrant>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data);
  };
  const { data, isLoading, error } = useSWR<IGrant>(
    id ? process.env.REACT_APP_API_URL + `/api/v2/grants/${id}` : "",
    fetch2,
    swrOptionDedupling5mins,
  );

  return { data, isLoading, error };
}
