import useOnboardingData from "../../hooks/useOnboarding";
import classNames from "classnames";
import { TooltipWrapper } from "../TooltipWrapper";
import { PastGrantSearchResult, SearchQuery, getSearchResultKey } from "../../types/search";
import RoundButton from "../RoundButton";
import TagFunderSize from "../TagFunderSize";
import PhilanthropicInformationFlexWrap from "../PhilanthropicInformationFlexWrap";
import { getFunderHqLocation, renderRow } from "../../utils/funder";
import TagGrantEligible from "../TagGrantEligible";
import { titleize, toUsdLong } from "../../utils/formatHelper";
import TagGrantPresent from "../TagGrantPresent";
import useLazyLoad from "../../hooks/useLazyLoad";
import usePastGrantSearchResult from "../../hooks/search/usePastGrantSearchResult";
import MemoizedLoadingTaxonomy from "../LoadingTaxonomy";
import TagFunderType from "../TagFunderType";
interface PastGrantCardProps {
  wrapperClass?: string;
  grant: PastGrantSearchResult;
  query?: SearchQuery;
  isHidden?: boolean;
  hideBtns?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  setHide?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, hide: boolean) => void;
  selected?: boolean;
  allowPastGrantSearch?: boolean;
  lazyLoadDelay?: number;
}
function PastGrantCardSimple({
  grant,
  query,
  isHidden = false,
  hideBtns = false,
  setHide,
  onClick,
  lazyLoadDelay = 0,
  ...props
}: PastGrantCardProps) {
  const [onboardingData] = useOnboardingData();
  const key = getSearchResultKey(grant);
  const { ready } = useLazyLoad(lazyLoadDelay);
  const { data: grantTaxonomy, isLoading } = usePastGrantSearchResult(
    ready ? grant._id : undefined,
    query,
  );
  return (
    <div
      key={key}
      className={classNames(
        "flex h-fit w-full flex-col gap-2",
        onClick ? "cursor-pointer bg-white hover:bg-purple-50" : "",
        props.wrapperClass,
      )}
      {...(onClick ? { onClick } : {})}
    >
      <div className="mb-1 inline-flex items-center justify-between">
        {/* <div className="inline-flex gap-2">
          <TagFunderType funder_type={grant.donor?.funder_type} />
          <TagGrantPresent grant={grant} />
        </div> */}
        {/* {!hideBtns && (
          <TooltipWrapper content={isHidden ? "Unhide this funder" : "Hide this funder"}>
            <div
              className="cursor-pointer p-1"
              onClick={(e) => {
                setHide?.(e, !isHidden);
              }}
            >
              <i className={classNames("gi-md ", isHidden ? "gi-view-hide" : "gi-view")} />
            </div>
          </TooltipWrapper>
        )} */}
      </div>
      <div className="flex items-start justify-between">
        <h4 className="line-clamp-2 w-full grow whitespace-pre-line font-semibold text-purple-500 group-hover:underline">
          {titleize(grant.grant_description)}
        </h4>
      </div>
      {grant.npo ? renderRow("Recipient", grant.npo.name) : null}
      {renderRow("Grant amount", toUsdLong(grant.grant_amount))}
      {renderRow("Year granted", <b>{grant.grant_year}</b>)}
      {isLoading || (!ready && !grantTaxonomy) ? (
        <MemoizedLoadingTaxonomy seed={grant.uid} />
      ) : (
        <PhilanthropicInformationFlexWrap
          containerClass={classNames(
            "mt-2 p-4 border rounded",
            props.selected ? "border-purple-200" : "border-gray-300",
          )}
          id={key}
          focusArea={grantTaxonomy?.focus_area}
          beneficiary={grantTaxonomy?.beneficiary}
          program={grantTaxonomy?.program}
          location={grantTaxonomy?.service_loc}
          priorityFocusArea={query?.must_focus_area}
          priorityBeneficiary={query?.must_beneficiary}
          priorityProgram={query?.must_program}
          priorityLocation={query?.must_service_loc}
          secondaryPriorityLocation={query?.service_loc}
          tagSize="sm"
          tagMatched
          allowPastGrantSearch={props.allowPastGrantSearch}
          funder_id={grant.donor?._id}
          searchQuery={query}
          predictedSize={3}
        />
      )}
    </div>
  );
}
export default PastGrantCardSimple;
