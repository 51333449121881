import React, { useEffect, useState } from "react";
import GibooLocationInput from "./GibooLocationInput";
import { ILocation } from "../types/location";
import MultipleSelector from "./MultipleSelector";
import { caseInsensitiveAndSpaceInsensitive } from "../utils/dataTypeHelper";
import {
  beneficiaryAutoComplete,
  focusAutoComplete,
  programAutoComplete,
} from "../utils/autoComplete";
import PopupModal from "./tailwind/PopupModal";
import Button from "./tailwind/Button";
import TextArea from "./tailwind/TextArea";
import { Divider } from "rsuite";
import GibooGradientDiv from "./GibooGradientDiv";
import useTotalTaxonomyTagging from "../hooks/useTotalTaxonomyTagging";
import Tag from "./Tag";
import Spinner from "./Spinner";

interface EditPhilanthropicFocusProps {
  showEdit: boolean;
  focus_area: string[];
  beneficiary: string[];
  program: string[];
  mission?: string;
  service_loc: ILocation[] | undefined;
  onClose: () => void;
  handleSave: (data: {
    focus_area: string[];
    beneficiary: string[];
    program: string[];
    mission?: string;
    service_loc: ILocation[] | undefined;
  }) => void;
  allowEdit: boolean;
  titlePrefix?: string;
  withoutMission?: boolean;
  withoutTaxonomy?: boolean;
}

function EditPhilanthropicFocus({
  showEdit,
  focus_area,
  beneficiary,
  program,
  service_loc,
  mission,
  onClose,
  handleSave,
  allowEdit,
  titlePrefix,
  withoutMission = false,
  withoutTaxonomy = false,
}: EditPhilanthropicFocusProps) {
  const [updatedFocusArea, setUpdateFocusArea] = useState<string[]>(focus_area);
  const [updatedBeneficiary, setUpdatedBeneficiary] = useState<string[]>(beneficiary);
  const [updatedProgram, setUpdatedProgram] = useState<string[]>(program);
  const [updatedLocation, setUpdatedLocation] = useState<ILocation[]>(service_loc || []);
  const [updatedMission, setUpdateMission] = useState<string>(mission || "");

  const {
    focusArea: fr,
    beneficiary: br,
    program: pr,
    isLoading,
  } = useTotalTaxonomyTagging(updatedMission, undefined, 20);
  const [focusAreaAll, setFocusAreaAll] = useState<string[]>([]);
  const [beneficiaryAll, setBeneficiaryAll] = useState<string[]>([]);
  const [programAll, setProgramAll] = useState<string[]>([]);
  useEffect(() => {
    setFocusAreaAll(() => fr.filter((f) => !updatedFocusArea.includes(f)));
  }, [fr, updatedFocusArea, setFocusAreaAll]);
  useEffect(() => {
    setBeneficiaryAll(() => br.filter((f) => !updatedBeneficiary.includes(f)));
  }, [br, updatedBeneficiary, setBeneficiaryAll]);
  useEffect(() => {
    setProgramAll(() => pr.filter((f) => !updatedProgram.includes(f)));
  }, [pr, updatedProgram, setProgramAll]);

  const PhilanthropicfocusEditContent = () => {
    return (
      <div className="mb-[110px] mt-3 flex flex-col gap-3 pt-[20px]" id="philanthropy">
        {!withoutMission && (
          <>
            <div>
              <h4 className="h-[30px] font-poppins text-base font-medium">{`Mission statement`}</h4>
              <TextArea
                id="mission-statement"
                value={updatedMission}
                placeholder="Mission statement"
                noOfRows={withoutTaxonomy ? 7 : 5}
                className="mt-2"
                handleOnChange={(e) => {
                  setUpdateMission(e.target.value);
                }}
              />
            </div>
          </>
        )}
        {!withoutTaxonomy && (
          <>
            <div className="h-[1px] w-full bg-gray-300" />
            <GibooGradientDiv
              showIcon
              className="w-full p-4 font-poppins text-sm font-normal text-purple-500"
            >
              These tags have been generated using your mission statement. You can add or edit these
              tags that best represent your organization.
            </GibooGradientDiv>
            <div className="z-[3]">
              <h4 className="font-poppins text-base font-medium">{`Focus area`}</h4>
              <MultipleSelector<string>
                id={"org-focus-area"}
                color={"yellow"}
                inputClassName="max-w-[750px] !h-[48px]"
                maxWidth="95%"
                searchFieldPosition="start"
                inputBoxMaxWidth="650px"
                maxHeight="320px"
                getKey={caseInsensitiveAndSpaceInsensitive}
                reprItem={(i) => i}
                createItem={(i) => i}
                value={updatedFocusArea}
                setValue={setUpdateFocusArea}
                placeholder="Search and add your focus area"
                autoCompleteFunction={(query: string) =>
                  focusAutoComplete(query).then((res) => res.map((item) => item.label))
                }
                itemsPosition="top"
                addMoreLabel="Add focus area"
                addMoreLabelClass="!text-base !border-[1px] !rounded !border-gray-300 h-[32px] !pl-0 !pr-[6px]"
                hideSearch
                hideNone
                icon
                tagMatched
                tagSize="sm"
              />
            </div>
            <div className="mb-5 flex flex-col gap-2 rounded border border-gray-300 p-2">
              <div className="flex gap-2">
                <h6>Suggested:</h6>
                {isLoading && <Spinner size="sm" />}
              </div>
              <div className="flex flex-wrap gap-2">
                {focusAreaAll.map((i) => (
                  <Tag
                    id={`focusarea-rec-${i}`}
                    key={`focusarea-rec-${i}`}
                    onAdd={() => setUpdateFocusArea((prev) => [...prev.filter((p) => p !== i), i])}
                    matched
                    color="yellow"
                    name={i}
                    size="sm"
                    isFullTagClick
                  />
                ))}
              </div>
            </div>
            <div className="z-[2]">
              <h4 className="h-[30px] font-poppins text-base font-medium">{`Beneficiary`}</h4>
              <MultipleSelector<string>
                id={"org-beneficiary-area"}
                color={"blue"}
                inputClassName="max-w-[750px] !h-[48px]"
                maxWidth="95%"
                searchFieldPosition="start"
                inputBoxMaxWidth="650px"
                maxHeight="320px"
                getKey={caseInsensitiveAndSpaceInsensitive}
                reprItem={(i) => i}
                createItem={(i) => i}
                value={updatedBeneficiary}
                setValue={setUpdatedBeneficiary}
                placeholder="Search and add your beneficiary"
                addMoreLabel="Add beneficiary"
                addMoreLabelClass="!text-base !border-[1px] !rounded !border-gray-300 h-[32px] !pl-0 !pr-[6px]"
                autoCompleteFunction={(query: string) =>
                  beneficiaryAutoComplete(query).then((res) => res.map((item) => item.label))
                }
                itemsPosition="top"
                hideSearch
                hideNone
                icon
                tagMatched
                tagSize="sm"
              />
            </div>
            <div className="mb-5 flex flex-col gap-2 rounded border border-gray-300 p-2">
              <div className="flex gap-2">
                <h6>Suggested:</h6>
                {isLoading && <Spinner size="sm" />}
              </div>
              <div className="flex flex-wrap gap-2">
                {beneficiaryAll.map((i) => (
                  <Tag
                    id={`beneficiary-rec-${i}`}
                    key={`beneficiary-rec-${i}`}
                    onAdd={() =>
                      setUpdatedBeneficiary((prev) => [...prev.filter((p) => p !== i), i])
                    }
                    matched
                    color="blue"
                    name={i}
                    size="sm"
                    isFullTagClick
                  />
                ))}
              </div>
            </div>
            <div className="z-[1]">
              <h4 className="h-[30px] font-poppins text-base font-medium">{`Program`}</h4>
              <MultipleSelector<string>
                id={"org-program-area"}
                color={"green"}
                inputClassName="max-w-[750px] !h-[48px]"
                maxWidth="95%"
                searchFieldPosition="start"
                inputBoxMaxWidth="650px"
                maxHeight="320px"
                getKey={caseInsensitiveAndSpaceInsensitive}
                reprItem={(i) => i}
                createItem={(i) => i}
                value={updatedProgram}
                setValue={setUpdatedProgram}
                placeholder="Search and add program"
                addMoreLabel="Add program"
                addMoreLabelClass="!text-base !border-[1px] !rounded !border-gray-300 h-[32px] !pl-0 !pr-[6px]"
                autoCompleteFunction={(query: string) =>
                  programAutoComplete(query).then((res) => res.map((item) => item.label))
                }
                itemsPosition="top"
                hideSearch
                hideNone
                icon
                tagMatched
                tagSize="sm"
              />
            </div>
            <div className="mb-5 flex flex-col gap-2 rounded border border-gray-300 p-2">
              <div className="flex gap-2">
                <h6>Suggested:</h6>
                {isLoading && <Spinner size="sm" />}
              </div>
              <div className="flex flex-wrap gap-2">
                {programAll.map((i) => (
                  <Tag
                    id={`program-rec-${i}`}
                    key={`program-rec-${i}`}
                    onAdd={() => setUpdatedProgram((prev) => [...prev.filter((p) => p !== i), i])}
                    matched
                    color="green"
                    name={i}
                    size="sm"
                    isFullTagClick
                  />
                ))}
              </div>
            </div>
            {service_loc && (
              <div>
                <h4 className="h-[30px] font-poppins text-base font-medium">{`Geograhpic focus`}</h4>
                <GibooLocationInput
                  id={"location-input"}
                  value={updatedLocation}
                  setValue={setUpdatedLocation}
                  inputClassName="max-w-[750px] !h-[48px]"
                  maxWidth="95%"
                  searchFieldPosition="start"
                  inputBoxMaxWidth="650px"
                  maxHeight="320px"
                  placeholder={"Search and add your geographic focus"}
                  addMoreLabel="Add geographic focus"
                  addMoreLabelClass="!text-base !border-[1px] !rounded !border-gray-300 h-[32px] !pl-0 !pr-[6px]"
                  float
                  itemsPosition="top"
                  icon
                  hideSearch
                  hideNone
                  matched
                  tagSize="sm"
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {showEdit && (
        <PopupModal
          isOpen={showEdit}
          handleClose={() => {
            onClose();
          }}
          title="Edit mission statement"
          wrapperClass="max-w-[864px] md:!px-0 "
          contentClass="!px-[24px] max-h-[550px] overflow-y-scroll h-fit"
          RightActionButton={() => (
            <Button
              id="save-btn"
              className="mx-6 !w-[170px]"
              handleOnClick={() => {
                handleSave({
                  focus_area: updatedFocusArea,
                  beneficiary: updatedBeneficiary,
                  program: updatedProgram,
                  service_loc: service_loc ? updatedLocation : undefined,
                  ...(withoutMission ? {} : { mission: updatedMission }),
                });
              }}
              label="Save"
            />
          )}
        >
          <>{PhilanthropicfocusEditContent()}</>
        </PopupModal>
      )}
    </>
  );
}

EditPhilanthropicFocus.propTypes = {};

export default EditPhilanthropicFocus;
