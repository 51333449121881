import React, { ReactElement, useCallback } from "react";
import { getYearsString, titleize, toUsdLong } from "../../utils/formatHelper";
import "../css/box.scss";
import classNames from "classnames";
import { reprLocation } from "../../types/location";
import useFunderPastRecipients from "../../hooks/funder/useFunderPastRecipients";
import { scrollBarClass } from "../../utils/classes";
import { SearchQuery, getURLSearchParamsFromSearchQuery } from "../../types/search";
import PhilanthropicInformationFlexWrap from "../PhilanthropicInformationFlexWrap";
import MemoizedLoadingPhilanthropy from "../LoadingPhilanthropy";
import { useNavigate } from "react-router-dom";
import { reprGrantFundingType } from "../../utils/grant";
import { ClickToScrollWrapper } from "../ClickToScrollWrapper";
import useOnboardingData from "../../hooks/useOnboarding";
import usePastGrantOverview from "../../hooks/usePastGrantOverview";
import { queryMaskForNameSearch } from "../../hooks/useGibooStructureSearch";
import PlainButton from "../PlainButton";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";

interface props {
  id: string;
  funder_uid?: number;
  funder_id?: string;
  npo_id?: string;
  query: SearchQuery;
  compact?: boolean;
}

function PastRecipients({ query, funder_id, funder_uid, ...props }: props) {
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const { data: funder } = useFunderSearchResult(funder_id);
  const { data, isLoading } = useFunderPastRecipients(query, funder_id, funder_uid, 10, true);
  const renderItem = (
    key: string,
    icon: string,
    value: string | ReactElement,
    className?: string,
  ) => {
    return (
      <div className={classNames("flex items-start gap-1", className)}>
        {icon && <i className={icon} />}
        <span className="whitespace-nowrap">{`${key}: `}</span>
        <span className="font-semibold">{value}</span>
      </div>
    );
  };
  const reprStage = (stage?: string) => {
    return stage === "hyperstar"
      ? "Hyperstar"
      : stage === "superstar"
      ? "Superstar"
      : stage === "buildup"
      ? "Build-up"
      : stage === "startup"
      ? "Startup"
      : stage === "seed"
      ? "Seed"
      : "Individual";
  };
  // const { pastGrant: pastGrantAll, isPastGrantOverviewLoading: isPastGrantAllLoading } =
  //   usePastGrantOverview(
  //     onboardingData ? funder_uid : undefined,
  //     {
  //       ...query,
  //       ...queryMaskForNameSearch,
  //     },
  //     [],
  //     onboardingData.stage,
  //     true,
  //   );
  const handleViewAll = useCallback(() => {
    navigate(
      `/search?${getURLSearchParamsFromSearchQuery(
        onboardingData,
        {
          type: ["past_grant"],
          ...queryMaskForNameSearch,
          focus_area_subs: query.focus_area || [],
          beneficiary_subs: query.beneficiary || [],
          program_subs: query.program || [],
          service_loc_subs: query.service_loc || [],
          donor_id: funder_id,
        },
        5,
      ).toString()}`,
    );
  }, [query, onboardingData, funder_id]);
  return (
    <>
      <ClickToScrollWrapper disabled={data.length <= 3 || isLoading}>
        <div className={classNames("flex max-h-[900px] flex-col gap-3", scrollBarClass)}>
          {isLoading ? (
            <MemoizedLoadingPhilanthropy loading={isLoading} max={1} />
          ) : (
            <>
              {data.map((d, i) => {
                return (
                  <div
                    key={i}
                    className={classNames(
                      "flex cursor-pointer flex-col gap-2 rounded border border-gray-300 p-3 hover:bg-gray-100",
                    )}
                    onClick={() => navigate(`/npos/${d.npo_id}/`)}
                  >
                    <p className="w-full truncate text-ellipsis font-semibold">{d.npo_name}</p>
                    {props.npo_id === d.npo_id && (
                      <div className="self-start rounded border border-[#C6C697] bg-[#F2F2E1] px-2 py-[2px]">
                        <h6>
                          This funder has a history of providing funding to your organization.
                        </h6>
                      </div>
                    )}
                    <div className="flex flex-col gap-2">
                      {renderItem("Grant name", "", titleize(d.grants[0].grant_description), "")}
                      {renderItem("Grant type", "", reprGrantFundingType(d.grants[0]), "truncate")}
                      <div className="flex flex-nowrap gap-x-3  divide-x divide-gray-300 whitespace-nowrap">
                        {renderItem(
                          "Grant amount",
                          "",
                          d.grants[0].grant_amount_min === d.grants[0].grant_amount_max
                            ? `${toUsdLong(d.grants[0].grant_amount_min)}`
                            : `${toUsdLong(d.grants[0].grant_amount_min)}-${toUsdLong(
                                d.grants[0].grant_amount_max,
                              )}`,
                        )}
                        {renderItem("Year granted", "", getYearsString(d.years), "pl-3")}
                      </div>
                      <div className="flex flex-nowrap gap-x-3  divide-x divide-gray-300 whitespace-nowrap">
                        {renderItem(
                          "Recipient location",
                          "",
                          d.npo_location ? reprLocation(d.npo_location) : "",
                        )}
                        {renderItem("Recipient stage", "", reprStage(d.stage), "pl-3")}
                      </div>
                      <div className="rounded border border-gray-300 p-3">
                        <PhilanthropicInformationFlexWrap
                          id={`${props.id}-${d.npo_id}`}
                          focusArea={d.focus_area}
                          beneficiary={d.beneficiary}
                          program={d.program}
                          searchQuery={query}
                          tagMatched
                          tagSize="sm"
                          predictedSize={7}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              {data.length === 0 && <p>No information available</p>}
            </>
          )}
        </div>
        {!isLoading && funder_id && data.length > 0 && (
          <div className="group/viewall flex h-[48px] cursor-pointer justify-center rounded-b  bg-gray-100 hover:bg-gray-100">
            <PlainButton
              id="btn-grant-viewall"
              className="!text-sm !text-purple-500 group-hover/viewall:!font-semibold  "
              label={`View all (${funder ? funder.grant_count_total.toLocaleString() : "..."})`}
              handleOnClick={() => handleViewAll()}
            />
          </div>
        )}
      </ClickToScrollWrapper>
    </>
  );
}

export default PastRecipients;
