import { useNavigate, useSearchParams } from "react-router-dom";
import useOnboardingData from "../../../hooks/useOnboarding";
import {
  ISearchParamNullable,
  getObjectFromURLSearchParams,
  getURLSearchParamsFromSearchParam,
} from "../../../types/search";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { Popover, Whisper } from "rsuite";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import { useRecoilValue } from "recoil";
import { searchSession } from "../../../app/recoilStore";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import { Dialog } from "@headlessui/react";
import { zIndexBackdrop, zIndexDialog } from "../../../types/zIndex";
import Button from "../../tailwind/Button";

function SearchPlusFilter({ onClick }: { onClick?: () => void }) {
  const searchSessionForMixpanel = useRecoilValue(searchSession);
  const mxEvent = useGibooMixpanel(undefined, true);
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [diffQueue, setDiffQueue] = useState<ISearchParamNullable[]>([]);
  const [useMission, setUseMission] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const obj = getObjectFromURLSearchParams(searchParams);
    setUseMission(obj.mission ? true : false);
  }, [searchParams, setSearchParams, setUseMission]);
  const updateFilter = useCallback(
    (diff: ISearchParamNullable) => {
      setDiffQueue((prev) => [...prev, diff]);
    },
    [setDiffQueue],
  );
  useEffect(() => {
    if (diffQueue.length === 0) return;
    const diff = diffQueue[0];
    const done_tagging = searchParams.get("done_tagging") === "true" ? true : false;
    const _obj = getObjectFromURLSearchParams(searchParams);
    if (!done_tagging) return;
    const obj = {
      ..._obj,
      ...diff,
    };
    if (
      getURLSearchParamsFromSearchParam(obj).toString() !==
      getURLSearchParamsFromSearchParam(_obj).toString()
    ) {
      navigate(`/search?${getURLSearchParamsFromSearchParam(obj, true).toString()}`);
    }
    setDiffQueue((prev) => prev.slice(1));
  }, [diffQueue, searchParams, setDiffQueue]);
  return (
    <>
      <Dialog open={open} onClose={() => null} className="">
        <div
          className={classNames("fixed inset-0 bg-black/80", zIndexBackdrop)}
          aria-hidden="true"
        />
        <div
          className={classNames(
            "fixed inset-0 flex  w-screen items-center justify-center overflow-y-auto p-4",
            zIndexDialog,
          )}
        >
          <Dialog.Panel
            className={classNames("mx-auto min-h-fit !w-[486px] rounded bg-white", zIndexDialog)}
          >
            <div className="flex h-full w-full flex-col gap-5 p-6">
              <h5>
                Share your organization&apos;s{" "}
                <span className="font-semibold text-purple-500">mission statement</span> and key
                details with us, and we&apos;ll craft a highly personalized search experience
                tailored specifically to your unique needs. This customization ensures you get{" "}
                <span className="font-semibold text-purple-500">
                  the most relevant and impactful results
                </span>
                , perfectly aligned with your goals.
              </h5>
              <div className="flex justify-end gap-3">
                <Button
                  id="btn-ok"
                  className=""
                  handleOnClick={() => {
                    navigate("/organization");
                  }}
                  label="Go to update your profile"
                />
                <Button
                  id="btn-cancel"
                  className=""
                  outline
                  handleOnClick={() => {
                    setOpen(false);
                  }}
                  label="Not now"
                />
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
      <Whisper
        trigger={"hover"}
        placement={"bottomStart"}
        className="shadow-none"
        speaker={
          <Popover
            prefix="tooltip-hover-search-plus"
            className="!mt-[-2px] w-[372px] rounded border border-gray-300 bg-gray-50 p-4"
            arrow={false}
          >
            <h6>
              Turn the button on and off to experience the difference. When on, enjoy a customized
              search based on your organization&apos;s unique profile.
            </h6>
            <h6>
              If you prefer a more traditional search, simply turn it off, and we&apos;ll focus on
              your search queries without considering your past interactions.
            </h6>
          </Popover>
        }
      >
        <div
          className={classNames(
            "GA_TAG_click_search_plus relative flex !h-[29px] w-fit cursor-pointer items-center justify-start whitespace-nowrap rounded px-2 hover:font-semibold",
            useMission
              ? "bg-purple-500 text-white hover:bg-purple-400"
              : "border border-gray-300 text-gray-900 hover:bg-gray-100",
          )}
          onClick={() => {
            onClick?.();
            if (onboardingData.mission) {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName: "search_plus",
                filterAction: useMission ? "uncheck" : "check",
              });
              updateFilter({
                mission: useMission ? undefined : onboardingData.mission || "",
                npo_uid: onboardingData.npo_uid,
                from_more_search_option: false,
              });
            } else {
              setOpen(true);
            }
          }}
          aria-valuetext="search plus"
        >
          Search plus
          <span
            className={classNames(
              "ml-1 rounded px-1 py-0 text-xs",
              useMission ? "bg-white text-purple-500" : "bg-gray-200 text-gray-700",
            )}
            aria-valuetext="search plus"
          >
            {useMission ? "On" : "Off"}
          </span>
        </div>
      </Whisper>
    </>
  );
}
export default SearchPlusFilter;
