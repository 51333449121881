import axios from "axios";
import config from "../../api";
import useSWR from "swr";
import { PastGrantSearchResult } from "../../types/search";

interface PastGrantSearchResultState {
  data: PastGrantSearchResult[];
  isLoading: boolean;
  error: object;
}
const swrOption = {
  dedupingInterval: 1000 * 300,
  refreshInterval: 0,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
const emptyList: PastGrantSearchResult[] = [];
export default function usePastGrantSearchResults(id?: string[]): PastGrantSearchResultState {
  const url =
    id && id.length > 0
      ? process.env.REACT_APP_API_URL +
        `/api/v2/search/pastgrants_searchresult?id=${id.join("&id=")}`
      : null;
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<PastGrantSearchResult[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) =>
      res.data.map((d: any) => ({
        ...d,
        search_type: "past_grant",
        focus_area: d.focus_area.map((t: string) => ({ label: t, matched: false })),
        beneficiary: d.beneficiary.map((t: string) => ({ label: t, matched: false })),
        program: d.program.map((t: string) => ({ label: t, matched: false })),
        service_specific_loc: true,
      })),
    );
  };
  const { data, isLoading, error } = useSWR<PastGrantSearchResult[]>(
    url ? url : null,
    fetch,
    swrOption,
  );
  return { data: data || emptyList, isLoading, error };
}
