import Select, {
  ActionMeta,
  components,
  DropdownIndicatorProps,
  ClearIndicatorProps,
  SingleValue,
  MenuPlacement,
} from "react-select";
import "../css/selector.scss";
import classNames from "classnames";
import { scrollBarClass } from "../../utils/classes";
import closeTag from "../../assets/images/closeTag.svg";
import downTag from "../../assets/images/arrow-down.svg";
import { ReactNode } from "react";

interface SingleSelectorProps<T> {
  id: string;
  data: T[];
  value: T | undefined;
  placeholder?: string;
  focusPlaceholder?: string;
  onChange: (newValue: SingleValue<T>, actionMeta: ActionMeta<T>) => void;
  disabled?: boolean;
  invalid?: boolean;
  errorMessage?: string;
  className?: string;
  inputClass?: string;
  placeholderClass?: string;
  inputContainerClass?: string;
  borderClass?: string;
  isClearable?: boolean;
  compact?: boolean;
  dropdownIcon?: boolean;
  isRequired?: boolean;
  requiredMessage?: string;
  isSearchable?: boolean;
  menuPlacement?: MenuPlacement;
  renderItem?: (value: T) => ReactNode;
  renderButton?: (value: T) => ReactNode;
  renderNoOption?: () => ReactNode;
  defaultOpen?: boolean;
  menuClass?: string;
  itemClass?: string;
}
function SingleSelector<T>({
  id,
  data,
  value,
  placeholder,
  onChange,
  invalid,
  disabled,
  errorMessage,
  className,
  inputClass,
  placeholderClass,
  inputContainerClass,
  borderClass,
  isClearable = true,
  compact = false,
  dropdownIcon = true,
  isRequired,
  requiredMessage,
  focusPlaceholder,
  isSearchable = false,
  defaultOpen = false,
  menuClass,
  renderItem,
  renderButton,
  renderNoOption,
  menuPlacement = "auto",
  itemClass,
}: SingleSelectorProps<T>) {
  const DropdownIndicator = (props: any) => {
    // if (props?.hasValue) {
    //   return <></>;
    // }
    return (
      <components.DropdownIndicator {...props} className="px-1">
        <i className={classNames("gi gi-angle-down mt-[1px]")} />
      </components.DropdownIndicator>
    );
  };
  const ClearIndicator = (props: any) => {
    return (
      <components.ClearIndicator {...props} className="px-1">
        <i className={classNames("gi gi-x cursor-pointer")} />
      </components.ClearIndicator>
    );
  };
  const SingleValue = (props: any) => {
    if (renderButton) {
      return (
        <components.SingleValue {...props}>
          {renderButton && renderButton(props.data)}
        </components.SingleValue>
      );
    }
    return <components.SingleValue {...props}></components.SingleValue>;
  };
  const Option = (props: any) => {
    if (renderItem) {
      return (
        <components.Option {...props}>{renderItem && renderItem(props.data)}</components.Option>
      );
    }
    return <components.Option {...props}></components.Option>;
  };
  const NoOptionsMessage = (props: any) => {
    if (renderNoOption) {
      return (
        <components.NoOptionsMessage {...props}>
          {renderNoOption && renderNoOption()}
        </components.NoOptionsMessage>
      );
    }
    return <components.NoOptionsMessage {...props}></components.NoOptionsMessage>;
  };
  const Placeholder = (props: any) => {
    return (
      <components.Placeholder {...props}>
        {isRequired
          ? requiredMessage
          : focusPlaceholder
          ? props.isFocused
            ? focusPlaceholder
            : placeholder
          : placeholder}
      </components.Placeholder>
    );
  };

  return (
    <>
      <Select
        unstyled
        classNamePrefix=""
        classNames={{
          control: (state) =>
            classNames(
              "rounded border px-3 text-gray-700 max-h-[90px] min-h-[56px] overflow-y-auto font-poppins",
              compact ? "py-0" : "py-2",
              state.isFocused ? `border-gray-400` : `border-gray-400`,
              // state.isDisabled && `!bg-gray-50`,
              className,
              inputContainerClass ? inputContainerClass : "!max-h-[90px] !min-h-[56px] ",
              isRequired && "!bg-purple-50 !rounded-none",
            ),
          menu: (state) => classNames("bg-white rounded shadow border border-gray-300", menuClass),
          menuList: (state) => classNames("flex flex-col overscroll-contain max-h-[300px]"),
          option: (state) =>
            classNames(
              "p-2 __option text-gray-700 !text-sm hover:bg-gray-200",
              state.isSelected ? "bg-gray-300 __option--selected" : "",
              state.isFocused ? "bg-gray-300 __option--focused" : "",
              itemClass,
            ),
          groupHeading: (state) => "text-sm text-gray-500",
          placeholder: (state) =>
            classNames(
              "text-gray-500 text-sm font-poppins font-normal",
              placeholderClass,
              isRequired && "!text-purple-500",
            ),
          container: (state) => "p-0",
          input: (state) => classNames("creatable-select-input", inputClass),
          valueContainer: (state) => "flex flex-wrap gap-2",
          multiValue: (state) => "rounded bg-gray-200 border-gray-500 px-2 !h-[24px] items-center",
          multiValueRemove: (state) => "ml-1",
          indicatorsContainer: () => "grid place-items-center h-full",
        }}
        id={id}
        options={data}
        value={value}
        placeholder={isRequired ? requiredMessage : placeholder}
        components={{
          DropdownIndicator: dropdownIcon ? DropdownIndicator : null,
          ClearIndicator: ClearIndicator,
          Placeholder,
          SingleValue,
          Option,
          NoOptionsMessage,
        }}
        onChange={onChange}
        isClearable={isClearable}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 99999 }),
        }}
        menuPortalTarget={document.body}
        isDisabled={disabled}
        isSearchable={isSearchable}
        menuPlacement={menuPlacement}
        defaultMenuIsOpen={defaultOpen}
      />
      {errorMessage && invalid ? (
        <div className="font-sm pt-2 font-poppins text-base text-purple-500">{errorMessage}</div>
      ) : null}
    </>
  );
}
export default SingleSelector;
