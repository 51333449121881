import { useEffect, RefObject } from "react";

type Event = MouseEvent | TouchEvent;

function findParentStartsWithGiboo(element: Element | null): Element | null {
  if (!element) {
    return null;
  }
  if (element?.parentElement && element.parentElement.id.startsWith("giboo-")) {
    return element.parentElement;
  }
  return findParentStartsWithGiboo(element?.parentElement);
}
const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  exceptionPrefix: string[] = [],
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }
      const parentGiboo = findParentStartsWithGiboo(event?.target as Element | null);
      if (parentGiboo && exceptionPrefix.find((e) => parentGiboo?.id?.startsWith(e))) return;
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
export default useOnClickOutside;
