import { useCallback, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { swrOptionDedupling5mins } from "../types/swr";
import axios from "axios";
import config from "../api";

interface IYearData {
  year: number;
  value: number;
}
interface ReturnModel {
  [key: number]: number;
}
interface useForecastsState<T> {
  data: (T & {
    is_predicted?: boolean;
    is_interpolated?: boolean;
    is_interpolated_or_predicted?: boolean;
  })[];
  isLoading: boolean;
  error?: object;
  interpolate?: boolean;
}

function interpolateMissingYears(data: IYearData[]): IYearData[] {
  const interpolatedData: IYearData[] = data.reduce(
    (acc: IYearData[], current: IYearData, index: number, array: IYearData[]) => {
      acc.push(current);

      if (index < array.length - 1) {
        const currentYear = current.year;
        const nextYear = array[index + 1].year;
        const yearDifference = nextYear - currentYear;

        if (yearDifference > 1) {
          const valueDifference = array[index + 1].value - current.value;
          const increment = valueDifference / yearDifference;

          for (let j = 1; j < yearDifference; j++) {
            const interpolatedYear = currentYear + j;
            const interpolatedValue = current.value + increment * j;
            acc.push({ year: interpolatedYear, value: interpolatedValue });
          }
        }
      }

      return acc;
    },
    [],
  );

  return interpolatedData.sort((a, b) => a.year - b.year);
}
export default function useForecasts<T extends { tax_year: number }>(
  req: T[] | undefined,
  keys: string[],
  _forecast_years = 3,
  non_negative = true,
  postProcessing?: (orgin: T) => T,
  interpolate = true,
): useForecastsState<T> {
  const forecast_years = Math.min(
    _forecast_years,
    !req || req.length === 0
      ? 0
      : new Date().getFullYear() -
          req.reduce((prev, cur) => Math.min(prev, cur.tax_year), req[0].tax_year),
  );
  const _default = useMemo(() => keys.reduce((prev, key) => ({ ...prev, [key]: [] }), {}), keys);
  const [transposed, setTransposed] = useState<{ [key: string]: IYearData[] }>(_default);
  const [interpolated, setInterpolated] = useState<{ [key: string]: IYearData[] }>(_default);
  const [data, setData] = useState<
    (T & {
      is_predicted?: boolean;
      is_interpolated?: boolean;
    })[]
  >([]);
  useEffect(() => {
    if (!req) setTransposed(_default);
    else
      setTransposed(
        [...req]
          .sort((a, b) => a.tax_year - b.tax_year)
          .reduce((prev, cur: T) => {
            return keys.reduce(
              (p, key) => ({
                ...p,
                [key]: [
                  ...(prev as any)[key],
                  { year: cur.tax_year, value: cur[key as keyof typeof cur] },
                ],
              }),
              {},
            );
          }, _default),
      );
  }, [req, _default]);
  useEffect(() => {
    setInterpolated(
      keys.reduce(
        (prev, key) => ({
          ...prev,
          [key]: interpolate ? interpolateMissingYears(transposed[key]) : transposed[key],
        }),
        {},
      ),
    );
  }, [transposed]);
  const fetch = useCallback(
    async (url: string) => {
      if (!url || !req || !forecast_years || keys.some((key) => interpolated[key].length === 0))
        return new Promise<{ [key: string]: ReturnModel }>((resolve, reject) => reject());
      const promises = keys.map((key) =>
        axios
          .post(url, { data: interpolated[key], forecast_years }, config)
          .then((res) => res.data),
      );
      return Promise.all(promises).then((res) =>
        keys.reduce((prev, key, i) => ({ ...prev, [key]: res[i] }), {}),
      );
    },
    [req, interpolated, forecast_years, keys],
  );
  const {
    data: forecast,
    isLoading,
    error,
  } = useSWR<{ [key: string]: ReturnModel }>(
    req && keys.every((key) => interpolated[key].length) && forecast_years > 0
      ? process.env.REACT_APP_TAXONOMY_URL +
          `/algo/v1/forecast?forecast_years=${forecast_years}&data=${JSON.stringify(interpolated)}`
      : null,
    fetch,
    swrOptionDedupling5mins,
  );

  useEffect(() => {
    if (!req || req.length === 0) {
      setData([]);
      return;
    }
    const years = req.map((a) => a.tax_year);
    const start_year = req.reduce((prev, cur) => Math.min(prev, cur.tax_year), req[0].tax_year);
    const end_year = req.reduce((prev, cur) => Math.max(prev, cur.tax_year), req[0].tax_year);
    const start_forecast_year = end_year + 1;
    const end_forecast_year = start_forecast_year + forecast_years - 1;
    const _data = Array.from({ length: end_forecast_year - start_forecast_year + 1 }, (x, i) => i)
      .map((_, i) => i + start_forecast_year)
      .map((tax_year) =>
        keys.reduce(
          (prev, key) => ({
            ...prev,
            [key]: non_negative
              ? Math.max(0, forecast?.[key]?.[tax_year] || 0)
              : forecast?.[key]?.[tax_year] || 0,
          }),
          {
            ...req[0],
            tax_year,
            grant_year: tax_year,
            is_interpolated_or_predicted: true,
            is_predicted: true,
          } as T & {
            is_predicted?: boolean;
            is_interpolated?: boolean;
            is_interpolated_or_predicted?: boolean;
          },
        ),
      );
    const _dataInterpolated = Array.from({ length: end_year - start_year + 1 }, (x, i) => i)
      .map((_, i) => i + start_year)
      .map((tax_year, i) => {
        const is_interpolated = years.includes(tax_year) ? false : true;
        return keys.reduce(
          (prev, key) => ({
            ...prev,
            [key]: interpolated?.[key]?.find((a) => a.year === tax_year)?.value || 0,
          }),
          {
            ...req[0],
            tax_year,
            grant_year: tax_year,
            is_interpolated_or_predicted: is_interpolated,
            is_interpolated: is_interpolated,
          } as T & {
            is_predicted?: boolean;
            is_interpolated?: boolean;
            is_interpolated_or_predicted?: boolean;
          },
        );
      });
    setData([..._dataInterpolated, ..._data].map((d) => (postProcessing ? postProcessing(d) : d)));
  }, [req, forecast, forecast_years]);
  return { data, isLoading: isLoading, error: error };
}
