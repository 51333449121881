import axios from "axios";
import config from "../api";
import { stateIdToStateName } from "../utils/formatHelper";
export interface ILocation {
  location?: string;
  label?: string;
  origin?: { label: string; matched?: boolean };
  level: number;
  city?: string;
  county_name?: string;
  state_name?: string;
  state_id?: string;
  country?: string;
  iso2: string;
  lat: number;
  lng: number;
  matched?: boolean;
  amount?: number;
  score?: number;
}
const iso2_to_country: { [key: string]: string } = {
  JP: "Japan",
  ID: "Indonesia",
  IN: "India",
  PH: "Philippines",
  BR: "Brazil",
  KR: "South Korea",
  CN: "China",
  MX: "Mexico",
  EG: "Egypt",
  US: "United States",
  RU: "Russia",
  TH: "Thailand",
  BD: "Bangladesh",
  AR: "Argentina",
  NG: "Nigeria",
  TR: "Turkey",
  PK: "Pakistan",
  CD: "Congo (Kinshasa)",
  VN: "Vietnam",
  IR: "Iran",
  GB: "United Kingdom",
  FR: "France",
  PE: "Peru",
  AO: "Angola",
  MY: "Malaysia",
  CO: "Colombia",
  TZ: "Tanzania",
  HK: "Hong Kong",
  CL: "Chile",
  SA: "Saudi Arabia",
  IQ: "Iraq",
  SD: "Sudan",
  ES: "Spain",
  KE: "Kenya",
  MM: "Myanmar",
  CA: "Canada",
  SG: "Singapore",
  CI: "Côte d'Ivoire",
  AU: "Australia",
  ZA: "South Africa",
  MA: "Morocco",
  AF: "Afghanistan",
  JO: "Jordan",
  DE: "Germany",
  DZ: "Algeria",
  BO: "Bolivia",
  ET: "Ethiopia",
  KW: "Kuwait",
  UA: "Ukraine",
  YE: "Yemen",
  GT: "Guatemala",
  IT: "Italy",
  KP: "North Korea",
  TW: "Taiwan",
  EC: "Ecuador",
  MG: "Madagascar",
  DO: "Dominican Republic",
  AE: "United Arab Emirates",
  UZ: "Uzbekistan",
  BF: "Burkina Faso",
  CM: "Cameroon",
  GH: "Ghana",
  AZ: "Azerbaijan",
  ZW: "Zimbabwe",
  CU: "Cuba",
  KH: "Cambodia",
  SO: "Somalia",
  ML: "Mali",
  BY: "Belarus",
  VE: "Venezuela",
  SY: "Syria",
  KZ: "Kazakhstan",
  AT: "Austria",
  MW: "Malawi",
  RO: "Romania",
  CG: "Congo (Brazzaville)",
  PL: "Poland",
  BE: "Belgium",
  ZM: "Zambia",
  HU: "Hungary",
  GN: "Guinea",
  UG: "Uganda",
  OM: "Oman",
  MN: "Mongolia",
  RS: "Serbia",
  NZ: "New Zealand",
  CZ: "Czechia",
  UY: "Uruguay",
  BG: "Bulgaria",
  HN: "Honduras",
  MZ: "Mozambique",
  QA: "Qatar",
  SN: "Senegal",
  RW: "Rwanda",
  LY: "Libya",
  GE: "Georgia",
  TD: "Chad",
  DK: "Denmark",
  AM: "Armenia",
  MR: "Mauritania",
  KG: "Kyrgyzstan",
  TN: "Tunisia",
  NP: "Nepal",
  NE: "Niger",
  NI: "Nicaragua",
  LR: "Liberia",
  HT: "Haiti",
  SE: "Sweden",
  ER: "Eritrea",
  SL: "Sierra Leone",
  LA: "Laos",
  IL: "Israel",
  CF: "Central African Republic",
  PA: "Panama",
  NL: "Netherlands",
  TG: "Togo",
  GA: "Gabon",
  HR: "Croatia",
  TJ: "Tajikistan",
  BJ: "Benin",
  LK: "Sri Lanka",
  NO: "Norway",
  GR: "Greece",
  BI: "Burundi",
  FI: "Finland",
  MK: "Macedonia",
  MD: "Moldova",
  LV: "Latvia",
  JM: "Jamaica",
  LT: "Lithuania",
  SV: "El Salvador",
  DJ: "Djibouti",
  IE: "Ireland",
  PY: "Paraguay",
  PT: "Portugal",
  SK: "Slovakia",
  EE: "Estonia",
  LB: "Lebanon",
  AL: "Albania",
  GW: "Guinea-Bissau",
  SS: "South Sudan",
  LS: "Lesotho",
  CY: "Cyprus",
  NA: "Namibia",
  PG: "Papua New Guinea",
  CR: "Costa Rica",
  SI: "Slovenia",
  BA: "Bosnia And Herzegovina",
  BS: "The Bahamas",
  MQ: "Martinique",
  BW: "Botswana",
  SR: "Suriname",
  TL: "Timor-Leste",
  MV: "Maldives",
  GY: "Guyana",
  GI: "Gibraltar",
  RE: "Reunion",
  GQ: "Equatorial Guinea",
  ME: "Montenegro",
  BH: "Bahrain",
  MU: "Mauritius",
  CW: "Curaçao",
  CH: "Switzerland",
  PF: "French Polynesia",
  LU: "Luxembourg",
  IS: "Iceland",
  CV: "Cabo Verde",
  BB: "Barbados",
  KM: "Comoros",
  BT: "Bhutan",
  SZ: "Swaziland",
  NC: "New Caledonia",
  SB: "Solomon Islands",
  FJ: "Fiji",
  LC: "Saint Lucia",
  GF: "French Guiana",
  ST: "Sao Tome And Principe",
  VU: "Vanuatu",
  BM: "Bermuda",
  BN: "Brunei",
  MC: "Monaco",
  TT: "Trinidad And Tobago",
  WS: "Samoa",
  KI: "Kiribati",
  AW: "Aruba",
  JE: "Jersey",
  GM: "The Gambia",
  YT: "Mayotte",
  MH: "Marshall Islands",
  IM: "Isle Of Man",
  KY: "Cayman Islands",
  SC: "Seychelles",
  VC: "Saint Vincent And The Grenadines",
  AD: "Andorra",
  AG: "Antigua And Barbuda",
  TO: "Tonga",
  TM: "Turkmenistan",
  GL: "Greenland",
  BZ: "Belize",
  DM: "Dominica",
  KN: "Saint Kitts And Nevis",
  FO: "Faroe Islands",
  AS: "American Samoa",
  MT: "Malta",
  XG: "Gaza Strip",
  TC: "Turks And Caicos Islands",
  FM: "Federated States of Micronesia",
  TV: "Tuvalu",
  LI: "Liechtenstein",
  CK: "Cook Islands",
  GD: "Grenada",
  SM: "San Marino",
  XW: "West Bank",
  MP: "Northern Mariana Islands",
  FK: "Falkland Islands (Islas Malvinas)",
  VA: "Vatican City",
  NU: "Niue",
  GP: "Guadeloupe",
  GU: "Guam",
  MF: "Saint Martin",
  SH: "Saint Helena, Ascension, And Tristan Da Cunha",
  MS: "Montserrat",
  SX: "Sint Maarten",
  NR: "Nauru",
  XK: "Kosovo",
  BL: "Saint Barthelemy",
  VG: "British Virgin Islands",
  PW: "Palau",
  PM: "Saint Pierre And Miquelon",
  AI: "Anguilla",
  WF: "Wallis And Futuna",
  NF: "Norfolk Island",
  XR: "Svalbard",
  PN: "Pitcairn Islands",
  CX: "Christmas Island",
  GS: "South Georgia And South Sandwich Islands",
  MO: "Macau",
  PR: "Puerto Rico",
  VI: "U.S. Virgin Islands",
  TK: "Tokelau",
  AQ: "Antarctica",
  GG: "Guernsey",
  AN: "Netherlands Antilles",
};

// TBD : to be deprecated
const dup = [
  { city: "Alta Sierra", state_name: "California" },
  { city: "Arroyo Hondo", state_name: "New Mexico" },
  { city: "Beavertown", state_name: "Pennsylvania" },
  { city: "Buckhead", state_name: "Georgia" },
  { city: "Bull Run", state_name: "Virginia" },
  { city: "Caney", state_name: "Oklahoma" },
  { city: "Centerville", state_name: "California" },
  { city: "Chula Vista", state_name: "Texas" },
  { city: "Clear Lake", state_name: "South Dakota" },
  { city: "Cottonwood", state_name: "California" },
  { city: "Fairfax", state_name: "California" },
  { city: "Fairview", state_name: "Oregon" },
  { city: "Franklin", state_name: "Pennsylvania" },
  { city: "Fredericksburg", state_name: "Pennsylvania" },
  { city: "Georgetown", state_name: "Pennsylvania" },
  { city: "Halls", state_name: "Tennessee" },
  { city: "Harrisburg", state_name: "Ohio" },
  { city: "Jefferson", state_name: "Pennsylvania" },
  { city: "Kingston", state_name: "Illinois" },
  { city: "La Cueva", state_name: "New Mexico" },
  { city: "La Madera", state_name: "New Mexico" },
  { city: "Lakeside", state_name: "California" },
  { city: "Lakewood", state_name: "Illinois" },
  { city: "Liberty", state_name: "Pennsylvania" },
  { city: "Lincoln Heights", state_name: "Ohio" },
  { city: "Lockwood", state_name: "California" },
  { city: "Loma Linda East", state_name: "Texas" },
  { city: "Long Hill", state_name: "Connecticut" },
  { city: "Marshallton", state_name: "Pennsylvania" },
  { city: "Middletown", state_name: "Pennsylvania" },
  { city: "Midway", state_name: "Florida" },
  { city: "Millerstown", state_name: "Pennsylvania" },
  { city: "Mountain View", state_name: "New Mexico" },
  { city: "Newburg", state_name: "Pennsylvania" },
  { city: "Newtown", state_name: "Pennsylvania" },
  { city: "Oak Grove", state_name: "Arkansas" },
  { city: "Oakland", state_name: "Pennsylvania" },
  { city: "Oakwood", state_name: "Ohio" },
  { city: "Palmarejo", state_name: "Puerto Rico" },
  { city: "Palmer", state_name: "Texas" },
  { city: "Pine Ridge", state_name: "South Carolina" },
  { city: "Red Hill", state_name: "Pennsylvania" },
  { city: "Rincon", state_name: "Puerto Rico" },
  { city: "Salem Heights", state_name: "Ohio" },
  { city: "San Antonio", state_name: "Puerto Rico" },
  { city: "Shiloh", state_name: "Ohio" },
  { city: "Silver Lake", state_name: "New Jersey" },
  { city: "Twin Lakes", state_name: "California" },
  { city: "Unionville", state_name: "Pennsylvania" },
  { city: "Valencia", state_name: "New Mexico" },
  { city: "Vicksburg", state_name: "Pennsylvania" },
  { city: "Westwood", state_name: "Pennsylvania" },
  { city: "White Lake", state_name: "New York" },
  { city: "Woodbury", state_name: "New York" },
  { city: "Mid Florida", state_name: "Florida" },
  { city: "Young America", state_name: "Minnesota" },
  { city: "Valley Forge", state_name: "Pennsylvania" },
  { city: "Henrico", state_name: "Virginia" },
].map((item) => `${item.city}, ${item.state_name}`);
const getKey = (loc: ILocation): string =>
  `${loc.city}, ${loc.county_name}, ${loc.state_id}, ${loc.iso2}`;
export const reprLocation = (loc: ILocation): string => {
  if (loc.level === 0 || (!loc.level && loc.iso2)) {
    if (loc.country === "Georgia" || loc.iso2 === "GE") return "Georgia (country)";
    return !iso2_to_country[loc.iso2] && loc.country ? loc.country : iso2_to_country[loc.iso2];
  } else if (loc.level === 1 && loc.state_id)
    return loc.state_name ? loc.state_name : stateIdToStateName(loc.state_id);
  else if (loc.level === 2) return `${loc.county_name} County, ${loc.state_id}`;
  else if (loc.level === 3 && loc.iso2 === "US") {
    if (dup.includes(`${loc.city}, ${loc.state_name}`))
      return `${loc.city}, ${loc.county_name} County, ${loc.state_id}`;
    return `${loc.city}, ${loc.state_id}`;
  } else if (loc.level === 3) return `${loc.city}, ${loc.country}`;
  else if (loc.level === 4 && loc.iso2 === "US") {
    if (dup.includes(`${loc.city}, ${loc.state_name}`))
      return `${loc.city}, ${loc.county_name} County, ${loc.state_id}`;
    return `${loc.city}, ${loc.state_id}`;
  } else if (loc.location) return loc.location;
  return getKey(loc);
};
export const locationAutoComplete = async (query: string): Promise<ILocation[]> => {
  // console.log(key);
  if (!query || query.length < 2)
    return new Promise<ILocation[]>((resolve, reject) => reject("Query is not long enough."));
  let key = query;
  let level = "";
  let limit = "";
  if (key.toLowerCase().endsWith(" city")) {
    level = "&level=3";
    key = query.slice(0, -5);
  } else if (key.toLowerCase().endsWith(" county")) {
    level = "&level=2";
    key = query.slice(0, -7);
  }
  if (key.length > 4) limit = `&limit=${key.length * 15}`;
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v2/geocode?name=${encodeURIComponent(
        key,
      )}${level}${limit}`,
      config,
    )
    .then((res) => {
      // console.log(
      //   "locationAutoComplete",
      //   `${process.env.REACT_APP_API_URL}/api/v2/geocode?name=${key}${level}${limit}`,
      //   res.data,
      // );
      return res.data;
    })
    .catch((e) => new Promise<ILocation[]>((resolve, reject) => reject(e)));
};
export const countryLocationAutoComplete = async (key: string) => {
  // if (!key)
  //   return new Promise<ILocation[]>((resolve, reject) => reject("Query is not long enough."));
  const limit = "&limit=10000";
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v2/geocode?name=${encodeURIComponent(
        key,
      )}&level=${0}${limit}`,
      config,
    )
    .then((res) => {
      return key.length === 0
        ? res.data
            .map((l: ILocation) => ({ ...l, label: reprLocation(l) }))
            .sort((a: ILocation, b: ILocation) => a.label?.localeCompare(b.label || ""))
        : res.data;
    })
    .catch((e) => new Promise<ILocation[]>((resolve, reject) => reject(e)));
};
export const stateLocationAutoComplete = async (key: string) => {
  // if (!key)
  //   return new Promise<ILocation[]>((resolve, reject) => reject("Query is not long enough."));
  const limit = "&limit=10000";
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/v2/geocode?name=${encodeURIComponent(
        key,
      )}&level=${1}${limit}`,
      config,
    )
    .then((res) => {
      return key.length === 0
        ? res.data
            .map((l: ILocation) => ({ ...l, label: reprLocation(l) }))
            .sort((a: ILocation, b: ILocation) => a.label?.localeCompare(b.label || ""))
        : res.data;
    })
    .catch((e) => new Promise<ILocation[]>((resolve, reject) => reject(e)));
};
export const aLocationIncludesBLocation = (a: ILocation, b: ILocation): boolean => {
  if (
    a.city === b.city &&
    a.county_name === b.county_name &&
    a.state_id === b.state_id &&
    a.iso2 === b.iso2
  )
    return true;
  if ((a.level || 0) <= (b.level || 0)) {
    if ((a.level === 0 || (!a.level && a.iso2)) && a.iso2 === b.iso2) return true;
    if (a.level === 1 && a.state_id === b.state_id) return true;
    if (a.level === 2 && a.county_name === b.county_name) return true;
    if (a.level > 2 && a.city === b.city) return true;
  }
  return false;
};
export const isLocationEligible = (eligibility: ILocation, queries: ILocation[]): boolean => {
  return (
    queries.filter(
      (q) =>
        aLocationIncludesBLocation(eligibility, q) || aLocationIncludesBLocation(q, eligibility),
    ).length > 0
  );
};
export const isLocationMatched = (loc: ILocation, matched: ILocation[]): boolean => {
  return matched.filter((m) => aLocationIncludesBLocation(m, loc)).length > 0;
};
export const toGibooLocationFromFlatData = (d: {
  location_level: number;
  location_iso2: string;
  location_city: string;
  location_county_name: string;
  location_state_id: string;
  location_country?: string;
  location_lat?: number;
  location_lng?: number;
  location_state_name?: string;
}): ILocation | undefined => {
  if (
    !d ||
    d.location_level === undefined ||
    d.location_level === null ||
    (d.location_level === 0 && !d.location_iso2) ||
    (d.location_level === 2 && !d.location_county_name) ||
    !d.location_city ||
    !d.location_state_id
  )
    return undefined;
  return {
    level: d.location_level,
    iso2: d.location_iso2,
    city: d.location_city,
    county_name: d.location_county_name,
    state_id: d.location_state_id,
    state_name: d.location_state_name || "",
    lat: d.location_lat || 0,
    lng: d.location_lng || 0,
    country: d.location_country || d.location_iso2 || "",
  };
};
