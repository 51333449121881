import { useSetRecoilState } from "recoil";
import ClickMeTooltip from "./ClickMeTooltip";
import Button from "./tailwind/Button";
import { openSigninSignup } from "../app/recoilStore";

export function SuggestEditButton({
  handleClick,
  showGuidance,
}: {
  handleClick?: () => void;
  showGuidance?: boolean;
}) {
  const setSignUpSignin = useSetRecoilState(openSigninSignup);
  return (
    <ClickMeTooltip id="suggest-an-edit" open={showGuidance} controlled>
      <Button
        className="h-[29px] w-fit"
        labelClass="!text-sm"
        label="Suggest an edit"
        id="suggest-an-edit"
        outline
        handleOnClick={() => {
          handleClick?.();
          setSignUpSignin({ show: true, type: "EDIT_SUGGEST" });
        }}
        prependIcon={
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M15.4617 2.67858C15.2466 2.46345 14.9912 2.29279 14.7101 2.17636C14.4291 2.05993 14.1278 2 13.8236 2C13.5194 2 13.2181 2.05993 12.937 2.17636C12.656 2.29279 12.4006 2.46345 12.1855 2.67858L3.47381 11.3903C3.21458 11.6495 3.02606 11.9712 2.92767 12.3246L2.01626 15.5797C1.99521 15.6551 1.99459 15.7348 2.01447 15.8106C2.03435 15.8864 2.07402 15.9555 2.12942 16.0109C2.18481 16.0663 2.25394 16.1059 2.32971 16.1258C2.40548 16.1457 2.48518 16.1451 2.56063 16.124L5.81506 15.2126C6.16851 15.1137 6.49052 14.9254 6.75004 14.6659L15.4617 5.95423C15.896 5.51981 16.14 4.93068 16.14 4.3164C16.14 3.70213 15.896 3.113 15.4617 2.67858ZM12.8106 3.30308C13.0793 3.0344 13.4438 2.8835 13.8238 2.88355C14.2038 2.88361 14.5683 3.03462 14.8369 3.30337C15.1056 3.57212 15.2565 3.9366 15.2565 4.31661C15.2564 4.69663 15.1054 5.06106 14.8366 5.32973L13.9293 6.23643L11.9033 4.20977L12.8106 3.30308ZM11.2788 4.83485L13.3054 6.86151L6.12495 14.0414C5.97287 14.1935 5.78417 14.3039 5.57705 14.3619L3.07849 15.0612L3.7778 12.5632C3.83592 12.3562 3.94628 12.1675 4.0983 12.0153L11.2788 4.83485Z"
                fill="#5C38A7"
              />
            </svg>
          </span>
        }
      />
    </ClickMeTooltip>
  );
}
