import React, { ReactNode, useEffect, useState } from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from "recharts";
import classNames from "classnames";
import {
  getRandomColor,
  toFixedWithMinimum,
  toUsdLong,
  toUsdShort,
} from "../../../../utils/formatHelper";
import useNPOIRSProfile, { RevenueModel } from "../../../../hooks/useNPOIRSProfile";
import { Divider } from "rsuite";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import Spacer from "../../../../components/Spacer";
import FinancialChartLoader from "../../financials/component/FinancialChartLoader";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import AssessmentResult from "../../../../components/AssessmentResult";
import PopupModal from "../../../../components/tailwind/PopupModal";
import { FinancialRevenueModelInfo } from "../../financials/pages/BusinessRevenueModel";
import { NpoTitle } from "./NpoDiversityEthnicity";
interface Ifinancial {
  npo_id?: string;
  org_id?: string;
  allowEdit?: boolean;
  isPrivateView?: boolean;
  compact?: boolean;
  recomendation?: boolean;
  showStats?: boolean;
  showInfo?: boolean;
  hideTitle?: boolean;
  children?: ReactNode;
  showChartLoader?: boolean;
}
const COLOR_MAP: any = {
  "Contribution and grants": "#E9FF70",
  "Portfolio revenue": "#FFD670",
  "Operation revenue": "#70D6FF",
};
const InfoTooltip = (props: any) => {
  const renderTitle = (value: string) => {
    return <span className="font-poppins text-xs font-semibold text-gray-900">{value}</span>;
  };
  const renderValue = (value: string) => {
    return <span className="font-poppins text-xs font-normal text-gray-900">{value}</span>;
  };
  if (props.active && props.payload && props.payload.length) {
    // const totalRevenue = (props.payload[0].payload?.contributions || 0) + (props.payload[0].payload?.investement || 0) + (props.payload[0].payload?.operation || 0);
    return (
      <div className="giboo-box flex flex-col gap-1">
        <p className="font-poppins font-semibold text-gray-900">
          {renderTitle(props.payload[0].payload.name)}
        </p>
        <div className="inline-flex gap-2 ">
          {renderValue("Annual average amount:")}
          {renderValue(toUsdLong(props.payload[0].payload?.amount || 0))}
        </div>
        <div className="inline-flex gap-2 ">
          {renderValue("Percentage:")}
          {renderValue(`${toFixedWithMinimum(props.payload[0].payload?.value || 0)}%`)}
        </div>
        <Divider className="my-2" />
        {renderTitle(`Total amount:`)}
        {renderValue(toUsdLong(props.totalRevenue))}
      </div>
    );
  }
  return null;
};
const RADIAN = Math.PI / 170;

function NpoFinancialRevenue({
  npo_id,
  org_id,
  allowEdit,
  isPrivateView,
  compact = false,
  recomendation,
  showInfo = true,
  showStats = true,
  hideTitle = false,
  showChartLoader = true,
  children,
}: Ifinancial) {
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { revenueModel, isLoading, latest_year, earliest_year } = useNPOIRSProfile(npo_id);
  const { data: businessModel, isLoading: isBusinessModelLoading } = useFinancialRecommendedActions(
    !isPrivateView && loading ? undefined : !isPrivateView && !org_id ? npo_id : org_id,
    "business_model",
    !isPrivateView && !org_id,
  );
  const renderContributionAndGrants = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Contribution and grants</p>
        <div className="flex items-center gap-5">
          <div className="flex h-[76px] w-[90px] shrink-0 flex-col items-center justify-evenly rounded bg-blue-100 font-poppins text-2xl font-semibold text-purple-500">
            {`${toFixedWithMinimum(revenueModel?.per_contributions || 0, 2, 1)}%`}
          </div>
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {businessModel?.Contributions_and_Grants?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2  ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              {businessModel?.Contributions_and_Grants?.recommendations || ""}
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderPortfolioRevenue = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Portfolio revenue</p>
        <div className="flex items-center gap-5">
          <div className="flex h-[76px] w-[90px] shrink-0 flex-col items-center justify-evenly rounded bg-blue-100 font-poppins text-2xl font-semibold text-purple-500">
            {`${toFixedWithMinimum(revenueModel?.per_investment || 0, 2, 1)}%`}
          </div>
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {businessModel?.Portfolio?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              {businessModel?.Portfolio?.recommendations || ""}
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderOperationRevenue = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Operation revenue</p>
        <div className="flex items-center gap-5">
          <div className="flex h-[76px] w-[90px] shrink-0 flex-col items-center justify-evenly rounded bg-blue-100 font-poppins text-2xl font-semibold text-purple-500">
            {`${toFixedWithMinimum(revenueModel?.per_operation || 0, 2, 1)}%`}
          </div>
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {businessModel?.Operations?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              {businessModel?.Operations?.recommendations || ""}
            </div>
          </div>
        )}
      </div>
    );
  };
  const chartLoading = recomendation ? loading : isLoading;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-5"
        showHeader={false}
        showActionButtons={false}
      >
        <FinancialRevenueModelInfo
          handleClose={() => {
            setShowInfoModal(false);
          }}
        />
      </PopupModal>
      <div
        className={classNames(
          "flex min-h-[100px] flex-col ",
          recomendation && "rounded border border-gray-300 bg-white p-5",
        )}
      >
        {!hideTitle && (
          <NpoTitle
            title="Business (Revenue) model"
            recomendation={recomendation}
            setShowInfoModal={setShowInfoModal}
          />
        )}
        {showChartLoader && chartLoading ? (
          <FinancialChartLoader title="Analyzing organization’s Business (Revenue) model" />
        ) : (
          <>
            {/* chart */}
            <div className="mx-auto my-5">
              <RevenueChart
                revenueModel={revenueModel}
                latest_year={latest_year}
                earliest_year={earliest_year}
                compact
              />
            </div>
            {/* info */}
            {showInfo &&
              (loading || isBusinessModelLoading ? (
                <MemoizedLoadingRecommendedAction
                  loading={loading || isBusinessModelLoading}
                  short
                />
              ) : (
                <AssessmentResult content={businessModel?.Summary || ""} />
              ))}
            {recomendation ? <Divider className="my-[40px]" /> : <Spacer height="20px" />}
            {/* stats */}
            {showStats &&
              (loading || isBusinessModelLoading ? (
                <MemoizedLoadingRecommendedAction loading={loading || isBusinessModelLoading} />
              ) : (
                <div className="bg-gray-50 px-6 py-5">
                  <h6 className="mb-5 font-poppins text-base font-semibold text-black">Stats</h6>
                  <div className="flex flex-col gap-10">
                    {renderContributionAndGrants()}
                    {renderPortfolioRevenue()}
                    {renderOperationRevenue()}
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      {!chartLoading && children && children}
    </>
  );
}
export default NpoFinancialRevenue;

export function RevenueChart({
  revenueModel,
  latest_year,
  earliest_year,
  hideInfo = false,
  wrapperClass,
  compact,
}: {
  revenueModel?: RevenueModel;
  latest_year?: number;
  earliest_year?: number;
  hideInfo?: boolean;
  wrapperClass?: string;
  compact?: boolean;
}) {
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  const data: any = [
    {
      name: "Contribution and grants",
      value: revenueModel?.per_contributions || 0,
      count: revenueModel?.per_contributions || 0,
      amount: revenueModel?.contributions || 0,
    },
    {
      name: "Portfolio revenue",
      value: revenueModel?.per_investment || 0,
      count: revenueModel?.per_investment || 0,
      amount: revenueModel?.investment || 0,
    },
    {
      name: "Operation revenue",
      value: revenueModel?.per_operation || 0,
      count: revenueModel?.per_operation || 0,
      amount: revenueModel?.operation || 0,
    },
  ]
    .sort((a, b) => a.value - b.value)
    .map((d) => ({ ...d, value: +toFixedWithMinimum(d.value) }));
  const totalRevenue =
    (revenueModel?.contributions || 0) +
    (revenueModel?.investment || 0) +
    (revenueModel?.operation || 0);
  const hasNoData =
    data
      .map((i: { value: any }) => i.value)
      .reduce((acc: any, value: any) => {
        return acc + value;
      }, 0) === 0;
  const indexWithvalue = data.reduce((acc: number[], item: any, index: number) => {
    if (item.count > 0) {
      acc.push(index);
    }
    return acc;
  }, []);
  const renderHeader = () => {
    return (
      <div className="-mb-2 inline-flex flex-wrap items-center gap-5 md:flex-nowrap">
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#E9FF70",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Contribution and grants</p>
        </div>
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#FFD670",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Portfolio revenue</p>
        </div>
        <div className="inline-flex items-center gap-1">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#70D6FF",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Operation revenue</p>
        </div>
      </div>
    );
  };
  const renderActiveShape = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text> */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          fontSize={14}
          y={ey}
          dy={-5}
          textAnchor={textAnchor}
          fill="#5F5F5F"
        >
          {payload.name}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={24}
          className="font-poppins text-2xl font-semibold"
          textAnchor={textAnchor}
          fill="#000"
        >
          {toUsdLong(payload.amount)}
        </text>
      </g>
    );
  };
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (value && index === activeIndex) {
      return (
        <text
          x={x}
          y={y}
          fill="#5C38A7"
          dominantBaseline="central"
          textAnchor="middle"
          className="text-sm font-semibold"
        >
          {`${value}%`}
        </text>
      );
    }
  };
  const renderPie = () => {
    return (
      <Pie
        data={data}
        activeIndex={activeIndex ? activeIndex : indexWithvalue[indexWithvalue?.length - 1] || 0}
        activeShape={renderActiveShape}
        stroke=""
        cx="50%"
        cy="50%"
        startAngle={90}
        endAngle={450}
        innerRadius={60}
        isAnimationActive={false}
        labelLine={false}
        minAngle={6}
        label={renderCustomizedLabel}
        outerRadius={110}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
        onMouseEnter={(_, index) => {
          setActiveIndex(index);
        }}
      >
        {data.map((entry: any, index: number) => {
          return (
            <Cell
              key={`cell-${index}`}
              fill={COLOR_MAP[entry.name || ""]}
              fillOpacity={activeIndex === index ? 1 : 0.8}
              tabIndex={index}
            />
          );
        })}
      </Pie>
    );
  };
  return (
    <div className={classNames("flex flex-col items-start justify-center", wrapperClass)}>
      <div className="flex w-full justify-center">{renderHeader()}</div>
      <PieChart width={700} height={360}>
        {!hasNoData && (
          <Tooltip
            content={<InfoTooltip totalRevenue={totalRevenue} latest_year={latest_year} />}
            cursor={false}
            wrapperStyle={{ outline: "none" }}
          />
        )}
        {hasNoData ? (
          <Pie
            data={[{ name: "No Data", value: 1 }]}
            fill="#eeeeee"
            stroke=""
            cx="50%"
            cy="50%"
            startAngle={140}
            endAngle={-220}
            innerRadius={60}
            isAnimationActive={false}
            labelLine={false}
            label={false}
            outerRadius={110}
            paddingAngle={0}
            dataKey="value"
          >
            <Label value="No Data" position="center" />
          </Pie>
        ) : (
          renderPie()
        )}
      </PieChart>
      <div className="flex w-full justify-end">
        <h6 className="font-poppins text-xs text-gray-700">
          Year: {earliest_year === latest_year ? latest_year : `${earliest_year}-${latest_year}`}
        </h6>
      </div>
    </div>
  );
}
