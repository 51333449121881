import axios from "axios";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import config from "../api";
import { MIX_PANEL_TOKEN } from "../App";
import NavigationUserProfile from "../components/NavigationUserProfile";
import useUser from "../hooks/useUser";
function TopNav({ isOnboarding = false }: { isOnboarding?: boolean }) {
  const signinPaths = ["/agreement"];
  const [user, setUser] = useUser();
  const navigate = useNavigate();
  const logoutHandle = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/api/v2/auth/logout",
        config,
      );
      // console.log("ok", response);
    } catch (err) {
      // console.log("error", err);
    } finally {
      if (MIX_PANEL_TOKEN) {
        mixpanel.reset();
      }
      setUser(null);
      navigate("/", { replace: true });
    }
  };
  return (
    <div className="flex justify-end">
      <div>
        <NavigationUserProfile handleLogout={() => logoutHandle()} isOnboarding={isOnboarding} />
      </div>
    </div>
  );
}
export default TopNav;
