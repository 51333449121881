import React, { useEffect, useState } from "react";
import Button from "../../../../components/tailwind/Button";
import Spacer from "../../../../components/Spacer";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { setUserDictionaryValue } from "../../../../hooks/useUserDictionary";
import GotoSmartSearchContext from "../../../../components/useGotoSmartSearch";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import { FAKE_LOADING_TIME } from "../FinancialDetail";
import { getURLSearchParamsFromSearchQuery } from "../../../../types/search";
import useOnboardingData from "../../../../hooks/useOnboarding";
import Spinner from "../../../../components/Spinner";
import { MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";

export default function PlanningAhead({
  onBack,
  onNext,
  npo_id,
  org_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  org_id?: string;
  isBack?: boolean;
}) {
  const mxEvent = useGibooMixpanel(undefined, true);
  const [onboardingData] = useOnboardingData();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { data: assessment, isLoading: isAssessmentLoading } = useFinancialRecommendedActions(
    org_id,
    "assessment",
  );
  return (
    <div className="flex min-h-[600px] max-w-[760px] flex-col gap-4">
      <div className="w-full border-b border-gray-300 ">
        <h6 className="text-base font-semibold text-gray-900 underline underline-offset-[6px]">
          Planning ahead for fundraising needs
        </h6>
      </div>
      <div className="flex min-h-fit flex-col rounded border border-gray-300 bg-white p-5">
        <div>
          <h5 className="font-poppins text-base font-semibold text-black">Overall assessment</h5>
          <Spacer height="8px" />
          {loading || isAssessmentLoading ? (
            <MemoizedLoadingRecommendedAction loading={loading || isAssessmentLoading} short />
          ) : (
            <pre className="whitespace-pre-line font-poppins text-sm text-gray-800">
              {assessment?.Summary || ""}
            </pre>
          )}
        </div>
      </div>
      <div className="flex min-h-[100px] flex-col rounded border border-gray-300 bg-white p-5">
        {loading || isAssessmentLoading ? (
          <MemoizedLoadingRecommendedAction
            loading={loading || isAssessmentLoading}
            max={2}
            hidePercentage
          />
        ) : (
          <div className="flex flex-col gap-5">
            <h5 className="font-poppins text-base font-semibold text-black">Consider this</h5>
            <div
              className="flex cursor-pointer items-center justify-between gap-5 rounded border border-gray-300 px-5 py-4 hover:bg-gray-50"
              onClick={() => {
                mxEvent(MIXPANEL_EVENTS_V2.financial.assessment_recommendation.clicked, {
                  recommendedAction: "find unrestricted funding",
                });
                mxEvent(MIXPANEL_EVENTS_V2.financial[""].successful);
                navigate(
                  `/search?${getURLSearchParamsFromSearchQuery(
                    onboardingData,
                    {
                      funding_type: ["general"],
                    },
                    0,
                  ).toString()}`,
                );
              }}
            >
              <div className="flex flex-col gap-[5px]">
                <h6 className="font-poppins text-base font-semibold text-purple-500">
                  Find unrestricted funding
                </h6>
                <h6 className="font-poppins text-sm  text-gray-800">
                  After conducting a comprehensive financial analysis, we highly recommend
                  considering grant opportunities that offer unrestricted funding. We strongly
                  encourage your organization to delve into these prospects as a strategic avenue
                  for securing additional financial support.
                </h6>
              </div>
              <div className="grid shrink-0 items-center">
                <i className="gi-angle-right gi-md" />
              </div>
            </div>
            {/* <div className="flex items-center justify-between gap-5 rounded border  border-gray-300 px-5 py-4">
            <div className="flex flex-col gap-[5px]">
              <h6 className="font-poppins text-base font-semibold text-purple-500">
                Communicate with funders
              </h6>
              <h6 className="font-poppins text-xs  text-gray-800">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam atque ab non
                accusamus perspiciatis inventore?{" "}
              </h6>
            </div>
            <div className="grid shrink-0 items-center">
              <i className="gi-angle-right gi-md" />
            </div>
          </div> */}
            <GotoSmartSearchContext type="virtual_grant" project_id={undefined}>
              {({ go, isLoading }) => (
                <div
                  className="flex cursor-pointer items-center justify-between gap-5 rounded border border-gray-300 px-5 py-4 hover:bg-gray-50"
                  onClick={() => {
                    mxEvent(MIXPANEL_EVENTS_V2.financial.assessment_recommendation.clicked, {
                      recommendedAction: "recommended grant opportunities",
                    });
                    mxEvent(MIXPANEL_EVENTS_V2.financial[""].successful);
                    go();
                  }}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="flex flex-col gap-[5px]">
                        <h6 className="font-poppins text-base font-semibold text-purple-500">
                          Recommended grant opportunities
                        </h6>
                        <h6 className="font-poppins text-sm  text-gray-800">
                          Following the financial analysis, we&apos;d like to recommend grant
                          opportunities that align with your organization&apos;s mission and focus
                          areas. Include details such as eligibility criteria, application
                          deadlines, and any specific focus areas of the grants and encourage your
                          organization to explore these opportunities for additional financial
                          support.
                        </h6>
                      </div>
                      <div className="grid shrink-0 items-center">
                        <i className="gi-angle-right gi-md" />
                      </div>
                    </>
                  )}
                </div>
              )}
            </GotoSmartSearchContext>
          </div>
        )}
      </div>
      <div className={classNames("flex h-[38px] items-center justify-start gap-2")}>
        <Button
          className="!h-[37px]"
          id="btn-back"
          handleOnClick={() => {
            onBack?.();
          }}
          label="Back"
          outline
          prependIcon={<i className="gi-left-arrow gi" />}
        />
        <Button
          className="!h-[37px]"
          id="btn-next"
          handleOnClick={() => {
            mxEvent(MIXPANEL_EVENTS_V2.financial[""].successful);
            onNext?.();
            setTimeout(() => {
              navigate("/organization", { state: { activeTab: "financials" } });
            }, 300);
          }}
          label="See my organization profile"
          appendIcon={
            <div className="-mt-1 !rotate-180">
              <i className="gi-left-arrow" />
            </div>
          }
        />
      </div>
      <Spacer height="100px" />
    </div>
  );
}
