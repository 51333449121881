import React, { ReactNode, useEffect, useRef, useState } from "react";
import TextInput from "../../components/tailwind/TextInput";
import Spacer from "../../components/Spacer";
import Table, { ColumnType } from "rc-table";
import { toDateString } from "../../utils/formatHelper";
import { useNavigate } from "react-router-dom";
import PlainDropdownSelector from "../../components/tailwind/dropdown/PlainDropdownSelector";
import UserProfile from "../../components/UserProfile";
import EMPTY from "../../assets/images/empty-table.svg";
import { PROJECT_PURPOSE_OPTIONS } from "./ProjectCreationNew";
import useProjects from "../../hooks/project/useProjects";
import useOnboardingData from "../../hooks/useOnboarding";
import ProjectCreationModal from "../../components/ProjectCreationModal";
import { IProject } from "../../types/project";
import PlainButton from "../../components/PlainButton";
import { IOption } from "../../components/tailwind/AsyncCreatableSelector";
import DropdownSelector from "../../components/dropdown/DropdownSelector";
import classNames from "classnames";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import PrimaryButton from "../../components/PrimaryButton";
import IMG_TRASH from "../../assets/project-mgt/trash.svg";
import AlertModal from "../../components/tailwind/AlertModal";
import { deleteProjectAsync } from "../../app/projectSlice";
import { useAppDispatch } from "../../app/store";
import useProject from "../../hooks/project/useProject";
import ProjectInformationModal from "../../components/ProjectInformationModal";
import usePageTitle from "../../hooks/usePagetitle";
import { MIXPANEL_EVENTS_V2 } from "../../mixpanel/mixpanel";
interface IProps {
  customClass?: string | undefined;
}
const SORT_TYPE: IOption[] = [
  { label: "A-Z", value: "A-Z" },
  { label: "Z-A", value: "Z-A" },
];
function ProjectList({ customClass, ...props }: IProps) {
  const [onboardingData] = useOnboardingData();
  const [projects] = useProjects();
  usePageTitle("My projects", projects.data.length);
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState(projects.data);
  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState<IProject>();
  const [showProjectCreationModal, setShowProjectCreationModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<IOption | undefined>(SORT_TYPE[0]);
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const hasEditPermission = true;
  const inputRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(inputRef, () => {
    if (open) setOpen(false);
  });
  useEffect(() => {
    if (projects.isFetched) {
      setLoading(false);
      if (projects.data.length > 0) {
        setData(projects.data);
      }
    }
  }, [projects]);

  useEffect(() => {
    setData(projects.data.filter((i) => i.name.includes(search)));
  }, [search]);

  useEffect(() => {
    if (data.length === 0) return;
    if (type?.value === "A-Z") {
      setData([...data].sort((a, b) => a.name.localeCompare(b.name)));
    } else {
      setData([...data].sort((a, b) => b.name.localeCompare(a.name)));
    }
  }, [type]);

  const renderEmpty = () => {
    return (
      <div className="flex h-full w-full flex-col bg-white">
        <Spacer height="140px" />
        <div className="flex justify-center">
          <div className="grid w-[361px] place-items-center">
            <img src={EMPTY} className="h-[142px] w-[60px]" />
            <Spacer height="36px" />
            <p className="text-center text-sm font-normal text-gray-800">
              Looks like you haven&apos;t created a project yet! <br />
              Create a project to start finding funders and grants.
            </p>
            <Spacer height="20px" />
            <PrimaryButton
              size="md"
              id={`btn-create-project`}
              label={"Create a project"}
              className="GA_TAG_click_createaproject !h-[29px] !text-sm"
              leftIconClass="gi-add gi-md"
              handleOnClick={() => {
                // mxEvent(MIXPANEL_EVENTS.PROJECT_CREATION.PROJECT_CREATE_CLICKED, {
                //   organizationId: onboardingData._id,
                //   location: location.pathname,
                // });
                setShowProjectCreationModal(true);
              }}
            />
          </div>
        </div>
        <Spacer height="140px" />
      </div>
    );
  };
  const renderWithAlignment = (
    component: ReactNode,
    position: "start" | "end" | "center" = "start",
  ) => {
    return <div className={classNames("mx-4 flex w-full", `justify-${position}`)}>{component}</div>;
  };
  const columns: ColumnType<any>[] = [
    {
      title: "Project name",
      dataIndex: "_id",
      key: "_id",
      width: 434,
      render: (id: any, rowData: any) => {
        return (
          <ProjectName
            rowData={rowData}
            onClick={() => {
              navigate(`/project/${rowData?._id}`);
            }}
          />
        );
      },
    },
    {
      title: "Owner",
      dataIndex: "_id",
      key: "_id",
      width: 150,
      render: (id: any, rowData: any) => {
        return renderWithAlignment(<UserProfile userId={rowData.user_id} />, "start");
      },
    },
    {
      title: "Date created",
      dataIndex: "_id",
      key: "_id",
      width: 150,
      render: (id: any, rowData: any) => {
        return renderWithAlignment(<DateCreated rowData={rowData} />, "start");
      },
    },
    {
      title: "Delete",
      dataIndex: "",
      key: "_id",
      width: 50,
      render: (rowData: any) => {
        return (
          <ProjectDelete
            rowData={rowData}
            onClick={() => {
              setShowAlert(true);
              setDeleteId(rowData._id);
            }}
          />
        );
      },
    },
  ];

  const hasData = projects.data.length > 0;
  return (
    <>
      {showAlert && deleteId && (
        <AlertModal
          variant="purple"
          handleCancel={() => {
            setShowAlert(false);
            setDeleteId(undefined);
          }}
          isLoading={isDeleteLoading}
          handleConfirm={() => {
            if (deleteId === undefined) return;
            setDeleteLoading(true);
            Promise.resolve(dispatch(deleteProjectAsync(deleteId))).then(() => {
              setDeleteLoading(false);
              setDeleteId(undefined);
              setShowAlert(false);
            });
          }}
          confirmTitle={"Delete"}
          title="Delete"
          content={
            <div className="p-10 text-base">
              Are you sure you want to delete project &quot;
              <b>{projects.data.find((t) => t._id === deleteId)?.name}</b>&quot; ?
            </div>
          }
          isOpen={showAlert}
          handleClose={() => setShowAlert(false)}
        />
      )}
      {showProjectCreationModal && (
        <ProjectInformationModal
          from_for_mixpanel="projects"
          open={showProjectCreationModal}
          setOpen={setShowProjectCreationModal}
          id=""
          isNewProject
          withOutFunderGrant
        />
      )}
      <Spacer height="60px" />
      <div className="w-fulls mx-auto flex h-[calc(100vh-118px)] w-[1280px] flex-col rounded-t-xl border border-gray-300 bg-white">
        <div className="inline-flex items-center gap-2 border-b border-gray-300 px-4  py-3">
          <span>
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="30" height="30" rx="3.65854" fill="#EAF9EA" />
              <path
                d="M12.5229 11.1914C12.3713 11.1914 12.2259 11.2516 12.1187 11.3588C12.0114 11.466 11.9512 11.6114 11.9512 11.7631C11.9512 11.9147 12.0114 12.0601 12.1187 12.1673C12.2259 12.2745 12.3713 12.3347 12.5229 12.3347H18.2393C18.3909 12.3347 18.5363 12.2745 18.6435 12.1673C18.7508 12.0601 18.811 11.9147 18.811 11.7631C18.811 11.6114 18.7508 11.466 18.6435 11.3588C18.5363 11.2516 18.3909 11.1914 18.2393 11.1914H12.5229ZM11.189 14.8118C11.189 14.6602 11.2493 14.5148 11.3565 14.4076C11.4637 14.3004 11.6091 14.2402 11.7607 14.2402H16.7149C16.8665 14.2402 17.012 14.3004 17.1192 14.4076C17.2264 14.5148 17.2866 14.6602 17.2866 14.8118C17.2866 14.9634 17.2264 15.1088 17.1192 15.216C17.012 15.3233 16.8665 15.3835 16.7149 15.3835H11.7607C11.6091 15.3835 11.4637 15.3233 11.3565 15.216C11.2493 15.1088 11.189 14.9634 11.189 14.8118ZM13.2851 17.289C13.1335 17.289 12.9881 17.3492 12.8808 17.4564C12.7736 17.5636 12.7134 17.709 12.7134 17.8606C12.7134 18.0122 12.7736 18.1576 12.8808 18.2648C12.9881 18.372 13.1335 18.4323 13.2851 18.4323H18.2393C18.3909 18.4323 18.5363 18.372 18.6435 18.2648C18.7508 18.1576 18.811 18.0122 18.811 17.8606C18.811 17.709 18.7508 17.5636 18.6435 17.4564C18.5363 17.3492 18.3909 17.289 18.2393 17.289H13.2851Z"
                fill="#3E9C35"
              />
              <path
                d="M7.37805 8.71275C7.37805 7.97647 7.97561 7.37891 8.71189 7.37891H21.2881C22.0244 7.37891 22.622 7.97647 22.622 8.71275V21.289C22.622 21.6427 22.4814 21.982 22.2313 22.2321C21.9811 22.4823 21.6419 22.6228 21.2881 22.6228H8.71189C8.35814 22.6228 8.01887 22.4823 7.76872 22.2321C7.51858 21.982 7.37805 21.6427 7.37805 21.289V8.71275ZM8.71189 8.5222C8.66136 8.5222 8.61289 8.54227 8.57715 8.57801C8.54142 8.61374 8.52134 8.66221 8.52134 8.71275V21.289C8.52134 21.3941 8.60671 21.4795 8.71189 21.4795H21.2881C21.3386 21.4795 21.3871 21.4594 21.4228 21.4237C21.4586 21.388 21.4787 21.3395 21.4787 21.289V8.71275C21.4787 8.66221 21.4586 8.61374 21.4228 8.57801C21.3871 8.54227 21.3386 8.5222 21.2881 8.5222H8.71189Z"
                fill="#3E9C35"
              />
            </svg>
          </span>
          <h4 className="px-3 font-poppins text-xl font-semibold">Projects</h4>
        </div>
        {hasData ? (
          <div className=" h-full w-full overflow-y-scroll rounded p-10">
            <Table<any>
              {...(hasEditPermission && {
                footer: () => (
                  <AddNewProject
                    addProject={() => {
                      setShowProjectCreationModal(true);
                    }}
                  />
                ),
              })}
              columns={columns}
              data={data}
              tableLayout="auto"
              rowKey={"_id"}
              rowClassName="cursor-pointer"
              className="w-full"
              scroll={{ x: "max-content" }}
            />
          </div>
        ) : (
          <div className="h-[calc(70vh)] px-3">{renderEmpty()}</div>
        )}
      </div>
    </>
  );
}
ProjectList.defaultProps = {};
export default ProjectList;

function ProjectType({ rowData }: { rowData: any }) {
  return (
    <div className="font-poppins text-sm font-medium text-gray-700">
      <div className="flex h-full w-full items-center overflow-hidden text-ellipsis">
        {PROJECT_PURPOSE_OPTIONS.find((o) => o.value === rowData?.project_type)?.label || ""}
      </div>
    </div>
  );
}
function DateCreated({ rowData }: { rowData: any }) {
  return (
    <div className="font-poppins text-xs font-medium text-gray-700">
      <div className="flex h-full w-full items-center" title={rowData.created_at}>
        {toDateString(rowData.created_at, true)}
      </div>
    </div>
  );
}
function ProjectName({ rowData, onClick }: { rowData: any; onClick: () => void }) {
  return (
    <div className="ellipsis w-full  pl-3">
      <p
        className="flex h-full w-fit cursor-pointer items-center truncate overflow-ellipsis  font-poppins text-sm font-semibold text-purple-500 decoration-2 underline-offset-4 hover:underline"
        title={rowData.name}
        onClick={onClick}
      >
        {`${rowData?.name || "Untitled"}`}
      </p>
    </div>
  );
}
function ProjectDelete({ rowData, onClick }: { rowData: any; onClick: () => void }) {
  const { hasEditPermission } = useProject(rowData._id);
  return (
    <div className="ellipsis grid w-full place-items-center ">
      {hasEditPermission && (
        <p
          className="flex h-full w-fit cursor-pointer items-center truncate overflow-ellipsis  font-poppins text-sm font-semibold text-purple-500 decoration-2 underline-offset-4 hover:underline"
          title={rowData.name}
          onClick={onClick}
        >
          <img src={IMG_TRASH} alt="trash" className="h-5 w-5" />
        </p>
      )}
    </div>
  );
}
function AddNewProject({ addProject }: { addProject: () => void }) {
  return (
    <div
      className={classNames(
        "flex !h-[36px] w-full cursor-pointer items-center justify-start gap-2 !py-2 pl-2 hover:bg-purple-50",
      )}
    >
      <span
        className="flex !h-[36px] w-full items-center truncate text-sm text-gray-700"
        onClick={() => addProject()}
      >
        <i className="gi gi-add mr-1 " />
        <h5>Create a new project</h5>
      </span>
    </div>
  );
}
