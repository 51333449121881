import classNames from "classnames";
import { ReactNode } from "react";

interface INoInformation {
  img?: string;
  content?: ReactNode;
  title?: string;
  wrapperClass?: string;
  titleClass?: string;
  imgClass?: string;
  outline?: boolean;
}
export default function NoInformation({
  title = "No information available",
  outline = true,
  titleClass,
  imgClass,
  ...props
}: INoInformation) {
  return (
    <div
      className={classNames(
        "flex w-full flex-col items-center rounded border border-gray-300 py-16",
        outline && "bg-white",
        !outline && "border-none !py-4",
        props.wrapperClass,
      )}
    >
      {props.img && <img src={props.img} className={imgClass} />}
      <p className={classNames("mb-2 mt-4 font-semibold", titleClass)}>{title}</p>
      {props.content && props.content}
    </div>
  );
}
