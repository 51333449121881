import { getOnboardingStatus, updateOnboardingAsync } from "../../../app/onboardingSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import SecondaryButton from "../../../components/SecondaryButton";
import IMG_1 from "../../../assets/onboarding/card1.svg";
import IMG_2 from "../../../assets/onboarding/card2.svg";
import IMG_3 from "../../../assets/onboarding/card3.svg";
import CardButton from "../../../components/CardButton";
import useTotalOrganizationMember from "../../../hooks/useTotalOrganizationMember";
import { getTotalOrganizationMembersId } from "../../../hooks/useTotalOrganizationMembersData";
import useOnboardingData from "../../../hooks/useOnboarding";
import useUser from "../../../hooks/useUser";
import { useEffect } from "react";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import { Information, MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
function OnboardingType() {
  const mxEvent = useGibooMixpanel();
  const dispatch = useAppDispatch();
  const { type } = useAppSelector((state) => getOnboardingStatus(state));
  const [onboardingData] = useOnboardingData();
  const [user] = useUser();
  const { update } = useTotalOrganizationMember(
    onboardingData._id,
    getTotalOrganizationMembersId({ _id: user?._id || "", type: "user" }),
  );
  useEffect(() => {
    // resetting title
    update({ title: undefined });
  }, []);
  return (
    <div className="onboarding flex flex-col items-start">
      <h3 className="onboarding-header onboarding-width-lg">Awesome! Let&apos;s dive deeper.</h3>
      <h3 className="onboarding-header mt-1 ">
        Do you already have an associated nonprofit organization?
      </h3>
      <div className="onboarding-body-row onboarding-width-sm2 !mt-10 flex-wrap justify-center md:flex-nowrap md:justify-start">
        <CardButton
          id="btn-type-1"
          handleOnClick={() => {
            mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
              field: Information.ORG_TYPE,
              orgType: "us",
            });
            dispatch(
              updateOnboardingAsync(
                {
                  type: 1,
                  step: 0,
                  npo_id: undefined,
                  npo_ein_requested: undefined,
                  npo: undefined,
                  npo_name_requested: undefined,
                  npo_location_requested: undefined,
                  service_loc_requested: undefined,
                  focus_area_text: undefined,
                  beneficiary_text: undefined,
                  strategy_text: undefined,
                  summary: undefined,
                  focus_area_requested: undefined,
                  beneficiary_requested: undefined,
                  program_requested: undefined,
                  stage_requested: undefined,
                },
                +1,
              ),
            );
          }}
          className="h-[255px] w-[228px] hover:bg-purple-50 active:border-purple-500"
          imgClas="!h-[140px] !w-[140px]"
          img={IMG_1}
          label="I'm associated with an existing nonprofit organization in the US."
        />
        <CardButton
          id="btn-type-2"
          className="h-[255px] w-[228px] hover:bg-purple-50 active:border-purple-500"
          imgClas="!h-[140px] !w-[140px]"
          handleOnClick={() => {
            mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
              field: Information.ORG_TYPE,
              orgType: "outside_us",
            });
            dispatch(
              updateOnboardingAsync(
                {
                  type: 5,
                  npo_id: "",
                  npo: undefined,
                  npo_profile: undefined,
                  npo_uid: undefined,
                  step: 0,
                  tax_exemption_requested: undefined,
                  operating_budget_requested: undefined,
                  n_employee_requested: undefined,
                  n_volunteer_requested: undefined,
                  mission_requested: undefined,
                  focus_area_requested: undefined,
                  beneficiary_requested: undefined,
                  program_requested: undefined,
                  service_loc_requested: undefined,
                  npo_ein_requested: undefined,
                  city: undefined,
                  address1: undefined,
                  zipcode: undefined,
                  state_id: undefined,
                  website: undefined,
                  focus_area_text: undefined,
                  beneficiary_text: undefined,
                  strategy_text: undefined,
                  summary: undefined,
                  stage_requested: undefined,
                },
                +1,
              ),
            );
          }}
          img={IMG_2}
          label="My nonprofit is not based in the US."
          isComingSoon
          labelClass="w-[150px] text-center"
          disabled
        />
        <CardButton
          id="btn-type-3"
          className="h-[255px] w-[228px] hover:bg-purple-50 active:border-purple-500"
          imgClas="!h-[140px] !w-[140px]"
          handleOnClick={() => {
            mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
              field: Information.ORG_TYPE,
              orgType: "not_launched",
            });
            dispatch(
              updateOnboardingAsync(
                {
                  npo_id: "",
                  npo: undefined,
                  npo_profile: undefined,
                  npo_uid: undefined,
                  type: 3,
                  step: 0,
                  tax_exemption_requested: undefined,
                  operating_budget_requested: undefined,
                  n_employee_requested: undefined,
                  n_volunteer_requested: undefined,
                  mission_requested: undefined,
                  focus_area_requested: undefined,
                  beneficiary_requested: undefined,
                  program_requested: undefined,
                  service_loc_requested: undefined,
                  npo_ein_requested: undefined,
                  city: undefined,
                  address1: undefined,
                  zipcode: undefined,
                  state_id: undefined,
                  website: undefined,
                  focus_area_text: undefined,
                  beneficiary_text: undefined,
                  strategy_text: undefined,
                  summary: undefined,
                  stage_requested: undefined,
                },
                +1,
              ),
            );
          }}
          img={IMG_3}
          label="My nonprofit has not been launched."
          isComingSoon
          labelClass="w-[150px] text-center"
          disabled
        />
      </div>
    </div>
  );
}
export default OnboardingType;
