import { Dialog } from "@headlessui/react";
import PlainButton from "./PlainButton";
import React, { Dispatch, SetStateAction, useState } from "react";
import CloseButton from "./CloseButton";
import IMG_PROJECTS from "../assets/project-mgt/mytask-placeholder.svg";
import { IProject } from "../types/project";
import { FUNDRAISING_PROCESS, TaskAsistantDocumentType } from "./GibooAiTaskAssistant";
import IMAGE_CUSTOM from "../assets/project-mgt/custom.png";
import IMAGE_0 from "../assets/project-mgt/outreach_0.png";
import IMAGE_1 from "../assets/project-mgt/outreach_1.png";
import IMAGE_2 from "../assets/project-mgt/outreach_2.png";
import IMAGE_3 from "../assets/project-mgt/outreach_3.png";
import IMAGE_4 from "../assets/project-mgt/outreach_4.png";
import IMAGE_5 from "../assets/project-mgt/outreach_5.png";
import IMAGE_6 from "../assets/project-mgt/outreach_6.png";
import IMAGE_7 from "../assets/project-mgt/outreach_7.png";
import IMAGE_8 from "../assets/project-mgt/outreach_8.png";
import IMAGE_9 from "../assets/project-mgt/outreach_9.png";
import IMAGE_10 from "../assets/project-mgt/outreach_10.png";
import IMAGE_11 from "../assets/project-mgt/outreach_11.png";
import IMAGE_12 from "../assets/project-mgt/outreach_12.png";
import classNames from "classnames";
import { scrollBarClass } from "../utils/classes";
import { zIndexBackdrop, zIndexDialog, zIndexOutReach } from "../types/zIndex";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
interface OutReachPickerModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  onSelect?: (data: TaskAsistantDocumentType) => void;
  showBackDrop?: boolean;
  loading?: boolean;
}
const OUTREACH_IMAGE: any = {
  CUSTOM: IMAGE_CUSTOM,
  PHONE_CALL_SCRIPT: IMAGE_0,
  LETTER_OF_INTENT: IMAGE_1,
  INTRO_EMAIL: IMAGE_2,
  INTRO_LINKEDIN_MESSAGE: IMAGE_3,
  LETTER_APPEAL: IMAGE_4,
  LETTER_NO_FOLLOW_UP: IMAGE_5,
  LETTER_REMINDER: IMAGE_6,
  LETTER_POSITIVE_FOLLOW_UP_FEEDBACK: IMAGE_7,
  LETTER_NEGATIVE_FOLLOW_UP_FEEDBACK: IMAGE_8,
  LETTER_TO_OLD_FUNDER: IMAGE_9,
  ACK_IN_KIND_DONATION: IMAGE_10,
  EOY_APPEAL_OLD_FUNDER: IMAGE_11,
  EOY_APPEAL_NEW_FUNDER: IMAGE_12,
};
const OUTREACH_DESCRIPTION: any = {
  CUSTOM: (
    <>
      Generate a custom document that isn&apos;t bound by specific prompts. Utilize our Giboo AI to
      create any type of document by simply inputting commands.
      <br />
      <br />
      You can generate grant writing documents, reporting documents, or any other types of outreach
      documents using this tool.
    </>
  ),
  PHONE_CALL_SCRIPT: (
    <>
      Crafting a compelling letter of inquiry demands more than just precise language; it&apos;s
      about captivating your audience from the start.
      <br />
      <br />
      Giboo&apos;s AI effortlessly blends project details with engaging elements, including your
      organization&apos;s mission statement, for maximum impact. Minimize effort and maximize appeal
      with our tool, ensuring strong initial impact in securing funding.
    </>
  ),
  LETTER_OF_INTENT: (
    <>
      Crafting a compelling donation request call script demands precise wording, addressing
      concerns, and resonating impact.
      <br />
      <br />
      Giboo&apos;s AI assistant leverages your project information and organization&apos;s mission
      statement to streamline the process seamlessly. This approach minimizes your effort while
      maximizing fundraising efficiency and effectiveness, ensuring impactful communication with
      funders.
    </>
  ),
  INTRO_EMAIL: (
    <>
      Crafting an introductory email for donation support necessitates impactful storytelling and
      personalized engagement.
      <br />
      <br />
      Giboo&apos;s AI assistant streamlines this process, guiding you to overcome tone and content
      challenges effortlessly. It&apos;s a valuable asset for enhancing fundraising communication,
      ensuring your messages are not only effective but also resonate deeply with your audience.
    </>
  ),
  INTRO_LINKEDIN_MESSAGE: (
    <>
      Crafting concise, impactful LinkedIn messages to program officers poses challenges.
      <br />
      <br />
      Giboo&apos;s AI assistant tailors messages by condensing information, emphasizing continuity,
      customizing for recipients, ensuring clarity and engagement, and ending with clear calls to
      action. Each message captures project essence and invites further engagement.
    </>
  ),
  LETTER_APPEAL: (
    <>
      Crafting a compelling annual giving appeal is vital, yet challenging.
      <br />
      <br />
      Simplify the process with Giboo&apos;s AI-powered assistant, ensuring your message inspires
      generosity and effectively communicates your cause. Let us help you create an appealing,
      heartfelt appeal that resonates with donors and drives impactful fundraising success.
    </>
  ),
  LETTER_NO_FOLLOW_UP: (
    <>
      Crafting a follow-up letter to no response can be tricky—finding the right balance of
      persistence and courtesy is key.
      <br />
      <br />
      Simplify the process with Giboo&apos;s AI-powered assistant. Let us help you create a polite
      yet compelling follow-up letter that prompts engagement. Try it now for effective and
      respectful communication.
    </>
  ),
  LETTER_REMINDER: (
    <>
      Sending a reminder letter can be a delicate task—finding the right tone to prompt action
      without sounding intrusive can be challenging.
      <br />
      <br />
      Simplify the process with Giboo&apos;s AI-powered assistant. Let us help you craft a gentle
      yet effective reminder that encourages timely responses. Try it now for streamlined and
      considerate communication
    </>
  ),
  LETTER_POSITIVE_FOLLOW_UP_FEEDBACK: (
    <>
      Crafting a follow-up letter to positive feedback is essential—expressing gratitude,
      maintaining engagement, and furthering the connection requires finesse.
      <br />
      <br />
      Simplify the process with Giboo&apos;s AI-powered assistant. Let us help you create a warm and
      appreciative follow-up letter that solidifies positive interactions. Try it now for thoughtful
      and impactful communication.
    </>
  ),
  LETTER_NEGATIVE_FOLLOW_UP_FEEDBACK: (
    <>
      Responding to negative feedback requires tact—addressing concerns and maintaining a positive
      connection is essential.
      <br />
      <br />
      Simplify the process with Giboo&apos;s AI-powered assistant. Let us help you craft a
      thoughtful follow-up letter that acknowledges concerns and demonstrates your commitment to
      improvement. Try it now for effective and constructive communication.{" "}
    </>
  ),
  LETTER_TO_OLD_FUNDER: (
    <>
      Reconnecting with an old funder is an important yet delicate task—expressing gratitude,
      highlighting past impact, and conveying current needs is essential.
      <br />
      <br />
      Simplify the process with Giboo&apos;s AI-powered assistant. Let us help you craft a heartfelt
      and compelling letter that reignites the connection.{" "}
    </>
  ),
  ACK_IN_KIND_DONATION: (
    <>
      Gratitude for in-kind donations is crucial. Crafting an impactful acknowledgment letter,
      conveying appreciation and the donation&apos;s impact, can be challenging.
      <br />
      <br />
      Simplify the process with Giboo&apos;s AI-powered assistant. Let us help you express sincere
      thanks. Try it now for heartfelt and meaningful donor communication
    </>
  ),
  EOY_APPEAL_OLD_FUNDER: (
    <>
      As the year draws to a close, we reflect on the impactful journey with our supporting funder.
      Their past assistance has been pivotal in advancing our mission and effecting tangible change.
      <br />
      <br />
      Now, we aim to craft an end-of-year appeal letter that acknowledges their vital contributions
      and invites continued support for our current project, fostering lasting impact. Let us assist
      you in composing a compelling and heartfelt appeal to our supportive funder.
    </>
  ),
  EOY_APPEAL_NEW_FUNDER: (
    <>
      Seize the opportunity for impactful end-of-year giving! Crafting an appeal to a new funder can
      be daunting—finding the right words, expressing urgency, and showcasing your mission.
      <br />
      <br />
      Simplify the process with Giboo&apos;s AI-powered assistant. Let us help you generate a
      compelling appeal to secure support for your cause. Try it now!
    </>
  ),
};
function OutReachPickerModal({
  loading,
  open,
  setOpen,
  onClose,
  onSelect,
  showBackDrop = true,
}: OutReachPickerModalProps) {
  const [selected, setSelected] = useState<TaskAsistantDocumentType>();
  const promptList = FUNDRAISING_PROCESS.reduce(
    (prev, cur) => [...prev, ...cur.data.filter((t) => t.typeEnum)],
    [] as TaskAsistantDocumentType[],
  );
  const location = useLocation();
  const navigate = useNavigate();
  const renderCard = (data: TaskAsistantDocumentType, index: number) => {
    return (
      <div
        className="group/card relative min-h-[224px] w-[254px] bg-white"
        key={"btn-outreach-" + data.type}
      >
        <div
          className={classNames(
            "flex h-full  max-w-[254px] flex-col  items-center justify-center gap-[28px] rounded border border-gray-300 px-5 py-[14px]",
            {
              ["active !border-purple-500 bg-purple-50 shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)]"]:
                selected?.type === data.type,
            },
          )}
        >
          <img
            src={OUTREACH_IMAGE[data.type]}
            className="h-[109px] w-[109px] scale-90"
            alt="outreach"
          />
          <div className="text-center font-poppins text-sm text-black">{data.name}</div>
        </div>
        <div
          className={classNames(
            "absolute  z-10 hidden h-fit min-w-[254px] max-w-full cursor-pointer select-none rounded border border-gray-300 bg-white p-4  transition-all delay-75 group-hover/card:block",
            index <= 2 ? "-left-[15px] top-[15px]" : "-left-[15px] bottom-[15px]",
          )}
          id={"btn-outreach-" + data.type}
          onClick={() => {
            setSelected(data);
            onSelect?.(data);
          }}
        >
          <div className="flex min-h-[146px] flex-col justify-between font-poppins text-xs">
            {OUTREACH_DESCRIPTION[data.type]}
          </div>
          <h6 className="mt-1 flex justify-end self-end text-end font-poppins text-xs text-black">
            {selected?.type === data.type ? "Click to unselect" : "Click to select"}
          </h6>
        </div>
      </div>
    );
  };
  return (
    <>
      <Dialog open={open} onClose={() => null} className="">
        {showBackDrop && (
          <div
            className={classNames("fixed inset-0 bg-black/80", zIndexBackdrop)}
            aria-hidden="true"
          />
        )}
        <div
          className={classNames(
            "fixed inset-0  grid w-screen place-items-center overflow-y-auto p-4 ",
            zIndexDialog,
          )}
        >
          <Dialog.Panel
            className={classNames(
              "mx-auto  min-h-[747px] !w-[959px] rounded bg-white",
              zIndexOutReach,
            )}
          >
            {loading ? (
              <div className=" grid h-[747px] w-full place-items-center">
                <Spinner />
              </div>
            ) : (
              <div className="flex h-full w-full flex-col">
                {/* header */}
                <div className="flex items-center justify-end p-5 pb-0">
                  <PlainButton
                    id={`btn-update-image-close`}
                    handleOnClick={() => {
                      if (location.state?.fromFundarisingModal) {
                        onClose?.();
                        navigate(-1);
                      } else {
                        onClose?.();
                        setOpen(false);
                      }
                    }}
                    leftIconClass={<CloseButton />}
                  />
                </div>
                <div className="flex flex-col gap-[9px] pb-5">
                  <h5 className="w-full text-center font-poppins text-[20px] font-semibold">
                    Choose your preferred method of outreach
                  </h5>
                </div>
                {/* content */}
                <div className="flex h-[747px] flex-col items-center">
                  <div
                    className={classNames(
                      "grid max-h-full max-w-full grid-cols-3 gap-3 overflow-y-scroll px-5 pb-5",
                      promptList.length > 2 && scrollBarClass,
                    )}
                  >
                    {promptList.map((i, index) => renderCard(i, index))}
                  </div>
                </div>
              </div>
            )}
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
export default OutReachPickerModal;
