import React from "react";
import { Cell, Pie, PieChart } from "recharts";
import { TASK_STATUS } from "../MyTasks";
import { IProjectSubtask } from "../../../../types/project";
const PROJ_STATUS_COLOR: any = {
  "Not started": "#DEDEDE",
  "In progress": "#FFEC8B",
  Done: "#7FC8F8",
};

export default function ProjectTaskCounts({
  onClick,
  label = "Tasks",
  tasks = [],
}: {
  label?: string;

  onClick?: () => void;
  tasks: IProjectSubtask[];
}) {
  const taskCount: any = [
    {
      name: "Not started",
      value: tasks.filter((i) => i.status === TASK_STATUS.NOT_STARTED).length,
    },
    {
      name: "Done",
      value: tasks.filter((i) => i.status === TASK_STATUS.DONE).length,
    },
    {
      name: "In progress",
      value: tasks.filter((i) => i.status === TASK_STATUS.IN_PROGRESS).length,
    },
  ];
  const renderChart = () => {
    const data = taskCount.sort((a: { value: number }, b: { value: number }) => a.value - b.value);
    const hasData = taskCount.some((i: { value: number }) => i.value > 0);
    if (!hasData) {
      return (
        <PieChart width={200} height={200}>
          <Pie
            data={[{ name: "No Data", value: 100 }]}
            stroke=""
            cx={80}
            startAngle={90}
            endAngle={450}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
          >
            <Cell key={0} fill={"#E2E8F0"} />;
          </Pie>
        </PieChart>
      );
    }
    return (
      <div className="h-fit w-full">
        <PieChart width={200} height={200}>
          <Pie
            data={data.length > 0 ? data : [{ name: "Not started", value: 0 }]}
            stroke=""
            cx={80}
            startAngle={90}
            endAngle={450}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
          >
            {taskCount.map((entry: any, index: any) => {
              const value = entry?.name ? entry?.name : "Not started";
              return <Cell key={`cell-${index}`} fill={PROJ_STATUS_COLOR[value]} />;
            })}
          </Pie>
        </PieChart>
      </div>
    );
  };
  return (
    <div className="flex h-[306px] w-[363px] flex-col gap-1 rounded border border-gray-200 bg-white p-4">
      <div className="flex items-center justify-between gap-4">
        <div className="font-poppins text-sm font-semibold text-purple-500">{label}</div>
        <div
          className="giboo-btn-text-purple cursor-pointer text-sm font-normal text-gray-600"
          onClick={() => onClick?.()}
        >
          View all
        </div>
      </div>
      <div className="grid h-full grid-cols-[162px_1fr] gap-[30px]">
        <div className="grid place-items-center">{renderChart()}</div>
        <div className="flex flex-col justify-center gap-5 p-4">
          {Object.keys(PROJ_STATUS_COLOR).map((item) => {
            const value = taskCount.find((i: { name: string }) => i?.name === item).value;
            return (
              <div key={item} className="inline-flex items-center gap-[10px]">
                <span
                  className="h-[10px] w-[10px] rounded-full"
                  style={{ background: PROJ_STATUS_COLOR[item] }}
                ></span>
                <div className="whitespace-nowrap font-poppins text-sm">
                  {item}: {value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
