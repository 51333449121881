import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useMatch, useSearchParams } from "react-router-dom";
import useLocalStorage from "./useLocalstorage";

interface RateLimitState {
  ok: boolean | undefined;
  error: any;
  isLoading: boolean;
  reset?: () => void;
}

const limitPages = ["/search", "/donors", "/grants", "/npos"];

function useRateLimit(use: boolean): RateLimitState {
  const browserLocation = useLocation();
  const isOnboarding = useMatch("/onboarding")?.pathname ? true : false;
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const { value: pageVisits, update: setPageVisits, deleteThis } = useLocalStorage("PAGE_VISIT", 0);
  useEffect(() => {
    if (isOnboarding) return;
    if (use && limitPages.find((p) => browserLocation.pathname.startsWith(p))) {
      setIsLoading(true);
      const visitCount = pageVisits + 1;
      console.log({ visitCount });
      setPageVisits(visitCount);
      if (visitCount >= 5) {
        setData(true);
      } else {
        setData(false);
      }
      setIsLoading(false);
    }
  }, [browserLocation, isOnboarding]);

  return {
    ok: searchParams.get("signup") ? false : data,
    isLoading,
    error,
    reset: () => {
      deleteThis();
      setData(false);
    },
  };
}

export default useRateLimit;
