import React from "react";
import GibooGradientDiv from "./GibooGradientDiv";
import classNames from "classnames";
import Button from "./tailwind/Button";
import ClickMeTooltip from "./ClickMeTooltip";
import { useSetRecoilState } from "recoil";
import { openSigninSignup } from "../app/recoilStore";

export default function TryLetterCard({ wrapperClass }: { wrapperClass?: string }) {
  return (
    <GibooGradientDiv
      borderSize={1}
      wrapperClassName={classNames(
        "w-full  max-w-[680px] mx-auto shadow-[0_0_15px_1px_rgba(0,0,0,0.1)] ",
        wrapperClass,
      )}
    >
      <div className="flex min-h-[99px] w-full flex-col items-center justify-center gap-[10px]">
        <div className="inline-flex items-center gap-[10px]">
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.68064 19.681L5.28928 21.2896C5.79727 21.7976 6.51692 22.094 7.19424 22.094C7.87156 22.094 8.59121 21.7976 9.0992 21.2896L19.0754 11.3135L19.5834 6.48752C19.668 5.89486 19.2024 5.38687 18.6097 5.38687C18.5674 5.38687 18.5251 5.38687 18.4827 5.38687L13.6568 5.89486L3.68064 15.871C2.62233 16.9294 2.62233 18.6227 3.68064 19.681ZM14.5881 7.84217L17.4244 7.54584L17.1281 10.3821L12.3165 15.236L11.0042 16.5484L8.42188 13.9661L9.73419 12.6537L14.5881 7.84217ZM5.11995 17.3104L6.98258 15.4054L9.56486 17.9877L7.6599 19.8503C7.49057 20.0197 7.2789 20.062 7.19424 20.062C7.10957 20.062 6.89791 20.0197 6.72858 19.8503L5.11995 18.2417C4.86595 17.9877 4.86595 17.5644 5.11995 17.3104Z"
                fill="#CF51FB"
              />
              <path
                d="M4.34738 1.51328C4.51333 0.828906 5.48667 0.828906 5.65262 1.51328L6.10924 3.39639C6.16849 3.64073 6.35927 3.83151 6.60361 3.89075L8.48672 4.34738C9.17109 4.51333 9.17109 5.48667 8.48672 5.65262L6.60361 6.10924C6.35927 6.16849 6.16849 6.35927 6.10924 6.60361L5.65262 8.48672C5.48667 9.17109 4.51333 9.17109 4.34738 8.48672L3.89076 6.60361C3.83151 6.35927 3.64073 6.16849 3.39639 6.10924L1.51328 5.65262C0.828906 5.48667 0.828906 4.51333 1.51328 4.34738L3.39639 3.89076C3.64073 3.83151 3.83151 3.64073 3.89075 3.39639L4.34738 1.51328Z"
                fill="#CF51FB"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 2.24146L4.6739 3.58629C4.54355 4.12384 4.12384 4.54355 3.58629 4.6739L2.24146 5L3.58629 5.3261C4.12384 5.45645 4.54355 5.87616 4.6739 6.41371L5 7.75854L5.3261 6.41371C5.45645 5.87616 5.87616 5.45645 6.41371 5.3261L7.75854 5L6.41371 4.6739C5.87616 4.54355 5.45645 4.12384 5.3261 3.58629L5 2.24146ZM5.65262 1.51328C5.48667 0.828906 4.51333 0.828906 4.34738 1.51328L3.89075 3.39639C3.83151 3.64073 3.64073 3.83151 3.39639 3.89076L1.51328 4.34738C0.828906 4.51333 0.828906 5.48667 1.51328 5.65262L3.39639 6.10924C3.64073 6.16849 3.83151 6.35927 3.89076 6.60361L4.34738 8.48672C4.51333 9.17109 5.48667 9.17109 5.65262 8.48672L6.10924 6.60361C6.16849 6.35927 6.35927 6.16849 6.60361 6.10924L8.48672 5.65262C9.17109 5.48667 9.17109 4.51333 8.48672 4.34738L6.60361 3.89075C6.35927 3.83151 6.16849 3.64073 6.10924 3.39639L5.65262 1.51328Z"
                fill="#CF51FB"
              />
              <path
                d="M19.5105 17.385C19.635 16.8717 20.365 16.8717 20.4895 17.385L20.8319 18.7973C20.8764 18.9805 21.0195 19.1236 21.2027 19.1681L22.615 19.5105C23.1283 19.635 23.1283 20.365 22.615 20.4895L21.2027 20.8319C21.0195 20.8764 20.8764 21.0195 20.8319 21.2027L20.4895 22.615C20.365 23.1283 19.635 23.1283 19.5105 22.615L19.1681 21.2027C19.1236 21.0195 18.9805 20.8764 18.7973 20.8319L17.385 20.4895C16.8717 20.365 16.8717 19.635 17.385 19.5105L18.7973 19.1681C18.9805 19.1236 19.1236 18.9805 19.1681 18.7973L19.5105 17.385Z"
                fill="#CF51FB"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 17.9311L19.7554 18.9397C19.6577 19.3429 19.3429 19.6577 18.9397 19.7554L17.9311 20L18.9397 20.2446C19.3429 20.3423 19.6577 20.6571 19.7554 21.0603L20 22.0689L20.2446 21.0603C20.3423 20.6571 20.6571 20.3423 21.0603 20.2446L22.0689 20L21.0603 19.7554C20.6571 19.6577 20.3423 19.3429 20.2446 18.9397L20 17.9311ZM20.4895 17.385C20.365 16.8717 19.635 16.8717 19.5105 17.385L19.1681 18.7973C19.1236 18.9805 18.9805 19.1236 18.7973 19.1681L17.385 19.5105C16.8717 19.635 16.8717 20.365 17.385 20.4895L18.7973 20.8319C18.9805 20.8764 19.1236 21.0195 19.1681 21.2027L19.5105 22.615C19.635 23.1283 20.365 23.1283 20.4895 22.615L20.8319 21.2027C20.8764 21.0195 21.0195 20.8764 21.2027 20.8319L22.615 20.4895C23.1283 20.365 23.1283 19.635 22.615 19.5105L21.2027 19.1681C21.0195 19.1236 20.8764 18.9805 20.8319 18.7973L20.4895 17.385Z"
                fill="#CF51FB"
              />
              <path
                d="M23 2C23 2.55228 22.5523 3 22 3C21.4477 3 21 2.55228 21 2C21 1.44772 21.4477 1 22 1C22.5523 1 23 1.44772 23 2Z"
                fill="#CF51FB"
              />
              <path
                d="M13 2C13 2.55228 12.5523 3 12 3C11.4477 3 11 2.55228 11 2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2Z"
                fill="#CF51FB"
              />
              <path
                d="M4 12C4 12.5523 3.55228 13 3 13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11C3.55228 11 4 11.4477 4 12Z"
                fill="#CF51FB"
              />
            </svg>
          </span>
          <h6 className="font-poppins text-xs text-gray-900">
            Try generating a letter of inquiry based based on the matched information for free.
          </h6>
        </div>
        <TryLoiButton />
      </div>
    </GibooGradientDiv>
  );
}

export function TryLoiButton({ fromNav }: { fromNav?: boolean }) {
  const setSignUpSignin = useSetRecoilState(openSigninSignup);
  return (
    <ClickMeTooltip
      id="click-me-tryloi"
      position={fromNav ? "left" : "right"}
      autoOpen
      delay={3000}
      tooltipPlacement={fromNav ? "left" : "right"}
    >
      <Button
        className="h-[29px] w-fit"
        label="Generate a letter of inquiry"
        id="try loi"
        handleOnClick={() => {
          setSignUpSignin({ show: true });
        }}
        prependIcon={
          <span className="-mt-1">
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.93828 5.83869H3.2925C3.01624 5.83869 2.75129 5.94844 2.55595 6.14379C2.3606 6.33914 2.25085 6.60409 2.25085 6.88036V15.2137C2.25085 15.4899 2.3606 15.7549 2.55595 15.9502C2.75129 16.1456 3.01624 16.2553 3.2925 16.2553H12.6673C12.9436 16.2553 13.2086 16.1456 13.4039 15.9502C13.5993 15.7549 13.709 15.4899 13.709 15.2137V11.047M2.25085 8.44286H9.02157M7.98015 13.6512H9.54262M9.41346 6.322C9.04888 6.25846 9.04888 5.73346 9.41346 5.66992C10.061 5.55744 10.6604 5.25462 11.1351 4.80008C11.6099 4.34555 11.9385 3.7599 12.079 3.11784L12.0999 3.0168C12.1801 2.65534 12.6936 2.65222 12.7769 3.01368L12.803 3.13138C12.9488 3.7706 13.2803 4.35244 13.7559 4.80374C14.2315 5.25505 14.8299 5.55568 15.4759 5.66784C15.8425 5.73138 15.8425 6.2595 15.4759 6.32409C14.8299 6.43625 14.2315 6.73688 13.7559 7.18818C13.2803 7.63949 12.9488 8.22133 12.803 8.86054L12.7759 8.97721C12.6936 9.33763 12.1801 9.33554 12.1009 8.97408L12.079 8.87513C11.9385 8.23307 11.6099 7.64742 11.1351 7.19288C10.6604 6.73835 10.061 6.43553 9.41346 6.32305V6.322Z"
                stroke="white"
                strokeWidth="0.972213"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        }
      />
    </ClickMeTooltip>
  );
}
