import classNames from "classnames";
import React, { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import PlainButton from "./PlainButton";

type FoldableBoxProps = {
  globalOpen?: boolean;
  className?: string;
  id: string;
  title: string;
  icon?: string | ReactNode;
  extraButton?: ReactNode;
  titleClass?: string;
  arrowIconClass?: string;
};

type FoldableBoxComponent = React.FunctionComponent<PropsWithChildren<FoldableBoxProps>>;
const FoldableBox: FoldableBoxComponent = ({
  id,
  title,
  className,
  icon,
  extraButton,
  titleClass,
  arrowIconClass,
  ...props
}: PropsWithChildren<FoldableBoxProps>) => {
  const [open, setOpen] = useState<boolean>(props.globalOpen || false);
  useEffect(() => {
    setOpen(props.globalOpen || false);
  }, [props.globalOpen]);
  return (
    <div className={classNames("flex flex-col gap-2 py-3", className)}>
      <div className="flex justify-between">
        <div className="inline-flex items-center gap-2 ">
          <h5 className={classNames("font-semibold", titleClass)}>{title}</h5>
          <span className="cursor-pointer">
            {typeof icon === "string" ? <i className={icon} /> : icon}
          </span>
          {extraButton && (
            <PlainButton
              className="!p-0"
              id={`${id}-open`}
              label=""
              leftIconClass={classNames(
                open && arrowIconClass,
                !open ? "gi-md gi-angle-up" : "gi-md gi-angle-down",
              )}
              handleOnClick={() => {
                setOpen((prev) => !prev);
              }}
            />
          )}
        </div>
        {extraButton ? (
          <>{extraButton}</>
        ) : (
          <PlainButton
            className="!p-0"
            id={`${id}-open`}
            label=""
            leftIconClass={classNames(
              open && arrowIconClass,
              !open ? "gi-md gi-angle-up" : "gi-md gi-angle-down",
            )}
            handleOnClick={() => {
              setOpen((prev) => !prev);
            }}
          />
        )}
      </div>
      {open && props.children}
    </div>
  );
};
export default FoldableBox;
