import React, { useEffect, useState } from "react";
import {
  calculateVariance,
  getPercentageDiff,
  getYearsString,
  toLetterString,
  toUsdLong,
  toUsdShort,
} from "../../utils/formatHelper";
import classNames from "classnames";
import {
  CartesianGrid,
  Dot,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GrantAmount } from "../../types/funder";
import RenderIncrOrDecr from "../../pages/donor-detail-view/components/RenderIncrOrDecr";
import NoGraph from "../../pages/donor-detail-view/components/NoGraph";
import GibooGradientDiv from "../GibooGradientDiv";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import FinancialPredictionInfo from "../../pages/npo/dashboard/components/FinancialPredictionInfo";
interface DonorWealthInfoTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
  selectedTab?: number;
  range_from: number;
  range_to: number;
}

const DonorWealthInfoTooltip = ({
  selectedTab,
  range_from,
  range_to,
  ...props
}: DonorWealthInfoTooltipProps) => {
  if (props.active && props.payload && props.payload.length) {
    const { payload } = props.payload?.[0] || {};
    return (
      <div className="giboo-box space-y-1 ">
        {selectedTab == 1 && (
          <>
            <h5 className="mb-[1px] font-poppins text-sm font-semibold">{`Contribution in ${
              payload?.grant_year || 0
            }`}</h5>
            <h5 className="mb-[1px] font-poppins text-sm font-normal ">{`Amount: ${toUsdLong(
              payload?.sum || 0,
            )}`}</h5>
            <h5 className="font-poppins text-sm font-normal ">{`Count: ${
              payload?.count || 0
            } grants`}</h5>
          </>
        )}
        {selectedTab == 2 && (
          <>
            <h5 className="font-poppins text-sm ">{`Grant maximum: ${toUsdLong(
              props.payload[0]?.value,
            )}`}</h5>
            <h5 className="font-poppins text-sm ">{`Grant median: ${toUsdLong(
              props.payload[1]?.value,
            )}`}</h5>
            <h5 className="font-poppins text-sm ">{`Grant minimum: ${toUsdLong(
              props.payload[2]?.value,
            )}`}</h5>
          </>
        )}
        {selectedTab == 3 && (
          <>
            {/* <h5>{`In range ${toUsdShort(range_from)} - ${toUsdShort(range_to)}`}</h5> */}
            <h5 className="font-poppins text-sm ">{`Count: ${payload.count}`}</h5>
          </>
        )}
      </div>
    );
  }
  return null;
};
interface GrantsOverviewProps {
  returnCode: string;
  filedYears: number[];
  selectedTab: number;
  grantAmountOverview: GrantAmount[];
  range_from: number;
  range_to: number;
  compact?: boolean;
}
function DonorGrantsOverview({
  returnCode,
  filedYears,
  selectedTab,
  grantAmountOverview,
  range_from,
  range_to,
  compact,
  ...props
}: GrantsOverviewProps) {
  const valid: GrantAmount[] = grantAmountOverview
    .filter((v) => !v.is_interpolated && !v.is_predicted)
    .filter(Boolean);
  const firstYearData: GrantAmount = valid[0];
  const latestYearData: GrantAmount = valid[valid.length - 1];
  const previousYearData: GrantAmount = valid.length > 1 ? valid[valid.length - 2] : valid[0];

  const renderTitle = () => {
    return (
      <div className="flex h-[22px] items-center justify-end gap-5">
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-yellow-500">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-yellow-500" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Grant minimum</h6>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-green-500">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-green-500" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Grant median</h6>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-blue-500">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-blue-500" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Grant maximum</h6>
        </div>
      </div>
    );
  };
  const renderChart2 = () => {
    const foreCastCount = grantAmountOverview?.reduce((count, item) => {
      return item.is_predicted ? count + 1 : count;
    }, 0);
    const estimatedData = grantAmountOverview?.filter((i) => i.is_interpolated);
    const predictedData = grantAmountOverview?.filter((i) => i.is_predicted);
    const chartSeries: ApexAxisChartSeries = [
      {
        name: "grantMin",
        type: "line",
        data: grantAmountOverview?.map((i) => i.min) || [],
        color: "#ECD332",
      },
      {
        name: "grantMedian",
        type: "line",
        data: grantAmountOverview?.map((i) => i.median) || [],
        color: "#7EAB67",
      },
      {
        name: "grantMax",
        type: "line",
        data: grantAmountOverview?.map((i) => i.max) || [],
        color: "#8ac1de",
      },
      {
        name: "grantContribution",
        type: "line",
        data: grantAmountOverview?.map((i) => i.sum) || [],
        color: "#5C38A7",
      },
    ].filter((i) =>
      selectedTab === 1 ? i.name === "grantContribution" : i.name !== "grantContribution",
    );
    const chartOptions: ApexOptions = {
      chart: {
        toolbar: { show: false },
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => toUsdShort(Number(val), 1),
        distributed: true,
        offsetY: -8,
        offsetX: -5,
        textAnchor: "start",
        background: { enabled: false },
        style: {
          colors: ["#9B9B9B"],
          fontSize: "10px",
          fontFamily: "poppins",
          fontWeight: "400px",
        },
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 4,
        borderColor: "#DEDEDE",
        row: {
          colors: ["transparent"],
          opacity: 1,
        },
      },
      xaxis: {
        categories: grantAmountOverview?.map((i) => i.tax_year) || [],
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "poppins",
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#DEDEDE",
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "poppins",
            fontWeight: 400,
          },
          formatter(val, opts) {
            return toUsdLong(val);
          },
        },
      },
      stroke: {
        curve: "monotoneCubic",
        width: 2,
        colors: ["#ECD332", "#7EAB67", "#8ac1de"],
      },
      forecastDataPoints: {
        count: foreCastCount,
        strokeWidth: undefined,
        dashArray: 4,
      },
      markers: {
        size: 4.5,
        shape: "circle",
        hover: {
          size: 4.5,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const data = grantAmountOverview?.[dataPointIndex];
          const renderTitle = (value: string) => {
            return '<span class="font-poppins font-normal text-gray-700">' + value + "</span>";
          };
          const renderValue = (value: string) => {
            return '<span class="font-poppins font-semibold text-gray-900">' + value + "</span>";
          };
          if (data) {
            if (selectedTab === 0) {
              return (
                '<div class="giboo-box flex flex-col gap-1">' +
                '<p class="font-poppins font-semibold text-gray-900">' +
                data.tax_year +
                (data.is_predicted ? " (Predicted)" : data.is_interpolated ? " (Estimated)" : "") +
                "</p>" +
                '<div class="inline-flex items-center gap-2 !text-sm">' +
                renderTitle("Amount:") +
                renderValue(toUsdLong(data.min || 0)) +
                "</div>" +
                '<div class="inline-flex items-center gap-2 !text-sm">' +
                renderTitle("Count:") +
                renderValue(toUsdLong(data?.median || 0)) +
                "</div>" +
                "</div>"
              );
            }

            return (
              '<div class="giboo-box flex flex-col gap-1">' +
              '<p class="font-poppins font-semibold text-gray-900">' +
              data.tax_year +
              (data.is_predicted ? " (Predicted)" : data.is_interpolated ? " (Estimated)" : "") +
              "</p>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px]  bg-[#ECD332]">' +
              '<span class=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full  bg-[#ECD332]"></span>' +
              "</span>" +
              renderTitle("Grant minimum:") +
              renderValue(toUsdLong(data.min || 0)) +
              "</div>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-green-500">' +
              '<span class=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-green-500"></span>' +
              "</span>" +
              renderTitle("Grant median:") +
              renderValue(toUsdLong(data?.median || 0)) +
              "</div>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-blue-500">' +
              '<span class=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-blue-500"></span>' +
              "</span>" +
              renderTitle("Grant maximum") +
              renderValue(toUsdLong(data?.max || 0)) +
              "</div>" +
              "</div>"
            );
          }
        },
      },
    };

    return (
      <>
        <Chart options={chartOptions} series={chartSeries} type="line" width="600" />
        <div className="flex items-center  justify-center gap-4">
          {estimatedData && estimatedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-purple-100 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-estimated text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Estimated years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {estimatedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
          {predictedData && predictedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-dashed border-purple-500 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-predicted text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Predicted years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {predictedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <FinancialPredictionInfo />
        </div>
      </>
    );
  };
  const renderChart = () => {
    if (grantAmountOverview.length === 0) return <NoGraph />;
    return (
      <>
        {selectedTab == 2 ? renderTitle() : <div className="" />}
        {renderChart2()}
      </>
    );
  };
  const renderHeader = () => {
    if (grantAmountOverview.length === 0)
      return (
        <GibooGradientDiv borderSize={2} wrapperClassName="my-4">
          <div className="rounded bg-gray-100 px-4 py-[10px]">
            <h5 className="font-poppins text-gray-700">
              We apologize for the inconvenience, but currently, there is no information available.
              We are actively working to collect and provide the necessary data as soon as it
              becomes accessible.
            </h5>
          </div>
        </GibooGradientDiv>
      );
    const amountSum = grantAmountOverview.reduce((prev, cur) => prev + cur.sum, 0);
    const countSum = grantAmountOverview.reduce((prev, cur) => prev + cur.count, 0);
    const amountSumInRange = grantAmountOverview.reduce((prev, cur) => prev + cur.in_range_sum, 0);
    const countSumInRange = grantAmountOverview.reduce((prev, cur) => prev + cur.in_range_count, 0);
    const perFirstAndLatest = getPercentageDiff(firstYearData.sum, latestYearData.sum);
    const years = latestYearData.grant_year - firstYearData.grant_year + 1;
    const stdDev = Math.sqrt(calculateVariance(grantAmountOverview.map((item) => item.median)));
    const medianMean =
      grantAmountOverview.reduce((prev, cur) => prev + cur.median, 0) / grantAmountOverview.length;
    const isConsistent =
      medianMean === 0 ? true : Math.abs(stdDev - amountSum / countSum) / medianMean < 0.5;
    // console.log(
    //   "abs(stddev - mean) / mean",
    //   medianMean > 0 ? Math.abs(stdDev - amountSum / countSum) / medianMean : "inf",
    // );
    return (
      <GibooGradientDiv borderSize={2}>
        <div className="rounded bg-gray-100 px-4 py-[10px]">
          {selectedTab == 1 && (
            <h5 className="font-poppins text-gray-700">
              This funder&apos;s total amount of grants has{" "}
              <b className="text-purple-500">
                {perFirstAndLatest > 0
                  ? "increased"
                  : perFirstAndLatest < 0
                  ? "decreased"
                  : "remained the same"}
              </b>{" "}
              by <b className="text-purple-500">{Math.abs(Math.round(perFirstAndLatest))}%</b> over
              the {toLetterString(years)} years. This funder funded total of{" "}
              <b className="text-purple-500">{toUsdShort(amountSum, 1)}</b> through{" "}
              <b className="text-purple-500">{countSum.toLocaleString()}</b> grants in{" "}
              <span>
                {getYearsString(
                  filedYears.filter((y) => valid.find((g) => g.tax_year === y)) || [],
                )}
                .
              </span>
            </h5>
          )}
          {selectedTab == 2 &&
            (!isConsistent ? (
              <h5 className="font-poppins text-gray-700">
                This funder&apos;s grant range remained{" "}
                <b className="text-purple-500">relatively inconsistent</b> throughout the year.
              </h5>
            ) : (
              <h5 className="font-poppins text-gray-700">
                This funder&apos;s grant range remained{" "}
                <b className="text-purple-500">relatively consistent</b> throughout the year.
              </h5>
            ))}
          {selectedTab === 3 && (
            <h5 className="font-poppins text-gray-700">
              This funder funded{" "}
              <b className="text-purple-500">{toUsdShort(amountSumInRange, 1)}</b> through{" "}
              <b className="text-purple-500">{countSumInRange.toLocaleString()}</b> grants, each
              grant falling between{" "}
              <b className="text-purple-500">{`${toUsdShort(range_from)}-${toUsdShort(
                range_to,
              )}`}</b>{" "}
              from {firstYearData.grant_year} to {latestYearData.grant_year}, which aligns with your{" "}
              search criteria for fundraising amount.
            </h5>
          )}
        </div>
      </GibooGradientDiv>
    );
  };
  const renderRightContent = () => {
    if (valid.length === 0)
      return (
        <>
          <p className="text-base font-semibold">
            Overview
            <span className="px-1 text-sm font-light text-gray-700"></span>
          </p>
          <div className={classNames("mt-4 flex ", compact ? "flex-col gap-[50px]" : "flex-row")}>
            {selectedTab === 1 && (
              <>
                <div className={classNames("font-poppings flex flex-col gap-[4px]")}>
                  <h5 className=" text-sm text-gray-700">Total grant disbursed</h5>
                  <h3>No data</h3>
                </div>
                <div className={classNames("font-poppings flex flex-col gap-[4px]")}>
                  <h5 className=" text-sm text-gray-700">Grant disbursed recent year</h5>
                  <h3>No data</h3>
                </div>
              </>
            )}
            {selectedTab === 2 && (
              <>
                <div className={classNames("font-poppings mt-4 flex flex-col gap-[4px]")}>
                  <h5 className=" text-sm text-gray-700">Grant disbursement standard deviation</h5>
                  <h3>No data</h3>
                </div>
                <div className={classNames("font-poppings mt-4 flex flex-col gap-[4px]")}>
                  <h5 className=" text-sm text-gray-700">Median amount trend</h5>
                  <h3>No data</h3>
                </div>
              </>
            )}
          </div>
        </>
      );
    const latestYearData: GrantAmount = valid[valid.length - 1];
    const previousYearData: GrantAmount = valid.length > 1 ? valid[valid.length - 2] : valid[0];
    const amountSum = valid.reduce((prev, cur) => prev + cur.sum, 0);
    return (
      <div className="flex h-full w-full flex-col">
        <p className="text-base font-semibold">
          Overview
          <span className="px-1 text-sm font-light text-gray-700">
            (
            {selectedTab === 1
              ? getYearsString(valid.map((item) => item.grant_year))
              : latestYearData.grant_year}
            )
          </span>
        </p>
        <div className={classNames("mt-4 flex ", compact ? "flex-row gap-[50px]" : "flex-col")}>
          {selectedTab === 1 && (
            <>
              <div className={classNames("font-poppings  flex flex-col gap-[4px]")}>
                <h5 className=" text-sm text-gray-700">Total grant disbursed</h5>
                <h3>{toUsdShort(amountSum, 1)}</h3>
              </div>
              <div className={classNames("font-poppings  flex flex-col gap-[4px]")}>
                <h5 className=" text-sm text-gray-700">Grant disbursed recent year</h5>
                <h3>{toUsdShort(latestYearData.sum, 1)}</h3>
                <RenderIncrOrDecr
                  value={getPercentageDiff(previousYearData.sum, latestYearData.sum)}
                />
              </div>
            </>
          )}
          {selectedTab === 2 && (
            <>
              <div className={classNames("font-poppings  flex flex-col gap-[4px]")}>
                <h5 className=" text-sm text-gray-700">Grant disbursement standard deviation</h5>
                <h3>{toUsdShort(Math.round(Math.sqrt(latestYearData.variance)), 1)}</h3>
                <RenderIncrOrDecr
                  value={getPercentageDiff(
                    Math.sqrt(previousYearData.variance),
                    Math.sqrt(latestYearData.variance),
                  )}
                />
              </div>
              <div className={classNames("font-poppings  flex flex-col gap-[4px]")}>
                <h5 className=" text-sm text-gray-700">Median amount trend</h5>
                <h3>{toUsdShort(latestYearData.median, 1)}</h3>
                <RenderIncrOrDecr
                  value={getPercentageDiff(previousYearData.median, latestYearData.median)}
                />
              </div>
            </>
          )}
          {selectedTab === 3 && (
            <>
              <div className={classNames("font-poppings  flex flex-col gap-[4px]")}>
                <h5 className=" text-sm text-gray-700">Your range grant count</h5>
                <h3>{latestYearData.in_range_count.toLocaleString()}</h3>
                <RenderIncrOrDecr
                  value={getPercentageDiff(
                    previousYearData.in_range_count,
                    latestYearData.in_range_count,
                  )}
                />
              </div>
            </>
          )}
        </div>
        <h6 className={classNames("mt-5  text-gray-700", compact ? "self-start" : "self-end")}>
          {`Dataset from: IRS ${returnCode}`}
        </h6>
      </div>
    );
  };
  return (
    <>
      {renderHeader()}
      <div className="container mx-auto flex min-h-[300px] items-center">
        <div
          className={classNames(
            "flex w-full gap-5",
            compact ? "flex-col" : "flex-row items-center",
            selectedTab == 2 && "mt-4",
          )}
        >
          <div
            className={classNames(
              "w-full min-w-[300px] grow",
              //  compact && "max-w-[500px]"
            )}
            id={"total-grant-amount-chart-view-" + selectedTab}
          >
            {renderChart()}
          </div>
          <div className={classNames("flex min-w-[220px] grow-0 flex-col")}>
            {renderRightContent()}
          </div>
        </div>
      </div>
    </>
  );
}

export default DonorGrantsOverview;
