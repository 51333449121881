import React, { ReactNode, useCallback, useEffect, useState } from "react";
import ExpandableCard from "../../../../components/tailwind/ExpandableCard";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import PublicPrivateButton from "../../../../components/PublicPrivateButton";
import classNames from "classnames";
import useDiversity from "../../../../hooks/useDiversity";
import { ResponsiveContainer, Treemap } from "recharts";
import { Divider } from "rsuite";
import Spacer from "../../../../components/Spacer";
import DiversityChartLoader from "../../diversity/component/DiversityChartLoader";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import DiversityFilterWrapper, {
  DiversityFilter,
} from "../../diversity/component/DiversityFilterWrapper";
import useDiversityRecommendedAction from "../../../../hooks/npo/useDiversityRecommendedAction";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import MemoizedPhilanthropyWords from "../../../donor-detail-view/components/PhilanthropyWords";
import FinancialDiversityRecommendation from "../../../../components/FinancialDiversityRecommendation";
import AssessmentResult from "../../../../components/AssessmentResult";
import PopupModal from "../../../../components/tailwind/PopupModal";
import { ProfessionInfoModal } from "../../diversity/pages/ProfessionInfo";
import { NpoTitle } from "./NpoDiversityEthnicity";
import useTotalOrganizationMembersData from "../../../../hooks/useTotalOrganizationMembersData";

interface Iprops {
  npo_id?: string;
  org_id?: string;
  allowEdit?: boolean;
  compact?: boolean;
  recomendation?: boolean;
  showStats?: boolean;
  showInfo?: boolean;
  hideTitle?: boolean;
  children?: ReactNode;
  showChartLoader?: boolean;
  title?: ReactNode;
}
const PROJ_STATUS_COLOR = {
  Academics: "#9BC3AA",
  "Art and Culture": "#CD5C5A",
  Entrepreneurship: "#A793C3",
  "Business and Entrepreneurship": "#A793C3",
  Environment: "#EBCD78",
  "Health and Medicine": "#75B6E1",
  "Government and Policy": "#ECECEC",
  Law: "#6953D5",
  "Science and Technology": "#B353D5",
};
function NpoDiversityProfession({
  npo_id,
  org_id,
  allowEdit,
  compact,
  recomendation,
  showInfo = true,
  showStats = true,
  hideTitle = false,
  showChartLoader = true,
  children,
  title,
}: Iprops) {
  const { data: peoples } = useTotalOrganizationMembersData(org_id);
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const { disability, isLoading } = useDiversity(org_id);
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { data: recommendedAction, isLoading: isRecommendedActionLoading } =
    useDiversityRecommendedAction(org_id, "profession");
  const chartLoading = recomendation ? loading : isLoading;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-5"
        showHeader={false}
        showActionButtons={false}
      >
        <ProfessionInfoModal
          handleClose={() => {
            setShowInfoModal(false);
          }}
        />
      </PopupModal>
      <div
        className={classNames(
          "flex min-h-[100px] flex-col ",
          recomendation && "rounded border border-gray-300 bg-white p-5",
        )}
      >
        {!hideTitle &&
          (title ? (
            title
          ) : (
            <NpoTitle
              title="Board members professional background"
              recomendation={recomendation}
              setShowInfoModal={setShowInfoModal}
            />
          ))}
        {showChartLoader && chartLoading ? (
          <DiversityChartLoader title="professtional background" />
        ) : (
          <>
            {/* chart */}
            <div className="my-5">
              <ProfessionChart org_id={org_id} />
            </div>
            {/* info */}
            {showInfo &&
              (loading || isRecommendedActionLoading ? (
                <MemoizedLoadingRecommendedAction
                  loading={loading || isRecommendedActionLoading}
                  short
                />
              ) : (
                <AssessmentResult content={recommendedAction?.summary || ""} />
              ))}
            {recomendation ? <Divider className="my-[40px]" /> : <Spacer height="20px" />}
            {/* stats */}
            {showStats &&
              (loading || isRecommendedActionLoading ? (
                <MemoizedLoadingRecommendedAction
                  loading={loading || isRecommendedActionLoading}
                  hidePercentage
                />
              ) : (
                <div className="bg-gray-50 px-6 py-5">
                  <h6 className="mb-5 font-poppins text-base font-semibold text-black">
                    Recommendations
                  </h6>
                  <div className="flex flex-col gap-10">
                    <FinancialDiversityRecommendation
                      title="Board members"
                      recommendation={recommendedAction?.board_members || ""}
                    />
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      {!chartLoading && children && children}
    </>
  );
}

NpoDiversityProfession.propTypes = {};

export default NpoDiversityProfession;

const gridClass = [
  "col-span-3 row-span-2 h-[129px]",
  "row-span-4 row-start-3 ",
  "row-span-2 row-start-3",
  "col-start-2 row-span-2 row-start-5 ",
  "col-start-3 row-span-3 row-start-3  ",
  "col-span-2 col-start-1 row-span-2 row-start-7 ",
  "col-start-3 row-span-3 row-start-6 ",
  "col-span-4 row-span-2  h-[70px]",
];
interface WordData {
  label: string;
  score: number;
  per: number;
  count: number;
}
export function ProfessionChart({
  org_id,
  allowEdit,
  wrapperClass,
  compact,
}: {
  org_id?: string;
  allowEdit?: boolean;
  wrapperClass?: string;
  compact?: boolean;
}) {
  const [selectedTab, setSelectedTab] = useState<DiversityFilter>("board");
  const { profession: professionInfo, isLoading } = useDiversity(org_id, selectedTab);
  const [data, setData] = useState<WordData[]>([]);
  useEffect(() => {
    setData(
      Object.keys(professionInfo)
        .filter((key) => professionInfo[key].count)
        .map((key) => ({
          label: key,
          score: professionInfo[key].percentage || 0,
          per: professionInfo[key].percentage,
          count: professionInfo[key].count,
        })),
    );
  }, [professionInfo, setData]);
  const isEmpty = data.every((i) => i.score === 0);
  const renderTooltip = useCallback((d: WordData) => {
    return (
      <>
        <h5 className="font-semibold">{d?.label || ""}</h5>
        <h5>{`Count: ${d?.count || 0}`}</h5>
        <h5>{`Percentage: ${d?.per || 0}%`}</h5>
      </>
    );
  }, []);
  const renderChart = () => {
    return (
      // <Treemap
      //   width={450}
      //   height={283}
      //   data={data}
      //   dataKey="value"
      //   aspectRatio={4 / 3}
      //   stroke="#fff"
      //   content={<CustomTreeMapContent />}
      // />
      <div className="w-full">
        <MemoizedPhilanthropyWords<WordData>
          colors={["#5c38a7", "#e18b82", "#ecd332", "#7eab67", "#8ac1de"]}
          title=""
          data={data}
          key={"word-cloud-2"}
          loading={isLoading}
          sizeRatio={2}
          reprTooltip={renderTooltip}
        />
      </div>
    );
  };

  return (
    <div className={classNames("flex h-fit w-full items-center justify-around", wrapperClass)}>
      {!isEmpty ? (
        <>{renderChart()}</>
      ) : (
        <div className="grid min-h-[283px] place-items-center">
          <span className="w-full text-center font-poppins font-semibold text-gray-900">
            No Information available
          </span>
        </div>
      )}
    </div>
  );
}
const CustomTreeMapContent = (props: any) => {
  const { depth, x, y, width, height, index, name, value, count } = props;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={index === 1 ? 150 : height}
        style={{
          fill: PROJ_STATUS_COLOR[name as keyof typeof PROJ_STATUS_COLOR],
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {depth === 1 ? (
        <text
          x={x + 18}
          y={y + 25}
          fill="#fff"
          fontSize={14}
          fillOpacity={0.9}
          fontStyle={"poppins"}
          className="truncate text-ellipsis"
          style={{ maxWidth: width - 36, fontWeight: 500 }}
        >
          {name}
        </text>
      ) : null}
      {depth === 1 ? (
        <text
          x={x + 18}
          y={y + 50}
          fill="#fff"
          fontSize={12}
          fillOpacity={0.9}
          fontStyle={"poppins"}
          className="truncate text-ellipsis"
          style={{ maxWidth: width - 36, fontWeight: 400 }}
        >
          {`${value || 0}%, ${count || 0} ${(count || 0) === 1 ? "Person" : "People"}`}
        </text>
      ) : null}
    </g>
  );
};
