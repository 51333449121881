import React from "react";
import { Cell, Pie, PieChart } from "recharts";

interface ProgressBarCircleProps {
  percentage: number;
  height?: number;
  width?: number;
  img?: string;
}
function ProgressBarCircle({ img, height = 88, width = 200, percentage }: ProgressBarCircleProps) {
  const percent = Math.min(percentage, 100);
  return (
    <PieChart width={width} height={height}>
      {img && (
        <g>
          <foreignObject x="82" y="48" width="100%" height="100%" textAnchor="middle">
            <span className="grid h-10 w-10 place-items-center rounded-full bg-purple-50 p-1">
              <img src={img} className="h-6 w-6" />
            </span>
          </foreignObject>
        </g>
      )}
      <Pie
        data={[{ value: percent }, { value: 100 - percent }]}
        stroke=""
        cx={98}
        cy={80}
        startAngle={180}
        endAngle={0}
        innerRadius={55}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
      >
        <Cell key={0} fill="#9747FF" />
        <Cell key={1} fill="#E2E8F0" />
      </Pie>
    </PieChart>
  );
}

export default ProgressBarCircle;
