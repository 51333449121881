import classNames from "classnames";
import "../../css/dropdown.scss";
import React, { Dispatch, SetStateAction, useMemo, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import downTag from "../../../assets/images/arrow-down.svg";

interface DropdownSelectorProps<T> {
  id: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green" | "gray";
  data: T[];
  selected: T | undefined;
  setSelected: (v: T | undefined) => void;
  getKey: (item: T) => number | string;
  renderItem: (item: T) => React.ReactNode;
  renderButton?: (item: T) => React.ReactNode;
  maxHeight?: string;
  openAnimation?: boolean;
  placeholder?: string;
  disabled?: boolean;
  float?: boolean;
  compact?: boolean;
  defaultValue?: T;
  inputClass?: string;
  downIcon?: boolean;
  isClearable?: boolean;
}

function DropdownSelector<T>({
  id,
  color,
  data,
  selected = undefined,
  setSelected,
  getKey,
  renderItem,
  renderButton = renderItem,
  maxHeight,
  openAnimation = false,
  placeholder,
  disabled = false,
  float = false,
  compact = false,
  defaultValue,
  inputClass,
  downIcon = false,
  isClearable = false,
}: DropdownSelectorProps<T>) {
  const colorClass = color ? `-${color}` : "";
  const options = useMemo(() => {
    return (
      <Listbox.Options
        as="div"
        className={classNames("dropdown-selector-item-container w-full overflow-auto shadow-sm", {
          float: float,
          compact: compact,
        })}
        style={{ maxHeight: maxHeight }}
      >
        {data.map((item) => (
          <Listbox.Option as={Fragment} key={getKey(item)} value={item} disabled={false}>
            <div
              className={classNames(
                "dropdown-selector-item flex items-center",
                `dropdown-selector-item${colorClass} mx-2`,
                compact ? "min-h-[28px] " : "min-h-[56px]",
                {
                  compact: compact,
                },
              )}
            >
              <div id={`item-${id}-${getKey(item)}`}>{renderItem(item)}</div>
            </div>
          </Listbox.Option>
        ))}
      </Listbox.Options>
    );
  }, [data, selected, renderItem]);
  const transitionClasses = openAnimation
    ? {
        enter: "transition ease-out duration-100",
        enterFrom: "transform opacity-0 scale-95",
        enterTo: "transform opacity-100 scale-100",
        leave: "transition ease-in duration-75",
        leaveFrom: "transform opacity-100 scale-100",
        leaveTo: "transform opacity-0 scale-95",
      }
    : {};
  const defaultValueProp = defaultValue
    ? ({ defaultValue: defaultValue } as { defaultValue: T })
    : typeof selected === "string"
    ? ({ value: selected } as { value: string })
    : {};
  return (
    <Listbox
      id={id}
      as="div"
      by={(a: T | undefined, b: T | undefined) =>
        a !== undefined && b !== undefined && getKey(a) === getKey(b)
      }
      onChange={setSelected}
      className="dropdown-selector absolute w-full"
      disabled={disabled}
      {...defaultValueProp}
    >
      <Listbox.Button as={Fragment}>
        <button
          id={`btn-${id}`}
          className={classNames(
            inputClass,
            "dropdown-selector-button h-14 py-1 ",
            `dropdown-selector-button${colorClass} `,
          )}
          disabled={disabled}
        >
          <div className="flex items-center">
            <div className="grow">
              {selected ? (
                renderButton(selected)
              ) : (
                <p className="dropdown-selector-placeholder m-0">{placeholder}</p>
              )}
            </div>
            <div className="inline-flex items-center gap-2">
              {selected && isClearable && (
                <i
                  id={`btn-clear-${id}`}
                  className=" gi-x cursor-pointer"
                  onClick={() => setSelected(undefined)}
                />
              )}
              {downIcon && <i className={classNames("gi-md gi-angle-down", color)} />}
            </div>
          </div>
        </button>
      </Listbox.Button>
      <div className="relative z-30">
        {openAnimation ? (
          <Transition as={Fragment} {...transitionClasses}>
            {options}
          </Transition>
        ) : (
          <>{options}</>
        )}
      </div>
    </Listbox>
  );
}

export default DropdownSelector;
