import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import { MutableRefObject, ReactElement, ReactNode } from "react";

interface FilterBaseRenderProps {
  open: boolean;
  close: (
    focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined,
  ) => void;
}
interface FilterBaseProps<T> {
  id?: string;
  onFocus?: () => void;
  downIcon?: boolean;
  panelWidth?: string;
  panelMaxHeight?: string;
  selected?: (v: T | undefined) => boolean;
  repr: (v: T | undefined) => string | ReactElement;
  reprWithSize?: (v: number | undefined) => string | ReactElement;
  value: T | undefined;
  setValue: (v: T | undefined) => void;
  children: (props: FilterBaseRenderProps) => ReactNode;
  size?: number;
}

function FilterBase<T>({
  id,
  repr,
  reprWithSize,
  value,
  setValue,
  children,
  panelWidth = "400px",
  panelMaxHeight = "600px",
  ...props
}: FilterBaseProps<T>) {
  if (!isFunction(children)) {
    throw new Error("children is mandatory and needs to be a function");
  }
  const selected = props.selected ? props.selected(value) : value ? true : false;
  return (
    <Popover className="w-fit">
      <Popover.Button
        as="button"
        className="ring-none w-fit outline-none"
        onFocus={props.onFocus}
        id={`btn-filter-${id}`}
        aria-valuetext={id}
      >
        <div
          className={classNames(
            "relative flex !h-[29px] w-full items-center justify-start whitespace-nowrap rounded px-2 hover:font-semibold",
            selected || props.size
              ? "bg-purple-500 text-white hover:bg-purple-400"
              : "border border-gray-300 text-gray-900 hover:bg-gray-100",
            props.downIcon ? "pr-8" : "pr-2",
          )}
        >
          <>
            {reprWithSize && value && Array.isArray(value)
              ? reprWithSize(props.size || value.length)
              : repr(value)}
          </>
          {props.downIcon && <i className="gi gi-angle-down absolute right-2 top-[7px]" />}
        </div>
      </Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="relative w-full">
          {({ open, close }) => (
            <div
              className="float absolute top-0 float-left w-full overflow-y-auto rounded border border-gray-300 bg-white p-2 shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)]"
              style={{ width: panelWidth, maxHeight: panelMaxHeight }}
            >
              {children({ open, close })}
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

type IsFunction<T> = T extends (...args: any[]) => any ? T : never;
const isFunction = <T extends object>(value: T): value is IsFunction<T> =>
  typeof value === "function";
export default FilterBase;
