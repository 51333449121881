import { useCallback } from "react";
import {
  IProjectTask,
  IProjectTaskCreateRequest,
  IProjectTaskUpdateRequest,
} from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { swrOptionDedupling5mins, swrOptionFetchOnlyOnMount } from "../../types/swr";
interface ProjectTaskState {
  data: IProjectTask;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  update: (req: IProjectTaskUpdateRequest) => Promise<IProjectTask | undefined>;
  deleteThis: () => Promise<boolean>;
  revalidate: () => Promise<IProjectTask | undefined>;
}
const defaultProjectTask: IProjectTask = {
  _id: "",
  project_id: "",
  updated_at: "",
  created_at: "",
  amount: 0,
  inactive: true,
  // priority: "",
  status: "",
  target_amount: 0,
  target_id: "",
  target_type: 0,
  users: [],
};
export const PROJECT_TASK_STATUS_OPTIONS = [
  { label: "Update status", value: "none" },
  { label: "Active", value: "active" },
  { label: "On hold", value: "on_hold" },
  { label: "At risk", value: "at_risk" },
  { label: "Raised", value: "raised" },
  { label: "Declined", value: "declined" },
];
const filterStatus = (status?: string): string | undefined => {
  return PROJECT_TASK_STATUS_OPTIONS.find((o) => o.value === (status || "none"))?.value;
};
const getProjectTask = async (id: string) => {
  if (!id) return new Promise<IProjectTask>((resolve, reject) => reject());
  return axios
    .get(process.env.REACT_APP_API_URL + `/api/v2/projects/task/${id}`, config)
    .then((res) => res.data as IProjectTask);
};
const createProjectTask = async (req: IProjectTaskCreateRequest) => {
  return axios
    .post(
      process.env.REACT_APP_API_URL + `/api/v2/projects/task`,
      { ...req, ...(req.status ? { status: filterStatus(req.status) } : {}) },
      config,
    )
    .then((res) => res.data as IProjectTask);
};
const updateProjectTask = async (id: string, req: IProjectTaskUpdateRequest) => {
  if (!id) return new Promise<IProjectTask>((resolve, reject) => reject());
  return axios
    .put(
      process.env.REACT_APP_API_URL + `/api/v2/projects/task/${id}`,
      { ...req, ...(req.status ? { status: filterStatus(req.status) } : {}) },
      config,
    )
    .then((res) => res.data as IProjectTask);
};
const inactiveProjectTask = async (id: string) => {
  if (!id) return new Promise<boolean>((resolve, reject) => reject());
  return axios
    .put(process.env.REACT_APP_API_URL + `/api/v2/projects/task/${id}`, { inactive: true }, config)
    .then((res) => (res.data ? true : false) as boolean);
};
const inactiveProjectTaskByTarget = async (
  project_id: string,
  target_id: string,
  target_type: 0 | 1 | 2,
) => {
  if (!target_id || !project_id) return new Promise<boolean>((resolve, reject) => reject());
  return axios
    .put(
      process.env.REACT_APP_API_URL +
        `/api/v2/projects/task?project_id=${project_id}&target_id=${target_id}&target_type=${target_type}`,
      { inactive: true },
      config,
    )
    .then((res) => (res.data ? true : false) as boolean);
};
// const deleteProjectTask = async (id: string) => {
//   if (!id) return new Promise<boolean>((resolve, reject) => reject());
//   return axios
//     .delete(process.env.REACT_APP_API_URL + `/api/v2/projects/${project_id}/task/${id}`, config)
//     .then((res) => res.data as boolean);
// };
function useTask(task_id?: string): ProjectTaskState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/task/${task_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectTask>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as IProjectTask);
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectTask>(
    task_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const update = useCallback(
    async (req: IProjectTaskUpdateRequest) => {
      if (!data) return;
      const options = {
        optimisticData: {
          ...data,
          ...req,
          ...(req.status ? { status: filterStatus(req.status) } : {}),
        },
        rollbackOnError: true,
      };
      return mutate<IProjectTask>(
        url,
        axios.put(url, req, config).then((response) => response.data),
        options,
      ).then((res) => {
        if (data.project_id)
          mutate(
            process.env.REACT_APP_API_URL + `/api/v2/projects/tasks?project_id=${data.project_id}`,
          );
        return res;
      });
    },
    [url, data],
  );

  const deleteThis = useCallback(async () => {
    if (task_id)
      return inactiveProjectTask(task_id).then((res) => {
        if (res && data?.project_id)
          mutate(process.env.REACT_APP_API_URL + `/api/v2/projects/${data.project_id}`);
        return res;
      });
    else return new Promise<boolean>((resolve, reject) => reject());
  }, [task_id, data]);
  const revalidate = useCallback(() => mutate<IProjectTask>(url), [url]);
  return {
    data: data || defaultProjectTask,
    isLoading,
    error,
    isValidating,
    update,
    deleteThis,
    revalidate,
  };
}
export {
  getProjectTask,
  createProjectTask,
  updateProjectTask,
  inactiveProjectTask,
  inactiveProjectTaskByTarget,
};
export default useTask;
