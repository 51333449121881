const getStringCombinations = (s: string): string[] => {
  const mid = s
    .split(" ")
    .reduce(
      (prev, cur) => [...prev.map((p) => [...p, `${p[p.length - 1]} ${cur}`]), [cur]],
      [] as string[][],
    );
  return mid.reduce((prev, cur) => [...prev, ...cur.filter((c) => !stopWord.includes(c))], []);
};
const stopWord = [
  "in",
  "a",
  "an",
  "the",
  "on",
  "i",
  "is",
  "it",
  "for",
  "to",
  "as",
  "by",
  "and",
  "or",
  "at",
  "of",
  "about",
  "with",
  "within",
  "in",
];
export { getStringCombinations, stopWord };
