import classNames from "classnames";
import * as React from "react";
import "./css/input.scss";
import PlainButton from "./PlainButton";
import eye from "../assets/images/eye.svg";
import eyeSlash from "../assets/images/eye-slash.svg";
interface PasswordInputProps {
  id: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green";
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  invalid?: boolean;
  disabled?: boolean;
  className?: string;
}

function PasswordInput(props: PasswordInputProps) {
  const [showPasswd, setShowPasswd] = React.useState(false);
  const colorClass = props.color ? `-${props.color}` : "";
  return (
    <div className="inner-addon">
      <i className={classNames("left-glyphicon", props.color)}></i>
      <input
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        type={showPasswd ? "text" : "password"}
        className={classNames(
          "form-control giboo-input",
          { "form-invalid": props.invalid },
          colorClass ? `giboo-input${colorClass}` : "",
          props.className,
        )}
        onFocus={props.handleOnFocus}
        onChange={props.handleOnChange}
        onKeyDown={props.handleOnKeyDown}
        disabled={props.disabled}
        style={{ paddingLeft: "18px" }}
      />
      <div className="show-button-wrapper ">
        <PlainButton
          id={`btn-toggle-show-${props.id}`}
          className="p-0"
          skipTab={true}
          rightIconClass={
            showPasswd ? (
              <img src={eye} className="h-5 w-5" />
            ) : (
              <img src={eyeSlash} className="h-5 w-5 " />
            )
          }
          handleOnClick={(e) => {
            e.preventDefault();
            setShowPasswd((prev) => !prev);
          }}
        />
      </div>
    </div>
  );
}

export default PasswordInput;
