import axios from "axios";
import config from "../api";

export interface FeedbackReq {
  user_id: string;
  npo_id: string;
  module: string;
  score: number;
  comment: string;
  request: object;
  response: object;
}
export interface BugReportReq {
  name: string;
  subject: string;
  description: string;
  file_object_key: string[];
  files: { key: string; name: string }[];
}
export interface BetaFeedbackReq extends BugReportReq {
  allow_follow_up: string;
}
export const addFeedback = (data: FeedbackReq) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/v1/users/feedback`,
        {
          ...data,
        },
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const disableFeedBack = (
  userId: string,
  status: "FUNDER_RECOMMENDATION" | "SEARCH_RESULT" | "LOI" | "FUNDER_ANALYSIS",
) => {
  const url = `/api/v1/users/feedback/disable?user_id=${userId}&module=${status}`;
  return new Promise((resolve, reject) => {
    axios
      .patch(process.env.REACT_APP_API_URL + url, {}, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const checkFeedBackStatus = (
  userId: string,
  status: "FUNDER_RECOMMENDATION" | "SEARCH_RESULT" | "LOI" | "FUNDER_ANALYSIS",
) => {
  const url = `/api/v1/users/feedback/status?user_id=${userId}&module=${status}`;

  return new Promise<{ feedback: boolean }>((resolve, reject) => {
    axios
      .patch(process.env.REACT_APP_API_URL + url, {}, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addBetaFeedback = (data: BetaFeedbackReq) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/v2/users/beta_feedback`,
        {
          ...data,
        },
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const reportBug = (data: BugReportReq) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/api/v2/users/report_bug`,
        {
          ...data,
        },
        config,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
