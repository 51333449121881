import PopupModal from "./tailwind/PopupModal";
import IMAGE_LOI_PERSONALIZED from "../assets/images/loi-personalized.svg";
import IMAGE_LOI_STANDARD from "../assets/images/loi-standard.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/store";
import useUser from "../hooks/useUser";
import useOnboardingData from "../hooks/useOnboarding";
import GibooGradientDiv from "./GibooGradientDiv";
import useOrgs from "../hooks/useOrgs";
import { useEffect, useState } from "react";
import { Org } from "../types/org";
import { isValidEmail } from "../utils/formatHelper";
import { sendOrgInvitation } from "../services/org.services";
import Button from "./tailwind/Button";
import LeftInnerIconInput from "./LeftInnerIconInput";
import useOrgMembers from "../hooks/project/useOrgMembers";
import UserProfile from "./UserProfile";
import { roleToID, roleToName } from "../app/userSlice";
import classNames from "classnames";
import { scrollBarClass } from "../utils/classes";
import RolePicker from "./RolePicker";
import GibooToast from "./GibooToast";
import { zIndexOrgInvitation } from "../types/zIndex";
import TextInput from "./tailwind/TextInput";
import { FROM_FOR_MIXPANEL, MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useOrgID from "../hooks/useOrgID";
import useGibooMixpanel from "../hooks/useGibooMixpanel";

const OrgInvitationPopup = ({
  from_for_mixpanel,
  show,
  onClose,
  zIndex,
  noBackdrop,
  backDropZindex,
  ...props
}: {
  from_for_mixpanel: FROM_FOR_MIXPANEL;
  show: boolean;
  onClose?: () => void;
  zIndex?: number;
  backDropZindex?: number;
  noBackdrop?: boolean;
}) => {
  const [tried, setTried] = useState<boolean>(false);
  const mxEvent = useGibooMixpanel(from_for_mixpanel, true);
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const { orgs, selected } = useOrgs();
  const org_id = useOrgID();
  const location = useLocation();
  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<number>(3);
  const {
    data: members,
    revalidate,
    setMemberRole,
    hasEditPermission,
  } = useOrgMembers(selected, 1000, false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const validate = () => {
    if (!email || !isValidEmail(email)) {
      mxEvent(MIXPANEL_EVENTS_V2.invitation[""].failed, {
        invitationType: "organization",
        email,
        error: "wrong email",
      });
      setError("Wrong email");
      setIsDirty(true);
      return false;
    } else if (members.find((m) => m.accepted && m?.email === email)) {
      mxEvent(MIXPANEL_EVENTS_V2.invitation[""].failed, {
        invitationType: "organization",
        email,
        error: "tried to invite an existing member",
      });
      setError(`This user is already a member of ${onboardingData.npo_name}`);
      setIsDirty(true);
      return false;
    } else {
      setError("");
    }
    return true;
  };
  useEffect(() => {
    setError(email && !isValidEmail(email) ? "Wrong email" : "");
  }, [email]);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.invitation[""].started, {
      invitationType: "organization",
    });
  }, []);
  const handleSend = () => {
    const isValid = validate();
    if (!selected) return;
    if (isValid) {
      setLoading(true);
      setTried(true);
      sendOrgInvitation(selected, email, role)
        .then(() => {
          mxEvent(MIXPANEL_EVENTS_V2.invitation[""].successful, {
            invitationType: "organization",
            email,
          });
          GibooToast({
            type: "success",
            message: `Email successfully sent.`,
          });
          setEmail("");
          revalidate();
        })
        .catch((e) => {
          mxEvent(MIXPANEL_EVENTS_V2.invitation[""].failed, {
            invitationType: "organization",
            email,
            error: "api error",
          });
          GibooToast({
            type: "failure",
            message: e.response?.data?.detail || `Failed to send email.`,
          });
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <>
      <PopupModal
        title={"Invite a new member"}
        isOpen={show}
        zIndex={zIndexOrgInvitation}
        wrapperClass="!max-w-[540px] !min-w-[540px] md:!px-0 h-full"
        showActionButtons={true}
        noBackdrop={noBackdrop}
        backDropZindex={backDropZindex}
        RightActionButton={() => (
          <Button id="btn-close" label="Done" handleOnClick={() => onClose?.()} />
        )}
      >
        <div className="flex w-full flex-col gap-12 px-10 py-6">
          <div className="flex w-full flex-col items-start gap-3">
            <p className="text-gray-700">Invite a new member by email</p>
            <div className="relative flex w-full gap-3">
              <div className="h-[58px] w-full grow">
                <TextInput
                  className="h-[48px] max-w-[330px]"
                  placeholder="Enter email"
                  id="input-email"
                  value={email}
                  handleOnChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  iconRight={
                    <div className="flex items-center gap-2">
                      <RolePicker
                        disable={["Owner", "Guest", "Delete", "Viewer"]}
                        currentRole={role}
                        allowEdit={true}
                        updateRole={(role: number) => {
                          setRole(role);
                        }}
                      />
                      <Button
                        id="btn-send"
                        className="!h-[25px] text-xs"
                        disabled={!email ? true : false}
                        handleOnClick={handleSend}
                        label="Invite"
                        loading={loading}
                      />
                    </div>
                  }
                />
              </div>
            </div>
            {isDirty && error && <h5 className="text-purple-500">{error}</h5>}
          </div>
          <div className={classNames("flex w-full flex-col items-start gap-3")}>
            <p className="text-gray-700">Members</p>
            <div
              className={classNames(
                "flex max-h-[400px] w-full flex-col items-start gap-3",
                scrollBarClass,
              )}
            >
              {members.map((m) => (
                <div
                  key={m._id}
                  className="flex w-full items-center justify-between rounded border border-gray-300 p-3"
                >
                  <UserProfile
                    showName={m.accepted}
                    size={24}
                    userId={m._id}
                    showEmail
                    pending={!m.accepted}
                    org_id={selected}
                    role={m.role}
                    profileImageClass={"h-[30px] w-[30px] p-2"}
                  />
                  {m.role === 5 || !hasEditPermission ? (
                    <h6 className="pr-5 text-gray-900">{roleToName[5]}</h6>
                  ) : (
                    <RolePicker
                      disable={["Owner", "Guest", "Delete", "Viewer"]}
                      currentRole={m.role}
                      allowEdit={true}
                      updateRole={(role: number) => {
                        setMemberRole(m._id, role);
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </PopupModal>
    </>
  );
};

export default OrgInvitationPopup;
