import { useCallback } from "react";
import { IProjectSubtaskNote } from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { useAppDispatch } from "../../app/store";
interface ProjectSubtaskNotesState {
  data: IProjectSubtaskNote[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IProjectSubtaskNote[] | undefined>;
}
function useSubtaskNotes(subtask_id?: string): ProjectSubtaskNotesState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/notes/${subtask_id}`;
  const dispatch = useAppDispatch();
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectSubtaskNote[]>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => res.data as IProjectSubtaskNote[])
      .then((res) =>
        res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
      );
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectSubtaskNote[]>(
    subtask_id ? url : null,
    fetch,
    {
      keepPreviousData: false,
    },
  );
  const revalidate = useCallback(() => mutate<IProjectSubtaskNote[]>(url), [url]);
  return {
    data: data || [],
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useSubtaskNotes;
