import classNames from "classnames";
import useFunderContact from "../../../hooks/funder/useFunderContact";
import GrantContact from "./GrantContact";
import { IGrant } from "../../../types/grant";
import GrantContactOld from "./GrantContactOld";

export default function GrantContactWrapper({
  funder_id,
  grant,
  wrapperClass,
  compact = false,
  withoutLogin,
  ...props
}: {
  grant?: IGrant;
  funder_id?: string;
  wrapperClass?: string;
  compact?: boolean;
  withoutLogin?: boolean;
}) {
  const { data: contact } = useFunderContact(funder_id);
  return (
    <div
      className={classNames(
        "flex flex-col gap-4 border-gray-300 bg-white px-7 py-5",
        wrapperClass,
        !compact && "rounded border",
      )}
    >
      <h4 className="font-poppins text-xl font-semibold text-black">Contact information</h4>
      <GrantContactOld
        target_name={grant?.title}
        target_id={grant?._id}
        target_type={1}
        contacts={contact?.contacts}
        apply_via_website={contact?.apply_via_website}
        email={grant?.contact_email}
        title={grant?.contact_title}
        name={grant?.contact_name}
        text={grant?.grantor_contact_text}
        compact={compact}
        forSearch
        withoutLogin={withoutLogin}
      />
    </div>
  );
}
