import { Relevant } from "../app/searchResultCacheSlice";

function RelevantText({ relevant }: { relevant?: Relevant }) {
  return (
    <>
      {relevant ? (
        relevant.error ? (
          <span>We apologize for the inconvenience. We apologize for the inconvenience.</span>
        ) : relevant.desc.length > 0 ? (
          relevant.completed ? (
            <span>{relevant.desc}</span>
          ) : (
            <>
              <span>
                {relevant && relevant.desc}
                {/* {currentSearchRelevant.value} */}
                <span className="ml-2 inline-block h-3 w-3 animate-spin rounded-full border-[2px] border-solid border-purple-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]">
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </span>
                {/* <span className="animate-blink font-medium text-purple-500">{` |`}</span> */}
                {/* <i className="h-2 w-2 animate-ping rounded-full bg-purple-500" /> */}
              </span>
            </>
          )
        ) : (
          <div className="loading-light-shimmer-on h-full w-full">
            <div className="loading-light-shimmer-child h-full w-full">
              <div className="h-full !min-h-[21px] w-full !min-w-[80px]"></div>
            </div>
          </div>
        )
      ) : null}
    </>
  );
}

export default RelevantText;
