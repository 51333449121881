import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import TextInput from "../../../components/tailwind/TextInput";
import SingleSelector from "../../../components/selector/SingleSelector";
import { IOption } from "../../../components/tailwind/AsyncCreatableSelector";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import Button from "../../../components/tailwind/Button";
export const COMMUNICATION_METHOD = [
  { label: "Email address", value: "contact_communication" },
  { label: "Mailing address", value: "contact_mailing" },
  { label: "Phone number", value: "contact_phone" },
];
export const PLACEHOLDER: any = {
  contact_communication: "Enter email address",
  contact_mailing: "Enter mailing address",
  contact_phone: "Enter phone number",
};

export const TOP_PLACEHOLDER: any = {
  contact_communication: "Email address",
  contact_mailing: "Mailing address",
  contact_phone: "Phone number",
};

export function CommunicationSelector({
  defaulValue,
  defaultMethod,
  isEdit,
  onComplete,
  isNew = false,
  exclude = [],
}: {
  isEdit?: boolean;
  isNew?: boolean;
  onComplete: (label?: string, value?: string) => void;
  defaulValue?: string;
  exclude?: string[];
  defaultMethod?: "contact_communication" | "contact_mailing" | "contact_phone";
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [selectedMethod, setSelectedMethod] = useState<IOption | undefined>();
  const [value, setValue] = useState(defaulValue);
  const [showEdit, setShowEdit] = useState(false);
  useEffect(() => {
    if (isNew) return;
    if (defaulValue && defaultMethod) {
      setSelectedMethod({ label: defaultMethod, value: defaulValue });
    }
  }, [defaulValue, defaultMethod, isNew, isEdit]);
  useOnClickOutside<HTMLDivElement>(ref, () => {
    if (isEdit) {
      setShowEdit(false);
      onComplete(selectedMethod?.value || "", value);
    } else {
      if (isNew) {
        setSelectedMethod(undefined);
      } else {
        onComplete(selectedMethod?.value || "", value);
        setSelectedMethod(undefined);
      }
    }
  });
  if (isEdit) {
    return (
      <div className="rounded border  border-gray-300 p-4" ref={ref}>
        {showEdit ? (
          <div className="flex flex-col gap-4">
            <TextInput
              id="org-mailing"
              value={value}
              className="h-[45px]"
              topPlaceholder={TOP_PLACEHOLDER[defaultMethod || ""]}
              placeholder={PLACEHOLDER[defaultMethod || ""]}
              handleOnChange={(e) => {
                setValue(e.target.value);
              }}
              handleOnKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShowEdit(false);
                  onComplete(defaultMethod || "", value);
                }
              }}
            />
            <div className="self-end">
              <Button
                id="add-btn"
                handleOnClick={() => {
                  onComplete?.(defaultMethod, "");
                  setValue("");
                }}
                label="Remove"
              />
            </div>
          </div>
        ) : (
          <div className={classNames("flex items-center justify-between")}>
            <div>
              <span className="font-poppins font-semibold text-gray-900">
                {TOP_PLACEHOLDER[selectedMethod?.label || ""]}:
              </span>{" "}
              {value}
            </div>
            <span
              onClick={() => {
                setShowEdit(true);
              }}
            >
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.4617 4.87853C16.2466 4.6634 15.9912 4.49274 15.7101 4.37631C15.4291 4.25988 15.1278 4.19995 14.8236 4.19995C14.5194 4.19995 14.2181 4.25988 13.937 4.37631C13.656 4.49274 13.4006 4.6634 13.1855 4.87853L4.47381 13.5902C4.21458 13.8494 4.02606 14.1711 3.92767 14.5246L3.01626 17.7796C2.99521 17.8551 2.99459 17.9348 3.01447 18.0105C3.03435 18.0863 3.07402 18.1554 3.12942 18.2108C3.18481 18.2662 3.25394 18.3059 3.32971 18.3258C3.40548 18.3457 3.48518 18.345 3.56063 18.324L6.81506 17.4126C7.16851 17.3137 7.49052 17.1254 7.75004 16.8659L16.4617 8.15418C16.896 7.71976 17.14 7.13063 17.14 6.51636C17.14 5.90208 16.896 5.31295 16.4617 4.87853ZM13.8106 5.50303C14.0793 5.23435 14.4438 5.08345 14.8238 5.0835C15.2038 5.08356 15.5683 5.23457 15.8369 5.50332C16.1056 5.77207 16.2565 6.13655 16.2565 6.51656C16.2564 6.89658 16.1054 7.26101 15.8366 7.52968L14.9293 8.43638L12.9033 6.40972L13.8106 5.50303ZM12.2788 7.0348L14.3054 9.06146L7.12495 16.2414C6.97287 16.3935 6.78417 16.5038 6.57705 16.5619L4.07849 17.2612L4.7778 14.7632C4.83592 14.5561 4.94628 14.3674 5.0983 14.2153L12.2788 7.0348Z"
                  fill="#5F5F5F"
                />
              </svg>
            </span>
          </div>
        )}
      </div>
    );
  }
  return (
    <div ref={ref}>
      {selectedMethod ? (
        <div className="flex w-full flex-col gap-4">
          <TextInput
            id="org-mailing"
            value={value}
            className="h-[45px]"
            placeholder={PLACEHOLDER[selectedMethod?.value || ""]}
            handleOnChange={(e) => {
              setValue(e.target.value);
            }}
            handleOnKeyDown={(e) => {
              if (e.key === "Enter") {
                onComplete(selectedMethod?.value || "", value);
                setSelectedMethod(undefined);
                setValue("");
              }
            }}
          />
          <div className="self-end">
            <Button
              id="add-btn"
              handleOnClick={() => {
                onComplete(selectedMethod?.value || "", value);
                setSelectedMethod(undefined);
              }}
              disabled={!value}
              label="Add"
            />
          </div>
        </div>
      ) : (
        <SingleSelector
          id={`input-communication-method`}
          value={selectedMethod}
          placeholder="Choose a communication method"
          inputContainerClass={"h-[45px]  !text-gray-700 !text-xs"}
          placeholderClass="!text-gray-500 !text-sm"
          onChange={(v) => {
            setSelectedMethod(v ? v : undefined);
            setValue("");
          }}
          compact
          data={COMMUNICATION_METHOD.filter((i) => !exclude.includes(i.value))}
          isClearable={false}
          dropdownIcon
        />
      )}
    </div>
  );
}
