import React, { CSSProperties, ReactNode } from "react";
import "./css/input.scss";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import PlainButton from "./PlainButton";
import CloseButton from "./CloseButton";

interface GibooDialogProps {
  title: string;
  header?: ReactNode;
  footer?: ReactNode;
  content?: ReactNode;
  open: boolean;
  onClose: () => void;
  headerClassname?: string;
  contentClassname?: string;
  footerClassname?: string;
  className?: string;
  style?: CSSProperties;
}

function GibooDialog({
  header,
  footer,
  content,
  open,
  onClose,
  title,
  style,
  className,
  ...props
}: GibooDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose?.();
      }}
      className={classNames("", className)}
    >
      <div className="fixed inset-0 z-[100] bg-black/80" aria-hidden="true" />
      <div className="fixed inset-0 z-[101] flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto h-fit w-full max-w-xl rounded bg-white" style={style}>
          {header ? (
            <div
              className={classNames(
                "flex h-20 items-center justify-center border-b border-t border-gray-300 p-6",
                props.headerClassname,
              )}
            >
              {header}
            </div>
          ) : (
            <>
              <div className="flex justify-between p-6">
                <h4>{title}</h4>
                <PlainButton
                  id={`btn-delete-close`}
                  className="!p-0"
                  handleOnClick={() => {
                    onClose();
                  }}
                  leftIconClass={<CloseButton />}
                />
              </div>
            </>
          )}
          <div
            className={classNames(
              "min-h-20 flex items-center justify-center border-b border-t border-gray-300 p-6",
              props.contentClassname,
            )}
          >
            {content}
          </div>
          {footer && (
            <div className={classNames("flex items-center justify-end p-6", props.footerClassname)}>
              {footer}
            </div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default GibooDialog;
