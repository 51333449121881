import React from "react";
import { Outlet } from "react-router-dom";
import "../css/box.scss";
import classNames from "classnames";

interface WhiteContainerProps {
  children: React.ReactNode;
  wrapperClass?: string;
}

function WhiteContainer(props: WhiteContainerProps) {
  return (
    <div
      className={classNames("my-2 rounded border border-gray-300 bg-white", props.wrapperClass)}
      style={{ padding: "30px" }}
    >
      {props.children}
    </div>
  );
}

export default WhiteContainer;
