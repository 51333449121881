import React, { ReactNode, useEffect, useState } from "react";
import IMG_1 from "../../../../assets/diversity/org-member-type-1.svg";
import IMG_2 from "../../../../assets/diversity/org-member-type-2.svg";
import IMG_3 from "../../../../assets/diversity/org-member-type-3.svg";
import IMG_4 from "../../../../assets/diversity/org-member-type-4.svg";
import classNames from "classnames";
import Spacer from "../../../../components/Spacer";
import Button from "../../../../components/tailwind/Button";
import Checkbox from "../../../../components/checkbox/Checkbox";
import {
  getUserDisctionaryValue,
  setUserDictionaryValue,
} from "../../../../hooks/useUserDictionary";
import UpdateOrgPeopleMemeberType from "./UpdateOrgPeopleMemeberType";
import useScrollTopByDefault from "../../../../hooks/useScrollTopByDefault";
import useElement from "../../../../hooks/useElement";
import useOrgProfile from "../../../../hooks/useOrgProfile";

export default function OrgPeopleMemberType({
  onBack,
  onNext,
  npo_id,
  org_id,
  hasWritePermission,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  org_id?: string;
  hasWritePermission?: boolean;
  isBack?: boolean;
}) {
  const [consent, setConsent] = useState<boolean>(false);
  const [showUpdateOrg, setShowUpdateOrg] = useState<boolean>(false);
  const scrollContainer = useElement("diversity-scroll-container");
  useScrollTopByDefault();
  const { data: profile, update: profileUpdate, isLoading } = useOrgProfile(org_id);
  useEffect(() => {
    if (isLoading) return;
    setConsent(profile?.diversity_consent ? true : false);
  }, [isLoading, profile]);
  const renderTitle = (title: string, className?: string) => {
    return (
      <div>
        <h5 className={classNames("font-poppins text-xl font-semibold text-black", className)}>
          {title}
        </h5>
      </div>
    );
  };

  const renderitem = (
    index: number,
    title: string | ReactNode,
    content: ReactNode,
    image: string,
  ) => {
    return (
      <div className="flex flex-wrap items-center gap-[60px] md:flex-nowrap">
        <div className="flex w-full max-w-[493px] flex-col gap-3">
          <div className="inline-flex items-start gap-2">
            <span className="flex h-[21px] w-[21px] shrink-0 items-center justify-center rounded-full bg-purple-500 text-xs font-semibold text-white ">
              {index}
            </span>
            <div className="font-poppins text-base font-semibold text-black">{title}</div>
          </div>
          <div className="pl-[30px] font-poppins text-sm text-gray-800">{content}</div>
        </div>
        <div className="h-fit w-fit">
          <img src={image} alt="business-model-image" />
        </div>
      </div>
    );
  };
  if (showUpdateOrg) {
    return (
      <UpdateOrgPeopleMemeberType
        onBack={() => {
          setShowUpdateOrg(false);
          scrollContainer?.scrollTo(0, 0);
        }}
        onNext={onNext}
        org_id={org_id}
        npo_id={npo_id}
        hasWritePermission={hasWritePermission}
      />
    );
  }
  return (
    <div className="flex max-w-[881px] flex-col gap-6">
      {renderTitle("Great! Let’s now dive into creating organization’s diversity information.")}
      <div className="flex flex-col gap-[60px]">
        {renderitem(
          1,
          "Step 1: Update organization’s board member, employee and volunteer information",
          <>
            First step is to distinguish board members, employees and volunteer information. This is
            essential as these groups often serve distinct roles and carry different
            responsibilities within the nonprofit structure.
            <Spacer height="16px" />
            Funders frequently seek board member information as it provides insights into the
            governance and leadership of the organization, showcasing the expertise, diversity, and
            commitment of individuals guiding its strategic direction. This process keeps
            organization&apos;s leadership and workforce data remain up-to-date.
          </>,
          IMG_1,
        )}
        {renderitem(
          2,
          "Step 2: Understanding and choosing to showcase your organization's diversity information",
          <>
            Seven core diversity sections—ethnicity, gender, age, sexual orientation, disability,
            religion, and professional background—play a pivotal role in addressing key factors
            funders consider when evaluating nonprofit organizations.
            <Spacer height="16px" />
            You have the option to showcase your organization&apos;s diversity data, or you can
            choose to skip this step if it doesn&apos;t align with your preferences for the
            organization profile.
          </>,
          IMG_2,
        )}
        {renderitem(
          3,
          "Step 3: Update member information across each section",
          <>
            In the next phase, diligently update the information for each member within each
            diversity section. This ensures that Giboo can accurately analyze the data, providing
            you with valuable insights in just a matter of seconds. This step is crucial for
            obtaining a information of your organization&apos;s diversity landscape.
          </>,
          IMG_3,
        )}
        {renderitem(
          4,
          "Step 4: View your organization's diversity analysis",
          <>
            Upon completing each section, Giboo presents a comprehensive data analysis view. This
            analysis serves as a valuable tool to gauge your organization&apos;s current standing in
            terms of diversity. It provides a snapshot of where you stand in terms of diversity,
            aiding in informed decision-making and fostering continuous improvement.
          </>,
          IMG_4,
        )}

        <div className="space-y-4 rounded border border-orange-300 bg-white p-4">
          <h6 className="font-poppins text-xs text-gray-800">
            Your organization&apos;s dashboard will showcase the information gathered in these
            steps, providing a comprehensive overview of your philanthropic diversity. By consenting
            to the presentation of this data, you acknowledge and agree that the information
            pertaining to board members, employees, and volunteers collected during these steps will
            be displayed in your organization profile.
          </h6>
          <div className="flex w-full justify-start">
            <Checkbox
              id={`input-check-consent`}
              className=""
              label={"I consent"}
              size="sm"
              inputClass="!border-gray-700"
              labelClass="!text-xs font-poppins !text-gray-700"
              checked={consent}
              onChange={() => {
                if (!hasWritePermission) return;
                setConsent(!consent);
                profileUpdate({ diversity_consent: !consent });
              }}
            />
          </div>
        </div>

        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            disabled
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            disabled={!consent}
            handleOnClick={() => {
              scrollContainer?.scrollTo(0, 0);
              setShowUpdateOrg(true);
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
