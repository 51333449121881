import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../api";
import { Onboarding } from "../app/onboarding";
import { useCallback } from "react";
import { swrOptionFetchOnlyOnMount } from "../types/swr";
interface useVerifiedOrgState {
  data?: Onboarding;
  isLoading?: boolean;
  error?: object;
  isValidating: boolean;
  revalidate: () => Promise<Onboarding | undefined>;
}
function useVerifiedOrg(npo_id?: string): useVerifiedOrgState {
  const { mutate } = useSWRConfig();
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const { data, isLoading, error, isValidating } = useSWR<Onboarding>(
    npo_id ? process.env.REACT_APP_API_URL + `/api/v2/orgs/verified/${npo_id}` : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const url = npo_id ? process.env.REACT_APP_API_URL + `/api/v2/orgs/verified/${npo_id}` : null;
  const revalidate = useCallback(() => mutate<Onboarding>(url), [url]);
  return {
    data: npo_id && data && data._id ? data : undefined,
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useVerifiedOrg;
