import { useCallback, useEffect, useState } from "react";
import {
  selectMoreSearchOptionState,
  updateMoreSearchOptionAsync,
} from "../app/moreSearchOptionSlice";
import { getSearchQueryFromOnboardingData } from "../app/onboardingSlice";
import { RootState, useAppDispatch, useAppSelector } from "../app/store";
import { SearchQuery, SearchQueryNullable, SearchType, defaultSearchQuery } from "../types/search";
import useOnboardingData from "./useOnboarding";

const useSavedMoreSearchOption = (): {
  isFetched: boolean;
  query: SearchQuery;
  search_type: number;
  searchType: SearchType;
  updateSearchQuery: (q: SearchQueryNullable) => void;
  setSearchType: (t: SearchType) => void;
} => {
  const [onboardingData] = useOnboardingData();
  const [defaultSearchQueryFromOnboarding, setDefaultSearchQueryFromOnboarding] =
    useState<SearchQuery>(defaultSearchQuery);
  const { isFetched, query, search_type } = useAppSelector((state: RootState) =>
    selectMoreSearchOptionState(state),
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    setDefaultSearchQueryFromOnboarding({
      ...getSearchQueryFromOnboardingData(onboardingData),
      focus_area: [],
      beneficiary: [],
      program: [],
      focus_area_subs: [],
      beneficiary_subs: [],
      program_subs: [],
    });
  }, [onboardingData, isFetched]);

  const setSearchType = useCallback(
    (t: SearchType) => {
      const intergerType =
        t === "grant"
          ? 2
          : t === "virtual_grant"
          ? 0
          : t === "npo"
          ? 3
          : t === "by_name"
          ? 4
          : t === "past_grant"
          ? 5
          : t === "specific_funder_grant"
          ? 6
          : t === "eligible_npo"
          ? 7
          : 1;
      dispatch(updateMoreSearchOptionAsync({}, intergerType > 4 ? 1 : intergerType));
    },
    [dispatch],
  );
  const updateSearchQuery = useCallback(
    (q: SearchQueryNullable) => {
      dispatch(updateMoreSearchOptionAsync(q, search_type));
    },
    [dispatch, search_type, updateMoreSearchOptionAsync],
  );
  return {
    isFetched,
    query: query || defaultSearchQueryFromOnboarding,
    search_type,
    searchType:
      search_type >= 0 && search_type < 7
        ? (
            [
              "virtual_grant",
              "funder",
              "grant",
              "npo",
              "by_name",
              "past_grant",
              "specific_funder_grant",
              "eligible_npo",
            ] as SearchType[]
          )[search_type] || "grant"
        : "grant",
    setSearchType,
    updateSearchQuery,
  };
};
export default useSavedMoreSearchOption;
