import React, { ReactNode } from "react";
import GibooGradientDiv from "./GibooGradientDiv";
import classNames from "classnames";
import Button from "./tailwind/Button";

export default function ReviewAndEditCard({
  wrapperClass,
  content,
  handleOnClick,
  label = "Confirm",
}: {
  wrapperClass?: string;
  content: ReactNode;
  handleOnClick: () => void;
  label?: string;
}) {
  return (
    <GibooGradientDiv
      borderSize={1}
      wrapperClassName={classNames(
        "w-full mb-6 mx-auto shadow-[0_0_15px_1px_rgba(0,0,0,0.1)] ",
        wrapperClass,
      )}
    >
      <div className="flex min-h-[99px] w-full flex-col  gap-3 bg-gray-50 p-4">
        <p className="font-poppins text-sm">{content}</p>
        <Button
          className="h-[29px] w-fit "
          handleOnClick={handleOnClick}
          id="review and edit"
          label={label}
        />
      </div>
    </GibooGradientDiv>
  );
}
