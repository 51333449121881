import React, { ReactNode } from "react";
import IMG_1 from "../../../../assets/diversity/org-diversity-info.svg";
import classNames from "classnames";
import Button from "../../../../components/tailwind/Button";
import Spacer from "../../../../components/Spacer";

export default function OrgDiversityInfo({
  onBack,
  onNext,
  npo_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  isBack?: boolean;
}) {
  return (
    <div className="flex max-w-[760px] flex-col gap-5">
      <div className="flex w-full flex-col gap-2">
        <div className="font-poppins text-base font-semibold text-black">
          Identify organization&apos;s diversity information
        </div>
      </div>
      <div className="h-[688px] w-full rounded border border-gray-300 bg-white p-5">
        <div className="mb-2 w-[720px] font-poppins text-sm font-semibold text-purple-500">
          Ready to start?
        </div>

        <div>
          <span className="font-poppins text-xs font-normal text-gray-800">
            Let&apos;s now update the organization&apos;s diversity information. <br />
            We&apos;ll go through{" "}
          </span>
          <span className="font-poppins text-xs font-semibold text-purple-500">
            seven different aspects of diversity,
          </span>
          <span className="font-poppins text-xs font-normal text-gray-800">
            {" "}
            and you have the option to either create or skip the data visualization for each
            section.
          </span>
        </div>
        <Spacer height="20px" />
        <div className="inline-flex h-[198px] w-[323px] items-center justify-end gap-4">
          <div className="inline-flex w-[282px] flex-col items-start justify-start gap-3">
            <div className="self-stretch font-poppins text-xs font-normal text-gray-700">
              I. Ethnicity
            </div>
            <div className="self-stretch font-poppins text-xs font-normal text-gray-700">
              II. Gender
            </div>
            <div className="self-stretch font-poppins text-xs font-normal text-gray-700">
              III. Age
            </div>
            <div className="self-stretch font-poppins text-xs font-normal text-gray-700">
              IV. Sexual orientation perspective
            </div>
            <div className="self-stretch font-poppins text-xs font-normal text-gray-700">
              V. Disability
            </div>
            <div className="self-stretch font-poppins text-xs font-normal text-gray-700">
              VI. Religion
            </div>
            <div className="self-stretch font-poppins text-xs font-normal text-gray-700">
              VII. Professional background of board member
            </div>
          </div>
        </div>
        <Spacer height="40px" />
        <div className="flex w-full justify-center">
          <div className="h-[306px] w-[343px]">
            <img src={IMG_1} className="h-fit w-fit" alt="org-diversity-info-image" />
          </div>
        </div>
      </div>
      <div className="mt-5 flex h-[38px] items-center justify-start gap-2">
        <Button
          className="!h-[37px]"
          id="btn-back"
          handleOnClick={() => {
            onBack?.();
          }}
          label="Back"
          outline
          prependIcon={<i className="gi-left-arrow gi" />}
        />
        <Button
          className="!h-[37px]"
          id="btn-next"
          handleOnClick={() => {
            onNext?.();
          }}
          label="Next"
          appendIcon={
            <div className="-mt-1 !rotate-180">
              <i className="gi-left-arrow" />
            </div>
          }
        />
      </div>
    </div>
  );
}
