import React from "react";

export default function IncreaseTag() {
  return (
    <span className="inline-flex items-center gap-1 h-5 rounded px-1 py-[2px] bg-[#EDF6FA] w-fit">
      <span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 12.6673V3.33398"
            stroke="#27678A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.33301 8.00065L7.99967 3.33398L12.6663 8.00065"
            stroke="#27678A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
      <p className="font-poppins text-[10px] text-[#27678A]">Increase</p>
    </span>
  );
}
