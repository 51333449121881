import { ReactNode, useCallback, useEffect, useState } from "react";
import ProjectPicker from "../pages/ProjectPicker";
import useProjects from "../hooks/project/useProjects";
import { IProject } from "../types/project";
import { useNavigate } from "react-router-dom";
import useTasks from "../hooks/project/useTasks";
import useProject from "../hooks/project/useProject";
import { createProjectTask, inactiveProjectTaskByTarget } from "../hooks/project/useTask";
import GibooToast from "./GibooToast";
import { SearchQuery } from "../types/search";
interface SaveToProjectContextRenderProps {
  isAdded?: boolean;
  isLoading?: boolean;
  showPicker: () => void;
  add: () => void;
  remove: () => void;
}
interface SaveToProjectContextProps {
  project_id: string | undefined;
  target_id: string;
  target_type: 0 | 1 | 2;
  search_query?: SearchQuery;
  children?: (props: SaveToProjectContextRenderProps) => ReactNode;
}
type IsFunction<T> = T extends (...args: any[]) => any ? T : never;
const isFunction = <T extends object>(value: T): value is IsFunction<T> =>
  typeof value === "function";
const SaveToProjectContext = ({
  project_id,
  target_id,
  target_type,
  search_query,
  children,
}: SaveToProjectContextProps) => {
  if (children && !isFunction(children)) {
    throw new Error("children is mandatory and needs to be a function");
  }
  const navigate = useNavigate();
  const [projects] = useProjects();
  const [show, setShow] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data: tasks } = useTasks(project_id);
  const { data: projectData } = useProject(project_id);

  const addToProject = useCallback(
    (projectData: IProject, id: string, type: 0 | 1 | 2, search_query?: SearchQuery) => {
      if (!projectData) return;
      setIsLoading(true);
      createProjectTask({
        project_id: projectData._id,
        target_id: id,
        target_type: type,
        ...(search_query ? { search_query } : {}),
      })
        .then(() => {
          setIsAdded(true);
          GibooToast({
            type: "success",
            message: `${target_type === 0 ? "Funder" : "Grant"} successfully added to ${
              projectData.name
            }.`,
            actionLabel: "View project",
            handleAction: () => {
              navigate(`/project/${projectData._id}`);
            },
          });
        })
        .catch((err) => {
          GibooToast({
            type: "failure",
            message: `${target_type === 0 ? "Funder" : "Grant"} failed to add to the ${
              projectData.name
            }.`,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [project_id, projectData, setIsLoading, search_query],
  );
  const removeFromProject = useCallback(
    (projectData: IProject, id: string, type: 0 | 1 | 2) => {
      if (!projectData) return;
      setIsLoading(true);
      inactiveProjectTaskByTarget(projectData._id, id, type)
        .then((res) => {
          setIsAdded(false);
          GibooToast({
            type: "success",
            message: `${target_type === 0 ? "Funder" : "Grant"} removed from the project.`,
          });
        })
        .catch((err) => {
          GibooToast({
            type: "failure",
            message: `${target_type === 0 ? "Funder" : "Grant"} failed to remove from the project.`,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [project_id, projectData, setIsLoading],
  );
  useEffect(() => {
    if (projectData) {
      setIsAdded(
        tasks.find((t) => t.target_id === target_id && t.target_type === target_type && !t.inactive)
          ? true
          : false,
      );
    }
  }, [projectData]);
  const showPicker = useCallback(() => {
    setShow(true);
  }, []);
  const add = useCallback(() => {
    const p = projects.data.find((p) => p._id === project_id);
    if (project_id && p) {
      addToProject(p, target_id, target_type);
    }
  }, [project_id, projects, isAdded]);
  const remove = useCallback(() => {
    const p = projects.data.find((p) => p._id === project_id);
    if (project_id && p) {
      removeFromProject(p, target_id, target_type);
    }
  }, [project_id, projects, isAdded]);
  return (
    <>
      <ProjectPicker
        show={show}
        type="ADD_TO"
        onClose={() => setShow((prev) => !prev)}
        onSelect={(data: IProject) => {
          setShow((prev) => !prev);
          addToProject(data, target_id, target_type);
        }}
      />
      {children &&
        children({ showPicker, add, remove, isAdded: project_id ? isAdded : false, isLoading })}
    </>
  );
};
export default SaveToProjectContext;
