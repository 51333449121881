const swrOptionDedupling5mins = {
  dedupingInterval: 1000 * 300,
  refreshInterval: 0,
  revalidateIfStale: false,
  keepPreviousData: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}; // 5 mins
const swrOptionFetchOnlyOnMount = {
  keepPreviousData: false,
  revalidateOnFocus: false,
  revalidateIfStale: false,
  revalidateOnReconnect: false,
  revalidateOnMount: true,
};
export { swrOptionDedupling5mins, swrOptionFetchOnlyOnMount };
