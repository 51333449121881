import React from "react";
import RecDonors from "../../components/RecDonors";
import classNames from "classnames";
import { scrollBarClass } from "../../utils/classes";
import { SearchQuery } from "../../types/search";
import useOnboardingData from "../../hooks/useOnboarding";

interface Iprops {
  customClass?: string;
  exclude?: string[];
  query: SearchQuery;
}
function SimilarToFunder({ customClass, exclude, query }: Iprops) {
  const [onboardingData] = useOnboardingData();
  const renderTitle = () => {
    return (
      <div className="flex w-full select-none flex-col items-center   border-b border-gray-300 bg-white px-6 py-4 font-poppins ">
        <p className=" w-full font-poppins text-base font-normal ">Similar to this funder</p>
      </div>
    );
  };
  return (
    <div className="flex h-full  w-full flex-col items-center justify-start  rounded border border-gray-300  bg-white ">
      {renderTitle()}
      <div className={classNames("flex w-full max-h-[1200px] flex-col overflow-y-scroll", scrollBarClass)}>
        <RecDonors recommendationQuery={query} exclude={exclude} parentPage="funder" />
      </div>
    </div>
  );
}
export default SimilarToFunder;
