import React from "react";

interface SpacerProps {
  height?: string;
  width?: string;
}

function Spacer(props: SpacerProps) {
  return <div style={{ height: props.height, width: props.width ||'100%' }} />;
}

export default Spacer;
