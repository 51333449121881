import classNames from "classnames";
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

interface DropdownPropsRenderProps {
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}
type DropdownProps = {
  children: (props: DropdownPropsRenderProps) => ReactNode;
  id?: string;
  className?: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green";
  onOpen?: () => void;
  maxHeight?: string;
  openAnimation?: boolean;
  disabled?: boolean;
  defaultExpanded?: boolean;
};
type DropdownButtonProps = {
  className?: string;
  id?: string;
};
type DropdownPanelProps = {
  className?: string;
  id?: string;
};
type DropdownButtonComponent = React.FunctionComponent<PropsWithChildren<DropdownButtonProps>>;
type DropdownPanelComponent = React.FunctionComponent<PropsWithChildren<DropdownPanelProps>>;
type DropdownComponent = React.FunctionComponent<DropdownProps> & {
  Button: DropdownButtonComponent;
  Panel: DropdownPanelComponent;
};

interface IDropdownContext {
  colorClass: string;
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  onOpen: () => void;
  maxHeight?: string;
  disabled: boolean;
}
const DropdownContextContext = createContext<IDropdownContext | undefined>(undefined);
const useDropdownContextContext = () => {
  const context = useContext(DropdownContextContext);
  if (!context) throw new Error("Provider not found");
  return context;
};

const Button: DropdownButtonComponent = ({
  children,
  className,
}: PropsWithChildren<DropdownButtonProps>) => {
  const { colorClass, expanded, setExpanded, onOpen, disabled } = useDropdownContextContext();
  return (
    <div
      className={classNames(className, "dropdown-button", `dropdown-button${colorClass}`)}
      onClick={() => {
        if (!expanded) onOpen();
        setExpanded((prev) => !prev);
      }}
    >
      {children}
    </div>
  );
};
const Panel: DropdownPanelComponent = ({
  children,
  className,
}: PropsWithChildren<DropdownPanelProps>) => {
  const { expanded, maxHeight } = useDropdownContextContext();
  return (
    <div
      className={classNames(className, "expand-container", {
        expanded: expanded,
      })}
    >
      <div
        className={classNames("expand-target", {
          expanded: expanded,
        })}
        style={{ maxHeight: maxHeight }}
      >
        {children}
      </div>
    </div>
  );
};
const Dropdown: DropdownComponent = ({
  children,
  className,
  color,
  onOpen = () => {},
  openAnimation = false,
  maxHeight,
  disabled = false,
  defaultExpanded = false,
}: DropdownProps) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const colorClass = color ? `-${color}` : "";
  return (
    <DropdownContextContext.Provider
      value={{ colorClass, expanded, setExpanded, onOpen, maxHeight, disabled }}
    >
      <div
        className={classNames(
          "dropdown",
          { "dropdown-animated": openAnimation },
          className ? className : "",
        )}
      >
        {children({ expanded, setExpanded })}
      </div>
    </DropdownContextContext.Provider>
  );
};
Dropdown.Button = Button;
Dropdown.Panel = Panel;
type IsFunction<T> = T extends (...args: any[]) => any ? T : never;
const isFunction = <T extends object>(value: T): value is IsFunction<T> =>
  typeof value === "function";

export default Dropdown;
