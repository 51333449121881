import classNames from "classnames";

const range = [1_000_000, 10_000_000, 100_000_000, 1_000_000_000];
export default function TagFunderSize({
  assets,
  className,
}: {
  assets: number;
  className?: string;
}) {
  const funderSize = range.reduce(
    (prev, cur, i) => (prev === range.length && assets < cur ? i : prev),
    range.length,
  );
  const colorVariant = [
    "border-[#726027] bg-[#FFFAEA] text-[#726027]",
    "border-[#435788] bg-[#EAF7FF] text-[#435788]",
    "border-[#2D8D1D] bg-[#F0FFEE] text-[#2D8D1D]",
    "border-[#D1366E] bg-[#FFEAF1] text-[#D1366E]",
    "border-[#514EFF] bg-[#E3E2FF] text-[#514EFF]",
  ][funderSize];
  const label = ["Small funder", "Medium funder", "Large funder", "Major funder", "Mega funder"][
    funderSize
  ];
  return (
    <span
      className={classNames(
        "whitespace-nowrap rounded-full border px-2 py-[2px] text-xs !font-normal leading-[18px]",
        colorVariant,
        className,
      )}
      aria-valuetext={label}
    >
      {label}
    </span>
  );
}
