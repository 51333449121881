import LeftInnerIconInput from "../components/LeftInnerIconInput";
import PasswordInput from "../components/PasswordInput";
import PlainButton from "../components/PlainButton";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Dropdown from "../components/dropdown/Dropdown";
import DropdownSelector from "../components/dropdown/DropdownSelector";
import PlainDropdownSelector from "../components/dropdown/PlainDropdownSelector";
import Tag from "../components/Tag";
import Label from "../components/Label";
import RangeSlider from "../components/input/RangeSlider";
import LeftInnerIconNumberInput from "../components/LeftInnerIconNumberInput";
import RangeInput from "../components/input/RangeInput";
import FileDropArea from "../components/input/FileDropArea";
import axios from "axios";
import PlainMultipleDropdownSelector from "../components/dropdown/PlainMultipleDropdownSelector";
import MultipleDropdownSelector from "../components/dropdown/MultipleDropdownSelector";
import MultipleCreatableSelector from "../components/selector/MultipleCreatableSelector";
import GroupBaseMultipleCreatableSelector from "../components/selector/GroupBaseMultipleCreatableSelector";
import RoundButton from "../components/RoundButton";
import { Pagination } from "rsuite";

interface IDonor {
  key: number;
  name: string;
  address: string;
}

function HiddenTest() {
  const [loading, setLoading] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [selected, setSelected] = useState<IDonor>();
  const [location, setLocation] = useState<string>();
  const [donor, setDonor] = useState<IDonor>();
  const [stage, setStage] = useState<string | undefined>("All");
  useEffect(() => {
    setTimeout(() => {
      setDonor({
        key: 0,
        name: "Ahl Foundation",
        address: "420 West 23rd Street, Suite 7A,  New York, New York 10011",
      });
    }, 2000);
  }, []);
  const fakeFetch = () => {
    if (keywords.length > 0) return;
    setLoading(true);
    setTimeout(() => {
      setKeywords([
        "Cultural diversity",
        "Cultural identity",
        "Public art",
        "Cultural differences",
        "Contemporary art",
        "Cultural heritage",
        "Visual art",
        "Exhibitions",
      ]);
      setLoading(false);
    }, 3000);
  };
  const states = [
    "Alaska",
    "Alabama",
    "Arkansas",
    "American Samoa",
    "Arizona",
    "California",
    "Colorado",
    "Connecticut",
    "District of Columbia",
    "Delaware",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Iowa",
    "Idaho",
    "Illinois",
    "Indiana",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Massachusetts",
    "Maryland",
    "Maine",
    "Michigan",
    "Minnesota",
    "Missouri",
    "Mississippi",
    "Montana",
    "North Carolina",
    "North Dakota",
    "Nebraska",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "Nevada",
    "New York",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Virginia",
    "Virgin Islands",
    "Vermont",
    "Washington",
    "Wisconsin",
    "West Virginia",
    "Wyoming",
  ];
  const donors = [
    {
      key: 0,
      name: "Ahl Foundation",
      address: "420 West 23rd Street, Suite 7A,  New York, New York 10011",
    },
    {
      key: 1,
      name: "Windham Community Memorial Hospital Women's Auxiliary Inc",
      address: "112 Mansfield Avenue, Willimantic, CT, 06226",
    },
    {
      key: 2,
      name: "AbbVie Foundation",
      address: "1 North Waukegan Road, North Chicago, IL, 60064",
    },
    {
      key: 3,
      name: "JONAS FAMILY FOUNDATION TRUST",
      address: "60355 SUNSET VIEW DR, BEND, OR, 97702",
    },
  ] as IDonor[];
  const [v1, setV1] = useState<number | undefined>();
  const [v2, setV2] = useState<number | undefined>();
  const [v3, setV3] = useState<number | undefined>();
  const [rv1, setRv1] = useState<number | undefined>();
  const [rv2, setRv2] = useState<number | undefined>();

  /* For Multiple Selector */
  const [myStates, setMyStates] = useState<string[]>([]);
  const [myDonors, setMyDonors] = useState<IDonor[]>([]);

  const icecreamData = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const [icecream, setIcecream] = useState<{ value: string; label: string }[]>([]);
  const [ethnics, setEthnics] = useState<{ value: string; label: string }[]>([]);
  const [activePage, setActivePage] = useState(5);
  /* For FileDropArea */
  const [file, setFile] = useState<File>();
  const [file_key, setFileKey] = useState<string>("img/hello.png");
  const [file_url, setFileUrl] = useState<string>("");

  const getUploadUrl = async (key: string) => {
    // console.log("get upload url");
    return axios
      .post(
        process.env.REACT_APP_UPLOAD_NPO_MEDIA_URL || "",
        { key },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
      .then((response) => response.data);
    // .catch((e) => console.log(e));
  };

  const uploadFile = async (): Promise<string> => {
    if (!file) {
      return "";
    }

    // Key should be unique, recommend using UUID in production code
    const key = `img/${Date.now()}.${file.name}`;
    // console.log("upload file key:", key);

    const uploadUrl = await getUploadUrl(key);
    if (uploadUrl) {
      // console.log("upload presigned url:", uploadUrl);
      try {
        await axios.put(uploadUrl, file, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });
      } catch (e) {
        // console.log(e);
      }
    } else {
      // console.error("couldn't get s3 url");
      return "";
    }
    // console.log("upload success, key:", key);
    return key;
  };

  const onDrop = (f: File) => {
    // console.log(f);
    setFile(f);
  };

  const onUploadFile = async () => {
    if (file) {
      const uploaded_file_key = await uploadFile();
      setFileKey(uploaded_file_key);
    }
  };

  useEffect(() => {
    const getFileUrl = async (key: string) => {
      // console.log("get upload url");
      try {
        const response = await axios.post(
          process.env.REACT_APP_DOWNLOAD_NPO_MEDIA_URL || "",
          { key },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        // console.log("file_url", response.data);
        setFileUrl(response.data);
      } catch (e) {
        // return console.log(e);
      }
    };

    getFileUrl(file_key);
  }, [file_key]);
  /* End FileDropArea */

  return (
    <div className="container mx-auto flex flex-col gap-3 p-6 sm:px-4">
      <div className="giboo-box shadow-sm">
        Pagination
        <Pagination
          prev={true}
          last={false}
          next={true}
          first={false}
          size="md"
          total={200}
          limit={15}
          maxButtons={6}
          ellipsis
          activePage={activePage}
          onChangePage={setActivePage}
        />
      </div>
      <div className="giboo-box shadow-sm">
        Round Button
        <div className="flex flex-wrap items-center gap-2">
          {/* PRIMARY */}
          <RoundButton
            id="primary"
            handleOnClick={() => {
              console.log("btn clicked");
            }}
            size="sm"
            type="primary"
            icon="gi-md gi-notification"
          />
          <RoundButton
            id="primary"
            handleOnClick={() => {
              console.log("btn clicked");
            }}
            size="sm"
            type="primary"
            icon="gi-md gi-notification"
            disabled
          />
          {/* SECONDARY */}
          <RoundButton
            id="primary"
            handleOnClick={() => {
              console.log("btn clicked");
            }}
            size="sm"
            type="secondary"
            icon="gi-md gi-notification"
          />
          <RoundButton
            id="primary"
            handleOnClick={() => {
              console.log("btn clicked");
            }}
            size="sm"
            type="secondary"
            icon="gi-md gi-notification"
            disabled
          />
          {/* SECONDARY MUTED */}
          <RoundButton
            id="primary"
            handleOnClick={() => {
              console.log("btn clicked");
            }}
            size="sm"
            type="secondary muted"
            icon="gi-md gi-notification"
          />
          <RoundButton
            id="primary"
            handleOnClick={() => {
              console.log("btn clicked");
            }}
            size="sm"
            type="secondary muted"
            icon="gi-md gi-notification"
            disabled
          />
          {/* TERTIARY */}
          <RoundButton
            id="primary"
            handleOnClick={() => {
              console.log("btn clicked");
            }}
            size="sm"
            type="tertiary"
            icon="gi-md gi-notification"
          />
          <RoundButton
            id="primary"
            handleOnClick={() => {
              console.log("btn clicked");
            }}
            size="sm"
            type="tertiary"
            icon="gi-md gi-notification"
            disabled
          />
          {/* TERTIARY HIGHLIGHT*/}
          <RoundButton
            id="primary"
            handleOnClick={() => {
              console.log("btn clicked");
            }}
            size="sm"
            type="tertiary highlight"
            icon="gi-md gi-notification"
          />
          <RoundButton
            id="primary"
            handleOnClick={() => {
              console.log("btn clicked");
            }}
            size="sm"
            type="tertiary highlight"
            icon="gi-md gi-notification"
            disabled
          />
        </div>
      </div>
      <div className="flex flex-wrap  space-x-3 space-y-3">
        <div className="w-1/2">
          <div className="giboo-box">
            <RangeSlider id="single" min={0} max={100000} onChange={() => {}} />
          </div>
        </div>
        <div className="flex w-full space-x-3 ">
          <div className="giboo-box flex w-1/2 flex-col gap-3">
            <LeftInnerIconNumberInput
              id="input-number-1"
              value={v1}
              setValue={setV1}
              iconClass="gi-md gi-dollar-sign"
              placeholder="integer"
            />
            <LeftInnerIconNumberInput
              id="input-number-2"
              value={v2}
              setValue={setV2}
              iconClass="gi-md gi-dollar-sign"
              placeholder="positive or zero"
              min={0}
            />
            <LeftInnerIconNumberInput
              id="input-number-3"
              value={v3}
              setValue={setV3}
              iconClass="gi-md gi-dollar-sign"
              placeholder="float"
              acceptFloat
            />
          </div>
          <div className="giboo-box w-1/2">
            <RangeInput
              id="input-range-1"
              a={rv1}
              setA={setRv1}
              b={rv2}
              setB={setRv2}
              min={0}
              max={10000}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="giboo-box flex gap-3 shadow-sm">
            <div className="w-1/3">
              <PlainMultipleDropdownSelector
                id={"my-state"}
                data={states}
                selected={myStates}
                setSelected={setMyStates}
                placeholder="select your fav states"
                checkbox
                compact
              />
            </div>
            <div className="w-2/3">
              <MultipleDropdownSelector<IDonor>
                id={"my-donor"}
                data={donors}
                color="red"
                selected={myDonors}
                setSelected={setMyDonors}
                getKey={(donor) => donor.key}
                renderItem={(item: IDonor) => (
                  <div className="flex flex-col gap-2">
                    <b>{item.name}</b>
                    <div className="flex items-center gap-3">
                      <i className="fa fa-location-dot purple" />
                      <p className="m-0">{item.address}</p>
                    </div>
                    <div></div>
                  </div>
                )}
                renderButton={(item: IDonor[]) =>
                  item.length > 0 ? item.map((item) => item.name).join(", ") : undefined
                }
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="giboo-box flex gap-3 shadow-sm">
            <div className="w-1/2">
              <MultipleCreatableSelector
                id="icecream"
                data={icecreamData}
                value={icecream}
                onCreateOption={(val: string) =>
                  setIcecream((prev) => [...prev, { value: val, label: val }])
                }
                onChange={(v) => setIcecream(v.map((item) => item))}
              />
            </div>
            <div className="w-1/2"></div>
          </div>
        </div>

        <div className="w-full">
          <div className="giboo-box shadow-sm">
            <div className="flex flex-wrap items-center gap-2">
              <Label name="Focus area" color="yellow" outline active />
              <Label name="Environment" size="lg" leftIconClass="environment" />
              <Label
                name="Environment"
                color="yellow"
                size="lg"
                outline
                active
                leftIconClass="environment"
              />
              <Label name="Climate change" color="yellow" outline active />
              <Label name="Climate justice" color="yellow" size="sm" outline active />
              <Label name="deselected" color="yellow" size="sm" outline />
              <Label name="Beneficiary" color="blue" outline active />
              <Label name="Health" size="lg" />
              <Label name="Health" color="blue" size="lg" outline active />
              <Label name="Patients" color="blue" outline active />
              <Label name="Climate justice" color="blue" size="sm" outline active />
              <Label name="disabled" color="blue" size="sm" outline disabled />
              <Label name="Activity" color="green" outline active />
              <Label name="recommended" color="green" outline recommended onClick={() => {}} />
            </div>
          </div>
        </div>
        <div className="w-3/4">
          <div className="giboo-box shadow-sm">
            <h4>DropDown</h4>
            <div className="flex gap-2">
              <div className="w-1/3">
                <p className="subtitle my-2">Plain Text Dropdown Selector</p>
                <PlainDropdownSelector
                  id="location"
                  data={states}
                  selected={location}
                  setSelected={setLocation}
                  placeholder={"Select location"}
                  leftIconClass={"fa fa-location-dot purple"}
                  maxHeight={"400px"}
                />
              </div>
              <div className="w-2/3">
                <p className="subtitle my-2">Custom Template Dropdown Selector</p>
                <div className="flex">
                  <DropdownSelector<IDonor>
                    renderItem={(item: IDonor) => (
                      <div className="flex flex-col gap-2">
                        <b>{item.name}</b>
                        <div className="flex items-center gap-3">
                          <i className="fa fa-location-dot purple" />
                          <p className="m-0">{item.address}</p>
                        </div>
                        <div></div>
                      </div>
                    )}
                    id="donors"
                    color="purple"
                    selected={selected}
                    setSelected={setSelected}
                    placeholder={"Select your farvorite"}
                    getKey={(item: IDonor) => item.key}
                    data={donors}
                    openAnimation // TBD. doesn't work
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4">
          <div className="giboo-box shadow-md">
            <p className="subtitle my-2">Plain Text Dropdown Selector Compact</p>
            <PlainDropdownSelector
              id="stage"
              color="gray"
              data={["All", "Dreamer", "Start-up", "Build-up", "Super star"]}
              selected={stage}
              setSelected={setStage}
              // placeholder={"Select location"}
              // rightIconClass={"fa fa-arrow-down gray"}
              maxHeight={"400px"}
              float
              compact
              downIcon
              defaultValue="All"
            />
          </div>
        </div>
        <div className="w-full">
          <div className="giboo-box shadow-sm">
            <h4>Dropdown Expand</h4>
            <div className="flex flex-wrap ">
              <div className="w-1/2">
                <p className="subtitle my-2">Dropdown</p>
                <Dropdown
                  className={classNames("rounded-sm shadow-sm", {
                    "loading-light-shimmer": donor !== undefined,
                    "loading-light-shimmer-on": donor === undefined,
                  })}
                  color={"purple"}
                  onOpen={fakeFetch}
                  openAnimation
                  maxHeight="500px"
                >
                  {() => (
                    <>
                      <Dropdown.Button className="rounded-sm shadow-sm">
                        <div className="flex flex-col gap-2 px-4 py-2">
                          <div className="loading-light-shimmer-child mt-2 flex">
                            <div style={{ minWidth: "100px" }}>
                              <b>{donor ? donor.name : ""}</b>
                            </div>
                          </div>
                          <div className="loading-light-shimmer-child flex items-center gap-3">
                            <i className="fa fa-location-dot purple" />
                            <div style={{ minWidth: "200px" }}>
                              <p className="m-0">{donor?.address}</p>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </Dropdown.Button>
                      <Dropdown.Panel>
                        <div className="p-4">
                          <div
                            className={classNames("rounded-sm p-4", {
                              "loading-light-shimmer": !loading,
                              "loading-light-shimmer-on": loading,
                            })}
                            style={{ background: "#fafafa" }}
                          >
                            <b>Your organization&apos;s keyword</b>
                            <p className="subtitle" style={{ color: "#5c38a7" }}>
                              We&apos;ve identified 26 keywords of your organization.
                            </p>
                            {loading ? (
                              <div className="loading-light-shimmer-child flex flex-col gap-4">
                                <div className="w-full"></div>
                                <div className="w-full"></div>
                                <div className="w-full"></div>
                              </div>
                            ) : (
                              <div className="flex flex-wrap">
                                {keywords.map((keyword) => (
                                  <Tag key={keyword} id={keyword} name={keyword} />
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </Dropdown.Panel>
                    </>
                  )}
                </Dropdown>
              </div>
              <div className="w-1/2">
                <p className="subtitle my-2">Dropdown without animation</p>
                <Dropdown className="rounded-sm shadow-sm" color={"purple"}>
                  {() => (
                    <>
                      <Dropdown.Button className="rounded-sm shadow-sm">
                        <div className="mx-3 my-2">
                          <p className="semibold mb-1">My previous donors</p>
                          <p className="subtitle m-0">More detail</p>
                        </div>
                      </Dropdown.Button>
                      <Dropdown.Panel>
                        <div className="my-1 flex flex-col gap-1" style={{ background: "#fafafa" }}>
                          {donors.map((item) => (
                            <div
                              className="flex flex-col gap-2 rounded-sm bg-white p-2"
                              key={item.key}
                            >
                              <b>{item.name}</b>
                              <div className="flex items-center gap-3">
                                <p className="m-0">{item.address}</p>
                              </div>
                              <div></div>
                            </div>
                          ))}
                        </div>
                      </Dropdown.Panel>
                    </>
                  )}
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4">
          <div className="giboo-box shadow-sm">
            <div className="flex flex-col gap-2 py-2">
              <h5>PrimaryButton</h5>
              <PrimaryButton handleOnClick={() => {}} id="1" label={"Primary"}></PrimaryButton>
              <PrimaryButton
                handleOnClick={() => {}}
                id="btn-pn"
                label={"Primary Normal"}
                leftIconClass="fa-solid fa-arrow-rotate-left"
              ></PrimaryButton>
              <PrimaryButton
                color="red"
                handleOnClick={() => {}}
                id="btn-pr"
                label={"Primary Red"}
                rightIconClass="fa-solid fa-arrow-up"
              ></PrimaryButton>
              <PrimaryButton
                color="yellow"
                handleOnClick={() => {}}
                id="btn-py"
                label={"Primary Yellow"}
                rightIconClass="fa-solid fa-arrow-up"
              ></PrimaryButton>
              <PrimaryButton
                color="green"
                handleOnClick={() => {}}
                id="btn-pg"
                label={"Primary Green"}
                rightIconClass="fa-solid fa-arrow-up"
              ></PrimaryButton>
              <PrimaryButton
                color="blue"
                handleOnClick={() => {}}
                id="btn-pb"
                label={"Primary Blue"}
                rightIconClass="fa-solid fa-arrow-up"
              ></PrimaryButton>
              <PrimaryButton
                color="blue"
                handleOnClick={() => {}}
                id="btn-pd"
                label={"disabled"}
                rightIconClass="fa-solid fa-arrow-up"
                disabled
              ></PrimaryButton>
            </div>
          </div>
        </div>
        <div className="w-1/4">
          <div className="giboo-box shadow-sm">
            <div className="flex flex-col gap-2 py-2">
              <h5>SecondaryButton</h5>
              <SecondaryButton
                handleOnClick={() => {}}
                id="1"
                label={"Secondary"}
              ></SecondaryButton>
              <SecondaryButton
                color="purple"
                handleOnClick={() => {}}
                id="1"
                label={"Secondary Purple"}
                leftIconClass="fa-solid fa-arrow-rotate-left"
              ></SecondaryButton>
              <SecondaryButton
                color="red"
                handleOnClick={() => {}}
                id="1"
                label={"Secondary Red"}
                rightIconClass="fa-solid fa-arrow-up"
              ></SecondaryButton>
              <SecondaryButton
                color="yellow"
                handleOnClick={() => {}}
                id="1"
                label={"Secondary Yellow"}
                rightIconClass="fa-solid fa-arrow-up"
              ></SecondaryButton>
              <SecondaryButton
                color="green"
                handleOnClick={() => {}}
                id="1"
                label={"Secondary Green"}
                rightIconClass="fa-solid fa-arrow-up"
              ></SecondaryButton>
              <SecondaryButton
                color="blue"
                handleOnClick={() => {}}
                id="1"
                label={"Secondary Blue"}
                rightIconClass="fa-solid fa-arrow-up"
              ></SecondaryButton>
              <SecondaryButton
                color="blue"
                handleOnClick={() => {}}
                id="1"
                label={"Secondary disabled"}
                rightIconClass="fa-solid fa-arrow-up"
                disabled
              ></SecondaryButton>
            </div>
          </div>
        </div>
        <div className="w-1/4">
          <div className="giboo-box shadow-sm">
            <div className="flex flex-col gap-2 py-2">
              <h5>SecondaryButton Tonal</h5>
              <h5>default -&gt; gray border</h5>
              <h5>on hover -&gt; colorize border, text</h5>
              <SecondaryButton
                handleOnClick={() => {}}
                id="btn-snt"
                label={"2nd Tonal"}
                leftIconClass="fa-solid fa-arrow-rotate-left"
                tonal
              ></SecondaryButton>
              <SecondaryButton
                handleOnClick={() => {}}
                id="btn-snt"
                color="purple"
                label={"2nd Tonal Purple"}
                leftIconClass="fa-solid fa-arrow-rotate-left"
                tonal
              ></SecondaryButton>
              <SecondaryButton
                color="red"
                handleOnClick={() => {}}
                id="btn-srt"
                label={"2nd Tonal Red"}
                rightIconClass="fa-solid fa-arrow-up"
                tonal
              ></SecondaryButton>
              <SecondaryButton
                color="yellow"
                handleOnClick={() => {}}
                id="btn-syt"
                label={"2nd Tonal Yellow"}
                rightIconClass="fa-solid fa-arrow-up"
                tonal
              ></SecondaryButton>
              <SecondaryButton
                color="green"
                handleOnClick={() => {}}
                id="btn-sgt"
                label={"2nd Tonal Green"}
                rightIconClass="fa-solid fa-arrow-up"
                tonal
              ></SecondaryButton>
              <SecondaryButton
                color="blue"
                handleOnClick={() => {}}
                id="btn-sbt"
                label={"2nd Tonal Blue"}
                rightIconClass="fa-solid fa-arrow-up"
                tonal
              ></SecondaryButton>
              <SecondaryButton
                color="blue"
                handleOnClick={() => {}}
                id="btn-sdt"
                label={"2nd Tonal disabled"}
                rightIconClass="fa-solid fa-arrow-up"
                tonal
                disabled
              ></SecondaryButton>
            </div>
          </div>
        </div>
        <div className="w-1/4">
          <div className="giboo-box shadow-sm">
            <div className="flex flex-col gap-2 py-2">
              <h5>SecondaryButton Active</h5>
              <h5>default -&gt; colorize text, weight 600</h5>
              <SecondaryButton
                handleOnClick={() => {}}
                id="btn-sni"
                label={"2nd Active"}
                leftIconClass="fa-solid fa-arrow-rotate-left"
                active
              ></SecondaryButton>
              <SecondaryButton
                color="purple"
                handleOnClick={() => {}}
                id="btn-spi"
                label={"2nd Active Purple"}
                leftIconClass="fa-solid fa-arrow-rotate-left"
                active
              ></SecondaryButton>
              <SecondaryButton
                color="red"
                handleOnClick={() => {}}
                id="btn-sri"
                label={"2nd Active Red"}
                rightIconClass="fa-solid fa-arrow-up"
                active
              ></SecondaryButton>
              <SecondaryButton
                color="yellow"
                handleOnClick={() => {}}
                id="btn-syi"
                label={"2nd Active Yellow"}
                rightIconClass="fa-solid fa-arrow-up"
                active
              ></SecondaryButton>
              <SecondaryButton
                color="green"
                handleOnClick={() => {}}
                id="btn-sgi"
                label={"2nd Active Green"}
                rightIconClass="fa-solid fa-arrow-up"
                active
              ></SecondaryButton>
              <SecondaryButton
                color="blue"
                handleOnClick={() => {}}
                id="btn-sbi"
                label={"2nd Active Blue"}
                rightIconClass="fa-solid fa-arrow-up"
                active
              ></SecondaryButton>
              <SecondaryButton
                color="blue"
                handleOnClick={() => {}}
                id="btn-sdi"
                label={"2nd Active disabled"}
                rightIconClass="fa-solid fa-arrow-up"
                active
                disabled
              ></SecondaryButton>
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <div className="giboo-box shadow-sm">
            <div className="flex flex-col items-start gap-2 py-2">
              <h5>PlainButton</h5>
              <PlainButton handleOnClick={() => {}} id="1" label={"Tertiary"} outline></PlainButton>
              <PlainButton
                handleOnClick={() => {}}
                id="1"
                label={"Tertiary"}
                leftIconClass="fa-solid fa-arrow-rotate-left"
                outline
              ></PlainButton>
              <PlainButton
                handleOnClick={() => {}}
                id="1"
                label={"Tertiary"}
                rightIconClass="fa-solid fa-arrow-up"
                outline
              ></PlainButton>
              <PlainButton
                handleOnClick={() => {}}
                id="1"
                label={"Tertiary no outline"}
                rightIconClass="fa-solid fa-arrow-up"
              ></PlainButton>
              <PlainButton
                handleOnClick={() => {}}
                id="1"
                label={"no outline & disabled"}
                rightIconClass="fa-solid fa-arrow-up"
                disabled
              ></PlainButton>
            </div>
          </div>
        </div>

        <div className="w-1/3">
          <div className="giboo-box shadow-sm">
            <div className="flex flex-col gap-2 py-2">
              <h5>LeftInnerIconInput</h5>
              <LeftInnerIconInput
                id="1"
                iconClass="fa-regular fa-user"
                placeholder="First name*"
                handleOnChange={() => {}}
              />
              <LeftInnerIconInput
                id="1"
                iconClass="fa-regular fa-user"
                placeholder="First name*"
                handleOnChange={() => {}}
                color="purple"
              />
              <LeftInnerIconInput
                id="1"
                iconClass="fa-regular fa-user"
                placeholder="First name*"
                handleOnChange={() => {}}
                color="red"
              />
              <LeftInnerIconInput
                id="1"
                iconClass="fa-regular fa-user"
                placeholder="First name*"
                handleOnChange={() => {}}
                color="yellow"
              />
              <LeftInnerIconInput
                id="1"
                iconClass="fa-regular fa-user"
                placeholder="First name*"
                handleOnChange={() => {}}
                color="green"
              />
              <LeftInnerIconInput
                id="1"
                iconClass="fa-regular fa-user"
                placeholder="First name*"
                handleOnChange={() => {}}
                color="blue"
              />
              <LeftInnerIconInput
                id="1"
                iconClass="fa-regular fa-user"
                placeholder="Disabled"
                handleOnChange={() => {}}
                disabled
              />
              <LeftInnerIconInput
                id="1"
                iconClass="fa-regular fa-user"
                placeholder="Invalid*"
                handleOnChange={() => {}}
                invalid
              />
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <div className="giboo-box shadow-sm">
            <div className=" flex flex-col gap-2 py-2">
              <h5>PasswordInput</h5>
              <PasswordInput id="1" placeholder="Password*" handleOnChange={() => {}} />
              <PasswordInput
                id="1"
                placeholder="Password*"
                handleOnChange={() => {}}
                color="purple"
              />
              <PasswordInput id="1" placeholder="Password*" handleOnChange={() => {}} color="red" />
              <PasswordInput
                id="1"
                placeholder="Password*"
                handleOnChange={() => {}}
                color="yellow"
              />
              <PasswordInput
                id="1"
                placeholder="Password*"
                handleOnChange={() => {}}
                color="green"
              />
              <PasswordInput
                id="1"
                placeholder="Password*"
                handleOnChange={() => {}}
                color="blue"
              />
              <PasswordInput id="1" placeholder="Password*" handleOnChange={() => {}} disabled />
              <PasswordInput id="1" placeholder="Password*" handleOnChange={() => {}} invalid />
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <div className="giboo-box shadow-sm">
            <h5>Label</h5>
            <div className="grid grid-flow-col grid-rows-4 gap-3">
              <div className="flex">
                <Label id="1" name="Default tonal" />
              </div>
              <div className="flex">
                <Label id="1" name="purple tonal" color="purple" />
              </div>
              <div className="flex">
                <Label id="1" name="red tonal" color="red" />
              </div>
              <div className="flex">
                <Label id="1" name="yellow tonal" color="yellow" />
              </div>
              <div className="flex">
                <Label id="1" name="green tonal" color="green" />
              </div>
              <div className="flex">
                <Label id="1" name="blue tonal" color="blue" />
              </div>
              <div className="flex">
                <Label id="1" name="gray tonal" color="gray" />
              </div>
              <div className="flex"></div>
              <div className="flex">
                <Label onDelete={() => {}} id="1" name="Default tonal" />
              </div>
              <div className="flex">
                <Label onDelete={() => {}} id="1" name="purple tonal" color="purple" />
              </div>
              <div className="flex">
                <Label onDelete={() => {}} id="1" name="red tonal" color="red" />
              </div>
              <div className="flex">
                <Label onDelete={() => {}} id="1" name="yellow tonal" color="yellow" />
              </div>
              <div className="flex">
                <Label onDelete={() => {}} id="1" name="green tonal" color="green" />
              </div>
              <div className="flex">
                <Label onDelete={() => {}} id="1" name="blue tonal" color="blue" />
              </div>
              <div className="flex">
                <Label onDelete={() => {}} id="1" name="blue tonal" color="gray" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <div className="giboo-box shadow-sm">
            <PrimaryButton
              handleOnClick={onUploadFile}
              id="1"
              label={"Upload File"}
            ></PrimaryButton>
            <FileDropArea
              id="input-file"
              style={{ height: "110px" }}
              onDrop={onDrop}
              formats={[".png", ".jpg", ".jpeg"]}
              sizeLimit={25}
              defaultValue={file ? file.name : undefined}
              disabled={loading}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="giboo-box shadow-sm">{file_url && <img src={file_url} />}</div>
        </div>
      </div>
    </div>
  );
}
export default HiddenTest;
