import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../api";
import { ReactNode, useCallback } from "react";
import { swrOptionFetchOnlyOnMount } from "../types/swr";
import { NPOSummary } from "../types/npo";
interface NPOState {
  data: NPOSummary | undefined;
  error: any;
  isLoading: boolean;
}
function useNPOSummary(npo_id?: string): NPOState {
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<NPOSummary>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data?.summary);
  };
  const { data, isLoading, error } = useSWR<NPOSummary>(
    npo_id ? process.env.REACT_APP_API_URL + `/api/v2/npos/${npo_id}/summary` : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );

  return {
    data: data,
    isLoading,
    error,
  };
}
export default useNPOSummary;
