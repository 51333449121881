import useSWR from "swr";
import axios from "axios";
import config from "../../api";
import { FundChar } from "../../types/funder";
import { swrOptionDedupling5mins } from "../../types/swr";
interface FunderStageAnalysisAmountState {
  data: FundChar[];
  error: any;
  isLoading: boolean;
}

function useFunderStageAnalysis(
  funder_id?: string,
  type: "amount" | "count" = "amount",
): FunderStageAnalysisAmountState {
  const fetchFundChar = async (_url: string) => {
    if (!_url || !funder_id) return new Promise<FundChar[]>((resolve, reject) => reject());
    return axios.get(_url, config).then(({ data }) => data as FundChar[]);
  };
  const { data, isLoading, error } = useSWR<FundChar[]>(
    funder_id
      ? process.env.REACT_APP_API_URL +
          `/api/v2/donors/fundcharacteristics?donor_id=${funder_id}&info_type=${type}`
      : null,
    fetchFundChar,
    swrOptionDedupling5mins,
  );

  return {
    data: data || [],
    isLoading,
    error,
  };
}
export default useFunderStageAnalysis;
