import { useEffect, useState } from "react";
import useUser from "../../hooks/useUser";
import useOnboardingData from "../../hooks/useOnboarding";
import ProfilePlaceholder from "../../assets/images/profile-bg-placeholder.svg";
import edit_icon from "../../assets/images/edit-white.svg";
import camera_icon from "../../assets/images/camera-white.svg";
import { Onboarding, OnboardingRequest } from "../../app/onboarding";
import { uploadFileService } from "../../services/file-upload/upload.service";
import { useAppDispatch, useAppSelector } from "../../app/store";
import UpdateImage from "../../components/UpdateImage";
import { getUserBGImageKey, getUserProfileImageKey } from "../../utils/media";
import classNames from "classnames";
import useOrgs from "../../hooks/useOrgs";
import useTotalOrganizationMembersData, {
  getTotalOrganizationMembersId,
} from "../../hooks/useTotalOrganizationMembersData";
import useOrgMember from "../../hooks/useOrgMember";
import OrgProfileCardLarge from "../../components/OrgProfileCardLarge";
import AddNewEmployee from "./organizational-profile/component/AddNewEmployee";
import PlainButton from "../../components/PlainButton";
import { reprLocation } from "../../types/location";
import useTotalOrganizationMember from "../../hooks/useTotalOrganizationMember";
import { IOrgManualMemberUpdateRequest } from "../../types/org";
import usePhotoUrl from "../../hooks/usePhotoUrl";
import useScrollTopByDefault from "../../hooks/useScrollTopByDefault";
import { MyProfileCard } from "./Home";
import usePageTitle from "../../hooks/usePagetitle";

function MyProfile() {
  const [user] = useUser();
  usePageTitle(`My profile`);
  const [onboardingData] = useOnboardingData();
  useScrollTopByDefault();
  const { data: orgUser } = useTotalOrganizationMember(
    onboardingData._id,
    getTotalOrganizationMembersId({ _id: user?._id || "", type: "user" }),
  );
  const { orgs } = useOrgs();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const renderLeftContainer = () => {
    return (
      <>
        <div
          style={{ top: `-${top}px` }}
          className={classNames(
            `sticky   flex w-[384px] flex-col items-center justify-start gap-4`,
          )}
        >
          <MyProfileCard />
        </div>
      </>
    );
  };

  const renderRow = (key: string, value?: string) => {
    return (
      <div className="grid grid-cols-4">
        <h5 className="text-gray-700">{key}</h5>
        <h5 className="col-span-3 text-black">{value ? value : "No information"}</h5>
      </div>
    );
  };
  const renderInfo = () => {
    return (
      <>
        {orgUser && (
          <>
            <div className="flex flex-col gap-5 rounded border border-gray-300 bg-white p-5">
              <div className="flex justify-between bg-white">
                <h4>About</h4>
                <PlainButton
                  id="btn-edit"
                  className="bg-white"
                  leftIconClass="gi gi-md gi-pencil"
                  handleOnClick={() => setShowEdit(true)}
                />
              </div>
              <div className="flex flex-col gap-6">
                {renderRow("Your title", orgUser.title)}
                {orgUser.employ_type !== "board" &&
                  renderRow("Your role", orgUser.roles?.join(" / "))}
                {renderRow("Member type", orgUser.employ_type)}
                {orgUser.employ_type === "employee" &&
                  renderRow("Your Employment status", orgUser.employ_status)}
                {renderRow("Your contact", orgUser.email)}
                <div className="h-[1px] w-full bg-gray-300"></div>
                {renderRow(
                  "Location",
                  orgUser.geographic_focus?.map((l) => reprLocation(l))?.join(" / "),
                )}
                {orgUser.employ_type === "board" &&
                  renderRow("Profession", orgUser.profession?.join(" / "))}
                {orgUser.employ_type === "board" &&
                  renderRow("Profession background", orgUser.board_profession_sector?.join(" / "))}
                {renderRow("Ethnicity", orgUser.ethnic?.join(" / "))}
                {renderRow("Gender identity", orgUser.gender)}
                {renderRow("Generation", orgUser.generation?.join(" / "))}
                {renderRow("Sexual orientation", orgUser.sexual_orientation?.join(" / "))}
                {renderRow("Disability", orgUser.disability)}
                {renderRow("Religion", orgUser.religion?.join(" / "))}
                {renderRow("Language", orgUser.language?.join(" / "))}
              </div>
            </div>
          </>
        )}
      </>
    );
  };
  const renderOrgs = () => {
    return (
      <>
        <div className="flex flex-col gap-5 rounded border border-gray-300 bg-white p-5">
          <h4>Organizations you work in</h4>
          <div className="flex flex-col gap-3">
            {orgs
              .filter((o) => o.completed)
              .map((o) => (
                <OrgProfileCardLarge key={o._id} org={o} joined={o.joined} role={o.role || 0} />
              ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="mx-auto mt-[40px] flex w-full gap-[32px]">
        <div className="flex w-[384px] items-start justify-center">{renderLeftContainer()}</div>
        <main className=" flex w-[800px] flex-col gap-5 overflow-y-auto">
          {showEdit && orgUser && (
            <AddNewEmployee
              employType="employee"
              title={"Edit my profile"}
              isEdit={false}
              showMemberSelect
              memberEditId={getTotalOrganizationMembersId({ ...orgUser, type: "user" })}
              emailRequired
              showAddEmployee={showEdit}
              onClose={() => {
                setShowEdit(false);
              }}
              disableEditEmail
              hideEditPhoto
            />
          )}
          {renderInfo()}
          {renderOrgs()}
        </main>
      </div>
    </>
  );
}
export default MyProfile;
