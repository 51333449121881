import classNames from "classnames";
import useProjectName from "../hooks/project/useProjectName";

export default function ProjectName({ id, className }: { id: string; className?: string }) {
  const { data, isLoading } = useProjectName(id);
  return (
    <div
      className={classNames("w-full", {
        "loading-light-shimmer": !isLoading,
        "loading-light-shimmer-on -mt-[2px]": isLoading,
      })}
    >
      <div className="loading-light-shimmer-child w-full">
        <div className={classNames("min-w-[100px]", className)}>{data}</div>
      </div>
    </div>
  );
}
