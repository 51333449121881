import React from "react";
import { TooltipWrapper } from "../../../../components/TooltipWrapper";
import classNames from "classnames";

export default function FinancialPredictionInfo() {
  return (
    <TooltipWrapper
      color="white"
      content={`Our financial predictions are crafted using historical data trends. However, extraordinary events, like the COVID-19 pandemic, have led to unforeseen fluctuations in market values. Such events can disrupt the usual patterns, making predictions for assets like LUNA and other financial data points less consistent with actual outcomes. We're refining our models to better navigate these anomalies, aiming to enhance predictive accuracy amidst global changes. Your understanding is appreciated as we improve our analytics for more reliable forecasting.`}
      arrow={false}
      textClass="text-xs w-[433px] text-black p-4"
      placements="rightStart"
    >
      <div className={classNames("mt-2 inline-flex cursor-pointer items-center gap-2")}>
        <span className="grid place-items-center">
          <i className="gi-md gi-info text-gray-700" />
        </span>
        <h6 className="font-poppins text-xs text-gray-700">
          How does Giboo predict financial data?
        </h6>
      </div>
    </TooltipWrapper>
  );
}
