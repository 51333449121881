import { useEffect, useRef, useState } from "react";
import Table from "rc-table";
import { Popover, Whisper, DatePicker } from "rsuite";
import UserProfile from "./UserProfile";
import classNames from "classnames";
import GibooToast from "./GibooToast";
//images
import IMG_TRASH from "../assets/project-mgt/trash.svg";
import TextInput from "./tailwind/TextInput";
import useSubtask, { createProjectSubtask } from "../hooks/project/useSubtask";
import useSubtasks from "../hooks/project/useSubtasks";
import useSubtaskNote from "../hooks/project/useSubtaskNote";
import ProjectSubtaskNotesPopup from "./ProjectSubtaskNotesPopup";
import icon_file from "../assets/project-mgt/file.svg";
import useLetter from "../hooks/project/useLetter";
import LetterPreview from "../pages/npo/grant/pages/component/LetterPreview";
import PopupModal from "./tailwind/PopupModal";
import Button from "./tailwind/Button";
import { IProjectLetter } from "../types/project";
import useProject from "../hooks/project/useProject";
import useOrgMembers from "../hooks/project/useOrgMembers";
import ProjectInvite from "./ProjectInvite";
import DropdownSelector from "./dropdown/DropdownSelector";
import { User } from "../app/userSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { convertToReadable } from "../utils/formatHelper";
import { TooltipWrapper } from "./TooltipWrapper";
import LeftInnerIconInput from "./LeftInnerIconInput";
import OrgMemberSuggestion from "./OrgMemberSuggestion";
import UserList from "./UserList";
import GeneratedLetterPreview from "./GeneratedLetterPreview";
import useOnboardingData from "../hooks/useOnboarding";

interface IprojectSubTask {
  project_id: string;
  task_id: string;
  autoWidth?: boolean;
  autoHeight?: boolean;
}
export default function ProjectTaskManage({
  project_id,
  task_id,
  autoWidth = false,
  autoHeight = false,
}: IprojectSubTask) {
  const navigate = useNavigate();
  const { hasEditPermission } = useProject(project_id);
  const [task, setTask] = useState<any[]>([]);
  const [showLoiPreview, setShowLoiPreview] = useState(false);
  const [loiContent, setLoiContent] = useState<IProjectLetter>();
  const { data, isLoading, revalidate, isValidating } = useSubtasks(task_id);
  useEffect(() => {
    if (isLoading) return;
    const combined = [...data];
    setTask(combined);
  }, [isLoading, isValidating]);
  const [searchParams] = useSearchParams();
  const { data: selectedLetter } = useLetter(searchParams.get("letter_id") || undefined);
  const { data: selectedNote } = useSubtaskNote(searchParams.get("note_id") || undefined);
  const { data: selectedSubtask } = useSubtask(searchParams.get("subtask_id") || undefined);
  const [showAllNotes, setShowAllNotes] = useState(false);
  useEffect(() => {
    const showLetter = searchParams.get("letter_id");
    if (showLetter && selectedLetter) {
      setLoiContent(selectedLetter);
      setShowLoiPreview(true);
    }
  }, [searchParams, selectedLetter]);
  useEffect(() => {
    const subtask_id = searchParams.get("subtask_id");
    const note_id = searchParams.get("note_id");
    if (subtask_id && note_id && selectedNote && selectedSubtask) {
      setShowAllNotes(true);
    }
  }, [searchParams, selectedNote, selectedSubtask]);
  const columns = [
    {
      title: "Task",
      dataIndex: "_id",
      key: "_id",
      width: "310px",
      render: (id: any, rowData: any) => {
        return (
          <SubTaskNameEditor
            project_id={project_id}
            subtask_id={rowData._id}
            handleViewLoi={(loi: IProjectLetter) => {
              setLoiContent(loi);
              setShowLoiPreview(true);
            }}
          />
        );
      },
    },
    {
      title: "Owner",
      dataIndex: "_id",
      key: "_id",
      width: 110,
      render: (id: any, rowData: any) => {
        return <SubtaskOwners project_id={project_id} subtask_id={rowData._id} />;
      },
    },
    {
      title: "Status",
      dataIndex: "_id",
      key: "_id",
      width: 150,
      render: (id: any, rowData: any) => {
        return <StatusPicker project_id={project_id} subtask_id={rowData._id} />;
      },
    },
    {
      title: "Due date",
      dataIndex: "_id",
      key: "_id",
      width: 200,
      render: (id: any, rowData: any) => {
        return <DateEditor project_id={project_id} subtask_id={rowData._id} />;
      },
    },
    {
      title: "Latest note",
      dataIndex: "_id",
      key: "_id",
      width: 295,
      render: (id: any, rowData: any) => {
        return (
          <LatestSubtaskNoteMemo
            project_id={project_id}
            subtask_id={rowData._id}
            revalidate={() => {
              revalidate();
            }}
          />
        );
      },
    },
    {
      title: (
        <span className="flex w-full justify-center">
          <img src={IMG_TRASH} alt="trash" className="h-5 w-5" />
        </span>
      ),
      dataIndex: "_id",
      key: "_id",
      width: 50,
      render: (id: any, rowData: any) => (
        <SubtaskDelete
          project_id={project_id}
          subtask_id={rowData._id}
          revalidate={() => {
            revalidate();
          }}
        />
      ),
    },
  ];
  return (
    <>
      {showAllNotes && (
        <ProjectSubtaskNotesPopup
          project_id={project_id}
          subtask_id={selectedSubtask._id}
          onClose={() => {
            setShowAllNotes(false);
            navigate(location.pathname, { replace: true });
            revalidate();
          }}
          show={showAllNotes}
          title={selectedSubtask.name}
        />
      )}
      {project_id && task_id && loiContent?._id && (
        <GeneratedLetterPreview
          letter_id={loiContent?._id}
          project_id={project_id}
          task_id={task_id}
          showLoiPreview={showLoiPreview}
          setShowLoiPreview={(f: boolean) => {
            setShowLoiPreview(f);
            if (!f) navigate(location.pathname, { replace: true });
          }}
        />
      )}
      <div className={classNames("relative max-w-[1150px]")}>
        <Table
          {...(hasEditPermission && {
            footer: () => (
              <AddMoreSubTask
                project_id={project_id}
                task_id={task_id}
                revalidate={() => {
                  revalidate();
                }}
              />
            ),
          })}
          columns={columns}
          data={task}
          tableLayout="auto"
          rowKey={"_id"}
          emptyText={""}
          className="w-full"
          scroll={{ x: "max-content" }}
        />
      </div>
    </>
  );
}
//Date editor start
interface IDateEditor {
  wrapperClassName?: string;
  project_id: string | undefined;
  subtask_id: string | undefined;
}

function DateEditor({ project_id, subtask_id, ...props }: IDateEditor) {
  const { hasEditPermission } = useProject(project_id);
  const [value, setValue] = useState<string | undefined>();
  const { data, isLoading, update } = useSubtask(subtask_id);
  useEffect(() => {
    if (isLoading) return;
    setValue(data.due_date);
  }, [data, isLoading]);
  return (
    <>
      <div
        className={classNames("loading-light-shimmer", isLoading ? "loading-light-shimmer-on" : "")}
      >
        <div className={classNames("loading-light-shimmer-child min-w-[50px]")}>
          <div className={classNames(!isLoading ? "hidden" : "w-full")} />
          <DatePicker
            className={classNames(
              "table-cell-date-picker !z-[1200] !h-[30px] !w-full",
              isLoading && "hidden",
            )}
            value={value ? new Date(value) : undefined}
            size="sm"
            oneTap
            readOnly={!hasEditPermission}
            editable={false}
            cleanable={true}
            placeholder="MM/DD/YYYY"
            onChange={(value) => {
              if (value) {
                setValue(value.toISOString());
                update({ due_date: value.toISOString() });
              } else {
                setValue(undefined);
                update({ due_date: "" });
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
//Date editor end

//Status Picker Starts
enum PROJ_STATUS {
  NOT_STARTED = "Not started",
  IN_PROGRESS = "In progress",
  DONE = "Done",
}
const PROJ_STATUS_COLOR = {
  NOT_STARTED: "#DEDEDE",
  IN_PROGRESS: "#FFEC8B",
  DONE: "#7FC8F8",
};
interface IstatusPicker {
  wrapperClassName?: string;
  project_id: string | undefined;
  subtask_id: string | undefined;
}
function StatusPicker({ project_id, subtask_id, ...props }: IstatusPicker) {
  const { hasEditPermission } = useProject(project_id);
  const enumKeys = Object.keys(PROJ_STATUS) as Array<keyof typeof PROJ_STATUS>;
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("Not started");
  const { data, isLoading, update } = useSubtask(subtask_id);
  useEffect(() => {
    if (isLoading) return;
    setValue(data.status);
  }, [data, isLoading]);
  const enumKeyValuePairs = enumKeys.map((enumKey) => ({
    key: enumKey,
    value: PROJ_STATUS[enumKey],
  }));
  return (
    <>
      <Whisper
        placement="bottom"
        controlId="control-id-hover-enterable"
        className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)] "
        open={show}
        speaker={
          <Popover onMouseLeave={() => setShow(false)} className="!px-0">
            <ul className="h-auto min-w-[162px] flex-col gap-4">
              {enumKeyValuePairs.map((enumPair) => {
                return (
                  <li
                    key={enumPair.key}
                    className="w-full cursor-pointer px-3 py-1 hover:bg-gray-100 hover:shadow-sm"
                    onClick={() => {
                      update({ status: enumPair.value });
                      setValue(enumPair.value);
                      setShow((prev) => !prev);
                    }}
                  >
                    <span
                      className="grid h-[25px] w-fit  place-items-center rounded px-[10px] font-poppins text-xs text-gray-900 last:mb-0"
                      style={{ background: PROJ_STATUS_COLOR[enumPair.key] }}
                    >
                      {enumPair.value}
                    </span>
                  </li>
                );
              })}
            </ul>
          </Popover>
        }
      >
        <div
          className={classNames(
            "mt-[1px] grid h-[25px] w-fit place-items-center rounded px-[10px] text-xs text-gray-900 hover:opacity-80",
            "loading-light-shimmer",
            isLoading ? "loading-light-shimmer-on" : "",
            props.wrapperClassName,
          )}
          style={{
            background:
              PROJ_STATUS_COLOR[enumKeyValuePairs.find((i) => i.value === value)?.key || "DONE"],
          }}
          onClick={() => hasEditPermission && setShow((prev) => !prev)}
        >
          <div className={classNames("loading-light-shimmer-child min-w-[50px]")}>{value}</div>
        </div>
      </Whisper>
    </>
  );
}
//Status Picker Ends

function SubTaskNameEditor({
  project_id,
  subtask_id,
  wrapperClass,
  className,
  handleViewLoi,
}: {
  project_id: string | undefined;
  subtask_id: string | undefined;
  wrapperClass?: string;
  className?: string;
  handleViewLoi?: (letter: IProjectLetter) => void;
}) {
  const { hasEditPermission } = useProject(project_id);
  const { data, isLoading, update } = useSubtask(subtask_id);
  const { data: letter, revalidate: letterRevalidate } = useLetter(data?.letter_id);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  useEffect(() => {
    if (isLoading) return;
    setValue(data.name);
  }, [data, isLoading]);

  return (
    <div
      className={classNames(
        "loading-light-shimmer max-w-[435px]",
        isLoading ? "loading-light-shimmer-on" : "",
        wrapperClass,
      )}
    >
      <div
        className={classNames(
          "loading-light-shimmer-child min-w-[50px]  rounded hover:border",
          { "!border-0": show },
          className,
        )}
        onClick={() => hasEditPermission && setShow(true)}
        onMouseLeave={() => {
          setShow(false);
          if (data.name !== value) {
            update({ name: value });
          } else {
            setValue(data.name);
          }
        }}
      >
        {!show ? (
          <div className="flex items-center justify-between">
            <h5
              className={classNames(
                "!h-[30px] truncate border-0 pt-1 !text-gray-900",
                letter._id ? "w-full" : " w-[290px] ",
              )}
            >
              {value}
            </h5>
            <div className="inline-flex items-center gap-1">
              {letter._id && (
                <TooltipWrapper content="View letter">
                  <span
                    onClick={(e) => {
                      handleViewLoi?.(letter);
                      e.stopPropagation();
                    }}
                    className=" grid h-[24px] w-[30px] place-items-center rounded-3xl border border-gray-300 px-1 text-gray-700"
                  >
                    <img src={icon_file} className="h-4 w-4" />
                  </span>
                </TooltipWrapper>
              )}
              {/* {hasEditPermission && (
                <span
                  className={classNames("grid h-[24px] place-items-center")}
                  onClick={() => hasEditPermission && setShow((prev) => !prev)}
                >
                  <span className=" grid place-items-center rounded-3xl border border-gray-300 px-[7px] py-[4px] text-gray-700">
                    <i className="gi-pencil text-sm" />
                  </span>
                </span>
              )} */}
            </div>
          </div>
        ) : (
          <TextInput
            id="input-number-1"
            className={classNames("!h-[30px] w-full px-5 !text-sm")}
            value={value === "Untitled" ? "" : value}
            placeholder={value === "Untitled" ? "Untitled" : ""}
            autoFocus
            handleOnChange={(e: any) => {
              setValue(e.target.value);
            }}
            handleOnKeyDown={(e) => {
              if (e.key === "Enter") {
                if (data.name !== value) {
                  update({ name: value });
                }
                setShow(false);
              }
            }}
          />
        )}
      </div>
    </div>
  );
}
function SubtaskOwners({
  project_id,
  subtask_id,
  wrapperClass,
  className,
}: {
  project_id: string | undefined;
  subtask_id: string | undefined;
  wrapperClass?: string;
  className?: string;
}) {
  const { hasEditPermission } = useProject(project_id);
  const [show, setShow] = useState(false);
  const { data, isLoading } = useSubtask(subtask_id);
  return (
    <Whisper
      placement="bottom"
      controlId="control-id-hover-enterable"
      className=" shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)]"
      open={show}
      speaker={
        <Popover onMouseLeave={() => setShow(false)} className="!px-0">
          <InviteUser project_id={project_id} subtask_id={subtask_id} />
        </Popover>
      }
    >
      <div
        className={classNames(
          "loading-light-shimmer",
          isLoading ? "loading-light-shimmer-on" : "",
          wrapperClass,
        )}
        onClick={() => hasEditPermission && setShow((prev) => !prev)}
      >
        <div
          className={classNames(
            "loading-light-shimmer-child mt-[2px] flex min-w-[50px] justify-center gap-0",
            className,
          )}
        >
          {data.users && data.users.length > 0 ? <UserList users={data.users} /> : "No owner"}
        </div>
      </div>
    </Whisper>
  );
}
function InviteUser({
  project_id,
  subtask_id,
}: {
  project_id: string | undefined;
  subtask_id: string | undefined;
}) {
  const { hasEditPermission } = useProject(project_id);
  const { data, isLoading, setMemberRole } = useProject(project_id);
  const { data: orgMembers } = useOrgMembers(data.org_id);
  const { data: subtask, isLoading: subtaskLoading, update, revalidate } = useSubtask(subtask_id);
  const [accessList, setAccessList] = useState<{ user_id: string; role: number }[]>([]);
  const [suggested, setSuggested] = useState<{ user_id: string; role: number }[]>([]);
  const [showInvite, setShowInvite] = useState(false);
  const { data: projectData } = useProject(project_id);
  const [query, setQuery] = useState<string>("");
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  useEffect(() => {
    if (subtaskLoading) return;
    setAccessList(subtask.users.map((item) => ({ user_id: item, role: 1 })));
  }, [subtaskLoading, subtask]);
  return (
    <>
      {showInvite && (
        <ProjectInvite
          from_for_mixpanel="owner_in_project_task_table"
          projectId={project_id || ""}
          handleClose={() => {
            setShowInvite(false);
          }}
          showInvite={showInvite}
        />
      )}
      <div className="flex h-full min-h-[180px] w-[379px] flex-col px-4">
        <LeftInnerIconInput
          className="w-full"
          type="text"
          placeholder="Search name"
          iconClass="gi-sm gi-search text-gray-500"
          id="input-email"
          value={query}
          handleOnFocus={() => setShowSuggestion(true)}
          handleOnChange={(e) => {
            setQuery(e.target.value);
            setShowSuggestion(true);
          }}
          offAutoComplete
        />
        <OrgMemberSuggestion
          show={showSuggestion}
          setShow={setShowSuggestion}
          query={query}
          exclude={accessList.map((u) => u.user_id)}
          onSelect={(user) => {
            setQuery("");
            setShowSuggestion(false);
            const userExist = accessList.find((i) => i.user_id === user._id);
            if (!userExist && user._id)
              update({ users: [...subtask.users, user._id] })
                .then(() => {
                  revalidate();
                  // GibooToast({
                  //   type: "success",
                  //   message: `User added successfully.`,
                  // });
                })
                .catch(() => {
                  GibooToast({
                    type: "failure",
                    message: `We apologize for the inconvenience. Please try again.`,
                  });
                })
                .finally(() => {});
          }}
        />
        <h6 className=" mt-3 text-xs text-gray-500">Added:</h6>
        <div className="mb-4 inline-flex flex-wrap gap-3">
          {accessList.length > 0
            ? accessList.map((item) => {
                return (
                  <div
                    key={item.user_id}
                    className="inline-flex h-[32px] w-fit  items-center gap-2 rounded border border-gray-300 px-2"
                  >
                    <UserProfile showName userId={item.user_id} />
                    <span
                      className="mt-1 cursor-pointer"
                      onClick={() => {
                        update({ users: subtask.users.filter((i) => i !== item.user_id) })
                          .then(() => {
                            revalidate();
                            // GibooToast({
                            //   type: "success",
                            //   message: `User removed successfully.`,
                            // });
                          })
                          .catch(() => {
                            GibooToast({
                              type: "failure",
                              message: `We apologize for the inconvenience. Please try again.`,
                            });
                          })
                          .finally(() => {});
                      }}
                    >
                      <i className="gi-x  text-sm text-gray-500" />
                    </span>
                  </div>
                );
              })
            : "None"}
        </div>
        <h6 className=" text-xs text-gray-500">Suggested: None</h6>
        <div className="flex justify-end self-end">
          <div
            id="invite-people"
            className={classNames(
              hasEditPermission ? "cursor-pointer bg-white hover:shadow" : "bg-gray-100",
              "flex h-[30px] w-fit items-center gap-2 rounded border border-gray-300 px-3 py-1 font-poppins",
            )}
            onClick={() => {
              if (hasEditPermission) setShowInvite(true);
            }}
          >
            <i className="gi-funder gi-md text-gray-700" />
            <span>Invite to project</span>
          </div>
        </div>
      </div>
    </>
  );
}
function LatestSubtaskNoteMemo({
  project_id,
  subtask_id,
  className,
  wrapperClass,
  revalidate,
}: {
  project_id: string | undefined;
  subtask_id: string | undefined;
  className?: string;
  wrapperClass?: string;
  revalidate: () => void;
}) {
  const { hasEditPermission } = useProject(project_id);
  const { data, isLoading, revalidate: revalidateSubTask } = useSubtask(subtask_id);
  const { data: note, isLoading: isLoadingInteraction } = useSubtaskNote(data.latest_note_id);
  const [showAllNotes, setShowAllNotes] = useState(false);
  return (
    <>
      {showAllNotes && (
        <ProjectSubtaskNotesPopup
          project_id={project_id}
          subtask_id={subtask_id}
          onClose={() => {
            setShowAllNotes(false);
            revalidate();
            revalidateSubTask();
          }}
          show={showAllNotes}
          title={data.name}
        />
      )}
      <div
        className={classNames(
          "loading-light-shimmer",
          isLoading || isLoadingInteraction ? "loading-light-shimmer-on" : "",
          wrapperClass,
        )}
      >
        <div className={classNames("loading-light-shimmer-child min-w-[50px]", className)}>
          <div className="flex items-center justify-between">
            <div className="w-[240px] truncate  text-left">
              {data.latest_note_id ? convertToReadable(note.content) : "None"}
            </div>
            <div className="inline-flex items-center gap-1">
              {hasEditPermission && (
                <TooltipWrapper content="View all notes">
                  <span
                    className={classNames("grid h-[24px] place-items-center")}
                    onClick={() => {
                      hasEditPermission && setShowAllNotes(true);
                    }}
                  >
                    <span className=" grid place-items-center rounded-3xl border border-gray-300 px-[7px] py-[4px] text-gray-700">
                      <i className="gi-pencil text-sm" />
                    </span>
                  </span>
                </TooltipWrapper>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function SubtaskDelete({
  project_id,
  subtask_id,
  revalidate,
}: {
  project_id: string | undefined;
  subtask_id: string | undefined;
  revalidate: () => void;
}) {
  const { hasEditPermission } = useProject(project_id);
  const { deleteThis } = useSubtask(subtask_id);
  return (
    <div className="grid place-items-center">
      {hasEditPermission && (
        <span
          onClick={() => {
            deleteThis()
              .then(() => {
                revalidate();
                GibooToast({
                  type: "success",
                  message: `deleted successfully.`,
                });
              })
              .catch(() => {
                GibooToast({
                  type: "failure",
                  message: `We apologize for the inconvenience. Please try again.`,
                });
              });
          }}
          className="h-5 w-5"
        >
          <img src={IMG_TRASH} alt="trash" className="h-5 w-5" />
        </span>
      )}
    </div>
  );
}

function AddMoreSubTask({
  project_id,
  task_id,
  revalidate,
}: {
  project_id: string;
  task_id: string;
  revalidate: () => void;
}) {
  const [onboardingData] = useOnboardingData();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const createNewTask = () => {
    if ((!project_id && !task_id) || !onboardingData._id) return;
    createProjectSubtask({
      org_id: onboardingData._id,
      task_id,
      name: value || "Task name",
      status: "Not started",
    })
      .then(() => {
        // GibooToast({
        //   type: "success",
        //   message: `Subtask created successfully.`,
        // });
        revalidate();
      })
      .catch(() => {
        GibooToast({
          type: "failure",
          message: `We apologize for the inconvenience. Please try again.`,
        });
      });
  };
  return (
    <div className="flex h-[36px] w-full cursor-pointer items-center justify-start gap-2 px-5 py-1">
      {!show ? (
        <span
          className="flex !h-[36px] w-full items-center truncate !border-0 text-gray-700"
          onClick={() => setShow((prev) => !prev)}
        >
          <i className="gi gi-add mr-1 text-sm" />
          <h5>add task</h5>
        </span>
      ) : (
        <TextInput
          id="input-add-subtask"
          className={classNames("!h-[36px] max-w-[310px] !py-1 !text-sm placeholder:!text-sm")}
          value={value}
          handleOnBlur={() => {
            setShow(false);
            setValue("");
          }}
          handleOnChange={(e: any) => {
            setValue(e.target.value);
          }}
          handleOnKeyDown={(e) => {
            if (e.key === "Enter") {
              createNewTask();
              setShow(false);
            }
          }}
        />
      )}
    </div>
  );
}
