import React, { useEffect, useState } from "react";
import useSWR from "swr";
import useOnboardingData from "../hooks/useOnboarding";
import useUser from "../hooks/useUser";
import { useNavigate } from "react-router-dom";
import "./css/recommendation.scss";
import classNames from "classnames";

import {
  PastGrantSearchResult,
  SearchQuery,
  getSearchQueryFromISearchParam,
} from "../types/search";
import { getISearchParamFromOnboardingData } from "../app/onboardingSlice";
import PastGrantCardSimple from "./search/PastGrantCardSimple";
import usePastGrantOverview from "../hooks/usePastGrantOverview";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";

function RecPastGrantsFromSameFunder({
  isLoading,
  recommendationQuery,
  exclude,
  funder_uid,
  ...props
}: {
  funder_uid?: number;
  isLoading?: boolean;
  recommendationQuery?: SearchQuery;
  exclude?: string[];
}) {
  const mxEvent = useGibooMixpanel();
  const [user] = useUser();
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const [controller, setController] = useState<AbortController>(new AbortController());
  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const query: SearchQuery = recommendationQuery
    ? recommendationQuery
    : getSearchQueryFromISearchParam(
        user?._id || "",
        onboardingData,
        getISearchParamFromOnboardingData(onboardingData),
      );

  const {
    isPastGrantOverviewLoading: recommendationLoading,
    pastGrant: recommendation,
    errorPastGrant,
  } = usePastGrantOverview(funder_uid, query, [], undefined, true);
  return (
    <div
      className={classNames({
        "loading-light-shimmer-on flex flex-col gap-y-1 divide-y divide-gray-300":
          recommendationLoading,
      })}
    >
      {!recommendation || (recommendation.length === 0 && recommendationLoading)
        ? Array.from({ length: 5 }, (x, i) => i).map((_, i) => (
            <div
              key={`${i}_item`}
              className="loading-light-shimmer-child flex h-[142px] w-full flex-col gap-[16px]  rounded border border-gray-300 bg-white px-[28px] py-[16px]"
            >
              <span className="flex justify-between gap-[10px]">
                <div className="h-[28px] w-full" />
                <div className="h-[14px] w-[30px]" />
              </span>
              <div className="h-[14px] w-1/2" />
              <div className="h-[48px] w-full" />
            </div>
          ))
        : recommendation
            ?.filter((item) => !exclude || !exclude.includes(item._id))
            .map((grant: PastGrantSearchResult, index) => (
              <PastGrantCardSimple
                wrapperClass="p-5 border-t first:border-t-0 border-gray-300"
                key={"rec-past-grant-from-same-funder-" + index}
                grant={grant}
                query={query}
                onClick={() => {
                  mxEvent(
                    MIXPANEL_EVENTS_V2.detail_view.other_grants_from_the_same_funder.clicked,
                    {
                      targetType: "past_grant",
                      targetId: grant._id,
                      targetName: grant.name,
                      location: location.pathname,
                    },
                  );
                  navigate(`/grants/past/${grant._id}`);
                }}
              />
            ))}
    </div>
  );
}

export default RecPastGrantsFromSameFunder;
