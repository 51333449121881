import UserProfile from "./UserProfile";

function UserList({
  users,
  maxUsers = 2,
  size = 24,
  withName = true,
  nameClass = "",
}: {
  users: string[];
  maxUsers?: number;
  size?: 12 | 16 | 20 | 24 | 44;
  withName?: boolean;
  nameClass?: string;
}) {
  const totalUsers = users.length;
  const remainingCount = totalUsers > maxUsers ? users.slice(maxUsers, totalUsers).length : 0;
  return (
    <div className="flex">
      {remainingCount === 0 ? (
        users.map((u, index) => (
          <UserProfile
            key={u}
            userId={u}
            showName={withName}
            wrapperStyle={{ transform: `translate(-${index * 4}px, 0)` }}
            size={size}
            textSize={nameClass}
          />
        ))
      ) : withName ? (
        <span className="inline-flex items-center">
          {users.slice(0, maxUsers + 1).map((u, index) => (
            <UserProfile
              key={u}
              userId={u}
              showName={false}
              wrapperStyle={{ transform: `translate(-${index * 4}px, 0)` }}
              size={size}
              textSize={nameClass}
            />
          ))}
          <UserProfile
            key={users[0]}
            userId={users[0]}
            size={size}
            renderName={(user) => {
              const userName = `${user && user.firstname ? user.firstname : ""} ${
                user && user.lastname ? user.lastname : ""
              }`;
              return (
                <div className="inline-flex items-center whitespace-nowrap font-poppins text-xs text-black">
                  <h6 className="max-w-[60px] truncate overflow-ellipsis ">{userName}</h6>
                  <span>{`+${remainingCount}`}</span>
                </div>
              );
            }}
          />
        </span>
      ) : (
        <span className="inline-flex items-center">
          <UserProfile
            key={users[0]}
            userId={users[0]}
            showName={false}
            size={size}
            textSize={nameClass}
          />
          <span
            className={`grid h-[${size}px] w-[${size}px] -translate-x-1 place-items-center rounded-full bg-black text-xs text-white`}
          >{`+${remainingCount}`}</span>
        </span>
      )}
    </div>
  );
}

export default UserList;
