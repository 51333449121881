import { useCallback } from "react";
import useSWR from "swr";
import { Wealth } from "../../types/funder";
import { getWealthInformation } from "../../services/funders.services";
import { Order, genLastNYears } from "../../utils/formatHelper";
import { swrOptionDedupling5mins } from "../../types/swr";

interface useFunderState {
  data: Wealth[];
  isLoading: boolean;
  error?: object;
}
const emptyList: Wealth[] = [];
export default function useFunderWealth(id?: string, LAST_N_YEARS = 6): useFunderState {
  const yearParam = genLastNYears(LAST_N_YEARS, Order.ASCEND);
  const fetch = useCallback(
    async (url: string) => {
      if (!url || !id) return new Promise<Wealth[]>((resolve, reject) => reject());
      return getWealthInformation(id, yearParam);
    },
    [id],
  );
  const { data, isLoading, error } = useSWR<Wealth[]>(
    id
      ? process.env.REACT_APP_API_URL + `/api/v2/donors/${id}/wealth?last_n_year=${LAST_N_YEARS}`
      : null,
    fetch,
    swrOptionDedupling5mins,
  );
  return { data: data || emptyList, isLoading, error };
}
