import React, { ReactNode, useEffect, useState } from "react";
import ExpandableCard from "../../../../components/tailwind/ExpandableCard";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import classNames from "classnames";
import PublicPrivateButton from "../../../../components/PublicPrivateButton";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import useDiversity, { IDiversityInfo } from "../../../../hooks/useDiversity";
import { convertGenerationToAge } from "../../organizational-profile/component/AddNewEmployee";
import { Divider } from "rsuite";
import Spacer from "../../../../components/Spacer";
import DiversityChartLoader from "../../diversity/component/DiversityChartLoader";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import DiversityFilterWrapper, {
  DiversityFilter,
} from "../../diversity/component/DiversityFilterWrapper";
import useDiversityRecommendedAction from "../../../../hooks/npo/useDiversityRecommendedAction";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import FinancialDiversityRecommendation from "../../../../components/FinancialDiversityRecommendation";
import AssessmentResult from "../../../../components/AssessmentResult";
import PopupModal from "../../../../components/tailwind/PopupModal";
import { AgeInfoModal } from "../../diversity/pages/AgeInfo";
import { NpoTitle } from "./NpoDiversityEthnicity";
import useTotalOrganizationMembersData from "../../../../hooks/useTotalOrganizationMembersData";

interface Iprops {
  npo_id?: string;
  org_id?: string;
  allowEdit?: boolean;
  compact?: boolean;
  recomendation?: boolean;
  showStats?: boolean;
  showInfo?: boolean;
  hideTitle?: boolean;
  children?: ReactNode;
  showChartLoader?: boolean;
  title?: ReactNode;
}
const COLOR_RANGE = {
  "9-20": "#F0B7C8",
  "21-30": "#EB97AD",
  "31-40": "#BB83E7",
  "41-50": "#DDCFF9",
  "51-60": "#CBBAF3",
  "61-70": "#f5cdd9",
  "71+": "#F1BFEB",
  Unknown: "#bababa",
  ["do_not_wish_to_answer"]: "#ECECEC",
};
const InfoTooltip = (props: any) => {
  if (props.active && props.payload && props.payload.length) {
    const temp = props.payload[0]?.payload?.name;
    const name = temp === "do_not_wish_to_answer" ? "Do not wish to answer" : temp;
    const count = props.payload[0].payload?.count || 0;
    return (
      <div className="giboo-box space-y-1 ">
        <b className="capitalize">{name}</b>
        <div>
          {count} {count === 1 ? "Person" : "People"}
        </div>
      </div>
    );
  }
  return null;
};
function NpoDiversityGeneration({
  npo_id,
  org_id,
  allowEdit,
  compact,
  recomendation,
  showInfo = true,
  showStats = true,
  hideTitle = false,
  showChartLoader = true,
  children,
  title,
}: Iprops) {
  const { data: peoples } = useTotalOrganizationMembersData(org_id);
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const { disability, isLoading } = useDiversity(org_id);
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { data: recommendedAction, isLoading: isRecommendedActionLoading } =
    useDiversityRecommendedAction(org_id, "age");
  const chartLoading = recomendation ? loading : isLoading;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-5"
        showHeader={false}
        showActionButtons={false}
      >
        <AgeInfoModal
          handleClose={() => {
            setShowInfoModal(false);
          }}
        />
      </PopupModal>
      <div
        className={classNames(
          "flex min-h-[100px] flex-col ",
          recomendation && "rounded border border-gray-300 bg-white p-5",
        )}
      >
        {!hideTitle &&
          (title ? (
            title
          ) : (
            <NpoTitle
              title="Age"
              recomendation={recomendation}
              setShowInfoModal={setShowInfoModal}
            />
          ))}
        {showChartLoader && chartLoading ? (
          <DiversityChartLoader title="age" />
        ) : (
          <>
            {/* chart */}
            <div className="my-5">
              <GenerationChart org_id={org_id} />
            </div>
            {/* info */}
            {showInfo &&
              (loading || isRecommendedActionLoading ? (
                <MemoizedLoadingRecommendedAction
                  loading={loading || isRecommendedActionLoading}
                  short
                />
              ) : (
                <AssessmentResult content={recommendedAction?.summary || ""} />
              ))}
            {recomendation ? <Divider className="my-[40px]" /> : <Spacer height="20px" />}
            {/* stats */}
            {showStats &&
              (loading || isRecommendedActionLoading ? (
                <MemoizedLoadingRecommendedAction
                  loading={loading || isRecommendedActionLoading}
                  hidePercentage
                />
              ) : (
                <div className="bg-gray-50 px-6 py-5">
                  <h6 className="mb-5 font-poppins text-base font-semibold text-black">
                    Recommendations
                  </h6>
                  <div className="flex flex-col gap-10">
                    {peoples.filter(
                      (v) => v.employ_type === "board" && v.generation && v.generation.length > 0,
                    ).length > 0 && (
                      <FinancialDiversityRecommendation
                        title="Board members"
                        recommendation={recommendedAction?.board_members || ""}
                      />
                    )}
                    {peoples.filter(
                      (v) =>
                        v.employ_type === "employee" && v.generation && v.generation.length > 0,
                    ).length > 0 && (
                      <FinancialDiversityRecommendation
                        title="Employees"
                        recommendation={recommendedAction?.employees || ""}
                      />
                    )}
                    {peoples.filter(
                      (v) =>
                        v.employ_type === "volunteer" && v.generation && v.generation.length > 0,
                    ).length > 0 && (
                      <FinancialDiversityRecommendation
                        title="Volunteers"
                        recommendation={recommendedAction?.volunteers || ""}
                      />
                    )}
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      {!chartLoading && children && children}
    </>
  );
}

NpoDiversityGeneration.propTypes = {};

export default NpoDiversityGeneration;
const generationOrder: any = {
  "Silent Generation": 1,
  "Baby Boomers": 2,
  "Generation X": 3,
  Millennials: 4,
  "Generation Z": 5,
  "Generation Alpha": 6,
};
export function GenerationChart({
  org_id,
  allowEdit,
  wrapperClass,
  compact,
}: {
  org_id?: string;
  allowEdit?: boolean;
  wrapperClass?: string;
  compact?: boolean;
}) {
  const [selectedTab, setSelectedTab] = useState<DiversityFilter>("board");
  const { generation: all } = useDiversity(org_id, "all");
  const { generation: board } = useDiversity(org_id, "board");
  const { generation: employee } = useDiversity(org_id, "employee");
  const { generation: volunteer } = useDiversity(org_id, "volunteer");
  const [data, setData] = useState<any[]>([]);
  const [excludeTabs, setExludeTabs] = useState<DiversityFilter[]>([]);
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  useEffect(() => {
    const tabs = [];
    const hasVolunteer = !Object.values(formatChartData(volunteer)).every(
      (item) => item.count === 0,
    );
    const hasBoard = !Object.values(formatChartData(board)).every((item) => item.count === 0);
    const hasEmployee = !Object.values(employee).every((item) => item.count === 0);
    if (!hasVolunteer) tabs.push("volunteer");
    if (!hasBoard) tabs.push("board");
    if (!hasBoard) setSelectedTab("all");
    if (!hasEmployee) tabs.push("employee");
    setExludeTabs(tabs as any);
  }, [board, employee, volunteer]);
  useEffect(() => {
    switch (selectedTab) {
      case "all":
        setData(formatChartData(all));
        break;
      case "board":
        setData(formatChartData(board));
        break;
      case "employee":
        setData(formatChartData(employee));
        break;
      case "volunteer":
        setData(formatChartData(volunteer));
        break;
      default:
        setData(formatChartData(all));
    }
  }, [selectedTab, all, board, employee, volunteer]);
  useEffect(() => {
    const indexWithvalue = data.reduce((acc: number[], item: any, index: number) => {
      if (item.count > 0) {
        acc.push(index);
      }
      return acc;
    }, []);
    setActiveIndex(indexWithvalue[indexWithvalue.length - 1] || 0);
  }, [data]);

  const formatChartData = (data: IDiversityInfo) => {
    const dataList = Object.entries(data)
      .map(([name, item]) => ({
        name,
        value: item?.percentage || 0,
        count: item?.count || 0,
      }))
      .sort((a, b) => a.value - b.value)
      .filter((i) => i.value);
    // checking data has same value
    const hasSameValue = dataList.every((i) => i.value === dataList[0].value);
    return hasSameValue ? dataList.reverse() : dataList;
  };

  const hasNoData =
    data
      .map((i) => i.value)
      .reduce((acc, value) => {
        return acc + value;
      }, 0) === 0;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    index,
    name,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (value && index === activeIndex) {
      return (
        <text
          x={x}
          y={y}
          fill={name === "Inclusive" ? "#ffff" : "#5C38A7"}
          opacity={activeIndex == index ? 1 : 0.5}
          dominantBaseline="central"
          textAnchor="middle"
          className="text-sm font-semibold"
        >
          {`${value}%`}
        </text>
      );
    }
  };
  const renderActiveShape = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text> */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          fontSize={14}
          y={ey}
          dy={-5}
          className="font-poppins"
          textAnchor={textAnchor}
          fill="#5F5F5F"
        >
          {payload?.name === "do_not_wish_to_answer" ? "Do not wish to answer" : payload?.name}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={16}
          className="font-poppins font-semibold"
          textAnchor={textAnchor}
          fill="#000"
        >
          {`${payload?.count} ${payload.count === 1 ? "Person" : "People"}`}
        </text>
      </g>
    );
  };
  const renderChart = () => {
    return (
      <PieChart width={700} height={360}>
        {!hasNoData && (
          <Tooltip content={<InfoTooltip />} cursor={false} wrapperStyle={{ outline: "none" }} />
        )}
        {hasNoData ? (
          <Pie
            data={[{ name: "No Data", value: 1 }]}
            fill="#eeeeee"
            stroke=""
            cx="50%"
            cy="50%"
            startAngle={140}
            endAngle={-220}
            innerRadius={60}
            isAnimationActive={false}
            labelLine={false}
            label={false}
            outerRadius={110}
            paddingAngle={0}
            dataKey="value"
          />
        ) : (
          <Pie
            data={data}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            stroke=""
            cx="50%"
            cy="50%"
            startAngle={90}
            endAngle={450}
            innerRadius={60}
            isAnimationActive={false}
            labelLine={false}
            minAngle={0}
            label={renderCustomizedLabel}
            outerRadius={110}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
            onMouseEnter={(_, index) => {
              setActiveIndex(index);
            }}
          >
            {data.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={COLOR_RANGE[entry.name as keyof typeof COLOR_RANGE]}
                  // fillOpacity={activeIndex === index ? 1 : 0.8}
                />
              );
            })}
          </Pie>
        )}
      </PieChart>
    );
  };
  return (
    <div className={classNames("flex flex-col items-start justify-center", wrapperClass)}>
      <DiversityFilterWrapper
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        excludeTabs={excludeTabs}
      >
        {renderChart()}
      </DiversityFilterWrapper>
    </div>
  );
}
