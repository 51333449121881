import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import useUser from "../useUser";
interface HasPermissionToUpdateGrantState {
  data: boolean | undefined;
  error: any;
  isLoading: boolean;
}
function useHasPermissionToUpdateGrant(
  use = true,
  grant_id?: string,
): HasPermissionToUpdateGrantState {
  const [user] = useUser();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<boolean>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data);
  };
  const { data, isLoading, error } = useSWR<boolean>(
    use && user
      ? process.env.REACT_APP_API_URL +
          `/api/v2/grants/${grant_id ? `${grant_id}/` : ""}permission?user_id=${user._id}`
      : "",
    fetch,
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 300,
    },
  );

  return {
    data: data,
    isLoading,
    error,
  };
}
export default useHasPermissionToUpdateGrant;
