import Spacer from "./Spacer";
//images
import { useNavigate } from "react-router-dom";
import useTask from "../hooks/project/useTask";
import useFunderOrGrantName from "../hooks/project/useFunderOrGrantName";
import classNames from "classnames";
import icon_loi from "../assets/project-mgt/loi.svg";
import icon_follow_up from "../assets/project-mgt/follow-up.svg";
import icon_thank_you from "../assets/project-mgt/thankyou.svg";
import icon_progress from "../assets/project-mgt/progress.svg";
import icon_funding from "../assets/project-mgt/fundingrenewal.svg";
import icon_end_of_year from "../assets/project-mgt/endofyear.svg";
import icon_open from "../assets/images/open.svg";
import useLetter, { createProjectLetter, updateProjectLetter } from "../hooks/project/useLetter";
import useOnboardingData from "../hooks/useOnboarding";
import useSubtasks from "../hooks/project/useSubtasks";
import useUser from "../hooks/useUser";
import ProjectTaskManage from "./ProjectTaskManage";
import { toDateString } from "../utils/formatHelper";
import { SetStateAction, useCallback, useState } from "react";
import LetterPreview from "../pages/npo/grant/pages/component/LetterPreview";
import Button from "./tailwind/Button";
import PopupModal from "./tailwind/PopupModal";
import useProject from "../hooks/project/useProject";
import PlainButton from "./PlainButton";
import UserProfile from "./UserProfile";
import UserList from "./UserList";
import LetterEditor from "../pages/npo/grant/pages/component/LetterEditor";
import LetterOfInquiry from "../pages/npo/grant/pages/LetterOfInquiry";
import GenerateLetterOfInquiry from "./GenerateLetterOfInquiry";
import { IProjectLetterCreateRequest, IProjectLetterUpdateRequest } from "../types/project";
import GibooToast from "./GibooToast";
import GeneratedLetterPreview from "./GeneratedLetterPreview";
interface IProjectTaskItem {
  projectId: string;
  task_id: string;
  detailedView?: boolean;
  autoWidth?: boolean;
  autoHeight?: boolean;
}
export default function ProjectTaskItem({
  projectId,
  task_id,
  detailedView = false,
  autoHeight = true,
  autoWidth = true,
  ...props
}: IProjectTaskItem) {
  const navigate = useNavigate();
  const { data: task, isLoading } = useTask(task_id);
  const [onboardingData] = useOnboardingData();
  const { data: name, isLoading: isNameLoading } = useFunderOrGrantName(
    task.target_id,
    task.target_type,
  );
  const { data: projectData } = useProject(projectId);
  const { data: subTasks, revalidate } = useSubtasks(task_id);
  const latestLoiId = subTasks.filter((i) => i.letter_id)[0];
  const [showLoiPreview, setShowLoiPreview] = useState(false);
  const renderHeader = useCallback(() => {
    return (
      <div className="inline-flex items-center gap-5">
        <h5 className="text-black">
          {task.target_type === 0 ? "Funder" : "Grant"} added:{" "}
          <span className="font-semibold">{toDateString(task.created_at, true)}</span>
        </h5>
      </div>
    );
  }, [task]);
  return (
    <>
      {projectId && task?._id && latestLoiId?.letter_id && (
        <GeneratedLetterPreview
          letter_id={latestLoiId.letter_id}
          project_id={projectId}
          task_id={task?._id}
          showLoiPreview={showLoiPreview}
          setShowLoiPreview={(f) => setShowLoiPreview(f)}
        />
      )}
      <div
        className={classNames("rounded-ss border border-gray-200 bg-white px-7 py-5")}
        style={{ minHeight: !detailedView ? "100%" : "calc(100vh - 160px)" }}
      >
        <div className="mb-4 flex flex-col gap-4">
          <div className="flex justify-between">
            <div className="inline-flex items-center gap-4">
              <div
                className={classNames("font-poppins text-base font-semibold text-black", {
                  "loading-light-shimmer": !isNameLoading,
                  "loading-light-shimmer-on -mt-[2px]": isNameLoading,
                })}
              >
                {name}
              </div>
              <div
                className={classNames("inline-flex cursor-pointer items-center gap-2", {
                  hidden: !detailedView,
                })}
                onClick={() =>
                  navigate(
                    task.target_type === 0
                      ? `/donors/${task.target_id}`
                      : `/grants/${task.target_id}`,
                    {
                      state: {
                        // query: projectData
                        //   ? getQueryFromProjectData(
                        //       onboardingData,
                        //       projectData,
                        //       task.target_type === 0 ? "funder" : "grant",
                        //     )
                        //   : getQueryFromOnboardingData(
                        //       onboardingData,
                        //       task.target_type === 0 ? "funder" : "grant",
                        //     ),
                        projectData: projectData,
                      },
                    },
                  )
                }
              >
                <span>
                  <img src={icon_open} className="h-5 w-5" />
                </span>
                <h5 className="font-poppins text-sm text-gray-700">
                  {task.target_type === 0 ? "view funder detail" : "view grant detail"}
                </h5>
              </div>
            </div>

            {!detailedView && (
              <div className="flex items-center gap-3">
                <UserList users={projectData.members.map((u) => u.user_id)} maxUsers={4} />
                <h5
                  className="w-[114px] cursor-pointer font-poppins text-xs text-purple-500"
                  onClick={() => {
                    task_id && projectId && navigate(`/project/${projectId}/task/${task_id}`);
                  }}
                >
                  View full task list
                </h5>
              </div>
            )}
          </div>
          {detailedView && renderHeader()}
        </div>
        <Spacer height="16px" />
        {projectId && task_id && (
          <ProjectTaskManage
            project_id={projectId}
            task_id={task_id}
            autoWidth={autoWidth}
            autoHeight={autoHeight}
          />
        )}
      </div>
    </>
  );
}
