import { useCallback, useEffect, useState } from "react";

function useFakeProgress({
  done,
  estimatedSeconds = 3,
  max = 0.95,
}: {
  done?: boolean;
  estimatedSeconds?: number;
  max?: number;
}) {
  const _estimatedSeconds = estimatedSeconds < 0 ? 1 : estimatedSeconds;
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    if (!estimatedSeconds) return;
    if (progress < 1 && done) {
      setProgress(1);
      return;
    } else if (done) {
      return;
    }
    setProgress((prev) => {
      return Math.min(1 / _estimatedSeconds, max);
    });
  }, []);
  useEffect(() => {
    if (!estimatedSeconds) return;
    if (progress < 1 && done) {
      setProgress(1);
      return;
    } else if (done) {
      return;
    }
    if (progress > 0)
      setTimeout(() => {
        setProgress((prev) => {
          const v = Math.min(
            prev < 0.6
              ? prev + 1 / _estimatedSeconds
              : Math.min(
                  prev + (((1 - prev) / _estimatedSeconds) * 2) / prev,
                  prev + 1 / _estimatedSeconds,
                ),
            max,
          );
          return v < prev ? prev : v;
        });
      }, 800);
  }, [done, progress]);
  const restart = useCallback(() => {
    setProgress((prev) => {
      return Math.min(1 / _estimatedSeconds, max);
    });
  }, [setProgress]);
  return { progress, restart };
}
export default useFakeProgress;
