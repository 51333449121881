import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import config from "../api";
import useSWR from "swr";
import useFetch from "../hooks/useFetch";
import useOnboardingData from "../hooks/useOnboarding";
import useUser from "../hooks/useUser";
import { grantHistory, ReprServiceLocations } from "../utils/grant";
import PlainButton from "./PlainButton";
import { useNavigate } from "react-router-dom";
import "./css/recommendation.scss";
import classNames from "classnames";
import Tag from "./Tag";

import { ILocation, reprLocation } from "../types/location";
import PrimaryButton from "./PrimaryButton";
import Button from "./tailwind/Button";
import SecondaryButton from "./SecondaryButton";
import {
  FunderSearchResult,
  SearchQuery,
  getSearchResultKey,
  getURLSearchParamsFromSearchQuery,
} from "../types/search";
import GibooToast from "./GibooToast";
import useRelevant from "../hooks/useRelevant";
import { useRecoilValue } from "recoil";
import { searchRelevantMapCachedSelector } from "../app/recoilStore";
import { Relevant } from "../app/searchResultCacheSlice";
import RelevantText from "./RelevantText";
import PhilanthropicInformationFlexWrap from "./PhilanthropicInformationFlexWrap";
import { IProject } from "../types/project";
import { swrOptionDedupling5mins } from "../types/swr";
import { getSearchQueryFromOnboardingData } from "../app/onboardingSlice";
import { matchTaxonomyBySearchQuery } from "../types/taxonomy";
import useCancellableSWR from "../hooks/useCancellableSWR";
import { queryMaskForFunderSearch } from "../hooks/useGibooStructureSearch";
import {
  getFunderHqLocation,
  renderRow,
  reprFunderPreferredStages,
  reprFunderTotalGrantCount,
} from "../utils/funder";
import { toUsdLong } from "../utils/formatHelper";
import TagFunderType from "./TagFunderType";
import TagPreviousFunder from "./TagPreviousFunder";
import TagInvitationOnly from "./TagInvitationOnly";
import useIsPreviousFunder from "../hooks/search/useIsPreviousFunder";
import FunderCardSimple from "./search/FunderCardSimple";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";

function RecDonorItem({
  tagWrapperClass,
  ...props
}: {
  queryURL: string;
  donor: FunderSearchResult;
  query: SearchQuery;
  parentPage?: string;
  tagWrapperClass?: string;
}) {
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const { data: hasGranted } = useIsPreviousFunder(props.donor._id);
  const location = props?.donor.location;

  // const { updatePhilanthroy } = useLike("funder", props.donor.ein, props.donor);

  // const description = getDescription(props.donor);
  const renderPhilanthropyMatched = () => {
    const query = props?.query ? props.query : onboardingData;
    const focusArea = props?.donor?.focus_area
      ?.filter((i) => i.matched)
      ?.map((item) => ({
        ...item,
        onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          navigate(
            `/search?${getURLSearchParamsFromSearchQuery(
              onboardingData,
              {
                type: ["past_grant"],
                donor_id: props?.donor?._id,
                ...{
                  focus_area_subs: query.focus_area,
                  beneficiary_subs: query.beneficiary,
                  program_subs: query.program,
                  service_loc_subs: query.service_loc?.map((i) => i),
                  service_loc: [],
                  beneficiary: [],
                  program: [],
                },
                focus_area: [item.label],
              },
              5,
            ).toString()}`,
          );
        },
      }));
    const beneficiary = props?.donor?.beneficiary
      ?.filter((i) => i.matched)
      ?.map((item) => ({
        ...item,
        onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          navigate(
            `/search?${getURLSearchParamsFromSearchQuery(
              onboardingData,
              {
                type: ["past_grant"],
                donor_id: props?.donor?._id,
                ...{
                  focus_area_subs: query.focus_area,
                  beneficiary_subs: query.beneficiary,
                  program_subs: query.program,
                  service_loc_subs: query.service_loc?.map((i) => i),
                  service_loc: [],
                  focus_area: [],
                  program: [],
                },
                beneficiary: [item.label],
              },
              5,
            ).toString()}`,
          );
        },
      }));
    const program = props?.donor?.program
      ?.filter((i) => i.matched)
      ?.map((item) => ({
        ...item,
        onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          navigate(
            `/search?${getURLSearchParamsFromSearchQuery(
              onboardingData,
              {
                type: ["past_grant"],
                donor_id: props?.donor?._id,
                ...{
                  focus_area_subs: query.focus_area,
                  beneficiary_subs: query.beneficiary,
                  program_subs: query.program,
                  service_loc_subs: query.service_loc?.map((i) => i),
                  service_loc: [],
                  beneficiary: [],
                  focus_area: [],
                },
                program: [item.label],
              },
              5,
            ).toString()}`,
          );
        },
      }));
    const service_loc = props?.donor?.service_loc
      ?.filter((i) => i.matched)
      ?.map((item) => ({
        ...item,
        onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          navigate(
            `/search?${getURLSearchParamsFromSearchQuery(
              onboardingData,
              {
                type: ["past_grant"],
                donor_id: props?.donor?._id,
                ...{
                  focus_area_subs: query.focus_area,
                  beneficiary_subs: query.beneficiary,
                  program_subs: query.program,
                  service_loc_subs: query.service_loc?.map((i) => i),
                  beneficiary: [],
                  focus_area: [],
                  program: [],
                },
                service_loc: [item],
              },
              5,
            ).toString()}`,
          );
        },
      }));
    return (
      <PhilanthropicInformationFlexWrap
        id="funder"
        focusArea={focusArea}
        beneficiary={beneficiary}
        program={program}
        location={service_loc}
        tagSize="sm"
      />
    );
  };
  const relevant = useRecoilValue(
    searchRelevantMapCachedSelector({
      url: props.queryURL,
      key: getSearchResultKey(props.donor),
    }),
  );
  return (
    <>
      <div
        className="h-fit w-full border-b border-gray-300 px-6 py-5 last:border-b-0 hover:bg-gray-100"
        onClick={() => {
          navigate(`/donors/${props.donor._id}`, {
            state: {
              query: props.query,
              searchResult: props.donor,
            },
          });
        }}
      >
        <div className="btn-rec flex flex-col gap-2" id={`btn-${props.donor._id}`}>
          <div className="flex flex-wrap items-center gap-2">
            {/* <TagOrganization type="funder" /> */}
            <TagFunderType funder_type={props.donor.funder_type} />
            {hasGranted ? <TagPreviousFunder /> : null}
          </div>
          <div className="box-title flex justify-between pb-1">
            <div>
              <p className="text-base font-semibold">{props.donor.name}</p>
            </div>
          </div>
          {props.donor.does_not_accept_unsolicited !== true && (
            <TagInvitationOnly invitationOnly={false} />
          )}
          <div className="flex flex-col gap-1">
            {props.donor && renderRow("Address", getFunderHqLocation(props.donor))}
            {props.donor?.service_loc &&
              props.donor.service_loc.length > 0 &&
              renderRow(
                "Service areas",
                <ReprServiceLocations
                  service_loc={props.donor.service_loc}
                  detail={false}
                  max={1}
                />,
              )}
            {props.donor && reprFunderPreferredStages(props.donor, false)
              ? renderRow("Funded stage", reprFunderPreferredStages(props.donor, false, 2))
              : null}
            {props.donor?.grant_amount_max
              ? renderRow(
                  "Funding size",
                  `${toUsdLong(props.donor.grant_amount_min || 0)} - ${toUsdLong(
                    props.donor.grant_amount_max || 0,
                  )}`,
                )
              : null}
            {props.donor && reprFunderTotalGrantCount(props.donor)
              ? renderRow(
                  "Number of grants made",
                  reprFunderTotalGrantCount(props.donor).toLocaleString(),
                )
              : null}
          </div>
          <div
            className={classNames(
              "my-1 flex flex-wrap gap-x-[4px] gap-y-[8px] font-poppins text-sm ",
              tagWrapperClass,
            )}
          >
            {renderPhilanthropyMatched()}
          </div>
        </div>
      </div>
    </>
  );
}

function RecDonors({
  parentPage = "home",
  recData,
  isLoading,
  hasInitialData,
  recommendationQuery,
  exclude,
  ...props
}: {
  parentPage?: string;
  recData?: FunderSearchResult[];
  isLoading?: boolean;
  hasInitialData?: boolean;
  recommendationQuery?: SearchQuery;
  exclude?: string[];
}) {
  const mxEvent = useGibooMixpanel();
  const [user] = useUser();
  const [onboardingData, ready] = useOnboardingData();
  // console.log({ onboardingData, ready });
  const navigate = useNavigate();
  // const [recommendation, setRecommendation] = useState<ISearchResult[]>([]);
  // const [recommendationLoading, setRecommendationLoading] = useState<boolean>(false);
  const [controller, setController] = useState<AbortController>(new AbortController());
  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const query: SearchQuery = recommendationQuery
    ? recommendationQuery
    : getSearchQueryFromOnboardingData(onboardingData);
  // const queryURL = `funder_rec@${user?._id}`; //
  const queryURL = "funder_rec@" + JSON.stringify(query);

  const fetchFunderSearch = useCallback(async (url: string, controller: AbortController) => {
    try {
      const req = JSON.parse(url.slice(10));
      return axios
        .post(
          process.env.REACT_APP_API_URL + `/api/v2/search/donors`,
          {
            ...req,
            store_search_history: false,
            hidden_uids: [],
            search_by_name: false,
            pagination_limit: 20,
            pagination_skip: 0,
          },
          { ...config, ...{ signal: controller.signal } },
        )
        .then((res) => ({
          ...res.data,
          items: res.data.items
            .map((d: any) => ({
              ...d,
              search_type: "funder",
              search_by_name: false,
              service_specific_loc: true,
              pagination_limit: 20,
              pagination_skip: 0,
              recommendation: true,
            }))
            .map((d: any) => ({ ...d, ...matchTaxonomyBySearchQuery(d, req, true, false) })),
        }))
        .then((res) => res.items);
    } catch {
      return new Promise<any>((resolve, reject) => reject());
    }
  }, []);
  const {
    data,
    isLoading: isLoadingFunder,
    error,
  } = useCancellableSWR<any[]>(
    !ready || !query
      ? null
      : "gsr_funder" +
          JSON.stringify({
            ...query,
            ...queryMaskForFunderSearch,
          }),
    fetchFunderSearch,
    swrOptionDedupling5mins,
  );
  const recommendation = hasInitialData ? recData : data;
  const recommendationLoading = isLoading || isLoadingFunder;

  // const { isLoading: isLoadingRelevant } = useRelevant(queryURL, recommendation);
  return (
    <div
      className={classNames({
        "loading-light-shimmer-on": recommendationLoading,
      })}
    >
      {!recommendation || (recommendation.length === 0 && recommendationLoading)
        ? Array.from({ length: 5 }, (x, i) => i).map((_, i) => (
            <div
              key={`${i}_item`}
              className="loading-light-shimmer-child flex h-[142px] w-full flex-col gap-[16px]  rounded border border-gray-300 bg-white px-[28px] py-[16px]"
            >
              <span className="flex justify-between gap-[10px]">
                <div className="h-[28px] w-full" />
                <div className="h-[14px] w-[30px]" />
              </span>
              <div className="h-[14px] w-1/2" />
              <div className="h-[48px] w-full" />
            </div>
          ))
        : recommendation
            ?.filter((item) => !exclude || !exclude.includes(item._id))
            .map((donor, index) => (
              <FunderCardSimple
                wrapperClass="p-5 border-t first:border-t-0 border-gray-300"
                key={"rec-funder" + index}
                funder={donor}
                query={query}
                onClick={() => {
                  mxEvent(MIXPANEL_EVENTS_V2.detail_view.similar_org.clicked, {
                    targetType: "funder",
                    targetId: donor._id,
                    targetName: donor.name,
                    location: location.pathname,
                  });
                  navigate(`/donors/${donor._id}`);
                }}
              />
            ))}
    </div>
  );
}

export default RecDonors;
