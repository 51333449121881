import React, { useEffect, useRef, useState } from "react";
import TextInput from "../tailwind/TextInput";
import { Placeholder } from "rsuite";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import classNames from "classnames";
import PlainTextInput from "../tailwind/PlainTextInput";

export default function ClickToEditInput({
  shouldFocus = false,
  isLoading,
  initalValue,
  noValueLabel = "No Name",
  wrapperClass,
  textInputWrapper,
  textInputClass,
  textClass,
  placeholder = "Enter value",
  withOutBorder = true,
  height = "h-[30px]",
  withoutEditIcon = false,
  preventEmpty = true,
  onChange,
  onInputChange,
  disabled,
  ...props
}: {
  shouldFocus?: boolean;
  isLoading?: boolean;
  initalValue: string;
  height?: string;
  noValueLabel?: string;
  placeholder?: string;
  textInputWrapper?: string;
  textInputClass?: string;
  wrapperClass?: string;
  textClass?: string;
  withOutBorder?: boolean;
  withoutEditIcon?: boolean;
  onChange?: (value: string) => void;
  onInputChange?: (value: string) => void;
  preventEmpty?: boolean;
  disabled?: boolean;
}) {
  const [text, setText] = useState<string>(initalValue);
  useEffect(() => {
    setText(initalValue);
  }, [initalValue]);
  const inputRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(shouldFocus);
  useOnClickOutside(inputRef, () => {
    if (open) {
      setOpen(false);
      if (text.length === 0) {
        preventEmpty ? setText(initalValue) : setText("");
        return;
      }
      if (text && text !== initalValue) {
        preventEmpty ? onChange?.(text) : onChange?.("");
      }
    }
  });
  return (
    <div className={classNames("grow", wrapperClass, height)}>
      {!open ? (
        isLoading ? (
          <Placeholder.Graph height={30} width={400} className="rounded" active />
        ) : (
          <div
            className={classNames(
              "flex cursor-text items-center gap-3 rounded border-gray-300 py-[2px]",
              !withOutBorder && !disabled && "hover:border",
              height,
            )}
            onClick={() => {
              if (disabled) return;
              setOpen(true);
            }}
          >
            <p
              className={classNames(
                "w-full truncate font-poppins text-xl font-semibold text-gray-900",
                textClass,
              )}
              title={text || ""}
            >
              {!isLoading && (text || noValueLabel)}
            </p>
            {!withoutEditIcon && (
              <span
                className="cursor-pointer"
                onClick={() => {
                  setOpen((prev) => !prev);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.462 3.67858C16.2468 3.46345 15.9915 3.29279 15.7104 3.17636C15.4293 3.05993 15.1281 3 14.8238 3C14.5196 3 14.2184 3.05993 13.9373 3.17636C13.6562 3.29279 13.4008 3.46345 13.1857 3.67858L4.47405 12.3903C4.21483 12.6495 4.0263 12.9712 3.92791 13.3246L3.01651 16.5797C2.99545 16.6551 2.99483 16.7348 3.01471 16.8106C3.0346 16.8864 3.07427 16.9555 3.12966 17.0109C3.18505 17.0663 3.25418 17.1059 3.32995 17.1258C3.40572 17.1457 3.48542 17.1451 3.56088 17.124L6.81531 16.2126C7.16876 16.1137 7.49076 15.9254 7.75028 15.6659L16.462 6.95423C16.8963 6.51981 17.1402 5.93068 17.1402 5.3164C17.1402 4.70213 16.8963 4.113 16.462 3.67858ZM13.8108 4.30308C14.0796 4.0344 14.444 3.8835 14.824 3.88355C15.2041 3.88361 15.5685 4.03462 15.8372 4.30337C16.1058 4.57212 16.2568 4.9366 16.2567 5.31661C16.2566 5.69663 16.1056 6.06106 15.8369 6.32973L14.9296 7.23643L12.9035 5.20977L13.8108 4.30308ZM12.279 5.83485L14.3057 7.86151L7.1252 15.0414C6.97311 15.1935 6.78442 15.3039 6.57729 15.3619L4.07873 16.0612L4.77805 13.5632C4.83616 13.3562 4.94652 13.1675 5.09854 13.0153L12.279 5.83485Z"
                    fill="#5F5F5F"
                  />
                </svg>
              </span>
            )}
          </div>
        )
      ) : (
        <div
          ref={inputRef}
          className={classNames(
            "flex w-full place-items-center rounded border-gray-300",
            height,
            !withOutBorder && "border",
          )}
        >
          <PlainTextInput
            id="click-to-edit"
            value={text || ""}
            className={classNames(
              "!mx-0 w-full min-w-full !px-0 text-xl font-semibold text-gray-700",
              textInputClass,
            )}
            placeholder={placeholder}
            handleOnChange={(e) => {
              setText(e.target.value);
              onInputChange?.(e.target.value);
            }}
            autoFocus
            handleOnKeyDown={(e) => {
              if (e.key === "Enter") {
                setOpen(false);
                if (text.length === 0) {
                  setText(initalValue);
                  return;
                }
                text.length > 0 && onChange?.(text);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
