import { DebounceSettings, debounce } from "lodash";
import { useState, useEffect, useCallback } from "react";

interface IObject {
  [key: string]: any;
}
export default function useDebounceObject(
  value: IObject,
  keys: string[],
  delay: number,
  options?: DebounceSettings,
) {
  const [delayedValue, setDelayedValue] = useState<IObject>(value);
  // 1. library
  const diffFunction = useCallback(
    (a: IObject, b: IObject) => {
      return keys.filter((k) => a[k] !== b[k]).length > 0;
    },
    [keys],
  );
  const debouncedFunc = useCallback(
    debounce(
      (value) => setDelayedValue((prev) => (diffFunction(prev, value) ? value : prev)),
      delay,
      options,
    ),
    [setDelayedValue],
  );
  useEffect(() => {
    debouncedFunc(value);
  }, [value, delay, options]);

  return delayedValue;
}
