import { FunderMemberContact } from "../../../services/funders.services";
import PeopleCard from "../../../components/PeopleCard";
import classNames from "classnames";
import { scrollBarClass } from "../../../utils/classes";
import { useState } from "react";
import NO_CONTACT from "../../img/no-contact.svg";
import { ClickToScrollWrapper } from "../../../components/ClickToScrollWrapper";
import Spacer from "../../../components/Spacer";
import ProfileCard2 from "../organizational-profile/component/ProfileCard2";
import ProfileCard2Old from "../organizational-profile/component/ProfileCard2Old";
import PeopleCardOld from "../../../components/PeopleCardOld";

interface GrantContactProps {
  target_name?: string;
  target_id?: string;
  target_type?: 0 | 1 | 2;
  wrapperClass?: string;
  address?: string;
  phone?: string;
  information?: string;
  grant?: string;
  support?: string;
  communication?: string;
  header?: string;
  email?: string;
  name?: string;
  title?: string;
  text?: string;
  contacts?: FunderMemberContact[];
  apply_via_website?: string;
  compact?: boolean;
  forSearch?: boolean;
  withoutLogin?: boolean;
  allowEdit?: boolean;
  onEdit?: () => void;
}
const notPersonTitle = {
  info: "Information",
  grant: "Grant",
  cs: "Support",
  communication: "Communication",
  media: "Media",
};
const sortPeople = (a: FunderMemberContact, b: FunderMemberContact): number => {
  if (a.email && !b.email) return -1;
  else if (!a.email && b.email) return 1;
  if (a.linkedin && !b.linkedin) return -1;
  else if (!a.linkedin && b.linkedin) return 1;
  if (a.title && !b.title) return -1;
  else if (!a.title && b.title) return 1;
  const aName = `${a.firstname || ""} ${a.lastname || ""}`;
  const bName = `${b.firstname || ""} ${b.lastname || ""}`;
  return aName.localeCompare(bName);
};
function GrantContactOld({
  wrapperClass,
  header = "Contact information",
  address,
  phone,
  email,
  name,
  title,
  text,
  contacts,
  information,
  support,
  communication,
  grant,
  apply_via_website,
  compact = false,
}: GrantContactProps) {
  const people = (contacts || []).sort((a, b) => sortPeople(a, b));
  const not_people = [
    ...(contacts?.filter(
      (m) =>
        m.category &&
        m.email &&
        ["media", "grant", "info", "cs", "communication"].includes(m.category),
    ) || []),
    ...(email
      ? [
          {
            category: "grant",
            email,
            firstname: name,
            lastname: "",
            title: title || text,
          },
        ]
      : []),
  ];
  const renderNoInfo = () => {
    return (
      <div className="flex flex-col items-center justify-between gap-3 py-20">
        <img src={NO_CONTACT} />
        <p>No information available</p>
      </div>
    );
  };
  return (
    <>
      {contacts || email || address || phone ? (
        <div className="flex flex-col gap-6">
          {(not_people.length > 0 || address || phone) && (
            <>
              <div className="flex flex-col">
                <div className="border-b border-gray-300">
                  <h5 className="text-base font-semibold underline underline-offset-[6px]">
                    Communication
                  </h5>
                </div>
                <Spacer height="20px" />
                <div className="flex flex-col gap-3">
                  {address && (
                    <div key="address" className="flex flex-col gap-2">
                      <h5 className="text-sm font-semibold text-gray-900">Mailing address</h5>
                      <div
                        className={classNames(
                          "flex items-center justify-between rounded border border-gray-300 px-4 py-3",
                        )}
                      >
                        <h5 className="font-poppins text-sm font-semibold ">{address}</h5>
                      </div>
                    </div>
                  )}
                  {phone && (
                    <div key="phone" className="flex flex-col gap-2">
                      <h5 className="text-sm font-semibold text-gray-900">Phone number</h5>
                      <div
                        className={classNames(
                          "flex items-center justify-between rounded border border-gray-300 px-4 py-3",
                        )}
                      >
                        <h5 className="font-poppins text-sm font-semibold ">{phone}</h5>
                      </div>
                    </div>
                  )}
                  {/* {information && (
                    <div key="information" className="flex flex-col gap-2">
                      <h5 className="text-sm font-semibold text-gray-900">Information</h5>
                      <div
                        className={classNames(
                          "flex items-center justify-between rounded border border-gray-300 px-4 py-3",
                        )}
                      >
                        <h5 className="font-poppins text-sm font-semibold ">{information}</h5>
                      </div>
                    </div>
                  )} */}
                  {/* {grant && (
                    <div key="grant" className="flex flex-col gap-2">
                      <h5 className="text-sm font-semibold text-gray-900">Grant</h5>
                      <div
                        className={classNames(
                          "flex items-center justify-between rounded border border-gray-300 px-4 py-3",
                        )}
                      >
                        <h5 className="font-poppins text-sm font-semibold ">{grant}</h5>
                      </div>
                    </div>
                  )} */}
                  {/* {support && (
                    <div key="support" className="flex flex-col gap-2">
                      <h5 className="text-sm font-semibold text-gray-900">Support</h5>
                      <div
                        className={classNames(
                          "flex items-center justify-between rounded border border-gray-300 px-4 py-3",
                        )}
                      >
                        <h5 className="font-poppins text-sm font-semibold ">{phone}</h5>
                      </div>
                    </div>
                  )} */}
                  {/* {email && (
                    <div key="communication" className="flex flex-col gap-2">
                      <h5 className="text-sm font-semibold text-gray-900">Email address</h5>
                      <div
                        className={classNames(
                          "flex items-center justify-between rounded border border-gray-300 px-4 py-3",
                        )}
                      >
                        <h5 className="font-poppins text-sm font-semibold ">{email}</h5>
                      </div>
                    </div>
                  )} */}
                  {Object.keys(notPersonTitle).map((key) => {
                    const m = not_people.filter((p) => p?.category === key);
                    if (m.length > 0) {
                      return (
                        <div key={key} className="flex flex-col gap-2">
                          <h5 className="text-sm font-semibold text-gray-900">
                            {notPersonTitle[key as keyof typeof notPersonTitle]}
                          </h5>
                          <div className="flex flex-col gap-3">
                            {m.map((item, index) => (
                              <div
                                key={`member-${key}-${item.email || ""}-${index}`}
                                className={classNames(
                                  "flex items-center justify-between rounded border border-gray-300 px-4 py-3",
                                )}
                              >
                                <h5 className="font-poppins text-sm font-semibold ">
                                  {item.email ? item.email : "no email information available"}
                                </h5>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    } else return null;
                  })}
                </div>
              </div>
            </>
          )}
          {(email || people.length > 0) && (
            <div className="flex flex-col">
              <div className="border-b border-gray-300">
                <h5 className="text-base font-semibold underline underline-offset-[6px]">People</h5>
              </div>
              <Spacer height="20px" />
              {people.length > 0 && (
                <ClickToScrollWrapper disabled={people.length <= 1}>
                  <div
                    className={classNames(
                      "flex  max-h-[430px] flex-col gap-4 overflow-hidden overflow-y-scroll",
                      scrollBarClass,
                    )}
                  >
                    {/* {email &&
                      !contacts
                        ?.filter((p) => p.email)
                        ?.map((p) => p.email)
                        ?.includes(email) && (
                        <PeopleCardOld
                          key={"agency"}
                          name={name}
                          title={title}
                          email={email}
                          text={text}
                        />
                      )} */}
                    {people.map((item, index) => (
                      <ProfileCard2Old
                        key={`member-${item.firstname}-${index}`}
                        data={item as any}
                        allowEdit={false}
                      />
                    ))}
                  </div>
                </ClickToScrollWrapper>
              )}
            </div>
          )}
        </div>
      ) : (
        <>{renderNoInfo()}</>
      )}
    </>
  );
}

export default GrantContactOld;
