import { OnboardingData } from "../app/onboarding";
import { getISearchParamFromOnboardingData } from "../app/onboardingSlice";
import { User } from "../app/userSlice";
import { ILocation, reprLocation } from "../types/location";
import {
  GrantSearchResult,
  SearchQuery,
  getSearchQueryFromISearchParam,
  getURLSearchParamsFromSearchQuery,
} from "../types/search";

const getPastGrantSearchURL = (
  funder_id: string,
  selected: string,
  type: string,
  onboardingData: OnboardingData,
  query: SearchQuery,
): string => {
  const appended = {
    focus_area_subs:
      type === "f"
        ? [selected, ...query.focus_area.filter((i) => i !== selected)]
        : query.focus_area,
    beneficiary_subs:
      type === "b"
        ? [selected, ...query.beneficiary.filter((i) => i !== selected)]
        : query.beneficiary,
    program_subs:
      type === "p" ? [selected, ...query.program.filter((i) => i !== selected)] : query.program,
  };
  const selection = {
    focus_area: type === "f" ? [selected] : [],
    beneficiary: type === "b" ? [selected] : [],
    program: type === "p" ? [selected] : [],
  };
  return `/search?${getURLSearchParamsFromSearchQuery(
    onboardingData,
    {
      type: ["past_grant"],
      donor_id: funder_id,
      ...{ ...appended, service_loc_subs: query.service_loc, service_loc: [] },
      ...selection,
    },
    5,
  ).toString()}`;
};
const getPastGrantSearchURLFromLocation = (
  funder_id: string,
  selected: ILocation,
  type: string,
  onboardingData: OnboardingData,
  query: SearchQuery,
): string => {
  return `/search?${getURLSearchParamsFromSearchQuery(
    onboardingData,
    {
      type: ["past_grant"],
      donor_id: funder_id,
      ...{
        service_loc_subs: [
          selected,
          ...(query.service_loc?.filter((i) => reprLocation(i) !== reprLocation(selected)) || []),
        ],
        service_loc: [selected],
        must_service_loc: [selected],
        focus_area: [],
        focus_area_subs: query.focus_area,
        beneficiary: [],
        beneficiary_subs: query.beneficiary,
        program: [],
        program_subs: query.program,
      },
    },
    5,
  ).toString()}`;
};
const getNPOSearchURLFromGrant = (
  grant: GrantSearchResult,
  onboardingData: OnboardingData,
): string => {
  return `/search?${getURLSearchParamsFromSearchQuery(
    onboardingData,
    {
      type: ["eligible_npo"],
      grant_id: grant._id,
      text_query: "",
      mission: "",
      focus_area: [],
      focus_area_subs: [],
      beneficiary: [],
      beneficiary_subs: [],
      program: [],
      program_subs: [],
      service_loc: [],
      service_loc_subs: [],
      hq_loc: [],
      hq_loc_subs: grant.service_loc,
    },
    7,
  ).toString()}`;
};

const setSelectedQueryFunc = (
  onboardingData: OnboardingData,
  query?: SearchQuery,
  context?: any,
  user?: User,
) => {
  return query
    ? query
    : context?.query
    ? context.query
    : getSearchQueryFromISearchParam(
        user?._id || "",
        onboardingData,
        getISearchParamFromOnboardingData(onboardingData),
      );
};
export {
  getNPOSearchURLFromGrant,
  getPastGrantSearchURL,
  getPastGrantSearchURLFromLocation,
  setSelectedQueryFunc,
};
