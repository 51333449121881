import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { Pagination } from "rsuite";
import MemoizedLoadingPhilanthropy from "../components/LoadingPhilanthropy";
import FunderCard from "../components/search/FunderCard";
import GrantCard from "../components/search/GrantCard";
import NPOCard from "../components/search/NPOCard";
import PastGrantCard from "../components/search/PastGrantCard";
import useTotalHidden from "../hooks/search/useTotalHidden";
import {
  FunderSearchResult,
  GrantSearchResult,
  NPOSearchResult,
  PastGrantSearchResult,
  SearchQuery,
  SearchType,
  UnifiedSearchResult,
  getSearchResultKey,
} from "../types/search";

interface HiddenListViewProps {
  className?: string;
  showType: SearchType;
  query: SearchQuery | undefined;
}
const UNIT = 10;
const HiddenListView = ({ showType, query, ...props }: HiddenListViewProps) => {
  const [hiddenTab, setHiddenTab] = useState<number>(0);
  const [currentUnHidden, setCurrentUnHidden] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  useEffect(() => {
    setPage(1);
  }, [hiddenTab]);
  const {
    data,
    dataWithQuery,
    detail,
    detailWithQuery,
    maxCountForPage,
    maxCountWithQueryForPage,
    isHidden,
    setHideFunder,
    setHideGrant,
    setHideNPO,
    isLoading,
    isValidating,
  } = useTotalHidden({
    type: showType,
    use_query: true,
    query,
    fetch_detail: true,
    limit: UNIT,
    skip: UNIT * (page - 1),
  });
  const renderSearchResultItem = useCallback(
    (item: UnifiedSearchResult) => {
      const key = getSearchResultKey(item);
      const hidden = isHidden(key);
      return (
        <a
          key={key}
          className={classNames("!text-gray-900", hidden ? "bg-white" : "bg-purple-50")}
          href={
            item.search_type === "funder"
              ? `/donors/${item._id}`
              : item.search_type === "npo" || item.search_type === "eligible_npo"
              ? `/npos/${item._id}/`
              : item.search_type === "grant" ||
                item.search_type === "virtual_grant" ||
                item.search_type === "specific_funder_grant"
              ? `/grants/${item._id}`
              : "/"
          }
          rel="noreferrer"
          target="_blank"
        >
          <div
            className={classNames(
              "flex cursor-pointer flex-col border-b border-gray-300 px-4 py-3 hover:bg-purple-50",
              "bg-white",
            )}
          >
            {item.search_type === "funder" ? (
              <FunderCard
                key={key}
                funder={item as FunderSearchResult}
                query={query}
                isHidden={hidden}
                setHide={(e: React.MouseEvent<HTMLDivElement, MouseEvent>, hide: boolean) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setHideFunder(item.uid, hide);
                  if (!hide) setCurrentUnHidden((prev) => [...prev, getSearchResultKey(item)]);
                  else
                    setCurrentUnHidden((prev) =>
                      prev.filter((p) => p !== getSearchResultKey(item)),
                    );
                }}
              />
            ) : item.search_type === "grant" || item.search_type === "virtual_grant" ? (
              <GrantCard
                key={key}
                grant={item as GrantSearchResult}
                query={query}
                isHidden={hidden}
                setHide={(e: React.MouseEvent<HTMLDivElement, MouseEvent>, hide: boolean) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setHideGrant(item.uid, hide);
                  if (!hide) setCurrentUnHidden((prev) => [...prev, getSearchResultKey(item)]);
                  else
                    setCurrentUnHidden((prev) =>
                      prev.filter((p) => p !== getSearchResultKey(item)),
                    );
                }}
              />
            ) : item.search_type === "npo" ? (
              <NPOCard
                key={key}
                npo={item as NPOSearchResult}
                query={query}
                isHidden={hidden}
                setHide={(e: React.MouseEvent<HTMLDivElement, MouseEvent>, hide: boolean) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setHideNPO(item.uid, hide);
                  if (!hide) setCurrentUnHidden((prev) => [...prev, getSearchResultKey(item)]);
                  else
                    setCurrentUnHidden((prev) =>
                      prev.filter((p) => p !== getSearchResultKey(item)),
                    );
                }}
              />
            ) : item.search_type === "past_grant" ? (
              <PastGrantCard
                key={key}
                grant={item as PastGrantSearchResult}
                query={query}
                // isHidden={hidden}
                // setHide={(e: React.MouseEvent<HTMLDivElement, MouseEvent>, hide: boolean) => {
                //   e.stopPropagation();
                //   e.preventDefault();
                //   setHidePastGrant(item._id, hide);
                // }}
              />
            ) : null}
          </div>
        </a>
      );
    },
    [query, isHidden, setCurrentUnHidden, setHideFunder, setHideGrant, setHideNPO],
  );
  return (
    <div className={classNames("flex w-full flex-col", props.className)}>
      <div className="mb-5 flex w-full items-center gap-5 border-b border-gray-300">
        <div
          className={classNames(
            "cursor-pointer p-1 hover:font-semibold hover:text-gray-700",
            hiddenTab === 0
              ? "border-b border-purple-500 font-semibold text-black"
              : "text-gray-700",
          )}
          onClick={() => setHiddenTab(0)}
        >
          Hidden in this search ({dataWithQuery.length})
        </div>
        <div
          className={classNames(
            "cursor-pointer p-1 hover:font-semibold hover:text-gray-700",
            hiddenTab === 1
              ? "border-b border-purple-500 font-semibold text-black"
              : "text-gray-700",
          )}
          onClick={() => setHiddenTab(1)}
        >
          View all hidden ({data.length})
        </div>
      </div>
      <div className="mb-3 flex flex-col">
        {isLoading || isValidating ? (
          <MemoizedLoadingPhilanthropy loading={isLoading || isValidating} />
        ) : (
          <>
            {(hiddenTab === 0 ? detailWithQuery : detail)
              .filter((d) => !currentUnHidden.includes(getSearchResultKey(d)))
              .map((d) => (
                <div key={getSearchResultKey(d)}>{renderSearchResultItem(d)}</div>
              ))}
          </>
        )}
      </div>
      <div className="mb-4 flex flex-col items-center py-4">
        {isLoading || isValidating ? null : (
          <Pagination
            prev={true}
            last={false}
            next={true}
            first={false}
            size="md"
            total={hiddenTab === 0 ? maxCountWithQueryForPage : maxCountForPage}
            limit={UNIT}
            maxButtons={8}
            ellipsis
            activePage={page}
            onChangePage={(page) => setPage(page)}
          />
        )}
      </div>
    </div>
  );
};

export default HiddenListView;
