import { useCallback, useEffect, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import { getFileUrl } from "../services/file-upload/download.service";
import { swrOptionDedupling5mins } from "../types/swr";

interface useFunderState {
  data?: string;
  isLoading?: boolean;
  error?: object;
  revalidate: () => Promise<string | undefined>;
}
export default function usePhotoUrl(photo_object_key?: string, filename?: string): useFunderState {
  const { mutate } = useSWRConfig();
  const fetch = useCallback(
    async (url: string) => {
      if (!url || !photo_object_key) return new Promise<string>((resolve, reject) => reject());
      return getFileUrl(photo_object_key, filename).then((res) => res as string);
    },
    [photo_object_key],
  );
  const { data, isLoading, error } = useSWR<string>(
    photo_object_key
      ? process.env.REACT_APP_DOWNLOAD_NPO_MEDIA_URL +
          `?id=${photo_object_key}&filename=${filename}`
      : null,
    fetch,
    swrOptionDedupling5mins,
  );
  const revalidate = useCallback(() => {
    // FIXME after cloudfront
    setTimeout(() => {
      mutate<string>(process.env.REACT_APP_DOWNLOAD_NPO_MEDIA_URL + `?id=${photo_object_key}`);
    }, 1500);
    return mutate<string>(process.env.REACT_APP_DOWNLOAD_NPO_MEDIA_URL + `?id=${photo_object_key}`);
  }, [photo_object_key]);
  return { data, isLoading, error, revalidate };
}
