import classNames from "classnames";
import React, { PropsWithChildren, ReactNode } from "react";
import ICON from "../assets/images/star-search.svg";

type GibooGradientDivProps = {
  id?: string;
  wrapperClassName?: string;
  className?: string;
  onClick?: () => void;
  hover?: boolean;
  borderSize?: 1 | 2 | 3 | 4 | 5;
  showIcon?: boolean;
  customIcon?: ReactNode;
  roundedTop?: boolean;
};

type GibooGradientDivComponent = React.FunctionComponent<PropsWithChildren<GibooGradientDivProps>>;
const GibooGradientDiv: GibooGradientDivComponent = ({
  wrapperClassName,
  className,
  borderSize = 2,
  showIcon = false,
  customIcon,
  id,
  roundedTop = false,
  ...props
}: PropsWithChildren<GibooGradientDivProps>) => {
  const borderVariants = {
    1: "rounded p-[1px]",
    2: "rounded p-[2px]",
    3: "rounded p-[3px]",
    4: "rounded p-1",
    5: "rounded p-[5px]",
  };
  const innerBorderVariants = {
    1: "rounded-[3px]",
    2: "rounded-[3px]",
    3: "rounded-[3px]",
    4: "rounded-[3px]",
    5: "rounded-[3px]",
  };
  return (
    <div
      id={id || "gradient-div"}
      className={classNames(
        "giboo-gradient-bg",
        borderVariants[borderSize],
        wrapperClassName,
        roundedTop &&
          "overflow-hidden rounded-bl-[0px] rounded-br-[10px] rounded-tl-[10px] rounded-tr-[10px]",
      )}
      onClick={props.onClick}
    >
      <div
        className={classNames(
          "overflow-hidden bg-white",
          innerBorderVariants[borderSize],
          className,
          roundedTop &&
            "overflow-hidden rounded-bl-[0px] rounded-br-[10px] rounded-tl-[10px] rounded-tr-[10px]",
        )}
      >
        {showIcon ? (
          <div className="flex gap-2">
            <span className="pt-1">
              {customIcon ? customIcon : <img src={ICON} className="h-4 w-4" />}
            </span>
            {props.children}
          </div>
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};
export default GibooGradientDiv;
