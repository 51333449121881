import classNames from "classnames";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import SearchableDropdown from "./dropdown/SearchableDropdown";
import Tag from "./Tag";
import PlainButton from "./PlainButton";

interface MultipleSelectorProps<T> {
  id: string;
  className?: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green" | "gray";
  getColor?: (key: T) => "purple" | "red" | "yellow" | "blue" | "green" | "gray";
  value: T[];
  getKey: (item: T) => string;
  reprItem: (item: T) => string;
  createItem?: (key: string) => T;
  setValue: Dispatch<SetStateAction<T[]>>;
  autoCompleteFunction?: (key: string) => Promise<T[]>;
  autoCompleteThrottleMilliseconds?: number;
  placeholder?: string;
  maxWidth?: string;
  maxHeight?: string;
  inputBoxMaxWidth?: string;
  itemsMaxHeight?: string;
  itemsPosition?: "top" | "bottom";
  itemsAlign?: "start" | "center" | "end";
  gap?: number;
  icon?: boolean;
  hideNone?: boolean;
  float?: boolean;
  searchFieldPosition?: "start" | "center" | "end";
  spinner?: boolean;
  spinnerBackgroundColor?: string;
  hideSearch?: boolean;
  inputClassName?: string;
  addMoreLabel?: string;
  addMoreLabelClass?: string;
  selected?: boolean;
  openAnimation?: boolean;
  compact?: boolean;
  tagMatched?: boolean;
  isFullTagClick?: boolean;
  tagSize?: "sm" | "md" | "lg";
}
function MultipleSelector<T>({
  id,
  className,
  color,
  value,
  setValue,
  getKey,
  reprItem,
  createItem,
  isFullTagClick,
  autoCompleteFunction = (key: string) => {
    return new Promise<T[]>((resolve) => resolve([]));
  },
  autoCompleteThrottleMilliseconds,
  placeholder,
  maxWidth,
  inputBoxMaxWidth,
  itemsMaxHeight,
  itemsPosition = "bottom",
  itemsAlign = "start",
  searchFieldPosition = "center",
  gap = 2,
  icon,
  hideNone,
  float,
  spinner = true,
  spinnerBackgroundColor = "bg-white",
  hideSearch = false,
  inputClassName,
  addMoreLabel = "Add more",
  addMoreLabelClass,
  selected,
  openAnimation,
  compact,
  tagMatched = false,
  maxHeight = "400px",
  tagSize,
}: MultipleSelectorProps<T>) {
  const [query, setQuery] = useState<string>("");
  const excludes = value.map((item) => getKey(item));
  const addItem = (item: T) => {
    if (excludes.includes(getKey(item))) {
      // TODO : focus label
      //   href={props.fragment}
    } else {
      setValue((prev) => [...prev, item]);
    }
  };
  const [hideSearchState, setHideSearchState] = useState<boolean>(true);
  const memoizedItems = useMemo(() => {
    return (
      <div
        className={classNames(
          className,
          "multiple-selector-items mt-4 flex w-full flex-wrap overflow-auto",
          `gap-${gap}`,
          `justify-${itemsAlign}`,
        )}
        style={{ maxWidth: maxWidth, maxHeight: itemsMaxHeight }}
      >
        {value.length > 0 ? (
          value.map((v) => (
            <Tag
              id={`${id}-${getKey(v)}`}
              key={getKey(v)}
              name={reprItem(v)}
              color={color}
              selected={selected}
              matched={tagMatched}
              isFullTagClick={isFullTagClick}
              onDelete={() => {
                setValue((prev) => [...prev.filter((item) => item !== v)]);
              }}
              size={tagSize}
            />
          ))
        ) : !hideNone ? (
          <Tag
            id={`${id}-`}
            key={null}
            name={"None"}
            matched={tagMatched}
            selected={selected}
            color={color}
            size={tagSize}
          />
        ) : null}
      </div>
    );
  }, [value, setValue]);
  return (
    <div
      className={classNames(
        `multiple-selector-container flex flex-col items-${searchFieldPosition}`,
        `gap-${gap}`,
      )}
      style={{ maxWidth: maxWidth }}
    >
      {itemsPosition === "top" && <>{memoizedItems}</>}
      {hideSearch && hideSearchState ? (
        <div className="self-start">
          <PlainButton
            id={`btn-hide-${id}`}
            label={addMoreLabel}
            className={addMoreLabelClass}
            rightIconClass="fa-regular fa-plus"
            handleOnClick={() => setHideSearchState(false)}
          />
        </div>
      ) : (
        <SearchableDropdown<T>
          id={id}
          color={color}
          query={query}
          setQuery={setQuery}
          autoCompleteFunction={autoCompleteFunction}
          autoCompleteThrottleMilliseconds={autoCompleteThrottleMilliseconds}
          addItem={addItem}
          createItem={createItem}
          renderItem={reprItem}
          getKey={getKey}
          excludeKeys={excludes}
          placeholder={placeholder}
          float={float}
          maxHeight={maxHeight}
          maxWidth={inputBoxMaxWidth}
          icon={icon}
          spinner={spinner}
          className={inputClassName}
          spinnerBackgroundColor={spinnerBackgroundColor}
          openAnimation={openAnimation}
          size={compact ? "md" : "lg"}
          clearQuery={true}
          reprItem={() => ""}
        />
      )}
      {itemsPosition === "bottom" && <>{memoizedItems}</>}
    </div>
  );
}
export default MultipleSelector;
