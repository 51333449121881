import { ReactNode, useCallback } from "react";
import useOnboardingData from "../hooks/useOnboarding";
import { SearchQuery, SearchType, getURLSearchParamsFromSearchQuery } from "../types/search";
import { useNavigate } from "react-router-dom";
import useOrgProjectTagging from "../hooks/useOrgProjectTagging";
import useProject from "../hooks/project/useProject";
interface GotoSmartSearchContextRenderProps {
  isLoading: boolean;
  go: (data?: any) => void;
}
interface GotoSmartSearchContextProps {
  query?: SearchQuery;
  type?: SearchType;
  project_id: string | undefined;
  children?: (props: GotoSmartSearchContextRenderProps) => ReactNode;
}
type IsFunction<T> = T extends (...args: any[]) => any ? T : never;
const isFunction = <T extends object>(value: T): value is IsFunction<T> =>
  typeof value === "function";
const GotoSmartSearchContext = ({
  query,
  type,
  project_id,
  children,
}: GotoSmartSearchContextProps) => {
  if (children && !isFunction(children)) {
    throw new Error("children is mandatory and needs to be a function");
  }
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const { data: project } = useProject(project_id);
  const { data, isLoading, error } = useOrgProjectTagging(project_id);
  const go = useCallback(
    (locationState?: any) => {
      if (isLoading) return;
      navigate(
        `/search?${getURLSearchParamsFromSearchQuery(
          onboardingData,
          query
            ? query
            : data
            ? {
                text_query: project_id ? project.problem_desc || "" : onboardingData.mission || "",
                ...data,
              }
            : {},
          type === "funder" ? 1 : type === "grant" ? 2 : type === "npo" ? 3 : 0,
          false,
          true,
        ).toString()}`,
      );
      // navigate(
      //   `/search?text_query=&mission=${encodeURIComponent(
      //     onboardingData.mission || "",
      //   )}&type=${type}&done_tagging=false&done_pre_tagging=false`,
      //   { state: locationState },
      // );
    },
    [onboardingData, isLoading, data],
  );
  return <>{children && children({ go, isLoading })}</>;
};
export default GotoSmartSearchContext;
