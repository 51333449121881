import { Dialog } from "@headlessui/react";
import PlainButton from "./PlainButton";
import React, { Dispatch, SetStateAction, useState } from "react";
import classNames from "classnames";
import CloseButton from "./CloseButton";
import useProjects from "../hooks/project/useProjects";
import ProjectCard2 from "./ProjectCard2";
import PrimaryButton from "./PrimaryButton";
import ProjectCreationModal from "./ProjectCreationModal";
import IMG_PROJECTS from "../assets/project-mgt/mytask-placeholder.svg";
import { IProject } from "../types/project";
import { scrollBarClass } from "../utils/classes";
import Spacer from "./Spacer";
import Button from "./tailwind/Button";
import { createProject } from "../hooks/project/useProject";
import { useAppDispatch } from "../app/store";
import { useNavigate } from "react-router-dom";
import { appendProjectAsync } from "../app/projectSlice";
import GibooToast from "./GibooToast";
import useOrgID from "../hooks/useOrgID";
import IMAGE_EMPTY from "../assets/images/giboo-bg-gray.svg";
import ProjectInformationModal from "./ProjectInformationModal";
import { createProjectTask } from "../hooks/project/useTask";
import { zIndexBackdrop, zIndexDialog, zIndexStartfundraising } from "../types/zIndex";
import Spinner from "./Spinner";
import { SearchQuery } from "../types/search";
import { FROM_FOR_MIXPANEL } from "../mixpanel/mixpanel";

interface ProjectPickerModalProps {
  from_for_mixpanel: FROM_FOR_MIXPANEL;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  onSelect?: (project: IProject) => void;
  target_id?: string;
  target_type?: "FUNDER" | "GRANT";
  search_query?: SearchQuery;
}
function ProjectPickerModal({
  from_for_mixpanel,
  open,
  setOpen,
  onClose,
  onSelect,
  target_id,
  target_type,
  search_query,
}: ProjectPickerModalProps) {
  const [selectedProject, setselectedProject] = useState<IProject | undefined>();
  const [projects] = useProjects();
  const projectList = [...projects.data].reverse();
  const [showProjectCreationModal, setShowProjectCreationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleDone = (_selectedProject: IProject) => {
    if (!_selectedProject || !target_id || !target_type) return;
    setLoading(true);
    createProjectTask({
      project_id: _selectedProject._id,
      target_id: target_id,
      target_type: target_type === "FUNDER" ? 0 : 1,
      ...(search_query ? { search_query } : {}),
    })
      .then((res) => {
        navigate(`/project/${_selectedProject._id}`, {
          state: {
            showOutreach: true,
            target_type: target_type === "FUNDER" ? 0 : 1,
            target_id,
            showBack: true,
          },
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      {showProjectCreationModal && (
        <ProjectInformationModal
          from_for_mixpanel={from_for_mixpanel}
          open={showProjectCreationModal}
          setOpen={setShowProjectCreationModal}
          id=""
          isNewProject
          fromFundraising
          onProjectCreation={(data) => {
            if (target_id && target_type)
              createProjectTask({
                project_id: data._id,
                target_id: target_id,
                target_type: target_type === "FUNDER" ? 0 : 1,
                ...(search_query ? { search_query } : {}),
              });
          }}
          target_id={target_id}
          target_type={target_type === "FUNDER" ? 0 : 1}
        />
      )}
      {!showProjectCreationModal && open && (
        <>
          <Dialog open={open} onClose={() => null}>
            <div
              className={classNames("fixed inset-0  bg-black/80", zIndexBackdrop)}
              aria-hidden="true"
            />
            <div
              className={classNames(
                "fixed inset-0 grid w-screen place-items-center overflow-y-auto p-4 ",
                zIndexDialog,
              )}
            >
              <Dialog.Panel
                className={classNames(
                  "mx-auto  min-h-[571px] !w-[959px] rounded bg-white",
                  zIndexStartfundraising,
                )}
              >
                <div className="relative flex h-full w-full flex-col">
                  {/* header */}
                  <div className="flex items-center justify-between border-b border-gray-300 p-5">
                    <div className="inline-flex gap-2">
                      <div className="flex flex-col gap-[9px]">
                        <h5 className="font-poppins text-[20px] font-semibold">
                          Start fundraising
                        </h5>
                      </div>
                    </div>
                    <PlainButton
                      id={`btn-update-image-close`}
                      handleOnClick={() => {
                        onClose?.();
                        setOpen(false);
                      }}
                      leftIconClass={<CloseButton />}
                    />
                  </div>
                  {/* content */}
                  <div className="flex h-[466px] w-full justify-center p-7">
                    {projectList.length > 0 ? (
                      <div
                        className={classNames(
                          "grid max-h-full grid-cols-3 gap-4 overflow-y-scroll ",
                          projectList.length > 2 && scrollBarClass,
                        )}
                      >
                        {projectList.map((i, index) => (
                          <ProjectCard2
                            key={index}
                            projectId={i._id}
                            height={197}
                            width={283}
                            showPublicBtn={false}
                            isPrivateView
                            hasWritePermission={false}
                            readonly
                            isCheckBox
                            isCheckBoxSelected={i._id === selectedProject?._id}
                            onSelect={(project) => {
                              if (project) {
                                handleDone(project);
                                onSelect?.(project);
                              }
                              // if (i._id === selectedProject?._id) {
                              //   setselectedProject(undefined);
                              // } else {
                              //   setselectedProject(project);
                              // }
                            }}
                          />
                        ))}
                        {projectList.length < 5 && (
                          <div
                            style={{ height: 197, width: 283 }}
                            className={classNames(
                              "flex cursor-pointer flex-col items-center justify-center rounded border border-gray-200 bg-white hover:bg-gray-50",
                            )}
                            onClick={() => setShowProjectCreationModal(true)}
                          >
                            <div className="relative grid w-full place-items-center">
                              <div className="h-[76px] w-[128px]">
                                <img src={IMAGE_EMPTY} className="h-full w-full brightness-90" />
                              </div>
                              <div className="absolute font-poppins text-sm font-semibold text-purple-500">
                                + Create a new project
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="mt-3 grid min-h-[239px] w-full place-items-center gap-5">
                        <img src={IMG_PROJECTS} className="h-[207px] w-full" />
                        <div className="flex flex-col items-center justify-center gap-4">
                          <h5 className=" text-center font-poppins text-xs font-normal text-gray-700">
                            No projects yet. Start creating a project!
                          </h5>
                        </div>
                        <PrimaryButton
                          size="md"
                          id={`btn-create-project`}
                          label={"Create a new project"}
                          className="GA_TAG_click_createaproject !h-[29px] !text-sm"
                          leftIconClass="gi-add gi-md"
                          handleOnClick={() => setShowProjectCreationModal(true)}
                        />
                      </div>
                    )}
                  </div>
                  {/* Footer */}
                  <div className="flex items-center justify-between border-t border-gray-300 p-5">
                    <Button
                      id="create a project"
                      handleOnClick={() => setShowProjectCreationModal(true)}
                      className="!text-sm"
                      outline
                      label="Create a new project"
                    />
                    {/* <Button
                    id="done"
                    handleOnClick={() => {
                      if (selectedProject) {
                        handleDone(selectedProject);
                        onSelect?.(selectedProject);
                      }
                    }}
                    loading={loading}
                    className="!text-sm"
                    disabled={!selectedProject}
                    label="Done"
                  /> */}
                  </div>
                  {loading && (
                    <div className="absolute top-0 z-[9999] flex h-full w-full items-center justify-center bg-black/10">
                      <Spinner size="md" />
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}
export default ProjectPickerModal;
