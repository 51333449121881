import { useEffect, useState } from "react";
import useUser from "../../hooks/useUser";
import { User } from "../../app/userSlice";
import useSWR from "swr";
import classNames from "classnames";
import axios from "axios";
import config from "../../api";
import Spinner from "../Spinner";
import { getRandomColor } from "../../utils/formatHelper";
import useHasPermissionToUpdateGrant from "../../hooks/internal_tool/useHasPermissionToUpdateGrant";
import { swrOptionDedupling5mins, swrOptionFetchOnlyOnMount } from "../../types/swr";

export default function AnyUserProfile({
  userId,
  showName = true,
  showEmail = false,
  pending = false,
  size = 24,
  wrapperStyle = {},
  wrapperClassname = "",
  profileImageClass,
  role = 1,
  ...props
}: {
  userId?: string;
  showName?: boolean;
  showEmail?: boolean;
  size?: 12 | 16 | 20 | 24;
  wrapperClassname?: string;
  wrapperStyle?: object;
  profileImageClass?: string;
  pending?: boolean;
  org_id?: string;
  role?: number;
}) {
  const [user] = useUser();
  const permission_url = process.env.REACT_APP_API_URL + `/api/v2/orgs/verify/permission`;
  const [loading, setLoading] = useState<boolean>(false);
  const sizeVariants = {
    12: "h-[12px] w-[12px] min-w-[12px] min-h-[12px]",
    16: "h-[16px] w-[16px] min-w-[16px] min-h-[16px]",
    20: "h-[20px] w-[20px] min-w-[20px] min-h-[20px]",
    24: "h-[24px] w-[24px] min-w-[24px] min-h-[24px]",
  };
  const textSizeVariants = {
    12: "mt-[2px] text-sm",
    16: "mt-[2px] text-sm",
    20: "mt-[2px] text-sm",
    24: "text-base",
    28: "text-normal",
  };
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const { data: hasPermission } = useSWR<boolean>(permission_url, fetch, swrOptionFetchOnlyOnMount);
  const { data, isLoading } = useSWR<User>(
    hasPermission && userId ? process.env.REACT_APP_API_URL + `/api/v2/users/${userId}` : null,
    fetch,
    swrOptionDedupling5mins,
  );
  const profile = userId === user?._id ? user : data;
  const userName = `${profile && profile.firstname ? profile.firstname : ""} ${
    profile && profile.lastname ? profile.lastname : ""
  }`;
  const userEmail = profile?.email || "";
  const renderProfile = (size: 12 | 16 | 20 | 24 = 12) => {
    const first =
      profile && !pending && profile.firstname && profile.firstname.length > 0
        ? profile.firstname[0].toUpperCase()
        : "";
    const second =
      profile && !pending && profile.lastname && profile.lastname.length > 0
        ? profile.lastname[0].toUpperCase()
        : "";
    return !pending ? (
      <div
        style={{
          backgroundColor: getRandomColor(profile?.firstname || "", profile?.lastname || ""),
        }}
        className={classNames(
          "prevent-select flex items-center justify-center rounded-full p-1 text-white",
          sizeVariants[size],
          profileImageClass,
        )}
      >
        <h4
          className={classNames("text-center text-xs", textSizeVariants[size])}
        >{`${first}${second}`}</h4>
      </div>
    ) : null;
  };
  return (
    <div
      className={classNames(
        "flex h-full w-fit items-center",
        wrapperClassname,
        showName || showEmail ? "gap-4" : "gap-0",
      )}
      style={wrapperStyle}
    >
      {renderProfile(size)}
      <span>
        {showName && (
          <div className="w-fit">
            <h5
              title={userName}
              className="giboo-nav-label  prevent-select m-0 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal text-black"
            >
              {userName}
            </h5>
          </div>
        )}
        {showEmail && (
          <div className="w-fit">
            <h6
              title={userEmail}
              className="giboo-nav-label  prevent-select m-0 overflow-hidden text-ellipsis whitespace-nowrap text-xs font-normal text-gray-700"
            >
              {userEmail}
            </h6>
          </div>
        )}
      </span>
    </div>
  );
}
