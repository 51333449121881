import classNames from "classnames";
import { ILocation, reprLocation } from "../types/location";
import { stageFilterOptions } from "../types/search";
import { ReactElement } from "react";

const getFunderHqLocation = (funder: { location?: ILocation; address?: string }): string => {
  return funder.location
    ? reprLocation(funder.location)
    : funder.address
    ? funder.address.split(",").slice(-3, -1).join(",")
    : "US";
};
const reprFunderTotalGrantCount = (funder: {
  unclassified_count: number;
  dreamer_count: number;
  seed_count: number;
  startup_count: number;
  buildup_count: number;
  superstar_count: number;
  hyperstar_count: number;
}): number => {
  const counts = [
    funder.dreamer_count,
    funder.seed_count,
    funder.startup_count,
    funder.startup_count,
    funder.buildup_count,
    funder.superstar_count,
    funder.hyperstar_count,
  ];
  return counts.reduce((prev, cur) => prev + (cur || 0), 0);
};
const reprFunderPreferredStages = (
  funder: {
    dreamer_count: number;
    seed_count: number;
    startup_count: number;
    buildup_count: number;
    superstar_count: number;
    hyperstar_count: number;
  },
  detail = true,
  max = 3,
  selected = false,
  scrollFunc?: () => void,
): ReactElement | string => {
  const counts = [
    funder.dreamer_count,
    funder.seed_count,
    funder.startup_count,
    funder.buildup_count,
    funder.superstar_count,
    funder.hyperstar_count,
  ];
  const labels = stageFilterOptions
    .map((o, i) => (counts[i] ? { label: o.label, count: counts[i] } : undefined))
    .sort((a, b) => {
      if (a && !b) return -1;
      if (!a && b) return 1;
      return (b?.count || 0) - (a?.count || 0);
    })
    .filter(Boolean);
  if (labels.length === 0) return "";
  const _max = !detail ? Math.min(max, labels.length) : labels.length;
  return (
    <span>
      {labels.slice(0, _max).map((l, i) => (
        <span
          key={l?.label}
          className={classNames(
            "font-normal",
            i === _max - 1
              ? ""
              : selected
              ? "mr-1 border-r border-purple-200 pr-1"
              : "mr-1 border-r border-gray-300 pr-1",
          )}
        >
          {l?.label}
        </span>
      ))}
      <span className="!text-black">
        {`${!detail && labels && labels.length > max ? `... +${labels.length - max}` : ""}`}
      </span>
      {!detail && labels && labels.length > max && scrollFunc ? (
        <span
          className="ml-3 cursor-pointer text-gray-700 hover:underline"
          onClick={() => {
            scrollFunc?.();
          }}
        >
          View all
        </span>
      ) : null}
    </span>
  );
};
const renderRow = (
  title: string | ReactElement,
  content: string | ReactElement | undefined,
  showNoInformation = false,
  detail = true,
) => {
  return (
    <span className={classNames("whitespace-pre-line text-sm", detail ? "" : "w-full truncate")}>
      {typeof title === "string" ? (
        <span
          className={classNames("mr-2 font-semibold text-gray-900 ", "whitespace-nowrap")}
        >{`${title}:`}</span>
      ) : (
        <>{title}</>
      )}
      {showNoInformation && !content ? (
        <span>No information available</span>
      ) : typeof content === "string" ? (
        <span className={classNames("font-normal text-gray-900", detail ? "" : "w-full truncate")}>
          {content}
        </span>
      ) : (
        <>{content}</>
      )}
    </span>
  );
};
export { getFunderHqLocation, reprFunderTotalGrantCount, reprFunderPreferredStages, renderRow };
