import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import { FunderProfile } from "../../types/funder";
import axios from "axios";
import config from "../../api";
import { IGrant } from "../../types/grant";
import { GrantSearchResult } from "../../types/search";
import { isActiveGrant } from "../../utils/grant";
import { swrOptionDedupling5mins } from "../../types/swr";

interface useFunderState {
  data: GrantSearchResult[];
  isLoading: boolean;
  error?: object;
}
const emptyList: GrantSearchResult[] = [];
export default function useFunderSolicitateGrants(id: string): useFunderState {
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) =>
      response.data
        // .filter((g: GrantSearchResult) => g.type === "grant-page" || isActiveGrant(g))
        .map((g: any) => ({
          ...g,
          focus_area: g.focus_area.map((i: string) => ({ label: i, matched: false })),
          beneficiary: g.beneficiary.map((i: string) => ({ label: i, matched: false })),
          program: g.program.map((i: string) => ({ label: i, matched: false })),
          search_type: "grant",
        })),
    );
  };
  const { data, isLoading, error } = useSWR<GrantSearchResult[]>(
    id ? process.env.REACT_APP_API_URL + `/api/v2/grants/searchresult/${id}` : null,
    fetch,
    swrOptionDedupling5mins,
  );
  return { data: data || emptyList, isLoading, error };
}
