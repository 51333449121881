import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import useNPOSummary from "../hooks/useNPOSummary";
import useOrgDataForNPODashboard from "../hooks/useOrgDataForNPODashboard";
import PlainButton from "./PlainButton";

export default function NPOSummary({
  id,
  title = "General information about nonprofit",
  showButton = false,
  isPrivateView = false,
  ...props
}: {
  about?: string;
  id?: string;
  isPrivateView?: boolean;
  title?: string;
  wrapperClass?: string;
  showButton?: boolean;
  compact?: boolean;
}) {
  const navigate = useNavigate();
  const { data: orgData, npo_id } = useOrgDataForNPODashboard(id, isPrivateView);
  const { data: summary, isLoading } = useNPOSummary(npo_id);
  const renderLoading = () => {
    return (
      <div
        className={classNames("flex w-full flex-wrap ", {
          "loading-light-shimmer": !isLoading,
          "loading-light-shimmer-on gap-4": isLoading,
        })}
      >
        <div className="loading-light-shimmer-child flex w-full flex-col gap-3 px-[28px] py-[16px]">
          <div className="h-[21px] w-[500px]" />
          <div className="h-[21px] w-[560px]" />
          <div className="h-[21px] w-[430px]" />
          <div className="h-[21px] w-[520px]" />
          <div className="h-[21px] w-[300px]" />
        </div>
      </div>
    );
  };
  const renderAny = (a: any) => {
    if (typeof a === "string") return a;
    else return JSON.stringify(a);
  };
  return (
    <div id={id} className={classNames("flex flex-col gap-5", props.wrapperClass)}>
      {title && (
        <div className="flex items-start justify-between gap-5">
          <h4 className="grow text-start text-black">{title || ""}</h4>
          {showButton && (
            <p onClick={() => navigate(`/npos/${id}/`)}>
              <PlainButton
                className="!m-0 min-w-[120px] whitespace-nowrap !p-0 !text-sm !text-purple-500"
                label="View npo detail"
                handleOnClick={() => {
                  //   navigate(`/donors/${funder_id}`);
                }}
                leftIconClass="gi-sm gi-open"
                id={`btn-go-${id}`}
              />
            </p>
          )}
        </div>
      )}
      {isLoading ? (
        <>{renderLoading()}</>
      ) : (
        <>
          {orgData?.summary ? (
            <h5>{orgData.summary}</h5>
          ) : orgData?.about ? (
            <h5>{orgData.about}</h5> //FIXME: deprecate about
          ) : (
            summary?.mission_and_vision && <h5>{renderAny(summary.mission_and_vision)}</h5>
          )}
          {(orgData?.focus_area_text || summary?.philanthropic_focuses) && (
            <>
              <div>
                <div className="mb-2 border-b border-gray-300">
                  <h5 className="text-base font-semibold underline underline-offset-[6px]">
                    Focus area
                  </h5>
                </div>
                {orgData?.focus_area_text ? (
                  <h5>{orgData.focus_area_text}</h5>
                ) : (
                  <h5>{renderAny(summary?.philanthropic_focuses || "")}</h5>
                )}
              </div>
            </>
          )}
          {(orgData?.beneficiary_text || summary?.beneficiaries) && (
            <>
              <div>
                <div className="mb-2 border-b border-gray-300">
                  <h5 className="text-base font-semibold underline underline-offset-[6px]">
                    Beneficiary
                  </h5>
                </div>
                {orgData?.beneficiary_text ? (
                  <h5>{orgData.beneficiary_text}</h5>
                ) : (
                  <h5>{renderAny(summary?.beneficiaries)}</h5>
                )}
              </div>
            </>
          )}
          {(orgData?.strategy_text || summary?.programs) && (
            <>
              <div>
                <div className="mb-2 border-b border-gray-300">
                  <h5 className="text-base font-semibold underline underline-offset-[6px]">
                    Program
                  </h5>
                </div>
                {orgData?.strategy_text ? (
                  <h5>{orgData.strategy_text}</h5>
                ) : (
                  <h5>{renderAny(summary?.programs)}</h5>
                )}
              </div>
            </>
          )}
          <>
            <div>
              <div className="mb-2 border-b border-gray-300">
                <h5 className="text-base font-semibold underline underline-offset-[6px]">
                  Mission statement
                </h5>
              </div>
              <h5 className="mt-4 text-sm font-normal text-gray-900">
                {orgData?.mission || "No information available"}
              </h5>
            </div>
          </>
          {/* <>
            <div>
              <div className="mb-2 border-b border-gray-300">
                <h5 className="text-base font-semibold underline underline-offset-[6px]">
                  990 forms as PDFs
                </h5>
              </div>
              <h5 className="mt-4 text-sm font-normal text-gray-900">
                {"No information available"}
              </h5>
            </div>
          </> */}
          {/* {summary?.headquarter_location && (
            <>
              <div>
                <div className="mb-2 border-b border-gray-300">
                  <h5 className="text-base font-semibold underline underline-offset-[6px]">
                    Address
                  </h5>
                </div>
                <h5>{renderAny(summary.headquarter_location)}</h5>
              </div>
            </>
          )} */}
          {/* {summary?.financial_information && (
            <>
              <div>
                <div className="mb-2 border-b border-gray-300">
                  <h5 className="text-base font-semibold underline underline-offset-[6px]">
                    Financial information
                  </h5>
                </div>
                <h5>{renderAny(summary.financial_information)}</h5>
              </div>
            </>
          )} */}
          {/* {summary &&
        Object.keys(summary)
          .filter(
            (key) =>
              ![
                "mission_and_vision",
                "philanthropic_focuses",
                "beneficiaries",
                "financial_information",
              ].includes(key),
          )
          .map((key) => (
            <div key={key}>
              <h5 className="font-semibold">
                {key[0].toUpperCase()}
                {snakeToCamelCase(key.slice(1)).toLowerCase()}
              </h5>
              <h5>{renderAny(summary[key])}</h5>
            </div>
          ))} */}
        </>
      )}
    </div>
  );
}
