import { useEffect, useState } from "react";
import useFunderFiledYear from "../../hooks/funder/useFunderFiledYear";
import useFunderGrantAmountOverview from "../../hooks/funder/useFunderGrantAmountOverview";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";
import useForecasts from "../../hooks/useForecasts";
import useGibooMixpanel from "../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../mixpanel/mixpanel";
import { GrantAmount } from "../../types/funder";
import ChartLoader from "../ChartLoader";
import VisibilityTracker from "../VisibilityTracker";
import DonorGrantsOverview from "../dashboard/DonorGrantsOverview";
const range_from = 0;
const range_to = 1000000000000;
const postProcessing = (a: GrantAmount): GrantAmount => {
  return {
    ...a,
    count: a.sum === 0 ? 0 : Math.round(a.count),
    sum: a.count === 0 ? 0 : a.sum,
    max: a.sum === 0 || a.count === 0 ? 0 : Math.max(a.max, Math.max(a.min, a.median)),
    median: a.sum === 0 || a.count === 0 ? 0 : Math.max(a.median, a.min),
    min: a.sum === 0 || a.count === 0 ? 0 : a.min,
  };
};
export default function FunderGrantAmountOverview({
  funder_id,
  compact,
  ...props
}: {
  funder_id: string;
  wrapperClass?: string;
  compact?: boolean;
}) {
  const LAST_N_YEARS = 6;
  const { data: funder, isLoading: isFunderLoading } = useFunderSearchResult(funder_id);
  const { data: filedYears } = useFunderFiledYear(funder_id, LAST_N_YEARS);
  const { data: grantAmountOverview, isLoading: isOverViewLoading } = useFunderGrantAmountOverview(
    funder_id,
    LAST_N_YEARS,
  );
  const [cutGrantAmountOverview, setCutGrantAmountOverview] =
    useState<GrantAmount[]>(grantAmountOverview);
  const mxEvent = useGibooMixpanel();
  useEffect(() => {
    if (
      grantAmountOverview.length > 0 &&
      grantAmountOverview[grantAmountOverview.length - 1].count === 0
    )
      setCutGrantAmountOverview(grantAmountOverview.slice(0, -1));
    else setCutGrantAmountOverview(grantAmountOverview);
  }, [grantAmountOverview]);
  const { data: forecast, isLoading: isForecastLoading } = useForecasts(
    cutGrantAmountOverview,
    ["sum", "count", "min", "max", "median"],
    3,
    true,
    postProcessing,
    false,
  );
  const isLoading = isFunderLoading || isOverViewLoading || isForecastLoading;
  return (
    <>
      <div className="flex flex-col gap-10">
        <div
          className="flex flex-col gap-4"
          id="chart_view"
          aria-valuetext="total-grant-amount-overview"
        >
          <div className="border-b border-gray-300 ">
            <h5 className="text-base font-semibold underline underline-offset-[6px]">
              Total grant amount overview
            </h5>
          </div>
          {isLoading ? (
            <ChartLoader />
          ) : (
            <VisibilityTracker
              elementIdOrRef={"total-grant-amount-chart-view-1"}
              onVisible={() => {
                // console.log("chart-view: Total grant amount overview");
                mxEvent(MIXPANEL_EVENTS_V2.detail_view.visibility.observed, {
                  value: "Total grant amount overview",
                });
              }}
            >
              <DonorGrantsOverview
                filedYears={filedYears}
                returnCode={funder?.return_code || ""}
                selectedTab={1}
                grantAmountOverview={forecast}
                range_from={range_from}
                range_to={range_to}
                compact={compact}
              />
            </VisibilityTracker>
          )}
        </div>
        <div className="flex flex-col gap-4" id="chart_view" aria-valuetext="grant-range-stream">
          <div className="border-b border-gray-300 ">
            <h5 className="text-base font-semibold underline underline-offset-[6px]">
              Grant range stream
            </h5>
          </div>
          {isLoading ? (
            <ChartLoader />
          ) : (
            <VisibilityTracker
              elementIdOrRef={"total-grant-amount-chart-view-2"}
              onVisible={() => {
                // console.log("chart-view: Grant range stream");
                mxEvent(MIXPANEL_EVENTS_V2.detail_view.visibility.observed, {
                  value: "Grant range stream",
                });
              }}
            >
              <DonorGrantsOverview
                filedYears={filedYears}
                returnCode={funder?.return_code || ""}
                selectedTab={2}
                grantAmountOverview={forecast}
                range_from={range_from}
                range_to={range_to}
                compact={compact}
              />
            </VisibilityTracker>
          )}
        </div>
      </div>
    </>
  );
}
