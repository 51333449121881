import { PastGrantSearchResult, SearchQuery } from "../../types/search";
import usePastGrantSearchResults from "./usePastGrantSearchResults";
import { prettierTaxonomy } from "../../utils/philanthropy";
import { filterUnmatched, matchTaxonomyBySearchQueryWithPhilanthropy } from "../../types/taxonomy";
import { useEffect, useState } from "react";

interface PastGrantSearchResultState {
  data: PastGrantSearchResult | undefined;
  dataWithAllLocation: PastGrantSearchResult | undefined;
  isLoading: boolean;
  error: object;
}
export default function usePastGrantSearchResult(
  id?: string,
  query?: SearchQuery,
): PastGrantSearchResultState {
  const { data, isLoading, error } = usePastGrantSearchResults(id ? [id] : []);
  const [allLocation, setAllLocation] = useState<PastGrantSearchResult | undefined>(
    data && data.length > 0
      ? query
        ? {
            ...data[0],
            ...prettierTaxonomy(
              filterUnmatched(
                matchTaxonomyBySearchQueryWithPhilanthropy(data[0], query, true, true),
                true,
              ),
            ),
          }
        : data[0]
      : undefined,
  );
  const [matched, setMatched] = useState<PastGrantSearchResult | undefined>(
    allLocation
      ? query
        ? { ...allLocation, ...filterUnmatched(allLocation) }
        : allLocation
      : undefined,
  );
  useEffect(() => {
    if (isLoading) return;
    const all =
      data && data.length > 0
        ? query
          ? {
              ...data[0],
              ...prettierTaxonomy(
                filterUnmatched(
                  matchTaxonomyBySearchQueryWithPhilanthropy(data[0], query, true, true),
                  true,
                ),
              ),
            }
          : data[0]
        : undefined;
    setAllLocation(all);
    setMatched(all ? (query ? { ...all, ...filterUnmatched(all) } : all) : undefined);
  }, [data, query, isLoading]);
  return {
    data: matched,
    dataWithAllLocation: allLocation,
    isLoading,
    error,
  };
}
