import React, { useEffect, useState } from "react";
import PopupModal from "../../../../components/tailwind/PopupModal";
import TextInput from "../../../../components/tailwind/TextInput";
import MemoizedDropArea from "../../../../components/input/FileDropArea";
import PlainDropdownSelector from "../../../../components/tailwind/dropdown/PlainDropdownSelector";
import AlertModal from "../../../../components/tailwind/AlertModal";
import useOnboardingData from "../../../../hooks/useOnboarding";
import { uploadFileService } from "../../../../services/file-upload/upload.service";
import MultipleCreatableSelector from "../../../../components/selector/MultipleCreatableSelector";
import GroupBaseMultipleCreatableSelector from "../../../../components/selector/GroupBaseMultipleCreatableSelector";
import CreatableSelector from "../../../../components/selector/CreatableSelector";
import Button from "../../../../components/tailwind/Button";
import { getOtherTaxonomies } from "../../../../services/taxonomy.services";
import { IOrgManualMemberCreateRequest } from "../../../../types/org";
import useOrgManualMembers, {
  createOrgManualMember,
  deleteOrgManualMember,
  updateOrgManualMember,
} from "../../../../hooks/project/useOrgManualMembers";
import { isValidEmail } from "../../../../utils/formatHelper";
import useSWR from "swr";
import useTaxonomyTree from "../../../../hooks/useTaxonomyTree";
import GibooLocationInput from "../../../../components/GibooLocationInput";
import { getOrgMemberImageKey } from "../../../../utils/media";
import useTotalOrganizationMember from "../../../../hooks/useTotalOrganizationMember";
import PlainButton from "../../../../components/PlainButton";
import DropdownSelector from "../../../../components/dropdown/DropdownSelector";
import { ILocation } from "../../../../types/location";
import useUser from "../../../../hooks/useUser";
import { User } from "../../../../app/userSlice";

const EMPSTATUS = ["Full Time", "Part Time"];
const EMP_TYPE: IOption[] = [
  { label: "Employee", value: "employee" },
  { label: "Board member", value: "board" },
  { label: "Volunteers", value: "volunteer" },
];
// const DISABILITY = ["with disabilities", "without disabilities"];
const DISABILITY = [
  { label: "with disabilities", value: "with disabilities" },
  { label: "without disabilities", value: "without disabilities" },
];
export const TITLE_TAXONOMY_ID = "6467c82b784b787ee02f3a08";
export const SEXUAL_ORIENTATION_ID = "65ddf5e1d46737452d9debe4";
export const RELIGION_ID = "65ddf433d46737452d9debe0";
export const BOARD_TITLE_TAXONOMY_ID = "6485b4db7e7f974f5ad296c1";
export const PROFESSION_TAXONOMY_ID = "65ddf440d46737452d9debe2";
export const ROLE_TAXONOMY_ID = "646ca559a1353229613b4372";
export const LANGUAGE_TAXONOMY_ID = "6481dbabd65e0391254d43be";
export const BOARD_PROFESSION_TAXONOMY_ID = "650b58558364165dcf7fc708";

//utils
function getGenerationsAgeRanges() {
  const currentYear = new Date().getFullYear();
  const calculateAgeRange = (start: number, end: number) => {
    const ageStart = currentYear - start;
    const ageEnd = currentYear - end;
    return `${ageEnd}-${ageStart}`;
  };
  const generations = {
    "Silent Generation": calculateAgeRange(1928, 1945),
    "Baby Boomers": calculateAgeRange(1946, 1964),
    "Generation X": calculateAgeRange(1965, 1980),
    Millennials: calculateAgeRange(1981, 1996),
    "Generation Z": calculateAgeRange(1997, 2012),
    "Generation Alpha": "0-10",
  };
  return generations;
}
export const convertGenerationToAge: any = getGenerationsAgeRanges();

interface IOption {
  label: string;
  value: string;
}
interface OrganizationAddEmployee {
  title: string;
  allowEdit: boolean;
  customClass?: string | undefined;
  showAddEmployee: boolean;
  isEdit: boolean;
  employType: "employee" | "volunteer" | "board";
  showEdit?: boolean;
  onClose: () => void;
  emailRequired?: boolean;
  memberEditId?: string;
  initialName?: string;
  showMemberSelect?: boolean;
  hideEditPhoto?: boolean;
  hideDetail?: boolean;
  disableEditEmail?: boolean;
}
export const fetchTaxonomiesAsOption = async (url: string) => {
  if (!url) return new Promise<IOption[]>((resolve) => resolve([]));
  return getOtherTaxonomies(url.split("@")[1])
    .then((data) => data as { name: string }[])
    .then((data) =>
      data
        .map((item) => ({ label: item.name, value: item.name } as IOption))
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
};
const titleClass = "py-2 font-poppins text-base font-semibold text-gray-900";
const inputClass = "!max-h-[48px] !min-h-[48px] !h-[48px]";
export const swrOption = {
  dedupingInterval: 1000 * 3600,
  refreshInterval: 0,
  revalidateIfStale: false,
  keepPreviousData: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}; // 60 mins
function AddNewEmployee({
  showAddEmployee,
  onClose,
  employType = "employee",
  memberEditId,
  initialName,
  emailRequired = false,
  showMemberSelect = false,
  ...props
}: OrganizationAddEmployee) {
  const [onboardingData] = useOnboardingData();
  const { revalidate } = useOrgManualMembers(onboardingData._id);
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>("");
  const [email, setEmail] = useState<string>("");
  const [roleOption, setRoleOption] = useState<IOption[]>([]);
  const [titleOption, setTitleOption] = useState<IOption | undefined>();
  const [empStatus, setEmpStatus] = useState<string>();
  const [professionOption, setProfessionOption] = useState<IOption[]>([]);
  const [boardProfessionOption, setBoardProfessionOption] = useState<IOption[]>([]);
  const [ethnicityOption, setEthnicityOption] = useState<IOption[]>([]);
  const [genderOption, setGenderOption] = useState<IOption | undefined>();
  const [generationOption, setGenerationOption] = useState<IOption>();
  const [sexualOrientationOption, setSexualOrientationOption] = useState<IOption[]>([]);
  const [religionOption, setReligionOption] = useState<IOption[]>([]);
  const [languageOption, setLanguageOption] = useState<IOption[]>([]);
  const [disabilityOption, setDisabilityOption] = useState<IOption | undefined>();
  const [geographic, setGeographic] = useState<ILocation[]>([]);
  const [profilePic, setProfilePic] = useState<File | undefined>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [type, setType] = useState<IOption>();
  const { data: memberEdit, update } = useTotalOrganizationMember(onboardingData._id, memberEditId);
  const { data: TITLES } = useSWR<IOption[]>(
    `taxonomy@${TITLE_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data: BOARD_TITLE } = useSWR<IOption[]>(
    `taxonomy@${BOARD_TITLE_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data: PROFESSION } = useSWR<IOption[]>(
    `taxonomy@${PROFESSION_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data: BOARD_PROFESSION } = useSWR<IOption[]>(
    `taxonomy@${BOARD_PROFESSION_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data: ROLE } = useSWR<IOption[]>(
    `taxonomy@${ROLE_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data: LANGUAGE } = useSWR<IOption[]>(
    `taxonomy@${LANGUAGE_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data: RELIGION } = useSWR<IOption[]>(
    `taxonomy@${RELIGION_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data: SEXUAL_ORIENTAION } = useSWR<IOption[]>(
    `taxonomy@${SEXUAL_ORIENTATION_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data: beneficiary } = useTaxonomyTree("beneficiary");

  const generationExclude = [
    "boys",
    "girls",
    "adults",
    "seniors",
    "young adults",
    "youth",
    "children",
    "infants and toddlers",
  ];
  const GENERATIONS = beneficiary
    .filter(
      (item) => item.parent === "age & generations" && !generationExclude.includes(item.label),
    )
    .map((item) => ({
      label: `${convertGenerationToAge[item.label]} (${item.label})`,
      value: item.label,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  // const GENDER_IDENTITY = beneficiary
  //   .filter((item) => item.parent === "gender" && item.label !== "LGBT community")
  //   .map((item) => ({ label: item.label, value: item.label }))
  //   .sort((a, b) => a.label.localeCompare(b.label));
  const GENDER_IDENTITY = [
    { label: "Man", value: "man" },
    { label: "Woman", value: "woman" },
    { label: "Other", value: "other" },
  ];
  const ethnicGroup = beneficiary.filter((item) => item.parent === "ethnic & cultural groups");
  const ETHNICITY = ethnicGroup.map((item) => ({
    label: item.label,
    options: [
      { label: item.label, value: item.label },
      ...beneficiary
        .filter((subItem) => subItem.parent === item.label)
        .map((subItem) => ({ label: subItem.label, value: subItem.label }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ],
  }));

  useEffect(() => {
    if (memberEdit) {
      const {
        email,
        firstname,
        lastname,
        roles,
        title,
        ethnic,
        gender,
        generation,
        language,
        employ_status,
        religion,
        sexual_orientation,
        profession,
        board_profession_sector,
        disability,
        geographic_focus,
        employ_type,
      } = memberEdit;
      employ_type
        ? setType(EMP_TYPE.find((i) => i.value === employ_type))
        : setType(EMP_TYPE.find((i) => i.value === employType));
      setFirstName(firstname);
      setLastName(lastname);
      setEmail(email || "");
      setRoleOption(roles?.map((item) => ({ label: item, value: item })) || []);
      setTitleOption(title ? { label: title, value: title } : undefined);
      setProfessionOption(profession?.map((item) => ({ label: item, value: item })) || []);
      setBoardProfessionOption(
        board_profession_sector?.map((item) => ({ label: item, value: item })) || [],
      );
      employ_status && setEmpStatus(employ_status);
      setEthnicityOption(ethnic?.map((item) => ({ label: item, value: item })) || []);
      setGenderOption(gender ? { label: gender, value: gender } : undefined);
      const tempGeneration = generation?.[0];
      tempGeneration &&
        setGenerationOption({
          label: `${convertGenerationToAge[tempGeneration]} (${tempGeneration})`,
          value: tempGeneration,
        });
      setSexualOrientationOption(
        sexual_orientation?.map((item) => ({ label: item, value: item })) || [],
      );
      setReligionOption(religion?.map((item) => ({ label: item, value: item })) || []);
      // setDisabilityOption;
      setLanguageOption(language?.map((item) => ({ label: item, value: item })) || []);
      disability && setDisabilityOption({ label: disability, value: disability });
      setGeographic(geographic_focus || []);
    } else {
      reset();
    }
  }, [memberEdit]);
  const renderRow = (content: JSX.Element) => {
    return <div className="flex max-w-full flex-col gap-x-5  md:flex-row md:p-0">{content}</div>;
  };
  const renderAddNewEmployee = () => {
    return (
      <div className="mt-0 flex flex-col gap-y-2">
        {renderRow(
          <>
            <TextInput
              id="fName"
              topPlaceholder="First name*"
              className="!h-[48px]"
              value={firstName}
              placeholder="First name"
              invalid={isDirty && firstName === ""}
              errorMessage={firstName === "" ? "First name required." : ""}
              handleOnChange={(e) => {
                setFirstName(e.target.value);
              }}
              disabled={isLoading}
            />
            <TextInput
              id="lname"
              value={lastName}
              className="!h-[48px]"
              topPlaceholder="Last name*"
              invalid={isDirty && lastName === ""}
              errorMessage={lastName === "" ? "Last name required." : ""}
              placeholder="Last name"
              handleOnChange={(e) => {
                setLastName(e.target.value);
              }}
              disabled={isLoading}
            />
            {!props.hideDetail ? (
              !props.disableEditEmail ? (
                <TextInput
                  id="email"
                  topPlaceholder={`Email address${emailRequired ? "*" : ""}`}
                  value={email}
                  className="!h-[48px]"
                  placeholder="Email address"
                  invalid={
                    isDirty && (emailRequired || email) && !isValidEmail(email) ? true : false
                  }
                  errorMessage={
                    !email && emailRequired
                      ? "Email required."
                      : email && !isValidEmail(email)
                      ? "Invalid Email address"
                      : ""
                  }
                  handleOnChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  disabled={isLoading}
                />
              ) : (
                <div></div>
              )
            ) : null}
          </>,
        )}
        {renderRow(
          <>
            {props.hideDetail && !props.disableEditEmail ? (
              <TextInput
                id="email"
                topPlaceholder={`Email address${emailRequired ? "*" : ""}`}
                value={email}
                className="!h-[48px]"
                placeholder="Email address"
                invalid={isDirty && (emailRequired || email) && !isValidEmail(email) ? true : false}
                errorMessage={
                  !email && emailRequired
                    ? "Email required."
                    : email && !isValidEmail(email)
                    ? "Invalid Email address"
                    : ""
                }
                handleOnChange={(e) => {
                  setEmail(e.target.value);
                }}
                disabled={isLoading}
              />
            ) : null}
            <div className="relative w-full">
              <div className={titleClass}>{"Title*"}</div>
              <CreatableSelector
                id="emp-title"
                placeholder="Choose title"
                className={inputClass}
                inputContainerClass="h-[48px]"
                data={(type?.value === "board" ? BOARD_TITLE : TITLES) || []}
                value={titleOption}
                invalid={isDirty && !titleOption}
                errorMessage={!titleOption ? "Title required." : ""}
                onCreateOption={(val: string) => setTitleOption({ label: val, value: val })}
                onChange={(v) => setTitleOption(v ? v : undefined)}
                disabled={isLoading}
              />
            </div>
            {!props.hideDetail && (
              <>
                {type?.value !== "board" ? (
                  <div className="relative w-full">
                    <div className={titleClass}>
                      {type?.value === "volunteer"
                        ? "Role as a volunteer"
                        : "Role in the organization"}
                    </div>
                    <MultipleCreatableSelector
                      id="role"
                      placeholder="Choose role"
                      className={inputClass}
                      data={ROLE || []}
                      value={roleOption}
                      disabled={isLoading}
                      onCreateOption={(val: string) =>
                        setRoleOption((prev) => [...prev, { value: val, label: val }])
                      }
                      onChange={(v) => setRoleOption(v.map((item) => item))}
                    />
                  </div>
                ) : (
                  <>
                    <div className="relative w-full">
                      <div className={titleClass}>Profession</div>
                      <MultipleCreatableSelector
                        id="profession"
                        placeholder="Choose profession"
                        className={inputClass}
                        data={PROFESSION || []}
                        value={professionOption}
                        onCreateOption={(val: string) =>
                          setProfessionOption((prev) => [...prev, { value: val, label: val }])
                        }
                        onChange={(v) => setProfessionOption(v.map((item) => item))}
                        disabled={isLoading}
                      />
                    </div>
                  </>
                )}
                {type?.value === "employee" && (
                  <PlainDropdownSelector
                    id="emp-status"
                    color="gray"
                    topPlaceholder="Employment status"
                    inputClass="h-[48px]"
                    data={EMPSTATUS}
                    placeholder="Choose employment status"
                    selected={empStatus}
                    setSelected={setEmpStatus}
                    maxHeight={"400px"}
                    downIcon
                    disabled={isLoading}
                  />
                )}
              </>
            )}
          </>,
        )}
        {!props.hideDetail && (
          <>
            {renderRow(
              <>
                <div className="relative w-full">
                  <div className={titleClass}>Ethnicity</div>
                  <GroupBaseMultipleCreatableSelector
                    id="ethnicity"
                    placeholder="Choose ethnicity"
                    data={ETHNICITY}
                    value={ethnicityOption}
                    inputContainerClass={inputClass}
                    onCreateOption={(val: string) =>
                      setEthnicityOption((prev) => [...prev, { value: val, label: val }])
                    }
                    onChange={(v) => setEthnicityOption(v.map((item) => item))}
                    disabled={isLoading}
                  />
                </div>
                <div className="relative w-full">
                  <div className={titleClass}>Gender identity</div>
                  <CreatableSelector
                    id="gender_identity"
                    placeholder="Choose gender identity"
                    className={inputClass}
                    inputContainerClass="h-[48px]"
                    data={GENDER_IDENTITY}
                    value={genderOption}
                    onCreateOption={(val: string) => setGenderOption({ label: val, value: val })}
                    onChange={(v) => setGenderOption(v ? v : undefined)}
                    disabled={isLoading}
                  />
                </div>
                <div className="relative w-full">
                  <div className={titleClass}>Age</div>
                  <DropdownSelector<IOption>
                    id={`input-generation`}
                    defaultValue={generationOption}
                    selected={generationOption}
                    placeholder="Choose age"
                    inputClass="!h-[48px] !w-[290px]"
                    setSelected={(v) => {
                      setGenerationOption(v ? v : undefined);
                    }}
                    renderButton={(i) => i.label}
                    compact
                    data={GENERATIONS}
                    getKey={(i) => (i ? i.label : "")}
                    renderItem={(i) => (
                      <div className="mb-[1px] py-2 font-normal text-black">{i.label}</div>
                    )}
                    isClearable
                    downIcon
                  />
                </div>
              </>,
            )}
            {renderRow(
              <>
                <div className="relative w-full">
                  <div className={titleClass}>Sexual orientation</div>
                  <MultipleCreatableSelector
                    id="sexual-orientation"
                    placeholder="Choose sexual orientation"
                    className={inputClass}
                    data={SEXUAL_ORIENTAION || []}
                    value={sexualOrientationOption}
                    onCreateOption={(val: string) =>
                      setSexualOrientationOption((prev) => [...prev, { value: val, label: val }])
                    }
                    onChange={(v) => setSexualOrientationOption(v.map((item) => item))}
                    disabled={isLoading}
                  />
                </div>
                <div className="relative w-full">
                  <div className={titleClass}>Disability</div>
                  <DropdownSelector<IOption>
                    id={`disability`}
                    defaultValue={disabilityOption}
                    selected={disabilityOption}
                    placeholder="Choose disability"
                    inputClass="!h-[48px] !w-[290px]"
                    setSelected={(v) => {
                      setDisabilityOption(v ? v : undefined);
                    }}
                    renderButton={(i) => i.label}
                    compact
                    data={DISABILITY}
                    getKey={(i) => (i ? i.label : "")}
                    renderItem={(i) => (
                      <div className="mb-[1px] py-2 font-normal text-black">{i.label}</div>
                    )}
                    isClearable
                    downIcon
                  />
                </div>
                <div className="relative w-full">
                  <div className={titleClass}>Religion</div>
                  <MultipleCreatableSelector
                    id="religion"
                    placeholder="Choose religion"
                    className={inputClass}
                    data={RELIGION || []}
                    value={religionOption}
                    onCreateOption={(val: string) =>
                      setReligionOption((prev) => [...prev, { value: val, label: val }])
                    }
                    onChange={(v) => setReligionOption(v.map((item) => item))}
                    disabled={isLoading}
                  />
                </div>
              </>,
            )}
            {renderRow(
              <>
                {type?.value === "board" && (
                  <div className="relative w-full">
                    <div className={titleClass}>Profession background</div>
                    <MultipleCreatableSelector
                      id="board-profession-background"
                      placeholder="Choose profession background"
                      className={inputClass}
                      data={BOARD_PROFESSION || []}
                      value={boardProfessionOption}
                      onCreateOption={(val: string) =>
                        setBoardProfessionOption((prev) => [...prev, { value: val, label: val }])
                      }
                      onChange={(v) => setBoardProfessionOption(v.map((item) => item))}
                      disabled={isLoading}
                    />
                  </div>
                )}
                <div className="relative w-full">
                  <div className={titleClass}>Geographical locations</div>
                  <GibooLocationInput
                    id="geographic"
                    value={geographic}
                    setValue={setGeographic}
                    icon
                    hideNone
                    matched
                    float
                    disabled={isLoading}
                    specificLevel="state"
                  />
                </div>
                <div className="relative w-full">
                  <div className={titleClass}>Language</div>
                  <MultipleCreatableSelector
                    id="language"
                    placeholder="Choose language"
                    className="!h-[48px] !max-h-[48px] !min-h-[48px]"
                    data={LANGUAGE || []}
                    value={languageOption}
                    onCreateOption={(val: string) =>
                      setLanguageOption((prev) => [...prev, { value: val, label: val }])
                    }
                    onChange={(v) => setLanguageOption(v.map((item) => item))}
                    disabled={isLoading}
                  />
                </div>
              </>,
            )}
            {!props.hideEditPhoto && (
              <>
                <div className={titleClass}>Upload profile photo</div>
                <div className="my-2 w-full md:w-1/2">
                  <MemoizedDropArea
                    id={"img file"}
                    disabled={isLoading}
                    formats={[".png", ".jpg", ".jpeg"]}
                    onRemove={() => {
                      setProfilePic(undefined);
                    }}
                    onDrop={function (arg: File): void {
                      setProfilePic(arg);
                      // console.log("file dropped", arg);
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const reset = () => {
    setFirstName(initialName || "");
    setLastName("");
    setEmail("");
    setRoleOption([]);
    setTitleOption(undefined);
    setEmpStatus("");
    setEthnicityOption([]);
    setGenderOption(undefined);
    setGenerationOption(undefined);
    setSexualOrientationOption([]);
    setReligionOption([]);
    setLanguageOption([]);
    setProfessionOption([]);
    setBoardProfessionOption([]);
    setDisabilityOption(undefined);
    setProfilePic(undefined);
  };

  const saveMember = (data: IOrgManualMemberCreateRequest) => {
    if (memberEdit?._id) {
      (profilePic
        ? uploadFileService(profilePic, getOrgMemberImageKey(memberEdit._id), ["image"])
            .then((res) => {
              return { ...data, photo_object_key: String(res) };
            })
            .catch((err) => {
              // console.log(err);
            })
        : new Promise<IOrgManualMemberCreateRequest>((resolve) => resolve(data))
      ).then(
        (data) =>
          data &&
          update(data)
            .catch((err) => {
              // console.log(err);
            })
            .finally(() => {
              setIsLoading(false);
              reset();
              onClose();
            }),
      );
    } else {
      createOrgManualMember(onboardingData._id, data)
        .then((res) => {
          if (res && profilePic) {
            return uploadFileService(profilePic, getOrgMemberImageKey(res._id), ["image"]).then(
              (photo_object_key) =>
                updateOrgManualMember(onboardingData._id, res._id, {
                  photo_object_key: String(photo_object_key),
                }),
            );
          }
        })
        .then((res) => revalidate())
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          revalidate();
          setIsLoading(false);
          reset();
          onClose();
        });
    }
  };
  const handleSave = () => {
    const isValid = hasValidDetail();
    if (isValid === false) {
      setIsDirty(true);
      return;
    } else {
      setIsDirty(false);
      setIsLoading(true);
      const profession = professionOption.map((item) => item.value);
      const boardProfession = boardProfessionOption.map((item) => item.value);
      const role = roleOption.map((item) => item.value);
      const title = titleOption?.value || "";
      const ethnicity = ethnicityOption.map((item) => item.value);
      const gender = genderOption?.value || "";
      const generation = generationOption;
      const sexualOrientation = sexualOrientationOption.map((item) => item.value);
      const religion = religionOption.map((item) => item.value);
      const language = languageOption.map((item) => item.value);
      const employee_type = type?.value;
      const data = {
        employ_type: employee_type,
        firstname: firstName,
        lastname: lastName,
        email: email === "" ? null : email,
        roles: role ? [...role] : [],
        title: title,
        employ_status: empStatus,
        profession: profession ? [...profession] : [],
        board_profession_sector: boardProfession ? [...boardProfession] : [],
        ethnic: ethnicity ? [...ethnicity] : [],
        gender: gender,
        disability: disabilityOption?.value,
        generation: generation ? [generation.value] : [],
        sexual_orientation: sexualOrientation ? [...sexualOrientation] : [],
        religion: religion ? [...religion] : [],
        language: language ? [...language] : [],
        geographic_focus: geographic,
      } as IOrgManualMemberCreateRequest;

      if (type?.value !== "board") {
        data["profession"] = [];
        data["board_profession_sector"] = [];
      } else {
        data["roles"] = [];
      }
      if (type?.value !== "employee") {
        data["employ_status"] = undefined;
      }

      saveMember(data);
    }
  };

  const hasValidDetail = () => {
    if (emailRequired && !email && isValidEmail(email)) return false;
    return (
      firstName !== "" && lastName !== "" && titleOption !== undefined
      // role.length !== 0 &&
    );
  };
  const handleDeleteUser = () => {
    if (!memberEdit?._id) return;
    setIsDeleting(true);
    deleteOrgManualMember(onboardingData._id, memberEdit._id)
      .then(() => {
        revalidate();
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setShowAlert(false);
        setIsDeleting(false);
        reset();
        onClose();
      });
  };
  return (
    <>
      {showAlert && (
        <AlertModal
          variant="purple"
          handleCancel={() => {
            setShowAlert(false);
          }}
          handleConfirm={() => {
            handleDeleteUser();
          }}
          isLoading={isDeleting}
          confirmTitle={"Remove"}
          title="Remove an employee"
          content="Are you sure you want to remove this employee?"
          isOpen={showAlert}
          handleClose={() => setShowAlert(false)}
        />
      )}
      <PopupModal
        isOpen={showAddEmployee}
        handleClose={() => onClose()}
        title={props.title}
        wrapperClass="max-w-[980px] md:!px-0 "
        contentClass="!px-[24px] max-h-[600px] overflow-y-scroll"
        {...(memberEdit?.type === "manual"
          ? {
              LeftActionButton: () => {
                let msg = "Delete this ";
                if (type?.value === "employee") {
                  msg = msg + "employee";
                } else if (type?.value === "volunteer") {
                  msg = msg + "volunteer";
                } else {
                  msg = msg + "board member";
                }
                if (!memberEdit) return <div />;
                return (
                  <div
                    className="mx-[24px] cursor-pointer font-poppins text-base font-normal text-gray-700 underline"
                    onClick={() => setShowAlert(true)}
                  >
                    {msg}
                  </div>
                );
              },
            }
          : {})}
        RightActionButton={() => (
          <div className="inline-flex items-center gap-6">
            <PlainButton
              id="btn-back"
              label="Back"
              className="text-sm !text-gray-600"
              leftIconClass="fa-solid fa-arrow-left !text-gray-600"
              handleOnClick={() => onClose()}
            />
            <Button
              className="!w-[170px]"
              id="save-btn"
              handleOnClick={() => handleSave()}
              loading={isLoading}
              label="Save"
            />
          </div>
        )}
      >
        <>
          {(initialName || showMemberSelect) &&
            renderRow(
              <>
                <div className="mt-1 flex w-full">
                  <DropdownSelector<IOption>
                    id={`input-member-type-selector`}
                    defaultValue={type}
                    selected={type}
                    inputClass="!h-[48px] !w-[290px]"
                    topPlaceholder="Member type"
                    setSelected={(v) => {
                      setType(v ? v : undefined);
                    }}
                    renderButton={(i) => i.label}
                    compact
                    data={EMP_TYPE}
                    getKey={(i) => (i ? i.label : "")}
                    renderItem={(i) => (
                      <div className="mb-[1px] py-2 font-normal text-black">{i.label}</div>
                    )}
                    isClearable
                    downIcon
                  />
                </div>
                <div className="w-full" />
                <div className="w-full" />
              </>,
            )}
          <div className="mb-10 mt-2">{renderAddNewEmployee()}</div>
        </>
      </PopupModal>
    </>
  );
}
AddNewEmployee.defaultProps = {
  allowEdit: false,
  showAddEmployee: false,
};
export default AddNewEmployee;
