import { CredentialResponse, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import classNames from "classnames";
import { FormEvent, createRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import config from "../api";
import { openSigninSignup, signupUserState } from "../app/recoilStore";
import { User, defaultUser } from "../app/userSlice";
import IMAGE_TRAIL_END from "../assets/images/trial-end.svg";
import useRateLimitOverview from "../hooks/internal_tool/useRateLimitOverview";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import useLocalStorage from "../hooks/useLocalstorage";
import useOnboardingData from "../hooks/useOnboarding";
import useUser from "../hooks/useUser";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import parseError from "../pages/errorParser";
import { isLengthOk } from "../pages/SignupView";
import { userService } from "../services/user.services";
import Checkbox from "./checkbox/Checkbox";
import CloseButton from "./CloseButton";
import LeftInnerIconInput from "./LeftInnerIconInput";
import PasswordInput from "./PasswordInput";
import PrimaryButton from "./PrimaryButton";
import Spacer from "./Spacer";
import Button from "./tailwind/Button";
import PopupModal from "./tailwind/PopupModal";
import TextInput from "./tailwind/TextInput";
export type GlobalSignInSignUp =
  | "SIGNUP"
  | "SIGNIN"
  | "TRIAL_END"
  | "CLAIM_PROFILE"
  | "EDIT_SUGGEST"
  | "SEARCH";
export default function SignInModal({
  showDefault,
  reset,
}: {
  showDefault?: boolean;
  reset?: () => void;
}) {
  const [onboardingData] = useOnboardingData();
  const [modalState, setModalState] = useRecoilState(openSigninSignup);
  const { value: joinPath, update: updateJoinPath } = useLocalStorage("JOIN_PATH");
  const { revalidate } = useRateLimitOverview();
  const browserLocation = useLocation();
  const resetLimits = () => {
    return axios
      .delete(process.env.REACT_APP_API_URL + `/api/v2/rate/ip`, config)
      .then(() => revalidate());
  };
  useEffect(() => {
    showDefault && setModalState({ show: showDefault });
  }, []);
  useEffect(() => {
    if (
      browserLocation.pathname.startsWith("/npos/") &&
      onboardingData.npo_id === browserLocation.pathname.slice(6, 30)
    ) {
      updateJoinPath("/organization");
    } else {
      updateJoinPath(`${browserLocation.pathname}${browserLocation.search}`);
    }
  }, [onboardingData]);
  return (
    <PopupModal
      isOpen={showDefault || modalState.show}
      allowBackdropClose={false}
      handleClose={() => {
        setModalState({ show: false });
      }}
      wrapperClass={classNames(
        "!min-w-[586px] max-w-[586px] md:!px-0 ",
        modalState.type === "TRIAL_END" && "!max-w-[760px]",
      )}
      contentClass={classNames(modalState.type === "TRIAL_END" ? "!p-0" : "!p-5")}
      showHeader={false}
      showActionButtons={false}
      blurBackdrop
    >
      {(!showDefault || reset) && !modalState.disable && modalState.type !== "TRIAL_END" && (
        <div className="flex w-full items-center justify-end py-[10px]">
          <CloseButton
            onClick={() => {
              setModalState({ show: false });
              reset?.();
            }}
            className="cursor-pointer"
          />
        </div>
      )}
      {modalState.type == "TRIAL_END" ? (
        <TrialEndModal
          onPlanSelect={() => {
            alert("coming soon");
          }}
        />
      ) : (
        <SignInSignUpGlobal reset={resetLimits} />
      )}
    </PopupModal>
  );
}

function SignUpContent({
  goBack,
  email,
  reset,
  onSignUpPassword,
}: {
  goBack?: () => void;
  email: string;
  reset: () => Promise<any>;
  onSignUpPassword: () => void;
}) {
  const mxEvent = useGibooMixpanel("join_modal");
  // signup
  const [params] = useSearchParams();
  const [user, setUser] = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGoogleSignup, setLoadingGoogleSignup] = useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [invalidPassword, setInvalidPassword] = useState<boolean>(false);
  const [pwLengthOk, setPwLengthOk] = useState(false);
  const [err, setErr] = useState();
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const setSignUpUser = useSetRecoilState(signupUserState);
  const refbtn = createRef<HTMLButtonElement>();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordVerify = (passwd: string) => {
    setPassword(passwd);
    setPwLengthOk(isLengthOk(passwd));
  };

  const isReadySubmit = () => {
    return email !== "" && firstname !== "" && lastname !== "" && pwLengthOk && checked;
  };

  const emailVerify = async (credentials: any) => {
    try {
      const req = {
        origin: window.location.origin,
        email: credentials.email,
        firstname: credentials.firstname,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v2/auth/send_email_verify",
        req,
        config,
      );
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };
  const addUser = async (user: any) => {
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v2/users",
        { ...user, access_from: document?.referrer },
        config,
      );
      // console.log("ok", response);
      setSignUpUser(response.data);
      if (process.env.REACT_APP_USE_INVITATION_CODE === "TRUE") {
        onSignUpPassword();
      } else {
        setUser(response.data);
        emailVerify(user);
        navigate("/onboarding", { replace: true });
        // navigate("/pending", { replace: true });
      }
    } catch (error: any) {
      // console.log("error", error);
      setSignUpUser(defaultUser);
      if (error.response.status === 409) {
        setErr(parseError("The account already exists"));
        setInvalidEmail(true);
        mxEvent(MIXPANEL_EVENTS_V2.auth.signup.failed, {
          sigininMethod: "password",
          email: email,
          error: "tried to signup with an existing email",
        });
      } else if (parseError(error) === "value is not a valid email address") {
        setErr(parseError("Email address is invalid"));
        setInvalidEmail(true);
        mxEvent(MIXPANEL_EVENTS_V2.auth.signup.failed, {
          sigininMethod: "password",
          email: email,
          error: "wrong email",
        });
      } else {
        setErr(parseError(error));
      }
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (e: FormEvent) => {
    if (loading) return;
    e.preventDefault();
    if (password === password2) {
      await addUser({
        email: email,
        firstname: firstname,
        lastname: lastname,
        role: "",
        password: password,
      }).then(() => {
        mxEvent(MIXPANEL_EVENTS_V2.auth.signup.successful, {
          sigininMethod: "password",
          email: email,
        });
      });
    } else {
      setInvalidPassword(true);
      setErr(parseError("Password do not match"));
    }
  };

  // useGoogleOneTapLogin({ onSuccess: onSuccessGoogle, onError: onFail });

  return (
    <div className="mt-4 flex flex-col gap-4">
      <form className="flex flex-wrap gap-y-4">
        <div className="flex w-full flex-col gap-[13px] md:flex-row">
          <div className="w-full md:w-1/2">
            <LeftInnerIconInput
              id="input-first-name"
              placeholder="First name*"
              className="!h-[45px]"
              handleOnChange={(e) => setFirstname(e.target.value)}
              handleOnKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (refbtn.current?.disabled) {
                    e.preventDefault();
                  } else {
                    // console.log(refbtn.current);
                    refbtn.current?.focus();
                  }
                }
              }}
              // invalid={err !== undefined}
            />
          </div>
          <div className="w-full md:w-1/2">
            <LeftInnerIconInput
              id="input-last-name"
              placeholder="Last name*"
              className="!h-[45px]"
              handleOnChange={(e) => setLastname(e.target.value)}
              handleOnKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (refbtn.current?.disabled) {
                    e.preventDefault();
                  } else {
                    // console.log(refbtn.current);
                    refbtn.current?.focus();
                  }
                }
              }}
              // invalid={err !== undefined}
            />
          </div>
        </div>
        <div className="w-full">
          <PasswordInput
            id="input-password"
            placeholder="Enter your password*"
            className="!h-[45px]"
            handleOnChange={(e) => {
              passwordVerify(e.target.value);
              setInvalidPassword(false);
            }}
            handleOnKeyDown={(e) => {
              if (e.key === "Enter") {
                if (refbtn.current?.disabled) {
                  e.preventDefault();
                } else {
                  refbtn.current?.focus();
                }
              }
            }}
            invalid={invalidPassword}
          />
        </div>
      </form>
      <div className="w-full">
        <PasswordInput
          id="input-password2"
          placeholder="Confirm your password*"
          className="!h-[45px]"
          handleOnChange={(e) => {
            setPassword2(e.target.value);
            setInvalidPassword(false);
          }}
          handleOnKeyDown={(e) => {
            if (e.key === "Enter") {
              if (refbtn.current?.disabled) {
                e.preventDefault();
              } else {
                refbtn.current?.focus();
              }
            }
          }}
          invalid={invalidPassword}
        />
      </div>
      {!pwLengthOk ? (
        <div className="w-full">
          <ul className="subtitle list-disc" style={{ paddingLeft: "1.5em", marginBottom: 0 }}>
            {!pwLengthOk ? (
              <li className={classNames("text-sm", pwLengthOk && "text-purple-500")}>
                At least 8 or longer
              </li>
            ) : null}
            {/* <li className={classNames("text-sm", pwCaseOk && "text-purple-500")}>
                Lower case letter, upper case letter
              </li> */}
            {/* <li className={classNames("text-sm", pwSymbolOk && "text-purple-500")}>
                Number and symbol
              </li> */}
          </ul>
        </div>
      ) : null}
      {err && (
        <div className="flex justify-start">
          <h5 className="text-purple-500">{err}</h5>
        </div>
      )}
      <div className="my-3 flex justify-start">
        <div className="form-check ms-0 flex items-center ps-0">
          <Checkbox id="input-checkbox" checked={checked} onChange={() => setChecked(!checked)} />
          <label className="form-check-label !text-gray-600" style={{ marginLeft: "12px" }}>
            I agree to the
            <a
              href="/terms"
              target="_blank"
              rel="noreferrer"
              className="px-1 !font-normal !text-gray-600 !underline"
            >
              Terms of Service
            </a>
            <span className="text-[#9B9B9B]">and</span>
            <a
              target="_blank"
              className="px-1 !font-normal !text-gray-600 !underline"
              href="/privacy"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </label>
        </div>
      </div>
      <div className="flex justify-center">
        <PrimaryButton
          id="btn-next"
          ref={refbtn}
          size="lg"
          loading={loading}
          disabled={!isReadySubmit()}
          handleOnClick={handleSubmit}
          label="Sign up"
          className="!h-[45px]"
          style={{ width: "100%" }}
        />
      </div>
      <div className="mt-3 flex justify-center">
        <p className="giboo-p-subtitle text-center">
          Already have an account?{" "}
          <span
            onClick={() => goBack?.()}
            className="cursor-pointer !font-light !text-purple-900 !underline"
          >
            Sign in
          </span>
        </p>
      </div>
    </div>
  );
}
function SignInSignUpGlobal({ reset }: { reset: () => Promise<any> }) {
  const mxEvent = useGibooMixpanel("join_modal");
  const [params] = useSearchParams();
  const [context, setContext] = useState<string>("");
  const [user, setUser] = useUser();
  const [signupUser, setSignUpUser] = useRecoilState(signupUserState);
  const [email, setEmail] = useState<string>(params.get("email") || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGoogleSignin, setLoadingGoogleSignin] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [invalid, setInvalid] = useState<boolean>(false);
  const [err, setErr] = useState();
  const navigate = useNavigate();
  const refbtn = createRef<HTMLButtonElement>();
  const [step, setStep] = useState(1);
  const [isExistingUser, setIsExistingUser] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [modalState] = useRecoilState(openSigninSignup);
  const [code, setCode] = useState("");

  const onSuccess = (user: User) => {
    reset().finally(() => {
      if (process.env.REACT_APP_USE_INVITATION_CODE === "TRUE") {
        if (user.created || !user.pass_invitation_code) {
          setSignUpUser(user);
          setContext("google");
          setStep(3);
        } else {
          setUser(user);
          navigate("/", { replace: true });
        }
      } else {
        setUser(user);
        navigate("/", { replace: true });
      }
    });
  };
  const loginUser = (credentials: { email: string; password: string }) => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_URL + "/api/v2/auth/login", credentials, config)
      .then((response) => {
        setUser(user);
        navigate("/", { replace: true });
        mxEvent(MIXPANEL_EVENTS_V2.auth.signin.successful, {
          sigininMethod: "password",
          email: email,
        });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 404) {
          setErr(parseError("The email or password you entered is incorrect"));
        } else {
          setErr(parseError(error));
        }
        mxEvent(MIXPANEL_EVENTS_V2.auth.signin.failed, {
          sigininMethod: "password",
          email: email,
          error:
            error.response.status === 403 || error.response.status === 404
              ? "wrong email or password"
              : "api error",
        });
        setInvalid(true);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e: FormEvent) => {
    if (loading) return;
    e.preventDefault();
    loginUser({
      email: email.toLowerCase(),
      password,
    });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      if (process.env.REACT_APP_DISABLE_GOOGLE_SIGNUP === "TRUE") return;
      setLoadingGoogleSignin(true);
      axios
        .post(
          process.env.REACT_APP_API_URL + "/api/v2/oauth/google/token",
          {
            token: codeResponse.access_token,
            no_invitation: true,
            access_from: document?.referrer,
          },
          config,
        )
        .then((response) => {
          onSuccess({ ...response.data, token: codeResponse.access_token });
          mxEvent(MIXPANEL_EVENTS_V2.auth.signin.successful, {
            sigininMethod: "google",
            email: response.data.email,
          });
        })
        .catch((error) => {
          setErr(parseError(error));
          mxEvent(MIXPANEL_EVENTS_V2.auth.signin.failed, {
            sigininMethod: "google",
            error: "api error",
          });
        })
        .finally(() => setLoadingGoogleSignin(false));
    },
    onError: (error) => {
      // console.log("Login Failed:", error);
      setLoadingGoogleSignin(false);
    },
    flow: "implicit",
  });

  const onSuccessGoogle = (credentialResponse: CredentialResponse) => {
    if (process.env.REACT_APP_DISABLE_GOOGLE_SIGNUP === "TRUE") return;
    setLoading(true);
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/api/v2/oauth/google",
          {
            token: credentialResponse.credential,
            no_invitation: true,
            access_from: document?.referrer,
          },
          config,
        )
        .then((response) => onSuccess({ ...response.data, token: credentialResponse.credential }))
        .catch((error) => setErr(parseError(error)))
        .finally(() => setLoading(false));
    } catch (error) {
      setErr(parseError(error));
    }
  };

  const emailVerify = async (credentials: any) => {
    try {
      const req = {
        origin: window.location.origin,
        email: credentials.email,
        firstname: credentials.firstname,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v2/auth/send_email_verify",
        req,
        config,
      );
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };

  const verifyCode = async (user: any) => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `/api/v2/auth/invitation/beta?code=${code}&email=${user.email}` +
          (context === "google" && user.idp_google ? `&idp_google=${user.idp_google}` : ""),
        config,
      );
      // console.log("ok", response);
      if (context === "google") {
        // console.log("!!!!!!!!!", user);
        setUser(user);
        navigate("/", { replace: true });
      } else {
        setUser(response.data);
        emailVerify(user);
        navigate("/onboarding", { replace: true });
        // navigate("/pending", { replace: true });
      }
    } catch (error: any) {
      // console.log("error", error);
      if (error.response.status === 404) {
        setErr(parseError("Invalid invitation code"));
      } else {
        setErr(parseError(error));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitCode = async (e: FormEvent) => {
    e.preventDefault();
    if (signupUser?.email && signupUser.email !== "" && code !== "") {
      const obj = JSON.parse(JSON.stringify(signupUser));
      obj["code"] = code;
      await verifyCode(obj);
    } else {
      setErr(parseError("Invalid email or code"));
    }
  };
  const onFail = () => {
    setErr(parseError("Sorry"));
  };
  const seeingList = location.pathname.startsWith("/search") ? true : false;
  const renderInfo = () => {
    return (
      <div className="mx-auto flex flex-col items-center gap-4">
        <h5
          className={classNames(
            seeingList ? "text-lg" : "text-xl",
            "font-poppins font-semibold text-black",
          )}
        >
          {modalState.type === "CLAIM_PROFILE"
            ? "Please sign up to claim your profile"
            : modalState.type === "EDIT_SUGGEST"
            ? "Please sign up to suggest edits"
            : seeingList
            ? "Sign up for free to see more potential opportunities"
            : "Ready to take the next step?"}
        </h5>
        <div className="w-full bg-gray-50 px-1 py-2">
          <h6 className="text-center font-poppins text-sm text-gray-700">
            Begin by creating an account to enjoy a <b className="text-purple-500">free trial</b>,{" "}
            <br /> or simply sign in if you&apos;re already a member.
          </h6>
        </div>
      </div>
    );
  };
  const renderStep1 = () => {
    return (
      <div className="px-7 pb-5">
        {renderInfo()}
        <div className="mt-7 max-w-[480px] font-poppins text-xs text-gray-600">
          By creating an account or signing in, you understand and agree to Giboo’s Terms. You also
          acknowledge our Cookie and Privacy policies.
        </div>
        <div className="mt-7 flex justify-center">
          <Button
            id="btn-google"
            className="!text-gray-900"
            size="lg"
            style={{ width: "100%" }}
            handleOnClick={() => {
              handleGoogleLogin();
            }}
            spinnerColor="purple"
            loading={loadingGoogleSignin}
            outline
            prependIcon={
              loadingGoogleSignin ? (
                ""
              ) : (
                <i className={`fa-brands fa-google fa-lg  ${loading ? "gray" : "purple"}`} />
              )
            }
            label="Continue with Google"
            labelClass="pl-2 !text-base"
            disabled={loading}
          />
        </div>
        <div className="relative my-7">
          <div className=" relative h-[1px] w-full bg-gray-500" />
          <div className="absolute text-center" style={{ marginTop: -12, left: "48%" }}>
            <span className="subtitle bg-white px-2">or</span>
          </div>
        </div>
        <form className="my-7 flex flex-wrap gap-y-3">
          <div className="w-full">
            <TextInput
              id="input-email"
              topPlaceholder="Email address*"
              topLabelClass="text-sm font-medium pt-0"
              className="!h-[45px]"
              value={email}
              placeholder="Enter email*"
              handleOnChange={(e) => {
                setEmail(e.target.value);
                setInvalid(false);
              }}
              handleOnKeyDown={(e) => {
                if (e.key === "Enter") {
                  refbtn.current?.focus();
                }
              }}
              invalid={invalid}
            />
          </div>
        </form>
        <div className="flex justify-center">
          <PrimaryButton
            id="btn-next"
            ref={refbtn}
            size="lg"
            handleOnClick={() => {
              setIsChecking(true);
              userService
                .isEmailExist(email)
                .then((res) => {
                  setIsExistingUser(res ? true : false);
                  setStep(2);
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  setIsChecking(false);
                });
              // setIsExistingUser(false);
            }}
            loading={isChecking}
            label="Continue"
            rightIconClass={<i className="gi-right-arrow gi" />}
            style={{ width: "100%" }}
            disabled={!email}
          />
        </div>
      </div>
    );
  };
  // useGoogleOneTapLogin({ onSuccess: onSuccessGoogle, onError: onFail });
  const renderStep2 = () => {
    return (
      <div className="px-7 pb-5">
        {renderInfo()}
        <div className="mt-7 flex flex-col gap-4">
          <div
            onClick={() => {
              setStep(1);
              setEmail("");
            }}
            className="inline-flex w-fit cursor-pointer items-center gap-2 font-poppins text-sm text-gray-600"
          >
            <i className="gi-left-arrow gi" />
            <p>Back</p>
          </div>
          <div className="font-poppins text-sm text-black">Email: {email}</div>
        </div>
        {isExistingUser ? (
          <>
            <div className="mb-4 mt-7 w-full">
              <PasswordInput
                id="input-password"
                className="!h-[45px]"
                placeholder="Enter your password*"
                handleOnChange={(e) => {
                  setPassword(e.target.value);
                  setInvalid(false);
                }}
                handleOnKeyDown={(e) => {
                  if (e.key === "Enter") {
                    refbtn.current?.focus();
                  }
                }}
                invalid={invalid}
              />
            </div>
            <div className="flex justify-center pt-3">
              <PrimaryButton
                id="btn-next"
                ref={refbtn}
                size="lg"
                handleOnClick={handleSubmit}
                loading={loading}
                label="Log in"
                style={{ width: "100%" }}
                disabled={email === "" || password === ""}
              />
            </div>
          </>
        ) : (
          <SignUpContent
            email={email}
            goBack={() => {
              setStep(1);
              setEmail("");
            }}
            onSignUpPassword={() => {
              setContext("");
              setStep(3);
            }}
            reset={reset}
          />
        )}
      </div>
    );
  };

  const renderStepInvitation = () => {
    return (
      <div className="px-7 pb-5">
        <div className="w-full py-1">
          <h3>Welcome to Giboo</h3>
          <p className="gray-700 pt-8">Thank you for your interest in joining the Giboo service.</p>
          <p className="gray-700 pt-8">
            We are currently offering a closed beta service exclusively for invited nonprofit users.
            Our one-to-one approach allows us to work closely with our customers and provide the
            best possible experience.
          </p>
          <p className="gray-700 pt-8">
            We are here to support all amazing nonprofit organizations that want to raise funds. We
            have successfully received your registration information and will get back to you within
            one to two days! If you have invitation code, please enter here to start.
          </p>
          <p className="pt-8" style={{ color: "#9b9b9b" }}>
            Invitation code
          </p>
          <form className="flex flex-wrap gap-y-4 pt-2">
            <div className="flex w-full flex-col gap-x-3 md:flex-row">
              <div className="w-full">
                <LeftInnerIconInput
                  id="input-invitation-code"
                  placeholder="Enter invitation code"
                  className="!h-[48px]"
                  handleOnChange={(e) => setCode(e.target.value)}
                  handleOnKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (refbtn.current?.disabled) {
                        e.preventDefault();
                      } else {
                        // console.log(refbtn.current);
                        refbtn.current?.focus();
                      }
                    }
                  }}
                  // invalid={err !== undefined}
                />
              </div>
            </div>
          </form>
          <div className="flex justify-start pt-1">
            <h5 className="text-purple-500">{err}</h5>
          </div>
          <div className="flex justify-end pt-5">
            <PrimaryButton
              id="btn-next"
              size="lg"
              ref={refbtn}
              // tonal={true}
              disabled={!(code !== "") || loading}
              handleOnClick={handleSubmitCode}
              label="Enter"
              style={{ width: "33%" }}
              // rightIconClass="fa-solid fa-arrow-right"
            />
          </div>
        </div>
      </div>
    );
  };
  if (process.env.REACT_APP_USE_INVITATION_CODE === "TRUE")
    return <>{step === 1 ? renderStep1() : step === 2 ? renderStep2() : renderStepInvitation()}</>;
  else return <>{step === 1 ? renderStep1() : renderStep2()}</>;
}

function TrialEndModal({ onPlanSelect }: { onPlanSelect: () => void }) {
  return (
    <>
      <div className="p-0">
        <div className="w-full">
          <img src={IMAGE_TRAIL_END} className="h-fit w-fit" />
        </div>
        <div className="flex flex-col items-center justify-center px-7 py-10">
          <div className="flex max-w-[369px] flex-col items-center justify-center gap-4">
            <h5 className="text-center text-xl font-semibold text-black">
              Your 14-day trial has ended
            </h5>
            <h5 className="text-center text-sm text-gray-700">
              This is only the start of our journey together.
              <br /> Select a plan, add your billing info, and you’re all set!
            </h5>
          </div>
          <Spacer height="40px" />
          <Button
            id="plan-select"
            label="Select your plan"
            handleOnClick={() => {
              onPlanSelect();
            }}
          />
        </div>
      </div>
    </>
  );
}
