import { useEffect, useState } from "react";
import useSWR from "swr";
import { IOption } from "../components/tailwind/AsyncCreatableSelector";
import {
  PROFESSION_TAXONOMY_ID,
  RELIGION_ID,
  SEXUAL_ORIENTATION_ID,
  fetchTaxonomiesAsOption,
  swrOption,
} from "../pages/npo/organizational-profile/component/AddNewEmployee";

type IUseDropdownOption = () => {
  loading: boolean;
  sexualOrientationOption: IOption[];
  religionOption: IOption[];
  professionOption: IOption[];
};
const useDropdownOption: IUseDropdownOption = () => {
  const secondaryOption = { label: "Do not wish to answer", value: "do_not_wish_to_answer" };
  // sexual orientation
  const { data: data1, isLoading: loading1 } = useSWR<IOption[]>(
    `taxonomy@${SEXUAL_ORIENTATION_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const sexualOrientationOption = data1 ? [...data1, secondaryOption] : [];
  // religion
  const { data: data2, isLoading: loading2 } = useSWR<IOption[]>(
    `taxonomy@${RELIGION_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const religionOption = data2 ? [...data2, secondaryOption] : [];
  // profession
  const { data: data3, isLoading: loading3 } = useSWR<IOption[]>(
    `taxonomy@${PROFESSION_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const professionOption = data3 ? [...data3, secondaryOption] : [];
  // global loading
  const loading = loading1 || loading2 || loading3;
  return { loading, sexualOrientationOption, religionOption, professionOption };
};

export default useDropdownOption;
