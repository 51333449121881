import classNames from "classnames";
import { useAppDispatch } from "../../../app/store";
import PrimaryButton from "../../../components/PrimaryButton";
import { updateOnboardingAsync } from "../../../app/onboardingSlice";
import OnboardingBack from "./OnboardingBack";
import { Divider } from "rsuite";
import LeftInnerIconInput from "../../../components/LeftInnerIconInput";
import RolePicker from "../../../components/RolePicker";
import Button from "../../../components/tailwind/Button";
import { scrollBarClass } from "../../../utils/classes";
import UserProfile from "../../../components/UserProfile";
import GibooToast from "../../../components/GibooToast";
import { sendOrgInvitation } from "../../../services/org.services";
import { isValidEmail } from "../../../utils/formatHelper";
import { useEffect, useState } from "react";
import useOrgMembers from "../../../hooks/project/useOrgMembers";
import useOnboardingData from "../../../hooks/useOnboarding";
import useOrgs from "../../../hooks/useOrgs";
import { roleToName } from "../../../app/userSlice";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
interface IUserInvite {
  email: string;
  role: number;
}
function NpoInviteMember() {
  const mxEvent = useGibooMixpanel("onboarding", true);
  const dispatch = useAppDispatch();
  const { selected } = useOrgs();
  const [loading, setLoading] = useState<boolean>(false);
  const [userList, setUserList] = useState<IUserInvite[]>([
    { email: "", role: 4 },
    { email: "", role: 4 },
    { email: "", role: 4 },
  ]);
  const [onboardingData] = useOnboardingData();
  const [hasValidData, setHasValidData] = useState<boolean>(false);
  useEffect(() => {
    setHasValidData(userList.filter((i) => i.email !== "").length > 0 ? true : false);
  }, [userList]);
  const { data: members } = useOrgMembers(selected, 1000, false);
  const validate = () => {
    const validData = userList.filter((i) => i.email !== "").filter((i) => isValidEmail(i.email));
    if (validData.length === 0) {
      return false;
    }
    return true;
  };

  const handleSend = () => {
    const isValid = validate();
    const validData = userList.filter((i) => i.email !== "").filter((i) => isValidEmail(i.email));
    if (!selected) return;
    if (isValid) {
      setLoading(true);
      const validEmail = validData.filter((item) =>
        members.find((m) => m.accepted && m?.email !== item.email),
      );
      if (validEmail) {
        Promise.allSettled(validEmail.map((i) => sendOrgInvitation(selected, i.email, i.role)))
          .then((res) => {
            res.forEach((result, index) => {
              mxEvent(
                result.status === "fulfilled"
                  ? MIXPANEL_EVENTS_V2.invitation[""].successful
                  : MIXPANEL_EVENTS_V2.invitation[""].failed,
                {
                  invitationType: "organization",
                  error: result.status === "rejected" ? "api error" : undefined,
                  email:
                    index >= 0 && index < validEmail.length ? validEmail[index]?.email : undefined,
                },
              );
            });
            GibooToast({
              type: "success",
              message: `Invites successfully sent.`,
            });
          })
          .catch((err) => {
            mxEvent(MIXPANEL_EVENTS_V2.invitation[""].failed, {
              invitationType: "organization",
              error: "api error",
            });
            // console.log(err);
            GibooToast({
              type: "failure",
              message: `We apologize for the inconvenience.`,
            });
          })
          .finally(() => {
            setLoading(false);
            dispatch(updateOnboardingAsync({}, 1));
          });
      }
    }
  };
  const renderDescription = () => {
    return (
      <div className="flex max-w-[721px] flex-col gap-4">
        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            What happens once invited members accept the invitation?
          </h4>
          <p className="font-poppins text-xs text-gray-800">
            Once invited members accept invitation, they gain access to a wealth of collaborative
            features within the organization. This includes access to the organization profile, the
            ability to view ongoing projects, and the opportunity to seamlessly collaborate with
            teammates.
          </p>
          <p className="font-poppins text-xs text-gray-800">
            Members can also assign tasks to each other, fostering efficient teamwork, and share
            opportunities effortlessly. This streamlined process ensures that organization members
            can contribute, collaborate, and thrive within the organization.
          </p>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="onboarding flex max-w-[853px] flex-col">
        <h4 className="onboarding-header">Invite your organization members.</h4>
        <div className="mb-5 mt-4 flex flex-col gap-4">
          <span className="onboarding-sub-header-gray">
            You and your teammates can streamline task, manage project progress, and discover grant
            opportunities together. Giboo facilitates effective project management and
            collaborations among your organization.
          </span>
        </div>
        <div className="onboarding-width-md mb-5">
          <div className="flex w-full flex-col gap-5">
            <div className="flex w-full flex-col items-start gap-4">
              {userList.map((item, index) => (
                <UserRoleSelector
                  key={index}
                  user={item}
                  update={(updatedValues) => {
                    setUserList((prevUserList) => {
                      const newUserList = [...prevUserList];
                      newUserList[index] = { ...newUserList[index], ...updatedValues };
                      return newUserList;
                    });
                  }}
                />
              ))}
              <div
                className="grid h-[37px] cursor-pointer place-items-center rounded p-1 "
                onClick={() => {
                  setUserList((prev) => [...prev, { email: "", role: 4 }]);
                }}
              >
                <div className="text-sm font-normal text-purple-500">
                  <span className="mr-2">
                    <i className="gi gi-add text-purple-500" />
                  </span>
                  Add more
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(" inline-flex items-center gap-2")}>
          <OnboardingBack outline />
          <PrimaryButton
            size="md"
            id="btn-next"
            handleOnClick={() => {
              handleSend();
            }}
            loading={loading}
            rightIconClass={<i className="gi gi-right-arrow" />}
            label="Next"
            disabled={!hasValidData}
          />
          <div
            id="btn-skip"
            className="grid h-[37px] w-[62px] cursor-pointer place-items-center rounded p-1 hover:bg-purple-50"
            onClick={() => {
              mxEvent(MIXPANEL_EVENTS_V2.invitation[""].failed, {
                invitationType: "organization",
                skip: true,
              });
              dispatch(updateOnboardingAsync({}, 1));
            }}
          >
            <h5 className="text-sm font-normal text-purple-500">Skip</h5>
          </div>
        </div>
        <Divider className="my-10" />
        {renderDescription()}
      </div>
    </>
  );
}
export default NpoInviteMember;

function UserRoleSelector({
  user,
  update,
}: {
  user: IUserInvite;
  update(data: IUserInvite): void;
}) {
  return (
    <>
      <div className="relative flex w-full max-w-[480px] gap-3">
        <div className="w-full grow">
          <LeftInnerIconInput
            className="w-full !border-gray-300"
            type="email"
            placeholder="Enter email"
            id="input-email"
            value={user.email}
            handleOnChange={(e) => update({ ...user, email: e.target.value })}
          />
          <div className="absolute right-4 top-[8px] flex items-center gap-2">
            <RolePicker
              disable={["Owner", "Guest", "Delete", "Viewer"]}
              wrapperClassName="!h-[33px]"
              currentRole={user.role}
              allowEdit={true}
              unControlled
              onSelect={(role: number) => {
                update({ ...user, role: role });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
