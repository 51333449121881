import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import {
  SaveItemList,
  SaveItemListCreateRequest,
  SaveItemListUpdateRequest,
} from "../../types/save";
import useOrgID from "../useOrgID";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface MySavedListState {
  data: SaveItemList[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<SaveItemList[] | undefined>;
  updateSavedList: (
    id: string,
    req: SaveItemListUpdateRequest,
  ) => Promise<SaveItemList[] | undefined>;
  updateSize: (id: string, delta: number) => Promise<SaveItemList[] | undefined>;
  handleDeleteSavedList: (id: string) => Promise<void>;
  renameSavedList: (id: string, newName: string) => Promise<void>;
}
const createSavedList = async (req: SaveItemListCreateRequest) => {
  return axios
    .post(process.env.REACT_APP_API_URL + `/api/v2/users/savelist`, req, config)
    .then((res) => res.data as string);
};
function useMySavedList(): MySavedListState {
  const org_id = useOrgID();
  const url = process.env.REACT_APP_API_URL + `/api/v2/users/savelist?org_id=${org_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<SaveItemList[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as SaveItemList[]);
  };
  const { data, isLoading, error, isValidating } = useSWR<SaveItemList[]>(
    org_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<SaveItemList[]>(url), [url]);
  const updateSavedList = useCallback(
    async (id: string, req: SaveItemListUpdateRequest) => {
      if (!data || !id) return;
      const index = data.findIndex((l) => l._id === id);
      if (index < 0) return new Promise<SaveItemList[] | undefined>((resolve, reject) => reject());
      const options = {
        optimisticData: [
          {
            ...data[index],
            ...Object.keys(req).reduce(
              (prev, key) => ({
                ...prev,
                ...(req[key as keyof typeof req] === undefined
                  ? {}
                  : { [key]: req[key as keyof typeof req] }),
              }),
              {},
            ),
            updated_at: new Date().toISOString(),
          },
          ...data.slice(0, index),
          ...data.slice(index + 1),
        ],
        rollbackOnError: true,
      };
      return mutate<SaveItemList[]>(
        url,
        axios
          .put(process.env.REACT_APP_API_URL + `/api/v2/users/savelist/${id}`, req, config)
          .then((response) => response.data)
          .then((res: SaveItemList) => {
            const index = data.findIndex((l) => l._id === res._id);
            if (index >= 0) return [res, ...data.slice(0, index), ...data.slice(index + 1)];
            return data;
          }),
        options,
      );
    },
    [url, data],
  );
  const updateSize = useCallback(
    async (save_list_id: string, delta: number) => {
      if (!data || !save_list_id) return;
      const index = data.findIndex((l) => l._id === save_list_id);
      if (index < 0) return new Promise<SaveItemList[] | undefined>((resolve, reject) => reject());
      const optimisticData = [
        ...data.slice(0, index),
        {
          ...data[index],
          size: data[index].size + delta,
        },
        ...data.slice(index + 1),
      ];
      const options = {
        optimisticData,
        rollbackOnError: true,
        revalidate: false,
      };
      return mutate<SaveItemList[]>(
        url,
        new Promise<SaveItemList[]>((resolve) => resolve(optimisticData)),
        options,
      );
    },
    [url, data],
  );
  const deleteSavedList = async (id: string) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/v2/users/savelist/${id}`, config);
    await mutate(url);
  };
  const handleDeleteSavedList = async (id: string) => {
    if (!data) return;

    try {
      await deleteSavedList(id); // Perform the deletion
      const updatedList = data.filter((item) => item._id !== id);
      mutate(url, updatedList, false); // Update the cache with the new list
    } catch (error) {
      console.error("Error deleting saved list", error);
      // Handle error (e.g., show a notification)
    }
  };
  const renameSavedList = async (id: string, newName: string) => {
    try {
      const updateRequest = { name: newName };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v2/users/savelist/${id}`,
        updateRequest,
        config,
      );
      // Assuming your API responds with the updated list item
      const updatedItem = response.data;

      // Update the state optimistically
      const updatedList = data?.map((item) => {
        if (item._id === id) {
          return { ...item, ...updatedItem };
        }
        return item;
      });

      mutate(url, updatedList, false); // Update the cache with the new list
    } catch (error) {
      console.error("Error renaming saved list", error);
      // Handle the error (e.g., show a notification)
    }
  };
  return {
    data: data || [],
    isLoading,
    error,
    isValidating,
    revalidate,
    updateSavedList,
    updateSize,
    handleDeleteSavedList,
    renameSavedList,
  };
}
export default useMySavedList;
export { createSavedList };
