import { ReactElement } from "react";

const deadlineTypeLabels = {
  rolling: "Rolling-based (or Cycle)",
  specific: "Specific deadline",
};
const deadlineTypeKeys = Object.keys(deadlineTypeLabels);
export type TypeDeadlineType = keyof typeof deadlineTypeLabels;

const DEADLINE_OPTIONS_GRANT_TOOL: {
  label: string;
  value: TypeDeadlineType;
}[] = ["rolling", "specific"].map((v) => ({
  value: v as TypeDeadlineType,
  label: deadlineTypeLabels[v as TypeDeadlineType],
}));

const solicitationLabels = {
  public: [
    "Open to public",
    "We are currently accepting grant applications from anyone who meets the eligibility requirements.",
  ],
  referral: ["Invitation or referral only", "We only accept grant applications through referrals."],
};
const solicitationKeys = Object.keys(solicitationLabels);
export type TypeSolicitation = keyof typeof solicitationLabels;

const grantAmountTabLabels = {
  range: "Range",
  fixed: "Fixed amount",
};
const grantAmountTabKeys = Object.keys(grantAmountTabLabels);
export type TypeGrantAmountTab = keyof typeof grantAmountTabLabels;

const grantAvailabilityLabels = {
  single: ["Single-year"],
  multi: ["Multi-year"],
  multi_in_special_cases: ["Multi-year grants are available only in special cases."],
};
const grantAvailabilityKeys = Object.keys(grantAvailabilityLabels);
export type TypeGrantAvailability = keyof typeof grantAvailabilityLabels;

const grantTypeLabels = {
  general: "General Operating Grants (Unrestricted Fund)",
  program: "Program and Project Grants",
  endowment: "Endowment Grants",
  matching: "Matching Grants",
  // challenge: "Challenge Grants",
  scholarship: "Scholarships",
  fellowship: "Fellowships",
  capital: "Capital Grants",
  capacity: "Capacity Building Grants",
  // seed: "Seed Funding",
  prize: "Prize or Award Funds",
  venture: "Venture Philanthropy",
  research: "Research",
  relief: "Relief",
  religious: "Religious",
  community: "Community",
  others: "Others",
};
const grantTypeKeys = Object.keys(grantTypeLabels);
export type TypeGrantType = keyof typeof grantTypeLabels & string;
const FUNDING_TYPE_OPTIONS_GRANT_TOOL: { label: string | ReactElement; value: TypeGrantType }[] = [
  "general",
  "program",
  "endowment",
  "matching",
  // "challenge",
  "scholarship",
  "fellowship",
  "capital",
  "capacity",
  // "seed",
  "prize",
  "venture",
  "research",
  "others",
].map((v) => ({
  value: v as TypeGrantType,
  label:
    v === "others" ? (
      <span className="my-1">
        Others, <span className="text-gray-600">please specify:</span>
      </span>
    ) : (
      grantTypeLabels[v as TypeGrantType]
    ),
}));

const grantProgramTypeLabels = {
  advertising: ["Advertising"],
  fundraising_event: ["Fundraising event, sponsorship for fundraising events"],
  reception: ["Receptions, socials and parties"],
  religious_doctrine: ["Programs or projects promoting a religious doctrine"],
  political: ["Political campaigns or programs promoting a political agenda"],
  conduit: ["Conduit organizations and fiscal sponsors"],
  lobby: ["Lobbying, legislative lobbying efforts"],
  conference: ["Conferences"],
  capital_campaign: ["Capital campaign"],
  deficit_reduction: ["Deficit reduction, retirement of debt, debt reduction"],
  annual_appeal: ["Annual appeals"],
  litigation: ["Fines, penalties, costs of litigation"],
  land: ["Purchase of land"],
};
const grantProgramTypeKeys = Object.keys(grantProgramTypeLabels);
export type TypeGrantProgramType = keyof typeof grantProgramTypeLabels;

const stageLabels = {
  dreamer: {
    title: <p>Individual</p>,
    detail: (
      <span>
        The funder is willing to provide grants to individuals who plan to establish nonprofit
        organizations. These individuals may not have any operating history to prove their track
        record, but we can request that they provide strategic plans for the use of the grant.
      </span>
    ),
  },
  seed: {
    title: <p>Pre-seed & Seed stage</p>,
    detail: (
      <>
        <span>
          The funder is willing to provide grants to nonprofit organizations in the{" "}
          <span className="text-purple-500">seed and pre-seed stages</span> that have a track record
          of operating for <span className="text-purple-500">less than a year</span> and have
          operating revenue of <span className="text-purple-500">less than $50,000.</span> A
          nonprofit of this stage might not be able to provide the financial statement or IRS 990.
        </span>
      </>
    ),
  },
  startup: {
    title: <p>Startup stage</p>,
    detail: (
      <>
        <span>
          The funder is willing to provide grants to{" "}
          <span className="text-purple-500">startup stage</span> nonprofit organizations that have a
          track record proving their operating history for the last{" "}
          <span className="text-purple-500">1-3 years,</span> with operating revenue of more than{" "}
          <span className="text-purple-500">$50,000 and less than $200,000.</span>
        </span>
      </>
    ),
  },
  buildup: {
    title: <p>Buildup stage</p>,
    detail: (
      <>
        <span>
          The funder is willing to provide grants to{" "}
          <span className="text-purple-500">Buildup Stage</span> nonprofit organizations that have a
          solid track record. These organizations must provide their operating history for the last{" "}
          <span className="text-purple-500">3-5 years</span> and have operating revenue between{" "}
          <span className="text-purple-500">$200,000 and $750,000.</span>
        </span>
      </>
    ),
  },
  superstar: {
    title: <p>Mature stage level 1 - Superstar</p>,
    detail: (
      <>
        <span>
          The funder is willing to provide grants to{" "}
          <span className="text-purple-500">Buildup Stage</span> nonprofit organizations that have a
          solid track record. These organizations have been operating for{" "}
          <span className="text-purple-500">5 to 10 years,</span> with operating revenue between{" "}
          <span className="text-purple-500">$750,000 and $10 million.</span>
        </span>
      </>
    ),
  },
  hyperstar: {
    title: <p>Mature stage level 2 - Hyperstar</p>,
    detail: (
      <>
        <span>
          The funder is offering grants to nonprofit organizations in the{" "}
          <span className="text-purple-500">Buildup Stage</span> that have a solid track record.
          These organizations must have an operating history of at least{" "}
          <span className="text-purple-500">10 years</span> and annual operating revenue of at least{" "}
          <span className="text-purple-500">$10 million.</span>
        </span>
      </>
    ),
  },
};
const stageKeys = Object.keys(stageLabels);
export type TypeStage = keyof typeof stageLabels;

const granteeTypeLabels = {
  npo: "Nonprofit organization",
  // intermediary: ["Funding intermediaries (foundations, intermediary fund agencies)"],
  // "501c4": ["Social Welfare Organization (Ex: 501(c)(4) Advocacy organizations)"],
  // federated: ["Federated funder (ex, United Way, United Arts affairs, American cancer society)"],
  // "501c6": ["Member Serving Organizations (ex: 501(c)(6) trade organizations)"],
  // company: "Company (For-Profit Business)",
  individual: "Individual",
  family: "Family",
  government: "Government Entity",
  // public_benefic_corporation: ["Public Benefit Corporation (Mission driven companies)"],
  profit_corporation: "Company (For-Profit Business)",
  others: "Others",
  "501c3": "Public Charities with 501(c)(3)",
  "501c3_pf": "Private Foundations with 501(c)(3)",
  "501c4": "Social Welfare Organizations 501(c)(4)",
  ngo: "Non-Governmental Organization",
  ed: "Organization with Equivalency Determination",
  others_npo: "Others",
};
const granteeTypeKeys = Object.keys(granteeTypeLabels);
export type TypeGranteeType = keyof typeof granteeTypeLabels & string;
const GRANTEE_TYPE_OPTIONS_GRANT_TOOL: { label: string | ReactElement; value: TypeGranteeType }[] =
  ["npo", "profit_corporation", "individual", "family", "government", "others"].map((v) => ({
    value: v as TypeGranteeType,
    label:
      v === "others" ? (
        <span className="my-1">
          Others, <span className="text-gray-600">please specify:</span>
        </span>
      ) : (
        granteeTypeLabels[v as TypeGranteeType]
      ),
  }));
const NPO_GRANTEE_TYPE_OPTIONS_GRANT_TOOL: {
  label: string | ReactElement;
  value: TypeGranteeType;
}[] = ["501c3", "501c3_pf", "501c4", "ngo", "ed", "others_npo"].map((v) => ({
  value: v as TypeGranteeType,
  label:
    v === "others_npo" ? (
      <span className="my-1">
        Others, <span className="text-gray-600">please specify:</span>
      </span>
    ) : (
      granteeTypeLabels[v as TypeGranteeType]
    ),
}));
const organizationExcludedLabels = {
  museum: ["Museums"],
  club: ["Civic Clubs"],
  research: ["Research organizations"],
  hospital: ["Hospitals"],
  school: ["Schools"],
  university: ["Universities"],
  religious: [
    "sectarian religious organizations (Churches, synagogues, mosques and other houses of worship)",
  ],
};
const organizationExcludedKeys = Object.keys(organizationExcludedLabels);
export type TypeOrganizationExcluded = keyof typeof organizationExcludedLabels;

const fiscalPositionLabels = {
  exemption: [
    "Tax-Exempt",
    "Funders allow organizations with tax-exempt status to apply for grants.",
  ],
  sponsored: [
    "Fiscal sponsored",
    "Funders allow organizations with fiscal sponsorship to apply for grants.",
  ],
  without: [
    "Without tax exemption",
    "Funders do not allow organizations without tax exemption to apply for grants.",
  ],
  others: ["", ""],
};
const fiscalPositionKeys = Object.keys(fiscalPositionLabels);
export type TypeFiscalPosition = keyof typeof fiscalPositionLabels & string;

const FISCAL_POSITION_OPTIONS_GRANT_TOOL: {
  label: ReactElement;
  value: TypeFiscalPosition;
}[] = ["exemption", "sponsored", "without", "others"].map((v) => ({
  value: v as TypeFiscalPosition,
  label:
    v === "others" ? (
      <span className="my-1">
        Others, <span className="text-gray-600">please specify:</span>
      </span>
    ) : (
      <span className="my-1">
        <span className="font-semibold">{`${
          fiscalPositionLabels[v as TypeFiscalPosition][0]
        }: `}</span>
        {fiscalPositionLabels[v as TypeFiscalPosition][1]}
      </span>
    ),
}));

const financialPositionLabels = {
  no_requirement: (
    <div>
      <h5>No requirement</h5>
      <h6 className="text-gray-700">
        The funder <span className="text-purple-500">does not require</span> any financial standing.
        <br />
        All organizations, regardless of their financial situation, are welcome to apply for our
        grants.
      </h6>
    </div>
  ),
  positive: (
    <div>
      <h5>Positive financial standing</h5>
      <h6 className="text-gray-700">
        The funder requires <span className="text-purple-500">a positive financial standing</span>{" "}
        for grantees those who do not have any net looses nor
        <br />
        significant operating or cumulative deficit with more than 0.5 month of operating
        unrestricted cash.
      </h6>
    </div>
  ),
};
const financialPositionKeys = Object.keys(financialPositionLabels);
export type TypeFinancialPosition = keyof typeof financialPositionLabels;

const FINANCIAL_POSITION_OPTIONS_GRANT_TOOL: {
  label: ReactElement;
  value: TypeFinancialPosition;
}[] = ["no_requirement", "positive"].map((v) => ({
  value: v as TypeFinancialPosition,
  label: financialPositionLabels[v as TypeFinancialPosition],
}));

const financialPositionRequirementLabels = {
  revenue: ["Revenue", "Source and stability of income."],
  expense: ["Expense", "Expenditure patterns."],
  budget: ["Organizational budget", "Budgetary allocations for different functions."],
  debt: ["Debt", "Outstanding debts and debt management."],
  income: ["Earned income", "Revenue generated through activities or services."],
  cash: ["Cash management", "Assessment of cash handling."],
  profit: ["Profitability", "Surplus or deficit determination."],
  others: ["", ""],
};
const financialPositionRequirementKeys = Object.keys(financialPositionRequirementLabels);
export type TypeFinancialPositionRequirement = keyof typeof financialPositionRequirementLabels &
  string;

const FINANCIAL_POSITION_REQUIREMENT_OPTIONS_GRANT_TOOL: {
  label: ReactElement;
  value: TypeFinancialPositionRequirement;
}[] = ["revenue", "expense", "budget", "debt", "income", "cash", "profit", "others"].map((v) => ({
  value: v as TypeFinancialPositionRequirement,
  label:
    v === "others" ? (
      <span className="my-1">
        Others, <span className="text-gray-600">please specify:</span>
      </span>
    ) : (
      <span className="my-1">
        <span className="font-semibold">{`${
          financialPositionRequirementLabels[v as TypeFinancialPositionRequirement][0]
        }: `}</span>
        {financialPositionRequirementLabels[v as TypeFinancialPositionRequirement][1]}
      </span>
    ),
}));

const financialPositionDocumentLabels = {
  irs: "IRS Form 990",
  audited: "Audited Financial Statement",
  others: "Others",
};

const financialPositionDocumentKeys = Object.keys(financialPositionDocumentLabels);
export type TypeFinancialPositionDocument = keyof typeof financialPositionDocumentLabels & string;

const FINANCIAL_POSITION_DOCUMENT_OPTIONS_GRANT_TOOL: {
  label: string | ReactElement;
  value: TypeFinancialPositionDocument;
}[] = ["irs", "audited", "others"].map((v) => ({
  value: v as TypeFinancialPositionDocument,
  label:
    v === "others" ? (
      <span className="my-1">
        Others, <span className="text-gray-600">please specify:</span>
      </span>
    ) : (
      financialPositionDocumentLabels[v as TypeFinancialPositionDocument]
    ),
}));

const applicationRequirementLabels = {
  letter: "Application letter",
  method: "Application methods",
  contact: "Contact",
  regrant: "Regranting or new granting",
  reference: "Reference or acknowledgment letter",
  diligence: "Due diligence and site visit",
  deadline: "Application deadline, timing, and cycle",
  decision: "Funding decision",
  others: "Others",
};

const applicationRequirementKeys = Object.keys(applicationRequirementLabels);
export type TypeApplicationRequirement = keyof typeof applicationRequirementLabels;

const APPLICATION_REQUIREMENT_OPTIONS_GRANT_TOOL: {
  label: string;
  value: TypeApplicationRequirement;
}[] = [
  "letter",
  "method",
  "contact",
  "regrant",
  "reference",
  "diligence",
  "deadline",
  "decision",
  "others",
].map((v) => ({
  value: v as TypeApplicationRequirement,
  label: applicationRequirementLabels[v as TypeApplicationRequirement],
}));

const proposalRequirementLabels = {
  mission: "Mission statement of the nonprofit organization",
  general: "General descriptions of the organization",
  track_record: "Track records, accomplished history",
  board: "Board members, staff",
  project_summary: "Summary of program or project",
  problem: "Program or project's problems and needs",
  beneficiary: "Program or project's beneficiary information",
  solution: "Program or project's solutions, activities, approaches",
  output: "Program or project's outputs",
  impact: "Program or project's outcomes and impacts",
  collaboration: "Collaboration and partnerships",
  tone: "Tones and approches",
  lengths: "Lengths",
  others: "Others",
};

const proposalRequirementKeys = Object.keys(proposalRequirementLabels);
export type TypeProposalRequirement = keyof typeof proposalRequirementLabels;

const PROPOSAL_REQUIREMENT_OPTIONS_GRANT_TOOL: {
  label: string;
  value: TypeProposalRequirement;
}[] = [
  "mission",
  "general",
  "track_record",
  "board",
  "project_summary",
  "problem",
  "beneficiary",
  "solution",
  "output",
  "impact",
  "collaboration",
  "tone",
  "lengths",
  "others",
].map((v) => ({
  value: v as TypeProposalRequirement,
  label: proposalRequirementLabels[v as TypeProposalRequirement],
}));

export interface Requirement {
  key: string;
  checked: boolean;
  value: TypeRequirement;
  others: string;
}
const requirementLabels = {
  good_to_have: "Good to have",
  must_have: "Must have",
  specific: "Specific requirement",
};
const requirementKeys = Object.keys(requirementLabels);
export type TypeRequirement = keyof typeof requirementLabels;
const REQUIREMENT_OPTIONS_GRANT_TOOL: {
  label: string;
  value: TypeRequirement;
}[] = ["good_to_have", "must_have", "specific"].map((v) => ({
  value: v as TypeRequirement,
  label: requirementLabels[v as TypeRequirement],
}));

const budgetRequirementTypeLabels = {
  no: (
    <span>
      There are <span className="text-purple-500">no specific requirements</span> for the budget
      planning.
    </span>
  ),
  general: (
    <span>
      The grant can be used to cover{" "}
      <span className="text-purple-500">100% of general operating expenses</span> or capacity
      building expenses.
    </span>
  ),
  flexible: (
    <span>
      This funder is <span className="text-purple-500">flexible with budgets.</span> Applicants are
      encouraged to include all expenses necessary to ensure a successful project, such as
      management supervision, accounting, evaluation, training, and grant reporting.
    </span>
  ),
};
const budgetRequirementTypeKeys = Object.keys(budgetRequirementTypeLabels);
export type TypeBudgetRequirementType = keyof typeof budgetRequirementTypeLabels;

const reportingRequiredLabels = {
  not: ["Reporting is not required."],
  progress: ["A one-page progress report will be accepted."],
  progress_and_final: [
    "The grantee should provide both a progress report and a final report, including details.",
  ],
};
const reportingRequiredKeys = Object.keys(reportingRequiredLabels);
export type TypeReportingRequired = keyof typeof reportingRequiredLabels;

export {
  deadlineTypeLabels,
  deadlineTypeKeys,
  solicitationLabels,
  solicitationKeys,
  grantAmountTabLabels,
  grantAmountTabKeys,
  grantAvailabilityLabels,
  grantAvailabilityKeys,
  grantTypeLabels,
  grantTypeKeys,
  grantProgramTypeLabels,
  grantProgramTypeKeys,
  stageLabels,
  stageKeys,
  granteeTypeLabels,
  granteeTypeKeys,
  organizationExcludedLabels,
  organizationExcludedKeys,
  fiscalPositionLabels,
  fiscalPositionKeys,
  financialPositionLabels,
  financialPositionKeys,
  requirementLabels,
  requirementKeys,
  budgetRequirementTypeLabels,
  budgetRequirementTypeKeys,
  reportingRequiredLabels,
  reportingRequiredKeys,
  FUNDING_TYPE_OPTIONS_GRANT_TOOL,
  GRANTEE_TYPE_OPTIONS_GRANT_TOOL,
  NPO_GRANTEE_TYPE_OPTIONS_GRANT_TOOL,
  FISCAL_POSITION_OPTIONS_GRANT_TOOL,
  FINANCIAL_POSITION_OPTIONS_GRANT_TOOL,
  FINANCIAL_POSITION_REQUIREMENT_OPTIONS_GRANT_TOOL,
  FINANCIAL_POSITION_DOCUMENT_OPTIONS_GRANT_TOOL,
  financialPositionRequirementKeys,
  financialPositionDocumentKeys,
  APPLICATION_REQUIREMENT_OPTIONS_GRANT_TOOL,
  PROPOSAL_REQUIREMENT_OPTIONS_GRANT_TOOL,
  applicationRequirementKeys,
  proposalRequirementKeys,
  REQUIREMENT_OPTIONS_GRANT_TOOL,
  DEADLINE_OPTIONS_GRANT_TOOL,
  applicationRequirementLabels,
  proposalRequirementLabels,
  financialPositionRequirementLabels,
  financialPositionDocumentLabels,
};
