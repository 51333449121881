import classNames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import { Divider, Steps } from "rsuite";
import Button from "../../../../components/tailwind/Button";
import Spacer from "../../../../components/Spacer";
import IMG_EXAMPLE from "../../../../assets/financial/business-modal-revenue-placeholder.svg";
import NpoFinancialRevenue, { RevenueChart } from "../../dashboard/components/NpoFinancialRevenue";
import useNPOIRSProfile from "../../../../hooks/useNPOIRSProfile";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import CloseButton from "../../../../components/CloseButton";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";

export default function BusinessRevenueModel({
  onBack,
  onNext,
  npo_id,
  org_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  org_id?: string;
  isBack?: boolean;
}) {
  const [currentStep, setCurrentStep] = useState<number>(isBack ? 1 : 0);
  useEffect(() => {
    if (isBack) {
      setTimeout(() => {
        scrollToElement("businessmodel-step-2");
      }, 300);
    }
  }, [isBack]);
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value >= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };

  const scrollToElement = (id: string) => {
    const element = document?.getElementById(id);
    const container = document.getElementById("financial-scroll-container");
    if (element && container) {
      const elementTop = element.getBoundingClientRect().top + container.scrollTop;
      const adjustedOffset = elementTop - 120;
      container.scrollTo({ top: adjustedOffset, behavior: "smooth" });
    }
  };
  const renderStep1 = () => {
    return (
      <FinancialRevenueModelInfo>
        <div
          className={classNames(
            "flex h-[38px] items-center justify-start gap-2",
            currentStep === 0 ? "flex" : "hidden",
          )}
        >
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              if (currentStep < 1) {
                setCurrentStep(1);
                setTimeout(() => {
                  scrollToElement("businessmodel-step-2");
                }, 300);
              }
            }}
            label="See the assessment result"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
        <Spacer height="100px" />
      </FinancialRevenueModelInfo>
    );
  };
  return (
    <div className="flex max-w-[760px] flex-col">
      <Steps current={currentStep} vertical>
        <Steps.Item
          title={false}
          className="!-ml-[40px] !mt-0"
          icon={currentStep > 0 ? renderDot(1) : <></>}
          description={renderStep1()}
        />
        {currentStep > 0 && (
          <Steps.Item
            icon={renderDot(2)}
            className="!-ml-[40px]"
            description={
              <Step2
                onBack={() => {
                  setCurrentStep(0);
                }}
                npo_id={npo_id}
                org_id={org_id}
                onNext={() => {
                  onNext?.();
                }}
              />
            }
          />
        )}
      </Steps>
    </div>
  );
}

const Step2 = ({
  onBack,
  onNext,
  org_id,
  npo_id,
  recomendation = true,
}: {
  onBack: () => void;
  onNext: () => void;
  org_id?: string;
  npo_id?: string;
  recomendation?: boolean;
}) => {
  const { data, update } = useOrgProfile(org_id);
  const prev = data?.skip_loading || [];
  const mxEvent = useGibooMixpanel(undefined, true);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.financial.business_model.successful);
  }, []);
  return (
    <div className="mt-3 flex min-h-[600px] flex-col gap-4" id="businessmodel-step-2">
      <h6 className="text-base font-semibold text-gray-900">
        Your organization&apos;s assessment result
      </h6>
      <NpoFinancialRevenue
        npo_id={npo_id}
        org_id={org_id}
        recomendation
        showChartLoader={!data.skip_loading?.includes("financial_revenue_model")}
      >
        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              data &&
                update({
                  skip_loading: [
                    ...prev.filter((p) => p !== "financial_revenue_model"),
                    "financial_revenue_model",
                  ],
                });
              onNext?.();
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </NpoFinancialRevenue>
      <Spacer height="150px" />
    </div>
  );
};

export function FinancialRevenueModelInfo({
  children,
  handleClose,
}: {
  children?: ReactNode;
  handleClose?: () => void;
}) {
  const renderStep1Item = (title: string, content: ReactNode) => {
    return (
      <>
        <div>
          <p className="font-poppins text-sm font-semibold text-purple-500">{title}</p>
          <Spacer height="8px" />
          <h6 className="font-poppins text-sm text-gray-800 ">{content}</h6>
        </div>
      </>
    );
  };
  return (
    <div className={classNames("flex min-h-[600px] flex-col gap-4", !handleClose && "mt-3")}>
      <div className="inline-flex w-full items-center justify-between border-b border-gray-300">
        <h6 className="text-base font-semibold text-gray-900 underline underline-offset-[6px]">
          Business (Revenue) model
        </h6>
        {handleClose && <CloseButton onClick={() => handleClose?.()} />}
      </div>

      <div className="flex min-h-[500px] flex-col rounded border border-gray-300 bg-white p-5">
        {/* section 1 */}
        <div className="flex flex-col gap-10">
          <div>
            <h5 className="font-poppins text-base font-semibold text-black">
              What is business (revenue) model?
            </h5>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800">
              The business (revenue) model of a nonprofit provides a detailed overview of the
              methods employed by the organization to generate income. This encompasses various
              sources such as contributions and grants, portfolio revenue, or operation revenue.
            </h6>
          </div>
          <div className="flex justify-center">
            <img src={IMG_EXAMPLE} className="h-fit w-fit" alt="example" />
          </div>
        </div>
        <Spacer height="16px" />
        {/* section 2 */}
        <div className="flex flex-col">
          {renderStep1Item(
            "Operation revenue",
            <>
              Operation revenue refers to the income generated by a nonprofit organization through
              its day-to-day activities and operations directly related to its mission. This can
              include revenue from program fees, service charges, or other income-generating
              activities that align with the nonprofit&apos;s core objectives.
              <br />
              <br />
              Operation revenue is crucial for covering the ongoing costs of running the
              organization and implementing its programs. It ensures that the nonprofit can sustain
              its daily operations, deliver services, and make progress toward achieving its
              mission.
            </>,
          )}
          <Divider className="my-5" />
          {renderStep1Item(
            "Contributions and grants",
            <>
              Contributions and grants encompass financial support received by a nonprofit from
              various sources. Contributions can include donations from individuals, corporations,
              or foundations, while grants are typically funds provided by government entities,
              private foundations, or other institutions to support specific projects or the overall
              mission of the nonprofit.
              <br />
              <br />
              Contributions and grants are often primary sources of revenue for nonprofits. They
              play a vital role in funding programs, initiatives, and organizational growth.
              Building strong relationships with donors and funders is essential for sustaining this
              type of revenue, and effective grant writing and fundraising efforts are commonly
              employed to secure these funds.
            </>,
          )}
          <Divider className="my-5" />
          {renderStep1Item(
            "Portfolio revenue",
            <>
              Portfolio revenue is the income derived from a nonprofit&apos;s investments and
              financial assets. Nonprofits may invest their funds in stocks, bonds, real estate, or
              other financial instruments, and the returns generated from these investments
              contribute to the portfolio revenue.
              <br />
              <br />
              Portfolio revenue provides an additional source of income for nonprofits, allowing
              them to diversify their funding streams. However, it also involves some level of
              financial risk, as the returns on investments can fluctuate based on market
              conditions. Careful management of the investment portfolio is essential to optimize
              returns while aligning with the organization&apos;s risk tolerance.
            </>,
          )}
        </div>
      </div>
      <div className="flex min-h-[100px] flex-col rounded border border-gray-300 bg-white p-5">
        <div>
          <h5 className="font-poppins text-base font-semibold text-black">
            Why is business (revenue) model important?
          </h5>
          <Spacer height="10px" />
          <h6 className="font-poppins text-sm text-gray-800">
            The significance of a well-defined business (revenue) model lies in its role as the
            cornerstone of the organization&apos;s financial strategy. It serves as a comprehensive
            guide, outlining the primary avenues through which the nonprofit sustains its operations
            and fulfills its mission. By clearly defining how income is generated, the organization
            can make informed decisions, allocate resources effectively, and establish a robust
            financial foundation.
          </h6>
        </div>
      </div>
      {children && children}
    </div>
  );
}
