import classNames from "classnames";
import * as React from "react";
import "./css/input.scss";

interface LongTextInputProps {
  id: string;
  className?: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green";
  handleOnChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleOnFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleOnBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  readonly?: boolean;
  placeholder?: string;
  value?: string | number;
  invalid?: boolean;
  disabled?: boolean;
  min?: number | undefined;
  max?: number | undefined;
  height?: string;
  topPlaceholder?: string;
  defaultValue?: string;
  status?: "success" | "fail";
  resize?: boolean;
  autoFocus?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  wrapperClass?: string;
  autoResize?: boolean;
}
const defaultInputHeightNum = 60;
const inputMaxHeightNum = 500;
const LongTextInput = React.forwardRef(function longTextInput(
  { resize = true, value, ...props }: LongTextInputProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const myRef = React.useRef<HTMLTextAreaElement | null>(null);
  const colorClass = props.color ? `-${props.color}` : "";
  const rightIconClass = props.status
    ? props.status === "success"
      ? "fa-solid fa-check"
      : "fa-solid fa-x"
    : undefined;
  const [height, setHeight] = React.useState<number>(0);
  React.useEffect(() => {
    if (!props.autoResize) return;
    if (myRef?.current) {
      if (myRef.current.scrollHeight > defaultInputHeightNum) setHeight(0);
    }
  }, [value, myRef]);
  React.useEffect(() => {
    if (!props.autoResize) return;
    if (myRef?.current) {
      if (height === 0)
        setHeight(
          Math.max(defaultInputHeightNum, Math.min(myRef.current.scrollHeight, inputMaxHeightNum)),
        );
    } else {
      setHeight(defaultInputHeightNum);
    }
  }, [height, myRef]);
  return (
    <div className={classNames("relative w-full ", props.wrapperClass)}>
      {props.topPlaceholder ? (
        <h6 className="giboo-input-inner-label prevent-select gray">{props.topPlaceholder}</h6>
      ) : null}
      {props.iconLeft && (
        <div className="absolute bottom-0 left-0 flex h-full items-center px-3">
          {props.iconLeft}
        </div>
      )}
      <textarea
        ref={(node) => {
          myRef.current = node;
          if (typeof ref === "function") {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }}
        id={props.id}
        value={value}
        placeholder={props.placeholder}
        className={classNames(
          "form-control giboo-input giboo-long-input font-poppins",
          {
            "form-invalid": props.invalid,
            "giboo-input-with-top-placeholder": props.topPlaceholder,
          },
          colorClass ? `giboo-input${colorClass}` : "",
          props.className,
        )}
        onFocus={props.handleOnFocus}
        onBlur={props.handleOnBlur}
        onChange={props.handleOnChange}
        onKeyDown={props.handleOnKeyDown}
        disabled={props.disabled}
        readOnly={props.readonly}
        style={{
          paddingRight: rightIconClass ? "48px" : "18px",
          height: props.height,
          ...(resize ? { resize: "none" } : {}),
          ...(props.autoResize
            ? { height: `${height}px`, overflowY: height >= inputMaxHeightNum ? "auto" : "hidden" }
            : {}),
        }}
        defaultValue={props.defaultValue}
        autoFocus={props.autoFocus}
      />
      {props.iconRight && (
        <div className="absolute bottom-0 right-0 flex h-full cursor-pointer items-center px-3">
          {props.iconRight}
        </div>
      )}
    </div>
  );
});

export default LongTextInput;
