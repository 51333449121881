import React, { useState } from "react";
import useDocumentSearch, {
  DOCUMENT_RECENT_LIMIT,
  DOCUMENT_RECENT_LIMIT_LAST_DAYS,
} from "../../../../hooks/project/useDocumentSearch";
import { useNavigate } from "react-router-dom";
import { GibooDocument } from "../../../../types/document";
import { toDateString } from "../../../../utils/formatHelper";
import OutReachPickerModal from "../../../../components/OutReachPickerModal";

export default function ProjectTaskDocuments({
  projectId,
  taskId,
  label = "Documents",
}: {
  projectId?: string;
  taskId?: string;
  label?: string;
}) {
  const { data: documentList } = useDocumentSearch(
    "",
    projectId,
    taskId,
    0,
    DOCUMENT_RECENT_LIMIT,
    DOCUMENT_RECENT_LIMIT_LAST_DAYS,
  );
  const [showOutreach, setShowOutreach] = useState<boolean>(false);
  const navigate = useNavigate();
  const renderItem = (document: GibooDocument) => {
    return (
      <div
        key={document._id}
        title={document.name}
        className="inline-flex h-[29px] cursor-pointer items-center gap-2 rounded px-2 py-1 hover:bg-purple-50"
        onClick={() => navigate(`/project/${document.project_id}/document/${document._id}`)}
      >
        <span>
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.1666 5.24681H2.92591C2.68034 5.24681 2.44483 5.34436 2.27119 5.518C2.09755 5.69165 2 5.92716 2 6.17273V13.5801C2 13.8257 2.09755 14.0612 2.27119 14.2349C2.44483 14.4085 2.68034 14.5061 2.92591 14.5061H11.2591C11.5047 14.5061 11.7402 14.4085 11.9138 14.2349C12.0875 14.0612 12.185 13.8257 12.185 13.5801V9.87643M2 7.56162H8.01842M7.09271 12.1912H8.48157M8.36676 5.67642C8.04269 5.61994 8.04269 5.15327 8.36676 5.09679C8.94237 4.9968 9.47515 4.72763 9.89715 4.3236C10.3191 3.91957 10.6112 3.39899 10.7362 2.82827L10.7547 2.73846C10.826 2.41716 11.2825 2.41439 11.3565 2.73568L11.3797 2.84031C11.5093 3.4085 11.804 3.92569 12.2267 4.32685C12.6495 4.72801 13.1814 4.99524 13.7556 5.09494C14.0815 5.15142 14.0815 5.62086 13.7556 5.67827C13.1814 5.77797 12.6495 6.04519 12.2267 6.44635C11.804 6.84752 11.5093 7.3647 11.3797 7.9329L11.3556 8.0366C11.2825 8.35697 10.826 8.35512 10.7556 8.03382L10.7362 7.94586C10.6112 7.37514 10.3191 6.85456 9.89715 6.45053C9.47515 6.0465 8.94237 5.77733 8.36676 5.67734V5.67642Z"
              stroke="#404040"
              strokeWidth="0.777771"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <div className="w-[200px] truncate font-poppins  text-sm font-semibold">
          {document.name}
        </div>
        <p className=" whitespace-nowrap font-poppins text-sm text-gray-700">
          {toDateString(document?.created_at, true, false)}
        </p>
      </div>
    );
  };
  return (
    <>
      {showOutreach && (
        <OutReachPickerModal
          open={showOutreach}
          setOpen={setShowOutreach}
          onSelect={(data) => {
            navigate(`/project/${projectId}/document?type=${data.typeEnum}`);
            setShowOutreach(false);
          }}
        />
      )}
      <div className="flex h-[306px] w-[363px] flex-col gap-2 rounded border border-gray-200 bg-white p-4">
        <div className="flex items-center justify-between gap-4">
          <div className="font-poppins text-sm font-semibold text-purple-500">
            {label} ({documentList.length})
          </div>
          <div
            className="giboo-btn-text-purple cursor-pointer text-sm font-normal text-gray-600"
            onClick={() => {
              navigate(`/project/${projectId}}/documents`);
            }}
          >
            View all
          </div>
        </div>
        <div className="flex grow flex-col gap-1 overflow-y-scroll">
          {documentList.map((i) => {
            return renderItem(i);
          })}
        </div>
        <div className="mt-1">
          <p
            className="giboo-btn-text-purple cursor-pointer font-poppins text-sm text-gray-700"
            onClick={() => {
              setShowOutreach(true);
            }}
          >
            + Create a new document
          </p>
        </div>
      </div>
    </>
  );
}
