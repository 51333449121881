import { useEffect, useState } from "react";
import { OnboardingData } from "../app/onboarding";
import useNPOSearchResult from "./search/useNPOSearchResult";
import useEINFromSearchParams from "./useEINFromSearchParams";
import { getOnboardingDataFromNPOandNPOProfile } from "./useOrgDataForNPODashboard";
interface OnboardingDataWithoutLoginState {
  data: OnboardingData | undefined;
  error: any;
  isLoading: boolean;
}
function useOnboardingDataWithoutLogin(enable?: boolean): OnboardingDataWithoutLoginState {
  const { ein, tags, textQuery } = useEINFromSearchParams();
  const {
    data: npoSearchResult,
    isLoading: isLoadingSearchResult,
    error: errorSearchResult,
  } = useNPOSearchResult(enable && ein ? ein : undefined, undefined, "ein");
  const [data, setData] = useState<OnboardingData>();
  useEffect(() => {
    if (!npoSearchResult) {
      setData(undefined);
      return;
    }
    const onboardingData = {
      ...getOnboardingDataFromNPOandNPOProfile(npoSearchResult),
      _id: ein || "",
      ...(tags ? tags : {}),
      ...(textQuery ? { text_query: textQuery } : {}),
      role: 5,
    };
    setData(onboardingData);
  }, [ein, npoSearchResult, tags]);
  return {
    data: data,
    isLoading: isLoadingSearchResult,
    error: errorSearchResult,
  };
}
export default useOnboardingDataWithoutLogin;
