import { useCallback, useState } from "react";

export type ExcludeSearchOptionsType =
  | "focus_area"
  | "beneficiary"
  | "program"
  | "service_loc"
  | "hq_loc";

const getExcludedKey = (key: ExcludeSearchOptionsType, item?: string) => {
  return `e_${key}_${item ? item : ""}`;
};

const isMarkedAsExcluded = (
  excludes: string[],
  key: ExcludeSearchOptionsType,
  item?: string,
): boolean => {
  if (excludes.length === 0) return false;
  return excludes.includes(getExcludedKey(key, item));
};

function useExcludeds() {
  const [excludes, setExcludes] = useState<string[]>([]);

  const isExcluded = useCallback(
    (key: ExcludeSearchOptionsType, item?: string): boolean => {
      return isMarkedAsExcluded(excludes, key, item);
    },
    [excludes],
  );
  const isAllExcluded = useCallback(
    (key: ExcludeSearchOptionsType, item: string[]): boolean => {
      const starKey = getExcludedKey(key);
      const keys = excludes.filter((i) => i.startsWith(starKey));
      return (
        keys.length >= item.length &&
        item
          .filter((i) => keys.includes(getExcludedKey(key, i)))
          .sort((a, b) => a.localeCompare(b))
          .reduce(
            (prev, cur) =>
              prev.length > 0 && prev[prev.length - 1] === cur ? prev : [...prev, cur],
            [] as string[],
          ).length === item.length
      );
    },
    [excludes],
  );
  const clearExcluded = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getExcludedKey(key, item);
      setExcludes((prev) => prev.filter((i) => i !== starKey));
    },
    [setExcludes],
  );
  const flipExcluded = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getExcludedKey(key, item);
      setExcludes((prev) =>
        prev.includes(starKey) ? prev.filter((i) => i !== starKey) : [...prev, starKey],
      );
    },
    [setExcludes],
  );
  const setExcluded = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getExcludedKey(key, item);
      setExcludes((prev) => [...prev.filter((i) => i !== starKey), starKey]);
    },
    [setExcludes],
  );
  const addAllExcluded = useCallback(
    (key: ExcludeSearchOptionsType, item: string[]) => {
      const starKeys = item.map((i) => getExcludedKey(key, i));
      setExcludes((prev) => [
        ...prev.filter((prevItem) => !starKeys.includes(prevItem)),
        ...starKeys,
      ]);
    },
    [setExcludes],
  );
  const clearAllExcluded = useCallback(
    (key: ExcludeSearchOptionsType) => {
      const starKey = getExcludedKey(key);
      setExcludes((prev) => prev.filter((prevItem) => !prevItem.startsWith(starKey)));
    },
    [setExcludes],
  );
  const flipAllExcluded = useCallback(
    (key: ExcludeSearchOptionsType, item: string[]) => {
      if (isAllExcluded(key, item)) clearAllExcluded(key);
      else addAllExcluded(key, item);
    },
    [isAllExcluded, addAllExcluded, clearAllExcluded],
  );
  return {
    excludes,
    setExcludes,
    setExcluded,
    isExcluded,
    flipExcluded,
    isAllExcluded,
    flipAllExcluded,
    clearExcluded,
    clearAllExcluded,
  };
}
export { isMarkedAsExcluded, getExcludedKey };
export default useExcludeds;
