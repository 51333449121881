import classNames from "classnames";
import React from "react";

export default function ChartLoader({ wrapperClass }: { wrapperClass?: string }) {
  return (
    <div className={classNames("loading-light-shimmer-on w-full p-4", wrapperClass)}>
      <div className="loading-light-shimmer-child flex flex-col items-stretch gap-4 p-4">
        <div className="mx-16 h-8"></div>
        <div className="ml-24 mr-16 h-8"></div>
        <div className="mx-8 h-6"></div>
        <div className="ml-16 mr-24 h-8"></div>
        <div className="ml-24 mr-12 h-8"></div>
      </div>
    </div>
  );
}
