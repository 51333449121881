import { ReactElement, useCallback, useEffect, useState } from "react";
import FilterBase from "./FilterBase";
import useStars, { SearchOptionsType, getStarKey } from "../../../hooks/useStars";
import {
  ISearchParamNullable,
  getObjectFromURLSearchParams,
  getURLSearchParamsFromSearchParam,
} from "../../../types/search";
import { useNavigate, useSearchParams } from "react-router-dom";
import TagSelector from "../../TagSelector";
import GibooLocationInput from "../../GibooLocationInput";
import { ILocation, reprLocation } from "../../../types/location";
import TagInfo from "../../TagInfo";
import useExcludeds, { ExcludeSearchOptionsType, getExcludedKey } from "../../../hooks/useExclude";

interface ServiceLocationFilterProps {
  repr: (v: ILocation[] | undefined) => string | ReactElement;
  selected?: (v: ILocation[] | undefined) => boolean;
  downIcon?: boolean;
  panelWidth?: string;
  panelMaxHeight?: string;
}
function ServiceLocationFilter({ ...props }: ServiceLocationFilterProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [diffQueue, setDiffQueue] = useState<ISearchParamNullable[]>([]);
  const [serviceLocation, setServiceLocation] = useState<ILocation[]>([]);
  const [serviceLocationSubs, setServiceLocationSubs] = useState<ILocation[]>([]);
  const { stars, setStars, isStar, isAllStar } = useStars();
  const { excludes, setExcludes, isExcluded } = useExcludeds();
  useEffect(() => {
    const obj = getObjectFromURLSearchParams(searchParams);
    setServiceLocation(obj.service_loc);
    setServiceLocationSubs([...(obj.service_loc_subs || [])]);
    setStars([...(obj.stars || [])]);
    setExcludes([...(obj.excludes || [])]);
  }, [searchParams, setSearchParams]);
  const updateFilter = useCallback(
    (diff: ISearchParamNullable) => {
      setDiffQueue((prev) => [...prev, diff]);
    },
    [setDiffQueue],
  );
  useEffect(() => {
    if (diffQueue.length === 0) return;
    const diff = diffQueue[0];
    const done_tagging = searchParams.get("done_tagging") === "true" ? true : false;
    const _obj = getObjectFromURLSearchParams(searchParams);
    if (!done_tagging) return;
    const obj = {
      ..._obj,
      ...diff,
    };
    if (
      getURLSearchParamsFromSearchParam(obj).toString() !==
      getURLSearchParamsFromSearchParam(_obj).toString()
    ) {
      navigate(`/search?${getURLSearchParamsFromSearchParam(obj, true).toString()}`);
    }
    setDiffQueue((prev) => prev.slice(1));
  }, [diffQueue, searchParams, setDiffQueue]);

  const flipStar = useCallback(
    (key: SearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      updateFilter({
        stars: stars.includes(starKey) ? stars.filter((i) => i !== starKey) : [...stars, starKey],
      });
    },
    [stars, updateFilter, getStarKey],
  );
  const setStar = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      const excludeKey = getExcludedKey(key, item);
      updateFilter({
        stars: [...stars.filter((s) => s !== starKey), starKey],
        excludes: excludes.filter((s) => s !== excludeKey),
      });
    },
    [stars, excludes, updateFilter, getStarKey, getExcludedKey],
  );
  const clearStar = useCallback(
    (key: SearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      updateFilter({
        stars: stars.filter((s) => s !== starKey),
      });
    },
    [stars, updateFilter, getStarKey],
  );
  const clearAllStar = useCallback(
    (key: SearchOptionsType) => {
      const starKey = getStarKey(key);
      updateFilter({
        stars: stars.filter((prevItem) => !prevItem.startsWith(starKey)),
      });
    },
    [stars, updateFilter, getStarKey],
  );
  const addAllStar = useCallback(
    (key: SearchOptionsType, item: string[]) => {
      const starKeys = item.map((i) => getStarKey(key, i));
      updateFilter({
        stars: [...stars.filter((prevItem) => !starKeys.includes(prevItem)), ...starKeys],
      });
    },
    [stars, updateFilter, getStarKey],
  );
  const flipAllStar = useCallback(
    (key: SearchOptionsType, item: string[]) => {
      if (isAllStar(key, item)) clearAllStar(key);
      else addAllStar(key, item);
    },
    [stars, updateFilter, getStarKey, isAllStar, clearAllStar, addAllStar],
  );
  const setExcluded = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      const excludeKey = getExcludedKey(key, item);
      updateFilter({
        stars: stars.filter((s) => s !== starKey),
        excludes: [...excludes.filter((s) => s !== excludeKey), excludeKey],
      });
    },
    [stars, excludes, updateFilter, getStarKey, getExcludedKey],
  );
  const renderServiceLocation = () => {
    return (
      <div className="mt-[-8px] flex flex-col px-3">
        <TagSelector<ILocation>
          className="z-[4] "
          globalOpen={true}
          isPrioritySelector
          id="service-location"
          name="Service areas"
          renderInfo={() => (
            <TagInfo type="OTHERS" value={"SERVICE_AREA"} placements="rightStart" />
          )}
          value={serviceLocation}
          setValue={(ls) => updateFilter({ service_loc: ls })}
          subs={serviceLocationSubs}
          setSubs={(ls) => updateFilter({ service_loc_subs: ls })}
          setValueAndSubs={(ls, lss) => updateFilter({ service_loc: ls, service_loc_subs: lss })}
          color="orange"
          size="sm"
          matched
          noIndent
          allowSelectAll
          getKey={(item: ILocation) => reprLocation(item)}
          getLabel={(item: ILocation) => reprLocation(item)}
          setStar={(item: ILocation) => setStar("service_loc", reprLocation(item))}
          flipStar={(item: ILocation) => flipStar("service_loc", reprLocation(item))}
          isStar={(item: ILocation) => isStar("service_loc", reprLocation(item))}
          isAllStar={(items: ILocation[]) =>
            isAllStar(
              "service_loc",
              items.map((l) => reprLocation(l)),
            )
          }
          flipAllStar={(items: ILocation[]) =>
            flipAllStar(
              "service_loc",
              items.map((l) => reprLocation(l)),
            )
          }
          clearStar={(item: ILocation) => clearStar("service_loc", reprLocation(item))}
          clearAllStar={() => clearAllStar("service_loc")}
          isExcluded={(item: ILocation) => isExcluded("service_loc", reprLocation(item))}
          setExcluded={(item: ILocation) => setExcluded("service_loc", reprLocation(item))}
          clearExcluded={(item: ILocation) => clearStar("service_loc", reprLocation(item))}
          hideCount
          hideCheckbox
          hideArrow
          removable
          seeAll
          twoLineCut={28}
        >
          <GibooLocationInput
            setValue={setServiceLocationSubs}
            value={serviceLocation}
            excludes={serviceLocationSubs}
            inputClassName="!h-[45px] !w-full"
            id="service-location"
            hideItems
            icon
            onAdd={(loc) =>
              updateFilter({
                service_loc: [
                  ...serviceLocation.filter((l) => reprLocation(l) !== reprLocation(loc)),
                  loc,
                ],
                service_loc_subs: [
                  ...serviceLocationSubs.filter((l) => reprLocation(l) !== reprLocation(loc)),
                  loc,
                ],
              })
            }
            placeholder="Search service area"
            hideSearch
            addMoreLabelClass="!text-sm"
            addMoreLabel="Add your own service area"
          />
        </TagSelector>
      </div>
    );
  };
  return (
    <FilterBase<ILocation[]>
      id={"service-location"}
      value={serviceLocation}
      setValue={(v) => {
        if (v) setServiceLocation(v);
      }}
      {...props}
    >
      {({ open, close }) => <>{renderServiceLocation()}</>}
    </FilterBase>
  );
}
export default ServiceLocationFilter;
