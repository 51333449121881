import React, { ReactNode } from "react";
import "../css/input.scss";
import classNames from "classnames";

interface CheckboxProps {
  id: string;
  checked: boolean;
  value?: string;
  label?: string;
  disabled?: boolean;
  onChange?: () => void;
  labelClass?: string;
  inputClass?: string;
  renderItem?: ReactNode;
  className?: string;
  readOnly?: boolean;
  size?: "md" | "sm" | "xs";
  ariaValueText?: string;
}
function Checkbox({ size = "md", inputClass, ariaValueText, ...props }: CheckboxProps) {
  return (
    <div
      className={classNames("flex items-center", props.className)}
      aria-valuetext={ariaValueText}
    >
      <input
        className={classNames(
          "giboo-checkbox form-check-input !outline-none !outline-0 !ring-0",
          size === "sm" ? "!h-4 !w-4" : "",
          size === "xs" ? "!h-3 !w-3" : "",
          inputClass,
        )}
        checked={props.checked}
        type="checkbox"
        value={props.value}
        id={props.id}
        onChange={props.onChange}
        disabled={props.disabled}
        readOnly={props.readOnly}
        aria-valuetext={ariaValueText}
      ></input>
      {props.label ? (
        <label htmlFor={props.id} aria-valuetext={ariaValueText}>
          <button
            onClick={props.onChange}
            className={classNames(
              "giboo-plain-btn no-outline prevent-select !px-2 text-left font-poppins !text-sm !text-black",
              props.labelClass,
            )}
            disabled={props.disabled}
            aria-valuetext={ariaValueText}
          >
            {props.label}
          </button>
        </label>
      ) : (
        <label className="w-full" htmlFor={props.id} aria-valuetext={ariaValueText}>
          {props.renderItem}
        </label>
      )}
    </div>
  );
}

export default Checkbox;
