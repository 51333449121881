import useSWR from "swr";
import axios from "axios";
import config from "../../api";
import { FundCharOverview } from "../../types/funder";
import { swrOptionDedupling5mins } from "../../types/swr";
interface FunderStageAnalysisOverviewState {
  data: FundCharOverview | undefined;
  error: any;
  isLoading: boolean;
}

function useFunderStageAnalysisOverview(funder_id?: string): FunderStageAnalysisOverviewState {
  const fetchFundOverView = async (_url: string) => {
    if (!_url || !funder_id) return new Promise<FundCharOverview>((resolve, reject) => reject());
    return axios.get(_url, config).then(({ data }) => data as FundCharOverview);
  };
  const { data, isLoading, error } = useSWR<FundCharOverview>(
    funder_id
      ? process.env.REACT_APP_API_URL +
          `/api/v2/donors/fundcharacteristics_overview?donor_id=${funder_id}`
      : null,
    fetchFundOverView,
    swrOptionDedupling5mins,
  );

  return {
    data: data,
    isLoading,
    error,
  };
}
export default useFunderStageAnalysisOverview;
