import axios from "axios";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import config from "../api";
import { IOrgMember } from "../types/org";
import { swrOptionFetchOnlyOnMount } from "../types/swr";
interface OrgMemberState {
  url: string | null;
  data: IOrgMember | undefined;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IOrgMember | undefined>;
}
function useOrgMemberInner(org_id?: string, user_id?: string): OrgMemberState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/member/${user_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IOrgMember>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => res.data as IOrgMember)
      .then((m) => {
        return {
          ...m,
          type: "user",
        };
      });
  };
  const { data, isLoading, error, isValidating } = useSWR<IOrgMember>(
    org_id && user_id && org_id.length === 24 ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );

  const revalidate = useCallback(() => mutate<IOrgMember>(url), [url]);
  return {
    url: org_id && user_id ? url : null,
    data: data,
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useOrgMemberInner;
