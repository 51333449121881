import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../api";
import { Onboarding } from "../app/onboarding";
import { useCallback } from "react";
import { swrOptionFetchOnlyOnMount } from "../types/swr";
interface useOrgState {
  data?: Onboarding;
  isLoading?: boolean;
  error?: object;
  isValidating: boolean;
  revalidate: () => Promise<Onboarding | undefined>;
}
function useOrg(org_id?: string): useOrgState {
  const { mutate } = useSWRConfig();
  const fetch = async (url: string) => {
    if (!url) return;
    return axios.get(url, config).then((response) => response.data);
  };
  const { data, isLoading, error, isValidating } = useSWR<Onboarding>(
    org_id ? process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}` : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const url = org_id ? process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}` : null;
  const revalidate = useCallback(() => mutate<Onboarding>(url), [url]);
  return {
    data: org_id && data && data._id ? data : undefined,
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useOrg;
