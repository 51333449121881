import classNames from "classnames";
import { Dispatch, SetStateAction } from "react";
import MultipleDropdownSelector from "./MultipleDropdownSelector";

interface PlainMultipleDropdownSelectorProps {
  id: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green" | "gray";
  data: string[];
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  maxHeight?: string;
  openAnimation?: boolean;
  placeholder?: string;
  disabled?: boolean;
  float?: boolean;
  compact?: boolean;
  downIcon?: boolean;
  leftIconClass?: string;
  rightIconClass?: string;
  defaultValue?: string;
  topPlaceholder?: string;
  checkbox?: boolean;
}
function PlainMultipleDropdownSelector({
  id,
  color,
  data,
  selected,
  setSelected,
  maxHeight,
  openAnimation = false,
  placeholder,
  disabled = false,
  float = false,
  compact = false,
  downIcon = false,
  leftIconClass,
  rightIconClass,
  defaultValue,
  topPlaceholder,
  checkbox = false,
}: PlainMultipleDropdownSelectorProps) {
  return (
    <div className="relative h-[94px] w-full">
      {topPlaceholder ? (
        <div className="py-2 font-poppins text-base font-semibold text-gray-900">
          {topPlaceholder}
        </div>
      ) : null}
      <MultipleDropdownSelector
        id={id}
        color={color}
        data={data}
        selected={selected}
        setSelected={setSelected}
        maxHeight={maxHeight}
        openAnimation={openAnimation}
        placeholder={placeholder}
        disabled={disabled}
        float={float}
        compact={compact}
        defaultValue={defaultValue}
        getKey={(item) => item}
        renderItem={(item) => (
          <div>
            {leftIconClass && <i className={leftIconClass}></i>}
            <span className="mx-2">{item}</span>
            {rightIconClass && <i className={rightIconClass}></i>}
          </div>
        )}
        renderButton={(item) => (
          <div className="flex items-center justify-between" title={item.join(", ")}>
            <div className="flex flex-wrap">
              {leftIconClass && <i className={leftIconClass}></i>}
              {item.length > 0 ? (
                <p className="mx-2 h-[22px] w-full overflow-hidden text-ellipsis">
                  {item.join(", ")}
                </p>
              ) : (
                <p className="dropdown-selector-placeholder m-0">{placeholder}</p>
              )}
              {rightIconClass && <i className={rightIconClass}></i>}
            </div>
            {downIcon && (
              <i className={classNames("fa-sharp fa-solid fa-chevron-down w-5", color)} />
            )}
          </div>
        )}
        checkbox={checkbox}
      />
    </div>
  );
}
export default PlainMultipleDropdownSelector;
