import { useCallback, useEffect, useState } from "react";
import {
  ISearchParamNullable,
  getObjectFromURLSearchParams,
  getURLSearchParamsFromSearchParam,
} from "../types/search";
import { useNavigate, useSearchParams } from "react-router-dom";

interface useGibooUpdateSearchFilterState {
  updateFilter: (diff: ISearchParamNullable) => void;
}
function useGibooUpdateSearchFilter(): useGibooUpdateSearchFilterState {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [diffQueue, setDiffQueue] = useState<ISearchParamNullable[]>([]);
  const updateFilter = useCallback(
    (diff: ISearchParamNullable) => {
      setDiffQueue((prev) => [...prev, diff]);
    },
    [setDiffQueue],
  );
  useEffect(() => {
    if (diffQueue.length === 0) return;
    const diff = diffQueue[0];
    const done_tagging = searchParams.get("done_tagging") === "true" ? true : false;
    const _obj = getObjectFromURLSearchParams(searchParams);
    if (!done_tagging) return;
    const obj = {
      ..._obj,
      ...diff,
    };
    if (
      getURLSearchParamsFromSearchParam(obj).toString() !==
      getURLSearchParamsFromSearchParam(_obj).toString()
    ) {
      navigate(`/search?${getURLSearchParamsFromSearchParam(obj, true).toString()}`);
    }
    setDiffQueue((prev) => prev.slice(1));
  }, [diffQueue, searchParams, setDiffQueue]);
  return { updateFilter };
}
export default useGibooUpdateSearchFilter;
