import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import {
  CartesianGrid,
  Dot,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import config from "../../api";
import {
  genLastNYears,
  getPercentageDiff,
  getYearsString,
  Order,
  toUsdLong,
  toUsdShort,
} from "../../utils/formatHelper";
import classNames from "classnames";
import { Wealth } from "../../types/funder";
import RenderIncrOrDecr from "../../pages/donor-detail-view/components/RenderIncrOrDecr";
import NoGraph from "../../pages/donor-detail-view/components/NoGraph";
import GibooGradientDiv from "../GibooGradientDiv";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import FinancialPredictionInfo from "../../pages/npo/dashboard/components/FinancialPredictionInfo";
interface DonorWealthInfoProps {
  returnCode: string;
  filedYears?: number[];
  wealthInformation: Wealth[];
  compact?: boolean;
}

interface DonorWealthInfoTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

function DonorWealthInfoBar({
  returnCode,
  filedYears,
  wealthInformation,
  compact,
}: DonorWealthInfoProps) {
  // useVisibilityTracker({
  //   elementIdOrRef: "donor-wealth-chart-view",
  //   onVisible() {
  //     console.log("chart-view: Donor wealth chart viewed for 5 sec");
  //   },
  //   timeout: 5000,
  // });
  const renderChart2 = () => {
    const foreCastCount = wealthInformation?.reduce((count, item) => {
      return item.is_predicted ? count + 1 : count;
    }, 0);
    const estimatedData = wealthInformation?.filter((i) => i.is_interpolated);
    const predictedData = wealthInformation?.filter((i) => i.is_predicted);
    const chartSeries: ApexAxisChartSeries = [
      {
        name: "assets",
        type: "line",
        data: wealthInformation?.map((i) => i.assets) || [],
        color: "#8ac1de",
      },
      {
        name: "revenue",
        type: "line",
        data: wealthInformation?.map((i) => i.revenue) || [],
        color: "#7EAB67",
      },
      {
        name: "contribution",
        type: "line",
        data: wealthInformation?.map((i) => i.contribution) || [],
        color: "#ECD332",
      },
    ];
    const chartOptions: ApexOptions = {
      chart: {
        toolbar: { show: false },
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => toUsdShort(Number(val), 1),
        distributed: true,
        offsetY: -8,
        offsetX: -5,
        textAnchor: "start",
        background: { enabled: false },
        style: {
          colors: ["#9B9B9B"],
          fontSize: "10px",
          fontFamily: "poppins",
          fontWeight: "400px",
        },
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 4,
        borderColor: "#DEDEDE",
        row: {
          colors: ["transparent"],
          opacity: 1,
        },
      },
      xaxis: {
        categories: wealthInformation?.map((i) => i.tax_year) || [],
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "poppins",
            fontWeight: 400,
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: "#DEDEDE",
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "poppins",
            fontWeight: 400,
          },
          formatter(val, opts) {
            return toUsdLong(val);
          },
        },
      },
      stroke: {
        curve: "monotoneCubic",
        width: 2,
        colors: ["#8ac1de", "#7EAB67", "#ECD332"],
      },
      forecastDataPoints: {
        count: foreCastCount,
        strokeWidth: undefined,
        dashArray: 4,
      },
      markers: {
        size: 4.5,
        shape: "circle",
        hover: {
          size: 4.5,
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const data = wealthInformation?.[dataPointIndex];
          const renderTitle = (value: string) => {
            return '<span class="font-poppins font-normal text-gray-700">' + value + "</span>";
          };
          const renderValue = (value: string) => {
            return '<span class="font-poppins font-semibold text-gray-900">' + value + "</span>";
          };
          if (data) {
            return (
              '<div class="giboo-box flex flex-col gap-1">' +
              '<p class="font-poppins font-semibold text-gray-900">' +
              data.tax_year +
              (data.is_predicted ? " (Predicted)" : data.is_interpolated ? " (Estimated)" : "") +
              "</p>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-blue-500">' +
              '<span class=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-blue-500"></span>' +
              "</span>" +
              renderTitle("Total assets:") +
              renderValue(toUsdLong(data.assets || 0)) +
              "</div>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-green-500">' +
              '<span class=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-green-500"></span>' +
              "</span>" +
              renderTitle("Total revenue:") +
              renderValue(toUsdLong(data?.revenue || 0)) +
              "</div>" +
              '<div class="inline-flex items-center gap-2 !text-sm">' +
              '<span class="relative h-[1px] w-[16px] bg-[#ECD332]">' +
              '<span class=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-[#ECD332]"></span>' +
              "</span>" +
              renderTitle("Total contributions") +
              renderValue(toUsdLong(data?.contribution || 0)) +
              "</div>" +
              "</div>"
            );
          }
        },
      },
    };

    return (
      <>
        <Chart options={chartOptions} series={chartSeries} type="line" width="650" />
        <div className="flex items-center  justify-center gap-4">
          {estimatedData && estimatedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-purple-100 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-estimated text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Estimated years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {estimatedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
          {predictedData && predictedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-dashed border-purple-500 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-predicted text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Predicted years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {predictedData?.map((i) => i.tax_year).join(", ")}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <FinancialPredictionInfo />
        </div>
      </>
    );
  };
  const renderTitle = () => {
    return (
      <div className="flex items-center justify-center gap-5">
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-blue-500">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-blue-500" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Total asset</h6>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-green-500">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-green-500" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Total revenue</h6>
        </div>
        <div className="flex items-center gap-2">
          <div className="relative h-[1px] w-[16px] bg-yellow-500">
            <span className=" absolute right-0 h-[8px] w-[8px] -translate-y-1 rounded-full bg-yellow-500" />
          </div>
          <h6 className="font-poppins  text-xs text-gray-700">Total contribution</h6>
        </div>
      </div>
    );
  };
  const renderLeftContent = () => {
    if (wealthInformation.length === 0) return <NoGraph />;
    return (
      <>
        {renderTitle()}
        <div className="min-h-[260px] min-w-[550px]" id="donor-wealth-chart-view">
          {renderChart2()}
        </div>
      </>
    );
  };
  const renderRightContent = () => {
    if (wealthInformation.length === 0)
      return (
        <div className={classNames("flex h-full w-full ", compact ? "flex-row" : "flex-col")}>
          <p className="font-semibold">Overview</p>
          <div className={classNames("font-poppings mt-4 flex flex-col gap-[4px]")}>
            <h5 className=" text-sm text-gray-700">Total asset</h5>
            <h3>No data</h3>
          </div>
          <div className={classNames("font-poppings mt-4 flex flex-col gap-[4px]")}>
            <h5 className=" text-sm text-gray-700">Total revenue</h5>
            <h3>No data</h3>
          </div>
          <div className={classNames("font-poppings mt-4 flex flex-col gap-[4px]")}>
            <h5 className=" text-sm text-gray-700">Total contribution</h5>
            <h3>No data</h3>
          </div>
        </div>
      );
    const latestYearData: Wealth = wealthInformation[wealthInformation.length - 1];
    const previousYearData: Wealth =
      wealthInformation.length > 1
        ? wealthInformation[wealthInformation.length - 2]
        : wealthInformation[0];

    return (
      <div className="flex h-full w-full flex-col">
        <p className="font-semibold">
          Overview
          <span className="px-1 text-sm font-light text-gray-700">
            ({latestYearData?.tax_year})
          </span>
        </p>
        <div className={classNames("mt-4 flex flex-wrap gap-6")}>
          <div
            className={classNames(
              "font-poppings flex flex-col gap-[4px] ",
              compact && "max-w-[140px]",
            )}
          >
            <h5 className=" text-sm text-gray-700">Total asset</h5>
            <h3>{toUsdShort(latestYearData?.assets || 0, 1)}</h3>
            <RenderIncrOrDecr
              value={getPercentageDiff(previousYearData.assets, latestYearData.assets)}
            />
          </div>
          <div
            className={classNames(
              "font-poppings flex flex-col gap-[4px] ",
              compact && "max-w-[140px]",
            )}
          >
            <h5 className=" text-sm text-gray-700">Total revenue</h5>
            <h3>{toUsdShort(latestYearData?.revenue || 0, 1)}</h3>
            <RenderIncrOrDecr
              value={getPercentageDiff(previousYearData.revenue, latestYearData.revenue)}
            />
          </div>
          <div
            className={classNames(
              "font-poppings flex flex-col gap-[4px] ",
              compact && "max-w-[140px]",
            )}
          >
            <h5 className=" text-sm text-gray-700">Total contribution</h5>
            <h3>{toUsdShort(latestYearData?.contribution || 0, 1)}</h3>
            <RenderIncrOrDecr
              value={getPercentageDiff(previousYearData.contribution, latestYearData.contribution)}
            />
          </div>
        </div>
        <h6 className={classNames("mt-5 text-gray-700", compact ? "self-start" : "self-end")}>
          {`Dataset from: IRS ${returnCode}`}
        </h6>
      </div>
    );
  };
  const renderHeader = () => {
    if (wealthInformation.length === 0)
      return (
        <div className="my-4 rounded bg-gray-100 px-4 py-[10px]">
          <h5 className="font-poppins text-gray-700">
            We apologize for the inconvenience, but currently, there is no information available. We
            are actively working to collect and provide the necessary data as soon as it becomes
            accessible.
          </h5>
        </div>
      );
    const latestYearData: Wealth = wealthInformation[wealthInformation.length - 1];
    const previousYearData: Wealth =
      wealthInformation.length > 1
        ? wealthInformation[wealthInformation.length - 2]
        : wealthInformation[0];
    const avgAssets =
      wealthInformation.reduce((prev, cur) => prev + cur.assets, 0) / wealthInformation.length;
    const assetsPercentChangeComparedToAvg = getPercentageDiff(avgAssets, latestYearData.assets);
    const consistentAsset = Math.abs(assetsPercentChangeComparedToAvg) < 10;
    const contributionPercentage = getPercentageDiff(
      previousYearData.contribution,
      latestYearData.contribution,
    );
    const finanicialAssetPosition =
      assetsPercentChangeComparedToAvg >= 0 && contributionPercentage >= 0
        ? "strong"
        : assetsPercentChangeComparedToAvg >= 0 || contributionPercentage >= 0
        ? "moderate"
        : "weak";
    return (
      <GibooGradientDiv borderSize={2} wrapperClassName="my-4">
        <div className="rounded bg-gray-100 px-4 py-[10px]">
          <h5 className="font-poppins text-gray-700">
            This funder has a <b className="text-purple-500">{finanicialAssetPosition}</b> financial
            position. Total contribution of this funder{" "}
            {contributionPercentage === 0 ? (
              <>{"remained same"}</>
            ) : (
              <>
                {contributionPercentage > 0
                  ? "increased"
                  : contributionPercentage < 0
                  ? "decreased"
                  : ""}{" "}
                by{" "}
                <b className="text-purple-500">{`${Math.round(
                  Math.abs(contributionPercentage),
                )}% `}</b>
              </>
            )}
            {(assetsPercentChangeComparedToAvg > 0 || consistentAsset) && (
              <>
                and total asset of this funder remained{" "}
                <b className="text-purple-500">
                  {assetsPercentChangeComparedToAvg > 0 ? "increased" : "relatively consistent"}
                </b>
              </>
            )}{" "}
            in <span>{getYearsString(filedYears || [])}.</span>
          </h5>
        </div>
      </GibooGradientDiv>
    );
  };
  return (
    <>
      {renderHeader()}
      <div className="container mx-auto flex min-h-[370px] items-center">
        <div className={classNames("flex min-h-[345px] w-full flex-col gap-5")}>
          <div className={classNames("w-full min-w-[330px] grow")}>{renderLeftContent()}</div>
          <div className={classNames("flex min-w-[220px] grow-0 flex-col")}>
            {renderRightContent()}
          </div>
        </div>
      </div>
    </>
  );
}

const MemoizedDonorWealthInfoBary = memo(DonorWealthInfoBar);
export default MemoizedDonorWealthInfoBary;
