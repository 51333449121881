import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolBar from "./EditorToolBar";
import TextInput from "../../../../../components/tailwind/TextInput";
// suggestion
import ICON_REFRESH from "../../../../../assets/loi/refresh.svg";
import ICON_IMPROVE from "../../../../../assets/loi/done.svg";
import ICON_LONGER from "../../../../../assets/loi/longer.svg";
import ICON_SHORTER from "../../../../../assets/loi/shorter.svg";
import ICON_STAR from "../../../../../assets/loi/star.svg";
import ICON_MAGIC_WAND from "../../../../../assets/loi/magic-wand.svg";
import Spinner from "../../../../../components/Spinner";
import { AiSuggestionService } from "../../../../../services/AIsuggestion.services";
import { getWordLength } from "../../../../../utils/formatHelper";
interface LetterEditorRenderProps {
  setContent: React.Dispatch<React.SetStateAction<string>>;
}

interface Iprops {
  quillRefProp?: React.RefObject<ReactQuill>;
  customClass?: string | undefined;
  data: string;
  enableAI: boolean;
  updateContent: (value: string) => void;
  updateDownloadContent: (pdf: any) => void;
  disableEditor?: boolean;
  letterEditorWrapper?: string;
  style?: any;
  children?: (props: LetterEditorRenderProps) => React.ReactNode;
  textContainerClass?: string;
  toolbarClass?: string;
  showWordLength?: boolean;
}
enum AI_SUGGESTIONS {
  IMPROVE = "improve",
  MAKE_SHORTER = "shorter",
  MAKE_LONGER = "longer",
}
const SUGGESTIONS = {
  [AI_SUGGESTIONS.IMPROVE]: "Check spelling and grammar",
  [AI_SUGGESTIONS.MAKE_SHORTER]: "Make shorter",
  [AI_SUGGESTIONS.MAKE_LONGER]: "Make longer",
};

type IsFunction<T> = T extends (...args: any[]) => any ? T : never;
const isFunction = <T extends object>(value: T): value is IsFunction<T> =>
  typeof value === "function";
function LetterEditor({
  quillRefProp,
  data,
  enableAI,
  customClass,
  updateDownloadContent,
  updateContent,
  disableEditor = false,
  letterEditorWrapper,
  style,
  textContainerClass,
  toolbarClass,
  children,
  showWordLength = false,
}: Iprops) {
  if (children && !isFunction(children)) {
    throw new Error("children is mandatory and needs to be a function");
  }
  const myQuillRef = useRef<ReactQuill>(null);
  const quillRef = quillRefProp ? quillRefProp : myQuillRef;
  const [AIquery, setAIquery] = useState<string>("");
  const [selectedWord, setSelectedWord] = useState("");
  const [AISuggestion, setAISuggestion] = useState<AI_SUGGESTIONS | undefined>();
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  const [content, setContent] = useState<string>(data);
  const [AiLoading, setAILoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [initialData, setInitialData] = useState<string>("");
  const [selectionRange, setSelectionRange] = useState<{ index: number; length: number } | null>();
  const selectionRangeRef = useRef<{ index: number; length: number } | null>();
  useEffect(() => {
    if (AISuggestion) {
      handleAiSuggestion();
    }
  }, [AISuggestion]);
  useEffect(() => {
    if (data) {
      setContent(content);
      setInitialData(data);
    }
  }, [data]);
  useEffect(() => {
    quillRef && updateDownloadContent(quillRef?.current?.getEditor().getContents());
    updateContent(content);
  }, [content, quillRef, quillRef?.current]);
  const replaceText = (suggestContent: string) => {
    // console.log({ suggestContent, selectionRange });
    const quill = quillRef?.current?.getEditor();
    selectionRange && quill?.deleteText(selectionRange.index, selectionRange.length);
    selectionRange && quill?.insertText(selectionRange.index, suggestContent);
    selectionRange && quill?.setSelection(selectionRange.index, suggestContent.length);
  };
  const handleAiSuggestion = () => {
    if (AIquery === "") return;
    setIsDisabled(true);
    setAILoading(true);
    AiSuggestionService({
      content: initialData,
      instruction: AIquery,
      selected_phrase: selectedWord,
    })
      ?.then((res) => {
        const aiSuggestion = res.revised_phrase;
        replaceText(aiSuggestion);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsDisabled(false);
        setAILoading(false);
        setAIquery("");
        removeSelection();
      });
  };
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0, height: 0 });
  const removeSelection = () => {
    const editor = quillRef?.current?.getEditor();
    if (selectionRangeRef.current) {
      const prevSelectionRange = selectionRangeRef.current;
      editor?.formatText(prevSelectionRange.index, prevSelectionRange.length, {
        background: "white",
      });
    }
  };
  const handleSelectionChange = (range: any, oldRange: any, source: any) => {
    if (AiLoading) return;
    removeSelection();
    const editor = quillRef?.current?.getEditor();
    if (range) {
      const text = source.getText(range.index, range.length);
      setSelectionRange({ index: range.index, length: range.length });
      if (editor) {
        const bounds = editor.getBounds(range.index, range.length);
        setSelectedWord(text);
        editor.formatText(range.index, range.length, {
          background: "#e6e1f2",
        });
        selectionRangeRef.current = selectionRange;
        if (bounds && text !== "") {
          const tooltipTop = bounds.top + bounds.height + 5;
          const tooltipHeight = bounds.height;
          setTooltipPosition({
            x: 0,
            y: tooltipTop,
            height: tooltipHeight,
          });
          setTooltipVisible(true);
          setShowSuggestion(false);
          setAISuggestion(undefined);
        } else {
          removeSelection();
          setTooltipVisible(false);
          setShowSuggestion(false);
          setSelectionRange(null);
        }
      }
    }
  };

  const renderAieditor = () => {
    return (
      <>
        {tooltipVisible && enableAI && (
          <div
            style={{
              left: `${tooltipPosition.x}px`,
              top: `${tooltipPosition.y}px`,
              bottom: "0px",
            }}
            className="absolute mx-5 "
          >
            <div
              className="absolute z-10 h-fit w-[500px]"
              onClick={(e) => {
                e.preventDefault();
                setShowSuggestion((prev) => !prev);
              }}
            >
              <TextInput
                id="giboo-ai-text"
                value={AiLoading ? "Giboo AI writing" : AIquery}
                wrapperClass=""
                className={classNames(
                  "!h-[45px] w-[510px] select-none !pl-[30] shadow placeholder:text-sm",
                  {
                    ["!pl-[30] !text-gray-900"]: AiLoading,
                  },
                )}
                disabled={AiLoading}
                placeholder={AiLoading ? "Giboo AI writing" : "Give Giboo AI any custom directions"}
                handleOnChange={(e) => {
                  e.preventDefault();
                  setAIquery(e.target.value);
                }}
                handleOnKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation();
                    handleAiSuggestion();
                  }
                }}
                handleOnBlur={() => {
                  setAISuggestion(undefined);
                }}
                iconLeft={
                  AiLoading ? (
                    <Spinner size="sm" borderSize={2} wrapperClass="px-1" />
                  ) : (
                    <div className="px-1">
                      <img src={ICON_MAGIC_WAND} alt="improve" className="h-5 w-5" />
                    </div>
                  )
                }
                iconRight={
                  !AiLoading && (
                    <div
                      className="cursor-pointer px-1 hover:scale-110"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAiSuggestion();
                      }}
                    >
                      <img src={ICON_REFRESH} alt="improve" className="h-5 w-5" />
                    </div>
                  )
                }
              />
            </div>
            {showSuggestion && !AiLoading && (
              <div
                className="absolute top-[48px] z-10 flex h-fit !w-[300px] select-none flex-col"
                onClick={() => setShowSuggestion((prev) => !prev)}
              >
                <div
                  className={classNames(
                    "inline-flex h-[46px] cursor-pointer items-center rounded border-[0.5px] border-gray-300 bg-white px-3 py-2 shadow-sm hover:bg-gray-100",
                    {
                      ["!bg-gray-200 text-gray-800"]: AISuggestion === AI_SUGGESTIONS.MAKE_SHORTER,
                    },
                  )}
                  onClick={() => {
                    setAISuggestion(AI_SUGGESTIONS.MAKE_SHORTER);
                    setAIquery(SUGGESTIONS[AI_SUGGESTIONS.MAKE_SHORTER]);
                  }}
                >
                  <img src={ICON_SHORTER} alt="shorter" className="mr-1 h-5 w-5" /> Make shorter
                </div>
                <div
                  className={classNames(
                    "inline-flex h-[46px] cursor-pointer items-center rounded border-[0.5px] border-gray-300 bg-white px-3 py-2 shadow-sm hover:bg-gray-100",
                    {
                      ["!bg-gray-200 text-gray-800"]: AISuggestion === AI_SUGGESTIONS.MAKE_LONGER,
                    },
                  )}
                  onClick={() => {
                    setAISuggestion(AI_SUGGESTIONS.MAKE_LONGER);
                    setAIquery(SUGGESTIONS[AI_SUGGESTIONS.MAKE_LONGER]);
                  }}
                >
                  <img src={ICON_LONGER} alt="longer" className="mr-1 h-5 w-5" /> Make longer
                </div>
                <div
                  className={classNames(
                    "inline-flex h-[46px] cursor-pointer items-center rounded border-[0.5px] border-gray-300 bg-white px-3 py-2 shadow-sm hover:bg-gray-100",
                    { ["!bg-gray-200 text-gray-800"]: AISuggestion === AI_SUGGESTIONS.IMPROVE },
                  )}
                  onClick={() => {
                    setAISuggestion(AI_SUGGESTIONS.IMPROVE);
                    setAIquery(SUGGESTIONS[AI_SUGGESTIONS.IMPROVE]);
                  }}
                >
                  <img src={ICON_IMPROVE} alt="improve" className="mr-1 h-5 w-5" /> Check spelling
                  and grammar
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  const text = quillRef?.current?.getEditor().getText() || "";
  return (
    <div className={classNames("rounded outline-none", letterEditorWrapper)}>
      {children && children({ setContent })}
      <EditorToolBar className={toolbarClass} />
      <div className={classNames("text-editor relative", textContainerClass, "!overflow-visible")}>
        {renderAieditor()}
        <ReactQuill
          {...(enableAI && { onChangeSelection: handleSelectionChange })}
          ref={quillRefProp ? quillRefProp : myQuillRef}
          theme="snow"
          style={style}
          className={classNames("px-2 selection:bg-purple-50", customClass, {
            ["select-none"]: AiLoading,
            [" bg-gray-50"]: disableEditor || isDisabled,
          })}
          preserveWhitespace
          value={content}
          readOnly={disableEditor || isDisabled}
          onChange={(value) => {
            setContent(value);
          }}
          modules={{
            toolbar: {
              container: "#toolbar",
            },
          }}
        />
        {showWordLength && (
          <div className="flex flex-wrap items-start gap-x-2 gap-y-0 px-5 py-2">
            <h6 className="font-poppins text-xs font-normal text-gray-600">{`${getWordLength(
              text,
            )} words`}</h6>
            <h6 className="font-poppins text-xs font-normal text-gray-600">{`·`}</h6>
            <h6 className="font-poppins text-xs font-normal text-gray-600">{`${
              text.trim().length
            } characters`}</h6>
          </div>
        )}
      </div>
    </div>
  );
}
export default LetterEditor;
