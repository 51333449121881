import { useCallback, useEffect, useState } from "react";
import {
  SearchQuery,
  SearchType,
  UnifiedSearchResult,
  getSearchResultKey,
} from "../../types/search";
import useHiddenSearchItems from "./useHiddenSearchItems";

interface HidingState {
  data: string[];
  dataWithQuery: string[];
  detail: UnifiedSearchResult[];
  detailWithQuery: UnifiedSearchResult[];
  maxCountForPage: number;
  maxCountWithQueryForPage: number;
  isLoading: boolean;
  isValidating: boolean;
  error: object;
  isHidden: (key: string) => boolean;
  setHideFunder: (uid: number, hide: boolean) => Promise<void | number[]>;
  setHideGrant: (uid: number, hide: boolean) => Promise<void | number[]>;
  setHideVirtualGrant: (uid: number, hide: boolean) => Promise<void | number[]>;
  setHideNPO: (uid: number, hide: boolean) => Promise<void | number[]>;
  setHidePastGrant: (uid: number, hide: boolean) => Promise<void | number[]>;
}
const emptyList: string[] = [];
const emptyDetailList: UnifiedSearchResult[] = [];
export default function useTotalHidden({
  type,
  use_query = false,
  query,
  fetch_detail = false,
  skip = 0,
  limit = 10,
}: {
  type: SearchType;
  use_query?: boolean;
  query?: SearchQuery;
  fetch_detail?: boolean;
  skip?: number;
  limit?: number;
}): HidingState {
  const search_by_name = type === "by_name";
  const [maxCount, setMaxCount] = useState<number>(0);
  const [maxCountWithQuery, setMaxCountWithQuery] = useState<number>(0);
  const {
    dataWithQuery: funderWithQuery,
    data: funder,
    detailWithQuery: funderDetailWithQuery,
    detail: funderDetail,
    isLoading: isFunderLoading,
    isValidating: isFunderValidating,
    error: funderError,
    setHide: setHideFunder,
  } = useHiddenSearchItems({
    type: "funder",
    use_query,
    query,
    fetch_detail,
    search_by_name,
    enable: type === "funder" || type === "by_name",
    skip,
    limit,
  });
  const {
    dataWithQuery: grantWithQuery,
    data: grant,
    detailWithQuery: grantDetailWithQuery,
    detail: grantDetail,
    isLoading: isGrantLoading,
    isValidating: isGrantValidating,
    error: grantError,
    setHide: setHideGrant,
  } = useHiddenSearchItems({
    type: "grant",
    use_query,
    query,
    fetch_detail,
    search_by_name,
    enable: type === "grant" || type === "by_name",
    skip,
    limit,
  });
  const {
    dataWithQuery: virtualGrantWithQuery,
    data: virtualGrant,
    detailWithQuery: virtualGrantDetailWithQuery,
    detail: virtualGrantDetail,
    isLoading: isVirtualGrantLoading,
    isValidating: isVirtualGrantValidating,
    error: virtualGrantError,
    setHide: setHideVirtualGrant,
  } = useHiddenSearchItems({
    type: "virtual_grant",
    use_query,
    query,
    fetch_detail,
    search_by_name,
    enable: type === "virtual_grant" || type === "by_name",
    skip,
    limit,
  });
  const {
    dataWithQuery: npoWithQuery,
    data: npo,
    detailWithQuery: npoDetailWithQuery,
    detail: npoDetail,
    isLoading: isNPOLoading,
    isValidating: isNPOValidating,
    error: npoError,
    setHide: setHideNPO,
  } = useHiddenSearchItems({
    type: "npo",
    use_query,
    query,
    fetch_detail,
    search_by_name,
    enable: type === "npo" || type === "by_name",
    skip,
    limit,
  });
  const {
    dataWithQuery: pastGrantWithQuery,
    data: pastGrant,
    detailWithQuery: pastGrantDetailWithQuery,
    detail: pastGrantDetail,
    isLoading: isPastGrantLoading,
    isValidating: isPastGrantValidating,
    error: pastGrantError,
    setHide: setHidePastGrant,
  } = useHiddenSearchItems({
    type: "past_grant",
    use_query,
    query,
    fetch_detail,
    search_by_name,
    enable: type === "past_grant",
    skip,
    limit,
  });
  const {
    dataWithQuery: grantSpecificFunderWithQuery,
    data: grantSpecificFunder,
    detailWithQuery: grantSpecificFunderDetailWithQuery,
    detail: grantSpecificFunderDetail,
    isLoading: isGrantSpecificFunderLoading,
    isValidating: isGrantSpecificFunderValidating,
    error: grantSpecificFunderError,
    setHide: setHideGrantSpecificFunder,
  } = useHiddenSearchItems({
    type: "specific_funder_grant",
    use_query,
    query,
    fetch_detail,
    search_by_name,
    enable: type === "specific_funder_grant",
    skip,
    limit,
  });
  const [data, setData] = useState<string[]>([]);
  const [dataWithQuery, setDataWithQuery] = useState<string[]>([]);
  const [detail, setDetail] = useState<UnifiedSearchResult[]>([]);
  const [detailWithQuery, setDetailWithQuery] = useState<UnifiedSearchResult[]>([]);
  useEffect(() => {
    setDataWithQuery([
      ...(type === "funder" || type === "by_name"
        ? funderWithQuery.map((i) =>
            getSearchResultKey({
              search_type: "funder",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
      ...(type === "grant" || type === "by_name"
        ? grantWithQuery.map((i) =>
            getSearchResultKey({
              search_type: "grant",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
      ...(type === "virtual_grant" || type === "by_name"
        ? virtualGrantWithQuery.map((i) =>
            getSearchResultKey({
              search_type: "grant",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
      ...(type === "npo" || type === "by_name"
        ? npoWithQuery.map((i) =>
            getSearchResultKey({
              search_type: "npo",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
      ...(type === "past_grant"
        ? pastGrantWithQuery.map((i) =>
            getSearchResultKey({
              search_type: "past_grant",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
      ...(type === "specific_funder_grant"
        ? grantSpecificFunderWithQuery.map((i) =>
            getSearchResultKey({
              search_type: "grant",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
    ]);
    setMaxCountWithQuery(
      [
        type === "funder" || type === "by_name" ? funderWithQuery.length : 0,
        type === "grant" || type === "virtual_grant" || type === "by_name"
          ? grantWithQuery.length
          : 0,
        type === "virtual_grant" || type === "by_name" ? virtualGrantWithQuery.length : 0,
        type === "npo" || type === "by_name" ? npoWithQuery.length : 0,
        type === "past_grant" ? pastGrantWithQuery.length : 0,
        type === "specific_funder_grant" ? grantSpecificFunderWithQuery.length : 0,
      ].reduce((prev, cur) => Math.max(prev, cur), 0),
    );
  }, [
    type,
    funderWithQuery,
    virtualGrantWithQuery,
    grantWithQuery,
    npoWithQuery,
    pastGrantWithQuery,
    grantSpecificFunderWithQuery,
  ]);
  useEffect(() => {
    setDetailWithQuery([
      ...(type === "funder" || type === "by_name" ? funderDetailWithQuery : emptyDetailList),
      ...(type === "grant" || type === "by_name" ? grantDetailWithQuery : emptyDetailList),
      ...(type === "virtual_grant" || type === "by_name"
        ? virtualGrantDetailWithQuery
        : emptyDetailList),
      ...(type === "npo" || type === "by_name" ? npoDetailWithQuery : emptyDetailList),
      ...(type === "past_grant" ? pastGrantDetailWithQuery : emptyDetailList),
      ...(type === "specific_funder_grant" ? grantSpecificFunderDetailWithQuery : emptyDetailList),
    ]);
  }, [
    type,
    funderDetailWithQuery,
    grantDetailWithQuery,
    virtualGrantDetailWithQuery,
    npoDetailWithQuery,
    pastGrantDetailWithQuery,
    grantSpecificFunderDetailWithQuery,
  ]);
  useEffect(() => {
    setDetail([
      ...(type === "funder" || type === "by_name" ? funderDetail : emptyDetailList),
      ...(type === "grant" || type === "by_name" ? grantDetail : emptyDetailList),
      ...(type === "virtual_grant" || type === "by_name" ? virtualGrantDetail : emptyDetailList),
      ...(type === "npo" || type === "by_name" ? npoDetail : emptyDetailList),
      ...(type === "past_grant" ? pastGrantDetail : emptyDetailList),
      ...(type === "specific_funder_grant" ? grantSpecificFunderDetail : emptyDetailList),
    ]);
  }, [
    type,
    funderDetail,
    grantDetail,
    virtualGrantDetail,
    npoDetail,
    pastGrantDetail,
    grantSpecificFunderDetail,
  ]);
  useEffect(() => {
    setData([
      ...(type === "funder" || type === "by_name"
        ? funder.map((i) =>
            getSearchResultKey({
              search_type: "funder",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
      ...(type === "grant" || type === "by_name"
        ? grant.map((i) =>
            getSearchResultKey({
              search_type: "grant",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
      ...(type === "virtual_grant" || type === "by_name"
        ? virtualGrant.map((i) =>
            getSearchResultKey({
              search_type: "grant",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
      ...(type === "npo" || type === "by_name"
        ? npo.map((i) =>
            getSearchResultKey({
              search_type: "npo",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
      ...(type === "past_grant"
        ? pastGrant.map((i) =>
            getSearchResultKey({
              search_type: "past_grant",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
      ...(type === "specific_funder_grant"
        ? grantSpecificFunder.map((i) =>
            getSearchResultKey({
              search_type: "grant",
              _id: "",
              uid: i,
              rank_conf: 0,
              name: "",
              service_specific_loc: true,
            }),
          )
        : emptyList),
    ]);
    setMaxCount(
      [
        type === "funder" || type === "by_name" ? funder.length : 0,
        type === "grant" || type === "by_name" ? grant.length : 0,
        type === "virtual_grant" || type === "by_name" ? virtualGrant.length : 0,
        type === "npo" || type === "by_name" ? npo.length : 0,
        type === "past_grant" ? pastGrant.length : 0,
        type === "specific_funder_grant" ? grantSpecificFunder.length : 0,
      ].reduce((prev, cur) => Math.max(prev, cur), 0),
    );
  }, [type, funder, grant, virtualGrant, npo, pastGrant, grantSpecificFunder]);
  const isHidden = useCallback(
    (key: string) => {
      return data.includes(key);
    },
    [data],
  );
  return {
    data,
    dataWithQuery,
    detail,
    detailWithQuery,
    maxCountForPage: maxCount,
    maxCountWithQueryForPage: maxCountWithQuery,
    isLoading:
      isFunderLoading ||
      isGrantLoading ||
      isVirtualGrantLoading ||
      isNPOLoading ||
      isPastGrantLoading ||
      isGrantSpecificFunderLoading,
    isValidating:
      isFunderValidating ||
      isGrantValidating ||
      isVirtualGrantValidating ||
      isNPOValidating ||
      isPastGrantValidating ||
      isGrantSpecificFunderValidating,
    error:
      funderError ||
      grantError ||
      virtualGrantError ||
      npoError ||
      pastGrantError ||
      grantSpecificFunderError,
    isHidden,
    setHideFunder,
    setHideGrant: type === "specific_funder_grant" ? setHideGrantSpecificFunder : setHideGrant,
    setHideVirtualGrant,
    setHideNPO,
    setHidePastGrant,
  };
}
