import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../api";
import { useAppDispatch, useAppSelector } from "../app/store";
import PrimaryButton from "../components/PrimaryButton";
import useUser from "../hooks/useUser";
import { fetchAndSelectOrgAsync, fetchOnboardingAsync } from "../app/onboardingSlice";
import { TutorType } from "../components/AppTour";
import { setUserDictionaryValue } from "../hooks/useUserDictionary";
import useProjects from "../hooks/project/useProjects";
import { deleteProject } from "../hooks/project/useProject";
import useOrgs from "../hooks/useOrgs";

function ResetView() {
  const [isLoading, setLoading] = useState(false);
  const [done, setDone] = useState<boolean>(false);
  const [user, setUser] = useUser();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [projects] = useProjects();
  const { orgs } = useOrgs();

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "DEV" || orgs.length === 0 || isLoading || done) return;
    const do_action = async () => {
      setLoading(true);
      const resetGuidance = () => {
        const enumKeys = Object.keys(TutorType) as Array<keyof typeof TutorType>;
        const promises = enumKeys.map((i) =>
          setUserDictionaryValue(TutorType[i], {
            type: i,
            completed: false,
            start: false,
            isPaused: false,
            pausedIndex: 0,
          }),
        );
        Promise.all(promises).then(() => {
          console.log("Tutor reset done...");
        });
      };
      console.log(
        "my",
        orgs?.filter((p) => p.owner?._id === user?._id),
        orgs,
      );
      const promises = [
        ...(orgs
          ?.filter((p) => p.owner?._id === user?._id)
          .map((item) =>
            axios.delete(process.env.REACT_APP_API_URL + `/api/v2/orgs/${item._id}`, config),
          ) || []),
        axios.delete(process.env.REACT_APP_API_URL + `/api/v2/users/dictionary`, config),
      ];
      await Promise.all(promises)
        .then((responses) => {
          dispatch(fetchAndSelectOrgAsync(""));
          dispatch(fetchOnboardingAsync());
          resetGuidance();
        })
        // .catch((error) => console.log("error", error))
        .finally(() => {
          setLoading(false);
          setDone(true);
        });
    };
    // if (isLoading && fetched) {
    //   do_action();
    // }
    do_action();
  }, [isLoading, orgs]);

  return (
    <>
      {process.env.REACT_APP_ENV === "DEV" ? (
        <>
          <h4>Your account is reset</h4>
          <PrimaryButton
            id={"btn-home"}
            label="Go home"
            handleOnClick={() => navigate("/")}
            disabled={isLoading || !done}
          />
        </>
      ) : (
        <div className="no-page">
          <h1>Not Found</h1>
        </div>
      )}
    </>
  );
}

export default ResetView;
