import classNames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import { Divider, Steps } from "rsuite";
import Button from "../../../../components/tailwind/Button";
import Spacer from "../../../../components/Spacer";
import IMG_EXAMPLE from "../../../../assets/financial/revenue-expense-stream-placeholder.svg";
import useOrg from "../../../../hooks/useOrg";
import useNPOIRSProfile from "../../../../hooks/useNPOIRSProfile";
import IncreaseTag from "../component/IncreaseTag";
import DecreaseTag from "../component/DecreaseTag";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import NpoFinancialRevenueExpenses from "../../dashboard/components/NpoFinancialRevenueExpenses";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import CloseButton from "../../../../components/CloseButton";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";

export default function RevenueExpenseStream({
  onBack,
  onNext,
  npo_id,
  org_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  org_id?: string;
  isBack?: boolean;
}) {
  const [currentStep, setCurrentStep] = useState<number>(isBack ? 1 : 0);
  useEffect(() => {
    if (isBack) {
      setTimeout(() => {
        scrollToElement("businessmodel-step-2");
      }, 300);
    }
  }, [isBack]);
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value >= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };
  const scrollToElement = (id: string) => {
    const element = document?.getElementById(id);
    const container = document.getElementById("financial-scroll-container");
    if (element && container) {
      const elementTop = element.getBoundingClientRect().top + container.scrollTop;
      const adjustedOffset = elementTop - 120;
      container.scrollTo({ top: adjustedOffset, behavior: "smooth" });
    }
  };
  const renderStep1 = () => {
    return (
      <RevenueExpenseStreamInfo>
        <div
          className={classNames(
            "flex h-[38px] items-center justify-start gap-2",
            currentStep === 0 ? "flex" : "hidden",
          )}
        >
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              if (currentStep < 1) {
                setCurrentStep(1);
                setTimeout(() => {
                  scrollToElement("businessmodel-step-2");
                }, 300);
              }
            }}
            label="See the assessment result"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
        <Spacer height="100px" />
      </RevenueExpenseStreamInfo>
    );
  };

  return (
    <div className="flex max-w-[760px] flex-col">
      <Steps current={currentStep} vertical>
        <Steps.Item
          title={false}
          className="!-ml-[40px] !mt-0"
          icon={currentStep > 0 ? renderDot(1) : <></>}
          description={renderStep1()}
        />
        {currentStep > 0 && (
          <Steps.Item
            icon={renderDot(2)}
            className="!-ml-[40px]"
            description={
              <Step2
                onBack={() => {
                  setCurrentStep(0);
                }}
                npo_id={npo_id}
                org_id={org_id}
                onNext={() => {
                  onNext?.();
                }}
              />
            }
          />
        )}
      </Steps>
    </div>
  );
}
const Step2 = ({
  onBack,
  onNext,
  npo_id,
  org_id,
  recomendation = true,
}: {
  onBack: () => void;
  onNext: () => void;
  npo_id?: string;
  org_id?: string;
  recomendation?: boolean;
}) => {
  const { data, update } = useOrgProfile(org_id);
  const prev = data?.skip_loading || [];
  const mxEvent = useGibooMixpanel(undefined, true);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.financial.revenue_and_expense.successful);
  }, []);
  return (
    <div className="mt-3 flex min-h-[600px] flex-col gap-4" id="businessmodel-step-2">
      <h6 className="text-base font-semibold text-gray-900">
        Your organization&apos;s assessment result
      </h6>
      <NpoFinancialRevenueExpenses
        npo_id={npo_id}
        org_id={org_id}
        recomendation
        showChartLoader={!data.skip_loading?.includes("revenue_expense_stream")}
      >
        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              data &&
                update({
                  skip_loading: [
                    ...prev.filter((p) => p !== "revenue_expense_stream"),
                    "revenue_expense_stream",
                  ],
                });
              onNext?.();
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </NpoFinancialRevenueExpenses>
      <Spacer height="150px" />
    </div>
  );
};

export function RevenueExpenseStreamInfo({
  children,
  handleClose,
}: {
  children?: ReactNode;
  handleClose?: () => void;
}) {
  return (
    <div className={classNames("flex min-h-[600px] flex-col gap-4", !handleClose && "mt-3")}>
      <div className="inline-flex w-full items-center justify-between border-b border-gray-300">
        <h6 className="text-base font-semibold text-gray-900 underline underline-offset-[6px]">
          Revenue & expense stream
        </h6>
        {handleClose && <CloseButton onClick={() => handleClose?.()} />}
      </div>
      <div className="flex min-h-[500px] flex-col rounded border border-gray-300 bg-white p-5">
        {/* section 1 */}
        <div className="flex flex-col gap-10">
          <div>
            <h5 className="font-poppins text-base font-semibold text-black">
              What is revenue & expense stream?
            </h5>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800">
              Revenue & expense stream is a comprehensive financial analysis tool that provides a
              detailed overview of a nonprofit organization&apos;s fiscal health. It encapsulates
              key financial metrics crucial for assessing the organization&apos;s financial
              sustainability.
              <br />
              <br />
              This financial metrics offer a comprehensive understanding of a nonprofit&apos;s
              financial position. They provide insights into the organization&apos;s income,
              expenditures, and the resulting financial surplus or deficit. Regular monitoring and
              analysis of these metrics are essential for making informed financial decisions,
              ensuring sustainability, and guiding strategic planning for the nonprofit&apos;s
              mission and objectives.
            </h6>
          </div>
          <div className="flex min-h-[330px] justify-center">
            <img src={IMG_EXAMPLE} className="h-fit w-fit" alt="example" />
          </div>
        </div>
        <Spacer height="16px" />
        {/* section 2 */}
        <div className="flex flex-col">
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Total revenue</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Total revenue represents the overall sum of all income sources for a nonprofit
              organization. This includes funds generated from contributions, grants, donations,
              investment returns, program fees, or any other sources of income.
              <br />
              <br />
              This is a critical metric as it provides a holistic view of the financial inflows that
              sustain the organization. It serves as the foundation for assessing the financial
              health and capacity of the nonprofit.
            </h6>
          </div>
          <Divider className="my-5" />
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Total expenses</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Total expenses encompass all the costs incurred by a nonprofit in its day-to-day
              operations. This includes program costs (direct costs associated with delivering
              services or programs), administrative expenses (overhead costs like salaries,
              utilities, and office supplies), fundraising expenses, and any other operational
              expenditures.
              <br />
              <br />
              This information offers a comprehensive insight into the resources expended by the
              nonprofit to fulfill its mission. Understanding the breakdown of expenses is crucial
              for effective financial management and decision-making.
            </h6>
          </div>
          <Divider className="my-5" />
          <div>
            <p className="font-poppins text-sm font-semibold text-purple-500">Net profit</p>
            <Spacer height="8px" />
            <h6 className="font-poppins text-sm text-gray-800 ">
              Net profit (or Net deficit) is calculated by subtracting total expenses from total
              revenue. A positive result indicates a net profit, meaning that the organization
              generated more revenue than it spent. Conversely, a negative result signifies a net
              deficit, indicating that expenses exceeded revenue.
              <br />
              <br />
              Net profit is a key indicator of the financial performance of the nonprofit. A surplus
              provides opportunities for investments, reserves, or growth initiatives. A deficit
              prompts the need for adjustments to ensure financial sustainability, such as expense
              reductions or revenue enhancement strategies.
            </h6>
          </div>
        </div>
      </div>
      <div className="flex min-h-[100px] flex-col rounded border border-gray-300 bg-white p-5">
        <div>
          <h5 className="font-poppins text-base font-semibold text-black">
            Why is revenue stream important?
          </h5>
          <Spacer height="10px" />
          <h6 className="font-poppins text-sm text-gray-800">
            This analysis is crucial as it ensures that the revenue generated adequately covers the
            associated costs, contributing to the overall sustainability of the nonprofit. It
            provides a clear picture of the financial health and viability of the organization,
            indicating whether there is a surplus to invest in growth or if adjustments are needed
            to align revenue and expenses for stability.
          </h6>
        </div>
      </div>
      {children && children}
    </div>
  );
}
