import { createDraftSafeSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface User {
  _id: string | undefined;
  email: string;
  email_verified?: boolean;
  firstname: string;
  lastname: string;
  idp_google_detail?: {
    picture?: string;
  };
  newUser?: boolean;
  idp_google?: string; // For Beta
  created?: boolean; // For Beta
  token?: boolean; // For Beta
  current_org_id?: string;
  roles?: string[];
  title?: string;
  pass_invitation_code?: boolean;
}
export const roleToName = ["Delete", "Guest", "Viewer", "Member", "Admin", "Owner"];
export const getRoleByValue = (value: number): string | undefined => {
  return Object.keys(roleToID).find((key) => roleToID[key] === value);
};
export const roleToID: { [key: string]: number } = {
  Delete: 0,
  Guest: 1,
  Viewer: 2,
  Member: 3,
  Admin: 4,
  Owner: 5,
};
export enum UserRoleEnum {
  NONE = 0,
  GUEST = 1,
  VIEWER = 2,
  EDITOR = 3,
  ADMIN = 4,
  OWNER = 5,
}
export interface UserWithRole extends User {
  role: number;
}
export const defaultUser: User = {
  _id: undefined,
  email: "",
  firstname: "",
  lastname: "",
  newUser: false,
};

interface UserState {
  isLoggedIn: boolean;
  user: User | undefined;
}

const token = JSON.parse(sessionStorage.getItem("token") || "{}");
const initialState = (
  token._id ? { isLoggedIn: true, user: token } : { isLoggedIn: false, user: undefined }
) as UserState;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logIn: (
      state,
      action: PayloadAction<{
        user: User;
      }>,
    ) => {
      sessionStorage.removeItem("token");
      sessionStorage.setItem("token", JSON.stringify(action.payload.user));
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    logOff: (state) => {
      sessionStorage.removeItem("token");
      state.isLoggedIn = false;
      state.user = undefined;
    },
  },
});

const selectSelf = (state: RootState) => state;
export const isLoggedIn = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.user.isLoggedIn,
);
export const selectUser = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.user.user,
);

export default userSlice;
