import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./app/store";
import { RecoilRoot } from "recoil";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SWRConfig } from "swr";
import { BrowserRouter } from "react-router-dom";
import { cancelMiddleware } from "./middleware/swr";
import Spinner from "./components/Spinner";
// import packageInfo from "../package.json";
// import CacheBuster from "react-cache-buster";
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  // <CacheBuster
  //   currentVersion={process.env.REACT_APP_ENV === "DEV" ? packageInfo.version : undefined}
  //   //production env will take advantage of AWS amplify
  //   isEnabled={process.env.REACT_APP_ENV === "DEV" ? true : false} //If false, the library is disabled.
  //   loadingComponent={
  //     <div className="flex h-[calc(100vh)] w-full items-center justify-center">
  //       <Spinner />
  //     </div>
  //   } //If not pass, nothing appears at the time of new version check.
  //   metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
  // >
  <Provider store={store}>
    <SWRConfig value={{ provider: () => new Map(), use: [cancelMiddleware] }}>
      <RecoilRoot>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </GoogleOAuthProvider>
      </RecoilRoot>
    </SWRConfig>
  </Provider>,
  // </CacheBuster>,
  // </React.StrictMode>)
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
