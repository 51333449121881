import { Transition } from "@headlessui/react";
import classNames from "classnames";
import React, {
  Dispatch,
  Fragment,
  ReactElement,
  ReactNode,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { openSigninSignup, sidekickSearchOpen } from "../app/recoilStore";
import { isSearchHistoryFetched, selectSearchHistory } from "../app/searchHistorySlice";
import { useAppSelector } from "../app/store";
import useCancellableSWR from "../hooks/useCancellableSWR";
import useDebounce from "../hooks/useDebounce";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useOnboardingData from "../hooks/useOnboarding";
import useOrgID from "../hooks/useOrgID";
import useOrgSearchQuery from "../hooks/useOrgSearchQuery";
import useSavedMoreSearchOption from "../hooks/useSavedMoreSearchOption";
import { FROM_FOR_MIXPANEL, MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import {
  FunderSearchResult,
  GrantSearchResult,
  ISearchHistory,
  ISearchParam,
  NPOSearchResult,
  SearchType,
  UnifiedSearchResult,
  getObjectFromURLSearchParams,
  getURLSearchParamsFromSearchParam,
  getURLSearchParamsFromSearchQuery,
} from "../types/search";
import { SEARCH_TYPE_OPTION } from "../types/searchFilter";
import { donorAutoComplete, grantAutoComplete, npoAutoComplete } from "../utils/autoComplete";
import { scrollBarClass } from "../utils/classes";
import { getWordLength, reprTimeDistance } from "../utils/formatHelper";
import PlainButton from "./PlainButton";
import RoundButton from "./RoundButton";
import Spinner from "./Spinner";
import TagFunderType from "./TagFunderType";
import TagGrantPresent from "./TagGrantPresent";
import { TooltipWrapper } from "./TooltipWrapper";
import "./css/dropdown.scss";
import RadioGroup from "./radio/RadioGroup";
import Button from "./tailwind/Button";

interface GibooTotalSearchProps {
  from_for_mixpanel: FROM_FOR_MIXPANEL;
  id: string;
  className?: string;
  inputClassName?: string;
  inputWidth?: string;
  inputMaxWidth?: string;
  itemMaxWidth?: string;
  inputMaxHeight?: string;
  maxWidth?: string;
  itemMaxHeight?: string;
  defaultQuery?: string;
  disabled?: boolean;
  float?: boolean;
  placeholder?: string;
  openAnimation?: boolean;
  spinner?: boolean;
  redirectImmediately?: boolean;
  maxLength?: number;
  onSearch?: (v: string) => void;
  onChange?: (v: string) => void;
  children?: (props: GibooTotalSearchRenderProps) => ReactNode;
  isHeader?: boolean;
  showBackdrop?: boolean;
  backDropClass?: string;
  showSearch?: boolean;
  setShowSearch?: Dispatch<SetStateAction<boolean>>;
  showOption?: boolean;
  setShowOption?: Dispatch<SetStateAction<boolean>>;
  showActionButton?: boolean;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  // guidance
  onClose?: () => void;
  onInputExpand?: () => void;
  onInputShrink?: () => void;
  showGuidance?: boolean;
  defaultSearchType?: SearchType | undefined;
  withoutLogin?: boolean;
}

interface GibooTotalSearchRenderProps {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
}
const autoCompleteThrottleMilliseconds = 200;
const useSimilarSearch = true;
const colorClass = "-gray";
const autoCompleteFunction = async (
  query: string,
  matchSimilar = true,
  controller?: AbortController,
) => {
  const promises = [
    npoAutoComplete(query, matchSimilar, 0, 2, controller),
    donorAutoComplete(query, matchSimilar, 0, 2, controller),
    grantAutoComplete(query, matchSimilar, 0, 2, controller, "virtual"),
    grantAutoComplete(query, matchSimilar, 0, 2, controller, "opencall"),
  ];
  return Promise.all(promises).then((res) => [...res[0], ...res[1], ...res[2], ...res[3]]);
};
type SuggestionOptionType = "history" | "history_matched" | "text" | "name_search";
interface SuggestionOption {
  type: SuggestionOptionType;
  text: string;
  index: number;
  history?: ISearchHistory;
  search_result?: UnifiedSearchResult;
}
const getSuggestionOptionKey = (o: SuggestionOption, i = 0) => `${o.type}_${i}_${o.text}`;
const defaultSuggestionOptionClass =
  "flex min-h-[40px] w-full items-center gap-3 !rounded-none border-b border-gray-300 pl-3 pr-5 py-2 cursor-pointer";

const emptyList: SuggestionOption[] = [];
type IsFunction<T> = T extends (...args: any[]) => any ? T : never;
const isFunction = <T extends object>(value: T): value is IsFunction<T> =>
  typeof value === "function";
function gibooTotalSearch(
  {
    from_for_mixpanel,
    id,
    className,
    inputClassName,
    inputWidth = "350px",
    inputMaxWidth = "350px",
    itemMaxWidth = "350px",
    inputMaxHeight = "130px",
    itemMaxHeight,
    maxWidth,
    defaultQuery = "",
    disabled = false,
    float = false,
    placeholder,
    spinner = true,
    onChange,
    onSearch,
    children,
    maxLength = 300,
    isHeader = true,
    showSearch = false,
    showBackdrop,
    setShowSearch,
    backDropClass,
    showOption = false,
    setShowOption,
    showActionButton = false,
    containerClass,
    containerStyle,
    onClose,
    onInputExpand,
    onInputShrink,
    showGuidance,
    defaultSearchType,
    withoutLogin = false,
  }: GibooTotalSearchProps,
  ref?: React.ForwardedRef<HTMLInputElement>,
) {
  const org_id = useOrgID();
  const mxEvent = useGibooMixpanel(from_for_mixpanel);
  const { query: searchQuery, search_type, searchType, setSearchType } = useSavedMoreSearchOption();
  const inputMaxHeightNum = parseInt(inputMaxHeight) || 300;
  const defaultInputHeightNum = isHeader ? 36 : 50;
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useRecoilState(sidekickSearchOpen);
  const [seeAllHistory, setSeeAllHistory] = useState<boolean>(false);
  const [selected, setSelected] = useState<SuggestionOption>();
  const controlled = false;
  const browserLocation = useLocation();
  const [onboardingData] = useOnboardingData();
  const navigate = useNavigate();
  const color = "gray";
  const [query, setQuery] = useState<string>(defaultQuery || "");
  const [showSearchOption, setShowSearchOption] = useState<boolean>(false);
  const [showMoreSearchOption, setShowMoreSearchOption] = useState<boolean>(false);
  const [iSearchParam, setISearchParam] = useState<ISearchParam>();
  const [dirty, setDirty] = useState<boolean>(false);
  const [filteredRecommendedQuery, setFilteredRecommendedQuery] = useState<string[]>([]);
  const searchHistory = useAppSelector((state) => selectSearchHistory(state));
  const searchHistoryFetched = useAppSelector((state) => isSearchHistoryFetched(state));
  const [filteredMatchedSearchHistory, setFilteredMatchedSearchHistory] = useState<
    ISearchHistory[]
  >([]);
  const [filteredSearchHistory, setFilteredSearchHistory] = useState<ISearchHistory[]>([]);
  const [inputHeight, setInputHeight] = useState<number>(defaultInputHeightNum);
  const buttonRef = useRef<any>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const { data: searchQuerySuggestion } = useOrgSearchQuery();
  const setSignUpSignin = useSetRecoilState(openSigninSignup);

  useEffect(() => {
    if (showSearch) setOpen(true);
  }, [showSearch]);

  useEffect(() => {
    if (getWordLength(query) >= maxLength) {
      setDirty(true);
    }
  }, [maxLength, query, setDirty]);
  useEffect(() => {
    const needsOpen =
      !showSearchOption &&
      (open || (showSearchOption && !isHeader)) &&
      search_type === 4 &&
      !query.trim()
        ? true
        : false;
    if (!open && needsOpen) setTimeout(() => setShowWarning(needsOpen), 500);
    else setShowWarning(needsOpen);
  }, [isHeader, showSearchOption, open, query, searchType, setShowWarning]);
  useEffect(() => {
    setOpen(showSearch);
  }, [showSearch]);

  useEffect(() => {
    setShowSearchOption(showOption);
    setOpen(showOption);
  }, [showOption]);

  useEffect(() => {
    if (showMoreSearchOption) {
      setOpen(true);
      setShowSearchOption(true);
    }
  }, [showMoreSearchOption]);

  useEffect(() => {
    if (id === "giboo-search-my-home") {
      if (defaultSearchType) {
        setSearchType(defaultSearchType);
      }
    }
  }, [id, defaultSearchType]);

  const delayedQuery = useDebounce<string>(
    query.length > 2 ? query : "",
    autoCompleteThrottleMilliseconds,
    {
      maxWait: autoCompleteThrottleMilliseconds * 5,
    },
  );
  // const delayedQuery = useThrottle<string>(query, autoCompleteThrottleMilliseconds);

  const autoCompleteFunctionWithKey = useCallback(
    (key: string, controller?: AbortController) => {
      if (key.startsWith("%"))
        return autoCompleteFunction(key.slice(id.length + 1), true, controller);
      else return autoCompleteFunction(key.slice(id.length), false, controller);
    },
    [id, autoCompleteFunction],
  );
  const { data, isLoading, error } = useCancellableSWR<UnifiedSearchResult[]>(
    open && delayedQuery.length > 2 ? id + delayedQuery : null,
    autoCompleteFunctionWithKey,
    {
      dedupingInterval: 1000 * 60,
    },
  );
  const {
    data: dataSimilar,
    isLoading: isLoadingSimilar,
    isValidating,
  } = useCancellableSWR<UnifiedSearchResult[]>(
    open && useSimilarSearch && delayedQuery.length > 2 ? "%" + id + delayedQuery : null,
    autoCompleteFunctionWithKey,
    {
      dedupingInterval: 1000 * 60,
    },
  );

  useEffect(() => {
    const v = browserLocation.pathname.startsWith("/search")
      ? searchParams.get("text_query") || ""
      : "";
    setQuery(v);
    if (!controlled && inputRef?.current) inputRef.current.value = v;
  }, [searchParams, inputRef, browserLocation, setQuery]);

  useEffect(() => {
    if (!searchHistoryFetched) return;
    setFilteredMatchedSearchHistory(
      searchHistory
        .filter((h) => (!query || h.text_query.includes(query)) && h.text_query.trim())
        .slice(0, !isHeader && showSearchOption ? 3 : query.length < 3 ? 5 : 3),
    );
  }, [
    isHeader,
    showSearchOption,
    searchHistoryFetched,
    searchHistory,
    query,
    setFilteredMatchedSearchHistory,
  ]);

  useEffect(() => {
    if (searchQuerySuggestion) {
      try {
        setFilteredRecommendedQuery(() => {
          // prevQueries
          const existingQueries: string[] = filteredMatchedSearchHistory.map(
            (item) => item.text_query,
          );

          const uniqueQueries = searchQuerySuggestion.filter(
            (suggestion) => !existingQueries.includes(suggestion),
          );

          const combinedQueries = [
            ...uniqueQueries,
            ...existingQueries.filter((v) => !uniqueQueries.includes(v)),
          ];
          return combinedQueries.slice(0, 3);
        });
      } catch (error) {
        console.error("Error parsing searchQuerySuggestion:", error);
      }
    }
  }, [
    isHeader,
    searchQuerySuggestion,
    showSearchOption,
    query,
    // isTaxonomyCombinationLoading,
    filteredMatchedSearchHistory,
    // combinations,
    onboardingData,
    setFilteredRecommendedQuery,
  ]);
  useEffect(() => {
    const searchHistoryText = [
      ...filteredMatchedSearchHistory.map((h) => h.text_query),
      ...filteredRecommendedQuery,
    ];
    setFilteredSearchHistory(
      searchHistory
        .filter((h) => h.text_query && !searchHistoryText.includes(h.text_query))
        .slice(0, !isHeader && showSearchOption ? 0 : 5),
    );
  }, [
    isHeader,
    showSearchOption,
    filteredMatchedSearchHistory,
    filteredRecommendedQuery,
    setFilteredSearchHistory,
  ]);
  // console.log(id, "this query", defaultQuery, query, inputRef?.current?.value);
  // console.log(delayedQuery, data, dataSimilar);

  // useEffect(() => {
  //   console.log(id, "changed", delayedQuery);
  // }, [delayedQuery]);

  const searchKeepingContext = useCallback(
    (s: string, keepTaxonomy = false) => {
      const obj = getObjectFromURLSearchParams(searchParams);
      if (keepTaxonomy)
        navigate(
          `/search?${getURLSearchParamsFromSearchParam(
            {
              ...obj,
              text_query: s,
              from_more_search_option: false,
              type: [searchType],
              mission: obj.mission ? onboardingData.mission || "" : "",
              current_grantee_stage: onboardingData.stage || "buildup",
              without_blink: false,
            },
            true,
          ).toString()}`,
        );
      else
        navigate(
          `/search?${getURLSearchParamsFromSearchParam(
            {
              ...obj,
              text_query: s,
              from_more_search_option: true,
              type: [searchType],
              stars: obj.stars.filter(
                (v) =>
                  !v.startsWith("focus_area") &&
                  !v.startsWith("beneficiary") &&
                  !v.startsWith("program"),
              ),
              excludes: obj.excludes.filter(
                (v) =>
                  !v.startsWith("e_focus_area") &&
                  !v.startsWith("e_beneficiary") &&
                  !v.startsWith("e_program"),
              ),
              mission: obj.mission ? onboardingData.mission || "" : "",
              current_grantee_stage: onboardingData.stage || "buildup",
              without_blink: false,
              done_pre_tagging: false,
              done_tagging: false,
            },
            true,
          ).toString()}`,
        );
    },
    [
      onboardingData,
      searchType,
      searchParams,
      getURLSearchParamsFromSearchParam,
      getURLSearchParamsFromSearchParam,
    ],
  );
  const handleSearchText = useCallback(
    (s: string, mission = "") => {
      if (onSearch) onSearch(s);
      else if (
        browserLocation.pathname.startsWith("/search") &&
        searchParams.get("text_query") === s
      )
        searchKeepingContext(s, true);
      else if (browserLocation.pathname.startsWith("/search")) searchKeepingContext(s);
      else {
        navigate(
          `/search?text_query=${encodeURIComponent(s)}&mission=${encodeURIComponent(
            mission,
          )}&type=${searchType}&done_tagging=false&done_pre_tagging=false`,
        );
      }
    },
    [browserLocation, searchType, onSearch, searchKeepingContext, searchParams],
  );
  const handleSearch = useCallback(
    (s?: SuggestionOption, useMission = false) => {
      handleOnclose(); // guidance
      onInputShrink?.(); // on input shrink
      if (showSearchOption && iSearchParam) {
        navigate(
          `/search?${getURLSearchParamsFromSearchParam({
            ...iSearchParam,
            text_query: query,
          }).toString()}`,
        );
      } else if (!s) {
        mxEvent(MIXPANEL_EVENTS_V2.search.search_text_query.edit, {
          textQuery: query,
          orgMission: onboardingData.mission || "",
          queryType: "direct",
          searchType: searchType,
        });
        handleSearchText(query, useMission ? onboardingData.mission || "" : "");
      } else {
        if ((s.type === "history" || s.type === "history_matched") && s.history) {
          mxEvent(MIXPANEL_EVENTS_V2.search.search_text_query.edit, {
            textQuery: s.history.text_query,
            queryType: s.type === "history_matched" ? "recent" : "other_recent_searches",
            orgMission: s.history.obj?.mission,
            searchType: [
              "virtual_grant",
              "funder",
              "grant",
              "npo",
              "by_name",
              "past_grant",
              "specific_funder_grant",
              "eligible_npo",
            ][s.history.search_type],
          });
          navigate(
            `/search?${getURLSearchParamsFromSearchQuery(
              onboardingData,
              s.history.obj,
              s.history.obj.grant_type.length === 1 && s.history.obj.grant_type[0] === "virtual"
                ? 0
                : s.history.search_type,
            ).toString()}`,
          );
        } else if (s.type === "name_search" && s.search_result) {
          mxEvent(MIXPANEL_EVENTS_V2.search.name_search_result.clicked, {
            textQuery: query,
            targetId: s.search_result?._id,
            targetType:
              (s.search_result as GrantSearchResult)?.type === "grant-page"
                ? "virtual_grant"
                : (s.search_result?.search_type as
                    | "funder"
                    | "npo"
                    | "grant"
                    | "virtual_grant"
                    | "past_grant"
                    | undefined),
          });
          // console.log("event(name-search)", mxData);
          if (s.search_result.search_type === "funder") {
            navigate(`/donors/${s.search_result._id}`);
          } else if (s.search_result.search_type === "npo") {
            navigate(`/npos/${s.search_result._id}/`);
          } else if (s.search_result.search_type === "grant") {
            navigate(`/grants/${s.search_result._id}`);
          }
        } else {
          mxEvent(MIXPANEL_EVENTS_V2.search.search_text_query.edit, {
            textQuery: query,
            orgMission: onboardingData.mission || "",
            queryType: "try_searching_for",
            searchType: searchType,
          });
          handleSearchText(s.text, useMission ? onboardingData.mission || "" : "");
        }
      }
      setOpen(false);
    },
    [
      onboardingData,
      searchQuery,
      searchType,
      search_type,
      query,
      handleSearchText,
      setOpen,
      showSearchOption,
      getURLSearchParamsFromSearchQuery,
      getURLSearchParamsFromSearchParam,
      iSearchParam,
      mxEvent,
    ],
  );

  useEffect(() => {
    if (inputRef?.current && (!open || isHeader)) {
      if (inputRef.current.scrollHeight > defaultInputHeightNum) setInputHeight(0);
    }
  }, [query, inputRef, open]);

  useEffect(() => {
    if (inputRef?.current) {
      if (open) {
        if (inputHeight === 0)
          setInputHeight(
            Math.max(
              defaultInputHeightNum,
              Math.min(inputRef.current.scrollHeight, inputMaxHeightNum),
            ),
          );
      } else {
        setInputHeight(defaultInputHeightNum);
      }
    }
  }, [inputHeight, inputRef, open]);
  const merged = useSimilarSearch && !isValidating && dataSimilar ? dataSimilar : data;
  const filtered = merged?.filter((i) =>
    searchType === "funder"
      ? i.search_type === "funder"
      : searchType === "npo"
      ? i.search_type === "npo"
      : searchType === "grant"
      ? i.search_type === "grant" && (i as GrantSearchResult).type !== "grant-page"
      : searchType === "virtual_grant"
      ? i.search_type === "grant" && (i as GrantSearchResult).type === "grant-page"
      : true,
  );
  const suggestion: SuggestionOption[] = !seeAllHistory
    ? [
        ...filteredMatchedSearchHistory.map((h, i) => ({
          type: "history_matched" as SuggestionOptionType,
          text: h.text_query,
          index: i,
          history: h,
        })),
        ...filteredRecommendedQuery.map((r, i) => ({
          type: "text" as SuggestionOptionType,
          text: r,
          index: filteredMatchedSearchHistory.length + i,
        })),
        ...(filtered?.map((s, i) => ({
          type: "name_search" as SuggestionOptionType,
          text: s.name,
          index: filteredMatchedSearchHistory.length + filteredRecommendedQuery.length + i,
          search_result: s,
        })) || emptyList),
        ...(query.length > 0
          ? filteredSearchHistory.map((h, i) => ({
              type: "history" as SuggestionOptionType,
              text: h.text_query,
              index:
                filteredMatchedSearchHistory.length +
                filteredRecommendedQuery.length +
                (filtered?.length || 0) +
                i,
              history: h,
            }))
          : emptyList),
      ]
    : [
        ...searchHistory
          .filter((h) => h.text_query && h.text_query.trim())
          .map((h, i) => ({
            type: "history_matched" as SuggestionOptionType,
            text: h.text_query,
            index: i,
            history: h,
          })),
      ];

  const options = useMemo(() => {
    return (
      <div
        className={classNames(
          "flex w-full flex-col gap-0 overflow-x-hidden outline-none",
          float ? "absolute" : "",
          isHeader
            ? "rounded border border-gray-300 bg-white shadow-sm"
            : "border-t border-gray-300 bg-transparent",
          scrollBarClass,
          "!pr-[0px]",
        )}
        style={{
          ...(isHeader ? { width: itemMaxWidth } : {}),
          maxWidth: itemMaxWidth,
          maxHeight: itemMaxHeight,
          overflowY: "auto",
        }}
      >
        <>
          <h6 className={classNames("mb-[-4px] ml-2 mt-2 text-gray-700")}>Search:</h6>
          <RadioGroup<SearchType>
            id={`input-search-type-${id}`}
            data={SEARCH_TYPE_OPTION}
            className={classNames(
              "mt-2 !inline-flex w-full items-center !gap-1 self-center py-[2px]",
              isHeader ? "!gap-2 px-2" : "!gap-2 border-b border-gray-300 px-4 pb-2",
            )}
            itemClassName={classNames(
              "border border-gray-300 rounded py-1",
              isHeader ? "px-2" : "px-2",
            )}
            // buttonClass="h-5 w-5"
            value={searchType}
            setValue={(v) => {
              if (v) setSearchType(v);
            }}
          />
        </>
        {!withoutLogin && (
          <>
            {seeAllHistory && (
              <div className="flex items-center justify-between px-5 py-2">
                <PlainButton
                  id={`btn-see-all-history-${id}`}
                  handleOnClick={() => {
                    setSeeAllHistory(false);
                  }}
                  color="purple"
                  className="!m-0 !p-0 !text-sm"
                  leftIconClass="fa-solid fa-arrow-left"
                  label="Recent"
                />
              </div>
            )}
            {!seeAllHistory &&
              searchHistory.length > 0 &&
              suggestion.filter((s) => s.type === "history_matched").length > 0 && (
                <div className={classNames("mb-1  flex items-center justify-between px-3 pt-2")}>
                  <h5 className="font-semibold text-purple-500">Recent</h5>
                  <PlainButton
                    id={`btn-see-all-history-${id}`}
                    handleOnClick={() => {
                      setSeeAllHistory(true);
                    }}
                    className="!m-0 !p-0 !text-sm !text-gray-800"
                    label="See all"
                  />
                </div>
              )}
            <div className="flex flex-col overflow-y-scroll">
              {suggestion.filter((s) => s.type === "history_matched").length > 0 && (
                <div className={classNames("flex flex-col px-0 pt-0", isHeader ? "" : "")}>
                  {!open && (
                    <div className={classNames("mb-1 flex items-center justify-between px-3 pt-2")}>
                      <h5 className="font-semibold text-purple-500">Recent</h5>
                    </div>
                  )}
                  {suggestion
                    .filter((s) => s.type === "history_matched")
                    .map((s) => (
                      <div
                        key={getSuggestionOptionKey(s)}
                        className={classNames(
                          defaultSuggestionOptionClass,
                          "hover:bg-purple-50",
                          selected?.index === s.index ? "bg-purple-50" : "",
                        )}
                        onClick={() => {
                          handleSearch(s);
                        }}
                        id={"GA_TAG_click_recent"}
                        aria-valuetext={s.text}
                      >
                        <i className="gi gi-time ml-2 text-gray-600" aria-valuetext={s.text} />
                        <h5
                          className="max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900"
                          aria-valuetext={s.text}
                        >
                          {s.text}
                        </h5>
                        <h6 className="whitespace-nowrap text-gray-700" aria-valuetext={s.text}>
                          {s.history?.updated_at && reprTimeDistance(s.history?.updated_at)}
                        </h6>
                      </div>
                    ))}
                </div>
              )}
              {suggestion.filter((s) => s.type === "text").length > 0 && (
                <div className="flex flex-col px-0 pt-0">
                  <h5 className="mb-1 px-3 pt-2 font-semibold text-purple-500">
                    Try searching for
                  </h5>
                  {suggestion
                    .filter((s) => s.type === "text")
                    .map((s, index) => (
                      <div
                        key={getSuggestionOptionKey(s)}
                        className={classNames(
                          defaultSuggestionOptionClass,
                          "hover:bg-purple-50",
                          selected?.index === s.index ? "bg-purple-50" : "",
                        )}
                        id={"GA_TAG_click_trysearchingfor"}
                        onClick={() => {
                          handleSearch(s, true);
                        }}
                        aria-valuetext={s.text}
                      >
                        <i className="gi gi-search ml-2 text-gray-600" aria-valuetext={s.text} />
                        <h5
                          className={classNames(
                            "max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900",
                          )}
                          aria-valuetext={s.text}
                        >
                          {s.text}
                        </h5>
                      </div>
                    ))}
                </div>
              )}
              {suggestion
                .filter((s) => s.type === "name_search")
                .map((s, i) => (
                  <div
                    key={getSuggestionOptionKey(s, i)}
                    className={classNames(
                      defaultSuggestionOptionClass,
                      "hover:bg-purple-50",
                      selected?.index === s.index ? "bg-purple-50" : "",
                    )}
                    onClick={() => {
                      handleSearch(s);
                    }}
                  >
                    {s.search_result && ["funder", "npo"].includes(s.search_result.search_type) && (
                      <TagFunderType
                        funder_type={
                          s.search_result?.search_type === "funder"
                            ? (s.search_result as FunderSearchResult).funder_type
                            : (s.search_result as NPOSearchResult).npo_type
                        }
                      />
                    )}
                    {s.search_result && s.search_result.search_type === "grant" && (
                      // <TagGrantType
                      //   type={(s.search_result as GrantSearchResult).type}
                      //   ntee_cd={(s.search_result as GrantSearchResult).donor?.ntee_cd}
                      //   return_code={(s.search_result as GrantSearchResult).donor?.return_code}
                      // />
                      <TagGrantPresent grant={s.search_result as GrantSearchResult} />
                    )}
                    <h5 className="max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900">
                      {s.text}
                    </h5>
                  </div>
                ))}
              {suggestion.filter((s) => s.type === "history").length > 0 && (
                <div className={classNames("flex flex-col px-0 pt-0")}>
                  <div className={classNames("mb-1 flex items-center justify-between px-3 pt-2")}>
                    <h5 className="font-semibold text-purple-500">Other recent searches</h5>
                    <PlainButton
                      id={`btn-see-all-history-${id}`}
                      handleOnClick={() => {
                        setSeeAllHistory(true);
                      }}
                      className="!m-0 !p-0 !text-sm !text-gray-800"
                      label="See all"
                    />
                  </div>
                  {suggestion
                    .filter((s) => s.type === "history")
                    .map((s) => (
                      <div
                        key={getSuggestionOptionKey(s)}
                        className={classNames(
                          defaultSuggestionOptionClass,
                          "hover:bg-purple-50",
                          selected?.index === s.index ? "bg-purple-50" : "",
                        )}
                        id={"GA_TAG_click_otherrecentsearch"}
                        aria-valuetext={s.text}
                        onClick={() => {
                          handleSearch(s);
                        }}
                      >
                        <i className="gi gi-time ml-2 text-gray-600" aria-valuetext={s.text} />
                        <h5
                          className="max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900"
                          aria-valuetext={s.text}
                        >
                          {s.text}
                        </h5>
                        <h6 className="whitespace-nowrap text-gray-700" aria-valuetext={s.text}>
                          {s.history?.updated_at && reprTimeDistance(s.history?.updated_at)}
                        </h6>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </>
        )}
        {withoutLogin && (
          <>
            <div className="mt-4 flex h-[142px] w-full flex-col items-center justify-center gap-3 bg-gray-100 px-[111px] py-4">
              <div className="whitespace-nowrap font-poppins text-sm font-semibold">
                Discover the perfect funding opportunities instantly with Giboo.
              </div>
              <p className="max-w-[358px] text-center font-poppins text-xs text-gray-700">
                With Giboo, sift through millions of opportunities effortlessly to find the one that
                fits your needs in just seconds.
              </p>
              <Button
                id="try free"
                label="Try for free"
                className="!h-[29px]"
                handleOnClick={() => {
                  setSignUpSignin({ show: true });
                  setOpen(false);
                }}
                prependIcon={
                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.25 5.75C5.25 3.2645 6.70275 2 8.904 2C10.293 2 11.3843 2.504 12 3.5M9 9.5V11.375"
                      stroke="white"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                    />
                    <path
                      d="M9 7.19828L8.601 7.59428C8.65328 7.64689 8.71544 7.68864 8.78392 7.71713C8.8524 7.74562 8.92583 7.76029 9 7.76029C9.07417 7.76029 9.1476 7.74562 9.21608 7.71713C9.28456 7.68864 9.34672 7.64689 9.399 7.59428L9 7.19828ZM7.29075 15.5143C6.26325 14.7305 5.15175 13.973 4.269 13.0108C3.4065 12.0703 2.8125 10.982 2.8125 9.57428H1.6875C1.6875 11.345 2.45025 12.692 3.44025 13.7713C4.41075 14.8295 5.649 15.6763 6.60825 16.409L7.29075 15.5143ZM2.8125 9.57503C2.8125 8.20703 3.61125 7.05203 4.71375 6.56378C5.7855 6.08978 7.22775 6.21428 8.601 7.59503L9.399 6.80228C7.734 5.12828 5.80275 4.85228 4.2585 5.53478C2.745 6.20453 1.6875 7.76528 1.6875 9.57503H2.8125ZM6.6075 16.4098C6.95325 16.673 7.3275 16.9573 7.707 17.1718C8.08725 17.387 8.5245 17.5633 9 17.5633V16.4383C8.8005 16.4383 8.56275 16.3633 8.26125 16.1923C7.95975 16.022 7.64625 15.7865 7.29075 15.515L6.6075 16.4098ZM11.3925 16.409C12.351 15.6763 13.5892 14.8303 14.5597 13.772C15.5498 12.6928 16.3125 11.3458 16.3125 9.57503H15.1875C15.1875 10.9828 14.5935 12.071 13.731 13.0115C12.8483 13.9738 11.736 14.7313 10.7093 15.515L11.3925 16.409ZM16.3125 9.57503C16.3125 7.76528 15.255 6.20453 13.7415 5.53553C12.1973 4.85153 10.2653 5.12828 8.601 6.80228L9.399 7.59503C10.7715 6.21428 12.2145 6.08978 13.2863 6.56378C14.3888 7.05128 15.1875 8.20628 15.1875 9.57503H16.3125ZM10.7093 15.515C10.353 15.7865 10.0402 16.022 9.73875 16.1923C9.43725 16.3633 9.19875 16.4383 9 16.4383V17.5633C9.4755 17.5633 9.91275 17.387 10.293 17.1718C10.6725 16.9565 11.0468 16.6723 11.3925 16.409L10.7093 15.515Z"
                      fill="white"
                    />
                  </svg>
                }
              />
            </div>
            {suggestion
              .filter((s) => s.type === "name_search")
              .map((s, i) => (
                <div
                  key={getSuggestionOptionKey(s, i)}
                  className={classNames(
                    defaultSuggestionOptionClass,
                    "hover:bg-purple-50",
                    selected?.index === s.index ? "bg-purple-50" : "",
                  )}
                  onClick={() => {
                    handleSearch(s);
                  }}
                >
                  {s.search_result && ["funder", "npo"].includes(s.search_result.search_type) && (
                    <TagFunderType
                      funder_type={
                        s.search_result?.search_type === "funder"
                          ? (s.search_result as FunderSearchResult).funder_type
                          : (s.search_result as NPOSearchResult).npo_type
                      }
                    />
                  )}
                  {s.search_result && s.search_result.search_type === "grant" && (
                    // <TagGrantType
                    //   type={(s.search_result as GrantSearchResult).type}
                    //   ntee_cd={(s.search_result as GrantSearchResult).donor?.ntee_cd}
                    //   return_code={(s.search_result as GrantSearchResult).donor?.return_code}
                    // />
                    <TagGrantPresent grant={s.search_result as GrantSearchResult} />
                  )}
                  <h5 className="max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900">
                    {s.text}
                  </h5>
                </div>
              ))}
            {suggestion.filter((s) => s.type === "text").length > 0 ? (
              <div className="flex flex-col px-0 pt-0">
                <h5 className="mb-1 px-3 pt-2 font-semibold text-purple-500">Try searching for</h5>
                {suggestion
                  .filter((s) => s.type === "text")
                  .map((s, index) => (
                    <div
                      key={getSuggestionOptionKey(s)}
                      className={classNames(
                        defaultSuggestionOptionClass,
                        "hover:bg-purple-50",
                        selected?.index === s.index ? "bg-purple-50" : "",
                      )}
                      id={"GA_TAG_click_trysearchingfor"}
                      onClick={() => {
                        handleSearch(s, true);
                      }}
                      aria-valuetext={s.text}
                    >
                      <i className="gi gi-search ml-2 text-gray-600" aria-valuetext={s.text} />
                      <h5
                        className={classNames(
                          "max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900",
                        )}
                        aria-valuetext={s.text}
                      >
                        {s.text}
                      </h5>
                    </div>
                  ))}
              </div>
            ) : (
              <>
                <div className="mb-1 flex items-center gap-1 px-3 pt-2">
                  <h5 className="font-semibold text-purple-500">Try searching for</h5>
                  {org_id && <Spinner size="sm" />}
                </div>
                <div className="blur-text relative">
                  <div className={classNames(defaultSuggestionOptionClass)}>
                    <i className="gi gi-search ml-2 text-gray-600" />
                    <h5
                      className={classNames(
                        "max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900",
                      )}
                    >
                      Grants sought for work in strengthening the economic resilience of Oahu
                      island. Emphasizing initiatives supporting local industries, such as
                      aquaculture and eco-friendly tourism, with a focus on community engagement.
                    </h5>
                  </div>
                  <div className={classNames(defaultSuggestionOptionClass, "hover:bg-purple-50")}>
                    <i className="gi gi-search ml-2 text-gray-600" />
                    <h5
                      className={classNames(
                        "max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900",
                      )}
                    >
                      Financial support needed for scholarships and financial aid targeting refugees
                      and displaced populations, making film education accessible and inclusive for
                      those facing unique challenges.
                    </h5>
                  </div>
                  <div className={classNames(defaultSuggestionOptionClass, "hover:bg-purple-50")}>
                    <i className="gi gi-search ml-2 text-gray-600" />
                    <h5
                      className={classNames(
                        "max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900",
                      )}
                    >
                      grants for legal aid services specifically addressing systemic injustices and
                      advocating for policy changes in support of social justice causes
                    </h5>
                  </div>
                  <div className={classNames(defaultSuggestionOptionClass, "hover:bg-purple-50")}>
                    <i className="gi gi-search ml-2 text-gray-600" />
                    <h5
                      className={classNames(
                        "max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900",
                      )}
                    >
                      grants for integrating arts education into STEM curriculum in schools
                    </h5>
                  </div>
                  <div className={classNames(defaultSuggestionOptionClass, "hover:bg-purple-50")}>
                    <i className="gi gi-search ml-2 text-gray-600" />
                    <h5
                      className={classNames(
                        "max-w-[calc(100%-5px)] grow truncate text-ellipsis whitespace-nowrap text-gray-900",
                      )}
                    >
                      Seeking financial support for screenwriting courses designed for LGBTQ+
                      communities, fostering creative writing skills, storytelling, and script
                      development within this demographic.
                    </h5>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }, [
    org_id,
    query,
    isValidating,
    handleSearch,
    setShowSearchOption,
    itemMaxWidth,
    itemMaxHeight,
    suggestion,
    selected,
    showSearchOption,
    searchType,
    setSearchType,
    seeAllHistory,
    setSeeAllHistory,
    searchHistory,
    isHeader,
    withoutLogin,
  ]);
  const transitionClasses = {
    enter: "transition ease-out duration-150",
    enterFrom: "transform opacity-0 scale-95",
    enterTo: "transform opacity-100 scale-100",
    leave: "transition ease-in duration-75",
    leaveFrom: "transform opacity-100 scale-100",
    leaveTo: "transform opacity-0 scale-95",
  };
  const globalLoading = !searchHistoryFetched || isLoadingSimilar;

  const handleOnclose = () => {
    onClose?.();
  };
  const handleClickOutside = useCallback(() => {
    if (id === "giboo-search-my-home" && showGuidance) {
      if (open) handleOnclose();
    }
    handleOnclose();
    onInputShrink?.();
    setOpen(false);
    setShowSearch?.(false);
    setShowOption?.(false);
    if (!isHeader) setShowSearchOption(false);
    setSelected(undefined);
  }, [setOpen, setShowSearchOption, setSelected, isHeader]);

  useOnClickOutside(buttonRef, handleClickOutside, ["giboo-sidekick"]);

  const renderTextInput = useCallback(() => {
    return (
      <>
        <textarea
          autoFocus={false}
          style={{
            resize: "none",
            width: "100%",
            height: !isHeader && open ? inputMaxHeight : `${inputHeight}px`,
            maxHeight: inputMaxHeight,
            ...(isHeader && !open
              ? { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }
              : { whiteSpace: "pre-line" }),
            // spinner && (isLoading || isValidating || isLoadingSimilar) ? "60px" : "40px",
          }}
          className={classNames(
            "top-0 flex h-full appearance-none border-none bg-transparent font-poppins text-sm leading-tight text-gray-700 !outline-none !ring-0 transition-all focus:ring-0",
            isHeader ? "px-3 py-2" : "px-8 py-4",
            "placeholder:pt-[1px] placeholder:text-xs placeholder:text-gray-700",
            inputClassName,
            !isHeader && !open && "line-clamp-1 truncate !whitespace-nowrap",
          )}
          disabled={disabled}
          defaultValue={defaultQuery}
          {...(controlled ? { value: query || "" } : { ref: inputRef })}
          id={`input-${id}`}
          onChange={(event) => {
            // if (!open) setOpen(true);
            // if (getWordLength(event.target.value) > maxLength) {
            //   const words = event.target.value.split(/\s+/g) || [];
            //   const spaces = event.target.value.match(/\s+/g) || [];
            //   const cut = words
            //     .slice(0, maxLength)
            //     .reduce((prev, cur, i) => `${prev}${i > 0 ? spaces[i - 1] : ""}${cur}`, "");
            //   setQuery(cut);
            //   onChange?.(cut);
            // } else {
            setQuery(event.target.value);
            onChange?.(event.target.value);
            // }
          }}
          onClick={() => {
            if (!open) onInputExpand?.();
            setOpen(true);
          }}
          // onFocus={() => {
          //   setOpen(true);
          //   onInputExpand?.();
          // }}
          placeholder={placeholder}
          // autoComplete="off" // disable browser's auto suggestion
          onKeyDown={(e) => {
            if (!open) onInputExpand?.();
            setOpen(true);
            if (e.key === "Enter") {
              if (search_type === 4 && !query.trim()) {
                //
              } else {
                handleSearch(selected, true);
                onInputShrink?.();
              }
              e.stopPropagation();
              e.preventDefault();
            } else if (e.key === "ArrowUp") {
              if (selected) {
                e.preventDefault();
                e.stopPropagation();
              }
              setSelected((prev) => (prev && prev.index >= 0 ? suggestion[prev.index - 1] : prev));
            } else if (e.key === "ArrowDown") {
              if (!selected || selected.index < suggestion.length) {
                e.preventDefault();
                e.stopPropagation();
              }
              setSelected((prev) =>
                (prev ? prev.index : -1) + 1 < suggestion.length
                  ? suggestion[(prev ? prev.index : -1) + 1]
                  : prev,
              );
            } else {
              setSelected(undefined);
            }
          }}
        />
        {/* {spinner && open && (isLoading || isValidating || isLoadingSimilar) ? (
          <div className={classNames("absolute right-0 top-[7px] mr-9")}>
            <div
              className={classNames(
                "flex h-5 w-5 animate-spin items-center justify-center rounded-full bg-gradient-to-tr to-transparent",
                "from-purple-500",
              )}
            >
              <div className={classNames("h-[14px] w-[14px] rounded-full", "bg-white")}></div>
            </div>
          </div>
        ) : (
          <></>
        )} */}
        <TooltipWrapper
          isControlled
          isOpen={showWarning}
          content={"Please enter at least a letter to search by name"}
          placements="bottom"
        >
          <div className={classNames("absolute", isHeader ? "right-1 top-[5px]" : "right-2 top-2")}>
            <RoundButton
              id={`btn-search-${id}`}
              size={isHeader ? "sm" : "md"}
              icon={isHeader ? "gi gi-search text-white" : "gi-md gi-search text-white"}
              disabled={showWarning}
              handleOnClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleSearch(undefined, true);
              }}
            />
          </div>
        </TooltipWrapper>
      </>
    );
  }, [
    isHeader,
    selected,
    suggestion,
    inputRef,
    inputMaxHeight,
    defaultQuery,
    disabled,
    inputHeight,
    open,
    query,
    setOpen,
    setSelected,
    onChange,
    onSearch,
    navigate,
    handleSearch,
  ]);
  return (
    <div
      id={id}
      className={classNames(
        "w-full overscroll-contain rounded transition-all duration-200",
        className,
      )}
      style={{ width: open ? inputMaxWidth : inputWidth, maxWidth: maxWidth }}
    >
      {showBackdrop && (open || showSearchOption) && (
        <div
          className={classNames(
            "fixed inset-0 bg-black/80",
            showSearchOption ? "top-0 z-[100]" : "top-[59px] -z-[100]",
            backDropClass,
          )}
        />
      )}
      <div className="g-full relative w-full" ref={buttonRef}>
        {isHeader ? (
          <div
            className={classNames(
              "rounded border border-gray-300 bg-white focus-within:border-gray-400",
            )}
          >
            <div className="w-full pr-9">{renderTextInput()}</div>
            <div className="w-[calc(100% + 2px)] relative ml-[-1px]">
              <Transition as={Fragment} {...transitionClasses} show={open}>
                {options}
              </Transition>
            </div>
          </div>
        ) : (
          <div className={classNames("giboo-gradient-bg rounded-[25px] p-[1px]")}>
            <div
              className={classNames(
                "relative rounded-[24px] bg-white",
                // showSearchOption ? "pr-2" : "",
                containerClass,
              )}
              style={containerStyle}
            >
              <div className="w-full pr-12">{renderTextInput()}</div>
              {open && dirty && (
                <span className="absolute right-1 top-[90px] rounded bg-white px-2 py-1 text-xs text-gray-600">
                  {`${getWordLength(query)}/${maxLength}`}
                </span>
              )}
              <div className={classNames("relative w-full")}>
                <Transition as={Fragment} {...transitionClasses} show={open}>
                  {options}
                </Transition>
              </div>
            </div>
          </div>
        )}
      </div>
      {children && children({ query, setQuery })}
      {/* {showActionButton && !open && (
        <div
          className="mt-2 flex w-fit cursor-pointer items-center gap-2 px-2 py-1 text-sm text-purple-500"
          onClick={() => {
            {
              setShowMoreSearchOption((prev) => !prev);
            }
          }}
        >
          <i className="gi-add gi" />
          <h5 className="whitespace-nowrap">More search options</h5>
        </div>
      )} */}
    </div>
  );
}

const GibooTotalSearch = React.forwardRef(gibooTotalSearch) as (
  p: GibooTotalSearchProps & { ref?: Ref<HTMLInputElement> },
) => ReactElement;

export default GibooTotalSearch;
