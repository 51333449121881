import React from "react";
export default function AssessmentResult({
  title = "Assessment result:",
  content,
}: {
  title?: string;
  content: string;
}) {
  return (
    <div className="min-h-10 flex flex-col gap-[10px] rounded border border-gray-200  p-4">
      <div className="flex flex-col gap-2 ">
        <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
          <span className="h-5 w-5">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.74805 14.375H13.748V15.625H8.74805V14.375ZM6.24805 14.375H7.49805V15.625H6.24805V14.375ZM8.74805 11.25H13.748V12.5H8.74805V11.25ZM6.24805 11.25H7.49805V12.5H6.24805V11.25ZM8.74805 8.125H13.748V9.375H8.74805V8.125ZM6.24805 8.125H7.49805V9.375H6.24805V8.125Z"
                fill="#5C38A7"
              />
              <path
                d="M15.623 3.125H13.748V2.5C13.748 2.16848 13.6164 1.85054 13.3819 1.61612C13.1475 1.3817 12.8296 1.25 12.498 1.25H7.49805C7.16653 1.25 6.84858 1.3817 6.61416 1.61612C6.37974 1.85054 6.24805 2.16848 6.24805 2.5V3.125H4.37305C4.04153 3.125 3.72358 3.2567 3.48916 3.49112C3.25474 3.72554 3.12305 4.04348 3.12305 4.375V17.5C3.12305 17.8315 3.25474 18.1495 3.48916 18.3839C3.72358 18.6183 4.04153 18.75 4.37305 18.75H15.623C15.9546 18.75 16.2725 18.6183 16.5069 18.3839C16.7414 18.1495 16.873 17.8315 16.873 17.5V4.375C16.873 4.04348 16.7414 3.72554 16.5069 3.49112C16.2725 3.2567 15.9546 3.125 15.623 3.125ZM7.49805 2.5H12.498V5H7.49805V2.5ZM15.623 17.5H4.37305V4.375H6.24805V6.25H13.748V4.375H15.623V17.5Z"
                fill="#5C38A7"
              />
            </svg>
          </span>
          <p className="text-sm font-semibold text-purple-500">{title}</p>
        </div>
        <div className="font-poppins text-sm text-gray-800">{content || ""}</div>
      </div>
    </div>
  );
}
