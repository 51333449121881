import axios from "axios";
import config from "../api";
import { ILocation } from "../types/location";

const getTaxonomies = (type: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL + `/api/v1/taxonomy?taxonomy=${type}`, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getOtherTaxonomies = (taxonomy_id: string, limit = 1000) => {
  const project_id = "64522fa85ab99dc821129267";
  const url =
    process.env.REACT_APP_API_URL +
    `/api/v2/tmtprojects/${project_id}/concepts?taxonomy_id=${taxonomy_id}&limit=${limit}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getTaxonomiesRelated = (
  taxonomy_id: string,
  relative_taxonomy_id: string,
  taxonomy: string[],
  high_threshold = false,
) => {
  if (taxonomy.length === 0) return new Promise<string[]>((resolve) => resolve([]));
  return new Promise<string[]>((resolve, reject) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/api/v1/taxonomy/${taxonomy_id}/related_concepts?relative_taxonomy_id=${relative_taxonomy_id}&concept=${taxonomy
            .map((i) => encodeURIComponent(i))
            .join("&concept=")}`,
        config,
      )
      .then(({ data }) => {
        const maxCount = data.reduce(
          (prev: number, cur: { label: string; count: number }) => Math.max(prev, cur.count),
          0,
        );
        const threshold = high_threshold
          ? Math.round((maxCount * 2) / 5)
          : Math.round(maxCount / 6);
        resolve(
          data
            .filter((i: { label: string; count: number }) => i.count >= threshold)
            .map((i: { label: string; count: number }) => i.label),
        );
      })
      .catch((e) => {
        resolve([]);
      });
  });
};

export { getTaxonomies, getOtherTaxonomies, getTaxonomiesRelated };
