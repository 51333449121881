import classNames from "classnames";

import ProjectTaskItem from "../../../components/ProjectTaskItem";
import useOnboardingData from "../../../hooks/useOnboarding";
import Slider from "../../../components/tailwind/Slider";
import { useCallback, useEffect, useState } from "react";
import { IProject } from "../../../types/project";
import { Placeholder } from "rsuite";
import IMAGE_NO_TASKS from "../../../assets/project-mgt/mytask-placeholder.svg";
import SmartSearchButton from "../../../components/SmartSearchButton";
import { useNavigate } from "react-router-dom";
import GotoSmartSearchContext from "../../../components/useGotoSmartSearch";
import useScrollTopByDefault from "../../../hooks/useScrollTopByDefault";
import useMySubtasks from "../../../hooks/project/useMySubtasks";
import SubtaskTable from "../../../components/SubtaskTable";
import Spacer from "../../../components/Spacer";
import PlainButton from "../../../components/PlainButton";
import usePageTitle from "../../../hooks/usePagetitle";
import Spinner from "../../../components/Spinner";
export enum TASK_STATUS {
  NOT_STARTED = "Not started",
  IN_PROGRESS = "In progress",
  DONE = "Done",
}
const hideColumns = ["Task under", "Delete"];
export default function MyTasks() {
  const [onboardingData] = useOnboardingData();
  // const { data: projects, isLoading: projectLoading } = useMyProjectsHavingSubtask(
  //   onboardingData._id,
  // );
  // NOT STARTED
  const toggleNotstarted =
    sessionStorage.getItem("PROJECT_MY_TASK_TOGGLE_NOT_STARTED") === "close" ? false : true;
  const updateNotstartedToggle = useCallback((flag: boolean) => {
    sessionStorage.setItem("PROJECT_MY_TASK_TOGGLE_NOT_STARTED", flag ? "open" : "close");
  }, []);
  const [openNotstarted, setOpenNotstarted] = useState<boolean>(toggleNotstarted);
  // IN PROGRESS
  const toggleInprogress =
    sessionStorage.getItem("PROJECT_MY_TASK_TOGGLE_IN_PROGRESS") === "close" ? false : true;
  const updateInprogressToggle = useCallback((flag: boolean) => {
    sessionStorage.setItem("PROJECT_MY_TASK_TOGGLE_IN_PROGRESS", flag ? "open" : "close");
  }, []);
  const [openInprogress, setOpenInprogress] = useState<boolean>(toggleInprogress);

  // DONE
  const toggleDone =
    sessionStorage.getItem("PROJECT_MY_TASK_TOGGLE_DONE") === "close" ? false : true;
  const updateDoneToggle = useCallback((flag: boolean) => {
    sessionStorage.setItem("PROJECT_MY_TASK_TOGGLE_DONE", flag ? "open" : "close");
  }, []);
  const [openDone, setOpenDone] = useState<boolean>(toggleDone);

  useScrollTopByDefault();
  const navigate = useNavigate();
  const { data: tasks, isLoading, revalidate } = useMySubtasks();
  usePageTitle("My tasks", tasks.length);
  const renderEmpty = () => {
    return (
      <div className="grid h-[304px] w-full place-items-center gap-7">
        <img src={IMAGE_NO_TASKS} alt="no tasks" className="h-fit w-fit" />
        <h5 className="font-poppins text-sm font-normal text-black">
          No tasks yet. Begin fundraising by finding funders or grants!
        </h5>
        <GotoSmartSearchContext project_id={undefined}>
          {({ go, isLoading }) => (
            <>
              {isLoading ? (
                <Spinner size="sm" />
              ) : (
                <SmartSearchButton
                  labelClass="!text-sm !font-normal"
                  handleOnclick={() => {
                    go();
                  }}
                />
              )}
            </>
          )}
        </GotoSmartSearchContext>
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <>
        <div
          className={classNames(
            "sticky top-0 z-10 inline-flex w-full items-center justify-between border-b bg-white px-4 py-3",
            !open && "!border-0",
          )}
        >
          <div className="inline-flex items-center gap-3">
            <span>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="30" height="30" rx="3.65854" fill="#F3EAF9" />
                <path
                  d="M13.8566 17.3914L11.8044 15.3392L10.9984 16.1452L13.8566 19.0034L19.0015 13.8586L18.1954 13.0469L13.8566 17.3914Z"
                  fill="#C25DBE"
                />
                <path
                  d="M20.1448 8.71494H18.4299V8.14329C18.4299 7.84007 18.3094 7.54927 18.095 7.33486C17.8806 7.12045 17.5898 7 17.2866 7H12.7134C12.4102 7 12.1194 7.12045 11.905 7.33486C11.6906 7.54927 11.5701 7.84007 11.5701 8.14329V8.71494H9.85521C9.55199 8.71494 9.26119 8.83539 9.04678 9.0498C8.83237 9.26421 8.71191 9.55501 8.71191 9.85823V21.8628C8.71191 22.166 8.83237 22.4568 9.04678 22.6712C9.26119 22.8856 9.55199 23.0061 9.85521 23.0061H20.1448C20.4481 23.0061 20.7389 22.8856 20.9533 22.6712C21.1677 22.4568 21.2881 22.166 21.2881 21.8628V9.85823C21.2881 9.55501 21.1677 9.26421 20.9533 9.0498C20.7389 8.83539 20.4481 8.71494 20.1448 8.71494ZM12.7134 8.14329H17.2866V10.4299H12.7134V8.14329ZM20.1448 21.8628H9.85521V9.85823H11.5701V11.5732H18.4299V9.85823H20.1448V21.8628Z"
                  fill="#C25DBE"
                />
              </svg>
            </span>
            <h6 className="font-poppins text-base font-semibold text-gray-900">Your tasks</h6>
          </div>
        </div>
      </>
    );
  };
  const renderTasks = () => {
    return (
      <div className="overscroll-x-scroll flex w-full max-w-[1250px] flex-col gap-[50px]">
        <div className="flex flex-col gap-5">
          <div className="inline-flex items-center gap-2">
            <h6 className="font-poppins text-base font-semibold">Not started</h6>
            <PlainButton
              className="!p-0"
              id={`your-tasks-collapse`}
              label=""
              leftIconClass={classNames(openNotstarted ? " gi-angle-up" : " gi-angle-down")}
              handleOnClick={() => {
                updateNotstartedToggle(!openNotstarted);
                setOpenNotstarted((prev) => !prev);
              }}
            />
          </div>
          {openNotstarted && (
            <SubtaskTable
              data={tasks.filter((st) => st.status === TASK_STATUS.NOT_STARTED)}
              autoHeight={false}
              revalidate_my_subtask_list={revalidate}
              hideColumns={hideColumns}
              hasEditPermission
              defaultStatus={TASK_STATUS.NOT_STARTED}
            />
          )}
        </div>
        <div className="flex flex-col gap-5">
          <div className="inline-flex items-center gap-2">
            <h6 className="font-poppins text-base font-semibold">In progress</h6>
            <PlainButton
              className="!p-0"
              id={`your-tasks-collapse`}
              label=""
              leftIconClass={classNames(openInprogress ? " gi-angle-up" : " gi-angle-down")}
              handleOnClick={() => {
                updateInprogressToggle(!openInprogress);
                setOpenInprogress((prev) => !prev);
              }}
            />
          </div>
          {openInprogress && (
            <SubtaskTable
              data={tasks.filter((st) => st.status === TASK_STATUS.IN_PROGRESS)}
              autoHeight={false}
              revalidate_my_subtask_list={revalidate}
              hideColumns={hideColumns}
              defaultStatus={TASK_STATUS.IN_PROGRESS}
              hasEditPermission
            />
          )}
        </div>
        <div className="flex flex-col gap-5">
          <div className="inline-flex items-center gap-2">
            <h6 className="font-poppins text-base font-semibold">Done</h6>
            <PlainButton
              className="!p-0"
              id={`your-tasks-collapse`}
              label=""
              leftIconClass={classNames(openDone ? " gi-angle-up" : " gi-angle-down")}
              handleOnClick={() => {
                updateDoneToggle(!openDone);
                setOpenDone((prev) => !prev);
              }}
            />
          </div>
          {openDone && (
            <SubtaskTable
              data={tasks.filter((st) => st.status === TASK_STATUS.DONE)}
              autoHeight={false}
              revalidate_my_subtask_list={revalidate}
              hideColumns={hideColumns}
              defaultStatus={TASK_STATUS.DONE}
              hasEditPermission
            />
          )}
        </div>
      </div>
    );
  };
  return (
    <div
      className={classNames(
        "ml-[113px] mt-[68px] w-full  overflow-hidden overflow-y-scroll border  border-gray-300 bg-white",
        "rounded-tl-[10px]",
      )}
      style={{ height: "calc(100vh  - 125px)", maxWidth: `calc(100vw)` }}
    >
      {renderHeader()}
      <div className="h-full w-full pl-[47px] pt-[41px]">
        {isLoading
          ? Array(3)
              .fill("#")
              .map((_, idx) => (
                <Placeholder.Graph key={idx} active height={220} className="rounded" />
              ))
          : renderTasks()}
      </div>
    </div>
  );
}
