import classNames from "classnames";
import { useParams } from "react-router-dom";
import ProjectTaskItem from "./ProjectTaskItem";
import useTasks from "../hooks/project/useTasks";

export default function ProjectTask() {
  const { id: projectId } = useParams();
  const { data: tasks } = useTasks(projectId);
  return (
    <>
      <div className={classNames("relative flex w-full flex-col gap-5")}>
        {projectId &&
          tasks.map((i, index) => (
            <ProjectTaskItem
              key={"task_" + index}
              task_id={i._id}
              projectId={projectId}
              autoHeight={false}
            />
          ))}
      </div>
    </>
  );
}
