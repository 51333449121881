import { useCallback, useEffect, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import useOrgID from "../useOrgID";
import { ChatActionLetter } from "../../types/document";
interface PreviousLettersState {
  data: ChatActionLetter[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<ChatActionLetter[] | undefined>;
}
function usePreviousLetters(target_id?: string, target_type?: 0 | 1 | 2): PreviousLettersState {
  const org_id = useOrgID();
  const url =
    process.env.REACT_APP_API_URL +
    `/api/v2/document/${org_id}/previous_letters?target_id=${target_id}&target_type=${target_type}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<ChatActionLetter[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as ChatActionLetter[]);
  };
  const { data, isLoading, error, isValidating } = useSWR<ChatActionLetter[]>(
    target_id && (target_type === 0 || target_type === 1) ? url : null,
    fetch,
    {
      keepPreviousData: false,
    },
  );
  const [letters, setLetters] = useState<ChatActionLetter[]>([]);
  useEffect(() => {
    if (!data) setLetters([]);
    else setLetters(data.filter((d) => d.output.replace(/\s/g, "")));
  }, [data]);
  const revalidate = useCallback(() => mutate<ChatActionLetter[]>(url), [url]);
  return {
    data: letters,
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default usePreviousLetters;
