import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDocumentAIContext } from "../ProjectDocumentAI";
import useDocumentSearch, {
  DOCUMENT_RECENT_LIMIT,
  DOCUMENT_RECENT_LIMIT_LAST_DAYS,
} from "../../../../hooks/project/useDocumentSearch";
import { DocumentTypeEnum, GibooDocument } from "../../../../types/document";
import ProjectName from "../../../../components/ProjectName";
import { useLocation, useNavigate } from "react-router-dom";
import OutReachPickerModal from "../../../../components/OutReachPickerModal";
import { scrollBarClass } from "../../../../utils/classes";
import { Popover, Whisper } from "rsuite";
import Spinner from "../../../../components/Spinner";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import PlainTextInput from "../../../../components/tailwind/PlainTextInput";
import { currentDateString } from "../../../../utils/dateUtils";
import { toDateString } from "../../../../utils/formatHelper";
import { createProject } from "../../../../hooks/project/useProject";
import { IProject } from "../../../../types/project";
import { appendProjectAsync } from "../../../../app/projectSlice";
import { useAppDispatch } from "../../../../app/store";
import useOrgID from "../../../../hooks/useOrgID";
import useProjects from "../../../../hooks/project/useProjects";
import GibooToast from "../../../../components/GibooToast";
import { createProjectTask } from "../../../../hooks/project/useTask";

interface ProjectDirectory {
  open: boolean;
  project_id: string;
  list: GibooDocument[];
  latest: string;
}
export default function DocumentAIRecents() {
  const whisperRef = useRef(null);
  const navigate = useNavigate();
  const browserLocation = useLocation();
  const {
    document,
    project_id,
    document_id,
    documentDefinition,
    isChatLoading,
    createDocument,
    setRenameNewDocument,
    renameNewDocument,
  } = useDocumentAIContext();
  const {
    data: documentList,
    isLoading,
    renameDocument,
    deleteDocument,
    revalidate,
  } = useDocumentSearch(
    "",
    undefined,
    undefined,
    0,
    DOCUMENT_RECENT_LIMIT,
    DOCUMENT_RECENT_LIMIT_LAST_DAYS,
  );
  const [collapse, setCollapase] = useState<boolean>(false);
  const [data, setData] = useState<ProjectDirectory[]>([]);
  const [showoutreach, setShowoutreach] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<string>("");
  const [renameTarget, setRenameTarget] = useState<string>("");
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const org_id = useOrgID();
  const [projects] = useProjects();

  useEffect(() => {
    if (browserLocation?.state?.showOutreachModal) {
      setShowoutreach(true);
    }
  }, [browserLocation]);
  useEffect(() => {
    const group: { [key: string]: { latest: string; list: GibooDocument[] } } = documentList.reduce(
      (prev, cur) => ({
        ...prev,
        ...(cur.project_id
          ? {
              [cur.project_id]: prev[cur.project_id]
                ? {
                    latest:
                      prev[cur.project_id].latest.localeCompare(cur.created_at || "") < 0
                        ? cur.created_at
                        : prev[cur.project_id].latest,
                    list: [...prev[cur.project_id].list, cur],
                  }
                : { latest: cur.created_at || "", list: [cur] },
            }
          : {}),
      }),
      {} as { [key: string]: { latest: string; list: GibooDocument[] } },
    );
    setData((prev) =>
      Object.keys(group)
        .map((key) => ({
          ...group[key],
          list: group[key].list.sort(
            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
          ),
          project_id: key,
          open: project_id === key || prev.find((p) => p.project_id === key)?.open ? true : false,
        }))
        .sort((a, b) => b.latest.localeCompare(a.latest)),
    );
  }, [documentList]);
  const renderHeader = () => {
    return (
      <div className="place-items-left grid h-[40px] w-full border-b px-5 py-[10px]">
        <div className="inline-flex items-center gap-2">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.60387 5.44996C4.91264 6.13101 4.38425 6.95946 4.05819 7.87341C3.73212 8.78736 3.61681 9.76318 3.72087 10.728C3.73055 10.8576 3.6894 10.9858 3.60613 11.0856C3.52286 11.1854 3.40402 11.2488 3.27478 11.2625C3.14555 11.2761 3.01607 11.2389 2.9138 11.1587C2.81152 11.0786 2.74447 10.9617 2.72687 10.833C2.60685 9.71866 2.74012 8.59163 3.11674 7.53606C3.49336 6.48048 4.1036 5.52363 4.90187 4.73696C7.83887 1.83996 12.5769 1.88696 15.4839 4.83496C18.3909 7.78196 18.3719 12.52 15.4339 15.417C14.074 16.7605 12.2483 17.5277 10.3369 17.559C9.61422 17.5718 8.89349 17.4806 8.19687 17.288C8.06903 17.2527 7.96045 17.1681 7.895 17.0527C7.82955 16.9374 7.81259 16.8008 7.84787 16.673C7.88314 16.5451 7.96775 16.4365 8.08309 16.3711C8.19842 16.3056 8.33503 16.2887 8.46287 16.324C9.06703 16.4911 9.69212 16.5702 10.3189 16.559C11.9738 16.5325 13.5546 15.8684 14.7319 14.705C17.2729 12.199 17.2939 8.09496 14.7719 5.53696C12.2499 2.97896 8.14487 2.94396 5.60387 5.44996Z"
              fill="#5C38A7"
              stroke="#5C38A7"
              strokeWidth="0.3"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.59396 11.3623C3.55023 11.4113 3.49728 11.4512 3.43813 11.4797C3.37899 11.5083 3.3148 11.5248 3.24924 11.5286C3.18368 11.5323 3.11803 11.523 3.05605 11.5014C2.99406 11.4797 2.93694 11.4461 2.88796 11.4023L1.16796 9.87233C1.11508 9.82989 1.07139 9.77713 1.03955 9.71728C1.0077 9.65742 0.988358 9.59171 0.982701 9.52414C0.977043 9.45658 0.985187 9.38857 1.00664 9.32424C1.02809 9.25992 1.06239 9.20064 1.10747 9.14999C1.15255 9.09934 1.20746 9.0584 1.26886 9.02964C1.33026 9.00087 1.39687 8.9849 1.46464 8.98269C1.5324 8.98048 1.59991 8.99207 1.66306 9.01676C1.72621 9.04146 1.78367 9.07874 1.83196 9.12633L3.55196 10.6563C3.60108 10.6999 3.64114 10.7528 3.66984 10.8118C3.69854 10.8709 3.71532 10.9351 3.71922 11.0006C3.72312 11.0662 3.71406 11.1319 3.69257 11.1939C3.67108 11.256 3.63757 11.3132 3.59396 11.3623Z"
              fill="#5C38A7"
              stroke="#5C38A7"
              strokeWidth="0.3"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.81997 11.3C2.85937 11.3525 2.90873 11.3968 2.96523 11.4302C3.02173 11.4637 3.08426 11.4857 3.14926 11.495C3.21426 11.5043 3.28046 11.5007 3.34406 11.4844C3.40767 11.4681 3.46744 11.4394 3.51997 11.4L5.51997 9.9C5.62606 9.82043 5.69619 9.70199 5.71495 9.57071C5.7337 9.43944 5.69954 9.30609 5.61997 9.2C5.54041 9.09391 5.42196 9.02378 5.29068 9.00502C5.15941 8.98627 5.02606 9.02044 4.91997 9.1L2.91997 10.6C2.81389 10.6796 2.74375 10.798 2.725 10.9293C2.70624 11.0606 2.74041 11.1939 2.81997 11.3ZM9.99997 6.5C10.1326 6.5 10.2598 6.55268 10.3535 6.64645C10.4473 6.74021 10.5 6.86739 10.5 7V10.5C10.5 10.6326 10.4473 10.7598 10.3535 10.8536C10.2598 10.9473 10.1326 11 9.99997 11C9.86736 11 9.74019 10.9473 9.64642 10.8536C9.55265 10.7598 9.49997 10.6326 9.49997 10.5V7C9.49997 6.86739 9.55265 6.74021 9.64642 6.64645C9.74019 6.55268 9.86736 6.5 9.99997 6.5Z"
              fill="#5C38A7"
              stroke="#5C38A7"
              strokeWidth="0.3"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.5 10.5C13.5 10.6326 13.4473 10.7598 13.3536 10.8536C13.2598 10.9473 13.1326 11 13 11H10C9.86739 11 9.74021 10.9473 9.64645 10.8536C9.55268 10.7598 9.5 10.6326 9.5 10.5C9.5 10.3674 9.55268 10.2402 9.64645 10.1464C9.74021 10.0527 9.86739 10 10 10H13C13.1326 10 13.2598 10.0527 13.3536 10.1464C13.4473 10.2402 13.5 10.3674 13.5 10.5Z"
              fill="#5C38A7"
              stroke="#5C38A7"
              strokeWidth="0.3"
            />
          </svg>
          {!collapse && (
            <p className="font-poppins text-sm font-semibold text-purple-500">Recent</p>
          )}
        </div>
      </div>
    );
  };
  const renderFloatButton = () => {
    return (
      <div className="absolute right-[-50px] top-[100px] z-[51] h-fit">
        <div
          className="inline-flex rotate-90 cursor-pointer items-center gap-[10px] rounded-t bg-purple-500 px-2 py-1 text-white"
          onClick={() => setCollapase((prev) => !prev)}
        >
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.75738 3.75538C1.77573 4.73701 1.16486 6.02867 1.02885 7.41024C0.892849 8.7918 1.24013 10.1778 2.01152 11.332C2.78291 12.4862 3.93067 13.3372 5.2592 13.74C6.58773 14.1427 8.01482 14.0724 9.29728 13.5408C10.5797 13.0093 11.6382 12.0495 12.2923 10.825C12.9464 9.6005 13.1556 8.18707 12.8843 6.82559C12.613 5.46411 11.878 4.23882 10.8046 3.35854C9.73112 2.47825 8.38562 1.99744 6.99738 1.99805L5.66671 1.99805"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.00008 0.666015L5.66675 1.99935L7.00008 3.33268M9.66675 7.33268L6.33341 7.33268L6.33341 10.666"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h6 className="text-normal font-poppins text-xs">{collapse ? "Open" : "Close"}</h6>
        </div>
      </div>
    );
  };
  const closePopupMenu = useCallback(() => {
    (whisperRef?.current as any)?.close();
    setOpenMenu("");
  }, [whisperRef, whisperRef?.current, setOpenMenu]);
  const handleRename = useCallback(
    (id: string) => {
      setRenameTarget(id);
      closePopupMenu();
    },
    [setOpenMenu, closePopupMenu],
  );
  const handleDelete = useCallback(
    (id: string) => {
      deleteDocument(id);
      if (document?.subtask_id === id) {
        navigate(`/project/${project_id ? `${project_id}/` : ""}document`);
      }
      closePopupMenu();
    },
    [project_id, document, deleteDocument, closePopupMenu],
  );
  const filpProject = useCallback(
    (project_id: string) => {
      setData((prev) => {
        const findIndex = prev.findIndex((d) => d.project_id === project_id);
        if (findIndex < 0) return prev;
        return [
          ...prev.slice(0, findIndex),
          {
            ...prev[findIndex],
            open: !prev[findIndex].open,
          },
          ...prev.slice(findIndex + 1),
        ];
      });
    },
    [setData],
  );
  const renderDocumentItem = useCallback(
    (item: ProjectDirectory | undefined, c: GibooDocument, beforeCreated = false) => {
      const selected = document_id ? c._id === document_id : beforeCreated;
      return (
        <div
          key={c._id}
          className={classNames(
            "group flex w-full cursor-pointer items-start gap-1 rounded",
            collapse ? "px-2 hover:bg-gray-100" : "",
          )}
          onClick={() => {
            if (collapse) {
              //setCollapase(false);
            } else if (!beforeCreated) {
              navigate(
                // `/project/${item?.project_id ? `${item.project_id}/` : ""}document/${c._id}`,
                `/${project_id ? `project/${project_id}/` : ""}document/${c._id}`,
              );
            }
          }}
        >
          {!item ? null : (
            <span
              className={classNames(
                "mt-1 grid h-6 w-6 min-w-[24px] place-items-center rounded-full",
                collapse && selected ? "bg-purple-50" : "",
              )}
            >
              <i className="gi-arrow-down-right gi-sm" />
            </span>
          )}
          {collapse ? (
            <div className="h-8 w-full">
              {beforeCreated ? (
                <span
                  className={classNames(
                    "grid h-6 w-6 min-w-[24px] place-items-center rounded-full",
                    selected ? "bg-purple-50" : "bg-gray-100 group-hover:bg-gray-100",
                  )}
                >
                  <i className="gi-chat gi-sm" />
                </span>
              ) : null}
            </div>
          ) : (
            <div
              className={classNames(
                "inline-flex w-full items-center justify-between gap-[10px] rounded py-1",
                !collapse && selected ? "bg-purple-50" : "bg-white group-hover:bg-gray-100",
                "px-2",
              )}
              style={{
                width: `calc(100% - ${collapse || !item ? "0px" : "28px"})`,
              }}
            >
              <div className={classNames("flex w-[calc(100%-32px)] grow items-start gap-[10px]")}>
                <span
                  className={classNames(
                    "grid h-6 w-6 min-w-[24px] place-items-center rounded-full",
                    "bg-gray-100",
                  )}
                >
                  <i className="gi-chat gi-sm" />
                </span>
                <div className="flex w-[calc(100%-32px)] flex-col gap-1">
                  {c.subtask_id === renameTarget ? (
                    <EditInput
                      shouldFocus
                      wrapperClass="w-full"
                      initalValue={c.name || c.output.slice(0, 50)}
                      onBlur={() => setRenameTarget("")}
                      onChange={(s) => {
                        if (c.subtask_id === "new_document") {
                          if (!documentDefinition) return;
                          setRenameNewDocument(s);
                          createDocument(documentDefinition, s, true)
                            .then((ids) => {
                              if (ids && ids.includes("@")) {
                                const did = ids.split("@")[0];
                                const pid = ids.split("@")[1];
                                revalidate().finally(() => {
                                  navigate(`/project/${pid}/document/${did}`, {
                                    replace: document_id ? false : true,
                                  });
                                  setRenameNewDocument("");
                                });
                              }
                            })
                            .catch(() => setRenameNewDocument(""));
                        } else renameDocument(c.subtask_id, s);
                      }}
                    />
                  ) : (
                    <h5 className="w-full truncate overflow-ellipsis text-sm">
                      {c.name || c.output.slice(0, 50)}
                    </h5>
                  )}
                  <h6 className="text-gray-700">{toDateString(c.created_at, true, false)}</h6>
                </div>
              </div>
              <Whisper
                {...(openMenu === c._id ? { ref: whisperRef } : {})}
                trigger={"click"}
                placement={"bottomStart"}
                controlId="control-id-hover-enterable"
                className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)]"
                onClose={() => setOpenMenu("")}
                speaker={
                  <Popover
                    prefix="custom-tooltip"
                    className={classNames("flex flex-col p-1")}
                    arrow={false}
                  >
                    <div
                      className="flex h-8 w-full cursor-pointer items-center gap-2 px-2 py-1 hover:bg-gray-100"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRename(c.subtask_id);
                      }}
                    >
                      <i className="gi gi-pencil_edit" />
                      <p className="font-poppins text-xs text-gray-700">Rename document</p>
                    </div>
                    {c._id !== "new_document" && (
                      <div
                        className="flex h-8 w-full cursor-pointer items-center gap-2 px-2 py-1 hover:bg-gray-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(c.subtask_id);
                        }}
                      >
                        <i className="gi gi-trash" />
                        <p className="font-poppins text-xs text-gray-700">Delete document</p>
                      </div>
                    )}
                  </Popover>
                }
              >
                <div
                  className={classNames(
                    openMenu === c._id ? "grid" : "hidden",
                    "group h-[21px] w-[21px] place-items-center rounded-full bg-gray-200 p-1 group-hover:grid",
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenMenu(c._id);
                  }}
                >
                  <i className="gi-meatballs-menu ml-[-0.5px] rotate-90" />
                </div>
              </Whisper>
            </div>
          )}
        </div>
      );
    },
    [
      collapse,
      document_id,
      openMenu,
      renameTarget,
      setRenameTarget,
      renameDocument,
      createDocument,
      documentDefinition,
      revalidate,
    ],
  );
  const renderProjectDirectory = useCallback(
    (item: ProjectDirectory) => {
      return (
        <>
          <div
            className={classNames(
              "inline-flex w-full cursor-pointer items-center gap-[10px] rounded px-2 py-1",
              project_id === item.project_id ? "bg-gray-50" : "hover:bg-gray-100",
            )}
            onClick={() => {
              filpProject(item.project_id);
              if (collapse) {
                //setCollapase(false);
              } else {
                // if (!document_id)
                //   navigate(`/project/${item.project_id}/document${browserLocation.search}`);
              }
            }}
          >
            <span
              className={classNames(
                "grid h-6 w-6 min-w-[24px] place-items-center rounded-full",
                collapse ? "" : "bg-purple-50",
              )}
            >
              <i className="gi-project gi-sm" />
            </span>
            {collapse ? (
              <div className="h-6 w-full"></div>
            ) : (
              <div className="w-[calc(100%-68px)]">
                <ProjectName id={item.project_id} className="truncate overflow-ellipsis text-sm" />
              </div>
            )}
            <Whisper
              {...(openMenu === `p_${item.project_id}` ? { ref: whisperRef } : {})}
              trigger={"click"}
              placement={"bottomStart"}
              controlId="control-id-hover-enterable"
              className="shadow-[1px_2px_6px_2px_rgba(0,0,0,0.1)]"
              onClose={() => setOpenMenu("")}
              speaker={
                <Popover
                  prefix="custom-tooltip"
                  className={classNames("flex flex-col p-1")}
                  arrow={false}
                >
                  <div
                    className="flex h-8 w-full cursor-pointer items-center gap-2 px-2 py-1 hover:bg-gray-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/project/${item.project_id}/`);
                      closePopupMenu();
                    }}
                  >
                    <i className="gi gi-project" />
                    <p className="font-poppins text-xs text-gray-700">Go to project</p>
                  </div>
                  <div
                    className="flex h-8 w-full cursor-pointer items-center gap-2 px-2 py-1 hover:bg-gray-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      filpProject(item.project_id);
                      closePopupMenu();
                    }}
                  >
                    <i
                      className={classNames(
                        "gi",
                        item.open ? "gi-angle-up" : "gi-angle-down mt-[2px]",
                      )}
                    />
                    <p className="font-poppins text-xs text-gray-700">
                      {item.open ? "Fold" : "Unfold"}
                    </p>
                  </div>
                </Popover>
              }
            >
              <div
                className={classNames(
                  "group flex h-[21px] w-[21px] min-w-[21px] items-center justify-center rounded-full bg-gray-200",
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenMenu(`p_${item.project_id}`);
                }}
              >
                <i
                  className={classNames(
                    "grid group-hover:hidden",
                    item.open ? "gi-angle-up" : "gi-angle-down mt-[2px]",
                    "gi group-hover:gi-meatballs-menu group-hover:ml-[-0.5px] group-hover:rotate-90",
                  )}
                />
                <i
                  className={classNames(
                    "hidden group-hover:grid",
                    "gi gi-meatballs-menu ml-[-0.5px] rotate-90",
                  )}
                />
              </div>
            </Whisper>
          </div>
          {(item.list.length > 0 || (project_id === item.project_id && !document_id)) &&
            item.open && (
              <div className={classNames("flex flex-col gap-3", collapse ? "" : "ml-5")}>
                {project_id === item.project_id && !document_id && (
                  <div key={"new_project"} className={classNames("flex w-full flex-col gap-2")}>
                    {renderDocumentItem(
                      item,
                      {
                        _id: "new_document",
                        created_at: currentDateString(),
                        updated_at: currentDateString(),
                        node_history_path: [],
                        output: "",
                        name:
                          renameNewDocument ||
                          `${
                            documentDefinition?.short_name ||
                            documentDefinition?.name ||
                            "Custom document"
                          } draft`,
                        datetime_key: 0,
                        subtask_id: "new_document",
                        type: documentDefinition?.type || DocumentTypeEnum.CUSTOM,
                      },
                      true,
                    )}
                  </div>
                )}
                {item.list
                  .filter((d) => item.open || d._id === document_id)
                  .map((c) => (
                    <div key={c._id}>{renderDocumentItem(item, c)}</div>
                  ))}
              </div>
            )}
        </>
      );
    },
    [
      collapse,
      project_id,
      document_id,
      openMenu,
      filpProject,
      closePopupMenu,
      documentDefinition,
      setOpenMenu,
      renderDocumentItem,
    ],
  );

  const handleCreateProjectTask = (docType?: DocumentTypeEnum) => {
    const projectName = `Untitled project (${projects.data.length})`;
    setIsCreating(true);
    const data = {
      name: projectName,
      org_id,
      completed: false,
    };
    createProject({ ...data, name: projectName })
      .then((res: IProject) => {
        Promise.resolve(dispatch(appendProjectAsync(res))).then(() => {
          if (res) {
            createProjectTask({
              project_id: res._id,
              target_id: browserLocation.state.target_id,
              target_type: browserLocation.state.target_type,
            })
              .then((taskRes) => {
                navigate(`/project/${res._id}/document?type=${docType}`, {
                  state: {
                    showBack: true,
                    target: browserLocation?.state?.target,
                  },
                });
              })
              .catch((err) => {
                GibooToast({
                  type: "failure",
                  message: `We apologize for the inconvenience. Please try again.`,
                });
              })
              .finally(() => {
                setIsCreating(false);
                setShowoutreach(false);
              });
          } else {
            setIsCreating(false);
            setShowoutreach(false);
          }
        });
      })
      .catch((err) => {
        setIsCreating(false);
        GibooToast({
          type: "failure",
          message: `We apologize for the inconvenience. Please try again.`,
        });
      });
  };
  return (
    <div
      className={classNames(
        "relative flex flex-col rounded-tl border border-b-0 bg-white transition-all",
        collapse ? "w-[60px] max-w-[60px]" : "w-[282px] min-w-[282px]",
      )}
    >
      {showoutreach && (
        <OutReachPickerModal
          open={showoutreach}
          setOpen={setShowoutreach}
          showBackDrop={true}
          loading={isCreating}
          onSelect={(data) => {
            if (projects.data.length > 0) {
              navigate(
                `${project_id ? `/project/${project_id}` : ""}/document?type=${data.typeEnum}`,
                { state: { target: browserLocation?.state?.target } },
              );
              setShowoutreach(false);
            } else {
              //creating project if there is no project
              handleCreateProjectTask(data?.typeEnum);
            }
          }}
        />
      )}
      {/* {renderFloatButton()} */}
      {renderHeader()}
      <div
        className={classNames(
          "loading-light-shimmer flex h-full flex-col gap-2 overflow-y-scroll py-2",
          isLoading ? "loading-light-shimmer-on" : "",
          scrollBarClass,
        )}
      >
        {!document_id &&
          (!project_id || !data.find((d) => d.project_id === project_id && d.list.length > 0)) && (
            <div key={"new_project"} className={classNames("flex w-full flex-col gap-2 px-1")}>
              {renderDocumentItem(
                undefined,
                {
                  _id: "new_document",
                  created_at: currentDateString(),
                  updated_at: currentDateString(),
                  node_history_path: [],
                  output: "",
                  name:
                    renameNewDocument ||
                    `${
                      documentDefinition?.short_name ||
                      documentDefinition?.name ||
                      "Custom document"
                    } draft`,
                  datetime_key: 0,
                  subtask_id: "new_document",
                  type: documentDefinition?.type || DocumentTypeEnum.CUSTOM,
                },
                true,
              )}
            </div>
          )}
        {isLoading ? (
          <>
            {[1.05, 0.95, 1.15].map((r) => (
              <div className="loading-light-shimmer-child px-3 py-2" key={`${r}`}>
                <div
                  className={classNames("min-h-[36px]")}
                  style={{
                    minWidth: collapse ? "20px" : `${Math.round(r * 200)}px`,
                    width: collapse ? "20px" : `${Math.round(r * 200)}px`,
                  }}
                ></div>
              </div>
            ))}
          </>
        ) : (
          <>
            {data
              .filter((i) => i.project_id === project_id)
              .map((item) => {
                return (
                  <div
                    key={item.project_id}
                    className={classNames("flex w-full flex-col gap-2 px-1 py-1")}
                  >
                    {renderProjectDirectory(item)}
                  </div>
                );
              })}
            {data.filter((i) => i.project_id !== project_id).length > 0 && (
              <div className="relative mb-3 mt-6 w-full px-2">
                <div className="relative h-[1px] w-full bg-gray-300" />
                <h6 className="absolute z-[2] ml-8 mt-[-10px] bg-white px-2 text-gray-700">
                  Other projects
                </h6>
              </div>
            )}
            {data
              .filter((i) => i.project_id !== project_id)
              .map((item) => {
                return (
                  <div
                    key={item.project_id}
                    className={classNames("flex w-full flex-col gap-2 px-1 py-1")}
                  >
                    {renderProjectDirectory(item)}
                  </div>
                );
              })}
          </>
        )}
      </div>
      <div className="flex w-full flex-col items-center pb-7 pt-4">
        <div
          className={classNames(
            "flex h-12 w-[210px] items-center gap-2 rounded-[20px_20px_0px_20px] px-8 py-3 text-white ",
            isChatLoading
              ? "cursor-not-allowed bg-gray-500"
              : "giboo-purple-gradient cursor-pointer hover:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.15),0px_1px_2px_0px_rgba(0,0,0,0.30)]",
          )}
          onClick={() => {
            // navigate(`${project_id ? `/project/${project_id}` : ""}/document`);
            if (!isChatLoading) setShowoutreach(true);
          }}
        >
          <i className="gi-chat-plus text-2xl" />
          <span className="text-sm">New document</span>
        </div>
      </div>
    </div>
  );
}
function EditInput({
  shouldFocus = false,
  isLoading,
  initalValue,
  noValueLabel = "No Name",
  wrapperClass,
  textInputWrapper,
  textInputClass,
  textClass,
  placeholder = "Enter value",
  withOutBorder = false,
  height = "h-8",
  onChange,
  onBlur,
  ...props
}: {
  shouldFocus?: boolean;
  isLoading?: boolean;
  initalValue: string;
  height?: string;
  noValueLabel?: string;
  placeholder?: string;
  textInputWrapper?: string;
  textInputClass?: string;
  wrapperClass?: string;
  textClass?: string;
  withOutBorder?: boolean;
  onChange: (value: string) => void;
  onBlur: () => void;
}) {
  const [text, setText] = useState<string>(initalValue);
  useEffect(() => {
    setText(initalValue);
  }, [initalValue]);
  const inputRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(shouldFocus);
  const handleUpdateAndClose = useCallback(() => {
    onBlur();
    setOpen(false);
    if (text.length === 0) {
      setText(initalValue);
      return;
    }
    if (text && text !== initalValue) {
      text.length > 0 && onChange(text);
    }
  }, [onBlur, setOpen, text, initalValue, text, onChange]);
  useOnClickOutside(inputRef, () => {
    if (open) {
      handleUpdateAndClose();
    }
  });
  return (
    <div className={classNames(wrapperClass, height)}>
      <div
        ref={inputRef}
        className={classNames(
          "flex w-full place-items-center rounded",
          open ? "border-purple-300" : "",
          height,
          !withOutBorder && open && "border",
        )}
      >
        <PlainTextInput
          id="click-to-edit"
          value={text || ""}
          className={classNames("w-full text-sm text-gray-900", textInputClass)}
          placeholder={placeholder}
          handleOnChange={(e) => {
            setText(e.target.value);
          }}
          autoFocus
          handleOnKeyDown={(e) => {
            if (e.key === "Enter") {
              handleUpdateAndClose();
            }
          }}
        />
      </div>
    </div>
  );
}
