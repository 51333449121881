import React, {
  Dispatch,
  ReactNode,
  Ref,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import Spacer from "../../../../components/Spacer";
import UserProfile from "../../../../components/UserProfile";
import { convertToReadable, reprTimeDistance, toDateString } from "../../../../utils/formatHelper";
import IMG_EXPAND from "../../../../assets/images/expand.svg";
import IMG_PROJECT from "../../../../assets/images/project.svg";

import IMG_MATCHED from "../../../../assets/images/whyMatchedWithoutRound.svg";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import ClickToEditInput from "../../../../components/input/ClickToEditInput";
import ProjectAddAcitivitySlider from "./ProjectAddAcitivitySlider";
import { IProjectInteraction, IProjectInteractionCreateRequest } from "../../../../types/project";
import useInteractionsOfProject from "../../../../hooks/project/useInteractionsOfProject";
import useInteractions from "../../../../hooks/project/useInteractions";
import ReprLogData from "../../../../components/ReprLogData";
import useInteraction, { createProjectInteraction } from "../../../../hooks/project/useInteraction";
import Spinner from "../../../../components/Spinner";
import { format } from "date-fns";
import useProject from "../../../../hooks/project/useProject";
import { TooltipWrapper } from "../../../../components/TooltipWrapper";
import useLatestDocument from "../../../../hooks/project/useLatestDocument";
import { useLocation, useNavigate } from "react-router-dom";

const useResizeObserver = (
  ref: React.RefObject<HTMLElement>,
  callback: (contentRect: ResizeObserverEntry) => void,
) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        callback(entry);
      }
    });
    const targetElement = ref?.current;
    if (targetElement) {
      resizeObserver.observe(targetElement);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, callback]);
};

export default function ProjectTaskActivities({
  projectId,
  taskId,
  fromDashboard,
  showTrigger,
}: {
  projectId?: string;
  taskId?: string;
  fromDashboard?: boolean;
  showTrigger?: string;
}) {
  const navigate = useNavigate();
  const browserLocation = useLocation();
  const [toAdd, setToAdd] = useState<IProjectInteractionCreateRequest>();
  const {
    data: latestDocument,
    isLoading: isLatestDocumentLoading,
    revalidate: revalidateLatestDocument,
  } = useLatestDocument(projectId, taskId, 1, "created");
  const [showSlider, setShowSlider] = useState<boolean>(false);
  const [addNew, setAddNew] = useState<boolean>(false);
  const activityRef = useRef<HTMLDivElement | null>(null);
  const { data: projectData } = useProject(projectId);

  // temp maintain
  const [ltsStatus, setLtsStatus] = useState("");
  const [ltsStatusContent, setLtsStatusContent] = useState("");
  //activity timeline start
  const {
    data: dataByProject,
    isLoading: isInteractionsByProjectLoading,
    revalidate: revalidateInteractionsOfProject,
  } = useInteractionsOfProject(!taskId ? projectId : undefined);
  const {
    data: dataByTask,
    isLoading: isInteractionsByTaskLoading,
    revalidate,
  } = useInteractions(taskId);
  const [timelineData, setTimelineData] = useState<IProjectInteraction[]>([]);
  const [allActivities, setAllActivities] = useState<IProjectInteraction[]>([]);
  const [compactTimeline, setCompactTimeline] = useState(false);
  const timelineContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (showTrigger) setShowSlider(true);
  }, [showTrigger]);
  useResizeObserver(timelineContainerRef, (entry) => {
    if (entry?.contentRect?.width < 650) {
      setCompactTimeline(true);
    } else {
      setCompactTimeline(false);
    }
  });
  useOnClickOutside(timelineContainerRef, () => {
    if (browserLocation.hash === "#project_progress")
      navigate(`${browserLocation.pathname}${browserLocation.search}`, {
        replace: true,
        state: browserLocation.state,
      });
  });
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect.width < 500) {
          setCompactTimeline(true);
        } else {
          setCompactTimeline(false);
        }
      }
    });
    const timelineContainer = timelineContainerRef.current;
    if (timelineContainer) {
      resizeObserver.observe(timelineContainer);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  const [selected, setSelected] = useState<IProjectInteraction | undefined>(undefined);
  const [latest, setLatest] = useState<IProjectInteraction | undefined>(undefined);
  const [isCreating, setIsCreating] = useState(false);
  useEffect(() => {
    const _data = taskId ? dataByTask : projectId ? dataByProject : [];
    const ordered = _data
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      .filter((i) => i.type !== "Created");
    // setAddNew(ordered.length > 0 ? false : true);
    const cleanedData = !fromDashboard
      ? ordered.filter((i) => !["Joined", "Added", "Created"].includes(i.type))
      : ordered;
    setTimelineData(cleanedData);
    setLatest(cleanedData.length > 0 ? cleanedData[0] : undefined);
    setAllActivities(cleanedData);
    if (cleanedData.length > 0) {
      setLatest(cleanedData.length > 0 ? cleanedData[0] : undefined);
    } else {
      // setAddNew(true);
      setLatest(undefined);
    }
  }, [projectId, taskId, dataByTask, dataByProject]);

  //activity timeline end
  const { deleteThis } = useInteraction(latest?._id);
  // console.log("delete activity", { id: latest?._id, deleteThis });
  useOnClickOutside(activityRef, () => {
    // if (addNew) {
    if (!ltsStatus && ltsStatusContent) {
      // need warning
    }
    if (ltsStatus) {
      setIsCreating(true);
      createProjectInteraction({
        project_id: projectId || "",
        task_id: taskId,
        title: ltsStatus,
        content: ltsStatusContent || "",
      })
        .catch(() => {
          setLtsStatus("");
          setLtsStatusContent("");
          setAddNew(false);
        })
        .finally(() => {
          revalidate();
          revalidateInteractionsOfProject();
          setIsCreating(false);
          setLtsStatus("");
          setLtsStatusContent("");
          setAddNew(false);
        });
    } else {
      setLtsStatus("");
      setLtsStatusContent("");
      setAddNew(false);
    }
    // }
  });

  const getActivityContent = (data: IProjectInteraction) => {
    return (
      <div className="group flex  h-full w-full flex-col p-4 hover:bg-gray-50">
        <div className="max-h-[80px] w-full grow">
          <div className="inline-flex w-full justify-between">
            <div className="w-full">
              <p className="font-poppins text-sm font-semibold group-hover:underline">
                {data.data && data.data.length > 0 ? (
                  <ReprLogData
                    id={data._id}
                    project_id={data.project_id}
                    task_id={data.task_id}
                    data={data.data}
                    disableClick
                  />
                ) : (
                  data.title
                )}
              </p>
            </div>
            <span
              className=" hidden cursor-pointer place-items-center group-hover:grid"
              onClick={() => {
                // for test
                // deleteThis().finally(() => {
                //   revalidate();
                //   revalidateInteractionsOfProject();
                // });
                setSelected(data);
                setShowSlider(true);
              }}
            >
              <img src={IMG_EXPAND} className="h-3 w-3" />
            </span>
          </div>
          <h6 className="mt-2 font-poppins text-xs font-normal">
            {data?.content ? convertToReadable(data?.content) : "-"}
          </h6>
        </div>
        <div className="inline-flex h-[18px] w-full justify-between gap-3">
          <span className="w-[200px]">
            <UserProfile userId={data.user_id} showName={true} size={24} />
          </span>
          <h5 className="whitespace-nowrap font-poppins text-[10px] text-gray-700">
            {data?.created_at
              ? toDateString(data?.created_at, true, true)
              : toDateString(new Date(), true, true)}
          </h5>
        </div>
      </div>
    );
  };
  const activitySuggestion = useCallback(() => {
    const name = `${latestDocument?.name || "Letter draft"}`;
    return (
      <div
        className="group flex  h-full w-full flex-col bg-blue-50  p-4 hover:bg-blue-100"
        onClick={() => {
          if (projectId)
            setToAdd({
              title: name,
              project_id: projectId,
              document_id: latestDocument?._id,
              task_id: taskId,
              content: "",
            });
          setShowSlider(true);
        }}
      >
        <div className="max-h-[80px] w-full grow">
          <div className="inline-flex w-full justify-between">
            <div className="inline-flex w-full items-center gap-2">
              <span className="grid place-items-center">
                <img src={IMG_MATCHED} className="" />
              </span>
              <p className="font-poppins text-sm font-semibold group-hover:underline">{name}</p>
            </div>
          </div>
          <h6 className="mt-2 font-poppins text-xs font-normal">
            You have recently created a document. Would you like to update the latest status?
          </h6>
        </div>
      </div>
    );
  }, [latestDocument, projectId, taskId, setToAdd, setShowSlider]);
  const getAddActivityContent = useCallback(() => {
    return (
      <div className="group flex  h-full w-full flex-col bg-gray-50 p-4 hover:bg-gray-50">
        <div className="min-h-[80px] w-full grow">
          <div className="inline-flex w-full justify-between">
            <div className="w-full max-w-[310px]">
              <ClickToEditInput
                shouldFocus
                initalValue={ltsStatus}
                placeholder="Enter the latest project progress"
                noValueLabel="Enter the latest project progress"
                height="!h-[30px]"
                wrapperClass="max-w-fit min-w-[250px]"
                textInputClass="!max-w-fit !text-sm placeholder:!text-sm !font-normal !text-gray-700"
                textClass="line-clamp-1 w-full font-poppins !text-sm  !text-gray-700 underline"
                onInputChange={(val) => {
                  setLtsStatus(val);
                }}
                withOutBorder
                withoutEditIcon
              />
            </div>
            <span
              className="grid cursor-pointer place-items-center"
              onClick={() => {
                setShowSlider(true);
              }}
            >
              <img src={IMG_EXPAND} className="h-3 w-3" />
            </span>
          </div>
          <div className="mt-2 max-w-[310px]">
            <ClickToEditInput
              initalValue={ltsStatusContent}
              placeholder="Try entering the latest project progress"
              noValueLabel="Try entering the latest project progress"
              height="!h-[30px]"
              wrapperClass="max-w-full"
              textInputClass="!max-w-full !text-xs placeholder:!text-xs !font-normal !text-gray-700"
              textClass="line-clamp-1 w-full !text-xs font-poppins !font-normal !text-gray-700"
              onInputChange={(val) => {
                setLtsStatusContent(val);
              }}
              withOutBorder
              withoutEditIcon
            />
          </div>
          <div className="flex justify-end">{isCreating && <Spinner size="sm" />}</div>
        </div>
      </div>
    );
  }, [ltsStatusContent, ltsStatus, setLtsStatusContent, setLtsStatus]);
  return (
    <>
      {showSlider && projectId && (
        <ProjectAddAcitivitySlider
          projectId={projectId}
          taskId={taskId}
          interaction={selected}
          onClose={() => {
            setShowSlider(false);
            setSelected(undefined);
            setToAdd(undefined);
            // revalidate();
          }}
          toAdd={toAdd}
          show={showSlider}
          onAdd={() => revalidateLatestDocument()}
        />
      )}
      <div
        ref={timelineContainerRef}
        className={classNames(
          "flex min-h-[306px] w-[745px] flex-col rounded px-4 pt-4",
          browserLocation.hash === "#project_progress"
            ? "border-2 border-purple-500"
            : "border border-gray-200",
        )}
      >
        <div className="mb-[10px] flex items-center justify-between gap-4">
          <div className="inline-flex items-center gap-2">
            <div className="font-poppins text-sm font-semibold text-purple-500">
              Project progress
            </div>
            <TooltipWrapper
              WrapperClass="max-w-[310px] !p-4 !rounded-[4px]"
              Zindex={9999}
              placements="right"
              enterable
              content={`Please keep your project progress updated to stay informed or share with members of your organization. Record the latest activities here to track the project's progress comprehensively.`}
            >
              <span className="grid place-items-center">
                <i className="gi-md gi-info font-semibold text-purple-500 " />
              </span>
            </TooltipWrapper>
          </div>
          <div
            className="giboo-btn-text-purple cursor-pointer text-sm font-normal text-gray-600"
            onClick={() => {
              setShowSlider(true);
            }}
          >
            View all
          </div>
        </div>
        <div className="grid h-full grid-cols-1 gap-[52px] bg-white md:grid-cols-[300px_1fr]">
          {/* status */}
          <div className="flex h-full w-full max-w-[300px] flex-col justify-between pl-2">
            <div className="flex h-[186px] items-center justify-between pt-[10px]">
              {isInteractionsByProjectLoading ||
              isInteractionsByTaskLoading ||
              isLatestDocumentLoading ? (
                <ActivityItem suggestion isStacked stackCount={0}>
                  <div className="flex h-full items-center justify-center">
                    <Spinner />
                  </div>
                </ActivityItem>
              ) : !addNew && latestDocument ? (
                <ActivityItem suggestion isStacked stackCount={allActivities.length}>
                  {activitySuggestion()}
                </ActivityItem>
              ) : !addNew && latest ? (
                <ActivityItem
                  isStacked
                  stackCount={allActivities.length}
                  onClick={() => {
                    setSelected(latest);
                    setShowSlider(true);
                  }}
                >
                  {getActivityContent(latest)}
                </ActivityItem>
              ) : (
                <ActivityItem isStacked stackCount={allActivities.length} ref={activityRef}>
                  {getAddActivityContent()}
                </ActivityItem>
              )}
            </div>
            {((!addNew && latestDocument) || (!addNew && latest)) && (
              <div className="mt-[35px] self-center">
                <div
                  className="shadow-top  grid h-[37px] w-[274px] cursor-pointer place-items-center rounded-[10px_10px_0px_0px] bg-white shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)] hover:bg-purple-50"
                  onClick={() => {
                    setAddNew(true);
                  }}
                >
                  <span className="text-purple-500"> + Add new</span>
                </div>
              </div>
            )}
          </div>
          {/* timeline */}
          <div className="flex h-full max-h-[250px] w-full max-w-full flex-col justify-between overflow-y-scroll">
            <ActivityTimeline
              projectId={projectId}
              taskId={taskId}
              timelineData={timelineData}
              setShowSlider={setShowSlider}
              setSelected={setSelected}
              fromDashboard={fromDashboard}
              footer={
                <>
                  {/* showing  project created only on project dashboard */}
                  {fromDashboard && (
                    <TimelineItem
                      borderType="solid"
                      icon={
                        <span>
                          <img src={IMG_PROJECT} className="h-4 w-4" />
                        </span>
                      }
                      isLast={false}
                      customIcon
                      description={
                        <TimelineContent title="Project created" date={projectData.created_at} />
                      }
                    />
                  )}
                  <TimelineItem
                    borderType="solid"
                    icon={
                      <span>
                        <i className="gi gi-add" />
                      </span>
                    }
                    isLast
                    customIcon
                    onClick={() => {
                      setShowSlider(true);
                    }}
                    description={
                      <TimelineContent
                        wrapperClass="cursor-pointer mb-5 h-10"
                        title={
                          <p className="giboo-btn-text-purple -mt-.5 text-sm text-gray-600">
                            Update activities
                          </p>
                        }
                      />
                    }
                  />
                </>
              }
              wrapperClass="pl-6"
            />
          </div>
        </div>
      </div>
    </>
  );
}
type ActivityItemProps = {
  children: ReactNode;
  suggestion?: boolean;
  isStacked?: boolean;
  wrapperClass?: string;
  stackCount?: number;
  onClick?: () => void;
};
const ActivityItem = forwardRef<HTMLDivElement, ActivityItemProps>(
  ({ children, suggestion = false, isStacked, wrapperClass, stackCount = 2, ...props }, ref) => {
    const count = stackCount >= 2 ? 2 : stackCount;
    return (
      <div
        ref={ref}
        id="stack"
        className="group relative mx-auto flex h-[160px] cursor-pointer flex-col  items-center"
        onClick={props?.onClick}
      >
        {isStacked && count == 2 && (
          <div className="absolute top-[22px] h-[140px] w-[233px]  overflow-hidden rounded-[10px]  bg-white shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)] transition-all "></div>
        )}
        {isStacked && count >= 1 && (
          <div className="absolute top-[12px] h-[140px] w-[274px] overflow-hidden rounded-[10px]  bg-white shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)] transition-all"></div>
        )}
        <div
          className={classNames(
            "absolute  h-[140px] w-[310px] overflow-hidden rounded-[10px] border border-gray-200 bg-white",
            wrapperClass,
          )}
        >
          <div
            className={classNames(
              "h-2 w-full overflow-hidden ",
              suggestion ? "giboo-gradient-bg" : "bg-purple-500",
            )}
          />
          {children}
        </div>
      </div>
    );
  },
);
ActivityItem.displayName = "ActivityItem";
const transformData = (
  data: IProjectInteraction[],
): {
  lastElement: IProjectInteraction[];
  firstTwoElements: IProjectInteraction[];
  remainingCount: number;
} => {
  if (data.length >= 3) {
    const lastElement = data.length > 0 ? [data[data.length - 1]] : [];
    const firstTwoElements = data.slice(0, 2);
    const remainingCount = Math.max(data.length - 3, 0);
    return {
      lastElement: lastElement,
      firstTwoElements,
      remainingCount,
    };
  } else {
    const firstTwoElements = data || [];
    const lastElement: any[] = [];
    const remainingCount = 0;
    return {
      lastElement: lastElement,
      firstTwoElements: firstTwoElements,
      remainingCount: remainingCount,
    };
  }
};
export function ActivityTimeline({
  projectId,
  taskId,
  timelineData,
  header,
  footer,
  renderItem,
  setShowSlider,
  wrapperClass,
  fromDashboard,
  setSelected,
  iconClass = "!bg-white",
}: {
  projectId?: string;
  taskId?: string;
  wrapperClass?: string;
  timelineData: IProjectInteraction[];
  header?: ReactNode;
  footer?: ReactNode;
  renderItem?: (data: IProjectInteraction) => ReactNode;
  setShowSlider?: Dispatch<SetStateAction<boolean>>;
  setSelected?: Dispatch<SetStateAction<IProjectInteraction | undefined>>;
  fromDashboard?: boolean;
  iconClass?: string;
}) {
  const { data: projectData } = useProject(projectId);
  const data = timelineData;
  return (
    <div className={classNames("mb-10 grid grow gap-10", "grid-cols-1", wrapperClass)}>
      <Timeline wrapperClass={classNames("self-end relative")}>
        <TimelineItem
          icon={<i className="gi gi-calendar text-gray-700"></i>}
          borderType="dashed"
          iconClass={iconClass}
          customIcon
          description={
            <TimelineContent
              wrapperClass={"mb-[28px]"}
              title={
                <TooltipWrapper
                  content="Due date"
                  textClass="!text-xs"
                  color="gray"
                  arrow={false}
                  placements="topStart"
                >
                  <div>
                    {format(
                      projectData?.project_end ? new Date(projectData?.project_end) : new Date(),
                      "MMM dd",
                    )}
                  </div>
                </TooltipWrapper>
              }
            />
          }
        />
        {header}
        {data.map((i, index) => (
          <TimelineItem
            key={i._id}
            data={i}
            icon={
              i.type === "Joined" ? (
                <UserProfile userId={i.user_id} showName={false} size={16} />
              ) : i.type === "Created" ? (
                <span>
                  <img src={IMG_PROJECT} className="h-4 w-4" />
                </span>
              ) : i.type === "Added" ? (
                <UserProfile userId={i.user_id} showName={false} size={16} />
              ) : (
                <></>
              )
            }
            onClick={() => {
              setSelected?.(i);
              setShowSlider?.(true);
            }}
            borderType={"solid"}
            customIcon={i.type === "Joined" || i.type === "Created" || i.type === "Added"}
            description={
              renderItem ? (
                renderItem(i)
              ) : (
                <TimelineContent
                  title={
                    <>
                      {i.data && i.data.length > 0 ? (
                        <ReprLogData
                          project_id={i.project_id || projectId}
                          task_id={i.task_id || taskId}
                          id={i._id}
                          data={i.data}
                          disableClick
                        />
                      ) : (
                        <span>{i.title}</span>
                      )}
                    </>
                  }
                  date={i.created_at}
                />
              )
            }
          />
        ))}
        <TimelineItem
          borderType="dashed"
          contentClass="mb-[80px]"
          icon={
            <span>
              <img src={IMG_PROJECT} className="h-4 w-4" />
            </span>
          }
          iconClass={iconClass}
          customIcon
          isLast
          description={<TimelineContent title="Project created" date={projectData.created_at} />}
        />
      </Timeline>
    </div>
  );
}
export const TimelineContent = ({
  title,
  date,
  wrapperClass,
  data,
  onClick,
}: {
  title: string | ReactNode;
  date?: string | ReactNode;
  wrapperClass?: string;
  onClick?: (data?: IProjectInteraction) => void;
  data?: IProjectInteraction;
}) => {
  return (
    <div
      className={classNames("flex flex-col gap-1", wrapperClass)}
      onClick={() => {
        onClick?.(data);
      }}
    >
      <div className="line-clamp-3 w-full max-w-fit overflow-ellipsis font-poppins text-xs text-gray-900">
        {title}
      </div>
      {date && (
        <p className="fonp whitespace-nowrap text-[10px] text-gray-700">
          {typeof date === "string" ? toDateString(date, true, true) : date}
        </p>
      )}
    </div>
  );
};
interface TimelineItemProps {
  icon?: ReactNode;
  description: ReactNode;
  borderClass?: string;
  iconClass?: string;
  borderType?: "solid" | "dashed";
  customIcon?: boolean;
  isLast?: boolean;
  contentClass?: string;
  onClick?: (data?: IProjectInteraction) => void;
  data?: IProjectInteraction;
}

const Timeline = ({
  children,
  header,
  footer,
  wrapperClass,
}: {
  children: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  wrapperClass?: string;
}) => {
  return (
    <div className={classNames("container", wrapperClass)}>
      {header}
      <div className="grid grid-cols-[25px_1fr]">{children}</div>
      {footer}
    </div>
  );
};

export const TimelineItem: React.FC<TimelineItemProps> = ({
  icon,
  description,
  borderClass,
  iconClass,
  borderType,
  customIcon,
  isLast,
  contentClass,
  onClick,
  data,
}) => {
  return (
    <div className="contents">
      <div className="relative mr-10 md:mx-auto">
        <div className="ml-[-20px] flex h-full w-[17px] items-center justify-center">
          {!isLast && (
            <div
              className={classNames(
                `h-full w-1 border-${borderType} pointer-events-none border-l border-purple-500`,
                borderClass,
              )}
            />
          )}
        </div>
        <div
          className={classNames(
            `absolute left-[-1px]  top-0 ml-[-20px] grid h-[17px] w-[17px] place-items-center bg-white`,
            !customIcon && "rounded-full !bg-purple-500 shadow",
            iconClass,
          )}
        >
          {icon}
        </div>
      </div>
      <div
        className={classNames(
          `col-start-2 col-end-12 -ml-2 mb-5 mr-auto w-full max-w-full text-xs`,
          contentClass,
        )}
        onClick={() => onClick?.(data)}
      >
        {description}
      </div>
    </div>
  );
};
