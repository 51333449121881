import axios from "axios";
import config from "../../api";
import useSWR from "swr";
import useOnboardingData from "../useOnboarding";

interface IsPreviousFunderState {
  data: boolean | undefined;
  isLoading: boolean;
  error: object;
}
const swrOption = {
  dedupingInterval: 1000 * 300,
  refreshInterval: 0,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
export default function useIsPreviousFunder(funder_id?: string): IsPreviousFunderState {
  const [onboardingData] = useOnboardingData();
  const url =
    onboardingData.npo_id && funder_id
      ? process.env.REACT_APP_API_URL +
        `/api/v2/npos/${onboardingData.npo_id}/granted?donor_id=${funder_id}`
      : null;
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<boolean>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data?.granted);
  };
  const { data, isLoading, error, isValidating } = useSWR<boolean>(
    url ? url : null,
    fetch,
    swrOption,
  );
  return { data: onboardingData.npo_id ? data : false, isLoading, error };
}
