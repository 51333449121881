import { useEffect, useState } from "react";
import {
  selectBasicInformation,
  selectRoles,
  updateOnboardingAsync,
} from "../../../app/onboardingSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import MultipleSelectBoxGroup from "../../../components/input/MultipleSelectBoxGroup";
import PrimaryButton from "../../../components/PrimaryButton";
import useOnboardingData from "../../../hooks/useOnboarding";
import { roleKeys, roleLabels, TypeRole } from "../../../types/onboarding";
import OnboardingBack from "./OnboardingBack";
import useTotalOrganizationMember from "../../../hooks/useTotalOrganizationMember";
import { getTotalOrganizationMembersId } from "../../../hooks/useTotalOrganizationMembersData";
import useUser from "../../../hooks/useUser";
import useSWR from "swr";
import { IOption } from "../../../components/tailwind/AsyncCreatableSelector";
import { getOtherTaxonomies } from "../../../services/taxonomy.services";
import CreatableSelector from "../../../components/selector/CreatableSelector";
import GibooGradientDiv from "../../../components/GibooGradientDiv";
import { Divider, Steps } from "rsuite";
import PlainButton from "../../../components/PlainButton";
import RoundButton from "../../../components/RoundButton";
import FakeProgressBar from "../../../components/FakeProgressBar";
import { toBytesFormat } from "../../../utils/formatHelper";
import classNames from "classnames";
import MemoizedDropArea from "../../../components/input/FileDropArea";
import { uploadFileService } from "../../../services/file-upload/upload.service";
import { getAdminVerificationFileKey, getVerificationFileKey } from "../../../utils/media";
import useRequestOrgVerification from "../../../hooks/useRequestOrgVerification";
import Button from "../../../components/tailwind/Button";
import GibooToast from "../../../components/GibooToast";
import { TooltipWrapper } from "../../../components/TooltipWrapper";
import ICON_FILE from "../../../assets/images/file-upload.svg";
import ICON_VERIFY from "../../../assets/onboarding/org-verify.svg";
import { NPO } from "../../../types/npo";
import { INPO } from "./FindNPO";
import Spacer from "../../../components/Spacer";
import { Information, MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import OnboardingSkipButton from "../../../components/OnboardingSkip";
const swrOption = {
  dedupingInterval: 1000 * 3600,
  refreshInterval: 0,
  revalidateIfStale: false,
  keepPreviousData: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}; // 60 mins
const TITLE_TAXONOMY_ID = "6467c82b784b787ee02f3a08";
const fetchTaxonomiesAsOption = async (url: string) => {
  if (!url) return new Promise<IOption[]>((resolve) => resolve([]));
  return getOtherTaxonomies(url.split("@")[1])
    .then((data) => data as { name: string }[])
    .then((data) =>
      data
        .map((item) => ({ label: item.name, value: item.name } as IOption))
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
};
function Role({ invited = false }: { invited?: boolean }) {
  const mxEvent = useGibooMixpanel();
  const dispatch = useAppDispatch();
  const [onboardingData] = useOnboardingData();
  const [titleOption, setTitleOption] = useState<IOption | undefined>();
  const [user] = useUser();
  const { data: TITLES } = useSWR<IOption[]>(
    `taxonomy@${TITLE_TAXONOMY_ID}`,
    fetchTaxonomiesAsOption,
    swrOption,
  );
  const { data, update } = useTotalOrganizationMember(
    onboardingData._id,
    getTotalOrganizationMembersId({ _id: user?._id || "", type: "user" }),
  );
  useEffect(() => {
    setTitleOption(data?.title ? { label: data.title, value: data.title } : undefined);
  }, [data]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isVerifyInitiated, setIsVerifyInitiated] = useState<boolean>(false);
  const [allowScroll, setAllowScroll] = useState(true);
  useEffect(() => {
    if (!allowScroll || invited) return;
    if (currentStep === 2) return;
    if (data?.title) setCurrentStep(2);
  }, [data, currentStep, allowScroll, invited]);

  const scrollToElement = (elementId: string, offset = 0): void => {
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      const elementTop = targetElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementTop - offset,
        behavior: "smooth",
      });
    }
  };
  const renderStep1 = () => {
    return (
      <div className="flex flex-col">
        {onboardingData.role >= 5 ? (
          <>
            <h4 className="onboarding-header pt-[3px]">
              What is your title within your organization?
            </h4>
            <p className="onboarding-sub-header-gray mt-3">
              Please select or type in your title. This is essential for effective communication
              throughout <br />
              various Giboo services, especially for outreach features including communicating with
              funders.
            </p>
          </>
        ) : (
          <h3 className="onboarding-header">
            You&apos;ve been invited to join.
            <br /> Please specify your title in the organization.*
            {/* <span style={{ fontSize: "14px", paddingLeft: "1em" }}>(Select all that apply)</span> */}
          </h3>
        )}
        <div className="onboarding-body onboarding-width-lg !mt-10 max-w-[640px] !gap-10">
          <div className="container flex flex-col gap-10">
            <div className="flex flex-col gap-2 font-semibold">
              {/* <h5 className="font-semibold text-gray-900">Type in or choose your title</h5> */}
              <CreatableSelector
                id="emp-title"
                placeholder="Choose one"
                placeholderClass="text-purple-900"
                borderClass="border-gray-200"
                className={
                  "!h-[48px] w-[480px] max-w-[480px] bg-white !font-normal !text-purple-900"
                }
                inputContainerClass="h-[48px]"
                data={TITLES || []}
                value={titleOption}
                // invalid={isDirty && title === ""}
                // errorMessage={title === "" ? "Title required." : ""}
                dropdownIcon
                onCreateOption={(val: string) => setTitleOption({ label: val, value: val })}
                onChange={(v) => {
                  setTitleOption(v ? v : undefined);
                  if (!v) {
                    setCurrentStep(0);
                    setAllowScroll(false);
                  }
                }}
              />
            </div>
          </div>
          <div
            className={classNames(
              "mb-10 inline-flex items-center gap-2",
              currentStep === 0 ? "block" : "hidden",
            )}
          >
            <OnboardingBack outline handleOnClick={handleBack} />
            <PrimaryButton
              disabled={!titleOption}
              size="md"
              id="btn-next"
              handleOnClick={() => {
                if (invited) {
                  handleNext();
                  return;
                }
                if (
                  (onboardingData.type === 3 || onboardingData.type === 5) &&
                  onboardingData.role >= 5
                ) {
                  handleNext();
                } else {
                  setCurrentStep(2);
                  setTimeout(() => {
                    scrollToElement("verify-admin", 140);
                  }, 500);
                }
              }}
              rightIconClass={<i className="gi gi-right-arrow" />}
              label="Next"
            />
            <OnboardingSkipButton />
          </div>
        </div>
        {currentStep !== 0 && <Spacer height="60px" />}
      </div>
    );
  };
  const renderDot = (value: number) => {
    return (
      <div
        className={classNames(
          "h-3 w-3 rounded-full",
          value <= currentStep ? "bg-purple-500" : "bg-purple-50",
        )}
      ></div>
    );
  };
  const handleBack = () => {
    update({ title: titleOption?.label });
  };
  const handleNext = () => {
    update({ title: titleOption?.label }).catch(() => update({ title: titleOption?.label }));
    mxEvent(MIXPANEL_EVENTS_V2.onboarding.information.edit, {
      field: Information.USER_TITLE,
      userTitle: titleOption?.label,
    });
    dispatch(
      updateOnboardingAsync(
        {
          // roles: selected,
        },
        +1,
      ),
    );
  };
  const renderComplete = () => {
    return (
      <div className="onboarding">
        <div className="onboarding-width-lg2 mt-2 flex flex-col !gap-[40px] border-b">
          <div>
            <h4 className="onboarding-header">
              Amazing! You&apos;ve successfully initiated the verification process of admin status.
            </h4>
            <p className="onboarding-sub-header mt-3">
              While Giboo verifies your information, let’s continue to explore your nonprofit
              further to find the most compelling funding opportunities!
            </p>
          </div>
          <div className="grid h-[209px] w-[564px] place-items-center rounded border border-green-500 bg-green-50 px-[100px] py-7">
            <div className="grid w-full place-items-center gap-6 text-center">
              <div className="h-[60px] w-[60px]">
                <img src={ICON_VERIFY} className="h-full w-full" />
              </div>
              <h6 className="text-base font-semibold text-green-900">Submitted successfully!</h6>
              <p className="font-poppins text-sm">
                This will be confirmed within{" "}
                <span className="font-poppins font-semibold text-green-900">24-48 hours.</span>
              </p>
            </div>
          </div>
          <div className=" mb-10 inline-flex items-center gap-2">
            <OnboardingBack
              outline
              noAction
              handleOnClick={() => {
                setIsVerifyInitiated(false);
              }}
            />
            <PrimaryButton
              disabled={!titleOption}
              className="!h-[37px]"
              id="btn-next"
              handleOnClick={handleNext}
              rightIconClass={<i className="gi gi-right-arrow" />}
              label="Next"
            />
          </div>
        </div>
      </div>
    );
  };
  if (isVerifyInitiated) {
    return renderComplete();
  }
  return (
    <div className="onboarding flex flex-col">
      {currentStep > 1 ? (
        <Steps current={currentStep} vertical>
          <Steps.Item
            title={false}
            className="!-ml-[40px] !mt-0"
            icon={renderDot(1)}
            description={renderStep1()}
          />
          <Steps.Item
            icon={renderDot(2)}
            className="!-ml-[40px]"
            title={false}
            description={
              <VerifyUser
                selectedNPO={{
                  ein: onboardingData.npo_ein || "",
                  _id: onboardingData.npo_id,
                  name: onboardingData.npo_name || "",
                  address: onboardingData.npo_address || "",
                }}
                type={onboardingData.type}
                onSkip={handleNext}
                onBack={handleBack}
                onComplete={() => setIsVerifyInitiated(true)}
                reset={() => {
                  setAllowScroll(false);
                  setCurrentStep(0);
                }}
              />
            }
          />
        </Steps>
      ) : (
        renderStep1()
      )}
    </div>
  );
}
export default Role;
function VerifyUser({
  selectedNPO,
  type,
  onSkip,
  onComplete,
  onBack,
  reset,
}: {
  selectedNPO: INPO;
  type: number | undefined;
  onSkip: () => void;
  onBack: () => void;
  onComplete: () => void;
  reset: () => void;
}) {
  const mxEvent = useGibooMixpanel("onboarding", true);
  //step2
  const [onboardingData] = useOnboardingData();
  const { data, request } = useRequestOrgVerification(onboardingData._id, true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<
    { file?: File; name: string; size: number; done?: boolean; key: string; failed?: boolean }[]
  >([]);
  const [loadingFileUpload, setLoadingFileUpload] = useState<boolean>(false);
  const [user] = useUser();
  const npoName = selectedNPO.name;
  useEffect(() => {
    if (data.files) setSelectedFile(data.files);
  }, [data]);
  const renderDescription = () => {
    return (
      <div className="flex max-w-[778px] flex-col gap-4">
        <h6 className="font-poppins text-xs text-gray-800">
          After verification, you will have access to the full Giboo features. Verification helps in
          the prevention of fraudulent <br />
          activities or misrepresentation, protecting both your organizations and funders from
          potential scams.
        </h6>
        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            1. Why is ‘Owner’ verification important?
          </h4>
          <p className="font-poppins text-xs text-gray-800">
            Owner verification is crucial because, as the first member of your organization, the
            owner holds the authority to invite and accept other organization members on Giboo.
            Owner verification adds an extra layer of security, preventing fraudulent outreach
            activities or misrepresentation. This verification helps safeguard both your
            organization and potential funders from potential scams. Once owner verification is
            complete, you and your organization members can fully utilize all of Giboo&apos;s
            features. We appreciate your effort in helping maintain the integrity of the Giboo
            community.
          </p>
        </div>

        <div className="flex flex-col gap-3">
          <h4 className="font-poppins text-sm font-semibold italic text-gray-800">
            2. How long does the verification process take?
          </h4>
          <p className="font-poppins text-xs text-gray-800">
            Verification typically takes 24-48 hours, and we&apos;ll notify you as soon as it&apos;s
            complete. Your information and privacy are safe with us, as we use these documents
            exclusively for verification purposes.
          </p>
        </div>
      </div>
    );
  };
  const handleUpload = (file: File) => {
    if (!onboardingData) return;
    setLoadingFileUpload(true);
    onboardingData._id &&
      uploadFileService(file, getAdminVerificationFileKey(onboardingData._id), ["image", "doc"])
        .then((res) => {
          const key = res as string;
          const success = key ? true : false;
          mxEvent(
            success
              ? MIXPANEL_EVENTS_V2.owner_verification.file.successful
              : MIXPANEL_EVENTS_V2.owner_verification.file.failed,
            {
              fileName: file.name,
              fileSize: file.size,
              error: "failed to upload to s3",
            },
          );
          setSelectedFile((prev) => {
            const index = prev.findIndex((f) => f.name === file.name && f.size === file.size);
            if (index >= 0) {
              return [
                ...prev.slice(0, index),
                { ...prev[index], done: success, failed: !success, key: success ? key : "" },
                ...prev.slice(index + 1),
              ];
            } else return prev;
          });
        })
        .catch(() => {
          mxEvent(MIXPANEL_EVENTS_V2.owner_verification.file.failed, {
            fileName: file.name,
            fileSize: file.size,
            error: "failed to upload to s3",
          });
          setSelectedFile((prev) => {
            const index = prev.findIndex((f) => f.name === file.name && f.size === file.size);
            if (index >= 0) {
              return [
                ...prev.slice(0, index),
                { ...prev[index], done: false, failed: true, key: "" },
                ...prev.slice(index + 1),
              ];
            } else return prev;
          });
        })
        .finally(() => setLoadingFileUpload(false));
  };
  const renderFileUpload = () => {
    return (
      <div className="min-h-[192px]  max-w-[564px] rounded bg-gray-50">
        <MemoizedDropArea
          id={"tax file"}
          formats={[".pdf", ".png", ".jpeg", ".jpg", ".doc", ".docx"]}
          containerClass="min-h-[192px] !border-solid border border-gray-200 hover:!border-[2px]  hover:!border-purple-500 hover:!bg-[#FBFAFF]"
          placeholder={
            <div className="grid w-full place-items-center text-center">
              <span>
                <img src={ICON_FILE} className="h-full w-full" />
              </span>
              <h5 className="mt-3 font-poppins text-sm text-gray-700">
                <span className="font-semibold text-purple-500">Click to upload </span>
                or drag and drop here
              </h5>
              <p className="m-2 font-poppins text-sm text-gray-700">
                We support JPEGs, PNGs, PDFs and DOCs under 10MB
              </p>
            </div>
          }
          sizeLimit={10}
          showFileSelection={false}
          onDrop={(arg: File) => {
            setSelectedFile((prev) => [
              ...prev.filter((f) => f.name !== arg.name || f.size !== arg.size),
              { file: arg, name: arg.name, size: arg.size, done: false, key: "" },
            ]);
            handleUpload(arg);
          }}
          resetAlways
        />
      </div>
    );
  };
  const renderSelectedFiles = () => {
    return (
      <div
        className={classNames(
          "onboarding-width-sm flex flex-col gap-4 ",
          selectedFile.length > 0 && "mt-4",
        )}
      >
        {selectedFile.map((item, index) => {
          return (
            <div key={index} className="grid w-full grid-cols-5 items-center gap-2">
              <div
                className={classNames(
                  "col-span-4 grid grid-cols-4 items-center gap-2 rounded px-2 py-1",
                  item.failed ? "bg-purple-50" : "",
                )}
              >
                <span className="col-span-3 truncate whitespace-nowrap">{item.name}</span>
                <div className="col-span-1 flex justify-end">
                  {item.failed ? (
                    <span className="text-sm font-semibold text-purple-500">Failed</span>
                  ) : item.done ? (
                    <span className="text-sm">{toBytesFormat(item.size)}</span>
                  ) : (
                    <FakeProgressBar estimatedSeconds={3} done={item.done} />
                  )}
                </div>
              </div>
              <div className="col-span-1 flex gap-2">
                <RoundButton
                  id={`btn-remove-item-${index}`}
                  icon="gi-md gi-x"
                  type="tertiary"
                  size="sm"
                  handleOnClick={() =>
                    setSelectedFile((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
                  }
                />
                {item.failed && item.file && (
                  <PlainButton
                    handleOnClick={() => {
                      if (item.file) handleUpload(item.file);
                    }}
                    id={`btn-retry-item-${index}`}
                    className="!p-0 !text-sm"
                    color="purple"
                    label="Retry"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="mt-2 max-w-[874px]" id="verify-admin">
      <h4 className="onboarding-header">
        You are the first member from {npoName} on Giboo!
        <br />
        Upload proof that you are actually associated with {npoName}.
      </h4>
      <p className="onboarding-sub-header-gray mt-3">
        As the first member, you will be listed as an ‘Owner’ on your organization on Giboo.
        <br />
        ‘Owner’ status can be transferred to another member at any time.
      </p>

      <div className="my-10">
        <p className="font-poppins text-sm text-gray-700">
          Upload at least one document that validates your association with this organization.
        </p>
        <div className="font-poppins text-sm font-semibold text-gray-900">
          For example, you can upload a photo of your identification badge, letter of employment,
          <br /> or another document that clearly shows your association with this organization.
        </div>
      </div>
      {renderFileUpload()}
      {renderSelectedFiles()}
      <div className="my-10 inline-flex items-center gap-2">
        <OnboardingBack
          outline
          handleOnClick={() => {
            reset();
          }}
          noAction
        />
        <Button
          id="btn-done"
          className="!h-[37px] w-fit !text-sm"
          color="purple"
          label="Next"
          appendIcon={<i className="gi gi-right-arrow" />}
          size="md"
          loading={isLoading}
          disabled={loadingFileUpload || selectedFile.filter((i) => i.done).length === 0}
          handleOnClick={() => {
            if (isLoading || !selectedNPO || !user || !type) return;
            setIsLoading(true);
            request({
              ...user,
              npo_id: selectedNPO._id || "",
              npo_name: selectedNPO.name || "",
              type: type,
              origin: window.location.origin,
              files: selectedFile.filter((f) => f.done && f.key),
            })
              .then(() => {
                mxEvent(MIXPANEL_EVENTS_V2.owner_verification[""].successful, {
                  fileName: selectedFile.map((f) => f.name).join(", "),
                });
                onComplete();
                // GibooToast({
                //   type: "success",
                //   message: `Successfully requested`,
                // });
              })
              .catch(() => {
                mxEvent(MIXPANEL_EVENTS_V2.owner_verification[""].failed, {
                  error: "api error",
                });
                GibooToast({
                  type: "failure",
                  message: `We apologize for the inconvenience., please try again`,
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
        <div
          id="btn-skip"
          className="grid h-[37px] w-[62px] cursor-pointer place-items-center rounded p-1 hover:bg-purple-50"
          onClick={() => {
            mxEvent(MIXPANEL_EVENTS_V2.owner_verification[""].failed, {
              skip: true,
            });
            onSkip();
          }}
        >
          <h5 className="text-sm font-normal text-purple-500">Skip</h5>
        </div>
      </div>
      <Divider className="!mt-0 mb-[60px]" />
      {renderDescription()}
    </div>
  );
}
