import classNames from "classnames";
import { Dispatch, SetStateAction } from "react";
import DropdownSelector from "./DropdownSelector";
import downTag from "../../../assets/images/arrow-down.svg";
import closeTag from "../../../assets/images/closeTag.svg";

interface PlainDropdownSelectorProps {
  id: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green" | "gray";
  data: string[];
  selected: string | undefined;
  setSelected: (v: string | undefined) => void;
  maxHeight?: string;
  openAnimation?: boolean;
  placeholder?: string;
  disabled?: boolean;
  float?: boolean;
  compact?: boolean;
  downIcon?: boolean;
  clearIcon?: boolean;
  leftIconClass?: string;
  rightIconClass?: string;
  defaultValue?: string;
  topPlaceholder?: string;
  wrapperClass?: string;
  inputClass?: string;
}
function PlainDropdownSelector({
  id,
  color,
  data,
  selected,
  setSelected,
  maxHeight,
  openAnimation = false,
  placeholder,
  disabled = false,
  float = false,
  compact = false,
  downIcon = false,
  clearIcon = false,
  leftIconClass,
  rightIconClass,
  defaultValue,
  topPlaceholder,
  wrapperClass,
  inputClass,
}: PlainDropdownSelectorProps) {
  return (
    <div className={classNames("relative w-full", wrapperClass)}>
      {topPlaceholder ? (
        <div className="py-2 font-poppins text-base font-semibold text-gray-900">
          {topPlaceholder}
        </div>
      ) : null}
      <DropdownSelector
        id={id}
        color={color}
        data={data}
        selected={selected}
        setSelected={setSelected}
        maxHeight={maxHeight}
        openAnimation={openAnimation}
        placeholder={placeholder}
        disabled={disabled}
        float={float}
        compact={compact}
        defaultValue={defaultValue}
        getKey={(item) => item}
        inputClass={inputClass}
        renderItem={(item) => (
          <div className="">
            {leftIconClass && <i className={leftIconClass}></i>}
            <span className="mx-2 text-gray-900">{item}</span>
            {rightIconClass && <i className={rightIconClass}></i>}
          </div>
        )}
        renderButton={(item) => (
          <div className="flex items-center justify-between">
            <div>
              {leftIconClass && <i className={leftIconClass}></i>}
              <span className={(leftIconClass || rightIconClass) && "mx-2"}>{item}</span>
              {rightIconClass && <i className={rightIconClass}></i>}
            </div>
            {!selected && downIcon && (
              <span className={classNames("absolute right-0 top-1 my-3 mr-3 cursor-pointer")}>
                <img src={downTag} alt="icon" className="h-[17px] w-[17px]" />
              </span>
            )}
            {selected && clearIcon && (
              <span
                className={classNames("absolute right-0 top-1 my-3 mr-3 cursor-pointer")}
                onClick={() => {
                  setSelected(undefined);
                }}
              >
                <img src={closeTag} alt="icon" className="h-[17px] w-[17px]" />
              </span>
            )}
          </div>
        )}
      />
    </div>
  );
}
export default PlainDropdownSelector;
