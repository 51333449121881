import axios from "axios";
import config from "../../api";
import useSWR from "swr";
import { GrantSearchResult } from "../../types/search";
import { prettierTaxonomy } from "../../utils/philanthropy";

interface GrantSearchResultState {
  data: GrantSearchResult[];
  isLoading: boolean;
  error: object;
}
const swrOption = {
  dedupingInterval: 1000 * 300,
  refreshInterval: 0,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
const emptyList: GrantSearchResult[] = [];
export default function useGrantSearchResults(id?: string[]): GrantSearchResultState {
  const url =
    id && id.length > 0
      ? process.env.REACT_APP_API_URL + `/api/v2/search/grants_searchresult?id=${id.join("&id=")}`
      : null;
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<GrantSearchResult[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) =>
      res.data.map((d: any) => ({
        ...d,
        search_type: "grant",
        ...prettierTaxonomy({
          focus_area: d.focus_area.map((t: string) => ({ label: t, matched: false })),
          beneficiary: d.beneficiary.map((t: string) => ({ label: t, matched: false })),
          program: d.program.map((t: string) => ({ label: t, matched: false })),
          service_loc: d.service_loc,
        }),
      })),
    );
  };
  const { data, isLoading, error } = useSWR<GrantSearchResult[]>(
    url ? url : null,
    fetch,
    swrOption,
  );

  return {
    data: data || emptyList,
    isLoading,
    error,
  };
}
