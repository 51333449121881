import {
  AnyAction,
  Dispatch,
  PayloadAction,
  ThunkAction,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { TreeItem } from "../components/TreeSelector";
import { RootState } from "./store";

interface TaxonomyState {
  isTaxonomyFetched: boolean;
  isAllTaxonomyFetched: boolean;
  uppercaseMap: { [key: string]: string };
  topBeneficiary: string[];
  focus_area: TreeItem[];
  beneficiary: TreeItem[];
  program: TreeItem[];
}
const initialState: TaxonomyState = {
  isTaxonomyFetched: false,
  isAllTaxonomyFetched: false,
  uppercaseMap: {},
  topBeneficiary: [],
  focus_area: [],
  beneficiary: [],
  program: [],
};
const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState,
  reducers: {
    fetch: (state, action: PayloadAction<{ fetched: boolean; data: string[] }>) => {
      state.uppercaseMap = action.payload.data.reduce(
        (prev, cur) => ({ ...prev, [cur.toLowerCase()]: cur }),
        {} as { [key: string]: string },
      );
      state.isTaxonomyFetched = action.payload.fetched;
    },
    fetchTopBeneficiary: (state, action: PayloadAction<{ fetched: boolean; data: string[] }>) => {
      state.topBeneficiary = action.payload.data;
    },
  },
});

const fetchTaxonomyUppercaseAsync = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    // deprecate, until clean taxonomy
    // axios
    //   .get(process.env.REACT_APP_API_URL + `/api/v2/taxonomy/uppercase`, config)
    //   .then((response) => response.data)
    new Promise<string[]>((resolve) => resolve([]))
      .then((data: string[]) => {
        dispatch(
          taxonomySlice.actions.fetch({
            fetched: true,
            data,
          }),
        );
      })
      .catch((e) => {
        // console.log(e);
        dispatch(
          taxonomySlice.actions.fetch({
            fetched: false,
            data: [],
          }),
        );
      });
  };
};

const selectTaxonomy = createDraftSafeSelector(
  (state: RootState) => state,
  (state: RootState): TaxonomyState =>
    state.taxonomySlice || {
      isTaxonomyFetched: false,
      isAllTaxonomyFetched: false,
      uppercaseMap: {},
      topBeneficiary: [],
      focus_area: [],
      beneficiary: [],
      program: [],
    },
);
export { fetchTaxonomyUppercaseAsync, selectTaxonomy };
export default taxonomySlice;
