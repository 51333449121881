import classNames from "classnames";
import useOnboardingData from "../hooks/useOnboarding";
import useOrgs from "../hooks/useOrgs";
import { toDateString } from "../utils/formatHelper";
import { RootState, useAppDispatch, useAppSelector } from "../app/store";
import { createNewOrgAsync, getOnboardingStatus } from "../app/onboardingSlice";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import Spinner from "./Spinner";
import { Popover } from "@headlessui/react";
import OrgProfileCard from "./OrgProfileCard";
import { scrollBarClass } from "../utils/classes";
import { MIXPANEL_EVENTS_V2, Navigate } from "../mixpanel/mixpanel";
import useGibooMixpanel from "../hooks/useGibooMixpanel";

interface OrganizationSelectorProps {
  wrapperClass?: string;
}
const exceptionList = ["/pending", "/accept_invitation"];
function OrganizationSelector({ wrapperClass, ...props }: OrganizationSelectorProps) {
  const mxEvent = useGibooMixpanel();
  const [user] = useUser();
  const { orgs, setOrg } = useOrgs();
  const navigate = useNavigate();
  const browserLocation = useLocation();
  const dispatch = useAppDispatch();
  const { isFetched, isOrgsFetched, completed, selected, accepted } = useAppSelector(
    (state: RootState) => getOnboardingStatus(state),
  );
  const [onboardingData] = useOnboardingData();
  const [loading, setLoading] = useState<boolean>(false);
  const handleNewOrg = () => {
    setLoading(true);
    Promise.resolve(dispatch(createNewOrgAsync())).finally(() =>
      setTimeout(() => setLoading(false), 2000),
    );
  };
  useEffect(() => {
    if (isFetched && selected) {
      if (
        process.env.REACT_APP_ROUTER !== "FUNDER" &&
        !exceptionList.find((e) => browserLocation.pathname.startsWith(e))
      )
        if (!completed || !accepted) navigate("/onboarding");
    }
  }, [selected, completed, accepted]);
  return (
    <>
      {isFetched && orgs.length > 0 ? (
        <Popover className={classNames("relative", wrapperClass)}>
          <Popover.Button className={"focus:outline-none focus:ring-0"} id="btn-org-selector">
            <div className="flex h-9 w-[200px] cursor-pointer items-center gap-2 rounded bg-gray-100 px-2">
              <OrgProfileCard
                org={onboardingData}
                showInvited={false}
                showNotFinished={false}
                showRole={false}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M5.18166 8.44264C5.23972 8.50084 5.30869 8.54702 5.38462 8.57853C5.46055 8.61004 5.54196 8.62626 5.62416 8.62626C5.70637 8.62626 5.78777 8.61004 5.86371 8.57853C5.93964 8.54702 6.00861 8.50084 6.06666 8.44264L9.99916 4.50889L13.9317 8.44264C13.9898 8.50075 14.0588 8.54685 14.1347 8.57829C14.2106 8.60974 14.292 8.62593 14.3742 8.62593C14.4563 8.62593 14.5377 8.60974 14.6136 8.57829C14.6896 8.54685 14.7586 8.50075 14.8167 8.44264C14.8748 8.38453 14.9209 8.31554 14.9523 8.23962C14.9838 8.1637 15 8.08232 15 8.00014C15 7.91796 14.9838 7.83659 14.9523 7.76066C14.9209 7.68474 14.8748 7.61575 14.8167 7.55764L10.4417 3.18264C10.3836 3.12444 10.3146 3.07826 10.2387 3.04675C10.1628 3.01524 10.0814 2.99902 9.99916 2.99902C9.91695 2.99902 9.83555 3.01524 9.75962 3.04675C9.68369 3.07826 9.61472 3.12444 9.55666 3.18264L5.18166 7.55764C5.12346 7.6157 5.07728 7.68467 5.04577 7.7606C5.01427 7.83653 4.99805 7.91793 4.99805 8.00014C4.99805 8.08235 5.01427 8.16375 5.04577 8.23968C5.07728 8.31561 5.12346 8.38458 5.18166 8.44264ZM5.18166 12.5576C5.23972 12.4994 5.30869 12.4533 5.38462 12.4218C5.46055 12.3902 5.54196 12.374 5.62416 12.374C5.70637 12.374 5.78777 12.3902 5.86371 12.4218C5.93964 12.4533 6.00861 12.4994 6.06666 12.5576L9.99916 16.4914L13.9317 12.5576C14.049 12.4403 14.2082 12.3744 14.3742 12.3744C14.5401 12.3744 14.6993 12.4403 14.8167 12.5576C14.934 12.675 15 12.8342 15 13.0001C15 13.1661 14.934 13.3253 14.8167 13.4426L10.4417 17.8176C10.3836 17.8758 10.3146 17.922 10.2387 17.9535C10.1628 17.985 10.0814 18.0013 9.99916 18.0013C9.91695 18.0013 9.83555 17.985 9.75962 17.9535C9.68369 17.922 9.61472 17.8758 9.55666 17.8176L5.18166 13.4426C5.12346 13.3846 5.07728 13.3156 5.04577 13.2397C5.01427 13.1638 4.99805 13.0823 4.99805 13.0001C4.99805 12.9179 5.01427 12.8365 5.04577 12.7606C5.07728 12.6847 5.12346 12.6157 5.18166 12.5576Z"
                  fill="#5F5F5F"
                />
              </svg>
            </div>
          </Popover.Button>
          <Popover.Panel
            as="div"
            className={classNames(
              "absolute left-0 top-[46px] z-10 w-[400px] rounded border border-gray-300 bg-white shadow-[0px_0px_15px_1px_rgba(0,0,0,0.10)]",
              scrollBarClass,
            )}
            style={{ maxHeight: `calc(100vh - 300px)` }}
          >
            <div className={classNames("flex flex-col divide-y divide-gray-300 px-4")}>
              {orgs.map((org, i) => (
                <div
                  key={`org-${org._id}-${i}`}
                  className={classNames("flex cursor-pointer items-center justify-between")}
                  onClick={() => {
                    mxEvent(MIXPANEL_EVENTS_V2.header.org_selection.clicked, {
                      targetId: org._id,
                      targetName: org.npo_name,
                    });
                    setOrg(org._id || "");
                  }}
                >
                  <OrgProfileCard
                    org={org}
                    customClass={selected === org._id ? "!w-[calc(100%-20px)]" : ""}
                  />
                  {selected === org._id && <i className="gi-md gi-check text-gray-700" />}
                </div>
              ))}
              {orgs.filter((o) => !o.completed).length === 0 && (
                <>
                  {loading ? (
                    <Spinner wrapperClass="p-3" size="sm" />
                  ) : (
                    <div
                      id="btn-new-org"
                      key={`org-new`}
                      className="cursor-pointer p-3"
                      onClick={() => {
                        handleNewOrg();
                        mxEvent(MIXPANEL_EVENTS_V2.header.org_selection.clicked, {
                          targetId: "new",
                        });
                      }}
                    >
                      <h6>Add another organization</h6>
                    </div>
                  )}
                </>
              )}
            </div>
          </Popover.Panel>
        </Popover>
      ) : null}
    </>
  );
}
export default OrganizationSelector;
