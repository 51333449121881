import { useEffect, useState } from "react";
interface LazyLoadState {
  ready: boolean;
}
//deprecated
export default function useLazyLoad(delay: number): LazyLoadState {
  const [ready, setReady] = useState<boolean>(true);
  // useEffect(() => {
  //   setTimeout(() => setReady(true), delay < 0 ? 0 : delay);
  // }, []);
  return { ready };
}
