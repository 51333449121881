import useSWR from "swr";
import axios from "axios";
import config from "../api";
import { swrOptionDedupling5mins } from "../types/swr";
interface ICPSearchContext {
  ein?: string;
  firstname?: string;
  text_query?: string;
  focus_area: string[];
  beneficiary: string[];
  program: string[];
}
interface ICPSearchContextState {
  data: ICPSearchContext | undefined;
  error: any;
  isLoading: boolean;
}
function useICPSearchContext(context_id?: string): ICPSearchContextState {
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<ICPSearchContext>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => res.data)
      .then((r) => ({
        ...r,
        focus_area: r.focus_area || [],
        beneficiary: r.beneficiary || [],
        program: r.program || [],
      }));
  };
  const { data, isLoading, error } = useSWR<ICPSearchContext>(
    context_id ? process.env.REACT_APP_API_URL + `/api/v2/users/context/${context_id}` : null,
    fetch,
    swrOptionDedupling5mins,
  );

  return {
    data: data,
    isLoading,
    error,
  };
}
export default useICPSearchContext;
