import useSWR, { useSWRConfig } from "swr";
import { FunderPastGrantGroup } from "../../types/funder";
import { getFunderPastRecipients } from "../../services/funders.services";
import { SearchQuery } from "../../types/search";
import { swrOptionDedupling5mins } from "../../types/swr";
import useCancellableSWR from "../useCancellableSWR";
import { useEffect, useState } from "react";
import { prettierTaxonomy } from "../../utils/philanthropy";
import { filterUnmatched, matchTaxonomyBySearchQueryWithPhilanthropy } from "../../types/taxonomy";
import { queryMaskForPastGrantOverview } from "../useGibooStructureSearch";
interface FunderPastRecipientState {
  data: FunderPastGrantGroup[];
  error: any;
  isLoading: boolean;
}
const emptyList: FunderPastGrantGroup[] = [];
function useFunderPastRecipients(
  query: SearchQuery,
  funder_id?: string,
  funder_uid?: number,
  limit = 100,
  clearLocation = true,
): FunderPastRecipientState {
  const fetch = async (_url: string, controller: AbortController) => {
    if (!_url || !funder_id || !funder_uid)
      return new Promise<FunderPastGrantGroup[]>((resolve, reject) => reject());
    const url = new URL(_url);
    const searchParams = url.searchParams;
    const queryValue = JSON.parse(searchParams.get("query") || "{}");
    return getFunderPastRecipients(
      queryValue as SearchQuery,
      funder_id,
      funder_uid,
      clearLocation,
      limit,
      controller,
    );
  };
  const { data, isLoading, error } = useCancellableSWR<FunderPastGrantGroup[]>(
    funder_uid
      ? process.env.REACT_APP_API_URL +
          `/api/v2/donors/${funder_uid}/recipients?limit=${limit}&query=${JSON.stringify({
            ...query,
            ...queryMaskForPastGrantOverview,
            ...(clearLocation
              ? {
                  hq_loc: [],
                  must_hq_loc: [],
                  exclude_hq_loc: [],
                  service_loc: [],
                  must_service_loc: [],
                  exclude_service_loc: [],
                }
              : {}),
            donor_id: funder_id,
            donor_uid: funder_uid,
            pagination_limit: limit,
            pagination_skip: 0,
          })}`
      : null,
    fetch,
    swrOptionDedupling5mins,
  );
  const setMatchedFunc = () => {
    const hasNoTaxonomy =
      query.focus_area.length === 0 &&
      query.beneficiary.length === 0 &&
      query.program.length === 0 &&
      query.hq_loc.length === 0 &&
      query.service_loc.length === 0;

    return data
      ? data.map((d) => ({
          ...d,
          ...prettierTaxonomy(
            hasNoTaxonomy
              ? matchTaxonomyBySearchQueryWithPhilanthropy(d, query, true, true)
              : filterUnmatched(matchTaxonomyBySearchQueryWithPhilanthropy(d, query, true, true)),
          ),
        }))
      : emptyList;
  };
  const [matched, setMatched] = useState<FunderPastGrantGroup[]>(setMatchedFunc());
  useEffect(() => {
    setMatched(setMatchedFunc());
  }, [query, data]);
  return {
    data: matched,
    isLoading,
    error,
  };
}
export default useFunderPastRecipients;
