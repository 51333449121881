import axios from "axios";
import { GrantSearchResult, SearchQuery } from "../../types/search";
import config from "../../api";
import { matchTaxonomyBySearchQuery } from "../../types/taxonomy";
import useSWR from "swr";
import { swrOptionDedupling5mins } from "../../types/swr";

interface GrantsFromSameFunderState {
  data: GrantSearchResult[] | undefined;
  isLoading: boolean;
  error: object;
}
export default function useGrantsFromSameFunder({
  use = false,
  query,
  grantType,
  keyId,
}: {
  use: boolean;
  query: SearchQuery;
  grantType?: string;
  keyId?: string;
}): GrantsFromSameFunderState {
  const fetchGrants = async (url: string) => {
    if (!url || !query) return new Promise<GrantSearchResult[]>((resolve) => resolve([]));
    return axios.get(url, config).then((res) =>
      res.data.map((r: any) => ({
        ...r,
        ...matchTaxonomyBySearchQuery(
          r,
          query,
          r.service_specific_loc,
          r.type === "grant-page" ? false : true,
        ),
        search_type: "grant",
      })),
    );
  };
  const useUloc =
    grantType && ["state", "county", "city", "federal"].includes(grantType) ? true : false;
  const { data, isLoading, error } = useSWR<GrantSearchResult[]>(
    use && keyId && grantType && query
      ? process.env.REACT_APP_API_URL +
          `/api/v2/grants/searchresult/${keyId}?grant_type=${
            useUloc ? grantType : "phil"
          }&query=${JSON.stringify(query)}`
      : null,
    fetchGrants,
    swrOptionDedupling5mins,
  );
  return { data, isLoading, error };
}
