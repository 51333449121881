import React, { useEffect, useState } from "react";
import useOnboardingData from "../../../../hooks/useOnboarding";
import PopupModal from "../../../../components/tailwind/PopupModal";
import TextArea from "../../../../components/tailwind/TextArea";
import TextInput from "../../../../components/tailwind/TextInput";
import Checkbox from "../../../../components/checkbox/Checkbox";
import MemoizedDropArea from "../../../../components/input/FileDropArea";
import { Onboarding, OnboardingData } from "../../../../app/onboarding";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectNPOInfo, updateOnboardingLocal } from "../../../../app/onboardingSlice";
import PlainDropdownSelector from "../../../../components/tailwind/dropdown/PlainDropdownSelector";
import {
  getStateNames,
  stateIdToStateName,
  stateNameToStateId,
  toUsdLong,
} from "../../../../utils/formatHelper";
import { uploadFileService } from "../../../../services/file-upload/upload.service";
import { updateOnboardingData } from "../../../../services/onboarding.services";
import Button from "../../../../components/tailwind/Button";
import { IOption } from "../../../../components/tailwind/AsyncCreatableSelector";
import { stageFilterOptions } from "../../../../types/search";
import DropdownSelector from "../../../../components/tailwind/dropdown/DropdownSelector";
import { getOrgDocKey } from "../../../../utils/media";
import FoldableBox from "../../../../components/FoldableBox";
import classNames from "classnames";
import Spacer from "../../../../components/Spacer";
import { Divider } from "rsuite";
import useScrollTo from "../../../../hooks/useScrollTo";
import useTaxonomyTagging from "../../../../hooks/useTaxonomyTagging";
import { TreeItem } from "../../../../components/TreeSelector";
import SearchableDropdown from "../../../../components/dropdown/SearchableDropdown";
import { scrollBarClass } from "../../../../utils/classes";
import Tag from "../../../../components/Tag";
import { allTaxonomyAutoComplete } from "../../../../utils/autoComplete";
import Spinner from "../../../../components/Spinner";
import GibooLocationInput from "../../../../components/GibooLocationInput";
import { ILocation, reprLocation } from "../../../../types/location";
import useLocationRecommendation from "../../../../hooks/useLocationRecommendation";
import useOrgDataForNPODashboard from "../../../../hooks/useOrgDataForNPODashboard";
import RenderIncrOrDecr from "../../../donor-detail-view/components/RenderIncrOrDecr";
import useNPOIRSProfile from "../../../../hooks/useNPOIRSProfile";
import UploadIRSModal from "../../../../components/UploadIRSModal";
import SingleSelector from "../../../../components/selector/SingleSelector";
import UploadIRS from "../../../../components/UploadIRS";
import { TooltipWrapper } from "../../../../components/TooltipWrapper";
import useNPOSummary from "../../../../hooks/useNPOSummary";
import { MIXPANEL_EVENTS_V2 } from "../../../../mixpanel/mixpanel";
import { useLocation } from "react-router-dom";
import useGibooMixpanel from "../../../../hooks/useGibooMixpanel";

export const FORMAT_INCORPORATED_STATUS = {
  nonprofit: "Nonprofit Corporation",
  // not_incorporated: "Not Incorporated",
  // corporation: "Corporation",
  // benefit_corporation: "Benefit Corporation",
  // cooperative: "Cooperative",
  // s_corporation: "S Corporation",
  // llc: "Limited Liability company",
  // others: "Others",
  corporation: "Corporation",
  trust: "Trust",
  cooperative: "Co-operative",
  partnership: "Partnership",
  association: "Association",
};
const incorporatedStatusOptions: IOption[] = Object.entries(FORMAT_INCORPORATED_STATUS).map(
  ([value, label]) => ({
    value,
    label,
  }),
);
export const FORMAT_ORGANIZATION_TYPE = {
  funding_intermediary: "Funding intermediary",
  // business: "Corporation and business",
  corporate: "Corporate foundation",
  community: "Community foundation",
  family: "Family foundation",
  private: "Private foundation",
  private_operating: "Private operating foundation",
  public: "Public foundation",
  daf: "Donor-advised fund (DAF)",
  federated_giving: "Federated giving program",
  service_provider: "Direct service provider",
  field_builder: "Field builder",
  hybrid: "Hybrid nonprofit",
};
const organizationTypeOptions: IOption[] = Object.entries(FORMAT_ORGANIZATION_TYPE).map(
  ([value, label]) => ({
    value,
    label,
  }),
);
export type OrgInfoNavType =
  | "ORGANIZATION_INFORMATION"
  | "ADDRESS_WEBSITE"
  | "ORGANIZATION_GROWTH_AND_SIZE"
  | "MY_ORGANIZATION_TAGS"
  | "ABOUT_ORGANIZATION";
interface EditBasicInfo {
  id: string;
  allowEdit?: boolean;
  showEdit: boolean;
  onClose: () => void;
  onboardingData: OnboardingData;
  showContent?: OrgInfoNavType[];
}
export const FORMAT_TAX_EXEMPT = {
  yes: "501(c)(3) - Charitable organizations",
  sponsorship: "Fiscal sponsored",
  applied: "Tax-exempt status pending",
  no: "Without tax exemption",
  "501c4": "501(c)(4) - Social welfare organizations",
  "501c6": "501(c)(6) - Business leagues, Chambers of commerce",
  "501c7": "501(c)(7) - Social and recreational clubs",
  "501c19": "501(c)(19) - Veterans' organizations",
  others: "Others",
};
const taxExemptOptions: IOption[] = Object.entries(FORMAT_TAX_EXEMPT).map(([value, label]) => ({
  value,
  label,
}));
export const FORMAT_STAGE: any = {
  dreamer: "Individual",
  seed: "Pre-seed & Seed",
  startup: "Start up",
  buildup: "Buildup",
  superstar: "Superstar",
  hyperstar: "Hyperstar",
};

const SIDE_MENU_CONSTANT = [
  {
    title: "Organization information",
    value: "ORGANIZATION_INFORMATION",
    subMenu: [
      { title: "Organization name", value: "ORGANIZATION_NAME" },
      { title: "Organization type", value: "ORGANIZATION_TYPE" },
      // { title: "Organization ID", value: "INCORPORATED_YEAR" },
      { title: "Incorporated status", value: "INCORPORATED_STATUS" },
      { title: "Incorporated year", value: "INCORPORATED_YEAR" },
      { title: "Tax exempt status", value: "TAX_EXEMPT_STATUS" },
    ],
  },
  {
    title: "Address and website",
    value: "ADDRESS_WEBSITE",
    subMenu: [
      { title: "Address", value: "ADDRESS" },
      { title: "Website", value: "WEBSITE" },
    ],
  },
  {
    title: "Organization growth and size",
    value: "ORGANIZATION_GROWTH_AND_SIZE",
    subMenu: [
      { title: "Organizational stage", value: "ORGANIZATIONAL_STAGE" },
      { title: "Number of employees", value: "NUMBER_OF_EMPLOYEES" },
      { title: "Number of grants received", value: "NUMBER_OF_GRANTS_RECEIVED" },
      { title: "Range of received funding", value: "RANGE_OF_RECEIVED_FUNDING" },
      // { title: "Total asset", value: "TOTAL_ASSET" },
      { title: "Total contributions", value: "TOTAL_CONTRIBUTION" },
    ],
  },
  {
    title: "My organization tags",
    value: "MY_ORGANIZATION_TAGS",
    subMenu: [
      { title: "Philanthropy focus", value: "PHILANTHROPY_FOCUS" },
      { title: "Service areas", value: "SERVICE_AREAS" },
    ],
  },
  {
    title: "Philanthropic focus",
    value: "ABOUT_ORGANIZATION",
    subMenu: [
      // { title: "About organization", value: "ABOUTORG" },
      { title: "Focus area", value: "FOCUSAREA" },
      { title: "Beneficiary", value: "BENEFICIARY" },
      { title: "Program", value: "PROGRAM" },
      { title: "Mission statement", value: "MISSION" },
    ],
  },
];
const initialValue = {
  user_id: "",
  _id: "",
  completed: false,
  type: 0,
  step: 0,
  focus_area: [],
  beneficiary: [],
  program: [],
  service_loc: [],
  roles: [],
  address1: "",
  address2: "",
  city: "",
  country: "",
  state_id: "",
  zipcode: "",
  website: "",
  updated_at: "",
  created_at: "",
  joined: "",
  role: 0,
  incorporated_year: 0,
  about: "",
  summary: "",
  phone: "",
  email: "",
  mission: "",
  activity: "",
  npo_name: "",
  npo_ein: "",
  npo_address: "",
  npo_city: "",
  operating_budget: 0,
  n_employee: 0,
  n_volunteer: 0,
  npo_type: "",
  org_type: "",
  org_status: "",
  n_grants_received: 0,
  grant_amount_min: 0,
  grant_amount_max: 0,
};
const STATE_ID_OPTIONS = getStateNames().map((i) => ({ label: i, value: i }));
function EditBasicInfo({
  id,
  allowEdit,
  onClose,
  showEdit,
  onboardingData,
  showContent = [],
}: EditBasicInfo) {
  const mxEvent = useGibooMixpanel();
  const appState = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [basicInfo, setBasicInfo] = useState<OnboardingData>(initialValue);
  const [orgStageOption, setOrgStageOption] = useState<IOption>();
  const [taxExemption, setTaxExemption] = useState<IOption>();
  const [orgTypeOption, setOrgTypeOption] = useState<IOption>();
  const [orgStatusOption, setOrgStatusOption] = useState<IOption>();
  const [stateIdOption, setStateIdOption] = useState<IOption>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [openLoc, setOpenLoc] = useState<boolean>(false);
  const { scrollTo } = useScrollTo("org-edit-scroll-container", -100);
  const [selectedItem, setSelectedItem] = useState<string>("INCORPORATED_YEAR");
  const { data: orgData, npo_id, org_id } = useOrgDataForNPODashboard(onboardingData._id, true);
  const { data: summary, isLoading: isLoadingSummary, error } = useNPOSummary(npo_id);
  const loc = useLocation();
  const { revenueStreamInfo, assets } = useNPOIRSProfile(npo_id);
  //taxonomy
  //
  const {
    isLoading: isRecommending,
    focus_area: focusAreaByMission,
    beneficiary: beneficiaryByMission,
    program: programByMission,
  } = useTaxonomyTagging(basicInfo.mission || "", "all", 20);
  const { location: head_location } = useAppSelector((state) => selectNPOInfo(state));
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [recommended, setRecommended] = useState<TreeItem[]>([]);
  const [focusArea, setFocusArea] = useState<string[]>(basicInfo.focus_area || []);
  const [beneficiary, setBeneficiary] = useState<string[]>(basicInfo.beneficiary || []);
  const [program, setProgram] = useState<string[]>(basicInfo.program || []);
  //location
  const [location, setLocation] = useState<ILocation[]>(basicInfo.service_loc || []);

  //about org
  const [updateAbout, setUpdateAbout] = useState<string>(
    orgData?.summary || orgData?.about || summary?.mission_and_vision || "",
  );
  const [updateMission, setUpdateMission] = useState<string>(orgData?.mission || "");
  const [focusAreaText, setFocusAreaText] = useState<string>(
    orgData?.focus_area_text || summary?.philanthropic_focuses || "",
  );
  const [beneficiaryText, setBeneficiaryText] = useState<string>(
    orgData?.beneficiary_text || summary?.beneficiaries || "",
  );
  const [programText, setprogramText] = useState<string>(
    orgData?.strategy_text || summary?.programs || "",
  );
  const {
    recommended: recommendedLoc,
    addCenter,
    isLoading: isLocRecommending,
  } = useLocationRecommendation(
    location,
    [
      ...(onboardingData.service_loc || []),
      ...(onboardingData.service_loc.length === 0 && head_location ? [head_location] : []),
    ],
    onboardingData.country,
    true,
  );
  useEffect(() => {
    setUpdateAbout(orgData?.summary || orgData?.about || summary?.mission_and_vision || "");
    setFocusAreaText(orgData?.focus_area_text || summary?.philanthropic_focuses || "");
    setBeneficiaryText(orgData?.beneficiary_text || summary?.beneficiaries || "");
    setprogramText(orgData?.strategy_text || summary?.programs || "");
    setUpdateMission(orgData?.mission || "");
  }, [orgData, summary]);

  useEffect(() => {
    setBasicInfo(onboardingData);
    setOrgStageOption(stageFilterOptions.find((i) => i.value === onboardingData?.stage));
    setOrgTypeOption(organizationTypeOptions.find((i) => i.value === onboardingData?.org_type));
    setOrgStatusOption(
      incorporatedStatusOptions.find((i) => i.value === onboardingData?.org_status),
    );
    setStateIdOption(
      STATE_ID_OPTIONS.find((i) => i.value === stateIdToStateName(onboardingData?.state_id)),
    );
    setFocusArea(onboardingData.focus_area);
    setBeneficiary(onboardingData.beneficiary);
    setProgram(onboardingData.program);
    setLocation(onboardingData.service_loc);
    setTaxExemption(taxExemptOptions.find((i) => i.value === onboardingData?.tax_exemption));
  }, [onboardingData]);

  useEffect(() => {
    const lower = {
      focusArea: focusArea.map((i) => i.toLowerCase()),
      beneficiary: beneficiary.map((i) => i.toLowerCase()),
      program: program.map((i) => i.toLowerCase()),
    };
    setRecommended(
      [
        ...focusAreaByMission.filter((i) => !lower.focusArea.includes(i.label.toLowerCase())),
        ...beneficiaryByMission.filter((i) => !lower.beneficiary.includes(i.label.toLowerCase())),
        ...programByMission.filter((i) => !lower.program.includes(i.label.toLowerCase())),
      ].sort((a, b) => (b.score || 0) - (a.score || 0)),
    );
  }, [
    focusAreaByMission,
    beneficiaryByMission,
    programByMission,
    focusArea,
    beneficiary,
    program,
    setRecommended,
  ]);
  const renderRow = (content: JSX.Element) => {
    return <div className="flex h-fit flex-col md:flex-row md:space-x-2 md:p-0">{content}</div>;
  };

  const handleSave = () => {
    const updatedData = {
      // npo_ein_requested: basicInfo.npo_ein,
      npo_name_requested: basicInfo.npo_name,
      website: basicInfo.website,
      stage_requested: orgStageOption?.value,
      address1: basicInfo.address1,
      address2: basicInfo.address2,
      city: basicInfo.city,
      country: basicInfo.country,
      state_id: basicInfo.state_id,
      zipcode: basicInfo.zipcode,
      tax_exemption_requested: taxExemption?.value,
      activity: basicInfo.activity,
      incorporated_year: basicInfo.incorporated_year,
      email: basicInfo.email,
      phone: basicInfo.phone,
      org_type: orgTypeOption?.value,
      org_status: orgStatusOption?.value,
      focus_area_requested: focusArea,
      beneficiary_requested: beneficiary,
      program_requested: program,
      service_loc_requested: location,
      n_employee_requested: basicInfo.n_employee,
      n_grants_received: basicInfo.n_grants_received,
      grant_amount_max: basicInfo.grant_amount_max,
      grant_amount_min: basicInfo.grant_amount_min,
      summary: updateAbout,
      mission_requested: updateMission,
      focus_area_text: focusAreaText,
      beneficiary_text: beneficiaryText,
      strategy_text: programText,
    } as Onboarding;
    updateData(updatedData);
  };

  const updateData = (data: Onboarding) => {
    setIsUpdating(true);
    updateOnboardingData({
      data,
      id: appState.onboarding.data._id,
    })
      ?.then(() => {
        dispatch(updateOnboardingLocal(data));
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          onClose();
          setIsUpdating(false);
        }, 1000);
      });
  };
  const renderAboutOrganization = () => {
    return (
      <>
        {/* <div className="my-10 h-[1px] w-full bg-gray-300" /> */}
        <div className="flex flex-col gap-10">
          <div id="ORG_ABOUTORG">
            <h4 className="mb-4 font-poppins text-xl font-semibold text-gray-900">
              Philanthropic focus
            </h4>
            <TextArea
              id="about-organization"
              value={updateAbout}
              placeholder="About organization"
              noOfRows={15}
              className=" !text-gray-500"
              handleOnChange={(e) => {
                setUpdateAbout(e.target.value);
              }}
            />
          </div>

          <div id="ORG_FOCUSAREA">
            <div className="mb-5  border-b border-gray-300">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">
                Focus area
              </h5>
            </div>
            <TextArea
              id="focus_area_text"
              value={focusAreaText}
              placeholder="E.g: We aim to address the limited exposure and opportunities available to artists of Korean heritage in the contemporary art world of the United States."
              noOfRows={10}
              className="mt-2 !text-gray-500"
              handleOnChange={(e) => {
                setFocusAreaText(e.target.value);
              }}
            />
          </div>
          <div id="ORG_BENEFICIARY">
            <div className="mb-5  border-b border-gray-300">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">
                Beneficiary
              </h5>
            </div>
            <TextArea
              id="beneficiary_text"
              value={beneficiaryText}
              placeholder={
                "E.g: The primary beneficiaries are talented Korean and Korean-American artists active in the United States."
              }
              noOfRows={10}
              className="mt-2 !text-gray-500"
              handleOnChange={(e) => {
                setBeneficiaryText(e.target.value);
              }}
            />
          </div>
          <div id="ORG_PROGRAM">
            <div className="mb-5  border-b border-gray-300">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">Program</h5>
            </div>
            <TextArea
              id="strategy_text"
              value={programText}
              placeholder="E.g: Our strategies include promoting artists through exhibitions and public art showings; educating artists on the business aspects of art;"
              noOfRows={10}
              className="mt-2 !text-gray-500"
              handleOnChange={(e) => {
                setprogramText(e.target.value);
              }}
            />
          </div>
          <div id="ORG_MISSION">
            <div className="mb-5  border-b border-gray-300">
              <h5 className="text-base font-semibold underline underline-offset-[6px]">
                Mission statement
              </h5>
            </div>
            <TextArea
              id="about-mission"
              value={updateMission}
              placeholder="Mission statement"
              noOfRows={10}
              className="mt-2 !text-gray-500"
              handleOnChange={(e) => {
                setUpdateMission(e.target.value);
              }}
            />
          </div>
        </div>
      </>
    );
  };
  const renderOrgInformation = () => (
    <>
      <div className="flex max-w-[480px] flex-col gap-6">
        <h6 className="text-base font-semibold text-purple-500">Organization information</h6>
        <div className="h-[74px] w-full">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
            Organization name
          </div>
          <div className="relative w-full" id="ORG_ORGANIZATION_NAME">
            <TextInput
              id="org-name"
              value={basicInfo.npo_name || ""}
              className="h-[45px]"
              placeholder="Enter organization name"
              handleOnChange={(e) => {
                setBasicInfo((prev) => ({ ...prev, npo_name: e.target.value }));
              }}
            />
          </div>
        </div>
        <div className="h-[74px] w-full">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
            Organization type
          </div>
          <div className="relative w-full" id="ORG_ORGANIZATION_TYPE">
            <DropdownSelector<IOption>
              id="stage"
              color="gray"
              inputClass="h-[45px] !w-[480px]"
              data={organizationTypeOptions}
              placeholder="Select organization type"
              selected={orgTypeOption}
              setSelected={setOrgTypeOption}
              maxHeight={"400px"}
              getKey={(i) => i.value}
              renderItem={(i) => i.label}
              isClearable={false}
              downIcon
              compact
            />
          </div>
        </div>
        {/* <div className="h-[74px] w-full" id="ORG_ORGANIZATION_ID">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
            Organization ID
          </div>
          <TextInput
            id="org-id"
            disabled
            value={basicInfo.npo_ein || ""}
            className="h-[45px]"
            placeholder="Enter organization ID"
            handleOnChange={(e) => {
              setBasicInfo((prev) => ({ ...prev, npo_ein: e.target.value }));
            }}
          />
        </div> */}
        <div className="h-[74px] w-full" id="ORG_INCORPORATED_STATUS">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
            Incorporated status
          </div>
          <div className="relative w-full">
            <DropdownSelector<IOption>
              id="stage"
              color="gray"
              inputClass="h-[45px] !w-[480px]"
              data={incorporatedStatusOptions}
              placeholder="Select incorporated status"
              selected={orgStatusOption}
              setSelected={setOrgStatusOption}
              maxHeight={"400px"}
              getKey={(i) => i.value}
              renderItem={(i) => i.label}
              isClearable={false}
              downIcon
              compact
            />
          </div>
        </div>
        <div className="h-[74px] w-full" id="ORG_INCORPORATED_YEAR">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
            Incorporated year
          </div>
          <TextInput
            id="org-id"
            value={
              basicInfo.incorporated_year !== null && basicInfo.incorporated_year !== undefined
                ? basicInfo.incorporated_year
                : orgData?.incorporated_year
            }
            className="h-[45px]"
            placeholder="Enter incorporated year"
            handleOnChange={(e) => {
              setBasicInfo((prev) => ({ ...prev, incorporated_year: Number(e.target.value) }));
            }}
          />
        </div>
        <div className="h-[74px] w-full" id="ORG_TAX_EXEMPT_STATUS">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
            Tax exempt status
          </div>
          <div className="relative w-full">
            <DropdownSelector<IOption>
              id="tax-exempt-status"
              color="gray"
              inputClass="h-[45px] !w-[480px]"
              data={taxExemptOptions}
              placeholder="Enter Tax exempt status"
              selected={taxExemption}
              setSelected={setTaxExemption}
              maxHeight={"400px"}
              getKey={(i) => i.value}
              renderItem={(i) => i.label}
              isClearable={false}
              downIcon
              compact
            />
          </div>
        </div>
      </div>
    </>
  );
  const renderAddressWebsite = () => (
    <>
      <div className="flex  max-w-[480px] flex-col gap-6">
        <h6 className="text-base font-semibold text-purple-500">Address and website</h6>
        <div className="flex  w-full flex-col gap-2" id="ORG_ADDRESS">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">Address</div>
          <TextInput
            id="address1"
            value={basicInfo.address1 || ""}
            className="h-[45px]"
            placeholder="Address line 1"
            handleOnChange={(e) => {
              setBasicInfo((prev) => ({ ...prev, address1: e.target.value }));
            }}
          />
          <TextInput
            id="address2"
            value={basicInfo.address2 || ""}
            className="h-[45px]"
            placeholder="Address line 2"
            handleOnChange={(e) => {
              setBasicInfo((prev) => ({ ...prev, address2: e.target.value }));
            }}
          />
          {renderRow(
            <>
              <TextInput
                id="city"
                className="h-[43px]"
                value={basicInfo.city || ""}
                placeholder="City"
                handleOnChange={(e) => {
                  setBasicInfo((prev) => ({ ...prev, city: e.target.value }));
                }}
              />
              <div className="relative h-fit w-full">
                <SingleSelector
                  id={`input-state-selector`}
                  value={stateIdOption}
                  inputContainerClass="h-[45px] w-[236px]"
                  onChange={(v) => {
                    setStateIdOption(v ? v : undefined);
                    v &&
                      setBasicInfo((prev) => ({ ...prev, state_id: stateNameToStateId(v.value) }));
                  }}
                  placeholder="State"
                  compact
                  data={STATE_ID_OPTIONS}
                  isClearable={false}
                  dropdownIcon
                  isSearchable
                  // disabled={user.type === "user"}
                />
              </div>
            </>,
          )}
          {renderRow(
            <>
              <TextInput
                id="zipcode"
                className="h-[45px] w-[236px]"
                value={basicInfo.zipcode || ""}
                placeholder="Zip code"
                handleOnChange={(e) => {
                  const zipcode = Number(e.target.value);
                  setBasicInfo((prev) => ({ ...prev, zipcode: zipcode.toString() }));
                }}
              />
            </>,
          )}
        </div>
        <div className="relative  w-full" id="ORG_WEBSITE">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">Website</div>
          <TextInput
            id="website"
            value={basicInfo.website || ""}
            className="h-[45px]"
            placeholder="Enter organization website"
            handleOnChange={(e) => {
              setBasicInfo((prev) => ({ ...prev, website: e.target.value }));
            }}
          />
        </div>
      </div>
    </>
  );
  const renderOrgGrowthAndSize = () => (
    <>
      <div className="my-10 h-[1px] w-full bg-gray-300" />
      <div className="flex  max-w-[480px] flex-col gap-6">
        <h6 className="text-base font-semibold text-purple-500">Organization growth and size</h6>

        <div className="h-[74px] w-full" id="ORG_ORGANIZATIONAL_STAGE">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
            Organization stage
          </div>
          <div className="relative w-full">
            <DropdownSelector<IOption>
              id="stage"
              color="gray"
              inputClass="h-[45px] !w-[480px]"
              data={stageFilterOptions}
              placeholder="Select organizational stage"
              selected={orgStageOption}
              setSelected={setOrgStageOption}
              maxHeight={"400px"}
              getKey={(i) => i.value}
              renderItem={(i) => i.label}
              isClearable={false}
              downIcon
              compact
            />
          </div>
        </div>
        <div className="h-[74px] w-full" id="ORG_NUMBER_OF_EMPLOYEES">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
            Number of employees
          </div>
          <TextInput
            id="org-id"
            value={basicInfo.n_employee || ""}
            className="h-[45px]"
            placeholder="Enter number of employees"
            handleOnChange={(e) => {
              setBasicInfo((prev) => ({ ...prev, n_employee: Number(e.target.value) }));
            }}
          />
        </div>
        <div className="flex flex-col gap-6 rounded border border-gray-200 bg-gray-50 p-5">
          <div className="flex flex-col gap-4">
            <h6>
              To update the information below, please upload IRS990 form.
              <br /> Once uploaded, Giboo will verify the information and update to your
              organization profile.
            </h6>
            <Button
              id={"btn-upload"}
              className="self-start !pl-1 !pr-2"
              handleOnClick={() => setOpenUpload(true)}
              label="Upload file to edit information"
              prependIcon={<i className="gi gi-upload !m-0 !p-0" />}
              size="sm"
            />
            <Divider className="my-0" />
            <UploadIRS />
          </div>
          <div className="h-[74px] w-full" id="ORG_NUMBER_OF_GRANTS_RECEIVED">
            <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
              Number of grants received
            </div>
            <TextInput
              id="org-id"
              value={basicInfo.n_grants_received || ""}
              className="h-[45px]"
              placeholder="0"
              handleOnChange={(e) => {
                setBasicInfo((prev) => ({ ...prev, n_grants_received: Number(e.target.value) }));
              }}
              readonly
            />
          </div>
          <div className="h-[74px] w-full" id="ORG_RANGE_OF_RECEIVED_FUNDING">
            <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
              Range of received funding
            </div>
            <div className="flex items-center  gap-2">
              <TextInput
                id="org-id"
                value={basicInfo.grant_amount_min || ""}
                className="h-[45px] w-full"
                placeholder="$"
                handleOnChange={(e) => {
                  setBasicInfo((prev) => ({ ...prev, grant_amount_min: Number(e.target.value) }));
                }}
                readonly
              />
              <Divider className="w-[30px]" />
              <TextInput
                id="org-id"
                value={basicInfo.grant_amount_max || ""}
                className="h-[45px] w-full"
                placeholder="$"
                handleOnChange={(e) => {
                  setBasicInfo((prev) => ({ ...prev, grant_amount_max: Number(e.target.value) }));
                }}
                readonly
              />
            </div>
          </div>
          {renderHealthInfo()}
        </div>
        {/* <div className="h-[74px] w-full" id="ORG_TOTAL_ASSET">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">Total asset</div>
          <div className="flex items-center  gap-2">
            <TextInput
              id="org-id"
              value={0 || ""}
              className="h-[45px] w-full"
              placeholder="$"
              handleOnChange={(e) => {
                // setBasicInfo((prev) => ({ ...prev, total_asset: Number(e.target.value) }));
              }}
            />
            <p className="text-sm text-gray-500">in</p>
            <TextInput
              id="org-id"
              value={0 || ""}
              className="h-[45px] w-full"
              placeholder="Year"
              handleOnChange={(e) => {
                // setBasicInfo((prev) => ({ ...prev, total_asset_in: Number(e.target.value) }));
              }}
            />
          </div>
        </div> */}
        {/* <div className="h-[74px] w-full" id="ORG_TOTAL_CONTRIBUTION">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
            Total contributions received
          </div>
          <div className="flex items-center  gap-2">
            <TextInput
              id="org-id"
              value={0 || ""}
              className="h-[45px] w-full"
              placeholder="$"
              handleOnChange={(e) => {
                // setBasicInfo((prev) => ({ ...prev, total_contribution: Number(e.target.value) }));
              }}
            />
            <p className="text-sm text-gray-500">in</p>
            <TextInput
              id="org-id"
              value={0 || ""}
              className="h-[45px] w-full"
              placeholder="Year"
              handleOnChange={(e) => {
                // setBasicInfo((prev) => ({ ...prev, total_contribution_in: Number(e.target.value) }));
              }}
            />
          </div>
        </div> */}
      </div>
    </>
  );
  const renderTagsLegend = () => {
    return (
      <div className="inline-flex items-center gap-3">
        <div className="inline-flex items-center gap-2">
          <span className="h-3 w-3 bg-yellow-500"></span>
          <h6 className="font-poppins text-xs text-gray-800">Focus area</h6>
        </div>
        <div className="inline-flex items-center gap-2">
          <span className="h-3 w-3 bg-blue-500"></span>
          <h6 className="font-poppins text-xs text-gray-800">Beneficiary</h6>
        </div>
        <div className="inline-flex items-center gap-2">
          <span className="h-3 w-3 bg-green-500"></span>
          <h6 className="font-poppins text-xs text-gray-800">Program</h6>
        </div>
      </div>
    );
  };
  const renderMyOrgTags = () => (
    <>
      <div className="flex  max-w-[480px] flex-col gap-6">
        <h6 className="text-base font-semibold text-purple-500">My organization tags</h6>
        <div className="flex  w-full flex-col gap-2" id="ORG_PHILANTHROPY_FOCUS">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">
            Philanthropy focus
          </div>
          {renderTaxonomy()}
        </div>
        <div className="flex  w-full flex-col gap-2" id="ORG_SERVICE_AREAS">
          <div className="pb-2 font-poppins text-sm font-semibold text-gray-900">Service areas</div>
          {renderServiceArea()}
        </div>
      </div>
    </>
  );
  const renderRightContent = () => {
    const hasValue = showContent.length > 0;
    return (
      <>
        {hasValue ? (
          <>
            {showContent.includes("ORGANIZATION_INFORMATION") && renderOrgInformation()}
            {showContent.includes("ADDRESS_WEBSITE") && renderAddressWebsite()}
            {showContent.includes("ORGANIZATION_GROWTH_AND_SIZE") && renderOrgGrowthAndSize()}
            {showContent.includes("MY_ORGANIZATION_TAGS") && renderMyOrgTags()}
            {showContent.includes("ABOUT_ORGANIZATION") && renderAboutOrganization()}
          </>
        ) : (
          <>
            {renderOrgInformation()}
            {renderAddressWebsite()}
            {renderOrgGrowthAndSize()}
            {renderMyOrgTags()}
            {renderAboutOrganization()}
          </>
        )}
      </>
    );
  };
  const renderServiceArea = () => {
    return (
      <>
        <div className="relative flex w-full flex-col gap-4 rounded border border-gray-300 p-4">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <h5 className="font-semibold text-purple-500">{`Selected service areas (${location.length})`}</h5>
            </div>
          </div>
          <div
            className={classNames("flex flex-wrap gap-2 rounded border border-gray-300 p-4", {
              ["hidden"]: location.length === 0,
            })}
          >
            {location.map((l) => {
              return (
                <Tag
                  id={`btn-location-${reprLocation(l)}`}
                  key={reprLocation(l)}
                  name={reprLocation(l)}
                  color="orange"
                  matched
                  onDelete={() => {
                    setLocation([...location.filter((item) => item !== l)]);
                  }}
                  size="sm"
                  hover
                />
              );
            })}
          </div>
        </div>
        <div className="relative w-full rounded border border-gray-300 p-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <h5 className="font-semibold text-purple-500">
                {`Select from the following suggested service areas (${recommendedLoc.length})`}
                {isLocRecommending && <Spinner size="sm" span wrapperClass="ml-2" />}
              </h5>
              <h6 className="font-poppins text-xs text-gray-800">
                Please select the service areas that most accurately represent your organization. If
                the service areas you envision are not listed, you may search for additional service
                areas.
              </h6>
            </div>
            <div
              className={classNames(
                "flex max-h-[110px] flex-wrap gap-2 rounded border border-gray-300 p-4",
                scrollBarClass,
                { ["hidden"]: recommendedLoc.length === 0 },
              )}
            >
              {recommendedLoc.map((l) => (
                <Tag
                  id={`btn-location-${reprLocation(l)}`}
                  key={reprLocation(l)}
                  name={reprLocation(l)}
                  color="orange"
                  onClick={() => {
                    setLocation((prev) =>
                      prev.map((loc) => reprLocation(loc)).includes(reprLocation(l))
                        ? prev
                        : [...prev, l],
                    );
                    addCenter(l);
                  }}
                  onAdd={() => {
                    //
                  }}
                  size="sm"
                  hover
                />
              ))}
            </div>
            {openLoc ? (
              <GibooLocationInput
                wrapperClass="self-start w-full"
                searchFieldPosition="start"
                id="location"
                value={location}
                setValue={setLocation}
                onAdd={(loc) => addCenter(loc)}
                maxWidth="480px"
                inputBoxMaxWidth="480px"
                itemsMaxHeight="480px"
                itemsPosition="top"
                itemsAlign="start"
                gap={5}
                hideNone
                hideItems
                placeholder={"Search and add your service areas"}
                icon
                matched
                float
                tagSize="sm"
                isFullTagClick
                spinnerBackgroundColor="bg-gray-50"
              />
            ) : (
              <div
                className="flex w-fit cursor-pointer items-center gap-3 rounded border border-gray-300 px-2 py-[2px] hover:border-gray-500 hover:bg-gray-100"
                onClick={() => {
                  setOpenLoc(true);
                }}
              >
                <i className="gi-search !text-xs text-gray-600" />
                <h5 className="text-gray-700">Add your own</h5>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };
  const renderTaxonomy = () => {
    return (
      <>
        <div className="relative flex w-full flex-col gap-4 rounded border border-gray-300 p-4">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <h5 className="font-semibold text-purple-500">{`Selected tags (${
                focusArea.length + beneficiary.length + program.length
              })`}</h5>
              {renderTagsLegend()}
            </div>
          </div>
          <div
            className={classNames("flex flex-wrap gap-2 rounded border border-gray-300 p-4", {
              ["hidden"]:
                focusArea.length === 0 && beneficiary.length === 0 && program.length === 0,
            })}
          >
            {focusArea.map((t) => {
              const key = `f_${t}`;
              return (
                <Tag
                  key={key}
                  id={key}
                  name={t}
                  color="yellow"
                  size="sm"
                  isFullTagClick
                  textTruncate={false}
                  matched
                  onDelete={() => {
                    setFocusArea((prev) => prev.filter((p) => p.toLowerCase() !== t.toLowerCase()));
                  }}
                />
              );
            })}
            {beneficiary.map((t) => {
              const key = `b_${t}`;
              return (
                <Tag
                  key={key}
                  id={key}
                  name={t}
                  color="blue"
                  size="sm"
                  isFullTagClick
                  textTruncate={false}
                  matched
                  onDelete={() => {
                    setBeneficiary((prev) =>
                      prev.filter((p) => p.toLowerCase() !== t.toLowerCase()),
                    );
                  }}
                />
              );
            })}
            {program.map((t) => {
              const key = `p_${t}`;
              return (
                <Tag
                  key={key}
                  id={key}
                  name={t}
                  color="green"
                  size="sm"
                  isFullTagClick
                  textTruncate={false}
                  matched
                  onDelete={() => {
                    setProgram((prev) => prev.filter((p) => p.toLowerCase() !== t.toLowerCase()));
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="relative flex w-full flex-col gap-4 rounded border border-gray-300 p-4">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <h5 className="font-semibold text-purple-500">
                {`Select from the following suggested tags(${recommended.length})`}
                {isRecommending && <Spinner size="sm" span wrapperClass="ml-2" />}
              </h5>
              <h6 className="font-poppins text-xs text-gray-800">
                Please select the tags that most accurately represent your organization. <br />
                If the tags you envision are not listed, you may search for additional tags.
              </h6>
            </div>
            {renderTagsLegend()}
          </div>
          <div
            className={classNames(
              "flex max-h-[120px] max-w-[908px] flex-wrap gap-2 rounded border border-gray-300 p-4",
              scrollBarClass,
              { ["hidden"]: recommended.length === 0 },
            )}
          >
            {recommended.map((t) => {
              const key = `${t.type}_${t.label}`;
              return (
                <Tag
                  key={key}
                  id={key}
                  name={t.label}
                  color={
                    t.type === "f"
                      ? "yellow"
                      : t.type === "b"
                      ? "blue"
                      : t.type === "p"
                      ? "green"
                      : "gray"
                  }
                  size="sm"
                  textTruncate={false}
                  isFullTagClick
                  onAdd={() => {
                    if (t.type === "f")
                      setFocusArea((prev) => [
                        ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                        t.label,
                      ]);
                    else if (t.type === "b")
                      setBeneficiary((prev) => [
                        ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                        t.label,
                      ]);
                    else if (t.type === "p")
                      setProgram((prev) => [
                        ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                        t.label,
                      ]);
                  }}
                />
              );
            })}
          </div>
          {open ? (
            <SearchableDropdown<TreeItem>
              id={"input-add-your-own"}
              getColor={(item: TreeItem) =>
                item.type === "f"
                  ? "yellow"
                  : item.type === "b"
                  ? "blue"
                  : item.type === "p"
                  ? "green"
                  : "gray"
              }
              query={query}
              setQuery={setQuery}
              autoCompleteFunction={allTaxonomyAutoComplete}
              autoCompleteThrottleMilliseconds={200}
              addItem={(t) => {
                if (t.type === "f")
                  setFocusArea((prev) => [
                    ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                    t.label,
                  ]);
                else if (t.type === "b")
                  setBeneficiary((prev) => [
                    ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                    t.label,
                  ]);
                else if (t.type === "p")
                  setProgram((prev) => [
                    ...prev.filter((p) => p.toLowerCase() !== t.label.toLowerCase()),
                    t.label,
                  ]);
              }}
              // createItem={(str) => ({
              //   type: "",
              //   label: str,
              //   parent: "",
              //   object_id: "",
              // })}
              renderItem={(item) => item.label}
              getKey={(item) => `${item.type}_${item.label.toLowerCase()}`}
              excludeKeys={[
                ...focusArea.map((t) => `f_${t.toLowerCase()}`),
                ...beneficiary.map((t) => `b_${t.toLowerCase()}`),
                ...program.map((t) => `p_${t.toLowerCase()}`),
              ]}
              placeholder={"Add tags from our classification dictionary"}
              float
              maxWidth="480px"
              maxHeight="400px"
              icon={true}
              spinner
              spinnerBackgroundColor="bg-gray-50"
            />
          ) : (
            <div
              className="flex w-fit cursor-pointer items-center gap-3 rounded border border-gray-300 px-2 py-[2px] hover:border-gray-500 hover:bg-gray-100"
              onClick={() => {
                setOpen(true);
              }}
            >
              <i className="gi-search !text-xs text-gray-600" />
              <h5 className="text-gray-700">Add your own</h5>
            </div>
          )}
        </div>
      </>
    );
  };
  const organizationEditContent2 = () => {
    return (
      <div className="grid  h-[618px] grid-cols-[232px_1fr]">
        {/* Left content */}
        <div className="flex h-full w-full flex-col gap-6 bg-gray-200 px-4 py-5">
          {(showContent.length > 0
            ? SIDE_MENU_CONSTANT.filter((i) => showContent.includes(i.value as any))
            : SIDE_MENU_CONSTANT
          ).map((item, index) => {
            const isHeaderSelected = item.subMenu.find((i) => i.value === selectedItem);
            return (
              <FoldableBox
                key={item.value}
                id={item.value}
                title={item.title}
                className="!py-0"
                arrowIconClass={classNames(isHeaderSelected && "text-purple-500")}
                titleClass={classNames(
                  "text-xs whitespace-nowrap",
                  isHeaderSelected && "text-purple-500  ",
                )}
                extraButton
                globalOpen
              >
                <div className="flex w-full flex-col gap-3 border-l border-gray-300 ">
                  {item.subMenu.map((item) => {
                    const isSelected = item.value === selectedItem;
                    return (
                      <div
                        key={item.value}
                        onClick={() => {
                          setSelectedItem(item.value);
                          scrollTo(`ORG_${item.value}`);
                        }}
                        className={classNames(
                          "cursor-pointer pl-[17px] text-xs",
                          isSelected
                            ? "border-l border-purple-500 text-purple-500 underline underline-offset-2"
                            : "text-gray-700",
                        )}
                      >
                        {item.title}
                      </div>
                    );
                  })}
                </div>
              </FoldableBox>
            );
          })}
        </div>
        {/* Right content */}

        <div
          id="org-edit-scroll-container"
          className="h-full w-full overflow-y-scroll bg-white px-[60px] py-[40px]"
        >
          {renderRightContent()}
        </div>
      </div>
    );
  };
  const renderHealthInfo = () => {
    const latestYearData = assets && assets.length > 0 ? assets[assets.length - 1] : undefined;
    return (
      <div className={classNames(" mt-3 flex flex-col gap-2")}>
        <div className="font-poppins text-sm font-semibold text-gray-900">Total asset</div>
        <div
          className={classNames(
            "font-poppings flex w-full max-w-[372px] flex-col gap-[4px] rounded border border-gray-300  bg-white p-4",
          )}
        >
          <h5 className="text-sm text-gray-700">Total asset in {revenueStreamInfo?.tax_year}</h5>
          <h3>{toUsdLong(latestYearData?.assets_total || 0)}</h3>
          <RenderIncrOrDecr value={latestYearData?.diff_assets_total || 0} />
        </div>
        <div
          className="pt-3 font-poppins text-sm font-semibold text-gray-900"
          id="ORG_TOTAL_CONTRIBUTION"
        >
          Total contributions
        </div>
        <div
          className={classNames(
            "font-poppings flex w-full max-w-[372px] flex-col gap-[4px] rounded border border-gray-300 bg-white p-4",
          )}
        >
          <h5 className=" text-sm text-gray-700">
            Total contributions in {revenueStreamInfo?.tax_year}
          </h5>
          <h3>{toUsdLong(revenueStreamInfo?.contributions || 0)}</h3>
          <RenderIncrOrDecr value={revenueStreamInfo?.diff_contributions || 0} />
        </div>
      </div>
    );
  };
  return (
    <>
      {showEdit && (
        <PopupModal
          isOpen={showEdit}
          handleClose={() => {
            onClose();
          }}
          wrapperClass="max-w-[959px] md:!px-0 "
          contentClass="overflow-y-scroll h-fit"
          RightActionButton={() => (
            <TooltipWrapper
              content="After editing this information, the revised details will be displayed on the public page of your organization. Please ensure all information is accurately reviewed."
              textClass="max-w-[248px]"
              WrapperClass="!p-4"
              placements="right"
            >
              <div>
                <Button
                  id="save-btn"
                  loading={isUpdating}
                  className="!w-fit"
                  handleOnClick={() => {
                    //mixpanel
                    mxEvent(MIXPANEL_EVENTS_V2.org_profile.information.edit, {
                      // TODO
                    });
                    handleSave();
                  }}
                  label="Save"
                />
              </div>
            </TooltipWrapper>
          )}
          isLoading={isUpdating}
          title="Edit information"
        >
          <>{organizationEditContent2()}</>
        </PopupModal>
      )}
      {openUpload && org_id && <UploadIRSModal open={openUpload} setOpen={setOpenUpload} />}
    </>
  );
}

EditBasicInfo.propTypes = {};

export default EditBasicInfo;
