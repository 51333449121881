import { UnifiedSearchResult } from "../../types/search";
import useSavedItems from "./useSavedItems";
import { SaveItem } from "../../types/save";
import useFunderSearchResults from "../search/useFunderSearchResults";
import useGrantSearchResults from "../search/useGrantSearchResults";
import { useEffect, useState } from "react";
import useNPOSearchResults from "../search/useNPOSearchResults";
interface SavedItemsState {
  saved: SaveItem[];
  data: UnifiedSearchResult[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<SaveItem[] | undefined>;
}
const emptyList2: SaveItem[] = [];
const emptyList: UnifiedSearchResult[] = [];
function useSavedDetailedItems(id?: string, limit = 20, skip = 0): SavedItemsState {
  const [total, setTotal] = useState<{ items: UnifiedSearchResult[]; loading: boolean }>({
    items: emptyList,
    loading: false,
  });
  const [delayedLoading, setDelayedLoading] = useState<boolean>(false);
  const { data, isLoading, error, isValidating, revalidate } = useSavedItems(id, limit, skip);
  const {
    data: funders,
    isLoading: isLoadingFunders,
    error: errorFunders,
  } = useFunderSearchResults(data.filter((d) => d.target_type === 0).map((d) => d.target_id));
  const {
    data: grants,
    isLoading: isLoadingGrants,
    error: errorGrants,
  } = useGrantSearchResults(data.filter((d) => d.target_type === 1).map((d) => d.target_id));
  const {
    data: npos,
    isLoading: isLoadingNPOs,
    error: errorNPOs,
  } = useNPOSearchResults(data.filter((d) => d.target_type === 3).map((d) => d.target_id));
  useEffect(() => {
    if (isLoading || isLoadingGrants || isLoadingFunders) return;
    setTotal({ items: emptyList, loading: true });
    setTotal({
      items: data
        .map((d) =>
          d.target_type === 0
            ? funders.find((t) => t._id === d.target_id)
            : d.target_type === 3
            ? npos.find((t) => t._id === d.target_id)
            : grants.find((t) => t._id === d.target_id),
        )
        .filter(Boolean) as UnifiedSearchResult[],
      loading: false,
    });
  }, [
    data,
    funders,
    npos,
    grants,
    isLoading,
    isLoadingNPOs,
    isLoadingFunders,
    isLoadingGrants,
    setTotal,
  ]);
  useEffect(() => {
    if (isLoading || isLoadingFunders || isLoadingNPOs || isLoadingGrants) setDelayedLoading(true);
    else {
      setTimeout(() => setDelayedLoading(false), 500);
    }
  }, [isLoading, isLoadingFunders, isLoadingNPOs, isLoadingGrants]);
  return {
    saved: data || emptyList2,
    data: total.items,
    isLoading:
      (delayedLoading && total.items.length === 0) ||
      isLoading ||
      isLoadingNPOs ||
      isLoadingFunders ||
      isLoadingGrants,
    error: error || errorFunders || errorNPOs || errorGrants,
    isValidating,
    revalidate,
  };
}
export default useSavedDetailedItems;
