import { useNavigate } from "react-router-dom";
import RoundButton from "./RoundButton";
import classNames from "classnames";

export default function BackButton({
  wrapper,
  onClick,
}: {
  wrapper?: string;
  onClick?: () => void;
}) {
  const navigate = useNavigate();
  const canGoBack = window.history.length > 1;
  if (!canGoBack) return <></>;
  return (
    <div className={classNames("w-fit", wrapper)}>
      <RoundButton
        id={`btn-back-item`}
        icon="gi-sm gi-left-arrow"
        type="primary"
        size="sm"
        handleOnClick={() => (onClick ? onClick() : navigate(-1))}
        tooltip="Back to previous page"
      />
    </div>
  );
}
