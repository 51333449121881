import classNames from "classnames";
import * as React from "react";

export interface TextAreaProps {
  id: string;
  className?: string;
  type?: string;
  iconClass?: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green";
  handleOnChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleOnFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleOnBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  handleOnPaste?: (e: React.ClipboardEvent<HTMLTextAreaElement>) => void;
  readonly?: boolean;
  placeholder?: string;
  value?: string | number;
  invalid?: boolean;
  disabled?: boolean;
  min?: number | undefined;
  max?: number | undefined;
  topPlaceholder?: string;
  defaultValue?: string;
  status?: "success" | "fail";
  wrapperClass?: string;
  noOfRows: number;
  topLabelClass?: string;
}

const TextArea = React.forwardRef(function textArea(
  props: TextAreaProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  return (
    <div className={classNames("w-full", props.wrapperClass)}>
      {props.topPlaceholder ? (
        <div
          className={classNames(
            "py-2 font-poppins text-base font-semibold text-gray-900",
            props.topLabelClass,
          )}
        >
          {props.topPlaceholder}
        </div>
      ) : null}
      <textarea
        ref={ref}
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        rows={props.noOfRows}
        style={{ resize: "none" }}
        maxLength={props.max}
        minLength={props.min}
        className={classNames(
          props.className,
          "flex w-full appearance-none rounded-[4px] border-[1px] border-gray-400 px-3  py-3 text-sm leading-tight text-gray-700 placeholder-gray-500 placeholder:text-sm focus:border-gray-400 focus:ring-0 focus:ring-gray-300 ",
          {
            "border-purple-500 bg-purple-50 text-gray-700": props.invalid,
            "pt-3.5": props.topPlaceholder,
          },
        )}
        onChange={props.handleOnChange}
        onFocus={props.handleOnFocus}
        onBlur={props.handleOnBlur}
        onKeyDown={props.handleOnKeyDown}
        onPaste={props.handleOnPaste}
        disabled={props.disabled}
        readOnly={props.readonly}
        defaultValue={props.defaultValue}
      />
    </div>
  );
});
TextArea.defaultProps = {
  noOfRows: 3,
};
export default TextArea;
