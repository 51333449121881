import { Dispatch, SetStateAction, useCallback } from "react";
import { toValidPositiveNumberOrZero } from "../../utils/formatHelper";
import LeftInnerIconNumberInput from "../LeftInnerIconNumberInput";

interface RangeInputProps {
  id: string;
  min: number;
  max: number;
  a: number | undefined;
  b: number | undefined;
  setA: Dispatch<SetStateAction<number | undefined>>;
  setB: Dispatch<SetStateAction<number | undefined>>;
  topPlaceholderA?: string;
  topPlaceholderB?: string;
  disabled?: boolean;
  iconClass?: string;
}
const RangeInput = ({
  id,
  min,
  max,
  a,
  b,
  setA,
  setB,
  topPlaceholderA,
  topPlaceholderB,
  iconClass,
  disabled,
}: RangeInputProps) => {
  const getValidNum = useCallback(
    (val: number) => {
      return Math.max(Math.min(val, max), min);
    },
    [min, max],
  );
  const onChangeA = (value: string, finalize: boolean) => {
    const validNum = getValidNum(+toValidPositiveNumberOrZero(value));
    if (finalize && (b === undefined || validNum > b)) {
      setB(+validNum);
    }
    setA(+validNum);
  };
  const onChangeB = (value: string, finalize: boolean) => {
    const validNum = getValidNum(+toValidPositiveNumberOrZero(value));
    if (finalize && a !== undefined && validNum < a) {
      setA(+validNum);
    }
    setB(+validNum);
  };
  return (
    <div className="flex items-center gap-3">
      <div className="grow">
        <LeftInnerIconNumberInput
          className="placeholder:!text-xs"
          id={`input-${id}-a`}
          value={a}
          setValue={setA}
          handleOnBlur={(e) => onChangeA(e.currentTarget.value, true)}
          handleOnKeyDown={(e) => {
            if (e.key == "Enter") onChangeA(e.currentTarget.value, true);
          }}
          handleOnChange={(e) => onChangeA(e.currentTarget.value, false)}
          topPlaceholder={topPlaceholderA}
          iconClass={iconClass}
          placeholder="Enter amount"
          min={min}
          max={max}
          disabled={disabled}
        />
      </div>
      <div className="input-range-bar"></div>
      <div className="grow">
        <LeftInnerIconNumberInput
          className="placeholder:!text-xs"
          id={`input-${id}-b`}
          value={b}
          setValue={setB}
          handleOnBlur={(e) => onChangeB(e.currentTarget.value, true)}
          handleOnKeyDown={(e) => {
            if (e.key == "Enter") onChangeB(e.currentTarget.value, true);
          }}
          handleOnChange={(e) => onChangeB(e.currentTarget.value, false)}
          topPlaceholder={topPlaceholderB}
          iconClass={iconClass}
          placeholder="Enter amount"
          min={min}
          max={max}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default RangeInput;
