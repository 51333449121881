import { useCallback, useState } from "react";
import {
  PastGrantOverview,
  PastGrantSearchResult,
  SearchQuery,
  SearchQueryNullable,
} from "../types/search";
import axios from "axios";
import config from "../api";
import useCancellableSWR from "./useCancellableSWR";
import { swrOptionDedupling5mins } from "../types/swr";
import { reprLocation } from "../types/location";
import { queryMaskForPastGrantSearch } from "./useGibooStructureSearch";

interface PastGrantSearchResponse {
  query_latency_sec: number;
  len_total: number;
  len_colbert: number;
  len_common: number;
  req: SearchQuery;
  res: { uid: number; conf: number }[];
  items: PastGrantSearchResult[];
  min_year: number;
  max_year: number;
  org_total: number;
  amount_total: number;
  amount_total_stage: number;
  org_total_stage: number;
}

interface PastGrantOverviewState {
  isPastGrantOverviewLoading: boolean;
  pastGrantOverview: PastGrantOverview | undefined;
  pastGrant: any[] | undefined;
  errorPastGrant: object;
}
function usePastGrantOverview(
  funder_uid?: number,
  query?: SearchQuery,
  hidden = [] as number[],
  stage?: string,
  detail = false,
  mask?: SearchQueryNullable,
  doNotSendLocationIfTaxonomyMatched = false,
): PastGrantOverviewState {
  // const fetchPastGrantSearchSize = useCallback(
  //   async (url: string, controller: AbortController) => {
  //     try {
  //       const req = JSON.parse(url.slice(19));
  //       return axios
  //         .post(
  //           process.env.REACT_APP_API_URL + `/api/v2/search/pastgrants/size`,
  //           {
  //             ...req,
  //             hidden_uids: hidden,
  //           },
  //           { ...config, ...{ signal: controller.signal } },
  //         )
  //         .then((res) => res.data.size);
  //     } catch {
  //       return new Promise<number>((resolve, reject) => reject());
  //     }
  //   },
  //   [hidden],
  // );
  // const fetchPastGrantSearchOverview = useCallback(
  //   async (url: string, controller: AbortController) => {
  //     try {
  //       const req = JSON.parse(url.slice(23));
  //       return axios
  //         .post(
  //           process.env.REACT_APP_API_URL + `/api/v2/search/pastgrants/overview`,
  //           {
  //             ...req,
  //             hidden_uids: hidden,
  //           },
  //           { ...config, ...{ signal: controller.signal } },
  //         )
  //         .then((res) => res.data);
  //     } catch {
  //       return new Promise<any>((resolve, reject) => reject());
  //     }
  //   },
  //   [hidden],
  // );
  const fetchPastGrantSearch = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(14));
        return axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/search/pastgrants`,
            {
              ...req,
              hidden_uids: hidden,
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => ({
            ...res.data,
            items: res.data.items.map((d: any) => ({
              ...d,
              search_type: "past_grant",
              service_specific_loc: true,
            })),
          }));
      } catch {
        return new Promise<PastGrantOverview>((resolve, reject) => reject());
      }
    },
    [hidden],
  );

  // const { data: pastGrantOverview, isLoading: isPastGrantOverviewLoading } =
  //   useCancellableSWR<PastGrantOverview>(
  //     !useOverview || !query || !funder_uid
  //       ? null
  //       : "gss_past_grant_overview" +
  //           JSON.stringify({
  //             ...query,
  //             ...queryMaskForPastGrantSearch,
  //             donor_uid: funder_uid,
  //             pagination_limit: undefined,
  //             pagination_skip: undefined,
  //             fetch_taxonomy: undefined,
  //             ...(stage ? { grantee_stage: [stage] } : {}),
  //           }),
  //     fetchPastGrantSearchOverview,
  //     swrOptionDedupling5mins,
  //   );
  // const { data: pastGrantSize, isLoading: isPastGrantSizeLoading } = useCancellableSWR<number>(
  //   !useSize || !query || !funder_uid
  //     ? null
  //     : "gss_past_grant_size" +
  //         JSON.stringify({
  //           ...query,
  //           ...queryMaskForPastGrantSearch,
  //           donor_uid: funder_uid,
  //           pagination_limit: undefined,
  //           pagination_skip: undefined,
  //           fetch_taxonomy: undefined,
  //           ...(stage ? { grantee_stage: [stage] } : {}),
  //         }),
  //   fetchPastGrantSearchSize,
  //   swrOptionDedupling5mins,
  // );

  const {
    data: pastGrant,
    isLoading: isPastGrantLoading,
    error: errorPastGrant,
  } = useCancellableSWR<PastGrantSearchResponse>(
    !query || !funder_uid
      ? null
      : "gss_past_grant" +
          JSON.stringify({
            ...query,
            ...(mask ? mask : {}),
            ...(doNotSendLocationIfTaxonomyMatched &&
            (query.focus_area.filter((t) => !query.exclude_focus_area.includes(t)).length > 0 ||
              query.beneficiary.filter((t) => !query.exclude_beneficiary.includes(t)).length > 0 ||
              query.program.filter((t) => !query.exclude_program.includes(t)).length > 0)
              ? { service_loc: [] }
              : doNotSendLocationIfTaxonomyMatched
              ? {
                  must_service_loc: query.service_loc.filter(
                    (l) =>
                      !query.exclude_service_loc
                        .map((q) => reprLocation(q))
                        .includes(reprLocation(l)),
                  ),
                }
              : {}),
            donor_uid: funder_uid,
            ...(stage ? { current_grantee_stage: stage } : {}),
            fetch_taxonomy: detail ? true : undefined,
            ...queryMaskForPastGrantSearch,
          }),
    fetchPastGrantSearch,
    swrOptionDedupling5mins,
  );
  return {
    isPastGrantOverviewLoading: isPastGrantLoading,
    pastGrantOverview: pastGrant,
    pastGrant: pastGrant?.items,
    errorPastGrant,
  };
}
export default usePastGrantOverview;
