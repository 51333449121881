import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import { useAppDispatch } from "../../app/store";
import { User } from "../../app/userSlice";
import { getOrgManualMembers, getOrgMembers } from "../../services/org.services";
import axios from "axios";
import config from "../../api";
import {
  IOrgManualMember,
  IOrgManualMemberCreateRequest,
  IOrgManualMemberUpdateRequest,
} from "../../types/org";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface OrgManualMemberState {
  data: IOrgManualMember[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<IOrgManualMember[] | undefined>;
  getOptimisticDataOnUpdate: (
    id: string | undefined,
    req: IOrgManualMemberUpdateRequest,
  ) => IOrgManualMember[] | undefined;
}
const getOrgManualMember = async (org_id: string, id: string) => {
  if (!id) return new Promise<IOrgManualMember>((resolve, reject) => reject());
  return axios
    .get(process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/orgmember/${id}`, config)
    .then((res) => res.data as IOrgManualMember);
};
const createOrgManualMember = async (org_id: string, req: IOrgManualMemberCreateRequest) => {
  return axios
    .post(process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/orgmember`, req, config)
    .then((res) => res.data as IOrgManualMember);
};
const updateOrgManualMember = async (
  org_id: string,
  id: string,
  req: IOrgManualMemberUpdateRequest,
) => {
  if (!id) return new Promise<IOrgManualMember>((resolve, reject) => reject());
  return axios
    .put(process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/orgmember/${id}`, req, config)
    .then((res) => res.data as IOrgManualMember);
};
const deleteOrgManualMember = async (org_id: string, id: string) => {
  if (!id) return new Promise<boolean>((resolve, reject) => reject());
  return axios
    .delete(process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/orgmember/${id}`, config)
    .then((res) => res.data as boolean);
};
export const ORG_MEMBER_DEFAULT_LIMIT = 1000;
const emptyList: IOrgManualMember[] = [];
function useOrgManualMembers(
  org_id?: string,
  limit = ORG_MEMBER_DEFAULT_LIMIT,
): OrgManualMemberState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/orgmembers?limit=${limit}`;
  const { mutate, cache } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url || !org_id) return new Promise<IOrgManualMember[]>((resolve, reject) => reject());
    return getOrgManualMembers(org_id, limit).then((res) =>
      res.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()),
    );
  };
  const { data, isLoading, error, isValidating } = useSWR<IOrgManualMember[]>(
    org_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<IOrgManualMember[]>(url), [url]);
  const getOptimisticDataOnUpdate = useCallback(
    (
      id: string | undefined,
      req: IOrgManualMemberUpdateRequest,
    ): IOrgManualMember[] | undefined => {
      if (!id || !url) return undefined;
      const c = cache.get(url);
      const data = c?.data as IOrgManualMember[] | undefined;
      if (!data) return undefined;
      const foundIndex = data.findIndex((m) => m._id === id);
      if (foundIndex < 0) return data;
      const next = [
        ...data.slice(0, foundIndex),
        {
          ...data[foundIndex],
          ...req,
        },
        ...data.slice(foundIndex + 1),
      ];
      console.log({ prev: data, next });
      return next;
    },
    [cache, url],
  );
  return {
    data: data || emptyList,
    isLoading,
    error,
    isValidating,
    revalidate,
    getOptimisticDataOnUpdate,
  };
}
export { getOrgManualMember, createOrgManualMember, updateOrgManualMember, deleteOrgManualMember };
export default useOrgManualMembers;
