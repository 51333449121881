import { Portal } from "@headlessui/react";
import mixpanel from "mixpanel-browser";
import { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactGA from "react-ga4";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchSearchHistoryAsync } from "./app/searchHistorySlice";
import { useAppDispatch } from "./app/store";
import useOrgID from "./hooks/useOrgID";
import useUser from "./hooks/useUser";
import "./index.scss";
import "./pages/css/box.scss";
import "./pages/css/icon-basic.scss";
import "./pages/css/icon.scss";
import "./pages/css/nav.scss";
import { FunderRoutes } from "./pages/routes/FunderRoutes";
import { NPORoutes } from "./pages/routes/NPORoutes";
import { WelcomeRoutes } from "./pages/routes/WelcomeRoutes";

import Button from "./components/tailwind/Button";
import useConsentCookie, { ConsentCookieEnum } from "./hooks/useConsentCookie";
// import { mxPageEvent } from "./mixpanel/mixpanel";
import { fetchMoreSearchOptionAsync } from "./app/moreSearchOptionSlice";
import projectSlice, { fetchProjectsAsync } from "./app/projectSlice";
import { fetchStaticData } from "./app/staticDataSlice";
import MobileVersion from "./components/MobileVersion";
import PlainButton from "./components/PlainButton";
import WelcomeModal from "./components/WelcomeModal";
import useAskEmailVerification from "./hooks/useAskEmailVerification";
import useDebounce from "./hooks/useDebounce";
import useDeviceType from "./hooks/useDeviceType";
import useEINFromSearchParams from "./hooks/useEINFromSearchParams";
import useGibooMixpanel from "./hooks/useGibooMixpanel";
import useICPSearchContext from "./hooks/useICPSearchContext";
import useLocalStorage from "./hooks/useLocalstorage";
import { MIXPANEL_EVENTS_V2 } from "./mixpanel/mixpanel";
export const MIX_PANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";

const GA_ID = process.env.REACT_APP_GA_TRACKING_ID || "";
GA_ID && ReactGA.initialize(GA_ID, { testMode: process.env.REACT_APP_ENV === "DEV" });

MIX_PANEL_TOKEN &&
  mixpanel.init(MIX_PANEL_TOKEN, {
    debug: process.env.REACT_APP_ENV === "DEV",
    track_pageview: true,
    persistence: "localStorage",
  });

function App() {
  const mxEvent = useGibooMixpanel();
  const navigate = useNavigate();
  const router = process.env.REACT_APP_ROUTER;
  const [user, setUser] = useUser();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const org_id = useOrgID();
  const { value: closed, update: closeWelcome } = useLocalStorage("CLOSED_WELCOME");
  const { ein, firstname, cid } = useEINFromSearchParams();
  const { data: context, isLoading } = useICPSearchContext(cid || undefined);
  const browserLocation = useLocation();
  const [welcomeOpen, setWelcomeOpen] = useState<boolean>(
    !browserLocation.pathname.includes("/search") || !ein || user || closed ? false : true,
  );
  const debouncedContext = useDebounce(context, 500);
  const { value: sent, update: updateSent } = useLocalStorage("SENT_INVITED_USER_MIXPANEL_EVENT");
  useEffect(() => {
    if (cid && sent !== cid && debouncedContext?.ein && MIX_PANEL_TOKEN) {
      updateSent(cid);
      mixpanel.track(MIXPANEL_EVENTS_V2.invited_user_session[""].started, {
        cid,
        invitedEIN: debouncedContext?.ein || ein || undefined,
        invitedFirstname: debouncedContext?.firstname || firstname || undefined,
      });
    }
  }, [debouncedContext]);
  useEffect(() => {
    dispatch(projectSlice.actions.clear());
    dispatch(fetchSearchHistoryAsync());
    if (org_id) {
      dispatch(fetchProjectsAsync(org_id));
      dispatch(fetchMoreSearchOptionAsync());
    }
  }, [user, org_id]);
  useEffect(() => {
    if (MIX_PANEL_TOKEN) {
      if (user?._id) {
        mixpanel.identify(user?._id);
        mixpanel.people.set({
          $name: `${user.firstname} ${user.lastname}`,
          $email: user.email,
          title: user.title,
        });
      }
    }
  }, [user]);

  if (MIX_PANEL_TOKEN) {
    useEffect(() => {
      mxEvent("Page Visited");
    }, [location]);
  }

  const { needToAsk, update } = useConsentCookie();
  const {
    needToAsk: needToAskVerification,
    handleClose,
    send,
    isLoading: sendLoading,
  } = useAskEmailVerification();

  useEffect(() => {
    dispatch(fetchStaticData("taxonomy_uppercase"));
    dispatch(fetchStaticData("geocode"));
  }, []);
  // if (GA_ID) {
  //   useEffect(() => {
  //     if (location.pathname.startsWith("/search")) {
  //       const queryString = location.search;
  //       const regex = /[?&]([^=#]+)=([^&#]*)/g;
  //       let match;
  //       const values: { [key: string]: string } = {
  //         text_query: "",
  //         mission: "",
  //         type: "",
  //       };
  //       while ((match = regex.exec(queryString))) {
  //         values[match[1]] = match[2];
  //       }
  //       ReactGA.send({
  //         hitType: "pageview",
  //         page: `${location.pathname}?type=${values["type"]}`,
  //         title: "Page view",
  //       });
  //     } else
  //       ReactGA.send({
  //         hitType: "pageview",
  //         page: location.pathname,
  //         title: "Page view",
  //       });
  //   }, [location]);
  // }
  // const { isLoading } = useLogin();
  // if (isLoading) {
  //   return (
  //     <div className="grid h-screen w-screen place-items-center">
  //       <div
  //         className="m-5  inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-purple-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
  //         role="status"
  //       >
  //         <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
  //           Loading...
  //         </span>
  //       </div>
  //     </div>
  //   );
  // }
  // useEffect(() => {
  //   console.log(location);
  // }, [location]);

  const handleOnConsent = useCallback(() => {
    (window as any)[`ga-disable-${GA_ID}`] = false;
    update(ConsentCookieEnum.CONSENT);
  }, [window, update]);
  const handleOnReject = useCallback(() => {
    (window as any)[`ga-disable-${GA_ID}`] = true;
    update(ConsentCookieEnum.REJECT);
  }, [window, update]);
  const { isMobile } = useDeviceType();
  const renderContent = () => {
    return (
      <>
        {user ? router === "FUNDER" ? <FunderRoutes /> : <NPORoutes /> : <WelcomeRoutes />}
        {needToAsk && (
          <Portal>
            <div className="fixed bottom-4 z-[9999] flex w-full flex-col items-center justify-center">
              <div className="flex max-w-[900px] items-center justify-between gap-5 rounded border border-gray-300 bg-white p-4">
                <h5 className="grow">
                  We use cookies to enhance your browsing experience. By continuing to use our site,
                  you consent to the use of cookies. For more information, please read our{" "}
                  <span
                    className="cursor-pointer font-semibold text-purple-500 hover:underline"
                    onClick={() => navigate(`/privacy`, { state: { selected: 1 } })}
                  >
                    Privacy policy.
                  </span>
                </h5>
                <div className="flex flex-col gap-2">
                  <Button
                    className="whitespace-nowrap"
                    size="sm"
                    id="btn-consent"
                    handleOnClick={handleOnConsent}
                    label="Consent"
                  />
                  <Button
                    className="whitespace-nowrap"
                    size="sm"
                    outline
                    id="btn-reject"
                    handleOnClick={handleOnReject}
                    label="Use necessary cookies only"
                  />
                </div>
              </div>
            </div>
          </Portal>
        )}
        {needToAskVerification && (
          <Portal>
            <div className="fixed right-8 top-20 z-[9999] flex w-full max-w-[320px] flex-col items-center justify-center">
              <div className="flex flex-col justify-between gap-10 rounded border border-gray-300 bg-purple-50 p-4 shadow">
                <div className="flex items-start">
                  <h5 className="grow">
                    To fully access our services, please verify your email address. This will also
                    enable you to reset your password if you forget it
                  </h5>
                  <PlainButton
                    id="btn-close"
                    handleOnClick={handleClose}
                    leftIconClass="gi-sm gi-x"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <Button
                    className="whitespace-nowrap"
                    size="sm"
                    id="btn-send-again"
                    handleOnClick={send}
                    loading={sendLoading}
                    label="Haven't received the email yet? Send it again"
                  />
                </div>
              </div>
            </div>
          </Portal>
        )}
      </>
    );
  };
  const allowMobilePaths = ["/signup", "/signin", "/verify", "/pending"];
  return (
    <>
      <Portal>
        <ToastContainer hideProgressBar closeButton={false} />
      </Portal>
      <WelcomeModal
        open={welcomeOpen}
        onClose={() => {
          setWelcomeOpen(false);
          closeWelcome("true");
        }}
      />
      {isMobile && !allowMobilePaths.some((prefix) => location.pathname.startsWith(prefix)) ? (
        <MobileVersion />
      ) : (
        renderContent()
      )}
    </>
  );
}

export default App;
