import * as React from "react";
import classNames from "classnames";
import calendar_icon from "../../assets/images/calendar.svg";
import { toDateString } from "../../utils/formatHelper";
import DatePicker from "react-datepicker";
interface Iprops {
  id: string;
  dateClass?: string;
  value?: string;
  handleDateSelection: (value?: string) => void;
  minDate?: string;
  maxDate?: string;
  placeholder?: string;
  type?: "start" | "end";
  hasStartEnd?: boolean;
  disabled?: boolean;
  className?: string;
}

function GibooDatePicker({
  value,
  handleDateSelection,
  minDate,
  maxDate,
  placeholder = "Select",
  type,
  id,
  disabled,
  hasStartEnd = false,
  className,
}: Iprops) {
  const [dateValue, setDateValue] = React.useState<Date | undefined>();
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>();
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [min, setMin] = React.useState<Date | undefined>();
  const [max, setMax] = React.useState<Date | undefined>();
  // React.useEffect(() => {
  //   dateValue ? handleDateSelection(toDateString(dateValue)) : handleDateSelection(undefined);
  // }, [dateValue]);
  React.useEffect(() => {
    if (value) {
      setSelectedDate(new Date(value));
      setDateValue(new Date(value));
    } else {
      setSelectedDate(undefined);
      setDateValue(undefined);
    }
    minDate && setMin(new Date(minDate));
    maxDate && setMax(new Date(maxDate));
    typeof disabled === "boolean" && setIsDisabled(disabled);
    if (type === "end" && minDate === undefined && hasStartEnd) {
      if (minDate === undefined) {
        setDateValue(undefined);
        setSelectedDate(undefined);
        handleDateSelection(undefined);
      }
    }
  }, [value, minDate, disabled, maxDate, type, hasStartEnd]);

  const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => {
    let tempValue = value;
    if (toDateString(value) === toDateString(new Date())) {
      tempValue = "Today";
    }
    return (
      <button
        className={classNames(
          "flex h-[48px] w-[208px] items-center rounded border border-gray-300 py-2 pl-[18px] text-gray-700 ",
          { ["disabled bg-gray-100"]: isDisabled },
          { "focus:border-gray-500": !isDisabled },
          className,
        )}
        onClick={onClick}
        ref={ref}
      >
        {tempValue ? (
          <span className="w-full text-start">{tempValue}</span>
        ) : (
          <span className="w-full text-start font-poppins font-normal text-gray-500">
            {placeholder}
          </span>
        )}
        <span className="flex h-full w-fit items-center px-[15px]">
          {tempValue && (
            <span
              className=" h-5 w-5"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                !isDisabled && setDateValue(undefined);
                !isDisabled && setSelectedDate(undefined);
                !isDisabled && handleDateSelection(undefined);
              }}
            >
              <i className="fa fa-xmark h-full w-full pr-2" />
            </span>
          )}
          <span className="h-5 w-5">
            <img src={calendar_icon} className="h-full w-full" />
          </span>
        </span>
      </button>
    );
  });
  CustomInput.displayName = "custom-calendar-input";
  return (
    <DatePicker
      {...(min ? { minDate: new Date(min) } : {})}
      {...(max ? { maxDate: new Date(max) } : {})}
      selected={selectedDate || dateValue}
      dateFormat={"yyyy/MM/dd"}
      onChange={(date: any) => {
        setDateValue(date);
        date ? handleDateSelection(toDateString(date)) : handleDateSelection(undefined);
      }}
      customInput={<CustomInput />}
      id={id}
      disabled={isDisabled}
      name="giboo-date-picker"
      placeholderText={placeholder}
      showYearDropdown
      showMonthDropdown
    />
  );
}

GibooDatePicker.defaultProps = {
  color: "purple",
  fullWidth: false,
  outline: false,
};

export default GibooDatePicker;
