import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import useOrgID from "../useOrgID";
import { GibooDocument } from "../../types/document";
import useCancellableSWR from "../useCancellableSWR";
import useThrottle from "../useThrottle";
import { swrOptionDedupling5mins, swrOptionFetchOnlyOnMount } from "../../types/swr";
import { IProject, IProjectSubtask } from "../../types/project";
interface DocumentSearchState {
  data: GibooDocument[];
  size: number;
  error: any;
  isLoading: boolean;
  isSizeLoading: boolean;
  isValidating: boolean;
  isSizeValidating: boolean;
  revalidate: () => Promise<GibooDocument[] | undefined>;
  revalidateSize: () => Promise<number | undefined>;
  renameDocument: (subtask_id: string, name: string) => Promise<GibooDocument[] | undefined>;
  deleteDocument: (subtask_id: string) => Promise<GibooDocument[] | undefined>;
}
export const DOCUMENT_RECENT_LIMIT = 100;
export const DOCUMENT_RECENT_LIMIT_LAST_DAYS = 90;
export const getDocumentSearchURL = (
  org_id: string,
  query: string | undefined,
  project_id: string | undefined,
  task_id: string | undefined,
  skip: number,
  limit: number,
  last_days: number,
  sorting = "last_updated",
): string => {
  return (
    process.env.REACT_APP_API_URL +
    `/api/v2/document/list/${org_id}?skip=${skip}&limit=${limit}&sort=${sorting}${
      last_days > 0 ? `&last_days=${last_days}` : ""
    }${query && query.length > 0 ? `&query=${encodeURIComponent(query)}` : ""}${
      project_id ? `&project_id=${project_id}` : ""
    }${task_id ? `&task_id=${task_id}` : ""}`
  );
};
const emptyList: GibooDocument[] = [];
function useDocumentSearch(
  query?: string,
  project_id?: string,
  task_id?: string,
  skip = 0,
  limit = 5,
  last_days = -1,
  sorting = "last_updated",
): DocumentSearchState {
  const org_id = useOrgID();
  const throttledQuery = useThrottle(query, 300);
  const url = getDocumentSearchURL(
    org_id,
    throttledQuery,
    project_id,
    task_id,
    skip,
    limit,
    last_days,
    sorting,
  );
  const size_url =
    process.env.REACT_APP_API_URL +
    `/api/v2/document/size/${org_id}${
      throttledQuery && throttledQuery.length > 0
        ? `?query=${encodeURIComponent(throttledQuery)}`
        : ""
    }`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string, controller?: AbortController) => {
    if (!_url) return new Promise<GibooDocument[]>((resolve, reject) => reject());
    return axios
      .get(_url, { ...config, ...(controller ? { signal: controller.signal } : {}) })
      .then((res) => res.data as GibooDocument[]);
  };
  const fetchSize = async (_url: string, controller?: AbortController) => {
    if (!_url) return new Promise<number>((resolve, reject) => reject());
    return axios
      .get(_url, { ...config, ...(controller ? { signal: controller.signal } : {}) })
      .then((res) => res.data as number);
  };
  const { data, isLoading, error, isValidating } = useCancellableSWR<GibooDocument[]>(
    org_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const {
    data: size,
    isLoading: isSizeLoading,
    error: errorSize,
    isValidating: isSizeValidating,
  } = useCancellableSWR<number>(org_id ? size_url : null, fetchSize, swrOptionFetchOnlyOnMount);
  const revalidate = useCallback(() => mutate<GibooDocument[]>(url), [url]);
  const revalidateSize = useCallback(() => mutate<number>(size_url), [size_url]);
  const renameDocument = useCallback(
    (subtask_id: string, name: string) => {
      const findIndex = !data ? -1 : data.findIndex((d) => d.subtask_id === subtask_id);
      const options = {
        optimisticData:
          !data || findIndex < 0
            ? data
            : [
                ...data.slice(0, findIndex),
                {
                  ...data[findIndex],
                  name,
                },
                ...data.slice(findIndex + 1),
              ],
        rollbackOnError: true,
      };
      return mutate<GibooDocument[] | undefined>(
        url,
        axios
          .put(
            process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/${subtask_id}`,
            { name },
            config,
          )
          .then((res) => res.data as IProjectSubtask)
          .then((res) => {
            const findIndex =
              !res?._id || !data ? -1 : data.findIndex((d) => d.subtask_id === res._id);
            return !data || findIndex < 0
              ? data
              : [
                  ...data.slice(0, findIndex),
                  {
                    ...data[findIndex],
                    name: res.name,
                  },
                  ...data.slice(findIndex + 1),
                ];
          }),
        options,
      );
    },
    [data, url],
  );
  const deleteDocument = useCallback(
    (subtask_id: string) => {
      const findIndex = !data ? -1 : data.findIndex((d) => d.subtask_id === subtask_id);
      const options = {
        optimisticData:
          !data || findIndex < 0
            ? data
            : [...data.slice(0, findIndex), ...data.slice(findIndex + 1)],
        rollbackOnError: true,
      };
      return mutate<GibooDocument[] | undefined>(
        url,
        axios
          .delete(process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/${subtask_id}`, config)
          .then((res) => {
            revalidateSize();
            return res.data as boolean;
          })
          .then((res) => {
            const findIndex =
              !res || !data ? -1 : data.findIndex((d) => d.subtask_id === subtask_id);
            return !data || findIndex < 0
              ? data
              : [...data.slice(0, findIndex), ...data.slice(findIndex + 1)];
          }),
        options,
      );
    },
    [data, url, revalidateSize],
  );
  return {
    data: data || emptyList,
    size: size || 0,
    isLoading: isLoading || !data ? true : false,
    isSizeLoading,
    error: error || errorSize,
    isValidating,
    isSizeValidating,
    revalidate,
    revalidateSize,
    renameDocument,
    deleteDocument,
  };
}
export default useDocumentSearch;
