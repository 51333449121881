import { useLocation, useParams, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import useProject from "../../../hooks/project/useProject";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import GibooAiTaskAssistant from "../../../components/GibooAiTaskAssistant";
import DocumentAIRecents from "./document/DocumentAIRecents";
import DocumentAIEditor from "./document/DocumentAIEditor";
import {
  ChatActionLetter,
  DocumentChatRequest,
  DocumentDefinition,
  DocumentNode,
  DocumentNodeType,
  DocumentTreeNode,
  DocumentTypeEnum,
  DocumentUpdateRequest,
  DocumentViewTypeEnum,
  GibooDocument,
} from "../../../types/document";
import useDocumentDefinitions from "../../../hooks/project/useDocumentDefinitions";
import GibooChat from "./document/GibooChat";
import useGibooChat from "../../../hooks/project/useGibooChat";
import useTasks from "../../../hooks/project/useTasks";
import {
  IProjectSubtask,
  IProjectSubtaskUpdateRequest,
  IProjectTask,
} from "../../../types/project";
import usePreviousLetters from "../../../hooks/project/usePreviousLetters";
import useDebounce from "../../../hooks/useDebounce";
import ReactQuill from "react-quill";
import useDocumentSearch from "../../../hooks/project/useDocumentSearch";
import useOrgID from "../../../hooks/useOrgID";
import useRecommendedTargets, {
  RecommendedTarget,
} from "../../../hooks/project/useRecommendedTargets";
import LetterEditorModal from "../../../components/LetterEditorModal";
import useFunderContact from "../../../hooks/funder/useFunderContact";
import useGrantSearchResult from "../../../hooks/search/useGrantSearchResult";
import { FunderMemberContact } from "../../../services/funders.services";
import useFunderSearchResult from "../../../hooks/search/useFunderSearchResult";
import useDebounceObject from "../../../hooks/useDebounceObject";
import useTask from "../../../hooks/project/useTask";
import useSubtask from "../../../hooks/project/useSubtask";
import { useSetRecoilState } from "recoil";
import { sidekickDocumentGenerated, sidekickDocumentTargetGrant } from "../../../app/recoilStore";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
const emptyContactList: FunderMemberContact[] = [];
interface IDocumentAIContext {
  project_id: string | undefined;
  document_id: string | undefined;
  target_id: string | undefined;
  target_type: 0 | 1 | 2 | undefined;
  target_name: string | undefined;
  chat: (
    req: DocumentChatRequest,
    document_id_by_param?: string,
    createNewDocument?: boolean,
  ) => Promise<{ project_id: string; document_id: string; created?: boolean } | undefined>;
  regenerate: (
    parent_id: string,
  ) => Promise<{ project_id: string; document_id: string } | undefined>;
  stop: () => void;
  updateSubtask:
    | ((req: IProjectSubtaskUpdateRequest) => Promise<IProjectSubtask | undefined>)
    | undefined;
  task: IProjectTask | undefined;
  tasks: IProjectTask[];
  createDocument: (
    definition: DocumentDefinition,
    name?: string,
    returnProjectId?: boolean,
    controller?: AbortController,
  ) => Promise<string | undefined>;
  updateDocument: (
    req: DocumentUpdateRequest,
    document_id_by_param?: string,
  ) => Promise<string | undefined>;
  document: GibooDocument | undefined;
  root: DocumentTreeNode | undefined;
  nodes: DocumentNode[];
  route: string[];
  messages: string[];
  recent: string | undefined;
  nodeDict: { [key: string]: DocumentTreeNode };
  previousLetters: ChatActionLetter[];
  contacts: FunderMemberContact[];
  error: any;
  isLoading: boolean;
  isChatLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<GibooDocument | undefined>;
  visit: (parent_id: string, node_id: string) => void;
  documentDefinition: DocumentDefinition | undefined;
  streamingNodeId: string | undefined;
  isPreviousLettersLoading: boolean;
  isContactsLoading: boolean;
  addTemporaryNodeForPastingToTheChatBox: (parent_id: string) => void;
  quillRef: React.RefObject<ReactQuill>;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;
  lastUpdatedAt: string | undefined;
  recommendedTargets: RecommendedTarget[];
  isRecommendedTargetsLoading: boolean;
  setParentId: Dispatch<SetStateAction<string>>;
  setOutputEditMode: Dispatch<SetStateAction<boolean>>;
  focusChat: () => void;
  inputRef: RefObject<HTMLTextAreaElement>;
  renameNewDocument: string;
  setRenameNewDocument: Dispatch<SetStateAction<string>>;
}
const DocumentAIContext = createContext<IDocumentAIContext | undefined>(undefined);
export const useDocumentAIContext = () => {
  const context = useContext(DocumentAIContext);
  if (!context) throw new Error("Provider not found");
  return context;
};
interface ProjectDocumentAIState {
  output?: string;
  documentType?: DocumentTypeEnum;
}
function ProjectDocumentAI() {
  const mxEvent = useGibooMixpanel(undefined, true);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const org_id = useOrgID();
  const { state: context } = useLocation() as { state: ProjectDocumentAIState };
  const [searchParams, setSearchParams] = useSearchParams();
  const { id: projectId, document_id } = useParams();
  const quillRef = useRef<ReactQuill>(null);
  const [outputEditMode, setOutputEditMode] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<string>("");
  const [parentId, setParentId] = useState<string>("");
  const [targetName, setTargetName] = useState<string>();
  const [renameNewDocument, setRenameNewDocument] = useState<string>("");
  const setSidekickDocumentGenerated = useSetRecoilState(sidekickDocumentGenerated);
  const setSidekickDocumentTargetGrant = useSetRecoilState(sidekickDocumentTargetGrant);
  const {
    data: projectData,
    hasEditPermission,
    isLoading: isLoadingProject,
  } = useProject(projectId);
  const {
    data: document,
    target_id,
    target_type,
    modify,
    chat,
    regenerate,
    stop,
    nodes,
    nodeDict,
    documentDefinition,
    isChatLoading,
    error,
    isLoading,
    isValidating,
    messages,
    recent,
    revalidate,
    root,
    route,
    createDocument,
    updateDocument,
    visit,
    streamingNodeId,
    addTemporaryNodeForPastingToTheChatBox,
  } = useGibooChat(projectId, document_id, context?.documentType);
  const { error: errorList, revalidate: revalidateDocumentList } = useDocumentSearch(
    "",
    undefined,
    undefined,
    0,
    100,
    90,
  );
  const [content, setContent] = useState<string>("");
  const [lastUpdatedAt, setLastUpdatedAt] = useState<string | undefined>();
  const debouncedContent = useDebounce<string>(content, 3000, {
    maxWait: 10000,
  });
  const debouncedRecent = useDebounceObject(
    { document_id: document_id || "", recent: recent || "" },
    ["document_id", "recent"],
    2000,
    {
      maxWait: 10000,
    },
  );
  const { data: tasks } = useTasks(projectId);
  const { data: task } = useTask(
    tasks?.find((t) => t.target_id === target_id && t.target_type === target_type)?._id,
  );
  const { data: subtask, update: updateSubtask } = useSubtask(document?.subtask_id);
  const { data: recommendedTargets, isLoading: isRecommendedTargetsLoading } =
    useRecommendedTargets(org_id);
  const { data: previousLetters, isLoading: isPreviousLettersLoading } = usePreviousLetters(
    target_id,
    target_type,
  );
  const { data: funder, isLoading: isFunderLoading } = useFunderSearchResult(
    target_type === 0 ? target_id : undefined,
  );
  const { data: grant, isLoading: isGrantLoading } = useGrantSearchResult(
    target_type === 1 ? target_id : undefined,
  );
  const { data: contact, isLoading: isContactsLoading } = useFunderContact(
    target_type === 0 ? target_id : grant?.donor_id,
  );
  useEffect(() => {
    setSidekickDocumentTargetGrant(
      target_type === 1 ? (grant?.type === "grant-page" ? "virtual" : "opencall") : undefined,
    );
  }, [grant, target_type]);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.documentai[""].started, {
      documentaiId: document_id,
    });
  }, [document_id]);
  useEffect(() => {
    const _found = recommendedTargets.find(
      (t) => t.target_id === target_id && t.target_type === target_type,
    );
    setTargetName(
      _found?.name
        ? _found.name
        : target_id
        ? target_type === 1
          ? grant?.name
          : target_type === 0
          ? funder?.name
          : undefined
        : undefined,
    );
  }, [target_id, target_type, recommendedTargets, funder, grant, setTargetName]);
  useEffect(() => {
    quillRef?.current?.getEditor().setText("");
    revalidateDocumentList();
    // return () => {
    //   updateDocument(
    //     {
    //       node_id: recent,
    //       node_history_path: route.filter((id) => !id.startsWith("virtual_")),
    //     },
    //     document_id,
    //   );
    // };
  }, [document_id]);
  useEffect(() => {
    if (
      route.some(
        (r) =>
          r &&
          !r.startsWith("virtual_") &&
          !r.startsWith("error_") &&
          nodeDict[r]?.type === DocumentNodeType.BOT,
      )
    ) {
      setSidekickDocumentGenerated(true);
    } else {
      setSidekickDocumentGenerated(false);
    }
    return () => setSidekickDocumentGenerated(false);
  }, [nodeDict, route]);
  // useEffect(() => {
  //   if (!quillRef?.current) return;
  //   if (context?.output) quillRef?.current?.getEditor()?.setText(context?.output || "");
  // }, [context, quillRef?.current]);
  // useEffect(() => {
  //   if (!quillRef?.current) return;
  //   quillRef?.current?.getEditor()?.setText(document?.output || "");
  // }, [document, quillRef?.current]);
  useEffect(() => {
    if (
      document_id &&
      debouncedRecent &&
      document_id === debouncedRecent.document_id &&
      debouncedRecent.recent &&
      !debouncedRecent.recent.startsWith("virtual_") &&
      !debouncedRecent.recent.startsWith("error_") &&
      !debouncedRecent.recent.startsWith("first_question") &&
      !isLoading
    ) {
      // const text = quillRef?.current?.getEditor().getText();
      updateDocument({
        node_id: debouncedRecent.recent,
        // output: text && text.trim() ? text : undefined,
        node_history_path: route.filter(
          (id) =>
            !id.startsWith("virtual_") &&
            !id.startsWith("error_") &&
            !id.startsWith("first_question"),
        ),
      }).then((id: string | undefined) => {
        if (id) {
          setLastUpdatedAt(new Date().toISOString());
        }
      });
    }
  }, [debouncedRecent]);
  // console.log(debouncedRecent, route);
  const onClose = useCallback(() => {
    setOutputEditMode(false);
  }, [setOutputEditMode]);
  const onSave = useCallback(() => {
    // const v = quillRef?.current?.getEditor().getText();
    modify({ parent_id: parentId, value: content });
    setOutputEditMode(false);
  }, [quillRef, quillRef?.current, content, parentId, setOutputEditMode, modify]);
  const focusChat = useCallback(() => {
    if (inputRef?.current) inputRef.current.focus();
  }, [inputRef]);
  return (
    <DocumentAIContext.Provider
      value={{
        project_id: projectId,
        document_id,
        target_name: targetName,
        target_id,
        target_type,
        chat,
        regenerate,
        stop,
        nodes,
        nodeDict,
        updateSubtask: subtask?._id ? updateSubtask : undefined,
        task,
        tasks,
        previousLetters,
        isChatLoading,
        document,
        documentDefinition,
        error,
        isLoading,
        isValidating,
        messages,
        recent,
        revalidate,
        root,
        route,
        createDocument,
        updateDocument,
        visit,
        streamingNodeId,
        isPreviousLettersLoading,
        isContactsLoading: isContactsLoading || isGrantLoading,
        contacts: contact?.contacts || emptyContactList,
        addTemporaryNodeForPastingToTheChatBox,
        quillRef,
        content,
        setContent,
        lastUpdatedAt,
        recommendedTargets,
        isRecommendedTargetsLoading,
        setParentId,
        setOutputEditMode,
        focusChat,
        inputRef,
        renameNewDocument,
        setRenameNewDocument,
      }}
    >
      {/* {projectId && <GibooAiTaskAssistant id="project-management-document-ai" />} */}
      <div
        className="flex grow overflow-x-auto overflow-y-hidden"
        // style={{ minHeight: projectId ? `calc(100vh - 300px)` : `calc(100vh - 62px)` }}
        style={{ minHeight: `calc(100vh - 300px)` }}
      >
        {parentId && (
          <LetterEditorModal
            quillRefProp={quillRef}
            onClose={() => onClose()}
            onSave={() => onSave()}
            show={outputEditMode}
            setContent={setContent}
            content={content}
          />
        )}
        <DocumentAIRecents />
        <GibooChat />
        {/* {projectId && document_id ? (
          <DocumentAIEditor readonly={!hasEditPermission} document_id={document_id} />
        ) : (
          <DocumentAIPromptList
            setDocumentType={(e: DocumentTypeEnum) =>
              setSearchParams(new URLSearchParams([["type", `${e}`]]))
            }
          />
        )} */}
      </div>
    </DocumentAIContext.Provider>
  );
}
export default ProjectDocumentAI;
