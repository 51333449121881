import { useEffect, useState } from "react";
import { FunderSearchResult, SearchQuery } from "../../types/search";
import { filterUnmatched, matchTaxonomyBySearchQueryWithPhilanthropy } from "../../types/taxonomy";
import useFunderSearchResults from "./useFunderSearchResults";

interface FunderSearchResultState {
  data: FunderSearchResult | undefined;
  dataAll: FunderSearchResult | undefined;
  dataUnMatched: FunderSearchResult | undefined;
  isLoading: boolean;
  error: object;
}
export default function useFunderSearchResult(
  id?: string | number,
  query?: SearchQuery,
): FunderSearchResultState {
  const { data, isLoading, error } = useFunderSearchResults(id ? [id] : []);
  const [all, setAll] = useState<FunderSearchResult | undefined>();
  const [matched, setMatched] = useState<FunderSearchResult | undefined>();
  const [unMatched, setUnMatched] = useState<FunderSearchResult | undefined>();
  useEffect(() => {
    const g = data && data.length > 0 ? data[0] : undefined;
    setAll(
      g
        ? query
          ? {
              ...g,
              ...matchTaxonomyBySearchQueryWithPhilanthropy(g, query, true, false),
            }
          : g
        : undefined,
    );
  }, [data, query]);
  useEffect(() => {
    if (isLoading || !all) return;
    setMatched({ ...all, ...filterUnmatched(all, false) });
    setUnMatched({
      ...all,
      focus_area: all?.focus_area?.filter((t) => !t.matched),
      beneficiary: all?.beneficiary?.filter((t) => !t.matched),
      program: all?.program?.filter((t) => !t.matched),
      service_loc: all?.service_loc?.filter((t) => !t.matched),
    });
  }, [all, isLoading]);
  return {
    data: matched,
    dataAll: all,
    dataUnMatched: unMatched,
    isLoading,
    error,
  };
}
