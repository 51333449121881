import Spinner from "./Spinner";

function FullScreenLoading() {
  return (
    <div className="flex h-[calc(100vh-60px)] w-full items-center justify-center">
      <Spinner />
    </div>
  );
}
export default FullScreenLoading;
