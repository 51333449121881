import classNames from "classnames";
import { Dispatch, ReactElement, ReactNode, SetStateAction, useEffect, useState } from "react";
import Checkbox from "./Checkbox";

interface CheckboxGroupProps<T extends string> {
  onCheck?: (t: T, check: boolean) => void;
  id: string;
  data: { value: T; label: ReactElement | string }[];
  value: T[];
  setValue: (d: T[]) => void;
  flexColumn?: boolean;
  disabled?: boolean;
  allowOthers?: boolean;
  selectAll?: boolean;
  selectAllText?: string;
  wrapperClass?: string;
  renderInfo?: (value: string) => ReactNode;
}
function CheckboxGroup<T extends string>({
  onCheck,
  selectAllText = "All",
  ...props
}: CheckboxGroupProps<T>) {
  const [others, setOthers] = useState<string>("");
  const [allSelected, setAllSelected] = useState<boolean>(false);
  useEffect(() => {
    if (props.allowOthers)
      if (others)
        props.setValue([...props.value.filter((item) => !item.startsWith("*")), `*${others}` as T]);
      else props.setValue(props.value.filter((item) => !item.startsWith("*")));
  }, [others]);
  useEffect(() => {
    setAllSelected(props.value.length === props.data.length + (props.allowOthers ? 1 : 0));
    setOthers(props.value.filter((item) => item.startsWith("*"))[0]?.slice(1) || "");
  }, [props.value]);
  return (
    <div
      className={classNames(
        "flex",
        {
          "flex-wrap gap-x-3 gap-y-0": !props.flexColumn,
          "flex-col items-start gap-0": props.flexColumn,
        },
        props.wrapperClass,
      )}
    >
      {props.selectAll && (
        <div className="me-4 flex items-center gap-1">
          <Checkbox
            id={`input-${props.id}-*all`}
            checked={allSelected}
            onChange={() => {
              if (!allSelected) {
                props.data
                  .filter((d) => !props.value.find((t) => t === d.value))
                  .forEach((d) => onCheck?.(d.value, true));
                props.setValue([
                  ...props.data.map((item) => item.value as T),
                  ...(props.allowOthers ? [`*${others}` as T] : []),
                ]);
              } else {
                props.value.forEach((d) => onCheck?.(d, false));
                props.setValue([]);
              }
            }}
            label={selectAllText}
            disabled={props.disabled}
          />
        </div>
      )}
      {props.data.map((d) => (
        <div className="me-4 flex w-full gap-2" key={d.value}>
          {d.value.startsWith("*") ? null : (
            <div className="flex w-full items-center justify-between gap-2">
              <div
                id={`${props.id}_${d.value}`}
                className={classNames("flex cursor-pointer items-center gap-2", props.id)}
                onClick={() => {
                  if (props.value.includes(d.value as T)) {
                    props.setValue(props.value.filter((item) => item !== d.value));
                    onCheck?.(d.value, false);
                  } else {
                    props.setValue([...props.value, d.value as T]);
                    onCheck?.(d.value, true);
                  }
                }}
                aria-valuetext={d.value}
              >
                <Checkbox
                  id={`input-${props.id}-${d.value}`}
                  checked={props.value.includes(d.value as T)}
                  onChange={() => {}}
                  {...(typeof d.label === "string" ? { label: d.label } : {})}
                  disabled={props.disabled}
                  ariaValueText={d.value}
                />
                {typeof d.label !== "string" ? <>{d.label}</> : null}
              </div>
              {props.renderInfo && props.renderInfo(d.value)}
            </div>
          )}
        </div>
      ))}
      {props.allowOthers && (
        <div className="flex items-center gap-1">
          <Checkbox
            id={`input-${props.id}-`}
            checked={props.value.filter((item) => item.startsWith("*")).length > 0}
            onChange={() => {
              if (props.value.filter((item) => item.startsWith("*")).length > 0) setOthers("");
            }}
            label="Others,"
            disabled={props.disabled}
          />
          <p className="gray">please specify</p>
          <input
            id={`input-${props.id}-others`}
            className="h-6 border-b border-l-0 border-r-0 border-t-0 border-dashed !border-gray-500 bg-transparent outline-none !ring-0"
            type="text"
            value={others}
            onChange={(e) => {
              setOthers(e.currentTarget.value);
            }}
          />
        </div>
      )}
    </div>
  );
}
export default CheckboxGroup;
