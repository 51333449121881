import React, { ReactNode, useEffect, useState } from "react";
import ExpandableCard from "../../../../components/tailwind/ExpandableCard";
import IMAGE_MEN from "../../../../assets/images/gender-men.svg";
import IMAGE_WOMEN from "../../../../assets/images/gender-women.svg";
import IMAGE_OTHER from "../../../../assets/images/gender-other.svg";
import IMAGE_UNKNOWN from "../../../../assets/images/gender-unknown.svg";
import { TooltipWrapper } from "../../../../components/TooltipWrapper";
import classNames from "classnames";
import PublicPrivateButton from "../../../../components/PublicPrivateButton";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import useDiversity, { IDiversityInfo } from "../../../../hooks/useDiversity";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import DiversityChartLoader from "../../diversity/component/DiversityChartLoader";
import { Divider } from "rsuite";
import Spacer from "../../../../components/Spacer";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import DiversityFilterWrapper, {
  DiversityFilter,
} from "../../diversity/component/DiversityFilterWrapper";
import useDiversityRecommendedAction from "../../../../hooks/npo/useDiversityRecommendedAction";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import FinancialDiversityRecommendation from "../../../../components/FinancialDiversityRecommendation";
import AssessmentResult from "../../../../components/AssessmentResult";
import PopupModal from "../../../../components/tailwind/PopupModal";
import { GenderInfoModal } from "../../diversity/pages/GenderInfo";
import { NpoTitle } from "./NpoDiversityEthnicity";
import useTotalOrganizationMembersData from "../../../../hooks/useTotalOrganizationMembersData";

interface Iprops {
  npo_id?: string;
  org_id?: string;
  allowEdit?: boolean;
  compact?: boolean;
  recomendation?: boolean;
  showStats?: boolean;
  showInfo?: boolean;
  hideTitle?: boolean;
  children?: ReactNode;
  showChartLoader?: boolean;
  title?: ReactNode;
}
const PROJ_STATUS_COLOR = {
  Man: "#83A5FF",
  Woman: "#FF8E8E",
  Other: "#D6D6D6",
  Unknown: "#D6D6D6",
};

function NpoDiversityGender({
  npo_id,
  org_id,
  allowEdit,
  compact,
  recomendation,
  showInfo = true,
  showStats = true,
  hideTitle = false,
  showChartLoader = true,
  children,
  title,
}: Iprops) {
  const { data: peoples } = useTotalOrganizationMembersData(org_id);
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const { disability, isLoading } = useDiversity(org_id);
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { data: recommendedAction, isLoading: isRecommendedActionLoading } =
    useDiversityRecommendedAction(org_id, "gender");
  const chartLoading = recomendation ? loading : isLoading;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-5"
        showHeader={false}
        showActionButtons={false}
      >
        <GenderInfoModal
          handleClose={() => {
            setShowInfoModal(false);
          }}
        />
      </PopupModal>
      <div
        className={classNames(
          "flex min-h-[100px] flex-col ",
          recomendation && "rounded border border-gray-300 bg-white p-5",
        )}
      >
        {!hideTitle &&
          (title ? (
            title
          ) : (
            <NpoTitle
              title="Gender"
              recomendation={recomendation}
              setShowInfoModal={setShowInfoModal}
            />
          ))}
        {showChartLoader && chartLoading ? (
          <DiversityChartLoader title="gender" />
        ) : (
          <>
            {/* chart */}
            <div className="my-5">
              <GenderChart org_id={org_id} />
            </div>
            {/* info */}
            {showInfo &&
              (loading || isRecommendedActionLoading ? (
                <MemoizedLoadingRecommendedAction
                  loading={loading || isRecommendedActionLoading}
                  short
                />
              ) : (
                <AssessmentResult content={recommendedAction?.summary || ""} />
              ))}
            {recomendation ? <Divider className="my-[40px]" /> : <Spacer height="20px" />}
            {/* stats */}
            {showStats &&
              (loading || isRecommendedActionLoading ? (
                <MemoizedLoadingRecommendedAction
                  loading={loading || isRecommendedActionLoading}
                  hidePercentage
                />
              ) : (
                <div className="bg-gray-50 px-6 py-5">
                  <h6 className="mb-5 font-poppins text-base font-semibold text-black">
                    Recommendations
                  </h6>
                  <div className="flex flex-col gap-10">
                    {peoples.filter((v) => v.employ_type === "board" && v.gender).length > 0 && (
                      <FinancialDiversityRecommendation
                        title="Board members"
                        recommendation={recommendedAction?.board_members || ""}
                      />
                    )}
                    {peoples.filter((v) => v.employ_type === "employee" && v.gender).length > 0 && (
                      <FinancialDiversityRecommendation
                        title="Employees"
                        recommendation={recommendedAction?.employees || ""}
                      />
                    )}
                    {peoples.filter((v) => v.employ_type === "volunteer" && v.gender).length >
                      0 && (
                      <FinancialDiversityRecommendation
                        title="Volunteers"
                        recommendation={recommendedAction?.volunteers || ""}
                      />
                    )}
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
      {!chartLoading && children && children}
    </>
  );
}
NpoDiversityGender.propTypes = {};

export default NpoDiversityGender;
export function GenderChart({
  org_id,
  allowEdit,
  wrapperClass,
  compact,
}: {
  org_id?: string;
  allowEdit?: boolean;
  hideInfo?: boolean;
  wrapperClass?: string;
  compact?: boolean;
}) {
  const [selectedTab, setSelectedTab] = useState<DiversityFilter>("board");
  const { gender: all } = useDiversity(org_id, "all");
  const { gender: board } = useDiversity(org_id, "board");
  const { gender: employee } = useDiversity(org_id, "employee");
  const { gender: volunteer } = useDiversity(org_id, "volunteer");
  const [genderInfo, setGenderInfo] = useState<IDiversityInfo>({});
  const [excludeTabs, setExludeTabs] = useState<DiversityFilter[]>([]);

  const [focusBar, setFocusBar] = useState<number | undefined>();
  const [mouseLeave, setMouseLeave] = useState(true);

  useEffect(() => {
    const tabs = [];
    const hasVolunteer = !Object.values(volunteer).every((item) => item.count === 0);
    const hasBoard = !Object.values(board).every((item) => item.count === 0);
    const hasEmployee = !Object.values(employee).every((item) => item.count === 0);
    if (!hasVolunteer) tabs.push("volunteer");
    if (!hasBoard) tabs.push("board");
    if (!hasBoard) setSelectedTab("all");
    if (!hasEmployee) tabs.push("employee");
    setExludeTabs(tabs as any);
  }, [board, employee, volunteer]);
  useEffect(() => {
    switch (selectedTab) {
      case "all":
        setGenderInfo(all);
        break;
      case "board":
        setGenderInfo(board);
        break;
      case "employee":
        setGenderInfo(employee);
        break;
      case "volunteer":
        setGenderInfo(volunteer);
        break;
      default:
        setGenderInfo(all);
    }
  }, [selectedTab, all, board, employee, volunteer]);
  const renderChart = () => {
    const totalHeight = 200;
    const baseHeight = 25;
    const womenCount = genderInfo.woman?.count || 0;
    const menCount = genderInfo.man?.count || 0;
    const otherCount = genderInfo.other?.count || 0;
    const unknownCount = genderInfo.Unknown?.count || 0;
    const womenPercentage = genderInfo.woman?.percentage || 0;
    const menPercentage = genderInfo.man?.percentage || 0;
    const otherPercentage = genderInfo.other?.percentage || 0;
    const unknownPercentage = genderInfo.Unknown?.percentage || 0;
    const womenHeight = (womenPercentage / 100) * totalHeight;
    const menHeight = (menPercentage / 100) * totalHeight;
    const otherHeight = (otherPercentage / 100) * totalHeight;
    const unknownHeight = (unknownPercentage / 100) * totalHeight;
    return (
      <div
        className="inline-flex h-[234px] w-[489px] items-end justify-center gap-[60px]"
        onMouseLeave={() => {
          setFocusBar(undefined);
          setMouseLeave(true);
        }}
      >
        <TooltipWrapper
          color="white"
          content={
            <div className="space-y-1 text-xs text-black">
              <div className="font-semibold capitalize">Woman</div>
              <div>
                {womenCount} {womenCount === 1 ? "Person" : "People"}
              </div>
            </div>
          }
          arrow={false}
        >
          <div
            className={classNames(
              "flex flex-col items-center gap-4",
              focusBar === 0 || mouseLeave ? "opacity-100" : "opacity-20",
            )}
          >
            <img
              src={IMAGE_WOMEN}
              style={{
                height: `${womenHeight}px`,
                minHeight: `${baseHeight}px`,
              }}
              onMouseEnter={() => {
                setFocusBar(0);
                setMouseLeave(false);
              }}
            />
            <p className="font-poppins text-sm text-purple-500">{womenPercentage || 0}%</p>
          </div>
        </TooltipWrapper>

        <TooltipWrapper
          color="white"
          content={
            <div className="space-y-1 text-xs text-black">
              <div className="font-semibold capitalize">Man</div>
              <div>
                {menCount || 0} {menCount === 1 ? "Person" : "People"}
              </div>
            </div>
          }
          arrow={false}
        >
          <div
            className={classNames(
              "flex flex-col items-center gap-4",
              focusBar === 1 || mouseLeave ? "opacity-100" : "opacity-20",
            )}
          >
            <img
              src={IMAGE_MEN}
              style={{
                height: `${menHeight}px`,
                minHeight: `${baseHeight}px`,
              }}
              onMouseEnter={() => {
                setFocusBar(1);
                setMouseLeave(false);
              }}
            />
            <p className="font-poppins text-sm text-purple-500">{menPercentage || 0}%</p>
          </div>
        </TooltipWrapper>

        <TooltipWrapper
          color="white"
          content={
            <div className="space-y-1 text-xs text-black">
              <div className="font-semibold capitalize">Other</div>
              <div>
                {otherCount || 0} {otherCount === 1 ? "Person" : "People"}
              </div>
            </div>
          }
          arrow={false}
        >
          <div
            className={classNames(
              "flex flex-col items-center gap-4",
              focusBar === 2 || mouseLeave ? "opacity-100" : "opacity-20",
            )}
          >
            <img
              src={IMAGE_OTHER}
              style={{
                height: `${otherHeight}px`,
                minHeight: `${baseHeight}px`,
              }}
              onMouseEnter={() => {
                setFocusBar(2);
                setMouseLeave(false);
              }}
            />
            <p className="font-poppins text-sm text-purple-500">{otherPercentage || 0}%</p>
          </div>
        </TooltipWrapper>

        <TooltipWrapper
          color="white"
          content={
            <div className="space-y-1 text-xs text-black">
              <div className="font-semibold capitalize">Unknown</div>
              <div>
                {unknownCount || 0} {unknownCount === 1 ? "Person" : "People"}
              </div>
            </div>
          }
          arrow={false}
        >
          <div
            className={classNames(
              "flex flex-col items-center gap-4",
              focusBar === 3 || mouseLeave ? "opacity-100" : "opacity-20",
            )}
          >
            <img
              src={IMAGE_UNKNOWN}
              style={{
                height: `${unknownHeight}px`,
                minHeight: `${baseHeight}px`,
              }}
              onMouseEnter={() => {
                setFocusBar(3);
                setMouseLeave(false);
              }}
            />
            <p className="font-poppins text-sm text-purple-500">{unknownPercentage || 0}%</p>
          </div>
        </TooltipWrapper>
      </div>
    );
  };
  const renderTitle = () => {
    return (
      <div className="inline-flex min-w-[262px] flex-wrap items-center gap-[36px] md:flex-nowrap">
        <div className="inline-flex items-center gap-2">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#FF8E8E",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Woman</p>
        </div>
        <div className="inline-flex items-center gap-2">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#83A5FF",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Man</p>
        </div>
        <div className="inline-flex items-center gap-2">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#D6D6D6",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Other</p>
        </div>
        <div className="inline-flex items-center gap-2">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#9b9b9b",
            }}
          />
          <p className="font-poppins text-xs text-gray-700">Unknown</p>
        </div>
      </div>
    );
  };
  return (
    <div className={classNames("mb-[26px] flex w-full flex-col items-center", wrapperClass)}>
      <DiversityFilterWrapper
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        excludeTabs={excludeTabs}
      >
        {renderTitle()}
        <Spacer height="26px" />
        {renderChart()}
      </DiversityFilterWrapper>
    </div>
  );
}
