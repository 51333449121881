import { throttle } from "lodash";
import { useState, useEffect, useCallback } from "react";

export default function useThrottle<T>(value: T, delay: number) {
  const [delayedValue, setDelayedValue] = useState<T>(value);
  const throttledFunc = useCallback(
    throttle((value) => setDelayedValue(value), delay),
    [setDelayedValue],
  );
  useEffect(() => {
    throttledFunc(value);
  }, [value, delay]);

  return delayedValue;
}
