import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import logo from "./img/logo.svg";
import TopNav from "./TopNav";
import ErrorBoundary from "../components/ErrorBoundary";
import useDeviceType from "../hooks/useDeviceType";
import classNames from "classnames";

function WelcomeLayout() {
  const { isMobile } = useDeviceType();

  return (
    <>
      {!isMobile && (
        <header className="giboo-nav-container">
          <nav className="giboo-nav flex items-center">
            <a href="/">
              <div className="flex items-center">
                <img src={logo} alt="Giboo" width="51" height="31" />
                <span className="logo-label">Giboo</span>
              </div>
            </a>
            <div className="grow">
              <TopNav />
            </div>
          </nav>
        </header>
      )}
      <ErrorBoundary>
        <div className={classNames(isMobile ? "mt-6" : "giboo-body")}>
          <Outlet />
          {!isMobile && <Footer />}
        </div>
      </ErrorBoundary>
    </>
  );
}
export default WelcomeLayout;
