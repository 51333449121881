import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSearchQueryFromOnboardingData } from "../../app/onboardingSlice";
import useFunderSolicitateGrants from "../../hooks/funder/useFunderSolicitateGrants";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";
import { queryMaskForCurrentGrantSearchWithSpecificFunder } from "../../hooks/useGibooStructureSearch";
import useOnboardingData from "../../hooks/useOnboarding";
import NoInformation from "../../pages/donor-detail-view/components/NoInformation";
import {
  GrantSearchResult,
  SearchQuery,
  getURLSearchParamsFromSearchQuery,
} from "../../types/search";
import { scrollBarClass } from "../../utils/classes";
import { isActiveGrant } from "../../utils/grant";
import { ClickToScrollWrapper } from "../ClickToScrollWrapper";
import DonorSolicitateGrants from "../dashboard/DonorSolicitateGrants";
import LoadingPhilanthropy from "../LoadingPhilanthropy";
import PlainButton from "../PlainButton";

interface Iprops {
  funder_id: string;
  query: SearchQuery;
  compact?: boolean;
}
function FunderSolicitedGrantOpportunities({ funder_id, query, compact, ...props }: Iprops) {
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const { data: funder } = useFunderSearchResult(funder_id);
  const { data: solicitateGrants, isLoading: loading } = useFunderSolicitateGrants(funder_id);
  const [activeGrants, setActiveGrants] = useState<GrantSearchResult[]>([]);
  const handleViewAll = useCallback(() => {
    navigate(
      `/search?${getURLSearchParamsFromSearchQuery(
        onboardingData,
        {
          ...query,
          ...queryMaskForCurrentGrantSearchWithSpecificFunder,
          type: ["specific_funder_grant"],
          donor_id: funder_id,
        },
        6,
      ).toString()}`,
    );
  }, [onboardingData, query, funder_id]);

  const handleSearchGrantWithMyOnboardingData = useCallback(() => {
    navigate(
      `/search?${getURLSearchParamsFromSearchQuery(
        onboardingData,
        getSearchQueryFromOnboardingData(onboardingData),
        2,
      ).toString()}`,
    );
  }, [onboardingData]);
  useEffect(() => {
    setActiveGrants(
      solicitateGrants.filter(
        (g: GrantSearchResult) => g.type === "grant-page" || isActiveGrant(g),
      ),
    );
  }, [solicitateGrants]);
  return (
    <>
      <div>
        {loading ? (
          <LoadingPhilanthropy loading={loading} max={1} wrapperClass="mt-3 mb-5" />
        ) : activeGrants.length > 0 ? (
          <ClickToScrollWrapper disabled={activeGrants.length <= 3}>
            <div
              className={classNames(
                activeGrants.length > 3 && scrollBarClass,
                "mb-3 max-h-[850px] overflow-y-auto",
              )}
            >
              <DonorSolicitateGrants solicitateGrants={activeGrants} query={query} />
            </div>
          </ClickToScrollWrapper>
        ) : (
          <NoInformation
            wrapperClass="!py-4"
            content={
              <div className="flex flex-col items-center gap-2">
                <h5 className="text-sm text-gray-700">Looking for grant opportunities?</h5>
                <PlainButton
                  className="!px-0 !pt-0 !text-sm"
                  id="btn-view"
                  handleOnClick={handleSearchGrantWithMyOnboardingData}
                  label="Click to explore potential grant opportunities for your organization"
                  underline
                />
              </div>
            }
          />
        )}
      </div>
      <div>
        {!loading && funder && solicitateGrants.length > 0 && (
          <div className="group/viewall flex h-[48px] cursor-pointer justify-center rounded-b  bg-gray-100 hover:bg-gray-100">
            <PlainButton
              id="btn-grant-viewall"
              className="!text-sm !text-purple-500 group-hover/viewall:!font-semibold  "
              label={`View all (${solicitateGrants.length})`}
              handleOnClick={() => handleViewAll()}
            />
          </div>
        )}
      </div>
    </>
  );
}
export default FunderSolicitedGrantOpportunities;
