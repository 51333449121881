import { useCallback } from "react";
import {
  IProjectLetter,
  IProjectLetterCreateRequest,
  IProjectLetterUpdateRequest,
} from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
interface ProjectLetterState {
  data: IProjectLetter;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  update: (req: IProjectLetterUpdateRequest) => Promise<IProjectLetter | undefined>;
  deleteThis: () => Promise<boolean>;
  revalidate: () => Promise<IProjectLetter | undefined>;
}
const defaultProjectLetter: IProjectLetter = {
  _id: "",
  type: "",
  subtask_id: "",
  updated_at: "",
  created_at: "",
  subject: "",
  contact: [],
  send_to: [],
  bcc: [],
  cc: [],
  content: "",
  sender: "",
};
const getProjectLetter = async (id: string) => {
  if (!id) return new Promise<IProjectLetter>((resolve, reject) => reject());
  return axios
    .get(process.env.REACT_APP_API_URL + `/api/v2/projects/letter/${id}`, config)
    .then((res) => res.data as IProjectLetter);
};
const createProjectLetter = async (req: IProjectLetterCreateRequest) => {
  return axios
    .post(process.env.REACT_APP_API_URL + `/api/v2/projects/letter`, req, config)
    .then((res) => res.data as IProjectLetter);
};
const updateProjectLetter = async (
  project_id: string,
  id: string,
  req: IProjectLetterUpdateRequest,
) => {
  if (!id) return new Promise<IProjectLetter>((resolve, reject) => reject());
  return axios
    .put(process.env.REACT_APP_API_URL + `/api/v2/projects/letter/${id}`, req, config)
    .then((res) => res.data as IProjectLetter);
};
const deleteProjectLetter = async (id: string) => {
  if (!id) return new Promise<boolean>((resolve, reject) => reject());
  return axios
    .delete(process.env.REACT_APP_API_URL + `/api/v2/projects/letter/${id}`, config)
    .then((res) => res.data as boolean);
};
function useLetter(letter_id?: string): ProjectLetterState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/letter/${letter_id}`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectLetter>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as IProjectLetter);
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectLetter>(
    letter_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const update = useCallback(
    async (req: IProjectLetterUpdateRequest) => {
      if (!data) return;
      const options = {
        optimisticData: { ...data, ...req },
        rollbackOnError: true,
      };
      return mutate<IProjectLetter>(
        url,
        axios.put(url, req, config).then((response) => response.data),
        options,
      );
    },
    [url, data],
  );

  const deleteThis = useCallback(async () => {
    if (letter_id)
      return deleteProjectLetter(letter_id).then((res) => {
        if (res && data)
          mutate(process.env.REACT_APP_API_URL + `/api/v2/projects/subtask/${data.subtask_id}`);
        return res;
      });
    else return new Promise<boolean>((resolve, reject) => reject());
  }, [letter_id, data]);
  const revalidate = useCallback(() => mutate<IProjectLetter>(url), [url]);
  return {
    data: data || defaultProjectLetter,
    isLoading,
    error,
    isValidating,
    update,
    deleteThis,
    revalidate,
  };
}
export { getProjectLetter, createProjectLetter, updateProjectLetter, defaultProjectLetter };
export default useLetter;
