import { ReactElement, useCallback, useEffect, useState } from "react";
import { TaxonomyConcept } from "../../../types/taxonomy";
import FilterBase from "./FilterBase";
import useStars, { SearchOptionsType, getStarKey } from "../../../hooks/useStars";
import {
  ISearchParamNullable,
  getObjectFromURLSearchParams,
  getURLSearchParamsFromSearchParam,
} from "../../../types/search";
import { useNavigate, useSearchParams } from "react-router-dom";
import { prettierTaxonomyString } from "../../../utils/philanthropy";
import SearchableDropdown from "../../dropdown/SearchableDropdown";
import { allTaxonomyAutoComplete } from "../../../utils/autoComplete";
import TagSelector from "../../TagSelector";
import PlainButton from "../../PlainButton";
import TagInfo from "../../TagInfo";
import useExcludeds, { ExcludeSearchOptionsType, getExcludedKey } from "../../../hooks/useExclude";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import { useRecoilValue } from "recoil";
import { searchSession } from "../../../app/recoilStore";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import useTaggingOperatorResult from "../../../hooks/search/useTaggingOperatorResult";
import useTaggingResult from "../../../hooks/search/useTaggingResult";

interface KeywordFilterProps {
  repr: (v: TaxonomyConcept[] | undefined) => string | ReactElement;
  selected?: (v: TaxonomyConcept[] | undefined) => boolean;
  downIcon?: boolean;
  panelWidth?: string;
  panelMaxHeight?: string;
  disabled?: boolean;
}
function KeywordFilter({ ...props }: KeywordFilterProps) {
  const searchSessionForMixpanel = useRecoilValue(searchSession);
  const mxEvent = useGibooMixpanel(undefined, true);
  const session = useRecoilValue(searchSession);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [diffQueue, setDiffQueue] = useState<ISearchParamNullable[]>([]);
  const [taxonomyQuery, setTaxonomyQuery] = useState<string>("");
  const [hideTaxonomySearch, setHideTaxonomySearch] = useState<boolean>(true);
  const [taxonomy, setTaxonomy] = useState<TaxonomyConcept[]>([]);
  const [taxonomySubs, setTaxonomySubs] = useState<TaxonomyConcept[]>([]);
  const { stars, setStars, isStar, isAllStar } = useStars();
  const { excludes, setExcludes, isExcluded } = useExcludeds();
  const {
    data: res,
    currentTextQuery,
    isLoading: taggingLoading,
    error: taggingError,
  } = useTaggingResult(searchParams.get("text_query") || "", searchParams.get("mission") || "");
  const {
    data: taggingOperator,
    currentTextQuery: taggingOperatorCurrentTextQuery,
    isLoading: taggingOperatorLoading,
    error: taggingOperatorError,
  } = useTaggingOperatorResult(
    searchParams.get("text_query") || "",
    searchParams.get("mission") || "",
    currentTextQuery,
    process.env.REACT_APP_DISABLE_TAGGING_OPERATOR === "TRUE" ? undefined : res,
  );
  useEffect(() => {
    const _obj = getObjectFromURLSearchParams(searchParams, taggingOperator);
    const obj = { ..._obj, ...prettierTaxonomyString(_obj) };
    setTaxonomy([
      ...obj.focus_area.map((t) => ({ type: "f", label: t, object_id: "" })),
      ...obj.beneficiary.map((t) => ({ type: "b", label: t, object_id: "" })),
      ...obj.program.map((t) => ({ type: "p", label: t, object_id: "" })),
    ]);
    setTaxonomySubs([
      ...obj.focus_area_subs.map((t) => ({ type: "f", label: t, object_id: "" })),
      ...obj.beneficiary_subs.map((t) => ({ type: "b", label: t, object_id: "" })),
      ...obj.program_subs.map((t) => ({ type: "p", label: t, object_id: "" })),
    ]);
    setStars([...(obj.stars || [])]);
    setExcludes([...(obj.excludes || [])]);
  }, [searchParams, setSearchParams, taggingOperator]);
  const updateFilter = useCallback(
    (diff: ISearchParamNullable) => {
      setDiffQueue((prev) => [...prev, diff]);
    },
    [setDiffQueue],
  );
  useEffect(() => {
    if (diffQueue.length === 0) return;
    const diff = diffQueue[0];
    const done_tagging = searchParams.get("done_tagging") === "true" ? true : false;
    const _obj = getObjectFromURLSearchParams(searchParams);
    if (!done_tagging) return;
    const obj = {
      ..._obj,
      ...diff,
    };
    if (
      getURLSearchParamsFromSearchParam(obj).toString() !==
      getURLSearchParamsFromSearchParam(_obj).toString()
    ) {
      const _updated_fields = [
        ...Object.keys(diff),
        ...(diff.updated_fields?.filter((i) => !Object.keys(diff).includes(i)) || []),
      ]
        .sort((a, b) => a.localeCompare(b))
        .reduce(
          (prev, cur) => (prev.length > 0 && prev[prev.length - 1] === cur ? prev : [...prev, cur]),
          [] as string[],
        );
      navigate(
        `/search?${getURLSearchParamsFromSearchParam(
          {
            ...obj,
            updated_fields: _updated_fields,
          },
          true,
        ).toString()}`,
      );
    }
    setDiffQueue((prev) => prev.slice(1));
  }, [diffQueue, searchParams, setDiffQueue]);

  const flipStar = useCallback(
    (key: SearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      updateFilter({
        stars: stars.includes(starKey) ? stars.filter((i) => i !== starKey) : [...stars, starKey],
        updated_fields: ["focus_area", "beneficiary", "program"].includes(key) ? [key] : undefined,
      });
    },
    [stars, updateFilter, getStarKey],
  );
  const setStar = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      const excludeKey = getExcludedKey(key, item);
      mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
        session: searchSessionForMixpanel,
        searchType: searchParams.get("type"),
        filterName: key as "focus_area" | "beneficiary" | "program",
        filterValue: item,
        filterAction: "set must have",
      });
      updateFilter({
        stars: [...stars.filter((s) => s !== starKey), starKey],
        excludes: excludes.filter((s) => s !== excludeKey),
        updated_fields: ["focus_area", "beneficiary", "program"].includes(key) ? [key] : undefined,
      });
    },
    [stars, excludes, updateFilter, getStarKey, getExcludedKey, mxEvent],
  );
  const clearStar = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      const excludeKey = getExcludedKey(key, item);
      mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
        session: searchSessionForMixpanel,
        searchType: searchParams.get("type"),
        filterName: key as "focus_area" | "beneficiary" | "program",
        filterValue: item,
        filterAction: "set good to have",
      });
      updateFilter({
        stars: stars.filter((s) => s !== starKey),
        excludes: excludes.filter((s) => s !== excludeKey),
        updated_fields: ["focus_area", "beneficiary", "program"].includes(key) ? [key] : undefined,
      });
    },
    [stars, excludes, updateFilter, getStarKey, getExcludedKey, mxEvent],
  );
  const clearAllStar = useCallback(
    (key: SearchOptionsType) => {
      const starKey = getStarKey(key);
      updateFilter({
        stars: stars.filter((prevItem) => !prevItem.startsWith(starKey)),
        updated_fields: ["focus_area", "beneficiary", "program"].includes(key) ? [key] : undefined,
      });
    },
    [stars, updateFilter, getStarKey],
  );
  const addAllStar = useCallback(
    (key: SearchOptionsType, item: string[]) => {
      const starKeys = item.map((i) => getStarKey(key, i));
      updateFilter({
        stars: [...stars.filter((prevItem) => !starKeys.includes(prevItem)), ...starKeys],
        updated_fields: ["focus_area", "beneficiary", "program"].includes(key) ? [key] : undefined,
      });
    },
    [stars, updateFilter, getStarKey],
  );
  const flipAllStar = useCallback(
    (key: SearchOptionsType, item: string[]) => {
      if (isAllStar(key, item)) clearAllStar(key);
      else addAllStar(key, item);
    },
    [stars, updateFilter, getStarKey, isAllStar, clearAllStar, addAllStar],
  );
  const setExcluded = useCallback(
    (key: ExcludeSearchOptionsType, item?: string) => {
      const starKey = getStarKey(key, item);
      const excludeKey = getExcludedKey(key, item);
      updateFilter({
        stars: stars.filter((s) => s !== starKey),
        excludes: [...excludes.filter((s) => s !== excludeKey), excludeKey],
        updated_fields: ["focus_area", "beneficiary", "program"].includes(key) ? [key] : undefined,
      });
      mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
        session: searchSessionForMixpanel,
        searchType: searchParams.get("type"),
        filterName: key as "focus_area" | "beneficiary" | "program",
        filterValue: item,
        filterAction: "set exclude",
      });
    },
    [stars, excludes, updateFilter, getStarKey, getExcludedKey, mxEvent],
  );
  const renderKeywordsCategories = () => {
    return (
      <div className="mt-[-8px] flex flex-col gap-1 px-3">
        <div className="z-[6] w-full pb-0">
          <TagSelector<TaxonomyConcept>
            onCheck={(t, check) => {
              mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                session: searchSessionForMixpanel,
                searchType: searchParams.get("type"),
                filterName:
                  t.type === "f"
                    ? "focus_area"
                    : t.type === "b"
                    ? "beneficiary"
                    : t.type === "p"
                    ? "program"
                    : undefined,
                filterValue: t.label,
                filterAction: check ? "check" : "uncheck",
              });
            }}
            className="z-[6]"
            globalOpen={true}
            noIndent
            id="keyword"
            isPrioritySelector
            name="Search tags"
            renderInfo={() => (
              <TagInfo type="OTHERS" value={"SEARCH_KEYWORDS"} placements="rightStart" />
            )}
            value={taxonomy}
            setValue={(ts) => {
              updateFilter({
                focus_area: ts.filter((t) => t.type === "f").map((t) => t.label),
                beneficiary: ts.filter((t) => t.type === "b").map((t) => t.label),
                program: ts.filter((t) => t.type === "p").map((t) => t.label),
              });
            }}
            subs={taxonomySubs}
            setSubs={(ts) => {
              updateFilter({
                focus_area_subs: ts.filter((t) => t.type === "f").map((t) => t.label),
                beneficiary_subs: ts.filter((t) => t.type === "b").map((t) => t.label),
                program_subs: ts.filter((t) => t.type === "p").map((t) => t.label),
              });
            }}
            setValueAndSubs={(ts, tss) =>
              updateFilter({
                focus_area: ts.filter((t) => t.type === "f").map((t) => t.label),
                beneficiary: ts.filter((t) => t.type === "b").map((t) => t.label),
                program: ts.filter((t) => t.type === "p").map((t) => t.label),
                focus_area_subs: tss.filter((t) => t.type === "f").map((t) => t.label),
                beneficiary_subs: tss.filter((t) => t.type === "b").map((t) => t.label),
                program_subs: tss.filter((t) => t.type === "p").map((t) => t.label),
              })
            }
            size="sm"
            matched
            seeAll
            getColor={(item: TaxonomyConcept) => {
              switch (item.type) {
                case "f":
                  return "yellow";
                case "b":
                  return "blue";
                case "p":
                  return "green";
                default:
                  return "gray";
              }
            }}
            getKey={(item: TaxonomyConcept) => `${item.type}_${item.label}`}
            getLabel={(item: TaxonomyConcept) => item.label}
            flipStar={(item: TaxonomyConcept) =>
              flipStar(
                item.type === "f" ? "focus_area" : item.type === "b" ? "beneficiary" : "program",
                item.label,
              )
            }
            setStar={(item: TaxonomyConcept) =>
              setStar(
                item.type === "f" ? "focus_area" : item.type === "b" ? "beneficiary" : "program",
                item.label,
              )
            }
            isStar={(item: TaxonomyConcept) =>
              isStar(
                item.type === "f" ? "focus_area" : item.type === "b" ? "beneficiary" : "program",
                item.label,
              )
            }
            isAllStar={(items: TaxonomyConcept[]) =>
              isAllStar(
                "focus_area",
                items.filter((i) => i.type === "f").map((i) => i.label),
              ) &&
              isAllStar(
                "beneficiary",
                items.filter((i) => i.type === "b").map((i) => i.label),
              ) &&
              isAllStar(
                "program",
                items.filter((i) => i.type === "p").map((i) => i.label),
              )
            }
            flipAllStar={(items: TaxonomyConcept[]) => {
              flipAllStar(
                "focus_area",
                items.filter((i) => i.type === "f").map((i) => i.label),
              );
              flipAllStar(
                "beneficiary",
                items.filter((i) => i.type === "b").map((i) => i.label),
              );
              flipAllStar(
                "program",
                items.filter((i) => i.type === "p").map((i) => i.label),
              );
            }}
            clearStar={(item: TaxonomyConcept) =>
              clearStar(
                item.type === "f" ? "focus_area" : item.type === "b" ? "beneficiary" : "program",
                item.label,
              )
            }
            clearAllStar={() => clearAllStar("keyword")}
            isExcluded={(item: TaxonomyConcept) =>
              isExcluded(
                item.type === "f" ? "focus_area" : item.type === "b" ? "beneficiary" : "program",
                item.label,
              )
            }
            setExcluded={(item: TaxonomyConcept) =>
              setExcluded(
                item.type === "f" ? "focus_area" : item.type === "b" ? "beneficiary" : "program",
                item.label,
              )
            }
            clearExcluded={(item: TaxonomyConcept) =>
              clearStar(
                item.type === "f" ? "focus_area" : item.type === "b" ? "beneficiary" : "program",
                item.label,
              )
            }
            hideCount
            hideCheckbox
            hideArrow
            removable
            disabled={props.disabled}
          >
            <div className="">
              {hideTaxonomySearch ? (
                <div className="self-start">
                  <PlainButton
                    id={`btn-hide-taxonomy-search`}
                    label={"Add relevant search tags from our classification dictionary"}
                    className={"!text-sm"}
                    leftIconClass="fa-regular fa-plus"
                    handleOnClick={() => setHideTaxonomySearch(false)}
                  />
                </div>
              ) : (
                <SearchableDropdown<TaxonomyConcept>
                  from_for_mixpanel={"search"}
                  mixpanel_event={MIXPANEL_EVENTS_V2.search_tag[""]}
                  asMixPanelProperty={(t) => ({
                    type:
                      t.type === "f"
                        ? "focus_area"
                        : t.type === "b"
                        ? "beneficiary"
                        : t.type === "p"
                        ? "program"
                        : undefined,
                    name: t.label,
                    search_session: session,
                  })}
                  id="taxonomy"
                  getColor={(item: TaxonomyConcept) => {
                    switch (item.type) {
                      case "f":
                        return "yellow";
                      case "b":
                        return "blue";
                      case "p":
                        return "green";
                      default:
                        return "gray";
                    }
                  }}
                  query={taxonomyQuery}
                  setQuery={setTaxonomyQuery}
                  autoCompleteThrottleMilliseconds={300}
                  autoCompleteFunction={allTaxonomyAutoComplete}
                  addItem={(item: TaxonomyConcept) => {
                    // setTaxonomy((prev) => [...prev, item]);
                    // setTaxonomySubs((prev) => [...prev, item]);
                    mxEvent(MIXPANEL_EVENTS_V2.search.filter.edit, {
                      session: searchSessionForMixpanel,
                      searchType: searchParams.get("type"),
                      filterName:
                        item.type === "f"
                          ? "focus_area"
                          : item.type === "b"
                          ? "beneficiary"
                          : item.type === "p"
                          ? "program"
                          : undefined,
                      filterValue: item.label,
                      filterAction: "check",
                    });
                    if (item.type === "f") {
                      updateFilter({
                        focus_area: [
                          ...taxonomy.filter((t) => t.type === "f").map((t) => t.label),
                          item.label,
                        ],
                        focus_area_subs: [
                          ...taxonomySubs.filter((t) => t.type === "f").map((t) => t.label),
                          item.label,
                        ],
                      });
                    } else if (item.type === "b") {
                      updateFilter({
                        beneficiary: [
                          ...taxonomy.filter((t) => t.type === "b").map((t) => t.label),
                          item.label,
                        ],
                        beneficiary_subs: [
                          ...taxonomySubs.filter((t) => t.type === "b").map((t) => t.label),
                          item.label,
                        ],
                      });
                    } else if (item.type === "p") {
                      updateFilter({
                        program: [
                          ...taxonomy.filter((t) => t.type === "p").map((t) => t.label),
                          item.label,
                        ],
                        program_subs: [
                          ...taxonomySubs.filter((t) => t.type === "p").map((t) => t.label),
                          item.label,
                        ],
                      });
                    }
                  }}
                  largeInput={false}
                  renderItem={(item: TaxonomyConcept) => item.label}
                  getKey={(item: TaxonomyConcept) => `${item.type}_${item.label.toLowerCase()}`}
                  excludeKeys={[...taxonomySubs.map((t) => `${t.type}_${t.label.toLowerCase()}`)]}
                  placeholder={`Add relevant search tags from our classification dictionary`}
                  maxHeight="300px"
                  icon
                  disabled={props.disabled}
                  // wrapperClass="!w-[350px]"
                  size="md"
                  spinner
                  spinnerBackgroundColor="bg-gray-50"
                />
              )}
            </div>
          </TagSelector>
        </div>
      </div>
    );
  };
  return (
    <FilterBase<TaxonomyConcept[]>
      id="keyword"
      value={taxonomy}
      setValue={(v) => {
        if (v) setTaxonomy(v);
      }}
      {...props}
    >
      {({ open, close }) => <>{renderKeywordsCategories()}</>}
    </FilterBase>
  );
}
export default KeywordFilter;
