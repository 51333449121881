import { useEffect, useRef } from "react";

export const useTimeout = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    if (delay === null) return;
    const handler = () => savedCallback.current();
    const id = setTimeout(handler, delay);
    return () => clearTimeout(id);
  }, [delay]);
};
