import React, { createRef, useCallback, useEffect, useRef, useState } from "react";
import Spacer from "../../../components/Spacer";
import Button from "../../../components/tailwind/Button";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import OrgPeopleMemberType from "./pages/OrgPeopleMemberType";
import useUserDictionary, { setUserDictionaryValue } from "../../../hooks/useUserDictionary";
import useOrgDataForNPODashboard from "../../../hooks/useOrgDataForNPODashboard";
import OrgDiversityInfo from "./pages/OrgDiversityInfo";
import EthnicityInfo from "./pages/EthnicityInfo";
import useOrgProfile from "../../../hooks/useOrgProfile";
import GenderInfo from "./pages/GenderInfo";
import AgeInfo from "./pages/AgeInfo";
import SexualOrientationInfo from "./pages/SexualOrientationInfo";
import DisablityInfo from "./pages/DisablityInfo";
import ReligionInfo from "./pages/ReligionInfo";
import ProfessionInfo from "./pages/ProfessionInfo";
import FinalInfo from "./pages/FinalInfo";
import useGibooMixpanel from "../../../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../../../mixpanel/mixpanel";
import BackButton from "../../../components/BackButton";

const SIDE_MENU_CONSTANT = [
  {
    title: "Update organization people’s member type",
    value: 0,
  },
  {
    title: "Identify organization’s diversity information",
    value: 1,
    subMenu: [
      { title: "I. Ethnicity", value: 2 },
      { title: "II. Gender", value: 3 },
      { title: "III. Age", value: 4 },
      { title: "IV. Sexual orientation perspective", value: 5 },
      { title: "V. Disability perspective", value: 6 },
      { title: "VI. Religion", value: 7 },
      { title: "VII. Professional background", value: 8 },
    ],
  },
];
export default function DiversityDetail() {
  const mxEvent = useGibooMixpanel(undefined, true); // this is a navigated page and location.state already has 'from_for_mixpanel', so do not set from_for_mixpanel
  const [loading, setLoading] = useState<boolean>(false);
  const { npo_id, org_id, hasWritePermission } = useOrgDataForNPODashboard(undefined, true);
  const { data, update: profileUpdate, isLoading } = useOrgProfile(org_id);
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [tempIndex, setTempIndex] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const previousItemIndexRef = useRef<number | null>(null);
  const clickedNextRef = useRef<boolean | null>(null);
  useEffect(() => {
    mxEvent(MIXPANEL_EVENTS_V2.diversity[""].started);
  }, []);
  useEffect(() => {
    if (isLoading) return;
    setSelectedItem(data.diversity_saved_index || 0);
    setTempIndex(data.diversity_saved_index || 0);
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) return;
    scrollToTop();
  }, [isLoading, selectedItem]);
  const handleBack = () => {
    if (selectedItem > 0 && hasWritePermission) {
      setSelectedItem(selectedItem - 1);
      profileUpdate({ diversity_saved_index: selectedItem - 1 });
      setTempIndex(selectedItem - 1);
      if (clickedNextRef.current) {
        previousItemIndexRef.current = selectedItem;
        clickedNextRef.current = false;
      }
    }
  };
  const handleNext = () => {
    if (selectedItem >= 0 && hasWritePermission) {
      previousItemIndexRef.current = selectedItem;
      setSelectedItem(selectedItem + 1);
      profileUpdate({ diversity_saved_index: selectedItem + 1 });
      setTempIndex(selectedItem + 1);
    }
  };

  const scrollToTop = () => {
    const element = document.getElementById("diversity-scroll-container");
    if (element) {
      element.scrollTo(0, 0);
    }
  };
  const isBack =
    previousItemIndexRef.current !== null && previousItemIndexRef.current >= selectedItem;
  const renderItem = useCallback(() => {
    return [
      <OrgPeopleMemberType
        key={"org-people-member-type"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        hasWritePermission={hasWritePermission}
        isBack={isBack}
      />,
      <OrgDiversityInfo
        key={"org-diversity-type"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        isBack={isBack}
      />,
      <EthnicityInfo
        key={"org-ethnicity-type"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <GenderInfo
        key={"org-gender-type"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <AgeInfo
        key={"org-age-type"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <SexualOrientationInfo
        key={"org-orientation-type"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <DisablityInfo
        key={"org-disablity-type"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        isBack={isBack}
        org_id={org_id}
      />,
      <ReligionInfo
        key={"org-religion-type"}
        onBack={handleBack}
        onNext={handleNext}
        npo_id={npo_id}
        isBack={isBack}
        org_id={org_id}
      />,
      <ProfessionInfo
        key={"org-profession-type"}
        onBack={handleBack}
        onNext={() => {
          handleNext();
          profileUpdate({ diversity_saved_index: selectedItem, diversity_completed: true });
        }}
        npo_id={npo_id}
        org_id={org_id}
        isBack={isBack}
      />,
      <FinalInfo
        key={"org-profession-type"}
        onBack={handleBack}
        onNext={() => {
          profileUpdate({ diversity_saved_index: selectedItem, diversity_completed: true });
        }}
        npo_id={npo_id}
        org_id={org_id}
      />,
    ][selectedItem];
  }, [selectedItem, org_id, npo_id, isBack]);
  const renderRightContent = () => {
    return (
      <div
        className="h-full w-full overflow-y-scroll bg-gray-50 px-[60px] py-[30px]"
        id="diversity-scroll-container"
      >
        <div className="mb-5">
          <BackButton onClick={() => (location?.state?.showBack ? navigate(-2) : navigate("/"))} />
        </div>
        {renderItem()}
      </div>
    );
  };

  const renderLeftContent = () => {
    const percentage = Math.min((selectedItem / 10) * 100, 100).toFixed(0);
    return (
      <div className="h-full w-full bg-white p-5">
        <div className="flex flex-col gap-2">
          <h6 className="font-poppins text-xs text-gray-700">Progress</h6>
          <div className="inline-flex items-center gap-4">
            <div className="h-1 w-full max-w-[191px] rounded-full bg-purple-50">
              <div className="h-1 rounded-full bg-purple-500" style={{ width: `${percentage}%` }} />
            </div>
            <h6 className="font-poppins text-xs text-gray-700">{`${percentage}%`}</h6>
          </div>
        </div>
        <Spacer height="40px" />
        <div className="flex flex-col gap-3">
          {SIDE_MENU_CONSTANT.map((item, index) => {
            const isHeaderSelected = item.value === selectedItem;
            return (
              <div key={item.value} className="flex w-full max-w-[192px] flex-col gap-3 ">
                <div
                  className={classNames(
                    "inline-flex cursor-pointer  items-center gap-[10px] font-poppins text-sm font-semibold ",
                    isHeaderSelected
                      ? " text-purple-500 underline underline-offset-2"
                      : "text-gray-700",
                    !data.diversity_completed &&
                      (item.value > tempIndex || !hasWritePermission) &&
                      "!cursor-not-allowed",
                  )}
                  onClick={() => {
                    if (!hasWritePermission) return;
                    if (data.diversity_completed) {
                      setSelectedItem(item.value);
                    } else {
                      if (item.value <= tempIndex) {
                        setSelectedItem(item.value);
                      }
                    }
                  }}
                >
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.66652 13.8513C6.08626 13.7197 5.52897 13.502 5.01319 13.2053M9.33319 2.14868C10.6586 2.4514 11.842 3.19514 12.6896 4.25815C13.5372 5.32116 13.9988 6.64045 13.9988 8.00002C13.9988 9.35958 13.5372 10.6789 12.6896 11.7419C11.842 12.8049 10.6586 13.5486 9.33319 13.8513M3.05252 11.3953C2.68886 10.867 2.41265 10.2835 2.23452 9.66735M2.08252 7.00002C2.18919 6.36668 2.39452 5.76668 2.68252 5.21668L2.79519 5.01335M4.60452 3.05268C5.22829 2.62328 5.92807 2.31649 6.66652 2.14868"
                        stroke="#5F5F5F"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>{" "}
                  {item.title}
                </div>
                <div className="ml-2 grid grid-cols-[1px_238px] ">
                  <div className="h-full w-[1px] bg-gray-300">
                    <div className="w-[1px] bg-purple-500" style={{ height: `${percentage}%` }} />
                  </div>
                  <div className="flex w-full max-w-[217px] flex-col gap-3">
                    {item?.subMenu?.map((item) => {
                      const isSelected = item.value === selectedItem;
                      return (
                        <div
                          key={item.value}
                          onClick={() => {
                            if (data.diversity_completed) {
                              setSelectedItem(item.value);
                            } else {
                              if (item.value <= tempIndex) {
                                setSelectedItem(item.value);
                              }
                            }
                          }}
                          className={classNames(
                            "cursor-pointer pl-4  font-poppins text-xs",
                            isSelected
                              ? "font-semibold text-purple-500 underline underline-offset-2"
                              : "text-gray-700",
                            !data.diversity_completed &&
                              (item.value > tempIndex || !hasWritePermission) &&
                              "!cursor-not-allowed",
                          )}
                        >
                          {item.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Spacer height="40px" />
        <Button
          label="Save & exit"
          id="save_exit"
          disabled={!hasWritePermission}
          handleOnClick={() => {
            if (!hasWritePermission) return;
            profileUpdate({ diversity_saved_index: selectedItem });
            location?.state?.showBack ? navigate(-2) : navigate("/");
          }}
        />
      </div>
    );
  };
  return (
    <div className="h-[calc(100vh-58px)] w-full">
      <div className="grid  h-full grid-cols-[278px_1fr]">
        {/* Left content */}
        {renderLeftContent()}
        {/* Right content */}
        {renderRightContent()}
      </div>
    </div>
  );
}
