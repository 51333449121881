import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import classNames from "classnames";
import { UserView } from "../NpoLayout";
import useOrgDataForNPODashboard from "../../../../hooks/useOrgDataForNPODashboard";
import useTotalOrganizationMember from "../../../../hooks/useTotalOrganizationMember";
import OrganizationPeople from "../../organizational-profile/OrganizationPeople";
import useOnboardingData from "../../../../hooks/useOnboarding";
import NpoDiversityEthnicity from "../components/NpoDiversityEthnicity";
import NpoDiversityGender from "../components/NpoDiversityGender";
import NpoDiversityGeneration from "../components/NpoDiversityGeneration";
import NpoDiversitySexualOrientation from "../components/NpoDiversitySexualOrientation";
import NpoDiversityReligion from "../components/NpoDiversityReligion";
import NpoDiversityDisability from "../components/NpoDiversityDisability";
import NpoDiversityProfession from "../components/NpoDiversityProfession";
import NpoDiversity from "../components/NpoDiversity";
import { Divider } from "rsuite";
import NpoDiversityLocation from "../components/NpoDiversityLocation";
import GibooGradientDiv from "../../../../components/GibooGradientDiv";
import useOrgProfile from "../../../../hooks/useOrgProfile";
import { RestartAssessment } from "../../Home";
import PrimaryButton from "../../../../components/PrimaryButton";
import { AssessmentSetting } from "../../../../components/AssessmentSetting";
import { SuggestEditButton } from "../../../../components/SuggestEditButton";
import IMG_DIVERSITY_GROUP from "../../../../assets/diversity/diversity-mock.svg";
import IMG_DIVERSITY_DESC from "../../../../assets/diversity/diversity-mock-desc.svg";
import ContentHideWrapper from "../../../../components/ContentHideWrapper";
import ReviewAndEditCard from "../../../../components/ReviewAndEditCard";
function NpoDiversityPeople({
  isPrivateView,
  hasWritePermission,
  id,
  compact = false,
  inProgress = false,
  progressIndex,
  withoutLogin,
  isProfileClaim,
  showAll = false,
}: {
  isPrivateView?: boolean;
  hasWritePermission?: boolean;
  id?: string;
  compact?: boolean;
  inProgress?: boolean;
  progressIndex?: number;
  withoutLogin?: boolean;
  isProfileClaim?: boolean;
  showAll?: boolean;
}) {
  const [onboardingData] = useOnboardingData();
  const { org_id, npo_id, data: orgData } = useOrgDataForNPODashboard(id, isPrivateView);
  const { data: profile } = useOrgProfile(org_id);
  // const { data: members } = useTotalOrganizationMember(org_id);
  const navigate = useNavigate();

  const renderContent = () => {
    return (
      <div className={classNames("flex flex-col", compact ? "gap-5" : "gap-4")}>
        {(profile.hide_ethnicity !== true && progressIndex && 2 <= progressIndex) || showAll ? (
          <>
            <NpoDiversityEthnicity
              allowEdit={isPrivateView && hasWritePermission ? true : false}
              org_id={org_id}
              compact={compact}
              showStats={false}
              showInfo
            />
          </>
        ) : (
          <></>
        )}
        {(profile.hide_gender !== true && progressIndex && 3 <= progressIndex) || showAll ? (
          <>
            <NpoDiversityGender
              allowEdit={isPrivateView && hasWritePermission ? true : false}
              org_id={org_id}
              compact={compact}
              showStats={false}
              showInfo
            />
          </>
        ) : (
          <></>
        )}
        {(profile.hide_age !== true && progressIndex && 4 <= progressIndex) || showAll ? (
          <>
            <NpoDiversityGeneration
              allowEdit={isPrivateView && hasWritePermission ? true : false}
              org_id={org_id}
              compact={compact}
              showStats={false}
              showInfo
            />
          </>
        ) : (
          <></>
        )}

        {(profile.hide_sexual !== true && progressIndex && 5 <= progressIndex) || showAll ? (
          <>
            <NpoDiversitySexualOrientation
              allowEdit={isPrivateView && hasWritePermission ? true : false}
              org_id={org_id}
              compact={compact}
              showStats={false}
              showInfo
            />
          </>
        ) : (
          <></>
        )}
        {(profile.hide_disability !== true && progressIndex && 6 <= progressIndex) || showAll ? (
          <>
            <NpoDiversityDisability
              allowEdit={isPrivateView && hasWritePermission ? true : false}
              org_id={org_id}
              compact={compact}
              showStats={false}
              showInfo
            />
          </>
        ) : (
          <></>
        )}
        {(profile.hide_religion !== true && progressIndex && 7 <= progressIndex) || showAll ? (
          <>
            <NpoDiversityReligion
              allowEdit={isPrivateView && hasWritePermission ? true : false}
              org_id={org_id}
              compact={compact}
              showStats={false}
              showInfo
            />
          </>
        ) : (
          <></>
        )}
        {(profile.hide_profession !== true && progressIndex && 8 <= progressIndex) || showAll ? (
          <>
            <NpoDiversityProfession
              allowEdit={isPrivateView && hasWritePermission ? true : false}
              org_id={org_id}
              compact={compact}
              showStats={false}
              showInfo
            />
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };
  const renderPublicModeContent = () => {
    return (
      <>
        {isPrivateView && hasWritePermission && showAll && (
          <ReviewAndEditCard
            content={
              <>
                Enhance transparency by highlighting the diversity of your employees, board members,
                and volunteers. Providing this information can increase funding opportunities by
                65%.
              </>
            }
            wrapperClass=""
            label="Add diversity information"
            handleOnClick={() => {
              navigate("/diversity", { state: { showBack: true } });
            }}
          />
        )}
        <div
          className={classNames(
            "flex flex-col",
            compact ? "gap-5" : "gap-4",
            isPrivateView &&
              hasWritePermission &&
              showAll &&
              "pointer-events-none select-none opacity-40",
          )}
        >
          <img src={IMG_DIVERSITY_GROUP} className="opacity-50" />
          <ContentHideWrapper
            id="diversity-assessment-mock"
            open={withoutLogin && isProfileClaim}
            messageTitle="Discover how Giboo represents your nonprofit’s diversity information!"
            messageDescription={
              <>
                Enhance transparency by highlighting the diversity of your employees, board members,
                and volunteers.{" "}
                <b className="text-purple-500">
                  Providing this information can increase funding opportunities by 65%.
                </b>
              </>
            }
          >
            <div className="min-h-fit">
              <img src={IMG_DIVERSITY_DESC} />
            </div>
          </ContentHideWrapper>
        </div>
      </>
    );
  };
  return (
    <div
      className={classNames(
        "flex flex-col gap-5 border-gray-300 bg-white p-5",
        !compact && "rounded border",
      )}
    >
      <div className="inline-flex items-center justify-between">
        <h4 className="font-poppins text-xl font-semibold text-gray-900">Diversity</h4>
        {/* {withoutLogin && isProfileClaim && <SuggestEditButton />} */}
        {isPrivateView && !showAll && (
          <>
            {inProgress ? (
              <PrimaryButton
                size="md"
                id={`btn-diversity-info`}
                disabled={!hasWritePermission}
                label={"Resume to add diversity information"}
                className="!h-[29px] !text-sm"
                leftIconClass={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M2.271 15.0968V3.90642C2.271 3.71335 2.33672 3.55154 2.46815 3.42101C2.59959 3.29048 2.76094 3.22567 2.95221 3.22657C3.14529 3.22657 3.30709 3.29138 3.43762 3.42101C3.56725 3.55154 3.63206 3.71335 3.63206 3.90642V15.0968C3.63206 15.2899 3.5668 15.4517 3.43626 15.5822C3.30573 15.7128 3.14393 15.7776 2.95085 15.7767C2.75777 15.7767 2.59642 15.7118 2.46679 15.5822C2.33717 15.4526 2.271 15.2908 2.271 15.0968ZM8.52972 14.7881L15.7878 10.448C15.9782 10.3365 16.116 10.1973 16.2012 10.0305C16.2864 9.86375 16.329 9.68744 16.329 9.50161C16.329 9.31579 16.2864 9.13948 16.2012 8.97269C16.116 8.8059 15.9782 8.66675 15.7878 8.55526L8.52972 4.21508C8.43364 4.16432 8.34072 4.1276 8.25098 4.10494C8.16034 4.08228 8.06969 4.07095 7.97904 4.07095C7.68444 4.07095 7.42655 4.17066 7.20537 4.37008C6.98419 4.56951 6.8736 4.83556 6.8736 5.16823V13.835C6.8736 14.1677 6.98419 14.4337 7.20537 14.6331C7.42655 14.8326 7.68398 14.9323 7.97768 14.9323C8.07014 14.9323 8.16079 14.9209 8.24962 14.8983C8.34027 14.8756 8.43364 14.8394 8.52972 14.7895M8.2333 13.3115V5.69172L14.6008 9.50161L8.2333 13.3115Z"
                      fill="white"
                    />
                  </svg>
                }
                handleOnClick={() => navigate("/diversity/detail")}
              />
            ) : (
              <AssessmentSetting
                hasWritePermission={hasWritePermission}
                type="diversity"
                org_id={org_id}
                npo_id={id}
              />
            )}
          </>
        )}
      </div>
      {withoutLogin && isProfileClaim
        ? renderPublicModeContent()
        : isPrivateView && hasWritePermission && showAll
        ? renderPublicModeContent()
        : renderContent()}
    </div>
  );
}
export default NpoDiversityPeople;
