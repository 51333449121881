import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import onboardingSlice, {
  fetchAndSelectOrgAsync,
  getOnboardingStatus,
  selectOrgs,
} from "../app/onboardingSlice";
import { RootState, useAppDispatch, useAppSelector } from "../app/store";
import { Org } from "../types/org";
import useUser from "./useUser";

const useOrgs = (): {
  orgs: Org[];
  setOrg: (id: string, redirect?: boolean) => void;
  selected: string;
} => {
  const browserLocation = useLocation();
  const [user] = useUser();
  const { selected } = useAppSelector((state: RootState) => getOnboardingStatus(state));
  const orgs = useAppSelector((state: RootState) => selectOrgs(state));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setOrg = useCallback(
    (id: string, redirectToOnboarding = true) => {
      if (user?._id)
        Promise.resolve(dispatch(onboardingSlice.actions.loading())).then(() =>
          Promise.resolve(dispatch(fetchAndSelectOrgAsync(id))).then(() => {
            if (process.env.REACT_APP_ROUTER !== "FUNDER") {
              if (redirectToOnboarding) navigate("/onboarding");
              else if (browserLocation.pathname.startsWith("/onboarding")) navigate("/");
            }
          }),
        );
    },
    [dispatch, user],
  );
  return { orgs, setOrg, selected };
};
export default useOrgs;
