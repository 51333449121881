import React, { useEffect, useState } from "react";
import useSWR from "swr";
import useOnboardingData from "../hooks/useOnboarding";
import useUser from "../hooks/useUser";
import { useNavigate } from "react-router-dom";
import "./css/recommendation.scss";
import classNames from "classnames";

import {
  GrantSearchResult,
  SearchQuery,
  getSearchQueryFromISearchParam,
  getURLSearchParamsFromSearchQuery,
} from "../types/search";
import PhilanthropicInformationFlexWrap from "./PhilanthropicInformationFlexWrap";
import { getISearchParamFromOnboardingData } from "../app/onboardingSlice";
import { getGrantTitle } from "../utils/grant";
import axios from "axios";
import config from "../api";
import { IGrant } from "../types/grant";
import useGrantsFromSameFunder from "../hooks/grant/useGrantsFromSameFunder";
import GrantCardSimple from "./search/GrantCardSimple";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";

function RecGrantsFromSameFunder({
  isLoading,
  recommendationQuery,
  grantType,
  exclude,
  keyId,
  useUloc,
  ...props
}: {
  keyId?: string;
  grantType: string;
  useUloc?: boolean;
  isLoading?: boolean;
  recommendationQuery?: SearchQuery;
  exclude?: string[];
}) {
  const mxEvent = useGibooMixpanel();
  const [user] = useUser();
  const navigate = useNavigate();
  const [onboardingData] = useOnboardingData();
  const [controller, setController] = useState<AbortController>(new AbortController());
  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const query: SearchQuery = recommendationQuery
    ? recommendationQuery
    : getSearchQueryFromISearchParam(
        user?._id || "",
        onboardingData,
        getISearchParamFromOnboardingData(onboardingData),
      );

  const { data: recommendation, isLoading: recommendationLoading } = useGrantsFromSameFunder({
    use: true,
    query,
    keyId,
    grantType,
  });
  return (
    <div
      className={classNames({
        "loading-light-shimmer-on flex flex-col gap-y-1 divide-y divide-gray-300":
          recommendationLoading,
      })}
    >
      {!recommendation || (recommendation.length === 0 && recommendationLoading)
        ? Array.from({ length: 5 }, (x, i) => i).map((_, i) => (
            <div
              key={`${i}_item`}
              className="loading-light-shimmer-child flex h-[142px] w-full flex-col gap-[16px]  rounded border border-gray-300 bg-white px-[28px] py-[16px]"
            >
              <span className="flex justify-between gap-[10px]">
                <div className="h-[28px] w-full" />
                <div className="h-[14px] w-[30px]" />
              </span>
              <div className="h-[14px] w-1/2" />
              <div className="h-[48px] w-full" />
            </div>
          ))
        : recommendation
            ?.filter((item) => !exclude || !exclude.includes(item._id))
            .map((grant, index) => (
              <GrantCardSimple
                wrapperClass="p-5 border-t first:border-t-0 border-gray-300"
                key={"rec-grant-from-same-funder-" + index}
                grant={grant}
                query={query}
                onClick={() => {
                  mxEvent(
                    MIXPANEL_EVENTS_V2.detail_view.other_grants_from_the_same_funder.clicked,
                    {
                      targetType: grant.type === "grant-page" ? "virtual_grant" : "grant",
                      targetId: grant._id,
                      targetName: grant.name,
                      location: location.pathname,
                    },
                  );
                  navigate(`/grants/${grant._id}`);
                }}
              />
            ))}
    </div>
  );
}

export default RecGrantsFromSameFunder;
