import classNames from "classnames";
import { memo } from "react";

function LoadingPhilanthropy({
  loading,
  max = 3,
  relevant,
  wrapperClass,
}: {
  loading: boolean;
  max?: number;
  relevant?: boolean;
  wrapperClass?: string;
}) {
  return (
    <div className={classNames("flex flex-col gap-3", wrapperClass)}>
      {Array.from({ length: max }, (x, i) => i).map((_, i) => (
        <div
          key={i}
          className="loading-light-shimmer-on flex flex-col overflow-hidden rounded border border-gray-300 bg-white px-4 py-3"
        >
          <div className="loading-light-shimmer-child self-start">
            <div className="mb-3 flex h-7 !min-w-[250px] items-center gap-1 rounded-[20px] border border-purple-500 bg-gray-50 px-3 text-purple-500"></div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
              <div className="loading-light-shimmer-child cursor-pointer hover:text-purple-500">
                <div className="!min-w-[50px] text-base font-semibold"></div>
              </div>

              <div
                className={classNames("loading-light-shimmer-child flex items-center space-x-2", {
                  "divide-x divide-gray-300": !loading,
                })}
              >
                <div className="!min-w-[130px]"></div>
              </div>
            </div>
            <div className="loading-light-shimmer-child">
              <div className="flex !min-h-[36px] !min-w-[100px] items-center gap-4"></div>
            </div>
          </div>
          <div className="loading-light-shimmer-child mt-3 flex gap-3">
            <div className="!min-h-[28px] !min-w-[120px]"></div>
            <div className="!min-h-[28px] !min-w-[120px]"></div>
            <div className="!min-h-[28px] !min-w-[120px]"></div>
          </div>
          {relevant && (
            <div className="loading-light-shimmer-child mt-3">
              <div className="!min-h-[21px] !min-w-[300px]"></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
const MemoizedLoadingPhilanthropy = memo(LoadingPhilanthropy);
export default MemoizedLoadingPhilanthropy;
