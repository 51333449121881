import { useEffect, useState } from "react";
import axios from "axios";
import config from "../api";
import { useNavigate, useParams } from "react-router-dom";
import useOrgs from "../hooks/useOrgs";
import { useAppDispatch } from "../app/store";
import { fetchOnboardingAsync } from "../app/onboardingSlice";
import Spacer from "../components/Spacer";
import PrimaryButton from "../components/PrimaryButton";
import Spinner from "../components/Spinner";
import useUser from "../hooks/useUser";
import { MIX_PANEL_TOKEN } from "../App";
import mixpanel from "mixpanel-browser";

function AcceptInvitationView() {
  const [user, setUser] = useUser();
  const { id } = useParams();
  const navigate = useNavigate();
  const { selected, setOrg } = useOrgs();
  const [loading, setLoading] = useState<boolean>(false);
  const [err, setErr] = useState<boolean>(false);
  const [errWrongId, setErrWrongId] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const acceptInvitation = async () => {
    if (loading) return;
    setErr(false);
    setErrWrongId(false);
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_URL + "/api/v2/orgs/accept_invitation", { token: id }, config)
      .then((res) => ({
        ...res,
        data: res.data as {
          from_user_id: string;
          org_id: string;
          project_id?: string;
          redirect?: boolean;
        },
      }))
      .then(({ data, status }) => {
        if (status === 203) {
          setErrWrongId(true);
        } else {
          if (data.org_id)
            Promise.resolve(dispatch(fetchOnboardingAsync(data.org_id))).then(() => {
              navigate(
                data.redirect && data.project_id ? `/project/${data.project_id}` : "/onboarding",
              );
            });
          else navigate("/signup");
        }
      })
      .catch((res) => {
        setErr(true);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    acceptInvitation();
  }, [id]);
  const renderErrorMessage = () => {
    return (
      <>
        <div className="flex h-[620px] justify-center">
          <div className="min-h-[486px] w-[536px]">
            <Spacer height="50px" />
            <h3 className="text-center">Invitation link expired</h3>
            <p className="subtitle mt-4 text-center">Looks like the invitation link has expired.</p>
            <Spacer height="72px" />
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-row-reverse" style={{ width: "480px" }}>
                <PrimaryButton
                  size="lg"
                  id="btn-next"
                  handleOnClick={() => {
                    navigate("/");
                  }}
                  label="Back to home"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <Spacer height="200px" />
          </div>
        </div>
      </>
    );
  };
  const logoutHandle = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/api/v2/auth/logout",
        config,
      );
      // console.log("ok", response);
    } catch (err) {
      // console.log("error", err);
    } finally {
      setLoading(false);
      if (MIX_PANEL_TOKEN) {
        mixpanel.reset();
      }
      setUser(null);
      navigate("/", { replace: true });
    }
  };
  const renderErrorWrongIdMessage = () => {
    return (
      <>
        <div className="flex h-[620px] justify-center">
          <div className="min-h-[486px] w-[536px]">
            <Spacer height="50px" />
            <h3 className="text-center">Email does not match</h3>
            <p className="subtitle mt-4 text-center">
              Email does not match. Please log in using the correct email address associated with
              your account.
            </p>
            <Spacer height="72px" />
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-row-reverse" style={{ width: "480px" }}>
                <PrimaryButton
                  size="lg"
                  id="btn-logout"
                  handleOnClick={() => {
                    logoutHandle();
                  }}
                  label="Log out"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <Spacer height="200px" />
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="min-h-[600px]">
      {loading ? (
        <div>
          <Spacer height="269px" />
          <Spinner size="lg" />
          <Spacer height="369px" />
        </div>
      ) : errWrongId ? (
        renderErrorWrongIdMessage()
      ) : err ? (
        renderErrorMessage()
      ) : null}
    </div>
  );
}

export default AcceptInvitationView;
