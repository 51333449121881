import classNames from "classnames";
import React, { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import PlainButton from "./PlainButton";
import { GeographicFocus, PhilanthropicFocusArea } from "../types/philanthropy";
import { ILocation } from "../types/location";
import { SearchQuery } from "../types/search";
import FundedAmount from "./FundedAmount";
import PhilanthropicInformationFlexWrapInner from "./PhilanthropicInformationFlexWrapInner";

type PhilanthropicInformationFoldableProps = {
  globalOpen?: boolean;
  className?: string;
  id: string;
  title: string;
  initialShow?: number;
  initialLine?: number;
  focusArea?: PhilanthropicFocusArea[];
  beneficiary?: PhilanthropicFocusArea[];
  program?: PhilanthropicFocusArea[];
  location?: GeographicFocus[];
  priorityLocation?: ILocation[];
  secondaryPriorityLocation?: ILocation[];
  icon?: string | ReactNode;
  extraButton?: ReactNode;
  arrowPosition?: "start" | "between";
  tagMatched?: boolean;
  allowPastGrantSearch?: boolean;
  funder_id?: string;
  funder_uid?: number;
  showFundedOverview?: boolean;
  searchQuery?: SearchQuery;
  enableGroupLocation?: boolean;
};
type PhilanthropicInformationFoldableComponent = React.FunctionComponent<
  PropsWithChildren<PhilanthropicInformationFoldableProps>
>;
interface GeographicFocusWithColor extends GeographicFocus {
  color: "green" | "purple" | "red" | "yellow" | "blue" | "gray" | "orange";
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  more?: number;
}
const PhilanthropicInformationFoldable: PhilanthropicInformationFoldableComponent = ({
  id,
  title,
  initialShow = 10,
  initialLine = 1,
  className,
  icon,
  extraButton,
  arrowPosition = "between",
  ...props
}: PropsWithChildren<PhilanthropicInformationFoldableProps>) => {
  const [open, setOpen] = useState<boolean>(props.globalOpen || false);
  useEffect(() => {
    setOpen(props.globalOpen || false);
  }, [id]);
  const [groupLocation, setGroupLocation] = useState<number>(props.enableGroupLocation ? 1 : -1);
  return (
    <div className={classNames("flex flex-col gap-2 py-3", className)}>
      <div
        className={classNames(
          "flex",
          arrowPosition === "start" ? "justify-start" : "justify-between",
        )}
      >
        <div
          className="inline-flex cursor-pointer items-center gap-2"
          onClick={() => {
            if (open) setGroupLocation(props.enableGroupLocation ? 1 : -1);
            setOpen((prev) => !prev);
          }}
        >
          <h5 className="font-semibold">
            {open
              ? `${title} (${
                  (props.focusArea?.length || 0) +
                  (props.beneficiary?.length || 0) +
                  (props.program?.length || 0) +
                  (props.location?.length || 0)
                })`
              : `View all ${title.length > 0 ? title[0].toLowerCase() : ""}${title.slice(1)} (${
                  (props.focusArea?.length || 0) +
                  (props.beneficiary?.length || 0) +
                  (props.program?.length || 0) +
                  (props.location?.length || 0)
                })`}
          </h5>
          <span className="cursor-pointer">
            {typeof icon === "string" ? <i className={icon} /> : icon}
          </span>
          {extraButton && (
            <PlainButton
              className="!p-0"
              id={`${id}-open`}
              label=""
              leftIconClass={!open ? "gi-md gi-angle-up" : "gi-md gi-angle-down"}
              handleOnClick={(e) => {
                e.preventDefault();
              }}
            />
          )}
        </div>
        {extraButton ? (
          <>{extraButton}</>
        ) : (
          <PlainButton
            className="!p-0"
            id={`${id}-open`}
            label=""
            leftIconClass={!open ? "gi-md gi-angle-up" : "gi-md gi-angle-down"}
            handleOnClick={() => {
              setOpen((prev) => !prev);
            }}
          />
        )}
      </div>
      <PhilanthropicInformationFlexWrapInner
        id={id}
        maxLine={initialLine}
        tagSize={"sm"}
        focusArea={props.focusArea}
        beneficiary={props.beneficiary}
        program={props.program}
        location={props.location}
        tagMatched={props.tagMatched}
        hideShowMoreBtn
        predictedSize={initialShow}
        priorityLocation={props.priorityLocation}
        secondaryPriorityLocation={props.secondaryPriorityLocation}
        allowPastGrantSearch={props.allowPastGrantSearch}
        funder_id={props.funder_id}
        searchQuery={props.searchQuery}
        enableGroupLocation={groupLocation > 0}
        showAll={open}
        setShowAll={setOpen}
      />
    </div>
  );
};
export default PhilanthropicInformationFoldable;
