import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../app/store";

import PopupModal from "../components/tailwind/PopupModal";
import { IProject } from "../types/project";
import { useLocation, useNavigate } from "react-router-dom";
import IMG_EMPTY from "../assets/images/project-empty.svg";
import classNames from "classnames";
import { scrollBarClass } from "../utils/classes";
import useUserDictionary from "../hooks/useUserDictionary";
import Checkbox from "../components/checkbox/Checkbox";
import useProjects from "../hooks/project/useProjects";
import useOnboardingData from "../hooks/useOnboarding";

interface IProps {
  title?: string;
  onClose?: () => void;
  type: "LOI" | "LOI_GRANT" | "SMART_SEARCH" | "ADD_TO";
  show?: boolean;
  hideClose?: boolean;
  onSelect?: (project: IProject) => void;
}

function ProjectPicker({
  type = "SMART_SEARCH",
  onSelect,
  onClose,
  show = false,
  ...props
}: IProps) {
  const location = useLocation();
  const [onboardingData] = useOnboardingData();
  const [projects] = useProjects();
  const [isEmpty, setIsEmpty] = useState(false);
  useEffect(() => {
    setIsEmpty(projects.data.length === 0);
  }, [projects]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { value: smart_search_do_not_ask, update: updateDoNotAsk } = useUserDictionary<boolean>(
    type === "SMART_SEARCH" ? "smart_search_do_not_ask" : null,
    false,
  );
  const [doNotAsk, setDoNotAsk] = useState<boolean>(false);

  const renderEmpty = () => {
    return (
      <div className="mt-5 flex flex-col items-center">
        <p className="font-poppins text-base text-gray-700">
          Looks like you haven&apos;t created a project yet!{" "}
        </p>
        <p className="mt-2 font-poppins text-base text-gray-700">
          {type === "SMART_SEARCH"
            ? " Create a project to start finding funders and grants."
            : "Create a project to write a letter"}
        </p>
        {onboardingData.role >= 3 ? (
          <div
            onClick={() => {
              onClose?.();
              navigate(`/project/new`, {
                state: { from: type === "SMART_SEARCH" ? "search" : location.pathname },
              });
            }}
            className="mt-[30px] flex h-[190px] w-[350px] cursor-pointer flex-col items-center justify-center gap-4 rounded border border-gray-300 p-5 hover:shadow "
          >
            <img src={IMG_EMPTY} className="h-[110px] w-[44px]" />
            <p className="font-poppins text-base font-semibold text-gray-700">
              + Create a new project
            </p>
          </div>
        ) : (
          <p className="font-poppins text-base font-semibold text-gray-700">
            Your organization currently has no projects. Please contact the owner of the
            organization.
          </p>
        )}
        <div className="h-[69px]" />
      </div>
    );
  };
  const renderContent = () => {
    return (
      <div className="flex flex-col px-10 py-3">
        <p className="font-poppins text-base font-medium text-gray-700">
          {(type === "LOI" || type === "LOI_GRANT") &&
            "Choose a project that you wish to write a letter"}
          {type === "SMART_SEARCH" &&
            "Choose a project that you wish to find funder and grants for."}
          {type === "ADD_TO" && "Name a project that you wish to add this funder to"}
        </p>
        <div
          className={classNames(
            "relative mt-5 flex max-h-[330px] flex-col gap-4 overflow-y-scroll",
            scrollBarClass,
          )}
        >
          {projects.data.map((item) => {
            return (
              <div
                key={item._id}
                className="cursor-pointer rounded border border-gray-300 px-[36px] py-[32px] hover:shadow-sm"
                onClick={() => {
                  if (type === "SMART_SEARCH" && doNotAsk) {
                    updateDoNotAsk(true);
                  }
                  onSelect?.(item);
                }}
              >
                <h4 className="font-poppins text-xl">{`${item?.name}`}</h4>
              </div>
            );
          })}
        </div>
        <div
          className="mt-4 cursor-pointer rounded border border-gray-300 px-[36px] py-[32px] hover:shadow-sm"
          onClick={() =>
            navigate(`/project/new`, {
              state: { from: type === "SMART_SEARCH" ? "search" : location.pathname },
            })
          }
        >
          <h4 className="font-poppins text-xl text-purple-500">+ Create a new project</h4>
        </div>
        {type === "SMART_SEARCH" && !smart_search_do_not_ask && projects.data.length > 0 && (
          <Checkbox
            className="mt-5"
            id="btn-smart-search-do-not-ask"
            checked={doNotAsk}
            onChange={() => {
              setDoNotAsk((prev) => !prev);
            }}
            label="Don't ask again. Go to recent project by default"
          />
        )}
        <div className="h-[20px]" />
      </div>
    );
  };
  return (
    <>
      <PopupModal
        {...(!props.hideClose && { handleClose: () => onClose?.() })}
        isOpen={show}
        wrapperClass="!min-w-[864px] md:!px-0 !p-0"
        title={
          props?.title ||
          (type === "LOI"
            ? "Write a letter to this funder"
            : type === "LOI_GRANT"
            ? "Write a letter"
            : type === "ADD_TO"
            ? "Save to a project"
            : "Smart search")
        }
        showActionButtons={false}
      >
        {isEmpty ? renderEmpty() : renderContent()}
      </PopupModal>
    </>
  );
}
export default ProjectPicker;
