import { escapeRegExp } from "lodash";
import { GrantDeadline } from "../types/grant";
import { stopWord } from "./stopword";
import { differenceInDays, format, formatDistanceToNow, parseISO } from "date-fns";
const CHART_COLOR_SET = ["#D8E4ED", "#F2E79A", "#CFDDC5"];
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const numberMap: { [key: number]: string } = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
};
enum Order {
  ASCEND,
  DESCEND,
}

const toLetterString = (n: number) => {
  if (n >= 1 && n <= 10) return numberMap[n];
  return n.toLocaleString();
};

const reprFloat = (n: number, fixed = 1) => `${parseFloat(n.toFixed(fixed))}`;
const toUsdShort = (n: number, fixed = 0, unit = false) => {
  const absN = Math.abs(n);
  if (absN >= 1_000_000_000) {
    return `${n < 0 ? "-" : ""}$${parseFloat((absN / 1_000_000_000).toFixed(fixed))}${
      unit ? " billion" : "B"
    }`;
  } else if (absN >= 1_000_000) {
    return `${n < 0 ? "-" : ""}$${parseFloat((absN / 1_000_000).toFixed(fixed))}${
      unit ? " million" : "M"
    }`;
  } else if (absN >= 1000) {
    return `${n < 0 ? "-" : ""}$${parseFloat((absN / 1000).toFixed(fixed))}${unit ? "K" : "K"}`;
  } else {
    return `${n < 0 ? "-" : ""}$${parseFloat(absN.toFixed(fixed))}`;
  }
};
const toUsdLong = (n: number, maximumFractionDigits = 0) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: maximumFractionDigits, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(n);
};

const toDateString = (dateStr: Date | string, simple = false, includeTime = false) => {
  try {
    try {
      const d = typeof dateStr === "string" ? parseISO(`${dateStr}+0000`) : dateStr;
      return format(d, `${simple ? "MM" : "MMM"}/dd/yyyy${includeTime ? " h:mm a" : ""}`);
    } catch (e: any) {
      const d = typeof dateStr === "string" ? parseISO(dateStr) : dateStr;
      const formatTwoDigits = (value: number) => {
        return value < 10 ? `0${value}` : `${value}`;
      };
      const get12HourFormat = (hours: number) => {
        if (hours === 0) return 12;
        if (hours > 12) return hours - 12;
        return hours;
      };
      const getAmPm = (hours: number) => {
        return hours >= 12 ? "PM" : "AM";
      };
      if (simple) {
        const datePart = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
        if (includeTime) {
          const hours = get12HourFormat(d.getHours());
          const timePart = `${hours}:${formatTwoDigits(d.getMinutes())}:${formatTwoDigits(
            d.getSeconds(),
          )} ${getAmPm(d.getHours())}`;
          return `${datePart} ${timePart}`;
        }
        return datePart;
      } else {
        const monthName = MONTHS[d.getMonth()];
        const hours = get12HourFormat(d.getHours());
        const timeString = `${hours}:${formatTwoDigits(d.getMinutes())}:${formatTwoDigits(
          d.getSeconds(),
        )} ${getAmPm(d.getHours())}`;
        const dateString = `${monthName} ${d.getDate()}, ${d.getFullYear()}`;
        return includeTime ? `${dateString} ${timeString}` : dateString;
      }
    }
  } catch (e: any) {
    console.error(e);
  }
  return "";
};

const toUTCDateString = (dateStr: Date | string, simple = false, includeTime = false) => {
  try {
    const d =
      typeof dateStr === "string"
        ? new Date(
            Date.UTC(
              Number(dateStr.slice(0, 4)),
              Number(dateStr.slice(5, 7)) - 1,
              Number(dateStr.slice(8, 10)),
            ),
          )
        : dateStr;
    if (simple) {
      return includeTime
        ? `${
            d.getUTCMonth() + 1
          }/${d.getUTCDate()}/${d.getUTCFullYear()} ${d.getUTCHours()}:${d.getUTCMinutes()}:${d.getUTCSeconds()}`
        : `${d.getUTCMonth() + 1}/${d.getUTCDate()}/${d.getUTCFullYear()}`;
    } else {
      const monthName = MONTHS[d.getUTCMonth()];
      const timeString = `${d.getUTCHours()}:${d.getUTCMinutes()}:${d.getUTCSeconds()}`;
      return includeTime
        ? `${monthName} ${d.getUTCDate()}, ${d.getUTCFullYear()} ${timeString}`
        : `${monthName} ${d.getUTCDate()}, ${d.getUTCFullYear()}`;
    }
  } catch (e: any) {
    console.error(e);
    return "";
  }
};

const toEinString = (ein: string) => {
  const n = ein.replace(/[^0-9]/g, "");
  if (n.length > 2) {
    return `${n.slice(0, 2)}-${n.slice(2, 9)}`;
  } else {
    return n;
  }
};

const toPhoneString = (phone: string) => {
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
};

const genLastNYears = (n: number, order: Order) => {
  const curYear = new Date().getFullYear();
  const ret = [] as number[];
  for (let i = 0; i < n; i++) {
    ret.push(curYear - i - 1);
  }

  if (order === Order.ASCEND) {
    return ret.reverse();
  }
  return ret;
};

const shortenText = (text: string, n: number) => {
  if (text.length > n) {
    return text.slice(undefined, n) + "...";
  } else {
    return text;
  }
};

const titleize = (text: string) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((s, i) =>
      i === 0 || !stopWord.includes(s) ? s.charAt(0).toUpperCase() + s.substring(1) : s,
    )
    .join(" ");
};
const matchExact = (r: RegExp, str: string) => {
  const match = str.match(r);
  return match && str === match[0];
};
const toValidPositiveNumberOrZero = (text: string): string => {
  const num = text.replace(/[^\d]/g, "");
  return matchExact(/0+/, num) ? "0" : num.replace(/^0+/, "");
};
const toValidNumberOrSign = (text: string, enableDecimalPoint = false): string => {
  const minus = text.length > 0 && text[0] == "-";
  let num = enableDecimalPoint
    ? text.replace(/[^\d.]/g, "").replace(/\.+/g, ".")
    : text.replace(/[^\d]/g, "");
  if (enableDecimalPoint && num.includes(".")) num = num.replace(/(^[\d]+(.[\d]*)?)(.*)/g, "$1");
  if (enableDecimalPoint) {
    if (matchExact(/0+/, num)) num = "0";
    else num = num.replace(/^0+/, "0").replace(/^(0)(.*)/g, "$2");
  } else {
    if (matchExact(/0+/, num)) return "0";
    else num = num.replace(/^0+/, "");
  }
  if (num.length > 0 && num[0] === ".") num = "0" + num;
  return `${minus ? "-" : ""}${num}`;
};

const stateIdToStateName = (stateId: string) => {
  const states: { [key: string]: string } = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DC: "District of Columbia",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };
  return states[stateId];
};

const fipsCodeToStateName = (fipscode: string) => {
  const fipsCodes: { [key: string]: string } = {
    "01": "Alabama",
    "02": "Alaska",
    "04": "Arizona",
    "05": "Arkansas",
    "06": "California",
    "08": "Colorado",
    "09": "Connecticut",
    "10": "District of Columbia",
    "11": "Delaware",
    "12": "Florida",
    "13": "Georgia",
    "15": "Hawaii",
    "16": "Idaho",
    "17": "Illinois",
    "18": "Indiana",
    "19": "Iowa",
    "20": "Kansas",
    "21": "Kentucky",
    "22": "Louisiana",
    "23": "Maine",
    "24": "Maryland",
    "25": "Massachusetts",
    "26": "Michigan",
    "27": "Minnesota",
    "28": "Mississippi",
    "29": "Missouri",
    "30": "Montana",
    "31": "Nebraska",
    "32": "Nevada",
    "33": "New Hampshire",
    "34": "New Jersey",
    "35": "New Mexico",
    "36": "New York",
    "37": "North Carolina",
    "38": "North Dakota",
    "39": "Ohio",
    "40": "Oklahoma",
    "41": "Oregon",
    "42": "Pennsylvania",
    "44": "Rhode Island",
    "45": "South Carolina",
    "46": "South Dakota",
    "47": "Tennessee",
    "48": "Texas",
    "49": "Utah",
    "50": "Vermont",
    "51": "Virginia",
    "53": "Washington",
    "54": "West Virginia",
    "55": "Wisconsin",
    "56": "Wyoming",
  };
  return fipsCodes[fipscode];
};

const stateNameToStateId = (stateName: string) => {
  const states: { [key: string]: string } = {
    Alabama: "AL",
    Alaska: "AK",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    "District of Columbia": "DC",
    Delaware: "DE",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Puerto Rico": "PR",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
  };
  return states[stateName];
};
const getStateNames = () => {
  return [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
};
const prettyListStrings = (l: string[], asNewLine = false, withoutQuote = false) => {
  if (l.length <= 1) {
    return withoutQuote ? l : `"${l}"`;
  } else if (withoutQuote) {
    const joined = l.slice(0, l.length - 1).join(`, `);
    return `${joined} and ${l[l.length - 1]}`;
  } else if (asNewLine) {
    const joined = l.slice(0, l.length - 1).join(`"\n"`);
    return `"${joined}", and\n"${l[l.length - 1]}"`;
  } else {
    const joined = l.slice(0, l.length - 1).join(`", "`);
    return `"${joined}" and "${l[l.length - 1]}"`;
  }
};

const getYearsString = (years: number[]): string => {
  if (years.length === 0) return "";
  const group = years
    .sort()
    .reduce(
      (prev, cur, i, arr) =>
        i === 0
          ? [[cur]]
          : arr[i - 1] + 1 === cur
          ? [...prev.slice(0, -1), [...prev[prev.length - 1], cur]]
          : [...prev, [cur]],
      [] as number[][],
    );
  return group
    .reduce(
      (prev, cur) => [
        ...prev,
        cur.length === 1
          ? cur.toString()
          : `${cur[0].toString()}-${cur[cur.length - 1].toString()}`,
      ],
      [] as string[],
    )
    .join(", ");
};
const getPercentageDiff = (a: number, b: number): number => {
  if (!a && !b) return 0;
  if (!a) return Infinity;
  return ((b - a) / a) * 100;
};
const calculateVariance = (nums: number[]): number => {
  const mean = nums.reduce((acc, curr) => acc + curr, 0) / nums.length;
  const sumSquaredDiff = nums.reduce((acc, curr) => acc + Math.pow(curr - mean, 2), 0);
  const variance = sumSquaredDiff / (nums.length - 1);
  return variance;
};
const isValidEmail = (email: string) => {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
};
function convertToReadable(text: string): string {
  let displayText = text;
  const tagRegex = /@\[([^\]]+)]\(([^)]+)\)/g;
  const matches = text.match(tagRegex) || [];
  matches.forEach((myTag) => {
    const tagDataArray = myTag.match(/@\[([^\]]+)]\(([^)]+)\)/);
    if (tagDataArray && tagDataArray.length === 3) {
      const tagDisplayValue = tagDataArray[1];
      displayText = displayText.replace(
        new RegExp(escapeRegExp(myTag), "g"),
        "@" + tagDisplayValue,
      );
    }
  });
  return displayText;
}
const getInitials = (firstName?: string, lastName?: string) => {
  const firstInitial = firstName?.charAt(0).toUpperCase();
  const lastInitial = lastName?.charAt(0).toUpperCase();
  return firstName ? (lastName ? `${firstInitial}${lastInitial}` : firstInitial) : lastInitial;
};

const getRandomColor = (firstName?: string, lastName?: string, colors?: Record<string, string>) => {
  const colorMap: Record<string, string> = {
    ABCDE: "#F15BB5",
    FGHIJ: "#00BBF9",
    KLMNO: "#9B5DE5",
    PQRST: "#7DCA29",
    UVWXYZ: "#FF9914",
  };
  const initial = getInitials(firstName, lastName)?.[0];
  const colorList = colors ? colors : colorMap;
  for (const key in colorList) {
    if (key.includes(initial || "")) {
      return colorList[key];
    }
  }
  return "#21143c";
};

const snakeToCamelCase = (snakeCaseString: string): string => {
  return snakeCaseString.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replaceAll("-", "").replaceAll("_", " "),
  );
};
function toBytesFormat(bytes: number): string {
  if (bytes < 1024) {
    return bytes + "B";
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(1) + "KB";
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(1) + "MB";
  } else {
    return (bytes / (1024 * 1024 * 1024)).toFixed(1) + "GB";
  }
}

const getValidNumber = (v: string | null, defaultNumber = 0): number => {
  const _v = v ? Number(v) : undefined;
  if (_v !== undefined && !isNaN(_v)) return _v;
  return defaultNumber;
};

const reprTimeDistance = (v?: string, maxDays = 1): string =>
  !v
    ? ""
    : differenceInDays(new Date(), new Date(v + "Z")) > maxDays
    ? format(parseISO(v + "Z"), "MM/dd/yyyy, hh:mm a")
    : `${formatDistanceToNow(parseISO(v + "Z"))} ago`;
const getWordLength = (v?: string): number => {
  if (!v) return 0;
  const t = v.replace(/\s+/g, " ").trim();
  return t.length > 0 ? t.split(" ").length : 0;
};
const toFixedWithMinimum = (v: number, fixed = 2, fixedForLarge = 2): string => {
  if (v === 0) return "0";
  if (v > 1 && fixed > fixedForLarge) return toFixedWithMinimum(v, fixedForLarge, fixedForLarge);
  const min = fixed < 1 ? 0 : Math.pow(0.1, fixed);
  if (v < min) return min.toFixed(fixed);
  const s = v.toFixed(fixed).replace(/(\d)(\.\d*?[1-9]*)0+$/g, "$1$2");
  return s.endsWith(".") ? s.slice(0, s.length - 1) : s;
};
export {
  getValidNumber,
  isValidEmail,
  getStateNames,
  CHART_COLOR_SET,
  MONTHS,
  Order,
  toUsdShort,
  toUsdLong,
  toDateString,
  toEinString,
  toPhoneString,
  shortenText,
  genLastNYears,
  titleize,
  toValidNumberOrSign,
  toValidPositiveNumberOrZero,
  stateIdToStateName,
  fipsCodeToStateName,
  stateNameToStateId,
  prettyListStrings,
  getYearsString,
  toLetterString,
  getPercentageDiff,
  calculateVariance,
  toUTCDateString,
  convertToReadable,
  getRandomColor,
  getInitials,
  snakeToCamelCase,
  toBytesFormat,
  reprTimeDistance,
  getWordLength,
  reprFloat,
  toFixedWithMinimum,
};
