import classNames from "classnames";
import "../css/dropdown.scss";
import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  Fragment,
  ReactNode,
  useCallback,
  useRef,
  ReactElement,
  Ref,
  useEffect,
} from "react";
import { Combobox, Transition } from "@headlessui/react";
import useDebounce from "../../hooks/useDebounce";
import useSWR from "swr";
import useThrottle from "../../hooks/useThrottle";
import closeTag from "../../assets/images/closeTag.svg";
import downTag from "../../assets/images/arrow-down.svg";
import { FROM_FOR_MIXPANEL } from "../../mixpanel/mixpanel";
import useGibooMixpanel, { GibooMixpanelProperties } from "../../hooks/useGibooMixpanel";

interface SearchableDropdownProps<T> {
  from_for_mixpanel?: FROM_FOR_MIXPANEL;
  mixpanel_event?: { started: string; successful: string };
  asMixPanelProperty?: (key: T) => GibooMixpanelProperties;
  id: string;
  color?: "purple" | "red" | "yellow" | "blue" | "green" | "gray" | "orange";
  getColor?: (key: T) => "purple" | "red" | "yellow" | "blue" | "green" | "gray" | "orange";
  query: string;
  value?: T;
  controlledValue?: boolean;
  setQuery: Dispatch<SetStateAction<string>>;
  autoCompleteFunction: (key: string, match_simliar?: boolean) => Promise<T[]>;
  autoCompleteThrottleMilliseconds?: number;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  getKey: (item: T) => string;
  reprItem?: (item: T) => string;
  renderItem: (item: T) => ReactNode;
  createItem?: ((key: string) => T) | undefined;
  addItem: (item: T) => void;
  excludeKeys?: string[];
  maxWidth?: string;
  maxHeight?: string;
  openAnimation?: boolean;
  placeholder?: string;
  disabled?: boolean;
  float?: boolean;
  icon?: boolean;
  clearQuery?: boolean;
  spinner?: boolean;
  spinnerFromColor?: "purple" | "red" | "yellow" | "green" | "blue" | "gray";
  spinnerBackgroundColor?: string;
  className?: string;
  wrapperClass?: string;
  size?: "lg" | "md" | "sm" | "xl";
  onClear?: () => void;
  showClear?: boolean;
  largeInput?: boolean;
  searchWithKeyword?: boolean;
  onFocus?: () => void;
  defaultValue?: T;
  useSimilarSearch?: boolean;
  smallInput?: boolean;
  actionButton?: ReactNode;
  invalid?: boolean;
  arrowIcon?: boolean;
  keepPreviousQueryOnBlur?: boolean;
}

function searchableDropdown<T>(
  {
    from_for_mixpanel,
    mixpanel_event,
    asMixPanelProperty,
    id,
    color,
    getColor,
    query,
    setQuery,
    value,
    controlledValue,
    autoCompleteFunction,
    autoCompleteThrottleMilliseconds = 200,
    getKey,
    reprItem,
    renderItem,
    createItem = undefined,
    addItem,
    excludeKeys = [],
    maxWidth,
    maxHeight,
    openAnimation = false,
    placeholder,
    disabled = false,
    float = false,
    icon = false,
    clearQuery = true,
    spinner = false,
    spinnerFromColor = "purple",
    spinnerBackgroundColor = "white",
    wrapperClass,
    className,
    useSimilarSearch = false,
    size = "lg",
    onClear,
    showClear,
    largeInput = false,
    searchWithKeyword = false,
    onFocus,
    defaultValue,
    handleOnKeyDown,
    smallInput = false,
    actionButton,
    invalid = false,
    arrowIcon = false,
    keepPreviousQueryOnBlur,
  }: SearchableDropdownProps<T>,
  ref?: React.ForwardedRef<HTMLInputElement>,
) {
  const mxEvent = useGibooMixpanel(from_for_mixpanel, true);
  const buttonRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputValue = useRef<string>(query);
  const colorClass = color ? `-${color}` : "";
  const delayedQuery = useDebounce<string>(query, autoCompleteThrottleMilliseconds, {
    maxWait: autoCompleteThrottleMilliseconds * 5,
  });
  useEffect(() => {
    if (keepPreviousQueryOnBlur) {
      setQuery?.(inputValue.current);
    }
  }, [inputValue.current, keepPreviousQueryOnBlur]);

  // const delayedQuery = useThrottle<string>(query, autoCompleteThrottleMilliseconds);
  const autoCompleteFunctionWithKey = useCallback(
    (key: string) => {
      // console.log(key);
      if (mixpanel_event && key.length > id.length + 1)
        mxEvent(mixpanel_event.started, {
          textQuery: key.startsWith("%") ? key.slice(id.length + 1) : key.slice(id.length),
        });
      if (key.startsWith("%")) return autoCompleteFunction(key.slice(id.length + 1), true);
      else return autoCompleteFunction(key.slice(id.length));
    },
    [mixpanel_event, mxEvent, id, autoCompleteFunction],
  );
  const { data, isLoading, error } = useSWR<T[]>(id + delayedQuery, autoCompleteFunctionWithKey, {
    dedupingInterval: 1000 * 60,
  });
  const {
    data: dataSimilar,
    isLoading: isLoadingSimilar,
    isValidating,
  } = useSWR<T[]>(useSimilarSearch ? "%" + id + delayedQuery : null, autoCompleteFunctionWithKey, {
    dedupingInterval: 1000 * 60,
  });
  // useEffect(() => {
  //   console.log(id, "changed", delayedQuery);
  // }, [delayedQuery]);
  const sizeVariants = {
    xl: "h-15 min-h-[60px]",
    lg: "h-12 min-h-[48px]",
    md: "h-9 min-h-[36px]",
    sm: "h-6 min-h-[24px]",
  };
  const options = useMemo(() => {
    const duplicated = excludeKeys.includes(createItem ? getKey(createItem(query)) : query.trim());
    const merged = useSimilarSearch && !isValidating && dataSimilar ? dataSimilar : data;
    const filtered = merged?.filter((d) => !excludeKeys.includes(getKey(d)));
    return (
      <Combobox.Options
        as="div"
        className={classNames(
          "flex w-full flex-col gap-[2px] overflow-y-auto overflow-x-hidden rounded border border-gray-300 bg-gray-100 text-sm shadow-sm outline-none",
          {
            float: float,
          },
          float ? "absolute" : "",
        )}
        style={{ maxHeight: maxHeight, overflowY: "auto" }}
      >
        {createItem && query.trim().length > 0 && (
          <Combobox.Option as={Fragment} key={null} value={createItem(query)}>
            <div
              className={classNames(
                "dropdown-selector-item flex w-full items-center",
                `dropdown-selector-item${duplicated ? "-duplicated" : "-purple"}`,
                sizeVariants[size],
              )}
            >
              <span
                id={`new-item-${id}`}
                className="w-full overflow-hidden text-ellipsis !whitespace-nowrap"
              >
                {duplicated
                  ? `"${query}" is already exist.`
                  : `${searchWithKeyword ? "Search with" : "Click to add"} "${query}"`}
              </span>
            </div>
          </Combobox.Option>
        )}
        {!error &&
          filtered &&
          (filtered.length > 0 ? (
            filtered.map((item) => (
              <Combobox.Option as={Fragment} key={getKey(item)} value={item}>
                <div
                  className={classNames(
                    "dropdown-selector-item flex w-full items-center",
                    getColor
                      ? `dropdown-selector-item-light-bg dropdown-selector-item-${getColor(item)}`
                      : `dropdown-selector-item${colorClass}`,
                    sizeVariants[size],
                  )}
                >
                  <div className="w-full" id={`item-${id}-${getKey(item)}`}>
                    {renderItem(item)}
                  </div>
                </div>
              </Combobox.Option>
            ))
          ) : !isValidating && !isLoading ? (
            <div className="mt-1 rounded bg-white p-4 text-center shadow">Not found</div>
          ) : null)}
        {actionButton && actionButton}
      </Combobox.Options>
    );
  }, [query, data, excludeKeys, dataSimilar, useSimilarSearch, isValidating]);
  const transitionClasses = openAnimation
    ? {
        enter: "transition ease-out duration-100",
        enterFrom: "transform opacity-0 scale-95",
        enterTo: "transform opacity-100 scale-100",
        leave: "transition ease-in duration-75",
        leaveFrom: "transform opacity-100 scale-100",
        leaveTo: "transform opacity-0 scale-95",
      }
    : {};
  const fromColorVariants = {
    purple: "from-purple-500",
    red: "from-red-500",
    yellow: "from-yellow-500",
    green: "from-green-500",
    blue: "from-blue-500",
    gray: "from-gray-500",
  };
  return (
    <Combobox
      {...(controlledValue ? { value } : { defaultValue })}
      id={id}
      as="div"
      onChange={(v: T) => {
        if (v) {
          const suggested = [...(data || []), ...(dataSimilar || [])]?.find(
            (d) => getKey(d) === getKey(v),
          );
          if (suggested) {
            addItem(suggested); // when choose item in the list suggested
            if (mixpanel_event)
              mxEvent(mixpanel_event.successful, {
                ...(asMixPanelProperty?.(v) || {}),
                textQuery: query,
              });
          } else if (createItem) addItem(v); // when choose not in the list, in this case createItem should defined
          if (clearQuery) {
            setQuery("");
          }
        }
      }}
      className={classNames("h-fit w-full rounded", sizeVariants["lg"], wrapperClass)}
      style={{ maxWidth: maxWidth }}
      disabled={disabled}
      nullable
    >
      {({ open }) => (
        <>
          <Combobox.Button as="div" className="relative" ref={buttonRef}>
            {icon ? (
              <i
                className={classNames(
                  "gi gi-search absolute",
                  "gray",
                  smallInput ? "my-[10px] ml-4" : "my-4 ml-5",
                )}
              />
            ) : null}
            <Combobox.Input
              displayValue={(item: T) => reprItem?.(item) || inputValue.current}
              // displayValue={reprItem}
              {...(ref ? { ref: ref } : { ref: inputRef })}
              {...(largeInput ? { as: "textarea" } : {})}
              id={`input-${id}`}
              className={classNames(
                "form-control giboo-input w-full overflow-hidden !outline-none !ring-0",
                className,
                { "form-invalid": invalid },
                // colorClass ? `giboo-input${colorClass}` : "",
                largeInput ? "!h-[100px] w-full !whitespace-pre-line !py-2" : "",
              )}
              onChange={(event) => {
                if (keepPreviousQueryOnBlur) {
                  inputValue.current = event.target.value;
                }
                if (!open && buttonRef && buttonRef.current) buttonRef.current.click();
                setQuery(event.target.value);
              }}
              placeholder={placeholder}
              autoComplete="off" // disable browser's auto suggestion
              style={{
                paddingLeft: icon ? (smallInput ? "40px" : "48px") : "24px",
                paddingRight: spinner ? "32px" : "24px",
              }}
              // value={query}
              onFocus={onFocus}
              onBlur={() => {
                if (!keepPreviousQueryOnBlur) setQuery("");
              }}
              onKeyDown={(e) => {
                if (!open) handleOnKeyDown?.(e);
              }}
            />
            {spinner && open && (isLoading || isValidating || isLoadingSimilar) ? (
              <div
                className={classNames(
                  "absolute right-0 top-0 mr-5",
                  largeInput ? "my-11" : smallInput ? "my-[6px]" : "my-3",
                )}
              >
                <div
                  className={classNames(
                    "flex h-6 w-6 animate-spin items-center justify-center rounded-full bg-gradient-to-tr to-transparent",
                    fromColorVariants[spinnerFromColor],
                  )}
                >
                  <div className={classNames("h-4 w-4 rounded-full", spinnerBackgroundColor)}></div>
                </div>
              </div>
            ) : (
              <>
                {showClear ? (
                  <span
                    className={classNames("absolute right-0 top-1 my-3 mr-3 cursor-pointer")}
                    onClick={(e) => {
                      setQuery("");
                      inputValue.current = "";
                      onClear?.();
                    }}
                  >
                    <img src={closeTag} alt="icon" className="h-[17px] w-[17px]" />
                  </span>
                ) : (
                  arrowIcon && (
                    <span className={classNames("absolute right-0 top-1 my-3 mr-3 cursor-pointer")}>
                      <img src={downTag} alt="icon" className="h-[17px] w-[17px]" />
                    </span>
                  )
                )}
              </>
            )}
          </Combobox.Button>
          <div className="relative z-50 w-full">
            {openAnimation ? (
              <Transition as={Fragment} {...transitionClasses}>
                {options}
              </Transition>
            ) : (
              <>{options}</>
            )}
          </div>
        </>
      )}
    </Combobox>
  );
}

const SearchableDropdown = React.forwardRef(searchableDropdown) as <T>(
  p: SearchableDropdownProps<T> & { ref?: Ref<HTMLInputElement> },
) => ReactElement;

export default SearchableDropdown;
