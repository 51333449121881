ResetPasswordView;

import React, { FormEvent, useState } from "react";
import axios from "axios";
import config from "../api";
import PrimaryButton from "../components/PrimaryButton";
import LeftInnerIconInput from "../components/LeftInnerIconInput";
import Spacer from "../components/Spacer";
import { useNavigate } from "react-router-dom";
import img from "./img/mail.svg";
import PlainButton from "../components/PlainButton";
import TextInput from "../components/tailwind/TextInput";
import GibooToast from "../components/GibooToast";
import { isValidEmail } from "../utils/formatHelper";
function ResetPasswordView() {
  const [username, setUsername] = useState("");
  const [submitted, setSubmit] = useState(false);
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [resetting, setResetting] = useState<boolean>(false);
  const requestResetLink = async () => {
    setResetting(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v2/auth/send_email_reset_password",
        {
          email: username,
          origin: window.location.origin,
        },
        config,
      );
      if (response) {
        GibooToast({ type: "success", message: "Email has been sent again" });
      }
      // console.log("ok", response);
    } catch (error: any) {
      // console.log("err", error);
    } finally {
      setSubmit(true);
      setResetting(false);
    }
  };

  const handleBack = async (e: FormEvent) => {
    e.preventDefault();
    navigate("/signin");
  };
  const hasValidDetail = () => {
    return isValidEmail(username);
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isValid = hasValidDetail();
    if (isValid === false) {
      setIsDirty(true);
      return;
    } else {
      setIsDirty(false);
      await requestResetLink();
    }
  };

  if (submitted) {
    return (
      <div className="flex h-[600px] justify-center">
        <div className="mt-[50px] min-h-[343px] w-[506px]">
          <div className="flex flex-col justify-center text-center">
            <h3 className="font-poppins text-[28px] font-medium">Please check your email</h3>
            <div className="mt-4  w-full whitespace-nowrap text-center font-poppins text-base text-gray-700">
              We&apos;ve sent password reset instructions to{" "}
              <span className="text-base font-semibold">{username}</span>
            </div>
            <span className="text-center font-poppins text-gray-700">
              Need help? Contact&nbsp;
              <a href="mailto:support@giboo.com">support@giboo.com</a>
            </span>
            <PrimaryButton
              id="btn-next"
              className="mt-[60px] w-full"
              size="lg"
              disabled={username === ""}
              loading={resetting}
              handleOnClick={() => requestResetLink()}
              label=" Didn't get the email? Send it again"
            />
            <PlainButton
              className="mt-[60px]"
              id="btn-back"
              underline
              handleOnClick={handleBack}
              label="Back to log in"
              leftIconClass="fa-solid fa-arrow-left mr-5"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-[600px] justify-center">
      <div className="mt-[50px] min-h-[343px] max-w-[480px]">
        <h3 className="text-center">Forget password?</h3>
        <p className="subtitle mt-4 text-center">No worries, we will send you reset instruction</p>
        <div className="mt-[60px] flex justify-center">
          <form style={{ width: "480px" }}>
            <TextInput
              id="email"
              value={username}
              className="!h-[48px]"
              placeholder="Enter email*"
              invalid={isDirty && (username === "" || !isValidEmail(username))}
              errorMessage={!isValidEmail(username) ? "Invalid Email address" : ""}
              handleOnChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </form>
        </div>
        <div className="mt-4 flex flex-col justify-center">
          <PrimaryButton
            id="btn-next"
            className="w-full"
            size="lg"
            disabled={username === ""}
            loading={resetting}
            handleOnClick={handleSubmit}
            label="Reset password"
          />
          <PlainButton
            className="mt-[60px]"
            id="btn-back"
            handleOnClick={handleBack}
            underline
            label="Back to log in"
            leftIconClass="fa-solid fa-arrow-left mr-5"
          />
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordView;
