import { ReactElement } from "react";
import { OnboardingData } from "../app/onboarding";
import { getISearchParamFromOnboardingData } from "../app/onboardingSlice";
import { IOption } from "../components/tailwind/AsyncCreatableSelector";
import { getExcludedKey, isMarkedAsExcluded } from "../hooks/useExclude";
import { getStarKey, isMarkedAsStar } from "../hooks/useStars";
import { ILocation, reprLocation } from "../types/location";
import {
  ALL_FUNDER_TYPE,
  ASSET_SIZE_OPTION,
  CONTACT_INFO_OPTION,
  DEADLINE_OPTION,
  DEFAULT_FUNDER_TYPE,
  FUNDING_SIZE_OPTION,
  FUNDING_TYPE_OPTION,
  GRANT_SORT_OPTION,
  GRANT_TYPE_OPTION,
  NPO_ADVANCED_FILTER_EXACT_VALUE_OPTION,
  NPO_ADVANCED_FILTER_PERCENTAGE_OPTION,
  NPO_ASSET_SIZE_OPTION,
  NPO_INCOPORATED_STATUS_OPTION,
  NPO_MONTH_OF_LIQUID_OPTION,
  NPO_SORT_OPTION,
  NPO_TAX_EXEMPT_STATUS_OPTION,
  PREVIOUS_FUNDER_OPTION,
  SOLICITATION_OPTION,
  SORT_OPTION,
  STAGE_OPTION,
} from "../types/searchFilter";
import {
  TypeStage,
  TypeTaxExemption,
  stageKeys,
  taxExemptionKeys,
  taxExemptionMap,
} from "./onboarding";
import { Philanthropy } from "./philanthropy";
import { ITaxonomyTaggingOperator } from "./taxonomy";

const getValidStage = (s: string | undefined | null): TypeStage => {
  if (stageKeys.filter((validitem) => validitem === s).length > 0) return s as TypeStage;
  return "dreamer";
};
const getValidTaxExemption = (
  s: number | string | undefined | null | boolean,
): TypeTaxExemption => {
  if (typeof s === "boolean") return s ? "yes" : "no";
  if (typeof s === "string") {
    if (taxExemptionKeys.filter((validitem) => validitem === s).length > 0)
      return s as TypeTaxExemption;
  }
  if (typeof s === "number") {
    const found = Object.keys(taxExemptionMap).find((k) => `${k}` === `${s}`);
    if (found) return taxExemptionMap[+found];
    if (s) return "others";
  }
  return "no";
};
const getValidNumber = (s: string | undefined | null): number | undefined => {
  if (!s) return undefined;
  try {
    return +s;
  } catch (e) {
    // console.log(e);
  }
  return undefined;
};

export const parseLocation = (searchParams: URLSearchParams, key: string): ILocation[] => {
  try {
    const parsedLocation = searchParams.getAll(key).map((item) => JSON.parse(item)) as ILocation[];
    if (parsedLocation) return parsedLocation;
  } catch (e) {
    // console.log(e);
  }
  return [];
};
export type FinancialFieldName =
  | "contributions"
  | "operation"
  | "investment"
  | "grants_gov"
  | "grants_ind"
  | "grants_phil"
  | "program"
  | "operating"
  | "fundraising"
  | "asset_cash"
  | "asset_receivable"
  | "asset_investment"
  | "asset_property"
  | "contribution_percentage"
  | "investment_percentage"
  | "operation_percentage"
  | "program_percentage"
  | "operating_percentage"
  | "fundraising_percentage";

export interface AdvancedFilter {
  field_name: FinancialFieldName | undefined;
  operator: "<" | ">" | undefined;
  type: "int" | "%" | undefined;
  value: number | undefined;
}
export const getValidAdvancedFilter = (
  f: AdvancedFilter | undefined,
  strict = true,
): AdvancedFilter | undefined => {
  if (!f) return f;
  const exact_f = NPO_ADVANCED_FILTER_EXACT_VALUE_OPTION.find((o) => o.value === f.field_name);
  const per_f = NPO_ADVANCED_FILTER_PERCENTAGE_OPTION.find((o) => o.value === f.field_name);
  if (strict && ((!exact_f && !per_f) || !f.operator || f.value === undefined || f.value === null))
    return undefined;
  if (exact_f) {
    return {
      ...f,
      type: "int",
      operator: ["<", ">"].find((o) => o === f.operator) as "<" | ">" | undefined,
      value: f.value === undefined ? undefined : Math.max(0, f.value || 0),
    };
  } else if (per_f) {
    return {
      ...f,
      type: "%",
      operator: ["<", ">"].find((o) => o === f.operator) as "<" | ">" | undefined,
      value: f.value === undefined ? undefined : Math.max(0, Math.min(0, f.value || 0)),
    };
  }
  return {
    ...f,
    field_name: undefined,
  };
};
export const parseAdvancedFilter = (
  searchParams: URLSearchParams,
  key: string,
): AdvancedFilter[] => {
  try {
    const parsed = searchParams.getAll(key).map((item) => JSON.parse(item)) as AdvancedFilter[];
    if (parsed) return parsed.filter((f) => getValidAdvancedFilter(f, false)).filter(Boolean);
  } catch (e) {
    // console.log(e);
  }
  return [];
};

const parseNumber = (searchParams: URLSearchParams, key: string, defaultNumber: number): number => {
  try {
    const parsedNumber = searchParams.get(key);
    if (parsedNumber) return +parsedNumber;
  } catch (e) {
    // console.log(e);
  }
  return defaultNumber;
};
const stageFilterOptions: IOption[] = [
  { label: "Individual", value: "dreamer" },
  { label: "Pre-seed & Seed", value: "seed" },
  { label: "Start up", value: "startup" },
  { label: "Buildup", value: "buildup" },
  { label: "Superstar", value: "superstar" },
  { label: "Hyperstar", value: "hyperstar" },
];

// ///////////////////////// new search
export type SearchType =
  | "funder"
  | "npo"
  | "by_name"
  | "grant"
  | "virtual_grant"
  | "past_grant"
  | "specific_funder_grant"
  | "eligible_npo";
const getSearchResultKey = (r: UnifiedSearchResult): string => {
  return `${
    r.search_type === "specific_funder_grant"
      ? "g"
      : r.search_type && r.search_type.length > 0
      ? r.search_type[0]
      : "e"
  }_${r.uid}`;
};
export interface PastGrantOverview {
  len_total: number;
  min_year: number;
  max_year: number;
  org_total: number;
  amount_total: number;
  amount_total_stage: number;
  org_total_stage: number;
}
export interface UnifiedSearchResult extends Philanthropy {
  search_type: SearchType;
  search_by_name?: boolean;
  name: string;
  _id: string;
  rank_conf: number;
  uid: number;
  service_specific_loc: boolean;
  search_query?: SearchQuery;
  result_type?: string;
  type?: string;
  gids?: string[];
}
export interface FunderSearchResult extends Philanthropy {
  search_type: SearchType;
  search_by_name?: boolean;
  _id: string;
  name: string;
  ein: string;
  location?: ILocation;
  return_code: string;
  ntee_cd?: string;
  does_not_accept_unsolicited: boolean;
  unclassified_count: number;
  dreamer_count: number;
  seed_count: number;
  startup_count: number;
  buildup_count: number;
  superstar_count: number;
  hyperstar_count: number;
  grant_amount_min: number;
  grant_amount_max: number;
  grant_amount_median: number;
  last_deadline?: string;
  has_rolling_grant: boolean;
  has_email: boolean;
  has_phone: boolean;
  has_linkedin: boolean;
  assets: number;
  address: string;
  rank_conf: number;
  uid: number;
  service_specific_loc: boolean;
  result_type?: string;
  funder_type: string;
  gids?: string[];
  grant_amount_total: number;
  grant_count_total: number;
  grant_year_max: number;
  grant_year_min: number;
  website?: string;
  phone?: string;
}
export interface GrantSearchResult extends Philanthropy {
  search_type: SearchType;
  search_by_name?: boolean;
  _id: string;
  name: string;
  type: string;
  agency_name: string;
  description?: string;
  donor_id?: string;
  donor_uid?: number;
  uloc?: string;
  deadline?: string;
  rolling: boolean;
  fiscal_position: string[];
  private: boolean;
  specific_grant_amount: boolean;
  grant_amount_min: number;
  grant_amount_max: number;
  service_loc: ILocation[];
  service_specific_loc: boolean;
  service_loc_text: string;
  npo_loc: ILocation[];
  npo_specific_loc: boolean;
  npo_loc_text: string;
  donor?: {
    _id: string;
    name: string;
    ein: string;
    location?: ILocation;
    return_code: string;
    ntee_cd?: string;
    does_not_accept_unsolicited: boolean;
    dreamer_count: number;
    seed_count: number;
    startup_count: number;
    buildup_count: number;
    superstar_count: number;
    hyperstar_count: number;
    grant_amount_min: number;
    grant_amount_max: number;
    grant_amount_median: number;
    last_deadline?: string;
    has_rolling_grant: boolean;
    has_email: boolean;
    has_phone: boolean;
    has_linkedin: boolean;
    assets: number;
    address: string;
    funder_type: string;
  };
  funding_type: string[];
  rank_conf: number;
  uid: number;
  result_type?: string;
  gids?: string[];
  focus_area_detail?: { title: string; description: string }[];
  beneficiary_detail?: { title: string; description: string }[];
  program_detail?: { title: string; description: string }[];
  service_loc_detail?: { title: string; description: string }[];
}

export interface PastGrantSearchResult extends Philanthropy {
  _id: string;
  search_by_name?: boolean;
  search_type: SearchType;
  name: string;
  grant_description: string;
  grant_year: number;
  grant_amount: number;
  donor?: {
    _id: string;
    name: string;
    ein: string;
    location?: ILocation;
    return_code: string;
    ntee_cd?: string;
    address?: string;
    assets: number;
    funder_type: string;
    uid: number;
  };
  npo?: {
    _id: string;
    name: string;
    ein: string;
    location?: ILocation;
    return_code: string;
    ntee_cd?: string;
    address?: string;
    assets: number;
    stage?: string;
    mission?: string;
    uid: number;
  };
  rank_conf: number;
  uid: number;
  funding_type?: string[];
  service_specific_loc: boolean;
  result_type?: string;
  gids?: string[];
  recipient_name?: string;
  iso2?: string;
}
export interface NPOSearchResult extends Philanthropy {
  search_type: SearchType;
  search_by_name?: boolean;
  _id: string;
  name: string;
  ein: string;
  location?: ILocation;
  return_code: string;
  ntee_cd?: string;
  assets: number;
  expenses: number;
  revenue: number;
  address: string;
  rank_conf: number;
  uid: number;
  service_specific_loc: boolean;
  result_type?: string;
  npo_type: string;
  stage: string;
  gids?: string[];
  tax_exemption: boolean;
  grant_amount_min: number;
  grant_amount_max: number;
  grant_amount_total: number;
  grant_count_total: number;
  employee_count: number;
  volunteer_count: number;
  year_of_formation?: number;
  tax_exempt_status?: number;
  incorporated_status?: string;
  month_of_liquid?: number;
  luna?: number;
  website?: string;
  mission?: string;
  phone?: string;
}
// ISearchParam: only for search url
export interface ISearchParamNullable {
  donor_id?: string;
  text_query?: string;
  mission?: string;
  focus_area?: string[];
  beneficiary?: string[];
  program?: string[];
  service_loc?: ILocation[];
  hq_loc?: ILocation[];
  stars?: string[];
  excludes?: string[];
  tagged_focus_area?: string[];
  tagged_beneficiary?: string[];
  tagged_program?: string[];
  focus_area_subs?: string[];
  beneficiary_subs?: string[];
  program_subs?: string[];
  service_loc_subs?: ILocation[];
  hq_loc_subs?: ILocation[];
  type?: SearchType[];

  solicitation?: string[];
  grant_type?: string[];
  grantee_type?: string[];
  grant_deadline?: string; // datetime
  grant_deadline_filter?: string[];
  funder_type?: string[];
  funding_type?: string[];
  funding_size?: string[];
  funder_assets?: string[];
  grantee_stage?: string[];
  contact?: string[];

  sortby?: string;
  selected?: number;
  done_tagging?: boolean;
  done_pre_tagging?: boolean;
  from_more_search_option?: boolean;
  current_grantee_stage?: string;
  any_location?: boolean;
  npo_uid?: number;
  previous_funders?: string[];

  incorporated_status?: string[];
  year_of_formation_min?: number;
  year_of_formation_max?: number;
  tax_exempt_status?: string[];
  npo_grant_amount_min?: number;
  npo_grant_amount_max?: number;
  npo_assets?: string[];
  npo_month_of_liquid?: string[];
  npo_financial_exact_value_filters?: AdvancedFilter[];
  npo_financial_per_revenue_filters?: AdvancedFilter[];
  npo_financial_per_expense_filters?: AdvancedFilter[];

  updated_fields?: string[];
}
export interface ISearchParam extends ISearchParamNullable {
  grant_id?: string;
  donor_id?: string;
  text_query: string;
  mission: string;
  focus_area: string[];
  beneficiary: string[];
  program: string[];
  service_loc: ILocation[];
  hq_loc: ILocation[];
  stars: string[];
  excludes: string[];
  tagged_focus_area: string[];
  tagged_beneficiary: string[];
  tagged_program: string[];
  focus_area_subs: string[];
  beneficiary_subs: string[];
  program_subs: string[];
  service_loc_subs: ILocation[];
  hq_loc_subs: ILocation[];
  type: SearchType[];

  solicitation: string[];
  grant_type: string[];
  grantee_type: string[];
  grant_deadline?: string; // datetime
  grant_deadline_filter: string[];
  funder_type: string[];
  funding_type: string[];
  funding_size: string[];
  funder_assets: string[];
  grantee_stage: string[];
  contact: string[];

  sortby: string;
  selected: number;
  done_pre_tagging: boolean;
  done_tagging: boolean;
  from_more_search_option: boolean;
  without_blink?: boolean;
  current_grantee_stage: string;
  any_location: boolean;
  npo_uid?: number;
  previous_funders: string[];

  incorporated_status: string[];
  year_of_formation_min: number | undefined;
  year_of_formation_max: number | undefined;
  tax_exempt_status: string[];
  npo_grant_amount_min: number | undefined;
  npo_grant_amount_max: number | undefined;
  npo_assets: string[];
  npo_month_of_liquid: string[];
  npo_financial_exact_value_filters: AdvancedFilter[];
  npo_financial_per_revenue_filters: AdvancedFilter[];
  npo_financial_per_expense_filters: AdvancedFilter[];
}
// SearchQuery will be sent to backend
export interface SearchQueryNullable {
  donor_id?: string;
  grant_id?: string;
  type?: SearchType[];
  user_id?: string;
  org_id?: string;
  text_query?: string;
  mission?: string | undefined;
  hidden?: string[];
  focus_area?: string[];
  beneficiary?: string[];
  program?: string[];
  service_loc?: ILocation[];
  hq_loc?: ILocation[];
  focus_area_subs?: string[];
  beneficiary_subs?: string[];
  program_subs?: string[];
  service_loc_subs?: ILocation[];
  hq_loc_subs?: ILocation[];
  tagged_focus_area?: string[];
  tagged_beneficiary?: string[];
  tagged_program?: string[];
  must_focus_area?: string[];
  must_beneficiary?: string[];
  must_program?: string[];
  must_service_loc?: ILocation[];
  must_hq_loc?: ILocation[];
  exclude_focus_area?: string[];
  exclude_beneficiary?: string[];
  exclude_program?: string[];
  exclude_service_loc?: ILocation[];
  exclude_hq_loc?: ILocation[];
  solicitation?: string[];
  grant_type?: string[];
  grantee_type?: string[];
  grant_deadline?: string; // datetime
  grant_deadline_filter?: string[];
  funder_type?: string[];
  funding_type?: string[];
  funding_size?: string[];
  funder_assets?: string[];
  grantee_stage?: string[];
  contact?: string[]; // phone linkedin email mailing
  sortby?: string;
  pagination_skip?: number;
  pagination_limit?: number;
  fetch_taxonomy?: boolean;
  current_grantee_stage?: string;
  any_location?: boolean;
  npo_uid?: number;
  previous_funders?: string[];

  incorporated_status?: string[];
  year_of_formation_min?: number;
  year_of_formation_max?: number;
  tax_exempt_status?: string[];
  npo_grant_amount_min?: number;
  npo_grant_amount_max?: number;
  npo_assets?: string[];
  npo_month_of_liquid?: string[];
  npo_financial_exact_value_filters?: AdvancedFilter[];
  npo_financial_per_revenue_filters?: AdvancedFilter[];
  npo_financial_per_expense_filters?: AdvancedFilter[];
  selected?: number;
  without_blink?: boolean;
  updated_fields?: string[];
}
export interface SearchQuery extends SearchQueryNullable {
  donor_id?: string;
  user_id: string;
  org_id: string;
  text_query: string;
  mission: string | undefined;
  hidden_uids?: number[];
  focus_area: string[];
  beneficiary: string[];
  program: string[];
  service_loc: ILocation[];
  hq_loc: ILocation[];
  focus_area_subs: string[];
  beneficiary_subs: string[];
  program_subs: string[];
  service_loc_subs: ILocation[];
  hq_loc_subs: ILocation[];
  tagged_focus_area: string[];
  tagged_beneficiary: string[];
  tagged_program: string[];
  must_focus_area: string[];
  must_beneficiary: string[];
  must_program: string[];
  must_service_loc: ILocation[];
  must_hq_loc: ILocation[];
  exclude_focus_area: string[];
  exclude_beneficiary: string[];
  exclude_program: string[];
  exclude_service_loc: ILocation[];
  exclude_hq_loc: ILocation[];
  solicitation: string[];
  grant_type: string[];
  grantee_type: string[];
  grant_deadline?: string; // datetime
  grant_deadline_filter: string[];
  funder_type: string[];
  funding_type: string[];
  funding_size: string[];
  funder_assets: string[];
  grantee_stage: string[];
  contact: string[]; // phone linkedin email mailing
  sortby: string;
  pagination_skip?: number;
  pagination_limit?: number;
  fetch_taxonomy?: boolean;
  current_grantee_stage: string;
  any_location: boolean;
  npo_uid?: number;
  previous_funders: string[];

  incorporated_status: string[];
  year_of_formation_min: number | undefined;
  year_of_formation_max: number | undefined;
  tax_exempt_status: string[];
  npo_grant_amount_min: number | undefined;
  npo_grant_amount_max: number | undefined;
  npo_assets: string[];
  npo_month_of_liquid: string[];
  npo_financial_exact_value_filters: AdvancedFilter[];
  npo_financial_per_revenue_filters: AdvancedFilter[];
  npo_financial_per_expense_filters: AdvancedFilter[];
}
const defaultSearchQuery: SearchQuery = {
  focus_area_subs: [],
  beneficiary_subs: [],
  program_subs: [],
  service_loc_subs: [],
  hq_loc_subs: [],
  focus_area: [],
  beneficiary: [],
  program: [],
  service_loc: [],
  hq_loc: [],
  tagged_focus_area: [],
  tagged_beneficiary: [],
  tagged_program: [],
  must_focus_area: [],
  must_beneficiary: [],
  must_program: [],
  must_service_loc: [],
  must_hq_loc: [],
  exclude_focus_area: [],
  exclude_beneficiary: [],
  exclude_program: [],
  exclude_service_loc: [],
  exclude_hq_loc: [],
  grant_deadline: undefined,
  grant_deadline_filter: [],
  solicitation: [],
  grant_type: [],
  funder_type: [], // DEFAULT_FUNDER_TYPE,
  funding_size: [],
  funding_type: [],
  funder_assets: [],
  grantee_type: [],
  grantee_stage: [],
  sortby: "total_matched_grant_amount",
  contact: [],
  hidden_uids: [] as number[],
  user_id: "",
  org_id: "",
  text_query: "",
  mission: "",
  current_grantee_stage: "buildup",
  any_location: false,
  npo_uid: undefined,
  previous_funders: [],
  incorporated_status: [],
  year_of_formation_min: undefined,
  year_of_formation_max: undefined,
  tax_exempt_status: [],
  npo_grant_amount_min: undefined,
  npo_grant_amount_max: undefined,
  npo_assets: [],
  npo_month_of_liquid: [],
  npo_financial_exact_value_filters: [],
  npo_financial_per_revenue_filters: [],
  npo_financial_per_expense_filters: [],
  updated_fields: [],
};
interface TaxonomyWithScore {
  [key: string]: number;
}
export interface ISearchHistory {
  text_query: string;
  obj: SearchQuery;
  search_type: number;
  updated_at: string;
  tagging?: {
    focus_area?: TaxonomyWithScore;
    beneficiary?: TaxonomyWithScore;
    program?: TaxonomyWithScore;
    service_loc?: ILocation[];
  };
  updated?: boolean;
  key?: string;
}

export const getValidDate = (s: string | undefined | null): Date | undefined => {
  if (!s) return undefined;
  const timestamp = Date.parse(s);
  if (!isNaN(timestamp)) return new Date(timestamp);
  return undefined;
};
export const isearch_param_ilocation_array_cols = [
  "service_loc",
  "hq_loc",
  "service_loc_subs",
  "hq_loc_subs",
];
export const isearch_param_advanced_filter_array_cols = [
  "npo_financial_exact_value_filters",
  "npo_financial_per_revenue_filters",
  "npo_financial_per_expense_filters",
];
export const isearch_param_string_array_cols = [
  "stars",
  "excludes",
  "type",
  "focus_area",
  "beneficiary",
  "program",
  "focus_area_subs",
  "beneficiary_subs",
  "program_subs",
  "tagged_focus_area",
  "tagged_beneficiary",
  "tagged_program",
  "solicitation",
  "funder_assets",
  "contact",
  "funder_type",
  "funding_type",
  "funding_size",
  "grant_deadline_filter",
  "grant_type",
  "grantee_stage",
  "previous_funders",
  "incorporated_status",
  "tax_exempt_status",
  "npo_assets",
  "npo_month_of_liquid",
  "updated_fields",
];
export const isearch_param_number_cols = [
  "npo_uid",
  "year_of_formation_min",
  "year_of_formation_max",
  "npo_grant_amount_min",
  "npo_grant_amount_max",
];
export const isearch_param_boolean_cols = [
  "done_tagging",
  "done_pre_tagging",
  "from_more_search_option",
  "any_location",
];
const getURLSearchParamsFromSearchParam = (
  param: ISearchParam,
  initializePage = false,
): URLSearchParams => {
  return new URLSearchParams([
    ["donor_id", param.donor_id || ""],
    ["grant_id", param.grant_id || ""],
    ["text_query", param.text_query],
    ["mission", param.mission || ""],
    ...isearch_param_string_array_cols.reduce((prev, key) => {
      const v = param[key as keyof typeof param];
      return v && Array.isArray(v) && v.length > 0 && typeof v[0] === "string"
        ? [...prev, ...(v as string[]).map((v) => [key, v])]
        : prev;
    }, [] as string[][]),
    ...[...isearch_param_ilocation_array_cols, ...isearch_param_advanced_filter_array_cols].reduce(
      (prev, key) => {
        const v = param[key as keyof typeof param];
        return v && Array.isArray(v) && v.length > 0 && typeof v[0] === "object"
          ? [...prev, ...(v as object[]).map((v) => [key, JSON.stringify(v)])]
          : prev;
      },
      [] as string[][],
    ),
    ...isearch_param_number_cols.reduce(
      (prev, key) =>
        param[key as keyof typeof param]
          ? [...prev, [key, `${param[key as keyof typeof param]}`]]
          : prev,
      [] as string[][],
    ),
    ...isearch_param_boolean_cols.reduce(
      (prev, key) => [...prev, [key, param[key as keyof typeof param] ? "true" : "false"]],
      [] as string[][],
    ),
    ["sortby", param.sortby],
    ...(param.grant_deadline ? [["grant_deadline", param.grant_deadline]] : []),
    ["selected", initializePage ? "0" : param.selected.toString()],
    ["current_grantee_stage", param.current_grantee_stage],
    ...(param.without_blink
      ? [["without_blink", "true"]]
      : param.without_blink === false
      ? [["without_blink", "false"]]
      : []),
  ]);
};
const getSearchQueryFromISearchParam = (
  user_id: string,
  onboardingData: OnboardingData,
  obj: ISearchParam,
): SearchQuery => {
  const service_loc_labels = obj.service_loc.map((l) => reprLocation(l));
  const hq_loc_labels = obj.hq_loc.map((l) => reprLocation(l));
  return {
    ...obj,
    selected: undefined,
    without_blink: undefined,
    hidden_uids: [],
    user_id: user_id,
    org_id: onboardingData._id,
    focus_area_subs: obj.focus_area_subs.filter((t) => !obj.focus_area.includes(t)),
    beneficiary_subs: obj.beneficiary_subs.filter((t) => !obj.beneficiary.includes(t)),
    program_subs: obj.program_subs.filter((t) => !obj.program.includes(t)),
    service_loc_subs: obj.service_loc_subs.filter(
      (t) => !service_loc_labels.includes(reprLocation(t)),
    ),
    hq_loc_subs: obj.hq_loc_subs.filter((t) => !hq_loc_labels.includes(reprLocation(t))),
    must_focus_area: obj.focus_area.filter((i) => isMarkedAsStar(obj.stars, "focus_area", i)),
    must_beneficiary: obj.beneficiary.filter((i) => isMarkedAsStar(obj.stars, "beneficiary", i)),
    must_program: obj.program.filter((i) => isMarkedAsStar(obj.stars, "program", i)),
    must_service_loc: obj.service_loc.filter((i) =>
      isMarkedAsStar(obj.stars, "service_loc", reprLocation(i)),
    ),
    must_hq_loc: obj.hq_loc.filter((i) => isMarkedAsStar(obj.stars, "hq_loc", reprLocation(i))),
    exclude_focus_area: obj.focus_area_subs.filter((i) =>
      isMarkedAsExcluded(obj.excludes, "focus_area", i),
    ),
    exclude_beneficiary: obj.beneficiary_subs.filter((i) =>
      isMarkedAsExcluded(obj.excludes, "beneficiary", i),
    ),
    exclude_program: obj.program_subs.filter((i) => isMarkedAsExcluded(obj.excludes, "program", i)),
    exclude_service_loc: obj.service_loc_subs.filter((i) =>
      isMarkedAsExcluded(obj.excludes, "service_loc", reprLocation(i)),
    ),
    exclude_hq_loc: obj.hq_loc_subs.filter((i) =>
      isMarkedAsExcluded(obj.excludes, "hq_loc", reprLocation(i)),
    ),
  };
};
const getURLSearchParamsFromSearchQuery = (
  onboardingData: OnboardingData,
  query: SearchQueryNullable,
  search_type?: number,
  from_more_search_option = false,
  use_tagging_operator = false,
): URLSearchParams => {
  const service_loc_labels = query.service_loc?.map((l) => reprLocation(l));
  const hq_loc_labels = query.hq_loc?.map((l) => reprLocation(l));
  const param: ISearchParam = {
    ...getISearchParamFromOnboardingData(onboardingData),
    ...Object.keys(query).reduce(
      (prev, key) => ({
        ...prev,
        ...(query[key as keyof typeof query] !== undefined
          ? { [key]: query[key as keyof typeof query] }
          : {}),
      }),
      {},
    ),
    ...(query.focus_area || query.focus_area_subs
      ? {
          focus_area_subs: [
            ...(query.focus_area || []),
            ...(query.focus_area_subs?.filter(
              (t) => !query.focus_area || !query.focus_area.includes(t),
            ) || []),
          ],
        }
      : {}),
    ...(query.beneficiary || query.beneficiary_subs
      ? {
          beneficiary_subs: [
            ...(query.beneficiary || []),
            ...(query.beneficiary_subs?.filter(
              (t) => !query.beneficiary || !query.beneficiary.includes(t),
            ) || []),
          ],
        }
      : {}),
    ...(query.program || query.program_subs
      ? {
          program_subs: [
            ...(query.program || []),
            ...(query.program_subs?.filter((t) => !query.program || !query.program.includes(t)) ||
              []),
          ],
        }
      : {}),
    ...(query.service_loc || query.service_loc_subs
      ? {
          service_loc_subs: [
            ...(query.service_loc || []),
            ...(query.service_loc_subs?.filter(
              (t) => !service_loc_labels || !service_loc_labels.includes(reprLocation(t)),
            ) || []),
          ],
        }
      : {}),
    ...(query.hq_loc || query.hq_loc_subs
      ? {
          hq_loc_subs: [
            ...(query.hq_loc || []),
            ...(query.hq_loc_subs?.filter(
              (t) => !hq_loc_labels || !hq_loc_labels.includes(reprLocation(t)),
            ) || []),
          ],
        }
      : {}),
    stars: [
      ...(query?.must_service_loc?.map((l) => getStarKey("service_loc", reprLocation(l))) || []),
      ...(query?.must_hq_loc?.map((l) => getStarKey("hq_loc", reprLocation(l))) || []),
      ...(query?.must_focus_area?.map((i) => getStarKey("focus_area", i)) || []),
      ...(query?.must_beneficiary?.map((i) => getStarKey("beneficiary", i)) || []),
      ...(query?.must_program?.map((i) => getStarKey("program", i)) || []),
    ],
    excludes: [
      ...(query?.exclude_service_loc?.map((l) => getExcludedKey("service_loc", reprLocation(l))) ||
        []),
      ...(query?.exclude_hq_loc?.map((l) => getExcludedKey("hq_loc", reprLocation(l))) || []),
      ...(query?.exclude_focus_area?.map((i) => getExcludedKey("focus_area", i)) || []),
      ...(query?.exclude_beneficiary?.map((i) => getExcludedKey("beneficiary", i)) || []),
      ...(query?.exclude_program?.map((i) => getExcludedKey("program", i)) || []),
    ],
    done_pre_tagging: true,
    done_tagging: use_tagging_operator ? false : true,
    ...(search_type !== null && search_type !== undefined && search_type >= 0 && search_type < 7
      ? {
          type: [
            [
              "virtual_grant",
              "funder",
              "grant",
              "npo",
              "by_name",
              "past_grant",
              "specific_funder_grant",
              "eligible_npo",
            ][search_type] as SearchType,
          ],
        }
      : {}),
    ...(from_more_search_option ? { from_more_search_option } : {}),
  };
  return getURLSearchParamsFromSearchParam(param);
};

const valid_options = {
  solicitation: SOLICITATION_OPTION,
  funder_assets: ASSET_SIZE_OPTION,
  contact: CONTACT_INFO_OPTION,
  funder_type: ALL_FUNDER_TYPE.map((v) => ({ label: v, value: v })),
  funding_type: FUNDING_TYPE_OPTION,
  funding_size: FUNDING_SIZE_OPTION,
  grant_type: GRANT_TYPE_OPTION,
  grant_deadline_filter: DEADLINE_OPTION,
  grantee_stage: STAGE_OPTION,
  previous_funders: PREVIOUS_FUNDER_OPTION,
  incorporated_status: NPO_INCOPORATED_STATUS_OPTION,
  tax_exempt_status: NPO_TAX_EXEMPT_STATUS_OPTION,
  npo_assets: NPO_ASSET_SIZE_OPTION,
  npo_month_of_liquid: NPO_MONTH_OF_LIQUID_OPTION,
};
const default_options_and_options_to_replace = {
  funder_type: {
    replace_if: {
      virtual_grant: DEFAULT_FUNDER_TYPE,
      funder: DEFAULT_FUNDER_TYPE,
      grant: [],
      npo: [],
      by_name: [],
      past_grant: [],
      specific_funder_grant: [],
    } as { [key: string]: string[] },
  },
};
const min_max_pair: string[][] = []; // [["year_of_formation_min", "year_of_formation_max"]];
const getObjectFromURLSearchParams = (
  searchParams: URLSearchParams,
  tagging_operator?: ITaxonomyTaggingOperator,
): ISearchParam => {
  const valid_types: SearchType[] =
    searchParams
      .getAll("type")
      .filter((item) =>
        [
          "virtual_grant",
          "funder",
          "npo",
          "by_name",
          "grant",
          "past_grant",
          "specific_funder_grant",
          "eligible_npo",
        ].includes(item),
      )
      .map((item) => item as SearchType) || [];
  const updated_fields = searchParams.getAll("updated_fields");
  const is_opencall = valid_types[0] === "grant";
  const valid_params = Object.keys(valid_options).reduce(
    (prev, key) => ({
      ...prev,
      [key]:
        !updated_fields.includes(key) &&
        Object.keys(default_options_and_options_to_replace).includes(key) &&
        default_options_and_options_to_replace[
          key as keyof typeof default_options_and_options_to_replace
        ].replace_if[valid_types[0] as string]
          ? default_options_and_options_to_replace[
              key as keyof typeof default_options_and_options_to_replace
            ].replace_if[valid_types[0] as string]
          : searchParams
              .getAll(key)
              .filter((item) =>
                (
                  valid_options[key as keyof typeof valid_options] as (
                    | IOption
                    | { label: string; value: string }
                    | { label: ReactElement; value: string }
                  )[]
                )?.find((o) => o.value === item),
              ),
    }),
    {},
  );
  const _grant_deadline_date = searchParams.get("grant_deadline");
  const valid_grant_deadline_date = getValidDate(_grant_deadline_date)?.toISOString();
  const _valid_grantee_stage = searchParams.get("current_grantee_stage");
  const valid_grantee_stage = _valid_grantee_stage
    ? STAGE_OPTION.find((v) => v.value === _valid_grantee_stage)?.value
    : "";
  const _sort = searchParams.get("sortby");
  const valid_sort =
    _sort &&
    (valid_types.includes("grant")
      ? GRANT_SORT_OPTION
      : valid_types.includes("npo")
      ? NPO_SORT_OPTION
      : SORT_OPTION
    ).find((s) => s.value === _sort)
      ? _sort
      : valid_types.includes("funder") || valid_types.includes("virtual_grant")
      ? "total_matched_grant_amount"
      : "relevance";
  const _without_blink = searchParams.get("without_blink");
  const _any_location = searchParams.get("any_location");
  const _selected = searchParams.get("selected");
  const valid_selected = getValidNumber(_selected);
  const defaultMustHaveTags = is_opencall
    ? []
    : [
        ...(tagging_operator?.focus_area
          .filter((i) => i.opr === "and")
          .map((i) => getStarKey("focus_area", i.tag)) || []),
        ...(tagging_operator?.beneficiary
          .filter((i) => i.opr === "and")
          .map((i) => getStarKey("beneficiary", i.tag)) || []),
        ...(tagging_operator?.program
          .filter((i) => i.opr === "and")
          .map((i) => getStarKey("program", i.tag)) || []),
      ];
  return {
    ...(isearch_param_string_array_cols
      .filter((key) => key !== "type" && !Object.keys(valid_options).includes(key))
      .reduce((prev, key) => ({ ...prev, [key]: searchParams.getAll(key) || [] }), {}) as {
      stars: string[];
      excludes: string[];
      // type: string[];
      focus_area: string[];
      beneficiary: string[];
      program: string[];
      focus_area_subs: string[];
      beneficiary_subs: string[];
      program_subs: string[];
      tagged_focus_area: string[];
      tagged_beneficiary: string[];
      tagged_program: string[];
    }),
    stars:
      !updated_fields.includes("focus_area") &&
      !updated_fields.includes("beneficiary") &&
      !updated_fields.includes("program")
        ? is_opencall
          ? searchParams
              .getAll("stars")
              .filter(
                (i) =>
                  !i.startsWith("focus_area_") &&
                  !i.startsWith("beneficiary_") &&
                  !i.startsWith("program_"),
              )
          : [
              ...defaultMustHaveTags,
              ...searchParams.getAll("stars").filter((i) => !defaultMustHaveTags.includes(i)),
            ]
        : searchParams.getAll("stars"),
    ...(valid_params as {
      solicitation: string[];
      funder_assets: string[];
      contact: string[];
      funder_type: string[];
      funding_type: string[];
      funding_size: string[];
      grant_type: string[];
      grant_deadline_filter: string[];
      grantee_stage: string[];
      previous_funders: string[];
      incorporated_status: string[];
      tax_exempt_status: string[];
      npo_assets: string[];
      npo_month_of_liquid: string[];
    }),
    ...(isearch_param_ilocation_array_cols.reduce(
      (prev, key) => ({ ...prev, [key]: parseLocation(searchParams, key) }),
      {},
    ) as {
      service_loc: ILocation[];
      hq_loc: ILocation[];
      service_loc_subs: ILocation[];
      hq_loc_subs: ILocation[];
    }),
    ...(isearch_param_advanced_filter_array_cols.reduce(
      (prev, key) => ({ ...prev, [key]: parseAdvancedFilter(searchParams, key) }),
      {},
    ) as {
      npo_financial_exact_value_filters: AdvancedFilter[];
      npo_financial_per_revenue_filters: AdvancedFilter[];
      npo_financial_per_expense_filters: AdvancedFilter[];
    }),
    text_query: searchParams.get("text_query") || "",
    mission: searchParams.get("mission") || "",
    donor_id: searchParams.get("donor_id") || "",
    grant_id: searchParams.get("grant_id") || "",
    type: valid_types,
    grant_deadline: valid_grant_deadline_date,
    grantee_type: [],
    sortby: valid_sort,
    ...(isearch_param_boolean_cols
      .filter((key) => key !== "any_location")
      .reduce((prev, key) => {
        const _v = searchParams.get(key);
        return { ...prev, [key]: _v && _v?.toLowerCase() === "true" ? true : false };
      }, {}) as {
      done_tagging: boolean;
      done_pre_tagging: boolean;
      from_more_search_option: boolean;
      any_location: boolean;
    }),
    selected: valid_selected ? valid_selected : 0,
    without_blink: _without_blink && _without_blink.toLowerCase() === "true" ? true : false,
    any_location:
      _any_location &&
      _any_location.toLowerCase() === "true" &&
      parseLocation(searchParams, "service_loc").length === 0
        ? true
        : false,
    current_grantee_stage: valid_grantee_stage || "buildup",
    ...(isearch_param_number_cols
      .filter((key) => !min_max_pair.reduce((p, c) => [...p, ...c], []).includes(key))
      .reduce((prev, key) => {
        const _v = searchParams.get(key);
        const _valid_v = getValidNumber(_v);
        return { ...prev, [key]: _valid_v };
      }, {}) as {
      npo_uid: number | undefined;
      npo_grant_amount_min: number | undefined;
      npo_grant_amount_max: number | undefined;
      year_of_formation_min: number | undefined;
      year_of_formation_max: number | undefined;
    }),
    ...(min_max_pair.reduce((prev, cur) => {
      const _a = searchParams.get(cur[0]);
      const _b = searchParams.get(cur[1]);
      const _valid_a = getValidNumber(_a);
      const _valid_b = getValidNumber(_b);
      const arr = [_valid_a, _valid_b].sort((a, b) =>
        a !== undefined && b !== undefined ? a - b : a === undefined ? 1 : -1,
      );
      return { ...prev, ...{ [cur[0]]: arr[0], [cur[1]]: arr[1] } };
    }, {}) as {
      year_of_formation_min: number | undefined;
      year_of_formation_max: number | undefined;
    }),
  };
};
export {
  defaultSearchQuery,
  getObjectFromURLSearchParams,
  getSearchQueryFromISearchParam,
  getSearchResultKey,
  getURLSearchParamsFromSearchParam,
  getURLSearchParamsFromSearchQuery,
  getValidStage,
  getValidTaxExemption,
  stageFilterOptions,
};
