import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import config from "../api";
import { signupUserState } from "../app/recoilStore";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import Spacer from "../components/Spacer";
import img from "./img/mail.svg";
import GibooToast from "../components/GibooToast";
import usePageTitle from "../hooks/usePagetitle";

function PendingVerifyView() {
  usePageTitle("Verify");
  const [signupUser] = useRecoilState(signupUserState);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const retry = async (credentials: any) => {
    setIsLoading(true);
    try {
      const req = {
        origin: window.location.origin,
        email: credentials.email,
        firstname: credentials.firstname,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v2/auth/send_email_verify",
        req,
        config,
      );
      if (response) {
        setIsLoading(false);
        GibooToast({ type: "success", message: "The email has been sent." });
      }
      // console.log(response);
    } catch (error) {
      setIsLoading(false);
      if (error) GibooToast({ type: "failure", message: "Failed to send an email." });
      // console.log(error);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center bg-gray-50">
        <Spacer height="48px" />
        <div className="flex h-[224px] w-[224px] justify-center pt-5">
          <img alt="img1" src={img} style={{ zIndex: 10 }} />
        </div>
        <div
          className="dashboard-white-box !shadow-md"
          style={{
            width: "800px",
            height: "600px",
            position: "relative",
            top: "-120px",
            zIndex: 0,
          }}
        >
          <Spacer height="123px" />
          <div className="flex flex-row justify-center">
            <h3 style={{ width: "500px", textAlign: "center" }}>
              We sent the verification email, <br />
              please verify
            </h3>
          </div>
          <Spacer height="64px" />
          <div className="flex flex-row justify-center pb-4">
            <PrimaryButton
              size="lg"
              id={"btn-resend-email"}
              style={{ width: "480px" }}
              handleOnClick={() => {
                retry(signupUser);
              }}
              loading={isLoading}
              label="Haven't received the email yet? Send it again"
              tonal={true}
            />
          </div>
          <div className="flex flex-row justify-center pb-4">
            <SecondaryButton
              size="lg"
              className="!h-12"
              id={"btn-goto-signup"}
              style={{ width: "480px" }}
              handleOnClick={() => {
                navigate("/signup");
              }}
              label="Back to sign up"
              tonal={true}
            />
          </div>
          <div className="flex flex-row justify-center pb-4">
            <SecondaryButton
              size="lg"
              id={"btn-goto-home"}
              className="!h-12"
              style={{ width: "480px" }}
              handleOnClick={() => {
                navigate("/");
              }}
              label="Back to homepage"
              tonal={true}
            />
          </div>
          <Spacer height="110px" />
        </div>
      </div>
    </>
  );
}

export default PendingVerifyView;
