import { useCallback } from "react";
import { IOrgProfile, IOrgProfileUpdateRequest } from "../types/org";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../api";
import { swrOptionFetchOnlyOnMount } from "../types/swr";
interface OrgProfileState {
  data: IOrgProfile;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  update: (req: IOrgProfileUpdateRequest) => Promise<IOrgProfile | undefined>;
  revalidate: () => Promise<IOrgProfile | undefined>;
}
const defaultOrgProfile: IOrgProfile = {
  org_id: "",
  project_layout_x: 2,
  project_layout_y: 4,
  activity_layout_x: 2,
  activity_layout_y: 4,
  //default: all false
};
const getOrgProfile = async (org_id: string) => {
  if (!org_id) return new Promise<IOrgProfile>((resolve, reject) => reject());
  return axios
    .get(process.env.REACT_APP_API_URL + `/api/v2/projects/${org_id}/profile`, config)
    .then((res) => res.data as IOrgProfile);
};
const updateOrgProfile = async (org_id: string, req: IOrgProfileUpdateRequest) => {
  if (!org_id) return new Promise<IOrgProfile>((resolve, reject) => reject());
  return axios
    .put(process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/profile`, req, config)
    .then((res) => res.data as IOrgProfile);
};
function useOrgProfile(org_id?: string): OrgProfileState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/profile`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IOrgProfile>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as IOrgProfile);
  };
  const { data, isLoading, error, isValidating } = useSWR<IOrgProfile>(
    org_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const update = useCallback(
    async (req: IOrgProfileUpdateRequest) => {
      if (!org_id) return;
      const options = {
        optimisticData: {
          ...(data ? data : {}),
          ...Object.keys(req).reduce(
            (prev: IOrgProfileUpdateRequest, cur) => ({
              ...prev,
              ...(req[cur as keyof typeof req] !== undefined
                ? { [cur]: req[cur as keyof typeof req] }
                : {}),
            }),
            {} as IOrgProfileUpdateRequest,
          ),
        } as IOrgProfile,
        rollbackOnError: true,
      };
      return mutate<IOrgProfile>(
        url,
        axios.put(url, req, config).then((response) => response.data),
        options,
      );
    },
    [org_id, url, data],
  );

  const revalidate = useCallback(() => mutate<IOrgProfile>(url), [url]);
  return {
    data: data || defaultOrgProfile,
    isLoading,
    error,
    isValidating,
    update,
    revalidate,
  };
}
export { getOrgProfile, updateOrgProfile };
export default useOrgProfile;
