import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import uuid from "react-uuid";
import { useRecoilState } from "recoil";
import useSWR, { useSWRConfig } from "swr";
import config from "../../api";
import { appendProjectAsync } from "../../app/projectSlice";
import { sidekickDocumentDefinition, sidekickDocumentTarget } from "../../app/recoilStore";
import { useAppDispatch } from "../../app/store";
import { MIXPANEL_EVENTS_V2 } from "../../mixpanel/mixpanel";
import {
  ChatActionLetter,
  ChatActionTarget,
  DocumentActionTypeEnum,
  DocumentChatRequest,
  DocumentCreateRequest,
  DocumentCustomQuestion,
  DocumentDefinition,
  DocumentNode,
  DocumentNodeType,
  DocumentOperatorEnum,
  DocumentTreeNode,
  DocumentTypeEnum,
  DocumentUpdateRequest,
  GibooDocument,
} from "../../types/document";
import { IProject } from "../../types/project";
import { currentDateString } from "../../utils/dateUtils";
import useGibooMixpanel from "../useGibooMixpanel";
import useGibooNavigate from "../useGibooNavigate";
import useOrgID from "../useOrgID";
import useUser from "../useUser";
import useDocumentDefinitions from "./useDocumentDefinitions";
import {
  DOCUMENT_RECENT_LIMIT,
  DOCUMENT_RECENT_LIMIT_LAST_DAYS,
  getDocumentSearchURL,
} from "./useDocumentSearch";
import { createProject } from "./useProject";
interface ChatStreamResponse {
  document_id: string;
  streaming_node_id?: string;
  isLoading: boolean;
  error?: string;
  nodes: DocumentNode[];
  messages: string[];
  target: ChatActionTarget[];
  letters: ChatActionLetter[];
  controller?: AbortController;
  canceled?: boolean;
}
const defaultChatStreamResponse: ChatStreamResponse = {
  document_id: "",
  streaming_node_id: undefined,
  isLoading: false,
  error: undefined,
  nodes: [],
  messages: [],
  target: [],
  letters: [],
  controller: undefined,
  canceled: undefined,
};
interface IDS {
  project_id: string;
  document_id: string;
  created?: boolean;
}
interface GibooChatState {
  target_id: string | undefined;
  target_type: 0 | 1 | 2 | undefined;
  chat: (
    req: DocumentChatRequest,
    document_id_by_param?: string,
    createNewDocument?: boolean,
  ) => Promise<IDS | undefined>;
  modify: (req: DocumentChatRequest, document_id_by_param?: string) => Promise<IDS | undefined>;
  stop: () => void;
  regenerate: (parent_id: string) => Promise<IDS | undefined>;
  createDocument: (definition: DocumentDefinition, name?: string) => Promise<string | undefined>;
  updateDocument: (
    req: DocumentUpdateRequest,
    document_id_by_param?: string,
  ) => Promise<string | undefined>;
  data: GibooDocument | undefined;
  nodes: DocumentNode[];
  root: DocumentTreeNode | undefined;
  route: string[];
  messages: string[];
  recent: string | undefined;
  nodeDict: { [key: string]: DocumentTreeNode };
  letters: ChatActionLetter[];
  error: any;
  isLoading: boolean;
  isChatLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<GibooDocument | undefined>;
  visit: (parent_id: string, node_id: string) => void;
  documentDefinition: DocumentDefinition | undefined;
  streamingNodeId: string | undefined;
  addTemporaryNodeForPastingToTheChatBox: (parent_id: string) => void;
}
interface QueueNode {
  req: DocumentChatRequest;
  virtualNodes: DocumentNode[];
}
function check_condition(condition?: object, context?: any, depth = 0): boolean {
  if (!context || !condition) return false;
  const arr = Object.keys(condition).map((key) => {
    if (key === DocumentOperatorEnum.EQUAL) {
      return condition[key as keyof typeof condition] === context ? true : false;
    } else if (key === DocumentOperatorEnum.SIZE) {
      if (!context || !Array.isArray(context)) return false;
      return check_condition(condition[key as keyof typeof condition], context?.length, depth + 1)
        ? true
        : false;
    } else {
      return check_condition(condition[key as keyof typeof condition], context?.[key], depth + 1)
        ? true
        : false;
    }
  });
  return arr.every(Boolean) ? true : false;
}
const reqToNode = (
  req: DocumentChatRequest,
  nodeDict: { [key: string]: DocumentTreeNode },
  user_id: string,
  document_id: string,
  virtual_id: string,
  forced_type?: DocumentNodeType,
  action_type?: DocumentActionTypeEnum,
): DocumentNode => {
  const _parent = req.parent_id ? nodeDict?.[req.parent_id] : undefined;
  return {
    ...req,
    _id: virtual_id,
    user_id,
    created_at: currentDateString(),
    document_id,
    type: forced_type
      ? forced_type
      : _parent?.type === DocumentNodeType.BOT || _parent?.type === DocumentNodeType.USER_CORRECTION
      ? DocumentNodeType.USER
      : _parent?.type === DocumentNodeType.CUSTOM_QUESTION
      ? DocumentNodeType.ANSWER_TO_CUSTOM_QUESTION
      : DocumentNodeType.BOT,
    action_type,
  };
};
const targetRegex = /^[01]_[a-f0-9]{24}$/;
const MIN_ANSWER_TO_CREATE_DOCUMNET = 1;
function useGibooChat(
  project_id?: string,
  document_id?: string,
  intendedDocumentType?: DocumentTypeEnum,
): GibooChatState {
  const mxEvent = useGibooMixpanel();
  const [user] = useUser();
  const navigate = useGibooNavigate();
  const browerLocation = useLocation();
  const dispatch = useAppDispatch();
  const org_id = useOrgID();
  const [searchParams, setSearchParams] = useSearchParams();
  const url = process.env.REACT_APP_API_URL + `/api/v2/document/${document_id}`;
  const nodes_url = process.env.REACT_APP_API_URL + `/api/v2/document/${document_id}/nodes`;
  const [documentDefinition, setDocumentDefinition] = useRecoilState(sidekickDocumentDefinition);
  const { data: definitions } = useDocumentDefinitions();
  const [target_id, setTargetId] = useState<string | undefined>();
  const [target_type, setTargetType] = useRecoilState(sidekickDocumentTarget);
  const [streamResponse, setStreamResponse] =
    useState<ChatStreamResponse>(defaultChatStreamResponse);
  const [mergedNodes, setMergedNodes] = useState<DocumentNode[]>([]);
  const [nodesByStreaming, setNodesByStreaming] = useState<DocumentNode[]>([]);
  const [root, setRoot] = useState<DocumentTreeNode | undefined>();
  const [route, setRoute] = useState<string[]>([]);
  const [nodeDict, setNodeDict] = useState<{ [key: string]: DocumentTreeNode }>({});
  const [latestChildMap, setLatestChildMap] = useState<{ [key: string]: string | undefined }>({});
  const [recent, setRecent] = useState<string | undefined>();
  const [recentByManual, setRecentByManual] = useState<string | undefined>();
  const [queue, setQueue] = useState<(QueueNode | undefined)[]>(
    Array.from({ length: MIN_ANSWER_TO_CREATE_DOCUMNET - 1 }, (_, i) => i).map((_) => undefined),
  );
  const [currentChatRequest, setCurrentChatRequest] = useState<QueueNode | undefined>();
  const { mutate } = useSWRConfig();
  const fetchDocument = async (_url: string) => {
    if (!_url) return new Promise<GibooDocument>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data);
  };
  const fetchNodes = async (_url: string) => {
    if (!_url) return new Promise<DocumentNode[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data);
  };
  const { data, isLoading, error, isValidating } = useSWR<GibooDocument>(
    document_id ? url : null,
    fetchDocument,
    {
      keepPreviousData: false,
    },
  );
  const {
    data: nodes,
    isLoading: isNodesLoading,
    error: errorNodes,
    isValidating: isNodesValidating,
  } = useSWR<DocumentNode[]>(document_id ? nodes_url : null, fetchNodes, {
    keepPreviousData: false,
  });
  const visit = useCallback(
    (parent_id: string, node_id: string) => {
      setLatestChildMap((prev) => ({ ...prev, [parent_id]: node_id }));
      setRecentByManual(node_id);
    },
    [setLatestChildMap, setRecentByManual],
  );

  useEffect(() => {
    setCurrentChatRequest(undefined);
    setQueue(
      Array.from({ length: MIN_ANSWER_TO_CREATE_DOCUMNET - 1 }, (_, i) => i).map((_) => undefined),
    );
    // console.log("context", browerLocation?.state);
    if (!browerLocation?.state?.keepPrevious) {
      setNodesByStreaming([]);
      setRoute([]);
      setNodeDict({});
      setTargetId(undefined);
      setTargetType(undefined);
      stop();
    }
  }, [document_id, searchParams]);
  useEffect(() => {
    route.map((n, i) => {
      if (i > 0) {
        setLatestChildMap((prev) =>
          prev?.[n] === route[i + 1] ? prev : { ...prev, [n]: route[i + 1] },
        );
        if (nodeDict?.[route[i - 1]]?.action_type === DocumentActionTypeEnum.CHOOSE_TARGET) {
          const answer = nodeDict?.[route[i]]?.answer;
          if (answer && targetRegex.test(answer)) {
            setTargetType(answer[0] === "0" ? 0 : answer[0] === "1" ? 1 : undefined);
            setTargetId(answer.slice(2));
          } else {
            setTargetId("");
          }
        }
      }
    });
  }, [route, setLatestChildMap, setTargetId, setTargetType]);

  const createProjectAndApplySlice = useCallback(
    async (org_id: string) => {
      return createProject({
        name: "Untitled project",
        org_id,
      })
        .then(async (res: IProject) => {
          return Promise.resolve(dispatch(appendProjectAsync(res)))
            .then(() => {
              // mxEvent(MIXPANEL_EVENTS.PROJECT_CREATION.PROJECT_CREATED, {
              //   name: res.name,
              //   organizationId: org_id,
              //   location: location.pathname,
              //   projectId: res._id,
              // });
              return res._id;
            })
            .catch(() => undefined);
        })
        .catch(() => undefined);
    },
    [dispatch, createProject, appendProjectAsync],
  );

  const createDocument = useCallback(
    async (
      definition: DocumentDefinition,
      name?: string,
      returnProjectId = false,
      controller?: AbortController,
    ) => {
      if (!org_id) return new Promise<string | undefined>((resolve, reject) => reject());
      const _project_id = project_id ? project_id : await createProjectAndApplySlice(org_id);
      return axios
        .post(
          process.env.REACT_APP_API_URL + `/api/v2/document`,
          {
            name: name || `${definition.short_name || definition.name || "Custom document"} draft`,
            org_id,
            project_id: _project_id,
            type: definition.type,
          } as DocumentCreateRequest,
          { ...config, ...(controller ? { signal: controller?.signal } : {}) },
        )
        .then((res) => {
          // mxEvent(MIXPANEL_EVENTS.DOCUMENT.OUTREACH_DOCUMENT_CREATED, {
          //   name: name || `${definition.short_name || definition.name || "Custom document"} draft`,
          //   organizationId: org_id,
          // });
          return returnProjectId ? `${res.data}@${_project_id}` : res.data;
        });
    },
    [mutate, createProjectAndApplySlice, org_id, project_id],
  );

  const updateDocument = useCallback(
    async (req: DocumentUpdateRequest, document_id_by_param?: string) => {
      if (!document_id && !document_id_by_param)
        return new Promise<string | undefined>((resolve, reject) => reject());
      return axios
        .put(
          process.env.REACT_APP_API_URL + `/api/v2/document/${document_id_by_param || document_id}`,
          req,
          config,
        )
        .then((res) => res.data as string);
    },
    [document_id],
  );
  const modify = useCallback(
    async (req: DocumentChatRequest, document_id_by_param?: string) => {
      if (!req.parent_id) return new Promise<IDS | undefined>((resolve, reject) => reject());
      const did = document_id_by_param || document_id || "";
      const pid = project_id || "";
      const virtual_id = `virtual_${uuid()}`;
      const virtualNode = reqToNode(
        req,
        nodeDict,
        user?._id || "",
        did,
        virtual_id,
        DocumentNodeType.USER_CORRECTION,
      );
      setNodesByStreaming((prev) => [
        ...prev.filter((n) => !n._id.startsWith("virtual_") && !n._id.startsWith("error_")),
        virtualNode,
      ]);
      setRecentByManual(virtual_id);
      return axios
        .post(
          process.env.REACT_APP_API_URL +
            `/api/v2/document/${document_id_by_param || document_id}/modify`,
          req,
          config,
        )
        .then((res) => res.data as DocumentNode)
        .then((res) => {
          setNodesByStreaming((prev) => [...prev.filter((p) => p._id !== virtual_id), res]);
          setRecentByManual(res._id);
          return { project_id: pid, document_id: did };
        })
        .catch(() => {
          setNodesByStreaming((prev) => prev.filter((p) => p._id !== virtual_id));
          return undefined;
        });
    },
    [
      createDocument,
      project_id,
      document_id,
      setStreamResponse,
      setNodesByStreaming,
      setRecentByManual,
      root,
      nodeDict,
      user,
    ],
  );
  const getNextQuestion = useCallback(
    (index: number, answer: string): DocumentCustomQuestion | undefined => {
      if (!documentDefinition?.custom_question) return undefined;
      let i = index;
      while (i >= 0 && i + 1 < documentDefinition.custom_question.length) {
        const skip_if_condition = documentDefinition.custom_question[i + 1]?.action_type
          ? documentDefinition.custom_question[i + 1].skip_if
          : undefined;
        if (check_condition(skip_if_condition, { [DocumentOperatorEnum.PREVIOUS_ANSWER]: answer }))
          i++;
        else break;
      }
      return i + 1 >= 0 && i + 1 < documentDefinition.custom_question.length
        ? documentDefinition.custom_question[i + 1]
        : undefined;
    },
    [documentDefinition, check_condition],
  );
  const chat = useCallback(
    async (
      req: DocumentChatRequest,
      document_id_by_param?: string,
      createNewDocument?: boolean,
      _regenerate?: boolean,
    ) => {
      if (!document_id && !document_id_by_param && !documentDefinition) return;
      if (_regenerate && (!req.parent_id || req.parent_id.startsWith("virtual_"))) return;
      let regenerate = _regenerate;
      let created = false;
      let did = document_id_by_param || document_id || "";
      let pid = project_id || "";
      let parent_id = req.parent_id;
      // console.log("hi, request chat", {
      //   parent_id,
      //   req,
      //   parent: parent_id ? nodeDict[parent_id] : undefined,
      // });
      if (regenerate) {
        if (req.parent_id?.startsWith("error_")) {
          while (
            parent_id &&
            nodeDict[parent_id] &&
            (nodeDict[parent_id].type === DocumentNodeType.ERROR ||
              nodeDict[parent_id].type === DocumentNodeType.USER ||
              nodeDict[parent_id].type === DocumentNodeType.ANSWER_TO_CUSTOM_QUESTION)
          ) {
            req.value = nodeDict[parent_id].value;
            parent_id = nodeDict[parent_id].parent_id;
          }
          req.parent_id = parent_id;
          regenerate = false;
        }
      } else {
        while (
          parent_id &&
          nodeDict[parent_id] &&
          (nodeDict[parent_id].type === DocumentNodeType.ERROR ||
            nodeDict[parent_id].type === DocumentNodeType.USER ||
            nodeDict[parent_id].type === DocumentNodeType.ANSWER_TO_CUSTOM_QUESTION)
        ) {
          parent_id = nodeDict[parent_id].parent_id;
        }
        req.parent_id = parent_id;
      }
      // console.log("hi, new parent_id", {
      //   parent_id,
      //   req,
      //   parent: parent_id ? nodeDict[parent_id] : undefined,
      // });
      const virtual_id = `virtual_${uuid()}`;
      const virtualNode = reqToNode(
        { ...req, parent_id: !req.parent_id && root ? root._id : req.parent_id },
        nodeDict,
        user?._id || "",
        did,
        virtual_id,
      );
      let depth = 0;
      let p = req.parent_id;
      while (p && nodeDict[p]) {
        depth += 1;
        p = nodeDict[p].parent_id;
      }
      const index = Math.floor(depth / 2);
      // first question -> 0
      // second question -> 2
      const nextQuestion = getNextQuestion(index, req.answer || "");
      let virtual_id2 = `virtual_${uuid()}`;
      while (virtual_id2 === virtual_id) virtual_id2 = `virtual_${uuid()}`;
      const virtualNode2 = reqToNode(
        {
          value: nextQuestion ? nextQuestion.value || "" : "",
          parent_id: regenerate ? req.parent_id : virtual_id,
        },
        nodeDict,
        "",
        did,
        virtual_id2,
        nextQuestion ? DocumentNodeType.CUSTOM_QUESTION : DocumentNodeType.BOT,
        nextQuestion ? nextQuestion.action_type : undefined,
      );
      const queueNode = { req, virtualNodes: [...(regenerate ? [] : [virtualNode]), virtualNode2] };
      // console.log("hi, chat", { req, nextQuestion, depth, did, pid, queueNode });
      if (!did && index < MIN_ANSWER_TO_CREATE_DOCUMNET - 1) {
        setQueue((prev) => [...prev.slice(0, index), queueNode, ...prev.slice(index + 1)]);
        setRecentByManual(virtual_id2);
      } else {
        const controller = new AbortController();
        setStreamResponse({
          ...defaultChatStreamResponse,
          document_id: did,
          streaming_node_id: virtual_id2,
          isLoading: true,
          controller,
        });
        setCurrentChatRequest(queueNode);
        setRecentByManual(virtual_id2);
        if (!did || createNewDocument) {
          if (!documentDefinition) {
            setStreamResponse({
              ...defaultChatStreamResponse,
              error: "couldn't find document definition",
            });
            throw new Error("couldn't find document definition");
          }
          const ids = (
            await createDocument(documentDefinition, undefined, true, controller)
              .then((d) => d || "")
              .catch(() => {
                setStreamResponse({
                  ...defaultChatStreamResponse,
                  error: "failed to create a project",
                });
                throw new Error("failed to create a project");
              })
          ).split("@");
          did = ids[0];
          pid = ids[1];
          created = true;
          if (!did || !pid) {
            setStreamResponse({
              ...defaultChatStreamResponse,
              error: "failed to create a document",
            });
            throw new Error("failed to create a document");
          }
        }
        let lastNodeId = undefined;
        if (queueNode.req.parent_id && !queueNode.req.parent_id.startsWith("virtual_")) {
          lastNodeId = queueNode.req.parent_id;
        } else {
          for (let i = 0; i < queue.length; i++) {
            const cur_node = queue[i];
            if (!cur_node) {
              setStreamResponse({
                ...defaultChatStreamResponse,
                error: `failed to chat. queue ${i} is empty`,
              });
              throw new Error(`failed to chat. queue ${i} is empty`);
            }
            lastNodeId = await handleChat(
              { ...cur_node, req: { ...cur_node.req, parent_id: lastNodeId } },
              pid,
              did,
              virtual_id2,
              false,
              true,
            );
            if (!lastNodeId) {
              setStreamResponse({
                ...defaultChatStreamResponse,
                error: `failed to chat ${JSON.stringify(queue[i]?.req || {})}`,
              });
              throw new Error(`failed to chat ${JSON.stringify(queue[i]?.req || {})}`);
            }
          }
        }
        const res = await handleChat(
          { ...queueNode, req: { ...queueNode.req, parent_id: lastNodeId } },
          pid,
          did,
          virtual_id2,
          regenerate,
        );
        if (!res) {
          setStreamResponse({
            ...defaultChatStreamResponse,
            error: `failed to chat ${JSON.stringify(queueNode.req || {})}`,
          });
          throw new Error(`failed to chat ${JSON.stringify(queueNode.req || {})}`);
        }
      }
      if (created) {
        const url = getDocumentSearchURL(
          org_id,
          "",
          project_id,
          undefined,
          0,
          DOCUMENT_RECENT_LIMIT,
          DOCUMENT_RECENT_LIMIT_LAST_DAYS,
        );
        mutate(url);
        navigate(`/project/${pid}/document/${did}`, {
          replace: !document_id ? true : false,
          state: { documentType: documentDefinition?.type, keepPrevious: true },
        });
      }
      return { project_id: pid, document_id: did, created };
    },
    [
      createDocument,
      project_id,
      document_id,
      setStreamResponse,
      setNodesByStreaming,
      setRecentByManual,
      setQueue,
      setCurrentChatRequest,
      queue,
      root,
      nodeDict,
    ],
  );
  const stop = useCallback(() => {
    streamResponse?.controller?.abort();
    setStreamResponse((prev) => ({
      ...defaultChatStreamResponse,
      canceled: true,
      isLoading: false,
    }));
  }, [streamResponse, setStreamResponse]);
  const regenerate = useCallback(
    async (parent_id: string) => {
      return chat({ value: "", parent_id }, undefined, undefined, true);
    },
    [chat],
  );
  useEffect(() => {
    const valid_document_type =
      data?.type || intendedDocumentType || searchParams.get("type") || DocumentTypeEnum.CUSTOM;
    const found = valid_document_type
      ? definitions.find((t) => t.type === (+valid_document_type as DocumentTypeEnum))
      : undefined;
    if (found) setDocumentDefinition(found);
    else {
      setDocumentDefinition(undefined);
    }
  }, [searchParams, intendedDocumentType, data, definitions]);

  useEffect(() => {
    const nodesKeys = nodes?.map((n) => n._id) || [];
    const _mergedNodes = [
      ...((!document_id || (nodes && nodes.length === 0)) && documentDefinition
        ? [
            {
              ...documentDefinition.custom_question[0],
              _id: "first_question",
              user_id: "",
              document_id: "",
              parent_id: undefined,
              type: DocumentNodeType.CUSTOM_QUESTION,
              children: [] as DocumentTreeNode[],
              created_at: "",
            } as DocumentNode,
          ]
        : []),
      ...(nodes || []),
      ...nodesByStreaming.filter(
        (n) => !nodesKeys.includes(n._id) && n.document_id === document_id,
      ),
      ...queue.reduce(
        (prev, cur) => (cur ? [...prev, ...cur.virtualNodes] : prev),
        [] as DocumentNode[],
      ),
      ...(currentChatRequest ? currentChatRequest.virtualNodes : []),
    ].sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
    setMergedNodes(_mergedNodes);
    // build tree
    const _nodeDict = _mergedNodes.reduce(
      (prev, cur) => ({
        ...prev,
        [cur._id]: {
          ...cur,
          children: [],
        },
      }),
      {} as { [key: string]: DocumentTreeNode },
    );
    let _root: DocumentTreeNode | undefined = undefined;
    for (let i = 0; i < _mergedNodes.length; i++) {
      if (_nodeDict[_mergedNodes[i]._id]) {
        const parent_id = _mergedNodes[i].parent_id;
        if (!parent_id) {
          _root = _nodeDict[_mergedNodes[i]._id];
        } else if (parent_id && Object.keys(_nodeDict).includes(parent_id)) {
          while (
            _nodeDict[parent_id]["children"].length > 0 &&
            _nodeDict[parent_id]["children"][
              _nodeDict[parent_id]["children"].length - 1
            ]._id.startsWith("virtual_")
          ) {
            _nodeDict[parent_id]["children"].pop();
          }
          _nodeDict[parent_id]["children"].push(_nodeDict[_mergedNodes[i]._id]);
        }
      }
    }
    // set child as latest if not set
    Object.keys(_nodeDict).map((key) => {
      if (_nodeDict[key]?.children && _nodeDict[key].children.length > 0) {
        setLatestChildMap((prev) =>
          prev?.[key]
            ? prev
            : { ...prev, [key]: _nodeDict[key].children[_nodeDict[key].children.length - 1]._id },
        );
      }
    });
    setNodeDict(_nodeDict);
    // console.log({ nodesByStreaming, _root, _nodeDict, _mergedNodes, route });
  }, [
    document_id,
    documentDefinition,
    nodes,
    queue,
    currentChatRequest,
    nodesByStreaming,
    setMergedNodes,
    setNodeDict,
    setLatestChildMap,
  ]);

  useEffect(() => {
    const _root = Object.keys(nodeDict).find((key) => !nodeDict[key].parent_id);
    if (_root && nodeDict[_root]) setRoot(nodeDict[_root]);
    const _previousRecent =
      data?.node_history_path && data?.node_history_path.length > 0
        ? data.node_history_path[data.node_history_path.length - 1]
        : undefined;
    const _recent =
      recentByManual && Object.keys(nodeDict).includes(recentByManual)
        ? recentByManual
        : _previousRecent && Object.keys(nodeDict).includes(_previousRecent)
        ? _previousRecent
        : _root;
    let _route: string[] = [];
    if (_recent) {
      const reversedRoute: string[] = [];
      let p: string | undefined = _recent;
      while (p) {
        if (Object.keys(nodeDict).includes(p) && nodeDict[p]) {
          reversedRoute.push(p);
          p = nodeDict[p].parent_id;
        } else break;
      }
      _route = reversedRoute.reverse();
    }
    let p: string | undefined = _route.length > 0 ? _route[_route.length - 1] : undefined;
    while (p) {
      const next = latestChildMap?.[p];
      if (next && nodeDict?.[next]) {
        _route.push(next);
        p = next;
      } else p = undefined;
    }
    setRecent((prev) =>
      _route.length > 0
        ? prev === _route[_route.length - 1]
          ? prev
          : _route[_route.length - 1]
        : undefined,
    );
    setRoute(_route);
    // console.log("nodeDict changed", {
    //   location: browerLocation.pathname,
    //   recentByManual,
    //   _root,
    //   _previousRecent,
    //   nodeDict,
    //   latestChildMap,
    //   _recent,
    //   _route,
    //   route: _route.map((id) => nodeDict?.[id]),
    // });
  }, [root, nodeDict, latestChildMap, recentByManual, setRoute, setRecent]);

  const handleChat = useCallback(
    async (
      req: QueueNode,
      pid: string,
      did: string,
      streamNode?: string,
      regenerate = false,
      keepLoading = false,
    ) => {
      mxEvent(MIXPANEL_EVENTS_V2.documentai.message.started, {
        documentaiId: document_id,
        message: req.req.value,
      });
      const controller = new AbortController();
      const virtual_id2 = req.virtualNodes[req.virtualNodes.length - 1]._id;
      let lastNodeId = undefined;
      setStreamResponse({
        ...defaultChatStreamResponse,
        document_id: did,
        streaming_node_id: streamNode,
        isLoading: true,
        controller,
      });
      setNodesByStreaming((prev) => [
        ...prev.filter((n) => !n._id.startsWith("virtual_") && !n._id.startsWith("error_")),
        ...req.virtualNodes,
      ]);
      setRecentByManual(streamNode);
      const chat_url =
        process.env.REACT_APP_SSE_API_URL +
        `/api/v2/document/${did}/chat${regenerate ? "?regenerate=true" : ""}`;
      //   const controller = new AbortController();
      try {
        // console.log("call ", target);
        const resp = await fetch(chat_url, {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": process.env.REACT_APP_API_KEY || "",
            "Access-Control-Allow-Origin": window.location.origin,
          },
          body: JSON.stringify({
            ...req.req,
            parent_id: req.req.parent_id === "first_question" ? undefined : req.req.parent_id,
          }),
          // signal: controller.signal,
          credentials: "include",
        }).catch((e) => {
          throw e;
        });
        if (!resp.ok || !resp.body) {
          throw resp.statusText;
        }
        const reader = resp.body.getReader();
        const done = false;
        while (!done) {
          const { value, done } = await reader.read().catch((e) => {
            throw e;
          });
          if (done) {
            break;
          }
          try {
            const text = new TextDecoder().decode(value).split(`\n`);
            for (let i = 0; i < text.length; i++) {
              if (!text[i]) continue;
              const decoded = JSON.parse(text[i]);
              const keys: { [key: string]: any } = Object.keys(decoded);
              if (keys.includes("c") && streamNode === virtual_id2) {
                setStreamResponse((prev) => ({
                  ...prev,
                  document_id: did,
                  messages: [...prev.messages, decoded["c"]],
                }));
                setRecentByManual(virtual_id2);
              } else if (keys.includes("nodes")) {
                setNodesByStreaming((prev) => {
                  const prevKeys = prev.map((n) => n._id);
                  return [
                    ...prev,
                    ...decoded["nodes"].filter((n: DocumentNode) => !prevKeys.includes(n._id)),
                  ];
                });
                if (
                  decoded["nodes"].length > 0 &&
                  decoded["nodes"][decoded["nodes"].length - 1]?._id
                ) {
                  lastNodeId = decoded["nodes"][decoded["nodes"].length - 1]?._id || undefined;
                  setRecentByManual(lastNodeId);
                }
              } else if (keys.includes("letters")) {
                setStreamResponse((prev) => ({
                  ...prev,
                  document_id: did,
                  letters: decoded["letters"],
                }));
              } else if (keys.includes("prompt")) {
                console.log("prompt>\n", decoded["prompt"]);
              } else if (keys.includes("latency")) {
                console.log("latency>\n", decoded["latency"]);
              }
            }
          } catch (err: any) {
            console.log("parsing error", err);
            throw err;
          }
        }
        setStreamResponse((prev) => ({ ...prev, document_id: did, isLoading: keepLoading }));
      } catch (err: any) {
        if (err.name !== "AbortError") {
          mxEvent(MIXPANEL_EVENTS_V2.documentai.message.failed, {
            documentaiId: document_id,
            message: req.req.value,
            error: "api error",
          });
          // console.log(err);
          setStreamResponse((prev) => ({
            ...prev,
            document_id: did,
            error: err.name,
            isLoading: false,
          }));
          // set node with error_id
          const decoded = {
            nodes: req.virtualNodes.reduce(
              (prev, n, i) => [
                ...prev,
                {
                  ...n,
                  _id: `error_node_${i}`,
                  parent_id: i > 0 ? `error_node_${i - 1}` : n.parent_id,
                  type: n.type & 1 ? n.type : DocumentNodeType.ERROR,
                  value:
                    n.type & 1 ? n.value : `We apologize for the inconvenience. Please try again.`,
                  created_at: currentDateString(),
                },
              ],
              [] as DocumentNode[],
            ),
          };
          setNodesByStreaming((prev) => {
            const prevKeys = prev.map((n) => n._id);
            return [
              ...prev,
              ...decoded["nodes"].filter((n: DocumentNode) => !prevKeys.includes(n._id)),
            ];
          });
          if (decoded["nodes"].length > 0 && decoded["nodes"][decoded["nodes"].length - 1]?._id) {
            lastNodeId = decoded["nodes"][decoded["nodes"].length - 1]?._id || undefined;
            setRecentByManual(lastNodeId);
          }
        } else {
          setStreamResponse({ ...defaultChatStreamResponse, isLoading: keepLoading });
        }
        return undefined;
      }
      mxEvent(MIXPANEL_EVENTS_V2.documentai.message.successful, {
        documentaiId: document_id,
        message: req.req.value,
      });
      return lastNodeId;
    },
    [
      streamResponse,
      createDocument,
      setNodesByStreaming,
      setRecentByManual,
      setStreamResponse,
      mxEvent,
    ],
  );
  const revalidate = useCallback(() => mutate<GibooDocument>(url), [url]);
  const addTemporaryNodeForPastingToTheChatBox = useCallback(
    (parent_id: string) => {
      const newNode: DocumentNode = {
        _id: `temporary_${parent_id}`,
        user_id: user?._id || "",
        created_at: currentDateString(),
        document_id: document_id || "",
        type: DocumentNodeType.USER,
        value: "",
        parent_id,
      };
      setNodesByStreaming((prev) => [...prev.filter((p) => p._id !== "temporary"), newNode]);
      setRecentByManual(newNode._id);
    },
    [document_id, setRecentByManual, setNodesByStreaming],
  );
  return {
    target_id,
    target_type,
    modify,
    chat,
    regenerate,
    stop,
    createDocument,
    updateDocument,
    data,
    root,
    route,
    recent,
    nodeDict,
    nodes: mergedNodes,
    messages: streamResponse.messages,
    letters: streamResponse.letters,
    isLoading,
    isChatLoading: streamResponse.isLoading,
    error: streamResponse.error || error,
    isValidating,
    revalidate,
    visit,
    documentDefinition,
    streamingNodeId: streamResponse.streaming_node_id,
    addTemporaryNodeForPastingToTheChatBox,
  };
}
export default useGibooChat;
