import { ReactNode, useCallback, useEffect, useState } from "react";
import useFunderStageAnalysis from "../hooks/funder/useFunderStageAnalysis";
import useFunderSearchResult from "../hooks/search/useFunderSearchResult";
import usePastGrantSearchResults from "../hooks/search/usePastGrantSearchResults";
import { SearchQuery } from "../types/search";
import GibooGradientDiv from "./GibooGradientDiv";
import classNames from "classnames";
import { Relevant } from "../app/searchResultCacheSlice";
import Spinner from "./Spinner";
import {
  filterUnmatched,
  matchTaxonomyBySearchQuery,
  matchTaxonomyBySearchQueryWithPhilanthropy,
} from "../types/taxonomy";
import usePastGrantOverview from "../hooks/usePastGrantOverview";
import {
  getFunderMatchingSummary,
  getOpencallGrantMatchingSummary,
  getVirtualGrantMatchingSummary,
} from "../services/summary.services";
import useGrantSearchResult from "../hooks/search/useGrantSearchResult";
import useOnboardingData from "../hooks/useOnboarding";
import { useSearchParams } from "react-router-dom";
import useIsPreviousFunder from "../hooks/search/useIsPreviousFunder";
import useOrgID from "../hooks/useOrgID";
import uuid from "react-uuid";
import { CountedAreas } from "./GrantDescriptionDetail";
import useFunderSummary from "../hooks/useFunderSummary";
import { queryMaskForPastGrantOverview } from "../hooks/useGibooStructureSearch";
import ICON_WHY_MATCH from "../assets/images/whyMatchedWithoutRound.svg";
import { useRecoilState, useSetRecoilState } from "recoil";
import { sidekickWhyMatched, sidekickWhyMatchedLoading } from "../app/recoilStore";
import axios from "axios";
import config from "../api";

interface WhyMatchedProps {
  type: "funder" | "virtual" | "opencall";
  query: SearchQuery;
  funder_id?: string;
  funder_uid?: number;
  grant_id?: string;
  gids?: string[];
  nameMatch?: boolean;
  countedAreas?: CountedAreas;
  onClick?: () => void;
  additionalComponent?: ReactNode;
}
function WhyMatched({
  type,
  query,
  countedAreas,
  funder_id,
  funder_uid,
  grant_id,
  gids,
  nameMatch = false,
  onClick,
  additionalComponent,
}: WhyMatchedProps) {
  const [loading, setLoading] = useRecoilState(sidekickWhyMatchedLoading);
  const [whyDesc, setWhyDesc] = useRecoilState(sidekickWhyMatched);
  const [searchParams] = useSearchParams();
  const [onboardingData] = useOnboardingData();
  const [error, setError] = useState<boolean>(false);
  const [initiateToken, setInitiateToken] = useState<string>();
  const { data: funder, isLoading: isFunderLoading } = useFunderSearchResult(funder_id, query);
  const { data: funderSummary, isLoading: isFunderSummaryLoading } = useFunderSummary(funder_id);
  const { data: grant, isLoading: isGrantLoading } = useGrantSearchResult(grant_id, false, query);
  const { data: matchedGrants, isLoading: isMatchedGrantsLoading } = usePastGrantSearchResults(
    gids && gids.length > 0 ? gids : undefined,
  );
  const { data: hasGranted, isLoading: isHasGrantedLoading } = useIsPreviousFunder(funder_id);
  const { data: funderStageAnalysis, isLoading: isStageAnalysisLoading } = useFunderStageAnalysis(
    funder_id,
    "amount",
  );
  const { pastGrant, isPastGrantOverviewLoading, pastGrantOverview } = usePastGrantOverview(
    onboardingData ? funder_uid : undefined,
    query,
    [],
    onboardingData.stage,
    true,
    queryMaskForPastGrantOverview,
    true,
  );

  const [open, setOpen] = useState<number>(0);
  const [why, setWhy] = useState<{ [key: string]: Relevant }>({});
  const [started, setStarted] = useState<string>();
  const logWhyMatched = useCallback(
    (prompt: string, res: string) =>
      axios.post(
        process.env.REACT_APP_API_URL + "/api/v2/log/gpt/whymatched",
        {
          prompt,
          res,
          target_type: type,
          donor_id: funder_id,
          grant_id,
          npo_id: undefined,
          query,
          search_type: type === "funder" ? 1 : type === "opencall" || type === "virtual" ? 2 : 3,
        },
        config,
      ),
    [query, type, funder_id, grant_id],
  );
  useEffect(() => {
    setError(false);
    setOpen(0);
    setStarted(undefined);
    setWhy({});
    setInitiateToken(undefined);
  }, [type, funder_id, funder_uid, grant_id]);
  useEffect(() => {
    setLoading(
      (!funder_uid && !grant_id) ||
        (type === "virtual" && !countedAreas) ||
        (type === "funder" && isFunderSummaryLoading) ||
        isFunderLoading ||
        isGrantLoading ||
        isMatchedGrantsLoading ||
        isStageAnalysisLoading ||
        isPastGrantOverviewLoading ||
        isHasGrantedLoading ||
        (initiateToken !== undefined && started && !whyDesc)
        ? true
        : false,
    );
  }, [
    funder_uid,
    grant_id,
    type,
    countedAreas,
    isFunderSummaryLoading,
    isFunderLoading,
    isGrantLoading,
    isMatchedGrantsLoading,
    isStageAnalysisLoading,
    isPastGrantOverviewLoading,
    isHasGrantedLoading,
    initiateToken,
    started,
    whyDesc,
  ]);
  useEffect(() => {
    if (
      (type === "virtual" && !countedAreas) ||
      (type === "funder" && isFunderSummaryLoading) ||
      isFunderLoading ||
      isGrantLoading ||
      isMatchedGrantsLoading ||
      isStageAnalysisLoading ||
      isPastGrantOverviewLoading ||
      isHasGrantedLoading ||
      started ||
      !open
    ) {
      if (
        (type === "funder" && isFunderSummaryLoading) ||
        isFunderLoading ||
        isGrantLoading ||
        isMatchedGrantsLoading ||
        isStageAnalysisLoading ||
        isPastGrantOverviewLoading ||
        isHasGrantedLoading
      )
        setTimeout(() => setInitiateToken(uuid()), 500);
      return;
    }
    if (gids && gids.length > 0 && !matchedGrants) {
      setTimeout(() => setInitiateToken(uuid()), 300);
      return;
    }
    // console.log({
    //   funder_uid,
    //   funder,
    //   matchedGrants,
    //   pastGrant,
    //   funderSummary,
    //   pastGrantOverview,
    //   funderStageAnalysis,
    //   hasGranted,
    //   flag:
    //     funder_uid &&
    //     (!funder || !pastGrantOverview || hasGranted === undefined || pastGrant === undefined),
    // });
    if (
      funder_uid &&
      (!funder || !pastGrantOverview || hasGranted === undefined || pastGrant === undefined)
    ) {
      setTimeout(() => setInitiateToken(uuid()), 300);
      return;
    }
    if (grant_id && (!grant || !countedAreas)) {
      setTimeout(() => setInitiateToken(uuid()), 300);
      return;
    }
    const controller = new AbortController();
    const matchedGrantsFiltered = matchedGrants.map((g) => ({
      ...g,
      ...filterUnmatched(matchTaxonomyBySearchQueryWithPhilanthropy(g, query)),
    }));
    if (type === "virtual") {
      if (
        !funder ||
        !grant ||
        !pastGrantOverview ||
        hasGranted === undefined ||
        pastGrant === undefined ||
        !countedAreas ||
        !funderStageAnalysis
      ) {
        setError(true);
        return;
      }
      const current_uid = uuid();
      setStarted(current_uid);
      setError(false);
      const callback = (
        res: string,
        completed: boolean,
        failed?: boolean,
        uid?: string,
        prompt?: string,
      ) => {
        if (failed) setError(true);
        if (res.startsWith("We apologize for the inconvenience.")) {
          setError(true);
          setWhy((prev) => ({
            ...prev,
            [uid || ""]: {
              desc: "",
              completed: false,
              error: "We apologize for the inconvenience.",
            },
          }));
        } else
          setWhy((prev) => ({
            ...prev,
            [uid || ""]: { desc: res, completed },
          }));
        if (completed && prompt) logWhyMatched(prompt, res);
      };
      getVirtualGrantMatchingSummary(
        callback,
        current_uid,
        {
          query,
          funder,
          grant,
          past_grants:
            pastGrant && pastGrant.length > 0
              ? pastGrant
                  .slice(0, 10)
                  .map((g) => ({ ...g, ...matchTaxonomyBySearchQuery(g, query) }))
              : [],
          past_grants_by_lm: matchedGrantsFiltered.slice(0, 10),
          past_grant_overview: pastGrantOverview,
          stage: onboardingData.stage,
          hasGranted,
          npo_id: onboardingData.npo_id,
          countedAreas: countedAreas,
          funderStageAnalysis,
        },
        // controller,
      );
    } else if (type === "opencall") {
      if (!grant) {
        setError(true);
        return;
      }
      const current_uid = uuid();
      setStarted(current_uid);
      setError(false);
      const callback = (
        res: string,
        completed: boolean,
        failed?: boolean,
        uid?: string,
        prompt?: string,
      ) => {
        if (failed) setError(true);
        if (res.startsWith("We apologize for the inconvenience.")) {
          setError(true);
          setWhy((prev) => ({
            ...prev,
            [uid || ""]: {
              desc: "",
              completed: false,
              error: "We apologize for the inconvenience.",
            },
          }));
        } else
          setWhy((prev) => ({
            ...prev,
            [uid || ""]: { desc: res, completed },
          }));
        if (completed && prompt) logWhyMatched(prompt, res);
      };
      getOpencallGrantMatchingSummary(
        callback,
        current_uid,
        {
          query,
          funder,
          grant,
          past_grants:
            pastGrant && pastGrant.length > 0
              ? pastGrant
                  .slice(0, 10)
                  .map((g) => ({ ...g, ...matchTaxonomyBySearchQuery(g, query) }))
              : [],
          past_grants_by_lm: matchedGrantsFiltered.slice(0, 10),
          past_grant_overview: pastGrantOverview,
          stage: onboardingData.stage,
          hasGranted,
          npo_id: onboardingData.npo_id,
        },
        // controller,
      );
    } else if (type === "funder") {
      if (
        !funder ||
        !pastGrantOverview ||
        hasGranted === undefined ||
        pastGrant === undefined ||
        funderSummary === undefined ||
        !funderStageAnalysis
      ) {
        setError(true);
        return;
      }
      const current_uid = uuid();
      setStarted(current_uid);
      setError(false);
      const callback = (
        res: string,
        completed: boolean,
        failed?: boolean,
        uid?: string,
        prompt?: string,
      ) => {
        if (failed) setError(true);
        if (res.startsWith("We apologize for the inconvenience.")) {
          setError(true);
          setWhy((prev) => ({
            ...prev,
            [uid || ""]: {
              desc: "",
              completed: false,
              error: "We apologize for the inconvenience.",
            },
          }));
        } else
          setWhy((prev) => ({
            ...prev,
            [uid || ""]: { desc: res, completed },
          }));
        if (completed && prompt) logWhyMatched(prompt, res);
      };
      getFunderMatchingSummary(
        callback,
        current_uid,
        {
          query,
          funder,
          past_grants:
            pastGrant && pastGrant.length > 0
              ? pastGrant
                  .slice(0, 10)
                  .map((g) => ({ ...g, ...matchTaxonomyBySearchQuery(g, query) }))
              : [],
          past_grants_by_lm: matchedGrantsFiltered.slice(0, 10),
          past_grant_overview: pastGrantOverview,
          stage: onboardingData.stage,
          hasGranted,
          npo_id: onboardingData.npo_id,
          funderSummary: funderSummary,
          funderStageAnalysis,
        },
        // controller,
      );
    } else {
      setError(true);
      return;
    }
    return () => {
      controller.abort();
    };
  }, [
    open,
    query,
    isFunderLoading,
    isGrantLoading,
    isMatchedGrantsLoading,
    isStageAnalysisLoading,
    isPastGrantOverviewLoading,
    funder,
    grant,
    matchedGrants,
    funderStageAnalysis,
    isHasGrantedLoading,
    isPastGrantOverviewLoading,
    hasGranted,
    gids,
    pastGrantOverview,
    started,
    setStarted,
    onboardingData,
    initiateToken,
  ]);
  useEffect(() => {
    const inputString = why
      ? started && why[started]?.desc
        ? why[started].desc
        : why[""]?.desc
        ? why[""].desc
        : Object.keys(why)
            .map((k) => why[k].desc)
            .filter(Boolean)?.[0] || ""
      : "";
    setWhyDesc(inputString.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>"));
  }, [started, why]);

  //public mode
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    if (loading) return;
    if (!!open && whyDesc) {
      setTimeout(() => {
        //after 5 second show card
        setShowComponent(true);
      }, 2000);
    }
  }, [loading, whyDesc, open]);

  return (
    <>
      {nameMatch ? null : funder_id || grant_id ? (
        <div className={classNames("h-full w-full", (!open || error) && !nameMatch ? "" : "")}>
          <GibooGradientDiv
            borderSize={1}
            roundedTop
            id={"GA_TAG_click_whymatched"}
            wrapperClassName={classNames(
              open ? "w-full" : "w-[382px]",
              (!open || error) && !nameMatch ? "cursor-pointer" : "",
            )}
            onClick={() => {
              if (error) {
                setStarted(undefined);
              }
              onClick?.();
              setOpen((prev) => prev + 1);
            }}
          >
            <div
              className={classNames(
                "flex w-full",
                !open || error ? "giboo-why-matched-hover" : "",
                !open && !nameMatch
                  ? "h-9 items-center justify-center gap-2"
                  : "giboo-why-matched h-full items-start gap-5 px-5 py-4",
              )}
            >
              <img src={ICON_WHY_MATCH} />
              {!open && !nameMatch ? (
                <h5 className="text-purple-500">{`Is my organization a good match to this ${
                  type === "funder" ? "funder" : "grant"
                }?`}</h5>
              ) : error && !nameMatch ? (
                <h5 className="text-purple-500">{`We apologize for the inconvenience. Please click again to see Is my organization a good match to this ${
                  type === "funder" ? "funder" : "grant"
                }?`}</h5>
              ) : (
                <>
                  {nameMatch ? (
                    <h6 className="mt-[1px] text-purple-500">{`Matched by '${query.text_query}'`}</h6>
                  ) : loading || (open && !whyDesc) ? (
                    <Spinner size="sm" wrapperClass="mt-[1px]" />
                  ) : (
                    <h6
                      className="text-purple-500"
                      dangerouslySetInnerHTML={{
                        __html: why && open ? whyDesc.replaceAll("\n", "<br />") : "",
                      }}
                    ></h6>
                  )}
                </>
              )}
            </div>
            {showComponent && <div className="giboo-why-matched pb-4">{additionalComponent}</div>}
          </GibooGradientDiv>
        </div>
      ) : null}
    </>
  );
}

export default WhyMatched;
