import { DefaultValue, atom, selector, selectorFamily } from "recoil";
import { defaultUser, User } from "./userSlice";
import { recoilPersist } from "recoil-persist";
import { ReactNode } from "react";
import { Relevant, RelevantMap } from "./searchResultCacheSlice";
import { OPPORTUNITIES } from "../types/opportunities";
import { SearchQuery } from "../types/search";
import { GlobalSignInSignUp } from "../components/SignInModal";
import { DocumentDefinition, DocumentTypeEnum } from "../types/document";
import { ILocation } from "../types/location";
import { TaxonomyConcept } from "../types/taxonomy";

const { persistAtom } = recoilPersist();

enum StateType {
  SELECTED_ORG_STATE = "selectedOrgState",
  USER_STATE = "userState",
  SIGNUP_USER_STATE = "signupUserState",
  FOCUS_TREE_SELECTOR_STATE = "focusTreeSelectorState",
  FOCUS_TREE_SELECTOR_SELECTOR = "focusTreeSelectorSelector",
  FOCUS_ALL_TREE_SELECTOR_SELECTOR = "focusAllTreeSelectorSelector",
  IS_FOCUSED_TREE_SELECTOR_SELECTOR = "isFocusedTreeSelectorSelector",
  SEARCH_TYPE_STATE = "searchTypeState",
  MATCHING_SUMMARY_STATE = "matchingSummaryState",
  SEARCH_RELEVANT_MAP_STATE = "searchRelevantMapState",
  SEARCH_RELEVANT_MAP_CACHED_STATE = "searchRelevantMapCachedState",
  SEARCH_RELEVANT_MAP_CACHED_SELECTOR = "searchRelevantMapCachedSelector",
  CURRENT_SEARCH_RELEVANT_STATE = "currentSearchRelevantState",
  FEEDBACK_MODULE_REQ_RES = "feedbackModuleTempRequestAndResponse",
  ACTIVE_TAB = "activeRecommendationTab",
  SIDEKICK_OPEN = "sidekickOpen",
  SIDEKICK_SEARCH_OPEN = "sidekickSearchOpen",
  SIDEKICK_SEARCH_LOADING = "sidekickSearchLoading",
  SIDEKICK_SEARCH_ESTIMATED_LOADING_TIME = "sidekickSearchEstimatedLoadingTime",
  SIDEKICK_SEARCH_COUNT = "sidekickSearchCount",
  SIDEKICK_TARGET_ID = "sidekickTargetId",
  SIDEKICK_TARGET_TYPE = "sidekickTargetType",
  SIDEKICK_WHY_MATCHED = "sidekickWhyMatched",
  SIDEKICK_WHY_MATCHED_LOADING = "sidekickWhyMatchedLoading",
  SIDEKICK_START_FUNDRAISING_LOADING = "sidekickStartFundraisingLoading",
  SIDEKICK_SEARH_BY_NAME = "sidekickSearchByName",
  SIDEKICK_SEARH_QUERY = "sidekickSearchQuery",
  SIDEKICK_SCROLL_DETAIL_VIEW = "sidekickDetailViewScroll",
  SIDEKICK_SEARCH_ITEM_CLICKED = "sidekickSearchItemClicked",
  SIDEKICK_TARGET_READY = "sidekickTargetReady",
  SIDEKICK_SCROLL_TARGET_MORE_SEARCH_OPTION = "sidekickScrollTargetMoreSearchOption",
  SIDEKICK_HIGHLIGHT_TARGET = "sidekickHighlightTarget",
  SIDEKICK_SHOW_TASK_CREATION = "sidekickShowTaskCreation",
  SIGNIN_SIGNUP_OPEN = "signinSignupOpen",
  SIDEKICK_MY_HOME_READY = "sidekickMyHomeReady",
  SIDEKICK_DOCUMENT_GENERATED = "sidekickDocumentGenerated",
  SIDEKICK_DOCUMENT_DEFINITION = "sidekickDocumentDefinition",
  SIDEKICK_DOCUMENT_TARGET = "sidekickDocumentTarget",
  SIDEKICK_DOCUMENT_TARGET_GRANT = "sidekickDocumentTargetGrant",
  SIDEKICK_DOCUMENT_MESSAGE_INPUT = "sidekickDocumentMessageInput",
  SEARCH_SESSION = "searchSession",
  SEARCH_TEXT_QUERY_TO_KEEP_SESSION = "searchTextQueryToKeepSession",
  SEARCH_FILTER_ADDED_TAGS = "searchFilterAddedTags",
  SEARCH_FILTER_ADDED_SERVICE_LOCATIONS = "searchFilterAddedServiceLocations",
  SEARCH_FILTER_ADDED_HQ_LOCATIONS = "searchFilterAddedHQLocations",
  SEARCH_QUERY = "searchQuery",
  SEARCH_TYPE = "searchType",
  SEARCH_VIEW_SCROLL_VALUE = "searchViewScrollValue",
  SEARCH_ITEM_VIEW_SCROLL_VALUE = "searchItemViewScrollValue",
}

function isDefaultValue(candidate: unknown): candidate is DefaultValue {
  return candidate instanceof DefaultValue;
}

export const signupUserState = atom<User>({
  key: StateType.SIGNUP_USER_STATE,
  default: defaultUser,
  effects_UNSTABLE: [persistAtom],
});

export const userState = atom<User>({
  key: StateType.USER_STATE,
  default: defaultUser,
  effects_UNSTABLE: [persistAtom],
});

export const selectedOrgState = atom<{ [key: string]: string }>({
  key: StateType.SELECTED_ORG_STATE,
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const focusTreeSelectorState = atom<{ [key: string]: string }>({
  key: StateType.FOCUS_TREE_SELECTOR_STATE,
  default: {},
});

export const focusTreeSelectorSelector = selectorFamily<string, string>({
  key: StateType.FOCUS_TREE_SELECTOR_SELECTOR,
  get:
    (id) =>
    ({ get }) => {
      return get(focusTreeSelectorState)[id];
    },
  set:
    (id) =>
    ({ get, set, reset }, newValue) => {
      if (isDefaultValue(newValue)) {
        set(focusTreeSelectorState, (prev) => ({ ...prev, [id]: "" }));
      } else {
        set(focusTreeSelectorState, (prev) => ({ ...prev, [id]: newValue }));
      }
    },
});

export const focusAllTreeSelectorSelector = selectorFamily<string[], string>({
  key: StateType.FOCUS_ALL_TREE_SELECTOR_SELECTOR,
  get:
    (id) =>
    ({ get }) => {
      return ["0", "1", "2", "3"]
        .map((i) => get(focusTreeSelectorState)[`${id}${i}`])
        .filter(Boolean);
    },
});

export const isFocusedTreeSelectorSelector = selectorFamily<boolean, { id: string; key: string }>({
  key: StateType.IS_FOCUSED_TREE_SELECTOR_SELECTOR,
  get:
    (param) =>
    ({ get }) => {
      return get(focusTreeSelectorState)[param.id] === param.key;
    },
});

export const matchingSummaryState = atom<{ [key: string]: ReactNode }>({
  key: StateType.MATCHING_SUMMARY_STATE,
  default: {},
});
export const searchRelevantMapState = atom<RelevantMap>({
  key: StateType.SEARCH_RELEVANT_MAP_STATE,
  default: {},
});

export const currentSearchRelevantState = atom<{ key: string; value: string[] }>({
  key: StateType.CURRENT_SEARCH_RELEVANT_STATE,
  default: { key: "", value: [] },
});

export const feedBackReqAndRes = atom<{ request?: object; response?: object }>({
  key: StateType.FEEDBACK_MODULE_REQ_RES,
  default: {},
});
export const activeRecommendationtTab = atom<OPPORTUNITIES>({
  key: StateType.ACTIVE_TAB,
  default: OPPORTUNITIES.FUNDER,
});
export const searchRelevantMapCachedState = atom<{ [key: string]: RelevantMap }>({
  key: StateType.SEARCH_RELEVANT_MAP_CACHED_STATE,
  default: {},
});

export const searchRelevantMapCachedSelector = selectorFamily<
  Relevant | undefined,
  { url: string; key: string }
>({
  key: StateType.SEARCH_RELEVANT_MAP_CACHED_SELECTOR,
  get:
    (param) =>
    ({ get }) => {
      return get(searchRelevantMapCachedState)[param.url]?.[param.key];
    },
  set:
    (param) =>
    ({ get, set, reset }, newValue) => {
      if (!newValue || isDefaultValue(newValue)) {
        set(searchRelevantMapCachedState, (prev) => ({
          ...prev,
          [param.url]: { ...prev[param.url], [param.key]: { desc: "", completed: false } },
        }));
      } else {
        set(searchRelevantMapCachedState, (prev) => ({
          ...prev,
          [param.url]: { ...prev[param.url], [param.key]: newValue },
        }));
      }
    },
});

export const selectedOrgSelector = selectorFamily<string | undefined, { key: string }>({
  key: StateType.SELECTED_ORG_STATE,
  get:
    (param) =>
    ({ get }) => {
      return get(selectedOrgState)[param.key];
    },
  set:
    (param) =>
    ({ get, set, reset }, newValue) => {
      if (!newValue || isDefaultValue(newValue)) {
        set(selectedOrgState, (prev) => ({
          ...prev,
          [param.key]: "",
        }));
      } else {
        set(selectedOrgState, (prev) => ({
          ...prev,
          [param.key]: newValue,
        }));
      }
    },
});

export const sidekickOpen = atom<boolean>({
  key: StateType.SIDEKICK_OPEN,
  default: false,
});
export const sidekickSearchOpen = atom<boolean>({
  key: StateType.SIDEKICK_SEARCH_OPEN,
  default: false,
});
export const sidekickSearchLoading = atom<boolean>({
  key: StateType.SIDEKICK_SEARCH_LOADING,
  default: false,
});
export const sidekickSearchEstimatedLoadingTime = atom<number>({
  key: StateType.SIDEKICK_SEARCH_ESTIMATED_LOADING_TIME,
  default: 10,
});
export const sidekickTargetId = atom<string>({
  key: StateType.SIDEKICK_TARGET_ID,
  default: "",
});
export const sidekickTargetType = atom<-1 | 0 | 1 | 2 | 3>({
  key: StateType.SIDEKICK_TARGET_TYPE,
  default: 0,
});
export const sidekickSearchCount = atom<number>({
  key: StateType.SIDEKICK_SEARCH_COUNT,
  default: 0,
});
export const sidekickWhyMatched = atom<string>({
  key: StateType.SIDEKICK_WHY_MATCHED,
  default: "",
});
export const sidekickWhyMatchedLoading = atom<boolean>({
  key: StateType.SIDEKICK_WHY_MATCHED_LOADING,
  default: false,
});
export const sidekickStartFundraisingLoading = atom<boolean>({
  key: StateType.SIDEKICK_START_FUNDRAISING_LOADING,
  default: false,
});
export const sidekickSearchByName = atom<boolean>({
  key: StateType.SIDEKICK_SEARH_BY_NAME,
  default: false,
});
export const sidekickSearchQuery = atom<SearchQuery | undefined>({
  key: StateType.SIDEKICK_SEARH_QUERY,
  default: undefined,
});
export const sidekickDetailViewScroll = atom<boolean>({
  key: StateType.SIDEKICK_SCROLL_DETAIL_VIEW,
  default: false,
});
export const sidekickSearchItemClicked = atom<number>({
  key: StateType.SIDEKICK_SEARCH_ITEM_CLICKED,
  default: -1,
});
export const sidekickTargetReady = atom<boolean>({
  key: StateType.SIDEKICK_TARGET_READY,
  default: false,
});
export const sidekickScrollTargetMoreSearchOption = atom<string>({
  key: StateType.SIDEKICK_SCROLL_TARGET_MORE_SEARCH_OPTION,
  default: "",
});
export const sidekickHighlightTarget = atom<string>({
  key: StateType.SIDEKICK_HIGHLIGHT_TARGET,
  default: "",
});
export const sidekickShowTaskCreation = atom<boolean>({
  key: StateType.SIDEKICK_SHOW_TASK_CREATION,
  default: false,
});
export const openSigninSignup = atom<{
  show: boolean;
  type?: GlobalSignInSignUp;
  source?: string;
  disable?: boolean;
}>({
  key: StateType.SIGNIN_SIGNUP_OPEN,
  default: { show: false, type: "SIGNUP", disable: false },
});
export const sidekickMyHomeReady = atom<boolean>({
  key: StateType.SIDEKICK_MY_HOME_READY,
  default: false,
});
export const sidekickDocumentGenerated = atom<boolean>({
  key: StateType.SIDEKICK_DOCUMENT_GENERATED,
  default: false,
});
export const sidekickDocumentTarget = atom<0 | 1 | 2 | undefined>({
  key: StateType.SIDEKICK_DOCUMENT_TARGET,
  default: undefined,
});
export const sidekickDocumentDefinition = atom<DocumentDefinition | undefined>({
  key: StateType.SIDEKICK_DOCUMENT_DEFINITION,
  default: undefined,
});
export const sidekickDocumentTargetGrant = atom<"opencall" | "virtual" | undefined>({
  key: StateType.SIDEKICK_DOCUMENT_TARGET_GRANT,
  default: undefined,
});
export const sidekickDocumentMessageInput = atom<string>({
  key: StateType.SIDEKICK_DOCUMENT_MESSAGE_INPUT,
  default: "",
});
export const searchSession = atom<string>({
  key: StateType.SEARCH_SESSION,
  default: "",
});
export const searchTextQueryToKeepSession = atom<string>({
  key: StateType.SEARCH_TEXT_QUERY_TO_KEEP_SESSION,
  default: "",
});
export const searchFilterAddedTags = atom<TaxonomyConcept[]>({
  key: StateType.SEARCH_FILTER_ADDED_TAGS,
  default: [],
});
export const searchFilterAddedServiceLocations = atom<ILocation[]>({
  key: StateType.SEARCH_FILTER_ADDED_SERVICE_LOCATIONS,
  default: [],
});
export const searchFilterAddedHQLocations = atom<ILocation[]>({
  key: StateType.SEARCH_FILTER_ADDED_HQ_LOCATIONS,
  default: [],
});

export const searchType = atom<string[]>({
  key: StateType.SEARCH_TYPE,
  default: [],
});
export const searchQuery = atom<SearchQuery | undefined>({
  key: StateType.SEARCH_QUERY,
  default: undefined,
});
export const searchViewScrollValue = atom<{ [key: string]: number }>({
  key: StateType.SEARCH_VIEW_SCROLL_VALUE,
  default: {},
});

export const searchItemViewScrollValue = atom<{ [key: string]: number }>({
  key: StateType.SEARCH_ITEM_VIEW_SCROLL_VALUE,
  default: {},
});
