import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import classNames from "classnames";
import GIBOO_PLACEHOLDER from "../../../../assets/images/giboo-bg.svg";
import IMG_PROJECT from "../../../../assets/images/project-placeholder.svg";
import useOrgProjects from "../../../../hooks/useOrgProjects";
import ProjectCard from "../../../../components/ProjectCard";
import { UserView } from "../NpoLayout";
import useVerifiedOrg from "../../../../hooks/useVerifiedOrg";
import useProjects from "../../../../hooks/project/useProjects";
import useOrgDataForNPODashboard from "../../../../hooks/useOrgDataForNPODashboard";
import ProjectCard2 from "../../../../components/ProjectCard2";
import { scrollBarClass } from "../../../../utils/classes";
import { ClickToScrollWrapper } from "../../../../components/ClickToScrollWrapper";
import Spacer from "../../../../components/Spacer";
import ProjectInformationModal from "../../../../components/ProjectInformationModal";
function NpoProjects() {
  const { id } = useParams();
  const { isPrivateView, hasWritePermission } = useOutletContext<UserView>();
  return (
    <NpoProjectsList
      hasWritePermission={hasWritePermission}
      isPrivateView={isPrivateView}
      id={id}
    />
  );
}
export default NpoProjects;
export function NpoProjectsList({
  isPrivateView,
  hasWritePermission,
  id,
  compact = false,
}: {
  isPrivateView?: boolean;
  hasWritePermission?: boolean;
  id?: string;
  compact?: boolean;
}) {
  const { org_id, projects } = useOrgDataForNPODashboard(id, isPrivateView);
  const navigate = useNavigate();
  const [showProjectCreationModal, setShowProjectCreationModal] = useState<boolean>(false);
  const renderEmpty = () => {
    return (
      <div className="grid h-[355px] w-[390px] place-items-center rounded border border-gray-200 bg-white">
        <div className="relative grid h-[205px] w-[334px] place-items-center">
          <img src={GIBOO_PLACEHOLDER} className="bg-cover" />
          <div className="absolute grid h-full w-full place-items-center bg-white bg-opacity-30">
            <div
              className="inline-flex cursor-pointer items-center gap-4"
              onClick={() => {
                setShowProjectCreationModal(true);
              }}
            >
              <span className="grid h-9 w-9 place-items-center rounded-full bg-white shadow">
                <i className="gi gi-add text-2xl" />
              </span>
              <h5 className="text-sm text-purple-500">Create a new project</h5>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderProjects = () => {
    return (
      <ClickToScrollWrapper disabled={projects.length < 3}>
        <div
          className={classNames(
            "grid max-h-[500px] grid-cols-1 gap-x-5 gap-y-4 overflow-y-scroll md:grid-cols-2",
            projects.length > 3 && scrollBarClass,
          )}
        >
          {projects.map((item) => (
            <ProjectCard2
              key={item._id}
              projectId={item._id}
              isPrivateView={isPrivateView}
              hasWritePermission={hasWritePermission}
              width={compact ? 355 : 372}
            />
          ))}
        </div>
      </ClickToScrollWrapper>
    );
  };
  return (
    <>
      {showProjectCreationModal && (
        <ProjectInformationModal
          from_for_mixpanel="org_profile"
          open={showProjectCreationModal}
          setOpen={setShowProjectCreationModal}
          id=""
          withOutFunderGrant
          isNewProject
        />
      )}
      <div
        className={classNames(
          "flex flex-col p-5",
          compact ? "gap-4" : "gap-4 rounded border border-gray-300 bg-white ",
        )}
      >
        {compact ? (
          <div className={classNames("inline-flex justify-between bg-white")}>
            <h4 className="font-poppins text-xl font-semibold text-gray-900">Projects</h4>
            {isPrivateView && hasWritePermission && (
              <span
                className="inline-flex cursor-pointer items-center gap-2"
                onClick={() => navigate("/projects")}
              >
                <i className="gi-pencil text-sm text-gray-700" />
                <h5 className="text-sm text-black">Edit</h5>
              </span>
            )}
          </div>
        ) : (
          <h4 className="font-poppins text-xl font-semibold text-gray-900">Projects</h4>
        )}
        {projects.length === 0 ? (
          isPrivateView ? (
            renderEmpty()
          ) : (
            <div
              className={classNames(
                "grid min-h-[150px] place-items-center",
                compact && "bg-inherit",
              )}
            >
              <img src={IMG_PROJECT} alt="no-org-projects" />
              <Spacer height="20px" />
              <h5 className="font-poppins text-sm text-gray-700">
                This organization currently has no ongoing projects in Giboo.{" "}
              </h5>
              <Spacer height="40px" />
            </div>
          )
        ) : (
          renderProjects()
        )}
      </div>
    </>
  );
}
