import classNames from "classnames";
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from "react";
import Button from "./Button";
import ReactQuill from "react-quill";
import ICON_REFRESH from "../../assets/loi/refresh.svg";
import ICON_IMPROVE from "../../assets/loi/done.svg";
import ICON_LONGER from "../../assets/loi/longer.svg";
import ICON_SHORTER from "../../assets/loi/shorter.svg";
import ICON_MAGIC_WAND_WHITE from "../../assets/loi/magic-wand-highlight.svg";
import ICON_MAGIC_WAND from "../../assets/loi/magic-wand.svg";
import Spinner from "../Spinner";
import { AiSuggestionService } from "../../services/AIsuggestion.services";
import TextInput from "./TextInput";
import { scrollBarClass } from "../../utils/classes";
enum AI_SUGGESTIONS {
  IMPROVE = "improve",
  MAKE_SHORTER = "shorter",
  MAKE_LONGER = "longer",
}
const SUGGESTIONS = {
  [AI_SUGGESTIONS.IMPROVE]: "Check spelling and grammar",
  [AI_SUGGESTIONS.MAKE_SHORTER]: "Make shorter",
  [AI_SUGGESTIONS.MAKE_LONGER]: "Make longer",
};

interface AiTextInputRenderProps {
  setContent: Dispatch<SetStateAction<string | undefined>>;
}
export interface AiTextInputProps {
  quillRefProp?: React.RefObject<ReactQuill>;
  id: string;
  className?: string;
  readonly?: boolean;
  placeholder?: string;
  value: string | undefined;
  invalid?: boolean;
  disabled?: boolean;
  wrapperClass?: string;
  customClass?: string | undefined;
  onChange: (value: string) => void;
  disableEditor?: boolean;
  suggestionQuery?: object;
  descType?:
    | "problem"
    | "impact"
    | "approach"
    | "partner"
    | "budget"
    | "result"
    | "activity"
    | "summary";
  children?: (props: AiTextInputRenderProps) => ReactNode;
  height?: string;
  enableAiSuggestion?: boolean;
}
type IsFunction<T> = T extends (...args: any[]) => any ? T : never;
const isFunction = <T extends object>(value: T): value is IsFunction<T> =>
  typeof value === "function";
const AITextField = React.forwardRef(function AITextField(
  {
    quillRefProp,
    id,
    placeholder = "Enter text here. Please keep it brief (less than 50 words).",
    readonly,
    value,
    onChange,
    suggestionQuery,
    descType,
    children,
    height = "150px",
    enableAiSuggestion = true,
    ...props
  }: AiTextInputProps,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  if (children && !isFunction(children)) {
    throw new Error("children is mandatory and needs to be a function");
  }
  const [enableAI, setEnableAI] = useState(false);
  const myQuillRef = useRef<ReactQuill>(null);
  const quillRef = quillRefProp ? quillRefProp : myQuillRef;
  const [generatedResponse, setGeneratedResponse] = useState<string>("");
  const [AIquery, setAIquery] = useState<string>("");
  const [selectedWord, setSelectedWord] = useState("");
  const [AISuggestion, setAISuggestion] = useState<AI_SUGGESTIONS | undefined>();
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false);
  const [content, setContent] = useState<string>();
  const [AiLoading, setAILoading] = useState<boolean>(false);
  const [AiSuggesting, setAiSuggesting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectionRange, setSelectionRange] = useState<{ index: number; length: number } | null>();
  const selectionRangeRef = useRef<{ index: number; length: number } | null>();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0, height: 0 });
  const [defaultValue, setDefaultValue] = useState("");
  useEffect(() => {
    setEnableAI(enableAiSuggestion);
  }, [enableAiSuggestion]);
  useEffect(() => {
    setDefaultValue(value || "");
  }, [value, setDefaultValue]);
  useEffect(() => {
    onChange(quillRef.current?.getEditor().getText() || "");
  }, [content]);
  useEffect(() => {
    if (AISuggestion) {
      handleAiSuggestion();
    }
  }, [AISuggestion]);
  const replaceText = (suggestContent: string) => {
    const quill = quillRef?.current?.getEditor();
    selectionRange && quill?.deleteText(selectionRange.index, selectionRange.length);
    selectionRange && quill?.insertText(selectionRange.index, suggestContent);
    selectionRange && quill?.setSelection(selectionRange.index, suggestContent.length);
  };
  const removeSelection = () => {
    const editor = quillRef?.current?.getEditor();
    if (selectionRangeRef.current) {
      const prevSelectionRange = selectionRangeRef.current;
      editor?.removeFormat(prevSelectionRange.index, prevSelectionRange.length);
    }
  };
  const handleAiSuggestion = () => {
    if (AIquery === "") return;
    setIsDisabled(true);
    setAILoading(true);
    AiSuggestionService({
      content: generatedResponse,
      instruction: AIquery,
      selected_phrase: selectedWord,
    })
      ?.then((res) => {
        const aiSuggestion = res.revised_phrase;
        replaceText(aiSuggestion);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setIsDisabled(false);
        setAILoading(false);
        setAIquery("");
        removeSelection();
      });
  };
  const renderAieditor = () => {
    return (
      <>
        {tooltipVisible && enableAI && (
          <div
            style={{
              left: `${tooltipPosition.x}px`,
              top: `${tooltipPosition.y}px`,
              bottom: "0px",
            }}
            className="absolute mx-5 "
          >
            <div
              className="absolute z-[8] h-fit"
              onClick={(e) => {
                e.preventDefault();
                setShowSuggestion((prev) => !prev);
              }}
            >
              <TextInput
                id="giboo-ai-text"
                value={AiLoading ? "Giboo writing" : AIquery}
                className={classNames("!h-[45px] !w-[480px] select-none !pl-14 shadow", {
                  ["!pl-14 !text-gray-900"]: AiLoading,
                })}
                disabled={AiLoading || props.disabled ? true : false}
                placeholder={AiLoading ? "Giboo writing" : "Give Giboo any custom directions"}
                handleOnChange={(e) => {
                  e.preventDefault();
                  setAIquery(e.target.value);
                }}
                handleOnKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation();
                    handleAiSuggestion();
                  }
                }}
                handleOnBlur={() => {
                  setAISuggestion(undefined);
                }}
                iconLeft={
                  AiLoading ? (
                    <Spinner size="md" wrapperClass="px-1" />
                  ) : (
                    <div className="px-1">
                      <img src={ICON_MAGIC_WAND} alt="improve" className="h-5 w-5" />
                    </div>
                  )
                }
                iconRight={
                  !AiLoading && (
                    <div
                      className="cursor-pointer px-1 hover:scale-110"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAiSuggestion();
                      }}
                    >
                      <img src={ICON_REFRESH} alt="improve" className="h-5 w-5" />
                    </div>
                  )
                }
              />
            </div>
            {showSuggestion && !AiLoading && (
              <div
                className="absolute top-[48px] z-[8] flex h-fit !w-[300px] select-none flex-col overflow-hidden"
                onClick={() => setShowSuggestion((prev) => !prev)}
              >
                <div
                  className={classNames(
                    "inline-flex h-[46px] cursor-pointer items-center rounded border-[0.5px] border-gray-300 bg-white px-3 py-2 shadow-sm hover:bg-gray-100",
                    {
                      ["!bg-gray-200 text-gray-800"]: AISuggestion === AI_SUGGESTIONS.MAKE_SHORTER,
                    },
                  )}
                  onClick={() => {
                    setAISuggestion(AI_SUGGESTIONS.MAKE_SHORTER);
                    setAIquery(SUGGESTIONS[AI_SUGGESTIONS.MAKE_SHORTER]);
                  }}
                >
                  <img src={ICON_SHORTER} alt="shorter" className="mr-1 h-5 w-5" /> Make shorter
                </div>
                <div
                  className={classNames(
                    "inline-flex h-[46px] cursor-pointer items-center rounded border-[0.5px] border-gray-300 bg-white px-3 py-2 shadow-sm hover:bg-gray-100",
                    {
                      ["!bg-gray-200 text-gray-800"]: AISuggestion === AI_SUGGESTIONS.MAKE_LONGER,
                    },
                  )}
                  onClick={() => {
                    setAISuggestion(AI_SUGGESTIONS.MAKE_LONGER);
                    setAIquery(SUGGESTIONS[AI_SUGGESTIONS.MAKE_LONGER]);
                  }}
                >
                  <img src={ICON_LONGER} alt="longer" className="mr-1 h-5 w-5" /> Make longer
                </div>
                <div
                  className={classNames(
                    "inline-flex h-[46px] cursor-pointer items-center rounded border-[0.5px] border-gray-300 bg-white px-3 py-2 shadow-sm hover:bg-gray-100",
                    { ["!bg-gray-200 text-gray-800"]: AISuggestion === AI_SUGGESTIONS.IMPROVE },
                  )}
                  onClick={() => {
                    setAISuggestion(AI_SUGGESTIONS.IMPROVE);
                    setAIquery(SUGGESTIONS[AI_SUGGESTIONS.IMPROVE]);
                  }}
                >
                  <img src={ICON_IMPROVE} alt="improve" className="mr-1 h-5 w-5" /> Check spelling
                  and grammar
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  const handleSelectionChange = (range: any, oldRange: any, source: any) => {
    if (AiLoading) return;
    removeSelection();
    const editor = quillRef?.current?.getEditor();
    if (range) {
      const text = source.getText(range.index, range.length);
      setSelectionRange({ index: range.index, length: range.length });
      if (editor) {
        const bounds = editor.getBounds(range.index, range.length);
        setSelectedWord(text);
        editor.formatText(range.index, range.length, {
          background: "#e6e1f2",
        });
        selectionRangeRef.current = selectionRange;
        if (bounds && text !== "") {
          const tooltipTop = bounds.top + bounds.height + 5;
          const tooltipHeight = bounds.height;
          setTooltipPosition({
            x: 0,
            y: tooltipTop,
            height: tooltipHeight,
          });
          setTooltipVisible(true);
          setShowSuggestion(false);
          setAISuggestion(undefined);
        } else {
          removeSelection();
          setTooltipVisible(false);
          setShowSuggestion(false);
          setSelectionRange(null);
        }
      }
    }
  };
  return (
    <div className={classNames("relative w-full ", props.wrapperClass)} id={id}>
      <div className={classNames("text-editor relative !mt-3")}>
        {renderAieditor()}
        <ReactQuill
          {...(enableAI && { onChangeSelection: handleSelectionChange })}
          ref={quillRefProp ? quillRefProp : myQuillRef}
          style={{ fontFamily: "Poppins", height: height }}
          theme="snow"
          placeholder={placeholder}
          className={classNames(
            "ql-normal rounded border border-gray-400 px-2 !font-poppins selection:bg-purple-50",
            props.customClass,
            {
              ["select-none"]: AiLoading,
              [" bg-gray-50"]: props.disableEditor || isDisabled,
              ["!border-purple-500 !bg-purple-50"]: props.invalid,
            },
            scrollBarClass,
          )}
          preserveWhitespace
          value={content || defaultValue}
          readOnly={props.disableEditor || isDisabled}
          onBlur={(previousSelection, source, editor) => {
            // if (previousSelection) {
            //   const editor = quillRef?.current?.getEditor();
            //   editor?.removeFormat(previousSelection.index, previousSelection.length);
            // }
            // setTooltipVisible(false);
            // setShowSuggestion(false);
          }}
          onChange={(value) => {
            setContent(value);
          }}
          modules={{
            toolbar: null,
          }}
        />
      </div>
      {children && children({ setContent })}
    </div>
  );
});

AITextField.defaultProps = {};
export default AITextField;
