import { useEffect, useRef, useState } from "react";
import { getRoleByValue, roleToID, roleToName } from "../app/userSlice";
import classNames from "classnames";
import { TypeAttributes } from "rsuite/esm/@types/common";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { zIndexRolePicker } from "../types/zIndex";
import { Popover } from "@headlessui/react";

interface IaccessRolePicker {
  disable: string[];
  wrapperClassName?: string;
  currentRole: number;
  updateRole?: (role: number) => void;
  onSelect?: (role: number) => void;
  allowEdit: boolean;
  placement?: TypeAttributes.Placement;
  showRoleInfo?: boolean;
  unControlled?: boolean;
  zIndex?: number;
}
const roleToDescription = {
  Admin:
    "Can edit projects, modify the organization profile, and invite teammates to this organization and its projects.",
  Member: "Can edit projects. Can request admin access.",
  Viewer: "None",
};
function RolePicker({
  currentRole,
  allowEdit = false,
  updateRole,
  placement = "bottomEnd",
  showRoleInfo = true,
  unControlled = false,
  onSelect,
  zIndex = zIndexRolePicker,
  ...props
}: IaccessRolePicker) {
  const [value, setValue] = useState(0);
  const ref = useRef<any>(null);
  useEffect(() => {
    setValue(currentRole);
  }, [currentRole]);
  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button>
            <div
              className={classNames(
                "grid h-[25px] w-[92px] cursor-pointer  place-items-center rounded border border-gray-300 px-[10px] text-xs text-gray-700 hover:opacity-80",
                props.wrapperClassName,
              )}
            >
              <span className="inline-flex items-center gap-2">
                {value === 0 ? "None" : getRoleByValue(value)}
                <i className="gi-angle-down mt-[1px]" />
              </span>
            </div>
          </Popover.Button>
          <Popover.Panel
            className={classNames(
              "absolute right-0 z-10 rounded border border-gray-200 bg-white shadow",
              !open && "hidden",
            )}
          >
            <ul className="s h-auto min-w-[162px] flex-col gap-5">
              {Array.from(roleToName)
                .sort()
                .filter((i) => !props.disable.includes(i))
                .map((item: string, index) => {
                  return (
                    <li
                      key={item + index}
                      className="grid w-full cursor-pointer place-items-start px-5 pb-4 pt-1 text-left hover:bg-gray-100 hover:shadow-sm"
                      onClick={() => {
                        if (onSelect) {
                          close();
                          setValue(roleToID[item]);
                          onSelect?.(roleToID[item]);
                          unControlled && ref.current.close();
                        } else if (currentRole !== 5) {
                          close();
                          setValue(roleToID[item]);
                          updateRole?.(roleToID[item]);
                          unControlled && ref.current.close();
                        } else {
                          close();
                          unControlled && ref.current.close();
                        }
                      }}
                    >
                      {showRoleInfo ? (
                        <div className="w-[214px] pt-2">
                          <p className="font-poppins text-xs font-semibold">{item}</p>
                          <h6 className="mt-1 font-poppins text-xs text-gray-700">
                            {roleToDescription[item as keyof typeof roleToDescription]}
                          </h6>
                        </div>
                      ) : (
                        <span className="flex h-[25px] w-[98px] items-center rounded px-[10px] font-poppins text-xs text-gray-700 last:mb-0">
                          {item}
                        </span>
                      )}
                    </li>
                  );
                })}
            </ul>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
export default RolePicker;
