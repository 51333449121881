import { ReactElement } from "react";
import { IOption } from "../components/tailwind/AsyncCreatableSelector";
import TagFunderSize from "../components/TagFunderSize";
import { FinancialFieldName, SearchType } from "./search";
import TagGrantPresent from "../components/TagGrantPresent";
import TagInvitationOnly from "../components/TagInvitationOnly";
import TagFunderType from "../components/TagFunderType";
export const SORT_OPTION: { value: string; label: string }[] = [
  {
    label: "Grant amount",
    value: "total_matched_grant_amount",
  },
  {
    label: "Number of grantees",
    value: "total_matched_grantee_number",
  },
  {
    label: "Grant amount allocated in my stage",
    value: "total_grants_in_your_stage",
  },
  {
    label: "% of the amount allocated",
    value: "grant_in_matched_area_perc",
  },
  {
    label: "% of the amount allocated in my stage",
    value: "grants_in_my_stage_perc",
  },
  { label: "Relevance", value: "relevance" },
  { label: "A to Z", value: "az" },
];
export const GRANT_SORT_OPTION: IOption[] = [
  { label: "Relevance", value: "relevance" },
  { label: "Amount descending", value: "amount" },
  { label: "Deadline ending latest", value: "deadline_latest" },
  { label: "Deadline ending soonest", value: "deadline_soonest" },
  { label: "A to Z", value: "az" },
];
export const NPO_SORT_OPTION: IOption[] = [
  { label: "Relevance", value: "relevance" },
  { label: "A to Z", value: "az" },
];
export const PRIORITY_OPTION: IOption[] = [
  { label: "Must have", value: "MUST_HAVE" },
  { label: "Good to have", value: "GOOD_TO_HAVE" },
];
export const SEARCH_TYPE_OPTION: { value: SearchType; label: string }[] = [
  { label: "Virtual grants", value: "virtual_grant" },
  { label: "Open call grants", value: "grant" },
  { label: "Funders", value: "funder" },
  { label: "Nonprofits", value: "npo" },
  { label: "By name", value: "by_name" },
];
export const GRANT_ACTIVE_OPTION: { value: "all" | "active" | "past"; label: string }[] = [
  { label: "All", value: "all" },
  { label: "Active grants", value: "active" },
  { label: "Past grants", value: "past" },
];
export const SOLICITATION_OPTION: { value: string; label: ReactElement }[] = [
  {
    label: <TagInvitationOnly className="my-1" invitationOnly={false} />,
    value: "open",
  },
  {
    label: <TagInvitationOnly className="my-1" invitationOnly={true} />,
    value: "private",
  },
];
export const PREVIOUS_FUNDER_OPTION: { value: string; label: string }[] = [
  {
    label: "View previous funders only",
    value: "view",
  },
  {
    label: "Hide all previous funders",
    value: "hide",
  },
];
export const GRANT_TYPE_OPTION: { value: string; label: ReactElement }[] = [
  {
    label: <TagGrantPresent className="my-1" grant={{ rolling: true } as any} />,
    value: "opencall",
  },
  {
    label: <TagGrantPresent className="my-1" grant={{ type: "grant-page" } as any} />,
    value: "virtual",
  },
];
export const FUNDING_SIZE_OPTION: IOption[] = [
  { label: "$0-$5,000", value: "$0-$5000" },
  { label: "$5,000-$50,000", value: "$5000-$50000" },
  { label: "$50,000-$100,000", value: "$50000-$100000" },
  { label: "Over $100,000", value: "$100000-$100000000000000" },
];
export const FUNDER_TYPE_OPTION: { label: ReactElement | string; value: string }[] = [
  { label: <TagFunderType className="my-1" funder_type="family" />, value: "family" },
  { label: <TagFunderType className="my-1" funder_type="corporate" />, value: "corporate" },
  { label: <TagFunderType className="my-1" funder_type="private" />, value: "private" },
  {
    label: <TagFunderType className="my-1" funder_type="private_operating" />,
    value: "private_operating",
  },
  { label: <TagFunderType className="my-1" funder_type="public" />, value: "public" },
  { label: <TagFunderType className="my-1" funder_type="community" />, value: "community" },
  { label: <TagFunderType className="my-1" funder_type="daf" />, value: "daf" },
  {
    label: <TagFunderType className="my-1" funder_type="federated_giving" />,
    value: "federated_giving",
  },
  {
    label: <TagFunderType className="my-1" funder_type="funding_intermediary" />,
    value: "funding_intermediary",
  },
  { label: <TagFunderType className="my-1" funder_type="hybrid" />, value: "hybrid" },
];
export const FUNDER_TYPE_FOR_NPO_OPTION: { label: ReactElement | string; value: string }[] = [
  { label: <TagFunderType className="my-1" funder_type="family" />, value: "family" },
  { label: <TagFunderType className="my-1" funder_type="corporate" />, value: "corporate" },
  { label: <TagFunderType className="my-1" funder_type="private" />, value: "private" },
  {
    label: <TagFunderType className="my-1" funder_type="private_operating" />,
    value: "private_operating",
  },
  { label: <TagFunderType className="my-1" funder_type="public" />, value: "public" },
  { label: <TagFunderType className="my-1" funder_type="community" />, value: "community" },
  { label: <TagFunderType className="my-1" funder_type="daf" />, value: "daf" },
  {
    label: <TagFunderType className="my-1" funder_type="federated_giving" />,
    value: "federated_giving",
  },
  {
    label: <TagFunderType className="my-1" funder_type="funding_intermediary" />,
    value: "funding_intermediary",
  },
  { label: <TagFunderType className="my-1" funder_type="hybrid" />, value: "hybrid" },
  {
    label: <TagFunderType className="my-1" funder_type="service_provider" />,
    value: "service_provider",
  },
  { label: <TagFunderType className="my-1" funder_type="field_builder" />, value: "field_builder" },
];
export const FUNDER_TYPE_FOR_GRANT_OPTION: { label: ReactElement | string; value: string }[] = [
  { label: <TagFunderType className="my-1" funder_type="family" />, value: "family" },
  { label: <TagFunderType className="my-1" funder_type="corporate" />, value: "corporate" },
  { label: <TagFunderType className="my-1" funder_type="private" />, value: "private" },
  {
    label: <TagFunderType className="my-1" funder_type="private_operating" />,
    value: "private_operating",
  },
  { label: <TagFunderType className="my-1" funder_type="public" />, value: "public" },
  { label: <TagFunderType className="my-1" funder_type="community" />, value: "community" },
  { label: <TagFunderType className="my-1" funder_type="daf" />, value: "daf" },
  {
    label: <TagFunderType className="my-1" funder_type="federated_giving" />,
    value: "federated_giving",
  },
  {
    label: <TagFunderType className="my-1" funder_type="funding_intermediary" />,
    value: "funding_intermediary",
  },
  { label: <TagFunderType className="my-1" type="federal" />, value: "federal" },
  { label: <TagFunderType className="my-1" type="state" />, value: "state" },
  { label: <TagFunderType className="my-1" type="local" />, value: "local" },
  { label: <TagFunderType className="my-1" funder_type="hybrid" />, value: "hybrid" },
];
export const ALL_FUNDER_TYPE = [...FUNDER_TYPE_FOR_NPO_OPTION, ...FUNDER_TYPE_FOR_GRANT_OPTION].map(
  (o) => o.value,
);
export const DEFAULT_FUNDER_TYPE = ALL_FUNDER_TYPE.filter((v) => v !== "daf")
  .sort((a, b) => a.localeCompare(b))
  .reduce(
    (prev, cur) => (prev.length > 0 && prev[prev.length - 1] === cur ? prev : [...prev, cur]),
    [] as string[],
  );
export const FUNDING_TYPE_OPTION: IOption[] = [
  { label: "General operating grant (Unrestricted)", value: "general" },
  { label: "Program and project-related funds", value: "program" },
  { label: "Capacity-building grants", value: "capacity" },
  { label: "Community support grants", value: "community" },
  { label: "Endowment / Capital building", value: "endowment/capital" },
  { label: "Fellowship grants", value: "fellowship" },
  { label: "Matching grants", value: "matching" },
  // { label: "Relief", value: "relief" },
  { label: "Research grants", value: "research" },
  { label: "Religious grants", value: "religious" },
  { label: "Scholarship grants", value: "scholarship" },
  { label: "Others", value: "others" },
];

export const ASSET_SIZE_OPTION: { label: ReactElement; value: string }[] = [
  {
    label: (
      <div className="my-1 flex items-center gap-2">
        <TagFunderSize assets={0} />
        <h5>: $0-$1M</h5>
      </div>
    ),
    value: "$0-$1000000",
  },
  {
    label: (
      <div className="my-1 flex items-center gap-2">
        <TagFunderSize assets={1_000_000} />
        <h5>: $1M-$10M</h5>
      </div>
    ),
    value: "$1000000-$10000000",
  },
  {
    label: (
      <div className="my-1 flex items-center gap-2">
        <TagFunderSize assets={10_000_000} />
        <h5>: $10M-$100M</h5>
      </div>
    ),
    value: "$10000000-$100000000",
  },
  {
    label: (
      <div className="my-1 flex items-center gap-2">
        <TagFunderSize assets={100_000_000} />
        <h5>: $100M-$1B</h5>
      </div>
    ),
    value: "$100000000-$1000000000",
  },
  {
    label: (
      <div className="my-1 flex items-center gap-2">
        <TagFunderSize assets={1_000_000_000} />
        <h5>: Over $1B</h5>
      </div>
    ),
    value: "$1000000000-$100000000000000",
  },
];
export const STAGE_OPTION: IOption[] = [
  { label: "Individual", value: "dreamer" },
  { label: "Pre-seed & Seed", value: "seed" },
  { label: "Start up", value: "startup" },
  { label: "Buildup", value: "buildup" },
  { label: "Superstar", value: "superstar" },
  { label: "Hyperstar", value: "hyperstar" },
];
export const CONTACT_INFO_OPTION: IOption[] = [
  { label: "Email available", value: "email" },
  { label: "Phone available", value: "phone" },
  { label: "Mailing address available", value: "address" },
  { label: "LinkedIn available", value: "linkedin" },
];
export const DEADLINE_OPTION: IOption[] = [
  // { label: "All", value: "all" },
  { label: "Rolling", value: "rolling" },
  { label: "Enter a specific date", value: "specific" },
];
export const NPO_TAX_EXEMPT_STATUS_OPTION: IOption[] = [
  { label: "501(c)(3) - Charitable organizations", value: "3" },
  { label: "501(c)(4) - Social welfare organizations", value: "4" },
  { label: "Benefic 501(c)(6) - Business leagues, Chambers of commerce", value: "6" },
  { label: "501(c)(7) - Social and recreational clubs", value: "7" },
  { label: "501(c)(19) - Veterans' organizations", value: "19" },
  { label: "Others", value: "-1" },
];
export const NPO_INCOPORATED_STATUS_OPTION: IOption[] = [
  { label: "Corporation", value: "corporation" },
  { label: "Trust", value: "trust" },
  { label: "Cooperative", value: "cooperative" },
  { label: "Partnership", value: "partnership" },
  { label: "Association", value: "association" },
  { label: "Others", value: "other" },
];
export const NPO_ASSET_SIZE_OPTION: IOption[] = [
  { label: "$0", value: "0" },
  { label: "$1M", value: `${1_000_000}` },
  { label: "$10M", value: `${10_000_000}` },
  { label: "$100M", value: `${100_000_000}` },
  { label: "$1B", value: `${1_000_000_000}` },
  { label: "over $1B", value: "over" },
];
export const NPO_MONTH_OF_LIQUID_OPTION: IOption[] = [
  { label: "0", value: "0" },
  { label: "3.0", value: "3" },
  { label: "6.0", value: "6" },
  { label: "9.0", value: "9" },
  { label: "over 9.0", value: "over" },
];

export const NPO_ADVANCED_FILTER_EXACT_VALUE_OPTION: {
  label: string;
  value: FinancialFieldName;
}[] = [
  { label: "Revenue: Contribution and grants", value: "contributions" },
  { label: "Revenue: Operation", value: "operation" },
  { label: "Revenue: Portfolio", value: "investment" },
  { label: "Grants: Government", value: "grants_gov" },
  { label: "Grants: Individual and corporation", value: "grants_ind" },
  { label: "Grants: Foundation contribution", value: "grants_phil" },
  { label: "Expenses: Program", value: "program" },
  { label: "Expenses: Operating", value: "operating" },
  { label: "Expenses: Fundraising", value: "fundraising" },
  { label: "Assets: Cash", value: "asset_cash" },
  { label: "Assets: Receivables", value: "asset_receivable" },
  { label: "Assets: Investments", value: "asset_investment" },
  { label: "Assets: Property & equipment", value: "asset_property" },
];

export const NPO_ADVANCED_FILTER_REVENUE_PERCENTAGE_OPTION: {
  label: string;
  value: FinancialFieldName;
}[] = [
  { label: "Revenue: Contribution and grants", value: "contribution_percentage" },
  { label: "Revenue: Operation", value: "operation_percentage" },
  { label: "Revenue: Portfolio", value: "investment_percentage" },
];

export const NPO_ADVANCED_FILTER_EXPENSE_PERCENTAGE_OPTION: {
  label: string;
  value: FinancialFieldName;
}[] = [
  { label: "Expenses: Program", value: "program_percentage" },
  { label: "Expenses: Operating", value: "operating_percentage" },
  { label: "Expenses: Fundraising", value: "fundraising_percentage" },
];
export const NPO_ADVANCED_FILTER_PERCENTAGE_OPTION = [
  ...NPO_ADVANCED_FILTER_REVENUE_PERCENTAGE_OPTION,
  ...NPO_ADVANCED_FILTER_EXPENSE_PERCENTAGE_OPTION,
];
export const NPO_ADVANCED_FILTER_OPERATOR_OPTION: IOption[] = [
  { label: "Greater than", value: ">" },
  { label: "Less than", value: "<" },
];
