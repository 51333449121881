import { OnboardingData } from "../app/onboarding";
import { selectOnboardingData } from "../app/onboardingSlice";
import { RootState, useAppSelector } from "../app/store";
import useEINFromSearchParams from "./useEINFromSearchParams";
import useOnboardingDataWithoutLogin from "./useOnboardingDataWithoutLogin";

const useOnboardingData = (): [OnboardingData, boolean] => {
  const { ein, cid, isLoading } = useEINFromSearchParams();
  const onboardingData = useAppSelector((state: RootState) => selectOnboardingData(state));
  const { data: onboardingDataWithoutLogin, isLoading: isLoadingNPO } =
    useOnboardingDataWithoutLogin(ein ? true : false);
  return cid || ein
    ? [
        onboardingDataWithoutLogin &&
        (!onboardingData.npo_id || onboardingData.npo_id === onboardingDataWithoutLogin.npo_id)
          ? onboardingDataWithoutLogin
          : onboardingData,
        onboardingDataWithoutLogin && !isLoading && !isLoadingNPO ? true : false,
      ]
    : [onboardingData, true];
};
export default useOnboardingData;
