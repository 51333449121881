import axios from "axios";
import { useCallback } from "react";
import { useSWRConfig } from "swr";
import config from "../api";
import { IOrgManualMemberUpdateRequest, IOrgMember } from "../types/org";
import { ORG_MEMBER_DEFAULT_LIMIT } from "./project/useOrgManualMembers";
import useOrgMembers from "./project/useOrgMembers";
import useOrgMemberInner from "./useOrgMemberInner";
interface OrgMemberState {
  data: IOrgMember;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  update: (req: IOrgManualMemberUpdateRequest) => Promise<IOrgMember | undefined>;
  revalidate: () => Promise<IOrgMember | undefined>;
}
const defaultOrgMember: IOrgMember = {
  org_id: "",
  _id: "",
  accepted: false,
  email: "",
  role: 0,
  firstname: "",
  lastname: "",
  employ_type: "",
  created_at: "",
  updated_at: "",
  type: "",
};
function useOrgMember(org_id?: string, user_id?: string): OrgMemberState {
  const { mutate } = useSWRConfig();
  const { url, data, isLoading, error, isValidating, revalidate } = useOrgMemberInner(
    org_id,
    user_id,
  );
  const { getOptimisticDataOnUpdate: getOptimisticDataOnUpdateUserMember } = useOrgMembers(
    user_id ? org_id : undefined,
  );
  const update = useCallback(
    async (req: IOrgManualMemberUpdateRequest) => {
      if (!org_id || !user_id || !url) return;
      const filteredReq = Object.keys(req).reduce(
        (prev: IOrgManualMemberUpdateRequest, cur) => ({
          ...prev,
          ...(req[cur as keyof typeof req] !== undefined
            ? { [cur]: req[cur as keyof typeof req] }
            : {}),
        }),
        {} as IOrgManualMemberUpdateRequest,
      );
      const options = {
        optimisticData: {
          ...(data ? data : {}),
          ...filteredReq,
        } as IOrgMember,
        rollbackOnError: true,
      };
      mutate(
        process.env.REACT_APP_API_URL +
          `/api/v2/orgs/${org_id}/members?limit=${ORG_MEMBER_DEFAULT_LIMIT}&only_accepted=true`,
        getOptimisticDataOnUpdateUserMember(user_id, filteredReq),
        {
          revalidate: false,
        },
      );
      return mutate<IOrgMember>(
        url,
        axios.put(url, req, config).then((response) => response.data),
        options,
      ).then((res) => {
        return res;
      });
    },
    [org_id, url, data],
  );

  return {
    data: data || defaultOrgMember,
    isLoading,
    error,
    isValidating,
    update,
    revalidate,
  };
}
export default useOrgMember;
