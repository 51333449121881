import React, { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import useOnboardingData from "../hooks/useOnboarding";
import useUser from "../hooks/useUser";
import { useNavigate } from "react-router-dom";
import "./css/recommendation.scss";
import { prettierTaxonomy } from "../utils/philanthropy";
import classNames from "classnames";

import { reprLocation } from "../types/location";
import useRelevant from "../hooks/useRelevant";
import { useRecoilValue } from "recoil";
import { searchRelevantMapCachedSelector } from "../app/recoilStore";
import RelevantText from "./RelevantText";
import PhilanthropicInformationFlexWrap from "./PhilanthropicInformationFlexWrap";
import { IProject } from "../types/project";
import { swrOptionDedupling5mins } from "../types/swr";
import {
  NPOSearchResult,
  SearchQuery,
  UnifiedSearchResult,
  getSearchResultKey,
} from "../types/search";
import { getSearchQueryFromOnboardingData } from "../app/onboardingSlice";
import useGibooStructureSearch, { queryMaskForNPOSearch } from "../hooks/useGibooStructureSearch";
import axios from "axios";
import config from "../api";
import useCancellableSWR from "../hooks/useCancellableSWR";
import { matchTaxonomyBySearchQuery } from "../types/taxonomy";
import NPOCard from "./search/NPOCard";
import NPOCardSimple from "./search/NPOCardSimple";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useOrgID from "../hooks/useOrgID";
import useGibooMixpanel from "../hooks/useGibooMixpanel";

function RecNPOItem({
  parentPage = "home",
  tagWrapperClass,
  ...props
}: {
  queryURL: string;
  npo: NPOSearchResult;
  query?: SearchQuery;
  onAdd?: (id: string) => void;
  onViewLOI?: (id: string) => void;
  isLoadingAddFunder?: boolean;
  parentPage?: string;
  tagWrapperClass?: string;
  projectData?: IProject;
}) {
  const navigate = useNavigate();
  const location = props?.npo.location;
  const renderPhilanthropyMatched = () => {
    const focusArea = props?.npo?.focus_area
      ?.filter((i) => i.matched)
      ?.map((item) => ({
        ...item,
      }));
    const beneficiary = props?.npo?.beneficiary
      ?.filter((i) => i.matched)
      ?.map((item) => ({
        ...item,
      }));
    const program = props?.npo?.program
      ?.filter((i) => i.matched)
      ?.map((item) => ({
        ...item,
      }));
    const service_loc = props?.npo?.service_loc
      ?.filter((i) => i.matched)
      ?.map((item) => ({
        ...item,
      }));
    return (
      <PhilanthropicInformationFlexWrap
        id="funder"
        focusArea={focusArea}
        beneficiary={beneficiary}
        program={program}
        location={service_loc}
        tagSize="sm"
      />
    );
  };
  // const relevant = useRecoilValue(
  //   searchRelevantMapCachedSelector({
  //     url: props.queryURL,
  //     key: getSearchResultKey(props.npo),
  //   }),
  // );
  return (
    <>
      <div
        className="h-fit w-full border-b border-gray-300 px-6 py-5 last:border-b-0 hover:bg-gray-100"
        onClick={() => {
          navigate(`/npos/${props.npo._id}/`, {
            state: {
              query: props.query,
              searchResult: props.npo,
              projectData: props?.projectData,
            },
          });
        }}
      >
        <div className="btn-rec" id={`btn-${props.npo._id}`}>
          <div className="box-title flex justify-between pb-1">
            <div>
              <p className="text-base font-semibold">{props.npo.name}</p>
            </div>
          </div>
          <div className="flex items-center space-x-2 divide-x divide-gray-300">
            <p className="font-poppins text-sm font-medium text-gray-700">
              {location ? reprLocation(location) : "US"}
            </p>
            {/* <p className="px-2 font-poppins text-sm font-semibold text-purple-500">
              {getMatchPercentage(props.donor)}
            </p> */}
          </div>
          {/* {<p className="box-subtitle py-3 !text-sm">{description}</p>} */}
          {/* <h5 className="my-2 text-gray-700">
            <RelevantText relevant={relevant} />
          </h5> */}
          <div
            className={classNames(
              "my-1 flex flex-wrap gap-x-[4px] gap-y-[8px] font-poppins text-sm ",
              tagWrapperClass,
            )}
          >
            {renderPhilanthropyMatched()}
          </div>
        </div>
      </div>
    </>
  );
}

function RecNPOs({
  isPrivateView,
  withoutLogin,
  parentPage = "home",
  recData,
  isLoading,
  hasInitialData,
  recommendationQuery,
  exclude,
  ...props
}: {
  isPrivateView?: boolean;
  withoutLogin?: boolean;
  parentPage?: string;
  recData?: NPOSearchResult[];
  isLoading?: boolean;
  hasInitialData?: boolean;
  recommendationQuery?: SearchQuery;
  exclude?: string[];
}) {
  const mxEvent = useGibooMixpanel();
  const navigate = useNavigate();
  const [user] = useUser();
  const org_id = useOrgID();
  const [onboardingData, ready] = useOnboardingData();
  const [controller, setController] = useState<AbortController>(new AbortController());
  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  const query: SearchQuery = recommendationQuery
    ? recommendationQuery
    : getSearchQueryFromOnboardingData(onboardingData);
  const fetchNPOSearch = useCallback(async (url: string, controller: AbortController) => {
    try {
      const req = JSON.parse(url.slice(7));
      return axios
        .post(
          process.env.REACT_APP_API_URL + `/api/v2/search/npos`,
          {
            ...req,
            store_search_history: false,
            hidden_uids: [],
            search_by_name: false,
            pagination_limit: 20,
            pagination_skip: 0,
          },
          { ...config, ...{ signal: controller.signal } },
        )
        .then((res) => ({
          ...res.data,
          items: res.data.items
            .map((d: any) => ({
              ...d,
              search_type: "npo",
              search_by_name: req.search_by_name,
              service_specific_loc: true,
              recommendation: true,
            }))
            .map((d: any) => ({ ...d, ...matchTaxonomyBySearchQuery(d, req, true) })),
        }))
        .then((res) => res.items);
    } catch {
      return new Promise<any[]>((resolve, reject) => reject());
    }
  }, []);
  const {
    data: npo,
    isLoading: isNPOLoading,
    error: errorNPO,
  } = useCancellableSWR<any[]>(
    !ready || !query
      ? null
      : "gsr_npo" +
          JSON.stringify({
            ...query,
            ...queryMaskForNPOSearch,
            pagination_limit: 20,
            pagination_skip: 0,
          }),
    fetchNPOSearch,
    swrOptionDedupling5mins,
  );
  const recommendation = hasInitialData ? recData : npo;
  const recommendationLoading = isLoading || isNPOLoading;
  // const { isLoading: isLoadingRelevant } = useRelevant(queryURL, recommendation);
  return (
    <div
      className={classNames({
        "loading-light-shimmer-on": recommendationLoading,
      })}
    >
      {!recommendation || (recommendation.length === 0 && recommendationLoading)
        ? Array.from({ length: 5 }, (x, i) => i).map((_, i) => (
            <div
              key={`${i}_item`}
              className="loading-light-shimmer-child flex h-[142px] w-full flex-col gap-[16px]  rounded border border-gray-300 bg-white px-[28px] py-[16px]"
            >
              <span className="flex justify-between gap-[10px]">
                <div className="h-[28px] w-full" />
                <div className="h-[14px] w-[30px]" />
              </span>
              <div className="h-[14px] w-1/2" />
              <div className="h-[48px] w-full" />
            </div>
          ))
        : recommendation
            ?.filter((item) => !exclude || !exclude.includes(item._id))
            .map((n, index) => (
              <NPOCardSimple
                wrapperClass="p-5 border-t first:border-t-0 border-gray-300"
                key={"rec-npo" + index}
                npo={n}
                query={query}
                onClick={() => {
                  mxEvent(
                    isPrivateView
                      ? MIXPANEL_EVENTS_V2.org_profile.similar_org.clicked
                      : MIXPANEL_EVENTS_V2.detail_view.similar_org.clicked,
                    {
                      targetType: "npo",
                      targetId: n._id,
                      targetName: n.name,
                      location: location.pathname,
                    },
                  );
                  navigate(`/npos/${n._id}/`);
                }}
              />
            ))}
    </div>
  );
}

export default RecNPOs;
