import { Onboarding } from "../app/onboarding";
import { ILocation, reprLocation, aLocationIncludesBLocation } from "../types/location";
import { CountedAreas } from "../components/GrantDescriptionDetail";
import { FundChar, FunderProfile, FunderSummary } from "../types/funder";
import { Philanthropy } from "../types/philanthropy";
import { IProjectCreateRequest } from "../types/project";
import {
  FunderSearchResult,
  GrantSearchResult,
  PastGrantOverview,
  PastGrantSearchResult,
  SearchQuery,
  UnifiedSearchResult,
} from "../types/search";
import {
  prettyListStrings,
  stateIdToStateName,
  toFixedWithMinimum,
  toUsdLong,
  toUsdShort,
} from "../utils/formatHelper";
import { sortTaxonomy } from "../utils/taxonomy";

type CallbackType = (
  res: string,
  completed: boolean,
  failed?: boolean,
  uid?: string,
  prompt?: string,
) => void;
type CallbackStringListType = (res: string[], completed: boolean, failed?: boolean) => void;
const getStreamingResponse = async (
  callback: CallbackStringListType,
  url: string,
  prompt: string,
  controller?: AbortController,
  maxTry = 5,
  temperature?: number,
  model:
    | "gpt-3.5-turbo"
    | "gpt-3.5-turbo-1106"
    | "gpt-3.5-turbo-0125"
    | "text-davinci-002"
    | "text-davinci-003" = "gpt-3.5-turbo-0125",
) => {
  if (maxTry <= 0) {
    callback([], false, true);
    return;
  }
  callback([], false);
  const resp = await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      // "X-API-Key": process.env.REACT_APP_API_KEY || "",
      "Access-Control-Allow-Origin": window.location.origin || "",
    },
    body: JSON.stringify({
      prompt,
      ...(temperature !== undefined || temperature !== null ? { temperature } : {}),
      model,
    }),
    ...(controller ? { signal: controller.signal } : {}),
  }).catch((e) => ({ ok: false, body: "", statusText: "" } as any));
  const stack = [];
  if (!resp.ok || !resp.body) {
    if (maxTry > 0)
      await getStreamingResponse(
        callback,
        url,
        prompt,
        controller,
        Math.min(1, maxTry - 1),
        temperature,
        model,
      );
    else callback([], false, true);
    return;
  }
  const reader = resp.body.getReader();
  const done = false;
  while (!done) {
    const { value, done } = await reader.read();
    if (done) {
      break;
    }
    let decoded = "";
    try {
      decoded = new TextDecoder().decode(value);
    } catch (err: any) {
      if (maxTry > 0)
        await getStreamingResponse(
          callback,
          url,
          prompt,
          controller,
          maxTry - 1,
          temperature,
          model,
        );
      else callback([], false, true);
      return;
    }
    stack.push(decoded);
    callback(stack, false);
  }
  callback(stack, true);
};

const sortLocation = (a: ILocation, b: ILocation, hq?: ILocation) => {
  if (!hq) return 0;
  const stateHQ = { ...hq, level: 1 };
  const aOK = aLocationIncludesBLocation(stateHQ, a);
  const bOK = aLocationIncludesBLocation(stateHQ, b);
  if (aOK && !bOK) return -1;
  else if (!aOK && bOK) return 1;
  return 0;
};
const getShortSummary = async (
  callback: CallbackType,
  philanthropy: UnifiedSearchResult,
  query?: SearchQuery,
  controller?: AbortController,
) => {
  const hq =
    philanthropy.search_type === "funder"
      ? (philanthropy as FunderSearchResult).location
      : undefined;
  const matched = {
    focus_area:
      philanthropy?.focus_area
        ?.filter((i) => i.matched)
        .map((i) => i.label)
        .slice(0, 3) || [],
    beneficiary:
      philanthropy?.beneficiary
        ?.filter((i) => i.matched)
        .map((i) => i.label)
        .slice(0, 3) || [],
    program:
      philanthropy?.program
        ?.filter((i) => i.matched)
        .map((i) => i.label)
        .slice(0, 2) || [],
    service_loc:
      philanthropy?.service_loc
        ?.filter((i) => i.matched)
        .sort((a, b) => sortLocation(a, b, hq))
        .slice(0, 1)
        .map((i) => ({ ...i, level: i.level >= 1 ? 1 : i.level }))
        .map((i) => reprLocation(i)) || [],
  };
  let prompt = `Write a sentence describing the foundation's philanthropic focus.\n`;

  const locationString =
    matched.service_loc.length > 0 ? ` in ${generateString(matched.service_loc)}` : "";
  prompt += `The sentence should have no more than 28 words. \
    Start the sentence with  “The foundation tackles“, “The foundation supports”, “The foundation endorses, “The foundation upholds, “The foundation promotes, “The foundation addresses”, “The foundation encourages, “The foundation upholds“, “The foundation handles“, “The foundation sustains“ or “The foundation resolves“.\
    `;
  prompt += `Include keeping the order 1) focus area, 2) beneficiary and 3) program.`;

  if (matched.focus_area.length > 0) prompt += `\nfocus_area: ${matched.focus_area.join(", ")}`;
  if (matched.beneficiary.length > 0) prompt += `\nbeneficiary: ${matched.beneficiary.join(", ")}`;
  if (matched.program.length > 0) prompt += `\nprogram: ${matched.program.join(", ")}`;
  prompt += `\n${getMatchedPhilanthropyString(matched, 3)}`;

  const shortSummaryCallback: CallbackStringListType = (
    res: string[],
    completed: boolean,
    failed?: boolean,
  ) => {
    // if (completed) console.log(locationString, "prompt>", prompt, "\nres> ", res.join(""));
    if (completed) {
      if (res.length > 0 && res[res.length - 1].endsWith("."))
        res[res.length - 1] = res[res.length - 1].slice(0, -1);
      res.push(locationString);
    }
    if (res.length > 1 || (res.length > 0 && res[0].length > 14)) {
      const str = res.join("");
      if (str.toLocaleLowerCase().startsWith("the foundation"))
        callback(str.slice(15), completed, failed);
      else callback(str, completed, failed);
    } else if (completed) {
      callback(res.join(""), completed, failed);
    }
  };
  await getStreamingResponse(
    shortSummaryCallback,
    process.env.REACT_APP_OPENAI_GENERAL_PROMPT_URL || "",
    prompt,
    controller,
    5,
    0.8,
  );
};

const getOnboardingSummary = async (
  callback: CallbackType,
  name: string,
  focus_area: string,
  beneficiary: string,
  program: string,
  controller?: AbortController,
) => {
  let prompt = `Write a comprehensive summary of philanthropic focus of '${
    name || "nonprofit"
  }' in 100 words using below\n`;
  prompt += `focus area: ${focus_area}\n`;
  prompt += `beneficiary: ${beneficiary}\n`;
  prompt += `program: ${program}\n`;

  const shortSummaryCallback: CallbackStringListType = (
    res: string[],
    completed: boolean,
    failed?: boolean,
  ) => {
    callback(res.join(""), completed, failed);
  };
  await getStreamingResponse(
    shortSummaryCallback,
    process.env.REACT_APP_OPENAI_GENERAL_PROMPT_URL || "",
    prompt,
    controller,
    5,
    0.8,
  );
};

const getProjectSummary = async (
  callback: CallbackType,
  projectData: IProjectCreateRequest,
  mission?: string,
  controller?: AbortController,
  temperature?: number,
) => {
  let prompt = `
Write a 50-word long paragraph summarizing the fundraising project based on the information below. Focus on the project aim and desired outcome.
\n
\n  **Rules to follow:
\n  - Start the paragraph with "This project".
\n  - Do not simply list the items.
\n  - Use the organization's mission statement only reference to back up the information from project creation page. Do not use the mission statement as it is.
\n  - For the information from "where are the beneficiaries of this project mainly located" below, don't list more than three locations. If there are three counties in one city listed, only include the city and the state in the letter. If more than three cities in the same state are listed, only include the state.\n
\n
\n  **Information to use:
\n  Input from project creation page:`;

  if (projectData.problem_desc)
    prompt += `\n    - Q3. What problems and challenges does your project aim to address?: ${projectData.problem_desc}`;
  if (projectData.outcome_desc)
    prompt += `\n    - Q4. What outcomes do you expect upon completion of this project?: ${projectData.outcome_desc}`;
  if (projectData.activity_desc)
    prompt += `\n    - Q5. What activities will this project engage in to achieve these desired outcomes?: ${projectData.activity_desc}`;
  if (projectData.beneficiary_desc)
    prompt += `\n    - Q6. Who will benefit from this project?:  ${projectData.beneficiary_desc}`;
  if (projectData.service_loc && projectData.service_loc.length > 0)
    prompt += `\n    - Q7. Where are the beneficiaries of this project mainly located?: ${projectData.service_loc.map(
      (l) => reprLocation(l),
    )}`;
  if (mission) prompt += `\nInput from organization profile:\n  - Mission statement: ${mission}`;

  await getStreamingResponse(
    (res: string[], completed: boolean, failed?: boolean) => {
      callback(res.join(""), completed, failed);
    },
    process.env.REACT_APP_OPENAI_GENERAL_PROMPT_URL || "",
    prompt,
    controller,
    5,
    temperature,
  );
};

const getMatchedPhilanthropyString = (
  philanthropy: {
    focus_area?: string[];
    beneficiary?: string[];
    program?: string[];
    text_query_list?: string[];
  },
  max = 4,
): string => {
  const matched = {
    focus_area: sortTaxonomy(philanthropy?.focus_area, "focus_area") || [],
    beneficiary: sortTaxonomy(philanthropy?.beneficiary, "beneficiary") || [],
    program: sortTaxonomy(philanthropy?.program, "program") || [],
  };
  let str = "";
  if (matched.focus_area.length > 0)
    str += `\nmatched focus_area: ${matched.focus_area
      .slice(0, Math.min(max, matched.focus_area.length))
      .join(", ")}`;
  if (matched.beneficiary.length > 0)
    str += `\nmatched beneficiary: ${matched.beneficiary
      .slice(0, Math.min(max, matched.beneficiary.length))
      .join(", ")}`;
  if (matched.program.length > 0)
    str += `\nmatched program: ${matched.program
      .slice(0, Math.min(max, matched.program.length))
      .join(", ")}`;
  if (philanthropy.text_query_list && philanthropy.text_query_list.length > 0)
    str += `\nmatched context: ${philanthropy.text_query_list
      .slice(0, Math.min(max, philanthropy.text_query_list.length))
      .join(", ")}`;

  return str;
};

const getIndex = (key: string): number => {
  const temp: any = {
    dreamer: 0,
    seed: 1,
    startup: 2,
    buildup: 3,
    superstar: 4,
    hyperstar: 5,
  };
  return temp[key];
};
function transformData(data: FundChar): number[] {
  return [data.dreamer, data.seed, data.startup, data.buildup, data.superstar, data.hyperstar];
}
const getFundingCharString = (stage: string, amount: FundChar[], count: FundChar[]) => {
  if (amount.length === 0 || amount.length !== count.length) return "";
  for (let i = amount.length - 1; i >= 0; i--) {
    const latestAmount = transformData(amount[i])[getIndex(stage)];
    const latestCount = transformData(count[i])[getIndex(stage)];
    if (latestAmount > 0 && latestCount > 0) {
      return `In ${
        amount[i].tax_year
      }, this funder supported ${stage} organizations like yours through ${latestCount} fundings with an average of ${toUsdLong(
        latestAmount / latestCount,
      )}`;
    }
  }
  return "";
};
const getFundingCharPercentage = (stage: string, amount: FundChar[]) => {
  if (amount.length === 0) return "";
  const yearSum = amount
    .map((a) => transformData(a))
    .reduce((prev, cur) => prev.map((p, i) => p + cur[i]), [0, 0, 0, 0, 0, 0]);
  const total = yearSum.reduce((prev, cur) => prev + cur, 0);
  const per = total === 0 ? 0 : Math.round((yearSum[getIndex(stage)] / total) * 100);
  return per;
};

const getLatestFundingAmountString = (amount: FundChar[]): string => {
  if (amount.length === 0) return "";
  const latestAmount = amount[amount.length - 1];
  return `Out of the total funding of ${toUsdShort(
    latestAmount.dreamer +
      latestAmount.seed +
      latestAmount.startup +
      latestAmount.buildup +
      latestAmount.superstar +
      latestAmount.hyperstar,
    0,
    true,
  )} made in ${latestAmount.tax_year}`;
};

const getGeographicFocusString = (
  service_loc?: ILocation[],
  query_service_loc?: ILocation[],
): string => {
  if (
    !service_loc ||
    service_loc.length === 0 ||
    !query_service_loc ||
    query_service_loc.length === 0
  )
    return "";
  const us = query_service_loc.filter((i) => i.level === 0 && i.iso2 === "US").length > 0;
  if (us) return "";
  const total = service_loc.reduce((prev, cur) => prev + (cur.amount || 0), 0);
  // console.log(total);
  if (total === 0) return "";
  const states = query_service_loc
    .map((i) => i.state_id)
    .filter(Boolean)
    .map((a) => a as string)
    .sort((a, b) => a.localeCompare(b))
    .reduce(
      (prev, cur) => (prev.length > 0 && prev[prev.length - 1] === cur ? prev : [...prev, cur]),
      [] as string[],
    );
  // console.log(states);
  if (states.length === 0) return "";
  const amountByState = states
    .reduce(
      (prev, cur) => [
        ...prev,
        {
          state_id: cur,
          amount: service_loc
            .filter((i) => i.state_id === cur)
            .reduce((prev, cur) => prev + (cur.amount || 0), 0),
        },
      ],
      [] as { state_id: string; amount: number }[],
    )
    .sort((a, b) => b.amount - a.amount)
    .filter((l) => stateIdToStateName(l.state_id));
  // console.log(amountByState, total, (amountByState[0].amount * 100) / total);
  if (amountByState.length === 0) return "";
  return `${
    Math.floor((amountByState[0].amount * 100) / total) > 0
      ? `${Math.floor((amountByState[0].amount * 100) / total)}%`
      : toUsdShort(amountByState[0].amount)
  } was allocated to nonprofit organizations in ${stateIdToStateName(amountByState[0].state_id)}`;
};

const generateTemplateString = (key: string, size: number) => {
  if (size === 1) return `**$${key}_1**`;
  else {
    return (
      Array.from({ length: size - 1 }, (x, i) => i)
        .map((_, i) => `**$${key}_${i + 1}`)
        .join(", ") + ` and $${key}_${size}**`
    );
  }
};
const generateString = (tags: string[], and = false) => {
  if (tags.length === 1) return tags[0];
  else {
    return and ? tags.slice(0, -1).join(", ") + ` and ${tags[tags.length - 1]}` : tags.join(", ");
  }
};
const stageLabel = {
  dreamer: "Individual",
  startup: "Start up",
  buildup: "Buildup",
  superstar: "Superstar",
  hyperstar: "Hyperstar",
  seed: "Pre-seed & Seed",
};
const stageIndex = {
  dreamer: 0,
  startup: 2,
  buildup: 3,
  superstar: 4,
  hyperstar: 5,
  seed: 1,
};
const reprStage = (stage: string): string => {
  return stageLabel[stage as keyof typeof stageLabel];
};
const getLongSummary = async (
  callback: CallbackType,
  funderName: string,
  philanthropy?: UnifiedSearchResult,
  fundcharacteristics?: string,
  granteeName?: string,
  granteeMission?: string,
  controller?: AbortController,
) => {
  const hq =
    philanthropy && philanthropy.search_type === "funder"
      ? (philanthropy as FunderSearchResult).location
      : undefined;
  const matched = {
    focus_area:
      sortTaxonomy(
        philanthropy?.focus_area?.filter((i) => i.matched).map((i) => i.label),
        "focus_area",
      ) || [],
    beneficiary:
      sortTaxonomy(
        philanthropy?.beneficiary?.filter((i) => i.matched).map((i) => i.label),
        "beneficiary",
      ) || [],
    program:
      sortTaxonomy(
        philanthropy?.program?.filter((i) => i.matched).map((i) => i.label),
        "program",
      ) || [],
    service_loc:
      philanthropy?.service_loc
        ?.filter((i) => i.matched)
        .sort((a, b) => sortLocation(a, b, hq))
        .slice(0, 1)
        .map((i) => ({ ...i, level: i.level >= 1 ? 1 : i.level }))
        .map((i) => reprLocation(i)) || [],
  };
  const prompt = `Write a summary explaining why this foundation is a good match for a nonprofit organization with the below summary.\n\
  ${
    granteeName && granteeMission
      ? `As the last sentence of the summary, summarize the past grantee's mission statement provided. Grantee mission should be within 30 words.`
      : ""
  }\n\
Don't summarize Foundation's name. The summary must start from Foundation's name. \n\
Please rephrase the below summary.\n\
Don't write "our" nonprofit organization, but "your".\n\
Summary: \n\
    ${funderName} is committed to ${
    matched.focus_area.length > 0
      ? `promoting ${generateString(
          matched.focus_area.slice(0, Math.min(3, matched.focus_area.length)),
        )}`
      : ""
  }${
    matched.beneficiary.length > 0
      ? `${matched.focus_area.length > 0 ? "and supporting" : "supporting"} ${generateString(
          matched.beneficiary.slice(0, Math.min(3, matched.beneficiary.length)),
        )}`
      : ""
  }which align with your organization’s philanthropic focus. The Foundation has provided grants to nonprofit organizations${
    matched.service_loc.length > 0
      ? `in ${generateString(
          matched.service_loc.slice(0, Math.min(1, matched.service_loc.length)),
        )}`
      : ""
  }\
  ${
    matched.program.length > 0
      ? ` conducting ${generateString(
          matched.program.slice(0, Math.min(2, matched.program.length)),
        )}`
      : ""
  }.\
   These initiatives closely resemble your organization’s philanthropic programs, fostering a shared vision and creating opportunities for collaboration.\n\
    ${fundcharacteristics ? `${fundcharacteristics}` : ""}.\n\
    The past grants provided by ${funderName} demonstrate the Foundation’s unwavering commitment to supporting ${generateString(
    [
      ...matched.focus_area.slice(0, Math.min(2, matched.focus_area.length)),
      ...matched.beneficiary.slice(0, Math.min(1, matched.beneficiary.length)),
      ...matched.program.slice(0, Math.min(2, matched.program.length)),
    ],
  )}. \
    ${
      granteeName && granteeMission
        ? `For example, the Foundation funded the ${granteeName} whose mission is ${granteeMission}.`
        : ""
    }`;

  //     let prompt = `**Request:**
  // Write a summary explaining why this foundation is a good match for a nonprofit organization, using the following template and following information.
  // ${
  // granteeName && granteeMission
  //   ? `\nAs the last sentence of the template, summarize the past grantee's mission statement provided in the given information.\n
  // $Grantee_mission should be within 30 words.`
  //   : ""
  // }
  // Please rephrase the result.

  // **Template:**
  // **$Foundation_name** is committed to ${funderName} is committed to ${
  // matched.focus_area.length > 0 ? `promoting ${generateTemplateString("Focus_area", 3)}` : ""
  // } ${
  // matched.beneficiary.length > 0
  //   ? `${
  //       matched.focus_area.length > 0 ? "and supporting" : "supporting"
  //     } ${generateTemplateString("Beneficiary", 2)}`
  //   : ""
  // }, which align with your organization’s philanthropic focus. The Foundation has provided grants to nonprofit organizations ${
  // matched.service_loc.length > 0 ? `in ${generateTemplateString("Geographic_focus", 1)}` : ""
  // } ${
  // matched.program.length > 0 ? `conducting ${generateTemplateString("Program", 2)}` : ""
  // }. These initiatives closely resemble your organization’s philanthropic programs, fostering a shared vision and creating opportunities for collaboration.
  // ${fundcharacteristics ? `\n${fundcharacteristics}` : ""}.
  // The past grants provided by **$Foundation_name** demonstrate the Foundation’s unwavering commitment to supporting **$Focus_area_1, $Focus_area_2, $Beneficiary_1, $Program_1**, and **$Program_2**. ${
  // granteeName && granteeMission
  //   ? "For example, the Foundation funded the **$past_grantee_name** whose mission is **$Grantee_mission**."
  //   : ""
  // }`;
  // prompt += `\n**Information to use:**`;
  // prompt += `\nFoundation_name: ${funderName}`;
  // if (matched.focus_area.length > 0)
  // prompt += `\nmatched focus_area: ${matched.focus_area.slice(0, 3).join(", ")}`;
  // if (matched.beneficiary.length > 0)
  // prompt += `\nmatched beneficiary: ${matched.beneficiary.slice(0, 2).join(", ")}`;
  // if (matched.program.length > 0)
  // prompt += `\nmatched program: ${matched.program.slice(0, 2).join(", ")}`;
  // if (matched.service_loc.length > 0)
  // prompt += `\nmatched Geographic focus: ${matched.service_loc.slice(0, 1).join(", ")}`;
  // if (granteeName && granteeMission)
  // prompt += `
  // \npast_grantee_name: ${granteeName}
  // \nGrantee_mission: ${granteeMission}`;

  // console.log(granteeName ? true : false, granteeMission ? true : false, "prompt>", prompt);
  await getStreamingResponse(
    (res: string[], completed: boolean, failed?: boolean) => {
      callback(res.join(""), completed, failed);
    },
    process.env.REACT_APP_OPENAI_GENERAL_PROMPT_URL || "",
    prompt,
    controller,
  );
};

const rephraseSentence = async (
  callback: CallbackType,
  prefix: string,
  input: string,
  additional?: string,
  controller?: AbortController,
) => {
  const prompt = `${prefix}\n${
    additional
      ? "You may utilize additional information, only if the original sentence is relevant to that.\nDo not mention additional information directly."
      : ""
  }\nOrginal sentence: ${input}\n${additional ? additional : ""}`;
  await getStreamingResponse(
    (res: string[], completed: boolean, failed?: boolean) => {
      callback(res.join(""), completed, failed, undefined, prompt);
    },
    process.env.REACT_APP_OPENAI_GENERAL_PROMPT_URL || "",
    prompt,
    controller,
  );
};

const build_mission_stage_and_search_query = (
  params: {
    query: SearchQuery;
    stage?: string;
  },
  isGrant = true,
  includeStage = true,
): string => {
  let prompt = "";
  prompt += params.query.mission
    ? ` Here is my mission statement: ${params.query.mission}.\nAnd we do quality work in the areas specified in the mission statement. `
    : ``;
  if (includeStage)
    prompt += `My organization is in "${reprStage(params.stage || "buildup")}" stage.\n`;
  if (!isGrant)
    prompt += `I am looking for a funder who can provide funding to support my project.\n`;
  if (params.query.text_query.trim())
    prompt += `I used the following search query: ${params.query.text_query}. to look for ${
      isGrant ? "grant" : "funder"
    } information`;
  return prompt;
};
const build_has_granted_str = (params: { hasGranted?: boolean }): string =>
  params.hasGranted
    ? `Additionally, This grant's funder has made grants to my organization previously.\n`
    : "";
const build_matched_tags_str = (
  target: Philanthropy,
  query: SearchQuery,
  isGrant = true,
): string => {
  const matched_tag_list = generateString(
    [
      ...(target.focus_area?.filter((t) => t.matched).map((t) => t.label) || []),
      ...(target.beneficiary?.filter((t) => t.matched).map((t) => t.label) || []),
      ...(target.program?.filter((t) => t.matched).map((t) => t.label) || []),
    ],
    false,
  );
  const matched_location_list = generateString(
    target.service_loc?.filter((t) => t.matched).map((t) => reprLocation(t)) || [],
    false,
  );
  if (matched_tag_list || matched_location_list)
    return `\n\nBased on the description of the ${
      isGrant ? "grant" : "funder"
    }, we found the following tags to be closely related to the search query: ${
      matched_tag_list || matched_location_list
    } and the tags are called matched tags.\n`;
  return "";
};

const build_search_tags_str = (
  target: { name: string } & Philanthropy,
  query: SearchQuery,
  isGrant = true,
): string => {
  let prompt = "";
  const query_tag_list = generateString(
    [...query.focus_area, ...query.beneficiary, ...query.program],
    false,
  );
  if (query_tag_list) {
    prompt += `\n\nThe following tags seem to represent the characteristics of the search query: ${query_tag_list}.\n`;
  }
  // const service_loc_list = generateString(
  //   params.query.service_loc?.map((l) => reprLocation(l)) || [],
  //   false,
  // );
  prompt += `\nI am evaluating whether a target ${isGrant ? "grant" : "funder"} '${
    target.name
  }' has a good track record funding`;
  prompt += query_tag_list ? ` in the areas represented by above tags.\n` : `\n`;
  return prompt;
};
const build_past_grants_str = (
  past_grants: PastGrantSearchResult[],
  npo_id?: string,
  isGrant = true,
): string => {
  let prompt = "";
  if (past_grants.length > 0) {
    prompt += `\n\nI also reviewed the history of grants made by this ${
      isGrant ? "grant" : "funder"
    }. Here is the top list of the past grants whose descriptions have semantical relevance to the matched tag.\n`;
    for (let i = 0; i < past_grants.length; i++) {
      const matched_tags = generateString(
        [
          ...(past_grants[i].focus_area?.filter((t) => t.matched).map((t) => t.label) || []),
          ...(past_grants[i].beneficiary?.filter((t) => t.matched).map((t) => t.label) || []),
          ...(past_grants[i].program?.filter((t) => t.matched).map((t) => t.label) || []),
        ],
        false,
      );
      const loc = past_grants[i].npo?.location;
      prompt += `\n\nFor ${
        matched_tags
          ? matched_tags
          : "doesn't match search tags, but might be related with the search query or my mission"
      }:\
    \n\tgrantee_name: ${past_grants[i].npo?.name || ""}${
        npo_id === past_grants[i].npo?._id ? "(my organization)" : ""
      }\
    \n\tgrantee_location: ${loc ? reprLocation(loc) : ""}\
    \n\tgrantee_stage: ${reprStage(past_grants[i].npo?.stage || "")}\
    \n\tgrant_year: ${past_grants[i].grant_year}\
    \n\tgrant_amount: ${toUsdLong(past_grants[i].grant_amount)}\
    \n\tdescription: ${past_grants[i].grant_description}\
    \n\tmission: ${past_grants[i].npo?.mission || ""}`;
    }
  }
  return prompt;
};
const build_criterion_str = (
  params: {
    query: SearchQuery;
    funder: FunderSearchResult;
    past_grant_overview: PastGrantOverview;
    stage?: string;
    funderStageAnalysis: FundChar[];
  },
  isGrant = true,
): string => {
  const stage_amount = params.funderStageAnalysis.map((a) => transformData(a));
  const stage_amount_total = stage_amount.reduce(
    (prev, cur) => prev + cur[stageIndex[params.stage as keyof typeof stageIndex] || 3],
    0,
  );
  const criterion = [
    `The total amount of past grants that went to grantees providing services in the areas represented by the matched tags is${toUsdShort(
      params.past_grant_overview.amount_total,
    )}.`,
    `The number of grantees providing services in the areas represented by the matched tags is ${params.past_grant_overview.org_total.toLocaleString()}.`,
    `The total amount of funding made by this funder to the organizations in the same stage as mine independent of the matched areas is ${toUsdLong(
      stage_amount_total,
    )}.`,
    `The percentage of grant amount made in the matched area by this funder over the total amount of past grants is ${
      params.funder.grant_amount_total === 0
        ? 0
        : toFixedWithMinimum(
            (100 * params.past_grant_overview.amount_total) / params.funder.grant_amount_total,
          )
    }%.`,
    `The percentage of grants made in the matched areas for the nonprofit organizations in the same stage as my organization(${
      params.stage ? reprStage(params.stage) : ""
    }) out of all grants made in the matched areas is ${
      params.past_grant_overview.amount_total === 0
        ? 0
        : toFixedWithMinimum(
            (100 * params.past_grant_overview.amount_total_stage) /
              params.past_grant_overview.amount_total,
          )
    }% out of six different stages.`,
    `The semantical relevance of the description of the past grants to the areas represented by the matched tags.`,
  ];
  const criteria_str = criterion.reduce(
    (prev, cur, i) => `${prev}\nCriterion ${i + 1}. ${cur}`,
    "",
  );
  const criterion_index =
    [
      "total_matched_grant_amount",
      "total_matched_grantee_number",
      "total_grants_in_your_stage",
      "grant_in_matched_area_perc",
      "grants_in_my_stage_perc",
      "relevance",
    ].findIndex((v) => v === params.query.sortby) || 0;
  return `\n\n\nWe are evaluating the target ${
    isGrant ? "grant" : "funder"
  } using the following criteria:\n\
  ${criteria_str}
  I consider '${criterion[criterion_index]}' as the most important factor in rating the ${
    isGrant ? "grant" : "funder"
  }. \nThe order of the criteria listed above does not represent their importance.\n\
  \n\
  I would like to rate the ${isGrant ? "grant" : "funder"} by the following rating buckets:\n\
  1. Outstanding match\n\
  2. Strong match\n\
  3. Relevant match\n\
  4. Partial match\n\
  \n\
  Please rate the ${isGrant ? "grant" : "funder"} using the above bucket for the search query${
    params.query.mission ? " and my mission" : ""
  }, and write a statement on why you gave such rating. This statement must justify why this ${
    isGrant ? "grant" : "funder"
  } is added in the search result. Please argue persuasively why this ${
    isGrant ? "grant" : "funder"
  } is still relevant to the search query${
    params.query.mission ? " and mission" : ""
  } although it might be rated as ”Relevant match” or ”Partial match.” Please write it in the second person perspective.`;
};
const additional_instruction = `\n\n"Ensure that you write it in the second person perspective, using phrases like 'your project', 'the same stage as yours', 'your mission statement', and 'your organization', avoiding 'mine' or 'ours'.\n\
When providing your response, use the <b></b> HTML tag to bold sentences.\n\
You must bold factors contributing to the given rating.\n\
Also, bold the rating bucket assigned, but avoid bolding other buckets in negative sentences.\n\
Statistical numbers can be bolded if they are significant enough.\n\
Highlight sentences related to the specified criteria.\n\
\n`;
const getVirtualGrantMatchingSummary = async (
  callback: CallbackType,
  uid: string,
  params: {
    query: SearchQuery;
    funder: FunderSearchResult;
    grant: GrantSearchResult;
    countedAreas: CountedAreas;
    past_grants: PastGrantSearchResult[];
    past_grants_by_lm: PastGrantSearchResult[];
    past_grant_overview: PastGrantOverview;
    stage?: string;
    hasGranted: boolean;
    npo_id?: string;
    funderStageAnalysis: FundChar[];
  },
  controller?: AbortController,
) => {
  let prompt =
    "I am looking for a grant from to support my project. A grant is the call for proposal to solicit applications from an organization or individuals that perform quality work in the focus areas specified in the grant description.\n";

  prompt += build_mission_stage_and_search_query(params);
  prompt += build_search_tags_str(params.grant, params.query);
  prompt += build_matched_tags_str(params.grant, params.query);
  prompt += build_has_granted_str(params);

  const grant_description = [
    ...(params.countedAreas.FocusAreas || []),
    ...(params.countedAreas.Beneficiaries || []),
    ...(params.countedAreas.Programs || []),
  ].reduce((prev, cur) => `${prev}\n\n${cur.title}:\n${cur.description}`, "");
  prompt += `Here is the description in the call -for-proposal of the grant: ${grant_description}`;

  prompt += build_past_grants_str(
    [...params.past_grants, ...params.past_grants_by_lm],
    params?.npo_id,
  );
  prompt += build_criterion_str(params);

  prompt += additional_instruction.replaceAll(
    "[stage]",
    params.stage ? reprStage(params.stage) : "",
  );
  if (process.env.REACT_APP_ENV === "DEV") console.log("prompt:\n", prompt);
  await getStreamingResponse(
    (res: string[], completed: boolean, failed?: boolean) => {
      callback(res.join(""), completed, failed, uid, prompt);
    },
    process.env.REACT_APP_OPENAI_GENERAL_PROMPT_URL || "",
    prompt,
    controller,
  );
};
const getFunderMatchingSummary = async (
  callback: CallbackType,
  uid: string,
  params: {
    query: SearchQuery;
    funder: FunderSearchResult;
    past_grants: PastGrantSearchResult[];
    past_grants_by_lm: PastGrantSearchResult[];
    past_grant_overview: PastGrantOverview;
    stage?: string;
    hasGranted: boolean;
    npo_id?: string;
    funderSummary: FunderSummary;
    funderStageAnalysis: FundChar[];
  },
  controller?: AbortController,
) => {
  let prompt =
    "I am looking for a funder from which I would like to raise funds to support my project. \n";
  prompt += build_mission_stage_and_search_query(params, false);
  prompt += build_search_tags_str(params.funder, params.query, false);
  prompt += build_matched_tags_str(params.funder, params.query, false);
  prompt += build_has_granted_str(params);
  const funder_description = [
    params.funderSummary.mission_and_vision,
    params.funderSummary.giving_philosophy_and_values,
  ]
    .filter(Boolean)
    .join("\n");
  prompt += `Here is the description of the funder: ${funder_description}`;
  prompt += build_past_grants_str(
    [...params.past_grants, ...params.past_grants_by_lm],
    params.npo_id,
    false,
  );
  prompt += build_criterion_str(params, false);

  prompt += additional_instruction.replaceAll(
    "[stage]",
    params.stage ? reprStage(params.stage) : "",
  );

  if (process.env.REACT_APP_ENV === "DEV") console.log("prompt:\n", prompt);
  await getStreamingResponse(
    (res: string[], completed: boolean, failed?: boolean) => {
      callback(res.join(""), completed, failed, uid, prompt);
    },
    process.env.REACT_APP_OPENAI_GENERAL_PROMPT_URL || "",
    prompt,
    controller,
  );
};
const getOpencallGrantMatchingSummary = async (
  callback: CallbackType,
  uid: string,
  params: {
    query: SearchQuery;
    funder?: FunderSearchResult;
    grant: GrantSearchResult;
    past_grants: PastGrantSearchResult[];
    past_grants_by_lm: PastGrantSearchResult[];
    past_grant_overview?: PastGrantOverview;
    past_grant_overview_my_stage?: PastGrantOverview;
    stage?: string;
    hasGranted?: boolean;
    npo_id?: string;
  },
  controller?: AbortController,
) => {
  let prompt =
    "I am looking for a grant open call from which I would like to raise funds to support my project. The grant is published by a funder to solicit grant applications and has specific focus areas to which it is funding.\n";

  prompt += build_mission_stage_and_search_query(params, true, false);
  prompt += build_search_tags_str(params.grant, params.query);
  prompt += build_matched_tags_str(params.grant, params.query);
  prompt += build_has_granted_str(params);
  prompt += `Here is the description of the grant: ${params.grant.description}.`;
  prompt += `\nI would like to rate the grant by the following rating buckets:\n\
1. Outstanding match\n\
2. Strong match\n\
3. Relevant match\n\
4. Partial match\n\
\n\
Please rate the grant using the above bucket for the search query${
    params.query.mission ? " and my mission" : ""
  }, and write a statement on why you gave such rating. This statement must justify why this grant is added in the search result. Please argue persuasively why this grant is still relevant to the search query${
    params.query.mission ? " and mission" : ""
  } although it might be rated as Relevant match” or ”Partial match.” Please write it in the second person perspective.`;

  prompt += additional_instruction.replaceAll(
    "[stage]",
    params.stage ? reprStage(params.stage) : "",
  );

  if (process.env.REACT_APP_ENV === "DEV") console.log("prompt:\n", prompt);
  await getStreamingResponse(
    (res: string[], completed: boolean, failed?: boolean) => {
      callback(res.join(""), completed, failed, uid, prompt);
    },
    process.env.REACT_APP_OPENAI_GENERAL_PROMPT_URL || "",
    prompt,
    controller,
  );
};
export {
  getShortSummary,
  getProjectSummary,
  getLongSummary,
  rephraseSentence,
  getOnboardingSummary,
  getVirtualGrantMatchingSummary,
  getFunderMatchingSummary,
  getOpencallGrantMatchingSummary,
};
