import React, { ReactNode } from "react";
import IMG_1 from "../../../../assets/financial/revenue-expense-1.svg";
import IMG_2 from "../../../../assets/financial/revenue-expense-2.svg";
import classNames from "classnames";
import Spacer from "../../../../components/Spacer";
import Button from "../../../../components/tailwind/Button";

export default function RevenueAndExpense({
  onBack,
  onNext,
  npo_id,
  isBack,
}: {
  onBack?: () => void;
  onNext?: () => void;
  npo_id?: string;
  isBack?: boolean;
}) {
  const renderTitle = (title: string, className?: string) => {
    return (
      <div>
        <h5 className={classNames("font-poppins text-xl font-semibold text-black", className)}>
          {title}
        </h5>
      </div>
    );
  };

  const renderitem = (
    index: number,
    title: string | ReactNode,
    content: ReactNode,
    image: string,
  ) => {
    return (
      <div className="flex flex-wrap items-center gap-[60px] md:flex-nowrap">
        <div className="flex w-full max-w-[533px] flex-col gap-3">
          <div className="inline-flex items-start gap-2">
            <span className="flex h-[21px] w-[21px] shrink-0 items-center justify-center rounded-full bg-purple-500 text-xs font-semibold text-white ">
              {index}
            </span>
            <div className="font-poppins text-sm font-semibold text-black">{title}</div>
          </div>
          <div className="pl-[30px] font-poppins text-sm text-gray-800">{content}</div>
        </div>
        <div className="h-fit w-fit">
          <img src={image} alt="business-model-image" />
        </div>
      </div>
    );
  };
  return (
    <div className="flex max-w-[881px] flex-col gap-6">
      {renderTitle("II. Revenue and expense")}
      <div className="flex flex-col gap-[60px]">
        {renderitem(
          1,
          "What is revenue and expense of a nonprofit organization?",
          <>
            Revenue and expense is a critical facet of a nonprofit&apos;s financial management,
            encapsulating the intricate interplay between the organization&apos;s income and its
            corresponding expenditures.
            <Spacer height="8px" />
            In essence, it provides a comprehensive understanding of the financial health by
            examining revenue streams, their relationships with expenses, and the overall
            operational costs incurred. This category plays a pivotal role in ensuring the
            sustainable functioning of the nonprofit.
          </>,
          IMG_1,
        )}
        {renderitem(
          2,
          "What is the significance of analyzing the revenue and expense of a nonprofit organization?",
          <>
            The significance of analyzing revenue and expense streams lies in its ability to
            identify and prioritize the main sources of financial support. By scrutinizing the
            revenue streams, nonprofits gain insights into which areas contribute most substantially
            to their income, allowing for strategic optimization.
            <Spacer height="8px" />
            Simultaneously, evaluating the relationship between income and expenses ensures
            financial sustainability, ensuring that the revenue generated adequately covers the
            costs associated with day-to-day operations.
          </>,
          IMG_2,
        )}
        <div className="flex h-[38px] items-center justify-start gap-2">
          <Button
            className="!h-[37px]"
            id="btn-back"
            handleOnClick={() => {
              onBack?.();
            }}
            label="Back"
            outline
            prependIcon={<i className="gi-left-arrow gi" />}
          />
          <Button
            className="!h-[37px]"
            id="btn-next"
            handleOnClick={() => {
              onNext?.();
            }}
            label="Next"
            appendIcon={
              <div className="-mt-1 !rotate-180">
                <i className="gi-left-arrow" />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
