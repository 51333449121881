import { useCallback } from "react";
import {
  IProjectTask,
  IProjectTaskComment,
  IProjectTaskCommentCreateRequest,
  IProjectTaskCommentUpdateRequest,
} from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { swrOptionDedupling5mins, swrOptionFetchOnlyOnMount } from "../../types/swr";
import useUser from "../useUser";
const deleteProjectTaskComment = async (id: string) => {
  if (!id) return new Promise<boolean>((resolve, reject) => reject());
  return axios
    .delete(process.env.REACT_APP_API_URL + `/api/v2/projects/task/comment/${id}`, config)
    .then((res) => res.data as boolean);
};
interface TaskLatestCommentState {
  data: IProjectTaskComment | undefined;
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  add: (req: IProjectTaskCommentCreateRequest) => Promise<IProjectTaskComment | undefined>;
  //   update: (req: IProjectTaskCommentUpdateRequest) => Promise<IProjectTaskComment | undefined>;
  revalidate: () => Promise<IProjectTaskComment | undefined>;
}
function useTaskLatestComment(task_id?: string): TaskLatestCommentState {
  const [user] = useUser();
  const url = process.env.REACT_APP_API_URL + `/api/v2/projects/task/latest_comment/${task_id}`;
  const url_to_add = process.env.REACT_APP_API_URL + `/api/v2/projects/task/comment`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<IProjectTaskComment>((resolve, reject) => reject());
    return axios
      .get(_url, config)
      .then((res) => (res.status === 204 ? undefined : (res.data as IProjectTaskComment)));
  };
  const { data, isLoading, error, isValidating } = useSWR<IProjectTaskComment | undefined>(
    task_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const add = useCallback(
    async (req: IProjectTaskCommentCreateRequest) => {
      if (!task_id) return;
      const current = new Date().toISOString();
      const options = {
        optimisticData: {
          task_id,
          ...req,
          _id: "",
          user_id: user?._id,
          created_at: current,
          updated_at: current,
        },
        rollbackOnError: true,
      };
      return mutate<IProjectTaskComment>(
        url,
        axios.post(url_to_add, { task_id, ...req }, config).then((response) => response.data),
        options,
      ).then((res) => {
        return res;
      });
    },
    [user, url, url_to_add, task_id],
  );

  const revalidate = useCallback(() => mutate<IProjectTaskComment>(url), [url]);
  return {
    data: data,
    isLoading,
    error,
    isValidating,
    add,
    revalidate,
  };
}
export default useTaskLatestComment;
export { deleteProjectTaskComment };
