import { useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProjectInvite from "../../../../components/ProjectInvite";
import useProject from "../../../../hooks/project/useProject";
import classNames from "classnames";

import Button from "../../../../components/tailwind/Button";
import { Divider, Placeholder } from "rsuite";
import ICON_AI from "../../../../assets/project-mgt/document-ai-color.svg";
import TextInput from "../../../../components/tailwind/TextInput";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import ClickToEditInput from "../../../../components/input/ClickToEditInput";
import BackButton from "../../../../components/BackButton";
function ProjectHeader({ wrapperClass }: { wrapperClass?: string }) {
  const { id: project_id } = useParams();
  const [showInvite, setShowInvite] = useState(false);
  const navigate = useNavigate();
  const {
    data: projectData,
    hasEditPermission,
    isLoading,
    setMemberRole,
    update,
  } = useProject(project_id);
  const [search, setSearch] = useState<string>(projectData.name);
  const inputRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const focusInput = location.state?.focusInput;
  useOnClickOutside(inputRef, () => {
    if (open) {
      setOpen(false);
      if (search && search !== projectData.name) {
        update({ name: search });
      }
    }
  });
  const renderProjectInvite = () => {
    return (
      <div
        id="invite-people"
        className={classNames(
          hasEditPermission ? "cursor-pointer bg-white hover:shadow" : "bg-gray-100",
          "ml-5 flex h-[30px] w-fit items-center gap-2 rounded border border-gray-300 px-3 py-1 font-poppins",
        )}
        onClick={() => {
          hasEditPermission && setShowInvite(true);
        }}
      >
        <i className="gi-funder gi-md text-gray-700" />
        <span>Invite to project</span>
      </div>
    );
  };
  return (
    <>
      {showInvite && (
        <ProjectInvite
          from_for_mixpanel="project_header"
          projectId={projectData?._id || ""}
          handleClose={() => {
            setShowInvite(false);
          }}
          showInvite={showInvite}
        />
      )}
      <div
        className={classNames(
          "flex w-full max-w-full items-center justify-between",
          process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE" ? "!bg-white" : "",
          wrapperClass,
        )}
      >
        <div className="grid grid-cols-[24px_1fr_auto] gap-4">
          <div className="w-full">
            <BackButton />
          </div>
          <div className="max-w-[500px] self-start">
            <ClickToEditInput
              shouldFocus={focusInput}
              isLoading={isLoading}
              initalValue={projectData?.name || ""}
              placeholder="Enter project name"
              height="!h-[30px]"
              wrapperClass="max-w-full"
              textInputClass="!max-w-full !w-[500px] placeholder:!text-lg placeholder:!font-semibold  !text-black"
              textClass="truncate w-full font-poppins text-lg font-semibold text-black"
              onChange={(val) => {
                update({ name: val });
              }}
            />
          </div>
        </div>

        <span className="flex items-center">
          {/* <UserList users={projectData?.members.map((i) => i.user_id)} /> */}
          {process.env.REACT_APP_ENABLE_DOCUMENT_AI === "TRUE" ? (
            <>
              <Button
                id={"btn-share"}
                label={"Invite"}
                size="sm"
                prependIcon={<i className="gi-md gi-funder" />}
                handleOnClick={() => {
                  hasEditPermission && setShowInvite(true);
                }}
              />
              {/* <Divider vertical className="h-[30px]" />
              <Button
                id={"btn-document-ai"}
                label={"Document AI"}
                color="gray"
                labelClass="!text-black"
                outline
                size="sm"
                prependIcon={<img src={ICON_AI} className="h-5 w-5" />}
                handleOnClick={() => {
                  navigate(`/project/${project_id}/document`);
                }}
              /> */}
            </>
          ) : (
            <>{renderProjectInvite()}</>
          )}
        </span>
      </div>
    </>
  );
}
export default ProjectHeader;
