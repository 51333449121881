import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useProject from "../hooks/project/useProject";
import { Placeholder } from "rsuite";
import { toDateString } from "../utils/formatHelper";
import { useNavigate } from "react-router-dom";
import { getFileUrl } from "../services/file-upload/download.service";
import IMAGE_EMPTY from "../assets/images/giboo-bg-gray.svg";
import useUserDetail from "../hooks/project/useUserDetail";
import UserList from "./UserList";
import { TooltipWrapper } from "./TooltipWrapper";
import UpdateImage from "./UpdateImage";
import { uploadFileService } from "../services/file-upload/upload.service";
import { getProjectImageKey } from "../utils/media";
import useUser from "../hooks/useUser";
import ProjectCreationModal from "./ProjectCreationModal";
import { IProject } from "../types/project";
import { Menu, Transition } from "@headlessui/react";
import GibooToast from "./GibooToast";
import GibooDialog from "./Dialog";
import Button from "./tailwind/Button";
import RoundButton from "./RoundButton";
import useTotalOrganizationMember from "../hooks/useTotalOrganizationMember";

interface ProjectCard2Interface {
  projectId: string;
  wrapperClass?: string;
  showPublicBtn?: boolean;
  isPrivateView?: boolean | undefined;
  hasWritePermission?: boolean | undefined;
  height?: number | undefined;
  width?: number | undefined;
  readonly?: boolean;
  onSelect?: (project: IProject) => void;
  isCheckBox?: boolean;
  isCheckBoxSelected?: boolean;
}
function ProjectCard2({
  projectId,
  wrapperClass,
  showPublicBtn = true,
  isPrivateView,
  hasWritePermission,
  height = 211,
  width = 372,
  readonly,
  onSelect,
  isCheckBox,
  isCheckBoxSelected,
}: ProjectCard2Interface) {
  const {
    data: projectData,
    update,
    isLoading,
    deleteThis,
    hasEditPermission,
  } = useProject(projectId);
  const { data: userData, isLoading: userFetching } = useUserDetail(projectData.user_id);
  const { data: orgUser } = useTotalOrganizationMember(projectData.org_id, projectData.user_id);
  // const { data: tasks } = useTasks(projectId);
  const [bgImage, setBgImage] = useState<string | undefined>();
  const [backgroundObjectKey, setBackgroundObjectKey] = useState<string | undefined>();
  const [hideFundraising, setHideFundraising] = useState<boolean | undefined>(false);
  const navigate = useNavigate();
  const [isPrivate, setIsPrivate] = useState<boolean | undefined>(false);
  const [show, setShow] = useState(false);
  const [showProfilBackgroundEdit, setShowProfileBackgroundEdit] = useState<boolean>(false);
  const [selectedBG, setSelectedBG] = useState<File | undefined>();
  const [user] = useUser();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpen(false); // Close the menu if a click outside is detected
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    if (!backgroundObjectKey) setBgImage(undefined);
    if (backgroundObjectKey) {
      getFileUrl(backgroundObjectKey)
        .then((url) => {
          setTimeout(() => {
            setBgImage(url as string);
          }, 1000);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [backgroundObjectKey]);
  useEffect(() => {
    if (isLoading) return;
    setBackgroundObjectKey(projectData.image_key);
    setIsPrivate(projectData.private);
    setHideFundraising(projectData.hide_fund);
  }, [projectData, isLoading]);
  const handleRename = () => {
    navigate(`/project/${projectData._id}`, { state: { focusInput: true } });
  };

  const handleDeleteClick = () => {
    console.log("Delete clicked");
    setShowConfirm(true);
    // Implement your delete logic here
  };
  const renderTarget = () => {
    return (
      <div className="inline-flex w-full items-end justify-between">
        <div className="mt-1 flex flex-col gap-2">
          <h6 className="font-poppins text-xs">
            Created by: <span>{`${orgUser?.firstname || ""} ${orgUser?.lastname || ""}`}</span>
          </h6>
          <h6 className="font-poppins text-xs">
            Created on: {toDateString(projectData.created_at)}
          </h6>
        </div>
        <div>
          <UserList
            users={projectData?.members.map((i) => i.user_id)}
            maxUsers={2}
            withName={false}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      {showEdit && (
        <ProjectCreationModal open={showEdit} setOpen={setShowEdit} data={projectData} isEdit />
      )}
      <UpdateImage
        from_for_mixpanel="projects"
        image_type_for_mixpanel="project"
        id="input-user-cover-image"
        title={"Add a project image"}
        type="PROJECT"
        open={showProfilBackgroundEdit}
        setOpen={setShowProfileBackgroundEdit}
        selectedImage={selectedBG}
        setSelectedImage={setSelectedBG}
        previousImageURL={bgImage}
        onUpdateImage={async () => {
          if (selectedBG && user?._id && projectId)
            uploadFileService(selectedBG, getProjectImageKey(user._id, projectId), ["image"]).then(
              (res) => {
                const key = res as string;
                update({ image_key: key });
                setBackgroundObjectKey(key);
              },
            );
          else {
            update({ image_key: "" });
            setBackgroundObjectKey("");
          }
        }}
      />
      {isLoading ? (
        <Placeholder.Graph key={projectId} active height={height} width={width} />
      ) : (
        <div
          style={{ height: height, width: width }}
          className={classNames(
            "flex cursor-pointer flex-col items-center rounded border border-gray-200 bg-white hover:bg-gray-50",
            wrapperClass,
          )}
          onClick={() => {
            if (readonly) {
              onSelect?.(projectData);
            } else {
              if (isPrivateView) {
                navigate(`/project/${projectData._id}`);
              } else {
                // const path = location.pathname.split("/");
                // navigate(`${path.slice(0, 3).join("/")}/project/${projectData._id}`);
                navigate(`/public/project/${projectData._id}`);
              }
            }
          }}
        >
          <div className="relative w-full ">
            {/* image */}
            <div
              className={classNames(
                "relative grid  w-full overflow-hidden rounded-t border-b border-gray-200",
                readonly ? "!h-[66px]" : "min-h-[104px]",
              )}
            >
              {bgImage ? (
                <img
                  className={classNames(
                    "h-[104px] w-full object-cover",
                    readonly ? "!h-[66px]" : "h-[104px]",
                  )}
                  src={bgImage}
                />
              ) : (
                <div className="flex h-full w-full items-center justify-between bg-gray-100 p-5">
                  <div
                    className={classNames("flex flex-col ", !projectData.completed && "-mt-[25px]")}
                  >
                    <div className="h-[24px] w-[40px]">
                      <img src={IMAGE_EMPTY} className="h-full w-full brightness-50" />
                    </div>
                  </div>
                  {hasEditPermission && !readonly && (
                    <div className="flex">
                      <TooltipWrapper content={"Add a project image"}>
                        <span
                          className="grid h-7 w-7 cursor-pointer place-items-center rounded-full bg-gray-300"
                          onClick={(e) => {
                            setShowProfileBackgroundEdit(true);
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          <i className="gi-md gi-add text-gray-700" />
                        </span>
                      </TooltipWrapper>
                    </div>
                  )}
                </div>
              )}
              {/* {!projectData.completed && hasEditPermission && !readonly && (
                <div className="absolute  bottom-4 left-5 z-10 w-fit rounded border border-gray-300 bg-white px-2 py-[2px] text-sm">
                  Project creation in progress
                </div>
              )} */}
            </div>
            {isCheckBox && (
              <div className="absolute right-[12px] top-[10px]">
                <RoundButton
                  id={"btn-start-save"}
                  handleOnClick={() => {
                    onSelect?.(projectData);
                  }}
                  bordered={false}
                  type={isCheckBoxSelected ? "primary" : "secondary"}
                  size="sm"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.6311 3.64441C14.756 3.76943 14.8262 3.93897 14.8262 4.11574C14.8262 4.29252 14.756 4.46206 14.6311 4.58708L7.13639 12.0824C7.06829 12.1505 6.98743 12.2046 6.89844 12.2415C6.80944 12.2783 6.71406 12.2973 6.61772 12.2973C6.52139 12.2973 6.426 12.2783 6.33701 12.2415C6.24802 12.2046 6.16716 12.1505 6.09906 12.0824L2.37506 8.35841C2.31312 8.29647 2.26398 8.22294 2.23046 8.14201C2.19694 8.06108 2.17969 7.97434 2.17969 7.88674C2.17969 7.79915 2.19694 7.71241 2.23046 7.63148C2.26398 7.55055 2.31312 7.47702 2.37506 7.41508C2.437 7.35314 2.51053 7.304 2.59146 7.27048C2.67239 7.23696 2.75913 7.21971 2.84672 7.21971C2.93432 7.21971 3.02106 7.23696 3.10199 7.27048C3.18292 7.304 3.25645 7.35314 3.31839 7.41508L6.61839 10.7151L13.6877 3.64441C13.8127 3.51943 13.9823 3.44922 14.1591 3.44922C14.3358 3.44922 14.506 3.51943 14.6311 3.64441Z"
                        fill={isCheckBoxSelected ? "#ffff" : "#9B9B9B"}
                      />
                    </svg>
                  }
                />
              </div>
            )}
          </div>
          <div className=" z-10 flex h-full w-full flex-col justify-between gap-[10px] px-4 pb-3">
            <div className="mt-3 flex items-center justify-between gap-2 ">
              <h5
                title={projectData.name}
                className={classNames(
                  "font-semibold text-black",
                  readonly
                    ? "line-clamp-2 text-ellipsis text-base "
                    : "truncate text-ellipsis text-sm",
                )}
              >
                {projectData.name || "Untitled"}
              </h5>

              {!readonly && (
                <TooltipWrapper
                  color="white"
                  isControlled
                  isOpen={open}
                  WrapperClass="!p-0 !mt-0"
                  content={
                    <div className="flex flex-col gap-0 p-0" ref={menuRef}>
                      <div
                        className="cursor-pointer px-4 py-2 font-poppins text-sm text-gray-900 hover:bg-gray-100"
                        onClick={(e) => {
                          setShowProfileBackgroundEdit(true);
                          setOpen(false);
                          e.stopPropagation();
                        }}
                      >
                        Edit photo
                      </div>
                      <div
                        className="cursor-pointer px-4 py-2 font-poppins text-sm text-gray-900 hover:bg-gray-100"
                        onClick={(e) => {
                          setOpen(false);
                          handleRename();
                          e.stopPropagation();
                        }}
                      >
                        Rename
                      </div>
                      <div
                        onClick={(e) => {
                          setOpen(false);
                          handleDeleteClick();
                          e.stopPropagation();
                        }}
                        className="inline-flex cursor-pointer px-4 py-2 font-poppins text-sm  text-gray-900 hover:bg-gray-100"
                      >
                        Delete
                      </div>
                    </div>
                  }
                  placements="bottomEnd"
                  arrow={false}
                >
                  <div
                    className="py-.5 grid h-5 w-5 cursor-pointer place-items-center rounded-full px-2 hover:bg-gray-100"
                    onClick={(e) => {
                      setOpen((prev) => !prev);
                      e.stopPropagation();
                    }}
                  >
                    <i className="fa-solid fa-ellipsis-vertical mt-[1px] text-gray-700"></i>
                  </div>
                </TooltipWrapper>
              )}
            </div>
            {/* DELETE POP UP */}
            {showConfirm && (
              <GibooDialog
                onClose={() => setShowConfirm(false)}
                open={showConfirm}
                title="Delete project"
                content={<p>Are you sure you want to delete this project?</p>}
                footer={
                  <Button
                    id="btn-delete-project"
                    handleOnClick={() => {
                      if (!projectData?._id) return;
                      setIsDeleting(true);
                      deleteThis()
                        .then((res) => {
                          if (res) {
                            setShowConfirm(false);
                            GibooToast({
                              type: "success",
                              message: `Project deleted`,
                              actionLabel: "",
                            });
                          }
                        })
                        .catch((err) => {
                          // console.log(err);
                          GibooToast({
                            type: "failure",
                            message: `Failed to delete project`,
                            actionLabel: "",
                          });
                        })
                        .finally(() => {
                          // navigate("/projects", { replace: true });
                          setIsDeleting(false);
                        });
                    }}
                    color="purple"
                    label="Delete"
                    loading={isDeleting}
                  />
                }
              />
            )}
            {!hideFundraising && renderTarget()}
          </div>
        </div>
      )}
    </>
  );
}

export default ProjectCard2;
