import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./css/card.scss";
import "react-quill/dist/quill.snow.css";
import GeneratingLoi from "../pages/npo/grant/pages/GeneratingLoi";
import LetterOfInquiry from "../pages/npo/grant/pages/LetterOfInquiry";
import PopupModal from "./tailwind/PopupModal";
import { ILOIRequest } from "../types/loi";
import { useSetRecoilState } from "recoil";
import { feedBackReqAndRes } from "../app/recoilStore";
import { IProjectLetterCreateRequest } from "../types/project";
import classNames from "classnames";
import { isValidEmail } from "../utils/formatHelper";
import { IOption } from "./tailwind/AsyncCreatableSelector";
import { FunderMemberContact } from "../services/funders.services";
import useTotalOrganizationMembersData, {
  getTotalOrganizationMembersId,
} from "../hooks/useTotalOrganizationMembersData";
import useOnboardingData from "../hooks/useOnboarding";
import useContacts from "../hooks/useContacts";
import useUser from "../hooks/useUser";
import { ITotalOrganizationMember } from "../types/org";
import { SingleValue, ActionMeta, MultiValue } from "react-select";
import MultipleCreatableSelector from "./selector/MultipleCreatableSelector";
import { getFunderMemberContactKey } from "../types/funder";
import CreatableSelect from "react-select/dist/declarations/src/Creatable";
import CreatableSelector from "./selector/CreatableSelector";
import LeftInnerIconInput from "./LeftInnerIconInput";
import { sendEmailService } from "../services/send-email/sendEmail.services";
import GibooToast from "./GibooToast";
import { createProjectInteraction } from "../hooks/project/useInteraction";
import { useNavigate } from "react-router-dom";
import useLetter from "../hooks/project/useLetter";
import useTotalOrganizationMember from "../hooks/useTotalOrganizationMember";

type CallbackStringListType = (res: string[], completed: boolean, failed?: boolean) => void;
const getStreamingResponse = async (
  callback: CallbackStringListType,
  url: string,
  req: object,
  controller?: AbortController,
) => {
  callback([], false);
  const resp = await fetch(url, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      // "X-API-Key": process.env.REACT_APP_API_KEY || "",
      "Access-Control-Allow-Origin": window.location.origin || "",
    },
    body: JSON.stringify({
      req,
    }),
    ...(controller ? { signal: controller.signal } : {}),
  });
  const stack = [];
  if (!resp.ok || !resp.body) {
    throw resp.statusText;
  }
  const reader = resp.body.getReader();
  const done = false;
  while (!done) {
    const { value, done } = await reader.read();
    if (done) {
      break;
    }
    let decoded = "";
    try {
      decoded = new TextDecoder().decode(value);
    } catch (err: any) {
      callback([], false, true);
      return;
    }
    stack.push(decoded);
    callback(stack, false);
  }
  callback(stack, true);
};
const removeSelection = (text: string): string => {
  const regexPattern = /<span style="background-color: rgb\(230, 225, 242\);">/g;
  return text.replace(regexPattern, "<span>");
};
interface GenerateLoiProps {
  title?: string;
  open: boolean;
  query?: ILOIRequest;
  project_id?: string;
  prev_letter_id?: string;
  task_id?: string;
  target_id?: string;
  target_type?: 0 | 1 | 2;
  showTutorial?: boolean;
  onClose?: () => void;
  forFunder?: boolean;
  sendTo?: FunderMemberContact;
  sender?: ITotalOrganizationMember;
  subject?: string;
  onSave?: (loi: IProjectLetterCreateRequest) => void;
  disableFeedback?: boolean;
}
function GenerateLetterOfInquiry({
  title = "Letter of inquiry",
  open,
  query,
  project_id,
  task_id,
  target_id,
  target_type,
  prev_letter_id,
  showTutorial = false,
  onClose,
  forFunder,
  sender,
  sendTo,
  subject,
  disableFeedback,
  ...props
}: GenerateLoiProps) {
  const navigate = useNavigate();
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const [sent, setSent] = useState<boolean>(false);
  const { data: contacts, isLoading } = useContacts(target_id, target_type);
  const [curTitle, setCurTitle] = useState<string>(subject || "");
  const [loadingLOI, setLoadingLOI] = useState(false);
  const [openModal, setOpenModal] = useState(open);
  const [errorMsg, setError] = useState<string | undefined>();
  const [content, setContent] = useState<string | undefined>();
  const [completed, setCompleted] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [controller, setController] = useState<AbortController>();
  const { data: letter } = useLetter(prev_letter_id);

  const [curSender, setCurSender] = useState<IOption | undefined>(
    sender
      ? { label: sender.email || "", value: getTotalOrganizationMembersId(sender) }
      : undefined,
  );
  const [curSendTo, setCurSendTo] = useState<IOption[]>(
    sendTo?.email ? [{ label: sendTo.email || "", value: getFunderMemberContactKey(sendTo) }] : [],
  );
  const [cc, setCc] = useState<IOption[]>(user ? [{ value: user.email, label: user.email }] : []);
  const [bcc, setBcc] = useState<IOption[]>([]);
  const { data: members } = useTotalOrganizationMembersData(onboardingData._id);
  const senderOptions = members
    .filter((m) => m.email)
    .map((i) => ({
      label: i.email || "",
      value: getTotalOrganizationMembersId(i),
      data: i,
    }));

  useEffect(() => {
    if (open) {
      setTimeout(() => setShowLoading(false), 2000);
    }
  }, [open]);

  useEffect(() => {
    if (letter._id) {
      if (letter.sender) setCurSender({ label: letter.sender, value: letter.sender });
      if (letter.send_to) setCurSendTo(letter.send_to.map((s) => ({ label: s, value: s })));
      if (letter.cc) setCc(letter.cc.map((s) => ({ label: s, value: s })));
      if (letter.bcc) setBcc(letter.bcc.map((s) => ({ label: s, value: s })));
      if (letter.subject) setCurTitle(letter.subject);
      if (letter.content) setContent(letter.content);
    }
  }, [letter]);
  const inputClass = "!max-h-[32px] !min-h-[32px] !h-[32px] w-full";
  const leftDivSize = "min-w-[48px] max-w-[48px] w-12";

  const renderSender = () => {
    return (
      <div className="mb-3 flex w-full flex-col gap-1">
        <div className="flex w-full items-center gap-3">
          <div className={leftDivSize}>To</div>
          <div className="w-full grow">
            <MultipleCreatableSelector
              id="input-letter-to"
              compact
              data={contacts.filter((i) => isValidEmail(i.value))}
              value={curSendTo}
              className={inputClass}
              onCreateOption={(email) =>
                setCurSendTo((prev) =>
                  prev.find((p) => p.data?.email === email)
                    ? prev
                    : [...prev, { label: email, value: email, data: { email: email } }],
                )
              }
              onChange={(v) => {
                setCurSendTo(v.map((i) => i));
              }}
            />
          </div>
        </div>
        <div className="flex w-full items-center gap-3">
          <div className={leftDivSize}>Subject</div>
          <LeftInnerIconInput
            id="input-letter-subject"
            className={inputClass}
            defaultValue={curTitle}
            handleOnChange={(e) => setCurTitle(e.target.value)}
          />
        </div>

        <div className="flex w-full items-center gap-3">
          <div className={leftDivSize}>From</div>
          <div className="w-full grow">
            <CreatableSelector
              id="input-letter-from"
              compact
              data={senderOptions}
              value={curSender}
              className={inputClass}
              onCreateOption={(email) =>
                setCurSender({ label: email, value: email, data: { email: email } })
              }
              onChange={(v) => {
                setCurSender(v ? { ...v } : undefined);
              }}
            />
          </div>
        </div>
        <div className="flex w-full items-center gap-3">
          <div className={leftDivSize}>cc</div>
          <div className="w-full grow">
            <MultipleCreatableSelector
              id="input-letter-cc"
              compact
              data={senderOptions}
              value={cc}
              className={inputClass}
              onCreateOption={(email) =>
                setCc((prev) =>
                  prev.find((p) => p.data?.email === email)
                    ? prev
                    : [...prev, { label: email, value: email, data: { email: email } }],
                )
              }
              onChange={(v) => {
                setCc(v.map((i) => i));
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  // Temporarily storing request and response for feedback module
  const setRequestAndResponse = useSetRecoilState(feedBackReqAndRes);
  const LetterGenerator = useCallback(
    ({ setEditorContent }: { setEditorContent: React.Dispatch<React.SetStateAction<string>> }) => {
      useEffect(() => {
        if (open && query && !loadingLOI) {
          const c = new AbortController();
          setController(c);
          genLOI(c);
        }
      }, [open, query, loadingLOI]);
      const callback = useCallback(
        (_res: string[], completed: boolean) => {
          const res = _res.join("");
          setEditorContent(res);
          setContent(res);
          if (completed) {
            setCompleted(true);
            setContent(res);
            setEditorContent(res);
            setRequestAndResponse({ response: { data: res }, request: { data: query } });
            setLoadingLOI(false);
          }
        },
        [setContent, setRequestAndResponse, setLoadingLOI],
      );
      const genLOI = (controller: AbortController) => {
        if (loadingLOI || completed || errorMsg) return;
        setLoadingLOI(true);
        setError(undefined);
        setCompleted(false);
        if (query)
          getStreamingResponse(
            callback,
            process.env.REACT_APP_OPENAI_LETTER_URL || "",
            query,
            controller,
          ).catch((e) => setError("We apologize for the inconvenience. Please try again."));
      };
      return <></>;
    },
    [
      open,
      query,
      loadingLOI,
      completed,
      errorMsg,
      setContent,
      setRequestAndResponse,
      setLoadingLOI,
      setError,
      setCompleted,
    ],
  );
  const enableSendEmail =
    curSender?.label &&
    isValidEmail(curSender.label) &&
    curSendTo.filter((t) => t && isValidEmail(t.label)).length > 0 &&
    curTitle &&
    content
      ? true
      : false;
  const sendEmail = async () => {
    if (!enableSendEmail) return new Promise<void>((resolve, reject) => reject());
    const data = {
      content: content ? removeSelection(content) : "",
      sender: curSender?.label || "",
      to: curSendTo.filter((t) => t && isValidEmail(t.label)).map((t) => t.label),
      cc: cc.filter((t) => t && isValidEmail(t.label)).map((t) => t.label),
      bcc: [],
      subject: curTitle,
    };
    // console.log({ data });
    return sendEmailService(data)
      .then((res) => {
        if (res) {
          GibooToast({ type: "success", message: "The email has been sent." });
          // if (project_id && task_id)
          //   createProjectInteraction({ type: "Email", memo: "", task_id: task_id });
        }
      })
      .catch((err) => {
        GibooToast({ type: "failure", message: "Failed to send an email." });
        // console.log(err);
      });
  };
  return (
    <>
      {openModal && (
        <>
          <PopupModal
            title={title}
            // title={curTitle}
            // setTitle={setCurTitle}
            {...(!loadingLOI && { showActionButtons: true })}
            isOpen={openModal}
            wrapperClass={"!w-[1216px] min-h-[800px] md:!px-0 "}
            contentClass={classNames("relative !my-0 min-h-[755px] !px-0 w-full h-full")}
            handleClose={() => {
              if (props.onSave && content && (completed || prev_letter_id)) {
                props.onSave?.({
                  type: `${
                    letter.type
                      ? letter.type
                      : query?.type
                      ? `${query?.type.split("@")[0]} ${sent ? "sent" : "drafted"}`
                      : `Letter of inquiry drafted`
                  }`,
                  task_id: "", // filled at the onSave function
                  subject: curTitle,
                  content: removeSelection(content) || "",
                  send_to: curSendTo.map((i) => i.label),
                  sender: curSender?.label || "",
                  contact: curSendTo.map((i) => i.data).filter(Boolean) || [],
                  cc: cc.map((i) => i.label) || [],
                  bcc: [],
                });
              }
              if (controller) controller.abort();
              setContent(undefined);
              setCompleted(false);
              setShowLoading(true);
              setOpenModal(false);
              setError(undefined);
              onClose && onClose();
            }}
            handleSave={() => {
              if (controller) controller.abort();
              setContent(undefined);
              setCompleted(false);
              setShowLoading(true);
              setOpenModal(false);
              setError(undefined);
              onClose && onClose();
            }}
            showActionButtons={false}
            primaryButtonLabel="OK"
          >
            {!prev_letter_id && showLoading && !errorMsg ? (
              <div className="absolute top-0 z-[1] grid min-h-[805px] w-full place-items-center bg-white">
                <GeneratingLoi
                  title={title}
                  loading={loadingLOI}
                  forFunder={forFunder}
                  name={query?.funder_name}
                />
              </div>
            ) : null}
            <div className="absolute top-0 z-[0]">
              {!errorMsg ? (
                <LetterOfInquiry
                  showTutorial
                  setSent={setSent}
                  data={content || ""}
                  content={content}
                  loading={loadingLOI}
                  setContent={setContent}
                  // subject={subject}
                  // onSave={props.onSave}
                  renderSender={renderSender}
                  enableSendEmail={enableSendEmail}
                  sendEmail={sendEmail}
                  disableFeedback={disableFeedback}
                >
                  {({ setContent }) => <LetterGenerator setEditorContent={setContent} />}
                </LetterOfInquiry>
              ) : (
                <GeneratingLoi title="" message={errorMsg} />
              )}
            </div>
          </PopupModal>
        </>
      )}
    </>
  );
}

export default GenerateLetterOfInquiry;
