import classNames from "classnames";
import { scrollBarClass } from "../utils/classes";
import Button from "./tailwind/Button";
import { useCallback, useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import GibooToast from "./GibooToast";
import { convertToReadable, toDateString } from "../utils/formatHelper";
import IMG_TRASH from "../assets/project-mgt/trash.svg";
import IMG_EDIT from "../assets/images/edit.svg";
import Slideover from "./Slideover";
import UserProfile from "./UserProfile";
import { createProjectSubtaskNote } from "../hooks/project/useSubtaskNote";
import useSubtaskNotes from "../hooks/project/useSubtaskNotes";
import UserMentionsInput from "./UserMentionsInput";
import useOrgs from "../hooks/useOrgs";
import useOrgMembers from "../hooks/project/useOrgMembers";
import icon_attach from "../assets/project-mgt/attach.svg";
import useProject from "../hooks/project/useProject";
import OrgInvitationPopup from "./OrgInvitationPopup";
import CommentBox from "./CommentBox";
import ProjectInvite from "./ProjectInvite";

interface ProjectSubtaskNotesPopupProps {
  project_id: string | undefined;
  subtask_id: string | undefined;
  show: boolean;
  onClose?: () => void;
  title?: string;
}
function ProjectSubtaskNotesPopup({
  project_id,
  subtask_id,
  show,
  onClose,
  title,
  ...props
}: ProjectSubtaskNotesPopupProps) {
  const [user] = useUser();
  const { data, isLoading, revalidate } = useSubtaskNotes(subtask_id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [memo, setMemo] = useState("");
  const { selected } = useOrgs();
  const { data: members, isLoading: orgmembersLoading } = useOrgMembers(selected);
  const [showInvite, setShowInvite] = useState<boolean>(false);

  const renderNoteList = (activity: any, index: number) => {
    return (
      <div
        key={"Note_" + index}
        className="flex h-fit w-full flex-col gap-3 rounded border border-gray-300 p-4"
      >
        <div className="inline-flex w-full justify-between gap-3">
          <span className="w-[200px]">
            <UserProfile userId={activity.user_id} showName={true} size={24} />
          </span>
          <h5 className="font-poppins text-xs text-gray-900">
            {activity?.created_at ? toDateString(activity?.created_at, true, true) : "None"}
          </h5>
        </div>
        <div className="w-full">
          <div className="flex items-start gap-2">
            <h5 className="font-poppins text-sm text-gray-900">
              {convertToReadable(activity?.content)}
            </h5>
            {/* 
              {!activity?.memo && (
                <PlainButton
                  className="!-ml-4 !p-0 !text-sm"
                  color="purple"
                  underline
                  label="Click to add note"
                  handleOnClick={() => {
                    setEditIndex(index);
                    setCurrentMemo(activityList[index].memo || "");
                  }}
                  id={`btn-add-memo-${index}`}
                />
              )} */}
          </div>
        </div>
      </div>
    );
  };
  const renderInteractionLog = useCallback(() => {
    return (
      <>
        <div
          className={classNames("mt-3 flex h-[calc(100vh-490px)] flex-col gap-3", scrollBarClass)}
        >
          {data.length > 0 ? (
            data.map((i, index) => {
              return (
                <CommentBox
                  projectId={project_id}
                  from_for_mixpanel="mention_in_project_task_comment"
                  key={i._id}
                  activity={i as any}
                  revalidate={() => {
                    revalidate();
                  }}
                  isNote
                />
              );
            })
          ) : (
            <div className="grid h-[130px] place-items-center rounded border border-gray-300">
              <h4 className="font-poppins text-xl font-medium text-black">No notes</h4>
            </div>
          )}
        </div>
      </>
    );
  }, [data]);
  const handleInteraction = () => {
    if (!subtask_id) return;
    setIsUpdating(true);
    createProjectSubtaskNote({ content: memo, subtask_id: subtask_id })
      .then(() => {
        // GibooToast({
        //   type: "success",
        //   message: "Activity note added successfully",
        // });
        revalidate();
      })
      .catch(() => {
        GibooToast({
          type: "failure",
          message: "We apologize for the inconvenience., Please try again",
        });
      })
      .finally(() => {
        setMemo("");
        setIsUpdating(false);
      });
  };
  return (
    <>
      {showInvite &&
        (project_id ? (
          <ProjectInvite
            from_for_mixpanel="mention_in_project_task_comment"
            projectId={project_id}
            showInvite={showInvite}
            handleClose={() => setShowInvite(false)}
          />
        ) : (
          <OrgInvitationPopup
            from_for_mixpanel="mention_in_project_task_comment"
            show={showInvite}
            onClose={() => setShowInvite(false)}
          />
        ))}
      <Slideover
        isOpen={show}
        onClose={() => {
          onClose?.();
        }}
        title={title}
        direction="right"
        size="md"
      >
        <div className="mt-[44px] flex h-fit min-h-[200px] w-full flex-col gap-3 py-5">
          <div className="flex w-full">
            <h5 className="whitespace-nowrap font-poppins text-base">Add a note</h5>
          </div>
          <>
            <UserMentionsInput
              id="note"
              value={memo}
              placeholder="Add a note"
              noOfRows={5}
              className="!mb-0 !min-h-[124px] !pb-0"
              handleOnChange={(event, newValue, newPlainTextValue, mentions) => {
                if (mentions?.[0]?.id === "CUSTOM") {
                  return;
                }
                setMemo(event.target.value);
              }}
              onAdd={(id) => {
                if (id === "CUSTOM") {
                  setShowInvite(true);
                }
              }}
              customComponent={
                <div className="w-full text-center font-poppins text-xs text-gray-900">
                  Invite member
                </div>
              }
              options={[
                ...(members
                  .filter((i) => i._id !== user?._id)
                  .map((i) => ({ id: i._id, display: `${i.firstname} ${i.lastname}` })) as []),
                { id: "CUSTOM", display: `Invite Member` },
              ]}
            />
            <div className="flex w-full items-center justify-between">
              <span className="inline-flex w-full items-center gap-5">
                {/* <h5 className="inline-flex gap-2 font-poppins text-sm text-gray-700">
                <img src={icon_attach} className="h-4 w-4" />
                add files
              </h5> */}
                <h5 className="font-poppins text-sm text-gray-700">@ mention</h5>
              </span>
              <Button
                id="btn-loi-close"
                className="!h-[37px]"
                color="purple"
                disabled={memo === ""}
                label={isUpdating ? "Updating" : "Update"}
                loading={isUpdating}
                handleOnClick={() => {
                  handleInteraction();
                }}
              />
            </div>
            {data.length > 0 && renderInteractionLog()}
          </>
        </div>
      </Slideover>
    </>
  );
}
export default ProjectSubtaskNotesPopup;
