import { Outlet, useLocation } from "react-router-dom";
import { RootState, useAppSelector } from "../../app/store";
import { getOnboardingStatus } from "../../app/onboardingSlice";
import { useEffect } from "react";
import Header from "../Header";
import classNames from "classnames";
import ErrorBoundary from "../../components/ErrorBoundary";
import Spacer from "../../components/Spacer";

import GibooSidekick from "../../components/GibooSidekick";
const exceptionList = ["/search", "/project", "/financial", "/diversity", "/document", "/my-tasks"];

function Layout({ withoutLogin = false }: { withoutLogin?: boolean }) {
  const browserLocation = useLocation();
  const { isFetched, completed, selected, accepted } = useAppSelector((state: RootState) =>
    getOnboardingStatus(state),
  );
  useEffect(() => {
    // if (!selected) navigate("/", { replace: true });
  }, [selected]);

  return (
    <div
      id="giboo-main-layout"
      className="h-full min-h-screen w-full overflow-x-hidden overflow-y-visible bg-gray-50"
    >
      {withoutLogin || isFetched ? (
        <>
          <div className="flex h-full w-full flex-col">
            {!withoutLogin && <GibooSidekick />}
            <Header customClass="top-0 z-10 shrink-0" withoutLogin={withoutLogin} />
            <Spacer height="58px" />
            <div
              className={classNames(
                "flex h-auto w-full flex-col self-center",
                exceptionList.find((p) => browserLocation.pathname.startsWith(p))
                  ? ""
                  : "max-w-[1280px]",
              )}
              style={{
                ...(exceptionList.find((p) => browserLocation.pathname.startsWith(p))
                  ? { maxHeight: `calc(100vh - 58px)` }
                  : {}),
              }}
            >
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </div>
          </div>
        </>
      ) : (
        <div className="grid h-screen w-screen place-items-center">
          <div
            className="m-5  inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-purple-500 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
export default Layout;
