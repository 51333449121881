import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import uuid from "react-uuid";
import { useRecoilState, useSetRecoilState } from "recoil";
import config from "../api";
import {
  searchSession,
  sidekickSearchCount,
  sidekickSearchEstimatedLoadingTime,
  sidekickSearchLoading,
} from "../app/recoilStore";
import { updateSearchHistoryAsync } from "../app/searchHistorySlice";
import { useAppDispatch } from "../app/store";
import { sortSearchResult } from "../components/selector/GroupBaseMultipleCreatableSelector";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import {
  AdvancedFilter,
  PastGrantOverview,
  SearchQuery,
  SearchQueryNullable,
  UnifiedSearchResult,
  getObjectFromURLSearchParams,
  getSearchQueryFromISearchParam,
  getValidAdvancedFilter,
  isearch_param_advanced_filter_array_cols,
} from "../types/search";
import { swrOptionDedupling5mins } from "../types/swr";
import { filterUnmatched, matchTaxonomyBySearchQuery } from "../types/taxonomy";
import { prettierTaxonomy, prettierTaxonomyString } from "../utils/philanthropy";
import useFunderSearchResult from "./search/useFunderSearchResult";
import useHiddenSearchItems from "./search/useHiddenSearchItems";
import useTaggingOperatorResult from "./search/useTaggingOperatorResult";
import useTaggingResult from "./search/useTaggingResult";
import useCancellableSWR from "./useCancellableSWR";
import useGibooMixpanel from "./useGibooMixpanel";
import useOnboardingData from "./useOnboarding";
import usePastGrantOverview from "./usePastGrantOverview";
import useUser from "./useUser";

export const getImportantSearchQueryKey = (query: SearchQuery, prefix = ""): string => {
  // const loc = query.service_loc.map((l) => reprLocation(l));
  // const must_loc = query.must_service_loc.map((l) => reprLocation(l));
  // const hq = query.hq_loc.map((l) => reprLocation(l));
  // const must_hq = query.must_hq_loc.map((l) => reprLocation(l));
  // const importantKeys = {
  //   mission: query.mission,
  //   user_id: query.user_id,
  //   focus_area: query.focus_area.sort((a, b) => a.localeCompare(b)),
  //   beneficiary: query.beneficiary.sort((a, b) => a.localeCompare(b)),
  //   program: query.program.sort((a, b) => a.localeCompare(b)),
  //   must_focus_area: query.must_focus_area.sort((a, b) => a.localeCompare(b)),
  //   must_beneficiary: query.must_beneficiary.sort((a, b) => a.localeCompare(b)),
  //   must_program: query.must_program.sort((a, b) => a.localeCompare(b)),
  //   service_loc: loc.sort((a, b) => a.localeCompare(b)),
  //   hq_loc: hq.sort((a, b) => a.localeCompare(b)),
  //   must_loc:must_loc.sort((a, b) => a.localeCompare(b)),
  //   must_hq_loc:must_hq.sort((a, b) => a.localeCompare(b)),
  // };
  return prefix + JSON.stringify(query);
};
const result_type_to_p = {
  c: 1,
  s: 2,
  l: 3,
};
interface SearchResponse {
  query_latency_sec: number;
  len_total: number;
  len_colbert: number;
  len_common: number;
  req: SearchQuery;
  res: { uid: number; conf: number }[];
  items: UnifiedSearchResult[];
}
interface IGibooSearch {
  session: string;
  query: SearchQuery | undefined;
  count: number;
  maxCountForPage: number;
  data: UnifiedSearchResult[];
  dataByName: UnifiedSearchResult[];
  pastGrantOverview: PastGrantOverview | undefined;
  isLoading: boolean;
  countLoading: boolean;
  isError: boolean;
}
export const queryMaskForFunderSearch: SearchQueryNullable = {
  selected: undefined,
  without_blink: undefined,
  donor_id: undefined,
  grant_deadline: undefined,
  grant_deadline_filter: [],
  funding_type: [],
  grant_type: [],
  grantee_type: [],
  any_location: false,
  incorporated_status: [],
  year_of_formation_min: undefined,
  year_of_formation_max: undefined,
  tax_exempt_status: [],
  npo_grant_amount_min: undefined,
  npo_grant_amount_max: undefined,
  npo_assets: [],
  npo_month_of_liquid: [],
  npo_financial_exact_value_filters: [],
  npo_financial_per_revenue_filters: [],
  npo_financial_per_expense_filters: [],
};
export const queryMaskForGrantSearch: SearchQueryNullable = {
  selected: undefined,
  without_blink: undefined,
  donor_id: undefined,
  grantee_type: [],
  solicitation: [],
  incorporated_status: [],
  year_of_formation_min: undefined,
  year_of_formation_max: undefined,
  tax_exempt_status: [],
  npo_grant_amount_min: undefined,
  npo_grant_amount_max: undefined,
  npo_assets: [],
  npo_month_of_liquid: [],
  npo_financial_exact_value_filters: [],
  npo_financial_per_revenue_filters: [],
  npo_financial_per_expense_filters: [],
};
export const queryMaskForNPOSearch: SearchQueryNullable = {
  selected: undefined,
  without_blink: undefined,
  donor_id: undefined,
  grant_deadline: undefined,
  grant_deadline_filter: [],
  solicitation: [],
  funding_type: [],
  grant_type: [],
  funding_size: [],
  funder_assets: [],
  grantee_type: [],
  hq_loc: [],
  hq_loc_subs: [],
  any_location: false,
  previous_funders: [],
};
export const queryMaskForEligibleNPOSearch: SearchQueryNullable = {
  ...queryMaskForNPOSearch,
  text_query: "",
  mission: "",
  sortby: "relevance",
};
export const queryMaskForPastGrantSearch: SearchQueryNullable = {
  selected: undefined,
  without_blink: undefined,
  grant_deadline: undefined,
  grant_deadline_filter: [],
  solicitation: [],
  funding_type: [],
  grant_type: [],
  funder_type: [],
  funding_size: [],
  funder_assets: [],
  grantee_type: [],
  grantee_stage: [],
  contact: [],
  any_location: false,
  previous_funders: [],
  incorporated_status: [],
  year_of_formation_min: undefined,
  year_of_formation_max: undefined,
  tax_exempt_status: [],
  npo_grant_amount_min: undefined,
  npo_grant_amount_max: undefined,
  npo_assets: [],
  npo_month_of_liquid: [],
  npo_financial_exact_value_filters: [],
  npo_financial_per_revenue_filters: [],
  npo_financial_per_expense_filters: [],
};
export const queryMaskForCurrentGrantSearchWithSpecificFunder: SearchQueryNullable = {
  selected: undefined,
  without_blink: undefined,
  text_query: "",
  mission: "",
  grant_deadline: undefined,
  grant_deadline_filter: [],
  solicitation: [],
  grant_type: [],
  funder_type: [],
  funding_type: [],
  funding_size: [],
  funder_assets: [],
  grantee_type: [],
  grantee_stage: [],
  contact: [],
  any_location: false,
  previous_funders: [],
  incorporated_status: [],
  year_of_formation_min: undefined,
  year_of_formation_max: undefined,
  tax_exempt_status: [],
  npo_grant_amount_min: undefined,
  npo_grant_amount_max: undefined,
  npo_assets: [],
  npo_month_of_liquid: [],
  npo_financial_exact_value_filters: [],
  npo_financial_per_revenue_filters: [],
  npo_financial_per_expense_filters: [],
};
export const queryMaskForPastGrantOverview: SearchQueryNullable = {
  selected: undefined,
  without_blink: undefined,
  text_query: "",
  mission: "",
  focus_area_subs: [],
  beneficiary_subs: [],
  program_subs: [],
  service_loc_subs: [],
  hq_loc: [],
  hq_loc_subs: [],
  exclude_hq_loc: [],
  tagged_focus_area: [],
  tagged_beneficiary: [],
  tagged_program: [],
  must_focus_area: [],
  must_beneficiary: [],
  must_program: [],
  must_service_loc: [],
  must_hq_loc: [],
  pagination_skip: 0,
  pagination_limit: 10,
  grant_deadline: undefined,
  grant_deadline_filter: [],
  solicitation: [],
  grant_type: [],
  funder_type: [],
  funding_type: [],
  funding_size: [],
  funder_assets: [],
  grantee_type: [],
  grantee_stage: [],
  contact: [],
  any_location: false,
  previous_funders: [],
  incorporated_status: [],
  year_of_formation_min: undefined,
  year_of_formation_max: undefined,
  tax_exempt_status: [],
  npo_grant_amount_min: undefined,
  npo_grant_amount_max: undefined,
  npo_assets: [],
  npo_month_of_liquid: [],
  npo_financial_exact_value_filters: [],
  npo_financial_per_revenue_filters: [],
  npo_financial_per_expense_filters: [],
};
export const queryMaskForNameSearch: SearchQueryNullable = {
  selected: undefined,
  without_blink: undefined,
  mission: "",
  donor_id: undefined,
  focus_area_subs: [],
  beneficiary_subs: [],
  program_subs: [],
  service_loc_subs: [],
  hq_loc_subs: [],
  focus_area: [],
  beneficiary: [],
  program: [],
  service_loc: [],
  hq_loc: [],
  tagged_focus_area: [],
  tagged_beneficiary: [],
  tagged_program: [],
  must_focus_area: [],
  must_beneficiary: [],
  must_program: [],
  must_service_loc: [],
  must_hq_loc: [],
  exclude_focus_area: [],
  exclude_beneficiary: [],
  exclude_program: [],
  exclude_service_loc: [],
  exclude_hq_loc: [],
  grant_deadline: undefined,
  grant_deadline_filter: [],
  solicitation: [],
  grant_type: [],
  funder_type: [],
  funding_type: [],
  funding_size: [],
  funder_assets: [],
  grantee_type: [],
  grantee_stage: [],
  contact: [],
  current_grantee_stage: "",
  any_location: false,
  npo_uid: undefined,
  previous_funders: [],
  incorporated_status: [],
  year_of_formation_min: undefined,
  year_of_formation_max: undefined,
  tax_exempt_status: [],
  npo_grant_amount_min: undefined,
  npo_grant_amount_max: undefined,
  npo_assets: [],
  npo_month_of_liquid: [],
  npo_financial_exact_value_filters: [],
  npo_financial_per_revenue_filters: [],
  npo_financial_per_expense_filters: [],
};
const advanced_filter_cols = isearch_param_advanced_filter_array_cols;
interface ITotal {
  loading: boolean;
  items: UnifiedSearchResult[];
  case?: string;
}
const UNIT = 20;
const NAME_SEARCH_UNIT = 8;
const swrOption = swrOptionDedupling5mins;
const diffQuery = (a: SearchQuery, b: SearchQuery): boolean => {
  const diff = Object.keys(a).find(
    (key) =>
      !["selected"].includes(key) &&
      JSON.stringify(a[key as keyof typeof a]) !== JSON.stringify(b[key as keyof typeof b]),
  );
  return diff ? true : false;
};
const useGibooStructureSearch = (pause?: boolean, withoutLogin?: boolean): IGibooSearch => {
  const mxEvent = useGibooMixpanel(undefined, true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const browserLoaction = useLocation();
  const [session, setSession] = useRecoilState(searchSession);
  const [user] = useUser();
  const [onboardingData] = useOnboardingData();
  const { id } = useParams();
  const pageId = !id || !+id ? 1 : +id;
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTextQuery, setSearchTextQuery] = useState<string | undefined>(
    searchParams.get("text_query") || undefined,
  );
  const [controller, setController] = useState<AbortController>();
  const [dataByName, setDataByName] = useState<UnifiedSearchResult[]>([]);
  const [count, setCount] = useState<number>(0);
  const [maxCount, setMaxCount] = useState<number>(0);
  const [query, setQuery] = useState<SearchQuery | undefined>();
  const [type, setType] = useState<string[]>([]);
  const [delayedLoading, setDelayedLoading] = useState<boolean>(true);
  const [searchLoading, setSearchLoading] = useRecoilState(sidekickSearchLoading);
  const setSidekickSearchEstimatedLoadingTime = useSetRecoilState(
    sidekickSearchEstimatedLoadingTime,
  );
  const setSidekickSearchCount = useSetRecoilState(sidekickSearchCount);
  const { data: funderSearchResult, isLoading: isFunderSearchResultLoading } =
    useFunderSearchResult(searchParams.get("donor_id") || undefined);

  const {
    data: res,
    currentTextQuery,
    isLoading: taggingLoading,
    error: taggingError,
  } = useTaggingResult(searchParams.get("text_query") || "", searchParams.get("mission") || "");
  const {
    data: taggingOperator,
    currentTextQuery: taggingOperatorCurrentTextQuery,
    isLoading: taggingOperatorLoading,
    error: taggingOperatorError,
  } = useTaggingOperatorResult(
    searchParams.get("text_query") || "",
    searchParams.get("mission") || "",
    currentTextQuery,
    process.env.REACT_APP_DISABLE_TAGGING_OPERATOR === "TRUE" ? undefined : res,
  );
  const { data: hiddenFunders, isLoading: isHiddenFundersLoading } = useHiddenSearchItems({
    type: "funder",
    enable: !withoutLogin,
  });
  const { data: hiddenGrants, isLoading: isHiddenGrantsLoading } = useHiddenSearchItems({
    type: "grant",
    enable: !withoutLogin,
  });
  const { data: hiddenVirtualGrants, isLoading: isHiddenVirtualGrantsLoading } =
    useHiddenSearchItems({
      type: "virtual_grant",
      enable: !withoutLogin,
    });
  const { data: hiddenNPOs, isLoading: isHiddenNPOsLoading } = useHiddenSearchItems({
    type: "npo",
    enable: !withoutLogin,
  });
  const { data: hiddenPastGrants, isLoading: isHiddenPastGrantsLoading } = useHiddenSearchItems({
    type: "past_grant",
    enable: !withoutLogin,
  });
  useEffect(() => {
    const text_query = searchParams.get("text_query") || "";
    if (!searchTextQuery || searchTextQuery !== text_query) {
      setSession(uuid());
    }
    setSearchTextQuery(text_query);
  }, [searchParams]);
  useEffect(() => {
    // if (!onboardingData._id) {
    //   setQuery(undefined);
    //   setType([]);
    //   setTotal({
    //     loading: true,
    //     items: [],
    //   });
    //   return;
    // }

    // setTotal((prev) => ({
    //   loading: true,
    //   items: prev.items,
    // }));
    const _obj = getObjectFromURLSearchParams(searchParams, taggingOperator);
    if (!_obj.npo_uid && onboardingData.npo_uid) {
      searchParams.set("npo_uid", `${onboardingData.npo_uid}`);
      navigate(`${browserLoaction.pathname}?${searchParams.toString()}`, { replace: true });
      return;
    }
    const obj = {
      ..._obj,
      ...prettierTaxonomyString(_obj),
      ...advanced_filter_cols.reduce(
        (prev, key) => ({
          ...prev,
          [key]: (_obj[key as keyof typeof _obj] as AdvancedFilter[]).filter((f) =>
            getValidAdvancedFilter(f),
          ),
        }),
        {},
      ),
    };
    // console.log({
    //   x: advanced_filter_cols.reduce(
    //     (prev, key) => ({
    //       ...prev,
    //       [key]: (_obj[key as keyof typeof _obj] as AdvancedFilter[]).filter((f) =>
    //         getValidAdvancedFilter(f),
    //       ),
    //     }),
    //     {},
    //   ),
    //   a0: _obj.npo_financial_exact_value_filters,
    //   a11: _obj.npo_financial_exact_value_filters.filter((f) => getValidAdvancedFilter(f)),
    //   a1: obj.npo_financial_exact_value_filters,
    //   a3: obj.npo_financial_per_expense_filters,
    // });

    if (obj.done_tagging) {
      const newQuery = {
        ...getSearchQueryFromISearchParam(user?._id || "", onboardingData, obj),
        pagination_skip: UNIT * (pageId - 1),
        pagination_limit: UNIT,
      };
      const diff = !query || diffQuery(newQuery, query);
      setTotal((prev) => ({
        loading: prev.loading,
        items: prev.items,
        case: "start search",
      }));
      setQuery(newQuery);
      setType(obj.type);
      if (diff)
        mxEvent(MIXPANEL_EVENTS_V2.search[""].started, {
          session,
          searchType: obj.type[0],
          searchQuery: newQuery,
        });
    }
  }, [user, searchParams, setSearchParams, pageId, onboardingData, taggingOperator]);
  useEffect(() => {
    if (!query || query.pagination_skip) return;
    const search_type = type.includes("funder")
      ? 1
      : type.includes("grant") || type.includes("virtual_grant")
      ? 2
      : type.includes("npo")
      ? 3
      : -1;
    if (search_type > 0) {
      dispatch(updateSearchHistoryAsync(query, search_type));
    }
  }, [query, type]);

  const fetchFunderSearchHidden = useCallback(async (url: string, controller: AbortController) => {
    try {
      const req = JSON.parse(url.slice(22));
      return axios
        .post(
          process.env.REACT_APP_API_URL +
            `/api/v2/search/donors/hidden?user_id=${req.user_id}&org_id=${req.org_id}`,
          {
            ...req,
          },
          { ...config, ...{ signal: controller.signal } },
        )
        .then((res) => res.data);
    } catch {
      return new Promise<any>((resolve, reject) => reject());
    }
  }, []);
  const fetchFunderSearch = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(10));
        return axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/search/donors`,
            {
              ...req,
              hidden_uids: hiddenFunders,
              store_search_history: true,
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => ({
            ...res.data,
            items: res.data.items.map((d: any) => ({
              ...d,
              search_type: "funder",
              search_by_name: req.search_by_name,
              service_specific_loc: true,
            })),
          }));
      } catch {
        return new Promise<any>((resolve, reject) => reject());
      }
    },
    [hiddenFunders],
  );
  const fetchFunderSearchSize = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(15));
        return axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/search/donors/size`,
            {
              ...req,
              hidden_uids: hiddenFunders,
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => res.data.size);
      } catch {
        return new Promise<number>((resolve, reject) => reject());
      }
    },
    [hiddenFunders],
  );

  const fetchGrantSearchHidden = useCallback(async (url: string, controller: AbortController) => {
    try {
      const req = JSON.parse(url.slice(22));
      return axios
        .post(
          process.env.REACT_APP_API_URL +
            `/api/v2/search/grants/hidden?user_id=${req.user_id}&org_id=${req.org_id}`,
          {
            ...req,
          },
          { ...config, ...{ signal: controller.signal } },
        )
        .then((res) => res.data);
    } catch {
      return new Promise<any>((resolve, reject) => reject());
    }
  }, []);
  const fetchGrantSearch = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(9));
        return axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/search/grants`,
            {
              ...req,
              hidden_uids: [...hiddenGrants, ...hiddenVirtualGrants],
              store_search_history: true,
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => ({
            ...res.data,
            items: res.data.items.map((d: any) => ({
              ...d,
              search_type: "grant",
              search_by_name: req.search_by_name,
            })),
          }));
      } catch {
        return new Promise<any>((resolve, reject) => reject());
      }
    },
    [hiddenGrants, hiddenVirtualGrants],
  );
  const fetchGrantSearchPrev = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(9));
        return axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/search/grants`,
            {
              ...req,
              hidden_uids: [...hiddenGrants, ...hiddenVirtualGrants],
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) =>
            res.data.map((d: any) => ({
              ...d,
              search_type: "grant",
              search_by_name: req.search_by_name,
            })),
          );
      } catch {
        return new Promise<any[]>((resolve, reject) => reject());
      }
    },
    [hiddenGrants, hiddenVirtualGrants],
  );
  const fetchGrantSearchSize = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(14));
        return axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/search/grants/size`,
            {
              ...req,
              hidden_uids: [...hiddenGrants, ...hiddenVirtualGrants],
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => res.data.size);
      } catch {
        return new Promise<number>((resolve, reject) => reject());
      }
    },
    [hiddenGrants, hiddenVirtualGrants],
  );

  const fetchNPOSearchHidden = useCallback(async (url: string, controller: AbortController) => {
    try {
      const req = JSON.parse(url.slice(19));
      return axios
        .post(
          process.env.REACT_APP_API_URL +
            `/api/v2/search/npos/hidden?user_id=${req.user_id}&org_id=${req.org_id}`,
          {
            ...req,
          },
          { ...config, ...{ signal: controller.signal } },
        )
        .then((res) => res.data);
    } catch {
      return new Promise<any>((resolve, reject) => reject());
    }
  }, []);

  const fetchEligibleNPOSearch = useCallback(async (url: string, controller: AbortController) => {
    try {
      const req = JSON.parse(url.slice(8));
      return axios
        .post(
          process.env.REACT_APP_API_URL + `/api/v2/search/eligible_npos`,
          {
            ...req,
            hidden_uids: [],
            store_search_history: true,
          },
          { ...config, ...{ signal: controller.signal } },
        )
        .then((res) => ({
          ...res.data,
          items: res.data.items.map((d: any) => ({
            ...d,
            search_type: "npo",
            search_by_name: false,
            service_specific_loc: true,
          })),
        }));
    } catch {
      return new Promise<any[]>((resolve, reject) => reject());
    }
  }, []);
  const fetchNPOSearch = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(7));
        return axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/search/npos`,
            {
              ...req,
              hidden_uids: hiddenNPOs,
              store_search_history: true,
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => ({
            ...res.data,
            items: res.data.items.map((d: any) => ({
              ...d,
              search_type: "npo",
              search_by_name: req.search_by_name,
              service_specific_loc: true,
            })),
          }));
      } catch {
        return new Promise<any[]>((resolve, reject) => reject());
      }
    },
    [hiddenNPOs],
  );
  const fetchNPOSearchSize = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(12));
        return axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/search/npos/size`,
            {
              ...req,
              hidden_uids: hiddenNPOs,
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => res.data.size);
      } catch {
        return new Promise<number>((resolve, reject) => reject());
      }
    },
    [hiddenNPOs],
  );

  const fetchGrantSearchWithSpecificFunder = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(12));
        return axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/search/grants_specific_funder`,
            {
              ...req,
              hidden_uids: [...hiddenGrants, ...hiddenVirtualGrants],
              store_search_history: false,
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) =>
            res.data.map((d: any) => ({
              ...d,
              search_type: "grant",
              search_by_name: req.search_by_name,
            })),
          );
      } catch {
        return new Promise<any>((resolve, reject) => reject());
      }
    },
    [hiddenGrants, hiddenVirtualGrants],
  );
  const fetchGrantSearchSizeWithSpecificFunder = useCallback(
    async (url: string, controller: AbortController) => {
      try {
        const req = JSON.parse(url.slice(17));
        return axios
          .post(
            process.env.REACT_APP_API_URL + `/api/v2/search/grants_specific_funder/size`,
            {
              ...req,
              hidden_uids: [...hiddenGrants, ...hiddenVirtualGrants],
            },
            { ...config, ...{ signal: controller.signal } },
          )
          .then((res) => res.data.size);
      } catch {
        return new Promise<number>((resolve, reject) => reject());
      }
    },
    [hiddenGrants, hiddenVirtualGrants],
  );
  const {
    data: funder,
    isLoading: isFunderLoading,
    error: errorFunder,
  } = useCancellableSWR<SearchResponse>(
    pause ||
      !query ||
      (!type.includes("funder") && !type.includes("by_name")) ||
      isHiddenFundersLoading
      ? null
      : "gss_funder" +
          JSON.stringify({
            ...query,
            ...queryMaskForFunderSearch,
            search_by_name: type.includes("by_name") || undefined,
          }),
    fetchFunderSearch,
    swrOption,
  );
  const {
    data: funderSize,
    isLoading: isFunderSizeLoading,
    error: errorFunderSize,
  } = useCancellableSWR<number>(
    pause || !query || !type.includes("by_name") || isHiddenFundersLoading
      ? null
      : "gss_funder_size" +
          JSON.stringify({
            ...query,
            ...queryMaskForFunderSearch,
            search_by_name: type.includes("by_name") || undefined,
            pagination_limit: 20,
            pagination_skip: 0,
          }),
    fetchFunderSearchSize,
    swrOption,
  );
  const {
    data: funderName,
    isLoading: isFunderNameLoading,
    error: errorFunderName,
  } = useCancellableSWR<SearchResponse>(
    pause ||
      !query ||
      !query.text_query.trim() ||
      !type.includes("funder") ||
      isHiddenFundersLoading
      ? null
      : "gss_funder" +
          JSON.stringify({
            ...query,
            ...queryMaskForNameSearch,
            search_by_name: true,
            match_exact: true,
            pagination_limit: 3,
            pagination_skip: 0,
          }),
    fetchFunderSearch,
    swrOption,
  );
  const {
    data: funderNameWithinQuery,
    isLoading: isFunderNameWithinQueryLoading,
    error: errorFunderNameWithinQuery,
  } = useCancellableSWR<number[]>(
    withoutLogin ||
      pause ||
      !query ||
      !type.includes("funder") ||
      isFunderNameLoading ||
      !funderName
      ? null
      : "gss_funder_name_hidden" +
          JSON.stringify({
            ...query,
            ...queryMaskForFunderSearch,
            hidden_uids: funderName.items.map((i) => i.uid),
            pagination_limit: 3,
            pagination_skip: 0,
          }),
    fetchFunderSearchHidden,
    swrOption,
  );
  const {
    data: grant,
    isLoading: isGrantLoading,
    error: errorGrant,
  } = useCancellableSWR<SearchResponse>(
    pause ||
      !query ||
      (!type.includes("grant") && !type.includes("virtual_grant") && !type.includes("by_name")) ||
      isHiddenGrantsLoading ||
      isHiddenVirtualGrantsLoading
      ? null
      : "gss_grant" +
          JSON.stringify({
            ...query,
            ...queryMaskForGrantSearch,
            grant_type: type.includes("virtual_grant") ? ["virtual"] : ["opencall"],
            any_location: type.includes("virtual_grant") ? false : query.any_location,
            ...(type.includes("by_name") ? queryMaskForNameSearch : {}),
            search_by_name: type.includes("by_name") || undefined,
          }),
    fetchGrantSearch,
    swrOption,
  );
  const {
    data: grantSize,
    isLoading: isGrantSizeLoading,
    error: errorGrantSize,
  } = useCancellableSWR<number>(
    pause ||
      !query ||
      !type.includes("by_name") ||
      isHiddenGrantsLoading ||
      isHiddenVirtualGrantsLoading
      ? null
      : "gss_grant_size" +
          JSON.stringify({
            ...query,
            ...queryMaskForGrantSearch,
            search_by_name: type.includes("by_name") || undefined,
            pagination_limit: 20,
            pagination_skip: 0,
          }),
    fetchGrantSearchSize,
    swrOption,
  );
  const {
    data: grantName,
    isLoading: isGrantNameLoading,
    error: errorGrantName,
  } = useCancellableSWR<SearchResponse>(
    pause ||
      !query ||
      !query.text_query.trim() ||
      (!type.includes("grant") && !type.includes("virtual_grant")) ||
      isHiddenGrantsLoading ||
      isHiddenVirtualGrantsLoading
      ? null
      : "gss_grant" +
          JSON.stringify({
            ...query,
            ...queryMaskForNameSearch,
            grant_type: type.includes("virtual_grant") ? ["virtual"] : ["opencall"],
            any_location: type.includes("virtual_grant") ? false : query.any_location,
            search_by_name: true,
            match_exact: true,
            pagination_limit: 3,
            pagination_skip: 0,
          }),
    fetchGrantSearch,
    swrOption,
  );
  const {
    data: grantNameWithinQuery,
    isLoading: isGrantNameWithinQueryLoading,
    error: errorGrantNameWithinQuery,
  } = useCancellableSWR<number[]>(
    withoutLogin ||
      pause ||
      !query ||
      (!type.includes("grant") && !type.includes("virtual_grant")) ||
      isGrantNameLoading ||
      !grantName
      ? null
      : "gss_grant_name_hidden" +
          JSON.stringify({
            ...query,
            ...queryMaskForGrantSearch,
            grant_type: type.includes("virtual_grant") ? ["virtual"] : ["opencall"],
            any_location: type.includes("virtual_grant") ? false : query.any_location,
            hidden_uids: grantName.items.map((i) => i.uid),
            pagination_limit: 3,
            pagination_skip: 0,
          }),
    fetchGrantSearchHidden,
    swrOption,
  );
  const { data: grantSpecificFunderSize, isLoading: isGrantSpecificFunderSizeLoading } =
    useCancellableSWR<number>(
      pause ||
        !query ||
        !type.includes("specific_funder_grant") ||
        isHiddenGrantsLoading ||
        isHiddenVirtualGrantsLoading
        ? null
        : "gss_grant_sf_size" +
            JSON.stringify({
              ...query,
              ...queryMaskForCurrentGrantSearchWithSpecificFunder,
              pagination_limit: undefined,
              pagination_skip: undefined,
              search_by_name: undefined,
            }),
      fetchGrantSearchSizeWithSpecificFunder,
      swrOption,
    );
  const {
    data: grantSpecificFunder,
    isLoading: isGrantSpecificFunderLoading,
    error: errorGrantSpecificFunder,
  } = useCancellableSWR<any[]>(
    pause ||
      !query ||
      !type.includes("specific_funder_grant") ||
      isHiddenGrantsLoading ||
      isHiddenVirtualGrantsLoading
      ? null
      : "gss_grant_sf" +
          JSON.stringify({
            ...query,
            ...queryMaskForCurrentGrantSearchWithSpecificFunder,
            search_by_name: undefined,
          }),
    fetchGrantSearchWithSpecificFunder,
    swrOption,
  );
  const {
    data: npo,
    isLoading: isNPOLoading,
    error: errorNPO,
  } = useCancellableSWR<SearchResponse>(
    pause || !query || (!type.includes("npo") && !type.includes("by_name")) || isHiddenNPOsLoading
      ? null
      : "gss_npo" +
          JSON.stringify({
            ...query,
            ...queryMaskForNPOSearch,
            ...(type.includes("by_name") ? queryMaskForNameSearch : {}),
            search_by_name: type.includes("by_name") || undefined,
          }),
    fetchNPOSearch,
    swrOption,
  );
  const {
    data: npoSize,
    isLoading: isNPOSizeLoading,
    error: errorNPOSize,
  } = useCancellableSWR<number>(
    pause || !query || !type.includes("by_name") || isHiddenNPOsLoading
      ? null
      : "gss_npo_size" +
          JSON.stringify({
            ...query,
            ...queryMaskForNPOSearch,
            search_by_name: type.includes("by_name") || undefined,
            pagination_limit: 20,
            pagination_skip: 0,
          }),
    fetchNPOSearchSize,
    swrOption,
  );
  const {
    data: npoName,
    isLoading: isNpoNameLoading,
    error: errorNpoName,
  } = useCancellableSWR<SearchResponse>(
    pause || !query || !query.text_query.trim() || !type.includes("npo") || isHiddenNPOsLoading
      ? null
      : "gss_npo" +
          JSON.stringify({
            ...query,
            ...queryMaskForNameSearch,
            search_by_name: true,
            match_exact: true,
            pagination_limit: 3,
            pagination_skip: 0,
          }),
    fetchNPOSearch,
    swrOption,
  );
  const {
    data: npoNameWithinQuery,
    isLoading: isNPONameWithinQueryLoading,
    error: errorNPONameWithinQuery,
  } = useCancellableSWR<number[]>(
    withoutLogin || pause || !query || !type.includes("npo") || isNpoNameLoading || !npoName
      ? null
      : "gss_npo_name_hidden" +
          JSON.stringify({
            ...query,
            ...queryMaskForNPOSearch,
            hidden_uids: npoName.items.map((i) => i.uid),
            pagination_limit: 3,
            pagination_skip: 0,
          }),
    fetchNPOSearchHidden,
    swrOption,
  );

  const {
    data: eliglbleNPO,
    isLoading: isEligibleNPOLoading,
    error: errorEligibleNPO,
  } = useCancellableSWR<SearchResponse>(
    pause || !query || !type.includes("eligible_npo")
      ? null
      : "gss_enpo" +
          JSON.stringify({
            ...query,
            ...queryMaskForEligibleNPOSearch,
            search_by_name: false,
          }),
    fetchEligibleNPOSearch,
    swrOption,
  );
  const { isPastGrantOverviewLoading, pastGrantOverview, pastGrant, errorPastGrant } =
    usePastGrantOverview(
      pause ||
        !type.includes("past_grant") ||
        isHiddenPastGrantsLoading ||
        isFunderSearchResultLoading ||
        !funderSearchResult
        ? undefined
        : funderSearchResult.uid,
      query,
      hiddenPastGrants,
    );
  const [total, setTotal] = useState<ITotal>({
    loading: true,
    items: [],
    case: `default ${type} ${grant?.items?.length}`,
  });
  useEffect(() => {
    setTotal((prev) => ({
      loading: true,
      items: prev.items,
      case: `start sorting`,
    }));
    const t = [
      (type.includes("funder") || type.includes("by_name")) && funder?.items ? funder.items : [],
      (type.includes("grant") || type.includes("virtual_grant") || type.includes("by_name")) &&
      grant?.items
        ? grant.items
        : [],
      (type.includes("npo") || type.includes("by_name")) && npo?.items ? npo.items : [],
      type.includes("past_grant") && pastGrant ? pastGrant : [],
      type.includes("specific_funder_grant") && grantSpecificFunder ? grantSpecificFunder : [],
      type.includes("eligible_npo") && eliglbleNPO?.items ? eliglbleNPO.items : [],
    ]
      .reduce(
        (prev, cur) => [
          ...prev,
          ...cur.map(
            (i) =>
              ({
                ...i,
                ...prettierTaxonomy(
                  filterUnmatched(
                    matchTaxonomyBySearchQuery(i, query, i.service_specific_loc, true),
                  ),
                  query,
                ),
              } as UnifiedSearchResult),
          ),
        ],
        [] as UnifiedSearchResult[],
      )
      .sort((a, b) =>
        !type.includes("by_name") && query?.sortby && query.sortby === "az"
          ? a.name.localeCompare(b.name)
          : query?.sortby && query.sortby === "deadline_soonest"
          ? a.rank_conf - b.rank_conf
          : type.includes("by_name") && query?.sortby !== "az"
          ? sortSearchResult(a, b, query?.text_query || "")
          : b.rank_conf - a.rank_conf,
      );
    setTotal({ loading: false, items: t, case: "finished sorting" });
    if (process.env.REACT_APP_ENV === "DEV") {
      console.log("type", type);
      console.log("total", t);
    }
  }, [funder, grant, npo, pastGrant, grantSpecificFunder, eliglbleNPO]);
  useEffect(() => {
    const t = [
      type.includes("funder") && funderName ? funderName.items.slice(0, 3) : [],
      (type.includes("grant") || type.includes("virtual_grant")) && grantName
        ? grantName.items.slice(0, 3)
        : [],
      type.includes("npo") && npoName ? npoName.items.slice(0, 3) : [],
    ].reduce(
      (prev, cur) => [
        ...prev,
        ...cur.map(
          (i: any) =>
            ({
              ...i,
              ...prettierTaxonomy(
                filterUnmatched(matchTaxonomyBySearchQuery(i, query, i.service_specific_loc, true)),
                query,
              ),
            } as UnifiedSearchResult),
        ),
      ],
      [] as UnifiedSearchResult[],
    );
    setDataByName(t);
  }, [query, type, funderName, grantName, npoName, setDataByName]);
  useEffect(() => {
    const t = [
      (type.includes("funder") || type.includes("by_name")) && funder ? funder.len_total : 0,
      (type.includes("funder") || type.includes("by_name")) && funder
        ? (funderName?.items.length || 0) - (funderNameWithinQuery?.length || 0)
        : 0,
      type.includes("by_name") && funderSize ? funderSize : 0,
      (type.includes("grant") || type.includes("virtual_grant") || type.includes("by_name")) &&
      grant
        ? grant.len_total
        : 0,
      (type.includes("grant") || type.includes("virtual_grant") || type.includes("by_name")) &&
      grant
        ? (grantName?.items.length || 0) - (grantNameWithinQuery?.length || 0)
        : 0,
      type.includes("by_name") && grantSize ? grantSize : 0,
      (type.includes("npo") || type.includes("by_name")) && npo ? npo.len_total : 0,
      (type.includes("npo") || type.includes("by_name")) && npo
        ? (npoName?.items.length || 0) - (npoNameWithinQuery?.length || 0)
        : 0,
      type.includes("by_name") && npoSize ? npoSize : 0,
      type.includes("past_grant") && pastGrantOverview ? pastGrantOverview.len_total : 0,
      type.includes("specific_funder_grant") && grantSpecificFunderSize
        ? grantSpecificFunderSize
        : 0,
      type.includes("eligible_npo") && eliglbleNPO ? eliglbleNPO.len_total : 0,
    ];
    setCount(t.reduce((prev, cur) => prev + cur, 0));
    setMaxCount(t.reduce((prev, cur) => Math.max(prev, cur), 0));
    setSidekickSearchCount(t.reduce((prev, cur) => prev + cur, 0));
  }, [
    type,
    funderNameWithinQuery,
    funderName,
    funder,
    grantNameWithinQuery,
    grantName,
    grant,
    npo,
    npoNameWithinQuery,
    npoName,
    grantSpecificFunderSize,
    pastGrantOverview,
    funderSize,
    grantSize,
    npoSize,
    eliglbleNPO,
    setCount,
    setMaxCount,
  ]);
  useEffect(() => {
    if (
      isFunderNameLoading ||
      isFunderLoading ||
      isGrantNameLoading ||
      isGrantLoading ||
      isNpoNameLoading ||
      isNPOLoading ||
      isGrantSpecificFunderLoading ||
      isFunderSearchResultLoading ||
      isEligibleNPOLoading
    )
      setDelayedLoading(true);
    else {
      setTimeout(() => setDelayedLoading(false), 1000);
    }
  }, [
    isFunderNameLoading,
    isFunderLoading,
    isGrantNameLoading,
    isGrantLoading,
    isNpoNameLoading,
    isNPOLoading,
    isGrantSpecificFunderLoading,
    isFunderSearchResultLoading,
    isEligibleNPOLoading,
    setDelayedLoading,
  ]);
  const isHiddenLoading =
    isHiddenFundersLoading ||
    isHiddenGrantsLoading ||
    isHiddenVirtualGrantsLoading ||
    isHiddenNPOsLoading ||
    isHiddenPastGrantsLoading;
  const countLoading =
    isFunderSizeLoading ||
    isGrantSizeLoading ||
    isNPOSizeLoading ||
    isFunderLoading ||
    isGrantLoading ||
    isNPOLoading ||
    isFunderNameLoading ||
    isFunderNameWithinQueryLoading ||
    isGrantNameLoading ||
    isGrantNameWithinQueryLoading ||
    isNpoNameLoading ||
    isNPONameWithinQueryLoading ||
    isPastGrantOverviewLoading ||
    isGrantSpecificFunderSizeLoading ||
    isEligibleNPOLoading;
  useEffect(() => {
    setSearchLoading(
      total.loading ||
        (total.items.length === 0 && (delayedLoading || isHiddenLoading)) ||
        isFunderNameLoading ||
        isFunderLoading ||
        isGrantNameLoading ||
        isGrantLoading ||
        isNpoNameLoading ||
        isNPOLoading ||
        isGrantSpecificFunderLoading ||
        isEligibleNPOLoading ||
        isFunderSearchResultLoading ||
        isPastGrantOverviewLoading ||
        isHiddenFundersLoading ||
        isHiddenGrantsLoading ||
        isHiddenNPOsLoading ||
        isHiddenPastGrantsLoading,
    );
  }, [
    total,
    delayedLoading,
    isHiddenLoading,
    isFunderNameLoading,
    isFunderLoading,
    isGrantNameLoading,
    isGrantLoading,
    isNpoNameLoading,
    isNPOLoading,
    isGrantSpecificFunderLoading,
    isFunderSearchResultLoading,
    isPastGrantOverviewLoading,
    isEligibleNPOLoading,
  ]);
  useEffect(() => {
    if (
      query?.sortby === "relevance" ||
      query?.sortby === "az" ||
      query?.sortby === "amount" ||
      query?.sortby === "deadline_latest" ||
      query?.sortby === "deadline_soonest"
    )
      setSidekickSearchEstimatedLoadingTime(3);
    else if (
      query?.sortby === "grant_in_matched_area_perc" ||
      query?.sortby === "total_matched_grant_amount" ||
      query?.sortby === "total_matched_grantee_number"
    )
      setSidekickSearchEstimatedLoadingTime(11);
    else setSidekickSearchEstimatedLoadingTime(8);
  }, [query]);
  // console.log({ pause, query, type, ...total });
  return {
    session,
    query,
    count: count,
    data: total.items,
    dataByName: dataByName,
    maxCountForPage: maxCount,
    pastGrantOverview: pastGrantOverview,
    isLoading: searchLoading || total.loading,
    countLoading,
    isError:
      [
        errorFunder,
        errorGrant,
        errorNPO,
        errorPastGrant,
        errorGrantSpecificFunder,
        errorEligibleNPO,
      ]
        .map(
          (e, i) =>
            e &&
            (!e.code || e.code !== "ERR_CANCELED") &&
            [
              type.includes("funder") || type.includes("by_name"),
              type.includes("grant") || type.includes("virtual_grant") || type.includes("by_name"),
              type.includes("npo") || type.includes("by_name"),
              type.includes("past_grant"),
              type.includes("specific_funder_grant"),
              type.includes("eligible_npo"),
            ][i],
        )
        .filter(Boolean).length > 0 && total.items.length === 0,
  };
};
export default useGibooStructureSearch;
