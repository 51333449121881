import { useNavigate } from "react-router-dom";
import PopupModal from "./tailwind/PopupModal";
import useOrgID from "../hooks/useOrgID";
import { useCallback, useEffect, useState } from "react";
import useMySavedList, { createSavedList } from "../hooks/save/useMySavedList";
import Button from "./tailwind/Button";
import classNames from "classnames";
import { scrollBarClass } from "../utils/classes";
import Spinner from "./Spinner";
import LeftInnerIconInput from "./LeftInnerIconInput";
import LongTextInput from "./LongTextInput";
import PlainButton from "./PlainButton";
import useSave from "../hooks/save/useSave";
import { SaveList } from "./SaveList";
import { Philanthropy } from "../types/philanthropy";
import { SearchQuery } from "../types/search";
import GibooToast from "./GibooToast";
import { zIndexSave } from "../types/zIndex";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
const SavePopup = ({
  wrapperClass,
  forceCreateMode = false,
  open,
  target_id,
  target_type,
  onCreate,
  onSave,
  onClose,
  searchQuery,
  compact,
  isVirtualGrant,
  ...props
}: {
  wrapperClass?: string;
  forceCreateMode?: boolean;
  open: boolean;
  onSave?: (save_list_id: string) => void;
  onCreate?: () => void;
  onClose?: () => void;
  target_id: string;
  target_type: 0 | 1 | 3;
  isVirtualGrant?: boolean;
  searchQuery?: SearchQuery;
  compact?: boolean;
}) => {
  const mxEvent = useGibooMixpanel();
  const [createSaveListMode, setCreateSaveListMode] = useState<boolean>(false);
  const org_id = useOrgID();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const { data, isLoading, isValidating, revalidate } = useMySavedList();

  const navigate = useNavigate();
  const {
    data: currentSavedList,
    isLoading: isLoadingSaved,
    save,
  } = useSave(target_id, target_type, searchQuery);
  const [selectedListIds, setSelectedListIds] = useState<string[]>([]);
  useEffect(() => {
    if (open) {
      setSelectedListIds(currentSavedList);
    }
  }, [open, currentSavedList]);
  useEffect(() => {
    if (!isLoading && !isValidating && data.length === 0) setCreateSaveListMode(true);
  }, [data, isLoading, isValidating]);
  const validate = useCallback(() => {
    if (!name) {
      setDirty(true);
      return false;
    }
    return true;
  }, [name, setDirty]);
  const handleSelectList = useCallback((save_list_id: string) => {
    setSelectedListIds((prevSelectedListIds) => {
      if (prevSelectedListIds.includes(save_list_id)) {
        return prevSelectedListIds.filter((id) => id !== save_list_id);
      } else {
        return [...prevSelectedListIds, save_list_id];
      }
    });
  }, []);

  const handleSave = useCallback(
    (save_list_id: string) => {
      const saved = currentSavedList.includes(save_list_id);
      save(save_list_id, !saved)
        .then(() => {
          !saved &&
            GibooToast({
              type: "success",
              message: `Successfully added to a saved list`,
              actionLabel: "View saved list",
              handleAction: () => {
                navigate(`/saved`);
              },
            });
        })
        .catch((e) => {
          // console.log(e);
          GibooToast({
            type: "failure",
            message: `We apologize for the inconvenience. Please try again.`,
          });
        });
    },
    [currentSavedList, save, isLoadingSaved],
  );
  const handleDone = useCallback(() => {
    // Iterate over all saved lists
    data.forEach(({ _id }) => {
      const isCurrentlySaved = currentSavedList.includes(_id);
      const isSelected = selectedListIds.includes(_id);

      if (isSelected && !isCurrentlySaved) {
        // If selected but not currently saved, add to the list
        handleSave(_id);
        mxEvent(MIXPANEL_EVENTS_V2.save.saved_item.added, {
          targetId: target_id,
          targetType:
            target_type === 0
              ? "funder"
              : target_type === 1
              ? isVirtualGrant
                ? "virtual_grant"
                : "grant"
              : "npo",
          textQuery: searchQuery?.text_query,
        });
      } else if (!isSelected && isCurrentlySaved) {
        // If not selected but currently saved, remove from the list
        handleSave(_id);
        mxEvent(MIXPANEL_EVENTS_V2.save.saved_item.removed, {
          targetId: target_id,
          targetType:
            target_type === 0
              ? "funder"
              : target_type === 1
              ? isVirtualGrant
                ? "virtual_grant"
                : "grant"
              : "npo",
          textQuery: searchQuery?.text_query,
        });
      }
    });

    onClose?.();
    setSelectedListIds([]); // Reset selected list IDs
  }, [
    selectedListIds,
    currentSavedList,
    handleSave,
    onClose,
    data,
    mxEvent,
    isVirtualGrant,
    searchQuery,
    target_id,
    target_type,
  ]);

  return (
    <>
      {open ? (
        <PopupModal
          isOpen={open}
          handleClose={() => {
            onClose?.();
          }}
          zIndex={zIndexSave}
          wrapperClass="!min-w-[600px] !max-w-[600px] md:!px-0"
          showActionButtons={true}
          {...(createSaveListMode || forceCreateMode
            ? {
                title: "Create a new saved list",
                LeftActionButton: () => (
                  <PlainButton
                    id="btn-cancel"
                    className="!m-0 !p-0 !text-sm"
                    handleOnClick={() => setCreateSaveListMode(false)}
                    label="Cancel"
                  />
                ),
                RightActionButton: () => (
                  <Button
                    size="md"
                    id="btn-new-save-list-confirm"
                    loading={createLoading}
                    disabled={createLoading}
                    handleOnClick={() => {
                      if (createLoading) return;
                      if (validate()) {
                        setCreateLoading(true);
                        createSavedList({ org_id, name, description })
                          .then((res) => {
                            if (res) {
                              return revalidate().finally(() => {
                                onCreate?.();
                                setCreateSaveListMode(false);
                                setName("");
                                setDescription("");
                              });
                            }
                          })
                          .catch((error) => {
                            GibooToast({
                              type: "failure",
                              message: `We apologize for the inconvenience. Please try again.`,
                            });
                          })
                          .finally(() => setCreateLoading(false));
                      }
                    }}
                    label={"Create"}
                  />
                ),
              }
            : {
                title: "Add to a saved list",
                LeftActionButton: () => (
                  <Button
                    outline
                    size="md"
                    id="btn-new-save-list"
                    handleOnClick={() => {
                      setCreateSaveListMode(true);
                    }}
                    label={"Create a new list"}
                  />
                ),
                RightActionButton: () => (
                  <Button
                    size="md"
                    id="btn-done"
                    label="Done"
                    handleOnClick={() => {
                      handleDone();
                      // onClose?.();
                    }}
                  />
                ),
              })}
        >
          {createSaveListMode || forceCreateMode ? (
            <div className={classNames("flex h-[300px] w-full flex-col gap-7 p-6")}>
              <div className="flex flex-col gap-2">
                <h5 className="font-semibold">Name</h5>
                <LeftInnerIconInput
                  id="input-name"
                  handleOnChange={(e) => setName(e.target.value)}
                  defaultValue=""
                  placeholder="Enter name"
                />
                {dirty && !name && (
                  <h6 className="font-semibold text-purple-500">Name required.</h6>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <h5 className="font-semibold">Description (Optional)</h5>
                <LongTextInput
                  id="input-desc"
                  height="120px"
                  handleOnChange={(e) => setDescription(e.target.value)}
                  defaultValue=""
                  placeholder="Enter description"
                />
              </div>
            </div>
          ) : (
            <div className="p-6">
              {isLoading ? (
                <div className="flex w-full items-center justify-center">
                  <Spinner size="lg" />
                </div>
              ) : (
                <div
                  className={classNames(
                    "flex max-h-[300px] min-h-[300px] w-full flex-col gap-3",
                    scrollBarClass,
                  )}
                >
                  {data.map((d) => (
                    <SaveList
                      key={d._id}
                      saveList={d}
                      onClick={() => handleSelectList(d._id)}
                      onClosePopup={onClose}
                      // query={searchQuery}
                      saved={selectedListIds.includes(d._id)}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </PopupModal>
      ) : null}
    </>
  );
};

export default SavePopup;
