import classNames from "classnames";
import React, { useState } from "react";

export interface PlainTextInputProps {
  id: string;
  className?: string;
  type?: "text" | "password" | "number";
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleOnBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleOnKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  readonly?: boolean;
  placeholder?: string;
  value?: string | number;
  invalid?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  errorMessage?: string;
  focusClass?: string;
}

const PlainTextInput = React.forwardRef(function textinput(
  props: PlainTextInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div className={classNames("relative", props.className)}>
      <div
        className={classNames(
          "flex items-center rounded-[4px] border-none",
          isFocused && props.focusClass,
        )}
      >
        {props.iconLeft && (
          <div className="absolute left-0 flex h-full items-center px-3">{props.iconLeft}</div>
        )}
        <input
          ref={ref}
          id={props.id}
          value={props.value}
          placeholder={props.placeholder}
          autoComplete="off"
          type={props.type}
          className={classNames(
            "flex font-poppins w-full bg-inherit appearance-none rounded-[4px] border-0 py-0 px-2 leading-tight text-gray-700 placeholder-gray-500 placeholder:font-poppins placeholder:text-sm placeholder:font-normal focus:ring-0 focus:ring-gray-900 ",
            {
              "pr-10": props.iconRight,
              "pl-10": props.iconLeft,
            },
            props.className,
          )}
          onFocus={(e) => {
            setIsFocused(true);
            props.handleOnFocus && props.handleOnFocus(e);
          }}
          onBlur={(e) => {
            setIsFocused(false);
            props.handleOnBlur && props.handleOnBlur(e);
          }}
          onChange={props.handleOnChange}
          onKeyDown={props.handleOnKeyDown}
          disabled={props.disabled}
          readOnly={props.readonly}
          autoFocus={props.autoFocus}
        />
        {props.iconRight && (
          <div className="absolute right-0 flex h-full cursor-pointer items-center px-3">
            {props.iconRight}
          </div>
        )}
      </div>
      {props.errorMessage && props.invalid ? (
        <div className="font-sm pt-2 font-poppins text-base text-purple-500">
          {props.errorMessage}
        </div>
      ) : null}
    </div>
  );
});

PlainTextInput.defaultProps = {
  type: "text",
};
export default PlainTextInput;
