import { useCallback } from "react";
import { IProjectInteractionWithProject } from "../../types/project";
import useSWR, { useSWRConfig } from "swr";
import axios from "axios";
import config from "../../api";
import { swrOptionFetchOnlyOnMount } from "../../types/swr";
export interface RecommendedTarget {
  name: string;
  target_id: string;
  target_type: number;
}
interface RecommendedTargetsState {
  data: RecommendedTarget[];
  error: any;
  isLoading: boolean;
  isValidating: boolean;
  revalidate: () => Promise<RecommendedTarget[] | undefined>;
}
const emptyList: RecommendedTarget[] = [];
function useRecommendedTargets(org_id?: string): RecommendedTargetsState {
  const url = process.env.REACT_APP_API_URL + `/api/v2/orgs/${org_id}/recommend_target`;
  const { mutate } = useSWRConfig();
  const fetch = async (_url: string) => {
    if (!_url) return new Promise<RecommendedTarget[]>((resolve, reject) => reject());
    return axios.get(_url, config).then((res) => res.data as RecommendedTarget[]);
  };
  const { data, isLoading, error, isValidating } = useSWR<RecommendedTarget[]>(
    org_id ? url : null,
    fetch,
    swrOptionFetchOnlyOnMount,
  );
  const revalidate = useCallback(() => mutate<RecommendedTarget[]>(url), [url]);
  return {
    data: data || emptyList,
    isLoading,
    error,
    isValidating,
    revalidate,
  };
}
export default useRecommendedTargets;
