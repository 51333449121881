import { CredentialResponse, useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import axios from "axios";
import { createRef, FormEvent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import config from "../api";
import { signupUserState } from "../app/recoilStore";
import { User } from "../app/userSlice";
import LeftInnerIconInput from "../components/LeftInnerIconInput";
import PasswordInput from "../components/PasswordInput";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import useGibooMixpanel from "../hooks/useGibooMixpanel";
import usePageTitle from "../hooks/usePagetitle";
import useUser from "../hooks/useUser";
import { MIXPANEL_EVENTS_V2 } from "../mixpanel/mixpanel";
import parseError from "./errorParser";

function SigninView() {
  usePageTitle("Giboo: Log In or Sign Up", undefined, true);
  const mxEvent = useGibooMixpanel("signin");
  const [params] = useSearchParams();
  const [user, setUser] = useUser();
  const setSignUpUser = useSetRecoilState(signupUserState);
  const [email, setEmail] = useState<string>(params.get("email") || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGoogleSignin, setLoadingGoogleSignin] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [invalid, setInvalid] = useState<boolean>(false);
  const [err, setErr] = useState();
  const navigate = useNavigate();
  const refbtn = createRef<HTMLButtonElement>();
  const onSuccess = (user: User) => {
    // console.log("ok", user);
    if (process.env.REACT_APP_USE_INVITATION_CODE === "TRUE") {
      if (user.created || !user.pass_invitation_code) {
        // FIXME For Beta
        if (process.env.REACT_APP_DISABLE_GOOGLE_SIGNUP === "TRUE") return;
        setSignUpUser(user);
        navigate("/invitation?context=google");
      } else {
        // FIXME For Production
        setUser(user);
        navigate("/", { replace: true });
      }
    } else {
      setUser(user);
      navigate("/", { replace: true });
    }
    // For production, you can keep else condition only.
  };

  const loginUser = (credentials: { email: string; password: string }) => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_URL + "/api/v2/auth/login", credentials, config)
      .then((response) => {
        mxEvent(MIXPANEL_EVENTS_V2.auth.signin.successful, {
          sigininMethod: "password",
          email: email,
        });
        return onSuccess(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 404) {
          setErr(parseError("The email or password you entered is incorrect"));
        } else {
          setErr(parseError(error));
        }
        mxEvent(MIXPANEL_EVENTS_V2.auth.signin.failed, {
          sigininMethod: "password",
          email: email,
          error:
            error.response.status === 403 || error.response.status === 404
              ? "wrong email or password"
              : "api error",
        });
        setInvalid(true);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e: FormEvent) => {
    if (loading) return;
    e.preventDefault();
    loginUser({
      email: email.toLowerCase(),
      password,
    });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      if (process.env.REACT_APP_DISABLE_GOOGLE_SIGNUP === "TRUE") return;
      setLoadingGoogleSignin(true);
      // mixpanel
      axios
        .post(
          process.env.REACT_APP_API_URL + "/api/v2/oauth/google/token",
          {
            token: codeResponse.access_token,
            no_invitation: true,
            access_from: document?.referrer,
          },
          config,
        )
        .then((response) => {
          onSuccess({ ...response.data, token: codeResponse.access_token });
          mxEvent(MIXPANEL_EVENTS_V2.auth.signin.successful, {
            sigininMethod: "google",
            email: response.data.email,
          });
        })
        .catch((error) => {
          setErr(parseError(error));
          mxEvent(MIXPANEL_EVENTS_V2.auth.signin.failed, {
            sigininMethod: "google",
            error: "api error",
          });
        })
        .finally(() => setLoadingGoogleSignin(false));
    },
    onError: (error) => {
      // console.log("Login Failed:", error);
      setLoadingGoogleSignin(false);
    },
    flow: "implicit",
  });

  const onSuccessGoogle = (credentialResponse: CredentialResponse) => {
    if (process.env.REACT_APP_DISABLE_GOOGLE_SIGNUP === "TRUE") return;
    setLoading(true);
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/api/v2/oauth/google",
          {
            token: credentialResponse.credential,
            no_invitation: true,
            access_from: document?.referrer,
          },
          config,
        )
        .then((response) => {
          mxEvent(
            response.data?.created
              ? MIXPANEL_EVENTS_V2.auth.signup.successful
              : MIXPANEL_EVENTS_V2.auth.signin.successful,
            {
              sigininMethod: "google_onetab",
              email: response.data?.email,
            },
          );
          onSuccess({ ...response.data, token: credentialResponse.credential });
        })
        .catch((error) => {
          mxEvent(MIXPANEL_EVENTS_V2.auth.signin.failed, {
            sigininMethod: "google_onetab",
            error: "api error",
          });
          setErr(parseError(error));
        })
        .finally(() => setLoading(false));
    } catch (error) {
      setErr(parseError(error));
    }
  };

  const onFail = () => {
    setErr(parseError("Sorry"));
  };

  // FIXME
  // Do we need this?
  if (
    process.env.REACT_APP_DISABLE_GOOGLE_SIGNUP !== "TRUE" &&
    process.env.REACT_APP_ROUTER !== "FUNDER"
  )
    useGoogleOneTapLogin({ onSuccess: onSuccessGoogle, onError: onFail });
  const renderLeft = () => {
    return (
      <div className="py-1">
        <h3>Log in</h3>
        <p className="subtitle mt-[18px] text-base">Log in to your fundraising space</p>
        <form className="mt-[40px] flex flex-wrap gap-y-3">
          <div className="w-full">
            <LeftInnerIconInput
              id="input-email"
              className="!h-[48px]"
              placeholder="Enter your registered email"
              defaultValue={email}
              handleOnChange={(e) => {
                setEmail(e.target.value);
                setInvalid(false);
              }}
              handleOnKeyDown={(e) => {
                if (e.key === "Enter") {
                  refbtn.current?.focus();
                }
              }}
              invalid={invalid}
            />
          </div>
          <div className="w-full">
            <PasswordInput
              id="input-password"
              className="!h-[48px]"
              placeholder="Enter your password*"
              handleOnChange={(e) => {
                setPassword(e.target.value);
                setInvalid(false);
              }}
              handleOnKeyDown={(e) => {
                if (e.key === "Enter") {
                  refbtn.current?.focus();
                }
              }}
              invalid={invalid}
            />
          </div>
        </form>
        {process.env.REACT_APP_DISABLE_SIGNUP !== "TRUE" && (
          <a
            id="btn-reset"
            href="/forgetpassword"
            style={{
              color: "#5F5F5F",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "48px",
            }}
          >
            Forgot your password?
          </a>
        )}
        <div className="flex justify-start">
          <h5 className="text-purple-500">{err}</h5>
        </div>
        <div className="flex justify-center pt-3">
          <PrimaryButton
            id="btn-next"
            ref={refbtn}
            size="lg"
            handleOnClick={handleSubmit}
            loading={loading}
            label="Log in"
            style={{ width: "100%" }}
            disabled={email === "" || password === ""}
          />
        </div>
        {(process.env.REACT_APP_DISABLE_SIGNUP !== "TRUE" ||
          process.env.REACT_APP_DISABLE_GOOGLE_SIGNUP !== "TRUE") && (
          <>
            <div className="relative my-[28px]">
              <div className=" relative h-[1px] w-full bg-gray-600" />
              <div className="absolute text-center" style={{ marginTop: -15, left: "48%" }}>
                <span className="subtitle bg-white px-2">or</span>
              </div>
            </div>
            <div className="flex justify-center">
              <SecondaryButton
                id="btn-google"
                className="!text-gray-900"
                size="lg"
                style={{ width: "100%" }}
                handleOnClick={() => {
                  handleGoogleLogin();
                }}
                spinnerColor="purple"
                loading={loadingGoogleSignin}
                leftIconClass={
                  loadingGoogleSignin
                    ? ""
                    : `fa-brands fa-google me-4 fa-lg mt-1 ${loading ? "gray" : "purple"}`
                }
                label="Log in with Google"
                disabled={loading}
              />
            </div>
            {process.env.REACT_APP_DISABLE_SIGNUP !== "TRUE" && (
              <div className="mt-7 flex justify-center !text-purple-900">
                <p className=" text-center font-poppins text-base ">
                  Don&apos;t have an account?{" "}
                  <a href="/signup" className="!font-light !text-purple-900 !underline">
                    Sign up
                  </a>
                </p>
              </div>
            )}
          </>
        )}
      </div>
    );
  };
  const renderRight = () => {
    return (
      <div className="px-[49px] py-[37px]">
        <div className="flex h-fit w-full justify-end ">
          <img alt="signup" src="/signup-image.svg" className=" h-[250px] w-fit" />
        </div>
        <div className="mt-[26px] grid place-items-center">
          <div className="min-h-[348px] w-full">
            <h4 className="font-poppins text-xl">
              Giboo transforms your philanthropic journey with AI-powered automation and unwavering
              support.
            </h4>
            <div className="mt-6 flex flex-col gap-3 ">
              <div className=" flex min-h-[72px] w-full gap-[10px] rounded border border-gray-200 bg-white px-[16px] py-[12px]">
                <img alt="icon1" src="/icon1.svg" className="h-[24px] w-[24px] " />
                <p className="font-poppins text-base text-gray-800">
                  Discover personalized funder-nonprofit matching driven by data analytics and
                  machine learning.
                </p>
              </div>
              <div className=" flex min-h-[72px] w-full gap-[10px] rounded border border-gray-200 bg-white px-[16px] py-[12px]">
                <img alt="icon1" src="/icon2.svg" className="h-[24px] w-[24px] " />
                <p className="font-poppins text-base text-gray-800">
                  Gain profound insights into funding patterns, finances, programs and contacts.
                </p>
              </div>
              <div className=" flex min-h-[72px] w-full gap-[10px] rounded border border-gray-200 bg-white px-[16px] py-[12px]">
                <img alt="icon1" src="/icon3.svg" className="h-[24px] w-[24px] " />
                <p className="font-poppins text-base text-gray-800">
                  Effortlessly craft compelling letters of inquiry and grant applications, backed by
                  data analytics for needs analysis and impact measurements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="container mx-auto">
        <div className="my-[48px] flex min-h-fit w-full flex-col justify-center gap-[12px] px-5 md:flex-row md:gap-[113px] md:px-0">
          <div className=" min-h-[525px] w-full md:w-[480px]">{renderLeft()}</div>
          <div className="gray-box !m-0 hidden min-h-[700px] w-full rounded-lg !p-0 md:block md:w-[606px]">
            {renderRight()}
          </div>
        </div>
      </div>
    </>
  );
}

export default SigninView;
