import useFunderContact from "../../hooks/funder/useFunderContact";
import useFunderSearchResult from "../../hooks/search/useFunderSearchResult";
import GrantContactOld from "../../pages/npo/grant/GrantContactOld";
export default function FunderContact({
  funder_id,
  compact,
  withoutLogin,
  ...props
}: {
  funder_id: string;
  wrapperClass?: string;
  compact?: boolean;
  withoutLogin?: boolean;
}) {
  const { data: contact } = useFunderContact(funder_id);
  const { data: funder } = useFunderSearchResult(funder_id);
  return (
    <GrantContactOld
      target_name={funder?.name}
      target_id={funder_id}
      target_type={0}
      contacts={contact?.contacts}
      wrapperClass="!p-5 !mb-0 !-mt-4 max-h-[463px] h-[463px]"
      address={funder?.address}
      phone={funder?.phone}
      compact={compact}
      forSearch
      withoutLogin={withoutLogin}
    />
  );
}
