import React, { ReactNode, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Dot,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import classNames from "classnames";
import { toUsdLong, toUsdShort } from "../../../../utils/formatHelper";
import useNPOIRSProfile, { AssetsTransposed, Revenue } from "../../../../hooks/useNPOIRSProfile";
import NoGraph from "../../../donor-detail-view/components/NoGraph";
import IMG_RECOM from "../../../../assets/financial/recommendation.svg";
import { Divider } from "rsuite";
import Spacer from "../../../../components/Spacer";
import FinancialChartLoader from "../../financials/component/FinancialChartLoader";
import { FAKE_LOADING_TIME } from "../../financials/FinancialDetail";
import useFinancialRecommendedActions from "../../../../hooks/npo/useFinancialRecommendedActions";
import MemoizedLoadingRecommendedAction from "../../../../components/LoadingRecommendedAction";
import IncreaseTag from "../../financials/component/IncreaseTag";
import DecreaseTag from "../../financials/component/DecreaseTag";
import SmallestProportionTag from "../../financials/component/SmallestProportionTag";
import LargestProportionTag from "../../financials/component/LargestProportionTag";
import PercentageTag from "../../../../components/PercentageTag";
import AssessmentResult from "../../../../components/AssessmentResult";
import PopupModal from "../../../../components/tailwind/PopupModal";
import { FinancialAssetsCategoriesInfo } from "../../financials/pages/FinancialAssetsCategories";
import ICON_PREDICT from "../../../../assets/financial/preditction.svg";
import ICON_ESTIMATE from "../../../../assets/financial/estimate.svg";
import { NpoTitle } from "./NpoDiversityEthnicity";
import FinancialPredictionInfo from "./FinancialPredictionInfo";
import ContentHideWrapper from "../../../../components/ContentHideWrapper";
interface Iprops {
  npo_id?: string;
  org_id?: string;
  allowEdit?: boolean;
  isPrivateView?: boolean;
  compact?: boolean;
  recomendation?: boolean;
  showStats?: boolean;
  showInfo?: boolean;
  hideTitle?: boolean;
  children?: ReactNode;
  showChartLoader?: boolean;
  withoutLogin?: boolean;
  isProfileClaim?: boolean;
}
const COLOR_MAP = ["#E99762", "#F4D4A8", "#90CDB7", "#298085"];
const LABEL_MAP = ["Cash", "Receivable", "Investment", "Property & equipment"];
const InfoTooltip = (props: any) => {
  const renderTitle = (value: string) => {
    return <span className="font-poppins font-normal text-gray-700">{value}</span>;
  };
  const renderValue = (value: string) => {
    return <span className="font-poppins font-semibold text-gray-900">{value}</span>;
  };
  if (props.active && props.payload && props.payload.length) {
    return (
      <div className="giboo-box flex flex-col gap-1">
        <p className="font-poppins font-semibold text-gray-900">
          {props.payload[0].payload.year}
          {props.payload[0].payload.is_predicted
            ? ` (Predicted)`
            : props.payload[0].payload.is_interpolated
            ? ` (Estimated)`
            : ""}
        </p>
        <div className="inline-flex gap-2 !text-sm capitalize">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#E99762",
            }}
          />
          {renderTitle("Cash:")}
          {renderValue(toUsdLong(props.payload[0].payload?.cash || 0))}
        </div>
        <div className="inline-flex gap-2 !text-sm ">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#F4D4A8",
            }}
          />
          {renderTitle("Receivable:")}
          {renderValue(toUsdLong(props.payload[0].payload?.receivable || 0))}
        </div>
        <div className="inline-flex gap-2 !text-sm ">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#90CDB7",
            }}
          />
          {renderTitle("Investment:")}
          {renderValue(toUsdLong(props.payload[0].payload?.investment || 0))}
        </div>
        <div className="inline-flex gap-2 !text-sm ">
          <span
            className="h-4 w-4"
            style={{
              backgroundColor: "#298085",
            }}
          />
          {renderTitle("Property & equipment:")}
          {renderValue(toUsdLong(props.payload[0].payload?.property || 0))}
        </div>
      </div>
    );
  }
  return null;
};

function NpoFinancialAssetsCategories({
  npo_id,
  org_id,
  allowEdit,
  isPrivateView,
  compact,
  recomendation,
  showInfo = true,
  showStats = true,
  hideTitle = false,
  showChartLoader = true,
  children,
  withoutLogin,
  isProfileClaim,
}: Iprops) {
  const [loading, setLoading] = useState<boolean>(
    process.env.REACT_APP_FAKE_LOADING_FOR_RECOMMENDED_ACTION === "TRUE",
  );
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setLoading(false), FAKE_LOADING_TIME);
  }, []);
  const { assetsTransposed, assetInfo, isLoading, beforeLatest, latest } = useNPOIRSProfile(npo_id);
  const { data: asset, isLoading: isAssetLoading } = useFinancialRecommendedActions(
    !isPrivateView && loading ? undefined : !isPrivateView && !org_id ? npo_id : org_id,
    "asset",
    !isPrivateView && !org_id,
  );

  const maxPortion = assetInfo
    ? [
        assetInfo.asset_cash,
        assetInfo.asset_investment,
        assetInfo.asset_property,
        assetInfo.asset_receivable,
      ].reduce((prev, cur) => Math.max(prev, cur), assetInfo.asset_cash)
    : -1;
  const minPortion = assetInfo
    ? [
        assetInfo.asset_cash,
        assetInfo.asset_investment,
        assetInfo.asset_property,
        assetInfo.asset_receivable,
      ].reduce((prev, cur) => Math.min(prev, cur), assetInfo.asset_cash)
    : -1;
  const renderCash = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Cash</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={assetInfo?.diff_asset_cash}
            beforeLatest={beforeLatest?.asset_cash}
            latest={latest?.asset_cash}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {assetInfo ? (
              maxPortion === assetInfo.asset_cash ? (
                <LargestProportionTag />
              ) : minPortion === assetInfo.asset_cash ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {asset?.Cash?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">{asset?.Cash?.recommendations || ""}</div>
          </div>
        )}
      </div>
    );
  };
  const renderReceivables = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Receivables</p>
        <div className="flex items-center gap-5">
          <div className="flex h-[76px] w-[90px] shrink-0 flex-col  items-center justify-evenly rounded bg-purple-50 font-poppins text-2xl font-semibold text-purple-500">
            <PercentageTag
              percentage={assetInfo?.diff_asset_receivable}
              beforeLatest={beforeLatest?.asset_receivable}
              latest={latest?.asset_receivable}
              beforeLatestYear={beforeLatest?.tax_year}
              latestYear={latest?.tax_year}
            />
          </div>
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {assetInfo ? (
              maxPortion === assetInfo.asset_receivable ? (
                <LargestProportionTag />
              ) : minPortion === assetInfo.asset_receivable ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {asset?.Receivables?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">{asset?.Receivables?.recommendations || ""}</div>
          </div>
        )}
      </div>
    );
  };
  const renderInvestment = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Investment</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={assetInfo?.diff_asset_investment}
            beforeLatest={beforeLatest?.asset_investment}
            latest={latest?.asset_investment}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {assetInfo ? (
              maxPortion === assetInfo.asset_investment ? (
                <LargestProportionTag />
              ) : minPortion === assetInfo.asset_investment ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {asset?.Investments?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">{asset?.Investments?.recommendations || ""}</div>
          </div>
        )}
      </div>
    );
  };
  const renderProperty = () => {
    return (
      <div className="flex flex-col gap-[10px]">
        <p className="font-poppins text-sm font-semibold text-black">Property & equipment</p>
        <div className="flex items-center gap-5">
          <PercentageTag
            percentage={assetInfo?.diff_asset_property}
            beforeLatest={beforeLatest?.asset_property}
            latest={latest?.asset_property}
            beforeLatestYear={beforeLatest?.tax_year}
            latestYear={latest?.tax_year}
          />
          <div className="flex  grow flex-col gap-2 font-poppins text-sm">
            {assetInfo ? (
              maxPortion === assetInfo.asset_property ? (
                <LargestProportionTag />
              ) : minPortion === assetInfo.asset_property ? (
                <SmallestProportionTag />
              ) : null
            ) : null}
            {asset?.Property_and_Equipment?.description || ""}
          </div>
        </div>
        {recomendation && (
          <div className="flex flex-col gap-2 rounded border border-gray-200 bg-white p-4">
            <div className="inline-flex items-center gap-2 text-sm font-semibold text-purple-500 ">
              <span>
                <img src={IMG_RECOM} className="h-fit w-fit" />
              </span>
              <p className="text-sm font-semibold text-purple-500">AI recommendations:</p>
            </div>
            <div className="font-poppins text-sm">
              {asset?.Property_and_Equipment?.recommendations || ""}
            </div>
          </div>
        )}
      </div>
    );
  };
  const chartLoading = recomendation ? loading : isLoading;
  return (
    <>
      <PopupModal
        isOpen={showInfoModal}
        allowBackdropClose
        handleClose={() => {
          setShowInfoModal(false);
        }}
        wrapperClass="!min-w-[760px] max-w-[760px] md:!px-0 "
        contentClass="!p-5"
        showHeader={false}
        showActionButtons={false}
      >
        <FinancialAssetsCategoriesInfo
          handleClose={() => {
            setShowInfoModal(false);
          }}
        />
      </PopupModal>
      <div
        className={classNames(
          "flex min-h-[100px] flex-col ",
          recomendation && "rounded border border-gray-300 bg-white p-5",
        )}
      >
        {!hideTitle && (
          <NpoTitle
            title="Financial assets categories"
            recomendation={recomendation}
            setShowInfoModal={setShowInfoModal}
          />
        )}
        {showChartLoader && chartLoading ? (
          <FinancialChartLoader title="Analyzing organization’s Financial assets categories" />
        ) : (
          <>
            {/* chart */}
            <div className="mx-auto my-5">
              <AssetCategoriesChart
                assetsTransposed={assetsTransposed}
                npo_id={npo_id}
                wrapperClass={""}
                compact
              />
            </div>

            <ContentHideWrapper
              id="financial assets categories"
              open={withoutLogin && isProfileClaim}
              messageTitle="Ensure your nonprofit's success with Giboo's financial assessment tool. "
              messageDescription="Get key insights and strategies to secure sustainable funding—try it now for free!"
            >
              {/* info */}
              {showInfo &&
                (isAssetLoading || loading ? (
                  <MemoizedLoadingRecommendedAction loading={loading || isAssetLoading} short />
                ) : (
                  <AssessmentResult content={asset?.Summary || ""} />
                ))}
              {recomendation ? <Divider className="my-[40px]" /> : <Spacer height="20px" />}
              {/* stats */}
              {showStats &&
                (isAssetLoading || loading ? (
                  <MemoizedLoadingRecommendedAction loading={loading || isAssetLoading} />
                ) : (
                  <div className="bg-gray-50 px-6 py-5">
                    <h6 className="mb-5 font-poppins text-base font-semibold text-black">Stats</h6>
                    <div className="flex flex-col gap-10">
                      {renderCash()}
                      {renderReceivables()}
                      {renderInvestment()}
                      {renderProperty()}
                    </div>
                  </div>
                ))}
            </ContentHideWrapper>
          </>
        )}
      </div>
      {!chartLoading && children && children}
    </>
  );
}
export default NpoFinancialAssetsCategories;

export function AssetCategoriesChart({
  npo_id,
  wrapperClass,
  compact,
  revenueStream,
  assetsTransposed,
}: {
  revenueStream?: Revenue[];
  assetsTransposed?: AssetsTransposed;
  npo_id?: string;
  wrapperClass?: string;
  compact?: boolean;
}) {
  // console.log({ assets, assetsTransposed });
  const transformedData = assetsTransposed?.tax_year.map((year, index) => ({
    year,
    cash: assetsTransposed?.cash[index],
    investment: assetsTransposed?.investment[index],
    receivable: assetsTransposed?.receivable[index],
    property: assetsTransposed?.property[index],
    is_interpolated: assetsTransposed?.is_interpolated[index],
    is_predicted: assetsTransposed?.is_predicted[index],
  }));
  const CustomizedAxisTick = (props: any) => {
    const { x, y, payload, index } = props;
    const isPredicted = transformedData?.[index]?.is_predicted;
    const isEstimated = transformedData?.[index]?.is_interpolated;
    const borderStyle =
      isEstimated || isPredicted
        ? {
            stroke: isPredicted ? "#5C38A7" : "#BFAEE4",
            strokeWidth: 1,
            strokeDasharray: isPredicted ? "2 2" : "0",
            rx: 5,
            ry: 5,
          }
        : {};
    // if (isEstimated || isPredicted) {
    //   return (
    //     <g transform={`translate(${x},${y})`} dy={30}>
    //       <rect x={-20} y={2} width={65} height={22} fill="none" style={borderStyle} />
    //       {isPredicted && (
    //         <image xlinkHref={ICON_PREDICT} x={-15} y={5} height="16px" width="16px" />
    //       )}
    //       {isEstimated && (
    //         <image xlinkHref={ICON_ESTIMATE} x={-15} y={5} height="16px" width="16px" />
    //       )}
    //       <text x={20} y={18} textAnchor="middle" fill={"#5F5F5F"}>
    //         {payload?.value}
    //       </text>
    //     </g>
    //   );
    // }
    return (
      <g transform={`translate(${x},${y})`} dy={30}>
        <text x={0} y={20} textAnchor="middle" fill={"#5F5F5F"}>
          {payload?.value}
        </text>
      </g>
    );
  };
  const renderChart = () => {
    const estimatedData = transformedData?.filter((i) => i.is_interpolated);
    const predictedData = transformedData?.filter((i) => i.is_predicted);
    return (
      <>
        <ResponsiveContainer minHeight="330px" minWidth="50px" width="100%">
          <BarChart data={transformedData} margin={{ left: 25, right: 0, bottom: 10 }}>
            <CartesianGrid
              vertical={false}
              strokeDasharray="4 4"
              stroke={"#DEDEDE"}
              strokeWidth={1}
            />
            <XAxis
              // dx={10}
              dy={5}
              dataKey="year"
              strokeWidth={1}
              stroke="#DEDEDE"
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                whiteSpace: "pre-wrap",
              }}
              tick={<CustomizedAxisTick />}
            />
            <YAxis
              dy={8}
              type="number"
              domain={[0, "dataMax"]}
              tickFormatter={(v, i) => toUsdLong(v, 0)}
              stroke="#DEDEDE"
              tick={{ fill: "#5F5F5F" }}
              strokeWidth={1}
              style={{
                fontFamily: "poppins",
                fontSize: "14px",
                whiteSpace: "pre-wrap",
              }}
            />
            <Tooltip
              content={<InfoTooltip />}
              cursor={{ fill: "transparent" }}
              wrapperStyle={{ outline: "none" }}
            />
            <Bar dataKey="cash" fill="#E99762" minPointSize={2} barSize={9} radius={[10, 10, 0, 0]}>
              {transformedData?.map((d: any, idx: any) => {
                return (
                  <Cell
                    key={d["cash"]}
                    fill="#E99762"
                    stroke={d.is_interpolated ? "#AA93DB" : "#E99762"}
                    fillOpacity={d.is_predicted || d.is_interpolated ? 0.3 : 1}
                    strokeDasharray={d.is_interpolated ? 0 : d.is_predicted ? 3 : 0}
                  />
                );
              })}
            </Bar>
            <Bar
              dataKey="receivable"
              fill="#F4D4A8"
              minPointSize={2}
              barSize={9}
              radius={[10, 10, 0, 0]}
            >
              {transformedData?.map((d: any, idx: any) => {
                return (
                  <Cell
                    key={d["receivable"]}
                    fill="#F4D4A8"
                    stroke={d.is_interpolated ? "#AA93DB" : "#F4D4A8"}
                    fillOpacity={d.is_predicted || d.is_interpolated ? 0.3 : 1}
                    strokeDasharray={d.is_interpolated ? 0 : d.is_predicted ? 3 : 0}
                  />
                );
              })}
            </Bar>
            <Bar
              dataKey="investment"
              fill="#90CDB7"
              minPointSize={2}
              barSize={9}
              radius={[10, 10, 0, 0]}
            >
              {transformedData?.map((d: any, idx: any) => {
                return (
                  <Cell
                    key={d["investment"]}
                    fill="#90CDB7"
                    stroke={d.is_interpolated ? "#AA93DB" : "#90CDB7"}
                    fillOpacity={d.is_predicted || d.is_interpolated ? 0.3 : 1}
                    strokeDasharray={d.is_interpolated ? 0 : d.is_predicted ? 3 : 0}
                  />
                );
              })}
            </Bar>
            <Bar
              dataKey="property"
              fill="#298085"
              minPointSize={2}
              barSize={9}
              radius={[10, 10, 0, 0]}
            >
              {transformedData?.map((d: any, idx: any) => {
                return (
                  <Cell
                    key={d["property"]}
                    fill="#298085"
                    stroke={d.is_interpolated ? "#AA93DB" : "#298085"}
                    fillOpacity={d.is_predicted || d.is_interpolated ? 0.3 : 1}
                    strokeDasharray={d.is_interpolated ? 0 : d.is_predicted ? 3 : 0}
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <div className="mt-4 flex  items-center justify-center gap-4">
          {estimatedData && estimatedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-purple-100 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-estimated text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Estimated years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {estimatedData?.map((i) => i.year).join(", ")}
              </p>
            </div>
          )}
          {predictedData && predictedData?.length > 0 && (
            <div className="inline-flex h-[38px] min-w-[246px] gap-3 bg-gray-50 px-4 py-2">
              <div className="inline-flex h-[22px] items-center gap-0.5 rounded border border-dashed border-purple-500 px-[6px] py-0.5">
                <span className="grid h-[14px] w-[14px] place-items-center">
                  <i className="gi gi-predicted text-purple-0" />
                </span>
                <p className="whitespace-nowrap font-poppins text-xs text-gray-700">
                  Predicted years
                </p>
              </div>
              <p className="font-poppins text-sm text-black">
                {predictedData?.map((i) => i.year).join(", ")}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <FinancialPredictionInfo />
        </div>
      </>
    );
  };
  const renderTitle = () => {
    return (
      <div className="flex items-center justify-center gap-5">
        {LABEL_MAP.map((item, index) => {
          return (
            <div className="flex items-center gap-2" key={item}>
              <div className="h-[14px] w-[16px]" style={{ background: COLOR_MAP[index] }}></div>
              <h6 className="font-poppins  text-xs text-gray-700">{LABEL_MAP[index]}</h6>
            </div>
          );
        })}
      </div>
    );
  };
  const renderLeftContent = () => {
    if (revenueStream?.length === 0) return <NoGraph />;
    return (
      <>
        {renderTitle()}
        <div className="mt-4 min-h-[260px] w-[540px]">{renderChart()}</div>
      </>
    );
  };

  return (
    <>
      <div className="container mx-auto flex min-h-[370px] items-center justify-center">
        <div
          className={classNames(
            "flex min-h-[345px] w-full   gap-5",
            compact ? "flex-col " : "flex-row ",
          )}
        >
          <div
            className={classNames(
              "flex w-full min-w-[330px]  flex-col items-center justify-center",
            )}
          >
            {renderLeftContent()}
          </div>
        </div>
      </div>
    </>
  );
}
