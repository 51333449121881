import classNames from "classnames";
import { useState } from "react";
import LeftInnerIconInput from "../../../../components/LeftInnerIconInput";
import {
  FUNDRAISING_PROCESS,
  TaskAsistantDocumentType,
} from "../../../../components/GibooAiTaskAssistant";
import { scrollBarClass } from "../../../../utils/classes";
import { DocumentTypeEnum } from "../../../../types/document";
import { useDocumentAIContext } from "../ProjectDocumentAI";

export default function DocumentAIPromptList({
  setDocumentType,
}: {
  setDocumentType: (e: DocumentTypeEnum) => void;
}) {
  const { documentDefinition } = useDocumentAIContext();
  const [query, setQuery] = useState<string>("");
  const promptList = FUNDRAISING_PROCESS.reduce(
    (prev, cur) => [...prev, ...cur.data.filter((t) => t.typeEnum)],
    [] as TaskAsistantDocumentType[],
  );
  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex max-w-[760px] flex-col items-center gap-12 text-gray-900">
        <div className="flex flex-col items-center gap-4">
          <p className="text-2xl font-semibold">Welcome to Document AI</p>
          <h5>
            Get started by writing a letter from our{" "}
            <span className="font-semibold text-purple-500">Prompt library.</span>
          </h5>
        </div>
        {/* <LeftInnerIconInput
          id="input-search-prompt"
          iconClass="gi-sm gi-search text-gray-700 !text-sm"
          className="max-h-[44px] !bg-white"
          placeholder="Browse prompts"
          value={query}
          handleOnChange={(e) => setQuery(e.target.value || "")}
        /> */}
        <div
          className={classNames("grid grow  grid-cols-2 gap-3", scrollBarClass)}
          style={{ maxHeight: `calc(100vh - 380px)` }}
        >
          {promptList
            .filter(
              (p) =>
                p.typeEnum !== DocumentTypeEnum.CUSTOM &&
                p.name.toLowerCase().includes(query.toLowerCase()),
            )
            .map((p) => (
              <div
                key={p.typeEnum!}
                className={classNames(
                  "flex h-12 !min-h-[48px] cursor-pointer items-center rounded border px-4",
                  // documentDefinition?.type === p.typeEnum
                  //   ? "border-purple-500 bg-purple-500 text-white"
                  //   :
                  "border-gray-300 bg-white hover:bg-gray-100",
                )}
                onClick={() => p.typeEnum && setDocumentType(p.typeEnum)}
              >
                <h5>{p.name}</h5>
              </div>
            ))}

          <div
            key={"comingsoon"}
            className={classNames(
              "flex h-12 !min-h-[48px] items-center rounded border px-4",
              "border-gray-300 bg-gray-50 text-gray-700",
            )}
          >
            <h5>{`More prompts coming soon`}</h5>
          </div>
        </div>
      </div>
    </div>
  );
}
