import { DebounceSettings, debounce } from "lodash";
import { useState, useEffect, useCallback } from "react";

export default function useDebounce<T>(value: T, delay: number, options?: DebounceSettings) {
  const [delayedValue, setDelayedValue] = useState<T>(value);
  // 1. library
  const debouncedFunc = useCallback(
    debounce((value) => setDelayedValue(value), delay, options),
    [setDelayedValue],
  );
  useEffect(() => {
    debouncedFunc(value);
  }, [value, delay, options]);

  // 2. raw
  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     setDelayedValue(value);
  //   }, delay);

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [value, delay]);

  return delayedValue;
}
